export const counties_sd = {
  "type":"FeatureCollection","name":"sd_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "081",
      "COUNTYNS": "01266996",
      "AFFGEOID": "0500000US46081",
      "GEOID": "46081",
      "NAME": "Lawrence",
      "LSAD": "06",
      "ALAND": 2072127374,
      "AWATER": 667509,
      "County_state": "Lawrence,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.055892,
              44.543341
            ],
            [
              -104.055927,
              44.51773
            ],
            [
              -104.055389,
              44.249983
            ],
            [
              -104.054487,
              44.180381
            ],
            [
              -104.054562099418,
              44.141080971847195
            ],
            [
              -103.589462,
              44.14067
            ],
            [
              -103.452453,
              44.140772
            ],
            [
              -103.452543,
              44.183423
            ],
            [
              -103.453016,
              44.256609
            ],
            [
              -103.574396,
              44.256077
            ],
            [
              -103.569417,
              44.344246
            ],
            [
              -103.567653,
              44.604098
            ],
            [
              -103.638658,
              44.604403
            ],
            [
              -103.820639,
              44.604777
            ],
            [
              -103.842925,
              44.587534
            ],
            [
              -103.866805,
              44.589519
            ],
            [
              -103.886459,
              44.598272
            ],
            [
              -103.918272,
              44.589228
            ],
            [
              -103.933931,
              44.579386
            ],
            [
              -103.96745,
              44.581871
            ],
            [
              -104.00333,
              44.573542
            ],
            [
              -104.034638,
              44.580958
            ],
            [
              -104.04952,
              44.576937
            ],
            [
              -104.05587666691001,
              44.57101573190359
            ],
            [
              -104.055892,
              44.543341
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "099",
      "COUNTYNS": "01265772",
      "AFFGEOID": "0500000US46099",
      "GEOID": "46099",
      "NAME": "Minnehaha",
      "LSAD": "06",
      "ALAND": 2089691696,
      "AWATER": 18198505,
      "County_state": "Minnehaha,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.129025,
              43.761126
            ],
            [
              -97.129478,
              43.499683
            ],
            [
              -96.924841,
              43.500007
            ],
            [
              -96.890085,
              43.500115
            ],
            [
              -96.790816,
              43.500276
            ],
            [
              -96.788206,
              43.500303
            ],
            [
              -96.748026,
              43.500378
            ],
            [
              -96.707659,
              43.50037
            ],
            [
              -96.60147,
              43.500449
            ],
            [
              -96.5989289125409,
              43.5004410269423
            ],
            [
              -96.598928,
              43.500457
            ],
            [
              -96.591213,
              43.500514
            ],
            [
              -96.45326,
              43.50039
            ],
            [
              -96.453352,
              43.58704
            ],
            [
              -96.453383,
              43.588183
            ],
            [
              -96.453356,
              43.607544
            ],
            [
              -96.453387,
              43.609944
            ],
            [
              -96.453408,
              43.675008
            ],
            [
              -96.45338,
              43.689637
            ],
            [
              -96.453281,
              43.791435
            ],
            [
              -96.453088,
              43.805123
            ],
            [
              -96.45326359312119,
              43.849501168336694
            ],
            [
              -96.504761,
              43.847694
            ],
            [
              -96.888664,
              43.848385
            ],
            [
              -97.129089,
              43.847973
            ],
            [
              -97.129025,
              43.761126
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "125",
      "COUNTYNS": "01265770",
      "AFFGEOID": "0500000US46125",
      "GEOID": "46125",
      "NAME": "Turner",
      "LSAD": "06",
      "ALAND": 1598188427,
      "AWATER": 1636386,
      "County_state": "Turner,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.40145,
              43.499783
            ],
            [
              -97.399191,
              43.169419
            ],
            [
              -97.160544,
              43.169978
            ],
            [
              -97.160543,
              43.083145
            ],
            [
              -96.924142,
              43.083733
            ],
            [
              -96.924568,
              43.257934
            ],
            [
              -96.924841,
              43.500007
            ],
            [
              -97.129478,
              43.499683
            ],
            [
              -97.40145,
              43.499783
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "003",
      "COUNTYNS": "01266983",
      "AFFGEOID": "0500000US46003",
      "GEOID": "46003",
      "NAME": "Aurora",
      "LSAD": "06",
      "ALAND": 1834813747,
      "AWATER": 11201379,
      "County_state": "Aurora,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.807771,
              43.935223
            ],
            [
              -98.806788,
              43.848487
            ],
            [
              -98.796965,
              43.848575
            ],
            [
              -98.794588,
              43.499187
            ],
            [
              -98.705782,
              43.499319
            ],
            [
              -98.320595,
              43.498651
            ],
            [
              -98.325053,
              43.579397
            ],
            [
              -98.325242,
              43.851058
            ],
            [
              -98.331484,
              43.850988
            ],
            [
              -98.331508,
              43.937708
            ],
            [
              -98.807771,
              43.935223
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "079",
      "COUNTYNS": "01265776",
      "AFFGEOID": "0500000US46079",
      "GEOID": "46079",
      "NAME": "Lake",
      "LSAD": "06",
      "ALAND": 1457893335,
      "AWATER": 31769730,
      "County_state": "Lake,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.370115,
              44.194971
            ],
            [
              -97.369463,
              43.848526
            ],
            [
              -97.129089,
              43.847973
            ],
            [
              -96.888664,
              43.848385
            ],
            [
              -96.889216,
              44.195705
            ],
            [
              -97.129671,
              44.195933
            ],
            [
              -97.370115,
              44.194971
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "069",
      "COUNTYNS": "01266984",
      "AFFGEOID": "0500000US46069",
      "GEOID": "46069",
      "NAME": "Hyde",
      "LSAD": "06",
      "ALAND": 2228778417,
      "AWATER": 14541547,
      "County_state": "Hyde,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.67305,
              44.897492
            ],
            [
              -99.676398,
              44.548111
            ],
            [
              -99.66367,
              44.548089
            ],
            [
              -99.663508,
              44.217517
            ],
            [
              -99.63235,
              44.215661
            ],
            [
              -99.593941,
              44.204045
            ],
            [
              -99.576571,
              44.192421
            ],
            [
              -99.30018,
              44.19483
            ],
            [
              -99.300495,
              44.54738
            ],
            [
              -99.312483,
              44.547485
            ],
            [
              -99.311754,
              44.897227
            ],
            [
              -99.572699,
              44.897263
            ],
            [
              -99.67305,
              44.897492
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "007",
      "COUNTYNS": "01265812",
      "AFFGEOID": "0500000US46007",
      "GEOID": "46007",
      "NAME": "Bennett",
      "LSAD": "06",
      "ALAND": 3068207883,
      "AWATER": 15044355,
      "County_state": "Bennett,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.110819,
              43.389902
            ],
            [
              -102.108892,
              43.129258
            ],
            [
              -102.081427,
              43.12918
            ],
            [
              -102.082486038791,
              42.9993559930387
            ],
            [
              -101.849982,
              42.999329
            ],
            [
              -101.713573,
              42.99662
            ],
            [
              -101.625424,
              42.996238
            ],
            [
              -101.500424,
              42.997115
            ],
            [
              -101.230325,
              42.997899
            ],
            [
              -101.229203,
              42.997854
            ],
            [
              -101.22800834159801,
              42.9978753513416
            ],
            [
              -101.228234,
              43.389185
            ],
            [
              -102.110819,
              43.389902
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "017",
      "COUNTYNS": "01265767",
      "AFFGEOID": "0500000US46017",
      "GEOID": "46017",
      "NAME": "Buffalo",
      "LSAD": "06",
      "ALAND": 1220891600,
      "AWATER": 42362357,
      "County_state": "Buffalo,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.576571,
              44.192421
            ],
            [
              -99.551233,
              44.171215
            ],
            [
              -99.540641,
              44.150137
            ],
            [
              -99.554,
              44.127766
            ],
            [
              -99.562536,
              44.101519
            ],
            [
              -99.558435,
              44.091971
            ],
            [
              -99.534366,
              44.069663
            ],
            [
              -99.511334,
              44.05499
            ],
            [
              -99.439546,
              44.046786
            ],
            [
              -99.421362,
              44.039732
            ],
            [
              -99.35372,
              43.995329
            ],
            [
              -99.345995,
              43.981446
            ],
            [
              -99.346229,
              43.962309
            ],
            [
              -99.355864,
              43.934371
            ],
            [
              -99.327026,
              43.933453
            ],
            [
              -98.926997,
              43.935143
            ],
            [
              -98.925953,
              44.196575
            ],
            [
              -99.236719,
              44.196428
            ],
            [
              -99.30018,
              44.19483
            ],
            [
              -99.576571,
              44.192421
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "097",
      "COUNTYNS": "01265777",
      "AFFGEOID": "0500000US46097",
      "GEOID": "46097",
      "NAME": "Miner",
      "LSAD": "06",
      "ALAND": 1476889390,
      "AWATER": 4382648,
      "County_state": "Miner,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.849492,
              44.195235
            ],
            [
              -97.85111,
              43.849857
            ],
            [
              -97.608517,
              43.849059
            ],
            [
              -97.369463,
              43.848526
            ],
            [
              -97.370115,
              44.194971
            ],
            [
              -97.849492,
              44.195235
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "025",
      "COUNTYNS": "01266985",
      "AFFGEOID": "0500000US46025",
      "GEOID": "46025",
      "NAME": "Clark",
      "LSAD": "06",
      "ALAND": 2480181919,
      "AWATER": 25627353,
      "County_state": "Clark,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.981792,
              45.153295
            ],
            [
              -97.982823,
              44.892297
            ],
            [
              -97.977194,
              44.892274
            ],
            [
              -97.977791,
              44.631602
            ],
            [
              -97.856226,
              44.631178
            ],
            [
              -97.853028,
              44.544398
            ],
            [
              -97.492266,
              44.543884
            ],
            [
              -97.491346,
              44.804035
            ],
            [
              -97.49122,
              44.891278
            ],
            [
              -97.494117,
              44.893654
            ],
            [
              -97.494254,
              45.151631
            ],
            [
              -97.981792,
              45.153295
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "053",
      "COUNTYNS": "01265765",
      "AFFGEOID": "0500000US46053",
      "GEOID": "46053",
      "NAME": "Gregory",
      "LSAD": "06",
      "ALAND": 2628707523,
      "AWATER": 99900775,
      "County_state": "Gregory,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.534481,
              43.499801
            ],
            [
              -99.5340492772214,
              42.9980405812359
            ],
            [
              -99.494287,
              42.998118
            ],
            [
              -99.490798,
              42.998143
            ],
            [
              -99.474531,
              42.998081
            ],
            [
              -99.471353,
              42.997967
            ],
            [
              -99.395568,
              42.99817
            ],
            [
              -99.374268,
              42.998047
            ],
            [
              -99.371121,
              42.998093
            ],
            [
              -99.368628,
              42.99814
            ],
            [
              -99.347283,
              42.998217
            ],
            [
              -99.288045,
              42.998152
            ],
            [
              -99.26271,
              42.998234
            ],
            [
              -99.2544542492264,
              42.998139794357
            ],
            [
              -99.254297,
              42.998138
            ],
            [
              -99.234462,
              42.998281
            ],
            [
              -99.195199,
              42.998107
            ],
            [
              -99.161388,
              42.998465
            ],
            [
              -99.151143,
              42.998344
            ],
            [
              -99.139045,
              42.998508
            ],
            [
              -99.135961,
              42.998301
            ],
            [
              -99.08188,
              42.998288
            ],
            [
              -99.080011,
              42.998357
            ],
            [
              -99.0223,
              42.998237
            ],
            [
              -99.021909,
              42.998365
            ],
            [
              -99.00037,
              42.998273
            ],
            [
              -98.962081,
              42.998286
            ],
            [
              -98.919234,
              42.998241
            ],
            [
              -98.919136,
              42.998242
            ],
            [
              -98.903154,
              42.998306
            ],
            [
              -98.899944,
              42.998122
            ],
            [
              -98.823989,
              42.99831
            ],
            [
              -98.801304,
              42.998241
            ],
            [
              -98.764378,
              42.998323
            ],
            [
              -98.742394,
              42.998343
            ],
            [
              -98.665613,
              42.998536
            ],
            [
              -98.663712,
              42.998444
            ],
            [
              -98.568936,
              42.998537
            ],
            [
              -98.565072,
              42.9984
            ],
            [
              -98.49855,
              42.99856
            ],
            [
              -98.506738,
              43.019291
            ],
            [
              -98.524871,
              43.039347
            ],
            [
              -98.557477,
              43.058965
            ],
            [
              -98.618559,
              43.074169
            ],
            [
              -98.645094,
              43.07129
            ],
            [
              -98.69046,
              43.076556
            ],
            [
              -98.714573,
              43.088914
            ],
            [
              -98.750362,
              43.129534
            ],
            [
              -98.773406,
              43.145919
            ],
            [
              -98.790429,
              43.150106
            ],
            [
              -98.853591,
              43.154623
            ],
            [
              -98.865627,
              43.159554
            ],
            [
              -98.87759,
              43.179981
            ],
            [
              -98.877733,
              43.22664
            ],
            [
              -98.896783,
              43.246909
            ],
            [
              -98.945735,
              43.248306
            ],
            [
              -98.981253,
              43.258945
            ],
            [
              -99.020249,
              43.290614
            ],
            [
              -99.069285,
              43.320688
            ],
            [
              -99.086251,
              43.339327
            ],
            [
              -99.108902,
              43.373003
            ],
            [
              -99.126383,
              43.380622
            ],
            [
              -99.152889,
              43.426908
            ],
            [
              -99.167204,
              43.43521
            ],
            [
              -99.255911,
              43.43709
            ],
            [
              -99.280798,
              43.444797
            ],
            [
              -99.310073,
              43.467935
            ],
            [
              -99.297998,
              43.499669
            ],
            [
              -99.534481,
              43.499801
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "043",
      "COUNTYNS": "01266978",
      "AFFGEOID": "0500000US46043",
      "GEOID": "46043",
      "NAME": "Douglas",
      "LSAD": "06",
      "ALAND": 1118358767,
      "AWATER": 4832944,
      "County_state": "Douglas,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.705782,
              43.499319
            ],
            [
              -98.706855,
              43.370075
            ],
            [
              -98.680688,
              43.373157
            ],
            [
              -98.109492,
              43.196838
            ],
            [
              -98.113196,
              43.484439
            ],
            [
              -98.114758,
              43.498297
            ],
            [
              -98.320595,
              43.498651
            ],
            [
              -98.705782,
              43.499319
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "091",
      "COUNTYNS": "01265759",
      "AFFGEOID": "0500000US46091",
      "GEOID": "46091",
      "NAME": "Marshall",
      "LSAD": "06",
      "ALAND": 2170630077,
      "AWATER": 124680563,
      "County_state": "Marshall,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.979238,
              45.762569
            ],
            [
              -97.979592,
              45.588483
            ],
            [
              -97.373366,
              45.587634
            ],
            [
              -97.36464,
              45.558728
            ],
            [
              -97.227089,
              45.558158
            ],
            [
              -97.2283038853974,
              45.93514103142339
            ],
            [
              -97.228323,
              45.935141
            ],
            [
              -97.312184,
              45.935077
            ],
            [
              -97.318899,
              45.935054
            ],
            [
              -97.481967,
              45.935138
            ],
            [
              -97.491892,
              45.935111
            ],
            [
              -97.518944,
              45.935304
            ],
            [
              -97.519035,
              45.935304
            ],
            [
              -97.542598,
              45.935258
            ],
            [
              -97.696691,
              45.935352
            ],
            [
              -97.77704,
              45.935393
            ],
            [
              -97.784575,
              45.935327
            ],
            [
              -97.958718,
              45.935878
            ],
            [
              -97.97877778172959,
              45.935937093589494
            ],
            [
              -97.979238,
              45.762569
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "029",
      "COUNTYNS": "01265791",
      "AFFGEOID": "0500000US46029",
      "GEOID": "46029",
      "NAME": "Codington",
      "LSAD": "06",
      "ALAND": 1780837027,
      "AWATER": 76608850,
      "County_state": "Codington,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.494254,
              45.151631
            ],
            [
              -97.494117,
              44.893654
            ],
            [
              -97.49122,
              44.891278
            ],
            [
              -97.491346,
              44.804035
            ],
            [
              -97.248576,
              44.804233
            ],
            [
              -97.00598,
              44.804351
            ],
            [
              -96.88457,
              44.804436
            ],
            [
              -96.882345,
              44.97687
            ],
            [
              -96.883948,
              45.150224
            ],
            [
              -97.226281,
              45.151826
            ],
            [
              -97.239991,
              45.151194
            ],
            [
              -97.494254,
              45.151631
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "067",
      "COUNTYNS": "01265771",
      "AFFGEOID": "0500000US46067",
      "GEOID": "46067",
      "NAME": "Hutchinson",
      "LSAD": "06",
      "ALAND": 2105695489,
      "AWATER": 3644220,
      "County_state": "Hutchinson,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.114758,
              43.498297
            ],
            [
              -98.113196,
              43.484439
            ],
            [
              -98.109492,
              43.196838
            ],
            [
              -98.069044,
              43.184887
            ],
            [
              -98.077108,
              43.16833
            ],
            [
              -97.991041,
              43.168533
            ],
            [
              -97.637496,
              43.16877
            ],
            [
              -97.399191,
              43.169419
            ],
            [
              -97.40145,
              43.499783
            ],
            [
              -97.607012,
              43.499826
            ],
            [
              -97.965012,
              43.49904
            ],
            [
              -98.114758,
              43.498297
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "019",
      "COUNTYNS": "01265793",
      "AFFGEOID": "0500000US46019",
      "GEOID": "46019",
      "NAME": "Butte",
      "LSAD": "06",
      "ALAND": 5827518214,
      "AWATER": 42730886,
      "County_state": "Butte,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.057698,
              44.997431
            ],
            [
              -104.055914,
              44.874986
            ],
            [
              -104.056496,
              44.867034
            ],
            [
              -104.055963,
              44.768236
            ],
            [
              -104.055963,
              44.767962
            ],
            [
              -104.055934,
              44.72372
            ],
            [
              -104.05587,
              44.723422
            ],
            [
              -104.055777,
              44.700466
            ],
            [
              -104.055938,
              44.693881
            ],
            [
              -104.05581,
              44.691343
            ],
            [
              -104.05587666691001,
              44.57101573190359
            ],
            [
              -104.04952,
              44.576937
            ],
            [
              -104.034638,
              44.580958
            ],
            [
              -104.00333,
              44.573542
            ],
            [
              -103.96745,
              44.581871
            ],
            [
              -103.933931,
              44.579386
            ],
            [
              -103.918272,
              44.589228
            ],
            [
              -103.886459,
              44.598272
            ],
            [
              -103.866805,
              44.589519
            ],
            [
              -103.842925,
              44.587534
            ],
            [
              -103.820639,
              44.604777
            ],
            [
              -103.638658,
              44.604403
            ],
            [
              -103.567653,
              44.604098
            ],
            [
              -102.964125,
              44.604287
            ],
            [
              -102.963646,
              44.690613
            ],
            [
              -102.958819,
              45.035189
            ],
            [
              -102.957371,
              45.039569
            ],
            [
              -102.957303,
              45.126256
            ],
            [
              -102.957281,
              45.212851
            ],
            [
              -102.987788,
              45.212542
            ],
            [
              -103.000648,
              45.212989
            ],
            [
              -103.562581,
              45.213266
            ],
            [
              -104.04013575649302,
              45.212890762041596
            ],
            [
              -104.039977,
              45.124988
            ],
            [
              -104.039563,
              45.124039
            ],
            [
              -104.039693,
              45.000552
            ],
            [
              -104.039138,
              44.99852
            ],
            [
              -104.05021,
              44.997571
            ],
            [
              -104.057698,
              44.997431
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "059",
      "COUNTYNS": "01265783",
      "AFFGEOID": "0500000US46059",
      "GEOID": "46059",
      "NAME": "Hand",
      "LSAD": "06",
      "ALAND": 3720897248,
      "AWATER": 9241423,
      "County_state": "Hand,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.311754,
              44.897227
            ],
            [
              -99.312483,
              44.547485
            ],
            [
              -99.300495,
              44.54738
            ],
            [
              -99.30018,
              44.19483
            ],
            [
              -99.236719,
              44.196428
            ],
            [
              -98.925953,
              44.196575
            ],
            [
              -98.700453,
              44.196727
            ],
            [
              -98.705714,
              44.547283
            ],
            [
              -98.705762,
              44.634159
            ],
            [
              -98.705535,
              44.808039
            ],
            [
              -98.705357,
              44.89687
            ],
            [
              -98.716498,
              44.896925
            ],
            [
              -99.311754,
              44.897227
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "077",
      "COUNTYNS": "01265780",
      "AFFGEOID": "0500000US46077",
      "GEOID": "46077",
      "NAME": "Kingsbury",
      "LSAD": "06",
      "ALAND": 2155483525,
      "AWATER": 81655092,
      "County_state": "Kingsbury,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.853028,
              44.544398
            ],
            [
              -97.85366,
              44.195233
            ],
            [
              -97.849492,
              44.195235
            ],
            [
              -97.370115,
              44.194971
            ],
            [
              -97.129671,
              44.195933
            ],
            [
              -97.128024,
              44.543126
            ],
            [
              -97.492266,
              44.543884
            ],
            [
              -97.853028,
              44.544398
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "035",
      "COUNTYNS": "01266980",
      "AFFGEOID": "0500000US46035",
      "GEOID": "46035",
      "NAME": "Davison",
      "LSAD": "06",
      "ALAND": 1127942470,
      "AWATER": 3675453,
      "County_state": "Davison,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.325242,
              43.851058
            ],
            [
              -98.325053,
              43.579397
            ],
            [
              -98.320595,
              43.498651
            ],
            [
              -98.114758,
              43.498297
            ],
            [
              -97.965012,
              43.49904
            ],
            [
              -97.967279,
              43.850686
            ],
            [
              -98.325242,
              43.851058
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "085",
      "COUNTYNS": "01265789",
      "AFFGEOID": "0500000US46085",
      "GEOID": "46085",
      "NAME": "Lyman",
      "LSAD": "06",
      "ALAND": 4253139895,
      "AWATER": 167659051,
      "County_state": "Lyman,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.367247,
              44.194621
            ],
            [
              -100.367207,
              44.168694
            ],
            [
              -100.366174,
              43.846978
            ],
            [
              -100.339397,
              43.847061
            ],
            [
              -100.33897,
              43.716942
            ],
            [
              -100.30146,
              43.720126
            ],
            [
              -100.280028,
              43.696795
            ],
            [
              -100.265561,
              43.709162
            ],
            [
              -100.252156,
              43.703171
            ],
            [
              -100.230845,
              43.713856
            ],
            [
              -100.229705,
              43.71412
            ],
            [
              -100.224742,
              43.713097
            ],
            [
              -100.223677,
              43.709442
            ],
            [
              -100.224782,
              43.700861
            ],
            [
              -100.218892,
              43.691171
            ],
            [
              -100.212897,
              43.68635
            ],
            [
              -100.2113,
              43.684111
            ],
            [
              -100.206211,
              43.687091
            ],
            [
              -100.203148,
              43.691396
            ],
            [
              -100.199758,
              43.698068
            ],
            [
              -100.198812,
              43.702187
            ],
            [
              -100.189919,
              43.706161
            ],
            [
              -100.178767,
              43.705924
            ],
            [
              -100.168104,
              43.70549
            ],
            [
              -100.159699,
              43.703789
            ],
            [
              -100.145959,
              43.696116
            ],
            [
              -100.142727,
              43.695122
            ],
            [
              -100.139292,
              43.697902
            ],
            [
              -100.141962,
              43.699381
            ],
            [
              -100.14371,
              43.700389
            ],
            [
              -100.14075,
              43.704207
            ],
            [
              -100.131887,
              43.702737
            ],
            [
              -100.125405,
              43.698521
            ],
            [
              -100.120512,
              43.690982
            ],
            [
              -100.113924,
              43.68533
            ],
            [
              -100.108656,
              43.688091
            ],
            [
              -100.108297,
              43.691268
            ],
            [
              -100.094992,
              43.68987
            ],
            [
              -100.090298,
              43.686738
            ],
            [
              -100.081992,
              43.687048
            ],
            [
              -100.077543,
              43.69203
            ],
            [
              -100.059722,
              43.692382
            ],
            [
              -100.052662,
              43.6926
            ],
            [
              -100.04559,
              43.697862
            ],
            [
              -100.046775,
              43.701318
            ],
            [
              -100.038021,
              43.703967
            ],
            [
              -100.033025,
              43.700568
            ],
            [
              -100.027724,
              43.699275
            ],
            [
              -100.023173,
              43.701757
            ],
            [
              -100.018328,
              43.704976
            ],
            [
              -100.011265,
              43.707664
            ],
            [
              -100.007122,
              43.703225
            ],
            [
              -100.009873,
              43.697736
            ],
            [
              -100.006797,
              43.692357
            ],
            [
              -100.003441,
              43.692381
            ],
            [
              -99.998984,
              43.696947
            ],
            [
              -99.999939,
              43.699968
            ],
            [
              -99.992469,
              43.699336
            ],
            [
              -99.98511,
              43.693282
            ],
            [
              -99.983525,
              43.689329
            ],
            [
              -99.976065,
              43.69038
            ],
            [
              -99.974432,
              43.692766
            ],
            [
              -99.967242,
              43.6924
            ],
            [
              -99.959657,
              43.689869
            ],
            [
              -99.955411,
              43.692775
            ],
            [
              -99.954837,
              43.695242
            ],
            [
              -99.952877,
              43.698067
            ],
            [
              -99.95047,
              43.697942
            ],
            [
              -99.939459,
              43.696306
            ],
            [
              -99.931956,
              43.692129
            ],
            [
              -99.924312,
              43.687483
            ],
            [
              -99.916123,
              43.692309
            ],
            [
              -99.912124,
              43.694499
            ],
            [
              -99.904405,
              43.698917
            ],
            [
              -99.891747,
              43.692785
            ],
            [
              -99.885454,
              43.683487
            ],
            [
              -99.888132,
              43.680767
            ],
            [
              -99.881323,
              43.675237
            ],
            [
              -99.875291,
              43.673439
            ],
            [
              -99.865307,
              43.674971
            ],
            [
              -99.862617,
              43.683599
            ],
            [
              -99.862865,
              43.691987
            ],
            [
              -99.859372,
              43.691656
            ],
            [
              -99.85566,
              43.690055
            ],
            [
              -99.851915,
              43.69168
            ],
            [
              -99.85363,
              43.693648
            ],
            [
              -99.858426,
              43.697363
            ],
            [
              -99.856572,
              43.701333
            ],
            [
              -99.849649,
              43.698086
            ],
            [
              -99.831696,
              43.691384
            ],
            [
              -99.818723,
              43.696082
            ],
            [
              -99.804761,
              43.699991
            ],
            [
              -99.79898,
              43.706637
            ],
            [
              -99.799483,
              43.709917
            ],
            [
              -99.791383,
              43.710927
            ],
            [
              -99.783551,
              43.709706
            ],
            [
              -99.765293,
              43.711154
            ],
            [
              -99.752008,
              43.710149
            ],
            [
              -99.750002,
              43.710404
            ],
            [
              -99.74855,
              43.710143
            ],
            [
              -99.747238,
              43.714994
            ],
            [
              -99.757172,
              43.723607
            ],
            [
              -99.762419,
              43.728679
            ],
            [
              -99.760364,
              43.741056
            ],
            [
              -99.751418,
              43.747207
            ],
            [
              -99.748028,
              43.748292
            ],
            [
              -99.744239,
              43.748662
            ],
            [
              -99.741674,
              43.748022
            ],
            [
              -99.740058,
              43.746701
            ],
            [
              -99.739492,
              43.745126
            ],
            [
              -99.738984,
              43.740752
            ],
            [
              -99.738799,
              43.739692
            ],
            [
              -99.738471,
              43.73802
            ],
            [
              -99.737613,
              43.736419
            ],
            [
              -99.736382,
              43.735322
            ],
            [
              -99.736473,
              43.733893
            ],
            [
              -99.736238,
              43.731461
            ],
            [
              -99.737051,
              43.729377
            ],
            [
              -99.737751,
              43.727543
            ],
            [
              -99.73858,
              43.725307
            ],
            [
              -99.740209,
              43.723717
            ],
            [
              -99.738728,
              43.722197
            ],
            [
              -99.737333,
              43.721291
            ],
            [
              -99.735622,
              43.720304
            ],
            [
              -99.734055,
              43.719565
            ],
            [
              -99.731797,
              43.718978
            ],
            [
              -99.728757,
              43.719101
            ],
            [
              -99.726522,
              43.719904
            ],
            [
              -99.725288,
              43.721002
            ],
            [
              -99.723226,
              43.722468
            ],
            [
              -99.72169,
              43.724777
            ],
            [
              -99.717435,
              43.727969
            ],
            [
              -99.713272,
              43.731501
            ],
            [
              -99.711011,
              43.732958
            ],
            [
              -99.710467,
              43.733891
            ],
            [
              -99.709944,
              43.736124
            ],
            [
              -99.710002,
              43.738154
            ],
            [
              -99.710269,
              43.740663
            ],
            [
              -99.710077,
              43.742369
            ],
            [
              -99.709139,
              43.743716
            ],
            [
              -99.708651,
              43.74522
            ],
            [
              -99.708353,
              43.746781
            ],
            [
              -99.707177,
              43.749096
            ],
            [
              -99.705798,
              43.75051
            ],
            [
              -99.704188,
              43.751524
            ],
            [
              -99.702333,
              43.752042
            ],
            [
              -99.700989,
              43.753066
            ],
            [
              -99.699429,
              43.754944
            ],
            [
              -99.698456,
              43.756901
            ],
            [
              -99.696825,
              43.758666
            ],
            [
              -99.694046,
              43.760242
            ],
            [
              -99.691869,
              43.760422
            ],
            [
              -99.689399,
              43.75935
            ],
            [
              -99.686695,
              43.758484
            ],
            [
              -99.684769,
              43.757077
            ],
            [
              -99.683478,
              43.755446
            ],
            [
              -99.682308,
              43.753888
            ],
            [
              -99.681938,
              43.752004
            ],
            [
              -99.681392,
              43.750474
            ],
            [
              -99.681121,
              43.748716
            ],
            [
              -99.681725,
              43.747379
            ],
            [
              -99.682684,
              43.746096
            ],
            [
              -99.683631,
              43.744285
            ],
            [
              -99.684736,
              43.742983
            ],
            [
              -99.68526,
              43.74199
            ],
            [
              -99.686479,
              43.740881
            ],
            [
              -99.6875,
              43.739715
            ],
            [
              -99.689249,
              43.737925
            ],
            [
              -99.690174,
              43.736083
            ],
            [
              -99.690767,
              43.733596
            ],
            [
              -99.689471,
              43.73083
            ],
            [
              -99.687738,
              43.729673
            ],
            [
              -99.686663,
              43.728553
            ],
            [
              -99.685119,
              43.72792
            ],
            [
              -99.681668,
              43.728056
            ],
            [
              -99.67427,
              43.728032
            ],
            [
              -99.670972,
              43.728885
            ],
            [
              -99.669144,
              43.729627
            ],
            [
              -99.667376,
              43.730423
            ],
            [
              -99.666129,
              43.731715
            ],
            [
              -99.66457,
              43.732936
            ],
            [
              -99.662931,
              43.734668
            ],
            [
              -99.662173,
              43.736471
            ],
            [
              -99.662226,
              43.73899
            ],
            [
              -99.662236,
              43.742148
            ],
            [
              -99.663614,
              43.744024
            ],
            [
              -99.663655,
              43.748282
            ],
            [
              -99.664003,
              43.750248
            ],
            [
              -99.664542,
              43.75165
            ],
            [
              -99.66531,
              43.753635
            ],
            [
              -99.665138,
              43.755834
            ],
            [
              -99.666008,
              43.757681
            ],
            [
              -99.666725,
              43.759043
            ],
            [
              -99.667448,
              43.761271
            ],
            [
              -99.66744,
              43.763349
            ],
            [
              -99.666062,
              43.764732
            ],
            [
              -99.664085,
              43.765359
            ],
            [
              -99.661877,
              43.766413
            ],
            [
              -99.658784,
              43.76655
            ],
            [
              -99.656543,
              43.76604
            ],
            [
              -99.651915,
              43.763254
            ],
            [
              -99.648655,
              43.761701
            ],
            [
              -99.645404,
              43.760214
            ],
            [
              -99.643038,
              43.757035
            ],
            [
              -99.641826,
              43.753437
            ],
            [
              -99.641568,
              43.751727
            ],
            [
              -99.641213,
              43.749701
            ],
            [
              -99.641523,
              43.746859
            ],
            [
              -99.642128,
              43.745361
            ],
            [
              -99.641921,
              43.742613
            ],
            [
              -99.64227,
              43.741667
            ],
            [
              -99.64302,
              43.739653
            ],
            [
              -99.643442,
              43.737732
            ],
            [
              -99.642006,
              43.736319
            ],
            [
              -99.640832,
              43.735558
            ],
            [
              -99.637677,
              43.735303
            ],
            [
              -99.63529,
              43.735821
            ],
            [
              -99.632487,
              43.736667
            ],
            [
              -99.630495,
              43.737519
            ],
            [
              -99.628432,
              43.738963
            ],
            [
              -99.626347,
              43.740536
            ],
            [
              -99.624959,
              43.741113
            ],
            [
              -99.624151,
              43.741834
            ],
            [
              -99.62371,
              43.722922
            ],
            [
              -99.623803,
              43.698721
            ],
            [
              -99.623651,
              43.666597
            ],
            [
              -99.623417,
              43.643014
            ],
            [
              -99.623422,
              43.60875
            ],
            [
              -99.623437,
              43.581678
            ],
            [
              -99.623443,
              43.571222
            ],
            [
              -99.623468,
              43.545231
            ],
            [
              -99.623502,
              43.529182
            ],
            [
              -99.62351,
              43.514979
            ],
            [
              -99.615475,
              43.499841
            ],
            [
              -99.603539,
              43.499813
            ],
            [
              -99.572029,
              43.499868
            ],
            [
              -99.545205,
              43.499635
            ],
            [
              -99.534481,
              43.499801
            ],
            [
              -99.297998,
              43.499669
            ],
            [
              -99.297882,
              43.499856
            ],
            [
              -99.282777,
              43.518315
            ],
            [
              -99.283342,
              43.531147
            ],
            [
              -99.323765,
              43.561131
            ],
            [
              -99.339601,
              43.580929
            ],
            [
              -99.343456,
              43.615965
            ],
            [
              -99.355636,
              43.642347
            ],
            [
              -99.375378,
              43.656869
            ],
            [
              -99.391342,
              43.658432
            ],
            [
              -99.429398,
              43.651979
            ],
            [
              -99.458809,
              43.6558
            ],
            [
              -99.469097,
              43.672803
            ],
            [
              -99.456258,
              43.686923
            ],
            [
              -99.42179,
              43.696316
            ],
            [
              -99.406961,
              43.705277
            ],
            [
              -99.407712,
              43.728466
            ],
            [
              -99.419004,
              43.744826
            ],
            [
              -99.41015,
              43.774619
            ],
            [
              -99.366244,
              43.792432
            ],
            [
              -99.339977,
              43.818636
            ],
            [
              -99.307996,
              43.860373
            ],
            [
              -99.329268,
              43.882751
            ],
            [
              -99.352226,
              43.892312
            ],
            [
              -99.363183,
              43.915355
            ],
            [
              -99.355864,
              43.934371
            ],
            [
              -99.346229,
              43.962309
            ],
            [
              -99.345995,
              43.981446
            ],
            [
              -99.35372,
              43.995329
            ],
            [
              -99.421362,
              44.039732
            ],
            [
              -99.439546,
              44.046786
            ],
            [
              -99.511334,
              44.05499
            ],
            [
              -99.534366,
              44.069663
            ],
            [
              -99.558435,
              44.091971
            ],
            [
              -99.562536,
              44.101519
            ],
            [
              -99.554,
              44.127766
            ],
            [
              -99.540641,
              44.150137
            ],
            [
              -99.551233,
              44.171215
            ],
            [
              -99.576571,
              44.192421
            ],
            [
              -99.593941,
              44.204045
            ],
            [
              -99.63235,
              44.215661
            ],
            [
              -99.663508,
              44.217517
            ],
            [
              -99.684033,
              44.21446
            ],
            [
              -99.710264,
              44.193197
            ],
            [
              -99.710694,
              44.184581
            ],
            [
              -99.695752,
              44.172375
            ],
            [
              -99.657972,
              44.168646
            ],
            [
              -99.635067,
              44.162114
            ],
            [
              -99.610311,
              44.148887
            ],
            [
              -99.598767,
              44.123778
            ],
            [
              -99.612249,
              44.103694
            ],
            [
              -99.629573,
              44.097985
            ],
            [
              -99.654536,
              44.101689
            ],
            [
              -99.750392,
              44.132819
            ],
            [
              -99.768681,
              44.135743
            ],
            [
              -99.860349,
              44.123638
            ],
            [
              -99.884485,
              44.129582
            ],
            [
              -99.910322,
              44.154638
            ],
            [
              -99.938217,
              44.195195
            ],
            [
              -100.367247,
              44.194621
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "045",
      "COUNTYNS": "01266972",
      "AFFGEOID": "0500000US46045",
      "GEOID": "46045",
      "NAME": "Edmunds",
      "LSAD": "06",
      "ALAND": 2916221362,
      "AWATER": 65024748,
      "County_state": "Edmunds,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.707705,
              45.593391
            ],
            [
              -99.708987,
              45.245866
            ],
            [
              -99.573445,
              45.245364
            ],
            [
              -98.722481,
              45.243764
            ],
            [
              -98.72217,
              45.41751
            ],
            [
              -98.722228,
              45.584795
            ],
            [
              -98.725002,
              45.591252
            ],
            [
              -99.707705,
              45.593391
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "047",
      "COUNTYNS": "01265787",
      "AFFGEOID": "0500000US46047",
      "GEOID": "46047",
      "NAME": "Fall River",
      "LSAD": "06",
      "ALAND": 4506227705,
      "AWATER": 24080933,
      "County_state": "Fall River,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.05477918835702,
              43.4778151089155
            ],
            [
              -104.054766,
              43.428914
            ],
            [
              -104.054614,
              43.390949
            ],
            [
              -104.054403,
              43.325914
            ],
            [
              -104.054218,
              43.30437
            ],
            [
              -104.053884,
              43.297047
            ],
            [
              -104.053876,
              43.289801
            ],
            [
              -104.053127,
              43.000585
            ],
            [
              -103.991077,
              43.001691
            ],
            [
              -103.96627,
              43.001708
            ],
            [
              -103.924921,
              43.000918
            ],
            [
              -103.815573,
              43.001279
            ],
            [
              -103.813939,
              43.001378
            ],
            [
              -103.715084,
              43.000983
            ],
            [
              -103.652919,
              43.001409
            ],
            [
              -103.618334,
              43.000679
            ],
            [
              -103.576966,
              43.000746
            ],
            [
              -103.576329,
              43.000807
            ],
            [
              -103.506556,
              43.000771
            ],
            [
              -103.506151,
              43.000771
            ],
            [
              -103.505219,
              43.00077
            ],
            [
              -103.505099997425,
              43.0007699609789
            ],
            [
              -103.404579,
              43.000737
            ],
            [
              -103.340829,
              43.000879
            ],
            [
              -103.132955,
              43.000784
            ],
            [
              -103.13174,
              43.000783
            ],
            [
              -103.000609657376,
              43.000472962679495
            ],
            [
              -103.000913,
              43.476848
            ],
            [
              -103.148287,
              43.476844
            ],
            [
              -104.05477918835702,
              43.4778151089155
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "107",
      "COUNTYNS": "01265761",
      "AFFGEOID": "0500000US46107",
      "GEOID": "46107",
      "NAME": "Potter",
      "LSAD": "06",
      "ALAND": 2230356770,
      "AWATER": 97171992,
      "County_state": "Potter,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.423918,
              44.990006
            ],
            [
              -100.41573,
              44.923545
            ],
            [
              -100.404753,
              44.897695
            ],
            [
              -99.67305,
              44.897492
            ],
            [
              -99.572699,
              44.897263
            ],
            [
              -99.573445,
              45.245364
            ],
            [
              -99.708987,
              45.245866
            ],
            [
              -100.260223,
              45.24681
            ],
            [
              -100.261342,
              45.224301
            ],
            [
              -100.274993,
              45.198281
            ],
            [
              -100.279,
              45.158894
            ],
            [
              -100.290717,
              45.138377
            ],
            [
              -100.325451,
              45.099337
            ],
            [
              -100.323858,
              45.074164
            ],
            [
              -100.316506,
              45.059289
            ],
            [
              -100.281577,
              45.033404
            ],
            [
              -100.27965,
              45.019558
            ],
            [
              -100.289539,
              45.014459
            ],
            [
              -100.315817,
              45.015629
            ],
            [
              -100.363178,
              45.029104
            ],
            [
              -100.390011,
              45.027166
            ],
            [
              -100.408379,
              45.011815
            ],
            [
              -100.423918,
              44.990006
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "111",
      "COUNTYNS": "01266977",
      "AFFGEOID": "0500000US46111",
      "GEOID": "46111",
      "NAME": "Sanborn",
      "LSAD": "06",
      "ALAND": 1474586446,
      "AWATER": 2938339,
      "County_state": "Sanborn,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.332042,
              44.19662
            ],
            [
              -98.331508,
              43.937708
            ],
            [
              -98.331484,
              43.850988
            ],
            [
              -98.325242,
              43.851058
            ],
            [
              -97.967279,
              43.850686
            ],
            [
              -97.85111,
              43.849857
            ],
            [
              -97.849492,
              44.195235
            ],
            [
              -97.85366,
              44.195233
            ],
            [
              -98.332042,
              44.19662
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "121",
      "COUNTYNS": "01266989",
      "AFFGEOID": "0500000US46121",
      "GEOID": "46121",
      "NAME": "Todd",
      "LSAD": "06",
      "ALAND": 3596445663,
      "AWATER": 5854544,
      "County_state": "Todd,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.228234,
              43.389185
            ],
            [
              -101.22800834159801,
              42.9978753513416
            ],
            [
              -101.226853,
              42.997896
            ],
            [
              -101.226494,
              42.997901
            ],
            [
              -101.043147,
              42.99796
            ],
            [
              -101.000429,
              42.99753
            ],
            [
              -100.96419,
              42.997886
            ],
            [
              -100.958365,
              42.997796
            ],
            [
              -100.906714,
              42.99791
            ],
            [
              -100.887898,
              42.997881
            ],
            [
              -100.867473,
              42.998266
            ],
            [
              -100.631728,
              42.998092
            ],
            [
              -100.625414,
              42.998584
            ],
            [
              -100.553131,
              42.998721
            ],
            [
              -100.544018,
              42.998795
            ],
            [
              -100.534335,
              42.999017
            ],
            [
              -100.472742,
              42.999288
            ],
            [
              -100.355406,
              42.99876
            ],
            [
              -100.349548,
              42.99874
            ],
            [
              -100.283713,
              42.998767
            ],
            [
              -100.277793,
              42.998674
            ],
            [
              -100.198434,
              42.998542
            ],
            [
              -100.198412688285,
              42.9985420503464
            ],
            [
              -100.198784,
              43.128528
            ],
            [
              -100.21385,
              43.128388
            ],
            [
              -100.214221,
              43.390321
            ],
            [
              -101.040718,
              43.389066
            ],
            [
              -101.228234,
              43.389185
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "105",
      "COUNTYNS": "01266990",
      "AFFGEOID": "0500000US46105",
      "GEOID": "46105",
      "NAME": "Perkins",
      "LSAD": "06",
      "ALAND": 7434503583,
      "AWATER": 50539581,
      "County_state": "Perkins,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.957303,
              45.126256
            ],
            [
              -102.957371,
              45.039569
            ],
            [
              -101.99999,
              45.038274
            ],
            [
              -101.99979,
              45.472414
            ],
            [
              -102.000678897073,
              45.944515040647495
            ],
            [
              -102.06093,
              45.944622
            ],
            [
              -102.085122,
              45.944642
            ],
            [
              -102.087555,
              45.944598
            ],
            [
              -102.124628,
              45.944813
            ],
            [
              -102.125429,
              45.944652
            ],
            [
              -102.135269,
              45.944586
            ],
            [
              -102.145356,
              45.944659
            ],
            [
              -102.156393,
              45.944663
            ],
            [
              -102.157965,
              45.944641
            ],
            [
              -102.159439,
              45.944641
            ],
            [
              -102.176698,
              45.944622
            ],
            [
              -102.176993,
              45.944622
            ],
            [
              -102.217867,
              45.944711
            ],
            [
              -102.32823,
              45.944806
            ],
            [
              -102.353384,
              45.944984
            ],
            [
              -102.354283,
              45.944901
            ],
            [
              -102.392696,
              45.944951
            ],
            [
              -102.392767,
              45.944979
            ],
            [
              -102.396359,
              45.944916
            ],
            [
              -102.398575,
              45.944868
            ],
            [
              -102.406176,
              45.944997
            ],
            [
              -102.410346,
              45.945079
            ],
            [
              -102.420173,
              45.94507
            ],
            [
              -102.425358,
              45.94499
            ],
            [
              -102.425397,
              45.945041
            ],
            [
              -102.446419,
              45.945083
            ],
            [
              -102.459586,
              45.945081
            ],
            [
              -102.467563,
              45.945159
            ],
            [
              -102.476024,
              45.945183
            ],
            [
              -102.550947,
              45.945015
            ],
            [
              -102.558579,
              45.945129
            ],
            [
              -102.642555,
              45.945404
            ],
            [
              -102.65162,
              45.94545
            ],
            [
              -102.666684,
              45.945307
            ],
            [
              -102.672474,
              45.945244
            ],
            [
              -102.674077,
              45.945274
            ],
            [
              -102.704871,
              45.945072
            ],
            [
              -102.880252,
              45.945069
            ],
            [
              -102.920482,
              45.945038
            ],
            [
              -102.942069771231,
              45.945091798392696
            ],
            [
              -102.942447,
              45.732831
            ],
            [
              -102.950118,
              45.386329
            ],
            [
              -102.957281,
              45.212851
            ],
            [
              -102.957303,
              45.126256
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "065",
      "COUNTYNS": "01265774",
      "AFFGEOID": "0500000US46065",
      "GEOID": "46065",
      "NAME": "Hughes",
      "LSAD": "06",
      "ALAND": 1920594002,
      "AWATER": 153254264,
      "County_state": "Hughes,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.575796,
              44.491713
            ],
            [
              -100.580034,
              44.461842
            ],
            [
              -100.551396,
              44.44552
            ],
            [
              -100.536136,
              44.444618
            ],
            [
              -100.474868,
              44.460367
            ],
            [
              -100.410258,
              44.45786
            ],
            [
              -100.386376,
              44.440008
            ],
            [
              -100.373836,
              44.382887
            ],
            [
              -100.36539,
              44.355524
            ],
            [
              -100.336361,
              44.343021
            ],
            [
              -100.23247,
              44.325557
            ],
            [
              -100.192032,
              44.322619
            ],
            [
              -100.165704,
              44.324633
            ],
            [
              -100.08533,
              44.308451
            ],
            [
              -100.040628,
              44.286354
            ],
            [
              -100.015817,
              44.280787
            ],
            [
              -99.984421,
              44.268338
            ],
            [
              -99.960573,
              44.264512
            ],
            [
              -99.92303,
              44.266291
            ],
            [
              -99.903449,
              44.25827
            ],
            [
              -99.892101,
              44.241881
            ],
            [
              -99.906162,
              44.225614
            ],
            [
              -99.934803,
              44.206095
            ],
            [
              -99.938217,
              44.195195
            ],
            [
              -99.910322,
              44.154638
            ],
            [
              -99.884485,
              44.129582
            ],
            [
              -99.860349,
              44.123638
            ],
            [
              -99.768681,
              44.135743
            ],
            [
              -99.750392,
              44.132819
            ],
            [
              -99.654536,
              44.101689
            ],
            [
              -99.629573,
              44.097985
            ],
            [
              -99.612249,
              44.103694
            ],
            [
              -99.598767,
              44.123778
            ],
            [
              -99.610311,
              44.148887
            ],
            [
              -99.635067,
              44.162114
            ],
            [
              -99.657972,
              44.168646
            ],
            [
              -99.695752,
              44.172375
            ],
            [
              -99.710694,
              44.184581
            ],
            [
              -99.710264,
              44.193197
            ],
            [
              -99.684033,
              44.21446
            ],
            [
              -99.663508,
              44.217517
            ],
            [
              -99.66367,
              44.548089
            ],
            [
              -99.676398,
              44.548111
            ],
            [
              -100.526498,
              44.547422
            ],
            [
              -100.516682,
              44.534449
            ],
            [
              -100.531767,
              44.515114
            ],
            [
              -100.553448,
              44.508979
            ],
            [
              -100.575796,
              44.491713
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "033",
      "COUNTYNS": "01265788",
      "AFFGEOID": "0500000US46033",
      "GEOID": "46033",
      "NAME": "Custer",
      "LSAD": "06",
      "ALAND": 4032473668,
      "AWATER": 5481079,
      "County_state": "Custer,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.055487996592,
              43.853475996615394
            ],
            [
              -104.05517607847001,
              43.761633042195896
            ],
            [
              -104.055138,
              43.750421
            ],
            [
              -104.055133,
              43.747105
            ],
            [
              -104.054902,
              43.583852
            ],
            [
              -104.054885,
              43.583512
            ],
            [
              -104.05484,
              43.579368
            ],
            [
              -104.055032,
              43.558603
            ],
            [
              -104.05478713378501,
              43.503327935732
            ],
            [
              -104.054786,
              43.503072
            ],
            [
              -104.05477918835702,
              43.4778151089155
            ],
            [
              -103.148287,
              43.476844
            ],
            [
              -103.000913,
              43.476848
            ],
            [
              -103.001017,
              43.605816
            ],
            [
              -102.974475,
              43.618723
            ],
            [
              -102.958292,
              43.613532
            ],
            [
              -102.932566,
              43.618706
            ],
            [
              -102.922265,
              43.639841
            ],
            [
              -102.919549,
              43.661217
            ],
            [
              -102.9025,
              43.674888
            ],
            [
              -102.871727,
              43.666985
            ],
            [
              -102.8605,
              43.658803
            ],
            [
              -102.846772,
              43.665352
            ],
            [
              -102.831869,
              43.65698
            ],
            [
              -102.813752,
              43.667592
            ],
            [
              -102.809398,
              43.68756
            ],
            [
              -102.817114,
              43.689658
            ],
            [
              -102.800896,
              43.699555
            ],
            [
              -102.791407,
              43.721843
            ],
            [
              -102.777811,
              43.721381
            ],
            [
              -102.738904,
              43.769746
            ],
            [
              -102.695526,
              43.79815
            ],
            [
              -102.700865,
              43.811976
            ],
            [
              -102.687633,
              43.855597
            ],
            [
              -103.375467,
              43.856638
            ],
            [
              -104.055487996592,
              43.853475996615394
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "015",
      "COUNTYNS": "01265768",
      "AFFGEOID": "0500000US46015",
      "GEOID": "46015",
      "NAME": "Brule",
      "LSAD": "06",
      "ALAND": 2116643860,
      "AWATER": 75721265,
      "County_state": "Brule,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.469097,
              43.672803
            ],
            [
              -99.458809,
              43.6558
            ],
            [
              -99.429398,
              43.651979
            ],
            [
              -99.391342,
              43.658432
            ],
            [
              -99.375378,
              43.656869
            ],
            [
              -99.355636,
              43.642347
            ],
            [
              -99.343456,
              43.615965
            ],
            [
              -99.339601,
              43.580929
            ],
            [
              -99.323765,
              43.561131
            ],
            [
              -99.283342,
              43.531147
            ],
            [
              -99.282777,
              43.518315
            ],
            [
              -99.297882,
              43.499856
            ],
            [
              -98.794588,
              43.499187
            ],
            [
              -98.796965,
              43.848575
            ],
            [
              -98.806788,
              43.848487
            ],
            [
              -98.807771,
              43.935223
            ],
            [
              -98.926997,
              43.935143
            ],
            [
              -99.327026,
              43.933453
            ],
            [
              -99.355864,
              43.934371
            ],
            [
              -99.363183,
              43.915355
            ],
            [
              -99.352226,
              43.892312
            ],
            [
              -99.329268,
              43.882751
            ],
            [
              -99.307996,
              43.860373
            ],
            [
              -99.339977,
              43.818636
            ],
            [
              -99.366244,
              43.792432
            ],
            [
              -99.41015,
              43.774619
            ],
            [
              -99.419004,
              43.744826
            ],
            [
              -99.407712,
              43.728466
            ],
            [
              -99.406961,
              43.705277
            ],
            [
              -99.42179,
              43.696316
            ],
            [
              -99.456258,
              43.686923
            ],
            [
              -99.469097,
              43.672803
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "103",
      "COUNTYNS": "01266995",
      "AFFGEOID": "0500000US46103",
      "GEOID": "46103",
      "NAME": "Pennington",
      "LSAD": "06",
      "ALAND": 7191808178,
      "AWATER": 19906989,
      "County_state": "Pennington,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.055488,
              43.853477
            ],
            [
              -104.055487996592,
              43.853475996615394
            ],
            [
              -103.375467,
              43.856638
            ],
            [
              -102.687633,
              43.855597
            ],
            [
              -102.700865,
              43.811976
            ],
            [
              -102.695526,
              43.79815
            ],
            [
              -102.738904,
              43.769746
            ],
            [
              -102.777811,
              43.721381
            ],
            [
              -102.791407,
              43.721843
            ],
            [
              -102.800896,
              43.699555
            ],
            [
              -102.817114,
              43.689658
            ],
            [
              -102.809398,
              43.68756
            ],
            [
              -102.175231,
              43.687756
            ],
            [
              -102.153253,
              43.706182
            ],
            [
              -102.139158,
              43.700948
            ],
            [
              -102.117009,
              43.712759
            ],
            [
              -102.107435,
              43.701853
            ],
            [
              -102.112294,
              43.69232
            ],
            [
              -102.09889,
              43.685181
            ],
            [
              -102.096181,
              43.695104
            ],
            [
              -102.033477,
              43.689342
            ],
            [
              -102.037122,
              43.698785
            ],
            [
              -102.024455,
              43.713408
            ],
            [
              -102.018887,
              43.708618
            ],
            [
              -102.019147,
              43.994492
            ],
            [
              -102.007336,
              43.99453
            ],
            [
              -102.00723,
              44.343785
            ],
            [
              -102.000943,
              44.345607
            ],
            [
              -102.001068,
              44.510926
            ],
            [
              -102.037589,
              44.50374
            ],
            [
              -102.054533,
              44.483286
            ],
            [
              -102.075934,
              44.475086
            ],
            [
              -102.072334,
              44.468086
            ],
            [
              -102.094034,
              44.454686
            ],
            [
              -102.112535,
              44.435886
            ],
            [
              -102.144098,
              44.444154
            ],
            [
              -102.164179,
              44.427466
            ],
            [
              -102.197107,
              44.433997
            ],
            [
              -102.222417,
              44.443713
            ],
            [
              -102.222096,
              44.452366
            ],
            [
              -102.254603,
              44.446764
            ],
            [
              -102.282876,
              44.448626
            ],
            [
              -102.29881,
              44.430812
            ],
            [
              -102.306223,
              44.434085
            ],
            [
              -102.304559,
              44.391542
            ],
            [
              -102.300002,
              44.374986
            ],
            [
              -102.311694,
              44.363835
            ],
            [
              -102.322909,
              44.368041
            ],
            [
              -102.341635,
              44.348772
            ],
            [
              -102.342187,
              44.328957
            ],
            [
              -102.360489,
              44.298214
            ],
            [
              -102.367453,
              44.262757
            ],
            [
              -102.357246,
              44.229915
            ],
            [
              -102.372528,
              44.221772
            ],
            [
              -102.372578,
              44.209414
            ],
            [
              -102.393522,
              44.202966
            ],
            [
              -102.401074,
              44.172228
            ],
            [
              -102.387229,
              44.167985
            ],
            [
              -102.40398,
              44.15793
            ],
            [
              -102.388045,
              44.140183
            ],
            [
              -103.050557,
              44.140714
            ],
            [
              -103.080434,
              44.14075
            ],
            [
              -103.131081,
              44.140786
            ],
            [
              -103.295384,
              44.140879
            ],
            [
              -103.452453,
              44.140772
            ],
            [
              -103.589462,
              44.14067
            ],
            [
              -104.054562099418,
              44.141080971847195
            ],
            [
              -104.05495,
              43.93809
            ],
            [
              -104.055077,
              43.936535
            ],
            [
              -104.055488,
              43.853477
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "115",
      "COUNTYNS": "01266987",
      "AFFGEOID": "0500000US46115",
      "GEOID": "46115",
      "NAME": "Spink",
      "LSAD": "06",
      "ALAND": 3894132582,
      "AWATER": 16076446,
      "County_state": "Spink,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.717759,
              45.243732
            ],
            [
              -98.716498,
              44.896925
            ],
            [
              -98.705357,
              44.89687
            ],
            [
              -98.705535,
              44.808039
            ],
            [
              -98.705762,
              44.634159
            ],
            [
              -98.341856,
              44.632732
            ],
            [
              -97.977791,
              44.631602
            ],
            [
              -97.977194,
              44.892274
            ],
            [
              -97.982823,
              44.892297
            ],
            [
              -97.981792,
              45.153295
            ],
            [
              -97.981457,
              45.240415
            ],
            [
              -98.717759,
              45.243732
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "127",
      "COUNTYNS": "01265763",
      "AFFGEOID": "0500000US46127",
      "GEOID": "46127",
      "NAME": "Union",
      "LSAD": "06",
      "ALAND": 1193294712,
      "AWATER": 16562654,
      "County_state": "Union,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.805682,
              43.08367
            ],
            [
              -96.80621880764551,
              42.7041490656603
            ],
            [
              -96.801652,
              42.698774
            ],
            [
              -96.800485,
              42.692466
            ],
            [
              -96.800193,
              42.684346
            ],
            [
              -96.802178,
              42.672237
            ],
            [
              -96.800986,
              42.669758
            ],
            [
              -96.798745,
              42.668243
            ],
            [
              -96.793238,
              42.666024
            ],
            [
              -96.778182,
              42.662993
            ],
            [
              -96.76406,
              42.661985
            ],
            [
              -96.751239,
              42.66436
            ],
            [
              -96.749372,
              42.665733
            ],
            [
              -96.746949,
              42.666223
            ],
            [
              -96.73546,
              42.667164
            ],
            [
              -96.728024,
              42.666882
            ],
            [
              -96.697639,
              42.659143
            ],
            [
              -96.691269,
              42.6562
            ],
            [
              -96.687669,
              42.653126
            ],
            [
              -96.687082,
              42.652093
            ],
            [
              -96.686982,
              42.649783
            ],
            [
              -96.687788,
              42.645992
            ],
            [
              -96.689083,
              42.644081
            ],
            [
              -96.692599,
              42.64204
            ],
            [
              -96.696852,
              42.637596
            ],
            [
              -96.70729,
              42.625317
            ],
            [
              -96.709485,
              42.621932
            ],
            [
              -96.711312,
              42.617375
            ],
            [
              -96.711546,
              42.614758
            ],
            [
              -96.710995,
              42.608128
            ],
            [
              -96.7093,
              42.603753
            ],
            [
              -96.706416,
              42.599413
            ],
            [
              -96.697313,
              42.590412
            ],
            [
              -96.685746,
              42.577944
            ],
            [
              -96.681369,
              42.574486
            ],
            [
              -96.675952,
              42.5716
            ],
            [
              -96.658754,
              42.566426
            ],
            [
              -96.648135,
              42.560877
            ],
            [
              -96.643589,
              42.557604
            ],
            [
              -96.638033,
              42.55196
            ],
            [
              -96.63533,
              42.54764
            ],
            [
              -96.633321,
              42.540211
            ],
            [
              -96.633343,
              42.531984
            ],
            [
              -96.632882,
              42.528987
            ],
            [
              -96.631494,
              42.524319
            ],
            [
              -96.63149355043069,
              42.5243180024038
            ],
            [
              -96.628179,
              42.516963
            ],
            [
              -96.625958,
              42.513576
            ],
            [
              -96.611489,
              42.506088
            ],
            [
              -96.608883,
              42.505218
            ],
            [
              -96.603468,
              42.50446
            ],
            [
              -96.595992,
              42.504621
            ],
            [
              -96.591121,
              42.50541
            ],
            [
              -96.584348,
              42.507834
            ],
            [
              -96.57251,
              42.515737
            ],
            [
              -96.567896,
              42.517877
            ],
            [
              -96.557775,
              42.52038
            ],
            [
              -96.548791,
              42.520547
            ],
            [
              -96.538036,
              42.518131
            ],
            [
              -96.531616,
              42.51517
            ],
            [
              -96.528753,
              42.513273
            ],
            [
              -96.525142,
              42.510234
            ],
            [
              -96.520683,
              42.504761
            ],
            [
              -96.518752,
              42.500839
            ],
            [
              -96.517557,
              42.496902
            ],
            [
              -96.515891,
              42.49427
            ],
            [
              -96.508587,
              42.486691
            ],
            [
              -96.505704,
              42.484723
            ],
            [
              -96.501321,
              42.482749
            ],
            [
              -96.489497,
              42.480112
            ],
            [
              -96.478792,
              42.479635
            ],
            [
              -96.475565,
              42.480036
            ],
            [
              -96.465671,
              42.483132
            ],
            [
              -96.459709,
              42.486037
            ],
            [
              -96.445508,
              42.49063
            ],
            [
              -96.456348,
              42.492478
            ],
            [
              -96.473339,
              42.503537
            ],
            [
              -96.477454,
              42.509589
            ],
            [
              -96.479384,
              42.511138
            ],
            [
              -96.483592,
              42.510345
            ],
            [
              -96.490089,
              42.512441
            ],
            [
              -96.49297,
              42.517282
            ],
            [
              -96.490802,
              42.520331
            ],
            [
              -96.479909,
              42.524195
            ],
            [
              -96.479009,
              42.526395
            ],
            [
              -96.479809,
              42.529595
            ],
            [
              -96.477709,
              42.535595
            ],
            [
              -96.476962,
              42.546434
            ],
            [
              -96.476952,
              42.556079
            ],
            [
              -96.494699,
              42.556745
            ],
            [
              -96.498997,
              42.560832
            ],
            [
              -96.489328,
              42.5708
            ],
            [
              -96.486855,
              42.572198
            ],
            [
              -96.485937,
              42.573524
            ],
            [
              -96.485796,
              42.575001
            ],
            [
              -96.486606,
              42.576062
            ],
            [
              -96.491402,
              42.577023
            ],
            [
              -96.49545,
              42.579474
            ],
            [
              -96.496066,
              42.580872
            ],
            [
              -96.49557,
              42.582722
            ],
            [
              -96.494676,
              42.584028
            ],
            [
              -96.494777,
              42.585741
            ],
            [
              -96.496792,
              42.587655
            ],
            [
              -96.499885,
              42.588539
            ],
            [
              -96.501037,
              42.589247
            ],
            [
              -96.501434,
              42.59061
            ],
            [
              -96.500243,
              42.592731
            ],
            [
              -96.500183,
              42.594106
            ],
            [
              -96.504654,
              42.605001
            ],
            [
              -96.509468,
              42.61273
            ],
            [
              -96.515918,
              42.624994
            ],
            [
              -96.51535,
              42.627645
            ],
            [
              -96.516338,
              42.630435
            ],
            [
              -96.526766,
              42.641184
            ],
            [
              -96.533701,
              42.643541
            ],
            [
              -96.537881,
              42.646446
            ],
            [
              -96.538468,
              42.648092
            ],
            [
              -96.5376,
              42.652161
            ],
            [
              -96.537877,
              42.655431
            ],
            [
              -96.542366,
              42.660736
            ],
            [
              -96.543698,
              42.661377
            ],
            [
              -96.546827,
              42.661491
            ],
            [
              -96.56055,
              42.669198
            ],
            [
              -96.566684,
              42.675942
            ],
            [
              -96.568078,
              42.676241
            ],
            [
              -96.575299,
              42.682665
            ],
            [
              -96.58562,
              42.687076
            ],
            [
              -96.591602,
              42.688081
            ],
            [
              -96.596405,
              42.688514
            ],
            [
              -96.61017,
              42.694568
            ],
            [
              -96.619536,
              42.700189
            ],
            [
              -96.629625,
              42.705102
            ],
            [
              -96.630617,
              42.70588
            ],
            [
              -96.629777,
              42.708852
            ],
            [
              -96.627233,
              42.709947
            ],
            [
              -96.624446,
              42.714294
            ],
            [
              -96.624704,
              42.725497
            ],
            [
              -96.626317,
              42.725951
            ],
            [
              -96.631931,
              42.725086
            ],
            [
              -96.638621,
              42.734921
            ],
            [
              -96.639704,
              42.737071
            ],
            [
              -96.635886,
              42.741002
            ],
            [
              -96.632314,
              42.745641
            ],
            [
              -96.630485,
              42.750378
            ],
            [
              -96.628741,
              42.757532
            ],
            [
              -96.632212,
              42.761512
            ],
            [
              -96.633168,
              42.768325
            ],
            [
              -96.632142,
              42.770863
            ],
            [
              -96.630311,
              42.770885
            ],
            [
              -96.626406,
              42.773518
            ],
            [
              -96.621875,
              42.779255
            ],
            [
              -96.61949,
              42.784034
            ],
            [
              -96.615579,
              42.784996
            ],
            [
              -96.604559,
              42.783034
            ],
            [
              -96.603784,
              42.78372
            ],
            [
              -96.602575,
              42.787767
            ],
            [
              -96.595283,
              42.792982
            ],
            [
              -96.592493,
              42.801122
            ],
            [
              -96.590757,
              42.808255
            ],
            [
              -96.590913,
              42.808987
            ],
            [
              -96.592155,
              42.809924
            ],
            [
              -96.595664,
              42.810426
            ],
            [
              -96.596008,
              42.815044
            ],
            [
              -96.594983,
              42.815844
            ],
            [
              -96.591039,
              42.815365
            ],
            [
              -96.585699,
              42.818041
            ],
            [
              -96.584488,
              42.818979
            ],
            [
              -96.577937,
              42.827645
            ],
            [
              -96.577813,
              42.828719
            ],
            [
              -96.58238,
              42.833657
            ],
            [
              -96.581604,
              42.837521
            ],
            [
              -96.579772,
              42.838093
            ],
            [
              -96.571353,
              42.837155
            ],
            [
              -96.56284,
              42.836309
            ],
            [
              -96.560572,
              42.839373
            ],
            [
              -96.558584,
              42.839487
            ],
            [
              -96.552184,
              42.841864
            ],
            [
              -96.554203,
              42.843648
            ],
            [
              -96.554709,
              42.846142
            ],
            [
              -96.553772,
              42.847501
            ],
            [
              -96.550847,
              42.847648
            ],
            [
              -96.545502,
              42.849956
            ],
            [
              -96.544321,
              42.851282
            ],
            [
              -96.546556,
              42.857273
            ],
            [
              -96.550439,
              42.863171
            ],
            [
              -96.550469,
              42.863742
            ],
            [
              -96.549659,
              42.870281
            ],
            [
              -96.547327,
              42.87371
            ],
            [
              -96.546394,
              42.874464
            ],
            [
              -96.543908,
              42.874614
            ],
            [
              -96.537851,
              42.878475
            ],
            [
              -96.538438,
              42.886111
            ],
            [
              -96.540396,
              42.888877
            ],
            [
              -96.540116,
              42.889678
            ],
            [
              -96.534395,
              42.890659
            ],
            [
              -96.52774,
              42.890588
            ],
            [
              -96.526357,
              42.891852
            ],
            [
              -96.526563,
              42.893755
            ],
            [
              -96.528886,
              42.89795
            ],
            [
              -96.536007,
              42.900901
            ],
            [
              -96.536564,
              42.905656
            ],
            [
              -96.537353873885,
              42.9087912967329
            ],
            [
              -96.537837,
              42.910709
            ],
            [
              -96.540229,
              42.918666
            ],
            [
              -96.541628,
              42.920678
            ],
            [
              -96.541689,
              42.922576
            ],
            [
              -96.541098,
              42.924496
            ],
            [
              -96.525536,
              42.935511
            ],
            [
              -96.519994,
              42.93976
            ],
            [
              -96.514888,
              42.943668
            ],
            [
              -96.510749,
              42.944397
            ],
            [
              -96.509472,
              42.945151
            ],
            [
              -96.508069,
              42.948534
            ],
            [
              -96.504857,
              42.954659
            ],
            [
              -96.500308,
              42.959391
            ],
            [
              -96.503132,
              42.968192
            ],
            [
              -96.505028,
              42.970844
            ],
            [
              -96.506148,
              42.971348
            ],
            [
              -96.510693,
              42.97126
            ],
            [
              -96.515922,
              42.972886
            ],
            [
              -96.520246,
              42.977643
            ],
            [
              -96.520773,
              42.980385
            ],
            [
              -96.516724,
              42.981458
            ],
            [
              -96.512237,
              42.985937
            ],
            [
              -96.512203,
              42.988818
            ],
            [
              -96.512886,
              42.991424
            ],
            [
              -96.509986,
              42.995126
            ],
            [
              -96.507337,
              42.996519
            ],
            [
              -96.502728,
              42.997066
            ],
            [
              -96.49782,
              42.998143
            ],
            [
              -96.496699,
              42.998807
            ],
            [
              -96.494341,
              43.001819
            ],
            [
              -96.492693,
              43.005089
            ],
            [
              -96.49167,
              43.009707
            ],
            [
              -96.494416,
              43.014551
            ],
            [
              -96.499187,
              43.019213
            ],
            [
              -96.503209,
              43.019805
            ],
            [
              -96.510995,
              43.024701
            ],
            [
              -96.511804,
              43.025799
            ],
            [
              -96.513085,
              43.028437
            ],
            [
              -96.512916,
              43.029962
            ],
            [
              -96.510802,
              43.031902
            ],
            [
              -96.509146,
              43.03668
            ],
            [
              -96.509145,
              43.037297
            ],
            [
              -96.511605,
              43.039927
            ],
            [
              -96.513873,
              43.039814
            ],
            [
              -96.515752,
              43.039388
            ],
            [
              -96.517319,
              43.040247
            ],
            [
              -96.518431,
              43.042068
            ],
            [
              -96.510256,
              43.049917
            ],
            [
              -96.508916,
              43.049985
            ],
            [
              -96.505239,
              43.048726
            ],
            [
              -96.501748,
              43.048632
            ],
            [
              -96.490365,
              43.050789
            ],
            [
              -96.488839,
              43.051475
            ],
            [
              -96.488155,
              43.054013
            ],
            [
              -96.486722,
              43.055498
            ],
            [
              -96.476905,
              43.062383
            ],
            [
              -96.473165,
              43.06355
            ],
            [
              -96.469953,
              43.062088
            ],
            [
              -96.468207,
              43.06186
            ],
            [
              -96.463094,
              43.062981
            ],
            [
              -96.46085,
              43.064033
            ],
            [
              -96.458201,
              43.067554
            ],
            [
              -96.455209,
              43.075053
            ],
            [
              -96.4541882587125,
              43.083379189414
            ],
            [
              -96.805682,
              43.08367
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "009",
      "COUNTYNS": "01266997",
      "AFFGEOID": "0500000US46009",
      "GEOID": "46009",
      "NAME": "Bon Homme",
      "LSAD": "06",
      "ALAND": 1459866820,
      "AWATER": 46518944,
      "County_state": "Bon Homme,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.162954,
              42.849817
            ],
            [
              -98.15307905341639,
              42.839065448144595
            ],
            [
              -98.14806,
              42.840013
            ],
            [
              -98.146933,
              42.839823
            ],
            [
              -98.137912,
              42.832728
            ],
            [
              -98.129038,
              42.821228
            ],
            [
              -98.127489,
              42.820127
            ],
            [
              -98.107688,
              42.810633
            ],
            [
              -98.1047,
              42.808475
            ],
            [
              -98.094574,
              42.799309
            ],
            [
              -98.087819,
              42.795789
            ],
            [
              -98.082782,
              42.794342
            ],
            [
              -98.067388,
              42.784759
            ],
            [
              -98.062913,
              42.781119
            ],
            [
              -98.061254,
              42.777954
            ],
            [
              -98.059838,
              42.772772
            ],
            [
              -98.056625,
              42.770781
            ],
            [
              -98.051624,
              42.768769
            ],
            [
              -98.044688,
              42.768029
            ],
            [
              -98.042011,
              42.767316
            ],
            [
              -98.037114,
              42.765724
            ],
            [
              -98.035034,
              42.764205
            ],
            [
              -98.017228,
              42.762411
            ],
            [
              -98.013046,
              42.762299
            ],
            [
              -98.005739,
              42.764167
            ],
            [
              -98.002532,
              42.763264
            ],
            [
              -98.000348,
              42.763256
            ],
            [
              -97.992507,
              42.765111
            ],
            [
              -97.977588,
              42.769923
            ],
            [
              -97.962044,
              42.768708
            ],
            [
              -97.953492,
              42.76904
            ],
            [
              -97.950147,
              42.769619
            ],
            [
              -97.936716,
              42.775754
            ],
            [
              -97.932962,
              42.778203
            ],
            [
              -97.921434,
              42.788352
            ],
            [
              -97.915947,
              42.789901
            ],
            [
              -97.908983,
              42.794909
            ],
            [
              -97.905001,
              42.798872
            ],
            [
              -97.89439,
              42.811682
            ],
            [
              -97.890241,
              42.815113
            ],
            [
              -97.888562,
              42.817251
            ],
            [
              -97.884864,
              42.826231
            ],
            [
              -97.879878,
              42.835395
            ],
            [
              -97.878976,
              42.843673
            ],
            [
              -97.875849,
              42.847725
            ],
            [
              -97.875651,
              42.850307
            ],
            [
              -97.876887,
              42.852663
            ],
            [
              -97.877003,
              42.854394
            ],
            [
              -97.875345,
              42.858724
            ],
            [
              -97.865695,
              42.86286
            ],
            [
              -97.857957,
              42.865093
            ],
            [
              -97.84527,
              42.867734
            ],
            [
              -97.834172,
              42.868794
            ],
            [
              -97.828496,
              42.868797
            ],
            [
              -97.825804,
              42.867532
            ],
            [
              -97.817075,
              42.861781
            ],
            [
              -97.804838301439,
              42.858842200994005
            ],
            [
              -97.801344,
              42.858003
            ],
            [
              -97.788462,
              42.853375
            ],
            [
              -97.774456,
              42.849774
            ],
            [
              -97.76473,
              42.8491
            ],
            [
              -97.753801,
              42.849012
            ],
            [
              -97.750343,
              42.849493
            ],
            [
              -97.72045,
              42.847439
            ],
            [
              -97.70103,
              42.843797
            ],
            [
              -97.686506,
              42.842435
            ],
            [
              -97.668294,
              42.843031
            ],
            [
              -97.657846,
              42.844626
            ],
            [
              -97.646719,
              42.847602
            ],
            [
              -97.6354116902609,
              42.851448785856896
            ],
            [
              -97.637496,
              43.16877
            ],
            [
              -97.991041,
              43.168533
            ],
            [
              -98.077108,
              43.16833
            ],
            [
              -98.089817,
              43.152543
            ],
            [
              -98.093353,
              43.118357
            ],
            [
              -98.103001,
              43.112682
            ],
            [
              -98.109534,
              43.087477
            ],
            [
              -98.102792,
              43.037117
            ],
            [
              -98.094931,
              43.030555
            ],
            [
              -98.110636,
              43.02067
            ],
            [
              -98.113145,
              43.001228
            ],
            [
              -98.092782,
              42.973921
            ],
            [
              -98.098853,
              42.968498
            ],
            [
              -98.106642,
              42.926252
            ],
            [
              -98.123463,
              42.910372
            ],
            [
              -98.112185,
              42.891119
            ],
            [
              -98.117047,
              42.869386
            ],
            [
              -98.139472,
              42.864744
            ],
            [
              -98.147723,
              42.849516
            ],
            [
              -98.162954,
              42.849817
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "027",
      "COUNTYNS": "01265764",
      "AFFGEOID": "0500000US46027",
      "GEOID": "46027",
      "NAME": "Clay",
      "LSAD": "06",
      "ALAND": 1067154414,
      "AWATER": 13955950,
      "County_state": "Clay,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.160543,
              43.083145
            ],
            [
              -97.160351504817,
              42.799733418734796
            ],
            [
              -97.150763,
              42.795566
            ],
            [
              -97.144595,
              42.790113
            ],
            [
              -97.138216,
              42.783428
            ],
            [
              -97.137028,
              42.780963
            ],
            [
              -97.137101,
              42.778932
            ],
            [
              -97.134461,
              42.774494
            ],
            [
              -97.131331,
              42.771929
            ],
            [
              -97.111622,
              42.76939
            ],
            [
              -97.101265,
              42.769697
            ],
            [
              -97.096128,
              42.76934
            ],
            [
              -97.085463,
              42.770061
            ],
            [
              -97.079356,
              42.771406
            ],
            [
              -97.071849,
              42.772305
            ],
            [
              -97.065592,
              42.772189
            ],
            [
              -97.05218,
              42.770187
            ],
            [
              -97.033229,
              42.765904
            ],
            [
              -97.030189,
              42.763712
            ],
            [
              -97.02485,
              42.76243
            ],
            [
              -97.0158249637164,
              42.761599065501095
            ],
            [
              -96.99282,
              42.759481
            ],
            [
              -96.982197,
              42.760554
            ],
            [
              -96.97912,
              42.76009
            ],
            [
              -96.975339,
              42.758321
            ],
            [
              -96.96888,
              42.754278
            ],
            [
              -96.96123,
              42.740623
            ],
            [
              -96.960866,
              42.739089
            ],
            [
              -96.961291,
              42.736569
            ],
            [
              -96.965833,
              42.727096
            ],
            [
              -96.965679,
              42.724532
            ],
            [
              -96.964776,
              42.722455
            ],
            [
              -96.963531,
              42.720643
            ],
            [
              -96.961576,
              42.719841
            ],
            [
              -96.955862,
              42.719178
            ],
            [
              -96.948902,
              42.719465
            ],
            [
              -96.941111,
              42.721569
            ],
            [
              -96.936773,
              42.723428
            ],
            [
              -96.930247,
              42.726441
            ],
            [
              -96.924156,
              42.730327
            ],
            [
              -96.920494,
              42.731432
            ],
            [
              -96.906797,
              42.7338
            ],
            [
              -96.886845,
              42.725222
            ],
            [
              -96.872789,
              42.724096
            ],
            [
              -96.860436,
              42.720797
            ],
            [
              -96.849956,
              42.715034
            ],
            [
              -96.843419,
              42.712024
            ],
            [
              -96.829554,
              42.708441
            ],
            [
              -96.819452,
              42.707774
            ],
            [
              -96.813148,
              42.706397
            ],
            [
              -96.806223,
              42.704154
            ],
            [
              -96.80621880764551,
              42.7041490656603
            ],
            [
              -96.805682,
              43.08367
            ],
            [
              -96.924142,
              43.083733
            ],
            [
              -97.160543,
              43.083145
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "051",
      "COUNTYNS": "01265782",
      "AFFGEOID": "0500000US46051",
      "GEOID": "46051",
      "NAME": "Grant",
      "LSAD": "06",
      "ALAND": 1764949271,
      "AWATER": 15753610,
      "County_state": "Grant,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.226244,
              45.297647
            ],
            [
              -97.226281,
              45.151826
            ],
            [
              -96.883948,
              45.150224
            ],
            [
              -96.882345,
              44.97687
            ],
            [
              -96.4520920423924,
              44.9774935816171
            ],
            [
              -96.45224,
              45.042347
            ],
            [
              -96.452177,
              45.050185
            ],
            [
              -96.45221,
              45.051602
            ],
            [
              -96.452219,
              45.093836
            ],
            [
              -96.452026,
              45.095138
            ],
            [
              -96.452418,
              45.122677
            ],
            [
              -96.452353,
              45.124071
            ],
            [
              -96.452304,
              45.178563
            ],
            [
              -96.452162,
              45.203109
            ],
            [
              -96.452152,
              45.204849
            ],
            [
              -96.452315,
              45.208986
            ],
            [
              -96.452947585722,
              45.268924993818395
            ],
            [
              -96.452949,
              45.269059
            ],
            [
              -96.452791,
              45.28428
            ],
            [
              -96.453067,
              45.298115
            ],
            [
              -96.454094,
              45.301546
            ],
            [
              -96.456941,
              45.303652
            ],
            [
              -96.457781,
              45.30761
            ],
            [
              -96.46191,
              45.313884
            ],
            [
              -96.466644,
              45.317162
            ],
            [
              -96.468027,
              45.318619
            ],
            [
              -96.468756,
              45.320564
            ],
            [
              -96.469246,
              45.324941
            ],
            [
              -96.47038677579108,
              45.3266054662554
            ],
            [
              -96.992946,
              45.32688
            ],
            [
              -97.007543,
              45.296866
            ],
            [
              -97.226244,
              45.297647
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "041",
      "COUNTYNS": "01266994",
      "AFFGEOID": "0500000US46041",
      "GEOID": "46041",
      "NAME": "Dewey",
      "LSAD": "06",
      "ALAND": 5963430685,
      "AWATER": 369819592,
      "County_state": "Dewey,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.500979,
              45.038336
            ],
            [
              -101.500512,
              44.99378
            ],
            [
              -101.136162,
              44.994074
            ],
            [
              -101.136838,
              44.747126
            ],
            [
              -101.083243,
              44.738433
            ],
            [
              -101.055546,
              44.740147
            ],
            [
              -101.022611,
              44.724153
            ],
            [
              -100.995667,
              44.739166
            ],
            [
              -100.98771,
              44.731961
            ],
            [
              -100.975379,
              44.751371
            ],
            [
              -100.954962,
              44.754849
            ],
            [
              -100.955185,
              44.765517
            ],
            [
              -100.939844,
              44.762518
            ],
            [
              -100.917193,
              44.769624
            ],
            [
              -100.910312,
              44.783737
            ],
            [
              -100.888048,
              44.769728
            ],
            [
              -100.85366,
              44.780719
            ],
            [
              -100.829795,
              44.783248
            ],
            [
              -100.799092,
              44.763784
            ],
            [
              -100.777718,
              44.772129
            ],
            [
              -100.769686,
              44.75818
            ],
            [
              -100.754877,
              44.760546
            ],
            [
              -100.753049,
              44.772718
            ],
            [
              -100.736264,
              44.774396
            ],
            [
              -100.736369,
              44.763737
            ],
            [
              -100.717644,
              44.77093
            ],
            [
              -100.729319,
              44.787905
            ],
            [
              -100.715226,
              44.82118
            ],
            [
              -100.702232,
              44.828371
            ],
            [
              -100.676387,
              44.823162
            ],
            [
              -100.641741,
              44.791844
            ],
            [
              -100.609036,
              44.769428
            ],
            [
              -100.543484,
              44.761087
            ],
            [
              -100.529636,
              44.766301
            ],
            [
              -100.512518,
              44.785634
            ],
            [
              -100.475132,
              44.816432
            ],
            [
              -100.418682,
              44.843366
            ],
            [
              -100.402721,
              44.856843
            ],
            [
              -100.393004,
              44.874983
            ],
            [
              -100.404753,
              44.897695
            ],
            [
              -100.41573,
              44.923545
            ],
            [
              -100.423918,
              44.990006
            ],
            [
              -100.408379,
              45.011815
            ],
            [
              -100.390011,
              45.027166
            ],
            [
              -100.363178,
              45.029104
            ],
            [
              -100.315817,
              45.015629
            ],
            [
              -100.289539,
              45.014459
            ],
            [
              -100.27965,
              45.019558
            ],
            [
              -100.281577,
              45.033404
            ],
            [
              -100.316506,
              45.059289
            ],
            [
              -100.323858,
              45.074164
            ],
            [
              -100.325451,
              45.099337
            ],
            [
              -100.290717,
              45.138377
            ],
            [
              -100.279,
              45.158894
            ],
            [
              -100.274993,
              45.198281
            ],
            [
              -100.261342,
              45.224301
            ],
            [
              -100.260223,
              45.24681
            ],
            [
              -100.268054,
              45.26111
            ],
            [
              -100.307384,
              45.291909
            ],
            [
              -100.316659,
              45.317904
            ],
            [
              -100.308234,
              45.332076
            ],
            [
              -100.282324,
              45.355322
            ],
            [
              -100.274176,
              45.381845
            ],
            [
              -100.296899,
              45.412651
            ],
            [
              -100.331472,
              45.444409
            ],
            [
              -100.341924,
              45.472904
            ],
            [
              -100.610928,
              45.47248
            ],
            [
              -101.47019,
              45.472416
            ],
            [
              -101.471065,
              45.385581
            ],
            [
              -101.486348,
              45.38559
            ],
            [
              -101.486593,
              45.038374
            ],
            [
              -101.500979,
              45.038336
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "135",
      "COUNTYNS": "01266975",
      "AFFGEOID": "0500000US46135",
      "GEOID": "46135",
      "NAME": "Yankton",
      "LSAD": "06",
      "ALAND": 1349873574,
      "AWATER": 28676624,
      "County_state": "Yankton,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.637496,
              43.16877
            ],
            [
              -97.6354116902609,
              42.851448785856896
            ],
            [
              -97.620276,
              42.856598
            ],
            [
              -97.611811,
              42.858367
            ],
            [
              -97.603762,
              42.858329
            ],
            [
              -97.59926,
              42.856229
            ],
            [
              -97.591916,
              42.853837
            ],
            [
              -97.574551,
              42.849653
            ],
            [
              -97.561928,
              42.847552
            ],
            [
              -97.547473,
              42.848028
            ],
            [
              -97.531867,
              42.850105
            ],
            [
              -97.515948,
              42.853752
            ],
            [
              -97.504847,
              42.858477
            ],
            [
              -97.500341,
              42.85722
            ],
            [
              -97.499088,
              42.855197
            ],
            [
              -97.49623,
              42.853231
            ],
            [
              -97.49149,
              42.851625
            ],
            [
              -97.484921,
              42.850368
            ],
            [
              -97.470529,
              42.850455
            ],
            [
              -97.461666,
              42.849176
            ],
            [
              -97.458772,
              42.848322
            ],
            [
              -97.456383,
              42.846937
            ],
            [
              -97.452177,
              42.846048
            ],
            [
              -97.442279,
              42.846224
            ],
            [
              -97.439114,
              42.84711
            ],
            [
              -97.431951,
              42.851542
            ],
            [
              -97.425543,
              42.856658
            ],
            [
              -97.425087,
              42.858221
            ],
            [
              -97.42319,
              42.861168
            ],
            [
              -97.417066,
              42.865918
            ],
            [
              -97.413422,
              42.867351
            ],
            [
              -97.408315,
              42.868334
            ],
            [
              -97.404442,
              42.86775
            ],
            [
              -97.399303,
              42.864835
            ],
            [
              -97.393966,
              42.86425
            ],
            [
              -97.376695,
              42.865195
            ],
            [
              -97.375337,
              42.862991
            ],
            [
              -97.368643,
              42.858419
            ],
            [
              -97.361784,
              42.855123
            ],
            [
              -97.359569,
              42.854816
            ],
            [
              -97.341181,
              42.855882
            ],
            [
              -97.336156,
              42.856802
            ],
            [
              -97.330749,
              42.858406
            ],
            [
              -97.328511,
              42.859501
            ],
            [
              -97.326348,
              42.861289
            ],
            [
              -97.324457,
              42.861998
            ],
            [
              -97.318066,
              42.863247
            ],
            [
              -97.311091,
              42.865821
            ],
            [
              -97.308853,
              42.867307
            ],
            [
              -97.306677,
              42.867604
            ],
            [
              -97.302075,
              42.86566
            ],
            [
              -97.289859,
              42.855499
            ],
            [
              -97.267946,
              42.852583
            ],
            [
              -97.256752,
              42.853913
            ],
            [
              -97.251764,
              42.855432
            ],
            [
              -97.248556,
              42.855386
            ],
            [
              -97.237868,
              42.853139
            ],
            [
              -97.231929,
              42.851335
            ],
            [
              -97.218825,
              42.845848
            ],
            [
              -97.217411,
              42.843519
            ],
            [
              -97.218269,
              42.829561
            ],
            [
              -97.21783,
              42.827766
            ],
            [
              -97.215059,
              42.822977
            ],
            [
              -97.213957,
              42.820143
            ],
            [
              -97.213084,
              42.813007
            ],
            [
              -97.211654,
              42.810684
            ],
            [
              -97.210126,
              42.809296
            ],
            [
              -97.204726,
              42.806505
            ],
            [
              -97.200431,
              42.805485
            ],
            [
              -97.1876,
              42.804835
            ],
            [
              -97.178488,
              42.80323
            ],
            [
              -97.172083,
              42.802925
            ],
            [
              -97.166978,
              42.802087
            ],
            [
              -97.163857,
              42.801257
            ],
            [
              -97.160351504817,
              42.799733418734796
            ],
            [
              -97.160543,
              43.083145
            ],
            [
              -97.160544,
              43.169978
            ],
            [
              -97.399191,
              43.169419
            ],
            [
              -97.637496,
              43.16877
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "071",
      "COUNTYNS": "01265794",
      "AFFGEOID": "0500000US46071",
      "GEOID": "46071",
      "NAME": "Jackson",
      "LSAD": "06",
      "ALAND": 4827418440,
      "AWATER": 19106241,
      "County_state": "Jackson,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.139158,
              43.700948
            ],
            [
              -102.139051,
              43.476676
            ],
            [
              -102.111131,
              43.476834
            ],
            [
              -102.110819,
              43.389902
            ],
            [
              -101.228234,
              43.389185
            ],
            [
              -101.228133,
              43.789
            ],
            [
              -101.217768,
              43.803847
            ],
            [
              -101.206008,
              43.812109
            ],
            [
              -101.189335,
              43.802872
            ],
            [
              -101.180599,
              43.818372
            ],
            [
              -101.171728,
              43.816229
            ],
            [
              -101.161493,
              43.830032
            ],
            [
              -101.155644,
              43.816748
            ],
            [
              -101.13439,
              43.830598
            ],
            [
              -101.13794,
              43.841321
            ],
            [
              -101.124837,
              43.844332
            ],
            [
              -101.107095,
              43.836743
            ],
            [
              -101.107966,
              43.846174
            ],
            [
              -101.087093,
              43.849455
            ],
            [
              -101.088371,
              43.842601
            ],
            [
              -101.064326,
              43.84109
            ],
            [
              -101.064068,
              43.994929
            ],
            [
              -101.968081,
              43.994483
            ],
            [
              -101.996224,
              43.994854
            ],
            [
              -102.007336,
              43.99453
            ],
            [
              -102.019147,
              43.994492
            ],
            [
              -102.018887,
              43.708618
            ],
            [
              -102.024455,
              43.713408
            ],
            [
              -102.037122,
              43.698785
            ],
            [
              -102.033477,
              43.689342
            ],
            [
              -102.096181,
              43.695104
            ],
            [
              -102.09889,
              43.685181
            ],
            [
              -102.112294,
              43.69232
            ],
            [
              -102.107435,
              43.701853
            ],
            [
              -102.117009,
              43.712759
            ],
            [
              -102.139158,
              43.700948
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "083",
      "COUNTYNS": "01265769",
      "AFFGEOID": "0500000US46083",
      "GEOID": "46083",
      "NAME": "Lincoln",
      "LSAD": "06",
      "ALAND": 1495182117,
      "AWATER": 1179561,
      "County_state": "Lincoln,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.924841,
              43.500007
            ],
            [
              -96.924568,
              43.257934
            ],
            [
              -96.924142,
              43.083733
            ],
            [
              -96.805682,
              43.08367
            ],
            [
              -96.4541882587125,
              43.083379189414
            ],
            [
              -96.454088,
              43.084197
            ],
            [
              -96.454526,
              43.086826
            ],
            [
              -96.455337,
              43.088129
            ],
            [
              -96.462636,
              43.089614
            ],
            [
              -96.462855,
              43.091419
            ],
            [
              -96.460516,
              43.09494
            ],
            [
              -96.451877,
              43.100474
            ],
            [
              -96.448134,
              43.104452
            ],
            [
              -96.439335,
              43.113916
            ],
            [
              -96.436589,
              43.120842
            ],
            [
              -96.439615,
              43.121963
            ],
            [
              -96.440801,
              43.123129
            ],
            [
              -96.441644,
              43.124687
            ],
            [
              -96.442711,
              43.128841
            ],
            [
              -96.443431,
              43.133825
            ],
            [
              -96.450361,
              43.142237
            ],
            [
              -96.455544,
              43.144157
            ],
            [
              -96.458854,
              43.143356
            ],
            [
              -96.459978,
              43.143516
            ],
            [
              -96.465099,
              43.147515
            ],
            [
              -96.466537,
              43.150281
            ],
            [
              -96.467384,
              43.159608
            ],
            [
              -96.467292,
              43.164066
            ],
            [
              -96.464896,
              43.182034
            ],
            [
              -96.465146,
              43.182971
            ],
            [
              -96.467146,
              43.184502
            ],
            [
              -96.468802,
              43.184525
            ],
            [
              -96.472395,
              43.185644
            ],
            [
              -96.473834,
              43.189804
            ],
            [
              -96.473777,
              43.198766
            ],
            [
              -96.470781,
              43.205099
            ],
            [
              -96.470626,
              43.207225
            ],
            [
              -96.472158,
              43.209534
            ],
            [
              -96.474912,
              43.217351
            ],
            [
              -96.475571,
              43.221054
            ],
            [
              -96.476697,
              43.222014
            ],
            [
              -96.485264,
              43.224183
            ],
            [
              -96.496454,
              43.223652
            ],
            [
              -96.500759,
              43.220767
            ],
            [
              -96.512458,
              43.218556
            ],
            [
              -96.519273,
              43.21769
            ],
            [
              -96.520961,
              43.21824
            ],
            [
              -96.522084,
              43.22096
            ],
            [
              -96.526865,
              43.224071
            ],
            [
              -96.535741,
              43.22764
            ],
            [
              -96.540088,
              43.225698
            ],
            [
              -96.544902,
              43.225928
            ],
            [
              -96.548184,
              43.226912
            ],
            [
              -96.554937,
              43.226775
            ],
            [
              -96.556313,
              43.226135
            ],
            [
              -96.557317,
              43.224778
            ],
            [
              -96.558995,
              43.224126
            ],
            [
              -96.56044,
              43.224219
            ],
            [
              -96.568505,
              43.231554
            ],
            [
              -96.571194,
              43.238961
            ],
            [
              -96.565253,
              43.244241
            ],
            [
              -96.559186,
              43.245155
            ],
            [
              -96.552963,
              43.247281
            ],
            [
              -96.55203,
              43.251117
            ],
            [
              -96.552591,
              43.257769
            ],
            [
              -96.553217,
              43.259141
            ],
            [
              -96.554965,
              43.259999
            ],
            [
              -96.5549681830449,
              43.2599975664391
            ],
            [
              -96.55697,
              43.259096
            ],
            [
              -96.560099,
              43.259279
            ],
            [
              -96.564165,
              43.260239
            ],
            [
              -96.568576,
              43.262662
            ],
            [
              -96.576804,
              43.268308
            ],
            [
              -96.582904,
              43.26769
            ],
            [
              -96.58522,
              43.268878
            ],
            [
              -96.586317,
              43.274319
            ],
            [
              -96.583533,
              43.276879
            ],
            [
              -96.582939,
              43.276536
            ],
            [
              -96.582876,
              43.274594
            ],
            [
              -96.580904,
              43.2748
            ],
            [
              -96.577588,
              43.2788
            ],
            [
              -96.578823,
              43.291095
            ],
            [
              -96.579094,
              43.293797
            ],
            [
              -96.579478,
              43.29511
            ],
            [
              -96.580409,
              43.295854
            ],
            [
              -96.581052,
              43.297118
            ],
            [
              -96.580346,
              43.298204
            ],
            [
              -96.573556,
              43.29917
            ],
            [
              -96.571646,
              43.298187
            ],
            [
              -96.570707,
              43.296701
            ],
            [
              -96.56911,
              43.295535
            ],
            [
              -96.56429,
              43.294804
            ],
            [
              -96.563523,
              43.294804
            ],
            [
              -96.555246,
              43.294803
            ],
            [
              -96.553087,
              43.29286
            ],
            [
              -96.541037,
              43.295556
            ],
            [
              -96.530392,
              43.300034
            ],
            [
              -96.526004,
              43.309999
            ],
            [
              -96.525564,
              43.312467
            ],
            [
              -96.528817,
              43.316561
            ],
            [
              -96.533101,
              43.328587
            ],
            [
              -96.534913,
              43.336473
            ],
            [
              -96.531905,
              43.33869
            ],
            [
              -96.524289,
              43.347214
            ],
            [
              -96.524476,
              43.348151
            ],
            [
              -96.52551,
              43.348335
            ],
            [
              -96.526635,
              43.351833
            ],
            [
              -96.527223,
              43.362257
            ],
            [
              -96.527345,
              43.368109
            ],
            [
              -96.526467,
              43.368314
            ],
            [
              -96.522203,
              43.371947
            ],
            [
              -96.521323,
              43.374607
            ],
            [
              -96.521297,
              43.375947
            ],
            [
              -96.521572,
              43.38564
            ],
            [
              -96.524044,
              43.394762
            ],
            [
              -96.525453,
              43.396317
            ],
            [
              -96.529152,
              43.397735
            ],
            [
              -96.530124,
              43.397553
            ],
            [
              -96.530124,
              43.39641
            ],
            [
              -96.531159,
              43.39561
            ],
            [
              -96.537116,
              43.395063
            ],
            [
              -96.553008,
              43.404117
            ],
            [
              -96.557586,
              43.406792
            ],
            [
              -96.562728,
              43.412782
            ],
            [
              -96.568499,
              43.417217
            ],
            [
              -96.573579,
              43.419228
            ],
            [
              -96.570788,
              43.423755
            ],
            [
              -96.569628,
              43.427527
            ],
            [
              -96.570224,
              43.428601
            ],
            [
              -96.575181,
              43.431756
            ],
            [
              -96.581956,
              43.432212
            ],
            [
              -96.587884,
              43.431685
            ],
            [
              -96.5896197170834,
              43.4321983518958
            ],
            [
              -96.592905,
              43.43317
            ],
            [
              -96.594254,
              43.434153
            ],
            [
              -96.602608,
              43.449649
            ],
            [
              -96.60286,
              43.450907
            ],
            [
              -96.600039,
              43.45708
            ],
            [
              -96.587929,
              43.464878
            ],
            [
              -96.584603,
              43.46961
            ],
            [
              -96.585137,
              43.471141
            ],
            [
              -96.586364,
              43.478251
            ],
            [
              -96.580997,
              43.481384
            ],
            [
              -96.585049,
              43.489887
            ],
            [
              -96.586274,
              43.491099
            ],
            [
              -96.590452,
              43.494298
            ],
            [
              -96.591676,
              43.494367
            ],
            [
              -96.594722,
              43.493314
            ],
            [
              -96.598396,
              43.495074
            ],
            [
              -96.599182,
              43.496011
            ],
            [
              -96.5989289125409,
              43.5004410269423
            ],
            [
              -96.60147,
              43.500449
            ],
            [
              -96.707659,
              43.50037
            ],
            [
              -96.748026,
              43.500378
            ],
            [
              -96.788206,
              43.500303
            ],
            [
              -96.790816,
              43.500276
            ],
            [
              -96.890085,
              43.500115
            ],
            [
              -96.924841,
              43.500007
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "093",
      "COUNTYNS": "01266991",
      "AFFGEOID": "0500000US46093",
      "GEOID": "46093",
      "NAME": "Meade",
      "LSAD": "06",
      "ALAND": 8989706542,
      "AWATER": 30712698,
      "County_state": "Meade,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.569417,
              44.344246
            ],
            [
              -103.574396,
              44.256077
            ],
            [
              -103.453016,
              44.256609
            ],
            [
              -103.452543,
              44.183423
            ],
            [
              -103.452453,
              44.140772
            ],
            [
              -103.295384,
              44.140879
            ],
            [
              -103.131081,
              44.140786
            ],
            [
              -103.080434,
              44.14075
            ],
            [
              -103.050557,
              44.140714
            ],
            [
              -102.388045,
              44.140183
            ],
            [
              -102.40398,
              44.15793
            ],
            [
              -102.387229,
              44.167985
            ],
            [
              -102.401074,
              44.172228
            ],
            [
              -102.393522,
              44.202966
            ],
            [
              -102.372578,
              44.209414
            ],
            [
              -102.372528,
              44.221772
            ],
            [
              -102.357246,
              44.229915
            ],
            [
              -102.367453,
              44.262757
            ],
            [
              -102.360489,
              44.298214
            ],
            [
              -102.342187,
              44.328957
            ],
            [
              -102.341635,
              44.348772
            ],
            [
              -102.322909,
              44.368041
            ],
            [
              -102.311694,
              44.363835
            ],
            [
              -102.300002,
              44.374986
            ],
            [
              -102.304559,
              44.391542
            ],
            [
              -102.306223,
              44.434085
            ],
            [
              -102.29881,
              44.430812
            ],
            [
              -102.282876,
              44.448626
            ],
            [
              -102.254603,
              44.446764
            ],
            [
              -102.222096,
              44.452366
            ],
            [
              -102.222417,
              44.443713
            ],
            [
              -102.197107,
              44.433997
            ],
            [
              -102.164179,
              44.427466
            ],
            [
              -102.144098,
              44.444154
            ],
            [
              -102.112535,
              44.435886
            ],
            [
              -102.094034,
              44.454686
            ],
            [
              -102.072334,
              44.468086
            ],
            [
              -102.075934,
              44.475086
            ],
            [
              -102.054533,
              44.483286
            ],
            [
              -102.037589,
              44.50374
            ],
            [
              -102.001068,
              44.510926
            ],
            [
              -102.000432,
              44.511663
            ],
            [
              -101.99999,
              45.038274
            ],
            [
              -102.957371,
              45.039569
            ],
            [
              -102.958819,
              45.035189
            ],
            [
              -102.963646,
              44.690613
            ],
            [
              -102.964125,
              44.604287
            ],
            [
              -103.567653,
              44.604098
            ],
            [
              -103.569417,
              44.344246
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "129",
      "COUNTYNS": "01266973",
      "AFFGEOID": "0500000US46129",
      "GEOID": "46129",
      "NAME": "Walworth",
      "LSAD": "06",
      "ALAND": 1835347958,
      "AWATER": 93215243,
      "County_state": "Walworth,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.486561,
              45.536521
            ],
            [
              -100.479153,
              45.525103
            ],
            [
              -100.443961,
              45.509858
            ],
            [
              -100.391682,
              45.511972
            ],
            [
              -100.365258,
              45.500109
            ],
            [
              -100.341924,
              45.472904
            ],
            [
              -100.331472,
              45.444409
            ],
            [
              -100.296899,
              45.412651
            ],
            [
              -100.274176,
              45.381845
            ],
            [
              -100.282324,
              45.355322
            ],
            [
              -100.308234,
              45.332076
            ],
            [
              -100.316659,
              45.317904
            ],
            [
              -100.307384,
              45.291909
            ],
            [
              -100.268054,
              45.26111
            ],
            [
              -100.260223,
              45.24681
            ],
            [
              -99.708987,
              45.245866
            ],
            [
              -99.707705,
              45.593391
            ],
            [
              -99.717466,
              45.593428
            ],
            [
              -100.430079,
              45.594823
            ],
            [
              -100.44082,
              45.578955
            ],
            [
              -100.475064,
              45.559764
            ],
            [
              -100.486561,
              45.536521
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "075",
      "COUNTYNS": "01266981",
      "AFFGEOID": "0500000US46075",
      "GEOID": "46075",
      "NAME": "Jones",
      "LSAD": "06",
      "ALAND": 2511453379,
      "AWATER": 3147180,
      "County_state": "Jones,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.064068,
              43.994929
            ],
            [
              -101.064326,
              43.84109
            ],
            [
              -101.058317,
              43.8456
            ],
            [
              -101.057377,
              43.850105
            ],
            [
              -101.056437,
              43.854344
            ],
            [
              -101.052408,
              43.854375
            ],
            [
              -101.046776,
              43.852036
            ],
            [
              -101.039461,
              43.851202
            ],
            [
              -101.033311,
              43.854032
            ],
            [
              -101.028,
              43.85603
            ],
            [
              -101.027486,
              43.855063
            ],
            [
              -101.02579,
              43.853141
            ],
            [
              -101.027179,
              43.8495
            ],
            [
              -101.030966,
              43.847983
            ],
            [
              -101.030038,
              43.842873
            ],
            [
              -101.026421,
              43.842133
            ],
            [
              -101.020406,
              43.842651
            ],
            [
              -101.011723,
              43.842104
            ],
            [
              -101.001967,
              43.84645
            ],
            [
              -100.996204,
              43.84937
            ],
            [
              -100.985935,
              43.84534
            ],
            [
              -100.98673,
              43.841256
            ],
            [
              -100.981097,
              43.836165
            ],
            [
              -100.973888,
              43.831022
            ],
            [
              -100.965615,
              43.832419
            ],
            [
              -100.959592,
              43.833805
            ],
            [
              -100.95925,
              43.831185
            ],
            [
              -100.962138,
              43.827093
            ],
            [
              -100.968454,
              43.827891
            ],
            [
              -100.973289,
              43.825311
            ],
            [
              -100.972814,
              43.82219
            ],
            [
              -100.96814,
              43.816891
            ],
            [
              -100.96337,
              43.815811
            ],
            [
              -100.957581,
              43.816943
            ],
            [
              -100.955739,
              43.811974
            ],
            [
              -100.956262,
              43.810286
            ],
            [
              -100.950533,
              43.812089
            ],
            [
              -100.944168,
              43.817927
            ],
            [
              -100.941769,
              43.826819
            ],
            [
              -100.936344,
              43.831399
            ],
            [
              -100.927232,
              43.828999
            ],
            [
              -100.92094,
              43.824681
            ],
            [
              -100.917266,
              43.815605
            ],
            [
              -100.907462,
              43.813503
            ],
            [
              -100.901518,
              43.813729
            ],
            [
              -100.897026,
              43.80938
            ],
            [
              -100.898426,
              43.80607
            ],
            [
              -100.905426,
              43.805607
            ],
            [
              -100.907255,
              43.807385
            ],
            [
              -100.910029,
              43.803618
            ],
            [
              -100.904168,
              43.800205
            ],
            [
              -100.897512,
              43.800469
            ],
            [
              -100.894358,
              43.796656
            ],
            [
              -100.898791,
              43.796095
            ],
            [
              -100.898705,
              43.793606
            ],
            [
              -100.895377,
              43.792991
            ],
            [
              -100.882771,
              43.795424
            ],
            [
              -100.879352,
              43.801534
            ],
            [
              -100.873636,
              43.798626
            ],
            [
              -100.873664,
              43.793348
            ],
            [
              -100.875279,
              43.786826
            ],
            [
              -100.876436,
              43.782264
            ],
            [
              -100.870569,
              43.780296
            ],
            [
              -100.866807,
              43.783211
            ],
            [
              -100.85829,
              43.786513
            ],
            [
              -100.853157,
              43.78178
            ],
            [
              -100.846025,
              43.770508
            ],
            [
              -100.839854,
              43.765078
            ],
            [
              -100.840337,
              43.762145
            ],
            [
              -100.835314,
              43.762065
            ],
            [
              -100.835023,
              43.764823
            ],
            [
              -100.830888,
              43.768994
            ],
            [
              -100.823493,
              43.768373
            ],
            [
              -100.814072,
              43.766728
            ],
            [
              -100.805872,
              43.762366
            ],
            [
              -100.805788,
              43.75943
            ],
            [
              -100.803014,
              43.754153
            ],
            [
              -100.79666,
              43.754875
            ],
            [
              -100.794578,
              43.751215
            ],
            [
              -100.796117,
              43.748342
            ],
            [
              -100.801516,
              43.748949
            ],
            [
              -100.804758,
              43.746171
            ],
            [
              -100.801102,
              43.74305
            ],
            [
              -100.796507,
              43.741167
            ],
            [
              -100.79195,
              43.741153
            ],
            [
              -100.787994,
              43.740452
            ],
            [
              -100.786406,
              43.738039
            ],
            [
              -100.789523,
              43.734609
            ],
            [
              -100.791663,
              43.735019
            ],
            [
              -100.790931,
              43.732474
            ],
            [
              -100.784714,
              43.730378
            ],
            [
              -100.77927,
              43.731488
            ],
            [
              -100.779344,
              43.728924
            ],
            [
              -100.784763,
              43.727853
            ],
            [
              -100.787558,
              43.728452
            ],
            [
              -100.787072,
              43.725063
            ],
            [
              -100.779511,
              43.72406
            ],
            [
              -100.766087,
              43.732167
            ],
            [
              -100.761779,
              43.73143
            ],
            [
              -100.757241,
              43.731621
            ],
            [
              -100.755559,
              43.733664
            ],
            [
              -100.757434,
              43.736043
            ],
            [
              -100.75957,
              43.73675
            ],
            [
              -100.757195,
              43.738782
            ],
            [
              -100.753786,
              43.735881
            ],
            [
              -100.749624,
              43.734314
            ],
            [
              -100.747594,
              43.736344
            ],
            [
              -100.747541,
              43.737588
            ],
            [
              -100.744856,
              43.73674
            ],
            [
              -100.744444,
              43.733601
            ],
            [
              -100.744117,
              43.72764
            ],
            [
              -100.749423,
              43.724976
            ],
            [
              -100.74882,
              43.72056
            ],
            [
              -100.742918,
              43.718171
            ],
            [
              -100.736981,
              43.710271
            ],
            [
              -100.72889,
              43.70943
            ],
            [
              -100.727569,
              43.7143
            ],
            [
              -100.736713,
              43.716652
            ],
            [
              -100.738151,
              43.720019
            ],
            [
              -100.734473,
              43.720892
            ],
            [
              -100.731506,
              43.721481
            ],
            [
              -100.725108,
              43.719668
            ],
            [
              -100.723582,
              43.716409
            ],
            [
              -100.72293,
              43.714103
            ],
            [
              -100.720738,
              43.713792
            ],
            [
              -100.719165,
              43.715868
            ],
            [
              -100.716782,
              43.719552
            ],
            [
              -100.710546,
              43.718578
            ],
            [
              -100.703902,
              43.721861
            ],
            [
              -100.702148,
              43.725294
            ],
            [
              -100.700207,
              43.725994
            ],
            [
              -100.697193,
              43.725153
            ],
            [
              -100.696499,
              43.721744
            ],
            [
              -100.698219,
              43.720111
            ],
            [
              -100.694293,
              43.716238
            ],
            [
              -100.6899,
              43.713883
            ],
            [
              -100.685992,
              43.713039
            ],
            [
              -100.681043,
              43.711896
            ],
            [
              -100.677162,
              43.717839
            ],
            [
              -100.674475,
              43.723067
            ],
            [
              -100.666199,
              43.726827
            ],
            [
              -100.656235,
              43.730297
            ],
            [
              -100.653075,
              43.732942
            ],
            [
              -100.656091,
              43.735644
            ],
            [
              -100.660235,
              43.735498
            ],
            [
              -100.665691,
              43.734914
            ],
            [
              -100.668195,
              43.738731
            ],
            [
              -100.663256,
              43.743434
            ],
            [
              -100.655593,
              43.748001
            ],
            [
              -100.643691,
              43.749101
            ],
            [
              -100.637645,
              43.745666
            ],
            [
              -100.63782,
              43.740551
            ],
            [
              -100.640266,
              43.740039
            ],
            [
              -100.637951,
              43.738281
            ],
            [
              -100.630645,
              43.741375
            ],
            [
              -100.619225,
              43.743398
            ],
            [
              -100.611171,
              43.749962
            ],
            [
              -100.611032,
              43.758004
            ],
            [
              -100.602466,
              43.762957
            ],
            [
              -100.5956,
              43.765684
            ],
            [
              -100.592796,
              43.767652
            ],
            [
              -100.58517,
              43.768613
            ],
            [
              -100.579259,
              43.765194
            ],
            [
              -100.57296,
              43.754865
            ],
            [
              -100.561181,
              43.753451
            ],
            [
              -100.551163,
              43.75295
            ],
            [
              -100.549901,
              43.74929
            ],
            [
              -100.549873,
              43.746221
            ],
            [
              -100.553643,
              43.741265
            ],
            [
              -100.558796,
              43.742987
            ],
            [
              -100.561084,
              43.741632
            ],
            [
              -100.559473,
              43.739992
            ],
            [
              -100.556163,
              43.738724
            ],
            [
              -100.546491,
              43.736976
            ],
            [
              -100.532724,
              43.740816
            ],
            [
              -100.528048,
              43.745249
            ],
            [
              -100.518267,
              43.744166
            ],
            [
              -100.515911,
              43.738025
            ],
            [
              -100.516421,
              43.732313
            ],
            [
              -100.511307,
              43.729127
            ],
            [
              -100.503407,
              43.729726
            ],
            [
              -100.494943,
              43.733336
            ],
            [
              -100.487257,
              43.736986
            ],
            [
              -100.484996,
              43.733742
            ],
            [
              -100.479751,
              43.728903
            ],
            [
              -100.473127,
              43.728832
            ],
            [
              -100.471329,
              43.73127
            ],
            [
              -100.472175,
              43.73484
            ],
            [
              -100.467167,
              43.734489
            ],
            [
              -100.465349,
              43.7311
            ],
            [
              -100.467097,
              43.726136
            ],
            [
              -100.469747,
              43.722045
            ],
            [
              -100.466986,
              43.722837
            ],
            [
              -100.453875,
              43.721744
            ],
            [
              -100.448546,
              43.726895
            ],
            [
              -100.440736,
              43.730678
            ],
            [
              -100.435963,
              43.726316
            ],
            [
              -100.424336,
              43.72031
            ],
            [
              -100.416677,
              43.719848
            ],
            [
              -100.413319,
              43.725223
            ],
            [
              -100.415401,
              43.731902
            ],
            [
              -100.414207,
              43.735161
            ],
            [
              -100.408727,
              43.733115
            ],
            [
              -100.400416,
              43.730729
            ],
            [
              -100.388692,
              43.727048
            ],
            [
              -100.377652,
              43.73033
            ],
            [
              -100.374596,
              43.736013
            ],
            [
              -100.365397,
              43.736675
            ],
            [
              -100.357474,
              43.732975
            ],
            [
              -100.345506,
              43.720782
            ],
            [
              -100.33897,
              43.716942
            ],
            [
              -100.339397,
              43.847061
            ],
            [
              -100.366174,
              43.846978
            ],
            [
              -100.367207,
              44.168694
            ],
            [
              -101.046916,
              44.168484
            ],
            [
              -101.046786,
              43.99497
            ],
            [
              -101.064068,
              43.994929
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "063",
      "COUNTYNS": "01265792",
      "AFFGEOID": "0500000US46063",
      "GEOID": "46063",
      "NAME": "Harding",
      "LSAD": "06",
      "ALAND": 6919334579,
      "AWATER": 16956320,
      "County_state": "Harding,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.045443,
              45.94531
            ],
            [
              -104.044030081588,
              45.881978657009796
            ],
            [
              -104.04403,
              45.881975
            ],
            [
              -104.044009,
              45.871974
            ],
            [
              -104.043814,
              45.868385
            ],
            [
              -104.042597,
              45.749998
            ],
            [
              -104.041937,
              45.557915
            ],
            [
              -104.041647,
              45.550691
            ],
            [
              -104.041717,
              45.539122
            ],
            [
              -104.041145,
              45.503367
            ],
            [
              -104.041274,
              45.499994
            ],
            [
              -104.041764,
              45.490789
            ],
            [
              -104.040816,
              45.462708
            ],
            [
              -104.04041,
              45.393474
            ],
            [
              -104.040114,
              45.374214
            ],
            [
              -104.040265,
              45.345356
            ],
            [
              -104.040358,
              45.335946
            ],
            [
              -104.04013575649302,
              45.212890762041596
            ],
            [
              -103.562581,
              45.213266
            ],
            [
              -103.000648,
              45.212989
            ],
            [
              -102.987788,
              45.212542
            ],
            [
              -102.957281,
              45.212851
            ],
            [
              -102.950118,
              45.386329
            ],
            [
              -102.942447,
              45.732831
            ],
            [
              -102.942069771231,
              45.945091798392696
            ],
            [
              -102.989902,
              45.945211
            ],
            [
              -102.995345,
              45.945166
            ],
            [
              -102.99566790323101,
              45.9451674824132
            ],
            [
              -103.026058,
              45.945307
            ],
            [
              -103.047779,
              45.945335
            ],
            [
              -103.078477,
              45.945289
            ],
            [
              -103.097872,
              45.945262
            ],
            [
              -103.140939,
              45.945257
            ],
            [
              -103.161251,
              45.945309
            ],
            [
              -103.210634,
              45.945222
            ],
            [
              -103.218396,
              45.945208
            ],
            [
              -103.284092,
              45.945149
            ],
            [
              -103.284109,
              45.945152
            ],
            [
              -103.369148,
              45.945152
            ],
            [
              -103.37546,
              45.944797
            ],
            [
              -103.411325,
              45.945264
            ],
            [
              -103.41804,
              45.945186
            ],
            [
              -103.432393,
              45.945313
            ],
            [
              -103.434851,
              45.945291
            ],
            [
              -103.55871,
              45.945131
            ],
            [
              -103.577083,
              45.945283
            ],
            [
              -103.660779,
              45.945231
            ],
            [
              -103.660779,
              45.945241
            ],
            [
              -103.668479,
              45.945242
            ],
            [
              -104.045443,
              45.94531
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "031",
      "COUNTYNS": "01265811",
      "AFFGEOID": "0500000US46031",
      "GEOID": "46031",
      "NAME": "Corson",
      "LSAD": "06",
      "ALAND": 6396478456,
      "AWATER": 155287099,
      "County_state": "Corson,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.000656,
              45.944515
            ],
            [
              -102.000678897073,
              45.944515040647495
            ],
            [
              -101.99979,
              45.472414
            ],
            [
              -101.47019,
              45.472416
            ],
            [
              -100.610928,
              45.47248
            ],
            [
              -100.341924,
              45.472904
            ],
            [
              -100.365258,
              45.500109
            ],
            [
              -100.391682,
              45.511972
            ],
            [
              -100.443961,
              45.509858
            ],
            [
              -100.479153,
              45.525103
            ],
            [
              -100.486561,
              45.536521
            ],
            [
              -100.475064,
              45.559764
            ],
            [
              -100.44082,
              45.578955
            ],
            [
              -100.430079,
              45.594823
            ],
            [
              -100.416719,
              45.634652
            ],
            [
              -100.406438,
              45.650011
            ],
            [
              -100.380281,
              45.660747
            ],
            [
              -100.337249,
              45.663191
            ],
            [
              -100.317783,
              45.673462
            ],
            [
              -100.308849,
              45.689634
            ],
            [
              -100.309013,
              45.707842
            ],
            [
              -100.351209,
              45.771844
            ],
            [
              -100.375541,
              45.815951
            ],
            [
              -100.382369,
              45.838614
            ],
            [
              -100.380638,
              45.858178
            ],
            [
              -100.387036,
              45.869142
            ],
            [
              -100.417516,
              45.884006
            ],
            [
              -100.462424,
              45.919574
            ],
            [
              -100.488683,
              45.93028
            ],
            [
              -100.499353928538,
              45.9436316399083
            ],
            [
              -100.511793,
              45.943654
            ],
            [
              -100.51194899399302,
              45.9436539838471
            ],
            [
              -100.627681,
              45.943642
            ],
            [
              -100.65082,
              45.94368
            ],
            [
              -100.720865,
              45.944024
            ],
            [
              -100.750407,
              45.943649
            ],
            [
              -100.762072,
              45.943803
            ],
            [
              -100.76211,
              45.943767
            ],
            [
              -100.769751,
              45.943766
            ],
            [
              -100.890176,
              45.943861
            ],
            [
              -100.935582,
              45.943757
            ],
            [
              -100.938989,
              45.943848
            ],
            [
              -100.964411,
              45.943822
            ],
            [
              -100.976565,
              45.943864
            ],
            [
              -100.980693,
              45.944068
            ],
            [
              -101.101334,
              45.943841
            ],
            [
              -101.106826,
              45.943984
            ],
            [
              -101.142571,
              45.943841
            ],
            [
              -101.146076,
              45.943842
            ],
            [
              -101.163241,
              45.943915
            ],
            [
              -101.171074,
              45.943959
            ],
            [
              -101.175693,
              45.943983
            ],
            [
              -101.179103,
              45.943896
            ],
            [
              -101.203787,
              45.943895
            ],
            [
              -101.224006,
              45.944025
            ],
            [
              -101.271524,
              45.944209
            ],
            [
              -101.287223,
              45.944107
            ],
            [
              -101.313272,
              45.944164
            ],
            [
              -101.333871,
              45.944166
            ],
            [
              -101.365283,
              45.944092
            ],
            [
              -101.37069,
              45.944198
            ],
            [
              -101.373769,
              45.944265
            ],
            [
              -101.41989,
              45.943763
            ],
            [
              -101.557276,
              45.9441
            ],
            [
              -101.562156,
              45.944237
            ],
            [
              -101.628597,
              45.944293
            ],
            [
              -101.657631,
              45.944387
            ],
            [
              -101.680574,
              45.944329
            ],
            [
              -101.681819,
              45.944444
            ],
            [
              -101.708785,
              45.944348
            ],
            [
              -101.72338,
              45.944187
            ],
            [
              -101.730069,
              45.944356
            ],
            [
              -101.758611,
              45.944478
            ],
            [
              -101.764277,
              45.944412
            ],
            [
              -101.765293,
              45.944367
            ],
            [
              -101.766177,
              45.944322
            ],
            [
              -101.790054,
              45.944442
            ],
            [
              -101.794606,
              45.944397
            ],
            [
              -101.832991,
              45.944464
            ],
            [
              -101.852642,
              45.944457
            ],
            [
              -101.886838,
              45.944559
            ],
            [
              -101.957439,
              45.944484
            ],
            [
              -101.973749,
              45.944456
            ],
            [
              -101.989501,
              45.944472
            ],
            [
              -101.992187,
              45.944471
            ],
            [
              -101.9986182554,
              45.9445558815169
            ],
            [
              -101.998703,
              45.944557
            ],
            [
              -102.000425,
              45.944581
            ],
            [
              -102.000656,
              45.944515
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "123",
      "COUNTYNS": "01265784",
      "AFFGEOID": "0500000US46123",
      "GEOID": "46123",
      "NAME": "Tripp",
      "LSAD": "06",
      "ALAND": 4176247355,
      "AWATER": 13272785,
      "County_state": "Tripp,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.230845,
              43.713856
            ],
            [
              -100.230991,
              43.477981
            ],
            [
              -100.214433,
              43.477332
            ],
            [
              -100.214221,
              43.390321
            ],
            [
              -100.21385,
              43.128388
            ],
            [
              -100.198784,
              43.128528
            ],
            [
              -100.198412688285,
              42.9985420503464
            ],
            [
              -100.19841203687501,
              42.9985420518853
            ],
            [
              -100.126896,
              42.998711
            ],
            [
              -100.126427,
              42.99871
            ],
            [
              -100.119297,
              42.998689
            ],
            [
              -100.034389,
              42.998425
            ],
            [
              -100.027815,
              42.998424
            ],
            [
              -100.004757,
              42.998392
            ],
            [
              -99.961204,
              42.998335
            ],
            [
              -99.950921,
              42.998291
            ],
            [
              -99.950411,
              42.998286
            ],
            [
              -99.927645,
              42.998113
            ],
            [
              -99.918401,
              42.998057
            ],
            [
              -99.877697,
              42.998094
            ],
            [
              -99.869885,
              42.998094
            ],
            [
              -99.859945,
              42.997962
            ],
            [
              -99.850037,
              42.998171
            ],
            [
              -99.821868,
              42.997995
            ],
            [
              -99.809373,
              42.998178
            ],
            [
              -99.803328,
              42.998064
            ],
            [
              -99.800306,
              42.997972
            ],
            [
              -99.788247,
              42.998016
            ],
            [
              -99.768524,
              42.998125
            ],
            [
              -99.743138,
              42.997912
            ],
            [
              -99.726788,
              42.997892
            ],
            [
              -99.719177,
              42.997899
            ],
            [
              -99.701446,
              42.997994
            ],
            [
              -99.699234,
              42.99788
            ],
            [
              -99.569277,
              42.997995
            ],
            [
              -99.535375,
              42.998038
            ],
            [
              -99.5340492772214,
              42.9980405812359
            ],
            [
              -99.534481,
              43.499801
            ],
            [
              -99.545205,
              43.499635
            ],
            [
              -99.572029,
              43.499868
            ],
            [
              -99.603539,
              43.499813
            ],
            [
              -99.615475,
              43.499841
            ],
            [
              -99.62351,
              43.514979
            ],
            [
              -99.623502,
              43.529182
            ],
            [
              -99.623468,
              43.545231
            ],
            [
              -99.623443,
              43.571222
            ],
            [
              -99.623437,
              43.581678
            ],
            [
              -99.623422,
              43.60875
            ],
            [
              -99.623417,
              43.643014
            ],
            [
              -99.623651,
              43.666597
            ],
            [
              -99.623803,
              43.698721
            ],
            [
              -99.62371,
              43.722922
            ],
            [
              -99.624151,
              43.741834
            ],
            [
              -99.624959,
              43.741113
            ],
            [
              -99.626347,
              43.740536
            ],
            [
              -99.628432,
              43.738963
            ],
            [
              -99.630495,
              43.737519
            ],
            [
              -99.632487,
              43.736667
            ],
            [
              -99.63529,
              43.735821
            ],
            [
              -99.637677,
              43.735303
            ],
            [
              -99.640832,
              43.735558
            ],
            [
              -99.642006,
              43.736319
            ],
            [
              -99.643442,
              43.737732
            ],
            [
              -99.64302,
              43.739653
            ],
            [
              -99.64227,
              43.741667
            ],
            [
              -99.641921,
              43.742613
            ],
            [
              -99.642128,
              43.745361
            ],
            [
              -99.641523,
              43.746859
            ],
            [
              -99.641213,
              43.749701
            ],
            [
              -99.641568,
              43.751727
            ],
            [
              -99.641826,
              43.753437
            ],
            [
              -99.643038,
              43.757035
            ],
            [
              -99.645404,
              43.760214
            ],
            [
              -99.648655,
              43.761701
            ],
            [
              -99.651915,
              43.763254
            ],
            [
              -99.656543,
              43.76604
            ],
            [
              -99.658784,
              43.76655
            ],
            [
              -99.661877,
              43.766413
            ],
            [
              -99.664085,
              43.765359
            ],
            [
              -99.666062,
              43.764732
            ],
            [
              -99.66744,
              43.763349
            ],
            [
              -99.667448,
              43.761271
            ],
            [
              -99.666725,
              43.759043
            ],
            [
              -99.666008,
              43.757681
            ],
            [
              -99.665138,
              43.755834
            ],
            [
              -99.66531,
              43.753635
            ],
            [
              -99.664542,
              43.75165
            ],
            [
              -99.664003,
              43.750248
            ],
            [
              -99.663655,
              43.748282
            ],
            [
              -99.663614,
              43.744024
            ],
            [
              -99.662236,
              43.742148
            ],
            [
              -99.662226,
              43.73899
            ],
            [
              -99.662173,
              43.736471
            ],
            [
              -99.662931,
              43.734668
            ],
            [
              -99.66457,
              43.732936
            ],
            [
              -99.666129,
              43.731715
            ],
            [
              -99.667376,
              43.730423
            ],
            [
              -99.669144,
              43.729627
            ],
            [
              -99.670972,
              43.728885
            ],
            [
              -99.67427,
              43.728032
            ],
            [
              -99.681668,
              43.728056
            ],
            [
              -99.685119,
              43.72792
            ],
            [
              -99.686663,
              43.728553
            ],
            [
              -99.687738,
              43.729673
            ],
            [
              -99.689471,
              43.73083
            ],
            [
              -99.690767,
              43.733596
            ],
            [
              -99.690174,
              43.736083
            ],
            [
              -99.689249,
              43.737925
            ],
            [
              -99.6875,
              43.739715
            ],
            [
              -99.686479,
              43.740881
            ],
            [
              -99.68526,
              43.74199
            ],
            [
              -99.684736,
              43.742983
            ],
            [
              -99.683631,
              43.744285
            ],
            [
              -99.682684,
              43.746096
            ],
            [
              -99.681725,
              43.747379
            ],
            [
              -99.681121,
              43.748716
            ],
            [
              -99.681392,
              43.750474
            ],
            [
              -99.681938,
              43.752004
            ],
            [
              -99.682308,
              43.753888
            ],
            [
              -99.683478,
              43.755446
            ],
            [
              -99.684769,
              43.757077
            ],
            [
              -99.686695,
              43.758484
            ],
            [
              -99.689399,
              43.75935
            ],
            [
              -99.691869,
              43.760422
            ],
            [
              -99.694046,
              43.760242
            ],
            [
              -99.696825,
              43.758666
            ],
            [
              -99.698456,
              43.756901
            ],
            [
              -99.699429,
              43.754944
            ],
            [
              -99.700989,
              43.753066
            ],
            [
              -99.702333,
              43.752042
            ],
            [
              -99.704188,
              43.751524
            ],
            [
              -99.705798,
              43.75051
            ],
            [
              -99.707177,
              43.749096
            ],
            [
              -99.708353,
              43.746781
            ],
            [
              -99.708651,
              43.74522
            ],
            [
              -99.709139,
              43.743716
            ],
            [
              -99.710077,
              43.742369
            ],
            [
              -99.710269,
              43.740663
            ],
            [
              -99.710002,
              43.738154
            ],
            [
              -99.709944,
              43.736124
            ],
            [
              -99.710467,
              43.733891
            ],
            [
              -99.711011,
              43.732958
            ],
            [
              -99.713272,
              43.731501
            ],
            [
              -99.717435,
              43.727969
            ],
            [
              -99.72169,
              43.724777
            ],
            [
              -99.723226,
              43.722468
            ],
            [
              -99.725288,
              43.721002
            ],
            [
              -99.726522,
              43.719904
            ],
            [
              -99.728757,
              43.719101
            ],
            [
              -99.731797,
              43.718978
            ],
            [
              -99.734055,
              43.719565
            ],
            [
              -99.735622,
              43.720304
            ],
            [
              -99.737333,
              43.721291
            ],
            [
              -99.738728,
              43.722197
            ],
            [
              -99.740209,
              43.723717
            ],
            [
              -99.73858,
              43.725307
            ],
            [
              -99.737751,
              43.727543
            ],
            [
              -99.737051,
              43.729377
            ],
            [
              -99.736238,
              43.731461
            ],
            [
              -99.736473,
              43.733893
            ],
            [
              -99.736382,
              43.735322
            ],
            [
              -99.737613,
              43.736419
            ],
            [
              -99.738471,
              43.73802
            ],
            [
              -99.738799,
              43.739692
            ],
            [
              -99.738984,
              43.740752
            ],
            [
              -99.739492,
              43.745126
            ],
            [
              -99.740058,
              43.746701
            ],
            [
              -99.741674,
              43.748022
            ],
            [
              -99.744239,
              43.748662
            ],
            [
              -99.748028,
              43.748292
            ],
            [
              -99.751418,
              43.747207
            ],
            [
              -99.760364,
              43.741056
            ],
            [
              -99.762419,
              43.728679
            ],
            [
              -99.757172,
              43.723607
            ],
            [
              -99.747238,
              43.714994
            ],
            [
              -99.74855,
              43.710143
            ],
            [
              -99.750002,
              43.710404
            ],
            [
              -99.752008,
              43.710149
            ],
            [
              -99.765293,
              43.711154
            ],
            [
              -99.783551,
              43.709706
            ],
            [
              -99.791383,
              43.710927
            ],
            [
              -99.799483,
              43.709917
            ],
            [
              -99.79898,
              43.706637
            ],
            [
              -99.804761,
              43.699991
            ],
            [
              -99.818723,
              43.696082
            ],
            [
              -99.831696,
              43.691384
            ],
            [
              -99.849649,
              43.698086
            ],
            [
              -99.856572,
              43.701333
            ],
            [
              -99.858426,
              43.697363
            ],
            [
              -99.85363,
              43.693648
            ],
            [
              -99.851915,
              43.69168
            ],
            [
              -99.85566,
              43.690055
            ],
            [
              -99.859372,
              43.691656
            ],
            [
              -99.862865,
              43.691987
            ],
            [
              -99.862617,
              43.683599
            ],
            [
              -99.865307,
              43.674971
            ],
            [
              -99.875291,
              43.673439
            ],
            [
              -99.881323,
              43.675237
            ],
            [
              -99.888132,
              43.680767
            ],
            [
              -99.885454,
              43.683487
            ],
            [
              -99.891747,
              43.692785
            ],
            [
              -99.904405,
              43.698917
            ],
            [
              -99.912124,
              43.694499
            ],
            [
              -99.916123,
              43.692309
            ],
            [
              -99.924312,
              43.687483
            ],
            [
              -99.931956,
              43.692129
            ],
            [
              -99.939459,
              43.696306
            ],
            [
              -99.95047,
              43.697942
            ],
            [
              -99.952877,
              43.698067
            ],
            [
              -99.954837,
              43.695242
            ],
            [
              -99.955411,
              43.692775
            ],
            [
              -99.959657,
              43.689869
            ],
            [
              -99.967242,
              43.6924
            ],
            [
              -99.974432,
              43.692766
            ],
            [
              -99.976065,
              43.69038
            ],
            [
              -99.983525,
              43.689329
            ],
            [
              -99.98511,
              43.693282
            ],
            [
              -99.992469,
              43.699336
            ],
            [
              -99.999939,
              43.699968
            ],
            [
              -99.998984,
              43.696947
            ],
            [
              -100.003441,
              43.692381
            ],
            [
              -100.006797,
              43.692357
            ],
            [
              -100.009873,
              43.697736
            ],
            [
              -100.007122,
              43.703225
            ],
            [
              -100.011265,
              43.707664
            ],
            [
              -100.018328,
              43.704976
            ],
            [
              -100.023173,
              43.701757
            ],
            [
              -100.027724,
              43.699275
            ],
            [
              -100.033025,
              43.700568
            ],
            [
              -100.038021,
              43.703967
            ],
            [
              -100.046775,
              43.701318
            ],
            [
              -100.04559,
              43.697862
            ],
            [
              -100.052662,
              43.6926
            ],
            [
              -100.059722,
              43.692382
            ],
            [
              -100.077543,
              43.69203
            ],
            [
              -100.081992,
              43.687048
            ],
            [
              -100.090298,
              43.686738
            ],
            [
              -100.094992,
              43.68987
            ],
            [
              -100.108297,
              43.691268
            ],
            [
              -100.108656,
              43.688091
            ],
            [
              -100.113924,
              43.68533
            ],
            [
              -100.120512,
              43.690982
            ],
            [
              -100.125405,
              43.698521
            ],
            [
              -100.131887,
              43.702737
            ],
            [
              -100.14075,
              43.704207
            ],
            [
              -100.14371,
              43.700389
            ],
            [
              -100.141962,
              43.699381
            ],
            [
              -100.139292,
              43.697902
            ],
            [
              -100.142727,
              43.695122
            ],
            [
              -100.145959,
              43.696116
            ],
            [
              -100.159699,
              43.703789
            ],
            [
              -100.168104,
              43.70549
            ],
            [
              -100.178767,
              43.705924
            ],
            [
              -100.189919,
              43.706161
            ],
            [
              -100.198812,
              43.702187
            ],
            [
              -100.199758,
              43.698068
            ],
            [
              -100.203148,
              43.691396
            ],
            [
              -100.206211,
              43.687091
            ],
            [
              -100.2113,
              43.684111
            ],
            [
              -100.212897,
              43.68635
            ],
            [
              -100.218892,
              43.691171
            ],
            [
              -100.224782,
              43.700861
            ],
            [
              -100.223677,
              43.709442
            ],
            [
              -100.224742,
              43.713097
            ],
            [
              -100.229705,
              43.71412
            ],
            [
              -100.230845,
              43.713856
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "073",
      "COUNTYNS": "01265778",
      "AFFGEOID": "0500000US46073",
      "GEOID": "46073",
      "NAME": "Jerauld",
      "LSAD": "06",
      "ALAND": 1362808091,
      "AWATER": 16668604,
      "County_state": "Jerauld,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.925953,
              44.196575
            ],
            [
              -98.926997,
              43.935143
            ],
            [
              -98.807771,
              43.935223
            ],
            [
              -98.331508,
              43.937708
            ],
            [
              -98.332042,
              44.19662
            ],
            [
              -98.700453,
              44.196727
            ],
            [
              -98.925953,
              44.196575
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "101",
      "COUNTYNS": "01265775",
      "AFFGEOID": "0500000US46101",
      "GEOID": "46101",
      "NAME": "Moody",
      "LSAD": "06",
      "ALAND": 1345200317,
      "AWATER": 3679400,
      "County_state": "Moody,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.889216,
              44.195705
            ],
            [
              -96.888664,
              43.848385
            ],
            [
              -96.504761,
              43.847694
            ],
            [
              -96.45326359312119,
              43.849501168336694
            ],
            [
              -96.45326361188069,
              43.8495059094739
            ],
            [
              -96.453264,
              43.849604
            ],
            [
              -96.453335,
              43.877029
            ],
            [
              -96.453304,
              43.878583
            ],
            [
              -96.453183,
              43.87865
            ],
            [
              -96.453352,
              43.949122
            ],
            [
              -96.453289,
              43.950814
            ],
            [
              -96.453165,
              43.96654
            ],
            [
              -96.453292,
              43.96718
            ],
            [
              -96.453389,
              43.97806
            ],
            [
              -96.453263,
              43.980277
            ],
            [
              -96.453328,
              43.992871
            ],
            [
              -96.453297,
              43.994723
            ],
            [
              -96.453116,
              44.006876
            ],
            [
              -96.453053,
              44.008887
            ],
            [
              -96.453373,
              44.023744
            ],
            [
              -96.453405,
              44.025413
            ],
            [
              -96.453313,
              44.03643
            ],
            [
              -96.453187,
              44.03835
            ],
            [
              -96.45277429961769,
              44.196779980888
            ],
            [
              -96.76687,
              44.195584
            ],
            [
              -96.889216,
              44.195705
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "119",
      "COUNTYNS": "01266982",
      "AFFGEOID": "0500000US46119",
      "GEOID": "46119",
      "NAME": "Sully",
      "LSAD": "06",
      "ALAND": 2607664601,
      "AWATER": 164384696,
      "County_state": "Sully,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.729319,
              44.787905
            ],
            [
              -100.717644,
              44.77093
            ],
            [
              -100.685178,
              44.754855
            ],
            [
              -100.680637,
              44.747607
            ],
            [
              -100.630103,
              44.737213
            ],
            [
              -100.613805,
              44.707135
            ],
            [
              -100.613466,
              44.68813
            ],
            [
              -100.624806,
              44.651552
            ],
            [
              -100.642388,
              44.621765
            ],
            [
              -100.645552,
              44.597955
            ],
            [
              -100.640061,
              44.583723
            ],
            [
              -100.620897,
              44.566698
            ],
            [
              -100.593032,
              44.556576
            ],
            [
              -100.537016,
              44.552603
            ],
            [
              -100.526498,
              44.547422
            ],
            [
              -99.676398,
              44.548111
            ],
            [
              -99.67305,
              44.897492
            ],
            [
              -100.404753,
              44.897695
            ],
            [
              -100.393004,
              44.874983
            ],
            [
              -100.402721,
              44.856843
            ],
            [
              -100.418682,
              44.843366
            ],
            [
              -100.475132,
              44.816432
            ],
            [
              -100.512518,
              44.785634
            ],
            [
              -100.529636,
              44.766301
            ],
            [
              -100.543484,
              44.761087
            ],
            [
              -100.609036,
              44.769428
            ],
            [
              -100.641741,
              44.791844
            ],
            [
              -100.676387,
              44.823162
            ],
            [
              -100.702232,
              44.828371
            ],
            [
              -100.715226,
              44.82118
            ],
            [
              -100.729319,
              44.787905
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "049",
      "COUNTYNS": "01265760",
      "AFFGEOID": "0500000US46049",
      "GEOID": "46049",
      "NAME": "Faulk",
      "LSAD": "06",
      "ALAND": 2542575647,
      "AWATER": 62678931,
      "County_state": "Faulk,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.573445,
              45.245364
            ],
            [
              -99.572699,
              44.897263
            ],
            [
              -99.311754,
              44.897227
            ],
            [
              -98.716498,
              44.896925
            ],
            [
              -98.717759,
              45.243732
            ],
            [
              -98.722481,
              45.243764
            ],
            [
              -99.573445,
              45.245364
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "087",
      "COUNTYNS": "01265773",
      "AFFGEOID": "0500000US46087",
      "GEOID": "46087",
      "NAME": "McCook",
      "LSAD": "06",
      "ALAND": 1487182081,
      "AWATER": 6898508,
      "County_state": "McCook,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.608517,
              43.849059
            ],
            [
              -97.609038,
              43.76261
            ],
            [
              -97.607035,
              43.731326
            ],
            [
              -97.607012,
              43.499826
            ],
            [
              -97.40145,
              43.499783
            ],
            [
              -97.129478,
              43.499683
            ],
            [
              -97.129025,
              43.761126
            ],
            [
              -97.129089,
              43.847973
            ],
            [
              -97.369463,
              43.848526
            ],
            [
              -97.608517,
              43.849059
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "095",
      "COUNTYNS": "01267177",
      "AFFGEOID": "0500000US46095",
      "GEOID": "46095",
      "NAME": "Mellette",
      "LSAD": "06",
      "ALAND": 3385957220,
      "AWATER": 9219640,
      "County_state": "Mellette,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.228133,
              43.789
            ],
            [
              -101.228234,
              43.389185
            ],
            [
              -101.040718,
              43.389066
            ],
            [
              -100.214221,
              43.390321
            ],
            [
              -100.214433,
              43.477332
            ],
            [
              -100.230991,
              43.477981
            ],
            [
              -100.230845,
              43.713856
            ],
            [
              -100.252156,
              43.703171
            ],
            [
              -100.265561,
              43.709162
            ],
            [
              -100.280028,
              43.696795
            ],
            [
              -100.30146,
              43.720126
            ],
            [
              -100.33897,
              43.716942
            ],
            [
              -100.345506,
              43.720782
            ],
            [
              -100.357474,
              43.732975
            ],
            [
              -100.365397,
              43.736675
            ],
            [
              -100.374596,
              43.736013
            ],
            [
              -100.377652,
              43.73033
            ],
            [
              -100.388692,
              43.727048
            ],
            [
              -100.400416,
              43.730729
            ],
            [
              -100.408727,
              43.733115
            ],
            [
              -100.414207,
              43.735161
            ],
            [
              -100.415401,
              43.731902
            ],
            [
              -100.413319,
              43.725223
            ],
            [
              -100.416677,
              43.719848
            ],
            [
              -100.424336,
              43.72031
            ],
            [
              -100.435963,
              43.726316
            ],
            [
              -100.440736,
              43.730678
            ],
            [
              -100.448546,
              43.726895
            ],
            [
              -100.453875,
              43.721744
            ],
            [
              -100.466986,
              43.722837
            ],
            [
              -100.469747,
              43.722045
            ],
            [
              -100.467097,
              43.726136
            ],
            [
              -100.465349,
              43.7311
            ],
            [
              -100.467167,
              43.734489
            ],
            [
              -100.472175,
              43.73484
            ],
            [
              -100.471329,
              43.73127
            ],
            [
              -100.473127,
              43.728832
            ],
            [
              -100.479751,
              43.728903
            ],
            [
              -100.484996,
              43.733742
            ],
            [
              -100.487257,
              43.736986
            ],
            [
              -100.494943,
              43.733336
            ],
            [
              -100.503407,
              43.729726
            ],
            [
              -100.511307,
              43.729127
            ],
            [
              -100.516421,
              43.732313
            ],
            [
              -100.515911,
              43.738025
            ],
            [
              -100.518267,
              43.744166
            ],
            [
              -100.528048,
              43.745249
            ],
            [
              -100.532724,
              43.740816
            ],
            [
              -100.546491,
              43.736976
            ],
            [
              -100.556163,
              43.738724
            ],
            [
              -100.559473,
              43.739992
            ],
            [
              -100.561084,
              43.741632
            ],
            [
              -100.558796,
              43.742987
            ],
            [
              -100.553643,
              43.741265
            ],
            [
              -100.549873,
              43.746221
            ],
            [
              -100.549901,
              43.74929
            ],
            [
              -100.551163,
              43.75295
            ],
            [
              -100.561181,
              43.753451
            ],
            [
              -100.57296,
              43.754865
            ],
            [
              -100.579259,
              43.765194
            ],
            [
              -100.58517,
              43.768613
            ],
            [
              -100.592796,
              43.767652
            ],
            [
              -100.5956,
              43.765684
            ],
            [
              -100.602466,
              43.762957
            ],
            [
              -100.611032,
              43.758004
            ],
            [
              -100.611171,
              43.749962
            ],
            [
              -100.619225,
              43.743398
            ],
            [
              -100.630645,
              43.741375
            ],
            [
              -100.637951,
              43.738281
            ],
            [
              -100.640266,
              43.740039
            ],
            [
              -100.63782,
              43.740551
            ],
            [
              -100.637645,
              43.745666
            ],
            [
              -100.643691,
              43.749101
            ],
            [
              -100.655593,
              43.748001
            ],
            [
              -100.663256,
              43.743434
            ],
            [
              -100.668195,
              43.738731
            ],
            [
              -100.665691,
              43.734914
            ],
            [
              -100.660235,
              43.735498
            ],
            [
              -100.656091,
              43.735644
            ],
            [
              -100.653075,
              43.732942
            ],
            [
              -100.656235,
              43.730297
            ],
            [
              -100.666199,
              43.726827
            ],
            [
              -100.674475,
              43.723067
            ],
            [
              -100.677162,
              43.717839
            ],
            [
              -100.681043,
              43.711896
            ],
            [
              -100.685992,
              43.713039
            ],
            [
              -100.6899,
              43.713883
            ],
            [
              -100.694293,
              43.716238
            ],
            [
              -100.698219,
              43.720111
            ],
            [
              -100.696499,
              43.721744
            ],
            [
              -100.697193,
              43.725153
            ],
            [
              -100.700207,
              43.725994
            ],
            [
              -100.702148,
              43.725294
            ],
            [
              -100.703902,
              43.721861
            ],
            [
              -100.710546,
              43.718578
            ],
            [
              -100.716782,
              43.719552
            ],
            [
              -100.719165,
              43.715868
            ],
            [
              -100.720738,
              43.713792
            ],
            [
              -100.72293,
              43.714103
            ],
            [
              -100.723582,
              43.716409
            ],
            [
              -100.725108,
              43.719668
            ],
            [
              -100.731506,
              43.721481
            ],
            [
              -100.734473,
              43.720892
            ],
            [
              -100.738151,
              43.720019
            ],
            [
              -100.736713,
              43.716652
            ],
            [
              -100.727569,
              43.7143
            ],
            [
              -100.72889,
              43.70943
            ],
            [
              -100.736981,
              43.710271
            ],
            [
              -100.742918,
              43.718171
            ],
            [
              -100.74882,
              43.72056
            ],
            [
              -100.749423,
              43.724976
            ],
            [
              -100.744117,
              43.72764
            ],
            [
              -100.744444,
              43.733601
            ],
            [
              -100.744856,
              43.73674
            ],
            [
              -100.747541,
              43.737588
            ],
            [
              -100.747594,
              43.736344
            ],
            [
              -100.749624,
              43.734314
            ],
            [
              -100.753786,
              43.735881
            ],
            [
              -100.757195,
              43.738782
            ],
            [
              -100.75957,
              43.73675
            ],
            [
              -100.757434,
              43.736043
            ],
            [
              -100.755559,
              43.733664
            ],
            [
              -100.757241,
              43.731621
            ],
            [
              -100.761779,
              43.73143
            ],
            [
              -100.766087,
              43.732167
            ],
            [
              -100.779511,
              43.72406
            ],
            [
              -100.787072,
              43.725063
            ],
            [
              -100.787558,
              43.728452
            ],
            [
              -100.784763,
              43.727853
            ],
            [
              -100.779344,
              43.728924
            ],
            [
              -100.77927,
              43.731488
            ],
            [
              -100.784714,
              43.730378
            ],
            [
              -100.790931,
              43.732474
            ],
            [
              -100.791663,
              43.735019
            ],
            [
              -100.789523,
              43.734609
            ],
            [
              -100.786406,
              43.738039
            ],
            [
              -100.787994,
              43.740452
            ],
            [
              -100.79195,
              43.741153
            ],
            [
              -100.796507,
              43.741167
            ],
            [
              -100.801102,
              43.74305
            ],
            [
              -100.804758,
              43.746171
            ],
            [
              -100.801516,
              43.748949
            ],
            [
              -100.796117,
              43.748342
            ],
            [
              -100.794578,
              43.751215
            ],
            [
              -100.79666,
              43.754875
            ],
            [
              -100.803014,
              43.754153
            ],
            [
              -100.805788,
              43.75943
            ],
            [
              -100.805872,
              43.762366
            ],
            [
              -100.814072,
              43.766728
            ],
            [
              -100.823493,
              43.768373
            ],
            [
              -100.830888,
              43.768994
            ],
            [
              -100.835023,
              43.764823
            ],
            [
              -100.835314,
              43.762065
            ],
            [
              -100.840337,
              43.762145
            ],
            [
              -100.839854,
              43.765078
            ],
            [
              -100.846025,
              43.770508
            ],
            [
              -100.853157,
              43.78178
            ],
            [
              -100.85829,
              43.786513
            ],
            [
              -100.866807,
              43.783211
            ],
            [
              -100.870569,
              43.780296
            ],
            [
              -100.876436,
              43.782264
            ],
            [
              -100.875279,
              43.786826
            ],
            [
              -100.873664,
              43.793348
            ],
            [
              -100.873636,
              43.798626
            ],
            [
              -100.879352,
              43.801534
            ],
            [
              -100.882771,
              43.795424
            ],
            [
              -100.895377,
              43.792991
            ],
            [
              -100.898705,
              43.793606
            ],
            [
              -100.898791,
              43.796095
            ],
            [
              -100.894358,
              43.796656
            ],
            [
              -100.897512,
              43.800469
            ],
            [
              -100.904168,
              43.800205
            ],
            [
              -100.910029,
              43.803618
            ],
            [
              -100.907255,
              43.807385
            ],
            [
              -100.905426,
              43.805607
            ],
            [
              -100.898426,
              43.80607
            ],
            [
              -100.897026,
              43.80938
            ],
            [
              -100.901518,
              43.813729
            ],
            [
              -100.907462,
              43.813503
            ],
            [
              -100.917266,
              43.815605
            ],
            [
              -100.92094,
              43.824681
            ],
            [
              -100.927232,
              43.828999
            ],
            [
              -100.936344,
              43.831399
            ],
            [
              -100.941769,
              43.826819
            ],
            [
              -100.944168,
              43.817927
            ],
            [
              -100.950533,
              43.812089
            ],
            [
              -100.956262,
              43.810286
            ],
            [
              -100.955739,
              43.811974
            ],
            [
              -100.957581,
              43.816943
            ],
            [
              -100.96337,
              43.815811
            ],
            [
              -100.96814,
              43.816891
            ],
            [
              -100.972814,
              43.82219
            ],
            [
              -100.973289,
              43.825311
            ],
            [
              -100.968454,
              43.827891
            ],
            [
              -100.962138,
              43.827093
            ],
            [
              -100.95925,
              43.831185
            ],
            [
              -100.959592,
              43.833805
            ],
            [
              -100.965615,
              43.832419
            ],
            [
              -100.973888,
              43.831022
            ],
            [
              -100.981097,
              43.836165
            ],
            [
              -100.98673,
              43.841256
            ],
            [
              -100.985935,
              43.84534
            ],
            [
              -100.996204,
              43.84937
            ],
            [
              -101.001967,
              43.84645
            ],
            [
              -101.011723,
              43.842104
            ],
            [
              -101.020406,
              43.842651
            ],
            [
              -101.026421,
              43.842133
            ],
            [
              -101.030038,
              43.842873
            ],
            [
              -101.030966,
              43.847983
            ],
            [
              -101.027179,
              43.8495
            ],
            [
              -101.02579,
              43.853141
            ],
            [
              -101.027486,
              43.855063
            ],
            [
              -101.028,
              43.85603
            ],
            [
              -101.033311,
              43.854032
            ],
            [
              -101.039461,
              43.851202
            ],
            [
              -101.046776,
              43.852036
            ],
            [
              -101.052408,
              43.854375
            ],
            [
              -101.056437,
              43.854344
            ],
            [
              -101.057377,
              43.850105
            ],
            [
              -101.058317,
              43.8456
            ],
            [
              -101.064326,
              43.84109
            ],
            [
              -101.088371,
              43.842601
            ],
            [
              -101.087093,
              43.849455
            ],
            [
              -101.107966,
              43.846174
            ],
            [
              -101.107095,
              43.836743
            ],
            [
              -101.124837,
              43.844332
            ],
            [
              -101.13794,
              43.841321
            ],
            [
              -101.13439,
              43.830598
            ],
            [
              -101.155644,
              43.816748
            ],
            [
              -101.161493,
              43.830032
            ],
            [
              -101.171728,
              43.816229
            ],
            [
              -101.180599,
              43.818372
            ],
            [
              -101.189335,
              43.802872
            ],
            [
              -101.206008,
              43.812109
            ],
            [
              -101.217768,
              43.803847
            ],
            [
              -101.228133,
              43.789
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "109",
      "COUNTYNS": "01265786",
      "AFFGEOID": "0500000US46109",
      "GEOID": "46109",
      "NAME": "Roberts",
      "LSAD": "06",
      "ALAND": 2851702831,
      "AWATER": 89947545,
      "County_state": "Roberts,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.2282906022523,
              45.935141053260196
            ],
            [
              -97.2283038853974,
              45.93514103142339
            ],
            [
              -97.227089,
              45.558158
            ],
            [
              -97.226244,
              45.297647
            ],
            [
              -97.007543,
              45.296866
            ],
            [
              -96.992946,
              45.32688
            ],
            [
              -96.47038677579108,
              45.3266054662554
            ],
            [
              -96.479323,
              45.339644
            ],
            [
              -96.482556,
              45.346273
            ],
            [
              -96.489065,
              45.357071
            ],
            [
              -96.502006,
              45.365473
            ],
            [
              -96.508132,
              45.367832
            ],
            [
              -96.521787,
              45.375645
            ],
            [
              -96.530944,
              45.378495
            ],
            [
              -96.539722,
              45.380338
            ],
            [
              -96.545973,
              45.38105
            ],
            [
              -96.562142,
              45.38609
            ],
            [
              -96.571364,
              45.389871
            ],
            [
              -96.578879,
              45.392295
            ],
            [
              -96.584764,
              45.395705
            ],
            [
              -96.60118,
              45.403181
            ],
            [
              -96.617726,
              45.408092
            ],
            [
              -96.631204,
              45.409238
            ],
            [
              -96.640624,
              45.409227
            ],
            [
              -96.647888,
              45.410126
            ],
            [
              -96.662258,
              45.409011
            ],
            [
              -96.670301,
              45.410545
            ],
            [
              -96.675447,
              45.410216
            ],
            [
              -96.680454,
              45.410499
            ],
            [
              -96.683753,
              45.411556
            ],
            [
              -96.692541,
              45.417338
            ],
            [
              -96.702006,
              45.426247
            ],
            [
              -96.710786,
              45.43693
            ],
            [
              -96.72425,
              45.451482
            ],
            [
              -96.731396,
              45.45702
            ],
            [
              -96.732739,
              45.458737
            ],
            [
              -96.736837,
              45.466775
            ],
            [
              -96.738446,
              45.473499
            ],
            [
              -96.742509,
              45.478723
            ],
            [
              -96.743486,
              45.480649
            ],
            [
              -96.743683,
              45.484439
            ],
            [
              -96.745487,
              45.488712
            ],
            [
              -96.752865,
              45.502163
            ],
            [
              -96.760591,
              45.514895
            ],
            [
              -96.76528,
              45.521414
            ],
            [
              -96.781036,
              45.535972
            ],
            [
              -96.784863,
              45.5413
            ],
            [
              -96.79384,
              45.550724
            ],
            [
              -96.799102,
              45.554225
            ],
            [
              -96.801987,
              45.555414
            ],
            [
              -96.835451,
              45.586129
            ],
            [
              -96.843957,
              45.594003
            ],
            [
              -96.844334,
              45.594375
            ],
            [
              -96.849444,
              45.598944
            ],
            [
              -96.853646,
              45.602307
            ],
            [
              -96.857751,
              45.605962
            ],
            [
              -96.856657,
              45.609041
            ],
            [
              -96.852392,
              45.61484
            ],
            [
              -96.851621,
              45.619412
            ],
            [
              -96.844211,
              45.639583
            ],
            [
              -96.840746,
              45.645294
            ],
            [
              -96.835769,
              45.649648
            ],
            [
              -96.832659,
              45.651716
            ],
            [
              -96.827428,
              45.653067
            ],
            [
              -96.82616,
              45.654164
            ],
            [
              -96.800156,
              45.668355
            ],
            [
              -96.760866,
              45.687518
            ],
            [
              -96.757174,
              45.690957
            ],
            [
              -96.75035,
              45.698782
            ],
            [
              -96.745086,
              45.701576
            ],
            [
              -96.711157,
              45.717561
            ],
            [
              -96.687224,
              45.725931
            ],
            [
              -96.672665,
              45.732336
            ],
            [
              -96.662595,
              45.738682
            ],
            [
              -96.652226,
              45.746809
            ],
            [
              -96.641941,
              45.759871
            ],
            [
              -96.639685,
              45.7654
            ],
            [
              -96.638726,
              45.770171
            ],
            [
              -96.636646,
              45.773702
            ],
            [
              -96.630512,
              45.781157
            ],
            [
              -96.629426,
              45.784211
            ],
            [
              -96.627778,
              45.786239
            ],
            [
              -96.625347,
              45.787924
            ],
            [
              -96.618195,
              45.791063
            ],
            [
              -96.612512,
              45.794442
            ],
            [
              -96.607621,
              45.79907
            ],
            [
              -96.601863,
              45.806343
            ],
            [
              -96.596704,
              45.811801
            ],
            [
              -96.593216,
              45.813873
            ],
            [
              -96.587093,
              45.816445
            ],
            [
              -96.583085,
              45.820024
            ],
            [
              -96.57974,
              45.82582
            ],
            [
              -96.577484,
              45.833108
            ],
            [
              -96.577534,
              45.83793
            ],
            [
              -96.576544,
              45.839945
            ],
            [
              -96.574517,
              45.843098
            ],
            [
              -96.572984,
              45.861602
            ],
            [
              -96.574417,
              45.86501
            ],
            [
              -96.574667,
              45.866816
            ],
            [
              -96.571871,
              45.871846
            ],
            [
              -96.572651,
              45.876474
            ],
            [
              -96.571354,
              45.886673
            ],
            [
              -96.568772,
              45.888072
            ],
            [
              -96.568281,
              45.891203
            ],
            [
              -96.568053,
              45.898697
            ],
            [
              -96.568315,
              45.902902
            ],
            [
              -96.567268,
              45.905393
            ],
            [
              -96.56442,
              45.909415
            ],
            [
              -96.565541,
              45.910883
            ],
            [
              -96.566534,
              45.911876
            ],
            [
              -96.56703,
              45.915682
            ],
            [
              -96.566562,
              45.916931
            ],
            [
              -96.564002,
              45.91956
            ],
            [
              -96.564317,
              45.921074
            ],
            [
              -96.564518,
              45.926256
            ],
            [
              -96.563672,
              45.935245
            ],
            [
              -96.576897,
              45.935259
            ],
            [
              -96.597432,
              45.935209
            ],
            [
              -96.607142,
              45.935301
            ],
            [
              -96.618295,
              45.935407
            ],
            [
              -96.639066,
              45.935318
            ],
            [
              -96.659895,
              45.93556
            ],
            [
              -96.680646,
              45.935716
            ],
            [
              -96.701313,
              45.935807
            ],
            [
              -96.791505,
              45.935857
            ],
            [
              -96.805155,
              45.935431
            ],
            [
              -96.8940764092495,
              45.9355546187594
            ],
            [
              -96.998652,
              45.9357
            ],
            [
              -97.000361,
              45.935233
            ],
            [
              -97.019596,
              45.935382
            ],
            [
              -97.103218,
              45.935991
            ],
            [
              -97.118053,
              45.935485
            ],
            [
              -97.144987,
              45.935278
            ],
            [
              -97.2282906022523,
              45.935141053260196
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "102",
      "COUNTYNS": "01266992",
      "AFFGEOID": "0500000US46102",
      "GEOID": "46102",
      "NAME": "Oglala Lakota",
      "LSAD": "06",
      "ALAND": 5422404947,
      "AWATER": 7126155,
      "County_state": "Oglala Lakota,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.001017,
              43.605816
            ],
            [
              -103.000913,
              43.476848
            ],
            [
              -103.000609657376,
              43.000472962679495
            ],
            [
              -102.792111,
              42.99998
            ],
            [
              -102.487329,
              42.999559
            ],
            [
              -102.440547,
              42.999609
            ],
            [
              -102.082546,
              42.999356
            ],
            [
              -102.082486038791,
              42.9993559930387
            ],
            [
              -102.081427,
              43.12918
            ],
            [
              -102.108892,
              43.129258
            ],
            [
              -102.110819,
              43.389902
            ],
            [
              -102.111131,
              43.476834
            ],
            [
              -102.139051,
              43.476676
            ],
            [
              -102.139158,
              43.700948
            ],
            [
              -102.153253,
              43.706182
            ],
            [
              -102.175231,
              43.687756
            ],
            [
              -102.809398,
              43.68756
            ],
            [
              -102.813752,
              43.667592
            ],
            [
              -102.831869,
              43.65698
            ],
            [
              -102.846772,
              43.665352
            ],
            [
              -102.8605,
              43.658803
            ],
            [
              -102.871727,
              43.666985
            ],
            [
              -102.9025,
              43.674888
            ],
            [
              -102.919549,
              43.661217
            ],
            [
              -102.922265,
              43.639841
            ],
            [
              -102.932566,
              43.618706
            ],
            [
              -102.958292,
              43.613532
            ],
            [
              -102.974475,
              43.618723
            ],
            [
              -103.001017,
              43.605816
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "117",
      "COUNTYNS": "01266998",
      "AFFGEOID": "0500000US46117",
      "GEOID": "46117",
      "NAME": "Stanley",
      "LSAD": "06",
      "ALAND": 3741060714,
      "AWATER": 188383669,
      "County_state": "Stanley,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.166805,
              44.343113
            ],
            [
              -101.166753,
              44.168085
            ],
            [
              -101.046916,
              44.168484
            ],
            [
              -100.367207,
              44.168694
            ],
            [
              -100.367247,
              44.194621
            ],
            [
              -99.938217,
              44.195195
            ],
            [
              -99.934803,
              44.206095
            ],
            [
              -99.906162,
              44.225614
            ],
            [
              -99.892101,
              44.241881
            ],
            [
              -99.903449,
              44.25827
            ],
            [
              -99.92303,
              44.266291
            ],
            [
              -99.960573,
              44.264512
            ],
            [
              -99.984421,
              44.268338
            ],
            [
              -100.015817,
              44.280787
            ],
            [
              -100.040628,
              44.286354
            ],
            [
              -100.08533,
              44.308451
            ],
            [
              -100.165704,
              44.324633
            ],
            [
              -100.192032,
              44.322619
            ],
            [
              -100.23247,
              44.325557
            ],
            [
              -100.336361,
              44.343021
            ],
            [
              -100.36539,
              44.355524
            ],
            [
              -100.373836,
              44.382887
            ],
            [
              -100.386376,
              44.440008
            ],
            [
              -100.410258,
              44.45786
            ],
            [
              -100.474868,
              44.460367
            ],
            [
              -100.536136,
              44.444618
            ],
            [
              -100.551396,
              44.44552
            ],
            [
              -100.580034,
              44.461842
            ],
            [
              -100.575796,
              44.491713
            ],
            [
              -100.553448,
              44.508979
            ],
            [
              -100.531767,
              44.515114
            ],
            [
              -100.516682,
              44.534449
            ],
            [
              -100.526498,
              44.547422
            ],
            [
              -100.537016,
              44.552603
            ],
            [
              -100.593032,
              44.556576
            ],
            [
              -100.620897,
              44.566698
            ],
            [
              -100.640061,
              44.583723
            ],
            [
              -100.645552,
              44.597955
            ],
            [
              -100.642388,
              44.621765
            ],
            [
              -100.624806,
              44.651552
            ],
            [
              -100.613466,
              44.68813
            ],
            [
              -100.613805,
              44.707135
            ],
            [
              -100.630103,
              44.737213
            ],
            [
              -100.680637,
              44.747607
            ],
            [
              -100.685178,
              44.754855
            ],
            [
              -100.717644,
              44.77093
            ],
            [
              -100.736369,
              44.763737
            ],
            [
              -100.736264,
              44.774396
            ],
            [
              -100.753049,
              44.772718
            ],
            [
              -100.754877,
              44.760546
            ],
            [
              -100.769686,
              44.75818
            ],
            [
              -100.777718,
              44.772129
            ],
            [
              -100.799092,
              44.763784
            ],
            [
              -100.829795,
              44.783248
            ],
            [
              -100.85366,
              44.780719
            ],
            [
              -100.888048,
              44.769728
            ],
            [
              -100.910312,
              44.783737
            ],
            [
              -100.917193,
              44.769624
            ],
            [
              -100.939844,
              44.762518
            ],
            [
              -100.955185,
              44.765517
            ],
            [
              -100.954962,
              44.754849
            ],
            [
              -100.975379,
              44.751371
            ],
            [
              -100.98771,
              44.731961
            ],
            [
              -100.995667,
              44.739166
            ],
            [
              -101.022611,
              44.724153
            ],
            [
              -101.055546,
              44.740147
            ],
            [
              -101.083243,
              44.738433
            ],
            [
              -101.136838,
              44.747126
            ],
            [
              -101.137163,
              44.689962
            ],
            [
              -101.154516,
              44.689947
            ],
            [
              -101.155188,
              44.343147
            ],
            [
              -101.166805,
              44.343113
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "137",
      "COUNTYNS": "01266993",
      "AFFGEOID": "0500000US46137",
      "GEOID": "46137",
      "NAME": "Ziebach",
      "LSAD": "06",
      "ALAND": 5079575456,
      "AWATER": 24212303,
      "County_state": "Ziebach,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.99999,
              45.038274
            ],
            [
              -102.000432,
              44.511663
            ],
            [
              -101.999105,
              44.511792
            ],
            [
              -101.990267,
              44.52232
            ],
            [
              -101.979756,
              44.522837
            ],
            [
              -101.971118,
              44.526081
            ],
            [
              -101.959284,
              44.528454
            ],
            [
              -101.94278,
              44.533666
            ],
            [
              -101.933666,
              44.531404
            ],
            [
              -101.922155,
              44.530531
            ],
            [
              -101.912376,
              44.532885
            ],
            [
              -101.900836,
              44.535753
            ],
            [
              -101.89247,
              44.537065
            ],
            [
              -101.885365,
              44.536212
            ],
            [
              -101.880659,
              44.532896
            ],
            [
              -101.872913,
              44.532422
            ],
            [
              -101.861688,
              44.530611
            ],
            [
              -101.849157,
              44.52927
            ],
            [
              -101.843159,
              44.527487
            ],
            [
              -101.839248,
              44.523963
            ],
            [
              -101.83152,
              44.526962
            ],
            [
              -101.823797,
              44.531897
            ],
            [
              -101.814118,
              44.528451
            ],
            [
              -101.814288,
              44.524742
            ],
            [
              -101.805396,
              44.528468
            ],
            [
              -101.806106,
              44.532875
            ],
            [
              -101.801385,
              44.53966
            ],
            [
              -101.784658,
              44.540785
            ],
            [
              -101.773434,
              44.541079
            ],
            [
              -101.757534,
              44.548809
            ],
            [
              -101.744041,
              44.559686
            ],
            [
              -101.743924,
              44.568102
            ],
            [
              -101.73398,
              44.567874
            ],
            [
              -101.721847,
              44.569565
            ],
            [
              -101.716233,
              44.576074
            ],
            [
              -101.706004,
              44.577803
            ],
            [
              -101.695184,
              44.575184
            ],
            [
              -101.69026,
              44.572392
            ],
            [
              -101.680788,
              44.572265
            ],
            [
              -101.674574,
              44.58116
            ],
            [
              -101.66221,
              44.586269
            ],
            [
              -101.652686,
              44.58314
            ],
            [
              -101.643812,
              44.5811
            ],
            [
              -101.636874,
              44.584027
            ],
            [
              -101.628245,
              44.583413
            ],
            [
              -101.627164,
              44.579155
            ],
            [
              -101.62409,
              44.573239
            ],
            [
              -101.614437,
              44.57261
            ],
            [
              -101.601175,
              44.576043
            ],
            [
              -101.588483,
              44.574521
            ],
            [
              -101.57404,
              44.577976
            ],
            [
              -101.562261,
              44.573399
            ],
            [
              -101.554607,
              44.571443
            ],
            [
              -101.55208,
              44.575768
            ],
            [
              -101.546715,
              44.574305
            ],
            [
              -101.538841,
              44.573885
            ],
            [
              -101.533933,
              44.570685
            ],
            [
              -101.5262,
              44.570333
            ],
            [
              -101.519316,
              44.574276
            ],
            [
              -101.514448,
              44.58286
            ],
            [
              -101.50605,
              44.585045
            ],
            [
              -101.50007,
              44.581569
            ],
            [
              -101.495002,
              44.590945
            ],
            [
              -101.497929,
              44.596481
            ],
            [
              -101.487236,
              44.6022
            ],
            [
              -101.475384,
              44.603023
            ],
            [
              -101.471903,
              44.609285
            ],
            [
              -101.461567,
              44.611311
            ],
            [
              -101.462719,
              44.616078
            ],
            [
              -101.45408,
              44.631187
            ],
            [
              -101.447298,
              44.638129
            ],
            [
              -101.427527,
              44.640914
            ],
            [
              -101.409021,
              44.649629
            ],
            [
              -101.397433,
              44.65678
            ],
            [
              -101.384893,
              44.653695
            ],
            [
              -101.374599,
              44.658239
            ],
            [
              -101.368318,
              44.664653
            ],
            [
              -101.372145,
              44.667368
            ],
            [
              -101.369411,
              44.673788
            ],
            [
              -101.363577,
              44.672408
            ],
            [
              -101.359214,
              44.679748
            ],
            [
              -101.352049,
              44.687942
            ],
            [
              -101.33295,
              44.69065
            ],
            [
              -101.326042,
              44.684646
            ],
            [
              -101.306077,
              44.684754
            ],
            [
              -101.294824,
              44.689319
            ],
            [
              -101.268518,
              44.689536
            ],
            [
              -101.256234,
              44.696349
            ],
            [
              -101.242804,
              44.698721
            ],
            [
              -101.224556,
              44.694234
            ],
            [
              -101.153674,
              44.728832
            ],
            [
              -101.13956,
              44.747062
            ],
            [
              -101.136838,
              44.747126
            ],
            [
              -101.136162,
              44.994074
            ],
            [
              -101.500512,
              44.99378
            ],
            [
              -101.500979,
              45.038336
            ],
            [
              -101.486593,
              45.038374
            ],
            [
              -101.486348,
              45.38559
            ],
            [
              -101.471065,
              45.385581
            ],
            [
              -101.47019,
              45.472416
            ],
            [
              -101.99979,
              45.472414
            ],
            [
              -101.99999,
              45.038274
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "021",
      "COUNTYNS": "01266974",
      "AFFGEOID": "0500000US46021",
      "GEOID": "46021",
      "NAME": "Campbell",
      "LSAD": "06",
      "ALAND": 1900214168,
      "AWATER": 96243697,
      "County_state": "Campbell,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.499353928538,
              45.9436316399083
            ],
            [
              -100.488683,
              45.93028
            ],
            [
              -100.462424,
              45.919574
            ],
            [
              -100.417516,
              45.884006
            ],
            [
              -100.387036,
              45.869142
            ],
            [
              -100.380638,
              45.858178
            ],
            [
              -100.382369,
              45.838614
            ],
            [
              -100.375541,
              45.815951
            ],
            [
              -100.351209,
              45.771844
            ],
            [
              -100.309013,
              45.707842
            ],
            [
              -100.308849,
              45.689634
            ],
            [
              -100.317783,
              45.673462
            ],
            [
              -100.337249,
              45.663191
            ],
            [
              -100.380281,
              45.660747
            ],
            [
              -100.406438,
              45.650011
            ],
            [
              -100.416719,
              45.634652
            ],
            [
              -100.430079,
              45.594823
            ],
            [
              -99.717466,
              45.593428
            ],
            [
              -99.718071040168,
              45.9409070032797
            ],
            [
              -99.718073,
              45.940907
            ],
            [
              -99.74787,
              45.940933
            ],
            [
              -99.749325,
              45.940935
            ],
            [
              -99.749494,
              45.940956
            ],
            [
              -99.750396,
              45.940935
            ],
            [
              -99.83868,
              45.941293
            ],
            [
              -99.8800620862737,
              45.9416699059574
            ],
            [
              -99.880292,
              45.941672
            ],
            [
              -99.965775,
              45.941822
            ],
            [
              -100.005486,
              45.94195
            ],
            [
              -100.06902,
              45.94217
            ],
            [
              -100.084163,
              45.942301
            ],
            [
              -100.108471,
              45.942391
            ],
            [
              -100.110339,
              45.942367
            ],
            [
              -100.14173,
              45.942506
            ],
            [
              -100.152084,
              45.942486
            ],
            [
              -100.170826,
              45.942514
            ],
            [
              -100.274762,
              45.942945
            ],
            [
              -100.275614,
              45.942922
            ],
            [
              -100.284134,
              45.942951
            ],
            [
              -100.285345,
              45.94313
            ],
            [
              -100.294126,
              45.943269
            ],
            [
              -100.410386,
              45.943453
            ],
            [
              -100.420162,
              45.943533
            ],
            [
              -100.424438,
              45.943569
            ],
            [
              -100.430597,
              45.943638
            ],
            [
              -100.462838,
              45.943566
            ],
            [
              -100.499353928538,
              45.9436316399083
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "011",
      "COUNTYNS": "01265779",
      "AFFGEOID": "0500000US46011",
      "GEOID": "46011",
      "NAME": "Brookings",
      "LSAD": "06",
      "ALAND": 2051804710,
      "AWATER": 32967600,
      "County_state": "Brookings,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.128024,
              44.543126
            ],
            [
              -97.129671,
              44.195933
            ],
            [
              -96.889216,
              44.195705
            ],
            [
              -96.76687,
              44.195584
            ],
            [
              -96.45277429961769,
              44.196779980888
            ],
            [
              -96.452774,
              44.196895
            ],
            [
              -96.452673,
              44.254588
            ],
            [
              -96.452419,
              44.255274
            ],
            [
              -96.452369,
              44.268967
            ],
            [
              -96.452365,
              44.271972
            ],
            [
              -96.452617,
              44.282702
            ],
            [
              -96.4525,
              44.285687
            ],
            [
              -96.452334,
              44.297009
            ],
            [
              -96.452239,
              44.298655
            ],
            [
              -96.452369,
              44.312071
            ],
            [
              -96.452248,
              44.313362
            ],
            [
              -96.452372,
              44.325991
            ],
            [
              -96.452309,
              44.328094
            ],
            [
              -96.452248,
              44.340642
            ],
            [
              -96.452152,
              44.342219
            ],
            [
              -96.452305,
              44.345332
            ],
            [
              -96.452282,
              44.354857
            ],
            [
              -96.452213,
              44.360149
            ],
            [
              -96.452134,
              44.383679
            ],
            [
              -96.452073,
              44.38969
            ],
            [
              -96.451924,
              44.441549
            ],
            [
              -96.451816,
              44.460402
            ],
            [
              -96.452218,
              44.470873
            ],
            [
              -96.452122,
              44.473043
            ],
            [
              -96.451974,
              44.506849
            ],
            [
              -96.45201,
              44.516929
            ],
            [
              -96.452236,
              44.526871
            ],
            [
              -96.452016,
              44.543533
            ],
            [
              -96.4519902127759,
              44.5436387679114
            ],
            [
              -96.885504,
              44.543773
            ],
            [
              -97.120814,
              44.542925
            ],
            [
              -97.128024,
              44.543126
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "055",
      "COUNTYNS": "01266988",
      "AFFGEOID": "0500000US46055",
      "GEOID": "46055",
      "NAME": "Haakon",
      "LSAD": "06",
      "ALAND": 4689280303,
      "AWATER": 42449686,
      "County_state": "Haakon,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.00723,
              44.343785
            ],
            [
              -102.007336,
              43.99453
            ],
            [
              -101.996224,
              43.994854
            ],
            [
              -101.968081,
              43.994483
            ],
            [
              -101.064068,
              43.994929
            ],
            [
              -101.046786,
              43.99497
            ],
            [
              -101.046916,
              44.168484
            ],
            [
              -101.166753,
              44.168085
            ],
            [
              -101.166805,
              44.343113
            ],
            [
              -101.155188,
              44.343147
            ],
            [
              -101.154516,
              44.689947
            ],
            [
              -101.137163,
              44.689962
            ],
            [
              -101.136838,
              44.747126
            ],
            [
              -101.13956,
              44.747062
            ],
            [
              -101.153674,
              44.728832
            ],
            [
              -101.224556,
              44.694234
            ],
            [
              -101.242804,
              44.698721
            ],
            [
              -101.256234,
              44.696349
            ],
            [
              -101.268518,
              44.689536
            ],
            [
              -101.294824,
              44.689319
            ],
            [
              -101.306077,
              44.684754
            ],
            [
              -101.326042,
              44.684646
            ],
            [
              -101.33295,
              44.69065
            ],
            [
              -101.352049,
              44.687942
            ],
            [
              -101.359214,
              44.679748
            ],
            [
              -101.363577,
              44.672408
            ],
            [
              -101.369411,
              44.673788
            ],
            [
              -101.372145,
              44.667368
            ],
            [
              -101.368318,
              44.664653
            ],
            [
              -101.374599,
              44.658239
            ],
            [
              -101.384893,
              44.653695
            ],
            [
              -101.397433,
              44.65678
            ],
            [
              -101.409021,
              44.649629
            ],
            [
              -101.427527,
              44.640914
            ],
            [
              -101.447298,
              44.638129
            ],
            [
              -101.45408,
              44.631187
            ],
            [
              -101.462719,
              44.616078
            ],
            [
              -101.461567,
              44.611311
            ],
            [
              -101.471903,
              44.609285
            ],
            [
              -101.475384,
              44.603023
            ],
            [
              -101.487236,
              44.6022
            ],
            [
              -101.497929,
              44.596481
            ],
            [
              -101.495002,
              44.590945
            ],
            [
              -101.50007,
              44.581569
            ],
            [
              -101.50605,
              44.585045
            ],
            [
              -101.514448,
              44.58286
            ],
            [
              -101.519316,
              44.574276
            ],
            [
              -101.5262,
              44.570333
            ],
            [
              -101.533933,
              44.570685
            ],
            [
              -101.538841,
              44.573885
            ],
            [
              -101.546715,
              44.574305
            ],
            [
              -101.55208,
              44.575768
            ],
            [
              -101.554607,
              44.571443
            ],
            [
              -101.562261,
              44.573399
            ],
            [
              -101.57404,
              44.577976
            ],
            [
              -101.588483,
              44.574521
            ],
            [
              -101.601175,
              44.576043
            ],
            [
              -101.614437,
              44.57261
            ],
            [
              -101.62409,
              44.573239
            ],
            [
              -101.627164,
              44.579155
            ],
            [
              -101.628245,
              44.583413
            ],
            [
              -101.636874,
              44.584027
            ],
            [
              -101.643812,
              44.5811
            ],
            [
              -101.652686,
              44.58314
            ],
            [
              -101.66221,
              44.586269
            ],
            [
              -101.674574,
              44.58116
            ],
            [
              -101.680788,
              44.572265
            ],
            [
              -101.69026,
              44.572392
            ],
            [
              -101.695184,
              44.575184
            ],
            [
              -101.706004,
              44.577803
            ],
            [
              -101.716233,
              44.576074
            ],
            [
              -101.721847,
              44.569565
            ],
            [
              -101.73398,
              44.567874
            ],
            [
              -101.743924,
              44.568102
            ],
            [
              -101.744041,
              44.559686
            ],
            [
              -101.757534,
              44.548809
            ],
            [
              -101.773434,
              44.541079
            ],
            [
              -101.784658,
              44.540785
            ],
            [
              -101.801385,
              44.53966
            ],
            [
              -101.806106,
              44.532875
            ],
            [
              -101.805396,
              44.528468
            ],
            [
              -101.814288,
              44.524742
            ],
            [
              -101.814118,
              44.528451
            ],
            [
              -101.823797,
              44.531897
            ],
            [
              -101.83152,
              44.526962
            ],
            [
              -101.839248,
              44.523963
            ],
            [
              -101.843159,
              44.527487
            ],
            [
              -101.849157,
              44.52927
            ],
            [
              -101.861688,
              44.530611
            ],
            [
              -101.872913,
              44.532422
            ],
            [
              -101.880659,
              44.532896
            ],
            [
              -101.885365,
              44.536212
            ],
            [
              -101.89247,
              44.537065
            ],
            [
              -101.900836,
              44.535753
            ],
            [
              -101.912376,
              44.532885
            ],
            [
              -101.922155,
              44.530531
            ],
            [
              -101.933666,
              44.531404
            ],
            [
              -101.94278,
              44.533666
            ],
            [
              -101.959284,
              44.528454
            ],
            [
              -101.971118,
              44.526081
            ],
            [
              -101.979756,
              44.522837
            ],
            [
              -101.990267,
              44.52232
            ],
            [
              -101.999105,
              44.511792
            ],
            [
              -102.000432,
              44.511663
            ],
            [
              -102.001068,
              44.510926
            ],
            [
              -102.000943,
              44.345607
            ],
            [
              -102.00723,
              44.343785
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "005",
      "COUNTYNS": "01266986",
      "AFFGEOID": "0500000US46005",
      "GEOID": "46005",
      "NAME": "Beadle",
      "LSAD": "06",
      "ALAND": 3260045596,
      "AWATER": 15805388,
      "County_state": "Beadle,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.705762,
              44.634159
            ],
            [
              -98.705714,
              44.547283
            ],
            [
              -98.700453,
              44.196727
            ],
            [
              -98.332042,
              44.19662
            ],
            [
              -97.85366,
              44.195233
            ],
            [
              -97.853028,
              44.544398
            ],
            [
              -97.856226,
              44.631178
            ],
            [
              -97.977791,
              44.631602
            ],
            [
              -98.341856,
              44.632732
            ],
            [
              -98.705762,
              44.634159
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "061",
      "COUNTYNS": "01266979",
      "AFFGEOID": "0500000US46061",
      "GEOID": "46061",
      "NAME": "Hanson",
      "LSAD": "06",
      "ALAND": 1125387966,
      "AWATER": 2248724,
      "County_state": "Hanson,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.967279,
              43.850686
            ],
            [
              -97.965012,
              43.49904
            ],
            [
              -97.607012,
              43.499826
            ],
            [
              -97.607035,
              43.731326
            ],
            [
              -97.609038,
              43.76261
            ],
            [
              -97.608517,
              43.849059
            ],
            [
              -97.85111,
              43.849857
            ],
            [
              -97.967279,
              43.850686
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "057",
      "COUNTYNS": "01265766",
      "AFFGEOID": "0500000US46057",
      "GEOID": "46057",
      "NAME": "Hamlin",
      "LSAD": "06",
      "ALAND": 1313733694,
      "AWATER": 79864241,
      "County_state": "Hamlin,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.491346,
              44.804035
            ],
            [
              -97.492266,
              44.543884
            ],
            [
              -97.128024,
              44.543126
            ],
            [
              -97.120814,
              44.542925
            ],
            [
              -96.885504,
              44.543773
            ],
            [
              -96.88457,
              44.804436
            ],
            [
              -97.00598,
              44.804351
            ],
            [
              -97.248576,
              44.804233
            ],
            [
              -97.491346,
              44.804035
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "037",
      "COUNTYNS": "01265790",
      "AFFGEOID": "0500000US46037",
      "GEOID": "46037",
      "NAME": "Day",
      "LSAD": "06",
      "ALAND": 2662165809,
      "AWATER": 163751130,
      "County_state": "Day,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.981457,
              45.240415
            ],
            [
              -97.981792,
              45.153295
            ],
            [
              -97.494254,
              45.151631
            ],
            [
              -97.239991,
              45.151194
            ],
            [
              -97.226281,
              45.151826
            ],
            [
              -97.226244,
              45.297647
            ],
            [
              -97.227089,
              45.558158
            ],
            [
              -97.36464,
              45.558728
            ],
            [
              -97.373366,
              45.587634
            ],
            [
              -97.979592,
              45.588483
            ],
            [
              -97.981457,
              45.240415
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "089",
      "COUNTYNS": "01265762",
      "AFFGEOID": "0500000US46089",
      "GEOID": "46089",
      "NAME": "McPherson",
      "LSAD": "06",
      "ALAND": 2943892857,
      "AWATER": 38569612,
      "County_state": "McPherson,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.718071040168,
              45.9409070032797
            ],
            [
              -99.717466,
              45.593428
            ],
            [
              -99.707705,
              45.593391
            ],
            [
              -98.725002,
              45.591252
            ],
            [
              -98.7243745348833,
              45.9386863487944
            ],
            [
              -98.904429,
              45.93952
            ],
            [
              -98.905477,
              45.93952
            ],
            [
              -99.005642,
              45.939944
            ],
            [
              -99.0057543501525,
              45.9399443091284
            ],
            [
              -99.092868,
              45.940184
            ],
            [
              -99.102372,
              45.940158
            ],
            [
              -99.212571,
              45.940108
            ],
            [
              -99.213644,
              45.940116
            ],
            [
              -99.221672,
              45.940069
            ],
            [
              -99.222269,
              45.940071
            ],
            [
              -99.257745,
              45.94006
            ],
            [
              -99.276266,
              45.940188
            ],
            [
              -99.283968,
              45.940195
            ],
            [
              -99.297272,
              45.940165
            ],
            [
              -99.317875,
              45.940263
            ],
            [
              -99.344774,
              45.940299
            ],
            [
              -99.34496,
              45.940299
            ],
            [
              -99.378486,
              45.940403
            ],
            [
              -99.385565,
              45.940407
            ],
            [
              -99.40126,
              45.940367
            ],
            [
              -99.490254,
              45.940362
            ],
            [
              -99.49314,
              45.940383
            ],
            [
              -99.58878,
              45.941104
            ],
            [
              -99.61116,
              45.941098
            ],
            [
              -99.671938,
              45.941062
            ],
            [
              -99.692975,
              45.940949
            ],
            [
              -99.718071040168,
              45.9409070032797
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "023",
      "COUNTYNS": "01266976",
      "AFFGEOID": "0500000US46023",
      "GEOID": "46023",
      "NAME": "Charles Mix",
      "LSAD": "06",
      "ALAND": 2842481188,
      "AWATER": 136616766,
      "County_state": "Charles Mix,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.297998,
              43.499669
            ],
            [
              -99.310073,
              43.467935
            ],
            [
              -99.280798,
              43.444797
            ],
            [
              -99.255911,
              43.43709
            ],
            [
              -99.167204,
              43.43521
            ],
            [
              -99.152889,
              43.426908
            ],
            [
              -99.126383,
              43.380622
            ],
            [
              -99.108902,
              43.373003
            ],
            [
              -99.086251,
              43.339327
            ],
            [
              -99.069285,
              43.320688
            ],
            [
              -99.020249,
              43.290614
            ],
            [
              -98.981253,
              43.258945
            ],
            [
              -98.945735,
              43.248306
            ],
            [
              -98.896783,
              43.246909
            ],
            [
              -98.877733,
              43.22664
            ],
            [
              -98.87759,
              43.179981
            ],
            [
              -98.865627,
              43.159554
            ],
            [
              -98.853591,
              43.154623
            ],
            [
              -98.790429,
              43.150106
            ],
            [
              -98.773406,
              43.145919
            ],
            [
              -98.750362,
              43.129534
            ],
            [
              -98.714573,
              43.088914
            ],
            [
              -98.69046,
              43.076556
            ],
            [
              -98.645094,
              43.07129
            ],
            [
              -98.618559,
              43.074169
            ],
            [
              -98.557477,
              43.058965
            ],
            [
              -98.524871,
              43.039347
            ],
            [
              -98.506738,
              43.019291
            ],
            [
              -98.49855,
              42.99856
            ],
            [
              -98.495747,
              42.988032
            ],
            [
              -98.490483,
              42.977948
            ],
            [
              -98.478919,
              42.963539
            ],
            [
              -98.467356,
              42.947556
            ],
            [
              -98.461673,
              42.944427
            ],
            [
              -98.458515,
              42.943374
            ],
            [
              -98.45222,
              42.938389
            ],
            [
              -98.448309,
              42.936428
            ],
            [
              -98.447047,
              42.935117
            ],
            [
              -98.445861,
              42.93062
            ],
            [
              -98.444145,
              42.929242
            ],
            [
              -98.439743,
              42.928195
            ],
            [
              -98.437285,
              42.928393
            ],
            [
              -98.434503,
              42.929227
            ],
            [
              -98.430934,
              42.931504
            ],
            [
              -98.426287,
              42.9321
            ],
            [
              -98.42074,
              42.931924
            ],
            [
              -98.407824,
              42.92575
            ],
            [
              -98.399298,
              42.922465
            ],
            [
              -98.386445,
              42.918407
            ],
            [
              -98.375358,
              42.913132
            ],
            [
              -98.358047,
              42.907516
            ],
            [
              -98.34623,
              42.902747
            ],
            [
              -98.342408,
              42.900847
            ],
            [
              -98.33799,
              42.89776
            ],
            [
              -98.335846,
              42.895654
            ],
            [
              -98.333497,
              42.891532
            ],
            [
              -98.332423,
              42.890501
            ],
            [
              -98.329663,
              42.888441
            ],
            [
              -98.325864,
              42.8865
            ],
            [
              -98.319513,
              42.88454
            ],
            [
              -98.30976908505669,
              42.882559662424704
            ],
            [
              -98.297465,
              42.880059
            ],
            [
              -98.280007,
              42.874996
            ],
            [
              -98.268363,
              42.874152
            ],
            [
              -98.258276,
              42.87439
            ],
            [
              -98.25181,
              42.872824
            ],
            [
              -98.24982,
              42.871843
            ],
            [
              -98.24683,
              42.868397
            ],
            [
              -98.231922,
              42.86114
            ],
            [
              -98.226512,
              42.857742
            ],
            [
              -98.224231,
              42.855521
            ],
            [
              -98.219826,
              42.853157
            ],
            [
              -98.204506,
              42.846845
            ],
            [
              -98.189765,
              42.841628
            ],
            [
              -98.171113,
              42.837114
            ],
            [
              -98.167523,
              42.836925
            ],
            [
              -98.163262,
              42.837143
            ],
            [
              -98.15307905341639,
              42.839065448144595
            ],
            [
              -98.162954,
              42.849817
            ],
            [
              -98.147723,
              42.849516
            ],
            [
              -98.139472,
              42.864744
            ],
            [
              -98.117047,
              42.869386
            ],
            [
              -98.112185,
              42.891119
            ],
            [
              -98.123463,
              42.910372
            ],
            [
              -98.106642,
              42.926252
            ],
            [
              -98.098853,
              42.968498
            ],
            [
              -98.092782,
              42.973921
            ],
            [
              -98.113145,
              43.001228
            ],
            [
              -98.110636,
              43.02067
            ],
            [
              -98.094931,
              43.030555
            ],
            [
              -98.102792,
              43.037117
            ],
            [
              -98.109534,
              43.087477
            ],
            [
              -98.103001,
              43.112682
            ],
            [
              -98.093353,
              43.118357
            ],
            [
              -98.089817,
              43.152543
            ],
            [
              -98.077108,
              43.16833
            ],
            [
              -98.069044,
              43.184887
            ],
            [
              -98.109492,
              43.196838
            ],
            [
              -98.680688,
              43.373157
            ],
            [
              -98.706855,
              43.370075
            ],
            [
              -98.705782,
              43.499319
            ],
            [
              -98.794588,
              43.499187
            ],
            [
              -99.297882,
              43.499856
            ],
            [
              -99.297998,
              43.499669
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "013",
      "COUNTYNS": "01265785",
      "AFFGEOID": "0500000US46013",
      "GEOID": "46013",
      "NAME": "Brown",
      "LSAD": "06",
      "ALAND": 4436728163,
      "AWATER": 46414610,
      "County_state": "Brown,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.7243745348833,
              45.9386863487944
            ],
            [
              -98.725002,
              45.591252
            ],
            [
              -98.722228,
              45.584795
            ],
            [
              -98.72217,
              45.41751
            ],
            [
              -98.722481,
              45.243764
            ],
            [
              -98.717759,
              45.243732
            ],
            [
              -97.981457,
              45.240415
            ],
            [
              -97.979592,
              45.588483
            ],
            [
              -97.979238,
              45.762569
            ],
            [
              -97.97877778172959,
              45.935937093589494
            ],
            [
              -97.986893,
              45.935961
            ],
            [
              -98.00810165517849,
              45.93609536428029
            ],
            [
              -98.008202,
              45.936096
            ],
            [
              -98.070515,
              45.93618
            ],
            [
              -98.184637,
              45.936183
            ],
            [
              -98.18563,
              45.936185
            ],
            [
              -98.414518,
              45.936504
            ],
            [
              -98.50700555702849,
              45.93726017846979
            ],
            [
              -98.625379,
              45.938228
            ],
            [
              -98.7243745348833,
              45.9386863487944
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "46",
      "COUNTYFP": "039",
      "COUNTYNS": "01265781",
      "AFFGEOID": "0500000US46039",
      "GEOID": "46039",
      "NAME": "Deuel",
      "LSAD": "06",
      "ALAND": 1612756368,
      "AWATER": 36065238,
      "County_state": "Deuel,46"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.88457,
              44.804436
            ],
            [
              -96.885504,
              44.543773
            ],
            [
              -96.4519902127759,
              44.5436387679114
            ],
            [
              -96.451888,
              44.544058
            ],
            [
              -96.45172,
              44.630708
            ],
            [
              -96.451761,
              44.631194
            ],
            [
              -96.4517605285233,
              44.6313495894722
            ],
            [
              -96.451543,
              44.703135
            ],
            [
              -96.451232,
              44.718375
            ],
            [
              -96.451573,
              44.76051
            ],
            [
              -96.45138,
              44.761788
            ],
            [
              -96.45162,
              44.776191
            ],
            [
              -96.451823,
              44.790471
            ],
            [
              -96.451888,
              44.792299
            ],
            [
              -96.451829,
              44.797691
            ],
            [
              -96.451559,
              44.805468
            ],
            [
              -96.4515595397173,
              44.8055694812453
            ],
            [
              -96.452009,
              44.89008
            ],
            [
              -96.451853,
              44.906672
            ],
            [
              -96.452047,
              44.910695
            ],
            [
              -96.452347,
              44.962734
            ],
            [
              -96.452092,
              44.977475
            ],
            [
              -96.4520920423924,
              44.9774935816171
            ],
            [
              -96.882345,
              44.97687
            ],
            [
              -96.88457,
              44.804436
            ]
          ]
        ]
      ]
    }
  }
]
};