export const counties_ar = {
  "type":"FeatureCollection","name":"ar_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "037",
      "COUNTYNS": "00066848",
      "AFFGEOID": "0500000US05037",
      "GEOID": "05037",
      "NAME": "Cross",
      "LSAD": "06",
      "ALAND": 1596421812,
      "AWATER": 15344834,
      "County_state": "Cross,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.039783,
              35.354256
            ],
            [
              -91.04267,
              35.148189
            ],
            [
              -90.82915,
              35.14875
            ],
            [
              -90.82214,
              35.148161
            ],
            [
              -90.721541,
              35.147926
            ],
            [
              -90.637384,
              35.145109
            ],
            [
              -90.633626,
              35.14686
            ],
            [
              -90.61152,
              35.14642
            ],
            [
              -90.609896,
              35.145082
            ],
            [
              -90.503015,
              35.143368
            ],
            [
              -90.502993,
              35.144214
            ],
            [
              -90.506475,
              35.178715
            ],
            [
              -90.503127,
              35.353962
            ],
            [
              -90.502429,
              35.441726
            ],
            [
              -90.610911,
              35.441663
            ],
            [
              -90.610753,
              35.448769
            ],
            [
              -90.716132,
              35.445771
            ],
            [
              -90.82382,
              35.44575
            ],
            [
              -90.931248,
              35.441829
            ],
            [
              -91.03923,
              35.444717
            ],
            [
              -91.03964,
              35.39831
            ],
            [
              -91.039783,
              35.354256
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "065",
      "COUNTYNS": "00066860",
      "AFFGEOID": "0500000US05065",
      "GEOID": "05065",
      "NAME": "Izard",
      "LSAD": "06",
      "ALAND": 1503683620,
      "AWATER": 8913818,
      "County_state": "Izard,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.2139,
              36.1751
            ],
            [
              -92.215203,
              36.133452
            ],
            [
              -92.19543,
              36.132798
            ],
            [
              -92.18921,
              36.105324
            ],
            [
              -92.17644,
              36.102159
            ],
            [
              -92.154047,
              36.115395
            ],
            [
              -92.139104,
              36.112221
            ],
            [
              -92.125492,
              36.08181
            ],
            [
              -92.11303,
              36.064552
            ],
            [
              -92.074682,
              36.053108
            ],
            [
              -92.057224,
              36.034537
            ],
            [
              -92.063576,
              36.024372
            ],
            [
              -92.091757,
              36.020227
            ],
            [
              -92.099442,
              36.005762
            ],
            [
              -92.085836,
              35.986256
            ],
            [
              -92.085485,
              35.965943
            ],
            [
              -92.110537,
              35.950102
            ],
            [
              -92.112677,
              35.93665
            ],
            [
              -92.079736,
              35.927257
            ],
            [
              -92.047455,
              35.943431
            ],
            [
              -92.029946,
              35.939232
            ],
            [
              -92.027448,
              35.922897
            ],
            [
              -92.004911,
              35.919358
            ],
            [
              -91.981635,
              35.93975
            ],
            [
              -91.968515,
              35.940225
            ],
            [
              -91.95302,
              35.931644
            ],
            [
              -91.936707,
              35.912354
            ],
            [
              -91.896361,
              35.882813
            ],
            [
              -91.866202,
              35.882198
            ],
            [
              -91.849448,
              35.867411
            ],
            [
              -91.753345,
              35.942883
            ],
            [
              -91.710552,
              35.941759
            ],
            [
              -91.709363,
              36.000917
            ],
            [
              -91.682277,
              36.000535
            ],
            [
              -91.691142,
              36.019083
            ],
            [
              -91.681542,
              36.02287
            ],
            [
              -91.679943,
              36.164668
            ],
            [
              -91.674143,
              36.208567
            ],
            [
              -91.692243,
              36.209167
            ],
            [
              -91.691435,
              36.253301
            ],
            [
              -91.836476,
              36.255398
            ],
            [
              -92.15699,
              36.261248
            ],
            [
              -92.159885,
              36.173782
            ],
            [
              -92.2139,
              36.1751
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "025",
      "COUNTYNS": "00063760",
      "AFFGEOID": "0500000US05025",
      "GEOID": "05025",
      "NAME": "Cleveland",
      "LSAD": "06",
      "ALAND": 1548252545,
      "AWATER": 2500346,
      "County_state": "Cleveland,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.471667,
              34.057054
            ],
            [
              -92.475602,
              33.972696
            ],
            [
              -92.45613,
              33.972622
            ],
            [
              -92.42022,
              33.938486
            ],
            [
              -92.400798,
              33.91356
            ],
            [
              -92.394007,
              33.886717
            ],
            [
              -92.38118,
              33.869367
            ],
            [
              -92.353225,
              33.853099
            ],
            [
              -92.337389,
              33.82813
            ],
            [
              -92.334249,
              33.795065
            ],
            [
              -92.327042,
              33.777809
            ],
            [
              -92.341845,
              33.742896
            ],
            [
              -92.330839,
              33.707806
            ],
            [
              -92.285272,
              33.705553
            ],
            [
              -92.184267,
              33.705286
            ],
            [
              -91.975844,
              33.704414
            ],
            [
              -91.975974,
              33.791787
            ],
            [
              -91.976439,
              33.977297
            ],
            [
              -91.953049,
              33.977188
            ],
            [
              -91.953799,
              34.064141
            ],
            [
              -91.988497,
              34.063934
            ],
            [
              -92.069815,
              34.063518
            ],
            [
              -92.233376,
              34.062312
            ],
            [
              -92.336765,
              34.059633
            ],
            [
              -92.471667,
              34.057054
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "115",
      "COUNTYNS": "00069901",
      "AFFGEOID": "0500000US05115",
      "GEOID": "05115",
      "NAME": "Pope",
      "LSAD": "06",
      "ALAND": 2104418088,
      "AWATER": 47279033,
      "County_state": "Pope,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.297938,
              35.382797
            ],
            [
              -93.299014,
              35.327425
            ],
            [
              -93.285744,
              35.320112
            ],
            [
              -93.278293,
              35.317402
            ],
            [
              -93.251889,
              35.297876
            ],
            [
              -93.246446,
              35.277078
            ],
            [
              -93.234656,
              35.273806
            ],
            [
              -93.200975,
              35.27182
            ],
            [
              -93.183471,
              35.262324
            ],
            [
              -93.156078,
              35.235307
            ],
            [
              -93.140065,
              35.20968
            ],
            [
              -93.13026,
              35.180476
            ],
            [
              -93.115102,
              35.171708
            ],
            [
              -93.087978,
              35.1816
            ],
            [
              -93.078125,
              35.178891
            ],
            [
              -93.080891,
              35.166197
            ],
            [
              -93.092088,
              35.144726
            ],
            [
              -93.084678,
              35.12739
            ],
            [
              -93.061066,
              35.117531
            ],
            [
              -93.04566,
              35.118398
            ],
            [
              -93.032466,
              35.125083
            ],
            [
              -93.027899,
              35.132928
            ],
            [
              -93.030386,
              35.152546
            ],
            [
              -93.027647,
              35.159793
            ],
            [
              -93.021807,
              35.163309
            ],
            [
              -92.923746,
              35.17608
            ],
            [
              -92.896109,
              35.170678
            ],
            [
              -92.855,
              35.172777
            ],
            [
              -92.854319,
              35.260196
            ],
            [
              -92.850624,
              35.46295
            ],
            [
              -92.849735,
              35.513693
            ],
            [
              -92.849378,
              35.549204
            ],
            [
              -92.813417,
              35.549
            ],
            [
              -92.809763,
              35.722401
            ],
            [
              -92.951315,
              35.724663
            ],
            [
              -93.164452,
              35.728831
            ],
            [
              -93.1678,
              35.597695
            ],
            [
              -93.22117,
              35.598938
            ],
            [
              -93.22345,
              35.483094
            ],
            [
              -93.223633,
              35.468593
            ],
            [
              -93.29591,
              35.469915
            ],
            [
              -93.297938,
              35.382797
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "141",
      "COUNTYNS": "00069904",
      "AFFGEOID": "0500000US05141",
      "GEOID": "05141",
      "NAME": "Van Buren",
      "LSAD": "06",
      "ALAND": 1838200422,
      "AWATER": 37692771,
      "County_state": "Van Buren,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.849735,
              35.513693
            ],
            [
              -92.850624,
              35.46295
            ],
            [
              -92.567229,
              35.456936
            ],
            [
              -92.479268,
              35.455357
            ],
            [
              -92.481469,
              35.368392
            ],
            [
              -92.3998,
              35.366845
            ],
            [
              -92.391702,
              35.366777
            ],
            [
              -92.252759,
              35.363718
            ],
            [
              -92.248816,
              35.523591
            ],
            [
              -92.244721,
              35.537933
            ],
            [
              -92.24411,
              35.569827
            ],
            [
              -92.241653,
              35.646638
            ],
            [
              -92.24052,
              35.71219
            ],
            [
              -92.418408,
              35.716046
            ],
            [
              -92.416719,
              35.78859
            ],
            [
              -92.598351,
              35.790122
            ],
            [
              -92.629837,
              35.790823
            ],
            [
              -92.631207,
              35.718365
            ],
            [
              -92.809763,
              35.722401
            ],
            [
              -92.813417,
              35.549
            ],
            [
              -92.849378,
              35.549204
            ],
            [
              -92.849735,
              35.513693
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "043",
      "COUNTYNS": "00066851",
      "AFFGEOID": "0500000US05043",
      "GEOID": "05043",
      "NAME": "Drew",
      "LSAD": "06",
      "ALAND": 2145434146,
      "AWATER": 18914033,
      "County_state": "Drew,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.015334,
              33.457092
            ],
            [
              -92.011662,
              33.446205
            ],
            [
              -91.992276,
              33.435841
            ],
            [
              -92.002087,
              33.415809
            ],
            [
              -91.990167,
              33.398214
            ],
            [
              -91.890827,
              33.396417
            ],
            [
              -91.872532,
              33.396343
            ],
            [
              -91.606099,
              33.391424
            ],
            [
              -91.45737,
              33.388974
            ],
            [
              -91.453438,
              33.563703
            ],
            [
              -91.450325,
              33.780415
            ],
            [
              -91.500123,
              33.780569
            ],
            [
              -91.557648,
              33.783384
            ],
            [
              -91.663738,
              33.792246
            ],
            [
              -91.769049,
              33.792001
            ],
            [
              -91.834382,
              33.791664
            ],
            [
              -91.839053,
              33.791771
            ],
            [
              -91.975974,
              33.791787
            ],
            [
              -91.975844,
              33.704414
            ],
            [
              -91.975852,
              33.697115
            ],
            [
              -91.97608,
              33.529687
            ],
            [
              -92.00785,
              33.529396
            ],
            [
              -91.999119,
              33.50011
            ],
            [
              -92.008934,
              33.486828
            ],
            [
              -92.000134,
              33.473927
            ],
            [
              -92.015334,
              33.457092
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "125",
      "COUNTYNS": "00067102",
      "AFFGEOID": "0500000US05125",
      "GEOID": "05125",
      "NAME": "Saline",
      "LSAD": "06",
      "ALAND": 1873978476,
      "AWATER": 17979687,
      "County_state": "Saline,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.073877,
              34.858091
            ],
            [
              -93.075178,
              34.771892
            ],
            [
              -92.96925,
              34.772266
            ],
            [
              -92.96949,
              34.701246
            ],
            [
              -92.979447,
              34.684811
            ],
            [
              -92.979902,
              34.637222
            ],
            [
              -92.875456,
              34.635124
            ],
            [
              -92.876976,
              34.59112
            ],
            [
              -92.808028,
              34.590406
            ],
            [
              -92.808423,
              34.577165
            ],
            [
              -92.808952,
              34.562248
            ],
            [
              -92.791498,
              34.561985
            ],
            [
              -92.793197,
              34.503606
            ],
            [
              -92.775581,
              34.503736
            ],
            [
              -92.738261,
              34.503283
            ],
            [
              -92.738905,
              34.473885
            ],
            [
              -92.685284,
              34.473105
            ],
            [
              -92.686155,
              34.414695
            ],
            [
              -92.668711,
              34.414237
            ],
            [
              -92.624706,
              34.413147
            ],
            [
              -92.623158,
              34.425775
            ],
            [
              -92.589418,
              34.455641
            ],
            [
              -92.477433,
              34.453294
            ],
            [
              -92.469836,
              34.453149
            ],
            [
              -92.40468,
              34.451916
            ],
            [
              -92.403557,
              34.495795
            ],
            [
              -92.395088,
              34.49564
            ],
            [
              -92.245818,
              34.493506
            ],
            [
              -92.244209,
              34.58089
            ],
            [
              -92.3277,
              34.581943
            ],
            [
              -92.348749,
              34.58229
            ],
            [
              -92.347218,
              34.611671
            ],
            [
              -92.346598,
              34.622704
            ],
            [
              -92.349917,
              34.625688
            ],
            [
              -92.445148,
              34.62694
            ],
            [
              -92.444336,
              34.646452
            ],
            [
              -92.443265,
              34.672041
            ],
            [
              -92.44913,
              34.672195
            ],
            [
              -92.500158,
              34.673692
            ],
            [
              -92.49907,
              34.6879
            ],
            [
              -92.551963,
              34.689432
            ],
            [
              -92.55,
              34.75811
            ],
            [
              -92.552187,
              34.761762
            ],
            [
              -92.654324,
              34.764541
            ],
            [
              -92.651248,
              34.851682
            ],
            [
              -92.739779,
              34.853459
            ],
            [
              -92.7406,
              34.828121
            ],
            [
              -92.75643,
              34.842562
            ],
            [
              -92.780651,
              34.839594
            ],
            [
              -92.802407,
              34.849845
            ],
            [
              -92.81773,
              34.84756
            ],
            [
              -92.829685,
              34.855339
            ],
            [
              -92.969442,
              34.859109
            ],
            [
              -93.073877,
              34.858091
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "081",
      "COUNTYNS": "00066874",
      "AFFGEOID": "0500000US05081",
      "GEOID": "05081",
      "NAME": "Little River",
      "LSAD": "06",
      "ALAND": 1378586566,
      "AWATER": 84150136,
      "County_state": "Little River,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.485577,
              33.65331
            ],
            [
              -94.485875,
              33.637867
            ],
            [
              -94.481313,
              33.638819
            ],
            [
              -94.459198,
              33.645146
            ],
            [
              -94.452325,
              33.618817
            ],
            [
              -94.458232,
              33.59827
            ],
            [
              -94.436215,
              33.594199
            ],
            [
              -94.419057,
              33.577217
            ],
            [
              -94.385927,
              33.581888
            ],
            [
              -94.37776,
              33.574609
            ],
            [
              -94.388052,
              33.565511
            ],
            [
              -94.392573,
              33.551142
            ],
            [
              -94.35897,
              33.54323
            ],
            [
              -94.352653,
              33.560611
            ],
            [
              -94.338422,
              33.567082
            ],
            [
              -94.33059,
              33.552692
            ],
            [
              -94.309582,
              33.551673
            ],
            [
              -94.298392,
              33.576218
            ],
            [
              -94.280605,
              33.574908
            ],
            [
              -94.270853,
              33.564783
            ],
            [
              -94.252656,
              33.586144
            ],
            [
              -94.238868,
              33.576722
            ],
            [
              -94.250197,
              33.556765
            ],
            [
              -94.226392,
              33.552912
            ],
            [
              -94.211329,
              33.573774
            ],
            [
              -94.183395,
              33.592212
            ],
            [
              -94.162266,
              33.588906
            ],
            [
              -94.143024,
              33.577725
            ],
            [
              -94.123898,
              33.5521
            ],
            [
              -94.103176,
              33.57035
            ],
            [
              -94.07267,
              33.572234
            ],
            [
              -94.056096,
              33.567252
            ],
            [
              -94.050212,
              33.551083
            ],
            [
              -94.04604,
              33.551321
            ],
            [
              -94.04345,
              33.552253
            ],
            [
              -93.998642,
              33.564382
            ],
            [
              -93.99197,
              33.55825
            ],
            [
              -93.998908,
              33.540265
            ],
            [
              -93.986265,
              33.533673
            ],
            [
              -93.971339,
              33.554501
            ],
            [
              -93.977669,
              33.57649
            ],
            [
              -93.967409,
              33.581052
            ],
            [
              -93.951474,
              33.549282
            ],
            [
              -93.935606,
              33.550176
            ],
            [
              -93.940888,
              33.571962
            ],
            [
              -93.93005,
              33.582819
            ],
            [
              -93.897568,
              33.57184
            ],
            [
              -93.889456,
              33.59273
            ],
            [
              -93.875908,
              33.591075
            ],
            [
              -93.857147,
              33.611239
            ],
            [
              -93.828945,
              33.603136
            ],
            [
              -93.825884,
              33.609853
            ],
            [
              -93.839836,
              33.616474
            ],
            [
              -93.852738,
              33.613823
            ],
            [
              -93.861117,
              33.627825
            ],
            [
              -93.873169,
              33.634109
            ],
            [
              -93.884499,
              33.627183
            ],
            [
              -93.891228,
              33.637703
            ],
            [
              -93.916703,
              33.629886
            ],
            [
              -93.90314,
              33.651031
            ],
            [
              -93.913259,
              33.662942
            ],
            [
              -93.929858,
              33.663605
            ],
            [
              -93.938964,
              33.647633
            ],
            [
              -93.946024,
              33.659437
            ],
            [
              -93.966973,
              33.672308
            ],
            [
              -93.960675,
              33.693886
            ],
            [
              -93.966739,
              33.704122
            ],
            [
              -93.951434,
              33.706902
            ],
            [
              -93.96462,
              33.713951
            ],
            [
              -93.969431,
              33.728111
            ],
            [
              -93.957315,
              33.75094
            ],
            [
              -93.958828,
              33.750994
            ],
            [
              -94.041786,
              33.753247
            ],
            [
              -94.040849,
              33.782046
            ],
            [
              -94.086563,
              33.783244
            ],
            [
              -94.121438,
              33.791212
            ],
            [
              -94.143925,
              33.782071
            ],
            [
              -94.146757,
              33.789743
            ],
            [
              -94.161998,
              33.784132
            ],
            [
              -94.190017,
              33.789216
            ],
            [
              -94.183492,
              33.809992
            ],
            [
              -94.191793,
              33.812089
            ],
            [
              -94.217804,
              33.803544
            ],
            [
              -94.218274,
              33.814497
            ],
            [
              -94.237445,
              33.802074
            ],
            [
              -94.251417,
              33.817891
            ],
            [
              -94.269092,
              33.817811
            ],
            [
              -94.285096,
              33.830057
            ],
            [
              -94.303002,
              33.821414
            ],
            [
              -94.303544,
              33.853648
            ],
            [
              -94.324878,
              33.863241
            ],
            [
              -94.333257,
              33.8605
            ],
            [
              -94.340911,
              33.882605
            ],
            [
              -94.372266,
              33.877475
            ],
            [
              -94.361605,
              33.887166
            ],
            [
              -94.388548,
              33.895176
            ],
            [
              -94.379773,
              33.933064
            ],
            [
              -94.38541,
              33.940887
            ],
            [
              -94.408672,
              33.945281
            ],
            [
              -94.421635,
              33.942972
            ],
            [
              -94.412155,
              33.926208
            ],
            [
              -94.435919,
              33.931894
            ],
            [
              -94.446535,
              33.929242
            ],
            [
              -94.467305,
              33.944351
            ],
            [
              -94.4773181199803,
              33.9409324150055
            ],
            [
              -94.477387,
              33.937759
            ],
            [
              -94.478842,
              33.881485
            ],
            [
              -94.478994,
              33.881197
            ],
            [
              -94.479954,
              33.85133
            ],
            [
              -94.480574,
              33.830166
            ],
            [
              -94.481355,
              33.802887
            ],
            [
              -94.481361,
              33.802649
            ],
            [
              -94.481543,
              33.795719
            ],
            [
              -94.481842,
              33.789008
            ],
            [
              -94.482682,
              33.756286
            ],
            [
              -94.482777,
              33.753638
            ],
            [
              -94.482862,
              33.75078
            ],
            [
              -94.48287,
              33.750564
            ],
            [
              -94.483874,
              33.716733
            ],
            [
              -94.48384,
              33.711332
            ],
            [
              -94.484616,
              33.691592
            ],
            [
              -94.48452,
              33.687909
            ],
            [
              -94.485528,
              33.663388
            ],
            [
              -94.485577,
              33.65331
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "143",
      "COUNTYNS": "00069905",
      "AFFGEOID": "0500000US05143",
      "GEOID": "05143",
      "NAME": "Washington",
      "LSAD": "06",
      "ALAND": 2439678769,
      "AWATER": 27051804,
      "County_state": "Washington,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.55218431116519,
              36.1022352237111
            ],
            [
              -94.547871,
              36.078281
            ],
            [
              -94.547715,
              36.077271
            ],
            [
              -94.535724,
              36.007807
            ],
            [
              -94.534852,
              36.002678
            ],
            [
              -94.533646,
              35.996804
            ],
            [
              -94.532071,
              35.987852
            ],
            [
              -94.528305,
              35.966054
            ],
            [
              -94.528162,
              35.965665
            ],
            [
              -94.52464,
              35.945727
            ],
            [
              -94.524344,
              35.94405
            ],
            [
              -94.52291,
              35.936127
            ],
            [
              -94.522634,
              35.934892
            ],
            [
              -94.522658,
              35.934799
            ],
            [
              -94.522658,
              35.93425
            ],
            [
              -94.517571,
              35.901866
            ],
            [
              -94.507631,
              35.845901
            ],
            [
              -94.505642,
              35.833628
            ],
            [
              -94.504438,
              35.826369
            ],
            [
              -94.503011,
              35.81721
            ],
            [
              -94.501162,
              35.80643
            ],
            [
              -94.500764,
              35.80382
            ],
            [
              -94.500526,
              35.802642
            ],
            [
              -94.499647,
              35.79691
            ],
            [
              -94.499045,
              35.79346
            ],
            [
              -94.494549,
              35.768303
            ],
            [
              -94.493362,
              35.761892
            ],
            [
              -94.4929318527681,
              35.7591658417281
            ],
            [
              -94.387388,
              35.757254
            ],
            [
              -94.283496,
              35.754793
            ],
            [
              -94.280993,
              35.754707
            ],
            [
              -94.212507,
              35.75292
            ],
            [
              -94.124871,
              35.750426
            ],
            [
              -94.124258,
              35.764636
            ],
            [
              -94.106411,
              35.764255
            ],
            [
              -93.962892,
              35.76093
            ],
            [
              -93.960619,
              35.833283
            ],
            [
              -93.951846,
              35.833259
            ],
            [
              -93.949917,
              35.890992
            ],
            [
              -93.947259,
              35.978132
            ],
            [
              -93.94574,
              36.033853
            ],
            [
              -93.944177,
              36.091103
            ],
            [
              -93.887775,
              36.235046
            ],
            [
              -93.943988,
              36.236177
            ],
            [
              -93.957701,
              36.22242
            ],
            [
              -93.97224,
              36.228529
            ],
            [
              -93.973298,
              36.203852
            ],
            [
              -93.983472,
              36.21221
            ],
            [
              -93.998167,
              36.210903
            ],
            [
              -94.007947,
              36.23022
            ],
            [
              -94.024268,
              36.223754
            ],
            [
              -94.017561,
              36.208277
            ],
            [
              -94.093256,
              36.209763
            ],
            [
              -94.113364,
              36.210061
            ],
            [
              -94.137865,
              36.210348
            ],
            [
              -94.173776,
              36.211389
            ],
            [
              -94.181923,
              36.211551
            ],
            [
              -94.310845,
              36.214224
            ],
            [
              -94.333565,
              36.214727
            ],
            [
              -94.334872,
              36.181044
            ],
            [
              -94.33516,
              36.170734
            ],
            [
              -94.33643,
              36.142248
            ],
            [
              -94.389936,
              36.142591
            ],
            [
              -94.394897,
              36.099471
            ],
            [
              -94.420703,
              36.099823
            ],
            [
              -94.55218431116519,
              36.1022352237111
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "119",
      "COUNTYNS": "00069177",
      "AFFGEOID": "0500000US05119",
      "GEOID": "05119",
      "NAME": "Pulaski",
      "LSAD": "06",
      "ALAND": 1965575733,
      "AWATER": 126242806,
      "County_state": "Pulaski,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.753626,
              34.911248
            ],
            [
              -92.755434,
              34.853711
            ],
            [
              -92.739779,
              34.853459
            ],
            [
              -92.651248,
              34.851682
            ],
            [
              -92.654324,
              34.764541
            ],
            [
              -92.552187,
              34.761762
            ],
            [
              -92.55,
              34.75811
            ],
            [
              -92.551963,
              34.689432
            ],
            [
              -92.49907,
              34.6879
            ],
            [
              -92.500158,
              34.673692
            ],
            [
              -92.44913,
              34.672195
            ],
            [
              -92.443265,
              34.672041
            ],
            [
              -92.444336,
              34.646452
            ],
            [
              -92.445148,
              34.62694
            ],
            [
              -92.349917,
              34.625688
            ],
            [
              -92.346598,
              34.622704
            ],
            [
              -92.347218,
              34.611671
            ],
            [
              -92.348749,
              34.58229
            ],
            [
              -92.3277,
              34.581943
            ],
            [
              -92.244209,
              34.58089
            ],
            [
              -92.245818,
              34.493506
            ],
            [
              -92.208041,
              34.492765
            ],
            [
              -92.14369,
              34.49151
            ],
            [
              -92.029996,
              34.489352
            ],
            [
              -92.029539,
              34.621248
            ],
            [
              -92.113313,
              34.622966
            ],
            [
              -92.098843,
              34.658939
            ],
            [
              -92.100738,
              34.668606
            ],
            [
              -92.093389,
              34.710763
            ],
            [
              -92.078711,
              34.729983
            ],
            [
              -92.076654,
              34.790359
            ],
            [
              -92.074897,
              34.837257
            ],
            [
              -92.075601,
              34.842878
            ],
            [
              -92.075546,
              34.843113
            ],
            [
              -92.074372,
              34.8666
            ],
            [
              -92.073159,
              34.897805
            ],
            [
              -92.07258,
              34.918248
            ],
            [
              -92.070805,
              35.011802
            ],
            [
              -92.121336,
              35.01221
            ],
            [
              -92.207877,
              35.014277
            ],
            [
              -92.227541,
              35.014484
            ],
            [
              -92.229893,
              34.927558
            ],
            [
              -92.261896,
              34.92815
            ],
            [
              -92.419276,
              34.931675
            ],
            [
              -92.417857,
              34.913427
            ],
            [
              -92.450316,
              34.901319
            ],
            [
              -92.442352,
              34.870211
            ],
            [
              -92.44723,
              34.856954
            ],
            [
              -92.436108,
              34.842218
            ],
            [
              -92.461655,
              34.857064
            ],
            [
              -92.453007,
              34.906257
            ],
            [
              -92.476379,
              34.946102
            ],
            [
              -92.483512,
              34.950937
            ],
            [
              -92.544341,
              34.953319
            ],
            [
              -92.544378,
              34.951832
            ],
            [
              -92.544614,
              34.936256
            ],
            [
              -92.586582,
              34.937503
            ],
            [
              -92.647754,
              34.939222
            ],
            [
              -92.64989,
              34.91003
            ],
            [
              -92.753626,
              34.911248
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "085",
      "COUNTYNS": "00069164",
      "AFFGEOID": "0500000US05085",
      "GEOID": "05085",
      "NAME": "Lonoke",
      "LSAD": "06",
      "ALAND": 1996628025,
      "AWATER": 82740471,
      "County_state": "Lonoke,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.120885,
              35.027372
            ],
            [
              -92.121336,
              35.01221
            ],
            [
              -92.070805,
              35.011802
            ],
            [
              -92.07258,
              34.918248
            ],
            [
              -92.073159,
              34.897805
            ],
            [
              -92.074372,
              34.8666
            ],
            [
              -92.075546,
              34.843113
            ],
            [
              -92.075601,
              34.842878
            ],
            [
              -92.074897,
              34.837257
            ],
            [
              -92.076654,
              34.790359
            ],
            [
              -92.078711,
              34.729983
            ],
            [
              -92.093389,
              34.710763
            ],
            [
              -92.100738,
              34.668606
            ],
            [
              -92.098843,
              34.658939
            ],
            [
              -92.113313,
              34.622966
            ],
            [
              -92.029539,
              34.621248
            ],
            [
              -92.029996,
              34.489352
            ],
            [
              -92.014398,
              34.48947
            ],
            [
              -91.974805,
              34.488683
            ],
            [
              -91.705185,
              34.48267
            ],
            [
              -91.678037,
              34.48192
            ],
            [
              -91.681051,
              34.499054
            ],
            [
              -91.669914,
              34.51887
            ],
            [
              -91.63283,
              34.552711
            ],
            [
              -91.636605,
              34.566532
            ],
            [
              -91.665029,
              34.579888
            ],
            [
              -91.648314,
              34.612762
            ],
            [
              -91.638531,
              34.626093
            ],
            [
              -91.64308,
              34.636862
            ],
            [
              -91.693098,
              34.670963
            ],
            [
              -91.700828,
              34.672029
            ],
            [
              -91.694682,
              34.875687
            ],
            [
              -91.693705,
              34.919605
            ],
            [
              -91.803703,
              34.921524
            ],
            [
              -91.802505,
              35.030425
            ],
            [
              -91.87888,
              35.024577
            ],
            [
              -91.89575,
              35.032614
            ],
            [
              -91.901281,
              35.028906
            ],
            [
              -91.928015,
              35.046803
            ],
            [
              -91.950873,
              35.045278
            ],
            [
              -91.982298,
              35.055805
            ],
            [
              -91.980626,
              35.058089
            ],
            [
              -92.001546,
              35.06229
            ],
            [
              -92.012735,
              35.076251
            ],
            [
              -92.050051,
              35.06706
            ],
            [
              -92.074634,
              35.075085
            ],
            [
              -92.088939,
              35.064766
            ],
            [
              -92.119719,
              35.066493
            ],
            [
              -92.120885,
              35.027372
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "029",
      "COUNTYNS": "00066846",
      "AFFGEOID": "0500000US05029",
      "GEOID": "05029",
      "NAME": "Conway",
      "LSAD": "06",
      "ALAND": 1430330693,
      "AWATER": 37331621,
      "County_state": "Conway,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.040709,
              35.108951
            ],
            [
              -93.026237,
              35.088347
            ],
            [
              -93.040525,
              35.076923
            ],
            [
              -92.980716,
              35.075536
            ],
            [
              -92.962488,
              35.075776
            ],
            [
              -92.909267,
              35.074727
            ],
            [
              -92.716331,
              35.071742
            ],
            [
              -92.675382,
              35.078452
            ],
            [
              -92.649229,
              35.104632
            ],
            [
              -92.623812,
              35.109116
            ],
            [
              -92.625161,
              35.092944
            ],
            [
              -92.643955,
              35.08426
            ],
            [
              -92.644709,
              35.076373
            ],
            [
              -92.621844,
              35.077694
            ],
            [
              -92.599697,
              35.085364
            ],
            [
              -92.56339,
              35.115827
            ],
            [
              -92.554685,
              35.113277
            ],
            [
              -92.543139,
              35.125957
            ],
            [
              -92.521445,
              35.114697
            ],
            [
              -92.507553,
              35.136711
            ],
            [
              -92.500305,
              35.173827
            ],
            [
              -92.528687,
              35.189658
            ],
            [
              -92.521977,
              35.221248
            ],
            [
              -92.531457,
              35.224258
            ],
            [
              -92.504138,
              35.24025
            ],
            [
              -92.514393,
              35.257004
            ],
            [
              -92.484259,
              35.280706
            ],
            [
              -92.481469,
              35.368392
            ],
            [
              -92.479268,
              35.455357
            ],
            [
              -92.567229,
              35.456936
            ],
            [
              -92.850624,
              35.46295
            ],
            [
              -92.854319,
              35.260196
            ],
            [
              -92.855,
              35.172777
            ],
            [
              -92.896109,
              35.170678
            ],
            [
              -92.924286,
              35.168336
            ],
            [
              -92.955966,
              35.140256
            ],
            [
              -92.978977,
              35.13134
            ],
            [
              -92.979748,
              35.11808
            ],
            [
              -93.040709,
              35.108951
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "097",
      "COUNTYNS": "00069168",
      "AFFGEOID": "0500000US05097",
      "GEOID": "05097",
      "NAME": "Montgomery",
      "LSAD": "06",
      "ALAND": 2019824136,
      "AWATER": 52913372,
      "County_state": "Montgomery,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.932489,
              34.550888
            ],
            [
              -93.935371,
              34.350097
            ],
            [
              -93.831536,
              34.347555
            ],
            [
              -93.743086,
              34.34813
            ],
            [
              -93.671204,
              34.345261
            ],
            [
              -93.478646,
              34.340851
            ],
            [
              -93.408124,
              34.339527
            ],
            [
              -93.407227,
              34.396853
            ],
            [
              -93.403555,
              34.601992
            ],
            [
              -93.403503,
              34.610417
            ],
            [
              -93.402189,
              34.685193
            ],
            [
              -93.393889,
              34.685218
            ],
            [
              -93.394289,
              34.743393
            ],
            [
              -93.710297,
              34.745296
            ],
            [
              -93.745216,
              34.746463
            ],
            [
              -93.745699,
              34.731896
            ],
            [
              -93.7815,
              34.732797
            ],
            [
              -93.781596,
              34.718401
            ],
            [
              -93.817003,
              34.711301
            ],
            [
              -93.870197,
              34.712699
            ],
            [
              -93.877996,
              34.687265
            ],
            [
              -93.878163,
              34.66527
            ],
            [
              -93.930509,
              34.665608
            ],
            [
              -93.932489,
              34.550888
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "023",
      "COUNTYNS": "00066983",
      "AFFGEOID": "0500000US05023",
      "GEOID": "05023",
      "NAME": "Cleburne",
      "LSAD": "06",
      "ALAND": 1434168142,
      "AWATER": 98893718,
      "County_state": "Cleburne,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.248816,
              35.523591
            ],
            [
              -92.252759,
              35.363718
            ],
            [
              -92.182019,
              35.362605
            ],
            [
              -92.122943,
              35.362019
            ],
            [
              -92.111463,
              35.362725
            ],
            [
              -91.851958,
              35.358287
            ],
            [
              -91.850144,
              35.436442
            ],
            [
              -91.836816,
              35.441001
            ],
            [
              -91.828629,
              35.454751
            ],
            [
              -91.819339,
              35.449872
            ],
            [
              -91.795855,
              35.455574
            ],
            [
              -91.793958,
              35.534114
            ],
            [
              -91.789823,
              35.711734
            ],
            [
              -91.806991,
              35.704532
            ],
            [
              -91.837179,
              35.704932
            ],
            [
              -92.032049,
              35.707688
            ],
            [
              -92.24052,
              35.71219
            ],
            [
              -92.241653,
              35.646638
            ],
            [
              -92.24411,
              35.569827
            ],
            [
              -92.244721,
              35.537933
            ],
            [
              -92.248816,
              35.523591
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "129",
      "COUNTYNS": "00069180",
      "AFFGEOID": "0500000US05129",
      "GEOID": "05129",
      "NAME": "Searcy",
      "LSAD": "06",
      "ALAND": 1725176973,
      "AWATER": 6256687,
      "County_state": "Searcy,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.946594,
              35.813037
            ],
            [
              -92.951315,
              35.724663
            ],
            [
              -92.809763,
              35.722401
            ],
            [
              -92.631207,
              35.718365
            ],
            [
              -92.629837,
              35.790823
            ],
            [
              -92.598351,
              35.790122
            ],
            [
              -92.416719,
              35.78859
            ],
            [
              -92.414324,
              35.975865
            ],
            [
              -92.411502,
              36.062756
            ],
            [
              -92.476754,
              36.063728
            ],
            [
              -92.495998,
              36.063902
            ],
            [
              -92.581538,
              36.065388
            ],
            [
              -92.731224,
              36.067558
            ],
            [
              -92.836531,
              36.070065
            ],
            [
              -92.836859,
              36.113741
            ],
            [
              -92.890695,
              36.114132
            ],
            [
              -92.944666,
              36.115123
            ],
            [
              -92.945543,
              36.071894
            ],
            [
              -92.93851,
              36.071726
            ],
            [
              -92.940932,
              35.990069
            ],
            [
              -92.946594,
              35.813037
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "095",
      "COUNTYNS": "00069167",
      "AFFGEOID": "0500000US05095",
      "GEOID": "05095",
      "NAME": "Monroe",
      "LSAD": "06",
      "ALAND": 1572480973,
      "AWATER": 36694917,
      "County_state": "Monroe,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.446983,
              34.667531
            ],
            [
              -91.447795,
              34.609185
            ],
            [
              -91.412178,
              34.608736
            ],
            [
              -91.41262,
              34.593782
            ],
            [
              -91.377067,
              34.592851
            ],
            [
              -91.37782,
              34.56348
            ],
            [
              -91.291483,
              34.561761
            ],
            [
              -91.301065,
              34.534011
            ],
            [
              -91.2616,
              34.52717
            ],
            [
              -91.263606,
              34.501867
            ],
            [
              -91.232256,
              34.480714
            ],
            [
              -91.218172,
              34.486906
            ],
            [
              -91.215306,
              34.472844
            ],
            [
              -91.206572,
              34.456154
            ],
            [
              -91.218733,
              34.443822
            ],
            [
              -91.191883,
              34.439746
            ],
            [
              -91.174745,
              34.450443
            ],
            [
              -91.153868,
              34.421946
            ],
            [
              -91.165883,
              34.412064
            ],
            [
              -91.138401,
              34.402784
            ],
            [
              -91.130694,
              34.381128
            ],
            [
              -91.130057,
              34.380246
            ],
            [
              -91.127965,
              34.379227
            ],
            [
              -91.12673,
              34.378745
            ],
            [
              -91.125117,
              34.377829
            ],
            [
              -91.122757,
              34.375786
            ],
            [
              -91.105946,
              34.366026
            ],
            [
              -91.093317,
              34.343963
            ],
            [
              -91.056276,
              34.336605
            ],
            [
              -91.002693,
              34.336353
            ],
            [
              -91.000459,
              34.469398
            ],
            [
              -91.054774,
              34.47088
            ],
            [
              -91.051918,
              34.645721
            ],
            [
              -91.050116,
              34.704044
            ],
            [
              -91.049474,
              34.734184
            ],
            [
              -91.102542,
              34.734864
            ],
            [
              -91.099961,
              34.86712
            ],
            [
              -91.117776,
              34.867167
            ],
            [
              -91.116609,
              34.911797
            ],
            [
              -91.152457,
              34.911847
            ],
            [
              -91.149842,
              35.00247
            ],
            [
              -91.208368,
              35.003464
            ],
            [
              -91.237493,
              35.004291
            ],
            [
              -91.238143,
              34.98488
            ],
            [
              -91.293111,
              34.985091
            ],
            [
              -91.29479,
              34.912452
            ],
            [
              -91.36856,
              34.912735
            ],
            [
              -91.381922,
              34.905126
            ],
            [
              -91.343872,
              34.873551
            ],
            [
              -91.36065,
              34.862122
            ],
            [
              -91.373055,
              34.826195
            ],
            [
              -91.370598,
              34.824276
            ],
            [
              -91.374765,
              34.665964
            ],
            [
              -91.446983,
              34.667531
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "111",
      "COUNTYNS": "00069174",
      "AFFGEOID": "0500000US05111",
      "GEOID": "05111",
      "NAME": "Poinsett",
      "LSAD": "06",
      "ALAND": 1964147526,
      "AWATER": 13521720,
      "County_state": "Poinsett,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.036967,
              35.708418
            ],
            [
              -91.03923,
              35.444717
            ],
            [
              -90.931248,
              35.441829
            ],
            [
              -90.82382,
              35.44575
            ],
            [
              -90.716132,
              35.445771
            ],
            [
              -90.610753,
              35.448769
            ],
            [
              -90.610911,
              35.441663
            ],
            [
              -90.502429,
              35.441726
            ],
            [
              -90.340878,
              35.439168
            ],
            [
              -90.286899,
              35.438573
            ],
            [
              -90.286827,
              35.538766
            ],
            [
              -90.286942,
              35.547242
            ],
            [
              -90.286736,
              35.699914
            ],
            [
              -90.391779,
              35.701234
            ],
            [
              -90.50058,
              35.707268
            ],
            [
              -90.604724,
              35.70446
            ],
            [
              -90.71349,
              35.705266
            ],
            [
              -91.036967,
              35.708418
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "007",
      "COUNTYNS": "00069896",
      "AFFGEOID": "0500000US05007",
      "GEOID": "05007",
      "NAME": "Benton",
      "LSAD": "06",
      "ALAND": 2195019818,
      "AWATER": 94307300,
      "County_state": "Benton,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.617919,
              36.499414
            ],
            [
              -94.615311,
              36.484992
            ],
            [
              -94.61383,
              36.476248
            ],
            [
              -94.611609,
              36.461528
            ],
            [
              -94.605408,
              36.421949
            ],
            [
              -94.602623,
              36.405283
            ],
            [
              -94.601984,
              36.40212
            ],
            [
              -94.599723,
              36.387587
            ],
            [
              -94.593397,
              36.345742
            ],
            [
              -94.5913180588467,
              36.3325199111557
            ],
            [
              -94.5862,
              36.299969
            ],
            [
              -94.58473089720769,
              36.2911566795471
            ],
            [
              -94.577883,
              36.25008
            ],
            [
              -94.577899,
              36.249548
            ],
            [
              -94.576003,
              36.24007
            ],
            [
              -94.575071,
              36.233682
            ],
            [
              -94.57488,
              36.232741
            ],
            [
              -94.574395,
              36.229996
            ],
            [
              -94.571806,
              36.213748
            ],
            [
              -94.57126713660419,
              36.21097377922649
            ],
            [
              -94.571253,
              36.210901
            ],
            [
              -94.566588,
              36.183774
            ],
            [
              -94.565655,
              36.178439
            ],
            [
              -94.5628276914512,
              36.1618934952038
            ],
            [
              -94.562803,
              36.161749
            ],
            [
              -94.561165,
              36.15211
            ],
            [
              -94.55218431116519,
              36.1022352237111
            ],
            [
              -94.420703,
              36.099823
            ],
            [
              -94.394897,
              36.099471
            ],
            [
              -94.389936,
              36.142591
            ],
            [
              -94.33643,
              36.142248
            ],
            [
              -94.33516,
              36.170734
            ],
            [
              -94.334872,
              36.181044
            ],
            [
              -94.333565,
              36.214727
            ],
            [
              -94.310845,
              36.214224
            ],
            [
              -94.181923,
              36.211551
            ],
            [
              -94.173776,
              36.211389
            ],
            [
              -94.137865,
              36.210348
            ],
            [
              -94.113364,
              36.210061
            ],
            [
              -94.093256,
              36.209763
            ],
            [
              -94.017561,
              36.208277
            ],
            [
              -94.024268,
              36.223754
            ],
            [
              -94.007947,
              36.23022
            ],
            [
              -93.998167,
              36.210903
            ],
            [
              -93.983472,
              36.21221
            ],
            [
              -93.973298,
              36.203852
            ],
            [
              -93.97224,
              36.228529
            ],
            [
              -93.957701,
              36.22242
            ],
            [
              -93.943988,
              36.236177
            ],
            [
              -93.887775,
              36.235046
            ],
            [
              -93.869632,
              36.234826
            ],
            [
              -93.869066,
              36.30745
            ],
            [
              -93.815605,
              36.306563
            ],
            [
              -93.832124,
              36.31281
            ],
            [
              -93.833084,
              36.322371
            ],
            [
              -93.861698,
              36.339266
            ],
            [
              -93.866836,
              36.376659
            ],
            [
              -93.880492,
              36.368913
            ],
            [
              -93.890508,
              36.377894
            ],
            [
              -93.882117,
              36.394239
            ],
            [
              -93.866785,
              36.394452
            ],
            [
              -93.86675809584749,
              36.498788514184895
            ],
            [
              -93.906128,
              36.498718
            ],
            [
              -93.92184,
              36.498718
            ],
            [
              -93.95919,
              36.498717
            ],
            [
              -93.96392,
              36.498717
            ],
            [
              -94.07342263223619,
              36.498729578835395
            ],
            [
              -94.077089,
              36.49873
            ],
            [
              -94.098588,
              36.498676
            ],
            [
              -94.100252,
              36.49867
            ],
            [
              -94.110673,
              36.498587
            ],
            [
              -94.111473,
              36.498597
            ],
            [
              -94.1814127473658,
              36.4988779016402
            ],
            [
              -94.269279803981,
              36.4992308054074
            ],
            [
              -94.361203,
              36.4996
            ],
            [
              -94.519478,
              36.499214
            ],
            [
              -94.55929,
              36.499496
            ],
            [
              -94.617919,
              36.499414
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "093",
      "COUNTYNS": "00069899",
      "AFFGEOID": "0500000US05093",
      "GEOID": "05093",
      "NAME": "Mississippi",
      "LSAD": "06",
      "ALAND": 2332480068,
      "AWATER": 49826375,
      "County_state": "Mississippi,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.2888,
              35.996419
            ],
            [
              -90.2889470498035,
              35.9964180953312
            ],
            [
              -90.286736,
              35.699914
            ],
            [
              -90.286942,
              35.547242
            ],
            [
              -90.286827,
              35.538766
            ],
            [
              -90.286899,
              35.438573
            ],
            [
              -90.1401796054271,
              35.436483531380794
            ],
            [
              -90.129448,
              35.441931
            ],
            [
              -90.123142,
              35.459853
            ],
            [
              -90.120619,
              35.465741
            ],
            [
              -90.11839,
              35.468791
            ],
            [
              -90.114412,
              35.472467
            ],
            [
              -90.107723,
              35.476935
            ],
            [
              -90.101759,
              35.476889
            ],
            [
              -90.098719,
              35.478595
            ],
            [
              -90.085009,
              35.478835
            ],
            [
              -90.080128,
              35.476844
            ],
            [
              -90.072154,
              35.470752
            ],
            [
              -90.067798,
              35.466224
            ],
            [
              -90.067138,
              35.464833
            ],
            [
              -90.067206,
              35.460957
            ],
            [
              -90.071327,
              35.450338
            ],
            [
              -90.074063,
              35.439611
            ],
            [
              -90.074082,
              35.433983
            ],
            [
              -90.070549,
              35.423291
            ],
            [
              -90.062018,
              35.41518
            ],
            [
              -90.069283,
              35.408306
            ],
            [
              -90.056644,
              35.403786
            ],
            [
              -90.05199406979949,
              35.4080350901095
            ],
            [
              -90.046598,
              35.412966
            ],
            [
              -90.045306,
              35.415435
            ],
            [
              -90.044216,
              35.419231
            ],
            [
              -90.04264,
              35.421237
            ],
            [
              -90.04057,
              35.422925
            ],
            [
              -90.031584,
              35.427662
            ],
            [
              -90.031267,
              35.431576
            ],
            [
              -90.02931,
              35.435245
            ],
            [
              -90.02737,
              35.43789
            ],
            [
              -90.026584,
              35.440103
            ],
            [
              -90.026899,
              35.444869
            ],
            [
              -90.026604,
              35.447788
            ],
            [
              -90.024247,
              35.45426
            ],
            [
              -90.022064,
              35.457375
            ],
            [
              -90.018842,
              35.464816
            ],
            [
              -90.018998,
              35.467803
            ],
            [
              -90.020386,
              35.470257
            ],
            [
              -90.034976,
              35.480705
            ],
            [
              -90.043517,
              35.492298
            ],
            [
              -90.045805,
              35.496533
            ],
            [
              -90.048519,
              35.504357
            ],
            [
              -90.050277,
              35.515275
            ],
            [
              -90.04909,
              35.522257
            ],
            [
              -90.046227,
              35.529592
            ],
            [
              -90.044748,
              35.531657
            ],
            [
              -90.041962,
              35.537468
            ],
            [
              -90.039744,
              35.548041
            ],
            [
              -90.037615,
              35.550329
            ],
            [
              -90.032938,
              35.55344
            ],
            [
              -90.02862,
              35.555249
            ],
            [
              -90.023903,
              35.556336
            ],
            [
              -90.017312,
              35.555996
            ],
            [
              -90.011262,
              35.559105
            ],
            [
              -90.008293,
              35.560065
            ],
            [
              -89.998996,
              35.56116
            ],
            [
              -89.992975,
              35.560774
            ],
            [
              -89.989363,
              35.560043
            ],
            [
              -89.97631,
              35.553872
            ],
            [
              -89.958498,
              35.541703
            ],
            [
              -89.956706,
              35.539369
            ],
            [
              -89.955641,
              35.534518
            ],
            [
              -89.95578,
              35.533214
            ],
            [
              -89.957739,
              35.530125
            ],
            [
              -89.957715,
              35.527192
            ],
            [
              -89.956347,
              35.525594
            ],
            [
              -89.951248,
              35.521866
            ],
            [
              -89.94801,
              35.52009
            ],
            [
              -89.945026,
              35.519388
            ],
            [
              -89.933614,
              35.518538
            ],
            [
              -89.923161,
              35.514428
            ],
            [
              -89.919331,
              35.51387
            ],
            [
              -89.9154,
              35.515119
            ],
            [
              -89.911931,
              35.51741
            ],
            [
              -89.909022,
              35.520548
            ],
            [
              -89.90766,
              35.522944
            ],
            [
              -89.903882,
              35.534175
            ],
            [
              -89.904392,
              35.535701
            ],
            [
              -89.905582,
              35.536774
            ],
            [
              -89.908826,
              35.538031
            ],
            [
              -89.9092060309267,
              35.538164136790705
            ],
            [
              -89.910787,
              35.538718
            ],
            [
              -89.910885,
              35.541072
            ],
            [
              -89.909923,
              35.544037
            ],
            [
              -89.910789,
              35.547515
            ],
            [
              -89.914177,
              35.549713
            ],
            [
              -89.917424,
              35.550308
            ],
            [
              -89.924145,
              35.550561
            ],
            [
              -89.929101,
              35.551545
            ],
            [
              -89.941393,
              35.556555
            ],
            [
              -89.944754,
              35.560308
            ],
            [
              -89.946911,
              35.56358
            ],
            [
              -89.954196,
              35.57605
            ],
            [
              -89.95669,
              35.581426
            ],
            [
              -89.957924,
              35.585499
            ],
            [
              -89.957896,
              35.587261
            ],
            [
              -89.956749,
              35.590511
            ],
            [
              -89.954145,
              35.594264
            ],
            [
              -89.951147,
              35.597491
            ],
            [
              -89.945405,
              35.601611
            ],
            [
              -89.9325,
              35.607865
            ],
            [
              -89.919619,
              35.612236
            ],
            [
              -89.912172,
              35.617055
            ],
            [
              -89.910687,
              35.617536
            ],
            [
              -89.906029,
              35.616145
            ],
            [
              -89.899789,
              35.615061
            ],
            [
              -89.896999,
              35.614882
            ],
            [
              -89.894346,
              35.615535
            ],
            [
              -89.876548,
              35.626653
            ],
            [
              -89.863875,
              35.630788
            ],
            [
              -89.856619,
              35.634444
            ],
            [
              -89.85389,
              35.638261
            ],
            [
              -89.851825,
              35.644262
            ],
            [
              -89.850863,
              35.650208
            ],
            [
              -89.851176,
              35.657432
            ],
            [
              -89.85351,
              35.663034
            ],
            [
              -89.858935,
              35.66906
            ],
            [
              -89.861277,
              35.670064
            ],
            [
              -89.864782,
              35.670385
            ],
            [
              -89.872078,
              35.668487
            ],
            [
              -89.877158,
              35.666136
            ],
            [
              -89.878534,
              35.66482
            ],
            [
              -89.882893,
              35.657463
            ],
            [
              -89.884932,
              35.655107
            ],
            [
              -89.886979,
              35.653637
            ],
            [
              -89.89051,
              35.652408
            ],
            [
              -89.898916,
              35.650904
            ],
            [
              -89.906147,
              35.651145
            ],
            [
              -89.915427,
              35.652782
            ],
            [
              -89.922749,
              35.655293
            ],
            [
              -89.931036,
              35.660044
            ],
            [
              -89.937383,
              35.665711
            ],
            [
              -89.9427,
              35.675121
            ],
            [
              -89.950161,
              35.682433
            ],
            [
              -89.953303,
              35.686418
            ],
            [
              -89.955753,
              35.690621
            ],
            [
              -89.956589,
              35.695486
            ],
            [
              -89.956933,
              35.711677
            ],
            [
              -89.958882,
              35.723834
            ],
            [
              -89.958687,
              35.727706
            ],
            [
              -89.956254,
              35.733386
            ],
            [
              -89.953983,
              35.73616
            ],
            [
              -89.950278,
              35.738493
            ],
            [
              -89.915491,
              35.754917
            ],
            [
              -89.913132,
              35.757302
            ],
            [
              -89.909996,
              35.759396
            ],
            [
              -89.905538,
              35.759063
            ],
            [
              -89.888163,
              35.750077
            ],
            [
              -89.883535,
              35.744984
            ],
            [
              -89.877256,
              35.741369
            ],
            [
              -89.872845,
              35.741299
            ],
            [
              -89.863874,
              35.747592
            ],
            [
              -89.857707,
              35.750077
            ],
            [
              -89.846343,
              35.755732
            ],
            [
              -89.832895,
              35.754905
            ],
            [
              -89.824923,
              35.755715
            ],
            [
              -89.821216,
              35.756716
            ],
            [
              -89.814456,
              35.759941
            ],
            [
              -89.812891,
              35.761154
            ],
            [
              -89.80928,
              35.764379
            ],
            [
              -89.799249,
              35.775439
            ],
            [
              -89.797231,
              35.780117
            ],
            [
              -89.797053,
              35.782648
            ],
            [
              -89.799331,
              35.788503
            ],
            [
              -89.796324,
              35.792807
            ],
            [
              -89.781793,
              35.805084
            ],
            [
              -89.765457,
              35.809513
            ],
            [
              -89.765442,
              35.811214
            ],
            [
              -89.757874,
              35.810415
            ],
            [
              -89.743025,
              35.805817
            ],
            [
              -89.734044,
              35.806174
            ],
            [
              -89.723426,
              35.809382
            ],
            [
              -89.719915,
              35.811557
            ],
            [
              -89.706085,
              35.81826
            ],
            [
              -89.703875,
              35.820281
            ],
            [
              -89.70175,
              35.824238
            ],
            [
              -89.701045,
              35.828227
            ],
            [
              -89.701407,
              35.830985
            ],
            [
              -89.702883,
              35.834153
            ],
            [
              -89.704351,
              35.835726
            ],
            [
              -89.709261,
              35.838911
            ],
            [
              -89.729517,
              35.847632
            ],
            [
              -89.749424,
              35.852955
            ],
            [
              -89.764343,
              35.858313
            ],
            [
              -89.769413,
              35.861558
            ],
            [
              -89.772467,
              35.865098
            ],
            [
              -89.773294,
              35.867426
            ],
            [
              -89.773564,
              35.871697
            ],
            [
              -89.772855,
              35.876244
            ],
            [
              -89.771726,
              35.879724
            ],
            [
              -89.768743,
              35.886663
            ],
            [
              -89.765689,
              35.891299
            ],
            [
              -89.756312,
              35.89806
            ],
            [
              -89.741241,
              35.906749
            ],
            [
              -89.714934,
              35.906247
            ],
            [
              -89.688141,
              35.896946
            ],
            [
              -89.68182,
              35.88999
            ],
            [
              -89.677012,
              35.88572
            ],
            [
              -89.669553,
              35.883281
            ],
            [
              -89.665672,
              35.883301
            ],
            [
              -89.657771,
              35.88575
            ],
            [
              -89.655452,
              35.886912
            ],
            [
              -89.65068,
              35.89088
            ],
            [
              -89.64727,
              35.89492
            ],
            [
              -89.644838,
              35.904351
            ],
            [
              -89.646711,
              35.908008
            ],
            [
              -89.65034,
              35.917795
            ],
            [
              -89.652279,
              35.921462
            ],
            [
              -89.656147,
              35.92581
            ],
            [
              -89.671117,
              35.935795
            ],
            [
              -89.676621,
              35.940539
            ],
            [
              -89.686924,
              35.947716
            ],
            [
              -89.699871,
              35.954063
            ],
            [
              -89.710227,
              35.959826
            ],
            [
              -89.714565,
              35.963034
            ],
            [
              -89.718796,
              35.968283
            ],
            [
              -89.719679,
              35.970939
            ],
            [
              -89.71997,
              35.97462
            ],
            [
              -89.718801,
              35.985015
            ],
            [
              -89.719168,
              35.985976
            ],
            [
              -89.728442,
              35.993568
            ],
            [
              -89.731218,
              35.996879
            ],
            [
              -89.733095,
              36.000608
            ],
            [
              -89.737564,
              36.000522
            ],
            [
              -89.737648,
              36.000567
            ],
            [
              -89.769973,
              36.000536
            ],
            [
              -89.770255,
              36.000524
            ],
            [
              -89.86901,
              35.99964
            ],
            [
              -89.87459,
              35.999575
            ],
            [
              -89.875085,
              35.999578
            ],
            [
              -89.875586,
              35.999562
            ],
            [
              -89.896508,
              35.999432
            ],
            [
              -89.901183,
              35.999365
            ],
            [
              -89.90141276984102,
              35.999363637821794
            ],
            [
              -89.9593753420007,
              35.9990200098294
            ],
            [
              -89.959377,
              35.99902
            ],
            [
              -89.959893,
              35.99902
            ],
            [
              -89.961075,
              35.999135
            ],
            [
              -89.965327,
              35.998813
            ],
            [
              -89.972563,
              35.998994
            ],
            [
              -90.103842,
              35.998143
            ],
            [
              -90.12635,
              35.997596
            ],
            [
              -90.127331,
              35.997635
            ],
            [
              -90.158812,
              35.997375
            ],
            [
              -90.2888,
              35.996419
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "049",
      "COUNTYNS": "00066854",
      "AFFGEOID": "0500000US05049",
      "GEOID": "05049",
      "NAME": "Fulton",
      "LSAD": "06",
      "ALAND": 1601111649,
      "AWATER": 5526927,
      "County_state": "Fulton,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.158422,
              36.287496
            ],
            [
              -92.159197,
              36.263479
            ],
            [
              -92.15699,
              36.261248
            ],
            [
              -91.836476,
              36.255398
            ],
            [
              -91.691435,
              36.253301
            ],
            [
              -91.566078,
              36.249945
            ],
            [
              -91.565866,
              36.257435
            ],
            [
              -91.565866,
              36.3374
            ],
            [
              -91.512441,
              36.336831
            ],
            [
              -91.454437,
              36.335465
            ],
            [
              -91.452848,
              36.418585
            ],
            [
              -91.4500043450567,
              36.4975617737561
            ],
            [
              -91.50014,
              36.498812
            ],
            [
              -91.529774,
              36.499022
            ],
            [
              -91.53687,
              36.499156
            ],
            [
              -91.539359,
              36.499116
            ],
            [
              -91.549163,
              36.499161
            ],
            [
              -91.596213,
              36.499162
            ],
            [
              -91.601317,
              36.499343
            ],
            [
              -91.6176945446342,
              36.499264162473494
            ],
            [
              -91.631439,
              36.499198
            ],
            [
              -91.64259,
              36.499335
            ],
            [
              -91.672343,
              36.499463
            ],
            [
              -91.686026,
              36.499374
            ],
            [
              -91.687615,
              36.499397
            ],
            [
              -91.726663,
              36.499209
            ],
            [
              -91.766111,
              36.499114
            ],
            [
              -91.784713,
              36.499074
            ],
            [
              -91.7995,
              36.498952
            ],
            [
              -91.80204,
              36.498963
            ],
            [
              -91.805981,
              36.498987
            ],
            [
              -91.864385,
              36.498789
            ],
            [
              -91.865995,
              36.498783
            ],
            [
              -91.985802,
              36.498431
            ],
            [
              -91.988751,
              36.498498
            ],
            [
              -92.019375,
              36.498524
            ],
            [
              -92.028847,
              36.498642
            ],
            [
              -92.055789,
              36.49867
            ],
            [
              -92.057178,
              36.49867
            ],
            [
              -92.074934,
              36.498761
            ],
            [
              -92.098356,
              36.498803
            ],
            [
              -92.120306,
              36.498864
            ],
            [
              -92.1204149758124,
              36.498863012436004
            ],
            [
              -92.137741,
              36.498706
            ],
            [
              -92.1502951365485,
              36.4986337152141
            ],
            [
              -92.151121,
              36.478272
            ],
            [
              -92.154301,
              36.353883
            ],
            [
              -92.15524,
              36.348942
            ],
            [
              -92.158422,
              36.287496
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "127",
      "COUNTYNS": "00069179",
      "AFFGEOID": "0500000US05127",
      "GEOID": "05127",
      "NAME": "Scott",
      "LSAD": "06",
      "ALAND": 2311063525,
      "AWATER": 14887193,
      "County_state": "Scott,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.4511886196818,
              34.8275901532647
            ],
            [
              -94.4545761452896,
              34.7289624171492
            ],
            [
              -94.316845,
              34.726503
            ],
            [
              -94.317639,
              34.697522
            ],
            [
              -94.018168,
              34.692469
            ],
            [
              -94.018201,
              34.683324
            ],
            [
              -93.930101,
              34.680102
            ],
            [
              -93.930509,
              34.665608
            ],
            [
              -93.878163,
              34.66527
            ],
            [
              -93.877996,
              34.687265
            ],
            [
              -93.870197,
              34.712699
            ],
            [
              -93.817003,
              34.711301
            ],
            [
              -93.781596,
              34.718401
            ],
            [
              -93.7815,
              34.732797
            ],
            [
              -93.745699,
              34.731896
            ],
            [
              -93.745216,
              34.746463
            ],
            [
              -93.710297,
              34.745296
            ],
            [
              -93.707276,
              34.905082
            ],
            [
              -93.706703,
              34.932035
            ],
            [
              -93.704885,
              35.019715
            ],
            [
              -94.074347,
              35.026585
            ],
            [
              -94.073742,
              35.054
            ],
            [
              -94.141765,
              35.055685
            ],
            [
              -94.140571,
              35.099678
            ],
            [
              -94.158645,
              35.099847
            ],
            [
              -94.15892,
              35.092475
            ],
            [
              -94.195294,
              35.08587
            ],
            [
              -94.232418,
              35.070502
            ],
            [
              -94.250764,
              35.065589
            ],
            [
              -94.250852,
              35.058171
            ],
            [
              -94.232698,
              35.057683
            ],
            [
              -94.228258,
              35.056754
            ],
            [
              -94.230123,
              34.985847
            ],
            [
              -94.230524,
              34.968527
            ],
            [
              -94.256161,
              34.959748
            ],
            [
              -94.294857,
              34.952867
            ],
            [
              -94.324036,
              34.955977
            ],
            [
              -94.348489,
              34.947838
            ],
            [
              -94.367617,
              34.962266
            ],
            [
              -94.376195,
              34.948553
            ],
            [
              -94.392173,
              34.943542
            ],
            [
              -94.409405,
              34.949895
            ],
            [
              -94.429544,
              34.931387
            ],
            [
              -94.4478888293082,
              34.933941114475395
            ],
            [
              -94.449253,
              34.895869
            ],
            [
              -94.449086,
              34.894152
            ],
            [
              -94.449058,
              34.890556
            ],
            [
              -94.44963,
              34.875253
            ],
            [
              -94.450065,
              34.861335
            ],
            [
              -94.45014,
              34.858694
            ],
            [
              -94.450233,
              34.855413
            ],
            [
              -94.4511886196818,
              34.8275901532647
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "061",
      "COUNTYNS": "00066872",
      "AFFGEOID": "0500000US05061",
      "GEOID": "05061",
      "NAME": "Howard",
      "LSAD": "06",
      "ALAND": 1524380220,
      "AWATER": 17475726,
      "County_state": "Howard,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.254668,
              34.355481
            ],
            [
              -94.256128,
              34.269306
            ],
            [
              -94.243885,
              34.269462
            ],
            [
              -94.243739,
              34.192501
            ],
            [
              -94.242574,
              34.192542
            ],
            [
              -94.105088,
              34.191261
            ],
            [
              -94.089758,
              34.186835
            ],
            [
              -94.097504,
              34.17698
            ],
            [
              -94.094902,
              34.147395
            ],
            [
              -94.103432,
              34.135653
            ],
            [
              -94.08038,
              34.125718
            ],
            [
              -94.093461,
              34.112589
            ],
            [
              -94.081517,
              34.101708
            ],
            [
              -94.092063,
              34.076549
            ],
            [
              -94.075505,
              34.071845
            ],
            [
              -94.08752,
              34.034279
            ],
            [
              -94.072571,
              34.001562
            ],
            [
              -94.066655,
              33.999235
            ],
            [
              -94.068178,
              33.979637
            ],
            [
              -94.057534,
              33.968219
            ],
            [
              -94.070139,
              33.938786
            ],
            [
              -94.066247,
              33.917588
            ],
            [
              -94.061796,
              33.913616
            ],
            [
              -94.05331,
              33.904213
            ],
            [
              -94.052943,
              33.886082
            ],
            [
              -94.019496,
              33.87247
            ],
            [
              -94.018916,
              33.850318
            ],
            [
              -94.00881,
              33.848401
            ],
            [
              -94.003081,
              33.833522
            ],
            [
              -93.971496,
              33.806641
            ],
            [
              -93.964656,
              33.796154
            ],
            [
              -93.969549,
              33.793537
            ],
            [
              -93.960753,
              33.785712
            ],
            [
              -93.969237,
              33.767777
            ],
            [
              -93.958828,
              33.750994
            ],
            [
              -93.957315,
              33.75094
            ],
            [
              -93.831452,
              33.747709
            ],
            [
              -93.824724,
              33.950835
            ],
            [
              -93.823076,
              34.008893
            ],
            [
              -93.820722,
              34.183802
            ],
            [
              -93.92587,
              34.18669
            ],
            [
              -93.92434,
              34.262985
            ],
            [
              -93.937009,
              34.263024
            ],
            [
              -93.935371,
              34.350097
            ],
            [
              -94.254668,
              34.355481
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "147",
      "COUNTYNS": "00069907",
      "AFFGEOID": "0500000US05147",
      "GEOID": "05147",
      "NAME": "Woodruff",
      "LSAD": "06",
      "ALAND": 1519723278,
      "AWATER": 18703959,
      "County_state": "Woodruff,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.475949,
              35.06648
            ],
            [
              -91.478299,
              35.058088
            ],
            [
              -91.461393,
              35.051875
            ],
            [
              -91.438879,
              35.060931
            ],
            [
              -91.441912,
              35.052215
            ],
            [
              -91.363763,
              35.051887
            ],
            [
              -91.366638,
              35.007268
            ],
            [
              -91.36856,
              34.912735
            ],
            [
              -91.29479,
              34.912452
            ],
            [
              -91.293111,
              34.985091
            ],
            [
              -91.238143,
              34.98488
            ],
            [
              -91.237493,
              35.004291
            ],
            [
              -91.208368,
              35.003464
            ],
            [
              -91.149842,
              35.00247
            ],
            [
              -91.04543,
              35.00104
            ],
            [
              -91.04267,
              35.148189
            ],
            [
              -91.039783,
              35.354256
            ],
            [
              -91.251504,
              35.357557
            ],
            [
              -91.252927,
              35.445845
            ],
            [
              -91.350179,
              35.444967
            ],
            [
              -91.347257,
              35.439106
            ],
            [
              -91.357194,
              35.430123
            ],
            [
              -91.345279,
              35.423792
            ],
            [
              -91.356598,
              35.408064
            ],
            [
              -91.363769,
              35.4302
            ],
            [
              -91.371953,
              35.421597
            ],
            [
              -91.370699,
              35.406139
            ],
            [
              -91.400644,
              35.391054
            ],
            [
              -91.383474,
              35.377289
            ],
            [
              -91.392192,
              35.375083
            ],
            [
              -91.404619,
              35.386243
            ],
            [
              -91.425681,
              35.375083
            ],
            [
              -91.409696,
              35.365966
            ],
            [
              -91.412874,
              35.360276
            ],
            [
              -91.430884,
              35.371566
            ],
            [
              -91.438202,
              35.369378
            ],
            [
              -91.425606,
              35.353008
            ],
            [
              -91.444814,
              35.346282
            ],
            [
              -91.430044,
              35.337691
            ],
            [
              -91.424955,
              35.329889
            ],
            [
              -91.437434,
              35.296118
            ],
            [
              -91.412626,
              35.303054
            ],
            [
              -91.411549,
              35.277515
            ],
            [
              -91.411866,
              35.264885
            ],
            [
              -91.401135,
              35.264665
            ],
            [
              -91.387948,
              35.251275
            ],
            [
              -91.385304,
              35.251548
            ],
            [
              -91.382796,
              35.25257
            ],
            [
              -91.37975,
              35.255251
            ],
            [
              -91.379175,
              35.257175
            ],
            [
              -91.379227,
              35.259775
            ],
            [
              -91.379771,
              35.263318
            ],
            [
              -91.379647,
              35.265247
            ],
            [
              -91.377908,
              35.26738
            ],
            [
              -91.376138,
              35.267775
            ],
            [
              -91.372789,
              35.266531
            ],
            [
              -91.371564,
              35.264767
            ],
            [
              -91.371035,
              35.262981
            ],
            [
              -91.371112,
              35.259892
            ],
            [
              -91.372599,
              35.2556
            ],
            [
              -91.374441,
              35.253432
            ],
            [
              -91.378255,
              35.250973
            ],
            [
              -91.382527,
              35.248901
            ],
            [
              -91.3851,
              35.246226
            ],
            [
              -91.386067,
              35.244395
            ],
            [
              -91.387656,
              35.23892
            ],
            [
              -91.38872,
              35.237007
            ],
            [
              -91.389088,
              35.233462
            ],
            [
              -91.388427,
              35.231482
            ],
            [
              -91.385135,
              35.228128
            ],
            [
              -91.380992,
              35.226458
            ],
            [
              -91.379099,
              35.2254
            ],
            [
              -91.37814,
              35.22385
            ],
            [
              -91.378354,
              35.222162
            ],
            [
              -91.381042,
              35.220067
            ],
            [
              -91.382588,
              35.21999
            ],
            [
              -91.388301,
              35.222688
            ],
            [
              -91.390819,
              35.223604
            ],
            [
              -91.393348,
              35.22325
            ],
            [
              -91.394593,
              35.222323
            ],
            [
              -91.394711,
              35.220757
            ],
            [
              -91.393296,
              35.218743
            ],
            [
              -91.389096,
              35.217284
            ],
            [
              -91.383027,
              35.217437
            ],
            [
              -91.381406,
              35.216998
            ],
            [
              -91.379719,
              35.215149
            ],
            [
              -91.381723,
              35.211615
            ],
            [
              -91.390145,
              35.203697
            ],
            [
              -91.390449,
              35.201338
            ],
            [
              -91.388499,
              35.198772
            ],
            [
              -91.385462,
              35.197828
            ],
            [
              -91.37964,
              35.1981
            ],
            [
              -91.377519,
              35.196761
            ],
            [
              -91.377257,
              35.196102
            ],
            [
              -91.378004,
              35.191537
            ],
            [
              -91.379327,
              35.189235
            ],
            [
              -91.382557,
              35.187555
            ],
            [
              -91.386011,
              35.188206
            ],
            [
              -91.391499,
              35.191115
            ],
            [
              -91.394696,
              35.194002
            ],
            [
              -91.395956,
              35.197736
            ],
            [
              -91.396325,
              35.210223
            ],
            [
              -91.407782,
              35.212655
            ],
            [
              -91.409747,
              35.20846
            ],
            [
              -91.411282,
              35.197288
            ],
            [
              -91.413105,
              35.194312
            ],
            [
              -91.415944,
              35.192739
            ],
            [
              -91.420324,
              35.192324
            ],
            [
              -91.427813,
              35.193684
            ],
            [
              -91.426612,
              35.185598
            ],
            [
              -91.421843,
              35.179248
            ],
            [
              -91.434237,
              35.16717
            ],
            [
              -91.45993,
              35.166564
            ],
            [
              -91.459614,
              35.155133
            ],
            [
              -91.437539,
              35.154442
            ],
            [
              -91.428866,
              35.136473
            ],
            [
              -91.452037,
              35.137592
            ],
            [
              -91.438982,
              35.11783
            ],
            [
              -91.444998,
              35.107925
            ],
            [
              -91.424784,
              35.103787
            ],
            [
              -91.450422,
              35.102305
            ],
            [
              -91.465114,
              35.089454
            ],
            [
              -91.446014,
              35.067981
            ],
            [
              -91.475949,
              35.06648
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "083",
      "COUNTYNS": "00082850",
      "AFFGEOID": "0500000US05083",
      "GEOID": "05083",
      "NAME": "Logan",
      "LSAD": "06",
      "ALAND": 1834026181,
      "AWATER": 60685645,
      "County_state": "Logan,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.140571,
              35.099678
            ],
            [
              -94.141765,
              35.055685
            ],
            [
              -94.073742,
              35.054
            ],
            [
              -94.074347,
              35.026585
            ],
            [
              -93.704885,
              35.019715
            ],
            [
              -93.709061,
              35.046787
            ],
            [
              -93.706692,
              35.134943
            ],
            [
              -93.663263,
              35.133835
            ],
            [
              -93.662979,
              35.148225
            ],
            [
              -93.529838,
              35.145441
            ],
            [
              -93.529261,
              35.160003
            ],
            [
              -93.511431,
              35.159718
            ],
            [
              -93.51117,
              35.174224
            ],
            [
              -93.493709,
              35.173755
            ],
            [
              -93.483954,
              35.180464
            ],
            [
              -93.483832,
              35.201786
            ],
            [
              -93.47451,
              35.209729
            ],
            [
              -93.45698,
              35.208482
            ],
            [
              -93.457022,
              35.215782
            ],
            [
              -93.315534,
              35.212382
            ],
            [
              -93.279959,
              35.212552
            ],
            [
              -93.278293,
              35.317402
            ],
            [
              -93.285744,
              35.320112
            ],
            [
              -93.299014,
              35.327425
            ],
            [
              -93.332909,
              35.341423
            ],
            [
              -93.36357,
              35.330704
            ],
            [
              -93.420576,
              35.3281
            ],
            [
              -93.422171,
              35.337412
            ],
            [
              -93.398965,
              35.34443
            ],
            [
              -93.396514,
              35.351264
            ],
            [
              -93.414405,
              35.365254
            ],
            [
              -93.424624,
              35.389206
            ],
            [
              -93.424669,
              35.406911
            ],
            [
              -93.435463,
              35.427871
            ],
            [
              -93.462977,
              35.424175
            ],
            [
              -93.480456,
              35.40517
            ],
            [
              -93.494626,
              35.396949
            ],
            [
              -93.508997,
              35.397687
            ],
            [
              -93.527068,
              35.408418
            ],
            [
              -93.561671,
              35.399646
            ],
            [
              -93.59459,
              35.383688
            ],
            [
              -93.640654,
              35.386215
            ],
            [
              -93.660628,
              35.403884
            ],
            [
              -93.669921,
              35.405551
            ],
            [
              -93.684647,
              35.381042
            ],
            [
              -93.710076,
              35.375242
            ],
            [
              -93.748802,
              35.364494
            ],
            [
              -93.757763,
              35.377118
            ],
            [
              -93.79456,
              35.402859
            ],
            [
              -93.806849,
              35.430011
            ],
            [
              -93.808251,
              35.385394
            ],
            [
              -93.810087,
              35.313095
            ],
            [
              -93.917127,
              35.319046
            ],
            [
              -93.920167,
              35.229427
            ],
            [
              -93.97358,
              35.230148
            ],
            [
              -94.028296,
              35.231151
            ],
            [
              -94.028547,
              35.213826
            ],
            [
              -94.028653,
              35.212131
            ],
            [
              -94.031236,
              35.140857
            ],
            [
              -94.139307,
              35.143361
            ],
            [
              -94.140571,
              35.099678
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "099",
      "COUNTYNS": "00069169",
      "AFFGEOID": "0500000US05099",
      "GEOID": "05099",
      "NAME": "Nevada",
      "LSAD": "06",
      "ALAND": 1600192985,
      "AWATER": 7254365,
      "County_state": "Nevada,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.483097,
              33.476075
            ],
            [
              -93.483974,
              33.447223
            ],
            [
              -93.365585,
              33.444459
            ],
            [
              -93.326751,
              33.443787
            ],
            [
              -93.22129,
              33.440444
            ],
            [
              -93.221031,
              33.455045
            ],
            [
              -93.116361,
              33.452909
            ],
            [
              -93.10429,
              33.777011
            ],
            [
              -93.112705,
              33.780084
            ],
            [
              -93.112501,
              33.7828
            ],
            [
              -93.12871,
              33.792683
            ],
            [
              -93.139844,
              33.815366
            ],
            [
              -93.155941,
              33.813854
            ],
            [
              -93.179434,
              33.800169
            ],
            [
              -93.195301,
              33.819238
            ],
            [
              -93.218972,
              33.828806
            ],
            [
              -93.257249,
              33.828626
            ],
            [
              -93.274318,
              33.840054
            ],
            [
              -93.274146,
              33.840165
            ],
            [
              -93.274452,
              33.840924
            ],
            [
              -93.274603,
              33.841096
            ],
            [
              -93.275047,
              33.84122
            ],
            [
              -93.275494,
              33.841167
            ],
            [
              -93.276597,
              33.840575
            ],
            [
              -93.277421,
              33.840593
            ],
            [
              -93.27849,
              33.840743
            ],
            [
              -93.279215,
              33.840981
            ],
            [
              -93.279661,
              33.841319
            ],
            [
              -93.281435,
              33.842466
            ],
            [
              -93.282609,
              33.84292
            ],
            [
              -93.283138,
              33.843281
            ],
            [
              -93.283155,
              33.843458
            ],
            [
              -93.283653,
              33.843978
            ],
            [
              -93.284385,
              33.844177
            ],
            [
              -93.284848,
              33.844331
            ],
            [
              -93.285202,
              33.844202
            ],
            [
              -93.285794,
              33.843785
            ],
            [
              -93.286327,
              33.843637
            ],
            [
              -93.286545,
              33.843422
            ],
            [
              -93.287025,
              33.843244
            ],
            [
              -93.288214,
              33.843576
            ],
            [
              -93.28922,
              33.843993
            ],
            [
              -93.290264,
              33.844744
            ],
            [
              -93.307625,
              33.881955
            ],
            [
              -93.304073,
              33.897867
            ],
            [
              -93.32258,
              33.907817
            ],
            [
              -93.358232,
              33.94805
            ],
            [
              -93.373695,
              33.951208
            ],
            [
              -93.373406,
              33.95707
            ],
            [
              -93.419093,
              33.948794
            ],
            [
              -93.456709,
              33.956837
            ],
            [
              -93.460897,
              33.824983
            ],
            [
              -93.471207,
              33.825224
            ],
            [
              -93.473768,
              33.752898
            ],
            [
              -93.474152,
              33.738545
            ],
            [
              -93.474757,
              33.733121
            ],
            [
              -93.475345,
              33.71679
            ],
            [
              -93.480625,
              33.56334
            ],
            [
              -93.483097,
              33.476075
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "109",
      "COUNTYNS": "00069173",
      "AFFGEOID": "0500000US05109",
      "GEOID": "05109",
      "NAME": "Pike",
      "LSAD": "06",
      "ALAND": 1555599952,
      "AWATER": 34989238,
      "County_state": "Pike,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.935371,
              34.350097
            ],
            [
              -93.937009,
              34.263024
            ],
            [
              -93.92434,
              34.262985
            ],
            [
              -93.92587,
              34.18669
            ],
            [
              -93.820722,
              34.183802
            ],
            [
              -93.823076,
              34.008893
            ],
            [
              -93.749375,
              34.006743
            ],
            [
              -93.717788,
              33.996632
            ],
            [
              -93.69982,
              33.995045
            ],
            [
              -93.682369,
              33.983539
            ],
            [
              -93.648131,
              33.982967
            ],
            [
              -93.627423,
              33.972496
            ],
            [
              -93.61746,
              33.959627
            ],
            [
              -93.605066,
              33.96447
            ],
            [
              -93.572636,
              33.961096
            ],
            [
              -93.546549,
              33.951403
            ],
            [
              -93.530528,
              33.954125
            ],
            [
              -93.528047,
              33.943012
            ],
            [
              -93.498028,
              33.953857
            ],
            [
              -93.45977,
              33.963124
            ],
            [
              -93.456709,
              33.956837
            ],
            [
              -93.419093,
              33.948794
            ],
            [
              -93.373406,
              33.95707
            ],
            [
              -93.380588,
              33.973017
            ],
            [
              -93.379053,
              33.986309
            ],
            [
              -93.391915,
              34.000965
            ],
            [
              -93.40503,
              34.005759
            ],
            [
              -93.416751,
              34.038671
            ],
            [
              -93.434165,
              34.049739
            ],
            [
              -93.435087,
              34.070609
            ],
            [
              -93.444877,
              34.114299
            ],
            [
              -93.441629,
              34.120512
            ],
            [
              -93.456641,
              34.130526
            ],
            [
              -93.449125,
              34.146335
            ],
            [
              -93.455704,
              34.158312
            ],
            [
              -93.448601,
              34.180454
            ],
            [
              -93.460373,
              34.190354
            ],
            [
              -93.442995,
              34.208419
            ],
            [
              -93.46169,
              34.205751
            ],
            [
              -93.502208,
              34.214106
            ],
            [
              -93.50148,
              34.253771
            ],
            [
              -93.480366,
              34.253335
            ],
            [
              -93.478646,
              34.340851
            ],
            [
              -93.671204,
              34.345261
            ],
            [
              -93.743086,
              34.34813
            ],
            [
              -93.831536,
              34.347555
            ],
            [
              -93.935371,
              34.350097
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "011",
      "COUNTYNS": "00063757",
      "AFFGEOID": "0500000US05011",
      "GEOID": "05011",
      "NAME": "Bradley",
      "LSAD": "06",
      "ALAND": 1681514463,
      "AWATER": 9532926,
      "County_state": "Bradley,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.381768,
              33.342673
            ],
            [
              -92.378616,
              33.314733
            ],
            [
              -92.350432,
              33.298941
            ],
            [
              -92.358892,
              33.282017
            ],
            [
              -92.335557,
              33.27556
            ],
            [
              -92.328784,
              33.279786
            ],
            [
              -92.32646,
              33.262836
            ],
            [
              -92.309121,
              33.265009
            ],
            [
              -92.295644,
              33.280673
            ],
            [
              -92.293377,
              33.26491
            ],
            [
              -92.268737,
              33.264471
            ],
            [
              -92.265749,
              33.254371
            ],
            [
              -92.246723,
              33.250633
            ],
            [
              -92.235909,
              33.258343
            ],
            [
              -92.225105,
              33.253656
            ],
            [
              -92.232283,
              33.23135
            ],
            [
              -92.211924,
              33.223632
            ],
            [
              -92.222462,
              33.222019
            ],
            [
              -92.222362,
              33.209258
            ],
            [
              -92.195517,
              33.201587
            ],
            [
              -92.178971,
              33.212694
            ],
            [
              -92.181934,
              33.188273
            ],
            [
              -92.172507,
              33.188173
            ],
            [
              -92.162593,
              33.171847
            ],
            [
              -92.137527,
              33.162956
            ],
            [
              -92.130735,
              33.173791
            ],
            [
              -92.136124,
              33.196443
            ],
            [
              -92.128464,
              33.208775
            ],
            [
              -92.114919,
              33.206797
            ],
            [
              -92.115588,
              33.223279
            ],
            [
              -92.102304,
              33.218526
            ],
            [
              -92.088343,
              33.225924
            ],
            [
              -92.084609,
              33.240886
            ],
            [
              -92.071162,
              33.238312
            ],
            [
              -92.074094,
              33.250118
            ],
            [
              -92.044172,
              33.256922
            ],
            [
              -92.037381,
              33.266078
            ],
            [
              -92.042084,
              33.278233
            ],
            [
              -92.028085,
              33.275162
            ],
            [
              -92.001178,
              33.285767
            ],
            [
              -92.009288,
              33.297413
            ],
            [
              -91.977284,
              33.302026
            ],
            [
              -91.973526,
              33.320594
            ],
            [
              -91.987573,
              33.341017
            ],
            [
              -91.967058,
              33.356512
            ],
            [
              -91.969507,
              33.369247
            ],
            [
              -91.960014,
              33.37775
            ],
            [
              -91.986894,
              33.387284
            ],
            [
              -91.990167,
              33.398214
            ],
            [
              -92.002087,
              33.415809
            ],
            [
              -91.992276,
              33.435841
            ],
            [
              -92.011662,
              33.446205
            ],
            [
              -92.015334,
              33.457092
            ],
            [
              -92.000134,
              33.473927
            ],
            [
              -92.008934,
              33.486828
            ],
            [
              -91.999119,
              33.50011
            ],
            [
              -92.00785,
              33.529396
            ],
            [
              -91.97608,
              33.529687
            ],
            [
              -91.975852,
              33.697115
            ],
            [
              -91.975844,
              33.704414
            ],
            [
              -92.184267,
              33.705286
            ],
            [
              -92.285272,
              33.705553
            ],
            [
              -92.330839,
              33.707806
            ],
            [
              -92.320101,
              33.682331
            ],
            [
              -92.326606,
              33.664597
            ],
            [
              -92.321136,
              33.630213
            ],
            [
              -92.32872,
              33.616976
            ],
            [
              -92.320995,
              33.584939
            ],
            [
              -92.324269,
              33.572322
            ],
            [
              -92.314172,
              33.551496
            ],
            [
              -92.322913,
              33.524226
            ],
            [
              -92.311603,
              33.501975
            ],
            [
              -92.322148,
              33.486403
            ],
            [
              -92.32016,
              33.469061
            ],
            [
              -92.328031,
              33.443564
            ],
            [
              -92.340623,
              33.428328
            ],
            [
              -92.341517,
              33.414418
            ],
            [
              -92.355442,
              33.401692
            ],
            [
              -92.359995,
              33.379801
            ],
            [
              -92.374467,
              33.37865
            ],
            [
              -92.381768,
              33.342673
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "063",
      "COUNTYNS": "00069163",
      "AFFGEOID": "0500000US05063",
      "GEOID": "05063",
      "NAME": "Independence",
      "LSAD": "06",
      "ALAND": 1978631574,
      "AWATER": 19593589,
      "County_state": "Independence,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.870983,
              35.810562
            ],
            [
              -91.865097,
              35.770824
            ],
            [
              -91.836081,
              35.748855
            ],
            [
              -91.837179,
              35.704932
            ],
            [
              -91.806991,
              35.704532
            ],
            [
              -91.789823,
              35.711734
            ],
            [
              -91.793958,
              35.534114
            ],
            [
              -91.745107,
              35.534188
            ],
            [
              -91.708647,
              35.533267
            ],
            [
              -91.647957,
              35.532309
            ],
            [
              -91.58188,
              35.530447
            ],
            [
              -91.36957,
              35.526362
            ],
            [
              -91.369256,
              35.587402
            ],
            [
              -91.36878,
              35.640012
            ],
            [
              -91.325095,
              35.637043
            ],
            [
              -91.323178,
              35.647711
            ],
            [
              -91.340006,
              35.651994
            ],
            [
              -91.339161,
              35.665099
            ],
            [
              -91.35337,
              35.678846
            ],
            [
              -91.346965,
              35.688388
            ],
            [
              -91.355506,
              35.695333
            ],
            [
              -91.345961,
              35.721225
            ],
            [
              -91.338237,
              35.712944
            ],
            [
              -91.333125,
              35.740747
            ],
            [
              -91.310358,
              35.766173
            ],
            [
              -91.292132,
              35.771702
            ],
            [
              -91.277533,
              35.792644
            ],
            [
              -91.270843,
              35.788328
            ],
            [
              -91.247144,
              35.801366
            ],
            [
              -91.260824,
              35.803603
            ],
            [
              -91.246822,
              35.812652
            ],
            [
              -91.232392,
              35.812664
            ],
            [
              -91.233065,
              35.822832
            ],
            [
              -91.247689,
              35.834207
            ],
            [
              -91.23588,
              35.833042
            ],
            [
              -91.227891,
              35.843964
            ],
            [
              -91.208636,
              35.84929
            ],
            [
              -91.221619,
              35.851984
            ],
            [
              -91.21194,
              35.85916
            ],
            [
              -91.217862,
              35.881771
            ],
            [
              -91.198556,
              35.890024
            ],
            [
              -91.35723,
              35.890621
            ],
            [
              -91.464678,
              35.892497
            ],
            [
              -91.463198,
              35.93681
            ],
            [
              -91.633054,
              35.939359
            ],
            [
              -91.710552,
              35.941759
            ],
            [
              -91.753345,
              35.942883
            ],
            [
              -91.849448,
              35.867411
            ],
            [
              -91.849617,
              35.846274
            ],
            [
              -91.870983,
              35.810562
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "067",
      "COUNTYNS": "00066861",
      "AFFGEOID": "0500000US05067",
      "GEOID": "05067",
      "NAME": "Jackson",
      "LSAD": "06",
      "ALAND": 1641853230,
      "AWATER": 19623381,
      "County_state": "Jackson,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.58188,
              35.530447
            ],
            [
              -91.584687,
              35.442311
            ],
            [
              -91.347257,
              35.439106
            ],
            [
              -91.350179,
              35.444967
            ],
            [
              -91.252927,
              35.445845
            ],
            [
              -91.251504,
              35.357557
            ],
            [
              -91.039783,
              35.354256
            ],
            [
              -91.03964,
              35.39831
            ],
            [
              -91.03923,
              35.444717
            ],
            [
              -91.036967,
              35.708418
            ],
            [
              -91.03503,
              35.796566
            ],
            [
              -91.032499,
              35.88507
            ],
            [
              -91.138801,
              35.889999
            ],
            [
              -91.198556,
              35.890024
            ],
            [
              -91.217862,
              35.881771
            ],
            [
              -91.21194,
              35.85916
            ],
            [
              -91.221619,
              35.851984
            ],
            [
              -91.208636,
              35.84929
            ],
            [
              -91.227891,
              35.843964
            ],
            [
              -91.23588,
              35.833042
            ],
            [
              -91.247689,
              35.834207
            ],
            [
              -91.233065,
              35.822832
            ],
            [
              -91.232392,
              35.812664
            ],
            [
              -91.246822,
              35.812652
            ],
            [
              -91.260824,
              35.803603
            ],
            [
              -91.247144,
              35.801366
            ],
            [
              -91.270843,
              35.788328
            ],
            [
              -91.277533,
              35.792644
            ],
            [
              -91.292132,
              35.771702
            ],
            [
              -91.310358,
              35.766173
            ],
            [
              -91.333125,
              35.740747
            ],
            [
              -91.338237,
              35.712944
            ],
            [
              -91.345961,
              35.721225
            ],
            [
              -91.355506,
              35.695333
            ],
            [
              -91.346965,
              35.688388
            ],
            [
              -91.35337,
              35.678846
            ],
            [
              -91.339161,
              35.665099
            ],
            [
              -91.340006,
              35.651994
            ],
            [
              -91.323178,
              35.647711
            ],
            [
              -91.325095,
              35.637043
            ],
            [
              -91.36878,
              35.640012
            ],
            [
              -91.369256,
              35.587402
            ],
            [
              -91.36957,
              35.526362
            ],
            [
              -91.58188,
              35.530447
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "069",
      "COUNTYNS": "00066862",
      "AFFGEOID": "0500000US05069",
      "GEOID": "05069",
      "NAME": "Jefferson",
      "LSAD": "06",
      "ALAND": 2253166949,
      "AWATER": 113500176,
      "County_state": "Jefferson,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.232548,
              34.230822
            ],
            [
              -92.233376,
              34.062312
            ],
            [
              -92.069815,
              34.063518
            ],
            [
              -91.988497,
              34.063934
            ],
            [
              -91.953799,
              34.064141
            ],
            [
              -91.953629,
              34.09338
            ],
            [
              -91.942398,
              34.093513
            ],
            [
              -91.849685,
              34.093353
            ],
            [
              -91.74499,
              34.094021
            ],
            [
              -91.744845,
              34.064982
            ],
            [
              -91.726625,
              34.065114
            ],
            [
              -91.727276,
              34.174093
            ],
            [
              -91.712255,
              34.173458
            ],
            [
              -91.693672,
              34.170678
            ],
            [
              -91.64254,
              34.138055
            ],
            [
              -91.614192,
              34.128109
            ],
            [
              -91.589356,
              34.133418
            ],
            [
              -91.582315,
              34.125107
            ],
            [
              -91.602597,
              34.117878
            ],
            [
              -91.609763,
              34.103125
            ],
            [
              -91.602334,
              34.088056
            ],
            [
              -91.58309,
              34.08932
            ],
            [
              -91.569974,
              34.101438
            ],
            [
              -91.551959,
              34.105966
            ],
            [
              -91.531571,
              34.09787
            ],
            [
              -91.521693,
              34.087434
            ],
            [
              -91.505323,
              34.073887
            ],
            [
              -91.46967,
              34.084716
            ],
            [
              -91.445614,
              34.080147
            ],
            [
              -91.438174,
              34.096786
            ],
            [
              -91.448306,
              34.121195
            ],
            [
              -91.442798,
              34.125989
            ],
            [
              -91.451931,
              34.133865
            ],
            [
              -91.477982,
              34.134873
            ],
            [
              -91.493101,
              34.127254
            ],
            [
              -91.494809,
              34.14898
            ],
            [
              -91.514374,
              34.142293
            ],
            [
              -91.520804,
              34.163681
            ],
            [
              -91.514551,
              34.171392
            ],
            [
              -91.527556,
              34.184077
            ],
            [
              -91.528344,
              34.215127
            ],
            [
              -91.547304,
              34.223019
            ],
            [
              -91.572314,
              34.2228
            ],
            [
              -91.594798,
              34.212203
            ],
            [
              -91.592535,
              34.228761
            ],
            [
              -91.711795,
              34.233919
            ],
            [
              -91.709709,
              34.307764
            ],
            [
              -91.705185,
              34.48267
            ],
            [
              -91.974805,
              34.488683
            ],
            [
              -92.014398,
              34.48947
            ],
            [
              -92.029996,
              34.489352
            ],
            [
              -92.14369,
              34.49151
            ],
            [
              -92.208041,
              34.492765
            ],
            [
              -92.209129,
              34.405612
            ],
            [
              -92.209424,
              34.386644
            ],
            [
              -92.210508,
              34.318074
            ],
            [
              -92.211302,
              34.306493
            ],
            [
              -92.211585,
              34.288821
            ],
            [
              -92.212906,
              34.2304
            ],
            [
              -92.232548,
              34.230822
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "075",
      "COUNTYNS": "00066865",
      "AFFGEOID": "0500000US05075",
      "GEOID": "05075",
      "NAME": "Lawrence",
      "LSAD": "06",
      "ALAND": 1521880659,
      "AWATER": 12286159,
      "County_state": "Lawrence,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.354342,
              35.979615
            ],
            [
              -91.35723,
              35.890621
            ],
            [
              -91.198556,
              35.890024
            ],
            [
              -91.138801,
              35.889999
            ],
            [
              -91.032499,
              35.88507
            ],
            [
              -90.872785,
              35.88087
            ],
            [
              -90.872175,
              35.9244
            ],
            [
              -90.861412,
              35.92409
            ],
            [
              -90.85722,
              35.95074
            ],
            [
              -90.861542,
              35.955605
            ],
            [
              -90.852064,
              35.968092
            ],
            [
              -90.841595,
              35.97852
            ],
            [
              -90.858502,
              35.990931
            ],
            [
              -90.854873,
              36.002561
            ],
            [
              -90.837975,
              36.022229
            ],
            [
              -90.839042,
              36.039858
            ],
            [
              -90.825207,
              36.055478
            ],
            [
              -90.820953,
              36.079539
            ],
            [
              -90.790671,
              36.093429
            ],
            [
              -90.77087,
              36.113505
            ],
            [
              -90.768559,
              36.12789
            ],
            [
              -90.763481,
              36.134509
            ],
            [
              -90.748329,
              36.14632
            ],
            [
              -90.74747,
              36.149116
            ],
            [
              -90.76867,
              36.14934
            ],
            [
              -90.809816,
              36.149567
            ],
            [
              -90.882375,
              36.150475
            ],
            [
              -90.95646,
              36.15049
            ],
            [
              -91.045223,
              36.151368
            ],
            [
              -91.052023,
              36.152868
            ],
            [
              -91.064724,
              36.143469
            ],
            [
              -91.063593,
              36.119022
            ],
            [
              -91.07302,
              36.114802
            ],
            [
              -91.072224,
              36.125369
            ],
            [
              -91.086224,
              36.132669
            ],
            [
              -91.082681,
              36.150871
            ],
            [
              -91.119625,
              36.153671
            ],
            [
              -91.141604,
              36.16641
            ],
            [
              -91.143934,
              36.18009
            ],
            [
              -91.157325,
              36.191001
            ],
            [
              -91.16308,
              36.205917
            ],
            [
              -91.183388,
              36.206045
            ],
            [
              -91.204046,
              36.218576
            ],
            [
              -91.227312,
              36.225122
            ],
            [
              -91.232559,
              36.2408
            ],
            [
              -91.24203,
              36.225667
            ],
            [
              -91.240729,
              36.257667
            ],
            [
              -91.25892,
              36.25788
            ],
            [
              -91.259326,
              36.243467
            ],
            [
              -91.331533,
              36.244867
            ],
            [
              -91.332233,
              36.230766
            ],
            [
              -91.349834,
              36.230967
            ],
            [
              -91.351933,
              36.157969
            ],
            [
              -91.354342,
              35.979615
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "117",
      "COUNTYNS": "00069176",
      "AFFGEOID": "0500000US05117",
      "GEOID": "05117",
      "NAME": "Prairie",
      "LSAD": "06",
      "ALAND": 1678019592,
      "AWATER": 71807768,
      "County_state": "Prairie,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.802505,
              35.030425
            ],
            [
              -91.803703,
              34.921524
            ],
            [
              -91.693705,
              34.919605
            ],
            [
              -91.694682,
              34.875687
            ],
            [
              -91.700828,
              34.672029
            ],
            [
              -91.693098,
              34.670963
            ],
            [
              -91.64308,
              34.636862
            ],
            [
              -91.638531,
              34.626093
            ],
            [
              -91.648314,
              34.612762
            ],
            [
              -91.665029,
              34.579888
            ],
            [
              -91.636605,
              34.566532
            ],
            [
              -91.63283,
              34.552711
            ],
            [
              -91.669914,
              34.51887
            ],
            [
              -91.681051,
              34.499054
            ],
            [
              -91.678037,
              34.48192
            ],
            [
              -91.616016,
              34.479537
            ],
            [
              -91.59552,
              34.48051
            ],
            [
              -91.594429,
              34.56805
            ],
            [
              -91.37782,
              34.56348
            ],
            [
              -91.377067,
              34.592851
            ],
            [
              -91.41262,
              34.593782
            ],
            [
              -91.412178,
              34.608736
            ],
            [
              -91.447795,
              34.609185
            ],
            [
              -91.446983,
              34.667531
            ],
            [
              -91.374765,
              34.665964
            ],
            [
              -91.370598,
              34.824276
            ],
            [
              -91.373055,
              34.826195
            ],
            [
              -91.36065,
              34.862122
            ],
            [
              -91.343872,
              34.873551
            ],
            [
              -91.381922,
              34.905126
            ],
            [
              -91.36856,
              34.912735
            ],
            [
              -91.366638,
              35.007268
            ],
            [
              -91.363763,
              35.051887
            ],
            [
              -91.441912,
              35.052215
            ],
            [
              -91.438879,
              35.060931
            ],
            [
              -91.461393,
              35.051875
            ],
            [
              -91.478299,
              35.058088
            ],
            [
              -91.475949,
              35.06648
            ],
            [
              -91.446014,
              35.067981
            ],
            [
              -91.465114,
              35.089454
            ],
            [
              -91.584005,
              35.091584
            ],
            [
              -91.5851,
              35.025234
            ],
            [
              -91.608426,
              35.020629
            ],
            [
              -91.640643,
              35.027459
            ],
            [
              -91.644149,
              35.042887
            ],
            [
              -91.664709,
              35.053982
            ],
            [
              -91.679371,
              35.05055
            ],
            [
              -91.701413,
              35.063014
            ],
            [
              -91.744846,
              35.047037
            ],
            [
              -91.766029,
              35.050021
            ],
            [
              -91.802505,
              35.030425
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "105",
      "COUNTYNS": "00069171",
      "AFFGEOID": "0500000US05105",
      "GEOID": "05105",
      "NAME": "Perry",
      "LSAD": "06",
      "ALAND": 1428543051,
      "AWATER": 23226441,
      "County_state": "Perry,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.305684,
              34.875592
            ],
            [
              -93.305885,
              34.861092
            ],
            [
              -93.288284,
              34.860992
            ],
            [
              -93.287785,
              34.773193
            ],
            [
              -93.162059,
              34.771992
            ],
            [
              -93.075178,
              34.771892
            ],
            [
              -93.073877,
              34.858091
            ],
            [
              -92.969442,
              34.859109
            ],
            [
              -92.829685,
              34.855339
            ],
            [
              -92.81773,
              34.84756
            ],
            [
              -92.802407,
              34.849845
            ],
            [
              -92.780651,
              34.839594
            ],
            [
              -92.75643,
              34.842562
            ],
            [
              -92.7406,
              34.828121
            ],
            [
              -92.739779,
              34.853459
            ],
            [
              -92.755434,
              34.853711
            ],
            [
              -92.753626,
              34.911248
            ],
            [
              -92.64989,
              34.91003
            ],
            [
              -92.647754,
              34.939222
            ],
            [
              -92.586582,
              34.937503
            ],
            [
              -92.544614,
              34.936256
            ],
            [
              -92.544378,
              34.951832
            ],
            [
              -92.544341,
              34.953319
            ],
            [
              -92.571976,
              34.962883
            ],
            [
              -92.597762,
              34.995827
            ],
            [
              -92.58418,
              35.020153
            ],
            [
              -92.569926,
              35.028506
            ],
            [
              -92.560971,
              35.035417
            ],
            [
              -92.545452,
              35.055144
            ],
            [
              -92.54103,
              35.077377
            ],
            [
              -92.541481,
              35.088559
            ],
            [
              -92.544635,
              35.097321
            ],
            [
              -92.549825,
              35.109559
            ],
            [
              -92.554685,
              35.113277
            ],
            [
              -92.56339,
              35.115827
            ],
            [
              -92.599697,
              35.085364
            ],
            [
              -92.621844,
              35.077694
            ],
            [
              -92.644709,
              35.076373
            ],
            [
              -92.643955,
              35.08426
            ],
            [
              -92.625161,
              35.092944
            ],
            [
              -92.623812,
              35.109116
            ],
            [
              -92.649229,
              35.104632
            ],
            [
              -92.675382,
              35.078452
            ],
            [
              -92.716331,
              35.071742
            ],
            [
              -92.909267,
              35.074727
            ],
            [
              -92.962488,
              35.075776
            ],
            [
              -92.980716,
              35.075536
            ],
            [
              -93.040525,
              35.076923
            ],
            [
              -93.051255,
              35.076978
            ],
            [
              -93.051491,
              35.062352
            ],
            [
              -93.069295,
              35.062627
            ],
            [
              -93.069526,
              35.048089
            ],
            [
              -93.087297,
              35.048312
            ],
            [
              -93.087329,
              35.033999
            ],
            [
              -93.10559,
              35.033868
            ],
            [
              -93.105954,
              35.017873
            ],
            [
              -93.123791,
              35.018661
            ],
            [
              -93.124127,
              35.004262
            ],
            [
              -93.141797,
              35.004698
            ],
            [
              -93.142277,
              34.99029
            ],
            [
              -93.160277,
              34.99059
            ],
            [
              -93.160177,
              34.97599
            ],
            [
              -93.181478,
              34.97639
            ],
            [
              -93.181778,
              34.96199
            ],
            [
              -93.200086,
              34.962263
            ],
            [
              -93.200579,
              34.94759
            ],
            [
              -93.217348,
              34.947945
            ],
            [
              -93.21748,
              34.932691
            ],
            [
              -93.235181,
              34.932591
            ],
            [
              -93.235081,
              34.918091
            ],
            [
              -93.252185,
              34.918323
            ],
            [
              -93.252448,
              34.906247
            ],
            [
              -93.255734,
              34.903808
            ],
            [
              -93.270473,
              34.903908
            ],
            [
              -93.270683,
              34.889191
            ],
            [
              -93.288184,
              34.888692
            ],
            [
              -93.288184,
              34.875292
            ],
            [
              -93.305684,
              34.875592
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "113",
      "COUNTYNS": "00069175",
      "AFFGEOID": "0500000US05113",
      "GEOID": "05113",
      "NAME": "Polk",
      "LSAD": "06",
      "ALAND": 2221382755,
      "AWATER": 12372286,
      "County_state": "Polk,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.465847,
              34.352073
            ],
            [
              -94.4702923271421,
              34.189864
            ],
            [
              -94.243739,
              34.192501
            ],
            [
              -94.243885,
              34.269462
            ],
            [
              -94.256128,
              34.269306
            ],
            [
              -94.254668,
              34.355481
            ],
            [
              -93.935371,
              34.350097
            ],
            [
              -93.932489,
              34.550888
            ],
            [
              -93.930509,
              34.665608
            ],
            [
              -93.930101,
              34.680102
            ],
            [
              -94.018201,
              34.683324
            ],
            [
              -94.018168,
              34.692469
            ],
            [
              -94.317639,
              34.697522
            ],
            [
              -94.316845,
              34.726503
            ],
            [
              -94.4545761452896,
              34.7289624171492
            ],
            [
              -94.45579465995209,
              34.6934853886321
            ],
            [
              -94.45753,
              34.642961
            ],
            [
              -94.4575,
              34.634945
            ],
            [
              -94.460052,
              34.547869
            ],
            [
              -94.460058,
              34.545264
            ],
            [
              -94.461149,
              34.507457
            ],
            [
              -94.463671,
              34.419585
            ],
            [
              -94.463816,
              34.414465
            ],
            [
              -94.464176,
              34.402713
            ],
            [
              -94.465425,
              34.359548
            ],
            [
              -94.465847,
              34.352073
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "015",
      "COUNTYNS": "00066975",
      "AFFGEOID": "0500000US05015",
      "GEOID": "05015",
      "NAME": "Carroll",
      "LSAD": "06",
      "ALAND": 1631597803,
      "AWATER": 22434173,
      "County_state": "Carroll,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.882117,
              36.394239
            ],
            [
              -93.890508,
              36.377894
            ],
            [
              -93.880492,
              36.368913
            ],
            [
              -93.866836,
              36.376659
            ],
            [
              -93.861698,
              36.339266
            ],
            [
              -93.833084,
              36.322371
            ],
            [
              -93.832124,
              36.31281
            ],
            [
              -93.815605,
              36.306563
            ],
            [
              -93.657689,
              36.303082
            ],
            [
              -93.663023,
              36.275009
            ],
            [
              -93.639833,
              36.283253
            ],
            [
              -93.631062,
              36.277672
            ],
            [
              -93.641609,
              36.268231
            ],
            [
              -93.618184,
              36.258871
            ],
            [
              -93.619974,
              36.201208
            ],
            [
              -93.611713,
              36.186571
            ],
            [
              -93.584866,
              36.185741
            ],
            [
              -93.585258,
              36.166338
            ],
            [
              -93.585476,
              36.1557
            ],
            [
              -93.550724,
              36.155033
            ],
            [
              -93.551135,
              36.126271
            ],
            [
              -93.479528,
              36.125072
            ],
            [
              -93.444504,
              36.124293
            ],
            [
              -93.30082,
              36.12155
            ],
            [
              -93.299621,
              36.178704
            ],
            [
              -93.299171,
              36.232412
            ],
            [
              -93.29755,
              36.29656
            ],
            [
              -93.296337,
              36.417296
            ],
            [
              -93.298642,
              36.496964
            ],
            [
              -93.2934668009526,
              36.498343671809
            ],
            [
              -93.31532440839989,
              36.4984079629635
            ],
            [
              -93.315337,
              36.498408
            ],
            [
              -93.394718,
              36.498519
            ],
            [
              -93.396079,
              36.498669
            ],
            [
              -93.426989,
              36.498585
            ],
            [
              -93.507408,
              36.498911
            ],
            [
              -93.514512,
              36.498881
            ],
            [
              -93.584281,
              36.498896
            ],
            [
              -93.5842819998356,
              36.498896002062004
            ],
            [
              -93.67922490554069,
              36.4990918028598
            ],
            [
              -93.700171,
              36.499135
            ],
            [
              -93.709956,
              36.499179
            ],
            [
              -93.718893,
              36.499178
            ],
            [
              -93.727552,
              36.499055
            ],
            [
              -93.728022,
              36.499037
            ],
            [
              -93.86675809584749,
              36.498788514184895
            ],
            [
              -93.866785,
              36.394452
            ],
            [
              -93.882117,
              36.394239
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "107",
      "COUNTYNS": "00069172",
      "AFFGEOID": "0500000US05107",
      "GEOID": "05107",
      "NAME": "Phillips",
      "LSAD": "06",
      "ALAND": 1801429477,
      "AWATER": 82378714,
      "County_state": "Phillips,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.111478,
              34.130303
            ],
            [
              -91.117905,
              34.119105
            ],
            [
              -90.95500367987549,
              34.1189731025439
            ],
            [
              -90.955974,
              34.120125
            ],
            [
              -90.958467,
              34.125105
            ],
            [
              -90.959317,
              34.13035
            ],
            [
              -90.9543,
              34.138498
            ],
            [
              -90.938064,
              34.148754
            ],
            [
              -90.91001,
              34.165508
            ],
            [
              -90.903577,
              34.164332
            ],
            [
              -90.894385,
              34.160953
            ],
            [
              -90.883073,
              34.151502
            ],
            [
              -90.876836,
              34.14813
            ],
            [
              -90.86788,
              34.142146
            ],
            [
              -90.86458,
              34.140555
            ],
            [
              -90.853471,
              34.137511
            ],
            [
              -90.847168,
              34.136884
            ],
            [
              -90.836099,
              34.137876
            ],
            [
              -90.830285,
              34.139813
            ],
            [
              -90.825708,
              34.142011
            ],
            [
              -90.822593,
              34.144054
            ],
            [
              -90.815878,
              34.149879
            ],
            [
              -90.810884,
              34.155903
            ],
            [
              -90.807813,
              34.161474
            ],
            [
              -90.807164,
              34.16746
            ],
            [
              -90.808685,
              34.175878
            ],
            [
              -90.810016,
              34.178437
            ],
            [
              -90.812374,
              34.180767
            ],
            [
              -90.816572,
              34.183023
            ],
            [
              -90.828388,
              34.184784
            ],
            [
              -90.838205,
              34.183804
            ],
            [
              -90.847108,
              34.186053
            ],
            [
              -90.8556,
              34.18688
            ],
            [
              -90.859087,
              34.186288
            ],
            [
              -90.864566,
              34.183882
            ],
            [
              -90.869651,
              34.182965
            ],
            [
              -90.87383,
              34.18322
            ],
            [
              -90.877475,
              34.185633
            ],
            [
              -90.882701,
              34.184364
            ],
            [
              -90.887884,
              34.18198
            ],
            [
              -90.891871,
              34.184766
            ],
            [
              -90.9118,
              34.193897
            ],
            [
              -90.916048,
              34.196916
            ],
            [
              -90.93268,
              34.214824
            ],
            [
              -90.93522,
              34.21905
            ],
            [
              -90.936988,
              34.227041
            ],
            [
              -90.937152,
              34.23411
            ],
            [
              -90.936404,
              34.236698
            ],
            [
              -90.933511,
              34.240218
            ],
            [
              -90.929015,
              34.244541
            ],
            [
              -90.923152,
              34.24653
            ],
            [
              -90.912396,
              34.245932
            ],
            [
              -90.907082,
              34.244492
            ],
            [
              -90.905934,
              34.243529
            ],
            [
              -90.904279,
              34.24096
            ],
            [
              -90.900078,
              34.229621
            ],
            [
              -90.898286,
              34.227253
            ],
            [
              -90.89456,
              34.22438
            ],
            [
              -90.87912,
              34.21545
            ],
            [
              -90.867064,
              34.212141
            ],
            [
              -90.856708,
              34.211598
            ],
            [
              -90.852764,
              34.209403
            ],
            [
              -90.847808,
              34.20653
            ],
            [
              -90.844824,
              34.210999
            ],
            [
              -90.842151,
              34.21688
            ],
            [
              -90.840009,
              34.223077
            ],
            [
              -90.839509,
              34.226201
            ],
            [
              -90.840128,
              34.230104
            ],
            [
              -90.839981,
              34.236114
            ],
            [
              -90.836972,
              34.250104
            ],
            [
              -90.832407,
              34.267491
            ],
            [
              -90.830612,
              34.271245
            ],
            [
              -90.828267,
              34.27365
            ],
            [
              -90.824478,
              34.27624
            ],
            [
              -90.820919,
              34.27784
            ],
            [
              -90.812829,
              34.279438
            ],
            [
              -90.802928,
              34.282465
            ],
            [
              -90.797569,
              34.282402
            ],
            [
              -90.772266,
              34.279943
            ],
            [
              -90.765165,
              34.280524
            ],
            [
              -90.755271,
              34.286848
            ],
            [
              -90.752681,
              34.289266
            ],
            [
              -90.743082,
              34.302257
            ],
            [
              -90.740889,
              34.306538
            ],
            [
              -90.74061,
              34.313469
            ],
            [
              -90.742694,
              34.320263
            ],
            [
              -90.744713,
              34.324872
            ],
            [
              -90.748942,
              34.331045
            ],
            [
              -90.765174,
              34.342818
            ],
            [
              -90.767108,
              34.345264
            ],
            [
              -90.767732,
              34.346872
            ],
            [
              -90.768445,
              34.353469
            ],
            [
              -90.767061,
              34.360271
            ],
            [
              -90.765764,
              34.362109
            ],
            [
              -90.762085,
              34.364754
            ],
            [
              -90.756197,
              34.367256
            ],
            [
              -90.750107,
              34.367919
            ],
            [
              -90.741616,
              34.367225
            ],
            [
              -90.729131,
              34.364206
            ],
            [
              -90.724909,
              34.363659
            ],
            [
              -90.712088,
              34.363805
            ],
            [
              -90.700147,
              34.365855
            ],
            [
              -90.690497,
              34.368606
            ],
            [
              -90.683222,
              34.368817
            ],
            [
              -90.681921,
              34.365998
            ],
            [
              -90.680512,
              34.362529
            ],
            [
              -90.68162,
              34.35291
            ],
            [
              -90.691551,
              34.338618
            ],
            [
              -90.693686,
              34.32968
            ],
            [
              -90.693129,
              34.32257
            ],
            [
              -90.690005,
              34.318584
            ],
            [
              -90.686003,
              34.315771
            ],
            [
              -90.678097,
              34.313031
            ],
            [
              -90.669343,
              34.31302
            ],
            [
              -90.661395,
              34.315398
            ],
            [
              -90.657488,
              34.322231
            ],
            [
              -90.657371,
              34.327287
            ],
            [
              -90.660404,
              34.33576
            ],
            [
              -90.66087440465779,
              34.336693015370294
            ],
            [
              -90.666862,
              34.348569
            ],
            [
              -90.666788,
              34.35582
            ],
            [
              -90.655346,
              34.371846
            ],
            [
              -90.658542,
              34.375705
            ],
            [
              -90.641398,
              34.383869
            ],
            [
              -90.63494,
              34.386363
            ],
            [
              -90.631586,
              34.387193
            ],
            [
              -90.61848,
              34.388767
            ],
            [
              -90.613944,
              34.390723
            ],
            [
              -90.580677,
              34.410554
            ],
            [
              -90.575336,
              34.415152
            ],
            [
              -90.571145,
              34.420319
            ],
            [
              -90.568397,
              34.424805
            ],
            [
              -90.566505,
              34.429528
            ],
            [
              -90.565826,
              34.434379
            ],
            [
              -90.56733,
              34.440383
            ],
            [
              -90.573959,
              34.451875
            ],
            [
              -90.576893,
              34.454351
            ],
            [
              -90.583717,
              34.458829
            ],
            [
              -90.585477,
              34.461247
            ],
            [
              -90.588346,
              34.470562
            ],
            [
              -90.589921,
              34.484793
            ],
            [
              -90.588942,
              34.491097
            ],
            [
              -90.587490456876,
              34.4965056046236
            ],
            [
              -90.586525,
              34.500103
            ],
            [
              -90.58353,
              34.504085
            ],
            [
              -90.581062,
              34.512818
            ],
            [
              -90.578493,
              34.516296
            ],
            [
              -90.569347,
              34.524867
            ],
            [
              -90.555276,
              34.531012
            ],
            [
              -90.545728,
              34.53775
            ],
            [
              -90.543633,
              34.540378
            ],
            [
              -90.541282,
              34.545331
            ],
            [
              -90.540736,
              34.548085
            ],
            [
              -90.540951,
              34.550853
            ],
            [
              -90.545891,
              34.563257
            ],
            [
              -90.549244,
              34.568101
            ],
            [
              -90.557666,
              34.576929
            ],
            [
              -90.564866,
              34.582602
            ],
            [
              -90.570133,
              34.587457
            ],
            [
              -90.574787,
              34.595243
            ],
            [
              -90.581693,
              34.604227
            ],
            [
              -90.587224,
              34.615732
            ],
            [
              -90.588255,
              34.626616
            ],
            [
              -90.5834403324521,
              34.641389257845596
            ],
            [
              -90.8208,
              34.643
            ],
            [
              -90.980185,
              34.644591
            ],
            [
              -91.051918,
              34.645721
            ],
            [
              -91.054774,
              34.47088
            ],
            [
              -91.000459,
              34.469398
            ],
            [
              -91.002693,
              34.336353
            ],
            [
              -91.056276,
              34.336605
            ],
            [
              -91.057667,
              34.318501
            ],
            [
              -91.077663,
              34.283322
            ],
            [
              -91.07331,
              34.265737
            ],
            [
              -91.062595,
              34.25494
            ],
            [
              -91.079232,
              34.249844
            ],
            [
              -91.065361,
              34.238601
            ],
            [
              -91.060626,
              34.216766
            ],
            [
              -91.070451,
              34.201384
            ],
            [
              -91.085368,
              34.202224
            ],
            [
              -91.094292,
              34.176856
            ],
            [
              -91.083768,
              34.159356
            ],
            [
              -91.090373,
              34.156187
            ],
            [
              -91.078628,
              34.143729
            ],
            [
              -91.095684,
              34.140803
            ],
            [
              -91.098479,
              34.131313
            ],
            [
              -91.111478,
              34.130303
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "003",
      "COUNTYNS": "00063756",
      "AFFGEOID": "0500000US05003",
      "GEOID": "05003",
      "NAME": "Ashley",
      "LSAD": "06",
      "ALAND": 2396650149,
      "AWATER": 39309860,
      "County_state": "Ashley,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.130735,
              33.173791
            ],
            [
              -92.137527,
              33.162956
            ],
            [
              -92.110748,
              33.157354
            ],
            [
              -92.113205,
              33.132055
            ],
            [
              -92.128849,
              33.11891
            ],
            [
              -92.132663,
              33.096724
            ],
            [
              -92.12938,
              33.070238
            ],
            [
              -92.114393,
              33.07017
            ],
            [
              -92.10848,
              33.031973
            ],
            [
              -92.084516,
              33.031983
            ],
            [
              -92.069105,
              33.008163
            ],
            [
              -91.951958,
              33.007428
            ],
            [
              -91.950001,
              33.00752
            ],
            [
              -91.875128,
              33.007728
            ],
            [
              -91.755068,
              33.00701
            ],
            [
              -91.735531,
              33.007569
            ],
            [
              -91.62763897199659,
              33.006647277932
            ],
            [
              -91.62667,
              33.006639
            ],
            [
              -91.617615,
              33.006717
            ],
            [
              -91.609001,
              33.006556
            ],
            [
              -91.579802,
              33.006518
            ],
            [
              -91.579639,
              33.006472
            ],
            [
              -91.572326,
              33.006908
            ],
            [
              -91.559494,
              33.00684
            ],
            [
              -91.500118,
              33.006784
            ],
            [
              -91.489176,
              33.006182
            ],
            [
              -91.4604000514466,
              33.0059095659352
            ],
            [
              -91.45737,
              33.388974
            ],
            [
              -91.606099,
              33.391424
            ],
            [
              -91.872532,
              33.396343
            ],
            [
              -91.890827,
              33.396417
            ],
            [
              -91.990167,
              33.398214
            ],
            [
              -91.986894,
              33.387284
            ],
            [
              -91.960014,
              33.37775
            ],
            [
              -91.969507,
              33.369247
            ],
            [
              -91.967058,
              33.356512
            ],
            [
              -91.987573,
              33.341017
            ],
            [
              -91.973526,
              33.320594
            ],
            [
              -91.977284,
              33.302026
            ],
            [
              -92.009288,
              33.297413
            ],
            [
              -92.001178,
              33.285767
            ],
            [
              -92.028085,
              33.275162
            ],
            [
              -92.042084,
              33.278233
            ],
            [
              -92.037381,
              33.266078
            ],
            [
              -92.044172,
              33.256922
            ],
            [
              -92.074094,
              33.250118
            ],
            [
              -92.071162,
              33.238312
            ],
            [
              -92.084609,
              33.240886
            ],
            [
              -92.088343,
              33.225924
            ],
            [
              -92.102304,
              33.218526
            ],
            [
              -92.115588,
              33.223279
            ],
            [
              -92.114919,
              33.206797
            ],
            [
              -92.128464,
              33.208775
            ],
            [
              -92.136124,
              33.196443
            ],
            [
              -92.130735,
              33.173791
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "017",
      "COUNTYNS": "00069160",
      "AFFGEOID": "0500000US05017",
      "GEOID": "05017",
      "NAME": "Chicot",
      "LSAD": "06",
      "ALAND": 1668823393,
      "AWATER": 120315319,
      "County_state": "Chicot,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.45737,
              33.388974
            ],
            [
              -91.4604000514466,
              33.0059095659352
            ],
            [
              -91.453369,
              33.005843
            ],
            [
              -91.435782,
              33.006099
            ],
            [
              -91.425466,
              33.006016
            ],
            [
              -91.376016,
              33.005794
            ],
            [
              -91.333011,
              33.005529
            ],
            [
              -91.329767,
              33.005421
            ],
            [
              -91.326396,
              33.005376
            ],
            [
              -91.325037,
              33.005364
            ],
            [
              -91.322506,
              33.005341
            ],
            [
              -91.312016,
              33.005262
            ],
            [
              -91.284398,
              33.005007
            ],
            [
              -91.265018,
              33.005084
            ],
            [
              -91.166073,
              33.004106
            ],
            [
              -91.166282,
              33.011331
            ],
            [
              -91.162363,
              33.019684
            ],
            [
              -91.15616,
              33.02358
            ],
            [
              -91.149758,
              33.026312
            ],
            [
              -91.142424,
              33.027231
            ],
            [
              -91.137439,
              33.028736
            ],
            [
              -91.129088,
              33.033554
            ],
            [
              -91.125656,
              33.038276
            ],
            [
              -91.123441,
              33.046577
            ],
            [
              -91.120379,
              33.05453
            ],
            [
              -91.120293,
              33.055921
            ],
            [
              -91.121195,
              33.059166
            ],
            [
              -91.124639,
              33.064127
            ],
            [
              -91.149823,
              33.081603
            ],
            [
              -91.160656,
              33.085596
            ],
            [
              -91.165918,
              33.086174
            ],
            [
              -91.171514,
              33.087818
            ],
            [
              -91.173546,
              33.089318
            ],
            [
              -91.174723,
              33.09164
            ],
            [
              -91.180836,
              33.098364
            ],
            [
              -91.195953,
              33.104561
            ],
            [
              -91.200167,
              33.10693
            ],
            [
              -91.201125,
              33.108185
            ],
            [
              -91.201462,
              33.109638
            ],
            [
              -91.202089,
              33.121249
            ],
            [
              -91.20178,
              33.125121
            ],
            [
              -91.195296,
              33.134731
            ],
            [
              -91.193174,
              33.136734
            ],
            [
              -91.188718,
              33.139811
            ],
            [
              -91.183662,
              33.141691
            ],
            [
              -91.169406,
              33.142639
            ],
            [
              -91.161651,
              33.141781
            ],
            [
              -91.160298,
              33.141216
            ],
            [
              -91.153015,
              33.135093
            ],
            [
              -91.151853,
              33.131802
            ],
            [
              -91.150362,
              33.130695
            ],
            [
              -91.143334,
              33.129785
            ],
            [
              -91.131659,
              33.129101
            ],
            [
              -91.114087,
              33.129834
            ],
            [
              -91.104317,
              33.131598
            ],
            [
              -91.093201,
              33.136726
            ],
            [
              -91.089862,
              33.139655
            ],
            [
              -91.087589,
              33.145177
            ],
            [
              -91.0869117647879,
              33.1487335707116
            ],
            [
              -91.085562,
              33.155822
            ],
            [
              -91.084889,
              33.1618
            ],
            [
              -91.084366,
              33.180856
            ],
            [
              -91.086963,
              33.198509
            ],
            [
              -91.089909,
              33.210194
            ],
            [
              -91.091711,
              33.220813
            ],
            [
              -91.085984,
              33.221644
            ],
            [
              -91.082878,
              33.221621
            ],
            [
              -91.079635,
              33.223225
            ],
            [
              -91.079227,
              33.223889
            ],
            [
              -91.079227,
              33.2265
            ],
            [
              -91.077673,
              33.227485
            ],
            [
              -91.074103,
              33.226821
            ],
            [
              -91.070697,
              33.227302
            ],
            [
              -91.071079,
              33.230096
            ],
            [
              -91.068708,
              33.232936
            ],
            [
              -91.065629,
              33.232982
            ],
            [
              -91.063912,
              33.237356
            ],
            [
              -91.054126,
              33.246105
            ],
            [
              -91.050407,
              33.251202
            ],
            [
              -91.047648,
              33.259989
            ],
            [
              -91.045191,
              33.265404
            ],
            [
              -91.043985,
              33.269835
            ],
            [
              -91.043624,
              33.274636
            ],
            [
              -91.045141,
              33.279015
            ],
            [
              -91.04815,
              33.282796
            ],
            [
              -91.052369,
              33.285415
            ],
            [
              -91.05873,
              33.286901
            ],
            [
              -91.067035,
              33.28718
            ],
            [
              -91.072567,
              33.285885
            ],
            [
              -91.07853,
              33.283306
            ],
            [
              -91.081244,
              33.28125
            ],
            [
              -91.083694,
              33.278557
            ],
            [
              -91.086137,
              33.273652
            ],
            [
              -91.090342,
              33.257325
            ],
            [
              -91.091489,
              33.254838
            ],
            [
              -91.094748,
              33.250499
            ],
            [
              -91.096931,
              33.241628
            ],
            [
              -91.099093,
              33.238173
            ],
            [
              -91.1001,
              33.238125
            ],
            [
              -91.106142,
              33.241799
            ],
            [
              -91.110561,
              33.24593
            ],
            [
              -91.114325,
              33.252953
            ],
            [
              -91.117223,
              33.260685
            ],
            [
              -91.118208,
              33.262071
            ],
            [
              -91.128078,
              33.268502
            ],
            [
              -91.125528,
              33.274732
            ],
            [
              -91.125539,
              33.280255
            ],
            [
              -91.140057,
              33.296618
            ],
            [
              -91.141615,
              33.299539
            ],
            [
              -91.141475,
              33.314318
            ],
            [
              -91.143667,
              33.328398
            ],
            [
              -91.143353,
              33.33052
            ],
            [
              -91.141893,
              33.332963
            ],
            [
              -91.140968,
              33.336493
            ],
            [
              -91.142219,
              33.348989
            ],
            [
              -91.141793,
              33.350076
            ],
            [
              -91.140192,
              33.351452
            ],
            [
              -91.125108,
              33.360099
            ],
            [
              -91.120409,
              33.363809
            ],
            [
              -91.106758,
              33.381141
            ],
            [
              -91.101456,
              33.38719
            ],
            [
              -91.090852,
              33.395781
            ],
            [
              -91.075293,
              33.405966
            ],
            [
              -91.062281,
              33.421238
            ],
            [
              -91.058152,
              33.428705
            ],
            [
              -91.057621,
              33.445341
            ],
            [
              -91.0580133125203,
              33.4462148596964
            ],
            [
              -91.059828,
              33.450257
            ],
            [
              -91.064701,
              33.453775
            ],
            [
              -91.067623,
              33.455104
            ],
            [
              -91.074555,
              33.455811
            ],
            [
              -91.077814,
              33.455648
            ],
            [
              -91.081834,
              33.454188
            ],
            [
              -91.086498,
              33.451576
            ],
            [
              -91.091566,
              33.446319
            ],
            [
              -91.094279,
              33.442671
            ],
            [
              -91.096723,
              33.437603
            ],
            [
              -91.097531,
              33.433725
            ],
            [
              -91.097474,
              33.431733
            ],
            [
              -91.095335,
              33.425684
            ],
            [
              -91.095211,
              33.417488
            ],
            [
              -91.099277,
              33.408244
            ],
            [
              -91.10717,
              33.399078
            ],
            [
              -91.113764,
              33.393124
            ],
            [
              -91.123623,
              33.387526
            ],
            [
              -91.140938,
              33.380477
            ],
            [
              -91.154017,
              33.378914
            ],
            [
              -91.166304,
              33.379709
            ],
            [
              -91.171968,
              33.38103
            ],
            [
              -91.176942,
              33.382841
            ],
            [
              -91.191127,
              33.389634
            ],
            [
              -91.204758,
              33.398823
            ],
            [
              -91.208113,
              33.402007
            ],
            [
              -91.209032,
              33.403633
            ],
            [
              -91.20922,
              33.40629
            ],
            [
              -91.208702,
              33.408719
            ],
            [
              -91.205272,
              33.414231
            ],
            [
              -91.20258,
              33.416832
            ],
            [
              -91.199354,
              33.418321
            ],
            [
              -91.191973,
              33.417728
            ],
            [
              -91.184427,
              33.419576
            ],
            [
              -91.179368,
              33.417151
            ],
            [
              -91.17628,
              33.416979
            ],
            [
              -91.163387,
              33.422157
            ],
            [
              -91.147663,
              33.427172
            ],
            [
              -91.144877,
              33.427706
            ],
            [
              -91.13915,
              33.426955
            ],
            [
              -91.131885,
              33.430063
            ],
            [
              -91.130561,
              33.4319
            ],
            [
              -91.130552,
              33.433263
            ],
            [
              -91.128589,
              33.436284
            ],
            [
              -91.1211,
              33.443563
            ],
            [
              -91.118495,
              33.449116
            ],
            [
              -91.117975,
              33.453807
            ],
            [
              -91.119667,
              33.46023
            ],
            [
              -91.125109,
              33.472842
            ],
            [
              -91.136656,
              33.481323
            ],
            [
              -91.157319,
              33.492923
            ],
            [
              -91.160142,
              33.494829
            ],
            [
              -91.164019,
              33.497448
            ],
            [
              -91.167403,
              33.498304
            ],
            [
              -91.172213,
              33.496691
            ],
            [
              -91.175488,
              33.49077
            ],
            [
              -91.177148,
              33.48617
            ],
            [
              -91.176984,
              33.478899
            ],
            [
              -91.175635,
              33.471761
            ],
            [
              -91.171799,
              33.462342
            ],
            [
              -91.16936,
              33.452629
            ],
            [
              -91.177293,
              33.443638
            ],
            [
              -91.181787,
              33.44078
            ],
            [
              -91.186979,
              33.438592
            ],
            [
              -91.194658,
              33.436358
            ],
            [
              -91.206807,
              33.433846
            ],
            [
              -91.210275,
              33.433796
            ],
            [
              -91.217374,
              33.434699
            ],
            [
              -91.235181,
              33.438972
            ],
            [
              -91.235928,
              33.440611
            ],
            [
              -91.23431,
              33.4423
            ],
            [
              -91.233422,
              33.444038
            ],
            [
              -91.232587,
              33.453958
            ],
            [
              -91.231661,
              33.4571
            ],
            [
              -91.223338,
              33.462764
            ],
            [
              -91.220192,
              33.463045
            ],
            [
              -91.215508,
              33.46478
            ],
            [
              -91.208535,
              33.468606
            ],
            [
              -91.206753,
              33.470308
            ],
            [
              -91.205661,
              33.473553
            ],
            [
              -91.199593,
              33.483125
            ],
            [
              -91.195634,
              33.488468
            ],
            [
              -91.189375,
              33.493005
            ],
            [
              -91.185637,
              33.496399
            ],
            [
              -91.18307,
              33.498613
            ],
            [
              -91.182901,
              33.502379
            ],
            [
              -91.184612,
              33.507364
            ],
            [
              -91.187367,
              33.510552
            ],
            [
              -91.215671,
              33.529423
            ],
            [
              -91.219297,
              33.532364
            ],
            [
              -91.226325,
              33.5412
            ],
            [
              -91.229834,
              33.547047
            ],
            [
              -91.232295,
              33.552788
            ],
            [
              -91.232537,
              33.557454
            ],
            [
              -91.231418,
              33.560593
            ],
            [
              -91.2308579778816,
              33.56137194507
            ],
            [
              -91.244582,
              33.561425
            ],
            [
              -91.24576,
              33.517148
            ],
            [
              -91.324744,
              33.518589
            ],
            [
              -91.333164,
              33.533563
            ],
            [
              -91.346152,
              33.5336
            ],
            [
              -91.35081,
              33.519476
            ],
            [
              -91.34954,
              33.563646
            ],
            [
              -91.453438,
              33.563703
            ],
            [
              -91.45737,
              33.388974
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "009",
      "COUNTYNS": "00069897",
      "AFFGEOID": "0500000US05009",
      "GEOID": "05009",
      "NAME": "Boone",
      "LSAD": "06",
      "ALAND": 1528076369,
      "AWATER": 31092507,
      "County_state": "Boone,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.299621,
              36.178704
            ],
            [
              -93.30082,
              36.12155
            ],
            [
              -93.265157,
              36.120315
            ],
            [
              -93.14032,
              36.11755
            ],
            [
              -93.08562,
              36.119352
            ],
            [
              -93.049421,
              36.116352
            ],
            [
              -92.95345,
              36.115662
            ],
            [
              -92.944666,
              36.115123
            ],
            [
              -92.890695,
              36.114132
            ],
            [
              -92.889404,
              36.18639
            ],
            [
              -92.889628,
              36.318457
            ],
            [
              -92.888215,
              36.333197
            ],
            [
              -92.884445,
              36.490713
            ],
            [
              -92.873666,
              36.475668
            ],
            [
              -92.854693,
              36.464619
            ],
            [
              -92.85404946684739,
              36.4979826282189
            ],
            [
              -92.894001,
              36.49785
            ],
            [
              -92.894336,
              36.497867
            ],
            [
              -93.068455,
              36.49825
            ],
            [
              -93.069512,
              36.498242
            ],
            [
              -93.087635,
              36.498239
            ],
            [
              -93.088988,
              36.498184
            ],
            [
              -93.125969,
              36.497851
            ],
            [
              -93.1511512645162,
              36.497925070177295
            ],
            [
              -93.2908089270187,
              36.4983358540374
            ],
            [
              -93.2934668009526,
              36.498343671809
            ],
            [
              -93.298642,
              36.496964
            ],
            [
              -93.296337,
              36.417296
            ],
            [
              -93.29755,
              36.29656
            ],
            [
              -93.299171,
              36.232412
            ],
            [
              -93.299621,
              36.178704
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "033",
      "COUNTYNS": "00069161",
      "AFFGEOID": "0500000US05033",
      "GEOID": "05033",
      "NAME": "Crawford",
      "LSAD": "06",
      "ALAND": 1531188224,
      "AWATER": 31076498,
      "County_state": "Crawford,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.4929318527681,
              35.7591658417281
            ],
            [
              -94.48821,
              35.72924
            ],
            [
              -94.487585,
              35.726147
            ],
            [
              -94.472647,
              35.638556
            ],
            [
              -94.465272,
              35.594037
            ],
            [
              -94.464457,
              35.588909
            ],
            [
              -94.464097,
              35.587265
            ],
            [
              -94.463318,
              35.58266
            ],
            [
              -94.4566165247859,
              35.5403804902825
            ],
            [
              -94.449696,
              35.496719
            ],
            [
              -94.4496195316777,
              35.496295182361095
            ],
            [
              -94.4373214645191,
              35.4281344377591
            ],
            [
              -94.413565,
              35.446811
            ],
            [
              -94.38729,
              35.448197
            ],
            [
              -94.358105,
              35.429281
            ],
            [
              -94.339049,
              35.408682
            ],
            [
              -94.347864,
              35.381109
            ],
            [
              -94.342481,
              35.371833
            ],
            [
              -94.30507,
              35.353191
            ],
            [
              -94.280212,
              35.359965
            ],
            [
              -94.260397,
              35.35389
            ],
            [
              -94.24234,
              35.355237
            ],
            [
              -94.233765,
              35.354072
            ],
            [
              -94.204866,
              35.36017
            ],
            [
              -94.189756,
              35.388017
            ],
            [
              -94.167371,
              35.378152
            ],
            [
              -94.13906,
              35.384608
            ],
            [
              -94.135113,
              35.399246
            ],
            [
              -94.161425,
              35.435341
            ],
            [
              -94.157162,
              35.441704
            ],
            [
              -94.142437,
              35.45024
            ],
            [
              -94.086617,
              35.442486
            ],
            [
              -94.074895,
              35.445403
            ],
            [
              -94.026969,
              35.460785
            ],
            [
              -94.054104,
              35.477692
            ],
            [
              -94.040717,
              35.482127
            ],
            [
              -94.039416,
              35.485704
            ],
            [
              -94.047576,
              35.489737
            ],
            [
              -94.050521,
              35.489284
            ],
            [
              -94.05065,
              35.491745
            ],
            [
              -94.04904,
              35.493606
            ],
            [
              -94.040656,
              35.49918
            ],
            [
              -94.037539,
              35.49902
            ],
            [
              -94.033197,
              35.501302
            ],
            [
              -94.040462,
              35.5438
            ],
            [
              -94.023493,
              35.556683
            ],
            [
              -94.023014,
              35.574204
            ],
            [
              -94.076209,
              35.575489
            ],
            [
              -94.074598,
              35.633396
            ],
            [
              -94.073703,
              35.662463
            ],
            [
              -93.984204,
              35.660783
            ],
            [
              -93.982723,
              35.704022
            ],
            [
              -93.964805,
              35.703397
            ],
            [
              -93.96415,
              35.732091
            ],
            [
              -93.911509,
              35.730754
            ],
            [
              -93.911158,
              35.7454
            ],
            [
              -93.892678,
              35.745047
            ],
            [
              -93.892246,
              35.75941
            ],
            [
              -93.910836,
              35.759752
            ],
            [
              -93.962892,
              35.76093
            ],
            [
              -94.106411,
              35.764255
            ],
            [
              -94.124258,
              35.764636
            ],
            [
              -94.124871,
              35.750426
            ],
            [
              -94.212507,
              35.75292
            ],
            [
              -94.280993,
              35.754707
            ],
            [
              -94.283496,
              35.754793
            ],
            [
              -94.387388,
              35.757254
            ],
            [
              -94.4929318527681,
              35.7591658417281
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "087",
      "COUNTYNS": "00069165",
      "AFFGEOID": "0500000US05087",
      "GEOID": "05087",
      "NAME": "Madison",
      "LSAD": "06",
      "ALAND": 2160705134,
      "AWATER": 7320899,
      "County_state": "Madison,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.960619,
              35.833283
            ],
            [
              -93.962892,
              35.76093
            ],
            [
              -93.910836,
              35.759752
            ],
            [
              -93.910588,
              35.774246
            ],
            [
              -93.695981,
              35.768649
            ],
            [
              -93.519204,
              35.763521
            ],
            [
              -93.517092,
              35.823333
            ],
            [
              -93.526087,
              35.823611
            ],
            [
              -93.523639,
              35.881592
            ],
            [
              -93.50601,
              35.88108
            ],
            [
              -93.504925,
              35.925188
            ],
            [
              -93.486864,
              35.924235
            ],
            [
              -93.486138,
              35.953299
            ],
            [
              -93.46839,
              35.953088
            ],
            [
              -93.468003,
              35.967545
            ],
            [
              -93.450715,
              35.967493
            ],
            [
              -93.448183,
              36.053506
            ],
            [
              -93.445619,
              36.080749
            ],
            [
              -93.48022,
              36.081425
            ],
            [
              -93.479528,
              36.125072
            ],
            [
              -93.551135,
              36.126271
            ],
            [
              -93.550724,
              36.155033
            ],
            [
              -93.585476,
              36.1557
            ],
            [
              -93.585258,
              36.166338
            ],
            [
              -93.584866,
              36.185741
            ],
            [
              -93.611713,
              36.186571
            ],
            [
              -93.619974,
              36.201208
            ],
            [
              -93.618184,
              36.258871
            ],
            [
              -93.641609,
              36.268231
            ],
            [
              -93.631062,
              36.277672
            ],
            [
              -93.639833,
              36.283253
            ],
            [
              -93.663023,
              36.275009
            ],
            [
              -93.657689,
              36.303082
            ],
            [
              -93.815605,
              36.306563
            ],
            [
              -93.869066,
              36.30745
            ],
            [
              -93.869632,
              36.234826
            ],
            [
              -93.887775,
              36.235046
            ],
            [
              -93.944177,
              36.091103
            ],
            [
              -93.94574,
              36.033853
            ],
            [
              -93.947259,
              35.978132
            ],
            [
              -93.949917,
              35.890992
            ],
            [
              -93.951846,
              35.833259
            ],
            [
              -93.960619,
              35.833283
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "047",
      "COUNTYNS": "00066853",
      "AFFGEOID": "0500000US05047",
      "GEOID": "05047",
      "NAME": "Franklin",
      "LSAD": "06",
      "ALAND": 1576930289,
      "AWATER": 27936032,
      "County_state": "Franklin,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.07983,
              35.2869
            ],
            [
              -94.082798,
              35.215913
            ],
            [
              -94.064247,
              35.214662
            ],
            [
              -94.064873,
              35.199848
            ],
            [
              -94.048095,
              35.199481
            ],
            [
              -94.046446,
              35.214391
            ],
            [
              -94.028547,
              35.213826
            ],
            [
              -94.028296,
              35.231151
            ],
            [
              -93.97358,
              35.230148
            ],
            [
              -93.920167,
              35.229427
            ],
            [
              -93.917127,
              35.319046
            ],
            [
              -93.810087,
              35.313095
            ],
            [
              -93.808251,
              35.385394
            ],
            [
              -93.806849,
              35.430011
            ],
            [
              -93.79456,
              35.402859
            ],
            [
              -93.757763,
              35.377118
            ],
            [
              -93.748802,
              35.364494
            ],
            [
              -93.710076,
              35.375242
            ],
            [
              -93.700024,
              35.644545
            ],
            [
              -93.699277,
              35.673337
            ],
            [
              -93.695981,
              35.768649
            ],
            [
              -93.910588,
              35.774246
            ],
            [
              -93.910836,
              35.759752
            ],
            [
              -93.892246,
              35.75941
            ],
            [
              -93.892678,
              35.745047
            ],
            [
              -93.911158,
              35.7454
            ],
            [
              -93.911509,
              35.730754
            ],
            [
              -93.96415,
              35.732091
            ],
            [
              -93.964805,
              35.703397
            ],
            [
              -93.982723,
              35.704022
            ],
            [
              -93.984204,
              35.660783
            ],
            [
              -94.073703,
              35.662463
            ],
            [
              -94.074598,
              35.633396
            ],
            [
              -94.076209,
              35.575489
            ],
            [
              -94.023014,
              35.574204
            ],
            [
              -94.023493,
              35.556683
            ],
            [
              -94.040462,
              35.5438
            ],
            [
              -94.033197,
              35.501302
            ],
            [
              -94.037539,
              35.49902
            ],
            [
              -94.040656,
              35.49918
            ],
            [
              -94.04904,
              35.493606
            ],
            [
              -94.05065,
              35.491745
            ],
            [
              -94.050521,
              35.489284
            ],
            [
              -94.047576,
              35.489737
            ],
            [
              -94.039416,
              35.485704
            ],
            [
              -94.040717,
              35.482127
            ],
            [
              -94.054104,
              35.477692
            ],
            [
              -94.026969,
              35.460785
            ],
            [
              -94.074895,
              35.445403
            ],
            [
              -94.075761,
              35.425354
            ],
            [
              -94.07693,
              35.41251
            ],
            [
              -94.07983,
              35.2869
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "059",
      "COUNTYNS": "00066859",
      "AFFGEOID": "0500000US05059",
      "GEOID": "05059",
      "NAME": "Hot Spring",
      "LSAD": "06",
      "ALAND": 1593376791,
      "AWATER": 18038347,
      "County_state": "Hot Spring,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.407227,
              34.396853
            ],
            [
              -93.408124,
              34.339527
            ],
            [
              -93.355795,
              34.338698
            ],
            [
              -93.35684,
              34.280306
            ],
            [
              -93.351132,
              34.280166
            ],
            [
              -93.251018,
              34.278407
            ],
            [
              -93.198278,
              34.277337
            ],
            [
              -93.199428,
              34.248064
            ],
            [
              -93.090042,
              34.245703
            ],
            [
              -93.084386,
              34.235587
            ],
            [
              -93.034441,
              34.236291
            ],
            [
              -93.038509,
              34.221431
            ],
            [
              -93.030867,
              34.213422
            ],
            [
              -93.025908,
              34.201885
            ],
            [
              -93.032605,
              34.196714
            ],
            [
              -93.043101,
              34.180267
            ],
            [
              -93.038949,
              34.155437
            ],
            [
              -93.029996,
              34.160765
            ],
            [
              -92.886285,
              34.155877
            ],
            [
              -92.813986,
              34.15166
            ],
            [
              -92.678383,
              34.149622
            ],
            [
              -92.673382,
              34.239347
            ],
            [
              -92.668711,
              34.414237
            ],
            [
              -92.686155,
              34.414695
            ],
            [
              -92.685284,
              34.473105
            ],
            [
              -92.738905,
              34.473885
            ],
            [
              -92.738261,
              34.503283
            ],
            [
              -92.775581,
              34.503736
            ],
            [
              -92.793197,
              34.503606
            ],
            [
              -92.879894,
              34.505431
            ],
            [
              -92.915079,
              34.506114
            ],
            [
              -92.913462,
              34.448361
            ],
            [
              -92.947882,
              34.444775
            ],
            [
              -92.948071,
              34.389255
            ],
            [
              -93.115251,
              34.391558
            ],
            [
              -93.229653,
              34.394415
            ],
            [
              -93.24741,
              34.39497
            ],
            [
              -93.387728,
              34.397108
            ],
            [
              -93.407227,
              34.396853
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "135",
      "COUNTYNS": "00069183",
      "AFFGEOID": "0500000US05135",
      "GEOID": "05135",
      "NAME": "Sharp",
      "LSAD": "06",
      "ALAND": 1565460785,
      "AWATER": 5201389,
      "County_state": "Sharp,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.709363,
              36.000917
            ],
            [
              -91.710552,
              35.941759
            ],
            [
              -91.633054,
              35.939359
            ],
            [
              -91.463198,
              35.93681
            ],
            [
              -91.464678,
              35.892497
            ],
            [
              -91.35723,
              35.890621
            ],
            [
              -91.354342,
              35.979615
            ],
            [
              -91.351933,
              36.157969
            ],
            [
              -91.349834,
              36.230967
            ],
            [
              -91.332233,
              36.230766
            ],
            [
              -91.331533,
              36.244867
            ],
            [
              -91.259326,
              36.243467
            ],
            [
              -91.25892,
              36.25788
            ],
            [
              -91.258581,
              36.330865
            ],
            [
              -91.276059,
              36.330872
            ],
            [
              -91.275282,
              36.374531
            ],
            [
              -91.292515,
              36.374746
            ],
            [
              -91.291633,
              36.389164
            ],
            [
              -91.310131,
              36.389744
            ],
            [
              -91.308733,
              36.420363
            ],
            [
              -91.349135,
              36.420863
            ],
            [
              -91.357616,
              36.464957
            ],
            [
              -91.398636,
              36.479762
            ],
            [
              -91.407137,
              36.497112
            ],
            [
              -91.407261,
              36.497123
            ],
            [
              -91.433298,
              36.497262
            ],
            [
              -91.436502,
              36.497377
            ],
            [
              -91.446284,
              36.497469
            ],
            [
              -91.4500043450567,
              36.4975617737561
            ],
            [
              -91.452848,
              36.418585
            ],
            [
              -91.454437,
              36.335465
            ],
            [
              -91.512441,
              36.336831
            ],
            [
              -91.565866,
              36.3374
            ],
            [
              -91.565866,
              36.257435
            ],
            [
              -91.566078,
              36.249945
            ],
            [
              -91.691435,
              36.253301
            ],
            [
              -91.692243,
              36.209167
            ],
            [
              -91.674143,
              36.208567
            ],
            [
              -91.679943,
              36.164668
            ],
            [
              -91.681542,
              36.02287
            ],
            [
              -91.691142,
              36.019083
            ],
            [
              -91.682277,
              36.000535
            ],
            [
              -91.709363,
              36.000917
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "073",
      "COUNTYNS": "00066864",
      "AFFGEOID": "0500000US05073",
      "GEOID": "05073",
      "NAME": "Lafayette",
      "LSAD": "06",
      "ALAND": 1368278178,
      "AWATER": 43469442,
      "County_state": "Lafayette,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.854198,
              33.11571
            ],
            [
              -93.860094,
              33.091308
            ],
            [
              -93.827753,
              33.058201
            ],
            [
              -93.818683,
              33.03358
            ],
            [
              -93.804112,
              33.02349
            ],
            [
              -93.804916416422,
              33.0193809735934
            ],
            [
              -93.723273,
              33.019457
            ],
            [
              -93.531499,
              33.018643
            ],
            [
              -93.524916,
              33.018637
            ],
            [
              -93.52099399934829,
              33.01861612243
            ],
            [
              -93.520971,
              33.018616
            ],
            [
              -93.490893,
              33.018442
            ],
            [
              -93.4905199755506,
              33.0184422689434
            ],
            [
              -93.48176,
              33.259991
            ],
            [
              -93.442019,
              33.258165
            ],
            [
              -93.432744,
              33.276578
            ],
            [
              -93.436226,
              33.289477
            ],
            [
              -93.423275,
              33.301502
            ],
            [
              -93.425866,
              33.308939
            ],
            [
              -93.428728,
              33.3162
            ],
            [
              -93.420812,
              33.328752
            ],
            [
              -93.376713,
              33.43256
            ],
            [
              -93.365585,
              33.444459
            ],
            [
              -93.483974,
              33.447223
            ],
            [
              -93.483097,
              33.476075
            ],
            [
              -93.635792,
              33.47986
            ],
            [
              -93.722867,
              33.481963
            ],
            [
              -93.722534,
              33.472764
            ],
            [
              -93.734921,
              33.465192
            ],
            [
              -93.738589,
              33.454804
            ],
            [
              -93.735002,
              33.450394
            ],
            [
              -93.729324,
              33.442351
            ],
            [
              -93.740371,
              33.436538
            ],
            [
              -93.745939,
              33.430599
            ],
            [
              -93.740679,
              33.426824
            ],
            [
              -93.730245,
              33.429383
            ],
            [
              -93.72252,
              33.436579
            ],
            [
              -93.711941,
              33.442536
            ],
            [
              -93.708044,
              33.432886
            ],
            [
              -93.720561,
              33.426828
            ],
            [
              -93.728279,
              33.42572
            ],
            [
              -93.729987,
              33.416221
            ],
            [
              -93.724068,
              33.412007
            ],
            [
              -93.711973,
              33.398272
            ],
            [
              -93.699051,
              33.388069
            ],
            [
              -93.676975,
              33.384814
            ],
            [
              -93.671,
              33.381166
            ],
            [
              -93.677418,
              33.373442
            ],
            [
              -93.696215,
              33.373193
            ],
            [
              -93.70269,
              33.368168
            ],
            [
              -93.702507,
              33.361511
            ],
            [
              -93.707984,
              33.353553
            ],
            [
              -93.695712,
              33.34077
            ],
            [
              -93.685562,
              33.341017
            ],
            [
              -93.668363,
              33.334452
            ],
            [
              -93.686212,
              33.319212
            ],
            [
              -93.695672,
              33.316068
            ],
            [
              -93.689074,
              33.302177
            ],
            [
              -93.682734,
              33.290548
            ],
            [
              -93.688612,
              33.277603
            ],
            [
              -93.705204,
              33.27569
            ],
            [
              -93.711611,
              33.281415
            ],
            [
              -93.724017,
              33.28622
            ],
            [
              -93.729337,
              33.279436
            ],
            [
              -93.712152,
              33.262003
            ],
            [
              -93.703373,
              33.245432
            ],
            [
              -93.713386,
              33.22499
            ],
            [
              -93.705815,
              33.223384
            ],
            [
              -93.712739,
              33.220384
            ],
            [
              -93.723385,
              33.2172
            ],
            [
              -93.73645,
              33.209899
            ],
            [
              -93.744648,
              33.199062
            ],
            [
              -93.740241,
              33.192476
            ],
            [
              -93.724846,
              33.178171
            ],
            [
              -93.735355,
              33.173927
            ],
            [
              -93.743539,
              33.17801
            ],
            [
              -93.74717,
              33.181507
            ],
            [
              -93.761081,
              33.170981
            ],
            [
              -93.741389,
              33.166983
            ],
            [
              -93.73215,
              33.162617
            ],
            [
              -93.733962,
              33.155737
            ],
            [
              -93.736327,
              33.15491
            ],
            [
              -93.750177,
              33.158741
            ],
            [
              -93.756711,
              33.1642
            ],
            [
              -93.769007,
              33.166998
            ],
            [
              -93.777259,
              33.158135
            ],
            [
              -93.775585,
              33.149782
            ],
            [
              -93.786069,
              33.144345
            ],
            [
              -93.786497,
              33.153353
            ],
            [
              -93.797196,
              33.165255
            ],
            [
              -93.805977,
              33.162474
            ],
            [
              -93.820165,
              33.156006
            ],
            [
              -93.819675,
              33.144528
            ],
            [
              -93.817304,
              33.139828
            ],
            [
              -93.829779,
              33.139218
            ],
            [
              -93.836669,
              33.147016
            ],
            [
              -93.853623,
              33.13537
            ],
            [
              -93.854198,
              33.11571
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "005",
      "COUNTYNS": "00069895",
      "AFFGEOID": "0500000US05005",
      "GEOID": "05005",
      "NAME": "Baxter",
      "LSAD": "06",
      "ALAND": 1435438032,
      "AWATER": 84171993,
      "County_state": "Baxter,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.592268,
              36.358897
            ],
            [
              -92.578057,
              36.342185
            ],
            [
              -92.533642,
              36.350734
            ],
            [
              -92.528057,
              36.344143
            ],
            [
              -92.534048,
              36.329465
            ],
            [
              -92.552962,
              36.336228
            ],
            [
              -92.570707,
              36.322664
            ],
            [
              -92.573696,
              36.306283
            ],
            [
              -92.551566,
              36.313488
            ],
            [
              -92.536576,
              36.308997
            ],
            [
              -92.523404,
              36.298544
            ],
            [
              -92.543577,
              36.263623
            ],
            [
              -92.520581,
              36.268824
            ],
            [
              -92.498976,
              36.258214
            ],
            [
              -92.474487,
              36.256876
            ],
            [
              -92.476487,
              36.227916
            ],
            [
              -92.459386,
              36.214952
            ],
            [
              -92.479112,
              36.193522
            ],
            [
              -92.463731,
              36.179252
            ],
            [
              -92.441124,
              36.164048
            ],
            [
              -92.425948,
              36.17833
            ],
            [
              -92.428384,
              36.163812
            ],
            [
              -92.408559,
              36.152397
            ],
            [
              -92.41664,
              36.14942
            ],
            [
              -92.426399,
              36.149401
            ],
            [
              -92.409175,
              36.134684
            ],
            [
              -92.411502,
              36.062756
            ],
            [
              -92.414324,
              35.975865
            ],
            [
              -92.30722,
              35.974022
            ],
            [
              -92.304951,
              36.06108
            ],
            [
              -92.197649,
              36.059287
            ],
            [
              -92.19543,
              36.132798
            ],
            [
              -92.215203,
              36.133452
            ],
            [
              -92.2139,
              36.1751
            ],
            [
              -92.159885,
              36.173782
            ],
            [
              -92.15699,
              36.261248
            ],
            [
              -92.159197,
              36.263479
            ],
            [
              -92.158422,
              36.287496
            ],
            [
              -92.15524,
              36.348942
            ],
            [
              -92.154301,
              36.353883
            ],
            [
              -92.151121,
              36.478272
            ],
            [
              -92.1502951365485,
              36.4986337152141
            ],
            [
              -92.199396,
              36.498351
            ],
            [
              -92.211449,
              36.498395
            ],
            [
              -92.214143,
              36.498372
            ],
            [
              -92.216412,
              36.498417
            ],
            [
              -92.309424,
              36.497894
            ],
            [
              -92.318415,
              36.497711
            ],
            [
              -92.350277,
              36.497787
            ],
            [
              -92.375159,
              36.497199
            ],
            [
              -92.384927,
              36.497845
            ],
            [
              -92.420383,
              36.497914
            ],
            [
              -92.444129,
              36.498526
            ],
            [
              -92.516836,
              36.498738
            ],
            [
              -92.5291281035939,
              36.498608860563095
            ],
            [
              -92.533175,
              36.386563
            ],
            [
              -92.549599,
              36.386921
            ],
            [
              -92.552585,
              36.379432
            ],
            [
              -92.592268,
              36.358897
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "013",
      "COUNTYNS": "00063758",
      "AFFGEOID": "0500000US05013",
      "GEOID": "05013",
      "NAME": "Calhoun",
      "LSAD": "06",
      "ALAND": 1627975434,
      "AWATER": 9926146,
      "County_state": "Calhoun,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.775871,
              33.544247
            ],
            [
              -92.776729,
              33.537648
            ],
            [
              -92.759035,
              33.530954
            ],
            [
              -92.773429,
              33.51006
            ],
            [
              -92.755382,
              33.500802
            ],
            [
              -92.765521,
              33.492376
            ],
            [
              -92.752895,
              33.484232
            ],
            [
              -92.737525,
              33.485966
            ],
            [
              -92.73625,
              33.494923
            ],
            [
              -92.716346,
              33.481585
            ],
            [
              -92.72464,
              33.460161
            ],
            [
              -92.716691,
              33.451868
            ],
            [
              -92.702715,
              33.453517
            ],
            [
              -92.705093,
              33.431215
            ],
            [
              -92.675233,
              33.430538
            ],
            [
              -92.653211,
              33.433968
            ],
            [
              -92.654987,
              33.422933
            ],
            [
              -92.636498,
              33.415163
            ],
            [
              -92.641248,
              33.396263
            ],
            [
              -92.619872,
              33.409153
            ],
            [
              -92.585529,
              33.397992
            ],
            [
              -92.579292,
              33.372529
            ],
            [
              -92.567534,
              33.378269
            ],
            [
              -92.567156,
              33.3672
            ],
            [
              -92.547285,
              33.366058
            ],
            [
              -92.532989,
              33.356
            ],
            [
              -92.523728,
              33.316608
            ],
            [
              -92.50422,
              33.326913
            ],
            [
              -92.488597,
              33.315605
            ],
            [
              -92.481174,
              33.327637
            ],
            [
              -92.459274,
              33.303141
            ],
            [
              -92.473336,
              33.294036
            ],
            [
              -92.459962,
              33.283154
            ],
            [
              -92.453522,
              33.287689
            ],
            [
              -92.445399,
              33.274859
            ],
            [
              -92.431092,
              33.26979
            ],
            [
              -92.380601,
              33.278679
            ],
            [
              -92.350432,
              33.298941
            ],
            [
              -92.378616,
              33.314733
            ],
            [
              -92.381768,
              33.342673
            ],
            [
              -92.374467,
              33.37865
            ],
            [
              -92.359995,
              33.379801
            ],
            [
              -92.355442,
              33.401692
            ],
            [
              -92.341517,
              33.414418
            ],
            [
              -92.340623,
              33.428328
            ],
            [
              -92.328031,
              33.443564
            ],
            [
              -92.32016,
              33.469061
            ],
            [
              -92.322148,
              33.486403
            ],
            [
              -92.311603,
              33.501975
            ],
            [
              -92.322913,
              33.524226
            ],
            [
              -92.314172,
              33.551496
            ],
            [
              -92.324269,
              33.572322
            ],
            [
              -92.320995,
              33.584939
            ],
            [
              -92.32872,
              33.616976
            ],
            [
              -92.321136,
              33.630213
            ],
            [
              -92.326606,
              33.664597
            ],
            [
              -92.320101,
              33.682331
            ],
            [
              -92.330839,
              33.707806
            ],
            [
              -92.341845,
              33.742896
            ],
            [
              -92.327042,
              33.777809
            ],
            [
              -92.334249,
              33.795065
            ],
            [
              -92.583054,
              33.802198
            ],
            [
              -92.58503,
              33.736514
            ],
            [
              -92.589523,
              33.72855
            ],
            [
              -92.599499,
              33.718341
            ],
            [
              -92.599637,
              33.711092
            ],
            [
              -92.635132,
              33.693057
            ],
            [
              -92.65273,
              33.661333
            ],
            [
              -92.723615,
              33.651861
            ],
            [
              -92.732873,
              33.616046
            ],
            [
              -92.71001,
              33.591021
            ],
            [
              -92.717454,
              33.552444
            ],
            [
              -92.726935,
              33.563724
            ],
            [
              -92.746363,
              33.54527
            ],
            [
              -92.764581,
              33.553245
            ],
            [
              -92.775871,
              33.544247
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "121",
      "COUNTYNS": "00069178",
      "AFFGEOID": "0500000US05121",
      "GEOID": "05121",
      "NAME": "Randolph",
      "LSAD": "06",
      "ALAND": 1689288878,
      "AWATER": 9852495,
      "County_state": "Randolph,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.405141,
              36.497165
            ],
            [
              -91.407137,
              36.497112
            ],
            [
              -91.398636,
              36.479762
            ],
            [
              -91.357616,
              36.464957
            ],
            [
              -91.349135,
              36.420863
            ],
            [
              -91.308733,
              36.420363
            ],
            [
              -91.310131,
              36.389744
            ],
            [
              -91.291633,
              36.389164
            ],
            [
              -91.292515,
              36.374746
            ],
            [
              -91.275282,
              36.374531
            ],
            [
              -91.276059,
              36.330872
            ],
            [
              -91.258581,
              36.330865
            ],
            [
              -91.25892,
              36.25788
            ],
            [
              -91.240729,
              36.257667
            ],
            [
              -91.24203,
              36.225667
            ],
            [
              -91.232559,
              36.2408
            ],
            [
              -91.227312,
              36.225122
            ],
            [
              -91.204046,
              36.218576
            ],
            [
              -91.183388,
              36.206045
            ],
            [
              -91.16308,
              36.205917
            ],
            [
              -91.157325,
              36.191001
            ],
            [
              -91.143934,
              36.18009
            ],
            [
              -91.141604,
              36.16641
            ],
            [
              -91.119625,
              36.153671
            ],
            [
              -91.082681,
              36.150871
            ],
            [
              -91.086224,
              36.132669
            ],
            [
              -91.072224,
              36.125369
            ],
            [
              -91.07302,
              36.114802
            ],
            [
              -91.063593,
              36.119022
            ],
            [
              -91.064724,
              36.143469
            ],
            [
              -91.052023,
              36.152868
            ],
            [
              -91.045223,
              36.151368
            ],
            [
              -90.95646,
              36.15049
            ],
            [
              -90.882375,
              36.150475
            ],
            [
              -90.809816,
              36.149567
            ],
            [
              -90.806615,
              36.266865
            ],
            [
              -90.806214,
              36.28817
            ],
            [
              -90.791614,
              36.293321
            ],
            [
              -90.781741,
              36.28417
            ],
            [
              -90.765126,
              36.28706
            ],
            [
              -90.749667,
              36.280536
            ],
            [
              -90.75181,
              36.283836
            ],
            [
              -90.748637,
              36.412764
            ],
            [
              -90.770601,
              36.41304
            ],
            [
              -90.757268,
              36.421789
            ],
            [
              -90.779699,
              36.437578
            ],
            [
              -90.774718,
              36.44732
            ],
            [
              -90.769612,
              36.48527
            ],
            [
              -90.7843982380622,
              36.4985237150037
            ],
            [
              -90.850434,
              36.498548
            ],
            [
              -90.873775,
              36.498074
            ],
            [
              -90.876567,
              36.498313
            ],
            [
              -90.876867,
              36.498423
            ],
            [
              -90.87922,
              36.498378
            ],
            [
              -90.960648,
              36.498426
            ],
            [
              -90.963063,
              36.498418
            ],
            [
              -90.98125406602479,
              36.498358822556895
            ],
            [
              -91.008558,
              36.49827
            ],
            [
              -91.017974,
              36.498062
            ],
            [
              -91.09588,
              36.49787
            ],
            [
              -91.096277,
              36.497893
            ],
            [
              -91.126529,
              36.497712
            ],
            [
              -91.21736,
              36.497511
            ],
            [
              -91.218645,
              36.497564
            ],
            [
              -91.227398,
              36.497617
            ],
            [
              -91.404915,
              36.49712
            ],
            [
              -91.405141,
              36.497165
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "145",
      "COUNTYNS": "00069906",
      "AFFGEOID": "0500000US05145",
      "GEOID": "05145",
      "NAME": "White",
      "LSAD": "06",
      "ALAND": 2680852086,
      "AWATER": 18293044,
      "County_state": "White,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.116592,
              35.156595
            ],
            [
              -92.119719,
              35.066493
            ],
            [
              -92.088939,
              35.064766
            ],
            [
              -92.074634,
              35.075085
            ],
            [
              -92.050051,
              35.06706
            ],
            [
              -92.012735,
              35.076251
            ],
            [
              -92.001546,
              35.06229
            ],
            [
              -91.980626,
              35.058089
            ],
            [
              -91.982298,
              35.055805
            ],
            [
              -91.950873,
              35.045278
            ],
            [
              -91.928015,
              35.046803
            ],
            [
              -91.901281,
              35.028906
            ],
            [
              -91.89575,
              35.032614
            ],
            [
              -91.87888,
              35.024577
            ],
            [
              -91.802505,
              35.030425
            ],
            [
              -91.766029,
              35.050021
            ],
            [
              -91.744846,
              35.047037
            ],
            [
              -91.701413,
              35.063014
            ],
            [
              -91.679371,
              35.05055
            ],
            [
              -91.664709,
              35.053982
            ],
            [
              -91.644149,
              35.042887
            ],
            [
              -91.640643,
              35.027459
            ],
            [
              -91.608426,
              35.020629
            ],
            [
              -91.5851,
              35.025234
            ],
            [
              -91.584005,
              35.091584
            ],
            [
              -91.465114,
              35.089454
            ],
            [
              -91.450422,
              35.102305
            ],
            [
              -91.424784,
              35.103787
            ],
            [
              -91.444998,
              35.107925
            ],
            [
              -91.438982,
              35.11783
            ],
            [
              -91.452037,
              35.137592
            ],
            [
              -91.428866,
              35.136473
            ],
            [
              -91.437539,
              35.154442
            ],
            [
              -91.459614,
              35.155133
            ],
            [
              -91.45993,
              35.166564
            ],
            [
              -91.434237,
              35.16717
            ],
            [
              -91.421843,
              35.179248
            ],
            [
              -91.426612,
              35.185598
            ],
            [
              -91.427813,
              35.193684
            ],
            [
              -91.420324,
              35.192324
            ],
            [
              -91.415944,
              35.192739
            ],
            [
              -91.413105,
              35.194312
            ],
            [
              -91.411282,
              35.197288
            ],
            [
              -91.409747,
              35.20846
            ],
            [
              -91.407782,
              35.212655
            ],
            [
              -91.396325,
              35.210223
            ],
            [
              -91.395956,
              35.197736
            ],
            [
              -91.394696,
              35.194002
            ],
            [
              -91.391499,
              35.191115
            ],
            [
              -91.386011,
              35.188206
            ],
            [
              -91.382557,
              35.187555
            ],
            [
              -91.379327,
              35.189235
            ],
            [
              -91.378004,
              35.191537
            ],
            [
              -91.377257,
              35.196102
            ],
            [
              -91.377519,
              35.196761
            ],
            [
              -91.37964,
              35.1981
            ],
            [
              -91.385462,
              35.197828
            ],
            [
              -91.388499,
              35.198772
            ],
            [
              -91.390449,
              35.201338
            ],
            [
              -91.390145,
              35.203697
            ],
            [
              -91.381723,
              35.211615
            ],
            [
              -91.379719,
              35.215149
            ],
            [
              -91.381406,
              35.216998
            ],
            [
              -91.383027,
              35.217437
            ],
            [
              -91.389096,
              35.217284
            ],
            [
              -91.393296,
              35.218743
            ],
            [
              -91.394711,
              35.220757
            ],
            [
              -91.394593,
              35.222323
            ],
            [
              -91.393348,
              35.22325
            ],
            [
              -91.390819,
              35.223604
            ],
            [
              -91.388301,
              35.222688
            ],
            [
              -91.382588,
              35.21999
            ],
            [
              -91.381042,
              35.220067
            ],
            [
              -91.378354,
              35.222162
            ],
            [
              -91.37814,
              35.22385
            ],
            [
              -91.379099,
              35.2254
            ],
            [
              -91.380992,
              35.226458
            ],
            [
              -91.385135,
              35.228128
            ],
            [
              -91.388427,
              35.231482
            ],
            [
              -91.389088,
              35.233462
            ],
            [
              -91.38872,
              35.237007
            ],
            [
              -91.387656,
              35.23892
            ],
            [
              -91.386067,
              35.244395
            ],
            [
              -91.3851,
              35.246226
            ],
            [
              -91.382527,
              35.248901
            ],
            [
              -91.378255,
              35.250973
            ],
            [
              -91.374441,
              35.253432
            ],
            [
              -91.372599,
              35.2556
            ],
            [
              -91.371112,
              35.259892
            ],
            [
              -91.371035,
              35.262981
            ],
            [
              -91.371564,
              35.264767
            ],
            [
              -91.372789,
              35.266531
            ],
            [
              -91.376138,
              35.267775
            ],
            [
              -91.377908,
              35.26738
            ],
            [
              -91.379647,
              35.265247
            ],
            [
              -91.379771,
              35.263318
            ],
            [
              -91.379227,
              35.259775
            ],
            [
              -91.379175,
              35.257175
            ],
            [
              -91.37975,
              35.255251
            ],
            [
              -91.382796,
              35.25257
            ],
            [
              -91.385304,
              35.251548
            ],
            [
              -91.387948,
              35.251275
            ],
            [
              -91.401135,
              35.264665
            ],
            [
              -91.411866,
              35.264885
            ],
            [
              -91.411549,
              35.277515
            ],
            [
              -91.412626,
              35.303054
            ],
            [
              -91.437434,
              35.296118
            ],
            [
              -91.424955,
              35.329889
            ],
            [
              -91.430044,
              35.337691
            ],
            [
              -91.444814,
              35.346282
            ],
            [
              -91.425606,
              35.353008
            ],
            [
              -91.438202,
              35.369378
            ],
            [
              -91.430884,
              35.371566
            ],
            [
              -91.412874,
              35.360276
            ],
            [
              -91.409696,
              35.365966
            ],
            [
              -91.425681,
              35.375083
            ],
            [
              -91.404619,
              35.386243
            ],
            [
              -91.392192,
              35.375083
            ],
            [
              -91.383474,
              35.377289
            ],
            [
              -91.400644,
              35.391054
            ],
            [
              -91.370699,
              35.406139
            ],
            [
              -91.371953,
              35.421597
            ],
            [
              -91.363769,
              35.4302
            ],
            [
              -91.356598,
              35.408064
            ],
            [
              -91.345279,
              35.423792
            ],
            [
              -91.357194,
              35.430123
            ],
            [
              -91.347257,
              35.439106
            ],
            [
              -91.584687,
              35.442311
            ],
            [
              -91.58188,
              35.530447
            ],
            [
              -91.647957,
              35.532309
            ],
            [
              -91.708647,
              35.533267
            ],
            [
              -91.745107,
              35.534188
            ],
            [
              -91.793958,
              35.534114
            ],
            [
              -91.795855,
              35.455574
            ],
            [
              -91.819339,
              35.449872
            ],
            [
              -91.828629,
              35.454751
            ],
            [
              -91.836816,
              35.441001
            ],
            [
              -91.850144,
              35.436442
            ],
            [
              -91.851958,
              35.358287
            ],
            [
              -92.111463,
              35.362725
            ],
            [
              -92.115942,
              35.19712
            ],
            [
              -92.116592,
              35.156595
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "021",
      "COUNTYNS": "00063759",
      "AFFGEOID": "0500000US05021",
      "GEOID": "05021",
      "NAME": "Clay",
      "LSAD": "06",
      "ALAND": 1656214466,
      "AWATER": 5097734,
      "County_state": "Clay,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.806214,
              36.28817
            ],
            [
              -90.806615,
              36.266865
            ],
            [
              -90.715822,
              36.267936
            ],
            [
              -90.469261,
              36.260034
            ],
            [
              -90.31982,
              36.259144
            ],
            [
              -90.32096,
              36.200575
            ],
            [
              -90.190207,
              36.201361
            ],
            [
              -90.1894381168169,
              36.2003064993461
            ],
            [
              -90.182853,
              36.205108
            ],
            [
              -90.155928,
              36.214074
            ],
            [
              -90.14224,
              36.227522
            ],
            [
              -90.126366,
              36.229367
            ],
            [
              -90.125958,
              36.243416
            ],
            [
              -90.114922,
              36.265595
            ],
            [
              -90.083731,
              36.272332
            ],
            [
              -90.078807,
              36.288547
            ],
            [
              -90.06398,
              36.303038
            ],
            [
              -90.079981,
              36.318619
            ],
            [
              -90.077401,
              36.341003
            ],
            [
              -90.063526,
              36.356911
            ],
            [
              -90.066136,
              36.386272
            ],
            [
              -90.080426,
              36.400763
            ],
            [
              -90.109495,
              36.404073
            ],
            [
              -90.131038,
              36.415069
            ],
            [
              -90.143743,
              36.424433
            ],
            [
              -90.134136,
              36.436602
            ],
            [
              -90.141399,
              36.459874
            ],
            [
              -90.142269,
              36.472138
            ],
            [
              -90.1593750959925,
              36.4809366769308
            ],
            [
              -90.160167,
              36.481344
            ],
            [
              -90.153871,
              36.495344
            ],
            [
              -90.154409,
              36.496832
            ],
            [
              -90.152481,
              36.497952
            ],
            [
              -90.193943,
              36.497823
            ],
            [
              -90.217323,
              36.497797
            ],
            [
              -90.220702,
              36.497858
            ],
            [
              -90.2207319861011,
              36.497857683437594
            ],
            [
              -90.228943,
              36.497771
            ],
            [
              -90.339892,
              36.498213
            ],
            [
              -90.494575,
              36.498368
            ],
            [
              -90.495027,
              36.498371
            ],
            [
              -90.50016,
              36.498399
            ],
            [
              -90.576112,
              36.498446
            ],
            [
              -90.57617955600901,
              36.4984463732781
            ],
            [
              -90.585342,
              36.498497
            ],
            [
              -90.5943,
              36.498459
            ],
            [
              -90.60545,
              36.498459
            ],
            [
              -90.612554,
              36.498559
            ],
            [
              -90.648494,
              36.498447
            ],
            [
              -90.653246,
              36.498488
            ],
            [
              -90.693005,
              36.49851
            ],
            [
              -90.711226,
              36.498318
            ],
            [
              -90.765672,
              36.498494
            ],
            [
              -90.782454,
              36.498523
            ],
            [
              -90.7843982380622,
              36.4985237150037
            ],
            [
              -90.769612,
              36.48527
            ],
            [
              -90.774718,
              36.44732
            ],
            [
              -90.779699,
              36.437578
            ],
            [
              -90.757268,
              36.421789
            ],
            [
              -90.770601,
              36.41304
            ],
            [
              -90.748637,
              36.412764
            ],
            [
              -90.75181,
              36.283836
            ],
            [
              -90.749667,
              36.280536
            ],
            [
              -90.765126,
              36.28706
            ],
            [
              -90.781741,
              36.28417
            ],
            [
              -90.791614,
              36.293321
            ],
            [
              -90.806214,
              36.28817
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "077",
      "COUNTYNS": "00066866",
      "AFFGEOID": "0500000US05077",
      "GEOID": "05077",
      "NAME": "Lee",
      "LSAD": "06",
      "ALAND": 1560781749,
      "AWATER": 43720910,
      "County_state": "Lee,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.099961,
              34.86712
            ],
            [
              -91.102542,
              34.734864
            ],
            [
              -91.049474,
              34.734184
            ],
            [
              -91.050116,
              34.704044
            ],
            [
              -91.051918,
              34.645721
            ],
            [
              -90.980185,
              34.644591
            ],
            [
              -90.8208,
              34.643
            ],
            [
              -90.5834403324521,
              34.641389257845596
            ],
            [
              -90.58302,
              34.642679
            ],
            [
              -90.583088,
              34.64361
            ],
            [
              -90.585031,
              34.647072
            ],
            [
              -90.586336,
              34.651689
            ],
            [
              -90.587323,
              34.665407
            ],
            [
              -90.588536,
              34.668646
            ],
            [
              -90.588419,
              34.670963
            ],
            [
              -90.582006,
              34.680235
            ],
            [
              -90.578745,
              34.683844
            ],
            [
              -90.573106,
              34.686425
            ],
            [
              -90.552717,
              34.688371
            ],
            [
              -90.539409,
              34.670902
            ],
            [
              -90.550158,
              34.663445
            ],
            [
              -90.552642,
              34.659707
            ],
            [
              -90.553962,
              34.655018
            ],
            [
              -90.555104,
              34.646236
            ],
            [
              -90.554129,
              34.640871
            ],
            [
              -90.551761,
              34.636484
            ],
            [
              -90.547614,
              34.631656
            ],
            [
              -90.543696,
              34.629559
            ],
            [
              -90.537165,
              34.627767
            ],
            [
              -90.532188,
              34.627487
            ],
            [
              -90.524481,
              34.628504
            ],
            [
              -90.517168,
              34.630928
            ],
            [
              -90.5081,
              34.636682
            ],
            [
              -90.503061,
              34.64079
            ],
            [
              -90.496639,
              34.648117
            ],
            [
              -90.48789,
              34.654881
            ],
            [
              -90.479718,
              34.659934
            ],
            [
              -90.469821,
              34.669436
            ],
            [
              -90.466041,
              34.674312
            ],
            [
              -90.462816,
              34.684074
            ],
            [
              -90.462552,
              34.687576
            ],
            [
              -90.463734,
              34.691093
            ],
            [
              -90.467064,
              34.695643
            ],
            [
              -90.471185,
              34.699066
            ],
            [
              -90.475194,
              34.700826
            ],
            [
              -90.480041,
              34.701515
            ],
            [
              -90.486966,
              34.701477
            ],
            [
              -90.496552,
              34.700578
            ],
            [
              -90.509847,
              34.698003
            ],
            [
              -90.522084,
              34.696605
            ],
            [
              -90.529211,
              34.696819
            ],
            [
              -90.538974,
              34.698783
            ],
            [
              -90.546053,
              34.702076
            ],
            [
              -90.565646,
              34.721053
            ],
            [
              -90.567482,
              34.723292
            ],
            [
              -90.568081,
              34.724802
            ],
            [
              -90.568172,
              34.727384
            ],
            [
              -90.56724,
              34.733463
            ],
            [
              -90.565437,
              34.736536
            ],
            [
              -90.563544,
              34.738671
            ],
            [
              -90.559895,
              34.740788
            ],
            [
              -90.556308,
              34.741541
            ],
            [
              -90.553186,
              34.741912
            ],
            [
              -90.550284,
              34.742804
            ],
            [
              -90.547606,
              34.744367
            ],
            [
              -90.54582,
              34.745929
            ],
            [
              -90.543811,
              34.749277
            ],
            [
              -90.542695,
              34.752626
            ],
            [
              -90.542631,
              34.764396
            ],
            [
              -90.546225,
              34.773443
            ],
            [
              -90.547859,
              34.779194
            ],
            [
              -90.54817,
              34.78189
            ],
            [
              -90.547612,
              34.784589
            ],
            [
              -90.544067,
              34.791159
            ],
            [
              -90.540222,
              34.795768
            ],
            [
              -90.53651,
              34.798572
            ],
            [
              -90.53133,
              34.800584
            ],
            [
              -90.522892,
              34.802265
            ],
            [
              -90.514706,
              34.801768
            ],
            [
              -90.512761,
              34.796488
            ],
            [
              -90.503679,
              34.780136
            ],
            [
              -90.501523,
              34.774795
            ],
            [
              -90.500994,
              34.771187
            ],
            [
              -90.501325,
              34.769931
            ],
            [
              -90.505494,
              34.764568
            ],
            [
              -90.516522,
              34.758333
            ],
            [
              -90.520556,
              34.753388
            ],
            [
              -90.5219,
              34.748463
            ],
            [
              -90.5219,
              34.743537
            ],
            [
              -90.521004,
              34.738612
            ],
            [
              -90.518317,
              34.73279
            ],
            [
              -90.514735,
              34.729656
            ],
            [
              -90.501667,
              34.724236
            ],
            [
              -90.488865,
              34.723731
            ],
            [
              -90.479704,
              34.724793
            ],
            [
              -90.469897,
              34.72703
            ],
            [
              -90.45795,
              34.732946
            ],
            [
              -90.454968,
              34.735557
            ],
            [
              -90.452479,
              34.739898
            ],
            [
              -90.451257,
              34.744485
            ],
            [
              -90.45117,
              34.747787
            ],
            [
              -90.453038,
              34.753352
            ],
            [
              -90.458883,
              34.764267
            ],
            [
              -90.470411,
              34.780877
            ],
            [
              -90.473527,
              34.788835
            ],
            [
              -90.47459,
              34.7932
            ],
            [
              -90.473877,
              34.798399
            ],
            [
              -90.47228,
              34.802465
            ],
            [
              -90.470544,
              34.805036
            ],
            [
              -90.465367,
              34.810592
            ],
            [
              -90.459024,
              34.81444
            ],
            [
              -90.456761,
              34.820395
            ],
            [
              -90.456935,
              34.823383
            ],
            [
              -90.463795,
              34.834923
            ],
            [
              -90.474403,
              34.849495
            ],
            [
              -90.481955,
              34.857805
            ],
            [
              -90.483876,
              34.861333
            ],
            [
              -90.485038,
              34.869252
            ],
            [
              -90.484558,
              34.875096
            ],
            [
              -90.483969,
              34.877176
            ],
            [
              -90.479872,
              34.883264
            ],
            [
              -90.475686,
              34.88631
            ],
            [
              -90.466154,
              34.890989
            ],
            [
              -90.459819,
              34.891946
            ],
            [
              -90.453916,
              34.891122
            ],
            [
              -90.444806,
              34.887994
            ],
            [
              -90.441254,
              34.886313
            ],
            [
              -90.438313,
              34.884581
            ],
            [
              -90.436561,
              34.882731
            ],
            [
              -90.430096,
              34.871212
            ],
            [
              -90.42898,
              34.867168
            ],
            [
              -90.429115,
              34.865087
            ],
            [
              -90.431722,
              34.858913
            ],
            [
              -90.431741,
              34.855051
            ],
            [
              -90.430828,
              34.848982
            ],
            [
              -90.428754,
              34.8414
            ],
            [
              -90.423879,
              34.834606
            ],
            [
              -90.422355,
              34.833675
            ],
            [
              -90.414864,
              34.831846
            ],
            [
              -90.410666,
              34.832028
            ],
            [
              -90.4079908833418,
              34.832998481267495
            ],
            [
              -90.40854,
              34.904121
            ],
            [
              -90.619817,
              34.906427
            ],
            [
              -90.69265,
              34.90555
            ],
            [
              -90.74697,
              34.90512
            ],
            [
              -90.951837,
              34.90701
            ],
            [
              -91.04645,
              34.911931
            ],
            [
              -91.099201,
              34.911796
            ],
            [
              -91.099961,
              34.86712
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "123",
      "COUNTYNS": "00083331",
      "AFFGEOID": "0500000US05123",
      "GEOID": "05123",
      "NAME": "St. Francis",
      "LSAD": "06",
      "ALAND": 1644132565,
      "AWATER": 20028723,
      "County_state": "St. Francis,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.149842,
              35.00247
            ],
            [
              -91.152457,
              34.911847
            ],
            [
              -91.116609,
              34.911797
            ],
            [
              -91.117776,
              34.867167
            ],
            [
              -91.099961,
              34.86712
            ],
            [
              -91.099201,
              34.911796
            ],
            [
              -91.04645,
              34.911931
            ],
            [
              -90.951837,
              34.90701
            ],
            [
              -90.74697,
              34.90512
            ],
            [
              -90.69265,
              34.90555
            ],
            [
              -90.619817,
              34.906427
            ],
            [
              -90.40854,
              34.904121
            ],
            [
              -90.407885,
              35.002494
            ],
            [
              -90.400438,
              35.148231
            ],
            [
              -90.502993,
              35.144214
            ],
            [
              -90.503015,
              35.143368
            ],
            [
              -90.609896,
              35.145082
            ],
            [
              -90.61152,
              35.14642
            ],
            [
              -90.633626,
              35.14686
            ],
            [
              -90.637384,
              35.145109
            ],
            [
              -90.721541,
              35.147926
            ],
            [
              -90.82214,
              35.148161
            ],
            [
              -90.82915,
              35.14875
            ],
            [
              -91.04267,
              35.148189
            ],
            [
              -91.04543,
              35.00104
            ],
            [
              -91.149842,
              35.00247
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "027",
      "COUNTYNS": "00063761",
      "AFFGEOID": "0500000US05027",
      "GEOID": "05027",
      "NAME": "Columbia",
      "LSAD": "06",
      "ALAND": 1983889573,
      "AWATER": 1990757,
      "County_state": "Columbia,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.4905199755506,
              33.0184422689434
            ],
            [
              -93.489506,
              33.018443
            ],
            [
              -93.46709735374169,
              33.018610586029695
            ],
            [
              -93.467042,
              33.018611
            ],
            [
              -93.377134,
              33.018234
            ],
            [
              -93.340353,
              33.018337
            ],
            [
              -93.308398,
              33.018179
            ],
            [
              -93.308181,
              33.018156
            ],
            [
              -93.238607,
              33.017992
            ],
            [
              -93.215653,
              33.018393
            ],
            [
              -93.197402,
              33.017951
            ],
            [
              -93.154351,
              33.017856
            ],
            [
              -93.1197298546433,
              33.0177800936558
            ],
            [
              -93.101443,
              33.01774
            ],
            [
              -93.100981,
              33.017786
            ],
            [
              -93.081428,
              33.017928
            ],
            [
              -93.073167,
              33.017898
            ],
            [
              -93.070686,
              33.017792
            ],
            [
              -92.9887078142573,
              33.0172979025058
            ],
            [
              -92.986389,
              33.097688
            ],
            [
              -92.978469,
              33.37728
            ],
            [
              -93.01394,
              33.377928
            ],
            [
              -93.119014,
              33.380293
            ],
            [
              -93.116361,
              33.452909
            ],
            [
              -93.221031,
              33.455045
            ],
            [
              -93.22129,
              33.440444
            ],
            [
              -93.326751,
              33.443787
            ],
            [
              -93.365585,
              33.444459
            ],
            [
              -93.376713,
              33.43256
            ],
            [
              -93.420812,
              33.328752
            ],
            [
              -93.428728,
              33.3162
            ],
            [
              -93.425866,
              33.308939
            ],
            [
              -93.423275,
              33.301502
            ],
            [
              -93.436226,
              33.289477
            ],
            [
              -93.432744,
              33.276578
            ],
            [
              -93.442019,
              33.258165
            ],
            [
              -93.48176,
              33.259991
            ],
            [
              -93.4905199755506,
              33.0184422689434
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "103",
      "COUNTYNS": "00069170",
      "AFFGEOID": "0500000US05103",
      "GEOID": "05103",
      "NAME": "Ouachita",
      "LSAD": "06",
      "ALAND": 1897898732,
      "AWATER": 18050431,
      "County_state": "Ouachita,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.116361,
              33.452909
            ],
            [
              -93.119014,
              33.380293
            ],
            [
              -93.01394,
              33.377928
            ],
            [
              -92.978469,
              33.37728
            ],
            [
              -92.944182,
              33.376441
            ],
            [
              -92.804149,
              33.372715
            ],
            [
              -92.777367,
              33.374902
            ],
            [
              -92.759946,
              33.387748
            ],
            [
              -92.720161,
              33.382242
            ],
            [
              -92.715403,
              33.371001
            ],
            [
              -92.681097,
              33.365215
            ],
            [
              -92.660493,
              33.355603
            ],
            [
              -92.645777,
              33.368365
            ],
            [
              -92.623767,
              33.359088
            ],
            [
              -92.603005,
              33.364303
            ],
            [
              -92.594339,
              33.373724
            ],
            [
              -92.567156,
              33.3672
            ],
            [
              -92.567534,
              33.378269
            ],
            [
              -92.579292,
              33.372529
            ],
            [
              -92.585529,
              33.397992
            ],
            [
              -92.619872,
              33.409153
            ],
            [
              -92.641248,
              33.396263
            ],
            [
              -92.636498,
              33.415163
            ],
            [
              -92.654987,
              33.422933
            ],
            [
              -92.653211,
              33.433968
            ],
            [
              -92.675233,
              33.430538
            ],
            [
              -92.705093,
              33.431215
            ],
            [
              -92.702715,
              33.453517
            ],
            [
              -92.716691,
              33.451868
            ],
            [
              -92.72464,
              33.460161
            ],
            [
              -92.716346,
              33.481585
            ],
            [
              -92.73625,
              33.494923
            ],
            [
              -92.737525,
              33.485966
            ],
            [
              -92.752895,
              33.484232
            ],
            [
              -92.765521,
              33.492376
            ],
            [
              -92.755382,
              33.500802
            ],
            [
              -92.773429,
              33.51006
            ],
            [
              -92.759035,
              33.530954
            ],
            [
              -92.776729,
              33.537648
            ],
            [
              -92.775871,
              33.544247
            ],
            [
              -92.764581,
              33.553245
            ],
            [
              -92.746363,
              33.54527
            ],
            [
              -92.726935,
              33.563724
            ],
            [
              -92.717454,
              33.552444
            ],
            [
              -92.71001,
              33.591021
            ],
            [
              -92.732873,
              33.616046
            ],
            [
              -92.723615,
              33.651861
            ],
            [
              -92.65273,
              33.661333
            ],
            [
              -92.635132,
              33.693057
            ],
            [
              -92.599637,
              33.711092
            ],
            [
              -92.599499,
              33.718341
            ],
            [
              -92.589523,
              33.72855
            ],
            [
              -92.58503,
              33.736514
            ],
            [
              -92.583054,
              33.802198
            ],
            [
              -92.585611,
              33.802259
            ],
            [
              -92.885671,
              33.809988
            ],
            [
              -92.893534,
              33.809943
            ],
            [
              -92.896495,
              33.806162
            ],
            [
              -92.91815,
              33.821587
            ],
            [
              -92.938687,
              33.811666
            ],
            [
              -92.940119,
              33.800178
            ],
            [
              -92.992632,
              33.77739
            ],
            [
              -93.008064,
              33.778786
            ],
            [
              -93.032049,
              33.769966
            ],
            [
              -93.061987,
              33.786365
            ],
            [
              -93.07826,
              33.786292
            ],
            [
              -93.086304,
              33.776423
            ],
            [
              -93.10429,
              33.777011
            ],
            [
              -93.116361,
              33.452909
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "031",
      "COUNTYNS": "00066847",
      "AFFGEOID": "0500000US05031",
      "GEOID": "05031",
      "NAME": "Craighead",
      "LSAD": "06",
      "ALAND": 1831867347,
      "AWATER": 14069141,
      "County_state": "Craighead,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.03503,
              35.796566
            ],
            [
              -91.036967,
              35.708418
            ],
            [
              -90.71349,
              35.705266
            ],
            [
              -90.604724,
              35.70446
            ],
            [
              -90.50058,
              35.707268
            ],
            [
              -90.391779,
              35.701234
            ],
            [
              -90.286736,
              35.699914
            ],
            [
              -90.2889470498035,
              35.9964180953312
            ],
            [
              -90.292376,
              35.996397
            ],
            [
              -90.339434,
              35.996033
            ],
            [
              -90.342616,
              35.995895
            ],
            [
              -90.368718,
              35.995812
            ],
            [
              -90.37789,
              35.995683
            ],
            [
              -90.387378,
              35.964708
            ],
            [
              -90.460279,
              35.965113
            ],
            [
              -90.496019,
              35.965268
            ],
            [
              -90.585454,
              35.96556
            ],
            [
              -90.603031,
              35.965662
            ],
            [
              -90.665861,
              35.966402
            ],
            [
              -90.852064,
              35.968092
            ],
            [
              -90.861542,
              35.955605
            ],
            [
              -90.85722,
              35.95074
            ],
            [
              -90.861412,
              35.92409
            ],
            [
              -90.872175,
              35.9244
            ],
            [
              -90.872785,
              35.88087
            ],
            [
              -91.032499,
              35.88507
            ],
            [
              -91.03503,
              35.796566
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "039",
      "COUNTYNS": "00066849",
      "AFFGEOID": "0500000US05039",
      "GEOID": "05039",
      "NAME": "Dallas",
      "LSAD": "06",
      "ALAND": 1728522517,
      "AWATER": 1864048,
      "County_state": "Dallas,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.949144,
              33.986369
            ],
            [
              -92.961419,
              33.974159
            ],
            [
              -92.94082,
              33.960832
            ],
            [
              -92.935171,
              33.937978
            ],
            [
              -92.908937,
              33.918129
            ],
            [
              -92.907702,
              33.908115
            ],
            [
              -92.89314,
              33.902751
            ],
            [
              -92.909755,
              33.898053
            ],
            [
              -92.899814,
              33.856775
            ],
            [
              -92.883834,
              33.855733
            ],
            [
              -92.878834,
              33.837176
            ],
            [
              -92.89369,
              33.833902
            ],
            [
              -92.888561,
              33.825904
            ],
            [
              -92.899968,
              33.816186
            ],
            [
              -92.893534,
              33.809943
            ],
            [
              -92.885671,
              33.809988
            ],
            [
              -92.585611,
              33.802259
            ],
            [
              -92.583054,
              33.802198
            ],
            [
              -92.334249,
              33.795065
            ],
            [
              -92.337389,
              33.82813
            ],
            [
              -92.353225,
              33.853099
            ],
            [
              -92.38118,
              33.869367
            ],
            [
              -92.394007,
              33.886717
            ],
            [
              -92.400798,
              33.91356
            ],
            [
              -92.42022,
              33.938486
            ],
            [
              -92.45613,
              33.972622
            ],
            [
              -92.475602,
              33.972696
            ],
            [
              -92.471667,
              34.057054
            ],
            [
              -92.336765,
              34.059633
            ],
            [
              -92.374239,
              34.075039
            ],
            [
              -92.374962,
              34.086481
            ],
            [
              -92.394041,
              34.104489
            ],
            [
              -92.405538,
              34.115413
            ],
            [
              -92.426456,
              34.125359
            ],
            [
              -92.448114,
              34.123052
            ],
            [
              -92.468844,
              34.137472
            ],
            [
              -92.491657,
              34.132936
            ],
            [
              -92.493672,
              34.144234
            ],
            [
              -92.678383,
              34.149622
            ],
            [
              -92.813986,
              34.15166
            ],
            [
              -92.886285,
              34.155877
            ],
            [
              -92.888538,
              34.09823
            ],
            [
              -92.893116,
              33.983284
            ],
            [
              -92.949144,
              33.986369
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "045",
      "COUNTYNS": "00066852",
      "AFFGEOID": "0500000US05045",
      "GEOID": "05045",
      "NAME": "Faulkner",
      "LSAD": "06",
      "ALAND": 1678176463,
      "AWATER": 41765990,
      "County_state": "Faulkner,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.58418,
              35.020153
            ],
            [
              -92.597762,
              34.995827
            ],
            [
              -92.571976,
              34.962883
            ],
            [
              -92.544341,
              34.953319
            ],
            [
              -92.483512,
              34.950937
            ],
            [
              -92.476379,
              34.946102
            ],
            [
              -92.453007,
              34.906257
            ],
            [
              -92.461655,
              34.857064
            ],
            [
              -92.436108,
              34.842218
            ],
            [
              -92.44723,
              34.856954
            ],
            [
              -92.442352,
              34.870211
            ],
            [
              -92.450316,
              34.901319
            ],
            [
              -92.417857,
              34.913427
            ],
            [
              -92.419276,
              34.931675
            ],
            [
              -92.261896,
              34.92815
            ],
            [
              -92.229893,
              34.927558
            ],
            [
              -92.227541,
              35.014484
            ],
            [
              -92.207877,
              35.014277
            ],
            [
              -92.121336,
              35.01221
            ],
            [
              -92.120885,
              35.027372
            ],
            [
              -92.119719,
              35.066493
            ],
            [
              -92.116592,
              35.156595
            ],
            [
              -92.115942,
              35.19712
            ],
            [
              -92.111463,
              35.362725
            ],
            [
              -92.122943,
              35.362019
            ],
            [
              -92.182019,
              35.362605
            ],
            [
              -92.252759,
              35.363718
            ],
            [
              -92.391702,
              35.366777
            ],
            [
              -92.3998,
              35.366845
            ],
            [
              -92.481469,
              35.368392
            ],
            [
              -92.484259,
              35.280706
            ],
            [
              -92.514393,
              35.257004
            ],
            [
              -92.504138,
              35.24025
            ],
            [
              -92.531457,
              35.224258
            ],
            [
              -92.521977,
              35.221248
            ],
            [
              -92.528687,
              35.189658
            ],
            [
              -92.500305,
              35.173827
            ],
            [
              -92.507553,
              35.136711
            ],
            [
              -92.521445,
              35.114697
            ],
            [
              -92.543139,
              35.125957
            ],
            [
              -92.554685,
              35.113277
            ],
            [
              -92.549825,
              35.109559
            ],
            [
              -92.544635,
              35.097321
            ],
            [
              -92.541481,
              35.088559
            ],
            [
              -92.54103,
              35.077377
            ],
            [
              -92.545452,
              35.055144
            ],
            [
              -92.560971,
              35.035417
            ],
            [
              -92.569926,
              35.028506
            ],
            [
              -92.58418,
              35.020153
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "131",
      "COUNTYNS": "00069181",
      "AFFGEOID": "0500000US05131",
      "GEOID": "05131",
      "NAME": "Sebastian",
      "LSAD": "06",
      "ALAND": 1377601663,
      "AWATER": 39693809,
      "County_state": "Sebastian,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.4463019390597,
              34.9782290232881
            ],
            [
              -94.4478888293082,
              34.933941114475395
            ],
            [
              -94.429544,
              34.931387
            ],
            [
              -94.409405,
              34.949895
            ],
            [
              -94.392173,
              34.943542
            ],
            [
              -94.376195,
              34.948553
            ],
            [
              -94.367617,
              34.962266
            ],
            [
              -94.348489,
              34.947838
            ],
            [
              -94.324036,
              34.955977
            ],
            [
              -94.294857,
              34.952867
            ],
            [
              -94.256161,
              34.959748
            ],
            [
              -94.230524,
              34.968527
            ],
            [
              -94.230123,
              34.985847
            ],
            [
              -94.228258,
              35.056754
            ],
            [
              -94.232698,
              35.057683
            ],
            [
              -94.250852,
              35.058171
            ],
            [
              -94.250764,
              35.065589
            ],
            [
              -94.232418,
              35.070502
            ],
            [
              -94.195294,
              35.08587
            ],
            [
              -94.15892,
              35.092475
            ],
            [
              -94.158645,
              35.099847
            ],
            [
              -94.140571,
              35.099678
            ],
            [
              -94.139307,
              35.143361
            ],
            [
              -94.031236,
              35.140857
            ],
            [
              -94.028653,
              35.212131
            ],
            [
              -94.028547,
              35.213826
            ],
            [
              -94.046446,
              35.214391
            ],
            [
              -94.048095,
              35.199481
            ],
            [
              -94.064873,
              35.199848
            ],
            [
              -94.064247,
              35.214662
            ],
            [
              -94.082798,
              35.215913
            ],
            [
              -94.07983,
              35.2869
            ],
            [
              -94.07693,
              35.41251
            ],
            [
              -94.075761,
              35.425354
            ],
            [
              -94.074895,
              35.445403
            ],
            [
              -94.086617,
              35.442486
            ],
            [
              -94.142437,
              35.45024
            ],
            [
              -94.157162,
              35.441704
            ],
            [
              -94.161425,
              35.435341
            ],
            [
              -94.135113,
              35.399246
            ],
            [
              -94.13906,
              35.384608
            ],
            [
              -94.167371,
              35.378152
            ],
            [
              -94.189756,
              35.388017
            ],
            [
              -94.204866,
              35.36017
            ],
            [
              -94.233765,
              35.354072
            ],
            [
              -94.24234,
              35.355237
            ],
            [
              -94.260397,
              35.35389
            ],
            [
              -94.280212,
              35.359965
            ],
            [
              -94.30507,
              35.353191
            ],
            [
              -94.342481,
              35.371833
            ],
            [
              -94.347864,
              35.381109
            ],
            [
              -94.339049,
              35.408682
            ],
            [
              -94.358105,
              35.429281
            ],
            [
              -94.38729,
              35.448197
            ],
            [
              -94.413565,
              35.446811
            ],
            [
              -94.4373214645191,
              35.4281344377591
            ],
            [
              -94.431215,
              35.39429
            ],
            [
              -94.4313807766676,
              35.39386640991479
            ],
            [
              -94.4321813745028,
              35.3918207341872
            ],
            [
              -94.433915,
              35.387391
            ],
            [
              -94.4337424273008,
              35.3864671046955
            ],
            [
              -94.432685,
              35.380806
            ],
            [
              -94.433415,
              35.376291
            ],
            [
              -94.431515,
              35.369591
            ],
            [
              -94.432015,
              35.367391
            ],
            [
              -94.431815,
              35.362891
            ],
            [
              -94.434115,
              35.306493
            ],
            [
              -94.43517,
              35.291494
            ],
            [
              -94.43528,
              35.287485
            ],
            [
              -94.435316,
              35.275893
            ],
            [
              -94.435706,
              35.274267
            ],
            [
              -94.4357930292852,
              35.2718310010462
            ],
            [
              -94.435812,
              35.2713
            ],
            [
              -94.436595,
              35.250094
            ],
            [
              -94.437578,
              35.242202
            ],
            [
              -94.437774,
              35.239271
            ],
            [
              -94.437578,
              35.230936
            ],
            [
              -94.438247,
              35.210992
            ],
            [
              -94.43847,
              35.208587
            ],
            [
              -94.439084,
              35.197298
            ],
            [
              -94.439056,
              35.193588
            ],
            [
              -94.43886,
              35.187183
            ],
            [
              -94.439509,
              35.171807
            ],
            [
              -94.43955,
              35.169037
            ],
            [
              -94.4397174421235,
              35.163442013231496
            ],
            [
              -94.440754,
              35.128806
            ],
            [
              -94.441232,
              35.119724
            ],
            [
              -94.4432818799616,
              35.06251468959
            ],
            [
              -94.4463019390597,
              34.9782290232881
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "057",
      "COUNTYNS": "00066858",
      "AFFGEOID": "0500000US05057",
      "GEOID": "05057",
      "NAME": "Hempstead",
      "LSAD": "06",
      "ALAND": 1884268212,
      "AWATER": 35372110,
      "County_state": "Hempstead,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.969431,
              33.728111
            ],
            [
              -93.96462,
              33.713951
            ],
            [
              -93.951434,
              33.706902
            ],
            [
              -93.966739,
              33.704122
            ],
            [
              -93.960675,
              33.693886
            ],
            [
              -93.966973,
              33.672308
            ],
            [
              -93.946024,
              33.659437
            ],
            [
              -93.938964,
              33.647633
            ],
            [
              -93.929858,
              33.663605
            ],
            [
              -93.913259,
              33.662942
            ],
            [
              -93.90314,
              33.651031
            ],
            [
              -93.916703,
              33.629886
            ],
            [
              -93.891228,
              33.637703
            ],
            [
              -93.884499,
              33.627183
            ],
            [
              -93.873169,
              33.634109
            ],
            [
              -93.861117,
              33.627825
            ],
            [
              -93.852738,
              33.613823
            ],
            [
              -93.839836,
              33.616474
            ],
            [
              -93.825884,
              33.609853
            ],
            [
              -93.812503,
              33.603351
            ],
            [
              -93.815274,
              33.588554
            ],
            [
              -93.8268,
              33.57674
            ],
            [
              -93.821568,
              33.565798
            ],
            [
              -93.800577,
              33.564678
            ],
            [
              -93.794128,
              33.541077
            ],
            [
              -93.778787,
              33.525986
            ],
            [
              -93.772686,
              33.498513
            ],
            [
              -93.7556,
              33.503252
            ],
            [
              -93.764488,
              33.514532
            ],
            [
              -93.74661,
              33.533431
            ],
            [
              -93.753867,
              33.516391
            ],
            [
              -93.736641,
              33.501435
            ],
            [
              -93.722867,
              33.481963
            ],
            [
              -93.635792,
              33.47986
            ],
            [
              -93.483097,
              33.476075
            ],
            [
              -93.480625,
              33.56334
            ],
            [
              -93.475345,
              33.71679
            ],
            [
              -93.474757,
              33.733121
            ],
            [
              -93.474152,
              33.738545
            ],
            [
              -93.473768,
              33.752898
            ],
            [
              -93.471207,
              33.825224
            ],
            [
              -93.460897,
              33.824983
            ],
            [
              -93.456709,
              33.956837
            ],
            [
              -93.45977,
              33.963124
            ],
            [
              -93.498028,
              33.953857
            ],
            [
              -93.528047,
              33.943012
            ],
            [
              -93.530528,
              33.954125
            ],
            [
              -93.546549,
              33.951403
            ],
            [
              -93.572636,
              33.961096
            ],
            [
              -93.605066,
              33.96447
            ],
            [
              -93.61746,
              33.959627
            ],
            [
              -93.627423,
              33.972496
            ],
            [
              -93.648131,
              33.982967
            ],
            [
              -93.682369,
              33.983539
            ],
            [
              -93.69982,
              33.995045
            ],
            [
              -93.717788,
              33.996632
            ],
            [
              -93.749375,
              34.006743
            ],
            [
              -93.823076,
              34.008893
            ],
            [
              -93.824724,
              33.950835
            ],
            [
              -93.831452,
              33.747709
            ],
            [
              -93.957315,
              33.75094
            ],
            [
              -93.969431,
              33.728111
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "149",
      "COUNTYNS": "00069908",
      "AFFGEOID": "0500000US05149",
      "GEOID": "05149",
      "NAME": "Yell",
      "LSAD": "06",
      "ALAND": 2409046726,
      "AWATER": 48482972,
      "County_state": "Yell,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.707276,
              34.905082
            ],
            [
              -93.710297,
              34.745296
            ],
            [
              -93.394289,
              34.743393
            ],
            [
              -93.394456,
              34.772355
            ],
            [
              -93.287785,
              34.773193
            ],
            [
              -93.288284,
              34.860992
            ],
            [
              -93.305885,
              34.861092
            ],
            [
              -93.305684,
              34.875592
            ],
            [
              -93.288184,
              34.875292
            ],
            [
              -93.288184,
              34.888692
            ],
            [
              -93.270683,
              34.889191
            ],
            [
              -93.270473,
              34.903908
            ],
            [
              -93.255734,
              34.903808
            ],
            [
              -93.252448,
              34.906247
            ],
            [
              -93.252185,
              34.918323
            ],
            [
              -93.235081,
              34.918091
            ],
            [
              -93.235181,
              34.932591
            ],
            [
              -93.21748,
              34.932691
            ],
            [
              -93.217348,
              34.947945
            ],
            [
              -93.200579,
              34.94759
            ],
            [
              -93.200086,
              34.962263
            ],
            [
              -93.181778,
              34.96199
            ],
            [
              -93.181478,
              34.97639
            ],
            [
              -93.160177,
              34.97599
            ],
            [
              -93.160277,
              34.99059
            ],
            [
              -93.142277,
              34.99029
            ],
            [
              -93.141797,
              35.004698
            ],
            [
              -93.124127,
              35.004262
            ],
            [
              -93.123791,
              35.018661
            ],
            [
              -93.105954,
              35.017873
            ],
            [
              -93.10559,
              35.033868
            ],
            [
              -93.087329,
              35.033999
            ],
            [
              -93.087297,
              35.048312
            ],
            [
              -93.069526,
              35.048089
            ],
            [
              -93.069295,
              35.062627
            ],
            [
              -93.051491,
              35.062352
            ],
            [
              -93.051255,
              35.076978
            ],
            [
              -93.040525,
              35.076923
            ],
            [
              -93.026237,
              35.088347
            ],
            [
              -93.040709,
              35.108951
            ],
            [
              -92.979748,
              35.11808
            ],
            [
              -92.978977,
              35.13134
            ],
            [
              -92.955966,
              35.140256
            ],
            [
              -92.924286,
              35.168336
            ],
            [
              -92.896109,
              35.170678
            ],
            [
              -92.923746,
              35.17608
            ],
            [
              -93.021807,
              35.163309
            ],
            [
              -93.027647,
              35.159793
            ],
            [
              -93.030386,
              35.152546
            ],
            [
              -93.027899,
              35.132928
            ],
            [
              -93.032466,
              35.125083
            ],
            [
              -93.04566,
              35.118398
            ],
            [
              -93.061066,
              35.117531
            ],
            [
              -93.084678,
              35.12739
            ],
            [
              -93.092088,
              35.144726
            ],
            [
              -93.080891,
              35.166197
            ],
            [
              -93.078125,
              35.178891
            ],
            [
              -93.087978,
              35.1816
            ],
            [
              -93.115102,
              35.171708
            ],
            [
              -93.13026,
              35.180476
            ],
            [
              -93.140065,
              35.20968
            ],
            [
              -93.156078,
              35.235307
            ],
            [
              -93.183471,
              35.262324
            ],
            [
              -93.200975,
              35.27182
            ],
            [
              -93.234656,
              35.273806
            ],
            [
              -93.246446,
              35.277078
            ],
            [
              -93.251889,
              35.297876
            ],
            [
              -93.278293,
              35.317402
            ],
            [
              -93.279959,
              35.212552
            ],
            [
              -93.315534,
              35.212382
            ],
            [
              -93.457022,
              35.215782
            ],
            [
              -93.45698,
              35.208482
            ],
            [
              -93.47451,
              35.209729
            ],
            [
              -93.483832,
              35.201786
            ],
            [
              -93.483954,
              35.180464
            ],
            [
              -93.493709,
              35.173755
            ],
            [
              -93.51117,
              35.174224
            ],
            [
              -93.511431,
              35.159718
            ],
            [
              -93.529261,
              35.160003
            ],
            [
              -93.529838,
              35.145441
            ],
            [
              -93.662979,
              35.148225
            ],
            [
              -93.663263,
              35.133835
            ],
            [
              -93.706692,
              35.134943
            ],
            [
              -93.709061,
              35.046787
            ],
            [
              -93.704885,
              35.019715
            ],
            [
              -93.706703,
              34.932035
            ],
            [
              -93.707276,
              34.905082
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "019",
      "COUNTYNS": "00066845",
      "AFFGEOID": "0500000US05019",
      "GEOID": "05019",
      "NAME": "Clark",
      "LSAD": "06",
      "ALAND": 2243130473,
      "AWATER": 43387485,
      "County_state": "Clark,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.50148,
              34.253771
            ],
            [
              -93.502208,
              34.214106
            ],
            [
              -93.46169,
              34.205751
            ],
            [
              -93.442995,
              34.208419
            ],
            [
              -93.460373,
              34.190354
            ],
            [
              -93.448601,
              34.180454
            ],
            [
              -93.455704,
              34.158312
            ],
            [
              -93.449125,
              34.146335
            ],
            [
              -93.456641,
              34.130526
            ],
            [
              -93.441629,
              34.120512
            ],
            [
              -93.444877,
              34.114299
            ],
            [
              -93.435087,
              34.070609
            ],
            [
              -93.434165,
              34.049739
            ],
            [
              -93.416751,
              34.038671
            ],
            [
              -93.40503,
              34.005759
            ],
            [
              -93.391915,
              34.000965
            ],
            [
              -93.379053,
              33.986309
            ],
            [
              -93.380588,
              33.973017
            ],
            [
              -93.373406,
              33.95707
            ],
            [
              -93.373695,
              33.951208
            ],
            [
              -93.358232,
              33.94805
            ],
            [
              -93.32258,
              33.907817
            ],
            [
              -93.304073,
              33.897867
            ],
            [
              -93.307625,
              33.881955
            ],
            [
              -93.290264,
              33.844744
            ],
            [
              -93.28922,
              33.843993
            ],
            [
              -93.288214,
              33.843576
            ],
            [
              -93.287025,
              33.843244
            ],
            [
              -93.286545,
              33.843422
            ],
            [
              -93.286327,
              33.843637
            ],
            [
              -93.285794,
              33.843785
            ],
            [
              -93.285202,
              33.844202
            ],
            [
              -93.284848,
              33.844331
            ],
            [
              -93.284385,
              33.844177
            ],
            [
              -93.283653,
              33.843978
            ],
            [
              -93.283155,
              33.843458
            ],
            [
              -93.283138,
              33.843281
            ],
            [
              -93.282609,
              33.84292
            ],
            [
              -93.281435,
              33.842466
            ],
            [
              -93.279661,
              33.841319
            ],
            [
              -93.279215,
              33.840981
            ],
            [
              -93.27849,
              33.840743
            ],
            [
              -93.277421,
              33.840593
            ],
            [
              -93.276597,
              33.840575
            ],
            [
              -93.275494,
              33.841167
            ],
            [
              -93.275047,
              33.84122
            ],
            [
              -93.274603,
              33.841096
            ],
            [
              -93.274452,
              33.840924
            ],
            [
              -93.274146,
              33.840165
            ],
            [
              -93.274318,
              33.840054
            ],
            [
              -93.257249,
              33.828626
            ],
            [
              -93.218972,
              33.828806
            ],
            [
              -93.195301,
              33.819238
            ],
            [
              -93.179434,
              33.800169
            ],
            [
              -93.155941,
              33.813854
            ],
            [
              -93.139844,
              33.815366
            ],
            [
              -93.12871,
              33.792683
            ],
            [
              -93.112501,
              33.7828
            ],
            [
              -93.112705,
              33.780084
            ],
            [
              -93.10429,
              33.777011
            ],
            [
              -93.086304,
              33.776423
            ],
            [
              -93.07826,
              33.786292
            ],
            [
              -93.061987,
              33.786365
            ],
            [
              -93.032049,
              33.769966
            ],
            [
              -93.008064,
              33.778786
            ],
            [
              -92.992632,
              33.77739
            ],
            [
              -92.940119,
              33.800178
            ],
            [
              -92.938687,
              33.811666
            ],
            [
              -92.91815,
              33.821587
            ],
            [
              -92.896495,
              33.806162
            ],
            [
              -92.893534,
              33.809943
            ],
            [
              -92.899968,
              33.816186
            ],
            [
              -92.888561,
              33.825904
            ],
            [
              -92.89369,
              33.833902
            ],
            [
              -92.878834,
              33.837176
            ],
            [
              -92.883834,
              33.855733
            ],
            [
              -92.899814,
              33.856775
            ],
            [
              -92.909755,
              33.898053
            ],
            [
              -92.89314,
              33.902751
            ],
            [
              -92.907702,
              33.908115
            ],
            [
              -92.908937,
              33.918129
            ],
            [
              -92.935171,
              33.937978
            ],
            [
              -92.94082,
              33.960832
            ],
            [
              -92.961419,
              33.974159
            ],
            [
              -92.949144,
              33.986369
            ],
            [
              -92.893116,
              33.983284
            ],
            [
              -92.888538,
              34.09823
            ],
            [
              -92.886285,
              34.155877
            ],
            [
              -93.029996,
              34.160765
            ],
            [
              -93.038949,
              34.155437
            ],
            [
              -93.043101,
              34.180267
            ],
            [
              -93.032605,
              34.196714
            ],
            [
              -93.025908,
              34.201885
            ],
            [
              -93.030867,
              34.213422
            ],
            [
              -93.038509,
              34.221431
            ],
            [
              -93.034441,
              34.236291
            ],
            [
              -93.084386,
              34.235587
            ],
            [
              -93.090042,
              34.245703
            ],
            [
              -93.199428,
              34.248064
            ],
            [
              -93.198278,
              34.277337
            ],
            [
              -93.251018,
              34.278407
            ],
            [
              -93.351132,
              34.280166
            ],
            [
              -93.35684,
              34.280306
            ],
            [
              -93.355795,
              34.338698
            ],
            [
              -93.408124,
              34.339527
            ],
            [
              -93.478646,
              34.340851
            ],
            [
              -93.480366,
              34.253335
            ],
            [
              -93.50148,
              34.253771
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "051",
      "COUNTYNS": "00066855",
      "AFFGEOID": "0500000US05051",
      "GEOID": "05051",
      "NAME": "Garland",
      "LSAD": "06",
      "ALAND": 1755290919,
      "AWATER": 147383373,
      "County_state": "Garland,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.403555,
              34.601992
            ],
            [
              -93.407227,
              34.396853
            ],
            [
              -93.387728,
              34.397108
            ],
            [
              -93.24741,
              34.39497
            ],
            [
              -93.229653,
              34.394415
            ],
            [
              -93.115251,
              34.391558
            ],
            [
              -92.948071,
              34.389255
            ],
            [
              -92.947882,
              34.444775
            ],
            [
              -92.913462,
              34.448361
            ],
            [
              -92.915079,
              34.506114
            ],
            [
              -92.879894,
              34.505431
            ],
            [
              -92.793197,
              34.503606
            ],
            [
              -92.791498,
              34.561985
            ],
            [
              -92.808952,
              34.562248
            ],
            [
              -92.808423,
              34.577165
            ],
            [
              -92.808028,
              34.590406
            ],
            [
              -92.876976,
              34.59112
            ],
            [
              -92.875456,
              34.635124
            ],
            [
              -92.979902,
              34.637222
            ],
            [
              -92.979447,
              34.684811
            ],
            [
              -92.96949,
              34.701246
            ],
            [
              -92.96925,
              34.772266
            ],
            [
              -93.075178,
              34.771892
            ],
            [
              -93.162059,
              34.771992
            ],
            [
              -93.287785,
              34.773193
            ],
            [
              -93.394456,
              34.772355
            ],
            [
              -93.394289,
              34.743393
            ],
            [
              -93.393889,
              34.685218
            ],
            [
              -93.402189,
              34.685193
            ],
            [
              -93.403503,
              34.610417
            ],
            [
              -93.403555,
              34.601992
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "133",
      "COUNTYNS": "00069182",
      "AFFGEOID": "0500000US05133",
      "GEOID": "05133",
      "NAME": "Sevier",
      "LSAD": "06",
      "ALAND": 1463680831,
      "AWATER": 41831844,
      "County_state": "Sevier,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.477038,
              33.953838
            ],
            [
              -94.4773181199803,
              33.9409324150055
            ],
            [
              -94.467305,
              33.944351
            ],
            [
              -94.446535,
              33.929242
            ],
            [
              -94.435919,
              33.931894
            ],
            [
              -94.412155,
              33.926208
            ],
            [
              -94.421635,
              33.942972
            ],
            [
              -94.408672,
              33.945281
            ],
            [
              -94.38541,
              33.940887
            ],
            [
              -94.379773,
              33.933064
            ],
            [
              -94.388548,
              33.895176
            ],
            [
              -94.361605,
              33.887166
            ],
            [
              -94.372266,
              33.877475
            ],
            [
              -94.340911,
              33.882605
            ],
            [
              -94.333257,
              33.8605
            ],
            [
              -94.324878,
              33.863241
            ],
            [
              -94.303544,
              33.853648
            ],
            [
              -94.303002,
              33.821414
            ],
            [
              -94.285096,
              33.830057
            ],
            [
              -94.269092,
              33.817811
            ],
            [
              -94.251417,
              33.817891
            ],
            [
              -94.237445,
              33.802074
            ],
            [
              -94.218274,
              33.814497
            ],
            [
              -94.217804,
              33.803544
            ],
            [
              -94.191793,
              33.812089
            ],
            [
              -94.183492,
              33.809992
            ],
            [
              -94.190017,
              33.789216
            ],
            [
              -94.161998,
              33.784132
            ],
            [
              -94.146757,
              33.789743
            ],
            [
              -94.143925,
              33.782071
            ],
            [
              -94.121438,
              33.791212
            ],
            [
              -94.086563,
              33.783244
            ],
            [
              -94.040849,
              33.782046
            ],
            [
              -94.041786,
              33.753247
            ],
            [
              -93.958828,
              33.750994
            ],
            [
              -93.969237,
              33.767777
            ],
            [
              -93.960753,
              33.785712
            ],
            [
              -93.969549,
              33.793537
            ],
            [
              -93.964656,
              33.796154
            ],
            [
              -93.971496,
              33.806641
            ],
            [
              -94.003081,
              33.833522
            ],
            [
              -94.00881,
              33.848401
            ],
            [
              -94.018916,
              33.850318
            ],
            [
              -94.019496,
              33.87247
            ],
            [
              -94.052943,
              33.886082
            ],
            [
              -94.05331,
              33.904213
            ],
            [
              -94.061796,
              33.913616
            ],
            [
              -94.066247,
              33.917588
            ],
            [
              -94.070139,
              33.938786
            ],
            [
              -94.057534,
              33.968219
            ],
            [
              -94.068178,
              33.979637
            ],
            [
              -94.066655,
              33.999235
            ],
            [
              -94.072571,
              34.001562
            ],
            [
              -94.08752,
              34.034279
            ],
            [
              -94.075505,
              34.071845
            ],
            [
              -94.092063,
              34.076549
            ],
            [
              -94.081517,
              34.101708
            ],
            [
              -94.093461,
              34.112589
            ],
            [
              -94.08038,
              34.125718
            ],
            [
              -94.103432,
              34.135653
            ],
            [
              -94.094902,
              34.147395
            ],
            [
              -94.097504,
              34.17698
            ],
            [
              -94.089758,
              34.186835
            ],
            [
              -94.105088,
              34.191261
            ],
            [
              -94.242574,
              34.192542
            ],
            [
              -94.243739,
              34.192501
            ],
            [
              -94.4702923271421,
              34.189864
            ],
            [
              -94.474896,
              34.021877
            ],
            [
              -94.474896,
              34.021838
            ],
            [
              -94.47489594037029,
              34.0217078283334
            ],
            [
              -94.474895,
              34.019655
            ],
            [
              -94.476957,
              33.957365
            ],
            [
              -94.477038,
              33.953838
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "137",
      "COUNTYNS": "00069902",
      "AFFGEOID": "0500000US05137",
      "GEOID": "05137",
      "NAME": "Stone",
      "LSAD": "06",
      "ALAND": 1570579424,
      "AWATER": 7841928,
      "County_state": "Stone,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.416719,
              35.78859
            ],
            [
              -92.418408,
              35.716046
            ],
            [
              -92.24052,
              35.71219
            ],
            [
              -92.032049,
              35.707688
            ],
            [
              -91.837179,
              35.704932
            ],
            [
              -91.836081,
              35.748855
            ],
            [
              -91.865097,
              35.770824
            ],
            [
              -91.870983,
              35.810562
            ],
            [
              -91.849617,
              35.846274
            ],
            [
              -91.849448,
              35.867411
            ],
            [
              -91.866202,
              35.882198
            ],
            [
              -91.896361,
              35.882813
            ],
            [
              -91.936707,
              35.912354
            ],
            [
              -91.95302,
              35.931644
            ],
            [
              -91.968515,
              35.940225
            ],
            [
              -91.981635,
              35.93975
            ],
            [
              -92.004911,
              35.919358
            ],
            [
              -92.027448,
              35.922897
            ],
            [
              -92.029946,
              35.939232
            ],
            [
              -92.047455,
              35.943431
            ],
            [
              -92.079736,
              35.927257
            ],
            [
              -92.112677,
              35.93665
            ],
            [
              -92.110537,
              35.950102
            ],
            [
              -92.085485,
              35.965943
            ],
            [
              -92.085836,
              35.986256
            ],
            [
              -92.099442,
              36.005762
            ],
            [
              -92.091757,
              36.020227
            ],
            [
              -92.063576,
              36.024372
            ],
            [
              -92.057224,
              36.034537
            ],
            [
              -92.074682,
              36.053108
            ],
            [
              -92.11303,
              36.064552
            ],
            [
              -92.125492,
              36.08181
            ],
            [
              -92.139104,
              36.112221
            ],
            [
              -92.154047,
              36.115395
            ],
            [
              -92.17644,
              36.102159
            ],
            [
              -92.18921,
              36.105324
            ],
            [
              -92.19543,
              36.132798
            ],
            [
              -92.197649,
              36.059287
            ],
            [
              -92.304951,
              36.06108
            ],
            [
              -92.30722,
              35.974022
            ],
            [
              -92.414324,
              35.975865
            ],
            [
              -92.416719,
              35.78859
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "071",
      "COUNTYNS": "00066863",
      "AFFGEOID": "0500000US05071",
      "GEOID": "05071",
      "NAME": "Johnson",
      "LSAD": "06",
      "ALAND": 1709103300,
      "AWATER": 59195663,
      "County_state": "Johnson,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.700024,
              35.644545
            ],
            [
              -93.710076,
              35.375242
            ],
            [
              -93.684647,
              35.381042
            ],
            [
              -93.669921,
              35.405551
            ],
            [
              -93.660628,
              35.403884
            ],
            [
              -93.640654,
              35.386215
            ],
            [
              -93.59459,
              35.383688
            ],
            [
              -93.561671,
              35.399646
            ],
            [
              -93.527068,
              35.408418
            ],
            [
              -93.508997,
              35.397687
            ],
            [
              -93.494626,
              35.396949
            ],
            [
              -93.480456,
              35.40517
            ],
            [
              -93.462977,
              35.424175
            ],
            [
              -93.435463,
              35.427871
            ],
            [
              -93.424669,
              35.406911
            ],
            [
              -93.424624,
              35.389206
            ],
            [
              -93.414405,
              35.365254
            ],
            [
              -93.396514,
              35.351264
            ],
            [
              -93.398965,
              35.34443
            ],
            [
              -93.422171,
              35.337412
            ],
            [
              -93.420576,
              35.3281
            ],
            [
              -93.36357,
              35.330704
            ],
            [
              -93.332909,
              35.341423
            ],
            [
              -93.299014,
              35.327425
            ],
            [
              -93.297938,
              35.382797
            ],
            [
              -93.29591,
              35.469915
            ],
            [
              -93.223633,
              35.468593
            ],
            [
              -93.22345,
              35.483094
            ],
            [
              -93.22117,
              35.598938
            ],
            [
              -93.1678,
              35.597695
            ],
            [
              -93.164452,
              35.728831
            ],
            [
              -93.200036,
              35.72998
            ],
            [
              -93.374056,
              35.730231
            ],
            [
              -93.519575,
              35.734422
            ],
            [
              -93.519204,
              35.763521
            ],
            [
              -93.695981,
              35.768649
            ],
            [
              -93.699277,
              35.673337
            ],
            [
              -93.700024,
              35.644545
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "055",
      "COUNTYNS": "00066857",
      "AFFGEOID": "0500000US05055",
      "GEOID": "05055",
      "NAME": "Greene",
      "LSAD": "06",
      "ALAND": 1496205815,
      "AWATER": 4789586,
      "County_state": "Greene,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.854873,
              36.002561
            ],
            [
              -90.858502,
              35.990931
            ],
            [
              -90.841595,
              35.97852
            ],
            [
              -90.852064,
              35.968092
            ],
            [
              -90.665861,
              35.966402
            ],
            [
              -90.603031,
              35.965662
            ],
            [
              -90.585454,
              35.96556
            ],
            [
              -90.496019,
              35.965268
            ],
            [
              -90.460279,
              35.965113
            ],
            [
              -90.387378,
              35.964708
            ],
            [
              -90.37789,
              35.995683
            ],
            [
              -90.35131,
              36.02688
            ],
            [
              -90.339343,
              36.047112
            ],
            [
              -90.333261,
              36.067504
            ],
            [
              -90.318491,
              36.075514
            ],
            [
              -90.319168,
              36.089976
            ],
            [
              -90.29991,
              36.098236
            ],
            [
              -90.294492,
              36.112949
            ],
            [
              -90.278724,
              36.117406
            ],
            [
              -90.255596,
              36.127086
            ],
            [
              -90.235585,
              36.139474
            ],
            [
              -90.23537,
              36.159153
            ],
            [
              -90.220425,
              36.184764
            ],
            [
              -90.209776,
              36.182004
            ],
            [
              -90.200582,
              36.192181
            ],
            [
              -90.1894381168169,
              36.2003064993461
            ],
            [
              -90.190207,
              36.201361
            ],
            [
              -90.32096,
              36.200575
            ],
            [
              -90.31982,
              36.259144
            ],
            [
              -90.469261,
              36.260034
            ],
            [
              -90.715822,
              36.267936
            ],
            [
              -90.806615,
              36.266865
            ],
            [
              -90.809816,
              36.149567
            ],
            [
              -90.76867,
              36.14934
            ],
            [
              -90.74747,
              36.149116
            ],
            [
              -90.748329,
              36.14632
            ],
            [
              -90.763481,
              36.134509
            ],
            [
              -90.768559,
              36.12789
            ],
            [
              -90.77087,
              36.113505
            ],
            [
              -90.790671,
              36.093429
            ],
            [
              -90.820953,
              36.079539
            ],
            [
              -90.825207,
              36.055478
            ],
            [
              -90.839042,
              36.039858
            ],
            [
              -90.837975,
              36.022229
            ],
            [
              -90.854873,
              36.002561
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "035",
      "COUNTYNS": "00069162",
      "AFFGEOID": "0500000US05035",
      "GEOID": "05035",
      "NAME": "Crittenden",
      "LSAD": "06",
      "ALAND": 1580755099,
      "AWATER": 67432622,
      "County_state": "Crittenden,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.503127,
              35.353962
            ],
            [
              -90.506475,
              35.178715
            ],
            [
              -90.502993,
              35.144214
            ],
            [
              -90.400438,
              35.148231
            ],
            [
              -90.407885,
              35.002494
            ],
            [
              -90.40854,
              34.904121
            ],
            [
              -90.4079908833418,
              34.832998481267495
            ],
            [
              -90.401633,
              34.835305
            ],
            [
              -90.379837,
              34.845931
            ],
            [
              -90.352174,
              34.853196
            ],
            [
              -90.348218,
              34.855113
            ],
            [
              -90.34038,
              34.860357
            ],
            [
              -90.332298,
              34.85253
            ],
            [
              -90.323067,
              34.846391
            ],
            [
              -90.319198,
              34.844854
            ],
            [
              -90.311312,
              34.844223
            ],
            [
              -90.307384,
              34.846195
            ],
            [
              -90.303944,
              34.850517
            ],
            [
              -90.302669,
              34.854366
            ],
            [
              -90.302523,
              34.856471
            ],
            [
              -90.303698,
              34.859704
            ],
            [
              -90.3044193945795,
              34.8605888851081
            ],
            [
              -90.313476,
              34.871698
            ],
            [
              -90.310005,
              34.875097
            ],
            [
              -90.301957,
              34.880053
            ],
            [
              -90.279364,
              34.890077
            ],
            [
              -90.250095,
              34.90732
            ],
            [
              -90.248308,
              34.909739
            ],
            [
              -90.246546,
              34.914168
            ],
            [
              -90.244725,
              34.921031
            ],
            [
              -90.244476,
              34.937596
            ],
            [
              -90.246116,
              34.944316
            ],
            [
              -90.247832,
              34.947916
            ],
            [
              -90.250056,
              34.951196
            ],
            [
              -90.253969,
              34.954988
            ],
            [
              -90.259663,
              34.957793
            ],
            [
              -90.27824,
              34.965077
            ],
            [
              -90.282234,
              34.967661
            ],
            [
              -90.287239,
              34.972531
            ],
            [
              -90.293083,
              34.974574
            ],
            [
              -90.296422,
              34.976346
            ],
            [
              -90.302471,
              34.982077
            ],
            [
              -90.304425,
              34.984939
            ],
            [
              -90.309297,
              34.995694
            ],
            [
              -90.310298,
              35.004295
            ],
            [
              -90.309877,
              35.00975
            ],
            [
              -90.306897,
              35.018194
            ],
            [
              -90.300697,
              35.028793
            ],
            [
              -90.297296,
              35.037893
            ],
            [
              -90.295596,
              35.040093
            ],
            [
              -90.29446489589901,
              35.0406271324921
            ],
            [
              -90.291996,
              35.041793
            ],
            [
              -90.265296,
              35.040293
            ],
            [
              -90.263796,
              35.039593
            ],
            [
              -90.263396,
              35.037493
            ],
            [
              -90.262396,
              35.036393
            ],
            [
              -90.256495,
              35.034493
            ],
            [
              -90.230611,
              35.03132
            ],
            [
              -90.224791,
              35.029961
            ],
            [
              -90.216258,
              35.026049
            ],
            [
              -90.214382,
              35.025795
            ],
            [
              -90.209397,
              35.026546
            ],
            [
              -90.200124,
              35.032813
            ],
            [
              -90.196095,
              35.0374
            ],
            [
              -90.19686,
              35.043667
            ],
            [
              -90.197146,
              35.050731
            ],
            [
              -90.196583,
              35.056137
            ],
            [
              -90.195133,
              35.061793
            ],
            [
              -90.181387,
              35.091401
            ],
            [
              -90.176843,
              35.112088
            ],
            [
              -90.173603,
              35.118073
            ],
            [
              -90.17312153527709,
              35.118489299709005
            ],
            [
              -90.165328,
              35.125228
            ],
            [
              -90.160058,
              35.12883
            ],
            [
              -90.155994,
              35.130991
            ],
            [
              -90.144691,
              35.134984
            ],
            [
              -90.142794,
              35.135091
            ],
            [
              -90.139024,
              35.133995
            ],
            [
              -90.109393,
              35.118891
            ],
            [
              -90.100593,
              35.116691
            ],
            [
              -90.09061,
              35.118287
            ],
            [
              -90.08671,
              35.119779
            ],
            [
              -90.08342,
              35.12167
            ],
            [
              -90.071192,
              35.131591
            ],
            [
              -90.066591,
              35.13599
            ],
            [
              -90.065392,
              35.137691
            ],
            [
              -90.064612,
              35.140621
            ],
            [
              -90.066482,
              35.151074
            ],
            [
              -90.066958,
              35.151839
            ],
            [
              -90.069402,
              35.153646
            ],
            [
              -90.090371,
              35.15627
            ],
            [
              -90.092944,
              35.157228
            ],
            [
              -90.096549,
              35.160976
            ],
            [
              -90.099777,
              35.164474
            ],
            [
              -90.103216,
              35.16798
            ],
            [
              -90.105525,
              35.170695
            ],
            [
              -90.108075,
              35.174571
            ],
            [
              -90.109177,
              35.178451
            ],
            [
              -90.111091,
              35.178639
            ],
            [
              -90.114214,
              35.181691
            ],
            [
              -90.117393,
              35.18789
            ],
            [
              -90.117542,
              35.19057
            ],
            [
              -90.116182,
              35.198498
            ],
            [
              -90.109076,
              35.199105
            ],
            [
              -90.097408,
              35.194794
            ],
            [
              -90.096466,
              35.194848
            ],
            [
              -90.093285,
              35.203282
            ],
            [
              -90.088597,
              35.212376
            ],
            [
              -90.08412,
              35.210423
            ],
            [
              -90.081173,
              35.208153
            ],
            [
              -90.07682,
              35.208817
            ],
            [
              -90.074271,
              35.211504
            ],
            [
              -90.074155,
              35.21707
            ],
            [
              -90.07492,
              35.220452
            ],
            [
              -90.076879,
              35.224405
            ],
            [
              -90.07875,
              35.227806
            ],
            [
              -90.082939,
              35.231824
            ],
            [
              -90.086322,
              35.235719
            ],
            [
              -90.090892,
              35.242189
            ],
            [
              -90.097947,
              35.249983
            ],
            [
              -90.09949,
              35.251393
            ],
            [
              -90.105093,
              35.254288
            ],
            [
              -90.110106,
              35.255456
            ],
            [
              -90.116493,
              35.255788
            ],
            [
              -90.123593,
              35.254989
            ],
            [
              -90.132116,
              35.25318
            ],
            [
              -90.140394,
              35.252289
            ],
            [
              -90.152094,
              35.255989
            ],
            [
              -90.158865,
              35.262577
            ],
            [
              -90.166594,
              35.274588
            ],
            [
              -90.168794,
              35.279088
            ],
            [
              -90.168871,
              35.281997
            ],
            [
              -90.165194,
              35.293188
            ],
            [
              -90.163812,
              35.296115
            ],
            [
              -90.161225,
              35.298951
            ],
            [
              -90.158913,
              35.300637
            ],
            [
              -90.153394,
              35.302588
            ],
            [
              -90.149794,
              35.303288
            ],
            [
              -90.139504,
              35.298828
            ],
            [
              -90.132393,
              35.300488
            ],
            [
              -90.123707,
              35.30453
            ],
            [
              -90.121864,
              35.304535
            ],
            [
              -90.117219,
              35.303384
            ],
            [
              -90.109093,
              35.304987
            ],
            [
              -90.106824,
              35.324618
            ],
            [
              -90.103862,
              35.332405
            ],
            [
              -90.110293,
              35.342786
            ],
            [
              -90.108817,
              35.342587
            ],
            [
              -90.107312,
              35.343143
            ],
            [
              -90.100294,
              35.351619
            ],
            [
              -90.096825,
              35.357216
            ],
            [
              -90.093492,
              35.360486
            ],
            [
              -90.090492,
              35.360886
            ],
            [
              -90.087903,
              35.36327
            ],
            [
              -90.083824,
              35.368798
            ],
            [
              -90.074992,
              35.384152
            ],
            [
              -90.077971,
              35.384501
            ],
            [
              -90.07993,
              35.385272
            ],
            [
              -90.087743,
              35.390838
            ],
            [
              -90.093589,
              35.393333
            ],
            [
              -90.09665,
              35.395257
            ],
            [
              -90.104842,
              35.401487
            ],
            [
              -90.106775,
              35.403339
            ],
            [
              -90.110543,
              35.408595
            ],
            [
              -90.112504,
              35.410153
            ],
            [
              -90.116902,
              35.411692
            ],
            [
              -90.130475,
              35.413745
            ],
            [
              -90.135125,
              35.412977
            ],
            [
              -90.137881,
              35.41151
            ],
            [
              -90.14166,
              35.408563
            ],
            [
              -90.143448,
              35.406671
            ],
            [
              -90.145085,
              35.403757
            ],
            [
              -90.146191,
              35.399468
            ],
            [
              -90.14587,
              35.395079
            ],
            [
              -90.143475,
              35.387602
            ],
            [
              -90.13551,
              35.376668
            ],
            [
              -90.144924,
              35.374633
            ],
            [
              -90.166246,
              35.374745
            ],
            [
              -90.172388,
              35.377681
            ],
            [
              -90.178341,
              35.382092
            ],
            [
              -90.179265,
              35.385194
            ],
            [
              -90.1707,
              35.410065
            ],
            [
              -90.170599,
              35.418352
            ],
            [
              -90.169002,
              35.421853
            ],
            [
              -90.1401796054271,
              35.436483531380794
            ],
            [
              -90.286899,
              35.438573
            ],
            [
              -90.340878,
              35.439168
            ],
            [
              -90.502429,
              35.441726
            ],
            [
              -90.503127,
              35.353962
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "079",
      "COUNTYNS": "00066873",
      "AFFGEOID": "0500000US05079",
      "GEOID": "05079",
      "NAME": "Lincoln",
      "LSAD": "06",
      "ALAND": 1454438336,
      "AWATER": 27514921,
      "County_state": "Lincoln,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.976439,
              33.977297
            ],
            [
              -91.975974,
              33.791787
            ],
            [
              -91.839053,
              33.791771
            ],
            [
              -91.834382,
              33.791664
            ],
            [
              -91.769049,
              33.792001
            ],
            [
              -91.663738,
              33.792246
            ],
            [
              -91.557648,
              33.783384
            ],
            [
              -91.555621,
              33.870979
            ],
            [
              -91.55299,
              33.889697
            ],
            [
              -91.550013,
              33.943582
            ],
            [
              -91.548668,
              33.976799
            ],
            [
              -91.465733,
              33.975798
            ],
            [
              -91.465232,
              34.004804
            ],
            [
              -91.422208,
              33.998518
            ],
            [
              -91.421715,
              34.014472
            ],
            [
              -91.434873,
              34.024354
            ],
            [
              -91.435887,
              34.06844
            ],
            [
              -91.445614,
              34.080147
            ],
            [
              -91.46967,
              34.084716
            ],
            [
              -91.505323,
              34.073887
            ],
            [
              -91.521693,
              34.087434
            ],
            [
              -91.531571,
              34.09787
            ],
            [
              -91.551959,
              34.105966
            ],
            [
              -91.569974,
              34.101438
            ],
            [
              -91.58309,
              34.08932
            ],
            [
              -91.602334,
              34.088056
            ],
            [
              -91.609763,
              34.103125
            ],
            [
              -91.602597,
              34.117878
            ],
            [
              -91.582315,
              34.125107
            ],
            [
              -91.589356,
              34.133418
            ],
            [
              -91.614192,
              34.128109
            ],
            [
              -91.64254,
              34.138055
            ],
            [
              -91.693672,
              34.170678
            ],
            [
              -91.712255,
              34.173458
            ],
            [
              -91.727276,
              34.174093
            ],
            [
              -91.726625,
              34.065114
            ],
            [
              -91.744845,
              34.064982
            ],
            [
              -91.74499,
              34.094021
            ],
            [
              -91.849685,
              34.093353
            ],
            [
              -91.942398,
              34.093513
            ],
            [
              -91.953629,
              34.09338
            ],
            [
              -91.953799,
              34.064141
            ],
            [
              -91.953049,
              33.977188
            ],
            [
              -91.976439,
              33.977297
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "089",
      "COUNTYNS": "00069898",
      "AFFGEOID": "0500000US05089",
      "GEOID": "05089",
      "NAME": "Marion",
      "LSAD": "06",
      "ALAND": 1546109507,
      "AWATER": 112285682,
      "County_state": "Marion,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.889404,
              36.18639
            ],
            [
              -92.890695,
              36.114132
            ],
            [
              -92.836859,
              36.113741
            ],
            [
              -92.836531,
              36.070065
            ],
            [
              -92.731224,
              36.067558
            ],
            [
              -92.581538,
              36.065388
            ],
            [
              -92.495998,
              36.063902
            ],
            [
              -92.476754,
              36.063728
            ],
            [
              -92.411502,
              36.062756
            ],
            [
              -92.409175,
              36.134684
            ],
            [
              -92.426399,
              36.149401
            ],
            [
              -92.41664,
              36.14942
            ],
            [
              -92.408559,
              36.152397
            ],
            [
              -92.428384,
              36.163812
            ],
            [
              -92.425948,
              36.17833
            ],
            [
              -92.441124,
              36.164048
            ],
            [
              -92.463731,
              36.179252
            ],
            [
              -92.479112,
              36.193522
            ],
            [
              -92.459386,
              36.214952
            ],
            [
              -92.476487,
              36.227916
            ],
            [
              -92.474487,
              36.256876
            ],
            [
              -92.498976,
              36.258214
            ],
            [
              -92.520581,
              36.268824
            ],
            [
              -92.543577,
              36.263623
            ],
            [
              -92.523404,
              36.298544
            ],
            [
              -92.536576,
              36.308997
            ],
            [
              -92.551566,
              36.313488
            ],
            [
              -92.573696,
              36.306283
            ],
            [
              -92.570707,
              36.322664
            ],
            [
              -92.552962,
              36.336228
            ],
            [
              -92.534048,
              36.329465
            ],
            [
              -92.528057,
              36.344143
            ],
            [
              -92.533642,
              36.350734
            ],
            [
              -92.578057,
              36.342185
            ],
            [
              -92.592268,
              36.358897
            ],
            [
              -92.552585,
              36.379432
            ],
            [
              -92.549599,
              36.386921
            ],
            [
              -92.533175,
              36.386563
            ],
            [
              -92.5291281035939,
              36.498608860563095
            ],
            [
              -92.564238,
              36.49824
            ],
            [
              -92.772333,
              36.497772
            ],
            [
              -92.7723406785747,
              36.497772035561795
            ],
            [
              -92.838621,
              36.498079
            ],
            [
              -92.838876,
              36.498033
            ],
            [
              -92.85404946684739,
              36.4979826282189
            ],
            [
              -92.854693,
              36.464619
            ],
            [
              -92.873666,
              36.475668
            ],
            [
              -92.884445,
              36.490713
            ],
            [
              -92.888215,
              36.333197
            ],
            [
              -92.889628,
              36.318457
            ],
            [
              -92.889404,
              36.18639
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "053",
      "COUNTYNS": "00066856",
      "AFFGEOID": "0500000US05053",
      "GEOID": "05053",
      "NAME": "Grant",
      "LSAD": "06",
      "ALAND": 1636384394,
      "AWATER": 2896901,
      "County_state": "Grant,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.673382,
              34.239347
            ],
            [
              -92.678383,
              34.149622
            ],
            [
              -92.493672,
              34.144234
            ],
            [
              -92.491657,
              34.132936
            ],
            [
              -92.468844,
              34.137472
            ],
            [
              -92.448114,
              34.123052
            ],
            [
              -92.426456,
              34.125359
            ],
            [
              -92.405538,
              34.115413
            ],
            [
              -92.394041,
              34.104489
            ],
            [
              -92.374962,
              34.086481
            ],
            [
              -92.374239,
              34.075039
            ],
            [
              -92.336765,
              34.059633
            ],
            [
              -92.233376,
              34.062312
            ],
            [
              -92.232548,
              34.230822
            ],
            [
              -92.212906,
              34.2304
            ],
            [
              -92.211585,
              34.288821
            ],
            [
              -92.211302,
              34.306493
            ],
            [
              -92.210508,
              34.318074
            ],
            [
              -92.209424,
              34.386644
            ],
            [
              -92.209129,
              34.405612
            ],
            [
              -92.208041,
              34.492765
            ],
            [
              -92.245818,
              34.493506
            ],
            [
              -92.395088,
              34.49564
            ],
            [
              -92.403557,
              34.495795
            ],
            [
              -92.40468,
              34.451916
            ],
            [
              -92.469836,
              34.453149
            ],
            [
              -92.477433,
              34.453294
            ],
            [
              -92.589418,
              34.455641
            ],
            [
              -92.623158,
              34.425775
            ],
            [
              -92.624706,
              34.413147
            ],
            [
              -92.668711,
              34.414237
            ],
            [
              -92.673382,
              34.239347
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "001",
      "COUNTYNS": "00063755",
      "AFFGEOID": "0500000US05001",
      "GEOID": "05001",
      "NAME": "Arkansas",
      "LSAD": "06",
      "ALAND": 2560988637,
      "AWATER": 116409463,
      "County_state": "Arkansas,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.709709,
              34.307764
            ],
            [
              -91.711795,
              34.233919
            ],
            [
              -91.592535,
              34.228761
            ],
            [
              -91.594798,
              34.212203
            ],
            [
              -91.572314,
              34.2228
            ],
            [
              -91.547304,
              34.223019
            ],
            [
              -91.528344,
              34.215127
            ],
            [
              -91.527556,
              34.184077
            ],
            [
              -91.514551,
              34.171392
            ],
            [
              -91.520804,
              34.163681
            ],
            [
              -91.514374,
              34.142293
            ],
            [
              -91.494809,
              34.14898
            ],
            [
              -91.493101,
              34.127254
            ],
            [
              -91.477982,
              34.134873
            ],
            [
              -91.451931,
              34.133865
            ],
            [
              -91.442798,
              34.125989
            ],
            [
              -91.448306,
              34.121195
            ],
            [
              -91.438174,
              34.096786
            ],
            [
              -91.445614,
              34.080147
            ],
            [
              -91.435887,
              34.06844
            ],
            [
              -91.434873,
              34.024354
            ],
            [
              -91.421715,
              34.014472
            ],
            [
              -91.392333,
              33.982255
            ],
            [
              -91.373409,
              33.982214
            ],
            [
              -91.35032,
              34.002299
            ],
            [
              -91.322014,
              34.000101
            ],
            [
              -91.325459,
              33.979488
            ],
            [
              -91.357168,
              33.964132
            ],
            [
              -91.352533,
              33.956535
            ],
            [
              -91.305581,
              33.961311
            ],
            [
              -91.284957,
              33.972907
            ],
            [
              -91.271273,
              33.972204
            ],
            [
              -91.262475,
              33.957284
            ],
            [
              -91.251534,
              33.956981
            ],
            [
              -91.239438,
              33.97376
            ],
            [
              -91.228169,
              33.978018
            ],
            [
              -91.214338,
              33.972403
            ],
            [
              -91.210391,
              33.95509
            ],
            [
              -91.199929,
              33.952994
            ],
            [
              -91.168553,
              33.958151
            ],
            [
              -91.151365,
              33.93751
            ],
            [
              -91.132686,
              33.955221
            ],
            [
              -91.135576,
              33.962043
            ],
            [
              -91.158768,
              33.979124
            ],
            [
              -91.156908,
              33.993563
            ],
            [
              -91.171366,
              34.015503
            ],
            [
              -91.182972,
              34.016663
            ],
            [
              -91.186016,
              34.031638
            ],
            [
              -91.19481,
              34.034472
            ],
            [
              -91.196874,
              34.050207
            ],
            [
              -91.188317,
              34.064007
            ],
            [
              -91.194263,
              34.071993
            ],
            [
              -91.169142,
              34.093933
            ],
            [
              -91.148119,
              34.087824
            ],
            [
              -91.142404,
              34.111301
            ],
            [
              -91.122501,
              34.107916
            ],
            [
              -91.117933,
              34.118952
            ],
            [
              -91.117905,
              34.119105
            ],
            [
              -91.111478,
              34.130303
            ],
            [
              -91.098479,
              34.131313
            ],
            [
              -91.095684,
              34.140803
            ],
            [
              -91.078628,
              34.143729
            ],
            [
              -91.090373,
              34.156187
            ],
            [
              -91.083768,
              34.159356
            ],
            [
              -91.094292,
              34.176856
            ],
            [
              -91.085368,
              34.202224
            ],
            [
              -91.070451,
              34.201384
            ],
            [
              -91.060626,
              34.216766
            ],
            [
              -91.065361,
              34.238601
            ],
            [
              -91.079232,
              34.249844
            ],
            [
              -91.062595,
              34.25494
            ],
            [
              -91.07331,
              34.265737
            ],
            [
              -91.077663,
              34.283322
            ],
            [
              -91.057667,
              34.318501
            ],
            [
              -91.056276,
              34.336605
            ],
            [
              -91.093317,
              34.343963
            ],
            [
              -91.105946,
              34.366026
            ],
            [
              -91.122757,
              34.375786
            ],
            [
              -91.125117,
              34.377829
            ],
            [
              -91.12673,
              34.378745
            ],
            [
              -91.127965,
              34.379227
            ],
            [
              -91.130057,
              34.380246
            ],
            [
              -91.130694,
              34.381128
            ],
            [
              -91.138401,
              34.402784
            ],
            [
              -91.165883,
              34.412064
            ],
            [
              -91.153868,
              34.421946
            ],
            [
              -91.174745,
              34.450443
            ],
            [
              -91.191883,
              34.439746
            ],
            [
              -91.218733,
              34.443822
            ],
            [
              -91.206572,
              34.456154
            ],
            [
              -91.215306,
              34.472844
            ],
            [
              -91.218172,
              34.486906
            ],
            [
              -91.232256,
              34.480714
            ],
            [
              -91.263606,
              34.501867
            ],
            [
              -91.2616,
              34.52717
            ],
            [
              -91.301065,
              34.534011
            ],
            [
              -91.291483,
              34.561761
            ],
            [
              -91.37782,
              34.56348
            ],
            [
              -91.594429,
              34.56805
            ],
            [
              -91.59552,
              34.48051
            ],
            [
              -91.616016,
              34.479537
            ],
            [
              -91.678037,
              34.48192
            ],
            [
              -91.705185,
              34.48267
            ],
            [
              -91.709709,
              34.307764
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "041",
      "COUNTYNS": "00066850",
      "AFFGEOID": "0500000US05041",
      "GEOID": "05041",
      "NAME": "Desha",
      "LSAD": "06",
      "ALAND": 1989461629,
      "AWATER": 133085432,
      "County_state": "Desha,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.555621,
              33.870979
            ],
            [
              -91.557648,
              33.783384
            ],
            [
              -91.500123,
              33.780569
            ],
            [
              -91.450325,
              33.780415
            ],
            [
              -91.453438,
              33.563703
            ],
            [
              -91.34954,
              33.563646
            ],
            [
              -91.35081,
              33.519476
            ],
            [
              -91.346152,
              33.5336
            ],
            [
              -91.333164,
              33.533563
            ],
            [
              -91.324744,
              33.518589
            ],
            [
              -91.24576,
              33.517148
            ],
            [
              -91.244582,
              33.561425
            ],
            [
              -91.2308579778816,
              33.56137194507
            ],
            [
              -91.228489,
              33.564667
            ],
            [
              -91.224121,
              33.567369
            ],
            [
              -91.221466,
              33.568166
            ],
            [
              -91.203151,
              33.570758
            ],
            [
              -91.198285,
              33.572061
            ],
            [
              -91.188942,
              33.576225
            ],
            [
              -91.181068,
              33.58152
            ],
            [
              -91.17822,
              33.582607
            ],
            [
              -91.175979,
              33.582968
            ],
            [
              -91.160755,
              33.581352
            ],
            [
              -91.157429,
              33.581372
            ],
            [
              -91.152148,
              33.582721
            ],
            [
              -91.138418,
              33.590896
            ],
            [
              -91.134043,
              33.594489
            ],
            [
              -91.13245,
              33.596989
            ],
            [
              -91.131588,
              33.59945
            ],
            [
              -91.130445,
              33.606034
            ],
            [
              -91.130902,
              33.610919
            ],
            [
              -91.1329605860014,
              33.6159919651592
            ],
            [
              -91.134389,
              33.619512
            ],
            [
              -91.139209,
              33.625658
            ],
            [
              -91.150499,
              33.633013
            ],
            [
              -91.164212,
              33.643278
            ],
            [
              -91.171168,
              33.647766
            ],
            [
              -91.178311,
              33.651109
            ],
            [
              -91.185455,
              33.653604
            ],
            [
              -91.211284,
              33.658526
            ],
            [
              -91.219048,
              33.661503
            ],
            [
              -91.223001,
              33.664794
            ],
            [
              -91.226537,
              33.668665
            ],
            [
              -91.228228,
              33.671326
            ],
            [
              -91.229015,
              33.677543
            ],
            [
              -91.227857,
              33.683073
            ],
            [
              -91.225279,
              33.687749
            ],
            [
              -91.22057,
              33.692923
            ],
            [
              -91.212077,
              33.698249
            ],
            [
              -91.205377,
              33.700819
            ],
            [
              -91.200712,
              33.701535
            ],
            [
              -91.190113,
              33.70186
            ],
            [
              -91.17573,
              33.703116
            ],
            [
              -91.165846,
              33.705133
            ],
            [
              -91.162464,
              33.70684
            ],
            [
              -91.160866,
              33.707096
            ],
            [
              -91.144188,
              33.689596
            ],
            [
              -91.133416,
              33.67679
            ],
            [
              -91.13045,
              33.674522
            ],
            [
              -91.10098,
              33.660551
            ],
            [
              -91.09404,
              33.658351
            ],
            [
              -91.088202,
              33.657387
            ],
            [
              -91.084126,
              33.657322
            ],
            [
              -91.078507,
              33.658283
            ],
            [
              -91.06711,
              33.662689
            ],
            [
              -91.059182,
              33.6664
            ],
            [
              -91.050523,
              33.668064
            ],
            [
              -91.046412,
              33.668272
            ],
            [
              -91.03684,
              33.671316
            ],
            [
              -91.034565,
              33.673018
            ],
            [
              -91.03146,
              33.678142
            ],
            [
              -91.030332,
              33.681622
            ],
            [
              -91.030402,
              33.687766
            ],
            [
              -91.033366,
              33.688773
            ],
            [
              -91.03612,
              33.689113
            ],
            [
              -91.03715,
              33.692907
            ],
            [
              -91.039025,
              33.696595
            ],
            [
              -91.041261,
              33.699933
            ],
            [
              -91.046778,
              33.706313
            ],
            [
              -91.055562,
              33.712486
            ],
            [
              -91.059891,
              33.714816
            ],
            [
              -91.063752,
              33.715892
            ],
            [
              -91.06829,
              33.716477
            ],
            [
              -91.075389,
              33.714403
            ],
            [
              -91.089873,
              33.707364
            ],
            [
              -91.101101,
              33.705007
            ],
            [
              -91.107646,
              33.704679
            ],
            [
              -91.117733,
              33.705342
            ],
            [
              -91.125527,
              33.70878
            ],
            [
              -91.132338,
              33.714246
            ],
            [
              -91.144732,
              33.726803
            ],
            [
              -91.145792,
              33.728924
            ],
            [
              -91.146618,
              33.732456
            ],
            [
              -91.146523,
              33.736503
            ],
            [
              -91.143287,
              33.747141
            ],
            [
              -91.144539,
              33.749338
            ],
            [
              -91.144571,
              33.751607
            ],
            [
              -91.141553,
              33.755957
            ],
            [
              -91.140756,
              33.759735
            ],
            [
              -91.141304,
              33.760835
            ],
            [
              -91.143634,
              33.762095
            ],
            [
              -91.144812,
              33.763996
            ],
            [
              -91.145112,
              33.76734
            ],
            [
              -91.14201,
              33.77382
            ],
            [
              -91.139869,
              33.777117
            ],
            [
              -91.137351,
              33.779923
            ],
            [
              -91.133854,
              33.782814
            ],
            [
              -91.132185,
              33.78342
            ],
            [
              -91.128222,
              33.783447
            ],
            [
              -91.123466,
              33.782106
            ],
            [
              -91.117836,
              33.779026
            ],
            [
              -91.111494,
              33.774568
            ],
            [
              -91.107318,
              33.770619
            ],
            [
              -91.088996,
              33.775801
            ],
            [
              -91.08551,
              33.77641
            ],
            [
              -91.060524,
              33.77764
            ],
            [
              -91.053886,
              33.778701
            ],
            [
              -91.026782,
              33.763642
            ],
            [
              -91.023285,
              33.762991
            ],
            [
              -91.01277,
              33.764675
            ],
            [
              -91.000106,
              33.769165
            ],
            [
              -90.993842,
              33.773601
            ],
            [
              -90.99122,
              33.776791
            ],
            [
              -90.989444,
              33.780576
            ],
            [
              -90.988466,
              33.78453
            ],
            [
              -90.989299,
              33.788016
            ],
            [
              -90.991747,
              33.792597
            ],
            [
              -91.000107,
              33.799549
            ],
            [
              -91.007767,
              33.802591
            ],
            [
              -91.020098,
              33.804447
            ],
            [
              -91.025173,
              33.805953
            ],
            [
              -91.042448,
              33.812855
            ],
            [
              -91.046849,
              33.815365
            ],
            [
              -91.049679,
              33.818729
            ],
            [
              -91.052819,
              33.824181
            ],
            [
              -91.056692,
              33.828935
            ],
            [
              -91.064977,
              33.837126
            ],
            [
              -91.067511,
              33.840443
            ],
            [
              -91.071195,
              33.849539
            ],
            [
              -91.073011,
              33.857449
            ],
            [
              -91.072798,
              33.862396
            ],
            [
              -91.070883,
              33.866714
            ],
            [
              -91.061247,
              33.877505
            ],
            [
              -91.055309,
              33.883035
            ],
            [
              -91.036674,
              33.898531
            ],
            [
              -91.026382,
              33.90798
            ],
            [
              -91.017481,
              33.919083
            ],
            [
              -91.010831,
              33.925552
            ],
            [
              -91.01004,
              33.927003
            ],
            [
              -91.010318,
              33.929352
            ],
            [
              -91.012994,
              33.932276
            ],
            [
              -91.020097,
              33.937127
            ],
            [
              -91.035961,
              33.943758
            ],
            [
              -91.046725,
              33.947406
            ],
            [
              -91.078496,
              33.95406
            ],
            [
              -91.0790048351099,
              33.954252573959295
            ],
            [
              -91.084095,
              33.956179
            ],
            [
              -91.086758,
              33.95827
            ],
            [
              -91.088696,
              33.961334
            ],
            [
              -91.089787,
              33.966004
            ],
            [
              -91.089756,
              33.969721
            ],
            [
              -91.089119,
              33.972653
            ],
            [
              -91.087921,
              33.975335
            ],
            [
              -91.083187,
              33.979865
            ],
            [
              -91.079254,
              33.982101
            ],
            [
              -91.075378,
              33.983586
            ],
            [
              -91.071203,
              33.984473
            ],
            [
              -91.062264,
              33.985083
            ],
            [
              -91.048367,
              33.985078
            ],
            [
              -91.042751,
              33.986811
            ],
            [
              -91.039589,
              33.989297
            ],
            [
              -91.033765,
              33.995323
            ],
            [
              -91.01889,
              34.003151
            ],
            [
              -91.01361,
              33.994495
            ],
            [
              -91.004981,
              33.977011
            ],
            [
              -91.002986,
              33.970902
            ],
            [
              -91.000108,
              33.966428
            ],
            [
              -90.994856,
              33.963118
            ],
            [
              -90.987653,
              33.960793
            ],
            [
              -90.983359,
              33.960186
            ],
            [
              -90.976864,
              33.960503
            ],
            [
              -90.970856,
              33.961868
            ],
            [
              -90.967632,
              33.963324
            ],
            [
              -90.965187,
              33.965461
            ],
            [
              -90.96372,
              33.967688
            ],
            [
              -90.961222,
              33.976151
            ],
            [
              -90.961548,
              33.979945
            ],
            [
              -90.970947,
              33.991885
            ],
            [
              -90.977489,
              33.996554
            ],
            [
              -90.979945,
              34.000106
            ],
            [
              -90.987948,
              34.019038
            ],
            [
              -90.982742,
              34.020469
            ],
            [
              -90.976918,
              34.021335
            ],
            [
              -90.970726,
              34.02162
            ],
            [
              -90.958456,
              34.020254
            ],
            [
              -90.950311,
              34.017822
            ],
            [
              -90.942662,
              34.01805
            ],
            [
              -90.934896,
              34.019262
            ],
            [
              -90.923745,
              34.023143
            ],
            [
              -90.922017,
              34.023216
            ],
            [
              -90.914642,
              34.021885
            ],
            [
              -90.899467,
              34.023796
            ],
            [
              -90.89242,
              34.02686
            ],
            [
              -90.888956,
              34.029788
            ],
            [
              -90.887413,
              34.032505
            ],
            [
              -90.886991,
              34.035094
            ],
            [
              -90.887394,
              34.039845
            ],
            [
              -90.889058,
              34.046362
            ],
            [
              -90.888627,
              34.052419
            ],
            [
              -90.887837,
              34.055403
            ],
            [
              -90.886351,
              34.058564
            ],
            [
              -90.882115,
              34.063903
            ],
            [
              -90.874541,
              34.072041
            ],
            [
              -90.87194,
              34.076362
            ],
            [
              -90.870528,
              34.080516
            ],
            [
              -90.870461,
              34.082739
            ],
            [
              -90.871923,
              34.086652
            ],
            [
              -90.876606,
              34.092911
            ],
            [
              -90.878912,
              34.094573
            ],
            [
              -90.882628,
              34.096615
            ],
            [
              -90.888085,
              34.09781
            ],
            [
              -90.893526,
              34.097795
            ],
            [
              -90.90113,
              34.094667
            ],
            [
              -90.914066,
              34.092756
            ],
            [
              -90.918395,
              34.093054
            ],
            [
              -90.921273,
              34.093958
            ],
            [
              -90.946323,
              34.109374
            ],
            [
              -90.948514,
              34.111269
            ],
            [
              -90.95500367987549,
              34.1189731025439
            ],
            [
              -91.117905,
              34.119105
            ],
            [
              -91.117933,
              34.118952
            ],
            [
              -91.122501,
              34.107916
            ],
            [
              -91.142404,
              34.111301
            ],
            [
              -91.148119,
              34.087824
            ],
            [
              -91.169142,
              34.093933
            ],
            [
              -91.194263,
              34.071993
            ],
            [
              -91.188317,
              34.064007
            ],
            [
              -91.196874,
              34.050207
            ],
            [
              -91.19481,
              34.034472
            ],
            [
              -91.186016,
              34.031638
            ],
            [
              -91.182972,
              34.016663
            ],
            [
              -91.171366,
              34.015503
            ],
            [
              -91.156908,
              33.993563
            ],
            [
              -91.158768,
              33.979124
            ],
            [
              -91.135576,
              33.962043
            ],
            [
              -91.132686,
              33.955221
            ],
            [
              -91.151365,
              33.93751
            ],
            [
              -91.168553,
              33.958151
            ],
            [
              -91.199929,
              33.952994
            ],
            [
              -91.210391,
              33.95509
            ],
            [
              -91.214338,
              33.972403
            ],
            [
              -91.228169,
              33.978018
            ],
            [
              -91.239438,
              33.97376
            ],
            [
              -91.251534,
              33.956981
            ],
            [
              -91.262475,
              33.957284
            ],
            [
              -91.271273,
              33.972204
            ],
            [
              -91.284957,
              33.972907
            ],
            [
              -91.305581,
              33.961311
            ],
            [
              -91.352533,
              33.956535
            ],
            [
              -91.357168,
              33.964132
            ],
            [
              -91.325459,
              33.979488
            ],
            [
              -91.322014,
              34.000101
            ],
            [
              -91.35032,
              34.002299
            ],
            [
              -91.373409,
              33.982214
            ],
            [
              -91.392333,
              33.982255
            ],
            [
              -91.421715,
              34.014472
            ],
            [
              -91.422208,
              33.998518
            ],
            [
              -91.465232,
              34.004804
            ],
            [
              -91.465733,
              33.975798
            ],
            [
              -91.548668,
              33.976799
            ],
            [
              -91.550013,
              33.943582
            ],
            [
              -91.55299,
              33.889697
            ],
            [
              -91.555621,
              33.870979
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "139",
      "COUNTYNS": "00069903",
      "AFFGEOID": "0500000US05139",
      "GEOID": "05139",
      "NAME": "Union",
      "LSAD": "06",
      "ALAND": 2691555882,
      "AWATER": 41400824,
      "County_state": "Union,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.986389,
              33.097688
            ],
            [
              -92.9887078142573,
              33.0172979025058
            ],
            [
              -92.971137,
              33.017192
            ],
            [
              -92.946553,
              33.016807
            ],
            [
              -92.86751,
              33.016062
            ],
            [
              -92.854167,
              33.016132
            ],
            [
              -92.844286,
              33.01607
            ],
            [
              -92.844073,
              33.016034
            ],
            [
              -92.830798,
              33.015661
            ],
            [
              -92.796533,
              33.014836
            ],
            [
              -92.733197,
              33.014347
            ],
            [
              -92.724994,
              33.014351
            ],
            [
              -92.72474282953719,
              33.0143469910336
            ],
            [
              -92.723553,
              33.014328
            ],
            [
              -92.715884,
              33.014398
            ],
            [
              -92.711289,
              33.014307
            ],
            [
              -92.5297818414117,
              33.0124299399492
            ],
            [
              -92.503776,
              33.012161
            ],
            [
              -92.501383,
              33.01216
            ],
            [
              -92.469762,
              33.01201
            ],
            [
              -92.37029,
              33.010717
            ],
            [
              -92.362865,
              33.010628
            ],
            [
              -92.335893,
              33.010349
            ],
            [
              -92.292664,
              33.010103
            ],
            [
              -92.222825,
              33.00908
            ],
            [
              -92.069105,
              33.008163
            ],
            [
              -92.084516,
              33.031983
            ],
            [
              -92.10848,
              33.031973
            ],
            [
              -92.114393,
              33.07017
            ],
            [
              -92.12938,
              33.070238
            ],
            [
              -92.132663,
              33.096724
            ],
            [
              -92.128849,
              33.11891
            ],
            [
              -92.113205,
              33.132055
            ],
            [
              -92.110748,
              33.157354
            ],
            [
              -92.137527,
              33.162956
            ],
            [
              -92.162593,
              33.171847
            ],
            [
              -92.172507,
              33.188173
            ],
            [
              -92.181934,
              33.188273
            ],
            [
              -92.178971,
              33.212694
            ],
            [
              -92.195517,
              33.201587
            ],
            [
              -92.222362,
              33.209258
            ],
            [
              -92.222462,
              33.222019
            ],
            [
              -92.211924,
              33.223632
            ],
            [
              -92.232283,
              33.23135
            ],
            [
              -92.225105,
              33.253656
            ],
            [
              -92.235909,
              33.258343
            ],
            [
              -92.246723,
              33.250633
            ],
            [
              -92.265749,
              33.254371
            ],
            [
              -92.268737,
              33.264471
            ],
            [
              -92.293377,
              33.26491
            ],
            [
              -92.295644,
              33.280673
            ],
            [
              -92.309121,
              33.265009
            ],
            [
              -92.32646,
              33.262836
            ],
            [
              -92.328784,
              33.279786
            ],
            [
              -92.335557,
              33.27556
            ],
            [
              -92.358892,
              33.282017
            ],
            [
              -92.350432,
              33.298941
            ],
            [
              -92.380601,
              33.278679
            ],
            [
              -92.431092,
              33.26979
            ],
            [
              -92.445399,
              33.274859
            ],
            [
              -92.453522,
              33.287689
            ],
            [
              -92.459962,
              33.283154
            ],
            [
              -92.473336,
              33.294036
            ],
            [
              -92.459274,
              33.303141
            ],
            [
              -92.481174,
              33.327637
            ],
            [
              -92.488597,
              33.315605
            ],
            [
              -92.50422,
              33.326913
            ],
            [
              -92.523728,
              33.316608
            ],
            [
              -92.532989,
              33.356
            ],
            [
              -92.547285,
              33.366058
            ],
            [
              -92.567156,
              33.3672
            ],
            [
              -92.594339,
              33.373724
            ],
            [
              -92.603005,
              33.364303
            ],
            [
              -92.623767,
              33.359088
            ],
            [
              -92.645777,
              33.368365
            ],
            [
              -92.660493,
              33.355603
            ],
            [
              -92.681097,
              33.365215
            ],
            [
              -92.715403,
              33.371001
            ],
            [
              -92.720161,
              33.382242
            ],
            [
              -92.759946,
              33.387748
            ],
            [
              -92.777367,
              33.374902
            ],
            [
              -92.804149,
              33.372715
            ],
            [
              -92.944182,
              33.376441
            ],
            [
              -92.978469,
              33.37728
            ],
            [
              -92.986389,
              33.097688
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "091",
      "COUNTYNS": "00069166",
      "AFFGEOID": "0500000US05091",
      "GEOID": "05091",
      "NAME": "Miller",
      "LSAD": "06",
      "ALAND": 1620232433,
      "AWATER": 31271179,
      "County_state": "Miller,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.04345,
              33.552253
            ],
            [
              -94.043428,
              33.551425
            ],
            [
              -94.043375,
              33.542315
            ],
            [
              -94.043009,
              33.493039
            ],
            [
              -94.0432454539383,
              33.491279607547696
            ],
            [
              -94.043279,
              33.49103
            ],
            [
              -94.043188,
              33.470324
            ],
            [
              -94.042988,
              33.435824
            ],
            [
              -94.042988,
              33.431024
            ],
            [
              -94.042887,
              33.420225
            ],
            [
              -94.04302105190601,
              33.3858972140335
            ],
            [
              -94.043053,
              33.377716
            ],
            [
              -94.042869,
              33.37117
            ],
            [
              -94.043128,
              33.358757
            ],
            [
              -94.043067,
              33.352097
            ],
            [
              -94.043067,
              33.347351
            ],
            [
              -94.043067,
              33.330498
            ],
            [
              -94.0430248501853,
              33.298053043318596
            ],
            [
              -94.04299002100379,
              33.271243167748096
            ],
            [
              -94.04299,
              33.271227
            ],
            [
              -94.04305,
              33.260904
            ],
            [
              -94.043004,
              33.250128
            ],
            [
              -94.04273,
              33.241823
            ],
            [
              -94.042876,
              33.215219
            ],
            [
              -94.042892,
              33.202666
            ],
            [
              -94.042875,
              33.199785
            ],
            [
              -94.042719,
              33.160291
            ],
            [
              -94.043185,
              33.143476
            ],
            [
              -94.043077,
              33.138162
            ],
            [
              -94.043007,
              33.13389
            ],
            [
              -94.04287,
              33.092727
            ],
            [
              -94.043036,
              33.079485
            ],
            [
              -94.042964,
              33.019219
            ],
            [
              -94.041444,
              33.019188
            ],
            [
              -94.035839,
              33.019145
            ],
            [
              -94.027983,
              33.019139
            ],
            [
              -94.024475,
              33.019207
            ],
            [
              -93.814553,
              33.019372
            ],
            [
              -93.804916416422,
              33.0193809735934
            ],
            [
              -93.804112,
              33.02349
            ],
            [
              -93.818683,
              33.03358
            ],
            [
              -93.827753,
              33.058201
            ],
            [
              -93.860094,
              33.091308
            ],
            [
              -93.854198,
              33.11571
            ],
            [
              -93.853623,
              33.13537
            ],
            [
              -93.836669,
              33.147016
            ],
            [
              -93.829779,
              33.139218
            ],
            [
              -93.817304,
              33.139828
            ],
            [
              -93.819675,
              33.144528
            ],
            [
              -93.820165,
              33.156006
            ],
            [
              -93.805977,
              33.162474
            ],
            [
              -93.797196,
              33.165255
            ],
            [
              -93.786497,
              33.153353
            ],
            [
              -93.786069,
              33.144345
            ],
            [
              -93.775585,
              33.149782
            ],
            [
              -93.777259,
              33.158135
            ],
            [
              -93.769007,
              33.166998
            ],
            [
              -93.756711,
              33.1642
            ],
            [
              -93.750177,
              33.158741
            ],
            [
              -93.736327,
              33.15491
            ],
            [
              -93.733962,
              33.155737
            ],
            [
              -93.73215,
              33.162617
            ],
            [
              -93.741389,
              33.166983
            ],
            [
              -93.761081,
              33.170981
            ],
            [
              -93.74717,
              33.181507
            ],
            [
              -93.743539,
              33.17801
            ],
            [
              -93.735355,
              33.173927
            ],
            [
              -93.724846,
              33.178171
            ],
            [
              -93.740241,
              33.192476
            ],
            [
              -93.744648,
              33.199062
            ],
            [
              -93.73645,
              33.209899
            ],
            [
              -93.723385,
              33.2172
            ],
            [
              -93.712739,
              33.220384
            ],
            [
              -93.705815,
              33.223384
            ],
            [
              -93.713386,
              33.22499
            ],
            [
              -93.703373,
              33.245432
            ],
            [
              -93.712152,
              33.262003
            ],
            [
              -93.729337,
              33.279436
            ],
            [
              -93.724017,
              33.28622
            ],
            [
              -93.711611,
              33.281415
            ],
            [
              -93.705204,
              33.27569
            ],
            [
              -93.688612,
              33.277603
            ],
            [
              -93.682734,
              33.290548
            ],
            [
              -93.689074,
              33.302177
            ],
            [
              -93.695672,
              33.316068
            ],
            [
              -93.686212,
              33.319212
            ],
            [
              -93.668363,
              33.334452
            ],
            [
              -93.685562,
              33.341017
            ],
            [
              -93.695712,
              33.34077
            ],
            [
              -93.707984,
              33.353553
            ],
            [
              -93.702507,
              33.361511
            ],
            [
              -93.70269,
              33.368168
            ],
            [
              -93.696215,
              33.373193
            ],
            [
              -93.677418,
              33.373442
            ],
            [
              -93.671,
              33.381166
            ],
            [
              -93.676975,
              33.384814
            ],
            [
              -93.699051,
              33.388069
            ],
            [
              -93.711973,
              33.398272
            ],
            [
              -93.724068,
              33.412007
            ],
            [
              -93.729987,
              33.416221
            ],
            [
              -93.728279,
              33.42572
            ],
            [
              -93.720561,
              33.426828
            ],
            [
              -93.708044,
              33.432886
            ],
            [
              -93.711941,
              33.442536
            ],
            [
              -93.72252,
              33.436579
            ],
            [
              -93.730245,
              33.429383
            ],
            [
              -93.740679,
              33.426824
            ],
            [
              -93.745939,
              33.430599
            ],
            [
              -93.740371,
              33.436538
            ],
            [
              -93.729324,
              33.442351
            ],
            [
              -93.735002,
              33.450394
            ],
            [
              -93.738589,
              33.454804
            ],
            [
              -93.734921,
              33.465192
            ],
            [
              -93.722534,
              33.472764
            ],
            [
              -93.722867,
              33.481963
            ],
            [
              -93.736641,
              33.501435
            ],
            [
              -93.753867,
              33.516391
            ],
            [
              -93.74661,
              33.533431
            ],
            [
              -93.764488,
              33.514532
            ],
            [
              -93.7556,
              33.503252
            ],
            [
              -93.772686,
              33.498513
            ],
            [
              -93.778787,
              33.525986
            ],
            [
              -93.794128,
              33.541077
            ],
            [
              -93.800577,
              33.564678
            ],
            [
              -93.821568,
              33.565798
            ],
            [
              -93.8268,
              33.57674
            ],
            [
              -93.815274,
              33.588554
            ],
            [
              -93.812503,
              33.603351
            ],
            [
              -93.825884,
              33.609853
            ],
            [
              -93.828945,
              33.603136
            ],
            [
              -93.857147,
              33.611239
            ],
            [
              -93.875908,
              33.591075
            ],
            [
              -93.889456,
              33.59273
            ],
            [
              -93.897568,
              33.57184
            ],
            [
              -93.93005,
              33.582819
            ],
            [
              -93.940888,
              33.571962
            ],
            [
              -93.935606,
              33.550176
            ],
            [
              -93.951474,
              33.549282
            ],
            [
              -93.967409,
              33.581052
            ],
            [
              -93.977669,
              33.57649
            ],
            [
              -93.971339,
              33.554501
            ],
            [
              -93.986265,
              33.533673
            ],
            [
              -93.998908,
              33.540265
            ],
            [
              -93.99197,
              33.55825
            ],
            [
              -93.998642,
              33.564382
            ],
            [
              -94.04345,
              33.552253
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "05",
      "COUNTYFP": "101",
      "COUNTYNS": "00069900",
      "AFFGEOID": "0500000US05101",
      "GEOID": "05101",
      "NAME": "Newton",
      "LSAD": "06",
      "ALAND": 2126136365,
      "AWATER": 5930177,
      "County_state": "Newton,05"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.523639,
              35.881592
            ],
            [
              -93.526087,
              35.823611
            ],
            [
              -93.517092,
              35.823333
            ],
            [
              -93.519204,
              35.763521
            ],
            [
              -93.519575,
              35.734422
            ],
            [
              -93.374056,
              35.730231
            ],
            [
              -93.200036,
              35.72998
            ],
            [
              -93.164452,
              35.728831
            ],
            [
              -92.951315,
              35.724663
            ],
            [
              -92.946594,
              35.813037
            ],
            [
              -92.940932,
              35.990069
            ],
            [
              -92.93851,
              36.071726
            ],
            [
              -92.945543,
              36.071894
            ],
            [
              -92.944666,
              36.115123
            ],
            [
              -92.95345,
              36.115662
            ],
            [
              -93.049421,
              36.116352
            ],
            [
              -93.08562,
              36.119352
            ],
            [
              -93.14032,
              36.11755
            ],
            [
              -93.265157,
              36.120315
            ],
            [
              -93.30082,
              36.12155
            ],
            [
              -93.444504,
              36.124293
            ],
            [
              -93.479528,
              36.125072
            ],
            [
              -93.48022,
              36.081425
            ],
            [
              -93.445619,
              36.080749
            ],
            [
              -93.448183,
              36.053506
            ],
            [
              -93.450715,
              35.967493
            ],
            [
              -93.468003,
              35.967545
            ],
            [
              -93.46839,
              35.953088
            ],
            [
              -93.486138,
              35.953299
            ],
            [
              -93.486864,
              35.924235
            ],
            [
              -93.504925,
              35.925188
            ],
            [
              -93.50601,
              35.88108
            ],
            [
              -93.523639,
              35.881592
            ]
          ]
        ]
      ]
    }
  }
]
};