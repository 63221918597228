export const counties_tx = {
  "type":"FeatureCollection","name":"tx_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "471",
      "COUNTYNS": "01384021",
      "AFFGEOID": "0500000US48471",
      "GEOID": "48471",
      "NAME": "Walker",
      "LSAD": "06",
      "ALAND": 2031033548,
      "AWATER": 44838548,
      "County_state": "Walker,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.843165,
              30.861867
            ],
            [
              -95.863059,
              30.864125
            ],
            [
              -95.83024,
              30.630284
            ],
            [
              -95.598971,
              30.509002
            ],
            [
              -95.361866,
              30.508916
            ],
            [
              -95.359156,
              30.504369
            ],
            [
              -95.32748,
              30.859547
            ],
            [
              -95.345335,
              30.875335
            ],
            [
              -95.352072,
              30.856951
            ],
            [
              -95.383279,
              30.852961
            ],
            [
              -95.396697,
              30.857706
            ],
            [
              -95.391592,
              30.875188
            ],
            [
              -95.397175,
              30.883244
            ],
            [
              -95.380232,
              30.880079
            ],
            [
              -95.381253,
              30.887062
            ],
            [
              -95.398108,
              30.891939
            ],
            [
              -95.415153,
              30.909572
            ],
            [
              -95.410077,
              30.927695
            ],
            [
              -95.434335,
              30.937405
            ],
            [
              -95.434781,
              31.058098
            ],
            [
              -95.617872,
              30.930418
            ],
            [
              -95.616233,
              30.910196
            ],
            [
              -95.629597,
              30.900084
            ],
            [
              -95.654501,
              30.89324
            ],
            [
              -95.663811,
              30.904119
            ],
            [
              -95.686855,
              30.90097
            ],
            [
              -95.716292,
              30.911454
            ],
            [
              -95.733388,
              30.900056
            ],
            [
              -95.76493,
              30.896328
            ],
            [
              -95.784532,
              30.879307
            ],
            [
              -95.843165,
              30.861867
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "047",
      "COUNTYNS": "01383809",
      "AFFGEOID": "0500000US48047",
      "GEOID": "48047",
      "NAME": "Brooks",
      "LSAD": "06",
      "ALAND": 2443277016,
      "AWATER": 741627,
      "County_state": "Brooks,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.536646,
              27.265196
            ],
            [
              -98.531575,
              27.254796
            ],
            [
              -98.510897,
              27.249997
            ],
            [
              -98.506168,
              27.238729
            ],
            [
              -98.492943,
              27.235012
            ],
            [
              -98.49291,
              27.142062
            ],
            [
              -98.467284,
              27.142006
            ],
            [
              -98.466542,
              27.054711
            ],
            [
              -98.417883,
              27.055285
            ],
            [
              -98.422616,
              26.783535
            ],
            [
              -98.32067,
              26.783081
            ],
            [
              -97.985494,
              26.780917
            ],
            [
              -97.985887,
              27.209308
            ],
            [
              -97.986076,
              27.260494
            ],
            [
              -98.058078,
              27.260981
            ],
            [
              -98.232464,
              27.262487
            ],
            [
              -98.523284,
              27.26515
            ],
            [
              -98.536646,
              27.265196
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "117",
      "COUNTYNS": "01383845",
      "AFFGEOID": "0500000US48117",
      "GEOID": "48117",
      "NAME": "Deaf Smith",
      "LSAD": "06",
      "ALAND": 3876750570,
      "AWATER": 3926557,
      "County_state": "Deaf Smith,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.043261,
              35.125058
            ],
            [
              -103.042642,
              35.109913
            ],
            [
              -103.042552402864,
              34.9541420854709
            ],
            [
              -103.042521,
              34.899546
            ],
            [
              -103.042781,
              34.850243
            ],
            [
              -103.04277,
              34.792224
            ],
            [
              -103.042769,
              34.747361
            ],
            [
              -102.525184,
              34.746931
            ],
            [
              -102.176433,
              34.746679
            ],
            [
              -102.168839,
              34.747417
            ],
            [
              -102.167471,
              35.183233
            ],
            [
              -103.042377029193,
              35.1831559850696
            ],
            [
              -103.042377,
              35.183149
            ],
            [
              -103.042366,
              35.182786
            ],
            [
              -103.042339,
              35.181922
            ],
            [
              -103.042395,
              35.178573
            ],
            [
              -103.042568,
              35.159318
            ],
            [
              -103.042711,
              35.144735
            ],
            [
              -103.0426,
              35.142766
            ],
            [
              -103.04252,
              35.135596
            ],
            [
              -103.043261,
              35.125058
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "219",
      "COUNTYNS": "01383895",
      "AFFGEOID": "0500000US48219",
      "GEOID": "48219",
      "NAME": "Hockley",
      "LSAD": "06",
      "ALAND": 2352723906,
      "AWATER": 446119,
      "County_state": "Hockley,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.615447,
              33.825121
            ],
            [
              -102.594836,
              33.388489
            ],
            [
              -102.075929,
              33.389586
            ],
            [
              -102.085733,
              33.824675
            ],
            [
              -102.615447,
              33.825121
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "417",
      "COUNTYNS": "01383994",
      "AFFGEOID": "0500000US48417",
      "GEOID": "48417",
      "NAME": "Shackelford",
      "LSAD": "06",
      "ALAND": 2367989256,
      "AWATER": 3268209,
      "County_state": "Shackelford,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.612001,
              32.956962
            ],
            [
              -99.612026,
              32.514654
            ],
            [
              -99.114293,
              32.514811
            ],
            [
              -99.09605,
              32.514768
            ],
            [
              -99.095956,
              32.707581
            ],
            [
              -99.096016,
              32.957037
            ],
            [
              -99.471258,
              32.957025
            ],
            [
              -99.612001,
              32.956962
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "451",
      "COUNTYNS": "01384011",
      "AFFGEOID": "0500000US48451",
      "GEOID": "48451",
      "NAME": "Tom Green",
      "LSAD": "06",
      "ALAND": 3941965427,
      "AWATER": 48077315,
      "County_state": "Tom Green,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.26763,
              31.556462
            ],
            [
              -101.267947,
              31.528688
            ],
            [
              -100.693065,
              31.523954
            ],
            [
              -100.688764,
              31.086576
            ],
            [
              -100.682061,
              31.086219
            ],
            [
              -100.115216,
              31.087994
            ],
            [
              -100.111234,
              31.580267
            ],
            [
              -100.233783,
              31.582196
            ],
            [
              -100.235761,
              31.69297
            ],
            [
              -100.82537,
              31.696155
            ],
            [
              -100.861099,
              31.705418
            ],
            [
              -100.865256,
              31.562294
            ],
            [
              -101.26763,
              31.556462
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "497",
      "COUNTYNS": "01384034",
      "AFFGEOID": "0500000US48497",
      "GEOID": "48497",
      "NAME": "Wise",
      "LSAD": "06",
      "ALAND": 2342363525,
      "AWATER": 47038989,
      "County_state": "Wise,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.918189,
              33.433873
            ],
            [
              -97.921642,
              33.001284
            ],
            [
              -97.679565,
              32.996373
            ],
            [
              -97.548599,
              32.994825
            ],
            [
              -97.544181,
              32.994177
            ],
            [
              -97.415462,
              32.992048
            ],
            [
              -97.39848,
              32.990839
            ],
            [
              -97.39267,
              33.183509
            ],
            [
              -97.391606,
              33.218935
            ],
            [
              -97.383095,
              33.43045
            ],
            [
              -97.385897,
              33.432917
            ],
            [
              -97.487065,
              33.433681
            ],
            [
              -97.918189,
              33.433873
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "101",
      "COUNTYNS": "01383836",
      "AFFGEOID": "0500000US48101",
      "GEOID": "48101",
      "NAME": "Cottle",
      "LSAD": "06",
      "ALAND": 2332448739,
      "AWATER": 2723996,
      "County_state": "Cottle,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.51734,
              34.314102
            ],
            [
              -100.518691,
              33.83565
            ],
            [
              -100.04848,
              33.835974
            ],
            [
              -100.047028,
              34.22978
            ],
            [
              -100.037319,
              34.238778
            ],
            [
              -100.009793,
              34.241955
            ],
            [
              -100.006052,
              34.225668
            ],
            [
              -99.997621,
              34.224355
            ],
            [
              -99.99772,
              34.311829
            ],
            [
              -100.417783,
              34.313524
            ],
            [
              -100.498193,
              34.313527
            ],
            [
              -100.51734,
              34.314102
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "441",
      "COUNTYNS": "01384006",
      "AFFGEOID": "0500000US48441",
      "GEOID": "48441",
      "NAME": "Taylor",
      "LSAD": "06",
      "ALAND": 2371267408,
      "AWATER": 9721167,
      "County_state": "Taylor,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.148609,
              32.404941
            ],
            [
              -100.151911,
              32.082638
            ],
            [
              -99.713971,
              32.082089
            ],
            [
              -99.631413,
              32.081274
            ],
            [
              -99.6298,
              32.436774
            ],
            [
              -99.629579,
              32.514657
            ],
            [
              -99.629639,
              32.520713
            ],
            [
              -100.146543,
              32.522793
            ],
            [
              -100.148609,
              32.404941
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "011",
      "COUNTYNS": "01383791",
      "AFFGEOID": "0500000US48011",
      "GEOID": "48011",
      "NAME": "Armstrong",
      "LSAD": "06",
      "ALAND": 2354581675,
      "AWATER": 12219587,
      "County_state": "Armstrong,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.622941,
              35.183117
            ],
            [
              -101.629257,
              34.747649
            ],
            [
              -101.471562,
              34.747462
            ],
            [
              -101.090749,
              34.748246
            ],
            [
              -101.086281,
              35.18214
            ],
            [
              -101.622941,
              35.183117
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "233",
      "COUNTYNS": "01383902",
      "AFFGEOID": "0500000US48233",
      "GEOID": "48233",
      "NAME": "Hutchinson",
      "LSAD": "06",
      "ALAND": 2298408148,
      "AWATER": 19507501,
      "County_state": "Hutchinson,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.623466,
              36.055405
            ],
            [
              -101.622831,
              35.624057
            ],
            [
              -101.086068,
              35.625267
            ],
            [
              -101.085735,
              36.055276
            ],
            [
              -101.623466,
              36.055405
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "235",
      "COUNTYNS": "01383903",
      "AFFGEOID": "0500000US48235",
      "GEOID": "48235",
      "NAME": "Irion",
      "LSAD": "06",
      "ALAND": 2723485996,
      "AWATER": 176317,
      "County_state": "Irion,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.267947,
              31.528688
            ],
            [
              -101.274799,
              31.079379
            ],
            [
              -100.962176,
              31.08249
            ],
            [
              -100.688764,
              31.086576
            ],
            [
              -100.693065,
              31.523954
            ],
            [
              -101.267947,
              31.528688
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "335",
      "COUNTYNS": "01383953",
      "AFFGEOID": "0500000US48335",
      "GEOID": "48335",
      "NAME": "Mitchell",
      "LSAD": "06",
      "ALAND": 2359710933,
      "AWATER": 12489774,
      "County_state": "Mitchell,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.174571,
              32.524112
            ],
            [
              -101.183997,
              32.087208
            ],
            [
              -100.821594,
              32.08661
            ],
            [
              -100.665353,
              32.085407
            ],
            [
              -100.660626,
              32.525312
            ],
            [
              -101.174562,
              32.527703
            ],
            [
              -101.174571,
              32.524112
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "501",
      "COUNTYNS": "01384036",
      "AFFGEOID": "0500000US48501",
      "GEOID": "48501",
      "NAME": "Yoakum",
      "LSAD": "06",
      "ALAND": 2071252452,
      "AWATER": 34329,
      "County_state": "Yoakum,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.064679,
              32.964373
            ],
            [
              -103.064657009318,
              32.9590992345529
            ],
            [
              -102.595023,
              32.958831
            ],
            [
              -102.594836,
              33.388489
            ],
            [
              -103.05665530475301,
              33.3884164031742
            ],
            [
              -103.057487,
              33.329477
            ],
            [
              -103.057856,
              33.315234
            ],
            [
              -103.059242,
              33.260371
            ],
            [
              -103.05972,
              33.256262
            ],
            [
              -103.060103,
              33.219225
            ],
            [
              -103.061784167753,
              33.14088399770589
            ],
            [
              -103.063905,
              33.042055
            ],
            [
              -103.06398,
              33.038693
            ],
            [
              -103.064452,
              33.01029
            ],
            [
              -103.064625,
              32.999899
            ],
            [
              -103.064679,
              32.964373
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "075",
      "COUNTYNS": "01383823",
      "AFFGEOID": "0500000US48075",
      "GEOID": "48075",
      "NAME": "Childress",
      "LSAD": "06",
      "ALAND": 1803680231,
      "AWATER": 44835076,
      "County_state": "Childress,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.415895,
              34.747525
            ],
            [
              -100.417783,
              34.313524
            ],
            [
              -99.99772,
              34.311829
            ],
            [
              -99.9975014109467,
              34.5604238870434
            ],
            [
              -100.000381,
              34.560509
            ],
            [
              -100.000381,
              34.746358
            ],
            [
              -100.415895,
              34.747525
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "171",
      "COUNTYNS": "01383871",
      "AFFGEOID": "0500000US48171",
      "GEOID": "48171",
      "NAME": "Gillespie",
      "LSAD": "06",
      "ALAND": 2740716834,
      "AWATER": 9015052,
      "County_state": "Gillespie,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.303996,
              30.499832
            ],
            [
              -99.301718,
              30.286653
            ],
            [
              -99.300988,
              30.134298
            ],
            [
              -98.920147,
              30.13829
            ],
            [
              -98.587897,
              30.138954
            ],
            [
              -98.59167,
              30.499875
            ],
            [
              -98.964232,
              30.498482
            ],
            [
              -99.303996,
              30.499832
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "125",
      "COUNTYNS": "01383848",
      "AFFGEOID": "0500000US48125",
      "GEOID": "48125",
      "NAME": "Dickens",
      "LSAD": "06",
      "ALAND": 2335454690,
      "AWATER": 9057848,
      "County_state": "Dickens,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.041158,
              33.833625
            ],
            [
              -101.038788,
              33.397214
            ],
            [
              -100.517449,
              33.397866
            ],
            [
              -100.518691,
              33.83565
            ],
            [
              -101.041158,
              33.833625
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "283",
      "COUNTYNS": "01383924",
      "AFFGEOID": "0500000US48283",
      "GEOID": "48283",
      "NAME": "La Salle",
      "LSAD": "06",
      "ALAND": 3850545746,
      "AWATER": 19503743,
      "County_state": "La Salle,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.395736,
              28.640784
            ],
            [
              -99.395672,
              28.252278
            ],
            [
              -99.394177,
              28.204627
            ],
            [
              -99.393972,
              28.197495
            ],
            [
              -99.388946,
              28.030419
            ],
            [
              -98.803325,
              28.05748
            ],
            [
              -98.800082,
              28.202333
            ],
            [
              -98.801645,
              28.625291
            ],
            [
              -98.800848,
              28.647306
            ],
            [
              -98.800841,
              28.647487
            ],
            [
              -99.395736,
              28.640784
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "195",
      "COUNTYNS": "01383883",
      "AFFGEOID": "0500000US48195",
      "GEOID": "48195",
      "NAME": "Hansford",
      "LSAD": "06",
      "ALAND": 2382296649,
      "AWATER": 1548341,
      "County_state": "Hansford,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.623915,
              36.499528
            ],
            [
              -101.623466,
              36.055405
            ],
            [
              -101.085735,
              36.055276
            ],
            [
              -101.085716,
              36.057572
            ],
            [
              -101.085156,
              36.499244
            ],
            [
              -101.623915,
              36.499528
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "375",
      "COUNTYNS": "01383973",
      "AFFGEOID": "0500000US48375",
      "GEOID": "48375",
      "NAME": "Potter",
      "LSAD": "06",
      "ALAND": 2352751873,
      "AWATER": 35190086,
      "County_state": "Potter,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.162752,
              35.620035
            ],
            [
              -102.167471,
              35.183233
            ],
            [
              -101.622941,
              35.183117
            ],
            [
              -101.6228,
              35.620196
            ],
            [
              -102.162752,
              35.620035
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "145",
      "COUNTYNS": "01383858",
      "AFFGEOID": "0500000US48145",
      "GEOID": "48145",
      "NAME": "Falls",
      "LSAD": "06",
      "ALAND": 1982544974,
      "AWATER": 21693803,
      "County_state": "Falls,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.278113,
              31.279799
            ],
            [
              -97.070188,
              30.98622
            ],
            [
              -96.948831,
              31.048441
            ],
            [
              -96.911558,
              31.068979
            ],
            [
              -96.829621,
              31.110545
            ],
            [
              -96.829121,
              31.105871
            ],
            [
              -96.596862,
              31.2209
            ],
            [
              -96.801123,
              31.522269
            ],
            [
              -96.870228,
              31.486734
            ],
            [
              -97.278113,
              31.279799
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "193",
      "COUNTYNS": "01383882",
      "AFFGEOID": "0500000US48193",
      "GEOID": "48193",
      "NAME": "Hamilton",
      "LSAD": "06",
      "ALAND": 2165008743,
      "AWATER": 1238628,
      "County_state": "Hamilton,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.463736,
              31.683989
            ],
            [
              -98.27107,
              31.416398
            ],
            [
              -98.180006,
              31.463717
            ],
            [
              -97.766431,
              31.672461
            ],
            [
              -98.005462,
              32.017897
            ],
            [
              -98.208386,
              31.917511
            ],
            [
              -98.156568,
              31.841713
            ],
            [
              -98.463736,
              31.683989
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "399",
      "COUNTYNS": "01383985",
      "AFFGEOID": "0500000US48399",
      "GEOID": "48399",
      "NAME": "Runnels",
      "LSAD": "06",
      "ALAND": 2721934030,
      "AWATER": 15930084,
      "County_state": "Runnels,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.235137,
              32.082372
            ],
            [
              -100.235761,
              31.69297
            ],
            [
              -100.233783,
              31.582196
            ],
            [
              -100.111234,
              31.580267
            ],
            [
              -99.721698,
              31.576759
            ],
            [
              -99.713971,
              32.082089
            ],
            [
              -100.151911,
              32.082638
            ],
            [
              -100.235137,
              32.082372
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "189",
      "COUNTYNS": "01383880",
      "AFFGEOID": "0500000US48189",
      "GEOID": "48189",
      "NAME": "Hale",
      "LSAD": "06",
      "ALAND": 2602109431,
      "AWATER": 246678,
      "County_state": "Hale,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.090415,
              34.313132
            ],
            [
              -102.085733,
              33.824675
            ],
            [
              -101.56358,
              33.830451
            ],
            [
              -101.564563,
              34.074228
            ],
            [
              -101.564856,
              34.312493
            ],
            [
              -101.99802,
              34.313039
            ],
            [
              -102.090415,
              34.313132
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "407",
      "COUNTYNS": "01383989",
      "AFFGEOID": "0500000US48407",
      "GEOID": "48407",
      "NAME": "San Jacinto",
      "LSAD": "06",
      "ALAND": 1474332262,
      "AWATER": 151949236,
      "County_state": "San Jacinto,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.359156,
              30.504369
            ],
            [
              -95.350705,
              30.476057
            ],
            [
              -95.314103,
              30.412369
            ],
            [
              -95.308395,
              30.40575
            ],
            [
              -95.260709,
              30.383446
            ],
            [
              -95.235888,
              30.379073
            ],
            [
              -95.217984,
              30.356242
            ],
            [
              -95.203324,
              30.347861
            ],
            [
              -95.226571,
              30.327145
            ],
            [
              -95.226139,
              30.31954
            ],
            [
              -95.165897,
              30.344976
            ],
            [
              -94.842621,
              30.487458
            ],
            [
              -94.849414,
              30.493553
            ],
            [
              -94.865917,
              30.495998
            ],
            [
              -94.867393,
              30.510755
            ],
            [
              -94.852581,
              30.524354
            ],
            [
              -94.833374,
              30.530522
            ],
            [
              -94.829984,
              30.541112
            ],
            [
              -94.842876,
              30.546152
            ],
            [
              -94.875209,
              30.546067
            ],
            [
              -94.889567,
              30.556361
            ],
            [
              -94.910452,
              30.561514
            ],
            [
              -94.940266,
              30.55947
            ],
            [
              -94.950297,
              30.571014
            ],
            [
              -94.965729,
              30.576586
            ],
            [
              -95.003474,
              30.580151
            ],
            [
              -95.029744,
              30.579138
            ],
            [
              -95.035491,
              30.585647
            ],
            [
              -95.008529,
              30.606106
            ],
            [
              -95.00895,
              30.627699
            ],
            [
              -95.015874,
              30.627077
            ],
            [
              -95.024663,
              30.649779
            ],
            [
              -95.009112,
              30.65456
            ],
            [
              -95.041038,
              30.663766
            ],
            [
              -95.038606,
              30.675766
            ],
            [
              -95.053638,
              30.671241
            ],
            [
              -95.054602,
              30.680173
            ],
            [
              -95.079278,
              30.669415
            ],
            [
              -95.088352,
              30.672062
            ],
            [
              -95.104841,
              30.657878
            ],
            [
              -95.11625,
              30.66426
            ],
            [
              -95.118919,
              30.675632
            ],
            [
              -95.10955,
              30.692748
            ],
            [
              -95.135384,
              30.693816
            ],
            [
              -95.145426,
              30.713799
            ],
            [
              -95.144771,
              30.744036
            ],
            [
              -95.131306,
              30.759421
            ],
            [
              -95.139382,
              30.776197
            ],
            [
              -95.131205,
              30.781703
            ],
            [
              -95.156846,
              30.800427
            ],
            [
              -95.17404,
              30.807583
            ],
            [
              -95.180374,
              30.818426
            ],
            [
              -95.20018,
              30.824566
            ],
            [
              -95.230989,
              30.841149
            ],
            [
              -95.243097,
              30.866856
            ],
            [
              -95.230777,
              30.887007
            ],
            [
              -95.252385,
              30.906719
            ],
            [
              -95.312898,
              30.897873
            ],
            [
              -95.313903,
              30.873101
            ],
            [
              -95.32748,
              30.859547
            ],
            [
              -95.359156,
              30.504369
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "131",
      "COUNTYNS": "01383851",
      "AFFGEOID": "0500000US48131",
      "GEOID": "48131",
      "NAME": "Duval",
      "LSAD": "06",
      "ALAND": 4645085813,
      "AWATER": 5478267,
      "County_state": "Duval,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.803325,
              28.05748
            ],
            [
              -98.798323,
              27.354029
            ],
            [
              -98.749377,
              27.35667
            ],
            [
              -98.625027,
              27.357727
            ],
            [
              -98.624929,
              27.338067
            ],
            [
              -98.589478,
              27.338236
            ],
            [
              -98.589537,
              27.358695
            ],
            [
              -98.552617,
              27.359101
            ],
            [
              -98.552448,
              27.343764
            ],
            [
              -98.522293,
              27.343284
            ],
            [
              -98.523284,
              27.26515
            ],
            [
              -98.232464,
              27.262487
            ],
            [
              -98.235497,
              28.057966
            ],
            [
              -98.334323,
              28.0578
            ],
            [
              -98.803325,
              28.05748
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "341",
      "COUNTYNS": "01383956",
      "AFFGEOID": "0500000US48341",
      "GEOID": "48341",
      "NAME": "Moore",
      "LSAD": "06",
      "ALAND": 2330188755,
      "AWATER": 25718180,
      "County_state": "Moore,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.163015,
              36.055249
            ],
            [
              -102.162809,
              35.627515
            ],
            [
              -102.162752,
              35.620035
            ],
            [
              -101.6228,
              35.620196
            ],
            [
              -101.622831,
              35.624057
            ],
            [
              -101.623466,
              36.055405
            ],
            [
              -102.163015,
              36.055249
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "317",
      "COUNTYNS": "01383941",
      "AFFGEOID": "0500000US48317",
      "GEOID": "48317",
      "NAME": "Martin",
      "LSAD": "06",
      "ALAND": 2369724595,
      "AWATER": 1931832,
      "County_state": "Martin,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.211037,
              32.523244
            ],
            [
              -102.211249,
              32.0868
            ],
            [
              -101.776085,
              32.086925
            ],
            [
              -101.695011,
              32.087532
            ],
            [
              -101.68874,
              32.525222
            ],
            [
              -102.202703,
              32.523271
            ],
            [
              -102.211037,
              32.523244
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "293",
      "COUNTYNS": "01383932",
      "AFFGEOID": "0500000US48293",
      "GEOID": "48293",
      "NAME": "Limestone",
      "LSAD": "06",
      "ALAND": 2344685542,
      "AWATER": 72197848,
      "County_state": "Limestone,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.932215,
              31.708887
            ],
            [
              -96.801123,
              31.522269
            ],
            [
              -96.596862,
              31.2209
            ],
            [
              -96.319165,
              31.357129
            ],
            [
              -96.322853,
              31.374067
            ],
            [
              -96.236629,
              31.413392
            ],
            [
              -96.496713,
              31.796191
            ],
            [
              -96.719114,
              31.814887
            ],
            [
              -96.932215,
              31.708887
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "507",
      "COUNTYNS": "01384039",
      "AFFGEOID": "0500000US48507",
      "GEOID": "48507",
      "NAME": "Zavala",
      "LSAD": "06",
      "ALAND": 3360268880,
      "AWATER": 11208707,
      "County_state": "Zavala,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.111406,
              29.086318
            ],
            [
              -100.114336,
              28.648122
            ],
            [
              -99.526761,
              28.642573
            ],
            [
              -99.493381,
              28.641336
            ],
            [
              -99.409021,
              28.640566
            ],
            [
              -99.41388,
              29.091335
            ],
            [
              -100.111406,
              29.086318
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "079",
      "COUNTYNS": "01383825",
      "AFFGEOID": "0500000US48079",
      "GEOID": "48079",
      "NAME": "Cochran",
      "LSAD": "06",
      "ALAND": 2007453829,
      "AWATER": 229068,
      "County_state": "Cochran,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.056655,
              33.388438
            ],
            [
              -103.05665530475301,
              33.3884164031742
            ],
            [
              -102.594836,
              33.388489
            ],
            [
              -102.615447,
              33.825121
            ],
            [
              -103.04734601957101,
              33.8246741231489
            ],
            [
              -103.049096,
              33.74627
            ],
            [
              -103.049608,
              33.737766
            ],
            [
              -103.050148,
              33.701971
            ],
            [
              -103.050532,
              33.672408
            ],
            [
              -103.051087,
              33.658186
            ],
            [
              -103.051535,
              33.650487
            ],
            [
              -103.051363,
              33.64195
            ],
            [
              -103.051664,
              33.629489
            ],
            [
              -103.05261,
              33.570599
            ],
            [
              -103.052610538094,
              33.5705747676868
            ],
            [
              -103.056655,
              33.388438
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "151",
      "COUNTYNS": "01383861",
      "AFFGEOID": "0500000US48151",
      "GEOID": "48151",
      "NAME": "Fisher",
      "LSAD": "06",
      "ALAND": 2328246701,
      "AWATER": 7283897,
      "County_state": "Fisher,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.65587,
              32.963469
            ],
            [
              -100.660626,
              32.525312
            ],
            [
              -100.146543,
              32.522793
            ],
            [
              -100.144224,
              32.959978
            ],
            [
              -100.519208,
              32.962926
            ],
            [
              -100.65587,
              32.963469
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "023",
      "COUNTYNS": "01383797",
      "AFFGEOID": "0500000US48023",
      "GEOID": "48023",
      "NAME": "Baylor",
      "LSAD": "06",
      "ALAND": 2246768188,
      "AWATER": 86991036,
      "County_state": "Baylor,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.47565,
              33.834005
            ],
            [
              -99.474498,
              33.733849
            ],
            [
              -99.472444,
              33.399023
            ],
            [
              -98.953938,
              33.397534
            ],
            [
              -98.953088,
              33.834
            ],
            [
              -99.47565,
              33.834005
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "365",
      "COUNTYNS": "01383968",
      "AFFGEOID": "0500000US48365",
      "GEOID": "48365",
      "NAME": "Panola",
      "LSAD": "06",
      "ALAND": 2101400666,
      "AWATER": 25810622,
      "County_state": "Panola,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.60156,
              32.079971
            ],
            [
              -94.599978,
              31.973193
            ],
            [
              -94.511431,
              31.973984
            ],
            [
              -94.0171,
              31.979837
            ],
            [
              -94.0127962602173,
              31.9816683460243
            ],
            [
              -94.018664,
              31.990843
            ],
            [
              -94.029283,
              31.995865
            ],
            [
              -94.038412,
              31.992437
            ],
            [
              -94.041833,
              31.992402
            ],
            [
              -94.04272,
              31.999265
            ],
            [
              -94.0427,
              32.056012
            ],
            [
              -94.042337,
              32.119914
            ],
            [
              -94.042752,
              32.125163
            ],
            [
              -94.042681,
              32.137956
            ],
            [
              -94.042591,
              32.158097
            ],
            [
              -94.042539,
              32.166826
            ],
            [
              -94.042566,
              32.166894
            ],
            [
              -94.0426205276662,
              32.1960049578035
            ],
            [
              -94.042662,
              32.218146
            ],
            [
              -94.042732,
              32.26962
            ],
            [
              -94.042733,
              32.269696
            ],
            [
              -94.042739,
              32.363559
            ],
            [
              -94.042763,
              32.373332
            ],
            [
              -94.042901,
              32.392283
            ],
            [
              -94.35064,
              32.326583
            ],
            [
              -94.367224,
              32.345945
            ],
            [
              -94.378831,
              32.342375
            ],
            [
              -94.383772,
              32.366499
            ],
            [
              -94.402245,
              32.369339
            ],
            [
              -94.440545,
              32.3621
            ],
            [
              -94.456049,
              32.369252
            ],
            [
              -94.491818,
              32.394137
            ],
            [
              -94.493361,
              32.356492
            ],
            [
              -94.519589,
              32.305558
            ],
            [
              -94.530714,
              32.271842
            ],
            [
              -94.557002,
              32.249053
            ],
            [
              -94.580256,
              32.202545
            ],
            [
              -94.580747,
              32.159269
            ],
            [
              -94.595006,
              32.111392
            ],
            [
              -94.60156,
              32.079971
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "237",
      "COUNTYNS": "01383904",
      "AFFGEOID": "0500000US48237",
      "GEOID": "48237",
      "NAME": "Jack",
      "LSAD": "06",
      "ALAND": 2358611822,
      "AWATER": 24503945,
      "County_state": "Jack,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.420666,
              33.396195
            ],
            [
              -98.426553,
              33.007913
            ],
            [
              -98.056094,
              33.003332
            ],
            [
              -97.921642,
              33.001284
            ],
            [
              -97.918189,
              33.433873
            ],
            [
              -97.978967,
              33.433748
            ],
            [
              -97.978919,
              33.467129
            ],
            [
              -98.421419,
              33.466797
            ],
            [
              -98.420666,
              33.396195
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "099",
      "COUNTYNS": "01383835",
      "AFFGEOID": "0500000US48099",
      "GEOID": "48099",
      "NAME": "Coryell",
      "LSAD": "06",
      "ALAND": 2725199226,
      "AWATER": 11781488,
      "County_state": "Coryell,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.180006,
              31.463717
            ],
            [
              -97.93167,
              31.105432
            ],
            [
              -97.927777,
              31.100991
            ],
            [
              -97.9071,
              31.069374
            ],
            [
              -97.846428,
              31.10061
            ],
            [
              -97.804399,
              31.122092
            ],
            [
              -97.418606,
              31.320202
            ],
            [
              -97.60523,
              31.587762
            ],
            [
              -97.689834,
              31.711019
            ],
            [
              -97.766431,
              31.672461
            ],
            [
              -98.180006,
              31.463717
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "295",
      "COUNTYNS": "01383933",
      "AFFGEOID": "0500000US48295",
      "GEOID": "48295",
      "NAME": "Lipscomb",
      "LSAD": "06",
      "ALAND": 2414327232,
      "AWATER": 298741,
      "County_state": "Lipscomb,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.546145,
              36.499343
            ],
            [
              -100.546724,
              36.056536
            ],
            [
              -100.540221,
              36.056491
            ],
            [
              -100.000399,
              36.055677
            ],
            [
              -100.000406,
              36.499702
            ],
            [
              -100.003761886524,
              36.4996994535481
            ],
            [
              -100.090021,
              36.499634
            ],
            [
              -100.181221,
              36.499633
            ],
            [
              -100.310643,
              36.499642
            ],
            [
              -100.311018,
              36.499688
            ],
            [
              -100.311245,
              36.499631
            ],
            [
              -100.32415,
              36.499679
            ],
            [
              -100.334441,
              36.49944
            ],
            [
              -100.334464,
              36.49942
            ],
            [
              -100.351842,
              36.499473
            ],
            [
              -100.351852,
              36.499487
            ],
            [
              -100.378592,
              36.499445
            ],
            [
              -100.378634,
              36.499517
            ],
            [
              -100.41355,
              36.499469
            ],
            [
              -100.413634,
              36.499444
            ],
            [
              -100.421301,
              36.499488
            ],
            [
              -100.421328,
              36.499447
            ],
            [
              -100.433959,
              36.499456
            ],
            [
              -100.441064,
              36.499462
            ],
            [
              -100.441065,
              36.49949
            ],
            [
              -100.522227,
              36.499291
            ],
            [
              -100.530314,
              36.499357
            ],
            [
              -100.530478,
              36.49924
            ],
            [
              -100.531215,
              36.49929
            ],
            [
              -100.531215,
              36.499341
            ],
            [
              -100.546145,
              36.499343
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "129",
      "COUNTYNS": "01383850",
      "AFFGEOID": "0500000US48129",
      "GEOID": "48129",
      "NAME": "Donley",
      "LSAD": "06",
      "ALAND": 2400623972,
      "AWATER": 15954005,
      "County_state": "Donley,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.086281,
              35.18214
            ],
            [
              -101.090749,
              34.748246
            ],
            [
              -100.944939,
              34.748281
            ],
            [
              -100.540703,
              34.747723
            ],
            [
              -100.538978,
              35.183144
            ],
            [
              -101.086281,
              35.18214
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "013",
      "COUNTYNS": "01383792",
      "AFFGEOID": "0500000US48013",
      "GEOID": "48013",
      "NAME": "Atascosa",
      "LSAD": "06",
      "ALAND": 3158596670,
      "AWATER": 4995711,
      "County_state": "Atascosa,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.804763,
              29.250693
            ],
            [
              -98.8049,
              29.090434
            ],
            [
              -98.800841,
              28.647487
            ],
            [
              -98.800848,
              28.647306
            ],
            [
              -98.63233,
              28.648364
            ],
            [
              -98.335047,
              28.648275
            ],
            [
              -98.335031,
              28.612658
            ],
            [
              -98.098315,
              28.786949
            ],
            [
              -98.168209,
              28.861277
            ],
            [
              -98.190991,
              28.882333
            ],
            [
              -98.330822,
              29.033529
            ],
            [
              -98.407336,
              29.114435
            ],
            [
              -98.441548,
              29.126127
            ],
            [
              -98.484266,
              29.140821
            ],
            [
              -98.779769,
              29.241959
            ],
            [
              -98.804763,
              29.250693
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "045",
      "COUNTYNS": "01383808",
      "AFFGEOID": "0500000US48045",
      "GEOID": "48045",
      "NAME": "Briscoe",
      "LSAD": "06",
      "ALAND": 2330991085,
      "AWATER": 4068657,
      "County_state": "Briscoe,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.471562,
              34.747462
            ],
            [
              -101.47158,
              34.31229
            ],
            [
              -101.041484,
              34.312444
            ],
            [
              -100.946132,
              34.312759
            ],
            [
              -100.944939,
              34.748281
            ],
            [
              -101.090749,
              34.748246
            ],
            [
              -101.471562,
              34.747462
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "175",
      "COUNTYNS": "01383873",
      "AFFGEOID": "0500000US48175",
      "GEOID": "48175",
      "NAME": "Goliad",
      "LSAD": "06",
      "ALAND": 2206698586,
      "AWATER": 19050015,
      "County_state": "Goliad,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.77853,
              28.668027
            ],
            [
              -97.7554,
              28.655037
            ],
            [
              -97.750607,
              28.639235
            ],
            [
              -97.732125,
              28.628833
            ],
            [
              -97.701888,
              28.561194
            ],
            [
              -97.696572,
              28.541537
            ],
            [
              -97.645742,
              28.520763
            ],
            [
              -97.622264,
              28.529357
            ],
            [
              -97.610401,
              28.523233
            ],
            [
              -97.577058,
              28.522547
            ],
            [
              -97.558496,
              28.514054
            ],
            [
              -97.55259,
              28.494326
            ],
            [
              -97.551369,
              28.450441
            ],
            [
              -97.520847,
              28.446414
            ],
            [
              -97.520292,
              28.433508
            ],
            [
              -97.4937,
              28.438836
            ],
            [
              -97.4805,
              28.433889
            ],
            [
              -97.459265,
              28.407499
            ],
            [
              -97.428292,
              28.412875
            ],
            [
              -97.420547,
              28.404182
            ],
            [
              -97.397882,
              28.403038
            ],
            [
              -97.375579,
              28.388684
            ],
            [
              -97.160751,
              28.553475
            ],
            [
              -97.164311,
              28.560986
            ],
            [
              -97.153968,
              28.570173
            ],
            [
              -97.170372,
              28.579748
            ],
            [
              -97.1623,
              28.585873
            ],
            [
              -97.176117,
              28.595212
            ],
            [
              -97.182359,
              28.610602
            ],
            [
              -97.167731,
              28.727846
            ],
            [
              -97.177972,
              28.736935
            ],
            [
              -97.167836,
              28.74792
            ],
            [
              -97.158933,
              28.776156
            ],
            [
              -97.176325,
              28.790734
            ],
            [
              -97.190182,
              28.812473
            ],
            [
              -97.186238,
              28.832929
            ],
            [
              -97.202759,
              28.853849
            ],
            [
              -97.231971,
              28.865303
            ],
            [
              -97.23923,
              28.853818
            ],
            [
              -97.254453,
              28.857274
            ],
            [
              -97.255942,
              28.848428
            ],
            [
              -97.303059,
              28.845071
            ],
            [
              -97.298578,
              28.861962
            ],
            [
              -97.305916,
              28.864037
            ],
            [
              -97.310406,
              28.877304
            ],
            [
              -97.325499,
              28.874837
            ],
            [
              -97.327249,
              28.885748
            ],
            [
              -97.339985,
              28.895647
            ],
            [
              -97.371474,
              28.899258
            ],
            [
              -97.384011,
              28.907417
            ],
            [
              -97.382417,
              28.915666
            ],
            [
              -97.400677,
              28.915535
            ],
            [
              -97.41734,
              28.925228
            ],
            [
              -97.574639,
              28.8133
            ],
            [
              -97.77853,
              28.668027
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "179",
      "COUNTYNS": "01383875",
      "AFFGEOID": "0500000US48179",
      "GEOID": "48179",
      "NAME": "Gray",
      "LSAD": "06",
      "ALAND": 2398261666,
      "AWATER": 8700791,
      "County_state": "Gray,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.085935,
              35.619102
            ],
            [
              -101.086281,
              35.18214
            ],
            [
              -100.538978,
              35.183144
            ],
            [
              -100.540158,
              35.619296
            ],
            [
              -101.085935,
              35.619102
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "191",
      "COUNTYNS": "01383881",
      "AFFGEOID": "0500000US48191",
      "GEOID": "48191",
      "NAME": "Hall",
      "LSAD": "06",
      "ALAND": 2288233624,
      "AWATER": 53366404,
      "County_state": "Hall,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.944939,
              34.748281
            ],
            [
              -100.946132,
              34.312759
            ],
            [
              -100.51734,
              34.314102
            ],
            [
              -100.498193,
              34.313527
            ],
            [
              -100.417783,
              34.313524
            ],
            [
              -100.415895,
              34.747525
            ],
            [
              -100.540703,
              34.747723
            ],
            [
              -100.944939,
              34.748281
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "049",
      "COUNTYNS": "01383810",
      "AFFGEOID": "0500000US48049",
      "GEOID": "48049",
      "NAME": "Brown",
      "LSAD": "06",
      "ALAND": 2446084213,
      "AWATER": 32411546,
      "County_state": "Brown,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.195871,
              32.079235
            ],
            [
              -99.202768,
              31.467234
            ],
            [
              -99.188558,
              31.470337
            ],
            [
              -99.184874,
              31.454963
            ],
            [
              -99.162124,
              31.467848
            ],
            [
              -99.11615,
              31.46824
            ],
            [
              -99.095564,
              31.448469
            ],
            [
              -99.090622,
              31.460927
            ],
            [
              -99.087506,
              31.456017
            ],
            [
              -99.041343,
              31.490559
            ],
            [
              -99.018224,
              31.469519
            ],
            [
              -98.991608,
              31.484071
            ],
            [
              -98.779951,
              31.610735
            ],
            [
              -98.760057,
              31.687896
            ],
            [
              -98.668441,
              31.700514
            ],
            [
              -98.924401,
              32.078018
            ],
            [
              -99.118665,
              32.079585
            ],
            [
              -99.195871,
              32.079235
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "165",
      "COUNTYNS": "01383868",
      "AFFGEOID": "0500000US48165",
      "GEOID": "48165",
      "NAME": "Gaines",
      "LSAD": "06",
      "ALAND": 3891105276,
      "AWATER": 1262946,
      "County_state": "Gaines,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.064916,
              32.85726
            ],
            [
              -103.064889,
              32.849359
            ],
            [
              -103.064672,
              32.82847
            ],
            [
              -103.06467362514,
              32.8284134812341
            ],
            [
              -103.064699,
              32.827531
            ],
            [
              -103.064711,
              32.784593
            ],
            [
              -103.064698,
              32.783602
            ],
            [
              -103.064807,
              32.777303
            ],
            [
              -103.064827,
              32.726628
            ],
            [
              -103.064799,
              32.708694
            ],
            [
              -103.064798,
              32.690761
            ],
            [
              -103.064864,
              32.682647
            ],
            [
              -103.064633,
              32.64642
            ],
            [
              -103.064815,
              32.624537
            ],
            [
              -103.064761,
              32.601863
            ],
            [
              -103.064788,
              32.600397
            ],
            [
              -103.064761,
              32.587983
            ],
            [
              -103.064696,
              32.522193
            ],
            [
              -102.211037,
              32.523244
            ],
            [
              -102.202703,
              32.523271
            ],
            [
              -102.20852,
              32.958956
            ],
            [
              -102.595023,
              32.958831
            ],
            [
              -103.064657009318,
              32.9590992345529
            ],
            [
              -103.064657,
              32.959097
            ],
            [
              -103.064569,
              32.900014
            ],
            [
              -103.064701,
              32.879355
            ],
            [
              -103.064862,
              32.868346
            ],
            [
              -103.064807,
              32.857696
            ],
            [
              -103.064916,
              32.85726
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "027",
      "COUNTYNS": "01383799",
      "AFFGEOID": "0500000US48027",
      "GEOID": "48027",
      "NAME": "Bell",
      "LSAD": "06",
      "ALAND": 2721755826,
      "AWATER": 95727690,
      "County_state": "Bell,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.913847,
              31.065882
            ],
            [
              -97.911684,
              31.034919
            ],
            [
              -97.88572,
              31.000394
            ],
            [
              -97.840365,
              30.929318
            ],
            [
              -97.828512,
              30.906188
            ],
            [
              -97.764317,
              30.898307
            ],
            [
              -97.62491,
              30.870334
            ],
            [
              -97.513067,
              30.827711
            ],
            [
              -97.315507,
              30.752371
            ],
            [
              -97.259082,
              30.889596
            ],
            [
              -97.250274,
              30.895137
            ],
            [
              -97.070188,
              30.98622
            ],
            [
              -97.278113,
              31.279799
            ],
            [
              -97.343426,
              31.244215
            ],
            [
              -97.418606,
              31.320202
            ],
            [
              -97.804399,
              31.122092
            ],
            [
              -97.846428,
              31.10061
            ],
            [
              -97.9071,
              31.069374
            ],
            [
              -97.913847,
              31.065882
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "435",
      "COUNTYNS": "01384003",
      "AFFGEOID": "0500000US48435",
      "GEOID": "48435",
      "NAME": "Sutton",
      "LSAD": "06",
      "ALAND": 3765674653,
      "AWATER": 1277066,
      "County_state": "Sutton,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.960587,
              30.706071
            ],
            [
              -100.960643,
              30.287776
            ],
            [
              -100.700393,
              30.288276
            ],
            [
              -100.116461,
              30.290296
            ],
            [
              -100.116234,
              30.710366
            ],
            [
              -100.960587,
              30.706071
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "405",
      "COUNTYNS": "01383988",
      "AFFGEOID": "0500000US48405",
      "GEOID": "48405",
      "NAME": "San Augustine",
      "LSAD": "06",
      "ALAND": 1374395181,
      "AWATER": 159644687,
      "County_state": "San Augustine,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.399091,
              31.653596
            ],
            [
              -94.395278,
              31.638636
            ],
            [
              -94.36695,
              31.633647
            ],
            [
              -94.350359,
              31.606985
            ],
            [
              -94.310888,
              31.589043
            ],
            [
              -94.313399,
              31.575543
            ],
            [
              -94.304921,
              31.563346
            ],
            [
              -94.308582,
              31.551914
            ],
            [
              -94.300622,
              31.526458
            ],
            [
              -94.305526,
              31.496609
            ],
            [
              -94.318835,
              31.481651
            ],
            [
              -94.31192,
              31.46887
            ],
            [
              -94.320929,
              31.444941
            ],
            [
              -94.304935,
              31.421824
            ],
            [
              -94.312787,
              31.404326
            ],
            [
              -94.326934,
              31.399991
            ],
            [
              -94.322807,
              31.38804
            ],
            [
              -94.330364,
              31.359946
            ],
            [
              -94.31344,
              31.337524
            ],
            [
              -94.322219,
              31.323576
            ],
            [
              -94.31593,
              31.304503
            ],
            [
              -94.32219,
              31.285513
            ],
            [
              -94.339634,
              31.263273
            ],
            [
              -94.332508,
              31.251053
            ],
            [
              -94.339773,
              31.24082
            ],
            [
              -94.326616,
              31.224754
            ],
            [
              -94.29445,
              31.212649
            ],
            [
              -94.287885,
              31.20133
            ],
            [
              -94.269863,
              31.19624
            ],
            [
              -94.262206,
              31.180334
            ],
            [
              -94.250572,
              31.18828
            ],
            [
              -94.218997,
              31.173265
            ],
            [
              -94.219556,
              31.164419
            ],
            [
              -94.202915,
              31.153972
            ],
            [
              -94.192872,
              31.136166
            ],
            [
              -94.143878,
              31.114756
            ],
            [
              -94.129632,
              31.09928
            ],
            [
              -94.043234,
              31.118552
            ],
            [
              -94.039034,
              31.134271
            ],
            [
              -94.0484,
              31.145596
            ],
            [
              -94.038692,
              31.176124
            ],
            [
              -94.038575,
              31.217716
            ],
            [
              -94.029819,
              31.2401
            ],
            [
              -94.039134,
              31.288274
            ],
            [
              -94.047109,
              31.295911
            ],
            [
              -94.037115,
              31.332879
            ],
            [
              -94.041642,
              31.340255
            ],
            [
              -94.022635,
              31.363843
            ],
            [
              -94.026192,
              31.381523
            ],
            [
              -94.044154,
              31.409979
            ],
            [
              -94.000186,
              31.448357
            ],
            [
              -93.983239,
              31.466114
            ],
            [
              -93.984461,
              31.569419
            ],
            [
              -94.090717,
              31.620609
            ],
            [
              -94.399091,
              31.653596
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "269",
      "COUNTYNS": "01383920",
      "AFFGEOID": "0500000US48269",
      "GEOID": "48269",
      "NAME": "King",
      "LSAD": "06",
      "ALAND": 2359148815,
      "AWATER": 6398045,
      "County_state": "King,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.518691,
              33.83565
            ],
            [
              -100.517449,
              33.397866
            ],
            [
              -99.99098,
              33.397404
            ],
            [
              -99.996434,
              33.835967
            ],
            [
              -100.04848,
              33.835974
            ],
            [
              -100.518691,
              33.83565
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "463",
      "COUNTYNS": "01384017",
      "AFFGEOID": "0500000US48463",
      "GEOID": "48463",
      "NAME": "Uvalde",
      "LSAD": "06",
      "ALAND": 4019521039,
      "AWATER": 17320583,
      "County_state": "Uvalde,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.112098,
              29.623263
            ],
            [
              -100.111406,
              29.086318
            ],
            [
              -99.41388,
              29.091335
            ],
            [
              -99.411817,
              29.627514
            ],
            [
              -99.60313,
              29.627181
            ],
            [
              -100.014188,
              29.623495
            ],
            [
              -100.112098,
              29.623263
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "493",
      "COUNTYNS": "01384032",
      "AFFGEOID": "0500000US48493",
      "GEOID": "48493",
      "NAME": "Wilson",
      "LSAD": "06",
      "ALAND": 2081662847,
      "AWATER": 12111367,
      "County_state": "Wilson,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.407336,
              29.114435
            ],
            [
              -98.330822,
              29.033529
            ],
            [
              -98.190991,
              28.882333
            ],
            [
              -97.995302,
              29.026104
            ],
            [
              -97.728443,
              29.221748
            ],
            [
              -97.858782,
              29.35289
            ],
            [
              -97.840383,
              29.376791
            ],
            [
              -97.956081,
              29.378436
            ],
            [
              -98.084684,
              29.379594
            ],
            [
              -98.113175,
              29.365667
            ],
            [
              -98.122574,
              29.378549
            ],
            [
              -98.134171,
              29.441751
            ],
            [
              -98.13965,
              29.437401
            ],
            [
              -98.327196,
              29.209868
            ],
            [
              -98.407336,
              29.114435
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "413",
      "COUNTYNS": "01383992",
      "AFFGEOID": "0500000US48413",
      "GEOID": "48413",
      "NAME": "Schleicher",
      "LSAD": "06",
      "ALAND": 3394546071,
      "AWATER": 87401,
      "County_state": "Schleicher,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.962176,
              31.08249
            ],
            [
              -100.961935,
              30.885072
            ],
            [
              -100.960501,
              30.859764
            ],
            [
              -100.960587,
              30.706071
            ],
            [
              -100.116234,
              30.710366
            ],
            [
              -100.115216,
              31.087994
            ],
            [
              -100.682061,
              31.086219
            ],
            [
              -100.688764,
              31.086576
            ],
            [
              -100.962176,
              31.08249
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "437",
      "COUNTYNS": "01384004",
      "AFFGEOID": "0500000US48437",
      "GEOID": "48437",
      "NAME": "Swisher",
      "LSAD": "06",
      "ALAND": 2305502706,
      "AWATER": 27277987,
      "County_state": "Swisher,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.998493,
              34.74819
            ],
            [
              -101.99802,
              34.313039
            ],
            [
              -101.564856,
              34.312493
            ],
            [
              -101.47158,
              34.31229
            ],
            [
              -101.471562,
              34.747462
            ],
            [
              -101.629257,
              34.747649
            ],
            [
              -101.998493,
              34.74819
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "345",
      "COUNTYNS": "01383958",
      "AFFGEOID": "0500000US48345",
      "GEOID": "48345",
      "NAME": "Motley",
      "LSAD": "06",
      "ALAND": 2562963901,
      "AWATER": 644140,
      "County_state": "Motley,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.041484,
              34.312444
            ],
            [
              -101.041437,
              34.130796
            ],
            [
              -101.041158,
              33.833625
            ],
            [
              -100.518691,
              33.83565
            ],
            [
              -100.51734,
              34.314102
            ],
            [
              -100.946132,
              34.312759
            ],
            [
              -101.041484,
              34.312444
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "305",
      "COUNTYNS": "01383938",
      "AFFGEOID": "0500000US48305",
      "GEOID": "48305",
      "NAME": "Lynn",
      "LSAD": "06",
      "ALAND": 2309924845,
      "AWATER": 4169719,
      "County_state": "Lynn,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.075929,
              33.389586
            ],
            [
              -102.076214,
              32.959702
            ],
            [
              -101.691284,
              32.961838
            ],
            [
              -101.557434,
              32.961025
            ],
            [
              -101.556884,
              33.39476
            ],
            [
              -102.075929,
              33.389586
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "327",
      "COUNTYNS": "01383949",
      "AFFGEOID": "0500000US48327",
      "GEOID": "48327",
      "NAME": "Menard",
      "LSAD": "06",
      "ALAND": 2336237920,
      "AWATER": 613559,
      "County_state": "Menard,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.115216,
              31.087994
            ],
            [
              -100.116234,
              30.710366
            ],
            [
              -99.483869,
              30.710771
            ],
            [
              -99.484755,
              30.940605
            ],
            [
              -99.603627,
              30.940726
            ],
            [
              -99.603223,
              31.087296
            ],
            [
              -100.115216,
              31.087994
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "353",
      "COUNTYNS": "01383962",
      "AFFGEOID": "0500000US48353",
      "GEOID": "48353",
      "NAME": "Nolan",
      "LSAD": "06",
      "ALAND": 2362061742,
      "AWATER": 5052158,
      "County_state": "Nolan,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.660626,
              32.525312
            ],
            [
              -100.665353,
              32.085407
            ],
            [
              -100.250373,
              32.081592
            ],
            [
              -100.235137,
              32.082372
            ],
            [
              -100.151911,
              32.082638
            ],
            [
              -100.148609,
              32.404941
            ],
            [
              -100.146543,
              32.522793
            ],
            [
              -100.660626,
              32.525312
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "431",
      "COUNTYNS": "01384001",
      "AFFGEOID": "0500000US48431",
      "GEOID": "48431",
      "NAME": "Sterling",
      "LSAD": "06",
      "ALAND": 2391722776,
      "AWATER": 167451,
      "County_state": "Sterling,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.267122,
              31.650854
            ],
            [
              -101.26763,
              31.556462
            ],
            [
              -100.865256,
              31.562294
            ],
            [
              -100.861099,
              31.705418
            ],
            [
              -100.82537,
              31.696155
            ],
            [
              -100.821594,
              32.08661
            ],
            [
              -101.183997,
              32.087208
            ],
            [
              -101.264216,
              32.087136
            ],
            [
              -101.267122,
              31.650854
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "169",
      "COUNTYNS": "01383870",
      "AFFGEOID": "0500000US48169",
      "GEOID": "48169",
      "NAME": "Garza",
      "LSAD": "06",
      "ALAND": 2313930840,
      "AWATER": 7215530,
      "County_state": "Garza,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.556884,
              33.39476
            ],
            [
              -101.557434,
              32.961025
            ],
            [
              -101.173378,
              32.963597
            ],
            [
              -101.03866,
              32.970225
            ],
            [
              -101.038788,
              33.397214
            ],
            [
              -101.556884,
              33.39476
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "059",
      "COUNTYNS": "01383815",
      "AFFGEOID": "0500000US48059",
      "GEOID": "48059",
      "NAME": "Callahan",
      "LSAD": "06",
      "ALAND": 2329363248,
      "AWATER": 4898783,
      "County_state": "Callahan,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.6298,
              32.436774
            ],
            [
              -99.631413,
              32.081274
            ],
            [
              -99.195871,
              32.079235
            ],
            [
              -99.118665,
              32.079585
            ],
            [
              -99.114293,
              32.514811
            ],
            [
              -99.612026,
              32.514654
            ],
            [
              -99.629579,
              32.514657
            ],
            [
              -99.6298,
              32.436774
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "433",
      "COUNTYNS": "01384002",
      "AFFGEOID": "0500000US48433",
      "GEOID": "48433",
      "NAME": "Stonewall",
      "LSAD": "06",
      "ALAND": 2373238704,
      "AWATER": 10177387,
      "County_state": "Stonewall,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.517449,
              33.397866
            ],
            [
              -100.519208,
              32.962926
            ],
            [
              -100.144224,
              32.959978
            ],
            [
              -99.988827,
              32.960121
            ],
            [
              -99.99098,
              33.397404
            ],
            [
              -100.517449,
              33.397866
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "159",
      "COUNTYNS": "01383865",
      "AFFGEOID": "0500000US48159",
      "GEOID": "48159",
      "NAME": "Franklin",
      "LSAD": "06",
      "ALAND": 736570306,
      "AWATER": 26874743,
      "County_state": "Franklin,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.308593,
              33.37719
            ],
            [
              -95.308957,
              32.962572
            ],
            [
              -95.231193,
              32.962196
            ],
            [
              -95.172947,
              32.961829
            ],
            [
              -95.152206,
              33.01345
            ],
            [
              -95.137213,
              33.030938
            ],
            [
              -95.12613,
              33.034581
            ],
            [
              -95.125451,
              33.389454
            ],
            [
              -95.156004,
              33.380077
            ],
            [
              -95.166183,
              33.385484
            ],
            [
              -95.196868,
              33.373838
            ],
            [
              -95.201067,
              33.38095
            ],
            [
              -95.227741,
              33.371888
            ],
            [
              -95.25613,
              33.367686
            ],
            [
              -95.271376,
              33.376643
            ],
            [
              -95.300098,
              33.382426
            ],
            [
              -95.30664,
              33.378027
            ],
            [
              -95.308593,
              33.37719
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "467",
      "COUNTYNS": "01384019",
      "AFFGEOID": "0500000US48467",
      "GEOID": "48467",
      "NAME": "Van Zandt",
      "LSAD": "06",
      "ALAND": 2182212910,
      "AWATER": 44164932,
      "County_state": "Van Zandt,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.076801,
              32.838489
            ],
            [
              -96.075537,
              32.506887
            ],
            [
              -96.075899,
              32.357505
            ],
            [
              -95.449165,
              32.355233
            ],
            [
              -95.45647,
              32.368192
            ],
            [
              -95.463334,
              32.365206
            ],
            [
              -95.493797,
              32.38964
            ],
            [
              -95.501642,
              32.409239
            ],
            [
              -95.53912,
              32.432986
            ],
            [
              -95.552843,
              32.453606
            ],
            [
              -95.572885,
              32.461441
            ],
            [
              -95.5943,
              32.479858
            ],
            [
              -95.594541,
              32.687026
            ],
            [
              -95.59321,
              32.693179
            ],
            [
              -95.626279,
              32.708703
            ],
            [
              -95.635017,
              32.72038
            ],
            [
              -95.659803,
              32.711402
            ],
            [
              -95.677538,
              32.727769
            ],
            [
              -95.710451,
              32.745579
            ],
            [
              -95.733043,
              32.746102
            ],
            [
              -95.733321,
              32.752744
            ],
            [
              -95.76434,
              32.759558
            ],
            [
              -95.761537,
              32.770314
            ],
            [
              -95.795966,
              32.775318
            ],
            [
              -95.829581,
              32.766967
            ],
            [
              -95.863949,
              32.777061
            ],
            [
              -95.895117,
              32.803846
            ],
            [
              -95.906737,
              32.806858
            ],
            [
              -95.922362,
              32.815557
            ],
            [
              -95.934433,
              32.837217
            ],
            [
              -96.076801,
              32.838489
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "231",
      "COUNTYNS": "01383901",
      "AFFGEOID": "0500000US48231",
      "GEOID": "48231",
      "NAME": "Hunt",
      "LSAD": "06",
      "ALAND": 2176470753,
      "AWATER": 107957790,
      "County_state": "Hunt,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.297227,
              32.981752
            ],
            [
              -96.297322,
              32.841723
            ],
            [
              -96.076801,
              32.838489
            ],
            [
              -95.934433,
              32.837217
            ],
            [
              -95.956777,
              32.845207
            ],
            [
              -95.987259,
              32.876415
            ],
            [
              -95.978133,
              32.886237
            ],
            [
              -95.9453,
              32.979877
            ],
            [
              -95.862521,
              32.979571
            ],
            [
              -95.863296,
              33.000117
            ],
            [
              -95.861778,
              33.21933
            ],
            [
              -95.858723,
              33.40953
            ],
            [
              -96.295413,
              33.351936
            ],
            [
              -96.297227,
              32.981752
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "313",
      "COUNTYNS": "01383939",
      "AFFGEOID": "0500000US48313",
      "GEOID": "48313",
      "NAME": "Madison",
      "LSAD": "06",
      "ALAND": 1207103467,
      "AWATER": 16493254,
      "County_state": "Madison,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.24102,
              30.973737
            ],
            [
              -96.231729,
              30.954802
            ],
            [
              -96.207477,
              30.947081
            ],
            [
              -96.198234,
              30.931861
            ],
            [
              -96.213165,
              30.911345
            ],
            [
              -96.193633,
              30.882402
            ],
            [
              -96.195713,
              30.871005
            ],
            [
              -96.17684,
              30.858018
            ],
            [
              -96.174627,
              30.829838
            ],
            [
              -96.16837,
              30.822997
            ],
            [
              -96.125235,
              30.826603
            ],
            [
              -95.863059,
              30.864125
            ],
            [
              -95.843165,
              30.861867
            ],
            [
              -95.784532,
              30.879307
            ],
            [
              -95.76493,
              30.896328
            ],
            [
              -95.733388,
              30.900056
            ],
            [
              -95.716292,
              30.911454
            ],
            [
              -95.686855,
              30.90097
            ],
            [
              -95.663811,
              30.904119
            ],
            [
              -95.654501,
              30.89324
            ],
            [
              -95.629597,
              30.900084
            ],
            [
              -95.616233,
              30.910196
            ],
            [
              -95.617872,
              30.930418
            ],
            [
              -95.638683,
              30.926736
            ],
            [
              -95.664954,
              30.970883
            ],
            [
              -95.681158,
              30.986958
            ],
            [
              -95.671059,
              30.993246
            ],
            [
              -95.658418,
              31.01631
            ],
            [
              -95.669505,
              31.024601
            ],
            [
              -95.66318,
              31.033216
            ],
            [
              -95.634704,
              31.037321
            ],
            [
              -95.629983,
              31.05175
            ],
            [
              -95.647546,
              31.055382
            ],
            [
              -95.656487,
              31.079339
            ],
            [
              -95.663546,
              31.083884
            ],
            [
              -95.676469,
              31.075908
            ],
            [
              -95.692388,
              31.08196
            ],
            [
              -95.723383,
              31.079834
            ],
            [
              -95.750226,
              31.092195
            ],
            [
              -95.76441,
              31.094211
            ],
            [
              -95.975218,
              31.091967
            ],
            [
              -96.008068,
              31.073112
            ],
            [
              -96.048912,
              31.068136
            ],
            [
              -96.075429,
              31.049203
            ],
            [
              -96.170918,
              30.9944
            ],
            [
              -96.193073,
              30.991158
            ],
            [
              -96.24102,
              30.973737
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "221",
      "COUNTYNS": "01383896",
      "AFFGEOID": "0500000US48221",
      "GEOID": "48221",
      "NAME": "Hood",
      "LSAD": "06",
      "ALAND": 1089567387,
      "AWATER": 41838592,
      "County_state": "Hood,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.066836,
              32.558822
            ],
            [
              -98.068545,
              32.511626
            ],
            [
              -98.020587,
              32.402285
            ],
            [
              -98.001237,
              32.362329
            ],
            [
              -97.950265,
              32.244263
            ],
            [
              -97.945625,
              32.233468
            ],
            [
              -97.78276,
              32.316493
            ],
            [
              -97.615286,
              32.318618
            ],
            [
              -97.617134,
              32.471693
            ],
            [
              -97.615675,
              32.495392
            ],
            [
              -97.617066,
              32.555484
            ],
            [
              -98.066836,
              32.558822
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "319",
      "COUNTYNS": "01383942",
      "AFFGEOID": "0500000US48319",
      "GEOID": "48319",
      "NAME": "Mason",
      "LSAD": "06",
      "ALAND": 2405596036,
      "AWATER": 8833381,
      "County_state": "Mason,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.484755,
              30.940605
            ],
            [
              -99.483869,
              30.710771
            ],
            [
              -99.484493,
              30.499641
            ],
            [
              -99.303996,
              30.499832
            ],
            [
              -98.964232,
              30.498482
            ],
            [
              -98.964612,
              30.921368
            ],
            [
              -99.092431,
              30.921968
            ],
            [
              -99.092317,
              30.941002
            ],
            [
              -99.484755,
              30.940605
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "183",
      "COUNTYNS": "01383877",
      "AFFGEOID": "0500000US48183",
      "GEOID": "48183",
      "NAME": "Gregg",
      "LSAD": "06",
      "ALAND": 708030600,
      "AWATER": 6227994,
      "County_state": "Gregg,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.986585,
              32.537324
            ],
            [
              -94.986935,
              32.537246
            ],
            [
              -94.986447,
              32.374229
            ],
            [
              -94.985411,
              32.37082
            ],
            [
              -94.896028,
              32.369609
            ],
            [
              -94.638347,
              32.370539
            ],
            [
              -94.600776,
              32.360044
            ],
            [
              -94.591703,
              32.365572
            ],
            [
              -94.579219,
              32.394916
            ],
            [
              -94.588653,
              32.391381
            ],
            [
              -94.598787,
              32.399645
            ],
            [
              -94.642426,
              32.398315
            ],
            [
              -94.67192,
              32.42182
            ],
            [
              -94.693083,
              32.427389
            ],
            [
              -94.701986,
              32.421782
            ],
            [
              -94.70179,
              32.652209
            ],
            [
              -94.70372,
              32.657828
            ],
            [
              -94.727919,
              32.65542
            ],
            [
              -94.746136,
              32.66754
            ],
            [
              -94.758502,
              32.659793
            ],
            [
              -94.820223,
              32.590648
            ],
            [
              -94.946148,
              32.547179
            ],
            [
              -94.974803,
              32.535936
            ],
            [
              -94.986585,
              32.537324
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "211",
      "COUNTYNS": "01383891",
      "AFFGEOID": "0500000US48211",
      "GEOID": "48211",
      "NAME": "Hemphill",
      "LSAD": "06",
      "ALAND": 2347270020,
      "AWATER": 15205585,
      "County_state": "Hemphill,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.540221,
              36.056491
            ],
            [
              -100.540158,
              35.619296
            ],
            [
              -100.000392,
              35.619115
            ],
            [
              -100.00039619832901,
              35.8809479999968
            ],
            [
              -100.000399,
              36.055677
            ],
            [
              -100.540221,
              36.056491
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "069",
      "COUNTYNS": "01383820",
      "AFFGEOID": "0500000US48069",
      "GEOID": "48069",
      "NAME": "Castro",
      "LSAD": "06",
      "ALAND": 2316573415,
      "AWATER": 12584396,
      "County_state": "Castro,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.525184,
              34.746931
            ],
            [
              -102.525631,
              34.313034
            ],
            [
              -102.090415,
              34.313132
            ],
            [
              -101.99802,
              34.313039
            ],
            [
              -101.998493,
              34.74819
            ],
            [
              -102.168839,
              34.747417
            ],
            [
              -102.176433,
              34.746679
            ],
            [
              -102.525184,
              34.746931
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "461",
      "COUNTYNS": "01384016",
      "AFFGEOID": "0500000US48461",
      "GEOID": "48461",
      "NAME": "Upton",
      "LSAD": "06",
      "ALAND": 3215010601,
      "AWATER": 464309,
      "County_state": "Upton,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.31805,
              31.651327
            ],
            [
              -102.307327,
              31.198084
            ],
            [
              -102.300886,
              31.127273
            ],
            [
              -102.301212,
              31.086212
            ],
            [
              -101.776191,
              31.079784
            ],
            [
              -101.775802,
              31.651319
            ],
            [
              -102.287345,
              31.651276
            ],
            [
              -102.31805,
              31.651327
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "247",
      "COUNTYNS": "01383909",
      "AFFGEOID": "0500000US48247",
      "GEOID": "48247",
      "NAME": "Jim Hogg",
      "LSAD": "06",
      "ALAND": 2942674729,
      "AWATER": 92565,
      "County_state": "Jim Hogg,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.954669,
              27.269397
            ],
            [
              -98.95423,
              26.785694
            ],
            [
              -98.422616,
              26.783535
            ],
            [
              -98.417883,
              27.055285
            ],
            [
              -98.466542,
              27.054711
            ],
            [
              -98.467284,
              27.142006
            ],
            [
              -98.49291,
              27.142062
            ],
            [
              -98.492943,
              27.235012
            ],
            [
              -98.506168,
              27.238729
            ],
            [
              -98.510897,
              27.249997
            ],
            [
              -98.531575,
              27.254796
            ],
            [
              -98.536646,
              27.265196
            ],
            [
              -98.523284,
              27.26515
            ],
            [
              -98.522293,
              27.343284
            ],
            [
              -98.552448,
              27.343764
            ],
            [
              -98.552617,
              27.359101
            ],
            [
              -98.589537,
              27.358695
            ],
            [
              -98.589478,
              27.338236
            ],
            [
              -98.624929,
              27.338067
            ],
            [
              -98.625027,
              27.357727
            ],
            [
              -98.749377,
              27.35667
            ],
            [
              -98.798323,
              27.354029
            ],
            [
              -98.798087,
              27.268012
            ],
            [
              -98.954669,
              27.269397
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "285",
      "COUNTYNS": "01383928",
      "AFFGEOID": "0500000US48285",
      "GEOID": "48285",
      "NAME": "Lavaca",
      "LSAD": "06",
      "ALAND": 2511535250,
      "AWATER": 1882216,
      "County_state": "Lavaca,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.202331,
              29.507527
            ],
            [
              -97.240108,
              29.384488
            ],
            [
              -96.976378,
              29.104046
            ],
            [
              -96.938587,
              29.063244
            ],
            [
              -96.658466,
              29.263876
            ],
            [
              -96.560555,
              29.335439
            ],
            [
              -96.793132,
              29.577378
            ],
            [
              -96.832812,
              29.576863
            ],
            [
              -96.838575,
              29.582743
            ],
            [
              -96.833362,
              29.595298
            ],
            [
              -96.843119,
              29.616565
            ],
            [
              -96.874222,
              29.632706
            ],
            [
              -97.142643,
              29.628101
            ],
            [
              -97.204087,
              29.580613
            ],
            [
              -97.202331,
              29.507527
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "329",
      "COUNTYNS": "01383950",
      "AFFGEOID": "0500000US48329",
      "GEOID": "48329",
      "NAME": "Midland",
      "LSAD": "06",
      "ALAND": 2331647582,
      "AWATER": 4520751,
      "County_state": "Midland,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.287048,
              32.086991
            ],
            [
              -102.287345,
              31.651276
            ],
            [
              -101.775802,
              31.651319
            ],
            [
              -101.776085,
              32.086925
            ],
            [
              -102.211249,
              32.0868
            ],
            [
              -102.287048,
              32.086991
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "267",
      "COUNTYNS": "01383919",
      "AFFGEOID": "0500000US48267",
      "GEOID": "48267",
      "NAME": "Kimble",
      "LSAD": "06",
      "ALAND": 3240036002,
      "AWATER": 493584,
      "County_state": "Kimble,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.116234,
              30.710366
            ],
            [
              -100.116461,
              30.290296
            ],
            [
              -99.754142,
              30.290698
            ],
            [
              -99.301718,
              30.286653
            ],
            [
              -99.303996,
              30.499832
            ],
            [
              -99.484493,
              30.499641
            ],
            [
              -99.483869,
              30.710771
            ],
            [
              -100.116234,
              30.710366
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "065",
      "COUNTYNS": "01383818",
      "AFFGEOID": "0500000US48065",
      "GEOID": "48065",
      "NAME": "Carson",
      "LSAD": "06",
      "ALAND": 2383359749,
      "AWATER": 10064086,
      "County_state": "Carson,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.6228,
              35.620196
            ],
            [
              -101.622941,
              35.183117
            ],
            [
              -101.086281,
              35.18214
            ],
            [
              -101.085935,
              35.619102
            ],
            [
              -101.086068,
              35.625267
            ],
            [
              -101.622831,
              35.624057
            ],
            [
              -101.6228,
              35.620196
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "363",
      "COUNTYNS": "01383967",
      "AFFGEOID": "0500000US48363",
      "GEOID": "48363",
      "NAME": "Palo Pinto",
      "LSAD": "06",
      "ALAND": 2466313772,
      "AWATER": 86141064,
      "County_state": "Palo Pinto,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.576023,
              32.733834
            ],
            [
              -98.576256,
              32.515163
            ],
            [
              -98.495775,
              32.513026
            ],
            [
              -98.475177,
              32.513039
            ],
            [
              -98.068545,
              32.511626
            ],
            [
              -98.066836,
              32.558822
            ],
            [
              -98.056094,
              33.003332
            ],
            [
              -98.426553,
              33.007913
            ],
            [
              -98.427211,
              32.9523
            ],
            [
              -98.575616,
              32.954349
            ],
            [
              -98.576023,
              32.733834
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "279",
      "COUNTYNS": "01383926",
      "AFFGEOID": "0500000US48279",
      "GEOID": "48279",
      "NAME": "Lamb",
      "LSAD": "06",
      "ALAND": 2631897577,
      "AWATER": 3973764,
      "County_state": "Lamb,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.61515,
              34.312891
            ],
            [
              -102.615447,
              33.825121
            ],
            [
              -102.085733,
              33.824675
            ],
            [
              -102.090415,
              34.313132
            ],
            [
              -102.525631,
              34.313034
            ],
            [
              -102.61515,
              34.312891
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "133",
      "COUNTYNS": "01383852",
      "AFFGEOID": "0500000US48133",
      "GEOID": "48133",
      "NAME": "Eastland",
      "LSAD": "06",
      "ALAND": 2399599303,
      "AWATER": 14047796,
      "County_state": "Eastland,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.114293,
              32.514811
            ],
            [
              -99.118665,
              32.079585
            ],
            [
              -98.924401,
              32.078018
            ],
            [
              -98.551158,
              32.261436
            ],
            [
              -98.474685,
              32.299084
            ],
            [
              -98.475177,
              32.513039
            ],
            [
              -98.495775,
              32.513026
            ],
            [
              -98.576256,
              32.515163
            ],
            [
              -99.09605,
              32.514768
            ],
            [
              -99.114293,
              32.514811
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "265",
      "COUNTYNS": "01383918",
      "AFFGEOID": "0500000US48265",
      "GEOID": "48265",
      "NAME": "Kerr",
      "LSAD": "06",
      "ALAND": 2857602924,
      "AWATER": 10246419,
      "County_state": "Kerr,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.754142,
              30.290698
            ],
            [
              -99.757621,
              30.074132
            ],
            [
              -99.689879,
              30.073948
            ],
            [
              -99.691215,
              29.908582
            ],
            [
              -99.602776,
              29.907679
            ],
            [
              -99.27864,
              29.904176
            ],
            [
              -99.176988,
              29.895063
            ],
            [
              -98.917725,
              29.781398
            ],
            [
              -98.920147,
              30.13829
            ],
            [
              -99.300988,
              30.134298
            ],
            [
              -99.301718,
              30.286653
            ],
            [
              -99.754142,
              30.290698
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "303",
      "COUNTYNS": "01383937",
      "AFFGEOID": "0500000US48303",
      "GEOID": "48303",
      "NAME": "Lubbock",
      "LSAD": "06",
      "ALAND": 2319602826,
      "AWATER": 13205756,
      "County_state": "Lubbock,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.085733,
              33.824675
            ],
            [
              -102.075929,
              33.389586
            ],
            [
              -101.556884,
              33.39476
            ],
            [
              -101.56358,
              33.830451
            ],
            [
              -102.085733,
              33.824675
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "143",
      "COUNTYNS": "01383857",
      "AFFGEOID": "0500000US48143",
      "GEOID": "48143",
      "NAME": "Erath",
      "LSAD": "06",
      "ALAND": 2805191451,
      "AWATER": 17394954,
      "County_state": "Erath,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.474685,
              32.299084
            ],
            [
              -98.551158,
              32.261436
            ],
            [
              -98.327499,
              31.941261
            ],
            [
              -98.271184,
              31.969949
            ],
            [
              -98.251211,
              31.980127
            ],
            [
              -98.208386,
              31.917511
            ],
            [
              -98.005462,
              32.017897
            ],
            [
              -97.864864,
              32.087328
            ],
            [
              -97.940474,
              32.221738
            ],
            [
              -97.945625,
              32.233468
            ],
            [
              -97.950265,
              32.244263
            ],
            [
              -98.001237,
              32.362329
            ],
            [
              -98.020587,
              32.402285
            ],
            [
              -98.068545,
              32.511626
            ],
            [
              -98.475177,
              32.513039
            ],
            [
              -98.474685,
              32.299084
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "459",
      "COUNTYNS": "01384015",
      "AFFGEOID": "0500000US48459",
      "GEOID": "48459",
      "NAME": "Upshur",
      "LSAD": "06",
      "ALAND": 1509791538,
      "AWATER": 24997458,
      "County_state": "Upshur,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.15274,
              32.660949
            ],
            [
              -95.15341,
              32.570115
            ],
            [
              -95.145888,
              32.569741
            ],
            [
              -95.140095,
              32.550162
            ],
            [
              -95.112711,
              32.550919
            ],
            [
              -95.094386,
              32.557372
            ],
            [
              -95.079116,
              32.556119
            ],
            [
              -95.083871,
              32.542541
            ],
            [
              -95.065935,
              32.537692
            ],
            [
              -95.046264,
              32.539246
            ],
            [
              -95.04076,
              32.532285
            ],
            [
              -95.015037,
              32.542696
            ],
            [
              -95.02236,
              32.521698
            ],
            [
              -95.009992,
              32.516478
            ],
            [
              -94.986935,
              32.537246
            ],
            [
              -94.986585,
              32.537324
            ],
            [
              -94.974803,
              32.535936
            ],
            [
              -94.946148,
              32.547179
            ],
            [
              -94.820223,
              32.590648
            ],
            [
              -94.758502,
              32.659793
            ],
            [
              -94.746136,
              32.66754
            ],
            [
              -94.727919,
              32.65542
            ],
            [
              -94.70372,
              32.657828
            ],
            [
              -94.70179,
              32.652209
            ],
            [
              -94.70214,
              32.793088
            ],
            [
              -94.702138,
              32.820515
            ],
            [
              -94.672351,
              32.834756
            ],
            [
              -94.698976,
              32.845062
            ],
            [
              -94.705878,
              32.879177
            ],
            [
              -94.705177,
              32.892062
            ],
            [
              -94.719942,
              32.904502
            ],
            [
              -95.15211,
              32.902641
            ],
            [
              -95.15274,
              32.660949
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "031",
      "COUNTYNS": "01383801",
      "AFFGEOID": "0500000US48031",
      "GEOID": "48031",
      "NAME": "Blanco",
      "LSAD": "06",
      "ALAND": 1836948497,
      "AWATER": 10818164,
      "County_state": "Blanco,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.59167,
              30.499875
            ],
            [
              -98.587897,
              30.138954
            ],
            [
              -98.414018,
              29.937557
            ],
            [
              -98.2976,
              30.037994
            ],
            [
              -98.172977,
              30.356312
            ],
            [
              -98.125556,
              30.426186
            ],
            [
              -98.351041,
              30.486096
            ],
            [
              -98.411462,
              30.502095
            ],
            [
              -98.59167,
              30.499875
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "003",
      "COUNTYNS": "01383787",
      "AFFGEOID": "0500000US48003",
      "GEOID": "48003",
      "NAME": "Andrews",
      "LSAD": "06",
      "ALAND": 3886850342,
      "AWATER": 957039,
      "County_state": "Andrews,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.064696,
              32.522193
            ],
            [
              -103.064422,
              32.145006
            ],
            [
              -103.064348,
              32.123041
            ],
            [
              -103.064344,
              32.087051
            ],
            [
              -102.812268,
              32.08713
            ],
            [
              -102.799086,
              32.085795
            ],
            [
              -102.287048,
              32.086991
            ],
            [
              -102.211249,
              32.0868
            ],
            [
              -102.211037,
              32.523244
            ],
            [
              -103.064696,
              32.522193
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "243",
      "COUNTYNS": "01383907",
      "AFFGEOID": "0500000US48243",
      "GEOID": "48243",
      "NAME": "Jeff Davis",
      "LSAD": "06",
      "ALAND": 5865172197,
      "AWATER": 206206,
      "County_state": "Jeff Davis,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.973815,
              30.632569
            ],
            [
              -104.982130952491,
              30.6286049903774
            ],
            [
              -104.1107,
              30.454448
            ],
            [
              -103.90101,
              30.41219
            ],
            [
              -103.80068,
              30.412527
            ],
            [
              -103.439976,
              30.665939
            ],
            [
              -103.585084,
              30.76647
            ],
            [
              -104.102351,
              31.105203
            ],
            [
              -104.917163,
              30.663626
            ],
            [
              -104.973815,
              30.632569
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "275",
      "COUNTYNS": "01383923",
      "AFFGEOID": "0500000US48275",
      "GEOID": "48275",
      "NAME": "Knox",
      "LSAD": "06",
      "ALAND": 2203109038,
      "AWATER": 12577261,
      "County_state": "Knox,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.996434,
              33.835967
            ],
            [
              -99.99098,
              33.397404
            ],
            [
              -99.472444,
              33.399023
            ],
            [
              -99.474498,
              33.733849
            ],
            [
              -99.4756,
              33.752309
            ],
            [
              -99.483833,
              33.750103
            ],
            [
              -99.492593,
              33.767234
            ],
            [
              -99.521063,
              33.769354
            ],
            [
              -99.526809,
              33.784245
            ],
            [
              -99.546711,
              33.782649
            ],
            [
              -99.542008,
              33.793244
            ],
            [
              -99.554906,
              33.798556
            ],
            [
              -99.562595,
              33.789996
            ],
            [
              -99.57685,
              33.802691
            ],
            [
              -99.5818,
              33.784723
            ],
            [
              -99.599863,
              33.787634
            ],
            [
              -99.62767,
              33.80423
            ],
            [
              -99.633442,
              33.814609
            ],
            [
              -99.651684,
              33.818379
            ],
            [
              -99.664755,
              33.808489
            ],
            [
              -99.670823,
              33.818601
            ],
            [
              -99.694781,
              33.827158
            ],
            [
              -99.700705,
              33.799963
            ],
            [
              -99.708365,
              33.810435
            ],
            [
              -99.723015,
              33.814066
            ],
            [
              -99.721017,
              33.800255
            ],
            [
              -99.744217,
              33.811073
            ],
            [
              -99.753232,
              33.807141
            ],
            [
              -99.754462,
              33.820929
            ],
            [
              -99.797012,
              33.817277
            ],
            [
              -99.810036,
              33.827439
            ],
            [
              -99.831762,
              33.81714
            ],
            [
              -99.835143,
              33.8358
            ],
            [
              -99.996434,
              33.835967
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "299",
      "COUNTYNS": "01383935",
      "AFFGEOID": "0500000US48299",
      "GEOID": "48299",
      "NAME": "Llano",
      "LSAD": "06",
      "ALAND": 2419183499,
      "AWATER": 82373072,
      "County_state": "Llano,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.964612,
              30.921368
            ],
            [
              -98.964232,
              30.498482
            ],
            [
              -98.59167,
              30.499875
            ],
            [
              -98.411462,
              30.502095
            ],
            [
              -98.351041,
              30.486096
            ],
            [
              -98.351983,
              30.558393
            ],
            [
              -98.364288,
              30.571592
            ],
            [
              -98.375946,
              30.573894
            ],
            [
              -98.398758,
              30.56373
            ],
            [
              -98.411148,
              30.574644
            ],
            [
              -98.411835,
              30.588182
            ],
            [
              -98.400181,
              30.598449
            ],
            [
              -98.417595,
              30.627274
            ],
            [
              -98.418594,
              30.645926
            ],
            [
              -98.428352,
              30.66073
            ],
            [
              -98.439087,
              30.66251
            ],
            [
              -98.438995,
              30.673285
            ],
            [
              -98.403984,
              30.676203
            ],
            [
              -98.413962,
              30.704392
            ],
            [
              -98.404152,
              30.721083
            ],
            [
              -98.392654,
              30.721233
            ],
            [
              -98.371905,
              30.740485
            ],
            [
              -98.375237,
              30.746574
            ],
            [
              -98.424046,
              30.750507
            ],
            [
              -98.430214,
              30.76096
            ],
            [
              -98.422531,
              30.775042
            ],
            [
              -98.431996,
              30.790436
            ],
            [
              -98.41391,
              30.794306
            ],
            [
              -98.403336,
              30.817936
            ],
            [
              -98.390053,
              30.818436
            ],
            [
              -98.376877,
              30.83489
            ],
            [
              -98.404977,
              30.843823
            ],
            [
              -98.421593,
              30.834997
            ],
            [
              -98.41935,
              30.859121
            ],
            [
              -98.431557,
              30.869432
            ],
            [
              -98.448326,
              30.870588
            ],
            [
              -98.457382,
              30.878897
            ],
            [
              -98.448753,
              30.890335
            ],
            [
              -98.445782,
              30.921439
            ],
            [
              -98.964612,
              30.921368
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "483",
      "COUNTYNS": "01384027",
      "AFFGEOID": "0500000US48483",
      "GEOID": "48483",
      "NAME": "Wheeler",
      "LSAD": "06",
      "ALAND": 2368603585,
      "AWATER": 2481258,
      "County_state": "Wheeler,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.540158,
              35.619296
            ],
            [
              -100.538978,
              35.183144
            ],
            [
              -100.000385,
              35.182702
            ],
            [
              -100.000388844143,
              35.4223639883094
            ],
            [
              -100.000392,
              35.619115
            ],
            [
              -100.540158,
              35.619296
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "107",
      "COUNTYNS": "01383839",
      "AFFGEOID": "0500000US48107",
      "GEOID": "48107",
      "NAME": "Crosby",
      "LSAD": "06",
      "ALAND": 2331503437,
      "AWATER": 3873557,
      "County_state": "Crosby,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.56358,
              33.830451
            ],
            [
              -101.556884,
              33.39476
            ],
            [
              -101.038788,
              33.397214
            ],
            [
              -101.041158,
              33.833625
            ],
            [
              -101.56358,
              33.830451
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "311",
      "COUNTYNS": "01383947",
      "AFFGEOID": "0500000US48311",
      "GEOID": "48311",
      "NAME": "McMullen",
      "LSAD": "06",
      "ALAND": 2952065876,
      "AWATER": 43985815,
      "County_state": "McMullen,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.800082,
              28.202333
            ],
            [
              -98.803325,
              28.05748
            ],
            [
              -98.334323,
              28.0578
            ],
            [
              -98.335031,
              28.612658
            ],
            [
              -98.335047,
              28.648275
            ],
            [
              -98.63233,
              28.648364
            ],
            [
              -98.800848,
              28.647306
            ],
            [
              -98.801645,
              28.625291
            ],
            [
              -98.800082,
              28.202333
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "393",
      "COUNTYNS": "01383982",
      "AFFGEOID": "0500000US48393",
      "GEOID": "48393",
      "NAME": "Roberts",
      "LSAD": "06",
      "ALAND": 2393298446,
      "AWATER": 346846,
      "County_state": "Roberts,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.086068,
              35.625267
            ],
            [
              -101.085935,
              35.619102
            ],
            [
              -100.540158,
              35.619296
            ],
            [
              -100.540221,
              36.056491
            ],
            [
              -100.546724,
              36.056536
            ],
            [
              -101.085716,
              36.057572
            ],
            [
              -101.085735,
              36.055276
            ],
            [
              -101.086068,
              35.625267
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "263",
      "COUNTYNS": "01383917",
      "AFFGEOID": "0500000US48263",
      "GEOID": "48263",
      "NAME": "Kent",
      "LSAD": "06",
      "ALAND": 2337482011,
      "AWATER": 1065195,
      "County_state": "Kent,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.038788,
              33.397214
            ],
            [
              -101.03866,
              32.970225
            ],
            [
              -100.990181,
              32.965023
            ],
            [
              -100.65587,
              32.963469
            ],
            [
              -100.519208,
              32.962926
            ],
            [
              -100.517449,
              33.397866
            ],
            [
              -101.038788,
              33.397214
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "091",
      "COUNTYNS": "01383831",
      "AFFGEOID": "0500000US48091",
      "GEOID": "48091",
      "NAME": "Comal",
      "LSAD": "06",
      "ALAND": 1449127320,
      "AWATER": 39800429,
      "County_state": "Comal,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.646124,
              29.745181
            ],
            [
              -98.640969,
              29.740228
            ],
            [
              -98.619232,
              29.744142
            ],
            [
              -98.600261,
              29.741574
            ],
            [
              -98.600406,
              29.74998
            ],
            [
              -98.582332,
              29.741236
            ],
            [
              -98.574567,
              29.750419
            ],
            [
              -98.559304,
              29.751482
            ],
            [
              -98.550489,
              29.760713
            ],
            [
              -98.543718,
              29.752052
            ],
            [
              -98.503974,
              29.738644
            ],
            [
              -98.476991,
              29.733436
            ],
            [
              -98.466283,
              29.738522
            ],
            [
              -98.457494,
              29.731564
            ],
            [
              -98.443671,
              29.734646
            ],
            [
              -98.443852,
              29.71965
            ],
            [
              -98.426968,
              29.71884
            ],
            [
              -98.416064,
              29.746549
            ],
            [
              -98.403871,
              29.749
            ],
            [
              -98.398339,
              29.737615
            ],
            [
              -98.373584,
              29.730401
            ],
            [
              -98.369828,
              29.720693
            ],
            [
              -98.352205,
              29.73415
            ],
            [
              -98.338794,
              29.722006
            ],
            [
              -98.34135,
              29.70965
            ],
            [
              -98.354557,
              29.703864
            ],
            [
              -98.380161,
              29.703929
            ],
            [
              -98.382566,
              29.67446
            ],
            [
              -98.343703,
              29.654344
            ],
            [
              -98.345673,
              29.619742
            ],
            [
              -98.328651,
              29.608233
            ],
            [
              -98.312222,
              29.601802
            ],
            [
              -98.31095,
              29.59456
            ],
            [
              -98.310928,
              29.594473
            ],
            [
              -98.19763,
              29.638128
            ],
            [
              -98.089941,
              29.683479
            ],
            [
              -98.001106,
              29.751051
            ],
            [
              -97.999271,
              29.752444
            ],
            [
              -98.01518,
              29.801485
            ],
            [
              -98.018657,
              29.812175
            ],
            [
              -98.030523,
              29.848539
            ],
            [
              -98.12127,
              29.912844
            ],
            [
              -98.2976,
              30.037994
            ],
            [
              -98.414018,
              29.937557
            ],
            [
              -98.646124,
              29.745181
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "505",
      "COUNTYNS": "01384038",
      "AFFGEOID": "0500000US48505",
      "GEOID": "48505",
      "NAME": "Zapata",
      "LSAD": "06",
      "ALAND": 2585876220,
      "AWATER": 154370982,
      "County_state": "Zapata,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.453949001404,
              27.264672596827698
            ],
            [
              -99.452391,
              27.264046
            ],
            [
              -99.441549,
              27.24992
            ],
            [
              -99.441407,
              27.236758
            ],
            [
              -99.445238,
              27.223341
            ],
            [
              -99.441928,
              27.217985
            ],
            [
              -99.432795,
              27.209693
            ],
            [
              -99.426348,
              27.176262
            ],
            [
              -99.429984,
              27.159149
            ],
            [
              -99.437951,
              27.154121
            ],
            [
              -99.439971,
              27.151072
            ],
            [
              -99.438265,
              27.144792
            ],
            [
              -99.431355,
              27.13758
            ],
            [
              -99.430581,
              27.126612
            ],
            [
              -99.43337,
              27.119218
            ],
            [
              -99.441109,
              27.110042
            ],
            [
              -99.442123,
              27.106839
            ],
            [
              -99.437646,
              27.100442
            ],
            [
              -99.430275,
              27.094872
            ],
            [
              -99.429209,
              27.090982
            ],
            [
              -99.43447,
              27.078517
            ],
            [
              -99.450282,
              27.067705
            ],
            [
              -99.452316,
              27.062669
            ],
            [
              -99.443973,
              27.036458
            ],
            [
              -99.444062,
              27.031253
            ],
            [
              -99.44697,
              27.026026
            ],
            [
              -99.446524,
              27.023008
            ],
            [
              -99.438721,
              27.01463
            ],
            [
              -99.432155,
              27.010699
            ],
            [
              -99.42938,
              27.010833
            ],
            [
              -99.420447,
              27.016568
            ],
            [
              -99.415476,
              27.01724
            ],
            [
              -99.407321,
              27.005809
            ],
            [
              -99.403694,
              26.997356
            ],
            [
              -99.387367,
              26.982399
            ],
            [
              -99.378435,
              26.980034
            ],
            [
              -99.376593,
              26.977717
            ],
            [
              -99.377312,
              26.973819
            ],
            [
              -99.390189,
              26.966348
            ],
            [
              -99.393748,
              26.96073
            ],
            [
              -99.388253,
              26.944217
            ],
            [
              -99.379149,
              26.93449
            ],
            [
              -99.367054,
              26.929034
            ],
            [
              -99.361144,
              26.928921
            ],
            [
              -99.337297,
              26.922759
            ],
            [
              -99.324684,
              26.915973
            ],
            [
              -99.321819,
              26.906846
            ],
            [
              -99.3289,
              26.879761
            ],
            [
              -99.316753,
              26.865831
            ],
            [
              -99.295146,
              26.86544
            ],
            [
              -99.280471,
              26.858053
            ],
            [
              -99.268613,
              26.843213
            ],
            [
              -99.262208,
              26.815668
            ],
            [
              -99.242444,
              26.788262
            ],
            [
              -99.240023,
              26.745851
            ],
            [
              -99.208907,
              26.724761
            ],
            [
              -99.209948,
              26.693938
            ],
            [
              -99.200522,
              26.656443
            ],
            [
              -99.178064,
              26.620547
            ],
            [
              -99.1694639664008,
              26.571673559574
            ],
            [
              -99.011112,
              26.675023
            ],
            [
              -98.95423,
              26.785694
            ],
            [
              -98.954669,
              27.269397
            ],
            [
              -99.33361,
              27.273223
            ],
            [
              -99.370621,
              27.319174
            ],
            [
              -99.453949001404,
              27.264672596827698
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "007",
      "COUNTYNS": "01383789",
      "AFFGEOID": "0500000US48007",
      "GEOID": "48007",
      "NAME": "Aransas",
      "LSAD": "06",
      "ALAND": 652804634,
      "AWATER": 714591509,
      "County_state": "Aransas,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.0781009814495,
              27.9154123808701
            ],
            [
              -97.057171,
              27.865413
            ],
            [
              -97.065254,
              27.851853
            ],
            [
              -97.0436840836865,
              27.8365323534718
            ],
            [
              -97.003325,
              27.908307
            ],
            [
              -96.947572,
              27.97094
            ],
            [
              -96.886459,
              28.030734
            ],
            [
              -96.8520706227301,
              28.0598208616046
            ],
            [
              -96.853912,
              28.063699
            ],
            [
              -96.830861,
              28.108
            ],
            [
              -96.8249014576363,
              28.1396256640933
            ],
            [
              -96.854272,
              28.120068
            ],
            [
              -96.879942,
              28.128767
            ],
            [
              -96.88731,
              28.116294
            ],
            [
              -96.888261,
              28.103925
            ],
            [
              -96.919872,
              28.093547
            ],
            [
              -96.92831,
              28.082329
            ],
            [
              -96.933657,
              28.056008
            ],
            [
              -96.954573,
              28.035975
            ],
            [
              -96.970654,
              28.012456
            ],
            [
              -96.984402,
              27.984355
            ],
            [
              -97.017202,
              27.945728
            ],
            [
              -97.0781009814495,
              27.9154123808701
            ]
          ]
        ],
        [
          [
            [
              -97.117474,
              28.260041
            ],
            [
              -97.125337,
              28.253263
            ],
            [
              -97.097865,
              28.232045
            ],
            [
              -97.101952,
              28.226385
            ],
            [
              -97.065035,
              28.214545
            ],
            [
              -97.054315,
              28.203791
            ],
            [
              -97.0432,
              28.209
            ],
            [
              -97.037422,
              28.201296
            ],
            [
              -97.017539,
              28.202876
            ],
            [
              -97.0278242472443,
              28.184692953904197
            ],
            [
              -97.021303,
              28.1841
            ],
            [
              -97.027014,
              28.148408
            ],
            [
              -97.007539,
              28.136087
            ],
            [
              -97.000414,
              28.137614
            ],
            [
              -96.962755,
              28.123365
            ],
            [
              -96.934765,
              28.123873
            ],
            [
              -96.910337,
              28.147283
            ],
            [
              -96.898123,
              28.152881
            ],
            [
              -96.872678,
              28.176291
            ],
            [
              -96.842143,
              28.193594
            ],
            [
              -96.8100371134392,
              28.2170859995267
            ],
            [
              -96.79930211214379,
              28.272715571994798
            ],
            [
              -96.806011,
              28.282144
            ],
            [
              -96.809573,
              28.290287
            ],
            [
              -96.806011,
              28.296902
            ],
            [
              -96.791761,
              28.31217
            ],
            [
              -96.79115922523539,
              28.3190954393755
            ],
            [
              -97.124252,
              28.271435
            ],
            [
              -97.117474,
              28.260041
            ]
          ]
        ],
        [
          [
            [
              -97.239364,
              28.061396
            ],
            [
              -97.260818,
              28.075759
            ],
            [
              -97.13480101929359,
              27.9024667988298
            ],
            [
              -97.129168,
              27.919801
            ],
            [
              -97.121534,
              27.923364
            ],
            [
              -97.075732,
              27.986977
            ],
            [
              -97.061992,
              27.996138
            ],
            [
              -97.04876,
              28.022092
            ],
            [
              -97.040618,
              28.028708
            ],
            [
              -97.030948,
              28.033288
            ],
            [
              -97.025859,
              28.041939
            ],
            [
              -97.031457,
              28.053644
            ],
            [
              -97.035528,
              28.074
            ],
            [
              -97.035528,
              28.084688
            ],
            [
              -97.031966,
              28.093848
            ],
            [
              -97.023824,
              28.103517
            ],
            [
              -97.022806,
              28.107588
            ],
            [
              -97.025693,
              28.11216
            ],
            [
              -97.05176,
              28.106054
            ],
            [
              -97.103633,
              28.078452
            ],
            [
              -97.137421,
              28.057037
            ],
            [
              -97.176444,
              28.059892
            ],
            [
              -97.215467,
              28.075597
            ],
            [
              -97.2153495489569,
              28.076575511947002
            ],
            [
              -97.239364,
              28.061396
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "361",
      "COUNTYNS": "01383966",
      "AFFGEOID": "0500000US48361",
      "GEOID": "48361",
      "NAME": "Orange",
      "LSAD": "06",
      "ALAND": 864489134,
      "AWATER": 118488356,
      "County_state": "Orange,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.117604,
              30.241665
            ],
            [
              -94.117044,
              30.215522
            ],
            [
              -94.091217,
              30.204648
            ],
            [
              -94.110103,
              30.188344
            ],
            [
              -94.115917,
              30.160347
            ],
            [
              -94.108432,
              30.137942
            ],
            [
              -94.079578,
              30.127111
            ],
            [
              -94.073678,
              30.114111
            ],
            [
              -94.087278,
              30.104912
            ],
            [
              -94.093539,
              30.083684
            ],
            [
              -94.083578,
              30.076912
            ],
            [
              -94.057777,
              30.074812
            ],
            [
              -94.031576,
              30.060712
            ],
            [
              -94.031976,
              30.028013
            ],
            [
              -93.998575,
              30.029156
            ],
            [
              -93.996774,
              30.013113
            ],
            [
              -93.986174,
              30.021612
            ],
            [
              -93.983174,
              30.010813
            ],
            [
              -93.957473,
              30.011812
            ],
            [
              -93.954373,
              30.001113
            ],
            [
              -93.931072,
              29.991113
            ],
            [
              -93.913781,
              29.995988
            ],
            [
              -93.903471,
              29.985513
            ],
            [
              -93.860169,
              29.977397
            ],
            [
              -93.855977,
              29.968725
            ],
            [
              -93.8398,
              29.943861
            ],
            [
              -93.841095,
              29.91287
            ],
            [
              -93.85434264083091,
              29.8649909997958
            ],
            [
              -93.838374,
              29.882855
            ],
            [
              -93.830374,
              29.894359
            ],
            [
              -93.818998,
              29.914822
            ],
            [
              -93.81655,
              29.920726
            ],
            [
              -93.813735,
              29.935126
            ],
            [
              -93.807815,
              29.954549
            ],
            [
              -93.789431,
              29.987812
            ],
            [
              -93.786935,
              29.99058
            ],
            [
              -93.741078,
              30.021571
            ],
            [
              -93.739734,
              30.023987
            ],
            [
              -93.739158,
              30.032627
            ],
            [
              -93.737446,
              30.037283
            ],
            [
              -93.7227914118907,
              30.051161751793895
            ],
            [
              -93.720805,
              30.053043
            ],
            [
              -93.70394,
              30.054291
            ],
            [
              -93.70082,
              30.056274
            ],
            [
              -93.699396,
              30.05925
            ],
            [
              -93.70058,
              30.063666
            ],
            [
              -93.70218,
              30.065474
            ],
            [
              -93.716405,
              30.069122
            ],
            [
              -93.731605,
              30.081282
            ],
            [
              -93.734085,
              30.08613
            ],
            [
              -93.732485,
              30.088914
            ],
            [
              -93.723765,
              30.09413
            ],
            [
              -93.702436,
              30.112721
            ],
            [
              -93.701252,
              30.137376
            ],
            [
              -93.698276,
              30.138608
            ],
            [
              -93.69498,
              30.135185
            ],
            [
              -93.692868,
              30.135217
            ],
            [
              -93.688212,
              30.141376
            ],
            [
              -93.697748,
              30.152944
            ],
            [
              -93.7014984578938,
              30.166030704140198
            ],
            [
              -93.703764,
              30.173936
            ],
            [
              -93.710468,
              30.180671
            ],
            [
              -93.717397,
              30.193439
            ],
            [
              -93.720946,
              30.209852
            ],
            [
              -93.71922,
              30.218542
            ],
            [
              -93.713359,
              30.225261
            ],
            [
              -93.705083,
              30.242752
            ],
            [
              -93.705519312533,
              30.244184772189
            ],
            [
              -93.901452,
              30.242672
            ],
            [
              -94.117604,
              30.241665
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "495",
      "COUNTYNS": "01384033",
      "AFFGEOID": "0500000US48495",
      "GEOID": "48495",
      "NAME": "Winkler",
      "LSAD": "06",
      "ALAND": 2178481652,
      "AWATER": 414369,
      "County_state": "Winkler,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.326501,
              32.00037
            ],
            [
              -103.327538,
              31.65142
            ],
            [
              -102.803806,
              31.651306
            ],
            [
              -102.798939,
              31.651783
            ],
            [
              -102.799086,
              32.085795
            ],
            [
              -102.812268,
              32.08713
            ],
            [
              -103.064344,
              32.087051
            ],
            [
              -103.064423,
              32.000518
            ],
            [
              -103.085876,
              32.000465
            ],
            [
              -103.088698,
              32.000453
            ],
            [
              -103.215641,
              32.000513
            ],
            [
              -103.267633,
              32.000475
            ],
            [
              -103.267708,
              32.000324
            ],
            [
              -103.270383,
              32.000326
            ],
            [
              -103.278521,
              32.000419
            ],
            [
              -103.326501,
              32.00037
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "253",
      "COUNTYNS": "01383912",
      "AFFGEOID": "0500000US48253",
      "GEOID": "48253",
      "NAME": "Jones",
      "LSAD": "06",
      "ALAND": 2404946720,
      "AWATER": 22227413,
      "County_state": "Jones,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.144224,
              32.959978
            ],
            [
              -100.146543,
              32.522793
            ],
            [
              -99.629639,
              32.520713
            ],
            [
              -99.629579,
              32.514657
            ],
            [
              -99.612026,
              32.514654
            ],
            [
              -99.612001,
              32.956962
            ],
            [
              -99.988827,
              32.960121
            ],
            [
              -100.144224,
              32.959978
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "421",
      "COUNTYNS": "01383996",
      "AFFGEOID": "0500000US48421",
      "GEOID": "48421",
      "NAME": "Sherman",
      "LSAD": "06",
      "ALAND": 2390651189,
      "AWATER": 428754,
      "County_state": "Sherman,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.162463,
              36.500326
            ],
            [
              -102.163015,
              36.055249
            ],
            [
              -101.623466,
              36.055405
            ],
            [
              -101.623915,
              36.499528
            ],
            [
              -101.649966,
              36.499573
            ],
            [
              -101.653708,
              36.499573
            ],
            [
              -101.698685,
              36.499508
            ],
            [
              -101.709314,
              36.499722
            ],
            [
              -101.779435,
              36.499734
            ],
            [
              -101.78061,
              36.499727
            ],
            [
              -101.781987,
              36.499718
            ],
            [
              -101.783359,
              36.499709
            ],
            [
              -101.78811,
              36.499678
            ],
            [
              -101.826498,
              36.499535
            ],
            [
              -101.826565,
              36.499654
            ],
            [
              -101.930245,
              36.500526
            ],
            [
              -102.03233903405601,
              36.5006100932816
            ],
            [
              -102.122066,
              36.500684
            ],
            [
              -102.12545,
              36.500324
            ],
            [
              -102.162463,
              36.500326
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "071",
      "COUNTYNS": "01383821",
      "AFFGEOID": "0500000US48071",
      "GEOID": "48071",
      "NAME": "Chambers",
      "LSAD": "06",
      "ALAND": 1546618338,
      "AWATER": 709745135,
      "County_state": "Chambers,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.98146,
              29.884276
            ],
            [
              -94.976835,
              29.867972
            ],
            [
              -94.95309,
              29.855303
            ],
            [
              -94.934599,
              29.834292
            ],
            [
              -94.909816,
              29.821223
            ],
            [
              -94.922942,
              29.802381
            ],
            [
              -94.913813,
              29.7663
            ],
            [
              -94.928596,
              29.741156
            ],
            [
              -94.945399,
              29.716237
            ],
            [
              -94.932566,
              29.714342
            ],
            [
              -94.929909,
              29.69737
            ],
            [
              -94.91558,
              29.697812
            ],
            [
              -94.9311539114845,
              29.6738738151668
            ],
            [
              -94.921318,
              29.658178
            ],
            [
              -94.915413,
              29.656614
            ],
            [
              -94.893107,
              29.661336
            ],
            [
              -94.872551,
              29.67125
            ],
            [
              -94.867438,
              29.678768
            ],
            [
              -94.865007,
              29.695337
            ],
            [
              -94.851108,
              29.721373
            ],
            [
              -94.816085,
              29.75671
            ],
            [
              -94.792238,
              29.767433
            ],
            [
              -94.771512,
              29.773889
            ],
            [
              -94.756509,
              29.781426
            ],
            [
              -94.73623,
              29.761785
            ],
            [
              -94.720716,
              29.766516
            ],
            [
              -94.708105,
              29.757399
            ],
            [
              -94.696023,
              29.75755
            ],
            [
              -94.695317,
              29.723052
            ],
            [
              -94.692434,
              29.70361
            ],
            [
              -94.693154,
              29.694453
            ],
            [
              -94.708741,
              29.625226
            ],
            [
              -94.734626,
              29.584046
            ],
            [
              -94.755237,
              29.562782
            ],
            [
              -94.771053,
              29.548439
            ],
            [
              -94.779439,
              29.549472
            ],
            [
              -94.790605,
              29.548401
            ],
            [
              -94.780938,
              29.531093
            ],
            [
              -94.768676,
              29.525659
            ],
            [
              -94.757689,
              29.524617
            ],
            [
              -94.740699,
              29.525858
            ],
            [
              -94.718276,
              29.533547
            ],
            [
              -94.691625,
              29.537787
            ],
            [
              -94.62589,
              29.552808
            ],
            [
              -94.593518,
              29.561319
            ],
            [
              -94.578211,
              29.567281
            ],
            [
              -94.570006,
              29.572232
            ],
            [
              -94.553988,
              29.573882
            ],
            [
              -94.546385,
              29.572048
            ],
            [
              -94.542532,
              29.569
            ],
            [
              -94.526336,
              29.552634
            ],
            [
              -94.523743,
              29.545987
            ],
            [
              -94.5110860419471,
              29.5429710287244
            ],
            [
              -94.474637,
              29.558329
            ],
            [
              -94.422868,
              29.566256
            ],
            [
              -94.412771,
              29.572612
            ],
            [
              -94.419177,
              29.589193
            ],
            [
              -94.388519,
              29.595696
            ],
            [
              -94.371828,
              29.597178
            ],
            [
              -94.370816,
              29.5556221980724
            ],
            [
              -94.3541242423198,
              29.5620753185838
            ],
            [
              -94.353948,
              29.611497
            ],
            [
              -94.357976,
              29.887464
            ],
            [
              -94.442204,
              29.887053
            ],
            [
              -94.442235,
              29.889538
            ],
            [
              -94.98146,
              29.884276
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "199",
      "COUNTYNS": "01383885",
      "AFFGEOID": "0500000US48199",
      "GEOID": "48199",
      "NAME": "Hardin",
      "LSAD": "06",
      "ALAND": 2306607883,
      "AWATER": 18042331,
      "County_state": "Hardin,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.732732,
              30.490066
            ],
            [
              -94.596338,
              30.112101
            ],
            [
              -94.44491,
              30.113015
            ],
            [
              -94.399233,
              30.097403
            ],
            [
              -94.375728,
              30.109215
            ],
            [
              -94.364062,
              30.101583
            ],
            [
              -94.341854,
              30.103221
            ],
            [
              -94.331627,
              30.11399
            ],
            [
              -94.292629,
              30.117671
            ],
            [
              -94.276569,
              30.139965
            ],
            [
              -94.275644,
              30.170095
            ],
            [
              -94.234932,
              30.165939
            ],
            [
              -94.213164,
              30.182137
            ],
            [
              -94.17644,
              30.18794
            ],
            [
              -94.169016,
              30.173448
            ],
            [
              -94.115917,
              30.160347
            ],
            [
              -94.110103,
              30.188344
            ],
            [
              -94.091217,
              30.204648
            ],
            [
              -94.117044,
              30.215522
            ],
            [
              -94.117604,
              30.241665
            ],
            [
              -94.096486,
              30.257881
            ],
            [
              -94.107734,
              30.261381
            ],
            [
              -94.120526,
              30.283042
            ],
            [
              -94.117792,
              30.300554
            ],
            [
              -94.104234,
              30.307873
            ],
            [
              -94.105023,
              30.322965
            ],
            [
              -94.094926,
              30.339391
            ],
            [
              -94.083386,
              30.342181
            ],
            [
              -94.099196,
              30.361542
            ],
            [
              -94.103938,
              30.394212
            ],
            [
              -94.113061,
              30.402944
            ],
            [
              -94.119394,
              30.426298
            ],
            [
              -94.106843,
              30.437466
            ],
            [
              -94.107395,
              30.450312
            ],
            [
              -94.094735,
              30.466654
            ],
            [
              -94.089532,
              30.485237
            ],
            [
              -94.081267,
              30.488434
            ],
            [
              -94.084089,
              30.5056
            ],
            [
              -94.071689,
              30.526033
            ],
            [
              -94.545717,
              30.526977
            ],
            [
              -94.537926,
              30.491059
            ],
            [
              -94.732732,
              30.490066
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "217",
      "COUNTYNS": "01383894",
      "AFFGEOID": "0500000US48217",
      "GEOID": "48217",
      "NAME": "Hill",
      "LSAD": "06",
      "ALAND": 2483455057,
      "AWATER": 69547484,
      "County_state": "Hill,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.49355,
              32.139848
            ],
            [
              -97.49585,
              32.128152
            ],
            [
              -97.472432,
              32.097552
            ],
            [
              -97.470665,
              32.086711
            ],
            [
              -97.492092,
              32.059723
            ],
            [
              -97.485668,
              32.04292
            ],
            [
              -97.485968,
              32.017611
            ],
            [
              -97.474942,
              32.010081
            ],
            [
              -97.441956,
              32.014436
            ],
            [
              -97.426387,
              32.028557
            ],
            [
              -97.422737,
              32.00789
            ],
            [
              -97.403525,
              31.986476
            ],
            [
              -97.381302,
              31.985596
            ],
            [
              -97.379992,
              31.97274
            ],
            [
              -97.404232,
              31.953713
            ],
            [
              -97.427001,
              31.942628
            ],
            [
              -97.439471,
              31.929775
            ],
            [
              -97.422731,
              31.923182
            ],
            [
              -97.412483,
              31.909844
            ],
            [
              -97.398334,
              31.909806
            ],
            [
              -97.360312,
              31.890669
            ],
            [
              -97.38182,
              31.884255
            ],
            [
              -97.382848,
              31.870788
            ],
            [
              -97.36246,
              31.86495
            ],
            [
              -97.327145,
              31.841452
            ],
            [
              -97.302673,
              31.84828
            ],
            [
              -97.28206,
              31.843152
            ],
            [
              -97.282026,
              31.823331
            ],
            [
              -97.32653,
              31.78836
            ],
            [
              -97.310335,
              31.761552
            ],
            [
              -97.277265,
              31.745492
            ],
            [
              -97.037266,
              31.863079
            ],
            [
              -96.932215,
              31.708887
            ],
            [
              -96.719114,
              31.814887
            ],
            [
              -96.896209,
              32.073977
            ],
            [
              -96.940656,
              32.052087
            ],
            [
              -97.086191,
              32.265451
            ],
            [
              -97.475985,
              32.181679
            ],
            [
              -97.476086,
              32.17346
            ],
            [
              -97.459744,
              32.1709
            ],
            [
              -97.457217,
              32.162726
            ],
            [
              -97.469751,
              32.147942
            ],
            [
              -97.49355,
              32.139848
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "491",
      "COUNTYNS": "01384031",
      "AFFGEOID": "0500000US48491",
      "GEOID": "48491",
      "NAME": "Williamson",
      "LSAD": "06",
      "ALAND": 2896480321,
      "AWATER": 41629535,
      "County_state": "Williamson,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.049886,
              30.624155
            ],
            [
              -98.036495,
              30.611726
            ],
            [
              -98.006276,
              30.627516
            ],
            [
              -97.991015,
              30.609849
            ],
            [
              -97.956734,
              30.628249
            ],
            [
              -97.917063,
              30.604871
            ],
            [
              -97.915123,
              30.580567
            ],
            [
              -97.926985,
              30.567654
            ],
            [
              -97.903277,
              30.570977
            ],
            [
              -97.867866,
              30.546499
            ],
            [
              -97.871416,
              30.522714
            ],
            [
              -97.857505,
              30.50161
            ],
            [
              -97.848384,
              30.472662
            ],
            [
              -97.811863,
              30.447018
            ],
            [
              -97.79448,
              30.444821
            ],
            [
              -97.788777,
              30.44419
            ],
            [
              -97.773458,
              30.430654
            ],
            [
              -97.753689,
              30.4334
            ],
            [
              -97.746686,
              30.449404
            ],
            [
              -97.721149,
              30.455835
            ],
            [
              -97.688784,
              30.461401
            ],
            [
              -97.682893,
              30.480066
            ],
            [
              -97.663886,
              30.481593
            ],
            [
              -97.651441,
              30.474894
            ],
            [
              -97.596236,
              30.501513
            ],
            [
              -97.576167,
              30.501799
            ],
            [
              -97.553864,
              30.490441
            ],
            [
              -97.546683,
              30.474824
            ],
            [
              -97.528151,
              30.475434
            ],
            [
              -97.512247,
              30.48521
            ],
            [
              -97.466953,
              30.46412
            ],
            [
              -97.445628,
              30.461404
            ],
            [
              -97.415953,
              30.437426
            ],
            [
              -97.405086,
              30.441685
            ],
            [
              -97.381949,
              30.423984
            ],
            [
              -97.369539,
              30.419563
            ],
            [
              -97.334463,
              30.402843
            ],
            [
              -97.155219,
              30.457344
            ],
            [
              -97.271418,
              30.735692
            ],
            [
              -97.315507,
              30.752371
            ],
            [
              -97.513067,
              30.827711
            ],
            [
              -97.62491,
              30.870334
            ],
            [
              -97.764317,
              30.898307
            ],
            [
              -97.828512,
              30.906188
            ],
            [
              -97.96286,
              30.785641
            ],
            [
              -98.049886,
              30.624155
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "271",
      "COUNTYNS": "01383921",
      "AFFGEOID": "0500000US48271",
      "GEOID": "48271",
      "NAME": "Kinney",
      "LSAD": "06",
      "ALAND": 3522525623,
      "AWATER": 13124946,
      "County_state": "Kinney,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.797046,
              29.235586
            ],
            [
              -100.795681,
              29.22773
            ],
            [
              -100.791372,
              29.225945
            ],
            [
              -100.785521,
              29.228137
            ],
            [
              -100.767059,
              29.195287
            ],
            [
              -100.766031,
              29.185849
            ],
            [
              -100.775905,
              29.173344
            ],
            [
              -100.772649,
              29.168492
            ],
            [
              -100.759726,
              29.15715
            ],
            [
              -100.74614,
              29.154149
            ],
            [
              -100.739681,
              29.150486
            ],
            [
              -100.737591,
              29.147407
            ],
            [
              -100.739116,
              29.141658
            ],
            [
              -100.737795,
              29.139079
            ],
            [
              -100.727462,
              29.129123
            ],
            [
              -100.709966,
              29.119684
            ],
            [
              -100.692327,
              29.115228
            ],
            [
              -100.684472,
              29.110657
            ],
            [
              -100.674656,
              29.099777
            ],
            [
              -100.66835550309101,
              29.083970135364098
            ],
            [
              -100.111406,
              29.086318
            ],
            [
              -100.112098,
              29.623263
            ],
            [
              -100.699932,
              29.623897
            ],
            [
              -100.699141,
              29.419747
            ],
            [
              -100.706061,
              29.401482
            ],
            [
              -100.710641,
              29.369779
            ],
            [
              -100.706307,
              29.363912
            ],
            [
              -100.712195,
              29.337884
            ],
            [
              -100.745094,
              29.32805
            ],
            [
              -100.748594,
              29.304997
            ],
            [
              -100.739067,
              29.290399
            ],
            [
              -100.745564,
              29.254809
            ],
            [
              -100.763083,
              29.248646
            ],
            [
              -100.757822,
              29.238325
            ],
            [
              -100.774649,
              29.245932
            ],
            [
              -100.795126802248,
              29.2417671837622
            ],
            [
              -100.797046,
              29.235586
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "057",
      "COUNTYNS": "01383814",
      "AFFGEOID": "0500000US48057",
      "GEOID": "48057",
      "NAME": "Calhoun",
      "LSAD": "06",
      "ALAND": 1312707024,
      "AWATER": 1361884769,
      "County_state": "Calhoun,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.8100371134392,
              28.2170859995267
            ],
            [
              -96.800413,
              28.224128
            ],
            [
              -96.787181,
              28.250083
            ],
            [
              -96.787181,
              28.255681
            ],
            [
              -96.79930211214379,
              28.272715571994798
            ],
            [
              -96.8100371134392,
              28.2170859995267
            ]
          ]
        ],
        [
          [
            [
              -96.930367,
              28.586728
            ],
            [
              -96.916885,
              28.570983
            ],
            [
              -96.903795,
              28.567033
            ],
            [
              -96.895144,
              28.549257
            ],
            [
              -96.905923,
              28.532393
            ],
            [
              -96.890951,
              28.507178
            ],
            [
              -96.864821,
              28.490235
            ],
            [
              -96.86256,
              28.477512
            ],
            [
              -96.848402,
              28.476071
            ],
            [
              -96.839916,
              28.458882
            ],
            [
              -96.823485,
              28.445582
            ],
            [
              -96.813494,
              28.430261
            ],
            [
              -96.789396,
              28.44717
            ],
            [
              -96.764132,
              28.426522
            ],
            [
              -96.7669593744276,
              28.4107372134025
            ],
            [
              -96.762245,
              28.411916
            ],
            [
              -96.749013,
              28.408862
            ],
            [
              -96.72255,
              28.408862
            ],
            [
              -96.710336,
              28.406827
            ],
            [
              -96.705756,
              28.400211
            ],
            [
              -96.700158,
              28.369676
            ],
            [
              -96.705247,
              28.348811
            ],
            [
              -96.687897,
              28.328127
            ],
            [
              -96.684355,
              28.314464
            ],
            [
              -96.665198,
              28.30961
            ],
            [
              -96.632398,
              28.318712
            ],
            [
              -96.620633,
              28.31913
            ],
            [
              -96.581416,
              28.353335
            ],
            [
              -96.542024,
              28.37185
            ],
            [
              -96.511538,
              28.383346
            ],
            [
              -96.45295,
              28.418679
            ],
            [
              -96.420032,
              28.416902
            ],
            [
              -96.420626,
              28.404359
            ],
            [
              -96.450217,
              28.388469
            ],
            [
              -96.452831,
              28.380209
            ],
            [
              -96.444869,
              28.370276
            ],
            [
              -96.433698,
              28.363688
            ],
            [
              -96.440591,
              28.34298
            ],
            [
              -96.450336,
              28.33775
            ],
            [
              -96.482541,
              28.330114
            ],
            [
              -96.525205,
              28.323001
            ],
            [
              -96.541486,
              28.31641
            ],
            [
              -96.553845,
              28.302704
            ],
            [
              -96.575593,
              28.300821
            ],
            [
              -96.599004,
              28.293287
            ],
            [
              -96.609437,
              28.28107
            ],
            [
              -96.626456,
              28.269112
            ],
            [
              -96.654978,
              28.246397
            ],
            [
              -96.702752,
              28.214567
            ],
            [
              -96.734745,
              28.190771
            ],
            [
              -96.790574,
              28.188907
            ],
            [
              -96.821267,
              28.168361
            ],
            [
              -96.819334,
              28.143333
            ],
            [
              -96.8249014576363,
              28.1396256640933
            ],
            [
              -96.830861,
              28.108
            ],
            [
              -96.853912,
              28.063699
            ],
            [
              -96.8520706227301,
              28.0598208616046
            ],
            [
              -96.792158,
              28.110497
            ],
            [
              -96.719627,
              28.164594
            ],
            [
              -96.63201,
              28.222821
            ],
            [
              -96.486267,
              28.298499
            ],
            [
              -96.442849,
              28.317666
            ],
            [
              -96.400477,
              28.357137
            ],
            [
              -96.390376,
              28.381815
            ],
            [
              -96.37837667303059,
              28.3905560343654
            ],
            [
              -96.322731,
              28.642944
            ],
            [
              -96.323522,
              28.675597
            ],
            [
              -96.404445,
              28.674409
            ],
            [
              -96.40008,
              28.689879
            ],
            [
              -96.39954,
              28.730231
            ],
            [
              -96.428394,
              28.703523
            ],
            [
              -96.478081,
              28.702535
            ],
            [
              -96.562818,
              28.704984
            ],
            [
              -96.577611,
              28.706397
            ],
            [
              -96.586776,
              28.725289
            ],
            [
              -96.643442,
              28.712105
            ],
            [
              -96.659864,
              28.701671
            ],
            [
              -96.665707,
              28.705705
            ],
            [
              -96.722814,
              28.669759
            ],
            [
              -96.853757,
              28.635199
            ],
            [
              -96.930367,
              28.586728
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "139",
      "COUNTYNS": "01383856",
      "AFFGEOID": "0500000US48139",
      "GEOID": "48139",
      "NAME": "Ellis",
      "LSAD": "06",
      "ALAND": 2423342394,
      "AWATER": 41672841,
      "County_state": "Ellis,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.086834,
              32.549428
            ],
            [
              -97.086191,
              32.265451
            ],
            [
              -96.940656,
              32.052087
            ],
            [
              -96.896209,
              32.073977
            ],
            [
              -96.395079,
              32.323644
            ],
            [
              -96.383082,
              32.328851
            ],
            [
              -96.408118,
              32.328502
            ],
            [
              -96.436293,
              32.345413
            ],
            [
              -96.451474,
              32.345574
            ],
            [
              -96.452138,
              32.358631
            ],
            [
              -96.434514,
              32.38873
            ],
            [
              -96.439697,
              32.399932
            ],
            [
              -96.434519,
              32.411437
            ],
            [
              -96.449315,
              32.422102
            ],
            [
              -96.476903,
              32.434201
            ],
            [
              -96.494378,
              32.467657
            ],
            [
              -96.510215,
              32.481926
            ],
            [
              -96.512451,
              32.513333
            ],
            [
              -96.503027,
              32.511465
            ],
            [
              -96.503789,
              32.526949
            ],
            [
              -96.520019,
              32.531704
            ],
            [
              -96.529987,
              32.545282
            ],
            [
              -97.01361,
              32.5483
            ],
            [
              -97.038385,
              32.548662
            ],
            [
              -97.052636,
              32.548907
            ],
            [
              -97.058759,
              32.548966
            ],
            [
              -97.086834,
              32.549428
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "077",
      "COUNTYNS": "01383824",
      "AFFGEOID": "0500000US48077",
      "GEOID": "48077",
      "NAME": "Clay",
      "LSAD": "06",
      "ALAND": 2819788075,
      "AWATER": 72590626,
      "County_state": "Clay,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.422992,
              33.843812
            ],
            [
              -98.423577,
              33.836047
            ],
            [
              -98.421419,
              33.466797
            ],
            [
              -97.978919,
              33.467129
            ],
            [
              -97.97780788974269,
              33.8898833742718
            ],
            [
              -97.977859,
              33.889929
            ],
            [
              -97.983769,
              33.8972
            ],
            [
              -97.984566,
              33.899077
            ],
            [
              -97.98454,
              33.900703
            ],
            [
              -97.983552,
              33.904002
            ],
            [
              -97.979985,
              33.911402
            ],
            [
              -97.978804,
              33.912548
            ],
            [
              -97.976963,
              33.912549
            ],
            [
              -97.973143,
              33.908014
            ],
            [
              -97.969873,
              33.905999
            ],
            [
              -97.964461,
              33.907398
            ],
            [
              -97.960615,
              33.910354
            ],
            [
              -97.957155,
              33.914454
            ],
            [
              -97.953695,
              33.924373
            ],
            [
              -97.952679,
              33.929482
            ],
            [
              -97.953395,
              33.936445
            ],
            [
              -97.954467,
              33.937774
            ],
            [
              -97.955511,
              33.938186
            ],
            [
              -97.963425,
              33.936237
            ],
            [
              -97.965953,
              33.936191
            ],
            [
              -97.971175,
              33.937129
            ],
            [
              -97.972494,
              33.937907
            ],
            [
              -97.974062,
              33.940289
            ],
            [
              -97.974173,
              33.942832
            ],
            [
              -97.972662,
              33.944527
            ],
            [
              -97.965737,
              33.947392
            ],
            [
              -97.960351,
              33.951928
            ],
            [
              -97.956917,
              33.958502
            ],
            [
              -97.94595,
              33.988396
            ],
            [
              -97.94573,
              33.989839
            ],
            [
              -97.946473,
              33.990732
            ],
            [
              -97.947572,
              33.991053
            ],
            [
              -97.952688,
              33.990114
            ],
            [
              -97.95585,
              33.990136
            ],
            [
              -97.958325,
              33.990846
            ],
            [
              -97.963028,
              33.994235
            ],
            [
              -97.96834,
              34.00053
            ],
            [
              -97.97167,
              34.005434
            ],
            [
              -97.974173,
              34.006716
            ],
            [
              -97.978243,
              34.005387
            ],
            [
              -97.982806,
              34.001949
            ],
            [
              -97.987388,
              33.999823
            ],
            [
              -98.005667,
              33.995964
            ],
            [
              -98.019485,
              33.993804
            ],
            [
              -98.027672,
              33.993357
            ],
            [
              -98.041117,
              33.993456
            ],
            [
              -98.055197,
              33.995841
            ],
            [
              -98.082839,
              34.002412
            ],
            [
              -98.08526,
              34.003259
            ],
            [
              -98.088203,
              34.005481
            ],
            [
              -98.103617,
              34.029207
            ],
            [
              -98.105482,
              34.031307
            ],
            [
              -98.105482,
              34.033861
            ],
            [
              -98.104022,
              34.036233
            ],
            [
              -98.102015,
              34.037327
            ],
            [
              -98.098001,
              34.03824
            ],
            [
              -98.097272,
              34.038969
            ],
            [
              -98.096542,
              34.040976
            ],
            [
              -98.096177,
              34.044625
            ],
            [
              -98.099096,
              34.048639
            ],
            [
              -98.114587,
              34.06228
            ],
            [
              -98.11803,
              34.067065
            ],
            [
              -98.120208,
              34.072127
            ],
            [
              -98.121039,
              34.081266
            ],
            [
              -98.119417,
              34.084474
            ],
            [
              -98.104309,
              34.0982
            ],
            [
              -98.099328,
              34.104295
            ],
            [
              -98.095118,
              34.11119
            ],
            [
              -98.092421,
              34.116917
            ],
            [
              -98.09066,
              34.12198
            ],
            [
              -98.089755,
              34.128211
            ],
            [
              -98.090224,
              34.130181
            ],
            [
              -98.101937,
              34.14683
            ],
            [
              -98.107065,
              34.152531
            ],
            [
              -98.109462,
              34.154111
            ],
            [
              -98.114506,
              34.154727
            ],
            [
              -98.123377,
              34.15454
            ],
            [
              -98.130816,
              34.150532
            ],
            [
              -98.13677,
              34.144992
            ],
            [
              -98.1389788043646,
              34.141805401056196
            ],
            [
              -98.142754,
              34.136359
            ],
            [
              -98.154354,
              34.122734
            ],
            [
              -98.157412,
              34.120467
            ],
            [
              -98.16912,
              34.114171
            ],
            [
              -98.191455,
              34.115753
            ],
            [
              -98.200075,
              34.116783
            ],
            [
              -98.203711,
              34.117676
            ],
            [
              -98.216463,
              34.121821
            ],
            [
              -98.2236,
              34.125093
            ],
            [
              -98.225282,
              34.127245
            ],
            [
              -98.241013,
              34.133103
            ],
            [
              -98.247954,
              34.130717
            ],
            [
              -98.256467,
              34.129481
            ],
            [
              -98.280321,
              34.13075
            ],
            [
              -98.293901,
              34.13302
            ],
            [
              -98.300209,
              34.134579
            ],
            [
              -98.31875,
              34.146421
            ],
            [
              -98.32258,
              34.14972
            ],
            [
              -98.325445,
              34.151025
            ],
            [
              -98.364023,
              34.157109
            ],
            [
              -98.367494,
              34.156191
            ],
            [
              -98.381238,
              34.149454
            ],
            [
              -98.384381,
              34.146317
            ],
            [
              -98.398441,
              34.128456
            ],
            [
              -98.400967,
              34.122236
            ],
            [
              -98.400494,
              34.121778
            ],
            [
              -98.39816,
              34.121396
            ],
            [
              -98.398389,
              34.104566
            ],
            [
              -98.399777,
              34.099973
            ],
            [
              -98.414426,
              34.085074
            ],
            [
              -98.417813,
              34.083029
            ],
            [
              -98.419995,
              34.082488
            ],
            [
              -98.422253,
              34.083037
            ],
            [
              -98.4231158951185,
              34.0835477226062
            ],
            [
              -98.422992,
              33.843812
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "257",
      "COUNTYNS": "01383914",
      "AFFGEOID": "0500000US48257",
      "GEOID": "48257",
      "NAME": "Kaufman",
      "LSAD": "06",
      "ALAND": 2021962201,
      "AWATER": 69763126,
      "County_state": "Kaufman,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.523118,
              32.545214
            ],
            [
              -96.529987,
              32.545282
            ],
            [
              -96.520019,
              32.531704
            ],
            [
              -96.503789,
              32.526949
            ],
            [
              -96.503027,
              32.511465
            ],
            [
              -96.512451,
              32.513333
            ],
            [
              -96.510215,
              32.481926
            ],
            [
              -96.494378,
              32.467657
            ],
            [
              -96.476903,
              32.434201
            ],
            [
              -96.449315,
              32.422102
            ],
            [
              -96.434519,
              32.411437
            ],
            [
              -96.439697,
              32.399932
            ],
            [
              -96.434514,
              32.38873
            ],
            [
              -96.452138,
              32.358631
            ],
            [
              -96.249701,
              32.358716
            ],
            [
              -96.200405,
              32.354838
            ],
            [
              -96.195621,
              32.356001
            ],
            [
              -96.184582,
              32.358117
            ],
            [
              -96.075899,
              32.357505
            ],
            [
              -96.075537,
              32.506887
            ],
            [
              -96.076801,
              32.838489
            ],
            [
              -96.297322,
              32.841723
            ],
            [
              -96.297376,
              32.814185
            ],
            [
              -96.51897,
              32.813616
            ],
            [
              -96.519261,
              32.796028
            ],
            [
              -96.523118,
              32.545214
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "155",
      "COUNTYNS": "01383863",
      "AFFGEOID": "0500000US48155",
      "GEOID": "48155",
      "NAME": "Foard",
      "LSAD": "06",
      "ALAND": 1824378633,
      "AWATER": 8514740,
      "County_state": "Foard,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.047028,
              34.22978
            ],
            [
              -100.04848,
              33.835974
            ],
            [
              -99.996434,
              33.835967
            ],
            [
              -99.835143,
              33.8358
            ],
            [
              -99.831762,
              33.81714
            ],
            [
              -99.810036,
              33.827439
            ],
            [
              -99.797012,
              33.817277
            ],
            [
              -99.754462,
              33.820929
            ],
            [
              -99.753232,
              33.807141
            ],
            [
              -99.744217,
              33.811073
            ],
            [
              -99.721017,
              33.800255
            ],
            [
              -99.723015,
              33.814066
            ],
            [
              -99.708365,
              33.810435
            ],
            [
              -99.700705,
              33.799963
            ],
            [
              -99.694781,
              33.827158
            ],
            [
              -99.670823,
              33.818601
            ],
            [
              -99.664755,
              33.808489
            ],
            [
              -99.651684,
              33.818379
            ],
            [
              -99.633442,
              33.814609
            ],
            [
              -99.62767,
              33.80423
            ],
            [
              -99.599863,
              33.787634
            ],
            [
              -99.5818,
              33.784723
            ],
            [
              -99.57685,
              33.802691
            ],
            [
              -99.562595,
              33.789996
            ],
            [
              -99.554906,
              33.798556
            ],
            [
              -99.542008,
              33.793244
            ],
            [
              -99.546711,
              33.782649
            ],
            [
              -99.526809,
              33.784245
            ],
            [
              -99.521063,
              33.769354
            ],
            [
              -99.492593,
              33.767234
            ],
            [
              -99.483833,
              33.750103
            ],
            [
              -99.4756,
              33.752309
            ],
            [
              -99.474498,
              33.733849
            ],
            [
              -99.47565,
              33.834005
            ],
            [
              -99.475294,
              34.085618
            ],
            [
              -99.491097,
              34.065407
            ],
            [
              -99.505386,
              34.077043
            ],
            [
              -99.534898,
              34.073983
            ],
            [
              -99.5485,
              34.055774
            ],
            [
              -99.573161,
              34.063608
            ],
            [
              -99.581531,
              34.077237
            ],
            [
              -99.593563,
              34.082526
            ],
            [
              -99.617846,
              34.083387
            ],
            [
              -99.623058,
              34.095754
            ],
            [
              -99.65301,
              34.098971
            ],
            [
              -99.673719,
              34.088506
            ],
            [
              -99.678619,
              34.093271
            ],
            [
              -99.704281,
              34.09847
            ],
            [
              -99.733356,
              34.093513
            ],
            [
              -99.746826,
              34.100998
            ],
            [
              -99.772043,
              34.093286
            ],
            [
              -99.997621,
              34.224355
            ],
            [
              -100.006052,
              34.225668
            ],
            [
              -100.009793,
              34.241955
            ],
            [
              -100.037319,
              34.238778
            ],
            [
              -100.047028,
              34.22978
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "085",
      "COUNTYNS": "01383828",
      "AFFGEOID": "0500000US48085",
      "GEOID": "48085",
      "NAME": "Collin",
      "LSAD": "06",
      "ALAND": 2178638821,
      "AWATER": 116470919,
      "County_state": "Collin,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.843266,
              33.025914
            ],
            [
              -96.843979,
              32.987554
            ],
            [
              -96.838963,
              32.987521
            ],
            [
              -96.803375,
              32.987003
            ],
            [
              -96.768152,
              32.986535
            ],
            [
              -96.767999,
              32.986534
            ],
            [
              -96.748422,
              32.986271
            ],
            [
              -96.682467,
              32.985325
            ],
            [
              -96.613377,
              32.984021
            ],
            [
              -96.583842,
              32.983608
            ],
            [
              -96.516866,
              32.982308
            ],
            [
              -96.503025,
              32.981842
            ],
            [
              -96.466726,
              32.982
            ],
            [
              -96.46371,
              32.982439
            ],
            [
              -96.370728,
              32.982084
            ],
            [
              -96.297227,
              32.981752
            ],
            [
              -96.295413,
              33.351936
            ],
            [
              -96.384495,
              33.341119
            ],
            [
              -96.384602,
              33.397826
            ],
            [
              -96.83411,
              33.405498
            ],
            [
              -96.837795,
              33.262835
            ],
            [
              -96.838803,
              33.219386
            ],
            [
              -96.841849,
              33.086456
            ],
            [
              -96.841867,
              33.085576
            ],
            [
              -96.843266,
              33.025914
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "093",
      "COUNTYNS": "01383832",
      "AFFGEOID": "0500000US48093",
      "GEOID": "48093",
      "NAME": "Comanche",
      "LSAD": "06",
      "ALAND": 2428770456,
      "AWATER": 25718009,
      "County_state": "Comanche,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.924401,
              32.078018
            ],
            [
              -98.668441,
              31.700514
            ],
            [
              -98.492802,
              31.723608
            ],
            [
              -98.463736,
              31.683989
            ],
            [
              -98.156568,
              31.841713
            ],
            [
              -98.208386,
              31.917511
            ],
            [
              -98.251211,
              31.980127
            ],
            [
              -98.271184,
              31.969949
            ],
            [
              -98.327499,
              31.941261
            ],
            [
              -98.551158,
              32.261436
            ],
            [
              -98.924401,
              32.078018
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "095",
      "COUNTYNS": "01383833",
      "AFFGEOID": "0500000US48095",
      "GEOID": "48095",
      "NAME": "Concho",
      "LSAD": "06",
      "ALAND": 2547913999,
      "AWATER": 25740027,
      "County_state": "Concho,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.111234,
              31.580267
            ],
            [
              -100.115216,
              31.087994
            ],
            [
              -99.603223,
              31.087296
            ],
            [
              -99.60185,
              31.49195
            ],
            [
              -99.61429,
              31.501564
            ],
            [
              -99.624685,
              31.493502
            ],
            [
              -99.608188,
              31.480809
            ],
            [
              -99.610833,
              31.471307
            ],
            [
              -99.62492,
              31.467442
            ],
            [
              -99.646604,
              31.491691
            ],
            [
              -99.661979,
              31.498547
            ],
            [
              -99.676358,
              31.497263
            ],
            [
              -99.670487,
              31.510926
            ],
            [
              -99.652559,
              31.527467
            ],
            [
              -99.658791,
              31.534779
            ],
            [
              -99.690255,
              31.532032
            ],
            [
              -99.692985,
              31.538242
            ],
            [
              -99.673186,
              31.547104
            ],
            [
              -99.673424,
              31.555291
            ],
            [
              -99.694673,
              31.558385
            ],
            [
              -99.70573,
              31.576899
            ],
            [
              -99.710758,
              31.561247
            ],
            [
              -99.722063,
              31.562761
            ],
            [
              -99.721698,
              31.576759
            ],
            [
              -100.111234,
              31.580267
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "103",
      "COUNTYNS": "01383837",
      "AFFGEOID": "0500000US48103",
      "GEOID": "48103",
      "NAME": "Crane",
      "LSAD": "06",
      "ALAND": 2033319713,
      "AWATER": 1695980,
      "County_state": "Crane,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.767246,
              31.651714
            ],
            [
              -102.767365,
              31.293803
            ],
            [
              -102.753248,
              31.279151
            ],
            [
              -102.729976,
              31.287703
            ],
            [
              -102.724875,
              31.308418
            ],
            [
              -102.708332,
              31.31113
            ],
            [
              -102.676104,
              31.330084
            ],
            [
              -102.66198,
              31.315902
            ],
            [
              -102.641008,
              31.309682
            ],
            [
              -102.628955,
              31.291026
            ],
            [
              -102.616377,
              31.289386
            ],
            [
              -102.600677,
              31.267097
            ],
            [
              -102.583891,
              31.26288
            ],
            [
              -102.579067,
              31.271525
            ],
            [
              -102.523063,
              31.262698
            ],
            [
              -102.502299,
              31.252975
            ],
            [
              -102.484027,
              31.237528
            ],
            [
              -102.450595,
              31.213329
            ],
            [
              -102.43992,
              31.209929
            ],
            [
              -102.429545,
              31.193557
            ],
            [
              -102.422872,
              31.149248
            ],
            [
              -102.430127,
              31.129058
            ],
            [
              -102.420143,
              31.118208
            ],
            [
              -102.406153,
              31.08787
            ],
            [
              -102.388804,
              31.087156
            ],
            [
              -102.301212,
              31.086212
            ],
            [
              -102.300886,
              31.127273
            ],
            [
              -102.307327,
              31.198084
            ],
            [
              -102.31805,
              31.651327
            ],
            [
              -102.767246,
              31.651714
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "241",
      "COUNTYNS": "01383906",
      "AFFGEOID": "0500000US48241",
      "GEOID": "48241",
      "NAME": "Jasper",
      "LSAD": "06",
      "ALAND": 2431105374,
      "AWATER": 80399908,
      "County_state": "Jasper,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.457816,
              31.033325
            ],
            [
              -94.45826,
              31.03203
            ],
            [
              -94.456275,
              31.031325
            ],
            [
              -94.451425,
              31.031457
            ],
            [
              -94.446969,
              31.033098
            ],
            [
              -94.445817,
              31.023616
            ],
            [
              -94.450189,
              31.012323
            ],
            [
              -94.451444,
              31.008305
            ],
            [
              -94.444223,
              31.005223
            ],
            [
              -94.435317,
              31.006511
            ],
            [
              -94.432702,
              31.011147
            ],
            [
              -94.432493,
              31.013207
            ],
            [
              -94.433077,
              31.01316
            ],
            [
              -94.430341,
              31.015104
            ],
            [
              -94.426926,
              31.019566
            ],
            [
              -94.414593,
              31.026082
            ],
            [
              -94.399638,
              31.025019
            ],
            [
              -94.39386,
              31.028977
            ],
            [
              -94.385903,
              31.027747
            ],
            [
              -94.379626,
              31.031269
            ],
            [
              -94.373596,
              31.030998
            ],
            [
              -94.36056,
              31.033447
            ],
            [
              -94.354367,
              31.035869
            ],
            [
              -94.3502,
              31.038362
            ],
            [
              -94.34328,
              31.03778
            ],
            [
              -94.337017,
              31.035941
            ],
            [
              -94.334943,
              31.028906
            ],
            [
              -94.330345,
              31.024801
            ],
            [
              -94.322037,
              31.021202
            ],
            [
              -94.315016,
              31.023352
            ],
            [
              -94.298526,
              31.034586
            ],
            [
              -94.295352,
              31.041814
            ],
            [
              -94.289225,
              31.041267
            ],
            [
              -94.285413,
              31.039572
            ],
            [
              -94.284458,
              31.033274
            ],
            [
              -94.286279,
              31.027792
            ],
            [
              -94.278076,
              31.017554
            ],
            [
              -94.269905,
              31.019305
            ],
            [
              -94.25594,
              31.018488
            ],
            [
              -94.244437,
              31.011551
            ],
            [
              -94.240862,
              31.006888
            ],
            [
              -94.235296,
              30.99663
            ],
            [
              -94.233165,
              30.987958
            ],
            [
              -94.235746,
              30.98671
            ],
            [
              -94.238955,
              30.987702
            ],
            [
              -94.239928,
              30.990823
            ],
            [
              -94.241595,
              30.9904
            ],
            [
              -94.242613,
              30.988874
            ],
            [
              -94.242924,
              30.980271
            ],
            [
              -94.239314,
              30.973098
            ],
            [
              -94.242273,
              30.969361
            ],
            [
              -94.242667,
              30.962966
            ],
            [
              -94.234999,
              30.959723
            ],
            [
              -94.229866,
              30.958686
            ],
            [
              -94.230891,
              30.956006
            ],
            [
              -94.232932,
              30.953699
            ],
            [
              -94.231696,
              30.951296
            ],
            [
              -94.229847,
              30.951534
            ],
            [
              -94.228612,
              30.94897
            ],
            [
              -94.230407,
              30.948033
            ],
            [
              -94.229742,
              30.944955
            ],
            [
              -94.228466,
              30.94192
            ],
            [
              -94.225376,
              30.940024
            ],
            [
              -94.224183,
              30.942745
            ],
            [
              -94.222575,
              30.947073
            ],
            [
              -94.221921,
              30.94396
            ],
            [
              -94.221367,
              30.938731
            ],
            [
              -94.221341,
              30.935738
            ],
            [
              -94.216549,
              30.93437
            ],
            [
              -94.211474,
              30.936605
            ],
            [
              -94.206871,
              30.936619
            ],
            [
              -94.2046,
              30.935429
            ],
            [
              -94.206479,
              30.933175
            ],
            [
              -94.205539,
              30.929669
            ],
            [
              -94.20306,
              30.929278
            ],
            [
              -94.20279,
              30.927056
            ],
            [
              -94.206572,
              30.923396
            ],
            [
              -94.204623,
              30.916021
            ],
            [
              -94.206444,
              30.912542
            ],
            [
              -94.208635,
              30.909703
            ],
            [
              -94.210057,
              30.910392
            ],
            [
              -94.214078,
              30.907388
            ],
            [
              -94.214904,
              30.904198
            ],
            [
              -94.213012,
              30.899962
            ],
            [
              -94.207904,
              30.897416
            ],
            [
              -94.202632,
              30.89413
            ],
            [
              -94.202411,
              30.889337
            ],
            [
              -94.203981,
              30.885437
            ],
            [
              -94.200079,
              30.882713
            ],
            [
              -94.196307,
              30.881325
            ],
            [
              -94.194274,
              30.876637
            ],
            [
              -94.196143,
              30.87568
            ],
            [
              -94.196967,
              30.87319
            ],
            [
              -94.19441,
              30.872406
            ],
            [
              -94.194888,
              30.868895
            ],
            [
              -94.193453,
              30.863737
            ],
            [
              -94.19827,
              30.861552
            ],
            [
              -94.197219,
              30.858339
            ],
            [
              -94.198037,
              30.853293
            ],
            [
              -94.191806,
              30.844058
            ],
            [
              -94.191636,
              30.840323
            ],
            [
              -94.186466,
              30.836674
            ],
            [
              -94.181324,
              30.835575
            ],
            [
              -94.183345,
              30.834412
            ],
            [
              -94.183255,
              30.830357
            ],
            [
              -94.179895,
              30.829884
            ],
            [
              -94.180486,
              30.824678
            ],
            [
              -94.181867,
              30.822851
            ],
            [
              -94.188439,
              30.821359
            ],
            [
              -94.187562,
              30.816732
            ],
            [
              -94.184569,
              30.816035
            ],
            [
              -94.180403,
              30.81043
            ],
            [
              -94.179043,
              30.809128
            ],
            [
              -94.181283,
              30.802476
            ],
            [
              -94.180358,
              30.798338
            ],
            [
              -94.17793,
              30.794379
            ],
            [
              -94.168504,
              30.793445
            ],
            [
              -94.157705,
              30.789488
            ],
            [
              -94.146689,
              30.787451
            ],
            [
              -94.141019,
              30.781781
            ],
            [
              -94.139938,
              30.77648
            ],
            [
              -94.141524,
              30.774759
            ],
            [
              -94.139413,
              30.770964
            ],
            [
              -94.136312,
              30.769399
            ],
            [
              -94.133526,
              30.762589
            ],
            [
              -94.138184,
              30.758361
            ],
            [
              -94.137438,
              30.752713
            ],
            [
              -94.133079,
              30.751217
            ],
            [
              -94.130541,
              30.751868
            ],
            [
              -94.128458,
              30.747245
            ],
            [
              -94.130965,
              30.742646
            ],
            [
              -94.137182,
              30.733462
            ],
            [
              -94.130935,
              30.722459
            ],
            [
              -94.134141,
              30.717134
            ],
            [
              -94.133317,
              30.710845
            ],
            [
              -94.127357,
              30.704357
            ],
            [
              -94.123422,
              30.696605
            ],
            [
              -94.123707,
              30.690953
            ],
            [
              -94.126006,
              30.686663
            ],
            [
              -94.120617,
              30.68437
            ],
            [
              -94.109136,
              30.688069
            ],
            [
              -94.097386,
              30.689003
            ],
            [
              -94.092514,
              30.688483
            ],
            [
              -94.091123,
              30.683279
            ],
            [
              -94.090784,
              30.678721
            ],
            [
              -94.082895,
              30.668374
            ],
            [
              -94.083961,
              30.661946
            ],
            [
              -94.083219,
              30.657556
            ],
            [
              -94.081938,
              30.658478
            ],
            [
              -94.076802,
              30.658059
            ],
            [
              -94.073839,
              30.657996
            ],
            [
              -94.075744,
              30.66109
            ],
            [
              -94.077863,
              30.664353
            ],
            [
              -94.07687,
              30.666236
            ],
            [
              -94.0723,
              30.664984
            ],
            [
              -94.065035,
              30.662595
            ],
            [
              -94.059683,
              30.656456
            ],
            [
              -94.05475,
              30.652037
            ],
            [
              -94.053125,
              30.648991
            ],
            [
              -94.055625,
              30.647998
            ],
            [
              -94.056727,
              30.643221
            ],
            [
              -94.056235,
              30.643577
            ],
            [
              -94.05565,
              30.644733
            ],
            [
              -94.051562,
              30.641792
            ],
            [
              -94.053651,
              30.63463
            ],
            [
              -94.053744,
              30.630609
            ],
            [
              -94.054607,
              30.628904
            ],
            [
              -94.058174,
              30.626392
            ],
            [
              -94.06566,
              30.62106
            ],
            [
              -94.071761,
              30.61942
            ],
            [
              -94.074931,
              30.614729
            ],
            [
              -94.07766,
              30.60801
            ],
            [
              -94.080683,
              30.597529
            ],
            [
              -94.085532,
              30.595318
            ],
            [
              -94.086617,
              30.589789
            ],
            [
              -94.090269,
              30.584698
            ],
            [
              -94.092622,
              30.575578
            ],
            [
              -94.088452,
              30.57208
            ],
            [
              -94.089641,
              30.569491
            ],
            [
              -94.09196,
              30.569464
            ],
            [
              -94.090472,
              30.563553
            ],
            [
              -94.087184,
              30.557591
            ],
            [
              -94.086072,
              30.55288
            ],
            [
              -94.082251,
              30.546451
            ],
            [
              -94.078214,
              30.547179
            ],
            [
              -94.072628,
              30.542923
            ],
            [
              -94.072685,
              30.53428
            ],
            [
              -94.067803,
              30.530484
            ],
            [
              -94.06904,
              30.527436
            ],
            [
              -94.071689,
              30.526033
            ],
            [
              -94.084089,
              30.5056
            ],
            [
              -94.081267,
              30.488434
            ],
            [
              -94.089532,
              30.485237
            ],
            [
              -94.094735,
              30.466654
            ],
            [
              -94.107395,
              30.450312
            ],
            [
              -94.106843,
              30.437466
            ],
            [
              -94.119394,
              30.426298
            ],
            [
              -94.113061,
              30.402944
            ],
            [
              -94.103938,
              30.394212
            ],
            [
              -94.099196,
              30.361542
            ],
            [
              -94.083386,
              30.342181
            ],
            [
              -94.094926,
              30.339391
            ],
            [
              -94.105023,
              30.322965
            ],
            [
              -94.104234,
              30.307873
            ],
            [
              -94.117792,
              30.300554
            ],
            [
              -94.120526,
              30.283042
            ],
            [
              -94.107734,
              30.261381
            ],
            [
              -94.096486,
              30.257881
            ],
            [
              -94.117604,
              30.241665
            ],
            [
              -93.901452,
              30.242672
            ],
            [
              -93.865711,
              30.947218
            ],
            [
              -93.911126,
              31.158071
            ],
            [
              -94.039034,
              31.134271
            ],
            [
              -94.043234,
              31.118552
            ],
            [
              -94.129632,
              31.09928
            ],
            [
              -94.457816,
              31.033325
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "177",
      "COUNTYNS": "01383874",
      "AFFGEOID": "0500000US48177",
      "GEOID": "48177",
      "NAME": "Gonzales",
      "LSAD": "06",
      "ALAND": 2762704870,
      "AWATER": 8204086,
      "County_state": "Gonzales,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.840383,
              29.376791
            ],
            [
              -97.858782,
              29.35289
            ],
            [
              -97.728443,
              29.221748
            ],
            [
              -97.613132,
              29.109765
            ],
            [
              -97.335207,
              29.315049
            ],
            [
              -97.240108,
              29.384488
            ],
            [
              -97.202331,
              29.507527
            ],
            [
              -97.204087,
              29.580613
            ],
            [
              -97.142643,
              29.628101
            ],
            [
              -97.130517,
              29.643296
            ],
            [
              -97.317893,
              29.784658
            ],
            [
              -97.597743,
              29.630718
            ],
            [
              -97.617497,
              29.633553
            ],
            [
              -97.616909,
              29.644782
            ],
            [
              -97.633176,
              29.649863
            ],
            [
              -97.664987,
              29.60772
            ],
            [
              -97.746585,
              29.500335
            ],
            [
              -97.753859,
              29.488582
            ],
            [
              -97.840383,
              29.376791
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "245",
      "COUNTYNS": "01383908",
      "AFFGEOID": "0500000US48245",
      "GEOID": "48245",
      "NAME": "Jefferson",
      "LSAD": "06",
      "ALAND": 2269793052,
      "AWATER": 611960906,
      "County_state": "Jefferson,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.44491,
              30.113015
            ],
            [
              -94.442235,
              29.889538
            ],
            [
              -94.442204,
              29.887053
            ],
            [
              -94.357976,
              29.887464
            ],
            [
              -94.353948,
              29.611497
            ],
            [
              -94.3541242423198,
              29.5620753185838
            ],
            [
              -94.133709,
              29.647289
            ],
            [
              -94.095762,
              29.660524
            ],
            [
              -94.056506,
              29.671163
            ],
            [
              -94.001406,
              29.681486
            ],
            [
              -93.931632,
              29.68276
            ],
            [
              -93.889536,
              29.680621
            ],
            [
              -93.861291,
              29.679007
            ],
            [
              -93.837725,
              29.679024
            ],
            [
              -93.840643,
              29.685757
            ],
            [
              -93.837971,
              29.690619
            ],
            [
              -93.863204,
              29.724059
            ],
            [
              -93.87002,
              29.735482
            ],
            [
              -93.873941,
              29.73777
            ],
            [
              -93.888821,
              29.742234
            ],
            [
              -93.891637,
              29.744618
            ],
            [
              -93.893829,
              29.753033
            ],
            [
              -93.890821,
              29.761673
            ],
            [
              -93.893862,
              29.767289
            ],
            [
              -93.89847,
              29.771577
            ],
            [
              -93.922407,
              29.785048
            ],
            [
              -93.926504,
              29.78956
            ],
            [
              -93.928808,
              29.79708
            ],
            [
              -93.929208,
              29.802952
            ],
            [
              -93.927992,
              29.80964
            ],
            [
              -93.922744,
              29.818808
            ],
            [
              -93.91636,
              29.824968
            ],
            [
              -93.900728,
              29.836967
            ],
            [
              -93.890679,
              29.843159
            ],
            [
              -93.872446,
              29.85165
            ],
            [
              -93.86357,
              29.857177
            ],
            [
              -93.85514,
              29.864099
            ],
            [
              -93.85434264083091,
              29.8649909997958
            ],
            [
              -93.841095,
              29.91287
            ],
            [
              -93.8398,
              29.943861
            ],
            [
              -93.855977,
              29.968725
            ],
            [
              -93.860169,
              29.977397
            ],
            [
              -93.903471,
              29.985513
            ],
            [
              -93.913781,
              29.995988
            ],
            [
              -93.931072,
              29.991113
            ],
            [
              -93.954373,
              30.001113
            ],
            [
              -93.957473,
              30.011812
            ],
            [
              -93.983174,
              30.010813
            ],
            [
              -93.986174,
              30.021612
            ],
            [
              -93.996774,
              30.013113
            ],
            [
              -93.998575,
              30.029156
            ],
            [
              -94.031976,
              30.028013
            ],
            [
              -94.031576,
              30.060712
            ],
            [
              -94.057777,
              30.074812
            ],
            [
              -94.083578,
              30.076912
            ],
            [
              -94.093539,
              30.083684
            ],
            [
              -94.087278,
              30.104912
            ],
            [
              -94.073678,
              30.114111
            ],
            [
              -94.079578,
              30.127111
            ],
            [
              -94.108432,
              30.137942
            ],
            [
              -94.115917,
              30.160347
            ],
            [
              -94.169016,
              30.173448
            ],
            [
              -94.17644,
              30.18794
            ],
            [
              -94.213164,
              30.182137
            ],
            [
              -94.234932,
              30.165939
            ],
            [
              -94.275644,
              30.170095
            ],
            [
              -94.276569,
              30.139965
            ],
            [
              -94.292629,
              30.117671
            ],
            [
              -94.331627,
              30.11399
            ],
            [
              -94.341854,
              30.103221
            ],
            [
              -94.364062,
              30.101583
            ],
            [
              -94.375728,
              30.109215
            ],
            [
              -94.399233,
              30.097403
            ],
            [
              -94.44491,
              30.113015
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "127",
      "COUNTYNS": "01383849",
      "AFFGEOID": "0500000US48127",
      "GEOID": "48127",
      "NAME": "Dimmit",
      "LSAD": "06",
      "ALAND": 3441795365,
      "AWATER": 14576538,
      "County_state": "Dimmit,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.114336,
              28.648122
            ],
            [
              -100.113719,
              28.197819
            ],
            [
              -99.394177,
              28.204627
            ],
            [
              -99.395672,
              28.252278
            ],
            [
              -99.395736,
              28.640784
            ],
            [
              -99.409021,
              28.640566
            ],
            [
              -99.493381,
              28.641336
            ],
            [
              -99.526761,
              28.642573
            ],
            [
              -100.114336,
              28.648122
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "037",
      "COUNTYNS": "01383804",
      "AFFGEOID": "0500000US48037",
      "GEOID": "48037",
      "NAME": "Bowie",
      "LSAD": "06",
      "ALAND": 2292153750,
      "AWATER": 98319961,
      "County_state": "Bowie,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.746096,
              33.703016
            ],
            [
              -94.746925,
              33.328938
            ],
            [
              -94.73031,
              33.308814
            ],
            [
              -94.691826,
              33.312405
            ],
            [
              -94.686314,
              33.292777
            ],
            [
              -94.65226,
              33.268861
            ],
            [
              -94.641714,
              33.253872
            ],
            [
              -94.61864,
              33.246833
            ],
            [
              -94.590455,
              33.259424
            ],
            [
              -94.586039,
              33.249722
            ],
            [
              -94.572463,
              33.249826
            ],
            [
              -94.568972,
              33.264328
            ],
            [
              -94.559347,
              33.261054
            ],
            [
              -94.540833,
              33.269367
            ],
            [
              -94.529954,
              33.26735
            ],
            [
              -94.517868,
              33.282096
            ],
            [
              -94.472247,
              33.288541
            ],
            [
              -94.468442,
              33.294313
            ],
            [
              -94.444228,
              33.281247
            ],
            [
              -94.432995,
              33.28745
            ],
            [
              -94.415739,
              33.284017
            ],
            [
              -94.411294,
              33.27084
            ],
            [
              -94.379409,
              33.255651
            ],
            [
              -94.357096,
              33.263154
            ],
            [
              -94.344673,
              33.252723
            ],
            [
              -94.332412,
              33.256152
            ],
            [
              -94.312033,
              33.248793
            ],
            [
              -94.280687,
              33.245632
            ],
            [
              -94.231333,
              33.241073
            ],
            [
              -94.240017,
              33.273384
            ],
            [
              -94.219235,
              33.287292
            ],
            [
              -94.218517,
              33.296936
            ],
            [
              -94.186946,
              33.305495
            ],
            [
              -94.172588,
              33.301811
            ],
            [
              -94.166347,
              33.311241
            ],
            [
              -94.140176,
              33.294049
            ],
            [
              -94.120492,
              33.292465
            ],
            [
              -94.115914,
              33.305145
            ],
            [
              -94.090752,
              33.305267
            ],
            [
              -94.07331,
              33.292046
            ],
            [
              -94.0430248501853,
              33.298053043318596
            ],
            [
              -94.043067,
              33.330498
            ],
            [
              -94.043067,
              33.347351
            ],
            [
              -94.043067,
              33.352097
            ],
            [
              -94.043128,
              33.358757
            ],
            [
              -94.042869,
              33.37117
            ],
            [
              -94.043053,
              33.377716
            ],
            [
              -94.04302105190601,
              33.3858972140335
            ],
            [
              -94.042887,
              33.420225
            ],
            [
              -94.042988,
              33.431024
            ],
            [
              -94.042988,
              33.435824
            ],
            [
              -94.043188,
              33.470324
            ],
            [
              -94.043279,
              33.49103
            ],
            [
              -94.0432454539383,
              33.491279607547696
            ],
            [
              -94.043009,
              33.493039
            ],
            [
              -94.043375,
              33.542315
            ],
            [
              -94.043428,
              33.551425
            ],
            [
              -94.04345,
              33.552253
            ],
            [
              -94.04604,
              33.551321
            ],
            [
              -94.050212,
              33.551083
            ],
            [
              -94.056096,
              33.567252
            ],
            [
              -94.07267,
              33.572234
            ],
            [
              -94.103176,
              33.57035
            ],
            [
              -94.123898,
              33.5521
            ],
            [
              -94.143024,
              33.577725
            ],
            [
              -94.162266,
              33.588906
            ],
            [
              -94.183395,
              33.592212
            ],
            [
              -94.211329,
              33.573774
            ],
            [
              -94.226392,
              33.552912
            ],
            [
              -94.250197,
              33.556765
            ],
            [
              -94.238868,
              33.576722
            ],
            [
              -94.252656,
              33.586144
            ],
            [
              -94.270853,
              33.564783
            ],
            [
              -94.280605,
              33.574908
            ],
            [
              -94.298392,
              33.576218
            ],
            [
              -94.309582,
              33.551673
            ],
            [
              -94.33059,
              33.552692
            ],
            [
              -94.338422,
              33.567082
            ],
            [
              -94.352653,
              33.560611
            ],
            [
              -94.35897,
              33.54323
            ],
            [
              -94.392573,
              33.551142
            ],
            [
              -94.388052,
              33.565511
            ],
            [
              -94.37776,
              33.574609
            ],
            [
              -94.385927,
              33.581888
            ],
            [
              -94.419057,
              33.577217
            ],
            [
              -94.436215,
              33.594199
            ],
            [
              -94.458232,
              33.59827
            ],
            [
              -94.452325,
              33.618817
            ],
            [
              -94.459198,
              33.645146
            ],
            [
              -94.481313,
              33.638819
            ],
            [
              -94.485875,
              33.637867
            ],
            [
              -94.487514,
              33.628939
            ],
            [
              -94.491503,
              33.625115
            ],
            [
              -94.504615,
              33.620682
            ],
            [
              -94.520725,
              33.616567
            ],
            [
              -94.526291,
              33.619203
            ],
            [
              -94.528928,
              33.62184
            ],
            [
              -94.529807,
              33.627406
            ],
            [
              -94.528342,
              33.62975
            ],
            [
              -94.529221,
              33.634437
            ],
            [
              -94.533322,
              33.63766
            ],
            [
              -94.538889,
              33.637953
            ],
            [
              -94.543869,
              33.635902
            ],
            [
              -94.549142,
              33.635902
            ],
            [
              -94.552658,
              33.638246
            ],
            [
              -94.553537,
              33.642054
            ],
            [
              -94.551312,
              33.64457
            ],
            [
              -94.551193,
              33.650257
            ],
            [
              -94.552072,
              33.65348
            ],
            [
              -94.557052,
              33.656702
            ],
            [
              -94.564669,
              33.655824
            ],
            [
              -94.568771,
              33.654652
            ],
            [
              -94.570821,
              33.654945
            ],
            [
              -94.572286,
              33.656995
            ],
            [
              -94.571993,
              33.659632
            ],
            [
              -94.569357,
              33.663441
            ],
            [
              -94.569943,
              33.66637
            ],
            [
              -94.572872,
              33.669886
            ],
            [
              -94.576974,
              33.673401
            ],
            [
              -94.57962,
              33.677623
            ],
            [
              -94.586641,
              33.678968
            ],
            [
              -94.59045,
              33.677503
            ],
            [
              -94.593673,
              33.673987
            ],
            [
              -94.596895,
              33.671351
            ],
            [
              -94.603047,
              33.671351
            ],
            [
              -94.607442,
              33.67223
            ],
            [
              -94.611543,
              33.674866
            ],
            [
              -94.616817,
              33.679554
            ],
            [
              -94.621211,
              33.681018
            ],
            [
              -94.627656,
              33.677796
            ],
            [
              -94.630586,
              33.673401
            ],
            [
              -94.635273,
              33.669886
            ],
            [
              -94.64289,
              33.668421
            ],
            [
              -94.646113,
              33.6693
            ],
            [
              -94.648457,
              33.673401
            ],
            [
              -94.647871,
              33.680432
            ],
            [
              -94.648457,
              33.684534
            ],
            [
              -94.649628,
              33.688049
            ],
            [
              -94.652265,
              33.690979
            ],
            [
              -94.659167,
              33.692138
            ],
            [
              -94.684792,
              33.684353
            ],
            [
              -94.707858,
              33.686876
            ],
            [
              -94.710088,
              33.68815
            ],
            [
              -94.710725,
              33.691654
            ],
            [
              -94.710725,
              33.696113
            ],
            [
              -94.709451,
              33.699617
            ],
            [
              -94.711043,
              33.705669
            ],
            [
              -94.714865,
              33.707261
            ],
            [
              -94.719006,
              33.708217
            ],
            [
              -94.721873,
              33.707261
            ],
            [
              -94.724102,
              33.705669
            ],
            [
              -94.725695,
              33.702483
            ],
            [
              -94.728243,
              33.699617
            ],
            [
              -94.732384,
              33.700254
            ],
            [
              -94.737161,
              33.704713
            ],
            [
              -94.7376180019138,
              33.7060079583273
            ],
            [
              -94.746096,
              33.703016
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "043",
      "COUNTYNS": "01383807",
      "AFFGEOID": "0500000US48043",
      "GEOID": "48043",
      "NAME": "Brewster",
      "LSAD": "06",
      "ALAND": 16015869999,
      "AWATER": 22063764,
      "County_state": "Brewster,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.80068,
              30.412527
            ],
            [
              -103.797514,
              29.875173
            ],
            [
              -103.792764,
              29.27253
            ],
            [
              -103.79396654852101,
              29.259900560703098
            ],
            [
              -103.789034,
              29.257502
            ],
            [
              -103.78166,
              29.248804
            ],
            [
              -103.777623,
              29.232265
            ],
            [
              -103.76857,
              29.227361
            ],
            [
              -103.755943,
              29.225545
            ],
            [
              -103.742175,
              29.20861
            ],
            [
              -103.724743,
              29.19147
            ],
            [
              -103.71377,
              29.185008
            ],
            [
              -103.697314,
              29.177194
            ],
            [
              -103.68867,
              29.178294
            ],
            [
              -103.660203,
              29.170934
            ],
            [
              -103.656808,
              29.169099
            ],
            [
              -103.65318,
              29.162864
            ],
            [
              -103.645635,
              29.159286
            ],
            [
              -103.61054,
              29.165773
            ],
            [
              -103.606438,
              29.160411
            ],
            [
              -103.59236,
              29.15026
            ],
            [
              -103.579462,
              29.149965
            ],
            [
              -103.558679,
              29.154962
            ],
            [
              -103.525027,
              29.137354
            ],
            [
              -103.523384,
              29.133389
            ],
            [
              -103.524613,
              29.120998
            ],
            [
              -103.503236,
              29.11911
            ],
            [
              -103.484429,
              29.094524
            ],
            [
              -103.474815,
              29.086671
            ],
            [
              -103.471426,
              29.081345
            ],
            [
              -103.471265,
              29.073115
            ],
            [
              -103.469167,
              29.069242
            ],
            [
              -103.463196,
              29.066822
            ],
            [
              -103.457386,
              29.068597
            ],
            [
              -103.453029,
              29.067467
            ],
            [
              -103.434668,
              29.055317
            ],
            [
              -103.427754,
              29.042334
            ],
            [
              -103.382125,
              29.024249
            ],
            [
              -103.361998,
              29.018914
            ],
            [
              -103.355428,
              29.021529
            ],
            [
              -103.350816,
              29.028567
            ],
            [
              -103.34787,
              29.037428
            ],
            [
              -103.341463,
              29.041224
            ],
            [
              -103.334819,
              29.039801
            ],
            [
              -103.332446,
              29.033394
            ],
            [
              -103.33292,
              29.026987
            ],
            [
              -103.331022,
              29.021766
            ],
            [
              -103.312987,
              29.010139
            ],
            [
              -103.296614,
              29.004444
            ],
            [
              -103.289258,
              28.999698
            ],
            [
              -103.285936,
              28.994003
            ],
            [
              -103.284749,
              28.987121
            ],
            [
              -103.28119,
              28.982138
            ],
            [
              -103.273357,
              28.982844
            ],
            [
              -103.270357,
              28.988114
            ],
            [
              -103.266003,
              28.990206
            ],
            [
              -103.260308,
              28.989731
            ],
            [
              -103.253285,
              28.986687
            ],
            [
              -103.249155,
              28.980952
            ],
            [
              -103.245121,
              28.98024
            ],
            [
              -103.239109,
              28.981651
            ],
            [
              -103.227801,
              28.991532
            ],
            [
              -103.166235,
              28.978836
            ],
            [
              -103.165923,
              28.974002
            ],
            [
              -103.163865,
              28.972099
            ],
            [
              -103.156646,
              28.972831
            ],
            [
              -103.143274,
              28.978074
            ],
            [
              -103.134931,
              28.983525
            ],
            [
              -103.126748,
              28.982124
            ],
            [
              -103.115328,
              28.98527
            ],
            [
              -103.113922,
              28.988547
            ],
            [
              -103.117238,
              29.000209
            ],
            [
              -103.107811,
              29.013812
            ],
            [
              -103.101608,
              29.018123
            ],
            [
              -103.100368,
              29.026877
            ],
            [
              -103.102532,
              29.040507
            ],
            [
              -103.100266,
              29.0577
            ],
            [
              -103.076847,
              29.076059
            ],
            [
              -103.076355,
              29.085722
            ],
            [
              -103.074407,
              29.088534
            ],
            [
              -103.05537,
              29.096539
            ],
            [
              -103.040442,
              29.099351
            ],
            [
              -103.035683,
              29.103029
            ],
            [
              -103.033303,
              29.107356
            ],
            [
              -103.032983,
              29.114118
            ],
            [
              -103.015028,
              29.12577
            ],
            [
              -103.010325,
              29.137822
            ],
            [
              -103.008362,
              29.148293
            ],
            [
              -103.002434,
              29.150261
            ],
            [
              -102.995688,
              29.161219
            ],
            [
              -102.998096,
              29.172676
            ],
            [
              -102.994653,
              29.17962
            ],
            [
              -102.989432,
              29.183174
            ],
            [
              -102.977266,
              29.186226
            ],
            [
              -102.953475,
              29.176308
            ],
            [
              -102.95089,
              29.176835
            ],
            [
              -102.947156,
              29.180777
            ],
            [
              -102.944911,
              29.18882
            ],
            [
              -102.932612,
              29.194113
            ],
            [
              -102.925482,
              29.193723
            ],
            [
              -102.917805,
              29.190697
            ],
            [
              -102.912448,
              29.206255
            ],
            [
              -102.915554,
              29.211342
            ],
            [
              -102.915866,
              29.215878
            ],
            [
              -102.912131,
              29.218902
            ],
            [
              -102.908787,
              29.219337
            ],
            [
              -102.899231,
              29.208863
            ],
            [
              -102.890064,
              29.208814
            ],
            [
              -102.87802,
              29.214698
            ],
            [
              -102.866846,
              29.225015
            ],
            [
              -102.871347,
              29.241625
            ],
            [
              -102.881135,
              29.246022
            ],
            [
              -102.887902,
              29.245612
            ],
            [
              -102.903189,
              29.254029
            ],
            [
              -102.906296,
              29.260011
            ],
            [
              -102.902605,
              29.279441
            ],
            [
              -102.891022,
              29.287113
            ],
            [
              -102.888328,
              29.291947
            ],
            [
              -102.890489,
              29.309499
            ],
            [
              -102.879805,
              29.338755
            ],
            [
              -102.883722,
              29.348059
            ],
            [
              -102.879534,
              29.353327
            ],
            [
              -102.876866,
              29.354059
            ],
            [
              -102.871857,
              29.352093
            ],
            [
              -102.86163,
              29.351639
            ],
            [
              -102.843021,
              29.357988
            ],
            [
              -102.840778,
              29.376962
            ],
            [
              -102.824564,
              29.399558
            ],
            [
              -102.827355,
              29.418262
            ],
            [
              -102.832539,
              29.433109
            ],
            [
              -102.83097,
              29.444267
            ],
            [
              -102.813954,
              29.482806
            ],
            [
              -102.807327,
              29.494009
            ],
            [
              -102.808692,
              29.522319
            ],
            [
              -102.771429,
              29.548546
            ],
            [
              -102.775725,
              29.552189
            ],
            [
              -102.777531,
              29.556497
            ],
            [
              -102.773961,
              29.573084
            ],
            [
              -102.766124,
              29.572348
            ],
            [
              -102.762241,
              29.579449
            ],
            [
              -102.768341,
              29.594734
            ],
            [
              -102.761811,
              29.598397
            ],
            [
              -102.757066,
              29.597799
            ],
            [
              -102.746202,
              29.592875
            ],
            [
              -102.739991,
              29.599041
            ],
            [
              -102.738428,
              29.621929
            ],
            [
              -102.742031,
              29.632142
            ],
            [
              -102.736948,
              29.641538
            ],
            [
              -102.724231,
              29.648415
            ],
            [
              -102.693466,
              29.676507
            ],
            [
              -102.699317,
              29.685029
            ],
            [
              -102.698347,
              29.695591
            ],
            [
              -102.690238,
              29.707482
            ],
            [
              -102.688164,
              29.722487
            ],
            [
              -102.677192,
              29.738261
            ],
            [
              -102.670971,
              29.741954
            ],
            [
              -102.661252,
              29.736123
            ],
            [
              -102.645665,
              29.73391
            ],
            [
              -102.630151,
              29.734315
            ],
            [
              -102.622534,
              29.736632
            ],
            [
              -102.612879,
              29.748182
            ],
            [
              -102.59716,
              29.751608
            ],
            [
              -102.587774,
              29.750657
            ],
            [
              -102.576353,
              29.754302
            ],
            [
              -102.565661,
              29.761592
            ],
            [
              -102.559343,
              29.760377
            ],
            [
              -102.551081,
              29.752358
            ],
            [
              -102.545492,
              29.7509
            ],
            [
              -102.539417,
              29.751629
            ],
            [
              -102.513381,
              29.76576
            ],
            [
              -102.512687,
              29.780303
            ],
            [
              -102.508313,
              29.783219
            ],
            [
              -102.490331,
              29.783948
            ],
            [
              -102.468946,
              29.779817
            ],
            [
              -102.433301,
              29.776608
            ],
            [
              -102.412062,
              29.768062
            ],
            [
              -102.392906,
              29.765569
            ],
            [
              -102.386678,
              29.76688
            ],
            [
              -102.377313,
              29.789971
            ],
            [
              -102.377254,
              29.800163
            ],
            [
              -102.369522,
              29.820395
            ],
            [
              -102.362514,
              29.833156
            ],
            [
              -102.364542,
              29.845387
            ],
            [
              -102.349861,
              29.862317
            ],
            [
              -102.341033,
              29.869305
            ],
            [
              -102.333384,
              29.868046
            ],
            [
              -102.324634,
              29.872307
            ],
            [
              -102.320692921451,
              29.8788571412597
            ],
            [
              -102.567049,
              30.052808
            ],
            [
              -103.334688,
              30.592561
            ],
            [
              -103.439976,
              30.665939
            ],
            [
              -103.80068,
              30.412527
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "111",
      "COUNTYNS": "01383841",
      "AFFGEOID": "0500000US48111",
      "GEOID": "48111",
      "NAME": "Dallam",
      "LSAD": "06",
      "ALAND": 3893093470,
      "AWATER": 5182189,
      "County_state": "Dallam,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.041924,
              36.500439
            ],
            [
              -103.041745,
              36.318267
            ],
            [
              -103.041674,
              36.317534
            ],
            [
              -103.040824,
              36.055231
            ],
            [
              -102.163015,
              36.055249
            ],
            [
              -102.162463,
              36.500326
            ],
            [
              -102.24499,
              36.500704
            ],
            [
              -102.250453,
              36.500369
            ],
            [
              -103.002434,
              36.500397
            ],
            [
              -103.041924,
              36.500439
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "067",
      "COUNTYNS": "01383819",
      "AFFGEOID": "0500000US48067",
      "GEOID": "48067",
      "NAME": "Cass",
      "LSAD": "06",
      "ALAND": 2426706440,
      "AWATER": 60517774,
      "County_state": "Cass,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.65226,
              33.268861
            ],
            [
              -94.653994,
              32.879506
            ],
            [
              -94.050329,
              32.880884
            ],
            [
              -94.0428857174211,
              32.881088560255
            ],
            [
              -94.042859,
              32.892771
            ],
            [
              -94.042885,
              32.898911
            ],
            [
              -94.043092,
              32.910021
            ],
            [
              -94.043067,
              32.937903
            ],
            [
              -94.043088,
              32.955592
            ],
            [
              -94.042964,
              33.019219
            ],
            [
              -94.043036,
              33.079485
            ],
            [
              -94.04287,
              33.092727
            ],
            [
              -94.043007,
              33.13389
            ],
            [
              -94.043077,
              33.138162
            ],
            [
              -94.043185,
              33.143476
            ],
            [
              -94.042719,
              33.160291
            ],
            [
              -94.042875,
              33.199785
            ],
            [
              -94.042892,
              33.202666
            ],
            [
              -94.042876,
              33.215219
            ],
            [
              -94.04273,
              33.241823
            ],
            [
              -94.043004,
              33.250128
            ],
            [
              -94.04305,
              33.260904
            ],
            [
              -94.04299,
              33.271227
            ],
            [
              -94.04299002100379,
              33.271243167748096
            ],
            [
              -94.0430248501853,
              33.298053043318596
            ],
            [
              -94.07331,
              33.292046
            ],
            [
              -94.090752,
              33.305267
            ],
            [
              -94.115914,
              33.305145
            ],
            [
              -94.120492,
              33.292465
            ],
            [
              -94.140176,
              33.294049
            ],
            [
              -94.166347,
              33.311241
            ],
            [
              -94.172588,
              33.301811
            ],
            [
              -94.186946,
              33.305495
            ],
            [
              -94.218517,
              33.296936
            ],
            [
              -94.219235,
              33.287292
            ],
            [
              -94.240017,
              33.273384
            ],
            [
              -94.231333,
              33.241073
            ],
            [
              -94.280687,
              33.245632
            ],
            [
              -94.312033,
              33.248793
            ],
            [
              -94.332412,
              33.256152
            ],
            [
              -94.344673,
              33.252723
            ],
            [
              -94.357096,
              33.263154
            ],
            [
              -94.379409,
              33.255651
            ],
            [
              -94.411294,
              33.27084
            ],
            [
              -94.415739,
              33.284017
            ],
            [
              -94.432995,
              33.28745
            ],
            [
              -94.444228,
              33.281247
            ],
            [
              -94.468442,
              33.294313
            ],
            [
              -94.472247,
              33.288541
            ],
            [
              -94.517868,
              33.282096
            ],
            [
              -94.529954,
              33.26735
            ],
            [
              -94.540833,
              33.269367
            ],
            [
              -94.559347,
              33.261054
            ],
            [
              -94.568972,
              33.264328
            ],
            [
              -94.572463,
              33.249826
            ],
            [
              -94.586039,
              33.249722
            ],
            [
              -94.590455,
              33.259424
            ],
            [
              -94.61864,
              33.246833
            ],
            [
              -94.641714,
              33.253872
            ],
            [
              -94.65226,
              33.268861
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "149",
      "COUNTYNS": "01383860",
      "AFFGEOID": "0500000US48149",
      "GEOID": "48149",
      "NAME": "Fayette",
      "LSAD": "06",
      "ALAND": 2460152119,
      "AWATER": 25739448,
      "County_state": "Fayette,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.315823,
              29.786541
            ],
            [
              -97.317893,
              29.784658
            ],
            [
              -97.130517,
              29.643296
            ],
            [
              -97.142643,
              29.628101
            ],
            [
              -96.874222,
              29.632706
            ],
            [
              -96.717043,
              29.792706
            ],
            [
              -96.569844,
              29.961516
            ],
            [
              -96.62198,
              30.044283
            ],
            [
              -96.623863,
              30.066713
            ],
            [
              -96.634289,
              30.075701
            ],
            [
              -96.633673,
              30.106142
            ],
            [
              -96.640649,
              30.120579
            ],
            [
              -96.653112,
              30.12999
            ],
            [
              -96.647223,
              30.145174
            ],
            [
              -96.683394,
              30.151038
            ],
            [
              -96.687141,
              30.16435
            ],
            [
              -96.714989,
              30.144983
            ],
            [
              -96.735445,
              30.147393
            ],
            [
              -96.766937,
              30.138745
            ],
            [
              -96.773016,
              30.149466
            ],
            [
              -96.794552,
              30.160545
            ],
            [
              -96.845857,
              30.116027
            ],
            [
              -97.019163,
              30.032
            ],
            [
              -97.024461,
              30.051435
            ],
            [
              -97.194235,
              29.896776
            ],
            [
              -97.315823,
              29.786541
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "053",
      "COUNTYNS": "01383812",
      "AFFGEOID": "0500000US48053",
      "GEOID": "48053",
      "NAME": "Burnet",
      "LSAD": "06",
      "ALAND": 2575134235,
      "AWATER": 69922923,
      "County_state": "Burnet,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.459235,
              31.007745
            ],
            [
              -98.441874,
              30.984324
            ],
            [
              -98.452897,
              30.968727
            ],
            [
              -98.447098,
              30.951755
            ],
            [
              -98.414508,
              30.94211
            ],
            [
              -98.418746,
              30.92626
            ],
            [
              -98.445782,
              30.921439
            ],
            [
              -98.448753,
              30.890335
            ],
            [
              -98.457382,
              30.878897
            ],
            [
              -98.448326,
              30.870588
            ],
            [
              -98.431557,
              30.869432
            ],
            [
              -98.41935,
              30.859121
            ],
            [
              -98.421593,
              30.834997
            ],
            [
              -98.404977,
              30.843823
            ],
            [
              -98.376877,
              30.83489
            ],
            [
              -98.390053,
              30.818436
            ],
            [
              -98.403336,
              30.817936
            ],
            [
              -98.41391,
              30.794306
            ],
            [
              -98.431996,
              30.790436
            ],
            [
              -98.422531,
              30.775042
            ],
            [
              -98.430214,
              30.76096
            ],
            [
              -98.424046,
              30.750507
            ],
            [
              -98.375237,
              30.746574
            ],
            [
              -98.371905,
              30.740485
            ],
            [
              -98.392654,
              30.721233
            ],
            [
              -98.404152,
              30.721083
            ],
            [
              -98.413962,
              30.704392
            ],
            [
              -98.403984,
              30.676203
            ],
            [
              -98.438995,
              30.673285
            ],
            [
              -98.439087,
              30.66251
            ],
            [
              -98.428352,
              30.66073
            ],
            [
              -98.418594,
              30.645926
            ],
            [
              -98.417595,
              30.627274
            ],
            [
              -98.400181,
              30.598449
            ],
            [
              -98.411835,
              30.588182
            ],
            [
              -98.411148,
              30.574644
            ],
            [
              -98.398758,
              30.56373
            ],
            [
              -98.375946,
              30.573894
            ],
            [
              -98.364288,
              30.571592
            ],
            [
              -98.351983,
              30.558393
            ],
            [
              -98.351041,
              30.486096
            ],
            [
              -98.125556,
              30.426186
            ],
            [
              -98.097709,
              30.467626
            ],
            [
              -98.097793,
              30.497442
            ],
            [
              -98.123111,
              30.484723
            ],
            [
              -98.049886,
              30.624155
            ],
            [
              -97.96286,
              30.785641
            ],
            [
              -97.828512,
              30.906188
            ],
            [
              -97.840365,
              30.929318
            ],
            [
              -97.88572,
              31.000394
            ],
            [
              -97.911684,
              31.034919
            ],
            [
              -98.439687,
              31.029537
            ],
            [
              -98.459235,
              31.007745
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "391",
      "COUNTYNS": "01383981",
      "AFFGEOID": "0500000US48391",
      "GEOID": "48391",
      "NAME": "Refugio",
      "LSAD": "06",
      "ALAND": 1995545559,
      "AWATER": 123687613,
      "County_state": "Refugio,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.541071,
              28.164669
            ],
            [
              -97.521211,
              28.151341
            ],
            [
              -97.495091,
              28.1659
            ],
            [
              -97.482384,
              28.168616
            ],
            [
              -97.476366,
              28.179461
            ],
            [
              -97.470076,
              28.154387
            ],
            [
              -97.459549,
              28.160045
            ],
            [
              -97.438847,
              28.13897
            ],
            [
              -97.422141,
              28.141749
            ],
            [
              -97.427171,
              28.117668
            ],
            [
              -97.399799,
              28.119953
            ],
            [
              -97.403304,
              28.130711
            ],
            [
              -97.384833,
              28.123951
            ],
            [
              -97.379803,
              28.137386
            ],
            [
              -97.355886,
              28.123142
            ],
            [
              -97.343902,
              28.120633
            ],
            [
              -97.34326,
              28.13019
            ],
            [
              -97.325314,
              28.126584
            ],
            [
              -97.324958,
              28.119746
            ],
            [
              -97.317163,
              28.137189
            ],
            [
              -97.309037,
              28.120017
            ],
            [
              -97.318153,
              28.112909
            ],
            [
              -97.303816,
              28.104005
            ],
            [
              -97.287259,
              28.110734
            ],
            [
              -97.283984,
              28.091331
            ],
            [
              -97.264272,
              28.088884
            ],
            [
              -97.260818,
              28.075759
            ],
            [
              -97.239364,
              28.061396
            ],
            [
              -97.2153495489569,
              28.076575511947002
            ],
            [
              -97.214039,
              28.087494
            ],
            [
              -97.153601,
              28.13318
            ],
            [
              -97.037008,
              28.185528
            ],
            [
              -97.0278242472443,
              28.184692953904197
            ],
            [
              -97.017539,
              28.202876
            ],
            [
              -97.037422,
              28.201296
            ],
            [
              -97.0432,
              28.209
            ],
            [
              -97.054315,
              28.203791
            ],
            [
              -97.065035,
              28.214545
            ],
            [
              -97.101952,
              28.226385
            ],
            [
              -97.097865,
              28.232045
            ],
            [
              -97.125337,
              28.253263
            ],
            [
              -97.117474,
              28.260041
            ],
            [
              -97.124252,
              28.271435
            ],
            [
              -96.79115922523539,
              28.3190954393755
            ],
            [
              -96.790744,
              28.323874
            ],
            [
              -96.794306,
              28.34932
            ],
            [
              -96.794815,
              28.364587
            ],
            [
              -96.790235,
              28.383926
            ],
            [
              -96.775985,
              28.405809
            ],
            [
              -96.768352,
              28.410389
            ],
            [
              -96.7669593744276,
              28.4107372134025
            ],
            [
              -96.764132,
              28.426522
            ],
            [
              -96.789396,
              28.44717
            ],
            [
              -96.813494,
              28.430261
            ],
            [
              -96.823485,
              28.445582
            ],
            [
              -96.839916,
              28.458882
            ],
            [
              -96.848402,
              28.476071
            ],
            [
              -96.86256,
              28.477512
            ],
            [
              -96.864821,
              28.490235
            ],
            [
              -96.890951,
              28.507178
            ],
            [
              -96.901722,
              28.509639
            ],
            [
              -96.91551,
              28.499174
            ],
            [
              -96.9155,
              28.485712
            ],
            [
              -96.955547,
              28.504358
            ],
            [
              -96.982147,
              28.499639
            ],
            [
              -97.004635,
              28.508909
            ],
            [
              -97.019738,
              28.520261
            ],
            [
              -97.030616,
              28.541527
            ],
            [
              -97.039863,
              28.532074
            ],
            [
              -97.051385,
              28.537033
            ],
            [
              -97.045395,
              28.54999
            ],
            [
              -97.061864,
              28.543843
            ],
            [
              -97.07201,
              28.547102
            ],
            [
              -97.077132,
              28.537762
            ],
            [
              -97.090837,
              28.534575
            ],
            [
              -97.105167,
              28.554807
            ],
            [
              -97.125049,
              28.545307
            ],
            [
              -97.140263,
              28.553828
            ],
            [
              -97.147817,
              28.54637
            ],
            [
              -97.160751,
              28.553475
            ],
            [
              -97.375579,
              28.388684
            ],
            [
              -97.541071,
              28.164669
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "357",
      "COUNTYNS": "01383964",
      "AFFGEOID": "0500000US48357",
      "GEOID": "48357",
      "NAME": "Ochiltree",
      "LSAD": "06",
      "ALAND": 2376792102,
      "AWATER": 1331778,
      "County_state": "Ochiltree,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.085156,
              36.499244
            ],
            [
              -101.085716,
              36.057572
            ],
            [
              -100.546724,
              36.056536
            ],
            [
              -100.546145,
              36.499343
            ],
            [
              -100.578114,
              36.499439
            ],
            [
              -100.578114,
              36.499463
            ],
            [
              -100.583379,
              36.499443
            ],
            [
              -100.583539,
              36.499483
            ],
            [
              -100.592551,
              36.499429
            ],
            [
              -100.592556,
              36.499469
            ],
            [
              -100.592614,
              36.499469
            ],
            [
              -100.648344,
              36.499463
            ],
            [
              -100.648343,
              36.499495
            ],
            [
              -100.657763,
              36.4995
            ],
            [
              -100.657763,
              36.499483
            ],
            [
              -100.708628,
              36.499521
            ],
            [
              -100.708626,
              36.499553
            ],
            [
              -100.724361,
              36.499558
            ],
            [
              -100.724362,
              36.49958
            ],
            [
              -100.761811,
              36.49958
            ],
            [
              -100.761811,
              36.499618
            ],
            [
              -100.802886,
              36.499621
            ],
            [
              -100.802909,
              36.499621
            ],
            [
              -100.806172,
              36.499634
            ],
            [
              -100.80619,
              36.499674
            ],
            [
              -100.824218,
              36.499618
            ],
            [
              -100.824236,
              36.499618
            ],
            [
              -100.85084,
              36.4997
            ],
            [
              -100.859657,
              36.499687
            ],
            [
              -100.88408,
              36.499682
            ],
            [
              -100.884174,
              36.499682
            ],
            [
              -100.918513,
              36.499621
            ],
            [
              -100.936058,
              36.499602
            ],
            [
              -100.954152945903,
              36.4995989128779
            ],
            [
              -100.977088,
              36.499595
            ],
            [
              -101.045331,
              36.49954
            ],
            [
              -101.052418,
              36.499563
            ],
            [
              -101.085156,
              36.499244
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "321",
      "COUNTYNS": "01383943",
      "AFFGEOID": "0500000US48321",
      "GEOID": "48321",
      "NAME": "Matagorda",
      "LSAD": "06",
      "ALAND": 2830586532,
      "AWATER": 1345801461,
      "County_state": "Matagorda,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.37837667303059,
              28.3905560343654
            ],
            [
              -96.341617,
              28.417334
            ],
            [
              -96.328817,
              28.423659
            ],
            [
              -96.32156,
              28.425148
            ],
            [
              -96.303212,
              28.441871
            ],
            [
              -96.270391,
              28.46193
            ],
            [
              -96.244751,
              28.475055
            ],
            [
              -96.220376,
              28.491966
            ],
            [
              -96.194412,
              28.502224
            ],
            [
              -96.077868,
              28.556626
            ],
            [
              -96.000682,
              28.588238
            ],
            [
              -95.884026,
              28.633098
            ],
            [
              -95.812504,
              28.664942
            ],
            [
              -95.684089,
              28.734041
            ],
            [
              -95.659511,
              28.751084
            ],
            [
              -95.588801,
              28.783169
            ],
            [
              -95.50726417573519,
              28.8256171832297
            ],
            [
              -95.506762,
              28.827199
            ],
            [
              -95.504592,
              28.830823
            ],
            [
              -95.503889,
              28.834928
            ],
            [
              -95.511486,
              28.836342
            ],
            [
              -95.531011,
              28.829064
            ],
            [
              -95.53437,
              28.830304
            ],
            [
              -95.535764,
              28.831972
            ],
            [
              -95.537085,
              28.832899
            ],
            [
              -95.538471,
              28.832186
            ],
            [
              -95.540242,
              28.83146
            ],
            [
              -95.542421,
              28.832492
            ],
            [
              -95.545714,
              28.831542
            ],
            [
              -95.550398,
              28.828773
            ],
            [
              -95.555864,
              28.830112
            ],
            [
              -95.560485,
              28.829924
            ],
            [
              -95.565895,
              28.830569
            ],
            [
              -95.571129,
              28.831284
            ],
            [
              -95.576749,
              28.836312
            ],
            [
              -95.579375,
              28.841671
            ],
            [
              -95.577436,
              28.84719
            ],
            [
              -95.576786,
              28.850242
            ],
            [
              -95.576412,
              28.853832
            ],
            [
              -95.579688,
              28.860012
            ],
            [
              -95.5863,
              28.863977
            ],
            [
              -95.588618,
              28.867275
            ],
            [
              -95.593022,
              28.870798
            ],
            [
              -95.598248,
              28.873962
            ],
            [
              -95.604761,
              28.878793
            ],
            [
              -95.611212,
              28.882865
            ],
            [
              -95.6186,
              28.887951
            ],
            [
              -95.622139,
              28.892629
            ],
            [
              -95.627006,
              28.894701
            ],
            [
              -95.633506,
              28.897469
            ],
            [
              -95.637533,
              28.903735
            ],
            [
              -95.644273,
              28.907986
            ],
            [
              -95.651551,
              28.920911
            ],
            [
              -95.649496,
              28.932468
            ],
            [
              -95.648678,
              28.94168
            ],
            [
              -95.653164,
              28.948989
            ],
            [
              -95.658304,
              28.952696
            ],
            [
              -95.663256,
              28.954302
            ],
            [
              -95.670167,
              28.958262
            ],
            [
              -95.673305,
              28.961671
            ],
            [
              -95.675599,
              28.964442
            ],
            [
              -95.678193,
              28.965256
            ],
            [
              -95.681544,
              28.96431
            ],
            [
              -95.682713,
              28.962232
            ],
            [
              -95.685207,
              28.96127
            ],
            [
              -95.686545,
              28.962479
            ],
            [
              -95.689464,
              28.963898
            ],
            [
              -95.691221,
              28.964948
            ],
            [
              -95.694665,
              28.965354
            ],
            [
              -95.696753,
              28.965816
            ],
            [
              -95.697804,
              28.968069
            ],
            [
              -95.699548,
              28.969592
            ],
            [
              -95.701711,
              28.969179
            ],
            [
              -95.704049,
              28.968937
            ],
            [
              -95.713301,
              28.96844
            ],
            [
              -95.725317,
              28.968101
            ],
            [
              -95.737351,
              28.967769
            ],
            [
              -95.764943,
              28.967496
            ],
            [
              -95.76878,
              28.970433
            ],
            [
              -95.770498,
              28.97598
            ],
            [
              -95.767484,
              28.97869
            ],
            [
              -95.766452,
              28.979272
            ],
            [
              -95.766201,
              28.980828
            ],
            [
              -95.768345,
              28.981988
            ],
            [
              -95.770158,
              28.983275
            ],
            [
              -95.770389,
              28.985194
            ],
            [
              -95.769775,
              28.98703
            ],
            [
              -95.769081,
              28.988371
            ],
            [
              -95.768831,
              28.988008
            ],
            [
              -95.767493,
              28.988348
            ],
            [
              -95.767461,
              28.990487
            ],
            [
              -95.769111,
              28.991405
            ],
            [
              -95.770899,
              28.993594
            ],
            [
              -95.774581,
              28.994397
            ],
            [
              -95.777257,
              28.995766
            ],
            [
              -95.777588,
              28.998257
            ],
            [
              -95.774153,
              29.002404
            ],
            [
              -95.772846,
              29.004344
            ],
            [
              -95.771001,
              29.004217
            ],
            [
              -95.768832,
              29.002738
            ],
            [
              -95.76895,
              29.004805
            ],
            [
              -95.771657,
              29.006108
            ],
            [
              -95.776715,
              29.007561
            ],
            [
              -95.779523,
              29.008007
            ],
            [
              -95.781656,
              29.007166
            ],
            [
              -95.784401,
              29.007508
            ],
            [
              -95.785113,
              29.009624
            ],
            [
              -95.785263,
              29.011175
            ],
            [
              -95.783312,
              29.011735
            ],
            [
              -95.783374,
              29.011251
            ],
            [
              -95.782681,
              29.010278
            ],
            [
              -95.78103,
              29.010481
            ],
            [
              -95.779853,
              29.013345
            ],
            [
              -95.779358,
              29.01705
            ],
            [
              -95.778026,
              29.017462
            ],
            [
              -95.779382,
              29.019689
            ],
            [
              -95.781433,
              29.020349
            ],
            [
              -95.780545,
              29.022289
            ],
            [
              -95.778862,
              29.022432
            ],
            [
              -95.777998,
              29.024553
            ],
            [
              -95.778729,
              29.026719
            ],
            [
              -95.781906,
              29.0281
            ],
            [
              -95.783107,
              29.027798
            ],
            [
              -95.783945,
              29.029497
            ],
            [
              -95.783057,
              29.030684
            ],
            [
              -95.781307,
              29.032418
            ],
            [
              -95.780147,
              29.034729
            ],
            [
              -95.780891,
              29.035576
            ],
            [
              -95.782223,
              29.03816
            ],
            [
              -95.784637,
              29.038351
            ],
            [
              -95.784161,
              29.040497
            ],
            [
              -95.784105,
              29.041761
            ],
            [
              -95.783291,
              29.043454
            ],
            [
              -95.781133,
              29.043497
            ],
            [
              -95.778211,
              29.044717
            ],
            [
              -95.776966,
              29.046795
            ],
            [
              -95.776954,
              29.048824
            ],
            [
              -95.776168,
              29.051242
            ],
            [
              -95.772841,
              29.052572
            ],
            [
              -95.770586,
              29.051881
            ],
            [
              -95.767666,
              29.054295
            ],
            [
              -95.766878,
              29.057256
            ],
            [
              -95.765837,
              29.059831
            ],
            [
              -95.767113,
              29.061948
            ],
            [
              -95.769871,
              29.064895
            ],
            [
              -95.772618,
              29.065369
            ],
            [
              -95.776034,
              29.064826
            ],
            [
              -95.782485,
              29.066745
            ],
            [
              -95.788522,
              29.069418
            ],
            [
              -95.791619,
              29.071117
            ],
            [
              -95.793684,
              29.072794
            ],
            [
              -95.795073,
              29.075026
            ],
            [
              -95.796587,
              29.077928
            ],
            [
              -95.798621,
              29.080232
            ],
            [
              -95.801399,
              29.081491
            ],
            [
              -95.80282,
              29.083514
            ],
            [
              -95.803514,
              29.086862
            ],
            [
              -95.805673,
              29.089885
            ],
            [
              -95.807657,
              29.09072
            ],
            [
              -95.811287,
              29.089885
            ],
            [
              -95.81635,
              29.089824
            ],
            [
              -95.819799,
              29.090489
            ],
            [
              -95.826758,
              29.089685
            ],
            [
              -95.829605,
              29.089432
            ],
            [
              -95.831759,
              29.091333
            ],
            [
              -95.832961,
              29.093032
            ],
            [
              -95.834838,
              29.09357
            ],
            [
              -95.836253,
              29.09274
            ],
            [
              -95.8382,
              29.094713
            ],
            [
              -95.839045,
              29.097598
            ],
            [
              -95.840961,
              29.098477
            ],
            [
              -95.84185,
              29.100319
            ],
            [
              -95.841419,
              29.101528
            ],
            [
              -95.843499,
              29.105821
            ],
            [
              -95.845114,
              29.106765
            ],
            [
              -95.845102,
              29.108838
            ],
            [
              -95.84357,
              29.111235
            ],
            [
              -95.842262,
              29.114094
            ],
            [
              -95.841193,
              29.115556
            ],
            [
              -95.841506,
              29.117541
            ],
            [
              -95.840719,
              29.120333
            ],
            [
              -95.840826,
              29.123989
            ],
            [
              -95.838636,
              29.128084
            ],
            [
              -95.83793,
              29.132525
            ],
            [
              -95.838482,
              29.136956
            ],
            [
              -95.840017,
              29.140182
            ],
            [
              -95.84147,
              29.142656
            ],
            [
              -95.844013,
              29.144337
            ],
            [
              -95.848058,
              29.146232
            ],
            [
              -95.849312,
              29.149739
            ],
            [
              -95.850659,
              29.152449
            ],
            [
              -95.849308,
              29.15534
            ],
            [
              -95.850161,
              29.159034
            ],
            [
              -95.848071,
              29.162041
            ],
            [
              -95.84831,
              29.165427
            ],
            [
              -95.846813,
              29.166659
            ],
            [
              -95.843858,
              29.168633
            ],
            [
              -95.841259,
              29.168161
            ],
            [
              -95.840176,
              29.169761
            ],
            [
              -95.83965,
              29.171933
            ],
            [
              -95.840283,
              29.174818
            ],
            [
              -95.854723,
              29.190693
            ],
            [
              -95.855751,
              29.195105
            ],
            [
              -95.849095,
              29.200843
            ],
            [
              -95.859327,
              29.217056
            ],
            [
              -95.874028,
              29.229702
            ],
            [
              -95.965196,
              29.146865
            ],
            [
              -96.309178,
              28.963291
            ],
            [
              -96.323522,
              28.675597
            ],
            [
              -96.322731,
              28.642944
            ],
            [
              -96.37837667303059,
              28.3905560343654
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "447",
      "COUNTYNS": "01384009",
      "AFFGEOID": "0500000US48447",
      "GEOID": "48447",
      "NAME": "Throckmorton",
      "LSAD": "06",
      "ALAND": 2363499442,
      "AWATER": 7586186,
      "County_state": "Throckmorton,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.472444,
              33.399023
            ],
            [
              -99.471258,
              32.957025
            ],
            [
              -99.096016,
              32.957037
            ],
            [
              -98.950875,
              32.956918
            ],
            [
              -98.953938,
              33.397534
            ],
            [
              -99.472444,
              33.399023
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "001",
      "COUNTYNS": "01383786",
      "AFFGEOID": "0500000US48001",
      "GEOID": "48001",
      "NAME": "Anderson",
      "LSAD": "06",
      "ALAND": 2752129268,
      "AWATER": 39880658,
      "County_state": "Anderson,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.0626,
              31.982817
            ],
            [
              -96.062496,
              31.978405
            ],
            [
              -96.058677,
              31.97489
            ],
            [
              -96.054405,
              31.974501
            ],
            [
              -96.050453,
              31.969452
            ],
            [
              -96.046794,
              31.967884
            ],
            [
              -96.044175,
              31.964125
            ],
            [
              -96.046853,
              31.960012
            ],
            [
              -96.050818,
              31.962517
            ],
            [
              -96.057307,
              31.96111
            ],
            [
              -96.061722,
              31.959578
            ],
            [
              -96.05992,
              31.954035
            ],
            [
              -96.055816,
              31.951086
            ],
            [
              -96.048291,
              31.951017
            ],
            [
              -96.043607,
              31.948511
            ],
            [
              -96.039868,
              31.946956
            ],
            [
              -96.039622,
              31.950722
            ],
            [
              -96.030988,
              31.95396
            ],
            [
              -96.029318,
              31.955925
            ],
            [
              -96.024876,
              31.95733
            ],
            [
              -96.020463,
              31.952473
            ],
            [
              -96.015006,
              31.947609
            ],
            [
              -96.016569,
              31.942811
            ],
            [
              -96.019763,
              31.936902
            ],
            [
              -96.013318,
              31.934775
            ],
            [
              -96.013005,
              31.929555
            ],
            [
              -96.006304,
              31.923731
            ],
            [
              -96.005751,
              31.918372
            ],
            [
              -96.002669,
              31.912795
            ],
            [
              -96.004226,
              31.907444
            ],
            [
              -96.00718,
              31.906176
            ],
            [
              -96.00958,
              31.907341
            ],
            [
              -96.010421,
              31.90928
            ],
            [
              -96.014066,
              31.908711
            ],
            [
              -96.015634,
              31.905382
            ],
            [
              -96.019669,
              31.903587
            ],
            [
              -96.024645,
              31.892655
            ],
            [
              -96.026716,
              31.886172
            ],
            [
              -96.027622,
              31.877875
            ],
            [
              -96.024683,
              31.873524
            ],
            [
              -96.017542,
              31.872666
            ],
            [
              -96.012348,
              31.876653
            ],
            [
              -96.009372,
              31.879266
            ],
            [
              -96.005023,
              31.878381
            ],
            [
              -96.0051,
              31.875141
            ],
            [
              -96.001623,
              31.872373
            ],
            [
              -95.998825,
              31.868274
            ],
            [
              -95.992695,
              31.867386
            ],
            [
              -95.990447,
              31.86827
            ],
            [
              -95.988876,
              31.870627
            ],
            [
              -95.990134,
              31.872785
            ],
            [
              -95.988539,
              31.874883
            ],
            [
              -95.987779,
              31.873113
            ],
            [
              -95.979968,
              31.876563
            ],
            [
              -95.974597,
              31.877192
            ],
            [
              -95.970016,
              31.875146
            ],
            [
              -95.968824,
              31.873388
            ],
            [
              -95.970124,
              31.870809
            ],
            [
              -95.972169,
              31.871797
            ],
            [
              -95.977899,
              31.868746
            ],
            [
              -95.97924,
              31.864453
            ],
            [
              -95.986275,
              31.860174
            ],
            [
              -95.989694,
              31.851637
            ],
            [
              -95.99234,
              31.846934
            ],
            [
              -95.993222,
              31.840652
            ],
            [
              -95.98704,
              31.840085
            ],
            [
              -95.978009,
              31.832348
            ],
            [
              -95.977246,
              31.823092
            ],
            [
              -95.976098,
              31.816563
            ],
            [
              -95.975516,
              31.806547
            ],
            [
              -95.980023,
              31.794075
            ],
            [
              -95.982407,
              31.787
            ],
            [
              -95.97728,
              31.785452
            ],
            [
              -95.972715,
              31.788729
            ],
            [
              -95.961197,
              31.78808
            ],
            [
              -95.956842,
              31.781823
            ],
            [
              -95.953019,
              31.776284
            ],
            [
              -95.938409,
              31.77437
            ],
            [
              -95.932618,
              31.77212
            ],
            [
              -95.927355,
              31.768619
            ],
            [
              -95.920059,
              31.768567
            ],
            [
              -95.912293,
              31.766295
            ],
            [
              -95.903924,
              31.767399
            ],
            [
              -95.897382,
              31.763544
            ],
            [
              -95.90052,
              31.761433
            ],
            [
              -95.901055,
              31.7596
            ],
            [
              -95.901823,
              31.755625
            ],
            [
              -95.898141,
              31.754548
            ],
            [
              -95.892776,
              31.758416
            ],
            [
              -95.884987,
              31.757456
            ],
            [
              -95.874277,
              31.754194
            ],
            [
              -95.873361,
              31.75016
            ],
            [
              -95.870238,
              31.745905
            ],
            [
              -95.868833,
              31.74599
            ],
            [
              -95.870682,
              31.74258
            ],
            [
              -95.875617,
              31.743415
            ],
            [
              -95.880889,
              31.738703
            ],
            [
              -95.881098,
              31.735532
            ],
            [
              -95.875128,
              31.736697
            ],
            [
              -95.869741,
              31.736247
            ],
            [
              -95.868864,
              31.732011
            ],
            [
              -95.86882,
              31.724652
            ],
            [
              -95.866932,
              31.721023
            ],
            [
              -95.870616,
              31.719872
            ],
            [
              -95.873715,
              31.719541
            ],
            [
              -95.875776,
              31.71146
            ],
            [
              -95.875698,
              31.704898
            ],
            [
              -95.87779,
              31.702697
            ],
            [
              -95.878279,
              31.699041
            ],
            [
              -95.875774,
              31.695681
            ],
            [
              -95.864527,
              31.691565
            ],
            [
              -95.856273,
              31.69446
            ],
            [
              -95.843104,
              31.695825
            ],
            [
              -95.829521,
              31.691762
            ],
            [
              -95.821283,
              31.686967
            ],
            [
              -95.812702,
              31.676309
            ],
            [
              -95.802063,
              31.674376
            ],
            [
              -95.794369,
              31.679977
            ],
            [
              -95.791249,
              31.688037
            ],
            [
              -95.787571,
              31.690343
            ],
            [
              -95.783681,
              31.686067
            ],
            [
              -95.782529,
              31.678591
            ],
            [
              -95.790477,
              31.66617
            ],
            [
              -95.793299,
              31.657914
            ],
            [
              -95.789679,
              31.648776
            ],
            [
              -95.794544,
              31.639635
            ],
            [
              -95.796653,
              31.631059
            ],
            [
              -95.79017,
              31.627723
            ],
            [
              -95.788091,
              31.620226
            ],
            [
              -95.7873,
              31.618385
            ],
            [
              -95.777586,
              31.602257
            ],
            [
              -95.767234,
              31.597581
            ],
            [
              -95.748669,
              31.617709
            ],
            [
              -95.757268,
              31.643548
            ],
            [
              -95.735681,
              31.653822
            ],
            [
              -95.710112,
              31.615587
            ],
            [
              -95.71169,
              31.603162
            ],
            [
              -95.729304,
              31.582904
            ],
            [
              -95.717945,
              31.572504
            ],
            [
              -95.718512,
              31.553659
            ],
            [
              -95.751072,
              31.551964
            ],
            [
              -95.758711,
              31.533217
            ],
            [
              -95.737034,
              31.518534
            ],
            [
              -95.739279,
              31.504056
            ],
            [
              -95.647171,
              31.527014
            ],
            [
              -95.651764,
              31.541791
            ],
            [
              -95.273203,
              31.592886
            ],
            [
              -95.258844,
              31.610532
            ],
            [
              -95.264882,
              31.62427
            ],
            [
              -95.281693,
              31.625391
            ],
            [
              -95.285479,
              31.642136
            ],
            [
              -95.275849,
              31.657229
            ],
            [
              -95.289661,
              31.683997
            ],
            [
              -95.311955,
              31.697271
            ],
            [
              -95.328042,
              31.717145
            ],
            [
              -95.342471,
              31.717951
            ],
            [
              -95.332823,
              31.72792
            ],
            [
              -95.341573,
              31.735289
            ],
            [
              -95.366187,
              31.726753
            ],
            [
              -95.362781,
              31.752459
            ],
            [
              -95.380752,
              31.752551
            ],
            [
              -95.402303,
              31.762036
            ],
            [
              -95.39412,
              31.787225
            ],
            [
              -95.40324,
              31.800409
            ],
            [
              -95.403734,
              31.821835
            ],
            [
              -95.413499,
              31.835515
            ],
            [
              -95.446825,
              31.843495
            ],
            [
              -95.445431,
              31.856985
            ],
            [
              -95.431004,
              31.880138
            ],
            [
              -95.437533,
              31.901519
            ],
            [
              -95.422735,
              31.924823
            ],
            [
              -95.445588,
              31.945858
            ],
            [
              -95.453519,
              31.959876
            ],
            [
              -95.443983,
              31.963524
            ],
            [
              -95.452859,
              31.97654
            ],
            [
              -95.446449,
              31.998517
            ],
            [
              -95.424106,
              32.018893
            ],
            [
              -95.432782,
              32.030902
            ],
            [
              -95.422083,
              32.047401
            ],
            [
              -95.435428,
              32.052991
            ],
            [
              -95.43793,
              32.068259
            ],
            [
              -95.428512,
              32.084475
            ],
            [
              -96.052786,
              32.005895
            ],
            [
              -96.050645,
              32.000228
            ],
            [
              -96.05308,
              31.993914
            ],
            [
              -96.057627,
              31.98671
            ],
            [
              -96.0626,
              31.982817
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "055",
      "COUNTYNS": "01383813",
      "AFFGEOID": "0500000US48055",
      "GEOID": "48055",
      "NAME": "Caldwell",
      "LSAD": "06",
      "ALAND": 1412139126,
      "AWATER": 4972802,
      "County_state": "Caldwell,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.893896,
              29.883734
            ],
            [
              -97.899238,
              29.857583
            ],
            [
              -97.875259,
              29.858208
            ],
            [
              -97.86336,
              29.856194
            ],
            [
              -97.848635,
              29.843348
            ],
            [
              -97.841549,
              29.824941
            ],
            [
              -97.842351,
              29.799224
            ],
            [
              -97.829484,
              29.79349
            ],
            [
              -97.829259,
              29.779597
            ],
            [
              -97.817138,
              29.790383
            ],
            [
              -97.815102,
              29.780726
            ],
            [
              -97.800279,
              29.766207
            ],
            [
              -97.799396,
              29.75226
            ],
            [
              -97.780917,
              29.759258
            ],
            [
              -97.77096,
              29.732305
            ],
            [
              -97.758601,
              29.725047
            ],
            [
              -97.768322,
              29.718883
            ],
            [
              -97.738369,
              29.712905
            ],
            [
              -97.743347,
              29.698841
            ],
            [
              -97.721852,
              29.682088
            ],
            [
              -97.703855,
              29.681267
            ],
            [
              -97.697797,
              29.6665
            ],
            [
              -97.688301,
              29.672589
            ],
            [
              -97.651075,
              29.666439
            ],
            [
              -97.654344,
              29.658035
            ],
            [
              -97.633176,
              29.649863
            ],
            [
              -97.616909,
              29.644782
            ],
            [
              -97.617497,
              29.633553
            ],
            [
              -97.597743,
              29.630718
            ],
            [
              -97.317893,
              29.784658
            ],
            [
              -97.315823,
              29.786541
            ],
            [
              -97.64937,
              30.067944
            ],
            [
              -97.655257,
              30.072907
            ],
            [
              -97.710215,
              30.024499
            ],
            [
              -97.712548,
              30.020613
            ],
            [
              -97.761332,
              29.987319
            ],
            [
              -97.792593,
              29.945057
            ],
            [
              -97.849178,
              29.916
            ],
            [
              -97.893896,
              29.883734
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "325",
      "COUNTYNS": "01383948",
      "AFFGEOID": "0500000US48325",
      "GEOID": "48325",
      "NAME": "Medina",
      "LSAD": "06",
      "ALAND": 3432663415,
      "AWATER": 23762579,
      "County_state": "Medina,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.411817,
              29.627514
            ],
            [
              -99.41388,
              29.091335
            ],
            [
              -98.8049,
              29.090434
            ],
            [
              -98.804763,
              29.250693
            ],
            [
              -98.805578,
              29.522711
            ],
            [
              -98.806552,
              29.690709
            ],
            [
              -98.927161,
              29.56225
            ],
            [
              -98.964728,
              29.558901
            ],
            [
              -98.971383,
              29.551842
            ],
            [
              -98.985202,
              29.564079
            ],
            [
              -98.979273,
              29.575455
            ],
            [
              -98.987068,
              29.584663
            ],
            [
              -98.976126,
              29.613264
            ],
            [
              -98.982404,
              29.622418
            ],
            [
              -99.000309,
              29.623224
            ],
            [
              -99.411817,
              29.627514
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "173",
      "COUNTYNS": "01383872",
      "AFFGEOID": "0500000US48173",
      "GEOID": "48173",
      "NAME": "Glasscock",
      "LSAD": "06",
      "ALAND": 2331552973,
      "AWATER": 2245301,
      "County_state": "Glasscock,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.776085,
              32.086925
            ],
            [
              -101.775802,
              31.651319
            ],
            [
              -101.267122,
              31.650854
            ],
            [
              -101.264216,
              32.087136
            ],
            [
              -101.695011,
              32.087532
            ],
            [
              -101.776085,
              32.086925
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "261",
      "COUNTYNS": "01383916",
      "AFFGEOID": "0500000US48261",
      "GEOID": "48261",
      "NAME": "Kenedy",
      "LSAD": "06",
      "ALAND": 3777375503,
      "AWATER": 1262209528,
      "County_state": "Kenedy,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.397438,
              27.010313
            ],
            [
              -97.401003,
              26.891668
            ],
            [
              -97.389119,
              26.800481
            ],
            [
              -97.357031,
              26.725431
            ],
            [
              -97.336829,
              26.650818
            ],
            [
              -97.3148893003729,
              26.6002710565844
            ],
            [
              -97.289717699364,
              26.6003484681446
            ],
            [
              -97.296171,
              26.61781
            ],
            [
              -97.32275,
              26.701746
            ],
            [
              -97.348225,
              26.795872
            ],
            [
              -97.366872,
              26.885581
            ],
            [
              -97.376943,
              26.971528
            ],
            [
              -97.378697,
              27.060044
            ],
            [
              -97.371741,
              27.153012
            ],
            [
              -97.358469,
              27.234796
            ],
            [
              -97.34685087695568,
              27.2779588322095
            ],
            [
              -97.3775228909102,
              27.278024636189798
            ],
            [
              -97.388084,
              27.20644
            ],
            [
              -97.399814,
              27.115083
            ],
            [
              -97.397438,
              27.010313
            ]
          ]
        ],
        [
          [
            [
              -97.985887,
              27.209308
            ],
            [
              -97.985494,
              26.780917
            ],
            [
              -97.985303,
              26.615809
            ],
            [
              -97.957405,
              26.611769
            ],
            [
              -97.861688,
              26.597995
            ],
            [
              -97.44120775772409,
              26.5999047499567
            ],
            [
              -97.445708,
              26.609362
            ],
            [
              -97.444945,
              26.633535
            ],
            [
              -97.467337,
              26.710126
            ],
            [
              -97.468609,
              26.740915
            ],
            [
              -97.471663,
              26.758727
            ],
            [
              -97.478605,
              26.806675
            ],
            [
              -97.466469,
              26.809695
            ],
            [
              -97.460284,
              26.847287
            ],
            [
              -97.453166,
              26.910467
            ],
            [
              -97.444064,
              26.987235
            ],
            [
              -97.439396,
              27.076524
            ],
            [
              -97.430994,
              27.160861
            ],
            [
              -97.441565,
              27.163833
            ],
            [
              -97.434767,
              27.202241
            ],
            [
              -97.422299,
              27.257712
            ],
            [
              -97.42408,
              27.264073
            ],
            [
              -97.450289,
              27.262546
            ],
            [
              -97.458431,
              27.259493
            ],
            [
              -97.467083,
              27.25364
            ],
            [
              -97.485149,
              27.250841
            ],
            [
              -97.500162,
              27.24448
            ],
            [
              -97.503215,
              27.2399
            ],
            [
              -97.509831,
              27.23532
            ],
            [
              -97.520009,
              27.231248
            ],
            [
              -97.54291,
              27.229213
            ],
            [
              -97.561231,
              27.232775
            ],
            [
              -97.573953,
              27.238882
            ],
            [
              -97.582605,
              27.240409
            ],
            [
              -97.597363,
              27.242444
            ],
            [
              -97.628916,
              27.242953
            ],
            [
              -97.635022,
              27.247024
            ],
            [
              -97.639094,
              27.253131
            ],
            [
              -97.640111,
              27.270943
            ],
            [
              -97.6366800047884,
              27.2817272286326
            ],
            [
              -97.650497,
              27.283488
            ],
            [
              -97.727403,
              27.26599
            ],
            [
              -97.740046,
              27.278281
            ],
            [
              -97.785102,
              27.283714
            ],
            [
              -97.797131,
              27.27159
            ],
            [
              -97.815925,
              27.271368
            ],
            [
              -97.815658,
              27.255806
            ],
            [
              -97.84134,
              27.243955
            ],
            [
              -97.855397,
              27.252424
            ],
            [
              -97.868125,
              27.240318
            ],
            [
              -97.884827,
              27.235764
            ],
            [
              -97.901703,
              27.238302
            ],
            [
              -97.951904,
              27.233249
            ],
            [
              -97.959196,
              27.212942
            ],
            [
              -97.985887,
              27.209308
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "499",
      "COUNTYNS": "01384035",
      "AFFGEOID": "0500000US48499",
      "GEOID": "48499",
      "NAME": "Wood",
      "LSAD": "06",
      "ALAND": 1671154351,
      "AWATER": 130748983,
      "County_state": "Wood,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.665389,
              32.960434
            ],
            [
              -95.635017,
              32.72038
            ],
            [
              -95.626279,
              32.708703
            ],
            [
              -95.59321,
              32.693179
            ],
            [
              -95.594541,
              32.687026
            ],
            [
              -95.583593,
              32.674673
            ],
            [
              -95.568957,
              32.672544
            ],
            [
              -95.560574,
              32.662283
            ],
            [
              -95.530238,
              32.647489
            ],
            [
              -95.510699,
              32.626996
            ],
            [
              -95.510945,
              32.620328
            ],
            [
              -95.492049,
              32.617575
            ],
            [
              -95.469093,
              32.605642
            ],
            [
              -95.446747,
              32.613733
            ],
            [
              -95.435278,
              32.606751
            ],
            [
              -95.413061,
              32.607111
            ],
            [
              -95.412773,
              32.606955
            ],
            [
              -95.412601,
              32.606805
            ],
            [
              -95.412234,
              32.606108
            ],
            [
              -95.411919,
              32.605728
            ],
            [
              -95.411343,
              32.605404
            ],
            [
              -95.410751,
              32.60524
            ],
            [
              -95.410689,
              32.605071
            ],
            [
              -95.410724,
              32.604613
            ],
            [
              -95.410467,
              32.60455
            ],
            [
              -95.410087,
              32.604666
            ],
            [
              -95.408707,
              32.604568
            ],
            [
              -95.40836,
              32.60475
            ],
            [
              -95.408144,
              32.605007
            ],
            [
              -95.408009,
              32.60534
            ],
            [
              -95.407777,
              32.605539
            ],
            [
              -95.407441,
              32.605547
            ],
            [
              -95.406747,
              32.605932
            ],
            [
              -95.406294,
              32.606296
            ],
            [
              -95.406053,
              32.606357
            ],
            [
              -95.405459,
              32.606378
            ],
            [
              -95.404639,
              32.606741
            ],
            [
              -95.404391,
              32.606766
            ],
            [
              -95.4041,
              32.606735
            ],
            [
              -95.403893,
              32.606617
            ],
            [
              -95.403767,
              32.606439
            ],
            [
              -95.403738,
              32.606088
            ],
            [
              -95.403821,
              32.605887
            ],
            [
              -95.404155,
              32.605489
            ],
            [
              -95.404461,
              32.605369
            ],
            [
              -95.404923,
              32.605347
            ],
            [
              -95.405451,
              32.604942
            ],
            [
              -95.405614,
              32.604618
            ],
            [
              -95.405683,
              32.604308
            ],
            [
              -95.405637,
              32.60403
            ],
            [
              -95.405529,
              32.6038
            ],
            [
              -95.405325,
              32.603692
            ],
            [
              -95.405115,
              32.60371
            ],
            [
              -95.404205,
              32.604146
            ],
            [
              -95.403805,
              32.604263
            ],
            [
              -95.403394,
              32.60421
            ],
            [
              -95.403082,
              32.603879
            ],
            [
              -95.402745,
              32.603665
            ],
            [
              -95.402221,
              32.603443
            ],
            [
              -95.40177,
              32.603395
            ],
            [
              -95.401628,
              32.60342
            ],
            [
              -95.401439,
              32.603682
            ],
            [
              -95.401435,
              32.603893
            ],
            [
              -95.401834,
              32.604384
            ],
            [
              -95.401993,
              32.605043
            ],
            [
              -95.402034,
              32.605531
            ],
            [
              -95.402233,
              32.606178
            ],
            [
              -95.402128,
              32.606271
            ],
            [
              -95.401753,
              32.606285
            ],
            [
              -95.40145,
              32.605785
            ],
            [
              -95.401161,
              32.605379
            ],
            [
              -95.401157,
              32.605192
            ],
            [
              -95.400971,
              32.604892
            ],
            [
              -95.400563,
              32.604551
            ],
            [
              -95.400321,
              32.604528
            ],
            [
              -95.400214,
              32.604618
            ],
            [
              -95.400324,
              32.605389
            ],
            [
              -95.400353,
              32.606006
            ],
            [
              -95.400027,
              32.605936
            ],
            [
              -95.399837,
              32.606003
            ],
            [
              -95.399583,
              32.606401
            ],
            [
              -95.399419,
              32.606395
            ],
            [
              -95.398857,
              32.60602
            ],
            [
              -95.398649,
              32.605375
            ],
            [
              -95.398434,
              32.605119
            ],
            [
              -95.398118,
              32.605024
            ],
            [
              -95.397099,
              32.605001
            ],
            [
              -95.396427,
              32.604929
            ],
            [
              -95.396052,
              32.60477
            ],
            [
              -95.395834,
              32.604553
            ],
            [
              -95.395748,
              32.604318
            ],
            [
              -95.395757,
              32.603852
            ],
            [
              -95.395835,
              32.603613
            ],
            [
              -95.396165,
              32.603125
            ],
            [
              -95.396338,
              32.602767
            ],
            [
              -95.396803,
              32.602319
            ],
            [
              -95.396793,
              32.602049
            ],
            [
              -95.39603,
              32.601489
            ],
            [
              -95.395751,
              32.601335
            ],
            [
              -95.395471,
              32.601261
            ],
            [
              -95.394847,
              32.601197
            ],
            [
              -95.394558,
              32.601256
            ],
            [
              -95.394376,
              32.601345
            ],
            [
              -95.394194,
              32.601621
            ],
            [
              -95.393847,
              32.60174
            ],
            [
              -95.392716,
              32.601484
            ],
            [
              -95.391306,
              32.601258
            ],
            [
              -95.390785,
              32.601022
            ],
            [
              -95.390349,
              32.600714
            ],
            [
              -95.389892,
              32.600265
            ],
            [
              -95.389754,
              32.599974
            ],
            [
              -95.389808,
              32.599697
            ],
            [
              -95.390051,
              32.599299
            ],
            [
              -95.39002,
              32.599162
            ],
            [
              -95.389883,
              32.599027
            ],
            [
              -95.389629,
              32.5989
            ],
            [
              -95.389252,
              32.59886
            ],
            [
              -95.388751,
              32.59894
            ],
            [
              -95.38846,
              32.599081
            ],
            [
              -95.387889,
              32.599503
            ],
            [
              -95.387823,
              32.599671
            ],
            [
              -95.387816,
              32.600026
            ],
            [
              -95.387756,
              32.600271
            ],
            [
              -95.38748,
              32.60053
            ],
            [
              -95.387101,
              32.60056
            ],
            [
              -95.386634,
              32.600534
            ],
            [
              -95.385501,
              32.600416
            ],
            [
              -95.385161,
              32.60043
            ],
            [
              -95.38483,
              32.600572
            ],
            [
              -95.384752,
              32.600735
            ],
            [
              -95.384695,
              32.601193
            ],
            [
              -95.38477,
              32.601432
            ],
            [
              -95.384741,
              32.601709
            ],
            [
              -95.384607,
              32.601891
            ],
            [
              -95.384097,
              32.602142
            ],
            [
              -95.383681,
              32.602162
            ],
            [
              -95.382912,
              32.602015
            ],
            [
              -95.382175,
              32.601944
            ],
            [
              -95.381952,
              32.601852
            ],
            [
              -95.381786,
              32.601658
            ],
            [
              -95.381779,
              32.601484
            ],
            [
              -95.38195,
              32.601245
            ],
            [
              -95.382411,
              32.601002
            ],
            [
              -95.38251,
              32.600853
            ],
            [
              -95.382143,
              32.599973
            ],
            [
              -95.382225,
              32.599027
            ],
            [
              -95.382126,
              32.59872
            ],
            [
              -95.381931,
              32.598492
            ],
            [
              -95.381485,
              32.59838
            ],
            [
              -95.380819,
              32.59849
            ],
            [
              -95.380453,
              32.59867
            ],
            [
              -95.379534,
              32.599297
            ],
            [
              -95.379025,
              32.599344
            ],
            [
              -95.378389,
              32.599317
            ],
            [
              -95.378226,
              32.599282
            ],
            [
              -95.377668,
              32.599023
            ],
            [
              -95.377432,
              32.598872
            ],
            [
              -95.376136,
              32.597692
            ],
            [
              -95.37567,
              32.597439
            ],
            [
              -95.375217,
              32.597409
            ],
            [
              -95.375053,
              32.597448
            ],
            [
              -95.37483,
              32.602719
            ],
            [
              -95.345921,
              32.609152
            ],
            [
              -95.281255,
              32.580005
            ],
            [
              -95.277861,
              32.571588
            ],
            [
              -95.250314,
              32.577727
            ],
            [
              -95.23795,
              32.560541
            ],
            [
              -95.204289,
              32.571784
            ],
            [
              -95.206706,
              32.559952
            ],
            [
              -95.189129,
              32.546596
            ],
            [
              -95.176099,
              32.555247
            ],
            [
              -95.164973,
              32.544583
            ],
            [
              -95.15341,
              32.570115
            ],
            [
              -95.15274,
              32.660949
            ],
            [
              -95.15211,
              32.902641
            ],
            [
              -95.15019,
              33.009312
            ],
            [
              -95.152206,
              33.01345
            ],
            [
              -95.172947,
              32.961829
            ],
            [
              -95.231193,
              32.962196
            ],
            [
              -95.308957,
              32.962572
            ],
            [
              -95.665389,
              32.960434
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "291",
      "COUNTYNS": "01383931",
      "AFFGEOID": "0500000US48291",
      "GEOID": "48291",
      "NAME": "Liberty",
      "LSAD": "06",
      "ALAND": 3000343678,
      "AWATER": 46378363,
      "County_state": "Liberty,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.165897,
              30.344976
            ],
            [
              -95.096708,
              30.167214
            ],
            [
              -95.056844,
              30.06098
            ],
            [
              -95.030936,
              29.993699
            ],
            [
              -95.026772,
              29.996739
            ],
            [
              -94.999834,
              29.991322
            ],
            [
              -94.985364,
              29.972279
            ],
            [
              -94.998656,
              29.919771
            ],
            [
              -94.98146,
              29.884276
            ],
            [
              -94.442235,
              29.889538
            ],
            [
              -94.44491,
              30.113015
            ],
            [
              -94.596338,
              30.112101
            ],
            [
              -94.732732,
              30.490066
            ],
            [
              -94.777341,
              30.489002
            ],
            [
              -94.849414,
              30.493553
            ],
            [
              -94.842621,
              30.487458
            ],
            [
              -95.165897,
              30.344976
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "453",
      "COUNTYNS": "01384012",
      "AFFGEOID": "0500000US48453",
      "GEOID": "48453",
      "NAME": "Travis",
      "LSAD": "06",
      "ALAND": 2569073026,
      "AWATER": 80592292,
      "County_state": "Travis,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.125556,
              30.426186
            ],
            [
              -98.172977,
              30.356312
            ],
            [
              -97.989016,
              30.226792
            ],
            [
              -97.969007,
              30.210884
            ],
            [
              -97.857222,
              30.13124
            ],
            [
              -97.857161,
              30.131196
            ],
            [
              -97.813018,
              30.098613
            ],
            [
              -97.781369,
              30.07567
            ],
            [
              -97.751479,
              30.054354
            ],
            [
              -97.710215,
              30.024499
            ],
            [
              -97.655257,
              30.072907
            ],
            [
              -97.64937,
              30.067944
            ],
            [
              -97.495778,
              30.207051
            ],
            [
              -97.49135,
              30.21193
            ],
            [
              -97.491254,
              30.21211
            ],
            [
              -97.486329,
              30.220528
            ],
            [
              -97.400089,
              30.367906
            ],
            [
              -97.369539,
              30.419563
            ],
            [
              -97.381949,
              30.423984
            ],
            [
              -97.405086,
              30.441685
            ],
            [
              -97.415953,
              30.437426
            ],
            [
              -97.445628,
              30.461404
            ],
            [
              -97.466953,
              30.46412
            ],
            [
              -97.512247,
              30.48521
            ],
            [
              -97.528151,
              30.475434
            ],
            [
              -97.546683,
              30.474824
            ],
            [
              -97.553864,
              30.490441
            ],
            [
              -97.576167,
              30.501799
            ],
            [
              -97.596236,
              30.501513
            ],
            [
              -97.651441,
              30.474894
            ],
            [
              -97.663886,
              30.481593
            ],
            [
              -97.682893,
              30.480066
            ],
            [
              -97.688784,
              30.461401
            ],
            [
              -97.721149,
              30.455835
            ],
            [
              -97.746686,
              30.449404
            ],
            [
              -97.753689,
              30.4334
            ],
            [
              -97.773458,
              30.430654
            ],
            [
              -97.788777,
              30.44419
            ],
            [
              -97.79448,
              30.444821
            ],
            [
              -97.811863,
              30.447018
            ],
            [
              -97.848384,
              30.472662
            ],
            [
              -97.857505,
              30.50161
            ],
            [
              -97.871416,
              30.522714
            ],
            [
              -97.867866,
              30.546499
            ],
            [
              -97.903277,
              30.570977
            ],
            [
              -97.926985,
              30.567654
            ],
            [
              -97.915123,
              30.580567
            ],
            [
              -97.917063,
              30.604871
            ],
            [
              -97.956734,
              30.628249
            ],
            [
              -97.991015,
              30.609849
            ],
            [
              -98.006276,
              30.627516
            ],
            [
              -98.036495,
              30.611726
            ],
            [
              -98.049886,
              30.624155
            ],
            [
              -98.123111,
              30.484723
            ],
            [
              -98.097793,
              30.497442
            ],
            [
              -98.097709,
              30.467626
            ],
            [
              -98.125556,
              30.426186
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "339",
      "COUNTYNS": "01383955",
      "AFFGEOID": "0500000US48339",
      "GEOID": "48339",
      "NAME": "Montgomery",
      "LSAD": "06",
      "ALAND": 2700317493,
      "AWATER": 88817300,
      "County_state": "Montgomery,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.83024,
              30.630284
            ],
            [
              -95.817478,
              30.500218
            ],
            [
              -95.804351,
              30.3378
            ],
            [
              -95.804335,
              30.314428
            ],
            [
              -95.804306,
              30.24557
            ],
            [
              -95.80333,
              30.090096
            ],
            [
              -95.760927,
              30.086406
            ],
            [
              -95.755185,
              30.092148
            ],
            [
              -95.733943,
              30.093539
            ],
            [
              -95.685701,
              30.106907
            ],
            [
              -95.672538,
              30.101515
            ],
            [
              -95.636613,
              30.12642
            ],
            [
              -95.624072,
              30.126675
            ],
            [
              -95.619629,
              30.135493
            ],
            [
              -95.602062,
              30.132741
            ],
            [
              -95.596757,
              30.13446
            ],
            [
              -95.590913,
              30.136064
            ],
            [
              -95.57463,
              30.151783
            ],
            [
              -95.551421,
              30.170606
            ],
            [
              -95.54214,
              30.170473
            ],
            [
              -95.529532,
              30.16582
            ],
            [
              -95.518574,
              30.152184
            ],
            [
              -95.501095,
              30.143772
            ],
            [
              -95.488415,
              30.108028
            ],
            [
              -95.474844,
              30.103256
            ],
            [
              -95.436642,
              30.110366
            ],
            [
              -95.424216,
              30.115491
            ],
            [
              -95.405443,
              30.092076
            ],
            [
              -95.395624,
              30.084025
            ],
            [
              -95.374971,
              30.086374
            ],
            [
              -95.353633,
              30.076337
            ],
            [
              -95.309548,
              30.045625
            ],
            [
              -95.310994,
              30.032887
            ],
            [
              -95.292997,
              30.027905
            ],
            [
              -95.263677,
              30.032517
            ],
            [
              -95.249854,
              30.043518
            ],
            [
              -95.195724,
              30.087321
            ],
            [
              -95.096708,
              30.167214
            ],
            [
              -95.165897,
              30.344976
            ],
            [
              -95.226139,
              30.31954
            ],
            [
              -95.226571,
              30.327145
            ],
            [
              -95.203324,
              30.347861
            ],
            [
              -95.217984,
              30.356242
            ],
            [
              -95.235888,
              30.379073
            ],
            [
              -95.260709,
              30.383446
            ],
            [
              -95.308395,
              30.40575
            ],
            [
              -95.314103,
              30.412369
            ],
            [
              -95.350705,
              30.476057
            ],
            [
              -95.359156,
              30.504369
            ],
            [
              -95.361866,
              30.508916
            ],
            [
              -95.598971,
              30.509002
            ],
            [
              -95.83024,
              30.630284
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "227",
      "COUNTYNS": "01383899",
      "AFFGEOID": "0500000US48227",
      "GEOID": "48227",
      "NAME": "Howard",
      "LSAD": "06",
      "ALAND": 2333039139,
      "AWATER": 8841781,
      "County_state": "Howard,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.68874,
              32.525222
            ],
            [
              -101.695011,
              32.087532
            ],
            [
              -101.264216,
              32.087136
            ],
            [
              -101.183997,
              32.087208
            ],
            [
              -101.174571,
              32.524112
            ],
            [
              -101.68874,
              32.525222
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "021",
      "COUNTYNS": "01383796",
      "AFFGEOID": "0500000US48021",
      "GEOID": "48021",
      "NAME": "Bastrop",
      "LSAD": "06",
      "ALAND": 2300355868,
      "AWATER": 19105699,
      "County_state": "Bastrop,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.495778,
              30.207051
            ],
            [
              -97.64937,
              30.067944
            ],
            [
              -97.315823,
              29.786541
            ],
            [
              -97.194235,
              29.896776
            ],
            [
              -97.024461,
              30.051435
            ],
            [
              -97.081834,
              30.259357
            ],
            [
              -97.158811,
              30.310695
            ],
            [
              -97.199578,
              30.337833
            ],
            [
              -97.251707,
              30.362848
            ],
            [
              -97.334463,
              30.402843
            ],
            [
              -97.369539,
              30.419563
            ],
            [
              -97.400089,
              30.367906
            ],
            [
              -97.486329,
              30.220528
            ],
            [
              -97.491254,
              30.21211
            ],
            [
              -97.49135,
              30.21193
            ],
            [
              -97.495778,
              30.207051
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "455",
      "COUNTYNS": "01384013",
      "AFFGEOID": "0500000US48455",
      "GEOID": "48455",
      "NAME": "Trinity",
      "LSAD": "06",
      "ALAND": 1796914248,
      "AWATER": 52267270,
      "County_state": "Trinity,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.434781,
              31.058098
            ],
            [
              -95.434335,
              30.937405
            ],
            [
              -95.410077,
              30.927695
            ],
            [
              -95.415153,
              30.909572
            ],
            [
              -95.398108,
              30.891939
            ],
            [
              -95.381253,
              30.887062
            ],
            [
              -95.380232,
              30.880079
            ],
            [
              -95.397175,
              30.883244
            ],
            [
              -95.391592,
              30.875188
            ],
            [
              -95.396697,
              30.857706
            ],
            [
              -95.383279,
              30.852961
            ],
            [
              -95.352072,
              30.856951
            ],
            [
              -95.345335,
              30.875335
            ],
            [
              -95.32748,
              30.859547
            ],
            [
              -95.313903,
              30.873101
            ],
            [
              -95.312898,
              30.897873
            ],
            [
              -95.252385,
              30.906719
            ],
            [
              -95.230777,
              30.887007
            ],
            [
              -95.243097,
              30.866856
            ],
            [
              -95.230989,
              30.841149
            ],
            [
              -95.20018,
              30.824566
            ],
            [
              -94.939127,
              31.042048
            ],
            [
              -94.842947,
              31.146578
            ],
            [
              -94.849959,
              31.154883
            ],
            [
              -94.850074,
              31.178553
            ],
            [
              -94.862887,
              31.200992
            ],
            [
              -94.860501,
              31.234955
            ],
            [
              -94.874546,
              31.249647
            ],
            [
              -94.897678,
              31.258011
            ],
            [
              -94.887995,
              31.276501
            ],
            [
              -94.876713,
              31.283394
            ],
            [
              -94.886168,
              31.295661
            ],
            [
              -94.894681,
              31.291708
            ],
            [
              -94.901546,
              31.313256
            ],
            [
              -94.899017,
              31.326972
            ],
            [
              -94.909502,
              31.337059
            ],
            [
              -94.947587,
              31.338589
            ],
            [
              -94.96109,
              31.348837
            ],
            [
              -94.952711,
              31.358174
            ],
            [
              -94.958798,
              31.366058
            ],
            [
              -94.95811,
              31.38693
            ],
            [
              -95.152477,
              31.251591
            ],
            [
              -95.434781,
              31.058098
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "309",
      "COUNTYNS": "01383946",
      "AFFGEOID": "0500000US48309",
      "GEOID": "48309",
      "NAME": "McLennan",
      "LSAD": "06",
      "ALAND": 2686101576,
      "AWATER": 59883892,
      "County_state": "McLennan,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.580232,
              31.598796
            ],
            [
              -97.60523,
              31.587762
            ],
            [
              -97.418606,
              31.320202
            ],
            [
              -97.343426,
              31.244215
            ],
            [
              -97.278113,
              31.279799
            ],
            [
              -96.870228,
              31.486734
            ],
            [
              -96.801123,
              31.522269
            ],
            [
              -96.932215,
              31.708887
            ],
            [
              -97.037266,
              31.863079
            ],
            [
              -97.277265,
              31.745492
            ],
            [
              -97.580232,
              31.598796
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "307",
      "COUNTYNS": "01383945",
      "AFFGEOID": "0500000US48307",
      "GEOID": "48307",
      "NAME": "McCulloch",
      "LSAD": "06",
      "ALAND": 2759894991,
      "AWATER": 20284774,
      "County_state": "McCulloch,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.603223,
              31.087296
            ],
            [
              -99.603627,
              30.940726
            ],
            [
              -99.484755,
              30.940605
            ],
            [
              -99.092317,
              30.941002
            ],
            [
              -99.090622,
              31.460927
            ],
            [
              -99.095564,
              31.448469
            ],
            [
              -99.11615,
              31.46824
            ],
            [
              -99.162124,
              31.467848
            ],
            [
              -99.184874,
              31.454963
            ],
            [
              -99.188558,
              31.470337
            ],
            [
              -99.202768,
              31.467234
            ],
            [
              -99.238447,
              31.483127
            ],
            [
              -99.272381,
              31.454557
            ],
            [
              -99.271365,
              31.446881
            ],
            [
              -99.289954,
              31.441447
            ],
            [
              -99.289131,
              31.4285
            ],
            [
              -99.302605,
              31.432504
            ],
            [
              -99.314875,
              31.409875
            ],
            [
              -99.347155,
              31.424992
            ],
            [
              -99.346422,
              31.443109
            ],
            [
              -99.357214,
              31.430853
            ],
            [
              -99.367029,
              31.437872
            ],
            [
              -99.402284,
              31.446198
            ],
            [
              -99.398793,
              31.460083
            ],
            [
              -99.416887,
              31.461391
            ],
            [
              -99.443854,
              31.4751
            ],
            [
              -99.446342,
              31.450493
            ],
            [
              -99.436896,
              31.446032
            ],
            [
              -99.469437,
              31.430131
            ],
            [
              -99.470548,
              31.421985
            ],
            [
              -99.493,
              31.444513
            ],
            [
              -99.511477,
              31.424401
            ],
            [
              -99.529716,
              31.460574
            ],
            [
              -99.537215,
              31.483616
            ],
            [
              -99.572559,
              31.49365
            ],
            [
              -99.60185,
              31.49195
            ],
            [
              -99.603223,
              31.087296
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "039",
      "COUNTYNS": "01383805",
      "AFFGEOID": "0500000US48039",
      "GEOID": "48039",
      "NAME": "Brazoria",
      "LSAD": "06",
      "ALAND": 3528358447,
      "AWATER": 637931958,
      "County_state": "Brazoria,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.874028,
              29.229702
            ],
            [
              -95.859327,
              29.217056
            ],
            [
              -95.849095,
              29.200843
            ],
            [
              -95.855751,
              29.195105
            ],
            [
              -95.854723,
              29.190693
            ],
            [
              -95.840283,
              29.174818
            ],
            [
              -95.83965,
              29.171933
            ],
            [
              -95.840176,
              29.169761
            ],
            [
              -95.841259,
              29.168161
            ],
            [
              -95.843858,
              29.168633
            ],
            [
              -95.846813,
              29.166659
            ],
            [
              -95.84831,
              29.165427
            ],
            [
              -95.848071,
              29.162041
            ],
            [
              -95.850161,
              29.159034
            ],
            [
              -95.849308,
              29.15534
            ],
            [
              -95.850659,
              29.152449
            ],
            [
              -95.849312,
              29.149739
            ],
            [
              -95.848058,
              29.146232
            ],
            [
              -95.844013,
              29.144337
            ],
            [
              -95.84147,
              29.142656
            ],
            [
              -95.840017,
              29.140182
            ],
            [
              -95.838482,
              29.136956
            ],
            [
              -95.83793,
              29.132525
            ],
            [
              -95.838636,
              29.128084
            ],
            [
              -95.840826,
              29.123989
            ],
            [
              -95.840719,
              29.120333
            ],
            [
              -95.841506,
              29.117541
            ],
            [
              -95.841193,
              29.115556
            ],
            [
              -95.842262,
              29.114094
            ],
            [
              -95.84357,
              29.111235
            ],
            [
              -95.845102,
              29.108838
            ],
            [
              -95.845114,
              29.106765
            ],
            [
              -95.843499,
              29.105821
            ],
            [
              -95.841419,
              29.101528
            ],
            [
              -95.84185,
              29.100319
            ],
            [
              -95.840961,
              29.098477
            ],
            [
              -95.839045,
              29.097598
            ],
            [
              -95.8382,
              29.094713
            ],
            [
              -95.836253,
              29.09274
            ],
            [
              -95.834838,
              29.09357
            ],
            [
              -95.832961,
              29.093032
            ],
            [
              -95.831759,
              29.091333
            ],
            [
              -95.829605,
              29.089432
            ],
            [
              -95.826758,
              29.089685
            ],
            [
              -95.819799,
              29.090489
            ],
            [
              -95.81635,
              29.089824
            ],
            [
              -95.811287,
              29.089885
            ],
            [
              -95.807657,
              29.09072
            ],
            [
              -95.805673,
              29.089885
            ],
            [
              -95.803514,
              29.086862
            ],
            [
              -95.80282,
              29.083514
            ],
            [
              -95.801399,
              29.081491
            ],
            [
              -95.798621,
              29.080232
            ],
            [
              -95.796587,
              29.077928
            ],
            [
              -95.795073,
              29.075026
            ],
            [
              -95.793684,
              29.072794
            ],
            [
              -95.791619,
              29.071117
            ],
            [
              -95.788522,
              29.069418
            ],
            [
              -95.782485,
              29.066745
            ],
            [
              -95.776034,
              29.064826
            ],
            [
              -95.772618,
              29.065369
            ],
            [
              -95.769871,
              29.064895
            ],
            [
              -95.767113,
              29.061948
            ],
            [
              -95.765837,
              29.059831
            ],
            [
              -95.766878,
              29.057256
            ],
            [
              -95.767666,
              29.054295
            ],
            [
              -95.770586,
              29.051881
            ],
            [
              -95.772841,
              29.052572
            ],
            [
              -95.776168,
              29.051242
            ],
            [
              -95.776954,
              29.048824
            ],
            [
              -95.776966,
              29.046795
            ],
            [
              -95.778211,
              29.044717
            ],
            [
              -95.781133,
              29.043497
            ],
            [
              -95.783291,
              29.043454
            ],
            [
              -95.784105,
              29.041761
            ],
            [
              -95.784161,
              29.040497
            ],
            [
              -95.784637,
              29.038351
            ],
            [
              -95.782223,
              29.03816
            ],
            [
              -95.780891,
              29.035576
            ],
            [
              -95.780147,
              29.034729
            ],
            [
              -95.781307,
              29.032418
            ],
            [
              -95.783057,
              29.030684
            ],
            [
              -95.783945,
              29.029497
            ],
            [
              -95.783107,
              29.027798
            ],
            [
              -95.781906,
              29.0281
            ],
            [
              -95.778729,
              29.026719
            ],
            [
              -95.777998,
              29.024553
            ],
            [
              -95.778862,
              29.022432
            ],
            [
              -95.780545,
              29.022289
            ],
            [
              -95.781433,
              29.020349
            ],
            [
              -95.779382,
              29.019689
            ],
            [
              -95.778026,
              29.017462
            ],
            [
              -95.779358,
              29.01705
            ],
            [
              -95.779853,
              29.013345
            ],
            [
              -95.78103,
              29.010481
            ],
            [
              -95.782681,
              29.010278
            ],
            [
              -95.783374,
              29.011251
            ],
            [
              -95.783312,
              29.011735
            ],
            [
              -95.785263,
              29.011175
            ],
            [
              -95.785113,
              29.009624
            ],
            [
              -95.784401,
              29.007508
            ],
            [
              -95.781656,
              29.007166
            ],
            [
              -95.779523,
              29.008007
            ],
            [
              -95.776715,
              29.007561
            ],
            [
              -95.771657,
              29.006108
            ],
            [
              -95.76895,
              29.004805
            ],
            [
              -95.768832,
              29.002738
            ],
            [
              -95.771001,
              29.004217
            ],
            [
              -95.772846,
              29.004344
            ],
            [
              -95.774153,
              29.002404
            ],
            [
              -95.777588,
              28.998257
            ],
            [
              -95.777257,
              28.995766
            ],
            [
              -95.774581,
              28.994397
            ],
            [
              -95.770899,
              28.993594
            ],
            [
              -95.769111,
              28.991405
            ],
            [
              -95.767461,
              28.990487
            ],
            [
              -95.767493,
              28.988348
            ],
            [
              -95.768831,
              28.988008
            ],
            [
              -95.769081,
              28.988371
            ],
            [
              -95.769775,
              28.98703
            ],
            [
              -95.770389,
              28.985194
            ],
            [
              -95.770158,
              28.983275
            ],
            [
              -95.768345,
              28.981988
            ],
            [
              -95.766201,
              28.980828
            ],
            [
              -95.766452,
              28.979272
            ],
            [
              -95.767484,
              28.97869
            ],
            [
              -95.770498,
              28.97598
            ],
            [
              -95.76878,
              28.970433
            ],
            [
              -95.764943,
              28.967496
            ],
            [
              -95.737351,
              28.967769
            ],
            [
              -95.725317,
              28.968101
            ],
            [
              -95.713301,
              28.96844
            ],
            [
              -95.704049,
              28.968937
            ],
            [
              -95.701711,
              28.969179
            ],
            [
              -95.699548,
              28.969592
            ],
            [
              -95.697804,
              28.968069
            ],
            [
              -95.696753,
              28.965816
            ],
            [
              -95.694665,
              28.965354
            ],
            [
              -95.691221,
              28.964948
            ],
            [
              -95.689464,
              28.963898
            ],
            [
              -95.686545,
              28.962479
            ],
            [
              -95.685207,
              28.96127
            ],
            [
              -95.682713,
              28.962232
            ],
            [
              -95.681544,
              28.96431
            ],
            [
              -95.678193,
              28.965256
            ],
            [
              -95.675599,
              28.964442
            ],
            [
              -95.673305,
              28.961671
            ],
            [
              -95.670167,
              28.958262
            ],
            [
              -95.663256,
              28.954302
            ],
            [
              -95.658304,
              28.952696
            ],
            [
              -95.653164,
              28.948989
            ],
            [
              -95.648678,
              28.94168
            ],
            [
              -95.649496,
              28.932468
            ],
            [
              -95.651551,
              28.920911
            ],
            [
              -95.644273,
              28.907986
            ],
            [
              -95.637533,
              28.903735
            ],
            [
              -95.633506,
              28.897469
            ],
            [
              -95.627006,
              28.894701
            ],
            [
              -95.622139,
              28.892629
            ],
            [
              -95.6186,
              28.887951
            ],
            [
              -95.611212,
              28.882865
            ],
            [
              -95.604761,
              28.878793
            ],
            [
              -95.598248,
              28.873962
            ],
            [
              -95.593022,
              28.870798
            ],
            [
              -95.588618,
              28.867275
            ],
            [
              -95.5863,
              28.863977
            ],
            [
              -95.579688,
              28.860012
            ],
            [
              -95.576412,
              28.853832
            ],
            [
              -95.576786,
              28.850242
            ],
            [
              -95.577436,
              28.84719
            ],
            [
              -95.579375,
              28.841671
            ],
            [
              -95.576749,
              28.836312
            ],
            [
              -95.571129,
              28.831284
            ],
            [
              -95.565895,
              28.830569
            ],
            [
              -95.560485,
              28.829924
            ],
            [
              -95.555864,
              28.830112
            ],
            [
              -95.550398,
              28.828773
            ],
            [
              -95.545714,
              28.831542
            ],
            [
              -95.542421,
              28.832492
            ],
            [
              -95.540242,
              28.83146
            ],
            [
              -95.538471,
              28.832186
            ],
            [
              -95.537085,
              28.832899
            ],
            [
              -95.535764,
              28.831972
            ],
            [
              -95.53437,
              28.830304
            ],
            [
              -95.531011,
              28.829064
            ],
            [
              -95.511486,
              28.836342
            ],
            [
              -95.503889,
              28.834928
            ],
            [
              -95.504592,
              28.830823
            ],
            [
              -95.506762,
              28.827199
            ],
            [
              -95.50726417573519,
              28.8256171832297
            ],
            [
              -95.486769,
              28.836287
            ],
            [
              -95.439594,
              28.859022
            ],
            [
              -95.416174,
              28.859482
            ],
            [
              -95.38239,
              28.866348
            ],
            [
              -95.376979,
              28.87616
            ],
            [
              -95.353451,
              28.898145
            ],
            [
              -95.309704,
              28.928262
            ],
            [
              -95.297147,
              28.934073
            ],
            [
              -95.2965602875134,
              28.9347208337745
            ],
            [
              -95.272266,
              28.961546
            ],
            [
              -95.238924,
              28.988644
            ],
            [
              -95.191391,
              29.02309
            ],
            [
              -95.125134,
              29.067321
            ],
            [
              -95.122525,
              29.074
            ],
            [
              -95.1192711445067,
              29.077843770495498
            ],
            [
              -95.125232,
              29.081059
            ],
            [
              -95.129935,
              29.085618
            ],
            [
              -95.1056271959924,
              29.1230296057938
            ],
            [
              -95.144845,
              29.090836
            ],
            [
              -95.16525,
              29.113566
            ],
            [
              -95.16281,
              29.133334
            ],
            [
              -95.155184,
              29.151465
            ],
            [
              -95.158512,
              29.189133
            ],
            [
              -95.137952,
              29.194839
            ],
            [
              -95.112758,
              29.195669
            ],
            [
              -95.099101,
              29.173529
            ],
            [
              -95.05861900141849,
              29.2031987507191
            ],
            [
              -95.119749,
              29.29584
            ],
            [
              -95.19219,
              29.404367
            ],
            [
              -95.233081,
              29.465566
            ],
            [
              -95.216422,
              29.55608
            ],
            [
              -95.237985,
              29.561426
            ],
            [
              -95.253856,
              29.553778
            ],
            [
              -95.260292,
              29.589883
            ],
            [
              -95.261606,
              29.593157
            ],
            [
              -95.286493,
              29.597886
            ],
            [
              -95.297411,
              29.596522
            ],
            [
              -95.331146,
              29.599108
            ],
            [
              -95.346263,
              29.585592
            ],
            [
              -95.375414,
              29.588278
            ],
            [
              -95.424118,
              29.580233
            ],
            [
              -95.436888,
              29.532945
            ],
            [
              -95.443224,
              29.510214
            ],
            [
              -95.446141,
              29.499549
            ],
            [
              -95.45029,
              29.484162
            ],
            [
              -95.462503,
              29.43924
            ],
            [
              -95.550612,
              29.438251
            ],
            [
              -95.555936,
              29.425344
            ],
            [
              -95.545825,
              29.407735
            ],
            [
              -95.547577,
              29.395197
            ],
            [
              -95.56729,
              29.389695
            ],
            [
              -95.580483,
              29.360863
            ],
            [
              -95.579598,
              29.340129
            ],
            [
              -95.590793,
              29.327485
            ],
            [
              -95.604975,
              29.330737
            ],
            [
              -95.627943,
              29.309062
            ],
            [
              -95.626058,
              29.293091
            ],
            [
              -95.64065,
              29.296637
            ],
            [
              -95.654355,
              29.290945
            ],
            [
              -95.672785,
              29.299527
            ],
            [
              -95.752866,
              29.324503
            ],
            [
              -95.784013,
              29.296249
            ],
            [
              -95.79782,
              29.307971
            ],
            [
              -95.847656,
              29.26259
            ],
            [
              -95.841948,
              29.258707
            ],
            [
              -95.874028,
              29.229702
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "017",
      "COUNTYNS": "01383794",
      "AFFGEOID": "0500000US48017",
      "GEOID": "48017",
      "NAME": "Bailey",
      "LSAD": "06",
      "ALAND": 2141847193,
      "AWATER": 1775275,
      "County_state": "Bailey,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.047346,
              33.824675
            ],
            [
              -103.04734601957101,
              33.8246741231489
            ],
            [
              -102.615447,
              33.825121
            ],
            [
              -102.61515,
              34.312891
            ],
            [
              -103.043978936987,
              34.312749083575
            ],
            [
              -103.043936266136,
              34.302648
            ],
            [
              -103.043936,
              34.302585
            ],
            [
              -103.043719,
              34.289441
            ],
            [
              -103.043644,
              34.256903
            ],
            [
              -103.043580047453,
              34.112834113618
            ],
            [
              -103.043569,
              34.087947
            ],
            [
              -103.043516,
              34.079382
            ],
            [
              -103.043686,
              34.063078
            ],
            [
              -103.043744,
              34.049986
            ],
            [
              -103.043767,
              34.043545
            ],
            [
              -103.043721,
              34.04232
            ],
            [
              -103.043771,
              34.041538
            ],
            [
              -103.043746,
              34.037294
            ],
            [
              -103.043555,
              34.032714
            ],
            [
              -103.043531,
              34.018014
            ],
            [
              -103.043571877651,
              34.0111784012571
            ],
            [
              -103.043617,
              34.003633
            ],
            [
              -103.04395,
              33.974629
            ],
            [
              -103.044893,
              33.945617
            ],
            [
              -103.045698,
              33.906299
            ],
            [
              -103.045644,
              33.901537
            ],
            [
              -103.046907,
              33.8503
            ],
            [
              -103.047346,
              33.824675
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "225",
      "COUNTYNS": "01383898",
      "AFFGEOID": "0500000US48225",
      "GEOID": "48225",
      "NAME": "Houston",
      "LSAD": "06",
      "ALAND": 3188288249,
      "AWATER": 14426022,
      "County_state": "Houston,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.775578,
              31.136095
            ],
            [
              -95.76441,
              31.094211
            ],
            [
              -95.750226,
              31.092195
            ],
            [
              -95.723383,
              31.079834
            ],
            [
              -95.692388,
              31.08196
            ],
            [
              -95.676469,
              31.075908
            ],
            [
              -95.663546,
              31.083884
            ],
            [
              -95.656487,
              31.079339
            ],
            [
              -95.647546,
              31.055382
            ],
            [
              -95.629983,
              31.05175
            ],
            [
              -95.634704,
              31.037321
            ],
            [
              -95.66318,
              31.033216
            ],
            [
              -95.669505,
              31.024601
            ],
            [
              -95.658418,
              31.01631
            ],
            [
              -95.671059,
              30.993246
            ],
            [
              -95.681158,
              30.986958
            ],
            [
              -95.664954,
              30.970883
            ],
            [
              -95.638683,
              30.926736
            ],
            [
              -95.617872,
              30.930418
            ],
            [
              -95.434781,
              31.058098
            ],
            [
              -95.152477,
              31.251591
            ],
            [
              -94.95811,
              31.38693
            ],
            [
              -94.976033,
              31.407744
            ],
            [
              -95.005525,
              31.421076
            ],
            [
              -95.003345,
              31.42571
            ],
            [
              -95.0075,
              31.444676
            ],
            [
              -95.044193,
              31.444965
            ],
            [
              -95.064701,
              31.471316
            ],
            [
              -95.100323,
              31.468196
            ],
            [
              -95.105573,
              31.494668
            ],
            [
              -95.098046,
              31.493495
            ],
            [
              -95.099966,
              31.518869
            ],
            [
              -95.125338,
              31.537248
            ],
            [
              -95.143445,
              31.539641
            ],
            [
              -95.147048,
              31.550285
            ],
            [
              -95.139603,
              31.562821
            ],
            [
              -95.161757,
              31.57346
            ],
            [
              -95.168509,
              31.583671
            ],
            [
              -95.207701,
              31.570434
            ],
            [
              -95.260257,
              31.577104
            ],
            [
              -95.273203,
              31.592886
            ],
            [
              -95.651764,
              31.541791
            ],
            [
              -95.647171,
              31.527014
            ],
            [
              -95.739279,
              31.504056
            ],
            [
              -95.741854,
              31.486549
            ],
            [
              -95.729501,
              31.489184
            ],
            [
              -95.722805,
              31.47064
            ],
            [
              -95.733525,
              31.474338
            ],
            [
              -95.746837,
              31.45817
            ],
            [
              -95.725558,
              31.462302
            ],
            [
              -95.711092,
              31.454133
            ],
            [
              -95.711677,
              31.423594
            ],
            [
              -95.698322,
              31.406335
            ],
            [
              -95.691736,
              31.374934
            ],
            [
              -95.662074,
              31.35701
            ],
            [
              -95.674241,
              31.344824
            ],
            [
              -95.659812,
              31.345378
            ],
            [
              -95.657247,
              31.337448
            ],
            [
              -95.657202,
              31.317999
            ],
            [
              -95.671787,
              31.316026
            ],
            [
              -95.671033,
              31.296236
            ],
            [
              -95.682981,
              31.288917
            ],
            [
              -95.690753,
              31.297388
            ],
            [
              -95.702928,
              31.292461
            ],
            [
              -95.703505,
              31.282486
            ],
            [
              -95.725226,
              31.271084
            ],
            [
              -95.723741,
              31.246083
            ],
            [
              -95.727924,
              31.22466
            ],
            [
              -95.747943,
              31.20831
            ],
            [
              -95.745436,
              31.181892
            ],
            [
              -95.728703,
              31.164064
            ],
            [
              -95.749409,
              31.15524
            ],
            [
              -95.757402,
              31.141573
            ],
            [
              -95.775578,
              31.136095
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "411",
      "COUNTYNS": "01383991",
      "AFFGEOID": "0500000US48411",
      "GEOID": "48411",
      "NAME": "San Saba",
      "LSAD": "06",
      "ALAND": 2940447178,
      "AWATER": 8069104,
      "County_state": "San Saba,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.092317,
              30.941002
            ],
            [
              -99.092431,
              30.921968
            ],
            [
              -98.964612,
              30.921368
            ],
            [
              -98.445782,
              30.921439
            ],
            [
              -98.418746,
              30.92626
            ],
            [
              -98.414508,
              30.94211
            ],
            [
              -98.447098,
              30.951755
            ],
            [
              -98.452897,
              30.968727
            ],
            [
              -98.441874,
              30.984324
            ],
            [
              -98.459235,
              31.007745
            ],
            [
              -98.439687,
              31.029537
            ],
            [
              -98.45031,
              31.043014
            ],
            [
              -98.475999,
              31.053435
            ],
            [
              -98.484429,
              31.066357
            ],
            [
              -98.472994,
              31.075926
            ],
            [
              -98.457541,
              31.077713
            ],
            [
              -98.457493,
              31.088209
            ],
            [
              -98.469146,
              31.096645
            ],
            [
              -98.456288,
              31.111603
            ],
            [
              -98.47206,
              31.107866
            ],
            [
              -98.482,
              31.11918
            ],
            [
              -98.501115,
              31.116946
            ],
            [
              -98.523459,
              31.088886
            ],
            [
              -98.548051,
              31.123474
            ],
            [
              -98.526959,
              31.14039
            ],
            [
              -98.526728,
              31.155819
            ],
            [
              -98.510341,
              31.155666
            ],
            [
              -98.506571,
              31.167235
            ],
            [
              -98.518008,
              31.16463
            ],
            [
              -98.542016,
              31.174496
            ],
            [
              -98.535363,
              31.186768
            ],
            [
              -98.542421,
              31.19449
            ],
            [
              -98.556339,
              31.191988
            ],
            [
              -98.569004,
              31.198302
            ],
            [
              -98.562739,
              31.230582
            ],
            [
              -98.56355,
              31.23785
            ],
            [
              -98.567986,
              31.244425
            ],
            [
              -98.575136,
              31.254153
            ],
            [
              -98.5788,
              31.264542
            ],
            [
              -98.585566,
              31.266877
            ],
            [
              -98.593786,
              31.264168
            ],
            [
              -98.59405,
              31.25944
            ],
            [
              -98.594005,
              31.254713
            ],
            [
              -98.598519,
              31.254365
            ],
            [
              -98.613133,
              31.263
            ],
            [
              -98.624347,
              31.2695
            ],
            [
              -98.62823,
              31.275212
            ],
            [
              -98.625366,
              31.282454
            ],
            [
              -98.61607,
              31.282763
            ],
            [
              -98.610505,
              31.282533
            ],
            [
              -98.60017,
              31.286045
            ],
            [
              -98.598059,
              31.29369
            ],
            [
              -98.594835,
              31.310565
            ],
            [
              -98.588828,
              31.318093
            ],
            [
              -98.586464,
              31.323153
            ],
            [
              -98.588572,
              31.326044
            ],
            [
              -98.59101,
              31.32657
            ],
            [
              -98.595882,
              31.323707
            ],
            [
              -98.596567,
              31.319595
            ],
            [
              -98.61735,
              31.310519
            ],
            [
              -98.627426,
              31.309488
            ],
            [
              -98.628449,
              31.316895
            ],
            [
              -98.622799,
              31.319214
            ],
            [
              -98.619673,
              31.321519
            ],
            [
              -98.620324,
              31.324895
            ],
            [
              -98.624932,
              31.325468
            ],
            [
              -98.633868,
              31.323553
            ],
            [
              -98.637644,
              31.318983
            ],
            [
              -98.645239,
              31.314279
            ],
            [
              -98.649141,
              31.318588
            ],
            [
              -98.646264,
              31.324885
            ],
            [
              -98.641719,
              31.328359
            ],
            [
              -98.643453,
              31.334534
            ],
            [
              -98.646265,
              31.338931
            ],
            [
              -98.642572,
              31.345334
            ],
            [
              -98.639876,
              31.346106
            ],
            [
              -98.63834,
              31.354431
            ],
            [
              -98.643784,
              31.357519
            ],
            [
              -98.64944,
              31.358363
            ],
            [
              -98.650921,
              31.357208
            ],
            [
              -98.652142,
              31.362625
            ],
            [
              -98.656449,
              31.369173
            ],
            [
              -98.659268,
              31.366963
            ],
            [
              -98.665569,
              31.364204
            ],
            [
              -98.670069,
              31.355422
            ],
            [
              -98.677132,
              31.351898
            ],
            [
              -98.68917,
              31.344356
            ],
            [
              -98.695351,
              31.339963
            ],
            [
              -98.703111,
              31.339681
            ],
            [
              -98.705872,
              31.344581
            ],
            [
              -98.710159,
              31.347125
            ],
            [
              -98.712914,
              31.350469
            ],
            [
              -98.712715,
              31.353853
            ],
            [
              -98.708595,
              31.356169
            ],
            [
              -98.704636,
              31.357603
            ],
            [
              -98.702796,
              31.36544
            ],
            [
              -98.704832,
              31.371926
            ],
            [
              -98.699516,
              31.384792
            ],
            [
              -98.694812,
              31.391903
            ],
            [
              -98.695329,
              31.402497
            ],
            [
              -98.702963,
              31.407143
            ],
            [
              -98.716256,
              31.40446
            ],
            [
              -98.725273,
              31.408265
            ],
            [
              -98.727398,
              31.414837
            ],
            [
              -98.724612,
              31.421446
            ],
            [
              -98.726094,
              31.428278
            ],
            [
              -98.731591,
              31.429639
            ],
            [
              -98.737051,
              31.430346
            ],
            [
              -98.739612,
              31.424312
            ],
            [
              -98.736842,
              31.418832
            ],
            [
              -98.74027,
              31.4076
            ],
            [
              -98.749663,
              31.403838
            ],
            [
              -98.754649,
              31.401389
            ],
            [
              -98.754941,
              31.394867
            ],
            [
              -98.751716,
              31.389089
            ],
            [
              -98.757407,
              31.381701
            ],
            [
              -98.768452,
              31.37695
            ],
            [
              -98.775071,
              31.378395
            ],
            [
              -98.779244,
              31.378381
            ],
            [
              -98.777932,
              31.381846
            ],
            [
              -98.775545,
              31.392593
            ],
            [
              -98.773529,
              31.406063
            ],
            [
              -98.773309,
              31.41163
            ],
            [
              -98.782326,
              31.41103
            ],
            [
              -98.790562,
              31.407683
            ],
            [
              -98.801138,
              31.410188
            ],
            [
              -98.807711,
              31.405816
            ],
            [
              -98.814243,
              31.404972
            ],
            [
              -98.815738,
              31.410142
            ],
            [
              -98.814558,
              31.412198
            ],
            [
              -98.819906,
              31.416
            ],
            [
              -98.826983,
              31.411601
            ],
            [
              -98.840596,
              31.4105
            ],
            [
              -98.849791,
              31.41274
            ],
            [
              -98.853124,
              31.413034
            ],
            [
              -98.852382,
              31.419587
            ],
            [
              -98.856511,
              31.428117
            ],
            [
              -98.86973,
              31.442961
            ],
            [
              -98.880457,
              31.443763
            ],
            [
              -98.888621,
              31.439073
            ],
            [
              -98.896955,
              31.433701
            ],
            [
              -98.906369,
              31.435415
            ],
            [
              -98.916994,
              31.43584
            ],
            [
              -98.926766,
              31.434808
            ],
            [
              -98.934732,
              31.442359
            ],
            [
              -98.935172,
              31.452899
            ],
            [
              -98.938469,
              31.457473
            ],
            [
              -98.945928,
              31.458341
            ],
            [
              -98.950803,
              31.456462
            ],
            [
              -98.954505,
              31.449766
            ],
            [
              -98.958781,
              31.441733
            ],
            [
              -98.961536,
              31.437385
            ],
            [
              -98.967576,
              31.438058
            ],
            [
              -98.966922,
              31.442416
            ],
            [
              -98.964595,
              31.445165
            ],
            [
              -98.964453,
              31.451023
            ],
            [
              -98.971235,
              31.454591
            ],
            [
              -98.986041,
              31.46213
            ],
            [
              -98.991122,
              31.464653
            ],
            [
              -98.994805,
              31.470181
            ],
            [
              -98.989748,
              31.476136
            ],
            [
              -98.987172,
              31.481393
            ],
            [
              -98.990188,
              31.48391
            ],
            [
              -98.991608,
              31.484071
            ],
            [
              -99.018224,
              31.469519
            ],
            [
              -99.041343,
              31.490559
            ],
            [
              -99.087506,
              31.456017
            ],
            [
              -99.090622,
              31.460927
            ],
            [
              -99.092317,
              30.941002
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "377",
      "COUNTYNS": "01383974",
      "AFFGEOID": "0500000US48377",
      "GEOID": "48377",
      "NAME": "Presidio",
      "LSAD": "06",
      "ALAND": 9985057446,
      "AWATER": 1773188,
      "County_state": "Presidio,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.982130952491,
              30.6286049903774
            ],
            [
              -104.980291,
              30.62204
            ],
            [
              -104.972071,
              30.61026
            ],
            [
              -104.967167,
              30.608107
            ],
            [
              -104.939873,
              30.603939
            ],
            [
              -104.924796,
              30.604832
            ],
            [
              -104.899001,
              30.5704
            ],
            [
              -104.892228,
              30.55142
            ],
            [
              -104.889376,
              30.535144
            ],
            [
              -104.876787,
              30.511004
            ],
            [
              -104.866094,
              30.46738
            ],
            [
              -104.866119,
              30.46479
            ],
            [
              -104.868711,
              30.46323
            ],
            [
              -104.869872,
              30.458645
            ],
            [
              -104.861074,
              30.428897
            ],
            [
              -104.85242,
              30.418792
            ],
            [
              -104.85744,
              30.408957
            ],
            [
              -104.859521,
              30.390413
            ],
            [
              -104.837493,
              30.373909
            ],
            [
              -104.824314,
              30.370466
            ],
            [
              -104.817596,
              30.365915
            ],
            [
              -104.814779,
              30.360497
            ],
            [
              -104.813478,
              30.347061
            ],
            [
              -104.809794,
              30.334926
            ],
            [
              -104.797291,
              30.330336
            ],
            [
              -104.779356,
              30.313188
            ],
            [
              -104.761634,
              30.301148
            ],
            [
              -104.757894,
              30.282396
            ],
            [
              -104.751567,
              30.263644
            ],
            [
              -104.749664,
              30.26126
            ],
            [
              -104.740448,
              30.259454
            ],
            [
              -104.73736,
              30.26138
            ],
            [
              -104.733822,
              30.261221
            ],
            [
              -104.713166,
              30.237957
            ],
            [
              -104.711236,
              30.222921
            ],
            [
              -104.702788,
              30.211736
            ],
            [
              -104.687296,
              30.179464
            ],
            [
              -104.687507,
              30.162203
            ],
            [
              -104.692123,
              30.138663
            ],
            [
              -104.695366,
              30.13213
            ],
            [
              -104.692094,
              30.107304
            ],
            [
              -104.685687,
              30.095957
            ],
            [
              -104.685003,
              30.085643
            ],
            [
              -104.692778,
              30.069757
            ],
            [
              -104.698233,
              30.065287
            ],
            [
              -104.703582,
              30.06453
            ],
            [
              -104.706874,
              30.050685
            ],
            [
              -104.701102,
              30.035534
            ],
            [
              -104.703998,
              30.02421
            ],
            [
              -104.702311,
              30.021322
            ],
            [
              -104.693592,
              30.019077
            ],
            [
              -104.689641,
              30.01495
            ],
            [
              -104.685479,
              29.989943
            ],
            [
              -104.679661,
              29.975272
            ],
            [
              -104.684322,
              29.956086
            ],
            [
              -104.679772,
              29.924659
            ],
            [
              -104.672327,
              29.911112
            ],
            [
              -104.659042,
              29.901413
            ],
            [
              -104.65865,
              29.891557
            ],
            [
              -104.656783,
              29.889333
            ],
            [
              -104.633275,
              29.870485
            ],
            [
              -104.63036,
              29.863377
            ],
            [
              -104.630103,
              29.853314
            ],
            [
              -104.62435,
              29.845222
            ],
            [
              -104.619039,
              29.844445
            ],
            [
              -104.610166,
              29.819118
            ],
            [
              -104.599149,
              29.811007
            ],
            [
              -104.594023,
              29.809221
            ],
            [
              -104.592472,
              29.810276
            ],
            [
              -104.565688,
              29.770462
            ],
            [
              -104.565951,
              29.758795
            ],
            [
              -104.55466,
              29.740726
            ],
            [
              -104.555601,
              29.731221
            ],
            [
              -104.552241,
              29.717123
            ],
            [
              -104.536302,
              29.690441
            ],
            [
              -104.53577,
              29.687248
            ],
            [
              -104.540559,
              29.681129
            ],
            [
              -104.539761,
              29.676074
            ],
            [
              -104.507568,
              29.639624
            ],
            [
              -104.493659,
              29.634017
            ],
            [
              -104.483502,
              29.627741
            ],
            [
              -104.46652,
              29.609296
            ],
            [
              -104.452301,
              29.60366
            ],
            [
              -104.399591,
              29.572319
            ],
            [
              -104.394351,
              29.560535
            ],
            [
              -104.394589,
              29.556087
            ],
            [
              -104.381041,
              29.543406
            ],
            [
              -104.371175,
              29.543063
            ],
            [
              -104.338113,
              29.519967
            ],
            [
              -104.334811,
              29.519463
            ],
            [
              -104.327483,
              29.522188
            ],
            [
              -104.318074,
              29.527938
            ],
            [
              -104.308813,
              29.524337
            ],
            [
              -104.264155,
              29.514001
            ],
            [
              -104.260293,
              29.509425
            ],
            [
              -104.235847,
              29.496744
            ],
            [
              -104.233487,
              29.492734
            ],
            [
              -104.233825,
              29.486545
            ],
            [
              -104.229081,
              29.48105
            ],
            [
              -104.220569,
              29.477976
            ],
            [
              -104.213239,
              29.47301
            ],
            [
              -104.213948,
              29.456222
            ],
            [
              -104.212529,
              29.452439
            ],
            [
              -104.208194,
              29.448201
            ],
            [
              -104.19599,
              29.442884
            ],
            [
              -104.181273,
              29.426265
            ],
            [
              -104.18007,
              29.412764
            ],
            [
              -104.166563,
              29.399352
            ],
            [
              -104.143692,
              29.383278
            ],
            [
              -104.125475,
              29.380922
            ],
            [
              -104.106467,
              29.373127
            ],
            [
              -104.098378,
              29.366756
            ],
            [
              -104.093326,
              29.359926
            ],
            [
              -104.091022,
              29.353692
            ],
            [
              -104.08215,
              29.345923
            ],
            [
              -104.075924,
              29.345075
            ],
            [
              -104.057244,
              29.337694
            ],
            [
              -104.055596,
              29.33091
            ],
            [
              -104.038282,
              29.320156
            ],
            [
              -103.975235,
              29.296017
            ],
            [
              -103.965796,
              29.298587
            ],
            [
              -103.943698,
              29.294946
            ],
            [
              -103.924976,
              29.293913
            ],
            [
              -103.918857,
              29.290107
            ],
            [
              -103.91891,
              29.285042
            ],
            [
              -103.917106,
              29.284216
            ],
            [
              -103.880606,
              29.284962
            ],
            [
              -103.856893,
              29.281852
            ],
            [
              -103.838303,
              29.278304
            ],
            [
              -103.816642,
              29.270927
            ],
            [
              -103.79396654852101,
              29.259900560703098
            ],
            [
              -103.792764,
              29.27253
            ],
            [
              -103.797514,
              29.875173
            ],
            [
              -103.80068,
              30.412527
            ],
            [
              -103.90101,
              30.41219
            ],
            [
              -104.1107,
              30.454448
            ],
            [
              -104.982130952491,
              30.6286049903774
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "347",
      "COUNTYNS": "01383959",
      "AFFGEOID": "0500000US48347",
      "GEOID": "48347",
      "NAME": "Nacogdoches",
      "LSAD": "06",
      "ALAND": 2451572359,
      "AWATER": 89731152,
      "County_state": "Nacogdoches,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.978128,
              31.793294
            ],
            [
              -94.971831,
              31.782399
            ],
            [
              -94.929228,
              31.766354
            ],
            [
              -94.931666,
              31.754506
            ],
            [
              -94.967268,
              31.753816
            ],
            [
              -94.972757,
              31.737631
            ],
            [
              -94.958344,
              31.7241
            ],
            [
              -94.965946,
              31.697758
            ],
            [
              -94.94857,
              31.657413
            ],
            [
              -94.951969,
              31.641351
            ],
            [
              -94.941618,
              31.638244
            ],
            [
              -94.933467,
              31.621817
            ],
            [
              -94.924219,
              31.579487
            ],
            [
              -94.912305,
              31.569027
            ],
            [
              -94.892247,
              31.578087
            ],
            [
              -94.893623,
              31.541196
            ],
            [
              -94.883969,
              31.529693
            ],
            [
              -94.865857,
              31.526916
            ],
            [
              -94.853066,
              31.508472
            ],
            [
              -94.840041,
              31.507721
            ],
            [
              -94.82287,
              31.483978
            ],
            [
              -94.804291,
              31.490404
            ],
            [
              -94.799217,
              31.482177
            ],
            [
              -94.780864,
              31.481384
            ],
            [
              -94.765363,
              31.469938
            ],
            [
              -94.753006,
              31.470277
            ],
            [
              -94.728679,
              31.457226
            ],
            [
              -94.686349,
              31.450817
            ],
            [
              -94.662844,
              31.458798
            ],
            [
              -94.660266,
              31.452354
            ],
            [
              -94.628994,
              31.440258
            ],
            [
              -94.584339,
              31.444247
            ],
            [
              -94.581452,
              31.433189
            ],
            [
              -94.545121,
              31.431855
            ],
            [
              -94.542404,
              31.404967
            ],
            [
              -94.53136,
              31.39854
            ],
            [
              -94.515745,
              31.409344
            ],
            [
              -94.495874,
              31.405728
            ],
            [
              -94.487145,
              31.390788
            ],
            [
              -94.469789,
              31.383974
            ],
            [
              -94.470788,
              31.372324
            ],
            [
              -94.449422,
              31.321337
            ],
            [
              -94.396017,
              31.302356
            ],
            [
              -94.385391,
              31.290009
            ],
            [
              -94.383027,
              31.274109
            ],
            [
              -94.357426,
              31.259372
            ],
            [
              -94.347664,
              31.236953
            ],
            [
              -94.326616,
              31.224754
            ],
            [
              -94.339773,
              31.24082
            ],
            [
              -94.332508,
              31.251053
            ],
            [
              -94.339634,
              31.263273
            ],
            [
              -94.32219,
              31.285513
            ],
            [
              -94.31593,
              31.304503
            ],
            [
              -94.322219,
              31.323576
            ],
            [
              -94.31344,
              31.337524
            ],
            [
              -94.330364,
              31.359946
            ],
            [
              -94.322807,
              31.38804
            ],
            [
              -94.326934,
              31.399991
            ],
            [
              -94.312787,
              31.404326
            ],
            [
              -94.304935,
              31.421824
            ],
            [
              -94.320929,
              31.444941
            ],
            [
              -94.31192,
              31.46887
            ],
            [
              -94.318835,
              31.481651
            ],
            [
              -94.305526,
              31.496609
            ],
            [
              -94.300622,
              31.526458
            ],
            [
              -94.308582,
              31.551914
            ],
            [
              -94.304921,
              31.563346
            ],
            [
              -94.313399,
              31.575543
            ],
            [
              -94.310888,
              31.589043
            ],
            [
              -94.350359,
              31.606985
            ],
            [
              -94.36695,
              31.633647
            ],
            [
              -94.395278,
              31.638636
            ],
            [
              -94.399091,
              31.653596
            ],
            [
              -94.407006,
              31.665193
            ],
            [
              -94.403753,
              31.681568
            ],
            [
              -94.416809,
              31.705913
            ],
            [
              -94.412702,
              31.721238
            ],
            [
              -94.414101,
              31.751957
            ],
            [
              -94.432401,
              31.775157
            ],
            [
              -94.428516,
              31.776072
            ],
            [
              -94.423979,
              31.799016
            ],
            [
              -94.433945,
              31.824547
            ],
            [
              -94.452416,
              31.844281
            ],
            [
              -94.937532,
              31.845555
            ],
            [
              -94.93591,
              31.831482
            ],
            [
              -94.948359,
              31.82539
            ],
            [
              -94.942438,
              31.818948
            ],
            [
              -94.955426,
              31.796368
            ],
            [
              -94.978128,
              31.793294
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "477",
      "COUNTYNS": "01384024",
      "AFFGEOID": "0500000US48477",
      "GEOID": "48477",
      "NAME": "Washington",
      "LSAD": "06",
      "ALAND": 1564249679,
      "AWATER": 46077120,
      "County_state": "Washington,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.794552,
              30.160545
            ],
            [
              -96.773016,
              30.149466
            ],
            [
              -96.766937,
              30.138745
            ],
            [
              -96.735445,
              30.147393
            ],
            [
              -96.714989,
              30.144983
            ],
            [
              -96.687141,
              30.16435
            ],
            [
              -96.683394,
              30.151038
            ],
            [
              -96.647223,
              30.145174
            ],
            [
              -96.653112,
              30.12999
            ],
            [
              -96.640649,
              30.120579
            ],
            [
              -96.633673,
              30.106142
            ],
            [
              -96.634289,
              30.075701
            ],
            [
              -96.623863,
              30.066713
            ],
            [
              -96.62198,
              30.044283
            ],
            [
              -96.594141,
              30.071022
            ],
            [
              -96.585899,
              30.064668
            ],
            [
              -96.518814,
              30.065198
            ],
            [
              -96.287788,
              30.096784
            ],
            [
              -96.272479,
              30.094432
            ],
            [
              -96.259336,
              30.075744
            ],
            [
              -96.22859,
              30.075592
            ],
            [
              -96.21382,
              30.068988
            ],
            [
              -96.179773,
              30.062047
            ],
            [
              -96.159442,
              30.053832
            ],
            [
              -96.146052,
              30.070224
            ],
            [
              -96.13378,
              30.07096
            ],
            [
              -96.143502,
              30.084979
            ],
            [
              -96.158421,
              30.091709
            ],
            [
              -96.152557,
              30.102535
            ],
            [
              -96.160271,
              30.108066
            ],
            [
              -96.172823,
              30.099343
            ],
            [
              -96.175046,
              30.112435
            ],
            [
              -96.191782,
              30.13842
            ],
            [
              -96.171942,
              30.14116
            ],
            [
              -96.183153,
              30.15071
            ],
            [
              -96.176289,
              30.154821
            ],
            [
              -96.154866,
              30.141373
            ],
            [
              -96.150126,
              30.155445
            ],
            [
              -96.160031,
              30.161829
            ],
            [
              -96.154562,
              30.177124
            ],
            [
              -96.15901,
              30.206613
            ],
            [
              -96.145075,
              30.218566
            ],
            [
              -96.128228,
              30.211605
            ],
            [
              -96.113704,
              30.227203
            ],
            [
              -96.098936,
              30.214525
            ],
            [
              -96.093165,
              30.225187
            ],
            [
              -96.105379,
              30.23287
            ],
            [
              -96.087671,
              30.23777
            ],
            [
              -96.084578,
              30.251253
            ],
            [
              -96.09912,
              30.255174
            ],
            [
              -96.085893,
              30.288983
            ],
            [
              -96.10058,
              30.292805
            ],
            [
              -96.094404,
              30.309371
            ],
            [
              -96.120477,
              30.305689
            ],
            [
              -96.15463,
              30.330288
            ],
            [
              -96.16016,
              30.341739
            ],
            [
              -96.15263,
              30.350311
            ],
            [
              -96.149872,
              30.36879
            ],
            [
              -96.158438,
              30.378368
            ],
            [
              -96.176568,
              30.376103
            ],
            [
              -96.178661,
              30.394718
            ],
            [
              -96.19325,
              30.399645
            ],
            [
              -96.199544,
              30.38291
            ],
            [
              -96.25175,
              30.365384
            ],
            [
              -96.263441,
              30.369659
            ],
            [
              -96.268675,
              30.358675
            ],
            [
              -96.285753,
              30.364689
            ],
            [
              -96.299173,
              30.379152
            ],
            [
              -96.304075,
              30.383239
            ],
            [
              -96.312795,
              30.3843
            ],
            [
              -96.340485,
              30.367925
            ],
            [
              -96.360511,
              30.367844
            ],
            [
              -96.406558,
              30.351184
            ],
            [
              -96.409323,
              30.35839
            ],
            [
              -96.422745,
              30.343372
            ],
            [
              -96.462273,
              30.336285
            ],
            [
              -96.490504,
              30.335617
            ],
            [
              -96.496041,
              30.324358
            ],
            [
              -96.512799,
              30.325989
            ],
            [
              -96.540907,
              30.317985
            ],
            [
              -96.562359,
              30.322457
            ],
            [
              -96.567916,
              30.313239
            ],
            [
              -96.60599,
              30.302119
            ],
            [
              -96.640883,
              30.296786
            ],
            [
              -96.657895,
              30.287777
            ],
            [
              -96.685272,
              30.256014
            ],
            [
              -96.685672,
              30.248915
            ],
            [
              -96.707532,
              30.245341
            ],
            [
              -96.718437,
              30.25048
            ],
            [
              -96.734469,
              30.241229
            ],
            [
              -96.74362,
              30.218135
            ],
            [
              -96.741508,
              30.19743
            ],
            [
              -96.794552,
              30.160545
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "097",
      "COUNTYNS": "01383834",
      "AFFGEOID": "0500000US48097",
      "GEOID": "48097",
      "NAME": "Cooke",
      "LSAD": "06",
      "ALAND": 2265775270,
      "AWATER": 61333916,
      "County_state": "Cooke,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.48403724377,
              33.9157618691195
            ],
            [
              -97.487065,
              33.433681
            ],
            [
              -97.385897,
              33.432917
            ],
            [
              -97.383095,
              33.43045
            ],
            [
              -97.220092,
              33.425122
            ],
            [
              -96.94386,
              33.41641
            ],
            [
              -96.944611,
              33.949217
            ],
            [
              -96.952313,
              33.944582
            ],
            [
              -96.972542,
              33.935795
            ],
            [
              -96.973807,
              33.935697
            ],
            [
              -96.976955,
              33.937453
            ],
            [
              -96.979818,
              33.941588
            ],
            [
              -96.981031,
              33.94916
            ],
            [
              -96.980676,
              33.951814
            ],
            [
              -96.979347,
              33.95513
            ],
            [
              -96.979415,
              33.956178
            ],
            [
              -96.981337,
              33.956378
            ],
            [
              -96.987892,
              33.954671
            ],
            [
              -96.990835,
              33.952701
            ],
            [
              -96.994288,
              33.949206
            ],
            [
              -96.995368,
              33.947302
            ],
            [
              -96.996183,
              33.941728
            ],
            [
              -96.995023,
              33.932035
            ],
            [
              -96.993997,
              33.928979
            ],
            [
              -96.988745,
              33.918468
            ],
            [
              -96.984939,
              33.904866
            ],
            [
              -96.983971,
              33.892083
            ],
            [
              -96.985567,
              33.886522
            ],
            [
              -97.017857,
              33.850142
            ],
            [
              -97.023899,
              33.844213
            ],
            [
              -97.038858,
              33.838264
            ],
            [
              -97.041245,
              33.837761
            ],
            [
              -97.050952,
              33.846568
            ],
            [
              -97.055838,
              33.855741
            ],
            [
              -97.068071,
              33.860465
            ],
            [
              -97.087247,
              33.853448
            ],
            [
              -97.090479,
              33.844841
            ],
            [
              -97.08345,
              33.840456
            ],
            [
              -97.057821,
              33.83452
            ],
            [
              -97.048881,
              33.82521
            ],
            [
              -97.048146,
              33.817456
            ],
            [
              -97.067977,
              33.814476
            ],
            [
              -97.07859,
              33.812756
            ],
            [
              -97.087999,
              33.808747
            ],
            [
              -97.092112,
              33.804097
            ],
            [
              -97.094771,
              33.798532
            ],
            [
              -97.095236,
              33.794136
            ],
            [
              -97.093917,
              33.789052
            ],
            [
              -97.087852,
              33.774099
            ],
            [
              -97.085218,
              33.765512
            ],
            [
              -97.084613,
              33.759993
            ],
            [
              -97.084693,
              33.753147
            ],
            [
              -97.086195,
              33.743933
            ],
            [
              -97.091072,
              33.735115
            ],
            [
              -97.094085,
              33.730992
            ],
            [
              -97.097154,
              33.727809
            ],
            [
              -97.104525,
              33.722608
            ],
            [
              -97.108936,
              33.720294
            ],
            [
              -97.113265,
              33.718804
            ],
            [
              -97.121102,
              33.717174
            ],
            [
              -97.126102,
              33.716941
            ],
            [
              -97.13753,
              33.718664
            ],
            [
              -97.149394,
              33.721967
            ],
            [
              -97.155066,
              33.724442
            ],
            [
              -97.163149,
              33.729322
            ],
            [
              -97.172192,
              33.737545
            ],
            [
              -97.181843,
              33.75587
            ],
            [
              -97.187792,
              33.769702
            ],
            [
              -97.190397,
              33.781153
            ],
            [
              -97.194786,
              33.785344
            ],
            [
              -97.203236,
              33.797343
            ],
            [
              -97.205431,
              33.801488
            ],
            [
              -97.205652,
              33.809824
            ],
            [
              -97.204995,
              33.81887
            ],
            [
              -97.203514,
              33.821825
            ],
            [
              -97.1997,
              33.827322
            ],
            [
              -97.197477,
              33.829795
            ],
            [
              -97.195831,
              33.830803
            ],
            [
              -97.19369,
              33.831307
            ],
            [
              -97.186254,
              33.830894
            ],
            [
              -97.18137,
              33.831375
            ],
            [
              -97.171627,
              33.835335
            ],
            [
              -97.166824,
              33.840395
            ],
            [
              -97.166629,
              33.847311
            ],
            [
              -97.179609,
              33.89225
            ],
            [
              -97.180845,
              33.895204
            ],
            [
              -97.185458,
              33.9007
            ],
            [
              -97.206141,
              33.91428
            ],
            [
              -97.210921,
              33.916064
            ],
            [
              -97.226522,
              33.914642
            ],
            [
              -97.242092,
              33.906277
            ],
            [
              -97.244946,
              33.903092
            ],
            [
              -97.24618,
              33.900344
            ],
            [
              -97.249209,
              33.875101
            ],
            [
              -97.254235,
              33.865323
            ],
            [
              -97.255636,
              33.863698
            ],
            [
              -97.256625,
              33.863286
            ],
            [
              -97.271532,
              33.86256
            ],
            [
              -97.275348,
              33.863225
            ],
            [
              -97.279108,
              33.864555
            ],
            [
              -97.285983,
              33.868526
            ],
            [
              -97.294227,
              33.876412
            ],
            [
              -97.299245,
              33.880175
            ],
            [
              -97.302471,
              33.880175
            ],
            [
              -97.30749,
              33.878204
            ],
            [
              -97.314413,
              33.866989
            ],
            [
              -97.315913,
              33.865838
            ],
            [
              -97.318243,
              33.865121
            ],
            [
              -97.322365,
              33.864941
            ],
            [
              -97.324158,
              33.866017
            ],
            [
              -97.326487,
              33.872648
            ],
            [
              -97.327563,
              33.873903
            ],
            [
              -97.329176,
              33.87444
            ],
            [
              -97.33294,
              33.87444
            ],
            [
              -97.336524,
              33.872827
            ],
            [
              -97.339392,
              33.86763
            ],
            [
              -97.3409,
              33.860236
            ],
            [
              -97.348338,
              33.843876
            ],
            [
              -97.358513,
              33.830018
            ],
            [
              -97.365507,
              33.823763
            ],
            [
              -97.368744,
              33.821471
            ],
            [
              -97.372941,
              33.819454
            ],
            [
              -97.410387,
              33.818845
            ],
            [
              -97.426493,
              33.819398
            ],
            [
              -97.444193,
              33.823773
            ],
            [
              -97.453057,
              33.828536
            ],
            [
              -97.459068,
              33.834581
            ],
            [
              -97.462857,
              33.841772
            ],
            [
              -97.461486,
              33.84956
            ],
            [
              -97.459566,
              33.853316
            ],
            [
              -97.457617,
              33.855126
            ],
            [
              -97.451469,
              33.87093
            ],
            [
              -97.450954,
              33.891398
            ],
            [
              -97.458069,
              33.901635
            ],
            [
              -97.460376,
              33.903948
            ],
            [
              -97.48403724377,
              33.9157618691195
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "487",
      "COUNTYNS": "01384029",
      "AFFGEOID": "0500000US48487",
      "GEOID": "48487",
      "NAME": "Wilbarger",
      "LSAD": "06",
      "ALAND": 2514536080,
      "AWATER": 18196245,
      "County_state": "Wilbarger,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.475294,
              34.085618
            ],
            [
              -99.47565,
              33.834005
            ],
            [
              -98.953088,
              33.834
            ],
            [
              -98.9523575349127,
              34.2125787665873
            ],
            [
              -98.952513,
              34.21265
            ],
            [
              -98.958475,
              34.213855
            ],
            [
              -98.960791,
              34.21303
            ],
            [
              -98.962307,
              34.211312
            ],
            [
              -98.962085,
              34.206386
            ],
            [
              -98.96247,
              34.204668
            ],
            [
              -98.966302,
              34.201323
            ],
            [
              -98.969003,
              34.201299
            ],
            [
              -98.974132,
              34.203566
            ],
            [
              -98.976587,
              34.206291
            ],
            [
              -98.978685,
              34.210231
            ],
            [
              -98.981364,
              34.217583
            ],
            [
              -98.987294,
              34.221223
            ],
            [
              -98.990852,
              34.221633
            ],
            [
              -99.000761,
              34.217643
            ],
            [
              -99.003433,
              34.214466
            ],
            [
              -99.002916,
              34.208782
            ],
            [
              -99.00579,
              34.206647
            ],
            [
              -99.013075,
              34.203222
            ],
            [
              -99.036273,
              34.206912
            ],
            [
              -99.037459,
              34.206454
            ],
            [
              -99.039004,
              34.204667
            ],
            [
              -99.040962,
              34.200842
            ],
            [
              -99.043471,
              34.198208
            ],
            [
              -99.048792,
              34.198209
            ],
            [
              -99.058084,
              34.200569
            ],
            [
              -99.0588,
              34.201256
            ],
            [
              -99.059159,
              34.202929
            ],
            [
              -99.060344,
              34.204761
            ],
            [
              -99.066465,
              34.208404
            ],
            [
              -99.075978,
              34.211221
            ],
            [
              -99.079535,
              34.211518
            ],
            [
              -99.092191,
              34.209316
            ],
            [
              -99.108758,
              34.203401
            ],
            [
              -99.119204,
              34.201747
            ],
            [
              -99.126567,
              34.203004
            ],
            [
              -99.129792,
              34.204403
            ],
            [
              -99.131885,
              34.207382
            ],
            [
              -99.131553,
              34.209352
            ],
            [
              -99.13009,
              34.212192
            ],
            [
              -99.127525,
              34.213771
            ],
            [
              -99.126614,
              34.215329
            ],
            [
              -99.127549,
              34.217986
            ],
            [
              -99.128514,
              34.218766
            ],
            [
              -99.130609,
              34.219408
            ],
            [
              -99.13822,
              34.219159
            ],
            [
              -99.143985,
              34.214763
            ],
            [
              -99.159016,
              34.20888
            ],
            [
              -99.189511,
              34.214312
            ],
            [
              -99.192104,
              34.216694
            ],
            [
              -99.192683,
              34.218825
            ],
            [
              -99.192076,
              34.222192
            ],
            [
              -99.190036,
              34.227186
            ],
            [
              -99.190146,
              34.22966
            ],
            [
              -99.191139,
              34.23234
            ],
            [
              -99.195553,
              34.24006
            ],
            [
              -99.197153,
              34.244298
            ],
            [
              -99.196926,
              34.260929
            ],
            [
              -99.19457,
              34.272424
            ],
            [
              -99.195605,
              34.280839
            ],
            [
              -99.19626,
              34.281463
            ],
            [
              -99.200222,
              34.281152
            ],
            [
              -99.203681,
              34.281926
            ],
            [
              -99.207561,
              34.283505
            ],
            [
              -99.209742,
              34.286345
            ],
            [
              -99.211648,
              34.292232
            ],
            [
              -99.213476,
              34.310672
            ],
            [
              -99.2116,
              34.31397
            ],
            [
              -99.209724,
              34.324935
            ],
            [
              -99.210716,
              34.336304
            ],
            [
              -99.213135,
              34.340369
            ],
            [
              -99.217335,
              34.34152
            ],
            [
              -99.219769,
              34.341377
            ],
            [
              -99.221975,
              34.34002
            ],
            [
              -99.226153,
              34.339726
            ],
            [
              -99.229994,
              34.340538
            ],
            [
              -99.232606,
              34.34238
            ],
            [
              -99.233274,
              34.344101
            ],
            [
              -99.234252,
              34.353459
            ],
            [
              -99.237233,
              34.362717
            ],
            [
              -99.242945,
              34.372668
            ],
            [
              -99.248969,
              34.375984
            ],
            [
              -99.251408,
              34.37508
            ],
            [
              -99.254722,
              34.372405
            ],
            [
              -99.258696,
              34.372634
            ],
            [
              -99.271281,
              34.381604
            ],
            [
              -99.274926,
              34.384904
            ],
            [
              -99.27534,
              34.386599
            ],
            [
              -99.273958,
              34.38756
            ],
            [
              -99.264508,
              34.388085
            ],
            [
              -99.261191,
              34.389548
            ],
            [
              -99.25898,
              34.391243
            ],
            [
              -99.261321,
              34.403499
            ],
            [
              -99.264167,
              34.405149
            ],
            [
              -99.289922,
              34.414731
            ],
            [
              -99.294648,
              34.415373
            ],
            [
              -99.299098,
              34.414228
            ],
            [
              -99.308274,
              34.410014
            ],
            [
              -99.316373,
              34.408205
            ],
            [
              -99.318363,
              34.408296
            ],
            [
              -99.319606,
              34.408869
            ],
            [
              -99.324222,
              34.414458
            ],
            [
              -99.328674,
              34.422383
            ],
            [
              -99.334037,
              34.427536
            ],
            [
              -99.341337,
              34.431061
            ],
            [
              -99.350407,
              34.437083
            ],
            [
              -99.356713,
              34.442144
            ],
            [
              -99.357102,
              34.444915
            ],
            [
              -99.356771,
              34.446542
            ],
            [
              -99.354837,
              34.449658
            ],
            [
              -99.354672,
              34.451857
            ],
            [
              -99.358795,
              34.455863
            ],
            [
              -99.36961,
              34.458699
            ],
            [
              -99.375365,
              34.458845
            ],
            [
              -99.381011,
              34.456936
            ],
            [
              -99.394956,
              34.442099
            ],
            [
              -99.39701,
              34.424003
            ],
            [
              -99.396902,
              34.418688
            ],
            [
              -99.396488,
              34.417291
            ],
            [
              -99.393919,
              34.415274
            ],
            [
              -99.391492,
              34.405631
            ],
            [
              -99.397253,
              34.377871
            ],
            [
              -99.399603,
              34.375079
            ],
            [
              -99.40296,
              34.373481
            ],
            [
              -99.407168,
              34.372605
            ],
            [
              -99.408848,
              34.372776
            ],
            [
              -99.420432,
              34.380464
            ],
            [
              -99.430995,
              34.373414
            ],
            [
              -99.44076,
              34.374123
            ],
            [
              -99.445021,
              34.379892
            ],
            [
              -99.452648,
              34.388252
            ],
            [
              -99.470969,
              34.396471
            ],
            [
              -99.4751232484102,
              34.3963977417428
            ],
            [
              -99.475294,
              34.085618
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "289",
      "COUNTYNS": "01383930",
      "AFFGEOID": "0500000US48289",
      "GEOID": "48289",
      "NAME": "Leon",
      "LSAD": "06",
      "ALAND": 2779462014,
      "AWATER": 19328383,
      "County_state": "Leon,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.314773,
              31.265762
            ],
            [
              -96.331121,
              31.252465
            ],
            [
              -96.31415,
              31.228445
            ],
            [
              -96.311746,
              31.198121
            ],
            [
              -96.297174,
              31.180986
            ],
            [
              -96.300597,
              31.158982
            ],
            [
              -96.295868,
              31.139923
            ],
            [
              -96.276991,
              31.11628
            ],
            [
              -96.280753,
              31.096067
            ],
            [
              -96.269199,
              31.077855
            ],
            [
              -96.260594,
              31.077071
            ],
            [
              -96.243258,
              31.061137
            ],
            [
              -96.260308,
              31.006172
            ],
            [
              -96.238286,
              30.997722
            ],
            [
              -96.24102,
              30.973737
            ],
            [
              -96.193073,
              30.991158
            ],
            [
              -96.170918,
              30.9944
            ],
            [
              -96.075429,
              31.049203
            ],
            [
              -96.048912,
              31.068136
            ],
            [
              -96.008068,
              31.073112
            ],
            [
              -95.975218,
              31.091967
            ],
            [
              -95.76441,
              31.094211
            ],
            [
              -95.775578,
              31.136095
            ],
            [
              -95.757402,
              31.141573
            ],
            [
              -95.749409,
              31.15524
            ],
            [
              -95.728703,
              31.164064
            ],
            [
              -95.745436,
              31.181892
            ],
            [
              -95.747943,
              31.20831
            ],
            [
              -95.727924,
              31.22466
            ],
            [
              -95.723741,
              31.246083
            ],
            [
              -95.725226,
              31.271084
            ],
            [
              -95.703505,
              31.282486
            ],
            [
              -95.702928,
              31.292461
            ],
            [
              -95.690753,
              31.297388
            ],
            [
              -95.682981,
              31.288917
            ],
            [
              -95.671033,
              31.296236
            ],
            [
              -95.671787,
              31.316026
            ],
            [
              -95.657202,
              31.317999
            ],
            [
              -95.657247,
              31.337448
            ],
            [
              -95.659812,
              31.345378
            ],
            [
              -95.674241,
              31.344824
            ],
            [
              -95.662074,
              31.35701
            ],
            [
              -95.691736,
              31.374934
            ],
            [
              -95.698322,
              31.406335
            ],
            [
              -95.711677,
              31.423594
            ],
            [
              -95.711092,
              31.454133
            ],
            [
              -95.725558,
              31.462302
            ],
            [
              -95.746837,
              31.45817
            ],
            [
              -95.733525,
              31.474338
            ],
            [
              -95.722805,
              31.47064
            ],
            [
              -95.729501,
              31.489184
            ],
            [
              -95.741854,
              31.486549
            ],
            [
              -95.739279,
              31.504056
            ],
            [
              -95.737034,
              31.518534
            ],
            [
              -95.758711,
              31.533217
            ],
            [
              -95.751072,
              31.551964
            ],
            [
              -95.718512,
              31.553659
            ],
            [
              -95.717945,
              31.572504
            ],
            [
              -95.729304,
              31.582904
            ],
            [
              -95.71169,
              31.603162
            ],
            [
              -95.710112,
              31.615587
            ],
            [
              -95.735681,
              31.653822
            ],
            [
              -95.757268,
              31.643548
            ],
            [
              -95.748669,
              31.617709
            ],
            [
              -95.767234,
              31.597581
            ],
            [
              -95.777586,
              31.602257
            ],
            [
              -95.7873,
              31.618385
            ],
            [
              -96.236629,
              31.413392
            ],
            [
              -96.322853,
              31.374067
            ],
            [
              -96.319165,
              31.357129
            ],
            [
              -96.317205,
              31.3255
            ],
            [
              -96.325988,
              31.310713
            ],
            [
              -96.327988,
              31.28647
            ],
            [
              -96.314773,
              31.265762
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "255",
      "COUNTYNS": "01383913",
      "AFFGEOID": "0500000US48255",
      "GEOID": "48255",
      "NAME": "Karnes",
      "LSAD": "06",
      "ALAND": 1936008340,
      "AWATER": 15671660,
      "County_state": "Karnes,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.190991,
              28.882333
            ],
            [
              -98.168209,
              28.861277
            ],
            [
              -98.098315,
              28.786949
            ],
            [
              -98.005252,
              28.690239
            ],
            [
              -97.915106,
              28.719648
            ],
            [
              -97.891815,
              28.703097
            ],
            [
              -97.893402,
              28.700128
            ],
            [
              -97.858368,
              28.684344
            ],
            [
              -97.830111,
              28.678079
            ],
            [
              -97.825312,
              28.670341
            ],
            [
              -97.795231,
              28.673398
            ],
            [
              -97.77853,
              28.668027
            ],
            [
              -97.574639,
              28.8133
            ],
            [
              -97.75511,
              29.007113
            ],
            [
              -97.630208,
              29.098363
            ],
            [
              -97.613132,
              29.109765
            ],
            [
              -97.728443,
              29.221748
            ],
            [
              -97.995302,
              29.026104
            ],
            [
              -98.190991,
              28.882333
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "381",
      "COUNTYNS": "01383976",
      "AFFGEOID": "0500000US48381",
      "GEOID": "48381",
      "NAME": "Randall",
      "LSAD": "06",
      "ALAND": 2361843607,
      "AWATER": 27235708,
      "County_state": "Randall,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.167471,
              35.183233
            ],
            [
              -102.168839,
              34.747417
            ],
            [
              -101.998493,
              34.74819
            ],
            [
              -101.629257,
              34.747649
            ],
            [
              -101.622941,
              35.183117
            ],
            [
              -102.167471,
              35.183233
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "123",
      "COUNTYNS": "01383844",
      "AFFGEOID": "0500000US48123",
      "GEOID": "48123",
      "NAME": "DeWitt",
      "LSAD": "06",
      "ALAND": 2354237945,
      "AWATER": 3919919,
      "County_state": "DeWitt,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.630208,
              29.098363
            ],
            [
              -97.75511,
              29.007113
            ],
            [
              -97.574639,
              28.8133
            ],
            [
              -97.41734,
              28.925228
            ],
            [
              -97.400677,
              28.915535
            ],
            [
              -97.382417,
              28.915666
            ],
            [
              -97.384011,
              28.907417
            ],
            [
              -97.371474,
              28.899258
            ],
            [
              -97.339985,
              28.895647
            ],
            [
              -97.327249,
              28.885748
            ],
            [
              -97.325499,
              28.874837
            ],
            [
              -97.310406,
              28.877304
            ],
            [
              -97.305916,
              28.864037
            ],
            [
              -96.976378,
              29.104046
            ],
            [
              -97.240108,
              29.384488
            ],
            [
              -97.335207,
              29.315049
            ],
            [
              -97.613132,
              29.109765
            ],
            [
              -97.630208,
              29.098363
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "475",
      "COUNTYNS": "01384023",
      "AFFGEOID": "0500000US48475",
      "GEOID": "48475",
      "NAME": "Ward",
      "LSAD": "06",
      "ALAND": 2164201087,
      "AWATER": 624206,
      "County_state": "Ward,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.610887,
              31.651802
            ],
            [
              -103.57489,
              31.632662
            ],
            [
              -103.562324,
              31.63291
            ],
            [
              -103.549328,
              31.644627
            ],
            [
              -103.530927,
              31.642284
            ],
            [
              -103.511306,
              31.626272
            ],
            [
              -103.504196,
              31.596648
            ],
            [
              -103.514044,
              31.582693
            ],
            [
              -103.511417,
              31.567004
            ],
            [
              -103.495599,
              31.55029
            ],
            [
              -103.503505,
              31.505477
            ],
            [
              -103.482102,
              31.477162
            ],
            [
              -103.489935,
              31.459714
            ],
            [
              -103.459426,
              31.428494
            ],
            [
              -103.440544,
              31.431934
            ],
            [
              -103.418577,
              31.426159
            ],
            [
              -103.393806,
              31.43133
            ],
            [
              -103.369224,
              31.414137
            ],
            [
              -103.344586,
              31.422246
            ],
            [
              -103.329941,
              31.411998
            ],
            [
              -103.290353,
              31.421807
            ],
            [
              -103.280647,
              31.415779
            ],
            [
              -103.261677,
              31.42757
            ],
            [
              -103.259519,
              31.412437
            ],
            [
              -103.244934,
              31.389368
            ],
            [
              -103.181225,
              31.370245
            ],
            [
              -103.158592,
              31.372326
            ],
            [
              -103.14249,
              31.36541
            ],
            [
              -103.107271,
              31.361057
            ],
            [
              -103.067348,
              31.365197
            ],
            [
              -103.057765,
              31.385291
            ],
            [
              -103.023895,
              31.379569
            ],
            [
              -103.01104,
              31.371306
            ],
            [
              -102.998868,
              31.35908
            ],
            [
              -102.965675,
              31.351309
            ],
            [
              -102.933578,
              31.350081
            ],
            [
              -102.930357,
              31.340064
            ],
            [
              -102.913983,
              31.339118
            ],
            [
              -102.895667,
              31.322069
            ],
            [
              -102.875387,
              31.316292
            ],
            [
              -102.877926,
              31.307024
            ],
            [
              -102.856346,
              31.290915
            ],
            [
              -102.838849,
              31.285834
            ],
            [
              -102.826735,
              31.266881
            ],
            [
              -102.802115,
              31.281113
            ],
            [
              -102.767365,
              31.293803
            ],
            [
              -102.767246,
              31.651714
            ],
            [
              -102.798939,
              31.651783
            ],
            [
              -102.803806,
              31.651306
            ],
            [
              -103.327538,
              31.65142
            ],
            [
              -103.610887,
              31.651802
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "485",
      "COUNTYNS": "01384028",
      "AFFGEOID": "0500000US48485",
      "GEOID": "48485",
      "NAME": "Wichita",
      "LSAD": "06",
      "ALAND": 1625943418,
      "AWATER": 13820243,
      "County_state": "Wichita,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.9523575349127,
              34.2125787665873
            ],
            [
              -98.953088,
              33.834
            ],
            [
              -98.423577,
              33.836047
            ],
            [
              -98.422992,
              33.843812
            ],
            [
              -98.4231158951185,
              34.0835477226062
            ],
            [
              -98.42523,
              34.084799
            ],
            [
              -98.42848,
              34.085523
            ],
            [
              -98.432127,
              34.085622
            ],
            [
              -98.440092,
              34.084311
            ],
            [
              -98.442808,
              34.083144
            ],
            [
              -98.443724,
              34.082152
            ],
            [
              -98.445585,
              34.079298
            ],
            [
              -98.445784,
              34.076827
            ],
            [
              -98.446379,
              34.07543
            ],
            [
              -98.449034,
              34.073462
            ],
            [
              -98.475066,
              34.064269
            ],
            [
              -98.48204,
              34.06227
            ],
            [
              -98.486328,
              34.062598
            ],
            [
              -98.504182,
              34.072371
            ],
            [
              -98.5282,
              34.094961
            ],
            [
              -98.530611,
              34.099843
            ],
            [
              -98.536257,
              34.107343
            ],
            [
              -98.550917,
              34.119334
            ],
            [
              -98.558593,
              34.128254
            ],
            [
              -98.560191,
              34.133202
            ],
            [
              -98.572451,
              34.145091
            ],
            [
              -98.577136,
              34.148962
            ],
            [
              -98.599789,
              34.160571
            ],
            [
              -98.603978,
              34.160249
            ],
            [
              -98.608853,
              34.157521
            ],
            [
              -98.6101579900004,
              34.157098719969596
            ],
            [
              -98.611829,
              34.156558
            ],
            [
              -98.616733,
              34.156418
            ],
            [
              -98.621666,
              34.157195
            ],
            [
              -98.63573,
              34.161618
            ],
            [
              -98.643223,
              34.164531
            ],
            [
              -98.648073,
              34.164441
            ],
            [
              -98.650583,
              34.163113
            ],
            [
              -98.655655,
              34.158258
            ],
            [
              -98.690072,
              34.133155
            ],
            [
              -98.696518,
              34.133521
            ],
            [
              -98.716104,
              34.135947
            ],
            [
              -98.717537,
              34.13645
            ],
            [
              -98.734287,
              34.135758
            ],
            [
              -98.735471,
              34.135208
            ],
            [
              -98.73682,
              34.133374
            ],
            [
              -98.737232,
              34.130992
            ],
            [
              -98.739461,
              34.127394
            ],
            [
              -98.741966,
              34.12553
            ],
            [
              -98.749291,
              34.124238
            ],
            [
              -98.757037,
              34.124633
            ],
            [
              -98.759653,
              34.126912
            ],
            [
              -98.759486,
              34.128882
            ],
            [
              -98.760558,
              34.132388
            ],
            [
              -98.761797,
              34.133785
            ],
            [
              -98.76557,
              34.136376
            ],
            [
              -98.792015,
              34.143736
            ],
            [
              -98.80681,
              34.155901
            ],
            [
              -98.812954,
              34.158444
            ],
            [
              -98.831115,
              34.162154
            ],
            [
              -98.855585,
              34.161621
            ],
            [
              -98.857322,
              34.161094
            ],
            [
              -98.8579,
              34.159627
            ],
            [
              -98.858419,
              34.152732
            ],
            [
              -98.860125,
              34.149913
            ],
            [
              -98.86255,
              34.149111
            ],
            [
              -98.868116,
              34.149635
            ],
            [
              -98.873271,
              34.153596
            ],
            [
              -98.874872,
              34.155657
            ],
            [
              -98.874955,
              34.157031
            ],
            [
              -98.872229,
              34.160446
            ],
            [
              -98.871211,
              34.163012
            ],
            [
              -98.871543,
              34.165027
            ],
            [
              -98.872922,
              34.166584
            ],
            [
              -98.909349,
              34.177499
            ],
            [
              -98.918333,
              34.181831
            ],
            [
              -98.920704,
              34.183435
            ],
            [
              -98.923129,
              34.185978
            ],
            [
              -98.927456,
              34.191155
            ],
            [
              -98.928145,
              34.192689
            ],
            [
              -98.94022,
              34.203686
            ],
            [
              -98.950396,
              34.21168
            ],
            [
              -98.9523575349127,
              34.2125787665873
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "489",
      "COUNTYNS": "01384030",
      "AFFGEOID": "0500000US48489",
      "GEOID": "48489",
      "NAME": "Willacy",
      "LSAD": "06",
      "ALAND": 1529611927,
      "AWATER": 501715022,
      "County_state": "Willacy,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.305931,
              26.579632
            ],
            [
              -97.367934,
              26.556414
            ],
            [
              -97.284301,
              26.54261
            ],
            [
              -97.252453,
              26.438192
            ],
            [
              -97.24466398494118,
              26.411496535115198
            ],
            [
              -97.22523655679909,
              26.411526542410698
            ],
            [
              -97.253801,
              26.503164
            ],
            [
              -97.289717699364,
              26.6003484681446
            ],
            [
              -97.3148893003729,
              26.6002710565844
            ],
            [
              -97.305931,
              26.579632
            ]
          ]
        ],
        [
          [
            [
              -98.004189,
              26.448784
            ],
            [
              -97.988318,
              26.446514
            ],
            [
              -97.908296,
              26.434908
            ],
            [
              -97.86192,
              26.433579
            ],
            [
              -97.861875,
              26.348156
            ],
            [
              -97.575868,
              26.306249
            ],
            [
              -97.527582,
              26.299304
            ],
            [
              -97.52401,
              26.32355
            ],
            [
              -97.500824,
              26.318852
            ],
            [
              -97.509035,
              26.333581
            ],
            [
              -97.479342,
              26.335871
            ],
            [
              -97.467876,
              26.342902
            ],
            [
              -97.466457,
              26.333952
            ],
            [
              -97.446987,
              26.324947
            ],
            [
              -97.437276,
              26.337233
            ],
            [
              -97.430051,
              26.364337
            ],
            [
              -97.421294,
              26.366138
            ],
            [
              -97.400779,
              26.38652
            ],
            [
              -97.390733,
              26.411332
            ],
            [
              -97.3502357018891,
              26.4114389750309
            ],
            [
              -97.38055,
              26.480559
            ],
            [
              -97.413112,
              26.481197
            ],
            [
              -97.406813,
              26.499492
            ],
            [
              -97.419173,
              26.527247
            ],
            [
              -97.416955,
              26.553637
            ],
            [
              -97.428151,
              26.572466
            ],
            [
              -97.44120775772409,
              26.5999047499567
            ],
            [
              -97.861688,
              26.597995
            ],
            [
              -97.957405,
              26.611769
            ],
            [
              -97.96884,
              26.551355
            ],
            [
              -97.963912,
              26.5335
            ],
            [
              -97.984367,
              26.536578
            ],
            [
              -98.004189,
              26.448784
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "419",
      "COUNTYNS": "01383995",
      "AFFGEOID": "0500000US48419",
      "GEOID": "48419",
      "NAME": "Shelby",
      "LSAD": "06",
      "ALAND": 2060566167,
      "AWATER": 101081674,
      "County_state": "Shelby,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.511431,
              31.973984
            ],
            [
              -94.511137,
              31.923879
            ],
            [
              -94.480709,
              31.902113
            ],
            [
              -94.46678,
              31.878991
            ],
            [
              -94.462597,
              31.85441
            ],
            [
              -94.452416,
              31.844281
            ],
            [
              -94.433945,
              31.824547
            ],
            [
              -94.423979,
              31.799016
            ],
            [
              -94.428516,
              31.776072
            ],
            [
              -94.432401,
              31.775157
            ],
            [
              -94.414101,
              31.751957
            ],
            [
              -94.412702,
              31.721238
            ],
            [
              -94.416809,
              31.705913
            ],
            [
              -94.403753,
              31.681568
            ],
            [
              -94.407006,
              31.665193
            ],
            [
              -94.399091,
              31.653596
            ],
            [
              -94.090717,
              31.620609
            ],
            [
              -93.984461,
              31.569419
            ],
            [
              -93.969652,
              31.572371
            ],
            [
              -93.896537,
              31.611836
            ],
            [
              -93.88526,
              31.601426
            ],
            [
              -93.8349233245136,
              31.5862099793189
            ],
            [
              -93.834924,
              31.586211
            ],
            [
              -93.839383,
              31.599075
            ],
            [
              -93.838057,
              31.606795
            ],
            [
              -93.827852,
              31.616551
            ],
            [
              -93.823977,
              31.614228
            ],
            [
              -93.818717,
              31.614556
            ],
            [
              -93.81686189111218,
              31.622407879183
            ],
            [
              -93.816838,
              31.622509
            ],
            [
              -93.818037,
              31.647892
            ],
            [
              -93.825661,
              31.661022
            ],
            [
              -93.826462,
              31.666919
            ],
            [
              -93.822051,
              31.673967
            ],
            [
              -93.817425,
              31.672146
            ],
            [
              -93.804479,
              31.685664
            ],
            [
              -93.802452,
              31.693186
            ],
            [
              -93.802694,
              31.697783
            ],
            [
              -93.803419,
              31.700686
            ],
            [
              -93.80727,
              31.704232
            ],
            [
              -93.810304,
              31.705481
            ],
            [
              -93.814587,
              31.707444
            ],
            [
              -93.815836,
              31.711905
            ],
            [
              -93.815657,
              31.719043
            ],
            [
              -93.819048,
              31.728858
            ],
            [
              -93.824579,
              31.734397
            ],
            [
              -93.830647,
              31.745811
            ],
            [
              -93.830112,
              31.754555
            ],
            [
              -93.827343,
              31.75937
            ],
            [
              -93.822598,
              31.773559
            ],
            [
              -93.823443,
              31.775098
            ],
            [
              -93.827451,
              31.777741
            ],
            [
              -93.831197,
              31.780227
            ],
            [
              -93.834649,
              31.783309
            ],
            [
              -93.836868,
              31.788734
            ],
            [
              -93.836868,
              31.794159
            ],
            [
              -93.839951,
              31.798597
            ],
            [
              -93.846188,
              31.802021
            ],
            [
              -93.85339,
              31.805467
            ],
            [
              -93.870917,
              31.816837
            ],
            [
              -93.874761,
              31.821661
            ],
            [
              -93.874822,
              31.840611
            ],
            [
              -93.8792944619865,
              31.8439767742438
            ],
            [
              -93.884117,
              31.847606
            ],
            [
              -93.888149,
              31.856914
            ],
            [
              -93.889197,
              31.867693
            ],
            [
              -93.896981,
              31.873382
            ],
            [
              -93.901888,
              31.880063
            ],
            [
              -93.901173,
              31.885958
            ],
            [
              -93.904766,
              31.890599
            ],
            [
              -93.909557,
              31.893144
            ],
            [
              -93.915949,
              31.892861
            ],
            [
              -93.919588,
              31.890748
            ],
            [
              -93.923929,
              31.88985
            ],
            [
              -93.927672,
              31.891497
            ],
            [
              -93.932463,
              31.895539
            ],
            [
              -93.935008,
              31.903773
            ],
            [
              -93.938002,
              31.906917
            ],
            [
              -93.943541,
              31.908564
            ],
            [
              -93.953546,
              31.910563
            ],
            [
              -93.971712,
              31.920384
            ],
            [
              -93.977461,
              31.926419
            ],
            [
              -94.0127962602173,
              31.9816683460243
            ],
            [
              -94.0171,
              31.979837
            ],
            [
              -94.511431,
              31.973984
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "259",
      "COUNTYNS": "01383915",
      "AFFGEOID": "0500000US48259",
      "GEOID": "48259",
      "NAME": "Kendall",
      "LSAD": "06",
      "ALAND": 1715747530,
      "AWATER": 1496797,
      "County_state": "Kendall,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.920147,
              30.13829
            ],
            [
              -98.917725,
              29.781398
            ],
            [
              -98.778782,
              29.720167
            ],
            [
              -98.738551,
              29.716559
            ],
            [
              -98.735403,
              29.719633
            ],
            [
              -98.699994,
              29.737441
            ],
            [
              -98.659736,
              29.742033
            ],
            [
              -98.651278,
              29.754024
            ],
            [
              -98.646124,
              29.745181
            ],
            [
              -98.414018,
              29.937557
            ],
            [
              -98.587897,
              30.138954
            ],
            [
              -98.920147,
              30.13829
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "343",
      "COUNTYNS": "01383957",
      "AFFGEOID": "0500000US48343",
      "GEOID": "48343",
      "NAME": "Morris",
      "LSAD": "06",
      "ALAND": 652648672,
      "AWATER": 17382822,
      "County_state": "Morris,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.808783,
              33.363636
            ],
            [
              -94.819828,
              32.982886
            ],
            [
              -94.790304,
              32.96224
            ],
            [
              -94.768855,
              32.925834
            ],
            [
              -94.719942,
              32.904502
            ],
            [
              -94.705177,
              32.892062
            ],
            [
              -94.705878,
              32.879177
            ],
            [
              -94.653994,
              32.879506
            ],
            [
              -94.65226,
              33.268861
            ],
            [
              -94.686314,
              33.292777
            ],
            [
              -94.691826,
              33.312405
            ],
            [
              -94.73031,
              33.308814
            ],
            [
              -94.746925,
              33.328938
            ],
            [
              -94.781447,
              33.318722
            ],
            [
              -94.777214,
              33.331637
            ],
            [
              -94.800813,
              33.365354
            ],
            [
              -94.808783,
              33.363636
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "223",
      "COUNTYNS": "01383897",
      "AFFGEOID": "0500000US48223",
      "GEOID": "48223",
      "NAME": "Hopkins",
      "LSAD": "06",
      "ALAND": 1987018121,
      "AWATER": 66250826,
      "County_state": "Hopkins,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.863296,
              33.000117
            ],
            [
              -95.862521,
              32.979571
            ],
            [
              -95.667728,
              32.979554
            ],
            [
              -95.665389,
              32.960434
            ],
            [
              -95.308957,
              32.962572
            ],
            [
              -95.308593,
              33.37719
            ],
            [
              -95.314618,
              33.36707
            ],
            [
              -95.343552,
              33.361345
            ],
            [
              -95.369667,
              33.369999
            ],
            [
              -95.385084,
              33.365568
            ],
            [
              -95.400307,
              33.371698
            ],
            [
              -95.441294,
              33.362707
            ],
            [
              -95.457418,
              33.368243
            ],
            [
              -95.465034,
              33.356802
            ],
            [
              -95.501827,
              33.347865
            ],
            [
              -95.526442,
              33.349204
            ],
            [
              -95.534634,
              33.359538
            ],
            [
              -95.556795,
              33.351016
            ],
            [
              -95.584727,
              33.359136
            ],
            [
              -95.610126,
              33.349952
            ],
            [
              -95.615872,
              33.338323
            ],
            [
              -95.673722,
              33.302117
            ],
            [
              -95.692752,
              33.307246
            ],
            [
              -95.710363,
              33.301611
            ],
            [
              -95.731056,
              33.305222
            ],
            [
              -95.772304,
              33.278948
            ],
            [
              -95.786682,
              33.253997
            ],
            [
              -95.810261,
              33.241994
            ],
            [
              -95.845065,
              33.233241
            ],
            [
              -95.861778,
              33.21933
            ],
            [
              -95.863296,
              33.000117
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "397",
      "COUNTYNS": "01383984",
      "AFFGEOID": "0500000US48397",
      "GEOID": "48397",
      "NAME": "Rockwall",
      "LSAD": "06",
      "ALAND": 329205133,
      "AWATER": 56076769,
      "County_state": "Rockwall,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.518402,
              32.876468
            ],
            [
              -96.51897,
              32.813616
            ],
            [
              -96.297376,
              32.814185
            ],
            [
              -96.297322,
              32.841723
            ],
            [
              -96.297227,
              32.981752
            ],
            [
              -96.370728,
              32.982084
            ],
            [
              -96.46371,
              32.982439
            ],
            [
              -96.466726,
              32.982
            ],
            [
              -96.503025,
              32.981842
            ],
            [
              -96.516866,
              32.982308
            ],
            [
              -96.516999,
              32.973669
            ],
            [
              -96.517912,
              32.91723
            ],
            [
              -96.518402,
              32.876468
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "229",
      "COUNTYNS": "01383900",
      "AFFGEOID": "0500000US48229",
      "GEOID": "48229",
      "NAME": "Hudspeth",
      "LSAD": "06",
      "ALAND": 11837605523,
      "AWATER": 2189943,
      "County_state": "Hudspeth,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -105.998003,
              32.002328
            ],
            [
              -105.99800678071601,
              32.0023280060773
            ],
            [
              -105.994756,
              31.679688
            ],
            [
              -105.992695,
              31.500109
            ],
            [
              -105.993255,
              31.478392
            ],
            [
              -105.959262,
              31.478417
            ],
            [
              -105.958548,
              31.423461
            ],
            [
              -105.954328,
              31.423468
            ],
            [
              -105.997485690397,
              31.3867918276815
            ],
            [
              -105.970101,
              31.365937
            ],
            [
              -105.953943,
              31.364749
            ],
            [
              -105.945903,
              31.35281
            ],
            [
              -105.948091,
              31.340069
            ],
            [
              -105.938452,
              31.318735
            ],
            [
              -105.932553,
              31.313121
            ],
            [
              -105.908771,
              31.312774
            ],
            [
              -105.903461,
              31.306769
            ],
            [
              -105.895035,
              31.290978
            ],
            [
              -105.890872,
              31.290014
            ],
            [
              -105.876015,
              31.291589
            ],
            [
              -105.869353,
              31.288634
            ],
            [
              -105.835722,
              31.246812
            ],
            [
              -105.818835,
              31.230681
            ],
            [
              -105.794386,
              31.20224
            ],
            [
              -105.782895,
              31.197563
            ],
            [
              -105.779725,
              31.191283
            ],
            [
              -105.780021,
              31.182666
            ],
            [
              -105.773257,
              31.166897
            ],
            [
              -105.763531,
              31.164121
            ],
            [
              -105.742678,
              31.164897
            ],
            [
              -105.71954,
              31.149161
            ],
            [
              -105.717006,
              31.141438
            ],
            [
              -105.709491,
              31.136375
            ],
            [
              -105.648834,
              31.115902
            ],
            [
              -105.646731,
              31.113908
            ],
            [
              -105.64189,
              31.098322
            ],
            [
              -105.627349,
              31.098545
            ],
            [
              -105.60333,
              31.082625
            ],
            [
              -105.598773,
              31.074926
            ],
            [
              -105.595921,
              31.064842
            ],
            [
              -105.585323,
              31.057488
            ],
            [
              -105.579542,
              31.035396
            ],
            [
              -105.581404,
              31.026847
            ],
            [
              -105.579114,
              31.021645
            ],
            [
              -105.55743,
              30.990229
            ],
            [
              -105.543676,
              30.984746
            ],
            [
              -105.533088,
              30.984859
            ],
            [
              -105.502257,
              30.96268
            ],
            [
              -105.497964,
              30.957279
            ],
            [
              -105.496856,
              30.950494
            ],
            [
              -105.488027,
              30.943278
            ],
            [
              -105.430089,
              30.905792
            ],
            [
              -105.413505,
              30.899808
            ],
            [
              -105.399609,
              30.888941
            ],
            [
              -105.394249,
              30.871961
            ],
            [
              -105.39669,
              30.855427
            ],
            [
              -105.394242,
              30.852979
            ],
            [
              -105.377417,
              30.848645
            ],
            [
              -105.360672,
              30.847384
            ],
            [
              -105.347695,
              30.838065
            ],
            [
              -105.320108,
              30.827452
            ],
            [
              -105.31766,
              30.825004
            ],
            [
              -105.314863,
              30.816961
            ],
            [
              -105.303673,
              30.816961
            ],
            [
              -105.29563,
              30.822206
            ],
            [
              -105.287238,
              30.822206
            ],
            [
              -105.261361,
              30.798078
            ],
            [
              -105.255416,
              30.797029
            ],
            [
              -105.238364,
              30.803109
            ],
            [
              -105.21866,
              30.801567
            ],
            [
              -105.214891,
              30.79726
            ],
            [
              -105.216685,
              30.789543
            ],
            [
              -105.215967,
              30.786492
            ],
            [
              -105.212917,
              30.785415
            ],
            [
              -105.206161,
              30.786454
            ],
            [
              -105.195144,
              30.792138
            ],
            [
              -105.185931,
              30.784392
            ],
            [
              -105.183436,
              30.776645
            ],
            [
              -105.178279,
              30.772134
            ],
            [
              -105.164819,
              30.772493
            ],
            [
              -105.16123,
              30.767468
            ],
            [
              -105.160153,
              30.757059
            ],
            [
              -105.15764,
              30.754008
            ],
            [
              -105.152362,
              30.751452
            ],
            [
              -105.140207,
              30.752502
            ],
            [
              -105.123265,
              30.749504
            ],
            [
              -105.113816,
              30.746001
            ],
            [
              -105.110682,
              30.743366
            ],
            [
              -105.110706,
              30.73775
            ],
            [
              -105.108076,
              30.73005
            ],
            [
              -105.098282,
              30.718914
            ],
            [
              -105.062626,
              30.698222
            ],
            [
              -105.062334,
              30.686303
            ],
            [
              -105.049885,
              30.680817
            ],
            [
              -105.04293,
              30.687256
            ],
            [
              -105.020142,
              30.683637
            ],
            [
              -105.006801,
              30.686039
            ],
            [
              -105.002057,
              30.680972
            ],
            [
              -105.00124,
              30.672583
            ],
            [
              -104.9863,
              30.661059
            ],
            [
              -104.983981,
              30.635206
            ],
            [
              -104.982130952491,
              30.6286049903774
            ],
            [
              -104.973815,
              30.632569
            ],
            [
              -104.917163,
              30.663626
            ],
            [
              -104.916396,
              30.835979
            ],
            [
              -104.907592,
              30.835919
            ],
            [
              -104.907818,
              30.984874
            ],
            [
              -104.908893,
              31.000134
            ],
            [
              -104.918276,
              31.813306
            ],
            [
              -104.918356959202,
              32.0004960444129
            ],
            [
              -105.077046,
              32.000579
            ],
            [
              -105.078605,
              32.000533
            ],
            [
              -105.11804,
              32.000485
            ],
            [
              -105.131377,
              32.000524
            ],
            [
              -105.132916,
              32.000518
            ],
            [
              -105.14824,
              32.000485
            ],
            [
              -105.15031,
              32.000497
            ],
            [
              -105.153994,
              32.000497
            ],
            [
              -105.390396,
              32.000607
            ],
            [
              -105.427049,
              32.000638
            ],
            [
              -105.428582,
              32.0006
            ],
            [
              -105.429281,
              32.000577
            ],
            [
              -105.731362,
              32.001564
            ],
            [
              -105.750527,
              32.002206
            ],
            [
              -105.854061,
              32.00235
            ],
            [
              -105.886159,
              32.00197
            ],
            [
              -105.9006,
              32.0021
            ],
            [
              -105.998003,
              32.002328
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "423",
      "COUNTYNS": "01383997",
      "AFFGEOID": "0500000US48423",
      "GEOID": "48423",
      "NAME": "Smith",
      "LSAD": "06",
      "ALAND": 2386701635,
      "AWATER": 73066272,
      "County_state": "Smith,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.594541,
              32.687026
            ],
            [
              -95.5943,
              32.479858
            ],
            [
              -95.572885,
              32.461441
            ],
            [
              -95.552843,
              32.453606
            ],
            [
              -95.53912,
              32.432986
            ],
            [
              -95.501642,
              32.409239
            ],
            [
              -95.493797,
              32.38964
            ],
            [
              -95.463334,
              32.365206
            ],
            [
              -95.45647,
              32.368192
            ],
            [
              -95.449165,
              32.355233
            ],
            [
              -95.457825,
              32.327929
            ],
            [
              -95.446969,
              32.295807
            ],
            [
              -95.457268,
              32.273111
            ],
            [
              -95.469949,
              32.262124
            ],
            [
              -95.467584,
              32.24663
            ],
            [
              -95.488107,
              32.234912
            ],
            [
              -95.478936,
              32.212223
            ],
            [
              -95.462578,
              32.201763
            ],
            [
              -95.478585,
              32.147224
            ],
            [
              -95.459908,
              32.135559
            ],
            [
              -95.228007,
              32.136405
            ],
            [
              -94.985272,
              32.137989
            ],
            [
              -94.985411,
              32.37082
            ],
            [
              -94.986447,
              32.374229
            ],
            [
              -94.986935,
              32.537246
            ],
            [
              -95.009992,
              32.516478
            ],
            [
              -95.02236,
              32.521698
            ],
            [
              -95.015037,
              32.542696
            ],
            [
              -95.04076,
              32.532285
            ],
            [
              -95.046264,
              32.539246
            ],
            [
              -95.065935,
              32.537692
            ],
            [
              -95.083871,
              32.542541
            ],
            [
              -95.079116,
              32.556119
            ],
            [
              -95.094386,
              32.557372
            ],
            [
              -95.112711,
              32.550919
            ],
            [
              -95.140095,
              32.550162
            ],
            [
              -95.145888,
              32.569741
            ],
            [
              -95.15341,
              32.570115
            ],
            [
              -95.164973,
              32.544583
            ],
            [
              -95.176099,
              32.555247
            ],
            [
              -95.189129,
              32.546596
            ],
            [
              -95.206706,
              32.559952
            ],
            [
              -95.204289,
              32.571784
            ],
            [
              -95.23795,
              32.560541
            ],
            [
              -95.250314,
              32.577727
            ],
            [
              -95.277861,
              32.571588
            ],
            [
              -95.281255,
              32.580005
            ],
            [
              -95.345921,
              32.609152
            ],
            [
              -95.37483,
              32.602719
            ],
            [
              -95.375053,
              32.597448
            ],
            [
              -95.375217,
              32.597409
            ],
            [
              -95.37567,
              32.597439
            ],
            [
              -95.376136,
              32.597692
            ],
            [
              -95.377432,
              32.598872
            ],
            [
              -95.377668,
              32.599023
            ],
            [
              -95.378226,
              32.599282
            ],
            [
              -95.378389,
              32.599317
            ],
            [
              -95.379025,
              32.599344
            ],
            [
              -95.379534,
              32.599297
            ],
            [
              -95.380453,
              32.59867
            ],
            [
              -95.380819,
              32.59849
            ],
            [
              -95.381485,
              32.59838
            ],
            [
              -95.381931,
              32.598492
            ],
            [
              -95.382126,
              32.59872
            ],
            [
              -95.382225,
              32.599027
            ],
            [
              -95.382143,
              32.599973
            ],
            [
              -95.38251,
              32.600853
            ],
            [
              -95.382411,
              32.601002
            ],
            [
              -95.38195,
              32.601245
            ],
            [
              -95.381779,
              32.601484
            ],
            [
              -95.381786,
              32.601658
            ],
            [
              -95.381952,
              32.601852
            ],
            [
              -95.382175,
              32.601944
            ],
            [
              -95.382912,
              32.602015
            ],
            [
              -95.383681,
              32.602162
            ],
            [
              -95.384097,
              32.602142
            ],
            [
              -95.384607,
              32.601891
            ],
            [
              -95.384741,
              32.601709
            ],
            [
              -95.38477,
              32.601432
            ],
            [
              -95.384695,
              32.601193
            ],
            [
              -95.384752,
              32.600735
            ],
            [
              -95.38483,
              32.600572
            ],
            [
              -95.385161,
              32.60043
            ],
            [
              -95.385501,
              32.600416
            ],
            [
              -95.386634,
              32.600534
            ],
            [
              -95.387101,
              32.60056
            ],
            [
              -95.38748,
              32.60053
            ],
            [
              -95.387756,
              32.600271
            ],
            [
              -95.387816,
              32.600026
            ],
            [
              -95.387823,
              32.599671
            ],
            [
              -95.387889,
              32.599503
            ],
            [
              -95.38846,
              32.599081
            ],
            [
              -95.388751,
              32.59894
            ],
            [
              -95.389252,
              32.59886
            ],
            [
              -95.389629,
              32.5989
            ],
            [
              -95.389883,
              32.599027
            ],
            [
              -95.39002,
              32.599162
            ],
            [
              -95.390051,
              32.599299
            ],
            [
              -95.389808,
              32.599697
            ],
            [
              -95.389754,
              32.599974
            ],
            [
              -95.389892,
              32.600265
            ],
            [
              -95.390349,
              32.600714
            ],
            [
              -95.390785,
              32.601022
            ],
            [
              -95.391306,
              32.601258
            ],
            [
              -95.392716,
              32.601484
            ],
            [
              -95.393847,
              32.60174
            ],
            [
              -95.394194,
              32.601621
            ],
            [
              -95.394376,
              32.601345
            ],
            [
              -95.394558,
              32.601256
            ],
            [
              -95.394847,
              32.601197
            ],
            [
              -95.395471,
              32.601261
            ],
            [
              -95.395751,
              32.601335
            ],
            [
              -95.39603,
              32.601489
            ],
            [
              -95.396793,
              32.602049
            ],
            [
              -95.396803,
              32.602319
            ],
            [
              -95.396338,
              32.602767
            ],
            [
              -95.396165,
              32.603125
            ],
            [
              -95.395835,
              32.603613
            ],
            [
              -95.395757,
              32.603852
            ],
            [
              -95.395748,
              32.604318
            ],
            [
              -95.395834,
              32.604553
            ],
            [
              -95.396052,
              32.60477
            ],
            [
              -95.396427,
              32.604929
            ],
            [
              -95.397099,
              32.605001
            ],
            [
              -95.398118,
              32.605024
            ],
            [
              -95.398434,
              32.605119
            ],
            [
              -95.398649,
              32.605375
            ],
            [
              -95.398857,
              32.60602
            ],
            [
              -95.399419,
              32.606395
            ],
            [
              -95.399583,
              32.606401
            ],
            [
              -95.399837,
              32.606003
            ],
            [
              -95.400027,
              32.605936
            ],
            [
              -95.400353,
              32.606006
            ],
            [
              -95.400324,
              32.605389
            ],
            [
              -95.400214,
              32.604618
            ],
            [
              -95.400321,
              32.604528
            ],
            [
              -95.400563,
              32.604551
            ],
            [
              -95.400971,
              32.604892
            ],
            [
              -95.401157,
              32.605192
            ],
            [
              -95.401161,
              32.605379
            ],
            [
              -95.40145,
              32.605785
            ],
            [
              -95.401753,
              32.606285
            ],
            [
              -95.402128,
              32.606271
            ],
            [
              -95.402233,
              32.606178
            ],
            [
              -95.402034,
              32.605531
            ],
            [
              -95.401993,
              32.605043
            ],
            [
              -95.401834,
              32.604384
            ],
            [
              -95.401435,
              32.603893
            ],
            [
              -95.401439,
              32.603682
            ],
            [
              -95.401628,
              32.60342
            ],
            [
              -95.40177,
              32.603395
            ],
            [
              -95.402221,
              32.603443
            ],
            [
              -95.402745,
              32.603665
            ],
            [
              -95.403082,
              32.603879
            ],
            [
              -95.403394,
              32.60421
            ],
            [
              -95.403805,
              32.604263
            ],
            [
              -95.404205,
              32.604146
            ],
            [
              -95.405115,
              32.60371
            ],
            [
              -95.405325,
              32.603692
            ],
            [
              -95.405529,
              32.6038
            ],
            [
              -95.405637,
              32.60403
            ],
            [
              -95.405683,
              32.604308
            ],
            [
              -95.405614,
              32.604618
            ],
            [
              -95.405451,
              32.604942
            ],
            [
              -95.404923,
              32.605347
            ],
            [
              -95.404461,
              32.605369
            ],
            [
              -95.404155,
              32.605489
            ],
            [
              -95.403821,
              32.605887
            ],
            [
              -95.403738,
              32.606088
            ],
            [
              -95.403767,
              32.606439
            ],
            [
              -95.403893,
              32.606617
            ],
            [
              -95.4041,
              32.606735
            ],
            [
              -95.404391,
              32.606766
            ],
            [
              -95.404639,
              32.606741
            ],
            [
              -95.405459,
              32.606378
            ],
            [
              -95.406053,
              32.606357
            ],
            [
              -95.406294,
              32.606296
            ],
            [
              -95.406747,
              32.605932
            ],
            [
              -95.407441,
              32.605547
            ],
            [
              -95.407777,
              32.605539
            ],
            [
              -95.408009,
              32.60534
            ],
            [
              -95.408144,
              32.605007
            ],
            [
              -95.40836,
              32.60475
            ],
            [
              -95.408707,
              32.604568
            ],
            [
              -95.410087,
              32.604666
            ],
            [
              -95.410467,
              32.60455
            ],
            [
              -95.410724,
              32.604613
            ],
            [
              -95.410689,
              32.605071
            ],
            [
              -95.410751,
              32.60524
            ],
            [
              -95.411343,
              32.605404
            ],
            [
              -95.411919,
              32.605728
            ],
            [
              -95.412234,
              32.606108
            ],
            [
              -95.412601,
              32.606805
            ],
            [
              -95.412773,
              32.606955
            ],
            [
              -95.413061,
              32.607111
            ],
            [
              -95.435278,
              32.606751
            ],
            [
              -95.446747,
              32.613733
            ],
            [
              -95.469093,
              32.605642
            ],
            [
              -95.492049,
              32.617575
            ],
            [
              -95.510945,
              32.620328
            ],
            [
              -95.510699,
              32.626996
            ],
            [
              -95.530238,
              32.647489
            ],
            [
              -95.560574,
              32.662283
            ],
            [
              -95.568957,
              32.672544
            ],
            [
              -95.583593,
              32.674673
            ],
            [
              -95.594541,
              32.687026
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "215",
      "COUNTYNS": "01383893",
      "AFFGEOID": "0500000US48215",
      "GEOID": "48215",
      "NAME": "Hidalgo",
      "LSAD": "06",
      "ALAND": 4068610437,
      "AWATER": 31102594,
      "County_state": "Hidalgo,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.5855,
              26.259861
            ],
            [
              -98.5873137213485,
              26.2549142471762
            ],
            [
              -98.585184,
              26.254429
            ],
            [
              -98.58178,
              26.243001
            ],
            [
              -98.576188,
              26.235221
            ],
            [
              -98.5616,
              26.230845
            ],
            [
              -98.543852,
              26.234492
            ],
            [
              -98.538017,
              26.231331
            ],
            [
              -98.535241,
              26.225677
            ],
            [
              -98.52659,
              26.221606
            ],
            [
              -98.516621,
              26.223551
            ],
            [
              -98.509327,
              26.222822
            ],
            [
              -98.503492,
              26.214798
            ],
            [
              -98.500575,
              26.213826
            ],
            [
              -98.496684,
              26.212853
            ],
            [
              -98.483269,
              26.216439
            ],
            [
              -98.481646,
              26.219277
            ],
            [
              -98.465077,
              26.222335
            ],
            [
              -98.450976,
              26.219904
            ],
            [
              -98.443682,
              26.213339
            ],
            [
              -98.444376,
              26.201407
            ],
            [
              -98.442536,
              26.199151
            ],
            [
              -98.41812,
              26.184648
            ],
            [
              -98.404433,
              26.182564
            ],
            [
              -98.38673404290489,
              26.157927738170596
            ],
            [
              -98.386694,
              26.157872
            ],
            [
              -98.354645,
              26.15304
            ],
            [
              -98.345781,
              26.166016
            ],
            [
              -98.336837,
              26.166432
            ],
            [
              -98.333156,
              26.162336
            ],
            [
              -98.333316,
              26.1588
            ],
            [
              -98.33842,
              26.151344
            ],
            [
              -98.335204,
              26.137617
            ],
            [
              -98.323828,
              26.121249
            ],
            [
              -98.3081195433402,
              26.1128112329065
            ],
            [
              -98.302979,
              26.11005
            ],
            [
              -98.299523,
              26.11749
            ],
            [
              -98.296195,
              26.120321
            ],
            [
              -98.265698,
              26.12037
            ],
            [
              -98.270034,
              26.10709
            ],
            [
              -98.272898,
              26.10629
            ],
            [
              -98.277218,
              26.098802
            ],
            [
              -98.264514,
              26.085507
            ],
            [
              -98.248806,
              26.073101
            ],
            [
              -98.230097,
              26.077155
            ],
            [
              -98.220673,
              26.076467
            ],
            [
              -98.20496,
              26.066419
            ],
            [
              -98.2035438317249,
              26.0639052926532
            ],
            [
              -98.200871,
              26.059161
            ],
            [
              -98.197046,
              26.056153
            ],
            [
              -98.191534,
              26.057118
            ],
            [
              -98.18906,
              26.063258
            ],
            [
              -98.177897,
              26.074672
            ],
            [
              -98.151731,
              26.058187
            ],
            [
              -98.149463,
              26.055813
            ],
            [
              -98.149463,
              26.051579
            ],
            [
              -98.146622,
              26.049412
            ],
            [
              -98.128331,
              26.061929
            ],
            [
              -98.1270321582507,
              26.0622481056046
            ],
            [
              -98.105505,
              26.067537
            ],
            [
              -98.097643,
              26.060271
            ],
            [
              -98.094432,
              26.058625
            ],
            [
              -98.091038,
              26.059169
            ],
            [
              -98.081884,
              26.063724
            ],
            [
              -98.081567,
              26.066108
            ],
            [
              -98.085849,
              26.069208
            ],
            [
              -98.084755,
              26.070808
            ],
            [
              -98.080495,
              26.070932
            ],
            [
              -98.076544,
              26.068042
            ],
            [
              -98.070025,
              26.051466
            ],
            [
              -98.070021,
              26.047992
            ],
            [
              -98.039239,
              26.041275
            ],
            [
              -98.034403,
              26.051375
            ],
            [
              -98.033102,
              26.061039
            ],
            [
              -98.028759,
              26.06647
            ],
            [
              -98.010971,
              26.063863
            ],
            [
              -97.981335,
              26.067182
            ],
            [
              -97.978769,
              26.059708
            ],
            [
              -97.967358,
              26.051718
            ],
            [
              -97.950095,
              26.061828
            ],
            [
              -97.944345,
              26.059621
            ],
            [
              -97.93542,
              26.052688
            ],
            [
              -97.913882,
              26.056539
            ],
            [
              -97.88653,
              26.066339
            ],
            [
              -97.876983,
              26.064483
            ],
            [
              -97.871187,
              26.058083
            ],
            [
              -97.8682353660958,
              26.0566559486928
            ],
            [
              -97.861776,
              26.070057
            ],
            [
              -97.861875,
              26.120295
            ],
            [
              -97.861875,
              26.129972
            ],
            [
              -97.861775,
              26.230051
            ],
            [
              -97.861875,
              26.348156
            ],
            [
              -97.86192,
              26.433579
            ],
            [
              -97.908296,
              26.434908
            ],
            [
              -97.988318,
              26.446514
            ],
            [
              -98.004189,
              26.448784
            ],
            [
              -97.984367,
              26.536578
            ],
            [
              -97.963912,
              26.5335
            ],
            [
              -97.96884,
              26.551355
            ],
            [
              -97.957405,
              26.611769
            ],
            [
              -97.985303,
              26.615809
            ],
            [
              -97.985494,
              26.780917
            ],
            [
              -98.32067,
              26.783081
            ],
            [
              -98.396759,
              26.625337
            ],
            [
              -98.491943,
              26.445362
            ],
            [
              -98.5855,
              26.259861
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "041",
      "COUNTYNS": "01383806",
      "AFFGEOID": "0500000US48041",
      "GEOID": "48041",
      "NAME": "Brazos",
      "LSAD": "06",
      "ALAND": 1516471988,
      "AWATER": 14824446,
      "County_state": "Brazos,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.582804,
              30.663792
            ],
            [
              -96.601723,
              30.640564
            ],
            [
              -96.568744,
              30.63558
            ],
            [
              -96.566632,
              30.629387
            ],
            [
              -96.510494,
              30.627828
            ],
            [
              -96.499935,
              30.612829
            ],
            [
              -96.487399,
              30.620478
            ],
            [
              -96.48505,
              30.603844
            ],
            [
              -96.471375,
              30.607222
            ],
            [
              -96.452151,
              30.597001
            ],
            [
              -96.438327,
              30.575143
            ],
            [
              -96.423096,
              30.587587
            ],
            [
              -96.420065,
              30.584309
            ],
            [
              -96.424087,
              30.578045
            ],
            [
              -96.423669,
              30.560487
            ],
            [
              -96.419261,
              30.545941
            ],
            [
              -96.413057,
              30.539535
            ],
            [
              -96.404662,
              30.540088
            ],
            [
              -96.400299,
              30.535565
            ],
            [
              -96.388993,
              30.53253
            ],
            [
              -96.382877,
              30.535409
            ],
            [
              -96.38522,
              30.544123
            ],
            [
              -96.375581,
              30.536078
            ],
            [
              -96.376526,
              30.546727
            ],
            [
              -96.358817,
              30.541753
            ],
            [
              -96.351966,
              30.501264
            ],
            [
              -96.344894,
              30.499795
            ],
            [
              -96.329935,
              30.477882
            ],
            [
              -96.340875,
              30.474543
            ],
            [
              -96.322466,
              30.45875
            ],
            [
              -96.320933,
              30.449567
            ],
            [
              -96.308921,
              30.455199
            ],
            [
              -96.290895,
              30.443999
            ],
            [
              -96.282564,
              30.426614
            ],
            [
              -96.287988,
              30.421287
            ],
            [
              -96.272049,
              30.416145
            ],
            [
              -96.278998,
              30.407422
            ],
            [
              -96.295201,
              30.412367
            ],
            [
              -96.306356,
              30.391715
            ],
            [
              -96.293339,
              30.382044
            ],
            [
              -96.299173,
              30.379152
            ],
            [
              -96.285753,
              30.364689
            ],
            [
              -96.268675,
              30.358675
            ],
            [
              -96.263441,
              30.369659
            ],
            [
              -96.25175,
              30.365384
            ],
            [
              -96.199544,
              30.38291
            ],
            [
              -96.19325,
              30.399645
            ],
            [
              -96.178661,
              30.394718
            ],
            [
              -96.176568,
              30.376103
            ],
            [
              -96.158438,
              30.378368
            ],
            [
              -96.149872,
              30.36879
            ],
            [
              -96.15263,
              30.350311
            ],
            [
              -96.16016,
              30.341739
            ],
            [
              -96.15463,
              30.330288
            ],
            [
              -96.139812,
              30.340935
            ],
            [
              -96.148465,
              30.345
            ],
            [
              -96.141609,
              30.36551
            ],
            [
              -96.147255,
              30.38284
            ],
            [
              -96.12174,
              30.383893
            ],
            [
              -96.108193,
              30.406208
            ],
            [
              -96.106341,
              30.421933
            ],
            [
              -96.087926,
              30.417293
            ],
            [
              -96.079969,
              30.43003
            ],
            [
              -96.104673,
              30.444007
            ],
            [
              -96.121806,
              30.443426
            ],
            [
              -96.129431,
              30.465829
            ],
            [
              -96.139903,
              30.472482
            ],
            [
              -96.1321,
              30.49054
            ],
            [
              -96.152643,
              30.520855
            ],
            [
              -96.148262,
              30.528146
            ],
            [
              -96.161536,
              30.541834
            ],
            [
              -96.161918,
              30.570559
            ],
            [
              -96.182286,
              30.586251
            ],
            [
              -96.188305,
              30.599335
            ],
            [
              -96.164099,
              30.658813
            ],
            [
              -96.172736,
              30.679843
            ],
            [
              -96.177911,
              30.709688
            ],
            [
              -96.164522,
              30.722083
            ],
            [
              -96.162587,
              30.739797
            ],
            [
              -96.170145,
              30.748855
            ],
            [
              -96.162644,
              30.769509
            ],
            [
              -96.183355,
              30.797273
            ],
            [
              -96.173851,
              30.801121
            ],
            [
              -96.16837,
              30.822997
            ],
            [
              -96.174627,
              30.829838
            ],
            [
              -96.17684,
              30.858018
            ],
            [
              -96.195713,
              30.871005
            ],
            [
              -96.193633,
              30.882402
            ],
            [
              -96.213165,
              30.911345
            ],
            [
              -96.198234,
              30.931861
            ],
            [
              -96.207477,
              30.947081
            ],
            [
              -96.231729,
              30.954802
            ],
            [
              -96.24102,
              30.973737
            ],
            [
              -96.263223,
              30.964123
            ],
            [
              -96.338001,
              30.920531
            ],
            [
              -96.36182,
              30.896874
            ],
            [
              -96.365642,
              30.884547
            ],
            [
              -96.387408,
              30.864012
            ],
            [
              -96.398471,
              30.840541
            ],
            [
              -96.410143,
              30.825844
            ],
            [
              -96.423395,
              30.79271
            ],
            [
              -96.45763,
              30.744474
            ],
            [
              -96.566983,
              30.69567
            ],
            [
              -96.584171,
              30.683191
            ],
            [
              -96.582804,
              30.663792
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "051",
      "COUNTYNS": "01383811",
      "AFFGEOID": "0500000US48051",
      "GEOID": "48051",
      "NAME": "Burleson",
      "LSAD": "06",
      "ALAND": 1706886781,
      "AWATER": 46048550,
      "County_state": "Burleson,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.963629,
              30.557169
            ],
            [
              -96.918902,
              30.539212
            ],
            [
              -96.904272,
              30.50844
            ],
            [
              -96.89681,
              30.484006
            ],
            [
              -96.90462,
              30.466453
            ],
            [
              -96.874822,
              30.457466
            ],
            [
              -96.87711,
              30.447945
            ],
            [
              -96.868031,
              30.433519
            ],
            [
              -96.846242,
              30.431387
            ],
            [
              -96.808258,
              30.396543
            ],
            [
              -96.790114,
              30.390697
            ],
            [
              -96.787643,
              30.380316
            ],
            [
              -96.769858,
              30.364743
            ],
            [
              -96.75407,
              30.329152
            ],
            [
              -96.743672,
              30.319757
            ],
            [
              -96.730407,
              30.32424
            ],
            [
              -96.72194,
              30.31889
            ],
            [
              -96.694682,
              30.320191
            ],
            [
              -96.663196,
              30.295735
            ],
            [
              -96.640883,
              30.296786
            ],
            [
              -96.60599,
              30.302119
            ],
            [
              -96.567916,
              30.313239
            ],
            [
              -96.562359,
              30.322457
            ],
            [
              -96.540907,
              30.317985
            ],
            [
              -96.512799,
              30.325989
            ],
            [
              -96.496041,
              30.324358
            ],
            [
              -96.490504,
              30.335617
            ],
            [
              -96.462273,
              30.336285
            ],
            [
              -96.422745,
              30.343372
            ],
            [
              -96.409323,
              30.35839
            ],
            [
              -96.406558,
              30.351184
            ],
            [
              -96.360511,
              30.367844
            ],
            [
              -96.340485,
              30.367925
            ],
            [
              -96.312795,
              30.3843
            ],
            [
              -96.304075,
              30.383239
            ],
            [
              -96.299173,
              30.379152
            ],
            [
              -96.293339,
              30.382044
            ],
            [
              -96.306356,
              30.391715
            ],
            [
              -96.295201,
              30.412367
            ],
            [
              -96.278998,
              30.407422
            ],
            [
              -96.272049,
              30.416145
            ],
            [
              -96.287988,
              30.421287
            ],
            [
              -96.282564,
              30.426614
            ],
            [
              -96.290895,
              30.443999
            ],
            [
              -96.308921,
              30.455199
            ],
            [
              -96.320933,
              30.449567
            ],
            [
              -96.322466,
              30.45875
            ],
            [
              -96.340875,
              30.474543
            ],
            [
              -96.329935,
              30.477882
            ],
            [
              -96.344894,
              30.499795
            ],
            [
              -96.351966,
              30.501264
            ],
            [
              -96.358817,
              30.541753
            ],
            [
              -96.376526,
              30.546727
            ],
            [
              -96.375581,
              30.536078
            ],
            [
              -96.38522,
              30.544123
            ],
            [
              -96.382877,
              30.535409
            ],
            [
              -96.388993,
              30.53253
            ],
            [
              -96.400299,
              30.535565
            ],
            [
              -96.404662,
              30.540088
            ],
            [
              -96.413057,
              30.539535
            ],
            [
              -96.419261,
              30.545941
            ],
            [
              -96.423669,
              30.560487
            ],
            [
              -96.424087,
              30.578045
            ],
            [
              -96.420065,
              30.584309
            ],
            [
              -96.423096,
              30.587587
            ],
            [
              -96.438327,
              30.575143
            ],
            [
              -96.452151,
              30.597001
            ],
            [
              -96.471375,
              30.607222
            ],
            [
              -96.48505,
              30.603844
            ],
            [
              -96.487399,
              30.620478
            ],
            [
              -96.499935,
              30.612829
            ],
            [
              -96.510494,
              30.627828
            ],
            [
              -96.566632,
              30.629387
            ],
            [
              -96.568744,
              30.63558
            ],
            [
              -96.601723,
              30.640564
            ],
            [
              -96.582804,
              30.663792
            ],
            [
              -96.584171,
              30.683191
            ],
            [
              -96.566983,
              30.69567
            ],
            [
              -96.595776,
              30.715072
            ],
            [
              -96.599467,
              30.728634
            ],
            [
              -96.621281,
              30.730819
            ],
            [
              -96.963629,
              30.557169
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "119",
      "COUNTYNS": "01383846",
      "AFFGEOID": "0500000US48119",
      "GEOID": "48119",
      "NAME": "Delta",
      "LSAD": "06",
      "ALAND": 665174292,
      "AWATER": 54666016,
      "County_state": "Delta,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.858723,
              33.40953
            ],
            [
              -95.861778,
              33.21933
            ],
            [
              -95.845065,
              33.233241
            ],
            [
              -95.810261,
              33.241994
            ],
            [
              -95.786682,
              33.253997
            ],
            [
              -95.772304,
              33.278948
            ],
            [
              -95.731056,
              33.305222
            ],
            [
              -95.710363,
              33.301611
            ],
            [
              -95.692752,
              33.307246
            ],
            [
              -95.673722,
              33.302117
            ],
            [
              -95.615872,
              33.338323
            ],
            [
              -95.610126,
              33.349952
            ],
            [
              -95.584727,
              33.359136
            ],
            [
              -95.556795,
              33.351016
            ],
            [
              -95.534634,
              33.359538
            ],
            [
              -95.526442,
              33.349204
            ],
            [
              -95.501827,
              33.347865
            ],
            [
              -95.465034,
              33.356802
            ],
            [
              -95.457418,
              33.368243
            ],
            [
              -95.441294,
              33.362707
            ],
            [
              -95.400307,
              33.371698
            ],
            [
              -95.385084,
              33.365568
            ],
            [
              -95.369667,
              33.369999
            ],
            [
              -95.343552,
              33.361345
            ],
            [
              -95.314618,
              33.36707
            ],
            [
              -95.308593,
              33.37719
            ],
            [
              -95.30664,
              33.378027
            ],
            [
              -95.308637,
              33.380662
            ],
            [
              -95.368791,
              33.394306
            ],
            [
              -95.399752,
              33.409778
            ],
            [
              -95.416898,
              33.412194
            ],
            [
              -95.449499,
              33.434785
            ],
            [
              -95.4646,
              33.433895
            ],
            [
              -95.473742,
              33.448054
            ],
            [
              -95.491897,
              33.455337
            ],
            [
              -95.500903,
              33.443404
            ],
            [
              -95.526956,
              33.445923
            ],
            [
              -95.565836,
              33.47706
            ],
            [
              -95.582012,
              33.469172
            ],
            [
              -95.603675,
              33.484721
            ],
            [
              -95.642957,
              33.48669
            ],
            [
              -95.681464,
              33.470273
            ],
            [
              -95.707786,
              33.48776
            ],
            [
              -95.726705,
              33.484471
            ],
            [
              -95.761076,
              33.495387
            ],
            [
              -95.776506,
              33.492396
            ],
            [
              -95.807818,
              33.475065
            ],
            [
              -95.826979,
              33.458968
            ],
            [
              -95.857738,
              33.461722
            ],
            [
              -95.858723,
              33.40953
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "105",
      "COUNTYNS": "01383838",
      "AFFGEOID": "0500000US48105",
      "GEOID": "48105",
      "NAME": "Crockett",
      "LSAD": "06",
      "ALAND": 7270935065,
      "AWATER": 53329,
      "County_state": "Crockett,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.388804,
              31.087156
            ],
            [
              -102.370932,
              31.085099
            ],
            [
              -102.356405,
              31.074445
            ],
            [
              -102.320801,
              31.061851
            ],
            [
              -102.288937,
              31.035768
            ],
            [
              -102.265961,
              31.034274
            ],
            [
              -102.24657,
              31.042053
            ],
            [
              -102.232035,
              31.035469
            ],
            [
              -102.20146,
              31.03052
            ],
            [
              -102.191731,
              31.003788
            ],
            [
              -102.185755,
              31.01514
            ],
            [
              -102.170389,
              31.006077
            ],
            [
              -102.15193,
              31.007302
            ],
            [
              -102.139397,
              30.994925
            ],
            [
              -102.127756,
              31.001183
            ],
            [
              -102.1099,
              30.990321
            ],
            [
              -102.09716,
              30.9956
            ],
            [
              -102.081729,
              30.986691
            ],
            [
              -102.049179,
              31.00364
            ],
            [
              -102.032599,
              30.996864
            ],
            [
              -102.011667,
              30.979338
            ],
            [
              -101.993026,
              30.989337
            ],
            [
              -101.98364,
              30.98717
            ],
            [
              -101.961123,
              30.970709
            ],
            [
              -101.951886,
              30.955676
            ],
            [
              -101.921017,
              30.946405
            ],
            [
              -101.919419,
              30.935145
            ],
            [
              -101.908196,
              30.928245
            ],
            [
              -101.883436,
              30.925647
            ],
            [
              -101.874276,
              30.912236
            ],
            [
              -101.89118,
              30.890743
            ],
            [
              -101.860053,
              30.873615
            ],
            [
              -101.845094,
              30.841032
            ],
            [
              -101.847209,
              30.828414
            ],
            [
              -101.831469,
              30.80173
            ],
            [
              -101.839894,
              30.785281
            ],
            [
              -101.834528,
              30.757949
            ],
            [
              -101.815828,
              30.752015
            ],
            [
              -101.807952,
              30.725266
            ],
            [
              -101.80742,
              30.699504
            ],
            [
              -101.775803,
              30.680894
            ],
            [
              -101.76842,
              30.653077
            ],
            [
              -101.756224,
              30.657394
            ],
            [
              -101.750433,
              30.649691
            ],
            [
              -101.712065,
              30.654643
            ],
            [
              -101.689683,
              30.639047
            ],
            [
              -101.669833,
              30.637907
            ],
            [
              -101.652446,
              30.620471
            ],
            [
              -101.66353,
              30.588858
            ],
            [
              -101.646245,
              30.560763
            ],
            [
              -101.667598,
              30.528024
            ],
            [
              -101.679959,
              30.521795
            ],
            [
              -101.675103,
              30.502136
            ],
            [
              -101.691825,
              30.491418
            ],
            [
              -101.688867,
              30.46762
            ],
            [
              -101.700101,
              30.457599
            ],
            [
              -101.721896,
              30.471016
            ],
            [
              -101.730972,
              30.459452
            ],
            [
              -101.719249,
              30.455138
            ],
            [
              -101.720323,
              30.442225
            ],
            [
              -101.740552,
              30.421321
            ],
            [
              -101.728732,
              30.408293
            ],
            [
              -101.701627,
              30.402241
            ],
            [
              -101.694458,
              30.393294
            ],
            [
              -101.697417,
              30.380493
            ],
            [
              -101.685271,
              30.369532
            ],
            [
              -101.65853,
              30.357861
            ],
            [
              -101.658886,
              30.342622
            ],
            [
              -101.669557,
              30.339355
            ],
            [
              -101.700606,
              30.345928
            ],
            [
              -101.736987,
              30.326975
            ],
            [
              -101.744509,
              30.301277
            ],
            [
              -101.7584,
              30.288044
            ],
            [
              -101.005154,
              30.287333
            ],
            [
              -100.972067,
              30.287596
            ],
            [
              -100.960643,
              30.287776
            ],
            [
              -100.960587,
              30.706071
            ],
            [
              -100.960501,
              30.859764
            ],
            [
              -100.961935,
              30.885072
            ],
            [
              -100.962176,
              31.08249
            ],
            [
              -101.274799,
              31.079379
            ],
            [
              -101.776191,
              31.079784
            ],
            [
              -102.301212,
              31.086212
            ],
            [
              -102.388804,
              31.087156
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "287",
      "COUNTYNS": "01383929",
      "AFFGEOID": "0500000US48287",
      "GEOID": "48287",
      "NAME": "Lee",
      "LSAD": "06",
      "ALAND": 1629205103,
      "AWATER": 13106451,
      "County_state": "Lee,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.334463,
              30.402843
            ],
            [
              -97.251707,
              30.362848
            ],
            [
              -97.199578,
              30.337833
            ],
            [
              -97.158811,
              30.310695
            ],
            [
              -97.081834,
              30.259357
            ],
            [
              -97.024461,
              30.051435
            ],
            [
              -97.019163,
              30.032
            ],
            [
              -96.845857,
              30.116027
            ],
            [
              -96.794552,
              30.160545
            ],
            [
              -96.741508,
              30.19743
            ],
            [
              -96.74362,
              30.218135
            ],
            [
              -96.734469,
              30.241229
            ],
            [
              -96.718437,
              30.25048
            ],
            [
              -96.707532,
              30.245341
            ],
            [
              -96.685672,
              30.248915
            ],
            [
              -96.685272,
              30.256014
            ],
            [
              -96.657895,
              30.287777
            ],
            [
              -96.640883,
              30.296786
            ],
            [
              -96.663196,
              30.295735
            ],
            [
              -96.694682,
              30.320191
            ],
            [
              -96.72194,
              30.31889
            ],
            [
              -96.730407,
              30.32424
            ],
            [
              -96.743672,
              30.319757
            ],
            [
              -96.75407,
              30.329152
            ],
            [
              -96.769858,
              30.364743
            ],
            [
              -96.787643,
              30.380316
            ],
            [
              -96.790114,
              30.390697
            ],
            [
              -96.808258,
              30.396543
            ],
            [
              -96.846242,
              30.431387
            ],
            [
              -96.868031,
              30.433519
            ],
            [
              -96.87711,
              30.447945
            ],
            [
              -96.874822,
              30.457466
            ],
            [
              -96.90462,
              30.466453
            ],
            [
              -96.89681,
              30.484006
            ],
            [
              -96.904272,
              30.50844
            ],
            [
              -96.918902,
              30.539212
            ],
            [
              -96.963629,
              30.557169
            ],
            [
              -97.155219,
              30.457344
            ],
            [
              -97.334463,
              30.402843
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "395",
      "COUNTYNS": "01383983",
      "AFFGEOID": "0500000US48395",
      "GEOID": "48395",
      "NAME": "Robertson",
      "LSAD": "06",
      "ALAND": 2214719320,
      "AWATER": 26358042,
      "County_state": "Robertson,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.829121,
              31.105871
            ],
            [
              -96.830691,
              31.100415
            ],
            [
              -96.809523,
              31.079576
            ],
            [
              -96.821189,
              31.064147
            ],
            [
              -96.805582,
              31.05957
            ],
            [
              -96.793139,
              31.065718
            ],
            [
              -96.785118,
              31.049102
            ],
            [
              -96.765945,
              31.043425
            ],
            [
              -96.778531,
              31.035629
            ],
            [
              -96.768284,
              30.999686
            ],
            [
              -96.755709,
              31.005378
            ],
            [
              -96.745062,
              30.994772
            ],
            [
              -96.771015,
              30.972177
            ],
            [
              -96.769573,
              30.958779
            ],
            [
              -96.755364,
              30.943576
            ],
            [
              -96.75633,
              30.927206
            ],
            [
              -96.741153,
              30.914874
            ],
            [
              -96.729418,
              30.913758
            ],
            [
              -96.729917,
              30.899846
            ],
            [
              -96.714846,
              30.898187
            ],
            [
              -96.698444,
              30.908772
            ],
            [
              -96.68497,
              30.906191
            ],
            [
              -96.695712,
              30.892557
            ],
            [
              -96.68171,
              30.882367
            ],
            [
              -96.693482,
              30.879318
            ],
            [
              -96.694853,
              30.864517
            ],
            [
              -96.670192,
              30.833871
            ],
            [
              -96.651681,
              30.827142
            ],
            [
              -96.635945,
              30.804285
            ],
            [
              -96.645906,
              30.791535
            ],
            [
              -96.646804,
              30.779113
            ],
            [
              -96.62445,
              30.779985
            ],
            [
              -96.611097,
              30.757354
            ],
            [
              -96.632729,
              30.741043
            ],
            [
              -96.621281,
              30.730819
            ],
            [
              -96.599467,
              30.728634
            ],
            [
              -96.595776,
              30.715072
            ],
            [
              -96.566983,
              30.69567
            ],
            [
              -96.45763,
              30.744474
            ],
            [
              -96.423395,
              30.79271
            ],
            [
              -96.410143,
              30.825844
            ],
            [
              -96.398471,
              30.840541
            ],
            [
              -96.387408,
              30.864012
            ],
            [
              -96.365642,
              30.884547
            ],
            [
              -96.36182,
              30.896874
            ],
            [
              -96.338001,
              30.920531
            ],
            [
              -96.263223,
              30.964123
            ],
            [
              -96.24102,
              30.973737
            ],
            [
              -96.238286,
              30.997722
            ],
            [
              -96.260308,
              31.006172
            ],
            [
              -96.243258,
              31.061137
            ],
            [
              -96.260594,
              31.077071
            ],
            [
              -96.269199,
              31.077855
            ],
            [
              -96.280753,
              31.096067
            ],
            [
              -96.276991,
              31.11628
            ],
            [
              -96.295868,
              31.139923
            ],
            [
              -96.300597,
              31.158982
            ],
            [
              -96.297174,
              31.180986
            ],
            [
              -96.311746,
              31.198121
            ],
            [
              -96.31415,
              31.228445
            ],
            [
              -96.331121,
              31.252465
            ],
            [
              -96.314773,
              31.265762
            ],
            [
              -96.327988,
              31.28647
            ],
            [
              -96.325988,
              31.310713
            ],
            [
              -96.317205,
              31.3255
            ],
            [
              -96.319165,
              31.357129
            ],
            [
              -96.596862,
              31.2209
            ],
            [
              -96.829121,
              31.105871
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "333",
      "COUNTYNS": "01383952",
      "AFFGEOID": "0500000US48333",
      "GEOID": "48333",
      "NAME": "Mills",
      "LSAD": "06",
      "ALAND": 1937867082,
      "AWATER": 4022957,
      "County_state": "Mills,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.994805,
              31.470181
            ],
            [
              -98.991122,
              31.464653
            ],
            [
              -98.986041,
              31.46213
            ],
            [
              -98.971235,
              31.454591
            ],
            [
              -98.964453,
              31.451023
            ],
            [
              -98.964595,
              31.445165
            ],
            [
              -98.966922,
              31.442416
            ],
            [
              -98.967576,
              31.438058
            ],
            [
              -98.961536,
              31.437385
            ],
            [
              -98.958781,
              31.441733
            ],
            [
              -98.954505,
              31.449766
            ],
            [
              -98.950803,
              31.456462
            ],
            [
              -98.945928,
              31.458341
            ],
            [
              -98.938469,
              31.457473
            ],
            [
              -98.935172,
              31.452899
            ],
            [
              -98.934732,
              31.442359
            ],
            [
              -98.926766,
              31.434808
            ],
            [
              -98.916994,
              31.43584
            ],
            [
              -98.906369,
              31.435415
            ],
            [
              -98.896955,
              31.433701
            ],
            [
              -98.888621,
              31.439073
            ],
            [
              -98.880457,
              31.443763
            ],
            [
              -98.86973,
              31.442961
            ],
            [
              -98.856511,
              31.428117
            ],
            [
              -98.852382,
              31.419587
            ],
            [
              -98.853124,
              31.413034
            ],
            [
              -98.849791,
              31.41274
            ],
            [
              -98.840596,
              31.4105
            ],
            [
              -98.826983,
              31.411601
            ],
            [
              -98.819906,
              31.416
            ],
            [
              -98.814558,
              31.412198
            ],
            [
              -98.815738,
              31.410142
            ],
            [
              -98.814243,
              31.404972
            ],
            [
              -98.807711,
              31.405816
            ],
            [
              -98.801138,
              31.410188
            ],
            [
              -98.790562,
              31.407683
            ],
            [
              -98.782326,
              31.41103
            ],
            [
              -98.773309,
              31.41163
            ],
            [
              -98.773529,
              31.406063
            ],
            [
              -98.775545,
              31.392593
            ],
            [
              -98.777932,
              31.381846
            ],
            [
              -98.779244,
              31.378381
            ],
            [
              -98.775071,
              31.378395
            ],
            [
              -98.768452,
              31.37695
            ],
            [
              -98.757407,
              31.381701
            ],
            [
              -98.751716,
              31.389089
            ],
            [
              -98.754941,
              31.394867
            ],
            [
              -98.754649,
              31.401389
            ],
            [
              -98.749663,
              31.403838
            ],
            [
              -98.74027,
              31.4076
            ],
            [
              -98.736842,
              31.418832
            ],
            [
              -98.739612,
              31.424312
            ],
            [
              -98.737051,
              31.430346
            ],
            [
              -98.731591,
              31.429639
            ],
            [
              -98.726094,
              31.428278
            ],
            [
              -98.724612,
              31.421446
            ],
            [
              -98.727398,
              31.414837
            ],
            [
              -98.725273,
              31.408265
            ],
            [
              -98.716256,
              31.40446
            ],
            [
              -98.702963,
              31.407143
            ],
            [
              -98.695329,
              31.402497
            ],
            [
              -98.694812,
              31.391903
            ],
            [
              -98.699516,
              31.384792
            ],
            [
              -98.704832,
              31.371926
            ],
            [
              -98.702796,
              31.36544
            ],
            [
              -98.704636,
              31.357603
            ],
            [
              -98.708595,
              31.356169
            ],
            [
              -98.712715,
              31.353853
            ],
            [
              -98.712914,
              31.350469
            ],
            [
              -98.710159,
              31.347125
            ],
            [
              -98.705872,
              31.344581
            ],
            [
              -98.703111,
              31.339681
            ],
            [
              -98.695351,
              31.339963
            ],
            [
              -98.68917,
              31.344356
            ],
            [
              -98.677132,
              31.351898
            ],
            [
              -98.670069,
              31.355422
            ],
            [
              -98.665569,
              31.364204
            ],
            [
              -98.659268,
              31.366963
            ],
            [
              -98.656449,
              31.369173
            ],
            [
              -98.652142,
              31.362625
            ],
            [
              -98.650921,
              31.357208
            ],
            [
              -98.64944,
              31.358363
            ],
            [
              -98.643784,
              31.357519
            ],
            [
              -98.63834,
              31.354431
            ],
            [
              -98.639876,
              31.346106
            ],
            [
              -98.642572,
              31.345334
            ],
            [
              -98.646265,
              31.338931
            ],
            [
              -98.643453,
              31.334534
            ],
            [
              -98.641719,
              31.328359
            ],
            [
              -98.646264,
              31.324885
            ],
            [
              -98.649141,
              31.318588
            ],
            [
              -98.645239,
              31.314279
            ],
            [
              -98.637644,
              31.318983
            ],
            [
              -98.633868,
              31.323553
            ],
            [
              -98.624932,
              31.325468
            ],
            [
              -98.620324,
              31.324895
            ],
            [
              -98.619673,
              31.321519
            ],
            [
              -98.622799,
              31.319214
            ],
            [
              -98.628449,
              31.316895
            ],
            [
              -98.627426,
              31.309488
            ],
            [
              -98.61735,
              31.310519
            ],
            [
              -98.596567,
              31.319595
            ],
            [
              -98.595882,
              31.323707
            ],
            [
              -98.59101,
              31.32657
            ],
            [
              -98.588572,
              31.326044
            ],
            [
              -98.586464,
              31.323153
            ],
            [
              -98.588828,
              31.318093
            ],
            [
              -98.594835,
              31.310565
            ],
            [
              -98.598059,
              31.29369
            ],
            [
              -98.60017,
              31.286045
            ],
            [
              -98.610505,
              31.282533
            ],
            [
              -98.61607,
              31.282763
            ],
            [
              -98.625366,
              31.282454
            ],
            [
              -98.62823,
              31.275212
            ],
            [
              -98.624347,
              31.2695
            ],
            [
              -98.613133,
              31.263
            ],
            [
              -98.598519,
              31.254365
            ],
            [
              -98.594005,
              31.254713
            ],
            [
              -98.59405,
              31.25944
            ],
            [
              -98.593786,
              31.264168
            ],
            [
              -98.585566,
              31.266877
            ],
            [
              -98.5788,
              31.264542
            ],
            [
              -98.575136,
              31.254153
            ],
            [
              -98.567986,
              31.244425
            ],
            [
              -98.56355,
              31.23785
            ],
            [
              -98.562739,
              31.230582
            ],
            [
              -98.266794,
              31.410521
            ],
            [
              -98.27107,
              31.416398
            ],
            [
              -98.463736,
              31.683989
            ],
            [
              -98.492802,
              31.723608
            ],
            [
              -98.668441,
              31.700514
            ],
            [
              -98.760057,
              31.687896
            ],
            [
              -98.779951,
              31.610735
            ],
            [
              -98.991608,
              31.484071
            ],
            [
              -98.990188,
              31.48391
            ],
            [
              -98.987172,
              31.481393
            ],
            [
              -98.989748,
              31.476136
            ],
            [
              -98.994805,
              31.470181
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "301",
      "COUNTYNS": "01383936",
      "AFFGEOID": "0500000US48301",
      "GEOID": "48301",
      "NAME": "Loving",
      "LSAD": "06",
      "ALAND": 1732059386,
      "AWATER": 20151852,
      "County_state": "Loving,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.980179,
              32.000125
            ],
            [
              -103.983936,
              31.994113
            ],
            [
              -103.974457,
              31.979206
            ],
            [
              -103.951922,
              31.987419
            ],
            [
              -103.941129,
              31.961357
            ],
            [
              -103.948592,
              31.954483
            ],
            [
              -103.940896,
              31.944546
            ],
            [
              -103.922396,
              31.942527
            ],
            [
              -103.915713,
              31.930858
            ],
            [
              -103.918636,
              31.917053
            ],
            [
              -103.912248,
              31.902424
            ],
            [
              -103.882389,
              31.891917
            ],
            [
              -103.885584,
              31.870373
            ],
            [
              -103.839642,
              31.889395
            ],
            [
              -103.829385,
              31.887992
            ],
            [
              -103.828676,
              31.862549
            ],
            [
              -103.813073,
              31.846324
            ],
            [
              -103.821109,
              31.838637
            ],
            [
              -103.807121,
              31.821757
            ],
            [
              -103.809458,
              31.794317
            ],
            [
              -103.797232,
              31.770187
            ],
            [
              -103.78469,
              31.77492
            ],
            [
              -103.781129,
              31.763894
            ],
            [
              -103.75732,
              31.751354
            ],
            [
              -103.754294,
              31.741041
            ],
            [
              -103.724012,
              31.739249
            ],
            [
              -103.691717,
              31.747783
            ],
            [
              -103.676026,
              31.722748
            ],
            [
              -103.659466,
              31.710398
            ],
            [
              -103.625726,
              31.705032
            ],
            [
              -103.620752,
              31.690342
            ],
            [
              -103.635639,
              31.690287
            ],
            [
              -103.632417,
              31.673624
            ],
            [
              -103.610887,
              31.651802
            ],
            [
              -103.327538,
              31.65142
            ],
            [
              -103.326501,
              32.00037
            ],
            [
              -103.72288201487001,
              32.0002083713881
            ],
            [
              -103.748317,
              32.000198
            ],
            [
              -103.875476,
              32.000554
            ],
            [
              -103.980179,
              32.000125
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "403",
      "COUNTYNS": "01383987",
      "AFFGEOID": "0500000US48403",
      "GEOID": "48403",
      "NAME": "Sabine",
      "LSAD": "06",
      "ALAND": 1272739766,
      "AWATER": 220719716,
      "County_state": "Sabine,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.0484,
              31.145596
            ],
            [
              -94.039034,
              31.134271
            ],
            [
              -93.911126,
              31.158071
            ],
            [
              -93.600308,
              31.176158
            ],
            [
              -93.602443,
              31.182541
            ],
            [
              -93.607288,
              31.205403
            ],
            [
              -93.604319,
              31.215286
            ],
            [
              -93.604319,
              31.220794
            ],
            [
              -93.60526,
              31.224153
            ],
            [
              -93.607409,
              31.227243
            ],
            [
              -93.609828,
              31.229661
            ],
            [
              -93.616007,
              31.23396
            ],
            [
              -93.616308,
              31.244595
            ],
            [
              -93.614288,
              31.251631
            ],
            [
              -93.613942,
              31.259375
            ],
            [
              -93.620343,
              31.271025
            ],
            [
              -93.642516,
              31.269508
            ],
            [
              -93.657004,
              31.281736
            ],
            [
              -93.668928,
              31.297975
            ],
            [
              -93.67544,
              31.30104
            ],
            [
              -93.684039,
              31.301771
            ],
            [
              -93.68688,
              31.305166
            ],
            [
              -93.687851,
              31.309835
            ],
            [
              -93.677277,
              31.330483
            ],
            [
              -93.668439,
              31.353012
            ],
            [
              -93.665825,
              31.355184
            ],
            [
              -93.664665,
              31.357698
            ],
            [
              -93.663698,
              31.360019
            ],
            [
              -93.663892,
              31.361953
            ],
            [
              -93.665052,
              31.363886
            ],
            [
              -93.66892,
              31.3664
            ],
            [
              -93.669693,
              31.371815
            ],
            [
              -93.668146,
              31.375103
            ],
            [
              -93.668533,
              31.379357
            ],
            [
              -93.670182,
              31.387184
            ],
            [
              -93.671644,
              31.393352
            ],
            [
              -93.674117,
              31.397681
            ],
            [
              -93.695866,
              31.409392
            ],
            [
              -93.701611,
              31.409334
            ],
            [
              -93.704879,
              31.410881
            ],
            [
              -93.704678,
              31.4189
            ],
            [
              -93.697603,
              31.428409
            ],
            [
              -93.70093,
              31.437784
            ],
            [
              -93.709416,
              31.442995
            ],
            [
              -93.749476,
              31.46869
            ],
            [
              -93.74987,
              31.475276
            ],
            [
              -93.74987,
              31.478929
            ],
            [
              -93.747841,
              31.480958
            ],
            [
              -93.745608,
              31.481973
            ],
            [
              -93.741885,
              31.483535
            ],
            [
              -93.737168,
              31.484622
            ],
            [
              -93.730998,
              31.492119
            ],
            [
              -93.728766,
              31.496786
            ],
            [
              -93.725925,
              31.504092
            ],
            [
              -93.726736,
              31.5116
            ],
            [
              -93.733433,
              31.513223
            ],
            [
              -93.739318,
              31.51505
            ],
            [
              -93.740332,
              31.517079
            ],
            [
              -93.741111,
              31.520101
            ],
            [
              -93.74155,
              31.522558
            ],
            [
              -93.743376,
              31.525196
            ],
            [
              -93.746826,
              31.526008
            ],
            [
              -93.74987,
              31.526211
            ],
            [
              -93.751899,
              31.525602
            ],
            [
              -93.75386,
              31.525331
            ],
            [
              -93.760062,
              31.523933
            ],
            [
              -93.780835,
              31.525384
            ],
            [
              -93.787687,
              31.527344
            ],
            [
              -93.798087,
              31.534044
            ],
            [
              -93.818582,
              31.554826
            ],
            [
              -93.820764,
              31.558221
            ],
            [
              -93.822958,
              31.56813
            ],
            [
              -93.8349233245136,
              31.5862099793189
            ],
            [
              -93.88526,
              31.601426
            ],
            [
              -93.896537,
              31.611836
            ],
            [
              -93.969652,
              31.572371
            ],
            [
              -93.984461,
              31.569419
            ],
            [
              -93.983239,
              31.466114
            ],
            [
              -94.000186,
              31.448357
            ],
            [
              -94.044154,
              31.409979
            ],
            [
              -94.026192,
              31.381523
            ],
            [
              -94.022635,
              31.363843
            ],
            [
              -94.041642,
              31.340255
            ],
            [
              -94.037115,
              31.332879
            ],
            [
              -94.047109,
              31.295911
            ],
            [
              -94.039134,
              31.288274
            ],
            [
              -94.029819,
              31.2401
            ],
            [
              -94.038575,
              31.217716
            ],
            [
              -94.038692,
              31.176124
            ],
            [
              -94.0484,
              31.145596
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "015",
      "COUNTYNS": "01383793",
      "AFFGEOID": "0500000US48015",
      "GEOID": "48015",
      "NAME": "Austin",
      "LSAD": "06",
      "ALAND": 1674405635,
      "AWATER": 25606133,
      "County_state": "Austin,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.594141,
              30.071022
            ],
            [
              -96.62198,
              30.044283
            ],
            [
              -96.569844,
              29.961516
            ],
            [
              -96.535705,
              29.907326
            ],
            [
              -96.485091,
              29.867335
            ],
            [
              -96.478609,
              29.869102
            ],
            [
              -96.459993,
              29.854641
            ],
            [
              -96.449966,
              29.856417
            ],
            [
              -96.434749,
              29.847945
            ],
            [
              -96.412691,
              29.824655
            ],
            [
              -96.388811,
              29.821459
            ],
            [
              -96.379327,
              29.825303
            ],
            [
              -96.357624,
              29.81915
            ],
            [
              -96.343159,
              29.82962
            ],
            [
              -96.330869,
              29.814449
            ],
            [
              -96.332676,
              29.802839
            ],
            [
              -96.318066,
              29.791231
            ],
            [
              -96.310732,
              29.755406
            ],
            [
              -96.297194,
              29.749718
            ],
            [
              -96.298312,
              29.737207
            ],
            [
              -96.270117,
              29.71658
            ],
            [
              -96.277718,
              29.700416
            ],
            [
              -96.266304,
              29.686955
            ],
            [
              -96.259226,
              29.668912
            ],
            [
              -96.240354,
              29.657821
            ],
            [
              -96.224679,
              29.66489
            ],
            [
              -96.199282,
              29.65646
            ],
            [
              -96.175422,
              29.633806
            ],
            [
              -96.141837,
              29.619636
            ],
            [
              -96.10965,
              29.622524
            ],
            [
              -96.088912,
              29.601658
            ],
            [
              -96.02485,
              29.602877
            ],
            [
              -96.02272,
              29.624443
            ],
            [
              -96.016438,
              29.627104
            ],
            [
              -96.017536,
              29.64546
            ],
            [
              -96.03185,
              29.655566
            ],
            [
              -96.016039,
              29.663716
            ],
            [
              -96.029664,
              29.675223
            ],
            [
              -96.04384,
              29.665793
            ],
            [
              -96.060001,
              29.675963
            ],
            [
              -96.057222,
              29.681943
            ],
            [
              -96.032157,
              29.688524
            ],
            [
              -96.016637,
              29.680061
            ],
            [
              -96.014524,
              29.693589
            ],
            [
              -96.022639,
              29.70501
            ],
            [
              -96.007579,
              29.702938
            ],
            [
              -96.011605,
              29.719843
            ],
            [
              -96.032388,
              29.717478
            ],
            [
              -96.032711,
              29.727944
            ],
            [
              -96.039844,
              29.737452
            ],
            [
              -96.029596,
              29.754666
            ],
            [
              -96.043009,
              29.779129
            ],
            [
              -96.041365,
              29.792443
            ],
            [
              -96.049234,
              29.803187
            ],
            [
              -96.082275,
              29.804733
            ],
            [
              -96.102496,
              29.809665
            ],
            [
              -96.098988,
              29.827382
            ],
            [
              -96.107925,
              29.82228
            ],
            [
              -96.121405,
              29.836652
            ],
            [
              -96.116245,
              29.847506
            ],
            [
              -96.101657,
              29.853963
            ],
            [
              -96.112227,
              29.872557
            ],
            [
              -96.095808,
              29.876676
            ],
            [
              -96.126267,
              29.89885
            ],
            [
              -96.113357,
              29.915565
            ],
            [
              -96.13165,
              29.937504
            ],
            [
              -96.121487,
              29.949189
            ],
            [
              -96.100796,
              29.943526
            ],
            [
              -96.106352,
              29.9555
            ],
            [
              -96.11946,
              29.955017
            ],
            [
              -96.122754,
              29.968545
            ],
            [
              -96.105258,
              29.959172
            ],
            [
              -96.095703,
              29.978139
            ],
            [
              -96.104516,
              29.996378
            ],
            [
              -96.084541,
              30.005137
            ],
            [
              -96.09471,
              30.025384
            ],
            [
              -96.113221,
              30.026997
            ],
            [
              -96.107465,
              30.038618
            ],
            [
              -96.117214,
              30.050126
            ],
            [
              -96.140629,
              30.058225
            ],
            [
              -96.146052,
              30.070224
            ],
            [
              -96.159442,
              30.053832
            ],
            [
              -96.179773,
              30.062047
            ],
            [
              -96.21382,
              30.068988
            ],
            [
              -96.22859,
              30.075592
            ],
            [
              -96.259336,
              30.075744
            ],
            [
              -96.272479,
              30.094432
            ],
            [
              -96.287788,
              30.096784
            ],
            [
              -96.518814,
              30.065198
            ],
            [
              -96.585899,
              30.064668
            ],
            [
              -96.594141,
              30.071022
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "383",
      "COUNTYNS": "01383977",
      "AFFGEOID": "0500000US48383",
      "GEOID": "48383",
      "NAME": "Reagan",
      "LSAD": "06",
      "ALAND": 3044049124,
      "AWATER": 1792716,
      "County_state": "Reagan,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.775802,
              31.651319
            ],
            [
              -101.776191,
              31.079784
            ],
            [
              -101.274799,
              31.079379
            ],
            [
              -101.267947,
              31.528688
            ],
            [
              -101.26763,
              31.556462
            ],
            [
              -101.267122,
              31.650854
            ],
            [
              -101.775802,
              31.651319
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "389",
      "COUNTYNS": "01383980",
      "AFFGEOID": "0500000US48389",
      "GEOID": "48389",
      "NAME": "Reeves",
      "LSAD": "06",
      "ALAND": 6825534308,
      "AWATER": 17396681,
      "County_state": "Reeves,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.101342,
              31.120164
            ],
            [
              -104.102351,
              31.105203
            ],
            [
              -103.585084,
              30.76647
            ],
            [
              -103.375412,
              30.987246
            ],
            [
              -103.01104,
              31.371306
            ],
            [
              -103.023895,
              31.379569
            ],
            [
              -103.057765,
              31.385291
            ],
            [
              -103.067348,
              31.365197
            ],
            [
              -103.107271,
              31.361057
            ],
            [
              -103.14249,
              31.36541
            ],
            [
              -103.158592,
              31.372326
            ],
            [
              -103.181225,
              31.370245
            ],
            [
              -103.244934,
              31.389368
            ],
            [
              -103.259519,
              31.412437
            ],
            [
              -103.261677,
              31.42757
            ],
            [
              -103.280647,
              31.415779
            ],
            [
              -103.290353,
              31.421807
            ],
            [
              -103.329941,
              31.411998
            ],
            [
              -103.344586,
              31.422246
            ],
            [
              -103.369224,
              31.414137
            ],
            [
              -103.393806,
              31.43133
            ],
            [
              -103.418577,
              31.426159
            ],
            [
              -103.440544,
              31.431934
            ],
            [
              -103.459426,
              31.428494
            ],
            [
              -103.489935,
              31.459714
            ],
            [
              -103.482102,
              31.477162
            ],
            [
              -103.503505,
              31.505477
            ],
            [
              -103.495599,
              31.55029
            ],
            [
              -103.511417,
              31.567004
            ],
            [
              -103.514044,
              31.582693
            ],
            [
              -103.504196,
              31.596648
            ],
            [
              -103.511306,
              31.626272
            ],
            [
              -103.530927,
              31.642284
            ],
            [
              -103.549328,
              31.644627
            ],
            [
              -103.562324,
              31.63291
            ],
            [
              -103.57489,
              31.632662
            ],
            [
              -103.610887,
              31.651802
            ],
            [
              -103.632417,
              31.673624
            ],
            [
              -103.635639,
              31.690287
            ],
            [
              -103.620752,
              31.690342
            ],
            [
              -103.625726,
              31.705032
            ],
            [
              -103.659466,
              31.710398
            ],
            [
              -103.676026,
              31.722748
            ],
            [
              -103.691717,
              31.747783
            ],
            [
              -103.724012,
              31.739249
            ],
            [
              -103.754294,
              31.741041
            ],
            [
              -103.75732,
              31.751354
            ],
            [
              -103.781129,
              31.763894
            ],
            [
              -103.78469,
              31.77492
            ],
            [
              -103.797232,
              31.770187
            ],
            [
              -103.809458,
              31.794317
            ],
            [
              -103.807121,
              31.821757
            ],
            [
              -103.821109,
              31.838637
            ],
            [
              -103.813073,
              31.846324
            ],
            [
              -103.828676,
              31.862549
            ],
            [
              -103.829385,
              31.887992
            ],
            [
              -103.839642,
              31.889395
            ],
            [
              -103.885584,
              31.870373
            ],
            [
              -103.882389,
              31.891917
            ],
            [
              -103.912248,
              31.902424
            ],
            [
              -103.918636,
              31.917053
            ],
            [
              -103.915713,
              31.930858
            ],
            [
              -103.922396,
              31.942527
            ],
            [
              -103.940896,
              31.944546
            ],
            [
              -103.948592,
              31.954483
            ],
            [
              -103.941129,
              31.961357
            ],
            [
              -103.951922,
              31.987419
            ],
            [
              -103.974457,
              31.979206
            ],
            [
              -103.983936,
              31.994113
            ],
            [
              -103.980179,
              32.000125
            ],
            [
              -104.024521,
              32.00001
            ],
            [
              -104.077294,
              31.394061
            ],
            [
              -104.100216,
              31.133361
            ],
            [
              -104.100333,
              31.125136
            ],
            [
              -104.101342,
              31.120164
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "439",
      "COUNTYNS": "01384005",
      "AFFGEOID": "0500000US48439",
      "GEOID": "48439",
      "NAME": "Tarrant",
      "LSAD": "06",
      "ALAND": 2236924384,
      "AWATER": 100013729,
      "County_state": "Tarrant,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.548201,
              32.776114
            ],
            [
              -97.550582,
              32.555391
            ],
            [
              -97.430998,
              32.554106
            ],
            [
              -97.386367,
              32.553559
            ],
            [
              -97.34899,
              32.553145
            ],
            [
              -97.313984,
              32.552627
            ],
            [
              -97.086834,
              32.549428
            ],
            [
              -97.058759,
              32.548966
            ],
            [
              -97.052636,
              32.548907
            ],
            [
              -97.038385,
              32.548662
            ],
            [
              -97.037063,
              32.645873
            ],
            [
              -97.036686,
              32.675913
            ],
            [
              -97.036675,
              32.676205
            ],
            [
              -97.036417,
              32.693188
            ],
            [
              -97.036207,
              32.711113
            ],
            [
              -97.035167,
              32.759368
            ],
            [
              -97.035171,
              32.759693
            ],
            [
              -97.03479,
              32.786041
            ],
            [
              -97.03601,
              32.805161
            ],
            [
              -97.034085,
              32.81666
            ],
            [
              -97.033781,
              32.837135
            ],
            [
              -97.033527,
              32.852752
            ],
            [
              -97.033088,
              32.878493
            ],
            [
              -97.032287,
              32.989324
            ],
            [
              -97.124154,
              32.989835
            ],
            [
              -97.12959,
              32.990011
            ],
            [
              -97.167261,
              32.989802
            ],
            [
              -97.180907,
              32.989813
            ],
            [
              -97.234794,
              32.989865
            ],
            [
              -97.328833,
              32.990116
            ],
            [
              -97.356048,
              32.990197
            ],
            [
              -97.39848,
              32.990839
            ],
            [
              -97.415462,
              32.992048
            ],
            [
              -97.544181,
              32.994177
            ],
            [
              -97.548201,
              32.776114
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "209",
      "COUNTYNS": "01383890",
      "AFFGEOID": "0500000US48209",
      "GEOID": "48209",
      "NAME": "Hays",
      "LSAD": "06",
      "ALAND": 1755978743,
      "AWATER": 5037781,
      "County_state": "Hays,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.172977,
              30.356312
            ],
            [
              -98.2976,
              30.037994
            ],
            [
              -98.12127,
              29.912844
            ],
            [
              -98.030523,
              29.848539
            ],
            [
              -98.018657,
              29.812175
            ],
            [
              -98.01518,
              29.801485
            ],
            [
              -97.999271,
              29.752444
            ],
            [
              -97.943564,
              29.806914
            ],
            [
              -97.875259,
              29.858208
            ],
            [
              -97.899238,
              29.857583
            ],
            [
              -97.893896,
              29.883734
            ],
            [
              -97.849178,
              29.916
            ],
            [
              -97.792593,
              29.945057
            ],
            [
              -97.761332,
              29.987319
            ],
            [
              -97.712548,
              30.020613
            ],
            [
              -97.710215,
              30.024499
            ],
            [
              -97.751479,
              30.054354
            ],
            [
              -97.781369,
              30.07567
            ],
            [
              -97.813018,
              30.098613
            ],
            [
              -97.857161,
              30.131196
            ],
            [
              -97.857222,
              30.13124
            ],
            [
              -97.969007,
              30.210884
            ],
            [
              -97.989016,
              30.226792
            ],
            [
              -98.172977,
              30.356312
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "409",
      "COUNTYNS": "01383990",
      "AFFGEOID": "0500000US48409",
      "GEOID": "48409",
      "NAME": "San Patricio",
      "LSAD": "06",
      "ALAND": 1795990945,
      "AWATER": 37577388,
      "County_state": "San Patricio,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.898116,
              28.099446
            ],
            [
              -97.908774,
              28.091866
            ],
            [
              -97.895441,
              28.075869
            ],
            [
              -97.901222,
              28.067837
            ],
            [
              -97.883148,
              28.0569
            ],
            [
              -97.867548,
              28.040986
            ],
            [
              -97.838918,
              28.034613
            ],
            [
              -97.801388,
              28.03836
            ],
            [
              -97.789154,
              28.013589
            ],
            [
              -97.798517,
              27.995659
            ],
            [
              -97.802797,
              27.979393
            ],
            [
              -97.817391,
              27.969244
            ],
            [
              -97.809839,
              27.957764
            ],
            [
              -97.806798,
              27.934712
            ],
            [
              -97.780678,
              27.947669
            ],
            [
              -97.775603,
              27.937733
            ],
            [
              -97.764303,
              27.938127
            ],
            [
              -97.760931,
              27.922644
            ],
            [
              -97.748994,
              27.932856
            ],
            [
              -97.723938,
              27.916297
            ],
            [
              -97.70821,
              27.922584
            ],
            [
              -97.691074,
              27.917353
            ],
            [
              -97.691505,
              27.903888
            ],
            [
              -97.671505,
              27.893033
            ],
            [
              -97.67528,
              27.882538
            ],
            [
              -97.638174,
              27.865594
            ],
            [
              -97.626033,
              27.881403
            ],
            [
              -97.632113,
              27.893385
            ],
            [
              -97.609593,
              27.890377
            ],
            [
              -97.610388,
              27.875991
            ],
            [
              -97.589473,
              27.856101
            ],
            [
              -97.573135,
              27.860989
            ],
            [
              -97.542622,
              27.858315
            ],
            [
              -97.53531,
              27.847677
            ],
            [
              -97.50432199301339,
              27.844405006351398
            ],
            [
              -97.515095,
              27.85117
            ],
            [
              -97.514737,
              27.870073
            ],
            [
              -97.493239,
              27.884076
            ],
            [
              -97.454293,
              27.873824
            ],
            [
              -97.377952,
              27.874905
            ],
            [
              -97.341,
              27.883336
            ],
            [
              -97.325097,
              27.867893
            ],
            [
              -97.295072,
              27.878071
            ],
            [
              -97.273698,
              27.881633
            ],
            [
              -97.26301,
              27.880106
            ],
            [
              -97.250797,
              27.876035
            ],
            [
              -97.242654,
              27.864839
            ],
            [
              -97.241127,
              27.857714
            ],
            [
              -97.234512,
              27.849063
            ],
            [
              -97.228388,
              27.843661
            ],
            [
              -97.226514,
              27.838307
            ],
            [
              -97.227317,
              27.832952
            ],
            [
              -97.227317,
              27.829204
            ],
            [
              -97.225176,
              27.825723
            ],
            [
              -97.220771,
              27.824126
            ],
            [
              -97.209575,
              27.822091
            ],
            [
              -97.196852,
              27.822091
            ],
            [
              -97.187183,
              27.824126
            ],
            [
              -97.184639,
              27.831251
            ],
            [
              -97.155122,
              27.880615
            ],
            [
              -97.144435,
              27.894356
            ],
            [
              -97.135783,
              27.899445
            ],
            [
              -97.13480101929359,
              27.9024667988298
            ],
            [
              -97.260818,
              28.075759
            ],
            [
              -97.264272,
              28.088884
            ],
            [
              -97.283984,
              28.091331
            ],
            [
              -97.287259,
              28.110734
            ],
            [
              -97.303816,
              28.104005
            ],
            [
              -97.318153,
              28.112909
            ],
            [
              -97.309037,
              28.120017
            ],
            [
              -97.317163,
              28.137189
            ],
            [
              -97.324958,
              28.119746
            ],
            [
              -97.325314,
              28.126584
            ],
            [
              -97.34326,
              28.13019
            ],
            [
              -97.343902,
              28.120633
            ],
            [
              -97.355886,
              28.123142
            ],
            [
              -97.379803,
              28.137386
            ],
            [
              -97.384833,
              28.123951
            ],
            [
              -97.403304,
              28.130711
            ],
            [
              -97.399799,
              28.119953
            ],
            [
              -97.427171,
              28.117668
            ],
            [
              -97.422141,
              28.141749
            ],
            [
              -97.438847,
              28.13897
            ],
            [
              -97.459549,
              28.160045
            ],
            [
              -97.470076,
              28.154387
            ],
            [
              -97.476366,
              28.179461
            ],
            [
              -97.482384,
              28.168616
            ],
            [
              -97.495091,
              28.1659
            ],
            [
              -97.521211,
              28.151341
            ],
            [
              -97.541071,
              28.164669
            ],
            [
              -97.567622,
              28.129683
            ],
            [
              -97.568482,
              28.129846
            ],
            [
              -97.817716,
              28.176848
            ],
            [
              -97.904081,
              28.114228
            ],
            [
              -97.898116,
              28.099446
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "359",
      "COUNTYNS": "01383965",
      "AFFGEOID": "0500000US48359",
      "GEOID": "48359",
      "NAME": "Oldham",
      "LSAD": "06",
      "ALAND": 3886337121,
      "AWATER": 2339716,
      "County_state": "Oldham,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.042775,
              35.241237
            ],
            [
              -103.042497,
              35.211862
            ],
            [
              -103.042377029193,
              35.1831559850696
            ],
            [
              -102.167471,
              35.183233
            ],
            [
              -102.162752,
              35.620035
            ],
            [
              -102.162809,
              35.627515
            ],
            [
              -103.041553998475,
              35.6224876319576
            ],
            [
              -103.041554,
              35.622487
            ],
            [
              -103.042366,
              35.250056
            ],
            [
              -103.042775,
              35.241237
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "371",
      "COUNTYNS": "01383971",
      "AFFGEOID": "0500000US48371",
      "GEOID": "48371",
      "NAME": "Pecos",
      "LSAD": "06",
      "ALAND": 12338274939,
      "AWATER": 2532275,
      "County_state": "Pecos,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.585084,
              30.76647
            ],
            [
              -103.439976,
              30.665939
            ],
            [
              -103.334688,
              30.592561
            ],
            [
              -102.567049,
              30.052808
            ],
            [
              -102.566937,
              30.283269
            ],
            [
              -102.343085,
              30.284116
            ],
            [
              -102.342986,
              30.598759
            ],
            [
              -102.138406,
              30.597521
            ],
            [
              -102.137778,
              30.655982
            ],
            [
              -102.000408,
              30.654933
            ],
            [
              -101.76842,
              30.653077
            ],
            [
              -101.775803,
              30.680894
            ],
            [
              -101.80742,
              30.699504
            ],
            [
              -101.807952,
              30.725266
            ],
            [
              -101.815828,
              30.752015
            ],
            [
              -101.834528,
              30.757949
            ],
            [
              -101.839894,
              30.785281
            ],
            [
              -101.831469,
              30.80173
            ],
            [
              -101.847209,
              30.828414
            ],
            [
              -101.845094,
              30.841032
            ],
            [
              -101.860053,
              30.873615
            ],
            [
              -101.89118,
              30.890743
            ],
            [
              -101.874276,
              30.912236
            ],
            [
              -101.883436,
              30.925647
            ],
            [
              -101.908196,
              30.928245
            ],
            [
              -101.919419,
              30.935145
            ],
            [
              -101.921017,
              30.946405
            ],
            [
              -101.951886,
              30.955676
            ],
            [
              -101.961123,
              30.970709
            ],
            [
              -101.98364,
              30.98717
            ],
            [
              -101.993026,
              30.989337
            ],
            [
              -102.011667,
              30.979338
            ],
            [
              -102.032599,
              30.996864
            ],
            [
              -102.049179,
              31.00364
            ],
            [
              -102.081729,
              30.986691
            ],
            [
              -102.09716,
              30.9956
            ],
            [
              -102.1099,
              30.990321
            ],
            [
              -102.127756,
              31.001183
            ],
            [
              -102.139397,
              30.994925
            ],
            [
              -102.15193,
              31.007302
            ],
            [
              -102.170389,
              31.006077
            ],
            [
              -102.185755,
              31.01514
            ],
            [
              -102.191731,
              31.003788
            ],
            [
              -102.20146,
              31.03052
            ],
            [
              -102.232035,
              31.035469
            ],
            [
              -102.24657,
              31.042053
            ],
            [
              -102.265961,
              31.034274
            ],
            [
              -102.288937,
              31.035768
            ],
            [
              -102.320801,
              31.061851
            ],
            [
              -102.356405,
              31.074445
            ],
            [
              -102.370932,
              31.085099
            ],
            [
              -102.388804,
              31.087156
            ],
            [
              -102.406153,
              31.08787
            ],
            [
              -102.420143,
              31.118208
            ],
            [
              -102.430127,
              31.129058
            ],
            [
              -102.422872,
              31.149248
            ],
            [
              -102.429545,
              31.193557
            ],
            [
              -102.43992,
              31.209929
            ],
            [
              -102.450595,
              31.213329
            ],
            [
              -102.484027,
              31.237528
            ],
            [
              -102.502299,
              31.252975
            ],
            [
              -102.523063,
              31.262698
            ],
            [
              -102.579067,
              31.271525
            ],
            [
              -102.583891,
              31.26288
            ],
            [
              -102.600677,
              31.267097
            ],
            [
              -102.616377,
              31.289386
            ],
            [
              -102.628955,
              31.291026
            ],
            [
              -102.641008,
              31.309682
            ],
            [
              -102.66198,
              31.315902
            ],
            [
              -102.676104,
              31.330084
            ],
            [
              -102.708332,
              31.31113
            ],
            [
              -102.724875,
              31.308418
            ],
            [
              -102.729976,
              31.287703
            ],
            [
              -102.753248,
              31.279151
            ],
            [
              -102.767365,
              31.293803
            ],
            [
              -102.802115,
              31.281113
            ],
            [
              -102.826735,
              31.266881
            ],
            [
              -102.838849,
              31.285834
            ],
            [
              -102.856346,
              31.290915
            ],
            [
              -102.877926,
              31.307024
            ],
            [
              -102.875387,
              31.316292
            ],
            [
              -102.895667,
              31.322069
            ],
            [
              -102.913983,
              31.339118
            ],
            [
              -102.930357,
              31.340064
            ],
            [
              -102.933578,
              31.350081
            ],
            [
              -102.965675,
              31.351309
            ],
            [
              -102.998868,
              31.35908
            ],
            [
              -103.01104,
              31.371306
            ],
            [
              -103.375412,
              30.987246
            ],
            [
              -103.585084,
              30.76647
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "385",
      "COUNTYNS": "01383978",
      "AFFGEOID": "0500000US48385",
      "GEOID": "48385",
      "NAME": "Real",
      "LSAD": "06",
      "ALAND": 1810812906,
      "AWATER": 2346258,
      "County_state": "Real,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.063741,
              29.71106
            ],
            [
              -100.046268,
              29.706487
            ],
            [
              -100.045791,
              29.694924
            ],
            [
              -100.016954,
              29.676823
            ],
            [
              -100.030303,
              29.663256
            ],
            [
              -100.007275,
              29.632018
            ],
            [
              -100.014188,
              29.623495
            ],
            [
              -99.60313,
              29.627181
            ],
            [
              -99.602776,
              29.907679
            ],
            [
              -99.691215,
              29.908582
            ],
            [
              -99.689879,
              30.073948
            ],
            [
              -99.757621,
              30.074132
            ],
            [
              -99.917482,
              30.078597
            ],
            [
              -99.967626,
              30.082356
            ],
            [
              -99.973046,
              30.024591
            ],
            [
              -99.989672,
              30.025747
            ],
            [
              -100.002302,
              29.896828
            ],
            [
              -100.023413,
              29.883134
            ],
            [
              -100.019556,
              29.869589
            ],
            [
              -100.024032,
              29.849204
            ],
            [
              -100.03088,
              29.848273
            ],
            [
              -100.027266,
              29.82436
            ],
            [
              -100.017075,
              29.812007
            ],
            [
              -100.013291,
              29.794339
            ],
            [
              -100.028976,
              29.788993
            ],
            [
              -100.035958,
              29.77937
            ],
            [
              -100.021997,
              29.745766
            ],
            [
              -100.029605,
              29.73647
            ],
            [
              -100.02637,
              29.723184
            ],
            [
              -100.063741,
              29.71106
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "113",
      "COUNTYNS": "01383842",
      "AFFGEOID": "0500000US48113",
      "GEOID": "48113",
      "NAME": "Dallas",
      "LSAD": "06",
      "ALAND": 2258737472,
      "AWATER": 94576756,
      "County_state": "Dallas,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.037063,
              32.645873
            ],
            [
              -97.038385,
              32.548662
            ],
            [
              -97.01361,
              32.5483
            ],
            [
              -96.529987,
              32.545282
            ],
            [
              -96.523118,
              32.545214
            ],
            [
              -96.519261,
              32.796028
            ],
            [
              -96.51897,
              32.813616
            ],
            [
              -96.518402,
              32.876468
            ],
            [
              -96.517912,
              32.91723
            ],
            [
              -96.516999,
              32.973669
            ],
            [
              -96.516866,
              32.982308
            ],
            [
              -96.583842,
              32.983608
            ],
            [
              -96.613377,
              32.984021
            ],
            [
              -96.682467,
              32.985325
            ],
            [
              -96.748422,
              32.986271
            ],
            [
              -96.767999,
              32.986534
            ],
            [
              -96.768152,
              32.986535
            ],
            [
              -96.803375,
              32.987003
            ],
            [
              -96.838963,
              32.987521
            ],
            [
              -96.843979,
              32.987554
            ],
            [
              -96.958665,
              32.989013
            ],
            [
              -96.980069,
              32.989203
            ],
            [
              -96.990214,
              32.989287
            ],
            [
              -97.010696,
              32.98932
            ],
            [
              -97.032287,
              32.989324
            ],
            [
              -97.033088,
              32.878493
            ],
            [
              -97.033527,
              32.852752
            ],
            [
              -97.033781,
              32.837135
            ],
            [
              -97.034085,
              32.81666
            ],
            [
              -97.03601,
              32.805161
            ],
            [
              -97.03479,
              32.786041
            ],
            [
              -97.035171,
              32.759693
            ],
            [
              -97.035167,
              32.759368
            ],
            [
              -97.036207,
              32.711113
            ],
            [
              -97.036417,
              32.693188
            ],
            [
              -97.036675,
              32.676205
            ],
            [
              -97.036686,
              32.675913
            ],
            [
              -97.037063,
              32.645873
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "355",
      "COUNTYNS": "01383963",
      "AFFGEOID": "0500000US48355",
      "GEOID": "48355",
      "NAME": "Nueces",
      "LSAD": "06",
      "ALAND": 2171223720,
      "AWATER": 847262030,
      "County_state": "Nueces,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.262222,
              27.5876
            ],
            [
              -97.2683119237207,
              27.575967973304397
            ],
            [
              -97.242122,
              27.582924
            ],
            [
              -97.2229914075318,
              27.5766073164796
            ],
            [
              -97.212679,
              27.59642
            ],
            [
              -97.140854,
              27.716688
            ],
            [
              -97.090735,
              27.785888
            ],
            [
              -97.044846,
              27.834466
            ],
            [
              -97.0436840836865,
              27.8365323534718
            ],
            [
              -97.065254,
              27.851853
            ],
            [
              -97.057171,
              27.865413
            ],
            [
              -97.0781009814495,
              27.9154123808701
            ],
            [
              -97.082481,
              27.913232
            ],
            [
              -97.102802,
              27.879097
            ],
            [
              -97.129898,
              27.839487
            ],
            [
              -97.131342,
              27.822657
            ],
            [
              -97.120885,
              27.798691
            ],
            [
              -97.151189,
              27.768621
            ],
            [
              -97.18696,
              27.694252
            ],
            [
              -97.235209,
              27.634468
            ],
            [
              -97.23555044211179,
              27.6338755923111
            ],
            [
              -97.262222,
              27.5876
            ]
          ]
        ],
        [
          [
            [
              -97.933665,
              27.776442
            ],
            [
              -97.942146,
              27.635932
            ],
            [
              -97.932377,
              27.635929
            ],
            [
              -97.920991,
              27.62291
            ],
            [
              -97.88859,
              27.620249
            ],
            [
              -97.877193,
              27.60898
            ],
            [
              -97.873867,
              27.587858
            ],
            [
              -97.85803,
              27.580704
            ],
            [
              -97.838883,
              27.563178
            ],
            [
              -97.840536,
              27.558473
            ],
            [
              -97.572961,
              27.560343
            ],
            [
              -97.32511825033188,
              27.5609268748918
            ],
            [
              -97.321535,
              27.571199
            ],
            [
              -97.302196,
              27.585957
            ],
            [
              -97.294054,
              27.5941
            ],
            [
              -97.297616,
              27.59868
            ],
            [
              -97.298634,
              27.604787
            ],
            [
              -97.296598,
              27.613947
            ],
            [
              -97.266064,
              27.678579
            ],
            [
              -97.259957,
              27.679597
            ],
            [
              -97.253955,
              27.696696
            ],
            [
              -97.316446,
              27.712676
            ],
            [
              -97.34698,
              27.725398
            ],
            [
              -97.368355,
              27.741683
            ],
            [
              -97.386166,
              27.76662
            ],
            [
              -97.393291,
              27.782905
            ],
            [
              -97.39308948780179,
              27.787001622901002
            ],
            [
              -97.39242417076709,
              27.8005271217267
            ],
            [
              -97.391764,
              27.813948
            ],
            [
              -97.379042,
              27.837867
            ],
            [
              -97.41919,
              27.822998
            ],
            [
              -97.472073,
              27.824154
            ],
            [
              -97.50432199301339,
              27.844405006351398
            ],
            [
              -97.53531,
              27.847677
            ],
            [
              -97.542622,
              27.858315
            ],
            [
              -97.573135,
              27.860989
            ],
            [
              -97.589473,
              27.856101
            ],
            [
              -97.610388,
              27.875991
            ],
            [
              -97.609593,
              27.890377
            ],
            [
              -97.632113,
              27.893385
            ],
            [
              -97.626033,
              27.881403
            ],
            [
              -97.638174,
              27.865594
            ],
            [
              -97.67528,
              27.882538
            ],
            [
              -97.671505,
              27.893033
            ],
            [
              -97.691505,
              27.903888
            ],
            [
              -97.691074,
              27.917353
            ],
            [
              -97.70821,
              27.922584
            ],
            [
              -97.723938,
              27.916297
            ],
            [
              -97.748994,
              27.932856
            ],
            [
              -97.760931,
              27.922644
            ],
            [
              -97.764303,
              27.938127
            ],
            [
              -97.775603,
              27.937733
            ],
            [
              -97.780678,
              27.947669
            ],
            [
              -97.806798,
              27.934712
            ],
            [
              -97.809839,
              27.957764
            ],
            [
              -97.817391,
              27.969244
            ],
            [
              -97.802797,
              27.979393
            ],
            [
              -97.798517,
              27.995659
            ],
            [
              -97.934274,
              27.885202
            ],
            [
              -97.933665,
              27.776442
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "387",
      "COUNTYNS": "01383979",
      "AFFGEOID": "0500000US48387",
      "GEOID": "48387",
      "NAME": "Red River",
      "LSAD": "06",
      "ALAND": 2703626759,
      "AWATER": 33305608,
      "County_state": "Red River,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.30945815240901,
              33.8803039268509
            ],
            [
              -95.310437,
              33.875027
            ],
            [
              -95.308637,
              33.380662
            ],
            [
              -95.30664,
              33.378027
            ],
            [
              -95.300098,
              33.382426
            ],
            [
              -95.271376,
              33.376643
            ],
            [
              -95.25613,
              33.367686
            ],
            [
              -95.227741,
              33.371888
            ],
            [
              -95.201067,
              33.38095
            ],
            [
              -95.196868,
              33.373838
            ],
            [
              -95.166183,
              33.385484
            ],
            [
              -95.156004,
              33.380077
            ],
            [
              -95.125451,
              33.389454
            ],
            [
              -95.093348,
              33.397571
            ],
            [
              -95.074208,
              33.385894
            ],
            [
              -95.057614,
              33.391576
            ],
            [
              -95.049583,
              33.382983
            ],
            [
              -95.024605,
              33.384127
            ],
            [
              -95.017127,
              33.390949
            ],
            [
              -94.979317,
              33.387099
            ],
            [
              -94.953056,
              33.390839
            ],
            [
              -94.908777,
              33.383785
            ],
            [
              -94.88977,
              33.395023
            ],
            [
              -94.875984,
              33.394193
            ],
            [
              -94.857586,
              33.3767
            ],
            [
              -94.830154,
              33.382776
            ],
            [
              -94.81271,
              33.375577
            ],
            [
              -94.808783,
              33.363636
            ],
            [
              -94.800813,
              33.365354
            ],
            [
              -94.777214,
              33.331637
            ],
            [
              -94.781447,
              33.318722
            ],
            [
              -94.746925,
              33.328938
            ],
            [
              -94.746096,
              33.703016
            ],
            [
              -94.7376180019138,
              33.7060079583273
            ],
            [
              -94.739072,
              33.710128
            ],
            [
              -94.73748,
              33.716179
            ],
            [
              -94.739391,
              33.72255
            ],
            [
              -94.742576,
              33.727009
            ],
            [
              -94.753087,
              33.729557
            ],
            [
              -94.759139,
              33.729557
            ],
            [
              -94.762961,
              33.731787
            ],
            [
              -94.767739,
              33.73752
            ],
            [
              -94.768057,
              33.742616
            ],
            [
              -94.766146,
              33.748031
            ],
            [
              -94.766465,
              33.750897
            ],
            [
              -94.768057,
              33.753446
            ],
            [
              -94.770924,
              33.754401
            ],
            [
              -94.775064,
              33.755038
            ],
            [
              -94.789716,
              33.74612
            ],
            [
              -94.798634,
              33.744527
            ],
            [
              -94.809145,
              33.749305
            ],
            [
              -94.812012,
              33.751853
            ],
            [
              -94.817427,
              33.752172
            ],
            [
              -94.821886,
              33.750897
            ],
            [
              -94.824753,
              33.749305
            ],
            [
              -94.826027,
              33.74389
            ],
            [
              -94.827938,
              33.741342
            ],
            [
              -94.830804,
              33.740068
            ],
            [
              -94.841634,
              33.739431
            ],
            [
              -94.849296,
              33.739585
            ],
            [
              -94.8693,
              33.745871
            ],
            [
              -94.874668,
              33.749164
            ],
            [
              -94.87708,
              33.75222
            ],
            [
              -94.875497,
              33.755483
            ],
            [
              -94.876033,
              33.760771
            ],
            [
              -94.879218,
              33.764912
            ],
            [
              -94.881448,
              33.765549
            ],
            [
              -94.886226,
              33.764594
            ],
            [
              -94.888368,
              33.76724
            ],
            [
              -94.902276,
              33.776289
            ],
            [
              -94.906245,
              33.778192
            ],
            [
              -94.911427,
              33.778383
            ],
            [
              -94.919614,
              33.786305
            ],
            [
              -94.920104,
              33.789575
            ],
            [
              -94.919614,
              33.794153
            ],
            [
              -94.916998,
              33.80151
            ],
            [
              -94.916834,
              33.804617
            ],
            [
              -94.917815,
              33.808704
            ],
            [
              -94.91945,
              33.810176
            ],
            [
              -94.921902,
              33.811811
            ],
            [
              -94.924518,
              33.812792
            ],
            [
              -94.928442,
              33.812628
            ],
            [
              -94.932366,
              33.810993
            ],
            [
              -94.9358,
              33.810339
            ],
            [
              -94.93956,
              33.810503
            ],
            [
              -94.944302,
              33.812138
            ],
            [
              -94.948716,
              33.818023
            ],
            [
              -94.949533,
              33.825708
            ],
            [
              -94.957676,
              33.835004
            ],
            [
              -94.964401,
              33.837021
            ],
            [
              -94.965888,
              33.848422
            ],
            [
              -94.968895,
              33.860916
            ],
            [
              -94.971435,
              33.862123
            ],
            [
              -94.973411,
              33.861731
            ],
            [
              -94.976208,
              33.859847
            ],
            [
              -94.98165,
              33.852284
            ],
            [
              -94.983303,
              33.851354
            ],
            [
              -94.988487,
              33.851
            ],
            [
              -94.992671,
              33.852455
            ],
            [
              -94.995524,
              33.857438
            ],
            [
              -95.000223,
              33.862505
            ],
            [
              -95.008376,
              33.866089
            ],
            [
              -95.016422,
              33.861392
            ],
            [
              -95.022325,
              33.859813
            ],
            [
              -95.037207,
              33.86025
            ],
            [
              -95.046568,
              33.862565
            ],
            [
              -95.049025,
              33.86409
            ],
            [
              -95.058834,
              33.886813
            ],
            [
              -95.061065,
              33.895292
            ],
            [
              -95.065492,
              33.899585
            ],
            [
              -95.07126,
              33.901597
            ],
            [
              -95.078905,
              33.898377
            ],
            [
              -95.084002,
              33.89328
            ],
            [
              -95.090441,
              33.89328
            ],
            [
              -95.093929,
              33.895963
            ],
            [
              -95.09527,
              33.899316
            ],
            [
              -95.095002,
              33.904816
            ],
            [
              -95.098489,
              33.909913
            ],
            [
              -95.10077,
              33.912193
            ],
            [
              -95.103318,
              33.913669
            ],
            [
              -95.110964,
              33.912998
            ],
            [
              -95.119951,
              33.915815
            ],
            [
              -95.122365,
              33.918632
            ],
            [
              -95.1225,
              33.921717
            ],
            [
              -95.121184,
              33.931307
            ],
            [
              -95.1247,
              33.934675
            ],
            [
              -95.131056,
              33.936925
            ],
            [
              -95.149462,
              33.936336
            ],
            [
              -95.1558884157265,
              33.9370323283804
            ],
            [
              -95.161109,
              33.937598
            ],
            [
              -95.166686,
              33.939728
            ],
            [
              -95.168746,
              33.941606
            ],
            [
              -95.184075,
              33.950353
            ],
            [
              -95.219358,
              33.961567
            ],
            [
              -95.226393,
              33.961954
            ],
            [
              -95.230491,
              33.960764
            ],
            [
              -95.252906,
              33.933648
            ],
            [
              -95.253623,
              33.92971
            ],
            [
              -95.25302,
              33.927237
            ],
            [
              -95.251327,
              33.924155
            ],
            [
              -95.250737,
              33.917083
            ],
            [
              -95.250885,
              33.913105
            ],
            [
              -95.253095,
              33.905444
            ],
            [
              -95.255747,
              33.902939
            ],
            [
              -95.261051,
              33.899993
            ],
            [
              -95.26385,
              33.899256
            ],
            [
              -95.272542,
              33.902055
            ],
            [
              -95.275342,
              33.901761
            ],
            [
              -95.277846,
              33.900877
            ],
            [
              -95.279762,
              33.899109
            ],
            [
              -95.280351,
              33.896751
            ],
            [
              -95.28153,
              33.887617
            ],
            [
              -95.281677,
              33.882902
            ],
            [
              -95.283445,
              33.877746
            ],
            [
              -95.287865,
              33.874946
            ],
            [
              -95.294789,
              33.875388
            ],
            [
              -95.30945815240901,
              33.8803039268509
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "083",
      "COUNTYNS": "01383827",
      "AFFGEOID": "0500000US48083",
      "GEOID": "48083",
      "NAME": "Coleman",
      "LSAD": "06",
      "ALAND": 3268424411,
      "AWATER": 50472148,
      "County_state": "Coleman,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.721698,
              31.576759
            ],
            [
              -99.722063,
              31.562761
            ],
            [
              -99.710758,
              31.561247
            ],
            [
              -99.70573,
              31.576899
            ],
            [
              -99.694673,
              31.558385
            ],
            [
              -99.673424,
              31.555291
            ],
            [
              -99.673186,
              31.547104
            ],
            [
              -99.692985,
              31.538242
            ],
            [
              -99.690255,
              31.532032
            ],
            [
              -99.658791,
              31.534779
            ],
            [
              -99.652559,
              31.527467
            ],
            [
              -99.670487,
              31.510926
            ],
            [
              -99.676358,
              31.497263
            ],
            [
              -99.661979,
              31.498547
            ],
            [
              -99.646604,
              31.491691
            ],
            [
              -99.62492,
              31.467442
            ],
            [
              -99.610833,
              31.471307
            ],
            [
              -99.608188,
              31.480809
            ],
            [
              -99.624685,
              31.493502
            ],
            [
              -99.61429,
              31.501564
            ],
            [
              -99.60185,
              31.49195
            ],
            [
              -99.572559,
              31.49365
            ],
            [
              -99.537215,
              31.483616
            ],
            [
              -99.529716,
              31.460574
            ],
            [
              -99.511477,
              31.424401
            ],
            [
              -99.493,
              31.444513
            ],
            [
              -99.470548,
              31.421985
            ],
            [
              -99.469437,
              31.430131
            ],
            [
              -99.436896,
              31.446032
            ],
            [
              -99.446342,
              31.450493
            ],
            [
              -99.443854,
              31.4751
            ],
            [
              -99.416887,
              31.461391
            ],
            [
              -99.398793,
              31.460083
            ],
            [
              -99.402284,
              31.446198
            ],
            [
              -99.367029,
              31.437872
            ],
            [
              -99.357214,
              31.430853
            ],
            [
              -99.346422,
              31.443109
            ],
            [
              -99.347155,
              31.424992
            ],
            [
              -99.314875,
              31.409875
            ],
            [
              -99.302605,
              31.432504
            ],
            [
              -99.289131,
              31.4285
            ],
            [
              -99.289954,
              31.441447
            ],
            [
              -99.271365,
              31.446881
            ],
            [
              -99.272381,
              31.454557
            ],
            [
              -99.238447,
              31.483127
            ],
            [
              -99.202768,
              31.467234
            ],
            [
              -99.195871,
              32.079235
            ],
            [
              -99.631413,
              32.081274
            ],
            [
              -99.713971,
              32.082089
            ],
            [
              -99.721698,
              31.576759
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "401",
      "COUNTYNS": "01383986",
      "AFFGEOID": "0500000US48401",
      "GEOID": "48401",
      "NAME": "Rusk",
      "LSAD": "06",
      "ALAND": 2393244478,
      "AWATER": 37217197,
      "County_state": "Rusk,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.985411,
              32.37082
            ],
            [
              -94.985272,
              32.137989
            ],
            [
              -94.984768,
              31.846144
            ],
            [
              -94.937532,
              31.845555
            ],
            [
              -94.452416,
              31.844281
            ],
            [
              -94.462597,
              31.85441
            ],
            [
              -94.46678,
              31.878991
            ],
            [
              -94.480709,
              31.902113
            ],
            [
              -94.511137,
              31.923879
            ],
            [
              -94.511431,
              31.973984
            ],
            [
              -94.599978,
              31.973193
            ],
            [
              -94.60156,
              32.079971
            ],
            [
              -94.595006,
              32.111392
            ],
            [
              -94.580747,
              32.159269
            ],
            [
              -94.580256,
              32.202545
            ],
            [
              -94.557002,
              32.249053
            ],
            [
              -94.530714,
              32.271842
            ],
            [
              -94.519589,
              32.305558
            ],
            [
              -94.493361,
              32.356492
            ],
            [
              -94.491818,
              32.394137
            ],
            [
              -94.518038,
              32.388124
            ],
            [
              -94.525511,
              32.401871
            ],
            [
              -94.554971,
              32.407603
            ],
            [
              -94.579219,
              32.394916
            ],
            [
              -94.591703,
              32.365572
            ],
            [
              -94.600776,
              32.360044
            ],
            [
              -94.638347,
              32.370539
            ],
            [
              -94.896028,
              32.369609
            ],
            [
              -94.985411,
              32.37082
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "443",
      "COUNTYNS": "01384007",
      "AFFGEOID": "0500000US48443",
      "GEOID": "48443",
      "NAME": "Terrell",
      "LSAD": "06",
      "ALAND": 6107235409,
      "AWATER": 105866,
      "County_state": "Terrell,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.566937,
              30.283269
            ],
            [
              -102.567049,
              30.052808
            ],
            [
              -102.320692921451,
              29.8788571412597
            ],
            [
              -102.320619,
              29.87898
            ],
            [
              -102.315389,
              29.87992
            ],
            [
              -102.301381,
              29.877674
            ],
            [
              -102.297331,
              29.875194
            ],
            [
              -102.281249,
              29.863117
            ],
            [
              -102.276755,
              29.862978
            ],
            [
              -102.268817,
              29.867991
            ],
            [
              -102.264954,
              29.867806
            ],
            [
              -102.261777,
              29.864002
            ],
            [
              -102.264041,
              29.855964
            ],
            [
              -102.261389,
              29.853283
            ],
            [
              -102.227553,
              29.843534
            ],
            [
              -102.205381,
              29.842438
            ],
            [
              -102.188672,
              29.848943
            ],
            [
              -102.18615,
              29.848462
            ],
            [
              -102.181894,
              29.846034
            ],
            [
              -102.161674,
              29.819487
            ],
            [
              -102.159601,
              29.814356
            ],
            [
              -102.142326,
              29.802854
            ],
            [
              -102.115682,
              29.79239
            ],
            [
              -102.091816,
              29.792854
            ],
            [
              -102.084439,
              29.794962
            ],
            [
              -102.077348,
              29.792421
            ],
            [
              -102.073646,
              29.786926
            ],
            [
              -102.050044,
              29.78507
            ],
            [
              -102.039227,
              29.790977
            ],
            [
              -102.038412,
              29.792832
            ],
            [
              -102.041088,
              29.79961
            ],
            [
              -102.039013,
              29.802655
            ],
            [
              -102.034759,
              29.804028
            ],
            [
              -102.021919,
              29.802491
            ],
            [
              -102.001786,
              29.804828
            ],
            [
              -101.987539,
              29.801057
            ],
            [
              -101.974548,
              29.810276
            ],
            [
              -101.970357,
              29.810276
            ],
            [
              -101.966167,
              29.807343
            ],
            [
              -101.959462,
              29.799381
            ],
            [
              -101.953595,
              29.796866
            ],
            [
              -101.946471,
              29.797704
            ],
            [
              -101.93809,
              29.796028
            ],
            [
              -101.929709,
              29.789323
            ],
            [
              -101.922585,
              29.790161
            ],
            [
              -101.917557,
              29.792676
            ],
            [
              -101.912406,
              29.79785
            ],
            [
              -101.892739,
              29.797891
            ],
            [
              -101.8754,
              29.794023
            ],
            [
              -101.862242,
              29.800219
            ],
            [
              -101.852604,
              29.801895
            ],
            [
              -101.831232,
              29.789323
            ],
            [
              -101.814889,
              29.793095
            ],
            [
              -101.809441,
              29.790161
            ],
            [
              -101.806508,
              29.78639
            ],
            [
              -101.79687,
              29.782619
            ],
            [
              -101.791003,
              29.783038
            ],
            [
              -101.785668,
              29.788252
            ],
            [
              -101.777161,
              29.789327
            ],
            [
              -101.763274,
              29.78417
            ],
            [
              -101.76091960021701,
              29.7824581874889
            ],
            [
              -101.7584,
              30.288044
            ],
            [
              -101.744509,
              30.301277
            ],
            [
              -101.736987,
              30.326975
            ],
            [
              -101.700606,
              30.345928
            ],
            [
              -101.669557,
              30.339355
            ],
            [
              -101.658886,
              30.342622
            ],
            [
              -101.65853,
              30.357861
            ],
            [
              -101.685271,
              30.369532
            ],
            [
              -101.697417,
              30.380493
            ],
            [
              -101.694458,
              30.393294
            ],
            [
              -101.701627,
              30.402241
            ],
            [
              -101.728732,
              30.408293
            ],
            [
              -101.740552,
              30.421321
            ],
            [
              -101.720323,
              30.442225
            ],
            [
              -101.719249,
              30.455138
            ],
            [
              -101.730972,
              30.459452
            ],
            [
              -101.721896,
              30.471016
            ],
            [
              -101.700101,
              30.457599
            ],
            [
              -101.688867,
              30.46762
            ],
            [
              -101.691825,
              30.491418
            ],
            [
              -101.675103,
              30.502136
            ],
            [
              -101.679959,
              30.521795
            ],
            [
              -101.667598,
              30.528024
            ],
            [
              -101.646245,
              30.560763
            ],
            [
              -101.66353,
              30.588858
            ],
            [
              -101.652446,
              30.620471
            ],
            [
              -101.669833,
              30.637907
            ],
            [
              -101.689683,
              30.639047
            ],
            [
              -101.712065,
              30.654643
            ],
            [
              -101.750433,
              30.649691
            ],
            [
              -101.756224,
              30.657394
            ],
            [
              -101.76842,
              30.653077
            ],
            [
              -102.000408,
              30.654933
            ],
            [
              -102.137778,
              30.655982
            ],
            [
              -102.138406,
              30.597521
            ],
            [
              -102.342986,
              30.598759
            ],
            [
              -102.343085,
              30.284116
            ],
            [
              -102.566937,
              30.283269
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "457",
      "COUNTYNS": "01384014",
      "AFFGEOID": "0500000US48457",
      "GEOID": "48457",
      "NAME": "Tyler",
      "LSAD": "06",
      "ALAND": 2394430926,
      "AWATER": 28821288,
      "County_state": "Tyler,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.561943,
              31.058952
            ],
            [
              -94.657992,
              31.012007
            ],
            [
              -94.545717,
              30.526977
            ],
            [
              -94.071689,
              30.526033
            ],
            [
              -94.06904,
              30.527436
            ],
            [
              -94.067803,
              30.530484
            ],
            [
              -94.072685,
              30.53428
            ],
            [
              -94.072628,
              30.542923
            ],
            [
              -94.078214,
              30.547179
            ],
            [
              -94.082251,
              30.546451
            ],
            [
              -94.086072,
              30.55288
            ],
            [
              -94.087184,
              30.557591
            ],
            [
              -94.090472,
              30.563553
            ],
            [
              -94.09196,
              30.569464
            ],
            [
              -94.089641,
              30.569491
            ],
            [
              -94.088452,
              30.57208
            ],
            [
              -94.092622,
              30.575578
            ],
            [
              -94.090269,
              30.584698
            ],
            [
              -94.086617,
              30.589789
            ],
            [
              -94.085532,
              30.595318
            ],
            [
              -94.080683,
              30.597529
            ],
            [
              -94.07766,
              30.60801
            ],
            [
              -94.074931,
              30.614729
            ],
            [
              -94.071761,
              30.61942
            ],
            [
              -94.06566,
              30.62106
            ],
            [
              -94.058174,
              30.626392
            ],
            [
              -94.054607,
              30.628904
            ],
            [
              -94.053744,
              30.630609
            ],
            [
              -94.053651,
              30.63463
            ],
            [
              -94.051562,
              30.641792
            ],
            [
              -94.05565,
              30.644733
            ],
            [
              -94.056235,
              30.643577
            ],
            [
              -94.056727,
              30.643221
            ],
            [
              -94.055625,
              30.647998
            ],
            [
              -94.053125,
              30.648991
            ],
            [
              -94.05475,
              30.652037
            ],
            [
              -94.059683,
              30.656456
            ],
            [
              -94.065035,
              30.662595
            ],
            [
              -94.0723,
              30.664984
            ],
            [
              -94.07687,
              30.666236
            ],
            [
              -94.077863,
              30.664353
            ],
            [
              -94.075744,
              30.66109
            ],
            [
              -94.073839,
              30.657996
            ],
            [
              -94.076802,
              30.658059
            ],
            [
              -94.081938,
              30.658478
            ],
            [
              -94.083219,
              30.657556
            ],
            [
              -94.083961,
              30.661946
            ],
            [
              -94.082895,
              30.668374
            ],
            [
              -94.090784,
              30.678721
            ],
            [
              -94.091123,
              30.683279
            ],
            [
              -94.092514,
              30.688483
            ],
            [
              -94.097386,
              30.689003
            ],
            [
              -94.109136,
              30.688069
            ],
            [
              -94.120617,
              30.68437
            ],
            [
              -94.126006,
              30.686663
            ],
            [
              -94.123707,
              30.690953
            ],
            [
              -94.123422,
              30.696605
            ],
            [
              -94.127357,
              30.704357
            ],
            [
              -94.133317,
              30.710845
            ],
            [
              -94.134141,
              30.717134
            ],
            [
              -94.130935,
              30.722459
            ],
            [
              -94.137182,
              30.733462
            ],
            [
              -94.130965,
              30.742646
            ],
            [
              -94.128458,
              30.747245
            ],
            [
              -94.130541,
              30.751868
            ],
            [
              -94.133079,
              30.751217
            ],
            [
              -94.137438,
              30.752713
            ],
            [
              -94.138184,
              30.758361
            ],
            [
              -94.133526,
              30.762589
            ],
            [
              -94.136312,
              30.769399
            ],
            [
              -94.139413,
              30.770964
            ],
            [
              -94.141524,
              30.774759
            ],
            [
              -94.139938,
              30.77648
            ],
            [
              -94.141019,
              30.781781
            ],
            [
              -94.146689,
              30.787451
            ],
            [
              -94.157705,
              30.789488
            ],
            [
              -94.168504,
              30.793445
            ],
            [
              -94.17793,
              30.794379
            ],
            [
              -94.180358,
              30.798338
            ],
            [
              -94.181283,
              30.802476
            ],
            [
              -94.179043,
              30.809128
            ],
            [
              -94.180403,
              30.81043
            ],
            [
              -94.184569,
              30.816035
            ],
            [
              -94.187562,
              30.816732
            ],
            [
              -94.188439,
              30.821359
            ],
            [
              -94.181867,
              30.822851
            ],
            [
              -94.180486,
              30.824678
            ],
            [
              -94.179895,
              30.829884
            ],
            [
              -94.183255,
              30.830357
            ],
            [
              -94.183345,
              30.834412
            ],
            [
              -94.181324,
              30.835575
            ],
            [
              -94.186466,
              30.836674
            ],
            [
              -94.191636,
              30.840323
            ],
            [
              -94.191806,
              30.844058
            ],
            [
              -94.198037,
              30.853293
            ],
            [
              -94.197219,
              30.858339
            ],
            [
              -94.19827,
              30.861552
            ],
            [
              -94.193453,
              30.863737
            ],
            [
              -94.194888,
              30.868895
            ],
            [
              -94.19441,
              30.872406
            ],
            [
              -94.196967,
              30.87319
            ],
            [
              -94.196143,
              30.87568
            ],
            [
              -94.194274,
              30.876637
            ],
            [
              -94.196307,
              30.881325
            ],
            [
              -94.200079,
              30.882713
            ],
            [
              -94.203981,
              30.885437
            ],
            [
              -94.202411,
              30.889337
            ],
            [
              -94.202632,
              30.89413
            ],
            [
              -94.207904,
              30.897416
            ],
            [
              -94.213012,
              30.899962
            ],
            [
              -94.214904,
              30.904198
            ],
            [
              -94.214078,
              30.907388
            ],
            [
              -94.210057,
              30.910392
            ],
            [
              -94.208635,
              30.909703
            ],
            [
              -94.206444,
              30.912542
            ],
            [
              -94.204623,
              30.916021
            ],
            [
              -94.206572,
              30.923396
            ],
            [
              -94.20279,
              30.927056
            ],
            [
              -94.20306,
              30.929278
            ],
            [
              -94.205539,
              30.929669
            ],
            [
              -94.206479,
              30.933175
            ],
            [
              -94.2046,
              30.935429
            ],
            [
              -94.206871,
              30.936619
            ],
            [
              -94.211474,
              30.936605
            ],
            [
              -94.216549,
              30.93437
            ],
            [
              -94.221341,
              30.935738
            ],
            [
              -94.221367,
              30.938731
            ],
            [
              -94.221921,
              30.94396
            ],
            [
              -94.222575,
              30.947073
            ],
            [
              -94.224183,
              30.942745
            ],
            [
              -94.225376,
              30.940024
            ],
            [
              -94.228466,
              30.94192
            ],
            [
              -94.229742,
              30.944955
            ],
            [
              -94.230407,
              30.948033
            ],
            [
              -94.228612,
              30.94897
            ],
            [
              -94.229847,
              30.951534
            ],
            [
              -94.231696,
              30.951296
            ],
            [
              -94.232932,
              30.953699
            ],
            [
              -94.230891,
              30.956006
            ],
            [
              -94.229866,
              30.958686
            ],
            [
              -94.234999,
              30.959723
            ],
            [
              -94.242667,
              30.962966
            ],
            [
              -94.242273,
              30.969361
            ],
            [
              -94.239314,
              30.973098
            ],
            [
              -94.242924,
              30.980271
            ],
            [
              -94.242613,
              30.988874
            ],
            [
              -94.241595,
              30.9904
            ],
            [
              -94.239928,
              30.990823
            ],
            [
              -94.238955,
              30.987702
            ],
            [
              -94.235746,
              30.98671
            ],
            [
              -94.233165,
              30.987958
            ],
            [
              -94.235296,
              30.99663
            ],
            [
              -94.240862,
              31.006888
            ],
            [
              -94.244437,
              31.011551
            ],
            [
              -94.25594,
              31.018488
            ],
            [
              -94.269905,
              31.019305
            ],
            [
              -94.278076,
              31.017554
            ],
            [
              -94.286279,
              31.027792
            ],
            [
              -94.284458,
              31.033274
            ],
            [
              -94.285413,
              31.039572
            ],
            [
              -94.289225,
              31.041267
            ],
            [
              -94.295352,
              31.041814
            ],
            [
              -94.298526,
              31.034586
            ],
            [
              -94.315016,
              31.023352
            ],
            [
              -94.322037,
              31.021202
            ],
            [
              -94.330345,
              31.024801
            ],
            [
              -94.334943,
              31.028906
            ],
            [
              -94.337017,
              31.035941
            ],
            [
              -94.34328,
              31.03778
            ],
            [
              -94.3502,
              31.038362
            ],
            [
              -94.354367,
              31.035869
            ],
            [
              -94.36056,
              31.033447
            ],
            [
              -94.373596,
              31.030998
            ],
            [
              -94.379626,
              31.031269
            ],
            [
              -94.385903,
              31.027747
            ],
            [
              -94.39386,
              31.028977
            ],
            [
              -94.399638,
              31.025019
            ],
            [
              -94.414593,
              31.026082
            ],
            [
              -94.426926,
              31.019566
            ],
            [
              -94.430341,
              31.015104
            ],
            [
              -94.433077,
              31.01316
            ],
            [
              -94.432493,
              31.013207
            ],
            [
              -94.432702,
              31.011147
            ],
            [
              -94.435317,
              31.006511
            ],
            [
              -94.444223,
              31.005223
            ],
            [
              -94.451444,
              31.008305
            ],
            [
              -94.450189,
              31.012323
            ],
            [
              -94.445817,
              31.023616
            ],
            [
              -94.446969,
              31.033098
            ],
            [
              -94.451425,
              31.031457
            ],
            [
              -94.456275,
              31.031325
            ],
            [
              -94.45826,
              31.03203
            ],
            [
              -94.457816,
              31.033325
            ],
            [
              -94.472704,
              31.02609
            ],
            [
              -94.486152,
              31.039333
            ],
            [
              -94.508927,
              31.043271
            ],
            [
              -94.533726,
              31.056825
            ],
            [
              -94.561943,
              31.058952
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "469",
      "COUNTYNS": "01384020",
      "AFFGEOID": "0500000US48469",
      "GEOID": "48469",
      "NAME": "Victoria",
      "LSAD": "06",
      "ALAND": 2284644914,
      "AWATER": 17425945,
      "County_state": "Victoria,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.305916,
              28.864037
            ],
            [
              -97.298578,
              28.861962
            ],
            [
              -97.303059,
              28.845071
            ],
            [
              -97.255942,
              28.848428
            ],
            [
              -97.254453,
              28.857274
            ],
            [
              -97.23923,
              28.853818
            ],
            [
              -97.231971,
              28.865303
            ],
            [
              -97.202759,
              28.853849
            ],
            [
              -97.186238,
              28.832929
            ],
            [
              -97.190182,
              28.812473
            ],
            [
              -97.176325,
              28.790734
            ],
            [
              -97.158933,
              28.776156
            ],
            [
              -97.167836,
              28.74792
            ],
            [
              -97.177972,
              28.736935
            ],
            [
              -97.167731,
              28.727846
            ],
            [
              -97.182359,
              28.610602
            ],
            [
              -97.176117,
              28.595212
            ],
            [
              -97.1623,
              28.585873
            ],
            [
              -97.170372,
              28.579748
            ],
            [
              -97.153968,
              28.570173
            ],
            [
              -97.164311,
              28.560986
            ],
            [
              -97.160751,
              28.553475
            ],
            [
              -97.147817,
              28.54637
            ],
            [
              -97.140263,
              28.553828
            ],
            [
              -97.125049,
              28.545307
            ],
            [
              -97.105167,
              28.554807
            ],
            [
              -97.090837,
              28.534575
            ],
            [
              -97.077132,
              28.537762
            ],
            [
              -97.07201,
              28.547102
            ],
            [
              -97.061864,
              28.543843
            ],
            [
              -97.045395,
              28.54999
            ],
            [
              -97.051385,
              28.537033
            ],
            [
              -97.039863,
              28.532074
            ],
            [
              -97.030616,
              28.541527
            ],
            [
              -97.019738,
              28.520261
            ],
            [
              -97.004635,
              28.508909
            ],
            [
              -96.982147,
              28.499639
            ],
            [
              -96.955547,
              28.504358
            ],
            [
              -96.9155,
              28.485712
            ],
            [
              -96.91551,
              28.499174
            ],
            [
              -96.901722,
              28.509639
            ],
            [
              -96.890951,
              28.507178
            ],
            [
              -96.905923,
              28.532393
            ],
            [
              -96.895144,
              28.549257
            ],
            [
              -96.903795,
              28.567033
            ],
            [
              -96.916885,
              28.570983
            ],
            [
              -96.930367,
              28.586728
            ],
            [
              -96.853757,
              28.635199
            ],
            [
              -96.722814,
              28.669759
            ],
            [
              -96.665707,
              28.705705
            ],
            [
              -96.659864,
              28.701671
            ],
            [
              -96.643442,
              28.712105
            ],
            [
              -96.642505,
              28.716945
            ],
            [
              -96.65921,
              28.724045
            ],
            [
              -96.668478,
              28.737621
            ],
            [
              -96.669878,
              28.760287
            ],
            [
              -96.687549,
              28.76462
            ],
            [
              -96.69933,
              28.778643
            ],
            [
              -96.695092,
              28.792092
            ],
            [
              -96.70719,
              28.815486
            ],
            [
              -96.719652,
              28.817178
            ],
            [
              -96.711514,
              28.851411
            ],
            [
              -96.732506,
              28.877076
            ],
            [
              -96.740219,
              28.893869
            ],
            [
              -96.758447,
              28.896476
            ],
            [
              -96.77604,
              28.915959
            ],
            [
              -96.792133,
              28.918803
            ],
            [
              -96.816639,
              28.985392
            ],
            [
              -96.832103,
              29.021299
            ],
            [
              -96.838795,
              29.025327
            ],
            [
              -96.938587,
              29.063244
            ],
            [
              -96.976378,
              29.104046
            ],
            [
              -97.305916,
              28.864037
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "479",
      "COUNTYNS": "01384025",
      "AFFGEOID": "0500000US48479",
      "GEOID": "48479",
      "NAME": "Webb",
      "LSAD": "06",
      "ALAND": 8706186700,
      "AWATER": 36555096,
      "County_state": "Webb,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.212136662868,
              28.1968373634523
            ],
            [
              -100.212105,
              28.19651
            ],
            [
              -100.208059,
              28.190383
            ],
            [
              -100.196499,
              28.190218
            ],
            [
              -100.185694,
              28.185781
            ],
            [
              -100.174413,
              28.179448
            ],
            [
              -100.168438,
              28.171542
            ],
            [
              -100.16059,
              28.16816
            ],
            [
              -100.141098,
              28.168149
            ],
            [
              -100.119628,
              28.155588
            ],
            [
              -100.090289,
              28.148313
            ],
            [
              -100.083393,
              28.144035
            ],
            [
              -100.075474,
              28.124882
            ],
            [
              -100.067652,
              28.113602
            ],
            [
              -100.056493,
              28.104186
            ],
            [
              -100.055596,
              28.101141
            ],
            [
              -100.056983,
              28.094207
            ],
            [
              -100.053123,
              28.08473
            ],
            [
              -100.046108,
              28.079068
            ],
            [
              -100.028725,
              28.073118
            ],
            [
              -100.017914,
              28.064787
            ],
            [
              -100.012839,
              28.037203
            ],
            [
              -100.008631,
              28.023964
            ],
            [
              -99.991447,
              27.99456
            ],
            [
              -99.984923,
              27.990729
            ],
            [
              -99.962769,
              27.983536
            ],
            [
              -99.931812,
              27.980967
            ],
            [
              -99.932161,
              27.96771
            ],
            [
              -99.938541,
              27.954059
            ],
            [
              -99.937142,
              27.940537
            ],
            [
              -99.917461,
              27.917973
            ],
            [
              -99.90008,
              27.912142
            ],
            [
              -99.895828,
              27.904178
            ],
            [
              -99.893456,
              27.899208
            ],
            [
              -99.894091,
              27.89295
            ],
            [
              -99.901232,
              27.884406
            ],
            [
              -99.904385,
              27.875284
            ],
            [
              -99.901486,
              27.864162
            ],
            [
              -99.89365,
              27.856193
            ],
            [
              -99.882015,
              27.850392
            ],
            [
              -99.877202,
              27.842179
            ],
            [
              -99.876003,
              27.837968
            ],
            [
              -99.87784,
              27.824376
            ],
            [
              -99.877677,
              27.799427
            ],
            [
              -99.870066,
              27.794627
            ],
            [
              -99.850877,
              27.793974
            ],
            [
              -99.844737,
              27.778809
            ],
            [
              -99.841708,
              27.766464
            ],
            [
              -99.835127,
              27.762881
            ],
            [
              -99.825793,
              27.764374
            ],
            [
              -99.822193,
              27.766855
            ],
            [
              -99.819092,
              27.776019
            ],
            [
              -99.813086,
              27.773952
            ],
            [
              -99.80567,
              27.758688
            ],
            [
              -99.801651,
              27.741771
            ],
            [
              -99.796342,
              27.735586
            ],
            [
              -99.788845,
              27.730718
            ],
            [
              -99.785366,
              27.730355
            ],
            [
              -99.774901,
              27.73354
            ],
            [
              -99.77074,
              27.732134
            ],
            [
              -99.758534,
              27.717071
            ],
            [
              -99.757539,
              27.711853
            ],
            [
              -99.732448,
              27.685425
            ],
            [
              -99.723716,
              27.673328
            ],
            [
              -99.721519,
              27.666155
            ],
            [
              -99.711511,
              27.658365
            ],
            [
              -99.704601,
              27.654954
            ],
            [
              -99.699356,
              27.655417
            ],
            [
              -99.685813,
              27.661015
            ],
            [
              -99.668942,
              27.659974
            ],
            [
              -99.661845,
              27.655753
            ],
            [
              -99.658295,
              27.650158
            ],
            [
              -99.6595,
              27.645246
            ],
            [
              -99.665422,
              27.640275
            ],
            [
              -99.665948,
              27.635968
            ],
            [
              -99.654324,
              27.629616
            ],
            [
              -99.638929,
              27.626758
            ],
            [
              -99.625322,
              27.631137
            ],
            [
              -99.624515,
              27.634515
            ],
            [
              -99.603533,
              27.641992
            ],
            [
              -99.596231,
              27.639858
            ],
            [
              -99.594038,
              27.638573
            ],
            [
              -99.591372,
              27.627464
            ],
            [
              -99.584782,
              27.622007
            ],
            [
              -99.578099,
              27.619196
            ],
            [
              -99.57816,
              27.610369
            ],
            [
              -99.585148,
              27.606398
            ],
            [
              -99.584843,
              27.603903
            ],
            [
              -99.580006,
              27.602251
            ],
            [
              -99.562869,
              27.607264
            ],
            [
              -99.559467,
              27.609076
            ],
            [
              -99.556812,
              27.614336
            ],
            [
              -99.55495,
              27.614454
            ],
            [
              -99.539722,
              27.603281
            ],
            [
              -99.530138,
              27.580207
            ],
            [
              -99.52401378974069,
              27.576714124148697
            ],
            [
              -99.515978,
              27.572131
            ],
            [
              -99.512219,
              27.568094
            ],
            [
              -99.511119,
              27.564494
            ],
            [
              -99.514319,
              27.556994
            ],
            [
              -99.518819,
              27.553194
            ],
            [
              -99.521919,
              27.544094
            ],
            [
              -99.52832,
              27.498896
            ],
            [
              -99.52582,
              27.496696
            ],
            [
              -99.51932,
              27.496896
            ],
            [
              -99.51332,
              27.499496
            ],
            [
              -99.497519,
              27.500496
            ],
            [
              -99.483519,
              27.491096
            ],
            [
              -99.480219,
              27.485796
            ],
            [
              -99.480419,
              27.481596
            ],
            [
              -99.483819,
              27.467297
            ],
            [
              -99.4856559428951,
              27.464728535494697
            ],
            [
              -99.495104,
              27.451518
            ],
            [
              -99.495699,
              27.438884
            ],
            [
              -99.487521,
              27.412396
            ],
            [
              -99.492144,
              27.380517
            ],
            [
              -99.499076,
              27.37314
            ],
            [
              -99.507779,
              27.354247
            ],
            [
              -99.507831,
              27.348637
            ],
            [
              -99.504837,
              27.338289
            ],
            [
              -99.52126,
              27.324784
            ],
            [
              -99.531376,
              27.323809
            ],
            [
              -99.537771,
              27.316073
            ],
            [
              -99.536443,
              27.312538
            ],
            [
              -99.529654,
              27.306051
            ],
            [
              -99.523658,
              27.304138
            ],
            [
              -99.511531,
              27.304077
            ],
            [
              -99.502036,
              27.306121
            ],
            [
              -99.494604,
              27.303542
            ],
            [
              -99.487937,
              27.294941
            ],
            [
              -99.487513,
              27.29024
            ],
            [
              -99.496615,
              27.271708
            ],
            [
              -99.492407,
              27.264118
            ],
            [
              -99.48791,
              27.260721
            ],
            [
              -99.480688,
              27.259989
            ],
            [
              -99.463309,
              27.268437
            ],
            [
              -99.453949001404,
              27.264672596827698
            ],
            [
              -99.370621,
              27.319174
            ],
            [
              -99.33361,
              27.273223
            ],
            [
              -98.954669,
              27.269397
            ],
            [
              -98.798087,
              27.268012
            ],
            [
              -98.798323,
              27.354029
            ],
            [
              -98.803325,
              28.05748
            ],
            [
              -99.388946,
              28.030419
            ],
            [
              -99.393972,
              28.197495
            ],
            [
              -99.394177,
              28.204627
            ],
            [
              -100.113719,
              28.197819
            ],
            [
              -100.212136662868,
              28.1968373634523
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "481",
      "COUNTYNS": "01384026",
      "AFFGEOID": "0500000US48481",
      "GEOID": "48481",
      "NAME": "Wharton",
      "LSAD": "06",
      "ALAND": 2813116790,
      "AWATER": 21288266,
      "County_state": "Wharton,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.604341,
              29.26578
            ],
            [
              -96.640315,
              29.247804
            ],
            [
              -96.309178,
              28.963291
            ],
            [
              -95.965196,
              29.146865
            ],
            [
              -95.874028,
              29.229702
            ],
            [
              -95.841948,
              29.258707
            ],
            [
              -95.847656,
              29.26259
            ],
            [
              -95.850833,
              29.266274
            ],
            [
              -95.87424,
              29.266538
            ],
            [
              -95.897541,
              29.299283
            ],
            [
              -95.894468,
              29.31498
            ],
            [
              -95.916606,
              29.319368
            ],
            [
              -95.923005,
              29.329273
            ],
            [
              -95.94681,
              29.333433
            ],
            [
              -95.95338,
              29.345079
            ],
            [
              -95.93951,
              29.352534
            ],
            [
              -95.947294,
              29.372518
            ],
            [
              -95.968412,
              29.383449
            ],
            [
              -95.97343,
              29.411445
            ],
            [
              -95.982061,
              29.410766
            ],
            [
              -95.992934,
              29.433628
            ],
            [
              -96.003848,
              29.428865
            ],
            [
              -96.047978,
              29.46184
            ],
            [
              -96.059627,
              29.473329
            ],
            [
              -96.059681,
              29.491868
            ],
            [
              -96.035519,
              29.492287
            ],
            [
              -96.022341,
              29.502044
            ],
            [
              -96.036519,
              29.51083
            ],
            [
              -96.053918,
              29.512009
            ],
            [
              -96.055312,
              29.532904
            ],
            [
              -96.041137,
              29.540516
            ],
            [
              -96.044596,
              29.562238
            ],
            [
              -96.059998,
              29.56866
            ],
            [
              -96.059562,
              29.588518
            ],
            [
              -96.088912,
              29.601658
            ],
            [
              -96.10965,
              29.622524
            ],
            [
              -96.141837,
              29.619636
            ],
            [
              -96.175422,
              29.633806
            ],
            [
              -96.307672,
              29.514523
            ],
            [
              -96.349217,
              29.406052
            ],
            [
              -96.375814,
              29.393271
            ],
            [
              -96.604341,
              29.26578
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "273",
      "COUNTYNS": "01383922",
      "AFFGEOID": "0500000US48273",
      "GEOID": "48273",
      "NAME": "Kleberg",
      "LSAD": "06",
      "ALAND": 2282572442,
      "AWATER": 541041664,
      "County_state": "Kleberg,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.377235,
              27.279976
            ],
            [
              -97.3775228909102,
              27.278024636189798
            ],
            [
              -97.34685087695568,
              27.2779588322095
            ],
            [
              -97.336122,
              27.317818
            ],
            [
              -97.296057,
              27.427175
            ],
            [
              -97.257325,
              27.510644
            ],
            [
              -97.2229914075318,
              27.5766073164796
            ],
            [
              -97.242122,
              27.582924
            ],
            [
              -97.2683119237207,
              27.575967973304397
            ],
            [
              -97.304254,
              27.507317
            ],
            [
              -97.330447,
              27.4345
            ],
            [
              -97.361464,
              27.351988
            ],
            [
              -97.377235,
              27.279976
            ]
          ]
        ],
        [
          [
            [
              -98.0598,
              27.635869
            ],
            [
              -98.058078,
              27.260981
            ],
            [
              -97.986076,
              27.260494
            ],
            [
              -97.985887,
              27.209308
            ],
            [
              -97.959196,
              27.212942
            ],
            [
              -97.951904,
              27.233249
            ],
            [
              -97.901703,
              27.238302
            ],
            [
              -97.884827,
              27.235764
            ],
            [
              -97.868125,
              27.240318
            ],
            [
              -97.855397,
              27.252424
            ],
            [
              -97.84134,
              27.243955
            ],
            [
              -97.815658,
              27.255806
            ],
            [
              -97.815925,
              27.271368
            ],
            [
              -97.797131,
              27.27159
            ],
            [
              -97.785102,
              27.283714
            ],
            [
              -97.740046,
              27.278281
            ],
            [
              -97.727403,
              27.26599
            ],
            [
              -97.650497,
              27.283488
            ],
            [
              -97.6366800047884,
              27.2817272286326
            ],
            [
              -97.636549,
              27.282139
            ],
            [
              -97.63146,
              27.28621
            ],
            [
              -97.621791,
              27.287228
            ],
            [
              -97.609068,
              27.285193
            ],
            [
              -97.584132,
              27.30962
            ],
            [
              -97.5709,
              27.315727
            ],
            [
              -97.53833,
              27.335574
            ],
            [
              -97.520518,
              27.352877
            ],
            [
              -97.514411,
              27.361529
            ],
            [
              -97.501688,
              27.366618
            ],
            [
              -97.48693,
              27.358984
            ],
            [
              -97.483877,
              27.351351
            ],
            [
              -97.483877,
              27.338628
            ],
            [
              -97.499144,
              27.327941
            ],
            [
              -97.502706,
              27.322343
            ],
            [
              -97.498126,
              27.308602
            ],
            [
              -97.504742,
              27.30504
            ],
            [
              -97.517465,
              27.30504
            ],
            [
              -97.524589,
              27.297915
            ],
            [
              -97.526625,
              27.291808
            ],
            [
              -97.536803,
              27.289264
            ],
            [
              -97.546981,
              27.290791
            ],
            [
              -97.544437,
              27.284175
            ],
            [
              -97.532223,
              27.278577
            ],
            [
              -97.508304,
              27.275014
            ],
            [
              -97.482859,
              27.297915
            ],
            [
              -97.450798,
              27.313691
            ],
            [
              -97.430441,
              27.313691
            ],
            [
              -97.420263,
              27.317254
            ],
            [
              -97.413138,
              27.321325
            ],
            [
              -97.404996,
              27.329977
            ],
            [
              -97.401942,
              27.335574
            ],
            [
              -97.399398,
              27.344735
            ],
            [
              -97.37955,
              27.390028
            ],
            [
              -97.372935,
              27.401224
            ],
            [
              -97.369881,
              27.41242
            ],
            [
              -97.371917,
              27.425142
            ],
            [
              -97.36581,
              27.450588
            ],
            [
              -97.359194,
              27.458221
            ],
            [
              -97.350543,
              27.478578
            ],
            [
              -97.347489,
              27.503005
            ],
            [
              -97.343418,
              27.517764
            ],
            [
              -97.336802,
              27.527433
            ],
            [
              -97.32511825033188,
              27.5609268748918
            ],
            [
              -97.572961,
              27.560343
            ],
            [
              -97.840536,
              27.558473
            ],
            [
              -97.838883,
              27.563178
            ],
            [
              -97.85803,
              27.580704
            ],
            [
              -97.873867,
              27.587858
            ],
            [
              -97.877193,
              27.60898
            ],
            [
              -97.88859,
              27.620249
            ],
            [
              -97.920991,
              27.62291
            ],
            [
              -97.932377,
              27.635929
            ],
            [
              -97.942146,
              27.635932
            ],
            [
              -98.0598,
              27.635869
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "201",
      "COUNTYNS": "01383886",
      "AFFGEOID": "0500000US48201",
      "GEOID": "48201",
      "NAME": "Harris",
      "LSAD": "06",
      "ALAND": 4415733152,
      "AWATER": 187863191,
      "County_state": "Harris,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.944505,
              30.148168
            ],
            [
              -95.960615,
              30.163628
            ],
            [
              -95.868966,
              29.908828
            ],
            [
              -95.82622,
              29.78828
            ],
            [
              -95.812471,
              29.780594
            ],
            [
              -95.751446,
              29.746472
            ],
            [
              -95.730396,
              29.734296
            ],
            [
              -95.688349,
              29.710862
            ],
            [
              -95.687082,
              29.710078
            ],
            [
              -95.670872,
              29.700991
            ],
            [
              -95.652188,
              29.69096
            ],
            [
              -95.621848,
              29.673572
            ],
            [
              -95.597839,
              29.661037
            ],
            [
              -95.597033,
              29.660673
            ],
            [
              -95.582706,
              29.653972
            ],
            [
              -95.571345,
              29.648741
            ],
            [
              -95.446339,
              29.590698
            ],
            [
              -95.424118,
              29.580233
            ],
            [
              -95.375414,
              29.588278
            ],
            [
              -95.346263,
              29.585592
            ],
            [
              -95.331146,
              29.599108
            ],
            [
              -95.297411,
              29.596522
            ],
            [
              -95.286493,
              29.597886
            ],
            [
              -95.261606,
              29.593157
            ],
            [
              -95.260292,
              29.589883
            ],
            [
              -95.253856,
              29.553778
            ],
            [
              -95.237985,
              29.561426
            ],
            [
              -95.216422,
              29.55608
            ],
            [
              -95.200985,
              29.563212
            ],
            [
              -95.193154,
              29.559518
            ],
            [
              -95.199453,
              29.544528
            ],
            [
              -95.183097,
              29.519017
            ],
            [
              -95.169668,
              29.514892
            ],
            [
              -95.163534,
              29.497856
            ],
            [
              -95.141024,
              29.506488
            ],
            [
              -95.117139,
              29.508031
            ],
            [
              -95.1155,
              29.508819
            ],
            [
              -95.114573,
              29.509962
            ],
            [
              -95.113747,
              29.510608
            ],
            [
              -95.112457,
              29.510936
            ],
            [
              -95.110833,
              29.510597
            ],
            [
              -95.109468,
              29.509856
            ],
            [
              -95.108102,
              29.509649
            ],
            [
              -95.106497,
              29.510212
            ],
            [
              -95.105199,
              29.510381
            ],
            [
              -95.104326,
              29.510734
            ],
            [
              -95.103874,
              29.51129
            ],
            [
              -95.104036,
              29.512562
            ],
            [
              -95.104819,
              29.514187
            ],
            [
              -95.10602,
              29.515781
            ],
            [
              -95.10618,
              29.516588
            ],
            [
              -95.105629,
              29.517233
            ],
            [
              -95.10363,
              29.517894
            ],
            [
              -95.103008,
              29.518799
            ],
            [
              -95.10345,
              29.520145
            ],
            [
              -95.103205,
              29.520853
            ],
            [
              -95.10189,
              29.521561
            ],
            [
              -95.100552,
              29.520787
            ],
            [
              -95.099896,
              29.51987
            ],
            [
              -95.099233,
              29.518408
            ],
            [
              -95.0985,
              29.518172
            ],
            [
              -95.097612,
              29.518611
            ],
            [
              -95.097046,
              29.520517
            ],
            [
              -95.096414,
              29.521266
            ],
            [
              -95.094785,
              29.522587
            ],
            [
              -95.093693,
              29.525344
            ],
            [
              -95.093871,
              29.526526
            ],
            [
              -95.095066,
              29.52816
            ],
            [
              -95.095361,
              29.529376
            ],
            [
              -95.095426,
              29.530738
            ],
            [
              -95.096508,
              29.532511
            ],
            [
              -95.096978,
              29.533738
            ],
            [
              -95.096453,
              29.534953
            ],
            [
              -95.094518,
              29.53574
            ],
            [
              -95.091862,
              29.533881
            ],
            [
              -95.091169,
              29.533172
            ],
            [
              -95.090879,
              29.531555
            ],
            [
              -95.090038,
              29.531128
            ],
            [
              -95.089354,
              29.531214
            ],
            [
              -95.088187,
              29.531891
            ],
            [
              -95.086973,
              29.533114
            ],
            [
              -95.086507,
              29.53441
            ],
            [
              -95.085926,
              29.534848
            ],
            [
              -95.083129,
              29.53392
            ],
            [
              -95.082189,
              29.533997
            ],
            [
              -95.080106,
              29.533672
            ],
            [
              -95.065065,
              29.558478
            ],
            [
              -95.01761,
              29.548181
            ],
            [
              -95.0181905786307,
              29.554583889664297
            ],
            [
              -95.018253,
              29.554885
            ],
            [
              -95.016627,
              29.558487
            ],
            [
              -95.00767,
              29.574257
            ],
            [
              -94.988993,
              29.591155
            ],
            [
              -94.982706,
              29.601344
            ],
            [
              -94.988871,
              29.610095
            ],
            [
              -95.013499,
              29.629194
            ],
            [
              -95.015636,
              29.639457
            ],
            [
              -95.011683,
              29.649802
            ],
            [
              -95.01102490450819,
              29.6508034360037
            ],
            [
              -95.005398,
              29.659366
            ],
            [
              -94.980114074552,
              29.6790666365217
            ],
            [
              -94.972666,
              29.68487
            ],
            [
              -94.965963,
              29.70033
            ],
            [
              -94.942681,
              29.697778
            ],
            [
              -94.936089,
              29.692704
            ],
            [
              -94.934167,
              29.678682
            ],
            [
              -94.9311539114845,
              29.6738738151668
            ],
            [
              -94.91558,
              29.697812
            ],
            [
              -94.929909,
              29.69737
            ],
            [
              -94.932566,
              29.714342
            ],
            [
              -94.945399,
              29.716237
            ],
            [
              -94.928596,
              29.741156
            ],
            [
              -94.913813,
              29.7663
            ],
            [
              -94.922942,
              29.802381
            ],
            [
              -94.909816,
              29.821223
            ],
            [
              -94.934599,
              29.834292
            ],
            [
              -94.95309,
              29.855303
            ],
            [
              -94.976835,
              29.867972
            ],
            [
              -94.98146,
              29.884276
            ],
            [
              -94.998656,
              29.919771
            ],
            [
              -94.985364,
              29.972279
            ],
            [
              -94.999834,
              29.991322
            ],
            [
              -95.026772,
              29.996739
            ],
            [
              -95.030936,
              29.993699
            ],
            [
              -95.056844,
              30.06098
            ],
            [
              -95.096708,
              30.167214
            ],
            [
              -95.195724,
              30.087321
            ],
            [
              -95.249854,
              30.043518
            ],
            [
              -95.263677,
              30.032517
            ],
            [
              -95.292997,
              30.027905
            ],
            [
              -95.310994,
              30.032887
            ],
            [
              -95.309548,
              30.045625
            ],
            [
              -95.353633,
              30.076337
            ],
            [
              -95.374971,
              30.086374
            ],
            [
              -95.395624,
              30.084025
            ],
            [
              -95.405443,
              30.092076
            ],
            [
              -95.424216,
              30.115491
            ],
            [
              -95.436642,
              30.110366
            ],
            [
              -95.474844,
              30.103256
            ],
            [
              -95.488415,
              30.108028
            ],
            [
              -95.501095,
              30.143772
            ],
            [
              -95.518574,
              30.152184
            ],
            [
              -95.529532,
              30.16582
            ],
            [
              -95.54214,
              30.170473
            ],
            [
              -95.551421,
              30.170606
            ],
            [
              -95.57463,
              30.151783
            ],
            [
              -95.590913,
              30.136064
            ],
            [
              -95.596757,
              30.13446
            ],
            [
              -95.602062,
              30.132741
            ],
            [
              -95.619629,
              30.135493
            ],
            [
              -95.624072,
              30.126675
            ],
            [
              -95.636613,
              30.12642
            ],
            [
              -95.672538,
              30.101515
            ],
            [
              -95.685701,
              30.106907
            ],
            [
              -95.733943,
              30.093539
            ],
            [
              -95.755185,
              30.092148
            ],
            [
              -95.760927,
              30.086406
            ],
            [
              -95.80333,
              30.090096
            ],
            [
              -95.828395,
              30.081723
            ],
            [
              -95.844741,
              30.088914
            ],
            [
              -95.846649,
              30.100499
            ],
            [
              -95.856902,
              30.101798
            ],
            [
              -95.874053,
              30.122225
            ],
            [
              -95.890804,
              30.119019
            ],
            [
              -95.911082,
              30.128107
            ],
            [
              -95.929056,
              30.144175
            ],
            [
              -95.944505,
              30.148168
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "197",
      "COUNTYNS": "01383884",
      "AFFGEOID": "0500000US48197",
      "GEOID": "48197",
      "NAME": "Hardeman",
      "LSAD": "06",
      "ALAND": 1800337387,
      "AWATER": 4548860,
      "County_state": "Hardeman,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.99772,
              34.311829
            ],
            [
              -99.997621,
              34.224355
            ],
            [
              -99.772043,
              34.093286
            ],
            [
              -99.746826,
              34.100998
            ],
            [
              -99.733356,
              34.093513
            ],
            [
              -99.704281,
              34.09847
            ],
            [
              -99.678619,
              34.093271
            ],
            [
              -99.673719,
              34.088506
            ],
            [
              -99.65301,
              34.098971
            ],
            [
              -99.623058,
              34.095754
            ],
            [
              -99.617846,
              34.083387
            ],
            [
              -99.593563,
              34.082526
            ],
            [
              -99.581531,
              34.077237
            ],
            [
              -99.573161,
              34.063608
            ],
            [
              -99.5485,
              34.055774
            ],
            [
              -99.534898,
              34.073983
            ],
            [
              -99.505386,
              34.077043
            ],
            [
              -99.491097,
              34.065407
            ],
            [
              -99.475294,
              34.085618
            ],
            [
              -99.4751232484102,
              34.3963977417428
            ],
            [
              -99.477547,
              34.396355
            ],
            [
              -99.487219,
              34.397955
            ],
            [
              -99.490426,
              34.399694
            ],
            [
              -99.494104,
              34.404755
            ],
            [
              -99.497091,
              34.407731
            ],
            [
              -99.499875,
              34.409608
            ],
            [
              -99.51428,
              34.414035
            ],
            [
              -99.517624,
              34.414494
            ],
            [
              -99.52365,
              34.412206
            ],
            [
              -99.529786,
              34.411452
            ],
            [
              -99.549242,
              34.412715
            ],
            [
              -99.555986,
              34.41464
            ],
            [
              -99.562204,
              34.417319
            ],
            [
              -99.569696,
              34.418418
            ],
            [
              -99.574367,
              34.418281
            ],
            [
              -99.58006,
              34.416653
            ],
            [
              -99.58448,
              34.407673
            ],
            [
              -99.585306,
              34.398122
            ],
            [
              -99.584531,
              34.391205
            ],
            [
              -99.585442,
              34.388914
            ],
            [
              -99.587596,
              34.385867
            ],
            [
              -99.596323,
              34.377137
            ],
            [
              -99.600026,
              34.374688
            ],
            [
              -99.624197,
              34.373577
            ],
            [
              -99.630905,
              34.376007
            ],
            [
              -99.649662,
              34.379885
            ],
            [
              -99.654194,
              34.376519
            ],
            [
              -99.659362,
              34.37439
            ],
            [
              -99.662705,
              34.37368
            ],
            [
              -99.665992,
              34.374185
            ],
            [
              -99.671377,
              34.377714
            ],
            [
              -99.678283,
              34.379799
            ],
            [
              -99.696462,
              34.381036
            ],
            [
              -99.707901,
              34.387539
            ],
            [
              -99.712682,
              34.390928
            ],
            [
              -99.714838,
              34.394524
            ],
            [
              -99.714232,
              34.397822
            ],
            [
              -99.715089,
              34.400754
            ],
            [
              -99.716416,
              34.402815
            ],
            [
              -99.720259,
              34.406295
            ],
            [
              -99.730348,
              34.41124
            ],
            [
              -99.740907,
              34.414763
            ],
            [
              -99.754248,
              34.421289
            ],
            [
              -99.767234,
              34.430502
            ],
            [
              -99.767648,
              34.431854
            ],
            [
              -99.764882,
              34.435266
            ],
            [
              -99.764826,
              34.436434
            ],
            [
              -99.765599,
              34.437488
            ],
            [
              -99.775743,
              34.444225
            ],
            [
              -99.782986,
              34.444364
            ],
            [
              -99.793684,
              34.453894
            ],
            [
              -99.814313,
              34.476204
            ],
            [
              -99.818739,
              34.484976
            ],
            [
              -99.818186,
              34.48784
            ],
            [
              -99.825325,
              34.497596
            ],
            [
              -99.832904,
              34.500068
            ],
            [
              -99.8446582036581,
              34.5067869364725
            ],
            [
              -99.853066,
              34.511593
            ],
            [
              -99.868953,
              34.527615
            ],
            [
              -99.872357,
              34.532096
            ],
            [
              -99.873254,
              34.535351
            ],
            [
              -99.874403,
              34.537095
            ],
            [
              -99.887147,
              34.549047
            ],
            [
              -99.89376,
              34.554219
            ],
            [
              -99.896007,
              34.55553
            ],
            [
              -99.898943,
              34.555804
            ],
            [
              -99.915771,
              34.565975
            ],
            [
              -99.921801,
              34.570253
            ],
            [
              -99.923211,
              34.574552
            ],
            [
              -99.929334,
              34.576714
            ],
            [
              -99.94572,
              34.579273
            ],
            [
              -99.954567,
              34.578195
            ],
            [
              -99.956717,
              34.576524
            ],
            [
              -99.957553,
              34.574169
            ],
            [
              -99.957541,
              34.572709
            ],
            [
              -99.958898,
              34.571271
            ],
            [
              -99.965608,
              34.565844
            ],
            [
              -99.971555,
              34.562179
            ],
            [
              -99.974762,
              34.561318
            ],
            [
              -99.985833,
              34.560079
            ],
            [
              -99.9975014109467,
              34.5604238870434
            ],
            [
              -99.99772,
              34.311829
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "351",
      "COUNTYNS": "01383961",
      "AFFGEOID": "0500000US48351",
      "GEOID": "48351",
      "NAME": "Newton",
      "LSAD": "06",
      "ALAND": 2418212543,
      "AWATER": 15672146,
      "County_state": "Newton,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.911126,
              31.158071
            ],
            [
              -93.865711,
              30.947218
            ],
            [
              -93.901452,
              30.242672
            ],
            [
              -93.705519312533,
              30.244184772189
            ],
            [
              -93.707271,
              30.249937
            ],
            [
              -93.709132,
              30.271827
            ],
            [
              -93.70719,
              30.275513
            ],
            [
              -93.706608,
              30.281187
            ],
            [
              -93.708645,
              30.288317
            ],
            [
              -93.711118,
              30.291372
            ],
            [
              -93.714319,
              30.294282
            ],
            [
              -93.718684,
              30.29501
            ],
            [
              -93.72422,
              30.295134
            ],
            [
              -93.738699,
              30.303794
            ],
            [
              -93.760328,
              30.329924
            ],
            [
              -93.764265,
              30.330223
            ],
            [
              -93.765822,
              30.333318
            ],
            [
              -93.756352,
              30.356166
            ],
            [
              -93.755894,
              30.370709
            ],
            [
              -93.758554,
              30.387077
            ],
            [
              -93.757654,
              30.390423
            ],
            [
              -93.751437,
              30.396288
            ],
            [
              -93.745333,
              30.397022
            ],
            [
              -93.7402445414121,
              30.4022625442349
            ],
            [
              -93.722314,
              30.420729
            ],
            [
              -93.702665,
              30.429947
            ],
            [
              -93.6978,
              30.440583
            ],
            [
              -93.697828,
              30.443838
            ],
            [
              -93.705845,
              30.457748
            ],
            [
              -93.716678,
              30.494006
            ],
            [
              -93.710117,
              30.5064
            ],
            [
              -93.714322,
              30.518562
            ],
            [
              -93.727721,
              30.525671
            ],
            [
              -93.732793,
              30.52996
            ],
            [
              -93.740253,
              30.539569
            ],
            [
              -93.729195,
              30.544842
            ],
            [
              -93.725847,
              30.556978
            ],
            [
              -93.727746,
              30.566487
            ],
            [
              -93.727844,
              30.57407
            ],
            [
              -93.712454,
              30.588479
            ],
            [
              -93.692869,
              30.594382
            ],
            [
              -93.689534,
              30.592759
            ],
            [
              -93.687282,
              30.591601
            ],
            [
              -93.684329,
              30.592586
            ],
            [
              -93.681235,
              30.596102
            ],
            [
              -93.679828,
              30.599758
            ],
            [
              -93.680813,
              30.602993
            ],
            [
              -93.683397,
              30.608041
            ],
            [
              -93.685121,
              30.625201
            ],
            [
              -93.6831,
              30.640763
            ],
            [
              -93.670354,
              30.658034
            ],
            [
              -93.654971,
              30.670184
            ],
            [
              -93.638213,
              30.673058
            ],
            [
              -93.629904,
              30.67994
            ],
            [
              -93.621093,
              30.695159
            ],
            [
              -93.620774,
              30.704122
            ],
            [
              -93.616184,
              30.71398
            ],
            [
              -93.611192,
              30.718053
            ],
            [
              -93.609544,
              30.723139
            ],
            [
              -93.609719,
              30.729182
            ],
            [
              -93.617688,
              30.738479
            ],
            [
              -93.619129,
              30.742002
            ],
            [
              -93.607757,
              30.757657
            ],
            [
              -93.592828,
              30.763986
            ],
            [
              -93.589896,
              30.77776
            ],
            [
              -93.589381,
              30.786681
            ],
            [
              -93.584265,
              30.796663
            ],
            [
              -93.578395,
              30.802047
            ],
            [
              -93.569303,
              30.802969
            ],
            [
              -93.563243,
              30.806218
            ],
            [
              -93.561666,
              30.807739
            ],
            [
              -93.554057,
              30.824941
            ],
            [
              -93.553626,
              30.83514
            ],
            [
              -93.558172,
              30.839974
            ],
            [
              -93.55860790084809,
              30.868821820173896
            ],
            [
              -93.558617,
              30.869424
            ],
            [
              -93.565428,
              30.87431
            ],
            [
              -93.567451,
              30.878524
            ],
            [
              -93.567788,
              30.888302
            ],
            [
              -93.564248,
              30.895045
            ],
            [
              -93.556493,
              30.901451
            ],
            [
              -93.55565,
              30.911228
            ],
            [
              -93.551942,
              30.918646
            ],
            [
              -93.549244,
              30.921006
            ],
            [
              -93.546884,
              30.921511
            ],
            [
              -93.54503,
              30.920837
            ],
            [
              -93.542489,
              30.920064
            ],
            [
              -93.530936,
              30.924534
            ],
            [
              -93.526147,
              30.930035
            ],
            [
              -93.526245,
              30.939411
            ],
            [
              -93.532549,
              30.950696
            ],
            [
              -93.549841,
              30.967118
            ],
            [
              -93.560533,
              30.971286
            ],
            [
              -93.567972,
              30.977981
            ],
            [
              -93.571906,
              30.987614
            ],
            [
              -93.569764,
              30.996715
            ],
            [
              -93.56798,
              31.001534
            ],
            [
              -93.566017,
              31.004567
            ],
            [
              -93.562626,
              31.005995
            ],
            [
              -93.555581,
              31.003919
            ],
            [
              -93.539526,
              31.008498
            ],
            [
              -93.516943,
              31.023662
            ],
            [
              -93.516407,
              31.02955
            ],
            [
              -93.516943,
              31.032584
            ],
            [
              -93.523248,
              31.037842
            ],
            [
              -93.531219,
              31.051678
            ],
            [
              -93.532069,
              31.055264
            ],
            [
              -93.529256,
              31.057567
            ],
            [
              -93.52533,
              31.060601
            ],
            [
              -93.52301,
              31.065241
            ],
            [
              -93.526044,
              31.070773
            ],
            [
              -93.53104,
              31.074699
            ],
            [
              -93.540129,
              31.078003
            ],
            [
              -93.546644,
              31.082989
            ],
            [
              -93.551034,
              31.091111
            ],
            [
              -93.551693,
              31.097258
            ],
            [
              -93.549717,
              31.10516
            ],
            [
              -93.541375,
              31.113502
            ],
            [
              -93.539619,
              31.121844
            ],
            [
              -93.540278,
              31.128868
            ],
            [
              -93.544702,
              31.135889
            ],
            [
              -93.544888,
              31.143137
            ],
            [
              -93.544888,
              31.148844
            ],
            [
              -93.54401,
              31.153015
            ],
            [
              -93.540253,
              31.156579
            ],
            [
              -93.53683,
              31.15862
            ],
            [
              -93.531744,
              31.180817
            ],
            [
              -93.533307,
              31.184463
            ],
            [
              -93.535097,
              31.185614
            ],
            [
              -93.548931,
              31.186601
            ],
            [
              -93.5525399494441,
              31.1856050930259
            ],
            [
              -93.552649,
              31.185575
            ],
            [
              -93.560943,
              31.182482
            ],
            [
              -93.569563,
              31.177574
            ],
            [
              -93.579215,
              31.167422
            ],
            [
              -93.588503,
              31.165581
            ],
            [
              -93.598828,
              31.174679
            ],
            [
              -93.600308,
              31.176158
            ],
            [
              -93.911126,
              31.158071
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "349",
      "COUNTYNS": "01383960",
      "AFFGEOID": "0500000US48349",
      "GEOID": "48349",
      "NAME": "Navarro",
      "LSAD": "06",
      "ALAND": 2614935427,
      "AWATER": 197626095,
      "County_state": "Navarro,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.896209,
              32.073977
            ],
            [
              -96.719114,
              31.814887
            ],
            [
              -96.496713,
              31.796191
            ],
            [
              -96.05478,
              32.012526
            ],
            [
              -96.062649,
              32.026442
            ],
            [
              -96.069534,
              32.0765
            ],
            [
              -96.06601,
              32.085812
            ],
            [
              -96.081578,
              32.081479
            ],
            [
              -96.105364,
              32.075337
            ],
            [
              -96.117726,
              32.094632
            ],
            [
              -96.11153,
              32.115803
            ],
            [
              -96.114699,
              32.126487
            ],
            [
              -96.102421,
              32.142269
            ],
            [
              -96.113161,
              32.160701
            ],
            [
              -96.138407,
              32.151417
            ],
            [
              -96.166975,
              32.158672
            ],
            [
              -96.17584,
              32.168241
            ],
            [
              -96.176477,
              32.181379
            ],
            [
              -96.157907,
              32.191334
            ],
            [
              -96.159453,
              32.205206
            ],
            [
              -96.146799,
              32.211937
            ],
            [
              -96.155854,
              32.21501
            ],
            [
              -96.175848,
              32.203939
            ],
            [
              -96.196965,
              32.206942
            ],
            [
              -96.229373,
              32.236182
            ],
            [
              -96.233967,
              32.250009
            ],
            [
              -96.262248,
              32.249739
            ],
            [
              -96.267667,
              32.259202
            ],
            [
              -96.285895,
              32.25598
            ],
            [
              -96.284013,
              32.279225
            ],
            [
              -96.312635,
              32.30313
            ],
            [
              -96.339016,
              32.305973
            ],
            [
              -96.367452,
              32.317871
            ],
            [
              -96.383082,
              32.328851
            ],
            [
              -96.395079,
              32.323644
            ],
            [
              -96.896209,
              32.073977
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "337",
      "COUNTYNS": "01383954",
      "AFFGEOID": "0500000US48337",
      "GEOID": "48337",
      "NAME": "Montague",
      "LSAD": "06",
      "ALAND": 2411034614,
      "AWATER": 19087361,
      "County_state": "Montague,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.978919,
              33.467129
            ],
            [
              -97.978967,
              33.433748
            ],
            [
              -97.918189,
              33.433873
            ],
            [
              -97.487065,
              33.433681
            ],
            [
              -97.48403724377,
              33.9157618691195
            ],
            [
              -97.486505,
              33.916994
            ],
            [
              -97.494858,
              33.919258
            ],
            [
              -97.50096,
              33.919643
            ],
            [
              -97.519171,
              33.913638
            ],
            [
              -97.525277,
              33.911751
            ],
            [
              -97.532723,
              33.906577
            ],
            [
              -97.543246,
              33.901289
            ],
            [
              -97.551541,
              33.897947
            ],
            [
              -97.555002,
              33.897282
            ],
            [
              -97.55827,
              33.897099
            ],
            [
              -97.56117554354529,
              33.8975366004539
            ],
            [
              -97.582744,
              33.900785
            ],
            [
              -97.587441,
              33.902479
            ],
            [
              -97.589254,
              33.903922
            ],
            [
              -97.596289,
              33.913769
            ],
            [
              -97.597115,
              33.917868
            ],
            [
              -97.596979,
              33.920228
            ],
            [
              -97.596155,
              33.922106
            ],
            [
              -97.595084,
              33.922954
            ],
            [
              -97.591514,
              33.9282
            ],
            [
              -97.588828,
              33.951882
            ],
            [
              -97.589598,
              33.953554
            ],
            [
              -97.609091,
              33.968093
            ],
            [
              -97.633778,
              33.981257
            ],
            [
              -97.65621,
              33.989488
            ],
            [
              -97.661489,
              33.990818
            ],
            [
              -97.671772,
              33.99137
            ],
            [
              -97.688023,
              33.986607
            ],
            [
              -97.69311,
              33.983699
            ],
            [
              -97.697921,
              33.977331
            ],
            [
              -97.704159,
              33.963336
            ],
            [
              -97.709684,
              33.954997
            ],
            [
              -97.716772,
              33.947666
            ],
            [
              -97.725289,
              33.941045
            ],
            [
              -97.732267,
              33.936691
            ],
            [
              -97.733723,
              33.936392
            ],
            [
              -97.736554,
              33.936575
            ],
            [
              -97.738478,
              33.937421
            ],
            [
              -97.752957,
              33.937049
            ],
            [
              -97.762768,
              33.934396
            ],
            [
              -97.762661,
              33.930846
            ],
            [
              -97.759834,
              33.92521
            ],
            [
              -97.759399,
              33.91882
            ],
            [
              -97.760224,
              33.917194
            ],
            [
              -97.76377,
              33.914241
            ],
            [
              -97.765446,
              33.913532
            ],
            [
              -97.772672,
              33.914382
            ],
            [
              -97.783717,
              33.91056
            ],
            [
              -97.78034,
              33.904833
            ],
            [
              -97.779683,
              33.899243
            ],
            [
              -97.780618,
              33.895533
            ],
            [
              -97.784657,
              33.890632
            ],
            [
              -97.801578,
              33.885138
            ],
            [
              -97.803473,
              33.88019
            ],
            [
              -97.805423,
              33.877167
            ],
            [
              -97.834333,
              33.857671
            ],
            [
              -97.865765,
              33.849393
            ],
            [
              -97.871447,
              33.849001
            ],
            [
              -97.877387,
              33.850236
            ],
            [
              -97.896738,
              33.857985
            ],
            [
              -97.905467,
              33.863531
            ],
            [
              -97.936743,
              33.879204
            ],
            [
              -97.938802,
              33.879891
            ],
            [
              -97.94273,
              33.879845
            ],
            [
              -97.946464,
              33.878883
            ],
            [
              -97.951215,
              33.878424
            ],
            [
              -97.958438,
              33.879179
            ],
            [
              -97.967777,
              33.88243
            ],
            [
              -97.974178,
              33.886643
            ],
            [
              -97.97780788974269,
              33.8898833742718
            ],
            [
              -97.978919,
              33.467129
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "163",
      "COUNTYNS": "01383867",
      "AFFGEOID": "0500000US48163",
      "GEOID": "48163",
      "NAME": "Frio",
      "LSAD": "06",
      "ALAND": 2935753507,
      "AWATER": 2193620,
      "County_state": "Frio,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.41388,
              29.091335
            ],
            [
              -99.409021,
              28.640566
            ],
            [
              -99.395736,
              28.640784
            ],
            [
              -98.800841,
              28.647487
            ],
            [
              -98.8049,
              29.090434
            ],
            [
              -99.41388,
              29.091335
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "203",
      "COUNTYNS": "01383887",
      "AFFGEOID": "0500000US48203",
      "GEOID": "48203",
      "NAME": "Harrison",
      "LSAD": "06",
      "ALAND": 2330820903,
      "AWATER": 40969486,
      "County_state": "Harrison,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.70214,
              32.793088
            ],
            [
              -94.70179,
              32.652209
            ],
            [
              -94.701986,
              32.421782
            ],
            [
              -94.693083,
              32.427389
            ],
            [
              -94.67192,
              32.42182
            ],
            [
              -94.642426,
              32.398315
            ],
            [
              -94.598787,
              32.399645
            ],
            [
              -94.588653,
              32.391381
            ],
            [
              -94.579219,
              32.394916
            ],
            [
              -94.554971,
              32.407603
            ],
            [
              -94.525511,
              32.401871
            ],
            [
              -94.518038,
              32.388124
            ],
            [
              -94.491818,
              32.394137
            ],
            [
              -94.456049,
              32.369252
            ],
            [
              -94.440545,
              32.3621
            ],
            [
              -94.402245,
              32.369339
            ],
            [
              -94.383772,
              32.366499
            ],
            [
              -94.378831,
              32.342375
            ],
            [
              -94.367224,
              32.345945
            ],
            [
              -94.35064,
              32.326583
            ],
            [
              -94.042901,
              32.392283
            ],
            [
              -94.042923,
              32.399918
            ],
            [
              -94.042899,
              32.400659
            ],
            [
              -94.042986,
              32.435507
            ],
            [
              -94.042908,
              32.439891
            ],
            [
              -94.042903,
              32.470386
            ],
            [
              -94.042875,
              32.471348
            ],
            [
              -94.042902,
              32.472906
            ],
            [
              -94.042995,
              32.478004
            ],
            [
              -94.042955,
              32.480261
            ],
            [
              -94.043072,
              32.4843
            ],
            [
              -94.043089,
              32.486561
            ],
            [
              -94.042911,
              32.492852
            ],
            [
              -94.042885,
              32.505145
            ],
            [
              -94.043081,
              32.513613
            ],
            [
              -94.0430847911547,
              32.516465004884196
            ],
            [
              -94.043142,
              32.559502
            ],
            [
              -94.043083,
              32.564261
            ],
            [
              -94.042919,
              32.610142
            ],
            [
              -94.042929,
              32.61826
            ],
            [
              -94.042926,
              32.622015
            ],
            [
              -94.042824,
              32.640305
            ],
            [
              -94.04278,
              32.643466
            ],
            [
              -94.042913,
              32.655127
            ],
            [
              -94.0431469938267,
              32.6930300000381
            ],
            [
              -94.077797,
              32.688149
            ],
            [
              -94.095537,
              32.700948
            ],
            [
              -94.107613,
              32.702848
            ],
            [
              -94.108702,
              32.713274
            ],
            [
              -94.102172,
              32.711425
            ],
            [
              -94.096046,
              32.715918
            ],
            [
              -94.090579,
              32.72322
            ],
            [
              -94.094262,
              32.725759
            ],
            [
              -94.095814,
              32.728872
            ],
            [
              -94.094048,
              32.729037
            ],
            [
              -94.091802,
              32.730659
            ],
            [
              -94.095811,
              32.732969
            ],
            [
              -94.099888,
              32.734332
            ],
            [
              -94.10616,
              32.73612
            ],
            [
              -94.11096,
              32.736276
            ],
            [
              -94.11882,
              32.737543
            ],
            [
              -94.135303,
              32.739993
            ],
            [
              -94.144783,
              32.735724
            ],
            [
              -94.149357,
              32.727125
            ],
            [
              -94.162381,
              32.715903
            ],
            [
              -94.170661,
              32.702615
            ],
            [
              -94.195331,
              32.699263
            ],
            [
              -94.210648,
              32.703154
            ],
            [
              -94.223818,
              32.708169
            ],
            [
              -94.231093,
              32.712552
            ],
            [
              -94.232879,
              32.718453
            ],
            [
              -94.23186,
              32.720137
            ],
            [
              -94.229715,
              32.721231
            ],
            [
              -94.227716,
              32.724472
            ],
            [
              -94.231412,
              32.730619
            ],
            [
              -94.235718,
              32.744258
            ],
            [
              -94.241467,
              32.748066
            ],
            [
              -94.247105,
              32.747857
            ],
            [
              -94.246891,
              32.745898
            ],
            [
              -94.250207,
              32.745151
            ],
            [
              -94.24994,
              32.746659
            ],
            [
              -94.248943,
              32.749512
            ],
            [
              -94.252314,
              32.750904
            ],
            [
              -94.25545,
              32.75166
            ],
            [
              -94.257706,
              32.749959
            ],
            [
              -94.258852,
              32.749445
            ],
            [
              -94.259982,
              32.748276
            ],
            [
              -94.259618,
              32.747587
            ],
            [
              -94.265427,
              32.747017
            ],
            [
              -94.270248,
              32.746949
            ],
            [
              -94.272971,
              32.74908
            ],
            [
              -94.278206,
              32.749443
            ],
            [
              -94.282262,
              32.745378
            ],
            [
              -94.285847,
              32.738933
            ],
            [
              -94.288094,
              32.735677
            ],
            [
              -94.291877,
              32.727349
            ],
            [
              -94.298725,
              32.724942
            ],
            [
              -94.308662,
              32.726821
            ],
            [
              -94.313375,
              32.728329
            ],
            [
              -94.318251,
              32.727857
            ],
            [
              -94.321917,
              32.724763
            ],
            [
              -94.32777,
              32.718439
            ],
            [
              -94.336979,
              32.716444
            ],
            [
              -94.345928,
              32.712951
            ],
            [
              -94.348047,
              32.709534
            ],
            [
              -94.354906,
              32.705869
            ],
            [
              -94.361724,
              32.703532
            ],
            [
              -94.367331,
              32.701382
            ],
            [
              -94.370148,
              32.704063
            ],
            [
              -94.375239,
              32.69777
            ],
            [
              -94.380225,
              32.695121
            ],
            [
              -94.392895,
              32.694377
            ],
            [
              -94.434946,
              32.694159
            ],
            [
              -94.471237,
              32.693956
            ],
            [
              -94.499094,
              32.693759
            ],
            [
              -94.50633,
              32.693739
            ],
            [
              -94.538554,
              32.70959
            ],
            [
              -94.557418,
              32.719244
            ],
            [
              -94.56234,
              32.721899
            ],
            [
              -94.572219,
              32.72686
            ],
            [
              -94.589929,
              32.735928
            ],
            [
              -94.616265,
              32.749494
            ],
            [
              -94.630571,
              32.756776
            ],
            [
              -94.667465,
              32.775501
            ],
            [
              -94.684255,
              32.784017
            ],
            [
              -94.701236,
              32.792629
            ],
            [
              -94.70214,
              32.793088
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "277",
      "COUNTYNS": "01383925",
      "AFFGEOID": "0500000US48277",
      "GEOID": "48277",
      "NAME": "Lamar",
      "LSAD": "06",
      "ALAND": 2349588917,
      "AWATER": 67136455,
      "County_state": "Lamar,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.855013,
              33.827992
            ],
            [
              -95.857738,
              33.461722
            ],
            [
              -95.826979,
              33.458968
            ],
            [
              -95.807818,
              33.475065
            ],
            [
              -95.776506,
              33.492396
            ],
            [
              -95.761076,
              33.495387
            ],
            [
              -95.726705,
              33.484471
            ],
            [
              -95.707786,
              33.48776
            ],
            [
              -95.681464,
              33.470273
            ],
            [
              -95.642957,
              33.48669
            ],
            [
              -95.603675,
              33.484721
            ],
            [
              -95.582012,
              33.469172
            ],
            [
              -95.565836,
              33.47706
            ],
            [
              -95.526956,
              33.445923
            ],
            [
              -95.500903,
              33.443404
            ],
            [
              -95.491897,
              33.455337
            ],
            [
              -95.473742,
              33.448054
            ],
            [
              -95.4646,
              33.433895
            ],
            [
              -95.449499,
              33.434785
            ],
            [
              -95.416898,
              33.412194
            ],
            [
              -95.399752,
              33.409778
            ],
            [
              -95.368791,
              33.394306
            ],
            [
              -95.308637,
              33.380662
            ],
            [
              -95.310437,
              33.875027
            ],
            [
              -95.30945815240901,
              33.8803039268509
            ],
            [
              -95.325572,
              33.885704
            ],
            [
              -95.333452,
              33.886286
            ],
            [
              -95.334523,
              33.885788
            ],
            [
              -95.334854,
              33.876831
            ],
            [
              -95.339122,
              33.868873
            ],
            [
              -95.352338,
              33.867789
            ],
            [
              -95.375233,
              33.868243
            ],
            [
              -95.407795,
              33.866308
            ],
            [
              -95.44737,
              33.86885
            ],
            [
              -95.463346,
              33.872313
            ],
            [
              -95.464211,
              33.873372
            ],
            [
              -95.461499,
              33.883686
            ],
            [
              -95.46291,
              33.885903
            ],
            [
              -95.464925,
              33.886709
            ],
            [
              -95.469962,
              33.886105
            ],
            [
              -95.478575,
              33.879301
            ],
            [
              -95.492028,
              33.874822
            ],
            [
              -95.502304,
              33.874742
            ],
            [
              -95.506085,
              33.87639
            ],
            [
              -95.506495,
              33.878589
            ],
            [
              -95.506234,
              33.886306
            ],
            [
              -95.510063,
              33.890135
            ],
            [
              -95.515302,
              33.891142
            ],
            [
              -95.520138,
              33.889329
            ],
            [
              -95.525322,
              33.885487
            ],
            [
              -95.533283,
              33.881162
            ],
            [
              -95.53979,
              33.879904
            ],
            [
              -95.545197,
              33.880294
            ],
            [
              -95.548325,
              33.882744
            ],
            [
              -95.552085,
              33.888422
            ],
            [
              -95.552331,
              33.89442
            ],
            [
              -95.549475,
              33.901311
            ],
            [
              -95.549145,
              33.90795
            ],
            [
              -95.551148,
              33.914566
            ],
            [
              -95.556915,
              33.92702
            ],
            [
              -95.559414,
              33.930179
            ],
            [
              -95.561007,
              33.931552
            ],
            [
              -95.563424,
              33.932193
            ],
            [
              -95.585945,
              33.93448
            ],
            [
              -95.599678,
              33.934247
            ],
            [
              -95.603657,
              33.927195
            ],
            [
              -95.636978,
              33.906613
            ],
            [
              -95.647273,
              33.905976
            ],
            [
              -95.659818,
              33.909092
            ],
            [
              -95.665338,
              33.908132
            ],
            [
              -95.669978,
              33.905844
            ],
            [
              -95.676925,
              33.897237
            ],
            [
              -95.684831,
              33.890232
            ],
            [
              -95.696962,
              33.885218
            ],
            [
              -95.710878,
              33.884552
            ],
            [
              -95.71354,
              33.885124
            ],
            [
              -95.728449,
              33.893704
            ],
            [
              -95.737508,
              33.895967
            ],
            [
              -95.747335,
              33.895756
            ],
            [
              -95.756367,
              33.892625
            ],
            [
              -95.758344,
              33.890611
            ],
            [
              -95.761916,
              33.883402
            ],
            [
              -95.762559,
              33.874367
            ],
            [
              -95.76183973777901,
              33.8729497991814
            ],
            [
              -95.760805,
              33.870911
            ],
            [
              -95.757458,
              33.867957
            ],
            [
              -95.753513,
              33.856464
            ],
            [
              -95.75431,
              33.853992
            ],
            [
              -95.758016,
              33.85008
            ],
            [
              -95.763622,
              33.847954
            ],
            [
              -95.772067,
              33.843817
            ],
            [
              -95.773282,
              33.843834
            ],
            [
              -95.776255,
              33.845145
            ],
            [
              -95.787891,
              33.856336
            ],
            [
              -95.789867,
              33.857686
            ],
            [
              -95.800842,
              33.861212
            ],
            [
              -95.805149,
              33.861304
            ],
            [
              -95.820596,
              33.858465
            ],
            [
              -95.821666,
              33.856633
            ],
            [
              -95.821666,
              33.855443
            ],
            [
              -95.820677,
              33.850751
            ],
            [
              -95.819525,
              33.848439
            ],
            [
              -95.818976,
              33.844456
            ],
            [
              -95.819358,
              33.842785
            ],
            [
              -95.820784,
              33.840564
            ],
            [
              -95.822787,
              33.838756
            ],
            [
              -95.828245,
              33.836054
            ],
            [
              -95.831948,
              33.835161
            ],
            [
              -95.837516,
              33.83564
            ],
            [
              -95.840012,
              33.836486
            ],
            [
              -95.843773,
              33.838949
            ],
            [
              -95.8456275554846,
              33.8407767617097
            ],
            [
              -95.855013,
              33.827992
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "153",
      "COUNTYNS": "01383862",
      "AFFGEOID": "0500000US48153",
      "GEOID": "48153",
      "NAME": "Floyd",
      "LSAD": "06",
      "ALAND": 2569637276,
      "AWATER": 983737,
      "County_state": "Floyd,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.564856,
              34.312493
            ],
            [
              -101.564563,
              34.074228
            ],
            [
              -101.56358,
              33.830451
            ],
            [
              -101.041158,
              33.833625
            ],
            [
              -101.041437,
              34.130796
            ],
            [
              -101.041484,
              34.312444
            ],
            [
              -101.47158,
              34.31229
            ],
            [
              -101.564856,
              34.312493
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "427",
      "COUNTYNS": "01383999",
      "AFFGEOID": "0500000US48427",
      "GEOID": "48427",
      "NAME": "Starr",
      "LSAD": "06",
      "ALAND": 3168019245,
      "AWATER": 15346371,
      "County_state": "Starr,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.171404,
              26.549848
            ],
            [
              -99.170704,
              26.540316
            ],
            [
              -99.166742,
              26.536079
            ],
            [
              -99.143659,
              26.527901
            ],
            [
              -99.127782,
              26.525199
            ],
            [
              -99.105031,
              26.500335
            ],
            [
              -99.091635,
              26.476977
            ],
            [
              -99.103083,
              26.441515
            ],
            [
              -99.113808,
              26.434002
            ],
            [
              -99.110855,
              26.426278
            ],
            [
              -99.089413,
              26.4081
            ],
            [
              -99.085126,
              26.398782
            ],
            [
              -99.082002,
              26.39651
            ],
            [
              -99.062093,
              26.397371
            ],
            [
              -99.053185,
              26.402006
            ],
            [
              -99.045466,
              26.409816
            ],
            [
              -99.039107,
              26.412947
            ],
            [
              -99.032316,
              26.412082
            ],
            [
              -99.021935,
              26.407902
            ],
            [
              -99.014739,
              26.398827
            ],
            [
              -99.008003,
              26.395459
            ],
            [
              -98.990321,
              26.395459
            ],
            [
              -98.967587,
              26.398266
            ],
            [
              -98.958325,
              26.394056
            ],
            [
              -98.950186,
              26.380303
            ],
            [
              -98.942046,
              26.375532
            ],
            [
              -98.937556,
              26.376093
            ],
            [
              -98.924926,
              26.381987
            ],
            [
              -98.921277,
              26.381426
            ],
            [
              -98.90556,
              26.364024
            ],
            [
              -98.890965,
              26.357569
            ],
            [
              -98.861354,
              26.36599
            ],
            [
              -98.853415,
              26.365023
            ],
            [
              -98.847707,
              26.359595
            ],
            [
              -98.844057,
              26.358638
            ],
            [
              -98.832909,
              26.363338
            ],
            [
              -98.824571,
              26.370715
            ],
            [
              -98.807348,
              26.369421
            ],
            [
              -98.798211,
              26.360166
            ],
            [
              -98.796252,
              26.349104
            ],
            [
              -98.789822,
              26.331575
            ],
            [
              -98.779912,
              26.326542
            ],
            [
              -98.755242,
              26.3251
            ],
            [
              -98.749054,
              26.321662
            ],
            [
              -98.745615,
              26.317193
            ],
            [
              -98.745272,
              26.303096
            ],
            [
              -98.734613,
              26.29897
            ],
            [
              -98.729196,
              26.299027
            ],
            [
              -98.711233,
              26.289687
            ],
            [
              -98.709171,
              26.284186
            ],
            [
              -98.710602,
              26.279018
            ],
            [
              -98.707451,
              26.272152
            ],
            [
              -98.698856,
              26.265619
            ],
            [
              -98.687156,
              26.26512
            ],
            [
              -98.681167,
              26.26271
            ],
            [
              -98.677766,
              26.255568
            ],
            [
              -98.679042,
              26.245554
            ],
            [
              -98.675206,
              26.239989
            ],
            [
              -98.669397,
              26.23632
            ],
            [
              -98.654221,
              26.23596
            ],
            [
              -98.63418,
              26.242612
            ],
            [
              -98.626654,
              26.25221
            ],
            [
              -98.613465,
              26.252028
            ],
            [
              -98.599154,
              26.257612
            ],
            [
              -98.5873137213485,
              26.2549142471762
            ],
            [
              -98.5855,
              26.259861
            ],
            [
              -98.491943,
              26.445362
            ],
            [
              -98.396759,
              26.625337
            ],
            [
              -98.32067,
              26.783081
            ],
            [
              -98.422616,
              26.783535
            ],
            [
              -98.95423,
              26.785694
            ],
            [
              -99.011112,
              26.675023
            ],
            [
              -99.1694639664008,
              26.571673559574
            ],
            [
              -99.16741,
              26.560001
            ],
            [
              -99.171404,
              26.549848
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "429",
      "COUNTYNS": "01384000",
      "AFFGEOID": "0500000US48429",
      "GEOID": "48429",
      "NAME": "Stephens",
      "LSAD": "06",
      "ALAND": 2322492638,
      "AWATER": 64152295,
      "County_state": "Stephens,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.095956,
              32.707581
            ],
            [
              -99.09605,
              32.514768
            ],
            [
              -98.576256,
              32.515163
            ],
            [
              -98.576023,
              32.733834
            ],
            [
              -98.575616,
              32.954349
            ],
            [
              -98.950875,
              32.956918
            ],
            [
              -99.096016,
              32.957037
            ],
            [
              -99.095956,
              32.707581
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "369",
      "COUNTYNS": "01383970",
      "AFFGEOID": "0500000US48369",
      "GEOID": "48369",
      "NAME": "Parmer",
      "LSAD": "06",
      "ALAND": 2281312151,
      "AWATER": 11355762,
      "County_state": "Parmer,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.043979,
              34.312764
            ],
            [
              -103.043978936987,
              34.312749083575
            ],
            [
              -102.61515,
              34.312891
            ],
            [
              -102.525631,
              34.313034
            ],
            [
              -102.525184,
              34.746931
            ],
            [
              -103.042769,
              34.747361
            ],
            [
              -103.042827,
              34.671188
            ],
            [
              -103.043286,
              34.653099
            ],
            [
              -103.043072,
              34.619782
            ],
            [
              -103.043594,
              34.46266
            ],
            [
              -103.043589,
              34.459774
            ],
            [
              -103.043588,
              34.459662
            ],
            [
              -103.043582,
              34.455657
            ],
            [
              -103.043538,
              34.405463
            ],
            [
              -103.043583,
              34.400678
            ],
            [
              -103.043611,
              34.397105
            ],
            [
              -103.043585,
              34.393716
            ],
            [
              -103.043613,
              34.390442
            ],
            [
              -103.043613,
              34.388679
            ],
            [
              -103.043614,
              34.384969
            ],
            [
              -103.04363,
              34.38469
            ],
            [
              -103.043693,
              34.383578
            ],
            [
              -103.043919,
              34.380916
            ],
            [
              -103.043944,
              34.37966
            ],
            [
              -103.043946,
              34.379555
            ],
            [
              -103.043979,
              34.312764
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "315",
      "COUNTYNS": "01383940",
      "AFFGEOID": "0500000US48315",
      "GEOID": "48315",
      "NAME": "Marion",
      "LSAD": "06",
      "ALAND": 986503062,
      "AWATER": 102126745,
      "County_state": "Marion,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.705878,
              32.879177
            ],
            [
              -94.698976,
              32.845062
            ],
            [
              -94.672351,
              32.834756
            ],
            [
              -94.702138,
              32.820515
            ],
            [
              -94.70214,
              32.793088
            ],
            [
              -94.701236,
              32.792629
            ],
            [
              -94.684255,
              32.784017
            ],
            [
              -94.667465,
              32.775501
            ],
            [
              -94.630571,
              32.756776
            ],
            [
              -94.616265,
              32.749494
            ],
            [
              -94.589929,
              32.735928
            ],
            [
              -94.572219,
              32.72686
            ],
            [
              -94.56234,
              32.721899
            ],
            [
              -94.557418,
              32.719244
            ],
            [
              -94.538554,
              32.70959
            ],
            [
              -94.50633,
              32.693739
            ],
            [
              -94.499094,
              32.693759
            ],
            [
              -94.471237,
              32.693956
            ],
            [
              -94.434946,
              32.694159
            ],
            [
              -94.392895,
              32.694377
            ],
            [
              -94.380225,
              32.695121
            ],
            [
              -94.375239,
              32.69777
            ],
            [
              -94.370148,
              32.704063
            ],
            [
              -94.367331,
              32.701382
            ],
            [
              -94.361724,
              32.703532
            ],
            [
              -94.354906,
              32.705869
            ],
            [
              -94.348047,
              32.709534
            ],
            [
              -94.345928,
              32.712951
            ],
            [
              -94.336979,
              32.716444
            ],
            [
              -94.32777,
              32.718439
            ],
            [
              -94.321917,
              32.724763
            ],
            [
              -94.318251,
              32.727857
            ],
            [
              -94.313375,
              32.728329
            ],
            [
              -94.308662,
              32.726821
            ],
            [
              -94.298725,
              32.724942
            ],
            [
              -94.291877,
              32.727349
            ],
            [
              -94.288094,
              32.735677
            ],
            [
              -94.285847,
              32.738933
            ],
            [
              -94.282262,
              32.745378
            ],
            [
              -94.278206,
              32.749443
            ],
            [
              -94.272971,
              32.74908
            ],
            [
              -94.270248,
              32.746949
            ],
            [
              -94.265427,
              32.747017
            ],
            [
              -94.259618,
              32.747587
            ],
            [
              -94.259982,
              32.748276
            ],
            [
              -94.258852,
              32.749445
            ],
            [
              -94.257706,
              32.749959
            ],
            [
              -94.25545,
              32.75166
            ],
            [
              -94.252314,
              32.750904
            ],
            [
              -94.248943,
              32.749512
            ],
            [
              -94.24994,
              32.746659
            ],
            [
              -94.250207,
              32.745151
            ],
            [
              -94.246891,
              32.745898
            ],
            [
              -94.247105,
              32.747857
            ],
            [
              -94.241467,
              32.748066
            ],
            [
              -94.235718,
              32.744258
            ],
            [
              -94.231412,
              32.730619
            ],
            [
              -94.227716,
              32.724472
            ],
            [
              -94.229715,
              32.721231
            ],
            [
              -94.23186,
              32.720137
            ],
            [
              -94.232879,
              32.718453
            ],
            [
              -94.231093,
              32.712552
            ],
            [
              -94.223818,
              32.708169
            ],
            [
              -94.210648,
              32.703154
            ],
            [
              -94.195331,
              32.699263
            ],
            [
              -94.170661,
              32.702615
            ],
            [
              -94.162381,
              32.715903
            ],
            [
              -94.149357,
              32.727125
            ],
            [
              -94.144783,
              32.735724
            ],
            [
              -94.135303,
              32.739993
            ],
            [
              -94.11882,
              32.737543
            ],
            [
              -94.11096,
              32.736276
            ],
            [
              -94.10616,
              32.73612
            ],
            [
              -94.099888,
              32.734332
            ],
            [
              -94.095811,
              32.732969
            ],
            [
              -94.091802,
              32.730659
            ],
            [
              -94.094048,
              32.729037
            ],
            [
              -94.095814,
              32.728872
            ],
            [
              -94.094262,
              32.725759
            ],
            [
              -94.090579,
              32.72322
            ],
            [
              -94.096046,
              32.715918
            ],
            [
              -94.102172,
              32.711425
            ],
            [
              -94.108702,
              32.713274
            ],
            [
              -94.107613,
              32.702848
            ],
            [
              -94.095537,
              32.700948
            ],
            [
              -94.077797,
              32.688149
            ],
            [
              -94.0431469938267,
              32.6930300000381
            ],
            [
              -94.043147,
              32.693031
            ],
            [
              -94.042947,
              32.767991
            ],
            [
              -94.043027,
              32.776863
            ],
            [
              -94.042938,
              32.780558
            ],
            [
              -94.042829,
              32.785277
            ],
            [
              -94.042747,
              32.786973
            ],
            [
              -94.043026,
              32.797476
            ],
            [
              -94.042785,
              32.871486
            ],
            [
              -94.043025,
              32.880446
            ],
            [
              -94.042886,
              32.880965
            ],
            [
              -94.0428857174211,
              32.881088560255
            ],
            [
              -94.050329,
              32.880884
            ],
            [
              -94.653994,
              32.879506
            ],
            [
              -94.705878,
              32.879177
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "465",
      "COUNTYNS": "01384018",
      "AFFGEOID": "0500000US48465",
      "GEOID": "48465",
      "NAME": "Val Verde",
      "LSAD": "06",
      "ALAND": 8144863674,
      "AWATER": 227711349,
      "County_state": "Val Verde,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.7584,
              30.288044
            ],
            [
              -101.76091960021701,
              29.7824581874889
            ],
            [
              -101.754323,
              29.777662
            ],
            [
              -101.735202,
              29.771592
            ],
            [
              -101.714224,
              29.76766
            ],
            [
              -101.706636,
              29.762737
            ],
            [
              -101.689992,
              29.771213
            ],
            [
              -101.662453,
              29.77128
            ],
            [
              -101.654578,
              29.765163
            ],
            [
              -101.652401,
              29.758795
            ],
            [
              -101.646418,
              29.754304
            ],
            [
              -101.635128,
              29.758675
            ],
            [
              -101.630319,
              29.768729
            ],
            [
              -101.625958,
              29.771063
            ],
            [
              -101.603681,
              29.774399
            ],
            [
              -101.582562,
              29.771334
            ],
            [
              -101.575564,
              29.774444
            ],
            [
              -101.561569,
              29.794658
            ],
            [
              -101.546797,
              29.796991
            ],
            [
              -101.537467,
              29.782996
            ],
            [
              -101.532802,
              29.764336
            ],
            [
              -101.522695,
              29.759671
            ],
            [
              -101.503223,
              29.764582
            ],
            [
              -101.475269,
              29.780663
            ],
            [
              -101.467494,
              29.779886
            ],
            [
              -101.455224,
              29.771874
            ],
            [
              -101.453499,
              29.759671
            ],
            [
              -101.446502,
              29.755006
            ],
            [
              -101.441059,
              29.753451
            ],
            [
              -101.424732,
              29.758116
            ],
            [
              -101.415402,
              29.756561
            ],
            [
              -101.415584,
              29.746534
            ],
            [
              -101.410024,
              29.741498
            ],
            [
              -101.400636,
              29.738079
            ],
            [
              -101.397009,
              29.733963
            ],
            [
              -101.396294,
              29.727055
            ],
            [
              -101.398362,
              29.717
            ],
            [
              -101.396948,
              29.713947
            ],
            [
              -101.37886,
              29.69825
            ],
            [
              -101.367198,
              29.664041
            ],
            [
              -101.361756,
              29.657821
            ],
            [
              -101.350093,
              29.654711
            ],
            [
              -101.325214,
              29.657821
            ],
            [
              -101.316661,
              29.655488
            ],
            [
              -101.311219,
              29.648491
            ],
            [
              -101.307332,
              29.640716
            ],
            [
              -101.314329,
              29.595622
            ],
            [
              -101.305533,
              29.577925
            ],
            [
              -101.293219,
              29.571828
            ],
            [
              -101.27761,
              29.593231
            ],
            [
              -101.280488,
              29.614835
            ],
            [
              -101.269217,
              29.630027
            ],
            [
              -101.252274,
              29.625634
            ],
            [
              -101.247418,
              29.618158
            ],
            [
              -101.252297,
              29.604167
            ],
            [
              -101.244355,
              29.569187
            ],
            [
              -101.261175,
              29.536777
            ],
            [
              -101.260837,
              29.529933
            ],
            [
              -101.254895,
              29.520342
            ],
            [
              -101.235275,
              29.524854
            ],
            [
              -101.227419,
              29.52235
            ],
            [
              -101.19272,
              29.520285
            ],
            [
              -101.173821,
              29.514566
            ],
            [
              -101.171663,
              29.50395
            ],
            [
              -101.151877,
              29.477005
            ],
            [
              -101.144337,
              29.473246
            ],
            [
              -101.137503,
              29.473542
            ],
            [
              -101.130038,
              29.47842
            ],
            [
              -101.115254,
              29.468459
            ],
            [
              -101.103699,
              29.47055
            ],
            [
              -101.087149,
              29.469414
            ],
            [
              -101.060151,
              29.458661
            ],
            [
              -101.056957,
              29.440773
            ],
            [
              -101.043364,
              29.42988
            ],
            [
              -101.037642,
              29.414681
            ],
            [
              -101.0386,
              29.410714
            ],
            [
              -101.036604,
              29.406108
            ],
            [
              -101.024016,
              29.393698
            ],
            [
              -101.010614,
              29.368669
            ],
            [
              -101.004207,
              29.364772
            ],
            [
              -100.995607,
              29.363403
            ],
            [
              -100.972916,
              29.354545
            ],
            [
              -100.971743,
              29.351371
            ],
            [
              -100.964325,
              29.347342
            ],
            [
              -100.948972,
              29.347246
            ],
            [
              -100.943196,
              29.341985
            ],
            [
              -100.940615,
              29.333109
            ],
            [
              -100.926678,
              29.326584
            ],
            [
              -100.904835,
              29.31201
            ],
            [
              -100.886842,
              29.307848
            ],
            [
              -100.882052,
              29.299045
            ],
            [
              -100.878883,
              29.282193
            ],
            [
              -100.876049,
              29.279585
            ],
            [
              -100.864659,
              29.276076
            ],
            [
              -100.856469,
              29.275664
            ],
            [
              -100.848664,
              29.271421
            ],
            [
              -100.839016,
              29.263259
            ],
            [
              -100.83404,
              29.2614
            ],
            [
              -100.823533,
              29.261742
            ],
            [
              -100.797671,
              29.246943
            ],
            [
              -100.794912,
              29.242459
            ],
            [
              -100.795126802248,
              29.2417671837622
            ],
            [
              -100.774649,
              29.245932
            ],
            [
              -100.757822,
              29.238325
            ],
            [
              -100.763083,
              29.248646
            ],
            [
              -100.745564,
              29.254809
            ],
            [
              -100.739067,
              29.290399
            ],
            [
              -100.748594,
              29.304997
            ],
            [
              -100.745094,
              29.32805
            ],
            [
              -100.712195,
              29.337884
            ],
            [
              -100.706307,
              29.363912
            ],
            [
              -100.710641,
              29.369779
            ],
            [
              -100.706061,
              29.401482
            ],
            [
              -100.699141,
              29.419747
            ],
            [
              -100.699932,
              29.623897
            ],
            [
              -100.700393,
              30.288276
            ],
            [
              -100.960643,
              30.287776
            ],
            [
              -100.972067,
              30.287596
            ],
            [
              -101.005154,
              30.287333
            ],
            [
              -101.7584,
              30.288044
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "141",
      "COUNTYNS": "01383855",
      "AFFGEOID": "0500000US48141",
      "GEOID": "48141",
      "NAME": "El Paso",
      "LSAD": "06",
      "ALAND": 2624144579,
      "AWATER": 5443740,
      "County_state": "El Paso,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -106.645479,
              31.89867
            ],
            [
              -106.645646,
              31.895649
            ],
            [
              -106.645296,
              31.894859
            ],
            [
              -106.6429,
              31.892933
            ],
            [
              -106.638154,
              31.891663
            ],
            [
              -106.633927,
              31.889184
            ],
            [
              -106.630692,
              31.886411
            ],
            [
              -106.629197,
              31.883717
            ],
            [
              -106.630799,
              31.879697
            ],
            [
              -106.634873,
              31.874478
            ],
            [
              -106.63588,
              31.871514
            ],
            [
              -106.635926,
              31.866235
            ],
            [
              -106.627808,
              31.860593
            ],
            [
              -106.625763,
              31.856276
            ],
            [
              -106.621857,
              31.852854
            ],
            [
              -106.614637,
              31.84649
            ],
            [
              -106.605845,
              31.846305
            ],
            [
              -106.605245,
              31.845905
            ],
            [
              -106.602045,
              31.844405
            ],
            [
              -106.601945,
              31.839605
            ],
            [
              -106.605267,
              31.827912
            ],
            [
              -106.602727,
              31.825024
            ],
            [
              -106.593826,
              31.824901
            ],
            [
              -106.589045,
              31.822706
            ],
            [
              -106.588045,
              31.822106
            ],
            [
              -106.582144,
              31.815506
            ],
            [
              -106.581344,
              31.813906
            ],
            [
              -106.577244,
              31.810406
            ],
            [
              -106.570944,
              31.810206
            ],
            [
              -106.566844,
              31.813306
            ],
            [
              -106.563444,
              31.812606
            ],
            [
              -106.562945,
              31.811104
            ],
            [
              -106.558444,
              31.810406
            ],
            [
              -106.547144,
              31.807305
            ],
            [
              -106.54657652495901,
              31.8065805235309
            ],
            [
              -106.545344,
              31.805007
            ],
            [
              -106.544714,
              31.804287
            ],
            [
              -106.542144,
              31.802107
            ],
            [
              -106.542097,
              31.802146
            ],
            [
              -106.535843,
              31.798607
            ],
            [
              -106.535343,
              31.797507
            ],
            [
              -106.535154,
              31.797089
            ],
            [
              -106.534743,
              31.796107
            ],
            [
              -106.533043,
              31.791907
            ],
            [
              -106.533,
              31.791829
            ],
            [
              -106.53248,
              31.791914
            ],
            [
              -106.530515,
              31.792103
            ],
            [
              -106.527943,
              31.790507
            ],
            [
              -106.527738,
              31.789761
            ],
            [
              -106.527623,
              31.789119
            ],
            [
              -106.527997,
              31.786945
            ],
            [
              -106.528124,
              31.784985
            ],
            [
              -106.528242,
              31.783148
            ],
            [
              -106.528643,
              31.781807
            ],
            [
              -106.523643,
              31.776207
            ],
            [
              -106.507342,
              31.761208
            ],
            [
              -106.489542,
              31.748408
            ],
            [
              -106.484642,
              31.747809
            ],
            [
              -106.475542,
              31.750109
            ],
            [
              -106.470742,
              31.753508
            ],
            [
              -106.467642,
              31.759608
            ],
            [
              -106.451541,
              31.764808
            ],
            [
              -106.45113485771802,
              31.764592764897497
            ],
            [
              -106.431541,
              31.754209
            ],
            [
              -106.41794,
              31.752009
            ],
            [
              -106.381039,
              31.73211
            ],
            [
              -106.378039,
              31.72831
            ],
            [
              -106.373839,
              31.71481
            ],
            [
              -106.370139,
              31.71071
            ],
            [
              -106.349538,
              31.696711
            ],
            [
              -106.346989328666,
              31.6910975862575
            ],
            [
              -106.337954334501,
              31.671198135220497
            ],
            [
              -106.334737,
              31.664112
            ],
            [
              -106.303536,
              31.620413
            ],
            [
              -106.287785,
              31.584445
            ],
            [
              -106.280811,
              31.562062
            ],
            [
              -106.25478,
              31.547997
            ],
            [
              -106.246203,
              31.541153
            ],
            [
              -106.236804,
              31.513376
            ],
            [
              -106.223909,
              31.500422
            ],
            [
              -106.218843,
              31.4803
            ],
            [
              -106.205827,
              31.465976
            ],
            [
              -106.175675,
              31.456279
            ],
            [
              -106.158218,
              31.438885
            ],
            [
              -106.132782,
              31.425367
            ],
            [
              -106.112169,
              31.423578
            ],
            [
              -106.106877,
              31.421403
            ],
            [
              -106.080258,
              31.398702
            ],
            [
              -106.004926,
              31.392458
            ],
            [
              -105.997485690397,
              31.3867918276815
            ],
            [
              -105.954328,
              31.423468
            ],
            [
              -105.958548,
              31.423461
            ],
            [
              -105.959262,
              31.478417
            ],
            [
              -105.993255,
              31.478392
            ],
            [
              -105.992695,
              31.500109
            ],
            [
              -105.994756,
              31.679688
            ],
            [
              -105.99800678071601,
              32.0023280060773
            ],
            [
              -106.05045102059302,
              32.0024123076916
            ],
            [
              -106.125534,
              32.002533
            ],
            [
              -106.18184,
              32.00205
            ],
            [
              -106.200699,
              32.001785
            ],
            [
              -106.205915,
              32.001762
            ],
            [
              -106.313307,
              32.001512
            ],
            [
              -106.323469336526,
              32.001457633722204
            ],
            [
              -106.32608506721301,
              32.0014436401351
            ],
            [
              -106.376861,
              32.001172
            ],
            [
              -106.37717982050499,
              32.0011777046509
            ],
            [
              -106.394298,
              32.001484
            ],
            [
              -106.411075,
              32.001334
            ],
            [
              -106.565142,
              32.000736
            ],
            [
              -106.566056,
              32.000759
            ],
            [
              -106.582678,
              32.0007514155868
            ],
            [
              -106.587972,
              32.000749
            ],
            [
              -106.595333,
              32.000778
            ],
            [
              -106.598639,
              32.000754
            ],
            [
              -106.599096,
              32.000731
            ],
            [
              -106.618486,
              32.000495
            ],
            [
              -106.619448,
              31.994733
            ],
            [
              -106.623568,
              31.990999
            ],
            [
              -106.631182,
              31.989809
            ],
            [
              -106.636492,
              31.985719
            ],
            [
              -106.639529,
              31.980348
            ],
            [
              -106.638186,
              31.97682
            ],
            [
              -106.630114,
              31.971258
            ],
            [
              -106.626466,
              31.97069
            ],
            [
              -106.624299,
              31.961054
            ],
            [
              -106.625535,
              31.957476
            ],
            [
              -106.625123,
              31.954531
            ],
            [
              -106.622819,
              31.952891
            ],
            [
              -106.623659,
              31.94551
            ],
            [
              -106.622377,
              31.940863
            ],
            [
              -106.622117,
              31.936621
            ],
            [
              -106.622529,
              31.934863
            ],
            [
              -106.625322,
              31.930053
            ],
            [
              -106.628499124429,
              31.927552226127002
            ],
            [
              -106.629747,
              31.92657
            ],
            [
              -106.628663,
              31.923614
            ],
            [
              -106.623445,
              31.914034
            ],
            [
              -106.625947,
              31.912227
            ],
            [
              -106.633668,
              31.90979
            ],
            [
              -106.64084,
              31.904598
            ],
            [
              -106.645479,
              31.89867
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "081",
      "COUNTYNS": "01383826",
      "AFFGEOID": "0500000US48081",
      "GEOID": "48081",
      "NAME": "Coke",
      "LSAD": "06",
      "ALAND": 2360696264,
      "AWATER": 42788774,
      "County_state": "Coke,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.821594,
              32.08661
            ],
            [
              -100.82537,
              31.696155
            ],
            [
              -100.235761,
              31.69297
            ],
            [
              -100.235137,
              32.082372
            ],
            [
              -100.250373,
              32.081592
            ],
            [
              -100.665353,
              32.085407
            ],
            [
              -100.821594,
              32.08661
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "061",
      "COUNTYNS": "01383816",
      "AFFGEOID": "0500000US48061",
      "GEOID": "48061",
      "NAME": "Cameron",
      "LSAD": "06",
      "ALAND": 2307897628,
      "AWATER": 998028553,
      "County_state": "Cameron,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.22523655679909,
              26.411526542410698
            ],
            [
              -97.24466398494118,
              26.411496535115198
            ],
            [
              -97.22512,
              26.344513
            ],
            [
              -97.201352,
              26.249691
            ],
            [
              -97.183051,
              26.123267
            ],
            [
              -97.169317,
              26.077714
            ],
            [
              -97.158798,
              26.08266
            ],
            [
              -97.171169,
              26.162181
            ],
            [
              -97.196935,
              26.305865
            ],
            [
              -97.221585,
              26.399812
            ],
            [
              -97.22523655679909,
              26.411526542410698
            ]
          ]
        ],
        [
          [
            [
              -97.861776,
              26.070057
            ],
            [
              -97.8682353660958,
              26.0566559486928
            ],
            [
              -97.860504,
              26.052918
            ],
            [
              -97.836608,
              26.051651
            ],
            [
              -97.825546,
              26.055702
            ],
            [
              -97.801344,
              26.060017
            ],
            [
              -97.795291,
              26.055218
            ],
            [
              -97.792253,
              26.044282
            ],
            [
              -97.789823,
              26.04246
            ],
            [
              -97.784051,
              26.040637
            ],
            [
              -97.779191,
              26.042763
            ],
            [
              -97.770077,
              26.041548
            ],
            [
              -97.764913,
              26.037903
            ],
            [
              -97.763091,
              26.03365
            ],
            [
              -97.758838,
              26.032131
            ],
            [
              -97.71992,
              26.030838
            ],
            [
              -97.711145,
              26.033043
            ],
            [
              -97.703247,
              26.030309
            ],
            [
              -97.697069,
              26.023455
            ],
            [
              -97.671351,
              26.024233
            ],
            [
              -97.668298,
              26.019956
            ],
            [
              -97.661326,
              26.019373
            ],
            [
              -97.649176,
              26.021499
            ],
            [
              -97.643708,
              26.016943
            ],
            [
              -97.644011,
              26.006614
            ],
            [
              -97.634804,
              25.999509
            ],
            [
              -97.627226,
              25.996727
            ],
            [
              -97.608283,
              25.976594
            ],
            [
              -97.607734,
              25.972745
            ],
            [
              -97.598043,
              25.957556
            ],
            [
              -97.583044,
              25.955443
            ],
            [
              -97.580419,
              25.945116
            ],
            [
              -97.582565,
              25.937857
            ],
            [
              -97.559364,
              25.932257
            ],
            [
              -97.555379,
              25.937136
            ],
            [
              -97.546421,
              25.934077
            ],
            [
              -97.54517,
              25.923975
            ],
            [
              -97.542957,
              25.920035
            ],
            [
              -97.530322,
              25.916797
            ],
            [
              -97.528628,
              25.905219
            ],
            [
              -97.5245463646236,
              25.8940661364263
            ],
            [
              -97.521762,
              25.886458
            ],
            [
              -97.496861,
              25.880058
            ],
            [
              -97.48606,
              25.878758
            ],
            [
              -97.468262,
              25.884162
            ],
            [
              -97.454727,
              25.879337
            ],
            [
              -97.449172,
              25.871678
            ],
            [
              -97.448271,
              25.859463
            ],
            [
              -97.445113,
              25.850026
            ],
            [
              -97.422636,
              25.840378
            ],
            [
              -97.394513,
              25.837377
            ],
            [
              -97.372864,
              25.840117
            ],
            [
              -97.36542,
              25.849826
            ],
            [
              -97.360082,
              25.868874
            ],
            [
              -97.365521,
              25.890476
            ],
            [
              -97.365976,
              25.902447
            ],
            [
              -97.372365,
              25.905016
            ],
            [
              -97.37443,
              25.907444
            ],
            [
              -97.367642,
              25.91568
            ],
            [
              -97.350398,
              25.925241
            ],
            [
              -97.338346,
              25.923125
            ],
            [
              -97.324914,
              25.924041
            ],
            [
              -97.320561,
              25.929802
            ],
            [
              -97.303602,
              25.936704
            ],
            [
              -97.277163,
              25.935438
            ],
            [
              -97.281389,
              25.948037
            ],
            [
              -97.276707,
              25.952147
            ],
            [
              -97.248033,
              25.948097
            ],
            [
              -97.239867,
              25.954974
            ],
            [
              -97.229226,
              25.958753
            ],
            [
              -97.206945,
              25.960899
            ],
            [
              -97.187583,
              25.958174
            ],
            [
              -97.178362,
              25.962114
            ],
            [
              -97.168199,
              25.959262
            ],
            [
              -97.160294,
              25.950243
            ],
            [
              -97.156608,
              25.949022
            ],
            [
              -97.147785,
              25.953132
            ],
            [
              -97.146294,
              25.955606
            ],
            [
              -97.146881,
              25.969781
            ],
            [
              -97.145567,
              25.971132
            ],
            [
              -97.151922,
              26.017653
            ],
            [
              -97.15321,
              26.038906
            ],
            [
              -97.152009,
              26.062108
            ],
            [
              -97.164982,
              26.063876
            ],
            [
              -97.18273,
              26.053126
            ],
            [
              -97.195675,
              26.046559
            ],
            [
              -97.199651,
              26.077044
            ],
            [
              -97.208048,
              26.079589
            ],
            [
              -97.24698,
              26.080352
            ],
            [
              -97.270898,
              26.086459
            ],
            [
              -97.279804,
              26.092057
            ],
            [
              -97.290588,
              26.104776
            ],
            [
              -97.284527,
              26.11502
            ],
            [
              -97.28536,
              26.128378
            ],
            [
              -97.298907,
              26.147562
            ],
            [
              -97.299976,
              26.163242
            ],
            [
              -97.296089,
              26.182388
            ],
            [
              -97.298817,
              26.20121
            ],
            [
              -97.311333,
              26.213177
            ],
            [
              -97.310566,
              26.23057
            ],
            [
              -97.293797,
              26.258987
            ],
            [
              -97.279892,
              26.270923
            ],
            [
              -97.297005,
              26.296069
            ],
            [
              -97.330281,
              26.36594
            ],
            [
              -97.3502357018891,
              26.4114389750309
            ],
            [
              -97.390733,
              26.411332
            ],
            [
              -97.400779,
              26.38652
            ],
            [
              -97.421294,
              26.366138
            ],
            [
              -97.430051,
              26.364337
            ],
            [
              -97.437276,
              26.337233
            ],
            [
              -97.446987,
              26.324947
            ],
            [
              -97.466457,
              26.333952
            ],
            [
              -97.467876,
              26.342902
            ],
            [
              -97.479342,
              26.335871
            ],
            [
              -97.509035,
              26.333581
            ],
            [
              -97.500824,
              26.318852
            ],
            [
              -97.52401,
              26.32355
            ],
            [
              -97.527582,
              26.299304
            ],
            [
              -97.575868,
              26.306249
            ],
            [
              -97.861875,
              26.348156
            ],
            [
              -97.861775,
              26.230051
            ],
            [
              -97.861875,
              26.129972
            ],
            [
              -97.861875,
              26.120295
            ],
            [
              -97.861776,
              26.070057
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "473",
      "COUNTYNS": "01384022",
      "AFFGEOID": "0500000US48473",
      "GEOID": "48473",
      "NAME": "Waller",
      "LSAD": "06",
      "ALAND": 1329535289,
      "AWATER": 11622560,
      "County_state": "Waller,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.191782,
              30.13842
            ],
            [
              -96.175046,
              30.112435
            ],
            [
              -96.172823,
              30.099343
            ],
            [
              -96.160271,
              30.108066
            ],
            [
              -96.152557,
              30.102535
            ],
            [
              -96.158421,
              30.091709
            ],
            [
              -96.143502,
              30.084979
            ],
            [
              -96.13378,
              30.07096
            ],
            [
              -96.146052,
              30.070224
            ],
            [
              -96.140629,
              30.058225
            ],
            [
              -96.117214,
              30.050126
            ],
            [
              -96.107465,
              30.038618
            ],
            [
              -96.113221,
              30.026997
            ],
            [
              -96.09471,
              30.025384
            ],
            [
              -96.084541,
              30.005137
            ],
            [
              -96.104516,
              29.996378
            ],
            [
              -96.095703,
              29.978139
            ],
            [
              -96.105258,
              29.959172
            ],
            [
              -96.122754,
              29.968545
            ],
            [
              -96.11946,
              29.955017
            ],
            [
              -96.106352,
              29.9555
            ],
            [
              -96.100796,
              29.943526
            ],
            [
              -96.121487,
              29.949189
            ],
            [
              -96.13165,
              29.937504
            ],
            [
              -96.113357,
              29.915565
            ],
            [
              -96.126267,
              29.89885
            ],
            [
              -96.095808,
              29.876676
            ],
            [
              -96.112227,
              29.872557
            ],
            [
              -96.101657,
              29.853963
            ],
            [
              -96.116245,
              29.847506
            ],
            [
              -96.121405,
              29.836652
            ],
            [
              -96.107925,
              29.82228
            ],
            [
              -96.098988,
              29.827382
            ],
            [
              -96.102496,
              29.809665
            ],
            [
              -96.082275,
              29.804733
            ],
            [
              -96.049234,
              29.803187
            ],
            [
              -96.041365,
              29.792443
            ],
            [
              -96.043009,
              29.779129
            ],
            [
              -96.029596,
              29.754666
            ],
            [
              -96.039844,
              29.737452
            ],
            [
              -96.032711,
              29.727944
            ],
            [
              -95.97094,
              29.728554
            ],
            [
              -95.971287,
              29.740454
            ],
            [
              -95.950931,
              29.740769
            ],
            [
              -95.82622,
              29.78828
            ],
            [
              -95.868966,
              29.908828
            ],
            [
              -95.960615,
              30.163628
            ],
            [
              -95.944505,
              30.148168
            ],
            [
              -95.929056,
              30.144175
            ],
            [
              -95.911082,
              30.128107
            ],
            [
              -95.890804,
              30.119019
            ],
            [
              -95.874053,
              30.122225
            ],
            [
              -95.856902,
              30.101798
            ],
            [
              -95.846649,
              30.100499
            ],
            [
              -95.844741,
              30.088914
            ],
            [
              -95.828395,
              30.081723
            ],
            [
              -95.80333,
              30.090096
            ],
            [
              -95.804306,
              30.24557
            ],
            [
              -95.923283,
              30.237284
            ],
            [
              -95.953773,
              30.236964
            ],
            [
              -96.088941,
              30.228718
            ],
            [
              -96.093165,
              30.225187
            ],
            [
              -96.098936,
              30.214525
            ],
            [
              -96.113704,
              30.227203
            ],
            [
              -96.128228,
              30.211605
            ],
            [
              -96.145075,
              30.218566
            ],
            [
              -96.15901,
              30.206613
            ],
            [
              -96.154562,
              30.177124
            ],
            [
              -96.160031,
              30.161829
            ],
            [
              -96.150126,
              30.155445
            ],
            [
              -96.154866,
              30.141373
            ],
            [
              -96.176289,
              30.154821
            ],
            [
              -96.183153,
              30.15071
            ],
            [
              -96.171942,
              30.14116
            ],
            [
              -96.191782,
              30.13842
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "025",
      "COUNTYNS": "01383798",
      "AFFGEOID": "0500000US48025",
      "GEOID": "48025",
      "NAME": "Bee",
      "LSAD": "06",
      "ALAND": 2279809504,
      "AWATER": 246409,
      "County_state": "Bee,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.005252,
              28.690239
            ],
            [
              -98.089764,
              28.662979
            ],
            [
              -97.808774,
              28.183415
            ],
            [
              -97.817716,
              28.176848
            ],
            [
              -97.568482,
              28.129846
            ],
            [
              -97.567622,
              28.129683
            ],
            [
              -97.541071,
              28.164669
            ],
            [
              -97.375579,
              28.388684
            ],
            [
              -97.397882,
              28.403038
            ],
            [
              -97.420547,
              28.404182
            ],
            [
              -97.428292,
              28.412875
            ],
            [
              -97.459265,
              28.407499
            ],
            [
              -97.4805,
              28.433889
            ],
            [
              -97.4937,
              28.438836
            ],
            [
              -97.520292,
              28.433508
            ],
            [
              -97.520847,
              28.446414
            ],
            [
              -97.551369,
              28.450441
            ],
            [
              -97.55259,
              28.494326
            ],
            [
              -97.558496,
              28.514054
            ],
            [
              -97.577058,
              28.522547
            ],
            [
              -97.610401,
              28.523233
            ],
            [
              -97.622264,
              28.529357
            ],
            [
              -97.645742,
              28.520763
            ],
            [
              -97.696572,
              28.541537
            ],
            [
              -97.701888,
              28.561194
            ],
            [
              -97.732125,
              28.628833
            ],
            [
              -97.750607,
              28.639235
            ],
            [
              -97.7554,
              28.655037
            ],
            [
              -97.77853,
              28.668027
            ],
            [
              -97.795231,
              28.673398
            ],
            [
              -97.825312,
              28.670341
            ],
            [
              -97.830111,
              28.678079
            ],
            [
              -97.858368,
              28.684344
            ],
            [
              -97.893402,
              28.700128
            ],
            [
              -97.891815,
              28.703097
            ],
            [
              -97.915106,
              28.719648
            ],
            [
              -98.005252,
              28.690239
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "449",
      "COUNTYNS": "01384010",
      "AFFGEOID": "0500000US48449",
      "GEOID": "48449",
      "NAME": "Titus",
      "LSAD": "06",
      "ALAND": 1051683607,
      "AWATER": 50727822,
      "County_state": "Titus,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.125451,
              33.389454
            ],
            [
              -95.12613,
              33.034581
            ],
            [
              -95.113617,
              33.030509
            ],
            [
              -95.094577,
              33.037565
            ],
            [
              -95.079146,
              33.051372
            ],
            [
              -95.060703,
              33.054622
            ],
            [
              -95.043543,
              33.069814
            ],
            [
              -95.014203,
              33.076045
            ],
            [
              -94.972276,
              33.071925
            ],
            [
              -94.935679,
              33.073684
            ],
            [
              -94.926546,
              33.069287
            ],
            [
              -94.921469,
              33.050725
            ],
            [
              -94.901905,
              33.041597
            ],
            [
              -94.890578,
              33.025323
            ],
            [
              -94.864374,
              33.014951
            ],
            [
              -94.854106,
              33.003642
            ],
            [
              -94.819828,
              32.982886
            ],
            [
              -94.808783,
              33.363636
            ],
            [
              -94.81271,
              33.375577
            ],
            [
              -94.830154,
              33.382776
            ],
            [
              -94.857586,
              33.3767
            ],
            [
              -94.875984,
              33.394193
            ],
            [
              -94.88977,
              33.395023
            ],
            [
              -94.908777,
              33.383785
            ],
            [
              -94.953056,
              33.390839
            ],
            [
              -94.979317,
              33.387099
            ],
            [
              -95.017127,
              33.390949
            ],
            [
              -95.024605,
              33.384127
            ],
            [
              -95.049583,
              33.382983
            ],
            [
              -95.057614,
              33.391576
            ],
            [
              -95.074208,
              33.385894
            ],
            [
              -95.093348,
              33.397571
            ],
            [
              -95.125451,
              33.389454
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "063",
      "COUNTYNS": "01383817",
      "AFFGEOID": "0500000US48063",
      "GEOID": "48063",
      "NAME": "Camp",
      "LSAD": "06",
      "ALAND": 507184763,
      "AWATER": 19186958,
      "County_state": "Camp,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.152206,
              33.01345
            ],
            [
              -95.15019,
              33.009312
            ],
            [
              -95.15211,
              32.902641
            ],
            [
              -94.719942,
              32.904502
            ],
            [
              -94.768855,
              32.925834
            ],
            [
              -94.790304,
              32.96224
            ],
            [
              -94.819828,
              32.982886
            ],
            [
              -94.854106,
              33.003642
            ],
            [
              -94.864374,
              33.014951
            ],
            [
              -94.890578,
              33.025323
            ],
            [
              -94.901905,
              33.041597
            ],
            [
              -94.921469,
              33.050725
            ],
            [
              -94.926546,
              33.069287
            ],
            [
              -94.935679,
              33.073684
            ],
            [
              -94.972276,
              33.071925
            ],
            [
              -95.014203,
              33.076045
            ],
            [
              -95.043543,
              33.069814
            ],
            [
              -95.060703,
              33.054622
            ],
            [
              -95.079146,
              33.051372
            ],
            [
              -95.094577,
              33.037565
            ],
            [
              -95.113617,
              33.030509
            ],
            [
              -95.12613,
              33.034581
            ],
            [
              -95.137213,
              33.030938
            ],
            [
              -95.152206,
              33.01345
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "185",
      "COUNTYNS": "01383878",
      "AFFGEOID": "0500000US48185",
      "GEOID": "48185",
      "NAME": "Grimes",
      "LSAD": "06",
      "ALAND": 2039539549,
      "AWATER": 36603391,
      "County_state": "Grimes,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.188305,
              30.599335
            ],
            [
              -96.182286,
              30.586251
            ],
            [
              -96.161918,
              30.570559
            ],
            [
              -96.161536,
              30.541834
            ],
            [
              -96.148262,
              30.528146
            ],
            [
              -96.152643,
              30.520855
            ],
            [
              -96.1321,
              30.49054
            ],
            [
              -96.139903,
              30.472482
            ],
            [
              -96.129431,
              30.465829
            ],
            [
              -96.121806,
              30.443426
            ],
            [
              -96.104673,
              30.444007
            ],
            [
              -96.079969,
              30.43003
            ],
            [
              -96.087926,
              30.417293
            ],
            [
              -96.106341,
              30.421933
            ],
            [
              -96.108193,
              30.406208
            ],
            [
              -96.12174,
              30.383893
            ],
            [
              -96.147255,
              30.38284
            ],
            [
              -96.141609,
              30.36551
            ],
            [
              -96.148465,
              30.345
            ],
            [
              -96.139812,
              30.340935
            ],
            [
              -96.15463,
              30.330288
            ],
            [
              -96.120477,
              30.305689
            ],
            [
              -96.094404,
              30.309371
            ],
            [
              -96.10058,
              30.292805
            ],
            [
              -96.085893,
              30.288983
            ],
            [
              -96.09912,
              30.255174
            ],
            [
              -96.084578,
              30.251253
            ],
            [
              -96.087671,
              30.23777
            ],
            [
              -96.105379,
              30.23287
            ],
            [
              -96.093165,
              30.225187
            ],
            [
              -96.088941,
              30.228718
            ],
            [
              -95.953773,
              30.236964
            ],
            [
              -95.923283,
              30.237284
            ],
            [
              -95.804306,
              30.24557
            ],
            [
              -95.804335,
              30.314428
            ],
            [
              -95.804351,
              30.3378
            ],
            [
              -95.817478,
              30.500218
            ],
            [
              -95.83024,
              30.630284
            ],
            [
              -95.863059,
              30.864125
            ],
            [
              -96.125235,
              30.826603
            ],
            [
              -96.16837,
              30.822997
            ],
            [
              -96.173851,
              30.801121
            ],
            [
              -96.183355,
              30.797273
            ],
            [
              -96.162644,
              30.769509
            ],
            [
              -96.170145,
              30.748855
            ],
            [
              -96.162587,
              30.739797
            ],
            [
              -96.164522,
              30.722083
            ],
            [
              -96.177911,
              30.709688
            ],
            [
              -96.172736,
              30.679843
            ],
            [
              -96.164099,
              30.658813
            ],
            [
              -96.188305,
              30.599335
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "415",
      "COUNTYNS": "01383993",
      "AFFGEOID": "0500000US48415",
      "GEOID": "48415",
      "NAME": "Scurry",
      "LSAD": "06",
      "ALAND": 2345090489,
      "AWATER": 5436958,
      "County_state": "Scurry,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.173378,
              32.963597
            ],
            [
              -101.174562,
              32.527703
            ],
            [
              -100.660626,
              32.525312
            ],
            [
              -100.65587,
              32.963469
            ],
            [
              -100.990181,
              32.965023
            ],
            [
              -101.03866,
              32.970225
            ],
            [
              -101.173378,
              32.963597
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "249",
      "COUNTYNS": "01383910",
      "AFFGEOID": "0500000US48249",
      "GEOID": "48249",
      "NAME": "Jim Wells",
      "LSAD": "06",
      "ALAND": 2240778091,
      "AWATER": 8739522,
      "County_state": "Jim Wells,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.235497,
              28.057966
            ],
            [
              -98.232464,
              27.262487
            ],
            [
              -98.058078,
              27.260981
            ],
            [
              -98.0598,
              27.635869
            ],
            [
              -97.942146,
              27.635932
            ],
            [
              -97.933665,
              27.776442
            ],
            [
              -97.934274,
              27.885202
            ],
            [
              -97.798517,
              27.995659
            ],
            [
              -97.789154,
              28.013589
            ],
            [
              -97.801388,
              28.03836
            ],
            [
              -97.838918,
              28.034613
            ],
            [
              -97.867548,
              28.040986
            ],
            [
              -97.883148,
              28.0569
            ],
            [
              -98.235497,
              28.057966
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "135",
      "COUNTYNS": "01383853",
      "AFFGEOID": "0500000US48135",
      "GEOID": "48135",
      "NAME": "Ector",
      "LSAD": "06",
      "ALAND": 2325100483,
      "AWATER": 10596946,
      "County_state": "Ector,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.799086,
              32.085795
            ],
            [
              -102.798939,
              31.651783
            ],
            [
              -102.767246,
              31.651714
            ],
            [
              -102.31805,
              31.651327
            ],
            [
              -102.287345,
              31.651276
            ],
            [
              -102.287048,
              32.086991
            ],
            [
              -102.799086,
              32.085795
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "379",
      "COUNTYNS": "01383975",
      "AFFGEOID": "0500000US48379",
      "GEOID": "48379",
      "NAME": "Rains",
      "LSAD": "06",
      "ALAND": 594280566,
      "AWATER": 76080004,
      "County_state": "Rains,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.978133,
              32.886237
            ],
            [
              -95.987259,
              32.876415
            ],
            [
              -95.956777,
              32.845207
            ],
            [
              -95.934433,
              32.837217
            ],
            [
              -95.922362,
              32.815557
            ],
            [
              -95.906737,
              32.806858
            ],
            [
              -95.895117,
              32.803846
            ],
            [
              -95.863949,
              32.777061
            ],
            [
              -95.829581,
              32.766967
            ],
            [
              -95.795966,
              32.775318
            ],
            [
              -95.761537,
              32.770314
            ],
            [
              -95.76434,
              32.759558
            ],
            [
              -95.733321,
              32.752744
            ],
            [
              -95.733043,
              32.746102
            ],
            [
              -95.710451,
              32.745579
            ],
            [
              -95.677538,
              32.727769
            ],
            [
              -95.659803,
              32.711402
            ],
            [
              -95.635017,
              32.72038
            ],
            [
              -95.665389,
              32.960434
            ],
            [
              -95.667728,
              32.979554
            ],
            [
              -95.862521,
              32.979571
            ],
            [
              -95.9453,
              32.979877
            ],
            [
              -95.978133,
              32.886237
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "367",
      "COUNTYNS": "01383969",
      "AFFGEOID": "0500000US48367",
      "GEOID": "48367",
      "NAME": "Parker",
      "LSAD": "06",
      "ALAND": 2340006395,
      "AWATER": 17127713,
      "County_state": "Parker,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.056094,
              33.003332
            ],
            [
              -98.066836,
              32.558822
            ],
            [
              -97.617066,
              32.555484
            ],
            [
              -97.550582,
              32.555391
            ],
            [
              -97.548201,
              32.776114
            ],
            [
              -97.544181,
              32.994177
            ],
            [
              -97.548599,
              32.994825
            ],
            [
              -97.679565,
              32.996373
            ],
            [
              -97.921642,
              33.001284
            ],
            [
              -98.056094,
              33.003332
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "503",
      "COUNTYNS": "01384037",
      "AFFGEOID": "0500000US48503",
      "GEOID": "48503",
      "NAME": "Young",
      "LSAD": "06",
      "ALAND": 2368463881,
      "AWATER": 42447713,
      "County_state": "Young,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.953938,
              33.397534
            ],
            [
              -98.950875,
              32.956918
            ],
            [
              -98.575616,
              32.954349
            ],
            [
              -98.427211,
              32.9523
            ],
            [
              -98.426553,
              33.007913
            ],
            [
              -98.420666,
              33.396195
            ],
            [
              -98.953938,
              33.397534
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "373",
      "COUNTYNS": "01383972",
      "AFFGEOID": "0500000US48373",
      "GEOID": "48373",
      "NAME": "Polk",
      "LSAD": "06",
      "ALAND": 2737733230,
      "AWATER": 136271619,
      "County_state": "Polk,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.20018,
              30.824566
            ],
            [
              -95.180374,
              30.818426
            ],
            [
              -95.17404,
              30.807583
            ],
            [
              -95.156846,
              30.800427
            ],
            [
              -95.131205,
              30.781703
            ],
            [
              -95.139382,
              30.776197
            ],
            [
              -95.131306,
              30.759421
            ],
            [
              -95.144771,
              30.744036
            ],
            [
              -95.145426,
              30.713799
            ],
            [
              -95.135384,
              30.693816
            ],
            [
              -95.10955,
              30.692748
            ],
            [
              -95.118919,
              30.675632
            ],
            [
              -95.11625,
              30.66426
            ],
            [
              -95.104841,
              30.657878
            ],
            [
              -95.088352,
              30.672062
            ],
            [
              -95.079278,
              30.669415
            ],
            [
              -95.054602,
              30.680173
            ],
            [
              -95.053638,
              30.671241
            ],
            [
              -95.038606,
              30.675766
            ],
            [
              -95.041038,
              30.663766
            ],
            [
              -95.009112,
              30.65456
            ],
            [
              -95.024663,
              30.649779
            ],
            [
              -95.015874,
              30.627077
            ],
            [
              -95.00895,
              30.627699
            ],
            [
              -95.008529,
              30.606106
            ],
            [
              -95.035491,
              30.585647
            ],
            [
              -95.029744,
              30.579138
            ],
            [
              -95.003474,
              30.580151
            ],
            [
              -94.965729,
              30.576586
            ],
            [
              -94.950297,
              30.571014
            ],
            [
              -94.940266,
              30.55947
            ],
            [
              -94.910452,
              30.561514
            ],
            [
              -94.889567,
              30.556361
            ],
            [
              -94.875209,
              30.546067
            ],
            [
              -94.842876,
              30.546152
            ],
            [
              -94.829984,
              30.541112
            ],
            [
              -94.833374,
              30.530522
            ],
            [
              -94.852581,
              30.524354
            ],
            [
              -94.867393,
              30.510755
            ],
            [
              -94.865917,
              30.495998
            ],
            [
              -94.849414,
              30.493553
            ],
            [
              -94.777341,
              30.489002
            ],
            [
              -94.732732,
              30.490066
            ],
            [
              -94.537926,
              30.491059
            ],
            [
              -94.545717,
              30.526977
            ],
            [
              -94.657992,
              31.012007
            ],
            [
              -94.561943,
              31.058952
            ],
            [
              -94.561602,
              31.063014
            ],
            [
              -94.598918,
              31.06856
            ],
            [
              -94.619554,
              31.086264
            ],
            [
              -94.643132,
              31.088085
            ],
            [
              -94.649011,
              31.098997
            ],
            [
              -94.657937,
              31.084917
            ],
            [
              -94.673013,
              31.095831
            ],
            [
              -94.698351,
              31.096246
            ],
            [
              -94.718618,
              31.106994
            ],
            [
              -94.738594,
              31.10369
            ],
            [
              -94.842947,
              31.146578
            ],
            [
              -94.939127,
              31.042048
            ],
            [
              -95.20018,
              30.824566
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "005",
      "COUNTYNS": "01383788",
      "AFFGEOID": "0500000US48005",
      "GEOID": "48005",
      "NAME": "Angelina",
      "LSAD": "06",
      "ALAND": 2066241599,
      "AWATER": 173338077,
      "County_state": "Angelina,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.003345,
              31.42571
            ],
            [
              -95.005525,
              31.421076
            ],
            [
              -94.976033,
              31.407744
            ],
            [
              -94.95811,
              31.38693
            ],
            [
              -94.958798,
              31.366058
            ],
            [
              -94.952711,
              31.358174
            ],
            [
              -94.96109,
              31.348837
            ],
            [
              -94.947587,
              31.338589
            ],
            [
              -94.909502,
              31.337059
            ],
            [
              -94.899017,
              31.326972
            ],
            [
              -94.901546,
              31.313256
            ],
            [
              -94.894681,
              31.291708
            ],
            [
              -94.886168,
              31.295661
            ],
            [
              -94.876713,
              31.283394
            ],
            [
              -94.887995,
              31.276501
            ],
            [
              -94.897678,
              31.258011
            ],
            [
              -94.874546,
              31.249647
            ],
            [
              -94.860501,
              31.234955
            ],
            [
              -94.862887,
              31.200992
            ],
            [
              -94.850074,
              31.178553
            ],
            [
              -94.849959,
              31.154883
            ],
            [
              -94.842947,
              31.146578
            ],
            [
              -94.738594,
              31.10369
            ],
            [
              -94.718618,
              31.106994
            ],
            [
              -94.698351,
              31.096246
            ],
            [
              -94.673013,
              31.095831
            ],
            [
              -94.657937,
              31.084917
            ],
            [
              -94.649011,
              31.098997
            ],
            [
              -94.643132,
              31.088085
            ],
            [
              -94.619554,
              31.086264
            ],
            [
              -94.598918,
              31.06856
            ],
            [
              -94.561602,
              31.063014
            ],
            [
              -94.561943,
              31.058952
            ],
            [
              -94.533726,
              31.056825
            ],
            [
              -94.508927,
              31.043271
            ],
            [
              -94.486152,
              31.039333
            ],
            [
              -94.472704,
              31.02609
            ],
            [
              -94.457816,
              31.033325
            ],
            [
              -94.129632,
              31.09928
            ],
            [
              -94.143878,
              31.114756
            ],
            [
              -94.192872,
              31.136166
            ],
            [
              -94.202915,
              31.153972
            ],
            [
              -94.219556,
              31.164419
            ],
            [
              -94.218997,
              31.173265
            ],
            [
              -94.250572,
              31.18828
            ],
            [
              -94.262206,
              31.180334
            ],
            [
              -94.269863,
              31.19624
            ],
            [
              -94.287885,
              31.20133
            ],
            [
              -94.29445,
              31.212649
            ],
            [
              -94.326616,
              31.224754
            ],
            [
              -94.347664,
              31.236953
            ],
            [
              -94.357426,
              31.259372
            ],
            [
              -94.383027,
              31.274109
            ],
            [
              -94.385391,
              31.290009
            ],
            [
              -94.396017,
              31.302356
            ],
            [
              -94.449422,
              31.321337
            ],
            [
              -94.470788,
              31.372324
            ],
            [
              -94.469789,
              31.383974
            ],
            [
              -94.487145,
              31.390788
            ],
            [
              -94.495874,
              31.405728
            ],
            [
              -94.515745,
              31.409344
            ],
            [
              -94.53136,
              31.39854
            ],
            [
              -94.542404,
              31.404967
            ],
            [
              -94.545121,
              31.431855
            ],
            [
              -94.581452,
              31.433189
            ],
            [
              -94.584339,
              31.444247
            ],
            [
              -94.628994,
              31.440258
            ],
            [
              -94.660266,
              31.452354
            ],
            [
              -94.662844,
              31.458798
            ],
            [
              -94.686349,
              31.450817
            ],
            [
              -94.728679,
              31.457226
            ],
            [
              -94.753006,
              31.470277
            ],
            [
              -94.765363,
              31.469938
            ],
            [
              -94.780864,
              31.481384
            ],
            [
              -94.799217,
              31.482177
            ],
            [
              -94.804291,
              31.490404
            ],
            [
              -94.82287,
              31.483978
            ],
            [
              -94.840041,
              31.507721
            ],
            [
              -94.853066,
              31.508472
            ],
            [
              -94.865857,
              31.526916
            ],
            [
              -95.003345,
              31.42571
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "297",
      "COUNTYNS": "01383934",
      "AFFGEOID": "0500000US48297",
      "GEOID": "48297",
      "NAME": "Live Oak",
      "LSAD": "06",
      "ALAND": 2692796908,
      "AWATER": 101483817,
      "County_state": "Live Oak,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.335031,
              28.612658
            ],
            [
              -98.334323,
              28.0578
            ],
            [
              -98.235497,
              28.057966
            ],
            [
              -97.883148,
              28.0569
            ],
            [
              -97.901222,
              28.067837
            ],
            [
              -97.895441,
              28.075869
            ],
            [
              -97.908774,
              28.091866
            ],
            [
              -97.898116,
              28.099446
            ],
            [
              -97.904081,
              28.114228
            ],
            [
              -97.817716,
              28.176848
            ],
            [
              -97.808774,
              28.183415
            ],
            [
              -98.089764,
              28.662979
            ],
            [
              -98.005252,
              28.690239
            ],
            [
              -98.098315,
              28.786949
            ],
            [
              -98.335031,
              28.612658
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "115",
      "COUNTYNS": "01383843",
      "AFFGEOID": "0500000US48115",
      "GEOID": "48115",
      "NAME": "Dawson",
      "LSAD": "06",
      "ALAND": 2331781556,
      "AWATER": 4720730,
      "County_state": "Dawson,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.20852,
              32.958956
            ],
            [
              -102.202703,
              32.523271
            ],
            [
              -101.68874,
              32.525222
            ],
            [
              -101.691284,
              32.961838
            ],
            [
              -102.076214,
              32.959702
            ],
            [
              -102.20852,
              32.958956
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "207",
      "COUNTYNS": "01383889",
      "AFFGEOID": "0500000US48207",
      "GEOID": "48207",
      "NAME": "Haskell",
      "LSAD": "06",
      "ALAND": 2339103815,
      "AWATER": 18465208,
      "County_state": "Haskell,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.99098,
              33.397404
            ],
            [
              -99.988827,
              32.960121
            ],
            [
              -99.612001,
              32.956962
            ],
            [
              -99.471258,
              32.957025
            ],
            [
              -99.472444,
              33.399023
            ],
            [
              -99.99098,
              33.397404
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "445",
      "COUNTYNS": "01384008",
      "AFFGEOID": "0500000US48445",
      "GEOID": "48445",
      "NAME": "Terry",
      "LSAD": "06",
      "ALAND": 2302083289,
      "AWATER": 5443861,
      "County_state": "Terry,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.594836,
              33.388489
            ],
            [
              -102.595023,
              32.958831
            ],
            [
              -102.20852,
              32.958956
            ],
            [
              -102.076214,
              32.959702
            ],
            [
              -102.075929,
              33.389586
            ],
            [
              -102.594836,
              33.388489
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "009",
      "COUNTYNS": "01383790",
      "AFFGEOID": "0500000US48009",
      "GEOID": "48009",
      "NAME": "Archer",
      "LSAD": "06",
      "ALAND": 2339055662,
      "AWATER": 57765519,
      "County_state": "Archer,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.953088,
              33.834
            ],
            [
              -98.953938,
              33.397534
            ],
            [
              -98.420666,
              33.396195
            ],
            [
              -98.421419,
              33.466797
            ],
            [
              -98.423577,
              33.836047
            ],
            [
              -98.953088,
              33.834
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "213",
      "COUNTYNS": "01383892",
      "AFFGEOID": "0500000US48213",
      "GEOID": "48213",
      "NAME": "Henderson",
      "LSAD": "06",
      "ALAND": 2263037771,
      "AWATER": 193234810,
      "County_state": "Henderson,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.452138,
              32.358631
            ],
            [
              -96.451474,
              32.345574
            ],
            [
              -96.436293,
              32.345413
            ],
            [
              -96.408118,
              32.328502
            ],
            [
              -96.383082,
              32.328851
            ],
            [
              -96.367452,
              32.317871
            ],
            [
              -96.339016,
              32.305973
            ],
            [
              -96.312635,
              32.30313
            ],
            [
              -96.284013,
              32.279225
            ],
            [
              -96.285895,
              32.25598
            ],
            [
              -96.267667,
              32.259202
            ],
            [
              -96.262248,
              32.249739
            ],
            [
              -96.233967,
              32.250009
            ],
            [
              -96.229373,
              32.236182
            ],
            [
              -96.196965,
              32.206942
            ],
            [
              -96.175848,
              32.203939
            ],
            [
              -96.155854,
              32.21501
            ],
            [
              -96.146799,
              32.211937
            ],
            [
              -96.159453,
              32.205206
            ],
            [
              -96.157907,
              32.191334
            ],
            [
              -96.176477,
              32.181379
            ],
            [
              -96.17584,
              32.168241
            ],
            [
              -96.166975,
              32.158672
            ],
            [
              -96.138407,
              32.151417
            ],
            [
              -96.113161,
              32.160701
            ],
            [
              -96.102421,
              32.142269
            ],
            [
              -96.114699,
              32.126487
            ],
            [
              -96.11153,
              32.115803
            ],
            [
              -96.117726,
              32.094632
            ],
            [
              -96.105364,
              32.075337
            ],
            [
              -96.081578,
              32.081479
            ],
            [
              -96.06601,
              32.085812
            ],
            [
              -96.069534,
              32.0765
            ],
            [
              -96.062649,
              32.026442
            ],
            [
              -96.05478,
              32.012526
            ],
            [
              -96.052786,
              32.005895
            ],
            [
              -95.428512,
              32.084475
            ],
            [
              -95.457733,
              32.113347
            ],
            [
              -95.459908,
              32.135559
            ],
            [
              -95.478585,
              32.147224
            ],
            [
              -95.462578,
              32.201763
            ],
            [
              -95.478936,
              32.212223
            ],
            [
              -95.488107,
              32.234912
            ],
            [
              -95.467584,
              32.24663
            ],
            [
              -95.469949,
              32.262124
            ],
            [
              -95.457268,
              32.273111
            ],
            [
              -95.446969,
              32.295807
            ],
            [
              -95.457825,
              32.327929
            ],
            [
              -95.449165,
              32.355233
            ],
            [
              -96.075899,
              32.357505
            ],
            [
              -96.184582,
              32.358117
            ],
            [
              -96.195621,
              32.356001
            ],
            [
              -96.200405,
              32.354838
            ],
            [
              -96.249701,
              32.358716
            ],
            [
              -96.452138,
              32.358631
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "035",
      "COUNTYNS": "01383803",
      "AFFGEOID": "0500000US48035",
      "GEOID": "48035",
      "NAME": "Bosque",
      "LSAD": "06",
      "ALAND": 2545908057,
      "AWATER": 50666112,
      "County_state": "Bosque,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.864864,
              32.087328
            ],
            [
              -98.005462,
              32.017897
            ],
            [
              -97.766431,
              31.672461
            ],
            [
              -97.689834,
              31.711019
            ],
            [
              -97.60523,
              31.587762
            ],
            [
              -97.580232,
              31.598796
            ],
            [
              -97.277265,
              31.745492
            ],
            [
              -97.310335,
              31.761552
            ],
            [
              -97.32653,
              31.78836
            ],
            [
              -97.282026,
              31.823331
            ],
            [
              -97.28206,
              31.843152
            ],
            [
              -97.302673,
              31.84828
            ],
            [
              -97.327145,
              31.841452
            ],
            [
              -97.36246,
              31.86495
            ],
            [
              -97.382848,
              31.870788
            ],
            [
              -97.38182,
              31.884255
            ],
            [
              -97.360312,
              31.890669
            ],
            [
              -97.398334,
              31.909806
            ],
            [
              -97.412483,
              31.909844
            ],
            [
              -97.422731,
              31.923182
            ],
            [
              -97.439471,
              31.929775
            ],
            [
              -97.427001,
              31.942628
            ],
            [
              -97.404232,
              31.953713
            ],
            [
              -97.379992,
              31.97274
            ],
            [
              -97.381302,
              31.985596
            ],
            [
              -97.403525,
              31.986476
            ],
            [
              -97.422737,
              32.00789
            ],
            [
              -97.426387,
              32.028557
            ],
            [
              -97.441956,
              32.014436
            ],
            [
              -97.474942,
              32.010081
            ],
            [
              -97.485968,
              32.017611
            ],
            [
              -97.485668,
              32.04292
            ],
            [
              -97.492092,
              32.059723
            ],
            [
              -97.470665,
              32.086711
            ],
            [
              -97.472432,
              32.097552
            ],
            [
              -97.49585,
              32.128152
            ],
            [
              -97.49355,
              32.139848
            ],
            [
              -97.469751,
              32.147942
            ],
            [
              -97.457217,
              32.162726
            ],
            [
              -97.459744,
              32.1709
            ],
            [
              -97.476086,
              32.17346
            ],
            [
              -97.511559,
              32.161839
            ],
            [
              -97.508423,
              32.14029
            ],
            [
              -97.514682,
              32.133605
            ],
            [
              -97.555211,
              32.149414
            ],
            [
              -97.568013,
              32.150153
            ],
            [
              -97.568475,
              32.17647
            ],
            [
              -97.578628,
              32.180771
            ],
            [
              -97.601558,
              32.17562
            ],
            [
              -97.589649,
              32.190883
            ],
            [
              -97.597588,
              32.203725
            ],
            [
              -97.615055,
              32.203581
            ],
            [
              -97.630885,
              32.207403
            ],
            [
              -97.864864,
              32.087328
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "205",
      "COUNTYNS": "01383888",
      "AFFGEOID": "0500000US48205",
      "GEOID": "48205",
      "NAME": "Hartley",
      "LSAD": "06",
      "ALAND": 3786435518,
      "AWATER": 3023182,
      "County_state": "Hartley,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.042186,
              35.825217
            ],
            [
              -103.041716,
              35.814072
            ],
            [
              -103.041917,
              35.796441
            ],
            [
              -103.041146,
              35.791583
            ],
            [
              -103.04127182956401,
              35.7394328138008
            ],
            [
              -103.041553998475,
              35.6224876319576
            ],
            [
              -102.162809,
              35.627515
            ],
            [
              -102.163015,
              36.055249
            ],
            [
              -103.040824,
              36.055231
            ],
            [
              -103.041305,
              35.837694
            ],
            [
              -103.042186,
              35.825217
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "087",
      "COUNTYNS": "01383829",
      "AFFGEOID": "0500000US48087",
      "GEOID": "48087",
      "NAME": "Collingsworth",
      "LSAD": "06",
      "ALAND": 2378749932,
      "AWATER": 2279193,
      "County_state": "Collingsworth,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.538978,
              35.183144
            ],
            [
              -100.540703,
              34.747723
            ],
            [
              -100.415895,
              34.747525
            ],
            [
              -100.000381,
              34.746358
            ],
            [
              -100.000381,
              34.746461
            ],
            [
              -100.00038360336801,
              35.0303849961589
            ],
            [
              -100.000385,
              35.182702
            ],
            [
              -100.538978,
              35.183144
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "425",
      "COUNTYNS": "01383998",
      "AFFGEOID": "0500000US48425",
      "GEOID": "48425",
      "NAME": "Somervell",
      "LSAD": "06",
      "ALAND": 482938558,
      "AWATER": 14221312,
      "County_state": "Somervell,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.945625,
              32.233468
            ],
            [
              -97.940474,
              32.221738
            ],
            [
              -97.864864,
              32.087328
            ],
            [
              -97.630885,
              32.207403
            ],
            [
              -97.615055,
              32.203581
            ],
            [
              -97.615286,
              32.318618
            ],
            [
              -97.78276,
              32.316493
            ],
            [
              -97.945625,
              32.233468
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "089",
      "COUNTYNS": "01383830",
      "AFFGEOID": "0500000US48089",
      "GEOID": "48089",
      "NAME": "Colorado",
      "LSAD": "06",
      "ALAND": 2487123844,
      "AWATER": 34710043,
      "County_state": "Colorado,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.874222,
              29.632706
            ],
            [
              -96.843119,
              29.616565
            ],
            [
              -96.833362,
              29.595298
            ],
            [
              -96.838575,
              29.582743
            ],
            [
              -96.832812,
              29.576863
            ],
            [
              -96.793132,
              29.577378
            ],
            [
              -96.560555,
              29.335439
            ],
            [
              -96.658466,
              29.263876
            ],
            [
              -96.640315,
              29.247804
            ],
            [
              -96.604341,
              29.26578
            ],
            [
              -96.375814,
              29.393271
            ],
            [
              -96.349217,
              29.406052
            ],
            [
              -96.307672,
              29.514523
            ],
            [
              -96.175422,
              29.633806
            ],
            [
              -96.199282,
              29.65646
            ],
            [
              -96.224679,
              29.66489
            ],
            [
              -96.240354,
              29.657821
            ],
            [
              -96.259226,
              29.668912
            ],
            [
              -96.266304,
              29.686955
            ],
            [
              -96.277718,
              29.700416
            ],
            [
              -96.270117,
              29.71658
            ],
            [
              -96.298312,
              29.737207
            ],
            [
              -96.297194,
              29.749718
            ],
            [
              -96.310732,
              29.755406
            ],
            [
              -96.318066,
              29.791231
            ],
            [
              -96.332676,
              29.802839
            ],
            [
              -96.330869,
              29.814449
            ],
            [
              -96.343159,
              29.82962
            ],
            [
              -96.357624,
              29.81915
            ],
            [
              -96.379327,
              29.825303
            ],
            [
              -96.388811,
              29.821459
            ],
            [
              -96.412691,
              29.824655
            ],
            [
              -96.434749,
              29.847945
            ],
            [
              -96.449966,
              29.856417
            ],
            [
              -96.459993,
              29.854641
            ],
            [
              -96.478609,
              29.869102
            ],
            [
              -96.485091,
              29.867335
            ],
            [
              -96.535705,
              29.907326
            ],
            [
              -96.569844,
              29.961516
            ],
            [
              -96.717043,
              29.792706
            ],
            [
              -96.874222,
              29.632706
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "161",
      "COUNTYNS": "01383866",
      "AFFGEOID": "0500000US48161",
      "GEOID": "48161",
      "NAME": "Freestone",
      "LSAD": "06",
      "ALAND": 2273311877,
      "AWATER": 36936952,
      "County_state": "Freestone,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.496713,
              31.796191
            ],
            [
              -96.236629,
              31.413392
            ],
            [
              -95.7873,
              31.618385
            ],
            [
              -95.788091,
              31.620226
            ],
            [
              -95.79017,
              31.627723
            ],
            [
              -95.796653,
              31.631059
            ],
            [
              -95.794544,
              31.639635
            ],
            [
              -95.789679,
              31.648776
            ],
            [
              -95.793299,
              31.657914
            ],
            [
              -95.790477,
              31.66617
            ],
            [
              -95.782529,
              31.678591
            ],
            [
              -95.783681,
              31.686067
            ],
            [
              -95.787571,
              31.690343
            ],
            [
              -95.791249,
              31.688037
            ],
            [
              -95.794369,
              31.679977
            ],
            [
              -95.802063,
              31.674376
            ],
            [
              -95.812702,
              31.676309
            ],
            [
              -95.821283,
              31.686967
            ],
            [
              -95.829521,
              31.691762
            ],
            [
              -95.843104,
              31.695825
            ],
            [
              -95.856273,
              31.69446
            ],
            [
              -95.864527,
              31.691565
            ],
            [
              -95.875774,
              31.695681
            ],
            [
              -95.878279,
              31.699041
            ],
            [
              -95.87779,
              31.702697
            ],
            [
              -95.875698,
              31.704898
            ],
            [
              -95.875776,
              31.71146
            ],
            [
              -95.873715,
              31.719541
            ],
            [
              -95.870616,
              31.719872
            ],
            [
              -95.866932,
              31.721023
            ],
            [
              -95.86882,
              31.724652
            ],
            [
              -95.868864,
              31.732011
            ],
            [
              -95.869741,
              31.736247
            ],
            [
              -95.875128,
              31.736697
            ],
            [
              -95.881098,
              31.735532
            ],
            [
              -95.880889,
              31.738703
            ],
            [
              -95.875617,
              31.743415
            ],
            [
              -95.870682,
              31.74258
            ],
            [
              -95.868833,
              31.74599
            ],
            [
              -95.870238,
              31.745905
            ],
            [
              -95.873361,
              31.75016
            ],
            [
              -95.874277,
              31.754194
            ],
            [
              -95.884987,
              31.757456
            ],
            [
              -95.892776,
              31.758416
            ],
            [
              -95.898141,
              31.754548
            ],
            [
              -95.901823,
              31.755625
            ],
            [
              -95.901055,
              31.7596
            ],
            [
              -95.90052,
              31.761433
            ],
            [
              -95.897382,
              31.763544
            ],
            [
              -95.903924,
              31.767399
            ],
            [
              -95.912293,
              31.766295
            ],
            [
              -95.920059,
              31.768567
            ],
            [
              -95.927355,
              31.768619
            ],
            [
              -95.932618,
              31.77212
            ],
            [
              -95.938409,
              31.77437
            ],
            [
              -95.953019,
              31.776284
            ],
            [
              -95.956842,
              31.781823
            ],
            [
              -95.961197,
              31.78808
            ],
            [
              -95.972715,
              31.788729
            ],
            [
              -95.97728,
              31.785452
            ],
            [
              -95.982407,
              31.787
            ],
            [
              -95.980023,
              31.794075
            ],
            [
              -95.975516,
              31.806547
            ],
            [
              -95.976098,
              31.816563
            ],
            [
              -95.977246,
              31.823092
            ],
            [
              -95.978009,
              31.832348
            ],
            [
              -95.98704,
              31.840085
            ],
            [
              -95.993222,
              31.840652
            ],
            [
              -95.99234,
              31.846934
            ],
            [
              -95.989694,
              31.851637
            ],
            [
              -95.986275,
              31.860174
            ],
            [
              -95.97924,
              31.864453
            ],
            [
              -95.977899,
              31.868746
            ],
            [
              -95.972169,
              31.871797
            ],
            [
              -95.970124,
              31.870809
            ],
            [
              -95.968824,
              31.873388
            ],
            [
              -95.970016,
              31.875146
            ],
            [
              -95.974597,
              31.877192
            ],
            [
              -95.979968,
              31.876563
            ],
            [
              -95.987779,
              31.873113
            ],
            [
              -95.988539,
              31.874883
            ],
            [
              -95.990134,
              31.872785
            ],
            [
              -95.988876,
              31.870627
            ],
            [
              -95.990447,
              31.86827
            ],
            [
              -95.992695,
              31.867386
            ],
            [
              -95.998825,
              31.868274
            ],
            [
              -96.001623,
              31.872373
            ],
            [
              -96.0051,
              31.875141
            ],
            [
              -96.005023,
              31.878381
            ],
            [
              -96.009372,
              31.879266
            ],
            [
              -96.012348,
              31.876653
            ],
            [
              -96.017542,
              31.872666
            ],
            [
              -96.024683,
              31.873524
            ],
            [
              -96.027622,
              31.877875
            ],
            [
              -96.026716,
              31.886172
            ],
            [
              -96.024645,
              31.892655
            ],
            [
              -96.019669,
              31.903587
            ],
            [
              -96.015634,
              31.905382
            ],
            [
              -96.014066,
              31.908711
            ],
            [
              -96.010421,
              31.90928
            ],
            [
              -96.00958,
              31.907341
            ],
            [
              -96.00718,
              31.906176
            ],
            [
              -96.004226,
              31.907444
            ],
            [
              -96.002669,
              31.912795
            ],
            [
              -96.005751,
              31.918372
            ],
            [
              -96.006304,
              31.923731
            ],
            [
              -96.013005,
              31.929555
            ],
            [
              -96.013318,
              31.934775
            ],
            [
              -96.019763,
              31.936902
            ],
            [
              -96.016569,
              31.942811
            ],
            [
              -96.015006,
              31.947609
            ],
            [
              -96.020463,
              31.952473
            ],
            [
              -96.024876,
              31.95733
            ],
            [
              -96.029318,
              31.955925
            ],
            [
              -96.030988,
              31.95396
            ],
            [
              -96.039622,
              31.950722
            ],
            [
              -96.039868,
              31.946956
            ],
            [
              -96.043607,
              31.948511
            ],
            [
              -96.048291,
              31.951017
            ],
            [
              -96.055816,
              31.951086
            ],
            [
              -96.05992,
              31.954035
            ],
            [
              -96.061722,
              31.959578
            ],
            [
              -96.057307,
              31.96111
            ],
            [
              -96.050818,
              31.962517
            ],
            [
              -96.046853,
              31.960012
            ],
            [
              -96.044175,
              31.964125
            ],
            [
              -96.046794,
              31.967884
            ],
            [
              -96.050453,
              31.969452
            ],
            [
              -96.054405,
              31.974501
            ],
            [
              -96.058677,
              31.97489
            ],
            [
              -96.062496,
              31.978405
            ],
            [
              -96.0626,
              31.982817
            ],
            [
              -96.057627,
              31.98671
            ],
            [
              -96.05308,
              31.993914
            ],
            [
              -96.050645,
              32.000228
            ],
            [
              -96.052786,
              32.005895
            ],
            [
              -96.05478,
              32.012526
            ],
            [
              -96.496713,
              31.796191
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "033",
      "COUNTYNS": "01383802",
      "AFFGEOID": "0500000US48033",
      "GEOID": "48033",
      "NAME": "Borden",
      "LSAD": "06",
      "ALAND": 2324366073,
      "AWATER": 22297610,
      "County_state": "Borden,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.691284,
              32.961838
            ],
            [
              -101.68874,
              32.525222
            ],
            [
              -101.174571,
              32.524112
            ],
            [
              -101.174562,
              32.527703
            ],
            [
              -101.173378,
              32.963597
            ],
            [
              -101.557434,
              32.961025
            ],
            [
              -101.691284,
              32.961838
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "109",
      "COUNTYNS": "01383840",
      "AFFGEOID": "0500000US48109",
      "GEOID": "48109",
      "NAME": "Culberson",
      "LSAD": "06",
      "ALAND": 9873439554,
      "AWATER": 556134,
      "County_state": "Culberson,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.918356959202,
              32.0004960444129
            ],
            [
              -104.918276,
              31.813306
            ],
            [
              -104.908893,
              31.000134
            ],
            [
              -104.907818,
              30.984874
            ],
            [
              -104.907592,
              30.835919
            ],
            [
              -104.916396,
              30.835979
            ],
            [
              -104.917163,
              30.663626
            ],
            [
              -104.102351,
              31.105203
            ],
            [
              -104.101342,
              31.120164
            ],
            [
              -104.100333,
              31.125136
            ],
            [
              -104.100216,
              31.133361
            ],
            [
              -104.077294,
              31.394061
            ],
            [
              -104.024521,
              32.00001
            ],
            [
              -104.329638023971,
              32.0000743637004
            ],
            [
              -104.531756,
              32.000117
            ],
            [
              -104.531937,
              32.000311
            ],
            [
              -104.640918,
              32.000396
            ],
            [
              -104.643526,
              32.000443
            ],
            [
              -104.84774,
              32.000482393992996
            ],
            [
              -104.918272,
              32.000496
            ],
            [
              -104.918356959202,
              32.0004960444129
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "167",
      "COUNTYNS": "01383869",
      "AFFGEOID": "0500000US48167",
      "GEOID": "48167",
      "NAME": "Galveston",
      "LSAD": "06",
      "ALAND": 981478578,
      "AWATER": 1281645343,
      "County_state": "Galveston,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.783131,
              29.375642
            ],
            [
              -94.782356,
              29.364266
            ],
            [
              -94.778691,
              29.361483
            ],
            [
              -94.761491,
              29.361883
            ],
            [
              -94.744834,
              29.369158
            ],
            [
              -94.731047,
              29.369141
            ],
            [
              -94.723959,
              29.383268
            ],
            [
              -94.708473,
              29.403049
            ],
            [
              -94.694158,
              29.415632
            ],
            [
              -94.670389,
              29.43078
            ],
            [
              -94.631084,
              29.451464
            ],
            [
              -94.594853,
              29.467903
            ],
            [
              -94.500807,
              29.505367
            ],
            [
              -94.370816,
              29.5556221980724
            ],
            [
              -94.371828,
              29.597178
            ],
            [
              -94.388519,
              29.595696
            ],
            [
              -94.419177,
              29.589193
            ],
            [
              -94.412771,
              29.572612
            ],
            [
              -94.422868,
              29.566256
            ],
            [
              -94.474637,
              29.558329
            ],
            [
              -94.5110860419471,
              29.5429710287244
            ],
            [
              -94.509487,
              29.54259
            ],
            [
              -94.503429,
              29.54325
            ],
            [
              -94.495025,
              29.525031
            ],
            [
              -94.511045,
              29.51965
            ],
            [
              -94.532348,
              29.5178
            ],
            [
              -94.546994,
              29.524379
            ],
            [
              -94.55399,
              29.529559
            ],
            [
              -94.566674,
              29.531988
            ],
            [
              -94.580274,
              29.525295
            ],
            [
              -94.591407,
              29.513858
            ],
            [
              -94.59544,
              29.507669
            ],
            [
              -94.594211,
              29.492127
            ],
            [
              -94.608557,
              29.483345
            ],
            [
              -94.628217,
              29.475986
            ],
            [
              -94.645948,
              29.473769
            ],
            [
              -94.656737,
              29.478033
            ],
            [
              -94.665853,
              29.478401
            ],
            [
              -94.6724,
              29.476843
            ],
            [
              -94.681541,
              29.471389
            ],
            [
              -94.686386,
              29.466509
            ],
            [
              -94.706365,
              29.436805
            ],
            [
              -94.723818,
              29.426536
            ],
            [
              -94.7541,
              29.401
            ],
            [
              -94.766848,
              29.393489
            ],
            [
              -94.783131,
              29.375642
            ]
          ]
        ],
        [
          [
            [
              -95.129935,
              29.085618
            ],
            [
              -95.125232,
              29.081059
            ],
            [
              -95.1192711445067,
              29.077843770495498
            ],
            [
              -95.110484,
              29.088224
            ],
            [
              -95.081773,
              29.111222
            ],
            [
              -95.026219,
              29.148064
            ],
            [
              -94.803695,
              29.279237
            ],
            [
              -94.786095,
              29.290737
            ],
            [
              -94.769695,
              29.304936
            ],
            [
              -94.731082,
              29.331833
            ],
            [
              -94.72253,
              29.331446
            ],
            [
              -94.73132,
              29.338066
            ],
            [
              -94.744945,
              29.33641
            ],
            [
              -94.745529,
              29.334235
            ],
            [
              -94.777064,
              29.336811
            ],
            [
              -94.779995,
              29.334935
            ],
            [
              -94.784895,
              29.335535
            ],
            [
              -94.810696,
              29.353435
            ],
            [
              -94.822307,
              29.344254
            ],
            [
              -94.822547,
              29.321882
            ],
            [
              -94.824953,
              29.306005
            ],
            [
              -94.84973,
              29.297345
            ],
            [
              -94.865126,
              29.293977
            ],
            [
              -94.879239,
              29.285839
            ],
            [
              -94.991549,
              29.201131
            ],
            [
              -95.003923,
              29.184474
            ],
            [
              -95.098599,
              29.128799
            ],
            [
              -95.1056271959924,
              29.1230296057938
            ],
            [
              -95.129935,
              29.085618
            ]
          ]
        ],
        [
          [
            [
              -95.216422,
              29.55608
            ],
            [
              -95.233081,
              29.465566
            ],
            [
              -95.19219,
              29.404367
            ],
            [
              -95.119749,
              29.29584
            ],
            [
              -95.05861900141849,
              29.2031987507191
            ],
            [
              -95.022482,
              29.229684
            ],
            [
              -94.952526,
              29.290122
            ],
            [
              -94.921593,
              29.281556
            ],
            [
              -94.8986635589798,
              29.3036670961742
            ],
            [
              -94.893994,
              29.30817
            ],
            [
              -94.894234,
              29.338
            ],
            [
              -94.886536,
              29.366386
            ],
            [
              -94.8873,
              29.415132
            ],
            [
              -94.8908,
              29.433432
            ],
            [
              -94.919401,
              29.448031
            ],
            [
              -94.930861,
              29.450504
            ],
            [
              -94.925914,
              29.469047
            ],
            [
              -94.913385,
              29.487254
            ],
            [
              -94.909465,
              29.496838
            ],
            [
              -94.958443,
              29.505013
            ],
            [
              -94.981916,
              29.511141
            ],
            [
              -94.999581,
              29.521093
            ],
            [
              -95.0026120193396,
              29.524768188747498
            ],
            [
              -95.015165,
              29.539989
            ],
            [
              -95.0181905786307,
              29.554583889664297
            ],
            [
              -95.01761,
              29.548181
            ],
            [
              -95.065065,
              29.558478
            ],
            [
              -95.080106,
              29.533672
            ],
            [
              -95.082189,
              29.533997
            ],
            [
              -95.083129,
              29.53392
            ],
            [
              -95.085926,
              29.534848
            ],
            [
              -95.086507,
              29.53441
            ],
            [
              -95.086973,
              29.533114
            ],
            [
              -95.088187,
              29.531891
            ],
            [
              -95.089354,
              29.531214
            ],
            [
              -95.090038,
              29.531128
            ],
            [
              -95.090879,
              29.531555
            ],
            [
              -95.091169,
              29.533172
            ],
            [
              -95.091862,
              29.533881
            ],
            [
              -95.094518,
              29.53574
            ],
            [
              -95.096453,
              29.534953
            ],
            [
              -95.096978,
              29.533738
            ],
            [
              -95.096508,
              29.532511
            ],
            [
              -95.095426,
              29.530738
            ],
            [
              -95.095361,
              29.529376
            ],
            [
              -95.095066,
              29.52816
            ],
            [
              -95.093871,
              29.526526
            ],
            [
              -95.093693,
              29.525344
            ],
            [
              -95.094785,
              29.522587
            ],
            [
              -95.096414,
              29.521266
            ],
            [
              -95.097046,
              29.520517
            ],
            [
              -95.097612,
              29.518611
            ],
            [
              -95.0985,
              29.518172
            ],
            [
              -95.099233,
              29.518408
            ],
            [
              -95.099896,
              29.51987
            ],
            [
              -95.100552,
              29.520787
            ],
            [
              -95.10189,
              29.521561
            ],
            [
              -95.103205,
              29.520853
            ],
            [
              -95.10345,
              29.520145
            ],
            [
              -95.103008,
              29.518799
            ],
            [
              -95.10363,
              29.517894
            ],
            [
              -95.105629,
              29.517233
            ],
            [
              -95.10618,
              29.516588
            ],
            [
              -95.10602,
              29.515781
            ],
            [
              -95.104819,
              29.514187
            ],
            [
              -95.104036,
              29.512562
            ],
            [
              -95.103874,
              29.51129
            ],
            [
              -95.104326,
              29.510734
            ],
            [
              -95.105199,
              29.510381
            ],
            [
              -95.106497,
              29.510212
            ],
            [
              -95.108102,
              29.509649
            ],
            [
              -95.109468,
              29.509856
            ],
            [
              -95.110833,
              29.510597
            ],
            [
              -95.112457,
              29.510936
            ],
            [
              -95.113747,
              29.510608
            ],
            [
              -95.114573,
              29.509962
            ],
            [
              -95.1155,
              29.508819
            ],
            [
              -95.117139,
              29.508031
            ],
            [
              -95.141024,
              29.506488
            ],
            [
              -95.163534,
              29.497856
            ],
            [
              -95.169668,
              29.514892
            ],
            [
              -95.183097,
              29.519017
            ],
            [
              -95.199453,
              29.544528
            ],
            [
              -95.193154,
              29.559518
            ],
            [
              -95.200985,
              29.563212
            ],
            [
              -95.216422,
              29.55608
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "281",
      "COUNTYNS": "01383927",
      "AFFGEOID": "0500000US48281",
      "GEOID": "48281",
      "NAME": "Lampasas",
      "LSAD": "06",
      "ALAND": 1846250633,
      "AWATER": 2833490,
      "County_state": "Lampasas,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.562739,
              31.230582
            ],
            [
              -98.569004,
              31.198302
            ],
            [
              -98.556339,
              31.191988
            ],
            [
              -98.542421,
              31.19449
            ],
            [
              -98.535363,
              31.186768
            ],
            [
              -98.542016,
              31.174496
            ],
            [
              -98.518008,
              31.16463
            ],
            [
              -98.506571,
              31.167235
            ],
            [
              -98.510341,
              31.155666
            ],
            [
              -98.526728,
              31.155819
            ],
            [
              -98.526959,
              31.14039
            ],
            [
              -98.548051,
              31.123474
            ],
            [
              -98.523459,
              31.088886
            ],
            [
              -98.501115,
              31.116946
            ],
            [
              -98.482,
              31.11918
            ],
            [
              -98.47206,
              31.107866
            ],
            [
              -98.456288,
              31.111603
            ],
            [
              -98.469146,
              31.096645
            ],
            [
              -98.457493,
              31.088209
            ],
            [
              -98.457541,
              31.077713
            ],
            [
              -98.472994,
              31.075926
            ],
            [
              -98.484429,
              31.066357
            ],
            [
              -98.475999,
              31.053435
            ],
            [
              -98.45031,
              31.043014
            ],
            [
              -98.439687,
              31.029537
            ],
            [
              -97.911684,
              31.034919
            ],
            [
              -97.913847,
              31.065882
            ],
            [
              -97.9071,
              31.069374
            ],
            [
              -97.927777,
              31.100991
            ],
            [
              -97.93167,
              31.105432
            ],
            [
              -98.180006,
              31.463717
            ],
            [
              -98.27107,
              31.416398
            ],
            [
              -98.266794,
              31.410521
            ],
            [
              -98.562739,
              31.230582
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "137",
      "COUNTYNS": "01383854",
      "AFFGEOID": "0500000US48137",
      "GEOID": "48137",
      "NAME": "Edwards",
      "LSAD": "06",
      "ALAND": 5485259829,
      "AWATER": 5199800,
      "County_state": "Edwards,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.700393,
              30.288276
            ],
            [
              -100.699932,
              29.623897
            ],
            [
              -100.112098,
              29.623263
            ],
            [
              -100.014188,
              29.623495
            ],
            [
              -100.007275,
              29.632018
            ],
            [
              -100.030303,
              29.663256
            ],
            [
              -100.016954,
              29.676823
            ],
            [
              -100.045791,
              29.694924
            ],
            [
              -100.046268,
              29.706487
            ],
            [
              -100.063741,
              29.71106
            ],
            [
              -100.02637,
              29.723184
            ],
            [
              -100.029605,
              29.73647
            ],
            [
              -100.021997,
              29.745766
            ],
            [
              -100.035958,
              29.77937
            ],
            [
              -100.028976,
              29.788993
            ],
            [
              -100.013291,
              29.794339
            ],
            [
              -100.017075,
              29.812007
            ],
            [
              -100.027266,
              29.82436
            ],
            [
              -100.03088,
              29.848273
            ],
            [
              -100.024032,
              29.849204
            ],
            [
              -100.019556,
              29.869589
            ],
            [
              -100.023413,
              29.883134
            ],
            [
              -100.002302,
              29.896828
            ],
            [
              -99.989672,
              30.025747
            ],
            [
              -99.973046,
              30.024591
            ],
            [
              -99.967626,
              30.082356
            ],
            [
              -99.917482,
              30.078597
            ],
            [
              -99.757621,
              30.074132
            ],
            [
              -99.754142,
              30.290698
            ],
            [
              -100.116461,
              30.290296
            ],
            [
              -100.700393,
              30.288276
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "187",
      "COUNTYNS": "01383879",
      "AFFGEOID": "0500000US48187",
      "GEOID": "48187",
      "NAME": "Guadalupe",
      "LSAD": "06",
      "ALAND": 1842135388,
      "AWATER": 9163416,
      "County_state": "Guadalupe,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.310928,
              29.594473
            ],
            [
              -98.311807,
              29.59037
            ],
            [
              -98.29985,
              29.566868
            ],
            [
              -98.298247,
              29.561113
            ],
            [
              -98.27371,
              29.551213
            ],
            [
              -98.236624,
              29.555894
            ],
            [
              -98.21921,
              29.524084
            ],
            [
              -98.204779,
              29.52215
            ],
            [
              -98.186324,
              29.5012
            ],
            [
              -98.168344,
              29.496153
            ],
            [
              -98.164638,
              29.478091
            ],
            [
              -98.142792,
              29.486458
            ],
            [
              -98.122555,
              29.47797
            ],
            [
              -98.120824,
              29.450321
            ],
            [
              -98.134171,
              29.441751
            ],
            [
              -98.122574,
              29.378549
            ],
            [
              -98.113175,
              29.365667
            ],
            [
              -98.084684,
              29.379594
            ],
            [
              -97.956081,
              29.378436
            ],
            [
              -97.840383,
              29.376791
            ],
            [
              -97.753859,
              29.488582
            ],
            [
              -97.746585,
              29.500335
            ],
            [
              -97.664987,
              29.60772
            ],
            [
              -97.633176,
              29.649863
            ],
            [
              -97.654344,
              29.658035
            ],
            [
              -97.651075,
              29.666439
            ],
            [
              -97.688301,
              29.672589
            ],
            [
              -97.697797,
              29.6665
            ],
            [
              -97.703855,
              29.681267
            ],
            [
              -97.721852,
              29.682088
            ],
            [
              -97.743347,
              29.698841
            ],
            [
              -97.738369,
              29.712905
            ],
            [
              -97.768322,
              29.718883
            ],
            [
              -97.758601,
              29.725047
            ],
            [
              -97.77096,
              29.732305
            ],
            [
              -97.780917,
              29.759258
            ],
            [
              -97.799396,
              29.75226
            ],
            [
              -97.800279,
              29.766207
            ],
            [
              -97.815102,
              29.780726
            ],
            [
              -97.817138,
              29.790383
            ],
            [
              -97.829259,
              29.779597
            ],
            [
              -97.829484,
              29.79349
            ],
            [
              -97.842351,
              29.799224
            ],
            [
              -97.841549,
              29.824941
            ],
            [
              -97.848635,
              29.843348
            ],
            [
              -97.86336,
              29.856194
            ],
            [
              -97.875259,
              29.858208
            ],
            [
              -97.943564,
              29.806914
            ],
            [
              -97.999271,
              29.752444
            ],
            [
              -98.001106,
              29.751051
            ],
            [
              -98.089941,
              29.683479
            ],
            [
              -98.19763,
              29.638128
            ],
            [
              -98.310928,
              29.594473
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "073",
      "COUNTYNS": "01383822",
      "AFFGEOID": "0500000US48073",
      "GEOID": "48073",
      "NAME": "Cherokee",
      "LSAD": "06",
      "ALAND": 2727141725,
      "AWATER": 23873001,
      "County_state": "Cherokee,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.459908,
              32.135559
            ],
            [
              -95.457733,
              32.113347
            ],
            [
              -95.428512,
              32.084475
            ],
            [
              -95.43793,
              32.068259
            ],
            [
              -95.435428,
              32.052991
            ],
            [
              -95.422083,
              32.047401
            ],
            [
              -95.432782,
              32.030902
            ],
            [
              -95.424106,
              32.018893
            ],
            [
              -95.446449,
              31.998517
            ],
            [
              -95.452859,
              31.97654
            ],
            [
              -95.443983,
              31.963524
            ],
            [
              -95.453519,
              31.959876
            ],
            [
              -95.445588,
              31.945858
            ],
            [
              -95.422735,
              31.924823
            ],
            [
              -95.437533,
              31.901519
            ],
            [
              -95.431004,
              31.880138
            ],
            [
              -95.445431,
              31.856985
            ],
            [
              -95.446825,
              31.843495
            ],
            [
              -95.413499,
              31.835515
            ],
            [
              -95.403734,
              31.821835
            ],
            [
              -95.40324,
              31.800409
            ],
            [
              -95.39412,
              31.787225
            ],
            [
              -95.402303,
              31.762036
            ],
            [
              -95.380752,
              31.752551
            ],
            [
              -95.362781,
              31.752459
            ],
            [
              -95.366187,
              31.726753
            ],
            [
              -95.341573,
              31.735289
            ],
            [
              -95.332823,
              31.72792
            ],
            [
              -95.342471,
              31.717951
            ],
            [
              -95.328042,
              31.717145
            ],
            [
              -95.311955,
              31.697271
            ],
            [
              -95.289661,
              31.683997
            ],
            [
              -95.275849,
              31.657229
            ],
            [
              -95.285479,
              31.642136
            ],
            [
              -95.281693,
              31.625391
            ],
            [
              -95.264882,
              31.62427
            ],
            [
              -95.258844,
              31.610532
            ],
            [
              -95.273203,
              31.592886
            ],
            [
              -95.260257,
              31.577104
            ],
            [
              -95.207701,
              31.570434
            ],
            [
              -95.168509,
              31.583671
            ],
            [
              -95.161757,
              31.57346
            ],
            [
              -95.139603,
              31.562821
            ],
            [
              -95.147048,
              31.550285
            ],
            [
              -95.143445,
              31.539641
            ],
            [
              -95.125338,
              31.537248
            ],
            [
              -95.099966,
              31.518869
            ],
            [
              -95.098046,
              31.493495
            ],
            [
              -95.105573,
              31.494668
            ],
            [
              -95.100323,
              31.468196
            ],
            [
              -95.064701,
              31.471316
            ],
            [
              -95.044193,
              31.444965
            ],
            [
              -95.0075,
              31.444676
            ],
            [
              -95.003345,
              31.42571
            ],
            [
              -94.865857,
              31.526916
            ],
            [
              -94.883969,
              31.529693
            ],
            [
              -94.893623,
              31.541196
            ],
            [
              -94.892247,
              31.578087
            ],
            [
              -94.912305,
              31.569027
            ],
            [
              -94.924219,
              31.579487
            ],
            [
              -94.933467,
              31.621817
            ],
            [
              -94.941618,
              31.638244
            ],
            [
              -94.951969,
              31.641351
            ],
            [
              -94.94857,
              31.657413
            ],
            [
              -94.965946,
              31.697758
            ],
            [
              -94.958344,
              31.7241
            ],
            [
              -94.972757,
              31.737631
            ],
            [
              -94.967268,
              31.753816
            ],
            [
              -94.931666,
              31.754506
            ],
            [
              -94.929228,
              31.766354
            ],
            [
              -94.971831,
              31.782399
            ],
            [
              -94.978128,
              31.793294
            ],
            [
              -94.955426,
              31.796368
            ],
            [
              -94.942438,
              31.818948
            ],
            [
              -94.948359,
              31.82539
            ],
            [
              -94.93591,
              31.831482
            ],
            [
              -94.937532,
              31.845555
            ],
            [
              -94.984768,
              31.846144
            ],
            [
              -94.985272,
              32.137989
            ],
            [
              -95.228007,
              32.136405
            ],
            [
              -95.459908,
              32.135559
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "147",
      "COUNTYNS": "01383859",
      "AFFGEOID": "0500000US48147",
      "GEOID": "48147",
      "NAME": "Fannin",
      "LSAD": "06",
      "ALAND": 2307250942,
      "AWATER": 20847063,
      "County_state": "Fannin,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.384602,
              33.397826
            ],
            [
              -96.384495,
              33.341119
            ],
            [
              -96.295413,
              33.351936
            ],
            [
              -95.858723,
              33.40953
            ],
            [
              -95.857738,
              33.461722
            ],
            [
              -95.855013,
              33.827992
            ],
            [
              -95.8456275554846,
              33.8407767617097
            ],
            [
              -95.849864,
              33.844952
            ],
            [
              -95.859469,
              33.852456
            ],
            [
              -95.881292,
              33.860627
            ],
            [
              -95.887491,
              33.863856
            ],
            [
              -95.893306,
              33.868161
            ],
            [
              -95.905343,
              33.875629
            ],
            [
              -95.915961,
              33.881148
            ],
            [
              -95.922712,
              33.883758
            ],
            [
              -95.935198,
              33.887101
            ],
            [
              -95.936132,
              33.886826
            ],
            [
              -95.937202,
              33.884652
            ],
            [
              -95.936817,
              33.882386
            ],
            [
              -95.935637,
              33.880371
            ],
            [
              -95.935308,
              33.878724
            ],
            [
              -95.935325,
              33.875099
            ],
            [
              -95.936631,
              33.870615
            ],
            [
              -95.941267,
              33.861619
            ],
            [
              -95.944284,
              33.859811
            ],
            [
              -95.951609,
              33.857017
            ],
            [
              -95.972156,
              33.856371
            ],
            [
              -95.980966,
              33.859307
            ],
            [
              -95.984254,
              33.864403
            ],
            [
              -95.988857,
              33.866869
            ],
            [
              -95.991487,
              33.866869
            ],
            [
              -95.993624,
              33.866211
            ],
            [
              -95.996748,
              33.864403
            ],
            [
              -95.997734,
              33.860951
            ],
            [
              -95.997405,
              33.855526
            ],
            [
              -95.997709,
              33.852182
            ],
            [
              -95.998351,
              33.851049
            ],
            [
              -96.005296,
              33.845505
            ],
            [
              -96.019599,
              33.840566
            ],
            [
              -96.021407,
              33.841881
            ],
            [
              -96.022065,
              33.843196
            ],
            [
              -96.022507,
              33.84613
            ],
            [
              -96.0219,
              33.849114
            ],
            [
              -96.022229,
              33.850923
            ],
            [
              -96.025188,
              33.852073
            ],
            [
              -96.029463,
              33.852402
            ],
            [
              -96.031271,
              33.850758
            ],
            [
              -96.037191,
              33.841245
            ],
            [
              -96.048834,
              33.836468
            ],
            [
              -96.055358,
              33.838262
            ],
            [
              -96.063924,
              33.841523
            ],
            [
              -96.084626,
              33.846656
            ],
            [
              -96.100095,
              33.847971
            ],
            [
              -96.101473,
              33.846709
            ],
            [
              -96.101349,
              33.845721
            ],
            [
              -96.100785,
              33.84423
            ],
            [
              -96.099153,
              33.842409
            ],
            [
              -96.097638,
              33.837935
            ],
            [
              -96.097448,
              33.832725
            ],
            [
              -96.09936,
              33.83047
            ],
            [
              -96.104075,
              33.83073
            ],
            [
              -96.109993,
              33.832396
            ],
            [
              -96.118169,
              33.837884
            ],
            [
              -96.122951,
              33.839964
            ],
            [
              -96.138905,
              33.839159
            ],
            [
              -96.14807,
              33.837799
            ],
            [
              -96.150147,
              33.835856
            ],
            [
              -96.15163,
              33.831946
            ],
            [
              -96.148792,
              33.819197
            ],
            [
              -96.150765,
              33.816987
            ],
            [
              -96.164217,
              33.817001
            ],
            [
              -96.17589,
              33.814627
            ],
            [
              -96.17691,
              33.813934
            ],
            [
              -96.178964,
              33.810553
            ],
            [
              -96.17734,
              33.805117
            ],
            [
              -96.17515,
              33.801951
            ],
            [
              -96.173025,
              33.80056
            ],
            [
              -96.170373,
              33.799382
            ],
            [
              -96.166837,
              33.797908
            ],
            [
              -96.162123,
              33.79614
            ],
            [
              -96.162757,
              33.788769
            ],
            [
              -96.169452,
              33.770131
            ],
            [
              -96.174633,
              33.763699
            ],
            [
              -96.178059,
              33.760518
            ],
            [
              -96.186554,
              33.756375
            ],
            [
              -96.1963803242589,
              33.7532399416533
            ],
            [
              -96.1999,
              33.752117
            ],
            [
              -96.220521,
              33.74739
            ],
            [
              -96.229023,
              33.748021
            ],
            [
              -96.248232,
              33.758986
            ],
            [
              -96.269896,
              33.768405
            ],
            [
              -96.277269,
              33.769735
            ],
            [
              -96.292482,
              33.766419
            ],
            [
              -96.294867,
              33.764771
            ],
            [
              -96.301706,
              33.753756
            ],
            [
              -96.303009,
              33.750878
            ],
            [
              -96.3061,
              33.741002
            ],
            [
              -96.307389,
              33.735005
            ],
            [
              -96.306596,
              33.726786
            ],
            [
              -96.307035,
              33.719987
            ],
            [
              -96.309964,
              33.710489
            ],
            [
              -96.316925,
              33.698997
            ],
            [
              -96.31876,
              33.696753
            ],
            [
              -96.321103,
              33.6951
            ],
            [
              -96.342665,
              33.686975
            ],
            [
              -96.348306,
              33.686379
            ],
            [
              -96.355946,
              33.687155
            ],
            [
              -96.362198,
              33.691818
            ],
            [
              -96.363135,
              33.694215
            ],
            [
              -96.363253,
              33.70105
            ],
            [
              -96.366945,
              33.711222
            ],
            [
              -96.36959,
              33.716809
            ],
            [
              -96.37940399227689,
              33.7258163072446
            ],
            [
              -96.384602,
              33.397826
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "157",
      "COUNTYNS": "01383864",
      "AFFGEOID": "0500000US48157",
      "GEOID": "48157",
      "NAME": "Fort Bend",
      "LSAD": "06",
      "ALAND": 2231423107,
      "AWATER": 61595965,
      "County_state": "Fort Bend,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.088912,
              29.601658
            ],
            [
              -96.059562,
              29.588518
            ],
            [
              -96.059998,
              29.56866
            ],
            [
              -96.044596,
              29.562238
            ],
            [
              -96.041137,
              29.540516
            ],
            [
              -96.055312,
              29.532904
            ],
            [
              -96.053918,
              29.512009
            ],
            [
              -96.036519,
              29.51083
            ],
            [
              -96.022341,
              29.502044
            ],
            [
              -96.035519,
              29.492287
            ],
            [
              -96.059681,
              29.491868
            ],
            [
              -96.059627,
              29.473329
            ],
            [
              -96.047978,
              29.46184
            ],
            [
              -96.003848,
              29.428865
            ],
            [
              -95.992934,
              29.433628
            ],
            [
              -95.982061,
              29.410766
            ],
            [
              -95.97343,
              29.411445
            ],
            [
              -95.968412,
              29.383449
            ],
            [
              -95.947294,
              29.372518
            ],
            [
              -95.93951,
              29.352534
            ],
            [
              -95.95338,
              29.345079
            ],
            [
              -95.94681,
              29.333433
            ],
            [
              -95.923005,
              29.329273
            ],
            [
              -95.916606,
              29.319368
            ],
            [
              -95.894468,
              29.31498
            ],
            [
              -95.897541,
              29.299283
            ],
            [
              -95.87424,
              29.266538
            ],
            [
              -95.850833,
              29.266274
            ],
            [
              -95.847656,
              29.26259
            ],
            [
              -95.79782,
              29.307971
            ],
            [
              -95.784013,
              29.296249
            ],
            [
              -95.752866,
              29.324503
            ],
            [
              -95.672785,
              29.299527
            ],
            [
              -95.654355,
              29.290945
            ],
            [
              -95.64065,
              29.296637
            ],
            [
              -95.626058,
              29.293091
            ],
            [
              -95.627943,
              29.309062
            ],
            [
              -95.604975,
              29.330737
            ],
            [
              -95.590793,
              29.327485
            ],
            [
              -95.579598,
              29.340129
            ],
            [
              -95.580483,
              29.360863
            ],
            [
              -95.56729,
              29.389695
            ],
            [
              -95.547577,
              29.395197
            ],
            [
              -95.545825,
              29.407735
            ],
            [
              -95.555936,
              29.425344
            ],
            [
              -95.550612,
              29.438251
            ],
            [
              -95.462503,
              29.43924
            ],
            [
              -95.45029,
              29.484162
            ],
            [
              -95.446141,
              29.499549
            ],
            [
              -95.443224,
              29.510214
            ],
            [
              -95.436888,
              29.532945
            ],
            [
              -95.424118,
              29.580233
            ],
            [
              -95.446339,
              29.590698
            ],
            [
              -95.571345,
              29.648741
            ],
            [
              -95.582706,
              29.653972
            ],
            [
              -95.597033,
              29.660673
            ],
            [
              -95.597839,
              29.661037
            ],
            [
              -95.621848,
              29.673572
            ],
            [
              -95.652188,
              29.69096
            ],
            [
              -95.670872,
              29.700991
            ],
            [
              -95.687082,
              29.710078
            ],
            [
              -95.688349,
              29.710862
            ],
            [
              -95.730396,
              29.734296
            ],
            [
              -95.751446,
              29.746472
            ],
            [
              -95.812471,
              29.780594
            ],
            [
              -95.82622,
              29.78828
            ],
            [
              -95.950931,
              29.740769
            ],
            [
              -95.971287,
              29.740454
            ],
            [
              -95.97094,
              29.728554
            ],
            [
              -96.032711,
              29.727944
            ],
            [
              -96.032388,
              29.717478
            ],
            [
              -96.011605,
              29.719843
            ],
            [
              -96.007579,
              29.702938
            ],
            [
              -96.022639,
              29.70501
            ],
            [
              -96.014524,
              29.693589
            ],
            [
              -96.016637,
              29.680061
            ],
            [
              -96.032157,
              29.688524
            ],
            [
              -96.057222,
              29.681943
            ],
            [
              -96.060001,
              29.675963
            ],
            [
              -96.04384,
              29.665793
            ],
            [
              -96.029664,
              29.675223
            ],
            [
              -96.016039,
              29.663716
            ],
            [
              -96.03185,
              29.655566
            ],
            [
              -96.017536,
              29.64546
            ],
            [
              -96.016438,
              29.627104
            ],
            [
              -96.02272,
              29.624443
            ],
            [
              -96.02485,
              29.602877
            ],
            [
              -96.088912,
              29.601658
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "331",
      "COUNTYNS": "01383951",
      "AFFGEOID": "0500000US48331",
      "GEOID": "48331",
      "NAME": "Milam",
      "LSAD": "06",
      "ALAND": 2633798194,
      "AWATER": 12758799,
      "County_state": "Milam,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.315507,
              30.752371
            ],
            [
              -97.271418,
              30.735692
            ],
            [
              -97.155219,
              30.457344
            ],
            [
              -96.963629,
              30.557169
            ],
            [
              -96.621281,
              30.730819
            ],
            [
              -96.632729,
              30.741043
            ],
            [
              -96.611097,
              30.757354
            ],
            [
              -96.62445,
              30.779985
            ],
            [
              -96.646804,
              30.779113
            ],
            [
              -96.645906,
              30.791535
            ],
            [
              -96.635945,
              30.804285
            ],
            [
              -96.651681,
              30.827142
            ],
            [
              -96.670192,
              30.833871
            ],
            [
              -96.694853,
              30.864517
            ],
            [
              -96.693482,
              30.879318
            ],
            [
              -96.68171,
              30.882367
            ],
            [
              -96.695712,
              30.892557
            ],
            [
              -96.68497,
              30.906191
            ],
            [
              -96.698444,
              30.908772
            ],
            [
              -96.714846,
              30.898187
            ],
            [
              -96.729917,
              30.899846
            ],
            [
              -96.729418,
              30.913758
            ],
            [
              -96.741153,
              30.914874
            ],
            [
              -96.75633,
              30.927206
            ],
            [
              -96.755364,
              30.943576
            ],
            [
              -96.769573,
              30.958779
            ],
            [
              -96.771015,
              30.972177
            ],
            [
              -96.745062,
              30.994772
            ],
            [
              -96.755709,
              31.005378
            ],
            [
              -96.768284,
              30.999686
            ],
            [
              -96.778531,
              31.035629
            ],
            [
              -96.765945,
              31.043425
            ],
            [
              -96.785118,
              31.049102
            ],
            [
              -96.793139,
              31.065718
            ],
            [
              -96.805582,
              31.05957
            ],
            [
              -96.821189,
              31.064147
            ],
            [
              -96.809523,
              31.079576
            ],
            [
              -96.830691,
              31.100415
            ],
            [
              -96.829121,
              31.105871
            ],
            [
              -96.829621,
              31.110545
            ],
            [
              -96.911558,
              31.068979
            ],
            [
              -96.948831,
              31.048441
            ],
            [
              -97.070188,
              30.98622
            ],
            [
              -97.250274,
              30.895137
            ],
            [
              -97.259082,
              30.889596
            ],
            [
              -97.315507,
              30.752371
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "029",
      "COUNTYNS": "01383800",
      "AFFGEOID": "0500000US48029",
      "GEOID": "48029",
      "NAME": "Bexar",
      "LSAD": "06",
      "ALAND": 3211308851,
      "AWATER": 41911545,
      "County_state": "Bexar,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.806552,
              29.690709
            ],
            [
              -98.805578,
              29.522711
            ],
            [
              -98.804763,
              29.250693
            ],
            [
              -98.779769,
              29.241959
            ],
            [
              -98.484266,
              29.140821
            ],
            [
              -98.441548,
              29.126127
            ],
            [
              -98.407336,
              29.114435
            ],
            [
              -98.327196,
              29.209868
            ],
            [
              -98.13965,
              29.437401
            ],
            [
              -98.134171,
              29.441751
            ],
            [
              -98.120824,
              29.450321
            ],
            [
              -98.122555,
              29.47797
            ],
            [
              -98.142792,
              29.486458
            ],
            [
              -98.164638,
              29.478091
            ],
            [
              -98.168344,
              29.496153
            ],
            [
              -98.186324,
              29.5012
            ],
            [
              -98.204779,
              29.52215
            ],
            [
              -98.21921,
              29.524084
            ],
            [
              -98.236624,
              29.555894
            ],
            [
              -98.27371,
              29.551213
            ],
            [
              -98.298247,
              29.561113
            ],
            [
              -98.29985,
              29.566868
            ],
            [
              -98.311807,
              29.59037
            ],
            [
              -98.310928,
              29.594473
            ],
            [
              -98.31095,
              29.59456
            ],
            [
              -98.312222,
              29.601802
            ],
            [
              -98.328651,
              29.608233
            ],
            [
              -98.345673,
              29.619742
            ],
            [
              -98.343703,
              29.654344
            ],
            [
              -98.382566,
              29.67446
            ],
            [
              -98.380161,
              29.703929
            ],
            [
              -98.354557,
              29.703864
            ],
            [
              -98.34135,
              29.70965
            ],
            [
              -98.338794,
              29.722006
            ],
            [
              -98.352205,
              29.73415
            ],
            [
              -98.369828,
              29.720693
            ],
            [
              -98.373584,
              29.730401
            ],
            [
              -98.398339,
              29.737615
            ],
            [
              -98.403871,
              29.749
            ],
            [
              -98.416064,
              29.746549
            ],
            [
              -98.426968,
              29.71884
            ],
            [
              -98.443852,
              29.71965
            ],
            [
              -98.443671,
              29.734646
            ],
            [
              -98.457494,
              29.731564
            ],
            [
              -98.466283,
              29.738522
            ],
            [
              -98.476991,
              29.733436
            ],
            [
              -98.503974,
              29.738644
            ],
            [
              -98.543718,
              29.752052
            ],
            [
              -98.550489,
              29.760713
            ],
            [
              -98.559304,
              29.751482
            ],
            [
              -98.574567,
              29.750419
            ],
            [
              -98.582332,
              29.741236
            ],
            [
              -98.600406,
              29.74998
            ],
            [
              -98.600261,
              29.741574
            ],
            [
              -98.619232,
              29.744142
            ],
            [
              -98.640969,
              29.740228
            ],
            [
              -98.646124,
              29.745181
            ],
            [
              -98.651278,
              29.754024
            ],
            [
              -98.659736,
              29.742033
            ],
            [
              -98.699994,
              29.737441
            ],
            [
              -98.735403,
              29.719633
            ],
            [
              -98.738551,
              29.716559
            ],
            [
              -98.778782,
              29.720167
            ],
            [
              -98.806552,
              29.690709
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "251",
      "COUNTYNS": "01383911",
      "AFFGEOID": "0500000US48251",
      "GEOID": "48251",
      "NAME": "Johnson",
      "LSAD": "06",
      "ALAND": 1877223454,
      "AWATER": 25002941,
      "County_state": "Johnson,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.615675,
              32.495392
            ],
            [
              -97.617134,
              32.471693
            ],
            [
              -97.615286,
              32.318618
            ],
            [
              -97.615055,
              32.203581
            ],
            [
              -97.597588,
              32.203725
            ],
            [
              -97.589649,
              32.190883
            ],
            [
              -97.601558,
              32.17562
            ],
            [
              -97.578628,
              32.180771
            ],
            [
              -97.568475,
              32.17647
            ],
            [
              -97.568013,
              32.150153
            ],
            [
              -97.555211,
              32.149414
            ],
            [
              -97.514682,
              32.133605
            ],
            [
              -97.508423,
              32.14029
            ],
            [
              -97.511559,
              32.161839
            ],
            [
              -97.476086,
              32.17346
            ],
            [
              -97.475985,
              32.181679
            ],
            [
              -97.086191,
              32.265451
            ],
            [
              -97.086834,
              32.549428
            ],
            [
              -97.313984,
              32.552627
            ],
            [
              -97.34899,
              32.553145
            ],
            [
              -97.386367,
              32.553559
            ],
            [
              -97.430998,
              32.554106
            ],
            [
              -97.550582,
              32.555391
            ],
            [
              -97.617066,
              32.555484
            ],
            [
              -97.615675,
              32.495392
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "121",
      "COUNTYNS": "01383847",
      "AFFGEOID": "0500000US48121",
      "GEOID": "48121",
      "NAME": "Denton",
      "LSAD": "06",
      "ALAND": 2275424546,
      "AWATER": 192588409,
      "County_state": "Denton,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.39267,
              33.183509
            ],
            [
              -97.39848,
              32.990839
            ],
            [
              -97.356048,
              32.990197
            ],
            [
              -97.328833,
              32.990116
            ],
            [
              -97.234794,
              32.989865
            ],
            [
              -97.180907,
              32.989813
            ],
            [
              -97.167261,
              32.989802
            ],
            [
              -97.12959,
              32.990011
            ],
            [
              -97.124154,
              32.989835
            ],
            [
              -97.032287,
              32.989324
            ],
            [
              -97.010696,
              32.98932
            ],
            [
              -96.990214,
              32.989287
            ],
            [
              -96.980069,
              32.989203
            ],
            [
              -96.958665,
              32.989013
            ],
            [
              -96.843979,
              32.987554
            ],
            [
              -96.843266,
              33.025914
            ],
            [
              -96.841867,
              33.085576
            ],
            [
              -96.841849,
              33.086456
            ],
            [
              -96.838803,
              33.219386
            ],
            [
              -96.837795,
              33.262835
            ],
            [
              -96.83411,
              33.405498
            ],
            [
              -96.841098,
              33.413377
            ],
            [
              -96.94386,
              33.41641
            ],
            [
              -97.220092,
              33.425122
            ],
            [
              -97.383095,
              33.43045
            ],
            [
              -97.391606,
              33.218935
            ],
            [
              -97.39267,
              33.183509
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "019",
      "COUNTYNS": "01383795",
      "AFFGEOID": "0500000US48019",
      "GEOID": "48019",
      "NAME": "Bandera",
      "LSAD": "06",
      "ALAND": 2048666851,
      "AWATER": 17288354,
      "County_state": "Bandera,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.602776,
              29.907679
            ],
            [
              -99.60313,
              29.627181
            ],
            [
              -99.411817,
              29.627514
            ],
            [
              -99.000309,
              29.623224
            ],
            [
              -98.982404,
              29.622418
            ],
            [
              -98.976126,
              29.613264
            ],
            [
              -98.987068,
              29.584663
            ],
            [
              -98.979273,
              29.575455
            ],
            [
              -98.985202,
              29.564079
            ],
            [
              -98.971383,
              29.551842
            ],
            [
              -98.964728,
              29.558901
            ],
            [
              -98.927161,
              29.56225
            ],
            [
              -98.806552,
              29.690709
            ],
            [
              -98.778782,
              29.720167
            ],
            [
              -98.917725,
              29.781398
            ],
            [
              -99.176988,
              29.895063
            ],
            [
              -99.27864,
              29.904176
            ],
            [
              -99.602776,
              29.907679
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "323",
      "COUNTYNS": "01383944",
      "AFFGEOID": "0500000US48323",
      "GEOID": "48323",
      "NAME": "Maverick",
      "LSAD": "06",
      "ALAND": 3313819221,
      "AWATER": 31869807,
      "County_state": "Maverick,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.66835550309101,
              29.083970135364098
            ],
            [
              -100.664065,
              29.073206
            ],
            [
              -100.662508,
              29.058107
            ],
            [
              -100.663212,
              29.048042
            ],
            [
              -100.660208,
              29.031497
            ],
            [
              -100.65611,
              29.017224
            ],
            [
              -100.653758,
              29.015356
            ],
            [
              -100.650946,
              29.008254
            ],
            [
              -100.645894,
              28.986421
            ],
            [
              -100.646993,
              28.957079
            ],
            [
              -100.651512,
              28.943432
            ],
            [
              -100.638857,
              28.927622
            ],
            [
              -100.63917,
              28.916289
            ],
            [
              -100.640568,
              28.914212
            ],
            [
              -100.631611,
              28.902839
            ],
            [
              -100.627206,
              28.903734
            ],
            [
              -100.602054,
              28.901944
            ],
            [
              -100.602654,
              28.88766
            ],
            [
              -100.598877,
              28.875591
            ],
            [
              -100.59104,
              28.863054
            ],
            [
              -100.580502,
              28.856008
            ],
            [
              -100.572992,
              28.848464
            ],
            [
              -100.576846,
              28.836168
            ],
            [
              -100.574699,
              28.828787
            ],
            [
              -100.57051,
              28.826317
            ],
            [
              -100.561443,
              28.829174
            ],
            [
              -100.55313,
              28.828249
            ],
            [
              -100.547324,
              28.825817
            ],
            [
              -100.53583,
              28.805888
            ],
            [
              -100.532431,
              28.791063
            ],
            [
              -100.537772,
              28.780776
            ],
            [
              -100.533017,
              28.76328
            ],
            [
              -100.519226,
              28.756161
            ],
            [
              -100.507613,
              28.740599
            ],
            [
              -100.506701,
              28.716745
            ],
            [
              -100.511998,
              28.705352
            ],
            [
              -100.510055,
              28.690723
            ],
            [
              -100.500354,
              28.66196
            ],
            [
              -100.495863,
              28.658569
            ],
            [
              -100.479636,
              28.655225
            ],
            [
              -100.474494,
              28.647071
            ],
            [
              -100.462866,
              28.641364
            ],
            [
              -100.447091,
              28.642197
            ],
            [
              -100.445529,
              28.637144
            ],
            [
              -100.448648,
              28.616774
            ],
            [
              -100.44732,
              28.609325
            ],
            [
              -100.429856,
              28.596441
            ],
            [
              -100.398385,
              28.584884
            ],
            [
              -100.3968,
              28.580401
            ],
            [
              -100.39727,
              28.575637
            ],
            [
              -100.411414,
              28.551899
            ],
            [
              -100.405058,
              28.53578
            ],
            [
              -100.38886,
              28.515748
            ],
            [
              -100.379079,
              28.511639
            ],
            [
              -100.362148,
              28.508399
            ],
            [
              -100.338518,
              28.501833
            ],
            [
              -100.333814,
              28.499252
            ],
            [
              -100.33714,
              28.491729
            ],
            [
              -100.344181,
              28.486249
            ],
            [
              -100.352235,
              28.482638
            ],
            [
              -100.365982,
              28.481116
            ],
            [
              -100.368288,
              28.477196
            ],
            [
              -100.357498,
              28.463642
            ],
            [
              -100.350786,
              28.459246
            ],
            [
              -100.341533,
              28.449571
            ],
            [
              -100.337797,
              28.44296
            ],
            [
              -100.336186,
              28.430181
            ],
            [
              -100.337059,
              28.427151
            ],
            [
              -100.343945,
              28.410119
            ],
            [
              -100.349586,
              28.402604
            ],
            [
              -100.3444,
              28.389662
            ],
            [
              -100.341869,
              28.384953
            ],
            [
              -100.320393,
              28.362117
            ],
            [
              -100.317246,
              28.357382
            ],
            [
              -100.314198,
              28.345859
            ],
            [
              -100.288639,
              28.316978
            ],
            [
              -100.286471,
              28.312296
            ],
            [
              -100.287554,
              28.301093
            ],
            [
              -100.294296,
              28.284381
            ],
            [
              -100.293468,
              28.278475
            ],
            [
              -100.289384,
              28.273491
            ],
            [
              -100.280518,
              28.267969
            ],
            [
              -100.267604,
              28.250269
            ],
            [
              -100.251634,
              28.236177
            ],
            [
              -100.2462,
              28.234092
            ],
            [
              -100.227575,
              28.235857
            ],
            [
              -100.22363,
              28.235224
            ],
            [
              -100.220284,
              28.23221
            ],
            [
              -100.217565,
              28.226934
            ],
            [
              -100.21345,
              28.210416
            ],
            [
              -100.212136662868,
              28.1968373634523
            ],
            [
              -100.113719,
              28.197819
            ],
            [
              -100.114336,
              28.648122
            ],
            [
              -100.111406,
              29.086318
            ],
            [
              -100.66835550309101,
              29.083970135364098
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "239",
      "COUNTYNS": "01383905",
      "AFFGEOID": "0500000US48239",
      "GEOID": "48239",
      "NAME": "Jackson",
      "LSAD": "06",
      "ALAND": 2148246715,
      "AWATER": 71039573,
      "County_state": "Jackson,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.938587,
              29.063244
            ],
            [
              -96.838795,
              29.025327
            ],
            [
              -96.832103,
              29.021299
            ],
            [
              -96.816639,
              28.985392
            ],
            [
              -96.792133,
              28.918803
            ],
            [
              -96.77604,
              28.915959
            ],
            [
              -96.758447,
              28.896476
            ],
            [
              -96.740219,
              28.893869
            ],
            [
              -96.732506,
              28.877076
            ],
            [
              -96.711514,
              28.851411
            ],
            [
              -96.719652,
              28.817178
            ],
            [
              -96.70719,
              28.815486
            ],
            [
              -96.695092,
              28.792092
            ],
            [
              -96.69933,
              28.778643
            ],
            [
              -96.687549,
              28.76462
            ],
            [
              -96.669878,
              28.760287
            ],
            [
              -96.668478,
              28.737621
            ],
            [
              -96.65921,
              28.724045
            ],
            [
              -96.642505,
              28.716945
            ],
            [
              -96.643442,
              28.712105
            ],
            [
              -96.586776,
              28.725289
            ],
            [
              -96.577611,
              28.706397
            ],
            [
              -96.562818,
              28.704984
            ],
            [
              -96.478081,
              28.702535
            ],
            [
              -96.428394,
              28.703523
            ],
            [
              -96.39954,
              28.730231
            ],
            [
              -96.40008,
              28.689879
            ],
            [
              -96.404445,
              28.674409
            ],
            [
              -96.323522,
              28.675597
            ],
            [
              -96.309178,
              28.963291
            ],
            [
              -96.640315,
              29.247804
            ],
            [
              -96.658466,
              29.263876
            ],
            [
              -96.938587,
              29.063244
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "48",
      "COUNTYFP": "181",
      "COUNTYNS": "01383876",
      "AFFGEOID": "0500000US48181",
      "GEOID": "48181",
      "NAME": "Grayson",
      "LSAD": "06",
      "ALAND": 2415995947,
      "AWATER": 120112446,
      "County_state": "Grayson,48"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.944611,
              33.949217
            ],
            [
              -96.94386,
              33.41641
            ],
            [
              -96.841098,
              33.413377
            ],
            [
              -96.83411,
              33.405498
            ],
            [
              -96.384602,
              33.397826
            ],
            [
              -96.37940399227689,
              33.7258163072446
            ],
            [
              -96.384116,
              33.730141
            ],
            [
              -96.403507,
              33.746289
            ],
            [
              -96.408469,
              33.751192
            ],
            [
              -96.413408,
              33.757714
            ],
            [
              -96.416146,
              33.766099
            ],
            [
              -96.417562,
              33.769038
            ],
            [
              -96.419961,
              33.773034
            ],
            [
              -96.422643,
              33.776041
            ],
            [
              -96.430214,
              33.778654
            ],
            [
              -96.436455,
              33.78005
            ],
            [
              -96.448045,
              33.781031
            ],
            [
              -96.45051,
              33.780588
            ],
            [
              -96.456254,
              33.776035
            ],
            [
              -96.459154,
              33.775232
            ],
            [
              -96.48606,
              33.77301
            ],
            [
              -96.500268,
              33.772583
            ],
            [
              -96.502286,
              33.77346
            ],
            [
              -96.511914,
              33.781478
            ],
            [
              -96.515912,
              33.787795
            ],
            [
              -96.515959,
              33.798934
            ],
            [
              -96.516584,
              33.803168
            ],
            [
              -96.519911,
              33.811347
            ],
            [
              -96.523863,
              33.818114
            ],
            [
              -96.526655,
              33.820891
            ],
            [
              -96.529234,
              33.822127
            ],
            [
              -96.532865,
              33.823005
            ],
            [
              -96.551223,
              33.819129
            ],
            [
              -96.566298,
              33.818511
            ],
            [
              -96.572937,
              33.819098
            ],
            [
              -96.587067,
              33.828009
            ],
            [
              -96.592926,
              33.830916
            ],
            [
              -96.601258,
              33.834327
            ],
            [
              -96.623155,
              33.841483
            ],
            [
              -96.62929,
              33.845488
            ],
            [
              -96.630022,
              33.847541
            ],
            [
              -96.629747,
              33.850866
            ],
            [
              -96.628969,
              33.852407
            ],
            [
              -96.625399,
              33.856542
            ],
            [
              -96.61197,
              33.869016
            ],
            [
              -96.601686,
              33.872823
            ],
            [
              -96.597348,
              33.875101
            ],
            [
              -96.590112,
              33.880665
            ],
            [
              -96.587494,
              33.884251
            ],
            [
              -96.58536,
              33.888948
            ],
            [
              -96.585452,
              33.891281
            ],
            [
              -96.587934,
              33.894784
            ],
            [
              -96.5885189157485,
              33.8948810582175
            ],
            [
              -96.592948,
              33.895616
            ],
            [
              -96.607562,
              33.894735
            ],
            [
              -96.628294,
              33.894477
            ],
            [
              -96.630117,
              33.895422
            ],
            [
              -96.64405,
              33.905962
            ],
            [
              -96.659896,
              33.916666
            ],
            [
              -96.66441,
              33.917267
            ],
            [
              -96.667187,
              33.91694
            ],
            [
              -96.670618,
              33.914914
            ],
            [
              -96.673449,
              33.912278
            ],
            [
              -96.675306,
              33.909114
            ],
            [
              -96.680947,
              33.896204
            ],
            [
              -96.683464,
              33.884217
            ],
            [
              -96.682103,
              33.876645
            ],
            [
              -96.682209,
              33.873876
            ],
            [
              -96.684727,
              33.862905
            ],
            [
              -96.688191,
              33.854613
            ],
            [
              -96.690708,
              33.849959
            ],
            [
              -96.699574,
              33.839049
            ],
            [
              -96.704457,
              33.835021
            ],
            [
              -96.708134,
              33.83306
            ],
            [
              -96.712422,
              33.831633
            ],
            [
              -96.746038,
              33.825699
            ],
            [
              -96.754041,
              33.824658
            ],
            [
              -96.761588,
              33.824406
            ],
            [
              -96.766235,
              33.825458
            ],
            [
              -96.769378,
              33.827477
            ],
            [
              -96.770676,
              33.829621
            ],
            [
              -96.776766,
              33.841976
            ],
            [
              -96.777202,
              33.848162
            ],
            [
              -96.779588,
              33.857939
            ],
            [
              -96.780569,
              33.860098
            ],
            [
              -96.783485,
              33.863534
            ],
            [
              -96.794276,
              33.868886
            ],
            [
              -96.812778,
              33.872646
            ],
            [
              -96.832157,
              33.874835
            ],
            [
              -96.837413,
              33.871349
            ],
            [
              -96.839778,
              33.868396
            ],
            [
              -96.840819,
              33.863645
            ],
            [
              -96.841592,
              33.852894
            ],
            [
              -96.845896,
              33.848975
            ],
            [
              -96.850593,
              33.847211
            ],
            [
              -96.85609,
              33.84749
            ],
            [
              -96.866438,
              33.853149
            ],
            [
              -96.875281,
              33.860505
            ],
            [
              -96.88301,
              33.868019
            ],
            [
              -96.895728,
              33.896414
            ],
            [
              -96.897194,
              33.902954
            ],
            [
              -96.896469,
              33.913318
            ],
            [
              -96.899442,
              33.933728
            ],
            [
              -96.902434,
              33.942018
            ],
            [
              -96.905253,
              33.947219
            ],
            [
              -96.907387,
              33.950025
            ],
            [
              -96.911336,
              33.95396
            ],
            [
              -96.9163,
              33.957798
            ],
            [
              -96.918618,
              33.958926
            ],
            [
              -96.922114,
              33.959579
            ],
            [
              -96.924268,
              33.959159
            ],
            [
              -96.932252,
              33.955688
            ],
            [
              -96.93479067141789,
              33.954358787058396
            ],
            [
              -96.944611,
              33.949217
            ]
          ]
        ]
      ]
    }
  }
]
};