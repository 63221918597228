export const counties_dc = {
  "type":"FeatureCollection","name":"dc_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "11",
      "COUNTYFP": "001",
      "COUNTYNS": "01702382",
      "AFFGEOID": "0500000US11001",
      "GEOID": "11001",
      "NAME": "District of Columbia",
      "LSAD": "00",
      "ALAND": 158351639,
      "AWATER": 18675956,
      "County_state": "District of Columbia,11"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.119759,
              38.934343
            ],
            [
              -77.1179,
              38.932411
            ],
            [
              -77.1166,
              38.928911
            ],
            [
              -77.1134,
              38.925211
            ],
            [
              -77.1063,
              38.919111
            ],
            [
              -77.1034,
              38.912911
            ],
            [
              -77.1012,
              38.911111
            ],
            [
              -77.0937,
              38.905911
            ],
            [
              -77.0902,
              38.904211
            ],
            [
              -77.0822,
              38.901911
            ],
            [
              -77.0783563148089,
              38.901529839581094
            ],
            [
              -77.070099,
              38.900711
            ],
            [
              -77.068199,
              38.899811
            ],
            [
              -77.067299,
              38.899211
            ],
            [
              -77.063499,
              38.888611
            ],
            [
              -77.058254,
              38.880069
            ],
            [
              -77.055199,
              38.880012
            ],
            [
              -77.054099,
              38.879112
            ],
            [
              -77.051099,
              38.875212
            ],
            [
              -77.051299,
              38.873212
            ],
            [
              -77.049099,
              38.870712
            ],
            [
              -77.046299,
              38.871312
            ],
            [
              -77.045599,
              38.873012
            ],
            [
              -77.046599,
              38.874912
            ],
            [
              -77.045399,
              38.875212
            ],
            [
              -77.0434540926829,
              38.8741006243902
            ],
            [
              -77.043299,
              38.874012
            ],
            [
              -77.040599,
              38.871212
            ],
            [
              -77.039099,
              38.868112
            ],
            [
              -77.038899,
              38.865812
            ],
            [
              -77.039299,
              38.864312
            ],
            [
              -77.0318917188571,
              38.850863706384395
            ],
            [
              -77.031698,
              38.850512
            ],
            [
              -77.032798,
              38.841712
            ],
            [
              -77.041699,
              38.840212
            ],
            [
              -77.044199,
              38.840212
            ],
            [
              -77.044487611898,
              38.839598699716696
            ],
            [
              -77.044999,
              38.838512
            ],
            [
              -77.044899,
              38.834712
            ],
            [
              -77.043499,
              38.833212
            ],
            [
              -77.042599,
              38.833812
            ],
            [
              -77.041199,
              38.833712
            ],
            [
              -77.039199,
              38.832212
            ],
            [
              -77.038098,
              38.828612
            ],
            [
              -77.039098,
              38.821413
            ],
            [
              -77.038098,
              38.815613
            ],
            [
              -77.035798,
              38.814913
            ],
            [
              -77.038898,
              38.800813
            ],
            [
              -77.039006,
              38.791645
            ],
            [
              -77.0244236516675,
              38.8032258870216
            ],
            [
              -77.001397,
              38.821513
            ],
            [
              -76.999997,
              38.821913
            ],
            [
              -76.992697,
              38.828213
            ],
            [
              -76.979497,
              38.837812
            ],
            [
              -76.96194148624339,
              38.8518966918631
            ],
            [
              -76.9581401027746,
              38.8549465208544
            ],
            [
              -76.953696,
              38.858512
            ],
            [
              -76.949696,
              38.861312
            ],
            [
              -76.920195,
              38.884412
            ],
            [
              -76.919295,
              38.885112
            ],
            [
              -76.910795,
              38.891712
            ],
            [
              -76.909393,
              38.892852
            ],
            [
              -76.92754893665301,
              38.9073037102278
            ],
            [
              -76.935096,
              38.913311
            ],
            [
              -76.9419210679896,
              38.918586499498396
            ],
            [
              -76.9918925205442,
              38.9572123964546
            ],
            [
              -77.002498,
              38.96541
            ],
            [
              -77.0025892201687,
              38.9654839197919
            ],
            [
              -77.008298,
              38.97011
            ],
            [
              -77.013798,
              38.97441
            ],
            [
              -77.015598,
              38.97591
            ],
            [
              -77.036299,
              38.99171
            ],
            [
              -77.041018,
              38.995548
            ],
            [
              -77.0425182478171,
              38.9943689030408
            ],
            [
              -77.054299,
              38.98511
            ],
            [
              -77.0716409840087,
              38.971777542736795
            ],
            [
              -77.0857801216917,
              38.9609074226397
            ],
            [
              -77.0915,
              38.95651
            ],
            [
              -77.1007,
              38.94891
            ],
            [
              -77.1045,
              38.94641
            ],
            [
              -77.119759,
              38.934343
            ]
          ]
        ]
      ]
    }
  }
]
};