export const counties_ct = {
  "type":"FeatureCollection","name":"ct_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "09",
      "COUNTYFP": "007",
      "COUNTYNS": "00212797",
      "AFFGEOID": "0500000US09007",
      "GEOID": "09007",
      "NAME": "Middlesex",
      "LSAD": "06",
      "ALAND": 956493768,
      "AWATER": 180679144,
      "County_state": "Middlesex,09"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -72.752941,
              41.592497
            ],
            [
              -72.752181,
              41.578894
            ],
            [
              -72.748878,
              41.544279
            ],
            [
              -72.744742,
              41.497698
            ],
            [
              -72.74333,
              41.483114
            ],
            [
              -72.733886,
              41.485368
            ],
            [
              -72.735399,
              41.473564
            ],
            [
              -72.73912,
              41.438294
            ],
            [
              -72.746141,
              41.423297
            ],
            [
              -72.73801,
              41.424669
            ],
            [
              -72.708343,
              41.429443
            ],
            [
              -72.684019,
              41.433551
            ],
            [
              -72.678739,
              41.433797
            ],
            [
              -72.653672,
              41.438125
            ],
            [
              -72.650482,
              41.419119
            ],
            [
              -72.632639,
              41.412466
            ],
            [
              -72.612328,
              41.386179
            ],
            [
              -72.614407,
              41.329966
            ],
            [
              -72.586344,
              41.324244
            ],
            [
              -72.585657,
              41.309852
            ],
            [
              -72.545986,
              41.280335
            ],
            [
              -72.5377762161873,
              41.2556463675281
            ],
            [
              -72.536746,
              41.256207
            ],
            [
              -72.533247,
              41.26269
            ],
            [
              -72.529416,
              41.264421
            ],
            [
              -72.521312,
              41.2656
            ],
            [
              -72.51866,
              41.261253
            ],
            [
              -72.506634,
              41.260099
            ],
            [
              -72.499534,
              41.265866
            ],
            [
              -72.4862274061012,
              41.270687369005294
            ],
            [
              -72.485693,
              41.270881
            ],
            [
              -72.472539,
              41.270103
            ],
            [
              -72.451925,
              41.278885
            ],
            [
              -72.40593,
              41.278398
            ],
            [
              -72.398688,
              41.278172
            ],
            [
              -72.38897106658659,
              41.2649781142955
            ],
            [
              -72.386629,
              41.261798
            ],
            [
              -72.348068,
              41.269698
            ],
            [
              -72.348643,
              41.277446
            ],
            [
              -72.341460181784,
              41.280109910478096
            ],
            [
              -72.349548,
              41.296302
            ],
            [
              -72.349121,
              41.324492
            ],
            [
              -72.369147,
              41.342337
            ],
            [
              -72.379397,
              41.349746
            ],
            [
              -72.376004,
              41.376255
            ],
            [
              -72.384833,
              41.38519
            ],
            [
              -72.421552,
              41.390527
            ],
            [
              -72.43371,
              41.423995
            ],
            [
              -72.426707,
              41.423386
            ],
            [
              -72.305531,
              41.436282
            ],
            [
              -72.312537,
              41.478411
            ],
            [
              -72.322931,
              41.518119
            ],
            [
              -72.323394,
              41.53425
            ],
            [
              -72.435138,
              41.53187
            ],
            [
              -72.46673,
              41.583899
            ],
            [
              -72.505431,
              41.646999
            ],
            [
              -72.54423,
              41.642696
            ],
            [
              -72.630524,
              41.633282
            ],
            [
              -72.627836,
              41.640199
            ],
            [
              -72.713897,
              41.627705
            ],
            [
              -72.711274,
              41.617693
            ],
            [
              -72.714538,
              41.60431
            ],
            [
              -72.727041,
              41.602398
            ],
            [
              -72.739077,
              41.594794
            ],
            [
              -72.752941,
              41.592497
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "09",
      "COUNTYFP": "009",
      "COUNTYNS": "00212798",
      "AFFGEOID": "0500000US09009",
      "GEOID": "09009",
      "NAME": "New Haven",
      "LSAD": "06",
      "ALAND": 1565653728,
      "AWATER": 667092277,
      "County_state": "New Haven,09"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -73.320874,
              41.503615
            ],
            [
              -73.327114,
              41.484527
            ],
            [
              -73.310473,
              41.468815
            ],
            [
              -73.29776,
              41.448276
            ],
            [
              -73.273696,
              41.451645
            ],
            [
              -73.264524,
              41.435072
            ],
            [
              -73.245259,
              41.439895
            ],
            [
              -73.235911,
              41.426153
            ],
            [
              -73.220857,
              41.429577
            ],
            [
              -73.207688,
              41.420544
            ],
            [
              -73.186557,
              41.408596
            ],
            [
              -73.183269,
              41.391711
            ],
            [
              -73.157977,
              41.382959
            ],
            [
              -73.157055,
              41.376696
            ],
            [
              -73.147555,
              41.367696
            ],
            [
              -73.136054,
              41.351196
            ],
            [
              -73.124654,
              41.345296
            ],
            [
              -73.122854,
              41.341396
            ],
            [
              -73.115754,
              41.332196
            ],
            [
              -73.090938,
              41.319256
            ],
            [
              -73.082052,
              41.313096
            ],
            [
              -73.067851,
              41.300996
            ],
            [
              -73.065251,
              41.294896
            ],
            [
              -73.068751,
              41.289196
            ],
            [
              -73.08153,
              41.282856
            ],
            [
              -73.088682,
              41.271737
            ],
            [
              -73.086752,
              41.254596
            ],
            [
              -73.091215,
              41.246411
            ],
            [
              -73.109953,
              41.229396
            ],
            [
              -73.11034,
              41.213294
            ],
            [
              -73.109253,
              41.204097
            ],
            [
              -73.121997,
              41.178927
            ],
            [
              -73.107987,
              41.168738
            ],
            [
              -73.105493,
              41.172194
            ],
            [
              -73.07945,
              41.194015
            ],
            [
              -73.05935,
              41.206697
            ],
            [
              -73.05065,
              41.210197
            ],
            [
              -73.0456024021101,
              41.2096581297224
            ],
            [
              -73.022549,
              41.207197
            ],
            [
              -73.020449,
              41.206397
            ],
            [
              -73.0202391867704,
              41.2047884319066
            ],
            [
              -73.020149,
              41.204097
            ],
            [
              -73.014948,
              41.204297
            ],
            [
              -73.007548,
              41.210197
            ],
            [
              -72.997948,
              41.222697
            ],
            [
              -72.986247,
              41.233497
            ],
            [
              -72.962047,
              41.251597
            ],
            [
              -72.9596329269992,
              41.2522279269992
            ],
            [
              -72.9569835813667,
              41.2529203433798
            ],
            [
              -72.935646,
              41.258497
            ],
            [
              -72.9334722145705,
              41.2600244933098
            ],
            [
              -72.928206,
              41.263725
            ],
            [
              -72.932799,
              41.270096
            ],
            [
              -72.93662614074829,
              41.2766933502467
            ],
            [
              -72.9368653758693,
              41.2771057515635
            ],
            [
              -72.93856,
              41.280027
            ],
            [
              -72.927229,
              41.282003
            ],
            [
              -72.919823,
              41.29106
            ],
            [
              -72.913087,
              41.296737
            ],
            [
              -72.907621,
              41.296201
            ],
            [
              -72.907463554317,
              41.2950832294113
            ],
            [
              -72.905438,
              41.280703
            ],
            [
              -72.905481,
              41.270125
            ],
            [
              -72.897496,
              41.265756
            ],
            [
              -72.893845,
              41.259897
            ],
            [
              -72.894745,
              41.256197
            ],
            [
              -72.903045,
              41.252797
            ],
            [
              -72.905245,
              41.248297
            ],
            [
              -72.904345,
              41.247297
            ],
            [
              -72.9008033370427,
              41.2458644172307
            ],
            [
              -72.895445,
              41.243697
            ],
            [
              -72.881445,
              41.242597
            ],
            [
              -72.861344,
              41.245297
            ],
            [
              -72.85977209613749,
              41.245823843344205
            ],
            [
              -72.854055,
              41.24774
            ],
            [
              -72.85021,
              41.255544
            ],
            [
              -72.847767,
              41.25669
            ],
            [
              -72.826883,
              41.256755
            ],
            [
              -72.819372,
              41.254061
            ],
            [
              -72.818737,
              41.252244
            ],
            [
              -72.7896076863656,
              41.263461399746596
            ],
            [
              -72.786142,
              41.264796
            ],
            [
              -72.757477,
              41.266913
            ],
            [
              -72.754444,
              41.266913
            ],
            [
              -72.760341,
              41.241235
            ],
            [
              -72.722439,
              41.259138
            ],
            [
              -72.71246,
              41.254167
            ],
            [
              -72.711208,
              41.251018
            ],
            [
              -72.713674,
              41.249007
            ],
            [
              -72.710595,
              41.24448
            ],
            [
              -72.694744,
              41.24497
            ],
            [
              -72.690439,
              41.246697
            ],
            [
              -72.685539,
              41.251297
            ],
            [
              -72.684939,
              41.257597
            ],
            [
              -72.672339,
              41.266997
            ],
            [
              -72.662838,
              41.269197
            ],
            [
              -72.6547149283877,
              41.266218540408794
            ],
            [
              -72.653838,
              41.265897
            ],
            [
              -72.641538,
              41.266998
            ],
            [
              -72.617237,
              41.271998
            ],
            [
              -72.598036,
              41.268698
            ],
            [
              -72.583336,
              41.271698
            ],
            [
              -72.571136,
              41.268098
            ],
            [
              -72.547235,
              41.250499
            ],
            [
              -72.5377762161873,
              41.2556463675281
            ],
            [
              -72.545986,
              41.280335
            ],
            [
              -72.585657,
              41.309852
            ],
            [
              -72.586344,
              41.324244
            ],
            [
              -72.614407,
              41.329966
            ],
            [
              -72.612328,
              41.386179
            ],
            [
              -72.632639,
              41.412466
            ],
            [
              -72.650482,
              41.419119
            ],
            [
              -72.653672,
              41.438125
            ],
            [
              -72.678739,
              41.433797
            ],
            [
              -72.684019,
              41.433551
            ],
            [
              -72.708343,
              41.429443
            ],
            [
              -72.73801,
              41.424669
            ],
            [
              -72.746141,
              41.423297
            ],
            [
              -72.73912,
              41.438294
            ],
            [
              -72.735399,
              41.473564
            ],
            [
              -72.733886,
              41.485368
            ],
            [
              -72.74333,
              41.483114
            ],
            [
              -72.744742,
              41.497698
            ],
            [
              -72.748878,
              41.544279
            ],
            [
              -72.752181,
              41.578894
            ],
            [
              -72.801243,
              41.573098
            ],
            [
              -72.826141,
              41.555897
            ],
            [
              -72.827644,
              41.570999
            ],
            [
              -72.840244,
              41.569098
            ],
            [
              -72.844147,
              41.544697
            ],
            [
              -72.852707,
              41.546291
            ],
            [
              -72.867806,
              41.54898
            ],
            [
              -72.879256,
              41.563152
            ],
            [
              -72.903213,
              41.561747
            ],
            [
              -72.90347,
              41.561708
            ],
            [
              -72.94635,
              41.556797
            ],
            [
              -72.945512,
              41.55958
            ],
            [
              -72.933644,
              41.607794
            ],
            [
              -72.938501,
              41.644311
            ],
            [
              -72.963931,
              41.641318
            ],
            [
              -72.98325,
              41.639598
            ],
            [
              -73.021471,
              41.627104
            ],
            [
              -73.01865,
              41.614097
            ],
            [
              -73.036156,
              41.615197
            ],
            [
              -73.050362,
              41.609748
            ],
            [
              -73.058024,
              41.60674
            ],
            [
              -73.05801,
              41.588647
            ],
            [
              -73.064209,
              41.588491
            ],
            [
              -73.082955,
              41.585616
            ],
            [
              -73.093853,
              41.570196
            ],
            [
              -73.145036,
              41.557822
            ],
            [
              -73.164676,
              41.557088
            ],
            [
              -73.15573,
              41.515279
            ],
            [
              -73.170371,
              41.512587
            ],
            [
              -73.204197,
              41.508527
            ],
            [
              -73.252265,
              41.512134
            ],
            [
              -73.320874,
              41.503615
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "09",
      "COUNTYFP": "015",
      "COUNTYNS": "00212801",
      "AFFGEOID": "0500000US09015",
      "GEOID": "09015",
      "NAME": "Windham",
      "LSAD": "06",
      "ALAND": 1328250338,
      "AWATER": 21706060,
      "County_state": "Windham,09"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -72.252643,
              41.728202
            ],
            [
              -72.246596,
              41.722901
            ],
            [
              -72.239559,
              41.714191
            ],
            [
              -72.211589,
              41.69441
            ],
            [
              -72.185111,
              41.675312
            ],
            [
              -72.157546,
              41.657627
            ],
            [
              -72.151747,
              41.667516
            ],
            [
              -72.119957,
              41.663301
            ],
            [
              -72.118566,
              41.661636
            ],
            [
              -71.981928,
              41.639203
            ],
            [
              -71.975253,
              41.638117
            ],
            [
              -71.954983,
              41.634799
            ],
            [
              -71.949616,
              41.634563
            ],
            [
              -71.884279,
              41.641219
            ],
            [
              -71.857917,
              41.644227
            ],
            [
              -71.78763664588949,
              41.639917146298195
            ],
            [
              -71.786994,
              41.655992
            ],
            [
              -71.7869970249528,
              41.65606947440539
            ],
            [
              -71.789678,
              41.724734
            ],
            [
              -71.7896921119938,
              41.7251983396573
            ],
            [
              -71.791062,
              41.770273
            ],
            [
              -71.79125806357601,
              41.774496473912
            ],
            [
              -71.792767,
              41.807001
            ],
            [
              -71.792786,
              41.80867
            ],
            [
              -71.794161,
              41.840141
            ],
            [
              -71.794161,
              41.841101
            ],
            [
              -71.7944823560536,
              41.8491578858595
            ],
            [
              -71.7946917826635,
              41.85440853003801
            ],
            [
              -71.79715894256209,
              41.9162639874898
            ],
            [
              -71.797922,
              41.935395
            ],
            [
              -71.799242,
              42.008065
            ],
            [
              -71.80065,
              42.023569
            ],
            [
              -71.883947767419,
              42.0243074324439
            ],
            [
              -71.89078,
              42.024368
            ],
            [
              -71.96301930863551,
              42.0262475718444
            ],
            [
              -71.987326,
              42.02688
            ],
            [
              -72.063496,
              42.027347
            ],
            [
              -72.0997252597077,
              42.0288013695839
            ],
            [
              -72.1021687771907,
              42.028899460948004
            ],
            [
              -72.099229,
              41.958948
            ],
            [
              -72.151654,
              41.959302
            ],
            [
              -72.21698,
              41.958161
            ],
            [
              -72.217719,
              41.835184
            ],
            [
              -72.164498,
              41.834913
            ],
            [
              -72.156321,
              41.753491
            ],
            [
              -72.186977,
              41.755427
            ],
            [
              -72.198126,
              41.728176
            ],
            [
              -72.216443,
              41.728035
            ],
            [
              -72.242202,
              41.728147
            ],
            [
              -72.252643,
              41.728202
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "09",
      "COUNTYFP": "011",
      "COUNTYNS": "00212799",
      "AFFGEOID": "0500000US09011",
      "GEOID": "09011",
      "NAME": "New London",
      "LSAD": "06",
      "ALAND": 1722708285,
      "AWATER": 276666169,
      "County_state": "New London,09"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -72.46673,
              41.583899
            ],
            [
              -72.435138,
              41.53187
            ],
            [
              -72.323394,
              41.53425
            ],
            [
              -72.322931,
              41.518119
            ],
            [
              -72.312537,
              41.478411
            ],
            [
              -72.305531,
              41.436282
            ],
            [
              -72.426707,
              41.423386
            ],
            [
              -72.43371,
              41.423995
            ],
            [
              -72.421552,
              41.390527
            ],
            [
              -72.384833,
              41.38519
            ],
            [
              -72.376004,
              41.376255
            ],
            [
              -72.379397,
              41.349746
            ],
            [
              -72.369147,
              41.342337
            ],
            [
              -72.349121,
              41.324492
            ],
            [
              -72.349548,
              41.296302
            ],
            [
              -72.341460181784,
              41.280109910478096
            ],
            [
              -72.333894,
              41.282916
            ],
            [
              -72.327595,
              41.27846
            ],
            [
              -72.31776,
              41.277782
            ],
            [
              -72.261487,
              41.282926
            ],
            [
              -72.251323,
              41.289997
            ],
            [
              -72.250515,
              41.294386
            ],
            [
              -72.251895,
              41.29862
            ],
            [
              -72.24881037219129,
              41.2993370479212
            ],
            [
              -72.248161,
              41.299488
            ],
            [
              -72.235531,
              41.300413
            ],
            [
              -72.225276,
              41.299047
            ],
            [
              -72.212924,
              41.291365
            ],
            [
              -72.204022,
              41.299097
            ],
            [
              -72.203022,
              41.313197
            ],
            [
              -72.201422,
              41.315697
            ],
            [
              -72.191022,
              41.323197
            ],
            [
              -72.184122,
              41.323997
            ],
            [
              -72.177622,
              41.322497
            ],
            [
              -72.1774650579298,
              41.3222891577989
            ],
            [
              -72.173922,
              41.317597
            ],
            [
              -72.16158,
              41.310262
            ],
            [
              -72.134221,
              41.299398
            ],
            [
              -72.11182,
              41.299098
            ],
            [
              -72.10311925119639,
              41.3048259929624
            ],
            [
              -72.09982,
              41.306998
            ],
            [
              -72.094443,
              41.314164
            ],
            [
              -72.084487,
              41.319634
            ],
            [
              -72.0823518315876,
              41.3195386169314
            ],
            [
              -72.021898,
              41.316838
            ],
            [
              -71.988153,
              41.320577
            ],
            [
              -71.982194,
              41.329861
            ],
            [
              -71.9802752216186,
              41.3299490109487
            ],
            [
              -71.979447,
              41.329987
            ],
            [
              -71.970955,
              41.324526
            ],
            [
              -71.956747,
              41.329871
            ],
            [
              -71.945652,
              41.337799
            ],
            [
              -71.936284,
              41.337959
            ],
            [
              -71.923282,
              41.335113
            ],
            [
              -71.922092,
              41.334518
            ],
            [
              -71.91671,
              41.332217
            ],
            [
              -71.886302,
              41.33641
            ],
            [
              -71.868235,
              41.330941
            ],
            [
              -71.859566,
              41.3224
            ],
            [
              -71.860513,
              41.320248
            ],
            [
              -71.839013,
              41.334042
            ],
            [
              -71.829595,
              41.344544
            ],
            [
              -71.835951,
              41.353935
            ],
            [
              -71.837738,
              41.363529
            ],
            [
              -71.831613,
              41.370899
            ],
            [
              -71.83279927624969,
              41.379731248034
            ],
            [
              -71.833443,
              41.384524
            ],
            [
              -71.842131,
              41.395359
            ],
            [
              -71.843472,
              41.40583
            ],
            [
              -71.842563,
              41.409855
            ],
            [
              -71.839649,
              41.412119
            ],
            [
              -71.81839,
              41.419599
            ],
            [
              -71.797683,
              41.416709
            ],
            [
              -71.79767369254981,
              41.4169104184967
            ],
            [
              -71.79356831588959,
              41.5057530964788
            ],
            [
              -71.7925957022037,
              41.5268010079481
            ],
            [
              -71.7893586727423,
              41.5968521603427
            ],
            [
              -71.789356,
              41.59691
            ],
            [
              -71.78785519859501,
              41.6344503677445
            ],
            [
              -71.78763664588949,
              41.639917146298195
            ],
            [
              -71.857917,
              41.644227
            ],
            [
              -71.884279,
              41.641219
            ],
            [
              -71.949616,
              41.634563
            ],
            [
              -71.954983,
              41.634799
            ],
            [
              -71.975253,
              41.638117
            ],
            [
              -71.981928,
              41.639203
            ],
            [
              -72.118566,
              41.661636
            ],
            [
              -72.119957,
              41.663301
            ],
            [
              -72.151747,
              41.667516
            ],
            [
              -72.157546,
              41.657627
            ],
            [
              -72.185111,
              41.675312
            ],
            [
              -72.211589,
              41.69441
            ],
            [
              -72.239559,
              41.714191
            ],
            [
              -72.24413,
              41.714381
            ],
            [
              -72.250045,
              41.699653
            ],
            [
              -72.274307,
              41.692607
            ],
            [
              -72.266141,
              41.677273
            ],
            [
              -72.303643,
              41.653982
            ],
            [
              -72.334226,
              41.643598
            ],
            [
              -72.32451,
              41.61857
            ],
            [
              -72.328095,
              41.605471
            ],
            [
              -72.375447,
              41.601324
            ],
            [
              -72.38415,
              41.588652
            ],
            [
              -72.410367,
              41.602581
            ],
            [
              -72.417229,
              41.590598
            ],
            [
              -72.42886,
              41.588986
            ],
            [
              -72.46673,
              41.583899
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "09",
      "COUNTYFP": "001",
      "COUNTYNS": "00212794",
      "AFFGEOID": "0500000US09001",
      "GEOID": "09001",
      "NAME": "Fairfield",
      "LSAD": "06",
      "ALAND": 1618664433,
      "AWATER": 549280551,
      "County_state": "Fairfield,09"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -73.727775,
              41.100696
            ],
            [
              -73.722575,
              41.093596
            ],
            [
              -73.716875,
              41.087596
            ],
            [
              -73.70616305989901,
              41.074183562832594
            ],
            [
              -73.694273,
              41.059296
            ],
            [
              -73.687173,
              41.050697
            ],
            [
              -73.679973,
              41.041797
            ],
            [
              -73.670472,
              41.030097
            ],
            [
              -73.662672,
              41.020497
            ],
            [
              -73.655371,
              41.012797
            ],
            [
              -73.65535923695079,
              41.012778515208396
            ],
            [
              -73.654671,
              41.011697
            ],
            [
              -73.655571,
              41.007697
            ],
            [
              -73.659372,
              40.999497
            ],
            [
              -73.658772,
              40.993497
            ],
            [
              -73.659671,
              40.987909
            ],
            [
              -73.6576158881351,
              40.98549919431
            ],
            [
              -73.657336,
              40.985171
            ],
            [
              -73.651175,
              40.995229
            ],
            [
              -73.643478,
              41.002171
            ],
            [
              -73.603952,
              41.015054
            ],
            [
              -73.595699,
              41.015995
            ],
            [
              -73.584988,
              41.010537
            ],
            [
              -73.583968,
              41.000897
            ],
            [
              -73.570068,
              41.001597
            ],
            [
              -73.567668,
              41.010897
            ],
            [
              -73.561968,
              41.016797
            ],
            [
              -73.5528704814779,
              41.023345232970996
            ],
            [
              -73.551494,
              41.024336
            ],
            [
              -73.5395024237203,
              41.0299651232054
            ],
            [
              -73.535338,
              41.03192
            ],
            [
              -73.532786,
              41.03167
            ],
            [
              -73.530189,
              41.028776
            ],
            [
              -73.531169,
              41.021919
            ],
            [
              -73.528866,
              41.016397
            ],
            [
              -73.522666,
              41.019297
            ],
            [
              -73.51880927306568,
              41.0259645618187
            ],
            [
              -73.516766,
              41.029497
            ],
            [
              -73.516903,
              41.038738
            ],
            [
              -73.49570843204229,
              41.0472199625331
            ],
            [
              -73.493327,
              41.048173
            ],
            [
              -73.477364,
              41.035997
            ],
            [
              -73.468239,
              41.051347
            ],
            [
              -73.450364,
              41.057096
            ],
            [
              -73.44684360823071,
              41.0570039696289
            ],
            [
              -73.435063,
              41.056696
            ],
            [
              -73.4318523132305,
              41.05442228214049
            ],
            [
              -73.422165,
              41.047562
            ],
            [
              -73.387227,
              41.058247
            ],
            [
              -73.38377972268879,
              41.0611087959786
            ],
            [
              -73.354231,
              41.085639
            ],
            [
              -73.372296,
              41.10402
            ],
            [
              -73.3451802197774,
              41.107911918114404
            ],
            [
              -73.33066,
              41.109996
            ],
            [
              -73.31186,
              41.116296
            ],
            [
              -73.3114526554267,
              41.1165430188368
            ],
            [
              -73.2983961626033,
              41.1244606391542
            ],
            [
              -73.296359,
              41.125696
            ],
            [
              -73.286759,
              41.127896
            ],
            [
              -73.262358,
              41.117496
            ],
            [
              -73.247958,
              41.126396
            ],
            [
              -73.2352703552089,
              41.1437062750638
            ],
            [
              -73.235058,
              41.143996
            ],
            [
              -73.2348411938807,
              41.143951311588005
            ],
            [
              -73.228295,
              41.142602
            ],
            [
              -73.220273079983,
              41.14744975649369
            ],
            [
              -73.202656,
              41.158096
            ],
            [
              -73.1846672794473,
              41.1643141892723
            ],
            [
              -73.177774,
              41.166697
            ],
            [
              -73.170701,
              41.164945
            ],
            [
              -73.170074,
              41.160532
            ],
            [
              -73.16437020894459,
              41.1585646568859
            ],
            [
              -73.130253,
              41.146797
            ],
            [
              -73.111052,
              41.150797
            ],
            [
              -73.108352,
              41.153718
            ],
            [
              -73.109952,
              41.156997
            ],
            [
              -73.110352,
              41.159697
            ],
            [
              -73.107987,
              41.168738
            ],
            [
              -73.121997,
              41.178927
            ],
            [
              -73.109253,
              41.204097
            ],
            [
              -73.11034,
              41.213294
            ],
            [
              -73.109953,
              41.229396
            ],
            [
              -73.091215,
              41.246411
            ],
            [
              -73.086752,
              41.254596
            ],
            [
              -73.088682,
              41.271737
            ],
            [
              -73.08153,
              41.282856
            ],
            [
              -73.068751,
              41.289196
            ],
            [
              -73.065251,
              41.294896
            ],
            [
              -73.067851,
              41.300996
            ],
            [
              -73.082052,
              41.313096
            ],
            [
              -73.090938,
              41.319256
            ],
            [
              -73.115754,
              41.332196
            ],
            [
              -73.122854,
              41.341396
            ],
            [
              -73.124654,
              41.345296
            ],
            [
              -73.136054,
              41.351196
            ],
            [
              -73.147555,
              41.367696
            ],
            [
              -73.157055,
              41.376696
            ],
            [
              -73.157977,
              41.382959
            ],
            [
              -73.183269,
              41.391711
            ],
            [
              -73.186557,
              41.408596
            ],
            [
              -73.207688,
              41.420544
            ],
            [
              -73.220857,
              41.429577
            ],
            [
              -73.235911,
              41.426153
            ],
            [
              -73.245259,
              41.439895
            ],
            [
              -73.264524,
              41.435072
            ],
            [
              -73.273696,
              41.451645
            ],
            [
              -73.29776,
              41.448276
            ],
            [
              -73.310473,
              41.468815
            ],
            [
              -73.331656,
              41.472729
            ],
            [
              -73.375263,
              41.489194
            ],
            [
              -73.386964,
              41.514794
            ],
            [
              -73.430765,
              41.497994
            ],
            [
              -73.441466,
              41.491294
            ],
            [
              -73.445266,
              41.509494
            ],
            [
              -73.445086,
              41.522574
            ],
            [
              -73.49781,
              41.638145
            ],
            [
              -73.484686,
              41.64743
            ],
            [
              -73.493968,
              41.661118
            ],
            [
              -73.506923,
              41.665802
            ],
            [
              -73.51823760355249,
              41.666733981689404
            ],
            [
              -73.520017,
              41.641197
            ],
            [
              -73.521041,
              41.619773
            ],
            [
              -73.530067,
              41.527194
            ],
            [
              -73.533969,
              41.479693
            ],
            [
              -73.534055,
              41.478968
            ],
            [
              -73.53415,
              41.47806
            ],
            [
              -73.534269,
              41.476911
            ],
            [
              -73.534269,
              41.476394
            ],
            [
              -73.534369,
              41.475894
            ],
            [
              -73.53529546019818,
              41.463495977276594
            ],
            [
              -73.535769,
              41.457159
            ],
            [
              -73.535857,
              41.455709
            ],
            [
              -73.535885,
              41.455236
            ],
            [
              -73.535986,
              41.45306
            ],
            [
              -73.536067,
              41.451331
            ],
            [
              -73.536969,
              41.441094
            ],
            [
              -73.537469,
              41.43589
            ],
            [
              -73.537673,
              41.433905
            ],
            [
              -73.541169,
              41.405994
            ],
            [
              -73.5434148559124,
              41.3767540709835
            ],
            [
              -73.543425,
              41.376622
            ],
            [
              -73.544728,
              41.366375
            ],
            [
              -73.5452425041353,
              41.3615174507099
            ],
            [
              -73.548973,
              41.326297
            ],
            [
              -73.549574,
              41.315931
            ],
            [
              -73.548929,
              41.307598
            ],
            [
              -73.550961,
              41.295422
            ],
            [
              -73.518384,
              41.256719
            ],
            [
              -73.5025525269551,
              41.237211341315195
            ],
            [
              -73.482709,
              41.21276
            ],
            [
              -73.4849111933569,
              41.2117775740592
            ],
            [
              -73.4959359310458,
              41.206859300983105
            ],
            [
              -73.509487,
              41.200814
            ],
            [
              -73.514617,
              41.198434
            ],
            [
              -73.55465007369439,
              41.1799273346628
            ],
            [
              -73.5647586721642,
              41.17525428731359
            ],
            [
              -73.564941,
              41.17517
            ],
            [
              -73.61439109108869,
              41.152914810371
            ],
            [
              -73.632153,
              41.144921
            ],
            [
              -73.639672,
              41.141495
            ],
            [
              -73.6959488909347,
              41.1154341295047
            ],
            [
              -73.727775,
              41.100696
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "09",
      "COUNTYFP": "013",
      "COUNTYNS": "00212668",
      "AFFGEOID": "0500000US09013",
      "GEOID": "09013",
      "NAME": "Tolland",
      "LSAD": "06",
      "ALAND": 1062807475,
      "AWATER": 17549693,
      "County_state": "Tolland,09"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -72.51733,
              41.869897
            ],
            [
              -72.51833,
              41.856899
            ],
            [
              -72.49681,
              41.860616
            ],
            [
              -72.50543,
              41.820298
            ],
            [
              -72.507531,
              41.809698
            ],
            [
              -72.485404,
              41.812233
            ],
            [
              -72.476029,
              41.812997
            ],
            [
              -72.473468,
              41.798264
            ],
            [
              -72.472281,
              41.79096
            ],
            [
              -72.463899,
              41.745572
            ],
            [
              -72.460129,
              41.727299
            ],
            [
              -72.452229,
              41.688799
            ],
            [
              -72.448129,
              41.671898
            ],
            [
              -72.429429,
              41.662299
            ],
            [
              -72.409217,
              41.630749
            ],
            [
              -72.410367,
              41.602581
            ],
            [
              -72.38415,
              41.588652
            ],
            [
              -72.375447,
              41.601324
            ],
            [
              -72.328095,
              41.605471
            ],
            [
              -72.32451,
              41.61857
            ],
            [
              -72.334226,
              41.643598
            ],
            [
              -72.303643,
              41.653982
            ],
            [
              -72.266141,
              41.677273
            ],
            [
              -72.274307,
              41.692607
            ],
            [
              -72.250045,
              41.699653
            ],
            [
              -72.24413,
              41.714381
            ],
            [
              -72.239559,
              41.714191
            ],
            [
              -72.246596,
              41.722901
            ],
            [
              -72.252643,
              41.728202
            ],
            [
              -72.242202,
              41.728147
            ],
            [
              -72.216443,
              41.728035
            ],
            [
              -72.198126,
              41.728176
            ],
            [
              -72.186977,
              41.755427
            ],
            [
              -72.156321,
              41.753491
            ],
            [
              -72.164498,
              41.834913
            ],
            [
              -72.217719,
              41.835184
            ],
            [
              -72.21698,
              41.958161
            ],
            [
              -72.151654,
              41.959302
            ],
            [
              -72.099229,
              41.958948
            ],
            [
              -72.1021687771907,
              42.028899460948004
            ],
            [
              -72.135687,
              42.030245
            ],
            [
              -72.135715,
              42.0302453396816
            ],
            [
              -72.2050807834552,
              42.031086849809796
            ],
            [
              -72.249523,
              42.031626
            ],
            [
              -72.2706561367986,
              42.031713813847496
            ],
            [
              -72.317148,
              42.031907
            ],
            [
              -72.3669917600491,
              42.0326543063241
            ],
            [
              -72.39743337454189,
              42.0331107167355
            ],
            [
              -72.45668,
              42.033999
            ],
            [
              -72.5092040512358,
              42.0342165913446
            ],
            [
              -72.492729,
              41.947497
            ],
            [
              -72.51333,
              41.945401
            ],
            [
              -72.51733,
              41.869897
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "09",
      "COUNTYFP": "005",
      "COUNTYNS": "00212796",
      "AFFGEOID": "0500000US09005",
      "GEOID": "09005",
      "NAME": "Litchfield",
      "LSAD": "06",
      "ALAND": 2384058125,
      "AWATER": 62393362,
      "County_state": "Litchfield,09"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -73.516785,
              41.687581
            ],
            [
              -73.51823760355249,
              41.666733981689404
            ],
            [
              -73.506923,
              41.665802
            ],
            [
              -73.493968,
              41.661118
            ],
            [
              -73.484686,
              41.64743
            ],
            [
              -73.49781,
              41.638145
            ],
            [
              -73.445086,
              41.522574
            ],
            [
              -73.445266,
              41.509494
            ],
            [
              -73.441466,
              41.491294
            ],
            [
              -73.430765,
              41.497994
            ],
            [
              -73.386964,
              41.514794
            ],
            [
              -73.375263,
              41.489194
            ],
            [
              -73.331656,
              41.472729
            ],
            [
              -73.310473,
              41.468815
            ],
            [
              -73.327114,
              41.484527
            ],
            [
              -73.320874,
              41.503615
            ],
            [
              -73.252265,
              41.512134
            ],
            [
              -73.204197,
              41.508527
            ],
            [
              -73.170371,
              41.512587
            ],
            [
              -73.15573,
              41.515279
            ],
            [
              -73.164676,
              41.557088
            ],
            [
              -73.145036,
              41.557822
            ],
            [
              -73.093853,
              41.570196
            ],
            [
              -73.082955,
              41.585616
            ],
            [
              -73.064209,
              41.588491
            ],
            [
              -73.05801,
              41.588647
            ],
            [
              -73.058024,
              41.60674
            ],
            [
              -73.050362,
              41.609748
            ],
            [
              -73.036156,
              41.615197
            ],
            [
              -73.01865,
              41.614097
            ],
            [
              -73.021471,
              41.627104
            ],
            [
              -72.98325,
              41.639598
            ],
            [
              -72.99855,
              41.712498
            ],
            [
              -72.99895,
              41.714398
            ],
            [
              -73.016936,
              41.798183
            ],
            [
              -72.949024,
              41.806431
            ],
            [
              -72.936348,
              41.839798
            ],
            [
              -72.953647,
              41.853885
            ],
            [
              -72.940647,
              41.895196
            ],
            [
              -72.915346,
              41.896797
            ],
            [
              -72.905945,
              41.921696
            ],
            [
              -72.88706,
              41.973121
            ],
            [
              -73.029537,
              41.966606
            ],
            [
              -73.0087643066587,
              42.038860282198
            ],
            [
              -73.053254,
              42.039861
            ],
            [
              -73.0532880861326,
              42.039861968461295
            ],
            [
              -73.12727749969179,
              42.0419641690369
            ],
            [
              -73.229798,
              42.044877
            ],
            [
              -73.231056,
              42.044945
            ],
            [
              -73.2329591865244,
              42.045006199160504
            ],
            [
              -73.293097,
              42.04694
            ],
            [
              -73.29442,
              42.046984
            ],
            [
              -73.432812,
              42.050587
            ],
            [
              -73.487314,
              42.049638
            ],
            [
              -73.489615,
              42.000092
            ],
            [
              -73.492975,
              41.958524
            ],
            [
              -73.496527,
              41.92238
            ],
            [
              -73.498304,
              41.892508
            ],
            [
              -73.501984,
              41.858717
            ],
            [
              -73.504944,
              41.824285
            ],
            [
              -73.505008,
              41.823773
            ],
            [
              -73.510961,
              41.758749
            ],
            [
              -73.511921,
              41.740941
            ],
            [
              -73.516785,
              41.687581
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "09",
      "COUNTYFP": "003",
      "COUNTYNS": "00212338",
      "AFFGEOID": "0500000US09003",
      "GEOID": "09003",
      "NAME": "Hartford",
      "LSAD": "06",
      "ALAND": 1903983151,
      "AWATER": 40128067,
      "County_state": "Hartford,09"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -73.0087643066587,
              42.038860282198
            ],
            [
              -73.029537,
              41.966606
            ],
            [
              -72.88706,
              41.973121
            ],
            [
              -72.905945,
              41.921696
            ],
            [
              -72.915346,
              41.896797
            ],
            [
              -72.940647,
              41.895196
            ],
            [
              -72.953647,
              41.853885
            ],
            [
              -72.936348,
              41.839798
            ],
            [
              -72.949024,
              41.806431
            ],
            [
              -73.016936,
              41.798183
            ],
            [
              -72.99895,
              41.714398
            ],
            [
              -72.99855,
              41.712498
            ],
            [
              -72.98325,
              41.639598
            ],
            [
              -72.963931,
              41.641318
            ],
            [
              -72.938501,
              41.644311
            ],
            [
              -72.933644,
              41.607794
            ],
            [
              -72.945512,
              41.55958
            ],
            [
              -72.94635,
              41.556797
            ],
            [
              -72.90347,
              41.561708
            ],
            [
              -72.903213,
              41.561747
            ],
            [
              -72.879256,
              41.563152
            ],
            [
              -72.867806,
              41.54898
            ],
            [
              -72.852707,
              41.546291
            ],
            [
              -72.844147,
              41.544697
            ],
            [
              -72.840244,
              41.569098
            ],
            [
              -72.827644,
              41.570999
            ],
            [
              -72.826141,
              41.555897
            ],
            [
              -72.801243,
              41.573098
            ],
            [
              -72.752181,
              41.578894
            ],
            [
              -72.752941,
              41.592497
            ],
            [
              -72.739077,
              41.594794
            ],
            [
              -72.727041,
              41.602398
            ],
            [
              -72.714538,
              41.60431
            ],
            [
              -72.711274,
              41.617693
            ],
            [
              -72.713897,
              41.627705
            ],
            [
              -72.627836,
              41.640199
            ],
            [
              -72.630524,
              41.633282
            ],
            [
              -72.54423,
              41.642696
            ],
            [
              -72.505431,
              41.646999
            ],
            [
              -72.46673,
              41.583899
            ],
            [
              -72.42886,
              41.588986
            ],
            [
              -72.417229,
              41.590598
            ],
            [
              -72.410367,
              41.602581
            ],
            [
              -72.409217,
              41.630749
            ],
            [
              -72.429429,
              41.662299
            ],
            [
              -72.448129,
              41.671898
            ],
            [
              -72.452229,
              41.688799
            ],
            [
              -72.460129,
              41.727299
            ],
            [
              -72.463899,
              41.745572
            ],
            [
              -72.472281,
              41.79096
            ],
            [
              -72.473468,
              41.798264
            ],
            [
              -72.476029,
              41.812997
            ],
            [
              -72.485404,
              41.812233
            ],
            [
              -72.507531,
              41.809698
            ],
            [
              -72.50543,
              41.820298
            ],
            [
              -72.49681,
              41.860616
            ],
            [
              -72.51833,
              41.856899
            ],
            [
              -72.51733,
              41.869897
            ],
            [
              -72.51333,
              41.945401
            ],
            [
              -72.492729,
              41.947497
            ],
            [
              -72.5092040512358,
              42.0342165913446
            ],
            [
              -72.528131,
              42.034295
            ],
            [
              -72.55285820422401,
              42.032017465491194
            ],
            [
              -72.573231,
              42.030141
            ],
            [
              -72.582332,
              42.024695
            ],
            [
              -72.590233,
              42.024695
            ],
            [
              -72.606933,
              42.024995
            ],
            [
              -72.6069560573249,
              42.0251287324845
            ],
            [
              -72.607933,
              42.030795
            ],
            [
              -72.6079909707697,
              42.0307976349601
            ],
            [
              -72.643134,
              42.032395
            ],
            [
              -72.695927,
              42.036788
            ],
            [
              -72.755838,
              42.036195
            ],
            [
              -72.757538,
              42.033295
            ],
            [
              -72.753538,
              42.032095
            ],
            [
              -72.751738,
              42.030195
            ],
            [
              -72.754038,
              42.025395
            ],
            [
              -72.757467,
              42.020947
            ],
            [
              -72.758151,
              42.020865
            ],
            [
              -72.760558,
              42.021846
            ],
            [
              -72.762151,
              42.021527
            ],
            [
              -72.76231,
              42.019775
            ],
            [
              -72.761354,
              42.018183
            ],
            [
              -72.759738,
              42.016995
            ],
            [
              -72.761238,
              42.014595
            ],
            [
              -72.763238,
              42.012795
            ],
            [
              -72.763265,
              42.009742
            ],
            [
              -72.766139,
              42.007695
            ],
            [
              -72.766739,
              42.002995
            ],
            [
              -72.77475938856001,
              42.002128832682196
            ],
            [
              -72.816741,
              41.997595
            ],
            [
              -72.813541,
              42.036494
            ],
            [
              -72.847142,
              42.036894
            ],
            [
              -72.863619,
              42.037709
            ],
            [
              -72.86363841078709,
              42.0377091702701
            ],
            [
              -72.863733,
              42.03771
            ],
            [
              -72.9789786343673,
              42.0385101754816
            ],
            [
              -72.999549,
              42.038653
            ],
            [
              -73.0087643066587,
              42.038860282198
            ]
          ]
        ]
      ]
    }
  }
]
};