export const counties_ri = {
  "type":"FeatureCollection","name":"ri_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "44",
      "COUNTYFP": "005",
      "COUNTYNS": "01219779",
      "AFFGEOID": "0500000US44005",
      "GEOID": "44005",
      "NAME": "Newport",
      "LSAD": "06",
      "ALAND": 265310311,
      "AWATER": 546985247,
      "County_state": "Newport,44"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -71.36152,
              41.464831
            ],
            [
              -71.362743,
              41.460379
            ],
            [
              -71.351096,
              41.450802
            ],
            [
              -71.337695,
              41.448902
            ],
            [
              -71.32141,
              41.4556
            ],
            [
              -71.312718,
              41.454597
            ],
            [
              -71.312694,
              41.451402
            ],
            [
              -71.311394,
              41.450802
            ],
            [
              -71.304394,
              41.454502
            ],
            [
              -71.295111,
              41.48435
            ],
            [
              -71.285639,
              41.487805
            ],
            [
              -71.28469928125611,
              41.487854451763496
            ],
            [
              -71.264793,
              41.488902
            ],
            [
              -71.252692,
              41.485902
            ],
            [
              -71.245992,
              41.481302
            ],
            [
              -71.246703,
              41.47196
            ],
            [
              -71.24071,
              41.474872
            ],
            [
              -71.236751,
              41.483369
            ],
            [
              -71.237175,
              41.486546
            ],
            [
              -71.238586,
              41.486845
            ],
            [
              -71.240614,
              41.500557
            ],
            [
              -71.2330561810456,
              41.5147571742521
            ],
            [
              -71.229444,
              41.521544
            ],
            [
              -71.227989,
              41.528297
            ],
            [
              -71.234775,
              41.532538
            ],
            [
              -71.236642,
              41.535852
            ],
            [
              -71.23613,
              41.574767
            ],
            [
              -71.2436,
              41.587508
            ],
            [
              -71.242867469599,
              41.5955445502349
            ],
            [
              -71.240709,
              41.619225
            ],
            [
              -71.2280567270736,
              41.6224539091158
            ],
            [
              -71.21616,
              41.62549
            ],
            [
              -71.212004,
              41.62299
            ],
            [
              -71.2122770662031,
              41.619882466937796
            ],
            [
              -71.212417,
              41.61829
            ],
            [
              -71.212656,
              41.610072
            ],
            [
              -71.20778,
              41.60066
            ],
            [
              -71.20865,
              41.571028
            ],
            [
              -71.213563,
              41.545818
            ],
            [
              -71.200788,
              41.514371
            ],
            [
              -71.206382,
              41.499215
            ],
            [
              -71.199692,
              41.495511
            ],
            [
              -71.19939,
              41.491769
            ],
            [
              -71.190167,
              41.484285
            ],
            [
              -71.190016,
              41.478275
            ],
            [
              -71.196607,
              41.464756
            ],
            [
              -71.196857,
              41.461116
            ],
            [
              -71.194967,
              41.459037
            ],
            [
              -71.19302,
              41.457931
            ],
            [
              -71.170131,
              41.463974
            ],
            [
              -71.167345,
              41.471405
            ],
            [
              -71.140224,
              41.485855
            ],
            [
              -71.141093,
              41.489937
            ],
            [
              -71.136867,
              41.493942
            ],
            [
              -71.12057,
              41.497448
            ],
            [
              -71.1224,
              41.522156
            ],
            [
              -71.131312,
              41.592308
            ],
            [
              -71.131618,
              41.593918
            ],
            [
              -71.137492,
              41.602561
            ],
            [
              -71.140588,
              41.605102
            ],
            [
              -71.14091,
              41.607405
            ],
            [
              -71.141509,
              41.616076
            ],
            [
              -71.140468,
              41.623893
            ],
            [
              -71.135688,
              41.628402
            ],
            [
              -71.135675749492,
              41.6285321972593
            ],
            [
              -71.13470117132269,
              41.6388899233839
            ],
            [
              -71.134484,
              41.641198
            ],
            [
              -71.134478,
              41.641262
            ],
            [
              -71.13267,
              41.658744
            ],
            [
              -71.132888,
              41.660102
            ],
            [
              -71.134688,
              41.660502
            ],
            [
              -71.135188,
              41.660502
            ],
            [
              -71.14587,
              41.662795
            ],
            [
              -71.153989,
              41.664102
            ],
            [
              -71.1621482835032,
              41.665578726574005
            ],
            [
              -71.17609,
              41.668102
            ],
            [
              -71.17609,
              41.668502
            ],
            [
              -71.17599,
              41.671402
            ],
            [
              -71.18129,
              41.672502
            ],
            [
              -71.191175,
              41.674292
            ],
            [
              -71.191178,
              41.674216
            ],
            [
              -71.194384,
              41.674803
            ],
            [
              -71.19564,
              41.67509
            ],
            [
              -71.204984,
              41.658519
            ],
            [
              -71.2117707854053,
              41.6562010017748
            ],
            [
              -71.21919,
              41.653667
            ],
            [
              -71.233234,
              41.64023
            ],
            [
              -71.2450808614103,
              41.639267542075004
            ],
            [
              -71.251082,
              41.63878
            ],
            [
              -71.25548440534429,
              41.635645775521496
            ],
            [
              -71.271862,
              41.623986
            ],
            [
              -71.275234,
              41.619444
            ],
            [
              -71.2742236702447,
              41.617867642128694
            ],
            [
              -71.272412,
              41.615041
            ],
            [
              -71.273445,
              41.60699
            ],
            [
              -71.285142,
              41.577127
            ],
            [
              -71.288376,
              41.573274
            ],
            [
              -71.2884836215711,
              41.5732406008219
            ],
            [
              -71.294363,
              41.571416
            ],
            [
              -71.3014570726041,
              41.562640244989296
            ],
            [
              -71.303652,
              41.559925
            ],
            [
              -71.310533,
              41.54692
            ],
            [
              -71.313079,
              41.534672
            ],
            [
              -71.3206551509205,
              41.5277121155243
            ],
            [
              -71.330831,
              41.518364
            ],
            [
              -71.330694,
              41.507699
            ],
            [
              -71.3368430086962,
              41.502332474477896
            ],
            [
              -71.341122,
              41.498598
            ],
            [
              -71.343013,
              41.495615
            ],
            [
              -71.327822,
              41.482985
            ],
            [
              -71.327379,
              41.476386
            ],
            [
              -71.335992,
              41.469647
            ],
            [
              -71.34707,
              41.47123
            ],
            [
              -71.36152,
              41.464831
            ]
          ]
        ],
        [
          [
            [
              -71.366165,
              41.66098
            ],
            [
              -71.362869,
              41.651457
            ],
            [
              -71.351294,
              41.635785
            ],
            [
              -71.34657,
              41.632229
            ],
            [
              -71.333305,
              41.629536
            ],
            [
              -71.325877,
              41.623988
            ],
            [
              -71.326609,
              41.616114
            ],
            [
              -71.329559,
              41.609097
            ],
            [
              -71.333751,
              41.605859
            ],
            [
              -71.337048,
              41.594688
            ],
            [
              -71.335949,
              41.585898
            ],
            [
              -71.3312,
              41.580318
            ],
            [
              -71.326103,
              41.578583
            ],
            [
              -71.317474,
              41.583187
            ],
            [
              -71.307381,
              41.597984
            ],
            [
              -71.303352,
              41.606591
            ],
            [
              -71.30555,
              41.622523
            ],
            [
              -71.314889,
              41.630398
            ],
            [
              -71.326412,
              41.636044
            ],
            [
              -71.332448,
              41.643789
            ],
            [
              -71.336182,
              41.647961
            ],
            [
              -71.338696,
              41.658782
            ],
            [
              -71.344821,
              41.667005
            ],
            [
              -71.366165,
              41.66098
            ]
          ]
        ],
        [
          [
            [
              -71.40056,
              41.46094
            ],
            [
              -71.399568,
              41.448596
            ],
            [
              -71.390275,
              41.455043
            ],
            [
              -71.389284,
              41.460605
            ],
            [
              -71.383586,
              41.464782
            ],
            [
              -71.380947,
              41.474561
            ],
            [
              -71.360403,
              41.483121
            ],
            [
              -71.363292,
              41.501952
            ],
            [
              -71.359868,
              41.556308
            ],
            [
              -71.36356,
              41.57086
            ],
            [
              -71.370194,
              41.573963
            ],
            [
              -71.373618,
              41.573214
            ],
            [
              -71.379021,
              41.567772
            ],
            [
              -71.384478,
              41.556736
            ],
            [
              -71.392137,
              41.524468
            ],
            [
              -71.391005,
              41.514578
            ],
            [
              -71.378914,
              41.504948
            ],
            [
              -71.386511,
              41.493071
            ],
            [
              -71.395927,
              41.492215
            ],
            [
              -71.40056,
              41.46094
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "44",
      "COUNTYFP": "003",
      "COUNTYNS": "01219778",
      "AFFGEOID": "0500000US44003",
      "GEOID": "44003",
      "NAME": "Kent",
      "LSAD": "06",
      "ALAND": 436556188,
      "AWATER": 50710341,
      "County_state": "Kent,44"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -71.7896921119938,
              41.7251983396573
            ],
            [
              -71.789678,
              41.724734
            ],
            [
              -71.7869970249528,
              41.65606947440539
            ],
            [
              -71.786994,
              41.655992
            ],
            [
              -71.78763664588949,
              41.639917146298195
            ],
            [
              -71.78785519859501,
              41.6344503677445
            ],
            [
              -71.789356,
              41.59691
            ],
            [
              -71.7893586727423,
              41.5968521603427
            ],
            [
              -71.658757,
              41.598693
            ],
            [
              -71.643983,
              41.600979
            ],
            [
              -71.544807,
              41.601993
            ],
            [
              -71.522429,
              41.602566
            ],
            [
              -71.498095,
              41.602653
            ],
            [
              -71.484427,
              41.602591
            ],
            [
              -71.488031,
              41.615213
            ],
            [
              -71.467844,
              41.634165
            ],
            [
              -71.451366,
              41.629455
            ],
            [
              -71.442508,
              41.645818
            ],
            [
              -71.4086362699643,
              41.653819309168895
            ],
            [
              -71.409302,
              41.662643
            ],
            [
              -71.425452,
              41.670785
            ],
            [
              -71.430038,
              41.667541
            ],
            [
              -71.44247837919701,
              41.664840842852
            ],
            [
              -71.444468,
              41.664409
            ],
            [
              -71.4446192100939,
              41.665125829377295
            ],
            [
              -71.4492824598884,
              41.6872325178876
            ],
            [
              -71.449318,
              41.687401
            ],
            [
              -71.445923,
              41.691144
            ],
            [
              -71.441896,
              41.690025
            ],
            [
              -71.443082,
              41.688303
            ],
            [
              -71.441336,
              41.686446
            ],
            [
              -71.418069,
              41.684208
            ],
            [
              -71.4015669618488,
              41.684140835661296
            ],
            [
              -71.390551,
              41.684096
            ],
            [
              -71.389432,
              41.683425
            ],
            [
              -71.390775,
              41.680629
            ],
            [
              -71.38988,
              41.671903
            ],
            [
              -71.382049,
              41.667317
            ],
            [
              -71.37791,
              41.666646
            ],
            [
              -71.372988,
              41.672575
            ],
            [
              -71.365717,
              41.694947
            ],
            [
              -71.365717,
              41.711615
            ],
            [
              -71.3652079860121,
              41.7132664358317
            ],
            [
              -71.363937,
              41.71739
            ],
            [
              -71.3725294505984,
              41.720294394272294
            ],
            [
              -71.376534,
              41.721648
            ],
            [
              -71.3795677212754,
              41.7254609765188
            ],
            [
              -71.38105,
              41.727324
            ],
            [
              -71.3808661790703,
              41.736228502096594
            ],
            [
              -71.380812,
              41.738853
            ],
            [
              -71.380574,
              41.745947
            ],
            [
              -71.38458238293,
              41.757479933498296
            ],
            [
              -71.384733,
              41.757602
            ],
            [
              -71.399577,
              41.768321
            ],
            [
              -71.405203,
              41.76761
            ],
            [
              -71.417586,
              41.762254
            ],
            [
              -71.419078,
              41.761885
            ],
            [
              -71.419797,
              41.764785
            ],
            [
              -71.434069,
              41.760936
            ],
            [
              -71.440662,
              41.757795
            ],
            [
              -71.446538,
              41.748887
            ],
            [
              -71.444116,
              41.740636
            ],
            [
              -71.460249,
              41.732278
            ],
            [
              -71.460599,
              41.732268
            ],
            [
              -71.476971,
              41.731752
            ],
            [
              -71.48391,
              41.731603
            ],
            [
              -71.497849,
              41.73115
            ],
            [
              -71.53402,
              41.730181
            ],
            [
              -71.534286,
              41.730171
            ],
            [
              -71.545497,
              41.73007
            ],
            [
              -71.562938,
              41.729672
            ],
            [
              -71.667794,
              41.72746
            ],
            [
              -71.700584,
              41.726694
            ],
            [
              -71.7896921119938,
              41.7251983396573
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "44",
      "COUNTYFP": "007",
      "COUNTYNS": "01219781",
      "AFFGEOID": "0500000US44007",
      "GEOID": "44007",
      "NAME": "Providence",
      "LSAD": "06",
      "ALAND": 1060791694,
      "AWATER": 67639362,
      "County_state": "Providence,44"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -71.799242,
              42.008065
            ],
            [
              -71.797922,
              41.935395
            ],
            [
              -71.79715894256209,
              41.9162639874898
            ],
            [
              -71.7946917826635,
              41.85440853003801
            ],
            [
              -71.7944823560536,
              41.8491578858595
            ],
            [
              -71.794161,
              41.841101
            ],
            [
              -71.794161,
              41.840141
            ],
            [
              -71.792786,
              41.80867
            ],
            [
              -71.792767,
              41.807001
            ],
            [
              -71.79125806357601,
              41.774496473912
            ],
            [
              -71.791062,
              41.770273
            ],
            [
              -71.7896921119938,
              41.7251983396573
            ],
            [
              -71.700584,
              41.726694
            ],
            [
              -71.667794,
              41.72746
            ],
            [
              -71.562938,
              41.729672
            ],
            [
              -71.545497,
              41.73007
            ],
            [
              -71.534286,
              41.730171
            ],
            [
              -71.53402,
              41.730181
            ],
            [
              -71.497849,
              41.73115
            ],
            [
              -71.48391,
              41.731603
            ],
            [
              -71.476971,
              41.731752
            ],
            [
              -71.460599,
              41.732268
            ],
            [
              -71.460249,
              41.732278
            ],
            [
              -71.444116,
              41.740636
            ],
            [
              -71.446538,
              41.748887
            ],
            [
              -71.440662,
              41.757795
            ],
            [
              -71.434069,
              41.760936
            ],
            [
              -71.419797,
              41.764785
            ],
            [
              -71.419078,
              41.761885
            ],
            [
              -71.417586,
              41.762254
            ],
            [
              -71.405203,
              41.76761
            ],
            [
              -71.399577,
              41.768321
            ],
            [
              -71.384733,
              41.757602
            ],
            [
              -71.38458238293,
              41.757479933498296
            ],
            [
              -71.385566,
              41.76031
            ],
            [
              -71.385506272669,
              41.7673376355407
            ],
            [
              -71.385495,
              41.768664
            ],
            [
              -71.390831,
              41.778833
            ],
            [
              -71.390557,
              41.783708
            ],
            [
              -71.3824715642505,
              41.7850991833895
            ],
            [
              -71.381287,
              41.785303
            ],
            [
              -71.3716853333333,
              41.7833826666667
            ],
            [
              -71.371542,
              41.783354
            ],
            [
              -71.366308412104,
              41.778583662520795
            ],
            [
              -71.365125,
              41.777505
            ],
            [
              -71.36465,
              41.764742
            ],
            [
              -71.3625123288918,
              41.75752428320239
            ],
            [
              -71.3621525696299,
              41.7563095778824
            ],
            [
              -71.347076,
              41.759392
            ],
            [
              -71.342395,
              41.758965
            ],
            [
              -71.332659,
              41.765715
            ],
            [
              -71.324717,
              41.771201
            ],
            [
              -71.322678,
              41.772592
            ],
            [
              -71.31792439180039,
              41.7761573631246
            ],
            [
              -71.327896,
              41.780501
            ],
            [
              -71.329396,
              41.7826
            ],
            [
              -71.329296,
              41.7868
            ],
            [
              -71.332196,
              41.7923
            ],
            [
              -71.333896,
              41.7945
            ],
            [
              -71.335797,
              41.7948
            ],
            [
              -71.339297,
              41.7963
            ],
            [
              -71.340697,
              41.7983
            ],
            [
              -71.340797,
              41.8002
            ],
            [
              -71.34017342533939,
              41.8019460090498
            ],
            [
              -71.339297,
              41.8044
            ],
            [
              -71.339297,
              41.8065
            ],
            [
              -71.338897,
              41.8083
            ],
            [
              -71.339197,
              41.809
            ],
            [
              -71.347197,
              41.8231
            ],
            [
              -71.344897,
              41.828
            ],
            [
              -71.339597,
              41.832
            ],
            [
              -71.337597,
              41.8337
            ],
            [
              -71.335197,
              41.8355
            ],
            [
              -71.341797,
              41.8437
            ],
            [
              -71.342198,
              41.8448
            ],
            [
              -71.3344939886823,
              41.8612394827533
            ],
            [
              -71.333997,
              41.8623
            ],
            [
              -71.3374129679819,
              41.871993895317004
            ],
            [
              -71.3378288661725,
              41.873174138675
            ],
            [
              -71.340798,
              41.8816
            ],
            [
              -71.339298,
              41.893399
            ],
            [
              -71.339298,
              41.893599
            ],
            [
              -71.338698,
              41.898399
            ],
            [
              -71.3387624937221,
              41.898391169178794
            ],
            [
              -71.3396650579154,
              41.898281579926
            ],
            [
              -71.352699,
              41.896699
            ],
            [
              -71.3545188256255,
              41.8965170174374
            ],
            [
              -71.354699,
              41.896499
            ],
            [
              -71.362499,
              41.895599
            ],
            [
              -71.364699,
              41.895399
            ],
            [
              -71.365399,
              41.895299
            ],
            [
              -71.370999,
              41.894599
            ],
            [
              -71.373799,
              41.894399
            ],
            [
              -71.3766,
              41.893999
            ],
            [
              -71.3815292380827,
              41.8932257861831
            ],
            [
              -71.3817,
              41.893199
            ],
            [
              -71.3817,
              41.89375174725279
            ],
            [
              -71.3817,
              41.9146596532258
            ],
            [
              -71.3817,
              41.914816
            ],
            [
              -71.3817,
              41.922699
            ],
            [
              -71.3816,
              41.922899
            ],
            [
              -71.381401,
              41.964799
            ],
            [
              -71.381501,
              41.966699
            ],
            [
              -71.3814657108006,
              41.9850843199885
            ],
            [
              -71.381401,
              42.018798
            ],
            [
              -71.42435692338171,
              42.018218023987295
            ],
            [
              -71.4581035191943,
              42.0177623891285
            ],
            [
              -71.498258301062,
              42.01722023315919
            ],
            [
              -71.499905,
              42.017198
            ],
            [
              -71.500905,
              42.017098
            ],
            [
              -71.5020555032736,
              42.0170108439625
            ],
            [
              -71.527306,
              42.015098
            ],
            [
              -71.527606,
              42.014998
            ],
            [
              -71.5291172465257,
              42.0149668569183
            ],
            [
              -71.559439,
              42.014342
            ],
            [
              -71.576908,
              42.014098
            ],
            [
              -71.58686389633739,
              42.013868822007396
            ],
            [
              -71.60624200979849,
              42.0134227509563
            ],
            [
              -71.6453334734301,
              42.0125228919322
            ],
            [
              -71.6808758163105,
              42.011704731264594
            ],
            [
              -71.76601,
              42.009745
            ],
            [
              -71.799242,
              42.008065
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "44",
      "COUNTYFP": "009",
      "COUNTYNS": "01219782",
      "AFFGEOID": "0500000US44009",
      "GEOID": "44009",
      "NAME": "Washington",
      "LSAD": "06",
      "ALAND": 852825659,
      "AWATER": 604771644,
      "County_state": "Washington,44"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -71.613133,
              41.160281
            ],
            [
              -71.611706,
              41.153239
            ],
            [
              -71.599993,
              41.146932
            ],
            [
              -71.5937,
              41.146339
            ],
            [
              -71.551953,
              41.151718
            ],
            [
              -71.547051,
              41.153684
            ],
            [
              -71.543872,
              41.161321
            ],
            [
              -71.544446,
              41.164912
            ],
            [
              -71.550226,
              41.166787
            ],
            [
              -71.560969,
              41.176186
            ],
            [
              -71.565752,
              41.184373
            ],
            [
              -71.564119,
              41.195372
            ],
            [
              -71.557459,
              41.204542
            ],
            [
              -71.554067,
              41.212957
            ],
            [
              -71.555006,
              41.216822
            ],
            [
              -71.561093,
              41.224207
            ],
            [
              -71.573785,
              41.228436
            ],
            [
              -71.576661,
              41.224434
            ],
            [
              -71.577301,
              41.21471
            ],
            [
              -71.580228,
              41.204837
            ],
            [
              -71.58955,
              41.196557
            ],
            [
              -71.594994,
              41.188392
            ],
            [
              -71.605565,
              41.182139
            ],
            [
              -71.613133,
              41.160281
            ]
          ]
        ],
        [
          [
            [
              -71.862109,
              41.316612
            ],
            [
              -71.862772,
              41.309791
            ],
            [
              -71.857432,
              41.306318
            ],
            [
              -71.833755,
              41.315631
            ],
            [
              -71.785957,
              41.325739
            ],
            [
              -71.773702,
              41.327977
            ],
            [
              -71.7292470045153,
              41.3309903573844
            ],
            [
              -71.72074,
              41.331567
            ],
            [
              -71.701631,
              41.336968
            ],
            [
              -71.68807,
              41.342823
            ],
            [
              -71.624505,
              41.36087
            ],
            [
              -71.555381,
              41.373316
            ],
            [
              -71.526724,
              41.376636
            ],
            [
              -71.52324019767251,
              41.376130282541396
            ],
            [
              -71.513401,
              41.374702
            ],
            [
              -71.483295,
              41.371722
            ],
            [
              -71.474918,
              41.386104
            ],
            [
              -71.455371,
              41.407962
            ],
            [
              -71.455845,
              41.432986
            ],
            [
              -71.4546807571771,
              41.4339132416741
            ],
            [
              -71.448948,
              41.438479
            ],
            [
              -71.441199,
              41.441602
            ],
            [
              -71.4404734588792,
              41.441540321808894
            ],
            [
              -71.43767,
              41.441302
            ],
            [
              -71.433612,
              41.444995
            ],
            [
              -71.428652,
              41.454158
            ],
            [
              -71.427935,
              41.459529
            ],
            [
              -71.430926,
              41.465655
            ],
            [
              -71.430744,
              41.470636
            ],
            [
              -71.422991,
              41.472682
            ],
            [
              -71.421157,
              41.469888
            ],
            [
              -71.418404,
              41.472652
            ],
            [
              -71.417621,
              41.477934
            ],
            [
              -71.417957,
              41.482073
            ],
            [
              -71.419971,
              41.484758
            ],
            [
              -71.421425,
              41.498629
            ],
            [
              -71.41942513594459,
              41.504076841756195
            ],
            [
              -71.414937,
              41.516303
            ],
            [
              -71.414825,
              41.523126
            ],
            [
              -71.417398,
              41.534536
            ],
            [
              -71.421649,
              41.537892
            ],
            [
              -71.442567,
              41.565075
            ],
            [
              -71.447712,
              41.5804
            ],
            [
              -71.4136281238605,
              41.5873196272141
            ],
            [
              -71.40377,
              41.589321
            ],
            [
              -71.4086362699643,
              41.653819309168895
            ],
            [
              -71.442508,
              41.645818
            ],
            [
              -71.451366,
              41.629455
            ],
            [
              -71.467844,
              41.634165
            ],
            [
              -71.488031,
              41.615213
            ],
            [
              -71.484427,
              41.602591
            ],
            [
              -71.498095,
              41.602653
            ],
            [
              -71.522429,
              41.602566
            ],
            [
              -71.544807,
              41.601993
            ],
            [
              -71.643983,
              41.600979
            ],
            [
              -71.658757,
              41.598693
            ],
            [
              -71.7893586727423,
              41.5968521603427
            ],
            [
              -71.7925957022037,
              41.5268010079481
            ],
            [
              -71.79356831588959,
              41.5057530964788
            ],
            [
              -71.79767369254981,
              41.4169104184967
            ],
            [
              -71.797683,
              41.416709
            ],
            [
              -71.81839,
              41.419599
            ],
            [
              -71.839649,
              41.412119
            ],
            [
              -71.842563,
              41.409855
            ],
            [
              -71.843472,
              41.40583
            ],
            [
              -71.842131,
              41.395359
            ],
            [
              -71.833443,
              41.384524
            ],
            [
              -71.83279927624969,
              41.379731248034
            ],
            [
              -71.831613,
              41.370899
            ],
            [
              -71.837738,
              41.363529
            ],
            [
              -71.835951,
              41.353935
            ],
            [
              -71.829595,
              41.344544
            ],
            [
              -71.839013,
              41.334042
            ],
            [
              -71.860513,
              41.320248
            ],
            [
              -71.862109,
              41.316612
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "44",
      "COUNTYFP": "001",
      "COUNTYNS": "01219777",
      "AFFGEOID": "0500000US44001",
      "GEOID": "44001",
      "NAME": "Bristol",
      "LSAD": "06",
      "ALAND": 62513687,
      "AWATER": 53346252,
      "County_state": "Bristol,44"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -71.3621525696299,
              41.7563095778824
            ],
            [
              -71.360134,
              41.749494
            ],
            [
              -71.35530956508128,
              41.7467674931165
            ],
            [
              -71.35229,
              41.745061
            ],
            [
              -71.344447,
              41.736193
            ],
            [
              -71.336131,
              41.724959
            ],
            [
              -71.323231515559,
              41.724262303149004
            ],
            [
              -71.31482,
              41.723808
            ],
            [
              -71.305759,
              41.718662
            ],
            [
              -71.2953439306392,
              41.707205566944296
            ],
            [
              -71.291217,
              41.702666
            ],
            [
              -71.293119,
              41.688347
            ],
            [
              -71.298935,
              41.681524
            ],
            [
              -71.302627,
              41.681747
            ],
            [
              -71.306095,
              41.672575
            ],
            [
              -71.303746,
              41.654788
            ],
            [
              -71.301396,
              41.649978
            ],
            [
              -71.299159,
              41.649531
            ],
            [
              -71.2949806812448,
              41.6456960093985
            ],
            [
              -71.29015128154589,
              41.641263436504694
            ],
            [
              -71.285346,
              41.636853
            ],
            [
              -71.272692,
              41.638535
            ],
            [
              -71.2698691597587,
              41.641744935417194
            ],
            [
              -71.267055,
              41.644945
            ],
            [
              -71.254697,
              41.649059
            ],
            [
              -71.253416,
              41.660593
            ],
            [
              -71.24155,
              41.667205
            ],
            [
              -71.235352,
              41.672134
            ],
            [
              -71.2364820310652,
              41.6768367705434
            ],
            [
              -71.237635,
              41.681635
            ],
            [
              -71.245335,
              41.694855
            ],
            [
              -71.2428492200865,
              41.6965081809783
            ],
            [
              -71.240991,
              41.697744
            ],
            [
              -71.227875,
              41.705498
            ],
            [
              -71.224798,
              41.710498
            ],
            [
              -71.225709,
              41.711603
            ],
            [
              -71.2262389298232,
              41.7122074078117
            ],
            [
              -71.261392,
              41.752301
            ],
            [
              -71.2844017716869,
              41.762010280821
            ],
            [
              -71.3163878442731,
              41.775507231826296
            ],
            [
              -71.317795,
              41.776101
            ],
            [
              -71.31792439180039,
              41.7761573631246
            ],
            [
              -71.322678,
              41.772592
            ],
            [
              -71.324717,
              41.771201
            ],
            [
              -71.332659,
              41.765715
            ],
            [
              -71.342395,
              41.758965
            ],
            [
              -71.347076,
              41.759392
            ],
            [
              -71.3621525696299,
              41.7563095778824
            ]
          ]
        ]
      ]
    }
  }
]
};