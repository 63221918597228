export const counties_vt = {
  "type":"FeatureCollection","name":"vt_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "50",
      "COUNTYFP": "015",
      "COUNTYNS": "01461764",
      "AFFGEOID": "0500000US50015",
      "GEOID": "50015",
      "NAME": "Lamoille",
      "LSAD": "06",
      "ALAND": 1188473310,
      "AWATER": 12658077,
      "County_state": "Lamoille,50"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -72.926689,
              44.647232
            ],
            [
              -72.924112,
              44.633051
            ],
            [
              -72.910572,
              44.630855
            ],
            [
              -72.829073,
              44.552273
            ],
            [
              -72.809349,
              44.547921
            ],
            [
              -72.830756,
              44.51643
            ],
            [
              -72.803567,
              44.506172
            ],
            [
              -72.838638,
              44.459229
            ],
            [
              -72.804995,
              44.451676
            ],
            [
              -72.765856,
              44.442595
            ],
            [
              -72.654398,
              44.398224
            ],
            [
              -72.596807,
              44.479292
            ],
            [
              -72.492914,
              44.42303
            ],
            [
              -72.490548,
              44.425693
            ],
            [
              -72.434315,
              44.506098
            ],
            [
              -72.429847,
              44.512045
            ],
            [
              -72.374786,
              44.584125
            ],
            [
              -72.368151,
              44.592287
            ],
            [
              -72.482302,
              44.633928
            ],
            [
              -72.476651,
              44.641841
            ],
            [
              -72.417315,
              44.722893
            ],
            [
              -72.578161,
              44.781578
            ],
            [
              -72.593494,
              44.790616
            ],
            [
              -72.612102,
              44.801587
            ],
            [
              -72.675841,
              44.794213
            ],
            [
              -72.744603,
              44.786457
            ],
            [
              -72.75645,
              44.766438
            ],
            [
              -72.76703,
              44.766371
            ],
            [
              -72.771553,
              44.740262
            ],
            [
              -72.783056,
              44.740291
            ],
            [
              -72.795505,
              44.6922
            ],
            [
              -72.851068,
              44.71107
            ],
            [
              -72.890924,
              44.656944
            ],
            [
              -72.902743,
              44.646175
            ],
            [
              -72.922689,
              44.64977
            ],
            [
              -72.926689,
              44.647232
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "50",
      "COUNTYFP": "025",
      "COUNTYNS": "01461769",
      "AFFGEOID": "0500000US50025",
      "GEOID": "50025",
      "NAME": "Windham",
      "LSAD": "06",
      "ALAND": 2034441181,
      "AWATER": 32925693,
      "County_state": "Windham,50"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -73.002796,
              43.022882
            ],
            [
              -73.008063,
              42.930087
            ],
            [
              -72.921376,
              42.926792
            ],
            [
              -72.926024,
              42.829915
            ],
            [
              -72.928908,
              42.76771
            ],
            [
              -72.930260821819,
              42.739160469970095
            ],
            [
              -72.8827924320332,
              42.7381497767183
            ],
            [
              -72.809113,
              42.736581
            ],
            [
              -72.7910764312897,
              42.7360805358152
            ],
            [
              -72.68617347799702,
              42.733169773139196
            ],
            [
              -72.5167111827822,
              42.72846766982919
            ],
            [
              -72.458519,
              42.726853
            ],
            [
              -72.458488,
              42.729094
            ],
            [
              -72.461001,
              42.733209
            ],
            [
              -72.467827,
              42.741209
            ],
            [
              -72.473071,
              42.745916
            ],
            [
              -72.474723,
              42.750729
            ],
            [
              -72.475938,
              42.757702
            ],
            [
              -72.477615,
              42.761245
            ],
            [
              -72.479354,
              42.763119
            ],
            [
              -72.484878,
              42.76554
            ],
            [
              -72.4864,
              42.76698
            ],
            [
              -72.487767,
              42.76938
            ],
            [
              -72.491122,
              42.772465
            ],
            [
              -72.495343,
              42.773286
            ],
            [
              -72.497949,
              42.772918
            ],
            [
              -72.498786,
              42.771981
            ],
            [
              -72.499249,
              42.769054
            ],
            [
              -72.50069,
              42.767657
            ],
            [
              -72.507985,
              42.764414
            ],
            [
              -72.510871,
              42.763752
            ],
            [
              -72.513105,
              42.763822
            ],
            [
              -72.516082,
              42.765949
            ],
            [
              -72.516731,
              42.76867
            ],
            [
              -72.514836,
              42.771436
            ],
            [
              -72.510154,
              42.773221
            ],
            [
              -72.508372,
              42.77461
            ],
            [
              -72.508048,
              42.776885
            ],
            [
              -72.508858,
              42.779919
            ],
            [
              -72.511746,
              42.784114
            ],
            [
              -72.515838,
              42.78856
            ],
            [
              -72.518354,
              42.790651
            ],
            [
              -72.5396,
              42.804832
            ],
            [
              -72.542784,
              42.808482
            ],
            [
              -72.54611935078601,
              42.8238750073899
            ],
            [
              -72.546133,
              42.823938
            ],
            [
              -72.547434,
              42.832603
            ],
            [
              -72.547402,
              42.837587
            ],
            [
              -72.54855,
              42.842021
            ],
            [
              -72.553426,
              42.846709
            ],
            [
              -72.5565766339102,
              42.851911957334
            ],
            [
              -72.557247,
              42.853019
            ],
            [
              -72.554232,
              42.860038
            ],
            [
              -72.555132,
              42.865731
            ],
            [
              -72.556112,
              42.866252
            ],
            [
              -72.556214,
              42.86695
            ],
            [
              -72.555131,
              42.871058
            ],
            [
              -72.555415,
              42.875428
            ],
            [
              -72.552834,
              42.884968
            ],
            [
              -72.552025,
              42.885631
            ],
            [
              -72.546491,
              42.88714
            ],
            [
              -72.540708,
              42.889379
            ],
            [
              -72.537287,
              42.89187
            ],
            [
              -72.532777,
              42.896076
            ],
            [
              -72.5327503134955,
              42.8961142343344
            ],
            [
              -72.531469,
              42.89795
            ],
            [
              -72.531588,
              42.907164
            ],
            [
              -72.530218,
              42.911576
            ],
            [
              -72.529191,
              42.912719
            ],
            [
              -72.525271,
              42.914363
            ],
            [
              -72.52443,
              42.915575
            ],
            [
              -72.524242,
              42.918501
            ],
            [
              -72.527097,
              42.928584
            ],
            [
              -72.526346,
              42.935717
            ],
            [
              -72.526624,
              42.939901
            ],
            [
              -72.527431,
              42.943148
            ],
            [
              -72.52855,
              42.94532
            ],
            [
              -72.529763,
              42.94612
            ],
            [
              -72.531693,
              42.94651
            ],
            [
              -72.533901,
              42.948591
            ],
            [
              -72.534554,
              42.949894
            ],
            [
              -72.534117,
              42.952133
            ],
            [
              -72.532186,
              42.954945
            ],
            [
              -72.518422,
              42.96317
            ],
            [
              -72.507901,
              42.964171
            ],
            [
              -72.504226,
              42.965815
            ],
            [
              -72.492597,
              42.967648
            ],
            [
              -72.486872,
              42.971789
            ],
            [
              -72.481706,
              42.973985
            ],
            [
              -72.479245,
              42.973597
            ],
            [
              -72.476722,
              42.971746
            ],
            [
              -72.473827,
              42.972045
            ],
            [
              -72.465985,
              42.97847
            ],
            [
              -72.461627,
              42.982906
            ],
            [
              -72.461597,
              42.984049
            ],
            [
              -72.464026,
              42.986107
            ],
            [
              -72.465335,
              42.989558
            ],
            [
              -72.464714,
              42.993582
            ],
            [
              -72.46294,
              42.996943
            ],
            [
              -72.459951,
              43.00008
            ],
            [
              -72.456936,
              43.001306
            ],
            [
              -72.451797,
              43.000577
            ],
            [
              -72.448714,
              43.001169
            ],
            [
              -72.444977,
              43.004416
            ],
            [
              -72.443762,
              43.006245
            ],
            [
              -72.443825,
              43.008965
            ],
            [
              -72.444635,
              43.010566
            ],
            [
              -72.452984,
              43.015731
            ],
            [
              -72.457035,
              43.017285
            ],
            [
              -72.458998,
              43.019388
            ],
            [
              -72.462397,
              43.02556
            ],
            [
              -72.46299,
              43.028531
            ],
            [
              -72.460905,
              43.035961
            ],
            [
              -72.460252,
              43.040671
            ],
            [
              -72.462248,
              43.044214
            ],
            [
              -72.465896,
              43.047505
            ],
            [
              -72.466832,
              43.049197
            ],
            [
              -72.467363,
              43.052648
            ],
            [
              -72.466491,
              43.054729
            ],
            [
              -72.463812,
              43.057404
            ],
            [
              -72.45471,
              43.063487
            ],
            [
              -72.445202,
              43.071352
            ],
            [
              -72.43987,
              43.077043
            ],
            [
              -72.43619,
              43.08173
            ],
            [
              -72.435316,
              43.083536
            ],
            [
              -72.435191,
              43.086622
            ],
            [
              -72.439214,
              43.094852
            ],
            [
              -72.443051,
              43.100841
            ],
            [
              -72.442427,
              43.10363
            ],
            [
              -72.440587,
              43.106145
            ],
            [
              -72.434845,
              43.109917
            ],
            [
              -72.433129,
              43.112637
            ],
            [
              -72.432661,
              43.114077
            ],
            [
              -72.432972,
              43.119655
            ],
            [
              -72.435936,
              43.123381
            ],
            [
              -72.4379502256947,
              43.1250733339319
            ],
            [
              -72.440967,
              43.127608
            ],
            [
              -72.442933,
              43.130192
            ],
            [
              -72.442746,
              43.131152
            ],
            [
              -72.44078,
              43.131472
            ],
            [
              -72.440624,
              43.132203
            ],
            [
              -72.440905,
              43.135793
            ],
            [
              -72.441904,
              43.136615
            ],
            [
              -72.444214,
              43.13737
            ],
            [
              -72.448303,
              43.137187
            ],
            [
              -72.451986,
              43.138924
            ],
            [
              -72.45689,
              43.146558
            ],
            [
              -72.45714,
              43.148493
            ],
            [
              -72.456537,
              43.149528
            ],
            [
              -72.452801,
              43.151977
            ],
            [
              -72.451802,
              43.153486
            ],
            [
              -72.451553,
              43.155155
            ],
            [
              -72.45209374428919,
              43.1613424195726
            ],
            [
              -72.4521,
              43.161414
            ],
            [
              -72.451868,
              43.170174
            ],
            [
              -72.452556,
              43.172117
            ],
            [
              -72.444904,
              43.177969
            ],
            [
              -72.443405,
              43.179729
            ],
            [
              -72.443749,
              43.182221
            ],
            [
              -72.449435,
              43.18917
            ],
            [
              -72.45028,
              43.192485
            ],
            [
              -72.438969,
              43.201035
            ],
            [
              -72.437719,
              43.20275
            ],
            [
              -72.438594,
              43.209013
            ],
            [
              -72.440563,
              43.215254
            ],
            [
              -72.4405,
              43.219049
            ],
            [
              -72.437656,
              43.225266
            ],
            [
              -72.434466,
              43.230432
            ],
            [
              -72.4337736887345,
              43.2330834990285
            ],
            [
              -72.549912,
              43.235809
            ],
            [
              -72.685637,
              43.222495
            ],
            [
              -72.73027,
              43.233249
            ],
            [
              -72.755812,
              43.239805
            ],
            [
              -72.819368,
              43.255498
            ],
            [
              -72.847918,
              43.262488
            ],
            [
              -72.861994,
              43.205095
            ],
            [
              -72.864931,
              43.166229
            ],
            [
              -72.868737,
              43.11317
            ],
            [
              -72.997126,
              43.116994
            ],
            [
              -72.998677,
              43.109304
            ],
            [
              -73.002796,
              43.022882
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "50",
      "COUNTYFP": "011",
      "COUNTYNS": "01461762",
      "AFFGEOID": "0500000US50011",
      "GEOID": "50011",
      "NAME": "Franklin",
      "LSAD": "06",
      "ALAND": 1641633748,
      "AWATER": 150930318,
      "County_state": "Franklin,50"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -73.25906,
              44.771977
            ],
            [
              -73.256321,
              44.759444
            ],
            [
              -73.228854,
              44.722866
            ],
            [
              -73.128196,
              44.685525
            ],
            [
              -73.038028,
              44.651157
            ],
            [
              -72.924112,
              44.633051
            ],
            [
              -72.926689,
              44.647232
            ],
            [
              -72.922689,
              44.64977
            ],
            [
              -72.902743,
              44.646175
            ],
            [
              -72.890924,
              44.656944
            ],
            [
              -72.851068,
              44.71107
            ],
            [
              -72.795505,
              44.6922
            ],
            [
              -72.783056,
              44.740291
            ],
            [
              -72.771553,
              44.740262
            ],
            [
              -72.76703,
              44.766371
            ],
            [
              -72.75645,
              44.766438
            ],
            [
              -72.744603,
              44.786457
            ],
            [
              -72.675841,
              44.794213
            ],
            [
              -72.612102,
              44.801587
            ],
            [
              -72.593494,
              44.790616
            ],
            [
              -72.578161,
              44.781578
            ],
            [
              -72.532638,
              44.833346
            ],
            [
              -72.538743,
              44.925602
            ],
            [
              -72.538029,
              44.935979
            ],
            [
              -72.5543599324424,
              45.0082745618354
            ],
            [
              -72.555912,
              45.008304
            ],
            [
              -72.582371,
              45.011543
            ],
            [
              -72.586752,
              45.012881
            ],
            [
              -72.58988,
              45.013237
            ],
            [
              -72.67477,
              45.015459
            ],
            [
              -72.7026243478423,
              45.0155714648904
            ],
            [
              -72.777306,
              45.015873
            ],
            [
              -72.845633,
              45.016659
            ],
            [
              -72.930599,
              45.015152
            ],
            [
              -72.936365,
              45.014656
            ],
            [
              -72.93644,
              45.014267
            ],
            [
              -72.968039,
              45.014098
            ],
            [
              -73.014766,
              45.01498
            ],
            [
              -73.015539,
              45.015072
            ],
            [
              -73.048386,
              45.01479
            ],
            [
              -73.052438,
              45.015721
            ],
            [
              -73.059685,
              45.015869
            ],
            [
              -73.065098,
              45.014786
            ],
            [
              -73.084969,
              45.014751
            ],
            [
              -73.085972,
              45.015494
            ],
            [
              -73.17015001270079,
              45.014005718362796
            ],
            [
              -73.19192810038889,
              45.013620678054096
            ],
            [
              -73.229711,
              44.959308
            ],
            [
              -73.240994,
              44.949575
            ],
            [
              -73.230777,
              44.927437
            ],
            [
              -73.192787,
              44.9087
            ],
            [
              -73.19402,
              44.886844
            ],
            [
              -73.208864,
              44.823471
            ],
            [
              -73.24188,
              44.80437
            ],
            [
              -73.25906,
              44.771977
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "50",
      "COUNTYFP": "027",
      "COUNTYNS": "01461770",
      "AFFGEOID": "0500000US50027",
      "GEOID": "50027",
      "NAME": "Windsor",
      "LSAD": "06",
      "ALAND": 2511193707,
      "AWATER": 19363219,
      "County_state": "Windsor,50"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -72.980158,
              43.882587
            ],
            [
              -72.980165,
              43.863928
            ],
            [
              -72.972896,
              43.866692
            ],
            [
              -72.958853,
              43.826542
            ],
            [
              -72.874552,
              43.858131
            ],
            [
              -72.873366,
              43.839633
            ],
            [
              -72.837595,
              43.829177
            ],
            [
              -72.831096,
              43.839016
            ],
            [
              -72.790659,
              43.823844
            ],
            [
              -72.782812,
              43.804338
            ],
            [
              -72.818605,
              43.746305
            ],
            [
              -72.806063,
              43.74243
            ],
            [
              -72.820043,
              43.716834
            ],
            [
              -72.700945,
              43.682831
            ],
            [
              -72.758005,
              43.587517
            ],
            [
              -72.772829,
              43.591565
            ],
            [
              -72.774982,
              43.579375
            ],
            [
              -72.79107,
              43.495
            ],
            [
              -72.770286,
              43.491832
            ],
            [
              -72.771543,
              43.477862
            ],
            [
              -72.749541,
              43.474428
            ],
            [
              -72.727184,
              43.468717
            ],
            [
              -72.723995,
              43.461694
            ],
            [
              -72.737299,
              43.421779
            ],
            [
              -72.758518,
              43.381953
            ],
            [
              -72.759799,
              43.354144
            ],
            [
              -72.825913,
              43.38029
            ],
            [
              -72.853439,
              43.371076
            ],
            [
              -72.853691,
              43.347584
            ],
            [
              -72.866076,
              43.34697
            ],
            [
              -72.867902,
              43.300742
            ],
            [
              -72.8199,
              43.29921
            ],
            [
              -72.819368,
              43.255498
            ],
            [
              -72.755812,
              43.239805
            ],
            [
              -72.73027,
              43.233249
            ],
            [
              -72.685637,
              43.222495
            ],
            [
              -72.549912,
              43.235809
            ],
            [
              -72.4337736887345,
              43.2330834990285
            ],
            [
              -72.433684,
              43.233427
            ],
            [
              -72.434216,
              43.234958
            ],
            [
              -72.436654,
              43.238319
            ],
            [
              -72.438937,
              43.24424
            ],
            [
              -72.438693,
              43.252905
            ],
            [
              -72.436378,
              43.257454
            ],
            [
              -72.435221,
              43.258483
            ],
            [
              -72.421583,
              43.263442
            ],
            [
              -72.41545,
              43.271374
            ],
            [
              -72.407842,
              43.282892
            ],
            [
              -72.401666,
              43.303395
            ],
            [
              -72.395462,
              43.312994
            ],
            [
              -72.395805,
              43.314617
            ],
            [
              -72.397619,
              43.317064
            ],
            [
              -72.402532,
              43.32038
            ],
            [
              -72.408696,
              43.327674
            ],
            [
              -72.410197,
              43.330395
            ],
            [
              -72.410353,
              43.331675
            ],
            [
              -72.409037,
              43.334395
            ],
            [
              -72.4066991286665,
              43.3376975044409
            ],
            [
              -72.4066245346759,
              43.3378028767861
            ],
            [
              -72.400981,
              43.345775
            ],
            [
              -72.399289,
              43.347581
            ],
            [
              -72.395403,
              43.350414
            ],
            [
              -72.39092,
              43.354984
            ],
            [
              -72.390103,
              43.356926
            ],
            [
              -72.39217,
              43.357865
            ],
            [
              -72.400441,
              43.357685
            ],
            [
              -72.403949,
              43.358098
            ],
            [
              -72.413377,
              43.362741
            ],
            [
              -72.414692,
              43.364273
            ],
            [
              -72.415099,
              43.365896
            ],
            [
              -72.415978,
              43.376531
            ],
            [
              -72.415381,
              43.380211
            ],
            [
              -72.413154,
              43.384302
            ],
            [
              -72.405253,
              43.389992
            ],
            [
              -72.403811,
              43.391935
            ],
            [
              -72.401624713554,
              43.403259726150395
            ],
            [
              -72.400131,
              43.410997
            ],
            [
              -72.399972,
              43.415249
            ],
            [
              -72.3964568073635,
              43.4288773047854
            ],
            [
              -72.3964397283084,
              43.428943519810396
            ],
            [
              -72.395916,
              43.430974
            ],
            [
              -72.395659,
              43.438541
            ],
            [
              -72.393992,
              43.444666
            ],
            [
              -72.391196,
              43.449305
            ],
            [
              -72.390567,
              43.451225
            ],
            [
              -72.392628,
              43.465078
            ],
            [
              -72.3925,
              43.467364
            ],
            [
              -72.391526,
              43.46878
            ],
            [
              -72.384491,
              43.474195
            ],
            [
              -72.382951,
              43.476
            ],
            [
              -72.381723,
              43.480091
            ],
            [
              -72.380428,
              43.488525
            ],
            [
              -72.380362,
              43.491634
            ],
            [
              -72.380894,
              43.493394
            ],
            [
              -72.384773,
              43.500259
            ],
            [
              -72.389556,
              43.503899
            ],
            [
              -72.396305,
              43.508062
            ],
            [
              -72.398376,
              43.510829
            ],
            [
              -72.398563,
              43.513435
            ],
            [
              -72.395825,
              43.52056
            ],
            [
              -72.395949,
              43.52388
            ],
            [
              -72.394218,
              43.5274
            ],
            [
              -72.3907,
              43.527261
            ],
            [
              -72.389097,
              43.528266
            ],
            [
              -72.38331,
              43.53519
            ],
            [
              -72.380383,
              43.54088
            ],
            [
              -72.381187,
              43.554915
            ],
            [
              -72.382783,
              43.562459
            ],
            [
              -72.382625,
              43.564127
            ],
            [
              -72.37944,
              43.574069
            ],
            [
              -72.373126,
              43.579419
            ],
            [
              -72.363916,
              43.583652
            ],
            [
              -72.349926,
              43.587726
            ],
            [
              -72.332382,
              43.599364
            ],
            [
              -72.3296772840816,
              43.6001836405589
            ],
            [
              -72.32962,
              43.600201
            ],
            [
              -72.328514,
              43.600805
            ],
            [
              -72.327665,
              43.602679
            ],
            [
              -72.3281646788123,
              43.606345073825395
            ],
            [
              -72.328232,
              43.606839
            ],
            [
              -72.329522,
              43.608393
            ],
            [
              -72.3327,
              43.610313
            ],
            [
              -72.334745,
              43.614519
            ],
            [
              -72.334401,
              43.61925
            ],
            [
              -72.33236,
              43.62507
            ],
            [
              -72.328966,
              43.626991
            ],
            [
              -72.327236,
              43.630534
            ],
            [
              -72.327362,
              43.631174
            ],
            [
              -72.329471,
              43.632843
            ],
            [
              -72.32966,
              43.634648
            ],
            [
              -72.329126,
              43.635563
            ],
            [
              -72.327395,
              43.636774
            ],
            [
              -72.322517,
              43.638901
            ],
            [
              -72.315247,
              43.641164
            ],
            [
              -72.314083,
              43.64281
            ],
            [
              -72.313863,
              43.646558
            ],
            [
              -72.315059,
              43.649415
            ],
            [
              -72.31402,
              43.656158
            ],
            [
              -72.312887,
              43.658444
            ],
            [
              -72.310841,
              43.659724
            ],
            [
              -72.305771,
              43.666535
            ],
            [
              -72.304322,
              43.669507
            ],
            [
              -72.303408,
              43.674055
            ],
            [
              -72.303092,
              43.678078
            ],
            [
              -72.304351,
              43.681141
            ],
            [
              -72.30602,
              43.683061
            ],
            [
              -72.3055044139206,
              43.6925027629444
            ],
            [
              -72.3055042006241,
              43.6925066689756
            ],
            [
              -72.305326,
              43.69577
            ],
            [
              -72.302867,
              43.702718
            ],
            [
              -72.299715,
              43.706558
            ],
            [
              -72.294894,
              43.709003
            ],
            [
              -72.292215,
              43.711333
            ],
            [
              -72.28695,
              43.717252
            ],
            [
              -72.284805,
              43.72036
            ],
            [
              -72.279855,
              43.724633
            ],
            [
              -72.276072,
              43.727054
            ],
            [
              -72.27118,
              43.734138
            ],
            [
              -72.264245,
              43.734158
            ],
            [
              -72.245068,
              43.743093
            ],
            [
              -72.232713,
              43.748286
            ],
            [
              -72.223645,
              43.757842
            ],
            [
              -72.222069,
              43.759831
            ],
            [
              -72.220116,
              43.763626
            ],
            [
              -72.218099,
              43.765729
            ],
            [
              -72.216491,
              43.766507
            ],
            [
              -72.210815,
              43.767696
            ],
            [
              -72.207535,
              43.769274
            ],
            [
              -72.20520479908029,
              43.7709441872538
            ],
            [
              -72.337451,
              43.809705
            ],
            [
              -72.460132,
              43.838291
            ],
            [
              -72.574443,
              43.873348
            ],
            [
              -72.703521,
              43.909347
            ],
            [
              -72.783208,
              43.928967
            ],
            [
              -72.791336,
              43.961855
            ],
            [
              -72.807032,
              43.940507
            ],
            [
              -72.81633,
              43.937805
            ],
            [
              -72.865283,
              43.861656
            ],
            [
              -72.874176,
              43.86406
            ],
            [
              -72.876556,
              43.902765
            ],
            [
              -72.953083,
              43.87487
            ],
            [
              -72.980158,
              43.882587
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "50",
      "COUNTYFP": "021",
      "COUNTYNS": "01461767",
      "AFFGEOID": "0500000US50021",
      "GEOID": "50021",
      "NAME": "Rutland",
      "LSAD": "06",
      "ALAND": 2408212536,
      "AWATER": 38758591,
      "County_state": "Rutland,50"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -73.431229,
              43.588285
            ],
            [
              -73.430947,
              43.587036
            ],
            [
              -73.428636,
              43.583994
            ],
            [
              -73.426663,
              43.582974
            ],
            [
              -73.420378,
              43.581489
            ],
            [
              -73.416964,
              43.57773
            ],
            [
              -73.405629,
              43.571179
            ],
            [
              -73.400295,
              43.568889
            ],
            [
              -73.398125,
              43.568065
            ],
            [
              -73.395767,
              43.568087
            ],
            [
              -73.39196,
              43.569915
            ],
            [
              -73.384188,
              43.575512
            ],
            [
              -73.383369,
              43.57677
            ],
            [
              -73.382549,
              43.579193
            ],
            [
              -73.383426,
              43.584727
            ],
            [
              -73.383446,
              43.596778
            ],
            [
              -73.377748,
              43.599656
            ],
            [
              -73.373443,
              43.603292
            ],
            [
              -73.372469,
              43.604848
            ],
            [
              -73.372375,
              43.606014
            ],
            [
              -73.376036,
              43.612596
            ],
            [
              -73.374557,
              43.614677
            ],
            [
              -73.369933,
              43.619093
            ],
            [
              -73.36987,
              43.619711
            ],
            [
              -73.372486,
              43.622751
            ],
            [
              -73.371889,
              43.624489
            ],
            [
              -73.368899,
              43.62471
            ],
            [
              -73.367167,
              43.623622
            ],
            [
              -73.365562,
              43.62344
            ],
            [
              -73.35911,
              43.624598
            ],
            [
              -73.358593,
              43.625065
            ],
            [
              -73.347621,
              43.622509
            ],
            [
              -73.342181,
              43.62607
            ],
            [
              -73.327702,
              43.625913
            ],
            [
              -73.323893,
              43.627629
            ],
            [
              -73.317566,
              43.627355
            ],
            [
              -73.312809,
              43.624602
            ],
            [
              -73.310606,
              43.624114
            ],
            [
              -73.307682,
              43.627492
            ],
            [
              -73.306234,
              43.628018
            ],
            [
              -73.304125,
              43.627057
            ],
            [
              -73.302552,
              43.625708
            ],
            [
              -73.302076,
              43.624364
            ],
            [
              -73.300285,
              43.610806
            ],
            [
              -73.29802,
              43.610028
            ],
            [
              -73.293741,
              43.605203
            ],
            [
              -73.292232,
              43.60255
            ],
            [
              -73.292202,
              43.59816
            ],
            [
              -73.292801,
              43.593861
            ],
            [
              -73.293242,
              43.592558
            ],
            [
              -73.296924,
              43.587323
            ],
            [
              -73.292364,
              43.585104
            ],
            [
              -73.292113,
              43.584509
            ],
            [
              -73.29444,
              43.582494
            ],
            [
              -73.295344,
              43.580235
            ],
            [
              -73.294621,
              43.57897
            ],
            [
              -73.293536,
              43.578518
            ],
            [
              -73.284912,
              43.579272
            ],
            [
              -73.281296,
              43.577579
            ],
            [
              -73.280952,
              43.575407
            ],
            [
              -73.279726,
              43.574241
            ],
            [
              -73.26938,
              43.571973
            ],
            [
              -73.264099,
              43.568884
            ],
            [
              -73.258631,
              43.564949
            ],
            [
              -73.252602,
              43.556851
            ],
            [
              -73.248641,
              43.553857
            ],
            [
              -73.24842,
              43.552577
            ],
            [
              -73.250408,
              43.550425
            ],
            [
              -73.250132,
              43.543429
            ],
            [
              -73.247812,
              43.542814
            ],
            [
              -73.246585,
              43.541855
            ],
            [
              -73.2455893651413,
              43.540336234961295
            ],
            [
              -73.242042,
              43.534925
            ],
            [
              -73.241589,
              43.534973
            ],
            [
              -73.24139,
              43.532345
            ],
            [
              -73.241891,
              43.529418
            ],
            [
              -73.243366,
              43.527726
            ],
            [
              -73.246821,
              43.52578
            ],
            [
              -73.247698,
              43.523173
            ],
            [
              -73.247631,
              43.51924
            ],
            [
              -73.24672,
              43.518875
            ],
            [
              -73.247061,
              43.514919
            ],
            [
              -73.248401,
              43.470443
            ],
            [
              -73.2487638638259,
              43.4618122044894
            ],
            [
              -73.252582,
              43.370997
            ],
            [
              -73.252674,
              43.370285
            ],
            [
              -73.252832,
              43.363493
            ],
            [
              -73.253084,
              43.354714
            ],
            [
              -73.2545139435998,
              43.3146701262092
            ],
            [
              -73.122704,
              43.308682
            ],
            [
              -72.98613,
              43.304828
            ],
            [
              -72.991297,
              43.291642
            ],
            [
              -72.975913,
              43.304444
            ],
            [
              -72.867902,
              43.300742
            ],
            [
              -72.866076,
              43.34697
            ],
            [
              -72.853691,
              43.347584
            ],
            [
              -72.853439,
              43.371076
            ],
            [
              -72.825913,
              43.38029
            ],
            [
              -72.759799,
              43.354144
            ],
            [
              -72.758518,
              43.381953
            ],
            [
              -72.737299,
              43.421779
            ],
            [
              -72.723995,
              43.461694
            ],
            [
              -72.727184,
              43.468717
            ],
            [
              -72.749541,
              43.474428
            ],
            [
              -72.771543,
              43.477862
            ],
            [
              -72.770286,
              43.491832
            ],
            [
              -72.79107,
              43.495
            ],
            [
              -72.774982,
              43.579375
            ],
            [
              -72.772829,
              43.591565
            ],
            [
              -72.758005,
              43.587517
            ],
            [
              -72.700945,
              43.682831
            ],
            [
              -72.820043,
              43.716834
            ],
            [
              -72.806063,
              43.74243
            ],
            [
              -72.818605,
              43.746305
            ],
            [
              -72.782812,
              43.804338
            ],
            [
              -72.790659,
              43.823844
            ],
            [
              -72.831096,
              43.839016
            ],
            [
              -72.837595,
              43.829177
            ],
            [
              -72.873366,
              43.839633
            ],
            [
              -72.874552,
              43.858131
            ],
            [
              -72.958853,
              43.826542
            ],
            [
              -73.016831,
              43.804953
            ],
            [
              -73.029938,
              43.846716
            ],
            [
              -73.156173,
              43.842314
            ],
            [
              -73.218956,
              43.837291
            ],
            [
              -73.206322,
              43.766775
            ],
            [
              -73.231377,
              43.764469
            ],
            [
              -73.3629511574321,
              43.7531814596909
            ],
            [
              -73.369725,
              43.744274
            ],
            [
              -73.370287,
              43.742269
            ],
            [
              -73.370724,
              43.735571
            ],
            [
              -73.369916,
              43.728789
            ],
            [
              -73.370612,
              43.725329
            ],
            [
              -73.377756,
              43.717712
            ],
            [
              -73.382965,
              43.714058
            ],
            [
              -73.385883,
              43.711336
            ],
            [
              -73.39179,
              43.703481
            ],
            [
              -73.393723,
              43.6992
            ],
            [
              -73.395517,
              43.696831
            ],
            [
              -73.398332,
              43.694625
            ],
            [
              -73.402078,
              43.693106
            ],
            [
              -73.404739,
              43.690213
            ],
            [
              -73.405243,
              43.688367
            ],
            [
              -73.403474,
              43.684694
            ],
            [
              -73.404126,
              43.681339
            ],
            [
              -73.40739952321769,
              43.6734287329369
            ],
            [
              -73.407776,
              43.672519
            ],
            [
              -73.408061,
              43.669438
            ],
            [
              -73.414546,
              43.658209
            ],
            [
              -73.415513,
              43.65245
            ],
            [
              -73.418763,
              43.64788
            ],
            [
              -73.423539,
              43.645676
            ],
            [
              -73.425217,
              43.64429
            ],
            [
              -73.426463,
              43.642598
            ],
            [
              -73.428583,
              43.636543
            ],
            [
              -73.42791,
              43.634428
            ],
            [
              -73.418319,
              43.623325
            ],
            [
              -73.417668,
              43.621687
            ],
            [
              -73.417827,
              43.620586
            ],
            [
              -73.423708,
              43.612356
            ],
            [
              -73.423815,
              43.610989
            ],
            [
              -73.422154,
              43.606511
            ],
            [
              -73.421616,
              43.603023
            ],
            [
              -73.424977,
              43.598775
            ],
            [
              -73.430325,
              43.590532
            ],
            [
              -73.431229,
              43.588285
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "50",
      "COUNTYFP": "013",
      "COUNTYNS": "01461763",
      "AFFGEOID": "0500000US50013",
      "GEOID": "50013",
      "NAME": "Grand Isle",
      "LSAD": "06",
      "ALAND": 211881636,
      "AWATER": 292317315,
      "County_state": "Grand Isle,50"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -73.389966,
              44.61962
            ],
            [
              -73.390231,
              44.618353
            ],
            [
              -73.38982,
              44.61721
            ],
            [
              -73.382932,
              44.612184
            ],
            [
              -73.380726,
              44.605239
            ],
            [
              -73.376849,
              44.599598
            ],
            [
              -73.376332,
              44.597218
            ],
            [
              -73.376806,
              44.595455
            ],
            [
              -73.377897,
              44.593848
            ],
            [
              -73.38164,
              44.590583
            ],
            [
              -73.381848,
              44.589316
            ],
            [
              -73.377794,
              44.585128
            ],
            [
              -73.375666,
              44.582038
            ],
            [
              -73.374389,
              44.575455
            ],
            [
              -73.367275,
              44.567545
            ],
            [
              -73.3614860172538,
              44.563518406880696
            ],
            [
              -73.293811,
              44.578405
            ],
            [
              -73.267742,
              44.591029
            ],
            [
              -73.258378,
              44.600378
            ],
            [
              -73.254465,
              44.607897
            ],
            [
              -73.252756,
              44.641041
            ],
            [
              -73.233209,
              44.649791
            ],
            [
              -73.228854,
              44.722866
            ],
            [
              -73.256321,
              44.759444
            ],
            [
              -73.25906,
              44.771977
            ],
            [
              -73.24188,
              44.80437
            ],
            [
              -73.208864,
              44.823471
            ],
            [
              -73.19402,
              44.886844
            ],
            [
              -73.192787,
              44.9087
            ],
            [
              -73.230777,
              44.927437
            ],
            [
              -73.240994,
              44.949575
            ],
            [
              -73.229711,
              44.959308
            ],
            [
              -73.19192810038889,
              45.013620678054096
            ],
            [
              -73.241061,
              45.012752
            ],
            [
              -73.249323,
              45.012181
            ],
            [
              -73.343124,
              45.01084
            ],
            [
              -73.350188,
              44.994304
            ],
            [
              -73.353429,
              44.990165
            ],
            [
              -73.354633,
              44.987352
            ],
            [
              -73.354112,
              44.984062
            ],
            [
              -73.352886,
              44.980644
            ],
            [
              -73.350218,
              44.976222
            ],
            [
              -73.34474,
              44.970468
            ],
            [
              -73.338734,
              44.965886
            ],
            [
              -73.338243,
              44.96475
            ],
            [
              -73.337906,
              44.960541
            ],
            [
              -73.339603,
              44.94337
            ],
            [
              -73.338482,
              44.924112
            ],
            [
              -73.338979,
              44.917681
            ],
            [
              -73.341106,
              44.914632
            ],
            [
              -73.3427866086322,
              44.913802307163195
            ],
            [
              -73.347837,
              44.911309
            ],
            [
              -73.353657,
              44.907346
            ],
            [
              -73.356218,
              44.904492
            ],
            [
              -73.35808,
              44.901325
            ],
            [
              -73.360327,
              44.897236
            ],
            [
              -73.362229,
              44.891463
            ],
            [
              -73.366459,
              44.87504
            ],
            [
              -73.369103,
              44.86668
            ],
            [
              -73.371967,
              44.862414
            ],
            [
              -73.375709,
              44.860745
            ],
            [
              -73.379822,
              44.857037
            ],
            [
              -73.381397,
              44.848805
            ],
            [
              -73.381359,
              44.845021
            ],
            [
              -73.379452,
              44.83801
            ],
            [
              -73.378717,
              44.837358
            ],
            [
              -73.375345,
              44.836307
            ],
            [
              -73.371329,
              44.830742
            ],
            [
              -73.369647,
              44.829136
            ],
            [
              -73.365678,
              44.826451
            ],
            [
              -73.35808,
              44.82331
            ],
            [
              -73.354945,
              44.8215
            ],
            [
              -73.353472,
              44.820386
            ],
            [
              -73.3502,
              44.816394
            ],
            [
              -73.335443,
              44.804602
            ],
            [
              -73.33443,
              44.802188
            ],
            [
              -73.333933,
              44.7992
            ],
            [
              -73.333154,
              44.788759
            ],
            [
              -73.333771,
              44.785192
            ],
            [
              -73.335713,
              44.782086
            ],
            [
              -73.344254,
              44.776282
            ],
            [
              -73.347072,
              44.772988
            ],
            [
              -73.348694,
              44.768246
            ],
            [
              -73.354361,
              44.755296
            ],
            [
              -73.357671,
              44.751018
            ],
            [
              -73.363791,
              44.745254
            ],
            [
              -73.365561,
              44.741786
            ],
            [
              -73.365068,
              44.725646
            ],
            [
              -73.36556,
              44.700297
            ],
            [
              -73.361323,
              44.695369
            ],
            [
              -73.361308,
              44.694523
            ],
            [
              -73.365297,
              44.687546
            ],
            [
              -73.370142,
              44.684853
            ],
            [
              -73.369685,
              44.683758
            ],
            [
              -73.367414,
              44.681292
            ],
            [
              -73.367209,
              44.678513
            ],
            [
              -73.371089,
              44.67753
            ],
            [
              -73.371843,
              44.676956
            ],
            [
              -73.37272,
              44.668739
            ],
            [
              -73.370065,
              44.666071
            ],
            [
              -73.369669,
              44.663478
            ],
            [
              -73.37059,
              44.662518
            ],
            [
              -73.373063,
              44.662713
            ],
            [
              -73.374134,
              44.66234
            ],
            [
              -73.379074,
              44.656772
            ],
            [
              -73.378968,
              44.65518
            ],
            [
              -73.378014,
              44.653846
            ],
            [
              -73.377973,
              44.652918
            ],
            [
              -73.383157,
              44.645764
            ],
            [
              -73.378561,
              44.641475
            ],
            [
              -73.379748,
              44.64036
            ],
            [
              -73.386783,
              44.636369
            ],
            [
              -73.387169,
              44.635542
            ],
            [
              -73.385899,
              44.631044
            ],
            [
              -73.386497,
              44.626924
            ],
            [
              -73.387346,
              44.623672
            ],
            [
              -73.389966,
              44.61962
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "50",
      "COUNTYFP": "003",
      "COUNTYNS": "01461758",
      "AFFGEOID": "0500000US50003",
      "GEOID": "50003",
      "NAME": "Bennington",
      "LSAD": "06",
      "ALAND": 1748244447,
      "AWATER": 6928011,
      "County_state": "Bennington,50"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -73.286337,
              42.808038
            ],
            [
              -73.290944,
              42.80192
            ],
            [
              -73.276421,
              42.746019
            ],
            [
              -73.264957,
              42.74594
            ],
            [
              -73.1424934176269,
              42.7435079706162
            ],
            [
              -73.0230149955487,
              42.7411352241426
            ],
            [
              -73.022903,
              42.741133
            ],
            [
              -72.930260821819,
              42.739160469970095
            ],
            [
              -72.928908,
              42.76771
            ],
            [
              -72.926024,
              42.829915
            ],
            [
              -72.921376,
              42.926792
            ],
            [
              -73.008063,
              42.930087
            ],
            [
              -73.002796,
              43.022882
            ],
            [
              -72.998677,
              43.109304
            ],
            [
              -72.997126,
              43.116994
            ],
            [
              -72.868737,
              43.11317
            ],
            [
              -72.864931,
              43.166229
            ],
            [
              -72.861994,
              43.205095
            ],
            [
              -72.847918,
              43.262488
            ],
            [
              -72.819368,
              43.255498
            ],
            [
              -72.8199,
              43.29921
            ],
            [
              -72.867902,
              43.300742
            ],
            [
              -72.975913,
              43.304444
            ],
            [
              -72.991297,
              43.291642
            ],
            [
              -72.98613,
              43.304828
            ],
            [
              -73.122704,
              43.308682
            ],
            [
              -73.2545139435998,
              43.3146701262092
            ],
            [
              -73.256493,
              43.259249
            ],
            [
              -73.2586680648889,
              43.230552806826
            ],
            [
              -73.258718,
              43.229894
            ],
            [
              -73.25938653218309,
              43.2168596701502
            ],
            [
              -73.265574,
              43.096223
            ],
            [
              -73.266206375905,
              43.0871568404487
            ],
            [
              -73.26978,
              43.035923
            ],
            [
              -73.2700347484158,
              43.0307156692348
            ],
            [
              -73.274294,
              42.943652
            ],
            [
              -73.274393,
              42.942482
            ],
            [
              -73.274466,
              42.940361
            ],
            [
              -73.27464979158489,
              42.934439009859396
            ],
            [
              -73.275804,
              42.897249
            ],
            [
              -73.2784862199926,
              42.837566099299195
            ],
            [
              -73.278673,
              42.83341
            ],
            [
              -73.284311,
              42.834954
            ],
            [
              -73.285388,
              42.834093
            ],
            [
              -73.287063,
              42.82014
            ],
            [
              -73.28375,
              42.813864
            ],
            [
              -73.286337,
              42.808038
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "50",
      "COUNTYFP": "019",
      "COUNTYNS": "01461766",
      "AFFGEOID": "0500000US50019",
      "GEOID": "50019",
      "NAME": "Orleans",
      "LSAD": "06",
      "ALAND": 1796376281,
      "AWATER": 71442197,
      "County_state": "Orleans,50"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -72.532638,
              44.833346
            ],
            [
              -72.578161,
              44.781578
            ],
            [
              -72.417315,
              44.722893
            ],
            [
              -72.476651,
              44.641841
            ],
            [
              -72.482302,
              44.633928
            ],
            [
              -72.368151,
              44.592287
            ],
            [
              -72.374786,
              44.584125
            ],
            [
              -72.262965,
              44.542336
            ],
            [
              -72.237433,
              44.576818
            ],
            [
              -72.146599,
              44.703495
            ],
            [
              -72.088864,
              44.683714
            ],
            [
              -72.114503,
              44.749624
            ],
            [
              -72.012504,
              44.698821
            ],
            [
              -72.003978,
              44.70498
            ],
            [
              -71.939936,
              44.769032
            ],
            [
              -71.974318,
              44.786519
            ],
            [
              -71.925842,
              44.832237
            ],
            [
              -71.874433,
              44.876382
            ],
            [
              -71.92851,
              44.907298
            ],
            [
              -71.8976565406717,
              45.0082197635741
            ],
            [
              -71.915009,
              45.007791
            ],
            [
              -71.947201,
              45.008359
            ],
            [
              -71.986705,
              45.007872
            ],
            [
              -72.023292,
              45.006792
            ],
            [
              -72.029739,
              45.006782
            ],
            [
              -72.033614,
              45.008878
            ],
            [
              -72.052169,
              45.006369
            ],
            [
              -72.103058,
              45.005598
            ],
            [
              -72.160506,
              45.006185
            ],
            [
              -72.2467252808235,
              45.0046233140874
            ],
            [
              -72.270869,
              45.004186
            ],
            [
              -72.291866,
              45.004496
            ],
            [
              -72.310073,
              45.003822
            ],
            [
              -72.3292432299146,
              45.0047195311487
            ],
            [
              -72.348583,
              45.005625
            ],
            [
              -72.401298,
              45.006589
            ],
            [
              -72.448865,
              45.008537
            ],
            [
              -72.481033,
              45.00887
            ],
            [
              -72.532503,
              45.00786
            ],
            [
              -72.5543599324424,
              45.0082745618354
            ],
            [
              -72.538029,
              44.935979
            ],
            [
              -72.538743,
              44.925602
            ],
            [
              -72.532638,
              44.833346
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "50",
      "COUNTYFP": "007",
      "COUNTYNS": "01461760",
      "AFFGEOID": "0500000US50007",
      "GEOID": "50007",
      "NAME": "Chittenden",
      "LSAD": "06",
      "ALAND": 1389740233,
      "AWATER": 214237435,
      "County_state": "Chittenden,50"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -73.3614860172538,
              44.563518406880696
            ],
            [
              -73.360088,
              44.562546
            ],
            [
              -73.356788,
              44.557918
            ],
            [
              -73.355186,
              44.556918
            ],
            [
              -73.350027,
              44.555392
            ],
            [
              -73.342932,
              44.551907
            ],
            [
              -73.338751,
              44.548046
            ],
            [
              -73.33863,
              44.546844
            ],
            [
              -73.33863044507319,
              44.5468424276295
            ],
            [
              -73.3393,
              44.544477
            ],
            [
              -73.338995,
              44.543302
            ],
            [
              -73.331595,
              44.535924
            ],
            [
              -73.330893,
              44.534269
            ],
            [
              -73.330588,
              44.531034
            ],
            [
              -73.329458,
              44.529203
            ],
            [
              -73.328512,
              44.528478
            ],
            [
              -73.323935,
              44.52712
            ],
            [
              -73.322026,
              44.525289
            ],
            [
              -73.3211898546784,
              44.5203251296318
            ],
            [
              -73.321111,
              44.519857
            ],
            [
              -73.321416,
              44.516454
            ],
            [
              -73.320836,
              44.513631
            ],
            [
              -73.319265,
              44.51196
            ],
            [
              -73.312871,
              44.507246
            ],
            [
              -73.306707,
              44.500334
            ],
            [
              -73.304921,
              44.492209
            ],
            [
              -73.304418,
              44.485739
            ],
            [
              -73.299885,
              44.476652
            ],
            [
              -73.298939,
              44.471304
            ],
            [
              -73.298725,
              44.463957
            ],
            [
              -73.300114,
              44.454711
            ],
            [
              -73.295216,
              44.445884
            ],
            [
              -73.293613,
              44.440559
            ],
            [
              -73.29380572883369,
              44.4381674103822
            ],
            [
              -73.293855,
              44.437556
            ],
            [
              -73.296031,
              44.428339
            ],
            [
              -73.310491,
              44.402601
            ],
            [
              -73.312418,
              44.39471
            ],
            [
              -73.315016,
              44.388513
            ],
            [
              -73.317029,
              44.385978
            ],
            [
              -73.320954,
              44.382669
            ],
            [
              -73.330369,
              44.375987
            ],
            [
              -73.333575,
              44.372288
            ],
            [
              -73.334939,
              44.364441
            ],
            [
              -73.334637,
              44.356877
            ],
            [
              -73.327335,
              44.344369
            ],
            [
              -73.32695510159479,
              44.3433650565242
            ],
            [
              -73.325127,
              44.338534
            ],
            [
              -73.323997,
              44.333842
            ],
            [
              -73.323835,
              44.325418
            ],
            [
              -73.324545,
              44.319247
            ],
            [
              -73.324229,
              44.310023
            ],
            [
              -73.322267,
              44.301523
            ],
            [
              -73.316838,
              44.287683
            ],
            [
              -73.312299,
              44.280025
            ],
            [
              -73.311025,
              44.27424
            ],
            [
              -73.312852,
              44.265346
            ],
            [
              -73.3134220405831,
              44.2641991074088
            ],
            [
              -73.270378,
              44.260106
            ],
            [
              -73.1968,
              44.266634
            ],
            [
              -73.133083,
              44.275055
            ],
            [
              -73.06902,
              44.284064
            ],
            [
              -73.016523,
              44.285332
            ],
            [
              -73.020378,
              44.303744
            ],
            [
              -72.973318,
              44.29342
            ],
            [
              -72.965447,
              44.231393
            ],
            [
              -72.952167,
              44.161271
            ],
            [
              -72.89598,
              44.276284
            ],
            [
              -72.911398,
              44.281769
            ],
            [
              -72.879139,
              44.330026
            ],
            [
              -72.855592,
              44.365551
            ],
            [
              -72.826112,
              44.35919
            ],
            [
              -72.804465,
              44.390468
            ],
            [
              -72.839132,
              44.403355
            ],
            [
              -72.804995,
              44.451676
            ],
            [
              -72.838638,
              44.459229
            ],
            [
              -72.803567,
              44.506172
            ],
            [
              -72.830756,
              44.51643
            ],
            [
              -72.809349,
              44.547921
            ],
            [
              -72.829073,
              44.552273
            ],
            [
              -72.910572,
              44.630855
            ],
            [
              -72.924112,
              44.633051
            ],
            [
              -73.038028,
              44.651157
            ],
            [
              -73.128196,
              44.685525
            ],
            [
              -73.228854,
              44.722866
            ],
            [
              -73.233209,
              44.649791
            ],
            [
              -73.252756,
              44.641041
            ],
            [
              -73.254465,
              44.607897
            ],
            [
              -73.258378,
              44.600378
            ],
            [
              -73.267742,
              44.591029
            ],
            [
              -73.293811,
              44.578405
            ],
            [
              -73.3614860172538,
              44.563518406880696
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "50",
      "COUNTYFP": "023",
      "COUNTYNS": "01461768",
      "AFFGEOID": "0500000US50023",
      "GEOID": "50023",
      "NAME": "Washington",
      "LSAD": "06",
      "ALAND": 1779421703,
      "AWATER": 21184426,
      "County_state": "Washington,50"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -72.952167,
              44.161271
            ],
            [
              -72.934285,
              44.163619
            ],
            [
              -72.909019,
              44.072587
            ],
            [
              -72.896904,
              44.07366
            ],
            [
              -72.821553,
              44.052634
            ],
            [
              -72.742303,
              44.029655
            ],
            [
              -72.683772,
              44.012938
            ],
            [
              -72.634789,
              44.08945
            ],
            [
              -72.62891,
              44.088639
            ],
            [
              -72.589333,
              44.160282
            ],
            [
              -72.564001,
              44.153629
            ],
            [
              -72.559688,
              44.162858
            ],
            [
              -72.443032,
              44.128669
            ],
            [
              -72.419747,
              44.220979
            ],
            [
              -72.368353,
              44.204057
            ],
            [
              -72.316302,
              44.272583
            ],
            [
              -72.317621,
              44.298384
            ],
            [
              -72.26985,
              44.340227
            ],
            [
              -72.217104,
              44.411087
            ],
            [
              -72.220442,
              44.421197
            ],
            [
              -72.22371,
              44.424582
            ],
            [
              -72.321277,
              44.462572
            ],
            [
              -72.434315,
              44.506098
            ],
            [
              -72.490548,
              44.425693
            ],
            [
              -72.492914,
              44.42303
            ],
            [
              -72.596807,
              44.479292
            ],
            [
              -72.654398,
              44.398224
            ],
            [
              -72.765856,
              44.442595
            ],
            [
              -72.804995,
              44.451676
            ],
            [
              -72.839132,
              44.403355
            ],
            [
              -72.804465,
              44.390468
            ],
            [
              -72.826112,
              44.35919
            ],
            [
              -72.855592,
              44.365551
            ],
            [
              -72.879139,
              44.330026
            ],
            [
              -72.911398,
              44.281769
            ],
            [
              -72.89598,
              44.276284
            ],
            [
              -72.952167,
              44.161271
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "50",
      "COUNTYFP": "017",
      "COUNTYNS": "01461765",
      "AFFGEOID": "0500000US50017",
      "GEOID": "50017",
      "NAME": "Orange",
      "LSAD": "06",
      "ALAND": 1779389357,
      "AWATER": 12016544,
      "County_state": "Orange,50"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -72.791336,
              43.961855
            ],
            [
              -72.783208,
              43.928967
            ],
            [
              -72.703521,
              43.909347
            ],
            [
              -72.574443,
              43.873348
            ],
            [
              -72.460132,
              43.838291
            ],
            [
              -72.337451,
              43.809705
            ],
            [
              -72.20520479908029,
              43.7709441872538
            ],
            [
              -72.20476,
              43.771263
            ],
            [
              -72.205521,
              43.782279
            ],
            [
              -72.2053,
              43.784474
            ],
            [
              -72.20407,
              43.786097
            ],
            [
              -72.197036,
              43.790006
            ],
            [
              -72.195552,
              43.791492
            ],
            [
              -72.193184,
              43.794697
            ],
            [
              -72.190754,
              43.800807
            ],
            [
              -72.184847,
              43.804698
            ],
            [
              -72.183333,
              43.808177
            ],
            [
              -72.184184,
              43.812524
            ],
            [
              -72.186424,
              43.815857
            ],
            [
              -72.18857,
              43.821153
            ],
            [
              -72.188255,
              43.822888
            ],
            [
              -72.186238,
              43.826931
            ],
            [
              -72.183337,
              43.830699
            ],
            [
              -72.182203,
              43.834032
            ],
            [
              -72.182864,
              43.845109
            ],
            [
              -72.187379,
              43.853612
            ],
            [
              -72.187916,
              43.856126
            ],
            [
              -72.184788,
              43.863393
            ],
            [
              -72.182956,
              43.865335
            ],
            [
              -72.179386,
              43.866181
            ],
            [
              -72.174774,
              43.866386
            ],
            [
              -72.167476,
              43.86915
            ],
            [
              -72.1686464134551,
              43.87132166559049
            ],
            [
              -72.1693273369057,
              43.8725850977742
            ],
            [
              -72.16978,
              43.873425
            ],
            [
              -72.171904,
              43.876149
            ],
            [
              -72.173576,
              43.87967
            ],
            [
              -72.172785,
              43.883716
            ],
            [
              -72.171648,
              43.885361
            ],
            [
              -72.170604,
              43.886388
            ],
            [
              -72.167224,
              43.886113
            ],
            [
              -72.160819,
              43.887223
            ],
            [
              -72.159216,
              43.888313
            ],
            [
              -72.158585,
              43.892762
            ],
            [
              -72.155724,
              43.89712
            ],
            [
              -72.151324,
              43.901704
            ],
            [
              -72.145041,
              43.905288
            ],
            [
              -72.135117,
              43.910024
            ],
            [
              -72.121002,
              43.918956
            ],
            [
              -72.11919,
              43.920952
            ],
            [
              -72.118013,
              43.923292
            ],
            [
              -72.116767,
              43.933923
            ],
            [
              -72.116766,
              43.935278
            ],
            [
              -72.118985,
              43.943225
            ],
            [
              -72.118698,
              43.94536
            ],
            [
              -72.117839,
              43.946828
            ],
            [
              -72.115268,
              43.947629
            ],
            [
              -72.110872,
              43.947654
            ],
            [
              -72.105875,
              43.94937
            ],
            [
              -72.104421,
              43.950536
            ],
            [
              -72.098689,
              43.95766
            ],
            [
              -72.098955,
              43.958879
            ],
            [
              -72.100894,
              43.960851
            ],
            [
              -72.110945,
              43.966959
            ],
            [
              -72.114273,
              43.967513
            ],
            [
              -72.114726,
              43.968332
            ],
            [
              -72.114702,
              43.969478
            ],
            [
              -72.113078,
              43.97279
            ],
            [
              -72.11249,
              43.975654
            ],
            [
              -72.111756,
              43.984943
            ],
            [
              -72.112813,
              43.98802
            ],
            [
              -72.116706,
              43.991954
            ],
            [
              -72.116985,
              43.99448
            ],
            [
              -72.109019,
              44.000535
            ],
            [
              -72.103765,
              44.002837
            ],
            [
              -72.103576,
              44.004231
            ],
            [
              -72.104941,
              44.009395
            ],
            [
              -72.105292,
              44.012663
            ],
            [
              -72.102475,
              44.014882
            ],
            [
              -72.098897,
              44.015477
            ],
            [
              -72.095669,
              44.019683
            ],
            [
              -72.0951,
              44.021831
            ],
            [
              -72.094056,
              44.023179
            ],
            [
              -72.09203,
              44.024459
            ],
            [
              -72.090478,
              44.024299
            ],
            [
              -72.085257,
              44.024467
            ],
            [
              -72.081864,
              44.026952
            ],
            [
              -72.081357,
              44.028529
            ],
            [
              -72.079996,
              44.029764
            ],
            [
              -72.07964236374369,
              44.029917719531795
            ],
            [
              -72.0793090203006,
              44.0300626181306
            ],
            [
              -72.075648,
              44.031654
            ],
            [
              -72.075004,
              44.032789
            ],
            [
              -72.075486,
              44.034614
            ],
            [
              -72.079397,
              44.039531
            ],
            [
              -72.079595,
              44.041429
            ],
            [
              -72.078989,
              44.042886
            ],
            [
              -72.077372,
              44.044591
            ],
            [
              -72.074881,
              44.045892
            ],
            [
              -72.066422,
              44.049299
            ],
            [
              -72.062713,
              44.051618
            ],
            [
              -72.062069,
              44.055547
            ],
            [
              -72.058863,
              44.057921
            ],
            [
              -72.057173,
              44.058646
            ],
            [
              -72.056341,
              44.059582
            ],
            [
              -72.053482,
              44.06473
            ],
            [
              -72.048289,
              44.069136
            ],
            [
              -72.04857,
              44.071359
            ],
            [
              -72.042504,
              44.074639
            ],
            [
              -72.039783,
              44.081271
            ],
            [
              -72.047305,
              44.085382
            ],
            [
              -72.048781,
              44.087141
            ],
            [
              -72.047684,
              44.088873
            ],
            [
              -72.046235,
              44.089538
            ],
            [
              -72.040012,
              44.088762
            ],
            [
              -72.036291,
              44.089236
            ],
            [
              -72.03429,
              44.090138
            ],
            [
              -72.032894,
              44.09144
            ],
            [
              -72.031878,
              44.093359
            ],
            [
              -72.031019,
              44.097975
            ],
            [
              -72.03124,
              44.100101
            ],
            [
              -72.032983,
              44.101655
            ],
            [
              -72.036883,
              44.103119
            ],
            [
              -72.039674,
              44.103371
            ],
            [
              -72.054831,
              44.110137
            ],
            [
              -72.054675,
              44.112147
            ],
            [
              -72.052342,
              44.119891
            ],
            [
              -72.04643,
              44.123911
            ],
            [
              -72.041948,
              44.125653
            ],
            [
              -72.040728,
              44.125668
            ],
            [
              -72.038839,
              44.124628
            ],
            [
              -72.037506,
              44.124708
            ],
            [
              -72.033703,
              44.131541
            ],
            [
              -72.034242,
              44.132524
            ],
            [
              -72.037859,
              44.133782
            ],
            [
              -72.041983,
              44.137165
            ],
            [
              -72.042867,
              44.151288
            ],
            [
              -72.042708,
              44.15227
            ],
            [
              -72.040082,
              44.155749
            ],
            [
              -72.040167,
              44.157023
            ],
            [
              -72.0406490709507,
              44.1578468635977
            ],
            [
              -72.045767,
              44.157401
            ],
            [
              -72.166147,
              44.190938
            ],
            [
              -72.174381,
              44.192183
            ],
            [
              -72.30498,
              44.183149
            ],
            [
              -72.368353,
              44.204057
            ],
            [
              -72.419747,
              44.220979
            ],
            [
              -72.443032,
              44.128669
            ],
            [
              -72.559688,
              44.162858
            ],
            [
              -72.564001,
              44.153629
            ],
            [
              -72.589333,
              44.160282
            ],
            [
              -72.62891,
              44.088639
            ],
            [
              -72.634789,
              44.08945
            ],
            [
              -72.683772,
              44.012938
            ],
            [
              -72.742303,
              44.029655
            ],
            [
              -72.791336,
              43.961855
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "50",
      "COUNTYFP": "009",
      "COUNTYNS": "01461761",
      "AFFGEOID": "0500000US50009",
      "GEOID": "50009",
      "NAME": "Essex",
      "LSAD": "06",
      "ALAND": 1718717381,
      "AWATER": 28683328,
      "County_state": "Essex,50"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -71.974318,
              44.786519
            ],
            [
              -71.939936,
              44.769032
            ],
            [
              -71.837294,
              44.716637
            ],
            [
              -71.910282,
              44.647038
            ],
            [
              -71.842732,
              44.611165
            ],
            [
              -71.898111,
              44.557857
            ],
            [
              -71.908657,
              44.54708
            ],
            [
              -71.856637,
              44.496178
            ],
            [
              -71.903558,
              44.454472
            ],
            [
              -71.933288,
              44.441174
            ],
            [
              -71.83771955183909,
              44.3477442357108
            ],
            [
              -71.833261,
              44.350136
            ],
            [
              -71.826246,
              44.352006
            ],
            [
              -71.818838,
              44.352939
            ],
            [
              -71.814351,
              44.354541
            ],
            [
              -71.812902,
              44.355547
            ],
            [
              -71.812206,
              44.357356
            ],
            [
              -71.812473,
              44.358477
            ],
            [
              -71.814991,
              44.363686
            ],
            [
              -71.816157,
              44.367559
            ],
            [
              -71.81549,
              44.368836
            ],
            [
              -71.812832,
              44.370448
            ],
            [
              -71.812235,
              44.371492
            ],
            [
              -71.812424,
              44.372532
            ],
            [
              -71.815251,
              44.374594
            ],
            [
              -71.815773,
              44.375464
            ],
            [
              -71.814388,
              44.381932
            ],
            [
              -71.81313,
              44.382801
            ],
            [
              -71.808828,
              44.383862
            ],
            [
              -71.803461,
              44.383335
            ],
            [
              -71.800316,
              44.384276
            ],
            [
              -71.799899,
              44.385951
            ],
            [
              -71.803489,
              44.390384
            ],
            [
              -71.803488,
              44.39189
            ],
            [
              -71.802353,
              44.39338
            ],
            [
              -71.793924,
              44.399271
            ],
            [
              -71.790688,
              44.40026
            ],
            [
              -71.778613,
              44.399799
            ],
            [
              -71.775399,
              44.401126
            ],
            [
              -71.772801,
              44.403097
            ],
            [
              -71.767888,
              44.405445
            ],
            [
              -71.76568897743229,
              44.406307687658
            ],
            [
              -71.764977,
              44.406587
            ],
            [
              -71.761966,
              44.407027
            ],
            [
              -71.756091,
              44.406401
            ],
            [
              -71.75434,
              44.405577
            ],
            [
              -71.749533,
              44.401955
            ],
            [
              -71.745011,
              44.401359
            ],
            [
              -71.743104,
              44.401657
            ],
            [
              -71.742308,
              44.402366
            ],
            [
              -71.739921,
              44.406778
            ],
            [
              -71.737836,
              44.408921
            ],
            [
              -71.735923,
              44.410062
            ],
            [
              -71.73152,
              44.411015
            ],
            [
              -71.726199,
              44.411385
            ],
            [
              -71.715087,
              44.41049
            ],
            [
              -71.708041,
              44.411977
            ],
            [
              -71.699434,
              44.416069
            ],
            [
              -71.69092,
              44.421234
            ],
            [
              -71.68585,
              44.423405
            ],
            [
              -71.67995,
              44.427908
            ],
            [
              -71.679158,
              44.432174
            ],
            [
              -71.679933,
              44.434062
            ],
            [
              -71.679263,
              44.435018
            ],
            [
              -71.677384,
              44.435702
            ],
            [
              -71.668944,
              44.436523
            ],
            [
              -71.664191,
              44.438351
            ],
            [
              -71.66183,
              44.440293
            ],
            [
              -71.659021,
              44.444932
            ],
            [
              -71.657313,
              44.454003
            ],
            [
              -71.653348,
              44.460499
            ],
            [
              -71.65232,
              44.461117
            ],
            [
              -71.645068,
              44.460545
            ],
            [
              -71.642851,
              44.461734
            ],
            [
              -71.640404,
              44.464186
            ],
            [
              -71.640847,
              44.465935
            ],
            [
              -71.646551,
              44.468869
            ],
            [
              -71.647864,
              44.469976
            ],
            [
              -71.648178,
              44.472023
            ],
            [
              -71.647693,
              44.473125
            ],
            [
              -71.64589,
              44.475141
            ],
            [
              -71.643111,
              44.476649
            ],
            [
              -71.639312,
              44.477836
            ],
            [
              -71.632795,
              44.48389
            ],
            [
              -71.631007,
              44.484323
            ],
            [
              -71.617614,
              44.485715
            ],
            [
              -71.615923,
              44.485944
            ],
            [
              -71.609568,
              44.484348
            ],
            [
              -71.59948,
              44.486455
            ],
            [
              -71.597917,
              44.488375
            ],
            [
              -71.595484,
              44.494424
            ],
            [
              -71.595236,
              44.49673
            ],
            [
              -71.591502,
              44.499655
            ],
            [
              -71.58841,
              44.500482
            ],
            [
              -71.585241,
              44.501309
            ],
            [
              -71.579974,
              44.501778
            ],
            [
              -71.57876,
              44.501915
            ],
            [
              -71.577643,
              44.502692
            ],
            [
              -71.577068,
              44.504041
            ],
            [
              -71.577771,
              44.504886
            ],
            [
              -71.583233,
              44.508268
            ],
            [
              -71.584959,
              44.510141
            ],
            [
              -71.58595,
              44.513432
            ],
            [
              -71.586909,
              44.514666
            ],
            [
              -71.592117,
              44.517773
            ],
            [
              -71.593971,
              44.519738
            ],
            [
              -71.594259,
              44.52168
            ],
            [
              -71.592855,
              44.523006
            ],
            [
              -71.587104,
              44.522436
            ],
            [
              -71.585731,
              44.522665
            ],
            [
              -71.582505,
              44.524403
            ],
            [
              -71.576884,
              44.530323
            ],
            [
              -71.574456,
              44.53366
            ],
            [
              -71.573019,
              44.536312
            ],
            [
              -71.573083,
              44.53798
            ],
            [
              -71.575193,
              44.540859
            ],
            [
              -71.588076,
              44.54785
            ],
            [
              -71.596804,
              44.553424
            ],
            [
              -71.598116,
              44.555412
            ],
            [
              -71.597797,
              44.557172
            ],
            [
              -71.596137,
              44.560898
            ],
            [
              -71.593923,
              44.563813
            ],
            [
              -71.592091,
              44.565118
            ],
            [
              -71.59017,
              44.565694
            ],
            [
              -71.575519,
              44.564775
            ],
            [
              -71.569599,
              44.562777
            ],
            [
              -71.563399,
              44.563218
            ],
            [
              -71.559846,
              44.564119
            ],
            [
              -71.558565,
              44.565572
            ],
            [
              -71.558985,
              44.568779
            ],
            [
              -71.558635,
              44.570738
            ],
            [
              -71.555483,
              44.578171
            ],
            [
              -71.5532,
              44.580683
            ],
            [
              -71.551145,
              44.580405
            ],
            [
              -71.54927,
              44.579164
            ],
            [
              -71.547448,
              44.578547
            ],
            [
              -71.544922,
              44.579278
            ],
            [
              -71.540123,
              44.582522
            ],
            [
              -71.537724,
              44.584785
            ],
            [
              -71.536251,
              44.588441
            ],
            [
              -71.540601,
              44.590453
            ],
            [
              -71.549268,
              44.593174
            ],
            [
              -71.553447,
              44.593451
            ],
            [
              -71.554614,
              44.595784
            ],
            [
              -71.554449,
              44.598408
            ],
            [
              -71.556014,
              44.601383
            ],
            [
              -71.555781,
              44.603483
            ],
            [
              -71.554833,
              44.605172
            ],
            [
              -71.553873,
              44.607069
            ],
            [
              -71.554097,
              44.609583
            ],
            [
              -71.55656,
              44.616988
            ],
            [
              -71.55576,
              44.624119
            ],
            [
              -71.554666,
              44.625387
            ],
            [
              -71.553898,
              44.62541
            ],
            [
              -71.553156,
              44.626645
            ],
            [
              -71.551722,
              44.627598
            ],
            [
              -71.554634,
              44.632197
            ],
            [
              -71.562124,
              44.63658
            ],
            [
              -71.562636,
              44.637266
            ],
            [
              -71.562636,
              44.639505
            ],
            [
              -71.558859,
              44.640122
            ],
            [
              -71.558026,
              44.641791
            ],
            [
              -71.55805008432729,
              44.6419051022624
            ],
            [
              -71.558571,
              44.644373
            ],
            [
              -71.561772,
              44.650224
            ],
            [
              -71.564411,
              44.652827
            ],
            [
              -71.566144,
              44.653863
            ],
            [
              -71.567645,
              44.65356
            ],
            [
              -71.568677,
              44.651537
            ],
            [
              -71.570235,
              44.650483
            ],
            [
              -71.572163,
              44.650373
            ],
            [
              -71.575145,
              44.650612
            ],
            [
              -71.576079,
              44.652012
            ],
            [
              -71.576312,
              44.653179
            ],
            [
              -71.57571,
              44.654574
            ],
            [
              -71.576013,
              44.655691
            ],
            [
              -71.582965,
              44.656621
            ],
            [
              -71.584848,
              44.657816
            ],
            [
              -71.586578,
              44.659478
            ],
            [
              -71.586578,
              44.661111
            ],
            [
              -71.585246,
              44.663523
            ],
            [
              -71.584574,
              44.665351
            ],
            [
              -71.585645,
              44.667644
            ],
            [
              -71.585645,
              44.669277
            ],
            [
              -71.584478,
              44.670211
            ],
            [
              -71.582527,
              44.672253
            ],
            [
              -71.581983,
              44.673533
            ],
            [
              -71.583009,
              44.674836
            ],
            [
              -71.587365,
              44.674926
            ],
            [
              -71.590024,
              44.675543
            ],
            [
              -71.596304,
              44.679083
            ],
            [
              -71.5964,
              44.679677
            ],
            [
              -71.594671,
              44.681643
            ],
            [
              -71.594224,
              44.683815
            ],
            [
              -71.596437,
              44.687059
            ],
            [
              -71.598042,
              44.692818
            ],
            [
              -71.596858,
              44.694921
            ],
            [
              -71.598656,
              44.698005
            ],
            [
              -71.600162,
              44.698919
            ],
            [
              -71.600772,
              44.699901
            ],
            [
              -71.600772,
              44.700815
            ],
            [
              -71.599205,
              44.703878
            ],
            [
              -71.59975,
              44.705318
            ],
            [
              -71.604912,
              44.70815
            ],
            [
              -71.613094,
              44.718933
            ],
            [
              -71.618355,
              44.72261
            ],
            [
              -71.618516,
              44.723913
            ],
            [
              -71.617431,
              44.72805
            ],
            [
              -71.617656,
              44.728918
            ],
            [
              -71.619067,
              44.729283
            ],
            [
              -71.622593,
              44.727773
            ],
            [
              -71.623266,
              44.727795
            ],
            [
              -71.624922,
              44.729032
            ],
            [
              -71.625611,
              44.730312
            ],
            [
              -71.625638,
              44.735065
            ],
            [
              -71.625059,
              44.737099
            ],
            [
              -71.62518,
              44.743978
            ],
            [
              -71.626909,
              44.747224
            ],
            [
              -71.631109,
              44.748689
            ],
            [
              -71.631840844135,
              44.750091274776196
            ],
            [
              -71.631967,
              44.750333
            ],
            [
              -71.631883,
              44.752463
            ],
            [
              -71.631255,
              44.753253
            ],
            [
              -71.623924,
              44.755135
            ],
            [
              -71.617941,
              44.755883
            ],
            [
              -71.614238,
              44.758664
            ],
            [
              -71.614267,
              44.760622
            ],
            [
              -71.611767,
              44.764345
            ],
            [
              -71.608234,
              44.765658
            ],
            [
              -71.604615,
              44.767738
            ],
            [
              -71.601471,
              44.772067
            ],
            [
              -71.596035,
              44.775422
            ],
            [
              -71.595913,
              44.776272
            ],
            [
              -71.59668,
              44.777416
            ],
            [
              -71.596949,
              44.778987
            ],
            [
              -71.592966,
              44.782776
            ],
            [
              -71.584392,
              44.785733
            ],
            [
              -71.580005,
              44.78548
            ],
            [
              -71.578938,
              44.78607
            ],
            [
              -71.573247,
              44.791882
            ],
            [
              -71.571706,
              44.79483
            ],
            [
              -71.573129,
              44.797947
            ],
            [
              -71.570402,
              44.805276
            ],
            [
              -71.569098,
              44.807044
            ],
            [
              -71.569216,
              44.808813
            ],
            [
              -71.572864,
              44.810383
            ],
            [
              -71.575139,
              44.813565
            ],
            [
              -71.5755,
              44.816058
            ],
            [
              -71.574314,
              44.818079
            ],
            [
              -71.567907,
              44.823832
            ],
            [
              -71.565146,
              44.824678
            ],
            [
              -71.56476,
              44.823901
            ],
            [
              -71.563701,
              44.823901
            ],
            [
              -71.562256,
              44.824632
            ],
            [
              -71.557672,
              44.834421
            ],
            [
              -71.553712,
              44.836065
            ],
            [
              -71.552218,
              44.837775
            ],
            [
              -71.552005,
              44.839208
            ],
            [
              -71.552654,
              44.842049
            ],
            [
              -71.555036,
              44.845733
            ],
            [
              -71.55675,
              44.846862
            ],
            [
              -71.556805,
              44.848808
            ],
            [
              -71.5556,
              44.850547
            ],
            [
              -71.553656,
              44.852123
            ],
            [
              -71.548345,
              44.85553
            ],
            [
              -71.548377,
              44.857016
            ],
            [
              -71.550304,
              44.859552
            ],
            [
              -71.550176,
              44.861609
            ],
            [
              -71.549533,
              44.862592
            ],
            [
              -71.545901,
              44.866134
            ],
            [
              -71.540116,
              44.868625
            ],
            [
              -71.534588,
              44.869698
            ],
            [
              -71.529154,
              44.873559
            ],
            [
              -71.528889,
              44.876928
            ],
            [
              -71.528342,
              44.877819
            ],
            [
              -71.526638,
              44.879098
            ],
            [
              -71.522393,
              44.880811
            ],
            [
              -71.512292,
              44.890246
            ],
            [
              -71.511712,
              44.891571
            ],
            [
              -71.51409,
              44.893149
            ],
            [
              -71.51435,
              44.893964
            ],
            [
              -71.51387,
              44.894648
            ],
            [
              -71.508642,
              44.897703
            ],
            [
              -71.502473,
              44.90272
            ],
            [
              -71.501088,
              44.904433
            ],
            [
              -71.499528,
              44.904774
            ],
            [
              -71.496968,
              44.904225
            ],
            [
              -71.495844,
              44.90498
            ],
            [
              -71.49392,
              44.910923
            ],
            [
              -71.494403,
              44.911837
            ],
            [
              -71.500788,
              44.914535
            ],
            [
              -71.504483,
              44.919062
            ],
            [
              -71.509207,
              44.923429
            ],
            [
              -71.515189,
              44.927317
            ],
            [
              -71.516949,
              44.939704
            ],
            [
              -71.516144,
              44.940846
            ],
            [
              -71.515498,
              44.94352
            ],
            [
              -71.516814,
              44.947588
            ],
            [
              -71.514843,
              44.958741
            ],
            [
              -71.516223,
              44.964569
            ],
            [
              -71.52237,
              44.966308
            ],
            [
              -71.527163,
              44.973668
            ],
            [
              -71.531605,
              44.976023
            ],
            [
              -71.537784,
              44.984298
            ],
            [
              -71.538592,
              44.988182
            ],
            [
              -71.53698,
              44.994177
            ],
            [
              -71.530091,
              44.999656
            ],
            [
              -71.525016,
              45.001881
            ],
            [
              -71.520022,
              45.002291
            ],
            [
              -71.514609,
              45.003957
            ],
            [
              -71.497412,
              45.003878
            ],
            [
              -71.487565,
              45.000936
            ],
            [
              -71.479611,
              45.002905
            ],
            [
              -71.466247,
              45.011959
            ],
            [
              -71.465039,
              45.013638
            ],
            [
              -71.501088,
              45.013377
            ],
            [
              -71.560562,
              45.012555
            ],
            [
              -71.60984,
              45.012709
            ],
            [
              -71.691898,
              45.011419
            ],
            [
              -71.767452,
              45.011437
            ],
            [
              -71.8976565406717,
              45.0082197635741
            ],
            [
              -71.92851,
              44.907298
            ],
            [
              -71.874433,
              44.876382
            ],
            [
              -71.925842,
              44.832237
            ],
            [
              -71.974318,
              44.786519
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "50",
      "COUNTYFP": "001",
      "COUNTYNS": "01461757",
      "AFFGEOID": "0500000US50001",
      "GEOID": "50001",
      "NAME": "Addison",
      "LSAD": "06",
      "ALAND": 1984780357,
      "AWATER": 107303299,
      "County_state": "Addison,50"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -73.43774,
              44.045006
            ],
            [
              -73.43688,
              44.042578
            ],
            [
              -73.430772,
              44.038746
            ],
            [
              -73.427987,
              44.037708
            ],
            [
              -73.42312,
              44.032759
            ],
            [
              -73.42016,
              44.032004
            ],
            [
              -73.414364,
              44.029526
            ],
            [
              -73.410776,
              44.026944
            ],
            [
              -73.407739,
              44.021312
            ],
            [
              -73.40738403594659,
              44.0202750561591
            ],
            [
              -73.405999,
              44.016229
            ],
            [
              -73.405977,
              44.011485
            ],
            [
              -73.411224,
              43.986202
            ],
            [
              -73.412581,
              43.98272
            ],
            [
              -73.412613,
              43.97998
            ],
            [
              -73.411248,
              43.975596
            ],
            [
              -73.406823,
              43.967317
            ],
            [
              -73.405525,
              43.948813
            ],
            [
              -73.408589,
              43.932933
            ],
            [
              -73.4077427838004,
              43.9298898187203
            ],
            [
              -73.407742,
              43.929887
            ],
            [
              -73.400926,
              43.917048
            ],
            [
              -73.397256,
              43.905668
            ],
            [
              -73.395878,
              43.903044
            ],
            [
              -73.383491,
              43.890951
            ],
            [
              -73.376312,
              43.880292
            ],
            [
              -73.374051,
              43.875563
            ],
            [
              -73.37415,
              43.874163
            ],
            [
              -73.379334,
              43.864648
            ],
            [
              -73.381501,
              43.859235
            ],
            [
              -73.382046,
              43.855008
            ],
            [
              -73.380987,
              43.852633
            ],
            [
              -73.373742,
              43.847693
            ],
            [
              -73.372462,
              43.846266
            ],
            [
              -73.372247,
              43.845337
            ],
            [
              -73.373688,
              43.84261
            ],
            [
              -73.376598,
              43.839357
            ],
            [
              -73.381865,
              43.837315
            ],
            [
              -73.388389,
              43.832404
            ],
            [
              -73.390194,
              43.829364
            ],
            [
              -73.392751,
              43.822196
            ],
            [
              -73.392492,
              43.820779
            ],
            [
              -73.390302,
              43.817371
            ],
            [
              -73.383259,
              43.81331
            ],
            [
              -73.380804,
              43.810951
            ],
            [
              -73.3793298267232,
              43.808476322237
            ],
            [
              -73.379279,
              43.808391
            ],
            [
              -73.37827,
              43.805995
            ],
            [
              -73.377232,
              43.800565
            ],
            [
              -73.376361,
              43.798766
            ],
            [
              -73.368184,
              43.793346
            ],
            [
              -73.362498,
              43.790211
            ],
            [
              -73.357547,
              43.785933
            ],
            [
              -73.355545,
              43.778468
            ],
            [
              -73.354758,
              43.776721
            ],
            [
              -73.350593,
              43.771939
            ],
            [
              -73.350707,
              43.770463
            ],
            [
              -73.354597,
              43.764167
            ],
            [
              -73.3629511574321,
              43.7531814596909
            ],
            [
              -73.231377,
              43.764469
            ],
            [
              -73.206322,
              43.766775
            ],
            [
              -73.218956,
              43.837291
            ],
            [
              -73.156173,
              43.842314
            ],
            [
              -73.029938,
              43.846716
            ],
            [
              -73.016831,
              43.804953
            ],
            [
              -72.958853,
              43.826542
            ],
            [
              -72.972896,
              43.866692
            ],
            [
              -72.980165,
              43.863928
            ],
            [
              -72.980158,
              43.882587
            ],
            [
              -72.953083,
              43.87487
            ],
            [
              -72.876556,
              43.902765
            ],
            [
              -72.874176,
              43.86406
            ],
            [
              -72.865283,
              43.861656
            ],
            [
              -72.81633,
              43.937805
            ],
            [
              -72.807032,
              43.940507
            ],
            [
              -72.791336,
              43.961855
            ],
            [
              -72.742303,
              44.029655
            ],
            [
              -72.821553,
              44.052634
            ],
            [
              -72.896904,
              44.07366
            ],
            [
              -72.909019,
              44.072587
            ],
            [
              -72.934285,
              44.163619
            ],
            [
              -72.952167,
              44.161271
            ],
            [
              -72.965447,
              44.231393
            ],
            [
              -72.973318,
              44.29342
            ],
            [
              -73.020378,
              44.303744
            ],
            [
              -73.016523,
              44.285332
            ],
            [
              -73.06902,
              44.284064
            ],
            [
              -73.133083,
              44.275055
            ],
            [
              -73.1968,
              44.266634
            ],
            [
              -73.270378,
              44.260106
            ],
            [
              -73.3134220405831,
              44.2641991074088
            ],
            [
              -73.316618,
              44.257769
            ],
            [
              -73.319802,
              44.249547
            ],
            [
              -73.323596,
              44.243897
            ],
            [
              -73.324681,
              44.243614
            ],
            [
              -73.329322,
              44.244504
            ],
            [
              -73.3305,
              44.244254
            ],
            [
              -73.334042,
              44.240971
            ],
            [
              -73.336778,
              44.239557
            ],
            [
              -73.34323,
              44.238049
            ],
            [
              -73.342312,
              44.234531
            ],
            [
              -73.349889,
              44.230356
            ],
            [
              -73.350806,
              44.225943
            ],
            [
              -73.354747,
              44.223599
            ],
            [
              -73.355252,
              44.22287
            ],
            [
              -73.355276,
              44.219554
            ],
            [
              -73.357908,
              44.216193
            ],
            [
              -73.361476,
              44.210374
            ],
            [
              -73.362013,
              44.208545
            ],
            [
              -73.370678,
              44.204546
            ],
            [
              -73.372405,
              44.202165
            ],
            [
              -73.375289,
              44.199868
            ],
            [
              -73.377693,
              44.199453
            ],
            [
              -73.382252,
              44.197178
            ],
            [
              -73.383987,
              44.193158
            ],
            [
              -73.385326,
              44.192597
            ],
            [
              -73.388502,
              44.192318
            ],
            [
              -73.390583,
              44.190886
            ],
            [
              -73.390805,
              44.189072
            ],
            [
              -73.389658,
              44.181249
            ],
            [
              -73.390383,
              44.179486
            ],
            [
              -73.395862,
              44.175785
            ],
            [
              -73.396892,
              44.173846
            ],
            [
              -73.397385,
              44.171596
            ],
            [
              -73.396664,
              44.168831
            ],
            [
              -73.395399,
              44.166903
            ],
            [
              -73.395532,
              44.166122
            ],
            [
              -73.398728,
              44.162248
            ],
            [
              -73.399634,
              44.155326
            ],
            [
              -73.402381,
              44.145856
            ],
            [
              -73.403268,
              44.144295
            ],
            [
              -73.408118,
              44.139373
            ],
            [
              -73.41172,
              44.137825
            ],
            [
              -73.415761,
              44.132826
            ],
            [
              -73.41578,
              44.131523
            ],
            [
              -73.413751,
              44.126068
            ],
            [
              -73.411722,
              44.11754
            ],
            [
              -73.411316,
              44.112686
            ],
            [
              -73.416319,
              44.099422
            ],
            [
              -73.429239,
              44.079414
            ],
            [
              -73.430207,
              44.071716
            ],
            [
              -73.431991,
              44.06345
            ],
            [
              -73.43774,
              44.045006
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "50",
      "COUNTYFP": "005",
      "COUNTYNS": "01461759",
      "AFFGEOID": "0500000US50005",
      "GEOID": "50005",
      "NAME": "Caledonia",
      "LSAD": "06",
      "ALAND": 1680951693,
      "AWATER": 22386386,
      "County_state": "Caledonia,50"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -72.429847,
              44.512045
            ],
            [
              -72.434315,
              44.506098
            ],
            [
              -72.321277,
              44.462572
            ],
            [
              -72.22371,
              44.424582
            ],
            [
              -72.220442,
              44.421197
            ],
            [
              -72.217104,
              44.411087
            ],
            [
              -72.26985,
              44.340227
            ],
            [
              -72.317621,
              44.298384
            ],
            [
              -72.316302,
              44.272583
            ],
            [
              -72.368353,
              44.204057
            ],
            [
              -72.30498,
              44.183149
            ],
            [
              -72.174381,
              44.192183
            ],
            [
              -72.166147,
              44.190938
            ],
            [
              -72.045767,
              44.157401
            ],
            [
              -72.0406490709507,
              44.1578468635977
            ],
            [
              -72.042387,
              44.160817
            ],
            [
              -72.047593,
              44.161801
            ],
            [
              -72.053021,
              44.167903
            ],
            [
              -72.057496,
              44.179444
            ],
            [
              -72.061338,
              44.184951
            ],
            [
              -72.066166,
              44.189773
            ],
            [
              -72.064577,
              44.196949
            ],
            [
              -72.063561,
              44.198457
            ],
            [
              -72.060067,
              44.200446
            ],
            [
              -72.058987,
              44.202114
            ],
            [
              -72.058066,
              44.206067
            ],
            [
              -72.058605,
              44.208215
            ],
            [
              -72.053233,
              44.216876
            ],
            [
              -72.052942903517,
              44.2178743180194
            ],
            [
              -72.052662,
              44.218841
            ],
            [
              -72.0539,
              44.222703
            ],
            [
              -72.053582,
              44.22604
            ],
            [
              -72.050656,
              44.233581
            ],
            [
              -72.047889,
              44.238493
            ],
            [
              -72.04846,
              44.241212
            ],
            [
              -72.050112,
              44.244046
            ],
            [
              -72.05399,
              44.246926
            ],
            [
              -72.059782,
              44.256018
            ],
            [
              -72.061174,
              44.263377
            ],
            [
              -72.064544,
              44.267997
            ],
            [
              -72.066464,
              44.268331
            ],
            [
              -72.067774,
              44.270976
            ],
            [
              -72.065434,
              44.277235
            ],
            [
              -72.05888,
              44.28624
            ],
            [
              -72.053355,
              44.290501
            ],
            [
              -72.046302,
              44.291983
            ],
            [
              -72.039004,
              44.296463
            ],
            [
              -72.03703,
              44.297834
            ],
            [
              -72.033465,
              44.301878
            ],
            [
              -72.032541,
              44.303752
            ],
            [
              -72.032317,
              44.306677
            ],
            [
              -72.032341,
              44.315752
            ],
            [
              -72.033806,
              44.317349
            ],
            [
              -72.033136,
              44.320365
            ],
            [
              -72.029061,
              44.322398
            ],
            [
              -72.025783,
              44.322054
            ],
            [
              -72.01913,
              44.320383
            ],
            [
              -72.014543,
              44.321032
            ],
            [
              -72.009977,
              44.321951
            ],
            [
              -72.002314,
              44.324871
            ],
            [
              -71.988306,
              44.329768
            ],
            [
              -71.986484,
              44.331218
            ],
            [
              -71.9846456807074,
              44.336165806344404
            ],
            [
              -71.984617,
              44.336243
            ],
            [
              -71.98112,
              44.3375
            ],
            [
              -71.963133,
              44.336556
            ],
            [
              -71.958119,
              44.337544
            ],
            [
              -71.945163,
              44.337744
            ],
            [
              -71.939049,
              44.335844
            ],
            [
              -71.935395,
              44.33577
            ],
            [
              -71.92911,
              44.337577
            ],
            [
              -71.925088,
              44.342024
            ],
            [
              -71.917434,
              44.346535
            ],
            [
              -71.906909,
              44.348284
            ],
            [
              -71.902332,
              44.347499
            ],
            [
              -71.881895,
              44.340209
            ],
            [
              -71.875863,
              44.33737
            ],
            [
              -71.872472,
              44.336628
            ],
            [
              -71.86991,
              44.336962
            ],
            [
              -71.861941,
              44.340109
            ],
            [
              -71.852628,
              44.340873
            ],
            [
              -71.844319,
              44.344204
            ],
            [
              -71.83771955183909,
              44.3477442357108
            ],
            [
              -71.933288,
              44.441174
            ],
            [
              -71.903558,
              44.454472
            ],
            [
              -71.856637,
              44.496178
            ],
            [
              -71.908657,
              44.54708
            ],
            [
              -71.898111,
              44.557857
            ],
            [
              -71.842732,
              44.611165
            ],
            [
              -71.910282,
              44.647038
            ],
            [
              -71.837294,
              44.716637
            ],
            [
              -71.939936,
              44.769032
            ],
            [
              -72.003978,
              44.70498
            ],
            [
              -72.012504,
              44.698821
            ],
            [
              -72.114503,
              44.749624
            ],
            [
              -72.088864,
              44.683714
            ],
            [
              -72.146599,
              44.703495
            ],
            [
              -72.237433,
              44.576818
            ],
            [
              -72.262965,
              44.542336
            ],
            [
              -72.374786,
              44.584125
            ],
            [
              -72.429847,
              44.512045
            ]
          ]
        ]
      ]
    }
  }
]
};