export const counties_pa = {
  "type":"FeatureCollection","name":"pa_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "075",
      "COUNTYNS": "01214034",
      "AFFGEOID": "0500000US42075",
      "GEOID": "42075",
      "NAME": "Lebanon",
      "LSAD": "06",
      "ALAND": 937138344,
      "AWATER": 1727921,
      "County_state": "Lebanon,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.67804,
              40.474717
            ],
            [
              -76.667771,
              40.447888
            ],
            [
              -76.625538,
              40.339273
            ],
            [
              -76.592173,
              40.25428
            ],
            [
              -76.570467,
              40.198434
            ],
            [
              -76.566339,
              40.196644
            ],
            [
              -76.466307,
              40.2284
            ],
            [
              -76.435427,
              40.238217
            ],
            [
              -76.386298,
              40.244994
            ],
            [
              -76.307578,
              40.255066
            ],
            [
              -76.151215,
              40.315971
            ],
            [
              -76.198594,
              40.354877
            ],
            [
              -76.231916,
              40.366292
            ],
            [
              -76.307108,
              40.413438
            ],
            [
              -76.341021,
              40.434358
            ],
            [
              -76.440181,
              40.495952
            ],
            [
              -76.535414,
              40.555157
            ],
            [
              -76.67804,
              40.474717
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "007",
      "COUNTYNS": "01214112",
      "AFFGEOID": "0500000US42007",
      "GEOID": "42007",
      "NAME": "Beaver",
      "LSAD": "06",
      "ALAND": 1125901161,
      "AWATER": 24165972,
      "County_state": "Beaver,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.519086,
              40.616385
            ],
            [
              -80.519086,
              40.590161
            ],
            [
              -80.519055,
              40.590173
            ],
            [
              -80.51905584593969,
              40.5596130052828
            ],
            [
              -80.519057,
              40.517922
            ],
            [
              -80.519054,
              40.517922
            ],
            [
              -80.5189953454551,
              40.4773632987013
            ],
            [
              -80.361865,
              40.477571
            ],
            [
              -80.360873,
              40.477539
            ],
            [
              -80.278026,
              40.536736
            ],
            [
              -80.228579,
              40.573072
            ],
            [
              -80.211832,
              40.582484
            ],
            [
              -80.207978,
              40.588038
            ],
            [
              -80.186331,
              40.596293
            ],
            [
              -80.180133,
              40.609397
            ],
            [
              -80.158602,
              40.609172
            ],
            [
              -80.14485,
              40.613474
            ],
            [
              -80.148677,
              40.673848
            ],
            [
              -80.148451,
              40.67429
            ],
            [
              -80.152562,
              40.744099
            ],
            [
              -80.155115,
              40.788314
            ],
            [
              -80.158534,
              40.855157
            ],
            [
              -80.322579,
              40.853151
            ],
            [
              -80.5190191701796,
              40.851339398102795
            ],
            [
              -80.51902,
              40.850073
            ],
            [
              -80.519081,
              40.849157
            ],
            [
              -80.518992,
              40.801221
            ],
            [
              -80.519058,
              40.792298
            ],
            [
              -80.518991,
              40.638801
            ],
            [
              -80.519039,
              40.616391
            ],
            [
              -80.519086,
              40.616385
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "121",
      "COUNTYNS": "01213690",
      "AFFGEOID": "0500000US42121",
      "GEOID": "42121",
      "NAME": "Venango",
      "LSAD": "06",
      "ALAND": 1746388722,
      "AWATER": 22148538,
      "County_state": "Venango,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.999779,
              41.490049
            ],
            [
              -79.999779,
              41.171861
            ],
            [
              -79.885546,
              41.172463
            ],
            [
              -79.694984,
              41.172865
            ],
            [
              -79.697242,
              41.184614
            ],
            [
              -79.670125,
              41.183116
            ],
            [
              -79.668892,
              41.199075
            ],
            [
              -79.649628,
              41.217912
            ],
            [
              -79.650394,
              41.253709
            ],
            [
              -79.632875,
              41.253599
            ],
            [
              -79.627561,
              41.277183
            ],
            [
              -79.60461,
              41.277072
            ],
            [
              -79.604161,
              41.321478
            ],
            [
              -79.541474,
              41.321008
            ],
            [
              -79.54143,
              41.335951
            ],
            [
              -79.478365,
              41.335716
            ],
            [
              -79.477822,
              41.386479
            ],
            [
              -79.478163,
              41.440134
            ],
            [
              -79.521599,
              41.440554
            ],
            [
              -79.512276,
              41.46524
            ],
            [
              -79.51207,
              41.624559
            ],
            [
              -79.612873,
              41.623858
            ],
            [
              -79.612866,
              41.617058
            ],
            [
              -79.644117,
              41.617206
            ],
            [
              -79.688082,
              41.617363
            ],
            [
              -79.768357,
              41.617711
            ],
            [
              -79.828876,
              41.622057
            ],
            [
              -79.829576,
              41.602057
            ],
            [
              -79.877171,
              41.574242
            ],
            [
              -79.892988,
              41.574157
            ],
            [
              -79.92348,
              41.537555
            ],
            [
              -79.943579,
              41.537454
            ],
            [
              -79.958384,
              41.513507
            ],
            [
              -79.981452,
              41.5129
            ],
            [
              -79.999779,
              41.490049
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "059",
      "COUNTYNS": "01214033",
      "AFFGEOID": "0500000US42059",
      "GEOID": "42059",
      "NAME": "Greene",
      "LSAD": "06",
      "ALAND": 1491700969,
      "AWATER": 5253864,
      "County_state": "Greene,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.519423,
              39.806181
            ],
            [
              -80.5193982276866,
              39.780253256914
            ],
            [
              -80.519342,
              39.721403
            ],
            [
              -80.421388,
              39.721189
            ],
            [
              -80.309457,
              39.721264
            ],
            [
              -80.308651,
              39.721283
            ],
            [
              -80.24130705308929,
              39.721302389629905
            ],
            [
              -80.075947,
              39.72135
            ],
            [
              -80.0417021197902,
              39.7212520986433
            ],
            [
              -79.9161705658965,
              39.72089322136409
            ],
            [
              -79.903679,
              39.743478
            ],
            [
              -79.93008,
              39.757078
            ],
            [
              -79.934411,
              39.781277
            ],
            [
              -79.908577,
              39.793477
            ],
            [
              -79.920168,
              39.812278
            ],
            [
              -79.924376,
              39.838176
            ],
            [
              -79.938377,
              39.846176
            ],
            [
              -79.929808,
              39.851436
            ],
            [
              -79.927063,
              39.853293
            ],
            [
              -79.92465,
              39.855056
            ],
            [
              -79.924507,
              39.856363
            ],
            [
              -79.930676,
              39.868076
            ],
            [
              -79.915278,
              39.8803
            ],
            [
              -79.92843,
              39.902283
            ],
            [
              -79.925614,
              39.914763
            ],
            [
              -79.925597,
              39.924795
            ],
            [
              -79.957246,
              39.953274
            ],
            [
              -79.993711,
              39.949386
            ],
            [
              -80.009344,
              39.958794
            ],
            [
              -79.998014,
              39.983322
            ],
            [
              -80.048292,
              39.978527
            ],
            [
              -80.038927,
              39.996467
            ],
            [
              -80.060314,
              40.009453
            ],
            [
              -80.077242,
              39.997014
            ],
            [
              -80.086114,
              39.990313
            ],
            [
              -80.12951,
              39.988406
            ],
            [
              -80.157339,
              39.999581
            ],
            [
              -80.175921,
              40.008138
            ],
            [
              -80.191233,
              40.001931
            ],
            [
              -80.240138,
              40.009476
            ],
            [
              -80.273355,
              40.002376
            ],
            [
              -80.273255,
              40.01328
            ],
            [
              -80.301396,
              40.020559
            ],
            [
              -80.309114,
              40.00585
            ],
            [
              -80.379505,
              39.994125
            ],
            [
              -80.396475,
              39.97789
            ],
            [
              -80.452777,
              39.973419
            ],
            [
              -80.475277,
              39.952065
            ],
            [
              -80.500943,
              39.953784
            ],
            [
              -80.5192168880039,
              39.9621993767921
            ],
            [
              -80.519203,
              39.959394
            ],
            [
              -80.519175,
              39.956648
            ],
            [
              -80.519115,
              39.939188
            ],
            [
              -80.519248,
              39.936967
            ],
            [
              -80.518891,
              39.890964
            ],
            [
              -80.519423,
              39.806181
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "065",
      "COUNTYNS": "01213674",
      "AFFGEOID": "0500000US42065",
      "GEOID": "42065",
      "NAME": "Jefferson",
      "LSAD": "06",
      "ALAND": 1689784203,
      "AWATER": 11321806,
      "County_state": "Jefferson,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.210197,
              41.049091
            ],
            [
              -79.21531,
              40.911346
            ],
            [
              -78.805167,
              40.90598
            ],
            [
              -78.804047,
              40.993855
            ],
            [
              -78.804532,
              41.132186
            ],
            [
              -78.766377,
              41.202759
            ],
            [
              -78.710248,
              41.201859
            ],
            [
              -78.710059,
              41.230243
            ],
            [
              -78.738214,
              41.230672
            ],
            [
              -78.73528,
              41.262915
            ],
            [
              -78.959157,
              41.362161
            ],
            [
              -78.97159,
              41.376506
            ],
            [
              -78.992688,
              41.368616
            ],
            [
              -79.020329,
              41.350494
            ],
            [
              -79.027786,
              41.357205
            ],
            [
              -79.065138,
              41.350157
            ],
            [
              -79.076706,
              41.354249
            ],
            [
              -79.095096,
              41.340658
            ],
            [
              -79.106743,
              41.3261
            ],
            [
              -79.139791,
              41.341431
            ],
            [
              -79.154733,
              41.32865
            ],
            [
              -79.187005,
              41.33731
            ],
            [
              -79.208878,
              41.331861
            ],
            [
              -79.211173,
              41.112575
            ],
            [
              -79.21132,
              41.078737
            ],
            [
              -79.215214,
              41.050515
            ],
            [
              -79.210197,
              41.049091
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "021",
      "COUNTYNS": "01213662",
      "AFFGEOID": "0500000US42021",
      "GEOID": "42021",
      "NAME": "Cambria",
      "LSAD": "06",
      "ALAND": 1782804173,
      "AWATER": 13696237,
      "County_state": "Cambria,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.054781,
              40.289589
            ],
            [
              -79.055983,
              40.285089
            ],
            [
              -78.932662,
              40.284974
            ],
            [
              -78.927851,
              40.284214
            ],
            [
              -78.896546,
              40.27165
            ],
            [
              -78.884862,
              40.244631
            ],
            [
              -78.868756,
              40.248883
            ],
            [
              -78.856089,
              40.243431
            ],
            [
              -78.8531,
              40.245741
            ],
            [
              -78.849535,
              40.246012
            ],
            [
              -78.848041,
              40.244901
            ],
            [
              -78.838979,
              40.244794
            ],
            [
              -78.658409,
              40.242933
            ],
            [
              -78.643998,
              40.259446
            ],
            [
              -78.647953,
              40.270621
            ],
            [
              -78.649517,
              40.275042
            ],
            [
              -78.651868,
              40.281678
            ],
            [
              -78.620383,
              40.327025
            ],
            [
              -78.602353,
              40.352973
            ],
            [
              -78.564085,
              40.370609
            ],
            [
              -78.556958,
              40.409136
            ],
            [
              -78.55973,
              40.438765
            ],
            [
              -78.551183,
              40.456654
            ],
            [
              -78.540448,
              40.476387
            ],
            [
              -78.544715,
              40.486682
            ],
            [
              -78.53984,
              40.506154
            ],
            [
              -78.518455,
              40.528795
            ],
            [
              -78.485958,
              40.540847
            ],
            [
              -78.447515,
              40.591551
            ],
            [
              -78.44199,
              40.641477
            ],
            [
              -78.408629,
              40.644349
            ],
            [
              -78.35043,
              40.724827
            ],
            [
              -78.712747,
              40.72435
            ],
            [
              -78.801697,
              40.724539
            ],
            [
              -78.79936,
              40.717273
            ],
            [
              -78.80811,
              40.72064
            ],
            [
              -78.834641,
              40.661678
            ],
            [
              -78.846656,
              40.635638
            ],
            [
              -78.860105,
              40.604665
            ],
            [
              -78.924248,
              40.48107
            ],
            [
              -78.974649,
              40.395972
            ],
            [
              -79.037187,
              40.306986
            ],
            [
              -79.054781,
              40.289589
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "071",
      "COUNTYNS": "01209181",
      "AFFGEOID": "0500000US42071",
      "GEOID": "42071",
      "NAME": "Lancaster",
      "LSAD": "06",
      "ALAND": 2444600820,
      "AWATER": 103429249,
      "County_state": "Lancaster,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.72162,
              40.12007
            ],
            [
              -76.715097,
              40.11598
            ],
            [
              -76.697187,
              40.102492
            ],
            [
              -76.663825,
              40.063227
            ],
            [
              -76.644508,
              40.058069
            ],
            [
              -76.571217,
              40.045669
            ],
            [
              -76.534927,
              40.051359
            ],
            [
              -76.512236,
              40.013442
            ],
            [
              -76.512005,
              40.012489
            ],
            [
              -76.498454,
              39.99673
            ],
            [
              -76.495352,
              39.976128
            ],
            [
              -76.477726,
              39.949844
            ],
            [
              -76.448969,
              39.934338
            ],
            [
              -76.391674,
              39.912819
            ],
            [
              -76.379769,
              39.887913
            ],
            [
              -76.380692,
              39.873675
            ],
            [
              -76.350759,
              39.84709
            ],
            [
              -76.338255,
              39.822062
            ],
            [
              -76.315702,
              39.810037
            ],
            [
              -76.276113,
              39.77562
            ],
            [
              -76.240582,
              39.730313
            ],
            [
              -76.2396824239736,
              39.721645324517596
            ],
            [
              -76.233485,
              39.721646
            ],
            [
              -76.23327974765701,
              39.7216465489498
            ],
            [
              -76.1357639058503,
              39.7219073562293
            ],
            [
              -76.119924,
              39.736364
            ],
            [
              -76.102479,
              39.724033
            ],
            [
              -76.10205,
              39.739884
            ],
            [
              -76.067427,
              39.75965
            ],
            [
              -76.041365,
              39.797196
            ],
            [
              -76.049293,
              39.802003
            ],
            [
              -76.029592,
              39.812998
            ],
            [
              -76.037636,
              39.826917
            ],
            [
              -76.017384,
              39.833488
            ],
            [
              -76.022823,
              39.849844
            ],
            [
              -76.006734,
              39.853958
            ],
            [
              -76.001709,
              39.874698
            ],
            [
              -75.991284,
              39.868738
            ],
            [
              -75.995133,
              39.898916
            ],
            [
              -75.997165,
              39.928671
            ],
            [
              -75.985056,
              39.937871
            ],
            [
              -75.988005,
              39.939562
            ],
            [
              -75.994644,
              39.95225
            ],
            [
              -75.972407,
              39.98751
            ],
            [
              -75.951398,
              40.016421
            ],
            [
              -75.93587,
              40.037466
            ],
            [
              -75.936135,
              40.078585
            ],
            [
              -75.94244,
              40.106515
            ],
            [
              -75.937998,
              40.111139
            ],
            [
              -75.873375,
              40.137107
            ],
            [
              -75.941804,
              40.181588
            ],
            [
              -76.043993,
              40.247196
            ],
            [
              -76.044058,
              40.247237
            ],
            [
              -76.091764,
              40.277692
            ],
            [
              -76.151215,
              40.315971
            ],
            [
              -76.307578,
              40.255066
            ],
            [
              -76.386298,
              40.244994
            ],
            [
              -76.435427,
              40.238217
            ],
            [
              -76.466307,
              40.2284
            ],
            [
              -76.566339,
              40.196644
            ],
            [
              -76.567018,
              40.195554
            ],
            [
              -76.571195,
              40.194401
            ],
            [
              -76.580708,
              40.194687
            ],
            [
              -76.582682,
              40.190952
            ],
            [
              -76.586412,
              40.189484
            ],
            [
              -76.58789,
              40.190559
            ],
            [
              -76.599865,
              40.188264
            ],
            [
              -76.606519,
              40.18999
            ],
            [
              -76.636018,
              40.170062
            ],
            [
              -76.643169,
              40.165625
            ],
            [
              -76.676756,
              40.149463
            ],
            [
              -76.697664,
              40.156342
            ],
            [
              -76.72162,
              40.12007
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "003",
      "COUNTYNS": "01213657",
      "AFFGEOID": "0500000US42003",
      "GEOID": "42003",
      "NAME": "Allegheny",
      "LSAD": "06",
      "ALAND": 1890889701,
      "AWATER": 37411492,
      "County_state": "Allegheny,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.278026,
              40.536736
            ],
            [
              -80.360873,
              40.477539
            ],
            [
              -80.2347,
              40.375368
            ],
            [
              -80.183129,
              40.332515
            ],
            [
              -80.116156,
              40.312324
            ],
            [
              -80.07396,
              40.299837
            ],
            [
              -80.033712,
              40.288034
            ],
            [
              -79.914139,
              40.252518
            ],
            [
              -79.970428,
              40.232246
            ],
            [
              -79.959532,
              40.216134
            ],
            [
              -79.896448,
              40.194481
            ],
            [
              -79.870585,
              40.197415
            ],
            [
              -79.781761,
              40.227711
            ],
            [
              -79.806311,
              40.233446
            ],
            [
              -79.788015,
              40.2613
            ],
            [
              -79.794715,
              40.281683
            ],
            [
              -79.775139,
              40.287888
            ],
            [
              -79.787853,
              40.297693
            ],
            [
              -79.783806,
              40.315441
            ],
            [
              -79.774412,
              40.359673
            ],
            [
              -79.762068,
              40.392768
            ],
            [
              -79.742497,
              40.396846
            ],
            [
              -79.734707,
              40.397826
            ],
            [
              -79.722151,
              40.409098
            ],
            [
              -79.727665,
              40.412495
            ],
            [
              -79.704503,
              40.434025
            ],
            [
              -79.702142,
              40.489523
            ],
            [
              -79.701711,
              40.525491
            ],
            [
              -79.722424,
              40.542059
            ],
            [
              -79.736615,
              40.549324
            ],
            [
              -79.765415,
              40.549854
            ],
            [
              -79.765946,
              40.550915
            ],
            [
              -79.77187,
              40.562967
            ],
            [
              -79.77437,
              40.569767
            ],
            [
              -79.773331,
              40.580758
            ],
            [
              -79.772285,
              40.583788
            ],
            [
              -79.76377,
              40.592966
            ],
            [
              -79.749187,
              40.59897
            ],
            [
              -79.72127,
              40.607966
            ],
            [
              -79.712477,
              40.620076
            ],
            [
              -79.689117,
              40.642722
            ],
            [
              -79.692587,
              40.669732
            ],
            [
              -79.69293,
              40.669744
            ],
            [
              -79.732744,
              40.66924
            ],
            [
              -79.786358,
              40.671543
            ],
            [
              -79.924182,
              40.673021
            ],
            [
              -79.964595,
              40.672514
            ],
            [
              -79.99418,
              40.673636
            ],
            [
              -80.148451,
              40.67429
            ],
            [
              -80.148677,
              40.673848
            ],
            [
              -80.14485,
              40.613474
            ],
            [
              -80.158602,
              40.609172
            ],
            [
              -80.180133,
              40.609397
            ],
            [
              -80.186331,
              40.596293
            ],
            [
              -80.207978,
              40.588038
            ],
            [
              -80.211832,
              40.582484
            ],
            [
              -80.228579,
              40.573072
            ],
            [
              -80.278026,
              40.536736
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "049",
      "COUNTYNS": "01209178",
      "AFFGEOID": "0500000US42049",
      "GEOID": "42049",
      "NAME": "Erie",
      "LSAD": "06",
      "ALAND": 2069615830,
      "AWATER": 1966184465,
      "County_state": "Erie,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.519425,
              41.977523
            ],
            [
              -80.519405,
              41.976158
            ],
            [
              -80.519304,
              41.943992
            ],
            [
              -80.519345,
              41.929168
            ],
            [
              -80.519293557418,
              41.849563030893194
            ],
            [
              -80.405892,
              41.849619
            ],
            [
              -80.350756,
              41.849507
            ],
            [
              -80.206545,
              41.849045
            ],
            [
              -80.035233,
              41.85011
            ],
            [
              -79.909977,
              41.850693
            ],
            [
              -79.680353,
              41.851524
            ],
            [
              -79.612072,
              41.850056
            ],
            [
              -79.6108388547624,
              41.9989893460904
            ],
            [
              -79.625301,
              41.999068
            ],
            [
              -79.625287,
              41.999003
            ],
            [
              -79.670128,
              41.999335
            ],
            [
              -79.761313,
              41.998808
            ],
            [
              -79.76162,
              42.00006
            ],
            [
              -79.761798,
              42.019042
            ],
            [
              -79.761709,
              42.11899
            ],
            [
              -79.762122,
              42.131246
            ],
            [
              -79.761861,
              42.150712
            ],
            [
              -79.761759,
              42.162675
            ],
            [
              -79.761921,
              42.173319
            ],
            [
              -79.761929,
              42.179693
            ],
            [
              -79.761833,
              42.183627
            ],
            [
              -79.762152,
              42.243054
            ],
            [
              -79.761964,
              42.251354
            ],
            [
              -79.761951,
              42.26986
            ],
            [
              -79.798447,
              42.255939
            ],
            [
              -79.844661,
              42.235486
            ],
            [
              -79.867979,
              42.230999
            ],
            [
              -79.886187,
              42.224933
            ],
            [
              -79.90105,
              42.216701
            ],
            [
              -79.923924,
              42.207546
            ],
            [
              -79.931324,
              42.206737
            ],
            [
              -79.989186,
              42.177051
            ],
            [
              -80.0210990936056,
              42.1627603758097
            ],
            [
              -80.0419360632006,
              42.1534296194303
            ],
            [
              -80.06108,
              42.144857
            ],
            [
              -80.07198,
              42.146057
            ],
            [
              -80.076281,
              42.147857
            ],
            [
              -80.078781,
              42.151457
            ],
            [
              -80.078423041131,
              42.151662299939595
            ],
            [
              -80.071981,
              42.155357
            ],
            [
              -80.080028,
              42.163625
            ],
            [
              -80.073381,
              42.168658
            ],
            [
              -80.077388,
              42.171262
            ],
            [
              -80.088512,
              42.173184
            ],
            [
              -80.117368,
              42.166341
            ],
            [
              -80.13043,
              42.156331
            ],
            [
              -80.136213,
              42.149937
            ],
            [
              -80.154084,
              42.114757
            ],
            [
              -80.165884,
              42.105857
            ],
            [
              -80.188085,
              42.094257
            ],
            [
              -80.22655445915349,
              42.0794683821796
            ],
            [
              -80.230486,
              42.077957
            ],
            [
              -80.296758,
              42.049076
            ],
            [
              -80.2988678931987,
              42.048256128201295
            ],
            [
              -80.329976,
              42.036168
            ],
            [
              -80.349169,
              42.030243
            ],
            [
              -80.363251,
              42.027973
            ],
            [
              -80.371869,
              42.023966
            ],
            [
              -80.373066,
              42.024102
            ],
            [
              -80.409776,
              42.011578
            ],
            [
              -80.435451,
              42.005611
            ],
            [
              -80.519425,
              41.977523
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "017",
      "COUNTYNS": "01209173",
      "AFFGEOID": "0500000US42017",
      "GEOID": "42017",
      "NAME": "Bucks",
      "LSAD": "06",
      "ALAND": 1565408735,
      "AWATER": 45201371,
      "County_state": "Bucks,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.484057,
              40.418451
            ],
            [
              -75.445647,
              40.396205
            ],
            [
              -75.410402,
              40.3745
            ],
            [
              -75.404327,
              40.370825
            ],
            [
              -75.353219,
              40.340182
            ],
            [
              -75.334614,
              40.328809
            ],
            [
              -75.243171,
              40.274395
            ],
            [
              -75.214133,
              40.257422
            ],
            [
              -75.137782,
              40.210886
            ],
            [
              -75.106878,
              40.192279
            ],
            [
              -75.071198,
              40.171032
            ],
            [
              -75.029301,
              40.146509
            ],
            [
              -75.015066,
              40.137992
            ],
            [
              -74.997885,
              40.127816
            ],
            [
              -74.993595,
              40.132266
            ],
            [
              -74.964991,
              40.118635
            ],
            [
              -74.955763,
              40.094982
            ],
            [
              -74.971268,
              40.073744
            ],
            [
              -74.980688,
              40.065007
            ],
            [
              -74.984893,
              40.056836
            ],
            [
              -74.97431985326,
              40.04889913332
            ],
            [
              -74.944412,
              40.063211
            ],
            [
              -74.932211,
              40.068411
            ],
            [
              -74.925311,
              40.07071
            ],
            [
              -74.920811,
              40.07111
            ],
            [
              -74.911911,
              40.06991
            ],
            [
              -74.90907183176469,
              40.0702037070588
            ],
            [
              -74.909011,
              40.07021
            ],
            [
              -74.88781,
              40.07581
            ],
            [
              -74.880209,
              40.07881
            ],
            [
              -74.863809,
              40.08221
            ],
            [
              -74.860909,
              40.08371
            ],
            [
              -74.859809,
              40.08491
            ],
            [
              -74.858209,
              40.08881
            ],
            [
              -74.85739757549229,
              40.090003271334794
            ],
            [
              -74.856509,
              40.09131
            ],
            [
              -74.854409,
              40.09311
            ],
            [
              -74.851108,
              40.09491
            ],
            [
              -74.843408,
              40.09771
            ],
            [
              -74.838008,
              40.10091
            ],
            [
              -74.835108,
              40.10391
            ],
            [
              -74.832808,
              40.11171
            ],
            [
              -74.828408,
              40.12031
            ],
            [
              -74.825907,
              40.12391
            ],
            [
              -74.822307,
              40.12671
            ],
            [
              -74.819007,
              40.12751
            ],
            [
              -74.816307,
              40.12761
            ],
            [
              -74.8156695,
              40.1274825
            ],
            [
              -74.812807,
              40.12691
            ],
            [
              -74.800607,
              40.12281
            ],
            [
              -74.788706,
              40.12041
            ],
            [
              -74.785106,
              40.12031
            ],
            [
              -74.782106,
              40.12081
            ],
            [
              -74.769488,
              40.129145
            ],
            [
              -74.762864,
              40.132541
            ],
            [
              -74.758882,
              40.134036
            ],
            [
              -74.755305,
              40.13471
            ],
            [
              -74.745905,
              40.13421
            ],
            [
              -74.742905,
              40.13441
            ],
            [
              -74.740605,
              40.13521
            ],
            [
              -74.725663,
              40.145495
            ],
            [
              -74.724304,
              40.14701
            ],
            [
              -74.722604,
              40.15001
            ],
            [
              -74.7224939093264,
              40.1504283445596
            ],
            [
              -74.721604,
              40.15381
            ],
            [
              -74.721504,
              40.158409
            ],
            [
              -74.722304,
              40.160609
            ],
            [
              -74.733804,
              40.174509
            ],
            [
              -74.737205,
              40.177609
            ],
            [
              -74.744105,
              40.181009
            ],
            [
              -74.751705,
              40.183309
            ],
            [
              -74.7519933317261,
              40.1835197039537
            ],
            [
              -74.754305,
              40.185209
            ],
            [
              -74.755605,
              40.186709
            ],
            [
              -74.756905,
              40.189409
            ],
            [
              -74.760605,
              40.198909
            ],
            [
              -74.7608412405874,
              40.1992389868522
            ],
            [
              -74.766905,
              40.207709
            ],
            [
              -74.770406,
              40.214508
            ],
            [
              -74.77136,
              40.215399
            ],
            [
              -74.781206,
              40.221508
            ],
            [
              -74.795306,
              40.229408
            ],
            [
              -74.819507,
              40.238508
            ],
            [
              -74.823907,
              40.241508
            ],
            [
              -74.836307,
              40.246208
            ],
            [
              -74.842308,
              40.250508
            ],
            [
              -74.846608,
              40.258808
            ],
            [
              -74.853108,
              40.269707
            ],
            [
              -74.853118148928,
              40.269729984337
            ],
            [
              -74.856508,
              40.277407
            ],
            [
              -74.860492,
              40.284584
            ],
            [
              -74.864692,
              40.290684
            ],
            [
              -74.868209,
              40.295207
            ],
            [
              -74.880609,
              40.305607
            ],
            [
              -74.887109,
              40.310307
            ],
            [
              -74.891609,
              40.313007
            ],
            [
              -74.896409,
              40.315107
            ],
            [
              -74.90331,
              40.315607
            ],
            [
              -74.90831,
              40.316907
            ],
            [
              -74.91741,
              40.322406
            ],
            [
              -74.92681,
              40.329406
            ],
            [
              -74.933111,
              40.333106
            ],
            [
              -74.939711,
              40.338006
            ],
            [
              -74.9429542775691,
              40.3416426197195
            ],
            [
              -74.943776,
              40.342564
            ],
            [
              -74.945088,
              40.347332
            ],
            [
              -74.946006,
              40.357306
            ],
            [
              -74.948722,
              40.364768
            ],
            [
              -74.953697,
              40.376081
            ],
            [
              -74.9540018446549,
              40.376648218234095
            ],
            [
              -74.963997,
              40.395246
            ],
            [
              -74.965508,
              40.397337
            ],
            [
              -74.969597,
              40.39977
            ],
            [
              -74.982735,
              40.404432
            ],
            [
              -74.985467,
              40.405935
            ],
            [
              -74.988901,
              40.408773
            ],
            [
              -74.996378,
              40.410528
            ],
            [
              -74.998651,
              40.410093
            ],
            [
              -75.003351,
              40.40785
            ],
            [
              -75.017221,
              40.404638
            ],
            [
              -75.024775,
              40.403455
            ],
            [
              -75.028315,
              40.403883
            ],
            [
              -75.036616,
              40.406796
            ],
            [
              -75.041651,
              40.409894
            ],
            [
              -75.0417605522261,
              40.4100258484186
            ],
            [
              -75.043071,
              40.411603
            ],
            [
              -75.0444082558835,
              40.412463450655196
            ],
            [
              -75.046473,
              40.413792
            ],
            [
              -75.056102,
              40.416066
            ],
            [
              -75.058848,
              40.418065
            ],
            [
              -75.06066217472039,
              40.4213505727709
            ],
            [
              -75.061489,
              40.422848
            ],
            [
              -75.062923,
              40.433407
            ],
            [
              -75.067425,
              40.448323
            ],
            [
              -75.070568,
              40.455165
            ],
            [
              -75.070568,
              40.456348
            ],
            [
              -75.067302,
              40.464954
            ],
            [
              -75.06805,
              40.468578
            ],
            [
              -75.067776,
              40.472827
            ],
            [
              -75.064327,
              40.476795
            ],
            [
              -75.062227,
              40.481391
            ],
            [
              -75.061937,
              40.486362
            ],
            [
              -75.062373,
              40.491689
            ],
            [
              -75.065275,
              40.504682
            ],
            [
              -75.066001,
              40.510716
            ],
            [
              -75.065853,
              40.519495
            ],
            [
              -75.06509,
              40.526148
            ],
            [
              -75.066402,
              40.536532
            ],
            [
              -75.067257,
              40.539584
            ],
            [
              -75.068615,
              40.542223
            ],
            [
              -75.078503,
              40.548296
            ],
            [
              -75.0957,
              40.564401
            ],
            [
              -75.100325,
              40.567811
            ],
            [
              -75.110903,
              40.570671
            ],
            [
              -75.117292,
              40.573211
            ],
            [
              -75.136748,
              40.575731
            ],
            [
              -75.141906,
              40.575273
            ],
            [
              -75.147368,
              40.573152
            ],
            [
              -75.158446,
              40.565286
            ],
            [
              -75.162871,
              40.564096
            ],
            [
              -75.168609,
              40.564111
            ],
            [
              -75.175307,
              40.564996
            ],
            [
              -75.183151,
              40.567354
            ],
            [
              -75.186737,
              40.569406
            ],
            [
              -75.192352,
              40.574257
            ],
            [
              -75.194046,
              40.576256
            ],
            [
              -75.19487,
              40.578591
            ],
            [
              -75.195114,
              40.579689
            ],
            [
              -75.194656,
              40.58194
            ],
            [
              -75.190796,
              40.586838
            ],
            [
              -75.190146,
              40.590359
            ],
            [
              -75.190369434453,
              40.5916420033367
            ],
            [
              -75.192291,
              40.602676
            ],
            [
              -75.195923,
              40.606788
            ],
            [
              -75.196803,
              40.60858
            ],
            [
              -75.25654,
              40.582731
            ],
            [
              -75.299017,
              40.558601
            ],
            [
              -75.329489,
              40.539043
            ],
            [
              -75.333514,
              40.537057
            ],
            [
              -75.409733,
              40.487984
            ],
            [
              -75.484057,
              40.418451
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "005",
      "COUNTYNS": "01213658",
      "AFFGEOID": "0500000US42005",
      "GEOID": "42005",
      "NAME": "Armstrong",
      "LSAD": "06",
      "ALAND": 1691718473,
      "AWATER": 27625399,
      "County_state": "Armstrong,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.69293,
              40.669744
            ],
            [
              -79.692587,
              40.669732
            ],
            [
              -79.668345,
              40.680049
            ],
            [
              -79.638137,
              40.665601
            ],
            [
              -79.61818,
              40.644766
            ],
            [
              -79.616808,
              40.644817
            ],
            [
              -79.613128,
              40.643617
            ],
            [
              -79.611032,
              40.642017
            ],
            [
              -79.609496,
              40.639809
            ],
            [
              -79.60892,
              40.637313
            ],
            [
              -79.609288,
              40.633873
            ],
            [
              -79.610135,
              40.631009
            ],
            [
              -79.609946,
              40.628317
            ],
            [
              -79.608952,
              40.625954
            ],
            [
              -79.608133,
              40.62549
            ],
            [
              -79.591656,
              40.629234
            ],
            [
              -79.58738,
              40.62707
            ],
            [
              -79.589575,
              40.616865
            ],
            [
              -79.584375,
              40.607041
            ],
            [
              -79.578455,
              40.601409
            ],
            [
              -79.550608,
              40.603897
            ],
            [
              -79.565932,
              40.593403
            ],
            [
              -79.56111,
              40.564113
            ],
            [
              -79.526249,
              40.542364
            ],
            [
              -79.511912,
              40.542683
            ],
            [
              -79.497092,
              40.525338
            ],
            [
              -79.464289,
              40.53408
            ],
            [
              -79.450176,
              40.530149
            ],
            [
              -79.358475,
              40.624531
            ],
            [
              -79.337576,
              40.646696
            ],
            [
              -79.215235,
              40.776016
            ],
            [
              -79.21531,
              40.911346
            ],
            [
              -79.210197,
              41.049091
            ],
            [
              -79.215214,
              41.050515
            ],
            [
              -79.261137,
              41.028977
            ],
            [
              -79.284998,
              41.009295
            ],
            [
              -79.302476,
              41.013535
            ],
            [
              -79.32027,
              41.001537
            ],
            [
              -79.365971,
              41.0008
            ],
            [
              -79.376059,
              40.968377
            ],
            [
              -79.374762,
              40.98374
            ],
            [
              -79.420275,
              41.003978
            ],
            [
              -79.424649,
              40.988965
            ],
            [
              -79.451022,
              40.98173
            ],
            [
              -79.456958,
              40.991145
            ],
            [
              -79.488517,
              40.995369
            ],
            [
              -79.493658,
              40.977901
            ],
            [
              -79.514012,
              40.986809
            ],
            [
              -79.521677,
              40.975068
            ],
            [
              -79.539427,
              40.984288
            ],
            [
              -79.552624,
              40.97978
            ],
            [
              -79.572787,
              40.9853
            ],
            [
              -79.590091,
              40.969945
            ],
            [
              -79.620787,
              40.975167
            ],
            [
              -79.619067,
              40.98896
            ],
            [
              -79.581361,
              40.994556
            ],
            [
              -79.587951,
              41.003243
            ],
            [
              -79.607627,
              41.005416
            ],
            [
              -79.598932,
              41.032211
            ],
            [
              -79.619575,
              41.033396
            ],
            [
              -79.665762,
              41.049123
            ],
            [
              -79.655503,
              41.066734
            ],
            [
              -79.680057,
              41.09371
            ],
            [
              -79.679288,
              41.116242
            ],
            [
              -79.686184,
              41.143793
            ],
            [
              -79.679925,
              41.16076
            ],
            [
              -79.690711,
              41.170691
            ],
            [
              -79.69014,
              41.030659
            ],
            [
              -79.691295,
              40.894816
            ],
            [
              -79.691968,
              40.81336
            ],
            [
              -79.69293,
              40.669744
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "023",
      "COUNTYNS": "01213663",
      "AFFGEOID": "0500000US42023",
      "GEOID": "42023",
      "NAME": "Cameron",
      "LSAD": "06",
      "ALAND": 1026229553,
      "AWATER": 5664041,
      "County_state": "Cameron,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.419118,
              41.602188
            ],
            [
              -78.420028,
              41.405046
            ],
            [
              -78.253959,
              41.404721
            ],
            [
              -78.254382,
              41.323584
            ],
            [
              -78.234675,
              41.323413
            ],
            [
              -78.236817,
              41.230475
            ],
            [
              -78.093381,
              41.216931
            ],
            [
              -77.988786,
              41.367453
            ],
            [
              -77.989194,
              41.474822
            ],
            [
              -78.050442,
              41.475462
            ],
            [
              -78.203422,
              41.618157
            ],
            [
              -78.419299,
              41.616853
            ],
            [
              -78.419118,
              41.602188
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "025",
      "COUNTYNS": "01213664",
      "AFFGEOID": "0500000US42025",
      "GEOID": "42025",
      "NAME": "Carbon",
      "LSAD": "06",
      "ALAND": 987977576,
      "AWATER": 15349904,
      "County_state": "Carbon,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.997348,
              40.912985
            ],
            [
              -75.994358,
              40.910119
            ],
            [
              -75.926429,
              40.851726
            ],
            [
              -75.893655,
              40.822806
            ],
            [
              -75.886037,
              40.816267
            ],
            [
              -75.870839,
              40.806948
            ],
            [
              -75.858369,
              40.798753
            ],
            [
              -75.757807,
              40.735414
            ],
            [
              -75.708515,
              40.760302
            ],
            [
              -75.69938,
              40.76612
            ],
            [
              -75.694749,
              40.770075
            ],
            [
              -75.608985,
              40.787387
            ],
            [
              -75.577488,
              40.799414
            ],
            [
              -75.474193,
              40.814746
            ],
            [
              -75.487657,
              40.838035
            ],
            [
              -75.556438,
              40.928693
            ],
            [
              -75.57459,
              40.951323
            ],
            [
              -75.49599,
              40.987181
            ],
            [
              -75.561947,
              41.049523
            ],
            [
              -75.576484,
              41.067573
            ],
            [
              -75.612694,
              41.094237
            ],
            [
              -75.620397,
              41.109284
            ],
            [
              -75.645873,
              41.112949
            ],
            [
              -75.649637,
              41.122344
            ],
            [
              -75.667322,
              41.134023
            ],
            [
              -75.692494,
              41.131284
            ],
            [
              -75.732499,
              41.102968
            ],
            [
              -75.76728,
              41.093056
            ],
            [
              -75.765416,
              41.084996
            ],
            [
              -75.770032,
              41.06575
            ],
            [
              -75.770196,
              41.050219
            ],
            [
              -75.756397,
              41.031581
            ],
            [
              -75.7425,
              41.031712
            ],
            [
              -75.73193,
              41.00822
            ],
            [
              -75.801702,
              40.982917
            ],
            [
              -75.819575,
              40.982069
            ],
            [
              -75.849625,
              40.971605
            ],
            [
              -75.935529,
              40.935361
            ],
            [
              -75.997348,
              40.912985
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "047",
      "COUNTYNS": "01213668",
      "AFFGEOID": "0500000US42047",
      "GEOID": "42047",
      "NAME": "Elk",
      "LSAD": "06",
      "ALAND": 2142856032,
      "AWATER": 12818644,
      "County_state": "Elk,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.093588,
              41.423286
            ],
            [
              -79.095096,
              41.340658
            ],
            [
              -79.076706,
              41.354249
            ],
            [
              -79.065138,
              41.350157
            ],
            [
              -79.027786,
              41.357205
            ],
            [
              -79.020329,
              41.350494
            ],
            [
              -78.992688,
              41.368616
            ],
            [
              -78.97159,
              41.376506
            ],
            [
              -78.959157,
              41.362161
            ],
            [
              -78.73528,
              41.262915
            ],
            [
              -78.738214,
              41.230672
            ],
            [
              -78.710059,
              41.230243
            ],
            [
              -78.710248,
              41.201859
            ],
            [
              -78.647947,
              41.21746
            ],
            [
              -78.646666,
              41.253778
            ],
            [
              -78.484712,
              41.24394
            ],
            [
              -78.236817,
              41.230475
            ],
            [
              -78.234675,
              41.323413
            ],
            [
              -78.254382,
              41.323584
            ],
            [
              -78.253959,
              41.404721
            ],
            [
              -78.420028,
              41.405046
            ],
            [
              -78.419118,
              41.602188
            ],
            [
              -78.482836,
              41.602354
            ],
            [
              -78.483409,
              41.629898
            ],
            [
              -78.641557,
              41.628186
            ],
            [
              -78.672528,
              41.625061
            ],
            [
              -78.815809,
              41.622726
            ],
            [
              -78.956056,
              41.623863
            ],
            [
              -78.956042,
              41.623402
            ],
            [
              -78.9586,
              41.525492
            ],
            [
              -78.958957,
              41.437061
            ],
            [
              -78.976657,
              41.436963
            ],
            [
              -78.976658,
              41.451761
            ],
            [
              -79.06352,
              41.453207
            ],
            [
              -79.065154,
              41.423194
            ],
            [
              -79.093588,
              41.423286
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "009",
      "COUNTYNS": "01209171",
      "AFFGEOID": "0500000US42009",
      "GEOID": "42009",
      "NAME": "Bedford",
      "LSAD": "06",
      "ALAND": 2621836594,
      "AWATER": 11935889,
      "County_state": "Bedford,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.767041,
              39.795384
            ],
            [
              -78.8083363526072,
              39.7230036184217
            ],
            [
              -78.723578,
              39.723122
            ],
            [
              -78.65821322789421,
              39.7229156692895
            ],
            [
              -78.61031867837701,
              39.722764485156006
            ],
            [
              -78.575951,
              39.722656
            ],
            [
              -78.546475,
              39.722967
            ],
            [
              -78.537763,
              39.722589
            ],
            [
              -78.461488,
              39.722976
            ],
            [
              -78.438906,
              39.72259
            ],
            [
              -78.3805616640433,
              39.7226311929318
            ],
            [
              -78.328849,
              39.781284
            ],
            [
              -78.299003,
              39.8255
            ],
            [
              -78.267502,
              39.903501
            ],
            [
              -78.219811,
              39.993288
            ],
            [
              -78.20433,
              40.010979
            ],
            [
              -78.204157,
              40.042203
            ],
            [
              -78.187896,
              40.047054
            ],
            [
              -78.181488,
              40.058715
            ],
            [
              -78.202732,
              40.068898
            ],
            [
              -78.206894,
              40.086265
            ],
            [
              -78.158866,
              40.121902
            ],
            [
              -78.134863,
              40.165131
            ],
            [
              -78.236302,
              40.224559
            ],
            [
              -78.257305,
              40.297883
            ],
            [
              -78.288764,
              40.242107
            ],
            [
              -78.3931,
              40.284202
            ],
            [
              -78.434288,
              40.302301
            ],
            [
              -78.447895,
              40.244395
            ],
            [
              -78.5057,
              40.287308
            ],
            [
              -78.529696,
              40.29287
            ],
            [
              -78.620383,
              40.327025
            ],
            [
              -78.651868,
              40.281678
            ],
            [
              -78.649517,
              40.275042
            ],
            [
              -78.647953,
              40.270621
            ],
            [
              -78.643998,
              40.259446
            ],
            [
              -78.658409,
              40.242933
            ],
            [
              -78.676956,
              40.211207
            ],
            [
              -78.739847,
              40.069571
            ],
            [
              -78.752016,
              40.074917
            ],
            [
              -78.763202,
              40.056745
            ],
            [
              -78.757241,
              39.823484
            ],
            [
              -78.767041,
              39.795384
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "029",
      "COUNTYNS": "01209174",
      "AFFGEOID": "0500000US42029",
      "GEOID": "42029",
      "NAME": "Chester",
      "LSAD": "06",
      "ALAND": 1943847580,
      "AWATER": 22560875,
      "County_state": "Chester,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.1357639058503,
              39.7219073562293
            ],
            [
              -76.1240338741063,
              39.7219387283388
            ],
            [
              -76.02784,
              39.722196
            ],
            [
              -76.01329,
              39.722284
            ],
            [
              -75.998872,
              39.721942
            ],
            [
              -75.8121525719633,
              39.722288568876
            ],
            [
              -75.810304,
              39.722292
            ],
            [
              -75.799799,
              39.722269
            ],
            [
              -75.788596,
              39.722199
            ],
            [
              -75.773786,
              39.7222
            ],
            [
              -75.76626,
              39.738178
            ],
            [
              -75.760529,
              39.747594
            ],
            [
              -75.7595457568876,
              39.748993919484
            ],
            [
              -75.753228,
              39.757989
            ],
            [
              -75.744536,
              39.768206
            ],
            [
              -75.736614,
              39.776103
            ],
            [
              -75.727156,
              39.784462
            ],
            [
              -75.72390952185569,
              39.7869901823956
            ],
            [
              -75.7195088833789,
              39.7904171627208
            ],
            [
              -75.717059,
              39.792325
            ],
            [
              -75.71010888507239,
              39.796989379588695
            ],
            [
              -75.701275,
              39.802918
            ],
            [
              -75.686039,
              39.811351
            ],
            [
              -75.6671626247441,
              39.819550051624496
            ],
            [
              -75.662846,
              39.821425
            ],
            [
              -75.641523,
              39.828616
            ],
            [
              -75.634706,
              39.83041
            ],
            [
              -75.61724,
              39.834227
            ],
            [
              -75.595734,
              39.837361
            ],
            [
              -75.5948740549263,
              39.8374831442788
            ],
            [
              -75.592561,
              39.868125
            ],
            [
              -75.597914,
              39.874464
            ],
            [
              -75.571162,
              39.888136
            ],
            [
              -75.562539,
              39.901638
            ],
            [
              -75.56237,
              39.901709
            ],
            [
              -75.542734,
              39.927181
            ],
            [
              -75.523505,
              39.923142
            ],
            [
              -75.523816,
              39.944124
            ],
            [
              -75.502135,
              39.954367
            ],
            [
              -75.436055,
              39.984948
            ],
            [
              -75.45343,
              39.993454
            ],
            [
              -75.404338,
              40.027364
            ],
            [
              -75.414991,
              40.041787
            ],
            [
              -75.373013,
              40.06019
            ],
            [
              -75.361066,
              40.065543
            ],
            [
              -75.366687,
              40.072355
            ],
            [
              -75.392632,
              40.060974
            ],
            [
              -75.420124,
              40.097201
            ],
            [
              -75.456501,
              40.088058
            ],
            [
              -75.461729,
              40.103035
            ],
            [
              -75.471584,
              40.112373
            ],
            [
              -75.462633,
              40.11958
            ],
            [
              -75.461599,
              40.124825
            ],
            [
              -75.493688,
              40.126493
            ],
            [
              -75.509237,
              40.136619
            ],
            [
              -75.502718,
              40.159196
            ],
            [
              -75.526838,
              40.149552
            ],
            [
              -75.528941,
              40.168396
            ],
            [
              -75.539581,
              40.174966
            ],
            [
              -75.547419,
              40.186449
            ],
            [
              -75.557148,
              40.207213
            ],
            [
              -75.573338,
              40.195303
            ],
            [
              -75.601356,
              40.23805
            ],
            [
              -75.612373,
              40.222997
            ],
            [
              -75.627792,
              40.232836
            ],
            [
              -75.645407,
              40.239603
            ],
            [
              -75.667161,
              40.245124
            ],
            [
              -75.676059,
              40.242551
            ],
            [
              -75.688526,
              40.236193
            ],
            [
              -75.696782,
              40.241863
            ],
            [
              -75.743178,
              40.216721
            ],
            [
              -75.807866,
              40.177654
            ],
            [
              -75.873375,
              40.137107
            ],
            [
              -75.937998,
              40.111139
            ],
            [
              -75.94244,
              40.106515
            ],
            [
              -75.936135,
              40.078585
            ],
            [
              -75.93587,
              40.037466
            ],
            [
              -75.951398,
              40.016421
            ],
            [
              -75.972407,
              39.98751
            ],
            [
              -75.994644,
              39.95225
            ],
            [
              -75.988005,
              39.939562
            ],
            [
              -75.985056,
              39.937871
            ],
            [
              -75.997165,
              39.928671
            ],
            [
              -75.995133,
              39.898916
            ],
            [
              -75.991284,
              39.868738
            ],
            [
              -76.001709,
              39.874698
            ],
            [
              -76.006734,
              39.853958
            ],
            [
              -76.022823,
              39.849844
            ],
            [
              -76.017384,
              39.833488
            ],
            [
              -76.037636,
              39.826917
            ],
            [
              -76.029592,
              39.812998
            ],
            [
              -76.049293,
              39.802003
            ],
            [
              -76.041365,
              39.797196
            ],
            [
              -76.067427,
              39.75965
            ],
            [
              -76.10205,
              39.739884
            ],
            [
              -76.102479,
              39.724033
            ],
            [
              -76.119924,
              39.736364
            ],
            [
              -76.1357639058503,
              39.7219073562293
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "031",
      "COUNTYNS": "01210234",
      "AFFGEOID": "0500000US42031",
      "GEOID": "42031",
      "NAME": "Clarion",
      "LSAD": "06",
      "ALAND": 1556152465,
      "AWATER": 23335885,
      "County_state": "Clarion,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.697242,
              41.184614
            ],
            [
              -79.694984,
              41.172865
            ],
            [
              -79.690711,
              41.170691
            ],
            [
              -79.679925,
              41.16076
            ],
            [
              -79.686184,
              41.143793
            ],
            [
              -79.679288,
              41.116242
            ],
            [
              -79.680057,
              41.09371
            ],
            [
              -79.655503,
              41.066734
            ],
            [
              -79.665762,
              41.049123
            ],
            [
              -79.619575,
              41.033396
            ],
            [
              -79.598932,
              41.032211
            ],
            [
              -79.607627,
              41.005416
            ],
            [
              -79.587951,
              41.003243
            ],
            [
              -79.581361,
              40.994556
            ],
            [
              -79.619067,
              40.98896
            ],
            [
              -79.620787,
              40.975167
            ],
            [
              -79.590091,
              40.969945
            ],
            [
              -79.572787,
              40.9853
            ],
            [
              -79.552624,
              40.97978
            ],
            [
              -79.539427,
              40.984288
            ],
            [
              -79.521677,
              40.975068
            ],
            [
              -79.514012,
              40.986809
            ],
            [
              -79.493658,
              40.977901
            ],
            [
              -79.488517,
              40.995369
            ],
            [
              -79.456958,
              40.991145
            ],
            [
              -79.451022,
              40.98173
            ],
            [
              -79.424649,
              40.988965
            ],
            [
              -79.420275,
              41.003978
            ],
            [
              -79.374762,
              40.98374
            ],
            [
              -79.376059,
              40.968377
            ],
            [
              -79.365971,
              41.0008
            ],
            [
              -79.32027,
              41.001537
            ],
            [
              -79.302476,
              41.013535
            ],
            [
              -79.284998,
              41.009295
            ],
            [
              -79.261137,
              41.028977
            ],
            [
              -79.215214,
              41.050515
            ],
            [
              -79.21132,
              41.078737
            ],
            [
              -79.211173,
              41.112575
            ],
            [
              -79.208878,
              41.331861
            ],
            [
              -79.207196,
              41.430922
            ],
            [
              -79.357653,
              41.432432
            ],
            [
              -79.400269,
              41.436145
            ],
            [
              -79.400439,
              41.386579
            ],
            [
              -79.477822,
              41.386479
            ],
            [
              -79.478365,
              41.335716
            ],
            [
              -79.54143,
              41.335951
            ],
            [
              -79.541474,
              41.321008
            ],
            [
              -79.604161,
              41.321478
            ],
            [
              -79.60461,
              41.277072
            ],
            [
              -79.627561,
              41.277183
            ],
            [
              -79.632875,
              41.253599
            ],
            [
              -79.650394,
              41.253709
            ],
            [
              -79.649628,
              41.217912
            ],
            [
              -79.668892,
              41.199075
            ],
            [
              -79.670125,
              41.183116
            ],
            [
              -79.697242,
              41.184614
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "053",
      "COUNTYNS": "01213669",
      "AFFGEOID": "0500000US42053",
      "GEOID": "42053",
      "NAME": "Forest",
      "LSAD": "06",
      "ALAND": 1106623935,
      "AWATER": 8356610,
      "County_state": "Forest,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.512276,
              41.46524
            ],
            [
              -79.521599,
              41.440554
            ],
            [
              -79.478163,
              41.440134
            ],
            [
              -79.477822,
              41.386479
            ],
            [
              -79.400439,
              41.386579
            ],
            [
              -79.400269,
              41.436145
            ],
            [
              -79.357653,
              41.432432
            ],
            [
              -79.207196,
              41.430922
            ],
            [
              -79.208878,
              41.331861
            ],
            [
              -79.187005,
              41.33731
            ],
            [
              -79.154733,
              41.32865
            ],
            [
              -79.139791,
              41.341431
            ],
            [
              -79.106743,
              41.3261
            ],
            [
              -79.095096,
              41.340658
            ],
            [
              -79.093588,
              41.423286
            ],
            [
              -79.065154,
              41.423194
            ],
            [
              -79.06352,
              41.453207
            ],
            [
              -78.976658,
              41.451761
            ],
            [
              -78.976657,
              41.436963
            ],
            [
              -78.958957,
              41.437061
            ],
            [
              -78.9586,
              41.525492
            ],
            [
              -78.956042,
              41.623402
            ],
            [
              -79.493454,
              41.624716
            ],
            [
              -79.51207,
              41.624559
            ],
            [
              -79.512276,
              41.46524
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "097",
      "COUNTYNS": "01209186",
      "AFFGEOID": "0500000US42097",
      "GEOID": "42097",
      "NAME": "Northumberland",
      "LSAD": "06",
      "ALAND": 1186219234,
      "AWATER": 50572294,
      "County_state": "Northumberland,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.939749,
              40.638378
            ],
            [
              -76.949414,
              40.628163
            ],
            [
              -76.918672,
              40.603466
            ],
            [
              -76.886411,
              40.617758
            ],
            [
              -76.86578,
              40.627244
            ],
            [
              -76.840104,
              40.625439
            ],
            [
              -76.81114,
              40.633817
            ],
            [
              -76.80984,
              40.642047
            ],
            [
              -76.804867,
              40.646839
            ],
            [
              -76.794052,
              40.640544
            ],
            [
              -76.749698,
              40.65315
            ],
            [
              -76.701624,
              40.658082
            ],
            [
              -76.700722,
              40.663247
            ],
            [
              -76.469523,
              40.743188
            ],
            [
              -76.380152,
              40.775511
            ],
            [
              -76.409667,
              40.833624
            ],
            [
              -76.501163,
              40.824737
            ],
            [
              -76.507916,
              40.846526
            ],
            [
              -76.49821,
              40.853823
            ],
            [
              -76.50578,
              40.882382
            ],
            [
              -76.528034,
              40.882515
            ],
            [
              -76.541786,
              40.882551
            ],
            [
              -76.551792,
              40.883406
            ],
            [
              -76.568111,
              40.915454
            ],
            [
              -76.557013,
              40.938617
            ],
            [
              -76.601436,
              40.942186
            ],
            [
              -76.62508,
              40.960309
            ],
            [
              -76.644446,
              40.96453
            ],
            [
              -76.661348,
              40.967996
            ],
            [
              -76.734826,
              40.954448
            ],
            [
              -76.792878,
              40.946583
            ],
            [
              -76.775786,
              41.008118
            ],
            [
              -76.734891,
              41.092133
            ],
            [
              -76.732672,
              41.17204
            ],
            [
              -76.788659,
              41.175762
            ],
            [
              -76.880963,
              41.158044
            ],
            [
              -76.884245,
              41.157099
            ],
            [
              -76.885228,
              41.155973
            ],
            [
              -76.888145,
              41.153807
            ],
            [
              -76.889338,
              41.151988
            ],
            [
              -76.889669,
              41.150791
            ],
            [
              -76.896114,
              41.13907
            ],
            [
              -76.889685,
              41.101313
            ],
            [
              -76.857878,
              41.072056
            ],
            [
              -76.864544,
              41.019795
            ],
            [
              -76.868842,
              40.994432
            ],
            [
              -76.882249,
              40.968763
            ],
            [
              -76.851456,
              40.912834
            ],
            [
              -76.823884,
              40.890146
            ],
            [
              -76.800242,
              40.881994
            ],
            [
              -76.798747,
              40.878052
            ],
            [
              -76.813022,
              40.847058
            ],
            [
              -76.849329,
              40.809666
            ],
            [
              -76.858703,
              40.781521
            ],
            [
              -76.857403,
              40.750035
            ],
            [
              -76.861184,
              40.711598
            ],
            [
              -76.880067,
              40.687173
            ],
            [
              -76.903849,
              40.674096
            ],
            [
              -76.939749,
              40.638378
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "057",
      "COUNTYNS": "01213671",
      "AFFGEOID": "0500000US42057",
      "GEOID": "42057",
      "NAME": "Fulton",
      "LSAD": "06",
      "ALAND": 1133252798,
      "AWATER": 1305486,
      "County_state": "Fulton,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.3805616640433,
              39.7226311929318
            ],
            [
              -78.3428342787621,
              39.7226578296496
            ],
            [
              -78.342593,
              39.722658
            ],
            [
              -78.340571,
              39.722633
            ],
            [
              -78.339612,
              39.722672
            ],
            [
              -78.337184,
              39.722581
            ],
            [
              -78.330789,
              39.722809
            ],
            [
              -78.269098,
              39.72274
            ],
            [
              -78.269026,
              39.722717
            ],
            [
              -78.243182,
              39.722611
            ],
            [
              -78.240414,
              39.722628
            ],
            [
              -78.204532,
              39.722654
            ],
            [
              -78.202977,
              39.72255
            ],
            [
              -78.09897028757709,
              39.722466543278095
            ],
            [
              -78.069297,
              39.748298
            ],
            [
              -78.064498,
              39.757701
            ],
            [
              -78.028503,
              39.7999
            ],
            [
              -78.0027,
              39.826599
            ],
            [
              -77.975135,
              39.871788
            ],
            [
              -77.956543,
              39.914824
            ],
            [
              -77.956188,
              39.927024
            ],
            [
              -77.944045,
              39.940596
            ],
            [
              -77.919502,
              40.003417
            ],
            [
              -77.883327,
              40.048518
            ],
            [
              -77.864489,
              40.061568
            ],
            [
              -77.92556,
              40.103977
            ],
            [
              -77.925905,
              40.101911
            ],
            [
              -78.079528,
              40.133884
            ],
            [
              -78.134863,
              40.165131
            ],
            [
              -78.158866,
              40.121902
            ],
            [
              -78.206894,
              40.086265
            ],
            [
              -78.202732,
              40.068898
            ],
            [
              -78.181488,
              40.058715
            ],
            [
              -78.187896,
              40.047054
            ],
            [
              -78.204157,
              40.042203
            ],
            [
              -78.20433,
              40.010979
            ],
            [
              -78.219811,
              39.993288
            ],
            [
              -78.267502,
              39.903501
            ],
            [
              -78.299003,
              39.8255
            ],
            [
              -78.328849,
              39.781284
            ],
            [
              -78.3805616640433,
              39.7226311929318
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "095",
      "COUNTYNS": "01209185",
      "AFFGEOID": "0500000US42095",
      "GEOID": "42095",
      "NAME": "Northampton",
      "LSAD": "06",
      "ALAND": 957324105,
      "AWATER": 19452870,
      "County_state": "Northampton,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.608985,
              40.787387
            ],
            [
              -75.601926,
              40.750095
            ],
            [
              -75.591326,
              40.735055
            ],
            [
              -75.580444,
              40.735031
            ],
            [
              -75.558165,
              40.720886
            ],
            [
              -75.530236,
              40.736399
            ],
            [
              -75.515628,
              40.702196
            ],
            [
              -75.499179,
              40.68113
            ],
            [
              -75.49534,
              40.677787
            ],
            [
              -75.481469,
              40.655744
            ],
            [
              -75.466157,
              40.661305
            ],
            [
              -75.445286,
              40.66882
            ],
            [
              -75.430404,
              40.674152
            ],
            [
              -75.430122,
              40.668427
            ],
            [
              -75.387464,
              40.633122
            ],
            [
              -75.378125,
              40.615358
            ],
            [
              -75.390635,
              40.600677
            ],
            [
              -75.391275,
              40.596551
            ],
            [
              -75.403822,
              40.580787
            ],
            [
              -75.333514,
              40.537057
            ],
            [
              -75.329489,
              40.539043
            ],
            [
              -75.299017,
              40.558601
            ],
            [
              -75.25654,
              40.582731
            ],
            [
              -75.196803,
              40.60858
            ],
            [
              -75.198499,
              40.611492
            ],
            [
              -75.201348,
              40.614628
            ],
            [
              -75.201812,
              40.617188
            ],
            [
              -75.200708,
              40.618356
            ],
            [
              -75.197891,
              40.619332
            ],
            [
              -75.190691,
              40.619956
            ],
            [
              -75.189283,
              40.621492
            ],
            [
              -75.188579,
              40.624628
            ],
            [
              -75.191059,
              40.637971
            ],
            [
              -75.192276,
              40.640803
            ],
            [
              -75.193492,
              40.642275
            ],
            [
              -75.200468,
              40.646899
            ],
            [
              -75.200452,
              40.649219
            ],
            [
              -75.196676,
              40.655123
            ],
            [
              -75.190852,
              40.661939
            ],
            [
              -75.18794,
              40.663811
            ],
            [
              -75.182756,
              40.665971
            ],
            [
              -75.177491,
              40.672595
            ],
            [
              -75.176803,
              40.675715
            ],
            [
              -75.177587,
              40.677731
            ],
            [
              -75.180564,
              40.679363
            ],
            [
              -75.184516,
              40.679971
            ],
            [
              -75.1901585993587,
              40.6794201393766
            ],
            [
              -75.19058,
              40.679379
            ],
            [
              -75.19692,
              40.681299
            ],
            [
              -75.20092,
              40.685498
            ],
            [
              -75.20392,
              40.691498
            ],
            [
              -75.19872,
              40.705298
            ],
            [
              -75.19442,
              40.714018
            ],
            [
              -75.192856911611,
              40.71562258631079
            ],
            [
              -75.192612,
              40.715874
            ],
            [
              -75.189412,
              40.71797
            ],
            [
              -75.186372,
              40.72397
            ],
            [
              -75.1825,
              40.729922
            ],
            [
              -75.182084,
              40.731522
            ],
            [
              -75.182804,
              40.73365
            ],
            [
              -75.18578,
              40.737266
            ],
            [
              -75.195349,
              40.745473
            ],
            [
              -75.196325,
              40.747137
            ],
            [
              -75.196861,
              40.750097
            ],
            [
              -75.196533,
              40.751631
            ],
            [
              -75.191796,
              40.75583
            ],
            [
              -75.183037,
              40.759344
            ],
            [
              -75.17904,
              40.761897
            ],
            [
              -75.177477,
              40.764225
            ],
            [
              -75.176855,
              40.768721
            ],
            [
              -75.17562,
              40.772923
            ],
            [
              -75.173349,
              40.776129
            ],
            [
              -75.171587,
              40.777745
            ],
            [
              -75.169523,
              40.778473
            ],
            [
              -75.16365,
              40.778386
            ],
            [
              -75.149378,
              40.774786
            ],
            [
              -75.139106,
              40.773606
            ],
            [
              -75.1344,
              40.773765
            ],
            [
              -75.133303,
              40.774124
            ],
            [
              -75.131465,
              40.77595
            ],
            [
              -75.125867,
              40.784026
            ],
            [
              -75.123088,
              40.786746
            ],
            [
              -75.116842,
              40.78935
            ],
            [
              -75.111343,
              40.789896
            ],
            [
              -75.1113280423616,
              40.789902314041896
            ],
            [
              -75.108505,
              40.791094
            ],
            [
              -75.1008,
              40.799797
            ],
            [
              -75.100277,
              40.801176
            ],
            [
              -75.100165,
              40.803
            ],
            [
              -75.100739,
              40.805488
            ],
            [
              -75.100277,
              40.807578
            ],
            [
              -75.098279,
              40.810286
            ],
            [
              -75.096147,
              40.812211
            ],
            [
              -75.090518,
              40.815913
            ],
            [
              -75.085387,
              40.821972
            ],
            [
              -75.083929,
              40.824471
            ],
            [
              -75.083822,
              40.827805
            ],
            [
              -75.085517,
              40.830085
            ],
            [
              -75.09494,
              40.837103
            ],
            [
              -75.097006,
              40.839336
            ],
            [
              -75.097572,
              40.840967
            ],
            [
              -75.097586,
              40.843042
            ],
            [
              -75.097221,
              40.844672
            ],
            [
              -75.095784,
              40.847082
            ],
            [
              -75.090962,
              40.849187
            ],
            [
              -75.076684,
              40.849875
            ],
            [
              -75.073544,
              40.84894
            ],
            [
              -75.07083,
              40.847392
            ],
            [
              -75.066014,
              40.847591
            ],
            [
              -75.064328,
              40.848338
            ],
            [
              -75.060491,
              40.85302
            ],
            [
              -75.053294,
              40.8599
            ],
            [
              -75.051029,
              40.865662
            ],
            [
              -75.050839,
              40.868067
            ],
            [
              -75.051508,
              40.870224
            ],
            [
              -75.053664,
              40.87366
            ],
            [
              -75.058655,
              40.877654
            ],
            [
              -75.062149,
              40.882289
            ],
            [
              -75.065438,
              40.885682
            ],
            [
              -75.07392,
              40.892176
            ],
            [
              -75.07534,
              40.894162
            ],
            [
              -75.075957,
              40.895694
            ],
            [
              -75.075188,
              40.900154
            ],
            [
              -75.076092,
              40.907042
            ],
            [
              -75.076956,
              40.90988
            ],
            [
              -75.079279,
              40.91389
            ],
            [
              -75.095526,
              40.924152
            ],
            [
              -75.09772,
              40.926679
            ],
            [
              -75.105524,
              40.936294
            ],
            [
              -75.106153,
              40.939671
            ],
            [
              -75.111683,
              40.948111
            ],
            [
              -75.117764,
              40.953023
            ],
            [
              -75.118904,
              40.956361
            ],
            [
              -75.119893,
              40.961646
            ],
            [
              -75.120316,
              40.96263
            ],
            [
              -75.12065,
              40.964028
            ],
            [
              -75.11977,
              40.96651
            ],
            [
              -75.120435,
              40.968302
            ],
            [
              -75.12051811257778,
              40.968372921710795
            ],
            [
              -75.129232,
              40.95893
            ],
            [
              -75.21819,
              40.927875
            ],
            [
              -75.24113,
              40.909438
            ],
            [
              -75.244197,
              40.894203
            ],
            [
              -75.284039,
              40.86604
            ],
            [
              -75.29494,
              40.8622
            ],
            [
              -75.320931,
              40.855657
            ],
            [
              -75.378852,
              40.832527
            ],
            [
              -75.416759,
              40.826102
            ],
            [
              -75.443267,
              40.818276
            ],
            [
              -75.474193,
              40.814746
            ],
            [
              -75.577488,
              40.799414
            ],
            [
              -75.608985,
              40.787387
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "067",
      "COUNTYNS": "01209180",
      "AFFGEOID": "0500000US42067",
      "GEOID": "42067",
      "NAME": "Juniata",
      "LSAD": "06",
      "ALAND": 1013592887,
      "AWATER": 5606074,
      "County_state": "Juniata,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.746766,
              40.384661
            ],
            [
              -77.75212,
              40.378545
            ],
            [
              -77.705668,
              40.271312
            ],
            [
              -77.70303,
              40.263576
            ],
            [
              -77.684612,
              40.276744
            ],
            [
              -77.671761,
              40.289825
            ],
            [
              -77.636139,
              40.322374
            ],
            [
              -77.599428,
              40.348147
            ],
            [
              -77.565665,
              40.367363
            ],
            [
              -77.532265,
              40.376006
            ],
            [
              -77.540141,
              40.399221
            ],
            [
              -77.445888,
              40.44716
            ],
            [
              -77.413433,
              40.460589
            ],
            [
              -77.289592,
              40.518457
            ],
            [
              -77.231696,
              40.538484
            ],
            [
              -77.198462,
              40.552287
            ],
            [
              -77.167561,
              40.559509
            ],
            [
              -77.18293,
              40.58214
            ],
            [
              -77.103314,
              40.595905
            ],
            [
              -77.041954,
              40.604223
            ],
            [
              -77.023304,
              40.60426
            ],
            [
              -76.956077,
              40.627938
            ],
            [
              -76.949414,
              40.628163
            ],
            [
              -76.939749,
              40.638378
            ],
            [
              -76.944828,
              40.650209
            ],
            [
              -76.965528,
              40.647149
            ],
            [
              -77.006054,
              40.642615
            ],
            [
              -77.008418,
              40.659912
            ],
            [
              -77.034409,
              40.659928
            ],
            [
              -77.035549,
              40.676918
            ],
            [
              -77.060451,
              40.679854
            ],
            [
              -77.093607,
              40.676121
            ],
            [
              -77.109597,
              40.691578
            ],
            [
              -77.150516,
              40.677074
            ],
            [
              -77.287941,
              40.693595
            ],
            [
              -77.368273,
              40.667593
            ],
            [
              -77.468999,
              40.624746
            ],
            [
              -77.49776,
              40.609484
            ],
            [
              -77.497506,
              40.58174
            ],
            [
              -77.532456,
              40.559476
            ],
            [
              -77.566648,
              40.541805
            ],
            [
              -77.612509,
              40.514187
            ],
            [
              -77.612459,
              40.490919
            ],
            [
              -77.645997,
              40.468381
            ],
            [
              -77.721861,
              40.408542
            ],
            [
              -77.746766,
              40.384661
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "131",
      "COUNTYNS": "01209192",
      "AFFGEOID": "0500000US42131",
      "GEOID": "42131",
      "NAME": "Wyoming",
      "LSAD": "06",
      "ALAND": 1029016039,
      "AWATER": 20034376,
      "County_state": "Wyoming,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.2831,
              41.376517
            ],
            [
              -76.252678,
              41.377364
            ],
            [
              -76.00507,
              41.384551
            ],
            [
              -75.874379,
              41.416126
            ],
            [
              -75.839287,
              41.424633
            ],
            [
              -75.834695,
              41.427246
            ],
            [
              -75.773721,
              41.50383
            ],
            [
              -75.802502,
              41.523578
            ],
            [
              -75.774471,
              41.574187
            ],
            [
              -75.729174,
              41.635791
            ],
            [
              -75.719887,
              41.642263
            ],
            [
              -75.767924,
              41.643409
            ],
            [
              -75.782461,
              41.642848
            ],
            [
              -76.087759,
              41.644724
            ],
            [
              -76.114224,
              41.643385
            ],
            [
              -76.115172,
              41.651825
            ],
            [
              -76.197998,
              41.647823
            ],
            [
              -76.220139,
              41.541285
            ],
            [
              -76.228131,
              41.511137
            ],
            [
              -76.2831,
              41.376517
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "073",
      "COUNTYNS": "01213676",
      "AFFGEOID": "0500000US42073",
      "GEOID": "42073",
      "NAME": "Lawrence",
      "LSAD": "06",
      "ALAND": 927644463,
      "AWATER": 11783868,
      "County_state": "Lawrence,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.519891,
              40.906661
            ],
            [
              -80.5198187447909,
              40.9024401511485
            ],
            [
              -80.51979,
              40.900761
            ],
            [
              -80.51977742323069,
              40.9003241991296
            ],
            [
              -80.519764,
              40.899858
            ],
            [
              -80.519002,
              40.877543
            ],
            [
              -80.5190191701796,
              40.851339398102795
            ],
            [
              -80.322579,
              40.853151
            ],
            [
              -80.158534,
              40.855157
            ],
            [
              -80.160006,
              40.886909
            ],
            [
              -80.162547,
              40.937751
            ],
            [
              -80.16529,
              41.00047
            ],
            [
              -80.100723,
              41.065476
            ],
            [
              -80.096861,
              41.069804
            ],
            [
              -80.147164,
              41.113795
            ],
            [
              -80.257975,
              41.117338
            ],
            [
              -80.258201,
              41.127964
            ],
            [
              -80.384551,
              41.1265
            ],
            [
              -80.385094,
              41.126492
            ],
            [
              -80.519012,
              41.1250929473499
            ],
            [
              -80.519012,
              41.125057
            ],
            [
              -80.519056,
              41.125057
            ],
            [
              -80.518992,
              41.115958
            ],
            [
              -80.519192,
              41.105358
            ],
            [
              -80.519125,
              41.100819
            ],
            [
              -80.519092,
              41.090658
            ],
            [
              -80.519088,
              41.082074
            ],
            [
              -80.518999,
              41.075014
            ],
            [
              -80.51896,
              41.071866
            ],
            [
              -80.518928,
              41.070954
            ],
            [
              -80.51896,
              41.061546
            ],
            [
              -80.518927,
              41.015387
            ],
            [
              -80.518989,
              40.995445
            ],
            [
              -80.51900005923159,
              40.9873800011642
            ],
            [
              -80.519091,
              40.921061
            ],
            [
              -80.519891,
              40.906661
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "085",
      "COUNTYNS": "01213678",
      "AFFGEOID": "0500000US42085",
      "GEOID": "42085",
      "NAME": "Mercer",
      "LSAD": "06",
      "ALAND": 1741978098,
      "AWATER": 25963051,
      "County_state": "Mercer,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.519345,
              41.34074
            ],
            [
              -80.519345,
              41.340145
            ],
            [
              -80.519293,
              41.339654
            ],
            [
              -80.519293,
              41.339054
            ],
            [
              -80.519311,
              41.339052
            ],
            [
              -80.519281,
              41.337174
            ],
            [
              -80.519281,
              41.337145
            ],
            [
              -80.519281,
              41.335958
            ],
            [
              -80.51926508160219,
              41.333507561632196
            ],
            [
              -80.519265,
              41.333495
            ],
            [
              -80.519129,
              41.312408
            ],
            [
              -80.518794,
              41.305509
            ],
            [
              -80.518996,
              41.2683
            ],
            [
              -80.518993,
              41.268155
            ],
            [
              -80.518893,
              41.265155
            ],
            [
              -80.518693,
              41.248855
            ],
            [
              -80.518893,
              41.232556
            ],
            [
              -80.518893,
              41.219356
            ],
            [
              -80.51883,
              41.209213
            ],
            [
              -80.519144,
              41.171203
            ],
            [
              -80.519115,
              41.14552
            ],
            [
              -80.5191668077946,
              41.133388009323596
            ],
            [
              -80.519167,
              41.133343
            ],
            [
              -80.519012,
              41.125116
            ],
            [
              -80.519012,
              41.1250929473499
            ],
            [
              -80.385094,
              41.126492
            ],
            [
              -80.384551,
              41.1265
            ],
            [
              -80.258201,
              41.127964
            ],
            [
              -80.257975,
              41.117338
            ],
            [
              -80.147164,
              41.113795
            ],
            [
              -80.096861,
              41.069804
            ],
            [
              -80.067373,
              41.09986
            ],
            [
              -79.999779,
              41.171861
            ],
            [
              -79.999779,
              41.490049
            ],
            [
              -80.041351,
              41.488712
            ],
            [
              -80.125316,
              41.480395
            ],
            [
              -80.265144,
              41.48345
            ],
            [
              -80.431147,
              41.486958
            ],
            [
              -80.444896,
              41.492269
            ],
            [
              -80.445001,
              41.487118
            ],
            [
              -80.51920863754069,
              41.4890128693366
            ],
            [
              -80.519169,
              41.462581
            ],
            [
              -80.51902726415379,
              41.440735157387
            ],
            [
              -80.518993,
              41.435454
            ],
            [
              -80.518993,
              41.423954
            ],
            [
              -80.518993,
              41.416437
            ],
            [
              -80.519025,
              41.416438
            ],
            [
              -80.519249,
              41.378918
            ],
            [
              -80.519217,
              41.372006
            ],
            [
              -80.519249,
              41.36103
            ],
            [
              -80.519345,
              41.34074
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "079",
      "COUNTYNS": "01209183",
      "AFFGEOID": "0500000US42079",
      "GEOID": "42079",
      "NAME": "Luzerne",
      "LSAD": "06",
      "ALAND": 2305976986,
      "AWATER": 41237262,
      "County_state": "Luzerne,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.319957,
              41.211255
            ],
            [
              -76.317916,
              41.205384
            ],
            [
              -76.277639,
              41.131804
            ],
            [
              -76.228975,
              41.138466
            ],
            [
              -76.228481,
              41.052012
            ],
            [
              -76.207827,
              40.94974
            ],
            [
              -76.104693,
              40.922693
            ],
            [
              -76.02815,
              40.901986
            ],
            [
              -75.997348,
              40.912985
            ],
            [
              -75.935529,
              40.935361
            ],
            [
              -75.849625,
              40.971605
            ],
            [
              -75.819575,
              40.982069
            ],
            [
              -75.801702,
              40.982917
            ],
            [
              -75.73193,
              41.00822
            ],
            [
              -75.7425,
              41.031712
            ],
            [
              -75.756397,
              41.031581
            ],
            [
              -75.770196,
              41.050219
            ],
            [
              -75.770032,
              41.06575
            ],
            [
              -75.765416,
              41.084996
            ],
            [
              -75.76728,
              41.093056
            ],
            [
              -75.732499,
              41.102968
            ],
            [
              -75.692494,
              41.131284
            ],
            [
              -75.667322,
              41.134023
            ],
            [
              -75.649637,
              41.122344
            ],
            [
              -75.634978,
              41.123192
            ],
            [
              -75.600736,
              41.161497
            ],
            [
              -75.616567,
              41.172618
            ],
            [
              -75.643494,
              41.176259
            ],
            [
              -75.649257,
              41.190951
            ],
            [
              -75.652922,
              41.258314
            ],
            [
              -75.661421,
              41.261041
            ],
            [
              -75.677753,
              41.264491
            ],
            [
              -75.679356,
              41.288179
            ],
            [
              -75.694055,
              41.303815
            ],
            [
              -75.687244,
              41.339216
            ],
            [
              -75.741539,
              41.348179
            ],
            [
              -75.760518,
              41.360942
            ],
            [
              -75.76786,
              41.365841
            ],
            [
              -75.795286,
              41.378127
            ],
            [
              -75.830135,
              41.394414
            ],
            [
              -75.834695,
              41.427246
            ],
            [
              -75.839287,
              41.424633
            ],
            [
              -75.874379,
              41.416126
            ],
            [
              -76.00507,
              41.384551
            ],
            [
              -76.252678,
              41.377364
            ],
            [
              -76.2831,
              41.376517
            ],
            [
              -76.310133,
              41.310199
            ],
            [
              -76.319957,
              41.211255
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "013",
      "COUNTYNS": "01213659",
      "AFFGEOID": "0500000US42013",
      "GEOID": "42013",
      "NAME": "Blair",
      "LSAD": "06",
      "ALAND": 1361815246,
      "AWATER": 3285511,
      "County_state": "Blair,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.602353,
              40.352973
            ],
            [
              -78.620383,
              40.327025
            ],
            [
              -78.529696,
              40.29287
            ],
            [
              -78.5057,
              40.287308
            ],
            [
              -78.447895,
              40.244395
            ],
            [
              -78.434288,
              40.302301
            ],
            [
              -78.3931,
              40.284202
            ],
            [
              -78.288764,
              40.242107
            ],
            [
              -78.257305,
              40.297883
            ],
            [
              -78.250091,
              40.312291
            ],
            [
              -78.191046,
              40.39187
            ],
            [
              -78.155447,
              40.438543
            ],
            [
              -78.134233,
              40.473242
            ],
            [
              -78.129584,
              40.490626
            ],
            [
              -78.135633,
              40.498214
            ],
            [
              -78.153373,
              40.488558
            ],
            [
              -78.147328,
              40.533251
            ],
            [
              -78.128638,
              40.567953
            ],
            [
              -78.149955,
              40.541366
            ],
            [
              -78.166247,
              40.531401
            ],
            [
              -78.176818,
              40.534471
            ],
            [
              -78.197083,
              40.558444
            ],
            [
              -78.17828,
              40.583786
            ],
            [
              -78.184212,
              40.595295
            ],
            [
              -78.166371,
              40.617948
            ],
            [
              -78.197219,
              40.639351
            ],
            [
              -78.198356,
              40.64809
            ],
            [
              -78.22197,
              40.674539
            ],
            [
              -78.204871,
              40.695874
            ],
            [
              -78.139076,
              40.724275
            ],
            [
              -78.116521,
              40.739127
            ],
            [
              -78.131834,
              40.743017
            ],
            [
              -78.282781,
              40.73641
            ],
            [
              -78.359912,
              40.732592
            ],
            [
              -78.35043,
              40.724827
            ],
            [
              -78.408629,
              40.644349
            ],
            [
              -78.44199,
              40.641477
            ],
            [
              -78.447515,
              40.591551
            ],
            [
              -78.485958,
              40.540847
            ],
            [
              -78.518455,
              40.528795
            ],
            [
              -78.53984,
              40.506154
            ],
            [
              -78.544715,
              40.486682
            ],
            [
              -78.540448,
              40.476387
            ],
            [
              -78.551183,
              40.456654
            ],
            [
              -78.55973,
              40.438765
            ],
            [
              -78.556958,
              40.409136
            ],
            [
              -78.564085,
              40.370609
            ],
            [
              -78.602353,
              40.352973
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "111",
      "COUNTYNS": "01209188",
      "AFFGEOID": "0500000US42111",
      "GEOID": "42111",
      "NAME": "Somerset",
      "LSAD": "06",
      "ALAND": 2782597159,
      "AWATER": 17002839,
      "County_state": "Somerset,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.398718,
              39.900628
            ],
            [
              -79.417558,
              39.853776
            ],
            [
              -79.381956,
              39.836861
            ],
            [
              -79.381233,
              39.826876
            ],
            [
              -79.365695,
              39.820546
            ],
            [
              -79.368849,
              39.801458
            ],
            [
              -79.356757,
              39.781477
            ],
            [
              -79.380058,
              39.790077
            ],
            [
              -79.391658,
              39.777977
            ],
            [
              -79.378658,
              39.768877
            ],
            [
              -79.399858,
              39.755577
            ],
            [
              -79.394058,
              39.743277
            ],
            [
              -79.409959,
              39.734978
            ],
            [
              -79.392659,
              39.732678
            ],
            [
              -79.3924584050224,
              39.7214393586401
            ],
            [
              -79.056531326389,
              39.722880985358294
            ],
            [
              -79.045576,
              39.722928
            ],
            [
              -78.931211,
              39.722832
            ],
            [
              -78.9284160738903,
              39.7228359036597
            ],
            [
              -78.8083363526072,
              39.7230036184217
            ],
            [
              -78.767041,
              39.795384
            ],
            [
              -78.757241,
              39.823484
            ],
            [
              -78.763202,
              40.056745
            ],
            [
              -78.752016,
              40.074917
            ],
            [
              -78.739847,
              40.069571
            ],
            [
              -78.676956,
              40.211207
            ],
            [
              -78.658409,
              40.242933
            ],
            [
              -78.838979,
              40.244794
            ],
            [
              -78.848041,
              40.244901
            ],
            [
              -78.849535,
              40.246012
            ],
            [
              -78.8531,
              40.245741
            ],
            [
              -78.856089,
              40.243431
            ],
            [
              -78.868756,
              40.248883
            ],
            [
              -78.884862,
              40.244631
            ],
            [
              -78.896546,
              40.27165
            ],
            [
              -78.927851,
              40.284214
            ],
            [
              -78.932662,
              40.284974
            ],
            [
              -79.055983,
              40.285089
            ],
            [
              -79.056646,
              40.278604
            ],
            [
              -79.088656,
              40.241259
            ],
            [
              -79.125963,
              40.184999
            ],
            [
              -79.143306,
              40.179889
            ],
            [
              -79.140702,
              40.163307
            ],
            [
              -79.161263,
              40.160869
            ],
            [
              -79.163164,
              40.14103
            ],
            [
              -79.185222,
              40.109848
            ],
            [
              -79.210774,
              40.118903
            ],
            [
              -79.226672,
              40.09825
            ],
            [
              -79.255632,
              40.088611
            ],
            [
              -79.278101,
              40.050479
            ],
            [
              -79.293682,
              40.040413
            ],
            [
              -79.296131,
              40.03675
            ],
            [
              -79.330397,
              39.992796
            ],
            [
              -79.347363,
              39.969492
            ],
            [
              -79.363739,
              39.947778
            ],
            [
              -79.398718,
              39.900628
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "045",
      "COUNTYNS": "01209177",
      "AFFGEOID": "0500000US42045",
      "GEOID": "42045",
      "NAME": "Delaware",
      "LSAD": "06",
      "ALAND": 476088135,
      "AWATER": 17572422,
      "County_state": "Delaware,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.597914,
              39.874464
            ],
            [
              -75.592561,
              39.868125
            ],
            [
              -75.5948740549263,
              39.8374831442788
            ],
            [
              -75.593643,
              39.837658
            ],
            [
              -75.579821,
              39.838714
            ],
            [
              -75.570433,
              39.839185
            ],
            [
              -75.53994382136939,
              39.8383719291137
            ],
            [
              -75.539309,
              39.838355
            ],
            [
              -75.518405,
              39.836432
            ],
            [
              -75.49968327104519,
              39.833546370246296
            ],
            [
              -75.498805,
              39.833411
            ],
            [
              -75.481207,
              39.829191
            ],
            [
              -75.46343882090001,
              39.823907709640096
            ],
            [
              -75.463312,
              39.82387
            ],
            [
              -75.46243421691631,
              39.82354886877079
            ],
            [
              -75.45987721341919,
              39.8226134056804
            ],
            [
              -75.453715,
              39.820359
            ],
            [
              -75.428027,
              39.809228
            ],
            [
              -75.415062,
              39.801919
            ],
            [
              -75.403737,
              39.807512
            ],
            [
              -75.390536,
              39.815312
            ],
            [
              -75.3897644561244,
              39.8158194589417
            ],
            [
              -75.371835,
              39.827612
            ],
            [
              -75.3544,
              39.839917
            ],
            [
              -75.341765,
              39.846082
            ],
            [
              -75.330433,
              39.849012
            ],
            [
              -75.323232,
              39.849812
            ],
            [
              -75.3185417987518,
              39.849938958538004
            ],
            [
              -75.309674,
              39.850179
            ],
            [
              -75.293376,
              39.848782
            ],
            [
              -75.271159,
              39.84944
            ],
            [
              -75.243431,
              39.854597
            ],
            [
              -75.235026,
              39.856613
            ],
            [
              -75.221025,
              39.861113
            ],
            [
              -75.2108764002954,
              39.865708592319095
            ],
            [
              -75.215722,
              39.876087
            ],
            [
              -75.262539,
              39.876578
            ],
            [
              -75.264433,
              39.882167
            ],
            [
              -75.264878,
              39.887927
            ],
            [
              -75.256741,
              39.894726
            ],
            [
              -75.251288,
              39.904403
            ],
            [
              -75.24694,
              39.916871
            ],
            [
              -75.234981,
              39.938023
            ],
            [
              -75.243026,
              39.942211
            ],
            [
              -75.247208,
              39.943904
            ],
            [
              -75.251496,
              39.949251
            ],
            [
              -75.247688,
              39.962817
            ],
            [
              -75.275394,
              39.969575
            ],
            [
              -75.280266,
              39.974964
            ],
            [
              -75.276482,
              39.976959
            ],
            [
              -75.286766,
              39.989956
            ],
            [
              -75.311432,
              40.01981
            ],
            [
              -75.320699,
              40.016216
            ],
            [
              -75.328531,
              40.026529
            ],
            [
              -75.349457,
              40.052905
            ],
            [
              -75.361066,
              40.065543
            ],
            [
              -75.373013,
              40.06019
            ],
            [
              -75.414991,
              40.041787
            ],
            [
              -75.404338,
              40.027364
            ],
            [
              -75.45343,
              39.993454
            ],
            [
              -75.436055,
              39.984948
            ],
            [
              -75.502135,
              39.954367
            ],
            [
              -75.523816,
              39.944124
            ],
            [
              -75.523505,
              39.923142
            ],
            [
              -75.542734,
              39.927181
            ],
            [
              -75.56237,
              39.901709
            ],
            [
              -75.562539,
              39.901638
            ],
            [
              -75.571162,
              39.888136
            ],
            [
              -75.597914,
              39.874464
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "063",
      "COUNTYNS": "01213673",
      "AFFGEOID": "0500000US42063",
      "GEOID": "42063",
      "NAME": "Indiana",
      "LSAD": "06",
      "ALAND": 2142009116,
      "AWATER": 18802621,
      "County_state": "Indiana,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.4599,
              40.517986
            ],
            [
              -79.452575,
              40.489003
            ],
            [
              -79.454011,
              40.485507
            ],
            [
              -79.453765,
              40.48425
            ],
            [
              -79.451747,
              40.482307
            ],
            [
              -79.447537,
              40.480811
            ],
            [
              -79.446694,
              40.480869
            ],
            [
              -79.440943,
              40.484921
            ],
            [
              -79.430628,
              40.489046
            ],
            [
              -79.423574,
              40.473329
            ],
            [
              -79.406631,
              40.474301
            ],
            [
              -79.395461,
              40.457132
            ],
            [
              -79.373741,
              40.453853
            ],
            [
              -79.365061,
              40.477868
            ],
            [
              -79.362275,
              40.460061
            ],
            [
              -79.345926,
              40.454825
            ],
            [
              -79.33844,
              40.469998
            ],
            [
              -79.325409,
              40.452699
            ],
            [
              -79.30245,
              40.461333
            ],
            [
              -79.301311,
              40.451756
            ],
            [
              -79.301284,
              40.435212
            ],
            [
              -79.272605,
              40.439082
            ],
            [
              -79.272856,
              40.418411
            ],
            [
              -79.247568,
              40.43208
            ],
            [
              -79.192722,
              40.414862
            ],
            [
              -79.155789,
              40.411765
            ],
            [
              -79.147892,
              40.3976
            ],
            [
              -79.117,
              40.394243
            ],
            [
              -79.12716,
              40.386878
            ],
            [
              -79.121634,
              40.370576
            ],
            [
              -79.096769,
              40.370725
            ],
            [
              -79.082965,
              40.389582
            ],
            [
              -79.058481,
              40.380868
            ],
            [
              -79.027714,
              40.406136
            ],
            [
              -79.033007,
              40.416774
            ],
            [
              -79.016617,
              40.420279
            ],
            [
              -78.98887,
              40.411974
            ],
            [
              -78.974649,
              40.395972
            ],
            [
              -78.924248,
              40.48107
            ],
            [
              -78.860105,
              40.604665
            ],
            [
              -78.846656,
              40.635638
            ],
            [
              -78.834641,
              40.661678
            ],
            [
              -78.80811,
              40.72064
            ],
            [
              -78.79936,
              40.717273
            ],
            [
              -78.801697,
              40.724539
            ],
            [
              -78.806274,
              40.729742
            ],
            [
              -78.805167,
              40.90598
            ],
            [
              -79.21531,
              40.911346
            ],
            [
              -79.215235,
              40.776016
            ],
            [
              -79.337576,
              40.646696
            ],
            [
              -79.358475,
              40.624531
            ],
            [
              -79.450176,
              40.530149
            ],
            [
              -79.4599,
              40.517986
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "077",
      "COUNTYNS": "01209182",
      "AFFGEOID": "0500000US42077",
      "GEOID": "42077",
      "NAME": "Lehigh",
      "LSAD": "06",
      "ALAND": 894010291,
      "AWATER": 7898528,
      "County_state": "Lehigh,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.854809,
              40.693192
            ],
            [
              -75.891473,
              40.67727
            ],
            [
              -75.739442,
              40.57994
            ],
            [
              -75.674921,
              40.53897
            ],
            [
              -75.641481,
              40.517655
            ],
            [
              -75.579274,
              40.478007
            ],
            [
              -75.571926,
              40.473348
            ],
            [
              -75.529694,
              40.446995
            ],
            [
              -75.490104,
              40.422192
            ],
            [
              -75.484057,
              40.418451
            ],
            [
              -75.409733,
              40.487984
            ],
            [
              -75.333514,
              40.537057
            ],
            [
              -75.403822,
              40.580787
            ],
            [
              -75.391275,
              40.596551
            ],
            [
              -75.390635,
              40.600677
            ],
            [
              -75.378125,
              40.615358
            ],
            [
              -75.387464,
              40.633122
            ],
            [
              -75.430122,
              40.668427
            ],
            [
              -75.430404,
              40.674152
            ],
            [
              -75.445286,
              40.66882
            ],
            [
              -75.466157,
              40.661305
            ],
            [
              -75.481469,
              40.655744
            ],
            [
              -75.49534,
              40.677787
            ],
            [
              -75.499179,
              40.68113
            ],
            [
              -75.515628,
              40.702196
            ],
            [
              -75.530236,
              40.736399
            ],
            [
              -75.558165,
              40.720886
            ],
            [
              -75.580444,
              40.735031
            ],
            [
              -75.591326,
              40.735055
            ],
            [
              -75.601926,
              40.750095
            ],
            [
              -75.608985,
              40.787387
            ],
            [
              -75.694749,
              40.770075
            ],
            [
              -75.69938,
              40.76612
            ],
            [
              -75.708515,
              40.760302
            ],
            [
              -75.757807,
              40.735414
            ],
            [
              -75.77769,
              40.729534
            ],
            [
              -75.807789,
              40.707492
            ],
            [
              -75.854809,
              40.693192
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "117",
      "COUNTYNS": "01209189",
      "AFFGEOID": "0500000US42117",
      "GEOID": "42117",
      "NAME": "Tioga",
      "LSAD": "06",
      "ALAND": 2936469292,
      "AWATER": 8257078,
      "County_state": "Tioga,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.6100277930756,
              41.99951862464489
            ],
            [
              -77.606887,
              41.88254
            ],
            [
              -77.605613,
              41.816074
            ],
            [
              -77.60346,
              41.727077
            ],
            [
              -77.602566,
              41.687374
            ],
            [
              -77.599278,
              41.542271
            ],
            [
              -76.960315,
              41.551379
            ],
            [
              -76.963117,
              41.556128
            ],
            [
              -76.943077,
              41.569323
            ],
            [
              -76.92657,
              41.571091
            ],
            [
              -76.922171,
              41.581037
            ],
            [
              -76.878331,
              41.590546
            ],
            [
              -76.874714,
              41.596919
            ],
            [
              -76.878699,
              41.620344
            ],
            [
              -76.898607,
              41.794465
            ],
            [
              -76.899315,
              41.809328
            ],
            [
              -76.927084,
              42.001674
            ],
            [
              -76.937084,
              42.001674
            ],
            [
              -76.942585,
              42.001574
            ],
            [
              -76.965686,
              42.001274
            ],
            [
              -76.96572797329421,
              42.0012735436595
            ],
            [
              -77.007536,
              42.000819
            ],
            [
              -77.007635,
              42.000848
            ],
            [
              -77.063676,
              42.000461
            ],
            [
              -77.124693,
              41.999395
            ],
            [
              -77.4565781532607,
              41.9999835802673
            ],
            [
              -77.505308,
              42.00007
            ],
            [
              -77.6100277930756,
              41.99951862464489
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "103",
      "COUNTYNS": "01213683",
      "AFFGEOID": "0500000US42103",
      "GEOID": "42103",
      "NAME": "Pike",
      "LSAD": "06",
      "ALAND": 1411441329,
      "AWATER": 56692145,
      "County_state": "Pike,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.341364,
              41.25445
            ],
            [
              -75.359184,
              41.239206
            ],
            [
              -75.307348,
              41.24223
            ],
            [
              -75.129721,
              41.252101
            ],
            [
              -75.13843,
              41.218635
            ],
            [
              -75.156507,
              41.150341
            ],
            [
              -75.076811,
              41.141122
            ],
            [
              -75.039216,
              41.088797
            ],
            [
              -75.027957,
              41.079868
            ],
            [
              -74.991718,
              41.092284
            ],
            [
              -74.982212,
              41.108245
            ],
            [
              -74.979873,
              41.110423
            ],
            [
              -74.972917,
              41.113327
            ],
            [
              -74.969312,
              41.113869
            ],
            [
              -74.966298,
              41.113669
            ],
            [
              -74.964294,
              41.114237
            ],
            [
              -74.947912,
              41.12356
            ],
            [
              -74.947334,
              41.124439
            ],
            [
              -74.947714,
              41.126292
            ],
            [
              -74.945067,
              41.129052
            ],
            [
              -74.931141,
              41.133387
            ],
            [
              -74.923169,
              41.138146
            ],
            [
              -74.905256,
              41.155668
            ],
            [
              -74.90178,
              41.161394
            ],
            [
              -74.901172,
              41.16387
            ],
            [
              -74.899701,
              41.166181
            ],
            [
              -74.889424,
              41.1736
            ],
            [
              -74.882139,
              41.180836
            ],
            [
              -74.878492,
              41.187504
            ],
            [
              -74.878275,
              41.190489
            ],
            [
              -74.874034,
              41.198543
            ],
            [
              -74.867287,
              41.208754
            ],
            [
              -74.860398,
              41.217454
            ],
            [
              -74.859632,
              41.219077
            ],
            [
              -74.859323,
              41.220507
            ],
            [
              -74.860837,
              41.222317
            ],
            [
              -74.866839,
              41.226865
            ],
            [
              -74.867405,
              41.22777
            ],
            [
              -74.866182,
              41.232132
            ],
            [
              -74.862049,
              41.237609
            ],
            [
              -74.861678,
              41.241575
            ],
            [
              -74.857151,
              41.248975
            ],
            [
              -74.856003,
              41.250094
            ],
            [
              -74.854669,
              41.25051
            ],
            [
              -74.848987,
              41.251192
            ],
            [
              -74.846932,
              41.253318
            ],
            [
              -74.845883,
              41.254945
            ],
            [
              -74.845031,
              41.258055
            ],
            [
              -74.846506,
              41.261576
            ],
            [
              -74.846319,
              41.263077
            ],
            [
              -74.841137,
              41.27098
            ],
            [
              -74.8387298902787,
              41.2764578902572
            ],
            [
              -74.838366,
              41.277286
            ],
            [
              -74.834067,
              41.281111
            ],
            [
              -74.830057,
              41.2872
            ],
            [
              -74.821884,
              41.293838
            ],
            [
              -74.815703,
              41.296151
            ],
            [
              -74.812033,
              41.298157
            ],
            [
              -74.806858,
              41.303155
            ],
            [
              -74.792558,
              41.310628
            ],
            [
              -74.791991,
              41.311639
            ],
            [
              -74.792377,
              41.314088
            ],
            [
              -74.795822,
              41.318516
            ],
            [
              -74.79504,
              41.320407
            ],
            [
              -74.792116,
              41.322465
            ],
            [
              -74.789095,
              41.323281
            ],
            [
              -74.781584,
              41.324229
            ],
            [
              -74.774887,
              41.324326
            ],
            [
              -74.771588,
              41.325079
            ],
            [
              -74.766714,
              41.328558
            ],
            [
              -74.763499,
              41.331568
            ],
            [
              -74.760325,
              41.340325
            ],
            [
              -74.755971,
              41.344953
            ],
            [
              -74.753239,
              41.346122
            ],
            [
              -74.735622,
              41.346518
            ],
            [
              -74.730373,
              41.345983
            ],
            [
              -74.720923,
              41.347384
            ],
            [
              -74.708514,
              41.352734
            ],
            [
              -74.704429,
              41.354043
            ],
            [
              -74.700595,
              41.354553
            ],
            [
              -74.694914,
              41.357423
            ],
            [
              -74.696398,
              41.357339
            ],
            [
              -74.691076,
              41.36034
            ],
            [
              -74.689767,
              41.361558
            ],
            [
              -74.689516,
              41.363843
            ],
            [
              -74.691129,
              41.367324
            ],
            [
              -74.694968,
              41.370431
            ],
            [
              -74.703282,
              41.375093
            ],
            [
              -74.708458,
              41.378901
            ],
            [
              -74.710391,
              41.382102
            ],
            [
              -74.713411,
              41.389814
            ],
            [
              -74.715979,
              41.392584
            ],
            [
              -74.720891,
              41.39469
            ],
            [
              -74.730384,
              41.39566
            ],
            [
              -74.73364,
              41.396975
            ],
            [
              -74.736103,
              41.398398
            ],
            [
              -74.738554,
              41.401191
            ],
            [
              -74.740963,
              41.40512
            ],
            [
              -74.741717,
              41.40788
            ],
            [
              -74.741086,
              41.411413
            ],
            [
              -74.738684,
              41.413463
            ],
            [
              -74.734731,
              41.422699
            ],
            [
              -74.734893,
              41.425818
            ],
            [
              -74.735519,
              41.427465
            ],
            [
              -74.736688,
              41.429228
            ],
            [
              -74.738455,
              41.430641
            ],
            [
              -74.740932,
              41.43116
            ],
            [
              -74.743821,
              41.430635
            ],
            [
              -74.75068,
              41.427984
            ],
            [
              -74.754359,
              41.425147
            ],
            [
              -74.7547086478625,
              41.4249931814039
            ],
            [
              -74.758587,
              41.423287
            ],
            [
              -74.763701,
              41.423612
            ],
            [
              -74.77065,
              41.42623
            ],
            [
              -74.773239,
              41.426352
            ],
            [
              -74.778029,
              41.425104
            ],
            [
              -74.784339,
              41.422397
            ],
            [
              -74.790417,
              41.42166
            ],
            [
              -74.793856,
              41.422671
            ],
            [
              -74.795396,
              41.42398
            ],
            [
              -74.799546,
              41.43129
            ],
            [
              -74.800095,
              41.432661
            ],
            [
              -74.80037,
              41.43606
            ],
            [
              -74.801225,
              41.4381
            ],
            [
              -74.805655,
              41.442101
            ],
            [
              -74.807582,
              41.442847
            ],
            [
              -74.812123,
              41.442982
            ],
            [
              -74.817995,
              41.440505
            ],
            [
              -74.82288,
              41.436792
            ],
            [
              -74.826031,
              41.431736
            ],
            [
              -74.828592,
              41.430698
            ],
            [
              -74.830671,
              41.430503
            ],
            [
              -74.834635,
              41.430796
            ],
            [
              -74.836915,
              41.431625
            ],
            [
              -74.845572,
              41.437577
            ],
            [
              -74.848602,
              41.440179
            ],
            [
              -74.8542,
              41.443166
            ],
            [
              -74.858578,
              41.444427
            ],
            [
              -74.864688,
              41.443993
            ],
            [
              -74.876721,
              41.440338
            ],
            [
              -74.888691,
              41.438259
            ],
            [
              -74.893913,
              41.43893
            ],
            [
              -74.896025,
              41.439987
            ],
            [
              -74.896399,
              41.442179
            ],
            [
              -74.894931,
              41.446099
            ],
            [
              -74.889075,
              41.451245
            ],
            [
              -74.889116,
              41.452534
            ],
            [
              -74.890358,
              41.455324
            ],
            [
              -74.892114,
              41.456959
            ],
            [
              -74.895069,
              41.45819
            ],
            [
              -74.9042,
              41.459806
            ],
            [
              -74.906887,
              41.461131
            ],
            [
              -74.908103,
              41.464639
            ],
            [
              -74.908133,
              41.468117
            ],
            [
              -74.909181,
              41.472436
            ],
            [
              -74.912517,
              41.475605
            ],
            [
              -74.917282,
              41.477041
            ],
            [
              -74.924092,
              41.477138
            ],
            [
              -74.926835,
              41.478327
            ],
            [
              -74.932585,
              41.482323
            ],
            [
              -74.941798,
              41.483542
            ],
            [
              -74.945634,
              41.483213
            ],
            [
              -74.94808,
              41.480625
            ],
            [
              -74.956411,
              41.476735
            ],
            [
              -74.95826,
              41.476396
            ],
            [
              -74.969887,
              41.477438
            ],
            [
              -74.981652,
              41.479945
            ],
            [
              -74.983341,
              41.480894
            ],
            [
              -74.985004,
              41.483703
            ],
            [
              -74.985595,
              41.485863
            ],
            [
              -74.985247,
              41.489113
            ],
            [
              -74.982463,
              41.496467
            ],
            [
              -74.982168,
              41.498486
            ],
            [
              -74.982385,
              41.500981
            ],
            [
              -74.984372,
              41.506611
            ],
            [
              -74.985653,
              41.507926
            ],
            [
              -74.987645,
              41.508738
            ],
            [
              -74.993893,
              41.508754
            ],
            [
              -74.999612,
              41.5074
            ],
            [
              -75.003151,
              41.508101
            ],
            [
              -75.003694,
              41.509295
            ],
            [
              -75.003706,
              41.511118
            ],
            [
              -75.002592,
              41.51456
            ],
            [
              -75.000935,
              41.517638
            ],
            [
              -75.000911,
              41.519292
            ],
            [
              -75.001297,
              41.52065
            ],
            [
              -75.00385,
              41.524052
            ],
            [
              -75.009552,
              41.528461
            ],
            [
              -75.014919,
              41.531399
            ],
            [
              -75.016616,
              41.53211
            ],
            [
              -75.023018,
              41.533147
            ],
            [
              -75.024206,
              41.534018
            ],
            [
              -75.024757,
              41.535099
            ],
            [
              -75.024798,
              41.539801
            ],
            [
              -75.022828,
              41.541456
            ],
            [
              -75.017626,
              41.542734
            ],
            [
              -75.016144,
              41.544246
            ],
            [
              -75.016328,
              41.546501
            ],
            [
              -75.018524,
              41.551802
            ],
            [
              -75.027343,
              41.563541
            ],
            [
              -75.029211,
              41.564637
            ],
            [
              -75.033162,
              41.565092
            ],
            [
              -75.036989,
              41.567049
            ],
            [
              -75.04049,
              41.569688
            ],
            [
              -75.043879,
              41.575094
            ],
            [
              -75.04676,
              41.583258
            ],
            [
              -75.052858,
              41.587772
            ],
            [
              -75.060012,
              41.590813
            ],
            [
              -75.063677,
              41.594739
            ],
            [
              -75.066955,
              41.599428
            ],
            [
              -75.0697121394208,
              41.6016900928416
            ],
            [
              -75.168943,
              41.475101
            ],
            [
              -75.187635,
              41.458244
            ],
            [
              -75.186236,
              41.442836
            ],
            [
              -75.218315,
              41.424117
            ],
            [
              -75.242346,
              41.421311
            ],
            [
              -75.251521,
              41.412374
            ],
            [
              -75.255073,
              41.386986
            ],
            [
              -75.265712,
              41.372382
            ],
            [
              -75.298148,
              41.367285
            ],
            [
              -75.309067,
              41.374679
            ],
            [
              -75.323657,
              41.366045
            ],
            [
              -75.336213,
              41.376146
            ],
            [
              -75.350648,
              41.367694
            ],
            [
              -75.336627,
              41.34513
            ],
            [
              -75.311143,
              41.331439
            ],
            [
              -75.313563,
              41.312697
            ],
            [
              -75.334603,
              41.26972
            ],
            [
              -75.347718,
              41.265308
            ],
            [
              -75.341364,
              41.25445
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "087",
      "COUNTYNS": "01213679",
      "AFFGEOID": "0500000US42087",
      "GEOID": "42087",
      "NAME": "Mifflin",
      "LSAD": "06",
      "ALAND": 1064602238,
      "AWATER": 9507398,
      "County_state": "Mifflin,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.913844,
              40.398679
            ],
            [
              -77.887717,
              40.391358
            ],
            [
              -77.856914,
              40.365228
            ],
            [
              -77.838343,
              40.36903
            ],
            [
              -77.818636,
              40.360542
            ],
            [
              -77.810393,
              40.368591
            ],
            [
              -77.76172,
              40.368733
            ],
            [
              -77.75212,
              40.378545
            ],
            [
              -77.746766,
              40.384661
            ],
            [
              -77.721861,
              40.408542
            ],
            [
              -77.645997,
              40.468381
            ],
            [
              -77.612459,
              40.490919
            ],
            [
              -77.612509,
              40.514187
            ],
            [
              -77.566648,
              40.541805
            ],
            [
              -77.532456,
              40.559476
            ],
            [
              -77.497506,
              40.58174
            ],
            [
              -77.49776,
              40.609484
            ],
            [
              -77.468999,
              40.624746
            ],
            [
              -77.368273,
              40.667593
            ],
            [
              -77.287941,
              40.693595
            ],
            [
              -77.354097,
              40.701667
            ],
            [
              -77.356628,
              40.807334
            ],
            [
              -77.357113,
              40.844484
            ],
            [
              -77.36418,
              40.846937
            ],
            [
              -77.370175,
              40.846291
            ],
            [
              -77.438467,
              40.824027
            ],
            [
              -77.593355,
              40.761247
            ],
            [
              -77.652574,
              40.744921
            ],
            [
              -77.681334,
              40.729758
            ],
            [
              -77.676611,
              40.716452
            ],
            [
              -77.70117,
              40.704982
            ],
            [
              -77.703787,
              40.679936
            ],
            [
              -77.682708,
              40.682717
            ],
            [
              -77.75162,
              40.6323
            ],
            [
              -77.787734,
              40.612082
            ],
            [
              -77.8216,
              40.578931
            ],
            [
              -77.841827,
              40.550035
            ],
            [
              -77.825866,
              40.492816
            ],
            [
              -77.913844,
              40.398679
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "107",
      "COUNTYNS": "01213685",
      "AFFGEOID": "0500000US42107",
      "GEOID": "42107",
      "NAME": "Schuylkill",
      "LSAD": "06",
      "ALAND": 2016645900,
      "AWATER": 10834897,
      "County_state": "Schuylkill,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.700722,
              40.663247
            ],
            [
              -76.701624,
              40.658082
            ],
            [
              -76.577073,
              40.580677
            ],
            [
              -76.535414,
              40.555157
            ],
            [
              -76.440181,
              40.495952
            ],
            [
              -76.4186,
              40.495615
            ],
            [
              -76.369222,
              40.509597
            ],
            [
              -76.339109,
              40.515351
            ],
            [
              -76.256008,
              40.517067
            ],
            [
              -76.219407,
              40.528699
            ],
            [
              -76.171624,
              40.534947
            ],
            [
              -76.155096,
              40.545229
            ],
            [
              -76.105865,
              40.562198
            ],
            [
              -76.079566,
              40.566077
            ],
            [
              -76.065339,
              40.579678
            ],
            [
              -76.058024,
              40.579629
            ],
            [
              -76.018562,
              40.573826
            ],
            [
              -76.012226,
              40.577713
            ],
            [
              -75.993212,
              40.639712
            ],
            [
              -75.891473,
              40.67727
            ],
            [
              -75.854809,
              40.693192
            ],
            [
              -75.807789,
              40.707492
            ],
            [
              -75.77769,
              40.729534
            ],
            [
              -75.757807,
              40.735414
            ],
            [
              -75.858369,
              40.798753
            ],
            [
              -75.870839,
              40.806948
            ],
            [
              -75.886037,
              40.816267
            ],
            [
              -75.893655,
              40.822806
            ],
            [
              -75.926429,
              40.851726
            ],
            [
              -75.994358,
              40.910119
            ],
            [
              -75.997348,
              40.912985
            ],
            [
              -76.02815,
              40.901986
            ],
            [
              -76.104693,
              40.922693
            ],
            [
              -76.207827,
              40.94974
            ],
            [
              -76.284611,
              40.883588
            ],
            [
              -76.2991,
              40.831191
            ],
            [
              -76.30717,
              40.801809
            ],
            [
              -76.380152,
              40.775511
            ],
            [
              -76.469523,
              40.743188
            ],
            [
              -76.700722,
              40.663247
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "081",
      "COUNTYNS": "01213677",
      "AFFGEOID": "0500000US42081",
      "GEOID": "42081",
      "NAME": "Lycoming",
      "LSAD": "06",
      "ALAND": 3182098644,
      "AWATER": 39419361,
      "County_state": "Lycoming,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.599278,
              41.542271
            ],
            [
              -77.598129,
              41.478576
            ],
            [
              -77.597282,
              41.441056
            ],
            [
              -77.584671,
              41.441301
            ],
            [
              -77.57574,
              41.421602
            ],
            [
              -77.564435,
              41.412683
            ],
            [
              -77.541899,
              41.381296
            ],
            [
              -77.540008,
              41.368101
            ],
            [
              -77.526537,
              41.358528
            ],
            [
              -77.499193,
              41.353577
            ],
            [
              -77.475987,
              41.338294
            ],
            [
              -77.471862,
              41.314273
            ],
            [
              -77.456424,
              41.304639
            ],
            [
              -77.445158,
              41.287762
            ],
            [
              -77.4294,
              41.28214
            ],
            [
              -77.399043,
              41.253053
            ],
            [
              -77.37035,
              41.233079
            ],
            [
              -77.349627,
              41.228873
            ],
            [
              -77.349765,
              41.219918
            ],
            [
              -77.320891,
              41.218932
            ],
            [
              -77.29058,
              41.1951
            ],
            [
              -77.293059,
              41.186473
            ],
            [
              -77.272018,
              41.172534
            ],
            [
              -77.164278,
              41.068708
            ],
            [
              -77.144111,
              41.06884
            ],
            [
              -77.113839,
              41.069032
            ],
            [
              -77.058088,
              41.085575
            ],
            [
              -76.977939,
              41.087883
            ],
            [
              -76.960229,
              41.148801
            ],
            [
              -76.896114,
              41.13907
            ],
            [
              -76.889669,
              41.150791
            ],
            [
              -76.889338,
              41.151988
            ],
            [
              -76.888145,
              41.153807
            ],
            [
              -76.885228,
              41.155973
            ],
            [
              -76.884245,
              41.157099
            ],
            [
              -76.880963,
              41.158044
            ],
            [
              -76.788659,
              41.175762
            ],
            [
              -76.732672,
              41.17204
            ],
            [
              -76.678776,
              41.154172
            ],
            [
              -76.640767,
              41.155718
            ],
            [
              -76.592607,
              41.157765
            ],
            [
              -76.447597,
              41.275629
            ],
            [
              -76.548488,
              41.293135
            ],
            [
              -76.592532,
              41.304332
            ],
            [
              -76.749497,
              41.405572
            ],
            [
              -76.813731,
              41.590034
            ],
            [
              -76.874714,
              41.596919
            ],
            [
              -76.878331,
              41.590546
            ],
            [
              -76.922171,
              41.581037
            ],
            [
              -76.92657,
              41.571091
            ],
            [
              -76.943077,
              41.569323
            ],
            [
              -76.963117,
              41.556128
            ],
            [
              -76.960315,
              41.551379
            ],
            [
              -77.599278,
              41.542271
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "037",
      "COUNTYNS": "01213665",
      "AFFGEOID": "0500000US42037",
      "GEOID": "42037",
      "NAME": "Columbia",
      "LSAD": "06",
      "ALAND": 1251240776,
      "AWATER": 18266225,
      "County_state": "Columbia,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.640767,
              41.155718
            ],
            [
              -76.620849,
              41.089957
            ],
            [
              -76.618971,
              41.063759
            ],
            [
              -76.569915,
              41.053761
            ],
            [
              -76.562263,
              41.034214
            ],
            [
              -76.565233,
              40.999812
            ],
            [
              -76.514951,
              40.958959
            ],
            [
              -76.512421,
              40.946102
            ],
            [
              -76.529395,
              40.940143
            ],
            [
              -76.520138,
              40.926634
            ],
            [
              -76.528034,
              40.882515
            ],
            [
              -76.50578,
              40.882382
            ],
            [
              -76.49821,
              40.853823
            ],
            [
              -76.507916,
              40.846526
            ],
            [
              -76.501163,
              40.824737
            ],
            [
              -76.409667,
              40.833624
            ],
            [
              -76.380152,
              40.775511
            ],
            [
              -76.30717,
              40.801809
            ],
            [
              -76.2991,
              40.831191
            ],
            [
              -76.284611,
              40.883588
            ],
            [
              -76.207827,
              40.94974
            ],
            [
              -76.228481,
              41.052012
            ],
            [
              -76.228975,
              41.138466
            ],
            [
              -76.277639,
              41.131804
            ],
            [
              -76.317916,
              41.205384
            ],
            [
              -76.319957,
              41.211255
            ],
            [
              -76.310133,
              41.310199
            ],
            [
              -76.407934,
              41.308418
            ],
            [
              -76.447597,
              41.275629
            ],
            [
              -76.592607,
              41.157765
            ],
            [
              -76.640767,
              41.155718
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "089",
      "COUNTYNS": "01209184",
      "AFFGEOID": "0500000US42089",
      "GEOID": "42089",
      "NAME": "Monroe",
      "LSAD": "06",
      "ALAND": 1575385119,
      "AWATER": 22867226,
      "County_state": "Monroe,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.649637,
              41.122344
            ],
            [
              -75.645873,
              41.112949
            ],
            [
              -75.620397,
              41.109284
            ],
            [
              -75.612694,
              41.094237
            ],
            [
              -75.576484,
              41.067573
            ],
            [
              -75.561947,
              41.049523
            ],
            [
              -75.49599,
              40.987181
            ],
            [
              -75.57459,
              40.951323
            ],
            [
              -75.556438,
              40.928693
            ],
            [
              -75.487657,
              40.838035
            ],
            [
              -75.474193,
              40.814746
            ],
            [
              -75.443267,
              40.818276
            ],
            [
              -75.416759,
              40.826102
            ],
            [
              -75.378852,
              40.832527
            ],
            [
              -75.320931,
              40.855657
            ],
            [
              -75.29494,
              40.8622
            ],
            [
              -75.284039,
              40.86604
            ],
            [
              -75.244197,
              40.894203
            ],
            [
              -75.24113,
              40.909438
            ],
            [
              -75.21819,
              40.927875
            ],
            [
              -75.129232,
              40.95893
            ],
            [
              -75.12051811257778,
              40.968372921710795
            ],
            [
              -75.122603,
              40.970152
            ],
            [
              -75.129074,
              40.968976
            ],
            [
              -75.131364,
              40.969277
            ],
            [
              -75.13378,
              40.970973
            ],
            [
              -75.135526,
              40.973807
            ],
            [
              -75.135521,
              40.976865
            ],
            [
              -75.133086,
              40.980179
            ],
            [
              -75.132106,
              40.982566
            ],
            [
              -75.13153,
              40.984914
            ],
            [
              -75.131619,
              40.9889
            ],
            [
              -75.130575,
              40.991093
            ],
            [
              -75.127196,
              40.993954
            ],
            [
              -75.123423,
              40.996129
            ],
            [
              -75.110595,
              41.002174
            ],
            [
              -75.109114,
              41.004102
            ],
            [
              -75.100682,
              41.006716
            ],
            [
              -75.095556,
              41.008874
            ],
            [
              -75.090312,
              41.013302
            ],
            [
              -75.089787,
              41.014549
            ],
            [
              -75.081101,
              41.016838
            ],
            [
              -75.074999,
              41.01713
            ],
            [
              -75.070532,
              41.01862
            ],
            [
              -75.040668,
              41.031755
            ],
            [
              -75.034496,
              41.036755
            ],
            [
              -75.030701,
              41.038416
            ],
            [
              -75.025777,
              41.039806
            ],
            [
              -75.02543,
              41.04071
            ],
            [
              -75.026376,
              41.04444
            ],
            [
              -75.025702,
              41.046482
            ],
            [
              -75.019186,
              41.052968
            ],
            [
              -75.017239,
              41.055491
            ],
            [
              -75.015867,
              41.05821
            ],
            [
              -75.015271,
              41.061215
            ],
            [
              -75.01257,
              41.066281
            ],
            [
              -75.011133,
              41.067521
            ],
            [
              -75.006376,
              41.067546
            ],
            [
              -74.999617,
              41.073943
            ],
            [
              -74.994847,
              41.076556
            ],
            [
              -74.989332,
              41.078319
            ],
            [
              -74.98259,
              41.079172
            ],
            [
              -74.970987,
              41.085293
            ],
            [
              -74.968389,
              41.087797
            ],
            [
              -74.966759,
              41.093425
            ],
            [
              -74.9670977569147,
              41.094338922910204
            ],
            [
              -74.967464,
              41.095327
            ],
            [
              -74.969434,
              41.096074
            ],
            [
              -74.972036,
              41.095562
            ],
            [
              -74.975298,
              41.094073
            ],
            [
              -74.981314,
              41.08986
            ],
            [
              -74.984782,
              41.088545
            ],
            [
              -74.988263,
              41.088222
            ],
            [
              -74.991013,
              41.088578
            ],
            [
              -74.991815,
              41.089132
            ],
            [
              -74.991718,
              41.092284
            ],
            [
              -75.027957,
              41.079868
            ],
            [
              -75.039216,
              41.088797
            ],
            [
              -75.076811,
              41.141122
            ],
            [
              -75.156507,
              41.150341
            ],
            [
              -75.13843,
              41.218635
            ],
            [
              -75.129721,
              41.252101
            ],
            [
              -75.307348,
              41.24223
            ],
            [
              -75.359184,
              41.239206
            ],
            [
              -75.366585,
              41.238281
            ],
            [
              -75.505607,
              41.232539
            ],
            [
              -75.517466,
              41.219259
            ],
            [
              -75.544063,
              41.223328
            ],
            [
              -75.558862,
              41.206857
            ],
            [
              -75.576653,
              41.177857
            ],
            [
              -75.598536,
              41.171935
            ],
            [
              -75.598634,
              41.163062
            ],
            [
              -75.600736,
              41.161497
            ],
            [
              -75.634978,
              41.123192
            ],
            [
              -75.649637,
              41.122344
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "119",
      "COUNTYNS": "01213689",
      "AFFGEOID": "0500000US42119",
      "GEOID": "42119",
      "NAME": "Union",
      "LSAD": "06",
      "ALAND": 818323459,
      "AWATER": 4639329,
      "County_state": "Union,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.36418,
              40.846937
            ],
            [
              -77.357113,
              40.844484
            ],
            [
              -77.356628,
              40.807334
            ],
            [
              -77.321639,
              40.817102
            ],
            [
              -77.129574,
              40.853794
            ],
            [
              -77.074058,
              40.868333
            ],
            [
              -77.052027,
              40.869387
            ],
            [
              -77.024901,
              40.857489
            ],
            [
              -77.011309,
              40.868768
            ],
            [
              -76.941788,
              40.88806
            ],
            [
              -76.908002,
              40.884843
            ],
            [
              -76.825555,
              40.887995
            ],
            [
              -76.799601,
              40.87857
            ],
            [
              -76.800242,
              40.881994
            ],
            [
              -76.823884,
              40.890146
            ],
            [
              -76.851456,
              40.912834
            ],
            [
              -76.882249,
              40.968763
            ],
            [
              -76.868842,
              40.994432
            ],
            [
              -76.864544,
              41.019795
            ],
            [
              -76.857878,
              41.072056
            ],
            [
              -76.889685,
              41.101313
            ],
            [
              -76.896114,
              41.13907
            ],
            [
              -76.960229,
              41.148801
            ],
            [
              -76.977939,
              41.087883
            ],
            [
              -77.058088,
              41.085575
            ],
            [
              -77.113839,
              41.069032
            ],
            [
              -77.144111,
              41.06884
            ],
            [
              -77.14416,
              41.044338
            ],
            [
              -77.204027,
              40.99271
            ],
            [
              -77.279236,
              40.90971
            ],
            [
              -77.36418,
              40.846937
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "039",
      "COUNTYNS": "01213666",
      "AFFGEOID": "0500000US42039",
      "GEOID": "42039",
      "NAME": "Crawford",
      "LSAD": "06",
      "ALAND": 2621846809,
      "AWATER": 65303124,
      "County_state": "Crawford,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.519373,
              41.701473
            ],
            [
              -80.519424,
              41.671228
            ],
            [
              -80.519357,
              41.669767
            ],
            [
              -80.5193534844336,
              41.644285002830394
            ],
            [
              -80.519339,
              41.539297
            ],
            [
              -80.519157,
              41.528769
            ],
            [
              -80.519225,
              41.499924
            ],
            [
              -80.51920863754069,
              41.4890128693366
            ],
            [
              -80.445001,
              41.487118
            ],
            [
              -80.444896,
              41.492269
            ],
            [
              -80.431147,
              41.486958
            ],
            [
              -80.265144,
              41.48345
            ],
            [
              -80.125316,
              41.480395
            ],
            [
              -80.041351,
              41.488712
            ],
            [
              -79.999779,
              41.490049
            ],
            [
              -79.981452,
              41.5129
            ],
            [
              -79.958384,
              41.513507
            ],
            [
              -79.943579,
              41.537454
            ],
            [
              -79.92348,
              41.537555
            ],
            [
              -79.892988,
              41.574157
            ],
            [
              -79.877171,
              41.574242
            ],
            [
              -79.829576,
              41.602057
            ],
            [
              -79.828876,
              41.622057
            ],
            [
              -79.768357,
              41.617711
            ],
            [
              -79.688082,
              41.617363
            ],
            [
              -79.644117,
              41.617206
            ],
            [
              -79.612866,
              41.617058
            ],
            [
              -79.612873,
              41.623858
            ],
            [
              -79.612072,
              41.850056
            ],
            [
              -79.680353,
              41.851524
            ],
            [
              -79.909977,
              41.850693
            ],
            [
              -80.035233,
              41.85011
            ],
            [
              -80.206545,
              41.849045
            ],
            [
              -80.350756,
              41.849507
            ],
            [
              -80.405892,
              41.849619
            ],
            [
              -80.519293557418,
              41.849563030893194
            ],
            [
              -80.519239,
              41.765138
            ],
            [
              -80.519369,
              41.752487
            ],
            [
              -80.519408,
              41.739359
            ],
            [
              -80.519373,
              41.701473
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "127",
      "COUNTYNS": "01213692",
      "AFFGEOID": "0500000US42127",
      "GEOID": "42127",
      "NAME": "Wayne",
      "LSAD": "06",
      "ALAND": 1879249061,
      "AWATER": 64832669,
      "County_state": "Wayne,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.496057,
              41.242948
            ],
            [
              -75.505607,
              41.232539
            ],
            [
              -75.366585,
              41.238281
            ],
            [
              -75.359184,
              41.239206
            ],
            [
              -75.341364,
              41.25445
            ],
            [
              -75.347718,
              41.265308
            ],
            [
              -75.334603,
              41.26972
            ],
            [
              -75.313563,
              41.312697
            ],
            [
              -75.311143,
              41.331439
            ],
            [
              -75.336627,
              41.34513
            ],
            [
              -75.350648,
              41.367694
            ],
            [
              -75.336213,
              41.376146
            ],
            [
              -75.323657,
              41.366045
            ],
            [
              -75.309067,
              41.374679
            ],
            [
              -75.298148,
              41.367285
            ],
            [
              -75.265712,
              41.372382
            ],
            [
              -75.255073,
              41.386986
            ],
            [
              -75.251521,
              41.412374
            ],
            [
              -75.242346,
              41.421311
            ],
            [
              -75.218315,
              41.424117
            ],
            [
              -75.186236,
              41.442836
            ],
            [
              -75.187635,
              41.458244
            ],
            [
              -75.168943,
              41.475101
            ],
            [
              -75.0697121394208,
              41.6016900928416
            ],
            [
              -75.074613,
              41.605711
            ],
            [
              -75.074626,
              41.607905
            ],
            [
              -75.071667,
              41.609501
            ],
            [
              -75.067795,
              41.610143
            ],
            [
              -75.062716,
              41.609639
            ],
            [
              -75.059725,
              41.610801
            ],
            [
              -75.059956,
              41.612306
            ],
            [
              -75.061675,
              41.615468
            ],
            [
              -75.06156,
              41.616429
            ],
            [
              -75.060098,
              41.617482
            ],
            [
              -75.05385,
              41.618655
            ],
            [
              -75.051856,
              41.618157
            ],
            [
              -75.048385,
              41.615986
            ],
            [
              -75.047298,
              41.615791
            ],
            [
              -75.045508,
              41.616203
            ],
            [
              -75.044224,
              41.617978
            ],
            [
              -75.043562,
              41.62364
            ],
            [
              -75.048199,
              41.632011
            ],
            [
              -75.048658,
              41.633781
            ],
            [
              -75.049281,
              41.641862
            ],
            [
              -75.048683,
              41.656317
            ],
            [
              -75.04992,
              41.662556
            ],
            [
              -75.053991,
              41.668194
            ],
            [
              -75.057251,
              41.668933
            ],
            [
              -75.05843,
              41.669653
            ],
            [
              -75.059332,
              41.67232
            ],
            [
              -75.058765,
              41.674412
            ],
            [
              -75.052653,
              41.678436
            ],
            [
              -75.051285,
              41.679961
            ],
            [
              -75.051234,
              41.682439
            ],
            [
              -75.052736,
              41.688393
            ],
            [
              -75.056745,
              41.695703
            ],
            [
              -75.059829,
              41.699716
            ],
            [
              -75.067278,
              41.705434
            ],
            [
              -75.06883,
              41.708161
            ],
            [
              -75.068642,
              41.710146
            ],
            [
              -75.06663,
              41.712588
            ],
            [
              -75.061174,
              41.712935
            ],
            [
              -75.052226,
              41.711396
            ],
            [
              -75.050689,
              41.711969
            ],
            [
              -75.049862,
              41.713309
            ],
            [
              -75.049699,
              41.715093
            ],
            [
              -75.053527,
              41.72715
            ],
            [
              -75.054818,
              41.735168
            ],
            [
              -75.052808,
              41.744725
            ],
            [
              -75.053431,
              41.752538
            ],
            [
              -75.060759,
              41.764638
            ],
            [
              -75.064901,
              41.766686
            ],
            [
              -75.068567,
              41.767298
            ],
            [
              -75.072664,
              41.768807
            ],
            [
              -75.074231,
              41.770518
            ],
            [
              -75.075942,
              41.771518
            ],
            [
              -75.079478,
              41.771205
            ],
            [
              -75.09281,
              41.768361
            ],
            [
              -75.095451,
              41.768366
            ],
            [
              -75.10099,
              41.769121
            ],
            [
              -75.103492,
              41.771238
            ],
            [
              -75.104334,
              41.772693
            ],
            [
              -75.10464,
              41.774203
            ],
            [
              -75.1039936438857,
              41.7788227888939
            ],
            [
              -75.103548,
              41.782008
            ],
            [
              -75.102329,
              41.786503
            ],
            [
              -75.101463,
              41.787941
            ],
            [
              -75.092876,
              41.796386
            ],
            [
              -75.088328,
              41.797534
            ],
            [
              -75.081415,
              41.796483
            ],
            [
              -75.07827,
              41.797467
            ],
            [
              -75.076889,
              41.798509
            ],
            [
              -75.074412,
              41.802191
            ],
            [
              -75.072168,
              41.808327
            ],
            [
              -75.071751,
              41.811901
            ],
            [
              -75.072172,
              41.813732
            ],
            [
              -75.074409,
              41.815088
            ],
            [
              -75.078063,
              41.815112
            ],
            [
              -75.079818,
              41.814815
            ],
            [
              -75.085789,
              41.811626
            ],
            [
              -75.089484,
              41.811576
            ],
            [
              -75.093537,
              41.813375
            ],
            [
              -75.100024,
              41.818347
            ],
            [
              -75.113334,
              41.822782
            ],
            [
              -75.114837,
              41.82567
            ],
            [
              -75.115147,
              41.827285
            ],
            [
              -75.114998,
              41.8303
            ],
            [
              -75.113441,
              41.836298
            ],
            [
              -75.113369,
              41.840698
            ],
            [
              -75.114399,
              41.843583
            ],
            [
              -75.115598,
              41.844638
            ],
            [
              -75.118789,
              41.845819
            ],
            [
              -75.127913,
              41.844903
            ],
            [
              -75.130983,
              41.845145
            ],
            [
              -75.140241,
              41.852078
            ],
            [
              -75.143824,
              41.851737
            ],
            [
              -75.146446,
              41.850899
            ],
            [
              -75.152898,
              41.848564
            ],
            [
              -75.156512,
              41.848327
            ],
            [
              -75.161541,
              41.849836
            ],
            [
              -75.164168,
              41.851586
            ],
            [
              -75.166217,
              41.853862
            ],
            [
              -75.168733,
              41.859258
            ],
            [
              -75.168053,
              41.867043
            ],
            [
              -75.169142,
              41.87029
            ],
            [
              -75.170565,
              41.871608
            ],
            [
              -75.174574,
              41.87266
            ],
            [
              -75.176633,
              41.872371
            ],
            [
              -75.179134,
              41.869935
            ],
            [
              -75.180497,
              41.86568
            ],
            [
              -75.182271,
              41.862198
            ],
            [
              -75.183937,
              41.860515
            ],
            [
              -75.185254,
              41.85993
            ],
            [
              -75.186993,
              41.860109
            ],
            [
              -75.188888,
              41.861264
            ],
            [
              -75.190203,
              41.862454
            ],
            [
              -75.191441,
              41.865063
            ],
            [
              -75.194382,
              41.867287
            ],
            [
              -75.197836,
              41.868807
            ],
            [
              -75.204002,
              41.869867
            ],
            [
              -75.209741,
              41.86925
            ],
            [
              -75.21497,
              41.867449
            ],
            [
              -75.220125,
              41.860534
            ],
            [
              -75.223734,
              41.857456
            ],
            [
              -75.22572,
              41.857481
            ],
            [
              -75.231612,
              41.859459
            ],
            [
              -75.234565,
              41.861569
            ],
            [
              -75.238743,
              41.865699
            ],
            [
              -75.241134,
              41.867118
            ],
            [
              -75.243345,
              41.866875
            ],
            [
              -75.248045,
              41.8633
            ],
            [
              -75.251197,
              41.86204
            ],
            [
              -75.257825,
              41.862154
            ],
            [
              -75.260527,
              41.8638
            ],
            [
              -75.262802,
              41.866213
            ],
            [
              -75.263673,
              41.868105
            ],
            [
              -75.263815,
              41.870757
            ],
            [
              -75.261488,
              41.873277
            ],
            [
              -75.258439,
              41.875087
            ],
            [
              -75.257564,
              41.877108
            ],
            [
              -75.260623,
              41.883783
            ],
            [
              -75.263005,
              41.885109
            ],
            [
              -75.267789,
              41.885982
            ],
            [
              -75.271292,
              41.88736
            ],
            [
              -75.272581,
              41.893168
            ],
            [
              -75.272778,
              41.897112
            ],
            [
              -75.267773,
              41.901971
            ],
            [
              -75.267562,
              41.907054
            ],
            [
              -75.269736,
              41.911363
            ],
            [
              -75.275368,
              41.919564
            ],
            [
              -75.276552,
              41.922208
            ],
            [
              -75.276501,
              41.926679
            ],
            [
              -75.277243,
              41.933598
            ],
            [
              -75.279094,
              41.938917
            ],
            [
              -75.289383,
              41.942891
            ],
            [
              -75.290966,
              41.945039
            ],
            [
              -75.291762,
              41.947092
            ],
            [
              -75.29143,
              41.952477
            ],
            [
              -75.293713,
              41.954593
            ],
            [
              -75.29858,
              41.954521
            ],
            [
              -75.300409,
              41.953871
            ],
            [
              -75.301593,
              41.952811
            ],
            [
              -75.301233,
              41.9489
            ],
            [
              -75.301664,
              41.94838
            ],
            [
              -75.303966,
              41.948216
            ],
            [
              -75.310358,
              41.949012
            ],
            [
              -75.312817,
              41.950182
            ],
            [
              -75.318168,
              41.954236
            ],
            [
              -75.32004,
              41.960867
            ],
            [
              -75.322384,
              41.961693
            ],
            [
              -75.329318,
              41.968232
            ],
            [
              -75.335771,
              41.970315
            ],
            [
              -75.339488,
              41.970786
            ],
            [
              -75.342204,
              41.972872
            ],
            [
              -75.34246,
              41.974303
            ],
            [
              -75.337791,
              41.984386
            ],
            [
              -75.337602,
              41.9867
            ],
            [
              -75.341125,
              41.992772
            ],
            [
              -75.346568,
              41.995324
            ],
            [
              -75.353504,
              41.99711
            ],
            [
              -75.359579,
              41.999445
            ],
            [
              -75.4022261958532,
              41.9993966859156
            ],
            [
              -75.431961,
              41.999363
            ],
            [
              -75.436216,
              41.999353
            ],
            [
              -75.477144,
              41.999407
            ],
            [
              -75.4831501008195,
              41.9992585325396
            ],
            [
              -75.462411,
              41.641587
            ],
            [
              -75.456383,
              41.533638
            ],
            [
              -75.450037,
              41.425611
            ],
            [
              -75.441421,
              41.260053
            ],
            [
              -75.440561,
              41.258552
            ],
            [
              -75.470849,
              41.243267
            ],
            [
              -75.496057,
              41.242948
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "115",
      "COUNTYNS": "01213688",
      "AFFGEOID": "0500000US42115",
      "GEOID": "42115",
      "NAME": "Susquehanna",
      "LSAD": "06",
      "ALAND": 2132850304,
      "AWATER": 22385627,
      "County_state": "Susquehanna,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.131201,
              41.998954
            ],
            [
              -76.1455192032297,
              41.9989130371419
            ],
            [
              -76.122003,
              41.73855
            ],
            [
              -76.115172,
              41.651825
            ],
            [
              -76.114224,
              41.643385
            ],
            [
              -76.087759,
              41.644724
            ],
            [
              -75.782461,
              41.642848
            ],
            [
              -75.767924,
              41.643409
            ],
            [
              -75.719887,
              41.642263
            ],
            [
              -75.652187,
              41.642196
            ],
            [
              -75.469259,
              41.64141
            ],
            [
              -75.462411,
              41.641587
            ],
            [
              -75.4831501008195,
              41.9992585325396
            ],
            [
              -75.483738,
              41.999244
            ],
            [
              -75.55307597628,
              41.9990884280581
            ],
            [
              -75.610316,
              41.99896
            ],
            [
              -75.742217,
              41.997864
            ],
            [
              -75.7733433652803,
              41.9980975810068
            ],
            [
              -75.870677,
              41.998828
            ],
            [
              -75.9584074252567,
              41.9989937360666
            ],
            [
              -75.98025,
              41.999035
            ],
            [
              -75.983082,
              41.999035
            ],
            [
              -76.0600871153046,
              41.9989239693103
            ],
            [
              -76.10584,
              41.998858
            ],
            [
              -76.123696,
              41.998954
            ],
            [
              -76.131201,
              41.998954
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "105",
      "COUNTYNS": "01213684",
      "AFFGEOID": "0500000US42105",
      "GEOID": "42105",
      "NAME": "Potter",
      "LSAD": "06",
      "ALAND": 2800612691,
      "AWATER": 559779,
      "County_state": "Potter,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.2066042785298,
              41.999622491491095
            ],
            [
              -78.203422,
              41.618157
            ],
            [
              -78.050442,
              41.475462
            ],
            [
              -77.989194,
              41.474822
            ],
            [
              -77.598129,
              41.478576
            ],
            [
              -77.599278,
              41.542271
            ],
            [
              -77.602566,
              41.687374
            ],
            [
              -77.60346,
              41.727077
            ],
            [
              -77.605613,
              41.816074
            ],
            [
              -77.606887,
              41.88254
            ],
            [
              -77.6100277930756,
              41.99951862464489
            ],
            [
              -77.749931,
              41.998782
            ],
            [
              -77.822799,
              41.998547
            ],
            [
              -77.83203,
              41.998524
            ],
            [
              -77.997508,
              41.998758
            ],
            [
              -78.030963,
              41.999392
            ],
            [
              -78.031177,
              41.999415
            ],
            [
              -78.12473,
              42.000452
            ],
            [
              -78.2066042785298,
              41.999622491491095
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "093",
      "COUNTYNS": "01213681",
      "AFFGEOID": "0500000US42093",
      "GEOID": "42093",
      "NAME": "Montour",
      "LSAD": "06",
      "ALAND": 337326240,
      "AWATER": 5407948,
      "County_state": "Montour,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.775786,
              41.008118
            ],
            [
              -76.792878,
              40.946583
            ],
            [
              -76.734826,
              40.954448
            ],
            [
              -76.661348,
              40.967996
            ],
            [
              -76.644446,
              40.96453
            ],
            [
              -76.62508,
              40.960309
            ],
            [
              -76.601436,
              40.942186
            ],
            [
              -76.557013,
              40.938617
            ],
            [
              -76.568111,
              40.915454
            ],
            [
              -76.551792,
              40.883406
            ],
            [
              -76.541786,
              40.882551
            ],
            [
              -76.528034,
              40.882515
            ],
            [
              -76.520138,
              40.926634
            ],
            [
              -76.529395,
              40.940143
            ],
            [
              -76.512421,
              40.946102
            ],
            [
              -76.514951,
              40.958959
            ],
            [
              -76.565233,
              40.999812
            ],
            [
              -76.562263,
              41.034214
            ],
            [
              -76.569915,
              41.053761
            ],
            [
              -76.618971,
              41.063759
            ],
            [
              -76.620849,
              41.089957
            ],
            [
              -76.640767,
              41.155718
            ],
            [
              -76.678776,
              41.154172
            ],
            [
              -76.732672,
              41.17204
            ],
            [
              -76.734891,
              41.092133
            ],
            [
              -76.775786,
              41.008118
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "041",
      "COUNTYNS": "01209176",
      "AFFGEOID": "0500000US42041",
      "GEOID": "42041",
      "NAME": "Cumberland",
      "LSAD": "06",
      "ALAND": 1412834149,
      "AWATER": 12521844,
      "County_state": "Cumberland,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.614665,
              40.198549
            ],
            [
              -77.627509,
              40.1886
            ],
            [
              -77.608261,
              40.152738
            ],
            [
              -77.562871,
              40.119286
            ],
            [
              -77.559728,
              40.100671
            ],
            [
              -77.534889,
              40.070272
            ],
            [
              -77.532481,
              40.048749
            ],
            [
              -77.519756,
              40.040468
            ],
            [
              -77.495403,
              40.005145
            ],
            [
              -77.454109,
              39.972432
            ],
            [
              -77.471085,
              39.944077
            ],
            [
              -77.421362,
              39.981294
            ],
            [
              -77.403606,
              39.994478
            ],
            [
              -77.35373,
              40.003302
            ],
            [
              -77.255209,
              40.02076
            ],
            [
              -77.18563,
              40.029501
            ],
            [
              -77.178108,
              40.036755
            ],
            [
              -77.162071,
              40.051697
            ],
            [
              -77.137425,
              40.069945
            ],
            [
              -77.080387,
              40.110985
            ],
            [
              -77.028853,
              40.147838
            ],
            [
              -77.012601,
              40.148421
            ],
            [
              -76.971226,
              40.157022
            ],
            [
              -76.978024,
              40.164777
            ],
            [
              -76.929107,
              40.161274
            ],
            [
              -76.905875,
              40.165723
            ],
            [
              -76.923294,
              40.177087
            ],
            [
              -76.908555,
              40.190729
            ],
            [
              -76.929481,
              40.196853
            ],
            [
              -76.921265,
              40.209207
            ],
            [
              -76.912249,
              40.204506
            ],
            [
              -76.896961,
              40.224666
            ],
            [
              -76.891801,
              40.215895
            ],
            [
              -76.867015,
              40.216002
            ],
            [
              -76.859023,
              40.226328
            ],
            [
              -76.873248,
              40.237681
            ],
            [
              -76.884916,
              40.247265
            ],
            [
              -76.898186,
              40.255
            ],
            [
              -76.913911,
              40.271938
            ],
            [
              -76.924291,
              40.289642
            ],
            [
              -76.922134,
              40.302743
            ],
            [
              -76.912356,
              40.324184
            ],
            [
              -76.91497,
              40.328484
            ],
            [
              -76.974219,
              40.31033
            ],
            [
              -77.014719,
              40.304728
            ],
            [
              -77.083942,
              40.30122
            ],
            [
              -77.185461,
              40.287664
            ],
            [
              -77.239163,
              40.284102
            ],
            [
              -77.280548,
              40.275923
            ],
            [
              -77.317288,
              40.26759
            ],
            [
              -77.330491,
              40.275596
            ],
            [
              -77.322769,
              40.292001
            ],
            [
              -77.340647,
              40.286915
            ],
            [
              -77.385114,
              40.262712
            ],
            [
              -77.420845,
              40.255368
            ],
            [
              -77.409304,
              40.274554
            ],
            [
              -77.359873,
              40.306684
            ],
            [
              -77.360082,
              40.314075
            ],
            [
              -77.394445,
              40.303506
            ],
            [
              -77.426595,
              40.28661
            ],
            [
              -77.473596,
              40.254216
            ],
            [
              -77.536868,
              40.219072
            ],
            [
              -77.555823,
              40.21919
            ],
            [
              -77.569587,
              40.212119
            ],
            [
              -77.605448,
              40.185362
            ],
            [
              -77.614665,
              40.198549
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "027",
      "COUNTYNS": "01214720",
      "AFFGEOID": "0500000US42027",
      "GEOID": "42027",
      "NAME": "Centre",
      "LSAD": "06",
      "ALAND": 2874529780,
      "AWATER": 8187277,
      "County_state": "Centre,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.376518,
              40.754417
            ],
            [
              -78.359912,
              40.732592
            ],
            [
              -78.282781,
              40.73641
            ],
            [
              -78.131834,
              40.743017
            ],
            [
              -78.116521,
              40.739127
            ],
            [
              -78.106573,
              40.736745
            ],
            [
              -77.944122,
              40.691622
            ],
            [
              -77.916111,
              40.705439
            ],
            [
              -77.824506,
              40.743681
            ],
            [
              -77.768549,
              40.719466
            ],
            [
              -77.718677,
              40.727372
            ],
            [
              -77.71026,
              40.714632
            ],
            [
              -77.681334,
              40.729758
            ],
            [
              -77.652574,
              40.744921
            ],
            [
              -77.593355,
              40.761247
            ],
            [
              -77.438467,
              40.824027
            ],
            [
              -77.370175,
              40.846291
            ],
            [
              -77.36418,
              40.846937
            ],
            [
              -77.279236,
              40.90971
            ],
            [
              -77.204027,
              40.99271
            ],
            [
              -77.14416,
              41.044338
            ],
            [
              -77.339925,
              40.987167
            ],
            [
              -77.393907,
              40.978285
            ],
            [
              -77.440274,
              40.974448
            ],
            [
              -77.508596,
              40.962938
            ],
            [
              -77.514275,
              40.969562
            ],
            [
              -77.558664,
              41.021316
            ],
            [
              -77.579952,
              41.046982
            ],
            [
              -77.561889,
              41.057554
            ],
            [
              -77.567673,
              41.065588
            ],
            [
              -77.590782,
              41.070647
            ],
            [
              -77.595262,
              41.077711
            ],
            [
              -77.621759,
              41.091185
            ],
            [
              -77.65897,
              41.100955
            ],
            [
              -77.662603,
              41.108523
            ],
            [
              -77.690389,
              41.106249
            ],
            [
              -77.706225,
              41.113872
            ],
            [
              -77.717759,
              41.104954
            ],
            [
              -77.741911,
              41.107614
            ],
            [
              -77.759338,
              41.12267
            ],
            [
              -77.776813,
              41.145147
            ],
            [
              -77.789563,
              41.142127
            ],
            [
              -77.790009,
              41.177142
            ],
            [
              -77.79856,
              41.18195
            ],
            [
              -77.892724,
              41.178259
            ],
            [
              -77.895213,
              41.182715
            ],
            [
              -77.898195,
              41.252752
            ],
            [
              -77.923631,
              41.226427
            ],
            [
              -77.939008,
              41.222707
            ],
            [
              -77.940833,
              41.209436
            ],
            [
              -77.959438,
              41.217497
            ],
            [
              -77.973078,
              41.204188
            ],
            [
              -77.973012,
              41.173021
            ],
            [
              -77.999201,
              41.163016
            ],
            [
              -78.003569,
              41.144856
            ],
            [
              -78.017348,
              41.153187
            ],
            [
              -78.038203,
              41.153633
            ],
            [
              -78.068929,
              41.123533
            ],
            [
              -78.112239,
              41.114217
            ],
            [
              -78.096621,
              41.073383
            ],
            [
              -78.090713,
              41.060248
            ],
            [
              -78.060271,
              41.052607
            ],
            [
              -78.056933,
              41.039284
            ],
            [
              -78.080356,
              41.030783
            ],
            [
              -78.0597,
              41.003571
            ],
            [
              -78.061762,
              40.995067
            ],
            [
              -78.086854,
              40.99355
            ],
            [
              -78.090989,
              40.980417
            ],
            [
              -78.07774,
              40.958824
            ],
            [
              -78.109828,
              40.949933
            ],
            [
              -78.120844,
              40.941747
            ],
            [
              -78.136431,
              40.956999
            ],
            [
              -78.168128,
              40.960865
            ],
            [
              -78.155491,
              40.942139
            ],
            [
              -78.171346,
              40.936713
            ],
            [
              -78.181494,
              40.922324
            ],
            [
              -78.219813,
              40.912741
            ],
            [
              -78.229198,
              40.90075
            ],
            [
              -78.22061,
              40.889861
            ],
            [
              -78.248975,
              40.869519
            ],
            [
              -78.24922,
              40.855383
            ],
            [
              -78.269602,
              40.847075
            ],
            [
              -78.288441,
              40.849747
            ],
            [
              -78.302223,
              40.833519
            ],
            [
              -78.299691,
              40.824274
            ],
            [
              -78.314271,
              40.808702
            ],
            [
              -78.338137,
              40.799
            ],
            [
              -78.342487,
              40.782633
            ],
            [
              -78.376518,
              40.754417
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "099",
      "COUNTYNS": "01213682",
      "AFFGEOID": "0500000US42099",
      "GEOID": "42099",
      "NAME": "Perry",
      "LSAD": "06",
      "ALAND": 1428244405,
      "AWATER": 10589698,
      "County_state": "Perry,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.671761,
              40.289825
            ],
            [
              -77.656416,
              40.270393
            ],
            [
              -77.667711,
              40.252001
            ],
            [
              -77.645698,
              40.256031
            ],
            [
              -77.649951,
              40.226283
            ],
            [
              -77.639893,
              40.217678
            ],
            [
              -77.616167,
              40.232616
            ],
            [
              -77.602717,
              40.2282
            ],
            [
              -77.614665,
              40.198549
            ],
            [
              -77.605448,
              40.185362
            ],
            [
              -77.569587,
              40.212119
            ],
            [
              -77.555823,
              40.21919
            ],
            [
              -77.536868,
              40.219072
            ],
            [
              -77.473596,
              40.254216
            ],
            [
              -77.426595,
              40.28661
            ],
            [
              -77.394445,
              40.303506
            ],
            [
              -77.360082,
              40.314075
            ],
            [
              -77.359873,
              40.306684
            ],
            [
              -77.409304,
              40.274554
            ],
            [
              -77.420845,
              40.255368
            ],
            [
              -77.385114,
              40.262712
            ],
            [
              -77.340647,
              40.286915
            ],
            [
              -77.322769,
              40.292001
            ],
            [
              -77.330491,
              40.275596
            ],
            [
              -77.317288,
              40.26759
            ],
            [
              -77.280548,
              40.275923
            ],
            [
              -77.239163,
              40.284102
            ],
            [
              -77.185461,
              40.287664
            ],
            [
              -77.083942,
              40.30122
            ],
            [
              -77.014719,
              40.304728
            ],
            [
              -76.974219,
              40.31033
            ],
            [
              -76.91497,
              40.328484
            ],
            [
              -76.916811,
              40.331676
            ],
            [
              -76.937547,
              40.357481
            ],
            [
              -77.008191,
              40.358449
            ],
            [
              -77.020799,
              40.36623
            ],
            [
              -77.028645,
              40.391197
            ],
            [
              -77.013125,
              40.402349
            ],
            [
              -77.01627,
              40.425173
            ],
            [
              -77.003235,
              40.4268
            ],
            [
              -76.980452,
              40.447136
            ],
            [
              -76.952838,
              40.462444
            ],
            [
              -76.949466,
              40.484527
            ],
            [
              -76.96909,
              40.497562
            ],
            [
              -76.985104,
              40.516349
            ],
            [
              -76.981989,
              40.543415
            ],
            [
              -76.992488,
              40.567534
            ],
            [
              -76.965268,
              40.58706
            ],
            [
              -76.953105,
              40.604504
            ],
            [
              -76.949414,
              40.628163
            ],
            [
              -76.956077,
              40.627938
            ],
            [
              -77.023304,
              40.60426
            ],
            [
              -77.041954,
              40.604223
            ],
            [
              -77.103314,
              40.595905
            ],
            [
              -77.18293,
              40.58214
            ],
            [
              -77.167561,
              40.559509
            ],
            [
              -77.198462,
              40.552287
            ],
            [
              -77.231696,
              40.538484
            ],
            [
              -77.289592,
              40.518457
            ],
            [
              -77.413433,
              40.460589
            ],
            [
              -77.445888,
              40.44716
            ],
            [
              -77.540141,
              40.399221
            ],
            [
              -77.532265,
              40.376006
            ],
            [
              -77.565665,
              40.367363
            ],
            [
              -77.599428,
              40.348147
            ],
            [
              -77.636139,
              40.322374
            ],
            [
              -77.671761,
              40.289825
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "109",
      "COUNTYNS": "01213686",
      "AFFGEOID": "0500000US42109",
      "GEOID": "42109",
      "NAME": "Snyder",
      "LSAD": "06",
      "ALAND": 851544496,
      "AWATER": 7157948,
      "County_state": "Snyder,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.356628,
              40.807334
            ],
            [
              -77.354097,
              40.701667
            ],
            [
              -77.287941,
              40.693595
            ],
            [
              -77.150516,
              40.677074
            ],
            [
              -77.109597,
              40.691578
            ],
            [
              -77.093607,
              40.676121
            ],
            [
              -77.060451,
              40.679854
            ],
            [
              -77.035549,
              40.676918
            ],
            [
              -77.034409,
              40.659928
            ],
            [
              -77.008418,
              40.659912
            ],
            [
              -77.006054,
              40.642615
            ],
            [
              -76.965528,
              40.647149
            ],
            [
              -76.944828,
              40.650209
            ],
            [
              -76.939749,
              40.638378
            ],
            [
              -76.903849,
              40.674096
            ],
            [
              -76.880067,
              40.687173
            ],
            [
              -76.861184,
              40.711598
            ],
            [
              -76.857403,
              40.750035
            ],
            [
              -76.858703,
              40.781521
            ],
            [
              -76.849329,
              40.809666
            ],
            [
              -76.813022,
              40.847058
            ],
            [
              -76.798747,
              40.878052
            ],
            [
              -76.800242,
              40.881994
            ],
            [
              -76.799601,
              40.87857
            ],
            [
              -76.825555,
              40.887995
            ],
            [
              -76.908002,
              40.884843
            ],
            [
              -76.941788,
              40.88806
            ],
            [
              -77.011309,
              40.868768
            ],
            [
              -77.024901,
              40.857489
            ],
            [
              -77.052027,
              40.869387
            ],
            [
              -77.074058,
              40.868333
            ],
            [
              -77.129574,
              40.853794
            ],
            [
              -77.321639,
              40.817102
            ],
            [
              -77.356628,
              40.807334
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "033",
      "COUNTYNS": "01209175",
      "AFFGEOID": "0500000US42033",
      "GEOID": "42033",
      "NAME": "Clearfield",
      "LSAD": "06",
      "ALAND": 2964714577,
      "AWATER": 23932649,
      "County_state": "Clearfield,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.805167,
              40.90598
            ],
            [
              -78.806274,
              40.729742
            ],
            [
              -78.801697,
              40.724539
            ],
            [
              -78.712747,
              40.72435
            ],
            [
              -78.35043,
              40.724827
            ],
            [
              -78.359912,
              40.732592
            ],
            [
              -78.376518,
              40.754417
            ],
            [
              -78.342487,
              40.782633
            ],
            [
              -78.338137,
              40.799
            ],
            [
              -78.314271,
              40.808702
            ],
            [
              -78.299691,
              40.824274
            ],
            [
              -78.302223,
              40.833519
            ],
            [
              -78.288441,
              40.849747
            ],
            [
              -78.269602,
              40.847075
            ],
            [
              -78.24922,
              40.855383
            ],
            [
              -78.248975,
              40.869519
            ],
            [
              -78.22061,
              40.889861
            ],
            [
              -78.229198,
              40.90075
            ],
            [
              -78.219813,
              40.912741
            ],
            [
              -78.181494,
              40.922324
            ],
            [
              -78.171346,
              40.936713
            ],
            [
              -78.155491,
              40.942139
            ],
            [
              -78.168128,
              40.960865
            ],
            [
              -78.136431,
              40.956999
            ],
            [
              -78.120844,
              40.941747
            ],
            [
              -78.109828,
              40.949933
            ],
            [
              -78.07774,
              40.958824
            ],
            [
              -78.090989,
              40.980417
            ],
            [
              -78.086854,
              40.99355
            ],
            [
              -78.061762,
              40.995067
            ],
            [
              -78.0597,
              41.003571
            ],
            [
              -78.080356,
              41.030783
            ],
            [
              -78.056933,
              41.039284
            ],
            [
              -78.060271,
              41.052607
            ],
            [
              -78.090713,
              41.060248
            ],
            [
              -78.096621,
              41.073383
            ],
            [
              -78.112239,
              41.114217
            ],
            [
              -78.068929,
              41.123533
            ],
            [
              -78.038203,
              41.153633
            ],
            [
              -78.061007,
              41.174539
            ],
            [
              -78.093381,
              41.216931
            ],
            [
              -78.236817,
              41.230475
            ],
            [
              -78.484712,
              41.24394
            ],
            [
              -78.646666,
              41.253778
            ],
            [
              -78.647947,
              41.21746
            ],
            [
              -78.710248,
              41.201859
            ],
            [
              -78.766377,
              41.202759
            ],
            [
              -78.804532,
              41.132186
            ],
            [
              -78.804047,
              40.993855
            ],
            [
              -78.805167,
              40.90598
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "101",
      "COUNTYNS": "01209187",
      "AFFGEOID": "0500000US42101",
      "GEOID": "42101",
      "NAME": "Philadelphia",
      "LSAD": "06",
      "ALAND": 347520038,
      "AWATER": 22086063,
      "County_state": "Philadelphia,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.276482,
              39.976959
            ],
            [
              -75.280266,
              39.974964
            ],
            [
              -75.275394,
              39.969575
            ],
            [
              -75.247688,
              39.962817
            ],
            [
              -75.251496,
              39.949251
            ],
            [
              -75.247208,
              39.943904
            ],
            [
              -75.243026,
              39.942211
            ],
            [
              -75.234981,
              39.938023
            ],
            [
              -75.24694,
              39.916871
            ],
            [
              -75.251288,
              39.904403
            ],
            [
              -75.256741,
              39.894726
            ],
            [
              -75.264878,
              39.887927
            ],
            [
              -75.264433,
              39.882167
            ],
            [
              -75.262539,
              39.876578
            ],
            [
              -75.215722,
              39.876087
            ],
            [
              -75.2108764002954,
              39.865708592319095
            ],
            [
              -75.210425,
              39.865913
            ],
            [
              -75.195324,
              39.877013
            ],
            [
              -75.189323,
              39.880713
            ],
            [
              -75.183023,
              39.882013
            ],
            [
              -75.17453546617179,
              39.8821969289728
            ],
            [
              -75.150721,
              39.882713
            ],
            [
              -75.145421,
              39.884213
            ],
            [
              -75.14532381843439,
              39.8842842664814
            ],
            [
              -75.142421,
              39.886413
            ],
            [
              -75.140221,
              39.888213
            ],
            [
              -75.1400064847137,
              39.888465333817194
            ],
            [
              -75.13342,
              39.896213
            ],
            [
              -75.13082,
              39.900213
            ],
            [
              -75.12792,
              39.911813
            ],
            [
              -75.13012,
              39.917013
            ],
            [
              -75.13282,
              39.921612
            ],
            [
              -75.13502,
              39.927312
            ],
            [
              -75.13588759459459,
              39.932517567567594
            ],
            [
              -75.13612,
              39.933912
            ],
            [
              -75.13572,
              39.947112
            ],
            [
              -75.13352,
              39.954412
            ],
            [
              -75.13012,
              39.958712
            ],
            [
              -75.12692,
              39.961112
            ],
            [
              -75.1238629772435,
              39.96281916855229
            ],
            [
              -75.11922,
              39.965412
            ],
            [
              -75.108119,
              39.970312
            ],
            [
              -75.1075409360153,
              39.970476576233395
            ],
            [
              -75.09371907941859,
              39.9744116926869
            ],
            [
              -75.093718,
              39.974412
            ],
            [
              -75.0890107861163,
              39.9751503864916
            ],
            [
              -75.088618,
              39.975212
            ],
            [
              -75.072017,
              39.980612
            ],
            [
              -75.0599940905637,
              39.9916175863301
            ],
            [
              -75.059017,
              39.992512
            ],
            [
              -75.051217,
              40.004512
            ],
            [
              -75.047016,
              40.008912
            ],
            [
              -75.0466806199737,
              40.009090578975
            ],
            [
              -75.039316,
              40.013012
            ],
            [
              -75.0299549242127,
              40.015568095607
            ],
            [
              -75.015515,
              40.019511
            ],
            [
              -75.011115,
              40.021311
            ],
            [
              -75.007914,
              40.023111
            ],
            [
              -74.989914,
              40.037311
            ],
            [
              -74.983913,
              40.042711
            ],
            [
              -74.974713,
              40.048711
            ],
            [
              -74.97431985326,
              40.04889913332
            ],
            [
              -74.984893,
              40.056836
            ],
            [
              -74.980688,
              40.065007
            ],
            [
              -74.971268,
              40.073744
            ],
            [
              -74.955763,
              40.094982
            ],
            [
              -74.964991,
              40.118635
            ],
            [
              -74.993595,
              40.132266
            ],
            [
              -74.997885,
              40.127816
            ],
            [
              -75.015066,
              40.137992
            ],
            [
              -75.020571,
              40.132274
            ],
            [
              -75.0493,
              40.112199
            ],
            [
              -75.054321,
              40.108692
            ],
            [
              -75.058342,
              40.105892
            ],
            [
              -75.07127,
              40.092532
            ],
            [
              -75.082456,
              40.082884
            ],
            [
              -75.090317,
              40.075115
            ],
            [
              -75.096733,
              40.068989
            ],
            [
              -75.087425,
              40.063599
            ],
            [
              -75.102413,
              40.051792
            ],
            [
              -75.110985,
              40.046769
            ],
            [
              -75.12632,
              40.055481
            ],
            [
              -75.139427,
              40.063358
            ],
            [
              -75.151596,
              40.070328
            ],
            [
              -75.176438,
              40.084649
            ],
            [
              -75.188461,
              40.07275
            ],
            [
              -75.22361,
              40.092907
            ],
            [
              -75.2305,
              40.086022
            ],
            [
              -75.255006,
              40.062704
            ],
            [
              -75.264431,
              40.054094
            ],
            [
              -75.259621,
              40.049238
            ],
            [
              -75.237762,
              40.031934
            ],
            [
              -75.218056,
              40.020943
            ],
            [
              -75.205771,
              40.011629
            ],
            [
              -75.211548,
              40.008213
            ],
            [
              -75.227462,
              40.000746
            ],
            [
              -75.237331,
              39.996128
            ],
            [
              -75.249214,
              39.990547
            ],
            [
              -75.251438,
              39.989504
            ],
            [
              -75.263687,
              39.982658
            ],
            [
              -75.276482,
              39.976959
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "123",
      "COUNTYNS": "01213691",
      "AFFGEOID": "0500000US42123",
      "GEOID": "42123",
      "NAME": "Warren",
      "LSAD": "06",
      "ALAND": 2289914225,
      "AWATER": 37352304,
      "County_state": "Warren,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.612072,
              41.850056
            ],
            [
              -79.612873,
              41.623858
            ],
            [
              -79.51207,
              41.624559
            ],
            [
              -79.493454,
              41.624716
            ],
            [
              -78.956042,
              41.623402
            ],
            [
              -78.956056,
              41.623863
            ],
            [
              -78.948689,
              41.83048
            ],
            [
              -78.921581,
              41.830916
            ],
            [
              -78.915775,
              41.840896
            ],
            [
              -78.917151,
              41.861779
            ],
            [
              -78.918575,
              41.95294
            ],
            [
              -78.9188549239275,
              41.998124927411794
            ],
            [
              -78.983065,
              41.998949
            ],
            [
              -79.061265,
              41.999259
            ],
            [
              -79.17857,
              41.999458
            ],
            [
              -79.249772,
              41.998807
            ],
            [
              -79.472472,
              41.998255
            ],
            [
              -79.5109917186785,
              41.9984138129004
            ],
            [
              -79.538445,
              41.998527
            ],
            [
              -79.551385,
              41.998666
            ],
            [
              -79.6108388547624,
              41.9989893460904
            ],
            [
              -79.612072,
              41.850056
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "035",
      "COUNTYNS": "01214721",
      "AFFGEOID": "0500000US42035",
      "GEOID": "42035",
      "NAME": "Clinton",
      "LSAD": "06",
      "ALAND": 2299868230,
      "AWATER": 23178794,
      "County_state": "Clinton,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.093381,
              41.216931
            ],
            [
              -78.061007,
              41.174539
            ],
            [
              -78.038203,
              41.153633
            ],
            [
              -78.017348,
              41.153187
            ],
            [
              -78.003569,
              41.144856
            ],
            [
              -77.999201,
              41.163016
            ],
            [
              -77.973012,
              41.173021
            ],
            [
              -77.973078,
              41.204188
            ],
            [
              -77.959438,
              41.217497
            ],
            [
              -77.940833,
              41.209436
            ],
            [
              -77.939008,
              41.222707
            ],
            [
              -77.923631,
              41.226427
            ],
            [
              -77.898195,
              41.252752
            ],
            [
              -77.895213,
              41.182715
            ],
            [
              -77.892724,
              41.178259
            ],
            [
              -77.79856,
              41.18195
            ],
            [
              -77.790009,
              41.177142
            ],
            [
              -77.789563,
              41.142127
            ],
            [
              -77.776813,
              41.145147
            ],
            [
              -77.759338,
              41.12267
            ],
            [
              -77.741911,
              41.107614
            ],
            [
              -77.717759,
              41.104954
            ],
            [
              -77.706225,
              41.113872
            ],
            [
              -77.690389,
              41.106249
            ],
            [
              -77.662603,
              41.108523
            ],
            [
              -77.65897,
              41.100955
            ],
            [
              -77.621759,
              41.091185
            ],
            [
              -77.595262,
              41.077711
            ],
            [
              -77.590782,
              41.070647
            ],
            [
              -77.567673,
              41.065588
            ],
            [
              -77.561889,
              41.057554
            ],
            [
              -77.579952,
              41.046982
            ],
            [
              -77.558664,
              41.021316
            ],
            [
              -77.514275,
              40.969562
            ],
            [
              -77.508596,
              40.962938
            ],
            [
              -77.440274,
              40.974448
            ],
            [
              -77.393907,
              40.978285
            ],
            [
              -77.339925,
              40.987167
            ],
            [
              -77.14416,
              41.044338
            ],
            [
              -77.144111,
              41.06884
            ],
            [
              -77.164278,
              41.068708
            ],
            [
              -77.272018,
              41.172534
            ],
            [
              -77.293059,
              41.186473
            ],
            [
              -77.29058,
              41.1951
            ],
            [
              -77.320891,
              41.218932
            ],
            [
              -77.349765,
              41.219918
            ],
            [
              -77.349627,
              41.228873
            ],
            [
              -77.37035,
              41.233079
            ],
            [
              -77.399043,
              41.253053
            ],
            [
              -77.4294,
              41.28214
            ],
            [
              -77.445158,
              41.287762
            ],
            [
              -77.456424,
              41.304639
            ],
            [
              -77.471862,
              41.314273
            ],
            [
              -77.475987,
              41.338294
            ],
            [
              -77.499193,
              41.353577
            ],
            [
              -77.526537,
              41.358528
            ],
            [
              -77.540008,
              41.368101
            ],
            [
              -77.541899,
              41.381296
            ],
            [
              -77.564435,
              41.412683
            ],
            [
              -77.57574,
              41.421602
            ],
            [
              -77.584671,
              41.441301
            ],
            [
              -77.597282,
              41.441056
            ],
            [
              -77.598129,
              41.478576
            ],
            [
              -77.989194,
              41.474822
            ],
            [
              -77.988786,
              41.367453
            ],
            [
              -78.093381,
              41.216931
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "019",
      "COUNTYNS": "01213661",
      "AFFGEOID": "0500000US42019",
      "GEOID": "42019",
      "NAME": "Butler",
      "LSAD": "06",
      "ALAND": 2042451307,
      "AWATER": 15948099,
      "County_state": "Butler,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.16529,
              41.00047
            ],
            [
              -80.162547,
              40.937751
            ],
            [
              -80.160006,
              40.886909
            ],
            [
              -80.158534,
              40.855157
            ],
            [
              -80.155115,
              40.788314
            ],
            [
              -80.152562,
              40.744099
            ],
            [
              -80.148451,
              40.67429
            ],
            [
              -79.99418,
              40.673636
            ],
            [
              -79.964595,
              40.672514
            ],
            [
              -79.924182,
              40.673021
            ],
            [
              -79.786358,
              40.671543
            ],
            [
              -79.732744,
              40.66924
            ],
            [
              -79.69293,
              40.669744
            ],
            [
              -79.691968,
              40.81336
            ],
            [
              -79.691295,
              40.894816
            ],
            [
              -79.69014,
              41.030659
            ],
            [
              -79.690711,
              41.170691
            ],
            [
              -79.694984,
              41.172865
            ],
            [
              -79.885546,
              41.172463
            ],
            [
              -79.999779,
              41.171861
            ],
            [
              -80.067373,
              41.09986
            ],
            [
              -80.096861,
              41.069804
            ],
            [
              -80.100723,
              41.065476
            ],
            [
              -80.16529,
              41.00047
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "051",
      "COUNTYNS": "01209179",
      "AFFGEOID": "0500000US42051",
      "GEOID": "42051",
      "NAME": "Fayette",
      "LSAD": "06",
      "ALAND": 2046968026,
      "AWATER": 20644021,
      "County_state": "Fayette,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.998014,
              39.983322
            ],
            [
              -80.009344,
              39.958794
            ],
            [
              -79.993711,
              39.949386
            ],
            [
              -79.957246,
              39.953274
            ],
            [
              -79.925597,
              39.924795
            ],
            [
              -79.925614,
              39.914763
            ],
            [
              -79.92843,
              39.902283
            ],
            [
              -79.915278,
              39.8803
            ],
            [
              -79.930676,
              39.868076
            ],
            [
              -79.924507,
              39.856363
            ],
            [
              -79.92465,
              39.855056
            ],
            [
              -79.927063,
              39.853293
            ],
            [
              -79.929808,
              39.851436
            ],
            [
              -79.938377,
              39.846176
            ],
            [
              -79.924376,
              39.838176
            ],
            [
              -79.920168,
              39.812278
            ],
            [
              -79.908577,
              39.793477
            ],
            [
              -79.934411,
              39.781277
            ],
            [
              -79.93008,
              39.757078
            ],
            [
              -79.903679,
              39.743478
            ],
            [
              -79.9161705658965,
              39.72089322136409
            ],
            [
              -79.853131,
              39.720713
            ],
            [
              -79.852904,
              39.720713
            ],
            [
              -79.80810850275802,
              39.720744662923
            ],
            [
              -79.763774,
              39.720776
            ],
            [
              -79.610623,
              39.721245
            ],
            [
              -79.608223,
              39.721154
            ],
            [
              -79.548465,
              39.720778
            ],
            [
              -79.476662,
              39.721078
            ],
            [
              -79.3924584050224,
              39.7214393586401
            ],
            [
              -79.392659,
              39.732678
            ],
            [
              -79.409959,
              39.734978
            ],
            [
              -79.394058,
              39.743277
            ],
            [
              -79.399858,
              39.755577
            ],
            [
              -79.378658,
              39.768877
            ],
            [
              -79.391658,
              39.777977
            ],
            [
              -79.380058,
              39.790077
            ],
            [
              -79.356757,
              39.781477
            ],
            [
              -79.368849,
              39.801458
            ],
            [
              -79.365695,
              39.820546
            ],
            [
              -79.381233,
              39.826876
            ],
            [
              -79.381956,
              39.836861
            ],
            [
              -79.417558,
              39.853776
            ],
            [
              -79.398718,
              39.900628
            ],
            [
              -79.363739,
              39.947778
            ],
            [
              -79.347363,
              39.969492
            ],
            [
              -79.330397,
              39.992796
            ],
            [
              -79.296131,
              40.03675
            ],
            [
              -79.293682,
              40.040413
            ],
            [
              -79.34278,
              40.04445
            ],
            [
              -79.355627,
              40.074359
            ],
            [
              -79.393712,
              40.090897
            ],
            [
              -79.40193,
              40.10283
            ],
            [
              -79.43756,
              40.11586
            ],
            [
              -79.447723,
              40.13027
            ],
            [
              -79.483744,
              40.143547
            ],
            [
              -79.505188,
              40.140568
            ],
            [
              -79.527613,
              40.122935
            ],
            [
              -79.535462,
              40.125977
            ],
            [
              -79.540347,
              40.114035
            ],
            [
              -79.578966,
              40.105496
            ],
            [
              -79.593021,
              40.082055
            ],
            [
              -79.622635,
              40.094337
            ],
            [
              -79.638994,
              40.082859
            ],
            [
              -79.659357,
              40.097305
            ],
            [
              -79.686137,
              40.11389
            ],
            [
              -79.72237,
              40.112825
            ],
            [
              -79.744404,
              40.128168
            ],
            [
              -79.780551,
              40.127947
            ],
            [
              -79.87363,
              40.12912
            ],
            [
              -79.877385,
              40.126792
            ],
            [
              -79.852536,
              40.116104
            ],
            [
              -79.839047,
              40.091641
            ],
            [
              -79.862456,
              40.075657
            ],
            [
              -79.896489,
              40.079833
            ],
            [
              -79.878937,
              40.063033
            ],
            [
              -79.891609,
              40.049417
            ],
            [
              -79.877048,
              40.036826
            ],
            [
              -79.877166,
              40.031708
            ],
            [
              -79.895469,
              40.022056
            ],
            [
              -79.91432,
              40.021037
            ],
            [
              -79.934523,
              39.999626
            ],
            [
              -79.95462,
              39.995274
            ],
            [
              -79.971407,
              40.01381
            ],
            [
              -79.993566,
              40.009129
            ],
            [
              -79.998014,
              39.983322
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "069",
      "COUNTYNS": "01213675",
      "AFFGEOID": "0500000US42069",
      "GEOID": "42069",
      "NAME": "Lackawanna",
      "LSAD": "06",
      "ALAND": 1188217624,
      "AWATER": 15896171,
      "County_state": "Lackawanna,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.834695,
              41.427246
            ],
            [
              -75.830135,
              41.394414
            ],
            [
              -75.795286,
              41.378127
            ],
            [
              -75.76786,
              41.365841
            ],
            [
              -75.760518,
              41.360942
            ],
            [
              -75.741539,
              41.348179
            ],
            [
              -75.687244,
              41.339216
            ],
            [
              -75.694055,
              41.303815
            ],
            [
              -75.679356,
              41.288179
            ],
            [
              -75.677753,
              41.264491
            ],
            [
              -75.661421,
              41.261041
            ],
            [
              -75.652922,
              41.258314
            ],
            [
              -75.649257,
              41.190951
            ],
            [
              -75.643494,
              41.176259
            ],
            [
              -75.616567,
              41.172618
            ],
            [
              -75.600736,
              41.161497
            ],
            [
              -75.598634,
              41.163062
            ],
            [
              -75.598536,
              41.171935
            ],
            [
              -75.576653,
              41.177857
            ],
            [
              -75.558862,
              41.206857
            ],
            [
              -75.544063,
              41.223328
            ],
            [
              -75.517466,
              41.219259
            ],
            [
              -75.505607,
              41.232539
            ],
            [
              -75.496057,
              41.242948
            ],
            [
              -75.470849,
              41.243267
            ],
            [
              -75.440561,
              41.258552
            ],
            [
              -75.441421,
              41.260053
            ],
            [
              -75.450037,
              41.425611
            ],
            [
              -75.456383,
              41.533638
            ],
            [
              -75.462411,
              41.641587
            ],
            [
              -75.469259,
              41.64141
            ],
            [
              -75.652187,
              41.642196
            ],
            [
              -75.719887,
              41.642263
            ],
            [
              -75.729174,
              41.635791
            ],
            [
              -75.774471,
              41.574187
            ],
            [
              -75.802502,
              41.523578
            ],
            [
              -75.773721,
              41.50383
            ],
            [
              -75.834695,
              41.427246
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "113",
      "COUNTYNS": "01213687",
      "AFFGEOID": "0500000US42113",
      "GEOID": "42113",
      "NAME": "Sullivan",
      "LSAD": "06",
      "ALAND": 1165338399,
      "AWATER": 6617028,
      "County_state": "Sullivan,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.778814,
              41.586707
            ],
            [
              -76.813731,
              41.590034
            ],
            [
              -76.749497,
              41.405572
            ],
            [
              -76.592532,
              41.304332
            ],
            [
              -76.548488,
              41.293135
            ],
            [
              -76.447597,
              41.275629
            ],
            [
              -76.407934,
              41.308418
            ],
            [
              -76.310133,
              41.310199
            ],
            [
              -76.2831,
              41.376517
            ],
            [
              -76.228131,
              41.511137
            ],
            [
              -76.220139,
              41.541285
            ],
            [
              -76.778814,
              41.586707
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "125",
      "COUNTYNS": "01209190",
      "AFFGEOID": "0500000US42125",
      "GEOID": "42125",
      "NAME": "Washington",
      "LSAD": "06",
      "ALAND": 2219590653,
      "AWATER": 10147672,
      "County_state": "Washington,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.519218,
              39.962424
            ],
            [
              -80.5192168880039,
              39.9621993767921
            ],
            [
              -80.500943,
              39.953784
            ],
            [
              -80.475277,
              39.952065
            ],
            [
              -80.452777,
              39.973419
            ],
            [
              -80.396475,
              39.97789
            ],
            [
              -80.379505,
              39.994125
            ],
            [
              -80.309114,
              40.00585
            ],
            [
              -80.301396,
              40.020559
            ],
            [
              -80.273255,
              40.01328
            ],
            [
              -80.273355,
              40.002376
            ],
            [
              -80.240138,
              40.009476
            ],
            [
              -80.191233,
              40.001931
            ],
            [
              -80.175921,
              40.008138
            ],
            [
              -80.157339,
              39.999581
            ],
            [
              -80.12951,
              39.988406
            ],
            [
              -80.086114,
              39.990313
            ],
            [
              -80.077242,
              39.997014
            ],
            [
              -80.060314,
              40.009453
            ],
            [
              -80.038927,
              39.996467
            ],
            [
              -80.048292,
              39.978527
            ],
            [
              -79.998014,
              39.983322
            ],
            [
              -79.993566,
              40.009129
            ],
            [
              -79.971407,
              40.01381
            ],
            [
              -79.95462,
              39.995274
            ],
            [
              -79.934523,
              39.999626
            ],
            [
              -79.91432,
              40.021037
            ],
            [
              -79.895469,
              40.022056
            ],
            [
              -79.877166,
              40.031708
            ],
            [
              -79.877048,
              40.036826
            ],
            [
              -79.891609,
              40.049417
            ],
            [
              -79.878937,
              40.063033
            ],
            [
              -79.896489,
              40.079833
            ],
            [
              -79.862456,
              40.075657
            ],
            [
              -79.839047,
              40.091641
            ],
            [
              -79.852536,
              40.116104
            ],
            [
              -79.877385,
              40.126792
            ],
            [
              -79.886649,
              40.130216
            ],
            [
              -79.90417,
              40.151967
            ],
            [
              -79.904778,
              40.157255
            ],
            [
              -79.900346,
              40.162503
            ],
            [
              -79.893306,
              40.164983
            ],
            [
              -79.887337,
              40.164983
            ],
            [
              -79.870668,
              40.162127
            ],
            [
              -79.850584,
              40.174727
            ],
            [
              -79.854456,
              40.191255
            ],
            [
              -79.870585,
              40.197415
            ],
            [
              -79.896448,
              40.194481
            ],
            [
              -79.959532,
              40.216134
            ],
            [
              -79.970428,
              40.232246
            ],
            [
              -79.914139,
              40.252518
            ],
            [
              -80.033712,
              40.288034
            ],
            [
              -80.07396,
              40.299837
            ],
            [
              -80.116156,
              40.312324
            ],
            [
              -80.183129,
              40.332515
            ],
            [
              -80.2347,
              40.375368
            ],
            [
              -80.360873,
              40.477539
            ],
            [
              -80.361865,
              40.477571
            ],
            [
              -80.5189953454551,
              40.4773632987013
            ],
            [
              -80.51899,
              40.473667
            ],
            [
              -80.51769,
              40.462467
            ],
            [
              -80.5179873274459,
              40.3996439826186
            ],
            [
              -80.517991,
              40.398868
            ],
            [
              -80.517991,
              40.367968
            ],
            [
              -80.519039,
              40.342101
            ],
            [
              -80.51904013767269,
              40.3307691106087
            ],
            [
              -80.519056,
              40.172771
            ],
            [
              -80.519056,
              40.172744
            ],
            [
              -80.519104,
              40.159672
            ],
            [
              -80.5189913219311,
              40.095834396573295
            ],
            [
              -80.51896,
              40.078089
            ],
            [
              -80.519008,
              40.077001
            ],
            [
              -80.51912,
              40.01641
            ],
            [
              -80.519207,
              39.963381
            ],
            [
              -80.519218,
              39.962424
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "083",
      "COUNTYNS": "01210235",
      "AFFGEOID": "0500000US42083",
      "GEOID": "42083",
      "NAME": "McKean",
      "LSAD": "06",
      "ALAND": 2536105992,
      "AWATER": 13010718,
      "County_state": "McKean,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.948689,
              41.83048
            ],
            [
              -78.956056,
              41.623863
            ],
            [
              -78.815809,
              41.622726
            ],
            [
              -78.672528,
              41.625061
            ],
            [
              -78.641557,
              41.628186
            ],
            [
              -78.483409,
              41.629898
            ],
            [
              -78.482836,
              41.602354
            ],
            [
              -78.419118,
              41.602188
            ],
            [
              -78.419299,
              41.616853
            ],
            [
              -78.203422,
              41.618157
            ],
            [
              -78.2066042785298,
              41.999622491491095
            ],
            [
              -78.271204,
              41.998968
            ],
            [
              -78.308128,
              41.999415
            ],
            [
              -78.59665,
              41.999877
            ],
            [
              -78.749754,
              41.998109
            ],
            [
              -78.874759,
              41.997559
            ],
            [
              -78.9188549239275,
              41.998124927411794
            ],
            [
              -78.918575,
              41.95294
            ],
            [
              -78.917151,
              41.861779
            ],
            [
              -78.915775,
              41.840896
            ],
            [
              -78.921581,
              41.830916
            ],
            [
              -78.948689,
              41.83048
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "011",
      "COUNTYNS": "01209172",
      "AFFGEOID": "0500000US42011",
      "GEOID": "42011",
      "NAME": "Berks",
      "LSAD": "06",
      "ALAND": 2218035729,
      "AWATER": 24256617,
      "County_state": "Berks,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.4186,
              40.495615
            ],
            [
              -76.440181,
              40.495952
            ],
            [
              -76.341021,
              40.434358
            ],
            [
              -76.307108,
              40.413438
            ],
            [
              -76.231916,
              40.366292
            ],
            [
              -76.198594,
              40.354877
            ],
            [
              -76.151215,
              40.315971
            ],
            [
              -76.091764,
              40.277692
            ],
            [
              -76.044058,
              40.247237
            ],
            [
              -76.043993,
              40.247196
            ],
            [
              -75.941804,
              40.181588
            ],
            [
              -75.873375,
              40.137107
            ],
            [
              -75.807866,
              40.177654
            ],
            [
              -75.743178,
              40.216721
            ],
            [
              -75.696782,
              40.241863
            ],
            [
              -75.638828,
              40.312772
            ],
            [
              -75.569274,
              40.397892
            ],
            [
              -75.559753,
              40.409723
            ],
            [
              -75.529694,
              40.446995
            ],
            [
              -75.571926,
              40.473348
            ],
            [
              -75.579274,
              40.478007
            ],
            [
              -75.641481,
              40.517655
            ],
            [
              -75.674921,
              40.53897
            ],
            [
              -75.739442,
              40.57994
            ],
            [
              -75.891473,
              40.67727
            ],
            [
              -75.993212,
              40.639712
            ],
            [
              -76.012226,
              40.577713
            ],
            [
              -76.018562,
              40.573826
            ],
            [
              -76.058024,
              40.579629
            ],
            [
              -76.065339,
              40.579678
            ],
            [
              -76.079566,
              40.566077
            ],
            [
              -76.105865,
              40.562198
            ],
            [
              -76.155096,
              40.545229
            ],
            [
              -76.171624,
              40.534947
            ],
            [
              -76.219407,
              40.528699
            ],
            [
              -76.256008,
              40.517067
            ],
            [
              -76.339109,
              40.515351
            ],
            [
              -76.369222,
              40.509597
            ],
            [
              -76.4186,
              40.495615
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "001",
      "COUNTYNS": "01213656",
      "AFFGEOID": "0500000US42001",
      "GEOID": "42001",
      "NAME": "Adams",
      "LSAD": "06",
      "ALAND": 1343396471,
      "AWATER": 7887889,
      "County_state": "Adams,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.471085,
              39.944077
            ],
            [
              -77.465124,
              39.849893
            ],
            [
              -77.459433419622,
              39.720229174305295
            ],
            [
              -77.24345,
              39.720233
            ],
            [
              -77.23995,
              39.720233
            ],
            [
              -77.2170240489139,
              39.720235990471494
            ],
            [
              -77.216951,
              39.720236
            ],
            [
              -77.059059,
              39.720354
            ],
            [
              -77.058359,
              39.720455
            ],
            [
              -77.04726,
              39.720256
            ],
            [
              -76.9993180535053,
              39.7200905005584
            ],
            [
              -77.000028,
              39.826284
            ],
            [
              -76.998583,
              39.831385
            ],
            [
              -76.978571,
              39.847708
            ],
            [
              -76.957175,
              39.855741
            ],
            [
              -76.955051,
              39.856975
            ],
            [
              -76.983738,
              39.893563
            ],
            [
              -76.966983,
              39.916183
            ],
            [
              -76.966202,
              39.935192
            ],
            [
              -77.018648,
              40.013947
            ],
            [
              -77.068485,
              40.028872
            ],
            [
              -77.092628,
              40.05392
            ],
            [
              -77.137425,
              40.069945
            ],
            [
              -77.162071,
              40.051697
            ],
            [
              -77.178108,
              40.036755
            ],
            [
              -77.18563,
              40.029501
            ],
            [
              -77.255209,
              40.02076
            ],
            [
              -77.35373,
              40.003302
            ],
            [
              -77.403606,
              39.994478
            ],
            [
              -77.421362,
              39.981294
            ],
            [
              -77.471085,
              39.944077
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "133",
      "COUNTYNS": "01209193",
      "AFFGEOID": "0500000US42133",
      "GEOID": "42133",
      "NAME": "York",
      "LSAD": "06",
      "ALAND": 2341882815,
      "AWATER": 16941833,
      "County_state": "York,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.137425,
              40.069945
            ],
            [
              -77.092628,
              40.05392
            ],
            [
              -77.068485,
              40.028872
            ],
            [
              -77.018648,
              40.013947
            ],
            [
              -76.966202,
              39.935192
            ],
            [
              -76.966983,
              39.916183
            ],
            [
              -76.983738,
              39.893563
            ],
            [
              -76.955051,
              39.856975
            ],
            [
              -76.957175,
              39.855741
            ],
            [
              -76.978571,
              39.847708
            ],
            [
              -76.998583,
              39.831385
            ],
            [
              -77.000028,
              39.826284
            ],
            [
              -76.9993180535053,
              39.7200905005584
            ],
            [
              -76.991062,
              39.720062
            ],
            [
              -76.936764,
              39.720968
            ],
            [
              -76.897732,
              39.720672
            ],
            [
              -76.890266,
              39.720673
            ],
            [
              -76.88124901711859,
              39.7207075529518
            ],
            [
              -76.809368,
              39.720983
            ],
            [
              -76.806568,
              39.720883
            ],
            [
              -76.78727,
              39.721085
            ],
            [
              -76.7870972309155,
              39.721085744246494
            ],
            [
              -76.715771,
              39.721393
            ],
            [
              -76.712072,
              39.721394
            ],
            [
              -76.6976582563422,
              39.7214056324705
            ],
            [
              -76.60977146442329,
              39.7214765606444
            ],
            [
              -76.569576,
              39.721509
            ],
            [
              -76.5694756327762,
              39.721509203425
            ],
            [
              -76.517277,
              39.721615
            ],
            [
              -76.492079,
              39.721618
            ],
            [
              -76.4424425714211,
              39.7215555292216
            ],
            [
              -76.41898,
              39.721526
            ],
            [
              -76.41888,
              39.721626
            ],
            [
              -76.40454774625779,
              39.721565193867
            ],
            [
              -76.395781,
              39.721528
            ],
            [
              -76.380782,
              39.72163
            ],
            [
              -76.380282,
              39.72163
            ],
            [
              -76.2396824239736,
              39.721645324517596
            ],
            [
              -76.240582,
              39.730313
            ],
            [
              -76.276113,
              39.77562
            ],
            [
              -76.315702,
              39.810037
            ],
            [
              -76.338255,
              39.822062
            ],
            [
              -76.350759,
              39.84709
            ],
            [
              -76.380692,
              39.873675
            ],
            [
              -76.379769,
              39.887913
            ],
            [
              -76.391674,
              39.912819
            ],
            [
              -76.448969,
              39.934338
            ],
            [
              -76.477726,
              39.949844
            ],
            [
              -76.495352,
              39.976128
            ],
            [
              -76.498454,
              39.99673
            ],
            [
              -76.512005,
              40.012489
            ],
            [
              -76.512236,
              40.013442
            ],
            [
              -76.534927,
              40.051359
            ],
            [
              -76.571217,
              40.045669
            ],
            [
              -76.644508,
              40.058069
            ],
            [
              -76.663825,
              40.063227
            ],
            [
              -76.697187,
              40.102492
            ],
            [
              -76.715097,
              40.11598
            ],
            [
              -76.72162,
              40.12007
            ],
            [
              -76.737307,
              40.125333
            ],
            [
              -76.756379,
              40.177052
            ],
            [
              -76.763787,
              40.17947
            ],
            [
              -76.800585,
              40.200111
            ],
            [
              -76.806121,
              40.200618
            ],
            [
              -76.823039,
              40.201704
            ],
            [
              -76.839468,
              40.221615
            ],
            [
              -76.859023,
              40.226328
            ],
            [
              -76.867015,
              40.216002
            ],
            [
              -76.891801,
              40.215895
            ],
            [
              -76.896961,
              40.224666
            ],
            [
              -76.912249,
              40.204506
            ],
            [
              -76.921265,
              40.209207
            ],
            [
              -76.929481,
              40.196853
            ],
            [
              -76.908555,
              40.190729
            ],
            [
              -76.923294,
              40.177087
            ],
            [
              -76.905875,
              40.165723
            ],
            [
              -76.929107,
              40.161274
            ],
            [
              -76.978024,
              40.164777
            ],
            [
              -76.971226,
              40.157022
            ],
            [
              -77.012601,
              40.148421
            ],
            [
              -77.028853,
              40.147838
            ],
            [
              -77.080387,
              40.110985
            ],
            [
              -77.137425,
              40.069945
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "091",
      "COUNTYNS": "01213680",
      "AFFGEOID": "0500000US42091",
      "GEOID": "42091",
      "NAME": "Montgomery",
      "LSAD": "06",
      "ALAND": 1250824558,
      "AWATER": 11016543,
      "County_state": "Montgomery,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.696782,
              40.241863
            ],
            [
              -75.688526,
              40.236193
            ],
            [
              -75.676059,
              40.242551
            ],
            [
              -75.667161,
              40.245124
            ],
            [
              -75.645407,
              40.239603
            ],
            [
              -75.627792,
              40.232836
            ],
            [
              -75.612373,
              40.222997
            ],
            [
              -75.601356,
              40.23805
            ],
            [
              -75.573338,
              40.195303
            ],
            [
              -75.557148,
              40.207213
            ],
            [
              -75.547419,
              40.186449
            ],
            [
              -75.539581,
              40.174966
            ],
            [
              -75.528941,
              40.168396
            ],
            [
              -75.526838,
              40.149552
            ],
            [
              -75.502718,
              40.159196
            ],
            [
              -75.509237,
              40.136619
            ],
            [
              -75.493688,
              40.126493
            ],
            [
              -75.461599,
              40.124825
            ],
            [
              -75.462633,
              40.11958
            ],
            [
              -75.471584,
              40.112373
            ],
            [
              -75.461729,
              40.103035
            ],
            [
              -75.456501,
              40.088058
            ],
            [
              -75.420124,
              40.097201
            ],
            [
              -75.392632,
              40.060974
            ],
            [
              -75.366687,
              40.072355
            ],
            [
              -75.361066,
              40.065543
            ],
            [
              -75.349457,
              40.052905
            ],
            [
              -75.328531,
              40.026529
            ],
            [
              -75.320699,
              40.016216
            ],
            [
              -75.311432,
              40.01981
            ],
            [
              -75.286766,
              39.989956
            ],
            [
              -75.276482,
              39.976959
            ],
            [
              -75.263687,
              39.982658
            ],
            [
              -75.251438,
              39.989504
            ],
            [
              -75.249214,
              39.990547
            ],
            [
              -75.237331,
              39.996128
            ],
            [
              -75.227462,
              40.000746
            ],
            [
              -75.211548,
              40.008213
            ],
            [
              -75.205771,
              40.011629
            ],
            [
              -75.218056,
              40.020943
            ],
            [
              -75.237762,
              40.031934
            ],
            [
              -75.259621,
              40.049238
            ],
            [
              -75.264431,
              40.054094
            ],
            [
              -75.255006,
              40.062704
            ],
            [
              -75.2305,
              40.086022
            ],
            [
              -75.22361,
              40.092907
            ],
            [
              -75.188461,
              40.07275
            ],
            [
              -75.176438,
              40.084649
            ],
            [
              -75.151596,
              40.070328
            ],
            [
              -75.139427,
              40.063358
            ],
            [
              -75.12632,
              40.055481
            ],
            [
              -75.110985,
              40.046769
            ],
            [
              -75.102413,
              40.051792
            ],
            [
              -75.087425,
              40.063599
            ],
            [
              -75.096733,
              40.068989
            ],
            [
              -75.090317,
              40.075115
            ],
            [
              -75.082456,
              40.082884
            ],
            [
              -75.07127,
              40.092532
            ],
            [
              -75.058342,
              40.105892
            ],
            [
              -75.054321,
              40.108692
            ],
            [
              -75.0493,
              40.112199
            ],
            [
              -75.020571,
              40.132274
            ],
            [
              -75.015066,
              40.137992
            ],
            [
              -75.029301,
              40.146509
            ],
            [
              -75.071198,
              40.171032
            ],
            [
              -75.106878,
              40.192279
            ],
            [
              -75.137782,
              40.210886
            ],
            [
              -75.214133,
              40.257422
            ],
            [
              -75.243171,
              40.274395
            ],
            [
              -75.334614,
              40.328809
            ],
            [
              -75.353219,
              40.340182
            ],
            [
              -75.404327,
              40.370825
            ],
            [
              -75.410402,
              40.3745
            ],
            [
              -75.445647,
              40.396205
            ],
            [
              -75.484057,
              40.418451
            ],
            [
              -75.490104,
              40.422192
            ],
            [
              -75.529694,
              40.446995
            ],
            [
              -75.559753,
              40.409723
            ],
            [
              -75.569274,
              40.397892
            ],
            [
              -75.638828,
              40.312772
            ],
            [
              -75.696782,
              40.241863
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "061",
      "COUNTYNS": "01213672",
      "AFFGEOID": "0500000US42061",
      "GEOID": "42061",
      "NAME": "Huntingdon",
      "LSAD": "06",
      "ALAND": 2265337403,
      "AWATER": 37851955,
      "County_state": "Huntingdon,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.250091,
              40.312291
            ],
            [
              -78.257305,
              40.297883
            ],
            [
              -78.236302,
              40.224559
            ],
            [
              -78.134863,
              40.165131
            ],
            [
              -78.079528,
              40.133884
            ],
            [
              -77.925905,
              40.101911
            ],
            [
              -77.92556,
              40.103977
            ],
            [
              -77.864489,
              40.061568
            ],
            [
              -77.856772,
              40.069231
            ],
            [
              -77.796386,
              40.166814
            ],
            [
              -77.768325,
              40.204129
            ],
            [
              -77.724197,
              40.250124
            ],
            [
              -77.70303,
              40.263576
            ],
            [
              -77.705668,
              40.271312
            ],
            [
              -77.75212,
              40.378545
            ],
            [
              -77.76172,
              40.368733
            ],
            [
              -77.810393,
              40.368591
            ],
            [
              -77.818636,
              40.360542
            ],
            [
              -77.838343,
              40.36903
            ],
            [
              -77.856914,
              40.365228
            ],
            [
              -77.887717,
              40.391358
            ],
            [
              -77.913844,
              40.398679
            ],
            [
              -77.825866,
              40.492816
            ],
            [
              -77.841827,
              40.550035
            ],
            [
              -77.8216,
              40.578931
            ],
            [
              -77.787734,
              40.612082
            ],
            [
              -77.75162,
              40.6323
            ],
            [
              -77.682708,
              40.682717
            ],
            [
              -77.703787,
              40.679936
            ],
            [
              -77.70117,
              40.704982
            ],
            [
              -77.676611,
              40.716452
            ],
            [
              -77.681334,
              40.729758
            ],
            [
              -77.71026,
              40.714632
            ],
            [
              -77.718677,
              40.727372
            ],
            [
              -77.768549,
              40.719466
            ],
            [
              -77.824506,
              40.743681
            ],
            [
              -77.916111,
              40.705439
            ],
            [
              -77.944122,
              40.691622
            ],
            [
              -78.106573,
              40.736745
            ],
            [
              -78.116521,
              40.739127
            ],
            [
              -78.139076,
              40.724275
            ],
            [
              -78.204871,
              40.695874
            ],
            [
              -78.22197,
              40.674539
            ],
            [
              -78.198356,
              40.64809
            ],
            [
              -78.197219,
              40.639351
            ],
            [
              -78.166371,
              40.617948
            ],
            [
              -78.184212,
              40.595295
            ],
            [
              -78.17828,
              40.583786
            ],
            [
              -78.197083,
              40.558444
            ],
            [
              -78.176818,
              40.534471
            ],
            [
              -78.166247,
              40.531401
            ],
            [
              -78.149955,
              40.541366
            ],
            [
              -78.128638,
              40.567953
            ],
            [
              -78.147328,
              40.533251
            ],
            [
              -78.153373,
              40.488558
            ],
            [
              -78.135633,
              40.498214
            ],
            [
              -78.129584,
              40.490626
            ],
            [
              -78.134233,
              40.473242
            ],
            [
              -78.155447,
              40.438543
            ],
            [
              -78.191046,
              40.39187
            ],
            [
              -78.250091,
              40.312291
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "129",
      "COUNTYNS": "01209191",
      "AFFGEOID": "0500000US42129",
      "GEOID": "42129",
      "NAME": "Westmoreland",
      "LSAD": "06",
      "ALAND": 2661370996,
      "AWATER": 22050453,
      "County_state": "Westmoreland,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.904778,
              40.157255
            ],
            [
              -79.90417,
              40.151967
            ],
            [
              -79.886649,
              40.130216
            ],
            [
              -79.877385,
              40.126792
            ],
            [
              -79.87363,
              40.12912
            ],
            [
              -79.780551,
              40.127947
            ],
            [
              -79.744404,
              40.128168
            ],
            [
              -79.72237,
              40.112825
            ],
            [
              -79.686137,
              40.11389
            ],
            [
              -79.659357,
              40.097305
            ],
            [
              -79.638994,
              40.082859
            ],
            [
              -79.622635,
              40.094337
            ],
            [
              -79.593021,
              40.082055
            ],
            [
              -79.578966,
              40.105496
            ],
            [
              -79.540347,
              40.114035
            ],
            [
              -79.535462,
              40.125977
            ],
            [
              -79.527613,
              40.122935
            ],
            [
              -79.505188,
              40.140568
            ],
            [
              -79.483744,
              40.143547
            ],
            [
              -79.447723,
              40.13027
            ],
            [
              -79.43756,
              40.11586
            ],
            [
              -79.40193,
              40.10283
            ],
            [
              -79.393712,
              40.090897
            ],
            [
              -79.355627,
              40.074359
            ],
            [
              -79.34278,
              40.04445
            ],
            [
              -79.293682,
              40.040413
            ],
            [
              -79.278101,
              40.050479
            ],
            [
              -79.255632,
              40.088611
            ],
            [
              -79.226672,
              40.09825
            ],
            [
              -79.210774,
              40.118903
            ],
            [
              -79.185222,
              40.109848
            ],
            [
              -79.163164,
              40.14103
            ],
            [
              -79.161263,
              40.160869
            ],
            [
              -79.140702,
              40.163307
            ],
            [
              -79.143306,
              40.179889
            ],
            [
              -79.125963,
              40.184999
            ],
            [
              -79.088656,
              40.241259
            ],
            [
              -79.056646,
              40.278604
            ],
            [
              -79.055983,
              40.285089
            ],
            [
              -79.054781,
              40.289589
            ],
            [
              -79.037187,
              40.306986
            ],
            [
              -78.974649,
              40.395972
            ],
            [
              -78.98887,
              40.411974
            ],
            [
              -79.016617,
              40.420279
            ],
            [
              -79.033007,
              40.416774
            ],
            [
              -79.027714,
              40.406136
            ],
            [
              -79.058481,
              40.380868
            ],
            [
              -79.082965,
              40.389582
            ],
            [
              -79.096769,
              40.370725
            ],
            [
              -79.121634,
              40.370576
            ],
            [
              -79.12716,
              40.386878
            ],
            [
              -79.117,
              40.394243
            ],
            [
              -79.147892,
              40.3976
            ],
            [
              -79.155789,
              40.411765
            ],
            [
              -79.192722,
              40.414862
            ],
            [
              -79.247568,
              40.43208
            ],
            [
              -79.272856,
              40.418411
            ],
            [
              -79.272605,
              40.439082
            ],
            [
              -79.301284,
              40.435212
            ],
            [
              -79.301311,
              40.451756
            ],
            [
              -79.30245,
              40.461333
            ],
            [
              -79.325409,
              40.452699
            ],
            [
              -79.33844,
              40.469998
            ],
            [
              -79.345926,
              40.454825
            ],
            [
              -79.362275,
              40.460061
            ],
            [
              -79.365061,
              40.477868
            ],
            [
              -79.373741,
              40.453853
            ],
            [
              -79.395461,
              40.457132
            ],
            [
              -79.406631,
              40.474301
            ],
            [
              -79.423574,
              40.473329
            ],
            [
              -79.430628,
              40.489046
            ],
            [
              -79.440943,
              40.484921
            ],
            [
              -79.446694,
              40.480869
            ],
            [
              -79.447537,
              40.480811
            ],
            [
              -79.451747,
              40.482307
            ],
            [
              -79.453765,
              40.48425
            ],
            [
              -79.454011,
              40.485507
            ],
            [
              -79.452575,
              40.489003
            ],
            [
              -79.4599,
              40.517986
            ],
            [
              -79.450176,
              40.530149
            ],
            [
              -79.464289,
              40.53408
            ],
            [
              -79.497092,
              40.525338
            ],
            [
              -79.511912,
              40.542683
            ],
            [
              -79.526249,
              40.542364
            ],
            [
              -79.56111,
              40.564113
            ],
            [
              -79.565932,
              40.593403
            ],
            [
              -79.550608,
              40.603897
            ],
            [
              -79.578455,
              40.601409
            ],
            [
              -79.584375,
              40.607041
            ],
            [
              -79.589575,
              40.616865
            ],
            [
              -79.58738,
              40.62707
            ],
            [
              -79.591656,
              40.629234
            ],
            [
              -79.608133,
              40.62549
            ],
            [
              -79.608952,
              40.625954
            ],
            [
              -79.609946,
              40.628317
            ],
            [
              -79.610135,
              40.631009
            ],
            [
              -79.609288,
              40.633873
            ],
            [
              -79.60892,
              40.637313
            ],
            [
              -79.609496,
              40.639809
            ],
            [
              -79.611032,
              40.642017
            ],
            [
              -79.613128,
              40.643617
            ],
            [
              -79.616808,
              40.644817
            ],
            [
              -79.61818,
              40.644766
            ],
            [
              -79.638137,
              40.665601
            ],
            [
              -79.668345,
              40.680049
            ],
            [
              -79.692587,
              40.669732
            ],
            [
              -79.689117,
              40.642722
            ],
            [
              -79.712477,
              40.620076
            ],
            [
              -79.72127,
              40.607966
            ],
            [
              -79.749187,
              40.59897
            ],
            [
              -79.76377,
              40.592966
            ],
            [
              -79.772285,
              40.583788
            ],
            [
              -79.773331,
              40.580758
            ],
            [
              -79.77437,
              40.569767
            ],
            [
              -79.77187,
              40.562967
            ],
            [
              -79.765946,
              40.550915
            ],
            [
              -79.765415,
              40.549854
            ],
            [
              -79.736615,
              40.549324
            ],
            [
              -79.722424,
              40.542059
            ],
            [
              -79.701711,
              40.525491
            ],
            [
              -79.702142,
              40.489523
            ],
            [
              -79.704503,
              40.434025
            ],
            [
              -79.727665,
              40.412495
            ],
            [
              -79.722151,
              40.409098
            ],
            [
              -79.734707,
              40.397826
            ],
            [
              -79.742497,
              40.396846
            ],
            [
              -79.762068,
              40.392768
            ],
            [
              -79.774412,
              40.359673
            ],
            [
              -79.783806,
              40.315441
            ],
            [
              -79.787853,
              40.297693
            ],
            [
              -79.775139,
              40.287888
            ],
            [
              -79.794715,
              40.281683
            ],
            [
              -79.788015,
              40.2613
            ],
            [
              -79.806311,
              40.233446
            ],
            [
              -79.781761,
              40.227711
            ],
            [
              -79.870585,
              40.197415
            ],
            [
              -79.854456,
              40.191255
            ],
            [
              -79.850584,
              40.174727
            ],
            [
              -79.870668,
              40.162127
            ],
            [
              -79.887337,
              40.164983
            ],
            [
              -79.893306,
              40.164983
            ],
            [
              -79.900346,
              40.162503
            ],
            [
              -79.904778,
              40.157255
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "043",
      "COUNTYNS": "01213667",
      "AFFGEOID": "0500000US42043",
      "GEOID": "42043",
      "NAME": "Dauphin",
      "LSAD": "06",
      "ALAND": 1359893289,
      "AWATER": 85780786,
      "County_state": "Dauphin,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.028645,
              40.391197
            ],
            [
              -77.020799,
              40.36623
            ],
            [
              -77.008191,
              40.358449
            ],
            [
              -76.937547,
              40.357481
            ],
            [
              -76.916811,
              40.331676
            ],
            [
              -76.91497,
              40.328484
            ],
            [
              -76.912356,
              40.324184
            ],
            [
              -76.922134,
              40.302743
            ],
            [
              -76.924291,
              40.289642
            ],
            [
              -76.913911,
              40.271938
            ],
            [
              -76.898186,
              40.255
            ],
            [
              -76.884916,
              40.247265
            ],
            [
              -76.873248,
              40.237681
            ],
            [
              -76.859023,
              40.226328
            ],
            [
              -76.839468,
              40.221615
            ],
            [
              -76.823039,
              40.201704
            ],
            [
              -76.806121,
              40.200618
            ],
            [
              -76.800585,
              40.200111
            ],
            [
              -76.763787,
              40.17947
            ],
            [
              -76.756379,
              40.177052
            ],
            [
              -76.737307,
              40.125333
            ],
            [
              -76.72162,
              40.12007
            ],
            [
              -76.697664,
              40.156342
            ],
            [
              -76.676756,
              40.149463
            ],
            [
              -76.643169,
              40.165625
            ],
            [
              -76.636018,
              40.170062
            ],
            [
              -76.606519,
              40.18999
            ],
            [
              -76.599865,
              40.188264
            ],
            [
              -76.58789,
              40.190559
            ],
            [
              -76.586412,
              40.189484
            ],
            [
              -76.582682,
              40.190952
            ],
            [
              -76.580708,
              40.194687
            ],
            [
              -76.571195,
              40.194401
            ],
            [
              -76.567018,
              40.195554
            ],
            [
              -76.566339,
              40.196644
            ],
            [
              -76.570467,
              40.198434
            ],
            [
              -76.592173,
              40.25428
            ],
            [
              -76.625538,
              40.339273
            ],
            [
              -76.667771,
              40.447888
            ],
            [
              -76.67804,
              40.474717
            ],
            [
              -76.535414,
              40.555157
            ],
            [
              -76.577073,
              40.580677
            ],
            [
              -76.701624,
              40.658082
            ],
            [
              -76.749698,
              40.65315
            ],
            [
              -76.794052,
              40.640544
            ],
            [
              -76.804867,
              40.646839
            ],
            [
              -76.80984,
              40.642047
            ],
            [
              -76.81114,
              40.633817
            ],
            [
              -76.840104,
              40.625439
            ],
            [
              -76.86578,
              40.627244
            ],
            [
              -76.886411,
              40.617758
            ],
            [
              -76.918672,
              40.603466
            ],
            [
              -76.949414,
              40.628163
            ],
            [
              -76.953105,
              40.604504
            ],
            [
              -76.965268,
              40.58706
            ],
            [
              -76.992488,
              40.567534
            ],
            [
              -76.981989,
              40.543415
            ],
            [
              -76.985104,
              40.516349
            ],
            [
              -76.96909,
              40.497562
            ],
            [
              -76.949466,
              40.484527
            ],
            [
              -76.952838,
              40.462444
            ],
            [
              -76.980452,
              40.447136
            ],
            [
              -77.003235,
              40.4268
            ],
            [
              -77.01627,
              40.425173
            ],
            [
              -77.013125,
              40.402349
            ],
            [
              -77.028645,
              40.391197
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "015",
      "COUNTYNS": "01213660",
      "AFFGEOID": "0500000US42015",
      "GEOID": "42015",
      "NAME": "Bradford",
      "LSAD": "06",
      "ALAND": 2971750814,
      "AWATER": 35275587,
      "County_state": "Bradford,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.921884,
              42.001674
            ],
            [
              -76.927084,
              42.001674
            ],
            [
              -76.899315,
              41.809328
            ],
            [
              -76.898607,
              41.794465
            ],
            [
              -76.878699,
              41.620344
            ],
            [
              -76.874714,
              41.596919
            ],
            [
              -76.813731,
              41.590034
            ],
            [
              -76.778814,
              41.586707
            ],
            [
              -76.220139,
              41.541285
            ],
            [
              -76.197998,
              41.647823
            ],
            [
              -76.115172,
              41.651825
            ],
            [
              -76.122003,
              41.73855
            ],
            [
              -76.1455192032297,
              41.9989130371419
            ],
            [
              -76.343722,
              41.998346
            ],
            [
              -76.349898,
              41.99841
            ],
            [
              -76.462155,
              41.998934
            ],
            [
              -76.46654,
              41.999025
            ],
            [
              -76.47303045547619,
              41.9991050870808
            ],
            [
              -76.55762413688969,
              42.000148906120295
            ],
            [
              -76.558118,
              42.000155
            ],
            [
              -76.6381035393041,
              42.000795529019
            ],
            [
              -76.749675,
              42.001689
            ],
            [
              -76.815878,
              42.001673
            ],
            [
              -76.835079,
              42.001773
            ],
            [
              -76.920784,
              42.001774
            ],
            [
              -76.921884,
              42.001674
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "42",
      "COUNTYFP": "055",
      "COUNTYNS": "01213670",
      "AFFGEOID": "0500000US42055",
      "GEOID": "42055",
      "NAME": "Franklin",
      "LSAD": "06",
      "ALAND": 2000052118,
      "AWATER": 1544300,
      "County_state": "Franklin,42"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.09897028757709,
              39.722466543278095
            ],
            [
              -78.075861,
              39.722448
            ],
            [
              -78.073826,
              39.722462
            ],
            [
              -77.874822,
              39.722387
            ],
            [
              -77.7901995700963,
              39.721710358483
            ],
            [
              -77.7895928365247,
              39.7217055070373
            ],
            [
              -77.768644,
              39.721538
            ],
            [
              -77.743315,
              39.721387
            ],
            [
              -77.732727,
              39.721277
            ],
            [
              -77.724228,
              39.721078
            ],
            [
              -77.674638,
              39.721037
            ],
            [
              -77.672365,
              39.720968
            ],
            [
              -77.66955148856,
              39.720955107314396
            ],
            [
              -77.534883,
              39.720338
            ],
            [
              -77.533496,
              39.72037
            ],
            [
              -77.469274,
              39.720229
            ],
            [
              -77.4691450037375,
              39.720229002284896
            ],
            [
              -77.459433419622,
              39.720229174305295
            ],
            [
              -77.465124,
              39.849893
            ],
            [
              -77.471085,
              39.944077
            ],
            [
              -77.454109,
              39.972432
            ],
            [
              -77.495403,
              40.005145
            ],
            [
              -77.519756,
              40.040468
            ],
            [
              -77.532481,
              40.048749
            ],
            [
              -77.534889,
              40.070272
            ],
            [
              -77.559728,
              40.100671
            ],
            [
              -77.562871,
              40.119286
            ],
            [
              -77.608261,
              40.152738
            ],
            [
              -77.627509,
              40.1886
            ],
            [
              -77.614665,
              40.198549
            ],
            [
              -77.602717,
              40.2282
            ],
            [
              -77.616167,
              40.232616
            ],
            [
              -77.639893,
              40.217678
            ],
            [
              -77.649951,
              40.226283
            ],
            [
              -77.645698,
              40.256031
            ],
            [
              -77.667711,
              40.252001
            ],
            [
              -77.656416,
              40.270393
            ],
            [
              -77.671761,
              40.289825
            ],
            [
              -77.684612,
              40.276744
            ],
            [
              -77.70303,
              40.263576
            ],
            [
              -77.724197,
              40.250124
            ],
            [
              -77.768325,
              40.204129
            ],
            [
              -77.796386,
              40.166814
            ],
            [
              -77.856772,
              40.069231
            ],
            [
              -77.864489,
              40.061568
            ],
            [
              -77.883327,
              40.048518
            ],
            [
              -77.919502,
              40.003417
            ],
            [
              -77.944045,
              39.940596
            ],
            [
              -77.956188,
              39.927024
            ],
            [
              -77.956543,
              39.914824
            ],
            [
              -77.975135,
              39.871788
            ],
            [
              -78.0027,
              39.826599
            ],
            [
              -78.028503,
              39.7999
            ],
            [
              -78.064498,
              39.757701
            ],
            [
              -78.069297,
              39.748298
            ],
            [
              -78.09897028757709,
              39.722466543278095
            ]
          ]
        ]
      ]
    }
  }
]
};