export const counties_oh = {
  "type":"FeatureCollection","name":"oh_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "033",
      "COUNTYNS": "01074029",
      "AFFGEOID": "0500000US39033",
      "GEOID": "39033",
      "NAME": "Crawford",
      "LSAD": "06",
      "ALAND": 1040619677,
      "AWATER": 2359515,
      "County_state": "Crawford,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.112737,
              40.993447
            ],
            [
              -83.11136,
              40.702915
            ],
            [
              -83.090577,
              40.70255
            ],
            [
              -82.858302,
              40.705019
            ],
            [
              -82.858197,
              40.712423
            ],
            [
              -82.727164,
              40.711203
            ],
            [
              -82.724792,
              40.995638
            ],
            [
              -82.829512,
              40.996624
            ],
            [
              -82.853184,
              40.993661
            ],
            [
              -83.112737,
              40.993447
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "103",
      "COUNTYNS": "01074064",
      "AFFGEOID": "0500000US39103",
      "GEOID": "39103",
      "NAME": "Medina",
      "LSAD": "06",
      "ALAND": 1091406463,
      "AWATER": 4198457,
      "County_state": "Medina,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.171492,
              41.063537
            ],
            [
              -82.173359,
              40.992046
            ],
            [
              -82.129334,
              40.991807
            ],
            [
              -82.078627,
              40.991153
            ],
            [
              -81.977381,
              40.989961
            ],
            [
              -81.688491,
              40.98859
            ],
            [
              -81.68699,
              41.135956
            ],
            [
              -81.68495,
              41.277146
            ],
            [
              -81.705151,
              41.277254
            ],
            [
              -81.807888,
              41.276051
            ],
            [
              -81.83911,
              41.275562
            ],
            [
              -81.878053,
              41.275044
            ],
            [
              -81.972485,
              41.274829
            ],
            [
              -81.973895,
              41.199831
            ],
            [
              -82.072465,
              41.19985
            ],
            [
              -82.074266,
              41.136456
            ],
            [
              -82.169875,
              41.137097
            ],
            [
              -82.171492,
              41.063537
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "063",
      "COUNTYNS": "01074044",
      "AFFGEOID": "0500000US39063",
      "GEOID": "39063",
      "NAME": "Hancock",
      "LSAD": "06",
      "ALAND": 1376210232,
      "AWATER": 5959837,
      "County_state": "Hancock,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.881149,
              41.167824
            ],
            [
              -83.880423,
              40.920429
            ],
            [
              -83.880063,
              40.819919
            ],
            [
              -83.515883,
              40.818134
            ],
            [
              -83.514728,
              40.905115
            ],
            [
              -83.476741,
              40.904964
            ],
            [
              -83.476793,
              40.948379
            ],
            [
              -83.457681,
              40.948517
            ],
            [
              -83.45785,
              40.991671
            ],
            [
              -83.420319,
              40.991889
            ],
            [
              -83.421152,
              41.130255
            ],
            [
              -83.421054,
              41.16678
            ],
            [
              -83.881149,
              41.167824
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "067",
      "COUNTYNS": "01074046",
      "AFFGEOID": "0500000US39067",
      "GEOID": "39067",
      "NAME": "Harrison",
      "LSAD": "06",
      "ALAND": 1042034970,
      "AWATER": 21847164,
      "County_state": "Harrison,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.338057,
              40.214253
            ],
            [
              -81.339565,
              40.171999
            ],
            [
              -81.225924,
              40.170075
            ],
            [
              -81.220395,
              40.17259
            ],
            [
              -81.109937,
              40.167833
            ],
            [
              -80.882892,
              40.159495
            ],
            [
              -80.867201,
              40.379378
            ],
            [
              -80.865504,
              40.422929
            ],
            [
              -80.941911,
              40.4247
            ],
            [
              -81.100076,
              40.430596
            ],
            [
              -81.217494,
              40.431727
            ],
            [
              -81.269951,
              40.433486
            ],
            [
              -81.275319,
              40.303434
            ],
            [
              -81.334601,
              40.304316
            ],
            [
              -81.338057,
              40.214253
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "083",
      "COUNTYNS": "01074054",
      "AFFGEOID": "0500000US39083",
      "GEOID": "39083",
      "NAME": "Knox",
      "LSAD": "06",
      "ALAND": 1361054324,
      "AWATER": 10718576,
      "County_state": "Knox,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.744931,
              40.349603
            ],
            [
              -82.750747,
              40.276996
            ],
            [
              -82.476114,
              40.264703
            ],
            [
              -82.477273,
              40.24581
            ],
            [
              -82.346317,
              40.240213
            ],
            [
              -82.280942,
              40.239251
            ],
            [
              -82.270174,
              40.240183
            ],
            [
              -82.195911,
              40.239071
            ],
            [
              -82.184453,
              40.452675
            ],
            [
              -82.184607,
              40.45628
            ],
            [
              -82.179069,
              40.573112
            ],
            [
              -82.22011,
              40.568206
            ],
            [
              -82.336962,
              40.555001
            ],
            [
              -82.374898,
              40.550871
            ],
            [
              -82.623609,
              40.549879
            ],
            [
              -82.642796,
              40.550011
            ],
            [
              -82.642837,
              40.522111
            ],
            [
              -82.646503,
              40.345076
            ],
            [
              -82.744931,
              40.349603
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "039",
      "COUNTYNS": "01074032",
      "AFFGEOID": "0500000US39039",
      "GEOID": "39039",
      "NAME": "Defiance",
      "LSAD": "06",
      "ALAND": 1065649891,
      "AWATER": 7073996,
      "County_state": "Defiance,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.804046,
              41.408361
            ],
            [
              -84.804133,
              41.408292
            ],
            [
              -84.803926,
              41.367959
            ],
            [
              -84.8035816909474,
              41.271273026028304
            ],
            [
              -84.803581,
              41.271079
            ],
            [
              -84.80358,
              41.270942
            ],
            [
              -84.80349214742239,
              41.2525618431087
            ],
            [
              -84.457217,
              41.253485
            ],
            [
              -84.457059,
              41.209434
            ],
            [
              -84.341601,
              41.20903
            ],
            [
              -84.341602,
              41.165524
            ],
            [
              -84.228222,
              41.165862
            ],
            [
              -84.228454,
              41.42781
            ],
            [
              -84.341664,
              41.427568
            ],
            [
              -84.8039563404477,
              41.4260444864396
            ],
            [
              -84.804015,
              41.411655
            ],
            [
              -84.804046,
              41.408361
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "057",
      "COUNTYNS": "01074041",
      "AFFGEOID": "0500000US39057",
      "GEOID": "39057",
      "NAME": "Greene",
      "LSAD": "06",
      "ALAND": 1071555583,
      "AWATER": 6535068,
      "County_state": "Greene,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.110569,
              39.618442
            ],
            [
              -84.114195,
              39.577983
            ],
            [
              -84.056908,
              39.574413
            ],
            [
              -83.977005,
              39.569169
            ],
            [
              -83.670196,
              39.550254
            ],
            [
              -83.65333,
              39.716876
            ],
            [
              -83.648847,
              39.765844
            ],
            [
              -83.647169,
              39.773029
            ],
            [
              -83.64661,
              39.775996
            ],
            [
              -83.738899,
              39.781293
            ],
            [
              -83.766656,
              39.791141
            ],
            [
              -83.829374,
              39.797766
            ],
            [
              -83.827425,
              39.822651
            ],
            [
              -83.941289,
              39.828838
            ],
            [
              -83.939583,
              39.843797
            ],
            [
              -84.053736,
              39.850458
            ],
            [
              -84.055238,
              39.835958
            ],
            [
              -84.092938,
              39.838345
            ],
            [
              -84.096937,
              39.793675
            ],
            [
              -84.111935,
              39.626359
            ],
            [
              -84.110569,
              39.618442
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "101",
      "COUNTYNS": "01074063",
      "AFFGEOID": "0500000US39101",
      "GEOID": "39101",
      "NAME": "Marion",
      "LSAD": "06",
      "ALAND": 1045726876,
      "AWATER": 908577,
      "County_state": "Marion,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.419828,
              40.643573
            ],
            [
              -83.420203,
              40.603183
            ],
            [
              -83.415303,
              40.515495
            ],
            [
              -83.418378,
              40.505232
            ],
            [
              -83.248183,
              40.507034
            ],
            [
              -83.248298,
              40.500997
            ],
            [
              -83.248595,
              40.443989
            ],
            [
              -83.165115,
              40.439022
            ],
            [
              -83.020798,
              40.433795
            ],
            [
              -83.016785,
              40.484511
            ],
            [
              -82.958401,
              40.490664
            ],
            [
              -82.958063,
              40.557934
            ],
            [
              -82.957715,
              40.635653
            ],
            [
              -82.859753,
              40.646395
            ],
            [
              -82.858302,
              40.705019
            ],
            [
              -83.090577,
              40.70255
            ],
            [
              -83.11136,
              40.702915
            ],
            [
              -83.304687,
              40.702006
            ],
            [
              -83.304997,
              40.687032
            ],
            [
              -83.419836,
              40.686823
            ],
            [
              -83.419828,
              40.643573
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "149",
      "COUNTYNS": "01074087",
      "AFFGEOID": "0500000US39149",
      "GEOID": "39149",
      "NAME": "Shelby",
      "LSAD": "06",
      "ALAND": 1055921780,
      "AWATER": 7808224,
      "County_state": "Shelby,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.434387,
              40.354534
            ],
            [
              -84.434631,
              40.354259
            ],
            [
              -84.432575,
              40.197037
            ],
            [
              -84.298866,
              40.198318
            ],
            [
              -84.218658,
              40.200004
            ],
            [
              -84.022919,
              40.183945
            ],
            [
              -84.014763,
              40.273459
            ],
            [
              -84.01033,
              40.31718
            ],
            [
              -84.0037,
              40.312927
            ],
            [
              -84.004837,
              40.390384
            ],
            [
              -84.001976,
              40.39363
            ],
            [
              -84.002372,
              40.483115
            ],
            [
              -84.339137,
              40.481076
            ],
            [
              -84.338768,
              40.378742
            ],
            [
              -84.434018,
              40.378246
            ],
            [
              -84.434387,
              40.354534
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "153",
      "COUNTYNS": "01074088",
      "AFFGEOID": "0500000US39153",
      "GEOID": "39153",
      "NAME": "Summit",
      "LSAD": "06",
      "ALAND": 1069192868,
      "AWATER": 18948340,
      "County_state": "Summit,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.68699,
              41.135956
            ],
            [
              -81.688491,
              40.98859
            ],
            [
              -81.647689,
              40.988563
            ],
            [
              -81.647735,
              40.914016
            ],
            [
              -81.536441,
              40.910312
            ],
            [
              -81.420418,
              40.906504
            ],
            [
              -81.416334,
              40.988456
            ],
            [
              -81.393249,
              40.988531
            ],
            [
              -81.393282,
              41.025435
            ],
            [
              -81.393216,
              41.04064
            ],
            [
              -81.392959,
              41.066344
            ],
            [
              -81.392534,
              41.100435
            ],
            [
              -81.392114,
              41.135722
            ],
            [
              -81.391831,
              41.276654
            ],
            [
              -81.391694,
              41.348272
            ],
            [
              -81.450331,
              41.349148
            ],
            [
              -81.517658,
              41.350154
            ],
            [
              -81.536216,
              41.35062
            ],
            [
              -81.598052,
              41.351157
            ],
            [
              -81.588099,
              41.342651
            ],
            [
              -81.585444,
              41.308864
            ],
            [
              -81.573002,
              41.30265
            ],
            [
              -81.565139,
              41.277758
            ],
            [
              -81.590542,
              41.277554
            ],
            [
              -81.67051,
              41.277255
            ],
            [
              -81.68495,
              41.277146
            ],
            [
              -81.68699,
              41.135956
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "161",
      "COUNTYNS": "01074092",
      "AFFGEOID": "0500000US39161",
      "GEOID": "39161",
      "NAME": "Van Wert",
      "LSAD": "06",
      "ALAND": 1059698928,
      "AWATER": 3281853,
      "County_state": "Van Wert,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.80331311272211,
              40.9893939621464
            ],
            [
              -84.803313,
              40.989209
            ],
            [
              -84.8029360779022,
              40.9225675776629
            ],
            [
              -84.802935,
              40.922377
            ],
            [
              -84.80217,
              40.800601
            ],
            [
              -84.802538,
              40.765515
            ],
            [
              -84.802266,
              40.742298
            ],
            [
              -84.802119,
              40.728163
            ],
            [
              -84.80211911064869,
              40.728145936904696
            ],
            [
              -84.456172,
              40.728306
            ],
            [
              -84.45618,
              40.684862
            ],
            [
              -84.396778,
              40.684926
            ],
            [
              -84.397374,
              40.815941
            ],
            [
              -84.340101,
              40.816143
            ],
            [
              -84.340531,
              40.859099
            ],
            [
              -84.340969,
              40.903596
            ],
            [
              -84.398407,
              40.903265
            ],
            [
              -84.399486,
              40.990315
            ],
            [
              -84.80331311272211,
              40.9893939621464
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "165",
      "COUNTYNS": "01074094",
      "AFFGEOID": "0500000US39165",
      "GEOID": "39165",
      "NAME": "Warren",
      "LSAD": "06",
      "ALAND": 1039437339,
      "AWATER": 15502922,
      "County_state": "Warren,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.365232,
              39.589493
            ],
            [
              -84.364736,
              39.543843
            ],
            [
              -84.332038,
              39.548554
            ],
            [
              -84.336751,
              39.496637
            ],
            [
              -84.337645,
              39.482357
            ],
            [
              -84.338534,
              39.47353
            ],
            [
              -84.336719,
              39.455994
            ],
            [
              -84.341405,
              39.412423
            ],
            [
              -84.353209,
              39.292287
            ],
            [
              -84.334105,
              39.293287
            ],
            [
              -84.262461,
              39.288379
            ],
            [
              -84.256506,
              39.27642
            ],
            [
              -84.258902,
              39.273743
            ],
            [
              -84.259431,
              39.270796
            ],
            [
              -84.006782,
              39.255068
            ],
            [
              -83.977005,
              39.569169
            ],
            [
              -84.056908,
              39.574413
            ],
            [
              -84.114195,
              39.577983
            ],
            [
              -84.28534,
              39.589158
            ],
            [
              -84.365232,
              39.589493
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "059",
      "COUNTYNS": "01074042",
      "AFFGEOID": "0500000US39059",
      "GEOID": "39059",
      "NAME": "Guernsey",
      "LSAD": "06",
      "ALAND": 1352631185,
      "AWATER": 15666075,
      "County_state": "Guernsey,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.716276,
              40.152166
            ],
            [
              -81.728611,
              39.931728
            ],
            [
              -81.691104,
              39.930756
            ],
            [
              -81.694146,
              39.842636
            ],
            [
              -81.580313,
              39.839104
            ],
            [
              -81.57859,
              39.897683
            ],
            [
              -81.464108,
              39.89455
            ],
            [
              -81.46275,
              39.9235
            ],
            [
              -81.387312,
              39.921396
            ],
            [
              -81.386006,
              39.950694
            ],
            [
              -81.347838,
              39.949962
            ],
            [
              -81.234053,
              39.95127
            ],
            [
              -81.225924,
              40.170075
            ],
            [
              -81.339565,
              40.171999
            ],
            [
              -81.338057,
              40.214253
            ],
            [
              -81.622683,
              40.221308
            ],
            [
              -81.66759,
              40.222468
            ],
            [
              -81.670899,
              40.150976
            ],
            [
              -81.716276,
              40.152166
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "041",
      "COUNTYNS": "01074033",
      "AFFGEOID": "0500000US39041",
      "GEOID": "39041",
      "NAME": "Delaware",
      "LSAD": "06",
      "ALAND": 1147867060,
      "AWATER": 36610530,
      "County_state": "Delaware,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.248595,
              40.443989
            ],
            [
              -83.248428,
              40.244466
            ],
            [
              -83.17233,
              40.244257
            ],
            [
              -83.169981,
              40.143087
            ],
            [
              -83.017012,
              40.136747
            ],
            [
              -83.011329,
              40.136339
            ],
            [
              -83.005204,
              40.136336
            ],
            [
              -82.971307,
              40.134933
            ],
            [
              -82.761827,
              40.125855
            ],
            [
              -82.750747,
              40.276996
            ],
            [
              -82.744931,
              40.349603
            ],
            [
              -82.929588,
              40.358123
            ],
            [
              -82.924781,
              40.415004
            ],
            [
              -83.022079,
              40.418884
            ],
            [
              -83.020798,
              40.433795
            ],
            [
              -83.165115,
              40.439022
            ],
            [
              -83.248595,
              40.443989
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "055",
      "COUNTYNS": "01074040",
      "AFFGEOID": "0500000US39055",
      "GEOID": "39055",
      "NAME": "Geauga",
      "LSAD": "06",
      "ALAND": 1036429792,
      "AWATER": 21045380,
      "County_state": "Geauga,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.391764,
              41.424269
            ],
            [
              -81.391694,
              41.348272
            ],
            [
              -81.003319,
              41.34786
            ],
            [
              -81.003125,
              41.501685
            ],
            [
              -81.002836,
              41.571342
            ],
            [
              -81.003631,
              41.715137
            ],
            [
              -81.102258,
              41.714307
            ],
            [
              -81.101422,
              41.641457
            ],
            [
              -81.190921,
              41.641335
            ],
            [
              -81.294669,
              41.640889
            ],
            [
              -81.29588,
              41.56995
            ],
            [
              -81.391005,
              41.569716
            ],
            [
              -81.391518,
              41.445222
            ],
            [
              -81.374992,
              41.445286
            ],
            [
              -81.374772,
              41.424427
            ],
            [
              -81.391764,
              41.424269
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "129",
      "COUNTYNS": "01074077",
      "AFFGEOID": "0500000US39129",
      "GEOID": "39129",
      "NAME": "Pickaway",
      "LSAD": "06",
      "ALAND": 1297966523,
      "AWATER": 13998877,
      "County_state": "Pickaway,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.252435,
              39.695438
            ],
            [
              -83.266737,
              39.516249
            ],
            [
              -82.992597,
              39.506388
            ],
            [
              -82.978741,
              39.500854
            ],
            [
              -82.980696,
              39.489861
            ],
            [
              -82.998204,
              39.480061
            ],
            [
              -82.740729,
              39.46835
            ],
            [
              -82.731518,
              39.554445
            ],
            [
              -82.842953,
              39.56148
            ],
            [
              -82.837259,
              39.6546
            ],
            [
              -82.824254,
              39.794996
            ],
            [
              -83.013071,
              39.804394
            ],
            [
              -83.120355,
              39.807823
            ],
            [
              -83.243702,
              39.812503
            ],
            [
              -83.252435,
              39.695438
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "133",
      "COUNTYNS": "01074079",
      "AFFGEOID": "0500000US39133",
      "GEOID": "39133",
      "NAME": "Portage",
      "LSAD": "06",
      "ALAND": 1262374971,
      "AWATER": 43121843,
      "County_state": "Portage,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.393282,
              41.025435
            ],
            [
              -81.393249,
              40.988531
            ],
            [
              -81.357529,
              40.988717
            ],
            [
              -81.203146,
              40.987989
            ],
            [
              -81.195679,
              40.988205
            ],
            [
              -81.183632,
              40.988209
            ],
            [
              -81.086312,
              40.988031
            ],
            [
              -81.001695,
              40.987783
            ],
            [
              -81.00236,
              41.062093
            ],
            [
              -81.00229,
              41.134189
            ],
            [
              -81.002614,
              41.200984
            ],
            [
              -81.002621,
              41.202554
            ],
            [
              -81.00288,
              41.271842
            ],
            [
              -81.003191,
              41.347859
            ],
            [
              -81.003319,
              41.34786
            ],
            [
              -81.391694,
              41.348272
            ],
            [
              -81.391831,
              41.276654
            ],
            [
              -81.392114,
              41.135722
            ],
            [
              -81.392534,
              41.100435
            ],
            [
              -81.392959,
              41.066344
            ],
            [
              -81.393216,
              41.04064
            ],
            [
              -81.393282,
              41.025435
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "089",
      "COUNTYNS": "01074057",
      "AFFGEOID": "0500000US39089",
      "GEOID": "39089",
      "NAME": "Licking",
      "LSAD": "06",
      "ALAND": 1767338086,
      "AWATER": 12924074,
      "County_state": "Licking,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.773825,
              39.976125
            ],
            [
              -82.782495,
              39.939662
            ],
            [
              -82.77287,
              39.939326
            ],
            [
              -82.473423,
              39.925065
            ],
            [
              -82.462813,
              39.93038
            ],
            [
              -82.418179,
              39.927936
            ],
            [
              -82.418666,
              39.922447
            ],
            [
              -82.233974,
              39.91326
            ],
            [
              -82.231695,
              39.951075
            ],
            [
              -82.198772,
              39.95014
            ],
            [
              -82.194786,
              40.020694
            ],
            [
              -82.187105,
              40.16688
            ],
            [
              -82.182823,
              40.238624
            ],
            [
              -82.195911,
              40.239071
            ],
            [
              -82.270174,
              40.240183
            ],
            [
              -82.280942,
              40.239251
            ],
            [
              -82.346317,
              40.240213
            ],
            [
              -82.477273,
              40.24581
            ],
            [
              -82.476114,
              40.264703
            ],
            [
              -82.750747,
              40.276996
            ],
            [
              -82.761827,
              40.125855
            ],
            [
              -82.767483,
              40.051028
            ],
            [
              -82.773825,
              39.976125
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "037",
      "COUNTYNS": "01074031",
      "AFFGEOID": "0500000US39037",
      "GEOID": "39037",
      "NAME": "Darke",
      "LSAD": "06",
      "ALAND": 1549071817,
      "AWATER": 4245786,
      "County_state": "Darke,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.812411,
              39.916916
            ],
            [
              -84.8124110112002,
              39.916915011456894
            ],
            [
              -84.485367,
              39.918491
            ],
            [
              -84.425902,
              39.919622
            ],
            [
              -84.431833,
              40.166777
            ],
            [
              -84.436332,
              40.180801
            ],
            [
              -84.432575,
              40.197037
            ],
            [
              -84.434631,
              40.354259
            ],
            [
              -84.599588,
              40.353209
            ],
            [
              -84.8041185884184,
              40.3527569382653
            ],
            [
              -84.803917,
              40.310115
            ],
            [
              -84.8039175091217,
              40.310093574693596
            ],
            [
              -84.804098,
              40.302498
            ],
            [
              -84.805627,
              40.223659
            ],
            [
              -84.806175,
              40.197995
            ],
            [
              -84.80634,
              40.192327
            ],
            [
              -84.806347,
              40.192252
            ],
            [
              -84.806766,
              40.180128
            ],
            [
              -84.808291,
              40.129027
            ],
            [
              -84.808305,
              40.127018
            ],
            [
              -84.808706,
              40.107216
            ],
            [
              -84.80896373948549,
              40.092644844432
            ],
            [
              -84.809737,
              40.048929
            ],
            [
              -84.810099,
              40.034214
            ],
            [
              -84.8109329724325,
              40.005078914562695
            ],
            [
              -84.811212,
              39.995331
            ],
            [
              -84.812193,
              39.92734
            ],
            [
              -84.812357,
              39.921764
            ],
            [
              -84.812411,
              39.916916
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "143",
      "COUNTYNS": "01074084",
      "AFFGEOID": "0500000US39143",
      "GEOID": "39143",
      "NAME": "Sandusky",
      "LSAD": "06",
      "ALAND": 1057906601,
      "AWATER": 23958602,
      "County_state": "Sandusky,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.414626,
              41.500228
            ],
            [
              -83.419843,
              41.254003
            ],
            [
              -83.32316,
              41.254889
            ],
            [
              -83.249512,
              41.253016
            ],
            [
              -83.075296,
              41.256903
            ],
            [
              -82.840087,
              41.255337
            ],
            [
              -82.841475,
              41.290023
            ],
            [
              -82.844565,
              41.362394
            ],
            [
              -82.847804,
              41.430516
            ],
            [
              -82.903078,
              41.430471
            ],
            [
              -82.952936,
              41.430172
            ],
            [
              -82.952224,
              41.458239
            ],
            [
              -83.03744,
              41.457533
            ],
            [
              -83.038646,
              41.457515
            ],
            [
              -83.067459,
              41.457169
            ],
            [
              -83.338196,
              41.457508
            ],
            [
              -83.338991,
              41.501189
            ],
            [
              -83.414626,
              41.500228
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "075",
      "COUNTYNS": "01074050",
      "AFFGEOID": "0500000US39075",
      "GEOID": "39075",
      "NAME": "Holmes",
      "LSAD": "06",
      "ALAND": 1094356029,
      "AWATER": 3745075,
      "County_state": "Holmes,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.220665,
              40.66758
            ],
            [
              -82.220332,
              40.594707
            ],
            [
              -82.22011,
              40.568206
            ],
            [
              -82.179069,
              40.573112
            ],
            [
              -82.184607,
              40.45628
            ],
            [
              -82.0902,
              40.452918
            ],
            [
              -82.085885,
              40.454101
            ],
            [
              -81.709132,
              40.444775
            ],
            [
              -81.669654,
              40.443866
            ],
            [
              -81.668971,
              40.632863
            ],
            [
              -81.649199,
              40.635106
            ],
            [
              -81.650045,
              40.668117
            ],
            [
              -81.876904,
              40.667187
            ],
            [
              -82.126199,
              40.668229
            ],
            [
              -82.220665,
              40.66758
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "073",
      "COUNTYNS": "01074049",
      "AFFGEOID": "0500000US39073",
      "GEOID": "39073",
      "NAME": "Hocking",
      "LSAD": "06",
      "ALAND": 1091232274,
      "AWATER": 5959003,
      "County_state": "Hocking,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.740729,
              39.46835
            ],
            [
              -82.748591,
              39.368165
            ],
            [
              -82.635686,
              39.361566
            ],
            [
              -82.634386,
              39.375966
            ],
            [
              -82.51758,
              39.369468
            ],
            [
              -82.514479,
              39.395767
            ],
            [
              -82.40223,
              39.390228
            ],
            [
              -82.289663,
              39.384195
            ],
            [
              -82.28041,
              39.472906
            ],
            [
              -82.167379,
              39.466307
            ],
            [
              -82.159161,
              39.556574
            ],
            [
              -82.263566,
              39.562137
            ],
            [
              -82.261954,
              39.590592
            ],
            [
              -82.37989,
              39.596738
            ],
            [
              -82.374531,
              39.654959
            ],
            [
              -82.490329,
              39.661704
            ],
            [
              -82.496123,
              39.602849
            ],
            [
              -82.617546,
              39.608605
            ],
            [
              -82.620088,
              39.563987
            ],
            [
              -82.730272,
              39.569066
            ],
            [
              -82.731518,
              39.554445
            ],
            [
              -82.740729,
              39.46835
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "095",
      "COUNTYNS": "01074060",
      "AFFGEOID": "0500000US39095",
      "GEOID": "39095",
      "NAME": "Lucas",
      "LSAD": "06",
      "ALAND": 883129493,
      "AWATER": 660199684,
      "County_state": "Lucas,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.882943,
              41.487543
            ],
            [
              -83.883234,
              41.414503
            ],
            [
              -83.85426,
              41.414451
            ],
            [
              -83.84562,
              41.425028
            ],
            [
              -83.781681,
              41.449341
            ],
            [
              -83.773282,
              41.463616
            ],
            [
              -83.747217,
              41.466216
            ],
            [
              -83.732649,
              41.484684
            ],
            [
              -83.712432,
              41.492856
            ],
            [
              -83.703336,
              41.528952
            ],
            [
              -83.625634,
              41.570051
            ],
            [
              -83.612734,
              41.570751
            ],
            [
              -83.588034,
              41.60095
            ],
            [
              -83.569934,
              41.61715
            ],
            [
              -83.415919,
              41.618938
            ],
            [
              -83.238355,
              41.623246
            ],
            [
              -83.182375,
              41.623154
            ],
            [
              -83.1657126966893,
              41.623245528659595
            ],
            [
              -83.194524,
              41.631008
            ],
            [
              -83.2157390263666,
              41.6385545989418
            ],
            [
              -83.23166,
              41.644218
            ],
            [
              -83.23252733148809,
              41.6451408428282
            ],
            [
              -83.238191,
              41.651167
            ],
            [
              -83.278455,
              41.672078
            ],
            [
              -83.29068,
              41.676794
            ],
            [
              -83.293928,
              41.680846
            ],
            [
              -83.326825,
              41.701562
            ],
            [
              -83.337977,
              41.70341
            ],
            [
              -83.337985,
              41.698682
            ],
            [
              -83.341817,
              41.693518
            ],
            [
              -83.357073,
              41.687763
            ],
            [
              -83.37573,
              41.686647
            ],
            [
              -83.39263,
              41.691947
            ],
            [
              -83.409531,
              41.691247
            ],
            [
              -83.4426247709506,
              41.7053907995351
            ],
            [
              -83.4455141901205,
              41.706625695539294
            ],
            [
              -83.446032,
              41.706847
            ],
            [
              -83.449001,
              41.710719
            ],
            [
              -83.455626,
              41.727445
            ],
            [
              -83.453832,
              41.732647
            ],
            [
              -83.497733,
              41.731847
            ],
            [
              -83.499733,
              41.731647
            ],
            [
              -83.503433,
              41.731547
            ],
            [
              -83.504334,
              41.731547
            ],
            [
              -83.585235,
              41.729348
            ],
            [
              -83.58554441405401,
              41.7293408043243
            ],
            [
              -83.593835,
              41.729148
            ],
            [
              -83.595235,
              41.729148
            ],
            [
              -83.636636,
              41.727849
            ],
            [
              -83.639636,
              41.727749
            ],
            [
              -83.64955722729461,
              41.727447225092696
            ],
            [
              -83.665937,
              41.726949
            ],
            [
              -83.685337,
              41.726449
            ],
            [
              -83.708937,
              41.72515
            ],
            [
              -83.763038,
              41.72355
            ],
            [
              -83.7631498887765,
              41.7235473333038
            ],
            [
              -83.859541,
              41.72125
            ],
            [
              -83.8803872579474,
              41.720089447778804
            ],
            [
              -83.877368,
              41.617547
            ],
            [
              -83.882487,
              41.599801
            ],
            [
              -83.882943,
              41.487543
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "105",
      "COUNTYNS": "01074065",
      "AFFGEOID": "0500000US39105",
      "GEOID": "39105",
      "NAME": "Meigs",
      "LSAD": "06",
      "ALAND": 1113958478,
      "AWATER": 7519824,
      "County_state": "Meigs,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.322874,
              39.027674
            ],
            [
              -82.323894,
              39.013154
            ],
            [
              -82.095465,
              39.002778
            ],
            [
              -82.0988733706356,
              38.9583193160251
            ],
            [
              -82.094865,
              38.964578
            ],
            [
              -82.093165,
              38.97098
            ],
            [
              -82.091565,
              38.973778
            ],
            [
              -82.089065,
              38.975978
            ],
            [
              -82.079364,
              38.981078
            ],
            [
              -82.068864,
              38.984878
            ],
            [
              -82.058764,
              38.990078
            ],
            [
              -82.051563,
              38.994378
            ],
            [
              -82.049163,
              38.997278
            ],
            [
              -82.045663,
              39.003778
            ],
            [
              -82.041563,
              39.017878
            ],
            [
              -82.038763,
              39.022678
            ],
            [
              -82.035963,
              39.025478
            ],
            [
              -82.027262,
              39.028378
            ],
            [
              -82.017562,
              39.030078
            ],
            [
              -82.007062,
              39.029578
            ],
            [
              -82.002261,
              39.027878
            ],
            [
              -81.994961,
              39.022478
            ],
            [
              -81.991361,
              39.018378
            ],
            [
              -81.987061,
              39.011978
            ],
            [
              -81.983761,
              39.005478
            ],
            [
              -81.982761,
              39.001978
            ],
            [
              -81.982032,
              38.995697
            ],
            [
              -81.981158,
              38.994351
            ],
            [
              -81.979371,
              38.993193
            ],
            [
              -81.977455,
              38.992679
            ],
            [
              -81.973871,
              38.992413
            ],
            [
              -81.967769,
              38.992955
            ],
            [
              -81.960832,
              38.994275
            ],
            [
              -81.95926,
              38.995227
            ],
            [
              -81.951447,
              38.996032
            ],
            [
              -81.941829,
              38.993295
            ],
            [
              -81.936828,
              38.990414
            ],
            [
              -81.933186,
              38.987659
            ],
            [
              -81.926561,
              38.977508
            ],
            [
              -81.918214,
              38.966595
            ],
            [
              -81.912443,
              38.954343
            ],
            [
              -81.9066,
              38.945262
            ],
            [
              -81.900595,
              38.937671
            ],
            [
              -81.89847,
              38.929603
            ],
            [
              -81.899953,
              38.925405
            ],
            [
              -81.90091,
              38.924338
            ],
            [
              -81.908341,
              38.917403
            ],
            [
              -81.911936,
              38.915564
            ],
            [
              -81.917757,
              38.910604
            ],
            [
              -81.919098,
              38.908615
            ],
            [
              -81.926671,
              38.901311
            ],
            [
              -81.928352,
              38.895371
            ],
            [
              -81.928,
              38.893492
            ],
            [
              -81.926967,
              38.891602
            ],
            [
              -81.915898,
              38.88427
            ],
            [
              -81.910312,
              38.879294
            ],
            [
              -81.908645,
              38.87846
            ],
            [
              -81.898541,
              38.874582
            ],
            [
              -81.892837,
              38.873869
            ],
            [
              -81.889233,
              38.874279
            ],
            [
              -81.874857,
              38.881174
            ],
            [
              -81.858921,
              38.89019
            ],
            [
              -81.855971,
              38.892734
            ],
            [
              -81.848653,
              38.901407
            ],
            [
              -81.845312,
              38.910088
            ],
            [
              -81.84607,
              38.913192
            ],
            [
              -81.844486,
              38.928746
            ],
            [
              -81.838067,
              38.937135
            ],
            [
              -81.831516,
              38.943697
            ],
            [
              -81.827354,
              38.945898
            ],
            [
              -81.825026,
              38.946603
            ],
            [
              -81.819692,
              38.947016
            ],
            [
              -81.814235,
              38.946168
            ],
            [
              -81.806137,
              38.942112
            ],
            [
              -81.796376,
              38.932498
            ],
            [
              -81.793372,
              38.930204
            ],
            [
              -81.785647,
              38.926193
            ],
            [
              -81.781248,
              38.924804
            ],
            [
              -81.774106,
              38.922965
            ],
            [
              -81.76976,
              38.92273
            ],
            [
              -81.766227,
              38.922946
            ],
            [
              -81.762659,
              38.924121
            ],
            [
              -81.759995,
              38.925828
            ],
            [
              -81.758506,
              38.927942
            ],
            [
              -81.756131,
              38.933545
            ],
            [
              -81.756975,
              38.937152
            ],
            [
              -81.769703,
              38.948707
            ],
            [
              -81.77396,
              38.951645
            ],
            [
              -81.778845,
              38.955892
            ],
            [
              -81.780736,
              38.958975
            ],
            [
              -81.7815788388276,
              38.9636090584987
            ],
            [
              -81.78182,
              38.964935
            ],
            [
              -81.779883,
              38.972773
            ],
            [
              -81.775734,
              38.980737
            ],
            [
              -81.776466,
              38.982048
            ],
            [
              -81.776723,
              38.985142
            ],
            [
              -81.775551,
              38.990107
            ],
            [
              -81.774062,
              38.993682
            ],
            [
              -81.771975,
              38.996845
            ],
            [
              -81.767188,
              39.000115
            ],
            [
              -81.765153,
              39.002579
            ],
            [
              -81.764253,
              39.006579
            ],
            [
              -81.764253,
              39.015279
            ],
            [
              -81.767253,
              39.019979
            ],
            [
              -81.772854,
              39.026179
            ],
            [
              -81.786554,
              39.036579
            ],
            [
              -81.793304,
              39.040353
            ],
            [
              -81.800355,
              39.044978
            ],
            [
              -81.803355,
              39.047678
            ],
            [
              -81.808955,
              39.055178
            ],
            [
              -81.811655,
              39.059578
            ],
            [
              -81.813355,
              39.065878
            ],
            [
              -81.814155,
              39.073478
            ],
            [
              -81.813855,
              39.079278
            ],
            [
              -81.812355,
              39.082078
            ],
            [
              -81.810655,
              39.083278
            ],
            [
              -81.807855,
              39.083978
            ],
            [
              -81.803055,
              39.083878
            ],
            [
              -81.798155,
              39.082878
            ],
            [
              -81.790754,
              39.079778
            ],
            [
              -81.785554,
              39.078578
            ],
            [
              -81.781454,
              39.078078
            ],
            [
              -81.775554,
              39.078378
            ],
            [
              -81.764854,
              39.081978
            ],
            [
              -81.760753,
              39.084078
            ],
            [
              -81.752353,
              39.089878
            ],
            [
              -81.747253,
              39.095378
            ],
            [
              -81.74725270065791,
              39.0953784490132
            ],
            [
              -81.745453,
              39.098078
            ],
            [
              -81.743853,
              39.102378
            ],
            [
              -81.742953,
              39.106578
            ],
            [
              -81.742153,
              39.116777
            ],
            [
              -81.744568,
              39.126816
            ],
            [
              -81.744838,
              39.130898
            ],
            [
              -81.744525,
              39.138829
            ],
            [
              -81.743565,
              39.141933
            ],
            [
              -81.744621,
              39.148413
            ],
            [
              -81.754254,
              39.171476
            ],
            [
              -81.756254,
              39.177276
            ],
            [
              -81.7558150545194,
              39.180524196556696
            ],
            [
              -81.960882,
              39.188744
            ],
            [
              -82.268583,
              39.20376
            ],
            [
              -82.306669,
              39.205494
            ],
            [
              -82.322874,
              39.027674
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "053",
      "COUNTYNS": "01074039",
      "AFFGEOID": "0500000US39053",
      "GEOID": "39053",
      "NAME": "Gallia",
      "LSAD": "06",
      "ALAND": 1208307867,
      "AWATER": 12085303,
      "County_state": "Gallia,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.575583,
              38.844478
            ],
            [
              -82.582683,
              38.779077
            ],
            [
              -82.483579,
              38.772278
            ],
            [
              -82.486579,
              38.742978
            ],
            [
              -82.466878,
              38.741478
            ],
            [
              -82.472478,
              38.682279
            ],
            [
              -82.354468,
              38.67607
            ],
            [
              -82.361674,
              38.585182
            ],
            [
              -82.2871015269719,
              38.5825881557857
            ],
            [
              -82.27427,
              38.593683
            ],
            [
              -82.27147,
              38.595383
            ],
            [
              -82.26207,
              38.598183
            ],
            [
              -82.252469,
              38.598783
            ],
            [
              -82.245969,
              38.598483
            ],
            [
              -82.222168,
              38.591384
            ],
            [
              -82.218967,
              38.591683
            ],
            [
              -82.205171,
              38.591719
            ],
            [
              -82.193824,
              38.593096
            ],
            [
              -82.188767,
              38.594984
            ],
            [
              -82.181967,
              38.599384
            ],
            [
              -82.177267,
              38.603784
            ],
            [
              -82.175167,
              38.608484
            ],
            [
              -82.172066,
              38.619284
            ],
            [
              -82.172066,
              38.625984
            ],
            [
              -82.172667,
              38.629684
            ],
            [
              -82.174267,
              38.633183
            ],
            [
              -82.176767,
              38.642783
            ],
            [
              -82.179067,
              38.648883
            ],
            [
              -82.185567,
              38.659583
            ],
            [
              -82.186067,
              38.666783
            ],
            [
              -82.187667,
              38.672683
            ],
            [
              -82.190867,
              38.680383
            ],
            [
              -82.190167,
              38.687382
            ],
            [
              -82.185067,
              38.699182
            ],
            [
              -82.182867,
              38.705482
            ],
            [
              -82.182467,
              38.708782
            ],
            [
              -82.184567,
              38.715882
            ],
            [
              -82.186568,
              38.722482
            ],
            [
              -82.188268,
              38.734082
            ],
            [
              -82.189668,
              38.737382
            ],
            [
              -82.193268,
              38.741182
            ],
            [
              -82.195606,
              38.752441
            ],
            [
              -82.198882,
              38.757725
            ],
            [
              -82.201537,
              38.760372
            ],
            [
              -82.207141,
              38.763943
            ],
            [
              -82.216614,
              38.76835
            ],
            [
              -82.220449,
              38.773739
            ],
            [
              -82.221518,
              38.77981
            ],
            [
              -82.221566,
              38.787187
            ],
            [
              -82.217269,
              38.79568
            ],
            [
              -82.214494,
              38.798691
            ],
            [
              -82.20929,
              38.802672
            ],
            [
              -82.19928,
              38.808688
            ],
            [
              -82.191172,
              38.815137
            ],
            [
              -82.179478,
              38.817376
            ],
            [
              -82.165898,
              38.822437
            ],
            [
              -82.16157,
              38.824632
            ],
            [
              -82.147667,
              38.83698
            ],
            [
              -82.144867,
              38.84048
            ],
            [
              -82.141616,
              38.851394
            ],
            [
              -82.139224,
              38.86502
            ],
            [
              -82.139988,
              38.870345
            ],
            [
              -82.1410712160353,
              38.873693077098594
            ],
            [
              -82.142167,
              38.87708
            ],
            [
              -82.145267,
              38.883479
            ],
            [
              -82.144567,
              38.891679
            ],
            [
              -82.143167,
              38.898079
            ],
            [
              -82.134766,
              38.905579
            ],
            [
              -82.128866,
              38.909979
            ],
            [
              -82.120966,
              38.921079
            ],
            [
              -82.111666,
              38.932579
            ],
            [
              -82.110565,
              38.935279
            ],
            [
              -82.110866,
              38.940379
            ],
            [
              -82.109065,
              38.945579
            ],
            [
              -82.100565,
              38.955678
            ],
            [
              -82.0988733706356,
              38.9583193160251
            ],
            [
              -82.095465,
              39.002778
            ],
            [
              -82.323894,
              39.013154
            ],
            [
              -82.322874,
              39.027674
            ],
            [
              -82.435309,
              39.035079
            ],
            [
              -82.455179,
              38.844577
            ],
            [
              -82.574383,
              38.853475
            ],
            [
              -82.575583,
              38.844478
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "077",
      "COUNTYNS": "01074051",
      "AFFGEOID": "0500000US39077",
      "GEOID": "39077",
      "NAME": "Huron",
      "LSAD": "06",
      "ALAND": 1272965337,
      "AWATER": 8615925,
      "County_state": "Huron,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.841475,
              41.290023
            ],
            [
              -82.840087,
              41.255337
            ],
            [
              -82.838538,
              41.216999
            ],
            [
              -82.829512,
              40.996624
            ],
            [
              -82.724792,
              40.995638
            ],
            [
              -82.432852,
              40.992944
            ],
            [
              -82.436916,
              41.065379
            ],
            [
              -82.336496,
              41.065761
            ],
            [
              -82.340616,
              41.137899
            ],
            [
              -82.341285,
              41.187947
            ],
            [
              -82.344086,
              41.202152
            ],
            [
              -82.344003,
              41.211266
            ],
            [
              -82.342314,
              41.283554
            ],
            [
              -82.841475,
              41.290023
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "019",
      "COUNTYNS": "01074022",
      "AFFGEOID": "0500000US39019",
      "GEOID": "39019",
      "NAME": "Carroll",
      "LSAD": "06",
      "ALAND": 1022030958,
      "AWATER": 11192655,
      "County_state": "Carroll,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.317739,
              40.651579
            ],
            [
              -81.321659,
              40.566648
            ],
            [
              -81.264744,
              40.565042
            ],
            [
              -81.268434,
              40.476714
            ],
            [
              -81.269951,
              40.433486
            ],
            [
              -81.217494,
              40.431727
            ],
            [
              -81.100076,
              40.430596
            ],
            [
              -80.941911,
              40.4247
            ],
            [
              -80.940153,
              40.468349
            ],
            [
              -80.924566,
              40.467975
            ],
            [
              -80.92092,
              40.556315
            ],
            [
              -80.863567,
              40.555411
            ],
            [
              -80.861994,
              40.599404
            ],
            [
              -80.860822,
              40.64257
            ],
            [
              -80.918125,
              40.643464
            ],
            [
              -80.917172,
              40.726934
            ],
            [
              -81.087289,
              40.727816
            ],
            [
              -81.198599,
              40.729172
            ],
            [
              -81.19909,
              40.722208
            ],
            [
              -81.237227,
              40.723542
            ],
            [
              -81.24125,
              40.650534
            ],
            [
              -81.317739,
              40.651579
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "091",
      "COUNTYNS": "01074058",
      "AFFGEOID": "0500000US39091",
      "GEOID": "39091",
      "NAME": "Logan",
      "LSAD": "06",
      "ALAND": 1187337620,
      "AWATER": 21597586,
      "County_state": "Logan,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.01033,
              40.31718
            ],
            [
              -84.014763,
              40.273459
            ],
            [
              -83.784196,
              40.260046
            ],
            [
              -83.785354,
              40.245213
            ],
            [
              -83.551338,
              40.22937
            ],
            [
              -83.532816,
              40.396802
            ],
            [
              -83.520227,
              40.504077
            ],
            [
              -83.520036,
              40.509287
            ],
            [
              -83.855915,
              40.530438
            ],
            [
              -83.879838,
              40.531861
            ],
            [
              -83.879932,
              40.538708
            ],
            [
              -83.908535,
              40.53539
            ],
            [
              -83.993699,
              40.535201
            ],
            [
              -83.993781,
              40.483004
            ],
            [
              -84.002372,
              40.483115
            ],
            [
              -84.001976,
              40.39363
            ],
            [
              -84.004837,
              40.390384
            ],
            [
              -84.0037,
              40.312927
            ],
            [
              -84.01033,
              40.31718
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "159",
      "COUNTYNS": "01074091",
      "AFFGEOID": "0500000US39159",
      "GEOID": "39159",
      "NAME": "Union",
      "LSAD": "06",
      "ALAND": 1118204745,
      "AWATER": 13288624,
      "County_state": "Union,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.532816,
              40.396802
            ],
            [
              -83.551338,
              40.22937
            ],
            [
              -83.494498,
              40.225467
            ],
            [
              -83.503714,
              40.111468
            ],
            [
              -83.206273,
              40.107733
            ],
            [
              -83.170208,
              40.106927
            ],
            [
              -83.169981,
              40.143087
            ],
            [
              -83.17233,
              40.244257
            ],
            [
              -83.248428,
              40.244466
            ],
            [
              -83.248595,
              40.443989
            ],
            [
              -83.248298,
              40.500997
            ],
            [
              -83.248183,
              40.507034
            ],
            [
              -83.418378,
              40.505232
            ],
            [
              -83.520227,
              40.504077
            ],
            [
              -83.532816,
              40.396802
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "011",
      "COUNTYNS": "01074019",
      "AFFGEOID": "0500000US39011",
      "GEOID": "39011",
      "NAME": "Auglaize",
      "LSAD": "06",
      "ALAND": 1039603387,
      "AWATER": 1345186,
      "County_state": "Auglaize,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.45618,
              40.684862
            ],
            [
              -84.455713,
              40.567888
            ],
            [
              -84.455617,
              40.553249
            ],
            [
              -84.455029,
              40.451305
            ],
            [
              -84.455418,
              40.358589
            ],
            [
              -84.434387,
              40.354534
            ],
            [
              -84.434018,
              40.378246
            ],
            [
              -84.338768,
              40.378742
            ],
            [
              -84.339137,
              40.481076
            ],
            [
              -84.002372,
              40.483115
            ],
            [
              -83.993781,
              40.483004
            ],
            [
              -83.993699,
              40.535201
            ],
            [
              -83.908535,
              40.53539
            ],
            [
              -83.879932,
              40.538708
            ],
            [
              -83.880194,
              40.64469
            ],
            [
              -84.107787,
              40.643069
            ],
            [
              -84.107908,
              40.657938
            ],
            [
              -84.222749,
              40.656948
            ],
            [
              -84.222799,
              40.685957
            ],
            [
              -84.396778,
              40.684926
            ],
            [
              -84.45618,
              40.684862
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "139",
      "COUNTYNS": "01074082",
      "AFFGEOID": "0500000US39139",
      "GEOID": "39139",
      "NAME": "Richland",
      "LSAD": "06",
      "ALAND": 1282720846,
      "AWATER": 12470881,
      "County_state": "Richland,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.724792,
              40.995638
            ],
            [
              -82.727164,
              40.711203
            ],
            [
              -82.689113,
              40.711033
            ],
            [
              -82.627767,
              40.709352
            ],
            [
              -82.626165,
              40.666132
            ],
            [
              -82.644977,
              40.666425
            ],
            [
              -82.644117,
              40.63724
            ],
            [
              -82.62476,
              40.637283
            ],
            [
              -82.623609,
              40.549879
            ],
            [
              -82.374898,
              40.550871
            ],
            [
              -82.336962,
              40.555001
            ],
            [
              -82.338483,
              40.638502
            ],
            [
              -82.339211,
              40.726683
            ],
            [
              -82.378397,
              40.727145
            ],
            [
              -82.378953,
              40.787227
            ],
            [
              -82.38969,
              40.796026
            ],
            [
              -82.388559,
              40.808169
            ],
            [
              -82.407759,
              40.808247
            ],
            [
              -82.420752,
              40.82333
            ],
            [
              -82.4179,
              40.992938
            ],
            [
              -82.432852,
              40.992944
            ],
            [
              -82.724792,
              40.995638
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "171",
      "COUNTYNS": "01074097",
      "AFFGEOID": "0500000US39171",
      "GEOID": "39171",
      "NAME": "Williams",
      "LSAD": "06",
      "ALAND": 1090296608,
      "AWATER": 5507083,
      "County_state": "Williams,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.806082,
              41.696089
            ],
            [
              -84.80621,
              41.67455
            ],
            [
              -84.805673,
              41.632342
            ],
            [
              -84.805696,
              41.631398
            ],
            [
              -84.8057905226358,
              41.6164389780273
            ],
            [
              -84.805812,
              41.61304
            ],
            [
              -84.804729,
              41.530231
            ],
            [
              -84.804729,
              41.5301345906643
            ],
            [
              -84.804729,
              41.530092
            ],
            [
              -84.804551,
              41.500364
            ],
            [
              -84.804457,
              41.488224
            ],
            [
              -84.803919,
              41.435531
            ],
            [
              -84.803956,
              41.426128
            ],
            [
              -84.8039563404477,
              41.4260444864396
            ],
            [
              -84.341664,
              41.427568
            ],
            [
              -84.341902,
              41.485519
            ],
            [
              -84.342051,
              41.514107
            ],
            [
              -84.380735,
              41.513942
            ],
            [
              -84.381312,
              41.61673
            ],
            [
              -84.398375,
              41.61698
            ],
            [
              -84.39954710435259,
              41.705860430932304
            ],
            [
              -84.438067,
              41.704903
            ],
            [
              -84.749955,
              41.698245
            ],
            [
              -84.806082,
              41.696089
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "013",
      "COUNTYNS": "01065575",
      "AFFGEOID": "0500000US39013",
      "GEOID": "39013",
      "NAME": "Belmont",
      "LSAD": "06",
      "ALAND": 1378212692,
      "AWATER": 23682282,
      "County_state": "Belmont,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.234053,
              39.95127
            ],
            [
              -81.237316,
              39.867828
            ],
            [
              -81.017087,
              39.859875
            ],
            [
              -81.017147,
              39.858182
            ],
            [
              -80.821279,
              39.849982
            ],
            [
              -80.81643,
              39.853032
            ],
            [
              -80.799898,
              39.858912
            ],
            [
              -80.793131,
              39.863751
            ],
            [
              -80.790761,
              39.86728
            ],
            [
              -80.790156,
              39.872252
            ],
            [
              -80.793989,
              39.882787
            ],
            [
              -80.796162,
              39.88553
            ],
            [
              -80.802339,
              39.89161
            ],
            [
              -80.806179,
              39.897306
            ],
            [
              -80.809011,
              39.903226
            ],
            [
              -80.809683,
              39.906106
            ],
            [
              -80.809283,
              39.910314
            ],
            [
              -80.807618,
              39.914938
            ],
            [
              -80.806018,
              39.91713
            ],
            [
              -80.803394,
              39.918762
            ],
            [
              -80.80053,
              39.919434
            ],
            [
              -80.795714,
              39.91969
            ],
            [
              -80.782849,
              39.917162
            ],
            [
              -80.772641,
              39.911306
            ],
            [
              -80.768272,
              39.909642
            ],
            [
              -80.762592,
              39.908906
            ],
            [
              -80.760656,
              39.908906
            ],
            [
              -80.759296,
              39.909466
            ],
            [
              -80.758304,
              39.910426
            ],
            [
              -80.756432,
              39.91393
            ],
            [
              -80.755936,
              39.916554
            ],
            [
              -80.756784,
              39.920586
            ],
            [
              -80.761312,
              39.929098
            ],
            [
              -80.764511,
              39.946602
            ],
            [
              -80.764479,
              39.95025
            ],
            [
              -80.763375,
              39.953514
            ],
            [
              -80.758527,
              39.959241
            ],
            [
              -80.755135,
              39.961561
            ],
            [
              -80.74627,
              39.966233
            ],
            [
              -80.743166,
              39.969113
            ],
            [
              -80.740126,
              39.970793
            ],
            [
              -80.738717,
              39.985113
            ],
            [
              -80.740045,
              39.993929
            ],
            [
              -80.741085,
              39.996857
            ],
            [
              -80.742045,
              40.005641
            ],
            [
              -80.741901,
              40.007929
            ],
            [
              -80.740509,
              40.015225
            ],
            [
              -80.737805,
              40.020761
            ],
            [
              -80.737341,
              40.022969
            ],
            [
              -80.737389,
              40.027593
            ],
            [
              -80.7363,
              40.029929
            ],
            [
              -80.733304,
              40.033272
            ],
            [
              -80.7332673845788,
              40.03335743598289
            ],
            [
              -80.731504,
              40.037472
            ],
            [
              -80.7313963316683,
              40.039122914420005
            ],
            [
              -80.730904,
              40.046672
            ],
            [
              -80.730904,
              40.049172
            ],
            [
              -80.733104,
              40.058772
            ],
            [
              -80.734304,
              40.059672
            ],
            [
              -80.737104,
              40.064972
            ],
            [
              -80.738504,
              40.071472
            ],
            [
              -80.738604,
              40.075672
            ],
            [
              -80.736804,
              40.080072
            ],
            [
              -80.730704,
              40.086472
            ],
            [
              -80.713003,
              40.096872
            ],
            [
              -80.710203,
              40.099572
            ],
            [
              -80.709102,
              40.101472
            ],
            [
              -80.707702,
              40.105372
            ],
            [
              -80.706702,
              40.110872
            ],
            [
              -80.707002,
              40.113272
            ],
            [
              -80.708106,
              40.117256
            ],
            [
              -80.70881,
              40.118088
            ],
            [
              -80.710554,
              40.125271
            ],
            [
              -80.710042,
              40.138311
            ],
            [
              -80.707322,
              40.144999
            ],
            [
              -80.705994,
              40.151591
            ],
            [
              -80.704602,
              40.154823
            ],
            [
              -80.7029242071106,
              40.1572566856078
            ],
            [
              -80.766269,
              40.155111
            ],
            [
              -80.882892,
              40.159495
            ],
            [
              -81.109937,
              40.167833
            ],
            [
              -81.220395,
              40.17259
            ],
            [
              -81.225924,
              40.170075
            ],
            [
              -81.234053,
              39.95127
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "071",
      "COUNTYNS": "01074048",
      "AFFGEOID": "0500000US39071",
      "GEOID": "39071",
      "NAME": "Highland",
      "LSAD": "06",
      "ALAND": 1432479993,
      "AWATER": 12194983,
      "County_state": "Highland,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.869762,
              39.05553
            ],
            [
              -83.872214,
              39.021304
            ],
            [
              -83.70535,
              39.021351
            ],
            [
              -83.673018,
              39.02043
            ],
            [
              -83.611592,
              39.01889
            ],
            [
              -83.385637,
              39.055197
            ],
            [
              -83.353531,
              39.197585
            ],
            [
              -83.343479,
              39.233216
            ],
            [
              -83.344984,
              39.250604
            ],
            [
              -83.362351,
              39.240576
            ],
            [
              -83.381125,
              39.249618
            ],
            [
              -83.393511,
              39.267272
            ],
            [
              -83.376629,
              39.290944
            ],
            [
              -83.386057,
              39.31969
            ],
            [
              -83.375073,
              39.347827
            ],
            [
              -83.372714,
              39.377416
            ],
            [
              -83.590878,
              39.378736
            ],
            [
              -83.784793,
              39.262888
            ],
            [
              -83.80134,
              39.231567
            ],
            [
              -83.813873,
              39.223044
            ],
            [
              -83.835869,
              39.223304
            ],
            [
              -83.834404,
              39.245715
            ],
            [
              -83.865679,
              39.247333
            ],
            [
              -83.869762,
              39.05553
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "173",
      "COUNTYNS": "01074098",
      "AFFGEOID": "0500000US39173",
      "GEOID": "39173",
      "NAME": "Wood",
      "LSAD": "06",
      "ALAND": 1598508447,
      "AWATER": 8545541,
      "County_state": "Wood,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.883234,
              41.414503
            ],
            [
              -83.881149,
              41.167824
            ],
            [
              -83.421054,
              41.16678
            ],
            [
              -83.419843,
              41.254003
            ],
            [
              -83.414626,
              41.500228
            ],
            [
              -83.415919,
              41.618938
            ],
            [
              -83.569934,
              41.61715
            ],
            [
              -83.588034,
              41.60095
            ],
            [
              -83.612734,
              41.570751
            ],
            [
              -83.625634,
              41.570051
            ],
            [
              -83.703336,
              41.528952
            ],
            [
              -83.712432,
              41.492856
            ],
            [
              -83.732649,
              41.484684
            ],
            [
              -83.747217,
              41.466216
            ],
            [
              -83.773282,
              41.463616
            ],
            [
              -83.781681,
              41.449341
            ],
            [
              -83.84562,
              41.425028
            ],
            [
              -83.85426,
              41.414451
            ],
            [
              -83.883234,
              41.414503
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "029",
      "COUNTYNS": "01074027",
      "AFFGEOID": "0500000US39029",
      "GEOID": "39029",
      "NAME": "Columbiana",
      "LSAD": "06",
      "ALAND": 1377680388,
      "AWATER": 7255101,
      "County_state": "Columbiana,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.086686,
              40.898858
            ],
            [
              -81.087289,
              40.727816
            ],
            [
              -80.917172,
              40.726934
            ],
            [
              -80.918125,
              40.643464
            ],
            [
              -80.860822,
              40.64257
            ],
            [
              -80.861994,
              40.599404
            ],
            [
              -80.747112,
              40.596991
            ],
            [
              -80.74732,
              40.583327
            ],
            [
              -80.667957,
              40.582496
            ],
            [
              -80.665892,
              40.587728
            ],
            [
              -80.662564,
              40.5916
            ],
            [
              -80.655188,
              40.596544
            ],
            [
              -80.653972,
              40.59648
            ],
            [
              -80.651716,
              40.597744
            ],
            [
              -80.644963,
              40.603648
            ],
            [
              -80.639379,
              40.61128
            ],
            [
              -80.634355,
              40.616095
            ],
            [
              -80.627171,
              40.619936
            ],
            [
              -80.616002,
              40.621696
            ],
            [
              -80.603876,
              40.625064
            ],
            [
              -80.598764,
              40.625263
            ],
            [
              -80.594065,
              40.623664
            ],
            [
              -80.592049,
              40.622496
            ],
            [
              -80.583633,
              40.61552
            ],
            [
              -80.579856,
              40.614304
            ],
            [
              -80.576736,
              40.614224
            ],
            [
              -80.571936,
              40.615456
            ],
            [
              -80.56784,
              40.617552
            ],
            [
              -80.56072,
              40.62368
            ],
            [
              -80.551126,
              40.628847
            ],
            [
              -80.545892,
              40.629702
            ],
            [
              -80.539541,
              40.632122
            ],
            [
              -80.532737,
              40.63559
            ],
            [
              -80.530093,
              40.636255
            ],
            [
              -80.52566,
              40.636068
            ],
            [
              -80.521917,
              40.636992
            ],
            [
              -80.518991,
              40.638801
            ],
            [
              -80.519058,
              40.792298
            ],
            [
              -80.518992,
              40.801221
            ],
            [
              -80.519081,
              40.849157
            ],
            [
              -80.51902,
              40.850073
            ],
            [
              -80.5190191701796,
              40.851339398102795
            ],
            [
              -80.519002,
              40.877543
            ],
            [
              -80.519764,
              40.899858
            ],
            [
              -80.51977742323069,
              40.9003241991296
            ],
            [
              -80.821923,
              40.900935
            ],
            [
              -80.821635,
              40.929775
            ],
            [
              -80.895813,
              40.930187
            ],
            [
              -80.896032,
              40.900995
            ],
            [
              -81.086679,
              40.901609
            ],
            [
              -81.086686,
              40.898858
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "151",
      "COUNTYNS": "01065576",
      "AFFGEOID": "0500000US39151",
      "GEOID": "39151",
      "NAME": "Stark",
      "LSAD": "06",
      "ALAND": 1490043482,
      "AWATER": 13657023,
      "County_state": "Stark,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.650045,
              40.668117
            ],
            [
              -81.649199,
              40.635106
            ],
            [
              -81.553957,
              40.64533
            ],
            [
              -81.467384,
              40.656007
            ],
            [
              -81.459019,
              40.66692
            ],
            [
              -81.438003,
              40.662862
            ],
            [
              -81.447059,
              40.653459
            ],
            [
              -81.317739,
              40.651579
            ],
            [
              -81.24125,
              40.650534
            ],
            [
              -81.237227,
              40.723542
            ],
            [
              -81.19909,
              40.722208
            ],
            [
              -81.198599,
              40.729172
            ],
            [
              -81.087289,
              40.727816
            ],
            [
              -81.086686,
              40.898858
            ],
            [
              -81.086679,
              40.901609
            ],
            [
              -81.086312,
              40.988031
            ],
            [
              -81.183632,
              40.988209
            ],
            [
              -81.195679,
              40.988205
            ],
            [
              -81.203146,
              40.987989
            ],
            [
              -81.357529,
              40.988717
            ],
            [
              -81.393249,
              40.988531
            ],
            [
              -81.416334,
              40.988456
            ],
            [
              -81.420418,
              40.906504
            ],
            [
              -81.536441,
              40.910312
            ],
            [
              -81.647735,
              40.914016
            ],
            [
              -81.647991,
              40.825776
            ],
            [
              -81.650045,
              40.668117
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "017",
      "COUNTYNS": "01074021",
      "AFFGEOID": "0500000US39017",
      "GEOID": "39017",
      "NAME": "Butler",
      "LSAD": "06",
      "ALAND": 1209498280,
      "AWATER": 7863952,
      "County_state": "Butler,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.819352,
              39.309454
            ],
            [
              -84.81945096866738,
              39.305153361544896
            ],
            [
              -84.70328,
              39.304748
            ],
            [
              -84.641814,
              39.305044
            ],
            [
              -84.630047,
              39.312056
            ],
            [
              -84.562347,
              39.308079
            ],
            [
              -84.504644,
              39.305957
            ],
            [
              -84.4832,
              39.301697
            ],
            [
              -84.465455,
              39.302922
            ],
            [
              -84.353209,
              39.292287
            ],
            [
              -84.341405,
              39.412423
            ],
            [
              -84.336719,
              39.455994
            ],
            [
              -84.338534,
              39.47353
            ],
            [
              -84.337645,
              39.482357
            ],
            [
              -84.336751,
              39.496637
            ],
            [
              -84.332038,
              39.548554
            ],
            [
              -84.364736,
              39.543843
            ],
            [
              -84.365232,
              39.589493
            ],
            [
              -84.474246,
              39.590155
            ],
            [
              -84.479213,
              39.591024
            ],
            [
              -84.478927,
              39.56879
            ],
            [
              -84.8150361906572,
              39.5676953269799
            ],
            [
              -84.814955,
              39.567251
            ],
            [
              -84.814955,
              39.566251
            ],
            [
              -84.815155,
              39.548051
            ],
            [
              -84.815355,
              39.521951
            ],
            [
              -84.81535501751969,
              39.521950045262
            ],
            [
              -84.815555,
              39.511052
            ],
            [
              -84.815555,
              39.510952
            ],
            [
              -84.815754,
              39.477352
            ],
            [
              -84.817453,
              39.391753
            ],
            [
              -84.819352,
              39.309454
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "167",
      "COUNTYNS": "01074095",
      "AFFGEOID": "0500000US39167",
      "GEOID": "39167",
      "NAME": "Washington",
      "LSAD": "06",
      "ALAND": 1636799188,
      "AWATER": 20784929,
      "County_state": "Washington,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.844863,
              39.450216
            ],
            [
              -81.853668,
              39.318165
            ],
            [
              -81.834635,
              39.317563
            ],
            [
              -81.835447,
              39.302807
            ],
            [
              -81.816614,
              39.302058
            ],
            [
              -81.818361,
              39.272581
            ],
            [
              -81.721808,
              39.269597
            ],
            [
              -81.7255834730739,
              39.2158352234561
            ],
            [
              -81.724365,
              39.216508
            ],
            [
              -81.7190774611809,
              39.21763715958139
            ],
            [
              -81.711628,
              39.219228
            ],
            [
              -81.700908,
              39.220844
            ],
            [
              -81.694603,
              39.224107
            ],
            [
              -81.692395,
              39.226443
            ],
            [
              -81.691339,
              39.227947
            ],
            [
              -81.691067,
              39.230139
            ],
            [
              -81.692203,
              39.236091
            ],
            [
              -81.695724,
              39.242859
            ],
            [
              -81.696636,
              39.246123
            ],
            [
              -81.696988,
              39.248747
            ],
            [
              -81.69638,
              39.257035
            ],
            [
              -81.69206,
              39.263227
            ],
            [
              -81.689483,
              39.266043
            ],
            [
              -81.683627,
              39.270939
            ],
            [
              -81.678331,
              39.273755
            ],
            [
              -81.670187,
              39.275963
            ],
            [
              -81.656138,
              39.277355
            ],
            [
              -81.643178,
              39.277195
            ],
            [
              -81.621305,
              39.273643
            ],
            [
              -81.613896,
              39.275339
            ],
            [
              -81.608408,
              39.276043
            ],
            [
              -81.603352,
              39.275531
            ],
            [
              -81.59516,
              39.273387
            ],
            [
              -81.588583,
              39.269787
            ],
            [
              -81.585559,
              39.268747
            ],
            [
              -81.570247,
              39.267675
            ],
            [
              -81.567347,
              39.270675
            ],
            [
              -81.565247,
              39.276175
            ],
            [
              -81.565047,
              39.293874
            ],
            [
              -81.560147,
              39.317874
            ],
            [
              -81.559647,
              39.330774
            ],
            [
              -81.557547,
              39.338774
            ],
            [
              -81.542346,
              39.352874
            ],
            [
              -81.53447,
              39.358234
            ],
            [
              -81.524309,
              39.36161
            ],
            [
              -81.513493,
              39.36705
            ],
            [
              -81.503189,
              39.373242
            ],
            [
              -81.489044,
              39.384074
            ],
            [
              -81.4829,
              39.389674
            ],
            [
              -81.473188,
              39.40017
            ],
            [
              -81.467744,
              39.403774
            ],
            [
              -81.456143,
              39.409274
            ],
            [
              -81.446543,
              39.410374
            ],
            [
              -81.435642,
              39.408474
            ],
            [
              -81.428642,
              39.405374
            ],
            [
              -81.420578,
              39.400378
            ],
            [
              -81.4175426815188,
              39.3981570352576
            ],
            [
              -81.412706,
              39.394618
            ],
            [
              -81.406689,
              39.38809
            ],
            [
              -81.40277,
              39.376914
            ],
            [
              -81.400744,
              39.369221
            ],
            [
              -81.395883,
              39.355553
            ],
            [
              -81.393794,
              39.351706
            ],
            [
              -81.391249,
              39.348814
            ],
            [
              -81.384556,
              39.343449
            ],
            [
              -81.379674,
              39.342081
            ],
            [
              -81.375961,
              39.341697
            ],
            [
              -81.3712707002794,
              39.3420616369494
            ],
            [
              -81.356911,
              39.343178
            ],
            [
              -81.347567,
              39.34577
            ],
            [
              -81.342623,
              39.348042
            ],
            [
              -81.335599,
              39.352794
            ],
            [
              -81.326174,
              39.358186
            ],
            [
              -81.319598,
              39.36129
            ],
            [
              -81.304798,
              39.370538
            ],
            [
              -81.297517,
              39.374378
            ],
            [
              -81.281405,
              39.379258
            ],
            [
              -81.275677,
              39.383786
            ],
            [
              -81.270716,
              39.385914
            ],
            [
              -81.259788,
              39.386698
            ],
            [
              -81.249088,
              39.389992
            ],
            [
              -81.24184,
              39.390276
            ],
            [
              -81.223581,
              39.386062
            ],
            [
              -81.221372,
              39.386172
            ],
            [
              -81.217315,
              39.38759
            ],
            [
              -81.213064,
              39.390656
            ],
            [
              -81.211654,
              39.392977
            ],
            [
              -81.21087,
              39.397112
            ],
            [
              -81.211433,
              39.402031
            ],
            [
              -81.210833,
              39.403563
            ],
            [
              -81.208231,
              39.407147
            ],
            [
              -81.205223,
              39.410786
            ],
            [
              -81.200412,
              39.415303
            ],
            [
              -81.190714,
              39.423562
            ],
            [
              -81.185946,
              39.430731
            ],
            [
              -81.182307,
              39.433533
            ],
            [
              -81.179934,
              39.435121
            ],
            [
              -81.170634,
              39.439175
            ],
            [
              -81.16352,
              39.441186
            ],
            [
              -81.152534,
              39.443175
            ],
            [
              -81.138134,
              39.443775
            ],
            [
              -81.134434,
              39.445075
            ],
            [
              -81.132534,
              39.446275
            ],
            [
              -81.128533,
              39.449375
            ],
            [
              -81.124733,
              39.453375
            ],
            [
              -81.121472141059,
              39.457756779201894
            ],
            [
              -81.115133,
              39.466275
            ],
            [
              -81.114433,
              39.466275
            ],
            [
              -81.100833,
              39.487175
            ],
            [
              -81.091433,
              39.496975
            ],
            [
              -81.07595,
              39.50966
            ],
            [
              -81.070594,
              39.515991
            ],
            [
              -81.060379,
              39.522744
            ],
            [
              -81.051982,
              39.52931
            ],
            [
              -81.049955,
              39.531893
            ],
            [
              -81.044902,
              39.5363
            ],
            [
              -81.03870601730101,
              39.5400475328739
            ],
            [
              -81.036791,
              39.572118
            ],
            [
              -81.246808,
              39.579037
            ],
            [
              -81.265347,
              39.57681
            ],
            [
              -81.264261,
              39.605344
            ],
            [
              -81.283075,
              39.605796
            ],
            [
              -81.28353,
              39.591838
            ],
            [
              -81.356445,
              39.593512
            ],
            [
              -81.35629,
              39.600865
            ],
            [
              -81.394934,
              39.601547
            ],
            [
              -81.395036,
              39.615681
            ],
            [
              -81.41385,
              39.615874
            ],
            [
              -81.413665,
              39.630502
            ],
            [
              -81.452712,
              39.631165
            ],
            [
              -81.451935,
              39.645449
            ],
            [
              -81.47316,
              39.645976
            ],
            [
              -81.473471,
              39.583318
            ],
            [
              -81.588185,
              39.586973
            ],
            [
              -81.688884,
              39.590511
            ],
            [
              -81.715522,
              39.5834
            ],
            [
              -81.722217,
              39.53155
            ],
            [
              -81.705075,
              39.53099
            ],
            [
              -81.707997,
              39.489321
            ],
            [
              -81.708527,
              39.480775
            ],
            [
              -81.760959,
              39.488583
            ],
            [
              -81.823158,
              39.494072
            ],
            [
              -81.825757,
              39.449172
            ],
            [
              -81.844863,
              39.450216
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "113",
      "COUNTYNS": "01074069",
      "AFFGEOID": "0500000US39113",
      "GEOID": "39113",
      "NAME": "Montgomery",
      "LSAD": "06",
      "ALAND": 1195936288,
      "AWATER": 6693457,
      "County_state": "Montgomery,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.485367,
              39.918491
            ],
            [
              -84.48359,
              39.830398
            ],
            [
              -84.480149,
              39.65705
            ],
            [
              -84.479213,
              39.591024
            ],
            [
              -84.474246,
              39.590155
            ],
            [
              -84.365232,
              39.589493
            ],
            [
              -84.28534,
              39.589158
            ],
            [
              -84.114195,
              39.577983
            ],
            [
              -84.110569,
              39.618442
            ],
            [
              -84.111935,
              39.626359
            ],
            [
              -84.096937,
              39.793675
            ],
            [
              -84.092938,
              39.838345
            ],
            [
              -84.055238,
              39.835958
            ],
            [
              -84.053736,
              39.850458
            ],
            [
              -84.051039,
              39.879807
            ],
            [
              -84.157223,
              39.88563
            ],
            [
              -84.167065,
              39.912197
            ],
            [
              -84.157671,
              39.92297
            ],
            [
              -84.425902,
              39.919622
            ],
            [
              -84.485367,
              39.918491
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "005",
      "COUNTYNS": "01074016",
      "AFFGEOID": "0500000US39005",
      "GEOID": "39005",
      "NAME": "Ashland",
      "LSAD": "06",
      "ALAND": 1095444134,
      "AWATER": 9962880,
      "County_state": "Ashland,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.436916,
              41.065379
            ],
            [
              -82.432852,
              40.992944
            ],
            [
              -82.4179,
              40.992938
            ],
            [
              -82.420752,
              40.82333
            ],
            [
              -82.407759,
              40.808247
            ],
            [
              -82.388559,
              40.808169
            ],
            [
              -82.38969,
              40.796026
            ],
            [
              -82.378953,
              40.787227
            ],
            [
              -82.378397,
              40.727145
            ],
            [
              -82.339211,
              40.726683
            ],
            [
              -82.338483,
              40.638502
            ],
            [
              -82.336962,
              40.555001
            ],
            [
              -82.22011,
              40.568206
            ],
            [
              -82.220332,
              40.594707
            ],
            [
              -82.220665,
              40.66758
            ],
            [
              -82.126199,
              40.668229
            ],
            [
              -82.12501,
              40.886263
            ],
            [
              -82.1279,
              40.900902
            ],
            [
              -82.129334,
              40.991807
            ],
            [
              -82.173359,
              40.992046
            ],
            [
              -82.171492,
              41.063537
            ],
            [
              -82.336496,
              41.065761
            ],
            [
              -82.436916,
              41.065379
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "081",
      "COUNTYNS": "01074053",
      "AFFGEOID": "0500000US39081",
      "GEOID": "39081",
      "NAME": "Jefferson",
      "LSAD": "06",
      "ALAND": 1057248945,
      "AWATER": 7120755,
      "County_state": "Jefferson,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.940153,
              40.468349
            ],
            [
              -80.941911,
              40.4247
            ],
            [
              -80.865504,
              40.422929
            ],
            [
              -80.867201,
              40.379378
            ],
            [
              -80.882892,
              40.159495
            ],
            [
              -80.766269,
              40.155111
            ],
            [
              -80.7029242071106,
              40.1572566856078
            ],
            [
              -80.686137,
              40.181607
            ],
            [
              -80.683705,
              40.184215
            ],
            [
              -80.682008,
              40.185495
            ],
            [
              -80.68181217781239,
              40.185566237639705
            ],
            [
              -80.6796,
              40.186371
            ],
            [
              -80.6726,
              40.192371
            ],
            [
              -80.6681,
              40.199671
            ],
            [
              -80.666299,
              40.206271
            ],
            [
              -80.664299,
              40.21917
            ],
            [
              -80.661543,
              40.229798
            ],
            [
              -80.652098,
              40.24497
            ],
            [
              -80.644598,
              40.25127
            ],
            [
              -80.637198,
              40.25507
            ],
            [
              -80.637098,
              40.25427
            ],
            [
              -80.622497,
              40.26177
            ],
            [
              -80.619297,
              40.26517
            ],
            [
              -80.616196,
              40.27227
            ],
            [
              -80.616796,
              40.285269
            ],
            [
              -80.614896,
              40.291969
            ],
            [
              -80.606596,
              40.303869
            ],
            [
              -80.602895,
              40.307069
            ],
            [
              -80.599895,
              40.317669
            ],
            [
              -80.600495,
              40.321169
            ],
            [
              -80.602895,
              40.327869
            ],
            [
              -80.610796,
              40.340868
            ],
            [
              -80.6127655700637,
              40.347564538216595
            ],
            [
              -80.612796,
              40.347668
            ],
            [
              -80.611796,
              40.355168
            ],
            [
              -80.608695,
              40.361968
            ],
            [
              -80.607595,
              40.369568
            ],
            [
              -80.609695,
              40.374968
            ],
            [
              -80.614396,
              40.378768
            ],
            [
              -80.619196,
              40.381768
            ],
            [
              -80.63074,
              40.3849
            ],
            [
              -80.631596,
              40.385468
            ],
            [
              -80.633596,
              40.390467
            ],
            [
              -80.632196,
              40.393667
            ],
            [
              -80.6296993745958,
              40.395006652655894
            ],
            [
              -80.628096,
              40.395867
            ],
            [
              -80.615195,
              40.399867
            ],
            [
              -80.612195,
              40.402667
            ],
            [
              -80.611795,
              40.403867
            ],
            [
              -80.612695,
              40.407667
            ],
            [
              -80.612295,
              40.418567
            ],
            [
              -80.612995,
              40.429367
            ],
            [
              -80.612295,
              40.434867
            ],
            [
              -80.611195,
              40.437767
            ],
            [
              -80.604895,
              40.446667
            ],
            [
              -80.604395,
              40.449767
            ],
            [
              -80.598294,
              40.458366
            ],
            [
              -80.596094,
              40.463366
            ],
            [
              -80.594794,
              40.471366
            ],
            [
              -80.595494,
              40.475266
            ],
            [
              -80.599194,
              40.482566
            ],
            [
              -80.60245,
              40.484226
            ],
            [
              -80.609058,
              40.489506
            ],
            [
              -80.618003,
              40.502049
            ],
            [
              -80.620883,
              40.512257
            ],
            [
              -80.622195,
              40.520497
            ],
            [
              -80.627507,
              40.535793
            ],
            [
              -80.630483,
              40.537921
            ],
            [
              -80.633107,
              40.538705
            ],
            [
              -80.64138,
              40.548417
            ],
            [
              -80.652436,
              40.562544
            ],
            [
              -80.655316,
              40.565184
            ],
            [
              -80.662708,
              40.570176
            ],
            [
              -80.666917,
              40.573664
            ],
            [
              -80.667781,
              40.578096
            ],
            [
              -80.667957,
              40.582496
            ],
            [
              -80.74732,
              40.583327
            ],
            [
              -80.747112,
              40.596991
            ],
            [
              -80.861994,
              40.599404
            ],
            [
              -80.863567,
              40.555411
            ],
            [
              -80.92092,
              40.556315
            ],
            [
              -80.924566,
              40.467975
            ],
            [
              -80.940153,
              40.468349
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "001",
      "COUNTYNS": "01074014",
      "AFFGEOID": "0500000US39001",
      "GEOID": "39001",
      "NAME": "Adams",
      "LSAD": "06",
      "ALAND": 1512208934,
      "AWATER": 6165939,
      "County_state": "Adams,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.7053136208764,
              38.6397614234931
            ],
            [
              -83.704006,
              38.639724
            ],
            [
              -83.68552,
              38.63189
            ],
            [
              -83.679484,
              38.630036
            ],
            [
              -83.668111,
              38.628068
            ],
            [
              -83.663911,
              38.62793
            ],
            [
              -83.659304,
              38.628592
            ],
            [
              -83.655425,
              38.629735
            ],
            [
              -83.652916,
              38.630604
            ],
            [
              -83.649737,
              38.632753
            ],
            [
              -83.645914,
              38.637007
            ],
            [
              -83.642994,
              38.643273
            ],
            [
              -83.639954,
              38.652468
            ],
            [
              -83.639396,
              38.659171
            ],
            [
              -83.637377,
              38.66793
            ],
            [
              -83.636208,
              38.670584
            ],
            [
              -83.634018,
              38.673351
            ],
            [
              -83.626922,
              38.679387
            ],
            [
              -83.615736,
              38.684145
            ],
            [
              -83.574754,
              38.692671
            ],
            [
              -83.575121,
              38.691746
            ],
            [
              -83.569098,
              38.692842
            ],
            [
              -83.56187,
              38.695371
            ],
            [
              -83.549298,
              38.698787
            ],
            [
              -83.533339,
              38.702105
            ],
            [
              -83.520953,
              38.703045
            ],
            [
              -83.512571,
              38.701716
            ],
            [
              -83.504365,
              38.699256
            ],
            [
              -83.493342,
              38.694187
            ],
            [
              -83.486477,
              38.690099
            ],
            [
              -83.47642,
              38.682261
            ],
            [
              -83.472157,
              38.678123
            ],
            [
              -83.468059,
              38.67547
            ],
            [
              -83.459809,
              38.673617
            ],
            [
              -83.457055,
              38.673965
            ],
            [
              -83.446989,
              38.670143
            ],
            [
              -83.440404,
              38.669361
            ],
            [
              -83.420194,
              38.668428
            ],
            [
              -83.412039,
              38.666499
            ],
            [
              -83.384755,
              38.663171
            ],
            [
              -83.376302,
              38.661473
            ],
            [
              -83.366661,
              38.658537
            ],
            [
              -83.356445,
              38.654009
            ],
            [
              -83.340004,
              38.645674
            ],
            [
              -83.327636,
              38.637489
            ],
            [
              -83.322383,
              38.630615
            ],
            [
              -83.320531,
              38.622713
            ],
            [
              -83.320099,
              38.616043
            ],
            [
              -83.319101,
              38.612233
            ],
            [
              -83.317542,
              38.609242
            ],
            [
              -83.311448,
              38.603314
            ],
            [
              -83.307832,
              38.600824
            ],
            [
              -83.301951,
              38.598178
            ],
            [
              -83.294193,
              38.596588
            ],
            [
              -83.288885,
              38.597977
            ],
            [
              -83.286514,
              38.599241
            ],
            [
              -83.26851,
              38.615104
            ],
            [
              -83.267694,
              38.618221
            ],
            [
              -83.2675502920792,
              38.6183503098152
            ],
            [
              -83.268268,
              38.625021
            ],
            [
              -83.270817,
              39.015791
            ],
            [
              -83.310418,
              39.042374
            ],
            [
              -83.314589,
              39.052195
            ],
            [
              -83.385637,
              39.055197
            ],
            [
              -83.611592,
              39.01889
            ],
            [
              -83.673018,
              39.02043
            ],
            [
              -83.703374,
              38.678991
            ],
            [
              -83.7053136208764,
              38.6397614234931
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "135",
      "COUNTYNS": "01074080",
      "AFFGEOID": "0500000US39135",
      "GEOID": "39135",
      "NAME": "Preble",
      "LSAD": "06",
      "ALAND": 1098738405,
      "AWATER": 6065437,
      "County_state": "Preble,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.815156,
              39.568351
            ],
            [
              -84.8150361906572,
              39.5676953269799
            ],
            [
              -84.478927,
              39.56879
            ],
            [
              -84.479213,
              39.591024
            ],
            [
              -84.480149,
              39.65705
            ],
            [
              -84.48359,
              39.830398
            ],
            [
              -84.485367,
              39.918491
            ],
            [
              -84.8124110112002,
              39.916915011456894
            ],
            [
              -84.812698,
              39.891585
            ],
            [
              -84.812787,
              39.89083
            ],
            [
              -84.81305,
              39.872958
            ],
            [
              -84.813464,
              39.853261
            ],
            [
              -84.813549,
              39.850773
            ],
            [
              -84.813674,
              39.843173
            ],
            [
              -84.813703,
              39.843059
            ],
            [
              -84.813793,
              39.826771
            ],
            [
              -84.813852,
              39.824621
            ],
            [
              -84.814179,
              39.814212
            ],
            [
              -84.81412,
              39.811398
            ],
            [
              -84.814209,
              39.799755
            ],
            [
              -84.814179,
              39.786853
            ],
            [
              -84.814189,
              39.785569
            ],
            [
              -84.81412906532259,
              39.7266202480145
            ],
            [
              -84.814129,
              39.726556
            ],
            [
              -84.81453,
              39.680429
            ],
            [
              -84.814619,
              39.669174
            ],
            [
              -84.814323,
              39.655814
            ],
            [
              -84.814705,
              39.628854
            ],
            [
              -84.815156,
              39.568351
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "137",
      "COUNTYNS": "01074081",
      "AFFGEOID": "0500000US39137",
      "GEOID": "39137",
      "NAME": "Putnam",
      "LSAD": "06",
      "ALAND": 1249724511,
      "AWATER": 4603965,
      "County_state": "Putnam,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.399486,
              40.990315
            ],
            [
              -84.398407,
              40.903265
            ],
            [
              -84.340969,
              40.903596
            ],
            [
              -84.340531,
              40.859099
            ],
            [
              -84.109586,
              40.860994
            ],
            [
              -84.109516,
              40.90473
            ],
            [
              -83.995165,
              40.905066
            ],
            [
              -83.995228,
              40.919843
            ],
            [
              -83.880423,
              40.920429
            ],
            [
              -83.881149,
              41.167824
            ],
            [
              -84.228222,
              41.165862
            ],
            [
              -84.341602,
              41.165524
            ],
            [
              -84.341911,
              40.990692
            ],
            [
              -84.399486,
              40.990315
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "141",
      "COUNTYNS": "01074083",
      "AFFGEOID": "0500000US39141",
      "GEOID": "39141",
      "NAME": "Ross",
      "LSAD": "06",
      "ALAND": 1784986611,
      "AWATER": 9938846,
      "County_state": "Ross,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.393511,
              39.267272
            ],
            [
              -83.381125,
              39.249618
            ],
            [
              -83.362351,
              39.240576
            ],
            [
              -83.344984,
              39.250604
            ],
            [
              -83.343479,
              39.233216
            ],
            [
              -83.353531,
              39.197585
            ],
            [
              -83.145022,
              39.187572
            ],
            [
              -82.840365,
              39.172012
            ],
            [
              -82.785891,
              39.168769
            ],
            [
              -82.766692,
              39.167768
            ],
            [
              -82.762892,
              39.207967
            ],
            [
              -82.760111,
              39.237644
            ],
            [
              -82.748591,
              39.368165
            ],
            [
              -82.740729,
              39.46835
            ],
            [
              -82.998204,
              39.480061
            ],
            [
              -82.980696,
              39.489861
            ],
            [
              -82.978741,
              39.500854
            ],
            [
              -82.992597,
              39.506388
            ],
            [
              -83.266737,
              39.516249
            ],
            [
              -83.372714,
              39.377416
            ],
            [
              -83.375073,
              39.347827
            ],
            [
              -83.386057,
              39.31969
            ],
            [
              -83.376629,
              39.290944
            ],
            [
              -83.393511,
              39.267272
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "023",
      "COUNTYNS": "01074024",
      "AFFGEOID": "0500000US39023",
      "GEOID": "39023",
      "NAME": "Clark",
      "LSAD": "06",
      "ALAND": 1029475869,
      "AWATER": 13070242,
      "County_state": "Clark,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.051039,
              39.879807
            ],
            [
              -84.053736,
              39.850458
            ],
            [
              -83.939583,
              39.843797
            ],
            [
              -83.941289,
              39.828838
            ],
            [
              -83.827425,
              39.822651
            ],
            [
              -83.829374,
              39.797766
            ],
            [
              -83.766656,
              39.791141
            ],
            [
              -83.738899,
              39.781293
            ],
            [
              -83.64661,
              39.775996
            ],
            [
              -83.647169,
              39.773029
            ],
            [
              -83.588041,
              39.768778
            ],
            [
              -83.564881,
              39.848225
            ],
            [
              -83.509933,
              40.009911
            ],
            [
              -83.516155,
              40.010188
            ],
            [
              -83.807225,
              40.027342
            ],
            [
              -84.036069,
              40.040182
            ],
            [
              -84.051039,
              39.879807
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "145",
      "COUNTYNS": "01074085",
      "AFFGEOID": "0500000US39145",
      "GEOID": "39145",
      "NAME": "Scioto",
      "LSAD": "06",
      "ALAND": 1580451539,
      "AWATER": 15363856,
      "County_state": "Scioto,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.270817,
              39.015791
            ],
            [
              -83.268268,
              38.625021
            ],
            [
              -83.2675502920792,
              38.6183503098152
            ],
            [
              -83.264011,
              38.621535
            ],
            [
              -83.261126,
              38.622723
            ],
            [
              -83.254558,
              38.623403
            ],
            [
              -83.251103,
              38.626224
            ],
            [
              -83.245572,
              38.627936
            ],
            [
              -83.239515,
              38.628588
            ],
            [
              -83.232404,
              38.627569
            ],
            [
              -83.223076,
              38.624158
            ],
            [
              -83.211027,
              38.618578
            ],
            [
              -83.202453,
              38.616956
            ],
            [
              -83.1914,
              38.617598
            ],
            [
              -83.172647,
              38.620252
            ],
            [
              -83.164744,
              38.620068
            ],
            [
              -83.156926,
              38.620547
            ],
            [
              -83.142836,
              38.625076
            ],
            [
              -83.138572,
              38.628286
            ],
            [
              -83.135046,
              38.631719
            ],
            [
              -83.128973,
              38.640231
            ],
            [
              -83.126311,
              38.645244
            ],
            [
              -83.122547,
              38.6592
            ],
            [
              -83.11786,
              38.666073
            ],
            [
              -83.112372,
              38.671685
            ],
            [
              -83.107436,
              38.67522
            ],
            [
              -83.102746,
              38.677316
            ],
            [
              -83.084226,
              38.68109
            ],
            [
              -83.064319,
              38.688976
            ],
            [
              -83.053104,
              38.695831
            ],
            [
              -83.0447314019964,
              38.705542778178504
            ],
            [
              -83.042338,
              38.708319
            ],
            [
              -83.038442,
              38.716377
            ],
            [
              -83.035964,
              38.720152
            ],
            [
              -83.033014,
              38.723805
            ],
            [
              -83.0308908096218,
              38.7255636114076
            ],
            [
              -83.030702,
              38.72572
            ],
            [
              -83.027917,
              38.727143
            ],
            [
              -83.021752,
              38.72879
            ],
            [
              -83.011816,
              38.730057
            ],
            [
              -82.999296,
              38.729376
            ],
            [
              -82.993996,
              38.728576
            ],
            [
              -82.986095,
              38.726276
            ],
            [
              -82.979395,
              38.725976
            ],
            [
              -82.968695,
              38.728776
            ],
            [
              -82.958895,
              38.734976
            ],
            [
              -82.943147,
              38.74328
            ],
            [
              -82.923694,
              38.750076
            ],
            [
              -82.894193,
              38.756576
            ],
            [
              -82.889193,
              38.756076
            ],
            [
              -82.879492,
              38.751476
            ],
            [
              -82.875492,
              38.747276
            ],
            [
              -82.87434064295078,
              38.745410007541004
            ],
            [
              -82.872592,
              38.742576
            ],
            [
              -82.871292,
              38.739376
            ],
            [
              -82.869892,
              38.728177
            ],
            [
              -82.870392,
              38.722077
            ],
            [
              -82.871192,
              38.718377
            ],
            [
              -82.875292,
              38.704977
            ],
            [
              -82.876892,
              38.697377
            ],
            [
              -82.877592,
              38.690177
            ],
            [
              -82.874892,
              38.682827
            ],
            [
              -82.869592,
              38.678177
            ],
            [
              -82.863291,
              38.669277
            ],
            [
              -82.859391,
              38.660378
            ],
            [
              -82.856291,
              38.646078
            ],
            [
              -82.856791,
              38.632878
            ],
            [
              -82.855795,
              38.620814
            ],
            [
              -82.854291,
              38.613454
            ],
            [
              -82.851314,
              38.604334
            ],
            [
              -82.847186,
              38.595166
            ],
            [
              -82.844306,
              38.590862
            ],
            [
              -82.839538,
              38.586159
            ],
            [
              -82.820161,
              38.572703
            ],
            [
              -82.81601184488581,
              38.5707328290844
            ],
            [
              -82.758479,
              38.590943
            ],
            [
              -82.740383,
              38.597167
            ],
            [
              -82.766188,
              38.644678
            ],
            [
              -82.763895,
              38.679974
            ],
            [
              -82.706404,
              38.677503
            ],
            [
              -82.701486,
              38.690878
            ],
            [
              -82.695387,
              38.749977
            ],
            [
              -82.662886,
              38.747977
            ],
            [
              -82.659086,
              38.762677
            ],
            [
              -82.650039,
              38.849073
            ],
            [
              -82.76069,
              38.854875
            ],
            [
              -82.753391,
              38.945373
            ],
            [
              -82.80709,
              38.948073
            ],
            [
              -82.999898,
              38.956323
            ],
            [
              -83.04362,
              38.956939
            ],
            [
              -83.212107,
              38.960148
            ],
            [
              -83.217234,
              38.971758
            ],
            [
              -83.215252,
              38.991922
            ],
            [
              -83.23406,
              38.998819
            ],
            [
              -83.237687,
              39.009606
            ],
            [
              -83.270817,
              39.015791
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "147",
      "COUNTYNS": "01074086",
      "AFFGEOID": "0500000US39147",
      "GEOID": "39147",
      "NAME": "Seneca",
      "LSAD": "06",
      "ALAND": 1427143576,
      "AWATER": 4584953,
      "County_state": "Seneca,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.421054,
              41.16678
            ],
            [
              -83.421152,
              41.130255
            ],
            [
              -83.420319,
              40.991889
            ],
            [
              -83.305004,
              40.992787
            ],
            [
              -83.112737,
              40.993447
            ],
            [
              -82.853184,
              40.993661
            ],
            [
              -82.829512,
              40.996624
            ],
            [
              -82.838538,
              41.216999
            ],
            [
              -82.840087,
              41.255337
            ],
            [
              -83.075296,
              41.256903
            ],
            [
              -83.249512,
              41.253016
            ],
            [
              -83.32316,
              41.254889
            ],
            [
              -83.419843,
              41.254003
            ],
            [
              -83.421054,
              41.16678
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "045",
      "COUNTYNS": "01074035",
      "AFFGEOID": "0500000US39045",
      "GEOID": "39045",
      "NAME": "Fairfield",
      "LSAD": "06",
      "ALAND": 1306421163,
      "AWATER": 10746965,
      "County_state": "Fairfield,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.837259,
              39.6546
            ],
            [
              -82.842953,
              39.56148
            ],
            [
              -82.731518,
              39.554445
            ],
            [
              -82.730272,
              39.569066
            ],
            [
              -82.620088,
              39.563987
            ],
            [
              -82.617546,
              39.608605
            ],
            [
              -82.496123,
              39.602849
            ],
            [
              -82.490329,
              39.661704
            ],
            [
              -82.374531,
              39.654959
            ],
            [
              -82.366051,
              39.74252
            ],
            [
              -82.404246,
              39.744605
            ],
            [
              -82.396594,
              39.833057
            ],
            [
              -82.472402,
              39.837223
            ],
            [
              -82.462813,
              39.93038
            ],
            [
              -82.473423,
              39.925065
            ],
            [
              -82.77287,
              39.939326
            ],
            [
              -82.782495,
              39.939662
            ],
            [
              -82.810797,
              39.941162
            ],
            [
              -82.813096,
              39.911663
            ],
            [
              -82.794383,
              39.910785
            ],
            [
              -82.799213,
              39.859684
            ],
            [
              -82.802476,
              39.822953
            ],
            [
              -82.821496,
              39.824271
            ],
            [
              -82.824254,
              39.794996
            ],
            [
              -82.837259,
              39.6546
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "155",
      "COUNTYNS": "01074089",
      "AFFGEOID": "0500000US39155",
      "GEOID": "39155",
      "NAME": "Trumbull",
      "LSAD": "06",
      "ALAND": 1600938408,
      "AWATER": 47766218,
      "County_state": "Trumbull,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.003319,
              41.34786
            ],
            [
              -81.003191,
              41.347859
            ],
            [
              -81.00288,
              41.271842
            ],
            [
              -81.002621,
              41.202554
            ],
            [
              -81.002614,
              41.200984
            ],
            [
              -81.00229,
              41.134189
            ],
            [
              -80.789472,
              41.134159
            ],
            [
              -80.5191668077946,
              41.133388009323596
            ],
            [
              -80.519115,
              41.14552
            ],
            [
              -80.519144,
              41.171203
            ],
            [
              -80.51883,
              41.209213
            ],
            [
              -80.518893,
              41.219356
            ],
            [
              -80.518893,
              41.232556
            ],
            [
              -80.518693,
              41.248855
            ],
            [
              -80.518893,
              41.265155
            ],
            [
              -80.518993,
              41.268155
            ],
            [
              -80.518996,
              41.2683
            ],
            [
              -80.518794,
              41.305509
            ],
            [
              -80.519129,
              41.312408
            ],
            [
              -80.519265,
              41.333495
            ],
            [
              -80.51926508160219,
              41.333507561632196
            ],
            [
              -80.519281,
              41.335958
            ],
            [
              -80.519281,
              41.337145
            ],
            [
              -80.519281,
              41.337174
            ],
            [
              -80.519311,
              41.339052
            ],
            [
              -80.519293,
              41.339054
            ],
            [
              -80.519293,
              41.339654
            ],
            [
              -80.519345,
              41.340145
            ],
            [
              -80.519345,
              41.34074
            ],
            [
              -80.519249,
              41.36103
            ],
            [
              -80.519217,
              41.372006
            ],
            [
              -80.519249,
              41.378918
            ],
            [
              -80.519025,
              41.416438
            ],
            [
              -80.518993,
              41.416437
            ],
            [
              -80.518993,
              41.423954
            ],
            [
              -80.518993,
              41.435454
            ],
            [
              -80.51902726415379,
              41.440735157387
            ],
            [
              -80.519169,
              41.462581
            ],
            [
              -80.51920863754069,
              41.4890128693366
            ],
            [
              -80.519225,
              41.499924
            ],
            [
              -81.003125,
              41.501685
            ],
            [
              -81.003319,
              41.34786
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "157",
      "COUNTYNS": "01074090",
      "AFFGEOID": "0500000US39157",
      "GEOID": "39157",
      "NAME": "Tuscarawas",
      "LSAD": "06",
      "ALAND": 1469659271,
      "AWATER": 10390938,
      "County_state": "Tuscarawas,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.709132,
              40.444775
            ],
            [
              -81.712288,
              40.370804
            ],
            [
              -81.61603,
              40.368118
            ],
            [
              -81.622683,
              40.221308
            ],
            [
              -81.338057,
              40.214253
            ],
            [
              -81.334601,
              40.304316
            ],
            [
              -81.275319,
              40.303434
            ],
            [
              -81.269951,
              40.433486
            ],
            [
              -81.268434,
              40.476714
            ],
            [
              -81.264744,
              40.565042
            ],
            [
              -81.321659,
              40.566648
            ],
            [
              -81.317739,
              40.651579
            ],
            [
              -81.447059,
              40.653459
            ],
            [
              -81.438003,
              40.662862
            ],
            [
              -81.459019,
              40.66692
            ],
            [
              -81.467384,
              40.656007
            ],
            [
              -81.553957,
              40.64533
            ],
            [
              -81.649199,
              40.635106
            ],
            [
              -81.668971,
              40.632863
            ],
            [
              -81.669654,
              40.443866
            ],
            [
              -81.709132,
              40.444775
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "093",
      "COUNTYNS": "01074059",
      "AFFGEOID": "0500000US39093",
      "GEOID": "39093",
      "NAME": "Lorain",
      "LSAD": "06",
      "ALAND": 1272272276,
      "AWATER": 1119442372,
      "County_state": "Lorain,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.3480756474318,
              41.428431419784495
            ],
            [
              -82.345202,
              41.35743
            ],
            [
              -82.345048,
              41.353479
            ],
            [
              -82.342314,
              41.283554
            ],
            [
              -82.344003,
              41.211266
            ],
            [
              -82.344086,
              41.202152
            ],
            [
              -82.341285,
              41.187947
            ],
            [
              -82.340616,
              41.137899
            ],
            [
              -82.336496,
              41.065761
            ],
            [
              -82.171492,
              41.063537
            ],
            [
              -82.169875,
              41.137097
            ],
            [
              -82.074266,
              41.136456
            ],
            [
              -82.072465,
              41.19985
            ],
            [
              -81.973895,
              41.199831
            ],
            [
              -81.972485,
              41.274829
            ],
            [
              -81.878053,
              41.275044
            ],
            [
              -81.87696,
              41.350685
            ],
            [
              -81.971262,
              41.351268
            ],
            [
              -81.970464,
              41.386748
            ],
            [
              -81.969465,
              41.478017
            ],
            [
              -81.96813028729011,
              41.5064221331362
            ],
            [
              -81.9884884364315,
              41.512596928043195
            ],
            [
              -81.994565,
              41.51444
            ],
            [
              -82.011966,
              41.515639
            ],
            [
              -82.06180041740119,
              41.503150538527
            ],
            [
              -82.094169,
              41.495039
            ],
            [
              -82.165373,
              41.47444
            ],
            [
              -82.181598,
              41.471634
            ],
            [
              -82.184774,
              41.47404
            ],
            [
              -82.186174,
              41.47344
            ],
            [
              -82.18885,
              41.468097
            ],
            [
              -82.193375,
              41.46454
            ],
            [
              -82.254678,
              41.434441
            ],
            [
              -82.268479,
              41.430842
            ],
            [
              -82.29158,
              41.428442
            ],
            [
              -82.334182,
              41.430243
            ],
            [
              -82.3480756474318,
              41.428431419784495
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "131",
      "COUNTYNS": "01074078",
      "AFFGEOID": "0500000US39131",
      "GEOID": "39131",
      "NAME": "Pike",
      "LSAD": "06",
      "ALAND": 1140324458,
      "AWATER": 9567612,
      "County_state": "Pike,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.353531,
              39.197585
            ],
            [
              -83.385637,
              39.055197
            ],
            [
              -83.314589,
              39.052195
            ],
            [
              -83.310418,
              39.042374
            ],
            [
              -83.270817,
              39.015791
            ],
            [
              -83.237687,
              39.009606
            ],
            [
              -83.23406,
              38.998819
            ],
            [
              -83.215252,
              38.991922
            ],
            [
              -83.217234,
              38.971758
            ],
            [
              -83.212107,
              38.960148
            ],
            [
              -83.04362,
              38.956939
            ],
            [
              -82.999898,
              38.956323
            ],
            [
              -82.80709,
              38.948073
            ],
            [
              -82.785891,
              39.168769
            ],
            [
              -82.840365,
              39.172012
            ],
            [
              -83.145022,
              39.187572
            ],
            [
              -83.353531,
              39.197585
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "087",
      "COUNTYNS": "01074056",
      "AFFGEOID": "0500000US39087",
      "GEOID": "39087",
      "NAME": "Lawrence",
      "LSAD": "06",
      "ALAND": 1174224653,
      "AWATER": 10110033,
      "County_state": "Lawrence,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.81601184488581,
              38.5707328290844
            ],
            [
              -82.800112,
              38.563183
            ],
            [
              -82.789776,
              38.559951
            ],
            [
              -82.779472,
              38.559023
            ],
            [
              -82.763695,
              38.560399
            ],
            [
              -82.739582,
              38.558991
            ],
            [
              -82.730958,
              38.559264
            ],
            [
              -82.724846,
              38.5576
            ],
            [
              -82.719662,
              38.553664
            ],
            [
              -82.714142,
              38.550544
            ],
            [
              -82.700045,
              38.544336
            ],
            [
              -82.696621,
              38.542112
            ],
            [
              -82.689965,
              38.53592
            ],
            [
              -82.675724,
              38.515504
            ],
            [
              -82.665548,
              38.505808
            ],
            [
              -82.6654809868313,
              38.5057370829218
            ],
            [
              -82.657051,
              38.496816
            ],
            [
              -82.648395,
              38.490368
            ],
            [
              -82.637707,
              38.484449
            ],
            [
              -82.6297414981797,
              38.4816659645872
            ],
            [
              -82.624106,
              38.479697
            ],
            [
              -82.618474,
              38.477089
            ],
            [
              -82.613802,
              38.474529
            ],
            [
              -82.610458,
              38.471457
            ],
            [
              -82.608202,
              38.468049
            ],
            [
              -82.604089,
              38.459841
            ],
            [
              -82.600761,
              38.437425
            ],
            [
              -82.596921,
              38.426705
            ],
            [
              -82.593673,
              38.421809
            ],
            [
              -82.588249,
              38.415489
            ],
            [
              -82.579976,
              38.41013
            ],
            [
              -82.577176,
              38.40877
            ],
            [
              -82.569368,
              38.406258
            ],
            [
              -82.560664,
              38.404338
            ],
            [
              -82.5535345000504,
              38.4035925686201
            ],
            [
              -82.549799,
              38.403202
            ],
            [
              -82.540199,
              38.403666
            ],
            [
              -82.529579,
              38.405182
            ],
            [
              -82.5293290908971,
              38.405245900779704
            ],
            [
              -82.507678,
              38.410782
            ],
            [
              -82.50767557967869,
              38.4107828373227
            ],
            [
              -82.486577,
              38.418082
            ],
            [
              -82.459676,
              38.424682
            ],
            [
              -82.447076,
              38.426982
            ],
            [
              -82.43601878360201,
              38.4296807930741
            ],
            [
              -82.434375,
              38.430082
            ],
            [
              -82.404882,
              38.439347
            ],
            [
              -82.389746,
              38.434355
            ],
            [
              -82.381773,
              38.434783
            ],
            [
              -82.360145,
              38.438596
            ],
            [
              -82.34064,
              38.440948
            ],
            [
              -82.330335,
              38.4445
            ],
            [
              -82.323999,
              38.449268
            ],
            [
              -82.318111,
              38.457876
            ],
            [
              -82.313935,
              38.468084
            ],
            [
              -82.312511,
              38.476116
            ],
            [
              -82.310639,
              38.483172
            ],
            [
              -82.306351,
              38.490692
            ],
            [
              -82.304223,
              38.496308
            ],
            [
              -82.303071,
              38.504384
            ],
            [
              -82.303971,
              38.517683
            ],
            [
              -82.302871,
              38.523183
            ],
            [
              -82.300271,
              38.529383
            ],
            [
              -82.297771,
              38.533283
            ],
            [
              -82.295671,
              38.538483
            ],
            [
              -82.293271,
              38.560283
            ],
            [
              -82.293871,
              38.572683
            ],
            [
              -82.293471,
              38.575383
            ],
            [
              -82.291271,
              38.578983
            ],
            [
              -82.2871015269719,
              38.5825881557857
            ],
            [
              -82.361674,
              38.585182
            ],
            [
              -82.354468,
              38.67607
            ],
            [
              -82.472478,
              38.682279
            ],
            [
              -82.466878,
              38.741478
            ],
            [
              -82.486579,
              38.742978
            ],
            [
              -82.483579,
              38.772278
            ],
            [
              -82.582683,
              38.779077
            ],
            [
              -82.575583,
              38.844478
            ],
            [
              -82.650039,
              38.849073
            ],
            [
              -82.659086,
              38.762677
            ],
            [
              -82.662886,
              38.747977
            ],
            [
              -82.695387,
              38.749977
            ],
            [
              -82.701486,
              38.690878
            ],
            [
              -82.706404,
              38.677503
            ],
            [
              -82.763895,
              38.679974
            ],
            [
              -82.766188,
              38.644678
            ],
            [
              -82.740383,
              38.597167
            ],
            [
              -82.758479,
              38.590943
            ],
            [
              -82.81601184488581,
              38.5707328290844
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "169",
      "COUNTYNS": "01074096",
      "AFFGEOID": "0500000US39169",
      "GEOID": "39169",
      "NAME": "Wayne",
      "LSAD": "06",
      "ALAND": 1437086621,
      "AWATER": 5055679,
      "County_state": "Wayne,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.129334,
              40.991807
            ],
            [
              -82.1279,
              40.900902
            ],
            [
              -82.12501,
              40.886263
            ],
            [
              -82.126199,
              40.668229
            ],
            [
              -81.876904,
              40.667187
            ],
            [
              -81.650045,
              40.668117
            ],
            [
              -81.647991,
              40.825776
            ],
            [
              -81.647735,
              40.914016
            ],
            [
              -81.647689,
              40.988563
            ],
            [
              -81.688491,
              40.98859
            ],
            [
              -81.977381,
              40.989961
            ],
            [
              -82.078627,
              40.991153
            ],
            [
              -82.129334,
              40.991807
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "025",
      "COUNTYNS": "01074025",
      "AFFGEOID": "0500000US39025",
      "GEOID": "39025",
      "NAME": "Clermont",
      "LSAD": "06",
      "ALAND": 1170958177,
      "AWATER": 19831170,
      "County_state": "Clermont,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.320028,
              39.223433
            ],
            [
              -84.309356,
              39.208774
            ],
            [
              -84.284304,
              39.213243
            ],
            [
              -84.276377,
              39.203873
            ],
            [
              -84.288288,
              39.186487
            ],
            [
              -84.299328,
              39.174447
            ],
            [
              -84.31524,
              39.057335
            ],
            [
              -84.3199364709232,
              39.02208095553439
            ],
            [
              -84.31368,
              39.016981
            ],
            [
              -84.304698,
              39.006455
            ],
            [
              -84.299362,
              38.995985
            ],
            [
              -84.297255,
              38.989694
            ],
            [
              -84.296466,
              38.985306
            ],
            [
              -84.295076,
              38.968295
            ],
            [
              -84.288164,
              38.955789
            ],
            [
              -84.279916,
              38.945168
            ],
            [
              -84.27491,
              38.941511
            ],
            [
              -84.260797,
              38.926593
            ],
            [
              -84.25701,
              38.923208
            ],
            [
              -84.245647,
              38.907035
            ],
            [
              -84.242689,
              38.903187
            ],
            [
              -84.240155,
              38.900912
            ],
            [
              -84.234453,
              38.893226
            ],
            [
              -84.232343,
              38.884325
            ],
            [
              -84.232132,
              38.880483
            ],
            [
              -84.23191434799891,
              38.8748661027637
            ],
            [
              -84.231837,
              38.87287
            ],
            [
              -84.233727,
              38.853576
            ],
            [
              -84.233265,
              38.842671
            ],
            [
              -84.231306,
              38.830552
            ],
            [
              -84.23042691143739,
              38.8274224447174
            ],
            [
              -84.230181,
              38.826547
            ],
            [
              -84.2253,
              38.817665
            ],
            [
              -84.222059,
              38.813753
            ],
            [
              -84.212904,
              38.805707
            ],
            [
              -84.205592,
              38.802588
            ],
            [
              -84.198358,
              38.80092
            ],
            [
              -84.155912,
              38.794909
            ],
            [
              -84.135088,
              38.789485
            ],
            [
              -84.115655,
              38.782721
            ],
            [
              -84.108836,
              38.779247
            ],
            [
              -84.094334,
              38.775246
            ],
            [
              -84.071491,
              38.770475
            ],
            [
              -84.06341,
              38.771151
            ],
            [
              -84.052684882421,
              38.7713751994327
            ],
            [
              -83.997763,
              39.191023
            ],
            [
              -83.99312,
              39.254235
            ],
            [
              -84.006782,
              39.255068
            ],
            [
              -84.259431,
              39.270796
            ],
            [
              -84.261408,
              39.264686
            ],
            [
              -84.282224,
              39.252263
            ],
            [
              -84.320028,
              39.223433
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "031",
      "COUNTYNS": "01074028",
      "AFFGEOID": "0500000US39031",
      "GEOID": "39031",
      "NAME": "Coshocton",
      "LSAD": "06",
      "ALAND": 1460530916,
      "AWATER": 9243155,
      "County_state": "Coshocton,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.184453,
              40.452675
            ],
            [
              -82.195911,
              40.239071
            ],
            [
              -82.182823,
              40.238624
            ],
            [
              -82.187105,
              40.16688
            ],
            [
              -81.721987,
              40.152947
            ],
            [
              -81.716276,
              40.152166
            ],
            [
              -81.670899,
              40.150976
            ],
            [
              -81.66759,
              40.222468
            ],
            [
              -81.622683,
              40.221308
            ],
            [
              -81.61603,
              40.368118
            ],
            [
              -81.712288,
              40.370804
            ],
            [
              -81.709132,
              40.444775
            ],
            [
              -82.085885,
              40.454101
            ],
            [
              -82.0902,
              40.452918
            ],
            [
              -82.184607,
              40.45628
            ],
            [
              -82.184453,
              40.452675
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "097",
      "COUNTYNS": "01074061",
      "AFFGEOID": "0500000US39097",
      "GEOID": "39097",
      "NAME": "Madison",
      "LSAD": "06",
      "ALAND": 1206646148,
      "AWATER": 1919019,
      "County_state": "Madison,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.648847,
              39.765844
            ],
            [
              -83.65333,
              39.716876
            ],
            [
              -83.252435,
              39.695438
            ],
            [
              -83.243702,
              39.812503
            ],
            [
              -83.252031,
              39.917363
            ],
            [
              -83.225643,
              39.932262
            ],
            [
              -83.250227,
              39.970328
            ],
            [
              -83.245962,
              39.987015
            ],
            [
              -83.259923,
              40.004272
            ],
            [
              -83.25168,
              40.008376
            ],
            [
              -83.255051,
              40.048614
            ],
            [
              -83.212196,
              40.048069
            ],
            [
              -83.210587,
              40.065277
            ],
            [
              -83.206273,
              40.107733
            ],
            [
              -83.503714,
              40.111468
            ],
            [
              -83.516155,
              40.010188
            ],
            [
              -83.509933,
              40.009911
            ],
            [
              -83.564881,
              39.848225
            ],
            [
              -83.588041,
              39.768778
            ],
            [
              -83.647169,
              39.773029
            ],
            [
              -83.648847,
              39.765844
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "035",
      "COUNTYNS": "01074030",
      "AFFGEOID": "0500000US39035",
      "GEOID": "39035",
      "NAME": "Cuyahoga",
      "LSAD": "06",
      "ALAND": 1184013873,
      "AWATER": 2041890563,
      "County_state": "Cuyahoga,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.970464,
              41.386748
            ],
            [
              -81.971262,
              41.351268
            ],
            [
              -81.87696,
              41.350685
            ],
            [
              -81.878053,
              41.275044
            ],
            [
              -81.83911,
              41.275562
            ],
            [
              -81.807888,
              41.276051
            ],
            [
              -81.705151,
              41.277254
            ],
            [
              -81.68495,
              41.277146
            ],
            [
              -81.67051,
              41.277255
            ],
            [
              -81.590542,
              41.277554
            ],
            [
              -81.565139,
              41.277758
            ],
            [
              -81.573002,
              41.30265
            ],
            [
              -81.585444,
              41.308864
            ],
            [
              -81.588099,
              41.342651
            ],
            [
              -81.598052,
              41.351157
            ],
            [
              -81.536216,
              41.35062
            ],
            [
              -81.517658,
              41.350154
            ],
            [
              -81.450331,
              41.349148
            ],
            [
              -81.391694,
              41.348272
            ],
            [
              -81.391764,
              41.424269
            ],
            [
              -81.374772,
              41.424427
            ],
            [
              -81.374992,
              41.445286
            ],
            [
              -81.391518,
              41.445222
            ],
            [
              -81.391005,
              41.569716
            ],
            [
              -81.487842,
              41.570051
            ],
            [
              -81.48804,
              41.588249
            ],
            [
              -81.48864,
              41.631348
            ],
            [
              -81.50044,
              41.623448
            ],
            [
              -81.529742,
              41.614548
            ],
            [
              -81.5499645816715,
              41.598053846095404
            ],
            [
              -81.562844,
              41.587549
            ],
            [
              -81.599747,
              41.560649
            ],
            [
              -81.633652,
              41.540458
            ],
            [
              -81.649872073479,
              41.53577482355529
            ],
            [
              -81.664851,
              41.53145
            ],
            [
              -81.6648836288106,
              41.531430135203
            ],
            [
              -81.706864,
              41.505872
            ],
            [
              -81.710251118207,
              41.5024575462829
            ],
            [
              -81.710953,
              41.50175
            ],
            [
              -81.738755,
              41.48855
            ],
            [
              -81.744755,
              41.48715
            ],
            [
              -81.76857576943449,
              41.4915966885891
            ],
            [
              -81.768856,
              41.491649
            ],
            [
              -81.782258,
              41.49605
            ],
            [
              -81.794157,
              41.496648
            ],
            [
              -81.7944488471141,
              41.496630419907596
            ],
            [
              -81.810758,
              41.495648
            ],
            [
              -81.83635860008229,
              41.4895421038063
            ],
            [
              -81.8576391187915,
              41.4844665728513
            ],
            [
              -81.860262,
              41.483841
            ],
            [
              -81.87736,
              41.483445
            ],
            [
              -81.937862,
              41.491443
            ],
            [
              -81.958463,
              41.498642
            ],
            [
              -81.962664,
              41.501341
            ],
            [
              -81.964912,
              41.505446
            ],
            [
              -81.96813028729011,
              41.5064221331362
            ],
            [
              -81.969465,
              41.478017
            ],
            [
              -81.970464,
              41.386748
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "049",
      "COUNTYNS": "01074037",
      "AFFGEOID": "0500000US39049",
      "GEOID": "39049",
      "NAME": "Franklin",
      "LSAD": "06",
      "ALAND": 1378853105,
      "AWATER": 29125601,
      "County_state": "Franklin,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.25168,
              40.008376
            ],
            [
              -83.259923,
              40.004272
            ],
            [
              -83.245962,
              39.987015
            ],
            [
              -83.250227,
              39.970328
            ],
            [
              -83.225643,
              39.932262
            ],
            [
              -83.252031,
              39.917363
            ],
            [
              -83.243702,
              39.812503
            ],
            [
              -83.120355,
              39.807823
            ],
            [
              -83.013071,
              39.804394
            ],
            [
              -82.824254,
              39.794996
            ],
            [
              -82.821496,
              39.824271
            ],
            [
              -82.802476,
              39.822953
            ],
            [
              -82.799213,
              39.859684
            ],
            [
              -82.794383,
              39.910785
            ],
            [
              -82.813096,
              39.911663
            ],
            [
              -82.810797,
              39.941162
            ],
            [
              -82.782495,
              39.939662
            ],
            [
              -82.773825,
              39.976125
            ],
            [
              -82.767483,
              40.051028
            ],
            [
              -82.761827,
              40.125855
            ],
            [
              -82.971307,
              40.134933
            ],
            [
              -83.005204,
              40.136336
            ],
            [
              -83.011329,
              40.136339
            ],
            [
              -83.017012,
              40.136747
            ],
            [
              -83.169981,
              40.143087
            ],
            [
              -83.170208,
              40.106927
            ],
            [
              -83.206273,
              40.107733
            ],
            [
              -83.210587,
              40.065277
            ],
            [
              -83.212196,
              40.048069
            ],
            [
              -83.255051,
              40.048614
            ],
            [
              -83.25168,
              40.008376
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "061",
      "COUNTYNS": "01074043",
      "AFFGEOID": "0500000US39061",
      "GEOID": "39061",
      "NAME": "Hamilton",
      "LSAD": "06",
      "ALAND": 1051353810,
      "AWATER": 17350705,
      "County_state": "Hamilton,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.820159,
              39.227225
            ],
            [
              -84.8201153322843,
              39.2187101624055
            ],
            [
              -84.819802,
              39.157613
            ],
            [
              -84.819985,
              39.149081
            ],
            [
              -84.820157,
              39.10548
            ],
            [
              -84.813767,
              39.106492
            ],
            [
              -84.812241,
              39.107102
            ],
            [
              -84.810753,
              39.109142
            ],
            [
              -84.79382,
              39.112939
            ],
            [
              -84.78768,
              39.115297
            ],
            [
              -84.783991,
              39.11806
            ],
            [
              -84.766749,
              39.138558
            ],
            [
              -84.754449,
              39.146658
            ],
            [
              -84.750749,
              39.147358
            ],
            [
              -84.744149,
              39.147458
            ],
            [
              -84.732048,
              39.144458
            ],
            [
              -84.726148,
              39.141958
            ],
            [
              -84.718548,
              39.137059
            ],
            [
              -84.714048,
              39.132659
            ],
            [
              -84.701947,
              39.118959
            ],
            [
              -84.69248451179729,
              39.1074799159508
            ],
            [
              -84.689747,
              39.104159
            ],
            [
              -84.684847,
              39.100459
            ],
            [
              -84.677247,
              39.09826
            ],
            [
              -84.666147,
              39.09796
            ],
            [
              -84.657246,
              39.09546
            ],
            [
              -84.650346,
              39.09166
            ],
            [
              -84.632446,
              39.07676
            ],
            [
              -84.6234682396614,
              39.0743557886818
            ],
            [
              -84.620112,
              39.073457
            ],
            [
              -84.607928,
              39.073238
            ],
            [
              -84.601682,
              39.074115
            ],
            [
              -84.592408,
              39.078088
            ],
            [
              -84.572144,
              39.08206
            ],
            [
              -84.5718392746594,
              39.0822569566226
            ],
            [
              -84.563944,
              39.08736
            ],
            [
              -84.557544,
              39.09486
            ],
            [
              -84.554444,
              39.09766
            ],
            [
              -84.550844,
              39.09936
            ],
            [
              -84.54355120700639,
              39.09965970382169
            ],
            [
              -84.543544,
              39.09966
            ],
            [
              -84.541344,
              39.09916
            ],
            [
              -84.524644,
              39.09216
            ],
            [
              -84.519543,
              39.09206
            ],
            [
              -84.509743,
              39.09366
            ],
            [
              -84.50608223252529,
              39.0950807457156
            ],
            [
              -84.502062,
              39.096641
            ],
            [
              -84.496543,
              39.10026
            ],
            [
              -84.49583814195579,
              39.1008138170347
            ],
            [
              -84.493743,
              39.10246
            ],
            [
              -84.487743,
              39.11076
            ],
            [
              -84.480943,
              39.11676
            ],
            [
              -84.476243,
              39.11916
            ],
            [
              -84.4712374796217,
              39.121179417097
            ],
            [
              -84.470542,
              39.12146
            ],
            [
              -84.462042,
              39.12176
            ],
            [
              -84.455342,
              39.12036
            ],
            [
              -84.449793,
              39.117754
            ],
            [
              -84.445242,
              39.114461
            ],
            [
              -84.440642,
              39.109661
            ],
            [
              -84.435541,
              39.102261
            ],
            [
              -84.432841,
              39.094261
            ],
            [
              -84.433941,
              39.092461
            ],
            [
              -84.434641,
              39.086861
            ],
            [
              -84.432941,
              39.083961
            ],
            [
              -84.432641,
              39.078261
            ],
            [
              -84.433141,
              39.072961
            ],
            [
              -84.432341,
              39.067561
            ],
            [
              -84.429841,
              39.058262
            ],
            [
              -84.427913,
              39.054962
            ],
            [
              -84.42573,
              39.053059
            ],
            [
              -84.421467,
              39.050938
            ],
            [
              -84.406941,
              39.045662
            ],
            [
              -84.40254,
              39.045662
            ],
            [
              -84.40094,
              39.046362
            ],
            [
              -84.39414,
              39.045262
            ],
            [
              -84.38684,
              39.045162
            ],
            [
              -84.360439,
              39.041362
            ],
            [
              -84.346039,
              39.036963
            ],
            [
              -84.336339,
              39.032863
            ],
            [
              -84.3294235658147,
              39.0290524546326
            ],
            [
              -84.326539,
              39.027463
            ],
            [
              -84.3199364709232,
              39.02208095553439
            ],
            [
              -84.31524,
              39.057335
            ],
            [
              -84.299328,
              39.174447
            ],
            [
              -84.288288,
              39.186487
            ],
            [
              -84.276377,
              39.203873
            ],
            [
              -84.284304,
              39.213243
            ],
            [
              -84.309356,
              39.208774
            ],
            [
              -84.320028,
              39.223433
            ],
            [
              -84.282224,
              39.252263
            ],
            [
              -84.261408,
              39.264686
            ],
            [
              -84.259431,
              39.270796
            ],
            [
              -84.258902,
              39.273743
            ],
            [
              -84.256506,
              39.27642
            ],
            [
              -84.262461,
              39.288379
            ],
            [
              -84.334105,
              39.293287
            ],
            [
              -84.353209,
              39.292287
            ],
            [
              -84.465455,
              39.302922
            ],
            [
              -84.4832,
              39.301697
            ],
            [
              -84.504644,
              39.305957
            ],
            [
              -84.562347,
              39.308079
            ],
            [
              -84.630047,
              39.312056
            ],
            [
              -84.641814,
              39.305044
            ],
            [
              -84.70328,
              39.304748
            ],
            [
              -84.81945096866738,
              39.305153361544896
            ],
            [
              -84.819451,
              39.305152
            ],
            [
              -84.819622,
              39.27159
            ],
            [
              -84.819633,
              39.261855
            ],
            [
              -84.819859,
              39.251018
            ],
            [
              -84.819801,
              39.247806
            ],
            [
              -84.819813,
              39.244334
            ],
            [
              -84.820159,
              39.227225
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "065",
      "COUNTYNS": "01074045",
      "AFFGEOID": "0500000US39065",
      "GEOID": "39065",
      "NAME": "Hardin",
      "LSAD": "06",
      "ALAND": 1218342693,
      "AWATER": 629922,
      "County_state": "Hardin,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.880063,
              40.819919
            ],
            [
              -83.880194,
              40.64469
            ],
            [
              -83.879932,
              40.538708
            ],
            [
              -83.879838,
              40.531861
            ],
            [
              -83.855915,
              40.530438
            ],
            [
              -83.520036,
              40.509287
            ],
            [
              -83.520227,
              40.504077
            ],
            [
              -83.418378,
              40.505232
            ],
            [
              -83.415303,
              40.515495
            ],
            [
              -83.420203,
              40.603183
            ],
            [
              -83.419828,
              40.643573
            ],
            [
              -83.419836,
              40.686823
            ],
            [
              -83.440278,
              40.687611
            ],
            [
              -83.440203,
              40.702303
            ],
            [
              -83.495799,
              40.701617
            ],
            [
              -83.496296,
              40.81794
            ],
            [
              -83.515883,
              40.818134
            ],
            [
              -83.880063,
              40.819919
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "027",
      "COUNTYNS": "01074026",
      "AFFGEOID": "0500000US39027",
      "GEOID": "39027",
      "NAME": "Clinton",
      "LSAD": "06",
      "ALAND": 1058487159,
      "AWATER": 9351754,
      "County_state": "Clinton,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.006782,
              39.255068
            ],
            [
              -83.99312,
              39.254235
            ],
            [
              -83.865679,
              39.247333
            ],
            [
              -83.834404,
              39.245715
            ],
            [
              -83.835869,
              39.223304
            ],
            [
              -83.813873,
              39.223044
            ],
            [
              -83.80134,
              39.231567
            ],
            [
              -83.784793,
              39.262888
            ],
            [
              -83.590878,
              39.378736
            ],
            [
              -83.576286,
              39.544547
            ],
            [
              -83.585523,
              39.545109
            ],
            [
              -83.670196,
              39.550254
            ],
            [
              -83.977005,
              39.569169
            ],
            [
              -84.006782,
              39.255068
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "003",
      "COUNTYNS": "01074015",
      "AFFGEOID": "0500000US39003",
      "GEOID": "39003",
      "NAME": "Allen",
      "LSAD": "06",
      "ALAND": 1042470095,
      "AWATER": 11266164,
      "County_state": "Allen,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.397374,
              40.815941
            ],
            [
              -84.396778,
              40.684926
            ],
            [
              -84.222799,
              40.685957
            ],
            [
              -84.222749,
              40.656948
            ],
            [
              -84.107908,
              40.657938
            ],
            [
              -84.107787,
              40.643069
            ],
            [
              -83.880194,
              40.64469
            ],
            [
              -83.880063,
              40.819919
            ],
            [
              -83.880423,
              40.920429
            ],
            [
              -83.995228,
              40.919843
            ],
            [
              -83.995165,
              40.905066
            ],
            [
              -84.109516,
              40.90473
            ],
            [
              -84.109586,
              40.860994
            ],
            [
              -84.340531,
              40.859099
            ],
            [
              -84.340101,
              40.816143
            ],
            [
              -84.397374,
              40.815941
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "051",
      "COUNTYNS": "01074038",
      "AFFGEOID": "0500000US39051",
      "GEOID": "39051",
      "NAME": "Fulton",
      "LSAD": "06",
      "ALAND": 1050092648,
      "AWATER": 4621138,
      "County_state": "Fulton,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.39954710435259,
              41.705860430932304
            ],
            [
              -84.398375,
              41.61698
            ],
            [
              -84.381312,
              41.61673
            ],
            [
              -84.380735,
              41.513942
            ],
            [
              -84.342051,
              41.514107
            ],
            [
              -84.341902,
              41.485519
            ],
            [
              -84.114863,
              41.486304
            ],
            [
              -83.882943,
              41.487543
            ],
            [
              -83.882487,
              41.599801
            ],
            [
              -83.877368,
              41.617547
            ],
            [
              -83.8803872579474,
              41.720089447778804
            ],
            [
              -83.880539,
              41.720081
            ],
            [
              -83.899764,
              41.719961
            ],
            [
              -83.998849,
              41.716822
            ],
            [
              -84.019373,
              41.716668
            ],
            [
              -84.134417,
              41.712931
            ],
            [
              -84.3604162952785,
              41.7066246193358
            ],
            [
              -84.360546,
              41.706621
            ],
            [
              -84.396547,
              41.705935
            ],
            [
              -84.39954710435259,
              41.705860430932304
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "079",
      "COUNTYNS": "01074052",
      "AFFGEOID": "0500000US39079",
      "GEOID": "39079",
      "NAME": "Jackson",
      "LSAD": "06",
      "ALAND": 1088598925,
      "AWATER": 3167846,
      "County_state": "Jackson,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.785891,
              39.168769
            ],
            [
              -82.80709,
              38.948073
            ],
            [
              -82.753391,
              38.945373
            ],
            [
              -82.76069,
              38.854875
            ],
            [
              -82.650039,
              38.849073
            ],
            [
              -82.575583,
              38.844478
            ],
            [
              -82.574383,
              38.853475
            ],
            [
              -82.455179,
              38.844577
            ],
            [
              -82.435309,
              39.035079
            ],
            [
              -82.424677,
              39.137972
            ],
            [
              -82.540282,
              39.144471
            ],
            [
              -82.536414,
              39.180775
            ],
            [
              -82.651486,
              39.189567
            ],
            [
              -82.650087,
              39.202269
            ],
            [
              -82.762892,
              39.207967
            ],
            [
              -82.766692,
              39.167768
            ],
            [
              -82.785891,
              39.168769
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "009",
      "COUNTYNS": "01074018",
      "AFFGEOID": "0500000US39009",
      "GEOID": "39009",
      "NAME": "Athens",
      "LSAD": "06",
      "ALAND": 1304383737,
      "AWATER": 12463875,
      "County_state": "Athens,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.289663,
              39.384195
            ],
            [
              -82.298476,
              39.294996
            ],
            [
              -82.260243,
              39.292915
            ],
            [
              -82.268583,
              39.20376
            ],
            [
              -81.960882,
              39.188744
            ],
            [
              -81.7558150545194,
              39.180524196556696
            ],
            [
              -81.755754,
              39.180976
            ],
            [
              -81.752754,
              39.184676
            ],
            [
              -81.749853,
              39.186489
            ],
            [
              -81.741533,
              39.189596
            ],
            [
              -81.737085,
              39.193836
            ],
            [
              -81.735805,
              39.196268
            ],
            [
              -81.733357,
              39.205868
            ],
            [
              -81.729949,
              39.211884
            ],
            [
              -81.726973,
              39.215068
            ],
            [
              -81.7255834730739,
              39.2158352234561
            ],
            [
              -81.721808,
              39.269597
            ],
            [
              -81.818361,
              39.272581
            ],
            [
              -81.816614,
              39.302058
            ],
            [
              -81.835447,
              39.302807
            ],
            [
              -81.834635,
              39.317563
            ],
            [
              -81.853668,
              39.318165
            ],
            [
              -81.844863,
              39.450216
            ],
            [
              -82.055779,
              39.460053
            ],
            [
              -82.047528,
              39.550825
            ],
            [
              -82.159161,
              39.556574
            ],
            [
              -82.167379,
              39.466307
            ],
            [
              -82.28041,
              39.472906
            ],
            [
              -82.289663,
              39.384195
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "069",
      "COUNTYNS": "01074047",
      "AFFGEOID": "0500000US39069",
      "GEOID": "39069",
      "NAME": "Henry",
      "LSAD": "06",
      "ALAND": 1077461474,
      "AWATER": 9687645,
      "County_state": "Henry,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.341902,
              41.485519
            ],
            [
              -84.341664,
              41.427568
            ],
            [
              -84.228454,
              41.42781
            ],
            [
              -84.228222,
              41.165862
            ],
            [
              -83.881149,
              41.167824
            ],
            [
              -83.883234,
              41.414503
            ],
            [
              -83.882943,
              41.487543
            ],
            [
              -84.114863,
              41.486304
            ],
            [
              -84.341902,
              41.485519
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "047",
      "COUNTYNS": "01074036",
      "AFFGEOID": "0500000US39047",
      "GEOID": "39047",
      "NAME": "Fayette",
      "LSAD": "06",
      "ALAND": 1052469899,
      "AWATER": 1694037,
      "County_state": "Fayette,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.65333,
              39.716876
            ],
            [
              -83.670196,
              39.550254
            ],
            [
              -83.585523,
              39.545109
            ],
            [
              -83.576286,
              39.544547
            ],
            [
              -83.590878,
              39.378736
            ],
            [
              -83.372714,
              39.377416
            ],
            [
              -83.266737,
              39.516249
            ],
            [
              -83.252435,
              39.695438
            ],
            [
              -83.65333,
              39.716876
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "109",
      "COUNTYNS": "01074067",
      "AFFGEOID": "0500000US39109",
      "GEOID": "39109",
      "NAME": "Miami",
      "LSAD": "06",
      "ALAND": 1053028461,
      "AWATER": 7967436,
      "County_state": "Miami,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.432575,
              40.197037
            ],
            [
              -84.436332,
              40.180801
            ],
            [
              -84.431833,
              40.166777
            ],
            [
              -84.425902,
              39.919622
            ],
            [
              -84.157671,
              39.92297
            ],
            [
              -84.167065,
              39.912197
            ],
            [
              -84.157223,
              39.88563
            ],
            [
              -84.051039,
              39.879807
            ],
            [
              -84.036069,
              40.040182
            ],
            [
              -84.022919,
              40.183945
            ],
            [
              -84.218658,
              40.200004
            ],
            [
              -84.298866,
              40.198318
            ],
            [
              -84.432575,
              40.197037
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "123",
      "COUNTYNS": "01074074",
      "AFFGEOID": "0500000US39123",
      "GEOID": "39123",
      "NAME": "Ottawa",
      "LSAD": "06",
      "ALAND": 660091271,
      "AWATER": 855217016,
      "County_state": "Ottawa,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.84357293941049,
              41.647221240952895
            ],
            [
              -82.843602,
              41.647009
            ],
            [
              -82.842099,
              41.628323
            ],
            [
              -82.827011,
              41.633701
            ],
            [
              -82.8133766131183,
              41.646149980138205
            ],
            [
              -82.793069,
              41.664692
            ],
            [
              -82.782719,
              41.694003
            ],
            [
              -82.7834180161047,
              41.694670624824
            ],
            [
              -82.7861534128587,
              41.697283180907
            ],
            [
              -82.810487,
              41.720524
            ],
            [
              -82.810518,
              41.7205365755896
            ],
            [
              -82.820409,
              41.724549
            ],
            [
              -82.82572,
              41.72281
            ],
            [
              -82.835118,
              41.708971
            ],
            [
              -82.84357293941049,
              41.647221240952895
            ]
          ]
        ],
        [
          [
            [
              -83.415919,
              41.618938
            ],
            [
              -83.414626,
              41.500228
            ],
            [
              -83.338991,
              41.501189
            ],
            [
              -83.338196,
              41.457508
            ],
            [
              -83.067459,
              41.457169
            ],
            [
              -83.038646,
              41.457515
            ],
            [
              -83.03744,
              41.457533
            ],
            [
              -82.952224,
              41.458239
            ],
            [
              -82.91439,
              41.458822
            ],
            [
              -82.8344,
              41.479653
            ],
            [
              -82.834236,
              41.479708
            ],
            [
              -82.819491,
              41.484952
            ],
            [
              -82.739223,
              41.477755
            ],
            [
              -82.719236,
              41.482423
            ],
            [
              -82.6947220531314,
              41.493945145473006
            ],
            [
              -82.710013,
              41.49759
            ],
            [
              -82.713904,
              41.501697
            ],
            [
              -82.719811,
              41.510296
            ],
            [
              -82.7213279682042,
              41.514898840756395
            ],
            [
              -82.721914,
              41.516677
            ],
            [
              -82.711632,
              41.527201
            ],
            [
              -82.710935,
              41.536648
            ],
            [
              -82.717878,
              41.54193
            ],
            [
              -82.749907,
              41.54647
            ],
            [
              -82.77201,
              41.54058
            ],
            [
              -82.7843379637791,
              41.540666842396504
            ],
            [
              -82.785496,
              41.540675
            ],
            [
              -82.794324,
              41.546486
            ],
            [
              -82.820207,
              41.570664
            ],
            [
              -82.834101,
              41.587587
            ],
            [
              -82.847657,
              41.585639
            ],
            [
              -82.852957,
              41.583327
            ],
            [
              -82.859531,
              41.576371
            ],
            [
              -82.855197,
              41.564114
            ],
            [
              -82.85677,
              41.548262
            ],
            [
              -82.869422,
              41.533962
            ],
            [
              -82.875229,
              41.529684
            ],
            [
              -82.8882,
              41.522508
            ],
            [
              -82.897728,
              41.519241
            ],
            [
              -82.934369,
              41.514353
            ],
            [
              -82.969642,
              41.524229
            ],
            [
              -82.999916,
              41.538534
            ],
            [
              -83.028072,
              41.555656
            ],
            [
              -83.043079,
              41.567963
            ],
            [
              -83.066593,
              41.59534
            ],
            [
              -83.086036,
              41.60668
            ],
            [
              -83.103928,
              41.613558
            ],
            [
              -83.145887,
              41.617904
            ],
            [
              -83.1657126966893,
              41.623245528659595
            ],
            [
              -83.182375,
              41.623154
            ],
            [
              -83.238355,
              41.623246
            ],
            [
              -83.415919,
              41.618938
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "115",
      "COUNTYNS": "01074070",
      "AFFGEOID": "0500000US39115",
      "GEOID": "39115",
      "NAME": "Morgan",
      "LSAD": "06",
      "ALAND": 1078539439,
      "AWATER": 13873466,
      "County_state": "Morgan,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.076639,
              39.77096
            ],
            [
              -82.080224,
              39.727673
            ],
            [
              -82.024219,
              39.724686
            ],
            [
              -82.047528,
              39.550825
            ],
            [
              -82.055779,
              39.460053
            ],
            [
              -81.844863,
              39.450216
            ],
            [
              -81.825757,
              39.449172
            ],
            [
              -81.823158,
              39.494072
            ],
            [
              -81.760959,
              39.488583
            ],
            [
              -81.708527,
              39.480775
            ],
            [
              -81.707997,
              39.489321
            ],
            [
              -81.705075,
              39.53099
            ],
            [
              -81.722217,
              39.53155
            ],
            [
              -81.715522,
              39.5834
            ],
            [
              -81.688884,
              39.590511
            ],
            [
              -81.588185,
              39.586973
            ],
            [
              -81.586132,
              39.663993
            ],
            [
              -81.6432,
              39.665681
            ],
            [
              -81.639886,
              39.75342
            ],
            [
              -81.697442,
              39.755572
            ],
            [
              -82.020392,
              39.769059
            ],
            [
              -82.02522,
              39.768325
            ],
            [
              -82.076639,
              39.77096
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "163",
      "COUNTYNS": "01074093",
      "AFFGEOID": "0500000US39163",
      "GEOID": "39163",
      "NAME": "Vinton",
      "LSAD": "06",
      "ALAND": 1068008706,
      "AWATER": 6781201,
      "County_state": "Vinton,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.760111,
              39.237644
            ],
            [
              -82.762892,
              39.207967
            ],
            [
              -82.650087,
              39.202269
            ],
            [
              -82.651486,
              39.189567
            ],
            [
              -82.536414,
              39.180775
            ],
            [
              -82.540282,
              39.144471
            ],
            [
              -82.424677,
              39.137972
            ],
            [
              -82.435309,
              39.035079
            ],
            [
              -82.322874,
              39.027674
            ],
            [
              -82.306669,
              39.205494
            ],
            [
              -82.268583,
              39.20376
            ],
            [
              -82.260243,
              39.292915
            ],
            [
              -82.298476,
              39.294996
            ],
            [
              -82.289663,
              39.384195
            ],
            [
              -82.40223,
              39.390228
            ],
            [
              -82.514479,
              39.395767
            ],
            [
              -82.51758,
              39.369468
            ],
            [
              -82.634386,
              39.375966
            ],
            [
              -82.635686,
              39.361566
            ],
            [
              -82.748591,
              39.368165
            ],
            [
              -82.760111,
              39.237644
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "175",
      "COUNTYNS": "01074099",
      "AFFGEOID": "0500000US39175",
      "GEOID": "39175",
      "NAME": "Wyandot",
      "LSAD": "06",
      "ALAND": 1053773838,
      "AWATER": 1783531,
      "County_state": "Wyandot,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.514728,
              40.905115
            ],
            [
              -83.515883,
              40.818134
            ],
            [
              -83.496296,
              40.81794
            ],
            [
              -83.495799,
              40.701617
            ],
            [
              -83.440203,
              40.702303
            ],
            [
              -83.440278,
              40.687611
            ],
            [
              -83.419836,
              40.686823
            ],
            [
              -83.304997,
              40.687032
            ],
            [
              -83.304687,
              40.702006
            ],
            [
              -83.11136,
              40.702915
            ],
            [
              -83.112737,
              40.993447
            ],
            [
              -83.305004,
              40.992787
            ],
            [
              -83.420319,
              40.991889
            ],
            [
              -83.45785,
              40.991671
            ],
            [
              -83.457681,
              40.948517
            ],
            [
              -83.476793,
              40.948379
            ],
            [
              -83.476741,
              40.904964
            ],
            [
              -83.514728,
              40.905115
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "127",
      "COUNTYNS": "01074076",
      "AFFGEOID": "0500000US39127",
      "GEOID": "39127",
      "NAME": "Perry",
      "LSAD": "06",
      "ALAND": 1056682426,
      "AWATER": 11653682,
      "County_state": "Perry,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.462813,
              39.93038
            ],
            [
              -82.472402,
              39.837223
            ],
            [
              -82.396594,
              39.833057
            ],
            [
              -82.404246,
              39.744605
            ],
            [
              -82.366051,
              39.74252
            ],
            [
              -82.374531,
              39.654959
            ],
            [
              -82.37989,
              39.596738
            ],
            [
              -82.261954,
              39.590592
            ],
            [
              -82.263566,
              39.562137
            ],
            [
              -82.159161,
              39.556574
            ],
            [
              -82.047528,
              39.550825
            ],
            [
              -82.024219,
              39.724686
            ],
            [
              -82.080224,
              39.727673
            ],
            [
              -82.076639,
              39.77096
            ],
            [
              -82.072934,
              39.816227
            ],
            [
              -82.170047,
              39.82075
            ],
            [
              -82.162372,
              39.909374
            ],
            [
              -82.233974,
              39.91326
            ],
            [
              -82.418666,
              39.922447
            ],
            [
              -82.418179,
              39.927936
            ],
            [
              -82.462813,
              39.93038
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "021",
      "COUNTYNS": "01074023",
      "AFFGEOID": "0500000US39021",
      "GEOID": "39021",
      "NAME": "Champaign",
      "LSAD": "06",
      "ALAND": 1109624422,
      "AWATER": 3638887,
      "County_state": "Champaign,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.022919,
              40.183945
            ],
            [
              -84.036069,
              40.040182
            ],
            [
              -83.807225,
              40.027342
            ],
            [
              -83.516155,
              40.010188
            ],
            [
              -83.503714,
              40.111468
            ],
            [
              -83.494498,
              40.225467
            ],
            [
              -83.551338,
              40.22937
            ],
            [
              -83.785354,
              40.245213
            ],
            [
              -83.784196,
              40.260046
            ],
            [
              -84.014763,
              40.273459
            ],
            [
              -84.022919,
              40.183945
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "085",
      "COUNTYNS": "01074055",
      "AFFGEOID": "0500000US39085",
      "GEOID": "39085",
      "NAME": "Lake",
      "LSAD": "06",
      "ALAND": 593799763,
      "AWATER": 1942309063,
      "County_state": "Lake,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.48864,
              41.631348
            ],
            [
              -81.48804,
              41.588249
            ],
            [
              -81.487842,
              41.570051
            ],
            [
              -81.391005,
              41.569716
            ],
            [
              -81.29588,
              41.56995
            ],
            [
              -81.294669,
              41.640889
            ],
            [
              -81.190921,
              41.641335
            ],
            [
              -81.101422,
              41.641457
            ],
            [
              -81.102258,
              41.714307
            ],
            [
              -81.003631,
              41.715137
            ],
            [
              -81.004763,
              41.726436
            ],
            [
              -81.0021167069681,
              41.8549082048937
            ],
            [
              -81.002663388143,
              41.854846428433596
            ],
            [
              -81.01049,
              41.853962
            ],
            [
              -81.024525,
              41.846469
            ],
            [
              -81.05192,
              41.839557
            ],
            [
              -81.092716,
              41.822988
            ],
            [
              -81.0955918867242,
              41.8222155929206
            ],
            [
              -81.112885,
              41.817571
            ],
            [
              -81.184368,
              41.786671
            ],
            [
              -81.248672,
              41.761291
            ],
            [
              -81.2637509287717,
              41.7582387579878
            ],
            [
              -81.264224,
              41.758143
            ],
            [
              -81.279925,
              41.759944
            ],
            [
              -81.286925,
              41.760243
            ],
            [
              -81.301626,
              41.750543
            ],
            [
              -81.353229,
              41.727743
            ],
            [
              -81.388632,
              41.707144
            ],
            [
              -81.442645,
              41.673255
            ],
            [
              -81.466038,
              41.649148
            ],
            [
              -81.48864,
              41.631348
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "099",
      "COUNTYNS": "01074062",
      "AFFGEOID": "0500000US39099",
      "GEOID": "39099",
      "NAME": "Mahoning",
      "LSAD": "06",
      "ALAND": 1065795297,
      "AWATER": 35434368,
      "County_state": "Mahoning,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.086312,
              40.988031
            ],
            [
              -81.086679,
              40.901609
            ],
            [
              -80.896032,
              40.900995
            ],
            [
              -80.895813,
              40.930187
            ],
            [
              -80.821635,
              40.929775
            ],
            [
              -80.821923,
              40.900935
            ],
            [
              -80.51977742323069,
              40.9003241991296
            ],
            [
              -80.51979,
              40.900761
            ],
            [
              -80.5198187447909,
              40.9024401511485
            ],
            [
              -80.519891,
              40.906661
            ],
            [
              -80.519091,
              40.921061
            ],
            [
              -80.51900005923159,
              40.9873800011642
            ],
            [
              -80.518989,
              40.995445
            ],
            [
              -80.518927,
              41.015387
            ],
            [
              -80.51896,
              41.061546
            ],
            [
              -80.518928,
              41.070954
            ],
            [
              -80.51896,
              41.071866
            ],
            [
              -80.518999,
              41.075014
            ],
            [
              -80.519088,
              41.082074
            ],
            [
              -80.519092,
              41.090658
            ],
            [
              -80.519125,
              41.100819
            ],
            [
              -80.519192,
              41.105358
            ],
            [
              -80.518992,
              41.115958
            ],
            [
              -80.519056,
              41.125057
            ],
            [
              -80.519012,
              41.125057
            ],
            [
              -80.519012,
              41.1250929473499
            ],
            [
              -80.519012,
              41.125116
            ],
            [
              -80.519167,
              41.133343
            ],
            [
              -80.5191668077946,
              41.133388009323596
            ],
            [
              -80.789472,
              41.134159
            ],
            [
              -81.00229,
              41.134189
            ],
            [
              -81.00236,
              41.062093
            ],
            [
              -81.001695,
              40.987783
            ],
            [
              -81.086312,
              40.988031
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "107",
      "COUNTYNS": "01074066",
      "AFFGEOID": "0500000US39107",
      "GEOID": "39107",
      "NAME": "Mercer",
      "LSAD": "06",
      "ALAND": 1197738404,
      "AWATER": 28425902,
      "County_state": "Mercer,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.804504,
              40.411555
            ],
            [
              -84.804119,
              40.352844
            ],
            [
              -84.8041185884184,
              40.3527569382653
            ],
            [
              -84.599588,
              40.353209
            ],
            [
              -84.434631,
              40.354259
            ],
            [
              -84.434387,
              40.354534
            ],
            [
              -84.455418,
              40.358589
            ],
            [
              -84.455029,
              40.451305
            ],
            [
              -84.455617,
              40.553249
            ],
            [
              -84.455713,
              40.567888
            ],
            [
              -84.45618,
              40.684862
            ],
            [
              -84.456172,
              40.728306
            ],
            [
              -84.80211911064869,
              40.728145936904696
            ],
            [
              -84.802181,
              40.718602
            ],
            [
              -84.802094,
              40.702476
            ],
            [
              -84.802127,
              40.691405
            ],
            [
              -84.802157,
              40.689324
            ],
            [
              -84.80222,
              40.674776
            ],
            [
              -84.802193,
              40.660298
            ],
            [
              -84.802135,
              40.644859
            ],
            [
              -84.802265,
              40.572215
            ],
            [
              -84.80226501503509,
              40.57221195372949
            ],
            [
              -84.802483,
              40.528046
            ],
            [
              -84.802547,
              40.50181
            ],
            [
              -84.8038280958923,
              40.46540312861
            ],
            [
              -84.803928,
              40.462564
            ],
            [
              -84.804504,
              40.411555
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "125",
      "COUNTYNS": "01074075",
      "AFFGEOID": "0500000US39125",
      "GEOID": "39125",
      "NAME": "Paulding",
      "LSAD": "06",
      "ALAND": 1078561239,
      "AWATER": 6245392,
      "County_state": "Paulding,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.803413,
              41.164649
            ],
            [
              -84.80378,
              41.14052
            ],
            [
              -84.803234,
              41.121414
            ],
            [
              -84.803374,
              41.089302
            ],
            [
              -84.8033254118667,
              41.009575245394196
            ],
            [
              -84.80331311272211,
              40.9893939621464
            ],
            [
              -84.399486,
              40.990315
            ],
            [
              -84.341911,
              40.990692
            ],
            [
              -84.341602,
              41.165524
            ],
            [
              -84.341601,
              41.20903
            ],
            [
              -84.457059,
              41.209434
            ],
            [
              -84.457217,
              41.253485
            ],
            [
              -84.80349214742239,
              41.2525618431087
            ],
            [
              -84.803492,
              41.252531
            ],
            [
              -84.803472,
              41.173889
            ],
            [
              -84.803594,
              41.173203
            ],
            [
              -84.803413,
              41.164649
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "117",
      "COUNTYNS": "01074071",
      "AFFGEOID": "0500000US39117",
      "GEOID": "39117",
      "NAME": "Morrow",
      "LSAD": "06",
      "ALAND": 1051881049,
      "AWATER": 2819351,
      "County_state": "Morrow,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.020798,
              40.433795
            ],
            [
              -83.022079,
              40.418884
            ],
            [
              -82.924781,
              40.415004
            ],
            [
              -82.929588,
              40.358123
            ],
            [
              -82.744931,
              40.349603
            ],
            [
              -82.646503,
              40.345076
            ],
            [
              -82.642837,
              40.522111
            ],
            [
              -82.642796,
              40.550011
            ],
            [
              -82.623609,
              40.549879
            ],
            [
              -82.62476,
              40.637283
            ],
            [
              -82.644117,
              40.63724
            ],
            [
              -82.644977,
              40.666425
            ],
            [
              -82.626165,
              40.666132
            ],
            [
              -82.627767,
              40.709352
            ],
            [
              -82.689113,
              40.711033
            ],
            [
              -82.727164,
              40.711203
            ],
            [
              -82.858197,
              40.712423
            ],
            [
              -82.858302,
              40.705019
            ],
            [
              -82.859753,
              40.646395
            ],
            [
              -82.957715,
              40.635653
            ],
            [
              -82.958063,
              40.557934
            ],
            [
              -82.958401,
              40.490664
            ],
            [
              -83.016785,
              40.484511
            ],
            [
              -83.020798,
              40.433795
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "119",
      "COUNTYNS": "01074072",
      "AFFGEOID": "0500000US39119",
      "GEOID": "39119",
      "NAME": "Muskingum",
      "LSAD": "06",
      "ALAND": 1721328762,
      "AWATER": 20656658,
      "County_state": "Muskingum,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.231695,
              39.951075
            ],
            [
              -82.233974,
              39.91326
            ],
            [
              -82.162372,
              39.909374
            ],
            [
              -82.170047,
              39.82075
            ],
            [
              -82.072934,
              39.816227
            ],
            [
              -82.076639,
              39.77096
            ],
            [
              -82.02522,
              39.768325
            ],
            [
              -82.020392,
              39.769059
            ],
            [
              -81.697442,
              39.755572
            ],
            [
              -81.694146,
              39.842636
            ],
            [
              -81.691104,
              39.930756
            ],
            [
              -81.728611,
              39.931728
            ],
            [
              -81.716276,
              40.152166
            ],
            [
              -81.721987,
              40.152947
            ],
            [
              -82.187105,
              40.16688
            ],
            [
              -82.194786,
              40.020694
            ],
            [
              -82.198772,
              39.95014
            ],
            [
              -82.231695,
              39.951075
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "111",
      "COUNTYNS": "01074068",
      "AFFGEOID": "0500000US39111",
      "GEOID": "39111",
      "NAME": "Monroe",
      "LSAD": "06",
      "ALAND": 1180317770,
      "AWATER": 4495382,
      "County_state": "Monroe,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.313591,
              39.869471
            ],
            [
              -81.319125,
              39.70736
            ],
            [
              -81.280588,
              39.707112
            ],
            [
              -81.283075,
              39.605796
            ],
            [
              -81.264261,
              39.605344
            ],
            [
              -81.265347,
              39.57681
            ],
            [
              -81.246808,
              39.579037
            ],
            [
              -81.036791,
              39.572118
            ],
            [
              -81.03870601730101,
              39.5400475328739
            ],
            [
              -81.030169,
              39.545211
            ],
            [
              -81.026662,
              39.548547
            ],
            [
              -81.0239,
              39.552313
            ],
            [
              -81.020055,
              39.55541
            ],
            [
              -81.00866,
              39.562798
            ],
            [
              -80.996804,
              39.56912
            ],
            [
              -80.993695,
              39.571253
            ],
            [
              -80.987732,
              39.577262
            ],
            [
              -80.978664,
              39.583517
            ],
            [
              -80.970436,
              39.590127
            ],
            [
              -80.943782,
              39.606926
            ],
            [
              -80.936906,
              39.612616
            ],
            [
              -80.933292,
              39.614812
            ],
            [
              -80.925841,
              39.617396
            ],
            [
              -80.91762,
              39.618703
            ],
            [
              -80.906247,
              39.618431
            ],
            [
              -80.896514,
              39.616757
            ],
            [
              -80.892208,
              39.616756
            ],
            [
              -80.88036,
              39.620706
            ],
            [
              -80.876002,
              39.627084
            ],
            [
              -80.87102,
              39.638963
            ],
            [
              -80.870473,
              39.641764
            ],
            [
              -80.870771,
              39.642885
            ],
            [
              -80.869802,
              39.646896
            ],
            [
              -80.866647,
              39.652616
            ],
            [
              -80.865575,
              39.662751
            ],
            [
              -80.86667,
              39.678487
            ],
            [
              -80.86633,
              39.683167
            ],
            [
              -80.865805,
              39.686484
            ],
            [
              -80.863698,
              39.691724
            ],
            [
              -80.861718,
              39.693625
            ],
            [
              -80.854599,
              39.697473
            ],
            [
              -80.852,
              39.69856
            ],
            [
              -80.844721,
              39.69944
            ],
            [
              -80.839112,
              39.701033
            ],
            [
              -80.833882,
              39.703497
            ],
            [
              -80.831871,
              39.705655
            ],
            [
              -80.829764,
              39.711839
            ],
            [
              -80.829723,
              39.714041
            ],
            [
              -80.831551,
              39.719475
            ],
            [
              -80.83346277006619,
              39.72081235044139
            ],
            [
              -80.834563,
              39.721582
            ],
            [
              -80.836597,
              39.723925
            ],
            [
              -80.846091,
              39.737812
            ],
            [
              -80.852738,
              39.74104
            ],
            [
              -80.854717,
              39.742592
            ],
            [
              -80.865339,
              39.753251
            ],
            [
              -80.867596,
              39.757116
            ],
            [
              -80.869933,
              39.763555
            ],
            [
              -80.869092,
              39.766364
            ],
            [
              -80.866329,
              39.771738
            ],
            [
              -80.863048,
              39.775197
            ],
            [
              -80.835311,
              39.79069
            ],
            [
              -80.826079,
              39.798584
            ],
            [
              -80.824969,
              39.801092
            ],
            [
              -80.822181,
              39.811708
            ],
            [
              -80.82248,
              39.824971
            ],
            [
              -80.82303,
              39.827484
            ],
            [
              -80.826228,
              39.835802
            ],
            [
              -80.826964,
              39.841656
            ],
            [
              -80.826124,
              39.844238
            ],
            [
              -80.824276,
              39.847159
            ],
            [
              -80.821279,
              39.849982
            ],
            [
              -81.017147,
              39.858182
            ],
            [
              -81.017087,
              39.859875
            ],
            [
              -81.237316,
              39.867828
            ],
            [
              -81.313591,
              39.869471
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "015",
      "COUNTYNS": "01074020",
      "AFFGEOID": "0500000US39015",
      "GEOID": "39015",
      "NAME": "Brown",
      "LSAD": "06",
      "ALAND": 1269138602,
      "AWATER": 8895019,
      "County_state": "Brown,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -84.052684882421,
              38.7713751994327
            ],
            [
              -84.051642,
              38.771397
            ],
            [
              -84.044486,
              38.770572
            ],
            [
              -84.023113,
              38.775061
            ],
            [
              -84.006104,
              38.780156
            ],
            [
              -83.995447,
              38.781912
            ],
            [
              -83.978814,
              38.787104
            ],
            [
              -83.962123,
              38.787384
            ],
            [
              -83.953546,
              38.786172
            ],
            [
              -83.943978,
              38.783616
            ],
            [
              -83.928454,
              38.774583
            ],
            [
              -83.928591,
              38.772203
            ],
            [
              -83.926986,
              38.771562
            ],
            [
              -83.917217,
              38.769665
            ],
            [
              -83.912922,
              38.769763
            ],
            [
              -83.903971,
              38.76816
            ],
            [
              -83.90391694448489,
              38.7681517942292
            ],
            [
              -83.887107,
              38.7656
            ],
            [
              -83.873168,
              38.762418
            ],
            [
              -83.86653,
              38.7602
            ],
            [
              -83.859028,
              38.756793
            ],
            [
              -83.852085,
              38.751433
            ],
            [
              -83.848734,
              38.747178
            ],
            [
              -83.846207,
              38.74229
            ],
            [
              -83.844676,
              38.737439
            ],
            [
              -83.84274,
              38.730365
            ],
            [
              -83.842506,
              38.727019
            ],
            [
              -83.841689,
              38.724264
            ],
            [
              -83.840595,
              38.721912
            ],
            [
              -83.836696,
              38.717857
            ],
            [
              -83.834015,
              38.716008
            ],
            [
              -83.821854,
              38.709575
            ],
            [
              -83.81388,
              38.707446
            ],
            [
              -83.806317,
              38.706613
            ],
            [
              -83.798549,
              38.704668
            ],
            [
              -83.790676,
              38.701676
            ],
            [
              -83.787113,
              38.699489
            ],
            [
              -83.78362,
              38.695641
            ],
            [
              -83.779961,
              38.684907
            ],
            [
              -83.777141,
              38.671205
            ],
            [
              -83.775761,
              38.666748
            ],
            [
              -83.77216,
              38.65815
            ],
            [
              -83.769347,
              38.65522
            ],
            [
              -83.76509,
              38.652881
            ],
            [
              -83.762445,
              38.652103
            ],
            [
              -83.74992,
              38.649613
            ],
            [
              -83.738207,
              38.647932
            ],
            [
              -83.720779,
              38.646704
            ],
            [
              -83.717915,
              38.645247
            ],
            [
              -83.716933,
              38.643657
            ],
            [
              -83.713405,
              38.641591
            ],
            [
              -83.708164,
              38.639843
            ],
            [
              -83.7053136208764,
              38.6397614234931
            ],
            [
              -83.703374,
              38.678991
            ],
            [
              -83.673018,
              39.02043
            ],
            [
              -83.70535,
              39.021351
            ],
            [
              -83.872214,
              39.021304
            ],
            [
              -83.869762,
              39.05553
            ],
            [
              -83.865679,
              39.247333
            ],
            [
              -83.99312,
              39.254235
            ],
            [
              -83.997763,
              39.191023
            ],
            [
              -84.052684882421,
              38.7713751994327
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "007",
      "COUNTYNS": "01074017",
      "AFFGEOID": "0500000US39007",
      "GEOID": "39007",
      "NAME": "Ashtabula",
      "LSAD": "06",
      "ALAND": 1818360011,
      "AWATER": 1724498213,
      "County_state": "Ashtabula,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.004763,
              41.726436
            ],
            [
              -81.003631,
              41.715137
            ],
            [
              -81.002836,
              41.571342
            ],
            [
              -81.003125,
              41.501685
            ],
            [
              -80.519225,
              41.499924
            ],
            [
              -80.519157,
              41.528769
            ],
            [
              -80.519339,
              41.539297
            ],
            [
              -80.5193534844336,
              41.644285002830394
            ],
            [
              -80.519357,
              41.669767
            ],
            [
              -80.519424,
              41.671228
            ],
            [
              -80.519373,
              41.701473
            ],
            [
              -80.519408,
              41.739359
            ],
            [
              -80.519369,
              41.752487
            ],
            [
              -80.519239,
              41.765138
            ],
            [
              -80.519293557418,
              41.849563030893194
            ],
            [
              -80.519345,
              41.929168
            ],
            [
              -80.519304,
              41.943992
            ],
            [
              -80.519405,
              41.976158
            ],
            [
              -80.519425,
              41.977523
            ],
            [
              -80.553836,
              41.967815
            ],
            [
              -80.581882,
              41.95761
            ],
            [
              -80.720816,
              41.919744
            ],
            [
              -80.757945,
              41.913352
            ],
            [
              -80.782052,
              41.906635
            ],
            [
              -80.784682,
              41.911525
            ],
            [
              -80.800794,
              41.909635
            ],
            [
              -80.808697,
              41.901606
            ],
            [
              -80.814943,
              41.897694
            ],
            [
              -80.900342,
              41.868912
            ],
            [
              -80.936244,
              41.862352
            ],
            [
              -80.9736741907041,
              41.8581222866147
            ],
            [
              -80.9917993274973,
              41.856074096844196
            ],
            [
              -81.0021167069681,
              41.8549082048937
            ],
            [
              -81.004763,
              41.726436
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "043",
      "COUNTYNS": "01074034",
      "AFFGEOID": "0500000US39043",
      "GEOID": "39043",
      "NAME": "Erie",
      "LSAD": "06",
      "ALAND": 651357083,
      "AWATER": 969654879,
      "County_state": "Erie,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.735707,
              41.603361
            ],
            [
              -82.735766,
              41.600982
            ],
            [
              -82.725827,
              41.595199
            ],
            [
              -82.702027,
              41.585437
            ],
            [
              -82.6998976642187,
              41.5855105801493
            ],
            [
              -82.688744,
              41.585896
            ],
            [
              -82.686033,
              41.587246
            ],
            [
              -82.680669,
              41.594611
            ],
            [
              -82.677772,
              41.617986
            ],
            [
              -82.68015,
              41.61897
            ],
            [
              -82.691123,
              41.611331
            ],
            [
              -82.700208,
              41.61219
            ],
            [
              -82.703438,
              41.617734
            ],
            [
              -82.70731,
              41.619609
            ],
            [
              -82.718802,
              41.619629
            ],
            [
              -82.735707,
              41.603361
            ]
          ]
        ],
        [
          [
            [
              -82.952224,
              41.458239
            ],
            [
              -82.952936,
              41.430172
            ],
            [
              -82.903078,
              41.430471
            ],
            [
              -82.847804,
              41.430516
            ],
            [
              -82.844565,
              41.362394
            ],
            [
              -82.841475,
              41.290023
            ],
            [
              -82.342314,
              41.283554
            ],
            [
              -82.345048,
              41.353479
            ],
            [
              -82.345202,
              41.35743
            ],
            [
              -82.3480756474318,
              41.428431419784495
            ],
            [
              -82.361784,
              41.426644
            ],
            [
              -82.398086,
              41.413945
            ],
            [
              -82.431315,
              41.396866
            ],
            [
              -82.460599,
              41.386316
            ],
            [
              -82.481214,
              41.381342
            ],
            [
              -82.499099,
              41.381541
            ],
            [
              -82.513827,
              41.384257
            ],
            [
              -82.55808,
              41.400005
            ],
            [
              -82.6019474597511,
              41.421181674924
            ],
            [
              -82.616952,
              41.428425
            ],
            [
              -82.617745,
              41.431833
            ],
            [
              -82.658302,
              41.461878
            ],
            [
              -82.6849338195907,
              41.4892534137297
            ],
            [
              -82.687921,
              41.492324
            ],
            [
              -82.6886160070397,
              41.4924896666246
            ],
            [
              -82.6947220531314,
              41.493945145473006
            ],
            [
              -82.719236,
              41.482423
            ],
            [
              -82.739223,
              41.477755
            ],
            [
              -82.819491,
              41.484952
            ],
            [
              -82.834236,
              41.479708
            ],
            [
              -82.8344,
              41.479653
            ],
            [
              -82.91439,
              41.458822
            ],
            [
              -82.952224,
              41.458239
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "39",
      "COUNTYFP": "121",
      "COUNTYNS": "01074073",
      "AFFGEOID": "0500000US39121",
      "GEOID": "39121",
      "NAME": "Noble",
      "LSAD": "06",
      "ALAND": 1030846457,
      "AWATER": 16984489,
      "County_state": "Noble,39"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.694146,
              39.842636
            ],
            [
              -81.697442,
              39.755572
            ],
            [
              -81.639886,
              39.75342
            ],
            [
              -81.6432,
              39.665681
            ],
            [
              -81.586132,
              39.663993
            ],
            [
              -81.588185,
              39.586973
            ],
            [
              -81.473471,
              39.583318
            ],
            [
              -81.47316,
              39.645976
            ],
            [
              -81.451935,
              39.645449
            ],
            [
              -81.452712,
              39.631165
            ],
            [
              -81.413665,
              39.630502
            ],
            [
              -81.41385,
              39.615874
            ],
            [
              -81.395036,
              39.615681
            ],
            [
              -81.394934,
              39.601547
            ],
            [
              -81.35629,
              39.600865
            ],
            [
              -81.356445,
              39.593512
            ],
            [
              -81.28353,
              39.591838
            ],
            [
              -81.283075,
              39.605796
            ],
            [
              -81.280588,
              39.707112
            ],
            [
              -81.319125,
              39.70736
            ],
            [
              -81.313591,
              39.869471
            ],
            [
              -81.237316,
              39.867828
            ],
            [
              -81.234053,
              39.95127
            ],
            [
              -81.347838,
              39.949962
            ],
            [
              -81.386006,
              39.950694
            ],
            [
              -81.387312,
              39.921396
            ],
            [
              -81.46275,
              39.9235
            ],
            [
              -81.464108,
              39.89455
            ],
            [
              -81.57859,
              39.897683
            ],
            [
              -81.580313,
              39.839104
            ],
            [
              -81.694146,
              39.842636
            ]
          ]
        ]
      ]
    }
  }
]
};