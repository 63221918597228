export const counties_va = {
  "type":"FeatureCollection","name":"va_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "820",
      "COUNTYNS": "01498443",
      "AFFGEOID": "0500000US51820",
      "GEOID": "51820",
      "NAME": "Waynesboro",
      "LSAD": "25",
      "ALAND": 38724176,
      "AWATER": 411928,
      "County_state": "Waynesboro,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.950277,
              38.069486
            ],
            [
              -78.943198,
              38.06202
            ],
            [
              -78.949417,
              38.056956
            ],
            [
              -78.946801,
              38.051985
            ],
            [
              -78.93023,
              38.049548
            ],
            [
              -78.921941,
              38.031403
            ],
            [
              -78.908001,
              38.043128
            ],
            [
              -78.901192,
              38.045255
            ],
            [
              -78.891772,
              38.039438
            ],
            [
              -78.877327,
              38.042746
            ],
            [
              -78.871469,
              38.040441
            ],
            [
              -78.855918,
              38.074652
            ],
            [
              -78.864449,
              38.095738
            ],
            [
              -78.879254,
              38.091649
            ],
            [
              -78.903795,
              38.099802
            ],
            [
              -78.950277,
              38.069486
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "595",
      "COUNTYNS": "01498422",
      "AFFGEOID": "0500000US51595",
      "GEOID": "51595",
      "NAME": "Emporia",
      "LSAD": "25",
      "ALAND": 17881071,
      "AWATER": 165621,
      "County_state": "Emporia,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.556573,
              36.713662
            ],
            [
              -77.563888,
              36.703067
            ],
            [
              -77.556524,
              36.674874
            ],
            [
              -77.541541,
              36.674703
            ],
            [
              -77.509246,
              36.682998
            ],
            [
              -77.517511,
              36.699562
            ],
            [
              -77.51445,
              36.720588
            ],
            [
              -77.521835,
              36.712827
            ],
            [
              -77.556573,
              36.713662
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "530",
      "COUNTYNS": "01498417",
      "AFFGEOID": "0500000US51530",
      "GEOID": "51530",
      "NAME": "Buena Vista",
      "LSAD": "25",
      "ALAND": 16673676,
      "AWATER": 219579,
      "County_state": "Buena Vista,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.381509,
              37.742149
            ],
            [
              -79.369847,
              37.727556
            ],
            [
              -79.381361,
              37.706578
            ],
            [
              -79.371705,
              37.695747
            ],
            [
              -79.349678,
              37.719734
            ],
            [
              -79.326012,
              37.743269
            ],
            [
              -79.331017,
              37.759843
            ],
            [
              -79.348224,
              37.755482
            ],
            [
              -79.381509,
              37.742149
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "590",
      "COUNTYNS": "01498421",
      "AFFGEOID": "0500000US51590",
      "GEOID": "51590",
      "NAME": "Danville",
      "LSAD": "25",
      "ALAND": 110751018,
      "AWATER": 2434869,
      "County_state": "Danville,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.505048,
              36.616744
            ],
            [
              -79.518688,
              36.603608
            ],
            [
              -79.491506,
              36.581016
            ],
            [
              -79.456537,
              36.576699
            ],
            [
              -79.472112,
              36.560621
            ],
            [
              -79.4700322670177,
              36.5410249389508
            ],
            [
              -79.445961,
              36.541195
            ],
            [
              -79.445687,
              36.541218
            ],
            [
              -79.3431171666517,
              36.5411766468952
            ],
            [
              -79.351535,
              36.55124
            ],
            [
              -79.33619,
              36.5717
            ],
            [
              -79.304626,
              36.570296
            ],
            [
              -79.304635,
              36.580776
            ],
            [
              -79.316418,
              36.582655
            ],
            [
              -79.330969,
              36.601669
            ],
            [
              -79.365013,
              36.607797
            ],
            [
              -79.369429,
              36.635074
            ],
            [
              -79.385843,
              36.637719
            ],
            [
              -79.398094,
              36.644037
            ],
            [
              -79.420829,
              36.603541
            ],
            [
              -79.42209,
              36.60064
            ],
            [
              -79.452673,
              36.612179
            ],
            [
              -79.500989,
              36.618146
            ],
            [
              -79.505048,
              36.616744
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "640",
      "COUNTYNS": "01498426",
      "AFFGEOID": "0500000US51640",
      "GEOID": "51640",
      "NAME": "Galax",
      "LSAD": "25",
      "ALAND": 21333585,
      "AWATER": 103777,
      "County_state": "Galax,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.944109,
              36.676303
            ],
            [
              -80.947754,
              36.648361
            ],
            [
              -80.921711,
              36.643533
            ],
            [
              -80.931147,
              36.625268
            ],
            [
              -80.916043,
              36.629343
            ],
            [
              -80.914173,
              36.650246
            ],
            [
              -80.900242,
              36.663898
            ],
            [
              -80.878301,
              36.692618
            ],
            [
              -80.917573,
              36.694133
            ],
            [
              -80.932271,
              36.671593
            ],
            [
              -80.944109,
              36.676303
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "660",
      "COUNTYNS": "01498563",
      "AFFGEOID": "0500000US51660",
      "GEOID": "51660",
      "NAME": "Harrisonburg",
      "LSAD": "25",
      "ALAND": 44890270,
      "AWATER": 149190,
      "County_state": "Harrisonburg,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.915443,
              38.437455
            ],
            [
              -78.902201,
              38.42436
            ],
            [
              -78.914168,
              38.390026
            ],
            [
              -78.890002,
              38.390814
            ],
            [
              -78.88182,
              38.412128
            ],
            [
              -78.837168,
              38.417689
            ],
            [
              -78.827031,
              38.420552
            ],
            [
              -78.833991,
              38.453551
            ],
            [
              -78.870993,
              38.480001
            ],
            [
              -78.871246,
              38.489338
            ],
            [
              -78.888973,
              38.455357
            ],
            [
              -78.900589,
              38.459217
            ],
            [
              -78.915443,
              38.437455
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "690",
      "COUNTYNS": "01789072",
      "AFFGEOID": "0500000US51690",
      "GEOID": "51690",
      "NAME": "Martinsville",
      "LSAD": "25",
      "ALAND": 28376605,
      "AWATER": 133615,
      "County_state": "Martinsville,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.904077,
              36.684127
            ],
            [
              -79.881533,
              36.670246
            ],
            [
              -79.859761,
              36.650368
            ],
            [
              -79.837139,
              36.645957
            ],
            [
              -79.838012,
              36.659295
            ],
            [
              -79.831433,
              36.646321
            ],
            [
              -79.826259,
              36.657937
            ],
            [
              -79.843737,
              36.672443
            ],
            [
              -79.839883,
              36.691622
            ],
            [
              -79.837359,
              36.704137
            ],
            [
              -79.865814,
              36.712582
            ],
            [
              -79.88126,
              36.711537
            ],
            [
              -79.904077,
              36.684127
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "540",
      "COUNTYNS": "01789068",
      "AFFGEOID": "0500000US51540",
      "GEOID": "51540",
      "NAME": "Charlottesville",
      "LSAD": "25",
      "ALAND": 26533566,
      "AWATER": 47160,
      "County_state": "Charlottesville,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.523035,
              38.026329
            ],
            [
              -78.520375,
              38.018355
            ],
            [
              -78.519284,
              38.010628
            ],
            [
              -78.491355,
              38.017503
            ],
            [
              -78.474959,
              38.013758
            ],
            [
              -78.469504,
              38.011745
            ],
            [
              -78.454668,
              38.021604
            ],
            [
              -78.449214,
              38.058929
            ],
            [
              -78.46613,
              38.0457
            ],
            [
              -78.464439,
              38.06455
            ],
            [
              -78.485202,
              38.068974
            ],
            [
              -78.503748,
              38.057069
            ],
            [
              -78.507616,
              38.056269
            ],
            [
              -78.504839,
              38.047275
            ],
            [
              -78.516072,
              38.043486
            ],
            [
              -78.515527,
              38.036644
            ],
            [
              -78.503456,
              38.036746
            ],
            [
              -78.497791,
              38.033255
            ],
            [
              -78.509613,
              38.032161
            ],
            [
              -78.523035,
              38.026329
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "099",
      "COUNTYNS": "01480137",
      "AFFGEOID": "0500000US51099",
      "GEOID": "51099",
      "NAME": "King George",
      "LSAD": "06",
      "ALAND": 465080763,
      "AWATER": 21140635,
      "County_state": "King George,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.339399,
              38.303089
            ],
            [
              -77.345102,
              38.261468
            ],
            [
              -77.326692,
              38.245136
            ],
            [
              -77.309419,
              38.234737
            ],
            [
              -77.290302,
              38.238728
            ],
            [
              -77.280402,
              38.224429
            ],
            [
              -77.270602,
              38.249828
            ],
            [
              -77.249801,
              38.250728
            ],
            [
              -77.224689,
              38.242207
            ],
            [
              -77.22871,
              38.235106
            ],
            [
              -77.251801,
              38.229628
            ],
            [
              -77.242001,
              38.210729
            ],
            [
              -77.253701,
              38.18913
            ],
            [
              -77.2298,
              38.18783
            ],
            [
              -77.2323,
              38.196929
            ],
            [
              -77.2143,
              38.197029
            ],
            [
              -77.188086,
              38.174402
            ],
            [
              -77.164598,
              38.16503
            ],
            [
              -77.148998,
              38.16993
            ],
            [
              -77.132897,
              38.164331
            ],
            [
              -77.115997,
              38.149931
            ],
            [
              -77.084596,
              38.160231
            ],
            [
              -77.073796,
              38.141331
            ],
            [
              -77.061695,
              38.161631
            ],
            [
              -77.064289,
              38.171666
            ],
            [
              -77.050215,
              38.177916
            ],
            [
              -77.047627,
              38.200235
            ],
            [
              -77.044799,
              38.260407
            ],
            [
              -76.99767,
              38.278047
            ],
            [
              -77.026304,
              38.302685
            ],
            [
              -77.030683,
              38.311623
            ],
            [
              -77.020947,
              38.329273
            ],
            [
              -77.016932,
              38.341697
            ],
            [
              -77.011827,
              38.374554
            ],
            [
              -77.024866,
              38.386791
            ],
            [
              -77.043526,
              38.400548
            ],
            [
              -77.051437,
              38.399083
            ],
            [
              -77.056032,
              38.3962
            ],
            [
              -77.069956,
              38.377895
            ],
            [
              -77.08481,
              38.368297
            ],
            [
              -77.094665,
              38.367715
            ],
            [
              -77.104717,
              38.369655
            ],
            [
              -77.138224,
              38.367917
            ],
            [
              -77.155191,
              38.351047
            ],
            [
              -77.162692,
              38.345994
            ],
            [
              -77.17934,
              38.341915
            ],
            [
              -77.199433,
              38.34089
            ],
            [
              -77.240072,
              38.331598
            ],
            [
              -77.265295,
              38.333165
            ],
            [
              -77.279633,
              38.339444
            ],
            [
              -77.286202,
              38.347025
            ],
            [
              -77.2870968325169,
              38.3488000844294
            ],
            [
              -77.321448,
              38.345577
            ],
            [
              -77.330094,
              38.33401
            ],
            [
              -77.326681,
              38.318743
            ],
            [
              -77.339399,
              38.303089
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "159",
      "COUNTYNS": "01480164",
      "AFFGEOID": "0500000US51159",
      "GEOID": "51159",
      "NAME": "Richmond",
      "LSAD": "06",
      "ALAND": 495991737,
      "AWATER": 64392325,
      "County_state": "Richmond,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.936959,
              38.077074
            ],
            [
              -76.928169,
              38.073736
            ],
            [
              -76.9064359784404,
              37.9987793062827
            ],
            [
              -76.900328,
              37.998652
            ],
            [
              -76.868003,
              37.983666
            ],
            [
              -76.851603,
              37.973175
            ],
            [
              -76.846255,
              37.94872
            ],
            [
              -76.836629,
              37.934193
            ],
            [
              -76.820705,
              37.933162
            ],
            [
              -76.798482,
              37.925194
            ],
            [
              -76.768772,
              37.899129
            ],
            [
              -76.765711,
              37.879274
            ],
            [
              -76.747552,
              37.875864
            ],
            [
              -76.738395,
              37.865373
            ],
            [
              -76.733046,
              37.852009
            ],
            [
              -76.72718,
              37.842263
            ],
            [
              -76.722156,
              37.83668
            ],
            [
              -76.701606,
              37.822677
            ],
            [
              -76.692747,
              37.82277
            ],
            [
              -76.680197,
              37.825654
            ],
            [
              -76.658302,
              37.806815
            ],
            [
              -76.651413,
              37.796239
            ],
            [
              -76.6422756727082,
              37.7923167043293
            ],
            [
              -76.629799,
              37.805219
            ],
            [
              -76.58564,
              37.811821
            ],
            [
              -76.564735,
              37.837288
            ],
            [
              -76.507828,
              37.838814
            ],
            [
              -76.510877,
              37.84909
            ],
            [
              -76.548227,
              37.868979
            ],
            [
              -76.541736,
              37.881919
            ],
            [
              -76.556042,
              37.931524
            ],
            [
              -76.604151,
              37.949008
            ],
            [
              -76.63423,
              37.96663
            ],
            [
              -76.648333,
              37.96867
            ],
            [
              -76.656292,
              37.982169
            ],
            [
              -76.700569,
              37.984545
            ],
            [
              -76.728989,
              37.99919
            ],
            [
              -76.748871,
              37.994897
            ],
            [
              -76.765961,
              38.001546
            ],
            [
              -76.75886,
              38.010625
            ],
            [
              -76.759001,
              38.032046
            ],
            [
              -76.797574,
              38.058557
            ],
            [
              -76.822843,
              38.065177
            ],
            [
              -76.86339,
              38.102635
            ],
            [
              -76.876918,
              38.124238
            ],
            [
              -76.936959,
              38.077074
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "169",
      "COUNTYNS": "01498172",
      "AFFGEOID": "0500000US51169",
      "GEOID": "51169",
      "NAME": "Scott",
      "LSAD": "06",
      "ALAND": 1387786118,
      "AWATER": 8105331,
      "County_state": "Scott,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.947587,
              36.67587
            ],
            [
              -82.9851235492625,
              36.5937302148783
            ],
            [
              -82.888013,
              36.593461
            ],
            [
              -82.830433,
              36.593761
            ],
            [
              -82.69578,
              36.593698
            ],
            [
              -82.679879,
              36.593698
            ],
            [
              -82.609176,
              36.594099
            ],
            [
              -82.561074,
              36.5948
            ],
            [
              -82.559774,
              36.5948
            ],
            [
              -82.554294,
              36.594876
            ],
            [
              -82.487238,
              36.595822
            ],
            [
              -82.478668,
              36.595588
            ],
            [
              -82.466613,
              36.594481
            ],
            [
              -82.296995,
              36.595704
            ],
            [
              -82.2941391924011,
              36.595705583357
            ],
            [
              -82.307831,
              36.619682
            ],
            [
              -82.332078,
              36.708969
            ],
            [
              -82.356356,
              36.779852
            ],
            [
              -82.406633,
              36.876356
            ],
            [
              -82.402601,
              36.88464
            ],
            [
              -82.532756,
              36.882564
            ],
            [
              -82.61774,
              36.880773
            ],
            [
              -82.63478,
              36.87831
            ],
            [
              -82.647003,
              36.867283
            ],
            [
              -82.656819,
              36.845319
            ],
            [
              -82.68207,
              36.831137
            ],
            [
              -82.698635,
              36.816825
            ],
            [
              -82.748147,
              36.832633
            ],
            [
              -82.771595,
              36.800031
            ],
            [
              -82.791271,
              36.780407
            ],
            [
              -82.808804,
              36.77279
            ],
            [
              -82.829812,
              36.747219
            ],
            [
              -82.823358,
              36.733603
            ],
            [
              -82.852718,
              36.717143
            ],
            [
              -82.879788,
              36.709711
            ],
            [
              -82.947587,
              36.67587
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "023",
      "COUNTYNS": "01674418",
      "AFFGEOID": "0500000US51023",
      "GEOID": "51023",
      "NAME": "Botetourt",
      "LSAD": "06",
      "ALAND": 1401891585,
      "AWATER": 12133615,
      "County_state": "Botetourt,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.074119,
              37.422469
            ],
            [
              -80.062622,
              37.408325
            ],
            [
              -79.945616,
              37.362997
            ],
            [
              -79.929296,
              37.356039
            ],
            [
              -79.887616,
              37.338114
            ],
            [
              -79.865882,
              37.328752
            ],
            [
              -79.847476,
              37.309352
            ],
            [
              -79.844198,
              37.31775
            ],
            [
              -79.788151,
              37.351116
            ],
            [
              -79.778616,
              37.381783
            ],
            [
              -79.792185,
              37.380822
            ],
            [
              -79.814203,
              37.403654
            ],
            [
              -79.79049,
              37.40879
            ],
            [
              -79.756185,
              37.425384
            ],
            [
              -79.719076,
              37.448957
            ],
            [
              -79.69006,
              37.476374
            ],
            [
              -79.658798,
              37.48501
            ],
            [
              -79.63977,
              37.471097
            ],
            [
              -79.634206,
              37.457021
            ],
            [
              -79.626124,
              37.455284
            ],
            [
              -79.617181,
              37.458017
            ],
            [
              -79.588526,
              37.449596
            ],
            [
              -79.567365,
              37.487717
            ],
            [
              -79.546351,
              37.489194
            ],
            [
              -79.518941,
              37.508447
            ],
            [
              -79.498699,
              37.532797
            ],
            [
              -79.577972,
              37.580746
            ],
            [
              -79.573231,
              37.595569
            ],
            [
              -79.683554,
              37.661763
            ],
            [
              -79.671834,
              37.674304
            ],
            [
              -79.667733,
              37.694509
            ],
            [
              -79.676341,
              37.698803
            ],
            [
              -79.68877,
              37.734747
            ],
            [
              -79.674162,
              37.763093
            ],
            [
              -79.816721,
              37.800973
            ],
            [
              -79.845321,
              37.782314
            ],
            [
              -79.844455,
              37.774588
            ],
            [
              -79.86708,
              37.748685
            ],
            [
              -79.890347,
              37.738063
            ],
            [
              -79.91756,
              37.701848
            ],
            [
              -79.941717,
              37.698951
            ],
            [
              -79.957365,
              37.683782
            ],
            [
              -80.020554,
              37.647442
            ],
            [
              -80.032033,
              37.638563
            ],
            [
              -80.027621,
              37.624218
            ],
            [
              -79.969071,
              37.544408
            ],
            [
              -80.009496,
              37.510827
            ],
            [
              -80.014433,
              37.494846
            ],
            [
              -80.03046,
              37.475504
            ],
            [
              -80.047594,
              37.443103
            ],
            [
              -80.074119,
              37.422469
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "199",
      "COUNTYNS": "01480182",
      "AFFGEOID": "0500000US51199",
      "GEOID": "51199",
      "NAME": "York",
      "LSAD": "06",
      "ALAND": 271042751,
      "AWATER": 285421540,
      "County_state": "York,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.755477,
              37.354325
            ],
            [
              -76.753072,
              37.338675
            ],
            [
              -76.734956,
              37.331885
            ],
            [
              -76.727859,
              37.305927
            ],
            [
              -76.710106,
              37.277362
            ],
            [
              -76.68187,
              37.292642
            ],
            [
              -76.684705,
              37.270068
            ],
            [
              -76.662466,
              37.251609
            ],
            [
              -76.651277,
              37.239154
            ],
            [
              -76.632722,
              37.236345
            ],
            [
              -76.601115,
              37.238193
            ],
            [
              -76.591577,
              37.213833
            ],
            [
              -76.575074,
              37.212972
            ],
            [
              -76.565189,
              37.220539
            ],
            [
              -76.556732,
              37.211
            ],
            [
              -76.516375,
              37.178319
            ],
            [
              -76.506241,
              37.154288
            ],
            [
              -76.470977,
              37.114445
            ],
            [
              -76.460816,
              37.103039
            ],
            [
              -76.458498,
              37.100727
            ],
            [
              -76.443569,
              37.094511
            ],
            [
              -76.4355,
              37.094866
            ],
            [
              -76.425451,
              37.092301
            ],
            [
              -76.407977,
              37.092124
            ],
            [
              -76.40029,
              37.095851
            ],
            [
              -76.402002,
              37.103988
            ],
            [
              -76.395795,
              37.107174
            ],
            [
              -76.401761,
              37.119233
            ],
            [
              -76.405844,
              37.125577
            ],
            [
              -76.410113,
              37.125158
            ],
            [
              -76.412885,
              37.155808
            ],
            [
              -76.3978831556465,
              37.1644153551795
            ],
            [
              -76.391252,
              37.179887
            ],
            [
              -76.389284,
              37.193503
            ],
            [
              -76.396052,
              37.201087
            ],
            [
              -76.3936,
              37.214049
            ],
            [
              -76.389793,
              37.222981
            ],
            [
              -76.394132,
              37.22515
            ],
            [
              -76.471799,
              37.216016
            ],
            [
              -76.494008,
              37.225408
            ],
            [
              -76.50364,
              37.233856
            ],
            [
              -76.4988999709057,
              37.2410151037221
            ],
            [
              -76.516057,
              37.248752
            ],
            [
              -76.53641,
              37.271119
            ],
            [
              -76.569978,
              37.289104
            ],
            [
              -76.600713,
              37.314038
            ],
            [
              -76.64952,
              37.374282
            ],
            [
              -76.658651,
              37.380139
            ],
            [
              -76.673712,
              37.367728
            ],
            [
              -76.711808,
              37.36509
            ],
            [
              -76.742245,
              37.37011
            ],
            [
              -76.755477,
              37.354325
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "157",
      "COUNTYNS": "01673976",
      "AFFGEOID": "0500000US51157",
      "GEOID": "51157",
      "NAME": "Rappahannock",
      "LSAD": "06",
      "ALAND": 689901003,
      "AWATER": 2142395,
      "County_state": "Rappahannock,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.315581,
              38.651564
            ],
            [
              -78.338176,
              38.627312
            ],
            [
              -78.231803,
              38.532539
            ],
            [
              -78.214882,
              38.538439
            ],
            [
              -78.175975,
              38.520792
            ],
            [
              -78.089203,
              38.597381
            ],
            [
              -77.97907,
              38.66785
            ],
            [
              -77.935355,
              38.69584
            ],
            [
              -77.954567,
              38.694789
            ],
            [
              -77.995664,
              38.710416
            ],
            [
              -78.014368,
              38.727061
            ],
            [
              -78.016445,
              38.754016
            ],
            [
              -78.028102,
              38.757486
            ],
            [
              -78.019536,
              38.777841
            ],
            [
              -78.025832,
              38.795787
            ],
            [
              -78.051397,
              38.79923
            ],
            [
              -78.078584,
              38.823356
            ],
            [
              -78.099732,
              38.830392
            ],
            [
              -78.130574,
              38.864865
            ],
            [
              -78.152641,
              38.854384
            ],
            [
              -78.170941,
              38.823847
            ],
            [
              -78.184702,
              38.792836
            ],
            [
              -78.200764,
              38.776665
            ],
            [
              -78.252959,
              38.757042
            ],
            [
              -78.284805,
              38.759315
            ],
            [
              -78.303477,
              38.738913
            ],
            [
              -78.318775,
              38.737866
            ],
            [
              -78.331705,
              38.702642
            ],
            [
              -78.314386,
              38.682509
            ],
            [
              -78.315581,
              38.651564
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "079",
      "COUNTYNS": "01480129",
      "AFFGEOID": "0500000US51079",
      "GEOID": "51079",
      "NAME": "Greene",
      "LSAD": "06",
      "ALAND": 403788487,
      "AWATER": 1756849,
      "County_state": "Greene,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.651214,
              38.293463
            ],
            [
              -78.663145,
              38.277933
            ],
            [
              -78.593696,
              38.253523
            ],
            [
              -78.368743,
              38.184074
            ],
            [
              -78.292194,
              38.268293
            ],
            [
              -78.288837,
              38.272656
            ],
            [
              -78.304715,
              38.269192
            ],
            [
              -78.349501,
              38.277463
            ],
            [
              -78.34849,
              38.288536
            ],
            [
              -78.365383,
              38.29274
            ],
            [
              -78.35863,
              38.299973
            ],
            [
              -78.36388,
              38.315255
            ],
            [
              -78.381816,
              38.317923
            ],
            [
              -78.407554,
              38.34013
            ],
            [
              -78.411616,
              38.35058
            ],
            [
              -78.429674,
              38.367035
            ],
            [
              -78.423755,
              38.383027
            ],
            [
              -78.436083,
              38.394629
            ],
            [
              -78.440823,
              38.410075
            ],
            [
              -78.434184,
              38.41973
            ],
            [
              -78.43489,
              38.450113
            ],
            [
              -78.452801,
              38.475527
            ],
            [
              -78.465073,
              38.447
            ],
            [
              -78.48574,
              38.421579
            ],
            [
              -78.493214,
              38.406286
            ],
            [
              -78.515141,
              38.39372
            ],
            [
              -78.522792,
              38.36951
            ],
            [
              -78.542015,
              38.364859
            ],
            [
              -78.553809,
              38.348499
            ],
            [
              -78.553143,
              38.334824
            ],
            [
              -78.587506,
              38.32483
            ],
            [
              -78.602248,
              38.307485
            ],
            [
              -78.628525,
              38.300023
            ],
            [
              -78.634337,
              38.292029
            ],
            [
              -78.651214,
              38.293463
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "750",
      "COUNTYNS": "01498438",
      "AFFGEOID": "0500000US51750",
      "GEOID": "51750",
      "NAME": "Radford",
      "LSAD": "25",
      "ALAND": 25061300,
      "AWATER": 858713,
      "County_state": "Radford,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.592875,
              37.127866
            ],
            [
              -80.594756,
              37.112302
            ],
            [
              -80.590406,
              37.102
            ],
            [
              -80.580469,
              37.089662
            ],
            [
              -80.556479,
              37.09999
            ],
            [
              -80.551262,
              37.11862
            ],
            [
              -80.53303,
              37.118088
            ],
            [
              -80.50854,
              37.135708
            ],
            [
              -80.511287,
              37.147782
            ],
            [
              -80.530054,
              37.132401
            ],
            [
              -80.529762,
              37.133842
            ],
            [
              -80.553642,
              37.14673
            ],
            [
              -80.592875,
              37.127866
            ]
          ],
          [
            [
              -80.519433,
              37.131859
            ],
            [
              -80.518893,
              37.132638
            ],
            [
              -80.518101,
              37.132626
            ],
            [
              -80.518092,
              37.13218
            ],
            [
              -80.51863,
              37.131523
            ],
            [
              -80.519433,
              37.131859
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "109",
      "COUNTYNS": "01494707",
      "AFFGEOID": "0500000US51109",
      "GEOID": "51109",
      "NAME": "Louisa",
      "LSAD": "06",
      "ALAND": 1284973900,
      "AWATER": 37790617,
      "County_state": "Louisa,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.306763,
              38.006474
            ],
            [
              -78.276527,
              37.99154
            ],
            [
              -78.230126,
              37.97854
            ],
            [
              -78.220092,
              37.970102
            ],
            [
              -78.205125,
              37.95734
            ],
            [
              -78.174004,
              37.95023
            ],
            [
              -78.062481,
              37.904685
            ],
            [
              -78.017139,
              37.892575
            ],
            [
              -77.997142,
              37.865419
            ],
            [
              -77.991329,
              37.85752
            ],
            [
              -77.948222,
              37.847221
            ],
            [
              -77.915107,
              37.806104
            ],
            [
              -77.90009,
              37.768349
            ],
            [
              -77.888908,
              37.760808
            ],
            [
              -77.832388,
              37.752909
            ],
            [
              -77.821236,
              37.74563
            ],
            [
              -77.797306,
              37.729358
            ],
            [
              -77.743844,
              37.865066
            ],
            [
              -77.687469,
              38.007806
            ],
            [
              -77.71189,
              38.012721
            ],
            [
              -77.739513,
              38.033038
            ],
            [
              -77.746913,
              38.023638
            ],
            [
              -77.745213,
              38.050837
            ],
            [
              -77.764024,
              38.053137
            ],
            [
              -77.784214,
              38.063137
            ],
            [
              -77.782514,
              38.067537
            ],
            [
              -77.786614,
              38.076037
            ],
            [
              -77.820297,
              38.089193
            ],
            [
              -77.837416,
              38.105736
            ],
            [
              -77.847017,
              38.098438
            ],
            [
              -77.916918,
              38.118336
            ],
            [
              -77.953639,
              38.118501
            ],
            [
              -77.979936,
              38.119533
            ],
            [
              -78.001947,
              38.137582
            ],
            [
              -78.021872,
              38.142105
            ],
            [
              -78.024735,
              38.136473
            ],
            [
              -78.047876,
              38.135883
            ],
            [
              -78.101989,
              38.152546
            ],
            [
              -78.116832,
              38.140002
            ],
            [
              -78.144342,
              38.142999
            ],
            [
              -78.171423,
              38.129688
            ],
            [
              -78.209384,
              38.131128
            ],
            [
              -78.253989,
              38.062813
            ],
            [
              -78.306763,
              38.006474
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "775",
      "COUNTYNS": "01789074",
      "AFFGEOID": "0500000US51775",
      "GEOID": "51775",
      "NAME": "Salem",
      "LSAD": "25",
      "ALAND": 37599554,
      "AWATER": 277514,
      "County_state": "Salem,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.12049,
              37.287053
            ],
            [
              -80.093675,
              37.282502
            ],
            [
              -80.081631,
              37.265952
            ],
            [
              -80.074512,
              37.257062
            ],
            [
              -80.051202,
              37.2522
            ],
            [
              -80.033464,
              37.26289
            ],
            [
              -80.018446,
              37.264254
            ],
            [
              -80.014253,
              37.270984
            ],
            [
              -80.021713,
              37.281444
            ],
            [
              -80.022226,
              37.308338
            ],
            [
              -80.033596,
              37.324293
            ],
            [
              -80.071712,
              37.311952
            ],
            [
              -80.084224,
              37.297499
            ],
            [
              -80.12049,
              37.287053
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "670",
      "COUNTYNS": "01498428",
      "AFFGEOID": "0500000US51670",
      "GEOID": "51670",
      "NAME": "Hopewell",
      "LSAD": "25",
      "ALAND": 26668801,
      "AWATER": 1324529,
      "County_state": "Hopewell,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.334549,
              37.312468
            ],
            [
              -77.325203,
              37.26698
            ],
            [
              -77.330609,
              37.257874
            ],
            [
              -77.3241,
              37.262297
            ],
            [
              -77.305269,
              37.262037
            ],
            [
              -77.299796,
              37.269509
            ],
            [
              -77.284236,
              37.274124
            ],
            [
              -77.278399,
              37.278134
            ],
            [
              -77.252818,
              37.29362
            ],
            [
              -77.270307,
              37.308948
            ],
            [
              -77.276499,
              37.317348
            ],
            [
              -77.284019,
              37.313012
            ],
            [
              -77.29627,
              37.30917
            ],
            [
              -77.334549,
              37.312468
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "610",
      "COUNTYNS": "01498423",
      "AFFGEOID": "0500000US51610",
      "GEOID": "51610",
      "NAME": "Falls Church",
      "LSAD": "25",
      "ALAND": 5300264,
      "AWATER": 32,
      "County_state": "Falls Church,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.194874,
              38.898876
            ],
            [
              -77.189922,
              38.894267
            ],
            [
              -77.189754,
              38.878136
            ],
            [
              -77.158128,
              38.873295
            ],
            [
              -77.149701,
              38.87567
            ],
            [
              -77.172276,
              38.893245
            ],
            [
              -77.194874,
              38.898876
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "678",
      "COUNTYNS": "01789071",
      "AFFGEOID": "0500000US51678",
      "GEOID": "51678",
      "NAME": "Lexington",
      "LSAD": "25",
      "ALAND": 6473002,
      "AWATER": 67785,
      "County_state": "Lexington,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.461582,
              37.786356
            ],
            [
              -79.455667,
              37.765219
            ],
            [
              -79.426063,
              37.785102
            ],
            [
              -79.414948,
              37.788627
            ],
            [
              -79.453328,
              37.793157
            ],
            [
              -79.461582,
              37.786356
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "049",
      "COUNTYNS": "01494109",
      "AFFGEOID": "0500000US51049",
      "GEOID": "51049",
      "NAME": "Cumberland",
      "LSAD": "06",
      "ALAND": 770511792,
      "AWATER": 5931926,
      "County_state": "Cumberland,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.465089,
              37.339677
            ],
            [
              -78.440662,
              37.327241
            ],
            [
              -78.413067,
              37.324179
            ],
            [
              -78.395239,
              37.305294
            ],
            [
              -78.364238,
              37.310342
            ],
            [
              -78.352501,
              37.301678
            ],
            [
              -78.34332,
              37.318886
            ],
            [
              -78.32184,
              37.309292
            ],
            [
              -78.309953,
              37.320942
            ],
            [
              -78.292349,
              37.324918
            ],
            [
              -78.277968,
              37.346694
            ],
            [
              -78.258998,
              37.342837
            ],
            [
              -78.259063,
              37.360645
            ],
            [
              -78.235039,
              37.368102
            ],
            [
              -78.188456,
              37.42552
            ],
            [
              -78.185002,
              37.444552
            ],
            [
              -78.171415,
              37.442536
            ],
            [
              -78.139835,
              37.459679
            ],
            [
              -78.132048,
              37.454686
            ],
            [
              -78.087488,
              37.628044
            ],
            [
              -78.089466,
              37.639415
            ],
            [
              -78.072174,
              37.657385
            ],
            [
              -78.109823,
              37.680406
            ],
            [
              -78.09148,
              37.695734
            ],
            [
              -78.100475,
              37.716416
            ],
            [
              -78.159326,
              37.748528
            ],
            [
              -78.18099,
              37.744959
            ],
            [
              -78.225302,
              37.711579
            ],
            [
              -78.239748,
              37.690495
            ],
            [
              -78.249021,
              37.635546
            ],
            [
              -78.465089,
              37.339677
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "155",
      "COUNTYNS": "01501465",
      "AFFGEOID": "0500000US51155",
      "GEOID": "51155",
      "NAME": "Pulaski",
      "LSAD": "06",
      "ALAND": 828377752,
      "AWATER": 24877168,
      "County_state": "Pulaski,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.930627,
              37.115087
            ],
            [
              -80.912674,
              37.073349
            ],
            [
              -80.893249,
              37.027399
            ],
            [
              -80.862576,
              37.003642
            ],
            [
              -80.790454,
              36.934111
            ],
            [
              -80.740483,
              36.879001
            ],
            [
              -80.68259,
              36.895144
            ],
            [
              -80.67613,
              36.908037
            ],
            [
              -80.633987,
              36.93178
            ],
            [
              -80.597585,
              36.94309
            ],
            [
              -80.552847,
              36.970239
            ],
            [
              -80.543933,
              36.984639
            ],
            [
              -80.565744,
              37.047549
            ],
            [
              -80.543145,
              37.048465
            ],
            [
              -80.580469,
              37.089662
            ],
            [
              -80.590406,
              37.102
            ],
            [
              -80.594756,
              37.112302
            ],
            [
              -80.592875,
              37.127866
            ],
            [
              -80.553642,
              37.14673
            ],
            [
              -80.529762,
              37.133842
            ],
            [
              -80.52976,
              37.154226
            ],
            [
              -80.552238,
              37.161718
            ],
            [
              -80.565069,
              37.174119
            ],
            [
              -80.554576,
              37.190005
            ],
            [
              -80.519262,
              37.192737
            ],
            [
              -80.515193,
              37.200561
            ],
            [
              -80.529471,
              37.206589
            ],
            [
              -80.56483,
              37.197711
            ],
            [
              -80.587953,
              37.183335
            ],
            [
              -80.612499,
              37.211148
            ],
            [
              -80.606962,
              37.244871
            ],
            [
              -80.672201,
              37.224115
            ],
            [
              -80.699651,
              37.210578
            ],
            [
              -80.80149,
              37.179632
            ],
            [
              -80.854627,
              37.147482
            ],
            [
              -80.90341,
              37.12015
            ],
            [
              -80.930627,
              37.115087
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "107",
      "COUNTYNS": "01480141",
      "AFFGEOID": "0500000US51107",
      "GEOID": "51107",
      "NAME": "Loudoun",
      "LSAD": "06",
      "ALAND": 1335692947,
      "AWATER": 14556679,
      "County_state": "Loudoun,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.893279,
              39.055425
            ],
            [
              -77.962203,
              39.013733
            ],
            [
              -77.655169,
              38.942656
            ],
            [
              -77.637298,
              38.941672
            ],
            [
              -77.625044,
              38.931825
            ],
            [
              -77.61889,
              38.931408
            ],
            [
              -77.5916,
              38.897175
            ],
            [
              -77.55224,
              38.886588
            ],
            [
              -77.548752,
              38.846609
            ],
            [
              -77.535991,
              38.847368
            ],
            [
              -77.496821,
              38.887154
            ],
            [
              -77.473248,
              38.911077
            ],
            [
              -77.432198,
              38.952673
            ],
            [
              -77.40257,
              38.982649
            ],
            [
              -77.400955,
              38.984292
            ],
            [
              -77.378344,
              39.007161
            ],
            [
              -77.3783,
              39.007205
            ],
            [
              -77.371103,
              39.01448
            ],
            [
              -77.32828109319901,
              39.0577948715365
            ],
            [
              -77.333706,
              39.059508
            ],
            [
              -77.33401,
              39.060989
            ],
            [
              -77.335511,
              39.061771
            ],
            [
              -77.340287,
              39.062991
            ],
            [
              -77.350311,
              39.062133
            ],
            [
              -77.359702,
              39.062004
            ],
            [
              -77.367529,
              39.061087
            ],
            [
              -77.375079,
              39.061297
            ],
            [
              -77.380108,
              39.062808
            ],
            [
              -77.38568,
              39.061987
            ],
            [
              -77.399204,
              39.064784
            ],
            [
              -77.4019135386037,
              39.064639196304
            ],
            [
              -77.404593,
              39.064496
            ],
            [
              -77.41543,
              39.066435
            ],
            [
              -77.42318,
              39.066878
            ],
            [
              -77.4374,
              39.070611
            ],
            [
              -77.452827,
              39.072468
            ],
            [
              -77.458202,
              39.073723
            ],
            [
              -77.46145,
              39.075151
            ],
            [
              -77.462617,
              39.076248
            ],
            [
              -77.46914754630019,
              39.085948147808004
            ],
            [
              -77.469443,
              39.086387
            ],
            [
              -77.472414,
              39.092524
            ],
            [
              -77.47701,
              39.100331
            ],
            [
              -77.481279,
              39.105658
            ],
            [
              -77.4858,
              39.109303
            ],
            [
              -77.494955,
              39.113038
            ],
            [
              -77.499711,
              39.11395
            ],
            [
              -77.51532,
              39.118591
            ],
            [
              -77.519929,
              39.120925
            ],
            [
              -77.5209858903006,
              39.1224991502187
            ],
            [
              -77.524559,
              39.127821
            ],
            [
              -77.526728,
              39.137315
            ],
            [
              -77.52718420999359,
              39.1446612984714
            ],
            [
              -77.527282,
              39.146236
            ],
            [
              -77.524872,
              39.148455
            ],
            [
              -77.521222,
              39.161057
            ],
            [
              -77.516426,
              39.170891
            ],
            [
              -77.510631,
              39.178484
            ],
            [
              -77.505162,
              39.18205
            ],
            [
              -77.485971,
              39.185665
            ],
            [
              -77.478596,
              39.189168
            ],
            [
              -77.477362,
              39.190495
            ],
            [
              -77.475013,
              39.194934
            ],
            [
              -77.475929,
              39.202024
            ],
            [
              -77.47361,
              39.208407
            ],
            [
              -77.470113,
              39.21184
            ],
            [
              -77.459883,
              39.218682
            ],
            [
              -77.4589223494682,
              39.220336398673496
            ],
            [
              -77.457943,
              39.222023
            ],
            [
              -77.45768,
              39.22502
            ],
            [
              -77.45812,
              39.22614
            ],
            [
              -77.46021,
              39.228359
            ],
            [
              -77.471213,
              39.234509
            ],
            [
              -77.480807,
              39.241664
            ],
            [
              -77.484664,
              39.246123
            ],
            [
              -77.486813,
              39.247586
            ],
            [
              -77.496606,
              39.251045
            ],
            [
              -77.508427,
              39.25263
            ],
            [
              -77.519634,
              39.257232
            ],
            [
              -77.520986,
              39.258491
            ],
            [
              -77.522486,
              39.259086
            ],
            [
              -77.534461,
              39.262361
            ],
            [
              -77.540581,
              39.264947
            ],
            [
              -77.543228,
              39.266937
            ],
            [
              -77.544258,
              39.26966
            ],
            [
              -77.545846,
              39.271535
            ],
            [
              -77.551054,
              39.275882
            ],
            [
              -77.553114,
              39.279268
            ],
            [
              -77.560854,
              39.286152
            ],
            [
              -77.562768,
              39.294501
            ],
            [
              -77.561826,
              39.301913
            ],
            [
              -77.56321,
              39.303903
            ],
            [
              -77.5658310251171,
              39.3056199030448
            ],
            [
              -77.566596,
              39.306121
            ],
            [
              -77.570041,
              39.30635
            ],
            [
              -77.578491,
              39.305228
            ],
            [
              -77.588235,
              39.301955
            ],
            [
              -77.592739,
              39.30129
            ],
            [
              -77.598892,
              39.301883
            ],
            [
              -77.6059,
              39.303688
            ],
            [
              -77.615939,
              39.302722
            ],
            [
              -77.636101,
              39.307782
            ],
            [
              -77.640282,
              39.308241
            ],
            [
              -77.650997,
              39.310784
            ],
            [
              -77.66613,
              39.317008
            ],
            [
              -77.671341,
              39.321675
            ],
            [
              -77.675846,
              39.324192
            ],
            [
              -77.67712301274629,
              39.32407737394119
            ],
            [
              -77.681706,
              39.323666
            ],
            [
              -77.687124,
              39.319914
            ],
            [
              -77.692984,
              39.31845
            ],
            [
              -77.697461,
              39.318633
            ],
            [
              -77.707709,
              39.321559
            ],
            [
              -77.715865,
              39.320641
            ],
            [
              -77.719519,
              39.321314
            ],
            [
              -77.719946,
              39.319693
            ],
            [
              -77.721638,
              39.318494
            ],
            [
              -77.730047,
              39.315666
            ],
            [
              -77.734899,
              39.312409
            ],
            [
              -77.747287,
              39.295001
            ],
            [
              -77.750267,
              39.289284
            ],
            [
              -77.752726,
              39.283373
            ],
            [
              -77.753357,
              39.280331
            ],
            [
              -77.75306,
              39.277971
            ],
            [
              -77.753105,
              39.27734
            ],
            [
              -77.755193,
              39.275191
            ],
            [
              -77.755698,
              39.274575
            ],
            [
              -77.758412,
              39.269197
            ],
            [
              -77.758733,
              39.268114
            ],
            [
              -77.761217,
              39.263721
            ],
            [
              -77.761768,
              39.263031
            ],
            [
              -77.762844,
              39.258445
            ],
            [
              -77.766525,
              39.25734
            ],
            [
              -77.768,
              39.257657
            ],
            [
              -77.768992,
              39.256417
            ],
            [
              -77.770281,
              39.255977
            ],
            [
              -77.770669,
              39.255262
            ],
            [
              -77.770876,
              39.24976
            ],
            [
              -77.770589,
              39.249393
            ],
            [
              -77.767277,
              39.24938
            ],
            [
              -77.7709401864601,
              39.2382222421114
            ],
            [
              -77.771415,
              39.236776
            ],
            [
              -77.778068,
              39.229305
            ],
            [
              -77.781268,
              39.226909
            ],
            [
              -77.78364,
              39.224081
            ],
            [
              -77.788763,
              39.215243
            ],
            [
              -77.793631,
              39.210125
            ],
            [
              -77.794596,
              39.206299
            ],
            [
              -77.79819,
              39.200658
            ],
            [
              -77.798478,
              39.199574
            ],
            [
              -77.797714,
              39.19424
            ],
            [
              -77.797943,
              39.192826
            ],
            [
              -77.804712,
              39.179419
            ],
            [
              -77.804415,
              39.178045
            ],
            [
              -77.805099,
              39.174222
            ],
            [
              -77.805991,
              39.172421
            ],
            [
              -77.809125,
              39.168567
            ],
            [
              -77.811295,
              39.167563
            ],
            [
              -77.813206,
              39.165023
            ],
            [
              -77.818446,
              39.155279
            ],
            [
              -77.821413,
              39.15241
            ],
            [
              -77.822874,
              39.147755
            ],
            [
              -77.82299,
              39.145451
            ],
            [
              -77.82223,
              39.142734
            ],
            [
              -77.822182,
              39.139985
            ],
            [
              -77.828157,
              39.132329
            ],
            [
              -77.82830183326729,
              39.1324224548325
            ],
            [
              -77.83896,
              39.121814
            ],
            [
              -77.843797,
              39.119747
            ],
            [
              -77.845329,
              39.11686
            ],
            [
              -77.853741,
              39.101025
            ],
            [
              -77.855169,
              39.095284
            ],
            [
              -77.862825,
              39.080979
            ],
            [
              -77.887892,
              39.064463
            ],
            [
              -77.893279,
              39.055425
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "011",
      "COUNTYNS": "01497238",
      "AFFGEOID": "0500000US51011",
      "GEOID": "51011",
      "NAME": "Appomattox",
      "LSAD": "06",
      "ALAND": 865622497,
      "AWATER": 3234361,
      "County_state": "Appomattox,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.010943,
              37.445055
            ],
            [
              -79.021687,
              37.429243
            ],
            [
              -78.953447,
              37.317112
            ],
            [
              -78.949703,
              37.292501
            ],
            [
              -78.941103,
              37.284026
            ],
            [
              -78.92646,
              37.277071
            ],
            [
              -78.919869,
              37.245545
            ],
            [
              -78.892712,
              37.248638
            ],
            [
              -78.863198,
              37.24049
            ],
            [
              -78.856928,
              37.212008
            ],
            [
              -78.824209,
              37.205361
            ],
            [
              -78.808198,
              37.201852
            ],
            [
              -78.777293,
              37.229107
            ],
            [
              -78.734854,
              37.220229
            ],
            [
              -78.720331,
              37.237098
            ],
            [
              -78.681573,
              37.248759
            ],
            [
              -78.673332,
              37.268511
            ],
            [
              -78.627028,
              37.322045
            ],
            [
              -78.594483,
              37.344949
            ],
            [
              -78.594436,
              37.397974
            ],
            [
              -78.615621,
              37.394052
            ],
            [
              -78.630175,
              37.38425
            ],
            [
              -78.640149,
              37.404031
            ],
            [
              -78.635714,
              37.416193
            ],
            [
              -78.683224,
              37.428974
            ],
            [
              -78.704187,
              37.45591
            ],
            [
              -78.825238,
              37.552542
            ],
            [
              -78.824938,
              37.537342
            ],
            [
              -78.869245,
              37.542092
            ],
            [
              -78.898741,
              37.530242
            ],
            [
              -78.89554,
              37.513242
            ],
            [
              -78.923725,
              37.497553
            ],
            [
              -78.949942,
              37.503142
            ],
            [
              -78.943609,
              37.481579
            ],
            [
              -78.97564,
              37.468729
            ],
            [
              -79.010943,
              37.445055
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "720",
      "COUNTYNS": "01498434",
      "AFFGEOID": "0500000US51720",
      "GEOID": "51720",
      "NAME": "Norton",
      "LSAD": "25",
      "ALAND": 19366208,
      "AWATER": 89336,
      "County_state": "Norton,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.663625,
              36.920959
            ],
            [
              -82.661768,
              36.918894
            ],
            [
              -82.645874,
              36.915346
            ],
            [
              -82.643992,
              36.900116
            ],
            [
              -82.636643,
              36.90629
            ],
            [
              -82.614934,
              36.905037
            ],
            [
              -82.621962,
              36.927068
            ],
            [
              -82.612597,
              36.93405
            ],
            [
              -82.591147,
              36.935313
            ],
            [
              -82.58919,
              36.950803
            ],
            [
              -82.597664,
              36.958061
            ],
            [
              -82.610914,
              36.954508
            ],
            [
              -82.634101,
              36.952248
            ],
            [
              -82.652813,
              36.931635
            ],
            [
              -82.663625,
              36.920959
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "087",
      "COUNTYNS": "01480133",
      "AFFGEOID": "0500000US51087",
      "GEOID": "51087",
      "NAME": "Henrico",
      "LSAD": "06",
      "ALAND": 605179949,
      "AWATER": 28933034,
      "County_state": "Henrico,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.655399,
              37.563986
            ],
            [
              -77.649239,
              37.559613
            ],
            [
              -77.622,
              37.55688
            ],
            [
              -77.614082,
              37.556135
            ],
            [
              -77.595462,
              37.55575
            ],
            [
              -77.529827,
              37.559225
            ],
            [
              -77.543353,
              37.58106
            ],
            [
              -77.534201,
              37.584185
            ],
            [
              -77.532388,
              37.592484
            ],
            [
              -77.516464,
              37.581666
            ],
            [
              -77.511812,
              37.581751
            ],
            [
              -77.493528,
              37.582286
            ],
            [
              -77.483147,
              37.57379
            ],
            [
              -77.472489,
              37.578686
            ],
            [
              -77.479386,
              37.589726
            ],
            [
              -77.478146,
              37.598892
            ],
            [
              -77.465886,
              37.597272
            ],
            [
              -77.457799,
              37.602723
            ],
            [
              -77.457713,
              37.602819
            ],
            [
              -77.450427,
              37.602427
            ],
            [
              -77.43762,
              37.598566
            ],
            [
              -77.438045,
              37.58474
            ],
            [
              -77.431189,
              37.580695
            ],
            [
              -77.413323,
              37.579964
            ],
            [
              -77.415236,
              37.56292
            ],
            [
              -77.408937,
              37.557921
            ],
            [
              -77.411538,
              37.552592
            ],
            [
              -77.394961,
              37.549872
            ],
            [
              -77.394993,
              37.544018
            ],
            [
              -77.393246,
              37.537298
            ],
            [
              -77.387776,
              37.536753
            ],
            [
              -77.385513,
              37.535172
            ],
            [
              -77.386037,
              37.528919
            ],
            [
              -77.391306,
              37.530122
            ],
            [
              -77.393905,
              37.526108
            ],
            [
              -77.387765,
              37.517428
            ],
            [
              -77.387597,
              37.517483
            ],
            [
              -77.387447,
              37.517168
            ],
            [
              -77.393877,
              37.504954
            ],
            [
              -77.41651,
              37.517133
            ],
            [
              -77.417615,
              37.504776
            ],
            [
              -77.421705,
              37.498988
            ],
            [
              -77.417791,
              37.457487
            ],
            [
              -77.420875,
              37.447101
            ],
            [
              -77.428993,
              37.430665
            ],
            [
              -77.418589,
              37.421256
            ],
            [
              -77.410711,
              37.422141
            ],
            [
              -77.398755,
              37.420958
            ],
            [
              -77.386654,
              37.387134
            ],
            [
              -77.357707,
              37.383534
            ],
            [
              -77.327495,
              37.377455
            ],
            [
              -77.299267,
              37.404683
            ],
            [
              -77.298205,
              37.392087
            ],
            [
              -77.311526,
              37.373278
            ],
            [
              -77.304991,
              37.358519
            ],
            [
              -77.279612,
              37.35207
            ],
            [
              -77.267026,
              37.36071
            ],
            [
              -77.263818,
              37.378289
            ],
            [
              -77.249665,
              37.382
            ],
            [
              -77.248749,
              37.394735
            ],
            [
              -77.224269,
              37.380221
            ],
            [
              -77.214593,
              37.393522
            ],
            [
              -77.222839,
              37.407099
            ],
            [
              -77.197726,
              37.45382
            ],
            [
              -77.177324,
              37.4906
            ],
            [
              -77.231846,
              37.539638
            ],
            [
              -77.240988,
              37.538088
            ],
            [
              -77.261366,
              37.549702
            ],
            [
              -77.305938,
              37.561011
            ],
            [
              -77.40382,
              37.605222
            ],
            [
              -77.407189,
              37.612524
            ],
            [
              -77.405382,
              37.626033
            ],
            [
              -77.42143,
              37.641682
            ],
            [
              -77.448572,
              37.684445
            ],
            [
              -77.451133,
              37.680272
            ],
            [
              -77.471659,
              37.687646
            ],
            [
              -77.479006,
              37.681396
            ],
            [
              -77.494891,
              37.701526
            ],
            [
              -77.499191,
              37.700921
            ],
            [
              -77.522216,
              37.700149
            ],
            [
              -77.530798,
              37.694566
            ],
            [
              -77.542686,
              37.686167
            ],
            [
              -77.557856,
              37.681919
            ],
            [
              -77.595394,
              37.697036
            ],
            [
              -77.610608,
              37.71041
            ],
            [
              -77.63067,
              37.705673
            ],
            [
              -77.654452,
              37.640725
            ],
            [
              -77.650621,
              37.624188
            ],
            [
              -77.640381,
              37.602851
            ],
            [
              -77.61643,
              37.577555
            ],
            [
              -77.655399,
              37.563986
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "620",
      "COUNTYNS": "01498424",
      "AFFGEOID": "0500000US51620",
      "GEOID": "51620",
      "NAME": "Franklin",
      "LSAD": "25",
      "ALAND": 21295844,
      "AWATER": 369820,
      "County_state": "Franklin,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.967212,
              36.702588
            ],
            [
              -76.967855,
              36.683851
            ],
            [
              -76.949695,
              36.678673
            ],
            [
              -76.962339,
              36.667515
            ],
            [
              -76.957786,
              36.666259
            ],
            [
              -76.95366,
              36.66388
            ],
            [
              -76.909672,
              36.648175
            ],
            [
              -76.912015,
              36.672717
            ],
            [
              -76.913445,
              36.674043
            ],
            [
              -76.914856,
              36.674564
            ],
            [
              -76.916145,
              36.674531
            ],
            [
              -76.917729,
              36.674023
            ],
            [
              -76.918595,
              36.674232
            ],
            [
              -76.919013,
              36.674695
            ],
            [
              -76.917129,
              36.676111
            ],
            [
              -76.916425,
              36.678683
            ],
            [
              -76.916467,
              36.679165
            ],
            [
              -76.917309,
              36.67998
            ],
            [
              -76.920422,
              36.681625
            ],
            [
              -76.921455,
              36.681781
            ],
            [
              -76.92315,
              36.681395
            ],
            [
              -76.924982,
              36.682195
            ],
            [
              -76.92518,
              36.683342
            ],
            [
              -76.924989,
              36.683886
            ],
            [
              -76.924575,
              36.683913
            ],
            [
              -76.924476,
              36.683299
            ],
            [
              -76.92448,
              36.682421
            ],
            [
              -76.924047,
              36.682452
            ],
            [
              -76.923878,
              36.683196
            ],
            [
              -76.923576,
              36.683649
            ],
            [
              -76.923308,
              36.683795
            ],
            [
              -76.92282,
              36.683742
            ],
            [
              -76.922688,
              36.683552
            ],
            [
              -76.922568,
              36.682813
            ],
            [
              -76.922266,
              36.682518
            ],
            [
              -76.921912,
              36.682491
            ],
            [
              -76.921921,
              36.68264
            ],
            [
              -76.920915,
              36.683237
            ],
            [
              -76.920638,
              36.683823
            ],
            [
              -76.920531,
              36.685099
            ],
            [
              -76.920953,
              36.685402
            ],
            [
              -76.921115,
              36.685733
            ],
            [
              -76.920984,
              36.686007
            ],
            [
              -76.920004,
              36.686134
            ],
            [
              -76.918646,
              36.686824
            ],
            [
              -76.917657,
              36.687199
            ],
            [
              -76.917625,
              36.687769
            ],
            [
              -76.918493,
              36.68867
            ],
            [
              -76.919586,
              36.688776
            ],
            [
              -76.919746,
              36.689058
            ],
            [
              -76.919568,
              36.689296
            ],
            [
              -76.918981,
              36.689182
            ],
            [
              -76.918647,
              36.689336
            ],
            [
              -76.918643,
              36.689793
            ],
            [
              -76.918347,
              36.690219
            ],
            [
              -76.918104,
              36.690359
            ],
            [
              -76.917528,
              36.690046
            ],
            [
              -76.917224,
              36.690175
            ],
            [
              -76.917039,
              36.690759
            ],
            [
              -76.917328,
              36.693043
            ],
            [
              -76.91708,
              36.693344
            ],
            [
              -76.915846,
              36.693117
            ],
            [
              -76.915434,
              36.693235
            ],
            [
              -76.91546,
              36.693655
            ],
            [
              -76.916015,
              36.693757
            ],
            [
              -76.916361,
              36.694061
            ],
            [
              -76.916505,
              36.694924
            ],
            [
              -76.9163,
              36.695311
            ],
            [
              -76.91581,
              36.695381
            ],
            [
              -76.915487,
              36.694743
            ],
            [
              -76.91507,
              36.694663
            ],
            [
              -76.914505,
              36.695093
            ],
            [
              -76.913889,
              36.695672
            ],
            [
              -76.913101,
              36.696105
            ],
            [
              -76.913031,
              36.696353
            ],
            [
              -76.913245,
              36.696968
            ],
            [
              -76.91236,
              36.697824
            ],
            [
              -76.912221,
              36.698408
            ],
            [
              -76.912463,
              36.698874
            ],
            [
              -76.913541,
              36.699599
            ],
            [
              -76.915059,
              36.700737
            ],
            [
              -76.915772,
              36.700985
            ],
            [
              -76.9163,
              36.700581
            ],
            [
              -76.916672,
              36.70008
            ],
            [
              -76.916913,
              36.699916
            ],
            [
              -76.91743,
              36.700315
            ],
            [
              -76.917735,
              36.700212
            ],
            [
              -76.918045,
              36.70033
            ],
            [
              -76.918424,
              36.700757
            ],
            [
              -76.918575,
              36.700643
            ],
            [
              -76.918365,
              36.700214
            ],
            [
              -76.918853,
              36.699606
            ],
            [
              -76.920044,
              36.699018
            ],
            [
              -76.920275,
              36.699045
            ],
            [
              -76.920407,
              36.700175
            ],
            [
              -76.920602,
              36.700283
            ],
            [
              -76.921018,
              36.700171
            ],
            [
              -76.921056,
              36.699873
            ],
            [
              -76.921268,
              36.699759
            ],
            [
              -76.922396,
              36.699988
            ],
            [
              -76.922958,
              36.699756
            ],
            [
              -76.923125,
              36.699036
            ],
            [
              -76.923824,
              36.69874
            ],
            [
              -76.924063,
              36.699095
            ],
            [
              -76.924832,
              36.699132
            ],
            [
              -76.924958,
              36.698648
            ],
            [
              -76.925229,
              36.698421
            ],
            [
              -76.925691,
              36.698329
            ],
            [
              -76.926142,
              36.698587
            ],
            [
              -76.926251,
              36.698862
            ],
            [
              -76.925926,
              36.699604
            ],
            [
              -76.926049,
              36.69996
            ],
            [
              -76.92649,
              36.700311
            ],
            [
              -76.927401,
              36.700183
            ],
            [
              -76.927882,
              36.700339
            ],
            [
              -76.927962,
              36.700502
            ],
            [
              -76.927342,
              36.701371
            ],
            [
              -76.927241,
              36.701915
            ],
            [
              -76.92745,
              36.702648
            ],
            [
              -76.927963,
              36.702824
            ],
            [
              -76.928184,
              36.703045
            ],
            [
              -76.928623,
              36.703815
            ],
            [
              -76.928884,
              36.704118
            ],
            [
              -76.929563,
              36.704342
            ],
            [
              -76.930071,
              36.7046
            ],
            [
              -76.930272,
              36.704996
            ],
            [
              -76.924629,
              36.707729
            ],
            [
              -76.939093,
              36.708644
            ],
            [
              -76.967212,
              36.702588
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "089",
      "COUNTYNS": "01502770",
      "AFFGEOID": "0500000US51089",
      "GEOID": "51089",
      "NAME": "Henry",
      "LSAD": "06",
      "ALAND": 990272714,
      "AWATER": 5281696,
      "County_state": "Henry,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.094389,
              36.779552
            ],
            [
              -80.091134,
              36.749079
            ],
            [
              -80.084702,
              36.740146
            ],
            [
              -80.094207,
              36.724581
            ],
            [
              -80.081518,
              36.703221
            ],
            [
              -80.07577,
              36.680829
            ],
            [
              -80.058431,
              36.656835
            ],
            [
              -80.059501,
              36.632911
            ],
            [
              -80.050211,
              36.609333
            ],
            [
              -80.0534585356035,
              36.5425366652957
            ],
            [
              -80.0273389759592,
              36.54249511132569
            ],
            [
              -80.027269,
              36.542495
            ],
            [
              -79.967511,
              36.542502
            ],
            [
              -79.966979,
              36.542475
            ],
            [
              -79.9200801435931,
              36.5424471543449
            ],
            [
              -79.904662,
              36.542438
            ],
            [
              -79.8915710847606,
              36.5427389405802
            ],
            [
              -79.887262,
              36.542838
            ],
            [
              -79.82918159040429,
              36.542556513879596
            ],
            [
              -79.71484019625919,
              36.5420023594641
            ],
            [
              -79.690513,
              36.645404
            ],
            [
              -79.689447,
              36.649864
            ],
            [
              -79.680963,
              36.685306
            ],
            [
              -79.640652,
              36.856139
            ],
            [
              -79.669153,
              36.827838
            ],
            [
              -79.690354,
              36.828838
            ],
            [
              -79.741417,
              36.806806
            ],
            [
              -79.761056,
              36.788337
            ],
            [
              -79.786756,
              36.800737
            ],
            [
              -79.802056,
              36.792137
            ],
            [
              -79.933294,
              36.817636
            ],
            [
              -79.999437,
              36.830618
            ],
            [
              -80.012032,
              36.789644
            ],
            [
              -80.01681,
              36.781048
            ],
            [
              -80.041493,
              36.794365
            ],
            [
              -80.075021,
              36.790761
            ],
            [
              -80.094389,
              36.779552
            ]
          ],
          [
            [
              -79.881533,
              36.670246
            ],
            [
              -79.904077,
              36.684127
            ],
            [
              -79.88126,
              36.711537
            ],
            [
              -79.865814,
              36.712582
            ],
            [
              -79.837359,
              36.704137
            ],
            [
              -79.839883,
              36.691622
            ],
            [
              -79.843737,
              36.672443
            ],
            [
              -79.826259,
              36.657937
            ],
            [
              -79.831433,
              36.646321
            ],
            [
              -79.838012,
              36.659295
            ],
            [
              -79.837139,
              36.645957
            ],
            [
              -79.859761,
              36.650368
            ],
            [
              -79.881533,
              36.670246
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "650",
      "COUNTYNS": "01498554",
      "AFFGEOID": "0500000US51650",
      "GEOID": "51650",
      "NAME": "Hampton",
      "LSAD": "25",
      "ALAND": 133308912,
      "AWATER": 219591270,
      "County_state": "Hampton,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.448931,
              37.079087
            ],
            [
              -76.450825,
              37.076654
            ],
            [
              -76.448689,
              37.063592
            ],
            [
              -76.450209,
              37.059855
            ],
            [
              -76.450291,
              37.057256
            ],
            [
              -76.448227,
              37.054987
            ],
            [
              -76.449981,
              37.051938
            ],
            [
              -76.450599,
              37.049548
            ],
            [
              -76.449733,
              37.043236
            ],
            [
              -76.449112,
              37.037561
            ],
            [
              -76.44085,
              37.02125
            ],
            [
              -76.442269,
              37.018448
            ],
            [
              -76.424141,
              37.024276
            ],
            [
              -76.401568,
              36.991947
            ],
            [
              -76.3877113044092,
              36.989671332859004
            ],
            [
              -76.383367,
              36.993347
            ],
            [
              -76.373567,
              36.998347
            ],
            [
              -76.356366,
              37.002947
            ],
            [
              -76.348066,
              37.006747
            ],
            [
              -76.340666,
              37.015246
            ],
            [
              -76.32953082049559,
              37.0145562406395
            ],
            [
              -76.318065,
              37.013846
            ],
            [
              -76.314624,
              37.00933
            ],
            [
              -76.315008,
              37.001683
            ],
            [
              -76.312048,
              37.000371
            ],
            [
              -76.304272,
              37.001378
            ],
            [
              -76.300352,
              37.00885
            ],
            [
              -76.292863,
              37.035145
            ],
            [
              -76.29031454,
              37.0409730346068
            ],
            [
              -76.2806688032552,
              37.063031723994094
            ],
            [
              -76.271262,
              37.084544
            ],
            [
              -76.274463,
              37.094544
            ],
            [
              -76.2872356344809,
              37.1174527389092
            ],
            [
              -76.301564,
              37.103244
            ],
            [
              -76.311744,
              37.102
            ],
            [
              -76.344666,
              37.096844
            ],
            [
              -76.350966,
              37.106744
            ],
            [
              -76.38301,
              37.112093
            ],
            [
              -76.395795,
              37.107174
            ],
            [
              -76.402002,
              37.103988
            ],
            [
              -76.40029,
              37.095851
            ],
            [
              -76.407977,
              37.092124
            ],
            [
              -76.425451,
              37.092301
            ],
            [
              -76.4355,
              37.094866
            ],
            [
              -76.436516,
              37.089856
            ],
            [
              -76.448931,
              37.079087
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "091",
      "COUNTYNS": "01673774",
      "AFFGEOID": "0500000US51091",
      "GEOID": "51091",
      "NAME": "Highland",
      "LSAD": "06",
      "ALAND": 1075253055,
      "AWATER": 1805613,
      "County_state": "Highland,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.810154,
              38.306707
            ],
            [
              -79.810115,
              38.305037
            ],
            [
              -79.807542,
              38.301694
            ],
            [
              -79.804026,
              38.298622
            ],
            [
              -79.803346,
              38.296682
            ],
            [
              -79.802778,
              38.292073
            ],
            [
              -79.797848,
              38.292053
            ],
            [
              -79.795448,
              38.290228
            ],
            [
              -79.789791,
              38.281167
            ],
            [
              -79.787542,
              38.273298
            ],
            [
              -79.788945,
              38.268703
            ],
            [
              -79.790134,
              38.267654
            ],
            [
              -79.7961001204602,
              38.266413403698
            ],
            [
              -79.753795,
              38.242698
            ],
            [
              -79.706196,
              38.221192
            ],
            [
              -79.562018,
              38.198805
            ],
            [
              -79.512158,
              38.180419
            ],
            [
              -79.495946,
              38.200918
            ],
            [
              -79.483456,
              38.205399
            ],
            [
              -79.481132,
              38.216478
            ],
            [
              -79.464806,
              38.222231
            ],
            [
              -79.438103,
              38.216235
            ],
            [
              -79.43367,
              38.229493
            ],
            [
              -79.409738,
              38.243094
            ],
            [
              -79.399613,
              38.260675
            ],
            [
              -79.408647,
              38.278007
            ],
            [
              -79.400218,
              38.293538
            ],
            [
              -79.391411,
              38.292132
            ],
            [
              -79.387487,
              38.309051
            ],
            [
              -79.350835,
              38.355194
            ],
            [
              -79.324983,
              38.381359
            ],
            [
              -79.308495,
              38.382963
            ],
            [
              -79.308669,
              38.39393
            ],
            [
              -79.322823,
              38.401667
            ],
            [
              -79.312276,
              38.411876
            ],
            [
              -79.370302,
              38.427244
            ],
            [
              -79.476638,
              38.457228
            ],
            [
              -79.499768,
              38.49772
            ],
            [
              -79.521469,
              38.533918
            ],
            [
              -79.53187,
              38.542817
            ],
            [
              -79.53317,
              38.544717
            ],
            [
              -79.53337,
              38.546217
            ],
            [
              -79.53687,
              38.550917
            ],
            [
              -79.537523299639,
              38.5513369783393
            ],
            [
              -79.53827,
              38.551817
            ],
            [
              -79.54257,
              38.553217
            ],
            [
              -79.555471,
              38.560217
            ],
            [
              -79.566271,
              38.562517
            ],
            [
              -79.571771,
              38.563117
            ],
            [
              -79.649075,
              38.591515
            ],
            [
              -79.656109,
              38.5762
            ],
            [
              -79.658175,
              38.573016
            ],
            [
              -79.659375,
              38.572616
            ],
            [
              -79.661575,
              38.567316
            ],
            [
              -79.660675,
              38.566216
            ],
            [
              -79.659275,
              38.562416
            ],
            [
              -79.662575,
              38.560516
            ],
            [
              -79.665075,
              38.560916
            ],
            [
              -79.669275,
              38.549516
            ],
            [
              -79.669675,
              38.543416
            ],
            [
              -79.666874,
              38.538317
            ],
            [
              -79.668774,
              38.534217
            ],
            [
              -79.672974,
              38.528717
            ],
            [
              -79.671574,
              38.527517
            ],
            [
              -79.669774,
              38.526917
            ],
            [
              -79.666774,
              38.524317
            ],
            [
              -79.662974,
              38.518717
            ],
            [
              -79.662074,
              38.515517
            ],
            [
              -79.663474,
              38.514117
            ],
            [
              -79.665674,
              38.513817
            ],
            [
              -79.667574,
              38.512917
            ],
            [
              -79.668774,
              38.512017
            ],
            [
              -79.669128,
              38.510975
            ],
            [
              -79.669128,
              38.510883
            ],
            [
              -79.670474,
              38.507717
            ],
            [
              -79.674074,
              38.510417
            ],
            [
              -79.680374,
              38.510617
            ],
            [
              -79.681574,
              38.508217
            ],
            [
              -79.681606,
              38.504504
            ],
            [
              -79.682974,
              38.501317
            ],
            [
              -79.688345,
              38.496183
            ],
            [
              -79.691301,
              38.496768
            ],
            [
              -79.692273,
              38.496474
            ],
            [
              -79.694506,
              38.494232
            ],
            [
              -79.697572,
              38.487223
            ],
            [
              -79.696959,
              38.484574
            ],
            [
              -79.695462,
              38.481454
            ],
            [
              -79.693424,
              38.481011
            ],
            [
              -79.69418,
              38.478311
            ],
            [
              -79.695565,
              38.477842
            ],
            [
              -79.699006,
              38.475148
            ],
            [
              -79.699622,
              38.473967
            ],
            [
              -79.698929,
              38.469869
            ],
            [
              -79.695588,
              38.469058
            ],
            [
              -79.691088,
              38.463744
            ],
            [
              -79.688882,
              38.458714
            ],
            [
              -79.688365,
              38.45687
            ],
            [
              -79.688205,
              38.450476
            ],
            [
              -79.688962,
              38.449538
            ],
            [
              -79.691478,
              38.446282
            ],
            [
              -79.689544,
              38.442511
            ],
            [
              -79.691377,
              38.439558
            ],
            [
              -79.691656,
              38.436436
            ],
            [
              -79.69093,
              38.433995
            ],
            [
              -79.689909,
              38.432864
            ],
            [
              -79.689675,
              38.431439
            ],
            [
              -79.706634,
              38.41573
            ],
            [
              -79.70914,
              38.412064
            ],
            [
              -79.708965,
              38.409553
            ],
            [
              -79.712904,
              38.405034
            ],
            [
              -79.717365,
              38.401562
            ],
            [
              -79.722653,
              38.389517
            ],
            [
              -79.72635,
              38.38707
            ],
            [
              -79.729895,
              38.380351
            ],
            [
              -79.731698,
              38.373376
            ],
            [
              -79.730494,
              38.372217
            ],
            [
              -79.727676,
              38.371701
            ],
            [
              -79.72679,
              38.370832
            ],
            [
              -79.725804,
              38.366128
            ],
            [
              -79.725597,
              38.363828
            ],
            [
              -79.725973,
              38.363229
            ],
            [
              -79.727053,
              38.362233
            ],
            [
              -79.729344,
              38.36183
            ],
            [
              -79.732059,
              38.360168
            ],
            [
              -79.7346,
              38.356728
            ],
            [
              -79.740615,
              38.354101
            ],
            [
              -79.744105,
              38.353968
            ],
            [
              -79.75556,
              38.357372
            ],
            [
              -79.757626,
              38.357566
            ],
            [
              -79.764432,
              38.356514
            ],
            [
              -79.767263,
              38.353395
            ],
            [
              -79.766403,
              38.350873
            ],
            [
              -79.769906,
              38.341843
            ],
            [
              -79.77309,
              38.335529
            ],
            [
              -79.779272,
              38.331609
            ],
            [
              -79.785972,
              38.330878
            ],
            [
              -79.79655,
              38.32348
            ],
            [
              -79.798159,
              38.319161
            ],
            [
              -79.799617,
              38.317149
            ],
            [
              -79.804093,
              38.313922
            ],
            [
              -79.808711,
              38.309429
            ],
            [
              -79.810154,
              38.306707
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "101",
      "COUNTYNS": "01480138",
      "AFFGEOID": "0500000US51101",
      "GEOID": "51101",
      "NAME": "King William",
      "LSAD": "06",
      "ALAND": 709481149,
      "AWATER": 31126025,
      "County_state": "King William,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.345215,
              37.789175
            ],
            [
              -77.331753,
              37.791997
            ],
            [
              -77.321989,
              37.76983
            ],
            [
              -77.334409,
              37.754636
            ],
            [
              -77.303586,
              37.755136
            ],
            [
              -77.303874,
              37.74422
            ],
            [
              -77.320949,
              37.743277
            ],
            [
              -77.322496,
              37.733701
            ],
            [
              -77.293509,
              37.739804
            ],
            [
              -77.292404,
              37.714067
            ],
            [
              -77.279567,
              37.713844
            ],
            [
              -77.269475,
              37.723515
            ],
            [
              -77.248883,
              37.70921
            ],
            [
              -77.248211,
              37.73009
            ],
            [
              -77.236383,
              37.726171
            ],
            [
              -77.230528,
              37.713831
            ],
            [
              -77.243859,
              37.699191
            ],
            [
              -77.239674,
              37.685748
            ],
            [
              -77.214539,
              37.688959
            ],
            [
              -77.202388,
              37.678575
            ],
            [
              -77.194529,
              37.690649
            ],
            [
              -77.182661,
              37.683079
            ],
            [
              -77.191243,
              37.667438
            ],
            [
              -77.186674,
              37.657708
            ],
            [
              -77.15209,
              37.663839
            ],
            [
              -77.148618,
              37.674717
            ],
            [
              -77.134919,
              37.663712
            ],
            [
              -77.109171,
              37.662293
            ],
            [
              -77.117304,
              37.644048
            ],
            [
              -77.129528,
              37.634875
            ],
            [
              -77.123675,
              37.626881
            ],
            [
              -77.089643,
              37.618224
            ],
            [
              -77.084159,
              37.598358
            ],
            [
              -77.067217,
              37.60392
            ],
            [
              -77.01916,
              37.581945
            ],
            [
              -77.019163,
              37.567417
            ],
            [
              -76.98299,
              37.565499
            ],
            [
              -76.986064,
              37.583428
            ],
            [
              -76.968576,
              37.586232
            ],
            [
              -76.957064,
              37.571913
            ],
            [
              -76.95676,
              37.557035
            ],
            [
              -76.974629,
              37.541564
            ],
            [
              -76.944206,
              37.538797
            ],
            [
              -76.942711,
              37.559552
            ],
            [
              -76.92155,
              37.568943
            ],
            [
              -76.903125,
              37.565791
            ],
            [
              -76.905261,
              37.548963
            ],
            [
              -76.893857,
              37.545668
            ],
            [
              -76.874686,
              37.552535
            ],
            [
              -76.881423,
              37.576891
            ],
            [
              -76.868479,
              37.581383
            ],
            [
              -76.853577,
              37.572627
            ],
            [
              -76.863776,
              37.549149
            ],
            [
              -76.878069,
              37.531377
            ],
            [
              -76.863826,
              37.523224
            ],
            [
              -76.851001,
              37.526083
            ],
            [
              -76.818039,
              37.55047
            ],
            [
              -76.792184,
              37.517594
            ],
            [
              -76.789111,
              37.535391
            ],
            [
              -76.777729,
              37.554446
            ],
            [
              -76.785248,
              37.569072
            ],
            [
              -76.799341,
              37.575795
            ],
            [
              -76.793708,
              37.586254
            ],
            [
              -76.814286,
              37.588981
            ],
            [
              -76.825842,
              37.597321
            ],
            [
              -76.816304,
              37.605456
            ],
            [
              -76.833364,
              37.606994
            ],
            [
              -76.840999,
              37.617521
            ],
            [
              -76.854267,
              37.613667
            ],
            [
              -76.868807,
              37.624013
            ],
            [
              -76.854942,
              37.634047
            ],
            [
              -76.880491,
              37.65713
            ],
            [
              -76.907545,
              37.654682
            ],
            [
              -76.913499,
              37.659125
            ],
            [
              -76.905995,
              37.67047
            ],
            [
              -76.915586,
              37.693325
            ],
            [
              -76.956408,
              37.688059
            ],
            [
              -76.97027,
              37.69292
            ],
            [
              -76.972264,
              37.705731
            ],
            [
              -76.9972,
              37.707236
            ],
            [
              -77.010383,
              37.7219
            ],
            [
              -77.033273,
              37.720809
            ],
            [
              -77.06046,
              37.749751
            ],
            [
              -77.083062,
              37.747866
            ],
            [
              -77.089542,
              37.755191
            ],
            [
              -77.073723,
              37.773227
            ],
            [
              -77.082548,
              37.781325
            ],
            [
              -77.103108,
              37.786034
            ],
            [
              -77.099368,
              37.813314
            ],
            [
              -77.113241,
              37.805144
            ],
            [
              -77.126454,
              37.826637
            ],
            [
              -77.123591,
              37.839297
            ],
            [
              -77.139784,
              37.852037
            ],
            [
              -77.134,
              37.866108
            ],
            [
              -77.147363,
              37.864583
            ],
            [
              -77.149497,
              37.882063
            ],
            [
              -77.164718,
              37.883934
            ],
            [
              -77.169671,
              37.897356
            ],
            [
              -77.181418,
              37.891654
            ],
            [
              -77.210202,
              37.886184
            ],
            [
              -77.211493,
              37.901163
            ],
            [
              -77.241679,
              37.90955
            ],
            [
              -77.243162,
              37.900241
            ],
            [
              -77.247702,
              37.876174
            ],
            [
              -77.279312,
              37.844365
            ],
            [
              -77.330124,
              37.805738
            ],
            [
              -77.345215,
              37.789175
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "121",
      "COUNTYNS": "01674630",
      "AFFGEOID": "0500000US51121",
      "GEOID": "51121",
      "NAME": "Montgomery",
      "LSAD": "06",
      "ALAND": 1001920374,
      "AWATER": 6291775,
      "County_state": "Montgomery,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.518893,
              37.132638
            ],
            [
              -80.519433,
              37.131859
            ],
            [
              -80.51863,
              37.131523
            ],
            [
              -80.518092,
              37.13218
            ],
            [
              -80.518101,
              37.132626
            ],
            [
              -80.518893,
              37.132638
            ]
          ]
        ],
        [
          [
            [
              -80.606962,
              37.244871
            ],
            [
              -80.612499,
              37.211148
            ],
            [
              -80.587953,
              37.183335
            ],
            [
              -80.56483,
              37.197711
            ],
            [
              -80.529471,
              37.206589
            ],
            [
              -80.515193,
              37.200561
            ],
            [
              -80.519262,
              37.192737
            ],
            [
              -80.554576,
              37.190005
            ],
            [
              -80.565069,
              37.174119
            ],
            [
              -80.552238,
              37.161718
            ],
            [
              -80.52976,
              37.154226
            ],
            [
              -80.529762,
              37.133842
            ],
            [
              -80.530054,
              37.132401
            ],
            [
              -80.511287,
              37.147782
            ],
            [
              -80.50854,
              37.135708
            ],
            [
              -80.53303,
              37.118088
            ],
            [
              -80.551262,
              37.11862
            ],
            [
              -80.556479,
              37.09999
            ],
            [
              -80.580469,
              37.089662
            ],
            [
              -80.543145,
              37.048465
            ],
            [
              -80.565744,
              37.047549
            ],
            [
              -80.543933,
              36.984639
            ],
            [
              -80.520714,
              36.996968
            ],
            [
              -80.515619,
              37.011125
            ],
            [
              -80.503785,
              36.991593
            ],
            [
              -80.484778,
              36.988263
            ],
            [
              -80.485986,
              36.999096
            ],
            [
              -80.471583,
              36.993452
            ],
            [
              -80.462789,
              37.011145
            ],
            [
              -80.434235,
              37.016663
            ],
            [
              -80.412766,
              37.003034
            ],
            [
              -80.413343,
              37.022182
            ],
            [
              -80.404574,
              37.014358
            ],
            [
              -80.389465,
              37.0217
            ],
            [
              -80.347762,
              37.021592
            ],
            [
              -80.283675,
              37.07883
            ],
            [
              -80.272399,
              37.082407
            ],
            [
              -80.178125,
              37.1134
            ],
            [
              -80.177673,
              37.133092
            ],
            [
              -80.189592,
              37.233449
            ],
            [
              -80.205091,
              37.248116
            ],
            [
              -80.238936,
              37.286222
            ],
            [
              -80.258577,
              37.308702
            ],
            [
              -80.262184,
              37.341526
            ],
            [
              -80.32484,
              37.368833
            ],
            [
              -80.351522,
              37.349193
            ],
            [
              -80.430943,
              37.316378
            ],
            [
              -80.467436,
              37.293831
            ],
            [
              -80.479194,
              37.285258
            ],
            [
              -80.520399,
              37.268893
            ],
            [
              -80.573592,
              37.256596
            ],
            [
              -80.606962,
              37.244871
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "760",
      "COUNTYNS": "01789073",
      "AFFGEOID": "0500000US51760",
      "GEOID": "51760",
      "NAME": "Richmond",
      "LSAD": "25",
      "ALAND": 155173777,
      "AWATER": 6878090,
      "County_state": "Richmond,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.595462,
              37.55575
            ],
            [
              -77.596849,
              37.534652
            ],
            [
              -77.55452,
              37.543028
            ],
            [
              -77.545036,
              37.528101
            ],
            [
              -77.528746,
              37.531904
            ],
            [
              -77.527912,
              37.517018
            ],
            [
              -77.527464,
              37.516361
            ],
            [
              -77.527404,
              37.516082
            ],
            [
              -77.526715,
              37.515993
            ],
            [
              -77.526868,
              37.515426
            ],
            [
              -77.527016,
              37.51521
            ],
            [
              -77.527655,
              37.514809
            ],
            [
              -77.523176,
              37.5019
            ],
            [
              -77.523552,
              37.498972
            ],
            [
              -77.507174,
              37.467704
            ],
            [
              -77.501545,
              37.464425
            ],
            [
              -77.500739,
              37.464014
            ],
            [
              -77.489098,
              37.455644
            ],
            [
              -77.486388,
              37.459024
            ],
            [
              -77.459383,
              37.464327
            ],
            [
              -77.450158,
              37.460577
            ],
            [
              -77.435488,
              37.457477
            ],
            [
              -77.420875,
              37.447101
            ],
            [
              -77.417791,
              37.457487
            ],
            [
              -77.421705,
              37.498988
            ],
            [
              -77.417615,
              37.504776
            ],
            [
              -77.41651,
              37.517133
            ],
            [
              -77.393877,
              37.504954
            ],
            [
              -77.387447,
              37.517168
            ],
            [
              -77.387597,
              37.517483
            ],
            [
              -77.387765,
              37.517428
            ],
            [
              -77.393905,
              37.526108
            ],
            [
              -77.391306,
              37.530122
            ],
            [
              -77.386037,
              37.528919
            ],
            [
              -77.385513,
              37.535172
            ],
            [
              -77.387776,
              37.536753
            ],
            [
              -77.393246,
              37.537298
            ],
            [
              -77.394993,
              37.544018
            ],
            [
              -77.394961,
              37.549872
            ],
            [
              -77.411538,
              37.552592
            ],
            [
              -77.408937,
              37.557921
            ],
            [
              -77.415236,
              37.56292
            ],
            [
              -77.413323,
              37.579964
            ],
            [
              -77.431189,
              37.580695
            ],
            [
              -77.438045,
              37.58474
            ],
            [
              -77.43762,
              37.598566
            ],
            [
              -77.450427,
              37.602427
            ],
            [
              -77.457713,
              37.602819
            ],
            [
              -77.457799,
              37.602723
            ],
            [
              -77.465886,
              37.597272
            ],
            [
              -77.478146,
              37.598892
            ],
            [
              -77.479386,
              37.589726
            ],
            [
              -77.472489,
              37.578686
            ],
            [
              -77.483147,
              37.57379
            ],
            [
              -77.493528,
              37.582286
            ],
            [
              -77.511812,
              37.581751
            ],
            [
              -77.516464,
              37.581666
            ],
            [
              -77.532388,
              37.592484
            ],
            [
              -77.534201,
              37.584185
            ],
            [
              -77.543353,
              37.58106
            ],
            [
              -77.529827,
              37.559225
            ],
            [
              -77.595462,
              37.55575
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "009",
      "COUNTYNS": "01480095",
      "AFFGEOID": "0500000US51009",
      "GEOID": "51009",
      "NAME": "Amherst",
      "LSAD": "06",
      "ALAND": 1227554626,
      "AWATER": 12883346,
      "County_state": "Amherst,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.436947,
              37.617057
            ],
            [
              -79.436111,
              37.605526
            ],
            [
              -79.424213,
              37.601078
            ],
            [
              -79.404525,
              37.607514
            ],
            [
              -79.380342,
              37.590551
            ],
            [
              -79.367342,
              37.551148
            ],
            [
              -79.347687,
              37.523283
            ],
            [
              -79.336604,
              37.515355
            ],
            [
              -79.313804,
              37.510224
            ],
            [
              -79.298105,
              37.500925
            ],
            [
              -79.262593,
              37.508055
            ],
            [
              -79.257502,
              37.494318
            ],
            [
              -79.241027,
              37.505875
            ],
            [
              -79.223714,
              37.506344
            ],
            [
              -79.225093,
              37.494444
            ],
            [
              -79.209467,
              37.477518
            ],
            [
              -79.189482,
              37.475278
            ],
            [
              -79.185702,
              37.46582
            ],
            [
              -79.185328,
              37.4591
            ],
            [
              -79.165994,
              37.442411
            ],
            [
              -79.146574,
              37.428684
            ],
            [
              -79.120279,
              37.398187
            ],
            [
              -79.109491,
              37.406917
            ],
            [
              -79.085123,
              37.394875
            ],
            [
              -79.068536,
              37.385827
            ],
            [
              -79.060719,
              37.390835
            ],
            [
              -79.070862,
              37.421341
            ],
            [
              -79.045476,
              37.416111
            ],
            [
              -79.040766,
              37.433677
            ],
            [
              -79.021687,
              37.429243
            ],
            [
              -79.010943,
              37.445055
            ],
            [
              -78.97564,
              37.468729
            ],
            [
              -78.943609,
              37.481579
            ],
            [
              -78.949942,
              37.503142
            ],
            [
              -78.923725,
              37.497553
            ],
            [
              -78.89554,
              37.513242
            ],
            [
              -78.898741,
              37.530242
            ],
            [
              -78.869245,
              37.542092
            ],
            [
              -78.897905,
              37.543857
            ],
            [
              -78.960308,
              37.656258
            ],
            [
              -78.983322,
              37.682554
            ],
            [
              -78.986017,
              37.695546
            ],
            [
              -79.005982,
              37.697475
            ],
            [
              -79.014477,
              37.705285
            ],
            [
              -79.030946,
              37.702646
            ],
            [
              -79.067355,
              37.720255
            ],
            [
              -79.065845,
              37.74634
            ],
            [
              -79.071417,
              37.778303
            ],
            [
              -79.089887,
              37.787891
            ],
            [
              -79.109485,
              37.784691
            ],
            [
              -79.121505,
              37.802418
            ],
            [
              -79.137964,
              37.814141
            ],
            [
              -79.172131,
              37.803097
            ],
            [
              -79.199573,
              37.788119
            ],
            [
              -79.209372,
              37.789156
            ],
            [
              -79.231557,
              37.813175
            ],
            [
              -79.264674,
              37.794694
            ],
            [
              -79.308442,
              37.73433
            ],
            [
              -79.322317,
              37.692874
            ],
            [
              -79.340576,
              37.678634
            ],
            [
              -79.345235,
              37.660625
            ],
            [
              -79.365573,
              37.645697
            ],
            [
              -79.380353,
              37.642759
            ],
            [
              -79.40158,
              37.626049
            ],
            [
              -79.419635,
              37.625665
            ],
            [
              -79.436947,
              37.617057
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "115",
      "COUNTYNS": "01480145",
      "AFFGEOID": "0500000US51115",
      "GEOID": "51115",
      "NAME": "Mathews",
      "LSAD": "06",
      "ALAND": 222554152,
      "AWATER": 430025572,
      "County_state": "Mathews,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.451828,
              37.484598
            ],
            [
              -76.442333,
              37.452046
            ],
            [
              -76.447944,
              37.420064
            ],
            [
              -76.407336,
              37.412722
            ],
            [
              -76.40475603175179,
              37.4002130024531
            ],
            [
              -76.393125,
              37.398068
            ],
            [
              -76.393958,
              37.39594
            ],
            [
              -76.391437,
              37.390284
            ],
            [
              -76.387112,
              37.385061
            ],
            [
              -76.366751,
              37.374495
            ],
            [
              -76.337476,
              37.364014
            ],
            [
              -76.31205,
              37.338088
            ],
            [
              -76.308581,
              37.329366
            ],
            [
              -76.291324,
              37.324145
            ],
            [
              -76.282555,
              37.319107
            ],
            [
              -76.275552,
              37.309964
            ],
            [
              -76.272005,
              37.322194
            ],
            [
              -76.272888,
              37.335174
            ],
            [
              -76.264847,
              37.357399
            ],
            [
              -76.262407,
              37.360786
            ],
            [
              -76.258277,
              37.36202
            ],
            [
              -76.24846,
              37.375135
            ],
            [
              -76.245283,
              37.386839
            ],
            [
              -76.246617,
              37.404122
            ],
            [
              -76.250454,
              37.421886
            ],
            [
              -76.252415,
              37.447274
            ],
            [
              -76.265056,
              37.481365
            ],
            [
              -76.281043,
              37.507821
            ],
            [
              -76.288167,
              37.514118
            ],
            [
              -76.293599,
              37.516499
            ],
            [
              -76.297651,
              37.515424
            ],
            [
              -76.298456,
              37.512677
            ],
            [
              -76.296445,
              37.511235
            ],
            [
              -76.297739,
              37.506863
            ],
            [
              -76.306952,
              37.497488
            ],
            [
              -76.32947,
              37.49492
            ],
            [
              -76.352678,
              37.504913
            ],
            [
              -76.359378,
              37.513426
            ],
            [
              -76.360474,
              37.51924
            ],
            [
              -76.35508448147058,
              37.527364175814895
            ],
            [
              -76.404118,
              37.535542
            ],
            [
              -76.418786,
              37.510385
            ],
            [
              -76.437561,
              37.51436
            ],
            [
              -76.451828,
              37.484598
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "730",
      "COUNTYNS": "01498435",
      "AFFGEOID": "0500000US51730",
      "GEOID": "51730",
      "NAME": "Petersburg",
      "LSAD": "25",
      "ALAND": 58813627,
      "AWATER": 609780,
      "County_state": "Petersburg,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.441467,
              37.212231
            ],
            [
              -77.454916,
              37.187616
            ],
            [
              -77.428069,
              37.185019
            ],
            [
              -77.418422,
              37.166111
            ],
            [
              -77.399203,
              37.17085
            ],
            [
              -77.376702,
              37.180549
            ],
            [
              -77.359664,
              37.171919
            ],
            [
              -77.345676,
              37.174136
            ],
            [
              -77.331288,
              37.196515
            ],
            [
              -77.352956,
              37.220005
            ],
            [
              -77.363107,
              37.239945
            ],
            [
              -77.376785,
              37.244849
            ],
            [
              -77.385168,
              37.23839
            ],
            [
              -77.402732,
              37.236069
            ],
            [
              -77.403671,
              37.237346
            ],
            [
              -77.416501,
              37.233208
            ],
            [
              -77.433359,
              37.22503
            ],
            [
              -77.447408,
              37.223066
            ],
            [
              -77.441467,
              37.212231
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "075",
      "COUNTYNS": "01674271",
      "AFFGEOID": "0500000US51075",
      "GEOID": "51075",
      "NAME": "Goochland",
      "LSAD": "06",
      "ALAND": 726830013,
      "AWATER": 21963164,
      "County_state": "Goochland,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.154463,
              37.76337
            ],
            [
              -78.159326,
              37.748528
            ],
            [
              -78.100475,
              37.716416
            ],
            [
              -78.09148,
              37.695734
            ],
            [
              -78.109823,
              37.680406
            ],
            [
              -78.072174,
              37.657385
            ],
            [
              -78.06246,
              37.648028
            ],
            [
              -78.043292,
              37.642498
            ],
            [
              -78.017473,
              37.642564
            ],
            [
              -77.982236,
              37.624279
            ],
            [
              -77.956951,
              37.624938
            ],
            [
              -77.94429,
              37.643772
            ],
            [
              -77.956033,
              37.660812
            ],
            [
              -77.947379,
              37.678087
            ],
            [
              -77.92621,
              37.690349
            ],
            [
              -77.910777,
              37.689289
            ],
            [
              -77.902238,
              37.673132
            ],
            [
              -77.889978,
              37.664245
            ],
            [
              -77.866105,
              37.649563
            ],
            [
              -77.831167,
              37.633587
            ],
            [
              -77.819899,
              37.608629
            ],
            [
              -77.792031,
              37.612726
            ],
            [
              -77.760528,
              37.61113
            ],
            [
              -77.732842,
              37.589958
            ],
            [
              -77.705448,
              37.589144
            ],
            [
              -77.655399,
              37.563986
            ],
            [
              -77.61643,
              37.577555
            ],
            [
              -77.640381,
              37.602851
            ],
            [
              -77.650621,
              37.624188
            ],
            [
              -77.654452,
              37.640725
            ],
            [
              -77.63067,
              37.705673
            ],
            [
              -77.659356,
              37.710204
            ],
            [
              -77.711709,
              37.706285
            ],
            [
              -77.784196,
              37.721625
            ],
            [
              -77.797306,
              37.729358
            ],
            [
              -77.821236,
              37.74563
            ],
            [
              -77.832388,
              37.752909
            ],
            [
              -77.888908,
              37.760808
            ],
            [
              -77.90009,
              37.768349
            ],
            [
              -77.915107,
              37.806104
            ],
            [
              -77.948222,
              37.847221
            ],
            [
              -77.991329,
              37.85752
            ],
            [
              -77.997142,
              37.865419
            ],
            [
              -78.017139,
              37.892575
            ],
            [
              -78.062481,
              37.904685
            ],
            [
              -78.154463,
              37.76337
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "003",
      "COUNTYNS": "01675170",
      "AFFGEOID": "0500000US51003",
      "GEOID": "51003",
      "NAME": "Albemarle",
      "LSAD": "06",
      "ALAND": 1866002205,
      "AWATER": 14235728,
      "County_state": "Albemarle,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.838874,
              38.04737
            ],
            [
              -78.8014,
              37.982628
            ],
            [
              -78.753516,
              37.902557
            ],
            [
              -78.682986,
              37.78206
            ],
            [
              -78.684307,
              37.773843
            ],
            [
              -78.667455,
              37.759409
            ],
            [
              -78.683104,
              37.759992
            ],
            [
              -78.686087,
              37.737505
            ],
            [
              -78.668862,
              37.724752
            ],
            [
              -78.643918,
              37.733084
            ],
            [
              -78.613919,
              37.755798
            ],
            [
              -78.580797,
              37.754047
            ],
            [
              -78.555694,
              37.7627
            ],
            [
              -78.512848,
              37.757222
            ],
            [
              -78.49954,
              37.761594
            ],
            [
              -78.491332,
              37.796971
            ],
            [
              -78.315715,
              37.996219
            ],
            [
              -78.306763,
              38.006474
            ],
            [
              -78.253989,
              38.062813
            ],
            [
              -78.209384,
              38.131128
            ],
            [
              -78.256818,
              38.147905
            ],
            [
              -78.344325,
              38.177786
            ],
            [
              -78.368743,
              38.184074
            ],
            [
              -78.593696,
              38.253523
            ],
            [
              -78.663145,
              38.277933
            ],
            [
              -78.655149,
              38.260632
            ],
            [
              -78.710624,
              38.240466
            ],
            [
              -78.726443,
              38.229972
            ],
            [
              -78.742136,
              38.21772
            ],
            [
              -78.749396,
              38.206648
            ],
            [
              -78.768348,
              38.173465
            ],
            [
              -78.775432,
              38.141008
            ],
            [
              -78.788073,
              38.126404
            ],
            [
              -78.780879,
              38.081029
            ],
            [
              -78.792788,
              38.069504
            ],
            [
              -78.838874,
              38.04737
            ]
          ],
          [
            [
              -78.520375,
              38.018355
            ],
            [
              -78.523035,
              38.026329
            ],
            [
              -78.509613,
              38.032161
            ],
            [
              -78.497791,
              38.033255
            ],
            [
              -78.503456,
              38.036746
            ],
            [
              -78.515527,
              38.036644
            ],
            [
              -78.516072,
              38.043486
            ],
            [
              -78.504839,
              38.047275
            ],
            [
              -78.507616,
              38.056269
            ],
            [
              -78.503748,
              38.057069
            ],
            [
              -78.485202,
              38.068974
            ],
            [
              -78.464439,
              38.06455
            ],
            [
              -78.46613,
              38.0457
            ],
            [
              -78.449214,
              38.058929
            ],
            [
              -78.454668,
              38.021604
            ],
            [
              -78.469504,
              38.011745
            ],
            [
              -78.474959,
              38.013758
            ],
            [
              -78.491355,
              38.017503
            ],
            [
              -78.519284,
              38.010628
            ],
            [
              -78.520375,
              38.018355
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "147",
      "COUNTYNS": "01492359",
      "AFFGEOID": "0500000US51147",
      "GEOID": "51147",
      "NAME": "Prince Edward",
      "LSAD": "06",
      "ALAND": 906395961,
      "AWATER": 10040117,
      "County_state": "Prince Edward,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.681573,
              37.248759
            ],
            [
              -78.692432,
              37.203673
            ],
            [
              -78.680546,
              37.197377
            ],
            [
              -78.644621,
              37.194323
            ],
            [
              -78.634927,
              37.170064
            ],
            [
              -78.620417,
              37.157661
            ],
            [
              -78.595609,
              37.150959
            ],
            [
              -78.583642,
              37.140635
            ],
            [
              -78.573221,
              37.146935
            ],
            [
              -78.547128,
              37.150431
            ],
            [
              -78.541917,
              37.140776
            ],
            [
              -78.515336,
              37.132722
            ],
            [
              -78.501882,
              37.114219
            ],
            [
              -78.503764,
              37.106854
            ],
            [
              -78.480891,
              37.085196
            ],
            [
              -78.457654,
              37.085346
            ],
            [
              -78.443644,
              37.079371
            ],
            [
              -78.382321,
              37.089494
            ],
            [
              -78.35901,
              37.106085
            ],
            [
              -78.312102,
              37.099644
            ],
            [
              -78.275167,
              37.103526
            ],
            [
              -78.239354,
              37.120089
            ],
            [
              -78.238161,
              37.194822
            ],
            [
              -78.231234,
              37.29622
            ],
            [
              -78.235039,
              37.368102
            ],
            [
              -78.259063,
              37.360645
            ],
            [
              -78.258998,
              37.342837
            ],
            [
              -78.277968,
              37.346694
            ],
            [
              -78.292349,
              37.324918
            ],
            [
              -78.309953,
              37.320942
            ],
            [
              -78.32184,
              37.309292
            ],
            [
              -78.34332,
              37.318886
            ],
            [
              -78.352501,
              37.301678
            ],
            [
              -78.364238,
              37.310342
            ],
            [
              -78.395239,
              37.305294
            ],
            [
              -78.413067,
              37.324179
            ],
            [
              -78.440662,
              37.327241
            ],
            [
              -78.465089,
              37.339677
            ],
            [
              -78.486567,
              37.340052
            ],
            [
              -78.490165,
              37.329612
            ],
            [
              -78.511491,
              37.329724
            ],
            [
              -78.536469,
              37.350462
            ],
            [
              -78.548482,
              37.346728
            ],
            [
              -78.569751,
              37.372218
            ],
            [
              -78.578951,
              37.364115
            ],
            [
              -78.579357,
              37.377362
            ],
            [
              -78.591669,
              37.3806
            ],
            [
              -78.580828,
              37.391515
            ],
            [
              -78.594436,
              37.397974
            ],
            [
              -78.594483,
              37.344949
            ],
            [
              -78.627028,
              37.322045
            ],
            [
              -78.673332,
              37.268511
            ],
            [
              -78.681573,
              37.248759
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "033",
      "COUNTYNS": "01480107",
      "AFFGEOID": "0500000US51033",
      "GEOID": "51033",
      "NAME": "Caroline",
      "LSAD": "06",
      "ALAND": 1366471980,
      "AWATER": 24948487,
      "County_state": "Caroline,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.642518,
              37.990688
            ],
            [
              -77.643457,
              37.979708
            ],
            [
              -77.608288,
              37.954454
            ],
            [
              -77.581241,
              37.950597
            ],
            [
              -77.576728,
              37.958072
            ],
            [
              -77.561693,
              37.93606
            ],
            [
              -77.547938,
              37.926203
            ],
            [
              -77.546224,
              37.915966
            ],
            [
              -77.528882,
              37.9208
            ],
            [
              -77.514783,
              37.904329
            ],
            [
              -77.493333,
              37.897271
            ],
            [
              -77.48417,
              37.883369
            ],
            [
              -77.445516,
              37.894724
            ],
            [
              -77.436289,
              37.885215
            ],
            [
              -77.421989,
              37.864929
            ],
            [
              -77.428167,
              37.856461
            ],
            [
              -77.428267,
              37.829567
            ],
            [
              -77.411227,
              37.822992
            ],
            [
              -77.420313,
              37.815864
            ],
            [
              -77.40645,
              37.802837
            ],
            [
              -77.395243,
              37.802442
            ],
            [
              -77.403688,
              37.785925
            ],
            [
              -77.397317,
              37.777289
            ],
            [
              -77.373677,
              37.777013
            ],
            [
              -77.36233,
              37.797661
            ],
            [
              -77.345215,
              37.789175
            ],
            [
              -77.330124,
              37.805738
            ],
            [
              -77.279312,
              37.844365
            ],
            [
              -77.247702,
              37.876174
            ],
            [
              -77.243162,
              37.900241
            ],
            [
              -77.241679,
              37.90955
            ],
            [
              -77.211493,
              37.901163
            ],
            [
              -77.210202,
              37.886184
            ],
            [
              -77.181418,
              37.891654
            ],
            [
              -77.186414,
              37.911249
            ],
            [
              -77.181962,
              37.949577
            ],
            [
              -77.167284,
              37.963904
            ],
            [
              -77.148634,
              37.972
            ],
            [
              -77.105848,
              37.971431
            ],
            [
              -77.070115,
              37.96477
            ],
            [
              -77.072658,
              37.994739
            ],
            [
              -77.08897,
              38.013644
            ],
            [
              -77.103296,
              38.011334
            ],
            [
              -77.130691,
              38.017731
            ],
            [
              -77.121793,
              38.033628
            ],
            [
              -77.129437,
              38.044992
            ],
            [
              -77.159137,
              38.064102
            ],
            [
              -77.1701,
              38.079543
            ],
            [
              -77.145262,
              38.094261
            ],
            [
              -77.144408,
              38.120851
            ],
            [
              -77.116847,
              38.120641
            ],
            [
              -77.105632,
              38.126235
            ],
            [
              -77.115997,
              38.149931
            ],
            [
              -77.132897,
              38.164331
            ],
            [
              -77.148998,
              38.16993
            ],
            [
              -77.164598,
              38.16503
            ],
            [
              -77.188086,
              38.174402
            ],
            [
              -77.2143,
              38.197029
            ],
            [
              -77.2323,
              38.196929
            ],
            [
              -77.2298,
              38.18783
            ],
            [
              -77.253701,
              38.18913
            ],
            [
              -77.242001,
              38.210729
            ],
            [
              -77.251801,
              38.229628
            ],
            [
              -77.22871,
              38.235106
            ],
            [
              -77.224689,
              38.242207
            ],
            [
              -77.249801,
              38.250728
            ],
            [
              -77.270602,
              38.249828
            ],
            [
              -77.280402,
              38.224429
            ],
            [
              -77.290302,
              38.238728
            ],
            [
              -77.309419,
              38.234737
            ],
            [
              -77.326692,
              38.245136
            ],
            [
              -77.340623,
              38.252616
            ],
            [
              -77.370297,
              38.246576
            ],
            [
              -77.403132,
              38.214556
            ],
            [
              -77.440229,
              38.177318
            ],
            [
              -77.518179,
              38.10489
            ],
            [
              -77.551611,
              38.073609
            ],
            [
              -77.626219,
              38.003429
            ],
            [
              -77.642518,
              37.990688
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "065",
      "COUNTYNS": "01494061",
      "AFFGEOID": "0500000US51065",
      "GEOID": "51065",
      "NAME": "Fluvanna",
      "LSAD": "06",
      "ALAND": 743630411,
      "AWATER": 10746124,
      "County_state": "Fluvanna,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.491332,
              37.796971
            ],
            [
              -78.464149,
              37.7602
            ],
            [
              -78.422111,
              37.740451
            ],
            [
              -78.385096,
              37.733746
            ],
            [
              -78.365616,
              37.731897
            ],
            [
              -78.33605,
              37.716157
            ],
            [
              -78.284124,
              37.705444
            ],
            [
              -78.257794,
              37.691659
            ],
            [
              -78.239748,
              37.690495
            ],
            [
              -78.225302,
              37.711579
            ],
            [
              -78.18099,
              37.744959
            ],
            [
              -78.159326,
              37.748528
            ],
            [
              -78.154463,
              37.76337
            ],
            [
              -78.062481,
              37.904685
            ],
            [
              -78.174004,
              37.95023
            ],
            [
              -78.205125,
              37.95734
            ],
            [
              -78.220092,
              37.970102
            ],
            [
              -78.230126,
              37.97854
            ],
            [
              -78.276527,
              37.99154
            ],
            [
              -78.306763,
              38.006474
            ],
            [
              -78.315715,
              37.996219
            ],
            [
              -78.491332,
              37.796971
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "133",
      "COUNTYNS": "01480152",
      "AFFGEOID": "0500000US51133",
      "GEOID": "51133",
      "NAME": "Northumberland",
      "LSAD": "06",
      "ALAND": 495728041,
      "AWATER": 243986806,
      "County_state": "Northumberland,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.616294,
              38.001623
            ],
            [
              -76.63423,
              37.96663
            ],
            [
              -76.604151,
              37.949008
            ],
            [
              -76.556042,
              37.931524
            ],
            [
              -76.541736,
              37.881919
            ],
            [
              -76.548227,
              37.868979
            ],
            [
              -76.510877,
              37.84909
            ],
            [
              -76.507828,
              37.838814
            ],
            [
              -76.477639,
              37.837394
            ],
            [
              -76.468482,
              37.8229
            ],
            [
              -76.44148,
              37.822253
            ],
            [
              -76.429636,
              37.829043
            ],
            [
              -76.420071,
              37.823766
            ],
            [
              -76.419696,
              37.806916
            ],
            [
              -76.401056,
              37.780249
            ],
            [
              -76.381917,
              37.779534
            ],
            [
              -76.364434,
              37.768521
            ],
            [
              -76.364348,
              37.728353
            ],
            [
              -76.375964,
              37.711498
            ],
            [
              -76.361017,
              37.708432
            ],
            [
              -76.34461,
              37.690935
            ],
            [
              -76.32021562464779,
              37.68066613549289
            ],
            [
              -76.315161,
              37.68472
            ],
            [
              -76.312079,
              37.684651
            ],
            [
              -76.302545,
              37.689
            ],
            [
              -76.300067,
              37.695364
            ],
            [
              -76.302803,
              37.704474
            ],
            [
              -76.312858,
              37.720338
            ],
            [
              -76.304917,
              37.729913
            ],
            [
              -76.312108,
              37.750522
            ],
            [
              -76.306489,
              37.788646
            ],
            [
              -76.310307,
              37.794849
            ],
            [
              -76.307482,
              37.81235
            ],
            [
              -76.293525,
              37.822717
            ],
            [
              -76.284904,
              37.822308
            ],
            [
              -76.281985,
              37.818068
            ],
            [
              -76.282592,
              37.814109
            ],
            [
              -76.280544,
              37.812597
            ],
            [
              -76.275178,
              37.812664
            ],
            [
              -76.266057,
              37.8174
            ],
            [
              -76.251358,
              37.833072
            ],
            [
              -76.245072,
              37.861918
            ],
            [
              -76.236725,
              37.889174
            ],
            [
              -76.265998,
              37.91138
            ],
            [
              -76.343848,
              37.947345
            ],
            [
              -76.360211,
              37.952329
            ],
            [
              -76.391439,
              37.958742
            ],
            [
              -76.416299,
              37.966828
            ],
            [
              -76.427487,
              37.977038
            ],
            [
              -76.462542,
              37.998572
            ],
            [
              -76.465291,
              38.010226
            ],
            [
              -76.469343,
              38.013544
            ],
            [
              -76.491998,
              38.017222
            ],
            [
              -76.516547,
              38.026566
            ],
            [
              -76.5195360450964,
              38.034814055558
            ],
            [
              -76.571899,
              38.027046
            ],
            [
              -76.590917,
              38.003725
            ],
            [
              -76.616294,
              38.001623
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "093",
      "COUNTYNS": "01702378",
      "AFFGEOID": "0500000US51093",
      "GEOID": "51093",
      "NAME": "Isle of Wight",
      "LSAD": "06",
      "ALAND": 817464624,
      "AWATER": 122256020,
      "County_state": "Isle of Wight,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.930272,
              36.704996
            ],
            [
              -76.930071,
              36.7046
            ],
            [
              -76.929563,
              36.704342
            ],
            [
              -76.928884,
              36.704118
            ],
            [
              -76.928623,
              36.703815
            ],
            [
              -76.928184,
              36.703045
            ],
            [
              -76.927963,
              36.702824
            ],
            [
              -76.92745,
              36.702648
            ],
            [
              -76.927241,
              36.701915
            ],
            [
              -76.927342,
              36.701371
            ],
            [
              -76.927962,
              36.700502
            ],
            [
              -76.927882,
              36.700339
            ],
            [
              -76.927401,
              36.700183
            ],
            [
              -76.92649,
              36.700311
            ],
            [
              -76.926049,
              36.69996
            ],
            [
              -76.925926,
              36.699604
            ],
            [
              -76.926251,
              36.698862
            ],
            [
              -76.926142,
              36.698587
            ],
            [
              -76.925691,
              36.698329
            ],
            [
              -76.925229,
              36.698421
            ],
            [
              -76.924958,
              36.698648
            ],
            [
              -76.924832,
              36.699132
            ],
            [
              -76.924063,
              36.699095
            ],
            [
              -76.923824,
              36.69874
            ],
            [
              -76.923125,
              36.699036
            ],
            [
              -76.922958,
              36.699756
            ],
            [
              -76.922396,
              36.699988
            ],
            [
              -76.921268,
              36.699759
            ],
            [
              -76.921056,
              36.699873
            ],
            [
              -76.921018,
              36.700171
            ],
            [
              -76.920602,
              36.700283
            ],
            [
              -76.920407,
              36.700175
            ],
            [
              -76.920275,
              36.699045
            ],
            [
              -76.920044,
              36.699018
            ],
            [
              -76.918853,
              36.699606
            ],
            [
              -76.918365,
              36.700214
            ],
            [
              -76.918575,
              36.700643
            ],
            [
              -76.918424,
              36.700757
            ],
            [
              -76.918045,
              36.70033
            ],
            [
              -76.917735,
              36.700212
            ],
            [
              -76.91743,
              36.700315
            ],
            [
              -76.916913,
              36.699916
            ],
            [
              -76.916672,
              36.70008
            ],
            [
              -76.9163,
              36.700581
            ],
            [
              -76.915772,
              36.700985
            ],
            [
              -76.915059,
              36.700737
            ],
            [
              -76.913541,
              36.699599
            ],
            [
              -76.912463,
              36.698874
            ],
            [
              -76.912221,
              36.698408
            ],
            [
              -76.91236,
              36.697824
            ],
            [
              -76.913245,
              36.696968
            ],
            [
              -76.913031,
              36.696353
            ],
            [
              -76.913101,
              36.696105
            ],
            [
              -76.913889,
              36.695672
            ],
            [
              -76.914505,
              36.695093
            ],
            [
              -76.91507,
              36.694663
            ],
            [
              -76.915487,
              36.694743
            ],
            [
              -76.91581,
              36.695381
            ],
            [
              -76.9163,
              36.695311
            ],
            [
              -76.916505,
              36.694924
            ],
            [
              -76.916361,
              36.694061
            ],
            [
              -76.916015,
              36.693757
            ],
            [
              -76.91546,
              36.693655
            ],
            [
              -76.915434,
              36.693235
            ],
            [
              -76.915846,
              36.693117
            ],
            [
              -76.91708,
              36.693344
            ],
            [
              -76.917328,
              36.693043
            ],
            [
              -76.917039,
              36.690759
            ],
            [
              -76.917224,
              36.690175
            ],
            [
              -76.917528,
              36.690046
            ],
            [
              -76.918104,
              36.690359
            ],
            [
              -76.918347,
              36.690219
            ],
            [
              -76.918643,
              36.689793
            ],
            [
              -76.918647,
              36.689336
            ],
            [
              -76.918981,
              36.689182
            ],
            [
              -76.919568,
              36.689296
            ],
            [
              -76.919746,
              36.689058
            ],
            [
              -76.919586,
              36.688776
            ],
            [
              -76.918493,
              36.68867
            ],
            [
              -76.917625,
              36.687769
            ],
            [
              -76.917657,
              36.687199
            ],
            [
              -76.918646,
              36.686824
            ],
            [
              -76.920004,
              36.686134
            ],
            [
              -76.920984,
              36.686007
            ],
            [
              -76.921115,
              36.685733
            ],
            [
              -76.920953,
              36.685402
            ],
            [
              -76.920531,
              36.685099
            ],
            [
              -76.920638,
              36.683823
            ],
            [
              -76.920915,
              36.683237
            ],
            [
              -76.921921,
              36.68264
            ],
            [
              -76.921912,
              36.682491
            ],
            [
              -76.922266,
              36.682518
            ],
            [
              -76.922568,
              36.682813
            ],
            [
              -76.922688,
              36.683552
            ],
            [
              -76.92282,
              36.683742
            ],
            [
              -76.923308,
              36.683795
            ],
            [
              -76.923576,
              36.683649
            ],
            [
              -76.923878,
              36.683196
            ],
            [
              -76.924047,
              36.682452
            ],
            [
              -76.92448,
              36.682421
            ],
            [
              -76.924476,
              36.683299
            ],
            [
              -76.924575,
              36.683913
            ],
            [
              -76.924989,
              36.683886
            ],
            [
              -76.92518,
              36.683342
            ],
            [
              -76.924982,
              36.682195
            ],
            [
              -76.92315,
              36.681395
            ],
            [
              -76.921455,
              36.681781
            ],
            [
              -76.920422,
              36.681625
            ],
            [
              -76.917309,
              36.67998
            ],
            [
              -76.916467,
              36.679165
            ],
            [
              -76.916425,
              36.678683
            ],
            [
              -76.917129,
              36.676111
            ],
            [
              -76.919013,
              36.674695
            ],
            [
              -76.918595,
              36.674232
            ],
            [
              -76.917729,
              36.674023
            ],
            [
              -76.916145,
              36.674531
            ],
            [
              -76.914856,
              36.674564
            ],
            [
              -76.913445,
              36.674043
            ],
            [
              -76.912015,
              36.672717
            ],
            [
              -76.909672,
              36.648175
            ],
            [
              -76.897592,
              36.64405
            ],
            [
              -76.860938,
              36.670658
            ],
            [
              -76.813535,
              36.706437
            ],
            [
              -76.747665,
              36.752616
            ],
            [
              -76.691033,
              36.796239
            ],
            [
              -76.581707,
              36.879413
            ],
            [
              -76.529279,
              36.916698
            ],
            [
              -76.521099,
              36.90633
            ],
            [
              -76.501754,
              36.913646
            ],
            [
              -76.48410706581349,
              36.928916
            ],
            [
              -76.487559,
              36.952372
            ],
            [
              -76.500355,
              36.965212
            ],
            [
              -76.513363,
              36.968057
            ],
            [
              -76.521006,
              36.973187
            ],
            [
              -76.524142,
              36.978316
            ],
            [
              -76.522971,
              36.981085
            ],
            [
              -76.524853,
              36.983833
            ],
            [
              -76.551246,
              36.998946
            ],
            [
              -76.562923,
              37.003796
            ],
            [
              -76.577531,
              37.022548
            ],
            [
              -76.586491,
              37.02874
            ],
            [
              -76.612124,
              37.035604
            ],
            [
              -76.646013,
              37.036228
            ],
            [
              -76.653998,
              37.039172
            ],
            [
              -76.662558,
              37.045748
            ],
            [
              -76.66835,
              37.05506
            ],
            [
              -76.669822,
              37.06426
            ],
            [
              -76.667646,
              37.076228
            ],
            [
              -76.65811,
              37.096787
            ],
            [
              -76.656894,
              37.109843
            ],
            [
              -76.666542,
              37.138179
            ],
            [
              -76.671588,
              37.14206
            ],
            [
              -76.680286,
              37.126291
            ],
            [
              -76.684478,
              37.088164
            ],
            [
              -76.698255,
              37.05914
            ],
            [
              -76.729753,
              37.048123
            ],
            [
              -76.849684,
              36.996211
            ],
            [
              -76.85897,
              36.956031
            ],
            [
              -76.836282,
              36.922725
            ],
            [
              -76.817073,
              36.906167
            ],
            [
              -76.827278,
              36.886023
            ],
            [
              -76.818031,
              36.880584
            ],
            [
              -76.852202,
              36.851807
            ],
            [
              -76.868881,
              36.808449
            ],
            [
              -76.867007,
              36.787959
            ],
            [
              -76.883445,
              36.781181
            ],
            [
              -76.884829,
              36.766335
            ],
            [
              -76.901405,
              36.757393
            ],
            [
              -76.903552,
              36.739304
            ],
            [
              -76.91699,
              36.733724
            ],
            [
              -76.914176,
              36.722144
            ],
            [
              -76.924629,
              36.707729
            ],
            [
              -76.930272,
              36.704996
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "685",
      "COUNTYNS": "01498431",
      "AFFGEOID": "0500000US51685",
      "GEOID": "51685",
      "NAME": "Manassas Park",
      "LSAD": "25",
      "ALAND": 6538719,
      "AWATER": 616,
      "County_state": "Manassas Park,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.478795,
              38.782583
            ],
            [
              -77.47576,
              38.781171
            ],
            [
              -77.474711,
              38.781414
            ],
            [
              -77.458695,
              38.772319
            ],
            [
              -77.427332,
              38.753598
            ],
            [
              -77.432828,
              38.779303
            ],
            [
              -77.439897,
              38.783208
            ],
            [
              -77.444978,
              38.770564
            ],
            [
              -77.472972,
              38.788391
            ],
            [
              -77.478795,
              38.782583
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "097",
      "COUNTYNS": "01480136",
      "AFFGEOID": "0500000US51097",
      "GEOID": "51097",
      "NAME": "King and Queen",
      "LSAD": "06",
      "ALAND": 816244563,
      "AWATER": 28884077,
      "County_state": "King and Queen,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.181962,
              37.949577
            ],
            [
              -77.186414,
              37.911249
            ],
            [
              -77.181418,
              37.891654
            ],
            [
              -77.169671,
              37.897356
            ],
            [
              -77.164718,
              37.883934
            ],
            [
              -77.149497,
              37.882063
            ],
            [
              -77.147363,
              37.864583
            ],
            [
              -77.134,
              37.866108
            ],
            [
              -77.139784,
              37.852037
            ],
            [
              -77.123591,
              37.839297
            ],
            [
              -77.126454,
              37.826637
            ],
            [
              -77.113241,
              37.805144
            ],
            [
              -77.099368,
              37.813314
            ],
            [
              -77.103108,
              37.786034
            ],
            [
              -77.082548,
              37.781325
            ],
            [
              -77.073723,
              37.773227
            ],
            [
              -77.089542,
              37.755191
            ],
            [
              -77.083062,
              37.747866
            ],
            [
              -77.06046,
              37.749751
            ],
            [
              -77.033273,
              37.720809
            ],
            [
              -77.010383,
              37.7219
            ],
            [
              -76.9972,
              37.707236
            ],
            [
              -76.972264,
              37.705731
            ],
            [
              -76.97027,
              37.69292
            ],
            [
              -76.956408,
              37.688059
            ],
            [
              -76.915586,
              37.693325
            ],
            [
              -76.905995,
              37.67047
            ],
            [
              -76.913499,
              37.659125
            ],
            [
              -76.907545,
              37.654682
            ],
            [
              -76.880491,
              37.65713
            ],
            [
              -76.854942,
              37.634047
            ],
            [
              -76.868807,
              37.624013
            ],
            [
              -76.854267,
              37.613667
            ],
            [
              -76.840999,
              37.617521
            ],
            [
              -76.833364,
              37.606994
            ],
            [
              -76.816304,
              37.605456
            ],
            [
              -76.825842,
              37.597321
            ],
            [
              -76.814286,
              37.588981
            ],
            [
              -76.793708,
              37.586254
            ],
            [
              -76.799341,
              37.575795
            ],
            [
              -76.785248,
              37.569072
            ],
            [
              -76.777729,
              37.554446
            ],
            [
              -76.789111,
              37.535391
            ],
            [
              -76.792184,
              37.517594
            ],
            [
              -76.79085,
              37.505762
            ],
            [
              -76.751693,
              37.483941
            ],
            [
              -76.741984,
              37.467076
            ],
            [
              -76.732961,
              37.450235
            ],
            [
              -76.712305,
              37.431309
            ],
            [
              -76.704721,
              37.448035
            ],
            [
              -76.687006,
              37.447882
            ],
            [
              -76.673994,
              37.461323
            ],
            [
              -76.667425,
              37.455525
            ],
            [
              -76.646794,
              37.481154
            ],
            [
              -76.648009,
              37.495891
            ],
            [
              -76.669084,
              37.52963
            ],
            [
              -76.654076,
              37.603174
            ],
            [
              -76.682266,
              37.617242
            ],
            [
              -76.717955,
              37.657732
            ],
            [
              -76.751067,
              37.711665
            ],
            [
              -76.751072,
              37.728605
            ],
            [
              -76.755812,
              37.744497
            ],
            [
              -76.77208,
              37.762322
            ],
            [
              -76.790036,
              37.771844
            ],
            [
              -76.790992,
              37.786734
            ],
            [
              -76.804423,
              37.794892
            ],
            [
              -76.842628,
              37.79804
            ],
            [
              -76.87231,
              37.782729
            ],
            [
              -76.906446,
              37.796236
            ],
            [
              -76.943221,
              37.782955
            ],
            [
              -76.945212,
              37.828174
            ],
            [
              -76.969882,
              37.828592
            ],
            [
              -76.981638,
              37.839027
            ],
            [
              -77.00326,
              37.836038
            ],
            [
              -77.018996,
              37.844883
            ],
            [
              -77.020204,
              37.86105
            ],
            [
              -77.041353,
              37.869299
            ],
            [
              -77.020245,
              37.885276
            ],
            [
              -77.031458,
              37.890808
            ],
            [
              -77.025066,
              37.903285
            ],
            [
              -77.033619,
              37.925278
            ],
            [
              -77.055028,
              37.931016
            ],
            [
              -77.059695,
              37.943243
            ],
            [
              -77.073036,
              37.94616
            ],
            [
              -77.070115,
              37.96477
            ],
            [
              -77.105848,
              37.971431
            ],
            [
              -77.148634,
              37.972
            ],
            [
              -77.167284,
              37.963904
            ],
            [
              -77.181962,
              37.949577
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "520",
      "COUNTYNS": "01498416",
      "AFFGEOID": "0500000US51520",
      "GEOID": "51520",
      "NAME": "Bristol",
      "LSAD": "25",
      "ALAND": 33420181,
      "AWATER": 322409,
      "County_state": "Bristol,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.24319622554509,
              36.595733827872394
            ],
            [
              -82.226653,
              36.595743
            ],
            [
              -82.225716,
              36.595744
            ],
            [
              -82.223445,
              36.595721
            ],
            [
              -82.221713,
              36.595814
            ],
            [
              -82.211005,
              36.59586
            ],
            [
              -82.210497,
              36.595772
            ],
            [
              -82.188491,
              36.595179
            ],
            [
              -82.18074,
              36.594928
            ],
            [
              -82.177247,
              36.594768
            ],
            [
              -82.173982,
              36.594607
            ],
            [
              -82.150727,
              36.594673
            ],
            [
              -82.148569,
              36.594718
            ],
            [
              -82.1455730331036,
              36.594710944091005
            ],
            [
              -82.105812,
              36.62983
            ],
            [
              -82.10655,
              36.651517
            ],
            [
              -82.121333,
              36.658453
            ],
            [
              -82.11676,
              36.668018
            ],
            [
              -82.132643,
              36.677656
            ],
            [
              -82.144574,
              36.670852
            ],
            [
              -82.130838,
              36.668511
            ],
            [
              -82.114373,
              36.638418
            ],
            [
              -82.15784,
              36.626745
            ],
            [
              -82.179632,
              36.626798
            ],
            [
              -82.24319622554509,
              36.595733827872394
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "570",
      "COUNTYNS": "01498420",
      "AFFGEOID": "0500000US51570",
      "GEOID": "51570",
      "NAME": "Colonial Heights",
      "LSAD": "25",
      "ALAND": 19475234,
      "AWATER": 766909,
      "County_state": "Colonial Heights,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.431794,
              37.275912
            ],
            [
              -77.426774,
              37.269139
            ],
            [
              -77.41854,
              37.265901
            ],
            [
              -77.416501,
              37.233208
            ],
            [
              -77.403671,
              37.237346
            ],
            [
              -77.382521,
              37.242837
            ],
            [
              -77.379193,
              37.26758
            ],
            [
              -77.362322,
              37.282162
            ],
            [
              -77.368203,
              37.298549
            ],
            [
              -77.389149,
              37.287357
            ],
            [
              -77.39934,
              37.272215
            ],
            [
              -77.410102,
              37.282377
            ],
            [
              -77.431794,
              37.275912
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "185",
      "COUNTYNS": "01497748",
      "AFFGEOID": "0500000US51185",
      "GEOID": "51185",
      "NAME": "Tazewell",
      "LSAD": "06",
      "ALAND": 1343661925,
      "AWATER": 2921369,
      "County_state": "Tazewell,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.900892,
              37.142553
            ],
            [
              -81.891041,
              37.113128
            ],
            [
              -81.827205,
              37.053848
            ],
            [
              -81.815877,
              37.037943
            ],
            [
              -81.805419,
              37.018655
            ],
            [
              -81.780545,
              36.958629
            ],
            [
              -81.727857,
              36.969745
            ],
            [
              -81.708788,
              36.977011
            ],
            [
              -81.682786,
              36.932934
            ],
            [
              -81.640781,
              36.948506
            ],
            [
              -81.576298,
              36.989698
            ],
            [
              -81.510392,
              37.003429
            ],
            [
              -81.495026,
              37.014986
            ],
            [
              -81.474539,
              37.018476
            ],
            [
              -81.472497,
              36.998471
            ],
            [
              -81.480519,
              36.986291
            ],
            [
              -81.43673,
              37.010135
            ],
            [
              -81.462279,
              37.035402
            ],
            [
              -81.436602,
              37.041137
            ],
            [
              -81.399281,
              37.057678
            ],
            [
              -81.357998,
              37.058295
            ],
            [
              -81.326273,
              37.067374
            ],
            [
              -81.270078,
              37.093012
            ],
            [
              -81.261318,
              37.114727
            ],
            [
              -81.269362,
              37.12779
            ],
            [
              -81.32814,
              37.138949
            ],
            [
              -81.333009,
              37.149137
            ],
            [
              -81.31376,
              37.159447
            ],
            [
              -81.263864,
              37.173018
            ],
            [
              -81.284703,
              37.215734
            ],
            [
              -81.243553,
              37.224062
            ],
            [
              -81.225104,
              37.234874
            ],
            [
              -81.2744856162342,
              37.2683746556213
            ],
            [
              -81.320105,
              37.299323
            ],
            [
              -81.362156,
              37.337687
            ],
            [
              -81.366315,
              37.335927
            ],
            [
              -81.367052,
              37.334504
            ],
            [
              -81.36809,
              37.332423
            ],
            [
              -81.369379,
              37.331827
            ],
            [
              -81.369264,
              37.330568
            ],
            [
              -81.36803,
              37.329447
            ],
            [
              -81.367599,
              37.327569
            ],
            [
              -81.371315,
              37.324115
            ],
            [
              -81.37261,
              37.320195
            ],
            [
              -81.374455,
              37.318614
            ],
            [
              -81.377349,
              37.318447
            ],
            [
              -81.380159,
              37.317838
            ],
            [
              -81.384127,
              37.318596
            ],
            [
              -81.384914,
              37.318832
            ],
            [
              -81.38581,
              37.320085
            ],
            [
              -81.386727,
              37.320474
            ],
            [
              -81.388132,
              37.319903
            ],
            [
              -81.394287,
              37.316411
            ],
            [
              -81.398548,
              37.310635
            ],
            [
              -81.398702,
              37.307806
            ],
            [
              -81.397357,
              37.306358
            ],
            [
              -81.396817,
              37.304498
            ],
            [
              -81.398185,
              37.302965
            ],
            [
              -81.402195,
              37.30166
            ],
            [
              -81.40506,
              37.298794
            ],
            [
              -81.403764,
              37.296597
            ],
            [
              -81.409196,
              37.286071
            ],
            [
              -81.409729,
              37.284837
            ],
            [
              -81.409577,
              37.284025
            ],
            [
              -81.411593,
              37.28033
            ],
            [
              -81.416663,
              37.273214
            ],
            [
              -81.427946,
              37.271015
            ],
            [
              -81.43285,
              37.272697
            ],
            [
              -81.448285,
              37.270575
            ],
            [
              -81.449068,
              37.269583
            ],
            [
              -81.454199,
              37.266999
            ],
            [
              -81.458895,
              37.266466
            ],
            [
              -81.460585,
              37.265527
            ],
            [
              -81.459874,
              37.263901
            ],
            [
              -81.46,
              37.262547
            ],
            [
              -81.462107,
              37.259899
            ],
            [
              -81.476431,
              37.255127
            ],
            [
              -81.480144,
              37.251121
            ],
            [
              -81.483559,
              37.250604
            ],
            [
              -81.492287,
              37.25096
            ],
            [
              -81.495738,
              37.252393
            ],
            [
              -81.498045,
              37.254659
            ],
            [
              -81.498445,
              37.256568
            ],
            [
              -81.497773,
              37.25719
            ],
            [
              -81.497775,
              37.257899
            ],
            [
              -81.498874,
              37.258025
            ],
            [
              -81.50319,
              37.252579
            ],
            [
              -81.504168,
              37.250115
            ],
            [
              -81.50488,
              37.247697
            ],
            [
              -81.506428,
              37.244469
            ],
            [
              -81.50626,
              37.239272
            ],
            [
              -81.507325,
              37.2338
            ],
            [
              -81.508786,
              37.232564
            ],
            [
              -81.520729,
              37.226914
            ],
            [
              -81.527458,
              37.225817
            ],
            [
              -81.53307,
              37.223414
            ],
            [
              -81.544437,
              37.220761
            ],
            [
              -81.545211,
              37.220165
            ],
            [
              -81.549248,
              37.213732
            ],
            [
              -81.5536,
              37.208443
            ],
            [
              -81.556119,
              37.207413
            ],
            [
              -81.556892,
              37.207275
            ],
            [
              -81.557315,
              37.207697
            ],
            [
              -81.558353,
              37.208145
            ],
            [
              -81.560625,
              37.206663
            ],
            [
              -81.67821,
              37.201483
            ],
            [
              -81.678603,
              37.202467
            ],
            [
              -81.681379,
              37.203634
            ],
            [
              -81.683268,
              37.205649
            ],
            [
              -81.684012,
              37.211098
            ],
            [
              -81.683544,
              37.211452
            ],
            [
              -81.686717,
              37.213105
            ],
            [
              -81.695113,
              37.21357
            ],
            [
              -81.698954,
              37.218201
            ],
            [
              -81.71573,
              37.228771
            ],
            [
              -81.716248,
              37.234321
            ],
            [
              -81.719554,
              37.237785
            ],
            [
              -81.723061,
              37.240493
            ],
            [
              -81.726171,
              37.240522
            ],
            [
              -81.728194,
              37.239823
            ],
            [
              -81.73332,
              37.238127
            ],
            [
              -81.738543,
              37.238264
            ],
            [
              -81.7392766866866,
              37.2388369743647
            ],
            [
              -81.761894,
              37.201927
            ],
            [
              -81.797431,
              37.186007
            ],
            [
              -81.857315,
              37.174425
            ],
            [
              -81.867123,
              37.15776
            ],
            [
              -81.900892,
              37.142553
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "043",
      "COUNTYNS": "01690562",
      "AFFGEOID": "0500000US51043",
      "GEOID": "51043",
      "NAME": "Clarke",
      "LSAD": "06",
      "ALAND": 455661684,
      "AWATER": 5693068,
      "County_state": "Clarke,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.142073,
              39.061735
            ],
            [
              -78.151614,
              39.036629
            ],
            [
              -78.096992,
              39.012923
            ],
            [
              -78.058532,
              39.019181
            ],
            [
              -78.046112,
              39.011718
            ],
            [
              -78.004313,
              38.979438
            ],
            [
              -77.990275,
              38.996493
            ],
            [
              -77.962203,
              39.013733
            ],
            [
              -77.893279,
              39.055425
            ],
            [
              -77.887892,
              39.064463
            ],
            [
              -77.862825,
              39.080979
            ],
            [
              -77.855169,
              39.095284
            ],
            [
              -77.853741,
              39.101025
            ],
            [
              -77.845329,
              39.11686
            ],
            [
              -77.843797,
              39.119747
            ],
            [
              -77.83896,
              39.121814
            ],
            [
              -77.82830183326729,
              39.1324224548325
            ],
            [
              -78.032841,
              39.264403
            ],
            [
              -78.0331853088999,
              39.2646209889705
            ],
            [
              -78.04254,
              39.236631
            ],
            [
              -78.074694,
              39.204942
            ],
            [
              -78.073001,
              39.178085
            ],
            [
              -78.085094,
              39.158536
            ],
            [
              -78.090269,
              39.147661
            ],
            [
              -78.107,
              39.120805
            ],
            [
              -78.109794,
              39.095822
            ],
            [
              -78.125416,
              39.089187
            ],
            [
              -78.142073,
              39.061735
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "083",
      "COUNTYNS": "01496705",
      "AFFGEOID": "0500000US51083",
      "GEOID": "51083",
      "NAME": "Halifax",
      "LSAD": "06",
      "ALAND": 2117910304,
      "AWATER": 30527401,
      "County_state": "Halifax,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.2184541869829,
              36.5414925778669
            ],
            [
              -79.20948,
              36.541594
            ],
            [
              -79.208686,
              36.541571
            ],
            [
              -79.1383311871639,
              36.541738061604995
            ],
            [
              -79.137936,
              36.541739
            ],
            [
              -79.126078,
              36.541533
            ],
            [
              -79.124736,
              36.541568
            ],
            [
              -78.9791747288393,
              36.5420962856979
            ],
            [
              -78.971814,
              36.542123
            ],
            [
              -78.970577,
              36.542154
            ],
            [
              -78.942254,
              36.542079
            ],
            [
              -78.942009,
              36.542113
            ],
            [
              -78.91542,
              36.541974
            ],
            [
              -78.914543,
              36.541972
            ],
            [
              -78.7963,
              36.541713
            ],
            [
              -78.7962710014091,
              36.5417130131513
            ],
            [
              -78.76543,
              36.541727
            ],
            [
              -78.758392,
              36.541852
            ],
            [
              -78.7341219239254,
              36.5419022759072
            ],
            [
              -78.737799,
              36.555875
            ],
            [
              -78.715694,
              36.580836
            ],
            [
              -78.720028,
              36.588877
            ],
            [
              -78.712316,
              36.609583
            ],
            [
              -78.711702,
              36.641078
            ],
            [
              -78.697302,
              36.66888
            ],
            [
              -78.677596,
              36.688326
            ],
            [
              -78.653551,
              36.69085
            ],
            [
              -78.640221,
              36.684668
            ],
            [
              -78.624724,
              36.667317
            ],
            [
              -78.594883,
              36.652895
            ],
            [
              -78.578771,
              36.638539
            ],
            [
              -78.56393,
              36.64014
            ],
            [
              -78.54923,
              36.626629
            ],
            [
              -78.561684,
              36.642588
            ],
            [
              -78.576446,
              36.647162
            ],
            [
              -78.622883,
              36.672025
            ],
            [
              -78.639486,
              36.694359
            ],
            [
              -78.648541,
              36.697909
            ],
            [
              -78.665822,
              36.706763
            ],
            [
              -78.67116,
              36.720817
            ],
            [
              -78.691328,
              36.741847
            ],
            [
              -78.666145,
              36.764363
            ],
            [
              -78.681469,
              36.787431
            ],
            [
              -78.682652,
              36.797222
            ],
            [
              -78.666995,
              36.811822
            ],
            [
              -78.686878,
              36.824331
            ],
            [
              -78.684726,
              36.832131
            ],
            [
              -78.666125,
              36.840482
            ],
            [
              -78.671463,
              36.857951
            ],
            [
              -78.687142,
              36.863825
            ],
            [
              -78.69487,
              36.878251
            ],
            [
              -78.723376,
              36.90567
            ],
            [
              -78.749727,
              36.920065
            ],
            [
              -78.748461,
              36.929295
            ],
            [
              -78.727686,
              36.937454
            ],
            [
              -78.7337,
              36.949163
            ],
            [
              -78.740441,
              37.001564
            ],
            [
              -78.749727,
              37.008483
            ],
            [
              -78.757614,
              36.997722
            ],
            [
              -78.748358,
              36.987057
            ],
            [
              -78.774715,
              36.980976
            ],
            [
              -78.775898,
              36.955481
            ],
            [
              -78.794862,
              36.955988
            ],
            [
              -78.805039,
              36.976566
            ],
            [
              -78.817455,
              36.98402
            ],
            [
              -78.870371,
              36.986068
            ],
            [
              -78.892702,
              36.976694
            ],
            [
              -78.900195,
              36.97985
            ],
            [
              -78.891742,
              37.001092
            ],
            [
              -78.904587,
              37.022288
            ],
            [
              -78.971675,
              37.05006
            ],
            [
              -79.001608,
              37.030975
            ],
            [
              -79.015081,
              37.038976
            ],
            [
              -79.058728,
              37.046083
            ],
            [
              -79.092264,
              37.061498
            ],
            [
              -79.158041,
              36.791057
            ],
            [
              -79.209073,
              36.581182
            ],
            [
              -79.2184541869829,
              36.5414925778669
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "013",
      "COUNTYNS": "01480097",
      "AFFGEOID": "0500000US51013",
      "GEOID": "51013",
      "NAME": "Arlington",
      "LSAD": "06",
      "ALAND": 67362395,
      "AWATER": 244142,
      "County_state": "Arlington,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.16811,
              38.896521
            ],
            [
              -77.172276,
              38.893245
            ],
            [
              -77.149701,
              38.87567
            ],
            [
              -77.137631,
              38.866199
            ],
            [
              -77.121151,
              38.853325
            ],
            [
              -77.110799,
              38.843446
            ],
            [
              -77.110671,
              38.84342
            ],
            [
              -77.106716,
              38.841934
            ],
            [
              -77.100722,
              38.836343
            ],
            [
              -77.087805,
              38.827357
            ],
            [
              -77.085696,
              38.843304
            ],
            [
              -77.064722,
              38.844677
            ],
            [
              -77.0524,
              38.840445
            ],
            [
              -77.044487611898,
              38.839598699716696
            ],
            [
              -77.044199,
              38.840212
            ],
            [
              -77.041699,
              38.840212
            ],
            [
              -77.032798,
              38.841712
            ],
            [
              -77.031698,
              38.850512
            ],
            [
              -77.0318917188571,
              38.850863706384395
            ],
            [
              -77.039299,
              38.864312
            ],
            [
              -77.038899,
              38.865812
            ],
            [
              -77.039099,
              38.868112
            ],
            [
              -77.040599,
              38.871212
            ],
            [
              -77.043299,
              38.874012
            ],
            [
              -77.0434540926829,
              38.8741006243902
            ],
            [
              -77.045399,
              38.875212
            ],
            [
              -77.046599,
              38.874912
            ],
            [
              -77.045599,
              38.873012
            ],
            [
              -77.046299,
              38.871312
            ],
            [
              -77.049099,
              38.870712
            ],
            [
              -77.051299,
              38.873212
            ],
            [
              -77.051099,
              38.875212
            ],
            [
              -77.054099,
              38.879112
            ],
            [
              -77.055199,
              38.880012
            ],
            [
              -77.058254,
              38.880069
            ],
            [
              -77.063499,
              38.888611
            ],
            [
              -77.067299,
              38.899211
            ],
            [
              -77.068199,
              38.899811
            ],
            [
              -77.070099,
              38.900711
            ],
            [
              -77.0783563148089,
              38.901529839581094
            ],
            [
              -77.0822,
              38.901911
            ],
            [
              -77.0902,
              38.904211
            ],
            [
              -77.0937,
              38.905911
            ],
            [
              -77.1012,
              38.911111
            ],
            [
              -77.1034,
              38.912911
            ],
            [
              -77.1063,
              38.919111
            ],
            [
              -77.1134,
              38.925211
            ],
            [
              -77.1166,
              38.928911
            ],
            [
              -77.1179,
              38.932411
            ],
            [
              -77.119759,
              38.934343
            ],
            [
              -77.124875,
              38.930408
            ],
            [
              -77.139427,
              38.918982
            ],
            [
              -77.145809,
              38.914006
            ],
            [
              -77.155774,
              38.906206
            ],
            [
              -77.16811,
              38.896521
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "181",
      "COUNTYNS": "01480174",
      "AFFGEOID": "0500000US51181",
      "GEOID": "51181",
      "NAME": "Surry",
      "LSAD": "06",
      "ALAND": 722475755,
      "AWATER": 81216907,
      "County_state": "Surry,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.155415,
              37.112034
            ],
            [
              -77.127281,
              37.097097
            ],
            [
              -77.110934,
              37.100349
            ],
            [
              -77.102296,
              37.084333
            ],
            [
              -77.079557,
              37.084477
            ],
            [
              -77.076038,
              37.066717
            ],
            [
              -77.03471,
              37.069668
            ],
            [
              -76.992599,
              37.04989
            ],
            [
              -76.965139,
              37.054308
            ],
            [
              -76.968529,
              37.037233
            ],
            [
              -76.96605,
              37.000392
            ],
            [
              -76.960729,
              36.976323
            ],
            [
              -76.953546,
              36.94445
            ],
            [
              -76.870705,
              36.985666
            ],
            [
              -76.849684,
              36.996211
            ],
            [
              -76.729753,
              37.048123
            ],
            [
              -76.698255,
              37.05914
            ],
            [
              -76.684478,
              37.088164
            ],
            [
              -76.680286,
              37.126291
            ],
            [
              -76.671588,
              37.14206
            ],
            [
              -76.67158138949921,
              37.1429943774773
            ],
            [
              -76.67147,
              37.158739
            ],
            [
              -76.66867,
              37.166771
            ],
            [
              -76.66427,
              37.171027
            ],
            [
              -76.663774,
              37.173875
            ],
            [
              -76.669886,
              37.183571
            ],
            [
              -76.685614,
              37.198851
            ],
            [
              -76.691918,
              37.195731
            ],
            [
              -76.692926,
              37.186147
            ],
            [
              -76.696735,
              37.174403
            ],
            [
              -76.7142815132156,
              37.149474850190195
            ],
            [
              -76.715295,
              37.148035
            ],
            [
              -76.73032,
              37.145395
            ],
            [
              -76.73728,
              37.146164
            ],
            [
              -76.747632,
              37.150548
            ],
            [
              -76.756899,
              37.161582
            ],
            [
              -76.796905,
              37.189404
            ],
            [
              -76.802511,
              37.198308
            ],
            [
              -76.836201,
              37.20598
            ],
            [
              -76.865704,
              37.209024
            ],
            [
              -76.890927,
              37.199506
            ],
            [
              -76.908455,
              37.202667
            ],
            [
              -76.928302,
              37.216864
            ],
            [
              -76.948386,
              37.230869
            ],
            [
              -76.9431873557451,
              37.234244445187294
            ],
            [
              -76.969589,
              37.246221
            ],
            [
              -76.972439,
              37.248501
            ],
            [
              -77.003427,
              37.232669
            ],
            [
              -77.012301,
              37.232883
            ],
            [
              -77.018811,
              37.219593
            ],
            [
              -77.01178,
              37.21241
            ],
            [
              -77.024069,
              37.201028
            ],
            [
              -77.04434,
              37.202006
            ],
            [
              -77.046598,
              37.191396
            ],
            [
              -77.073145,
              37.184072
            ],
            [
              -77.087776,
              37.169071
            ],
            [
              -77.155415,
              37.112034
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "187",
      "COUNTYNS": "01674058",
      "AFFGEOID": "0500000US51187",
      "GEOID": "51187",
      "NAME": "Warren",
      "LSAD": "06",
      "ALAND": 555629182,
      "AWATER": 8590279,
      "County_state": "Warren,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.394704,
              38.82279
            ],
            [
              -78.33993,
              38.803158
            ],
            [
              -78.321229,
              38.789534
            ],
            [
              -78.290403,
              38.780373
            ],
            [
              -78.284805,
              38.759315
            ],
            [
              -78.252959,
              38.757042
            ],
            [
              -78.200764,
              38.776665
            ],
            [
              -78.184702,
              38.792836
            ],
            [
              -78.170941,
              38.823847
            ],
            [
              -78.152641,
              38.854384
            ],
            [
              -78.130574,
              38.864865
            ],
            [
              -78.112222,
              38.880374
            ],
            [
              -78.062016,
              38.891926
            ],
            [
              -78.075149,
              38.912607
            ],
            [
              -78.041576,
              38.938096
            ],
            [
              -78.004313,
              38.979438
            ],
            [
              -78.046112,
              39.011718
            ],
            [
              -78.058532,
              39.019181
            ],
            [
              -78.096992,
              39.012923
            ],
            [
              -78.151614,
              39.036629
            ],
            [
              -78.163871,
              39.017928
            ],
            [
              -78.31408,
              39.007994
            ],
            [
              -78.329893,
              38.993156
            ],
            [
              -78.321318,
              38.974224
            ],
            [
              -78.336642,
              38.976928
            ],
            [
              -78.335155,
              38.94934
            ],
            [
              -78.307248,
              38.95263
            ],
            [
              -78.300414,
              38.943592
            ],
            [
              -78.33835,
              38.890953
            ],
            [
              -78.33437,
              38.88144
            ],
            [
              -78.394704,
              38.82279
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "191",
      "COUNTYNS": "01498359",
      "AFFGEOID": "0500000US51191",
      "GEOID": "51191",
      "NAME": "Washington",
      "LSAD": "06",
      "ALAND": 1453385750,
      "AWATER": 12998215,
      "County_state": "Washington,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.308651,
              36.726674
            ],
            [
              -82.332078,
              36.708969
            ],
            [
              -82.307831,
              36.619682
            ],
            [
              -82.2941391924011,
              36.595705583357
            ],
            [
              -82.24319622554509,
              36.595733827872394
            ],
            [
              -82.179632,
              36.626798
            ],
            [
              -82.15784,
              36.626745
            ],
            [
              -82.114373,
              36.638418
            ],
            [
              -82.130838,
              36.668511
            ],
            [
              -82.144574,
              36.670852
            ],
            [
              -82.132643,
              36.677656
            ],
            [
              -82.11676,
              36.668018
            ],
            [
              -82.121333,
              36.658453
            ],
            [
              -82.10655,
              36.651517
            ],
            [
              -82.105812,
              36.62983
            ],
            [
              -82.1455730331036,
              36.594710944091005
            ],
            [
              -82.01946365130131,
              36.5944139393676
            ],
            [
              -81.934144,
              36.594213
            ],
            [
              -81.922644,
              36.616213
            ],
            [
              -81.826742,
              36.614215
            ],
            [
              -81.73694,
              36.612379
            ],
            [
              -81.6469,
              36.611918
            ],
            [
              -81.6109,
              36.616952
            ],
            [
              -81.605865,
              36.638079
            ],
            [
              -81.61903,
              36.633997
            ],
            [
              -81.699561,
              36.789316
            ],
            [
              -81.740494,
              36.831002
            ],
            [
              -81.837096,
              36.929039
            ],
            [
              -81.885255,
              36.889544
            ],
            [
              -81.94595,
              36.864607
            ],
            [
              -81.976049,
              36.869502
            ],
            [
              -81.981732,
              36.879587
            ],
            [
              -82.074139,
              36.856025
            ],
            [
              -82.107931,
              36.835369
            ],
            [
              -82.116897,
              36.817926
            ],
            [
              -82.103704,
              36.798521
            ],
            [
              -82.224516,
              36.762904
            ],
            [
              -82.241382,
              36.753549
            ],
            [
              -82.308651,
              36.726674
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "195",
      "COUNTYNS": "01496656",
      "AFFGEOID": "0500000US51195",
      "GEOID": "51195",
      "NAME": "Wise",
      "LSAD": "06",
      "ALAND": 1044884216,
      "AWATER": 5284456,
      "County_state": "Wise,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.878639,
              36.893981
            ],
            [
              -82.8782081655467,
              36.8927955121479
            ],
            [
              -82.851532,
              36.870187
            ],
            [
              -82.847362,
              36.847663
            ],
            [
              -82.835012,
              36.847592
            ],
            [
              -82.802026,
              36.822464
            ],
            [
              -82.771595,
              36.800031
            ],
            [
              -82.748147,
              36.832633
            ],
            [
              -82.698635,
              36.816825
            ],
            [
              -82.68207,
              36.831137
            ],
            [
              -82.656819,
              36.845319
            ],
            [
              -82.647003,
              36.867283
            ],
            [
              -82.63478,
              36.87831
            ],
            [
              -82.61774,
              36.880773
            ],
            [
              -82.532756,
              36.882564
            ],
            [
              -82.402601,
              36.88464
            ],
            [
              -82.406633,
              36.876356
            ],
            [
              -82.389739,
              36.885621
            ],
            [
              -82.376216,
              36.890342
            ],
            [
              -82.298596,
              36.905761
            ],
            [
              -82.32783,
              36.970982
            ],
            [
              -82.362386,
              36.955717
            ],
            [
              -82.365201,
              36.96265
            ],
            [
              -82.378821,
              36.980806
            ],
            [
              -82.408056,
              36.988251
            ],
            [
              -82.467345,
              37.022136
            ],
            [
              -82.478873,
              37.023147
            ],
            [
              -82.494358,
              37.059954
            ],
            [
              -82.497601,
              37.064584
            ],
            [
              -82.540229,
              37.120212
            ],
            [
              -82.546135,
              37.125118
            ],
            [
              -82.55386432928549,
              37.202819153295295
            ],
            [
              -82.558178,
              37.199606
            ],
            [
              -82.565329,
              37.196118
            ],
            [
              -82.5653752160821,
              37.1960922172527
            ],
            [
              -82.578988,
              37.188498
            ],
            [
              -82.586718,
              37.185623
            ],
            [
              -82.592451,
              37.182847
            ],
            [
              -82.593232,
              37.18206
            ],
            [
              -82.592766,
              37.180391
            ],
            [
              -82.597447,
              37.177088
            ],
            [
              -82.60829,
              37.173047
            ],
            [
              -82.617423,
              37.168129
            ],
            [
              -82.624878,
              37.162932
            ],
            [
              -82.633493,
              37.154264
            ],
            [
              -82.651646,
              37.151908
            ],
            [
              -82.653268,
              37.151314
            ],
            [
              -82.65476,
              37.150601
            ],
            [
              -82.657468,
              37.145024
            ],
            [
              -82.662449,
              37.143865
            ],
            [
              -82.667717,
              37.141949
            ],
            [
              -82.67153,
              37.138444
            ],
            [
              -82.676765,
              37.134965
            ],
            [
              -82.679428,
              37.135575
            ],
            [
              -82.684601,
              37.135835
            ],
            [
              -82.695667,
              37.131514
            ],
            [
              -82.70787,
              37.125488
            ],
            [
              -82.716334,
              37.122093
            ],
            [
              -82.718334,
              37.122267
            ],
            [
              -82.722097,
              37.120168
            ],
            [
              -82.726201,
              37.115882
            ],
            [
              -82.726449,
              37.114985
            ],
            [
              -82.726294,
              37.111852
            ],
            [
              -82.721941,
              37.105689
            ],
            [
              -82.721617,
              37.101276
            ],
            [
              -82.722179,
              37.094309
            ],
            [
              -82.724358,
              37.09299
            ],
            [
              -82.724954,
              37.091905
            ],
            [
              -82.72304,
              37.084992
            ],
            [
              -82.720597,
              37.081833
            ],
            [
              -82.718183,
              37.080679
            ],
            [
              -82.717204,
              37.079544
            ],
            [
              -82.71674,
              37.07722
            ],
            [
              -82.718353,
              37.075706
            ],
            [
              -82.721676,
              37.07519
            ],
            [
              -82.723747,
              37.075525
            ],
            [
              -82.727022,
              37.073019
            ],
            [
              -82.726312,
              37.06687
            ],
            [
              -82.723904,
              37.062542
            ],
            [
              -82.722254,
              37.057948
            ],
            [
              -82.723128,
              37.052436
            ],
            [
              -82.723279,
              37.047616
            ],
            [
              -82.722472,
              37.045101
            ],
            [
              -82.724714,
              37.042758
            ],
            [
              -82.72509517249749,
              37.0425972499372
            ],
            [
              -82.726279,
              37.042098
            ],
            [
              -82.731722,
              37.043447
            ],
            [
              -82.733603,
              37.044525
            ],
            [
              -82.742454,
              37.04298
            ],
            [
              -82.743684,
              37.041397
            ],
            [
              -82.745562,
              37.029839
            ],
            [
              -82.747981,
              37.025214
            ],
            [
              -82.750715,
              37.024107
            ],
            [
              -82.751852,
              37.025624
            ],
            [
              -82.754051,
              37.026587
            ],
            [
              -82.759175,
              37.027333
            ],
            [
              -82.766408,
              37.023106
            ],
            [
              -82.767083,
              37.020935
            ],
            [
              -82.771795,
              37.015716
            ],
            [
              -82.777368,
              37.015279
            ],
            [
              -82.778386,
              37.012521
            ],
            [
              -82.782144,
              37.008242
            ],
            [
              -82.788897,
              37.00816
            ],
            [
              -82.789092,
              37.007995
            ],
            [
              -82.790462,
              37.007263
            ],
            [
              -82.79089,
              37.00676
            ],
            [
              -82.793441,
              37.005491
            ],
            [
              -82.794824,
              37.005899
            ],
            [
              -82.795695,
              37.006702
            ],
            [
              -82.796187,
              37.008502
            ],
            [
              -82.797487,
              37.009654
            ],
            [
              -82.800531,
              37.007944
            ],
            [
              -82.813579,
              37.00532
            ],
            [
              -82.815748,
              37.007196
            ],
            [
              -82.818006,
              37.006161
            ],
            [
              -82.819715,
              37.006212
            ],
            [
              -82.821798,
              37.00438
            ],
            [
              -82.822684,
              37.004128
            ],
            [
              -82.825224,
              37.006279
            ],
            [
              -82.828592,
              37.005707
            ],
            [
              -82.829961,
              37.003555
            ],
            [
              -82.830588,
              37.000945
            ],
            [
              -82.829125,
              36.997541
            ],
            [
              -82.830802,
              36.993445
            ],
            [
              -82.833843,
              36.991973
            ],
            [
              -82.836008,
              36.988837
            ],
            [
              -82.838549,
              36.987027
            ],
            [
              -82.840051,
              36.987113
            ],
            [
              -82.841252,
              36.986858
            ],
            [
              -82.845002,
              36.983812
            ],
            [
              -82.849429,
              36.983479
            ],
            [
              -82.851397,
              36.984497
            ],
            [
              -82.852614,
              36.984963
            ],
            [
              -82.853729,
              36.985178
            ],
            [
              -82.856417,
              36.982216
            ],
            [
              -82.856768,
              36.979095
            ],
            [
              -82.857936,
              36.978276
            ],
            [
              -82.860561,
              36.978265
            ],
            [
              -82.862926,
              36.979975
            ],
            [
              -82.864909,
              36.97901
            ],
            [
              -82.866019,
              36.978272
            ],
            [
              -82.866689,
              36.978052
            ],
            [
              -82.867535,
              36.977518
            ],
            [
              -82.868455,
              36.976481
            ],
            [
              -82.8691826690104,
              36.9741830452543
            ],
            [
              -82.869183,
              36.974182
            ],
            [
              -82.8694630847178,
              36.9720796959173
            ],
            [
              -82.870274,
              36.965993
            ],
            [
              -82.87023,
              36.965498
            ],
            [
              -82.867358,
              36.963182
            ],
            [
              -82.865404,
              36.958084
            ],
            [
              -82.864211,
              36.957983
            ],
            [
              -82.862866,
              36.957765
            ],
            [
              -82.860534,
              36.956015
            ],
            [
              -82.858443,
              36.954036
            ],
            [
              -82.855705,
              36.953808
            ],
            [
              -82.856099,
              36.952471
            ],
            [
              -82.859969,
              36.949074
            ],
            [
              -82.860633,
              36.94584
            ],
            [
              -82.861282,
              36.944848
            ],
            [
              -82.861684,
              36.939316
            ],
            [
              -82.860537,
              36.937439
            ],
            [
              -82.858784,
              36.933065
            ],
            [
              -82.858461,
              36.932717
            ],
            [
              -82.857965,
              36.929529
            ],
            [
              -82.858635,
              36.927785
            ],
            [
              -82.860032,
              36.925241
            ],
            [
              -82.861943,
              36.924236
            ],
            [
              -82.863468,
              36.922308
            ],
            [
              -82.865192,
              36.920923
            ],
            [
              -82.867116,
              36.917965
            ],
            [
              -82.872136,
              36.913456
            ],
            [
              -82.873777,
              36.912299
            ],
            [
              -82.876215,
              36.910218
            ],
            [
              -82.877473,
              36.90796
            ],
            [
              -82.872561,
              36.903376
            ],
            [
              -82.870068,
              36.901735
            ],
            [
              -82.873213,
              36.897263
            ],
            [
              -82.876438,
              36.896238
            ],
            [
              -82.878639,
              36.893981
            ]
          ],
          [
            [
              -82.661768,
              36.918894
            ],
            [
              -82.663625,
              36.920959
            ],
            [
              -82.652813,
              36.931635
            ],
            [
              -82.634101,
              36.952248
            ],
            [
              -82.610914,
              36.954508
            ],
            [
              -82.597664,
              36.958061
            ],
            [
              -82.58919,
              36.950803
            ],
            [
              -82.591147,
              36.935313
            ],
            [
              -82.612597,
              36.93405
            ],
            [
              -82.621962,
              36.927068
            ],
            [
              -82.614934,
              36.905037
            ],
            [
              -82.636643,
              36.90629
            ],
            [
              -82.643992,
              36.900116
            ],
            [
              -82.645874,
              36.915346
            ],
            [
              -82.661768,
              36.918894
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "840",
      "COUNTYNS": "01789077",
      "AFFGEOID": "0500000US51840",
      "GEOID": "51840",
      "NAME": "Winchester",
      "LSAD": "25",
      "ALAND": 23809486,
      "AWATER": 54462,
      "County_state": "Winchester,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.189348,
              39.175189
            ],
            [
              -78.196629,
              39.142151
            ],
            [
              -78.183145,
              39.131065
            ],
            [
              -78.17316,
              39.141035
            ],
            [
              -78.148026,
              39.175899
            ],
            [
              -78.136725,
              39.184371
            ],
            [
              -78.155418,
              39.20097
            ],
            [
              -78.187794,
              39.20487
            ],
            [
              -78.189348,
              39.175189
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "036",
      "COUNTYNS": "01480109",
      "AFFGEOID": "0500000US51036",
      "GEOID": "51036",
      "NAME": "Charles City",
      "LSAD": "06",
      "ALAND": 473494108,
      "AWATER": 55492133,
      "County_state": "Charles City,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.264595,
              37.344052
            ],
            [
              -77.270675,
              37.320756
            ],
            [
              -77.249719,
              37.308668
            ],
            [
              -77.224013,
              37.319403
            ],
            [
              -77.187549,
              37.31146
            ],
            [
              -77.124694,
              37.302923
            ],
            [
              -77.097625,
              37.31197
            ],
            [
              -77.086164,
              37.310904
            ],
            [
              -77.079555,
              37.274275
            ],
            [
              -77.069269,
              37.269742
            ],
            [
              -77.032747,
              37.294465
            ],
            [
              -77.010096,
              37.305251
            ],
            [
              -76.996003,
              37.300979
            ],
            [
              -76.991363,
              37.278005
            ],
            [
              -76.972439,
              37.248501
            ],
            [
              -76.969589,
              37.246221
            ],
            [
              -76.9431873557451,
              37.234244445187294
            ],
            [
              -76.936145,
              37.238817
            ],
            [
              -76.907624,
              37.242601
            ],
            [
              -76.8753156074675,
              37.260982527633395
            ],
            [
              -76.881564,
              37.285509
            ],
            [
              -76.871685,
              37.295597
            ],
            [
              -76.880005,
              37.309874
            ],
            [
              -76.874437,
              37.365427
            ],
            [
              -76.88477,
              37.368566
            ],
            [
              -76.909647,
              37.348643
            ],
            [
              -76.904471,
              37.377665
            ],
            [
              -76.917932,
              37.384261
            ],
            [
              -76.904116,
              37.403821
            ],
            [
              -76.917993,
              37.395327
            ],
            [
              -76.92761,
              37.399566
            ],
            [
              -76.942518,
              37.383238
            ],
            [
              -76.93802,
              37.39953
            ],
            [
              -76.943083,
              37.410389
            ],
            [
              -76.95192,
              37.403967
            ],
            [
              -76.968821,
              37.406511
            ],
            [
              -76.971672,
              37.418353
            ],
            [
              -76.991476,
              37.414917
            ],
            [
              -77.021316,
              37.41601
            ],
            [
              -77.026677,
              37.429006
            ],
            [
              -77.050797,
              37.432161
            ],
            [
              -77.076287,
              37.441287
            ],
            [
              -77.095814,
              37.440292
            ],
            [
              -77.119073,
              37.455822
            ],
            [
              -77.126984,
              37.471622
            ],
            [
              -77.161638,
              37.476516
            ],
            [
              -77.177324,
              37.4906
            ],
            [
              -77.197726,
              37.45382
            ],
            [
              -77.222839,
              37.407099
            ],
            [
              -77.214593,
              37.393522
            ],
            [
              -77.224269,
              37.380221
            ],
            [
              -77.248749,
              37.394735
            ],
            [
              -77.249665,
              37.382
            ],
            [
              -77.244167,
              37.369274
            ],
            [
              -77.264595,
              37.344052
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "139",
      "COUNTYNS": "01500440",
      "AFFGEOID": "0500000US51139",
      "GEOID": "51139",
      "NAME": "Page",
      "LSAD": "06",
      "ALAND": 802893902,
      "AWATER": 8425697,
      "County_state": "Page,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.691145,
              38.511211
            ],
            [
              -78.682131,
              38.506097
            ],
            [
              -78.671178,
              38.501429
            ],
            [
              -78.634462,
              38.497605
            ],
            [
              -78.62292,
              38.466379
            ],
            [
              -78.559098,
              38.447336
            ],
            [
              -78.548302,
              38.426401
            ],
            [
              -78.48574,
              38.421579
            ],
            [
              -78.465073,
              38.447
            ],
            [
              -78.452801,
              38.475527
            ],
            [
              -78.436268,
              38.522146
            ],
            [
              -78.442767,
              38.527775
            ],
            [
              -78.419754,
              38.534396
            ],
            [
              -78.41376,
              38.547201
            ],
            [
              -78.380929,
              38.561307
            ],
            [
              -78.385662,
              38.582656
            ],
            [
              -78.360018,
              38.613783
            ],
            [
              -78.338176,
              38.627312
            ],
            [
              -78.315581,
              38.651564
            ],
            [
              -78.314386,
              38.682509
            ],
            [
              -78.331705,
              38.702642
            ],
            [
              -78.318775,
              38.737866
            ],
            [
              -78.303477,
              38.738913
            ],
            [
              -78.284805,
              38.759315
            ],
            [
              -78.290403,
              38.780373
            ],
            [
              -78.321229,
              38.789534
            ],
            [
              -78.33993,
              38.803158
            ],
            [
              -78.394704,
              38.82279
            ],
            [
              -78.425019,
              38.798537
            ],
            [
              -78.470091,
              38.752583
            ],
            [
              -78.493393,
              38.741592
            ],
            [
              -78.50926,
              38.725626
            ],
            [
              -78.54894,
              38.738542
            ],
            [
              -78.551728,
              38.730968
            ],
            [
              -78.589891,
              38.686406
            ],
            [
              -78.623002,
              38.623601
            ],
            [
              -78.641769,
              38.604719
            ],
            [
              -78.647046,
              38.602029
            ],
            [
              -78.663181,
              38.571927
            ],
            [
              -78.675729,
              38.533996
            ],
            [
              -78.691145,
              38.511211
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "021",
      "COUNTYNS": "01494613",
      "AFFGEOID": "0500000US51021",
      "GEOID": "51021",
      "NAME": "Bland",
      "LSAD": "06",
      "ALAND": 926272936,
      "AWATER": 2585515,
      "County_state": "Bland,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.462279,
              37.035402
            ],
            [
              -81.43673,
              37.010135
            ],
            [
              -81.379402,
              36.952442
            ],
            [
              -81.367228,
              36.967217
            ],
            [
              -81.355827,
              36.964517
            ],
            [
              -81.338326,
              36.975417
            ],
            [
              -81.315425,
              36.998517
            ],
            [
              -81.286458,
              37.018993
            ],
            [
              -81.224624,
              37.047835
            ],
            [
              -81.191819,
              37.037035
            ],
            [
              -81.178854,
              37.025099
            ],
            [
              -81.166057,
              37.024653
            ],
            [
              -81.131051,
              37.038107
            ],
            [
              -81.114319,
              37.022612
            ],
            [
              -81.106884,
              37.025146
            ],
            [
              -81.032514,
              37.046858
            ],
            [
              -80.972963,
              37.054817
            ],
            [
              -80.912674,
              37.073349
            ],
            [
              -80.930627,
              37.115087
            ],
            [
              -80.90341,
              37.12015
            ],
            [
              -80.854627,
              37.147482
            ],
            [
              -80.881129,
              37.171058
            ],
            [
              -80.861545,
              37.178499
            ],
            [
              -80.871306,
              37.193766
            ],
            [
              -80.894041,
              37.179375
            ],
            [
              -80.97939,
              37.250119
            ],
            [
              -81.014079,
              37.27563
            ],
            [
              -80.980146,
              37.292743
            ],
            [
              -80.981322,
              37.293465
            ],
            [
              -80.982173,
              37.296023
            ],
            [
              -80.979106,
              37.300581
            ],
            [
              -80.979589,
              37.302279
            ],
            [
              -80.996013,
              37.299545
            ],
            [
              -81.000576,
              37.297868
            ],
            [
              -81.008457,
              37.296073
            ],
            [
              -81.021937,
              37.294143
            ],
            [
              -81.037191,
              37.290251
            ],
            [
              -81.084012,
              37.284401
            ],
            [
              -81.09482,
              37.28264
            ],
            [
              -81.112596,
              37.278497
            ],
            [
              -81.142404,
              37.269165
            ],
            [
              -81.158964,
              37.265382
            ],
            [
              -81.167029,
              37.262881
            ],
            [
              -81.178151,
              37.257979
            ],
            [
              -81.204774,
              37.243013
            ],
            [
              -81.225104,
              37.234874
            ],
            [
              -81.243553,
              37.224062
            ],
            [
              -81.284703,
              37.215734
            ],
            [
              -81.263864,
              37.173018
            ],
            [
              -81.31376,
              37.159447
            ],
            [
              -81.333009,
              37.149137
            ],
            [
              -81.32814,
              37.138949
            ],
            [
              -81.269362,
              37.12779
            ],
            [
              -81.261318,
              37.114727
            ],
            [
              -81.270078,
              37.093012
            ],
            [
              -81.326273,
              37.067374
            ],
            [
              -81.357998,
              37.058295
            ],
            [
              -81.399281,
              37.057678
            ],
            [
              -81.436602,
              37.041137
            ],
            [
              -81.462279,
              37.035402
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "183",
      "COUNTYNS": "01690257",
      "AFFGEOID": "0500000US51183",
      "GEOID": "51183",
      "NAME": "Sussex",
      "LSAD": "06",
      "ALAND": 1269664389,
      "AWATER": 6755317,
      "County_state": "Sussex,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.619029,
              36.877844
            ],
            [
              -77.594735,
              36.87108
            ],
            [
              -77.576445,
              36.851565
            ],
            [
              -77.557538,
              36.845572
            ],
            [
              -77.543073,
              36.8531
            ],
            [
              -77.51036,
              36.845082
            ],
            [
              -77.491243,
              36.848474
            ],
            [
              -77.475194,
              36.861127
            ],
            [
              -77.458678,
              36.862963
            ],
            [
              -77.489999,
              36.721009
            ],
            [
              -77.430127,
              36.707658
            ],
            [
              -77.344798,
              36.752586
            ],
            [
              -77.249703,
              36.795549
            ],
            [
              -77.119808,
              36.858759
            ],
            [
              -77.069918,
              36.884566
            ],
            [
              -76.953546,
              36.94445
            ],
            [
              -76.960729,
              36.976323
            ],
            [
              -76.96605,
              37.000392
            ],
            [
              -76.968529,
              37.037233
            ],
            [
              -76.965139,
              37.054308
            ],
            [
              -76.992599,
              37.04989
            ],
            [
              -77.03471,
              37.069668
            ],
            [
              -77.076038,
              37.066717
            ],
            [
              -77.079557,
              37.084477
            ],
            [
              -77.102296,
              37.084333
            ],
            [
              -77.110934,
              37.100349
            ],
            [
              -77.127281,
              37.097097
            ],
            [
              -77.155415,
              37.112034
            ],
            [
              -77.187038,
              37.098933
            ],
            [
              -77.196689,
              37.092958
            ],
            [
              -77.398498,
              36.992985
            ],
            [
              -77.619029,
              36.877844
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "111",
      "COUNTYNS": "01492239",
      "AFFGEOID": "0500000US51111",
      "GEOID": "51111",
      "NAME": "Lunenburg",
      "LSAD": "06",
      "ALAND": 1118051114,
      "AWATER": 1795027,
      "County_state": "Lunenburg,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.493028,
              36.89122
            ],
            [
              -78.483194,
              36.882711
            ],
            [
              -78.445388,
              36.869115
            ],
            [
              -78.430351,
              36.871265
            ],
            [
              -78.421386,
              36.85188
            ],
            [
              -78.397576,
              36.863934
            ],
            [
              -78.359711,
              36.859654
            ],
            [
              -78.345383,
              36.842198
            ],
            [
              -78.319135,
              36.849181
            ],
            [
              -78.277528,
              36.819861
            ],
            [
              -78.243391,
              36.814502
            ],
            [
              -78.241509,
              36.807958
            ],
            [
              -78.16904,
              36.802019
            ],
            [
              -78.119364,
              36.78707
            ],
            [
              -78.112318,
              36.798215
            ],
            [
              -78.086543,
              36.793532
            ],
            [
              -78.051926,
              36.776649
            ],
            [
              -78.046607,
              36.784268
            ],
            [
              -78.027411,
              36.777858
            ],
            [
              -78.021722,
              36.838773
            ],
            [
              -78.003639,
              37.022756
            ],
            [
              -78.031788,
              37.014459
            ],
            [
              -78.079912,
              37.010573
            ],
            [
              -78.082652,
              37.016406
            ],
            [
              -78.17098,
              37.056832
            ],
            [
              -78.170489,
              37.075662
            ],
            [
              -78.19313,
              37.075298
            ],
            [
              -78.229944,
              37.096868
            ],
            [
              -78.239354,
              37.120089
            ],
            [
              -78.275167,
              37.103526
            ],
            [
              -78.312102,
              37.099644
            ],
            [
              -78.35901,
              37.106085
            ],
            [
              -78.382321,
              37.089494
            ],
            [
              -78.443644,
              37.079371
            ],
            [
              -78.493028,
              36.89122
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "027",
      "COUNTYNS": "01497431",
      "AFFGEOID": "0500000US51027",
      "GEOID": "51027",
      "NAME": "Buchanan",
      "LSAD": "06",
      "ALAND": 1302387725,
      "AWATER": 2803981,
      "County_state": "Buchanan,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.309415,
              37.300066
            ],
            [
              -82.3143123901533,
              37.296224662827
            ],
            [
              -82.293007,
              37.305996
            ],
            [
              -82.250647,
              37.273201
            ],
            [
              -82.236504,
              37.212343
            ],
            [
              -82.201173,
              37.155885
            ],
            [
              -82.172283,
              37.129222
            ],
            [
              -82.171955,
              37.115443
            ],
            [
              -82.157889,
              37.110572
            ],
            [
              -82.157589,
              37.088641
            ],
            [
              -82.140915,
              37.069858
            ],
            [
              -82.137048,
              37.050255
            ],
            [
              -82.149375,
              37.041641
            ],
            [
              -82.113447,
              37.039655
            ],
            [
              -82.096737,
              37.052033
            ],
            [
              -82.075701,
              37.038217
            ],
            [
              -82.050013,
              37.046167
            ],
            [
              -82.035864,
              37.051458
            ],
            [
              -82.031701,
              37.065198
            ],
            [
              -82.017675,
              37.068784
            ],
            [
              -82.020413,
              37.091048
            ],
            [
              -82.007716,
              37.119871
            ],
            [
              -81.990295,
              37.128695
            ],
            [
              -81.978483,
              37.12071
            ],
            [
              -81.963992,
              37.128086
            ],
            [
              -81.937582,
              37.126366
            ],
            [
              -81.914916,
              37.130706
            ],
            [
              -81.900892,
              37.142553
            ],
            [
              -81.867123,
              37.15776
            ],
            [
              -81.857315,
              37.174425
            ],
            [
              -81.797431,
              37.186007
            ],
            [
              -81.761894,
              37.201927
            ],
            [
              -81.7392766866866,
              37.2388369743647
            ],
            [
              -81.744003,
              37.242528
            ],
            [
              -81.744291,
              37.244178
            ],
            [
              -81.74342,
              37.245858
            ],
            [
              -81.743505,
              37.247601
            ],
            [
              -81.740974,
              37.254052
            ],
            [
              -81.741662,
              37.254784
            ],
            [
              -81.743008,
              37.255127
            ],
            [
              -81.745445,
              37.258125
            ],
            [
              -81.745505,
              37.26133
            ],
            [
              -81.746109,
              37.263597
            ],
            [
              -81.747656,
              37.264329
            ],
            [
              -81.75129,
              37.265131
            ],
            [
              -81.752123,
              37.265568
            ],
            [
              -81.752912,
              37.266614
            ],
            [
              -81.755012,
              37.26772
            ],
            [
              -81.757531,
              37.27001
            ],
            [
              -81.757714,
              37.271124
            ],
            [
              -81.75773,
              37.271934
            ],
            [
              -81.757631,
              37.274003
            ],
            [
              -81.76022,
              37.275254
            ],
            [
              -81.761752,
              37.275713
            ],
            [
              -81.762776,
              37.275391
            ],
            [
              -81.763836,
              37.275218
            ],
            [
              -81.765195,
              37.275099
            ],
            [
              -81.767837,
              37.274216
            ],
            [
              -81.774684,
              37.274807
            ],
            [
              -81.774747,
              37.274847
            ],
            [
              -81.777319,
              37.275873
            ],
            [
              -81.77935,
              37.277394
            ],
            [
              -81.779362,
              37.279982
            ],
            [
              -81.783122,
              37.28258
            ],
            [
              -81.789294,
              37.284416
            ],
            [
              -81.7900754311478,
              37.283897315854
            ],
            [
              -81.793425,
              37.281674
            ],
            [
              -81.793639,
              37.282188
            ],
            [
              -81.793115,
              37.283538
            ],
            [
              -81.793595,
              37.284838
            ],
            [
              -81.803275,
              37.285916
            ],
            [
              -81.805382,
              37.285622
            ],
            [
              -81.807232,
              37.283175
            ],
            [
              -81.809184,
              37.283003
            ],
            [
              -81.810559,
              37.28298
            ],
            [
              -81.813222,
              37.281091
            ],
            [
              -81.816702,
              37.279865
            ],
            [
              -81.819625,
              37.279411
            ],
            [
              -81.825065,
              37.279536
            ],
            [
              -81.83447,
              37.281763
            ],
            [
              -81.834387,
              37.283086
            ],
            [
              -81.833406,
              37.284535
            ],
            [
              -81.834432,
              37.285416
            ],
            [
              -81.838762,
              37.286343
            ],
            [
              -81.84231,
              37.285556
            ],
            [
              -81.843167,
              37.285586
            ],
            [
              -81.846807,
              37.284649
            ],
            [
              -81.849949,
              37.285227
            ],
            [
              -81.853551,
              37.287701
            ],
            [
              -81.854059,
              37.291352
            ],
            [
              -81.853488,
              37.294763
            ],
            [
              -81.854465,
              37.299937
            ],
            [
              -81.853978,
              37.300418
            ],
            [
              -81.853645,
              37.300779
            ],
            [
              -81.85446,
              37.30657
            ],
            [
              -81.856032,
              37.306742
            ],
            [
              -81.859624,
              37.304765
            ],
            [
              -81.862031,
              37.305648
            ],
            [
              -81.86476,
              37.308404
            ],
            [
              -81.865219,
              37.308839
            ],
            [
              -81.865683,
              37.309484
            ],
            [
              -81.865429,
              37.31012
            ],
            [
              -81.863712,
              37.31223
            ],
            [
              -81.859928,
              37.313965
            ],
            [
              -81.860267,
              37.315715
            ],
            [
              -81.867425,
              37.320838
            ],
            [
              -81.87018,
              37.320667
            ],
            [
              -81.872662,
              37.323314
            ],
            [
              -81.873213,
              37.325065
            ],
            [
              -81.878343,
              37.328837
            ],
            [
              -81.878713,
              37.331753
            ],
            [
              -81.879601,
              37.332074
            ],
            [
              -81.880886,
              37.331146
            ],
            [
              -81.885075,
              37.330665
            ],
            [
              -81.886952,
              37.330725
            ],
            [
              -81.887722,
              37.331156
            ],
            [
              -81.892876,
              37.330134
            ],
            [
              -81.893773,
              37.330105
            ],
            [
              -81.894768,
              37.331381
            ],
            [
              -81.894797,
              37.332012
            ],
            [
              -81.895489,
              37.332022
            ],
            [
              -81.896001,
              37.331967
            ],
            [
              -81.899459,
              37.340277
            ],
            [
              -81.899495,
              37.341102
            ],
            [
              -81.902992,
              37.34234
            ],
            [
              -81.903795,
              37.34305
            ],
            [
              -81.905945,
              37.342775
            ],
            [
              -81.906368,
              37.34276
            ],
            [
              -81.907322,
              37.343119
            ],
            [
              -81.907895,
              37.343783
            ],
            [
              -81.910875,
              37.348729
            ],
            [
              -81.911487,
              37.348839
            ],
            [
              -81.911951,
              37.349339
            ],
            [
              -81.916678,
              37.349346
            ],
            [
              -81.920279,
              37.353402
            ],
            [
              -81.920711,
              37.355416
            ],
            [
              -81.921571,
              37.356423
            ],
            [
              -81.925643,
              37.357316
            ],
            [
              -81.926589,
              37.358942
            ],
            [
              -81.928497,
              37.360645
            ],
            [
              -81.926697,
              37.364618
            ],
            [
              -81.929915,
              37.366589
            ],
            [
              -81.930194,
              37.366728
            ],
            [
              -81.933895,
              37.372747
            ],
            [
              -81.932763,
              37.374229
            ],
            [
              -81.93388,
              37.377796
            ],
            [
              -81.935872,
              37.378554
            ],
            [
              -81.936744,
              37.38073
            ],
            [
              -81.933601,
              37.389217
            ],
            [
              -81.928778,
              37.393845
            ],
            [
              -81.92828,
              37.398059
            ],
            [
              -81.930786,
              37.401656
            ],
            [
              -81.930042,
              37.405291
            ],
            [
              -81.927338,
              37.406844
            ],
            [
              -81.925764,
              37.406874
            ],
            [
              -81.924506,
              37.407613
            ],
            [
              -81.923481,
              37.411379
            ],
            [
              -81.932468,
              37.415217
            ],
            [
              -81.93695,
              37.41992
            ],
            [
              -81.940553,
              37.429058
            ],
            [
              -81.937838,
              37.432111
            ],
            [
              -81.935316,
              37.43639
            ],
            [
              -81.935621,
              37.438397
            ],
            [
              -81.938843,
              37.440463
            ],
            [
              -81.941175,
              37.440485
            ],
            [
              -81.942856,
              37.439929
            ],
            [
              -81.945765,
              37.440214
            ],
            [
              -81.949367,
              37.445687
            ],
            [
              -81.958672,
              37.448045
            ],
            [
              -81.965582,
              37.446918
            ],
            [
              -81.969342,
              37.450324
            ],
            [
              -81.968795,
              37.451496
            ],
            [
              -81.976176,
              37.457186
            ],
            [
              -81.980013,
              37.45721
            ],
            [
              -81.984891,
              37.454315
            ],
            [
              -81.987006,
              37.454878
            ],
            [
              -81.99227,
              37.460916
            ],
            [
              -81.995649,
              37.469833
            ],
            [
              -81.996578,
              37.476705
            ],
            [
              -81.992916,
              37.482969
            ],
            [
              -81.989849,
              37.484879
            ],
            [
              -81.985703,
              37.485681
            ],
            [
              -81.980327,
              37.485447
            ],
            [
              -81.979169,
              37.484604
            ],
            [
              -81.977593,
              37.484603
            ],
            [
              -81.97073,
              37.489904
            ],
            [
              -81.964986,
              37.493488
            ],
            [
              -81.957213,
              37.491504
            ],
            [
              -81.953264,
              37.491763
            ],
            [
              -81.952681,
              37.492274
            ],
            [
              -81.952735,
              37.494162
            ],
            [
              -81.954167,
              37.495302
            ],
            [
              -81.954364,
              37.496084
            ],
            [
              -81.954077,
              37.499822
            ],
            [
              -81.953147,
              37.501314
            ],
            [
              -81.951831,
              37.50205
            ],
            [
              -81.949188,
              37.502376
            ],
            [
              -81.945957,
              37.501901
            ],
            [
              -81.943912,
              37.502929
            ],
            [
              -81.944188,
              37.506976
            ],
            [
              -81.943045,
              37.508609
            ],
            [
              -81.941151,
              37.509483
            ],
            [
              -81.932279,
              37.511961
            ],
            [
              -81.92787,
              37.512118
            ],
            [
              -81.9277586640962,
              37.5122085217842
            ],
            [
              -81.926736,
              37.51304
            ],
            [
              -81.926391,
              37.514207
            ],
            [
              -81.933088,
              37.518968
            ],
            [
              -81.943693,
              37.521212
            ],
            [
              -81.947085,
              37.523913
            ],
            [
              -81.94766,
              37.52508
            ],
            [
              -81.947545,
              37.52753
            ],
            [
              -81.943981,
              37.5303
            ],
            [
              -81.94401,
              37.530964
            ],
            [
              -81.946022,
              37.531742
            ],
            [
              -81.953524,
              37.528056
            ],
            [
              -81.95663,
              37.52849
            ],
            [
              -81.957693,
              37.529841
            ],
            [
              -81.957436,
              37.533206
            ],
            [
              -81.956947,
              37.534259
            ],
            [
              -81.957379,
              37.535198
            ],
            [
              -81.959362,
              37.53522
            ],
            [
              -81.967583,
              37.532815
            ],
            [
              -81.969279,
              37.534325
            ],
            [
              -81.968297,
              37.537798
            ],
            [
              -82.050888,
              37.480527
            ],
            [
              -82.062809,
              37.470911
            ],
            [
              -82.124854,
              37.427272
            ],
            [
              -82.201745,
              37.375108
            ],
            [
              -82.202688,
              37.374041
            ],
            [
              -82.254352,
              37.337745
            ],
            [
              -82.291908,
              37.311642
            ],
            [
              -82.309415,
              37.300066
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "117",
      "COUNTYNS": "01500747",
      "AFFGEOID": "0500000US51117",
      "GEOID": "51117",
      "NAME": "Mecklenburg",
      "LSAD": "06",
      "ALAND": 1619956537,
      "AWATER": 139243978,
      "County_state": "Mecklenburg,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.737799,
              36.555875
            ],
            [
              -78.7341219239254,
              36.5419022759072
            ],
            [
              -78.670051,
              36.542035
            ],
            [
              -78.663317,
              36.542011
            ],
            [
              -78.605304,
              36.541092
            ],
            [
              -78.533013,
              36.541004
            ],
            [
              -78.529722,
              36.540981
            ],
            [
              -78.509965,
              36.541065
            ],
            [
              -78.471022,
              36.542307
            ],
            [
              -78.470792,
              36.542316
            ],
            [
              -78.45697,
              36.542474
            ],
            [
              -78.4569669669848,
              36.5424740409633
            ],
            [
              -78.441199,
              36.542687
            ],
            [
              -78.436333,
              36.542666
            ],
            [
              -78.3239883198677,
              36.5438082240453
            ],
            [
              -78.323912,
              36.543809
            ],
            [
              -78.246681,
              36.544341
            ],
            [
              -78.245462,
              36.544411
            ],
            [
              -78.133323,
              36.543847
            ],
            [
              -78.132911,
              36.543811
            ],
            [
              -78.0462112119251,
              36.5441680334942
            ],
            [
              -78.027411,
              36.777858
            ],
            [
              -78.046607,
              36.784268
            ],
            [
              -78.051926,
              36.776649
            ],
            [
              -78.086543,
              36.793532
            ],
            [
              -78.112318,
              36.798215
            ],
            [
              -78.119364,
              36.78707
            ],
            [
              -78.16904,
              36.802019
            ],
            [
              -78.241509,
              36.807958
            ],
            [
              -78.243391,
              36.814502
            ],
            [
              -78.277528,
              36.819861
            ],
            [
              -78.319135,
              36.849181
            ],
            [
              -78.345383,
              36.842198
            ],
            [
              -78.359711,
              36.859654
            ],
            [
              -78.397576,
              36.863934
            ],
            [
              -78.421386,
              36.85188
            ],
            [
              -78.430351,
              36.871265
            ],
            [
              -78.445388,
              36.869115
            ],
            [
              -78.483194,
              36.882711
            ],
            [
              -78.493028,
              36.89122
            ],
            [
              -78.648541,
              36.697909
            ],
            [
              -78.639486,
              36.694359
            ],
            [
              -78.622883,
              36.672025
            ],
            [
              -78.576446,
              36.647162
            ],
            [
              -78.561684,
              36.642588
            ],
            [
              -78.54923,
              36.626629
            ],
            [
              -78.56393,
              36.64014
            ],
            [
              -78.578771,
              36.638539
            ],
            [
              -78.594883,
              36.652895
            ],
            [
              -78.624724,
              36.667317
            ],
            [
              -78.640221,
              36.684668
            ],
            [
              -78.653551,
              36.69085
            ],
            [
              -78.677596,
              36.688326
            ],
            [
              -78.697302,
              36.66888
            ],
            [
              -78.711702,
              36.641078
            ],
            [
              -78.712316,
              36.609583
            ],
            [
              -78.720028,
              36.588877
            ],
            [
              -78.715694,
              36.580836
            ],
            [
              -78.737799,
              36.555875
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "007",
      "COUNTYNS": "01497770",
      "AFFGEOID": "0500000US51007",
      "GEOID": "51007",
      "NAME": "Amelia",
      "LSAD": "06",
      "ALAND": 920142764,
      "AWATER": 8424800,
      "County_state": "Amelia,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.235039,
              37.368102
            ],
            [
              -78.231234,
              37.29622
            ],
            [
              -77.826108,
              37.201986
            ],
            [
              -77.795926,
              37.192592
            ],
            [
              -77.747693,
              37.192729
            ],
            [
              -77.711099,
              37.218316
            ],
            [
              -77.691125,
              37.221317
            ],
            [
              -77.686903,
              37.235293
            ],
            [
              -77.660687,
              37.241815
            ],
            [
              -77.65061,
              37.265112
            ],
            [
              -77.6522,
              37.265822
            ],
            [
              -77.669158,
              37.29285
            ],
            [
              -77.693035,
              37.281556
            ],
            [
              -77.701485,
              37.29613
            ],
            [
              -77.716211,
              37.296878
            ],
            [
              -77.717442,
              37.284855
            ],
            [
              -77.734912,
              37.284575
            ],
            [
              -77.738024,
              37.270358
            ],
            [
              -77.761396,
              37.269686
            ],
            [
              -77.769434,
              37.284982
            ],
            [
              -77.80675,
              37.304526
            ],
            [
              -77.808857,
              37.31599
            ],
            [
              -77.797462,
              37.333712
            ],
            [
              -77.837346,
              37.33838
            ],
            [
              -77.851804,
              37.354867
            ],
            [
              -77.877556,
              37.366247
            ],
            [
              -77.867779,
              37.394498
            ],
            [
              -77.875093,
              37.417137
            ],
            [
              -77.855148,
              37.418363
            ],
            [
              -77.865117,
              37.437647
            ],
            [
              -77.865945,
              37.456312
            ],
            [
              -77.886352,
              37.449299
            ],
            [
              -77.897617,
              37.456778
            ],
            [
              -77.899761,
              37.475422
            ],
            [
              -77.924261,
              37.482055
            ],
            [
              -77.936683,
              37.475419
            ],
            [
              -77.955816,
              37.483274
            ],
            [
              -77.98619,
              37.486269
            ],
            [
              -78,
              37.497971
            ],
            [
              -78.014437,
              37.492081
            ],
            [
              -78.016851,
              37.471982
            ],
            [
              -78.050491,
              37.471672
            ],
            [
              -78.06658,
              37.452054
            ],
            [
              -78.091757,
              37.456102
            ],
            [
              -78.10115,
              37.448455
            ],
            [
              -78.12429,
              37.445398
            ],
            [
              -78.132048,
              37.454686
            ],
            [
              -78.139835,
              37.459679
            ],
            [
              -78.171415,
              37.442536
            ],
            [
              -78.185002,
              37.444552
            ],
            [
              -78.188456,
              37.42552
            ],
            [
              -78.235039,
              37.368102
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "073",
      "COUNTYNS": "01480126",
      "AFFGEOID": "0500000US51073",
      "GEOID": "51073",
      "NAME": "Gloucester",
      "LSAD": "06",
      "ALAND": 564117815,
      "AWATER": 181754413,
      "County_state": "Gloucester,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.704721,
              37.448035
            ],
            [
              -76.712305,
              37.431309
            ],
            [
              -76.672296,
              37.395808
            ],
            [
              -76.658651,
              37.380139
            ],
            [
              -76.64952,
              37.374282
            ],
            [
              -76.600713,
              37.314038
            ],
            [
              -76.569978,
              37.289104
            ],
            [
              -76.53641,
              37.271119
            ],
            [
              -76.516057,
              37.248752
            ],
            [
              -76.4988999709057,
              37.2410151037221
            ],
            [
              -76.493302,
              37.24947
            ],
            [
              -76.48284,
              37.254831
            ],
            [
              -76.475927,
              37.250543
            ],
            [
              -76.429141,
              37.25331
            ],
            [
              -76.421765,
              37.255198
            ],
            [
              -76.417173,
              37.26395
            ],
            [
              -76.392788,
              37.264973
            ],
            [
              -76.36229,
              37.270226
            ],
            [
              -76.349489,
              37.273963
            ],
            [
              -76.352556,
              37.278334
            ],
            [
              -76.369029,
              37.279311
            ],
            [
              -76.381075,
              37.28534
            ],
            [
              -76.385603,
              37.294108
            ],
            [
              -76.38777,
              37.30767
            ],
            [
              -76.406388,
              37.332924
            ],
            [
              -76.434965,
              37.354524
            ],
            [
              -76.445333,
              37.36646
            ],
            [
              -76.437525,
              37.37975
            ],
            [
              -76.422503,
              37.381355
            ],
            [
              -76.418176,
              37.385064
            ],
            [
              -76.418719,
              37.3978
            ],
            [
              -76.415167,
              37.402133
            ],
            [
              -76.40475603175179,
              37.4002130024531
            ],
            [
              -76.407336,
              37.412722
            ],
            [
              -76.447944,
              37.420064
            ],
            [
              -76.442333,
              37.452046
            ],
            [
              -76.451828,
              37.484598
            ],
            [
              -76.437561,
              37.51436
            ],
            [
              -76.481221,
              37.536217
            ],
            [
              -76.499163,
              37.536869
            ],
            [
              -76.507266,
              37.547505
            ],
            [
              -76.521998,
              37.548312
            ],
            [
              -76.55208,
              37.566374
            ],
            [
              -76.55593,
              37.555054
            ],
            [
              -76.57353,
              37.562946
            ],
            [
              -76.576416,
              37.575186
            ],
            [
              -76.595767,
              37.588079
            ],
            [
              -76.614983,
              37.583979
            ],
            [
              -76.636972,
              37.590842
            ],
            [
              -76.654076,
              37.603174
            ],
            [
              -76.669084,
              37.52963
            ],
            [
              -76.648009,
              37.495891
            ],
            [
              -76.646794,
              37.481154
            ],
            [
              -76.667425,
              37.455525
            ],
            [
              -76.673994,
              37.461323
            ],
            [
              -76.687006,
              37.447882
            ],
            [
              -76.704721,
              37.448035
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "171",
      "COUNTYNS": "01673918",
      "AFFGEOID": "0500000US51171",
      "GEOID": "51171",
      "NAME": "Shenandoah",
      "LSAD": "06",
      "ALAND": 1315692012,
      "AWATER": 9944281,
      "County_state": "Shenandoah,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.865905,
              38.767034
            ],
            [
              -78.8692752588773,
              38.7629918888636
            ],
            [
              -78.794197,
              38.710119
            ],
            [
              -78.641769,
              38.604719
            ],
            [
              -78.623002,
              38.623601
            ],
            [
              -78.589891,
              38.686406
            ],
            [
              -78.551728,
              38.730968
            ],
            [
              -78.54894,
              38.738542
            ],
            [
              -78.50926,
              38.725626
            ],
            [
              -78.493393,
              38.741592
            ],
            [
              -78.470091,
              38.752583
            ],
            [
              -78.425019,
              38.798537
            ],
            [
              -78.394704,
              38.82279
            ],
            [
              -78.33437,
              38.88144
            ],
            [
              -78.33835,
              38.890953
            ],
            [
              -78.300414,
              38.943592
            ],
            [
              -78.307248,
              38.95263
            ],
            [
              -78.335155,
              38.94934
            ],
            [
              -78.336642,
              38.976928
            ],
            [
              -78.321318,
              38.974224
            ],
            [
              -78.329893,
              38.993156
            ],
            [
              -78.31408,
              39.007994
            ],
            [
              -78.313966,
              39.02484
            ],
            [
              -78.330052,
              39.049764
            ],
            [
              -78.347186,
              39.059724
            ],
            [
              -78.341692,
              39.069505
            ],
            [
              -78.324474,
              39.075729
            ],
            [
              -78.336723,
              39.084438
            ],
            [
              -78.336648,
              39.101997
            ],
            [
              -78.343927,
              39.095749
            ],
            [
              -78.37587,
              39.092066
            ],
            [
              -78.391624,
              39.101897
            ],
            [
              -78.418053,
              39.086166
            ],
            [
              -78.430937,
              39.066333
            ],
            [
              -78.448199,
              39.056038
            ],
            [
              -78.444811,
              39.047234
            ],
            [
              -78.454136,
              39.027797
            ],
            [
              -78.5441928910609,
              39.056569664611196
            ],
            [
              -78.545679,
              39.055052
            ],
            [
              -78.547734,
              39.054069
            ],
            [
              -78.554263,
              39.048058
            ],
            [
              -78.556748,
              39.044527
            ],
            [
              -78.559997,
              39.041573
            ],
            [
              -78.571901,
              39.031995
            ],
            [
              -78.565837,
              39.026303
            ],
            [
              -78.565073,
              39.025935
            ],
            [
              -78.563294,
              39.026328
            ],
            [
              -78.55964,
              39.024456
            ],
            [
              -78.55738,
              39.021393
            ],
            [
              -78.550467,
              39.018065
            ],
            [
              -78.552321,
              39.016374
            ],
            [
              -78.554919,
              39.015124
            ],
            [
              -78.5594,
              39.011877
            ],
            [
              -78.561711,
              39.009007
            ],
            [
              -78.570462,
              39.001552
            ],
            [
              -78.580465,
              38.990567
            ],
            [
              -78.581981,
              38.988398
            ],
            [
              -78.582928,
              38.985416
            ],
            [
              -78.588704,
              38.978579
            ],
            [
              -78.593261,
              38.971918
            ],
            [
              -78.596015,
              38.970192
            ],
            [
              -78.598894,
              38.969546
            ],
            [
              -78.601399,
              38.96653
            ],
            [
              -78.601655,
              38.964603
            ],
            [
              -78.608369,
              38.969743
            ],
            [
              -78.611184,
              38.976134
            ],
            [
              -78.614312,
              38.97585
            ],
            [
              -78.618676,
              38.974082
            ],
            [
              -78.619982,
              38.977338
            ],
            [
              -78.619914,
              38.981288
            ],
            [
              -78.620453,
              38.982601
            ],
            [
              -78.625672,
              38.982575
            ],
            [
              -78.629553,
              38.980866
            ],
            [
              -78.630846,
              38.979586
            ],
            [
              -78.632452,
              38.976983
            ],
            [
              -78.632471,
              38.974739
            ],
            [
              -78.638423,
              38.966819
            ],
            [
              -78.646589,
              38.968138
            ],
            [
              -78.652352,
              38.960677
            ],
            [
              -78.655043,
              38.953766
            ],
            [
              -78.65905,
              38.947375
            ],
            [
              -78.662083,
              38.945702
            ],
            [
              -78.665886,
              38.941579
            ],
            [
              -78.666594,
              38.9392
            ],
            [
              -78.670679,
              38.9338
            ],
            [
              -78.680456,
              38.925313
            ],
            [
              -78.681617,
              38.92584
            ],
            [
              -78.688266,
              38.92478
            ],
            [
              -78.69145,
              38.922195
            ],
            [
              -78.69738,
              38.915602
            ],
            [
              -78.704323,
              38.915231
            ],
            [
              -78.712622,
              38.908665
            ],
            [
              -78.716168,
              38.90483
            ],
            [
              -78.717178,
              38.904296
            ],
            [
              -78.718647,
              38.904561
            ],
            [
              -78.71981,
              38.905907
            ],
            [
              -78.7209,
              38.909844
            ],
            [
              -78.719451,
              38.92026
            ],
            [
              -78.719755,
              38.922135
            ],
            [
              -78.719806,
              38.922638
            ],
            [
              -78.720095,
              38.923863
            ],
            [
              -78.71962,
              38.92651
            ],
            [
              -78.717076,
              38.936028
            ],
            [
              -78.718482,
              38.934267
            ],
            [
              -78.722451,
              38.931405
            ],
            [
              -78.724062,
              38.930846
            ],
            [
              -78.726222,
              38.930932
            ],
            [
              -78.738921,
              38.927283
            ],
            [
              -78.750517,
              38.916029
            ],
            [
              -78.754658,
              38.907582
            ],
            [
              -78.754516,
              38.905728
            ],
            [
              -78.757278,
              38.903203
            ],
            [
              -78.759085,
              38.900529
            ],
            [
              -78.772793,
              38.893742
            ],
            [
              -78.779198,
              38.892298
            ],
            [
              -78.786025,
              38.887187
            ],
            [
              -78.788031,
              38.885123
            ],
            [
              -78.790078,
              38.880076
            ],
            [
              -78.79161,
              38.877593
            ],
            [
              -78.796213,
              38.874606
            ],
            [
              -78.808181,
              38.856175
            ],
            [
              -78.810943,
              38.849616
            ],
            [
              -78.815116,
              38.841594
            ],
            [
              -78.821167,
              38.830982
            ],
            [
              -78.827262,
              38.82161
            ],
            [
              -78.832267,
              38.814388
            ],
            [
              -78.835191,
              38.811499
            ],
            [
              -78.848187,
              38.794978
            ],
            [
              -78.863684,
              38.7718
            ],
            [
              -78.865905,
              38.767034
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "051",
      "COUNTYNS": "01497376",
      "AFFGEOID": "0500000US51051",
      "GEOID": "51051",
      "NAME": "Dickenson",
      "LSAD": "06",
      "ALAND": 855845425,
      "AWATER": 8097296,
      "County_state": "Dickenson,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.553835,
              37.202841
            ],
            [
              -82.55386432928549,
              37.202819153295295
            ],
            [
              -82.546135,
              37.125118
            ],
            [
              -82.540229,
              37.120212
            ],
            [
              -82.497601,
              37.064584
            ],
            [
              -82.494358,
              37.059954
            ],
            [
              -82.478873,
              37.023147
            ],
            [
              -82.467345,
              37.022136
            ],
            [
              -82.408056,
              36.988251
            ],
            [
              -82.378821,
              36.980806
            ],
            [
              -82.365201,
              36.96265
            ],
            [
              -82.362386,
              36.955717
            ],
            [
              -82.32783,
              36.970982
            ],
            [
              -82.284965,
              36.998429
            ],
            [
              -82.261926,
              36.98945
            ],
            [
              -82.246603,
              36.995716
            ],
            [
              -82.196419,
              37.03103
            ],
            [
              -82.184013,
              37.026797
            ],
            [
              -82.168374,
              37.041159
            ],
            [
              -82.149375,
              37.041641
            ],
            [
              -82.137048,
              37.050255
            ],
            [
              -82.140915,
              37.069858
            ],
            [
              -82.157589,
              37.088641
            ],
            [
              -82.157889,
              37.110572
            ],
            [
              -82.171955,
              37.115443
            ],
            [
              -82.172283,
              37.129222
            ],
            [
              -82.201173,
              37.155885
            ],
            [
              -82.236504,
              37.212343
            ],
            [
              -82.250647,
              37.273201
            ],
            [
              -82.293007,
              37.305996
            ],
            [
              -82.3143123901533,
              37.296224662827
            ],
            [
              -82.316028,
              37.294879
            ],
            [
              -82.317083,
              37.293635
            ],
            [
              -82.317395,
              37.290975
            ],
            [
              -82.318957,
              37.287524
            ],
            [
              -82.324619,
              37.28318
            ],
            [
              -82.33157,
              37.282211
            ],
            [
              -82.336942,
              37.279737
            ],
            [
              -82.341849,
              37.280886
            ],
            [
              -82.342068,
              37.274109
            ],
            [
              -82.350948,
              37.267077
            ],
            [
              -82.355343,
              37.26522
            ],
            [
              -82.364535,
              37.264415
            ],
            [
              -82.376595,
              37.2599
            ],
            [
              -82.383285,
              37.260154
            ],
            [
              -82.385663,
              37.259631
            ],
            [
              -82.418085,
              37.251331
            ],
            [
              -82.431022,
              37.246773
            ],
            [
              -82.435728,
              37.246491
            ],
            [
              -82.449164,
              37.243908
            ],
            [
              -82.451998,
              37.242559
            ],
            [
              -82.457016,
              37.238288
            ],
            [
              -82.463073,
              37.238292
            ],
            [
              -82.473275,
              37.235569
            ],
            [
              -82.486439,
              37.231204
            ],
            [
              -82.487317,
              37.230578
            ],
            [
              -82.496308,
              37.227562
            ],
            [
              -82.498858,
              37.227044
            ],
            [
              -82.507895,
              37.222727
            ],
            [
              -82.508342,
              37.222385
            ],
            [
              -82.5165008662769,
              37.2186259093968
            ],
            [
              -82.524464,
              37.214957
            ],
            [
              -82.528746,
              37.213742
            ],
            [
              -82.531787,
              37.212097
            ],
            [
              -82.550372,
              37.204458
            ],
            [
              -82.553835,
              37.202841
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "790",
      "COUNTYNS": "01789075",
      "AFFGEOID": "0500000US51790",
      "GEOID": "51790",
      "NAME": "Staunton",
      "LSAD": "25",
      "ALAND": 51593824,
      "AWATER": 147694,
      "County_state": "Staunton,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.11384,
              38.154611
            ],
            [
              -79.106469,
              38.140611
            ],
            [
              -79.086569,
              38.126964
            ],
            [
              -79.077799,
              38.12637
            ],
            [
              -79.06994,
              38.131022
            ],
            [
              -79.048755,
              38.122104
            ],
            [
              -79.030656,
              38.132835
            ],
            [
              -79.020308,
              38.143725
            ],
            [
              -79.012429,
              38.167268
            ],
            [
              -79.024304,
              38.176508
            ],
            [
              -79.011381,
              38.181737
            ],
            [
              -79.024808,
              38.192161
            ],
            [
              -79.022841,
              38.195645
            ],
            [
              -79.033789,
              38.199018
            ],
            [
              -79.044545,
              38.190043
            ],
            [
              -79.060144,
              38.18684
            ],
            [
              -79.093791,
              38.186287
            ],
            [
              -79.101543,
              38.179042
            ],
            [
              -79.11384,
              38.154611
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "063",
      "COUNTYNS": "01494354",
      "AFFGEOID": "0500000US51063",
      "GEOID": "51063",
      "NAME": "Floyd",
      "LSAD": "06",
      "ALAND": 986572574,
      "AWATER": 2190750,
      "County_state": "Floyd,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.597585,
              36.94309
            ],
            [
              -80.633987,
              36.93178
            ],
            [
              -80.567009,
              36.843305
            ],
            [
              -80.46418,
              36.708718
            ],
            [
              -80.458572,
              36.738359
            ],
            [
              -80.443794,
              36.747859
            ],
            [
              -80.411719,
              36.741844
            ],
            [
              -80.394658,
              36.744867
            ],
            [
              -80.397377,
              36.764327
            ],
            [
              -80.387659,
              36.769959
            ],
            [
              -80.386909,
              36.797676
            ],
            [
              -80.361599,
              36.80309
            ],
            [
              -80.344442,
              36.813471
            ],
            [
              -80.341953,
              36.833249
            ],
            [
              -80.328395,
              36.830759
            ],
            [
              -80.317297,
              36.843729
            ],
            [
              -80.294171,
              36.847809
            ],
            [
              -80.28999,
              36.857579
            ],
            [
              -80.255234,
              36.864671
            ],
            [
              -80.235455,
              36.87213
            ],
            [
              -80.198854,
              36.901524
            ],
            [
              -80.191969,
              36.921756
            ],
            [
              -80.17333,
              36.952788
            ],
            [
              -80.145127,
              36.949405
            ],
            [
              -80.134619,
              36.964655
            ],
            [
              -80.143946,
              36.980188
            ],
            [
              -80.121479,
              36.991428
            ],
            [
              -80.135867,
              37.004085
            ],
            [
              -80.113542,
              37.009215
            ],
            [
              -80.117142,
              37.025145
            ],
            [
              -80.111808,
              37.045874
            ],
            [
              -80.122798,
              37.055642
            ],
            [
              -80.10822,
              37.078006
            ],
            [
              -80.113024,
              37.098109
            ],
            [
              -80.128674,
              37.122901
            ],
            [
              -80.173492,
              37.109095
            ],
            [
              -80.178125,
              37.1134
            ],
            [
              -80.272399,
              37.082407
            ],
            [
              -80.283675,
              37.07883
            ],
            [
              -80.347762,
              37.021592
            ],
            [
              -80.389465,
              37.0217
            ],
            [
              -80.404574,
              37.014358
            ],
            [
              -80.413343,
              37.022182
            ],
            [
              -80.412766,
              37.003034
            ],
            [
              -80.434235,
              37.016663
            ],
            [
              -80.462789,
              37.011145
            ],
            [
              -80.471583,
              36.993452
            ],
            [
              -80.485986,
              36.999096
            ],
            [
              -80.484778,
              36.988263
            ],
            [
              -80.503785,
              36.991593
            ],
            [
              -80.515619,
              37.011125
            ],
            [
              -80.520714,
              36.996968
            ],
            [
              -80.543933,
              36.984639
            ],
            [
              -80.552847,
              36.970239
            ],
            [
              -80.597585,
              36.94309
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "770",
      "COUNTYNS": "01498439",
      "AFFGEOID": "0500000US51770",
      "GEOID": "51770",
      "NAME": "Roanoke",
      "LSAD": "25",
      "ALAND": 110141450,
      "AWATER": 865676,
      "County_state": "Roanoke,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.033464,
              37.26289
            ],
            [
              -80.037477,
              37.251804
            ],
            [
              -80.02878,
              37.243694
            ],
            [
              -80.008971,
              37.248901
            ],
            [
              -80.00645,
              37.246323
            ],
            [
              -79.998191,
              37.252158
            ],
            [
              -79.981429,
              37.229726
            ],
            [
              -79.973967,
              37.231265
            ],
            [
              -79.971437,
              37.229567
            ],
            [
              -79.955005,
              37.211988
            ],
            [
              -79.936217,
              37.227136
            ],
            [
              -79.924496,
              37.224634
            ],
            [
              -79.919301,
              37.241295
            ],
            [
              -79.900048,
              37.257297
            ],
            [
              -79.904671,
              37.266607
            ],
            [
              -79.917784,
              37.280719
            ],
            [
              -79.884236,
              37.283686
            ],
            [
              -79.886652,
              37.309757
            ],
            [
              -79.901744,
              37.317957
            ],
            [
              -79.90546,
              37.321835
            ],
            [
              -79.912493,
              37.319643
            ],
            [
              -79.919223,
              37.320866
            ],
            [
              -79.918066,
              37.328414
            ],
            [
              -79.922294,
              37.32716
            ],
            [
              -79.930578,
              37.321801
            ],
            [
              -79.929269,
              37.320056
            ],
            [
              -79.936468,
              37.321022
            ],
            [
              -79.949734,
              37.326254
            ],
            [
              -79.959013,
              37.320139
            ],
            [
              -79.974023,
              37.335419
            ],
            [
              -79.989021,
              37.327208
            ],
            [
              -80.017173,
              37.314281
            ],
            [
              -80.022226,
              37.308338
            ],
            [
              -80.021713,
              37.281444
            ],
            [
              -80.014253,
              37.270984
            ],
            [
              -80.018446,
              37.264254
            ],
            [
              -80.033464,
              37.26289
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "005",
      "COUNTYNS": "01673675",
      "AFFGEOID": "0500000US51005",
      "GEOID": "51005",
      "NAME": "Alleghany",
      "LSAD": "06",
      "ALAND": 1156287826,
      "AWATER": 8491488,
      "County_state": "Alleghany,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.294108,
              37.693852
            ],
            [
              -80.296138,
              37.691783
            ],
            [
              -80.292337,
              37.683976
            ],
            [
              -80.292258,
              37.683732
            ],
            [
              -80.279372,
              37.657077
            ],
            [
              -80.270323,
              37.648982
            ],
            [
              -80.270352,
              37.648929
            ],
            [
              -80.267455,
              37.646108
            ],
            [
              -80.267228,
              37.646011
            ],
            [
              -80.264874,
              37.645511
            ],
            [
              -80.26483,
              37.645526
            ],
            [
              -80.263291,
              37.645101
            ],
            [
              -80.263281,
              37.645082
            ],
            [
              -80.254469,
              37.642333
            ],
            [
              -80.254431,
              37.642352
            ],
            [
              -80.239288,
              37.637672
            ],
            [
              -80.220984,
              37.627767
            ],
            [
              -80.205198,
              37.616293
            ],
            [
              -80.168436,
              37.615376
            ],
            [
              -80.145565,
              37.596428
            ],
            [
              -80.104798,
              37.62269
            ],
            [
              -80.087462,
              37.626671
            ],
            [
              -80.032705,
              37.66029
            ],
            [
              -80.020554,
              37.647442
            ],
            [
              -79.957365,
              37.683782
            ],
            [
              -79.941717,
              37.698951
            ],
            [
              -79.91756,
              37.701848
            ],
            [
              -79.890347,
              37.738063
            ],
            [
              -79.86708,
              37.748685
            ],
            [
              -79.844455,
              37.774588
            ],
            [
              -79.845321,
              37.782314
            ],
            [
              -79.816721,
              37.800973
            ],
            [
              -79.674162,
              37.763093
            ],
            [
              -79.642641,
              37.814185
            ],
            [
              -79.625437,
              37.829726
            ],
            [
              -79.606341,
              37.862111
            ],
            [
              -79.62928,
              37.874908
            ],
            [
              -79.647589,
              37.874538
            ],
            [
              -79.660575,
              37.872104
            ],
            [
              -79.692793,
              37.844458
            ],
            [
              -79.720961,
              37.868259
            ],
            [
              -79.749814,
              37.885402
            ],
            [
              -79.80806,
              37.880769
            ],
            [
              -79.887329,
              37.892971
            ],
            [
              -79.894205,
              37.898022
            ],
            [
              -79.935364,
              37.954365
            ],
            [
              -79.976133,
              37.953958
            ],
            [
              -80.002955,
              37.960119
            ],
            [
              -80.027439,
              37.942999
            ],
            [
              -80.0563291815345,
              37.95227447323649
            ],
            [
              -80.056839,
              37.951833
            ],
            [
              -80.063682,
              37.947968
            ],
            [
              -80.066569,
              37.947171
            ],
            [
              -80.074514,
              37.942221
            ],
            [
              -80.075441,
              37.939629
            ],
            [
              -80.080823,
              37.935526
            ],
            [
              -80.086954,
              37.929547
            ],
            [
              -80.096563,
              37.918112
            ],
            [
              -80.102931,
              37.918911
            ],
            [
              -80.106819,
              37.914698
            ],
            [
              -80.116884,
              37.906292
            ],
            [
              -80.118967,
              37.903614
            ],
            [
              -80.119106,
              37.902018
            ],
            [
              -80.11748,
              37.900581
            ],
            [
              -80.117747,
              37.89772
            ],
            [
              -80.120613,
              37.896735
            ],
            [
              -80.123021,
              37.898046
            ],
            [
              -80.12362,
              37.897943
            ],
            [
              -80.129555,
              37.894134
            ],
            [
              -80.130464,
              37.893194
            ],
            [
              -80.13104,
              37.890697
            ],
            [
              -80.131931,
              37.8895
            ],
            [
              -80.141947,
              37.882616
            ],
            [
              -80.14613,
              37.884453
            ],
            [
              -80.147316,
              37.885936
            ],
            [
              -80.148951,
              37.886892
            ],
            [
              -80.153832,
              37.881824
            ],
            [
              -80.162202,
              37.875122
            ],
            [
              -80.168957,
              37.867116
            ],
            [
              -80.172033,
              37.862144
            ],
            [
              -80.172076,
              37.860066
            ],
            [
              -80.176712,
              37.854029
            ],
            [
              -80.181815,
              37.852724
            ],
            [
              -80.183062,
              37.850646
            ],
            [
              -80.183555,
              37.84681
            ],
            [
              -80.179391,
              37.839751
            ],
            [
              -80.181768,
              37.838343
            ],
            [
              -80.18638,
              37.837741
            ],
            [
              -80.19465,
              37.831759
            ],
            [
              -80.199633,
              37.827507
            ],
            [
              -80.202853,
              37.82424
            ],
            [
              -80.205841,
              37.818921
            ],
            [
              -80.206482,
              37.81597
            ],
            [
              -80.210965,
              37.812598
            ],
            [
              -80.216229,
              37.80982
            ],
            [
              -80.216939,
              37.809505
            ],
            [
              -80.218611,
              37.809783
            ],
            [
              -80.227092,
              37.798886
            ],
            [
              -80.229228,
              37.79466
            ],
            [
              -80.229489,
              37.792331
            ],
            [
              -80.227965,
              37.791714
            ],
            [
              -80.220092,
              37.78316
            ],
            [
              -80.218616,
              37.783291
            ],
            [
              -80.215892,
              37.781989
            ],
            [
              -80.215658,
              37.777481
            ],
            [
              -80.216498,
              37.776445
            ],
            [
              -80.216899,
              37.776056
            ],
            [
              -80.217634,
              37.776775
            ],
            [
              -80.221827,
              37.778293
            ],
            [
              -80.227498,
              37.778889
            ],
            [
              -80.230458,
              37.778305
            ],
            [
              -80.232011,
              37.775621
            ],
            [
              -80.24139,
              37.769443
            ],
            [
              -80.246902,
              37.768309
            ],
            [
              -80.251319,
              37.762958
            ],
            [
              -80.250427,
              37.761301
            ],
            [
              -80.24979,
              37.757111
            ],
            [
              -80.251622,
              37.755866
            ],
            [
              -80.25641,
              37.756372
            ],
            [
              -80.257411,
              37.756084
            ],
            [
              -80.262765,
              37.738336
            ],
            [
              -80.260313,
              37.733517
            ],
            [
              -80.252024,
              37.729825
            ],
            [
              -80.252227,
              37.727261
            ],
            [
              -80.253077,
              37.725899
            ],
            [
              -80.258143,
              37.720612
            ],
            [
              -80.264406,
              37.718786
            ],
            [
              -80.27199,
              37.711532
            ],
            [
              -80.275007,
              37.707844
            ],
            [
              -80.287107,
              37.696403
            ],
            [
              -80.294108,
              37.693852
            ]
          ],
          [
            [
              -80.008015,
              37.760481
            ],
            [
              -79.995941,
              37.782229
            ],
            [
              -79.987402,
              37.813585
            ],
            [
              -79.967071,
              37.803405
            ],
            [
              -79.980001,
              37.781063
            ],
            [
              -79.97721,
              37.765177
            ],
            [
              -79.966509,
              37.757109
            ],
            [
              -79.992566,
              37.748244
            ],
            [
              -80.008015,
              37.760481
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "071",
      "COUNTYNS": "01494551",
      "AFFGEOID": "0500000US51071",
      "GEOID": "51071",
      "NAME": "Giles",
      "LSAD": "06",
      "ALAND": 925206333,
      "AWATER": 11233315,
      "County_state": "Giles,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.980146,
              37.292743
            ],
            [
              -81.014079,
              37.27563
            ],
            [
              -80.97939,
              37.250119
            ],
            [
              -80.894041,
              37.179375
            ],
            [
              -80.871306,
              37.193766
            ],
            [
              -80.861545,
              37.178499
            ],
            [
              -80.881129,
              37.171058
            ],
            [
              -80.854627,
              37.147482
            ],
            [
              -80.80149,
              37.179632
            ],
            [
              -80.699651,
              37.210578
            ],
            [
              -80.672201,
              37.224115
            ],
            [
              -80.606962,
              37.244871
            ],
            [
              -80.573592,
              37.256596
            ],
            [
              -80.520399,
              37.268893
            ],
            [
              -80.479194,
              37.285258
            ],
            [
              -80.467436,
              37.293831
            ],
            [
              -80.430943,
              37.316378
            ],
            [
              -80.443721,
              37.321124
            ],
            [
              -80.436053,
              37.339744
            ],
            [
              -80.4727968266129,
              37.4237800299575
            ],
            [
              -80.475601,
              37.422949
            ],
            [
              -80.494867,
              37.43507
            ],
            [
              -80.49728,
              37.444779
            ],
            [
              -80.492981,
              37.457749
            ],
            [
              -80.511391,
              37.481672
            ],
            [
              -80.513409,
              37.479446
            ],
            [
              -80.515139,
              37.478566
            ],
            [
              -80.523481,
              37.476905
            ],
            [
              -80.528349,
              37.477368
            ],
            [
              -80.532372,
              37.477124
            ],
            [
              -80.533449,
              37.476406
            ],
            [
              -80.539786,
              37.474527
            ],
            [
              -80.544836,
              37.474695
            ],
            [
              -80.552036,
              37.473563
            ],
            [
              -80.561442,
              37.469775
            ],
            [
              -80.566297,
              37.466575
            ],
            [
              -80.585856,
              37.456654
            ],
            [
              -80.59024,
              37.453296
            ],
            [
              -80.591377,
              37.45144
            ],
            [
              -80.600204,
              37.446173
            ],
            [
              -80.616802,
              37.439443
            ],
            [
              -80.622117,
              37.435969
            ],
            [
              -80.622664,
              37.433307
            ],
            [
              -80.626365,
              37.433328
            ],
            [
              -80.632365,
              37.432125
            ],
            [
              -80.63439,
              37.431227
            ],
            [
              -80.637379,
              37.429372
            ],
            [
              -80.637554,
              37.428556
            ],
            [
              -80.636947,
              37.427471
            ],
            [
              -80.645893,
              37.422147
            ],
            [
              -80.653589,
              37.419514
            ],
            [
              -80.656687,
              37.417585
            ],
            [
              -80.664112,
              37.41422
            ],
            [
              -80.664971,
              37.414215
            ],
            [
              -80.684576,
              37.40463
            ],
            [
              -80.691709,
              37.401749
            ],
            [
              -80.705203,
              37.394618
            ],
            [
              -80.715479,
              37.390707
            ],
            [
              -80.723596,
              37.388261
            ],
            [
              -80.731589,
              37.38471
            ],
            [
              -80.73804,
              37.382547
            ],
            [
              -80.745527,
              37.380111
            ],
            [
              -80.748722,
              37.38005
            ],
            [
              -80.759886,
              37.374882
            ],
            [
              -80.770082,
              37.372363
            ],
            [
              -80.776649,
              37.383679
            ],
            [
              -80.776766,
              37.384131
            ],
            [
              -80.782295,
              37.389016
            ],
            [
              -80.783382,
              37.390649
            ],
            [
              -80.783324,
              37.392793
            ],
            [
              -80.784188,
              37.394587
            ],
            [
              -80.790317,
              37.395668
            ],
            [
              -80.798869,
              37.395807
            ],
            [
              -80.800447,
              37.395738
            ],
            [
              -80.806129,
              37.398074
            ],
            [
              -80.807134,
              37.401348
            ],
            [
              -80.806358,
              37.404119
            ],
            [
              -80.808769,
              37.406271
            ],
            [
              -80.811639,
              37.407507
            ],
            [
              -80.836446,
              37.424355
            ],
            [
              -80.837678,
              37.425658
            ],
            [
              -80.841672,
              37.425971
            ],
            [
              -80.844213,
              37.423555
            ],
            [
              -80.846324,
              37.423394
            ],
            [
              -80.850656,
              37.426062
            ],
            [
              -80.853163,
              37.426902
            ],
            [
              -80.856997,
              37.427052
            ],
            [
              -80.85836,
              37.428168
            ],
            [
              -80.85847336829929,
              37.4283007053671
            ],
            [
              -80.859556,
              37.429568
            ],
            [
              -80.85956311282,
              37.429558233261005
            ],
            [
              -80.863142,
              37.424644
            ],
            [
              -80.865148,
              37.419927
            ],
            [
              -80.865174,
              37.416996
            ],
            [
              -80.864455,
              37.41418
            ],
            [
              -80.862761,
              37.411829
            ],
            [
              -80.883248,
              37.383933
            ],
            [
              -80.849451,
              37.346909
            ],
            [
              -80.865321,
              37.340523
            ],
            [
              -80.868986,
              37.338573
            ],
            [
              -80.880103,
              37.328903
            ],
            [
              -80.900535,
              37.315
            ],
            [
              -80.919259,
              37.306163
            ],
            [
              -80.92704,
              37.303683
            ],
            [
              -80.931118,
              37.302872
            ],
            [
              -80.938135,
              37.300278
            ],
            [
              -80.947896,
              37.295872
            ],
            [
              -80.966556,
              37.292158
            ],
            [
              -80.973889,
              37.291444
            ],
            [
              -80.980146,
              37.292743
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "067",
      "COUNTYNS": "01494434",
      "AFFGEOID": "0500000US51067",
      "GEOID": "51067",
      "NAME": "Franklin",
      "LSAD": "06",
      "ALAND": 1788431417,
      "AWATER": 54655591,
      "County_state": "Franklin,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.198854,
              36.901524
            ],
            [
              -80.235455,
              36.87213
            ],
            [
              -80.101942,
              36.846742
            ],
            [
              -80.066652,
              36.841604
            ],
            [
              -80.07267,
              36.823278
            ],
            [
              -80.041493,
              36.794365
            ],
            [
              -80.01681,
              36.781048
            ],
            [
              -80.012032,
              36.789644
            ],
            [
              -79.999437,
              36.830618
            ],
            [
              -79.933294,
              36.817636
            ],
            [
              -79.802056,
              36.792137
            ],
            [
              -79.786756,
              36.800737
            ],
            [
              -79.761056,
              36.788337
            ],
            [
              -79.741417,
              36.806806
            ],
            [
              -79.690354,
              36.828838
            ],
            [
              -79.669153,
              36.827838
            ],
            [
              -79.640652,
              36.856139
            ],
            [
              -79.61232,
              36.975016
            ],
            [
              -79.594069,
              37.042206
            ],
            [
              -79.593061,
              37.052868
            ],
            [
              -79.623003,
              37.082311
            ],
            [
              -79.61715,
              37.092434
            ],
            [
              -79.641069,
              37.103113
            ],
            [
              -79.651194,
              37.126749
            ],
            [
              -79.678028,
              37.155165
            ],
            [
              -79.687369,
              37.149525
            ],
            [
              -79.702355,
              37.164295
            ],
            [
              -79.704648,
              37.151486
            ],
            [
              -79.722359,
              37.192307
            ],
            [
              -79.726999,
              37.191388
            ],
            [
              -79.757703,
              37.189968
            ],
            [
              -79.776606,
              37.202627
            ],
            [
              -79.781194,
              37.232558
            ],
            [
              -79.806065,
              37.215174
            ],
            [
              -79.818161,
              37.226201
            ],
            [
              -79.833008,
              37.216049
            ],
            [
              -79.847217,
              37.225406
            ],
            [
              -79.852106,
              37.214969
            ],
            [
              -79.883496,
              37.209445
            ],
            [
              -79.895123,
              37.181834
            ],
            [
              -79.922488,
              37.160769
            ],
            [
              -79.962822,
              37.137274
            ],
            [
              -80.001182,
              37.151665
            ],
            [
              -80.005309,
              37.167816
            ],
            [
              -80.0092,
              37.172708
            ],
            [
              -80.082765,
              37.160908
            ],
            [
              -80.086955,
              37.161198
            ],
            [
              -80.128674,
              37.122901
            ],
            [
              -80.113024,
              37.098109
            ],
            [
              -80.10822,
              37.078006
            ],
            [
              -80.122798,
              37.055642
            ],
            [
              -80.111808,
              37.045874
            ],
            [
              -80.117142,
              37.025145
            ],
            [
              -80.113542,
              37.009215
            ],
            [
              -80.135867,
              37.004085
            ],
            [
              -80.121479,
              36.991428
            ],
            [
              -80.143946,
              36.980188
            ],
            [
              -80.134619,
              36.964655
            ],
            [
              -80.145127,
              36.949405
            ],
            [
              -80.17333,
              36.952788
            ],
            [
              -80.191969,
              36.921756
            ],
            [
              -80.198854,
              36.901524
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "017",
      "COUNTYNS": "01673638",
      "AFFGEOID": "0500000US51017",
      "GEOID": "51017",
      "NAME": "Bath",
      "LSAD": "06",
      "ALAND": 1370626013,
      "AWATER": 14039993,
      "County_state": "Bath,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.051043,
              37.956852
            ],
            [
              -80.0563291815345,
              37.95227447323649
            ],
            [
              -80.027439,
              37.942999
            ],
            [
              -80.002955,
              37.960119
            ],
            [
              -79.976133,
              37.953958
            ],
            [
              -79.935364,
              37.954365
            ],
            [
              -79.894205,
              37.898022
            ],
            [
              -79.887329,
              37.892971
            ],
            [
              -79.80806,
              37.880769
            ],
            [
              -79.749814,
              37.885402
            ],
            [
              -79.720961,
              37.868259
            ],
            [
              -79.692793,
              37.844458
            ],
            [
              -79.660575,
              37.872104
            ],
            [
              -79.647589,
              37.874538
            ],
            [
              -79.609241,
              37.935486
            ],
            [
              -79.581865,
              37.954397
            ],
            [
              -79.53386,
              38.003321
            ],
            [
              -79.511144,
              38.035258
            ],
            [
              -79.524495,
              38.041123
            ],
            [
              -79.510286,
              38.051398
            ],
            [
              -79.482668,
              38.086054
            ],
            [
              -79.493253,
              38.095384
            ],
            [
              -79.46918,
              38.124534
            ],
            [
              -79.445118,
              38.147568
            ],
            [
              -79.436678,
              38.1628
            ],
            [
              -79.458334,
              38.163057
            ],
            [
              -79.481603,
              38.170301
            ],
            [
              -79.509181,
              38.154939
            ],
            [
              -79.527044,
              38.151831
            ],
            [
              -79.52732,
              38.170797
            ],
            [
              -79.512158,
              38.180419
            ],
            [
              -79.562018,
              38.198805
            ],
            [
              -79.706196,
              38.221192
            ],
            [
              -79.753795,
              38.242698
            ],
            [
              -79.7961001204602,
              38.266413403698
            ],
            [
              -79.798295,
              38.265957
            ],
            [
              -79.801274,
              38.261474
            ],
            [
              -79.806333,
              38.259193
            ],
            [
              -79.811987,
              38.260401
            ],
            [
              -79.814202,
              38.258174
            ],
            [
              -79.815708,
              38.255065
            ],
            [
              -79.815719,
              38.253645
            ],
            [
              -79.814865,
              38.251568
            ],
            [
              -79.817149,
              38.249511
            ],
            [
              -79.819623,
              38.248234
            ],
            [
              -79.82101,
              38.248277
            ],
            [
              -79.825283,
              38.250488
            ],
            [
              -79.830882,
              38.249687
            ],
            [
              -79.832971,
              38.247553
            ],
            [
              -79.834031,
              38.244957
            ],
            [
              -79.834171,
              38.242899
            ],
            [
              -79.835124,
              38.241892
            ],
            [
              -79.837494,
              38.241276
            ],
            [
              -79.842981,
              38.241594
            ],
            [
              -79.845207,
              38.241082
            ],
            [
              -79.846445,
              38.240003
            ],
            [
              -79.850324,
              38.233329
            ],
            [
              -79.856962,
              38.231075
            ],
            [
              -79.863625,
              38.223945
            ],
            [
              -79.879087,
              38.211016
            ],
            [
              -79.884234,
              38.207868
            ],
            [
              -79.886413,
              38.207953
            ],
            [
              -79.888045,
              38.20736
            ],
            [
              -79.891591,
              38.204652
            ],
            [
              -79.891999,
              38.203378
            ],
            [
              -79.892345,
              38.202397
            ],
            [
              -79.892916,
              38.199868
            ],
            [
              -79.898426,
              38.193045
            ],
            [
              -79.90609,
              38.188999
            ],
            [
              -79.910961,
              38.18792
            ],
            [
              -79.91441,
              38.188418
            ],
            [
              -79.916344,
              38.186278
            ],
            [
              -79.916174,
              38.184386
            ],
            [
              -79.917061,
              38.183741
            ],
            [
              -79.921196,
              38.180378
            ],
            [
              -79.921026,
              38.179954
            ],
            [
              -79.916622,
              38.177994
            ],
            [
              -79.916765,
              38.175504
            ],
            [
              -79.918629,
              38.172671
            ],
            [
              -79.918913,
              38.170439
            ],
            [
              -79.917924,
              38.168399
            ],
            [
              -79.916072,
              38.168428
            ],
            [
              -79.915065,
              38.168088
            ],
            [
              -79.914884,
              38.167524
            ],
            [
              -79.918662,
              38.15479
            ],
            [
              -79.923125,
              38.150874
            ],
            [
              -79.925251,
              38.150465
            ],
            [
              -79.925512,
              38.150237
            ],
            [
              -79.928683,
              38.144928
            ],
            [
              -79.928747,
              38.144436
            ],
            [
              -79.929031,
              38.139771
            ],
            [
              -79.933751,
              38.135508
            ],
            [
              -79.942747,
              38.134333
            ],
            [
              -79.944843,
              38.131585
            ],
            [
              -79.938952,
              38.111619
            ],
            [
              -79.938051,
              38.110759
            ],
            [
              -79.934364,
              38.109718
            ],
            [
              -79.929687,
              38.109197
            ],
            [
              -79.92633,
              38.107151
            ],
            [
              -79.927645,
              38.104826
            ],
            [
              -79.931034,
              38.101402
            ],
            [
              -79.933911,
              38.099168
            ],
            [
              -79.93425,
              38.097669
            ],
            [
              -79.935101,
              38.096541
            ],
            [
              -79.938274,
              38.094741
            ],
            [
              -79.942364,
              38.091588
            ],
            [
              -79.949113,
              38.084238
            ],
            [
              -79.953509,
              38.081484
            ],
            [
              -79.954369,
              38.080397
            ],
            [
              -79.960093,
              38.068677
            ],
            [
              -79.959844,
              38.063697
            ],
            [
              -79.968189,
              38.047709
            ],
            [
              -79.971231,
              38.044326
            ],
            [
              -79.973895,
              38.040004
            ],
            [
              -79.973777,
              38.038744
            ],
            [
              -79.972165,
              38.036102
            ],
            [
              -79.973701,
              38.032556
            ],
            [
              -79.975269,
              38.030075
            ],
            [
              -79.976732,
              38.029278
            ],
            [
              -79.978427,
              38.029082
            ],
            [
              -79.98029,
              38.027596
            ],
            [
              -79.985619,
              38.01916
            ],
            [
              -79.985792,
              38.018089
            ],
            [
              -79.984842,
              38.01661
            ],
            [
              -79.986142,
              38.014182
            ],
            [
              -79.990114,
              38.013246
            ],
            [
              -79.994985,
              38.007853
            ],
            [
              -79.995901,
              38.005791
            ],
            [
              -79.995398,
              38.003309
            ],
            [
              -79.996134,
              38.000996
            ],
            [
              -79.999384,
              37.995842
            ],
            [
              -80.002507,
              37.992767
            ],
            [
              -80.008888,
              37.99083
            ],
            [
              -80.012193,
              37.988633
            ],
            [
              -80.012891,
              37.987443
            ],
            [
              -80.012555,
              37.985999
            ],
            [
              -80.013145,
              37.984253
            ],
            [
              -80.024168,
              37.976907
            ],
            [
              -80.036236,
              37.96792
            ],
            [
              -80.04841,
              37.957481
            ],
            [
              -80.051043,
              37.956852
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "143",
      "COUNTYNS": "01480157",
      "AFFGEOID": "0500000US51143",
      "GEOID": "51143",
      "NAME": "Pittsylvania",
      "LSAD": "06",
      "ALAND": 2509683753,
      "AWATER": 23925747,
      "County_state": "Pittsylvania,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.690513,
              36.645404
            ],
            [
              -79.71484019625919,
              36.5420023594641
            ],
            [
              -79.667309,
              36.541772
            ],
            [
              -79.666827,
              36.541772
            ],
            [
              -79.5136470557863,
              36.5407578620674
            ],
            [
              -79.510647,
              36.540738
            ],
            [
              -79.4700322670177,
              36.5410249389508
            ],
            [
              -79.472112,
              36.560621
            ],
            [
              -79.456537,
              36.576699
            ],
            [
              -79.491506,
              36.581016
            ],
            [
              -79.518688,
              36.603608
            ],
            [
              -79.505048,
              36.616744
            ],
            [
              -79.500989,
              36.618146
            ],
            [
              -79.452673,
              36.612179
            ],
            [
              -79.42209,
              36.60064
            ],
            [
              -79.420829,
              36.603541
            ],
            [
              -79.398094,
              36.644037
            ],
            [
              -79.385843,
              36.637719
            ],
            [
              -79.369429,
              36.635074
            ],
            [
              -79.365013,
              36.607797
            ],
            [
              -79.330969,
              36.601669
            ],
            [
              -79.316418,
              36.582655
            ],
            [
              -79.304635,
              36.580776
            ],
            [
              -79.304626,
              36.570296
            ],
            [
              -79.33619,
              36.5717
            ],
            [
              -79.351535,
              36.55124
            ],
            [
              -79.3431171666517,
              36.5411766468952
            ],
            [
              -79.24974,
              36.541139
            ],
            [
              -79.2184541869829,
              36.5414925778669
            ],
            [
              -79.209073,
              36.581182
            ],
            [
              -79.158041,
              36.791057
            ],
            [
              -79.092264,
              37.061498
            ],
            [
              -79.10546,
              37.067262
            ],
            [
              -79.127139,
              37.089864
            ],
            [
              -79.143478,
              37.075852
            ],
            [
              -79.182374,
              37.069618
            ],
            [
              -79.193115,
              37.054637
            ],
            [
              -79.205975,
              37.076588
            ],
            [
              -79.200845,
              37.098401
            ],
            [
              -79.213032,
              37.117902
            ],
            [
              -79.222681,
              37.111547
            ],
            [
              -79.222058,
              37.10139
            ],
            [
              -79.237163,
              37.104174
            ],
            [
              -79.244166,
              37.124307
            ],
            [
              -79.259288,
              37.131076
            ],
            [
              -79.272657,
              37.111164
            ],
            [
              -79.295256,
              37.104805
            ],
            [
              -79.332161,
              37.125568
            ],
            [
              -79.341422,
              37.137166
            ],
            [
              -79.344654,
              37.125725
            ],
            [
              -79.383492,
              37.117193
            ],
            [
              -79.369724,
              37.11048
            ],
            [
              -79.387549,
              37.092948
            ],
            [
              -79.404731,
              37.091777
            ],
            [
              -79.404165,
              37.064161
            ],
            [
              -79.442859,
              37.055867
            ],
            [
              -79.477847,
              37.064244
            ],
            [
              -79.491249,
              37.058329
            ],
            [
              -79.480183,
              37.043506
            ],
            [
              -79.483272,
              37.03529
            ],
            [
              -79.461195,
              37.021657
            ],
            [
              -79.479921,
              37.006035
            ],
            [
              -79.495178,
              37.021965
            ],
            [
              -79.542296,
              37.045872
            ],
            [
              -79.559479,
              37.0439
            ],
            [
              -79.562621,
              37.035017
            ],
            [
              -79.594069,
              37.042206
            ],
            [
              -79.61232,
              36.975016
            ],
            [
              -79.640652,
              36.856139
            ],
            [
              -79.680963,
              36.685306
            ],
            [
              -79.689447,
              36.649864
            ],
            [
              -79.690513,
              36.645404
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "131",
      "COUNTYNS": "01480151",
      "AFFGEOID": "0500000US51131",
      "GEOID": "51131",
      "NAME": "Northampton",
      "LSAD": "06",
      "ALAND": 548282864,
      "AWATER": 1511417329,
      "County_state": "Northampton,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.023664,
              37.268971
            ],
            [
              -76.025753,
              37.257407
            ],
            [
              -76.014026,
              37.235381
            ],
            [
              -76.010535,
              37.231579
            ],
            [
              -76.013778,
              37.219263
            ],
            [
              -76.013071,
              37.205366
            ],
            [
              -76.006094,
              37.19481
            ],
            [
              -75.998647,
              37.188739
            ],
            [
              -75.978083,
              37.157338
            ],
            [
              -75.970004,
              37.128861
            ],
            [
              -75.97043,
              37.118608
            ],
            [
              -75.979608,
              37.100448
            ],
            [
              -75.97996,
              37.09217
            ],
            [
              -75.971876,
              37.085376
            ],
            [
              -75.955241,
              37.083827
            ],
            [
              -75.942399,
              37.089607
            ],
            [
              -75.943417,
              37.10066
            ],
            [
              -75.952983,
              37.105724
            ],
            [
              -75.955122,
              37.11975
            ],
            [
              -75.945872,
              37.120514
            ],
            [
              -75.942539,
              37.125142
            ],
            [
              -75.927789,
              37.130268
            ],
            [
              -75.911508,
              37.122877
            ],
            [
              -75.915786,
              37.112169
            ],
            [
              -75.906734,
              37.114193
            ],
            [
              -75.897298,
              37.118037
            ],
            [
              -75.886369,
              37.126085
            ],
            [
              -75.87767,
              37.135604
            ],
            [
              -75.856366,
              37.158497
            ],
            [
              -75.834692,
              37.173261
            ],
            [
              -75.830934,
              37.183591
            ],
            [
              -75.817387,
              37.193437
            ],
            [
              -75.815604,
              37.202241
            ],
            [
              -75.819882,
              37.212463
            ],
            [
              -75.8099,
              37.216249
            ],
            [
              -75.803245,
              37.227415
            ],
            [
              -75.803097,
              37.236311
            ],
            [
              -75.795164,
              37.247095
            ],
            [
              -75.795282,
              37.277361
            ],
            [
              -75.798448,
              37.296285
            ],
            [
              -75.791913,
              37.300589
            ],
            [
              -75.784634,
              37.300976
            ],
            [
              -75.780766,
              37.297222
            ],
            [
              -75.778817,
              37.297176
            ],
            [
              -75.765401,
              37.305596
            ],
            [
              -75.735829,
              37.335426
            ],
            [
              -75.726691,
              37.350127
            ],
            [
              -75.725634,
              37.358416
            ],
            [
              -75.727335,
              37.360346
            ],
            [
              -75.720739,
              37.373129
            ],
            [
              -75.697914,
              37.405301
            ],
            [
              -75.677542,
              37.428612
            ],
            [
              -75.665957,
              37.439209
            ],
            [
              -75.658379,
              37.451815
            ],
            [
              -75.66444,
              37.464456
            ],
            [
              -75.6765221891521,
              37.46370055508459
            ],
            [
              -75.68326,
              37.454744
            ],
            [
              -75.713275,
              37.449876
            ],
            [
              -75.72852,
              37.459775
            ],
            [
              -75.763912,
              37.463308
            ],
            [
              -75.776564,
              37.454589
            ],
            [
              -75.78703,
              37.464578
            ],
            [
              -75.80242,
              37.462433
            ],
            [
              -75.812793,
              37.473895
            ],
            [
              -75.793521,
              37.488837
            ],
            [
              -75.791656,
              37.50381
            ],
            [
              -75.804797,
              37.514726
            ],
            [
              -75.788012,
              37.528816
            ],
            [
              -75.835214,
              37.554245
            ],
            [
              -75.884455,
              37.549542
            ],
            [
              -75.90041,
              37.557265
            ],
            [
              -75.929315,
              37.554933
            ],
            [
              -75.9376646225106,
              37.5496523755582
            ],
            [
              -75.940318,
              37.534582
            ],
            [
              -75.949974,
              37.521876
            ],
            [
              -75.958966,
              37.500133
            ],
            [
              -75.963326,
              37.481785
            ],
            [
              -75.963496,
              37.475352
            ],
            [
              -75.960877,
              37.467562
            ],
            [
              -75.976491,
              37.444878
            ],
            [
              -75.981624,
              37.434116
            ],
            [
              -75.983105,
              37.415802
            ],
            [
              -75.97997,
              37.404608
            ],
            [
              -75.987122,
              37.368548
            ],
            [
              -75.997778,
              37.351739
            ],
            [
              -76.014251,
              37.331943
            ],
            [
              -76.018645,
              37.31782
            ],
            [
              -76.023475,
              37.289067
            ],
            [
              -76.015507,
              37.280874
            ],
            [
              -76.023664,
              37.268971
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "047",
      "COUNTYNS": "01497831",
      "AFFGEOID": "0500000US51047",
      "GEOID": "51047",
      "NAME": "Culpeper",
      "LSAD": "06",
      "ALAND": 982079080,
      "AWATER": 9015222,
      "County_state": "Culpeper,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.214882,
              38.538439
            ],
            [
              -78.231803,
              38.532539
            ],
            [
              -78.139781,
              38.449096
            ],
            [
              -78.118822,
              38.434382
            ],
            [
              -78.118312,
              38.416109
            ],
            [
              -78.101061,
              38.406577
            ],
            [
              -78.096545,
              38.385971
            ],
            [
              -78.117961,
              38.368822
            ],
            [
              -78.107784,
              38.343808
            ],
            [
              -78.082756,
              38.327931
            ],
            [
              -78.105009,
              38.31193
            ],
            [
              -78.094498,
              38.311242
            ],
            [
              -78.06905,
              38.308389
            ],
            [
              -78.054623,
              38.317502
            ],
            [
              -78.037188,
              38.311882
            ],
            [
              -78.029472,
              38.32843
            ],
            [
              -77.993872,
              38.349348
            ],
            [
              -77.975732,
              38.347087
            ],
            [
              -77.972949,
              38.358946
            ],
            [
              -77.957725,
              38.353906
            ],
            [
              -77.942645,
              38.362463
            ],
            [
              -77.911858,
              38.370668
            ],
            [
              -77.908979,
              38.381503
            ],
            [
              -77.886978,
              38.388159
            ],
            [
              -77.884655,
              38.379137
            ],
            [
              -77.854377,
              38.382279
            ],
            [
              -77.839951,
              38.377542
            ],
            [
              -77.827692,
              38.382195
            ],
            [
              -77.802641,
              38.376446
            ],
            [
              -77.782494,
              38.364465
            ],
            [
              -77.78218,
              38.385897
            ],
            [
              -77.770061,
              38.392992
            ],
            [
              -77.702843,
              38.36084
            ],
            [
              -77.679539,
              38.359908
            ],
            [
              -77.645744,
              38.378107
            ],
            [
              -77.633418,
              38.360183
            ],
            [
              -77.618727,
              38.367835
            ],
            [
              -77.621011,
              38.383072
            ],
            [
              -77.632644,
              38.393006
            ],
            [
              -77.63494,
              38.410218
            ],
            [
              -77.69189,
              38.426873
            ],
            [
              -77.735516,
              38.413116
            ],
            [
              -77.755075,
              38.429398
            ],
            [
              -77.761096,
              38.458148
            ],
            [
              -77.780446,
              38.477542
            ],
            [
              -77.781465,
              38.488158
            ],
            [
              -77.800574,
              38.502243
            ],
            [
              -77.8136,
              38.530115
            ],
            [
              -77.85691,
              38.557739
            ],
            [
              -77.877169,
              38.584891
            ],
            [
              -77.862378,
              38.598527
            ],
            [
              -77.87401,
              38.607126
            ],
            [
              -77.859948,
              38.62081
            ],
            [
              -77.880475,
              38.637197
            ],
            [
              -77.871503,
              38.647995
            ],
            [
              -77.909456,
              38.697322
            ],
            [
              -77.935355,
              38.69584
            ],
            [
              -77.97907,
              38.66785
            ],
            [
              -78.089203,
              38.597381
            ],
            [
              -78.175975,
              38.520792
            ],
            [
              -78.214882,
              38.538439
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "095",
      "COUNTYNS": "01480135",
      "AFFGEOID": "0500000US51095",
      "GEOID": "51095",
      "NAME": "James City",
      "LSAD": "06",
      "ALAND": 368881354,
      "AWATER": 95089229,
      "County_state": "James City,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.904471,
              37.377665
            ],
            [
              -76.909647,
              37.348643
            ],
            [
              -76.88477,
              37.368566
            ],
            [
              -76.874437,
              37.365427
            ],
            [
              -76.880005,
              37.309874
            ],
            [
              -76.871685,
              37.295597
            ],
            [
              -76.881564,
              37.285509
            ],
            [
              -76.8753156074675,
              37.260982527633395
            ],
            [
              -76.872209,
              37.26275
            ],
            [
              -76.858424,
              37.241385
            ],
            [
              -76.822178,
              37.243642
            ],
            [
              -76.8187654123648,
              37.2421652494972
            ],
            [
              -76.793537,
              37.231248
            ],
            [
              -76.7885770855513,
              37.2228910876279
            ],
            [
              -76.780532,
              37.209336
            ],
            [
              -76.773752,
              37.206061
            ],
            [
              -76.757765,
              37.191658
            ],
            [
              -76.75047,
              37.190098
            ],
            [
              -76.74304,
              37.192611
            ],
            [
              -76.74,
              37.195379
            ],
            [
              -76.73432,
              37.204211
            ],
            [
              -76.730951,
              37.213813
            ],
            [
              -76.698943,
              37.219059
            ],
            [
              -76.689166,
              37.222866
            ],
            [
              -76.649869,
              37.220914
            ],
            [
              -76.641085,
              37.216002
            ],
            [
              -76.629868,
              37.206738
            ],
            [
              -76.623292,
              37.198738
            ],
            [
              -76.61934,
              37.192146
            ],
            [
              -76.616268,
              37.178962
            ],
            [
              -76.61101757959901,
              37.167097001631596
            ],
            [
              -76.581218,
              37.182511
            ],
            [
              -76.591577,
              37.213833
            ],
            [
              -76.601115,
              37.238193
            ],
            [
              -76.632722,
              37.236345
            ],
            [
              -76.651277,
              37.239154
            ],
            [
              -76.662466,
              37.251609
            ],
            [
              -76.727279,
              37.250243
            ],
            [
              -76.738554,
              37.261318
            ],
            [
              -76.741143,
              37.27338
            ],
            [
              -76.737155,
              37.308308
            ],
            [
              -76.727859,
              37.305927
            ],
            [
              -76.734956,
              37.331885
            ],
            [
              -76.753072,
              37.338675
            ],
            [
              -76.755477,
              37.354325
            ],
            [
              -76.742245,
              37.37011
            ],
            [
              -76.711808,
              37.36509
            ],
            [
              -76.673712,
              37.367728
            ],
            [
              -76.658651,
              37.380139
            ],
            [
              -76.672296,
              37.395808
            ],
            [
              -76.712305,
              37.431309
            ],
            [
              -76.732961,
              37.450235
            ],
            [
              -76.741984,
              37.467076
            ],
            [
              -76.756789,
              37.461715
            ],
            [
              -76.757995,
              37.4455
            ],
            [
              -76.770279,
              37.434992
            ],
            [
              -76.79254,
              37.435495
            ],
            [
              -76.824103,
              37.450716
            ],
            [
              -76.859974,
              37.446834
            ],
            [
              -76.892062,
              37.432418
            ],
            [
              -76.894153,
              37.400228
            ],
            [
              -76.904471,
              37.377665
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "061",
      "COUNTYNS": "01480120",
      "AFFGEOID": "0500000US51061",
      "GEOID": "51061",
      "NAME": "Fauquier",
      "LSAD": "06",
      "ALAND": 1678273823,
      "AWATER": 9687703,
      "County_state": "Fauquier,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.112222,
              38.880374
            ],
            [
              -78.130574,
              38.864865
            ],
            [
              -78.099732,
              38.830392
            ],
            [
              -78.078584,
              38.823356
            ],
            [
              -78.051397,
              38.79923
            ],
            [
              -78.025832,
              38.795787
            ],
            [
              -78.019536,
              38.777841
            ],
            [
              -78.028102,
              38.757486
            ],
            [
              -78.016445,
              38.754016
            ],
            [
              -78.014368,
              38.727061
            ],
            [
              -77.995664,
              38.710416
            ],
            [
              -77.954567,
              38.694789
            ],
            [
              -77.935355,
              38.69584
            ],
            [
              -77.909456,
              38.697322
            ],
            [
              -77.871503,
              38.647995
            ],
            [
              -77.880475,
              38.637197
            ],
            [
              -77.859948,
              38.62081
            ],
            [
              -77.87401,
              38.607126
            ],
            [
              -77.862378,
              38.598527
            ],
            [
              -77.877169,
              38.584891
            ],
            [
              -77.85691,
              38.557739
            ],
            [
              -77.8136,
              38.530115
            ],
            [
              -77.800574,
              38.502243
            ],
            [
              -77.781465,
              38.488158
            ],
            [
              -77.780446,
              38.477542
            ],
            [
              -77.761096,
              38.458148
            ],
            [
              -77.755075,
              38.429398
            ],
            [
              -77.735516,
              38.413116
            ],
            [
              -77.69189,
              38.426873
            ],
            [
              -77.63494,
              38.410218
            ],
            [
              -77.628569,
              38.451864
            ],
            [
              -77.633837,
              38.466892
            ],
            [
              -77.632345,
              38.469615
            ],
            [
              -77.588783,
              38.504044
            ],
            [
              -77.560869,
              38.527059
            ],
            [
              -77.531328,
              38.55655
            ],
            [
              -77.535485,
              38.561247
            ],
            [
              -77.579484,
              38.627653
            ],
            [
              -77.624978,
              38.696168
            ],
            [
              -77.669725,
              38.763418
            ],
            [
              -77.679735,
              38.77844
            ],
            [
              -77.679854,
              38.77862
            ],
            [
              -77.709962,
              38.823761
            ],
            [
              -77.715667,
              38.843224
            ],
            [
              -77.704931,
              38.843152
            ],
            [
              -77.692622,
              38.887004
            ],
            [
              -77.679432,
              38.895455
            ],
            [
              -77.665221,
              38.916736
            ],
            [
              -77.655169,
              38.942656
            ],
            [
              -77.962203,
              39.013733
            ],
            [
              -77.990275,
              38.996493
            ],
            [
              -78.004313,
              38.979438
            ],
            [
              -78.041576,
              38.938096
            ],
            [
              -78.075149,
              38.912607
            ],
            [
              -78.062016,
              38.891926
            ],
            [
              -78.112222,
              38.880374
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "137",
      "COUNTYNS": "01494761",
      "AFFGEOID": "0500000US51137",
      "GEOID": "51137",
      "NAME": "Orange",
      "LSAD": "06",
      "ALAND": 883480537,
      "AWATER": 6502589,
      "County_state": "Orange,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.368743,
              38.184074
            ],
            [
              -78.344325,
              38.177786
            ],
            [
              -78.256818,
              38.147905
            ],
            [
              -78.209384,
              38.131128
            ],
            [
              -78.171423,
              38.129688
            ],
            [
              -78.144342,
              38.142999
            ],
            [
              -78.116832,
              38.140002
            ],
            [
              -78.101989,
              38.152546
            ],
            [
              -78.047876,
              38.135883
            ],
            [
              -78.024735,
              38.136473
            ],
            [
              -78.021872,
              38.142105
            ],
            [
              -78.001947,
              38.137582
            ],
            [
              -77.979936,
              38.119533
            ],
            [
              -77.953639,
              38.118501
            ],
            [
              -77.789393,
              38.266818
            ],
            [
              -77.78589,
              38.269931
            ],
            [
              -77.747943,
              38.304837
            ],
            [
              -77.715081,
              38.335764
            ],
            [
              -77.702843,
              38.36084
            ],
            [
              -77.770061,
              38.392992
            ],
            [
              -77.78218,
              38.385897
            ],
            [
              -77.782494,
              38.364465
            ],
            [
              -77.802641,
              38.376446
            ],
            [
              -77.827692,
              38.382195
            ],
            [
              -77.839951,
              38.377542
            ],
            [
              -77.854377,
              38.382279
            ],
            [
              -77.884655,
              38.379137
            ],
            [
              -77.886978,
              38.388159
            ],
            [
              -77.908979,
              38.381503
            ],
            [
              -77.911858,
              38.370668
            ],
            [
              -77.942645,
              38.362463
            ],
            [
              -77.957725,
              38.353906
            ],
            [
              -77.972949,
              38.358946
            ],
            [
              -77.975732,
              38.347087
            ],
            [
              -77.993872,
              38.349348
            ],
            [
              -78.029472,
              38.32843
            ],
            [
              -78.037188,
              38.311882
            ],
            [
              -78.054623,
              38.317502
            ],
            [
              -78.06905,
              38.308389
            ],
            [
              -78.094498,
              38.311242
            ],
            [
              -78.094994,
              38.303752
            ],
            [
              -78.126374,
              38.281922
            ],
            [
              -78.144747,
              38.283158
            ],
            [
              -78.15853,
              38.274298
            ],
            [
              -78.152165,
              38.265846
            ],
            [
              -78.183143,
              38.267554
            ],
            [
              -78.177738,
              38.254306
            ],
            [
              -78.192106,
              38.245593
            ],
            [
              -78.217333,
              38.242475
            ],
            [
              -78.221104,
              38.230089
            ],
            [
              -78.231833,
              38.230529
            ],
            [
              -78.252862,
              38.234521
            ],
            [
              -78.270424,
              38.250027
            ],
            [
              -78.267717,
              38.2637
            ],
            [
              -78.288837,
              38.272656
            ],
            [
              -78.292194,
              38.268293
            ],
            [
              -78.368743,
              38.184074
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "680",
      "COUNTYNS": "01498429",
      "AFFGEOID": "0500000US51680",
      "GEOID": "51680",
      "NAME": "Lynchburg",
      "LSAD": "25",
      "ALAND": 126842531,
      "AWATER": 1428787,
      "County_state": "Lynchburg,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.266986,
              37.447868
            ],
            [
              -79.26838,
              37.431251
            ],
            [
              -79.24494,
              37.420664
            ],
            [
              -79.254385,
              37.404493
            ],
            [
              -79.23378,
              37.39525
            ],
            [
              -79.233115,
              37.393069
            ],
            [
              -79.257955,
              37.355816
            ],
            [
              -79.214924,
              37.33258
            ],
            [
              -79.197451,
              37.344541
            ],
            [
              -79.176879,
              37.342163
            ],
            [
              -79.136925,
              37.374154
            ],
            [
              -79.130643,
              37.375101
            ],
            [
              -79.119399,
              37.368736
            ],
            [
              -79.085123,
              37.394875
            ],
            [
              -79.109491,
              37.406917
            ],
            [
              -79.120279,
              37.398187
            ],
            [
              -79.146574,
              37.428684
            ],
            [
              -79.165994,
              37.442411
            ],
            [
              -79.185328,
              37.4591
            ],
            [
              -79.185702,
              37.46582
            ],
            [
              -79.210023,
              37.468709
            ],
            [
              -79.246499,
              37.447286
            ],
            [
              -79.266986,
              37.447868
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "031",
      "COUNTYNS": "01674917",
      "AFFGEOID": "0500000US51031",
      "GEOID": "51031",
      "NAME": "Campbell",
      "LSAD": "06",
      "ALAND": 1303240046,
      "AWATER": 8955341,
      "County_state": "Campbell,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.442859,
              37.055867
            ],
            [
              -79.404165,
              37.064161
            ],
            [
              -79.404731,
              37.091777
            ],
            [
              -79.387549,
              37.092948
            ],
            [
              -79.369724,
              37.11048
            ],
            [
              -79.383492,
              37.117193
            ],
            [
              -79.344654,
              37.125725
            ],
            [
              -79.341422,
              37.137166
            ],
            [
              -79.332161,
              37.125568
            ],
            [
              -79.295256,
              37.104805
            ],
            [
              -79.272657,
              37.111164
            ],
            [
              -79.259288,
              37.131076
            ],
            [
              -79.244166,
              37.124307
            ],
            [
              -79.237163,
              37.104174
            ],
            [
              -79.222058,
              37.10139
            ],
            [
              -79.222681,
              37.111547
            ],
            [
              -79.213032,
              37.117902
            ],
            [
              -79.200845,
              37.098401
            ],
            [
              -79.205975,
              37.076588
            ],
            [
              -79.193115,
              37.054637
            ],
            [
              -79.182374,
              37.069618
            ],
            [
              -79.143478,
              37.075852
            ],
            [
              -79.127139,
              37.089864
            ],
            [
              -79.10546,
              37.067262
            ],
            [
              -79.092264,
              37.061498
            ],
            [
              -79.058728,
              37.046083
            ],
            [
              -79.015081,
              37.038976
            ],
            [
              -79.001608,
              37.030975
            ],
            [
              -78.971675,
              37.05006
            ],
            [
              -78.904587,
              37.022288
            ],
            [
              -78.902227,
              37.026799
            ],
            [
              -78.824209,
              37.205361
            ],
            [
              -78.856928,
              37.212008
            ],
            [
              -78.863198,
              37.24049
            ],
            [
              -78.892712,
              37.248638
            ],
            [
              -78.919869,
              37.245545
            ],
            [
              -78.92646,
              37.277071
            ],
            [
              -78.941103,
              37.284026
            ],
            [
              -78.949703,
              37.292501
            ],
            [
              -78.953447,
              37.317112
            ],
            [
              -79.021687,
              37.429243
            ],
            [
              -79.040766,
              37.433677
            ],
            [
              -79.045476,
              37.416111
            ],
            [
              -79.070862,
              37.421341
            ],
            [
              -79.060719,
              37.390835
            ],
            [
              -79.068536,
              37.385827
            ],
            [
              -79.085123,
              37.394875
            ],
            [
              -79.119399,
              37.368736
            ],
            [
              -79.130643,
              37.375101
            ],
            [
              -79.136925,
              37.374154
            ],
            [
              -79.176879,
              37.342163
            ],
            [
              -79.197451,
              37.344541
            ],
            [
              -79.214924,
              37.33258
            ],
            [
              -79.257955,
              37.355816
            ],
            [
              -79.294847,
              37.297116
            ],
            [
              -79.330477,
              37.238418
            ],
            [
              -79.442859,
              37.055867
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "710",
      "COUNTYNS": "01498557",
      "AFFGEOID": "0500000US51710",
      "GEOID": "51710",
      "NAME": "Norfolk",
      "LSAD": "25",
      "ALAND": 137985835,
      "AWATER": 111697132,
      "County_state": "Norfolk,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.333598,
              36.882216
            ],
            [
              -76.336784,
              36.875027
            ],
            [
              -76.313533,
              36.858533
            ],
            [
              -76.29796,
              36.846412
            ],
            [
              -76.295863,
              36.843847
            ],
            [
              -76.294962,
              36.833348
            ],
            [
              -76.289962,
              36.822048
            ],
            [
              -76.272416,
              36.82714
            ],
            [
              -76.257475,
              36.820757
            ],
            [
              -76.243765,
              36.836862
            ],
            [
              -76.22606,
              36.839947
            ],
            [
              -76.213959,
              36.832347
            ],
            [
              -76.197259,
              36.828148
            ],
            [
              -76.177637,
              36.854364
            ],
            [
              -76.193259,
              36.865247
            ],
            [
              -76.193122,
              36.877425
            ],
            [
              -76.182012,
              36.906668
            ],
            [
              -76.187011,
              36.91055
            ],
            [
              -76.1892,
              36.912034
            ],
            [
              -76.190231,
              36.91329
            ],
            [
              -76.192547,
              36.918077
            ],
            [
              -76.18915,
              36.918978
            ],
            [
              -76.1861912090013,
              36.9308190597518
            ],
            [
              -76.189959,
              36.931447
            ],
            [
              -76.2115486629688,
              36.936963427559
            ],
            [
              -76.22166,
              36.939547
            ],
            [
              -76.234961,
              36.945147
            ],
            [
              -76.2638977226416,
              36.9621577699538
            ],
            [
              -76.267962,
              36.964547
            ],
            [
              -76.285063,
              36.968747
            ],
            [
              -76.297663,
              36.968147
            ],
            [
              -76.29790385215729,
              36.9677788544333
            ],
            [
              -76.299364,
              36.965547
            ],
            [
              -76.315867,
              36.955351
            ],
            [
              -76.322764,
              36.959147
            ],
            [
              -76.327365,
              36.959447
            ],
            [
              -76.330765,
              36.938647
            ],
            [
              -76.328279,
              36.915029
            ],
            [
              -76.326021,
              36.903722
            ],
            [
              -76.3187362851182,
              36.9029643504213
            ],
            [
              -76.314137,
              36.902486
            ],
            [
              -76.314731,
              36.896024
            ],
            [
              -76.3149098130865,
              36.895441836600895
            ],
            [
              -76.318059,
              36.885189
            ],
            [
              -76.33049222625239,
              36.8891435854775
            ],
            [
              -76.333598,
              36.882216
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "735",
      "COUNTYNS": "01498436",
      "AFFGEOID": "0500000US51735",
      "GEOID": "51735",
      "NAME": "Poquoson",
      "LSAD": "25",
      "ALAND": 39839069,
      "AWATER": 163454357,
      "County_state": "Poquoson,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.412885,
              37.155808
            ],
            [
              -76.410113,
              37.125158
            ],
            [
              -76.405844,
              37.125577
            ],
            [
              -76.401761,
              37.119233
            ],
            [
              -76.395795,
              37.107174
            ],
            [
              -76.38301,
              37.112093
            ],
            [
              -76.350966,
              37.106744
            ],
            [
              -76.344666,
              37.096844
            ],
            [
              -76.311744,
              37.102
            ],
            [
              -76.301564,
              37.103244
            ],
            [
              -76.2872356344809,
              37.1174527389092
            ],
            [
              -76.292344,
              37.126615
            ],
            [
              -76.311088,
              37.138495
            ],
            [
              -76.324353,
              37.142895
            ],
            [
              -76.330481,
              37.141727
            ],
            [
              -76.334017,
              37.144223
            ],
            [
              -76.340129,
              37.151823
            ],
            [
              -76.34405,
              37.160367
            ],
            [
              -76.344898,
              37.164479
            ],
            [
              -76.343234,
              37.166207
            ],
            [
              -76.348658,
              37.170655
            ],
            [
              -76.35969,
              37.16858
            ],
            [
              -76.375255,
              37.16084
            ],
            [
              -76.381379,
              37.155711
            ],
            [
              -76.394756,
              37.157568
            ],
            [
              -76.399659,
              37.160272
            ],
            [
              -76.3978831556465,
              37.1644153551795
            ],
            [
              -76.412885,
              37.155808
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "740",
      "COUNTYNS": "01498556",
      "AFFGEOID": "0500000US51740",
      "GEOID": "51740",
      "NAME": "Portsmouth",
      "LSAD": "25",
      "ALAND": 86247836,
      "AWATER": 34658655,
      "County_state": "Portsmouth,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.413434,
              36.874257
            ],
            [
              -76.420368,
              36.866348
            ],
            [
              -76.398475,
              36.865079
            ],
            [
              -76.398976,
              36.861012
            ],
            [
              -76.387421,
              36.852199
            ],
            [
              -76.383466,
              36.837548
            ],
            [
              -76.403666,
              36.814048
            ],
            [
              -76.400265,
              36.806437
            ],
            [
              -76.386998,
              36.798547
            ],
            [
              -76.375679,
              36.786062
            ],
            [
              -76.363839,
              36.787804
            ],
            [
              -76.292144,
              36.798663
            ],
            [
              -76.289962,
              36.822048
            ],
            [
              -76.294962,
              36.833348
            ],
            [
              -76.295863,
              36.843847
            ],
            [
              -76.29796,
              36.846412
            ],
            [
              -76.313533,
              36.858533
            ],
            [
              -76.336784,
              36.875027
            ],
            [
              -76.333598,
              36.882216
            ],
            [
              -76.33049222625239,
              36.8891435854775
            ],
            [
              -76.349433,
              36.895168
            ],
            [
              -76.353765,
              36.922747
            ],
            [
              -76.385867,
              36.923247
            ],
            [
              -76.387567,
              36.899547
            ],
            [
              -76.4069080052877,
              36.8975071738154
            ],
            [
              -76.403942,
              36.89396
            ],
            [
              -76.405321,
              36.881113
            ],
            [
              -76.413434,
              36.874257
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "041",
      "COUNTYNS": "01480111",
      "AFFGEOID": "0500000US51041",
      "GEOID": "51041",
      "NAME": "Chesterfield",
      "LSAD": "06",
      "ALAND": 1096572169,
      "AWATER": 35123317,
      "County_state": "Chesterfield,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.867779,
              37.394498
            ],
            [
              -77.877556,
              37.366247
            ],
            [
              -77.851804,
              37.354867
            ],
            [
              -77.837346,
              37.33838
            ],
            [
              -77.797462,
              37.333712
            ],
            [
              -77.808857,
              37.31599
            ],
            [
              -77.80675,
              37.304526
            ],
            [
              -77.769434,
              37.284982
            ],
            [
              -77.761396,
              37.269686
            ],
            [
              -77.738024,
              37.270358
            ],
            [
              -77.734912,
              37.284575
            ],
            [
              -77.717442,
              37.284855
            ],
            [
              -77.716211,
              37.296878
            ],
            [
              -77.701485,
              37.29613
            ],
            [
              -77.693035,
              37.281556
            ],
            [
              -77.669158,
              37.29285
            ],
            [
              -77.6522,
              37.265822
            ],
            [
              -77.65061,
              37.265112
            ],
            [
              -77.613349,
              37.270877
            ],
            [
              -77.589159,
              37.246285
            ],
            [
              -77.57794,
              37.237334
            ],
            [
              -77.57212,
              37.23712
            ],
            [
              -77.5604,
              37.225661
            ],
            [
              -77.536115,
              37.226335
            ],
            [
              -77.521624,
              37.216994
            ],
            [
              -77.500625,
              37.224087
            ],
            [
              -77.447408,
              37.223066
            ],
            [
              -77.433359,
              37.22503
            ],
            [
              -77.416501,
              37.233208
            ],
            [
              -77.41854,
              37.265901
            ],
            [
              -77.426774,
              37.269139
            ],
            [
              -77.431794,
              37.275912
            ],
            [
              -77.410102,
              37.282377
            ],
            [
              -77.39934,
              37.272215
            ],
            [
              -77.389149,
              37.287357
            ],
            [
              -77.368203,
              37.298549
            ],
            [
              -77.362322,
              37.282162
            ],
            [
              -77.379193,
              37.26758
            ],
            [
              -77.382521,
              37.242837
            ],
            [
              -77.403671,
              37.237346
            ],
            [
              -77.402732,
              37.236069
            ],
            [
              -77.385168,
              37.23839
            ],
            [
              -77.376785,
              37.244849
            ],
            [
              -77.373735,
              37.266949
            ],
            [
              -77.356582,
              37.283125
            ],
            [
              -77.350326,
              37.311925
            ],
            [
              -77.334549,
              37.312468
            ],
            [
              -77.29627,
              37.30917
            ],
            [
              -77.284019,
              37.313012
            ],
            [
              -77.281955,
              37.319012
            ],
            [
              -77.270675,
              37.320756
            ],
            [
              -77.264595,
              37.344052
            ],
            [
              -77.244167,
              37.369274
            ],
            [
              -77.249665,
              37.382
            ],
            [
              -77.263818,
              37.378289
            ],
            [
              -77.267026,
              37.36071
            ],
            [
              -77.279612,
              37.35207
            ],
            [
              -77.304991,
              37.358519
            ],
            [
              -77.311526,
              37.373278
            ],
            [
              -77.298205,
              37.392087
            ],
            [
              -77.299267,
              37.404683
            ],
            [
              -77.327495,
              37.377455
            ],
            [
              -77.357707,
              37.383534
            ],
            [
              -77.386654,
              37.387134
            ],
            [
              -77.398755,
              37.420958
            ],
            [
              -77.410711,
              37.422141
            ],
            [
              -77.418589,
              37.421256
            ],
            [
              -77.428993,
              37.430665
            ],
            [
              -77.420875,
              37.447101
            ],
            [
              -77.435488,
              37.457477
            ],
            [
              -77.450158,
              37.460577
            ],
            [
              -77.459383,
              37.464327
            ],
            [
              -77.486388,
              37.459024
            ],
            [
              -77.489098,
              37.455644
            ],
            [
              -77.500739,
              37.464014
            ],
            [
              -77.501545,
              37.464425
            ],
            [
              -77.507174,
              37.467704
            ],
            [
              -77.523552,
              37.498972
            ],
            [
              -77.523176,
              37.5019
            ],
            [
              -77.527655,
              37.514809
            ],
            [
              -77.527016,
              37.51521
            ],
            [
              -77.526868,
              37.515426
            ],
            [
              -77.526715,
              37.515993
            ],
            [
              -77.527404,
              37.516082
            ],
            [
              -77.527464,
              37.516361
            ],
            [
              -77.527912,
              37.517018
            ],
            [
              -77.528746,
              37.531904
            ],
            [
              -77.545036,
              37.528101
            ],
            [
              -77.55452,
              37.543028
            ],
            [
              -77.596849,
              37.534652
            ],
            [
              -77.595462,
              37.55575
            ],
            [
              -77.614082,
              37.556135
            ],
            [
              -77.622,
              37.55688
            ],
            [
              -77.649239,
              37.559613
            ],
            [
              -77.655399,
              37.563986
            ],
            [
              -77.657582,
              37.559933
            ],
            [
              -77.676264,
              37.546678
            ],
            [
              -77.762624,
              37.484492
            ],
            [
              -77.79784,
              37.458961
            ],
            [
              -77.809003,
              37.426234
            ],
            [
              -77.855148,
              37.418363
            ],
            [
              -77.875093,
              37.417137
            ],
            [
              -77.867779,
              37.394498
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "800",
      "COUNTYNS": "01498560",
      "AFFGEOID": "0500000US51800",
      "GEOID": "51800",
      "NAME": "Suffolk",
      "LSAD": "25",
      "ALAND": 1033802700,
      "AWATER": 77061968,
      "County_state": "Suffolk,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.930936,
              36.559569
            ],
            [
              -76.915897,
              36.552093
            ],
            [
              -76.807078,
              36.550606
            ],
            [
              -76.781296,
              36.550659
            ],
            [
              -76.749678,
              36.550381
            ],
            [
              -76.738329,
              36.550985
            ],
            [
              -76.575496,
              36.550744
            ],
            [
              -76.5579380488928,
              36.5505196504221
            ],
            [
              -76.541687,
              36.550312
            ],
            [
              -76.5415364676522,
              36.55031325872059
            ],
            [
              -76.491482764957,
              36.5507317975136
            ],
            [
              -76.482069,
              36.62855
            ],
            [
              -76.466776,
              36.746184
            ],
            [
              -76.466768,
              36.746249
            ],
            [
              -76.463398,
              36.77046
            ],
            [
              -76.457469,
              36.814841
            ],
            [
              -76.456168,
              36.816948
            ],
            [
              -76.44067,
              36.841553
            ],
            [
              -76.438568,
              36.845648
            ],
            [
              -76.420368,
              36.866348
            ],
            [
              -76.413434,
              36.874257
            ],
            [
              -76.405321,
              36.881113
            ],
            [
              -76.403942,
              36.89396
            ],
            [
              -76.4069080052877,
              36.8975071738154
            ],
            [
              -76.407507,
              36.897444
            ],
            [
              -76.4232445599545,
              36.902148078984396
            ],
            [
              -76.43122,
              36.904532
            ],
            [
              -76.441605,
              36.906116
            ],
            [
              -76.447413,
              36.90322
            ],
            [
              -76.453941,
              36.89274
            ],
            [
              -76.45329,
              36.887031
            ],
            [
              -76.454692,
              36.884077
            ],
            [
              -76.469914,
              36.882898
            ],
            [
              -76.4786792132429,
              36.891588948337
            ],
            [
              -76.483369,
              36.896239
            ],
            [
              -76.482135,
              36.901108
            ],
            [
              -76.482407,
              36.917364
            ],
            [
              -76.48410706581349,
              36.928916
            ],
            [
              -76.501754,
              36.913646
            ],
            [
              -76.521099,
              36.90633
            ],
            [
              -76.529279,
              36.916698
            ],
            [
              -76.581707,
              36.879413
            ],
            [
              -76.691033,
              36.796239
            ],
            [
              -76.747665,
              36.752616
            ],
            [
              -76.813535,
              36.706437
            ],
            [
              -76.860938,
              36.670658
            ],
            [
              -76.897592,
              36.64405
            ],
            [
              -76.893351,
              36.626393
            ],
            [
              -76.910964,
              36.619229
            ],
            [
              -76.909268,
              36.579747
            ],
            [
              -76.930936,
              36.559569
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "045",
      "COUNTYNS": "01673664",
      "AFFGEOID": "0500000US51045",
      "GEOID": "51045",
      "NAME": "Craig",
      "LSAD": "06",
      "ALAND": 849759834,
      "AWATER": 2803739,
      "County_state": "Craig,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.46482,
              37.426144
            ],
            [
              -80.4727968266129,
              37.4237800299575
            ],
            [
              -80.436053,
              37.339744
            ],
            [
              -80.443721,
              37.321124
            ],
            [
              -80.430943,
              37.316378
            ],
            [
              -80.351522,
              37.349193
            ],
            [
              -80.32484,
              37.368833
            ],
            [
              -80.262184,
              37.341526
            ],
            [
              -80.246508,
              37.353942
            ],
            [
              -80.200301,
              37.378356
            ],
            [
              -80.147679,
              37.382492
            ],
            [
              -80.074119,
              37.422469
            ],
            [
              -80.047594,
              37.443103
            ],
            [
              -80.03046,
              37.475504
            ],
            [
              -80.014433,
              37.494846
            ],
            [
              -80.009496,
              37.510827
            ],
            [
              -79.969071,
              37.544408
            ],
            [
              -80.027621,
              37.624218
            ],
            [
              -80.032033,
              37.638563
            ],
            [
              -80.020554,
              37.647442
            ],
            [
              -80.032705,
              37.66029
            ],
            [
              -80.087462,
              37.626671
            ],
            [
              -80.104798,
              37.62269
            ],
            [
              -80.145565,
              37.596428
            ],
            [
              -80.168436,
              37.615376
            ],
            [
              -80.205198,
              37.616293
            ],
            [
              -80.220984,
              37.627767
            ],
            [
              -80.223386,
              37.623185
            ],
            [
              -80.226017,
              37.620059
            ],
            [
              -80.240272,
              37.606961
            ],
            [
              -80.24978,
              37.602117
            ],
            [
              -80.258919,
              37.595499
            ],
            [
              -80.26356,
              37.593374
            ],
            [
              -80.270342,
              37.591149
            ],
            [
              -80.28244,
              37.585481
            ],
            [
              -80.294882,
              37.57877
            ],
            [
              -80.328504,
              37.564315
            ],
            [
              -80.312393,
              37.546239
            ],
            [
              -80.314464,
              37.54412
            ],
            [
              -80.321249,
              37.541419
            ],
            [
              -80.324384,
              37.541052
            ],
            [
              -80.327489,
              37.540022
            ],
            [
              -80.330306,
              37.536244
            ],
            [
              -80.309346,
              37.527381
            ],
            [
              -80.291644,
              37.536505
            ],
            [
              -80.282385,
              37.533517
            ],
            [
              -80.299789,
              37.508271
            ],
            [
              -80.309833,
              37.503827
            ],
            [
              -80.309331,
              37.50288
            ],
            [
              -80.314806,
              37.500943
            ],
            [
              -80.320627,
              37.49888
            ],
            [
              -80.327103,
              37.495376
            ],
            [
              -80.332038,
              37.493744
            ],
            [
              -80.343789,
              37.492148
            ],
            [
              -80.366838,
              37.484879
            ],
            [
              -80.36317,
              37.480001
            ],
            [
              -80.369449,
              37.476599
            ],
            [
              -80.371952,
              37.474069
            ],
            [
              -80.378308,
              37.471381
            ],
            [
              -80.382535,
              37.470367
            ],
            [
              -80.39988,
              37.462314
            ],
            [
              -80.402816,
              37.460322
            ],
            [
              -80.425656,
              37.449876
            ],
            [
              -80.443025,
              37.438126
            ],
            [
              -80.451367,
              37.434039
            ],
            [
              -80.457313,
              37.432267
            ],
            [
              -80.46482,
              37.426144
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "069",
      "COUNTYNS": "01480124",
      "AFFGEOID": "0500000US51069",
      "GEOID": "51069",
      "NAME": "Frederick",
      "LSAD": "06",
      "ALAND": 1069672126,
      "AWATER": 6364651,
      "County_state": "Frederick,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.540216,
              39.060631
            ],
            [
              -78.5441928910609,
              39.056569664611196
            ],
            [
              -78.454136,
              39.027797
            ],
            [
              -78.444811,
              39.047234
            ],
            [
              -78.448199,
              39.056038
            ],
            [
              -78.430937,
              39.066333
            ],
            [
              -78.418053,
              39.086166
            ],
            [
              -78.391624,
              39.101897
            ],
            [
              -78.37587,
              39.092066
            ],
            [
              -78.343927,
              39.095749
            ],
            [
              -78.336648,
              39.101997
            ],
            [
              -78.336723,
              39.084438
            ],
            [
              -78.324474,
              39.075729
            ],
            [
              -78.341692,
              39.069505
            ],
            [
              -78.347186,
              39.059724
            ],
            [
              -78.330052,
              39.049764
            ],
            [
              -78.313966,
              39.02484
            ],
            [
              -78.31408,
              39.007994
            ],
            [
              -78.163871,
              39.017928
            ],
            [
              -78.151614,
              39.036629
            ],
            [
              -78.142073,
              39.061735
            ],
            [
              -78.125416,
              39.089187
            ],
            [
              -78.109794,
              39.095822
            ],
            [
              -78.107,
              39.120805
            ],
            [
              -78.090269,
              39.147661
            ],
            [
              -78.085094,
              39.158536
            ],
            [
              -78.073001,
              39.178085
            ],
            [
              -78.074694,
              39.204942
            ],
            [
              -78.04254,
              39.236631
            ],
            [
              -78.0331853088999,
              39.2646209889705
            ],
            [
              -78.0331865984578,
              39.2646218054158
            ],
            [
              -78.0800128545788,
              39.2942684609775
            ],
            [
              -78.0816712960184,
              39.295318453998505
            ],
            [
              -78.108746,
              39.31246
            ],
            [
              -78.14092,
              39.333745
            ],
            [
              -78.158194,
              39.343392
            ],
            [
              -78.18737,
              39.363989
            ],
            [
              -78.205401,
              39.375099
            ],
            [
              -78.228766,
              39.391233
            ],
            [
              -78.262785,
              39.414323
            ],
            [
              -78.28798,
              39.428755
            ],
            [
              -78.34703569532559,
              39.465979661051094
            ],
            [
              -78.347087,
              39.466012
            ],
            [
              -78.349476,
              39.462205
            ],
            [
              -78.345143,
              39.459484
            ],
            [
              -78.345823,
              39.453499
            ],
            [
              -78.346962,
              39.450679
            ],
            [
              -78.347333,
              39.447659
            ],
            [
              -78.346061,
              39.445613
            ],
            [
              -78.346546,
              39.442616
            ],
            [
              -78.347773,
              39.440583
            ],
            [
              -78.353227,
              39.436792
            ],
            [
              -78.347942,
              39.430879
            ],
            [
              -78.346718,
              39.427618
            ],
            [
              -78.348354,
              39.424431
            ],
            [
              -78.351236,
              39.420595
            ],
            [
              -78.356627,
              39.415902
            ],
            [
              -78.359352,
              39.412534
            ],
            [
              -78.359918,
              39.409028
            ],
            [
              -78.357949,
              39.404192
            ],
            [
              -78.349436,
              39.397252
            ],
            [
              -78.350014,
              39.392861
            ],
            [
              -78.343214,
              39.388807
            ],
            [
              -78.354837,
              39.371616
            ],
            [
              -78.362632,
              39.362888
            ],
            [
              -78.366867,
              39.35929
            ],
            [
              -78.362267,
              39.357784
            ],
            [
              -78.348698,
              39.354744
            ],
            [
              -78.34048,
              39.353492
            ],
            [
              -78.338958,
              39.349889
            ],
            [
              -78.339284,
              39.348605
            ],
            [
              -78.341308,
              39.345555
            ],
            [
              -78.342514,
              39.346769
            ],
            [
              -78.343685,
              39.346713
            ],
            [
              -78.347409,
              39.343402
            ],
            [
              -78.347634,
              39.34272
            ],
            [
              -78.34546,
              39.341024
            ],
            [
              -78.346301,
              39.339108
            ],
            [
              -78.35894,
              39.319484
            ],
            [
              -78.360035,
              39.317771
            ],
            [
              -78.361567,
              39.318037
            ],
            [
              -78.364686,
              39.317312
            ],
            [
              -78.367242,
              39.310148
            ],
            [
              -78.371604,
              39.307692
            ],
            [
              -78.374728,
              39.305136
            ],
            [
              -78.385888,
              39.294888
            ],
            [
              -78.387242,
              39.29343
            ],
            [
              -78.387194,
              39.291444
            ],
            [
              -78.388785,
              39.288572
            ],
            [
              -78.393371,
              39.282988
            ],
            [
              -78.398682,
              39.281332
            ],
            [
              -78.402275,
              39.277238
            ],
            [
              -78.401813,
              39.276754
            ],
            [
              -78.402783,
              39.275471
            ],
            [
              -78.414204,
              39.26391
            ],
            [
              -78.419422,
              39.257476
            ],
            [
              -78.418584,
              39.256065
            ],
            [
              -78.41612,
              39.25541
            ],
            [
              -78.414631,
              39.255733
            ],
            [
              -78.409116,
              39.252564
            ],
            [
              -78.399785,
              39.244129
            ],
            [
              -78.399669,
              39.243874
            ],
            [
              -78.404214,
              39.241214
            ],
            [
              -78.40498,
              39.238006
            ],
            [
              -78.405585,
              39.231176
            ],
            [
              -78.41789,
              39.217504
            ],
            [
              -78.423968,
              39.212049
            ],
            [
              -78.427911,
              39.208611
            ],
            [
              -78.429803,
              39.207014
            ],
            [
              -78.431167,
              39.205744
            ],
            [
              -78.43213,
              39.204717
            ],
            [
              -78.437053,
              39.199766
            ],
            [
              -78.438651,
              39.198049
            ],
            [
              -78.436662,
              39.196658
            ],
            [
              -78.430846,
              39.196227
            ],
            [
              -78.424905,
              39.193301
            ],
            [
              -78.424292,
              39.192156
            ],
            [
              -78.428697,
              39.187217
            ],
            [
              -78.426315,
              39.182762
            ],
            [
              -78.411972,
              39.172734
            ],
            [
              -78.406966,
              39.170903
            ],
            [
              -78.403697,
              39.167451
            ],
            [
              -78.412599,
              39.160038
            ],
            [
              -78.413943,
              39.158415
            ],
            [
              -78.427294,
              39.152726
            ],
            [
              -78.436658,
              39.141691
            ],
            [
              -78.437771,
              39.135426
            ],
            [
              -78.439429,
              39.132146
            ],
            [
              -78.459869,
              39.113351
            ],
            [
              -78.466662,
              39.112858
            ],
            [
              -78.46953,
              39.111204
            ],
            [
              -78.470261,
              39.110063
            ],
            [
              -78.473209,
              39.108143
            ],
            [
              -78.475376,
              39.107469
            ],
            [
              -78.47732,
              39.109398
            ],
            [
              -78.478426,
              39.109843
            ],
            [
              -78.484283,
              39.107372
            ],
            [
              -78.49516,
              39.100992
            ],
            [
              -78.495984,
              39.09898
            ],
            [
              -78.504384,
              39.091398
            ],
            [
              -78.508132,
              39.08863
            ],
            [
              -78.512103,
              39.084878
            ],
            [
              -78.516479,
              39.081802
            ],
            [
              -78.515955,
              39.080046
            ],
            [
              -78.516789,
              39.077569
            ],
            [
              -78.522714,
              39.071062
            ],
            [
              -78.526543,
              39.068221
            ],
            [
              -78.531695,
              39.066519
            ],
            [
              -78.540216,
              39.060631
            ]
          ],
          [
            [
              -78.196629,
              39.142151
            ],
            [
              -78.189348,
              39.175189
            ],
            [
              -78.187794,
              39.20487
            ],
            [
              -78.155418,
              39.20097
            ],
            [
              -78.136725,
              39.184371
            ],
            [
              -78.148026,
              39.175899
            ],
            [
              -78.17316,
              39.141035
            ],
            [
              -78.183145,
              39.131065
            ],
            [
              -78.196629,
              39.142151
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "550",
      "COUNTYNS": "01498558",
      "AFFGEOID": "0500000US51550",
      "GEOID": "51550",
      "NAME": "Chesapeake",
      "LSAD": "25",
      "ALAND": 876737781,
      "AWATER": 32210024,
      "County_state": "Chesapeake,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.482069,
              36.62855
            ],
            [
              -76.491482764957,
              36.5507317975136
            ],
            [
              -76.465268,
              36.550951
            ],
            [
              -76.3766927385896,
              36.5507179884543
            ],
            [
              -76.3133029178411,
              36.5505512312821
            ],
            [
              -76.313215,
              36.550551
            ],
            [
              -76.1223521611215,
              36.55062091212349
            ],
            [
              -76.122364,
              36.645709
            ],
            [
              -76.121955,
              36.66575
            ],
            [
              -76.069153,
              36.67745
            ],
            [
              -76.065553,
              36.68555
            ],
            [
              -76.082154,
              36.71245
            ],
            [
              -76.098455,
              36.71675
            ],
            [
              -76.100054,
              36.718177
            ],
            [
              -76.143253,
              36.757045
            ],
            [
              -76.186531,
              36.763747
            ],
            [
              -76.195246,
              36.76692
            ],
            [
              -76.2037,
              36.769742
            ],
            [
              -76.212882,
              36.794165
            ],
            [
              -76.22806,
              36.832874
            ],
            [
              -76.22606,
              36.839947
            ],
            [
              -76.243765,
              36.836862
            ],
            [
              -76.257475,
              36.820757
            ],
            [
              -76.272416,
              36.82714
            ],
            [
              -76.289962,
              36.822048
            ],
            [
              -76.292144,
              36.798663
            ],
            [
              -76.363839,
              36.787804
            ],
            [
              -76.375679,
              36.786062
            ],
            [
              -76.386998,
              36.798547
            ],
            [
              -76.400265,
              36.806437
            ],
            [
              -76.403666,
              36.814048
            ],
            [
              -76.383466,
              36.837548
            ],
            [
              -76.387421,
              36.852199
            ],
            [
              -76.398976,
              36.861012
            ],
            [
              -76.398475,
              36.865079
            ],
            [
              -76.420368,
              36.866348
            ],
            [
              -76.438568,
              36.845648
            ],
            [
              -76.44067,
              36.841553
            ],
            [
              -76.456168,
              36.816948
            ],
            [
              -76.457469,
              36.814841
            ],
            [
              -76.463398,
              36.77046
            ],
            [
              -76.466768,
              36.746249
            ],
            [
              -76.466776,
              36.746184
            ],
            [
              -76.482069,
              36.62855
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "053",
      "COUNTYNS": "01690739",
      "AFFGEOID": "0500000US51053",
      "GEOID": "51053",
      "NAME": "Dinwiddie",
      "LSAD": "06",
      "ALAND": 1305041272,
      "AWATER": 9004891,
      "County_state": "Dinwiddie,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.900254,
              37.143885
            ],
            [
              -77.889813,
              36.988818
            ],
            [
              -77.850695,
              36.988107
            ],
            [
              -77.840869,
              36.995934
            ],
            [
              -77.810111,
              36.983378
            ],
            [
              -77.794796,
              36.984453
            ],
            [
              -77.76554,
              36.974655
            ],
            [
              -77.745863,
              36.959042
            ],
            [
              -77.715683,
              36.927227
            ],
            [
              -77.713541,
              36.914139
            ],
            [
              -77.676526,
              36.904395
            ],
            [
              -77.658036,
              36.894514
            ],
            [
              -77.648037,
              36.896884
            ],
            [
              -77.639169,
              36.873752
            ],
            [
              -77.631207,
              36.88761
            ],
            [
              -77.619029,
              36.877844
            ],
            [
              -77.398498,
              36.992985
            ],
            [
              -77.394645,
              37.035734
            ],
            [
              -77.397418,
              37.157348
            ],
            [
              -77.399203,
              37.17085
            ],
            [
              -77.418422,
              37.166111
            ],
            [
              -77.428069,
              37.185019
            ],
            [
              -77.454916,
              37.187616
            ],
            [
              -77.441467,
              37.212231
            ],
            [
              -77.447408,
              37.223066
            ],
            [
              -77.500625,
              37.224087
            ],
            [
              -77.521624,
              37.216994
            ],
            [
              -77.536115,
              37.226335
            ],
            [
              -77.5604,
              37.225661
            ],
            [
              -77.57212,
              37.23712
            ],
            [
              -77.57794,
              37.237334
            ],
            [
              -77.589159,
              37.246285
            ],
            [
              -77.613349,
              37.270877
            ],
            [
              -77.65061,
              37.265112
            ],
            [
              -77.660687,
              37.241815
            ],
            [
              -77.686903,
              37.235293
            ],
            [
              -77.691125,
              37.221317
            ],
            [
              -77.711099,
              37.218316
            ],
            [
              -77.747693,
              37.192729
            ],
            [
              -77.795926,
              37.192592
            ],
            [
              -77.805982,
              37.175933
            ],
            [
              -77.824987,
              37.167201
            ],
            [
              -77.841723,
              37.169839
            ],
            [
              -77.870524,
              37.150368
            ],
            [
              -77.900254,
              37.143885
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "145",
      "COUNTYNS": "01674225",
      "AFFGEOID": "0500000US51145",
      "GEOID": "51145",
      "NAME": "Powhatan",
      "LSAD": "06",
      "ALAND": 673915707,
      "AWATER": 5449745,
      "County_state": "Powhatan,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.132048,
              37.454686
            ],
            [
              -78.12429,
              37.445398
            ],
            [
              -78.10115,
              37.448455
            ],
            [
              -78.091757,
              37.456102
            ],
            [
              -78.06658,
              37.452054
            ],
            [
              -78.050491,
              37.471672
            ],
            [
              -78.016851,
              37.471982
            ],
            [
              -78.014437,
              37.492081
            ],
            [
              -78,
              37.497971
            ],
            [
              -77.98619,
              37.486269
            ],
            [
              -77.955816,
              37.483274
            ],
            [
              -77.936683,
              37.475419
            ],
            [
              -77.924261,
              37.482055
            ],
            [
              -77.899761,
              37.475422
            ],
            [
              -77.897617,
              37.456778
            ],
            [
              -77.886352,
              37.449299
            ],
            [
              -77.865945,
              37.456312
            ],
            [
              -77.865117,
              37.437647
            ],
            [
              -77.855148,
              37.418363
            ],
            [
              -77.809003,
              37.426234
            ],
            [
              -77.79784,
              37.458961
            ],
            [
              -77.762624,
              37.484492
            ],
            [
              -77.676264,
              37.546678
            ],
            [
              -77.657582,
              37.559933
            ],
            [
              -77.655399,
              37.563986
            ],
            [
              -77.705448,
              37.589144
            ],
            [
              -77.732842,
              37.589958
            ],
            [
              -77.760528,
              37.61113
            ],
            [
              -77.792031,
              37.612726
            ],
            [
              -77.819899,
              37.608629
            ],
            [
              -77.831167,
              37.633587
            ],
            [
              -77.866105,
              37.649563
            ],
            [
              -77.889978,
              37.664245
            ],
            [
              -77.902238,
              37.673132
            ],
            [
              -77.910777,
              37.689289
            ],
            [
              -77.92621,
              37.690349
            ],
            [
              -77.947379,
              37.678087
            ],
            [
              -77.956033,
              37.660812
            ],
            [
              -77.94429,
              37.643772
            ],
            [
              -77.956951,
              37.624938
            ],
            [
              -77.982236,
              37.624279
            ],
            [
              -78.017473,
              37.642564
            ],
            [
              -78.043292,
              37.642498
            ],
            [
              -78.06246,
              37.648028
            ],
            [
              -78.072174,
              37.657385
            ],
            [
              -78.089466,
              37.639415
            ],
            [
              -78.087488,
              37.628044
            ],
            [
              -78.132048,
              37.454686
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "081",
      "COUNTYNS": "01501122",
      "AFFGEOID": "0500000US51081",
      "GEOID": "51081",
      "NAME": "Greensville",
      "LSAD": "06",
      "ALAND": 764634553,
      "AWATER": 4086108,
      "County_state": "Greensville,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.76712325037539,
              36.545413754966894
            ],
            [
              -77.749706,
              36.54552
            ],
            [
              -77.2968751997552,
              36.5447391568792
            ],
            [
              -77.333014,
              36.557195
            ],
            [
              -77.355111,
              36.554122
            ],
            [
              -77.361537,
              36.575632
            ],
            [
              -77.35741,
              36.600785
            ],
            [
              -77.370699,
              36.606752
            ],
            [
              -77.37748,
              36.632865
            ],
            [
              -77.414985,
              36.654069
            ],
            [
              -77.461082,
              36.652627
            ],
            [
              -77.477025,
              36.668664
            ],
            [
              -77.501917,
              36.670506
            ],
            [
              -77.430127,
              36.707658
            ],
            [
              -77.489999,
              36.721009
            ],
            [
              -77.458678,
              36.862963
            ],
            [
              -77.475194,
              36.861127
            ],
            [
              -77.491243,
              36.848474
            ],
            [
              -77.51036,
              36.845082
            ],
            [
              -77.543073,
              36.8531
            ],
            [
              -77.557538,
              36.845572
            ],
            [
              -77.576445,
              36.851565
            ],
            [
              -77.594735,
              36.87108
            ],
            [
              -77.619029,
              36.877844
            ],
            [
              -77.631207,
              36.88761
            ],
            [
              -77.639169,
              36.873752
            ],
            [
              -77.648037,
              36.896884
            ],
            [
              -77.658036,
              36.894514
            ],
            [
              -77.656537,
              36.757269
            ],
            [
              -77.655841,
              36.713539
            ],
            [
              -77.76712325037539,
              36.545413754966894
            ]
          ],
          [
            [
              -77.563888,
              36.703067
            ],
            [
              -77.556573,
              36.713662
            ],
            [
              -77.521835,
              36.712827
            ],
            [
              -77.51445,
              36.720588
            ],
            [
              -77.517511,
              36.699562
            ],
            [
              -77.509246,
              36.682998
            ],
            [
              -77.541541,
              36.674703
            ],
            [
              -77.556524,
              36.674874
            ],
            [
              -77.563888,
              36.703067
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "085",
      "COUNTYNS": "01480132",
      "AFFGEOID": "0500000US51085",
      "GEOID": "51085",
      "NAME": "Hanover",
      "LSAD": "06",
      "ALAND": 1211828533,
      "AWATER": 12648364,
      "County_state": "Hanover,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.797306,
              37.729358
            ],
            [
              -77.784196,
              37.721625
            ],
            [
              -77.711709,
              37.706285
            ],
            [
              -77.659356,
              37.710204
            ],
            [
              -77.63067,
              37.705673
            ],
            [
              -77.610608,
              37.71041
            ],
            [
              -77.595394,
              37.697036
            ],
            [
              -77.557856,
              37.681919
            ],
            [
              -77.542686,
              37.686167
            ],
            [
              -77.530798,
              37.694566
            ],
            [
              -77.522216,
              37.700149
            ],
            [
              -77.499191,
              37.700921
            ],
            [
              -77.494891,
              37.701526
            ],
            [
              -77.479006,
              37.681396
            ],
            [
              -77.471659,
              37.687646
            ],
            [
              -77.451133,
              37.680272
            ],
            [
              -77.448572,
              37.684445
            ],
            [
              -77.42143,
              37.641682
            ],
            [
              -77.405382,
              37.626033
            ],
            [
              -77.407189,
              37.612524
            ],
            [
              -77.40382,
              37.605222
            ],
            [
              -77.305938,
              37.561011
            ],
            [
              -77.261366,
              37.549702
            ],
            [
              -77.240988,
              37.538088
            ],
            [
              -77.170941,
              37.596524
            ],
            [
              -77.140457,
              37.592169
            ],
            [
              -77.138029,
              37.616932
            ],
            [
              -77.123675,
              37.626881
            ],
            [
              -77.129528,
              37.634875
            ],
            [
              -77.117304,
              37.644048
            ],
            [
              -77.109171,
              37.662293
            ],
            [
              -77.134919,
              37.663712
            ],
            [
              -77.148618,
              37.674717
            ],
            [
              -77.15209,
              37.663839
            ],
            [
              -77.186674,
              37.657708
            ],
            [
              -77.191243,
              37.667438
            ],
            [
              -77.182661,
              37.683079
            ],
            [
              -77.194529,
              37.690649
            ],
            [
              -77.202388,
              37.678575
            ],
            [
              -77.214539,
              37.688959
            ],
            [
              -77.239674,
              37.685748
            ],
            [
              -77.243859,
              37.699191
            ],
            [
              -77.230528,
              37.713831
            ],
            [
              -77.236383,
              37.726171
            ],
            [
              -77.248211,
              37.73009
            ],
            [
              -77.248883,
              37.70921
            ],
            [
              -77.269475,
              37.723515
            ],
            [
              -77.279567,
              37.713844
            ],
            [
              -77.292404,
              37.714067
            ],
            [
              -77.293509,
              37.739804
            ],
            [
              -77.322496,
              37.733701
            ],
            [
              -77.320949,
              37.743277
            ],
            [
              -77.303874,
              37.74422
            ],
            [
              -77.303586,
              37.755136
            ],
            [
              -77.334409,
              37.754636
            ],
            [
              -77.321989,
              37.76983
            ],
            [
              -77.331753,
              37.791997
            ],
            [
              -77.345215,
              37.789175
            ],
            [
              -77.36233,
              37.797661
            ],
            [
              -77.373677,
              37.777013
            ],
            [
              -77.397317,
              37.777289
            ],
            [
              -77.403688,
              37.785925
            ],
            [
              -77.395243,
              37.802442
            ],
            [
              -77.40645,
              37.802837
            ],
            [
              -77.420313,
              37.815864
            ],
            [
              -77.411227,
              37.822992
            ],
            [
              -77.428267,
              37.829567
            ],
            [
              -77.428167,
              37.856461
            ],
            [
              -77.421989,
              37.864929
            ],
            [
              -77.436289,
              37.885215
            ],
            [
              -77.445516,
              37.894724
            ],
            [
              -77.48417,
              37.883369
            ],
            [
              -77.493333,
              37.897271
            ],
            [
              -77.514783,
              37.904329
            ],
            [
              -77.528882,
              37.9208
            ],
            [
              -77.546224,
              37.915966
            ],
            [
              -77.547938,
              37.926203
            ],
            [
              -77.561693,
              37.93606
            ],
            [
              -77.576728,
              37.958072
            ],
            [
              -77.581241,
              37.950597
            ],
            [
              -77.608288,
              37.954454
            ],
            [
              -77.643457,
              37.979708
            ],
            [
              -77.642518,
              37.990688
            ],
            [
              -77.659129,
              37.991814
            ],
            [
              -77.687469,
              38.007806
            ],
            [
              -77.743844,
              37.865066
            ],
            [
              -77.797306,
              37.729358
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "163",
      "COUNTYNS": "01480166",
      "AFFGEOID": "0500000US51163",
      "GEOID": "51163",
      "NAME": "Rockbridge",
      "LSAD": "06",
      "ALAND": 1544997746,
      "AWATER": 8878371,
      "County_state": "Rockbridge,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.68877,
              37.734747
            ],
            [
              -79.676341,
              37.698803
            ],
            [
              -79.667733,
              37.694509
            ],
            [
              -79.671834,
              37.674304
            ],
            [
              -79.683554,
              37.661763
            ],
            [
              -79.573231,
              37.595569
            ],
            [
              -79.577972,
              37.580746
            ],
            [
              -79.498699,
              37.532797
            ],
            [
              -79.478987,
              37.548379
            ],
            [
              -79.434068,
              37.572327
            ],
            [
              -79.446226,
              37.576255
            ],
            [
              -79.457967,
              37.605835
            ],
            [
              -79.436947,
              37.617057
            ],
            [
              -79.419635,
              37.625665
            ],
            [
              -79.40158,
              37.626049
            ],
            [
              -79.380353,
              37.642759
            ],
            [
              -79.365573,
              37.645697
            ],
            [
              -79.345235,
              37.660625
            ],
            [
              -79.340576,
              37.678634
            ],
            [
              -79.322317,
              37.692874
            ],
            [
              -79.308442,
              37.73433
            ],
            [
              -79.264674,
              37.794694
            ],
            [
              -79.231557,
              37.813175
            ],
            [
              -79.209372,
              37.789156
            ],
            [
              -79.199573,
              37.788119
            ],
            [
              -79.172131,
              37.803097
            ],
            [
              -79.144388,
              37.856096
            ],
            [
              -79.15739,
              37.891003
            ],
            [
              -79.183978,
              37.914194
            ],
            [
              -79.452367,
              38.068092
            ],
            [
              -79.482668,
              38.086054
            ],
            [
              -79.510286,
              38.051398
            ],
            [
              -79.524495,
              38.041123
            ],
            [
              -79.511144,
              38.035258
            ],
            [
              -79.53386,
              38.003321
            ],
            [
              -79.581865,
              37.954397
            ],
            [
              -79.609241,
              37.935486
            ],
            [
              -79.647589,
              37.874538
            ],
            [
              -79.62928,
              37.874908
            ],
            [
              -79.606341,
              37.862111
            ],
            [
              -79.625437,
              37.829726
            ],
            [
              -79.642641,
              37.814185
            ],
            [
              -79.674162,
              37.763093
            ],
            [
              -79.68877,
              37.734747
            ]
          ],
          [
            [
              -79.455667,
              37.765219
            ],
            [
              -79.461582,
              37.786356
            ],
            [
              -79.453328,
              37.793157
            ],
            [
              -79.414948,
              37.788627
            ],
            [
              -79.426063,
              37.785102
            ],
            [
              -79.455667,
              37.765219
            ]
          ],
          [
            [
              -79.369847,
              37.727556
            ],
            [
              -79.381509,
              37.742149
            ],
            [
              -79.348224,
              37.755482
            ],
            [
              -79.331017,
              37.759843
            ],
            [
              -79.326012,
              37.743269
            ],
            [
              -79.349678,
              37.719734
            ],
            [
              -79.371705,
              37.695747
            ],
            [
              -79.381361,
              37.706578
            ],
            [
              -79.369847,
              37.727556
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "177",
      "COUNTYNS": "01480172",
      "AFFGEOID": "0500000US51177",
      "GEOID": "51177",
      "NAME": "Spotsylvania",
      "LSAD": "06",
      "ALAND": 1039771765,
      "AWATER": 33231063,
      "County_state": "Spotsylvania,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.953639,
              38.118501
            ],
            [
              -77.916918,
              38.118336
            ],
            [
              -77.847017,
              38.098438
            ],
            [
              -77.837416,
              38.105736
            ],
            [
              -77.820297,
              38.089193
            ],
            [
              -77.786614,
              38.076037
            ],
            [
              -77.782514,
              38.067537
            ],
            [
              -77.784214,
              38.063137
            ],
            [
              -77.764024,
              38.053137
            ],
            [
              -77.745213,
              38.050837
            ],
            [
              -77.746913,
              38.023638
            ],
            [
              -77.739513,
              38.033038
            ],
            [
              -77.71189,
              38.012721
            ],
            [
              -77.687469,
              38.007806
            ],
            [
              -77.659129,
              37.991814
            ],
            [
              -77.642518,
              37.990688
            ],
            [
              -77.626219,
              38.003429
            ],
            [
              -77.551611,
              38.073609
            ],
            [
              -77.518179,
              38.10489
            ],
            [
              -77.440229,
              38.177318
            ],
            [
              -77.403132,
              38.214556
            ],
            [
              -77.370297,
              38.246576
            ],
            [
              -77.39085,
              38.245589
            ],
            [
              -77.420148,
              38.257986
            ],
            [
              -77.447126,
              38.284614
            ],
            [
              -77.457512,
              38.271402
            ],
            [
              -77.460561,
              38.277843
            ],
            [
              -77.473699,
              38.270329
            ],
            [
              -77.492015,
              38.273719
            ],
            [
              -77.512009,
              38.279871
            ],
            [
              -77.516623,
              38.29501
            ],
            [
              -77.514803,
              38.305331
            ],
            [
              -77.530283,
              38.309175
            ],
            [
              -77.54546,
              38.325081
            ],
            [
              -77.584673,
              38.346806
            ],
            [
              -77.603865,
              38.333578
            ],
            [
              -77.602568,
              38.348671
            ],
            [
              -77.618727,
              38.367835
            ],
            [
              -77.633418,
              38.360183
            ],
            [
              -77.645744,
              38.378107
            ],
            [
              -77.679539,
              38.359908
            ],
            [
              -77.702843,
              38.36084
            ],
            [
              -77.715081,
              38.335764
            ],
            [
              -77.747943,
              38.304837
            ],
            [
              -77.78589,
              38.269931
            ],
            [
              -77.789393,
              38.266818
            ],
            [
              -77.953639,
              38.118501
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "179",
      "COUNTYNS": "01480173",
      "AFFGEOID": "0500000US51179",
      "GEOID": "51179",
      "NAME": "Stafford",
      "LSAD": "06",
      "ALAND": 697237227,
      "AWATER": 28088013,
      "County_state": "Stafford,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.63494,
              38.410218
            ],
            [
              -77.632644,
              38.393006
            ],
            [
              -77.621011,
              38.383072
            ],
            [
              -77.618727,
              38.367835
            ],
            [
              -77.602568,
              38.348671
            ],
            [
              -77.603865,
              38.333578
            ],
            [
              -77.584673,
              38.346806
            ],
            [
              -77.54546,
              38.325081
            ],
            [
              -77.530283,
              38.309175
            ],
            [
              -77.527185,
              38.320655
            ],
            [
              -77.502307,
              38.325902
            ],
            [
              -77.481386,
              38.316477
            ],
            [
              -77.471358,
              38.319528
            ],
            [
              -77.467053,
              38.31866
            ],
            [
              -77.447126,
              38.284614
            ],
            [
              -77.420148,
              38.257986
            ],
            [
              -77.39085,
              38.245589
            ],
            [
              -77.370297,
              38.246576
            ],
            [
              -77.340623,
              38.252616
            ],
            [
              -77.326692,
              38.245136
            ],
            [
              -77.345102,
              38.261468
            ],
            [
              -77.339399,
              38.303089
            ],
            [
              -77.326681,
              38.318743
            ],
            [
              -77.330094,
              38.33401
            ],
            [
              -77.321448,
              38.345577
            ],
            [
              -77.2870968325169,
              38.3488000844294
            ],
            [
              -77.28835,
              38.351286
            ],
            [
              -77.288145,
              38.359477
            ],
            [
              -77.296077,
              38.369797
            ],
            [
              -77.317288,
              38.383576
            ],
            [
              -77.314848,
              38.389579
            ],
            [
              -77.312201,
              38.390958
            ],
            [
              -77.310719,
              38.397669
            ],
            [
              -77.319036,
              38.417803
            ],
            [
              -77.32544,
              38.44885
            ],
            [
              -77.322622,
              38.467131
            ],
            [
              -77.310334,
              38.493926
            ],
            [
              -77.3024995918579,
              38.5046243359563
            ],
            [
              -77.308814,
              38.500817
            ],
            [
              -77.334446,
              38.514116
            ],
            [
              -77.372027,
              38.521189
            ],
            [
              -77.396866,
              38.5472
            ],
            [
              -77.420911,
              38.563627
            ],
            [
              -77.425965,
              38.564997
            ],
            [
              -77.448793,
              38.581284
            ],
            [
              -77.464019,
              38.57867
            ],
            [
              -77.487481,
              38.591845
            ],
            [
              -77.531328,
              38.55655
            ],
            [
              -77.560869,
              38.527059
            ],
            [
              -77.588783,
              38.504044
            ],
            [
              -77.632345,
              38.469615
            ],
            [
              -77.633837,
              38.466892
            ],
            [
              -77.628569,
              38.451864
            ],
            [
              -77.63494,
              38.410218
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "035",
      "COUNTYNS": "01480108",
      "AFFGEOID": "0500000US51035",
      "GEOID": "51035",
      "NAME": "Carroll",
      "LSAD": "06",
      "ALAND": 1229526812,
      "AWATER": 7645484,
      "County_state": "Carroll,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.030306,
              36.814522
            ],
            [
              -81.042428,
              36.806448
            ],
            [
              -80.932271,
              36.671593
            ],
            [
              -80.917573,
              36.694133
            ],
            [
              -80.878301,
              36.692618
            ],
            [
              -80.900242,
              36.663898
            ],
            [
              -80.914173,
              36.650246
            ],
            [
              -80.8379544979468,
              36.5591308803947
            ],
            [
              -80.837641,
              36.559118
            ],
            [
              -80.837089,
              36.559154
            ],
            [
              -80.80392,
              36.560813
            ],
            [
              -80.773663,
              36.560307
            ],
            [
              -80.730351,
              36.562349
            ],
            [
              -80.704831,
              36.562319
            ],
            [
              -80.653349,
              36.559221
            ],
            [
              -80.624788,
              36.558408
            ],
            [
              -80.61205809962209,
              36.557871889558896
            ],
            [
              -80.615254,
              36.588909
            ],
            [
              -80.623187,
              36.592454
            ],
            [
              -80.622242,
              36.6181
            ],
            [
              -80.604573,
              36.636949
            ],
            [
              -80.602965,
              36.652661
            ],
            [
              -80.594947,
              36.661372
            ],
            [
              -80.578148,
              36.662118
            ],
            [
              -80.552399,
              36.652112
            ],
            [
              -80.549872,
              36.642479
            ],
            [
              -80.525522,
              36.644114
            ],
            [
              -80.500701,
              36.656551
            ],
            [
              -80.489105,
              36.650287
            ],
            [
              -80.489307,
              36.661633
            ],
            [
              -80.465776,
              36.67342
            ],
            [
              -80.453879,
              36.700317
            ],
            [
              -80.46418,
              36.708718
            ],
            [
              -80.567009,
              36.843305
            ],
            [
              -80.633987,
              36.93178
            ],
            [
              -80.67613,
              36.908037
            ],
            [
              -80.68259,
              36.895144
            ],
            [
              -80.740483,
              36.879001
            ],
            [
              -80.794776,
              36.863387
            ],
            [
              -80.812735,
              36.853232
            ],
            [
              -80.852531,
              36.849069
            ],
            [
              -81.022269,
              36.811313
            ],
            [
              -81.030306,
              36.814522
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "700",
      "COUNTYNS": "01498555",
      "AFFGEOID": "0500000US51700",
      "GEOID": "51700",
      "NAME": "Newport News",
      "LSAD": "25",
      "ALAND": 178939602,
      "AWATER": 131011289,
      "County_state": "Newport News,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.622252,
              37.142146
            ],
            [
              -76.62478,
              37.127091
            ],
            [
              -76.618252,
              37.119347
            ],
            [
              -76.579499,
              37.096627
            ],
            [
              -76.567931,
              37.080467
            ],
            [
              -76.564219,
              37.077507
            ],
            [
              -76.555066,
              37.075859
            ],
            [
              -76.536875,
              37.083942
            ],
            [
              -76.526203,
              37.077773
            ],
            [
              -76.526573,
              37.070047
            ],
            [
              -76.527973,
              37.068247
            ],
            [
              -76.526273,
              37.062947
            ],
            [
              -76.518242,
              37.055351
            ],
            [
              -76.512289,
              37.054858
            ],
            [
              -76.464471,
              37.027547
            ],
            [
              -76.45352308581079,
              37.0141708600158
            ],
            [
              -76.4489047716248,
              37.0085282128435
            ],
            [
              -76.448231,
              37.007705
            ],
            [
              -76.449891,
              37.004868
            ],
            [
              -76.452461,
              37.004603
            ],
            [
              -76.452118,
              36.998163
            ],
            [
              -76.4447786814597,
              36.989255683043005
            ],
            [
              -76.428869,
              36.969947
            ],
            [
              -76.418969,
              36.964047
            ],
            [
              -76.411768,
              36.962847
            ],
            [
              -76.4106457838995,
              36.9650563629479
            ],
            [
              -76.408568,
              36.969147
            ],
            [
              -76.396368,
              36.982347
            ],
            [
              -76.3877113044092,
              36.989671332859004
            ],
            [
              -76.401568,
              36.991947
            ],
            [
              -76.424141,
              37.024276
            ],
            [
              -76.442269,
              37.018448
            ],
            [
              -76.44085,
              37.02125
            ],
            [
              -76.449112,
              37.037561
            ],
            [
              -76.449733,
              37.043236
            ],
            [
              -76.450599,
              37.049548
            ],
            [
              -76.449981,
              37.051938
            ],
            [
              -76.448227,
              37.054987
            ],
            [
              -76.450291,
              37.057256
            ],
            [
              -76.450209,
              37.059855
            ],
            [
              -76.448689,
              37.063592
            ],
            [
              -76.450825,
              37.076654
            ],
            [
              -76.448931,
              37.079087
            ],
            [
              -76.436516,
              37.089856
            ],
            [
              -76.4355,
              37.094866
            ],
            [
              -76.443569,
              37.094511
            ],
            [
              -76.458498,
              37.100727
            ],
            [
              -76.460816,
              37.103039
            ],
            [
              -76.470977,
              37.114445
            ],
            [
              -76.506241,
              37.154288
            ],
            [
              -76.516375,
              37.178319
            ],
            [
              -76.556732,
              37.211
            ],
            [
              -76.565189,
              37.220539
            ],
            [
              -76.575074,
              37.212972
            ],
            [
              -76.591577,
              37.213833
            ],
            [
              -76.581218,
              37.182511
            ],
            [
              -76.61101757959901,
              37.167097001631596
            ],
            [
              -76.610972,
              37.166994
            ],
            [
              -76.606684,
              37.166674
            ],
            [
              -76.604476,
              37.160034
            ],
            [
              -76.6044856367961,
              37.1600221252169
            ],
            [
              -76.617084,
              37.144498
            ],
            [
              -76.622252,
              37.142146
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "077",
      "COUNTYNS": "01501196",
      "AFFGEOID": "0500000US51077",
      "GEOID": "51077",
      "NAME": "Grayson",
      "LSAD": "06",
      "ALAND": 1144219726,
      "AWATER": 9792918,
      "County_state": "Grayson,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.6469,
              36.611918
            ],
            [
              -81.677535,
              36.588117
            ],
            [
              -81.600934,
              36.587019
            ],
            [
              -81.521032,
              36.58052
            ],
            [
              -81.499831,
              36.57982
            ],
            [
              -81.489387,
              36.579026
            ],
            [
              -81.47643,
              36.580421
            ],
            [
              -81.442228,
              36.576822
            ],
            [
              -81.374824,
              36.574673
            ],
            [
              -81.353322,
              36.574723
            ],
            [
              -81.35325920639549,
              36.5747234125838
            ],
            [
              -81.307511,
              36.575024
            ],
            [
              -81.262303,
              36.573924
            ],
            [
              -81.249816,
              36.573225
            ],
            [
              -81.176712,
              36.571926
            ],
            [
              -81.171212,
              36.571026
            ],
            [
              -81.14181,
              36.569527
            ],
            [
              -81.124809,
              36.569227
            ],
            [
              -81.083206,
              36.567328
            ],
            [
              -81.061866,
              36.56702
            ],
            [
              -81.058844,
              36.566976
            ],
            [
              -81.011402,
              36.564429
            ],
            [
              -81.003802,
              36.563629
            ],
            [
              -80.945988,
              36.563196
            ],
            [
              -80.944338,
              36.563058
            ],
            [
              -80.901726,
              36.561751
            ],
            [
              -80.9016647889236,
              36.561748485078205
            ],
            [
              -80.8379544979468,
              36.5591308803947
            ],
            [
              -80.914173,
              36.650246
            ],
            [
              -80.916043,
              36.629343
            ],
            [
              -80.931147,
              36.625268
            ],
            [
              -80.921711,
              36.643533
            ],
            [
              -80.947754,
              36.648361
            ],
            [
              -80.944109,
              36.676303
            ],
            [
              -80.932271,
              36.671593
            ],
            [
              -81.042428,
              36.806448
            ],
            [
              -81.072309,
              36.790822
            ],
            [
              -81.084188,
              36.788097
            ],
            [
              -81.08851,
              36.767422
            ],
            [
              -81.136713,
              36.763022
            ],
            [
              -81.154114,
              36.769021
            ],
            [
              -81.186016,
              36.758421
            ],
            [
              -81.261987,
              36.763033
            ],
            [
              -81.300897,
              36.753561
            ],
            [
              -81.38403,
              36.739987
            ],
            [
              -81.439214,
              36.716908
            ],
            [
              -81.467913,
              36.715064
            ],
            [
              -81.543957,
              36.701517
            ],
            [
              -81.531409,
              36.693595
            ],
            [
              -81.533047,
              36.676308
            ],
            [
              -81.547229,
              36.65648
            ],
            [
              -81.559965,
              36.65867
            ],
            [
              -81.605865,
              36.638079
            ],
            [
              -81.6109,
              36.616952
            ],
            [
              -81.6469,
              36.611918
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "001",
      "COUNTYNS": "01480091",
      "AFFGEOID": "0500000US51001",
      "GEOID": "51001",
      "NAME": "Accomack",
      "LSAD": "06",
      "ALAND": 1163743170,
      "AWATER": 2229242744,
      "County_state": "Accomack,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -75.941182,
              37.563839
            ],
            [
              -75.941153,
              37.558436
            ],
            [
              -75.937299,
              37.551729
            ],
            [
              -75.9376646225106,
              37.5496523755582
            ],
            [
              -75.929315,
              37.554933
            ],
            [
              -75.90041,
              37.557265
            ],
            [
              -75.884455,
              37.549542
            ],
            [
              -75.835214,
              37.554245
            ],
            [
              -75.788012,
              37.528816
            ],
            [
              -75.804797,
              37.514726
            ],
            [
              -75.791656,
              37.50381
            ],
            [
              -75.793521,
              37.488837
            ],
            [
              -75.812793,
              37.473895
            ],
            [
              -75.80242,
              37.462433
            ],
            [
              -75.78703,
              37.464578
            ],
            [
              -75.776564,
              37.454589
            ],
            [
              -75.763912,
              37.463308
            ],
            [
              -75.72852,
              37.459775
            ],
            [
              -75.713275,
              37.449876
            ],
            [
              -75.68326,
              37.454744
            ],
            [
              -75.6765221891521,
              37.46370055508459
            ],
            [
              -75.684048,
              37.46323
            ],
            [
              -75.686306,
              37.478604
            ],
            [
              -75.672877,
              37.483696
            ],
            [
              -75.642474,
              37.517112
            ],
            [
              -75.615816,
              37.551659
            ],
            [
              -75.602168,
              37.567107
            ],
            [
              -75.600504,
              37.573889
            ],
            [
              -75.612863,
              37.57728
            ],
            [
              -75.613339,
              37.585944
            ],
            [
              -75.614527,
              37.609296
            ],
            [
              -75.603831,
              37.628028
            ],
            [
              -75.591953,
              37.663178
            ],
            [
              -75.583629,
              37.69313
            ],
            [
              -75.576379,
              37.699431
            ],
            [
              -75.568696,
              37.723987
            ],
            [
              -75.562951,
              37.736469
            ],
            [
              -75.551898,
              37.748122
            ],
            [
              -75.520881,
              37.796687
            ],
            [
              -75.48927,
              37.832457
            ],
            [
              -75.467951,
              37.851328
            ],
            [
              -75.452681,
              37.86351
            ],
            [
              -75.439001,
              37.869335
            ],
            [
              -75.425334,
              37.870086
            ],
            [
              -75.397418,
              37.869457
            ],
            [
              -75.391346,
              37.864175
            ],
            [
              -75.389056,
              37.856169
            ],
            [
              -75.386056,
              37.852424
            ],
            [
              -75.380638,
              37.851702
            ],
            [
              -75.373321,
              37.856155
            ],
            [
              -75.364777,
              37.863166
            ],
            [
              -75.35246,
              37.87483
            ],
            [
              -75.338623,
              37.894986
            ],
            [
              -75.319335,
              37.922484
            ],
            [
              -75.296871,
              37.959043
            ],
            [
              -75.242266,
              38.027209
            ],
            [
              -75.2606353145422,
              38.025574482617
            ],
            [
              -75.3778511771824,
              38.015144514498004
            ],
            [
              -75.398839,
              38.013277
            ],
            [
              -75.42881,
              38.010854
            ],
            [
              -75.435956,
              38.010282
            ],
            [
              -75.624341,
              37.994211
            ],
            [
              -75.625612,
              37.9898
            ],
            [
              -75.627607,
              37.988521
            ],
            [
              -75.630869,
              37.987818
            ],
            [
              -75.632532,
              37.986693
            ],
            [
              -75.633833,
              37.984519
            ],
            [
              -75.63753864924719,
              37.9801934905551
            ],
            [
              -75.638221,
              37.979397
            ],
            [
              -75.641823,
              37.975967
            ],
            [
              -75.644725,
              37.969779
            ],
            [
              -75.648229,
              37.966775
            ],
            [
              -75.647606,
              37.947027
            ],
            [
              -75.655681,
              37.945435
            ],
            [
              -75.665012,
              37.949387
            ],
            [
              -75.669711,
              37.950796
            ],
            [
              -75.693942,
              37.930362
            ],
            [
              -75.704318,
              37.92901
            ],
            [
              -75.712065,
              37.936082
            ],
            [
              -75.757694,
              37.903912
            ],
            [
              -75.759835,
              37.899333
            ],
            [
              -75.758796,
              37.897615
            ],
            [
              -75.753048,
              37.896605
            ],
            [
              -75.726699,
              37.897299
            ],
            [
              -75.724505,
              37.900184
            ],
            [
              -75.72049,
              37.901926
            ],
            [
              -75.709626,
              37.900622
            ],
            [
              -75.687584,
              37.88634
            ],
            [
              -75.685293,
              37.873341
            ],
            [
              -75.689837,
              37.861817
            ],
            [
              -75.702914,
              37.849659
            ],
            [
              -75.709114,
              37.8477
            ],
            [
              -75.714487,
              37.837777
            ],
            [
              -75.71659,
              37.826696
            ],
            [
              -75.723224,
              37.820124
            ],
            [
              -75.73588,
              37.816561
            ],
            [
              -75.743097,
              37.806656
            ],
            [
              -75.770607,
              37.804602
            ],
            [
              -75.784599,
              37.806826
            ],
            [
              -75.793399,
              37.804493
            ],
            [
              -75.818125,
              37.791698
            ],
            [
              -75.812125,
              37.776589
            ],
            [
              -75.803041,
              37.762464
            ],
            [
              -75.812155,
              37.749502
            ],
            [
              -75.82481,
              37.741671
            ],
            [
              -75.827922,
              37.737986
            ],
            [
              -75.831438,
              37.73169
            ],
            [
              -75.830773,
              37.725486
            ],
            [
              -75.837685,
              37.712985
            ],
            [
              -75.845579,
              37.707993
            ],
            [
              -75.859262,
              37.703111
            ],
            [
              -75.868355,
              37.687609
            ],
            [
              -75.869523,
              37.674356
            ],
            [
              -75.868481,
              37.668224
            ],
            [
              -75.877059,
              37.660641
            ],
            [
              -75.909586,
              37.622671
            ],
            [
              -75.924756,
              37.600215
            ],
            [
              -75.941182,
              37.563839
            ]
          ]
        ],
        [
          [
            [
              -75.999658,
              37.848198
            ],
            [
              -76.001116,
              37.834947
            ],
            [
              -75.9983,
              37.812626
            ],
            [
              -75.987301,
              37.804917
            ],
            [
              -75.982158,
              37.806226
            ],
            [
              -75.977301,
              37.825821
            ],
            [
              -75.971705,
              37.830928
            ],
            [
              -75.973607,
              37.835817
            ],
            [
              -75.982098,
              37.837253
            ],
            [
              -75.988018,
              37.841085
            ],
            [
              -75.992556,
              37.848889
            ],
            [
              -75.996859,
              37.85042
            ],
            [
              -75.999658,
              37.848198
            ]
          ]
        ],
        [
          [
            [
              -76.041691,
              37.954
            ],
            [
              -76.04653,
              37.953586
            ],
            [
              -76.035802,
              37.929008
            ],
            [
              -76.032491,
              37.915008
            ],
            [
              -76.017592,
              37.935161
            ],
            [
              -76.00313,
              37.947997
            ],
            [
              -75.993905,
              37.953489
            ],
            [
              -76.0058878051011,
              37.9536619120505
            ],
            [
              -76.020932,
              37.953879
            ],
            [
              -76.021714,
              37.953887
            ],
            [
              -76.0223246348406,
              37.9538781742028
            ],
            [
              -76.029463,
              37.953775
            ],
            [
              -76.030122,
              37.953655
            ],
            [
              -76.041402,
              37.954006
            ],
            [
              -76.041691,
              37.954
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "149",
      "COUNTYNS": "01480160",
      "AFFGEOID": "0500000US51149",
      "GEOID": "51149",
      "NAME": "Prince George",
      "LSAD": "06",
      "ALAND": 686941559,
      "AWATER": 43184612,
      "County_state": "Prince George,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.399203,
              37.17085
            ],
            [
              -77.397418,
              37.157348
            ],
            [
              -77.394645,
              37.035734
            ],
            [
              -77.398498,
              36.992985
            ],
            [
              -77.196689,
              37.092958
            ],
            [
              -77.187038,
              37.098933
            ],
            [
              -77.155415,
              37.112034
            ],
            [
              -77.087776,
              37.169071
            ],
            [
              -77.073145,
              37.184072
            ],
            [
              -77.046598,
              37.191396
            ],
            [
              -77.04434,
              37.202006
            ],
            [
              -77.024069,
              37.201028
            ],
            [
              -77.01178,
              37.21241
            ],
            [
              -77.018811,
              37.219593
            ],
            [
              -77.012301,
              37.232883
            ],
            [
              -77.003427,
              37.232669
            ],
            [
              -76.972439,
              37.248501
            ],
            [
              -76.991363,
              37.278005
            ],
            [
              -76.996003,
              37.300979
            ],
            [
              -77.010096,
              37.305251
            ],
            [
              -77.032747,
              37.294465
            ],
            [
              -77.069269,
              37.269742
            ],
            [
              -77.079555,
              37.274275
            ],
            [
              -77.086164,
              37.310904
            ],
            [
              -77.097625,
              37.31197
            ],
            [
              -77.124694,
              37.302923
            ],
            [
              -77.187549,
              37.31146
            ],
            [
              -77.224013,
              37.319403
            ],
            [
              -77.249719,
              37.308668
            ],
            [
              -77.270675,
              37.320756
            ],
            [
              -77.281955,
              37.319012
            ],
            [
              -77.284019,
              37.313012
            ],
            [
              -77.276499,
              37.317348
            ],
            [
              -77.270307,
              37.308948
            ],
            [
              -77.252818,
              37.29362
            ],
            [
              -77.278399,
              37.278134
            ],
            [
              -77.284236,
              37.274124
            ],
            [
              -77.299796,
              37.269509
            ],
            [
              -77.305269,
              37.262037
            ],
            [
              -77.3241,
              37.262297
            ],
            [
              -77.330609,
              37.257874
            ],
            [
              -77.325203,
              37.26698
            ],
            [
              -77.334549,
              37.312468
            ],
            [
              -77.350326,
              37.311925
            ],
            [
              -77.356582,
              37.283125
            ],
            [
              -77.373735,
              37.266949
            ],
            [
              -77.376785,
              37.244849
            ],
            [
              -77.363107,
              37.239945
            ],
            [
              -77.352956,
              37.220005
            ],
            [
              -77.331288,
              37.196515
            ],
            [
              -77.345676,
              37.174136
            ],
            [
              -77.359664,
              37.171919
            ],
            [
              -77.376702,
              37.180549
            ],
            [
              -77.399203,
              37.17085
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "127",
      "COUNTYNS": "01480150",
      "AFFGEOID": "0500000US51127",
      "GEOID": "51127",
      "NAME": "New Kent",
      "LSAD": "06",
      "ALAND": 543990076,
      "AWATER": 35597678,
      "County_state": "New Kent,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.240988,
              37.538088
            ],
            [
              -77.231846,
              37.539638
            ],
            [
              -77.177324,
              37.4906
            ],
            [
              -77.161638,
              37.476516
            ],
            [
              -77.126984,
              37.471622
            ],
            [
              -77.119073,
              37.455822
            ],
            [
              -77.095814,
              37.440292
            ],
            [
              -77.076287,
              37.441287
            ],
            [
              -77.050797,
              37.432161
            ],
            [
              -77.026677,
              37.429006
            ],
            [
              -77.021316,
              37.41601
            ],
            [
              -76.991476,
              37.414917
            ],
            [
              -76.971672,
              37.418353
            ],
            [
              -76.968821,
              37.406511
            ],
            [
              -76.95192,
              37.403967
            ],
            [
              -76.943083,
              37.410389
            ],
            [
              -76.93802,
              37.39953
            ],
            [
              -76.942518,
              37.383238
            ],
            [
              -76.92761,
              37.399566
            ],
            [
              -76.917993,
              37.395327
            ],
            [
              -76.904116,
              37.403821
            ],
            [
              -76.917932,
              37.384261
            ],
            [
              -76.904471,
              37.377665
            ],
            [
              -76.894153,
              37.400228
            ],
            [
              -76.892062,
              37.432418
            ],
            [
              -76.859974,
              37.446834
            ],
            [
              -76.824103,
              37.450716
            ],
            [
              -76.79254,
              37.435495
            ],
            [
              -76.770279,
              37.434992
            ],
            [
              -76.757995,
              37.4455
            ],
            [
              -76.756789,
              37.461715
            ],
            [
              -76.741984,
              37.467076
            ],
            [
              -76.751693,
              37.483941
            ],
            [
              -76.79085,
              37.505762
            ],
            [
              -76.792184,
              37.517594
            ],
            [
              -76.818039,
              37.55047
            ],
            [
              -76.851001,
              37.526083
            ],
            [
              -76.863826,
              37.523224
            ],
            [
              -76.878069,
              37.531377
            ],
            [
              -76.863776,
              37.549149
            ],
            [
              -76.853577,
              37.572627
            ],
            [
              -76.868479,
              37.581383
            ],
            [
              -76.881423,
              37.576891
            ],
            [
              -76.874686,
              37.552535
            ],
            [
              -76.893857,
              37.545668
            ],
            [
              -76.905261,
              37.548963
            ],
            [
              -76.903125,
              37.565791
            ],
            [
              -76.92155,
              37.568943
            ],
            [
              -76.942711,
              37.559552
            ],
            [
              -76.944206,
              37.538797
            ],
            [
              -76.974629,
              37.541564
            ],
            [
              -76.95676,
              37.557035
            ],
            [
              -76.957064,
              37.571913
            ],
            [
              -76.968576,
              37.586232
            ],
            [
              -76.986064,
              37.583428
            ],
            [
              -76.98299,
              37.565499
            ],
            [
              -77.019163,
              37.567417
            ],
            [
              -77.01916,
              37.581945
            ],
            [
              -77.067217,
              37.60392
            ],
            [
              -77.084159,
              37.598358
            ],
            [
              -77.089643,
              37.618224
            ],
            [
              -77.123675,
              37.626881
            ],
            [
              -77.138029,
              37.616932
            ],
            [
              -77.140457,
              37.592169
            ],
            [
              -77.170941,
              37.596524
            ],
            [
              -77.240988,
              37.538088
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "197",
      "COUNTYNS": "01501379",
      "AFFGEOID": "0500000US51197",
      "GEOID": "51197",
      "NAME": "Wythe",
      "LSAD": "06",
      "ALAND": 1196367968,
      "AWATER": 7310342,
      "County_state": "Wythe,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.367228,
              36.967217
            ],
            [
              -81.379402,
              36.952442
            ],
            [
              -81.326985,
              36.899752
            ],
            [
              -81.272286,
              36.791786
            ],
            [
              -81.261987,
              36.763033
            ],
            [
              -81.186016,
              36.758421
            ],
            [
              -81.154114,
              36.769021
            ],
            [
              -81.136713,
              36.763022
            ],
            [
              -81.08851,
              36.767422
            ],
            [
              -81.084188,
              36.788097
            ],
            [
              -81.072309,
              36.790822
            ],
            [
              -81.042428,
              36.806448
            ],
            [
              -81.030306,
              36.814522
            ],
            [
              -81.022269,
              36.811313
            ],
            [
              -80.852531,
              36.849069
            ],
            [
              -80.812735,
              36.853232
            ],
            [
              -80.794776,
              36.863387
            ],
            [
              -80.740483,
              36.879001
            ],
            [
              -80.790454,
              36.934111
            ],
            [
              -80.862576,
              37.003642
            ],
            [
              -80.893249,
              37.027399
            ],
            [
              -80.912674,
              37.073349
            ],
            [
              -80.972963,
              37.054817
            ],
            [
              -81.032514,
              37.046858
            ],
            [
              -81.106884,
              37.025146
            ],
            [
              -81.114319,
              37.022612
            ],
            [
              -81.131051,
              37.038107
            ],
            [
              -81.166057,
              37.024653
            ],
            [
              -81.178854,
              37.025099
            ],
            [
              -81.191819,
              37.037035
            ],
            [
              -81.224624,
              37.047835
            ],
            [
              -81.286458,
              37.018993
            ],
            [
              -81.315425,
              36.998517
            ],
            [
              -81.338326,
              36.975417
            ],
            [
              -81.355827,
              36.964517
            ],
            [
              -81.367228,
              36.967217
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "141",
      "COUNTYNS": "01497999",
      "AFFGEOID": "0500000US51141",
      "GEOID": "51141",
      "NAME": "Patrick",
      "LSAD": "06",
      "ALAND": 1250846053,
      "AWATER": 7142208,
      "County_state": "Patrick,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.622242,
              36.6181
            ],
            [
              -80.623187,
              36.592454
            ],
            [
              -80.615254,
              36.588909
            ],
            [
              -80.61205809962209,
              36.557871889558896
            ],
            [
              -80.4401,
              36.55063
            ],
            [
              -80.432628,
              36.550302
            ],
            [
              -80.431605,
              36.550219
            ],
            [
              -80.295243,
              36.543973
            ],
            [
              -80.228263,
              36.543867
            ],
            [
              -80.225408,
              36.543748
            ],
            [
              -80.171636,
              36.543219
            ],
            [
              -80.169535,
              36.54319
            ],
            [
              -80.122183,
              36.542646
            ],
            [
              -80.0534585356035,
              36.5425366652957
            ],
            [
              -80.050211,
              36.609333
            ],
            [
              -80.059501,
              36.632911
            ],
            [
              -80.058431,
              36.656835
            ],
            [
              -80.07577,
              36.680829
            ],
            [
              -80.081518,
              36.703221
            ],
            [
              -80.094207,
              36.724581
            ],
            [
              -80.084702,
              36.740146
            ],
            [
              -80.091134,
              36.749079
            ],
            [
              -80.094389,
              36.779552
            ],
            [
              -80.075021,
              36.790761
            ],
            [
              -80.041493,
              36.794365
            ],
            [
              -80.07267,
              36.823278
            ],
            [
              -80.066652,
              36.841604
            ],
            [
              -80.101942,
              36.846742
            ],
            [
              -80.235455,
              36.87213
            ],
            [
              -80.255234,
              36.864671
            ],
            [
              -80.28999,
              36.857579
            ],
            [
              -80.294171,
              36.847809
            ],
            [
              -80.317297,
              36.843729
            ],
            [
              -80.328395,
              36.830759
            ],
            [
              -80.341953,
              36.833249
            ],
            [
              -80.344442,
              36.813471
            ],
            [
              -80.361599,
              36.80309
            ],
            [
              -80.386909,
              36.797676
            ],
            [
              -80.387659,
              36.769959
            ],
            [
              -80.397377,
              36.764327
            ],
            [
              -80.394658,
              36.744867
            ],
            [
              -80.411719,
              36.741844
            ],
            [
              -80.443794,
              36.747859
            ],
            [
              -80.458572,
              36.738359
            ],
            [
              -80.46418,
              36.708718
            ],
            [
              -80.453879,
              36.700317
            ],
            [
              -80.465776,
              36.67342
            ],
            [
              -80.489307,
              36.661633
            ],
            [
              -80.489105,
              36.650287
            ],
            [
              -80.500701,
              36.656551
            ],
            [
              -80.525522,
              36.644114
            ],
            [
              -80.549872,
              36.642479
            ],
            [
              -80.552399,
              36.652112
            ],
            [
              -80.578148,
              36.662118
            ],
            [
              -80.594947,
              36.661372
            ],
            [
              -80.602965,
              36.652661
            ],
            [
              -80.604573,
              36.636949
            ],
            [
              -80.622242,
              36.6181
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "175",
      "COUNTYNS": "01480171",
      "AFFGEOID": "0500000US51175",
      "GEOID": "51175",
      "NAME": "Southampton",
      "LSAD": "06",
      "ALAND": 1551752840,
      "AWATER": 8247158,
      "County_state": "Southampton,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.501917,
              36.670506
            ],
            [
              -77.477025,
              36.668664
            ],
            [
              -77.461082,
              36.652627
            ],
            [
              -77.414985,
              36.654069
            ],
            [
              -77.37748,
              36.632865
            ],
            [
              -77.370699,
              36.606752
            ],
            [
              -77.35741,
              36.600785
            ],
            [
              -77.361537,
              36.575632
            ],
            [
              -77.355111,
              36.554122
            ],
            [
              -77.333014,
              36.557195
            ],
            [
              -77.2968751997552,
              36.5447391568792
            ],
            [
              -77.205156,
              36.544581
            ],
            [
              -77.190175,
              36.546164
            ],
            [
              -77.16966,
              36.547315
            ],
            [
              -77.1645,
              36.54633
            ],
            [
              -77.164370064468,
              36.54630522103329
            ],
            [
              -77.152691,
              36.544078
            ],
            [
              -77.124812,
              36.543986
            ],
            [
              -77.095062,
              36.544626
            ],
            [
              -76.916048,
              36.543815
            ],
            [
              -76.916060288835,
              36.5438365877093
            ],
            [
              -76.917318,
              36.546046
            ],
            [
              -76.916989,
              36.550742
            ],
            [
              -76.915897,
              36.552093
            ],
            [
              -76.930936,
              36.559569
            ],
            [
              -76.909268,
              36.579747
            ],
            [
              -76.910964,
              36.619229
            ],
            [
              -76.893351,
              36.626393
            ],
            [
              -76.897592,
              36.64405
            ],
            [
              -76.909672,
              36.648175
            ],
            [
              -76.95366,
              36.66388
            ],
            [
              -76.957786,
              36.666259
            ],
            [
              -76.962339,
              36.667515
            ],
            [
              -76.949695,
              36.678673
            ],
            [
              -76.967855,
              36.683851
            ],
            [
              -76.967212,
              36.702588
            ],
            [
              -76.939093,
              36.708644
            ],
            [
              -76.924629,
              36.707729
            ],
            [
              -76.914176,
              36.722144
            ],
            [
              -76.91699,
              36.733724
            ],
            [
              -76.903552,
              36.739304
            ],
            [
              -76.901405,
              36.757393
            ],
            [
              -76.884829,
              36.766335
            ],
            [
              -76.883445,
              36.781181
            ],
            [
              -76.867007,
              36.787959
            ],
            [
              -76.868881,
              36.808449
            ],
            [
              -76.852202,
              36.851807
            ],
            [
              -76.818031,
              36.880584
            ],
            [
              -76.827278,
              36.886023
            ],
            [
              -76.817073,
              36.906167
            ],
            [
              -76.836282,
              36.922725
            ],
            [
              -76.85897,
              36.956031
            ],
            [
              -76.849684,
              36.996211
            ],
            [
              -76.870705,
              36.985666
            ],
            [
              -76.953546,
              36.94445
            ],
            [
              -77.069918,
              36.884566
            ],
            [
              -77.119808,
              36.858759
            ],
            [
              -77.249703,
              36.795549
            ],
            [
              -77.344798,
              36.752586
            ],
            [
              -77.430127,
              36.707658
            ],
            [
              -77.501917,
              36.670506
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "037",
      "COUNTYNS": "01492442",
      "AFFGEOID": "0500000US51037",
      "GEOID": "51037",
      "NAME": "Charlotte",
      "LSAD": "06",
      "ALAND": 1230994495,
      "AWATER": 5824992,
      "County_state": "Charlotte,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.902227,
              37.026799
            ],
            [
              -78.904587,
              37.022288
            ],
            [
              -78.891742,
              37.001092
            ],
            [
              -78.900195,
              36.97985
            ],
            [
              -78.892702,
              36.976694
            ],
            [
              -78.870371,
              36.986068
            ],
            [
              -78.817455,
              36.98402
            ],
            [
              -78.805039,
              36.976566
            ],
            [
              -78.794862,
              36.955988
            ],
            [
              -78.775898,
              36.955481
            ],
            [
              -78.774715,
              36.980976
            ],
            [
              -78.748358,
              36.987057
            ],
            [
              -78.757614,
              36.997722
            ],
            [
              -78.749727,
              37.008483
            ],
            [
              -78.740441,
              37.001564
            ],
            [
              -78.7337,
              36.949163
            ],
            [
              -78.727686,
              36.937454
            ],
            [
              -78.748461,
              36.929295
            ],
            [
              -78.749727,
              36.920065
            ],
            [
              -78.723376,
              36.90567
            ],
            [
              -78.69487,
              36.878251
            ],
            [
              -78.687142,
              36.863825
            ],
            [
              -78.671463,
              36.857951
            ],
            [
              -78.666125,
              36.840482
            ],
            [
              -78.684726,
              36.832131
            ],
            [
              -78.686878,
              36.824331
            ],
            [
              -78.666995,
              36.811822
            ],
            [
              -78.682652,
              36.797222
            ],
            [
              -78.681469,
              36.787431
            ],
            [
              -78.666145,
              36.764363
            ],
            [
              -78.691328,
              36.741847
            ],
            [
              -78.67116,
              36.720817
            ],
            [
              -78.665822,
              36.706763
            ],
            [
              -78.648541,
              36.697909
            ],
            [
              -78.493028,
              36.89122
            ],
            [
              -78.443644,
              37.079371
            ],
            [
              -78.457654,
              37.085346
            ],
            [
              -78.480891,
              37.085196
            ],
            [
              -78.503764,
              37.106854
            ],
            [
              -78.501882,
              37.114219
            ],
            [
              -78.515336,
              37.132722
            ],
            [
              -78.541917,
              37.140776
            ],
            [
              -78.547128,
              37.150431
            ],
            [
              -78.573221,
              37.146935
            ],
            [
              -78.583642,
              37.140635
            ],
            [
              -78.595609,
              37.150959
            ],
            [
              -78.620417,
              37.157661
            ],
            [
              -78.634927,
              37.170064
            ],
            [
              -78.644621,
              37.194323
            ],
            [
              -78.680546,
              37.197377
            ],
            [
              -78.692432,
              37.203673
            ],
            [
              -78.681573,
              37.248759
            ],
            [
              -78.720331,
              37.237098
            ],
            [
              -78.734854,
              37.220229
            ],
            [
              -78.777293,
              37.229107
            ],
            [
              -78.808198,
              37.201852
            ],
            [
              -78.824209,
              37.205361
            ],
            [
              -78.902227,
              37.026799
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "165",
      "COUNTYNS": "01488970",
      "AFFGEOID": "0500000US51165",
      "GEOID": "51165",
      "NAME": "Rockingham",
      "LSAD": "06",
      "ALAND": 2200698748,
      "AWATER": 10892582,
      "County_state": "Rockingham,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.220961,
              38.48059
            ],
            [
              -79.2243090862597,
              38.4776607800969
            ],
            [
              -79.073392,
              38.393427
            ],
            [
              -78.980426,
              38.341344
            ],
            [
              -78.913046,
              38.303645
            ],
            [
              -78.894094,
              38.306607
            ],
            [
              -78.818445,
              38.252729
            ],
            [
              -78.749396,
              38.206648
            ],
            [
              -78.742136,
              38.21772
            ],
            [
              -78.726443,
              38.229972
            ],
            [
              -78.710624,
              38.240466
            ],
            [
              -78.655149,
              38.260632
            ],
            [
              -78.663145,
              38.277933
            ],
            [
              -78.651214,
              38.293463
            ],
            [
              -78.634337,
              38.292029
            ],
            [
              -78.628525,
              38.300023
            ],
            [
              -78.602248,
              38.307485
            ],
            [
              -78.587506,
              38.32483
            ],
            [
              -78.553143,
              38.334824
            ],
            [
              -78.553809,
              38.348499
            ],
            [
              -78.542015,
              38.364859
            ],
            [
              -78.522792,
              38.36951
            ],
            [
              -78.515141,
              38.39372
            ],
            [
              -78.493214,
              38.406286
            ],
            [
              -78.48574,
              38.421579
            ],
            [
              -78.548302,
              38.426401
            ],
            [
              -78.559098,
              38.447336
            ],
            [
              -78.62292,
              38.466379
            ],
            [
              -78.634462,
              38.497605
            ],
            [
              -78.671178,
              38.501429
            ],
            [
              -78.682131,
              38.506097
            ],
            [
              -78.691145,
              38.511211
            ],
            [
              -78.675729,
              38.533996
            ],
            [
              -78.663181,
              38.571927
            ],
            [
              -78.647046,
              38.602029
            ],
            [
              -78.641769,
              38.604719
            ],
            [
              -78.794197,
              38.710119
            ],
            [
              -78.8692752588773,
              38.7629918888636
            ],
            [
              -78.869276,
              38.762991
            ],
            [
              -78.993997,
              38.850102
            ],
            [
              -78.998171,
              38.847353
            ],
            [
              -79.000252,
              38.845412
            ],
            [
              -78.998863,
              38.840962
            ],
            [
              -78.999014,
              38.840074
            ],
            [
              -79.002352,
              38.836512
            ],
            [
              -79.005152,
              38.829912
            ],
            [
              -79.006352,
              38.826112
            ],
            [
              -79.006152,
              38.824512
            ],
            [
              -79.006552,
              38.823712
            ],
            [
              -79.007952,
              38.822312
            ],
            [
              -79.011952,
              38.820412
            ],
            [
              -79.016752,
              38.820012
            ],
            [
              -79.019553,
              38.817912
            ],
            [
              -79.024053,
              38.809212
            ],
            [
              -79.024453,
              38.803712
            ],
            [
              -79.023453,
              38.802612
            ],
            [
              -79.023053,
              38.798613
            ],
            [
              -79.027253,
              38.792113
            ],
            [
              -79.029253,
              38.791013
            ],
            [
              -79.033153,
              38.791013
            ],
            [
              -79.046554,
              38.792113
            ],
            [
              -79.048954,
              38.790713
            ],
            [
              -79.054954,
              38.785713
            ],
            [
              -79.055654,
              38.783013
            ],
            [
              -79.054354,
              38.780613
            ],
            [
              -79.052454,
              38.779213
            ],
            [
              -79.051654,
              38.778013
            ],
            [
              -79.051254,
              38.773913
            ],
            [
              -79.051554,
              38.772613
            ],
            [
              -79.053754,
              38.772313
            ],
            [
              -79.055654,
              38.770913
            ],
            [
              -79.056754,
              38.766513
            ],
            [
              -79.057253,
              38.761413
            ],
            [
              -79.057554,
              38.760213
            ],
            [
              -79.060954,
              38.756713
            ],
            [
              -79.064854,
              38.754413
            ],
            [
              -79.072555,
              38.747513
            ],
            [
              -79.072755,
              38.744614
            ],
            [
              -79.073855,
              38.742114
            ],
            [
              -79.076555,
              38.739214
            ],
            [
              -79.079655,
              38.734714
            ],
            [
              -79.081955,
              38.729714
            ],
            [
              -79.085455,
              38.724614
            ],
            [
              -79.087255,
              38.720114
            ],
            [
              -79.086555,
              38.716015
            ],
            [
              -79.092755,
              38.702315
            ],
            [
              -79.092555,
              38.700149
            ],
            [
              -79.092271,
              38.699208
            ],
            [
              -79.090755,
              38.692515
            ],
            [
              -79.088055,
              38.690115
            ],
            [
              -79.085555,
              38.688816
            ],
            [
              -79.084355,
              38.686516
            ],
            [
              -79.087855,
              38.673816
            ],
            [
              -79.091055,
              38.669316
            ],
            [
              -79.092755,
              38.662816
            ],
            [
              -79.092955,
              38.659517
            ],
            [
              -79.106356,
              38.656217
            ],
            [
              -79.111556,
              38.659717
            ],
            [
              -79.120256,
              38.660216
            ],
            [
              -79.122256,
              38.659817
            ],
            [
              -79.129757,
              38.655017
            ],
            [
              -79.131057,
              38.653217
            ],
            [
              -79.133557,
              38.646017
            ],
            [
              -79.135472,
              38.644057
            ],
            [
              -79.135546,
              38.643715
            ],
            [
              -79.136374,
              38.6424
            ],
            [
              -79.137012,
              38.640655
            ],
            [
              -79.137557,
              38.638017
            ],
            [
              -79.139657,
              38.637217
            ],
            [
              -79.142657,
              38.634417
            ],
            [
              -79.146741,
              38.625819
            ],
            [
              -79.146974,
              38.625641
            ],
            [
              -79.151257,
              38.620618
            ],
            [
              -79.155355,
              38.611225
            ],
            [
              -79.155557,
              38.609218
            ],
            [
              -79.154357,
              38.606518
            ],
            [
              -79.159158,
              38.601219
            ],
            [
              -79.158957,
              38.594519
            ],
            [
              -79.158257,
              38.593919
            ],
            [
              -79.158657,
              38.592319
            ],
            [
              -79.163458,
              38.583119
            ],
            [
              -79.1673213887043,
              38.5793395980066
            ],
            [
              -79.168058,
              38.578619
            ],
            [
              -79.170858,
              38.574119
            ],
            [
              -79.171658,
              38.57162
            ],
            [
              -79.170658,
              38.56922
            ],
            [
              -79.170958,
              38.56812
            ],
            [
              -79.174512,
              38.566531
            ],
            [
              -79.174881,
              38.566314
            ],
            [
              -79.176658,
              38.56552
            ],
            [
              -79.180858,
              38.55992
            ],
            [
              -79.184058,
              38.55152
            ],
            [
              -79.188958,
              38.54742
            ],
            [
              -79.193458,
              38.542421
            ],
            [
              -79.196959,
              38.536721
            ],
            [
              -79.201459,
              38.527821
            ],
            [
              -79.205859,
              38.524521
            ],
            [
              -79.210959,
              38.507422
            ],
            [
              -79.206959,
              38.503522
            ],
            [
              -79.207884,
              38.500428
            ],
            [
              -79.207873,
              38.500122
            ],
            [
              -79.209703,
              38.495574
            ],
            [
              -79.210008,
              38.494283
            ],
            [
              -79.210026,
              38.494231
            ],
            [
              -79.210591,
              38.492913
            ],
            [
              -79.215212,
              38.489261
            ],
            [
              -79.219067,
              38.487441
            ],
            [
              -79.221406,
              38.484837
            ],
            [
              -79.220961,
              38.48059
            ]
          ],
          [
            [
              -78.902201,
              38.42436
            ],
            [
              -78.915443,
              38.437455
            ],
            [
              -78.900589,
              38.459217
            ],
            [
              -78.888973,
              38.455357
            ],
            [
              -78.871246,
              38.489338
            ],
            [
              -78.870993,
              38.480001
            ],
            [
              -78.833991,
              38.453551
            ],
            [
              -78.827031,
              38.420552
            ],
            [
              -78.837168,
              38.417689
            ],
            [
              -78.88182,
              38.412128
            ],
            [
              -78.890002,
              38.390814
            ],
            [
              -78.914168,
              38.390026
            ],
            [
              -78.902201,
              38.42436
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "830",
      "COUNTYNS": "01789076",
      "AFFGEOID": "0500000US51830",
      "GEOID": "51830",
      "NAME": "Williamsburg",
      "LSAD": "25",
      "ALAND": 23155312,
      "AWATER": 416904,
      "County_state": "Williamsburg,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.741143,
              37.27338
            ],
            [
              -76.738554,
              37.261318
            ],
            [
              -76.727279,
              37.250243
            ],
            [
              -76.662466,
              37.251609
            ],
            [
              -76.684705,
              37.270068
            ],
            [
              -76.68187,
              37.292642
            ],
            [
              -76.710106,
              37.277362
            ],
            [
              -76.727859,
              37.305927
            ],
            [
              -76.737155,
              37.308308
            ],
            [
              -76.741143,
              37.27338
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "173",
      "COUNTYNS": "01500545",
      "AFFGEOID": "0500000US51173",
      "GEOID": "51173",
      "NAME": "Smyth",
      "LSAD": "06",
      "ALAND": 1168992134,
      "AWATER": 3519017,
      "County_state": "Smyth,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.818728,
              36.947258
            ],
            [
              -81.837096,
              36.929039
            ],
            [
              -81.740494,
              36.831002
            ],
            [
              -81.699561,
              36.789316
            ],
            [
              -81.61903,
              36.633997
            ],
            [
              -81.605865,
              36.638079
            ],
            [
              -81.559965,
              36.65867
            ],
            [
              -81.547229,
              36.65648
            ],
            [
              -81.533047,
              36.676308
            ],
            [
              -81.531409,
              36.693595
            ],
            [
              -81.543957,
              36.701517
            ],
            [
              -81.467913,
              36.715064
            ],
            [
              -81.439214,
              36.716908
            ],
            [
              -81.38403,
              36.739987
            ],
            [
              -81.300897,
              36.753561
            ],
            [
              -81.261987,
              36.763033
            ],
            [
              -81.272286,
              36.791786
            ],
            [
              -81.326985,
              36.899752
            ],
            [
              -81.379402,
              36.952442
            ],
            [
              -81.43673,
              37.010135
            ],
            [
              -81.480519,
              36.986291
            ],
            [
              -81.472497,
              36.998471
            ],
            [
              -81.474539,
              37.018476
            ],
            [
              -81.495026,
              37.014986
            ],
            [
              -81.510392,
              37.003429
            ],
            [
              -81.576298,
              36.989698
            ],
            [
              -81.640781,
              36.948506
            ],
            [
              -81.682786,
              36.932934
            ],
            [
              -81.708788,
              36.977011
            ],
            [
              -81.727857,
              36.969745
            ],
            [
              -81.780545,
              36.958629
            ],
            [
              -81.818728,
              36.947258
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "119",
      "COUNTYNS": "01480147",
      "AFFGEOID": "0500000US51119",
      "GEOID": "51119",
      "NAME": "Middlesex",
      "LSAD": "06",
      "ALAND": 337507714,
      "AWATER": 208357022,
      "County_state": "Middlesex,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.751072,
              37.728605
            ],
            [
              -76.751067,
              37.711665
            ],
            [
              -76.717955,
              37.657732
            ],
            [
              -76.682266,
              37.617242
            ],
            [
              -76.654076,
              37.603174
            ],
            [
              -76.636972,
              37.590842
            ],
            [
              -76.614983,
              37.583979
            ],
            [
              -76.595767,
              37.588079
            ],
            [
              -76.576416,
              37.575186
            ],
            [
              -76.57353,
              37.562946
            ],
            [
              -76.55593,
              37.555054
            ],
            [
              -76.55208,
              37.566374
            ],
            [
              -76.521998,
              37.548312
            ],
            [
              -76.507266,
              37.547505
            ],
            [
              -76.499163,
              37.536869
            ],
            [
              -76.481221,
              37.536217
            ],
            [
              -76.437561,
              37.51436
            ],
            [
              -76.418786,
              37.510385
            ],
            [
              -76.404118,
              37.535542
            ],
            [
              -76.35508448147058,
              37.527364175814895
            ],
            [
              -76.348992,
              37.536548
            ],
            [
              -76.339989,
              37.53833
            ],
            [
              -76.330598,
              37.536391
            ],
            [
              -76.302762,
              37.551295
            ],
            [
              -76.29796,
              37.557636
            ],
            [
              -76.300144,
              37.561734
            ],
            [
              -76.332641,
              37.570042
            ],
            [
              -76.357835,
              37.573699
            ],
            [
              -76.383188,
              37.573056
            ],
            [
              -76.410781,
              37.581815
            ],
            [
              -76.420252,
              37.598686
            ],
            [
              -76.435474,
              37.612807
            ],
            [
              -76.527188,
              37.611315
            ],
            [
              -76.533777,
              37.61253
            ],
            [
              -76.542666,
              37.616857
            ],
            [
              -76.574049,
              37.646781
            ],
            [
              -76.583143,
              37.661986
            ],
            [
              -76.579591,
              37.671508
            ],
            [
              -76.597868,
              37.702918
            ],
            [
              -76.598073,
              37.70912
            ],
            [
              -76.595943,
              37.712989
            ],
            [
              -76.597213,
              37.717269
            ],
            [
              -76.606466,
              37.724819
            ],
            [
              -76.61997,
              37.731271
            ],
            [
              -76.621433,
              37.737973
            ],
            [
              -76.617373,
              37.742347
            ],
            [
              -76.61971,
              37.744795
            ],
            [
              -76.639962,
              37.750941
            ],
            [
              -76.663887,
              37.751887
            ],
            [
              -76.677002,
              37.7561
            ],
            [
              -76.680922,
              37.759647
            ],
            [
              -76.683372,
              37.765507
            ],
            [
              -76.6833585934644,
              37.7702575365395
            ],
            [
              -76.703069,
              37.755714
            ],
            [
              -76.723086,
              37.756697
            ],
            [
              -76.751072,
              37.728605
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "510",
      "COUNTYNS": "01498415",
      "AFFGEOID": "0500000US51510",
      "GEOID": "51510",
      "NAME": "Alexandria",
      "LSAD": "25",
      "ALAND": 38679307,
      "AWATER": 1070269,
      "County_state": "Alexandria,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.141558,
              38.826083
            ],
            [
              -77.14367,
              38.813588
            ],
            [
              -77.137742,
              38.800757
            ],
            [
              -77.134491,
              38.798603
            ],
            [
              -77.115985,
              38.801812
            ],
            [
              -77.112205,
              38.802071
            ],
            [
              -77.09122,
              38.802462
            ],
            [
              -77.077701,
              38.800837
            ],
            [
              -77.077475,
              38.80081
            ],
            [
              -77.070008,
              38.799143
            ],
            [
              -77.040372554527,
              38.785355394851
            ],
            [
              -77.040098,
              38.789913
            ],
            [
              -77.039498,
              38.791113
            ],
            [
              -77.039006,
              38.791645
            ],
            [
              -77.038898,
              38.800813
            ],
            [
              -77.035798,
              38.814913
            ],
            [
              -77.038098,
              38.815613
            ],
            [
              -77.039098,
              38.821413
            ],
            [
              -77.038098,
              38.828612
            ],
            [
              -77.039199,
              38.832212
            ],
            [
              -77.041199,
              38.833712
            ],
            [
              -77.042599,
              38.833812
            ],
            [
              -77.043499,
              38.833212
            ],
            [
              -77.044899,
              38.834712
            ],
            [
              -77.044999,
              38.838512
            ],
            [
              -77.044487611898,
              38.839598699716696
            ],
            [
              -77.0524,
              38.840445
            ],
            [
              -77.064722,
              38.844677
            ],
            [
              -77.085696,
              38.843304
            ],
            [
              -77.087805,
              38.827357
            ],
            [
              -77.100722,
              38.836343
            ],
            [
              -77.106716,
              38.841934
            ],
            [
              -77.110671,
              38.84342
            ],
            [
              -77.110799,
              38.843446
            ],
            [
              -77.125235,
              38.841017
            ],
            [
              -77.141558,
              38.826083
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "025",
      "COUNTYNS": "01493961",
      "AFFGEOID": "0500000US51025",
      "GEOID": "51025",
      "NAME": "Brunswick",
      "LSAD": "06",
      "ALAND": 1466529494,
      "AWATER": 8387116,
      "County_state": "Brunswick,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.0462112119251,
              36.5441680334942
            ],
            [
              -78.03942,
              36.544196
            ],
            [
              -78.038938,
              36.544173
            ],
            [
              -77.8997732358759,
              36.5446629921276
            ],
            [
              -77.899771,
              36.544663
            ],
            [
              -77.882357,
              36.544737
            ],
            [
              -77.87528,
              36.544754
            ],
            [
              -77.86804674818559,
              36.5447981227554
            ],
            [
              -77.76712325037539,
              36.545413754966894
            ],
            [
              -77.655841,
              36.713539
            ],
            [
              -77.656537,
              36.757269
            ],
            [
              -77.658036,
              36.894514
            ],
            [
              -77.676526,
              36.904395
            ],
            [
              -77.713541,
              36.914139
            ],
            [
              -77.715683,
              36.927227
            ],
            [
              -77.745863,
              36.959042
            ],
            [
              -77.76554,
              36.974655
            ],
            [
              -77.794796,
              36.984453
            ],
            [
              -77.810111,
              36.983378
            ],
            [
              -77.840869,
              36.995934
            ],
            [
              -77.850695,
              36.988107
            ],
            [
              -77.889813,
              36.988818
            ],
            [
              -77.917549,
              36.983673
            ],
            [
              -77.968114,
              36.987292
            ],
            [
              -78.003639,
              37.022756
            ],
            [
              -78.021722,
              36.838773
            ],
            [
              -78.027411,
              36.777858
            ],
            [
              -78.0462112119251,
              36.5441680334942
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "600",
      "COUNTYNS": "01789070",
      "AFFGEOID": "0500000US51600",
      "GEOID": "51600",
      "NAME": "Fairfax",
      "LSAD": "25",
      "ALAND": 16156802,
      "AWATER": 90056,
      "County_state": "Fairfax,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.333327,
              38.85642
            ],
            [
              -77.334822,
              38.851492
            ],
            [
              -77.318689,
              38.834651
            ],
            [
              -77.297162,
              38.834984
            ],
            [
              -77.27052,
              38.840902
            ],
            [
              -77.26994,
              38.86504
            ],
            [
              -77.291659,
              38.867498
            ],
            [
              -77.292749,
              38.871617
            ],
            [
              -77.328788,
              38.864848
            ],
            [
              -77.333327,
              38.85642
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "683",
      "COUNTYNS": "01498430",
      "AFFGEOID": "0500000US51683",
      "GEOID": "51683",
      "NAME": "Manassas",
      "LSAD": "25",
      "ALAND": 25508044,
      "AWATER": 147721,
      "County_state": "Manassas,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.526661,
              38.733201
            ],
            [
              -77.524369,
              38.723929
            ],
            [
              -77.506239,
              38.706106
            ],
            [
              -77.503486,
              38.716632
            ],
            [
              -77.505434,
              38.720138
            ],
            [
              -77.508995,
              38.721291
            ],
            [
              -77.507611,
              38.72305
            ],
            [
              -77.513345,
              38.731107
            ],
            [
              -77.514999,
              38.735829
            ],
            [
              -77.506911,
              38.740537
            ],
            [
              -77.50065,
              38.737896
            ],
            [
              -77.493912,
              38.725451
            ],
            [
              -77.471177,
              38.727665
            ],
            [
              -77.454573,
              38.735569
            ],
            [
              -77.451321,
              38.73991
            ],
            [
              -77.449365,
              38.748858
            ],
            [
              -77.447707,
              38.760856
            ],
            [
              -77.458695,
              38.772319
            ],
            [
              -77.474711,
              38.781414
            ],
            [
              -77.47576,
              38.781171
            ],
            [
              -77.495453,
              38.770649
            ],
            [
              -77.526661,
              38.733201
            ]
          ],
          [
            [
              -77.479977,
              38.75157
            ],
            [
              -77.481139,
              38.756111
            ],
            [
              -77.475368,
              38.752113
            ],
            [
              -77.47706,
              38.750514
            ],
            [
              -77.479841,
              38.75089
            ],
            [
              -77.479977,
              38.75157
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "135",
      "COUNTYNS": "01493928",
      "AFFGEOID": "0500000US51135",
      "GEOID": "51135",
      "NAME": "Nottoway",
      "LSAD": "06",
      "ALAND": 814265581,
      "AWATER": 4297829,
      "County_state": "Nottoway,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.238161,
              37.194822
            ],
            [
              -78.239354,
              37.120089
            ],
            [
              -78.229944,
              37.096868
            ],
            [
              -78.19313,
              37.075298
            ],
            [
              -78.170489,
              37.075662
            ],
            [
              -78.17098,
              37.056832
            ],
            [
              -78.082652,
              37.016406
            ],
            [
              -78.079912,
              37.010573
            ],
            [
              -78.031788,
              37.014459
            ],
            [
              -78.003639,
              37.022756
            ],
            [
              -77.968114,
              36.987292
            ],
            [
              -77.917549,
              36.983673
            ],
            [
              -77.889813,
              36.988818
            ],
            [
              -77.900254,
              37.143885
            ],
            [
              -77.870524,
              37.150368
            ],
            [
              -77.841723,
              37.169839
            ],
            [
              -77.824987,
              37.167201
            ],
            [
              -77.805982,
              37.175933
            ],
            [
              -77.795926,
              37.192592
            ],
            [
              -77.826108,
              37.201986
            ],
            [
              -78.231234,
              37.29622
            ],
            [
              -78.238161,
              37.194822
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "153",
      "COUNTYNS": "01480161",
      "AFFGEOID": "0500000US51153",
      "GEOID": "51153",
      "NAME": "Prince William",
      "LSAD": "06",
      "ALAND": 869614721,
      "AWATER": 30829977,
      "County_state": "Prince William,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.481139,
              38.756111
            ],
            [
              -77.479977,
              38.75157
            ],
            [
              -77.479841,
              38.75089
            ],
            [
              -77.47706,
              38.750514
            ],
            [
              -77.475368,
              38.752113
            ],
            [
              -77.481139,
              38.756111
            ]
          ]
        ],
        [
          [
            [
              -77.715667,
              38.843224
            ],
            [
              -77.709962,
              38.823761
            ],
            [
              -77.679854,
              38.77862
            ],
            [
              -77.679735,
              38.77844
            ],
            [
              -77.669725,
              38.763418
            ],
            [
              -77.624978,
              38.696168
            ],
            [
              -77.579484,
              38.627653
            ],
            [
              -77.535485,
              38.561247
            ],
            [
              -77.531328,
              38.55655
            ],
            [
              -77.487481,
              38.591845
            ],
            [
              -77.464019,
              38.57867
            ],
            [
              -77.448793,
              38.581284
            ],
            [
              -77.425965,
              38.564997
            ],
            [
              -77.420911,
              38.563627
            ],
            [
              -77.396866,
              38.5472
            ],
            [
              -77.372027,
              38.521189
            ],
            [
              -77.334446,
              38.514116
            ],
            [
              -77.308814,
              38.500817
            ],
            [
              -77.3024995918579,
              38.5046243359563
            ],
            [
              -77.300776,
              38.506978
            ],
            [
              -77.291103,
              38.515721
            ],
            [
              -77.283503,
              38.525221
            ],
            [
              -77.281032064989,
              38.5301625268366
            ],
            [
              -77.276303,
              38.53962
            ],
            [
              -77.276603,
              38.54712
            ],
            [
              -77.26083,
              38.56533
            ],
            [
              -77.265304,
              38.580319
            ],
            [
              -77.26443,
              38.582845
            ],
            [
              -77.247003,
              38.590618
            ],
            [
              -77.245104,
              38.620717
            ],
            [
              -77.248904,
              38.628617
            ],
            [
              -77.246704,
              38.635217
            ],
            [
              -77.240604,
              38.638917
            ],
            [
              -77.2223497367265,
              38.6380907093288
            ],
            [
              -77.220718,
              38.647408
            ],
            [
              -77.240631,
              38.667749
            ],
            [
              -77.245756,
              38.672273
            ],
            [
              -77.250363,
              38.674154
            ],
            [
              -77.263463,
              38.686314
            ],
            [
              -77.275143,
              38.694174
            ],
            [
              -77.282567,
              38.696567
            ],
            [
              -77.299868,
              38.705162
            ],
            [
              -77.324705,
              38.701544
            ],
            [
              -77.320725,
              38.711717
            ],
            [
              -77.360395,
              38.722262
            ],
            [
              -77.369465,
              38.710283
            ],
            [
              -77.380526,
              38.722537
            ],
            [
              -77.388128,
              38.748716
            ],
            [
              -77.410385,
              38.749558
            ],
            [
              -77.413391,
              38.77253
            ],
            [
              -77.432739,
              38.77976
            ],
            [
              -77.443084,
              38.803653
            ],
            [
              -77.449042,
              38.803087
            ],
            [
              -77.4523,
              38.796334
            ],
            [
              -77.468234,
              38.796299
            ],
            [
              -77.476488,
              38.799334
            ],
            [
              -77.494359,
              38.798917
            ],
            [
              -77.490113,
              38.810033
            ],
            [
              -77.505238,
              38.816448
            ],
            [
              -77.503441,
              38.824776
            ],
            [
              -77.512368,
              38.830403
            ],
            [
              -77.508812,
              38.84107
            ],
            [
              -77.529616,
              38.837011
            ],
            [
              -77.535991,
              38.847368
            ],
            [
              -77.548752,
              38.846609
            ],
            [
              -77.55224,
              38.886588
            ],
            [
              -77.5916,
              38.897175
            ],
            [
              -77.61889,
              38.931408
            ],
            [
              -77.625044,
              38.931825
            ],
            [
              -77.637298,
              38.941672
            ],
            [
              -77.655169,
              38.942656
            ],
            [
              -77.665221,
              38.916736
            ],
            [
              -77.679432,
              38.895455
            ],
            [
              -77.692622,
              38.887004
            ],
            [
              -77.704931,
              38.843152
            ],
            [
              -77.715667,
              38.843224
            ]
          ],
          [
            [
              -77.524369,
              38.723929
            ],
            [
              -77.526661,
              38.733201
            ],
            [
              -77.495453,
              38.770649
            ],
            [
              -77.47576,
              38.781171
            ],
            [
              -77.478795,
              38.782583
            ],
            [
              -77.472972,
              38.788391
            ],
            [
              -77.444978,
              38.770564
            ],
            [
              -77.439897,
              38.783208
            ],
            [
              -77.432828,
              38.779303
            ],
            [
              -77.427332,
              38.753598
            ],
            [
              -77.458695,
              38.772319
            ],
            [
              -77.447707,
              38.760856
            ],
            [
              -77.449365,
              38.748858
            ],
            [
              -77.451321,
              38.73991
            ],
            [
              -77.454573,
              38.735569
            ],
            [
              -77.471177,
              38.727665
            ],
            [
              -77.493912,
              38.725451
            ],
            [
              -77.50065,
              38.737896
            ],
            [
              -77.506911,
              38.740537
            ],
            [
              -77.514999,
              38.735829
            ],
            [
              -77.513345,
              38.731107
            ],
            [
              -77.507611,
              38.72305
            ],
            [
              -77.508995,
              38.721291
            ],
            [
              -77.505434,
              38.720138
            ],
            [
              -77.503486,
              38.716632
            ],
            [
              -77.506239,
              38.706106
            ],
            [
              -77.524369,
              38.723929
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "167",
      "COUNTYNS": "01497573",
      "AFFGEOID": "0500000US51167",
      "GEOID": "51167",
      "NAME": "Russell",
      "LSAD": "06",
      "ALAND": 1226378115,
      "AWATER": 7588460,
      "County_state": "Russell,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.389739,
              36.885621
            ],
            [
              -82.406633,
              36.876356
            ],
            [
              -82.356356,
              36.779852
            ],
            [
              -82.332078,
              36.708969
            ],
            [
              -82.308651,
              36.726674
            ],
            [
              -82.241382,
              36.753549
            ],
            [
              -82.224516,
              36.762904
            ],
            [
              -82.103704,
              36.798521
            ],
            [
              -82.116897,
              36.817926
            ],
            [
              -82.107931,
              36.835369
            ],
            [
              -82.074139,
              36.856025
            ],
            [
              -81.981732,
              36.879587
            ],
            [
              -81.976049,
              36.869502
            ],
            [
              -81.94595,
              36.864607
            ],
            [
              -81.885255,
              36.889544
            ],
            [
              -81.837096,
              36.929039
            ],
            [
              -81.818728,
              36.947258
            ],
            [
              -81.780545,
              36.958629
            ],
            [
              -81.805419,
              37.018655
            ],
            [
              -81.815877,
              37.037943
            ],
            [
              -81.827205,
              37.053848
            ],
            [
              -81.891041,
              37.113128
            ],
            [
              -81.900892,
              37.142553
            ],
            [
              -81.914916,
              37.130706
            ],
            [
              -81.937582,
              37.126366
            ],
            [
              -81.963992,
              37.128086
            ],
            [
              -81.978483,
              37.12071
            ],
            [
              -81.990295,
              37.128695
            ],
            [
              -82.007716,
              37.119871
            ],
            [
              -82.020413,
              37.091048
            ],
            [
              -82.017675,
              37.068784
            ],
            [
              -82.031701,
              37.065198
            ],
            [
              -82.035864,
              37.051458
            ],
            [
              -82.050013,
              37.046167
            ],
            [
              -82.075701,
              37.038217
            ],
            [
              -82.096737,
              37.052033
            ],
            [
              -82.113447,
              37.039655
            ],
            [
              -82.149375,
              37.041641
            ],
            [
              -82.168374,
              37.041159
            ],
            [
              -82.184013,
              37.026797
            ],
            [
              -82.196419,
              37.03103
            ],
            [
              -82.246603,
              36.995716
            ],
            [
              -82.261926,
              36.98945
            ],
            [
              -82.284965,
              36.998429
            ],
            [
              -82.32783,
              36.970982
            ],
            [
              -82.298596,
              36.905761
            ],
            [
              -82.376216,
              36.890342
            ],
            [
              -82.389739,
              36.885621
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "029",
      "COUNTYNS": "01501502",
      "AFFGEOID": "0500000US51029",
      "GEOID": "51029",
      "NAME": "Buckingham",
      "LSAD": "06",
      "ALAND": 1501214582,
      "AWATER": 9895206,
      "County_state": "Buckingham,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.82914,
              37.594642
            ],
            [
              -78.830339,
              37.580942
            ],
            [
              -78.813339,
              37.568152
            ],
            [
              -78.825238,
              37.552542
            ],
            [
              -78.704187,
              37.45591
            ],
            [
              -78.683224,
              37.428974
            ],
            [
              -78.635714,
              37.416193
            ],
            [
              -78.640149,
              37.404031
            ],
            [
              -78.630175,
              37.38425
            ],
            [
              -78.615621,
              37.394052
            ],
            [
              -78.594436,
              37.397974
            ],
            [
              -78.580828,
              37.391515
            ],
            [
              -78.591669,
              37.3806
            ],
            [
              -78.579357,
              37.377362
            ],
            [
              -78.578951,
              37.364115
            ],
            [
              -78.569751,
              37.372218
            ],
            [
              -78.548482,
              37.346728
            ],
            [
              -78.536469,
              37.350462
            ],
            [
              -78.511491,
              37.329724
            ],
            [
              -78.490165,
              37.329612
            ],
            [
              -78.486567,
              37.340052
            ],
            [
              -78.465089,
              37.339677
            ],
            [
              -78.249021,
              37.635546
            ],
            [
              -78.239748,
              37.690495
            ],
            [
              -78.257794,
              37.691659
            ],
            [
              -78.284124,
              37.705444
            ],
            [
              -78.33605,
              37.716157
            ],
            [
              -78.365616,
              37.731897
            ],
            [
              -78.385096,
              37.733746
            ],
            [
              -78.422111,
              37.740451
            ],
            [
              -78.464149,
              37.7602
            ],
            [
              -78.491332,
              37.796971
            ],
            [
              -78.49954,
              37.761594
            ],
            [
              -78.512848,
              37.757222
            ],
            [
              -78.555694,
              37.7627
            ],
            [
              -78.580797,
              37.754047
            ],
            [
              -78.613919,
              37.755798
            ],
            [
              -78.643918,
              37.733084
            ],
            [
              -78.655935,
              37.703543
            ],
            [
              -78.640784,
              37.686104
            ],
            [
              -78.651966,
              37.678035
            ],
            [
              -78.664359,
              37.680181
            ],
            [
              -78.677134,
              37.69624
            ],
            [
              -78.695737,
              37.692943
            ],
            [
              -78.688241,
              37.678643
            ],
            [
              -78.710437,
              37.672443
            ],
            [
              -78.71904,
              37.661543
            ],
            [
              -78.712822,
              37.640262
            ],
            [
              -78.739437,
              37.631542
            ],
            [
              -78.788128,
              37.642038
            ],
            [
              -78.800539,
              37.641942
            ],
            [
              -78.82254,
              37.630642
            ],
            [
              -78.82214,
              37.619142
            ],
            [
              -78.811042,
              37.601741
            ],
            [
              -78.82914,
              37.594642
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "015",
      "COUNTYNS": "01480098",
      "AFFGEOID": "0500000US51015",
      "GEOID": "51015",
      "NAME": "Augusta",
      "LSAD": "06",
      "ALAND": 2504323820,
      "AWATER": 9922311,
      "County_state": "Augusta,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.52732,
              38.170797
            ],
            [
              -79.527044,
              38.151831
            ],
            [
              -79.509181,
              38.154939
            ],
            [
              -79.481603,
              38.170301
            ],
            [
              -79.458334,
              38.163057
            ],
            [
              -79.436678,
              38.1628
            ],
            [
              -79.445118,
              38.147568
            ],
            [
              -79.46918,
              38.124534
            ],
            [
              -79.493253,
              38.095384
            ],
            [
              -79.482668,
              38.086054
            ],
            [
              -79.452367,
              38.068092
            ],
            [
              -79.183978,
              37.914194
            ],
            [
              -79.15739,
              37.891003
            ],
            [
              -79.146639,
              37.888826
            ],
            [
              -79.118283,
              37.901083
            ],
            [
              -79.062283,
              37.91752
            ],
            [
              -79.052255,
              37.912794
            ],
            [
              -79.044552,
              37.890888
            ],
            [
              -79.017225,
              37.89345
            ],
            [
              -79.00579,
              37.881587
            ],
            [
              -78.981119,
              37.90596
            ],
            [
              -78.959068,
              37.920262
            ],
            [
              -78.942068,
              37.940237
            ],
            [
              -78.907278,
              37.945959
            ],
            [
              -78.893611,
              37.977148
            ],
            [
              -78.907566,
              37.976066
            ],
            [
              -78.905996,
              37.988249
            ],
            [
              -78.889757,
              37.995045
            ],
            [
              -78.873692,
              38.028624
            ],
            [
              -78.857456,
              38.03263
            ],
            [
              -78.854384,
              38.036452
            ],
            [
              -78.838874,
              38.04737
            ],
            [
              -78.792788,
              38.069504
            ],
            [
              -78.780879,
              38.081029
            ],
            [
              -78.788073,
              38.126404
            ],
            [
              -78.775432,
              38.141008
            ],
            [
              -78.768348,
              38.173465
            ],
            [
              -78.749396,
              38.206648
            ],
            [
              -78.818445,
              38.252729
            ],
            [
              -78.894094,
              38.306607
            ],
            [
              -78.913046,
              38.303645
            ],
            [
              -78.980426,
              38.341344
            ],
            [
              -79.073392,
              38.393427
            ],
            [
              -79.2243090862597,
              38.4776607800969
            ],
            [
              -79.225669,
              38.476471
            ],
            [
              -79.23162,
              38.474041
            ],
            [
              -79.234408,
              38.473011
            ],
            [
              -79.240059,
              38.469841
            ],
            [
              -79.242024,
              38.464332
            ],
            [
              -79.241854,
              38.457055
            ],
            [
              -79.242641,
              38.454168
            ],
            [
              -79.247342,
              38.453294
            ],
            [
              -79.253067,
              38.455818
            ],
            [
              -79.254435,
              38.455949
            ],
            [
              -79.261107,
              38.448082
            ],
            [
              -79.26291,
              38.444586
            ],
            [
              -79.263376,
              38.443762
            ],
            [
              -79.265327,
              38.441772
            ],
            [
              -79.267414,
              38.438322
            ],
            [
              -79.272064,
              38.437376
            ],
            [
              -79.274529,
              38.436337
            ],
            [
              -79.282225,
              38.432078
            ],
            [
              -79.282762,
              38.431647
            ],
            [
              -79.282663,
              38.431021
            ],
            [
              -79.28247,
              38.429168
            ],
            [
              -79.280581,
              38.426833
            ],
            [
              -79.280263,
              38.425475
            ],
            [
              -79.279678,
              38.424173
            ],
            [
              -79.280149,
              38.42076
            ],
            [
              -79.282971,
              38.418095
            ],
            [
              -79.285613,
              38.419249
            ],
            [
              -79.286874,
              38.420555
            ],
            [
              -79.288432,
              38.42096
            ],
            [
              -79.290529,
              38.420757
            ],
            [
              -79.291813,
              38.419627
            ],
            [
              -79.295712,
              38.418129
            ],
            [
              -79.297758,
              38.416438
            ],
            [
              -79.300081,
              38.414888
            ],
            [
              -79.312276,
              38.411876
            ],
            [
              -79.322823,
              38.401667
            ],
            [
              -79.308669,
              38.39393
            ],
            [
              -79.308495,
              38.382963
            ],
            [
              -79.324983,
              38.381359
            ],
            [
              -79.350835,
              38.355194
            ],
            [
              -79.387487,
              38.309051
            ],
            [
              -79.391411,
              38.292132
            ],
            [
              -79.400218,
              38.293538
            ],
            [
              -79.408647,
              38.278007
            ],
            [
              -79.399613,
              38.260675
            ],
            [
              -79.409738,
              38.243094
            ],
            [
              -79.43367,
              38.229493
            ],
            [
              -79.438103,
              38.216235
            ],
            [
              -79.464806,
              38.222231
            ],
            [
              -79.481132,
              38.216478
            ],
            [
              -79.483456,
              38.205399
            ],
            [
              -79.495946,
              38.200918
            ],
            [
              -79.512158,
              38.180419
            ],
            [
              -79.52732,
              38.170797
            ]
          ],
          [
            [
              -79.106469,
              38.140611
            ],
            [
              -79.11384,
              38.154611
            ],
            [
              -79.101543,
              38.179042
            ],
            [
              -79.093791,
              38.186287
            ],
            [
              -79.060144,
              38.18684
            ],
            [
              -79.044545,
              38.190043
            ],
            [
              -79.033789,
              38.199018
            ],
            [
              -79.022841,
              38.195645
            ],
            [
              -79.024808,
              38.192161
            ],
            [
              -79.011381,
              38.181737
            ],
            [
              -79.024304,
              38.176508
            ],
            [
              -79.012429,
              38.167268
            ],
            [
              -79.020308,
              38.143725
            ],
            [
              -79.030656,
              38.132835
            ],
            [
              -79.048755,
              38.122104
            ],
            [
              -79.06994,
              38.131022
            ],
            [
              -79.077799,
              38.12637
            ],
            [
              -79.086569,
              38.126964
            ],
            [
              -79.106469,
              38.140611
            ]
          ],
          [
            [
              -78.943198,
              38.06202
            ],
            [
              -78.950277,
              38.069486
            ],
            [
              -78.903795,
              38.099802
            ],
            [
              -78.879254,
              38.091649
            ],
            [
              -78.864449,
              38.095738
            ],
            [
              -78.855918,
              38.074652
            ],
            [
              -78.871469,
              38.040441
            ],
            [
              -78.877327,
              38.042746
            ],
            [
              -78.891772,
              38.039438
            ],
            [
              -78.901192,
              38.045255
            ],
            [
              -78.908001,
              38.043128
            ],
            [
              -78.921941,
              38.031403
            ],
            [
              -78.93023,
              38.049548
            ],
            [
              -78.946801,
              38.051985
            ],
            [
              -78.949417,
              38.056956
            ],
            [
              -78.943198,
              38.06202
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "113",
      "COUNTYNS": "01494590",
      "AFFGEOID": "0500000US51113",
      "GEOID": "51113",
      "NAME": "Madison",
      "LSAD": "06",
      "ALAND": 830426508,
      "AWATER": 3228803,
      "County_state": "Madison,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.436268,
              38.522146
            ],
            [
              -78.452801,
              38.475527
            ],
            [
              -78.43489,
              38.450113
            ],
            [
              -78.434184,
              38.41973
            ],
            [
              -78.440823,
              38.410075
            ],
            [
              -78.436083,
              38.394629
            ],
            [
              -78.423755,
              38.383027
            ],
            [
              -78.429674,
              38.367035
            ],
            [
              -78.411616,
              38.35058
            ],
            [
              -78.407554,
              38.34013
            ],
            [
              -78.381816,
              38.317923
            ],
            [
              -78.36388,
              38.315255
            ],
            [
              -78.35863,
              38.299973
            ],
            [
              -78.365383,
              38.29274
            ],
            [
              -78.34849,
              38.288536
            ],
            [
              -78.349501,
              38.277463
            ],
            [
              -78.304715,
              38.269192
            ],
            [
              -78.288837,
              38.272656
            ],
            [
              -78.267717,
              38.2637
            ],
            [
              -78.270424,
              38.250027
            ],
            [
              -78.252862,
              38.234521
            ],
            [
              -78.231833,
              38.230529
            ],
            [
              -78.221104,
              38.230089
            ],
            [
              -78.217333,
              38.242475
            ],
            [
              -78.192106,
              38.245593
            ],
            [
              -78.177738,
              38.254306
            ],
            [
              -78.183143,
              38.267554
            ],
            [
              -78.152165,
              38.265846
            ],
            [
              -78.15853,
              38.274298
            ],
            [
              -78.144747,
              38.283158
            ],
            [
              -78.126374,
              38.281922
            ],
            [
              -78.094994,
              38.303752
            ],
            [
              -78.094498,
              38.311242
            ],
            [
              -78.105009,
              38.31193
            ],
            [
              -78.082756,
              38.327931
            ],
            [
              -78.107784,
              38.343808
            ],
            [
              -78.117961,
              38.368822
            ],
            [
              -78.096545,
              38.385971
            ],
            [
              -78.101061,
              38.406577
            ],
            [
              -78.118312,
              38.416109
            ],
            [
              -78.118822,
              38.434382
            ],
            [
              -78.139781,
              38.449096
            ],
            [
              -78.231803,
              38.532539
            ],
            [
              -78.338176,
              38.627312
            ],
            [
              -78.360018,
              38.613783
            ],
            [
              -78.385662,
              38.582656
            ],
            [
              -78.380929,
              38.561307
            ],
            [
              -78.41376,
              38.547201
            ],
            [
              -78.419754,
              38.534396
            ],
            [
              -78.442767,
              38.527775
            ],
            [
              -78.436268,
              38.522146
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "059",
      "COUNTYNS": "01480119",
      "AFFGEOID": "0500000US51059",
      "GEOID": "51059",
      "NAME": "Fairfax",
      "LSAD": "06",
      "ALAND": 1012584627,
      "AWATER": 40233137,
      "County_state": "Fairfax,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.535991,
              38.847368
            ],
            [
              -77.529616,
              38.837011
            ],
            [
              -77.508812,
              38.84107
            ],
            [
              -77.512368,
              38.830403
            ],
            [
              -77.503441,
              38.824776
            ],
            [
              -77.505238,
              38.816448
            ],
            [
              -77.490113,
              38.810033
            ],
            [
              -77.494359,
              38.798917
            ],
            [
              -77.476488,
              38.799334
            ],
            [
              -77.468234,
              38.796299
            ],
            [
              -77.4523,
              38.796334
            ],
            [
              -77.449042,
              38.803087
            ],
            [
              -77.443084,
              38.803653
            ],
            [
              -77.432739,
              38.77976
            ],
            [
              -77.413391,
              38.77253
            ],
            [
              -77.410385,
              38.749558
            ],
            [
              -77.388128,
              38.748716
            ],
            [
              -77.380526,
              38.722537
            ],
            [
              -77.369465,
              38.710283
            ],
            [
              -77.360395,
              38.722262
            ],
            [
              -77.320725,
              38.711717
            ],
            [
              -77.324705,
              38.701544
            ],
            [
              -77.299868,
              38.705162
            ],
            [
              -77.282567,
              38.696567
            ],
            [
              -77.275143,
              38.694174
            ],
            [
              -77.263463,
              38.686314
            ],
            [
              -77.250363,
              38.674154
            ],
            [
              -77.245756,
              38.672273
            ],
            [
              -77.240631,
              38.667749
            ],
            [
              -77.220718,
              38.647408
            ],
            [
              -77.2223497367265,
              38.6380907093288
            ],
            [
              -77.216303,
              38.637817
            ],
            [
              -77.205103,
              38.623917
            ],
            [
              -77.204302,
              38.617817
            ],
            [
              -77.202002,
              38.617217
            ],
            [
              -77.174902,
              38.624217
            ],
            [
              -77.157501,
              38.636417
            ],
            [
              -77.1302,
              38.635017
            ],
            [
              -77.132201,
              38.641217
            ],
            [
              -77.131901,
              38.644217
            ],
            [
              -77.135901,
              38.649817
            ],
            [
              -77.1344628993383,
              38.6599678758177
            ],
            [
              -77.132501,
              38.673816
            ],
            [
              -77.122001,
              38.685816
            ],
            [
              -77.1059,
              38.696815
            ],
            [
              -77.1027,
              38.698315
            ],
            [
              -77.099,
              38.698615
            ],
            [
              -77.0918,
              38.703415
            ],
            [
              -77.0863289190155,
              38.70612807975
            ],
            [
              -77.079499,
              38.709515
            ],
            [
              -77.073714031001,
              38.7112534298651
            ],
            [
              -77.072807,
              38.711526
            ],
            [
              -77.071861,
              38.710581
            ],
            [
              -77.065322,
              38.709564
            ],
            [
              -77.053199,
              38.709915
            ],
            [
              -77.045498,
              38.714315
            ],
            [
              -77.042298,
              38.718515
            ],
            [
              -77.041398,
              38.724515
            ],
            [
              -77.040998,
              38.737914
            ],
            [
              -77.041898,
              38.741514
            ],
            [
              -77.04176714029269,
              38.7467155097909
            ],
            [
              -77.0412178195569,
              38.7685503223889
            ],
            [
              -77.041098,
              38.773313
            ],
            [
              -77.040372554527,
              38.785355394851
            ],
            [
              -77.070008,
              38.799143
            ],
            [
              -77.077475,
              38.80081
            ],
            [
              -77.077701,
              38.800837
            ],
            [
              -77.09122,
              38.802462
            ],
            [
              -77.112205,
              38.802071
            ],
            [
              -77.115985,
              38.801812
            ],
            [
              -77.134491,
              38.798603
            ],
            [
              -77.137742,
              38.800757
            ],
            [
              -77.14367,
              38.813588
            ],
            [
              -77.141558,
              38.826083
            ],
            [
              -77.125235,
              38.841017
            ],
            [
              -77.110799,
              38.843446
            ],
            [
              -77.121151,
              38.853325
            ],
            [
              -77.137631,
              38.866199
            ],
            [
              -77.149701,
              38.87567
            ],
            [
              -77.158128,
              38.873295
            ],
            [
              -77.189754,
              38.878136
            ],
            [
              -77.189922,
              38.894267
            ],
            [
              -77.194874,
              38.898876
            ],
            [
              -77.172276,
              38.893245
            ],
            [
              -77.16811,
              38.896521
            ],
            [
              -77.155774,
              38.906206
            ],
            [
              -77.145809,
              38.914006
            ],
            [
              -77.139427,
              38.918982
            ],
            [
              -77.124875,
              38.930408
            ],
            [
              -77.119759,
              38.934343
            ],
            [
              -77.127601,
              38.94001
            ],
            [
              -77.131901,
              38.94741
            ],
            [
              -77.137701,
              38.95531
            ],
            [
              -77.146601,
              38.96421
            ],
            [
              -77.148179,
              38.965002
            ],
            [
              -77.151084,
              38.965832
            ],
            [
              -77.165301,
              38.96801
            ],
            [
              -77.166901,
              38.96811
            ],
            [
              -77.168001,
              38.96741
            ],
            [
              -77.171901,
              38.96751
            ],
            [
              -77.183002,
              38.96881
            ],
            [
              -77.188302,
              38.96751
            ],
            [
              -77.197502,
              38.96681
            ],
            [
              -77.202502,
              38.96791
            ],
            [
              -77.203602,
              38.96891
            ],
            [
              -77.209302,
              38.97041
            ],
            [
              -77.211502,
              38.96941
            ],
            [
              -77.221502,
              38.97131
            ],
            [
              -77.224969,
              38.973349
            ],
            [
              -77.228395,
              38.978404
            ],
            [
              -77.229992,
              38.979858
            ],
            [
              -77.231601,
              38.979917
            ],
            [
              -77.232268,
              38.979502
            ],
            [
              -77.234803,
              38.97631
            ],
            [
              -77.249803,
              38.985909
            ],
            [
              -77.248303,
              38.992309
            ],
            [
              -77.249203,
              38.993709
            ],
            [
              -77.253003,
              38.995709
            ],
            [
              -77.255703,
              39.002409
            ],
            [
              -77.251803,
              39.011409
            ],
            [
              -77.246903,
              39.014809
            ],
            [
              -77.244603,
              39.020109
            ],
            [
              -77.246003,
              39.024909
            ],
            [
              -77.24838303158039,
              39.0268923596504
            ],
            [
              -77.248403,
              39.026909
            ],
            [
              -77.255303,
              39.030009
            ],
            [
              -77.266004,
              39.031909
            ],
            [
              -77.274706,
              39.034091
            ],
            [
              -77.293105,
              39.046508
            ],
            [
              -77.301005,
              39.049508
            ],
            [
              -77.310705,
              39.052008
            ],
            [
              -77.314905,
              39.052208
            ],
            [
              -77.324206,
              39.056508
            ],
            [
              -77.32828109319901,
              39.0577948715365
            ],
            [
              -77.371103,
              39.01448
            ],
            [
              -77.3783,
              39.007205
            ],
            [
              -77.378344,
              39.007161
            ],
            [
              -77.400955,
              38.984292
            ],
            [
              -77.40257,
              38.982649
            ],
            [
              -77.432198,
              38.952673
            ],
            [
              -77.473248,
              38.911077
            ],
            [
              -77.496821,
              38.887154
            ],
            [
              -77.535991,
              38.847368
            ]
          ],
          [
            [
              -77.334822,
              38.851492
            ],
            [
              -77.333327,
              38.85642
            ],
            [
              -77.328788,
              38.864848
            ],
            [
              -77.292749,
              38.871617
            ],
            [
              -77.291659,
              38.867498
            ],
            [
              -77.26994,
              38.86504
            ],
            [
              -77.27052,
              38.840902
            ],
            [
              -77.297162,
              38.834984
            ],
            [
              -77.318689,
              38.834651
            ],
            [
              -77.334822,
              38.851492
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "193",
      "COUNTYNS": "01480179",
      "AFFGEOID": "0500000US51193",
      "GEOID": "51193",
      "NAME": "Westmoreland",
      "LSAD": "06",
      "ALAND": 594054337,
      "AWATER": 61106324,
      "County_state": "Westmoreland,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.064289,
              38.171666
            ],
            [
              -77.061695,
              38.161631
            ],
            [
              -77.052195,
              38.144331
            ],
            [
              -77.049695,
              38.109832
            ],
            [
              -77.031675,
              38.091261
            ],
            [
              -77.014693,
              38.093532
            ],
            [
              -77.004793,
              38.108332
            ],
            [
              -76.987732,
              38.102352
            ],
            [
              -76.984018,
              38.087494
            ],
            [
              -76.936959,
              38.077074
            ],
            [
              -76.876918,
              38.124238
            ],
            [
              -76.86339,
              38.102635
            ],
            [
              -76.822843,
              38.065177
            ],
            [
              -76.797574,
              38.058557
            ],
            [
              -76.759001,
              38.032046
            ],
            [
              -76.75886,
              38.010625
            ],
            [
              -76.765961,
              38.001546
            ],
            [
              -76.748871,
              37.994897
            ],
            [
              -76.728989,
              37.99919
            ],
            [
              -76.700569,
              37.984545
            ],
            [
              -76.656292,
              37.982169
            ],
            [
              -76.648333,
              37.96867
            ],
            [
              -76.63423,
              37.96663
            ],
            [
              -76.616294,
              38.001623
            ],
            [
              -76.590917,
              38.003725
            ],
            [
              -76.571899,
              38.027046
            ],
            [
              -76.5195360450964,
              38.034814055558
            ],
            [
              -76.522354,
              38.04259
            ],
            [
              -76.535919,
              38.069532
            ],
            [
              -76.543155,
              38.076971
            ],
            [
              -76.579497,
              38.09487
            ],
            [
              -76.600937,
              38.110084
            ],
            [
              -76.604131,
              38.128771
            ],
            [
              -76.613939,
              38.148587
            ],
            [
              -76.629476,
              38.15305
            ],
            [
              -76.638983,
              38.151476
            ],
            [
              -76.643448,
              38.14825
            ],
            [
              -76.665127,
              38.147638
            ],
            [
              -76.684892,
              38.156497
            ],
            [
              -76.701297,
              38.155718
            ],
            [
              -76.704048,
              38.149264
            ],
            [
              -76.721722,
              38.137635
            ],
            [
              -76.738938,
              38.14651
            ],
            [
              -76.7391046738335,
              38.1472953571529
            ],
            [
              -76.740278,
              38.152824
            ],
            [
              -76.743064,
              38.156988
            ],
            [
              -76.749685,
              38.162114
            ],
            [
              -76.760241,
              38.166581
            ],
            [
              -76.7658799790815,
              38.16710443097559
            ],
            [
              -76.788445,
              38.169199
            ],
            [
              -76.802968,
              38.167988
            ],
            [
              -76.824274,
              38.163639
            ],
            [
              -76.838795,
              38.163476
            ],
            [
              -76.875272,
              38.172207
            ],
            [
              -76.910832,
              38.197073
            ],
            [
              -76.916922,
              38.199751
            ],
            [
              -76.937134,
              38.202384
            ],
            [
              -76.962311,
              38.214075
            ],
            [
              -76.967335,
              38.227185
            ],
            [
              -76.966553,
              38.229542
            ],
            [
              -76.962375,
              38.230093
            ],
            [
              -76.957417,
              38.236341
            ],
            [
              -76.957796,
              38.243183
            ],
            [
              -76.96215,
              38.256486
            ],
            [
              -76.981372,
              38.274214
            ],
            [
              -76.990255,
              38.273935
            ],
            [
              -76.99767,
              38.278047
            ],
            [
              -77.044799,
              38.260407
            ],
            [
              -77.047627,
              38.200235
            ],
            [
              -77.050215,
              38.177916
            ],
            [
              -77.064289,
              38.171666
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "103",
      "COUNTYNS": "01480139",
      "AFFGEOID": "0500000US51103",
      "GEOID": "51103",
      "NAME": "Lancaster",
      "LSAD": "06",
      "ALAND": 345240091,
      "AWATER": 254112966,
      "County_state": "Lancaster,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.629799,
              37.805219
            ],
            [
              -76.6422756727082,
              37.7923167043293
            ],
            [
              -76.615351,
              37.780759
            ],
            [
              -76.602024,
              37.772731
            ],
            [
              -76.595939,
              37.77168
            ],
            [
              -76.593835,
              37.772848
            ],
            [
              -76.584289,
              37.76889
            ],
            [
              -76.576387,
              37.757493
            ],
            [
              -76.560476,
              37.727827
            ],
            [
              -76.54005,
              37.704432
            ],
            [
              -76.537228,
              37.698892
            ],
            [
              -76.535302,
              37.687516
            ],
            [
              -76.537698,
              37.66893
            ],
            [
              -76.536548,
              37.663574
            ],
            [
              -76.510187,
              37.642324
            ],
            [
              -76.501522,
              37.643762
            ],
            [
              -76.497564,
              37.647056
            ],
            [
              -76.491799,
              37.663614
            ],
            [
              -76.489576,
              37.666201
            ],
            [
              -76.472392,
              37.665772
            ],
            [
              -76.443254,
              37.652347
            ],
            [
              -76.399236,
              37.628636
            ],
            [
              -76.390054,
              37.630326
            ],
            [
              -76.381106,
              37.627003
            ],
            [
              -76.36232,
              37.610368
            ],
            [
              -76.309174,
              37.621892
            ],
            [
              -76.28037,
              37.613715
            ],
            [
              -76.279447,
              37.618225
            ],
            [
              -76.287959,
              37.631771
            ],
            [
              -76.292534,
              37.636098
            ],
            [
              -76.306464,
              37.642005
            ],
            [
              -76.332562,
              37.645817
            ],
            [
              -76.339892,
              37.655966
            ],
            [
              -76.324808,
              37.676983
            ],
            [
              -76.32021562464779,
              37.68066613549289
            ],
            [
              -76.34461,
              37.690935
            ],
            [
              -76.361017,
              37.708432
            ],
            [
              -76.375964,
              37.711498
            ],
            [
              -76.364348,
              37.728353
            ],
            [
              -76.364434,
              37.768521
            ],
            [
              -76.381917,
              37.779534
            ],
            [
              -76.401056,
              37.780249
            ],
            [
              -76.419696,
              37.806916
            ],
            [
              -76.420071,
              37.823766
            ],
            [
              -76.429636,
              37.829043
            ],
            [
              -76.44148,
              37.822253
            ],
            [
              -76.468482,
              37.8229
            ],
            [
              -76.477639,
              37.837394
            ],
            [
              -76.507828,
              37.838814
            ],
            [
              -76.564735,
              37.837288
            ],
            [
              -76.58564,
              37.811821
            ],
            [
              -76.629799,
              37.805219
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "057",
      "COUNTYNS": "01480118",
      "AFFGEOID": "0500000US51057",
      "GEOID": "51057",
      "NAME": "Essex",
      "LSAD": "06",
      "ALAND": 665909923,
      "AWATER": 74784989,
      "County_state": "Essex,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.1701,
              38.079543
            ],
            [
              -77.159137,
              38.064102
            ],
            [
              -77.129437,
              38.044992
            ],
            [
              -77.121793,
              38.033628
            ],
            [
              -77.130691,
              38.017731
            ],
            [
              -77.103296,
              38.011334
            ],
            [
              -77.08897,
              38.013644
            ],
            [
              -77.072658,
              37.994739
            ],
            [
              -77.070115,
              37.96477
            ],
            [
              -77.073036,
              37.94616
            ],
            [
              -77.059695,
              37.943243
            ],
            [
              -77.055028,
              37.931016
            ],
            [
              -77.033619,
              37.925278
            ],
            [
              -77.025066,
              37.903285
            ],
            [
              -77.031458,
              37.890808
            ],
            [
              -77.020245,
              37.885276
            ],
            [
              -77.041353,
              37.869299
            ],
            [
              -77.020204,
              37.86105
            ],
            [
              -77.018996,
              37.844883
            ],
            [
              -77.00326,
              37.836038
            ],
            [
              -76.981638,
              37.839027
            ],
            [
              -76.969882,
              37.828592
            ],
            [
              -76.945212,
              37.828174
            ],
            [
              -76.943221,
              37.782955
            ],
            [
              -76.906446,
              37.796236
            ],
            [
              -76.87231,
              37.782729
            ],
            [
              -76.842628,
              37.79804
            ],
            [
              -76.804423,
              37.794892
            ],
            [
              -76.790992,
              37.786734
            ],
            [
              -76.790036,
              37.771844
            ],
            [
              -76.77208,
              37.762322
            ],
            [
              -76.755812,
              37.744497
            ],
            [
              -76.751072,
              37.728605
            ],
            [
              -76.723086,
              37.756697
            ],
            [
              -76.703069,
              37.755714
            ],
            [
              -76.6833585934644,
              37.7702575365395
            ],
            [
              -76.683343,
              37.775783
            ],
            [
              -76.681901,
              37.778118
            ],
            [
              -76.683775,
              37.781391
            ],
            [
              -76.689773,
              37.78519
            ],
            [
              -76.715498,
              37.785873
            ],
            [
              -76.723863,
              37.788503
            ],
            [
              -76.734309,
              37.79866
            ],
            [
              -76.7512,
              37.824141
            ],
            [
              -76.766328,
              37.840437
            ],
            [
              -76.782826,
              37.863184
            ],
            [
              -76.784618,
              37.869569
            ],
            [
              -76.806325,
              37.896597
            ],
            [
              -76.84618,
              37.918513
            ],
            [
              -76.888206,
              37.951906
            ],
            [
              -76.904962,
              37.972519
            ],
            [
              -76.927423,
              37.981793
            ],
            [
              -76.918272,
              37.999026
            ],
            [
              -76.9064359784404,
              37.9987793062827
            ],
            [
              -76.928169,
              38.073736
            ],
            [
              -76.936959,
              38.077074
            ],
            [
              -76.984018,
              38.087494
            ],
            [
              -76.987732,
              38.102352
            ],
            [
              -77.004793,
              38.108332
            ],
            [
              -77.014693,
              38.093532
            ],
            [
              -77.031675,
              38.091261
            ],
            [
              -77.049695,
              38.109832
            ],
            [
              -77.052195,
              38.144331
            ],
            [
              -77.061695,
              38.161631
            ],
            [
              -77.073796,
              38.141331
            ],
            [
              -77.084596,
              38.160231
            ],
            [
              -77.115997,
              38.149931
            ],
            [
              -77.105632,
              38.126235
            ],
            [
              -77.116847,
              38.120641
            ],
            [
              -77.144408,
              38.120851
            ],
            [
              -77.145262,
              38.094261
            ],
            [
              -77.1701,
              38.079543
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "019",
      "COUNTYNS": "01674818",
      "AFFGEOID": "0500000US51019",
      "GEOID": "51019",
      "NAME": "Bedford",
      "LSAD": "06",
      "ALAND": 1968605215,
      "AWATER": 42120242,
      "County_state": "Bedford,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.858405,
              37.267155
            ],
            [
              -79.846472,
              37.247386
            ],
            [
              -79.847217,
              37.225406
            ],
            [
              -79.833008,
              37.216049
            ],
            [
              -79.818161,
              37.226201
            ],
            [
              -79.806065,
              37.215174
            ],
            [
              -79.781194,
              37.232558
            ],
            [
              -79.776606,
              37.202627
            ],
            [
              -79.757703,
              37.189968
            ],
            [
              -79.726999,
              37.191388
            ],
            [
              -79.722359,
              37.192307
            ],
            [
              -79.704648,
              37.151486
            ],
            [
              -79.702355,
              37.164295
            ],
            [
              -79.687369,
              37.149525
            ],
            [
              -79.678028,
              37.155165
            ],
            [
              -79.651194,
              37.126749
            ],
            [
              -79.641069,
              37.103113
            ],
            [
              -79.61715,
              37.092434
            ],
            [
              -79.623003,
              37.082311
            ],
            [
              -79.593061,
              37.052868
            ],
            [
              -79.594069,
              37.042206
            ],
            [
              -79.562621,
              37.035017
            ],
            [
              -79.559479,
              37.0439
            ],
            [
              -79.542296,
              37.045872
            ],
            [
              -79.495178,
              37.021965
            ],
            [
              -79.479921,
              37.006035
            ],
            [
              -79.461195,
              37.021657
            ],
            [
              -79.483272,
              37.03529
            ],
            [
              -79.480183,
              37.043506
            ],
            [
              -79.491249,
              37.058329
            ],
            [
              -79.477847,
              37.064244
            ],
            [
              -79.442859,
              37.055867
            ],
            [
              -79.330477,
              37.238418
            ],
            [
              -79.294847,
              37.297116
            ],
            [
              -79.257955,
              37.355816
            ],
            [
              -79.233115,
              37.393069
            ],
            [
              -79.23378,
              37.39525
            ],
            [
              -79.254385,
              37.404493
            ],
            [
              -79.24494,
              37.420664
            ],
            [
              -79.26838,
              37.431251
            ],
            [
              -79.266986,
              37.447868
            ],
            [
              -79.246499,
              37.447286
            ],
            [
              -79.210023,
              37.468709
            ],
            [
              -79.185702,
              37.46582
            ],
            [
              -79.189482,
              37.475278
            ],
            [
              -79.209467,
              37.477518
            ],
            [
              -79.225093,
              37.494444
            ],
            [
              -79.223714,
              37.506344
            ],
            [
              -79.241027,
              37.505875
            ],
            [
              -79.257502,
              37.494318
            ],
            [
              -79.262593,
              37.508055
            ],
            [
              -79.298105,
              37.500925
            ],
            [
              -79.313804,
              37.510224
            ],
            [
              -79.336604,
              37.515355
            ],
            [
              -79.347687,
              37.523283
            ],
            [
              -79.367342,
              37.551148
            ],
            [
              -79.380342,
              37.590551
            ],
            [
              -79.404525,
              37.607514
            ],
            [
              -79.424213,
              37.601078
            ],
            [
              -79.436111,
              37.605526
            ],
            [
              -79.436947,
              37.617057
            ],
            [
              -79.457967,
              37.605835
            ],
            [
              -79.446226,
              37.576255
            ],
            [
              -79.434068,
              37.572327
            ],
            [
              -79.478987,
              37.548379
            ],
            [
              -79.498699,
              37.532797
            ],
            [
              -79.518941,
              37.508447
            ],
            [
              -79.546351,
              37.489194
            ],
            [
              -79.567365,
              37.487717
            ],
            [
              -79.588526,
              37.449596
            ],
            [
              -79.617181,
              37.458017
            ],
            [
              -79.626124,
              37.455284
            ],
            [
              -79.634206,
              37.457021
            ],
            [
              -79.63977,
              37.471097
            ],
            [
              -79.658798,
              37.48501
            ],
            [
              -79.69006,
              37.476374
            ],
            [
              -79.719076,
              37.448957
            ],
            [
              -79.756185,
              37.425384
            ],
            [
              -79.79049,
              37.40879
            ],
            [
              -79.814203,
              37.403654
            ],
            [
              -79.792185,
              37.380822
            ],
            [
              -79.778616,
              37.381783
            ],
            [
              -79.788151,
              37.351116
            ],
            [
              -79.844198,
              37.31775
            ],
            [
              -79.847476,
              37.309352
            ],
            [
              -79.839601,
              37.301021
            ],
            [
              -79.858405,
              37.267155
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "161",
      "COUNTYNS": "01480165",
      "AFFGEOID": "0500000US51161",
      "GEOID": "51161",
      "NAME": "Roanoke",
      "LSAD": "06",
      "ALAND": 648883978,
      "AWATER": 1762701,
      "County_state": "Roanoke,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.262184,
              37.341526
            ],
            [
              -80.258577,
              37.308702
            ],
            [
              -80.238936,
              37.286222
            ],
            [
              -80.205091,
              37.248116
            ],
            [
              -80.189592,
              37.233449
            ],
            [
              -80.177673,
              37.133092
            ],
            [
              -80.178125,
              37.1134
            ],
            [
              -80.173492,
              37.109095
            ],
            [
              -80.128674,
              37.122901
            ],
            [
              -80.086955,
              37.161198
            ],
            [
              -80.082765,
              37.160908
            ],
            [
              -80.0092,
              37.172708
            ],
            [
              -80.005309,
              37.167816
            ],
            [
              -80.001182,
              37.151665
            ],
            [
              -79.962822,
              37.137274
            ],
            [
              -79.922488,
              37.160769
            ],
            [
              -79.895123,
              37.181834
            ],
            [
              -79.883496,
              37.209445
            ],
            [
              -79.852106,
              37.214969
            ],
            [
              -79.847217,
              37.225406
            ],
            [
              -79.846472,
              37.247386
            ],
            [
              -79.858405,
              37.267155
            ],
            [
              -79.839601,
              37.301021
            ],
            [
              -79.847476,
              37.309352
            ],
            [
              -79.865882,
              37.328752
            ],
            [
              -79.887616,
              37.338114
            ],
            [
              -79.929296,
              37.356039
            ],
            [
              -79.945616,
              37.362997
            ],
            [
              -80.062622,
              37.408325
            ],
            [
              -80.074119,
              37.422469
            ],
            [
              -80.147679,
              37.382492
            ],
            [
              -80.200301,
              37.378356
            ],
            [
              -80.246508,
              37.353942
            ],
            [
              -80.262184,
              37.341526
            ]
          ],
          [
            [
              -80.093675,
              37.282502
            ],
            [
              -80.12049,
              37.287053
            ],
            [
              -80.084224,
              37.297499
            ],
            [
              -80.071712,
              37.311952
            ],
            [
              -80.033596,
              37.324293
            ],
            [
              -80.022226,
              37.308338
            ],
            [
              -80.017173,
              37.314281
            ],
            [
              -79.989021,
              37.327208
            ],
            [
              -79.974023,
              37.335419
            ],
            [
              -79.959013,
              37.320139
            ],
            [
              -79.949734,
              37.326254
            ],
            [
              -79.936468,
              37.321022
            ],
            [
              -79.929269,
              37.320056
            ],
            [
              -79.930578,
              37.321801
            ],
            [
              -79.922294,
              37.32716
            ],
            [
              -79.918066,
              37.328414
            ],
            [
              -79.919223,
              37.320866
            ],
            [
              -79.912493,
              37.319643
            ],
            [
              -79.90546,
              37.321835
            ],
            [
              -79.901744,
              37.317957
            ],
            [
              -79.886652,
              37.309757
            ],
            [
              -79.884236,
              37.283686
            ],
            [
              -79.917784,
              37.280719
            ],
            [
              -79.904671,
              37.266607
            ],
            [
              -79.900048,
              37.257297
            ],
            [
              -79.919301,
              37.241295
            ],
            [
              -79.924496,
              37.224634
            ],
            [
              -79.936217,
              37.227136
            ],
            [
              -79.955005,
              37.211988
            ],
            [
              -79.971437,
              37.229567
            ],
            [
              -79.973967,
              37.231265
            ],
            [
              -79.981429,
              37.229726
            ],
            [
              -79.998191,
              37.252158
            ],
            [
              -80.00645,
              37.246323
            ],
            [
              -80.008971,
              37.248901
            ],
            [
              -80.02878,
              37.243694
            ],
            [
              -80.037477,
              37.251804
            ],
            [
              -80.033464,
              37.26289
            ],
            [
              -80.051202,
              37.2522
            ],
            [
              -80.074512,
              37.257062
            ],
            [
              -80.081631,
              37.265952
            ],
            [
              -80.093675,
              37.282502
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "630",
      "COUNTYNS": "01498425",
      "AFFGEOID": "0500000US51630",
      "GEOID": "51630",
      "NAME": "Fredericksburg",
      "LSAD": "25",
      "ALAND": 27067954,
      "AWATER": 178255,
      "County_state": "Fredericksburg,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -77.527185,
              38.320655
            ],
            [
              -77.530283,
              38.309175
            ],
            [
              -77.514803,
              38.305331
            ],
            [
              -77.516623,
              38.29501
            ],
            [
              -77.512009,
              38.279871
            ],
            [
              -77.492015,
              38.273719
            ],
            [
              -77.473699,
              38.270329
            ],
            [
              -77.460561,
              38.277843
            ],
            [
              -77.457512,
              38.271402
            ],
            [
              -77.447126,
              38.284614
            ],
            [
              -77.467053,
              38.31866
            ],
            [
              -77.471358,
              38.319528
            ],
            [
              -77.481386,
              38.316477
            ],
            [
              -77.502307,
              38.325902
            ],
            [
              -77.527185,
              38.320655
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "810",
      "COUNTYNS": "01498559",
      "AFFGEOID": "0500000US51810",
      "GEOID": "51810",
      "NAME": "Virginia Beach",
      "LSAD": "25",
      "ALAND": 633823900,
      "AWATER": 654692027,
      "County_state": "Virginia Beach,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -76.22606,
              36.839947
            ],
            [
              -76.22806,
              36.832874
            ],
            [
              -76.212882,
              36.794165
            ],
            [
              -76.2037,
              36.769742
            ],
            [
              -76.195246,
              36.76692
            ],
            [
              -76.186531,
              36.763747
            ],
            [
              -76.143253,
              36.757045
            ],
            [
              -76.100054,
              36.718177
            ],
            [
              -76.098455,
              36.71675
            ],
            [
              -76.082154,
              36.71245
            ],
            [
              -76.065553,
              36.68555
            ],
            [
              -76.069153,
              36.67745
            ],
            [
              -76.121955,
              36.66575
            ],
            [
              -76.122364,
              36.645709
            ],
            [
              -76.1223521611215,
              36.55062091212349
            ],
            [
              -76.034751,
              36.550653
            ],
            [
              -76.02675,
              36.550553
            ],
            [
              -75.957648,
              36.550553
            ],
            [
              -75.955748,
              36.550553
            ],
            [
              -75.953447,
              36.550553
            ],
            [
              -75.952847,
              36.550553
            ],
            [
              -75.922046,
              36.550654
            ],
            [
              -75.911446,
              36.550654
            ],
            [
              -75.909046,
              36.550654
            ],
            [
              -75.904745,
              36.550654
            ],
            [
              -75.903445,
              36.550654
            ],
            [
              -75.9010274253405,
              36.55067999542639
            ],
            [
              -75.894145,
              36.550754
            ],
            [
              -75.893245,
              36.550654
            ],
            [
              -75.891945,
              36.550754
            ],
            [
              -75.886545,
              36.550754
            ],
            [
              -75.885945,
              36.550754
            ],
            [
              -75.880644,
              36.550754
            ],
            [
              -75.879744,
              36.550754
            ],
            [
              -75.867044,
              36.550754
            ],
            [
              -75.874145,
              36.583853
            ],
            [
              -75.890946,
              36.630753
            ],
            [
              -75.921748,
              36.692051
            ],
            [
              -75.94955,
              36.76115
            ],
            [
              -75.965451,
              36.812449
            ],
            [
              -75.972151,
              36.842268
            ],
            [
              -75.9730919562015,
              36.8455941087234
            ],
            [
              -75.991552,
              36.910847
            ],
            [
              -75.996252,
              36.922047
            ],
            [
              -76.007553,
              36.929047
            ],
            [
              -76.013753,
              36.930746
            ],
            [
              -76.033454,
              36.931946
            ],
            [
              -76.043054,
              36.927547
            ],
            [
              -76.058154,
              36.916947
            ],
            [
              -76.087955,
              36.908647
            ],
            [
              -76.09160752323359,
              36.9087292095789
            ],
            [
              -76.095508,
              36.908817
            ],
            [
              -76.1295205276954,
              36.919804724332096
            ],
            [
              -76.139557,
              36.923047
            ],
            [
              -76.159451141253,
              36.926362558639006
            ],
            [
              -76.17701923197801,
              36.9292904575734
            ],
            [
              -76.1861912090013,
              36.9308190597518
            ],
            [
              -76.18915,
              36.918978
            ],
            [
              -76.192547,
              36.918077
            ],
            [
              -76.190231,
              36.91329
            ],
            [
              -76.1892,
              36.912034
            ],
            [
              -76.187011,
              36.91055
            ],
            [
              -76.182012,
              36.906668
            ],
            [
              -76.193122,
              36.877425
            ],
            [
              -76.193259,
              36.865247
            ],
            [
              -76.177637,
              36.854364
            ],
            [
              -76.197259,
              36.828148
            ],
            [
              -76.213959,
              36.832347
            ],
            [
              -76.22606,
              36.839947
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "125",
      "COUNTYNS": "01500936",
      "AFFGEOID": "0500000US51125",
      "GEOID": "51125",
      "NAME": "Nelson",
      "LSAD": "06",
      "ALAND": 1219239663,
      "AWATER": 8987274,
      "County_state": "Nelson,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.144388,
              37.856096
            ],
            [
              -79.172131,
              37.803097
            ],
            [
              -79.137964,
              37.814141
            ],
            [
              -79.121505,
              37.802418
            ],
            [
              -79.109485,
              37.784691
            ],
            [
              -79.089887,
              37.787891
            ],
            [
              -79.071417,
              37.778303
            ],
            [
              -79.065845,
              37.74634
            ],
            [
              -79.067355,
              37.720255
            ],
            [
              -79.030946,
              37.702646
            ],
            [
              -79.014477,
              37.705285
            ],
            [
              -79.005982,
              37.697475
            ],
            [
              -78.986017,
              37.695546
            ],
            [
              -78.983322,
              37.682554
            ],
            [
              -78.960308,
              37.656258
            ],
            [
              -78.897905,
              37.543857
            ],
            [
              -78.869245,
              37.542092
            ],
            [
              -78.824938,
              37.537342
            ],
            [
              -78.825238,
              37.552542
            ],
            [
              -78.813339,
              37.568152
            ],
            [
              -78.830339,
              37.580942
            ],
            [
              -78.82914,
              37.594642
            ],
            [
              -78.811042,
              37.601741
            ],
            [
              -78.82214,
              37.619142
            ],
            [
              -78.82254,
              37.630642
            ],
            [
              -78.800539,
              37.641942
            ],
            [
              -78.788128,
              37.642038
            ],
            [
              -78.739437,
              37.631542
            ],
            [
              -78.712822,
              37.640262
            ],
            [
              -78.71904,
              37.661543
            ],
            [
              -78.710437,
              37.672443
            ],
            [
              -78.688241,
              37.678643
            ],
            [
              -78.695737,
              37.692943
            ],
            [
              -78.677134,
              37.69624
            ],
            [
              -78.664359,
              37.680181
            ],
            [
              -78.651966,
              37.678035
            ],
            [
              -78.640784,
              37.686104
            ],
            [
              -78.655935,
              37.703543
            ],
            [
              -78.643918,
              37.733084
            ],
            [
              -78.668862,
              37.724752
            ],
            [
              -78.686087,
              37.737505
            ],
            [
              -78.683104,
              37.759992
            ],
            [
              -78.667455,
              37.759409
            ],
            [
              -78.684307,
              37.773843
            ],
            [
              -78.682986,
              37.78206
            ],
            [
              -78.753516,
              37.902557
            ],
            [
              -78.8014,
              37.982628
            ],
            [
              -78.838874,
              38.04737
            ],
            [
              -78.854384,
              38.036452
            ],
            [
              -78.857456,
              38.03263
            ],
            [
              -78.873692,
              38.028624
            ],
            [
              -78.889757,
              37.995045
            ],
            [
              -78.905996,
              37.988249
            ],
            [
              -78.907566,
              37.976066
            ],
            [
              -78.893611,
              37.977148
            ],
            [
              -78.907278,
              37.945959
            ],
            [
              -78.942068,
              37.940237
            ],
            [
              -78.959068,
              37.920262
            ],
            [
              -78.981119,
              37.90596
            ],
            [
              -79.00579,
              37.881587
            ],
            [
              -79.017225,
              37.89345
            ],
            [
              -79.044552,
              37.890888
            ],
            [
              -79.052255,
              37.912794
            ],
            [
              -79.062283,
              37.91752
            ],
            [
              -79.118283,
              37.901083
            ],
            [
              -79.146639,
              37.888826
            ],
            [
              -79.15739,
              37.891003
            ],
            [
              -79.144388,
              37.856096
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "580",
      "COUNTYNS": "01789069",
      "AFFGEOID": "0500000US51580",
      "GEOID": "51580",
      "NAME": "Covington",
      "LSAD": "25",
      "ALAND": 14163308,
      "AWATER": 522024,
      "County_state": "Covington,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.995941,
              37.782229
            ],
            [
              -80.008015,
              37.760481
            ],
            [
              -79.992566,
              37.748244
            ],
            [
              -79.966509,
              37.757109
            ],
            [
              -79.97721,
              37.765177
            ],
            [
              -79.980001,
              37.781063
            ],
            [
              -79.967071,
              37.803405
            ],
            [
              -79.987402,
              37.813585
            ],
            [
              -79.995941,
              37.782229
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "51",
      "COUNTYFP": "105",
      "COUNTYNS": "01496538",
      "AFFGEOID": "0500000US51105",
      "GEOID": "51105",
      "NAME": "Lee",
      "LSAD": "06",
      "ALAND": 1127622408,
      "AWATER": 4896872,
      "County_state": "Lee,51"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.674614,
              36.603082
            ],
            [
              -83.675413,
              36.600814
            ],
            [
              -83.670141,
              36.600797
            ],
            [
              -83.670128,
              36.600764
            ],
            [
              -83.645586,
              36.600002
            ],
            [
              -83.622624,
              36.598061
            ],
            [
              -83.55681,
              36.597384
            ],
            [
              -83.472108,
              36.597284
            ],
            [
              -83.374904,
              36.597386
            ],
            [
              -83.2763,
              36.598187
            ],
            [
              -83.261099,
              36.593887
            ],
            [
              -83.250304,
              36.593935
            ],
            [
              -83.249899,
              36.593898
            ],
            [
              -83.248933,
              36.593827
            ],
            [
              -83.028357,
              36.593893
            ],
            [
              -83.02725,
              36.593847
            ],
            [
              -82.9851235492625,
              36.5937302148783
            ],
            [
              -82.947587,
              36.67587
            ],
            [
              -82.879788,
              36.709711
            ],
            [
              -82.852718,
              36.717143
            ],
            [
              -82.823358,
              36.733603
            ],
            [
              -82.829812,
              36.747219
            ],
            [
              -82.808804,
              36.77279
            ],
            [
              -82.791271,
              36.780407
            ],
            [
              -82.771595,
              36.800031
            ],
            [
              -82.802026,
              36.822464
            ],
            [
              -82.835012,
              36.847592
            ],
            [
              -82.847362,
              36.847663
            ],
            [
              -82.851532,
              36.870187
            ],
            [
              -82.8782081655467,
              36.8927955121479
            ],
            [
              -82.877862,
              36.891843
            ],
            [
              -82.878569,
              36.889585
            ],
            [
              -82.879492,
              36.889085
            ],
            [
              -82.884626,
              36.888477
            ],
            [
              -82.887627,
              36.88689
            ],
            [
              -82.890028,
              36.884287
            ],
            [
              -82.895445,
              36.882145
            ],
            [
              -82.906325,
              36.87974
            ],
            [
              -82.907981,
              36.878749
            ],
            [
              -82.908004,
              36.877233
            ],
            [
              -82.907276,
              36.875643
            ],
            [
              -82.907774,
              36.874706
            ],
            [
              -82.910315,
              36.874055
            ],
            [
              -82.91169,
              36.874248
            ],
            [
              -82.911824,
              36.874243
            ],
            [
              -82.92241,
              36.873925
            ],
            [
              -82.937573,
              36.867275
            ],
            [
              -82.951685,
              36.866152
            ],
            [
              -82.960955,
              36.862536
            ],
            [
              -82.970253,
              36.857686
            ],
            [
              -82.971934,
              36.857767
            ],
            [
              -82.973395,
              36.859097
            ],
            [
              -82.979227,
              36.859693
            ],
            [
              -82.988707,
              36.859137
            ],
            [
              -82.998376,
              36.85663
            ],
            [
              -83.003786,
              36.851789
            ],
            [
              -83.006086,
              36.847889
            ],
            [
              -83.009222,
              36.847295
            ],
            [
              -83.012587,
              36.847289
            ],
            [
              -83.021887,
              36.849989
            ],
            [
              -83.024387,
              36.851689
            ],
            [
              -83.025887,
              36.855289
            ],
            [
              -83.026887,
              36.855489
            ],
            [
              -83.042188,
              36.854389
            ],
            [
              -83.044288,
              36.852989
            ],
            [
              -83.047589,
              36.851789
            ],
            [
              -83.052489,
              36.851989
            ],
            [
              -83.060489,
              36.853789
            ],
            [
              -83.063989,
              36.851689
            ],
            [
              -83.066189,
              36.851889
            ],
            [
              -83.06929,
              36.853589
            ],
            [
              -83.07259,
              36.854589
            ],
            [
              -83.07559,
              36.850589
            ],
            [
              -83.07379,
              36.844889
            ],
            [
              -83.07519,
              36.840889
            ],
            [
              -83.07969,
              36.840589
            ],
            [
              -83.086991,
              36.838189
            ],
            [
              -83.088991,
              36.835989
            ],
            [
              -83.091291,
              36.834389
            ],
            [
              -83.098892,
              36.831789
            ],
            [
              -83.101792,
              36.829089
            ],
            [
              -83.102092,
              36.828189
            ],
            [
              -83.101692,
              36.826689
            ],
            [
              -83.099792,
              36.824889
            ],
            [
              -83.098892,
              36.822989
            ],
            [
              -83.098492,
              36.814289
            ],
            [
              -83.103092,
              36.806689
            ],
            [
              -83.108029,
              36.802181
            ],
            [
              -83.110793,
              36.800388
            ],
            [
              -83.114693,
              36.796088
            ],
            [
              -83.119144,
              36.789531
            ],
            [
              -83.123341,
              36.786654
            ],
            [
              -83.128794,
              36.785388
            ],
            [
              -83.131694,
              36.781488
            ],
            [
              -83.128494,
              36.775588
            ],
            [
              -83.128894,
              36.769888
            ],
            [
              -83.131245,
              36.767105
            ],
            [
              -83.132286,
              36.76561
            ],
            [
              -83.132477,
              36.764398
            ],
            [
              -83.125655,
              36.761407
            ],
            [
              -83.125728,
              36.761276
            ],
            [
              -83.126719,
              36.761
            ],
            [
              -83.128813,
              36.757864
            ],
            [
              -83.128272,
              36.75637
            ],
            [
              -83.127833,
              36.750828
            ],
            [
              -83.130994,
              36.749788
            ],
            [
              -83.134294,
              36.746588
            ],
            [
              -83.136395,
              36.743088
            ],
            [
              -83.146095,
              36.741788
            ],
            [
              -83.156696,
              36.742187
            ],
            [
              -83.161496,
              36.739887
            ],
            [
              -83.167396,
              36.739187
            ],
            [
              -83.175696,
              36.739587
            ],
            [
              -83.194597,
              36.739487
            ],
            [
              -83.199698,
              36.737487
            ],
            [
              -83.219999,
              36.731287
            ],
            [
              -83.236399,
              36.726887
            ],
            [
              -83.238499,
              36.727087
            ],
            [
              -83.2463,
              36.725287
            ],
            [
              -83.2555,
              36.721787
            ],
            [
              -83.263601,
              36.720987
            ],
            [
              -83.269301,
              36.718487
            ],
            [
              -83.272501,
              36.717787
            ],
            [
              -83.275501,
              36.717987
            ],
            [
              -83.285702,
              36.715187
            ],
            [
              -83.286902,
              36.713987
            ],
            [
              -83.287802,
              36.713787
            ],
            [
              -83.307103,
              36.711387
            ],
            [
              -83.311403,
              36.710287
            ],
            [
              -83.313003,
              36.709087
            ],
            [
              -83.315703,
              36.708187
            ],
            [
              -83.342804,
              36.701286
            ],
            [
              -83.349705,
              36.698386
            ],
            [
              -83.350805,
              36.697386
            ],
            [
              -83.353613,
              36.696699
            ],
            [
              -83.354606,
              36.696153
            ],
            [
              -83.356405,
              36.694686
            ],
            [
              -83.359205,
              36.693586
            ],
            [
              -83.360205,
              36.693586
            ],
            [
              -83.362105,
              36.694786
            ],
            [
              -83.364005,
              36.694586
            ],
            [
              -83.367505,
              36.692686
            ],
            [
              -83.386099,
              36.686589
            ],
            [
              -83.389306,
              36.684986
            ],
            [
              -83.395306,
              36.679486
            ],
            [
              -83.395607,
              36.678987
            ],
            [
              -83.394906,
              36.677586
            ],
            [
              -83.395806,
              36.676786
            ],
            [
              -83.411807,
              36.670786
            ],
            [
              -83.419507,
              36.668486
            ],
            [
              -83.423707,
              36.667385
            ],
            [
              -83.431708,
              36.666485
            ],
            [
              -83.436508,
              36.666185
            ],
            [
              -83.440808,
              36.666885
            ],
            [
              -83.448108,
              36.665285
            ],
            [
              -83.454709,
              36.664785
            ],
            [
              -83.458009,
              36.665785
            ],
            [
              -83.460808,
              36.664885
            ],
            [
              -83.4610151191699,
              36.6648782480975
            ],
            [
              -83.466483,
              36.6647
            ],
            [
              -83.48261,
              36.666185
            ],
            [
              -83.48891,
              36.667685
            ],
            [
              -83.493911,
              36.670085
            ],
            [
              -83.498011,
              36.670485
            ],
            [
              -83.499911,
              36.670185
            ],
            [
              -83.501411,
              36.669085
            ],
            [
              -83.506911,
              36.668685
            ],
            [
              -83.511711,
              36.669085
            ],
            [
              -83.516011,
              36.667585
            ],
            [
              -83.527112,
              36.665985
            ],
            [
              -83.529612,
              36.666184
            ],
            [
              -83.531912,
              36.664984
            ],
            [
              -83.533012,
              36.663284
            ],
            [
              -83.533112,
              36.661884
            ],
            [
              -83.541812,
              36.656584
            ],
            [
              -83.547312,
              36.654484
            ],
            [
              -83.554112,
              36.653784
            ],
            [
              -83.562612,
              36.651284
            ],
            [
              -83.565712,
              36.648384
            ],
            [
              -83.569812,
              36.645684
            ],
            [
              -83.577312,
              36.641784
            ],
            [
              -83.584512,
              36.641384
            ],
            [
              -83.600713,
              36.637084
            ],
            [
              -83.603013,
              36.636883
            ],
            [
              -83.604313,
              36.637683
            ],
            [
              -83.605613,
              36.637783
            ],
            [
              -83.607913,
              36.637083
            ],
            [
              -83.614513,
              36.633983
            ],
            [
              -83.616413,
              36.631383
            ],
            [
              -83.619913,
              36.627983
            ],
            [
              -83.625013,
              36.625183
            ],
            [
              -83.628913,
              36.624083
            ],
            [
              -83.635013,
              36.622883
            ],
            [
              -83.639813,
              36.624783
            ],
            [
              -83.645213,
              36.624183
            ],
            [
              -83.648314,
              36.622683
            ],
            [
              -83.649513,
              36.616683
            ],
            [
              -83.657714,
              36.611782
            ],
            [
              -83.663614,
              36.606782
            ],
            [
              -83.665614,
              36.605782
            ],
            [
              -83.673114,
              36.604682
            ],
            [
              -83.674614,
              36.603082
            ]
          ]
        ]
      ]
    }
  }
]
};