export const counties_gu = {
  "type":"FeatureCollection","name":"gu_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "66",
      "COUNTYFP": "010",
      "COUNTYNS": "01802705",
      "AFFGEOID": "0500000US66010",
      "GEOID": "66010",
      "NAME": "Guam",
      "LSAD": "00",
      "ALAND": 543558310,
      "AWATER": 934334983,
      "County_state": "Guam,66"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              144.642331,
              13.241898
            ],
            [
              144.637775,
              13.231125
            ],
            [
              144.652096,
              13.230492
            ],
            [
              144.663814,
              13.237462
            ],
            [
              144.657304,
              13.246968
            ],
            [
              144.649492,
              13.2457
            ],
            [
              144.642331,
              13.241898
            ]
          ]
        ],
        [
          [
            [
              144.621561506422,
              13.451885424158698
            ],
            [
              144.618331,
              13.445776
            ],
            [
              144.626296,
              13.436955
            ],
            [
              144.639828,
              13.421025
            ],
            [
              144.646725,
              13.412499
            ],
            [
              144.658397,
              13.412231
            ],
            [
              144.662573649761,
              13.4022566712052
            ],
            [
              144.663076,
              13.401057
            ],
            [
              144.654293399457,
              13.382544480115799
            ],
            [
              144.65425,
              13.382453
            ],
            [
              144.635321,
              13.358641
            ],
            [
              144.635437,
              13.341376
            ],
            [
              144.635437632686,
              13.3413748980568
            ],
            [
              144.635439112858,
              13.341372320052
            ],
            [
              144.635845551034,
              13.340664430074598
            ],
            [
              144.651445,
              13.313495
            ],
            [
              144.658768561149,
              13.2840115342051
            ],
            [
              144.661893436977,
              13.2714312924747
            ],
            [
              144.661895,
              13.271425
            ],
            [
              144.676242,
              13.255804
            ],
            [
              144.702907,
              13.246938
            ],
            [
              144.72379731577402,
              13.246122221972099
            ],
            [
              144.723803,
              13.246122
            ],
            [
              144.74208,
              13.264244
            ],
            [
              144.755664,
              13.276696
            ],
            [
              144.767521,
              13.293537
            ],
            [
              144.772393,
              13.306452
            ],
            [
              144.772370589719,
              13.340526384578
            ],
            [
              144.772368587744,
              13.3435703466288
            ],
            [
              144.772368,
              13.344464
            ],
            [
              144.77437165157602,
              13.3694894284888
            ],
            [
              144.775936,
              13.389028
            ],
            [
              144.788227,
              13.415441
            ],
            [
              144.78822886247602,
              13.415442172704
            ],
            [
              144.806677,
              13.427058
            ],
            [
              144.824969,
              13.442513
            ],
            [
              144.851191,
              13.463863
            ],
            [
              144.881604854403,
              13.4908384830181
            ],
            [
              144.891305,
              13.499442
            ],
            [
              144.924469,
              13.520261
            ],
            [
              144.939381,
              13.551658
            ],
            [
              144.95044,
              13.578963
            ],
            [
              144.958717,
              13.596504
            ],
            [
              144.953996,
              13.600517
            ],
            [
              144.926945,
              13.600388
            ],
            [
              144.906843,
              13.606036
            ],
            [
              144.905335,
              13.614853
            ],
            [
              144.891819,
              13.62935
            ],
            [
              144.8816,
              13.642836
            ],
            [
              144.865432,
              13.653518
            ],
            [
              144.86012234705703,
              13.6529202364269
            ],
            [
              144.856585,
              13.652522
            ],
            [
              144.846554,
              13.636808
            ],
            [
              144.836508,
              13.622427
            ],
            [
              144.836646,
              13.595896
            ],
            [
              144.821611,
              13.56929
            ],
            [
              144.811879114352,
              13.5525604655759
            ],
            [
              144.802124,
              13.535791
            ],
            [
              144.803934,
              13.521154
            ],
            [
              144.79792,
              13.504737
            ],
            [
              144.783195,
              13.508326
            ],
            [
              144.774312,
              13.506394
            ],
            [
              144.770358,
              13.502395
            ],
            [
              144.77005,
              13.495161
            ],
            [
              144.774774,
              13.488871
            ],
            [
              144.769357,
              13.481091
            ],
            [
              144.769353785734,
              13.4810898484462
            ],
            [
              144.761134,
              13.478145
            ],
            [
              144.754238962857,
              13.4802344881802
            ],
            [
              144.748545,
              13.48196
            ],
            [
              144.72870499824302,
              13.4788141334186
            ],
            [
              144.725822,
              13.478357
            ],
            [
              144.706793907356,
              13.473398426427199
            ],
            [
              144.686312,
              13.468061
            ],
            [
              144.66446,
              13.465469
            ],
            [
              144.638345,
              13.464794
            ],
            [
              144.625406,
              13.459156
            ],
            [
              144.621561506422,
              13.451885424158698
            ]
          ]
        ]
      ]
    }
  }
]
};