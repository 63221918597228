export const counties_hi = {
  "type":"FeatureCollection","name":"hi_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "15",
      "COUNTYFP": "009",
      "COUNTYNS": "00365283",
      "AFFGEOID": "0500000US15009",
      "GEOID": "15009",
      "NAME": "Maui",
      "LSAD": "06",
      "ALAND": 3008348865,
      "AWATER": 3203294203,
      "County_state": "Maui,15"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -156.69989,
              20.920629
            ],
            [
              -156.697418,
              20.916368
            ],
            [
              -156.691334,
              20.91244
            ],
            [
              -156.688132,
              20.906325
            ],
            [
              -156.687804,
              20.89072
            ],
            [
              -156.688969,
              20.888673
            ],
            [
              -156.678634,
              20.870541
            ],
            [
              -156.631794,
              20.82124
            ],
            [
              -156.537752,
              20.778408
            ],
            [
              -156.525215,
              20.780821
            ],
            [
              -156.515994,
              20.794234
            ],
            [
              -156.506026,
              20.799463
            ],
            [
              -156.501688,
              20.799933
            ],
            [
              -156.489496,
              20.798339
            ],
            [
              -156.473562,
              20.790756
            ],
            [
              -156.464043,
              20.781667
            ],
            [
              -156.462694275465,
              20.7754866481783
            ],
            [
              -156.462058,
              20.772571
            ],
            [
              -156.462242,
              20.753952
            ],
            [
              -156.458438,
              20.736676
            ],
            [
              -156.452895,
              20.731287
            ],
            [
              -156.451038,
              20.725469
            ],
            [
              -156.448656,
              20.704739
            ],
            [
              -156.443673,
              20.656018
            ],
            [
              -156.445894,
              20.64927
            ],
            [
              -156.450651,
              20.642212
            ],
            [
              -156.442884,
              20.613842
            ],
            [
              -156.444242,
              20.607941
            ],
            [
              -156.438385,
              20.601337
            ],
            [
              -156.431872,
              20.598143
            ],
            [
              -156.427708,
              20.598873
            ],
            [
              -156.423141,
              20.602079
            ],
            [
              -156.417799,
              20.598682
            ],
            [
              -156.417151230991,
              20.597218603671298
            ],
            [
              -156.415746,
              20.594044
            ],
            [
              -156.417523,
              20.589728
            ],
            [
              -156.415313,
              20.586099
            ],
            [
              -156.377633,
              20.578427
            ],
            [
              -156.370725,
              20.57876
            ],
            [
              -156.359634,
              20.581977
            ],
            [
              -156.351716,
              20.58697
            ],
            [
              -156.322944,
              20.588273
            ],
            [
              -156.302692,
              20.586199
            ],
            [
              -156.293454,
              20.588783
            ],
            [
              -156.288037,
              20.59203
            ],
            [
              -156.284391,
              20.596488
            ],
            [
              -156.265921,
              20.601629
            ],
            [
              -156.246504753087,
              20.610967395756298
            ],
            [
              -156.236145,
              20.61595
            ],
            [
              -156.225338,
              20.62294
            ],
            [
              -156.210258,
              20.628518
            ],
            [
              -156.192938,
              20.631769
            ],
            [
              -156.184556,
              20.629719
            ],
            [
              -156.173393,
              20.6241
            ],
            [
              -156.169732,
              20.627358
            ],
            [
              -156.156772,
              20.629639
            ],
            [
              -156.148085,
              20.629067
            ],
            [
              -156.144588,
              20.624032
            ],
            [
              -156.142665,
              20.623605
            ],
            [
              -156.129898,
              20.627523
            ],
            [
              -156.120985,
              20.633685
            ],
            [
              -156.089365,
              20.648519
            ],
            [
              -156.081472,
              20.654387
            ],
            [
              -156.059753,
              20.652044
            ],
            [
              -156.053385,
              20.65432
            ],
            [
              -156.043786,
              20.664902
            ],
            [
              -156.040341,
              20.672719
            ],
            [
              -156.030702,
              20.682452
            ],
            [
              -156.020044,
              20.686857
            ],
            [
              -156.01415,
              20.685681
            ],
            [
              -156.00187,
              20.698064
            ],
            [
              -155.991534,
              20.713654
            ],
            [
              -155.987216,
              20.722717
            ],
            [
              -155.985413,
              20.744245
            ],
            [
              -155.986851,
              20.758577
            ],
            [
              -155.984587,
              20.767496
            ],
            [
              -155.987944,
              20.776552
            ],
            [
              -156.002947,
              20.789418
            ],
            [
              -156.003532,
              20.795545
            ],
            [
              -156.033287,
              20.808246
            ],
            [
              -156.059788,
              20.81054
            ],
            [
              -156.090291,
              20.831872
            ],
            [
              -156.100123,
              20.828502
            ],
            [
              -156.115735,
              20.827301
            ],
            [
              -156.129381,
              20.847513
            ],
            [
              -156.132669,
              20.861369
            ],
            [
              -156.166746,
              20.865646
            ],
            [
              -156.170458,
              20.874605
            ],
            [
              -156.173103,
              20.876926
            ],
            [
              -156.216341,
              20.907035
            ],
            [
              -156.217953,
              20.916573
            ],
            [
              -156.222062,
              20.918309
            ],
            [
              -156.226757,
              20.916677
            ],
            [
              -156.230089,
              20.917864
            ],
            [
              -156.230159,
              20.931936
            ],
            [
              -156.242555,
              20.937838
            ],
            [
              -156.263107,
              20.940888
            ],
            [
              -156.275116,
              20.937361
            ],
            [
              -156.286332,
              20.947701
            ],
            [
              -156.307198,
              20.942739
            ],
            [
              -156.324578,
              20.950184
            ],
            [
              -156.332817,
              20.94645
            ],
            [
              -156.342365,
              20.938737
            ],
            [
              -156.345655,
              20.941596
            ],
            [
              -156.352649,
              20.941414
            ],
            [
              -156.370729,
              20.932669
            ],
            [
              -156.374297,
              20.927616
            ],
            [
              -156.386045,
              20.919563
            ],
            [
              -156.394392031253,
              20.9177551565164
            ],
            [
              -156.422668,
              20.911631
            ],
            [
              -156.450399259691,
              20.902542054101
            ],
            [
              -156.474796,
              20.894546
            ],
            [
              -156.480382691879,
              20.897806614384898
            ],
            [
              -156.481055,
              20.898199
            ],
            [
              -156.493263,
              20.916011
            ],
            [
              -156.495883,
              20.928005
            ],
            [
              -156.49948,
              20.934577
            ],
            [
              -156.507913,
              20.937886
            ],
            [
              -156.510391,
              20.940358
            ],
            [
              -156.512226,
              20.95128
            ],
            [
              -156.518707,
              20.954662
            ],
            [
              -156.528246,
              20.967757
            ],
            [
              -156.546291,
              21.005082
            ],
            [
              -156.549813,
              21.004939
            ],
            [
              -156.562773,
              21.016167
            ],
            [
              -156.580448,
              21.020172
            ],
            [
              -156.58504745353702,
              21.0251220216423
            ],
            [
              -156.592256,
              21.03288
            ],
            [
              -156.606238,
              21.034371
            ],
            [
              -156.612065,
              21.027273
            ],
            [
              -156.612012,
              21.02477
            ],
            [
              -156.619581,
              21.027793
            ],
            [
              -156.642809,
              21.027583
            ],
            [
              -156.644167,
              21.022312
            ],
            [
              -156.642592,
              21.019936
            ],
            [
              -156.645966,
              21.014416
            ],
            [
              -156.652419,
              21.008994
            ],
            [
              -156.665514,
              21.007054
            ],
            [
              -156.680905,
              20.980262
            ],
            [
              -156.69411,
              20.952708
            ],
            [
              -156.69989,
              20.920629
            ]
          ]
        ],
        [
          [
            [
              -156.702265,
              20.532451
            ],
            [
              -156.703673,
              20.527237
            ],
            [
              -156.682939,
              20.506775
            ],
            [
              -156.668809,
              20.504738
            ],
            [
              -156.647464,
              20.512017
            ],
            [
              -156.642347,
              20.508285
            ],
            [
              -156.631143,
              20.514943
            ],
            [
              -156.603844,
              20.524372
            ],
            [
              -156.586238,
              20.511711
            ],
            [
              -156.559994,
              20.521892
            ],
            [
              -156.550016,
              20.520273
            ],
            [
              -156.544169,
              20.522802
            ],
            [
              -156.539643,
              20.527644
            ],
            [
              -156.540189,
              20.534741
            ],
            [
              -156.553018,
              20.539382
            ],
            [
              -156.556021,
              20.542657
            ],
            [
              -156.548909,
              20.56859
            ],
            [
              -156.542808,
              20.573674
            ],
            [
              -156.543034,
              20.580115
            ],
            [
              -156.553604,
              20.594729
            ],
            [
              -156.56714,
              20.604895
            ],
            [
              -156.576871,
              20.60657
            ],
            [
              -156.610734,
              20.59377
            ],
            [
              -156.614598,
              20.587109
            ],
            [
              -156.651567,
              20.565574
            ],
            [
              -156.6801,
              20.557021
            ],
            [
              -156.696662,
              20.541646
            ],
            [
              -156.702265,
              20.532451
            ]
          ]
        ],
        [
          [
            [
              -157.062511,
              20.904385
            ],
            [
              -157.061128,
              20.890635
            ],
            [
              -157.059663,
              20.884634
            ],
            [
              -157.054552,
              20.877219
            ],
            [
              -157.010911,
              20.854476
            ],
            [
              -157.006243,
              20.849603
            ],
            [
              -156.991834,
              20.826603
            ],
            [
              -156.988933,
              20.815496
            ],
            [
              -156.994001,
              20.786671
            ],
            [
              -156.984747,
              20.756677
            ],
            [
              -156.96789,
              20.73508
            ],
            [
              -156.949009,
              20.738997
            ],
            [
              -156.909081,
              20.739533
            ],
            [
              -156.890295,
              20.744855
            ],
            [
              -156.869753,
              20.754701
            ],
            [
              -156.851481,
              20.760069
            ],
            [
              -156.846413,
              20.760201
            ],
            [
              -156.838321,
              20.764575
            ],
            [
              -156.817427,
              20.794606
            ],
            [
              -156.809463,
              20.809169
            ],
            [
              -156.808469,
              20.820396
            ],
            [
              -156.809576,
              20.826036
            ],
            [
              -156.825237,
              20.850731
            ],
            [
              -156.837047,
              20.863575
            ],
            [
              -156.897169,
              20.915395
            ],
            [
              -156.91845,
              20.922546
            ],
            [
              -156.937529,
              20.925274
            ],
            [
              -156.971604,
              20.926254
            ],
            [
              -156.989813,
              20.932127
            ],
            [
              -157.010001,
              20.929757
            ],
            [
              -157.025626,
              20.929528
            ],
            [
              -157.035789,
              20.927078
            ],
            [
              -157.05913,
              20.913407
            ],
            [
              -157.062511,
              20.904385
            ]
          ]
        ],
        [
          [
            [
              -157.310748,
              21.101627
            ],
            [
              -157.298841,
              21.09337
            ],
            [
              -157.252534,
              21.08767
            ],
            [
              -157.149064,
              21.091538
            ],
            [
              -157.126818,
              21.097842
            ],
            [
              -157.095583,
              21.103584
            ],
            [
              -157.08066,
              21.101976
            ],
            [
              -157.04852,
              21.095821
            ],
            [
              -157.032045,
              21.091094
            ],
            [
              -157.02617,
              21.089015
            ],
            [
              -157.00052,
              21.076755
            ],
            [
              -156.953872,
              21.066128
            ],
            [
              -156.908831,
              21.055813
            ],
            [
              -156.891946,
              21.051831
            ],
            [
              -156.877137,
              21.0493
            ],
            [
              -156.857751,
              21.055731
            ],
            [
              -156.833175,
              21.057324
            ],
            [
              -156.8022,
              21.067095
            ],
            [
              -156.787121,
              21.079036
            ],
            [
              -156.765592,
              21.092139
            ],
            [
              -156.739342,
              21.111336
            ],
            [
              -156.722892,
              21.12895
            ],
            [
              -156.712434,
              21.144025
            ],
            [
              -156.709106,
              21.158655
            ],
            [
              -156.719386,
              21.163911
            ],
            [
              -156.736648,
              21.16188
            ],
            [
              -156.738341,
              21.17202
            ],
            [
              -156.742231,
              21.176214
            ],
            [
              -156.771495,
              21.180053
            ],
            [
              -156.821944,
              21.174693
            ],
            [
              -156.841592,
              21.167926
            ],
            [
              -156.867944,
              21.16452
            ],
            [
              -156.896537,
              21.172208
            ],
            [
              -156.89613,
              21.169561
            ],
            [
              -156.898174,
              21.16594
            ],
            [
              -156.903466,
              21.16421
            ],
            [
              -156.918248006048,
              21.168279353689897
            ],
            [
              -156.898876,
              21.147604
            ],
            [
              -156.900241,
              21.131625
            ],
            [
              -156.918072,
              21.129479
            ],
            [
              -156.942135,
              21.160173
            ],
            [
              -156.958934,
              21.160948
            ],
            [
              -156.962393,
              21.180107
            ],
            [
              -156.962508,
              21.159368
            ],
            [
              -156.973643,
              21.17524
            ],
            [
              -157.00412,
              21.178514
            ],
            [
              -157.014552604751,
              21.1855034366075
            ],
            [
              -157.039987,
              21.190909
            ],
            [
              -157.047757,
              21.190739
            ],
            [
              -157.053053,
              21.188754
            ],
            [
              -157.064264,
              21.189076
            ],
            [
              -157.097971,
              21.198012
            ],
            [
              -157.113438,
              21.197375
            ],
            [
              -157.128207,
              21.201488
            ],
            [
              -157.144627,
              21.202555
            ],
            [
              -157.148125,
              21.200745
            ],
            [
              -157.157103,
              21.200706
            ],
            [
              -157.185553,
              21.205602
            ],
            [
              -157.192439,
              21.207644
            ],
            [
              -157.202125,
              21.219298
            ],
            [
              -157.212082,
              21.221848
            ],
            [
              -157.226445,
              21.220185
            ],
            [
              -157.241534,
              21.220969
            ],
            [
              -157.257085,
              21.227268
            ],
            [
              -157.26069,
              21.225684
            ],
            [
              -157.263163,
              21.220873
            ],
            [
              -157.261457,
              21.217661
            ],
            [
              -157.256935,
              21.215665
            ],
            [
              -157.25026,
              21.207739
            ],
            [
              -157.251007,
              21.190952
            ],
            [
              -157.249695,
              21.184401
            ],
            [
              -157.25672,
              21.171067
            ],
            [
              -157.269854,
              21.16043
            ],
            [
              -157.27722,
              21.158431
            ],
            [
              -157.288435,
              21.146829
            ],
            [
              -157.292371,
              21.13585
            ],
            [
              -157.298753,
              21.120752
            ],
            [
              -157.310748,
              21.101627
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "15",
      "COUNTYFP": "001",
      "COUNTYNS": "00365280",
      "AFFGEOID": "0500000US15001",
      "GEOID": "15001",
      "NAME": "Hawaii",
      "LSAD": "06",
      "ALAND": 10433637860,
      "AWATER": 2739470030,
      "County_state": "Hawaii,15"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -156.05722,
              19.742536
            ],
            [
              -156.064364,
              19.730766
            ],
            [
              -156.05634327225002,
              19.7225967277504
            ],
            [
              -156.052485,
              19.718667
            ],
            [
              -156.051652,
              19.703649
            ],
            [
              -156.04796,
              19.698938
            ],
            [
              -156.036079,
              19.690252
            ],
            [
              -156.029281,
              19.678908
            ],
            [
              -156.027427,
              19.672154
            ],
            [
              -156.033326,
              19.66923
            ],
            [
              -156.034994,
              19.65936
            ],
            [
              -156.032928,
              19.653905
            ],
            [
              -156.028982,
              19.650098
            ],
            [
              -155.997728,
              19.642816
            ],
            [
              -155.98092285683902,
              19.6129821793607
            ],
            [
              -155.978206,
              19.608159
            ],
            [
              -155.970969,
              19.586328
            ],
            [
              -155.96935,
              19.555963
            ],
            [
              -155.965211,
              19.554745
            ],
            [
              -155.962264,
              19.551779
            ],
            [
              -155.960457,
              19.546612
            ],
            [
              -155.953663,
              19.510003
            ],
            [
              -155.952897,
              19.488805
            ],
            [
              -155.95149,
              19.486649
            ],
            [
              -155.939145,
              19.481577
            ],
            [
              -155.936403,
              19.481905
            ],
            [
              -155.935641,
              19.485628
            ],
            [
              -155.930523,
              19.484921
            ],
            [
              -155.924124,
              19.481406
            ],
            [
              -155.922609,
              19.478611
            ],
            [
              -155.925166,
              19.468081
            ],
            [
              -155.924269,
              19.438794
            ],
            [
              -155.921707,
              19.43055
            ],
            [
              -155.909087,
              19.415455
            ],
            [
              -155.913849,
              19.401107
            ],
            [
              -155.898792,
              19.377984
            ],
            [
              -155.888701,
              19.348031
            ],
            [
              -155.887356,
              19.337101
            ],
            [
              -155.890842,
              19.298905
            ],
            [
              -155.895435,
              19.274639
            ],
            [
              -155.902565,
              19.258427
            ],
            [
              -155.90491,
              19.230147
            ],
            [
              -155.903339,
              19.217792
            ],
            [
              -155.917292,
              19.155963
            ],
            [
              -155.921389,
              19.121183
            ],
            [
              -155.908355,
              19.081138
            ],
            [
              -155.903693,
              19.080777
            ],
            [
              -155.886278,
              19.05576
            ],
            [
              -155.884077,
              19.039266
            ],
            [
              -155.88155,
              19.036644
            ],
            [
              -155.853943,
              19.023762
            ],
            [
              -155.806109,
              19.013967
            ],
            [
              -155.763598,
              18.981837
            ],
            [
              -155.726043,
              18.969437
            ],
            [
              -155.693117,
              18.940542
            ],
            [
              -155.690171,
              18.932195
            ],
            [
              -155.687716,
              18.923358
            ],
            [
              -155.681825,
              18.918694
            ],
            [
              -155.672005,
              18.917466
            ],
            [
              -155.658486,
              18.924835
            ],
            [
              -155.638054,
              18.941723
            ],
            [
              -155.625,
              18.959934
            ],
            [
              -155.625256,
              18.961951
            ],
            [
              -155.613966,
              18.970399
            ],
            [
              -155.601866,
              18.971572
            ],
            [
              -155.596521,
              18.980654
            ],
            [
              -155.596032,
              18.998833
            ],
            [
              -155.581903,
              19.02224
            ],
            [
              -155.576599,
              19.027412
            ],
            [
              -155.566446,
              19.032531
            ],
            [
              -155.557371,
              19.046565
            ],
            [
              -155.555177,
              19.053932
            ],
            [
              -155.555326,
              19.069377
            ],
            [
              -155.557817,
              19.08213
            ],
            [
              -155.551129,
              19.08878
            ],
            [
              -155.549781090203,
              19.089265827364898
            ],
            [
              -155.544806,
              19.091059
            ],
            [
              -155.528902,
              19.11371
            ],
            [
              -155.526136,
              19.115889
            ],
            [
              -155.519652,
              19.117025
            ],
            [
              -155.512137,
              19.124296
            ],
            [
              -155.51214,
              19.128174
            ],
            [
              -155.51474,
              19.132501
            ],
            [
              -155.505281,
              19.137908
            ],
            [
              -155.465663,
              19.146964
            ],
            [
              -155.453516,
              19.151952
            ],
            [
              -155.432519,
              19.170623
            ],
            [
              -155.427093,
              19.179546
            ],
            [
              -155.417369,
              19.187858
            ],
            [
              -155.390701,
              19.201171
            ],
            [
              -155.378638,
              19.202435
            ],
            [
              -155.360631,
              19.20893
            ],
            [
              -155.349148,
              19.217756
            ],
            [
              -155.341268,
              19.234039
            ],
            [
              -155.31337,
              19.250698
            ],
            [
              -155.305007397244,
              19.2604380446443
            ],
            [
              -155.303808,
              19.261835
            ],
            [
              -155.296761,
              19.266289
            ],
            [
              -155.264619,
              19.274213
            ],
            [
              -155.243961,
              19.271313
            ],
            [
              -155.205892,
              19.260907
            ],
            [
              -155.19626,
              19.261295
            ],
            [
              -155.187427,
              19.266156
            ],
            [
              -155.172413,
              19.26906
            ],
            [
              -155.159635,
              19.268375
            ],
            [
              -155.1337,
              19.276099
            ],
            [
              -155.113272,
              19.290613
            ],
            [
              -155.061729,
              19.316636
            ],
            [
              -155.020537,
              19.331317
            ],
            [
              -154.980861,
              19.349291
            ],
            [
              -154.964619,
              19.365646
            ],
            [
              -154.944185,
              19.381852
            ],
            [
              -154.928772,
              19.397646
            ],
            [
              -154.887817,
              19.426425
            ],
            [
              -154.86854,
              19.438126
            ],
            [
              -154.838545,
              19.463642
            ],
            [
              -154.822968,
              19.48129
            ],
            [
              -154.809379,
              19.519086
            ],
            [
              -154.809561,
              19.522377
            ],
            [
              -154.814417,
              19.53009
            ],
            [
              -154.826732,
              19.537626
            ],
            [
              -154.837384,
              19.538354
            ],
            [
              -154.852618,
              19.549172
            ],
            [
              -154.875,
              19.556797
            ],
            [
              -154.903542,
              19.570622
            ],
            [
              -154.924422,
              19.586553
            ],
            [
              -154.928205,
              19.592702
            ],
            [
              -154.93394,
              19.597505
            ],
            [
              -154.947106,
              19.604856
            ],
            [
              -154.951014,
              19.613614
            ],
            [
              -154.947718,
              19.621947
            ],
            [
              -154.947874,
              19.62425
            ],
            [
              -154.950359,
              19.626461
            ],
            [
              -154.963933,
              19.627605
            ],
            [
              -154.974342,
              19.633201
            ],
            [
              -154.983778,
              19.641647
            ],
            [
              -154.983912011056,
              19.6459972269925
            ],
            [
              -154.984718,
              19.672161
            ],
            [
              -154.981102,
              19.690687
            ],
            [
              -154.987168,
              19.708524
            ],
            [
              -154.99090613513602,
              19.7159396318776
            ],
            [
              -154.997278,
              19.72858
            ],
            [
              -155.006423,
              19.739286
            ],
            [
              -155.045382,
              19.739824
            ],
            [
              -155.063972,
              19.728917
            ],
            [
              -155.079426,
              19.726193
            ],
            [
              -155.087118,
              19.728013
            ],
            [
              -155.087425262421,
              19.7284902884759
            ],
            [
              -155.093387,
              19.737751
            ],
            [
              -155.093460760735,
              19.7570882277538
            ],
            [
              -155.093517,
              19.771832
            ],
            [
              -155.091216,
              19.776368
            ],
            [
              -155.09207,
              19.799409
            ],
            [
              -155.094414,
              19.81491
            ],
            [
              -155.088979,
              19.826656
            ],
            [
              -155.085674,
              19.838584
            ],
            [
              -155.084357,
              19.849736
            ],
            [
              -155.086341,
              19.855399
            ],
            [
              -155.095032,
              19.867882
            ],
            [
              -155.098716,
              19.867811
            ],
            [
              -155.107541,
              19.872467
            ],
            [
              -155.12175,
              19.886099
            ],
            [
              -155.124618,
              19.897288
            ],
            [
              -155.131235,
              19.906801
            ],
            [
              -155.144394,
              19.920523
            ],
            [
              -155.149215,
              19.922872
            ],
            [
              -155.179939,
              19.949372
            ],
            [
              -155.194593,
              19.958368
            ],
            [
              -155.204486,
              19.969438
            ],
            [
              -155.240933,
              19.990173
            ],
            [
              -155.270316,
              20.014525
            ],
            [
              -155.282629,
              20.021969
            ],
            [
              -155.29548,
              20.024438
            ],
            [
              -155.33021,
              20.038517
            ],
            [
              -155.387578,
              20.067119
            ],
            [
              -155.4024,
              20.075541
            ],
            [
              -155.405459,
              20.078772
            ],
            [
              -155.443957,
              20.095318
            ],
            [
              -155.468211,
              20.104296
            ],
            [
              -155.502561,
              20.114155
            ],
            [
              -155.516795,
              20.11523
            ],
            [
              -155.523661,
              20.120028
            ],
            [
              -155.558933,
              20.13157
            ],
            [
              -155.568368,
              20.130545
            ],
            [
              -155.58168,
              20.123617
            ],
            [
              -155.590923,
              20.122497
            ],
            [
              -155.598033,
              20.124539
            ],
            [
              -155.600909,
              20.126573
            ],
            [
              -155.607797,
              20.137987
            ],
            [
              -155.624565,
              20.145911
            ],
            [
              -155.630382,
              20.146916
            ],
            [
              -155.653966,
              20.16736
            ],
            [
              -155.698249742677,
              20.188749917212597
            ],
            [
              -155.732704,
              20.205392
            ],
            [
              -155.735822,
              20.212417
            ],
            [
              -155.737004,
              20.222773
            ],
            [
              -155.746893,
              20.232325
            ],
            [
              -155.772734,
              20.245409
            ],
            [
              -155.778234,
              20.245743
            ],
            [
              -155.783242,
              20.246395
            ],
            [
              -155.811459,
              20.26032
            ],
            [
              -155.853293,
              20.271548
            ],
            [
              -155.873921,
              20.267744
            ],
            [
              -155.882631,
              20.263026
            ],
            [
              -155.890663,
              20.25524
            ],
            [
              -155.901452,
              20.235787
            ],
            [
              -155.906035,
              20.205157
            ],
            [
              -155.899149,
              20.145728
            ],
            [
              -155.88419,
              20.10675
            ],
            [
              -155.866931,
              20.078652
            ],
            [
              -155.850385,
              20.062506
            ],
            [
              -155.828182,
              20.035424
            ],
            [
              -155.825473,
              20.025944
            ],
            [
              -155.828965,
              19.995542
            ],
            [
              -155.831948,
              19.982775
            ],
            [
              -155.835312,
              19.976078
            ],
            [
              -155.838692,
              19.975527
            ],
            [
              -155.840708,
              19.976952
            ],
            [
              -155.856588,
              19.968885
            ],
            [
              -155.866919,
              19.954172
            ],
            [
              -155.892533,
              19.932162
            ],
            [
              -155.894474,
              19.926927
            ],
            [
              -155.894099,
              19.923135
            ],
            [
              -155.901987,
              19.912081
            ],
            [
              -155.912161983807,
              19.8935130496605
            ],
            [
              -155.915662,
              19.887126
            ],
            [
              -155.92549,
              19.875
            ],
            [
              -155.926938,
              19.870221
            ],
            [
              -155.926338208768,
              19.864035207866596
            ],
            [
              -155.925843,
              19.858928
            ],
            [
              -155.940311,
              19.852305
            ],
            [
              -155.945297,
              19.853443
            ],
            [
              -155.946189795604,
              19.8542538318197
            ],
            [
              -155.949251,
              19.857034
            ],
            [
              -155.964817,
              19.855183
            ],
            [
              -155.976651,
              19.85053
            ],
            [
              -155.982821,
              19.845651
            ],
            [
              -156.00394218017402,
              19.8203634179532
            ],
            [
              -156.006267,
              19.81758
            ],
            [
              -156.021732,
              19.8022
            ],
            [
              -156.049651,
              19.780452
            ],
            [
              -156.052315,
              19.756836
            ],
            [
              -156.05722,
              19.742536
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "15",
      "COUNTYFP": "005",
      "COUNTYNS": "01702380",
      "AFFGEOID": "0500000US15005",
      "GEOID": "15005",
      "NAME": "Kalawao",
      "LSAD": "06",
      "ALAND": 31057603,
      "AWATER": 105764468,
      "County_state": "Kalawao,15"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -157.014552604751,
              21.1855034366075
            ],
            [
              -157.00412,
              21.178514
            ],
            [
              -156.973643,
              21.17524
            ],
            [
              -156.962508,
              21.159368
            ],
            [
              -156.962393,
              21.180107
            ],
            [
              -156.958934,
              21.160948
            ],
            [
              -156.942135,
              21.160173
            ],
            [
              -156.918072,
              21.129479
            ],
            [
              -156.900241,
              21.131625
            ],
            [
              -156.898876,
              21.147604
            ],
            [
              -156.918248006048,
              21.168279353689897
            ],
            [
              -156.946159,
              21.175963
            ],
            [
              -156.950808,
              21.182636
            ],
            [
              -156.951654,
              21.191662
            ],
            [
              -156.962847,
              21.212131
            ],
            [
              -156.969064,
              21.217018
            ],
            [
              -156.974002,
              21.218503
            ],
            [
              -156.984032,
              21.212198
            ],
            [
              -156.984464,
              21.210063
            ],
            [
              -156.982343,
              21.207798
            ],
            [
              -156.987768,
              21.18935
            ],
            [
              -156.991318,
              21.18551
            ],
            [
              -156.999108,
              21.182221
            ],
            [
              -157.014552604751,
              21.1855034366075
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "15",
      "COUNTYFP": "003",
      "COUNTYNS": "00365281",
      "AFFGEOID": "0500000US15003",
      "GEOID": "15003",
      "NAME": "Honolulu",
      "LSAD": "06",
      "ALAND": 1555581074,
      "AWATER": 4054908589,
      "County_state": "Honolulu,15"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -158.277679,
              21.578789
            ],
            [
              -158.27951,
              21.575794
            ],
            [
              -158.27111809345203,
              21.5704336438668
            ],
            [
              -158.250671,
              21.557373
            ],
            [
              -158.234314,
              21.540058
            ],
            [
              -158.23175,
              21.533035
            ],
            [
              -158.231171,
              21.523857
            ],
            [
              -158.233,
              21.4876
            ],
            [
              -158.219446,
              21.46978
            ],
            [
              -158.205383,
              21.459793
            ],
            [
              -158.192352,
              21.44804
            ],
            [
              -158.182648,
              21.430073
            ],
            [
              -158.181,
              21.420868
            ],
            [
              -158.181274,
              21.409626
            ],
            [
              -158.1792,
              21.4043
            ],
            [
              -158.178054743453,
              21.403773983674398
            ],
            [
              -158.161743,
              21.396282
            ],
            [
              -158.149719,
              21.385208
            ],
            [
              -158.1403,
              21.3738
            ],
            [
              -158.13324,
              21.359207
            ],
            [
              -158.130502075895,
              21.349022738675497
            ],
            [
              -158.125459,
              21.330264
            ],
            [
              -158.1225,
              21.3224
            ],
            [
              -158.1211,
              21.3169
            ],
            [
              -158.1127,
              21.3019
            ],
            [
              -158.1033,
              21.2979
            ],
            [
              -158.0883,
              21.2988
            ],
            [
              -158.08664702014602,
              21.2990720421389
            ],
            [
              -158.044485315802,
              21.3060108806282
            ],
            [
              -158.0245,
              21.3093
            ],
            [
              -158.01145497772902,
              21.311302816915397
            ],
            [
              -157.981525,
              21.315898
            ],
            [
              -157.974157,
              21.319577
            ],
            [
              -157.969215165854,
              21.3262947308971
            ],
            [
              -157.967971,
              21.327986
            ],
            [
              -157.951881,
              21.318742
            ],
            [
              -157.950736,
              21.312509
            ],
            [
              -157.952263,
              21.306531
            ],
            [
              -157.910925,
              21.305768
            ],
            [
              -157.89,
              21.3065
            ],
            [
              -157.87001266874302,
              21.2952265950989
            ],
            [
              -157.866551968887,
              21.2932746651375
            ],
            [
              -157.86119137407204,
              21.2902511421329
            ],
            [
              -157.851048,
              21.28453
            ],
            [
              -157.8319,
              21.2795
            ],
            [
              -157.825329333333,
              21.271436
            ],
            [
              -157.8253,
              21.2714
            ],
            [
              -157.8241,
              21.2646
            ],
            [
              -157.8211,
              21.2606
            ],
            [
              -157.8096,
              21.2577
            ],
            [
              -157.7931,
              21.2604
            ],
            [
              -157.779944,
              21.265252
            ],
            [
              -157.768048067819,
              21.276116272109498
            ],
            [
              -157.765,
              21.2789
            ],
            [
              -157.7572,
              21.278
            ],
            [
              -157.732169,
              21.282014
            ],
            [
              -157.719905562649,
              21.2787862357309
            ],
            [
              -157.717929,
              21.278266
            ],
            [
              -157.7114,
              21.272
            ],
            [
              -157.7142,
              21.2665
            ],
            [
              -157.7139,
              21.2638
            ],
            [
              -157.7097,
              21.2621
            ],
            [
              -157.7001,
              21.264
            ],
            [
              -157.6944,
              21.2665
            ],
            [
              -157.685775,
              21.275763
            ],
            [
              -157.651032,
              21.298816
            ],
            [
              -157.651532433522,
              21.3086448271369
            ],
            [
              -157.6518,
              21.3139
            ],
            [
              -157.657678150804,
              21.313434403896697
            ],
            [
              -157.6619,
              21.3131
            ],
            [
              -157.68260059828302,
              21.3259486472103
            ],
            [
              -157.6938,
              21.3329
            ],
            [
              -157.6971,
              21.3364
            ],
            [
              -157.7088,
              21.3534
            ],
            [
              -157.7106,
              21.3585
            ],
            [
              -157.7126,
              21.3689
            ],
            [
              -157.7087,
              21.3793
            ],
            [
              -157.7089,
              21.3833
            ],
            [
              -157.7189,
              21.3961
            ],
            [
              -157.721083,
              21.399541
            ],
            [
              -157.72154540464803,
              21.400788036158097
            ],
            [
              -157.721845,
              21.401596
            ],
            [
              -157.722544,
              21.403036
            ],
            [
              -157.722735,
              21.403121
            ],
            [
              -157.723286,
              21.403227
            ],
            [
              -157.723794,
              21.40329
            ],
            [
              -157.724324,
              21.403311
            ],
            [
              -157.726421,
              21.402845
            ],
            [
              -157.728221,
              21.402104
            ],
            [
              -157.730191,
              21.401871
            ],
            [
              -157.7386,
              21.4043
            ],
            [
              -157.741211,
              21.414614
            ],
            [
              -157.740762,
              21.424048
            ],
            [
              -157.74029969094602,
              21.425392831030898
            ],
            [
              -157.73582,
              21.438424
            ],
            [
              -157.731328,
              21.444713
            ],
            [
              -157.725758,
              21.449973
            ],
            [
              -157.722506,
              21.459225
            ],
            [
              -157.737617,
              21.459089
            ],
            [
              -157.744999,
              21.453529
            ],
            [
              -157.764572,
              21.461335
            ],
            [
              -157.772209,
              21.457741
            ],
            [
              -157.778919,
              21.446404
            ],
            [
              -157.763086,
              21.437813
            ],
            [
              -157.772634,
              21.41952
            ],
            [
              -157.778044,
              21.411816
            ],
            [
              -157.780374477999,
              21.412866352405597
            ],
            [
              -157.786513,
              21.415633
            ],
            [
              -157.8059,
              21.4301
            ],
            [
              -157.811938986416,
              21.4377997076802
            ],
            [
              -157.8139,
              21.4403
            ],
            [
              -157.8163,
              21.4502
            ],
            [
              -157.82489,
              21.455379
            ],
            [
              -157.84099,
              21.459483
            ],
            [
              -157.84549,
              21.466747
            ],
            [
              -157.84888076687602,
              21.4825360453674
            ],
            [
              -157.852625,
              21.499971
            ],
            [
              -157.849579,
              21.509598
            ],
            [
              -157.837372,
              21.512085
            ],
            [
              -157.836945,
              21.529945
            ],
            [
              -157.85257,
              21.557514
            ],
            [
              -157.85614,
              21.560661
            ],
            [
              -157.8669,
              21.5637
            ],
            [
              -157.872528,
              21.557568
            ],
            [
              -157.878601,
              21.560181
            ],
            [
              -157.87735,
              21.575277
            ],
            [
              -157.900574,
              21.605885
            ],
            [
              -157.910797,
              21.611183
            ],
            [
              -157.9238,
              21.6293
            ],
            [
              -157.9228,
              21.6361
            ],
            [
              -157.924591,
              21.651183
            ],
            [
              -157.9301,
              21.6552
            ],
            [
              -157.939,
              21.669
            ],
            [
              -157.947174,
              21.689568
            ],
            [
              -157.968628,
              21.712704
            ],
            [
              -157.98703,
              21.712494
            ],
            [
              -157.9923,
              21.708
            ],
            [
              -158.018127,
              21.699955
            ],
            [
              -158.0372,
              21.6843
            ],
            [
              -158.0639,
              21.6584
            ],
            [
              -158.066711,
              21.65234
            ],
            [
              -158.0668,
              21.6437
            ],
            [
              -158.079895,
              21.628101
            ],
            [
              -158.108185,
              21.607487
            ],
            [
              -158.1095,
              21.6057
            ],
            [
              -158.106689,
              21.603024
            ],
            [
              -158.10672,
              21.596577
            ],
            [
              -158.12261323182,
              21.5882997308286
            ],
            [
              -158.12561,
              21.586739
            ],
            [
              -158.17,
              21.5823
            ],
            [
              -158.190704,
              21.585892
            ],
            [
              -158.254425,
              21.582684
            ],
            [
              -158.277679,
              21.578789
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "15",
      "COUNTYFP": "007",
      "COUNTYNS": "00365282",
      "AFFGEOID": "0500000US15007",
      "GEOID": "15007",
      "NAME": "Kauai",
      "LSAD": "06",
      "ALAND": 1605492340,
      "AWATER": 1674244223,
      "County_state": "Kauai,15"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -159.787943,
              22.030102
            ],
            [
              -159.786702,
              22.018801
            ],
            [
              -159.774173,
              22.005865
            ],
            [
              -159.765735,
              21.986593
            ],
            [
              -159.754795,
              21.977772
            ],
            [
              -159.721733,
              21.968801
            ],
            [
              -159.707795,
              21.961229
            ],
            [
              -159.668715,
              21.953585
            ],
            [
              -159.660793,
              21.942747
            ],
            [
              -159.649766,
              21.933848
            ],
            [
              -159.636232,
              21.916244
            ],
            [
              -159.612739,
              21.900991
            ],
            [
              -159.603279,
              21.892248
            ],
            [
              -159.597408,
              21.894706
            ],
            [
              -159.587708,
              21.898767
            ],
            [
              -159.574521,
              21.892806
            ],
            [
              -159.555787,
              21.888357
            ],
            [
              -159.526918,
              21.883886
            ],
            [
              -159.512206,
              21.888085
            ],
            [
              -159.493582,
              21.885261
            ],
            [
              -159.475342,
              21.882122
            ],
            [
              -159.444868,
              21.868627
            ],
            [
              -159.433957,
              21.874198
            ],
            [
              -159.422488,
              21.881842
            ],
            [
              -159.416669,
              21.888302
            ],
            [
              -159.404617,
              21.893367
            ],
            [
              -159.396883,
              21.902963
            ],
            [
              -159.388209643846,
              21.903272409366
            ],
            [
              -159.38724,
              21.903307
            ],
            [
              -159.385271,
              21.912439
            ],
            [
              -159.379884,
              21.921174
            ],
            [
              -159.36789,
              21.925841
            ],
            [
              -159.355088,
              21.931613
            ],
            [
              -159.346757,
              21.936368
            ],
            [
              -159.33768,
              21.951173
            ],
            [
              -159.329882,
              21.960177
            ],
            [
              -159.333494,
              21.97825
            ],
            [
              -159.329406,
              21.991696
            ],
            [
              -159.332564,
              21.999352
            ],
            [
              -159.335586,
              22.009546
            ],
            [
              -159.338591,
              22.023442
            ],
            [
              -159.334489,
              22.041698
            ],
            [
              -159.334030049983,
              22.044023707929
            ],
            [
              -159.33271,
              22.050713
            ],
            [
              -159.324694,
              22.053964
            ],
            [
              -159.31828,
              22.061417
            ],
            [
              -159.316634,
              22.075422
            ],
            [
              -159.30738,
              22.087929
            ],
            [
              -159.303892,
              22.099139
            ],
            [
              -159.296633,
              22.105107
            ],
            [
              -159.293013,
              22.12296
            ],
            [
              -159.293279,
              22.144341
            ],
            [
              -159.298983,
              22.147643
            ],
            [
              -159.306351,
              22.150285
            ],
            [
              -159.302786,
              22.15997
            ],
            [
              -159.312604,
              22.1729
            ],
            [
              -159.312293,
              22.183082
            ],
            [
              -159.328456,
              22.194966
            ],
            [
              -159.331575,
              22.200016
            ],
            [
              -159.349768,
              22.215005
            ],
            [
              -159.361507,
              22.214092
            ],
            [
              -159.382171,
              22.216971
            ],
            [
              -159.38292,
              22.224771
            ],
            [
              -159.402466,
              22.232603
            ],
            [
              -159.406741,
              22.22568
            ],
            [
              -159.419725,
              22.223132
            ],
            [
              -159.431707,
              22.220015
            ],
            [
              -159.449125,
              22.225152
            ],
            [
              -159.46083,
              22.224351
            ],
            [
              -159.47249,
              22.227752
            ],
            [
              -159.487939,
              22.229512
            ],
            [
              -159.500924,
              22.219173
            ],
            [
              -159.497446,
              22.21411
            ],
            [
              -159.498397,
              22.208169
            ],
            [
              -159.510756,
              22.203548
            ],
            [
              -159.517807,
              22.214216
            ],
            [
              -159.530594,
              22.215754
            ],
            [
              -159.53904,
              22.21389
            ],
            [
              -159.543924,
              22.221695
            ],
            [
              -159.559005,
              22.226652
            ],
            [
              -159.569701,
              22.223352
            ],
            [
              -159.581058,
              22.223488
            ],
            [
              -159.61165,
              22.201388
            ],
            [
              -159.630566,
              22.19271
            ],
            [
              -159.64861,
              22.18044
            ],
            [
              -159.676419,
              22.167454
            ],
            [
              -159.69055895502902,
              22.1635037302058
            ],
            [
              -159.705531,
              22.159321
            ],
            [
              -159.717757,
              22.151661
            ],
            [
              -159.730544,
              22.139953
            ],
            [
              -159.738216,
              22.119462
            ],
            [
              -159.745247,
              22.097508
            ],
            [
              -159.783746,
              22.064897
            ],
            [
              -159.783863,
              22.047809
            ],
            [
              -159.787943,
              22.030102
            ]
          ]
        ],
        [
          [
            [
              -160.244943,
              21.848943
            ],
            [
              -160.24961,
              21.815145
            ],
            [
              -160.23478,
              21.795418
            ],
            [
              -160.218044,
              21.783755
            ],
            [
              -160.205851,
              21.779518
            ],
            [
              -160.200427,
              21.786479
            ],
            [
              -160.205211,
              21.789053
            ],
            [
              -160.189782,
              21.82245
            ],
            [
              -160.174796,
              21.846923
            ],
            [
              -160.16162,
              21.864746
            ],
            [
              -160.147609,
              21.872814
            ],
            [
              -160.124283,
              21.876789
            ],
            [
              -160.098897,
              21.884711
            ],
            [
              -160.079065,
              21.89608
            ],
            [
              -160.080012,
              21.910808
            ],
            [
              -160.085787,
              21.927295
            ],
            [
              -160.078393,
              21.955153
            ],
            [
              -160.065811,
              21.976562
            ],
            [
              -160.063349,
              21.978354
            ],
            [
              -160.060549,
              21.976729
            ],
            [
              -160.056336,
              21.977939
            ],
            [
              -160.052729,
              21.980321
            ],
            [
              -160.051992,
              21.983681
            ],
            [
              -160.058543,
              21.99638
            ],
            [
              -160.072123,
              22.003334
            ],
            [
              -160.09645,
              22.001489
            ],
            [
              -160.112746,
              21.995245
            ],
            [
              -160.122262,
              21.962881
            ],
            [
              -160.125,
              21.95909
            ],
            [
              -160.127302,
              21.955508
            ],
            [
              -160.13705,
              21.948632
            ],
            [
              -160.167471,
              21.932863
            ],
            [
              -160.190158,
              21.923592
            ],
            [
              -160.202716,
              21.912422
            ],
            [
              -160.205528,
              21.907507
            ],
            [
              -160.21383,
              21.899193
            ],
            [
              -160.228965,
              21.889117
            ],
            [
              -160.231028,
              21.886263
            ],
            [
              -160.244943,
              21.848943
            ]
          ]
        ]
      ]
    }
  }
]
};