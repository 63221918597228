export const counties_ak = {
  "type":"FeatureCollection","name":"ak_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "02",
      "COUNTYFP": "275",
      "COUNTYNS": "02516402",
      "AFFGEOID": "0500000US02275",
      "GEOID": "02275",
      "NAME": "Wrangell",
      "LSAD": "03",
      "ALAND": 6619614514,
      "AWATER": 2384746838,
      "County_state": "Wrangell,02"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -132.489883,
              56.503438
            ],
            [
              -132.487268,
              56.497141
            ],
            [
              -132.464705,
              56.502147
            ],
            [
              -132.458747,
              56.511439
            ],
            [
              -132.444011,
              56.519307
            ],
            [
              -132.440683,
              56.529008
            ],
            [
              -132.448289,
              56.543687
            ],
            [
              -132.475147,
              56.544604
            ],
            [
              -132.476335,
              56.530581
            ],
            [
              -132.489883,
              56.503438
            ]
          ]
        ],
        [
          [
            [
              -132.562321,
              56.379391
            ],
            [
              -132.559346,
              56.365114
            ],
            [
              -132.520085,
              56.353812
            ],
            [
              -132.494506,
              56.355002
            ],
            [
              -132.407061,
              56.386529
            ],
            [
              -132.404682,
              56.405565
            ],
            [
              -132.418363,
              56.415083
            ],
            [
              -132.478445,
              56.438283
            ],
            [
              -132.502239,
              56.436498
            ],
            [
              -132.555182,
              56.403781
            ],
            [
              -132.562321,
              56.379391
            ]
          ]
        ],
        [
          [
            [
              -132.65274,
              56.482898
            ],
            [
              -132.650956,
              56.474569
            ],
            [
              -132.599797,
              56.44899
            ],
            [
              -132.586115,
              56.44899
            ],
            [
              -132.576598,
              56.456129
            ],
            [
              -132.573028,
              56.488251
            ],
            [
              -132.562321,
              56.491821
            ],
            [
              -132.538526,
              56.513236
            ],
            [
              -132.521275,
              56.541194
            ],
            [
              -132.518301,
              56.556066
            ],
            [
              -132.52068,
              56.562015
            ],
            [
              -132.530793,
              56.56261
            ],
            [
              -132.546854,
              56.551902
            ],
            [
              -132.562916,
              56.534651
            ],
            [
              -132.581357,
              56.511451
            ],
            [
              -132.606936,
              56.512046
            ],
            [
              -132.615264,
              56.509072
            ],
            [
              -132.649171,
              56.486467
            ],
            [
              -132.65274,
              56.482898
            ]
          ]
        ],
        [
          [
            [
              -132.718342,
              56.217704
            ],
            [
              -132.723396,
              56.145814
            ],
            [
              -132.708697,
              56.112124
            ],
            [
              -132.68462,
              56.082323
            ],
            [
              -132.640079,
              56.033194
            ],
            [
              -132.607653,
              56.010769
            ],
            [
              -132.594235,
              56.021858
            ],
            [
              -132.593155,
              56.032288
            ],
            [
              -132.608723,
              56.052136
            ],
            [
              -132.595531,
              56.068458
            ],
            [
              -132.573677,
              56.0707
            ],
            [
              -132.545143,
              56.053662
            ],
            [
              -132.522076,
              56.077035
            ],
            [
              -132.492795,
              56.066436
            ],
            [
              -132.481802,
              56.030628
            ],
            [
              -132.465283,
              56.018541
            ],
            [
              -132.454825,
              55.99163
            ],
            [
              -132.449834,
              55.956186
            ],
            [
              -132.420005,
              55.947403
            ],
            [
              -132.38364,
              55.936821
            ],
            [
              -132.373301,
              55.917713
            ],
            [
              -132.39708,
              55.905546
            ],
            [
              -132.398349,
              55.884052
            ],
            [
              -132.397304,
              55.878867
            ],
            [
              -132.376518,
              55.853377
            ],
            [
              -132.372298,
              55.850359
            ],
            [
              -132.323242,
              55.851878
            ],
            [
              -132.309949,
              55.862301
            ],
            [
              -132.309306,
              55.865059
            ],
            [
              -132.319799,
              55.874347
            ],
            [
              -132.320487,
              55.887648
            ],
            [
              -132.279962,
              55.924839
            ],
            [
              -132.224241,
              55.930421
            ],
            [
              -132.191893,
              55.921717
            ],
            [
              -132.170198,
              55.919231
            ],
            [
              -132.159064,
              55.92256
            ],
            [
              -132.135474,
              55.941626
            ],
            [
              -132.129697,
              55.957855
            ],
            [
              -132.176955,
              56.055706
            ],
            [
              -132.10402,
              56.108109
            ],
            [
              -132.060993,
              56.129355
            ],
            [
              -132.034849,
              56.133432
            ],
            [
              -132.01813,
              56.183155
            ],
            [
              -131.993894,
              56.193351
            ],
            [
              -131.958838,
              56.194762
            ],
            [
              -131.943402,
              56.192557
            ],
            [
              -131.935728,
              56.177207
            ],
            [
              -131.970294,
              56.114042
            ],
            [
              -132.011297,
              56.077359
            ],
            [
              -132.033368,
              56.095572
            ],
            [
              -132.038364,
              56.091297
            ],
            [
              -132.07034,
              56.046733
            ],
            [
              -132.041795,
              55.958795
            ],
            [
              -132.067412,
              55.875078
            ],
            [
              -132.08605,
              55.832436
            ],
            [
              -132.113361,
              55.812718
            ],
            [
              -132.130413,
              55.811419
            ],
            [
              -132.183163,
              55.80083
            ],
            [
              -132.187494,
              55.785595
            ],
            [
              -132.184982,
              55.778776
            ],
            [
              -132.185478,
              55.753161
            ],
            [
              -132.190479,
              55.742501
            ],
            [
              -132.206951,
              55.736987
            ],
            [
              -132.229647,
              55.740488
            ],
            [
              -132.251732,
              55.756247
            ],
            [
              -132.265071,
              55.762174
            ],
            [
              -132.280431,
              55.765599
            ],
            [
              -132.283594,
              55.761774
            ],
            [
              -132.260119,
              55.732293
            ],
            [
              -132.237532,
              55.711347
            ],
            [
              -132.224167,
              55.701766
            ],
            [
              -132.215409,
              55.68227
            ],
            [
              -132.197869,
              55.633967
            ],
            [
              -132.19793277049,
              55.6338336808621
            ],
            [
              -132.182744,
              55.634282
            ],
            [
              -132.150456,
              55.649704
            ],
            [
              -132.106963,
              55.656821
            ],
            [
              -132.091454,
              55.678697
            ],
            [
              -132.081822,
              55.702236
            ],
            [
              -132.033082,
              55.694964
            ],
            [
              -132.017511,
              55.691849
            ],
            [
              -131.981152,
              55.695724
            ],
            [
              -131.970956,
              55.714805
            ],
            [
              -131.97917,
              55.751959
            ],
            [
              -131.942207,
              55.778271
            ],
            [
              -131.937391,
              55.799522
            ],
            [
              -131.963706,
              55.820478
            ],
            [
              -132.003759,
              55.848449
            ],
            [
              -131.995959,
              55.873719
            ],
            [
              -131.972101,
              55.881884
            ],
            [
              -131.960591,
              55.893311
            ],
            [
              -131.960463,
              55.903246
            ],
            [
              -131.952992,
              55.908583
            ],
            [
              -131.939949,
              55.910439
            ],
            [
              -131.934501,
              55.915916
            ],
            [
              -131.92154,
              55.917078
            ],
            [
              -131.906887,
              55.916951
            ],
            [
              -131.891194,
              55.923699
            ],
            [
              -131.878371,
              55.936312
            ],
            [
              -131.875496,
              55.94604
            ],
            [
              -131.874869,
              55.953785
            ],
            [
              -131.88821,
              55.961619
            ],
            [
              -131.902214,
              55.970769
            ],
            [
              -131.905691,
              55.977732
            ],
            [
              -131.892596,
              55.989207
            ],
            [
              -131.878884,
              55.993785
            ],
            [
              -131.878637,
              56.003187
            ],
            [
              -131.873423,
              56.012603
            ],
            [
              -131.875002,
              56.019462
            ],
            [
              -131.872408,
              56.026318
            ],
            [
              -131.863392,
              56.0312
            ],
            [
              -131.856908,
              56.037181
            ],
            [
              -131.84661,
              56.038548
            ],
            [
              -131.836355,
              56.035718
            ],
            [
              -131.830648,
              56.033961
            ],
            [
              -131.823804,
              56.03767
            ],
            [
              -131.823106,
              56.043592
            ],
            [
              -131.832242,
              56.051458
            ],
            [
              -131.831903,
              56.054603
            ],
            [
              -131.823424,
              56.054606
            ],
            [
              -131.812849,
              56.055288
            ],
            [
              -131.794601,
              56.055128
            ],
            [
              -131.759865,
              56.058903
            ],
            [
              -131.737973,
              56.063351
            ],
            [
              -131.717208,
              56.059172
            ],
            [
              -131.709907,
              56.053206
            ],
            [
              -131.688917,
              56.053134
            ],
            [
              -131.677387,
              56.061871
            ],
            [
              -131.665841,
              56.065077
            ],
            [
              -131.664253,
              56.072175
            ],
            [
              -131.675822,
              56.07625
            ],
            [
              -131.678733,
              56.080266
            ],
            [
              -131.675341,
              56.084438
            ],
            [
              -131.683593,
              56.089183
            ],
            [
              -131.688471,
              56.089963
            ],
            [
              -131.688045,
              56.092284
            ],
            [
              -131.686457,
              56.094423
            ],
            [
              -131.678035,
              56.097019
            ],
            [
              -131.662211,
              56.101852
            ],
            [
              -131.650285,
              56.100737
            ],
            [
              -131.631092,
              56.090267
            ],
            [
              -131.608155,
              56.087789
            ],
            [
              -131.60079,
              56.081651
            ],
            [
              -131.590987,
              56.072293
            ],
            [
              -131.573811,
              56.064574
            ],
            [
              -131.554247,
              56.061022
            ],
            [
              -131.540879,
              56.055834
            ],
            [
              -131.526416,
              56.047568
            ],
            [
              -131.52838,
              56.042604
            ],
            [
              -131.524153,
              56.034154
            ],
            [
              -131.511118,
              56.030851
            ],
            [
              -131.497293,
              56.024439
            ],
            [
              -131.491044,
              56.017406
            ],
            [
              -131.478819,
              56.020639
            ],
            [
              -131.45747,
              56.022844
            ],
            [
              -131.42703,
              56.025262
            ],
            [
              -131.394846,
              56.021413
            ],
            [
              -131.381401,
              56.014155
            ],
            [
              -131.371818,
              56.026909
            ],
            [
              -131.381445,
              56.043565
            ],
            [
              -131.375646,
              56.058802
            ],
            [
              -131.371866,
              56.071543
            ],
            [
              -131.393425,
              56.082359
            ],
            [
              -131.41713,
              56.10026
            ],
            [
              -131.427868,
              56.118787
            ],
            [
              -131.433921,
              56.124128
            ],
            [
              -131.439435,
              56.134253
            ],
            [
              -131.4181,
              56.142113
            ],
            [
              -131.384999,
              56.145931
            ],
            [
              -131.358311,
              56.157093
            ],
            [
              -131.344269,
              56.17532
            ],
            [
              -131.331637,
              56.180395
            ],
            [
              -131.316732,
              56.191884
            ],
            [
              -131.290925,
              56.196036
            ],
            [
              -131.279281,
              56.20378
            ],
            [
              -131.266683,
              56.206579
            ],
            [
              -131.247816,
              56.19824
            ],
            [
              -131.248963,
              56.188682
            ],
            [
              -131.243243,
              56.179796
            ],
            [
              -131.222848,
              56.176482
            ],
            [
              -131.205311,
              56.185379
            ],
            [
              -131.146417,
              56.189587
            ],
            [
              -131.124664,
              56.200242
            ],
            [
              -131.094214,
              56.204584
            ],
            [
              -131.062065,
              56.23032
            ],
            [
              -131.039343,
              56.243899
            ],
            [
              -131.012782,
              56.254255
            ],
            [
              -131.001443,
              56.270883
            ],
            [
              -130.998845,
              56.273795
            ],
            [
              -131.002503,
              56.277299
            ],
            [
              -131.006308,
              56.275704
            ],
            [
              -131.020668,
              56.276255
            ],
            [
              -131.024698,
              56.280712
            ],
            [
              -131.035236,
              56.302345
            ],
            [
              -131.022362,
              56.307397
            ],
            [
              -131.041772,
              56.321688
            ],
            [
              -131.070272,
              56.331891
            ],
            [
              -131.105352,
              56.343623
            ],
            [
              -131.106495,
              56.368453
            ],
            [
              -131.082629,
              56.38812
            ],
            [
              -131.086135,
              56.40453
            ],
            [
              -131.086812970675,
              56.40708858642459
            ],
            [
              -131.130165,
              56.428534
            ],
            [
              -131.167925,
              56.448361
            ],
            [
              -131.191822,
              56.455776
            ],
            [
              -131.227928,
              56.469099
            ],
            [
              -131.363465,
              56.51569
            ],
            [
              -131.461806,
              56.547904
            ],
            [
              -131.495949,
              56.565543
            ],
            [
              -131.581221,
              56.613275
            ],
            [
              -131.761209,
              56.60396
            ],
            [
              -131.835133,
              56.601849
            ],
            [
              -131.844866,
              56.638977
            ],
            [
              -131.849898,
              56.661227
            ],
            [
              -131.862035,
              56.704136
            ],
            [
              -131.90176,
              56.753158
            ],
            [
              -131.896722,
              56.759737
            ],
            [
              -131.8865,
              56.776083
            ],
            [
              -131.871725,
              56.804965
            ],
            [
              -132.125934,
              56.874698
            ],
            [
              -132.119034236858,
              56.891264078517004
            ],
            [
              -132.160186,
              56.903305
            ],
            [
              -132.215746,
              56.891606
            ],
            [
              -132.23902,
              56.877465
            ],
            [
              -132.242331,
              56.858213
            ],
            [
              -132.277981,
              56.845858
            ],
            [
              -132.284604,
              56.8167
            ],
            [
              -132.30303,
              56.799253
            ],
            [
              -132.346219,
              56.782806
            ],
            [
              -132.348468,
              56.771287
            ],
            [
              -132.368625,
              56.777706
            ],
            [
              -132.401076,
              56.778918
            ],
            [
              -132.439599,
              56.770409
            ],
            [
              -132.470802,
              56.730892
            ],
            [
              -132.495538,
              56.727769
            ],
            [
              -132.518409,
              56.717192
            ],
            [
              -132.55064850405302,
              56.713875586357396
            ],
            [
              -132.542885,
              56.701905
            ],
            [
              -132.587094,
              56.682458
            ],
            [
              -132.581152,
              56.659604
            ],
            [
              -132.570694,
              56.633077
            ],
            [
              -132.485842,
              56.606007
            ],
            [
              -132.466115,
              56.583892
            ],
            [
              -132.484654,
              56.565825
            ],
            [
              -132.45079,
              56.564098
            ],
            [
              -132.408359,
              56.58507
            ],
            [
              -132.367088,
              56.574578
            ],
            [
              -132.361293,
              56.534232
            ],
            [
              -132.357564,
              56.529008
            ],
            [
              -132.290475,
              56.487017
            ],
            [
              -132.279753,
              56.485881
            ],
            [
              -132.259611,
              56.48763
            ],
            [
              -132.239043,
              56.476671
            ],
            [
              -132.208568,
              56.457125
            ],
            [
              -132.181647,
              56.399336
            ],
            [
              -132.17935,
              56.390823
            ],
            [
              -132.181158,
              56.387128
            ],
            [
              -132.199269,
              56.371054
            ],
            [
              -132.204367,
              56.372086
            ],
            [
              -132.223136,
              56.384017
            ],
            [
              -132.238473,
              56.398706
            ],
            [
              -132.242,
              56.41366
            ],
            [
              -132.233927,
              56.416736
            ],
            [
              -132.245479,
              56.441215
            ],
            [
              -132.253393,
              56.449539
            ],
            [
              -132.362556,
              56.487904
            ],
            [
              -132.382379,
              56.491972
            ],
            [
              -132.38938,
              56.491367
            ],
            [
              -132.394268,
              56.485579
            ],
            [
              -132.361132,
              56.380627
            ],
            [
              -132.340678,
              56.341754
            ],
            [
              -132.349149,
              56.304456
            ],
            [
              -132.35871,
              56.2908
            ],
            [
              -132.363966,
              56.287126
            ],
            [
              -132.382793,
              56.299203
            ],
            [
              -132.380574,
              56.307785
            ],
            [
              -132.381766,
              56.310756
            ],
            [
              -132.403678,
              56.334811
            ],
            [
              -132.422041,
              56.349341
            ],
            [
              -132.431631,
              56.352163
            ],
            [
              -132.441839,
              56.353983
            ],
            [
              -132.52936,
              56.338555
            ],
            [
              -132.543076,
              56.332276
            ],
            [
              -132.575023,
              56.296468
            ],
            [
              -132.582033,
              56.285456
            ],
            [
              -132.58207,
              56.278816
            ],
            [
              -132.601495,
              56.240065
            ],
            [
              -132.615797,
              56.234172
            ],
            [
              -132.64425,
              56.232807
            ],
            [
              -132.664212,
              56.236332
            ],
            [
              -132.672471,
              56.239439
            ],
            [
              -132.689888,
              56.238744
            ],
            [
              -132.718342,
              56.217704
            ]
          ]
        ],
        [
          [
            [
              -133.025493,
              56.229572
            ],
            [
              -133.008261,
              56.207766
            ],
            [
              -133.004339,
              56.197189
            ],
            [
              -133.012658,
              56.186345
            ],
            [
              -133.010281,
              56.173976
            ],
            [
              -133.002675,
              56.155713
            ],
            [
              -132.971777,
              56.152403
            ],
            [
              -132.941354,
              56.125321
            ],
            [
              -132.912595,
              56.108095
            ],
            [
              -132.896433,
              56.099744
            ],
            [
              -132.884192,
              56.1128
            ],
            [
              -132.85674,
              56.106372
            ],
            [
              -132.849253,
              56.112601
            ],
            [
              -132.878488,
              56.142738
            ],
            [
              -132.884073,
              56.171594
            ],
            [
              -132.915447,
              56.209286
            ],
            [
              -132.930896,
              56.228383
            ],
            [
              -132.962864,
              56.254402
            ],
            [
              -132.960487,
              56.271298
            ],
            [
              -132.978551,
              56.27849
            ],
            [
              -132.998991,
              56.275125
            ],
            [
              -133.005646,
              56.255458
            ],
            [
              -133.025493,
              56.229572
            ]
          ]
        ],
        [
          [
            [
              -133.069441,
              56.356426
            ],
            [
              -133.070863,
              56.354194
            ],
            [
              -133.067556,
              56.333573
            ],
            [
              -133.045383,
              56.320783
            ],
            [
              -133.010587,
              56.309492
            ],
            [
              -132.961082,
              56.296167
            ],
            [
              -132.94076,
              56.273542
            ],
            [
              -132.877582,
              56.240322
            ],
            [
              -132.843716,
              56.238933
            ],
            [
              -132.776045,
              56.254585
            ],
            [
              -132.721254,
              56.258464
            ],
            [
              -132.662081,
              56.274795
            ],
            [
              -132.655513,
              56.295575
            ],
            [
              -132.655467,
              56.303756
            ],
            [
              -132.662478,
              56.320451
            ],
            [
              -132.676553,
              56.333105
            ],
            [
              -132.684112,
              56.345671
            ],
            [
              -132.679401,
              56.354299
            ],
            [
              -132.662178,
              56.369134
            ],
            [
              -132.65238,
              56.375879
            ],
            [
              -132.620608,
              56.3912
            ],
            [
              -132.628592,
              56.416284
            ],
            [
              -132.634335,
              56.422174
            ],
            [
              -132.668127,
              56.440279
            ],
            [
              -132.716056,
              56.454861
            ],
            [
              -132.734466,
              56.458515
            ],
            [
              -132.782864,
              56.45153
            ],
            [
              -132.791872,
              56.449169
            ],
            [
              -132.808145,
              56.440801
            ],
            [
              -132.819256,
              56.439511
            ],
            [
              -132.843184,
              56.444827
            ],
            [
              -132.871919,
              56.457038
            ],
            [
              -132.896342,
              56.457978
            ],
            [
              -132.927663,
              56.456859
            ],
            [
              -132.957364,
              56.448963
            ],
            [
              -132.977163,
              56.439673
            ],
            [
              -133.002357,
              56.430655
            ],
            [
              -133.010817,
              56.424264
            ],
            [
              -133.010871,
              56.421404
            ],
            [
              -133.006314,
              56.417778
            ],
            [
              -133.006575,
              56.415881
            ],
            [
              -133.045714,
              56.371451
            ],
            [
              -133.060361,
              56.358378
            ],
            [
              -133.069441,
              56.356426
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "02",
      "COUNTYFP": "158",
      "COUNTYNS": "01419985",
      "AFFGEOID": "0500000US02158",
      "GEOID": "02158",
      "NAME": "Kusilvak",
      "LSAD": "05",
      "ALAND": 44218384953,
      "AWATER": 6697190751,
      "County_state": "Kusilvak,02"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -166.182688,
              61.588481
            ],
            [
              -166.18185,
              61.581342
            ],
            [
              -166.178627,
              61.574807
            ],
            [
              -166.165232,
              61.550618
            ],
            [
              -166.158345,
              61.541537
            ],
            [
              -166.149577,
              61.513288
            ],
            [
              -166.134247,
              61.504331
            ],
            [
              -166.108269,
              61.492475
            ],
            [
              -166.075398,
              61.49298
            ],
            [
              -166.075816,
              61.502056
            ],
            [
              -166.08868,
              61.522885
            ],
            [
              -166.075524,
              61.532672
            ],
            [
              -166.034748,
              61.535221
            ],
            [
              -165.999535,
              61.53972
            ],
            [
              -165.985948,
              61.54665
            ],
            [
              -165.981879,
              61.551249
            ],
            [
              -165.964035,
              61.555919
            ],
            [
              -165.912496,
              61.5562
            ],
            [
              -165.865668,
              61.535046
            ],
            [
              -165.807627,
              61.529171
            ],
            [
              -165.754317,
              61.498704
            ],
            [
              -165.746352,
              61.489304
            ],
            [
              -165.748503,
              61.476446
            ],
            [
              -165.767226,
              61.45695
            ],
            [
              -165.791085,
              61.449852
            ],
            [
              -165.800525,
              61.449657
            ],
            [
              -165.844525,
              61.440601
            ],
            [
              -165.918612,
              61.419087
            ],
            [
              -165.92195,
              61.409638
            ],
            [
              -165.921194,
              61.40308
            ],
            [
              -165.915445,
              61.387686
            ],
            [
              -165.879599,
              61.335044
            ],
            [
              -165.858993,
              61.318865
            ],
            [
              -165.831365,
              61.306719
            ],
            [
              -165.816434,
              61.303363
            ],
            [
              -165.809373,
              61.306827
            ],
            [
              -165.787442,
              61.310063
            ],
            [
              -165.662892,
              61.29457
            ],
            [
              -165.635791,
              61.285456
            ],
            [
              -165.623317,
              61.278431
            ],
            [
              -165.620589,
              61.268586
            ],
            [
              -165.627549,
              61.258125
            ],
            [
              -165.634048,
              61.237557
            ],
            [
              -165.631996,
              61.220708
            ],
            [
              -165.621281,
              61.192374
            ],
            [
              -165.627038,
              61.167152
            ],
            [
              -165.590682,
              61.111169
            ],
            [
              -165.55514,
              61.092674
            ],
            [
              -165.459236,
              61.083424
            ],
            [
              -165.417286,
              61.078711
            ],
            [
              -165.385437,
              61.079574
            ],
            [
              -165.368324,
              61.083998
            ],
            [
              -165.363927,
              61.110765
            ],
            [
              -165.325552,
              61.169306
            ],
            [
              -165.307976,
              61.181823
            ],
            [
              -165.2897,
              61.181714
            ],
            [
              -165.243779,
              61.151617
            ],
            [
              -165.203757,
              61.150341
            ],
            [
              -165.172994,
              61.146919
            ],
            [
              -165.16886,
              61.144913
            ],
            [
              -165.165857,
              61.136567
            ],
            [
              -165.167072,
              61.133487
            ],
            [
              -165.17711,
              61.125494
            ],
            [
              -165.175321,
              61.120926
            ],
            [
              -165.167636,
              61.113502
            ],
            [
              -165.119781,
              61.07864
            ],
            [
              -165.057842,
              61.059746
            ],
            [
              -165.011271,
              61.051984
            ],
            [
              -164.993503,
              61.072389
            ],
            [
              -164.952384,
              61.069285
            ],
            [
              -164.950573,
              61.048079
            ],
            [
              -164.951103,
              61.03102
            ],
            [
              -164.961527,
              61.024166
            ],
            [
              -164.998172,
              61.013826
            ],
            [
              -165.020265,
              61.011153
            ],
            [
              -165.083282,
              61.012933
            ],
            [
              -165.096828,
              61.014944
            ],
            [
              -165.097425,
              61.016658
            ],
            [
              -165.115681,
              61.016097
            ],
            [
              -165.133937,
              61.01125
            ],
            [
              -165.190271,
              60.983073
            ],
            [
              -165.194964,
              60.979915
            ],
            [
              -165.194945,
              60.9739
            ],
            [
              -165.172467,
              60.940328
            ],
            [
              -165.132427,
              60.922464
            ],
            [
              -165.12647326398502,
              60.9213681097544
            ],
            [
              -165.000514,
              60.963635
            ],
            [
              -164.991784,
              60.948201
            ],
            [
              -164.938278,
              60.953377
            ],
            [
              -164.885929,
              60.967097
            ],
            [
              -164.852984,
              60.969946
            ],
            [
              -164.797619,
              60.982487
            ],
            [
              -164.768849,
              60.974739
            ],
            [
              -164.755295,
              60.978727
            ],
            [
              -164.730051,
              60.97068
            ],
            [
              -164.727016,
              60.984122
            ],
            [
              -164.70231,
              60.982076
            ],
            [
              -164.675958,
              60.969455
            ],
            [
              -164.675881,
              60.976888
            ],
            [
              -164.616585,
              60.979049
            ],
            [
              -164.615567,
              60.951888
            ],
            [
              -164.60332,
              60.943566
            ],
            [
              -164.576278,
              60.94579
            ],
            [
              -164.529911,
              60.940796
            ],
            [
              -164.450897,
              60.95742
            ],
            [
              -164.411625,
              60.950414
            ],
            [
              -164.390066,
              60.972349
            ],
            [
              -164.33936,
              60.978432
            ],
            [
              -164.301782,
              60.974832
            ],
            [
              -164.285663,
              60.985916
            ],
            [
              -164.239727,
              60.987119
            ],
            [
              -164.219665,
              61.00691
            ],
            [
              -164.154702,
              61.022625
            ],
            [
              -164.146306,
              61.074682
            ],
            [
              -164.189996,
              61.071539
            ],
            [
              -164.211781,
              61.082262
            ],
            [
              -164.199462,
              61.109761
            ],
            [
              -164.228464,
              61.121035
            ],
            [
              -164.201874,
              61.151613
            ],
            [
              -164.15671,
              61.152982
            ],
            [
              -164.130782,
              61.141209
            ],
            [
              -164.121482,
              61.127
            ],
            [
              -164.077227,
              61.143477
            ],
            [
              -164.080069,
              61.160043
            ],
            [
              -164.017645,
              61.192886
            ],
            [
              -163.998269,
              61.192027
            ],
            [
              -163.920874,
              61.210692
            ],
            [
              -163.913489,
              61.222403
            ],
            [
              -163.923782,
              61.248145
            ],
            [
              -163.947621,
              61.255281
            ],
            [
              -163.981767,
              61.243286
            ],
            [
              -163.979172,
              61.261815
            ],
            [
              -163.990582,
              61.284427
            ],
            [
              -163.977044,
              61.303691
            ],
            [
              -164.008631,
              61.323182
            ],
            [
              -163.992933,
              61.350702
            ],
            [
              -163.99276,
              61.371763
            ],
            [
              -163.933598,
              61.36527
            ],
            [
              -163.876262,
              61.368976
            ],
            [
              -163.822125,
              61.355913
            ],
            [
              -163.785076,
              61.367097
            ],
            [
              -163.707616,
              61.349682
            ],
            [
              -163.690351,
              61.349937
            ],
            [
              -163.65539,
              61.335827
            ],
            [
              -163.606616,
              61.336979
            ],
            [
              -163.524124,
              61.355098
            ],
            [
              -163.520838,
              61.364481
            ],
            [
              -163.542182,
              61.381754
            ],
            [
              -163.531412,
              61.401454
            ],
            [
              -163.468274,
              61.409152
            ],
            [
              -163.464435,
              61.422478
            ],
            [
              -163.48634,
              61.433022
            ],
            [
              -163.447765,
              61.446783
            ],
            [
              -163.428866,
              61.46228
            ],
            [
              -163.431258,
              61.484107
            ],
            [
              -163.40774,
              61.483862
            ],
            [
              -163.397082,
              61.463256
            ],
            [
              -163.359261,
              61.474442
            ],
            [
              -163.295078,
              61.455794
            ],
            [
              -163.171354,
              61.449071
            ],
            [
              -163.137346,
              61.457491
            ],
            [
              -163.100421,
              61.474374
            ],
            [
              -163.061999,
              61.479931
            ],
            [
              -162.9602,
              61.482486
            ],
            [
              -162.914965,
              61.485735
            ],
            [
              -162.863646,
              61.493914
            ],
            [
              -162.847199,
              61.498736
            ],
            [
              -162.75398,
              61.542666
            ],
            [
              -162.717538,
              61.536897
            ],
            [
              -162.505761,
              61.556426
            ],
            [
              -162.468381,
              61.543334
            ],
            [
              -162.476189,
              61.503959
            ],
            [
              -162.398638,
              61.49916
            ],
            [
              -162.368732,
              61.503876
            ],
            [
              -162.328294,
              61.518376
            ],
            [
              -162.2664,
              61.510158
            ],
            [
              -162.221874,
              61.509856
            ],
            [
              -162.126481,
              61.497289
            ],
            [
              -162.05567,
              61.481588
            ],
            [
              -162.013438,
              61.477057
            ],
            [
              -161.959929,
              61.481012
            ],
            [
              -161.900328,
              61.480317
            ],
            [
              -161.738446,
              61.51807
            ],
            [
              -161.697058,
              61.530087
            ],
            [
              -161.663063,
              61.53363
            ],
            [
              -161.599227,
              61.533271
            ],
            [
              -161.537348,
              61.54393
            ],
            [
              -161.449611,
              61.568838
            ],
            [
              -161.326637,
              61.580435
            ],
            [
              -161.275493,
              61.588041
            ],
            [
              -161.126812,
              61.59954
            ],
            [
              -161.084222,
              61.615455
            ],
            [
              -161.027337,
              61.653532
            ],
            [
              -160.953961,
              61.685669
            ],
            [
              -160.869268,
              61.71369
            ],
            [
              -160.775244,
              61.738527
            ],
            [
              -160.683572,
              61.75157
            ],
            [
              -160.597733,
              61.760156
            ],
            [
              -160.460921,
              61.800225
            ],
            [
              -160.40398,
              61.812525
            ],
            [
              -160.35014,
              61.819071
            ],
            [
              -160.265343,
              61.817615
            ],
            [
              -160.20263,
              61.810322
            ],
            [
              -160.187597,
              61.827968
            ],
            [
              -160.187745,
              61.835212
            ],
            [
              -160.247325,
              61.841293
            ],
            [
              -160.252781,
              61.857789
            ],
            [
              -160.287193,
              61.848961
            ],
            [
              -160.321954,
              61.846512
            ],
            [
              -160.397691,
              61.851223
            ],
            [
              -160.437948,
              61.858332
            ],
            [
              -160.469477,
              61.869348
            ],
            [
              -160.502057,
              61.892448
            ],
            [
              -160.528204,
              61.92644
            ],
            [
              -160.534671,
              61.94594
            ],
            [
              -160.915953,
              61.945943
            ],
            [
              -160.915961,
              62.054929
            ],
            [
              -160.915964,
              62.205866
            ],
            [
              -161.048165,
              62.205869
            ],
            [
              -161.048171,
              62.50775
            ],
            [
              -161.048176,
              62.55241
            ],
            [
              -160.996201,
              62.552424
            ],
            [
              -160.996215,
              62.898936
            ],
            [
              -160.943437,
              62.898939
            ],
            [
              -160.94344,
              62.999269
            ],
            [
              -160.857996,
              62.999271
            ],
            [
              -160.85799,
              63.011953
            ],
            [
              -161.452121,
              63.011935
            ],
            [
              -162.001769,
              63.011919
            ],
            [
              -162.001798,
              63.098536
            ],
            [
              -162.192443,
              63.098556
            ],
            [
              -162.192503,
              63.185184
            ],
            [
              -162.383076,
              63.185195
            ],
            [
              -162.383082,
              63.271816
            ],
            [
              -162.593381202272,
              63.272282238119196
            ],
            [
              -162.662614,
              63.229906
            ],
            [
              -162.688083,
              63.220608
            ],
            [
              -162.72408,
              63.214615
            ],
            [
              -162.747621,
              63.213572
            ],
            [
              -162.758741,
              63.217187
            ],
            [
              -162.769536,
              63.217069
            ],
            [
              -162.821122,
              63.205596
            ],
            [
              -162.834354,
              63.198076
            ],
            [
              -162.839167,
              63.193004
            ],
            [
              -162.840187,
              63.187579
            ],
            [
              -162.834926,
              63.164621
            ],
            [
              -162.83785,
              63.159224
            ],
            [
              -162.844559,
              63.154191
            ],
            [
              -162.901643,
              63.125597
            ],
            [
              -162.919727,
              63.120153
            ],
            [
              -162.998302,
              63.089286
            ],
            [
              -163.0405,
              63.062151
            ],
            [
              -163.053996,
              63.058334
            ],
            [
              -163.130853,
              63.049387
            ],
            [
              -163.316203,
              63.037763
            ],
            [
              -163.364979,
              63.055805
            ],
            [
              -163.417683,
              63.083874
            ],
            [
              -163.433968,
              63.089296
            ],
            [
              -163.474794,
              63.099053
            ],
            [
              -163.507217,
              63.113685
            ],
            [
              -163.520806,
              63.12328
            ],
            [
              -163.529938,
              63.1354
            ],
            [
              -163.590122,
              63.146091
            ],
            [
              -163.616272,
              63.141213
            ],
            [
              -163.650294,
              63.157564
            ],
            [
              -163.70398,
              63.188107
            ],
            [
              -163.724072,
              63.206592
            ],
            [
              -163.725805,
              63.21062
            ],
            [
              -163.73265,
              63.213257
            ],
            [
              -163.755283,
              63.217461
            ],
            [
              -163.788882,
              63.217482
            ],
            [
              -163.885059,
              63.222308
            ],
            [
              -163.909405,
              63.232514
            ],
            [
              -163.970266,
              63.248291
            ],
            [
              -164.036565,
              63.261204
            ],
            [
              -164.066991,
              63.262276
            ],
            [
              -164.140096,
              63.259336
            ],
            [
              -164.209475,
              63.251472
            ],
            [
              -164.363592,
              63.22628
            ],
            [
              -164.423449,
              63.211977
            ],
            [
              -164.493118,
              63.17767
            ],
            [
              -164.633943,
              63.09782
            ],
            [
              -164.640324,
              63.091257
            ],
            [
              -164.644886,
              63.079268
            ],
            [
              -164.643672,
              63.074975
            ],
            [
              -164.641186,
              63.07268
            ],
            [
              -164.611616,
              63.077673
            ],
            [
              -164.580201,
              63.070127
            ],
            [
              -164.583735,
              63.058457
            ],
            [
              -164.685213,
              63.022191
            ],
            [
              -164.716841,
              63.006264
            ],
            [
              -164.766117,
              62.958228
            ],
            [
              -164.783858,
              62.946154
            ],
            [
              -164.813007,
              62.903919
            ],
            [
              -164.836318,
              62.852168
            ],
            [
              -164.850838,
              62.83951
            ],
            [
              -164.87564,
              62.806254
            ],
            [
              -164.877773,
              62.797774
            ],
            [
              -164.8773,
              62.78432
            ],
            [
              -164.837703,
              62.685267
            ],
            [
              -164.869519,
              62.665973
            ],
            [
              -164.936739,
              62.668947
            ],
            [
              -165.003661,
              62.641048
            ],
            [
              -165.013179,
              62.640096
            ],
            [
              -165.052202,
              62.598217
            ],
            [
              -165.075997,
              62.582037
            ],
            [
              -165.096155,
              62.522452
            ],
            [
              -165.199804,
              62.469637
            ],
            [
              -165.26927,
              62.427352
            ],
            [
              -165.294962,
              62.403353
            ],
            [
              -165.311967,
              62.378812
            ],
            [
              -165.337722,
              62.359031
            ],
            [
              -165.373713,
              62.338196
            ],
            [
              -165.500322,
              62.255451
            ],
            [
              -165.620746,
              62.172616
            ],
            [
              -165.672037,
              62.13989
            ],
            [
              -165.706155,
              62.108365
            ],
            [
              -165.747898,
              62.073592
            ],
            [
              -165.760495,
              62.038842
            ],
            [
              -165.759804,
              62.001906
            ],
            [
              -165.743528,
              61.962533
            ],
            [
              -165.703673,
              61.917012
            ],
            [
              -165.682536,
              61.894071
            ],
            [
              -165.629296,
              61.861358
            ],
            [
              -165.639516,
              61.847006
            ],
            [
              -165.696038,
              61.847055
            ],
            [
              -165.730439,
              61.842075
            ],
            [
              -165.736429,
              61.839188
            ],
            [
              -165.736904,
              61.832901
            ],
            [
              -165.74709,
              61.82772
            ],
            [
              -165.758413,
              61.825444
            ],
            [
              -165.870982,
              61.826013
            ],
            [
              -165.955265,
              61.832408
            ],
            [
              -166.085334,
              61.816498
            ],
            [
              -166.094312,
              61.813859
            ],
            [
              -166.094045,
              61.805296
            ],
            [
              -166.092081,
              61.800733
            ],
            [
              -166.006693,
              61.729879
            ],
            [
              -165.993851,
              61.723105
            ],
            [
              -165.934968,
              61.706299
            ],
            [
              -165.856791,
              61.690734
            ],
            [
              -165.81,
              61.68936
            ],
            [
              -165.809933,
              61.673029
            ],
            [
              -165.82214,
              61.67061
            ],
            [
              -165.903783,
              61.663632
            ],
            [
              -165.967894,
              61.654432
            ],
            [
              -166.015134,
              61.645866
            ],
            [
              -166.031834,
              61.641199
            ],
            [
              -166.053983,
              61.638201
            ],
            [
              -166.132162,
              61.63159
            ],
            [
              -166.139409,
              61.632315
            ],
            [
              -166.140133,
              61.639562
            ],
            [
              -166.138684,
              61.667101
            ],
            [
              -166.134402,
              61.709068
            ],
            [
              -166.13302,
              61.721918
            ],
            [
              -166.134285,
              61.723919
            ],
            [
              -166.143757,
              61.724352
            ],
            [
              -166.153178,
              61.714931
            ],
            [
              -166.158976,
              61.700437
            ],
            [
              -166.182688,
              61.588481
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "02",
      "COUNTYFP": "060",
      "COUNTYNS": "01419967",
      "AFFGEOID": "0500000US02060",
      "GEOID": "02060",
      "NAME": "Bristol Bay",
      "LSAD": "04",
      "ALAND": 1248342013,
      "AWATER": 949750202,
      "County_state": "Bristol Bay,02"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -157.251462,
              58.620786
            ],
            [
              -157.257442167593,
              58.6166710680046
            ],
            [
              -156.313889,
              58.616666
            ],
            [
              -156.313889,
              58.885833
            ],
            [
              -156.991700361036,
              58.8858341751265
            ],
            [
              -157.012392,
              58.875889
            ],
            [
              -157.016088,
              58.86349
            ],
            [
              -156.997469,
              58.856349
            ],
            [
              -156.993548,
              58.836798
            ],
            [
              -157.008226,
              58.817139
            ],
            [
              -157.048689,
              58.78036
            ],
            [
              -157.062594,
              58.75713
            ],
            [
              -157.061928,
              58.726102
            ],
            [
              -157.051779,
              58.711919
            ],
            [
              -157.077914,
              58.708103
            ],
            [
              -157.178834,
              58.66044
            ],
            [
              -157.251462,
              58.620786
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "02",
      "COUNTYFP": "105",
      "COUNTYNS": "02371430",
      "AFFGEOID": "0500000US02105",
      "GEOID": "02105",
      "NAME": "Hoonah-Angoon",
      "LSAD": "05",
      "ALAND": 16981434901,
      "AWATER": 7808657621,
      "County_state": "Hoonah-Angoon,02"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -133.598542053296,
              57.8602826614518
            ],
            [
              -133.569787,
              57.859365
            ],
            [
              -133.556097,
              57.78883
            ],
            [
              -133.559889,
              57.777457
            ],
            [
              -133.55295059887598,
              57.7730267528967
            ],
            [
              -133.548546,
              57.779935
            ],
            [
              -133.544743,
              57.785581
            ],
            [
              -133.536264,
              57.791851
            ],
            [
              -133.529275,
              57.799126
            ],
            [
              -133.524292,
              57.805775
            ],
            [
              -133.517961,
              57.812296
            ],
            [
              -133.510763,
              57.815073
            ],
            [
              -133.501622,
              57.817675
            ],
            [
              -133.497415,
              57.820814
            ],
            [
              -133.489937,
              57.822243
            ],
            [
              -133.483116,
              57.819149
            ],
            [
              -133.472395,
              57.818739
            ],
            [
              -133.461948,
              57.816258
            ],
            [
              -133.452242,
              57.814062
            ],
            [
              -133.444674,
              57.810062
            ],
            [
              -133.438968,
              57.807073
            ],
            [
              -133.433614,
              57.806406
            ],
            [
              -133.424858,
              57.804256
            ],
            [
              -133.421656,
              57.795224
            ],
            [
              -133.419948,
              57.786494
            ],
            [
              -133.413152,
              57.783242
            ],
            [
              -133.406438,
              57.783857
            ],
            [
              -133.396645,
              57.785504
            ],
            [
              -133.387516,
              57.78287
            ],
            [
              -133.377669,
              57.77831
            ],
            [
              -133.371607,
              57.778214
            ],
            [
              -133.364259,
              57.777074
            ],
            [
              -133.358918,
              57.774717
            ],
            [
              -133.351492,
              57.772397
            ],
            [
              -133.34182,
              57.773985
            ],
            [
              -133.330582,
              57.776903
            ],
            [
              -133.321375,
              57.776419
            ],
            [
              -133.311214,
              57.781774
            ],
            [
              -133.304427,
              57.786165
            ],
            [
              -133.29822,
              57.789197
            ],
            [
              -133.289677,
              57.792708
            ],
            [
              -133.283798,
              57.794375
            ],
            [
              -133.277367,
              57.792219
            ],
            [
              -133.275891,
              57.788865
            ],
            [
              -133.274972,
              57.782977
            ],
            [
              -133.282708,
              57.772922
            ],
            [
              -133.281468,
              57.769862
            ],
            [
              -133.275642,
              57.767246
            ],
            [
              -133.270088,
              57.767459
            ],
            [
              -133.259486,
              57.769688
            ],
            [
              -133.248474,
              57.770367
            ],
            [
              -133.233511,
              57.771487
            ],
            [
              -133.222702,
              57.768198
            ],
            [
              -133.218718,
              57.762393
            ],
            [
              -133.218899,
              57.758334
            ],
            [
              -133.212355,
              57.754766
            ],
            [
              -133.207183,
              57.754444
            ],
            [
              -133.198032,
              57.754333
            ],
            [
              -133.191787,
              57.75139
            ],
            [
              -133.191757,
              57.749502
            ],
            [
              -133.191908,
              57.745195
            ],
            [
              -133.187448,
              57.74108
            ],
            [
              -133.179644,
              57.741528
            ],
            [
              -133.174281,
              57.742513
            ],
            [
              -133.165287,
              57.74074
            ],
            [
              -133.15545,
              57.739685
            ],
            [
              -133.143869,
              57.741392
            ],
            [
              -133.137145,
              57.741725
            ],
            [
              -133.132053,
              57.739709
            ],
            [
              -133.131584,
              57.73643
            ],
            [
              -133.131719,
              57.732851
            ],
            [
              -133.122977,
              57.729501
            ],
            [
              -133.111588,
              57.728413
            ],
            [
              -133.107441,
              57.727291
            ],
            [
              -133.100435,
              57.729098
            ],
            [
              -133.096518,
              57.732587
            ],
            [
              -133.085173,
              57.736903
            ],
            [
              -133.079496,
              57.739792
            ],
            [
              -133.070842,
              57.741939
            ],
            [
              -133.064993,
              57.739191
            ],
            [
              -133.060474,
              57.734595
            ],
            [
              -133.055211,
              57.728643
            ],
            [
              -133.052396,
              57.722652
            ],
            [
              -133.042527,
              57.720325
            ],
            [
              -133.035089,
              57.720723
            ],
            [
              -133.025215,
              57.71932
            ],
            [
              -133.017198,
              57.717731
            ],
            [
              -133.01307,
              57.71649
            ],
            [
              -133.006258,
              57.716097
            ],
            [
              -132.99762,
              57.71721
            ],
            [
              -132.988311,
              57.719732
            ],
            [
              -132.973508,
              57.723823
            ],
            [
              -132.966078,
              57.731458
            ],
            [
              -132.961341,
              57.737318
            ],
            [
              -132.957639,
              57.741818
            ],
            [
              -132.954046,
              57.745545
            ],
            [
              -132.948296,
              57.744422
            ],
            [
              -132.941507,
              57.741383
            ],
            [
              -132.936174,
              57.73758
            ],
            [
              -132.931647,
              57.73543
            ],
            [
              -132.926669,
              57.732686
            ],
            [
              -132.922462,
              57.729994
            ],
            [
              -132.913572,
              57.728949
            ],
            [
              -132.893391,
              57.727435
            ],
            [
              -132.876604,
              57.718962
            ],
            [
              -132.878855,
              57.708186
            ],
            [
              -132.890604,
              57.703031
            ],
            [
              -132.896416,
              57.690561
            ],
            [
              -132.898629,
              57.68688
            ],
            [
              -132.891513,
              57.683928
            ],
            [
              -132.886108,
              57.683827
            ],
            [
              -132.872828,
              57.684438
            ],
            [
              -132.849241,
              57.672511
            ],
            [
              -132.841532,
              57.66067
            ],
            [
              -132.854384,
              57.653237
            ],
            [
              -132.873386,
              57.644613
            ],
            [
              -132.88123,
              57.639456
            ],
            [
              -132.878961,
              57.635783
            ],
            [
              -132.878491,
              57.628567
            ],
            [
              -132.883445,
              57.617975
            ],
            [
              -132.888253,
              57.612788
            ],
            [
              -132.889003,
              57.609348
            ],
            [
              -132.883649,
              57.608088
            ],
            [
              -132.875548,
              57.608075
            ],
            [
              -132.864778,
              57.607268
            ],
            [
              -132.855584,
              57.604694
            ],
            [
              -132.85515,
              57.599542
            ],
            [
              -132.844793,
              57.593989
            ],
            [
              -132.830228,
              57.588861
            ],
            [
              -132.828756,
              57.582576
            ],
            [
              -132.824648,
              57.579751
            ],
            [
              -132.801185,
              57.576287
            ],
            [
              -132.767736,
              57.582659
            ],
            [
              -132.729846,
              57.579981
            ],
            [
              -132.721833,
              57.576216
            ],
            [
              -132.711462,
              57.578012
            ],
            [
              -132.701557,
              57.578089
            ],
            [
              -132.695748,
              57.579398
            ],
            [
              -132.687151,
              57.580075
            ],
            [
              -132.676905,
              57.581234
            ],
            [
              -132.669381,
              57.581969
            ],
            [
              -132.660197,
              57.583535
            ],
            [
              -132.652187,
              57.586463
            ],
            [
              -132.649796,
              57.594813
            ],
            [
              -132.655135,
              57.604887
            ],
            [
              -132.649734176785,
              57.609687528299695
            ],
            [
              -132.658124,
              57.619486
            ],
            [
              -132.756813,
              57.705093
            ],
            [
              -132.869318,
              57.842941
            ],
            [
              -133.076421,
              57.999762
            ],
            [
              -133.14876,
              58.109536
            ],
            [
              -133.176444,
              58.150151
            ],
            [
              -133.17694973290202,
              58.1505582487793
            ],
            [
              -133.598542053296,
              57.8602826614518
            ]
          ]
        ],
        [
          [
            [
              -133.694617159264,
              57.794125222566194
            ],
            [
              -133.677433,
              57.786593
            ],
            [
              -133.658113,
              57.786368
            ],
            [
              -133.639675,
              57.790361
            ],
            [
              -133.637054,
              57.792203
            ],
            [
              -133.635578,
              57.7999
            ],
            [
              -133.638899,
              57.803323
            ],
            [
              -133.641996,
              57.811215
            ],
            [
              -133.634733147408,
              57.8353617943987
            ],
            [
              -133.694617159264,
              57.794125222566194
            ]
          ]
        ],
        [
          [
            [
              -133.882693,
              57.300651
            ],
            [
              -133.875673,
              57.267613
            ],
            [
              -133.840895,
              57.271074
            ],
            [
              -133.814613,
              57.280651
            ],
            [
              -133.778034,
              57.294357
            ],
            [
              -133.786398,
              57.311528
            ],
            [
              -133.828428,
              57.322007
            ],
            [
              -133.853455,
              57.317258
            ],
            [
              -133.882693,
              57.300651
            ]
          ]
        ],
        [
          [
            [
              -134.960502,
              58.403758
            ],
            [
              -134.969189,
              58.367542
            ],
            [
              -134.948327,
              58.281316
            ],
            [
              -134.914857,
              58.214932
            ],
            [
              -134.899665,
              58.19432
            ],
            [
              -134.885857,
              58.184031
            ],
            [
              -134.877918,
              58.181535
            ],
            [
              -134.864299,
              58.180489
            ],
            [
              -134.857221,
              58.176288
            ],
            [
              -134.820663,
              58.141465
            ],
            [
              -134.784927,
              58.096793
            ],
            [
              -134.784906967405,
              58.096541491202494
            ],
            [
              -134.783772,
              58.082292
            ],
            [
              -134.796804,
              58.058855
            ],
            [
              -134.777022,
              58.004679
            ],
            [
              -134.76529,
              57.993762
            ],
            [
              -134.758833,
              57.980212
            ],
            [
              -134.746108,
              57.898529
            ],
            [
              -134.737475,
              57.89079
            ],
            [
              -134.727077,
              57.877098
            ],
            [
              -134.705869,
              57.828929
            ],
            [
              -134.709024,
              57.780498
            ],
            [
              -134.728792,
              57.75664
            ],
            [
              -134.731798,
              57.721921
            ],
            [
              -134.720351,
              57.707052
            ],
            [
              -134.704859,
              57.701457
            ],
            [
              -134.700518,
              57.695966
            ],
            [
              -134.695428,
              57.685335
            ],
            [
              -134.674438,
              57.614409
            ],
            [
              -134.665337,
              57.605701
            ],
            [
              -134.611177,
              57.563137
            ],
            [
              -134.595981,
              57.534107
            ],
            [
              -134.598452,
              57.522395
            ],
            [
              -134.607557,
              57.513042
            ],
            [
              -134.544853,
              57.457872
            ],
            [
              -134.464032,
              57.392184
            ],
            [
              -134.47724,
              57.374401
            ],
            [
              -134.486023,
              57.372492
            ],
            [
              -134.527873,
              57.389874
            ],
            [
              -134.527759,
              57.39394
            ],
            [
              -134.525997,
              57.397845
            ],
            [
              -134.527594,
              57.405331
            ],
            [
              -134.55554,
              57.407428
            ],
            [
              -134.578511,
              57.400291
            ],
            [
              -134.575492,
              57.343694
            ],
            [
              -134.574114,
              57.341172
            ],
            [
              -134.559794,
              57.336138
            ],
            [
              -134.543385,
              57.337414
            ],
            [
              -134.517279,
              57.314567
            ],
            [
              -134.570954,
              57.294624
            ],
            [
              -134.605032,
              57.273
            ],
            [
              -134.640169,
              57.239852
            ],
            [
              -134.646773,
              57.226327
            ],
            [
              -134.634565,
              57.109863
            ],
            [
              -134.601407,
              57.033812
            ],
            [
              -134.565687,
              57.023737
            ],
            [
              -134.497718,
              57.031194
            ],
            [
              -134.484288,
              57.036481
            ],
            [
              -134.481167,
              57.046006
            ],
            [
              -134.386052,
              57.087392
            ],
            [
              -134.370797,
              57.099924
            ],
            [
              -134.378359,
              57.115016
            ],
            [
              -134.349602,
              57.124638
            ],
            [
              -134.302721,
              57.136562
            ],
            [
              -134.29276,
              57.137049
            ],
            [
              -134.193629,
              57.184879
            ],
            [
              -134.15539,
              57.208003
            ],
            [
              -134.110315,
              57.249948
            ],
            [
              -134.080495,
              57.297678
            ],
            [
              -134.084374,
              57.303963
            ],
            [
              -134.100587,
              57.321738
            ],
            [
              -134.094891,
              57.326401
            ],
            [
              -134.055618,
              57.330194
            ],
            [
              -134.034563,
              57.327638
            ],
            [
              -134.008394,
              57.317522
            ],
            [
              -133.983501,
              57.302838
            ],
            [
              -133.968495,
              57.303937
            ],
            [
              -133.962897,
              57.305425
            ],
            [
              -133.870657,
              57.358287
            ],
            [
              -133.867279,
              57.36206
            ],
            [
              -133.866931,
              57.367869
            ],
            [
              -133.870327,
              57.381298
            ],
            [
              -133.925527,
              57.45665
            ],
            [
              -133.927539,
              57.46957
            ],
            [
              -133.857368,
              57.463964
            ],
            [
              -133.886269,
              57.504999
            ],
            [
              -133.901074,
              57.517219
            ],
            [
              -133.920557,
              57.530088
            ],
            [
              -133.933216,
              57.54445
            ],
            [
              -133.943417,
              57.561555
            ],
            [
              -133.945156,
              57.569841
            ],
            [
              -133.934361,
              57.601765
            ],
            [
              -133.935976,
              57.614414
            ],
            [
              -133.958454,
              57.629537
            ],
            [
              -133.993974,
              57.649095
            ],
            [
              -134.013367,
              57.655898
            ],
            [
              -134.020169,
              57.656734
            ],
            [
              -134.116097,
              57.727582
            ],
            [
              -134.1277,
              57.739216
            ],
            [
              -134.146342,
              57.760258
            ],
            [
              -134.100899,
              57.776779
            ],
            [
              -134.099782,
              57.780261
            ],
            [
              -134.105408,
              57.795974
            ],
            [
              -134.112948,
              57.809263
            ],
            [
              -134.16312,
              57.848669
            ],
            [
              -134.206874,
              57.895901
            ],
            [
              -134.202353,
              57.90633
            ],
            [
              -134.176606,
              57.909725
            ],
            [
              -134.151127,
              57.903209
            ],
            [
              -134.132146,
              57.896189
            ],
            [
              -134.126105,
              57.89026
            ],
            [
              -134.121904,
              57.88252
            ],
            [
              -134.11921,
              57.872917
            ],
            [
              -134.121337,
              57.871236
            ],
            [
              -134.098628,
              57.85055
            ],
            [
              -134.061833,
              57.829808
            ],
            [
              -134.03082,
              57.818646
            ],
            [
              -134.013144,
              57.789393
            ],
            [
              -134.010728,
              57.759392
            ],
            [
              -133.994964,
              57.719821
            ],
            [
              -133.970087,
              57.695342
            ],
            [
              -133.962582,
              57.689887
            ],
            [
              -133.911329,
              57.663562
            ],
            [
              -133.863112,
              57.623701
            ],
            [
              -133.85801,
              57.61694
            ],
            [
              -133.859635,
              57.605325
            ],
            [
              -133.840838,
              57.576865
            ],
            [
              -133.8176,
              57.568353
            ],
            [
              -133.81164,
              57.572365
            ],
            [
              -133.807133,
              57.57877
            ],
            [
              -133.806003,
              57.583457
            ],
            [
              -133.808285,
              57.609604
            ],
            [
              -133.817662,
              57.629764
            ],
            [
              -133.821673,
              57.633887
            ],
            [
              -133.832895,
              57.635733
            ],
            [
              -133.837424,
              57.638486
            ],
            [
              -133.868134,
              57.660137
            ],
            [
              -133.896846,
              57.685524
            ],
            [
              -133.90989,
              57.780628
            ],
            [
              -133.908085,
              57.79167
            ],
            [
              -133.903854,
              57.794818
            ],
            [
              -133.902695,
              57.797797
            ],
            [
              -133.904874,
              57.807406
            ],
            [
              -133.934735,
              57.837626
            ],
            [
              -133.963791,
              57.854628
            ],
            [
              -133.995977,
              57.895632
            ],
            [
              -133.99634,
              57.904167
            ],
            [
              -133.999948,
              57.91481
            ],
            [
              -134.016873,
              57.930006
            ],
            [
              -134.068949,
              57.961083
            ],
            [
              -134.089575,
              57.974357
            ],
            [
              -134.101125,
              57.98407
            ],
            [
              -134.101549,
              57.988716
            ],
            [
              -134.087572,
              57.996475
            ],
            [
              -134.087461,
              58.001685
            ],
            [
              -134.091885,
              58.010777
            ],
            [
              -134.098652,
              58.018748
            ],
            [
              -134.138231,
              58.047103
            ],
            [
              -134.169743,
              58.066845
            ],
            [
              -134.183983,
              58.077295
            ],
            [
              -134.18937,
              58.083444
            ],
            [
              -134.192724,
              58.107685
            ],
            [
              -134.174352,
              58.125284
            ],
            [
              -134.167257,
              58.128577
            ],
            [
              -134.166332,
              58.132558
            ],
            [
              -134.17688888870802,
              58.158233946113796
            ],
            [
              -134.417764,
              58.114875
            ],
            [
              -134.427727,
              58.10481
            ],
            [
              -134.471982,
              58.094322
            ],
            [
              -134.510551,
              58.092777
            ],
            [
              -134.538413,
              58.098172
            ],
            [
              -134.56728,
              58.078576
            ],
            [
              -134.551336,
              58.063912
            ],
            [
              -134.577773,
              58.054116
            ],
            [
              -134.588093,
              58.059695
            ],
            [
              -134.628462,
              58.060992
            ],
            [
              -134.65318,
              58.040482
            ],
            [
              -134.67543,
              58.029471
            ],
            [
              -134.697766,
              58.032036
            ],
            [
              -134.725767,
              58.058687
            ],
            [
              -134.784681,
              58.096599
            ],
            [
              -134.769438,
              58.122903
            ],
            [
              -134.772728,
              58.166496
            ],
            [
              -134.71463926772003,
              58.22370882751879
            ],
            [
              -134.724463,
              58.268277
            ],
            [
              -134.729861,
              58.273512
            ],
            [
              -134.760052,
              58.275251
            ],
            [
              -134.779354,
              58.281279
            ],
            [
              -134.802388,
              58.30107
            ],
            [
              -134.803831,
              58.316567
            ],
            [
              -134.806116,
              58.321284
            ],
            [
              -134.897292,
              58.37689
            ],
            [
              -134.955902,
              58.410297
            ],
            [
              -134.960502,
              58.403758
            ]
          ]
        ],
        [
          [
            [
              -135.727908,
              58.365444
            ],
            [
              -135.696499,
              58.335939
            ],
            [
              -135.649861,
              58.324517
            ],
            [
              -135.544213,
              58.330228
            ],
            [
              -135.538502,
              58.337842
            ],
            [
              -135.602272,
              58.374962
            ],
            [
              -135.631777,
              58.380673
            ],
            [
              -135.669849,
              58.380673
            ],
            [
              -135.727908,
              58.365444
            ]
          ]
        ],
        [
          [
            [
              -135.919904,
              59.41062
            ],
            [
              -135.92734,
              59.403902
            ],
            [
              -135.900985,
              59.395905
            ],
            [
              -135.855631,
              59.385612
            ],
            [
              -135.853867,
              59.395937
            ],
            [
              -135.919904,
              59.41062
            ]
          ]
        ],
        [
          [
            [
              -136.153593,
              58.596966
            ],
            [
              -136.149786,
              58.591255
            ],
            [
              -136.096486,
              58.558894
            ],
            [
              -136.088872,
              58.565557
            ],
            [
              -136.089824,
              58.575075
            ],
            [
              -136.100293,
              58.596014
            ],
            [
              -136.123136,
              58.609339
            ],
            [
              -136.145979,
              58.607435
            ],
            [
              -136.153593,
              58.596966
            ]
          ]
        ],
        [
          [
            [
              -136.156151,
              58.283888
            ],
            [
              -136.157341,
              58.279129
            ],
            [
              -136.125218,
              58.261878
            ],
            [
              -136.094285,
              58.257119
            ],
            [
              -136.035393,
              58.27318
            ],
            [
              -136.033014,
              58.311252
            ],
            [
              -136.038368,
              58.318985
            ],
            [
              -136.047886,
              58.318985
            ],
            [
              -136.118674,
              58.303519
            ],
            [
              -136.156151,
              58.283888
            ]
          ]
        ],
        [
          [
            [
              -136.573288,
              57.926844
            ],
            [
              -136.572045,
              57.918469
            ],
            [
              -136.557651,
              57.912135
            ],
            [
              -136.534201,
              57.913938
            ],
            [
              -136.484259,
              57.89646
            ],
            [
              -136.474424,
              57.871648
            ],
            [
              -136.458829,
              57.853901
            ],
            [
              -136.391157,
              57.832653
            ],
            [
              -136.372377,
              57.832587
            ],
            [
              -136.37173959985802,
              57.832236669219
            ],
            [
              -136.024328,
              57.838795
            ],
            [
              -135.9799,
              57.8903
            ],
            [
              -135.901964,
              58.001343
            ],
            [
              -135.878746,
              57.997419
            ],
            [
              -135.81891,
              57.974144
            ],
            [
              -135.792389,
              57.957771
            ],
            [
              -135.715149,
              57.929413
            ],
            [
              -135.648499,
              57.91333
            ],
            [
              -135.608032,
              57.900673
            ],
            [
              -135.528534,
              57.88047
            ],
            [
              -135.426727,
              57.843994
            ],
            [
              -135.379425,
              57.823044
            ],
            [
              -135.351074,
              57.80545
            ],
            [
              -135.275879,
              57.773285
            ],
            [
              -135.217987,
              57.758789
            ],
            [
              -135.176422,
              57.757683
            ],
            [
              -135.122558,
              57.762558
            ],
            [
              -135.10802618195402,
              57.7684239812522
            ],
            [
              -135.115011,
              57.778261
            ],
            [
              -135.088509,
              57.785294
            ],
            [
              -135.068425,
              57.774586
            ],
            [
              -135.02337,
              57.780537
            ],
            [
              -134.949436,
              57.805027
            ],
            [
              -134.954547,
              57.815785
            ],
            [
              -134.991819,
              57.835436
            ],
            [
              -135.06572,
              57.869451
            ],
            [
              -135.131957,
              57.885241
            ],
            [
              -135.146717,
              57.891656
            ],
            [
              -135.16522,
              57.901524
            ],
            [
              -135.173712,
              57.919399
            ],
            [
              -135.140674,
              57.926114
            ],
            [
              -135.004952,
              57.884338
            ],
            [
              -134.926395,
              57.921919
            ],
            [
              -134.921104,
              57.935298
            ],
            [
              -134.912854,
              57.979287
            ],
            [
              -134.935005,
              58.021639
            ],
            [
              -134.950844,
              58.036993
            ],
            [
              -134.967723,
              58.047625
            ],
            [
              -134.977183,
              58.049943
            ],
            [
              -135.0707,
              58.061242
            ],
            [
              -135.082981,
              58.074737
            ],
            [
              -135.084832,
              58.080869
            ],
            [
              -135.108896,
              58.08827
            ],
            [
              -135.260951,
              58.097323
            ],
            [
              -135.275797,
              58.097024
            ],
            [
              -135.397518,
              58.144155
            ],
            [
              -135.411777,
              58.145473
            ],
            [
              -135.420107,
              58.144202
            ],
            [
              -135.451444,
              58.134348
            ],
            [
              -135.496739,
              58.086212
            ],
            [
              -135.544529,
              58.06088
            ],
            [
              -135.563434,
              58.043491
            ],
            [
              -135.565443,
              58.04112
            ],
            [
              -135.567817,
              58.02342
            ],
            [
              -135.564307,
              58.015007
            ],
            [
              -135.581753,
              57.997568
            ],
            [
              -135.593287,
              57.989636
            ],
            [
              -135.621582,
              57.984623
            ],
            [
              -135.63849,
              57.994508
            ],
            [
              -135.651368,
              58.036484
            ],
            [
              -135.58682,
              58.08167
            ],
            [
              -135.540712,
              58.10175
            ],
            [
              -135.524668,
              58.12075
            ],
            [
              -135.497911,
              58.168882
            ],
            [
              -135.504671,
              58.174914
            ],
            [
              -135.522646,
              58.185909
            ],
            [
              -135.589198,
              58.213677
            ],
            [
              -135.630521,
              58.222933
            ],
            [
              -135.712398,
              58.231892
            ],
            [
              -135.7351,
              58.240213
            ],
            [
              -135.764372,
              58.266276
            ],
            [
              -135.78338,
              58.286709
            ],
            [
              -135.801133,
              58.287716
            ],
            [
              -135.823562,
              58.282975
            ],
            [
              -135.914178,
              58.244073
            ],
            [
              -135.966119,
              58.211386
            ],
            [
              -135.976386,
              58.202029
            ],
            [
              -136.03303,
              58.205462
            ],
            [
              -136.03616,
              58.21921
            ],
            [
              -136.139007,
              58.224393
            ],
            [
              -136.162108,
              58.218724
            ],
            [
              -136.199854,
              58.180871
            ],
            [
              -136.239246,
              58.171913
            ],
            [
              -136.283886,
              58.223685
            ],
            [
              -136.287021,
              58.245817
            ],
            [
              -136.284047,
              58.269611
            ],
            [
              -136.317954,
              58.273775
            ],
            [
              -136.400046,
              58.271991
            ],
            [
              -136.404805,
              58.267232
            ],
            [
              -136.387113,
              58.252414
            ],
            [
              -136.373277,
              58.232135
            ],
            [
              -136.36138,
              58.214289
            ],
            [
              -136.354836,
              58.192279
            ],
            [
              -136.365544,
              58.148854
            ],
            [
              -136.420449,
              58.131857
            ],
            [
              -136.446286,
              58.11334
            ],
            [
              -136.469272,
              58.096868
            ],
            [
              -136.475811,
              58.101294
            ],
            [
              -136.500119,
              58.104787
            ],
            [
              -136.538708,
              58.093482
            ],
            [
              -136.556247,
              58.077019
            ],
            [
              -136.559999,
              58.063358
            ],
            [
              -136.563223,
              58.035052
            ],
            [
              -136.573288,
              57.926844
            ]
          ]
        ],
        [
          [
            [
              -137.94213493483102,
              58.8092189716311
            ],
            [
              -137.944957,
              58.804652
            ],
            [
              -137.941828,
              58.794322
            ],
            [
              -137.928156,
              58.780533
            ],
            [
              -137.901675,
              58.765316
            ],
            [
              -137.87535,
              58.757232
            ],
            [
              -137.795037,
              58.724855
            ],
            [
              -137.687627,
              58.664989
            ],
            [
              -137.683516,
              58.660267
            ],
            [
              -137.676857,
              58.64677
            ],
            [
              -137.680811,
              58.621835
            ],
            [
              -137.67169,
              58.615523
            ],
            [
              -137.632889,
              58.599982
            ],
            [
              -137.608804,
              58.601234
            ],
            [
              -137.568216,
              58.587989
            ],
            [
              -137.497002,
              58.557721
            ],
            [
              -137.408758,
              58.515822
            ],
            [
              -137.295788,
              58.466179
            ],
            [
              -137.278612,
              58.459484
            ],
            [
              -137.25271,
              58.456338
            ],
            [
              -137.239366,
              58.453159
            ],
            [
              -137.180029,
              58.429939
            ],
            [
              -137.134453,
              58.406596
            ],
            [
              -137.111802,
              58.392594
            ],
            [
              -137.078109,
              58.397474
            ],
            [
              -137.018409,
              58.409141
            ],
            [
              -137.009415,
              58.408877
            ],
            [
              -136.986384,
              58.404043
            ],
            [
              -136.946663,
              58.393185
            ],
            [
              -136.848992,
              58.328994
            ],
            [
              -136.857605,
              58.31636
            ],
            [
              -136.784326,
              58.290497
            ],
            [
              -136.762198,
              58.286765
            ],
            [
              -136.730218,
              58.286153
            ],
            [
              -136.717093,
              58.273508
            ],
            [
              -136.730885,
              58.256496
            ],
            [
              -136.723391,
              58.244926
            ],
            [
              -136.70125,
              58.219416
            ],
            [
              -136.658638,
              58.207323
            ],
            [
              -136.619824,
              58.209899
            ],
            [
              -136.597198,
              58.215006
            ],
            [
              -136.591924,
              58.217886
            ],
            [
              -136.567956,
              58.245153
            ],
            [
              -136.569831,
              58.2687
            ],
            [
              -136.576799,
              58.277951
            ],
            [
              -136.585703,
              58.296678
            ],
            [
              -136.570475,
              58.310954
            ],
            [
              -136.544776,
              58.316665
            ],
            [
              -136.514319,
              58.310003
            ],
            [
              -136.47202,
              58.306356
            ],
            [
              -136.389964,
              58.29707
            ],
            [
              -136.376464,
              58.298625
            ],
            [
              -136.370979,
              58.301643
            ],
            [
              -136.357115,
              58.328838
            ],
            [
              -136.360416,
              58.344077
            ],
            [
              -136.365148,
              58.346663
            ],
            [
              -136.382035,
              58.362694
            ],
            [
              -136.336728,
              58.37757
            ],
            [
              -136.288867,
              58.369649
            ],
            [
              -136.282604,
              58.367261
            ],
            [
              -136.273929,
              58.363409
            ],
            [
              -136.281631,
              58.35309
            ],
            [
              -136.290055,
              58.351447
            ],
            [
              -136.298718,
              58.342941
            ],
            [
              -136.303092,
              58.336277
            ],
            [
              -136.305121,
              58.328691
            ],
            [
              -136.304158,
              58.32345
            ],
            [
              -136.296281,
              58.318447
            ],
            [
              -136.288255,
              58.316144
            ],
            [
              -136.276769,
              58.313894
            ],
            [
              -136.265906,
              58.314499
            ],
            [
              -136.11193,
              58.34253
            ],
            [
              -136.092646,
              58.34899
            ],
            [
              -136.041818,
              58.380161
            ],
            [
              -136.053028,
              58.417375
            ],
            [
              -136.062165,
              58.435795
            ],
            [
              -136.072691,
              58.456577
            ],
            [
              -136.074595,
              58.469902
            ],
            [
              -136.100303,
              58.500673
            ],
            [
              -136.100293,
              58.514636
            ],
            [
              -136.132654,
              58.531768
            ],
            [
              -136.145027,
              58.554611
            ],
            [
              -136.194207,
              58.581731
            ],
            [
              -136.217601,
              58.672394
            ],
            [
              -136.229974,
              58.67525
            ],
            [
              -136.246368,
              58.663185
            ],
            [
              -136.317193,
              58.671231
            ],
            [
              -136.331366,
              58.663545
            ],
            [
              -136.342827,
              58.64503
            ],
            [
              -136.383327,
              58.629987
            ],
            [
              -136.459436,
              58.60788
            ],
            [
              -136.482395,
              58.616739
            ],
            [
              -136.449827,
              58.637816
            ],
            [
              -136.422309,
              58.647412
            ],
            [
              -136.409876,
              58.64925
            ],
            [
              -136.396076,
              58.654421
            ],
            [
              -136.372775,
              58.66741
            ],
            [
              -136.354222,
              58.684304
            ],
            [
              -136.356786,
              58.692581
            ],
            [
              -136.463258,
              58.781607
            ],
            [
              -136.538029,
              58.819777
            ],
            [
              -136.58343,
              58.838826
            ],
            [
              -136.612807,
              58.846227
            ],
            [
              -136.676388,
              58.856348
            ],
            [
              -136.744507,
              58.876626
            ],
            [
              -136.76793,
              58.870608
            ],
            [
              -136.868184,
              58.885243
            ],
            [
              -136.928643,
              58.900131
            ],
            [
              -136.933458,
              58.908558
            ],
            [
              -136.934841,
              58.916345
            ],
            [
              -136.932352,
              58.916252
            ],
            [
              -136.915995,
              58.938384
            ],
            [
              -136.91853,
              58.947217
            ],
            [
              -136.877826,
              58.962392
            ],
            [
              -136.857823,
              58.942868
            ],
            [
              -136.860014,
              58.931997
            ],
            [
              -136.840986,
              58.919742
            ],
            [
              -136.802832,
              58.923118
            ],
            [
              -136.78871,
              58.936318
            ],
            [
              -136.782908,
              58.936659
            ],
            [
              -136.750422,
              58.930439
            ],
            [
              -136.724994,
              58.923514
            ],
            [
              -136.704848,
              58.914395
            ],
            [
              -136.6946,
              58.904081
            ],
            [
              -136.676898,
              58.894973
            ],
            [
              -136.670412,
              58.893224
            ],
            [
              -136.630497,
              58.890256
            ],
            [
              -136.586289,
              58.909364
            ],
            [
              -136.575541,
              58.928941
            ],
            [
              -136.575516,
              58.9466
            ],
            [
              -136.572163,
              58.957292
            ],
            [
              -136.559836,
              58.963414
            ],
            [
              -136.544899,
              58.967314
            ],
            [
              -136.52652,
              58.954523
            ],
            [
              -136.528161,
              58.928484
            ],
            [
              -136.493716,
              58.838963
            ],
            [
              -136.474735,
              58.830788
            ],
            [
              -136.431055,
              58.818416
            ],
            [
              -136.397322,
              58.813019
            ],
            [
              -136.247343,
              58.752935
            ],
            [
              -136.21366,
              58.751153
            ],
            [
              -136.161943,
              58.752171
            ],
            [
              -136.150772,
              58.757266
            ],
            [
              -136.106997,
              58.864441
            ],
            [
              -136.124491,
              58.924542
            ],
            [
              -136.1503,
              58.947111
            ],
            [
              -136.160293,
              58.961999
            ],
            [
              -136.163648,
              58.973204
            ],
            [
              -136.162725,
              58.977261
            ],
            [
              -136.145306,
              58.976705
            ],
            [
              -136.120307,
              58.968418
            ],
            [
              -136.060728,
              58.92758
            ],
            [
              -136.050351,
              58.913433
            ],
            [
              -136.045279,
              58.836074
            ],
            [
              -136.077276,
              58.824983
            ],
            [
              -136.089603,
              58.815729
            ],
            [
              -136.082937,
              58.808383
            ],
            [
              -136.046172,
              58.781796
            ],
            [
              -136.011669,
              58.743276
            ],
            [
              -136.008929,
              58.73191
            ],
            [
              -136.015761,
              58.7226
            ],
            [
              -136.012226,
              58.712247
            ],
            [
              -135.912187,
              58.6188
            ],
            [
              -135.928572,
              58.572925
            ],
            [
              -135.914003,
              58.540583
            ],
            [
              -135.895088,
              58.534077
            ],
            [
              -135.893152,
              58.513929
            ],
            [
              -135.906941,
              58.50581
            ],
            [
              -135.945121,
              58.494836
            ],
            [
              -135.955625,
              58.492765
            ],
            [
              -135.968087,
              58.494669
            ],
            [
              -135.990948,
              58.487315
            ],
            [
              -135.99953,
              58.480281
            ],
            [
              -135.997418,
              58.470375
            ],
            [
              -135.987564,
              58.46442
            ],
            [
              -135.939926,
              58.4516
            ],
            [
              -135.934547,
              58.451953
            ],
            [
              -135.923268,
              58.462919
            ],
            [
              -135.916112,
              58.463858
            ],
            [
              -135.897169,
              58.450001
            ],
            [
              -135.897255,
              58.416132
            ],
            [
              -135.920299,
              58.389084
            ],
            [
              -135.921134,
              58.385772
            ],
            [
              -135.917917,
              58.381237
            ],
            [
              -135.90731,
              58.380839
            ],
            [
              -135.826079,
              58.390246
            ],
            [
              -135.728054,
              58.397067
            ],
            [
              -135.630425,
              58.42858
            ],
            [
              -135.622105,
              58.428186
            ],
            [
              -135.556066,
              58.40774
            ],
            [
              -135.521358,
              58.391449
            ],
            [
              -135.512402,
              58.385759
            ],
            [
              -135.466083,
              58.394328
            ],
            [
              -135.461296,
              58.399884
            ],
            [
              -135.446078452451,
              58.3998920844063
            ],
            [
              -135.448398,
              58.408075
            ],
            [
              -135.46835,
              58.433278
            ],
            [
              -135.472464,
              58.470098
            ],
            [
              -135.493614,
              58.48705
            ],
            [
              -135.494521,
              58.499654
            ],
            [
              -135.471786,
              58.509256
            ],
            [
              -135.454391,
              58.509798
            ],
            [
              -135.432433,
              58.513146
            ],
            [
              -135.418417,
              58.511318
            ],
            [
              -135.401518,
              58.503946
            ],
            [
              -135.374788,
              58.49992
            ],
            [
              -135.353083,
              58.49173
            ],
            [
              -135.33105,
              58.495073
            ],
            [
              -135.318354,
              58.507013
            ],
            [
              -135.331589,
              58.521933
            ],
            [
              -135.359683,
              58.534181
            ],
            [
              -135.367851,
              58.546745
            ],
            [
              -135.370697,
              58.560177
            ],
            [
              -135.38025,
              58.570236
            ],
            [
              -135.396123,
              58.579242
            ],
            [
              -135.407538,
              58.581346
            ],
            [
              -135.409037,
              58.593795
            ],
            [
              -135.396416,
              58.601768
            ],
            [
              -135.404569,
              58.618484
            ],
            [
              -135.418482,
              58.626427
            ],
            [
              -135.435786,
              58.635996
            ],
            [
              -135.460358,
              58.638154
            ],
            [
              -135.47856,
              58.6477
            ],
            [
              -135.491766,
              58.65461
            ],
            [
              -135.506831,
              58.654941
            ],
            [
              -135.528727,
              58.657117
            ],
            [
              -135.54042,
              58.667839
            ],
            [
              -135.542184,
              58.675971
            ],
            [
              -135.541848,
              58.687192
            ],
            [
              -135.546955,
              58.695242
            ],
            [
              -135.560224,
              58.699305
            ],
            [
              -135.567112,
              58.698794
            ],
            [
              -135.579128,
              58.70267
            ],
            [
              -135.587581,
              58.709267
            ],
            [
              -135.606185,
              58.708756
            ],
            [
              -135.613285,
              58.707862
            ],
            [
              -135.625532,
              58.711239
            ],
            [
              -135.635028,
              58.718987
            ],
            [
              -135.651213,
              58.720016
            ],
            [
              -135.662061,
              58.716001
            ],
            [
              -135.676753,
              58.717552
            ],
            [
              -135.692405,
              58.726283
            ],
            [
              -135.694934,
              58.732467
            ],
            [
              -135.72144,
              58.730467
            ],
            [
              -135.729528,
              58.725792
            ],
            [
              -135.745237,
              58.729628
            ],
            [
              -135.745092,
              58.734159
            ],
            [
              -135.754556,
              58.747101
            ],
            [
              -135.746712,
              58.758478
            ],
            [
              -135.737079,
              58.762912
            ],
            [
              -135.730379,
              58.766732
            ],
            [
              -135.7334,
              58.769849
            ],
            [
              -135.730553,
              58.779683
            ],
            [
              -135.729926,
              58.787689
            ],
            [
              -135.711907,
              58.800266
            ],
            [
              -135.710805,
              58.812493
            ],
            [
              -135.708672,
              58.825609
            ],
            [
              -135.684662,
              58.836835
            ],
            [
              -135.679899,
              58.848791
            ],
            [
              -135.660283,
              58.852532
            ],
            [
              -135.634267,
              58.855528
            ],
            [
              -135.609696,
              58.855666
            ],
            [
              -135.583068,
              58.861815
            ],
            [
              -135.551602,
              58.875287
            ],
            [
              -135.542156,
              58.874046
            ],
            [
              -135.529486,
              58.883512
            ],
            [
              -135.532252,
              58.889194
            ],
            [
              -135.548706,
              58.898721
            ],
            [
              -135.57008,
              58.90138
            ],
            [
              -135.585982,
              58.898159
            ],
            [
              -135.600335,
              58.903091
            ],
            [
              -135.60563,
              58.908513
            ],
            [
              -135.608028,
              58.922384
            ],
            [
              -135.59218,
              58.933539
            ],
            [
              -135.58382,
              58.935925
            ],
            [
              -135.565987,
              58.948586
            ],
            [
              -135.566059,
              58.962806
            ],
            [
              -135.588414,
              58.975479
            ],
            [
              -135.604707,
              58.983681
            ],
            [
              -135.627216,
              58.989067
            ],
            [
              -135.647387,
              59.002292
            ],
            [
              -135.664361,
              59.01085
            ],
            [
              -135.682743,
              59.020186
            ],
            [
              -135.694859,
              59.027459
            ],
            [
              -135.706489,
              59.043108
            ],
            [
              -135.720814,
              59.072794
            ],
            [
              -135.716655,
              59.083381
            ],
            [
              -135.709508,
              59.097419
            ],
            [
              -135.704535,
              59.103067
            ],
            [
              -135.708619,
              59.109988
            ],
            [
              -135.722933,
              59.113843
            ],
            [
              -135.745171,
              59.123131
            ],
            [
              -135.762293,
              59.133649
            ],
            [
              -135.782924,
              59.140355
            ],
            [
              -135.80255,
              59.140131
            ],
            [
              -135.829597,
              59.13983
            ],
            [
              -135.841385,
              59.149377
            ],
            [
              -135.8576,
              59.155106
            ],
            [
              -135.8727,
              59.158791
            ],
            [
              -135.882359,
              59.163546
            ],
            [
              -135.883513,
              59.169312
            ],
            [
              -135.895563,
              59.176272
            ],
            [
              -135.916765,
              59.179618
            ],
            [
              -135.940824,
              59.180166
            ],
            [
              -135.977049,
              59.181252
            ],
            [
              -135.995886,
              59.182078
            ],
            [
              -136.004565,
              59.184561
            ],
            [
              -136.016161,
              59.181746
            ],
            [
              -136.026896,
              59.176878
            ],
            [
              -136.044643,
              59.17745
            ],
            [
              -136.05576,
              59.186797
            ],
            [
              -136.057712,
              59.193568
            ],
            [
              -136.062547,
              59.19835
            ],
            [
              -136.073366,
              59.201407
            ],
            [
              -136.087246,
              59.199295
            ],
            [
              -136.101026,
              59.204481
            ],
            [
              -136.09797,
              59.207647
            ],
            [
              -136.101472,
              59.212037
            ],
            [
              -136.105043,
              59.214338
            ],
            [
              -136.113427,
              59.210529
            ],
            [
              -136.121216,
              59.205245
            ],
            [
              -136.131481,
              59.192023
            ],
            [
              -136.145206,
              59.185938
            ],
            [
              -136.158934,
              59.186974
            ],
            [
              -136.163514,
              59.193909
            ],
            [
              -136.186579,
              59.207737
            ],
            [
              -136.187778,
              59.215195
            ],
            [
              -136.204769,
              59.216931
            ],
            [
              -136.217151,
              59.21392
            ],
            [
              -136.239426,
              59.214795
            ],
            [
              -136.260615,
              59.226138
            ],
            [
              -136.280863,
              59.225715
            ],
            [
              -136.30149,
              59.230098
            ],
            [
              -136.32589,
              59.230661
            ],
            [
              -136.350621,
              59.231202
            ],
            [
              -136.367508,
              59.24079
            ],
            [
              -136.365558,
              59.24476
            ],
            [
              -136.373328,
              59.249179
            ],
            [
              -136.380126,
              59.249456
            ],
            [
              -136.387327,
              59.251061
            ],
            [
              -136.390809,
              59.247547
            ],
            [
              -136.407713,
              59.243928
            ],
            [
              -136.427786,
              59.247734
            ],
            [
              -136.443428,
              59.254697
            ],
            [
              -136.446663,
              59.261193
            ],
            [
              -136.459834,
              59.263813
            ],
            [
              -136.464302,
              59.260733
            ],
            [
              -136.476171,
              59.25715
            ],
            [
              -136.48840234349203,
              59.2592903388969
            ],
            [
              -136.581521,
              59.164909
            ],
            [
              -136.826633,
              59.158389
            ],
            [
              -136.863896,
              59.138472
            ],
            [
              -137.264752,
              59.002352
            ],
            [
              -137.447383,
              58.909513
            ],
            [
              -137.526424,
              58.906834
            ],
            [
              -137.525677105313,
              58.908974494140104
            ],
            [
              -137.94213493483102,
              58.8092189716311
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "02",
      "COUNTYFP": "195",
      "COUNTYNS": "02516404",
      "AFFGEOID": "0500000US02195",
      "GEOID": "02195",
      "NAME": "Petersburg",
      "LSAD": "04",
      "ALAND": 7513010884,
      "AWATER": 2385501229,
      "County_state": "Petersburg,02"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -133.574916,
              56.761181
            ],
            [
              -133.576473,
              56.755741
            ],
            [
              -133.570566,
              56.754222
            ],
            [
              -133.568549,
              56.750108
            ],
            [
              -133.564332,
              56.745218
            ],
            [
              -133.551456,
              56.739066
            ],
            [
              -133.540215,
              56.731499
            ],
            [
              -133.528816,
              56.725601
            ],
            [
              -133.517051,
              56.721187
            ],
            [
              -133.500683,
              56.712104
            ],
            [
              -133.48324,
              56.711766
            ],
            [
              -133.476249,
              56.712484
            ],
            [
              -133.47299,
              56.709675
            ],
            [
              -133.467011,
              56.705675
            ],
            [
              -133.459222,
              56.707093
            ],
            [
              -133.451478,
              56.706352
            ],
            [
              -133.450333,
              56.701425
            ],
            [
              -133.460453,
              56.696971
            ],
            [
              -133.478433,
              56.697559
            ],
            [
              -133.493185,
              56.69721
            ],
            [
              -133.504616,
              56.695468
            ],
            [
              -133.509923,
              56.687962
            ],
            [
              -133.504788,
              56.682425
            ],
            [
              -133.492422,
              56.677773
            ],
            [
              -133.48512,
              56.669227
            ],
            [
              -133.486733,
              56.661026
            ],
            [
              -133.484758,
              56.653522
            ],
            [
              -133.474873,
              56.644291
            ],
            [
              -133.460859,
              56.639503
            ],
            [
              -133.449727,
              56.637311
            ],
            [
              -133.437812,
              56.634781
            ],
            [
              -133.432164,
              56.62592
            ],
            [
              -133.427584,
              56.614796
            ],
            [
              -133.426224,
              56.606889
            ],
            [
              -133.425564,
              56.596135
            ],
            [
              -133.424998,
              56.590198
            ],
            [
              -133.432545,
              56.586983
            ],
            [
              -133.439961,
              56.586157
            ],
            [
              -133.448067,
              56.579136
            ],
            [
              -133.447713,
              56.572689
            ],
            [
              -133.442903,
              56.564138
            ],
            [
              -133.441943,
              56.548678
            ],
            [
              -133.438562,
              56.541706
            ],
            [
              -133.435039,
              56.535951
            ],
            [
              -133.425495,
              56.535003
            ],
            [
              -133.414873,
              56.526451
            ],
            [
              -133.406974,
              56.519657
            ],
            [
              -133.406423,
              56.509896
            ],
            [
              -133.414936,
              56.505746
            ],
            [
              -133.424331,
              56.50055
            ],
            [
              -133.41798,
              56.495791
            ],
            [
              -133.41415161645602,
              56.4946498497853
            ],
            [
              -133.401797,
              56.496355
            ],
            [
              -133.376245,
              56.495592
            ],
            [
              -133.371889,
              56.493689
            ],
            [
              -133.361615,
              56.486073
            ],
            [
              -133.3563,
              56.471794
            ],
            [
              -133.348504,
              56.469568
            ],
            [
              -133.203584,
              56.447657
            ],
            [
              -133.160996,
              56.460257
            ],
            [
              -133.14873,
              56.467357
            ],
            [
              -133.122245,
              56.49202
            ],
            [
              -133.124726,
              56.511439
            ],
            [
              -133.139228,
              56.515994
            ],
            [
              -133.142482,
              56.519697
            ],
            [
              -133.142099,
              56.528591
            ],
            [
              -133.138307,
              56.532923
            ],
            [
              -133.112907,
              56.529295
            ],
            [
              -133.089215,
              56.523916
            ],
            [
              -133.041726,
              56.518356
            ],
            [
              -132.986907,
              56.510784
            ],
            [
              -132.984751,
              56.51264
            ],
            [
              -132.874282,
              56.509108
            ],
            [
              -132.818043,
              56.494934
            ],
            [
              -132.796627,
              56.496471
            ],
            [
              -132.724189,
              56.515371
            ],
            [
              -132.701275,
              56.52958
            ],
            [
              -132.65091,
              56.552127
            ],
            [
              -132.628511,
              56.553553
            ],
            [
              -132.627435,
              56.552428
            ],
            [
              -132.61083,
              56.556252
            ],
            [
              -132.541282,
              56.584573
            ],
            [
              -132.539698,
              56.593199
            ],
            [
              -132.546463,
              56.606563
            ],
            [
              -132.553213,
              56.608492
            ],
            [
              -132.56078,
              56.607804
            ],
            [
              -132.57454,
              56.61262
            ],
            [
              -132.595179,
              56.613308
            ],
            [
              -132.605498,
              56.623627
            ],
            [
              -132.616506,
              56.63945
            ],
            [
              -132.62545,
              56.642202
            ],
            [
              -132.628201,
              56.645642
            ],
            [
              -132.62545,
              56.65321
            ],
            [
              -132.619258,
              56.660778
            ],
            [
              -132.622788,
              56.66871
            ],
            [
              -132.628129,
              56.66883
            ],
            [
              -132.665874,
              56.680241
            ],
            [
              -132.743207,
              56.71372
            ],
            [
              -132.741709,
              56.724278
            ],
            [
              -132.762964,
              56.753227
            ],
            [
              -132.820556,
              56.793787
            ],
            [
              -132.85315,
              56.79781
            ],
            [
              -132.872512,
              56.798144
            ],
            [
              -132.903211,
              56.80361
            ],
            [
              -132.935258,
              56.822941
            ],
            [
              -132.935933,
              56.839963
            ],
            [
              -132.947081,
              56.870963
            ],
            [
              -132.98137,
              56.92738
            ],
            [
              -132.99743,
              56.942201
            ],
            [
              -133.104611,
              57.005701
            ],
            [
              -133.308317,
              57.014762
            ],
            [
              -133.325058,
              57.011711
            ],
            [
              -133.325456669767,
              57.011309950502195
            ],
            [
              -133.325513,
              57.004941
            ],
            [
              -133.321851,
              57.004238
            ],
            [
              -133.317785,
              57.002592
            ],
            [
              -133.316324,
              56.998105
            ],
            [
              -133.313877,
              56.994758
            ],
            [
              -133.312301,
              56.992411
            ],
            [
              -133.308615,
              56.98868
            ],
            [
              -133.306169,
              56.98653
            ],
            [
              -133.303144,
              56.98409
            ],
            [
              -133.301832,
              56.981748
            ],
            [
              -133.299493,
              56.978183
            ],
            [
              -133.296105,
              56.977117
            ],
            [
              -133.29498,
              56.974286
            ],
            [
              -133.292937,
              56.971586
            ],
            [
              -133.291475,
              56.96949
            ],
            [
              -133.291862,
              56.966875
            ],
            [
              -133.291382,
              56.964374
            ],
            [
              -133.286658,
              56.959841
            ],
            [
              -133.283479,
              56.957157
            ],
            [
              -133.280694,
              56.955177
            ],
            [
              -133.278519,
              56.953301
            ],
            [
              -133.276606,
              56.950399
            ],
            [
              -133.275327,
              56.947233
            ],
            [
              -133.275033,
              56.945182
            ],
            [
              -133.27304,
              56.9428
            ],
            [
              -133.268673,
              56.940446
            ],
            [
              -133.265019,
              56.93889
            ],
            [
              -133.262508,
              56.938616
            ],
            [
              -133.261922,
              56.938155
            ],
            [
              -133.262663,
              56.937719
            ],
            [
              -133.263954,
              56.936475
            ],
            [
              -133.262495,
              56.934787
            ],
            [
              -133.260495,
              56.934086
            ],
            [
              -133.256612,
              56.933701
            ],
            [
              -133.253978,
              56.932798
            ],
            [
              -133.253973,
              56.932089
            ],
            [
              -133.255937,
              56.932039
            ],
            [
              -133.260272,
              56.93289
            ],
            [
              -133.264764,
              56.931953
            ],
            [
              -133.268698,
              56.931111
            ],
            [
              -133.271391,
              56.928796
            ],
            [
              -133.270853,
              56.92618
            ],
            [
              -133.270533,
              56.924288
            ],
            [
              -133.29579,
              56.918269
            ],
            [
              -133.363498,
              56.907248
            ],
            [
              -133.369537,
              56.901945
            ],
            [
              -133.370511,
              56.898074
            ],
            [
              -133.377005,
              56.894646
            ],
            [
              -133.384094,
              56.895691
            ],
            [
              -133.398209,
              56.893914
            ],
            [
              -133.409503,
              56.895468
            ],
            [
              -133.424001,
              56.889016
            ],
            [
              -133.438787,
              56.885302
            ],
            [
              -133.455345,
              56.88288
            ],
            [
              -133.478771,
              56.88415
            ],
            [
              -133.503143,
              56.877927
            ],
            [
              -133.491079,
              56.868079
            ],
            [
              -133.490142,
              56.858649
            ],
            [
              -133.490016,
              56.849553
            ],
            [
              -133.482018,
              56.847708
            ],
            [
              -133.484116,
              56.840517
            ],
            [
              -133.488102,
              56.832686
            ],
            [
              -133.496354,
              56.825244
            ],
            [
              -133.497577,
              56.81677
            ],
            [
              -133.513655,
              56.811045
            ],
            [
              -133.517124,
              56.801439
            ],
            [
              -133.516207,
              56.797837
            ],
            [
              -133.506518,
              56.796956
            ],
            [
              -133.49965,
              56.797551
            ],
            [
              -133.493856,
              56.793057
            ],
            [
              -133.494968,
              56.790403
            ],
            [
              -133.494723,
              56.786577
            ],
            [
              -133.491383,
              56.785497
            ],
            [
              -133.489457,
              56.781059
            ],
            [
              -133.49378,
              56.775521
            ],
            [
              -133.498485,
              56.768079
            ],
            [
              -133.494489,
              56.762882
            ],
            [
              -133.489833,
              56.759381
            ],
            [
              -133.491548,
              56.752572
            ],
            [
              -133.493508,
              56.748772
            ],
            [
              -133.501527,
              56.748997
            ],
            [
              -133.510488,
              56.751854
            ],
            [
              -133.528422,
              56.747733
            ],
            [
              -133.540084,
              56.75023
            ],
            [
              -133.547621,
              56.752951
            ],
            [
              -133.554425,
              56.761221
            ],
            [
              -133.55404,
              56.764191
            ],
            [
              -133.561983,
              56.764535
            ],
            [
              -133.566825,
              56.761974
            ],
            [
              -133.574916,
              56.761181
            ]
          ]
        ],
        [
          [
            [
              -133.680963,
              57.648265
            ],
            [
              -133.676449,
              57.625192
            ],
            [
              -133.66439,
              57.611707
            ],
            [
              -133.62076,
              57.578919
            ],
            [
              -133.578948,
              57.565094
            ],
            [
              -133.565478,
              57.563095
            ],
            [
              -133.53786,
              57.567292
            ],
            [
              -133.531905,
              57.569466
            ],
            [
              -133.528313,
              57.573944
            ],
            [
              -133.505982,
              57.578459
            ],
            [
              -133.481221,
              57.57147
            ],
            [
              -133.478086,
              57.56173
            ],
            [
              -133.488197,
              57.551387
            ],
            [
              -133.496365,
              57.548772
            ],
            [
              -133.510806,
              57.548139
            ],
            [
              -133.516749,
              57.543911
            ],
            [
              -133.52583,
              57.501777
            ],
            [
              -133.52514,
              57.490344
            ],
            [
              -133.503115,
              57.453528
            ],
            [
              -133.461179,
              57.394577
            ],
            [
              -133.472454,
              57.388446
            ],
            [
              -133.475998,
              57.380394
            ],
            [
              -133.472039,
              57.368651
            ],
            [
              -133.468267,
              57.364217
            ],
            [
              -133.453783,
              57.35624
            ],
            [
              -133.442682,
              57.352845
            ],
            [
              -133.403868,
              57.342685
            ],
            [
              -133.35472,
              57.333253
            ],
            [
              -133.34207,
              57.336798
            ],
            [
              -133.28351,
              57.333119
            ],
            [
              -133.274829,
              57.330625
            ],
            [
              -133.287052,
              57.30292
            ],
            [
              -133.307565,
              57.290052
            ],
            [
              -133.371591,
              57.286713
            ],
            [
              -133.425948,
              57.285995
            ],
            [
              -133.442436,
              57.289978
            ],
            [
              -133.444958,
              57.297729
            ],
            [
              -133.455936,
              57.30397
            ],
            [
              -133.47589,
              57.307982
            ],
            [
              -133.489738,
              57.305192
            ],
            [
              -133.522837,
              57.27858
            ],
            [
              -133.542565,
              57.250682
            ],
            [
              -133.544817,
              57.24257
            ],
            [
              -133.517197,
              57.177776
            ],
            [
              -133.466932,
              57.159356
            ],
            [
              -133.322359,
              57.112727
            ],
            [
              -133.247414,
              57.136802
            ],
            [
              -133.23488,
              57.137937
            ],
            [
              -133.224656,
              57.136522
            ],
            [
              -133.206655,
              57.123834
            ],
            [
              -133.210261,
              57.118453
            ],
            [
              -133.208726,
              57.109699
            ],
            [
              -133.188074,
              57.088973
            ],
            [
              -133.161448,
              57.086264
            ],
            [
              -133.125306,
              57.088891
            ],
            [
              -133.076481,
              57.081733
            ],
            [
              -133.02505,
              57.057322
            ],
            [
              -132.993944,
              57.032353
            ],
            [
              -132.984307,
              57.054845
            ],
            [
              -132.93752,
              57.048321
            ],
            [
              -132.916487,
              57.040086
            ],
            [
              -132.875197,
              57.069577
            ],
            [
              -132.870116,
              57.078424
            ],
            [
              -132.853284,
              57.080077
            ],
            [
              -132.83222,
              57.070408
            ],
            [
              -132.813684,
              57.030218
            ],
            [
              -132.853636,
              57.005343
            ],
            [
              -132.892388,
              56.993016
            ],
            [
              -132.918967,
              56.993673
            ],
            [
              -132.91197,
              56.966651
            ],
            [
              -132.87034,
              56.925682
            ],
            [
              -132.846744,
              56.910635
            ],
            [
              -132.829346,
              56.903573
            ],
            [
              -132.81789,
              56.896901
            ],
            [
              -132.796999,
              56.87779
            ],
            [
              -132.792727,
              56.871673
            ],
            [
              -132.793601,
              56.866364
            ],
            [
              -132.797107,
              56.864922
            ],
            [
              -132.792089,
              56.856152
            ],
            [
              -132.770404,
              56.837486
            ],
            [
              -132.637458,
              56.78091
            ],
            [
              -132.556758,
              56.757242
            ],
            [
              -132.566653,
              56.738553
            ],
            [
              -132.55064850405302,
              56.713875586357396
            ],
            [
              -132.518409,
              56.717192
            ],
            [
              -132.495538,
              56.727769
            ],
            [
              -132.470802,
              56.730892
            ],
            [
              -132.439599,
              56.770409
            ],
            [
              -132.401076,
              56.778918
            ],
            [
              -132.368625,
              56.777706
            ],
            [
              -132.348468,
              56.771287
            ],
            [
              -132.346219,
              56.782806
            ],
            [
              -132.30303,
              56.799253
            ],
            [
              -132.284604,
              56.8167
            ],
            [
              -132.277981,
              56.845858
            ],
            [
              -132.242331,
              56.858213
            ],
            [
              -132.23902,
              56.877465
            ],
            [
              -132.215746,
              56.891606
            ],
            [
              -132.160186,
              56.903305
            ],
            [
              -132.119034236858,
              56.891264078517004
            ],
            [
              -132.071126,
              57.00629
            ],
            [
              -132.051044,
              57.051155
            ],
            [
              -132.216318,
              57.074019
            ],
            [
              -132.371312,
              57.095229
            ],
            [
              -132.308938,
              57.158403
            ],
            [
              -132.252187,
              57.215655
            ],
            [
              -132.29792,
              57.269469
            ],
            [
              -132.367984,
              57.348685
            ],
            [
              -132.559178,
              57.503927
            ],
            [
              -132.649734176785,
              57.609687528299695
            ],
            [
              -132.655135,
              57.604887
            ],
            [
              -132.649796,
              57.594813
            ],
            [
              -132.652187,
              57.586463
            ],
            [
              -132.660197,
              57.583535
            ],
            [
              -132.669381,
              57.581969
            ],
            [
              -132.676905,
              57.581234
            ],
            [
              -132.687151,
              57.580075
            ],
            [
              -132.695748,
              57.579398
            ],
            [
              -132.701557,
              57.578089
            ],
            [
              -132.711462,
              57.578012
            ],
            [
              -132.721833,
              57.576216
            ],
            [
              -132.729846,
              57.579981
            ],
            [
              -132.767736,
              57.582659
            ],
            [
              -132.801185,
              57.576287
            ],
            [
              -132.824648,
              57.579751
            ],
            [
              -132.828756,
              57.582576
            ],
            [
              -132.830228,
              57.588861
            ],
            [
              -132.844793,
              57.593989
            ],
            [
              -132.85515,
              57.599542
            ],
            [
              -132.855584,
              57.604694
            ],
            [
              -132.864778,
              57.607268
            ],
            [
              -132.875548,
              57.608075
            ],
            [
              -132.883649,
              57.608088
            ],
            [
              -132.889003,
              57.609348
            ],
            [
              -132.888253,
              57.612788
            ],
            [
              -132.883445,
              57.617975
            ],
            [
              -132.878491,
              57.628567
            ],
            [
              -132.878961,
              57.635783
            ],
            [
              -132.88123,
              57.639456
            ],
            [
              -132.873386,
              57.644613
            ],
            [
              -132.854384,
              57.653237
            ],
            [
              -132.841532,
              57.66067
            ],
            [
              -132.849241,
              57.672511
            ],
            [
              -132.872828,
              57.684438
            ],
            [
              -132.886108,
              57.683827
            ],
            [
              -132.891513,
              57.683928
            ],
            [
              -132.898629,
              57.68688
            ],
            [
              -132.896416,
              57.690561
            ],
            [
              -132.890604,
              57.703031
            ],
            [
              -132.878855,
              57.708186
            ],
            [
              -132.876604,
              57.718962
            ],
            [
              -132.893391,
              57.727435
            ],
            [
              -132.913572,
              57.728949
            ],
            [
              -132.922462,
              57.729994
            ],
            [
              -132.926669,
              57.732686
            ],
            [
              -132.931647,
              57.73543
            ],
            [
              -132.936174,
              57.73758
            ],
            [
              -132.941507,
              57.741383
            ],
            [
              -132.948296,
              57.744422
            ],
            [
              -132.954046,
              57.745545
            ],
            [
              -132.957639,
              57.741818
            ],
            [
              -132.961341,
              57.737318
            ],
            [
              -132.966078,
              57.731458
            ],
            [
              -132.973508,
              57.723823
            ],
            [
              -132.988311,
              57.719732
            ],
            [
              -132.99762,
              57.71721
            ],
            [
              -133.006258,
              57.716097
            ],
            [
              -133.01307,
              57.71649
            ],
            [
              -133.017198,
              57.717731
            ],
            [
              -133.025215,
              57.71932
            ],
            [
              -133.035089,
              57.720723
            ],
            [
              -133.042527,
              57.720325
            ],
            [
              -133.052396,
              57.722652
            ],
            [
              -133.055211,
              57.728643
            ],
            [
              -133.060474,
              57.734595
            ],
            [
              -133.064993,
              57.739191
            ],
            [
              -133.070842,
              57.741939
            ],
            [
              -133.079496,
              57.739792
            ],
            [
              -133.085173,
              57.736903
            ],
            [
              -133.096518,
              57.732587
            ],
            [
              -133.100435,
              57.729098
            ],
            [
              -133.107441,
              57.727291
            ],
            [
              -133.111588,
              57.728413
            ],
            [
              -133.122977,
              57.729501
            ],
            [
              -133.131719,
              57.732851
            ],
            [
              -133.131584,
              57.73643
            ],
            [
              -133.132053,
              57.739709
            ],
            [
              -133.137145,
              57.741725
            ],
            [
              -133.143869,
              57.741392
            ],
            [
              -133.15545,
              57.739685
            ],
            [
              -133.165287,
              57.74074
            ],
            [
              -133.174281,
              57.742513
            ],
            [
              -133.179644,
              57.741528
            ],
            [
              -133.187448,
              57.74108
            ],
            [
              -133.191908,
              57.745195
            ],
            [
              -133.191757,
              57.749502
            ],
            [
              -133.191787,
              57.75139
            ],
            [
              -133.198032,
              57.754333
            ],
            [
              -133.207183,
              57.754444
            ],
            [
              -133.212355,
              57.754766
            ],
            [
              -133.218899,
              57.758334
            ],
            [
              -133.218718,
              57.762393
            ],
            [
              -133.222702,
              57.768198
            ],
            [
              -133.233511,
              57.771487
            ],
            [
              -133.248474,
              57.770367
            ],
            [
              -133.259486,
              57.769688
            ],
            [
              -133.270088,
              57.767459
            ],
            [
              -133.275642,
              57.767246
            ],
            [
              -133.281468,
              57.769862
            ],
            [
              -133.282708,
              57.772922
            ],
            [
              -133.274972,
              57.782977
            ],
            [
              -133.275891,
              57.788865
            ],
            [
              -133.277367,
              57.792219
            ],
            [
              -133.283798,
              57.794375
            ],
            [
              -133.289677,
              57.792708
            ],
            [
              -133.29822,
              57.789197
            ],
            [
              -133.304427,
              57.786165
            ],
            [
              -133.311214,
              57.781774
            ],
            [
              -133.321375,
              57.776419
            ],
            [
              -133.330582,
              57.776903
            ],
            [
              -133.34182,
              57.773985
            ],
            [
              -133.351492,
              57.772397
            ],
            [
              -133.358918,
              57.774717
            ],
            [
              -133.364259,
              57.777074
            ],
            [
              -133.371607,
              57.778214
            ],
            [
              -133.377669,
              57.77831
            ],
            [
              -133.387516,
              57.78287
            ],
            [
              -133.396645,
              57.785504
            ],
            [
              -133.406438,
              57.783857
            ],
            [
              -133.413152,
              57.783242
            ],
            [
              -133.419948,
              57.786494
            ],
            [
              -133.421656,
              57.795224
            ],
            [
              -133.424858,
              57.804256
            ],
            [
              -133.433614,
              57.806406
            ],
            [
              -133.438968,
              57.807073
            ],
            [
              -133.444674,
              57.810062
            ],
            [
              -133.452242,
              57.814062
            ],
            [
              -133.461948,
              57.816258
            ],
            [
              -133.472395,
              57.818739
            ],
            [
              -133.483116,
              57.819149
            ],
            [
              -133.489937,
              57.822243
            ],
            [
              -133.497415,
              57.820814
            ],
            [
              -133.501622,
              57.817675
            ],
            [
              -133.510763,
              57.815073
            ],
            [
              -133.517961,
              57.812296
            ],
            [
              -133.524292,
              57.805775
            ],
            [
              -133.529275,
              57.799126
            ],
            [
              -133.536264,
              57.791851
            ],
            [
              -133.544743,
              57.785581
            ],
            [
              -133.548546,
              57.779935
            ],
            [
              -133.55295059887598,
              57.7730267528967
            ],
            [
              -133.545031,
              57.76797
            ],
            [
              -133.485403,
              57.738677
            ],
            [
              -133.322532,
              57.66583
            ],
            [
              -133.291062,
              57.665358
            ],
            [
              -133.278209,
              57.661859
            ],
            [
              -133.251126,
              57.649966
            ],
            [
              -133.174032,
              57.610062
            ],
            [
              -133.162464,
              57.599796
            ],
            [
              -133.179062,
              57.587147
            ],
            [
              -133.188864,
              57.589071
            ],
            [
              -133.234598,
              57.608749
            ],
            [
              -133.40498,
              57.663783
            ],
            [
              -133.441215,
              57.672013
            ],
            [
              -133.489677,
              57.677141
            ],
            [
              -133.522243,
              57.683663
            ],
            [
              -133.530957,
              57.686914
            ],
            [
              -133.543928,
              57.696454
            ],
            [
              -133.582212,
              57.715095
            ],
            [
              -133.65453,
              57.713689
            ],
            [
              -133.65855,
              57.707924
            ],
            [
              -133.680963,
              57.648265
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "02",
      "COUNTYFP": "188",
      "COUNTYNS": "01419979",
      "AFFGEOID": "0500000US02188",
      "GEOID": "02188",
      "NAME": "Northwest Arctic",
      "LSAD": "04",
      "ALAND": 92326483031,
      "AWATER": 12878258897,
      "County_state": "Northwest Arctic,02"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -165.386570100313,
              68.03473171360119
            ],
            [
              -165.3132,
              68.016877
            ],
            [
              -165.227228,
              67.985322
            ],
            [
              -165.190915,
              67.966071
            ],
            [
              -165.129567,
              67.941833
            ],
            [
              -165.057516,
              67.921694
            ],
            [
              -164.907297,
              67.867844
            ],
            [
              -164.778331,
              67.820866
            ],
            [
              -164.533937,
              67.725606
            ],
            [
              -164.209816,
              67.639079
            ],
            [
              -164.14438,
              67.617228
            ],
            [
              -164.108716,
              67.601993
            ],
            [
              -164.079514,
              67.585856
            ],
            [
              -164.007032,
              67.535699
            ],
            [
              -163.878781,
              67.416125
            ],
            [
              -163.853584,
              67.388101
            ],
            [
              -163.822185,
              67.349812
            ],
            [
              -163.758588,
              67.256439
            ],
            [
              -163.74082,
              67.20996
            ],
            [
              -163.737464,
              67.184754
            ],
            [
              -163.736901,
              67.16323
            ],
            [
              -163.745924,
              67.137106
            ],
            [
              -163.744736,
              67.126391
            ],
            [
              -163.702045,
              67.109375
            ],
            [
              -163.665952,
              67.100124
            ],
            [
              -163.591216,
              67.092373
            ],
            [
              -163.495201,
              67.087503
            ],
            [
              -163.441198,
              67.081459
            ],
            [
              -163.399048,
              67.074167
            ],
            [
              -163.299266,
              67.060748
            ],
            [
              -163.097854,
              67.041191
            ],
            [
              -162.97529,
              67.024602
            ],
            [
              -162.842979,
              66.991177
            ],
            [
              -162.819211,
              66.994707
            ],
            [
              -162.816834,
              66.99949
            ],
            [
              -162.833709,
              67.00931
            ],
            [
              -162.831394,
              67.034945
            ],
            [
              -162.775523,
              67.018402
            ],
            [
              -162.755083,
              67.015247
            ],
            [
              -162.693048,
              67.028608
            ],
            [
              -162.655732,
              67.008285
            ],
            [
              -162.622695,
              66.992219
            ],
            [
              -162.501716,
              66.977164
            ],
            [
              -162.468441,
              66.980604
            ],
            [
              -162.406844,
              66.984713
            ],
            [
              -162.36112,
              66.994042
            ],
            [
              -162.23923,
              66.993814
            ],
            [
              -162.234302,
              66.994581
            ],
            [
              -162.233964,
              66.999568
            ],
            [
              -162.211633,
              67.00671
            ],
            [
              -162.123181,
              67.02579
            ],
            [
              -161.893702,
              67.049075
            ],
            [
              -161.850188,
              67.052186
            ],
            [
              -161.836325,
              67.051777
            ],
            [
              -161.810256,
              67.050281
            ],
            [
              -161.799175,
              67.047502
            ],
            [
              -161.759641,
              67.030572
            ],
            [
              -161.711715,
              67.001044
            ],
            [
              -161.697392,
              67.010849
            ],
            [
              -161.62216,
              67.008146
            ],
            [
              -161.530525,
              66.984951
            ],
            [
              -161.505747,
              66.974314
            ],
            [
              -161.485383,
              66.960818
            ],
            [
              -161.485121,
              66.945647
            ],
            [
              -161.489169,
              66.93695
            ],
            [
              -161.566678,
              66.934775
            ],
            [
              -161.674359,
              66.961965
            ],
            [
              -161.68628,
              66.961367
            ],
            [
              -161.688506,
              66.959799
            ],
            [
              -161.685775,
              66.955794
            ],
            [
              -161.692146,
              66.945033
            ],
            [
              -161.719587,
              66.916898
            ],
            [
              -161.770475,
              66.896643
            ],
            [
              -161.794377,
              66.881933
            ],
            [
              -161.782218,
              66.859956
            ],
            [
              -161.785495,
              66.846547
            ],
            [
              -161.796307,
              66.833126
            ],
            [
              -161.82417,
              66.817889
            ],
            [
              -161.846258,
              66.813647
            ],
            [
              -161.86154,
              66.797076
            ],
            [
              -161.866421,
              66.777658
            ],
            [
              -161.846076,
              66.761557
            ],
            [
              -161.881671,
              66.716796
            ],
            [
              -161.86618,
              66.704978
            ],
            [
              -161.693125,
              66.620562
            ],
            [
              -161.665368,
              66.610433
            ],
            [
              -161.598953,
              66.593181
            ],
            [
              -161.541756,
              66.581379
            ],
            [
              -161.4904,
              66.560844
            ],
            [
              -161.482869,
              66.543724
            ],
            [
              -161.486308,
              66.538037
            ],
            [
              -161.494988,
              66.534443
            ],
            [
              -161.493509,
              66.530977
            ],
            [
              -161.483604,
              66.525626
            ],
            [
              -161.469227,
              66.522843
            ],
            [
              -161.454092,
              66.522205
            ],
            [
              -161.399006,
              66.523529
            ],
            [
              -161.29321,
              66.520591
            ],
            [
              -161.279803,
              66.505179
            ],
            [
              -161.326349,
              66.478371
            ],
            [
              -161.435312,
              66.454162
            ],
            [
              -161.516449,
              66.441839
            ],
            [
              -161.574129,
              66.438566
            ],
            [
              -161.624334,
              66.450143
            ],
            [
              -161.765368,
              66.496934
            ],
            [
              -161.885095,
              66.553979
            ],
            [
              -161.953413,
              66.592365
            ],
            [
              -161.968863,
              66.602611
            ],
            [
              -162.033156,
              66.631585
            ],
            [
              -162.069068,
              66.6457
            ],
            [
              -162.07362,
              66.651217
            ],
            [
              -162.07801,
              66.664048
            ],
            [
              -162.066037,
              66.67926
            ],
            [
              -162.081515,
              66.693052
            ],
            [
              -162.074634,
              66.703681
            ],
            [
              -162.068253,
              66.709857
            ],
            [
              -162.058825,
              66.716253
            ],
            [
              -162.041314,
              66.723764
            ],
            [
              -162.029615,
              66.73458
            ],
            [
              -162.011455,
              66.759063
            ],
            [
              -162.013623,
              66.779406
            ],
            [
              -162.049123,
              66.780639
            ],
            [
              -162.073714,
              66.783324
            ],
            [
              -162.096878,
              66.7885
            ],
            [
              -162.117304,
              66.798482
            ],
            [
              -162.157568,
              66.831169
            ],
            [
              -162.228675,
              66.866623
            ],
            [
              -162.271769,
              66.904144
            ],
            [
              -162.281116,
              66.915679
            ],
            [
              -162.29008,
              66.93126
            ],
            [
              -162.322975,
              66.943215
            ],
            [
              -162.346352,
              66.934792
            ],
            [
              -162.408988,
              66.918857
            ],
            [
              -162.444857,
              66.925057
            ],
            [
              -162.466702,
              66.950998
            ],
            [
              -162.499785,
              66.933715
            ],
            [
              -162.503661,
              66.91354
            ],
            [
              -162.534182,
              66.905313
            ],
            [
              -162.582856,
              66.904292
            ],
            [
              -162.601052,
              66.898455
            ],
            [
              -162.61459,
              66.885941
            ],
            [
              -162.623054,
              66.874325
            ],
            [
              -162.626696,
              66.859572
            ],
            [
              -162.624945,
              66.855031
            ],
            [
              -162.621564,
              66.85086
            ],
            [
              -162.614738,
              66.846476
            ],
            [
              -162.594237,
              66.837647
            ],
            [
              -162.572224,
              66.825364
            ],
            [
              -162.544381,
              66.804872
            ],
            [
              -162.512617,
              66.777733
            ],
            [
              -162.502502,
              66.758875
            ],
            [
              -162.50052,
              66.749751
            ],
            [
              -162.501415,
              66.742503
            ],
            [
              -162.482353,
              66.730262
            ],
            [
              -162.422414,
              66.731581
            ],
            [
              -162.349774,
              66.726713
            ],
            [
              -162.268767,
              66.717905
            ],
            [
              -162.228635,
              66.70977
            ],
            [
              -162.175398,
              66.687789
            ],
            [
              -162.140377,
              66.664737
            ],
            [
              -162.124348,
              66.651291
            ],
            [
              -162.113311,
              66.639596
            ],
            [
              -162.105641,
              66.622584
            ],
            [
              -162.09791,
              66.609863
            ],
            [
              -162.091453,
              66.605963
            ],
            [
              -162.029043,
              66.586504
            ],
            [
              -161.87488,
              66.511446
            ],
            [
              -161.864156,
              66.488195
            ],
            [
              -161.863387,
              66.440783
            ],
            [
              -161.872447,
              66.414132
            ],
            [
              -161.8809,
              66.398763
            ],
            [
              -161.916309,
              66.349481
            ],
            [
              -161.912946,
              66.344436
            ],
            [
              -161.817538,
              66.360815
            ],
            [
              -161.694404,
              66.396174
            ],
            [
              -161.525554,
              66.397046
            ],
            [
              -161.360743,
              66.375943
            ],
            [
              -161.219834,
              66.348918
            ],
            [
              -161.1631,
              66.342291
            ],
            [
              -161.129512,
              66.336248
            ],
            [
              -161.107995,
              66.328367
            ],
            [
              -161.089161,
              66.31514
            ],
            [
              -161.079328,
              66.307126
            ],
            [
              -161.061034,
              66.283804
            ],
            [
              -160.99854,
              66.254935
            ],
            [
              -160.995905,
              66.251962
            ],
            [
              -160.990275,
              66.239715
            ],
            [
              -160.991066,
              66.236816
            ],
            [
              -160.993965,
              66.234444
            ],
            [
              -161.000026,
              66.233126
            ],
            [
              -161.035866,
              66.229437
            ],
            [
              -161.052732,
              66.231018
            ],
            [
              -161.067871,
              66.235164
            ],
            [
              -161.087342,
              66.234208
            ],
            [
              -161.145397,
              66.220179
            ],
            [
              -161.198971,
              66.210949
            ],
            [
              -161.263655,
              66.214154
            ],
            [
              -161.313025,
              66.221051
            ],
            [
              -161.320778,
              66.223591
            ],
            [
              -161.33212,
              66.236431
            ],
            [
              -161.337269,
              66.243163
            ],
            [
              -161.341189,
              66.2551
            ],
            [
              -161.367875,
              66.258653
            ],
            [
              -161.484539,
              66.262426
            ],
            [
              -161.548429,
              66.239912
            ],
            [
              -161.613943,
              66.176693
            ],
            [
              -161.623983,
              66.162082
            ],
            [
              -161.627008,
              66.153194
            ],
            [
              -161.6653,
              66.122177
            ],
            [
              -161.680577,
              66.111588
            ],
            [
              -161.775537,
              66.073732
            ],
            [
              -161.798585,
              66.055317
            ],
            [
              -161.812306,
              66.041688
            ],
            [
              -161.817091,
              66.033089
            ],
            [
              -161.838018,
              66.022582
            ],
            [
              -161.950043,
              66.040302
            ],
            [
              -162.025701,
              66.062829
            ],
            [
              -162.121788,
              66.067391
            ],
            [
              -162.129709,
              66.069487
            ],
            [
              -162.137424,
              66.078547
            ],
            [
              -162.139516,
              66.078819
            ],
            [
              -162.205889,
              66.056753
            ],
            [
              -162.26967,
              66.042104
            ],
            [
              -162.331284,
              66.031403
            ],
            [
              -162.372147,
              66.027985
            ],
            [
              -162.391892,
              66.028724
            ],
            [
              -162.413452,
              66.035085
            ],
            [
              -162.423726,
              66.048984
            ],
            [
              -162.45767,
              66.058579
            ],
            [
              -162.530755,
              66.045062
            ],
            [
              -162.622284,
              66.039526
            ],
            [
              -162.635985,
              66.040366
            ],
            [
              -162.673584,
              66.053685
            ],
            [
              -162.680204,
              66.058129
            ],
            [
              -162.681304,
              66.061574
            ],
            [
              -162.750705,
              66.09016
            ],
            [
              -162.791232,
              66.089138
            ],
            [
              -162.876016,
              66.082833
            ],
            [
              -162.997473,
              66.076845
            ],
            [
              -163.04714,
              66.068327
            ],
            [
              -163.093003,
              66.06296
            ],
            [
              -163.146726,
              66.059487
            ],
            [
              -163.168568,
              66.05929
            ],
            [
              -163.287768,
              66.069229
            ],
            [
              -163.313843,
              66.075287
            ],
            [
              -163.344759,
              66.084937
            ],
            [
              -163.495845,
              66.085388
            ],
            [
              -163.502704,
              66.081165
            ],
            [
              -163.540115,
              66.069921
            ],
            [
              -163.623921,
              66.058281
            ],
            [
              -163.76751,
              66.060803
            ],
            [
              -163.768357,
              66.073662
            ],
            [
              -163.772467,
              66.081922
            ],
            [
              -163.80358,
              66.100059
            ],
            [
              -163.847401,
              66.122106
            ],
            [
              -163.861406,
              66.136665
            ],
            [
              -163.878306,
              66.160279
            ],
            [
              -163.916551,
              66.190494
            ],
            [
              -164.007503,
              66.179386
            ],
            [
              -164.078677,
              66.181019
            ],
            [
              -164.089237,
              66.182338
            ],
            [
              -164.092715,
              66.184537
            ],
            [
              -164.078765,
              66.201764
            ],
            [
              -164.046937,
              66.209404
            ],
            [
              -163.955901,
              66.21717
            ],
            [
              -163.904813,
              66.230303
            ],
            [
              -163.843108,
              66.259869
            ],
            [
              -163.830077,
              66.272
            ],
            [
              -163.829977,
              66.280398
            ],
            [
              -163.839825,
              66.304079
            ],
            [
              -163.849163,
              66.307639
            ],
            [
              -163.873096,
              66.32855
            ],
            [
              -163.873106,
              66.389015
            ],
            [
              -163.856359,
              66.409296
            ],
            [
              -163.844221,
              66.418978
            ],
            [
              -163.798687,
              66.436875
            ],
            [
              -163.761967,
              66.454874
            ],
            [
              -163.730247,
              66.491372
            ],
            [
              -163.728308,
              66.498552
            ],
            [
              -163.727179,
              66.516388
            ],
            [
              -163.751163,
              66.528761
            ],
            [
              -163.752981,
              66.548584
            ],
            [
              -163.635631,
              66.553966
            ],
            [
              -163.603956,
              66.558089
            ],
            [
              -163.668961,
              66.575906
            ],
            [
              -163.726836,
              66.584361
            ],
            [
              -163.824166,
              66.59168
            ],
            [
              -163.914899,
              66.593905
            ],
            [
              -163.978492,
              66.591012
            ],
            [
              -163.978095,
              66.592616
            ],
            [
              -163.979581,
              66.593953
            ],
            [
              -164.094554,
              66.592281
            ],
            [
              -164.345015,
              66.580834
            ],
            [
              -164.39594352714502,
              66.581086316026
            ],
            [
              -164.395887,
              66.476324
            ],
            [
              -164.318936,
              66.476319
            ],
            [
              -164.318913,
              66.129944
            ],
            [
              -164.244191,
              66.129946
            ],
            [
              -164.244152,
              65.783558
            ],
            [
              -163.963516,
              65.783561
            ],
            [
              -163.963498,
              65.610363
            ],
            [
              -163.755407,
              65.610367
            ],
            [
              -163.755391,
              65.437165
            ],
            [
              -162.824941,
              65.437181
            ],
            [
              -162.331036,
              65.437158
            ],
            [
              -161.457968,
              65.437205
            ],
            [
              -160.634052,
              65.437216
            ],
            [
              -159.801692,
              65.437231
            ],
            [
              -159.8017,
              65.523826
            ],
            [
              -159.593506,
              65.523835
            ],
            [
              -159.593635,
              65.783636
            ],
            [
              -159.604795,
              65.783636
            ],
            [
              -159.604813,
              65.95683
            ],
            [
              -158.972175,
              65.956849
            ],
            [
              -158.972185,
              66.130027
            ],
            [
              -158.575528,
              66.130035
            ],
            [
              -157.906295,
              66.130049
            ],
            [
              -157.90631,
              66.476424
            ],
            [
              -157.051284,
              66.476438
            ],
            [
              -157.051266,
              66.303254
            ],
            [
              -156.623757,
              66.303259
            ],
            [
              -156.623769,
              66.325548
            ],
            [
              -156.62377,
              66.476442
            ],
            [
              -156.196224,
              66.476582
            ],
            [
              -156.196257,
              66.389857
            ],
            [
              -155.982504,
              66.38986
            ],
            [
              -155.982499,
              66.303268
            ],
            [
              -155.554993,
              66.303274
            ],
            [
              -155.555001,
              66.476458
            ],
            [
              -155.510581,
              66.476459
            ],
            [
              -155.510585,
              66.563051
            ],
            [
              -154.860431,
              66.563064
            ],
            [
              -154.860446,
              66.736242
            ],
            [
              -154.210302,
              66.736255
            ],
            [
              -154.210317,
              66.822842
            ],
            [
              -154.146221,
              66.822843
            ],
            [
              -154.146314,
              67.169178
            ],
            [
              -154.303097,
              67.169177
            ],
            [
              -154.30311,
              67.255759
            ],
            [
              -154.748904,
              67.255753
            ],
            [
              -154.748939,
              67.515495
            ],
            [
              -154.690477,
              67.515496
            ],
            [
              -154.690489,
              67.602075
            ],
            [
              -154.916649,
              67.60207
            ],
            [
              -154.916661,
              67.688648
            ],
            [
              -155.142825,
              67.688643
            ],
            [
              -155.142837,
              67.775221
            ],
            [
              -155.368999,
              67.775216
            ],
            [
              -155.369012,
              67.861792
            ],
            [
              -155.318723,
              67.861793
            ],
            [
              -155.318744,
              68.000032
            ],
            [
              -155.318748,
              68.034942
            ],
            [
              -156.236817,
              68.034926
            ],
            [
              -156.236831,
              68.121498
            ],
            [
              -157.15491,
              68.121483
            ],
            [
              -157.154924,
              68.208054
            ],
            [
              -157.790801,
              68.208037
            ],
            [
              -158.1791,
              68.208024
            ],
            [
              -158.955704,
              68.208006
            ],
            [
              -159.693477,
              68.207992
            ],
            [
              -159.693487,
              68.294564
            ],
            [
              -160.159452,
              68.294553
            ],
            [
              -160.15944,
              68.207982
            ],
            [
              -161.091351,
              68.207961
            ],
            [
              -161.091364,
              68.294532
            ],
            [
              -161.55732,
              68.294521
            ],
            [
              -161.557328,
              68.207949
            ],
            [
              -162.023261,
              68.20794
            ],
            [
              -162.023273,
              68.294511
            ],
            [
              -162.722202,
              68.294496
            ],
            [
              -162.72219,
              68.207926
            ],
            [
              -163.615271,
              68.207907
            ],
            [
              -164.499463,
              68.20789
            ],
            [
              -164.499437,
              68.034748
            ],
            [
              -165.386570100313,
              68.03473171360119
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "02",
      "COUNTYFP": "100",
      "COUNTYNS": "01419970",
      "AFFGEOID": "0500000US02100",
      "GEOID": "02100",
      "NAME": "Haines",
      "LSAD": "04",
      "ALAND": 6233111973,
      "AWATER": 1054089659,
      "County_state": "Haines,02"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -135.349335,
              59.020034
            ],
            [
              -135.353142,
              59.010516
            ],
            [
              -135.334107,
              58.943891
            ],
            [
              -135.316023,
              58.924855
            ],
            [
              -135.298626,
              58.919262
            ],
            [
              -135.296035,
              58.95912
            ],
            [
              -135.3303,
              59.012419
            ],
            [
              -135.349335,
              59.020034
            ]
          ]
        ],
        [
          [
            [
              -136.494084,
              59.27299
            ],
            [
              -136.486609,
              59.261108
            ],
            [
              -136.48840234349203,
              59.2592903388969
            ],
            [
              -136.476171,
              59.25715
            ],
            [
              -136.464302,
              59.260733
            ],
            [
              -136.459834,
              59.263813
            ],
            [
              -136.446663,
              59.261193
            ],
            [
              -136.443428,
              59.254697
            ],
            [
              -136.427786,
              59.247734
            ],
            [
              -136.407713,
              59.243928
            ],
            [
              -136.390809,
              59.247547
            ],
            [
              -136.387327,
              59.251061
            ],
            [
              -136.380126,
              59.249456
            ],
            [
              -136.373328,
              59.249179
            ],
            [
              -136.365558,
              59.24476
            ],
            [
              -136.367508,
              59.24079
            ],
            [
              -136.350621,
              59.231202
            ],
            [
              -136.32589,
              59.230661
            ],
            [
              -136.30149,
              59.230098
            ],
            [
              -136.280863,
              59.225715
            ],
            [
              -136.260615,
              59.226138
            ],
            [
              -136.239426,
              59.214795
            ],
            [
              -136.217151,
              59.21392
            ],
            [
              -136.204769,
              59.216931
            ],
            [
              -136.187778,
              59.215195
            ],
            [
              -136.186579,
              59.207737
            ],
            [
              -136.163514,
              59.193909
            ],
            [
              -136.158934,
              59.186974
            ],
            [
              -136.145206,
              59.185938
            ],
            [
              -136.131481,
              59.192023
            ],
            [
              -136.121216,
              59.205245
            ],
            [
              -136.113427,
              59.210529
            ],
            [
              -136.105043,
              59.214338
            ],
            [
              -136.101472,
              59.212037
            ],
            [
              -136.09797,
              59.207647
            ],
            [
              -136.101026,
              59.204481
            ],
            [
              -136.087246,
              59.199295
            ],
            [
              -136.073366,
              59.201407
            ],
            [
              -136.062547,
              59.19835
            ],
            [
              -136.057712,
              59.193568
            ],
            [
              -136.05576,
              59.186797
            ],
            [
              -136.044643,
              59.17745
            ],
            [
              -136.026896,
              59.176878
            ],
            [
              -136.016161,
              59.181746
            ],
            [
              -136.004565,
              59.184561
            ],
            [
              -135.995886,
              59.182078
            ],
            [
              -135.977049,
              59.181252
            ],
            [
              -135.940824,
              59.180166
            ],
            [
              -135.916765,
              59.179618
            ],
            [
              -135.895563,
              59.176272
            ],
            [
              -135.883513,
              59.169312
            ],
            [
              -135.882359,
              59.163546
            ],
            [
              -135.8727,
              59.158791
            ],
            [
              -135.8576,
              59.155106
            ],
            [
              -135.841385,
              59.149377
            ],
            [
              -135.829597,
              59.13983
            ],
            [
              -135.80255,
              59.140131
            ],
            [
              -135.782924,
              59.140355
            ],
            [
              -135.762293,
              59.133649
            ],
            [
              -135.745171,
              59.123131
            ],
            [
              -135.722933,
              59.113843
            ],
            [
              -135.708619,
              59.109988
            ],
            [
              -135.704535,
              59.103067
            ],
            [
              -135.709508,
              59.097419
            ],
            [
              -135.716655,
              59.083381
            ],
            [
              -135.720814,
              59.072794
            ],
            [
              -135.706489,
              59.043108
            ],
            [
              -135.694859,
              59.027459
            ],
            [
              -135.682743,
              59.020186
            ],
            [
              -135.664361,
              59.01085
            ],
            [
              -135.647387,
              59.002292
            ],
            [
              -135.627216,
              58.989067
            ],
            [
              -135.604707,
              58.983681
            ],
            [
              -135.588414,
              58.975479
            ],
            [
              -135.566059,
              58.962806
            ],
            [
              -135.565987,
              58.948586
            ],
            [
              -135.58382,
              58.935925
            ],
            [
              -135.59218,
              58.933539
            ],
            [
              -135.608028,
              58.922384
            ],
            [
              -135.60563,
              58.908513
            ],
            [
              -135.600335,
              58.903091
            ],
            [
              -135.585982,
              58.898159
            ],
            [
              -135.57008,
              58.90138
            ],
            [
              -135.548706,
              58.898721
            ],
            [
              -135.532252,
              58.889194
            ],
            [
              -135.529486,
              58.883512
            ],
            [
              -135.542156,
              58.874046
            ],
            [
              -135.551602,
              58.875287
            ],
            [
              -135.583068,
              58.861815
            ],
            [
              -135.609696,
              58.855666
            ],
            [
              -135.634267,
              58.855528
            ],
            [
              -135.660283,
              58.852532
            ],
            [
              -135.679899,
              58.848791
            ],
            [
              -135.684662,
              58.836835
            ],
            [
              -135.708672,
              58.825609
            ],
            [
              -135.710805,
              58.812493
            ],
            [
              -135.711907,
              58.800266
            ],
            [
              -135.729926,
              58.787689
            ],
            [
              -135.730553,
              58.779683
            ],
            [
              -135.7334,
              58.769849
            ],
            [
              -135.730379,
              58.766732
            ],
            [
              -135.737079,
              58.762912
            ],
            [
              -135.746712,
              58.758478
            ],
            [
              -135.754556,
              58.747101
            ],
            [
              -135.745092,
              58.734159
            ],
            [
              -135.745237,
              58.729628
            ],
            [
              -135.729528,
              58.725792
            ],
            [
              -135.72144,
              58.730467
            ],
            [
              -135.694934,
              58.732467
            ],
            [
              -135.692405,
              58.726283
            ],
            [
              -135.676753,
              58.717552
            ],
            [
              -135.662061,
              58.716001
            ],
            [
              -135.651213,
              58.720016
            ],
            [
              -135.635028,
              58.718987
            ],
            [
              -135.625532,
              58.711239
            ],
            [
              -135.613285,
              58.707862
            ],
            [
              -135.606185,
              58.708756
            ],
            [
              -135.587581,
              58.709267
            ],
            [
              -135.579128,
              58.70267
            ],
            [
              -135.567112,
              58.698794
            ],
            [
              -135.560224,
              58.699305
            ],
            [
              -135.546955,
              58.695242
            ],
            [
              -135.541848,
              58.687192
            ],
            [
              -135.542184,
              58.675971
            ],
            [
              -135.54042,
              58.667839
            ],
            [
              -135.528727,
              58.657117
            ],
            [
              -135.506831,
              58.654941
            ],
            [
              -135.491766,
              58.65461
            ],
            [
              -135.47856,
              58.6477
            ],
            [
              -135.460358,
              58.638154
            ],
            [
              -135.435786,
              58.635996
            ],
            [
              -135.418482,
              58.626427
            ],
            [
              -135.404569,
              58.618484
            ],
            [
              -135.396416,
              58.601768
            ],
            [
              -135.409037,
              58.593795
            ],
            [
              -135.407538,
              58.581346
            ],
            [
              -135.396123,
              58.579242
            ],
            [
              -135.38025,
              58.570236
            ],
            [
              -135.370697,
              58.560177
            ],
            [
              -135.367851,
              58.546745
            ],
            [
              -135.359683,
              58.534181
            ],
            [
              -135.331589,
              58.521933
            ],
            [
              -135.318354,
              58.507013
            ],
            [
              -135.33105,
              58.495073
            ],
            [
              -135.353083,
              58.49173
            ],
            [
              -135.374788,
              58.49992
            ],
            [
              -135.401518,
              58.503946
            ],
            [
              -135.418417,
              58.511318
            ],
            [
              -135.432433,
              58.513146
            ],
            [
              -135.454391,
              58.509798
            ],
            [
              -135.471786,
              58.509256
            ],
            [
              -135.494521,
              58.499654
            ],
            [
              -135.493614,
              58.48705
            ],
            [
              -135.472464,
              58.470098
            ],
            [
              -135.46835,
              58.433278
            ],
            [
              -135.448398,
              58.408075
            ],
            [
              -135.446078452451,
              58.3998920844063
            ],
            [
              -135.433061,
              58.399899
            ],
            [
              -135.408059,
              58.342999
            ],
            [
              -135.39826,
              58.327689
            ],
            [
              -135.344868,
              58.270795
            ],
            [
              -135.306507,
              58.242916
            ],
            [
              -135.2877,
              58.234933
            ],
            [
              -135.277198,
              58.233634
            ],
            [
              -135.246709,
              58.236368
            ],
            [
              -135.227736,
              58.2369
            ],
            [
              -135.220281,
              58.235584
            ],
            [
              -135.159055,
              58.210178
            ],
            [
              -135.112868,
              58.201553
            ],
            [
              -135.087872,
              58.200073
            ],
            [
              -135.073269,
              58.190575
            ],
            [
              -135.056227,
              58.189884
            ],
            [
              -135.099106,
              58.245096
            ],
            [
              -135.1077,
              58.265034
            ],
            [
              -135.10121,
              58.292607
            ],
            [
              -135.095814,
              58.297233
            ],
            [
              -135.069775,
              58.302694
            ],
            [
              -135.060452,
              58.290338
            ],
            [
              -135.056552,
              58.288699
            ],
            [
              -135.053488,
              58.290498
            ],
            [
              -135.049062,
              58.309295
            ],
            [
              -135.058071,
              58.349447
            ],
            [
              -135.088983,
              58.423022
            ],
            [
              -135.132273,
              58.496536
            ],
            [
              -135.165861,
              58.546605
            ],
            [
              -135.189368,
              58.576244
            ],
            [
              -135.190544,
              58.592417
            ],
            [
              -135.186357,
              58.59777
            ],
            [
              -135.159062,
              58.595525
            ],
            [
              -135.153827,
              58.586626
            ],
            [
              -135.145521,
              58.578332
            ],
            [
              -135.142161,
              58.577107
            ],
            [
              -135.137516,
              58.577835
            ],
            [
              -135.135843,
              58.588225
            ],
            [
              -135.142322,
              58.61637
            ],
            [
              -135.233878,
              58.735487
            ],
            [
              -135.2432,
              58.783112
            ],
            [
              -135.248985,
              58.790878
            ],
            [
              -135.274203,
              58.813122
            ],
            [
              -135.284657,
              58.818114
            ],
            [
              -135.339948,
              58.888955
            ],
            [
              -135.322622,
              58.900661
            ],
            [
              -135.323637,
              58.909627
            ],
            [
              -135.367419,
              58.929614
            ],
            [
              -135.393117,
              58.971493
            ],
            [
              -135.38931,
              58.990528
            ],
            [
              -135.399439,
              59.005417
            ],
            [
              -135.378841,
              59.020034
            ],
            [
              -135.382641,
              59.033366
            ],
            [
              -135.395021,
              59.042877
            ],
            [
              -135.37641,
              59.089
            ],
            [
              -135.388031,
              59.105025
            ],
            [
              -135.43723,
              59.1132
            ],
            [
              -135.456958,
              59.134546
            ],
            [
              -135.484053,
              59.186451
            ],
            [
              -135.540859,
              59.219064
            ],
            [
              -135.632365,
              59.265983
            ],
            [
              -135.55298,
              59.256872
            ],
            [
              -135.473358,
              59.225997
            ],
            [
              -135.407401,
              59.169757
            ],
            [
              -135.374382,
              59.128946
            ],
            [
              -135.350853,
              59.105778
            ],
            [
              -135.321413,
              59.088701
            ],
            [
              -135.301384,
              59.082286
            ],
            [
              -135.295789,
              59.096366
            ],
            [
              -135.331621,
              59.151333
            ],
            [
              -135.368092,
              59.208914
            ],
            [
              -135.429601,
              59.242722
            ],
            [
              -135.444526,
              59.277689
            ],
            [
              -135.491149,
              59.282183
            ],
            [
              -135.544424,
              59.310047
            ],
            [
              -135.527786,
              59.323631
            ],
            [
              -135.396611,
              59.292434
            ],
            [
              -135.368331,
              59.263275
            ],
            [
              -135.283964,
              59.192532
            ],
            [
              -135.238267,
              59.130134
            ],
            [
              -135.208585,
              59.076824
            ],
            [
              -135.180116,
              58.997871
            ],
            [
              -135.175756,
              58.973868
            ],
            [
              -135.165716298401,
              58.9217211192478
            ],
            [
              -135.151115,
              58.845881
            ],
            [
              -135.14528000300302,
              58.84072136606609
            ],
            [
              -135.022624,
              58.732262
            ],
            [
              -135.027233,
              58.782183
            ],
            [
              -135.001255601564,
              58.8153907390769
            ],
            [
              -134.998996,
              58.84733
            ],
            [
              -134.986526,
              58.853453
            ],
            [
              -134.983027,
              58.874056
            ],
            [
              -135.011441,
              58.901259
            ],
            [
              -135.007878,
              58.911803
            ],
            [
              -135.03547,
              58.927621
            ],
            [
              -135.037756,
              58.950669
            ],
            [
              -135.058835,
              58.966785
            ],
            [
              -134.326427676754,
              58.963129162404
            ],
            [
              -134.327982,
              58.963431
            ],
            [
              -134.401042,
              58.976221
            ],
            [
              -134.400293,
              58.996484
            ],
            [
              -134.379771,
              59.034961
            ],
            [
              -134.442196,
              59.08301
            ],
            [
              -134.481241,
              59.128071
            ],
            [
              -134.566689,
              59.128278
            ],
            [
              -134.681924,
              59.190843
            ],
            [
              -134.702383,
              59.247836
            ],
            [
              -134.961972,
              59.280376
            ],
            [
              -135.00325,
              59.319473
            ],
            [
              -135.029245,
              59.345364
            ],
            [
              -135.37502,
              59.350033
            ],
            [
              -135.717560201194,
              59.7309280295579
            ],
            [
              -135.854166,
              59.691846
            ],
            [
              -135.858947,
              59.690049
            ],
            [
              -135.945905,
              59.663802
            ],
            [
              -136.190352,
              59.639854
            ],
            [
              -136.256889,
              59.623646
            ],
            [
              -136.350622,
              59.599326
            ],
            [
              -136.23734,
              59.558734
            ],
            [
              -136.236527,
              59.538272
            ],
            [
              -136.234229,
              59.524731
            ],
            [
              -136.301846,
              59.464128
            ],
            [
              -136.358141,
              59.449799
            ],
            [
              -136.365825,
              59.448008
            ],
            [
              -136.474326,
              59.464194
            ],
            [
              -136.466815,
              59.284252
            ],
            [
              -136.494084,
              59.27299
            ]
          ],
          [
            [
              -135.92734,
              59.403902
            ],
            [
              -135.919904,
              59.41062
            ],
            [
              -135.853867,
              59.395937
            ],
            [
              -135.855631,
              59.385612
            ],
            [
              -135.900985,
              59.395905
            ],
            [
              -135.92734,
              59.403902
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "02",
      "COUNTYFP": "013",
      "COUNTYNS": "01419964",
      "AFFGEOID": "0500000US02013",
      "GEOID": "02013",
      "NAME": "Aleutians East",
      "LSAD": "04",
      "ALAND": 18091325125,
      "AWATER": 20800198540,
      "County_state": "Aleutians East,02"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -159.320732,
              54.897269
            ],
            [
              -159.327873,
              54.884749
            ],
            [
              -159.309681,
              54.865813
            ],
            [
              -159.305864,
              54.863698
            ],
            [
              -159.272354,
              54.864204
            ],
            [
              -159.236066,
              54.87648
            ],
            [
              -159.212627,
              54.896066
            ],
            [
              -159.203228,
              54.914842
            ],
            [
              -159.202857,
              54.9255
            ],
            [
              -159.20567,
              54.927438
            ],
            [
              -159.278696,
              54.948514
            ],
            [
              -159.317681,
              54.933707
            ],
            [
              -159.324364,
              54.928329
            ],
            [
              -159.312733,
              54.918686
            ],
            [
              -159.31206,
              54.909601
            ],
            [
              -159.313528,
              54.903388
            ],
            [
              -159.320732,
              54.897269
            ]
          ]
        ],
        [
          [
            [
              -159.459551,
              54.948652
            ],
            [
              -159.457995,
              54.94573
            ],
            [
              -159.447982,
              54.941374
            ],
            [
              -159.426615,
              54.942266
            ],
            [
              -159.33177,
              54.974598
            ],
            [
              -159.330164,
              54.976378
            ],
            [
              -159.328791,
              54.980598
            ],
            [
              -159.33847,
              55.046683
            ],
            [
              -159.345276,
              55.059397
            ],
            [
              -159.381841,
              55.064032
            ],
            [
              -159.448473,
              55.064343
            ],
            [
              -159.455311,
              55.061452
            ],
            [
              -159.455434,
              55.035809
            ],
            [
              -159.417987,
              55.022013
            ],
            [
              -159.416786,
              55.000296
            ],
            [
              -159.440057,
              54.988502
            ],
            [
              -159.451251,
              54.975285
            ],
            [
              -159.459551,
              54.948652
            ]
          ]
        ],
        [
          [
            [
              -159.587158,
              54.827351
            ],
            [
              -159.602386,
              54.814026
            ],
            [
              -159.587158,
              54.775955
            ],
            [
              -159.597627,
              54.755968
            ],
            [
              -159.543376,
              54.754064
            ],
            [
              -159.51387,
              54.759775
            ],
            [
              -159.510063,
              54.78928
            ],
            [
              -159.568122,
              54.825448
            ],
            [
              -159.587158,
              54.827351
            ]
          ]
        ],
        [
          [
            [
              -159.662223,
              55.190072
            ],
            [
              -159.670305,
              55.182337
            ],
            [
              -159.654855,
              55.122309
            ],
            [
              -159.650859,
              55.06579
            ],
            [
              -159.649062,
              55.049532
            ],
            [
              -159.644029,
              55.042597
            ],
            [
              -159.638905,
              55.038745
            ],
            [
              -159.635226,
              55.037294
            ],
            [
              -159.597824,
              55.047
            ],
            [
              -159.577372,
              55.042586
            ],
            [
              -159.572365,
              55.060122
            ],
            [
              -159.535465,
              55.058735
            ],
            [
              -159.517824,
              55.044735
            ],
            [
              -159.509674,
              55.041408
            ],
            [
              -159.489589,
              55.049229
            ],
            [
              -159.48498,
              55.05405
            ],
            [
              -159.4842,
              55.057695
            ],
            [
              -159.524444,
              55.077358
            ],
            [
              -159.53363,
              55.083987
            ],
            [
              -159.530302,
              55.106194
            ],
            [
              -159.509361,
              55.130206
            ],
            [
              -159.503592,
              55.131373
            ],
            [
              -159.494772,
              55.13605
            ],
            [
              -159.493398,
              55.155055
            ],
            [
              -159.499502,
              55.173991
            ],
            [
              -159.504325,
              55.176822
            ],
            [
              -159.533457,
              55.184761
            ],
            [
              -159.51235,
              55.208353
            ],
            [
              -159.50527,
              55.222269
            ],
            [
              -159.503196,
              55.234993
            ],
            [
              -159.519196,
              55.253693
            ],
            [
              -159.521096,
              55.253393
            ],
            [
              -159.581888,
              55.214351
            ],
            [
              -159.598525,
              55.172565
            ],
            [
              -159.662223,
              55.190072
            ]
          ]
        ],
        [
          [
            [
              -159.818441,
              54.820689
            ],
            [
              -159.820345,
              54.814978
            ],
            [
              -159.802261,
              54.800701
            ],
            [
              -159.774659,
              54.794039
            ],
            [
              -159.74325,
              54.813075
            ],
            [
              -159.703275,
              54.816882
            ],
            [
              -159.694709,
              54.834966
            ],
            [
              -159.742299,
              54.84258
            ],
            [
              -159.768948,
              54.8264
            ],
            [
              -159.818441,
              54.820689
            ]
          ]
        ],
        [
          [
            [
              -159.847138,
              55.055657
            ],
            [
              -159.858785,
              55.040271
            ],
            [
              -159.830738,
              55.010843
            ],
            [
              -159.811486,
              55.016294
            ],
            [
              -159.813625,
              55.027467
            ],
            [
              -159.796513,
              55.060422
            ],
            [
              -159.752779,
              55.066139
            ],
            [
              -159.715939,
              55.09335
            ],
            [
              -159.70762,
              55.12095
            ],
            [
              -159.715701,
              55.129104
            ],
            [
              -159.731864,
              55.124212
            ],
            [
              -159.816953,
              55.088453
            ],
            [
              -159.825509,
              55.075665
            ],
            [
              -159.847138,
              55.055657
            ]
          ]
        ],
        [
          [
            [
              -160.112717,
              55.333515
            ],
            [
              -160.104156,
              55.31353
            ],
            [
              -160.046387,
              55.307107
            ],
            [
              -160.028961,
              55.31353
            ],
            [
              -160.027054,
              55.323997
            ],
            [
              -160.046097,
              55.330662
            ],
            [
              -160.062271,
              55.343033
            ],
            [
              -160.087021,
              55.343033
            ],
            [
              -160.112717,
              55.333515
            ]
          ]
        ],
        [
          [
            [
              -160.250814,
              54.929816
            ],
            [
              -160.254765,
              54.895974
            ],
            [
              -160.226967,
              54.864075
            ],
            [
              -160.200831,
              54.875282
            ],
            [
              -160.181636,
              54.902992
            ],
            [
              -160.183466,
              54.91568
            ],
            [
              -160.115775,
              54.985078
            ],
            [
              -160.080659,
              54.994425
            ],
            [
              -160.052093,
              55.005777
            ],
            [
              -159.951127,
              55.066642
            ],
            [
              -159.94541,
              55.087939
            ],
            [
              -159.951281,
              55.100644
            ],
            [
              -159.947575,
              55.105215
            ],
            [
              -159.906609,
              55.112544
            ],
            [
              -159.886476,
              55.107087
            ],
            [
              -159.886109,
              55.102558
            ],
            [
              -159.86858,
              55.094888
            ],
            [
              -159.855444,
              55.100758
            ],
            [
              -159.83089,
              55.126467
            ],
            [
              -159.82371,
              55.14413
            ],
            [
              -159.816419,
              55.178051
            ],
            [
              -159.846264,
              55.180834
            ],
            [
              -159.860786,
              55.177086
            ],
            [
              -159.861308,
              55.171748
            ],
            [
              -159.859568,
              55.164912
            ],
            [
              -159.860891,
              55.149337
            ],
            [
              -159.884997,
              55.145598
            ],
            [
              -159.901569,
              55.156858
            ],
            [
              -159.905365,
              55.164689
            ],
            [
              -159.895326,
              55.217872
            ],
            [
              -159.886523,
              55.229149
            ],
            [
              -159.866624,
              55.231202
            ],
            [
              -159.860991,
              55.227884
            ],
            [
              -159.855302,
              55.230378
            ],
            [
              -159.844622,
              55.243828
            ],
            [
              -159.843859,
              55.249367
            ],
            [
              -159.848619,
              55.267548
            ],
            [
              -159.870591,
              55.284889
            ],
            [
              -159.87969,
              55.290183
            ],
            [
              -159.889174,
              55.287138
            ],
            [
              -159.93124,
              55.22006
            ],
            [
              -159.95435,
              55.189291
            ],
            [
              -159.983499,
              55.180379
            ],
            [
              -159.994027,
              55.185247
            ],
            [
              -160.002155,
              55.19448
            ],
            [
              -160.025257,
              55.203914
            ],
            [
              -160.052941,
              55.203035
            ],
            [
              -160.061469,
              55.200378
            ],
            [
              -160.047358,
              55.180879
            ],
            [
              -160.0179,
              55.15613
            ],
            [
              -160.004129,
              55.134482
            ],
            [
              -160.00517,
              55.129378
            ],
            [
              -160.01222,
              55.122946
            ],
            [
              -160.03033,
              55.116109
            ],
            [
              -160.055642,
              55.11158
            ],
            [
              -160.057797,
              55.115353
            ],
            [
              -160.057033,
              55.118488
            ],
            [
              -160.05282,
              55.119373
            ],
            [
              -160.052545,
              55.121716
            ],
            [
              -160.059599,
              55.133663
            ],
            [
              -160.077308,
              55.146495
            ],
            [
              -160.109864,
              55.160777
            ],
            [
              -160.187261,
              55.118376
            ],
            [
              -160.191392,
              55.108574
            ],
            [
              -160.174366,
              55.052577
            ],
            [
              -160.133416,
              55.043947
            ],
            [
              -160.094288,
              55.052996
            ],
            [
              -160.087574,
              55.049967
            ],
            [
              -160.077872,
              55.0376
            ],
            [
              -160.082168,
              55.033578
            ],
            [
              -160.106837,
              55.027002
            ],
            [
              -160.132168,
              55.013743
            ],
            [
              -160.177607,
              54.981446
            ],
            [
              -160.223411,
              54.939124
            ],
            [
              -160.244253,
              54.933532
            ],
            [
              -160.250814,
              54.929816
            ]
          ]
        ],
        [
          [
            [
              -160.347609,
              55.426187
            ],
            [
              -160.349526,
              55.420477
            ],
            [
              -160.339858,
              55.409692
            ],
            [
              -160.321132,
              55.393677
            ],
            [
              -160.308921,
              55.393174
            ],
            [
              -160.27997,
              55.395905
            ],
            [
              -160.20361,
              55.391739
            ],
            [
              -160.154038,
              55.377518
            ],
            [
              -160.147993,
              55.377576
            ],
            [
              -160.142505,
              55.383491
            ],
            [
              -160.141834,
              55.387154
            ],
            [
              -160.137032,
              55.450709
            ],
            [
              -160.21178,
              55.455862
            ],
            [
              -160.227504,
              55.46034
            ],
            [
              -160.260565,
              55.463674
            ],
            [
              -160.266834,
              55.462789
            ],
            [
              -160.323237,
              55.444633
            ],
            [
              -160.347609,
              55.426187
            ]
          ]
        ],
        [
          [
            [
              -160.856621,
              55.318488
            ],
            [
              -160.841221,
              55.293957
            ],
            [
              -160.841917,
              55.20444
            ],
            [
              -160.819487,
              55.187457
            ],
            [
              -160.807558,
              55.168161
            ],
            [
              -160.807468,
              55.155579
            ],
            [
              -160.815862,
              55.141556
            ],
            [
              -160.824468,
              55.120153
            ],
            [
              -160.821381,
              55.117851
            ],
            [
              -160.806009,
              55.12567
            ],
            [
              -160.794198,
              55.134399
            ],
            [
              -160.765229,
              55.176716
            ],
            [
              -160.767393,
              55.185399
            ],
            [
              -160.756587,
              55.195143
            ],
            [
              -160.690293,
              55.210474
            ],
            [
              -160.688372,
              55.195588
            ],
            [
              -160.675871,
              55.173622
            ],
            [
              -160.655577,
              55.160261
            ],
            [
              -160.525226,
              55.129871
            ],
            [
              -160.496306,
              55.166399
            ],
            [
              -160.486511,
              55.181951
            ],
            [
              -160.486174,
              55.193617
            ],
            [
              -160.527617,
              55.256374
            ],
            [
              -160.550759,
              55.264302
            ],
            [
              -160.565929,
              55.273137
            ],
            [
              -160.580088,
              55.302503
            ],
            [
              -160.580668,
              55.307196
            ],
            [
              -160.57969,
              55.314292
            ],
            [
              -160.564427,
              55.332504
            ],
            [
              -160.534943,
              55.343537
            ],
            [
              -160.528864,
              55.343501
            ],
            [
              -160.506927,
              55.32773
            ],
            [
              -160.526945,
              55.319044
            ],
            [
              -160.518148,
              55.309102
            ],
            [
              -160.481536,
              55.291779
            ],
            [
              -160.475493,
              55.28923
            ],
            [
              -160.468262,
              55.288925
            ],
            [
              -160.457765,
              55.291893
            ],
            [
              -160.449799,
              55.297081
            ],
            [
              -160.441896,
              55.298264
            ],
            [
              -160.390421,
              55.286576
            ],
            [
              -160.380472,
              55.272803
            ],
            [
              -160.378887,
              55.266692
            ],
            [
              -160.372603,
              55.25991
            ],
            [
              -160.341217,
              55.251799
            ],
            [
              -160.330722,
              55.261045
            ],
            [
              -160.30655,
              55.303275
            ],
            [
              -160.317665,
              55.338533
            ],
            [
              -160.326463,
              55.353189
            ],
            [
              -160.333692,
              55.360139
            ],
            [
              -160.344369,
              55.362962
            ],
            [
              -160.361758,
              55.36323
            ],
            [
              -160.40335,
              55.346299
            ],
            [
              -160.408614,
              55.341677
            ],
            [
              -160.425658,
              55.338883
            ],
            [
              -160.518955,
              55.361552
            ],
            [
              -160.522307,
              55.364367
            ],
            [
              -160.522399,
              55.370371
            ],
            [
              -160.522063,
              55.374369
            ],
            [
              -160.517513,
              55.379378
            ],
            [
              -160.545227,
              55.387911
            ],
            [
              -160.567384,
              55.390078
            ],
            [
              -160.572716,
              55.388978
            ],
            [
              -160.614256,
              55.348019
            ],
            [
              -160.651011,
              55.34365
            ],
            [
              -160.646156,
              55.357689
            ],
            [
              -160.646214,
              55.383142
            ],
            [
              -160.649234,
              55.38878
            ],
            [
              -160.665927,
              55.399025
            ],
            [
              -160.687442,
              55.402198
            ],
            [
              -160.710298,
              55.403075
            ],
            [
              -160.7778,
              55.388639
            ],
            [
              -160.797147,
              55.381521
            ],
            [
              -160.840251,
              55.339777
            ],
            [
              -160.856621,
              55.318488
            ]
          ]
        ],
        [
          [
            [
              -161.426005,
              55.216563
            ],
            [
              -161.43933,
              55.198479
            ],
            [
              -161.409825,
              55.180395
            ],
            [
              -161.344152,
              55.158504
            ],
            [
              -161.329875,
              55.163263
            ],
            [
              -161.325116,
              55.184202
            ],
            [
              -161.329875,
              55.219418
            ],
            [
              -161.399355,
              55.224177
            ],
            [
              -161.426005,
              55.216563
            ]
          ]
        ],
        [
          [
            [
              -161.693457,
              55.217515
            ],
            [
              -161.691553,
              55.198479
            ],
            [
              -161.629687,
              55.196576
            ],
            [
              -161.560207,
              55.207045
            ],
            [
              -161.527846,
              55.250827
            ],
            [
              -161.540219,
              55.258442
            ],
            [
              -161.617314,
              55.241309
            ],
            [
              -161.656337,
              55.244165
            ],
            [
              -161.68489,
              55.232743
            ],
            [
              -161.693457,
              55.217515
            ]
          ]
        ],
        [
          [
            [
              -161.90361,
              55.116947
            ],
            [
              -161.924375,
              55.109929
            ],
            [
              -161.87886,
              55.085537
            ],
            [
              -161.865044,
              55.099101
            ],
            [
              -161.839252,
              55.118114
            ],
            [
              -161.819869,
              55.113965
            ],
            [
              -161.816482,
              55.111319
            ],
            [
              -161.814984,
              55.098639
            ],
            [
              -161.791606,
              55.077307
            ],
            [
              -161.764169,
              55.059509
            ],
            [
              -161.737922,
              55.054054
            ],
            [
              -161.690346,
              55.0785
            ],
            [
              -161.678171,
              55.087741
            ],
            [
              -161.678537,
              55.092829
            ],
            [
              -161.674539,
              55.095912
            ],
            [
              -161.654918,
              55.103244
            ],
            [
              -161.598303,
              55.081341
            ],
            [
              -161.606482,
              55.074208
            ],
            [
              -161.606359,
              55.070264
            ],
            [
              -161.591069,
              55.060818
            ],
            [
              -161.582584,
              55.058224
            ],
            [
              -161.565036,
              55.058874
            ],
            [
              -161.557315,
              55.061193
            ],
            [
              -161.550357,
              55.065734
            ],
            [
              -161.548924,
              55.080115
            ],
            [
              -161.549901,
              55.082564
            ],
            [
              -161.570523,
              55.100493
            ],
            [
              -161.576643,
              55.103831
            ],
            [
              -161.608634,
              55.116906
            ],
            [
              -161.651563,
              55.130916
            ],
            [
              -161.663618,
              55.13026
            ],
            [
              -161.678389,
              55.131747
            ],
            [
              -161.697097,
              55.137133
            ],
            [
              -161.718614,
              55.154166
            ],
            [
              -161.73781,
              55.161935
            ],
            [
              -161.82784,
              55.178473
            ],
            [
              -161.850057,
              55.17526
            ],
            [
              -161.888936,
              55.160724
            ],
            [
              -161.900685,
              55.142139
            ],
            [
              -161.90361,
              55.116947
            ]
          ]
        ],
        [
          [
            [
              -162.435473,
              54.929249
            ],
            [
              -162.437501,
              54.927627
            ],
            [
              -162.425244,
              54.885021
            ],
            [
              -162.41737,
              54.877491
            ],
            [
              -162.349315,
              54.836049
            ],
            [
              -162.321094,
              54.827928
            ],
            [
              -162.30058,
              54.832594
            ],
            [
              -162.282944,
              54.841216
            ],
            [
              -162.275316,
              54.845565
            ],
            [
              -162.236806,
              54.88163
            ],
            [
              -162.232962,
              54.890984
            ],
            [
              -162.235675,
              54.962601
            ],
            [
              -162.249682,
              54.9759
            ],
            [
              -162.255031,
              54.978353
            ],
            [
              -162.266743,
              54.982133
            ],
            [
              -162.326811,
              54.98533
            ],
            [
              -162.337431,
              54.981636
            ],
            [
              -162.435473,
              54.929249
            ]
          ]
        ],
        [
          [
            [
              -162.834879,
              54.450482
            ],
            [
              -162.861736,
              54.424771
            ],
            [
              -162.808259,
              54.391156
            ],
            [
              -162.783778,
              54.376208
            ],
            [
              -162.760247,
              54.372193
            ],
            [
              -162.764525,
              54.38825
            ],
            [
              -162.719842,
              54.400981
            ],
            [
              -162.695836,
              54.387696
            ],
            [
              -162.608608,
              54.369147
            ],
            [
              -162.546811,
              54.377038
            ],
            [
              -162.490481,
              54.349065
            ],
            [
              -162.46695,
              54.342692
            ],
            [
              -162.4496,
              54.362223
            ],
            [
              -162.431061,
              54.371085
            ],
            [
              -162.388754,
              54.367623
            ],
            [
              -162.361421,
              54.390188
            ],
            [
              -162.400162,
              54.407621
            ],
            [
              -162.425119,
              54.414399
            ],
            [
              -162.474081,
              54.418963
            ],
            [
              -162.518527,
              54.414399
            ],
            [
              -162.556556,
              54.425186
            ],
            [
              -162.585315,
              54.447995
            ],
            [
              -162.636654,
              54.457253
            ],
            [
              -162.670167,
              54.461121
            ],
            [
              -162.743134,
              54.478663
            ],
            [
              -162.799702,
              54.49178
            ],
            [
              -162.829174,
              54.494679
            ],
            [
              -162.835116,
              54.482805
            ],
            [
              -162.834879,
              54.450482
            ]
          ]
        ],
        [
          [
            [
              -163.170072,
              55.428782
            ],
            [
              -163.174192,
              55.41569
            ],
            [
              -163.160597,
              55.402827
            ],
            [
              -163.138489,
              55.395653
            ],
            [
              -163.124208,
              55.398694
            ],
            [
              -163.117342,
              55.402515
            ],
            [
              -163.117342,
              55.408518
            ],
            [
              -163.115832,
              55.413118
            ],
            [
              -163.117479,
              55.42021
            ],
            [
              -163.120363,
              55.428549
            ],
            [
              -163.133958,
              55.435015
            ],
            [
              -163.150161,
              55.433613
            ],
            [
              -163.170072,
              55.428782
            ]
          ]
        ],
        [
          [
            [
              -164.865189,
              54.206962
            ],
            [
              -164.883134,
              54.196187
            ],
            [
              -164.839639,
              54.178664
            ],
            [
              -164.817653,
              54.182141
            ],
            [
              -164.764413,
              54.209812
            ],
            [
              -164.7637,
              54.223153
            ],
            [
              -164.796975,
              54.229059
            ],
            [
              -164.824183,
              54.225527
            ],
            [
              -164.865189,
              54.206962
            ]
          ]
        ],
        [
          [
            [
              -164.948789,
              54.579877
            ],
            [
              -164.949781,
              54.575697
            ],
            [
              -164.944636,
              54.532903
            ],
            [
              -164.936122,
              54.521253
            ],
            [
              -164.910059,
              54.507542
            ],
            [
              -164.904077,
              54.499195
            ],
            [
              -164.877373,
              54.449908
            ],
            [
              -164.876075,
              54.443495
            ],
            [
              -164.844931,
              54.417583
            ],
            [
              -164.789357,
              54.402012
            ],
            [
              -164.743977,
              54.394216
            ],
            [
              -164.640457,
              54.391166
            ],
            [
              -164.601607,
              54.402451
            ],
            [
              -164.582778,
              54.405702
            ],
            [
              -164.51997,
              54.414652
            ],
            [
              -164.499034,
              54.414225
            ],
            [
              -164.456554,
              54.419856
            ],
            [
              -164.41682,
              54.431713
            ],
            [
              -164.352704,
              54.465023
            ],
            [
              -164.33653,
              54.480977
            ],
            [
              -164.336042,
              54.484509
            ],
            [
              -164.341474,
              54.495266
            ],
            [
              -164.337538,
              54.524259
            ],
            [
              -164.331404,
              54.530431
            ],
            [
              -164.257585,
              54.572722
            ],
            [
              -164.23247,
              54.585494
            ],
            [
              -164.179617,
              54.599188
            ],
            [
              -164.084894,
              54.620131
            ],
            [
              -163.966307,
              54.631681
            ],
            [
              -163.952391,
              54.630461
            ],
            [
              -163.861206,
              54.632911
            ],
            [
              -163.80359,
              54.636498
            ],
            [
              -163.747316,
              54.635011
            ],
            [
              -163.670838,
              54.627825
            ],
            [
              -163.585967,
              54.611644
            ],
            [
              -163.581481,
              54.616863
            ],
            [
              -163.572383,
              54.623211
            ],
            [
              -163.488861,
              54.65511
            ],
            [
              -163.472016,
              54.656468
            ],
            [
              -163.439361,
              54.655928
            ],
            [
              -163.424375,
              54.656207
            ],
            [
              -163.416294,
              54.664456
            ],
            [
              -163.409995,
              54.693657
            ],
            [
              -163.418433,
              54.708902
            ],
            [
              -163.428377,
              54.714819
            ],
            [
              -163.423067,
              54.720426
            ],
            [
              -163.39197,
              54.74198
            ],
            [
              -163.380618,
              54.746176
            ],
            [
              -163.364626,
              54.749464
            ],
            [
              -163.354492,
              54.750357
            ],
            [
              -163.340438,
              54.748865
            ],
            [
              -163.327164,
              54.736904
            ],
            [
              -163.317996,
              54.719938
            ],
            [
              -163.280633,
              54.695367
            ],
            [
              -163.247422,
              54.692283
            ],
            [
              -163.223179,
              54.676895
            ],
            [
              -163.213653,
              54.677201
            ],
            [
              -163.208775,
              54.693136
            ],
            [
              -163.194952,
              54.699025
            ],
            [
              -163.185401,
              54.700398
            ],
            [
              -163.140925,
              54.694829
            ],
            [
              -163.126443,
              54.69503
            ],
            [
              -163.129008,
              54.683326
            ],
            [
              -163.149142,
              54.673047
            ],
            [
              -163.153539,
              54.669267
            ],
            [
              -163.152707,
              54.665899
            ],
            [
              -163.125738,
              54.66818
            ],
            [
              -163.096744,
              54.661597
            ],
            [
              -163.059085,
              54.661072
            ],
            [
              -163.05097,
              54.672263
            ],
            [
              -163.057228,
              54.688101
            ],
            [
              -163.079977,
              54.68562
            ],
            [
              -163.096139,
              54.705469
            ],
            [
              -163.107558,
              54.732752
            ],
            [
              -163.144089,
              54.761499
            ],
            [
              -163.184295,
              54.774912
            ],
            [
              -163.188853,
              54.773717
            ],
            [
              -163.219765,
              54.755072
            ],
            [
              -163.228391,
              54.753513
            ],
            [
              -163.287626,
              54.751495
            ],
            [
              -163.313259,
              54.753299
            ],
            [
              -163.342655,
              54.765104
            ],
            [
              -163.372806,
              54.790936
            ],
            [
              -163.373207,
              54.800841
            ],
            [
              -163.352997,
              54.810174
            ],
            [
              -163.299809,
              54.829232
            ],
            [
              -163.214398,
              54.847487
            ],
            [
              -163.14958,
              54.885906
            ],
            [
              -163.065602,
              54.926172
            ],
            [
              -163.036062,
              54.942544
            ],
            [
              -163.050467,
              54.969071
            ],
            [
              -163.067008,
              54.979302
            ],
            [
              -163.148615,
              55.014023
            ],
            [
              -163.17483,
              55.0131
            ],
            [
              -163.189447,
              55.016678
            ],
            [
              -163.213281,
              55.026138
            ],
            [
              -163.219018,
              55.030281
            ],
            [
              -163.226313,
              55.042694
            ],
            [
              -163.225092,
              55.049683
            ],
            [
              -163.213009,
              55.066742
            ],
            [
              -163.188428,
              55.090903
            ],
            [
              -163.165036,
              55.099214
            ],
            [
              -163.111507,
              55.109705
            ],
            [
              -163.079006,
              55.111652
            ],
            [
              -163.071468,
              55.110477
            ],
            [
              -163.051631,
              55.103267
            ],
            [
              -163.00155,
              55.080043
            ],
            [
              -162.964897,
              55.042201
            ],
            [
              -162.957826,
              55.031826
            ],
            [
              -162.958975,
              55.020151
            ],
            [
              -162.965872,
              55.017374
            ],
            [
              -162.970632,
              55.001039
            ],
            [
              -162.913684,
              54.950273
            ],
            [
              -162.881639,
              54.934785
            ],
            [
              -162.845475,
              54.926989
            ],
            [
              -162.834245,
              54.926851
            ],
            [
              -162.770983,
              54.932736
            ],
            [
              -162.708453,
              54.95848
            ],
            [
              -162.705096,
              54.96601
            ],
            [
              -162.707203,
              54.972023
            ],
            [
              -162.720404,
              54.980223
            ],
            [
              -162.716177,
              54.986679
            ],
            [
              -162.707083,
              54.991159
            ],
            [
              -162.688131,
              54.996126
            ],
            [
              -162.646472,
              54.997163
            ],
            [
              -162.615159,
              54.987841
            ],
            [
              -162.587967,
              54.97201
            ],
            [
              -162.569289,
              54.97124
            ],
            [
              -162.569292,
              55.015874
            ],
            [
              -162.599812,
              55.054806
            ],
            [
              -162.618918,
              55.097096
            ],
            [
              -162.604454,
              55.126028
            ],
            [
              -162.595603,
              55.124846
            ],
            [
              -162.585533,
              55.1286
            ],
            [
              -162.582908,
              55.13024
            ],
            [
              -162.579765,
              55.136939
            ],
            [
              -162.614497,
              55.174735
            ],
            [
              -162.638791,
              55.19477
            ],
            [
              -162.644734,
              55.197115
            ],
            [
              -162.668346,
              55.193445
            ],
            [
              -162.692309,
              55.197313
            ],
            [
              -162.711128,
              55.211267
            ],
            [
              -162.718077,
              55.219911
            ],
            [
              -162.714607,
              55.231611
            ],
            [
              -162.702851,
              55.252775
            ],
            [
              -162.682405,
              55.27645
            ],
            [
              -162.66196,
              55.294295
            ],
            [
              -162.649173,
              55.299118
            ],
            [
              -162.626101,
              55.304085
            ],
            [
              -162.584872,
              55.298386
            ],
            [
              -162.513121,
              55.252374
            ],
            [
              -162.510435,
              55.250177
            ],
            [
              -162.499019,
              55.21377
            ],
            [
              -162.49792,
              55.199052
            ],
            [
              -162.49447,
              55.183915
            ],
            [
              -162.48098,
              55.161271
            ],
            [
              -162.445182,
              55.151521
            ],
            [
              -162.406191,
              55.120498
            ],
            [
              -162.410574,
              55.105614
            ],
            [
              -162.4168,
              55.104096
            ],
            [
              -162.424796,
              55.104813
            ],
            [
              -162.437368,
              55.112122
            ],
            [
              -162.442556,
              55.118226
            ],
            [
              -162.453451,
              55.123948
            ],
            [
              -162.460958,
              55.12584
            ],
            [
              -162.506887,
              55.118927
            ],
            [
              -162.520986,
              55.115417
            ],
            [
              -162.521688,
              55.104011
            ],
            [
              -162.512104,
              55.086227
            ],
            [
              -162.489735,
              55.064849
            ],
            [
              -162.471364,
              55.051932
            ],
            [
              -162.41351,
              55.03656
            ],
            [
              -162.361969,
              55.042679
            ],
            [
              -162.300378,
              55.042927
            ],
            [
              -162.299619,
              55.040152
            ],
            [
              -162.292511,
              55.033429
            ],
            [
              -162.280512,
              55.026207
            ],
            [
              -162.267754,
              55.021553
            ],
            [
              -162.2535,
              55.020454
            ],
            [
              -162.247946,
              55.020439
            ],
            [
              -162.219326,
              55.028975
            ],
            [
              -162.189247,
              55.06026
            ],
            [
              -162.190348,
              55.066981
            ],
            [
              -162.2068,
              55.082391
            ],
            [
              -162.223528,
              55.102211
            ],
            [
              -162.224047,
              55.108658
            ],
            [
              -162.218192,
              55.118903
            ],
            [
              -162.177427,
              55.154403
            ],
            [
              -162.141084,
              55.157339
            ],
            [
              -162.126369,
              55.153408
            ],
            [
              -162.119033,
              55.141116
            ],
            [
              -162.131878,
              55.122776
            ],
            [
              -162.11874,
              55.102911
            ],
            [
              -162.053281,
              55.074212
            ],
            [
              -161.960866,
              55.106734
            ],
            [
              -161.956595,
              55.112174
            ],
            [
              -161.949882,
              55.126686
            ],
            [
              -161.966974,
              55.154831
            ],
            [
              -162.001711,
              55.169236
            ],
            [
              -162.046242,
              55.225605
            ],
            [
              -162.045694,
              55.232775
            ],
            [
              -162.041236,
              55.236806
            ],
            [
              -162.029636,
              55.239492
            ],
            [
              -161.978788,
              55.236131
            ],
            [
              -161.957455,
              55.227999
            ],
            [
              -161.919519,
              55.208209
            ],
            [
              -161.903407,
              55.204941
            ],
            [
              -161.878076,
              55.223599
            ],
            [
              -161.875238,
              55.227224
            ],
            [
              -161.875759,
              55.232592
            ],
            [
              -161.879542,
              55.240804
            ],
            [
              -161.875606,
              55.249921
            ],
            [
              -161.863339,
              55.266989
            ],
            [
              -161.853418,
              55.277634
            ],
            [
              -161.845473,
              55.281249
            ],
            [
              -161.833891,
              55.2844
            ],
            [
              -161.827543,
              55.287872
            ],
            [
              -161.720096,
              55.37669
            ],
            [
              -161.686495,
              55.408041
            ],
            [
              -161.688357,
              55.41638
            ],
            [
              -161.696719,
              55.423307
            ],
            [
              -161.700069,
              55.51439
            ],
            [
              -161.69886,
              55.5194
            ],
            [
              -161.612926,
              55.606158
            ],
            [
              -161.602825,
              55.613811
            ],
            [
              -161.587047,
              55.62006
            ],
            [
              -161.526162,
              55.630498
            ],
            [
              -161.482064,
              55.633979
            ],
            [
              -161.416235,
              55.632324
            ],
            [
              -161.392613,
              55.628221
            ],
            [
              -161.363378,
              55.618478
            ],
            [
              -161.35767,
              55.612603
            ],
            [
              -161.355686,
              55.606378
            ],
            [
              -161.367405,
              55.579484
            ],
            [
              -161.376102,
              55.569794
            ],
            [
              -161.469271,
              55.49683
            ],
            [
              -161.477114,
              55.485195
            ],
            [
              -161.471468,
              55.478588
            ],
            [
              -161.478303,
              55.4406
            ],
            [
              -161.484588,
              55.417994
            ],
            [
              -161.496123,
              55.396967
            ],
            [
              -161.509306,
              55.390626
            ],
            [
              -161.514211,
              55.385254
            ],
            [
              -161.507657,
              55.362786
            ],
            [
              -161.486114,
              55.359322
            ],
            [
              -161.460392,
              55.35907
            ],
            [
              -161.445196,
              55.368103
            ],
            [
              -161.364577,
              55.384194
            ],
            [
              -161.34608,
              55.385782
            ],
            [
              -161.314949,
              55.379231
            ],
            [
              -161.311989,
              55.372836
            ],
            [
              -161.317545,
              55.362758
            ],
            [
              -161.325325,
              55.359855
            ],
            [
              -161.280675,
              55.354038
            ],
            [
              -161.231535,
              55.357452
            ],
            [
              -161.013662,
              55.431002
            ],
            [
              -160.997335,
              55.440265
            ],
            [
              -160.982717,
              55.454326
            ],
            [
              -160.977376,
              55.461185
            ],
            [
              -160.979298,
              55.466274
            ],
            [
              -160.976551,
              55.472736
            ],
            [
              -160.944265,
              55.507825
            ],
            [
              -160.922934,
              55.5193
            ],
            [
              -160.909625,
              55.52414
            ],
            [
              -160.86538,
              55.526968
            ],
            [
              -160.849145,
              55.523916
            ],
            [
              -160.828273,
              55.516111
            ],
            [
              -160.82081,
              55.507974
            ],
            [
              -160.821773,
              55.506216
            ],
            [
              -160.836023,
              55.497259
            ],
            [
              -160.843407,
              55.489782
            ],
            [
              -160.836725,
              55.473135
            ],
            [
              -160.795988,
              55.454946
            ],
            [
              -160.781401,
              55.45178
            ],
            [
              -160.666917,
              55.459776
            ],
            [
              -160.651523,
              55.474174
            ],
            [
              -160.646304,
              55.492851
            ],
            [
              -160.647464,
              55.500862
            ],
            [
              -160.654117,
              55.512596
            ],
            [
              -160.660117,
              55.518475
            ],
            [
              -160.73215,
              55.523596
            ],
            [
              -160.771433,
              55.52943
            ],
            [
              -160.77295,
              55.538998
            ],
            [
              -160.766237,
              55.547559
            ],
            [
              -160.75104,
              55.552907
            ],
            [
              -160.737095,
              55.555448
            ],
            [
              -160.706883,
              55.556066
            ],
            [
              -160.666966,
              55.544417
            ],
            [
              -160.652775,
              55.548668
            ],
            [
              -160.638371,
              55.557426
            ],
            [
              -160.615305,
              55.575516
            ],
            [
              -160.595771,
              55.57554
            ],
            [
              -160.580083,
              55.564385
            ],
            [
              -160.554173,
              55.522965
            ],
            [
              -160.544224,
              55.502351
            ],
            [
              -160.536654,
              55.474938
            ],
            [
              -160.521335,
              55.47442
            ],
            [
              -160.501346,
              55.478518
            ],
            [
              -160.481633,
              55.489068
            ],
            [
              -160.462745,
              55.506654
            ],
            [
              -160.459815,
              55.514986
            ],
            [
              -160.465186,
              55.527361
            ],
            [
              -160.464301,
              55.533243
            ],
            [
              -160.448277,
              55.559049
            ],
            [
              -160.435859,
              55.573692
            ],
            [
              -160.392587,
              55.602771
            ],
            [
              -160.433022,
              55.639979
            ],
            [
              -160.433602,
              55.648975
            ],
            [
              -160.429727,
              55.658046
            ],
            [
              -160.421853,
              55.662701
            ],
            [
              -160.416452,
              55.665302
            ],
            [
              -160.410823,
              55.66538
            ],
            [
              -160.353494,
              55.649731
            ],
            [
              -160.325419,
              55.644207
            ],
            [
              -160.279827,
              55.641384
            ],
            [
              -160.185712,
              55.658644
            ],
            [
              -160.130445,
              55.681419
            ],
            [
              -160.058443,
              55.721734
            ],
            [
              -160.049417,
              55.757588
            ],
            [
              -160.051945,
              55.760594
            ],
            [
              -160.052525,
              55.76643
            ],
            [
              -160.048711,
              55.772061
            ],
            [
              -160.026282,
              55.792295
            ],
            [
              -160.010322,
              55.797087
            ],
            [
              -159.937089,
              55.803306
            ],
            [
              -159.892319,
              55.785096
            ],
            [
              -159.875994,
              55.784608
            ],
            [
              -159.862484,
              55.787629
            ],
            [
              -159.847359,
              55.80253
            ],
            [
              -159.85075,
              55.824076
            ],
            [
              -159.858456,
              55.841793
            ],
            [
              -159.853255,
              55.847162
            ],
            [
              -159.838981,
              55.852412
            ],
            [
              -159.81107,
              55.85657
            ],
            [
              -159.712816,
              55.846392
            ],
            [
              -159.643739,
              55.830424
            ],
            [
              -159.607973,
              55.8129
            ],
            [
              -159.602148,
              55.805004
            ],
            [
              -159.624884,
              55.804694
            ],
            [
              -159.627482,
              55.803248
            ],
            [
              -159.673191,
              55.750961
            ],
            [
              -159.676761,
              55.737357
            ],
            [
              -159.680635,
              55.68134
            ],
            [
              -159.679201,
              55.655895
            ],
            [
              -159.644656,
              55.652469
            ],
            [
              -159.635866,
              55.644398
            ],
            [
              -159.626772,
              55.629412
            ],
            [
              -159.639619,
              55.617915
            ],
            [
              -159.667511,
              55.614825
            ],
            [
              -159.673432,
              55.61735
            ],
            [
              -159.72415,
              55.614549
            ],
            [
              -159.735196,
              55.610933
            ],
            [
              -159.743282,
              55.603624
            ],
            [
              -159.744495,
              55.600018
            ],
            [
              -159.733899,
              55.569985
            ],
            [
              -159.729333,
              55.56965
            ],
            [
              -159.696713,
              55.573306
            ],
            [
              -159.61777,
              55.595798
            ],
            [
              -159.556676333447,
              55.638446264729296
            ],
            [
              -159.561451,
              55.638443
            ],
            [
              -159.56136,
              55.887197
            ],
            [
              -159.867971,
              55.887193
            ],
            [
              -159.86797,
              55.973918
            ],
            [
              -159.806406,
              55.973919
            ],
            [
              -159.806389,
              56.320805
            ],
            [
              -159.900133,
              56.320803
            ],
            [
              -159.900149,
              56.494239
            ],
            [
              -159.588036,
              56.494245
            ],
            [
              -159.588041,
              56.580961
            ],
            [
              -159.275928,
              56.580967
            ],
            [
              -159.275932,
              56.667682
            ],
            [
              -159.051089,
              56.667687
            ],
            [
              -159.051094,
              56.754401
            ],
            [
              -158.893607,
              56.754404
            ],
            [
              -158.893551103795,
              56.8059623912595
            ],
            [
              -158.91073,
              56.814797
            ],
            [
              -158.933589,
              56.827905
            ],
            [
              -158.953543,
              56.843418
            ],
            [
              -158.955338,
              56.849762
            ],
            [
              -158.957471,
              56.851184
            ],
            [
              -159.018304,
              56.815094
            ],
            [
              -159.038354,
              56.806006
            ],
            [
              -159.093468,
              56.783704
            ],
            [
              -159.106652,
              56.781126
            ],
            [
              -159.156455,
              56.763324
            ],
            [
              -159.263113,
              56.723321
            ],
            [
              -159.279894,
              56.715667
            ],
            [
              -159.30908,
              56.710457
            ],
            [
              -159.357391,
              56.693089
            ],
            [
              -159.388765,
              56.690479
            ],
            [
              -159.446973,
              56.664651
            ],
            [
              -159.534961,
              56.626529
            ],
            [
              -159.636156,
              56.59739
            ],
            [
              -159.815477,
              56.548941
            ],
            [
              -159.828049,
              56.543935
            ],
            [
              -159.938337,
              56.474192
            ],
            [
              -159.976758,
              56.453951
            ],
            [
              -160.001477,
              56.442201
            ],
            [
              -160.082592,
              56.411094
            ],
            [
              -160.146252,
              56.400176
            ],
            [
              -160.196329,
              56.37255
            ],
            [
              -160.208383,
              56.358022
            ],
            [
              -160.222878,
              56.346868
            ],
            [
              -160.274604,
              56.317151
            ],
            [
              -160.315896,
              56.302227
            ],
            [
              -160.340249,
              56.291271
            ],
            [
              -160.357156,
              56.279582
            ],
            [
              -160.383094,
              56.251352
            ],
            [
              -160.396338,
              56.231775
            ],
            [
              -160.411381,
              56.194138
            ],
            [
              -160.451417,
              56.125564
            ],
            [
              -160.482208,
              56.085234
            ],
            [
              -160.488708,
              56.077214
            ],
            [
              -160.568356,
              56.004062
            ],
            [
              -160.589569,
              55.983048
            ],
            [
              -160.576451,
              55.979291
            ],
            [
              -160.55197,
              55.991789
            ],
            [
              -160.534541,
              55.989498
            ],
            [
              -160.529292,
              55.986103
            ],
            [
              -160.526362,
              55.982433
            ],
            [
              -160.527094,
              55.973011
            ],
            [
              -160.533685,
              55.95995
            ],
            [
              -160.535759,
              55.939617
            ],
            [
              -160.486594,
              55.924168
            ],
            [
              -160.420735,
              55.90862
            ],
            [
              -160.380573,
              55.889456
            ],
            [
              -160.325637,
              55.867858
            ],
            [
              -160.273176,
              55.856881
            ],
            [
              -160.241798,
              55.850352
            ],
            [
              -160.222546,
              55.832069
            ],
            [
              -160.272533,
              55.831673
            ],
            [
              -160.317826,
              55.818983
            ],
            [
              -160.315655,
              55.814544
            ],
            [
              -160.293498,
              55.801788
            ],
            [
              -160.26893,
              55.784278
            ],
            [
              -160.264568,
              55.775723
            ],
            [
              -160.277382,
              55.765861
            ],
            [
              -160.293924,
              55.765556
            ],
            [
              -160.342876,
              55.778166
            ],
            [
              -160.385878,
              55.796445
            ],
            [
              -160.438735,
              55.789608
            ],
            [
              -160.468544,
              55.790667
            ],
            [
              -160.479355,
              55.822361
            ],
            [
              -160.477892,
              55.841099
            ],
            [
              -160.494678,
              55.864193
            ],
            [
              -160.508433,
              55.869379
            ],
            [
              -160.532582,
              55.869891
            ],
            [
              -160.550343,
              55.867549
            ],
            [
              -160.564014,
              55.863719
            ],
            [
              -160.639088,
              55.8583
            ],
            [
              -160.697591,
              55.862396
            ],
            [
              -160.734182,
              55.870995
            ],
            [
              -160.769468,
              55.88379
            ],
            [
              -160.793215,
              55.88596
            ],
            [
              -160.791893,
              55.872687
            ],
            [
              -160.769155,
              55.858268
            ],
            [
              -160.757705,
              55.785841
            ],
            [
              -160.751236,
              55.779364
            ],
            [
              -160.695227,
              55.755075
            ],
            [
              -160.675794,
              55.751411
            ],
            [
              -160.663037,
              55.745491
            ],
            [
              -160.655468,
              55.739868
            ],
            [
              -160.65556,
              55.730041
            ],
            [
              -160.661205,
              55.723427
            ],
            [
              -160.668102,
              55.723556
            ],
            [
              -160.730726,
              55.747664
            ],
            [
              -160.765228,
              55.757174
            ],
            [
              -160.806014,
              55.738241
            ],
            [
              -160.930591,
              55.814358
            ],
            [
              -160.940845,
              55.822529
            ],
            [
              -160.9464,
              55.834881
            ],
            [
              -160.95156,
              55.852772
            ],
            [
              -160.931544,
              55.872494
            ],
            [
              -160.930356,
              55.89569
            ],
            [
              -160.860003,
              55.908215
            ],
            [
              -160.82752,
              55.910675
            ],
            [
              -160.819902,
              55.903562
            ],
            [
              -160.80101,
              55.902256
            ],
            [
              -160.813893,
              55.925264
            ],
            [
              -160.811041,
              55.94723
            ],
            [
              -160.818171,
              55.953352
            ],
            [
              -160.865469,
              55.955081
            ],
            [
              -160.860953,
              55.981153
            ],
            [
              -160.863042,
              55.994872
            ],
            [
              -160.791432,
              56.018069
            ],
            [
              -160.807119,
              56.02398
            ],
            [
              -160.82649,
              56.026902
            ],
            [
              -160.872837,
              56.025375
            ],
            [
              -160.913062,
              56.025587
            ],
            [
              -160.989774,
              56.022859
            ],
            [
              -161.023564,
              56.007003
            ],
            [
              -161.082471,
              56.011061
            ],
            [
              -161.095543,
              56.015312
            ],
            [
              -161.133097,
              56.014914
            ],
            [
              -161.182564,
              56.009295
            ],
            [
              -161.24865,
              55.994711
            ],
            [
              -161.290777,
              55.98313
            ],
            [
              -161.380557,
              55.965618
            ],
            [
              -161.450442,
              55.954485
            ],
            [
              -161.585604,
              55.937324
            ],
            [
              -161.640007,
              55.919503
            ],
            [
              -161.712283,
              55.904232
            ],
            [
              -161.773409,
              55.89731
            ],
            [
              -161.807833,
              55.891954
            ],
            [
              -161.816225,
              55.888993
            ],
            [
              -161.85843,
              55.865402
            ],
            [
              -161.898956,
              55.833464
            ],
            [
              -161.994507,
              55.797825
            ],
            [
              -162.05063,
              55.790897
            ],
            [
              -162.063375,
              55.786123
            ],
            [
              -162.097544,
              55.763346
            ],
            [
              -162.146626,
              55.733348
            ],
            [
              -162.18491,
              55.722032
            ],
            [
              -162.219551,
              55.710867
            ],
            [
              -162.26451,
              55.688616
            ],
            [
              -162.312658,
              55.646502
            ],
            [
              -162.384005,
              55.589843
            ],
            [
              -162.427367,
              55.561803
            ],
            [
              -162.492625,
              55.511728
            ],
            [
              -162.535576,
              55.484165
            ],
            [
              -162.565411,
              55.466849
            ],
            [
              -162.591262,
              55.452736
            ],
            [
              -162.583656,
              55.443435
            ],
            [
              -162.553946,
              55.452197
            ],
            [
              -162.518769,
              55.460957
            ],
            [
              -162.510213,
              55.454488
            ],
            [
              -162.50817,
              55.442292
            ],
            [
              -162.520671,
              55.427658
            ],
            [
              -162.516463,
              55.414022
            ],
            [
              -162.504746,
              55.41174
            ],
            [
              -162.501181,
              55.389741
            ],
            [
              -162.5095,
              55.383936
            ],
            [
              -162.519444,
              55.369603
            ],
            [
              -162.542568,
              55.357393
            ],
            [
              -162.554659,
              55.350707
            ],
            [
              -162.574862,
              55.347869
            ],
            [
              -162.596491,
              55.357193
            ],
            [
              -162.601917,
              55.361449
            ],
            [
              -162.629053,
              55.365299
            ],
            [
              -162.64165,
              55.392576
            ],
            [
              -162.65496,
              55.377319
            ],
            [
              -162.660426,
              55.365434
            ],
            [
              -162.676388,
              55.35407
            ],
            [
              -162.702496,
              55.35395
            ],
            [
              -162.690612,
              55.340436
            ],
            [
              -162.708438,
              55.329215
            ],
            [
              -162.717844,
              55.318788
            ],
            [
              -162.731816,
              55.307829
            ],
            [
              -162.750371,
              55.307623
            ],
            [
              -162.766194,
              55.315151
            ],
            [
              -162.798043,
              55.310958
            ],
            [
              -162.813255,
              55.299458
            ],
            [
              -162.823237,
              55.279022
            ],
            [
              -162.846767,
              55.267513
            ],
            [
              -162.852709,
              55.280511
            ],
            [
              -162.881779,
              55.273776
            ],
            [
              -162.888118,
              55.270424
            ],
            [
              -162.900027,
              55.252466
            ],
            [
              -162.901644,
              55.247652
            ],
            [
              -162.900454,
              55.246416
            ],
            [
              -162.89402,
              55.243046
            ],
            [
              -162.880892,
              55.239564
            ],
            [
              -162.869478,
              55.248086
            ],
            [
              -162.8566,
              55.248721
            ],
            [
              -162.851839,
              55.247317
            ],
            [
              -162.843172,
              55.242564
            ],
            [
              -162.84014,
              55.224043
            ],
            [
              -162.86152,
              55.198339
            ],
            [
              -162.882292,
              55.183251
            ],
            [
              -162.957182,
              55.171271
            ],
            [
              -163.032256,
              55.172147
            ],
            [
              -163.070494,
              55.174114
            ],
            [
              -163.080719,
              55.176861
            ],
            [
              -163.081634,
              55.180409
            ],
            [
              -163.105011,
              55.183979
            ],
            [
              -163.157532,
              55.17551
            ],
            [
              -163.200867,
              55.166057
            ],
            [
              -163.268767,
              55.145465
            ],
            [
              -163.314652,
              55.126312
            ],
            [
              -163.319873,
              55.119622
            ],
            [
              -163.318731,
              55.110811
            ],
            [
              -163.314069,
              55.105146
            ],
            [
              -163.310694,
              55.105612
            ],
            [
              -163.305965,
              55.069584
            ],
            [
              -163.280771,
              55.032959
            ],
            [
              -163.288707,
              54.986708
            ],
            [
              -163.28677,
              54.967029
            ],
            [
              -163.234898,
              54.945003
            ],
            [
              -163.23371,
              54.93053
            ],
            [
              -163.269837,
              54.939269
            ],
            [
              -163.299072,
              54.937494
            ],
            [
              -163.314592,
              54.958862
            ],
            [
              -163.323106,
              54.959929
            ],
            [
              -163.338395,
              54.956191
            ],
            [
              -163.343735,
              54.950416
            ],
            [
              -163.34773,
              54.925093
            ],
            [
              -163.344402,
              54.919333
            ],
            [
              -163.336739,
              54.91749
            ],
            [
              -163.319956,
              54.903085
            ],
            [
              -163.319161,
              54.899026
            ],
            [
              -163.318885,
              54.88012
            ],
            [
              -163.334234,
              54.872948
            ],
            [
              -163.391397,
              54.855331
            ],
            [
              -163.410594,
              54.854576
            ],
            [
              -163.414691,
              54.85609
            ],
            [
              -163.415872,
              54.859652
            ],
            [
              -163.408027,
              54.88458
            ],
            [
              -163.399292,
              54.894012
            ],
            [
              -163.398294,
              54.902371
            ],
            [
              -163.418042,
              54.938499
            ],
            [
              -163.429548,
              54.954759
            ],
            [
              -163.442854,
              54.969875
            ],
            [
              -163.4615,
              54.982511
            ],
            [
              -163.530087,
              55.01666
            ],
            [
              -163.534638,
              55.025305
            ],
            [
              -163.527109,
              55.040871
            ],
            [
              -163.532962,
              55.048881
            ],
            [
              -163.568159,
              55.049145
            ],
            [
              -163.646834,
              55.044467
            ],
            [
              -163.740737,
              55.048266
            ],
            [
              -163.774093,
              55.05578
            ],
            [
              -163.790733,
              55.052583
            ],
            [
              -163.815779,
              55.044625
            ],
            [
              -163.85426,
              55.037796
            ],
            [
              -163.872144,
              55.037399
            ],
            [
              -163.884869,
              55.039909
            ],
            [
              -163.894695,
              55.039115
            ],
            [
              -163.909222,
              55.032089
            ],
            [
              -163.930369,
              55.017646
            ],
            [
              -163.96473,
              54.997337
            ],
            [
              -163.994179,
              54.983315
            ],
            [
              -164.030708,
              54.969818
            ],
            [
              -164.061164,
              54.964708
            ],
            [
              -164.086798,
              54.963396
            ],
            [
              -164.109333,
              54.963999
            ],
            [
              -164.119196,
              54.969416
            ],
            [
              -164.164342,
              54.953532
            ],
            [
              -164.204897,
              54.93124
            ],
            [
              -164.20707,
              54.927578
            ],
            [
              -164.295033,
              54.902122
            ],
            [
              -164.343534,
              54.894139
            ],
            [
              -164.35333,
              54.898327
            ],
            [
              -164.361631,
              54.907391
            ],
            [
              -164.373441,
              54.915349
            ],
            [
              -164.427303,
              54.932849
            ],
            [
              -164.43528,
              54.933126
            ],
            [
              -164.48678,
              54.922441
            ],
            [
              -164.550256,
              54.888785
            ],
            [
              -164.56405,
              54.875539
            ],
            [
              -164.561546,
              54.850835
            ],
            [
              -164.576896,
              54.824564
            ],
            [
              -164.629661,
              54.756031
            ],
            [
              -164.709465,
              54.661518
            ],
            [
              -164.727654,
              54.650957
            ],
            [
              -164.741815,
              54.645441
            ],
            [
              -164.761347,
              54.640634
            ],
            [
              -164.831936,
              54.629028
            ],
            [
              -164.91876,
              54.605306
            ],
            [
              -164.932187,
              54.598745
            ],
            [
              -164.948789,
              54.579877
            ]
          ]
        ],
        [
          [
            [
              -165.220871,
              54.101574
            ],
            [
              -165.212264,
              54.090158
            ],
            [
              -165.203413,
              54.087752
            ],
            [
              -165.044322,
              54.066629
            ],
            [
              -164.960581,
              54.076026
            ],
            [
              -164.953165,
              54.078056
            ],
            [
              -164.921464,
              54.111083
            ],
            [
              -164.919689,
              54.11608
            ],
            [
              -164.921307,
              54.128569
            ],
            [
              -164.937766,
              54.136682
            ],
            [
              -164.976199,
              54.134595
            ],
            [
              -165.00791,
              54.134934
            ],
            [
              -165.023065,
              54.121919
            ],
            [
              -165.050155,
              54.121708
            ],
            [
              -165.067428,
              54.123174
            ],
            [
              -165.088486,
              54.128005
            ],
            [
              -165.140978,
              54.131079
            ],
            [
              -165.198746,
              54.116474
            ],
            [
              -165.220871,
              54.101574
            ]
          ]
        ],
        [
          [
            [
              -165.483373,
              54.075036
            ],
            [
              -165.482747,
              54.072218
            ],
            [
              -165.458179,
              54.066313
            ],
            [
              -165.365768,
              54.073317
            ],
            [
              -165.336836,
              54.070126
            ],
            [
              -165.316662,
              54.049932
            ],
            [
              -165.287665,
              54.038349
            ],
            [
              -165.282674,
              54.057048
            ],
            [
              -165.24583,
              54.05611
            ],
            [
              -165.235149,
              54.062767
            ],
            [
              -165.234364,
              54.065423
            ],
            [
              -165.267012,
              54.095467
            ],
            [
              -165.271048,
              54.095665
            ],
            [
              -165.322268,
              54.094634
            ],
            [
              -165.438972,
              54.084136
            ],
            [
              -165.468221,
              54.079641
            ],
            [
              -165.483373,
              54.075036
            ]
          ]
        ],
        [
          [
            [
              -165.57502,
              54.041559
            ],
            [
              -165.555768,
              54.023551
            ],
            [
              -165.527722,
              54.030811
            ],
            [
              -165.507994,
              54.030671
            ],
            [
              -165.491832,
              54.035697
            ],
            [
              -165.480661,
              54.04421
            ],
            [
              -165.500151,
              54.048955
            ],
            [
              -165.5156,
              54.066673
            ],
            [
              -165.548015,
              54.058231
            ],
            [
              -165.57502,
              54.041559
            ]
          ]
        ],
        [
          [
            [
              -165.684114,
              54.249907
            ],
            [
              -165.685823,
              54.243406
            ],
            [
              -165.681458,
              54.236914
            ],
            [
              -165.669383,
              54.229036
            ],
            [
              -165.640013,
              54.229673
            ],
            [
              -165.625609,
              54.233756
            ],
            [
              -165.615629,
              54.244834
            ],
            [
              -165.612082,
              54.246537
            ],
            [
              -165.595732,
              54.242713
            ],
            [
              -165.587157,
              54.238166
            ],
            [
              -165.579801,
              54.229575
            ],
            [
              -165.585782,
              54.223067
            ],
            [
              -165.593656,
              54.218375
            ],
            [
              -165.621854,
              54.208105
            ],
            [
              -165.637081,
              54.199436
            ],
            [
              -165.629725,
              54.132558
            ],
            [
              -165.613214,
              54.120908
            ],
            [
              -165.575645,
              54.108618
            ],
            [
              -165.565422,
              54.108122
            ],
            [
              -165.549217,
              54.112196
            ],
            [
              -165.536004,
              54.129606
            ],
            [
              -165.481317,
              54.179962
            ],
            [
              -165.47619,
              54.182701
            ],
            [
              -165.422356,
              54.182799
            ],
            [
              -165.412925,
              54.179221
            ],
            [
              -165.399985,
              54.177741
            ],
            [
              -165.383719,
              54.196731
            ],
            [
              -165.391441,
              54.204253
            ],
            [
              -165.405377,
              54.212837
            ],
            [
              -165.478816,
              54.21331
            ],
            [
              -165.496279,
              54.210938
            ],
            [
              -165.512782,
              54.212929
            ],
            [
              -165.553251,
              54.239601
            ],
            [
              -165.55795,
              54.246826
            ],
            [
              -165.558835,
              54.250763
            ],
            [
              -165.557581,
              54.254138
            ],
            [
              -165.5137,
              54.274086
            ],
            [
              -165.479981,
              54.281838
            ],
            [
              -165.47775,
              54.283707
            ],
            [
              -165.478452,
              54.295333
            ],
            [
              -165.502775,
              54.299469
            ],
            [
              -165.523466,
              54.299895
            ],
            [
              -165.586509,
              54.284361
            ],
            [
              -165.605225,
              54.294219
            ],
            [
              -165.61557,
              54.297445
            ],
            [
              -165.62555,
              54.298964
            ],
            [
              -165.636383,
              54.297567
            ],
            [
              -165.675447,
              54.264639
            ],
            [
              -165.684114,
              54.249907
            ]
          ]
        ],
        [
          [
            [
              -166.101402,
              54.144148
            ],
            [
              -166.112242,
              54.122528
            ],
            [
              -166.098255,
              54.103538
            ],
            [
              -166.046438,
              54.044186
            ],
            [
              -166.027733,
              54.045917
            ],
            [
              -166.019861,
              54.051441
            ],
            [
              -165.984415,
              54.061722
            ],
            [
              -165.930242,
              54.066554
            ],
            [
              -165.916235,
              54.065708
            ],
            [
              -165.901649,
              54.06287
            ],
            [
              -165.897261,
              54.060634
            ],
            [
              -165.896308,
              54.055714
            ],
            [
              -165.875129,
              54.03642
            ],
            [
              -165.810747,
              54.074764
            ],
            [
              -165.767173,
              54.065935
            ],
            [
              -165.671477,
              54.096235
            ],
            [
              -165.655573,
              54.1191
            ],
            [
              -165.661379,
              54.130935
            ],
            [
              -165.667323,
              54.132123
            ],
            [
              -165.714198,
              54.120815
            ],
            [
              -165.732602,
              54.148121
            ],
            [
              -165.742613,
              54.158352
            ],
            [
              -165.747893,
              54.161297
            ],
            [
              -165.790523,
              54.171758
            ],
            [
              -165.792569,
              54.181605
            ],
            [
              -165.793781,
              54.183433
            ],
            [
              -165.797147,
              54.183246
            ],
            [
              -165.825159,
              54.164499
            ],
            [
              -165.832421,
              54.161333
            ],
            [
              -165.837274,
              54.161028
            ],
            [
              -165.863518,
              54.166162
            ],
            [
              -165.868076,
              54.168731
            ],
            [
              -165.880456,
              54.183648
            ],
            [
              -165.871973,
              54.189783
            ],
            [
              -165.865872,
              54.200014
            ],
            [
              -165.86514,
              54.21216
            ],
            [
              -165.868192,
              54.214884
            ],
            [
              -165.873076,
              54.216455
            ],
            [
              -165.94463,
              54.220855
            ],
            [
              -165.9832,
              54.221175
            ],
            [
              -166.002465,
              54.213629
            ],
            [
              -166.082028,
              54.175184
            ],
            [
              -166.101402,
              54.144148
            ]
          ]
        ],
        [
          [
            [
              -166.197111,
              53.984799
            ],
            [
              -166.196159,
              53.968619
            ],
            [
              -166.190449,
              53.960053
            ],
            [
              -166.152377,
              53.955294
            ],
            [
              -166.116209,
              53.957197
            ],
            [
              -166.075283,
              53.969571
            ],
            [
              -166.10574,
              53.988606
            ],
            [
              -166.172365,
              53.998124
            ],
            [
              -166.197111,
              53.984799
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "02",
      "COUNTYFP": "150",
      "COUNTYNS": "01419974",
      "AFFGEOID": "0500000US02150",
      "GEOID": "02150",
      "NAME": "Kodiak Island",
      "LSAD": "04",
      "ALAND": 17134142683,
      "AWATER": 14166400752,
      "County_state": "Kodiak Island,02"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -151.888495,
              58.219424
            ],
            [
              -151.890875,
              58.19325
            ],
            [
              -151.871839,
              58.169455
            ],
            [
              -151.862321,
              58.168265
            ],
            [
              -151.817111,
              58.183732
            ],
            [
              -151.795696,
              58.211096
            ],
            [
              -151.790937,
              58.246788
            ],
            [
              -151.817111,
              58.263444
            ],
            [
              -151.862321,
              58.257495
            ],
            [
              -151.888495,
              58.219424
            ]
          ]
        ],
        [
          [
            [
              -152.074522,
              58.924936
            ],
            [
              -152.074522,
              58.912563
            ],
            [
              -152.016463,
              58.908756
            ],
            [
              -151.955549,
              58.914466
            ],
            [
              -152.00409,
              58.941116
            ],
            [
              -152.031692,
              58.948731
            ],
            [
              -152.063101,
              58.946827
            ],
            [
              -152.074522,
              58.924936
            ]
          ]
        ],
        [
          [
            [
              -152.298525,
              57.382482
            ],
            [
              -152.312072,
              57.362746
            ],
            [
              -152.283075,
              57.365438
            ],
            [
              -152.261049,
              57.367335
            ],
            [
              -152.253603,
              57.384019
            ],
            [
              -152.268815,
              57.392345
            ],
            [
              -152.290919,
              57.394907
            ],
            [
              -152.298525,
              57.382482
            ]
          ]
        ],
        [
          [
            [
              -152.35625,
              58.915418
            ],
            [
              -152.342926,
              58.9059
            ],
            [
              -152.276301,
              58.906852
            ],
            [
              -152.159231,
              58.934454
            ],
            [
              -152.154472,
              58.94302
            ],
            [
              -152.178267,
              58.946827
            ],
            [
              -152.242036,
              58.938261
            ],
            [
              -152.307709,
              58.962056
            ],
            [
              -152.312468,
              58.931599
            ],
            [
              -152.35625,
              58.915418
            ]
          ]
        ],
        [
          [
            [
              -153.418343,
              58.064053
            ],
            [
              -153.419783,
              58.059638
            ],
            [
              -153.365574,
              58.039052
            ],
            [
              -153.344807,
              58.040619
            ],
            [
              -153.289701,
              58.05033
            ],
            [
              -153.218115,
              58.043909
            ],
            [
              -153.214568,
              58.042418
            ],
            [
              -153.209885,
              58.034925
            ],
            [
              -153.202525,
              58.030122
            ],
            [
              -153.097462,
              58.004516
            ],
            [
              -152.982406,
              57.984697
            ],
            [
              -152.947547,
              57.983519
            ],
            [
              -152.871416,
              57.997157
            ],
            [
              -152.871836,
              57.999275
            ],
            [
              -152.819043,
              57.995574
            ],
            [
              -152.762475,
              58.014464
            ],
            [
              -152.771303,
              58.046883
            ],
            [
              -152.706831,
              58.050577
            ],
            [
              -152.656801,
              58.061049
            ],
            [
              -152.631214,
              58.081924
            ],
            [
              -152.615103,
              58.116224
            ],
            [
              -152.597506,
              58.179686
            ],
            [
              -152.584222,
              58.187477
            ],
            [
              -152.562829,
              58.177979
            ],
            [
              -152.559884,
              58.170941
            ],
            [
              -152.557497,
              58.160683
            ],
            [
              -152.569595,
              58.1148
            ],
            [
              -152.557237,
              58.086462
            ],
            [
              -152.554461,
              58.08462
            ],
            [
              -152.541533,
              58.083666
            ],
            [
              -152.530388,
              58.087766
            ],
            [
              -152.529036,
              58.093779
            ],
            [
              -152.514794,
              58.114321
            ],
            [
              -152.482674,
              58.129813
            ],
            [
              -152.425391,
              58.127614
            ],
            [
              -152.401892,
              58.120755
            ],
            [
              -152.343522,
              58.119174
            ],
            [
              -152.273605,
              58.12563
            ],
            [
              -152.265111,
              58.135732
            ],
            [
              -152.311415,
              58.221115
            ],
            [
              -152.280629,
              58.242344
            ],
            [
              -152.24289,
              58.241192
            ],
            [
              -152.23383,
              58.243329
            ],
            [
              -152.203699,
              58.212055
            ],
            [
              -152.207488,
              58.206284
            ],
            [
              -152.219826,
              58.206289
            ],
            [
              -152.224439,
              58.202365
            ],
            [
              -152.223175,
              58.194794
            ],
            [
              -152.194827,
              58.174128
            ],
            [
              -152.112205,
              58.148559
            ],
            [
              -152.081083,
              58.154275
            ],
            [
              -151.986127,
              58.213774
            ],
            [
              -151.972053,
              58.230702
            ],
            [
              -151.964103,
              58.269049
            ],
            [
              -151.963817,
              58.328999
            ],
            [
              -151.966218,
              58.332737
            ],
            [
              -151.981781,
              58.347971
            ],
            [
              -151.986171,
              58.350413
            ],
            [
              -152.082342,
              58.309945
            ],
            [
              -152.107635,
              58.28024
            ],
            [
              -152.107962,
              58.260525
            ],
            [
              -152.116569,
              58.248537
            ],
            [
              -152.146519,
              58.24912
            ],
            [
              -152.147142,
              58.266992
            ],
            [
              -152.138294,
              58.295712
            ],
            [
              -152.11953,
              58.32977
            ],
            [
              -152.08925,
              58.367644
            ],
            [
              -152.090437,
              58.372628
            ],
            [
              -152.125339,
              58.396396
            ],
            [
              -152.129257,
              58.396414
            ],
            [
              -152.200953,
              58.355332
            ],
            [
              -152.224965,
              58.357372
            ],
            [
              -152.234718,
              58.362024
            ],
            [
              -152.227835,
              58.376424
            ],
            [
              -152.279508,
              58.415872
            ],
            [
              -152.301713,
              58.428697
            ],
            [
              -152.320554,
              58.433829
            ],
            [
              -152.328063,
              58.434372
            ],
            [
              -152.35609,
              58.42347
            ],
            [
              -152.358724,
              58.415585
            ],
            [
              -152.355073,
              58.413052
            ],
            [
              -152.348389,
              58.401502
            ],
            [
              -152.34486,
              58.39163
            ],
            [
              -152.364682,
              58.364613
            ],
            [
              -152.387343,
              58.359499
            ],
            [
              -152.476814,
              58.350955
            ],
            [
              -152.493991,
              58.354684
            ],
            [
              -152.512483,
              58.427349
            ],
            [
              -152.505516,
              58.441876
            ],
            [
              -152.498571,
              58.449538
            ],
            [
              -152.467197,
              58.476609
            ],
            [
              -152.418267,
              58.515244
            ],
            [
              -152.38761,
              58.52287
            ],
            [
              -152.372317,
              58.531175
            ],
            [
              -152.337212,
              58.589095
            ],
            [
              -152.329835,
              58.632102
            ],
            [
              -152.337964,
              58.637404
            ],
            [
              -152.354709,
              58.63828
            ],
            [
              -152.453817,
              58.618515
            ],
            [
              -152.50282,
              58.593451
            ],
            [
              -152.545009,
              58.594253
            ],
            [
              -152.549635,
              58.601024
            ],
            [
              -152.550418,
              58.610996
            ],
            [
              -152.560171,
              58.61968
            ],
            [
              -152.56771,
              58.621304
            ],
            [
              -152.61613,
              58.601852
            ],
            [
              -152.638569,
              58.587448
            ],
            [
              -152.665999,
              58.564493
            ],
            [
              -152.66622,
              58.544087
            ],
            [
              -152.653673,
              58.506572
            ],
            [
              -152.622794,
              58.494189
            ],
            [
              -152.619197,
              58.493674
            ],
            [
              -152.60903,
              58.496167
            ],
            [
              -152.600534,
              58.494946
            ],
            [
              -152.601666,
              58.490423
            ],
            [
              -152.610955,
              58.475775
            ],
            [
              -152.68994,
              58.459861
            ],
            [
              -152.723169,
              58.46208
            ],
            [
              -152.733845,
              58.460662
            ],
            [
              -152.771106,
              58.429515
            ],
            [
              -152.774509,
              58.419721
            ],
            [
              -152.787776,
              58.411313
            ],
            [
              -152.812207,
              58.403464
            ],
            [
              -152.844173,
              58.402842
            ],
            [
              -152.864939,
              58.40434
            ],
            [
              -152.886358,
              58.410585
            ],
            [
              -152.88886,
              58.409384
            ],
            [
              -152.883107,
              58.400443
            ],
            [
              -152.839234,
              58.372477
            ],
            [
              -152.78742,
              58.369015
            ],
            [
              -152.774048,
              58.366826
            ],
            [
              -152.804789,
              58.33951
            ],
            [
              -152.821964,
              58.328501
            ],
            [
              -152.870555,
              58.335743
            ],
            [
              -152.895407,
              58.345305
            ],
            [
              -152.925586,
              58.339686
            ],
            [
              -152.93644,
              58.334923
            ],
            [
              -152.936757,
              58.330513
            ],
            [
              -152.921122,
              58.313268
            ],
            [
              -152.91245,
              58.307191
            ],
            [
              -152.884023,
              58.307087
            ],
            [
              -152.869811,
              58.304906
            ],
            [
              -152.878858,
              58.288533
            ],
            [
              -152.888204,
              58.2831
            ],
            [
              -152.94127,
              58.279614
            ],
            [
              -152.982356,
              58.287495
            ],
            [
              -152.993217,
              58.296254
            ],
            [
              -153.00439,
              58.300135
            ],
            [
              -153.044316,
              58.306336
            ],
            [
              -153.099284,
              58.264065
            ],
            [
              -153.10241,
              58.260344
            ],
            [
              -153.101841,
              58.257938
            ],
            [
              -153.082507,
              58.244495
            ],
            [
              -153.061678,
              58.235649
            ],
            [
              -153.006979,
              58.221847
            ],
            [
              -152.998094,
              58.214122
            ],
            [
              -153.000579,
              58.211768
            ],
            [
              -153.036662,
              58.199235
            ],
            [
              -153.060846,
              58.194502
            ],
            [
              -153.073927,
              58.195107
            ],
            [
              -153.170101,
              58.216704
            ],
            [
              -153.202801,
              58.20808
            ],
            [
              -153.223709,
              58.16212
            ],
            [
              -153.209672,
              58.15035
            ],
            [
              -153.167605,
              58.127818
            ],
            [
              -153.14874,
              58.106121
            ],
            [
              -153.156402,
              58.090087
            ],
            [
              -153.168617,
              58.088385
            ],
            [
              -153.199117,
              58.102005
            ],
            [
              -153.227567,
              58.123364
            ],
            [
              -153.262643,
              58.145099
            ],
            [
              -153.274215,
              58.148102
            ],
            [
              -153.281874,
              58.147555
            ],
            [
              -153.316127,
              58.14039
            ],
            [
              -153.412933,
              58.069811
            ],
            [
              -153.418343,
              58.064053
            ]
          ]
        ],
        [
          [
            [
              -153.617409,
              56.896684
            ],
            [
              -153.603624,
              56.887336
            ],
            [
              -153.574865,
              56.891881
            ],
            [
              -153.544204,
              56.909273
            ],
            [
              -153.558465,
              56.914334
            ],
            [
              -153.602436,
              56.907586
            ],
            [
              -153.617409,
              56.896684
            ]
          ]
        ],
        [
          [
            [
              -154.159354,
              56.692156
            ],
            [
              -154.153147,
              56.681697
            ],
            [
              -154.047499,
              56.683601
            ],
            [
              -154.021801,
              56.684552
            ],
            [
              -154.017042,
              56.689311
            ],
            [
              -154.021801,
              56.715009
            ],
            [
              -154.046547,
              56.730238
            ],
            [
              -154.09604,
              56.705492
            ],
            [
              -154.120787,
              56.708347
            ],
            [
              -154.159354,
              56.692156
            ]
          ]
        ],
        [
          [
            [
              -154.362361,
              56.542512
            ],
            [
              -154.361378,
              56.52564
            ],
            [
              -154.3474,
              56.512046
            ],
            [
              -154.343096,
              56.510171
            ],
            [
              -154.304371,
              56.502322
            ],
            [
              -154.232464,
              56.491052
            ],
            [
              -154.19728,
              56.502002
            ],
            [
              -154.163987,
              56.507844
            ],
            [
              -154.143711,
              56.506172
            ],
            [
              -154.120244,
              56.501838
            ],
            [
              -153.993909,
              56.501796
            ],
            [
              -153.952958,
              56.507174
            ],
            [
              -153.887678,
              56.533637
            ],
            [
              -153.868461,
              56.551493
            ],
            [
              -153.870804,
              56.558015
            ],
            [
              -153.878764,
              56.565925
            ],
            [
              -153.915288,
              56.564921
            ],
            [
              -153.940505,
              56.558317
            ],
            [
              -154.009274,
              56.551445
            ],
            [
              -154.025334,
              56.551763
            ],
            [
              -154.041572,
              56.556209
            ],
            [
              -154.075187,
              56.583745
            ],
            [
              -154.079016,
              56.589977
            ],
            [
              -154.081829,
              56.603716
            ],
            [
              -154.090014,
              56.614798
            ],
            [
              -154.095833,
              56.617786
            ],
            [
              -154.103243,
              56.617695
            ],
            [
              -154.113397,
              56.616745
            ],
            [
              -154.136739,
              56.60935
            ],
            [
              -154.184819,
              56.603773
            ],
            [
              -154.206001,
              56.606908
            ],
            [
              -154.210336,
              56.609684
            ],
            [
              -154.223759,
              56.612955
            ],
            [
              -154.244234,
              56.609194
            ],
            [
              -154.29002,
              56.595376
            ],
            [
              -154.310913,
              56.585447
            ],
            [
              -154.341401,
              56.563705
            ],
            [
              -154.362361,
              56.542512
            ]
          ]
        ],
        [
          [
            [
              -154.779663,
              57.366335
            ],
            [
              -154.796539,
              57.346207
            ],
            [
              -154.758034,
              57.329916
            ],
            [
              -154.74309,
              57.31477
            ],
            [
              -154.751537,
              57.307781
            ],
            [
              -154.79384,
              57.288862
            ],
            [
              -154.792054,
              57.286696
            ],
            [
              -154.777368,
              57.280008
            ],
            [
              -154.740161,
              57.276517
            ],
            [
              -154.698264,
              57.284294
            ],
            [
              -154.691855,
              57.28411
            ],
            [
              -154.613723,
              57.2678
            ],
            [
              -154.594977,
              57.257161
            ],
            [
              -154.574343,
              57.239919
            ],
            [
              -154.539552,
              57.196351
            ],
            [
              -154.533699,
              57.183513
            ],
            [
              -154.529844,
              57.168882
            ],
            [
              -154.515213,
              57.077985
            ],
            [
              -154.516842,
              57.030312
            ],
            [
              -154.528538,
              57.001892
            ],
            [
              -154.524695,
              56.991623
            ],
            [
              -154.511672,
              56.988548
            ],
            [
              -154.476315,
              56.984204
            ],
            [
              -154.40749,
              56.968334
            ],
            [
              -154.385285,
              56.959767
            ],
            [
              -154.312888,
              56.918673
            ],
            [
              -154.306936,
              56.911783
            ],
            [
              -154.300002,
              56.892252
            ],
            [
              -154.298422,
              56.863176
            ],
            [
              -154.300193,
              56.852023
            ],
            [
              -154.305713,
              56.846871
            ],
            [
              -154.298965,
              56.846479
            ],
            [
              -154.276739,
              56.853648
            ],
            [
              -154.231771,
              56.872294
            ],
            [
              -154.226494,
              56.876257
            ],
            [
              -154.227193,
              56.883026
            ],
            [
              -154.22356,
              56.896064
            ],
            [
              -154.21211,
              56.909749
            ],
            [
              -154.16336,
              56.919783
            ],
            [
              -154.123489,
              56.95617
            ],
            [
              -154.076623,
              56.970589
            ],
            [
              -154.055554,
              56.987209
            ],
            [
              -154.024288,
              57.016608
            ],
            [
              -153.993807,
              57.050502
            ],
            [
              -153.982792,
              57.066277
            ],
            [
              -153.861711,
              57.119224
            ],
            [
              -153.823978,
              57.13979
            ],
            [
              -153.822875,
              57.142372
            ],
            [
              -153.80629,
              57.157424
            ],
            [
              -153.788521,
              57.161381
            ],
            [
              -153.779087,
              57.158821
            ],
            [
              -153.776707,
              57.142858
            ],
            [
              -153.783465,
              57.131822
            ],
            [
              -153.804787,
              57.113158
            ],
            [
              -153.858891,
              57.088844
            ],
            [
              -153.875356,
              57.089802
            ],
            [
              -153.887461,
              57.086958
            ],
            [
              -153.912845,
              57.051225
            ],
            [
              -153.963233,
              56.999351
            ],
            [
              -153.979743,
              56.962189
            ],
            [
              -153.976871,
              56.955144
            ],
            [
              -153.934781,
              56.958928
            ],
            [
              -153.917703,
              56.962169
            ],
            [
              -153.913627,
              56.965391
            ],
            [
              -153.902802,
              56.968445
            ],
            [
              -153.856991,
              56.974877
            ],
            [
              -153.836313,
              56.960236
            ],
            [
              -153.848672,
              56.942349
            ],
            [
              -153.888127,
              56.926269
            ],
            [
              -153.935992,
              56.915772
            ],
            [
              -153.984547,
              56.889626
            ],
            [
              -154.030502,
              56.855052
            ],
            [
              -154.040948,
              56.854135
            ],
            [
              -154.055228,
              56.850465
            ],
            [
              -154.067425,
              56.845303
            ],
            [
              -154.072878,
              56.841099
            ],
            [
              -154.125431,
              56.783298
            ],
            [
              -154.148745,
              56.745677
            ],
            [
              -154.136965,
              56.742359
            ],
            [
              -154.129017,
              56.742168
            ],
            [
              -154.106565,
              56.745572
            ],
            [
              -154.085088,
              56.751193
            ],
            [
              -154.064292,
              56.760091
            ],
            [
              -154.050518,
              56.763523
            ],
            [
              -154.037153,
              56.763414
            ],
            [
              -154.02261,
              56.755946
            ],
            [
              -154.016213,
              56.743466
            ],
            [
              -153.990158,
              56.743263
            ],
            [
              -153.97178,
              56.744861
            ],
            [
              -153.963274,
              56.7476
            ],
            [
              -153.90215,
              56.771208
            ],
            [
              -153.871727,
              56.793604
            ],
            [
              -153.83964,
              56.822099
            ],
            [
              -153.821339,
              56.841604
            ],
            [
              -153.776468,
              56.830315
            ],
            [
              -153.739339,
              56.846674
            ],
            [
              -153.699409,
              56.855382
            ],
            [
              -153.687525,
              56.864997
            ],
            [
              -153.687525,
              56.876688
            ],
            [
              -153.694418,
              56.908754
            ],
            [
              -153.692754,
              56.92562
            ],
            [
              -153.664708,
              56.931197
            ],
            [
              -153.627483,
              56.937127
            ],
            [
              -153.600664,
              56.942629
            ],
            [
              -153.556762,
              56.968862
            ],
            [
              -153.543429,
              56.995245
            ],
            [
              -153.580831,
              57.049048
            ],
            [
              -153.595819,
              57.056309
            ],
            [
              -153.601294,
              57.056656
            ],
            [
              -153.66381,
              57.053694
            ],
            [
              -153.675736,
              57.054778
            ],
            [
              -153.675981,
              57.06983
            ],
            [
              -153.654497,
              57.084602
            ],
            [
              -153.577006,
              57.093177
            ],
            [
              -153.563562,
              57.089769
            ],
            [
              -153.535942,
              57.077988
            ],
            [
              -153.49885,
              57.065363
            ],
            [
              -153.4896,
              57.074702
            ],
            [
              -153.48652,
              57.085915
            ],
            [
              -153.366525,
              57.176708
            ],
            [
              -153.368921,
              57.180832
            ],
            [
              -153.36818,
              57.185337
            ],
            [
              -153.350266,
              57.192339
            ],
            [
              -153.310143,
              57.194426
            ],
            [
              -153.286351,
              57.184288
            ],
            [
              -153.282735,
              57.175815
            ],
            [
              -153.284012,
              57.173867
            ],
            [
              -153.320547,
              57.157334
            ],
            [
              -153.345533,
              57.139565
            ],
            [
              -153.380389,
              57.120468
            ],
            [
              -153.384699,
              57.115354
            ],
            [
              -153.404263,
              57.080511
            ],
            [
              -153.402608,
              57.070092
            ],
            [
              -153.396921,
              57.060399
            ],
            [
              -153.365239,
              57.07208
            ],
            [
              -153.324265,
              57.043308
            ],
            [
              -153.320929,
              57.036838
            ],
            [
              -153.349037,
              57.011196
            ],
            [
              -153.348707,
              57.008373
            ],
            [
              -153.312583,
              56.991486
            ],
            [
              -153.301142,
              56.991192
            ],
            [
              -153.235282,
              57.007398
            ],
            [
              -153.204319,
              57.03364
            ],
            [
              -153.200217,
              57.042039
            ],
            [
              -153.205384,
              57.056148
            ],
            [
              -153.213318,
              57.055891
            ],
            [
              -153.221204,
              57.060367
            ],
            [
              -153.22224,
              57.061798
            ],
            [
              -153.220953,
              57.068239
            ],
            [
              -153.21544,
              57.075943
            ],
            [
              -153.18001,
              57.094523
            ],
            [
              -153.146361,
              57.100883
            ],
            [
              -153.133988,
              57.099351
            ],
            [
              -153.132708,
              57.094936
            ],
            [
              -153.128881,
              57.092571
            ],
            [
              -153.118673,
              57.091033
            ],
            [
              -152.997246,
              57.119491
            ],
            [
              -152.950736,
              57.119788
            ],
            [
              -152.911371,
              57.126813
            ],
            [
              -152.90054,
              57.132076
            ],
            [
              -152.869797,
              57.150849
            ],
            [
              -152.874839,
              57.16095
            ],
            [
              -152.880321,
              57.164798
            ],
            [
              -152.949333,
              57.187346
            ],
            [
              -153.073982,
              57.187091
            ],
            [
              -153.097019,
              57.183289
            ],
            [
              -153.123865,
              57.175445
            ],
            [
              -153.166002,
              57.180643
            ],
            [
              -153.213802,
              57.205059
            ],
            [
              -153.215967,
              57.209297
            ],
            [
              -153.215107,
              57.213356
            ],
            [
              -153.209732,
              57.218773
            ],
            [
              -153.201722,
              57.221679
            ],
            [
              -153.169724,
              57.220236
            ],
            [
              -153.163333,
              57.216713
            ],
            [
              -153.125477,
              57.211841
            ],
            [
              -153.077916,
              57.211444
            ],
            [
              -153.056971,
              57.214756
            ],
            [
              -152.997739,
              57.231176
            ],
            [
              -152.950982,
              57.248991
            ],
            [
              -152.943463,
              57.256956
            ],
            [
              -152.944201,
              57.259083
            ],
            [
              -152.97091,
              57.282624
            ],
            [
              -153.012992,
              57.299453
            ],
            [
              -153.015994,
              57.300231
            ],
            [
              -153.017643,
              57.297715
            ],
            [
              -153.039134,
              57.293314
            ],
            [
              -153.096133,
              57.286866
            ],
            [
              -153.101322,
              57.286901
            ],
            [
              -153.11628,
              57.297312
            ],
            [
              -153.09904,
              57.310513
            ],
            [
              -153.079288,
              57.32196
            ],
            [
              -153.056007,
              57.329229
            ],
            [
              -153.008525,
              57.339733
            ],
            [
              -152.984715,
              57.339918
            ],
            [
              -152.909051,
              57.324222
            ],
            [
              -152.900688,
              57.302976
            ],
            [
              -152.886384,
              57.291337
            ],
            [
              -152.818187,
              57.265368
            ],
            [
              -152.787994,
              57.279905
            ],
            [
              -152.774155,
              57.290432
            ],
            [
              -152.750116,
              57.308869
            ],
            [
              -152.716128,
              57.30566
            ],
            [
              -152.712008,
              57.27812
            ],
            [
              -152.707768,
              57.276046
            ],
            [
              -152.695698,
              57.281318
            ],
            [
              -152.657569,
              57.303551
            ],
            [
              -152.630441,
              57.322668
            ],
            [
              -152.606522,
              57.36366
            ],
            [
              -152.601148,
              57.382165
            ],
            [
              -152.620377,
              57.401601
            ],
            [
              -152.630018,
              57.405573
            ],
            [
              -152.67325,
              57.413246
            ],
            [
              -152.722651,
              57.433352
            ],
            [
              -152.742678,
              57.447852
            ],
            [
              -152.762676,
              57.45756
            ],
            [
              -152.816666,
              57.471925
            ],
            [
              -152.911738,
              57.470775
            ],
            [
              -152.890173,
              57.486705
            ],
            [
              -152.913877,
              57.502968
            ],
            [
              -152.886205,
              57.510697
            ],
            [
              -152.838905,
              57.50227
            ],
            [
              -152.825515,
              57.497048
            ],
            [
              -152.809036,
              57.494505
            ],
            [
              -152.798914,
              57.494255
            ],
            [
              -152.770196,
              57.50429
            ],
            [
              -152.743084,
              57.50571
            ],
            [
              -152.722846,
              57.494087
            ],
            [
              -152.719447,
              57.488028
            ],
            [
              -152.720471,
              57.481572
            ],
            [
              -152.716765,
              57.478467
            ],
            [
              -152.684413,
              57.466597
            ],
            [
              -152.662831,
              57.463679
            ],
            [
              -152.646017,
              57.466134
            ],
            [
              -152.600375,
              57.468833
            ],
            [
              -152.570527,
              57.448909
            ],
            [
              -152.517004,
              57.432184
            ],
            [
              -152.495215,
              57.452379
            ],
            [
              -152.481776,
              57.467835
            ],
            [
              -152.449927,
              57.455819
            ],
            [
              -152.474883,
              57.434204
            ],
            [
              -152.416473,
              57.435293
            ],
            [
              -152.361592,
              57.427761
            ],
            [
              -152.339406,
              57.422688
            ],
            [
              -152.314925,
              57.432413
            ],
            [
              -152.326134,
              57.441514
            ],
            [
              -152.323683,
              57.467861
            ],
            [
              -152.314889,
              57.486065
            ],
            [
              -152.29147,
              57.517103
            ],
            [
              -152.259641,
              57.527156
            ],
            [
              -152.163996,
              57.584607
            ],
            [
              -152.159677,
              57.593614
            ],
            [
              -152.152393,
              57.619485
            ],
            [
              -152.161617,
              57.623287
            ],
            [
              -152.179531,
              57.624809
            ],
            [
              -152.265346,
              57.62643
            ],
            [
              -152.322733,
              57.623402
            ],
            [
              -152.313974,
              57.63642
            ],
            [
              -152.338455,
              57.653338
            ],
            [
              -152.363411,
              57.641636
            ],
            [
              -152.38714,
              57.612428
            ],
            [
              -152.40247,
              57.607981
            ],
            [
              -152.426062,
              57.593357
            ],
            [
              -152.439667,
              57.590399
            ],
            [
              -152.459929,
              57.594373
            ],
            [
              -152.467756,
              57.598221
            ],
            [
              -152.468172,
              57.600996
            ],
            [
              -152.461018,
              57.606311
            ],
            [
              -152.428946,
              57.642162
            ],
            [
              -152.375295,
              57.67495
            ],
            [
              -152.398569,
              57.68721
            ],
            [
              -152.401492,
              57.686513
            ],
            [
              -152.44303,
              57.668049
            ],
            [
              -152.467679,
              57.68139
            ],
            [
              -152.4649,
              57.702645
            ],
            [
              -152.440182,
              57.72664
            ],
            [
              -152.497056,
              57.734387
            ],
            [
              -152.497314,
              57.738596
            ],
            [
              -152.471,
              57.763466
            ],
            [
              -152.46555,
              57.767169
            ],
            [
              -152.443786,
              57.776142
            ],
            [
              -152.381076,
              57.776744
            ],
            [
              -152.357233,
              57.773918
            ],
            [
              -152.349169,
              57.76848
            ],
            [
              -152.348644,
              57.764393
            ],
            [
              -152.342674,
              57.762306
            ],
            [
              -152.298762,
              57.745925
            ],
            [
              -152.24576,
              57.769637
            ],
            [
              -152.212247,
              57.791433
            ],
            [
              -152.246473,
              57.792193
            ],
            [
              -152.309696,
              57.790166
            ],
            [
              -152.324284,
              57.824444
            ],
            [
              -152.351152,
              57.834768
            ],
            [
              -152.364079,
              57.829372
            ],
            [
              -152.417424,
              57.815464
            ],
            [
              -152.429326,
              57.820114
            ],
            [
              -152.433653,
              57.824314
            ],
            [
              -152.421441,
              57.836856
            ],
            [
              -152.40144,
              57.857375
            ],
            [
              -152.446124,
              57.869133
            ],
            [
              -152.468511,
              57.888621
            ],
            [
              -152.44824,
              57.902605
            ],
            [
              -152.414977,
              57.902231
            ],
            [
              -152.4037,
              57.901146
            ],
            [
              -152.39475,
              57.894602
            ],
            [
              -152.38613,
              57.890706
            ],
            [
              -152.377063,
              57.886728
            ],
            [
              -152.364777,
              57.883921
            ],
            [
              -152.333209,
              57.90255
            ],
            [
              -152.324103,
              57.916604
            ],
            [
              -152.362161,
              57.9262
            ],
            [
              -152.388626,
              57.924486
            ],
            [
              -152.426458,
              57.930851
            ],
            [
              -152.437416,
              57.936978
            ],
            [
              -152.437604,
              57.939834
            ],
            [
              -152.422573,
              57.948662
            ],
            [
              -152.411618,
              57.969282
            ],
            [
              -152.415177,
              57.973081
            ],
            [
              -152.421408,
              57.975683
            ],
            [
              -152.432608,
              57.976029
            ],
            [
              -152.470336,
              57.962099
            ],
            [
              -152.487666,
              57.941968
            ],
            [
              -152.526283,
              57.913266
            ],
            [
              -152.549661,
              57.900137
            ],
            [
              -152.567395,
              57.900358
            ],
            [
              -152.585985,
              57.908101
            ],
            [
              -152.587705,
              57.926961
            ],
            [
              -152.635378,
              57.91861
            ],
            [
              -152.639375,
              57.91422
            ],
            [
              -152.641805,
              57.902499
            ],
            [
              -152.639887,
              57.899688
            ],
            [
              -152.653856,
              57.883541
            ],
            [
              -152.681204,
              57.875675
            ],
            [
              -152.71589,
              57.855478
            ],
            [
              -152.732528,
              57.816255
            ],
            [
              -152.753437,
              57.834452
            ],
            [
              -152.758168,
              57.840272
            ],
            [
              -152.790211,
              57.858058
            ],
            [
              -152.822543,
              57.843203
            ],
            [
              -152.841361,
              57.830221
            ],
            [
              -152.849997,
              57.821462
            ],
            [
              -152.854718,
              57.770271
            ],
            [
              -152.852269,
              57.752318
            ],
            [
              -152.847811,
              57.746625
            ],
            [
              -152.850336,
              57.740041
            ],
            [
              -152.874498,
              57.737961
            ],
            [
              -152.881998,
              57.73832
            ],
            [
              -152.892875,
              57.742012
            ],
            [
              -152.904312,
              57.750825
            ],
            [
              -152.916334,
              57.771216
            ],
            [
              -152.909791,
              57.810405
            ],
            [
              -152.902633,
              57.830146
            ],
            [
              -152.892517,
              57.842525
            ],
            [
              -152.823299,
              57.890928
            ],
            [
              -152.804807,
              57.899175
            ],
            [
              -152.751978,
              57.933466
            ],
            [
              -152.739766,
              57.944798
            ],
            [
              -152.722524,
              57.987364
            ],
            [
              -152.852785,
              57.974583
            ],
            [
              -152.856284,
              57.947385
            ],
            [
              -152.871663,
              57.933279
            ],
            [
              -152.876197,
              57.932446
            ],
            [
              -153.024425,
              57.956954
            ],
            [
              -153.063853,
              57.959782
            ],
            [
              -153.099268,
              57.957008
            ],
            [
              -153.129494,
              57.946551
            ],
            [
              -153.217306,
              57.983659
            ],
            [
              -153.221576,
              57.989319
            ],
            [
              -153.23473,
              57.996972
            ],
            [
              -153.276536,
              57.998447
            ],
            [
              -153.297756,
              57.996425
            ],
            [
              -153.302198,
              57.991706
            ],
            [
              -153.299009,
              57.985626
            ],
            [
              -153.270325,
              57.958566
            ],
            [
              -153.233229,
              57.940993
            ],
            [
              -153.198618,
              57.929923
            ],
            [
              -153.089419,
              57.865233
            ],
            [
              -153.09342,
              57.861569
            ],
            [
              -153.122513,
              57.856639
            ],
            [
              -153.127278,
              57.856748
            ],
            [
              -153.236952,
              57.891818
            ],
            [
              -153.268149,
              57.888741
            ],
            [
              -153.273676,
              57.890408
            ],
            [
              -153.452645,
              57.963509
            ],
            [
              -153.461113,
              57.972769
            ],
            [
              -153.469421,
              57.977282
            ],
            [
              -153.484603,
              57.9765
            ],
            [
              -153.513347,
              57.968751
            ],
            [
              -153.520392,
              57.963387
            ],
            [
              -153.533204,
              57.941117
            ],
            [
              -153.536524,
              57.93077
            ],
            [
              -153.528697,
              57.921717
            ],
            [
              -153.512024,
              57.909156
            ],
            [
              -153.462011,
              57.880588
            ],
            [
              -153.446406,
              57.875035
            ],
            [
              -153.395813,
              57.858772
            ],
            [
              -153.328137,
              57.849851
            ],
            [
              -153.324881,
              57.848421
            ],
            [
              -153.322687,
              57.83619
            ],
            [
              -153.324872,
              57.831048
            ],
            [
              -153.343408,
              57.810866
            ],
            [
              -153.35358,
              57.809731
            ],
            [
              -153.406716,
              57.828663
            ],
            [
              -153.45156,
              57.839284
            ],
            [
              -153.479457,
              57.84202
            ],
            [
              -153.48735,
              57.834274
            ],
            [
              -153.480377,
              57.814665
            ],
            [
              -153.462463,
              57.795292
            ],
            [
              -153.469892,
              57.766536
            ],
            [
              -153.493401,
              57.728316
            ],
            [
              -153.515205,
              57.716505
            ],
            [
              -153.549605,
              57.717967
            ],
            [
              -153.554226,
              57.72245
            ],
            [
              -153.557647,
              57.734741
            ],
            [
              -153.553251,
              57.759512
            ],
            [
              -153.551088,
              57.76311
            ],
            [
              -153.550823,
              57.78689
            ],
            [
              -153.571362,
              57.832101
            ],
            [
              -153.648798,
              57.880103
            ],
            [
              -153.721176,
              57.890615
            ],
            [
              -153.755054,
              57.883565
            ],
            [
              -153.823385,
              57.865013
            ],
            [
              -153.93522,
              57.813047
            ],
            [
              -153.932964,
              57.703778
            ],
            [
              -153.930279,
              57.696791
            ],
            [
              -153.918344,
              57.695663
            ],
            [
              -153.888099,
              57.705447
            ],
            [
              -153.862886,
              57.706943
            ],
            [
              -153.797971,
              57.696508
            ],
            [
              -153.676721,
              57.669663
            ],
            [
              -153.658008,
              57.66148
            ],
            [
              -153.648693,
              57.654125
            ],
            [
              -153.663007,
              57.639858
            ],
            [
              -153.667261,
              57.639008
            ],
            [
              -153.705322,
              57.640923
            ],
            [
              -153.749178,
              57.646224
            ],
            [
              -153.858545,
              57.651138
            ],
            [
              -153.868275,
              57.649688
            ],
            [
              -153.874286,
              57.64611
            ],
            [
              -153.879943,
              57.634072
            ],
            [
              -153.877756,
              57.629529
            ],
            [
              -153.852502,
              57.613517
            ],
            [
              -153.846828,
              57.612648
            ],
            [
              -153.823753,
              57.597651
            ],
            [
              -153.813136,
              57.588581
            ],
            [
              -153.824823,
              57.577617
            ],
            [
              -153.848082,
              57.560589
            ],
            [
              -153.869096,
              57.551844
            ],
            [
              -153.87595,
              57.542769
            ],
            [
              -153.888891,
              57.504682
            ],
            [
              -153.922183,
              57.499036
            ],
            [
              -153.922982,
              57.520153
            ],
            [
              -153.925905,
              57.529051
            ],
            [
              -153.929265,
              57.533253
            ],
            [
              -153.939099,
              57.538271
            ],
            [
              -153.94755,
              57.540244
            ],
            [
              -153.971114,
              57.539436
            ],
            [
              -153.982199,
              57.553156
            ],
            [
              -153.984847,
              57.604595
            ],
            [
              -153.982581,
              57.648251
            ],
            [
              -153.983015,
              57.649835
            ],
            [
              -153.994572,
              57.656905
            ],
            [
              -154.031592,
              57.660854
            ],
            [
              -154.056226,
              57.65243
            ],
            [
              -154.08613,
              57.649054
            ],
            [
              -154.186597,
              57.658578
            ],
            [
              -154.196959,
              57.664639
            ],
            [
              -154.22566,
              57.661366
            ],
            [
              -154.344244,
              57.630901
            ],
            [
              -154.411385,
              57.598452
            ],
            [
              -154.431841,
              57.584783
            ],
            [
              -154.468328,
              57.570339
            ],
            [
              -154.500282,
              57.574423
            ],
            [
              -154.511233,
              57.578646
            ],
            [
              -154.52206,
              57.577786
            ],
            [
              -154.540923,
              57.539621
            ],
            [
              -154.591678,
              57.518597
            ],
            [
              -154.602546,
              57.518751
            ],
            [
              -154.618704,
              57.514972
            ],
            [
              -154.629678,
              57.510197
            ],
            [
              -154.69331,
              57.446085
            ],
            [
              -154.702588,
              57.420528
            ],
            [
              -154.699629,
              57.412873
            ],
            [
              -154.700598,
              57.401162
            ],
            [
              -154.707646,
              57.380688
            ],
            [
              -154.729038,
              57.36249
            ],
            [
              -154.779663,
              57.366335
            ]
          ]
        ],
        [
          [
            [
              -154.806114,
              56.434182
            ],
            [
              -154.805481,
              56.427488
            ],
            [
              -154.799907,
              56.419387
            ],
            [
              -154.789003,
              56.411015
            ],
            [
              -154.775766,
              56.404075
            ],
            [
              -154.765021,
              56.401361
            ],
            [
              -154.742887,
              56.401678
            ],
            [
              -154.73655,
              56.403848
            ],
            [
              -154.704129,
              56.42423
            ],
            [
              -154.691485,
              56.436711
            ],
            [
              -154.668517,
              56.452544
            ],
            [
              -154.633586,
              56.471817
            ],
            [
              -154.571701,
              56.494165
            ],
            [
              -154.529507,
              56.502655
            ],
            [
              -154.436794,
              56.534556
            ],
            [
              -154.39248,
              56.554053
            ],
            [
              -154.391294,
              56.557931
            ],
            [
              -154.393868,
              56.562388
            ],
            [
              -154.404015,
              56.572287
            ],
            [
              -154.399389,
              56.576411
            ],
            [
              -154.402289,
              56.580543
            ],
            [
              -154.413435,
              56.586768
            ],
            [
              -154.449965,
              56.600361
            ],
            [
              -154.514078,
              56.604059
            ],
            [
              -154.524629,
              56.603925
            ],
            [
              -154.534726,
              56.60054
            ],
            [
              -154.70614,
              56.521273
            ],
            [
              -154.739644,
              56.496332
            ],
            [
              -154.777505,
              56.462199
            ],
            [
              -154.806114,
              56.434182
            ]
          ]
        ],
        [
          [
            [
              -155.74905,
              55.825656
            ],
            [
              -155.750002,
              55.821849
            ],
            [
              -155.728111,
              55.779019
            ],
            [
              -155.718593,
              55.772356
            ],
            [
              -155.591002,
              55.761725
            ],
            [
              -155.566307,
              55.789488
            ],
            [
              -155.564404,
              55.809476
            ],
            [
              -155.584391,
              55.848499
            ],
            [
              -155.563452,
              55.885618
            ],
            [
              -155.568211,
              55.90751
            ],
            [
              -155.591054,
              55.912268
            ],
            [
              -155.637691,
              55.894185
            ],
            [
              -155.656727,
              55.860872
            ],
            [
              -155.692895,
              55.841836
            ],
            [
              -155.74905,
              55.825656
            ]
          ]
        ],
        [
          [
            [
              -156.750741,
              56.037116
            ],
            [
              -156.735292,
              56.02264
            ],
            [
              -156.681814,
              55.994337
            ],
            [
              -156.672955,
              56.00671
            ],
            [
              -156.673733,
              56.026891
            ],
            [
              -156.634041,
              56.048003
            ],
            [
              -156.648539,
              56.078919
            ],
            [
              -156.683003,
              56.09881
            ],
            [
              -156.733628,
              56.077593
            ],
            [
              -156.750741,
              56.037116
            ]
          ]
        ],
        [
          [
            [
              -156.75078,
              57.235795
            ],
            [
              -156.750815,
              57.162878
            ],
            [
              -156.470851,
              57.155811
            ],
            [
              -156.355400719262,
              57.1596787197289
            ],
            [
              -156.334404,
              57.1823
            ],
            [
              -156.33843,
              57.190325
            ],
            [
              -156.3413,
              57.191857
            ],
            [
              -156.355756,
              57.192844
            ],
            [
              -156.388592,
              57.20662
            ],
            [
              -156.398751,
              57.214756
            ],
            [
              -156.401488,
              57.233169
            ],
            [
              -156.399423,
              57.241627
            ],
            [
              -156.376507,
              57.252284
            ],
            [
              -156.358139,
              57.252188
            ],
            [
              -156.342943,
              57.248056
            ],
            [
              -156.332718,
              57.265192
            ],
            [
              -156.32191,
              57.293369
            ],
            [
              -156.336427,
              57.336081
            ],
            [
              -156.420864,
              57.311142
            ],
            [
              -156.507301,
              57.281164
            ],
            [
              -156.538684,
              57.283041
            ],
            [
              -156.551239,
              57.2908
            ],
            [
              -156.539718,
              57.320059
            ],
            [
              -156.533544,
              57.328527
            ],
            [
              -156.511412,
              57.33502
            ],
            [
              -156.481632,
              57.338705
            ],
            [
              -156.377439,
              57.390865
            ],
            [
              -156.362039,
              57.400474
            ],
            [
              -156.339425,
              57.417641
            ],
            [
              -156.254462,
              57.438961
            ],
            [
              -156.226886,
              57.440667
            ],
            [
              -156.220105,
              57.445295
            ],
            [
              -156.211485,
              57.459475
            ],
            [
              -156.210883,
              57.474409
            ],
            [
              -156.19574,
              57.480059
            ],
            [
              -156.183932,
              57.482112
            ],
            [
              -156.13748,
              57.471734
            ],
            [
              -156.099067,
              57.443691
            ],
            [
              -156.091668,
              57.439829
            ],
            [
              -156.021875,
              57.43966
            ],
            [
              -156.012841,
              57.451394
            ],
            [
              -156.014396,
              57.455285
            ],
            [
              -156.036722,
              57.470941
            ],
            [
              -156.045324,
              57.487037
            ],
            [
              -156.048584,
              57.500808
            ],
            [
              -156.044031,
              57.564455
            ],
            [
              -156.033806,
              57.569883
            ],
            [
              -156.010818,
              57.571379
            ],
            [
              -155.988113,
              57.558328
            ],
            [
              -155.985988,
              57.553721
            ],
            [
              -155.96789,
              57.544429
            ],
            [
              -155.945812,
              57.539249
            ],
            [
              -155.915261,
              57.535331
            ],
            [
              -155.877856,
              57.547173
            ],
            [
              -155.786939,
              57.547007
            ],
            [
              -155.732779,
              57.549732
            ],
            [
              -155.731412,
              57.555546
            ],
            [
              -155.730951,
              57.588562
            ],
            [
              -155.735509,
              57.594149
            ],
            [
              -155.724167,
              57.633445
            ],
            [
              -155.699986,
              57.642085
            ],
            [
              -155.629912,
              57.656376
            ],
            [
              -155.615203,
              57.688074
            ],
            [
              -155.626373,
              57.693623
            ],
            [
              -155.634543,
              57.704764
            ],
            [
              -155.635323,
              57.715441
            ],
            [
              -155.617188,
              57.769715
            ],
            [
              -155.609353,
              57.777699
            ],
            [
              -155.596857,
              57.783539
            ],
            [
              -155.585411,
              57.786981
            ],
            [
              -155.568437,
              57.789511
            ],
            [
              -155.545676,
              57.786769
            ],
            [
              -155.539766,
              57.783942
            ],
            [
              -155.533627,
              57.77688
            ],
            [
              -155.506533,
              57.76097
            ],
            [
              -155.468287,
              57.744637
            ],
            [
              -155.37861,
              57.710766
            ],
            [
              -155.305814,
              57.72405
            ],
            [
              -155.291651,
              57.735809
            ],
            [
              -155.284691,
              57.757388
            ],
            [
              -155.285339,
              57.758726
            ],
            [
              -155.302789,
              57.761658
            ],
            [
              -155.310981,
              57.764811
            ],
            [
              -155.334944,
              57.780692
            ],
            [
              -155.341235,
              57.819644
            ],
            [
              -155.338153,
              57.825384
            ],
            [
              -155.326369,
              57.830545
            ],
            [
              -155.303044,
              57.828454
            ],
            [
              -155.298385,
              57.82602
            ],
            [
              -155.272917,
              57.823981
            ],
            [
              -155.237933,
              57.827131
            ],
            [
              -155.15242,
              57.855375
            ],
            [
              -155.097095,
              57.865356
            ],
            [
              -155.082139,
              57.872248
            ],
            [
              -155.068148,
              57.883773
            ],
            [
              -155.061806,
              57.90433
            ],
            [
              -155.064199,
              57.909294
            ],
            [
              -155.072566,
              57.911968
            ],
            [
              -155.094686,
              57.92928
            ],
            [
              -155.118648,
              57.953925
            ],
            [
              -155.026275,
              57.999302
            ],
            [
              -154.990431,
              58.013424
            ],
            [
              -154.891812,
              58.027676
            ],
            [
              -154.876559,
              58.027722
            ],
            [
              -154.82823,
              58.018849
            ],
            [
              -154.825051,
              58.016586
            ],
            [
              -154.823518,
              58.009348
            ],
            [
              -154.819518,
              58.003494
            ],
            [
              -154.807767,
              58.000939
            ],
            [
              -154.774719,
              58.002168
            ],
            [
              -154.765287,
              58.00371
            ],
            [
              -154.745581,
              58.01222
            ],
            [
              -154.730726,
              58.021837
            ],
            [
              -154.731059,
              58.028454
            ],
            [
              -154.72898,
              58.038455
            ],
            [
              -154.721884,
              58.050544
            ],
            [
              -154.716162,
              58.055256
            ],
            [
              -154.676108,
              58.065114
            ],
            [
              -154.668895,
              58.065272
            ],
            [
              -154.653383,
              58.064037
            ],
            [
              -154.646223,
              58.060141
            ],
            [
              -154.644666,
              58.056433
            ],
            [
              -154.650618,
              58.045387
            ],
            [
              -154.643965,
              58.033147
            ],
            [
              -154.581547,
              58.019285
            ],
            [
              -154.568627,
              58.025787
            ],
            [
              -154.539367,
              58.055887
            ],
            [
              -154.501246,
              58.050112
            ],
            [
              -154.477979,
              58.052379
            ],
            [
              -154.462929,
              58.05835
            ],
            [
              -154.449212,
              58.093218
            ],
            [
              -154.452096,
              58.121582
            ],
            [
              -154.459389,
              58.129089
            ],
            [
              -154.466436,
              58.142328
            ],
            [
              -154.460121,
              58.146067
            ],
            [
              -154.436518,
              58.148294
            ],
            [
              -154.42657,
              58.144901
            ],
            [
              -154.384327,
              58.120823
            ],
            [
              -154.340449,
              58.090921
            ],
            [
              -154.291163,
              58.13568
            ],
            [
              -154.269027,
              58.12877
            ],
            [
              -154.222465,
              58.132566
            ],
            [
              -154.210078,
              58.136062
            ],
            [
              -154.21625,
              58.142849
            ],
            [
              -154.241874,
              58.156328
            ],
            [
              -154.219755,
              58.184631
            ],
            [
              -154.177652,
              58.189832
            ],
            [
              -154.145277,
              58.210931
            ],
            [
              -154.103412,
              58.280161
            ],
            [
              -154.149073,
              58.314539
            ],
            [
              -154.166648,
              58.320569
            ],
            [
              -154.174999,
              58.320107
            ],
            [
              -154.177161,
              58.32147
            ],
            [
              -154.175194,
              58.344527
            ],
            [
              -154.167997,
              58.358378
            ],
            [
              -154.150373,
              58.357581
            ],
            [
              -154.139223,
              58.354108
            ],
            [
              -154.133866,
              58.350632
            ],
            [
              -154.097254,
              58.345322
            ],
            [
              -153.999323,
              58.376372
            ],
            [
              -153.985416,
              58.390877
            ],
            [
              -154.007305,
              58.402187
            ],
            [
              -154.023713,
              58.403952
            ],
            [
              -154.034147,
              58.402638
            ],
            [
              -154.040013,
              58.404297
            ],
            [
              -154.055759,
              58.418782
            ],
            [
              -154.07066,
              58.440018
            ],
            [
              -154.073592,
              58.446866
            ],
            [
              -154.075235,
              58.458106
            ],
            [
              -154.075051,
              58.472188
            ],
            [
              -154.073032,
              58.478259
            ],
            [
              -154.065121,
              58.48643
            ],
            [
              -154.056526,
              58.489222
            ],
            [
              -154.001918,
              58.492346
            ],
            [
              -153.977617,
              58.491931
            ],
            [
              -153.97402,
              58.488348
            ],
            [
              -153.96037,
              58.487831
            ],
            [
              -153.934852,
              58.494414
            ],
            [
              -153.930473,
              58.497482
            ],
            [
              -153.909588,
              58.514562
            ],
            [
              -153.919134,
              58.516659
            ],
            [
              -153.902558,
              58.597377
            ],
            [
              -153.897155,
              58.606237
            ],
            [
              -153.851432,
              58.611872
            ],
            [
              -153.832837,
              58.611671
            ],
            [
              -153.806232,
              58.606595
            ],
            [
              -153.771636,
              58.605639
            ],
            [
              -153.677597,
              58.611603
            ],
            [
              -153.601257,
              58.634633
            ],
            [
              -153.591635,
              58.640084
            ],
            [
              -153.587799,
              58.651742
            ],
            [
              -153.577544,
              58.670532
            ],
            [
              -153.55265,
              58.687176
            ],
            [
              -153.477755,
              58.712767
            ],
            [
              -153.468963,
              58.712234
            ],
            [
              -153.458816,
              58.708561
            ],
            [
              -153.445002,
              58.70931
            ],
            [
              -153.426641,
              58.721127
            ],
            [
              -153.402472,
              58.742607
            ],
            [
              -153.385126,
              58.766173
            ],
            [
              -153.37073,
              58.799301
            ],
            [
              -153.369389,
              58.821255
            ],
            [
              -153.358917,
              58.836767
            ],
            [
              -153.350964,
              58.843551
            ],
            [
              -153.34483,
              58.846953
            ],
            [
              -153.336826,
              58.848878
            ],
            [
              -153.326138,
              58.84932
            ],
            [
              -153.317823,
              58.847441
            ],
            [
              -153.258597535022,
              58.855036
            ],
            [
              -153.639403,
              58.855029
            ],
            [
              -153.639027,
              58.73945
            ],
            [
              -153.817076,
              58.739446
            ],
            [
              -153.817076,
              58.65276
            ],
            [
              -154.305691,
              58.652751
            ],
            [
              -154.308044,
              58.59636
            ],
            [
              -154.303447,
              58.460385
            ],
            [
              -154.355667,
              58.46073
            ],
            [
              -154.351306,
              58.418083
            ],
            [
              -154.432332,
              58.418998
            ],
            [
              -154.432613,
              58.390748
            ],
            [
              -154.465877,
              58.390759
            ],
            [
              -154.465741,
              58.361356
            ],
            [
              -154.569723,
              58.360808
            ],
            [
              -154.570107,
              58.333055
            ],
            [
              -154.729769,
              58.332837
            ],
            [
              -154.730369,
              58.304669
            ],
            [
              -154.870231,
              58.304849
            ],
            [
              -154.869278,
              58.290549
            ],
            [
              -155.008132,
              58.290905
            ],
            [
              -155.005767,
              58.264832
            ],
            [
              -155.058483,
              58.264944
            ],
            [
              -155.057741,
              58.236209
            ],
            [
              -155.221463,
              58.236408
            ],
            [
              -155.221313,
              58.193193
            ],
            [
              -155.331945,
              58.193283
            ],
            [
              -155.331999,
              58.107967
            ],
            [
              -155.280169,
              58.108591
            ],
            [
              -155.280743,
              58.047874
            ],
            [
              -155.331678,
              58.048211
            ],
            [
              -155.330245,
              57.876343
            ],
            [
              -155.44602,
              57.871459
            ],
            [
              -155.446251,
              57.827094
            ],
            [
              -155.535765,
              57.825867
            ],
            [
              -155.53883,
              57.799806
            ],
            [
              -155.693773,
              57.797987
            ],
            [
              -155.692481,
              57.739054
            ],
            [
              -155.747821,
              57.73983
            ],
            [
              -155.747215,
              57.697284
            ],
            [
              -155.768602,
              57.697518
            ],
            [
              -155.768022,
              57.668261
            ],
            [
              -155.898455,
              57.6681
            ],
            [
              -155.898864,
              57.627929
            ],
            [
              -155.84623,
              57.629144
            ],
            [
              -155.846469,
              57.613286
            ],
            [
              -155.820167,
              57.613321
            ],
            [
              -155.820268,
              57.599487
            ],
            [
              -155.87199,
              57.599963
            ],
            [
              -155.871648,
              57.585208
            ],
            [
              -155.897376,
              57.585214
            ],
            [
              -155.896966,
              57.570378
            ],
            [
              -155.92335,
              57.570603
            ],
            [
              -155.921548,
              57.554334
            ],
            [
              -155.951729,
              57.55383
            ],
            [
              -155.952772,
              57.598213
            ],
            [
              -156.01136,
              57.598924
            ],
            [
              -156.011182,
              57.584577
            ],
            [
              -156.071148,
              57.584424
            ],
            [
              -156.072018,
              57.556051
            ],
            [
              -156.102059,
              57.555793
            ],
            [
              -156.102796,
              57.539309
            ],
            [
              -156.130993,
              57.539131
            ],
            [
              -156.132032,
              57.509692
            ],
            [
              -156.208823,
              57.507875
            ],
            [
              -156.208021,
              57.522963
            ],
            [
              -156.370235,
              57.522368
            ],
            [
              -156.372045,
              57.493232
            ],
            [
              -156.405254,
              57.493154
            ],
            [
              -156.405348,
              57.47819
            ],
            [
              -156.458121,
              57.478905
            ],
            [
              -156.460922,
              57.434033
            ],
            [
              -156.508759,
              57.434831
            ],
            [
              -156.502226,
              57.362606
            ],
            [
              -156.553569,
              57.364386
            ],
            [
              -156.552832,
              57.348852
            ],
            [
              -156.540332,
              57.348683
            ],
            [
              -156.539459,
              57.333901
            ],
            [
              -156.617395,
              57.334942
            ],
            [
              -156.620582,
              57.292731
            ],
            [
              -156.672142,
              57.29298
            ],
            [
              -156.672751,
              57.234909
            ],
            [
              -156.75078,
              57.235795
            ]
          ]
        ],
        [
          [
            [
              -156.804177,
              56.196646
            ],
            [
              -156.804177,
              56.176659
            ],
            [
              -156.796224,
              56.155856
            ],
            [
              -156.771816,
              56.150009
            ],
            [
              -156.759443,
              56.156671
            ],
            [
              -156.76325,
              56.181418
            ],
            [
              -156.766676,
              56.213251
            ],
            [
              -156.771816,
              56.227103
            ],
            [
              -156.795611,
              56.215682
            ],
            [
              -156.804177,
              56.196646
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "02",
      "COUNTYFP": "164",
      "COUNTYNS": "01419975",
      "AFFGEOID": "0500000US02164",
      "GEOID": "02164",
      "NAME": "Lake and Peninsula",
      "LSAD": "04",
      "ALAND": 61894488750,
      "AWATER": 25991759519,
      "County_state": "Lake and Peninsula,02"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -157.32611,
              56.540375
            ],
            [
              -157.328898,
              56.528155
            ],
            [
              -157.326059,
              56.525169
            ],
            [
              -157.168777,
              56.53021
            ],
            [
              -157.150309,
              56.5336
            ],
            [
              -157.142219,
              56.54239
            ],
            [
              -157.121393,
              56.551963
            ],
            [
              -157.113193,
              56.552658
            ],
            [
              -157.053384,
              56.550425
            ],
            [
              -157.017711,
              56.543081
            ],
            [
              -157.006523,
              56.53891
            ],
            [
              -157.003409,
              56.535639
            ],
            [
              -156.98609,
              56.532749
            ],
            [
              -156.972896,
              56.536505
            ],
            [
              -156.975549,
              56.540446
            ],
            [
              -156.990969,
              56.547939
            ],
            [
              -157.02607,
              56.559757
            ],
            [
              -157.077383,
              56.579035
            ],
            [
              -157.091146,
              56.581134
            ],
            [
              -157.146636,
              56.583651
            ],
            [
              -157.250098,
              56.582142
            ],
            [
              -157.288702,
              56.566039
            ],
            [
              -157.298635,
              56.560051
            ],
            [
              -157.32611,
              56.540375
            ]
          ]
        ],
        [
          [
            [
              -157.901008,
              56.348119
            ],
            [
              -157.901959,
              56.342408
            ],
            [
              -157.826768,
              56.310999
            ],
            [
              -157.796311,
              56.320517
            ],
            [
              -157.807733,
              56.357637
            ],
            [
              -157.832479,
              56.37001
            ],
            [
              -157.901008,
              56.348119
            ]
          ]
        ],
        [
          [
            [
              -158.897764,
              55.826303
            ],
            [
              -158.889198,
              55.810123
            ],
            [
              -158.840657,
              55.808219
            ],
            [
              -158.83685,
              55.814882
            ],
            [
              -158.865403,
              55.827255
            ],
            [
              -158.845416,
              55.853905
            ],
            [
              -158.805441,
              55.857712
            ],
            [
              -158.758803,
              55.836773
            ],
            [
              -158.731202,
              55.826303
            ],
            [
              -158.711214,
              55.8244
            ],
            [
              -158.701696,
              55.832014
            ],
            [
              -158.7036,
              55.841532
            ],
            [
              -158.750237,
              55.875796
            ],
            [
              -158.800682,
              55.891025
            ],
            [
              -158.86921,
              55.880555
            ],
            [
              -158.881584,
              55.866278
            ],
            [
              -158.897764,
              55.826303
            ]
          ]
        ],
        [
          [
            [
              -159.145228,
              55.876748
            ],
            [
              -159.183299,
              55.844387
            ],
            [
              -159.134758,
              55.828207
            ],
            [
              -159.086217,
              55.834869
            ],
            [
              -159.096687,
              55.854857
            ],
            [
              -159.116674,
              55.868182
            ],
            [
              -159.145228,
              55.876748
            ]
          ]
        ],
        [
          [
            [
              -159.384126,
              55.749209
            ],
            [
              -159.394595,
              55.714944
            ],
            [
              -159.352826,
              55.746422
            ],
            [
              -159.308935,
              55.75016
            ],
            [
              -159.279429,
              55.762534
            ],
            [
              -159.275622,
              55.773955
            ],
            [
              -159.290851,
              55.793943
            ],
            [
              -159.321308,
              55.815834
            ],
            [
              -159.355572,
              55.807268
            ],
            [
              -159.384126,
              55.749209
            ]
          ]
        ],
        [
          [
            [
              -159.900149,
              56.494239
            ],
            [
              -159.900133,
              56.320803
            ],
            [
              -159.806389,
              56.320805
            ],
            [
              -159.806406,
              55.973919
            ],
            [
              -159.86797,
              55.973918
            ],
            [
              -159.867971,
              55.887193
            ],
            [
              -159.56136,
              55.887197
            ],
            [
              -159.561451,
              55.638443
            ],
            [
              -159.556676333447,
              55.638446264729296
            ],
            [
              -159.545115,
              55.646517
            ],
            [
              -159.530117,
              55.665394
            ],
            [
              -159.535961,
              55.689831
            ],
            [
              -159.552016,
              55.704794
            ],
            [
              -159.551432,
              55.711543
            ],
            [
              -159.537152,
              55.728459
            ],
            [
              -159.521589,
              55.736021
            ],
            [
              -159.503768,
              55.747878
            ],
            [
              -159.494404,
              55.765798
            ],
            [
              -159.498022,
              55.855299
            ],
            [
              -159.534415,
              55.881299
            ],
            [
              -159.528349,
              55.888458
            ],
            [
              -159.493883,
              55.900109
            ],
            [
              -159.482226,
              55.901826
            ],
            [
              -159.453945,
              55.89682
            ],
            [
              -159.465282,
              55.852845
            ],
            [
              -159.471973,
              55.843506
            ],
            [
              -159.472801,
              55.83905
            ],
            [
              -159.470216,
              55.828911
            ],
            [
              -159.434787,
              55.792909
            ],
            [
              -159.423468,
              55.789025
            ],
            [
              -159.411505,
              55.788911
            ],
            [
              -159.404326,
              55.796992
            ],
            [
              -159.40938,
              55.810434
            ],
            [
              -159.406126,
              55.831956
            ],
            [
              -159.400096,
              55.852357
            ],
            [
              -159.3964,
              55.856767
            ],
            [
              -159.374842,
              55.871522
            ],
            [
              -159.347681,
              55.877802
            ],
            [
              -159.271514,
              55.890704
            ],
            [
              -159.138748,
              55.90932
            ],
            [
              -158.999598,
              55.927011
            ],
            [
              -158.909396,
              55.934887
            ],
            [
              -158.898116,
              55.951041
            ],
            [
              -158.854132,
              56.003343
            ],
            [
              -158.747305,
              56.009908
            ],
            [
              -158.729567,
              56.002854
            ],
            [
              -158.735348,
              55.996208
            ],
            [
              -158.751215,
              55.963759
            ],
            [
              -158.74856,
              55.959365
            ],
            [
              -158.737009,
              55.953313
            ],
            [
              -158.673246,
              55.951485
            ],
            [
              -158.653214,
              55.958615
            ],
            [
              -158.639497,
              55.98607
            ],
            [
              -158.636689,
              56.005007
            ],
            [
              -158.638704,
              56.015932
            ],
            [
              -158.643216,
              56.023415
            ],
            [
              -158.651674,
              56.031358
            ],
            [
              -158.660914,
              56.034928
            ],
            [
              -158.666818,
              56.078415
            ],
            [
              -158.659738,
              56.098553
            ],
            [
              -158.640447,
              56.114079
            ],
            [
              -158.628303,
              56.120943
            ],
            [
              -158.600405,
              56.130444
            ],
            [
              -158.575042,
              56.121129
            ],
            [
              -158.574659,
              56.11864
            ],
            [
              -158.584362,
              56.115657
            ],
            [
              -158.594188,
              56.110445
            ],
            [
              -158.598367,
              56.048822
            ],
            [
              -158.59562,
              56.045252
            ],
            [
              -158.50984,
              55.979617
            ],
            [
              -158.49905,
              55.981685
            ],
            [
              -158.495114,
              55.989207
            ],
            [
              -158.494015,
              56.00732
            ],
            [
              -158.496366,
              56.010601
            ],
            [
              -158.50485,
              56.015544
            ],
            [
              -158.501967,
              56.02517
            ],
            [
              -158.475543,
              56.028366
            ],
            [
              -158.467335,
              56.027219
            ],
            [
              -158.445696,
              55.99758
            ],
            [
              -158.43933,
              55.99362
            ],
            [
              -158.431471,
              55.994452
            ],
            [
              -158.413645,
              56.004951
            ],
            [
              -158.407723,
              56.014521
            ],
            [
              -158.417889,
              56.036796
            ],
            [
              -158.448413,
              56.055278
            ],
            [
              -158.472706,
              56.087583
            ],
            [
              -158.475258,
              56.093405
            ],
            [
              -158.46181,
              56.106644
            ],
            [
              -158.455297,
              56.108742
            ],
            [
              -158.439944,
              56.10778
            ],
            [
              -158.432229,
              56.102881
            ],
            [
              -158.431105,
              56.100264
            ],
            [
              -158.438315,
              56.095702
            ],
            [
              -158.438644,
              56.093672
            ],
            [
              -158.424451,
              56.068899
            ],
            [
              -158.398324,
              56.062957
            ],
            [
              -158.394922,
              56.064721
            ],
            [
              -158.394388,
              56.091949
            ],
            [
              -158.395996,
              56.109834
            ],
            [
              -158.374324,
              56.134522
            ],
            [
              -158.314128,
              56.163697
            ],
            [
              -158.237025,
              56.187387
            ],
            [
              -158.117797,
              56.230742
            ],
            [
              -158.112718,
              56.240286
            ],
            [
              -158.115282,
              56.242102
            ],
            [
              -158.119493,
              56.241995
            ],
            [
              -158.17493,
              56.236227
            ],
            [
              -158.19096,
              56.226407
            ],
            [
              -158.264792,
              56.209597
            ],
            [
              -158.331039,
              56.213609
            ],
            [
              -158.339765,
              56.217807
            ],
            [
              -158.334506,
              56.23294
            ],
            [
              -158.276842,
              56.248698
            ],
            [
              -158.253331,
              56.253125
            ],
            [
              -158.21654,
              56.269451
            ],
            [
              -158.203083,
              56.283833
            ],
            [
              -158.207387,
              56.294354
            ],
            [
              -158.322563,
              56.325242
            ],
            [
              -158.329735,
              56.326028
            ],
            [
              -158.338137,
              56.323923
            ],
            [
              -158.397337,
              56.328921
            ],
            [
              -158.432795,
              56.343505
            ],
            [
              -158.489546,
              56.341865
            ],
            [
              -158.50204,
              56.365178
            ],
            [
              -158.501705,
              56.37586
            ],
            [
              -158.498837,
              56.38011
            ],
            [
              -158.402954,
              56.455193
            ],
            [
              -158.328798,
              56.484208
            ],
            [
              -158.284699,
              56.481089
            ],
            [
              -158.246144,
              56.466124
            ],
            [
              -158.12744,
              56.460805
            ],
            [
              -158.118682,
              56.466558
            ],
            [
              -158.111603,
              56.49011
            ],
            [
              -158.113709,
              56.493001
            ],
            [
              -158.123352,
              56.496457
            ],
            [
              -158.131729,
              56.501944
            ],
            [
              -158.11957,
              56.518346
            ],
            [
              -158.112276,
              56.521322
            ],
            [
              -158.027621,
              56.511779
            ],
            [
              -157.986398,
              56.499105
            ],
            [
              -157.900056,
              56.477941
            ],
            [
              -157.872074,
              56.473974
            ],
            [
              -157.859766,
              56.483668
            ],
            [
              -157.823072,
              56.501982
            ],
            [
              -157.817826,
              56.51421
            ],
            [
              -157.828139,
              56.546332
            ],
            [
              -157.83842,
              56.56076
            ],
            [
              -157.911173,
              56.57479
            ],
            [
              -158.005531,
              56.553443
            ],
            [
              -158.074358,
              56.554164
            ],
            [
              -158.085748,
              56.57335
            ],
            [
              -158.059722,
              56.583758
            ],
            [
              -158.042012,
              56.596744
            ],
            [
              -157.983394,
              56.599448
            ],
            [
              -157.95134,
              56.623005
            ],
            [
              -157.918541,
              56.643137
            ],
            [
              -157.848126,
              56.653554
            ],
            [
              -157.763698,
              56.679247
            ],
            [
              -157.754141,
              56.679468
            ],
            [
              -157.736799,
              56.675616
            ],
            [
              -157.719048,
              56.653084
            ],
            [
              -157.715998,
              56.648492
            ],
            [
              -157.71428,
              56.640575
            ],
            [
              -157.705382,
              56.62878
            ],
            [
              -157.674587,
              56.609507
            ],
            [
              -157.636018,
              56.612838
            ],
            [
              -157.615041,
              56.62002
            ],
            [
              -157.605231,
              56.621315
            ],
            [
              -157.589315,
              56.622262
            ],
            [
              -157.546085,
              56.619025
            ],
            [
              -157.536486,
              56.615317
            ],
            [
              -157.496523,
              56.616897
            ],
            [
              -157.466497,
              56.623266
            ],
            [
              -157.462105,
              56.625685
            ],
            [
              -157.45486,
              56.634748
            ],
            [
              -157.452196,
              56.638863
            ],
            [
              -157.45216,
              56.64322
            ],
            [
              -157.479153,
              56.67008
            ],
            [
              -157.48099,
              56.671419
            ],
            [
              -157.498689,
              56.667285
            ],
            [
              -157.507589,
              56.667169
            ],
            [
              -157.542295,
              56.67532
            ],
            [
              -157.550792,
              56.681029
            ],
            [
              -157.563802,
              56.703426
            ],
            [
              -157.551196,
              56.730314
            ],
            [
              -157.544855,
              56.738945
            ],
            [
              -157.530765,
              56.753775
            ],
            [
              -157.517478,
              56.760839
            ],
            [
              -157.41344,
              56.769185
            ],
            [
              -157.411488,
              56.778351
            ],
            [
              -157.41856,
              56.799821
            ],
            [
              -157.42112,
              56.801691
            ],
            [
              -157.436358,
              56.803781
            ],
            [
              -157.447768,
              56.801246
            ],
            [
              -157.457622,
              56.804291
            ],
            [
              -157.462361,
              56.809603
            ],
            [
              -157.469925,
              56.824889
            ],
            [
              -157.472407,
              56.833356
            ],
            [
              -157.45759,
              56.848204
            ],
            [
              -157.436932,
              56.858522
            ],
            [
              -157.405679,
              56.864216
            ],
            [
              -157.394663,
              56.864426
            ],
            [
              -157.378771,
              56.861696
            ],
            [
              -157.332735,
              56.838398
            ],
            [
              -157.298283,
              56.818567
            ],
            [
              -157.291231,
              56.811077
            ],
            [
              -157.290511,
              56.804713
            ],
            [
              -157.283764,
              56.800766
            ],
            [
              -157.201724,
              56.767511
            ],
            [
              -157.183636,
              56.769079
            ],
            [
              -157.161372,
              56.774134
            ],
            [
              -157.140277,
              56.790874
            ],
            [
              -157.14099,
              56.802275
            ],
            [
              -157.163272,
              56.823542
            ],
            [
              -157.163811,
              56.826066
            ],
            [
              -157.159494,
              56.833477
            ],
            [
              -157.073453,
              56.838345
            ],
            [
              -157.034624,
              56.884487
            ],
            [
              -157.015665,
              56.898486
            ],
            [
              -157.00595,
              56.90422
            ],
            [
              -156.986171,
              56.911131
            ],
            [
              -156.935629,
              56.920087
            ],
            [
              -156.935692,
              56.954873
            ],
            [
              -156.918796,
              56.963583
            ],
            [
              -156.909725,
              56.965581
            ],
            [
              -156.893683,
              56.965965
            ],
            [
              -156.886307,
              56.964869
            ],
            [
              -156.882893,
              56.962582
            ],
            [
              -156.882464,
              56.960072
            ],
            [
              -156.885686,
              56.957965
            ],
            [
              -156.885372,
              56.953284
            ],
            [
              -156.876316,
              56.942828
            ],
            [
              -156.839322,
              56.901854
            ],
            [
              -156.825982,
              56.897667
            ],
            [
              -156.804432,
              56.905881
            ],
            [
              -156.79731,
              56.911717
            ],
            [
              -156.786802,
              56.941443
            ],
            [
              -156.788341,
              56.960693
            ],
            [
              -156.7869,
              56.965035
            ],
            [
              -156.781421,
              56.971879
            ],
            [
              -156.762718,
              56.986342
            ],
            [
              -156.753642,
              56.991225
            ],
            [
              -156.730617,
              56.979845
            ],
            [
              -156.671376,
              56.996064
            ],
            [
              -156.63784,
              56.993905
            ],
            [
              -156.555077,
              56.98355
            ],
            [
              -156.55052,
              56.98461
            ],
            [
              -156.5472,
              56.986488
            ],
            [
              -156.547667,
              57.004629
            ],
            [
              -156.562827,
              57.020314
            ],
            [
              -156.535587,
              57.047905
            ],
            [
              -156.509239,
              57.054911
            ],
            [
              -156.441599,
              57.085158
            ],
            [
              -156.441566,
              57.094698
            ],
            [
              -156.44461,
              57.100087
            ],
            [
              -156.456497,
              57.106041
            ],
            [
              -156.43511,
              57.12743
            ],
            [
              -156.368524,
              57.149986
            ],
            [
              -156.357358,
              57.15757
            ],
            [
              -156.355400719262,
              57.1596787197289
            ],
            [
              -156.470851,
              57.155811
            ],
            [
              -156.750815,
              57.162878
            ],
            [
              -156.75078,
              57.235795
            ],
            [
              -156.672751,
              57.234909
            ],
            [
              -156.672142,
              57.29298
            ],
            [
              -156.620582,
              57.292731
            ],
            [
              -156.617395,
              57.334942
            ],
            [
              -156.539459,
              57.333901
            ],
            [
              -156.540332,
              57.348683
            ],
            [
              -156.552832,
              57.348852
            ],
            [
              -156.553569,
              57.364386
            ],
            [
              -156.502226,
              57.362606
            ],
            [
              -156.508759,
              57.434831
            ],
            [
              -156.460922,
              57.434033
            ],
            [
              -156.458121,
              57.478905
            ],
            [
              -156.405348,
              57.47819
            ],
            [
              -156.405254,
              57.493154
            ],
            [
              -156.372045,
              57.493232
            ],
            [
              -156.370235,
              57.522368
            ],
            [
              -156.208021,
              57.522963
            ],
            [
              -156.208823,
              57.507875
            ],
            [
              -156.132032,
              57.509692
            ],
            [
              -156.130993,
              57.539131
            ],
            [
              -156.102796,
              57.539309
            ],
            [
              -156.102059,
              57.555793
            ],
            [
              -156.072018,
              57.556051
            ],
            [
              -156.071148,
              57.584424
            ],
            [
              -156.011182,
              57.584577
            ],
            [
              -156.01136,
              57.598924
            ],
            [
              -155.952772,
              57.598213
            ],
            [
              -155.951729,
              57.55383
            ],
            [
              -155.921548,
              57.554334
            ],
            [
              -155.92335,
              57.570603
            ],
            [
              -155.896966,
              57.570378
            ],
            [
              -155.897376,
              57.585214
            ],
            [
              -155.871648,
              57.585208
            ],
            [
              -155.87199,
              57.599963
            ],
            [
              -155.820268,
              57.599487
            ],
            [
              -155.820167,
              57.613321
            ],
            [
              -155.846469,
              57.613286
            ],
            [
              -155.84623,
              57.629144
            ],
            [
              -155.898864,
              57.627929
            ],
            [
              -155.898455,
              57.6681
            ],
            [
              -155.768022,
              57.668261
            ],
            [
              -155.768602,
              57.697518
            ],
            [
              -155.747215,
              57.697284
            ],
            [
              -155.747821,
              57.73983
            ],
            [
              -155.692481,
              57.739054
            ],
            [
              -155.693773,
              57.797987
            ],
            [
              -155.53883,
              57.799806
            ],
            [
              -155.535765,
              57.825867
            ],
            [
              -155.446251,
              57.827094
            ],
            [
              -155.44602,
              57.871459
            ],
            [
              -155.330245,
              57.876343
            ],
            [
              -155.331678,
              58.048211
            ],
            [
              -155.280743,
              58.047874
            ],
            [
              -155.280169,
              58.108591
            ],
            [
              -155.331999,
              58.107967
            ],
            [
              -155.331945,
              58.193283
            ],
            [
              -155.221313,
              58.193193
            ],
            [
              -155.221463,
              58.236408
            ],
            [
              -155.057741,
              58.236209
            ],
            [
              -155.058483,
              58.264944
            ],
            [
              -155.005767,
              58.264832
            ],
            [
              -155.008132,
              58.290905
            ],
            [
              -154.869278,
              58.290549
            ],
            [
              -154.870231,
              58.304849
            ],
            [
              -154.730369,
              58.304669
            ],
            [
              -154.729769,
              58.332837
            ],
            [
              -154.570107,
              58.333055
            ],
            [
              -154.569723,
              58.360808
            ],
            [
              -154.465741,
              58.361356
            ],
            [
              -154.465877,
              58.390759
            ],
            [
              -154.432613,
              58.390748
            ],
            [
              -154.432332,
              58.418998
            ],
            [
              -154.351306,
              58.418083
            ],
            [
              -154.355667,
              58.46073
            ],
            [
              -154.303447,
              58.460385
            ],
            [
              -154.308044,
              58.59636
            ],
            [
              -154.305691,
              58.652751
            ],
            [
              -154.642545,
              58.652747
            ],
            [
              -154.642558,
              58.739431
            ],
            [
              -154.695036,
              58.739431
            ],
            [
              -154.695105,
              59.086159
            ],
            [
              -154.748834,
              59.086159
            ],
            [
              -154.748861,
              59.259518
            ],
            [
              -154.412013,
              59.259524
            ],
            [
              -154.412028,
              59.432882
            ],
            [
              -154.123321,
              59.432887
            ],
            [
              -154.123327,
              59.519552
            ],
            [
              -153.953181,
              59.519569
            ],
            [
              -153.953198,
              59.692918
            ],
            [
              -153.783068,
              59.692926
            ],
            [
              -153.78307,
              59.779597
            ],
            [
              -153.656977,
              59.779607
            ],
            [
              -153.65698,
              60.126277
            ],
            [
              -153.528392,
              60.126281
            ],
            [
              -153.528441,
              60.472939
            ],
            [
              -153.397276,
              60.472948
            ],
            [
              -153.397284,
              60.819581
            ],
            [
              -153.440886,
              60.819578
            ],
            [
              -153.440884,
              60.906236
            ],
            [
              -154.258103,
              60.906212
            ],
            [
              -154.948166,
              60.906201
            ],
            [
              -155.906894,
              60.906191
            ],
            [
              -156.000086,
              60.90619
            ],
            [
              -156.000145,
              59.692879
            ],
            [
              -156.165068,
              59.692877
            ],
            [
              -156.165066,
              59.606202
            ],
            [
              -156.505349,
              59.606199
            ],
            [
              -156.505345,
              59.519523
            ],
            [
              -156.675489,
              59.519521
            ],
            [
              -156.675483,
              59.432844
            ],
            [
              -156.769971,
              59.432843
            ],
            [
              -156.769965,
              59.346166
            ],
            [
              -157.106814,
              59.346162
            ],
            [
              -157.106808,
              59.259483
            ],
            [
              -157.275233,
              59.259482
            ],
            [
              -157.275221,
              59.086121
            ],
            [
              -157.196306,
              59.086122
            ],
            [
              -157.19745563325102,
              58.8458516065572
            ],
            [
              -157.189554,
              58.847724
            ],
            [
              -157.116866,
              58.867533
            ],
            [
              -157.070584,
              58.887816
            ],
            [
              -157.040625,
              58.913391
            ],
            [
              -157.039206,
              58.945921
            ],
            [
              -157.029517,
              58.956203
            ],
            [
              -156.999681,
              58.973355
            ],
            [
              -156.971325,
              58.983097
            ],
            [
              -156.945893,
              58.983403
            ],
            [
              -156.929255,
              58.973788
            ],
            [
              -156.963243,
              58.962638
            ],
            [
              -156.975946,
              58.940896
            ],
            [
              -156.966649,
              58.904074
            ],
            [
              -156.980888,
              58.891031
            ],
            [
              -156.991700361036,
              58.8858341751265
            ],
            [
              -156.313889,
              58.885833
            ],
            [
              -156.313889,
              58.616666
            ],
            [
              -157.257442167593,
              58.6166710680046
            ],
            [
              -157.281327,
              58.600236
            ],
            [
              -157.313572,
              58.565043
            ],
            [
              -157.330683,
              58.551516
            ],
            [
              -157.358487,
              58.533876
            ],
            [
              -157.380259,
              58.524398
            ],
            [
              -157.397197,
              58.527333
            ],
            [
              -157.451918,
              58.505618
            ],
            [
              -157.46088,
              58.499693
            ],
            [
              -157.481487,
              58.480771
            ],
            [
              -157.488108,
              58.471705
            ],
            [
              -157.524477,
              58.414506
            ],
            [
              -157.536176,
              58.391597
            ],
            [
              -157.556865,
              58.330715
            ],
            [
              -157.556343,
              58.303749
            ],
            [
              -157.547209,
              58.277535
            ],
            [
              -157.541564,
              58.271883
            ],
            [
              -157.515475,
              58.255638
            ],
            [
              -157.442712,
              58.218875
            ],
            [
              -157.423325,
              58.21136
            ],
            [
              -157.407918,
              58.211871
            ],
            [
              -157.389237,
              58.228091
            ],
            [
              -157.374511,
              58.232117
            ],
            [
              -157.366928,
              58.232669
            ],
            [
              -157.352316,
              58.219097
            ],
            [
              -157.383099,
              58.184607
            ],
            [
              -157.39735,
              58.173383
            ],
            [
              -157.493784,
              58.162148
            ],
            [
              -157.514474,
              58.162978
            ],
            [
              -157.533329,
              58.160335
            ],
            [
              -157.556556,
              58.148445
            ],
            [
              -157.580924,
              58.128096
            ],
            [
              -157.583636,
              58.124307
            ],
            [
              -157.596601,
              58.08867
            ],
            [
              -157.611802,
              58.034263
            ],
            [
              -157.623886,
              57.960502
            ],
            [
              -157.642226,
              57.868777
            ],
            [
              -157.671061,
              57.772866
            ],
            [
              -157.703782,
              57.721768
            ],
            [
              -157.710645,
              57.639946
            ],
            [
              -157.691291,
              57.611131
            ],
            [
              -157.684282,
              57.609974
            ],
            [
              -157.652202,
              57.614794
            ],
            [
              -157.607387,
              57.612537
            ],
            [
              -157.599644,
              57.60795
            ],
            [
              -157.588339,
              57.582152
            ],
            [
              -157.573472,
              57.522732
            ],
            [
              -157.573129,
              57.514525
            ],
            [
              -157.58691,
              57.487156
            ],
            [
              -157.615137,
              57.488691
            ],
            [
              -157.649389,
              57.500331
            ],
            [
              -157.680416,
              57.537727
            ],
            [
              -157.684833,
              57.557746
            ],
            [
              -157.678891,
              57.563888
            ],
            [
              -157.703852,
              57.563455
            ],
            [
              -157.772496,
              57.547055
            ],
            [
              -157.786046,
              57.542189
            ],
            [
              -157.881163,
              57.489122
            ],
            [
              -157.931624,
              57.476208
            ],
            [
              -157.937241,
              57.472048
            ],
            [
              -157.956239,
              57.449383
            ],
            [
              -157.99467,
              57.414234
            ],
            [
              -158.010538,
              57.401456
            ],
            [
              -158.034246,
              57.39023
            ],
            [
              -158.049932,
              57.390141
            ],
            [
              -158.060041,
              57.387456
            ],
            [
              -158.083785,
              57.357181
            ],
            [
              -158.14971,
              57.344916
            ],
            [
              -158.229883,
              57.321534
            ],
            [
              -158.307085,
              57.289555
            ],
            [
              -158.393125,
              57.236921
            ],
            [
              -158.459675,
              57.188655
            ],
            [
              -158.540248,
              57.125106
            ],
            [
              -158.608344,
              57.064484
            ],
            [
              -158.645303,
              57.031841
            ],
            [
              -158.679293,
              56.988625
            ],
            [
              -158.699788,
              56.927362
            ],
            [
              -158.686184,
              56.911555
            ],
            [
              -158.693552,
              56.888127
            ],
            [
              -158.663659,
              56.857055
            ],
            [
              -158.646812,
              56.846992
            ],
            [
              -158.657543,
              56.831539
            ],
            [
              -158.656355,
              56.810012
            ],
            [
              -158.674181,
              56.797064
            ],
            [
              -158.744534,
              56.795112
            ],
            [
              -158.78359,
              56.78075
            ],
            [
              -158.853294,
              56.79262
            ],
            [
              -158.868797,
              56.796648
            ],
            [
              -158.893212,
              56.805788
            ],
            [
              -158.893551103795,
              56.8059623912595
            ],
            [
              -158.893607,
              56.754404
            ],
            [
              -159.051094,
              56.754401
            ],
            [
              -159.051089,
              56.667687
            ],
            [
              -159.275932,
              56.667682
            ],
            [
              -159.275928,
              56.580967
            ],
            [
              -159.588041,
              56.580961
            ],
            [
              -159.588036,
              56.494245
            ],
            [
              -159.900149,
              56.494239
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "02",
      "COUNTYFP": "261",
      "COUNTYNS": "01419984",
      "AFFGEOID": "0500000US02261",
      "GEOID": "02261",
      "NAME": "Valdez-Cordova",
      "LSAD": "05",
      "ALAND": 88635832004,
      "AWATER": 15763249807,
      "County_state": "Valdez-Cordova,02"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -145.657221,
              60.367925
            ],
            [
              -145.671244,
              60.363577
            ],
            [
              -145.640108,
              60.332769
            ],
            [
              -145.609447,
              60.310292
            ],
            [
              -145.566427,
              60.301461
            ],
            [
              -145.533627,
              60.310292
            ],
            [
              -145.51081,
              60.318296
            ],
            [
              -145.519604,
              60.333004
            ],
            [
              -145.537906,
              60.336886
            ],
            [
              -145.556445,
              60.355466
            ],
            [
              -145.588294,
              60.365457
            ],
            [
              -145.657221,
              60.367925
            ]
          ]
        ],
        [
          [
            [
              -146.011601,
              60.396234
            ],
            [
              -145.988546,
              60.387427
            ],
            [
              -145.910112,
              60.376385
            ],
            [
              -145.86733,
              60.357347
            ],
            [
              -145.831202,
              60.350293
            ],
            [
              -145.780101,
              60.352527
            ],
            [
              -145.754432,
              60.359345
            ],
            [
              -145.744449,
              60.38543
            ],
            [
              -145.76489,
              60.39142
            ],
            [
              -145.83144,
              60.39095
            ],
            [
              -145.878738,
              60.401517
            ],
            [
              -145.953132,
              60.407856
            ],
            [
              -146.011601,
              60.396234
            ]
          ]
        ],
        [
          [
            [
              -147.321084,
              60.880198
            ],
            [
              -147.32564,
              60.877153
            ],
            [
              -147.309086,
              60.873924
            ],
            [
              -147.253128,
              60.872969
            ],
            [
              -147.217749,
              60.869741
            ],
            [
              -147.215036283875,
              60.868875109986
            ],
            [
              -147.19331725523298,
              60.8619424678842
            ],
            [
              -147.192354,
              60.861635
            ],
            [
              -147.141802,
              60.853991
            ],
            [
              -147.126799,
              60.858011
            ],
            [
              -147.089645,
              60.874693
            ],
            [
              -147.071788,
              60.893833
            ],
            [
              -147.077772,
              60.899503
            ],
            [
              -147.115336,
              60.911938
            ],
            [
              -147.131319,
              60.912932
            ],
            [
              -147.149219615253,
              60.909465328907494
            ],
            [
              -147.17089852603002,
              60.9052669450952
            ],
            [
              -147.171486728523,
              60.9051530325537
            ],
            [
              -147.178969,
              60.903704
            ],
            [
              -147.193399,
              60.902949
            ],
            [
              -147.203081370867,
              60.9062824815386
            ],
            [
              -147.210324,
              60.908776
            ],
            [
              -147.226303,
              60.910421
            ],
            [
              -147.24197910753003,
              60.905422329402796
            ],
            [
              -147.321084,
              60.880198
            ]
          ]
        ],
        [
          [
            [
              -147.500009,
              60.653852
            ],
            [
              -147.483828,
              60.618636
            ],
            [
              -147.454323,
              60.619588
            ],
            [
              -147.348675,
              60.627202
            ],
            [
              -147.3087,
              60.665274
            ],
            [
              -147.362,
              60.714767
            ],
            [
              -147.383891,
              60.741417
            ],
            [
              -147.395312,
              60.74332
            ],
            [
              -147.487635,
              60.728092
            ],
            [
              -147.483828,
              60.683358
            ],
            [
              -147.500009,
              60.653852
            ]
          ]
        ],
        [
          [
            [
              -147.505719,
              60.253151
            ],
            [
              -147.499057,
              60.235067
            ],
            [
              -147.483828,
              60.224598
            ],
            [
              -147.340109,
              60.275042
            ],
            [
              -147.341061,
              60.305499
            ],
            [
              -147.421962,
              60.279801
            ],
            [
              -147.496201,
              60.265524
            ],
            [
              -147.505719,
              60.253151
            ]
          ]
        ],
        [
          [
            [
              -147.92924,
              59.783875
            ],
            [
              -147.9088,
              59.771432
            ],
            [
              -147.876475,
              59.763893
            ],
            [
              -147.832386,
              59.770116
            ],
            [
              -147.803151,
              59.785071
            ],
            [
              -147.765122,
              59.795954
            ],
            [
              -147.714496,
              59.803845
            ],
            [
              -147.68241,
              59.798943
            ],
            [
              -147.646045,
              59.817828
            ],
            [
              -147.651274,
              59.83312
            ],
            [
              -147.62394,
              59.848285
            ],
            [
              -147.567967,
              59.85115
            ],
            [
              -147.542773,
              59.835031
            ],
            [
              -147.508309,
              59.841957
            ],
            [
              -147.461486,
              59.841659
            ],
            [
              -147.411811,
              59.86404
            ],
            [
              -147.391846,
              59.87776
            ],
            [
              -147.44366,
              59.903157
            ],
            [
              -147.470281,
              59.906732
            ],
            [
              -147.501654,
              59.926867
            ],
            [
              -147.481689,
              59.943656
            ],
            [
              -147.452217,
              59.954011
            ],
            [
              -147.421081,
              59.966147
            ],
            [
              -147.372594,
              59.95294
            ],
            [
              -147.339794,
              59.962102
            ],
            [
              -147.38329,
              59.978279
            ],
            [
              -147.369267,
              59.993735
            ],
            [
              -147.376397,
              60.016192
            ],
            [
              -147.353342,
              60.047061
            ],
            [
              -147.306519,
              60.076241
            ],
            [
              -147.257795,
              60.107883
            ],
            [
              -147.212873,
              60.139139
            ],
            [
              -147.179123,
              60.158305
            ],
            [
              -147.089993,
              60.192467
            ],
            [
              -147.02059,
              60.22624
            ],
            [
              -146.97971,
              60.247006
            ],
            [
              -146.955704,
              60.259153
            ],
            [
              -146.916487,
              60.290973
            ],
            [
              -146.923142,
              60.309461
            ],
            [
              -146.945721,
              60.309932
            ],
            [
              -146.998724,
              60.297097
            ],
            [
              -147.028909,
              60.286378
            ],
            [
              -147.062422,
              60.300866
            ],
            [
              -147.036515,
              60.312875
            ],
            [
              -147.006567,
              60.332292
            ],
            [
              -147.002051,
              60.344289
            ],
            [
              -147.045547,
              60.342172
            ],
            [
              -147.089042,
              60.342878
            ],
            [
              -147.100926,
              60.356517
            ],
            [
              -147.085477,
              60.368741
            ],
            [
              -147.102352,
              60.38002
            ],
            [
              -147.157732,
              60.367448
            ],
            [
              -147.200039,
              60.350286
            ],
            [
              -147.215488,
              60.327586
            ],
            [
              -147.221905,
              60.304045
            ],
            [
              -147.233314,
              60.289559
            ],
            [
              -147.200276,
              60.276599
            ],
            [
              -147.225946,
              60.254908
            ],
            [
              -147.278473,
              60.233321
            ],
            [
              -147.319829,
              60.217741
            ],
            [
              -147.331476,
              60.184432
            ],
            [
              -147.374258,
              60.160315
            ],
            [
              -147.374971,
              60.146594
            ],
            [
              -147.401353,
              60.11641
            ],
            [
              -147.485254,
              60.074344
            ],
            [
              -147.555132,
              60.051807
            ],
            [
              -147.58817,
              60.025812
            ],
            [
              -147.639033,
              60.00954
            ],
            [
              -147.695363,
              59.996706
            ],
            [
              -147.707247,
              59.982917
            ],
            [
              -147.679914,
              59.976376
            ],
            [
              -147.681815,
              59.961745
            ],
            [
              -147.712476,
              59.951988
            ],
            [
              -147.740047,
              59.955082
            ],
            [
              -147.776174,
              59.93044
            ],
            [
              -147.806122,
              59.920911
            ],
            [
              -147.814916,
              59.908758
            ],
            [
              -147.800655,
              59.894336
            ],
            [
              -147.805171,
              59.873227
            ],
            [
              -147.855084,
              59.871915
            ],
            [
              -147.887171,
              59.86225
            ],
            [
              -147.913316,
              59.837181
            ],
            [
              -147.898579,
              59.815797
            ],
            [
              -147.897629,
              59.801932
            ],
            [
              -147.92924,
              59.783875
            ]
          ]
        ],
        [
          [
            [
              -147.950532,
              60.243791
            ],
            [
              -147.956228,
              60.228667
            ],
            [
              -147.945158,
              60.222324
            ],
            [
              -147.908985,
              60.224359
            ],
            [
              -147.855453,
              60.216419
            ],
            [
              -147.828962,
              60.207442
            ],
            [
              -147.827991,
              60.20063
            ],
            [
              -147.832285,
              60.197855
            ],
            [
              -147.845681,
              60.195434
            ],
            [
              -147.820159,
              60.179555
            ],
            [
              -147.783583,
              60.161073
            ],
            [
              -147.766484,
              60.15418
            ],
            [
              -147.760681,
              60.156396
            ],
            [
              -147.720124,
              60.202002
            ],
            [
              -147.704731,
              60.227874
            ],
            [
              -147.698608,
              60.245552
            ],
            [
              -147.703599,
              60.285589
            ],
            [
              -147.671135,
              60.308929
            ],
            [
              -147.639474,
              60.340579
            ],
            [
              -147.618906,
              60.368848
            ],
            [
              -147.62202,
              60.383794
            ],
            [
              -147.630081,
              60.38955
            ],
            [
              -147.681888,
              60.388167
            ],
            [
              -147.690773,
              60.405054
            ],
            [
              -147.674351,
              60.41443
            ],
            [
              -147.619972,
              60.436821
            ],
            [
              -147.623835,
              60.465878
            ],
            [
              -147.607756,
              60.50692
            ],
            [
              -147.565775,
              60.534713
            ],
            [
              -147.551709,
              60.559612
            ],
            [
              -147.555392,
              60.574059
            ],
            [
              -147.562801,
              60.579821
            ],
            [
              -147.566372,
              60.580849
            ],
            [
              -147.613843,
              60.565906
            ],
            [
              -147.721824,
              60.508635
            ],
            [
              -147.72646,
              60.502533
            ],
            [
              -147.726642,
              60.472216
            ],
            [
              -147.717097,
              60.467282
            ],
            [
              -147.70916,
              60.451883
            ],
            [
              -147.715312,
              60.447915
            ],
            [
              -147.738151,
              60.441277
            ],
            [
              -147.750864,
              60.440981
            ],
            [
              -147.779329,
              60.457078
            ],
            [
              -147.765825,
              60.476505
            ],
            [
              -147.778269,
              60.484007
            ],
            [
              -147.782548,
              60.4833
            ],
            [
              -147.792822,
              60.476193
            ],
            [
              -147.837456,
              60.414452
            ],
            [
              -147.933269,
              60.273632
            ],
            [
              -147.950532,
              60.243791
            ]
          ]
        ],
        [
          [
            [
              -148.020259,
              60.72495
            ],
            [
              -147.970684,
              60.673799
            ],
            [
              -147.932931,
              60.655714
            ],
            [
              -147.868067,
              60.670825
            ],
            [
              -147.860057,
              60.677233
            ],
            [
              -147.846103,
              60.694509
            ],
            [
              -147.848176,
              60.698116
            ],
            [
              -147.906021,
              60.735515
            ],
            [
              -147.952039,
              60.741879
            ],
            [
              -147.957239,
              60.747706
            ],
            [
              -147.965419,
              60.751996
            ],
            [
              -148.020259,
              60.72495
            ]
          ]
        ],
        [
          [
            [
              -148.738281,
              60.819662
            ],
            [
              -148.745618,
              60.733007
            ],
            [
              -148.657119,
              60.732976
            ],
            [
              -148.657134,
              60.725066
            ],
            [
              -148.657076,
              60.472958
            ],
            [
              -148.664474,
              60.472959
            ],
            [
              -148.664471,
              60.423047
            ],
            [
              -148.66447,
              60.415183
            ],
            [
              -148.563715,
              60.415172
            ],
            [
              -148.563026,
              60.126314
            ],
            [
              -148.571915,
              60.06194
            ],
            [
              -148.585580269786,
              59.9372103414648
            ],
            [
              -148.568766,
              59.944546
            ],
            [
              -148.548077,
              59.968024
            ],
            [
              -148.545225,
              59.994956
            ],
            [
              -148.511593,
              60.002087
            ],
            [
              -148.491152,
              59.978431
            ],
            [
              -148.480457,
              59.958149
            ],
            [
              -148.444567,
              59.942617
            ],
            [
              -148.424195,
              59.953658
            ],
            [
              -148.401666,
              59.977836
            ],
            [
              -148.40131,
              60.030713
            ],
            [
              -148.365658,
              60.086528
            ],
            [
              -148.377423,
              60.116739
            ],
            [
              -148.344742,
              60.139113
            ],
            [
              -148.303861,
              60.118042
            ],
            [
              -148.282945,
              60.085046
            ],
            [
              -148.301826,
              60.05388
            ],
            [
              -148.313962,
              60.033859
            ],
            [
              -148.271774,
              60.012424
            ],
            [
              -148.220554,
              59.97635
            ],
            [
              -148.25406,
              59.932357
            ],
            [
              -148.22507,
              59.931485
            ],
            [
              -148.149013,
              59.937677
            ],
            [
              -148.101239,
              59.952794
            ],
            [
              -148.039204,
              59.938867
            ],
            [
              -147.918226,
              59.968975
            ],
            [
              -147.870452,
              59.991331
            ],
            [
              -147.859281,
              60.014503
            ],
            [
              -147.830522,
              60.041456
            ],
            [
              -147.815548,
              60.057713
            ],
            [
              -147.825293,
              60.068744
            ],
            [
              -147.851438,
              60.07906
            ],
            [
              -147.889229,
              60.112357
            ],
            [
              -147.910144,
              60.131065
            ],
            [
              -147.968138,
              60.154375
            ],
            [
              -148.006405,
              60.177965
            ],
            [
              -148.050603,
              60.201314
            ],
            [
              -148.086774,
              60.214182
            ],
            [
              -148.128487,
              60.236903
            ],
            [
              -148.211794,
              60.260256
            ],
            [
              -148.31055,
              60.248463
            ],
            [
              -148.313022,
              60.263084
            ],
            [
              -148.285204,
              60.270971
            ],
            [
              -148.215549,
              60.301014
            ],
            [
              -148.211591,
              60.306961
            ],
            [
              -148.216245,
              60.318269
            ],
            [
              -148.217318,
              60.331594
            ],
            [
              -148.193066,
              60.339103
            ],
            [
              -148.171278,
              60.335266
            ],
            [
              -148.150628,
              60.324182
            ],
            [
              -148.150506,
              60.320739
            ],
            [
              -148.154021,
              60.3116
            ],
            [
              -148.153519,
              60.303062
            ],
            [
              -148.150529,
              60.29621
            ],
            [
              -148.116261,
              60.279522
            ],
            [
              -148.064891,
              60.276233
            ],
            [
              -148.025994,
              60.279029
            ],
            [
              -147.993336,
              60.352596
            ],
            [
              -147.942106,
              60.444029
            ],
            [
              -147.977647,
              60.480937
            ],
            [
              -147.971595,
              60.486479
            ],
            [
              -147.964014,
              60.50275
            ],
            [
              -147.979416,
              60.519146
            ],
            [
              -148.086775,
              60.595518
            ],
            [
              -148.103144,
              60.598026
            ],
            [
              -148.115163,
              60.596029
            ],
            [
              -148.19243,
              60.557371
            ],
            [
              -148.238951,
              60.521443
            ],
            [
              -148.250529,
              60.507573
            ],
            [
              -148.248264,
              60.500504
            ],
            [
              -148.255822,
              60.49341
            ],
            [
              -148.293931,
              60.483001
            ],
            [
              -148.329384,
              60.476182
            ],
            [
              -148.346775,
              60.48873
            ],
            [
              -148.346024,
              60.502973
            ],
            [
              -148.333642,
              60.530464
            ],
            [
              -148.328564,
              60.531913
            ],
            [
              -148.30652,
              60.550702
            ],
            [
              -148.293234,
              60.565496
            ],
            [
              -148.255249,
              60.595124
            ],
            [
              -148.238225,
              60.600206
            ],
            [
              -148.230358,
              60.595062
            ],
            [
              -148.142993,
              60.622632
            ],
            [
              -148.094129,
              60.661533
            ],
            [
              -148.091712,
              60.676249
            ],
            [
              -148.107384,
              60.73977
            ],
            [
              -148.120763,
              60.748952
            ],
            [
              -148.148059,
              60.758536
            ],
            [
              -148.230153,
              60.76414
            ],
            [
              -148.26992,
              60.757389
            ],
            [
              -148.280533,
              60.753337
            ],
            [
              -148.326753,
              60.709539
            ],
            [
              -148.348278,
              60.680327
            ],
            [
              -148.374415,
              60.67264
            ],
            [
              -148.384491,
              60.687754
            ],
            [
              -148.365711,
              60.740969
            ],
            [
              -148.366804,
              60.765833
            ],
            [
              -148.382396,
              60.775768
            ],
            [
              -148.396359,
              60.779701
            ],
            [
              -148.405973,
              60.780301
            ],
            [
              -148.431475,
              60.771842
            ],
            [
              -148.442062,
              60.777956
            ],
            [
              -148.451287,
              60.789487
            ],
            [
              -148.451000158129,
              60.7920674578157
            ],
            [
              -148.450518,
              60.796405
            ],
            [
              -148.446675,
              60.808705
            ],
            [
              -148.426951,
              60.827113
            ],
            [
              -148.397011,
              60.813694
            ],
            [
              -148.389914,
              60.805622
            ],
            [
              -148.375813,
              60.80347
            ],
            [
              -148.350857,
              60.803991
            ],
            [
              -148.341108,
              60.809072
            ],
            [
              -148.310107,
              60.837737
            ],
            [
              -148.294872,
              60.862751
            ],
            [
              -148.281645,
              60.917792
            ],
            [
              -148.265981,
              60.936331
            ],
            [
              -148.242061,
              60.937738
            ],
            [
              -148.219351,
              60.953573
            ],
            [
              -148.199367,
              60.971584
            ],
            [
              -148.178046,
              60.999608
            ],
            [
              -148.164849,
              61.042665
            ],
            [
              -148.166366,
              61.069277
            ],
            [
              -148.14942,
              61.076672
            ],
            [
              -148.125524,
              61.070698
            ],
            [
              -148.105785,
              61.035123
            ],
            [
              -148.095401,
              61.011384
            ],
            [
              -148.090487,
              61.00511
            ],
            [
              -148.065902,
              61.003979
            ],
            [
              -148.003613,
              61.053797
            ],
            [
              -148.002034,
              61.060103
            ],
            [
              -147.999457,
              61.084059
            ],
            [
              -147.977566,
              61.102143
            ],
            [
              -147.913797,
              61.140214
            ],
            [
              -147.817667,
              61.206839
            ],
            [
              -147.793872,
              61.231586
            ],
            [
              -147.738668,
              61.26585
            ],
            [
              -147.726295,
              61.266802
            ],
            [
              -147.715826,
              61.249669
            ],
            [
              -147.761511,
              61.214453
            ],
            [
              -147.772413,
              61.18032
            ],
            [
              -147.888099,
              61.107854
            ],
            [
              -147.949965,
              61.074541
            ],
            [
              -147.95282,
              61.06883
            ],
            [
              -147.948182,
              61.040625
            ],
            [
              -147.951016,
              61.029211
            ],
            [
              -148.017673,
              60.971807
            ],
            [
              -148.065529,
              60.937963
            ],
            [
              -148.085617,
              60.918613
            ],
            [
              -148.102238,
              60.899347
            ],
            [
              -148.148695,
              60.828701
            ],
            [
              -148.151994,
              60.818122
            ],
            [
              -148.144752,
              60.797089
            ],
            [
              -148.134384,
              60.791268
            ],
            [
              -148.098545,
              60.786556
            ],
            [
              -148.03435,
              60.783198
            ],
            [
              -147.920842,
              60.812442
            ],
            [
              -147.915513,
              60.818955
            ],
            [
              -147.913796,
              60.825152
            ],
            [
              -147.856317,
              60.820882
            ],
            [
              -147.829162,
              60.815947
            ],
            [
              -147.777554,
              60.811018
            ],
            [
              -147.743653,
              60.813887
            ],
            [
              -147.733649,
              60.816975
            ],
            [
              -147.729818,
              60.818252
            ],
            [
              -147.732521,
              60.824711
            ],
            [
              -147.750609,
              60.852141
            ],
            [
              -147.767358,
              60.853544
            ],
            [
              -147.780114,
              60.863435
            ],
            [
              -147.787512,
              60.873511
            ],
            [
              -147.76124,
              60.913227
            ],
            [
              -147.730788,
              60.911256
            ],
            [
              -147.666252,
              60.883774
            ],
            [
              -147.663357,
              60.874951
            ],
            [
              -147.677689,
              60.86996
            ],
            [
              -147.672325,
              60.845283
            ],
            [
              -147.66899,
              60.841563
            ],
            [
              -147.626982,
              60.845065
            ],
            [
              -147.602802,
              60.849978
            ],
            [
              -147.587706,
              60.874463
            ],
            [
              -147.598146,
              60.913905
            ],
            [
              -147.614243,
              60.951496
            ],
            [
              -147.619197,
              60.97004
            ],
            [
              -147.591657,
              61.016591
            ],
            [
              -147.557623,
              61.081402
            ],
            [
              -147.554836,
              61.091719
            ],
            [
              -147.558598,
              61.099797
            ],
            [
              -147.525494,
              61.101176
            ],
            [
              -147.514173,
              61.096127
            ],
            [
              -147.50272,
              61.072056
            ],
            [
              -147.516179,
              61.061408
            ],
            [
              -147.534431,
              61.03109
            ],
            [
              -147.537195,
              61.019346
            ],
            [
              -147.550153,
              60.908009
            ],
            [
              -147.543399,
              60.903331
            ],
            [
              -147.525453,
              60.896057
            ],
            [
              -147.517821,
              60.894819
            ],
            [
              -147.494209,
              60.912379
            ],
            [
              -147.502762,
              60.920429
            ],
            [
              -147.506407,
              60.92117
            ],
            [
              -147.507665,
              60.927235
            ],
            [
              -147.491943,
              60.957998
            ],
            [
              -147.473487,
              60.957552
            ],
            [
              -147.453803,
              60.941468
            ],
            [
              -147.452096,
              60.92588
            ],
            [
              -147.453301,
              60.897366
            ],
            [
              -147.451966,
              60.894219
            ],
            [
              -147.4189001697,
              60.8868510386302
            ],
            [
              -147.378483,
              60.877845
            ],
            [
              -147.37348305677,
              60.879847967560096
            ],
            [
              -147.280834,
              60.916963
            ],
            [
              -147.278401,
              60.961063
            ],
            [
              -147.274043,
              60.974595
            ],
            [
              -147.253381,
              60.979621
            ],
            [
              -147.222013,
              60.983541
            ],
            [
              -147.220722,
              60.981702
            ],
            [
              -147.219972,
              60.969505
            ],
            [
              -147.226884,
              60.96216
            ],
            [
              -147.221206,
              60.953121
            ],
            [
              -147.21567,
              60.948077
            ],
            [
              -147.205327,
              60.94266
            ],
            [
              -147.181654,
              60.933099
            ],
            [
              -147.172021,
              60.932877
            ],
            [
              -147.143711,
              60.939831
            ],
            [
              -147.135968,
              60.946248
            ],
            [
              -147.145036,
              60.963492
            ],
            [
              -147.137281,
              60.980968
            ],
            [
              -147.113004,
              61.002974
            ],
            [
              -147.09526,
              61.010189
            ],
            [
              -147.087221,
              61.035517
            ],
            [
              -147.096372,
              61.056631
            ],
            [
              -147.108419,
              61.078456
            ],
            [
              -147.132499,
              61.089166
            ],
            [
              -147.119545,
              61.097381
            ],
            [
              -147.103146,
              61.11478
            ],
            [
              -147.127864,
              61.132915
            ],
            [
              -147.109563,
              61.148689
            ],
            [
              -147.075218,
              61.153219
            ],
            [
              -147.021146,
              61.150696
            ],
            [
              -146.998091,
              61.144215
            ],
            [
              -147.023523,
              61.128267
            ],
            [
              -147.062027,
              61.118626
            ],
            [
              -147.046221,
              61.094394
            ],
            [
              -147.019007,
              61.072845
            ],
            [
              -147.000111,
              61.041099
            ],
            [
              -146.99928,
              61.013928
            ],
            [
              -146.977651,
              60.996013
            ],
            [
              -146.982523,
              60.975608
            ],
            [
              -147.020908,
              60.959748
            ],
            [
              -147.056151,
              60.945468
            ],
            [
              -147.039349,
              60.942079
            ],
            [
              -146.973469,
              60.934835
            ],
            [
              -146.930186,
              60.944263
            ],
            [
              -146.88025,
              60.965161
            ],
            [
              -146.869223,
              60.971448
            ],
            [
              -146.862358,
              60.976177
            ],
            [
              -146.863106,
              60.982523
            ],
            [
              -146.848509,
              61.000587
            ],
            [
              -146.785548200346,
              61.0420011670737
            ],
            [
              -146.784127,
              61.042936
            ],
            [
              -146.774287505545,
              61.045168360780096
            ],
            [
              -146.690949,
              61.064076
            ],
            [
              -146.64602,
              61.103571
            ],
            [
              -146.613659,
              61.118799
            ],
            [
              -146.493734,
              61.122606
            ],
            [
              -146.424254,
              61.133076
            ],
            [
              -146.401411,
              61.125462
            ],
            [
              -146.288149,
              61.122606
            ],
            [
              -146.269113,
              61.113088
            ],
            [
              -146.25674,
              61.098812
            ],
            [
              -146.262451,
              61.090246
            ],
            [
              -146.435676,
              61.085487
            ],
            [
              -146.607949,
              61.085487
            ],
            [
              -146.642213,
              61.075017
            ],
            [
              -146.661915,
              61.060776
            ],
            [
              -146.653827,
              61.047752
            ],
            [
              -146.67338463936602,
              61.022964
            ],
            [
              -146.701753,
              60.987009
            ],
            [
              -146.74594,
              60.957582
            ],
            [
              -146.747155,
              60.935454
            ],
            [
              -146.736422,
              60.910301
            ],
            [
              -146.711684,
              60.896465
            ],
            [
              -146.698087,
              60.872534
            ],
            [
              -146.727623,
              60.86627
            ],
            [
              -146.757401,
              60.878454
            ],
            [
              -146.774552,
              60.876225
            ],
            [
              -146.787828,
              60.865597
            ],
            [
              -146.816702,
              60.855628
            ],
            [
              -146.819405,
              60.841568
            ],
            [
              -146.819415,
              60.816346
            ],
            [
              -146.801009,
              60.80516
            ],
            [
              -146.755244,
              60.807882
            ],
            [
              -146.725241,
              60.81212
            ],
            [
              -146.720187,
              60.814475
            ],
            [
              -146.715145,
              60.820385
            ],
            [
              -146.719929,
              60.830166
            ],
            [
              -146.718529,
              60.835667
            ],
            [
              -146.701138,
              60.848345
            ],
            [
              -146.664765,
              60.870854
            ],
            [
              -146.620816,
              60.869019
            ],
            [
              -146.556361,
              60.810066
            ],
            [
              -146.550974,
              60.809402
            ],
            [
              -146.394766,
              60.812271
            ],
            [
              -146.333821,
              60.821921
            ],
            [
              -146.314154,
              60.827833
            ],
            [
              -146.290599,
              60.842694
            ],
            [
              -146.269056,
              60.863842
            ],
            [
              -146.262969,
              60.867787
            ],
            [
              -146.188582,
              60.869374
            ],
            [
              -146.173528,
              60.866071
            ],
            [
              -146.172294,
              60.862823
            ],
            [
              -146.183555,
              60.846969
            ],
            [
              -146.255812,
              60.809962
            ],
            [
              -146.304842,
              60.798038
            ],
            [
              -146.359015,
              60.786193
            ],
            [
              -146.465221,
              60.770722
            ],
            [
              -146.501075,
              60.772113
            ],
            [
              -146.567128,
              60.751198
            ],
            [
              -146.605405,
              60.758608
            ],
            [
              -146.703994,
              60.741903
            ],
            [
              -146.699616,
              60.732176
            ],
            [
              -146.668151,
              60.692761
            ],
            [
              -146.649456,
              60.683438
            ],
            [
              -146.623663,
              60.68042
            ],
            [
              -146.60755,
              60.686377
            ],
            [
              -146.57921,
              60.690212
            ],
            [
              -146.532793,
              60.689748
            ],
            [
              -146.518245,
              60.688102
            ],
            [
              -146.500246,
              60.680134
            ],
            [
              -146.474539,
              60.681539
            ],
            [
              -146.412917,
              60.69045
            ],
            [
              -146.40327,
              60.693084
            ],
            [
              -146.387289,
              60.714598
            ],
            [
              -146.34697,
              60.735747
            ],
            [
              -146.31268,
              60.734401
            ],
            [
              -146.318346,
              60.723817
            ],
            [
              -146.317697,
              60.721124
            ],
            [
              -146.314255,
              60.717926
            ],
            [
              -146.303795,
              60.713214
            ],
            [
              -146.25806,
              60.713068
            ],
            [
              -146.239909,
              60.716889
            ],
            [
              -146.231103,
              60.722008
            ],
            [
              -146.228647,
              60.735643
            ],
            [
              -146.217208,
              60.7417
            ],
            [
              -146.209136,
              60.74439
            ],
            [
              -146.200497,
              60.743081
            ],
            [
              -146.202309,
              60.735912
            ],
            [
              -146.199423,
              60.734359
            ],
            [
              -146.191553,
              60.73199
            ],
            [
              -146.168456,
              60.72535
            ],
            [
              -146.160617,
              60.726383
            ],
            [
              -146.120787,
              60.741981
            ],
            [
              -146.090177,
              60.758156
            ],
            [
              -146.085504,
              60.761063
            ],
            [
              -146.071364,
              60.770956
            ],
            [
              -146.067618,
              60.773918
            ],
            [
              -146.064954,
              60.776944
            ],
            [
              -146.049101,
              60.76377
            ],
            [
              -146.045723,
              60.75404
            ],
            [
              -146.044133,
              60.743636
            ],
            [
              -146.101458,
              60.719277
            ],
            [
              -146.12447,
              60.712417
            ],
            [
              -146.141037,
              60.707652
            ],
            [
              -146.179073,
              60.691483
            ],
            [
              -146.188556,
              60.687333
            ],
            [
              -146.263379,
              60.651569
            ],
            [
              -146.270647,
              60.648035
            ],
            [
              -146.270654,
              60.644928
            ],
            [
              -146.269081,
              60.64124
            ],
            [
              -146.263539,
              60.631932
            ],
            [
              -146.258777,
              60.626288
            ],
            [
              -146.253471,
              60.622315
            ],
            [
              -146.188073,
              60.624521
            ],
            [
              -146.147633,
              60.631407
            ],
            [
              -146.143646,
              60.633869
            ],
            [
              -146.131338,
              60.639181
            ],
            [
              -146.117309,
              60.643327
            ],
            [
              -146.08669,
              60.65203
            ],
            [
              -146.056067,
              60.658685
            ],
            [
              -146.025417,
              60.665311
            ],
            [
              -146.016799,
              60.667222
            ],
            [
              -145.978502,
              60.684712
            ],
            [
              -145.905874,
              60.715045
            ],
            [
              -145.901676,
              60.715373
            ],
            [
              -145.899559,
              60.705642
            ],
            [
              -145.911935,
              60.696647
            ],
            [
              -145.931833,
              60.685478
            ],
            [
              -145.937428,
              60.682822
            ],
            [
              -145.969131,
              60.668235
            ],
            [
              -145.999595,
              60.640832
            ],
            [
              -146.008144,
              60.625326
            ],
            [
              -146.008072,
              60.619742
            ],
            [
              -146.006152,
              60.616854
            ],
            [
              -146.005018,
              60.616231
            ],
            [
              -146.00293,
              60.615082
            ],
            [
              -145.965956,
              60.622748
            ],
            [
              -145.937464,
              60.63049
            ],
            [
              -145.937318,
              60.632053
            ],
            [
              -145.92532,
              60.648898
            ],
            [
              -145.922403,
              60.651954
            ],
            [
              -145.899605,
              60.671118
            ],
            [
              -145.894654,
              60.674164
            ],
            [
              -145.876217,
              60.683453
            ],
            [
              -145.858557,
              60.688484
            ],
            [
              -145.85218,
              60.689858
            ],
            [
              -145.844937,
              60.690169
            ],
            [
              -145.841815,
              60.689787
            ],
            [
              -145.841742,
              60.685893
            ],
            [
              -145.868167,
              60.666784
            ],
            [
              -145.884301,
              60.658185
            ],
            [
              -145.897542,
              60.651214
            ],
            [
              -145.89564,
              60.629213
            ],
            [
              -145.896557,
              60.628684
            ],
            [
              -145.898182,
              60.613653
            ],
            [
              -145.896663,
              60.611789
            ],
            [
              -145.888747,
              60.610304
            ],
            [
              -145.856443,
              60.610936
            ],
            [
              -145.832717,
              60.614851
            ],
            [
              -145.816105,
              60.612217
            ],
            [
              -145.795684,
              60.603151
            ],
            [
              -145.795141,
              60.601121
            ],
            [
              -145.800808,
              60.593996
            ],
            [
              -145.844626,
              60.586509
            ],
            [
              -145.872969,
              60.584836
            ],
            [
              -145.88692,
              60.585713
            ],
            [
              -145.951737,
              60.576779
            ],
            [
              -146.08036,
              60.543056
            ],
            [
              -146.15629,
              60.526296
            ],
            [
              -146.290083,
              60.515692
            ],
            [
              -146.316761,
              60.497499
            ],
            [
              -146.3684,
              60.480671
            ],
            [
              -146.455444,
              60.465318
            ],
            [
              -146.505842,
              60.476961
            ],
            [
              -146.524263,
              60.487332
            ],
            [
              -146.52925,
              60.492135
            ],
            [
              -146.59103,
              60.491039
            ],
            [
              -146.61084,
              60.485613
            ],
            [
              -146.637783,
              60.467178
            ],
            [
              -146.721479,
              60.396416
            ],
            [
              -146.724068,
              60.387606
            ],
            [
              -146.725511,
              60.359939
            ],
            [
              -146.717548,
              60.349598
            ],
            [
              -146.656291,
              60.340462
            ],
            [
              -146.624614,
              60.341407
            ],
            [
              -146.607435,
              60.351673
            ],
            [
              -146.575835,
              60.357272
            ],
            [
              -146.543101,
              60.357974
            ],
            [
              -146.524591,
              60.350667
            ],
            [
              -146.540703,
              60.338811
            ],
            [
              -146.572014,
              60.321755
            ],
            [
              -146.595376,
              60.321203
            ],
            [
              -146.650252,
              60.305063
            ],
            [
              -146.681867,
              60.292247
            ],
            [
              -146.693546,
              60.284593
            ],
            [
              -146.694034,
              60.279608
            ],
            [
              -146.689523,
              60.271279
            ],
            [
              -146.650852,
              60.242982
            ],
            [
              -146.641427,
              60.240897
            ],
            [
              -146.628377,
              60.239633
            ],
            [
              -146.607692,
              60.241182
            ],
            [
              -146.540294,
              60.270968
            ],
            [
              -146.490804,
              60.294939
            ],
            [
              -146.458719,
              60.30725
            ],
            [
              -146.393256,
              60.327476
            ],
            [
              -146.313692,
              60.340532
            ],
            [
              -146.246667,
              60.335122
            ],
            [
              -146.197229,
              60.348294
            ],
            [
              -146.147554,
              60.36722
            ],
            [
              -146.088134,
              60.364987
            ],
            [
              -146.094646,
              60.410296
            ],
            [
              -146.126199,
              60.42429
            ],
            [
              -146.123595,
              60.428031
            ],
            [
              -146.127029,
              60.430815
            ],
            [
              -146.133957,
              60.431523
            ],
            [
              -146.284201,
              60.417656
            ],
            [
              -146.308784,
              60.414246
            ],
            [
              -146.330118,
              60.407098
            ],
            [
              -146.350104,
              60.40778
            ],
            [
              -146.356252,
              60.425527
            ],
            [
              -146.355334,
              60.449349
            ],
            [
              -146.351292,
              60.454293
            ],
            [
              -146.317354,
              60.460608
            ],
            [
              -146.312552,
              60.457438
            ],
            [
              -146.247144,
              60.451188
            ],
            [
              -146.216786,
              60.450228
            ],
            [
              -146.109713,
              60.470345
            ],
            [
              -146.074409,
              60.480085
            ],
            [
              -146.039209,
              60.492968
            ],
            [
              -145.964543,
              60.513557
            ],
            [
              -145.828622,
              60.549746
            ],
            [
              -145.820659,
              60.550051
            ],
            [
              -145.798819,
              60.561918
            ],
            [
              -145.780628,
              60.579182
            ],
            [
              -145.764045,
              60.591588
            ],
            [
              -145.736494,
              60.59521
            ],
            [
              -145.712891,
              60.583249
            ],
            [
              -145.802387,
              60.520173
            ],
            [
              -145.863092,
              60.501821
            ],
            [
              -145.914403,
              60.49235
            ],
            [
              -145.960508,
              60.46751
            ],
            [
              -145.96106,
              60.465017
            ],
            [
              -145.957404,
              60.461101
            ],
            [
              -145.9469,
              60.455395
            ],
            [
              -145.882293,
              60.444633
            ],
            [
              -145.853469,
              60.44563
            ],
            [
              -145.799318,
              60.462031
            ],
            [
              -145.735938,
              60.47466
            ],
            [
              -145.668841,
              60.465431
            ],
            [
              -145.594158,
              60.45183
            ],
            [
              -145.561523,
              60.443124
            ],
            [
              -145.536942,
              60.430533
            ],
            [
              -145.502351,
              60.420811
            ],
            [
              -145.501549,
              60.416799
            ],
            [
              -145.50393,
              60.410607
            ],
            [
              -145.510457,
              60.408988
            ],
            [
              -145.505458,
              60.394268
            ],
            [
              -145.335651,
              60.338098
            ],
            [
              -145.254749,
              60.311448
            ],
            [
              -145.219533,
              60.303834
            ],
            [
              -145.136728,
              60.296219
            ],
            [
              -145.113885,
              60.300978
            ],
            [
              -145.089139,
              60.320014
            ],
            [
              -145.041549,
              60.357134
            ],
            [
              -145.039646,
              60.366651
            ],
            [
              -145.039491,
              60.406066
            ],
            [
              -144.994451,
              60.414399
            ],
            [
              -144.933691,
              60.425698
            ],
            [
              -144.903296,
              60.442581
            ],
            [
              -144.887342,
              60.456048
            ],
            [
              -144.874451,
              60.457304
            ],
            [
              -144.848662,
              60.455192
            ],
            [
              -144.834059,
              60.443751
            ],
            [
              -144.855457,
              60.416886
            ],
            [
              -144.871428,
              60.407269
            ],
            [
              -144.912707,
              60.363178
            ],
            [
              -144.966167,
              60.309232
            ],
            [
              -144.957848,
              60.288152
            ],
            [
              -144.914016,
              60.280934
            ],
            [
              -144.892815,
              60.292821
            ],
            [
              -144.80502,
              60.281201
            ],
            [
              -144.738945,
              60.265055
            ],
            [
              -144.87725,
              60.244589
            ],
            [
              -144.929327,
              60.228253
            ],
            [
              -144.908886,
              60.221289
            ],
            [
              -144.654899,
              60.204882
            ],
            [
              -144.596256,
              60.181666
            ],
            [
              -144.558163,
              60.177797
            ],
            [
              -144.555093,
              60.178485
            ],
            [
              -144.553786,
              60.181914
            ],
            [
              -144.545101,
              60.186999
            ],
            [
              -144.534892,
              60.18942
            ],
            [
              -144.484756,
              60.169632
            ],
            [
              -144.453957,
              60.166004
            ],
            [
              -144.441936,
              60.163069
            ],
            [
              -144.429249,
              60.14802
            ],
            [
              -144.348913,
              60.091184
            ],
            [
              -144.328472,
              60.108482
            ],
            [
              -144.323867,
              60.123474
            ],
            [
              -144.349626,
              60.146009
            ],
            [
              -144.312007,
              60.162018
            ],
            [
              -144.285357,
              60.140127
            ],
            [
              -144.1103,
              60.098939
            ],
            [
              -144.048732,
              60.059271
            ],
            [
              -144.052539,
              60.041759
            ],
            [
              -144.216828,
              60.040189
            ],
            [
              -144.323428,
              59.9926
            ],
            [
              -144.439546,
              59.940252
            ],
            [
              -144.567085,
              59.851736
            ],
            [
              -144.601349,
              59.797484
            ],
            [
              -144.59088,
              59.795581
            ],
            [
              -144.450967,
              59.871724
            ],
            [
              -144.424317,
              59.894566
            ],
            [
              -144.195889,
              59.997359
            ],
            [
              -144.035037,
              60.020202
            ],
            [
              -143.897029,
              59.985938
            ],
            [
              -143.893326,
              59.9867200205566
            ],
            [
              -143.893325,
              60.024166
            ],
            [
              -143.586834,
              60.242126
            ],
            [
              -143.193519,
              60.521828
            ],
            [
              -142.869382,
              60.521845
            ],
            [
              -141.96307,
              60.521896
            ],
            [
              -141.790619,
              60.521899
            ],
            [
              -141.790601,
              60.435238
            ],
            [
              -141.213451,
              60.435268
            ],
            [
              -141.213444,
              60.391676
            ],
            [
              -141.00185619923602,
              60.391688018701096
            ],
            [
              -141.001873,
              60.480449
            ],
            [
              -140.997755,
              61.069317
            ],
            [
              -141.002016,
              61.436392
            ],
            [
              -141.002054,
              61.678695
            ],
            [
              -141.00236,
              61.728712
            ],
            [
              -141.002082,
              61.755615
            ],
            [
              -141.002253713424,
              61.9040470153705
            ],
            [
              -141.831804,
              61.904053
            ],
            [
              -141.831812,
              62.117178
            ],
            [
              -142.014987,
              62.117646
            ],
            [
              -142.014989,
              62.163981
            ],
            [
              -141.977336,
              62.163983
            ],
            [
              -141.977353,
              62.510559
            ],
            [
              -142.125838,
              62.510556
            ],
            [
              -142.126131,
              62.597192
            ],
            [
              -142.313555,
              62.597193
            ],
            [
              -142.313564,
              62.683828
            ],
            [
              -142.505637,
              62.683822
            ],
            [
              -142.558408,
              62.68596
            ],
            [
              -142.58569,
              62.694596
            ],
            [
              -142.59887,
              62.683959
            ],
            [
              -142.630017,
              62.683697
            ],
            [
              -142.636116,
              62.700508
            ],
            [
              -142.673714,
              62.704001
            ],
            [
              -142.685903,
              62.711116
            ],
            [
              -142.719523,
              62.713508
            ],
            [
              -142.738934,
              62.699434
            ],
            [
              -142.732743,
              62.68682
            ],
            [
              -142.751685,
              62.682663
            ],
            [
              -142.742485,
              62.657813
            ],
            [
              -142.791436,
              62.654242
            ],
            [
              -142.802105,
              62.639404
            ],
            [
              -142.829001,
              62.62474
            ],
            [
              -142.83856,
              62.609096
            ],
            [
              -142.829507,
              62.600938
            ],
            [
              -142.867485,
              62.607581
            ],
            [
              -142.88242,
              62.604247
            ],
            [
              -142.92739,
              62.611393
            ],
            [
              -142.945634,
              62.595512
            ],
            [
              -142.968808,
              62.597781
            ],
            [
              -143.012873,
              62.615371
            ],
            [
              -143.104996,
              62.614905
            ],
            [
              -143.151065,
              62.630679
            ],
            [
              -143.173603,
              62.650785
            ],
            [
              -143.156772,
              62.66429
            ],
            [
              -143.116179,
              62.665259
            ],
            [
              -143.124949,
              62.685028
            ],
            [
              -143.142228,
              62.690356
            ],
            [
              -143.127506,
              62.701155
            ],
            [
              -143.099974,
              62.701333
            ],
            [
              -143.05465,
              62.73725
            ],
            [
              -143.008849,
              62.766249
            ],
            [
              -143.019434,
              62.774241
            ],
            [
              -143.055157,
              62.770371
            ],
            [
              -143.140592,
              62.8037
            ],
            [
              -143.163683,
              62.801398
            ],
            [
              -143.20757,
              62.829091
            ],
            [
              -143.16435,
              62.834569
            ],
            [
              -143.162177,
              62.851831
            ],
            [
              -143.131333,
              62.857403
            ],
            [
              -143.120626,
              62.876822
            ],
            [
              -143.094868,
              62.879614
            ],
            [
              -143.085094,
              62.895555
            ],
            [
              -143.059748,
              62.909892
            ],
            [
              -143.067085,
              62.915848
            ],
            [
              -143.032238,
              62.931791
            ],
            [
              -143.038387,
              62.96941
            ],
            [
              -143.001853,
              62.973989
            ],
            [
              -142.996224,
              62.982377
            ],
            [
              -143.001837,
              62.995623
            ],
            [
              -143.098876,
              63.007891
            ],
            [
              -143.084452,
              63.025183
            ],
            [
              -143.08998,
              63.034953
            ],
            [
              -143.123515,
              63.041412
            ],
            [
              -143.115335,
              63.056347
            ],
            [
              -143.123762,
              63.066494
            ],
            [
              -143.121086,
              63.092998
            ],
            [
              -143.141184,
              63.111985
            ],
            [
              -143.132337,
              63.116978
            ],
            [
              -144.066078,
              63.116948
            ],
            [
              -144.580159,
              63.116931
            ],
            [
              -144.580171,
              63.131168
            ],
            [
              -145.154385,
              63.131149
            ],
            [
              -145.154395,
              63.217781
            ],
            [
              -145.172889,
              63.217781
            ],
            [
              -145.64675,
              63.217771
            ],
            [
              -146.111486,
              63.217762
            ],
            [
              -146.111498,
              63.199518
            ],
            [
              -146.140378,
              63.184301
            ],
            [
              -146.190616,
              63.180374
            ],
            [
              -146.219223,
              63.184745
            ],
            [
              -146.340295,
              63.190816
            ],
            [
              -146.466584,
              63.17758
            ],
            [
              -146.494311,
              63.168975
            ],
            [
              -146.494296,
              62.957864
            ],
            [
              -146.450134,
              62.957864
            ],
            [
              -146.450126,
              62.856991
            ],
            [
              -146.436899,
              62.856991
            ],
            [
              -146.436884,
              62.510453
            ],
            [
              -146.423984,
              62.250511
            ],
            [
              -146.979807,
              62.250497
            ],
            [
              -146.979811,
              62.163852
            ],
            [
              -146.960978,
              62.163852
            ],
            [
              -146.960952,
              61.817272
            ],
            [
              -146.942601,
              61.817272
            ],
            [
              -146.942559,
              61.470675
            ],
            [
              -147.206605,
              61.470663
            ],
            [
              -147.206597,
              61.426265
            ],
            [
              -148.050309,
              61.426266
            ],
            [
              -148.462471,
              61.426267
            ],
            [
              -148.472123,
              61.166295
            ],
            [
              -148.472097,
              61.070766
            ],
            [
              -148.472065,
              60.84852
            ],
            [
              -148.738363,
              60.848546
            ],
            [
              -148.738281,
              60.819662
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "02",
      "COUNTYFP": "290",
      "COUNTYNS": "01419987",
      "AFFGEOID": "0500000US02290",
      "GEOID": "02290",
      "NAME": "Yukon-Koyukuk",
      "LSAD": "05",
      "ALAND": 377030936019,
      "AWATER": 5953180597,
      "County_state": "Yukon-Koyukuk,02"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -161.048176,
              62.55241
            ],
            [
              -161.048171,
              62.50775
            ],
            [
              -161.048165,
              62.205869
            ],
            [
              -160.915964,
              62.205866
            ],
            [
              -160.915961,
              62.054929
            ],
            [
              -160.915953,
              61.945943
            ],
            [
              -160.534671,
              61.94594
            ],
            [
              -159.554467,
              61.945953
            ],
            [
              -159.265037,
              61.945957
            ],
            [
              -159.265035,
              62.032598
            ],
            [
              -158.531351,
              62.03262
            ],
            [
              -158.531361,
              62.119264
            ],
            [
              -157.633817,
              62.119268
            ],
            [
              -157.25653,
              62.11927
            ],
            [
              -157.063864,
              62.119271
            ],
            [
              -157.063852,
              62.032634
            ],
            [
              -156.680792,
              62.03264
            ],
            [
              -155.867952,
              62.032653
            ],
            [
              -155.062594,
              62.032666
            ],
            [
              -154.410473,
              62.032677
            ],
            [
              -153.761875,
              62.032689
            ],
            [
              -153.761871,
              62.119335
            ],
            [
              -153.395009,
              62.119347
            ],
            [
              -153.395009,
              62.20599
            ],
            [
              -153.070123,
              62.206015
            ],
            [
              -153.07012,
              62.292644
            ],
            [
              -152.999839,
              62.292621
            ],
            [
              -153.0016,
              62.718661
            ],
            [
              -153.001506,
              62.727441
            ],
            [
              -152.997647,
              62.727401
            ],
            [
              -152.680154,
              62.981462
            ],
            [
              -152.428084,
              63.183171
            ],
            [
              -152.428098,
              63.304259
            ],
            [
              -152.619512,
              63.304254
            ],
            [
              -152.619517,
              63.347568
            ],
            [
              -152.810931,
              63.347563
            ],
            [
              -152.810945,
              63.477501
            ],
            [
              -152.873392,
              63.4775
            ],
            [
              -152.87341,
              63.650745
            ],
            [
              -152.228439,
              63.65076
            ],
            [
              -152.229256,
              63.824001
            ],
            [
              -152.055132,
              63.824006
            ],
            [
              -152.047444,
              63.997242
            ],
            [
              -151.830535,
              63.997248
            ],
            [
              -151.859734,
              64.018901
            ],
            [
              -151.826876,
              64.040556
            ],
            [
              -151.793976,
              64.06943
            ],
            [
              -151.776068,
              64.073171
            ],
            [
              -151.729758,
              64.069431
            ],
            [
              -151.564903,
              64.069436
            ],
            [
              -151.564901,
              64.055
            ],
            [
              -151.540489,
              64.050448
            ],
            [
              -151.48966,
              64.044609
            ],
            [
              -151.516333,
              64.020631
            ],
            [
              -151.432994,
              64.007806
            ],
            [
              -151.407625,
              63.997261
            ],
            [
              -151.281995,
              63.997265
            ],
            [
              -150.950598,
              64.213701
            ],
            [
              -150.729385,
              64.358173
            ],
            [
              -149.982393,
              64.358183
            ],
            [
              -149.132653,
              64.358214
            ],
            [
              -149.132599,
              64.343778
            ],
            [
              -148.657335,
              64.343789
            ],
            [
              -148.046696,
              64.343802
            ],
            [
              -148.06978,
              64.355165
            ],
            [
              -148.108554,
              64.36221
            ],
            [
              -148.104557,
              64.377612
            ],
            [
              -148.176111,
              64.400924
            ],
            [
              -148.175752,
              64.4101
            ],
            [
              -148.223706,
              64.435332
            ],
            [
              -148.21095,
              64.448838
            ],
            [
              -148.231693,
              64.46929
            ],
            [
              -148.270857,
              64.491769
            ],
            [
              -148.298082,
              64.487396
            ],
            [
              -148.326961,
              64.512186
            ],
            [
              -148.350771,
              64.522018
            ],
            [
              -148.403117,
              64.531297
            ],
            [
              -148.420106,
              64.561438
            ],
            [
              -148.444636,
              64.566311
            ],
            [
              -148.424918,
              64.574854
            ],
            [
              -148.457524,
              64.589764
            ],
            [
              -148.531461,
              64.607895
            ],
            [
              -148.550742,
              64.617208
            ],
            [
              -148.577778,
              64.610324
            ],
            [
              -148.59596,
              64.616268
            ],
            [
              -148.648207,
              64.585371
            ],
            [
              -148.663265,
              64.59079
            ],
            [
              -148.648208,
              64.597334
            ],
            [
              -148.64822,
              64.688143
            ],
            [
              -148.648241,
              64.863447
            ],
            [
              -148.661199,
              65.209875
            ],
            [
              -148.104648,
              65.209891
            ],
            [
              -147.64299,
              65.209892
            ],
            [
              -147.555319,
              65.208939
            ],
            [
              -147.381719,
              65.256716
            ],
            [
              -147.282818,
              65.278248
            ],
            [
              -147.191665,
              65.281419
            ],
            [
              -146.998776,
              65.278627
            ],
            [
              -146.869247,
              65.288529
            ],
            [
              -146.719396,
              65.310255
            ],
            [
              -146.637627,
              65.332789
            ],
            [
              -146.549263,
              65.344113
            ],
            [
              -146.493299,
              65.379843
            ],
            [
              -146.499338,
              65.39165
            ],
            [
              -146.524294,
              65.424614
            ],
            [
              -146.471774,
              65.430211
            ],
            [
              -146.430371,
              65.427818
            ],
            [
              -146.223606,
              65.444225
            ],
            [
              -146.16957,
              65.443987
            ],
            [
              -146.094401,
              65.403352
            ],
            [
              -146.027238,
              65.406897
            ],
            [
              -145.999526,
              65.408686
            ],
            [
              -145.976279,
              65.389745
            ],
            [
              -146.006109,
              65.373034
            ],
            [
              -146.087011,
              65.338356
            ],
            [
              -146.126521,
              65.314839
            ],
            [
              -146.114467,
              65.270929
            ],
            [
              -146.080243,
              65.25365
            ],
            [
              -145.996082,
              65.224436
            ],
            [
              -145.902076,
              65.206983
            ],
            [
              -145.716497,
              65.184223
            ],
            [
              -145.677089,
              65.183109
            ],
            [
              -145.633179,
              65.136616
            ],
            [
              -145.670756,
              65.129147
            ],
            [
              -145.698107,
              65.108837
            ],
            [
              -145.768353,
              65.084958
            ],
            [
              -145.767061,
              65.06989
            ],
            [
              -145.67477,
              65.068843
            ],
            [
              -145.645233,
              65.032868
            ],
            [
              -145.556029,
              65.047186
            ],
            [
              -145.506184,
              65.066877
            ],
            [
              -145.444624,
              65.066016
            ],
            [
              -145.431082,
              65.076249
            ],
            [
              -145.387799,
              65.083666
            ],
            [
              -145.243702,
              65.071417
            ],
            [
              -145.152321,
              65.097442
            ],
            [
              -145.083719,
              65.103158
            ],
            [
              -145.000788,
              65.128006
            ],
            [
              -144.915835,
              65.125786
            ],
            [
              -144.866475,
              65.130159
            ],
            [
              -144.766574,
              65.102782
            ],
            [
              -144.654914,
              65.097158
            ],
            [
              -144.467069,
              65.072195
            ],
            [
              -144.389061,
              65.079792
            ],
            [
              -144.329288,
              65.100226
            ],
            [
              -144.281869,
              65.114231
            ],
            [
              -144.20043,
              65.116109
            ],
            [
              -144.056718,
              65.118911
            ],
            [
              -143.990453,
              65.118904
            ],
            [
              -143.928823,
              65.102203
            ],
            [
              -143.895719,
              65.089693
            ],
            [
              -143.911985,
              65.070491
            ],
            [
              -143.975599,
              65.048278
            ],
            [
              -144.047297,
              65.025845
            ],
            [
              -144.095036,
              65.013496
            ],
            [
              -144.064582,
              64.99333
            ],
            [
              -143.992149,
              64.975613
            ],
            [
              -143.995593,
              64.942896
            ],
            [
              -144.02019,
              64.936596
            ],
            [
              -144.073942,
              64.908887
            ],
            [
              -144.072443,
              64.872097
            ],
            [
              -144.091942,
              64.854013
            ],
            [
              -144.117126,
              64.801708
            ],
            [
              -144.091157,
              64.789405
            ],
            [
              -144.030893,
              64.774574
            ],
            [
              -144.079075,
              64.751625
            ],
            [
              -144.109243,
              64.72679
            ],
            [
              -144.070019,
              64.692704
            ],
            [
              -144.069029,
              64.683562
            ],
            [
              -144.026883,
              64.675507
            ],
            [
              -143.966697,
              64.670336
            ],
            [
              -143.930114,
              64.668192
            ],
            [
              -143.890956,
              64.662605
            ],
            [
              -143.865511,
              64.661444
            ],
            [
              -143.840587,
              64.654677
            ],
            [
              -143.818087,
              64.655527
            ],
            [
              -143.803427,
              64.652949
            ],
            [
              -143.788266,
              64.650716
            ],
            [
              -143.769436,
              64.648858
            ],
            [
              -143.749094,
              64.64886
            ],
            [
              -143.732388,
              64.651487
            ],
            [
              -143.713691,
              64.653996
            ],
            [
              -143.697383,
              64.655926
            ],
            [
              -143.69134,
              64.662334
            ],
            [
              -143.680312,
              64.666459
            ],
            [
              -143.668211,
              64.667002
            ],
            [
              -143.643789,
              64.686202
            ],
            [
              -143.624806,
              64.702313
            ],
            [
              -143.605703,
              64.721672
            ],
            [
              -143.561375,
              64.738521
            ],
            [
              -143.515986,
              64.751978
            ],
            [
              -143.504367,
              64.770752
            ],
            [
              -143.525375,
              64.774921
            ],
            [
              -143.526002,
              64.783207
            ],
            [
              -143.522904,
              64.792624
            ],
            [
              -143.502346,
              64.803004
            ],
            [
              -143.457408,
              64.809812
            ],
            [
              -143.439352,
              64.815641
            ],
            [
              -143.441094,
              64.825102
            ],
            [
              -143.453655,
              64.832594
            ],
            [
              -143.489466,
              64.845542
            ],
            [
              -143.500383,
              64.861322
            ],
            [
              -143.494408,
              64.876569
            ],
            [
              -143.488483,
              64.894015
            ],
            [
              -143.477182,
              64.914395
            ],
            [
              -143.489353,
              64.928944
            ],
            [
              -143.500994,
              64.942576
            ],
            [
              -143.512878,
              64.955511
            ],
            [
              -143.50939,
              64.96418
            ],
            [
              -143.490152,
              64.966262
            ],
            [
              -143.454105,
              64.982446
            ],
            [
              -143.426333,
              64.979003
            ],
            [
              -143.393783,
              64.986756
            ],
            [
              -143.403714,
              64.993195
            ],
            [
              -143.391274,
              64.998548
            ],
            [
              -143.378282,
              64.99775
            ],
            [
              -143.368701,
              65.005255
            ],
            [
              -143.374065,
              65.015477
            ],
            [
              -143.353388,
              65.02875
            ],
            [
              -143.36135,
              65.03472
            ],
            [
              -143.337126,
              65.040002
            ],
            [
              -143.290238,
              65.039597
            ],
            [
              -143.241603,
              65.053782
            ],
            [
              -143.216109,
              65.07471
            ],
            [
              -143.190194,
              65.081941
            ],
            [
              -143.173098,
              65.084201
            ],
            [
              -143.157361,
              65.077516
            ],
            [
              -143.146967,
              65.087154
            ],
            [
              -143.126535,
              65.096243
            ],
            [
              -143.113932,
              65.102705
            ],
            [
              -143.100653,
              65.112003
            ],
            [
              -143.06954,
              65.114635
            ],
            [
              -143.042691,
              65.118459
            ],
            [
              -143.028907,
              65.129391
            ],
            [
              -143.009481,
              65.132983
            ],
            [
              -142.990467,
              65.132169
            ],
            [
              -142.992138,
              65.130576
            ],
            [
              -142.961416,
              65.134876
            ],
            [
              -142.932257,
              65.14644
            ],
            [
              -142.884067,
              65.144853
            ],
            [
              -142.844428,
              65.150246
            ],
            [
              -142.821947,
              65.170815
            ],
            [
              -142.797097,
              65.183428
            ],
            [
              -142.774461,
              65.195949
            ],
            [
              -142.771187,
              65.20516
            ],
            [
              -142.754999,
              65.216031
            ],
            [
              -142.759202,
              65.227479
            ],
            [
              -142.746127,
              65.238123
            ],
            [
              -142.732258,
              65.24376
            ],
            [
              -142.737569,
              65.26111
            ],
            [
              -142.734864,
              65.27627
            ],
            [
              -142.731631,
              65.284877
            ],
            [
              -142.735859,
              65.294301
            ],
            [
              -142.735739,
              65.30409
            ],
            [
              -142.742069,
              65.306338
            ],
            [
              -142.75649,
              65.310367
            ],
            [
              -142.767461,
              65.312088
            ],
            [
              -142.770641,
              65.31752
            ],
            [
              -142.757121,
              65.320902
            ],
            [
              -142.726033,
              65.324106
            ],
            [
              -142.701874,
              65.331825
            ],
            [
              -142.676998,
              65.343439
            ],
            [
              -142.671526,
              65.359145
            ],
            [
              -142.659092,
              65.37427
            ],
            [
              -142.6333,
              65.384042
            ],
            [
              -142.586072,
              65.390223
            ],
            [
              -142.550783,
              65.380941
            ],
            [
              -142.512619,
              65.375063
            ],
            [
              -142.473279,
              65.374823
            ],
            [
              -142.443258,
              65.38634
            ],
            [
              -142.423667,
              65.398901
            ],
            [
              -142.412902,
              65.409702
            ],
            [
              -142.375416,
              65.413751
            ],
            [
              -142.350392,
              65.425661
            ],
            [
              -142.316975,
              65.436195
            ],
            [
              -142.273665,
              65.445674
            ],
            [
              -142.221116,
              65.452011
            ],
            [
              -142.180547,
              65.459934
            ],
            [
              -142.176157,
              65.463108
            ],
            [
              -142.15349,
              65.466423
            ],
            [
              -142.13476,
              65.466565
            ],
            [
              -142.110181,
              65.467752
            ],
            [
              -142.083084,
              65.462923
            ],
            [
              -142.054807,
              65.458537
            ],
            [
              -142.024716,
              65.450013
            ],
            [
              -141.996612,
              65.441595
            ],
            [
              -141.949786,
              65.444911
            ],
            [
              -141.911913,
              65.454189
            ],
            [
              -141.888824,
              65.454703
            ],
            [
              -141.863558,
              65.444172
            ],
            [
              -141.851224,
              65.450208
            ],
            [
              -141.827552,
              65.461354
            ],
            [
              -141.801753,
              65.471771
            ],
            [
              -141.791482,
              65.486426
            ],
            [
              -141.770263,
              65.505788
            ],
            [
              -141.735173,
              65.506339
            ],
            [
              -141.686077,
              65.521015
            ],
            [
              -141.643259,
              65.522208
            ],
            [
              -141.625837,
              65.534402
            ],
            [
              -141.613866,
              65.541211
            ],
            [
              -141.586425,
              65.546682
            ],
            [
              -141.553997,
              65.568283
            ],
            [
              -141.528245,
              65.569882
            ],
            [
              -141.507105,
              65.578133
            ],
            [
              -141.503711,
              65.587614
            ],
            [
              -141.482707,
              65.592707
            ],
            [
              -141.420386,
              65.606772
            ],
            [
              -141.383121,
              65.624016
            ],
            [
              -141.384323,
              65.645975
            ],
            [
              -141.359007,
              65.660879
            ],
            [
              -141.33963,
              65.680644
            ],
            [
              -141.342915,
              65.704827
            ],
            [
              -141.321313,
              65.714472
            ],
            [
              -141.286606,
              65.734513
            ],
            [
              -141.258645,
              65.747186
            ],
            [
              -141.24089,
              65.759336
            ],
            [
              -141.226435,
              65.766141
            ],
            [
              -141.213199,
              65.773446
            ],
            [
              -141.17701,
              65.779709
            ],
            [
              -141.153249,
              65.795498
            ],
            [
              -141.13447,
              65.803241
            ],
            [
              -141.116462,
              65.814811
            ],
            [
              -141.09644,
              65.824748
            ],
            [
              -141.077757,
              65.826329
            ],
            [
              -141.047897,
              65.833061
            ],
            [
              -141.00576,
              65.838846
            ],
            [
              -141.002464900301,
              65.8394210010823
            ],
            [
              -141.002465,
              65.840075
            ],
            [
              -141.00262,
              66.642895
            ],
            [
              -141.002679,
              67.28604
            ],
            [
              -141.002711,
              67.892335
            ],
            [
              -141.002694000757,
              68.498364000001
            ],
            [
              -141.356222,
              68.498835
            ],
            [
              -142.232922,
              68.500007
            ],
            [
              -142.97202,
              68.500006
            ],
            [
              -143.718715,
              68.500005
            ],
            [
              -144.659559,
              68.5
            ],
            [
              -145.352435,
              68.499981
            ],
            [
              -146,
              68.5
            ],
            [
              -146,
              68
            ],
            [
              -146.05255,
              68
            ],
            [
              -146.656445,
              68
            ],
            [
              -147.056671,
              68
            ],
            [
              -147.753117,
              67.999988
            ],
            [
              -148.524201,
              68
            ],
            [
              -149.1459,
              68
            ],
            [
              -150.000591,
              68.000002
            ],
            [
              -150.830148,
              68
            ],
            [
              -151.678425,
              68.000006
            ],
            [
              -152.525287,
              68.000008
            ],
            [
              -153.404152,
              68
            ],
            [
              -154.395444,
              68.000006
            ],
            [
              -155.318744,
              68.000032
            ],
            [
              -155.318723,
              67.861793
            ],
            [
              -155.369012,
              67.861792
            ],
            [
              -155.368999,
              67.775216
            ],
            [
              -155.142837,
              67.775221
            ],
            [
              -155.142825,
              67.688643
            ],
            [
              -154.916661,
              67.688648
            ],
            [
              -154.916649,
              67.60207
            ],
            [
              -154.690489,
              67.602075
            ],
            [
              -154.690477,
              67.515496
            ],
            [
              -154.748939,
              67.515495
            ],
            [
              -154.748904,
              67.255753
            ],
            [
              -154.30311,
              67.255759
            ],
            [
              -154.303097,
              67.169177
            ],
            [
              -154.146314,
              67.169178
            ],
            [
              -154.146221,
              66.822843
            ],
            [
              -154.210317,
              66.822842
            ],
            [
              -154.210302,
              66.736255
            ],
            [
              -154.860446,
              66.736242
            ],
            [
              -154.860431,
              66.563064
            ],
            [
              -155.510585,
              66.563051
            ],
            [
              -155.510581,
              66.476459
            ],
            [
              -155.555001,
              66.476458
            ],
            [
              -155.554993,
              66.303274
            ],
            [
              -155.982499,
              66.303268
            ],
            [
              -155.982504,
              66.38986
            ],
            [
              -156.196257,
              66.389857
            ],
            [
              -156.196224,
              66.476582
            ],
            [
              -156.62377,
              66.476442
            ],
            [
              -156.623769,
              66.325548
            ],
            [
              -156.623757,
              66.303259
            ],
            [
              -157.051266,
              66.303254
            ],
            [
              -157.051284,
              66.476438
            ],
            [
              -157.90631,
              66.476424
            ],
            [
              -157.906295,
              66.130049
            ],
            [
              -158.575528,
              66.130035
            ],
            [
              -158.972185,
              66.130027
            ],
            [
              -158.972175,
              65.956849
            ],
            [
              -159.604813,
              65.95683
            ],
            [
              -159.604795,
              65.783636
            ],
            [
              -159.593635,
              65.783636
            ],
            [
              -159.593506,
              65.523835
            ],
            [
              -159.385578,
              65.523835
            ],
            [
              -159.385483,
              65.437236
            ],
            [
              -159.377365,
              65.264033
            ],
            [
              -159.582736,
              65.264026
            ],
            [
              -159.582715,
              65.090912
            ],
            [
              -159.572173,
              64.917597
            ],
            [
              -159.774921,
              64.917565
            ],
            [
              -159.774897,
              64.744382
            ],
            [
              -159.96229,
              64.744377
            ],
            [
              -159.962255,
              64.397927
            ],
            [
              -159.947288,
              64.051459
            ],
            [
              -159.737475,
              64.051459
            ],
            [
              -159.737451,
              63.791591
            ],
            [
              -159.932731,
              63.791589
            ],
            [
              -159.932739,
              63.704973
            ],
            [
              -160.111498,
              63.704972
            ],
            [
              -160.111522,
              63.618351
            ],
            [
              -160.497381,
              63.618343
            ],
            [
              -160.497375,
              63.531713
            ],
            [
              -160.690294,
              63.531689
            ],
            [
              -160.690263,
              63.358485
            ],
            [
              -160.667367,
              63.358459
            ],
            [
              -160.667361,
              63.271845
            ],
            [
              -160.858004,
              63.271838
            ],
            [
              -160.85799,
              63.011953
            ],
            [
              -160.857996,
              62.999271
            ],
            [
              -160.94344,
              62.999269
            ],
            [
              -160.943437,
              62.898939
            ],
            [
              -160.996215,
              62.898936
            ],
            [
              -160.996201,
              62.552424
            ],
            [
              -161.048176,
              62.55241
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "02",
      "COUNTYFP": "240",
      "COUNTYNS": "01419983",
      "AFFGEOID": "0500000US02240",
      "GEOID": "02240",
      "NAME": "Southeast Fairbanks",
      "LSAD": "05",
      "ALAND": 64290353013,
      "AWATER": 752301282,
      "County_state": "Southeast Fairbanks,02"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -146.999927,
              63.859755
            ],
            [
              -146.999966,
              63.479645
            ],
            [
              -146.489541,
              63.482913
            ],
            [
              -146.484532,
              63.180428
            ],
            [
              -146.493998,
              63.169611
            ],
            [
              -146.494311,
              63.168975
            ],
            [
              -146.466584,
              63.17758
            ],
            [
              -146.340295,
              63.190816
            ],
            [
              -146.219223,
              63.184745
            ],
            [
              -146.190616,
              63.180374
            ],
            [
              -146.140378,
              63.184301
            ],
            [
              -146.111498,
              63.199518
            ],
            [
              -146.111486,
              63.217762
            ],
            [
              -145.64675,
              63.217771
            ],
            [
              -145.172889,
              63.217781
            ],
            [
              -145.154395,
              63.217781
            ],
            [
              -145.154385,
              63.131149
            ],
            [
              -144.580171,
              63.131168
            ],
            [
              -144.580159,
              63.116931
            ],
            [
              -144.066078,
              63.116948
            ],
            [
              -143.132337,
              63.116978
            ],
            [
              -143.141184,
              63.111985
            ],
            [
              -143.121086,
              63.092998
            ],
            [
              -143.123762,
              63.066494
            ],
            [
              -143.115335,
              63.056347
            ],
            [
              -143.123515,
              63.041412
            ],
            [
              -143.08998,
              63.034953
            ],
            [
              -143.084452,
              63.025183
            ],
            [
              -143.098876,
              63.007891
            ],
            [
              -143.001837,
              62.995623
            ],
            [
              -142.996224,
              62.982377
            ],
            [
              -143.001853,
              62.973989
            ],
            [
              -143.038387,
              62.96941
            ],
            [
              -143.032238,
              62.931791
            ],
            [
              -143.067085,
              62.915848
            ],
            [
              -143.059748,
              62.909892
            ],
            [
              -143.085094,
              62.895555
            ],
            [
              -143.094868,
              62.879614
            ],
            [
              -143.120626,
              62.876822
            ],
            [
              -143.131333,
              62.857403
            ],
            [
              -143.162177,
              62.851831
            ],
            [
              -143.16435,
              62.834569
            ],
            [
              -143.20757,
              62.829091
            ],
            [
              -143.163683,
              62.801398
            ],
            [
              -143.140592,
              62.8037
            ],
            [
              -143.055157,
              62.770371
            ],
            [
              -143.019434,
              62.774241
            ],
            [
              -143.008849,
              62.766249
            ],
            [
              -143.05465,
              62.73725
            ],
            [
              -143.099974,
              62.701333
            ],
            [
              -143.127506,
              62.701155
            ],
            [
              -143.142228,
              62.690356
            ],
            [
              -143.124949,
              62.685028
            ],
            [
              -143.116179,
              62.665259
            ],
            [
              -143.156772,
              62.66429
            ],
            [
              -143.173603,
              62.650785
            ],
            [
              -143.151065,
              62.630679
            ],
            [
              -143.104996,
              62.614905
            ],
            [
              -143.012873,
              62.615371
            ],
            [
              -142.968808,
              62.597781
            ],
            [
              -142.945634,
              62.595512
            ],
            [
              -142.92739,
              62.611393
            ],
            [
              -142.88242,
              62.604247
            ],
            [
              -142.867485,
              62.607581
            ],
            [
              -142.829507,
              62.600938
            ],
            [
              -142.83856,
              62.609096
            ],
            [
              -142.829001,
              62.62474
            ],
            [
              -142.802105,
              62.639404
            ],
            [
              -142.791436,
              62.654242
            ],
            [
              -142.742485,
              62.657813
            ],
            [
              -142.751685,
              62.682663
            ],
            [
              -142.732743,
              62.68682
            ],
            [
              -142.738934,
              62.699434
            ],
            [
              -142.719523,
              62.713508
            ],
            [
              -142.685903,
              62.711116
            ],
            [
              -142.673714,
              62.704001
            ],
            [
              -142.636116,
              62.700508
            ],
            [
              -142.630017,
              62.683697
            ],
            [
              -142.59887,
              62.683959
            ],
            [
              -142.58569,
              62.694596
            ],
            [
              -142.558408,
              62.68596
            ],
            [
              -142.505637,
              62.683822
            ],
            [
              -142.313564,
              62.683828
            ],
            [
              -142.313555,
              62.597193
            ],
            [
              -142.126131,
              62.597192
            ],
            [
              -142.125838,
              62.510556
            ],
            [
              -141.977353,
              62.510559
            ],
            [
              -141.977336,
              62.163983
            ],
            [
              -142.014989,
              62.163981
            ],
            [
              -142.014987,
              62.117646
            ],
            [
              -141.831812,
              62.117178
            ],
            [
              -141.831804,
              61.904053
            ],
            [
              -141.002253713424,
              61.9040470153705
            ],
            [
              -141.002492,
              62.110026
            ],
            [
              -141.002133,
              62.124686
            ],
            [
              -141.00218,
              62.916737
            ],
            [
              -141.002254,
              63.852725
            ],
            [
              -141.002311,
              64.829873
            ],
            [
              -141.002464900301,
              65.8394210010823
            ],
            [
              -141.00576,
              65.838846
            ],
            [
              -141.047897,
              65.833061
            ],
            [
              -141.077757,
              65.826329
            ],
            [
              -141.09644,
              65.824748
            ],
            [
              -141.116462,
              65.814811
            ],
            [
              -141.13447,
              65.803241
            ],
            [
              -141.153249,
              65.795498
            ],
            [
              -141.17701,
              65.779709
            ],
            [
              -141.213199,
              65.773446
            ],
            [
              -141.226435,
              65.766141
            ],
            [
              -141.24089,
              65.759336
            ],
            [
              -141.258645,
              65.747186
            ],
            [
              -141.286606,
              65.734513
            ],
            [
              -141.321313,
              65.714472
            ],
            [
              -141.342915,
              65.704827
            ],
            [
              -141.33963,
              65.680644
            ],
            [
              -141.359007,
              65.660879
            ],
            [
              -141.384323,
              65.645975
            ],
            [
              -141.383121,
              65.624016
            ],
            [
              -141.420386,
              65.606772
            ],
            [
              -141.482707,
              65.592707
            ],
            [
              -141.503711,
              65.587614
            ],
            [
              -141.507105,
              65.578133
            ],
            [
              -141.528245,
              65.569882
            ],
            [
              -141.553997,
              65.568283
            ],
            [
              -141.586425,
              65.546682
            ],
            [
              -141.613866,
              65.541211
            ],
            [
              -141.625837,
              65.534402
            ],
            [
              -141.643259,
              65.522208
            ],
            [
              -141.686077,
              65.521015
            ],
            [
              -141.735173,
              65.506339
            ],
            [
              -141.770263,
              65.505788
            ],
            [
              -141.791482,
              65.486426
            ],
            [
              -141.801753,
              65.471771
            ],
            [
              -141.827552,
              65.461354
            ],
            [
              -141.851224,
              65.450208
            ],
            [
              -141.863558,
              65.444172
            ],
            [
              -141.888824,
              65.454703
            ],
            [
              -141.911913,
              65.454189
            ],
            [
              -141.949786,
              65.444911
            ],
            [
              -141.996612,
              65.441595
            ],
            [
              -142.024716,
              65.450013
            ],
            [
              -142.054807,
              65.458537
            ],
            [
              -142.083084,
              65.462923
            ],
            [
              -142.110181,
              65.467752
            ],
            [
              -142.13476,
              65.466565
            ],
            [
              -142.15349,
              65.466423
            ],
            [
              -142.176157,
              65.463108
            ],
            [
              -142.180547,
              65.459934
            ],
            [
              -142.221116,
              65.452011
            ],
            [
              -142.273665,
              65.445674
            ],
            [
              -142.316975,
              65.436195
            ],
            [
              -142.350392,
              65.425661
            ],
            [
              -142.375416,
              65.413751
            ],
            [
              -142.412902,
              65.409702
            ],
            [
              -142.423667,
              65.398901
            ],
            [
              -142.443258,
              65.38634
            ],
            [
              -142.473279,
              65.374823
            ],
            [
              -142.512619,
              65.375063
            ],
            [
              -142.550783,
              65.380941
            ],
            [
              -142.586072,
              65.390223
            ],
            [
              -142.6333,
              65.384042
            ],
            [
              -142.659092,
              65.37427
            ],
            [
              -142.671526,
              65.359145
            ],
            [
              -142.676998,
              65.343439
            ],
            [
              -142.701874,
              65.331825
            ],
            [
              -142.726033,
              65.324106
            ],
            [
              -142.757121,
              65.320902
            ],
            [
              -142.770641,
              65.31752
            ],
            [
              -142.767461,
              65.312088
            ],
            [
              -142.75649,
              65.310367
            ],
            [
              -142.742069,
              65.306338
            ],
            [
              -142.735739,
              65.30409
            ],
            [
              -142.735859,
              65.294301
            ],
            [
              -142.731631,
              65.284877
            ],
            [
              -142.734864,
              65.27627
            ],
            [
              -142.737569,
              65.26111
            ],
            [
              -142.732258,
              65.24376
            ],
            [
              -142.746127,
              65.238123
            ],
            [
              -142.759202,
              65.227479
            ],
            [
              -142.754999,
              65.216031
            ],
            [
              -142.771187,
              65.20516
            ],
            [
              -142.774461,
              65.195949
            ],
            [
              -142.797097,
              65.183428
            ],
            [
              -142.821947,
              65.170815
            ],
            [
              -142.844428,
              65.150246
            ],
            [
              -142.884067,
              65.144853
            ],
            [
              -142.932257,
              65.14644
            ],
            [
              -142.961416,
              65.134876
            ],
            [
              -142.992138,
              65.130576
            ],
            [
              -142.990467,
              65.132169
            ],
            [
              -143.009481,
              65.132983
            ],
            [
              -143.028907,
              65.129391
            ],
            [
              -143.042691,
              65.118459
            ],
            [
              -143.06954,
              65.114635
            ],
            [
              -143.100653,
              65.112003
            ],
            [
              -143.113932,
              65.102705
            ],
            [
              -143.126535,
              65.096243
            ],
            [
              -143.146967,
              65.087154
            ],
            [
              -143.157361,
              65.077516
            ],
            [
              -143.173098,
              65.084201
            ],
            [
              -143.190194,
              65.081941
            ],
            [
              -143.216109,
              65.07471
            ],
            [
              -143.241603,
              65.053782
            ],
            [
              -143.290238,
              65.039597
            ],
            [
              -143.337126,
              65.040002
            ],
            [
              -143.36135,
              65.03472
            ],
            [
              -143.353388,
              65.02875
            ],
            [
              -143.374065,
              65.015477
            ],
            [
              -143.368701,
              65.005255
            ],
            [
              -143.378282,
              64.99775
            ],
            [
              -143.391274,
              64.998548
            ],
            [
              -143.403714,
              64.993195
            ],
            [
              -143.393783,
              64.986756
            ],
            [
              -143.426333,
              64.979003
            ],
            [
              -143.454105,
              64.982446
            ],
            [
              -143.490152,
              64.966262
            ],
            [
              -143.50939,
              64.96418
            ],
            [
              -143.512878,
              64.955511
            ],
            [
              -143.500994,
              64.942576
            ],
            [
              -143.489353,
              64.928944
            ],
            [
              -143.477182,
              64.914395
            ],
            [
              -143.488483,
              64.894015
            ],
            [
              -143.494408,
              64.876569
            ],
            [
              -143.500383,
              64.861322
            ],
            [
              -143.489466,
              64.845542
            ],
            [
              -143.453655,
              64.832594
            ],
            [
              -143.441094,
              64.825102
            ],
            [
              -143.439352,
              64.815641
            ],
            [
              -143.457408,
              64.809812
            ],
            [
              -143.502346,
              64.803004
            ],
            [
              -143.522904,
              64.792624
            ],
            [
              -143.526002,
              64.783207
            ],
            [
              -143.525375,
              64.774921
            ],
            [
              -143.504367,
              64.770752
            ],
            [
              -143.515986,
              64.751978
            ],
            [
              -143.561375,
              64.738521
            ],
            [
              -143.605703,
              64.721672
            ],
            [
              -143.624806,
              64.702313
            ],
            [
              -143.643789,
              64.686202
            ],
            [
              -143.668211,
              64.667002
            ],
            [
              -143.680312,
              64.666459
            ],
            [
              -143.69134,
              64.662334
            ],
            [
              -143.697383,
              64.655926
            ],
            [
              -143.713691,
              64.653996
            ],
            [
              -143.732388,
              64.651487
            ],
            [
              -143.749094,
              64.64886
            ],
            [
              -143.769436,
              64.648858
            ],
            [
              -143.788266,
              64.650716
            ],
            [
              -143.803427,
              64.652949
            ],
            [
              -143.818087,
              64.655527
            ],
            [
              -143.840587,
              64.654677
            ],
            [
              -143.865511,
              64.661444
            ],
            [
              -143.890956,
              64.662605
            ],
            [
              -143.930114,
              64.668192
            ],
            [
              -143.966697,
              64.670336
            ],
            [
              -144.026883,
              64.675507
            ],
            [
              -144.069029,
              64.683562
            ],
            [
              -144.05584,
              64.667747
            ],
            [
              -144.072511,
              64.654082
            ],
            [
              -144.104744,
              64.656602
            ],
            [
              -144.129576,
              64.649461
            ],
            [
              -144.147605,
              64.656144
            ],
            [
              -144.190117,
              64.641074
            ],
            [
              -144.232909,
              64.649303
            ],
            [
              -144.295859,
              64.642771
            ],
            [
              -144.31598,
              64.645136
            ],
            [
              -144.319405,
              64.617763
            ],
            [
              -144.460096,
              64.58326
            ],
            [
              -144.511701,
              64.592423
            ],
            [
              -144.546741,
              64.590624
            ],
            [
              -144.552526,
              64.600645
            ],
            [
              -144.621161,
              64.598242
            ],
            [
              -144.637094,
              64.5922
            ],
            [
              -144.719058,
              64.587479
            ],
            [
              -144.784204,
              64.58091
            ],
            [
              -144.847904,
              64.567208
            ],
            [
              -144.933809,
              64.577296
            ],
            [
              -144.990911,
              64.559256
            ],
            [
              -145.044561,
              64.529625
            ],
            [
              -145.118301,
              64.503817
            ],
            [
              -145.180253,
              64.505055
            ],
            [
              -145.270016,
              64.490923
            ],
            [
              -145.317202,
              64.491226
            ],
            [
              -145.360708,
              64.485334
            ],
            [
              -145.37118,
              64.46073
            ],
            [
              -145.441347,
              64.445111
            ],
            [
              -145.532389,
              64.418156
            ],
            [
              -145.596686,
              64.424083
            ],
            [
              -145.623851,
              64.435419
            ],
            [
              -145.671755,
              64.444478
            ],
            [
              -145.718433,
              64.454605
            ],
            [
              -145.758666,
              64.448703
            ],
            [
              -145.826873,
              64.430815
            ],
            [
              -145.87887,
              64.429783
            ],
            [
              -145.929507,
              64.418858
            ],
            [
              -145.965639,
              64.401337
            ],
            [
              -146.00156,
              64.383896
            ],
            [
              -146.054251,
              64.379114
            ],
            [
              -146.099314,
              64.386967
            ],
            [
              -146.14156,
              64.38096
            ],
            [
              -146.190543,
              64.374455
            ],
            [
              -146.220345,
              64.366037
            ],
            [
              -146.22477,
              64.344925
            ],
            [
              -146.248551,
              64.309807
            ],
            [
              -146.303899,
              64.298346
            ],
            [
              -146.337948,
              64.281963
            ],
            [
              -146.376622,
              64.278692
            ],
            [
              -146.441381,
              64.281766
            ],
            [
              -146.473115,
              64.281895
            ],
            [
              -146.519578,
              64.277496
            ],
            [
              -146.57156,
              64.26761
            ],
            [
              -146.630511,
              64.259435
            ],
            [
              -146.680649,
              64.257212
            ],
            [
              -146.999909,
              64.257196
            ],
            [
              -146.999927,
              63.859755
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "02",
      "COUNTYFP": "198",
      "COUNTYNS": "01419980",
      "AFFGEOID": "0500000US02198",
      "GEOID": "02198",
      "NAME": "Prince of Wales-Hyder",
      "LSAD": "05",
      "ALAND": 13630288113,
      "AWATER": 14625197420,
      "County_state": "Prince of Wales-Hyder,02"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -130.383427,
              55.957009
            ],
            [
              -130.388606,
              55.943179
            ],
            [
              -130.349123,
              55.920078
            ],
            [
              -130.332966,
              55.904219
            ],
            [
              -130.309799,
              55.909565
            ],
            [
              -130.288673,
              55.887006
            ],
            [
              -130.29848,
              55.867112
            ],
            [
              -130.292563,
              55.857348
            ],
            [
              -130.264687,
              55.841361
            ],
            [
              -130.237973,
              55.816645
            ],
            [
              -130.210275,
              55.81061
            ],
            [
              -130.192342,
              55.788882
            ],
            [
              -130.150595,
              55.767031
            ],
            [
              -130.142537,
              55.779024
            ],
            [
              -130.133595,
              55.79063
            ],
            [
              -130.128538,
              55.802148
            ],
            [
              -130.12372,
              55.80704
            ],
            [
              -130.08451,
              55.823997
            ],
            [
              -130.013198,
              55.916382
            ],
            [
              -130.021425,
              55.915641
            ],
            [
              -130.025929,
              55.915995
            ],
            [
              -130.023189,
              55.930665
            ],
            [
              -130.02025,
              55.964362
            ],
            [
              -130.015364,
              55.984656
            ],
            [
              -130.00426,
              55.993379
            ],
            [
              -130.016874,
              56.017323
            ],
            [
              -130.031573,
              56.036791
            ],
            [
              -130.035943,
              56.040141
            ],
            [
              -130.061273,
              56.068508
            ],
            [
              -130.102761,
              56.116696
            ],
            [
              -130.24554,
              56.096876
            ],
            [
              -130.246577284174,
              56.0971959884747
            ],
            [
              -130.282366,
              56.079598
            ],
            [
              -130.289296,
              56.047321
            ],
            [
              -130.251402,
              56.028236
            ],
            [
              -130.250256,
              56.016693
            ],
            [
              -130.218768,
              55.999971
            ],
            [
              -130.26205,
              55.985225
            ],
            [
              -130.288619,
              55.998125
            ],
            [
              -130.324726,
              55.980963
            ],
            [
              -130.320172,
              55.968974
            ],
            [
              -130.352019,
              55.968255
            ],
            [
              -130.383427,
              55.957009
            ]
          ]
        ],
        [
          [
            [
              -131.646276,
              55.035579
            ],
            [
              -131.641035,
              55.026585
            ],
            [
              -131.615632,
              55.006999
            ],
            [
              -131.605661,
              55.004403
            ],
            [
              -131.590063,
              55.007745
            ],
            [
              -131.579882,
              55.017576
            ],
            [
              -131.53214,
              55.037945
            ],
            [
              -131.508449,
              55.029166
            ],
            [
              -131.50759,
              55.025427
            ],
            [
              -131.498863,
              55.016138
            ],
            [
              -131.484995,
              55.010454
            ],
            [
              -131.388569,
              55.012222
            ],
            [
              -131.378572,
              55.017308
            ],
            [
              -131.356314,
              55.041211
            ],
            [
              -131.350575,
              55.067042
            ],
            [
              -131.362319,
              55.155896
            ],
            [
              -131.352654,
              55.164822
            ],
            [
              -131.341407,
              55.165659
            ],
            [
              -131.355642,
              55.182945
            ],
            [
              -131.39769,
              55.210916
            ],
            [
              -131.416951,
              55.217298
            ],
            [
              -131.430501,
              55.218175
            ],
            [
              -131.445293,
              55.216977
            ],
            [
              -131.462701,
              55.223438
            ],
            [
              -131.481522,
              55.244448
            ],
            [
              -131.482252,
              55.25411
            ],
            [
              -131.49264,
              55.257749
            ],
            [
              -131.516651,
              55.261645
            ],
            [
              -131.550916,
              55.280915
            ],
            [
              -131.56265,
              55.284012
            ],
            [
              -131.56956,
              55.284114
            ],
            [
              -131.58907,
              55.273951
            ],
            [
              -131.607383,
              55.240437
            ],
            [
              -131.598454,
              55.179566
            ],
            [
              -131.588368,
              55.169961
            ],
            [
              -131.565677,
              55.155354
            ],
            [
              -131.548093,
              55.143138
            ],
            [
              -131.558603,
              55.125508
            ],
            [
              -131.577773,
              55.131094
            ],
            [
              -131.594978,
              55.125502
            ],
            [
              -131.605302,
              55.107436
            ],
            [
              -131.589387,
              55.08894
            ],
            [
              -131.620745,
              55.049259
            ],
            [
              -131.646276,
              55.035579
            ]
          ]
        ],
        [
          [
            [
              -132.889659,
              54.974747
            ],
            [
              -132.862801,
              54.943223
            ],
            [
              -132.838558,
              54.934212
            ],
            [
              -132.822871,
              54.948411
            ],
            [
              -132.830739,
              54.968601
            ],
            [
              -132.823346,
              54.979794
            ],
            [
              -132.855433,
              54.992068
            ],
            [
              -132.889659,
              54.974747
            ]
          ]
        ],
        [
          [
            [
              -133.318654,
              55.426457
            ],
            [
              -133.325197,
              55.413964
            ],
            [
              -133.306756,
              55.400877
            ],
            [
              -133.286531,
              55.402067
            ],
            [
              -133.250839,
              55.4098
            ],
            [
              -133.232993,
              55.430026
            ],
            [
              -133.231803,
              55.438949
            ],
            [
              -133.243106,
              55.449656
            ],
            [
              -133.293074,
              55.444897
            ],
            [
              -133.318654,
              55.426457
            ]
          ]
        ],
        [
          [
            [
              -133.562096,
              54.869267
            ],
            [
              -133.556945,
              54.843851
            ],
            [
              -133.549695,
              54.818045
            ],
            [
              -133.548507,
              54.783725
            ],
            [
              -133.508696,
              54.746565
            ],
            [
              -133.496235,
              54.758562
            ],
            [
              -133.501609,
              54.775686
            ],
            [
              -133.51333,
              54.802978
            ],
            [
              -133.51448,
              54.839846
            ],
            [
              -133.531104,
              54.868687
            ],
            [
              -133.562096,
              54.869267
            ]
          ]
        ],
        [
          [
            [
              -133.655819,
              55.625617
            ],
            [
              -133.655582,
              55.610987
            ],
            [
              -133.622782,
              55.582517
            ],
            [
              -133.594736,
              55.603603
            ],
            [
              -133.571681,
              55.625751
            ],
            [
              -133.545774,
              55.644533
            ],
            [
              -133.552191,
              55.652044
            ],
            [
              -133.54815,
              55.671886
            ],
            [
              -133.601391,
              55.692522
            ],
            [
              -133.61993,
              55.697746
            ],
            [
              -133.619217,
              55.673763
            ],
            [
              -133.624921,
              55.645472
            ],
            [
              -133.655819,
              55.625617
            ]
          ]
        ],
        [
          [
            [
              -133.711436,
              55.674433
            ],
            [
              -133.716665,
              55.660223
            ],
            [
              -133.669605,
              55.651507
            ],
            [
              -133.658909,
              55.675103
            ],
            [
              -133.667466,
              55.682339
            ],
            [
              -133.711436,
              55.674433
            ]
          ]
        ],
        [
          [
            [
              -133.727361,
              55.604006
            ],
            [
              -133.728549,
              55.593128
            ],
            [
              -133.715952,
              55.581308
            ],
            [
              -133.69266,
              55.582786
            ],
            [
              -133.697651,
              55.593934
            ],
            [
              -133.714504,
              55.61437
            ],
            [
              -133.727361,
              55.604006
            ]
          ]
        ],
        [
          [
            [
              -133.789055,
              55.457892
            ],
            [
              -133.764347,
              55.468593
            ],
            [
              -133.749371,
              55.471818
            ],
            [
              -133.739077,
              55.472323
            ],
            [
              -133.636291,
              55.428423
            ],
            [
              -133.622422,
              55.396474
            ],
            [
              -133.633006,
              55.361299
            ],
            [
              -133.647725,
              55.34548
            ],
            [
              -133.672243,
              55.326765
            ],
            [
              -133.689159,
              55.320285
            ],
            [
              -133.690944,
              55.308703
            ],
            [
              -133.690174,
              55.304409
            ],
            [
              -133.634509,
              55.250043
            ],
            [
              -133.623457,
              55.242571
            ],
            [
              -133.61278,
              55.239737
            ],
            [
              -133.609073,
              55.241486
            ],
            [
              -133.603491,
              55.271739
            ],
            [
              -133.595805,
              55.293766
            ],
            [
              -133.576808,
              55.324795
            ],
            [
              -133.497028,
              55.368159
            ],
            [
              -133.446281,
              55.382175
            ],
            [
              -133.427366,
              55.381832
            ],
            [
              -133.419384,
              55.386105
            ],
            [
              -133.411582,
              55.406683
            ],
            [
              -133.412524,
              55.41756
            ],
            [
              -133.416649,
              55.422146
            ],
            [
              -133.425823,
              55.425511
            ],
            [
              -133.4653,
              55.42337
            ],
            [
              -133.474054,
              55.437155
            ],
            [
              -133.426516,
              55.466702
            ],
            [
              -133.396511,
              55.473169
            ],
            [
              -133.391243,
              55.471979
            ],
            [
              -133.373595,
              55.460656
            ],
            [
              -133.358993,
              55.453832
            ],
            [
              -133.305747,
              55.484115
            ],
            [
              -133.289854,
              55.50187
            ],
            [
              -133.281917,
              55.524486
            ],
            [
              -133.285317,
              55.535315
            ],
            [
              -133.292346,
              55.539736
            ],
            [
              -133.307406,
              55.542249
            ],
            [
              -133.344847,
              55.569327
            ],
            [
              -133.413499,
              55.570384
            ],
            [
              -133.436102,
              55.560872
            ],
            [
              -133.44266,
              55.553012
            ],
            [
              -133.454576,
              55.532423
            ],
            [
              -133.44367,
              55.518639
            ],
            [
              -133.480965,
              55.512738
            ],
            [
              -133.525646,
              55.529097
            ],
            [
              -133.52912,
              55.5277
            ],
            [
              -133.542322,
              55.507084
            ],
            [
              -133.544088,
              55.491784
            ],
            [
              -133.573698,
              55.472083
            ],
            [
              -133.618367,
              55.457045
            ],
            [
              -133.638673,
              55.458902
            ],
            [
              -133.644565,
              55.46468
            ],
            [
              -133.644202,
              55.470815
            ],
            [
              -133.632978,
              55.491664
            ],
            [
              -133.624912,
              55.494674
            ],
            [
              -133.611671,
              55.495585
            ],
            [
              -133.595632,
              55.501483
            ],
            [
              -133.590163,
              55.507236
            ],
            [
              -133.582005,
              55.537363
            ],
            [
              -133.584326,
              55.539707
            ],
            [
              -133.615623,
              55.550776
            ],
            [
              -133.733029,
              55.558757
            ],
            [
              -133.75287,
              55.544282
            ],
            [
              -133.75913,
              55.537371
            ],
            [
              -133.789055,
              55.457892
            ]
          ]
        ],
        [
          [
            [
              -133.816363,
              55.964025
            ],
            [
              -133.816958,
              55.952127
            ],
            [
              -133.799931,
              55.925349
            ],
            [
              -133.718805,
              55.893236
            ],
            [
              -133.704663,
              55.878023
            ],
            [
              -133.692303,
              55.855084
            ],
            [
              -133.682558,
              55.850414
            ],
            [
              -133.67115,
              55.863221
            ],
            [
              -133.672416,
              55.880985
            ],
            [
              -133.700147,
              55.899483
            ],
            [
              -133.674715,
              55.913205
            ],
            [
              -133.638122,
              55.920982
            ],
            [
              -133.541041,
              55.977322
            ],
            [
              -133.495052,
              56.017089
            ],
            [
              -133.476888,
              56.004071
            ],
            [
              -133.501872,
              55.949343
            ],
            [
              -133.49598,
              55.913139
            ],
            [
              -133.522838,
              55.887421
            ],
            [
              -133.521411,
              55.878223
            ],
            [
              -133.490988,
              55.873556
            ],
            [
              -133.447018,
              55.890487
            ],
            [
              -133.384089,
              55.87677
            ],
            [
              -133.352963,
              55.837318
            ],
            [
              -133.323664,
              55.818632
            ],
            [
              -133.342058,
              55.805764
            ],
            [
              -133.347915,
              55.803943
            ],
            [
              -133.422064,
              55.788821
            ],
            [
              -133.46618,
              55.805981
            ],
            [
              -133.556005,
              55.835129
            ],
            [
              -133.62263,
              55.830965
            ],
            [
              -133.655942,
              55.823232
            ],
            [
              -133.701152,
              55.78516
            ],
            [
              -133.700557,
              55.778617
            ],
            [
              -133.643324,
              55.729037
            ],
            [
              -133.62512,
              55.719927
            ],
            [
              -133.583297,
              55.705338
            ],
            [
              -133.554504,
              55.698354
            ],
            [
              -133.527387,
              55.695108
            ],
            [
              -133.492438,
              55.706525
            ],
            [
              -133.489253,
              55.713081
            ],
            [
              -133.490882,
              55.726576
            ],
            [
              -133.494087,
              55.733158
            ],
            [
              -133.496509,
              55.733258
            ],
            [
              -133.509704,
              55.744183
            ],
            [
              -133.512632,
              55.755898
            ],
            [
              -133.49143,
              55.764082
            ],
            [
              -133.438619,
              55.748366
            ],
            [
              -133.416549,
              55.739647
            ],
            [
              -133.42729,
              55.710268
            ],
            [
              -133.404235,
              55.694464
            ],
            [
              -133.394486,
              55.666881
            ],
            [
              -133.429667,
              55.671417
            ],
            [
              -133.452484,
              55.6682
            ],
            [
              -133.438933,
              55.645167
            ],
            [
              -133.43818,
              55.643862
            ],
            [
              -133.3869,
              55.619346
            ],
            [
              -133.294158,
              55.58868
            ],
            [
              -133.275972,
              55.580188
            ],
            [
              -133.263042,
              55.568793
            ],
            [
              -133.17676,
              55.586722
            ],
            [
              -133.156566,
              55.563589
            ],
            [
              -133.189611,
              55.55853
            ],
            [
              -133.190799,
              55.530829
            ],
            [
              -133.156335,
              55.515894
            ],
            [
              -133.132409,
              55.513579
            ],
            [
              -133.133293,
              55.502508
            ],
            [
              -133.16513,
              55.502435
            ],
            [
              -133.183431,
              55.492203
            ],
            [
              -133.173211,
              55.479275
            ],
            [
              -133.138129,
              55.458373
            ],
            [
              -133.104304,
              55.426907
            ],
            [
              -133.077901,
              55.410386
            ],
            [
              -133.135207024535,
              55.382777785775595
            ],
            [
              -133.157761,
              55.371912
            ],
            [
              -133.18328,
              55.379392
            ],
            [
              -133.208794,
              55.384237
            ],
            [
              -133.226844,
              55.38185
            ],
            [
              -133.257734,
              55.367026
            ],
            [
              -133.269068,
              55.359341
            ],
            [
              -133.278443,
              55.349184
            ],
            [
              -133.280701,
              55.341536
            ],
            [
              -133.279271,
              55.333264
            ],
            [
              -133.254639,
              55.318806
            ],
            [
              -133.233572,
              55.308822
            ],
            [
              -133.235129,
              55.304162
            ],
            [
              -133.244343,
              55.291865
            ],
            [
              -133.268999,
              55.286971
            ],
            [
              -133.292402,
              55.291377
            ],
            [
              -133.311114,
              55.317301
            ],
            [
              -133.310529,
              55.320257
            ],
            [
              -133.313491,
              55.331557
            ],
            [
              -133.33236,
              55.345357
            ],
            [
              -133.352083,
              55.347381
            ],
            [
              -133.395371,
              55.341753
            ],
            [
              -133.43635,
              55.330158
            ],
            [
              -133.452818,
              55.31998
            ],
            [
              -133.459753,
              55.30678
            ],
            [
              -133.472866,
              55.267873
            ],
            [
              -133.473593,
              55.255547
            ],
            [
              -133.471938,
              55.247527
            ],
            [
              -133.453777,
              55.218497
            ],
            [
              -133.441074,
              55.211654
            ],
            [
              -133.422869,
              55.210659
            ],
            [
              -133.415257,
              55.212159
            ],
            [
              -133.404497,
              55.214992
            ],
            [
              -133.376615,
              55.228288
            ],
            [
              -133.361562,
              55.22556
            ],
            [
              -133.353719,
              55.221215
            ],
            [
              -133.341259,
              55.205701
            ],
            [
              -133.323021,
              55.20703
            ],
            [
              -133.281979,
              55.217117
            ],
            [
              -133.254478,
              55.231149
            ],
            [
              -133.223791,
              55.229317
            ],
            [
              -133.236908,
              55.183326
            ],
            [
              -133.215086,
              55.136876
            ],
            [
              -133.231918,
              55.127783
            ],
            [
              -133.152503,
              55.111319
            ],
            [
              -133.128705,
              55.101429
            ],
            [
              -133.134987,
              55.099473
            ],
            [
              -133.144954,
              55.099155
            ],
            [
              -133.218101,
              55.101042
            ],
            [
              -133.223923,
              55.099986
            ],
            [
              -133.239695,
              55.092415
            ],
            [
              -133.21042,
              55.040269
            ],
            [
              -133.197719,
              55.033404
            ],
            [
              -133.17236,
              55.032745
            ],
            [
              -133.168931,
              55.030889
            ],
            [
              -133.157326,
              55.000365
            ],
            [
              -133.164788,
              54.976909
            ],
            [
              -133.159756,
              54.95876
            ],
            [
              -133.123941,
              54.940065
            ],
            [
              -133.099047,
              54.919007
            ],
            [
              -133.032752,
              54.855055
            ],
            [
              -132.95025,
              54.788333
            ],
            [
              -132.940485,
              54.783831
            ],
            [
              -132.918751,
              54.783253
            ],
            [
              -132.909517,
              54.780516
            ],
            [
              -132.891553,
              54.768191
            ],
            [
              -132.87721,
              54.753772
            ],
            [
              -132.865598,
              54.728908
            ],
            [
              -132.864084,
              54.716917
            ],
            [
              -132.869604,
              54.702416
            ],
            [
              -132.866355,
              54.700386
            ],
            [
              -132.775733,
              54.674844
            ],
            [
              -132.69397,
              54.669064
            ],
            [
              -132.675868,
              54.672812
            ],
            [
              -132.674324,
              54.674652
            ],
            [
              -132.676226,
              54.680865
            ],
            [
              -132.722011,
              54.763259
            ],
            [
              -132.747451,
              54.800743
            ],
            [
              -132.759364,
              54.808277
            ],
            [
              -132.759779,
              54.816998
            ],
            [
              -132.754875,
              54.821236
            ],
            [
              -132.729369,
              54.826981
            ],
            [
              -132.723538,
              54.825128
            ],
            [
              -132.709267,
              54.817521
            ],
            [
              -132.701153,
              54.811012
            ],
            [
              -132.701203,
              54.798446
            ],
            [
              -132.681165,
              54.773697
            ],
            [
              -132.66725,
              54.763369
            ],
            [
              -132.639032,
              54.753251
            ],
            [
              -132.619024,
              54.766565
            ],
            [
              -132.614851,
              54.77717
            ],
            [
              -132.625563,
              54.807503
            ],
            [
              -132.628612,
              54.883316
            ],
            [
              -132.650001,
              54.904387
            ],
            [
              -132.730931,
              54.939483
            ],
            [
              -132.800784,
              54.933783
            ],
            [
              -132.817385,
              54.92364
            ],
            [
              -132.814282,
              54.909356
            ],
            [
              -132.807952,
              54.904378
            ],
            [
              -132.803742,
              54.897206
            ],
            [
              -132.803014,
              54.873725
            ],
            [
              -132.804525,
              54.870938
            ],
            [
              -132.82834,
              54.868747
            ],
            [
              -132.889474,
              54.896619
            ],
            [
              -132.91465,
              54.914009
            ],
            [
              -132.909706,
              54.923594
            ],
            [
              -132.959508,
              55.021045
            ],
            [
              -133.014178,
              55.035502
            ],
            [
              -133.01974,
              55.048366
            ],
            [
              -133.011351,
              55.056849
            ],
            [
              -132.990395,
              55.06673
            ],
            [
              -132.99086,
              55.079112
            ],
            [
              -133.012275,
              55.128056
            ],
            [
              -133.073381,
              55.184492
            ],
            [
              -133.119294,
              55.251405
            ],
            [
              -133.104708,
              55.2638
            ],
            [
              -133.043938,
              55.248569
            ],
            [
              -133.040748,
              55.232969
            ],
            [
              -133.008156,
              55.2057
            ],
            [
              -132.982166,
              55.215882
            ],
            [
              -132.939845,
              55.211481
            ],
            [
              -132.935449,
              55.209898
            ],
            [
              -132.894283,
              55.169373
            ],
            [
              -132.873408,
              55.134241
            ],
            [
              -132.869827,
              55.124104
            ],
            [
              -132.881083,
              55.110375
            ],
            [
              -132.909355,
              55.081277
            ],
            [
              -132.931172,
              55.074154
            ],
            [
              -132.932293,
              55.070199
            ],
            [
              -132.926772,
              55.055023
            ],
            [
              -132.916414,
              55.044465
            ],
            [
              -132.864117,
              55.024833
            ],
            [
              -132.813743,
              55.010987
            ],
            [
              -132.748854,
              54.996007
            ],
            [
              -132.692917,
              55.02506
            ],
            [
              -132.689667,
              55.027901
            ],
            [
              -132.684005,
              55.045604
            ],
            [
              -132.712195,
              55.098736
            ],
            [
              -132.735645,
              55.134218
            ],
            [
              -132.695227,
              55.137711
            ],
            [
              -132.663719,
              55.143932
            ],
            [
              -132.635758,
              55.152514
            ],
            [
              -132.629643,
              55.174232
            ],
            [
              -132.630049,
              55.177618
            ],
            [
              -132.637926,
              55.186077
            ],
            [
              -132.620123,
              55.199617
            ],
            [
              -132.605219,
              55.194064
            ],
            [
              -132.581133,
              55.166076
            ],
            [
              -132.587868,
              55.155971
            ],
            [
              -132.591084,
              55.155695
            ],
            [
              -132.598675,
              55.150482
            ],
            [
              -132.606895,
              55.141722
            ],
            [
              -132.624518,
              55.114419
            ],
            [
              -132.637866,
              55.073602
            ],
            [
              -132.633305,
              55.054954
            ],
            [
              -132.630865,
              55.052946
            ],
            [
              -132.626687,
              55.053314
            ],
            [
              -132.624296,
              55.056163
            ],
            [
              -132.624575,
              55.061352
            ],
            [
              -132.619912,
              55.069094
            ],
            [
              -132.594568,
              55.105378
            ],
            [
              -132.561819,
              55.114897
            ],
            [
              -132.550502,
              55.114247
            ],
            [
              -132.548994,
              55.113556
            ],
            [
              -132.541802,
              55.097764
            ],
            [
              -132.575001,
              54.998317
            ],
            [
              -132.57783,
              54.994234
            ],
            [
              -132.598557,
              54.990301
            ],
            [
              -132.6099,
              54.991517
            ],
            [
              -132.614836,
              54.980095
            ],
            [
              -132.612531,
              54.969924
            ],
            [
              -132.609786,
              54.965728
            ],
            [
              -132.581367,
              54.946005
            ],
            [
              -132.55839,
              54.932612
            ],
            [
              -132.484579,
              54.899301
            ],
            [
              -132.416438,
              54.887485
            ],
            [
              -132.344897,
              54.851924
            ],
            [
              -132.332661,
              54.826322
            ],
            [
              -132.341009,
              54.826718
            ],
            [
              -132.350629,
              54.821314
            ],
            [
              -132.351004,
              54.818182
            ],
            [
              -132.447812,
              54.826603
            ],
            [
              -132.509371,
              54.781258
            ],
            [
              -132.491007,
              54.77558
            ],
            [
              -132.429035,
              54.785918
            ],
            [
              -132.371042,
              54.782903
            ],
            [
              -132.366389,
              54.751197
            ],
            [
              -132.322555,
              54.743958
            ],
            [
              -132.307943,
              54.718714
            ],
            [
              -132.281803,
              54.71529
            ],
            [
              -132.280103,
              54.715988
            ],
            [
              -132.280701,
              54.726184
            ],
            [
              -132.279597,
              54.72823
            ],
            [
              -132.2631,
              54.734312
            ],
            [
              -132.256092,
              54.734617
            ],
            [
              -132.199566,
              54.715444
            ],
            [
              -132.179635,
              54.705898
            ],
            [
              -132.165182,
              54.69405
            ],
            [
              -132.142277,
              54.691674
            ],
            [
              -132.029747,
              54.701189
            ],
            [
              -132.018657,
              54.710109
            ],
            [
              -131.999591,
              54.731975
            ],
            [
              -131.957914,
              54.791239
            ],
            [
              -131.957382,
              54.804279
            ],
            [
              -131.965904,
              54.835539
            ],
            [
              -131.976847,
              54.848894
            ],
            [
              -131.98289,
              54.853068
            ],
            [
              -131.984592,
              55.027978
            ],
            [
              -132.000449,
              55.035712
            ],
            [
              -132.028288,
              55.038672
            ],
            [
              -132.039217,
              55.036765
            ],
            [
              -132.072544,
              55.016956
            ],
            [
              -132.135544,
              54.985976
            ],
            [
              -132.143263,
              54.984633
            ],
            [
              -132.192581,
              54.989655
            ],
            [
              -132.197614,
              55.005158
            ],
            [
              -132.196215,
              55.008047
            ],
            [
              -132.180334,
              55.015557
            ],
            [
              -132.168076,
              55.015574
            ],
            [
              -132.154061,
              55.018197
            ],
            [
              -132.094024,
              55.039452
            ],
            [
              -132.039002,
              55.086962
            ],
            [
              -132.016932,
              55.120971
            ],
            [
              -132.038978,
              55.125011
            ],
            [
              -132.040874,
              55.127326
            ],
            [
              -132.039009,
              55.14407
            ],
            [
              -132.031417,
              55.151671
            ],
            [
              -131.988815,
              55.165464
            ],
            [
              -131.977397,
              55.180949
            ],
            [
              -131.979818,
              55.211787
            ],
            [
              -131.995908,
              55.259054
            ],
            [
              -132.0035,
              55.265254
            ],
            [
              -132.028163,
              55.27695
            ],
            [
              -132.037122,
              55.275144
            ],
            [
              -132.061625,
              55.260052
            ],
            [
              -132.075924,
              55.246276
            ],
            [
              -132.078869,
              55.240335
            ],
            [
              -132.072751,
              55.233718
            ],
            [
              -132.075095,
              55.22239
            ],
            [
              -132.088127,
              55.206516
            ],
            [
              -132.104376,
              55.200899
            ],
            [
              -132.12737,
              55.19957
            ],
            [
              -132.207431,
              55.218273
            ],
            [
              -132.214353,
              55.222562
            ],
            [
              -132.229963,
              55.23808
            ],
            [
              -132.214912,
              55.2457
            ],
            [
              -132.164031,
              55.237617
            ],
            [
              -132.142742,
              55.238212
            ],
            [
              -132.109531,
              55.26024
            ],
            [
              -132.102716,
              55.268175
            ],
            [
              -132.098531,
              55.28056
            ],
            [
              -132.115561,
              55.281237
            ],
            [
              -132.126398,
              55.288418
            ],
            [
              -132.149864,
              55.328637
            ],
            [
              -132.166857,
              55.363039
            ],
            [
              -132.22596,
              55.374919
            ],
            [
              -132.258056,
              55.416142
            ],
            [
              -132.273503,
              55.419189
            ],
            [
              -132.390782,
              55.401739
            ],
            [
              -132.41934,
              55.432054
            ],
            [
              -132.303329,
              55.438268
            ],
            [
              -132.284442,
              55.442173
            ],
            [
              -132.281269,
              55.444189
            ],
            [
              -132.288585,
              55.451365
            ],
            [
              -132.31857,
              55.469208
            ],
            [
              -132.364297,
              55.483373
            ],
            [
              -132.388686,
              55.480994
            ],
            [
              -132.408317,
              55.512522
            ],
            [
              -132.491598,
              55.503599
            ],
            [
              -132.519556,
              55.520255
            ],
            [
              -132.568335,
              55.479804
            ],
            [
              -132.604027,
              55.47683
            ],
            [
              -132.605866988059,
              55.4805099761186
            ],
            [
              -132.608786,
              55.486348
            ],
            [
              -132.525505,
              55.549998
            ],
            [
              -132.544541,
              55.566655
            ],
            [
              -132.514798,
              55.576767
            ],
            [
              -132.325728,
              55.515395
            ],
            [
              -132.315773,
              55.514547
            ],
            [
              -132.247327,
              55.492951
            ],
            [
              -132.231936,
              55.48396
            ],
            [
              -132.230752,
              55.479944
            ],
            [
              -132.219413,
              55.472211
            ],
            [
              -132.17861,
              55.452829
            ],
            [
              -132.164757,
              55.451213
            ],
            [
              -132.142945,
              55.457941
            ],
            [
              -132.142649,
              55.460967
            ],
            [
              -132.146062,
              55.470346
            ],
            [
              -132.188771,
              55.508443
            ],
            [
              -132.198289,
              55.513045
            ],
            [
              -132.240921,
              55.526533
            ],
            [
              -132.301119,
              55.55096
            ],
            [
              -132.32928,
              55.578936
            ],
            [
              -132.332401,
              55.595071
            ],
            [
              -132.358558,
              55.648759
            ],
            [
              -132.382505,
              55.665336
            ],
            [
              -132.394266,
              55.669114
            ],
            [
              -132.415237,
              55.667263
            ],
            [
              -132.448855,
              55.667337
            ],
            [
              -132.462531,
              55.673854
            ],
            [
              -132.461281,
              55.6834
            ],
            [
              -132.470697,
              55.782162
            ],
            [
              -132.5048,
              55.815166
            ],
            [
              -132.543398,
              55.845927
            ],
            [
              -132.592085,
              55.877152
            ],
            [
              -132.614757,
              55.899635
            ],
            [
              -132.615103,
              55.908082
            ],
            [
              -132.618464,
              55.911476
            ],
            [
              -132.635291,
              55.921766
            ],
            [
              -132.837592,
              56.024327
            ],
            [
              -132.845569,
              56.03616
            ],
            [
              -132.882885,
              56.032774
            ],
            [
              -132.897675,
              56.020166
            ],
            [
              -132.927305,
              56.034459
            ],
            [
              -132.923409,
              56.043131
            ],
            [
              -132.932085,
              56.060717
            ],
            [
              -132.961913,
              56.061248
            ],
            [
              -132.977244,
              56.072725
            ],
            [
              -132.989603,
              56.089569
            ],
            [
              -132.989841,
              56.109719
            ],
            [
              -133.007667,
              56.120121
            ],
            [
              -133.03298,
              56.119724
            ],
            [
              -133.05552,
              56.125258
            ],
            [
              -133.061465,
              56.135305
            ],
            [
              -133.062175,
              56.141163
            ],
            [
              -133.059994,
              56.150761
            ],
            [
              -133.052004,
              56.155585
            ],
            [
              -133.040979,
              56.184536
            ],
            [
              -133.071435,
              56.238484
            ],
            [
              -133.07823,
              56.246802
            ],
            [
              -133.094977,
              56.250583
            ],
            [
              -133.163212,
              56.317445
            ],
            [
              -133.197009,
              56.333016
            ],
            [
              -133.235354,
              56.324275
            ],
            [
              -133.284826,
              56.327118
            ],
            [
              -133.328577,
              56.332797
            ],
            [
              -133.41837,
              56.332132
            ],
            [
              -133.582116,
              56.352506
            ],
            [
              -133.593728,
              56.352192
            ],
            [
              -133.624918,
              56.343906
            ],
            [
              -133.625121,
              56.339847
            ],
            [
              -133.624658,
              56.33707
            ],
            [
              -133.643182,
              56.324459
            ],
            [
              -133.649916,
              56.326792
            ],
            [
              -133.656415,
              56.326909
            ],
            [
              -133.664218,
              56.310504
            ],
            [
              -133.656889,
              56.281228
            ],
            [
              -133.646906,
              56.262224
            ],
            [
              -133.633121,
              56.252719
            ],
            [
              -133.62813,
              56.236606
            ],
            [
              -133.646193,
              56.224188
            ],
            [
              -133.6721,
              56.222734
            ],
            [
              -133.679468,
              56.211765
            ],
            [
              -133.649045,
              56.197883
            ],
            [
              -133.60864,
              56.190742
            ],
            [
              -133.58606,
              56.178309
            ],
            [
              -133.554449,
              56.167194
            ],
            [
              -133.550408,
              56.158327
            ],
            [
              -133.558014,
              56.142837
            ],
            [
              -133.581544,
              56.129196
            ],
            [
              -133.61886,
              56.143764
            ],
            [
              -133.635735,
              56.147736
            ],
            [
              -133.643817,
              56.127739
            ],
            [
              -133.633121,
              56.107863
            ],
            [
              -133.58202,
              56.097789
            ],
            [
              -133.55231,
              56.096331
            ],
            [
              -133.558965,
              56.084662
            ],
            [
              -133.59224,
              56.07949
            ],
            [
              -133.620999,
              56.084132
            ],
            [
              -133.659241,
              56.083818
            ],
            [
              -133.684209,
              56.075507
            ],
            [
              -133.693765,
              56.070562
            ],
            [
              -133.748803,
              56.009536
            ],
            [
              -133.777846,
              55.982876
            ],
            [
              -133.798092,
              55.975096
            ],
            [
              -133.816363,
              55.964025
            ]
          ]
        ],
        [
          [
            [
              -133.943499,
              55.912446
            ],
            [
              -133.945619,
              55.896216
            ],
            [
              -133.929325,
              55.869538
            ],
            [
              -133.92025,
              55.860295
            ],
            [
              -133.903184,
              55.848101
            ],
            [
              -133.894706,
              55.845641
            ],
            [
              -133.866988,
              55.845886
            ],
            [
              -133.861039,
              55.848844
            ],
            [
              -133.847565,
              55.869599
            ],
            [
              -133.840298,
              55.88677
            ],
            [
              -133.840833,
              55.892726
            ],
            [
              -133.846128,
              55.904622
            ],
            [
              -133.846763,
              55.91167
            ],
            [
              -133.854291,
              55.931581
            ],
            [
              -133.864099,
              55.936286
            ],
            [
              -133.876494,
              55.937683
            ],
            [
              -133.891851,
              55.93668
            ],
            [
              -133.935016,
              55.920689
            ],
            [
              -133.940298,
              55.917506
            ],
            [
              -133.943499,
              55.912446
            ]
          ]
        ],
        [
          [
            [
              -134.366957,
              55.910541
            ],
            [
              -134.348153,
              55.892817
            ],
            [
              -134.33083,
              55.879756
            ],
            [
              -134.317044,
              55.868555
            ],
            [
              -134.345432,
              55.856687
            ],
            [
              -134.344418,
              55.843198
            ],
            [
              -134.327238,
              55.83644
            ],
            [
              -134.314192,
              55.826791
            ],
            [
              -134.282453,
              55.828667
            ],
            [
              -134.273156,
              55.833058
            ],
            [
              -134.240537,
              55.853922
            ],
            [
              -134.231157,
              55.864747
            ],
            [
              -134.208251,
              55.876709
            ],
            [
              -134.168363,
              55.892319
            ],
            [
              -134.145803,
              55.896713
            ],
            [
              -134.136647,
              55.895393
            ],
            [
              -134.130544,
              55.897512
            ],
            [
              -134.125521,
              55.902095
            ],
            [
              -134.118062,
              55.914642
            ],
            [
              -134.122839,
              55.918654
            ],
            [
              -134.11668,
              55.944763
            ],
            [
              -134.099805,
              55.98414
            ],
            [
              -134.122689,
              55.985516
            ],
            [
              -134.139498,
              55.951683
            ],
            [
              -134.137358,
              55.934646
            ],
            [
              -134.152216,
              55.920916
            ],
            [
              -134.173104,
              55.918519
            ],
            [
              -134.230613,
              55.905629
            ],
            [
              -134.262378,
              55.892553
            ],
            [
              -134.265872,
              55.917371
            ],
            [
              -134.266656,
              55.929586
            ],
            [
              -134.315782,
              55.923003
            ],
            [
              -134.336063,
              55.926614
            ],
            [
              -134.366957,
              55.910541
            ]
          ]
        ],
        [
          [
            [
              -134.415206,
              56.847948
            ],
            [
              -134.418534,
              56.822333
            ],
            [
              -134.396667,
              56.769749
            ],
            [
              -134.401658,
              56.733001
            ],
            [
              -134.376274,
              56.668608
            ],
            [
              -134.321798,
              56.644501
            ],
            [
              -134.30112,
              56.620317
            ],
            [
              -134.320134,
              56.554484
            ],
            [
              -134.298981,
              56.552781
            ],
            [
              -134.241938,
              56.555531
            ],
            [
              -134.197967,
              56.531028
            ],
            [
              -134.224112,
              56.475017
            ],
            [
              -134.25192,
              56.44455
            ],
            [
              -134.243126,
              56.395778
            ],
            [
              -134.292801,
              56.354056
            ],
            [
              -134.299456,
              56.289605
            ],
            [
              -134.262616,
              56.215135
            ],
            [
              -134.259288,
              56.121446
            ],
            [
              -134.234094,
              56.067086
            ],
            [
              -134.189701,
              56.022682
            ],
            [
              -134.137121,
              56.006405
            ],
            [
              -134.111927,
              56.045188
            ],
            [
              -134.107886,
              56.080617
            ],
            [
              -134.087446,
              56.094939
            ],
            [
              -134.018043,
              56.088176
            ],
            [
              -133.99689,
              56.082209
            ],
            [
              -133.960525,
              56.091359
            ],
            [
              -133.927725,
              56.145949
            ],
            [
              -133.941986,
              56.180095
            ],
            [
              -133.903006,
              56.200858
            ],
            [
              -133.88114,
              56.223197
            ],
            [
              -133.876624,
              56.275884
            ],
            [
              -133.834555,
              56.319801
            ],
            [
              -133.847622,
              56.351318
            ],
            [
              -133.871633,
              56.363799
            ],
            [
              -133.863972,
              56.390672
            ],
            [
              -133.780601,
              56.392489
            ],
            [
              -133.776561,
              56.47016
            ],
            [
              -133.821007,
              56.495227
            ],
            [
              -133.764914,
              56.524867
            ],
            [
              -133.714526,
              56.550029
            ],
            [
              -133.675836,
              56.522714
            ],
            [
              -133.671653,
              56.487656
            ],
            [
              -133.663094,
              56.448073
            ],
            [
              -133.655468,
              56.442279
            ],
            [
              -133.638349,
              56.438881
            ],
            [
              -133.603669,
              56.435413
            ],
            [
              -133.532969,
              56.434751
            ],
            [
              -133.512684,
              56.437015
            ],
            [
              -133.460634,
              56.45412
            ],
            [
              -133.428585,
              56.479247
            ],
            [
              -133.417795,
              56.494147
            ],
            [
              -133.41415161645602,
              56.4946498497853
            ],
            [
              -133.41798,
              56.495791
            ],
            [
              -133.424331,
              56.50055
            ],
            [
              -133.414936,
              56.505746
            ],
            [
              -133.406423,
              56.509896
            ],
            [
              -133.406974,
              56.519657
            ],
            [
              -133.414873,
              56.526451
            ],
            [
              -133.425495,
              56.535003
            ],
            [
              -133.435039,
              56.535951
            ],
            [
              -133.438562,
              56.541706
            ],
            [
              -133.441943,
              56.548678
            ],
            [
              -133.442903,
              56.564138
            ],
            [
              -133.447713,
              56.572689
            ],
            [
              -133.448067,
              56.579136
            ],
            [
              -133.439961,
              56.586157
            ],
            [
              -133.432545,
              56.586983
            ],
            [
              -133.424998,
              56.590198
            ],
            [
              -133.425564,
              56.596135
            ],
            [
              -133.426224,
              56.606889
            ],
            [
              -133.427584,
              56.614796
            ],
            [
              -133.432164,
              56.62592
            ],
            [
              -133.437812,
              56.634781
            ],
            [
              -133.449727,
              56.637311
            ],
            [
              -133.460859,
              56.639503
            ],
            [
              -133.474873,
              56.644291
            ],
            [
              -133.484758,
              56.653522
            ],
            [
              -133.486733,
              56.661026
            ],
            [
              -133.48512,
              56.669227
            ],
            [
              -133.492422,
              56.677773
            ],
            [
              -133.504788,
              56.682425
            ],
            [
              -133.509923,
              56.687962
            ],
            [
              -133.504616,
              56.695468
            ],
            [
              -133.493185,
              56.69721
            ],
            [
              -133.478433,
              56.697559
            ],
            [
              -133.460453,
              56.696971
            ],
            [
              -133.450333,
              56.701425
            ],
            [
              -133.451478,
              56.706352
            ],
            [
              -133.459222,
              56.707093
            ],
            [
              -133.467011,
              56.705675
            ],
            [
              -133.47299,
              56.709675
            ],
            [
              -133.476249,
              56.712484
            ],
            [
              -133.48324,
              56.711766
            ],
            [
              -133.500683,
              56.712104
            ],
            [
              -133.517051,
              56.721187
            ],
            [
              -133.528816,
              56.725601
            ],
            [
              -133.540215,
              56.731499
            ],
            [
              -133.551456,
              56.739066
            ],
            [
              -133.564332,
              56.745218
            ],
            [
              -133.568549,
              56.750108
            ],
            [
              -133.570566,
              56.754222
            ],
            [
              -133.576473,
              56.755741
            ],
            [
              -133.574916,
              56.761181
            ],
            [
              -133.566825,
              56.761974
            ],
            [
              -133.561983,
              56.764535
            ],
            [
              -133.55404,
              56.764191
            ],
            [
              -133.554425,
              56.761221
            ],
            [
              -133.547621,
              56.752951
            ],
            [
              -133.540084,
              56.75023
            ],
            [
              -133.528422,
              56.747733
            ],
            [
              -133.510488,
              56.751854
            ],
            [
              -133.501527,
              56.748997
            ],
            [
              -133.493508,
              56.748772
            ],
            [
              -133.491548,
              56.752572
            ],
            [
              -133.489833,
              56.759381
            ],
            [
              -133.494489,
              56.762882
            ],
            [
              -133.498485,
              56.768079
            ],
            [
              -133.49378,
              56.775521
            ],
            [
              -133.489457,
              56.781059
            ],
            [
              -133.491383,
              56.785497
            ],
            [
              -133.494723,
              56.786577
            ],
            [
              -133.494968,
              56.790403
            ],
            [
              -133.493856,
              56.793057
            ],
            [
              -133.49965,
              56.797551
            ],
            [
              -133.506518,
              56.796956
            ],
            [
              -133.516207,
              56.797837
            ],
            [
              -133.517124,
              56.801439
            ],
            [
              -133.513655,
              56.811045
            ],
            [
              -133.497577,
              56.81677
            ],
            [
              -133.496354,
              56.825244
            ],
            [
              -133.488102,
              56.832686
            ],
            [
              -133.484116,
              56.840517
            ],
            [
              -133.482018,
              56.847708
            ],
            [
              -133.490016,
              56.849553
            ],
            [
              -133.490142,
              56.858649
            ],
            [
              -133.491079,
              56.868079
            ],
            [
              -133.503143,
              56.877927
            ],
            [
              -133.478771,
              56.88415
            ],
            [
              -133.455345,
              56.88288
            ],
            [
              -133.438787,
              56.885302
            ],
            [
              -133.424001,
              56.889016
            ],
            [
              -133.409503,
              56.895468
            ],
            [
              -133.398209,
              56.893914
            ],
            [
              -133.384094,
              56.895691
            ],
            [
              -133.377005,
              56.894646
            ],
            [
              -133.370511,
              56.898074
            ],
            [
              -133.369537,
              56.901945
            ],
            [
              -133.363498,
              56.907248
            ],
            [
              -133.29579,
              56.918269
            ],
            [
              -133.270533,
              56.924288
            ],
            [
              -133.270853,
              56.92618
            ],
            [
              -133.271391,
              56.928796
            ],
            [
              -133.268698,
              56.931111
            ],
            [
              -133.264764,
              56.931953
            ],
            [
              -133.260272,
              56.93289
            ],
            [
              -133.255937,
              56.932039
            ],
            [
              -133.253973,
              56.932089
            ],
            [
              -133.253978,
              56.932798
            ],
            [
              -133.256612,
              56.933701
            ],
            [
              -133.260495,
              56.934086
            ],
            [
              -133.262495,
              56.934787
            ],
            [
              -133.263954,
              56.936475
            ],
            [
              -133.262663,
              56.937719
            ],
            [
              -133.261922,
              56.938155
            ],
            [
              -133.262508,
              56.938616
            ],
            [
              -133.265019,
              56.93889
            ],
            [
              -133.268673,
              56.940446
            ],
            [
              -133.27304,
              56.9428
            ],
            [
              -133.275033,
              56.945182
            ],
            [
              -133.275327,
              56.947233
            ],
            [
              -133.276606,
              56.950399
            ],
            [
              -133.278519,
              56.953301
            ],
            [
              -133.280694,
              56.955177
            ],
            [
              -133.283479,
              56.957157
            ],
            [
              -133.286658,
              56.959841
            ],
            [
              -133.291382,
              56.964374
            ],
            [
              -133.291862,
              56.966875
            ],
            [
              -133.291475,
              56.96949
            ],
            [
              -133.292937,
              56.971586
            ],
            [
              -133.29498,
              56.974286
            ],
            [
              -133.296105,
              56.977117
            ],
            [
              -133.299493,
              56.978183
            ],
            [
              -133.301832,
              56.981748
            ],
            [
              -133.303144,
              56.98409
            ],
            [
              -133.306169,
              56.98653
            ],
            [
              -133.308615,
              56.98868
            ],
            [
              -133.312301,
              56.992411
            ],
            [
              -133.313877,
              56.994758
            ],
            [
              -133.316324,
              56.998105
            ],
            [
              -133.317785,
              57.002592
            ],
            [
              -133.321851,
              57.004238
            ],
            [
              -133.325513,
              57.004941
            ],
            [
              -133.325456669767,
              57.011309950502195
            ],
            [
              -133.334272,
              57.002442
            ],
            [
              -133.344217,
              57.001834
            ],
            [
              -133.346321,
              57.004118
            ],
            [
              -133.362502,
              57.007424
            ],
            [
              -133.446204,
              57.020124
            ],
            [
              -133.536258,
              57.0387
            ],
            [
              -133.613908,
              57.057978
            ],
            [
              -133.702925,
              57.065206
            ],
            [
              -133.773464,
              57.078676
            ],
            [
              -133.887957,
              57.097744
            ],
            [
              -134.008856,
              57.074578
            ],
            [
              -134.033979,
              57.063281
            ],
            [
              -134.049218,
              57.029203
            ],
            [
              -133.96706,
              56.988044
            ],
            [
              -133.921451,
              56.961511
            ],
            [
              -133.903634,
              56.927269
            ],
            [
              -133.916358,
              56.914447
            ],
            [
              -133.887426,
              56.868391
            ],
            [
              -133.86904,
              56.845938
            ],
            [
              -133.795364,
              56.824586
            ],
            [
              -133.787484,
              56.821366
            ],
            [
              -133.772302,
              56.809495
            ],
            [
              -133.765324,
              56.807489
            ],
            [
              -133.800804,
              56.785246
            ],
            [
              -133.833366,
              56.803209
            ],
            [
              -133.887082,
              56.808674
            ],
            [
              -133.942936,
              56.805551
            ],
            [
              -134.006635,
              56.851587
            ],
            [
              -134.047753,
              56.923
            ],
            [
              -134.147103,
              56.95697
            ],
            [
              -134.153283,
              56.934802
            ],
            [
              -134.193751,
              56.933598
            ],
            [
              -134.247642,
              56.935062
            ],
            [
              -134.270459,
              56.93558
            ],
            [
              -134.296366,
              56.907301
            ],
            [
              -134.353647,
              56.893153
            ],
            [
              -134.415206,
              56.847948
            ]
          ]
        ],
        [
          [
            [
              -134.666668,
              56.283334
            ],
            [
              -134.666441193352,
              56.170219731340794
            ],
            [
              -134.65987,
              56.182494
            ],
            [
              -134.653827,
              56.198385
            ],
            [
              -134.634668,
              56.265832
            ],
            [
              -134.634438778557,
              56.283252829674595
            ],
            [
              -134.666668,
              56.283334
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "02",
      "COUNTYFP": "220",
      "COUNTYNS": "01419981",
      "AFFGEOID": "0500000US02220",
      "GEOID": "02220",
      "NAME": "Sitka",
      "LSAD": "03",
      "ALAND": 7433222810,
      "AWATER": 5037940435,
      "County_state": "Sitka,02"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -135.69512,
              57.363077
            ],
            [
              -135.695563,
              57.35746
            ],
            [
              -135.684597,
              57.350328
            ],
            [
              -135.674687,
              57.336747
            ],
            [
              -135.638209,
              57.320278
            ],
            [
              -135.637423,
              57.315276
            ],
            [
              -135.631415,
              57.311568
            ],
            [
              -135.601782,
              57.300772
            ],
            [
              -135.600688,
              57.294074
            ],
            [
              -135.604161,
              57.290437
            ],
            [
              -135.602297,
              57.285707
            ],
            [
              -135.568954,
              57.255186
            ],
            [
              -135.561853,
              57.244067
            ],
            [
              -135.561883,
              57.241868
            ],
            [
              -135.552574,
              57.233194
            ],
            [
              -135.511241,
              57.247077
            ],
            [
              -135.503121,
              57.253809
            ],
            [
              -135.477683,
              57.256824
            ],
            [
              -135.470189,
              57.255752
            ],
            [
              -135.449073,
              57.248104
            ],
            [
              -135.429229,
              57.247213
            ],
            [
              -135.414799,
              57.251324
            ],
            [
              -135.406086,
              57.251678
            ],
            [
              -135.366177,
              57.248852
            ],
            [
              -135.358715,
              57.243776
            ],
            [
              -135.361771,
              57.233591
            ],
            [
              -135.372021,
              57.228003
            ],
            [
              -135.37758,
              57.206302
            ],
            [
              -135.372441,
              57.198063
            ],
            [
              -135.399312,
              57.185003
            ],
            [
              -135.422746,
              57.167724
            ],
            [
              -135.409703,
              57.148861
            ],
            [
              -135.477159,
              57.115327
            ],
            [
              -135.493084,
              57.094545
            ],
            [
              -135.457907,
              57.070165
            ],
            [
              -135.383632,
              57.040501
            ],
            [
              -135.353447,
              57.020905
            ],
            [
              -135.377571,
              56.988612
            ],
            [
              -135.442339,
              56.942355
            ],
            [
              -135.447924,
              56.889226
            ],
            [
              -135.432238,
              56.852074
            ],
            [
              -135.467533,
              56.841416
            ],
            [
              -135.506869,
              56.865978
            ],
            [
              -135.573419,
              56.86338
            ],
            [
              -135.535272,
              56.826138
            ],
            [
              -135.506312,
              56.781409
            ],
            [
              -135.467177,
              56.77141
            ],
            [
              -135.398678,
              56.779201
            ],
            [
              -135.362241,
              56.758742
            ],
            [
              -135.310159,
              56.778093
            ],
            [
              -135.280875,
              56.768357
            ],
            [
              -135.281608,
              56.758528
            ],
            [
              -135.29643,
              56.756294
            ],
            [
              -135.318082,
              56.748688
            ],
            [
              -135.317429,
              56.743311
            ],
            [
              -135.305077,
              56.726382
            ],
            [
              -135.27491,
              56.701633
            ],
            [
              -135.224142,
              56.687858
            ],
            [
              -135.21583,
              56.66534
            ],
            [
              -135.175826,
              56.677876
            ],
            [
              -135.147761,
              56.651932
            ],
            [
              -135.148836,
              56.648915
            ],
            [
              -135.123389,
              56.602823
            ],
            [
              -135.118709,
              56.597728
            ],
            [
              -135.103271,
              56.597927
            ],
            [
              -135.091611,
              56.600068
            ],
            [
              -135.065693,
              56.607852
            ],
            [
              -135.036432,
              56.619628
            ],
            [
              -135.029747,
              56.628918
            ],
            [
              -134.998369,
              56.630017
            ],
            [
              -134.979386,
              56.627676
            ],
            [
              -134.969842,
              56.615392
            ],
            [
              -134.967164,
              56.603026
            ],
            [
              -134.985881,
              56.595844
            ],
            [
              -134.997669,
              56.597272
            ],
            [
              -135.005249,
              56.602252
            ],
            [
              -135.02674,
              56.594625
            ],
            [
              -135.045054,
              56.583392
            ],
            [
              -135.056756,
              56.570611
            ],
            [
              -135.060878,
              56.541965
            ],
            [
              -135.058238,
              56.529453
            ],
            [
              -135.054049,
              56.527658
            ],
            [
              -135.02378,
              56.499977
            ],
            [
              -134.990763,
              56.457998
            ],
            [
              -134.945704,
              56.38971
            ],
            [
              -134.915911,
              56.360555
            ],
            [
              -134.839411,
              56.309403
            ],
            [
              -134.824902,
              56.279692
            ],
            [
              -134.806163,
              56.235533
            ],
            [
              -134.705007,
              56.175722
            ],
            [
              -134.674028,
              56.166925
            ],
            [
              -134.668151,
              56.167026
            ],
            [
              -134.666441193352,
              56.170219731340794
            ],
            [
              -134.666668,
              56.283334
            ],
            [
              -134.634438778557,
              56.283252829674595
            ],
            [
              -134.63401,
              56.31584
            ],
            [
              -134.636506,
              56.405625
            ],
            [
              -134.645308,
              56.472215
            ],
            [
              -134.655174,
              56.492033
            ],
            [
              -134.658622,
              56.494676
            ],
            [
              -134.663839,
              56.503183
            ],
            [
              -134.669778,
              56.524129
            ],
            [
              -134.671047,
              56.5412
            ],
            [
              -134.639732,
              56.538835
            ],
            [
              -134.634207,
              56.540968
            ],
            [
              -134.626943,
              56.553868
            ],
            [
              -134.615955,
              56.637289
            ],
            [
              -134.619799,
              56.665888
            ],
            [
              -134.633997,
              56.728722
            ],
            [
              -134.663434,
              56.804687
            ],
            [
              -134.674238,
              56.825177
            ],
            [
              -134.699099,
              56.850864
            ],
            [
              -134.695735,
              56.900791
            ],
            [
              -134.719275,
              56.929031
            ],
            [
              -134.740135,
              56.948481
            ],
            [
              -134.7383,
              56.968059
            ],
            [
              -134.73788,
              57.009935
            ],
            [
              -134.755886,
              57.058035
            ],
            [
              -134.841774,
              57.248588
            ],
            [
              -134.854948,
              57.264766
            ],
            [
              -134.879535,
              57.288177
            ],
            [
              -134.922329,
              57.303708
            ],
            [
              -134.946626,
              57.303164
            ],
            [
              -134.953011,
              57.306131
            ],
            [
              -134.955014,
              57.309433
            ],
            [
              -134.953951,
              57.323723
            ],
            [
              -134.951267,
              57.32866
            ],
            [
              -134.921651,
              57.330713
            ],
            [
              -134.88478,
              57.330003
            ],
            [
              -134.835211,
              57.300315
            ],
            [
              -134.829365,
              57.294098
            ],
            [
              -134.812769,
              57.299765
            ],
            [
              -134.806352,
              57.341888
            ],
            [
              -134.849477,
              57.40967
            ],
            [
              -134.89344,
              57.420044
            ],
            [
              -134.926516,
              57.422267
            ],
            [
              -135.026566,
              57.420497
            ],
            [
              -135.039634,
              57.418131
            ],
            [
              -135.06505,
              57.418972
            ],
            [
              -135.145949,
              57.439049
            ],
            [
              -135.159206,
              57.445568
            ],
            [
              -135.168056,
              57.454212
            ],
            [
              -135.161017,
              57.460713
            ],
            [
              -135.209123,
              57.488753
            ],
            [
              -135.227293,
              57.489838
            ],
            [
              -135.246114,
              57.483598
            ],
            [
              -135.265557,
              57.48086
            ],
            [
              -135.297784,
              57.483721
            ],
            [
              -135.308458,
              57.495201
            ],
            [
              -135.294837,
              57.50872
            ],
            [
              -135.294491,
              57.510836
            ],
            [
              -135.322925,
              57.533476
            ],
            [
              -135.347597,
              57.550232
            ],
            [
              -135.355749,
              57.553568
            ],
            [
              -135.419205,
              57.564159
            ],
            [
              -135.443404,
              57.556876
            ],
            [
              -135.491537,
              57.531687
            ],
            [
              -135.526036,
              57.509697
            ],
            [
              -135.548794,
              57.508977
            ],
            [
              -135.532069,
              57.481117
            ],
            [
              -135.534221,
              57.450252
            ],
            [
              -135.540158,
              57.446241
            ],
            [
              -135.556109,
              57.446458
            ],
            [
              -135.558496,
              57.444788
            ],
            [
              -135.562234,
              57.44272
            ],
            [
              -135.562234,
              57.440413
            ],
            [
              -135.561916,
              57.438505
            ],
            [
              -135.56099,
              57.437455
            ],
            [
              -135.557806,
              57.438236
            ],
            [
              -135.555321,
              57.436738
            ],
            [
              -135.554548,
              57.426636
            ],
            [
              -135.570872,
              57.417459
            ],
            [
              -135.585395,
              57.416811
            ],
            [
              -135.604184,
              57.409932
            ],
            [
              -135.626007,
              57.399578
            ],
            [
              -135.633654,
              57.399102
            ],
            [
              -135.633573,
              57.394211
            ],
            [
              -135.627022,
              57.388207
            ],
            [
              -135.628016,
              57.383393
            ],
            [
              -135.630627,
              57.379595
            ],
            [
              -135.639556,
              57.371883
            ],
            [
              -135.659614,
              57.373907
            ],
            [
              -135.687696,
              57.367477
            ],
            [
              -135.69512,
              57.363077
            ]
          ]
        ],
        [
          [
            [
              -135.852455,
              57.240936
            ],
            [
              -135.870519,
              57.221639
            ],
            [
              -135.823458,
              57.195637
            ],
            [
              -135.832253,
              57.170647
            ],
            [
              -135.791372,
              57.171034
            ],
            [
              -135.753581,
              57.167168
            ],
            [
              -135.740033,
              57.146286
            ],
            [
              -135.755007,
              57.123972
            ],
            [
              -135.806108,
              57.10939
            ],
            [
              -135.844612,
              57.083568
            ],
            [
              -135.847464,
              57.041826
            ],
            [
              -135.856021,
              56.995636
            ],
            [
              -135.825598,
              56.989032
            ],
            [
              -135.772595,
              56.998743
            ],
            [
              -135.734804,
              57.002108
            ],
            [
              -135.6799,
              57.014662
            ],
            [
              -135.63688,
              57.009874
            ],
            [
              -135.630463,
              57.026436
            ],
            [
              -135.604555,
              57.045834
            ],
            [
              -135.600515,
              57.071683
            ],
            [
              -135.571043,
              57.092608
            ],
            [
              -135.572231,
              57.126295
            ],
            [
              -135.541333,
              57.147704
            ],
            [
              -135.518278,
              57.150025
            ],
            [
              -135.492846,
              57.158533
            ],
            [
              -135.455768,
              57.16008
            ],
            [
              -135.451727,
              57.176445
            ],
            [
              -135.411797,
              57.203459
            ],
            [
              -135.398725,
              57.230677
            ],
            [
              -135.433773,
              57.236108
            ],
            [
              -135.478803,
              57.238962
            ],
            [
              -135.513524,
              57.224631
            ],
            [
              -135.541333,
              57.213017
            ],
            [
              -135.583877,
              57.229745
            ],
            [
              -135.594631,
              57.244779
            ],
            [
              -135.638599,
              57.292858
            ],
            [
              -135.698201,
              57.319304
            ],
            [
              -135.71365,
              57.331366
            ],
            [
              -135.760711,
              57.34663
            ],
            [
              -135.801354,
              57.341372
            ],
            [
              -135.848177,
              57.33855
            ],
            [
              -135.85816,
              57.321358
            ],
            [
              -135.837719,
              57.282068
            ],
            [
              -135.853168,
              57.26742
            ],
            [
              -135.852455,
              57.240936
            ]
          ]
        ],
        [
          [
            [
              -136.37173959985802,
              57.832236669219
            ],
            [
              -136.365052,
              57.828561
            ],
            [
              -136.309429,
              57.779465
            ],
            [
              -136.304684,
              57.771051
            ],
            [
              -136.314631,
              57.758703
            ],
            [
              -136.280809,
              57.717379
            ],
            [
              -136.250818,
              57.684831
            ],
            [
              -136.266688,
              57.663769
            ],
            [
              -136.238166,
              57.625991
            ],
            [
              -136.22961,
              57.595372
            ],
            [
              -136.190274,
              57.580402
            ],
            [
              -136.163059,
              57.558861
            ],
            [
              -136.120634,
              57.591295
            ],
            [
              -136.085739,
              57.577589
            ],
            [
              -136.088071,
              57.555291
            ],
            [
              -136.055509,
              57.533672
            ],
            [
              -136.047547,
              57.513762
            ],
            [
              -136.014529,
              57.506227
            ],
            [
              -135.966986,
              57.472759
            ],
            [
              -135.943766,
              57.45878
            ],
            [
              -135.896979,
              57.440719
            ],
            [
              -135.900816,
              57.418181
            ],
            [
              -135.892131,
              57.408048
            ],
            [
              -135.84381,
              57.390896
            ],
            [
              -135.768018,
              57.383435
            ],
            [
              -135.720478,
              57.373663
            ],
            [
              -135.711401,
              57.36776
            ],
            [
              -135.667735,
              57.396001
            ],
            [
              -135.597144,
              57.442652
            ],
            [
              -135.566807,
              57.485696
            ],
            [
              -135.580506,
              57.516954
            ],
            [
              -135.565651,
              57.547193
            ],
            [
              -135.588477,
              57.552081
            ],
            [
              -135.587475,
              57.568829
            ],
            [
              -135.570574,
              57.579613
            ],
            [
              -135.557812,
              57.583449
            ],
            [
              -135.52761,
              57.589003
            ],
            [
              -135.519471,
              57.588615
            ],
            [
              -135.511012,
              57.595906
            ],
            [
              -135.526303,
              57.606388
            ],
            [
              -135.546143,
              57.613826
            ],
            [
              -135.571186,
              57.605367
            ],
            [
              -135.592778,
              57.602474
            ],
            [
              -135.660902,
              57.629196
            ],
            [
              -135.697422,
              57.649711
            ],
            [
              -135.704444,
              57.658361
            ],
            [
              -135.712317,
              57.672648
            ],
            [
              -135.684951,
              57.677508
            ],
            [
              -135.650701,
              57.65252
            ],
            [
              -135.593447,
              57.663795
            ],
            [
              -135.571606,
              57.674397
            ],
            [
              -135.514801,
              57.650349
            ],
            [
              -135.474703,
              57.631381
            ],
            [
              -135.392499,
              57.599909
            ],
            [
              -135.325849,
              57.577178
            ],
            [
              -135.242855,
              57.546302
            ],
            [
              -135.134063,
              57.497681
            ],
            [
              -135.096818,
              57.469916
            ],
            [
              -135.091216,
              57.466352
            ],
            [
              -135.084338,
              57.464671
            ],
            [
              -135.025148,
              57.454315
            ],
            [
              -134.90268,
              57.459349
            ],
            [
              -134.874346,
              57.464406
            ],
            [
              -134.83712,
              57.482761
            ],
            [
              -134.824891,
              57.500067
            ],
            [
              -134.856386,
              57.598967
            ],
            [
              -134.867433,
              57.623439
            ],
            [
              -134.918167,
              57.682272
            ],
            [
              -134.91479,
              57.705821
            ],
            [
              -134.921604,
              57.742376
            ],
            [
              -134.929726,
              57.759203
            ],
            [
              -134.939924,
              57.763612
            ],
            [
              -134.97812,
              57.763125
            ],
            [
              -135.012577,
              57.746105
            ],
            [
              -135.024837,
              57.74299
            ],
            [
              -135.063972,
              57.750847
            ],
            [
              -135.098422,
              57.754898
            ],
            [
              -135.10802618195402,
              57.7684239812522
            ],
            [
              -135.122558,
              57.762558
            ],
            [
              -135.176422,
              57.757683
            ],
            [
              -135.217987,
              57.758789
            ],
            [
              -135.275879,
              57.773285
            ],
            [
              -135.351074,
              57.80545
            ],
            [
              -135.379425,
              57.823044
            ],
            [
              -135.426727,
              57.843994
            ],
            [
              -135.528534,
              57.88047
            ],
            [
              -135.608032,
              57.900673
            ],
            [
              -135.648499,
              57.91333
            ],
            [
              -135.715149,
              57.929413
            ],
            [
              -135.792389,
              57.957771
            ],
            [
              -135.81891,
              57.974144
            ],
            [
              -135.878746,
              57.997419
            ],
            [
              -135.901964,
              58.001343
            ],
            [
              -135.9799,
              57.8903
            ],
            [
              -136.024328,
              57.838795
            ],
            [
              -136.37173959985802,
              57.832236669219
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "02",
      "COUNTYFP": "185",
      "COUNTYNS": "01419978",
      "AFFGEOID": "0500000US02185",
      "GEOID": "02185",
      "NAME": "North Slope",
      "LSAD": "04",
      "ALAND": 230056014591,
      "AWATER": 15547168253,
      "County_state": "North Slope,02"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -166.796068,
              68.354069
            ],
            [
              -166.838969,
              68.337186
            ],
            [
              -166.689274,
              68.342478
            ],
            [
              -166.527965,
              68.326304
            ],
            [
              -166.398041,
              68.307011
            ],
            [
              -166.237049,
              68.273114
            ],
            [
              -166.135495,
              68.242169
            ],
            [
              -166.04417,
              68.200727
            ],
            [
              -165.994255,
              68.161209
            ],
            [
              -165.97497,
              68.14091
            ],
            [
              -165.898211,
              68.118252
            ],
            [
              -165.872088,
              68.110047
            ],
            [
              -165.783331,
              68.098236
            ],
            [
              -165.688137,
              68.090396
            ],
            [
              -165.49355,
              68.059283
            ],
            [
              -165.430442,
              68.045408
            ],
            [
              -165.386570100313,
              68.03473171360119
            ],
            [
              -164.499437,
              68.034748
            ],
            [
              -164.499463,
              68.20789
            ],
            [
              -163.615271,
              68.207907
            ],
            [
              -162.72219,
              68.207926
            ],
            [
              -162.722202,
              68.294496
            ],
            [
              -162.023273,
              68.294511
            ],
            [
              -162.023261,
              68.20794
            ],
            [
              -161.557328,
              68.207949
            ],
            [
              -161.55732,
              68.294521
            ],
            [
              -161.091364,
              68.294532
            ],
            [
              -161.091351,
              68.207961
            ],
            [
              -160.15944,
              68.207982
            ],
            [
              -160.159452,
              68.294553
            ],
            [
              -159.693487,
              68.294564
            ],
            [
              -159.693477,
              68.207992
            ],
            [
              -158.955704,
              68.208006
            ],
            [
              -158.1791,
              68.208024
            ],
            [
              -157.790801,
              68.208037
            ],
            [
              -157.154924,
              68.208054
            ],
            [
              -157.15491,
              68.121483
            ],
            [
              -156.236831,
              68.121498
            ],
            [
              -156.236817,
              68.034926
            ],
            [
              -155.318748,
              68.034942
            ],
            [
              -155.318744,
              68.000032
            ],
            [
              -154.395444,
              68.000006
            ],
            [
              -153.404152,
              68
            ],
            [
              -152.525287,
              68.000008
            ],
            [
              -151.678425,
              68.000006
            ],
            [
              -150.830148,
              68
            ],
            [
              -150.000591,
              68.000002
            ],
            [
              -149.1459,
              68
            ],
            [
              -148.524201,
              68
            ],
            [
              -147.753117,
              67.999988
            ],
            [
              -147.056671,
              68
            ],
            [
              -146.656445,
              68
            ],
            [
              -146.05255,
              68
            ],
            [
              -146,
              68
            ],
            [
              -146,
              68.5
            ],
            [
              -145.352435,
              68.499981
            ],
            [
              -144.659559,
              68.5
            ],
            [
              -143.718715,
              68.500005
            ],
            [
              -142.97202,
              68.500006
            ],
            [
              -142.232922,
              68.500007
            ],
            [
              -141.356222,
              68.498835
            ],
            [
              -141.002694000757,
              68.498364000001
            ],
            [
              -141.002694,
              68.498391
            ],
            [
              -141.002676,
              69.165185
            ],
            [
              -141.002672,
              69.645609
            ],
            [
              -141.119233,
              69.673527
            ],
            [
              -141.210456,
              69.68419
            ],
            [
              -141.303669,
              69.684993
            ],
            [
              -141.43084,
              69.695144
            ],
            [
              -141.528197,
              69.736025
            ],
            [
              -141.606229,
              69.761695
            ],
            [
              -141.713369,
              69.789497
            ],
            [
              -141.842843,
              69.811927
            ],
            [
              -141.958715,
              69.823376
            ],
            [
              -142.075612,
              69.853319
            ],
            [
              -142.081696,
              69.855498
            ],
            [
              -142.112714,
              69.862162
            ],
            [
              -142.239873,
              69.896598
            ],
            [
              -142.272156,
              69.907044
            ],
            [
              -142.404366,
              69.916511
            ],
            [
              -142.409962,
              69.933646
            ],
            [
              -142.452927,
              69.958125
            ],
            [
              -142.498036,
              69.973611
            ],
            [
              -142.746807,
              70.042531
            ],
            [
              -142.939555,
              70.07438
            ],
            [
              -143.03481,
              70.096384
            ],
            [
              -143.151273,
              70.123352
            ],
            [
              -143.191678,
              70.131957
            ],
            [
              -143.22127,
              70.136762
            ],
            [
              -143.253119,
              70.152262
            ],
            [
              -143.281878,
              70.151052
            ],
            [
              -143.358173,
              70.132361
            ],
            [
              -143.425199,
              70.124928
            ],
            [
              -143.5173,
              70.138418
            ],
            [
              -143.57767,
              70.14633
            ],
            [
              -143.619977,
              70.134582
            ],
            [
              -143.661215,
              70.134259
            ],
            [
              -143.712511,
              70.1318
            ],
            [
              -143.770547,
              70.125655
            ],
            [
              -143.773756,
              70.114219
            ],
            [
              -143.826164,
              70.107548
            ],
            [
              -143.887486,
              70.120605
            ],
            [
              -143.914244,
              70.115696
            ],
            [
              -143.95059,
              70.099985
            ],
            [
              -144.022673,
              70.087863
            ],
            [
              -144.090345,
              70.085094
            ],
            [
              -144.129206,
              70.079547
            ],
            [
              -144.172226,
              70.063102
            ],
            [
              -144.251849,
              70.057995
            ],
            [
              -144.274904,
              70.048711
            ],
            [
              -144.285362,
              70.040721
            ],
            [
              -144.386732,
              70.041005
            ],
            [
              -144.455421,
              70.035244
            ],
            [
              -144.488578,
              70.023879
            ],
            [
              -144.512258,
              70.004478
            ],
            [
              -144.589172,
              69.977611
            ],
            [
              -144.618671,
              69.969315
            ],
            [
              -144.672305,
              69.966876
            ],
            [
              -144.738976,
              69.970112
            ],
            [
              -144.792614,
              69.979796
            ],
            [
              -144.854539,
              69.986364
            ],
            [
              -144.856954,
              69.985987
            ],
            [
              -144.863111,
              69.973524
            ],
            [
              -144.867623,
              69.972266
            ],
            [
              -144.902304,
              69.96451
            ],
            [
              -144.953637,
              69.959262
            ],
            [
              -144.966761,
              69.964362
            ],
            [
              -144.990131,
              69.97768
            ],
            [
              -145.011711,
              69.981144
            ],
            [
              -145.175073,
              69.991707
            ],
            [
              -145.197331,
              69.994954
            ],
            [
              -145.218593,
              70.00728
            ],
            [
              -145.247167,
              70.017891
            ],
            [
              -145.331553,
              70.022596
            ],
            [
              -145.408182,
              70.031572
            ],
            [
              -145.43483,
              70.036994
            ],
            [
              -145.468856,
              70.048336
            ],
            [
              -145.469508,
              70.059136
            ],
            [
              -145.505682,
              70.074528
            ],
            [
              -145.522384,
              70.077465
            ],
            [
              -145.579972,
              70.076804
            ],
            [
              -145.623306,
              70.084375
            ],
            [
              -145.760443,
              70.126113
            ],
            [
              -145.767092,
              70.128717
            ],
            [
              -145.783327,
              70.139454
            ],
            [
              -145.790386,
              70.148569
            ],
            [
              -145.842689,
              70.164102
            ],
            [
              -145.858297,
              70.165996
            ],
            [
              -145.872923,
              70.148829
            ],
            [
              -145.917674,
              70.142525
            ],
            [
              -145.955164,
              70.140199
            ],
            [
              -146.006411,
              70.140402
            ],
            [
              -146.096827,
              70.145151
            ],
            [
              -146.114124,
              70.154956
            ],
            [
              -146.129579,
              70.158948
            ],
            [
              -146.172672,
              70.165894
            ],
            [
              -146.272965,
              70.176944
            ],
            [
              -146.309558,
              70.178907
            ],
            [
              -146.335147,
              70.176235
            ],
            [
              -146.413197,
              70.17825
            ],
            [
              -146.44886,
              70.183398
            ],
            [
              -146.508133,
              70.186044
            ],
            [
              -146.624761,
              70.182398
            ],
            [
              -146.713053,
              70.175398
            ],
            [
              -146.734021,
              70.175475
            ],
            [
              -146.885771,
              70.185917
            ],
            [
              -146.909516,
              70.173259
            ],
            [
              -146.973212,
              70.151857
            ],
            [
              -146.991109,
              70.14761
            ],
            [
              -147.161601,
              70.155612
            ],
            [
              -147.182123,
              70.16035
            ],
            [
              -147.21921,
              70.178826
            ],
            [
              -147.231008,
              70.195398
            ],
            [
              -147.233327,
              70.207553
            ],
            [
              -147.244119,
              70.218963
            ],
            [
              -147.350145,
              70.187683
            ],
            [
              -147.385271,
              70.185256
            ],
            [
              -147.402283,
              70.185273
            ],
            [
              -147.431532,
              70.188826
            ],
            [
              -147.50527,
              70.200384
            ],
            [
              -147.585678,
              70.203398
            ],
            [
              -147.648,
              70.203299
            ],
            [
              -147.681722,
              70.199954
            ],
            [
              -147.765104,
              70.219806
            ],
            [
              -147.789357,
              70.247972
            ],
            [
              -147.817637,
              70.276938
            ],
            [
              -147.863719,
              70.293317
            ],
            [
              -147.9615,
              70.314201
            ],
            [
              -148.076865,
              70.32751
            ],
            [
              -148.089174,
              70.331005
            ],
            [
              -148.089676,
              70.332812
            ],
            [
              -148.089576,
              70.335423
            ],
            [
              -148.09058,
              70.337432
            ],
            [
              -148.107231,
              70.342801
            ],
            [
              -148.138212,
              70.337752
            ],
            [
              -148.139682,
              70.322116
            ],
            [
              -148.166624,
              70.304911
            ],
            [
              -148.201505,
              70.294109
            ],
            [
              -148.22795,
              70.302861
            ],
            [
              -148.230786,
              70.323739
            ],
            [
              -148.203477,
              70.348188
            ],
            [
              -148.2582,
              70.336996
            ],
            [
              -148.2698,
              70.329617
            ],
            [
              -148.351437,
              70.304453
            ],
            [
              -148.363196,
              70.302627
            ],
            [
              -148.411253,
              70.302627
            ],
            [
              -148.450639,
              70.308437
            ],
            [
              -148.46615,
              70.313609
            ],
            [
              -148.477028,
              70.320872
            ],
            [
              -148.464543,
              70.340159
            ],
            [
              -148.477044,
              70.359068
            ],
            [
              -148.580356,
              70.412546
            ],
            [
              -148.590007,
              70.416956
            ],
            [
              -148.610566,
              70.422588
            ],
            [
              -148.667017,
              70.430084
            ],
            [
              -148.696768,
              70.42916
            ],
            [
              -148.69877,
              70.425878
            ],
            [
              -148.728082,
              70.413035
            ],
            [
              -148.789577,
              70.402746
            ],
            [
              -148.858069,
              70.422917
            ],
            [
              -148.928979,
              70.426835
            ],
            [
              -148.959443,
              70.423944
            ],
            [
              -149.082079,
              70.464894
            ],
            [
              -149.179148,
              70.4857
            ],
            [
              -149.314473,
              70.495325
            ],
            [
              -149.400623,
              70.489931
            ],
            [
              -149.418727,
              70.492257
            ],
            [
              -149.432083,
              70.50375
            ],
            [
              -149.461755,
              70.518271
            ],
            [
              -149.509854,
              70.508746
            ],
            [
              -149.524235,
              70.502128
            ],
            [
              -149.536891,
              70.499397
            ],
            [
              -149.565278,
              70.49645
            ],
            [
              -149.581348,
              70.495891
            ],
            [
              -149.649556,
              70.504757
            ],
            [
              -149.656806,
              70.508713
            ],
            [
              -149.661165,
              70.509203
            ],
            [
              -149.716075,
              70.504968
            ],
            [
              -149.728247,
              70.502793
            ],
            [
              -149.740188,
              70.498151
            ],
            [
              -149.790427,
              70.49119
            ],
            [
              -149.810924,
              70.490477
            ],
            [
              -149.81974,
              70.491428
            ],
            [
              -149.866698,
              70.510769
            ],
            [
              -150.074461,
              70.439333
            ],
            [
              -150.104388,
              70.432091
            ],
            [
              -150.112899,
              70.431372
            ],
            [
              -150.21185,
              70.432301
            ],
            [
              -150.36088,
              70.409151
            ],
            [
              -150.387025,
              70.428269
            ],
            [
              -150.375357,
              70.43853
            ],
            [
              -150.414358,
              70.459694
            ],
            [
              -150.496357,
              70.471218
            ],
            [
              -150.518937,
              70.483132
            ],
            [
              -150.661545,
              70.477573
            ],
            [
              -150.66511,
              70.463271
            ],
            [
              -150.698385,
              70.447766
            ],
            [
              -150.742356,
              70.461284
            ],
            [
              -150.786327,
              70.463271
            ],
            [
              -150.824355,
              70.453731
            ],
            [
              -150.855254,
              70.451743
            ],
            [
              -150.903765,
              70.46091
            ],
            [
              -150.950668,
              70.459561
            ],
            [
              -150.987874,
              70.450139
            ],
            [
              -151.020441,
              70.433841
            ],
            [
              -151.046702,
              70.427886
            ],
            [
              -151.085803,
              70.402376
            ],
            [
              -151.133339,
              70.391611
            ],
            [
              -151.147104,
              70.376775
            ],
            [
              -151.246237,
              70.372459
            ],
            [
              -151.297598,
              70.400748
            ],
            [
              -151.343202,
              70.408877
            ],
            [
              -151.444897,
              70.425405
            ],
            [
              -151.554647,
              70.435895
            ],
            [
              -151.605581,
              70.437332
            ],
            [
              -151.653184,
              70.4348
            ],
            [
              -151.785657,
              70.436935
            ],
            [
              -151.844375,
              70.434959
            ],
            [
              -151.876122,
              70.430761
            ],
            [
              -151.900033,
              70.434135
            ],
            [
              -151.946384,
              70.452523
            ],
            [
              -151.936783,
              70.463564
            ],
            [
              -151.91921,
              70.472686
            ],
            [
              -151.824111,
              70.484011
            ],
            [
              -151.775537,
              70.485353
            ],
            [
              -151.728579,
              70.495375
            ],
            [
              -151.734287,
              70.503492
            ],
            [
              -151.760248,
              70.516711
            ],
            [
              -151.751558,
              70.524105
            ],
            [
              -151.722711,
              70.541608
            ],
            [
              -151.709462,
              70.54649
            ],
            [
              -151.697258,
              70.547741
            ],
            [
              -151.695162,
              70.549675
            ],
            [
              -151.701467,
              70.55322
            ],
            [
              -151.718215,
              70.555286
            ],
            [
              -151.745047,
              70.554041
            ],
            [
              -151.774703,
              70.547925
            ],
            [
              -151.816701,
              70.545698
            ],
            [
              -151.880141,
              70.554869
            ],
            [
              -151.975785,
              70.563215
            ],
            [
              -152.02607,
              70.559345
            ],
            [
              -152.065748,
              70.563074
            ],
            [
              -152.064546,
              70.568542
            ],
            [
              -152.060684,
              70.574935
            ],
            [
              -152.078663,
              70.584504
            ],
            [
              -152.100886,
              70.587269
            ],
            [
              -152.146165,
              70.586754
            ],
            [
              -152.169944,
              70.585219
            ],
            [
              -152.200644,
              70.58607
            ],
            [
              -152.264049,
              70.592655
            ],
            [
              -152.332608,
              70.612871
            ],
            [
              -152.341592,
              70.612193
            ],
            [
              -152.355679,
              70.603794
            ],
            [
              -152.365736,
              70.601242
            ],
            [
              -152.420775,
              70.608983
            ],
            [
              -152.433781,
              70.616926
            ],
            [
              -152.460505,
              70.646107
            ],
            [
              -152.473348,
              70.683669
            ],
            [
              -152.471531,
              70.68884
            ],
            [
              -152.377274,
              70.714682
            ],
            [
              -152.370808,
              70.730068
            ],
            [
              -152.283763,
              70.7856
            ],
            [
              -152.263346,
              70.790777
            ],
            [
              -152.239344,
              70.793416
            ],
            [
              -152.19246,
              70.795294
            ],
            [
              -152.188649,
              70.79814
            ],
            [
              -152.187197,
              70.801546
            ],
            [
              -152.223053,
              70.824594
            ],
            [
              -152.29507,
              70.836847
            ],
            [
              -152.353658,
              70.843984
            ],
            [
              -152.384368,
              70.854177
            ],
            [
              -152.502683,
              70.867836
            ],
            [
              -152.603103,
              70.881814
            ],
            [
              -152.696868,
              70.882086
            ],
            [
              -152.781006,
              70.881386
            ],
            [
              -152.843278,
              70.898388
            ],
            [
              -152.904243,
              70.883877
            ],
            [
              -152.931101,
              70.890881
            ],
            [
              -152.999315,
              70.894654
            ],
            [
              -153.033066,
              70.900799
            ],
            [
              -153.049207,
              70.913102
            ],
            [
              -153.147627,
              70.9186
            ],
            [
              -153.23848,
              70.922467
            ],
            [
              -153.253386,
              70.920775
            ],
            [
              -153.326202,
              70.904111
            ],
            [
              -153.359112,
              70.898292
            ],
            [
              -153.426265,
              70.890131
            ],
            [
              -153.485989,
              70.885873
            ],
            [
              -153.525976,
              70.8855
            ],
            [
              -153.615619,
              70.884849
            ],
            [
              -153.666363,
              70.883448
            ],
            [
              -153.712235,
              70.888935
            ],
            [
              -153.749432,
              70.888741
            ],
            [
              -153.805049,
              70.886017
            ],
            [
              -153.89048,
              70.885719
            ],
            [
              -153.932921,
              70.878677
            ],
            [
              -153.934351,
              70.876609
            ],
            [
              -153.932949,
              70.874201
            ],
            [
              -153.93545,
              70.869728
            ],
            [
              -153.95137,
              70.854028
            ],
            [
              -153.976014,
              70.833925
            ],
            [
              -153.995579,
              70.821876
            ],
            [
              -154.069982,
              70.793703
            ],
            [
              -154.127487,
              70.778133
            ],
            [
              -154.169631,
              70.768604
            ],
            [
              -154.181863,
              70.768325
            ],
            [
              -154.239166,
              70.776866
            ],
            [
              -154.223307,
              70.79523
            ],
            [
              -154.228627,
              70.802417
            ],
            [
              -154.260799,
              70.815164
            ],
            [
              -154.352604,
              70.834828
            ],
            [
              -154.430229,
              70.831258
            ],
            [
              -154.4857,
              70.825304
            ],
            [
              -154.501866,
              70.821765
            ],
            [
              -154.51904,
              70.822799
            ],
            [
              -154.572458,
              70.82594
            ],
            [
              -154.60269,
              70.867885
            ],
            [
              -154.608347,
              70.908905
            ],
            [
              -154.608294,
              70.961716
            ],
            [
              -154.594048,
              70.976993
            ],
            [
              -154.567593,
              70.989929
            ],
            [
              -154.581129,
              71.007321
            ],
            [
              -154.604413,
              71.021502
            ],
            [
              -154.61605,
              71.026182
            ],
            [
              -154.777335,
              71.083231
            ],
            [
              -154.942864,
              71.126264
            ],
            [
              -155.03174,
              71.146473
            ],
            [
              -155.060764,
              71.145422
            ],
            [
              -155.085782,
              71.127572
            ],
            [
              -155.064558,
              71.108006
            ],
            [
              -155.061428,
              71.091999
            ],
            [
              -155.064004,
              71.083912
            ],
            [
              -155.075362,
              71.072042
            ],
            [
              -155.108509,
              71.070475
            ],
            [
              -155.120317,
              71.073416
            ],
            [
              -155.125994,
              71.077495
            ],
            [
              -155.142858,
              71.097254
            ],
            [
              -155.146931,
              71.103459
            ],
            [
              -155.146948,
              71.110959
            ],
            [
              -155.150524,
              71.11205
            ],
            [
              -155.25686,
              71.081119
            ],
            [
              -155.262602,
              71.079149
            ],
            [
              -155.275989,
              71.070464
            ],
            [
              -155.275814,
              71.067042
            ],
            [
              -155.273764,
              71.064728
            ],
            [
              -155.177,
              71.02745
            ],
            [
              -155.163938,
              71.013801
            ],
            [
              -155.159922,
              71.002775
            ],
            [
              -155.161735,
              70.995715
            ],
            [
              -155.168934,
              70.987947
            ],
            [
              -155.182779,
              70.978218
            ],
            [
              -155.192246,
              70.974056
            ],
            [
              -155.201466,
              70.974306
            ],
            [
              -155.211434,
              70.978023
            ],
            [
              -155.256177,
              71.004762
            ],
            [
              -155.260084,
              71.011281
            ],
            [
              -155.260042,
              71.015227
            ],
            [
              -155.343871,
              71.004449
            ],
            [
              -155.36416,
              70.994195
            ],
            [
              -155.382646,
              70.978973
            ],
            [
              -155.404225,
              70.967477
            ],
            [
              -155.454991,
              70.94749
            ],
            [
              -155.47594,
              70.943547
            ],
            [
              -155.513293,
              70.940579
            ],
            [
              -155.493044,
              70.917371
            ],
            [
              -155.487574,
              70.902679
            ],
            [
              -155.485915,
              70.885905
            ],
            [
              -155.48798,
              70.875299
            ],
            [
              -155.489811,
              70.87174
            ],
            [
              -155.504202,
              70.860303
            ],
            [
              -155.543031,
              70.847175
            ],
            [
              -155.643516,
              70.824209
            ],
            [
              -155.731842,
              70.83116
            ],
            [
              -155.868743,
              70.829397
            ],
            [
              -155.935413,
              70.837356
            ],
            [
              -155.924955,
              70.85272
            ],
            [
              -155.95407,
              70.893022
            ],
            [
              -156.013512,
              70.895983
            ],
            [
              -156.014425,
              70.898644
            ],
            [
              -156.014769,
              70.903133
            ],
            [
              -155.979264,
              70.918524
            ],
            [
              -155.995681,
              70.947796
            ],
            [
              -155.978405,
              70.962197
            ],
            [
              -155.95205,
              70.964831
            ],
            [
              -155.878946,
              70.967684
            ],
            [
              -155.830881,
              70.965584
            ],
            [
              -155.762068,
              70.985644
            ],
            [
              -155.711852,
              71.012473
            ],
            [
              -155.705487,
              71.020153
            ],
            [
              -155.638994,
              71.04236
            ],
            [
              -155.548283,
              71.060685
            ],
            [
              -155.533347,
              71.067683
            ],
            [
              -155.510637,
              71.081152
            ],
            [
              -155.511125,
              71.090348
            ],
            [
              -155.513987,
              71.096794
            ],
            [
              -155.520737,
              71.102476
            ],
            [
              -155.561772,
              71.128458
            ],
            [
              -155.567765,
              71.14113
            ],
            [
              -155.566925,
              71.165139
            ],
            [
              -155.587702,
              71.17256
            ],
            [
              -155.657178,
              71.182471
            ],
            [
              -155.760802,
              71.194662
            ],
            [
              -155.803853,
              71.19642
            ],
            [
              -155.829034,
              71.192088
            ],
            [
              -155.88418,
              71.190057
            ],
            [
              -155.895105,
              71.193899
            ],
            [
              -155.920202,
              71.207157
            ],
            [
              -155.957961,
              71.186211
            ],
            [
              -156.018574,
              71.172041
            ],
            [
              -156.044615,
              71.184701
            ],
            [
              -156.044754,
              71.18677
            ],
            [
              -156.038116,
              71.196506
            ],
            [
              -156.029205,
              71.203209
            ],
            [
              -156.074411,
              71.242489
            ],
            [
              -156.2142,
              71.259392
            ],
            [
              -156.301938,
              71.260566
            ],
            [
              -156.320702,
              71.258952
            ],
            [
              -156.356736,
              71.261273
            ],
            [
              -156.402659,
              71.267945
            ],
            [
              -156.524499,
              71.294469
            ],
            [
              -156.531124,
              71.296338
            ],
            [
              -156.556496,
              71.311795
            ],
            [
              -156.561512,
              71.316809
            ],
            [
              -156.566383,
              71.334016
            ],
            [
              -156.56865,
              71.352561
            ],
            [
              -156.62014,
              71.344209
            ],
            [
              -156.645615,
              71.338012
            ],
            [
              -156.773937,
              71.299506
            ],
            [
              -156.809653,
              71.286886
            ],
            [
              -156.962555,
              71.211885
            ],
            [
              -157.072487,
              71.154521
            ],
            [
              -157.119621,
              71.128682
            ],
            [
              -157.249083,
              71.052537
            ],
            [
              -157.392802,
              70.987908
            ],
            [
              -157.421001,
              70.976805
            ],
            [
              -157.502459,
              70.948659
            ],
            [
              -157.708782,
              70.89139
            ],
            [
              -157.768452,
              70.875842
            ],
            [
              -157.840997,
              70.861025
            ],
            [
              -157.884086,
              70.853468
            ],
            [
              -158.032397,
              70.832263
            ],
            [
              -158.157725,
              70.820806
            ],
            [
              -158.25032,
              70.817734
            ],
            [
              -158.366302,
              70.819712
            ],
            [
              -158.375717,
              70.806939
            ],
            [
              -158.41048,
              70.799239
            ],
            [
              -158.462426,
              70.802871
            ],
            [
              -158.52264,
              70.792506
            ],
            [
              -158.573913,
              70.79495
            ],
            [
              -158.657855,
              70.786061
            ],
            [
              -158.763503,
              70.788173
            ],
            [
              -158.949607,
              70.796774
            ],
            [
              -159.066118,
              70.817523
            ],
            [
              -159.114972,
              70.817402
            ],
            [
              -159.149317,
              70.834225
            ],
            [
              -159.17181,
              70.875103
            ],
            [
              -159.395934,
              70.840842
            ],
            [
              -159.528682,
              70.820849
            ],
            [
              -159.585714,
              70.809475
            ],
            [
              -159.648383,
              70.794368
            ],
            [
              -159.798514,
              70.731226
            ],
            [
              -159.913805,
              70.690673
            ],
            [
              -160.056727,
              70.632834
            ],
            [
              -160.214828,
              70.559087
            ],
            [
              -160.480062,
              70.465971
            ],
            [
              -160.608869,
              70.427493
            ],
            [
              -160.81279,
              70.376696
            ],
            [
              -161.003665,
              70.338485
            ],
            [
              -161.041248,
              70.33076
            ],
            [
              -161.089021,
              70.325799
            ],
            [
              -161.236145,
              70.304703
            ],
            [
              -161.288197,
              70.296772
            ],
            [
              -161.300084,
              70.29338
            ],
            [
              -161.352487,
              70.295931
            ],
            [
              -161.40359,
              70.294768
            ],
            [
              -161.445303,
              70.294808
            ],
            [
              -161.534077,
              70.299015
            ],
            [
              -161.651158,
              70.308445
            ],
            [
              -161.770161,
              70.319944
            ],
            [
              -161.84413,
              70.325599
            ],
            [
              -161.879266,
              70.329269
            ],
            [
              -161.928688,
              70.313792
            ],
            [
              -161.939248,
              70.307536
            ],
            [
              -161.993511,
              70.294808
            ],
            [
              -162.046276,
              70.280699
            ],
            [
              -162.04901,
              70.276287
            ],
            [
              -162.074441,
              70.270752
            ],
            [
              -162.214989,
              70.230361
            ],
            [
              -162.302314,
              70.204227
            ],
            [
              -162.342425,
              70.190159
            ],
            [
              -162.388416,
              70.16606
            ],
            [
              -162.445816,
              70.139996
            ],
            [
              -162.475277,
              70.124031
            ],
            [
              -162.503572,
              70.100393
            ],
            [
              -162.523168,
              70.087362
            ],
            [
              -162.558714,
              70.072585
            ],
            [
              -162.58676,
              70.053094
            ],
            [
              -162.605299,
              70.03683
            ],
            [
              -162.653073,
              70.004671
            ],
            [
              -162.707457,
              69.970489
            ],
            [
              -162.78808,
              69.929097
            ],
            [
              -162.826178,
              69.902717
            ],
            [
              -162.852801,
              69.894637
            ],
            [
              -162.915472,
              69.863348
            ],
            [
              -162.989084,
              69.82525
            ],
            [
              -163.009673,
              69.812701
            ],
            [
              -163.042517,
              69.77868
            ],
            [
              -163.063189,
              69.747782
            ],
            [
              -163.071903,
              69.737605
            ],
            [
              -163.107516,
              69.675918
            ],
            [
              -163.118348,
              69.668158
            ],
            [
              -163.142891,
              69.637594
            ],
            [
              -163.151261,
              69.61263
            ],
            [
              -163.145944,
              69.541342
            ],
            [
              -163.14544,
              69.521304
            ],
            [
              -163.148754,
              69.458606
            ],
            [
              -163.142602,
              69.452949
            ],
            [
              -163.151351,
              69.430262
            ],
            [
              -163.156241,
              69.413399
            ],
            [
              -163.169394,
              69.385237
            ],
            [
              -163.202351,
              69.343795
            ],
            [
              -163.244656,
              69.306081
            ],
            [
              -163.266881,
              69.290909
            ],
            [
              -163.297956,
              69.274725
            ],
            [
              -163.452685,
              69.19463
            ],
            [
              -163.535314,
              69.141656
            ],
            [
              -163.574034,
              69.124077
            ],
            [
              -163.655864,
              69.090567
            ],
            [
              -163.724184,
              69.066713
            ],
            [
              -163.827447,
              69.040632
            ],
            [
              -163.858069,
              69.02886
            ],
            [
              -163.893881,
              69.011962
            ],
            [
              -163.973678,
              68.985044
            ],
            [
              -164.069362,
              68.969651
            ],
            [
              -164.130742,
              68.951001
            ],
            [
              -164.161249,
              68.944773
            ],
            [
              -164.253157,
              68.930938
            ],
            [
              -164.299092,
              68.927569
            ],
            [
              -164.526887,
              68.917909
            ],
            [
              -164.655317,
              68.90936
            ],
            [
              -164.812671,
              68.893542
            ],
            [
              -164.883745,
              68.891649
            ],
            [
              -164.967542,
              68.88303
            ],
            [
              -165.327043,
              68.858111
            ],
            [
              -165.522358,
              68.855839
            ],
            [
              -165.572483,
              68.852946
            ],
            [
              -165.666566,
              68.855387
            ],
            [
              -165.814938,
              68.864158
            ],
            [
              -166.214433,
              68.879524
            ],
            [
              -166.224187,
              68.873175
            ],
            [
              -166.222496,
              68.860441
            ],
            [
              -166.20375,
              68.818221
            ],
            [
              -166.195374,
              68.80399
            ],
            [
              -166.190209,
              68.790437
            ],
            [
              -166.187795,
              68.778706
            ],
            [
              -166.197365,
              68.690019
            ],
            [
              -166.199826,
              68.678556
            ],
            [
              -166.213635,
              68.664324
            ],
            [
              -166.229761,
              68.613771
            ],
            [
              -166.231432,
              68.587338
            ],
            [
              -166.225567,
              68.579015
            ],
            [
              -166.226111,
              68.576186
            ],
            [
              -166.23378,
              68.564263
            ],
            [
              -166.24449,
              68.553888
            ],
            [
              -166.295343,
              68.5109
            ],
            [
              -166.30203,
              68.470413
            ],
            [
              -166.303464,
              68.464683
            ],
            [
              -166.305962,
              68.46154
            ],
            [
              -166.328459,
              68.442261
            ],
            [
              -166.342381,
              68.433966
            ],
            [
              -166.362135,
              68.42624
            ],
            [
              -166.377564,
              68.422406
            ],
            [
              -166.706139,
              68.371783
            ],
            [
              -166.796068,
              68.354069
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "02",
      "COUNTYFP": "050",
      "COUNTYNS": "01419966",
      "AFFGEOID": "0500000US02050",
      "GEOID": "02050",
      "NAME": "Bethel",
      "LSAD": "05",
      "ALAND": 105228855314,
      "AWATER": 12768607553,
      "County_state": "Bethel,02"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -165.419788,
              60.552418
            ],
            [
              -165.420349,
              60.550692
            ],
            [
              -165.405071,
              60.53465
            ],
            [
              -165.377559,
              60.513164
            ],
            [
              -165.362975,
              60.506866
            ],
            [
              -165.244442,
              60.496298
            ],
            [
              -165.190449,
              60.498001
            ],
            [
              -165.05744,
              60.544631
            ],
            [
              -164.986952,
              60.542406
            ],
            [
              -164.97128,
              60.539558
            ],
            [
              -164.965488,
              60.536701
            ],
            [
              -164.960843,
              60.533845
            ],
            [
              -164.956788,
              60.527837
            ],
            [
              -164.961439,
              60.508391
            ],
            [
              -164.99787,
              60.480459
            ],
            [
              -165.015155,
              60.471414
            ],
            [
              -165.04907,
              60.461516
            ],
            [
              -165.069693,
              60.460893
            ],
            [
              -165.120728,
              60.451196
            ],
            [
              -165.124792,
              60.449191
            ],
            [
              -165.132893,
              60.438867
            ],
            [
              -165.129403,
              60.433707
            ],
            [
              -165.057585,
              60.386287
            ],
            [
              -165.005576,
              60.359812
            ],
            [
              -164.962678,
              60.33966
            ],
            [
              -164.899296,
              60.316787
            ],
            [
              -164.850355,
              60.303615
            ],
            [
              -164.777233,
              60.293833
            ],
            [
              -164.72657,
              60.291475
            ],
            [
              -164.698889,
              60.296298
            ],
            [
              -164.653098,
              60.267902
            ],
            [
              -164.651996,
              60.262745
            ],
            [
              -164.646332,
              60.253303
            ],
            [
              -164.634362,
              60.24298
            ],
            [
              -164.619501,
              60.234938
            ],
            [
              -164.59607,
              60.222874
            ],
            [
              -164.558343,
              60.207042
            ],
            [
              -164.541699,
              60.205279
            ],
            [
              -164.517647,
              60.199493
            ],
            [
              -164.505677,
              60.194304
            ],
            [
              -164.494317,
              60.184833
            ],
            [
              -164.493861,
              60.177397
            ],
            [
              -164.498556,
              60.170546
            ],
            [
              -164.461194,
              60.137824
            ],
            [
              -164.385471,
              60.07719
            ],
            [
              -164.336111,
              60.055527
            ],
            [
              -164.302968,
              60.054233
            ],
            [
              -164.1916,
              60.024496
            ],
            [
              -164.13181,
              59.991177
            ],
            [
              -164.11508,
              59.973166
            ],
            [
              -164.12543,
              59.964626
            ],
            [
              -164.161024,
              59.964076
            ],
            [
              -164.178705,
              59.96181
            ],
            [
              -164.198545,
              59.955109
            ],
            [
              -164.208306,
              59.949046
            ],
            [
              -164.209843,
              59.942874
            ],
            [
              -164.208475,
              59.934461
            ],
            [
              -164.201811,
              59.916119
            ],
            [
              -164.160319,
              59.864679
            ],
            [
              -164.133393,
              59.845612
            ],
            [
              -164.115117,
              59.836688
            ],
            [
              -164.079837,
              59.828034
            ],
            [
              -163.930798,
              59.803853
            ],
            [
              -163.772229,
              59.795624
            ],
            [
              -163.704795,
              59.794805
            ],
            [
              -163.662607,
              59.79571
            ],
            [
              -163.559148,
              59.801391
            ],
            [
              -163.38767,
              59.81588
            ],
            [
              -163.349027,
              59.81989
            ],
            [
              -163.172633,
              59.845058
            ],
            [
              -163.086004,
              59.861008
            ],
            [
              -162.997587,
              59.885821
            ],
            [
              -162.929135,
              59.908054
            ],
            [
              -162.904179,
              59.919134
            ],
            [
              -162.859258,
              59.924971
            ],
            [
              -162.808513,
              59.933933
            ],
            [
              -162.77809,
              59.944291
            ],
            [
              -162.746716,
              59.961845
            ],
            [
              -162.737447,
              59.972254
            ],
            [
              -162.742676,
              59.984681
            ],
            [
              -162.707499,
              59.990268
            ],
            [
              -162.644231,
              59.972954
            ],
            [
              -162.622569,
              59.971809
            ],
            [
              -162.585518,
              59.97723
            ],
            [
              -162.547303,
              59.980876
            ],
            [
              -162.530118,
              59.99011
            ],
            [
              -162.503647,
              59.99923
            ],
            [
              -162.476759,
              60.04769
            ],
            [
              -162.495608,
              60.078949
            ],
            [
              -162.498103,
              60.115088
            ],
            [
              -162.490973,
              60.14267
            ],
            [
              -162.45128,
              60.174367
            ],
            [
              -162.37224,
              60.167009
            ],
            [
              -162.336849,
              60.128795
            ],
            [
              -162.273746,
              60.086682
            ],
            [
              -162.268255,
              60.069186
            ],
            [
              -162.228371,
              60.056313
            ],
            [
              -162.207225,
              60.021834
            ],
            [
              -162.190616,
              60.00203
            ],
            [
              -162.171759,
              59.984163
            ],
            [
              -162.143049,
              59.967506
            ],
            [
              -162.121072,
              59.965241
            ],
            [
              -162.10856,
              59.953861
            ],
            [
              -162.100708,
              59.944675
            ],
            [
              -162.108772,
              59.920107
            ],
            [
              -162.092361,
              59.881104
            ],
            [
              -162.017059,
              59.829426
            ],
            [
              -161.911163,
              59.741741
            ],
            [
              -161.88552,
              59.69839
            ],
            [
              -161.873944,
              59.649487
            ],
            [
              -161.820466,
              59.594978
            ],
            [
              -161.75798,
              59.557152
            ],
            [
              -161.70253,
              59.490906
            ],
            [
              -161.738312,
              59.46701
            ],
            [
              -161.790375,
              59.468197
            ],
            [
              -161.814406,
              59.437087
            ],
            [
              -161.848988,
              59.432494
            ],
            [
              -161.877034,
              59.400145
            ],
            [
              -161.95119,
              59.380721
            ],
            [
              -161.955944,
              59.352321
            ],
            [
              -161.992903,
              59.338385
            ],
            [
              -162.032001,
              59.288531
            ],
            [
              -162.056363,
              59.271047
            ],
            [
              -162.048584,
              59.254177
            ],
            [
              -161.981964,
              59.150997
            ],
            [
              -161.878698,
              59.068112
            ],
            [
              -161.856118,
              59.061636
            ],
            [
              -161.821774,
              59.050575
            ],
            [
              -161.841382,
              59.033153
            ],
            [
              -161.783981,
              58.969421
            ],
            [
              -161.784458,
              58.938978
            ],
            [
              -161.786953,
              58.899714
            ],
            [
              -161.756622,
              58.826477
            ],
            [
              -161.769501,
              58.774937
            ],
            [
              -161.859055,
              58.708637
            ],
            [
              -161.877213,
              58.666138
            ],
            [
              -161.939163,
              58.655613
            ],
            [
              -161.994644,
              58.688828
            ],
            [
              -162.171722,
              58.648441
            ],
            [
              -162.066269,
              58.6208
            ],
            [
              -161.871216,
              58.637478
            ],
            [
              -161.766296,
              58.599224
            ],
            [
              -161.751999,
              58.551842
            ],
            [
              -161.682907,
              58.564671
            ],
            [
              -161.62645,
              58.602581
            ],
            [
              -161.550537,
              58.61116
            ],
            [
              -161.521347,
              58.633141
            ],
            [
              -161.372314,
              58.666172
            ],
            [
              -161.372711,
              58.707958
            ],
            [
              -161.341530442937,
              58.7393405676405
            ],
            [
              -161.365049,
              58.739364
            ],
            [
              -161.364931,
              58.826021
            ],
            [
              -161.07251163437803,
              58.826023700205496
            ],
            [
              -161.034136119879,
              58.838743396163494
            ],
            [
              -161.031441,
              58.839752
            ],
            [
              -161.031448,
              58.999393
            ],
            [
              -160.864703,
              58.999394
            ],
            [
              -160.864705,
              59.086077
            ],
            [
              -160.812133,
              59.086077
            ],
            [
              -160.81214,
              59.259438
            ],
            [
              -160.643716,
              59.25944
            ],
            [
              -160.643719,
              59.34612
            ],
            [
              -160.475296,
              59.346122
            ],
            [
              -160.475354,
              59.432799
            ],
            [
              -160.41864,
              59.4328
            ],
            [
              -160.418647,
              59.606152
            ],
            [
              -160.248503,
              59.606154
            ],
            [
              -160.24851,
              59.779501
            ],
            [
              -160.017423,
              59.779504
            ],
            [
              -160.01743,
              59.952846
            ],
            [
              -159.845524,
              59.952848
            ],
            [
              -159.845533,
              60.126186
            ],
            [
              -159.781902,
              60.126187
            ],
            [
              -159.781906,
              60.212854
            ],
            [
              -159.608196,
              60.212856
            ],
            [
              -159.608204,
              60.386188
            ],
            [
              -159.434497,
              60.38619
            ],
            [
              -159.434502,
              60.472854
            ],
            [
              -159.366206,
              60.472856
            ],
            [
              -159.366215,
              60.646179
            ],
            [
              -159.190657,
              60.646181
            ],
            [
              -159.190661,
              60.732841
            ],
            [
              -159.015104,
              60.732843
            ],
            [
              -159.01511,
              60.819503
            ],
            [
              -158.941919,
              60.819503
            ],
            [
              -158.941926,
              60.906162
            ],
            [
              -158.693867,
              60.906164
            ],
            [
              -157.877224,
              60.906172
            ],
            [
              -157.877208,
              60.819515
            ],
            [
              -157.69976,
              60.819515
            ],
            [
              -157.699775,
              60.906175
            ],
            [
              -157.671866,
              60.906176
            ],
            [
              -157.522325,
              60.906178
            ],
            [
              -157.522332,
              60.992834
            ],
            [
              -157.167428,
              60.992839
            ],
            [
              -157.16742,
              60.906183
            ],
            [
              -156.766376,
              60.906186
            ],
            [
              -156.000086,
              60.90619
            ],
            [
              -155.906894,
              60.906191
            ],
            [
              -154.948166,
              60.906201
            ],
            [
              -154.258103,
              60.906212
            ],
            [
              -153.440884,
              60.906236
            ],
            [
              -153.440877,
              61.166196
            ],
            [
              -153.485584,
              61.166193
            ],
            [
              -153.485575,
              61.426153
            ],
            [
              -152.999882,
              61.426172
            ],
            [
              -152.999877,
              61.816874
            ],
            [
              -152.999839,
              62.292621
            ],
            [
              -153.07012,
              62.292644
            ],
            [
              -153.070123,
              62.206015
            ],
            [
              -153.395009,
              62.20599
            ],
            [
              -153.395009,
              62.119347
            ],
            [
              -153.761871,
              62.119335
            ],
            [
              -153.761875,
              62.032689
            ],
            [
              -154.410473,
              62.032677
            ],
            [
              -155.062594,
              62.032666
            ],
            [
              -155.867952,
              62.032653
            ],
            [
              -156.680792,
              62.03264
            ],
            [
              -157.063852,
              62.032634
            ],
            [
              -157.063864,
              62.119271
            ],
            [
              -157.25653,
              62.11927
            ],
            [
              -157.633817,
              62.119268
            ],
            [
              -158.531361,
              62.119264
            ],
            [
              -158.531351,
              62.03262
            ],
            [
              -159.265035,
              62.032598
            ],
            [
              -159.265037,
              61.945957
            ],
            [
              -159.554467,
              61.945953
            ],
            [
              -160.534671,
              61.94594
            ],
            [
              -160.528204,
              61.92644
            ],
            [
              -160.502057,
              61.892448
            ],
            [
              -160.469477,
              61.869348
            ],
            [
              -160.437948,
              61.858332
            ],
            [
              -160.397691,
              61.851223
            ],
            [
              -160.321954,
              61.846512
            ],
            [
              -160.287193,
              61.848961
            ],
            [
              -160.252781,
              61.857789
            ],
            [
              -160.247325,
              61.841293
            ],
            [
              -160.187745,
              61.835212
            ],
            [
              -160.187597,
              61.827968
            ],
            [
              -160.20263,
              61.810322
            ],
            [
              -160.265343,
              61.817615
            ],
            [
              -160.35014,
              61.819071
            ],
            [
              -160.40398,
              61.812525
            ],
            [
              -160.460921,
              61.800225
            ],
            [
              -160.597733,
              61.760156
            ],
            [
              -160.683572,
              61.75157
            ],
            [
              -160.775244,
              61.738527
            ],
            [
              -160.869268,
              61.71369
            ],
            [
              -160.953961,
              61.685669
            ],
            [
              -161.027337,
              61.653532
            ],
            [
              -161.084222,
              61.615455
            ],
            [
              -161.126812,
              61.59954
            ],
            [
              -161.275493,
              61.588041
            ],
            [
              -161.326637,
              61.580435
            ],
            [
              -161.449611,
              61.568838
            ],
            [
              -161.537348,
              61.54393
            ],
            [
              -161.599227,
              61.533271
            ],
            [
              -161.663063,
              61.53363
            ],
            [
              -161.697058,
              61.530087
            ],
            [
              -161.738446,
              61.51807
            ],
            [
              -161.900328,
              61.480317
            ],
            [
              -161.959929,
              61.481012
            ],
            [
              -162.013438,
              61.477057
            ],
            [
              -162.05567,
              61.481588
            ],
            [
              -162.126481,
              61.497289
            ],
            [
              -162.221874,
              61.509856
            ],
            [
              -162.2664,
              61.510158
            ],
            [
              -162.328294,
              61.518376
            ],
            [
              -162.368732,
              61.503876
            ],
            [
              -162.398638,
              61.49916
            ],
            [
              -162.476189,
              61.503959
            ],
            [
              -162.468381,
              61.543334
            ],
            [
              -162.505761,
              61.556426
            ],
            [
              -162.717538,
              61.536897
            ],
            [
              -162.75398,
              61.542666
            ],
            [
              -162.847199,
              61.498736
            ],
            [
              -162.863646,
              61.493914
            ],
            [
              -162.914965,
              61.485735
            ],
            [
              -162.9602,
              61.482486
            ],
            [
              -163.061999,
              61.479931
            ],
            [
              -163.100421,
              61.474374
            ],
            [
              -163.137346,
              61.457491
            ],
            [
              -163.171354,
              61.449071
            ],
            [
              -163.295078,
              61.455794
            ],
            [
              -163.359261,
              61.474442
            ],
            [
              -163.397082,
              61.463256
            ],
            [
              -163.40774,
              61.483862
            ],
            [
              -163.431258,
              61.484107
            ],
            [
              -163.428866,
              61.46228
            ],
            [
              -163.447765,
              61.446783
            ],
            [
              -163.48634,
              61.433022
            ],
            [
              -163.464435,
              61.422478
            ],
            [
              -163.468274,
              61.409152
            ],
            [
              -163.531412,
              61.401454
            ],
            [
              -163.542182,
              61.381754
            ],
            [
              -163.520838,
              61.364481
            ],
            [
              -163.524124,
              61.355098
            ],
            [
              -163.606616,
              61.336979
            ],
            [
              -163.65539,
              61.335827
            ],
            [
              -163.690351,
              61.349937
            ],
            [
              -163.707616,
              61.349682
            ],
            [
              -163.785076,
              61.367097
            ],
            [
              -163.822125,
              61.355913
            ],
            [
              -163.876262,
              61.368976
            ],
            [
              -163.933598,
              61.36527
            ],
            [
              -163.99276,
              61.371763
            ],
            [
              -163.992933,
              61.350702
            ],
            [
              -164.008631,
              61.323182
            ],
            [
              -163.977044,
              61.303691
            ],
            [
              -163.990582,
              61.284427
            ],
            [
              -163.979172,
              61.261815
            ],
            [
              -163.981767,
              61.243286
            ],
            [
              -163.947621,
              61.255281
            ],
            [
              -163.923782,
              61.248145
            ],
            [
              -163.913489,
              61.222403
            ],
            [
              -163.920874,
              61.210692
            ],
            [
              -163.998269,
              61.192027
            ],
            [
              -164.017645,
              61.192886
            ],
            [
              -164.080069,
              61.160043
            ],
            [
              -164.077227,
              61.143477
            ],
            [
              -164.121482,
              61.127
            ],
            [
              -164.130782,
              61.141209
            ],
            [
              -164.15671,
              61.152982
            ],
            [
              -164.201874,
              61.151613
            ],
            [
              -164.228464,
              61.121035
            ],
            [
              -164.199462,
              61.109761
            ],
            [
              -164.211781,
              61.082262
            ],
            [
              -164.189996,
              61.071539
            ],
            [
              -164.146306,
              61.074682
            ],
            [
              -164.154702,
              61.022625
            ],
            [
              -164.219665,
              61.00691
            ],
            [
              -164.239727,
              60.987119
            ],
            [
              -164.285663,
              60.985916
            ],
            [
              -164.301782,
              60.974832
            ],
            [
              -164.33936,
              60.978432
            ],
            [
              -164.390066,
              60.972349
            ],
            [
              -164.411625,
              60.950414
            ],
            [
              -164.450897,
              60.95742
            ],
            [
              -164.529911,
              60.940796
            ],
            [
              -164.576278,
              60.94579
            ],
            [
              -164.60332,
              60.943566
            ],
            [
              -164.615567,
              60.951888
            ],
            [
              -164.616585,
              60.979049
            ],
            [
              -164.675881,
              60.976888
            ],
            [
              -164.675958,
              60.969455
            ],
            [
              -164.70231,
              60.982076
            ],
            [
              -164.727016,
              60.984122
            ],
            [
              -164.730051,
              60.97068
            ],
            [
              -164.755295,
              60.978727
            ],
            [
              -164.768849,
              60.974739
            ],
            [
              -164.797619,
              60.982487
            ],
            [
              -164.852984,
              60.969946
            ],
            [
              -164.885929,
              60.967097
            ],
            [
              -164.938278,
              60.953377
            ],
            [
              -164.991784,
              60.948201
            ],
            [
              -165.000514,
              60.963635
            ],
            [
              -165.12647326398502,
              60.9213681097544
            ],
            [
              -165.03204,
              60.903986
            ],
            [
              -165.007096,
              60.922058
            ],
            [
              -164.975305,
              60.933122
            ],
            [
              -164.964862,
              60.945384
            ],
            [
              -164.940065,
              60.945369
            ],
            [
              -164.921256,
              60.946509
            ],
            [
              -164.903903,
              60.942213
            ],
            [
              -164.917542,
              60.928144
            ],
            [
              -164.925994,
              60.925063
            ],
            [
              -164.939496,
              60.924774
            ],
            [
              -164.945958,
              60.92106
            ],
            [
              -165.003679,
              60.87558
            ],
            [
              -165.030183,
              60.83805
            ],
            [
              -165.02962,
              60.826001
            ],
            [
              -165.02143,
              60.815086
            ],
            [
              -165.009703,
              60.81506
            ],
            [
              -164.939313,
              60.823463
            ],
            [
              -164.92418,
              60.809331
            ],
            [
              -164.944914,
              60.800379
            ],
            [
              -164.977663,
              60.79036
            ],
            [
              -165.020309,
              60.785539
            ],
            [
              -165.032615,
              60.786704
            ],
            [
              -165.037889,
              60.78901
            ],
            [
              -165.042584,
              60.78443
            ],
            [
              -165.040843,
              60.77266
            ],
            [
              -165.032074,
              60.760022
            ],
            [
              -165.023904,
              60.753128
            ],
            [
              -165.010452,
              60.744789
            ],
            [
              -164.971839,
              60.72973
            ],
            [
              -164.96541,
              60.724306
            ],
            [
              -164.966591,
              60.717438
            ],
            [
              -164.97125,
              60.711434
            ],
            [
              -164.991665,
              60.69884
            ],
            [
              -165.027535,
              60.686008
            ],
            [
              -165.0433,
              60.687468
            ],
            [
              -165.052642,
              60.690068
            ],
            [
              -165.063148,
              60.688645
            ],
            [
              -165.147184,
              60.65116
            ],
            [
              -165.170458,
              60.629091
            ],
            [
              -165.178617,
              60.623927
            ],
            [
              -165.268717,
              60.579488
            ],
            [
              -165.289651,
              60.575755
            ],
            [
              -165.312937,
              60.576313
            ],
            [
              -165.346721,
              60.580603
            ],
            [
              -165.367676,
              60.581158
            ],
            [
              -165.381052,
              60.577987
            ],
            [
              -165.415193,
              60.55816
            ],
            [
              -165.419788,
              60.552418
            ]
          ]
        ],
        [
          [
            [
              -167.442859,
              60.209348
            ],
            [
              -167.455694,
              60.207577
            ],
            [
              -167.430619,
              60.197006
            ],
            [
              -167.401741,
              60.176445
            ],
            [
              -167.366564,
              60.146288
            ],
            [
              -167.343509,
              60.126287
            ],
            [
              -167.33626,
              60.101772
            ],
            [
              -167.332932,
              60.076587
            ],
            [
              -167.333289,
              60.066626
            ],
            [
              -167.300727,
              60.060755
            ],
            [
              -167.266501,
              60.060339
            ],
            [
              -167.244159,
              60.057908
            ],
            [
              -167.225739,
              60.04088
            ],
            [
              -167.170835,
              60.012797
            ],
            [
              -167.128766,
              59.995864
            ],
            [
              -167.101076,
              59.988078
            ],
            [
              -167.06578,
              59.98796
            ],
            [
              -167.024899,
              59.984868
            ],
            [
              -166.987821,
              59.979042
            ],
            [
              -166.939572,
              59.965483
            ],
            [
              -166.861138,
              59.94317
            ],
            [
              -166.772246,
              59.90273
            ],
            [
              -166.762976,
              59.893312
            ],
            [
              -166.715322,
              59.887887
            ],
            [
              -166.665528,
              59.878168
            ],
            [
              -166.643305,
              59.86051
            ],
            [
              -166.613951,
              59.848335
            ],
            [
              -166.578299,
              59.846186
            ],
            [
              -166.533853,
              59.848335
            ],
            [
              -166.512819,
              59.846425
            ],
            [
              -166.487981,
              59.844335
            ],
            [
              -166.443773,
              59.853229
            ],
            [
              -166.408002,
              59.851081
            ],
            [
              -166.36213,
              59.838604
            ],
            [
              -166.343115,
              59.826122
            ],
            [
              -166.316852,
              59.824987
            ],
            [
              -166.272168,
              59.811455
            ],
            [
              -166.208707,
              59.774623
            ],
            [
              -166.206806,
              59.759424
            ],
            [
              -166.190406,
              59.750206
            ],
            [
              -166.154041,
              59.74829
            ],
            [
              -166.11209,
              59.754037
            ],
            [
              -166.097354,
              59.75703
            ],
            [
              -166.084282,
              59.776059
            ],
            [
              -166.097354,
              59.784074
            ],
            [
              -166.11209,
              59.800935
            ],
            [
              -166.125163,
              59.812291
            ],
            [
              -166.109951,
              59.827585
            ],
            [
              -166.084995,
              59.839649
            ],
            [
              -166.02605,
              59.861494
            ],
            [
              -165.982317,
              59.871637
            ],
            [
              -165.930265,
              59.877243
            ],
            [
              -165.90008,
              59.867699
            ],
            [
              -165.861101,
              59.861017
            ],
            [
              -165.856822,
              59.869728
            ],
            [
              -165.838996,
              59.890481
            ],
            [
              -165.799304,
              59.895488
            ],
            [
              -165.769832,
              59.900495
            ],
            [
              -165.727049,
              59.899064
            ],
            [
              -165.706609,
              59.883564
            ],
            [
              -165.677137,
              59.899064
            ],
            [
              -165.626511,
              59.90121
            ],
            [
              -165.582302,
              59.908242
            ],
            [
              -165.566378,
              59.928018
            ],
            [
              -165.58254,
              59.934568
            ],
            [
              -165.591097,
              59.949806
            ],
            [
              -165.569468,
              59.957184
            ],
            [
              -165.557584,
              59.968487
            ],
            [
              -165.551879,
              59.978715
            ],
            [
              -165.599415,
              59.972531
            ],
            [
              -165.626511,
              59.969795
            ],
            [
              -165.652656,
              59.987157
            ],
            [
              -165.645287,
              59.999519
            ],
            [
              -165.633879,
              60.019597
            ],
            [
              -165.673571,
              60.029928
            ],
            [
              -165.680702,
              60.053904
            ],
            [
              -165.709223,
              60.066003
            ],
            [
              -165.700429,
              60.077387
            ],
            [
              -165.685455,
              60.084026
            ],
            [
              -165.662638,
              60.098484
            ],
            [
              -165.666679,
              60.124304
            ],
            [
              -165.675711,
              60.143242
            ],
            [
              -165.678087,
              60.154717
            ],
            [
              -165.705183,
              60.152825
            ],
            [
              -165.723484,
              60.163824
            ],
            [
              -165.711125,
              60.181202
            ],
            [
              -165.683554,
              60.198335
            ],
            [
              -165.696389,
              60.225847
            ],
            [
              -165.722533,
              60.236468
            ],
            [
              -165.71778,
              60.250122
            ],
            [
              -165.694131,
              60.253011
            ],
            [
              -165.678563,
              60.270696
            ],
            [
              -165.67987,
              60.292435
            ],
            [
              -165.719206,
              60.309981
            ],
            [
              -165.767098,
              60.312188
            ],
            [
              -165.776843,
              60.326899
            ],
            [
              -165.82937,
              60.3309
            ],
            [
              -165.883086,
              60.343368
            ],
            [
              -165.918738,
              60.33784
            ],
            [
              -165.954865,
              60.320898
            ],
            [
              -165.984932,
              60.314277
            ],
            [
              -166.03734,
              60.319456
            ],
            [
              -166.080954,
              60.322751
            ],
            [
              -166.069189,
              60.341163
            ],
            [
              -166.095215,
              60.351158
            ],
            [
              -166.103296,
              60.367321
            ],
            [
              -166.119221,
              60.386353
            ],
            [
              -166.127777,
              60.417404
            ],
            [
              -166.149763,
              60.4367
            ],
            [
              -166.167351,
              60.425558
            ],
            [
              -166.203716,
              60.390229
            ],
            [
              -166.241032,
              60.38882
            ],
            [
              -166.285121,
              60.382301
            ],
            [
              -166.331707,
              60.368261
            ],
            [
              -166.371874,
              60.355156
            ],
            [
              -166.401347,
              60.361503
            ],
            [
              -166.438662,
              60.376251
            ],
            [
              -166.490358,
              60.389466
            ],
            [
              -166.514839,
              60.37302
            ],
            [
              -166.548589,
              60.361856
            ],
            [
              -166.579844,
              60.349747
            ],
            [
              -166.572238,
              60.328105
            ],
            [
              -166.582578,
              60.317809
            ],
            [
              -166.616566,
              60.319221
            ],
            [
              -166.664577,
              60.32687
            ],
            [
              -166.713539,
              60.327341
            ],
            [
              -166.737664,
              60.312159
            ],
            [
              -166.770939,
              60.301209
            ],
            [
              -166.81277,
              60.283718
            ],
            [
              -166.835825,
              60.268751
            ],
            [
              -166.813127,
              60.249768
            ],
            [
              -166.805878,
              60.236084
            ],
            [
              -166.819901,
              60.223575
            ],
            [
              -166.842599,
              60.21047
            ],
            [
              -166.87124,
              60.213304
            ],
            [
              -166.888947,
              60.21047
            ],
            [
              -166.9183,
              60.202321
            ],
            [
              -166.951219,
              60.222335
            ],
            [
              -166.999943,
              60.218086
            ],
            [
              -167.033694,
              60.213717
            ],
            [
              -167.080041,
              60.222099
            ],
            [
              -167.112841,
              60.231482
            ],
            [
              -167.160377,
              60.229299
            ],
            [
              -167.203278,
              60.23337
            ],
            [
              -167.238336,
              60.228768
            ],
            [
              -167.273156,
              60.230066
            ],
            [
              -167.317602,
              60.231541
            ],
            [
              -167.343153,
              60.224165
            ],
            [
              -167.37215,
              60.215783
            ],
            [
              -167.396691,
              60.210083
            ],
            [
              -167.442859,
              60.209348
            ]
          ]
        ],
        [
          [
            [
              -173.0638,
              60.502589
            ],
            [
              -173.055719,
              60.496152
            ],
            [
              -173.036467,
              60.494279
            ],
            [
              -172.999626,
              60.485967
            ],
            [
              -172.963737,
              60.479643
            ],
            [
              -172.932363,
              60.476364
            ],
            [
              -172.926896,
              60.468867
            ],
            [
              -172.907882,
              60.45422
            ],
            [
              -172.895998,
              60.450587
            ],
            [
              -172.820178,
              60.414816
            ],
            [
              -172.75957,
              60.384643
            ],
            [
              -172.722967,
              60.359028
            ],
            [
              -172.70324,
              60.356089
            ],
            [
              -172.630272,
              60.334922
            ],
            [
              -172.605553,
              60.319508
            ],
            [
              -172.577507,
              60.320097
            ],
            [
              -172.509531,
              60.334451
            ],
            [
              -172.445833,
              60.335392
            ],
            [
              -172.385937,
              60.335275
            ],
            [
              -172.326042,
              60.327628
            ],
            [
              -172.308929,
              60.311975
            ],
            [
              -172.293955,
              60.295373
            ],
            [
              -172.254263,
              60.297375
            ],
            [
              -172.23406,
              60.307619
            ],
            [
              -172.218848,
              60.31174
            ],
            [
              -172.245944,
              60.32045
            ],
            [
              -172.284923,
              60.334334
            ],
            [
              -172.33959,
              60.356207
            ],
            [
              -172.375955,
              60.374306
            ],
            [
              -172.380946,
              60.382764
            ],
            [
              -172.424441,
              60.392629
            ],
            [
              -172.447259,
              60.382999
            ],
            [
              -172.513571,
              60.38112
            ],
            [
              -172.591768,
              60.393451
            ],
            [
              -172.636452,
              60.407891
            ],
            [
              -172.709182,
              60.429598
            ],
            [
              -172.764799,
              60.447421
            ],
            [
              -172.817326,
              60.476598
            ],
            [
              -172.842758,
              60.483976
            ],
            [
              -172.884827,
              60.507972
            ],
            [
              -172.903366,
              60.531717
            ],
            [
              -172.926896,
              60.554509
            ],
            [
              -172.927134,
              60.569228
            ],
            [
              -172.915012,
              60.583356
            ],
            [
              -172.912636,
              60.604129
            ],
            [
              -172.95114,
              60.606229
            ],
            [
              -172.993922,
              60.577519
            ],
            [
              -173.040745,
              60.561869
            ],
            [
              -173.047875,
              60.541303
            ],
            [
              -173.048826,
              60.524934
            ],
            [
              -173.045023,
              60.514056
            ],
            [
              -173.0638,
              60.502589
            ]
          ]
        ],
        [
          [
            [
              -173.090823,
              60.698946
            ],
            [
              -173.115569,
              60.658971
            ],
            [
              -173.045137,
              60.622804
            ],
            [
              -173.04133,
              60.630418
            ],
            [
              -173.059414,
              60.655164
            ],
            [
              -173.063221,
              60.695139
            ],
            [
              -173.074642,
              60.704657
            ],
            [
              -173.090823,
              60.698946
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "02",
      "COUNTYFP": "090",
      "COUNTYNS": "01419969",
      "AFFGEOID": "0500000US02090",
      "GEOID": "02090",
      "NAME": "Fairbanks North Star",
      "LSAD": "04",
      "ALAND": 18982307701,
      "AWATER": 274103901,
      "County_state": "Fairbanks North Star,02"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -148.648208,
              64.597334
            ],
            [
              -148.663265,
              64.59079
            ],
            [
              -148.648207,
              64.585371
            ],
            [
              -148.59596,
              64.616268
            ],
            [
              -148.577778,
              64.610324
            ],
            [
              -148.550742,
              64.617208
            ],
            [
              -148.531461,
              64.607895
            ],
            [
              -148.457524,
              64.589764
            ],
            [
              -148.424918,
              64.574854
            ],
            [
              -148.444636,
              64.566311
            ],
            [
              -148.420106,
              64.561438
            ],
            [
              -148.403117,
              64.531297
            ],
            [
              -148.350771,
              64.522018
            ],
            [
              -148.326961,
              64.512186
            ],
            [
              -148.298082,
              64.487396
            ],
            [
              -148.270857,
              64.491769
            ],
            [
              -148.231693,
              64.46929
            ],
            [
              -148.21095,
              64.448838
            ],
            [
              -148.223706,
              64.435332
            ],
            [
              -148.175752,
              64.4101
            ],
            [
              -148.176111,
              64.400924
            ],
            [
              -148.104557,
              64.377612
            ],
            [
              -148.108554,
              64.36221
            ],
            [
              -148.06978,
              64.355165
            ],
            [
              -148.046696,
              64.343802
            ],
            [
              -148.00322,
              64.346628
            ],
            [
              -147.958842,
              64.324528
            ],
            [
              -147.93108,
              64.322026
            ],
            [
              -147.925255,
              64.312313
            ],
            [
              -147.894299,
              64.306324
            ],
            [
              -147.868424,
              64.287627
            ],
            [
              -147.820465,
              64.281204
            ],
            [
              -147.779479,
              64.259029
            ],
            [
              -147.7592,
              64.2572
            ],
            [
              -146.999909,
              64.257196
            ],
            [
              -146.680649,
              64.257212
            ],
            [
              -146.630511,
              64.259435
            ],
            [
              -146.57156,
              64.26761
            ],
            [
              -146.519578,
              64.277496
            ],
            [
              -146.473115,
              64.281895
            ],
            [
              -146.441381,
              64.281766
            ],
            [
              -146.376622,
              64.278692
            ],
            [
              -146.337948,
              64.281963
            ],
            [
              -146.303899,
              64.298346
            ],
            [
              -146.248551,
              64.309807
            ],
            [
              -146.22477,
              64.344925
            ],
            [
              -146.220345,
              64.366037
            ],
            [
              -146.190543,
              64.374455
            ],
            [
              -146.14156,
              64.38096
            ],
            [
              -146.099314,
              64.386967
            ],
            [
              -146.054251,
              64.379114
            ],
            [
              -146.00156,
              64.383896
            ],
            [
              -145.965639,
              64.401337
            ],
            [
              -145.929507,
              64.418858
            ],
            [
              -145.87887,
              64.429783
            ],
            [
              -145.826873,
              64.430815
            ],
            [
              -145.758666,
              64.448703
            ],
            [
              -145.718433,
              64.454605
            ],
            [
              -145.671755,
              64.444478
            ],
            [
              -145.623851,
              64.435419
            ],
            [
              -145.596686,
              64.424083
            ],
            [
              -145.532389,
              64.418156
            ],
            [
              -145.441347,
              64.445111
            ],
            [
              -145.37118,
              64.46073
            ],
            [
              -145.360708,
              64.485334
            ],
            [
              -145.317202,
              64.491226
            ],
            [
              -145.270016,
              64.490923
            ],
            [
              -145.180253,
              64.505055
            ],
            [
              -145.118301,
              64.503817
            ],
            [
              -145.044561,
              64.529625
            ],
            [
              -144.990911,
              64.559256
            ],
            [
              -144.933809,
              64.577296
            ],
            [
              -144.847904,
              64.567208
            ],
            [
              -144.784204,
              64.58091
            ],
            [
              -144.719058,
              64.587479
            ],
            [
              -144.637094,
              64.5922
            ],
            [
              -144.621161,
              64.598242
            ],
            [
              -144.552526,
              64.600645
            ],
            [
              -144.546741,
              64.590624
            ],
            [
              -144.511701,
              64.592423
            ],
            [
              -144.460096,
              64.58326
            ],
            [
              -144.319405,
              64.617763
            ],
            [
              -144.31598,
              64.645136
            ],
            [
              -144.295859,
              64.642771
            ],
            [
              -144.232909,
              64.649303
            ],
            [
              -144.190117,
              64.641074
            ],
            [
              -144.147605,
              64.656144
            ],
            [
              -144.129576,
              64.649461
            ],
            [
              -144.104744,
              64.656602
            ],
            [
              -144.072511,
              64.654082
            ],
            [
              -144.05584,
              64.667747
            ],
            [
              -144.069029,
              64.683562
            ],
            [
              -144.070019,
              64.692704
            ],
            [
              -144.109243,
              64.72679
            ],
            [
              -144.079075,
              64.751625
            ],
            [
              -144.030893,
              64.774574
            ],
            [
              -144.091157,
              64.789405
            ],
            [
              -144.117126,
              64.801708
            ],
            [
              -144.091942,
              64.854013
            ],
            [
              -144.072443,
              64.872097
            ],
            [
              -144.073942,
              64.908887
            ],
            [
              -144.02019,
              64.936596
            ],
            [
              -143.995593,
              64.942896
            ],
            [
              -143.992149,
              64.975613
            ],
            [
              -144.064582,
              64.99333
            ],
            [
              -144.095036,
              65.013496
            ],
            [
              -144.047297,
              65.025845
            ],
            [
              -143.975599,
              65.048278
            ],
            [
              -143.911985,
              65.070491
            ],
            [
              -143.895719,
              65.089693
            ],
            [
              -143.928823,
              65.102203
            ],
            [
              -143.990453,
              65.118904
            ],
            [
              -144.056718,
              65.118911
            ],
            [
              -144.20043,
              65.116109
            ],
            [
              -144.281869,
              65.114231
            ],
            [
              -144.329288,
              65.100226
            ],
            [
              -144.389061,
              65.079792
            ],
            [
              -144.467069,
              65.072195
            ],
            [
              -144.654914,
              65.097158
            ],
            [
              -144.766574,
              65.102782
            ],
            [
              -144.866475,
              65.130159
            ],
            [
              -144.915835,
              65.125786
            ],
            [
              -145.000788,
              65.128006
            ],
            [
              -145.083719,
              65.103158
            ],
            [
              -145.152321,
              65.097442
            ],
            [
              -145.243702,
              65.071417
            ],
            [
              -145.387799,
              65.083666
            ],
            [
              -145.431082,
              65.076249
            ],
            [
              -145.444624,
              65.066016
            ],
            [
              -145.506184,
              65.066877
            ],
            [
              -145.556029,
              65.047186
            ],
            [
              -145.645233,
              65.032868
            ],
            [
              -145.67477,
              65.068843
            ],
            [
              -145.767061,
              65.06989
            ],
            [
              -145.768353,
              65.084958
            ],
            [
              -145.698107,
              65.108837
            ],
            [
              -145.670756,
              65.129147
            ],
            [
              -145.633179,
              65.136616
            ],
            [
              -145.677089,
              65.183109
            ],
            [
              -145.716497,
              65.184223
            ],
            [
              -145.902076,
              65.206983
            ],
            [
              -145.996082,
              65.224436
            ],
            [
              -146.080243,
              65.25365
            ],
            [
              -146.114467,
              65.270929
            ],
            [
              -146.126521,
              65.314839
            ],
            [
              -146.087011,
              65.338356
            ],
            [
              -146.006109,
              65.373034
            ],
            [
              -145.976279,
              65.389745
            ],
            [
              -145.999526,
              65.408686
            ],
            [
              -146.027238,
              65.406897
            ],
            [
              -146.094401,
              65.403352
            ],
            [
              -146.16957,
              65.443987
            ],
            [
              -146.223606,
              65.444225
            ],
            [
              -146.430371,
              65.427818
            ],
            [
              -146.471774,
              65.430211
            ],
            [
              -146.524294,
              65.424614
            ],
            [
              -146.499338,
              65.39165
            ],
            [
              -146.493299,
              65.379843
            ],
            [
              -146.549263,
              65.344113
            ],
            [
              -146.637627,
              65.332789
            ],
            [
              -146.719396,
              65.310255
            ],
            [
              -146.869247,
              65.288529
            ],
            [
              -146.998776,
              65.278627
            ],
            [
              -147.191665,
              65.281419
            ],
            [
              -147.282818,
              65.278248
            ],
            [
              -147.381719,
              65.256716
            ],
            [
              -147.555319,
              65.208939
            ],
            [
              -147.64299,
              65.209892
            ],
            [
              -148.104648,
              65.209891
            ],
            [
              -148.661199,
              65.209875
            ],
            [
              -148.648241,
              64.863447
            ],
            [
              -148.64822,
              64.688143
            ],
            [
              -148.648208,
              64.597334
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "02",
      "COUNTYFP": "070",
      "COUNTYNS": "01419968",
      "AFFGEOID": "0500000US02070",
      "GEOID": "02070",
      "NAME": "Dillingham",
      "LSAD": "05",
      "ALAND": 47477685010,
      "AWATER": 5951019310,
      "County_state": "Dillingham,02"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -160.317363,
              58.694314
            ],
            [
              -160.311421,
              58.680974
            ],
            [
              -160.286753,
              58.657725
            ],
            [
              -160.275294,
              58.636345
            ],
            [
              -160.255329,
              58.646487
            ],
            [
              -160.248674,
              58.658481
            ],
            [
              -160.253903,
              58.674673
            ],
            [
              -160.265043,
              58.699243
            ],
            [
              -160.281236,
              58.718882
            ],
            [
              -160.30025,
              58.729494
            ],
            [
              -160.308331,
              58.717771
            ],
            [
              -160.317363,
              58.694314
            ]
          ]
        ],
        [
          [
            [
              -160.437154,
              58.684186
            ],
            [
              -160.430024,
              58.678256
            ],
            [
              -160.389143,
              58.700859
            ],
            [
              -160.381775,
              58.7116
            ],
            [
              -160.385815,
              58.729494
            ],
            [
              -160.406731,
              58.749968
            ],
            [
              -160.423131,
              58.730358
            ],
            [
              -160.425745,
              58.712958
            ],
            [
              -160.437154,
              58.684186
            ]
          ]
        ],
        [
          [
            [
              -161.031441,
              58.839752
            ],
            [
              -161.034136119879,
              58.838743396163494
            ],
            [
              -161.001101,
              58.849693
            ],
            [
              -160.872003,
              58.878472
            ],
            [
              -160.823489,
              58.829136
            ],
            [
              -160.753067,
              58.910431
            ],
            [
              -160.641785,
              58.964489
            ],
            [
              -160.476578,
              59.026047
            ],
            [
              -160.31778,
              59.070477
            ],
            [
              -160.256592,
              58.99448
            ],
            [
              -160.322922,
              58.953953
            ],
            [
              -160.286346,
              58.945007
            ],
            [
              -160.232788,
              58.901127
            ],
            [
              -160.15448,
              58.916874
            ],
            [
              -160.150528,
              58.866062
            ],
            [
              -160.093109,
              58.860798
            ],
            [
              -160.054047,
              58.887001
            ],
            [
              -159.985352,
              58.870464
            ],
            [
              -159.995667,
              58.848301
            ],
            [
              -159.908386,
              58.779903
            ],
            [
              -159.806305,
              58.805595
            ],
            [
              -159.792923,
              58.823971
            ],
            [
              -159.808777,
              58.861542
            ],
            [
              -159.753754,
              58.855724
            ],
            [
              -159.732932,
              58.930739
            ],
            [
              -159.723491,
              58.932312
            ],
            [
              -159.712114,
              58.929468
            ],
            [
              -159.691493,
              58.931601
            ],
            [
              -159.657362,
              58.938712
            ],
            [
              -159.64243,
              58.938712
            ],
            [
              -159.61612,
              58.931601
            ],
            [
              -159.60261,
              58.920935
            ],
            [
              -159.594788,
              58.912402
            ],
            [
              -159.586966,
              58.900314
            ],
            [
              -159.589811,
              58.890359
            ],
            [
              -159.601899,
              58.884671
            ],
            [
              -159.643549,
              58.845063
            ],
            [
              -159.580287,
              58.840691
            ],
            [
              -159.556355,
              58.837414
            ],
            [
              -159.532347,
              58.833609
            ],
            [
              -159.501768,
              58.824304
            ],
            [
              -159.450831,
              58.797736
            ],
            [
              -159.390664,
              58.762362
            ],
            [
              -159.357625,
              58.73452
            ],
            [
              -159.24229,
              58.619067
            ],
            [
              -159.187347,
              58.555609
            ],
            [
              -159.080496,
              58.444256
            ],
            [
              -159.063346,
              58.423139
            ],
            [
              -159.046105,
              58.417466
            ],
            [
              -158.944154,
              58.396885
            ],
            [
              -158.896067,
              58.390065
            ],
            [
              -158.880927,
              58.39067
            ],
            [
              -158.830598,
              58.397095
            ],
            [
              -158.795316,
              58.408032
            ],
            [
              -158.704052,
              58.482759
            ],
            [
              -158.721173,
              58.497971
            ],
            [
              -158.769131,
              58.54865
            ],
            [
              -158.827852,
              58.626432
            ],
            [
              -158.861207,
              58.69558
            ],
            [
              -158.848225,
              58.722736
            ],
            [
              -158.827105,
              58.724495
            ],
            [
              -158.812116,
              58.727845
            ],
            [
              -158.800959,
              58.732842
            ],
            [
              -158.784886,
              58.747739
            ],
            [
              -158.771246,
              58.765109
            ],
            [
              -158.7698,
              58.774141
            ],
            [
              -158.774626,
              58.778593
            ],
            [
              -158.782365,
              58.791157
            ],
            [
              -158.790378,
              58.804712
            ],
            [
              -158.790786,
              58.808424
            ],
            [
              -158.789632,
              58.814257
            ],
            [
              -158.767748,
              58.864264
            ],
            [
              -158.745305,
              58.874098
            ],
            [
              -158.729581,
              58.871218
            ],
            [
              -158.717436,
              58.872462
            ],
            [
              -158.619684,
              58.911048
            ],
            [
              -158.529845,
              58.997969
            ],
            [
              -158.487015,
              58.999872
            ],
            [
              -158.520327,
              58.857105
            ],
            [
              -158.559499,
              58.841819
            ],
            [
              -158.56587,
              58.815429
            ],
            [
              -158.566397,
              58.807137
            ],
            [
              -158.564833,
              58.802715
            ],
            [
              -158.550626,
              58.792915
            ],
            [
              -158.538516,
              58.788394
            ],
            [
              -158.512547,
              58.78311
            ],
            [
              -158.45521,
              58.776972
            ],
            [
              -158.423828,
              58.769847
            ],
            [
              -158.400475,
              58.761182
            ],
            [
              -158.376873,
              58.748043
            ],
            [
              -158.351481,
              58.727693
            ],
            [
              -158.343545,
              58.713634
            ],
            [
              -158.332394,
              58.686814
            ],
            [
              -158.330216,
              58.675043
            ],
            [
              -158.33286,
              58.669274
            ],
            [
              -158.332093,
              58.665313
            ],
            [
              -158.327038,
              58.659835
            ],
            [
              -158.297189,
              58.643147
            ],
            [
              -158.273036,
              58.63347
            ],
            [
              -158.232276,
              58.619902
            ],
            [
              -158.213861,
              58.615828
            ],
            [
              -158.190283,
              58.61371
            ],
            [
              -158.140307,
              58.61502
            ],
            [
              -158.101646,
              58.62109
            ],
            [
              -158.036593,
              58.634248
            ],
            [
              -157.855396,
              58.678277
            ],
            [
              -157.799597,
              58.69559
            ],
            [
              -157.721786,
              58.723212
            ],
            [
              -157.696472,
              58.729975
            ],
            [
              -157.550603,
              58.754514
            ],
            [
              -157.542326,
              58.760962
            ],
            [
              -157.537543,
              58.768542
            ],
            [
              -157.532654,
              58.772638
            ],
            [
              -157.484062,
              58.785962
            ],
            [
              -157.429531,
              58.791071
            ],
            [
              -157.353132,
              58.817729
            ],
            [
              -157.275451,
              58.836136
            ],
            [
              -157.259663,
              58.835665
            ],
            [
              -157.241396,
              58.837558
            ],
            [
              -157.21571,
              58.841526
            ],
            [
              -157.19745563325102,
              58.8458516065572
            ],
            [
              -157.196306,
              59.086122
            ],
            [
              -157.275221,
              59.086121
            ],
            [
              -157.275233,
              59.259482
            ],
            [
              -157.106808,
              59.259483
            ],
            [
              -157.106814,
              59.346162
            ],
            [
              -156.769965,
              59.346166
            ],
            [
              -156.769971,
              59.432843
            ],
            [
              -156.675483,
              59.432844
            ],
            [
              -156.675489,
              59.519521
            ],
            [
              -156.505345,
              59.519523
            ],
            [
              -156.505349,
              59.606199
            ],
            [
              -156.165066,
              59.606202
            ],
            [
              -156.165068,
              59.692877
            ],
            [
              -156.000145,
              59.692879
            ],
            [
              -156.000086,
              60.90619
            ],
            [
              -156.766376,
              60.906186
            ],
            [
              -157.16742,
              60.906183
            ],
            [
              -157.167428,
              60.992839
            ],
            [
              -157.522332,
              60.992834
            ],
            [
              -157.522325,
              60.906178
            ],
            [
              -157.671866,
              60.906176
            ],
            [
              -157.699775,
              60.906175
            ],
            [
              -157.69976,
              60.819515
            ],
            [
              -157.877208,
              60.819515
            ],
            [
              -157.877224,
              60.906172
            ],
            [
              -158.693867,
              60.906164
            ],
            [
              -158.941926,
              60.906162
            ],
            [
              -158.941919,
              60.819503
            ],
            [
              -159.01511,
              60.819503
            ],
            [
              -159.015104,
              60.732843
            ],
            [
              -159.190661,
              60.732841
            ],
            [
              -159.190657,
              60.646181
            ],
            [
              -159.366215,
              60.646179
            ],
            [
              -159.366206,
              60.472856
            ],
            [
              -159.434502,
              60.472854
            ],
            [
              -159.434497,
              60.38619
            ],
            [
              -159.608204,
              60.386188
            ],
            [
              -159.608196,
              60.212856
            ],
            [
              -159.781906,
              60.212854
            ],
            [
              -159.781902,
              60.126187
            ],
            [
              -159.845533,
              60.126186
            ],
            [
              -159.845524,
              59.952848
            ],
            [
              -160.01743,
              59.952846
            ],
            [
              -160.017423,
              59.779504
            ],
            [
              -160.24851,
              59.779501
            ],
            [
              -160.248503,
              59.606154
            ],
            [
              -160.418647,
              59.606152
            ],
            [
              -160.41864,
              59.4328
            ],
            [
              -160.475354,
              59.432799
            ],
            [
              -160.475296,
              59.346122
            ],
            [
              -160.643719,
              59.34612
            ],
            [
              -160.643716,
              59.25944
            ],
            [
              -160.81214,
              59.259438
            ],
            [
              -160.812133,
              59.086077
            ],
            [
              -160.864705,
              59.086077
            ],
            [
              -160.864703,
              58.999394
            ],
            [
              -161.031448,
              58.999393
            ],
            [
              -161.031441,
              58.839752
            ]
          ]
        ],
        [
          [
            [
              -161.084196,
              58.589891
            ],
            [
              -161.081341,
              58.555627
            ],
            [
              -161.07563,
              58.549916
            ],
            [
              -160.961416,
              58.553723
            ],
            [
              -160.880515,
              58.581325
            ],
            [
              -160.684447,
              58.815464
            ],
            [
              -160.700627,
              58.817368
            ],
            [
              -160.843395,
              58.751694
            ],
            [
              -160.918586,
              58.746935
            ],
            [
              -161.056595,
              58.702202
            ],
            [
              -161.078486,
              58.635577
            ],
            [
              -161.084196,
              58.589891
            ]
          ]
        ],
        [
          [
            [
              -161.364931,
              58.826021
            ],
            [
              -161.365049,
              58.739364
            ],
            [
              -161.341530442937,
              58.7393405676405
            ],
            [
              -161.337982,
              58.742912
            ],
            [
              -161.18338,
              58.789276
            ],
            [
              -161.07251163437803,
              58.826023700205496
            ],
            [
              -161.364931,
              58.826021
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "02",
      "COUNTYFP": "130",
      "COUNTYNS": "01419973",
      "AFFGEOID": "0500000US02130",
      "GEOID": "02130",
      "NAME": "Ketchikan Gateway",
      "LSAD": "04",
      "ALAND": 12579507052,
      "AWATER": 5220218255,
      "County_state": "Ketchikan Gateway,02"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -131.241191,
              55.092547
            ],
            [
              -131.246545,
              55.072916
            ],
            [
              -131.240001,
              55.061614
            ],
            [
              -131.213232,
              55.044958
            ],
            [
              -131.190033,
              55.043173
            ],
            [
              -131.187653,
              55.047337
            ],
            [
              -131.190033,
              55.066968
            ],
            [
              -131.175756,
              55.081839
            ],
            [
              -131.174566,
              55.087788
            ],
            [
              -131.190628,
              55.108013
            ],
            [
              -131.207284,
              55.112178
            ],
            [
              -131.241191,
              55.092547
            ]
          ]
        ],
        [
          [
            [
              -131.486616,
              54.950394
            ],
            [
              -131.491504,
              54.930392
            ],
            [
              -131.469097,
              54.913153
            ],
            [
              -131.353233,
              54.859009
            ],
            [
              -131.266049,
              54.859369
            ],
            [
              -131.253671,
              54.866779
            ],
            [
              -131.200161,
              54.910169
            ],
            [
              -131.195411,
              54.918249
            ],
            [
              -131.195197,
              54.919767
            ],
            [
              -131.197924,
              54.921489
            ],
            [
              -131.217805,
              54.927423
            ],
            [
              -131.233001,
              54.926814
            ],
            [
              -131.242771,
              54.929639
            ],
            [
              -131.257421,
              54.97901
            ],
            [
              -131.246018,
              54.989555
            ],
            [
              -131.248909,
              54.99719
            ],
            [
              -131.266084,
              54.998806
            ],
            [
              -131.409738,
              54.971152
            ],
            [
              -131.482676,
              54.952659
            ],
            [
              -131.486616,
              54.950394
            ]
          ]
        ],
        [
          [
            [
              -131.621948,
              54.946531
            ],
            [
              -131.594567,
              54.93113
            ],
            [
              -131.567377,
              54.938777
            ],
            [
              -131.551215,
              54.949589
            ],
            [
              -131.520411,
              54.957996
            ],
            [
              -131.500066,
              54.980917
            ],
            [
              -131.504439,
              54.995099
            ],
            [
              -131.529158,
              55.002188
            ],
            [
              -131.58487,
              54.970659
            ],
            [
              -131.621948,
              54.946531
            ]
          ]
        ],
        [
          [
            [
              -131.870568,
              55.364553
            ],
            [
              -131.862162,
              55.289284
            ],
            [
              -131.850839,
              55.274364
            ],
            [
              -131.828395,
              55.198482
            ],
            [
              -131.830718,
              55.194991
            ],
            [
              -131.829585,
              55.191916
            ],
            [
              -131.798943,
              55.162351
            ],
            [
              -131.748334,
              55.128588
            ],
            [
              -131.718468,
              55.200099
            ],
            [
              -131.694487,
              55.223739
            ],
            [
              -131.688644,
              55.282113
            ],
            [
              -131.65813,
              55.292512
            ],
            [
              -131.647236,
              55.30614
            ],
            [
              -131.666738,
              55.327492
            ],
            [
              -131.685192,
              55.333501
            ],
            [
              -131.71614,
              55.34957
            ],
            [
              -131.759896,
              55.381845
            ],
            [
              -131.798555,
              55.399386
            ],
            [
              -131.80679,
              55.405175
            ],
            [
              -131.809721,
              55.412555
            ],
            [
              -131.811697,
              55.414048
            ],
            [
              -131.833218,
              55.422014
            ],
            [
              -131.85233,
              55.423782
            ],
            [
              -131.854297,
              55.421074
            ],
            [
              -131.870568,
              55.364553
            ]
          ]
        ],
        [
          [
            [
              -132.2015,
              55.626376
            ],
            [
              -132.198652,
              55.615721
            ],
            [
              -132.183207,
              55.588128
            ],
            [
              -132.148383,
              55.562481
            ],
            [
              -132.141118,
              55.55901
            ],
            [
              -132.114654,
              55.550623
            ],
            [
              -132.098521,
              55.550015
            ],
            [
              -132.060504,
              55.54303
            ],
            [
              -132.043772,
              55.535742
            ],
            [
              -132.014613,
              55.515238
            ],
            [
              -131.986493,
              55.500619
            ],
            [
              -131.971792,
              55.498279
            ],
            [
              -131.936689,
              55.535151
            ],
            [
              -131.945303,
              55.572441
            ],
            [
              -131.962642,
              55.608708
            ],
            [
              -131.963121,
              55.615263
            ],
            [
              -131.939318,
              55.623844
            ],
            [
              -131.897413,
              55.603914
            ],
            [
              -131.865395,
              55.63068
            ],
            [
              -131.828887,
              55.667148
            ],
            [
              -131.831407,
              55.681342
            ],
            [
              -131.82616,
              55.71858
            ],
            [
              -131.814759,
              55.73135
            ],
            [
              -131.779908,
              55.791904
            ],
            [
              -131.771248,
              55.810028
            ],
            [
              -131.777033,
              55.823261
            ],
            [
              -131.81631,
              55.837449
            ],
            [
              -131.830547,
              55.856616
            ],
            [
              -131.836962,
              55.875472
            ],
            [
              -131.828176,
              55.877284
            ],
            [
              -131.776737,
              55.878784
            ],
            [
              -131.720384,
              55.894659
            ],
            [
              -131.689509,
              55.890232
            ],
            [
              -131.687317,
              55.886772
            ],
            [
              -131.689044,
              55.875488
            ],
            [
              -131.701487,
              55.860943
            ],
            [
              -131.713742,
              55.853263
            ],
            [
              -131.710448,
              55.80662
            ],
            [
              -131.705259,
              55.789939
            ],
            [
              -131.700951,
              55.788977
            ],
            [
              -131.697211,
              55.793768
            ],
            [
              -131.691058,
              55.797561
            ],
            [
              -131.678213,
              55.799837
            ],
            [
              -131.653124,
              55.795735
            ],
            [
              -131.640141,
              55.789355
            ],
            [
              -131.640294,
              55.785274
            ],
            [
              -131.649626,
              55.768728
            ],
            [
              -131.704907,
              55.755541
            ],
            [
              -131.719308,
              55.749099
            ],
            [
              -131.733408,
              55.730832
            ],
            [
              -131.726467,
              55.720826
            ],
            [
              -131.706744,
              55.706435
            ],
            [
              -131.701147,
              55.69696
            ],
            [
              -131.712102,
              55.665797
            ],
            [
              -131.719546,
              55.650282
            ],
            [
              -131.726615,
              55.641
            ],
            [
              -131.726322,
              55.63593
            ],
            [
              -131.724359,
              55.632559
            ],
            [
              -131.701091,
              55.613684
            ],
            [
              -131.682849,
              55.610488
            ],
            [
              -131.671471,
              55.606573
            ],
            [
              -131.654172,
              55.592431
            ],
            [
              -131.664629,
              55.581525
            ],
            [
              -131.733052,
              55.548559
            ],
            [
              -131.766373,
              55.54005
            ],
            [
              -131.793717,
              55.541682
            ],
            [
              -131.841683,
              55.526748
            ],
            [
              -131.845542,
              55.522119
            ],
            [
              -131.844157,
              55.456742
            ],
            [
              -131.828446,
              55.445214
            ],
            [
              -131.741834,
              55.398074
            ],
            [
              -131.736654,
              55.392206
            ],
            [
              -131.735939,
              55.381905
            ],
            [
              -131.732441,
              55.377553
            ],
            [
              -131.698743,
              55.354873
            ],
            [
              -131.639031,
              55.339481
            ],
            [
              -131.584842,
              55.309588
            ],
            [
              -131.566677,
              55.306068
            ],
            [
              -131.550044,
              55.293389
            ],
            [
              -131.53651,
              55.292352
            ],
            [
              -131.528201,
              55.295349
            ],
            [
              -131.515257,
              55.327938
            ],
            [
              -131.509811,
              55.33231
            ],
            [
              -131.494146,
              55.33231
            ],
            [
              -131.471976,
              55.323386
            ],
            [
              -131.462968,
              55.312648
            ],
            [
              -131.475602,
              55.303263
            ],
            [
              -131.463532,
              55.283389
            ],
            [
              -131.444799,
              55.264491
            ],
            [
              -131.437857,
              55.248407
            ],
            [
              -131.428234,
              55.239416
            ],
            [
              -131.424502,
              55.238764
            ],
            [
              -131.402931,
              55.238065
            ],
            [
              -131.326989,
              55.265911
            ],
            [
              -131.291203,
              55.281751
            ],
            [
              -131.284986,
              55.286437
            ],
            [
              -131.255107,
              55.322104
            ],
            [
              -131.254461,
              55.329698
            ],
            [
              -131.264608,
              55.345639
            ],
            [
              -131.287016,
              55.35826
            ],
            [
              -131.293043,
              55.378684
            ],
            [
              -131.292102,
              55.383946
            ],
            [
              -131.272447,
              55.387774
            ],
            [
              -131.202477,
              55.392834
            ],
            [
              -131.197489,
              55.391051
            ],
            [
              -131.191933,
              55.368334
            ],
            [
              -131.191595,
              55.360527
            ],
            [
              -131.230432,
              55.297802
            ],
            [
              -131.24019,
              55.287156
            ],
            [
              -131.278302,
              55.260319
            ],
            [
              -131.302697,
              55.250217
            ],
            [
              -131.297162,
              55.235046
            ],
            [
              -131.263089,
              55.208318
            ],
            [
              -131.235516,
              55.197574
            ],
            [
              -131.21123,
              55.192379
            ],
            [
              -131.188747,
              55.192745
            ],
            [
              -131.160492,
              55.197481
            ],
            [
              -131.072348,
              55.253822
            ],
            [
              -131.031357,
              55.284785
            ],
            [
              -131.019881,
              55.347905
            ],
            [
              -131.027301,
              55.371392
            ],
            [
              -131.030521,
              55.376917
            ],
            [
              -131.034191,
              55.379358
            ],
            [
              -131.033054,
              55.393118
            ],
            [
              -131.029045,
              55.408395
            ],
            [
              -131.008726,
              55.404818
            ],
            [
              -131.000594,
              55.398012
            ],
            [
              -130.964088,
              55.332664
            ],
            [
              -130.968326,
              55.316626
            ],
            [
              -130.959772,
              55.315892
            ],
            [
              -130.946597,
              55.322396
            ],
            [
              -130.933399,
              55.331906
            ],
            [
              -130.928172,
              55.339426
            ],
            [
              -130.947498,
              55.380823
            ],
            [
              -130.969588,
              55.393281
            ],
            [
              -130.994376,
              55.472396
            ],
            [
              -130.987103,
              55.539872
            ],
            [
              -130.978917,
              55.550835
            ],
            [
              -130.945177,
              55.557731
            ],
            [
              -130.927651,
              55.576585
            ],
            [
              -130.94683,
              55.650716
            ],
            [
              -130.965994,
              55.688974
            ],
            [
              -130.998638,
              55.723538
            ],
            [
              -131.040966,
              55.762837
            ],
            [
              -131.043527,
              55.766997
            ],
            [
              -131.053217,
              55.799843
            ],
            [
              -131.070138,
              55.828551
            ],
            [
              -131.156834,
              55.901147
            ],
            [
              -131.241704,
              55.955069
            ],
            [
              -131.245949,
              55.965905
            ],
            [
              -131.243491,
              55.973689
            ],
            [
              -131.236936,
              55.982293
            ],
            [
              -131.229971,
              55.984342
            ],
            [
              -131.216475,
              55.984342
            ],
            [
              -131.187429,
              55.95601
            ],
            [
              -131.171406,
              55.942952
            ],
            [
              -131.093956,
              55.895675
            ],
            [
              -130.984774,
              55.799349
            ],
            [
              -130.939017,
              55.754831
            ],
            [
              -130.901872,
              55.69738
            ],
            [
              -130.880013,
              55.598954
            ],
            [
              -130.870524,
              55.533768
            ],
            [
              -130.881297,
              55.495582
            ],
            [
              -130.898129,
              55.470177
            ],
            [
              -130.910744,
              55.459982
            ],
            [
              -130.920295,
              55.446085
            ],
            [
              -130.922985,
              55.435113
            ],
            [
              -130.9208,
              55.428721
            ],
            [
              -130.882146,
              55.358831
            ],
            [
              -130.871857,
              55.313991
            ],
            [
              -130.864918,
              55.309469
            ],
            [
              -130.864918,
              55.298367
            ],
            [
              -130.871329,
              55.29378
            ],
            [
              -130.909948,
              55.299878
            ],
            [
              -130.925069,
              55.300713
            ],
            [
              -130.951572,
              55.291648
            ],
            [
              -130.952956,
              55.273092
            ],
            [
              -130.985304,
              55.247286
            ],
            [
              -131.092605,
              55.192711
            ],
            [
              -131.093806,
              55.191335
            ],
            [
              -131.087497,
              55.163036
            ],
            [
              -131.085579,
              55.158233
            ],
            [
              -131.076646,
              55.146178
            ],
            [
              -131.070692,
              55.138143
            ],
            [
              -131.052298,
              55.11816
            ],
            [
              -131.029676,
              55.099478
            ],
            [
              -131.013215,
              55.090069
            ],
            [
              -130.984157,
              55.08441
            ],
            [
              -130.98373,
              55.068946
            ],
            [
              -130.986802,
              55.065222
            ],
            [
              -131.004216,
              55.029605
            ],
            [
              -131.012061,
              54.996238
            ],
            [
              -131.007787,
              54.9913
            ],
            [
              -130.97503,
              54.974853
            ],
            [
              -130.953507,
              54.972102
            ],
            [
              -130.949104,
              54.967846
            ],
            [
              -130.9604,
              54.933685
            ],
            [
              -130.959732,
              54.918678
            ],
            [
              -130.947338,
              54.886733
            ],
            [
              -130.941029,
              54.841587
            ],
            [
              -130.947098,
              54.826047
            ],
            [
              -130.932454,
              54.806938
            ],
            [
              -130.915936,
              54.789617
            ],
            [
              -130.901801,
              54.780876
            ],
            [
              -130.866866,
              54.769068
            ],
            [
              -130.854966,
              54.766341
            ],
            [
              -130.836853,
              54.765437
            ],
            [
              -130.806815,
              54.776862
            ],
            [
              -130.792122,
              54.784784
            ],
            [
              -130.78857,
              54.794643
            ],
            [
              -130.787444,
              54.822905
            ],
            [
              -130.773606,
              54.820845
            ],
            [
              -130.742316,
              54.801914
            ],
            [
              -130.736295,
              54.794798
            ],
            [
              -130.732201,
              54.78262
            ],
            [
              -130.733209,
              54.77961
            ],
            [
              -130.747227,
              54.7726
            ],
            [
              -130.737423,
              54.753545
            ],
            [
              -130.695817,
              54.719346
            ],
            [
              -130.686192,
              54.71691
            ],
            [
              -130.685213,
              54.720091
            ],
            [
              -130.644479,
              54.736897
            ],
            [
              -130.62807,
              54.739341
            ],
            [
              -130.657754,
              54.761828
            ],
            [
              -130.636745,
              54.778456
            ],
            [
              -130.617106,
              54.781554
            ],
            [
              -130.569366,
              54.790869
            ],
            [
              -130.474605,
              54.838102
            ],
            [
              -130.409764,
              54.881192
            ],
            [
              -130.339504,
              54.921376
            ],
            [
              -130.308016,
              54.947585
            ],
            [
              -130.27556,
              54.97293
            ],
            [
              -130.259079,
              54.987642
            ],
            [
              -130.247951,
              55.002341
            ],
            [
              -130.242959,
              55.007316
            ],
            [
              -130.232294,
              55.015597
            ],
            [
              -130.221512,
              55.02599
            ],
            [
              -130.209512,
              55.040822
            ],
            [
              -130.187541,
              55.064665
            ],
            [
              -130.182375,
              55.079388
            ],
            [
              -130.182707,
              55.093212
            ],
            [
              -130.169294,
              55.105424
            ],
            [
              -130.158117,
              55.117104
            ],
            [
              -130.152912,
              55.124537
            ],
            [
              -130.144723,
              55.146038
            ],
            [
              -130.134811,
              55.158273
            ],
            [
              -130.118919,
              55.176074
            ],
            [
              -130.104749,
              55.188975
            ],
            [
              -130.096546,
              55.197953
            ],
            [
              -130.079854,
              55.208527
            ],
            [
              -130.065963,
              55.220163
            ],
            [
              -130.043659,
              55.235541
            ],
            [
              -130.030162,
              55.246592
            ],
            [
              -130.001735,
              55.264557
            ],
            [
              -129.996092,
              55.269559
            ],
            [
              -129.985379,
              55.27776
            ],
            [
              -129.983796,
              55.280795
            ],
            [
              -129.980058,
              55.28423
            ],
            [
              -129.979511,
              55.286723
            ],
            [
              -129.980487,
              55.296334
            ],
            [
              -129.982348,
              55.302079
            ],
            [
              -129.9919,
              55.311663
            ],
            [
              -130.023558,
              55.338259
            ],
            [
              -130.030182,
              55.367696
            ],
            [
              -130.039928,
              55.429422
            ],
            [
              -130.044303,
              55.45197
            ],
            [
              -130.085413,
              55.491517
            ],
            [
              -130.095588,
              55.511205
            ],
            [
              -130.110311,
              55.545067
            ],
            [
              -130.120132,
              55.563919
            ],
            [
              -130.126743,
              55.581282
            ],
            [
              -130.124084,
              55.60276
            ],
            [
              -130.117456,
              55.640543
            ],
            [
              -130.111677,
              55.682051
            ],
            [
              -130.129518,
              55.699806
            ],
            [
              -130.14804,
              55.715041
            ],
            [
              -130.150061,
              55.727099
            ],
            [
              -130.151509,
              55.746029
            ],
            [
              -130.150595,
              55.767031
            ],
            [
              -130.192342,
              55.788882
            ],
            [
              -130.210275,
              55.81061
            ],
            [
              -130.237973,
              55.816645
            ],
            [
              -130.264687,
              55.841361
            ],
            [
              -130.292563,
              55.857348
            ],
            [
              -130.29848,
              55.867112
            ],
            [
              -130.288673,
              55.887006
            ],
            [
              -130.309799,
              55.909565
            ],
            [
              -130.332966,
              55.904219
            ],
            [
              -130.349123,
              55.920078
            ],
            [
              -130.388606,
              55.943179
            ],
            [
              -130.383427,
              55.957009
            ],
            [
              -130.352019,
              55.968255
            ],
            [
              -130.320172,
              55.968974
            ],
            [
              -130.324726,
              55.980963
            ],
            [
              -130.288619,
              55.998125
            ],
            [
              -130.26205,
              55.985225
            ],
            [
              -130.218768,
              55.999971
            ],
            [
              -130.250256,
              56.016693
            ],
            [
              -130.251402,
              56.028236
            ],
            [
              -130.289296,
              56.047321
            ],
            [
              -130.282366,
              56.079598
            ],
            [
              -130.246577284174,
              56.0971959884747
            ],
            [
              -130.343716,
              56.127162
            ],
            [
              -130.425575,
              56.140676
            ],
            [
              -130.466874,
              56.239789
            ],
            [
              -130.541173,
              56.248017
            ],
            [
              -130.589182,
              56.260394
            ],
            [
              -130.622482,
              56.267939
            ],
            [
              -130.644312,
              56.281075
            ],
            [
              -130.740619,
              56.342953
            ],
            [
              -130.782231,
              56.367511
            ],
            [
              -130.810707,
              56.371063
            ],
            [
              -130.831462,
              56.374356
            ],
            [
              -130.881454,
              56.380295
            ],
            [
              -131.016127,
              56.39795
            ],
            [
              -131.067428,
              56.403797
            ],
            [
              -131.085704,
              56.40654
            ],
            [
              -131.086812970675,
              56.40708858642459
            ],
            [
              -131.086135,
              56.40453
            ],
            [
              -131.082629,
              56.38812
            ],
            [
              -131.106495,
              56.368453
            ],
            [
              -131.105352,
              56.343623
            ],
            [
              -131.070272,
              56.331891
            ],
            [
              -131.041772,
              56.321688
            ],
            [
              -131.022362,
              56.307397
            ],
            [
              -131.035236,
              56.302345
            ],
            [
              -131.024698,
              56.280712
            ],
            [
              -131.020668,
              56.276255
            ],
            [
              -131.006308,
              56.275704
            ],
            [
              -131.002503,
              56.277299
            ],
            [
              -130.998845,
              56.273795
            ],
            [
              -131.001443,
              56.270883
            ],
            [
              -131.012782,
              56.254255
            ],
            [
              -131.039343,
              56.243899
            ],
            [
              -131.062065,
              56.23032
            ],
            [
              -131.094214,
              56.204584
            ],
            [
              -131.124664,
              56.200242
            ],
            [
              -131.146417,
              56.189587
            ],
            [
              -131.205311,
              56.185379
            ],
            [
              -131.222848,
              56.176482
            ],
            [
              -131.243243,
              56.179796
            ],
            [
              -131.248963,
              56.188682
            ],
            [
              -131.247816,
              56.19824
            ],
            [
              -131.266683,
              56.206579
            ],
            [
              -131.279281,
              56.20378
            ],
            [
              -131.290925,
              56.196036
            ],
            [
              -131.316732,
              56.191884
            ],
            [
              -131.331637,
              56.180395
            ],
            [
              -131.344269,
              56.17532
            ],
            [
              -131.358311,
              56.157093
            ],
            [
              -131.384999,
              56.145931
            ],
            [
              -131.4181,
              56.142113
            ],
            [
              -131.439435,
              56.134253
            ],
            [
              -131.433921,
              56.124128
            ],
            [
              -131.427868,
              56.118787
            ],
            [
              -131.41713,
              56.10026
            ],
            [
              -131.393425,
              56.082359
            ],
            [
              -131.371866,
              56.071543
            ],
            [
              -131.375646,
              56.058802
            ],
            [
              -131.381445,
              56.043565
            ],
            [
              -131.371818,
              56.026909
            ],
            [
              -131.381401,
              56.014155
            ],
            [
              -131.394846,
              56.021413
            ],
            [
              -131.42703,
              56.025262
            ],
            [
              -131.45747,
              56.022844
            ],
            [
              -131.478819,
              56.020639
            ],
            [
              -131.491044,
              56.017406
            ],
            [
              -131.497293,
              56.024439
            ],
            [
              -131.511118,
              56.030851
            ],
            [
              -131.524153,
              56.034154
            ],
            [
              -131.52838,
              56.042604
            ],
            [
              -131.526416,
              56.047568
            ],
            [
              -131.540879,
              56.055834
            ],
            [
              -131.554247,
              56.061022
            ],
            [
              -131.573811,
              56.064574
            ],
            [
              -131.590987,
              56.072293
            ],
            [
              -131.60079,
              56.081651
            ],
            [
              -131.608155,
              56.087789
            ],
            [
              -131.631092,
              56.090267
            ],
            [
              -131.650285,
              56.100737
            ],
            [
              -131.662211,
              56.101852
            ],
            [
              -131.678035,
              56.097019
            ],
            [
              -131.686457,
              56.094423
            ],
            [
              -131.688045,
              56.092284
            ],
            [
              -131.688471,
              56.089963
            ],
            [
              -131.683593,
              56.089183
            ],
            [
              -131.675341,
              56.084438
            ],
            [
              -131.678733,
              56.080266
            ],
            [
              -131.675822,
              56.07625
            ],
            [
              -131.664253,
              56.072175
            ],
            [
              -131.665841,
              56.065077
            ],
            [
              -131.677387,
              56.061871
            ],
            [
              -131.688917,
              56.053134
            ],
            [
              -131.709907,
              56.053206
            ],
            [
              -131.717208,
              56.059172
            ],
            [
              -131.737973,
              56.063351
            ],
            [
              -131.759865,
              56.058903
            ],
            [
              -131.794601,
              56.055128
            ],
            [
              -131.812849,
              56.055288
            ],
            [
              -131.823424,
              56.054606
            ],
            [
              -131.831903,
              56.054603
            ],
            [
              -131.832242,
              56.051458
            ],
            [
              -131.823106,
              56.043592
            ],
            [
              -131.823804,
              56.03767
            ],
            [
              -131.830648,
              56.033961
            ],
            [
              -131.836355,
              56.035718
            ],
            [
              -131.84661,
              56.038548
            ],
            [
              -131.856908,
              56.037181
            ],
            [
              -131.863392,
              56.0312
            ],
            [
              -131.872408,
              56.026318
            ],
            [
              -131.875002,
              56.019462
            ],
            [
              -131.873423,
              56.012603
            ],
            [
              -131.878637,
              56.003187
            ],
            [
              -131.878884,
              55.993785
            ],
            [
              -131.892596,
              55.989207
            ],
            [
              -131.905691,
              55.977732
            ],
            [
              -131.902214,
              55.970769
            ],
            [
              -131.88821,
              55.961619
            ],
            [
              -131.874869,
              55.953785
            ],
            [
              -131.875496,
              55.94604
            ],
            [
              -131.878371,
              55.936312
            ],
            [
              -131.891194,
              55.923699
            ],
            [
              -131.906887,
              55.916951
            ],
            [
              -131.92154,
              55.917078
            ],
            [
              -131.934501,
              55.915916
            ],
            [
              -131.939949,
              55.910439
            ],
            [
              -131.952992,
              55.908583
            ],
            [
              -131.960463,
              55.903246
            ],
            [
              -131.960591,
              55.893311
            ],
            [
              -131.972101,
              55.881884
            ],
            [
              -131.995959,
              55.873719
            ],
            [
              -132.003759,
              55.848449
            ],
            [
              -131.963706,
              55.820478
            ],
            [
              -131.937391,
              55.799522
            ],
            [
              -131.942207,
              55.778271
            ],
            [
              -131.97917,
              55.751959
            ],
            [
              -131.970956,
              55.714805
            ],
            [
              -131.981152,
              55.695724
            ],
            [
              -132.017511,
              55.691849
            ],
            [
              -132.033082,
              55.694964
            ],
            [
              -132.081822,
              55.702236
            ],
            [
              -132.091454,
              55.678697
            ],
            [
              -132.106963,
              55.656821
            ],
            [
              -132.150456,
              55.649704
            ],
            [
              -132.182744,
              55.634282
            ],
            [
              -132.19793277049,
              55.6338336808621
            ],
            [
              -132.2015,
              55.626376
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "02",
      "COUNTYFP": "282",
      "COUNTYNS": "01419986",
      "AFFGEOID": "0500000US02282",
      "GEOID": "02282",
      "NAME": "Yakutat",
      "LSAD": "03",
      "ALAND": 19746741191,
      "AWATER": 35390272145,
      "County_state": "Yakutat,02"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -143.893325,
              60.024166
            ],
            [
              -143.893326,
              59.9867200205566
            ],
            [
              -143.69899,
              60.027761
            ],
            [
              -143.624152,
              60.037257
            ],
            [
              -143.413377,
              60.051924
            ],
            [
              -143.194276,
              60.061995
            ],
            [
              -143.135616,
              60.062082
            ],
            [
              -143.0687,
              60.068603
            ],
            [
              -142.908859,
              60.090328
            ],
            [
              -142.875248,
              60.092428
            ],
            [
              -142.809852,
              60.095217
            ],
            [
              -142.698419,
              60.093333
            ],
            [
              -142.589676,
              60.088182
            ],
            [
              -142.537534,
              60.083953
            ],
            [
              -142.24518,
              60.049778
            ],
            [
              -142.13004,
              60.030327
            ],
            [
              -142.100059,
              60.026772
            ],
            [
              -142.062454,
              60.023781
            ],
            [
              -141.998818,
              60.022606
            ],
            [
              -141.966178,
              60.019129
            ],
            [
              -141.912218,
              60.009779
            ],
            [
              -141.869766,
              59.998834
            ],
            [
              -141.73624,
              59.961905
            ],
            [
              -141.595376,
              59.961905
            ],
            [
              -141.530295,
              59.977655
            ],
            [
              -141.40145,
              60.005925
            ],
            [
              -141.368138,
              60.024009
            ],
            [
              -141.398119,
              60.053119
            ],
            [
              -141.467284,
              60.083835
            ],
            [
              -141.52813,
              60.12731
            ],
            [
              -141.552611,
              60.15358
            ],
            [
              -141.549283,
              60.170255
            ],
            [
              -141.513156,
              60.162214
            ],
            [
              -141.438525,
              60.132518
            ],
            [
              -141.403586,
              60.15571
            ],
            [
              -141.380055,
              60.158903
            ],
            [
              -141.366745,
              60.147192
            ],
            [
              -141.375613,
              60.110713
            ],
            [
              -141.289499,
              60.034249
            ],
            [
              -141.285332,
              60.015443
            ],
            [
              -141.259314,
              59.998377
            ],
            [
              -141.291043,
              59.967854
            ],
            [
              -141.299609,
              59.937397
            ],
            [
              -141.418582,
              59.916457
            ],
            [
              -141.47093,
              59.926927
            ],
            [
              -141.485207,
              59.925024
            ],
            [
              -141.468075,
              59.898374
            ],
            [
              -141.423134,
              59.877329
            ],
            [
              -141.216148,
              59.827285
            ],
            [
              -141.156497,
              59.813582
            ],
            [
              -140.981942,
              59.769644
            ],
            [
              -140.890673,
              59.74199
            ],
            [
              -140.792511,
              59.728573
            ],
            [
              -140.648953,
              59.711196
            ],
            [
              -140.599991,
              59.711316
            ],
            [
              -140.549111,
              59.707493
            ],
            [
              -140.377972,
              59.697686
            ],
            [
              -140.305981,
              59.69285
            ],
            [
              -140.255117,
              59.702324
            ],
            [
              -140.206393,
              59.71689
            ],
            [
              -140.186428,
              59.732467
            ],
            [
              -140.16266,
              59.740613
            ],
            [
              -140.080423,
              59.756299
            ],
            [
              -139.995333,
              59.776883
            ],
            [
              -139.925455,
              59.792074
            ],
            [
              -139.844169,
              59.807856
            ],
            [
              -139.780233,
              59.826497
            ],
            [
              -139.771439,
              59.845486
            ],
            [
              -139.775955,
              59.868402
            ],
            [
              -139.755277,
              59.887934
            ],
            [
              -139.723496,
              59.912614
            ],
            [
              -139.701013,
              59.930404
            ],
            [
              -139.660799,
              59.933394
            ],
            [
              -139.633466,
              59.943633
            ],
            [
              -139.612431,
              59.94887
            ],
            [
              -139.607202,
              59.968325
            ],
            [
              -139.605063,
              59.999625
            ],
            [
              -139.585336,
              60.004616
            ],
            [
              -139.563232,
              60.027778
            ],
            [
              -139.559666,
              60.041668
            ],
            [
              -139.535898,
              60.043211
            ],
            [
              -139.522588,
              60.020297
            ],
            [
              -139.488702,
              59.995034
            ],
            [
              -139.507971,
              59.985509
            ],
            [
              -139.531264,
              59.974391
            ],
            [
              -139.545168,
              59.96892
            ],
            [
              -139.53162,
              59.960176
            ],
            [
              -139.527455,
              59.940047
            ],
            [
              -139.535902,
              59.935248
            ],
            [
              -139.577232,
              59.918265
            ],
            [
              -139.605909,
              59.905036
            ],
            [
              -139.625896,
              59.904084
            ],
            [
              -139.634462,
              59.874579
            ],
            [
              -139.621137,
              59.840315
            ],
            [
              -139.598294,
              59.807002
            ],
            [
              -139.582114,
              59.774642
            ],
            [
              -139.578307,
              59.734667
            ],
            [
              -139.587651,
              59.725841
            ],
            [
              -139.595439,
              59.718486
            ],
            [
              -139.588777,
              59.708968
            ],
            [
              -139.549753,
              59.703258
            ],
            [
              -139.532297,
              59.705985
            ],
            [
              -139.510518,
              59.702632
            ],
            [
              -139.51818,
              59.687814
            ],
            [
              -139.585789,
              59.642765
            ],
            [
              -139.593488,
              59.624317
            ],
            [
              -139.589369,
              59.618674
            ],
            [
              -139.582528,
              59.613542
            ],
            [
              -139.581447,
              59.609171
            ],
            [
              -139.587135,
              59.605959
            ],
            [
              -139.614513,
              59.597135
            ],
            [
              -139.623125,
              59.595909
            ],
            [
              -139.654579,
              59.598015
            ],
            [
              -139.672408,
              59.602894
            ],
            [
              -139.67631,
              59.611249
            ],
            [
              -139.671436,
              59.615682
            ],
            [
              -139.649691,
              59.618549
            ],
            [
              -139.640173,
              59.626163
            ],
            [
              -139.65445,
              59.630922
            ],
            [
              -139.725834,
              59.638536
            ],
            [
              -139.770567,
              59.599513
            ],
            [
              -139.798169,
              59.585236
            ],
            [
              -139.795314,
              59.574767
            ],
            [
              -139.785068,
              59.564043
            ],
            [
              -139.807161,
              59.554333
            ],
            [
              -139.837817,
              59.561984
            ],
            [
              -139.847236,
              59.557304
            ],
            [
              -139.861306,
              59.546678
            ],
            [
              -139.862547,
              59.544258
            ],
            [
              -139.855565,
              59.53666
            ],
            [
              -139.746478,
              59.503415
            ],
            [
              -139.595186,
              59.445413
            ],
            [
              -139.541156,
              59.423071
            ],
            [
              -139.420168,
              59.37976
            ],
            [
              -139.343049,
              59.356608
            ],
            [
              -139.271031,
              59.337421
            ],
            [
              -139.044593,
              59.280341
            ],
            [
              -138.919749,
              59.248531
            ],
            [
              -138.847498,
              59.224835
            ],
            [
              -138.763467,
              59.19132
            ],
            [
              -138.7059,
              59.162549
            ],
            [
              -138.636702,
              59.130585
            ],
            [
              -138.289132,
              59.05417
            ],
            [
              -138.224323,
              59.032216
            ],
            [
              -138.182075,
              59.013831
            ],
            [
              -138.173949,
              59.003696
            ],
            [
              -138.136246,
              58.989026
            ],
            [
              -138.066332,
              58.957126
            ],
            [
              -137.985198,
              58.909525
            ],
            [
              -137.951995,
              58.886029
            ],
            [
              -137.932593,
              58.868494
            ],
            [
              -137.924608,
              58.843928
            ],
            [
              -137.927624,
              58.827187
            ],
            [
              -137.931565,
              58.819787
            ],
            [
              -137.939353,
              58.813721
            ],
            [
              -137.94213493483102,
              58.8092189716311
            ],
            [
              -137.525677105313,
              58.908974494140104
            ],
            [
              -137.498558,
              58.986694
            ],
            [
              -137.504049,
              59.002092
            ],
            [
              -137.604277,
              59.243057
            ],
            [
              -138.001128,
              59.452164
            ],
            [
              -138.560226,
              59.741201
            ],
            [
              -138.584819,
              59.752453
            ],
            [
              -138.620931,
              59.770559
            ],
            [
              -138.630953,
              59.782209
            ],
            [
              -138.643422,
              59.792502
            ],
            [
              -138.662972,
              59.810225
            ],
            [
              -138.662769,
              59.813719
            ],
            [
              -138.702053,
              59.910245
            ],
            [
              -138.796083,
              59.928701
            ],
            [
              -139.031643,
              59.9937
            ],
            [
              -139.046426,
              59.998235
            ],
            [
              -139.053597,
              60.001864
            ],
            [
              -139.057043,
              60.006825
            ],
            [
              -139.060938,
              60.010967
            ],
            [
              -139.200346,
              60.090701
            ],
            [
              -139.082246,
              60.323825
            ],
            [
              -139.086669,
              60.357654
            ],
            [
              -139.698361,
              60.340421
            ],
            [
              -139.738924,
              60.31842
            ],
            [
              -139.989142,
              60.18524
            ],
            [
              -140.472292,
              60.31059
            ],
            [
              -140.53509,
              60.224224
            ],
            [
              -141.00184,
              60.306105
            ],
            [
              -141.00185619923602,
              60.391688018701096
            ],
            [
              -141.213444,
              60.391676
            ],
            [
              -141.213451,
              60.435268
            ],
            [
              -141.790601,
              60.435238
            ],
            [
              -141.790619,
              60.521899
            ],
            [
              -141.96307,
              60.521896
            ],
            [
              -142.869382,
              60.521845
            ],
            [
              -143.193519,
              60.521828
            ],
            [
              -143.586834,
              60.242126
            ],
            [
              -143.893325,
              60.024166
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "02",
      "COUNTYFP": "110",
      "COUNTYNS": "01419971",
      "AFFGEOID": "0500000US02110",
      "GEOID": "02110",
      "NAME": "Juneau",
      "LSAD": "03",
      "ALAND": 6829216436,
      "AWATER": 1423792753,
      "County_state": "Juneau,02"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -134.769438,
              58.122903
            ],
            [
              -134.784681,
              58.096599
            ],
            [
              -134.725767,
              58.058687
            ],
            [
              -134.697766,
              58.032036
            ],
            [
              -134.67543,
              58.029471
            ],
            [
              -134.65318,
              58.040482
            ],
            [
              -134.628462,
              58.060992
            ],
            [
              -134.588093,
              58.059695
            ],
            [
              -134.577773,
              58.054116
            ],
            [
              -134.551336,
              58.063912
            ],
            [
              -134.56728,
              58.078576
            ],
            [
              -134.538413,
              58.098172
            ],
            [
              -134.510551,
              58.092777
            ],
            [
              -134.471982,
              58.094322
            ],
            [
              -134.427727,
              58.10481
            ],
            [
              -134.417764,
              58.114875
            ],
            [
              -134.17688888870802,
              58.158233946113796
            ],
            [
              -134.177467,
              58.15964
            ],
            [
              -134.215981,
              58.162128
            ],
            [
              -134.259705,
              58.157712
            ],
            [
              -134.306483,
              58.15249
            ],
            [
              -134.317037,
              58.14544
            ],
            [
              -134.32736,
              58.14388
            ],
            [
              -134.371445,
              58.148966
            ],
            [
              -134.401512,
              58.163427
            ],
            [
              -134.413953,
              58.167546
            ],
            [
              -134.446657,
              58.173583
            ],
            [
              -134.462633,
              58.173851
            ],
            [
              -134.47981,
              58.171199
            ],
            [
              -134.50074,
              58.172546
            ],
            [
              -134.519644,
              58.175771
            ],
            [
              -134.541609,
              58.184327
            ],
            [
              -134.559241,
              58.195121
            ],
            [
              -134.594804,
              58.183511
            ],
            [
              -134.608358,
              58.173472
            ],
            [
              -134.608911,
              58.171637
            ],
            [
              -134.631434,
              58.162198
            ],
            [
              -134.682812,
              58.158843
            ],
            [
              -134.689515,
              58.158825
            ],
            [
              -134.699956,
              58.161494
            ],
            [
              -134.703727,
              58.166794
            ],
            [
              -134.710513,
              58.192557
            ],
            [
              -134.712801,
              58.215369
            ],
            [
              -134.71463926772003,
              58.22370882751879
            ],
            [
              -134.772728,
              58.166496
            ],
            [
              -134.769438,
              58.122903
            ]
          ]
        ],
        [
          [
            [
              -135.028573,
              58.519895
            ],
            [
              -135.032142,
              58.513946
            ],
            [
              -135.004183,
              58.484798
            ],
            [
              -134.955999,
              58.469926
            ],
            [
              -134.918733,
              58.468005
            ],
            [
              -134.840595,
              58.369989
            ],
            [
              -134.810257,
              58.370583
            ],
            [
              -134.806093,
              58.374153
            ],
            [
              -134.851303,
              58.43126
            ],
            [
              -134.903651,
              58.481228
            ],
            [
              -134.970276,
              58.502644
            ],
            [
              -134.990501,
              58.504428
            ],
            [
              -135.021434,
              58.5193
            ],
            [
              -135.028573,
              58.519895
            ]
          ]
        ],
        [
          [
            [
              -135.058835,
              58.966785
            ],
            [
              -135.037756,
              58.950669
            ],
            [
              -135.03547,
              58.927621
            ],
            [
              -135.007878,
              58.911803
            ],
            [
              -135.011441,
              58.901259
            ],
            [
              -134.983027,
              58.874056
            ],
            [
              -134.986526,
              58.853453
            ],
            [
              -134.998996,
              58.84733
            ],
            [
              -135.001255601564,
              58.8153907390769
            ],
            [
              -134.997761,
              58.819858
            ],
            [
              -134.960682,
              58.83007
            ],
            [
              -134.941668,
              58.782922
            ],
            [
              -134.935251,
              58.729542
            ],
            [
              -134.920515,
              58.680528
            ],
            [
              -134.988717,
              58.679319
            ],
            [
              -134.941722,
              58.612099
            ],
            [
              -134.792411,
              58.491936
            ],
            [
              -134.77635,
              58.397352
            ],
            [
              -134.69362290705,
              58.3119124966297
            ],
            [
              -134.631203,
              58.247446
            ],
            [
              -134.506876,
              58.216513
            ],
            [
              -134.482352,
              58.229844
            ],
            [
              -134.404868,
              58.21908
            ],
            [
              -134.352398,
              58.200485
            ],
            [
              -134.262386,
              58.190934
            ],
            [
              -134.185015,
              58.208438
            ],
            [
              -134.146685,
              58.199084
            ],
            [
              -134.131299,
              58.232347
            ],
            [
              -134.102777,
              58.248735
            ],
            [
              -134.144711,
              58.267484
            ],
            [
              -134.143658,
              58.30385
            ],
            [
              -134.071879,
              58.329566
            ],
            [
              -134.040505,
              58.401995
            ],
            [
              -134.01341,
              58.403987
            ],
            [
              -133.992494,
              58.352021
            ],
            [
              -133.966825,
              58.317834
            ],
            [
              -134.011746,
              58.293609
            ],
            [
              -134.060708,
              58.282489
            ],
            [
              -134.054291,
              58.238478
            ],
            [
              -134.087674,
              58.181952
            ],
            [
              -134.049603,
              58.062027
            ],
            [
              -133.848776,
              57.93544
            ],
            [
              -133.709141,
              57.792739
            ],
            [
              -133.703097,
              57.792152
            ],
            [
              -133.696784,
              57.795075
            ],
            [
              -133.694617159264,
              57.794125222566194
            ],
            [
              -133.634733147408,
              57.8353617943987
            ],
            [
              -133.631303,
              57.846766
            ],
            [
              -133.610178,
              57.860654
            ],
            [
              -133.598542053296,
              57.8602826614518
            ],
            [
              -133.17694973290202,
              58.1505582487793
            ],
            [
              -133.191526,
              58.162296
            ],
            [
              -133.213311,
              58.178605
            ],
            [
              -133.222898,
              58.186368
            ],
            [
              -133.343725,
              58.270915
            ],
            [
              -133.359691,
              58.284789
            ],
            [
              -133.385718,
              58.311023
            ],
            [
              -133.460377,
              58.38361
            ],
            [
              -133.461475,
              58.385526
            ],
            [
              -133.379908,
              58.427909
            ],
            [
              -133.499893,
              58.490276
            ],
            [
              -133.559942,
              58.522318
            ],
            [
              -133.615751,
              58.555784
            ],
            [
              -133.699835,
              58.60729
            ],
            [
              -133.714186,
              58.618137
            ],
            [
              -133.723635,
              58.626004
            ],
            [
              -133.840392,
              58.727991
            ],
            [
              -133.992081,
              58.774581
            ],
            [
              -134.250526,
              58.858046
            ],
            [
              -134.26609729375502,
              58.870264139380495
            ],
            [
              -134.328964,
              58.919593
            ],
            [
              -134.30639,
              58.959238
            ],
            [
              -134.326427676754,
              58.963129162404
            ],
            [
              -135.058835,
              58.966785
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "02",
      "COUNTYFP": "068",
      "COUNTYNS": "01419988",
      "AFFGEOID": "0500000US02068",
      "GEOID": "02068",
      "NAME": "Denali",
      "LSAD": "04",
      "ALAND": 32729613863,
      "AWATER": 66068878,
      "County_state": "Denali,02"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -152.997647,
              62.727401
            ],
            [
              -152.939073,
              62.725834
            ],
            [
              -152.155261,
              62.725856
            ],
            [
              -151.893784,
              62.725864
            ],
            [
              -151.894015,
              62.798058
            ],
            [
              -151.271216,
              62.938816
            ],
            [
              -150.720916,
              63.063184
            ],
            [
              -150.261715,
              63.166972
            ],
            [
              -149.526148,
              63.333217
            ],
            [
              -149.116749,
              63.333222
            ],
            [
              -148.311832,
              63.333222
            ],
            [
              -148.025618,
              63.333233
            ],
            [
              -148.025627,
              63.477609
            ],
            [
              -147.115864,
              63.477617
            ],
            [
              -146.999966,
              63.477618
            ],
            [
              -146.999966,
              63.479645
            ],
            [
              -146.999927,
              63.859755
            ],
            [
              -146.999909,
              64.257196
            ],
            [
              -147.7592,
              64.2572
            ],
            [
              -147.779479,
              64.259029
            ],
            [
              -147.820465,
              64.281204
            ],
            [
              -147.868424,
              64.287627
            ],
            [
              -147.894299,
              64.306324
            ],
            [
              -147.925255,
              64.312313
            ],
            [
              -147.93108,
              64.322026
            ],
            [
              -147.958842,
              64.324528
            ],
            [
              -148.00322,
              64.346628
            ],
            [
              -148.046696,
              64.343802
            ],
            [
              -148.657335,
              64.343789
            ],
            [
              -149.132599,
              64.343778
            ],
            [
              -149.132653,
              64.358214
            ],
            [
              -149.982393,
              64.358183
            ],
            [
              -150.729385,
              64.358173
            ],
            [
              -150.950598,
              64.213701
            ],
            [
              -151.281995,
              63.997265
            ],
            [
              -151.407625,
              63.997261
            ],
            [
              -151.432994,
              64.007806
            ],
            [
              -151.516333,
              64.020631
            ],
            [
              -151.48966,
              64.044609
            ],
            [
              -151.540489,
              64.050448
            ],
            [
              -151.564901,
              64.055
            ],
            [
              -151.564903,
              64.069436
            ],
            [
              -151.729758,
              64.069431
            ],
            [
              -151.776068,
              64.073171
            ],
            [
              -151.793976,
              64.06943
            ],
            [
              -151.826876,
              64.040556
            ],
            [
              -151.859734,
              64.018901
            ],
            [
              -151.830535,
              63.997248
            ],
            [
              -152.047444,
              63.997242
            ],
            [
              -152.055132,
              63.824006
            ],
            [
              -152.229256,
              63.824001
            ],
            [
              -152.228439,
              63.65076
            ],
            [
              -152.87341,
              63.650745
            ],
            [
              -152.873392,
              63.4775
            ],
            [
              -152.810945,
              63.477501
            ],
            [
              -152.810931,
              63.347563
            ],
            [
              -152.619517,
              63.347568
            ],
            [
              -152.619512,
              63.304254
            ],
            [
              -152.428098,
              63.304259
            ],
            [
              -152.428084,
              63.183171
            ],
            [
              -152.680154,
              62.981462
            ],
            [
              -152.997647,
              62.727401
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "02",
      "COUNTYFP": "170",
      "COUNTYNS": "01419976",
      "AFFGEOID": "0500000US02170",
      "GEOID": "02170",
      "NAME": "Matanuska-Susitna",
      "LSAD": "04",
      "ALAND": 63760578688,
      "AWATER": 1704714297,
      "County_state": "Matanuska-Susitna,02"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -153.001506,
              62.727441
            ],
            [
              -153.0016,
              62.718661
            ],
            [
              -152.999839,
              62.292621
            ],
            [
              -152.999877,
              61.816874
            ],
            [
              -152.999882,
              61.426172
            ],
            [
              -152.132646,
              61.426207
            ],
            [
              -151.332784,
              61.426239
            ],
            [
              -151.332802,
              61.252936
            ],
            [
              -150.974014,
              61.25294
            ],
            [
              -150.974011240993,
              61.191658351718395
            ],
            [
              -150.960114,
              61.194037
            ],
            [
              -150.951153,
              61.198778
            ],
            [
              -150.947425,
              61.20314
            ],
            [
              -150.947155,
              61.206299
            ],
            [
              -150.946243,
              61.208644
            ],
            [
              -150.941944,
              61.209947
            ],
            [
              -150.939251,
              61.210299
            ],
            [
              -150.926773,
              61.206351
            ],
            [
              -150.895905,
              61.208915
            ],
            [
              -150.848842,
              61.220588
            ],
            [
              -150.84241,
              61.224213
            ],
            [
              -150.827295,
              61.22839
            ],
            [
              -150.711291,
              61.251089
            ],
            [
              -150.690497,
              61.259297
            ],
            [
              -150.679902,
              61.265888
            ],
            [
              -150.67125,
              61.273652
            ],
            [
              -150.66262,
              61.295356
            ],
            [
              -150.655804,
              61.298173
            ],
            [
              -150.646221,
              61.296689
            ],
            [
              -150.628459,
              61.284407
            ],
            [
              -150.606961,
              61.277732
            ],
            [
              -150.591842,
              61.279485
            ],
            [
              -150.590166,
              61.281487
            ],
            [
              -150.56167,
              61.281487
            ],
            [
              -150.524086927064,
              61.26425135624579
            ],
            [
              -150.495726,
              61.251245
            ],
            [
              -150.484391,
              61.247262
            ],
            [
              -150.468812,
              61.244627
            ],
            [
              -150.425,
              61.245552
            ],
            [
              -150.394411,
              61.249107
            ],
            [
              -150.334576,
              61.251301
            ],
            [
              -150.312226,
              61.254029
            ],
            [
              -150.303639,
              61.255871
            ],
            [
              -150.303688,
              61.257467
            ],
            [
              -150.286978,
              61.257443
            ],
            [
              -150.273575,
              61.251559
            ],
            [
              -150.266124,
              61.251237
            ],
            [
              -150.254296,
              61.254501
            ],
            [
              -150.204894,
              61.259548
            ],
            [
              -150.132634,
              61.257915
            ],
            [
              -150.074793,
              61.2535
            ],
            [
              -149.985874,
              61.237515
            ],
            [
              -149.919682,
              61.26347
            ],
            [
              -149.9183,
              61.323473
            ],
            [
              -149.898216,
              61.352715
            ],
            [
              -149.876852,
              61.384347
            ],
            [
              -149.808324,
              61.395768
            ],
            [
              -149.767968,
              61.438343
            ],
            [
              -149.72647,
              61.453827
            ],
            [
              -149.614665,
              61.493237
            ],
            [
              -149.614577855404,
              61.493233070013794
            ],
            [
              -149.542776,
              61.489995
            ],
            [
              -149.479006,
              61.468104
            ],
            [
              -149.431417,
              61.468104
            ],
            [
              -149.431406685809,
              61.4679905709827
            ],
            [
              -149.35944,
              61.484025
            ],
            [
              -149.240449,
              61.484025
            ],
            [
              -149.240395,
              61.455142
            ],
            [
              -149.180041,
              61.45514
            ],
            [
              -149.180062,
              61.42625
            ],
            [
              -148.483047,
              61.426269
            ],
            [
              -148.462471,
              61.426267
            ],
            [
              -148.050309,
              61.426266
            ],
            [
              -147.206597,
              61.426265
            ],
            [
              -147.206605,
              61.470663
            ],
            [
              -146.942559,
              61.470675
            ],
            [
              -146.942601,
              61.817272
            ],
            [
              -146.960952,
              61.817272
            ],
            [
              -146.960978,
              62.163852
            ],
            [
              -146.979811,
              62.163852
            ],
            [
              -146.979807,
              62.250497
            ],
            [
              -146.423984,
              62.250511
            ],
            [
              -146.436884,
              62.510453
            ],
            [
              -146.436899,
              62.856991
            ],
            [
              -146.450126,
              62.856991
            ],
            [
              -146.450134,
              62.957864
            ],
            [
              -146.494296,
              62.957864
            ],
            [
              -146.494311,
              63.168975
            ],
            [
              -146.493998,
              63.169611
            ],
            [
              -146.484532,
              63.180428
            ],
            [
              -146.489541,
              63.482913
            ],
            [
              -146.999966,
              63.479645
            ],
            [
              -146.999966,
              63.477618
            ],
            [
              -147.115864,
              63.477617
            ],
            [
              -148.025627,
              63.477609
            ],
            [
              -148.025618,
              63.333233
            ],
            [
              -148.311832,
              63.333222
            ],
            [
              -149.116749,
              63.333222
            ],
            [
              -149.526148,
              63.333217
            ],
            [
              -150.261715,
              63.166972
            ],
            [
              -150.720916,
              63.063184
            ],
            [
              -151.271216,
              62.938816
            ],
            [
              -151.894015,
              62.798058
            ],
            [
              -151.893784,
              62.725864
            ],
            [
              -152.155261,
              62.725856
            ],
            [
              -152.939073,
              62.725834
            ],
            [
              -152.997647,
              62.727401
            ],
            [
              -153.001506,
              62.727441
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "02",
      "COUNTYFP": "020",
      "COUNTYNS": "01416061",
      "AFFGEOID": "0500000US02020",
      "GEOID": "02020",
      "NAME": "Anchorage",
      "LSAD": "12",
      "ALAND": 4419537010,
      "AWATER": 615526050,
      "County_state": "Anchorage,02"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -150.068004,
              61.166132
            ],
            [
              -150.075451,
              61.156269
            ],
            [
              -150.065646,
              61.151079
            ],
            [
              -150.039304,
              61.144291
            ],
            [
              -150.012094074652,
              61.139731572389294
            ],
            [
              -149.969164,
              61.132538
            ],
            [
              -149.963973995028,
              61.129519923535895
            ],
            [
              -149.915666,
              61.101428
            ],
            [
              -149.857168,
              61.07902
            ],
            [
              -149.831922,
              61.076197
            ],
            [
              -149.83084826186,
              61.0754749228228
            ],
            [
              -149.805816,
              61.058641
            ],
            [
              -149.785126,
              61.040798
            ],
            [
              -149.784393,
              61.038147
            ],
            [
              -149.751943,
              61.024072
            ],
            [
              -149.739692,
              61.016961
            ],
            [
              -149.596076,
              60.98079
            ],
            [
              -149.510415,
              60.981742
            ],
            [
              -149.327217,
              60.927958
            ],
            [
              -149.192519,
              60.939864
            ],
            [
              -149.087253,
              60.909257
            ],
            [
              -149.008462,
              60.854782
            ],
            [
              -148.99741,
              60.839672
            ],
            [
              -149.004897,
              60.83168
            ],
            [
              -149.034078391325,
              60.8438693782096
            ],
            [
              -149.034071,
              60.834081
            ],
            [
              -149.037839,
              60.819648
            ],
            [
              -149.037741,
              60.732987
            ],
            [
              -148.745618,
              60.733007
            ],
            [
              -148.738281,
              60.819662
            ],
            [
              -148.738363,
              60.848546
            ],
            [
              -148.472065,
              60.84852
            ],
            [
              -148.472097,
              61.070766
            ],
            [
              -148.472123,
              61.166295
            ],
            [
              -148.462471,
              61.426267
            ],
            [
              -148.483047,
              61.426269
            ],
            [
              -149.180062,
              61.42625
            ],
            [
              -149.180041,
              61.45514
            ],
            [
              -149.240395,
              61.455142
            ],
            [
              -149.240449,
              61.484025
            ],
            [
              -149.35944,
              61.484025
            ],
            [
              -149.431406685809,
              61.4679905709827
            ],
            [
              -149.429513,
              61.447165
            ],
            [
              -149.49321,
              61.428284
            ],
            [
              -149.496675407471,
              61.4266751867959
            ],
            [
              -149.537419,
              61.40776
            ],
            [
              -149.575804,
              61.407703
            ],
            [
              -149.612407,
              61.394619
            ],
            [
              -149.701724,
              61.385299
            ],
            [
              -149.730277,
              61.332951
            ],
            [
              -149.778860817521,
              61.32380557767401
            ],
            [
              -149.811179,
              61.317722
            ],
            [
              -149.88665552708702,
              61.246684955029394
            ],
            [
              -149.913696124906,
              61.2212348682615
            ],
            [
              -149.924441,
              61.211122
            ],
            [
              -150.009941,
              61.203637
            ],
            [
              -150.068004,
              61.166132
            ]
          ]
        ],
        [
          [
            [
              -150.242099,
              61.137835
            ],
            [
              -150.265894,
              61.127365
            ],
            [
              -150.228774,
              61.123558
            ],
            [
              -150.190703,
              61.139738
            ],
            [
              -150.158342,
              61.160678
            ],
            [
              -150.158342,
              61.16734
            ],
            [
              -150.164053,
              61.174003
            ],
            [
              -150.220208,
              61.170195
            ],
            [
              -150.228774,
              61.162581
            ],
            [
              -150.242099,
              61.137835
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "02",
      "COUNTYFP": "016",
      "COUNTYNS": "01419965",
      "AFFGEOID": "0500000US02016",
      "GEOID": "02016",
      "NAME": "Aleutians West",
      "LSAD": "05",
      "ALAND": 11376643262,
      "AWATER": 25184877501,
      "County_state": "Aleutians West,02"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              179.482464,
              51.982834
            ],
            [
              179.475569,
              51.937456
            ],
            [
              179.484634,
              51.921268
            ],
            [
              179.521868,
              51.896765
            ],
            [
              179.614364,
              51.871772
            ],
            [
              179.639077,
              51.871931
            ],
            [
              179.655878,
              51.876215
            ],
            [
              179.743012,
              51.911749
            ],
            [
              179.755426,
              51.935774
            ],
            [
              179.767251,
              51.947572
            ],
            [
              179.77847,
              51.962217
            ],
            [
              179.773922,
              51.970693
            ],
            [
              179.663327,
              52.022941
            ],
            [
              179.636846,
              52.025712
            ],
            [
              179.609536,
              52.022345
            ],
            [
              179.571049,
              52.011111
            ],
            [
              179.546866,
              51.991407
            ],
            [
              179.526743,
              51.981164
            ],
            [
              179.486565,
              51.983959
            ],
            [
              179.482464,
              51.982834
            ]
          ]
        ],
        [
          [
            [
              178.625536,
              51.637303
            ],
            [
              178.631609,
              51.625782
            ],
            [
              178.634021,
              51.623981
            ],
            [
              178.706047,
              51.593182
            ],
            [
              178.767787,
              51.576123
            ],
            [
              178.785061,
              51.571866
            ],
            [
              178.795194,
              51.575429
            ],
            [
              178.831825,
              51.580534
            ],
            [
              178.843631,
              51.578642
            ],
            [
              178.951626,
              51.541963
            ],
            [
              178.969019,
              51.531237
            ],
            [
              179.013516,
              51.49728
            ],
            [
              179.034532,
              51.47853
            ],
            [
              179.060354,
              51.454875
            ],
            [
              179.097477,
              51.44058
            ],
            [
              179.2065,
              51.393284
            ],
            [
              179.218185,
              51.387377
            ],
            [
              179.220471,
              51.376667
            ],
            [
              179.261618,
              51.357688
            ],
            [
              179.366788,
              51.371837
            ],
            [
              179.384679,
              51.36421
            ],
            [
              179.389855,
              51.361004
            ],
            [
              179.399469,
              51.359433
            ],
            [
              179.450191,
              51.365142
            ],
            [
              179.467581,
              51.371629
            ],
            [
              179.462765,
              51.376176
            ],
            [
              179.400809,
              51.400557
            ],
            [
              179.386221,
              51.404401
            ],
            [
              179.33498,
              51.404933
            ],
            [
              179.263934,
              51.405838
            ],
            [
              179.236253,
              51.409606
            ],
            [
              179.23027,
              51.413419
            ],
            [
              179.226883,
              51.423941
            ],
            [
              179.218031,
              51.43894
            ],
            [
              179.191082,
              51.462935
            ],
            [
              179.178081,
              51.464812
            ],
            [
              179.164531,
              51.464635
            ],
            [
              179.143656,
              51.469442
            ],
            [
              179.101442,
              51.485497
            ],
            [
              179.076803,
              51.498518
            ],
            [
              179.002896,
              51.552486
            ],
            [
              178.920826,
              51.586137
            ],
            [
              178.918827,
              51.588337
            ],
            [
              178.917608,
              51.594949
            ],
            [
              178.919136,
              51.605546
            ],
            [
              178.90391,
              51.614914
            ],
            [
              178.864937,
              51.623133
            ],
            [
              178.804128,
              51.635034
            ],
            [
              178.785825,
              51.633434
            ],
            [
              178.738019,
              51.632734
            ],
            [
              178.689903,
              51.644422
            ],
            [
              178.681998,
              51.649946
            ],
            [
              178.675528,
              51.659064
            ],
            [
              178.664013,
              51.661935
            ],
            [
              178.645511,
              51.657634
            ],
            [
              178.625536,
              51.637303
            ]
          ]
        ],
        [
          [
            [
              178.446964,
              51.978222
            ],
            [
              178.454664,
              51.960501
            ],
            [
              178.468045,
              51.931635
            ],
            [
              178.484831,
              51.909898
            ],
            [
              178.502493,
              51.899644
            ],
            [
              178.518861,
              51.899759
            ],
            [
              178.539395,
              51.903246
            ],
            [
              178.567447,
              51.925939
            ],
            [
              178.590245,
              51.945457
            ],
            [
              178.591597,
              51.952652
            ],
            [
              178.570619,
              51.968064
            ],
            [
              178.552612,
              51.973968
            ],
            [
              178.478586,
              51.987549
            ],
            [
              178.463385,
              51.987849
            ],
            [
              178.446964,
              51.978222
            ]
          ]
        ],
        [
          [
            [
              178.236931,
              51.828209
            ],
            [
              178.246209,
              51.817078
            ],
            [
              178.304892,
              51.777434
            ],
            [
              178.308563,
              51.775701
            ],
            [
              178.318757,
              51.772322
            ],
            [
              178.335664,
              51.769926
            ],
            [
              178.339082,
              51.771529
            ],
            [
              178.354635,
              51.768515
            ],
            [
              178.380741,
              51.763907
            ],
            [
              178.372348,
              51.774146
            ],
            [
              178.335631,
              51.807031
            ],
            [
              178.33219,
              51.809037
            ],
            [
              178.319389,
              51.815737
            ],
            [
              178.310298,
              51.819993
            ],
            [
              178.305568,
              51.821748
            ],
            [
              178.236931,
              51.828209
            ]
          ]
        ],
        [
          [
            [
              178.09461,
              52.033294
            ],
            [
              178.10273,
              52.003927
            ],
            [
              178.105874,
              51.998357
            ],
            [
              178.132547,
              51.986982
            ],
            [
              178.174473,
              51.991684
            ],
            [
              178.190963,
              52.003546
            ],
            [
              178.179962,
              52.033247
            ],
            [
              178.175781,
              52.036777
            ],
            [
              178.141695,
              52.051034
            ],
            [
              178.119144,
              52.051659
            ],
            [
              178.1176,
              52.048612
            ],
            [
              178.107266,
              52.045744
            ],
            [
              178.09461,
              52.033294
            ]
          ]
        ],
        [
          [
            [
              177.203323,
              51.896562
            ],
            [
              177.200423,
              51.894746
            ],
            [
              177.203996,
              51.880531
            ],
            [
              177.212422,
              51.876431
            ],
            [
              177.235523,
              51.87326
            ],
            [
              177.27337,
              51.857123
            ],
            [
              177.293424,
              51.84561
            ],
            [
              177.296018,
              51.839866
            ],
            [
              177.294035,
              51.837301
            ],
            [
              177.303314,
              51.829458
            ],
            [
              177.311768,
              51.825971
            ],
            [
              177.321687,
              51.828543
            ],
            [
              177.334017,
              51.844444
            ],
            [
              177.334229,
              51.866769
            ],
            [
              177.327179,
              51.871049
            ],
            [
              177.326781,
              51.873636
            ],
            [
              177.348816,
              51.904469
            ],
            [
              177.373934,
              51.91976
            ],
            [
              177.409536,
              51.930821
            ],
            [
              177.484313,
              51.923413
            ],
            [
              177.560513,
              51.916364
            ],
            [
              177.601005,
              51.922254
            ],
            [
              177.606529,
              51.925069
            ],
            [
              177.610618,
              51.936713
            ],
            [
              177.611553,
              51.950829
            ],
            [
              177.607535,
              51.95472
            ],
            [
              177.579823,
              51.950836
            ],
            [
              177.571796,
              51.95159
            ],
            [
              177.543534,
              51.956175
            ],
            [
              177.539627,
              51.959418
            ],
            [
              177.532729,
              51.97007
            ],
            [
              177.538223,
              51.978897
            ],
            [
              177.572068,
              52.001812
            ],
            [
              177.577226,
              52.00497
            ],
            [
              177.601645,
              52.016377
            ],
            [
              177.609087,
              52.028518
            ],
            [
              177.632555,
              52.064844
            ],
            [
              177.641864,
              52.068316
            ],
            [
              177.653614,
              52.070323
            ],
            [
              177.659451,
              52.069439
            ],
            [
              177.667256,
              52.076274
            ],
            [
              177.675952,
              52.092167
            ],
            [
              177.661607,
              52.112746
            ],
            [
              177.602673,
              52.13732
            ],
            [
              177.563396,
              52.121959
            ],
            [
              177.545604,
              52.101091
            ],
            [
              177.505319,
              52.038768
            ],
            [
              177.505747,
              52.016374
            ],
            [
              177.503441,
              52.008829
            ],
            [
              177.497441,
              51.993328
            ],
            [
              177.483712,
              51.984877
            ],
            [
              177.413484,
              51.979724
            ],
            [
              177.345577,
              51.963005
            ],
            [
              177.341518,
              51.955016
            ],
            [
              177.291312,
              51.91943
            ],
            [
              177.272695,
              51.920054
            ],
            [
              177.233904,
              51.909624
            ],
            [
              177.203323,
              51.896562
            ]
          ]
        ],
        [
          [
            [
              175.874353,
              52.371004
            ],
            [
              175.873317,
              52.361138
            ],
            [
              175.890684,
              52.344514
            ],
            [
              175.90277,
              52.336823
            ],
            [
              175.911286,
              52.334831
            ],
            [
              175.94418,
              52.336437
            ],
            [
              175.966521,
              52.359728
            ],
            [
              175.95056,
              52.368357
            ],
            [
              175.906734,
              52.375651
            ],
            [
              175.874353,
              52.371004
            ]
          ]
        ],
        [
          [
            [
              174.069186,
              52.734888
            ],
            [
              174.066195,
              52.731042
            ],
            [
              174.071842,
              52.718295
            ],
            [
              174.109409,
              52.70856
            ],
            [
              174.158146,
              52.706059
            ],
            [
              174.159252,
              52.707387
            ],
            [
              174.155764,
              52.715375
            ],
            [
              174.145326,
              52.72855
            ],
            [
              174.13315,
              52.733786
            ],
            [
              174.09665,
              52.743485
            ],
            [
              174.092073,
              52.74206
            ],
            [
              174.069186,
              52.734888
            ]
          ]
        ],
        [
          [
            [
              173.954075,
              52.75141
            ],
            [
              173.952793,
              52.747885
            ],
            [
              173.96088,
              52.738136
            ],
            [
              173.9726,
              52.729423
            ],
            [
              174.004827,
              52.719857
            ],
            [
              174.021702,
              52.730286
            ],
            [
              174.003651,
              52.744283
            ],
            [
              173.983432,
              52.749053
            ],
            [
              173.954075,
              52.75141
            ]
          ]
        ],
        [
          [
            [
              173.867436,
              52.775128
            ],
            [
              173.861653,
              52.773579
            ],
            [
              173.875585,
              52.761898
            ],
            [
              173.894753,
              52.75078
            ],
            [
              173.925271,
              52.752433
            ],
            [
              173.930912,
              52.750227
            ],
            [
              173.932926,
              52.746649
            ],
            [
              173.940037,
              52.75186
            ],
            [
              173.931553,
              52.758574
            ],
            [
              173.897452,
              52.77178
            ],
            [
              173.881412,
              52.775028
            ],
            [
              173.867436,
              52.775128
            ]
          ]
        ],
        [
          [
            [
              173.356103,
              52.405563
            ],
            [
              173.356927,
              52.403873
            ],
            [
              173.385704,
              52.404072
            ],
            [
              173.3955,
              52.402647
            ],
            [
              173.455586,
              52.389656
            ],
            [
              173.465442,
              52.384621
            ],
            [
              173.483843,
              52.383485
            ],
            [
              173.512162,
              52.385035
            ],
            [
              173.543778,
              52.392666
            ],
            [
              173.559891,
              52.401165
            ],
            [
              173.588794,
              52.400973
            ],
            [
              173.595397,
              52.391893
            ],
            [
              173.606767,
              52.378249
            ],
            [
              173.640825,
              52.359428
            ],
            [
              173.644793,
              52.357598
            ],
            [
              173.651293,
              52.35637
            ],
            [
              173.725696,
              52.356579
            ],
            [
              173.719161,
              52.397703
            ],
            [
              173.712323,
              52.421033
            ],
            [
              173.704299,
              52.432192
            ],
            [
              173.702252,
              52.434804
            ],
            [
              173.69386,
              52.438694
            ],
            [
              173.691601,
              52.445935
            ],
            [
              173.695719,
              52.458935
            ],
            [
              173.707741,
              52.477377
            ],
            [
              173.739385,
              52.493257
            ],
            [
              173.754979,
              52.496127
            ],
            [
              173.772402,
              52.506877
            ],
            [
              173.772799,
              52.509905
            ],
            [
              173.769503,
              52.512072
            ],
            [
              173.73627,
              52.512422
            ],
            [
              173.680586,
              52.512878
            ],
            [
              173.623883,
              52.506948
            ],
            [
              173.587554,
              52.476785
            ],
            [
              173.555739,
              52.479472
            ],
            [
              173.545302,
              52.476
            ],
            [
              173.549605,
              52.469989
            ],
            [
              173.550002,
              52.467067
            ],
            [
              173.530105,
              52.449968
            ],
            [
              173.525161,
              52.448137
            ],
            [
              173.501022,
              52.440926
            ],
            [
              173.476243,
              52.441909
            ],
            [
              173.467698,
              52.444473
            ],
            [
              173.445696,
              52.455031
            ],
            [
              173.440111,
              52.453664
            ],
            [
              173.380058,
              52.431843
            ],
            [
              173.356103,
              52.405563
            ]
          ]
        ],
        [
          [
            [
              172.461667,
              52.92716
            ],
            [
              172.469022,
              52.911337
            ],
            [
              172.512996,
              52.905181
            ],
            [
              172.5487,
              52.914322
            ],
            [
              172.585075,
              52.921327
            ],
            [
              172.640372,
              52.925441
            ],
            [
              172.754236,
              52.87749
            ],
            [
              172.766693,
              52.862669
            ],
            [
              172.76739,
              52.848372
            ],
            [
              172.763366,
              52.823656
            ],
            [
              172.809387,
              52.78929
            ],
            [
              172.903628,
              52.761667
            ],
            [
              172.998472,
              52.796979
            ],
            [
              173.11856,
              52.78444
            ],
            [
              173.134521,
              52.784357
            ],
            [
              173.142678,
              52.786254
            ],
            [
              173.166899,
              52.795229
            ],
            [
              173.173543,
              52.804378
            ],
            [
              173.187952,
              52.8315
            ],
            [
              173.204948,
              52.848911
            ],
            [
              173.224051,
              52.856403
            ],
            [
              173.22907,
              52.856156
            ],
            [
              173.284417,
              52.827933
            ],
            [
              173.302331,
              52.823286
            ],
            [
              173.413016,
              52.827891
            ],
            [
              173.423819,
              52.828799
            ],
            [
              173.42767,
              52.830763
            ],
            [
              173.421682,
              52.845477
            ],
            [
              173.295399,
              52.926987
            ],
            [
              173.251326,
              52.944362
            ],
            [
              173.235265,
              52.943628
            ],
            [
              173.211752,
              52.939489
            ],
            [
              173.172406,
              52.960545
            ],
            [
              173.159648,
              52.974163
            ],
            [
              173.13151,
              52.987521
            ],
            [
              173.121988,
              52.990352
            ],
            [
              173.107249,
              52.993228
            ],
            [
              172.746566,
              53.01075
            ],
            [
              172.643266,
              53.004979
            ],
            [
              172.629077,
              53.001324
            ],
            [
              172.461667,
              52.92716
            ]
          ]
        ],
        [
          [
            [
              -167.852333,
              53.315599
            ],
            [
              -167.851511,
              53.308668
            ],
            [
              -167.83509,
              53.29962
            ],
            [
              -167.798984,
              53.284757
            ],
            [
              -167.644179,
              53.250842
            ],
            [
              -167.622173,
              53.250362
            ],
            [
              -167.609903,
              53.2853
            ],
            [
              -167.598428,
              53.288048
            ],
            [
              -167.58918,
              53.288698
            ],
            [
              -167.539247,
              53.277864
            ],
            [
              -167.530884,
              53.275659
            ],
            [
              -167.51547,
              53.267876
            ],
            [
              -167.488215,
              53.269121
            ],
            [
              -167.466304,
              53.295888
            ],
            [
              -167.442804,
              53.321015
            ],
            [
              -167.386984,
              53.340671
            ],
            [
              -167.348653,
              53.333262
            ],
            [
              -167.308126,
              53.33433
            ],
            [
              -167.302982,
              53.336911
            ],
            [
              -167.291831,
              53.364102
            ],
            [
              -167.201432,
              53.3979
            ],
            [
              -167.134134,
              53.426448
            ],
            [
              -167.124277,
              53.425534
            ],
            [
              -167.112008,
              53.416775
            ],
            [
              -167.075386,
              53.424979
            ],
            [
              -167.050025,
              53.433067
            ],
            [
              -167.04821,
              53.448844
            ],
            [
              -167.036104,
              53.449289
            ],
            [
              -166.994329,
              53.429201
            ],
            [
              -166.964377,
              53.43252
            ],
            [
              -166.959082,
              53.455753
            ],
            [
              -166.922674,
              53.441136
            ],
            [
              -166.878087,
              53.429884
            ],
            [
              -166.863119,
              53.443878
            ],
            [
              -166.808221,
              53.434715
            ],
            [
              -166.789062,
              53.4531
            ],
            [
              -166.749158,
              53.440944
            ],
            [
              -166.742859,
              53.464298
            ],
            [
              -166.74381,
              53.483395
            ],
            [
              -166.772655,
              53.496371
            ],
            [
              -166.743054,
              53.51482
            ],
            [
              -166.735039,
              53.50664
            ],
            [
              -166.712475,
              53.498445
            ],
            [
              -166.667921,
              53.486027
            ],
            [
              -166.662276,
              53.485349
            ],
            [
              -166.656234,
              53.487119
            ],
            [
              -166.647312,
              53.503898
            ],
            [
              -166.636379,
              53.523119
            ],
            [
              -166.639587,
              53.545086
            ],
            [
              -166.581011,
              53.530449
            ],
            [
              -166.572086,
              53.559736
            ],
            [
              -166.565907,
              53.583094
            ],
            [
              -166.508982,
              53.5838
            ],
            [
              -166.52871,
              53.610952
            ],
            [
              -166.553983,
              53.623448
            ],
            [
              -166.532639,
              53.630533
            ],
            [
              -166.467583,
              53.646574
            ],
            [
              -166.444909,
              53.640646
            ],
            [
              -166.283267,
              53.684219
            ],
            [
              -166.274896,
              53.687253
            ],
            [
              -166.265182,
              53.698248
            ],
            [
              -166.262974,
              53.70371
            ],
            [
              -166.19906,
              53.727328
            ],
            [
              -166.166703,
              53.733402
            ],
            [
              -166.111317,
              53.776856
            ],
            [
              -166.094147,
              53.8392
            ],
            [
              -166.097565,
              53.84399
            ],
            [
              -166.113037,
              53.853716
            ],
            [
              -166.119922,
              53.855048
            ],
            [
              -166.198751,
              53.8361
            ],
            [
              -166.21233,
              53.827769
            ],
            [
              -166.214312,
              53.82043
            ],
            [
              -166.212603,
              53.817127
            ],
            [
              -166.303201,
              53.791538
            ],
            [
              -166.336768,
              53.78709
            ],
            [
              -166.420471,
              53.762088
            ],
            [
              -166.460324,
              53.745838
            ],
            [
              -166.469112,
              53.735935
            ],
            [
              -166.540531,
              53.715926
            ],
            [
              -166.552078,
              53.728498
            ],
            [
              -166.547438,
              53.749404
            ],
            [
              -166.434846,
              53.798012
            ],
            [
              -166.404896,
              53.809345
            ],
            [
              -166.389196,
              53.832343
            ],
            [
              -166.351999,
              53.858532
            ],
            [
              -166.320004,
              53.869527
            ],
            [
              -166.250935,
              53.876851
            ],
            [
              -166.236513,
              53.881343
            ],
            [
              -166.211207,
              53.912334
            ],
            [
              -166.208767,
              53.92911
            ],
            [
              -166.210964,
              53.933557
            ],
            [
              -166.264519,
              53.97755
            ],
            [
              -166.279407,
              53.982532
            ],
            [
              -166.319895,
              53.960126
            ],
            [
              -166.359925,
              53.977136
            ],
            [
              -166.354341,
              53.995515
            ],
            [
              -166.354614,
              53.999039
            ],
            [
              -166.357117,
              54.002343
            ],
            [
              -166.36746,
              54.008903
            ],
            [
              -166.373689,
              54.01003
            ],
            [
              -166.437083,
              53.955644
            ],
            [
              -166.435153,
              53.920415
            ],
            [
              -166.436526,
              53.916151
            ],
            [
              -166.443699,
              53.909727
            ],
            [
              -166.487847,
              53.895448
            ],
            [
              -166.508388,
              53.923949
            ],
            [
              -166.535959,
              53.918
            ],
            [
              -166.551765,
              53.903229
            ],
            [
              -166.560546,
              53.878775
            ],
            [
              -166.57509,
              53.879236
            ],
            [
              -166.597182,
              53.88399
            ],
            [
              -166.619003,
              53.893514
            ],
            [
              -166.640466,
              53.912519
            ],
            [
              -166.646786,
              53.923785
            ],
            [
              -166.621979,
              53.953744
            ],
            [
              -166.605438,
              53.955354
            ],
            [
              -166.587393,
              53.959831
            ],
            [
              -166.599947,
              53.983695
            ],
            [
              -166.619754,
              54.001264
            ],
            [
              -166.636936,
              54.012
            ],
            [
              -166.644627,
              54.014495
            ],
            [
              -166.67664,
              54.017419
            ],
            [
              -166.728918,
              54.003111
            ],
            [
              -166.742587,
              54.015501
            ],
            [
              -166.746095,
              54.016936
            ],
            [
              -166.751681,
              54.01605
            ],
            [
              -166.818635,
              53.993198
            ],
            [
              -166.879488,
              53.988716
            ],
            [
              -166.930452,
              53.976091
            ],
            [
              -167.031252,
              53.945204
            ],
            [
              -167.058168,
              53.929778
            ],
            [
              -167.140992,
              53.866774
            ],
            [
              -167.141966,
              53.826932
            ],
            [
              -167.075859,
              53.786272
            ],
            [
              -167.024981,
              53.757241
            ],
            [
              -167.016863,
              53.754936
            ],
            [
              -167.005778,
              53.755446
            ],
            [
              -166.992846,
              53.762604
            ],
            [
              -166.983294,
              53.771348
            ],
            [
              -166.975635,
              53.775254
            ],
            [
              -166.960681,
              53.776841
            ],
            [
              -166.942766,
              53.769562
            ],
            [
              -166.856491,
              53.747301
            ],
            [
              -166.787318,
              53.734577
            ],
            [
              -166.779991,
              53.719126
            ],
            [
              -166.832464,
              53.707994
            ],
            [
              -166.832346,
              53.690687
            ],
            [
              -166.805874,
              53.665733
            ],
            [
              -166.832725,
              53.657376
            ],
            [
              -166.861769,
              53.659234
            ],
            [
              -166.859022,
              53.674439
            ],
            [
              -166.894976,
              53.717746
            ],
            [
              -166.923324,
              53.719719
            ],
            [
              -166.999282,
              53.71852
            ],
            [
              -167.022385,
              53.715467
            ],
            [
              -167.041245,
              53.707929
            ],
            [
              -167.057695,
              53.698864
            ],
            [
              -167.067674,
              53.687267
            ],
            [
              -167.071823,
              53.66556
            ],
            [
              -167.030011,
              53.653559
            ],
            [
              -167.017863,
              53.648607
            ],
            [
              -167.008671,
              53.64204
            ],
            [
              -167.009635,
              53.635344
            ],
            [
              -167.062187,
              53.620058
            ],
            [
              -167.070082,
              53.619857
            ],
            [
              -167.084579,
              53.626502
            ],
            [
              -167.091377,
              53.633438
            ],
            [
              -167.107836,
              53.633056
            ],
            [
              -167.14043,
              53.626968
            ],
            [
              -167.159808,
              53.617308
            ],
            [
              -167.163196,
              53.613813
            ],
            [
              -167.16164,
              53.605909
            ],
            [
              -167.135695,
              53.551227
            ],
            [
              -167.131239,
              53.547267
            ],
            [
              -167.105816,
              53.540507
            ],
            [
              -167.102305,
              53.515077
            ],
            [
              -167.188033,
              53.524073
            ],
            [
              -167.182685,
              53.490643
            ],
            [
              -167.193801,
              53.467007
            ],
            [
              -167.199966,
              53.463039
            ],
            [
              -167.217606,
              53.465389
            ],
            [
              -167.226182,
              53.468692
            ],
            [
              -167.267902,
              53.478115
            ],
            [
              -167.278827,
              53.478565
            ],
            [
              -167.30129,
              53.466006
            ],
            [
              -167.306517,
              53.441475
            ],
            [
              -167.343,
              53.455489
            ],
            [
              -167.332792,
              53.433107
            ],
            [
              -167.355624,
              53.424498
            ],
            [
              -167.373527,
              53.432776
            ],
            [
              -167.393985,
              53.439752
            ],
            [
              -167.457366,
              53.442793
            ],
            [
              -167.473328,
              53.438001
            ],
            [
              -167.474457,
              53.431782
            ],
            [
              -167.488252,
              53.420001
            ],
            [
              -167.622089,
              53.385329
            ],
            [
              -167.653113,
              53.392276
            ],
            [
              -167.694484,
              53.388034
            ],
            [
              -167.710446,
              53.381326
            ],
            [
              -167.790928,
              53.33552
            ],
            [
              -167.852333,
              53.315599
            ]
          ]
        ],
        [
          [
            [
              -169.054243,
              52.863266
            ],
            [
              -169.102465,
              52.824349
            ],
            [
              -169.041338,
              52.839348
            ],
            [
              -168.907003,
              52.884006
            ],
            [
              -168.808854,
              52.926102
            ],
            [
              -168.785954,
              52.90464
            ],
            [
              -168.755531,
              52.907507
            ],
            [
              -168.741851,
              52.951442
            ],
            [
              -168.688468,
              52.9664
            ],
            [
              -168.625257,
              52.998214
            ],
            [
              -168.613964,
              53.008776
            ],
            [
              -168.587808,
              53.027175
            ],
            [
              -168.578895,
              53.029915
            ],
            [
              -168.553195,
              53.033296
            ],
            [
              -168.546059,
              53.02958
            ],
            [
              -168.527404,
              53.028588
            ],
            [
              -168.49749,
              53.035403
            ],
            [
              -168.457103,
              53.055839
            ],
            [
              -168.451161,
              53.075131
            ],
            [
              -168.442859,
              53.085562
            ],
            [
              -168.433734,
              53.093934
            ],
            [
              -168.412522,
              53.110683
            ],
            [
              -168.392379,
              53.123609
            ],
            [
              -168.37315,
              53.128891
            ],
            [
              -168.344468,
              53.155215
            ],
            [
              -168.341678,
              53.185911
            ],
            [
              -168.312376,
              53.215231
            ],
            [
              -168.296229,
              53.227235
            ],
            [
              -168.270744,
              53.242811
            ],
            [
              -168.226915,
              53.254822
            ],
            [
              -168.211705,
              53.256184
            ],
            [
              -168.158943,
              53.26771
            ],
            [
              -168.03976,
              53.304276
            ],
            [
              -168.009301,
              53.317263
            ],
            [
              -167.988487,
              53.329578
            ],
            [
              -167.959096,
              53.341788
            ],
            [
              -167.878128,
              53.366902
            ],
            [
              -167.872879,
              53.36736
            ],
            [
              -167.852217,
              53.378294
            ],
            [
              -167.842328,
              53.386489
            ],
            [
              -167.839887,
              53.394432
            ],
            [
              -167.83952,
              53.410325
            ],
            [
              -167.8448,
              53.417497
            ],
            [
              -167.851698,
              53.421236
            ],
            [
              -167.856837,
              53.428609
            ],
            [
              -167.858337,
              53.43791
            ],
            [
              -167.853225,
              53.445469
            ],
            [
              -167.843611,
              53.453893
            ],
            [
              -167.808117,
              53.473861
            ],
            [
              -167.788066,
              53.492411
            ],
            [
              -167.784099,
              53.501048
            ],
            [
              -167.786387,
              53.513896
            ],
            [
              -167.789164,
              53.519329
            ],
            [
              -167.791026,
              53.521076
            ],
            [
              -167.796866,
              53.521113
            ],
            [
              -167.816998,
              53.517947
            ],
            [
              -167.888901,
              53.519691
            ],
            [
              -167.901871,
              53.520508
            ],
            [
              -167.938981,
              53.526907
            ],
            [
              -167.965038,
              53.538913
            ],
            [
              -167.965714,
              53.54344
            ],
            [
              -167.960861,
              53.55255
            ],
            [
              -167.962723,
              53.554069
            ],
            [
              -167.981038,
              53.561714
            ],
            [
              -168.004624,
              53.566053
            ],
            [
              -168.14462,
              53.545342
            ],
            [
              -168.200443,
              53.534079
            ],
            [
              -168.238321,
              53.521902
            ],
            [
              -168.239572,
              53.518491
            ],
            [
              -168.295793,
              53.489062
            ],
            [
              -168.315847,
              53.481729
            ],
            [
              -168.342127,
              53.475992
            ],
            [
              -168.386886,
              53.431496
            ],
            [
              -168.409588,
              53.410708
            ],
            [
              -168.385107,
              53.388459
            ],
            [
              -168.406531,
              53.346393
            ],
            [
              -168.43502,
              53.335518
            ],
            [
              -168.420521,
              53.322743
            ],
            [
              -168.375674,
              53.318291
            ],
            [
              -168.371218,
              53.316575
            ],
            [
              -168.365388,
              53.309105
            ],
            [
              -168.343994,
              53.26215
            ],
            [
              -168.361758,
              53.252253
            ],
            [
              -168.366519,
              53.252024
            ],
            [
              -168.412851,
              53.257859
            ],
            [
              -168.445083,
              53.26533
            ],
            [
              -168.490957,
              53.264009
            ],
            [
              -168.501365,
              53.25734
            ],
            [
              -168.524991,
              53.252311
            ],
            [
              -168.539398,
              53.25167
            ],
            [
              -168.599732,
              53.273629
            ],
            [
              -168.617143,
              53.260985
            ],
            [
              -168.763331,
              53.182812
            ],
            [
              -168.788756,
              53.160749
            ],
            [
              -168.792327,
              53.15572
            ],
            [
              -168.799469,
              53.143794
            ],
            [
              -168.804901,
              53.120015
            ],
            [
              -168.80203,
              53.108226
            ],
            [
              -168.789424,
              53.10097
            ],
            [
              -168.776176,
              53.097766
            ],
            [
              -168.768544,
              53.093684
            ],
            [
              -168.759691,
              53.081461
            ],
            [
              -168.763689,
              53.070961
            ],
            [
              -168.785236,
              53.045038
            ],
            [
              -168.861061,
              53.016384
            ],
            [
              -168.870687,
              52.996361
            ],
            [
              -168.861078,
              52.968046
            ],
            [
              -168.898045,
              52.937136
            ],
            [
              -168.95946,
              52.936739
            ],
            [
              -168.973127,
              52.916107
            ],
            [
              -168.958983,
              52.886048
            ],
            [
              -168.97171,
              52.878028
            ],
            [
              -168.992403,
              52.87344
            ],
            [
              -169.038767,
              52.869662
            ],
            [
              -169.054243,
              52.863266
            ]
          ]
        ],
        [
          [
            [
              -169.268127,
              52.772402
            ],
            [
              -169.261765,
              52.754897
            ],
            [
              -169.197,
              52.764546
            ],
            [
              -169.170371,
              52.776663
            ],
            [
              -169.15305,
              52.801265
            ],
            [
              -169.163985,
              52.814647
            ],
            [
              -169.213248,
              52.798023
            ],
            [
              -169.268127,
              52.772402
            ]
          ]
        ],
        [
          [
            [
              -169.76274,
              52.97805
            ],
            [
              -169.760725,
              52.971556
            ],
            [
              -169.758008,
              52.967246
            ],
            [
              -169.741096,
              52.951512
            ],
            [
              -169.721744,
              52.947117
            ],
            [
              -169.698274,
              52.958267
            ],
            [
              -169.666078,
              52.997068
            ],
            [
              -169.663576,
              53.021258
            ],
            [
              -169.66493,
              53.023973
            ],
            [
              -169.680033,
              53.035075
            ],
            [
              -169.698128,
              53.033779
            ],
            [
              -169.742538,
              53.024072
            ],
            [
              -169.745743,
              53.02147
            ],
            [
              -169.76274,
              52.97805
            ]
          ]
        ],
        [
          [
            [
              -169.789659,
              56.618217
            ],
            [
              -169.785692,
              56.613245
            ],
            [
              -169.75575,
              56.591922
            ],
            [
              -169.683639,
              56.58334
            ],
            [
              -169.675327,
              56.578414
            ],
            [
              -169.671324,
              56.567328
            ],
            [
              -169.672818,
              56.560866
            ],
            [
              -169.667749,
              56.554535
            ],
            [
              -169.657736,
              56.547319
            ],
            [
              -169.650135,
              56.54423
            ],
            [
              -169.640735,
              56.542162
            ],
            [
              -169.582624,
              56.536939
            ],
            [
              -169.568984,
              56.540935
            ],
            [
              -169.490133,
              56.583482
            ],
            [
              -169.47155,
              56.598864
            ],
            [
              -169.473138,
              56.601741
            ],
            [
              -169.507415,
              56.610702
            ],
            [
              -169.528659,
              56.612181
            ],
            [
              -169.553937,
              56.608682
            ],
            [
              -169.611548,
              56.606924
            ],
            [
              -169.679305,
              56.611593
            ],
            [
              -169.763506,
              56.620739
            ],
            [
              -169.789659,
              56.618217
            ]
          ]
        ],
        [
          [
            [
              -169.775534,
              53.079216
            ],
            [
              -169.799302,
              53.05708
            ],
            [
              -169.768879,
              53.046222
            ],
            [
              -169.748082,
              53.046293
            ],
            [
              -169.740714,
              53.063579
            ],
            [
              -169.76258,
              53.080501
            ],
            [
              -169.775534,
              53.079216
            ]
          ]
        ],
        [
          [
            [
              -170.004218,
              52.846743
            ],
            [
              -170.012487,
              52.831161
            ],
            [
              -169.995422,
              52.804676
            ],
            [
              -169.962883,
              52.789882
            ],
            [
              -169.951498,
              52.788615
            ],
            [
              -169.927446,
              52.792675
            ],
            [
              -169.897078,
              52.802131
            ],
            [
              -169.886671,
              52.808563
            ],
            [
              -169.879866,
              52.816088
            ],
            [
              -169.838232,
              52.81728
            ],
            [
              -169.750136,
              52.790576
            ],
            [
              -169.72978,
              52.774386
            ],
            [
              -169.703873,
              52.777117
            ],
            [
              -169.704105,
              52.793938
            ],
            [
              -169.683482,
              52.826618
            ],
            [
              -169.666512,
              52.864349
            ],
            [
              -169.704736,
              52.886272
            ],
            [
              -169.749177,
              52.893269
            ],
            [
              -169.773504,
              52.89445
            ],
            [
              -169.818139,
              52.878446
            ],
            [
              -169.852185,
              52.881804
            ],
            [
              -169.870011,
              52.874776
            ],
            [
              -169.860214,
              52.858377
            ],
            [
              -169.905631,
              52.85324
            ],
            [
              -169.943521,
              52.861099
            ],
            [
              -169.975345,
              52.858884
            ],
            [
              -169.990149,
              52.856266
            ],
            [
              -170.004218,
              52.846743
            ]
          ]
        ],
        [
          [
            [
              -170.112853,
              52.902043
            ],
            [
              -170.113189,
              52.891078
            ],
            [
              -170.095331,
              52.870851
            ],
            [
              -170.050274,
              52.857433
            ],
            [
              -170.015514,
              52.87026
            ],
            [
              -170.002368,
              52.880239
            ],
            [
              -169.999094,
              52.884034
            ],
            [
              -169.996712,
              52.891475
            ],
            [
              -169.995982,
              52.902378
            ],
            [
              -170.002071,
              52.910043
            ],
            [
              -170.020493,
              52.917171
            ],
            [
              -170.04656,
              52.923853
            ],
            [
              -170.083985,
              52.92364
            ],
            [
              -170.092221,
              52.919387
            ],
            [
              -170.112853,
              52.902043
            ]
          ]
        ],
        [
          [
            [
              -170.185684,
              52.723007
            ],
            [
              -170.184564,
              52.721937
            ],
            [
              -170.170646,
              52.717359
            ],
            [
              -170.114087,
              52.716172
            ],
            [
              -170.077734,
              52.720416
            ],
            [
              -170.070287,
              52.724301
            ],
            [
              -170.055363,
              52.745887
            ],
            [
              -170.052922,
              52.758745
            ],
            [
              -170.053443,
              52.769076
            ],
            [
              -170.061868,
              52.773525
            ],
            [
              -170.128714,
              52.787425
            ],
            [
              -170.170683,
              52.784918
            ],
            [
              -170.185684,
              52.723007
            ]
          ]
        ],
        [
          [
            [
              -170.423548,
              57.169327
            ],
            [
              -170.421867,
              57.161202
            ],
            [
              -170.359817,
              57.156118
            ],
            [
              -170.32484,
              57.156769
            ],
            [
              -170.303963,
              57.15491
            ],
            [
              -170.290793,
              57.145052
            ],
            [
              -170.286318,
              57.128169
            ],
            [
              -170.170848,
              57.1811
            ],
            [
              -170.150813,
              57.223168
            ],
            [
              -170.161647,
              57.229656
            ],
            [
              -170.239557,
              57.214658
            ],
            [
              -170.267664,
              57.210649
            ],
            [
              -170.291916,
              57.212056
            ],
            [
              -170.311707,
              57.219122
            ],
            [
              -170.33188,
              57.217488
            ],
            [
              -170.390121,
              57.206248
            ],
            [
              -170.402772,
              57.201933
            ],
            [
              -170.418919,
              57.192844
            ],
            [
              -170.42041,
              57.19176
            ],
            [
              -170.423548,
              57.169327
            ]
          ]
        ],
        [
          [
            [
              -170.833364,
              52.599985
            ],
            [
              -170.841936,
              52.558171
            ],
            [
              -170.788495,
              52.54024
            ],
            [
              -170.777143,
              52.546664
            ],
            [
              -170.767378,
              52.558254
            ],
            [
              -170.735824,
              52.580823
            ],
            [
              -170.696488,
              52.598364
            ],
            [
              -170.683854,
              52.602485
            ],
            [
              -170.674453,
              52.603385
            ],
            [
              -170.668075,
              52.600677
            ],
            [
              -170.665266,
              52.59526
            ],
            [
              -170.659041,
              52.593811
            ],
            [
              -170.635419,
              52.595711
            ],
            [
              -170.603862,
              52.601732
            ],
            [
              -170.56361,
              52.640706
            ],
            [
              -170.557324,
              52.652105
            ],
            [
              -170.559523,
              52.667907
            ],
            [
              -170.562734,
              52.674785
            ],
            [
              -170.579913,
              52.682029
            ],
            [
              -170.633753,
              52.697469
            ],
            [
              -170.671545,
              52.698082
            ],
            [
              -170.727717,
              52.679978
            ],
            [
              -170.817943,
              52.636275
            ],
            [
              -170.820641,
              52.633091
            ],
            [
              -170.833364,
              52.599985
            ]
          ]
        ],
        [
          [
            [
              -171.15158,
              52.584536
            ],
            [
              -171.176774,
              52.558678
            ],
            [
              -171.149312,
              52.550171
            ],
            [
              -171.117117,
              52.557522
            ],
            [
              -171.118068,
              52.576159
            ],
            [
              -171.13114,
              52.584391
            ],
            [
              -171.15158,
              52.584536
            ]
          ]
        ],
        [
          [
            [
              -171.312658,
              52.493502
            ],
            [
              -171.313083,
              52.472932
            ],
            [
              -171.30417,
              52.449952
            ],
            [
              -171.299348,
              52.448716
            ],
            [
              -171.294554,
              52.451105
            ],
            [
              -171.252316,
              52.449466
            ],
            [
              -171.236843,
              52.450527
            ],
            [
              -171.214565,
              52.4633
            ],
            [
              -171.208919,
              52.469023
            ],
            [
              -171.194639,
              52.498039
            ],
            [
              -171.196013,
              52.500106
            ],
            [
              -171.252053,
              52.529954
            ],
            [
              -171.277165,
              52.522634
            ],
            [
              -171.291387,
              52.514813
            ],
            [
              -171.3075,
              52.501514
            ],
            [
              -171.312658,
              52.493502
            ]
          ]
        ],
        [
          [
            [
              -172.620261,
              52.298751
            ],
            [
              -172.633153,
              52.266215
            ],
            [
              -172.616839,
              52.255317
            ],
            [
              -172.608935,
              52.253014
            ],
            [
              -172.528095,
              52.254336
            ],
            [
              -172.414419,
              52.27674
            ],
            [
              -172.313133,
              52.320697
            ],
            [
              -172.301445,
              52.329951
            ],
            [
              -172.302393,
              52.342357
            ],
            [
              -172.311427,
              52.356456
            ],
            [
              -172.326444,
              52.366472
            ],
            [
              -172.405243,
              52.389442
            ],
            [
              -172.448182,
              52.391439
            ],
            [
              -172.47461,
              52.383763
            ],
            [
              -172.568051,
              52.34942
            ],
            [
              -172.574154,
              52.345323
            ],
            [
              -172.620261,
              52.298751
            ]
          ]
        ],
        [
          [
            [
              -174.048451,
              52.132911
            ],
            [
              -174.052296,
              52.1304
            ],
            [
              -174.04675,
              52.122403
            ],
            [
              -174.035082,
              52.112952
            ],
            [
              -174.011338,
              52.098862
            ],
            [
              -174.001866,
              52.097641
            ],
            [
              -173.992274,
              52.10059
            ],
            [
              -173.97133,
              52.099428
            ],
            [
              -173.935561,
              52.064731
            ],
            [
              -173.937239,
              52.057513
            ],
            [
              -173.901075,
              52.049435
            ],
            [
              -173.831555,
              52.040763
            ],
            [
              -173.820692,
              52.043312
            ],
            [
              -173.816999,
              52.048538
            ],
            [
              -173.799574,
              52.05365
            ],
            [
              -173.712223,
              52.056158
            ],
            [
              -173.612014,
              52.051148
            ],
            [
              -173.548385,
              52.029308
            ],
            [
              -173.513047,
              52.02531
            ],
            [
              -173.479534,
              52.026187
            ],
            [
              -173.423442,
              52.042271
            ],
            [
              -173.39397,
              52.028674
            ],
            [
              -173.313705,
              52.058701
            ],
            [
              -173.206837,
              52.063532
            ],
            [
              -173.155577,
              52.059665
            ],
            [
              -173.107933,
              52.078828
            ],
            [
              -173.04754,
              52.073329
            ],
            [
              -173.033166,
              52.074611
            ],
            [
              -172.960751,
              52.087018
            ],
            [
              -172.958523,
              52.093648
            ],
            [
              -173.019588,
              52.097881
            ],
            [
              -173.06643,
              52.09633
            ],
            [
              -173.107373,
              52.09928
            ],
            [
              -173.119255,
              52.107628
            ],
            [
              -173.124504,
              52.10942
            ],
            [
              -173.173206,
              52.109136
            ],
            [
              -173.238295,
              52.100443
            ],
            [
              -173.273466,
              52.109323
            ],
            [
              -173.357498,
              52.096129
            ],
            [
              -173.372574,
              52.10275
            ],
            [
              -173.375595,
              52.106343
            ],
            [
              -173.375229,
              52.108228
            ],
            [
              -173.467877,
              52.116423
            ],
            [
              -173.49702,
              52.103641
            ],
            [
              -173.514171,
              52.108348
            ],
            [
              -173.534914,
              52.128587
            ],
            [
              -173.51376,
              52.15251
            ],
            [
              -173.529923,
              52.159364
            ],
            [
              -173.557256,
              52.142884
            ],
            [
              -173.59056,
              52.145393
            ],
            [
              -173.602446,
              52.153773
            ],
            [
              -173.624771,
              52.152213
            ],
            [
              -173.654404,
              52.146192
            ],
            [
              -173.721266,
              52.130207
            ],
            [
              -173.771643,
              52.131651
            ],
            [
              -173.802339,
              52.10639
            ],
            [
              -173.818277,
              52.105363
            ],
            [
              -173.824087,
              52.105892
            ],
            [
              -173.830906,
              52.11045
            ],
            [
              -173.890733,
              52.12547
            ],
            [
              -173.874558,
              52.148135
            ],
            [
              -173.921619,
              52.132965
            ],
            [
              -173.984245,
              52.127855
            ],
            [
              -174.036854,
              52.135878
            ],
            [
              -174.048451,
              52.132911
            ]
          ]
        ],
        [
          [
            [
              -175.32707,
              52.027032
            ],
            [
              -175.341624,
              52.021588
            ],
            [
              -175.323322,
              52.007488
            ],
            [
              -175.302683,
              52.011499
            ],
            [
              -175.300639,
              52.01497
            ],
            [
              -175.292821,
              52.01879
            ],
            [
              -175.27485,
              52.018619
            ],
            [
              -175.211744,
              52.006001
            ],
            [
              -175.155673,
              52.011512
            ],
            [
              -175.104889,
              52.003548
            ],
            [
              -175.09551,
              52.000797
            ],
            [
              -175.014807,
              52.007
            ],
            [
              -175.014748,
              52.020584
            ],
            [
              -175.000792,
              52.028354
            ],
            [
              -174.967907,
              52.037203
            ],
            [
              -174.885554,
              52.043001
            ],
            [
              -174.783189,
              52.032293
            ],
            [
              -174.736592,
              52.007308
            ],
            [
              -174.71461,
              52.009863
            ],
            [
              -174.615943,
              52.032665
            ],
            [
              -174.593635,
              52.045247
            ],
            [
              -174.580676,
              52.040453
            ],
            [
              -174.556278,
              52.036733
            ],
            [
              -174.508822,
              52.048623
            ],
            [
              -174.507816,
              52.054955
            ],
            [
              -174.453651,
              52.049227
            ],
            [
              -174.415042,
              52.028381
            ],
            [
              -174.382661,
              52.081658
            ],
            [
              -174.365667,
              52.097238
            ],
            [
              -174.348463,
              52.109245
            ],
            [
              -174.334424,
              52.115198
            ],
            [
              -174.302947,
              52.111325
            ],
            [
              -174.278279,
              52.089489
            ],
            [
              -174.218124,
              52.093409
            ],
            [
              -174.218469,
              52.10488
            ],
            [
              -174.206353,
              52.116554
            ],
            [
              -174.168568,
              52.11071
            ],
            [
              -174.142262,
              52.125452
            ],
            [
              -174.11437,
              52.117107
            ],
            [
              -174.109089,
              52.113117
            ],
            [
              -174.102161,
              52.104534
            ],
            [
              -174.09447,
              52.104274
            ],
            [
              -174.0891,
              52.107251
            ],
            [
              -174.080677,
              52.128026
            ],
            [
              -174.082814,
              52.132069
            ],
            [
              -174.090169,
              52.139119
            ],
            [
              -174.135217,
              52.168514
            ],
            [
              -174.175044,
              52.181835
            ],
            [
              -174.1901,
              52.19032
            ],
            [
              -174.196836,
              52.195856
            ],
            [
              -174.200389,
              52.211861
            ],
            [
              -174.198624,
              52.219244
            ],
            [
              -174.182857,
              52.232762
            ],
            [
              -174.177679,
              52.233638
            ],
            [
              -174.106533,
              52.228392
            ],
            [
              -174.084042,
              52.223677
            ],
            [
              -174.060451,
              52.225326
            ],
            [
              -174.036222,
              52.245011
            ],
            [
              -173.987917,
              52.295345
            ],
            [
              -173.986421,
              52.298565
            ],
            [
              -173.985203,
              52.3176
            ],
            [
              -173.989415,
              52.325275
            ],
            [
              -174.016822,
              52.348537
            ],
            [
              -174.068248,
              52.390331
            ],
            [
              -174.155774,
              52.416041
            ],
            [
              -174.185347,
              52.417788
            ],
            [
              -174.324935,
              52.378095
            ],
            [
              -174.329818,
              52.373548
            ],
            [
              -174.330494,
              52.366439
            ],
            [
              -174.320813,
              52.355726
            ],
            [
              -174.3177,
              52.344869
            ],
            [
              -174.331065,
              52.328465
            ],
            [
              -174.340679,
              52.322284
            ],
            [
              -174.358624,
              52.31419
            ],
            [
              -174.367047,
              52.314105
            ],
            [
              -174.384199,
              52.321139
            ],
            [
              -174.432846,
              52.328004
            ],
            [
              -174.443132,
              52.325654
            ],
            [
              -174.45366,
              52.319367
            ],
            [
              -174.455979,
              52.31369
            ],
            [
              -174.451554,
              52.305557
            ],
            [
              -174.408277,
              52.289872
            ],
            [
              -174.368754,
              52.280405
            ],
            [
              -174.349404,
              52.281336
            ],
            [
              -174.346089,
              52.285036
            ],
            [
              -174.323471,
              52.28399
            ],
            [
              -174.301818,
              52.278949
            ],
            [
              -174.255832,
              52.274152
            ],
            [
              -174.249848,
              52.243694
            ],
            [
              -174.299044,
              52.21467
            ],
            [
              -174.328599,
              52.211647
            ],
            [
              -174.360631,
              52.212994
            ],
            [
              -174.400139,
              52.219053
            ],
            [
              -174.453746,
              52.218823
            ],
            [
              -174.462962,
              52.213031
            ],
            [
              -174.457804,
              52.202831
            ],
            [
              -174.405464,
              52.18356
            ],
            [
              -174.404588,
              52.18133
            ],
            [
              -174.41529,
              52.169376
            ],
            [
              -174.424054,
              52.169053
            ],
            [
              -174.455707,
              52.176802
            ],
            [
              -174.465189,
              52.180711
            ],
            [
              -174.49688,
              52.179151
            ],
            [
              -174.527081,
              52.17472
            ],
            [
              -174.55349,
              52.180137
            ],
            [
              -174.56478,
              52.175035
            ],
            [
              -174.55467,
              52.160405
            ],
            [
              -174.55708,
              52.153637
            ],
            [
              -174.568402,
              52.138426
            ],
            [
              -174.597818,
              52.141426
            ],
            [
              -174.604871,
              52.122124
            ],
            [
              -174.656294,
              52.107962
            ],
            [
              -174.691701,
              52.106696
            ],
            [
              -174.715205,
              52.127375
            ],
            [
              -174.751121,
              52.117351
            ],
            [
              -174.744228,
              52.098374
            ],
            [
              -174.786809,
              52.091324
            ],
            [
              -174.839715,
              52.091338
            ],
            [
              -174.843103,
              52.102754
            ],
            [
              -174.878517,
              52.102608
            ],
            [
              -174.905409,
              52.116509
            ],
            [
              -174.920042,
              52.109274
            ],
            [
              -174.927549,
              52.101415
            ],
            [
              -174.922299,
              52.09158
            ],
            [
              -174.962181,
              52.082018
            ],
            [
              -174.980497,
              52.061471
            ],
            [
              -174.992309,
              52.058603
            ],
            [
              -175.000209,
              52.077928
            ],
            [
              -175.033247,
              52.076175
            ],
            [
              -175.044344,
              52.057519
            ],
            [
              -175.092867,
              52.034794
            ],
            [
              -175.113721,
              52.046308
            ],
            [
              -175.11768,
              52.053234
            ],
            [
              -175.117115,
              52.054499
            ],
            [
              -175.132635,
              52.059223
            ],
            [
              -175.156744,
              52.057642
            ],
            [
              -175.1959,
              52.051407
            ],
            [
              -175.32707,
              52.027032
            ]
          ]
        ],
        [
          [
            [
              -175.50991,
              51.982415
            ],
            [
              -175.504296,
              51.968511
            ],
            [
              -175.463417,
              51.969995
            ],
            [
              -175.424859,
              51.972332
            ],
            [
              -175.428127,
              51.990298
            ],
            [
              -175.450446,
              51.996663
            ],
            [
              -175.481113,
              51.989397
            ],
            [
              -175.50991,
              51.982415
            ]
          ]
        ],
        [
          [
            [
              -175.539911,
              52.183441
            ],
            [
              -175.538933,
              52.171277
            ],
            [
              -175.523012,
              52.156525
            ],
            [
              -175.498741,
              52.155393
            ],
            [
              -175.483834,
              52.167573
            ],
            [
              -175.497317,
              52.180792
            ],
            [
              -175.515653,
              52.192162
            ],
            [
              -175.539911,
              52.183441
            ]
          ]
        ],
        [
          [
            [
              -175.742364,
              51.966626
            ],
            [
              -175.745335,
              51.946779
            ],
            [
              -175.722756,
              51.93176
            ],
            [
              -175.692271,
              51.944959
            ],
            [
              -175.644031,
              51.952621
            ],
            [
              -175.598496,
              51.947503
            ],
            [
              -175.577611,
              51.94957
            ],
            [
              -175.578365,
              51.963844
            ],
            [
              -175.580966,
              51.972459
            ],
            [
              -175.61813,
              51.971516
            ],
            [
              -175.670466,
              51.972776
            ],
            [
              -175.724182,
              51.975046
            ],
            [
              -175.742364,
              51.966626
            ]
          ]
        ],
        [
          [
            [
              -175.954423,
              51.983002
            ],
            [
              -175.948712,
              51.975388
            ],
            [
              -175.87828,
              51.963967
            ],
            [
              -175.864003,
              51.943979
            ],
            [
              -175.815462,
              51.921136
            ],
            [
              -175.760259,
              51.917329
            ],
            [
              -175.760259,
              51.924943
            ],
            [
              -175.785005,
              51.93922
            ],
            [
              -175.794523,
              51.958256
            ],
            [
              -175.807848,
              51.989665
            ],
            [
              -175.81451,
              51.994424
            ],
            [
              -175.912544,
              51.995376
            ],
            [
              -175.954423,
              51.983002
            ]
          ]
        ],
        [
          [
            [
              -176.200254,
              52.078731
            ],
            [
              -176.211188,
              52.064706
            ],
            [
              -176.189559,
              52.025383
            ],
            [
              -176.183023,
              51.998904
            ],
            [
              -176.15664,
              51.996783
            ],
            [
              -176.168643,
              51.948025
            ],
            [
              -176.103638,
              51.929122
            ],
            [
              -176.038276,
              51.930514
            ],
            [
              -176.026629,
              51.934691
            ],
            [
              -176.002861,
              51.945168
            ],
            [
              -175.993948,
              51.960109
            ],
            [
              -176.022945,
              51.982366
            ],
            [
              -176.03578,
              51.99488
            ],
            [
              -176.044693,
              52.008342
            ],
            [
              -176.020331,
              52.017704
            ],
            [
              -176.001554,
              52.026553
            ],
            [
              -175.98147,
              52.028308
            ],
            [
              -175.974934,
              52.040956
            ],
            [
              -176.008684,
              52.053161
            ],
            [
              -176.006902,
              52.074422
            ],
            [
              -176.036493,
              52.086473
            ],
            [
              -176.055983,
              52.109469
            ],
            [
              -176.091872,
              52.110491
            ],
            [
              -176.14951,
              52.11757
            ],
            [
              -176.182547,
              52.10071
            ],
            [
              -176.200254,
              52.078731
            ]
          ]
        ],
        [
          [
            [
              -176.236246,
              51.825965
            ],
            [
              -176.235544,
              51.823157
            ],
            [
              -176.216957,
              51.812714
            ],
            [
              -176.183142,
              51.807099
            ],
            [
              -176.186469,
              51.788533
            ],
            [
              -176.158185,
              51.768901
            ],
            [
              -176.14064,
              51.783584
            ],
            [
              -176.139622,
              51.802386
            ],
            [
              -176.123965,
              51.802745
            ],
            [
              -176.094962,
              51.79162
            ],
            [
              -176.102093,
              51.825861
            ],
            [
              -176.053725,
              51.816752
            ],
            [
              -176.023658,
              51.804629
            ],
            [
              -175.998464,
              51.801542
            ],
            [
              -176.016528,
              51.830488
            ],
            [
              -176.047892,
              51.846309
            ],
            [
              -175.983742,
              51.852352
            ],
            [
              -175.963041,
              51.846253
            ],
            [
              -175.954287,
              51.868381
            ],
            [
              -175.953251,
              51.881376
            ],
            [
              -175.957546,
              51.893455
            ],
            [
              -175.971562,
              51.888631
            ],
            [
              -175.984993,
              51.908445
            ],
            [
              -175.99265,
              51.912655
            ],
            [
              -176.020182,
              51.911373
            ],
            [
              -176.065288,
              51.902986
            ],
            [
              -176.111452,
              51.889748
            ],
            [
              -176.115489,
              51.887015
            ],
            [
              -176.078865,
              51.874778
            ],
            [
              -176.073431,
              51.870312
            ],
            [
              -176.072225,
              51.867938
            ],
            [
              -176.080442,
              51.858567
            ],
            [
              -176.099137,
              51.855533
            ],
            [
              -176.140908,
              51.859562
            ],
            [
              -176.161052,
              51.869685
            ],
            [
              -176.168775,
              51.87733
            ],
            [
              -176.169751,
              51.880138
            ],
            [
              -176.173871,
              51.882449
            ],
            [
              -176.206069,
              51.883089
            ],
            [
              -176.217544,
              51.874627
            ],
            [
              -176.236246,
              51.825965
            ]
          ]
        ],
        [
          [
            [
              -176.991322,
              51.629052
            ],
            [
              -176.987383,
              51.606872
            ],
            [
              -176.984331,
              51.602135
            ],
            [
              -176.954147,
              51.592568
            ],
            [
              -176.938917,
              51.590982
            ],
            [
              -176.930952,
              51.59247
            ],
            [
              -176.903184,
              51.635648
            ],
            [
              -176.863062,
              51.684921
            ],
            [
              -176.837514,
              51.682745
            ],
            [
              -176.814437,
              51.66025
            ],
            [
              -176.826252,
              51.640932
            ],
            [
              -176.823682,
              51.634011
            ],
            [
              -176.809,
              51.616235
            ],
            [
              -176.801675,
              51.613488
            ],
            [
              -176.751817,
              51.635017
            ],
            [
              -176.715424,
              51.620422
            ],
            [
              -176.722318,
              51.645845
            ],
            [
              -176.735912,
              51.662154
            ],
            [
              -176.713062,
              51.68333
            ],
            [
              -176.70266,
              51.685404
            ],
            [
              -176.667176,
              51.675848
            ],
            [
              -176.656005,
              51.658306
            ],
            [
              -176.631643,
              51.6572
            ],
            [
              -176.582933,
              51.691822
            ],
            [
              -176.520765,
              51.702812
            ],
            [
              -176.508168,
              51.729686
            ],
            [
              -176.474132,
              51.747208
            ],
            [
              -176.46705,
              51.726668
            ],
            [
              -176.425456,
              51.739769
            ],
            [
              -176.343756,
              51.73152
            ],
            [
              -176.289921,
              51.741678
            ],
            [
              -176.273792,
              51.772019
            ],
            [
              -176.268243,
              51.785498
            ],
            [
              -176.26649,
              51.817716
            ],
            [
              -176.281694,
              51.863919
            ],
            [
              -176.287188,
              51.870313
            ],
            [
              -176.290728,
              51.872136
            ],
            [
              -176.311573,
              51.872463
            ],
            [
              -176.398062,
              51.867842
            ],
            [
              -176.507989,
              51.84597
            ],
            [
              -176.517599,
              51.839557
            ],
            [
              -176.543309,
              51.838624
            ],
            [
              -176.576381,
              51.842275
            ],
            [
              -176.625463,
              51.859824
            ],
            [
              -176.623452,
              51.883205
            ],
            [
              -176.620015,
              51.89563
            ],
            [
              -176.616095,
              51.903013
            ],
            [
              -176.566275,
              51.914702
            ],
            [
              -176.558376,
              51.908725
            ],
            [
              -176.554661,
              51.909834
            ],
            [
              -176.544867,
              51.927245
            ],
            [
              -176.554398,
              51.99066
            ],
            [
              -176.560565,
              51.996732
            ],
            [
              -176.579975,
              52.003238
            ],
            [
              -176.589955,
              52.002741
            ],
            [
              -176.603598,
              51.997056
            ],
            [
              -176.627155,
              51.978294
            ],
            [
              -176.63051,
              51.970352
            ],
            [
              -176.656481,
              51.95231
            ],
            [
              -176.698771,
              51.964454
            ],
            [
              -176.72078,
              51.969518
            ],
            [
              -176.736549,
              51.969808
            ],
            [
              -176.774023,
              51.965895
            ],
            [
              -176.789558,
              51.957211
            ],
            [
              -176.810433,
              51.927089
            ],
            [
              -176.797799,
              51.908512
            ],
            [
              -176.762478,
              51.867878
            ],
            [
              -176.790163,
              51.817217
            ],
            [
              -176.856205,
              51.818366
            ],
            [
              -176.904302,
              51.811772
            ],
            [
              -176.911016,
              51.807597
            ],
            [
              -176.917088,
              51.797016
            ],
            [
              -176.918065,
              51.788003
            ],
            [
              -176.90503,
              51.771532
            ],
            [
              -176.882018,
              51.766628
            ],
            [
              -176.8707,
              51.731969
            ],
            [
              -176.870997,
              51.72941
            ],
            [
              -176.873924,
              51.724071
            ],
            [
              -176.896966,
              51.700424
            ],
            [
              -176.906884,
              51.696639
            ],
            [
              -176.930872,
              51.697195
            ],
            [
              -176.950128,
              51.686719
            ],
            [
              -176.976249,
              51.6664
            ],
            [
              -176.984489,
              51.657411
            ],
            [
              -176.991322,
              51.629052
            ]
          ]
        ],
        [
          [
            [
              -177.464905,
              51.913521
            ],
            [
              -177.479161,
              51.898036
            ],
            [
              -177.458978,
              51.884225
            ],
            [
              -177.429031,
              51.888289
            ],
            [
              -177.410078,
              51.899093
            ],
            [
              -177.418131,
              51.921266
            ],
            [
              -177.446756,
              51.927381
            ],
            [
              -177.464905,
              51.913521
            ]
          ]
        ],
        [
          [
            [
              -177.705261,
              51.70724
            ],
            [
              -177.707802,
              51.703268
            ],
            [
              -177.670951,
              51.66398
            ],
            [
              -177.651386,
              51.653604
            ],
            [
              -177.635883,
              51.659541
            ],
            [
              -177.640524,
              51.672084
            ],
            [
              -177.631523,
              51.696844
            ],
            [
              -177.616753,
              51.703978
            ],
            [
              -177.608055,
              51.705184
            ],
            [
              -177.570973,
              51.69822
            ],
            [
              -177.540393,
              51.698755
            ],
            [
              -177.490005,
              51.705106
            ],
            [
              -177.463577,
              51.713943
            ],
            [
              -177.444717,
              51.725419
            ],
            [
              -177.417678,
              51.730875
            ],
            [
              -177.39076,
              51.733525
            ],
            [
              -177.360408,
              51.727533
            ],
            [
              -177.342784,
              51.721395
            ],
            [
              -177.322977,
              51.711416
            ],
            [
              -177.316353,
              51.700811
            ],
            [
              -177.317939,
              51.696866
            ],
            [
              -177.317888,
              51.693447
            ],
            [
              -177.316501,
              51.690353
            ],
            [
              -177.296369,
              51.684245
            ],
            [
              -177.275121,
              51.68051
            ],
            [
              -177.261631,
              51.680846
            ],
            [
              -177.145675,
              51.707294
            ],
            [
              -177.122808,
              51.729355
            ],
            [
              -177.120581,
              51.739815
            ],
            [
              -177.13096,
              51.762772
            ],
            [
              -177.136977,
              51.814493
            ],
            [
              -177.128617,
              51.833835
            ],
            [
              -177.120377,
              51.839687
            ],
            [
              -177.08101,
              51.855497
            ],
            [
              -177.04509,
              51.898605
            ],
            [
              -177.054768,
              51.908944
            ],
            [
              -177.099266,
              51.936119
            ],
            [
              -177.154842,
              51.944381
            ],
            [
              -177.181271,
              51.943167
            ],
            [
              -177.191399,
              51.938001
            ],
            [
              -177.197506,
              51.931339
            ],
            [
              -177.199764,
              51.924816
            ],
            [
              -177.19912,
              51.883142
            ],
            [
              -177.200825,
              51.844605
            ],
            [
              -177.205675,
              51.820639
            ],
            [
              -177.21193,
              51.812331
            ],
            [
              -177.238175,
              51.79852
            ],
            [
              -177.281479,
              51.784075
            ],
            [
              -177.4612,
              51.750718
            ],
            [
              -177.497974,
              51.738624
            ],
            [
              -177.515591,
              51.724978
            ],
            [
              -177.536977,
              51.72147
            ],
            [
              -177.555197,
              51.721125
            ],
            [
              -177.597498,
              51.726464
            ],
            [
              -177.639983,
              51.736061
            ],
            [
              -177.697662,
              51.713123
            ],
            [
              -177.705261,
              51.70724
            ]
          ]
        ],
        [
          [
            [
              -178.224618,
              51.880675
            ],
            [
              -178.227822,
              51.873526
            ],
            [
              -178.224129,
              51.864881
            ],
            [
              -178.215124,
              51.857801
            ],
            [
              -178.172666,
              51.839985
            ],
            [
              -178.086074,
              51.808047
            ],
            [
              -178.08064,
              51.798739
            ],
            [
              -178.059335,
              51.786829
            ],
            [
              -178.039344,
              51.778925
            ],
            [
              -177.995272,
              51.781535
            ],
            [
              -177.965031,
              51.778162
            ],
            [
              -177.956998,
              51.772541
            ],
            [
              -177.950283,
              51.765682
            ],
            [
              -177.946649,
              51.752681
            ],
            [
              -177.947777,
              51.740381
            ],
            [
              -177.956443,
              51.722862
            ],
            [
              -177.962426,
              51.719772
            ],
            [
              -178.021818,
              51.706906
            ],
            [
              -178.104285,
              51.701539
            ],
            [
              -178.117864,
              51.677831
            ],
            [
              -178.109378,
              51.670461
            ],
            [
              -178.086304,
              51.663618
            ],
            [
              -178.069823,
              51.670676
            ],
            [
              -178.013038,
              51.629213
            ],
            [
              -177.963852,
              51.650231
            ],
            [
              -177.957443,
              51.647149
            ],
            [
              -177.953024,
              51.638175
            ],
            [
              -177.950665,
              51.620001
            ],
            [
              -177.944957,
              51.611539
            ],
            [
              -177.930123,
              51.601499
            ],
            [
              -177.909185,
              51.596671
            ],
            [
              -177.906072,
              51.59767
            ],
            [
              -177.903083,
              51.606497
            ],
            [
              -177.915445,
              51.630684
            ],
            [
              -177.92564,
              51.642481
            ],
            [
              -177.929023,
              51.65052
            ],
            [
              -177.928907,
              51.655368
            ],
            [
              -177.918806,
              51.67439
            ],
            [
              -177.902693,
              51.691581
            ],
            [
              -177.899416,
              51.692557
            ],
            [
              -177.887768,
              51.689483
            ],
            [
              -177.876811,
              51.681411
            ],
            [
              -177.86796,
              51.679374
            ],
            [
              -177.856332,
              51.681015
            ],
            [
              -177.841411,
              51.68956
            ],
            [
              -177.826997,
              51.705972
            ],
            [
              -177.827524,
              51.712086
            ],
            [
              -177.838054,
              51.717198
            ],
            [
              -177.842419,
              51.722645
            ],
            [
              -177.842267,
              51.73248
            ],
            [
              -177.813886,
              51.75428
            ],
            [
              -177.796308,
              51.770831
            ],
            [
              -177.800647,
              51.778294
            ],
            [
              -177.797719,
              51.793297
            ],
            [
              -177.735909,
              51.807991
            ],
            [
              -177.692118,
              51.813897
            ],
            [
              -177.685555,
              51.812745
            ],
            [
              -177.649208,
              51.820727
            ],
            [
              -177.625008,
              51.837529
            ],
            [
              -177.614511,
              51.853033
            ],
            [
              -177.615311,
              51.85508
            ],
            [
              -177.691714,
              51.843975
            ],
            [
              -177.734909,
              51.82585
            ],
            [
              -177.759641,
              51.831195
            ],
            [
              -177.796538,
              51.840594
            ],
            [
              -177.852285,
              51.826045
            ],
            [
              -177.859763,
              51.826944
            ],
            [
              -177.874964,
              51.849795
            ],
            [
              -177.921569,
              51.853883
            ],
            [
              -177.924315,
              51.857522
            ],
            [
              -177.913269,
              51.879748
            ],
            [
              -177.952094,
              51.915348
            ],
            [
              -177.963723,
              51.917919
            ],
            [
              -178.002345,
              51.909968
            ],
            [
              -178.061147,
              51.912539
            ],
            [
              -178.070548,
              51.917408
            ],
            [
              -178.090632,
              51.919399
            ],
            [
              -178.124786,
              51.920093
            ],
            [
              -178.145326,
              51.917216
            ],
            [
              -178.175023,
              51.911584
            ],
            [
              -178.19709,
              51.905464
            ],
            [
              -178.220742,
              51.884841
            ],
            [
              -178.224618,
              51.880675
            ]
          ]
        ],
        [
          [
            [
              -178.681285,
              51.608951
            ],
            [
              -178.68414,
              51.602289
            ],
            [
              -178.673671,
              51.588964
            ],
            [
              -178.642262,
              51.586108
            ],
            [
              -178.621322,
              51.588964
            ],
            [
              -178.591817,
              51.583253
            ],
            [
              -178.576589,
              51.583253
            ],
            [
              -178.567071,
              51.598481
            ],
            [
              -178.576589,
              51.606096
            ],
            [
              -178.597528,
              51.60324
            ],
            [
              -178.618467,
              51.609903
            ],
            [
              -178.638455,
              51.611806
            ],
            [
              -178.681285,
              51.608951
            ]
          ]
        ],
        [
          [
            [
              -178.857365,
              51.57659
            ],
            [
              -178.857365,
              51.572783
            ],
            [
              -178.825956,
              51.547085
            ],
            [
              -178.796451,
              51.541374
            ],
            [
              -178.734585,
              51.542326
            ],
            [
              -178.735537,
              51.550892
            ],
            [
              -178.761235,
              51.562314
            ],
            [
              -178.828811,
              51.575639
            ],
            [
              -178.857365,
              51.57659
            ]
          ]
        ],
        [
          [
            [
              -178.870118,
              51.795261
            ],
            [
              -178.873024,
              51.782623
            ],
            [
              -178.815757,
              51.749176
            ],
            [
              -178.808157,
              51.747078
            ],
            [
              -178.792409,
              51.746071
            ],
            [
              -178.776661,
              51.748612
            ],
            [
              -178.750414,
              51.757752
            ],
            [
              -178.733355,
              51.783947
            ],
            [
              -178.748283,
              51.809942
            ],
            [
              -178.767695,
              51.823179
            ],
            [
              -178.788541,
              51.832602
            ],
            [
              -178.811249,
              51.839018
            ],
            [
              -178.819459,
              51.839575
            ],
            [
              -178.828645,
              51.83615
            ],
            [
              -178.858248,
              51.820966
            ],
            [
              -178.870118,
              51.795261
            ]
          ]
        ],
        [
          [
            [
              -178.992094,
              51.381311
            ],
            [
              -178.990684,
              51.311648
            ],
            [
              -178.987236,
              51.311038
            ],
            [
              -178.979179,
              51.31438
            ],
            [
              -178.965171,
              51.322682
            ],
            [
              -178.95446,
              51.332731
            ],
            [
              -178.954338,
              51.339247
            ],
            [
              -178.908883,
              51.340582
            ],
            [
              -178.914207,
              51.363992
            ],
            [
              -178.926874,
              51.38364
            ],
            [
              -178.964323,
              51.402492
            ],
            [
              -178.977782,
              51.398929
            ],
            [
              -178.992094,
              51.381311
            ]
          ]
        ],
        [
          [
            [
              -179.137239,
              51.286006
            ],
            [
              -179.14734,
              51.276781
            ],
            [
              -179.136196,
              51.229216
            ],
            [
              -179.126856,
              51.219862
            ],
            [
              -179.097619,
              51.226135
            ],
            [
              -179.07232,
              51.250963
            ],
            [
              -179.069176,
              51.262874
            ],
            [
              -179.075466,
              51.284619
            ],
            [
              -179.094665,
              51.301229
            ],
            [
              -179.113495,
              51.300801
            ],
            [
              -179.137239,
              51.286006
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "02",
      "COUNTYFP": "122",
      "COUNTYNS": "01419972",
      "AFFGEOID": "0500000US02122",
      "GEOID": "02122",
      "NAME": "Kenai Peninsula",
      "LSAD": "04",
      "ALAND": 41499900917,
      "AWATER": 22525622024,
      "County_state": "Kenai Peninsula,02"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -150.772243,
              59.333014
            ],
            [
              -150.773195,
              59.308267
            ],
            [
              -150.762725,
              59.30446
            ],
            [
              -150.721799,
              59.292087
            ],
            [
              -150.680872,
              59.305412
            ],
            [
              -150.650415,
              59.343483
            ],
            [
              -150.618054,
              59.355856
            ],
            [
              -150.609488,
              59.386314
            ],
            [
              -150.617102,
              59.39488
            ],
            [
              -150.671354,
              59.410108
            ],
            [
              -150.701811,
              59.389169
            ],
            [
              -150.742738,
              59.350146
            ],
            [
              -150.772243,
              59.333014
            ]
          ]
        ],
        [
          [
            [
              -151.528818,
              59.147586
            ],
            [
              -151.526323,
              59.134359
            ],
            [
              -151.490671,
              59.121614
            ],
            [
              -151.445036,
              59.105082
            ],
            [
              -151.431726,
              59.11765
            ],
            [
              -151.43339,
              59.135517
            ],
            [
              -151.482471,
              59.143137
            ],
            [
              -151.528818,
              59.147586
            ]
          ]
        ],
        [
          [
            [
              -151.718141,
              59.132248
            ],
            [
              -151.726568,
              59.115088
            ],
            [
              -151.713733,
              59.101115
            ],
            [
              -151.662368,
              59.089735
            ],
            [
              -151.64338,
              59.105204
            ],
            [
              -151.638389,
              59.117406
            ],
            [
              -151.687707,
              59.129725
            ],
            [
              -151.718141,
              59.132248
            ]
          ]
        ],
        [
          [
            [
              -151.860619,
              59.165926
            ],
            [
              -151.88308,
              59.156179
            ],
            [
              -151.858124,
              59.144234
            ],
            [
              -151.823422,
              59.136798
            ],
            [
              -151.808686,
              59.134908
            ],
            [
              -151.794544,
              59.145331
            ],
            [
              -151.790266,
              59.16422
            ],
            [
              -151.799535,
              59.177071
            ],
            [
              -151.824254,
              59.178045
            ],
            [
              -151.860619,
              59.165926
            ]
          ]
        ],
        [
          [
            [
              -151.991618,
              59.313617
            ],
            [
              -151.978748,
              59.253779
            ],
            [
              -151.959279,
              59.247625
            ],
            [
              -151.952723,
              59.250447
            ],
            [
              -151.925051,
              59.254428
            ],
            [
              -151.905106,
              59.247075
            ],
            [
              -151.906191,
              59.237963
            ],
            [
              -151.910958,
              59.236707
            ],
            [
              -151.917248,
              59.231254
            ],
            [
              -151.915684,
              59.227522
            ],
            [
              -151.874356,
              59.211931
            ],
            [
              -151.838335,
              59.209135
            ],
            [
              -151.761301,
              59.221327
            ],
            [
              -151.75853,
              59.215743
            ],
            [
              -151.761451,
              59.205235
            ],
            [
              -151.764908,
              59.17551
            ],
            [
              -151.748451,
              59.158601
            ],
            [
              -151.739068,
              59.156005
            ],
            [
              -151.720931,
              59.156078
            ],
            [
              -151.710625,
              59.158097
            ],
            [
              -151.698875,
              59.163081
            ],
            [
              -151.590729,
              59.161725
            ],
            [
              -151.580351,
              59.165233
            ],
            [
              -151.576452,
              59.172601
            ],
            [
              -151.579261,
              59.187666
            ],
            [
              -151.574664,
              59.195327
            ],
            [
              -151.558151,
              59.200085
            ],
            [
              -151.521455,
              59.195483
            ],
            [
              -151.502657,
              59.195071
            ],
            [
              -151.498826,
              59.200178
            ],
            [
              -151.497805,
              59.204264
            ],
            [
              -151.499592,
              59.207839
            ],
            [
              -151.504699,
              59.21269
            ],
            [
              -151.520245,
              59.21693
            ],
            [
              -151.525127,
              59.224947
            ],
            [
              -151.518488,
              59.230309
            ],
            [
              -151.509551,
              59.234395
            ],
            [
              -151.488612,
              59.237714
            ],
            [
              -151.449207,
              59.248457
            ],
            [
              -151.437695,
              59.253989
            ],
            [
              -151.429415,
              59.268552
            ],
            [
              -151.407203,
              59.279349
            ],
            [
              -151.399549,
              59.276005
            ],
            [
              -151.390544,
              59.264917
            ],
            [
              -151.387261,
              59.25045
            ],
            [
              -151.379612,
              59.242024
            ],
            [
              -151.341601,
              59.222231
            ],
            [
              -151.305724,
              59.209544
            ],
            [
              -151.292812,
              59.214273
            ],
            [
              -151.287771,
              59.219417
            ],
            [
              -151.287063,
              59.224789
            ],
            [
              -151.28488,
              59.227586
            ],
            [
              -151.280544,
              59.230476
            ],
            [
              -151.273779,
              59.229663
            ],
            [
              -151.261636,
              59.220304
            ],
            [
              -151.223629,
              59.224347
            ],
            [
              -151.206053,
              59.219319
            ],
            [
              -151.192634,
              59.211208
            ],
            [
              -151.190948,
              59.206632
            ],
            [
              -151.186254,
              59.202813
            ],
            [
              -151.163408,
              59.202636
            ],
            [
              -151.126247,
              59.209923
            ],
            [
              -151.107558,
              59.217792
            ],
            [
              -151.102395,
              59.228713
            ],
            [
              -151.101102,
              59.240605
            ],
            [
              -151.105635,
              59.263143
            ],
            [
              -151.091532,
              59.269187
            ],
            [
              -151.087319,
              59.26879
            ],
            [
              -151.071902,
              59.281058
            ],
            [
              -151.068166,
              59.284102
            ],
            [
              -151.070305,
              59.287852
            ],
            [
              -151.057756,
              59.301721
            ],
            [
              -151.0461,
              59.299359
            ],
            [
              -151.044411,
              59.293611
            ],
            [
              -151.03243,
              59.275762
            ],
            [
              -151.023097,
              59.269045
            ],
            [
              -150.999063,
              59.271082
            ],
            [
              -150.996808,
              59.257739
            ],
            [
              -151.007292,
              59.251577
            ],
            [
              -151.008815,
              59.245787
            ],
            [
              -151.006682,
              59.233292
            ],
            [
              -151.001196,
              59.224149
            ],
            [
              -150.995406,
              59.224149
            ],
            [
              -150.988397,
              59.230549
            ],
            [
              -150.975164,
              59.236141
            ],
            [
              -150.959531,
              59.232537
            ],
            [
              -150.942212,
              59.233136
            ],
            [
              -150.897808,
              59.255648
            ],
            [
              -150.887821,
              59.26792
            ],
            [
              -150.887825,
              59.273526
            ],
            [
              -150.895552,
              59.286227
            ],
            [
              -150.912817,
              59.305214
            ],
            [
              -150.911598,
              59.311614
            ],
            [
              -150.877447,
              59.31812
            ],
            [
              -150.834627,
              59.35198
            ],
            [
              -150.819565,
              59.357276
            ],
            [
              -150.79547,
              59.362845
            ],
            [
              -150.769853,
              59.372966
            ],
            [
              -150.745004,
              59.400729
            ],
            [
              -150.739958,
              59.425211
            ],
            [
              -150.700859,
              59.432951
            ],
            [
              -150.651586,
              59.421751
            ],
            [
              -150.650046,
              59.420885
            ],
            [
              -150.601162,
              59.425657
            ],
            [
              -150.581182,
              59.445233
            ],
            [
              -150.585567,
              59.450057
            ],
            [
              -150.584342,
              59.467715
            ],
            [
              -150.579869,
              59.475709
            ],
            [
              -150.579595,
              59.47954
            ],
            [
              -150.584636,
              59.49451
            ],
            [
              -150.589645,
              59.500083
            ],
            [
              -150.595056,
              59.499777
            ],
            [
              -150.615152,
              59.510199
            ],
            [
              -150.631577,
              59.521575
            ],
            [
              -150.638704,
              59.532056
            ],
            [
              -150.639543,
              59.547149
            ],
            [
              -150.631158,
              59.549245
            ],
            [
              -150.614808,
              59.545472
            ],
            [
              -150.60223,
              59.545891
            ],
            [
              -150.594543,
              59.553167
            ],
            [
              -150.589315,
              59.565154
            ],
            [
              -150.575818,
              59.579431
            ],
            [
              -150.556114,
              59.590331
            ],
            [
              -150.547729,
              59.590331
            ],
            [
              -150.54186,
              59.586977
            ],
            [
              -150.537248,
              59.581108
            ],
            [
              -150.533056,
              59.56308
            ],
            [
              -150.549825,
              59.538764
            ],
            [
              -150.550245,
              59.527026
            ],
            [
              -150.543537,
              59.510256
            ],
            [
              -150.536119,
              59.498457
            ],
            [
              -150.522994,
              59.494744
            ],
            [
              -150.516286,
              59.486778
            ],
            [
              -150.515867,
              59.482167
            ],
            [
              -150.518382,
              59.477136
            ],
            [
              -150.521626,
              59.474672
            ],
            [
              -150.521537,
              59.467924
            ],
            [
              -150.516317,
              59.462326
            ],
            [
              -150.4989,
              59.456298
            ],
            [
              -150.478742,
              59.458498
            ],
            [
              -150.431518,
              59.514287
            ],
            [
              -150.412448,
              59.554628
            ],
            [
              -150.355493,
              59.598412
            ],
            [
              -150.318668,
              59.610427
            ],
            [
              -150.316945,
              59.585285
            ],
            [
              -150.368687,
              59.51766
            ],
            [
              -150.440221,
              59.404016
            ],
            [
              -150.430144,
              59.343357
            ],
            [
              -150.385341,
              59.341963
            ],
            [
              -150.364663,
              59.382479
            ],
            [
              -150.353611,
              59.415993
            ],
            [
              -150.297108,
              59.424747
            ],
            [
              -150.296351,
              59.455794
            ],
            [
              -150.253521,
              59.486251
            ],
            [
              -150.180233,
              59.53384
            ],
            [
              -150.10885,
              59.569056
            ],
            [
              -150.031935,
              59.613947
            ],
            [
              -149.933721,
              59.668994
            ],
            [
              -149.919444,
              59.691836
            ],
            [
              -149.928962,
              59.723245
            ],
            [
              -149.97916,
              59.744976
            ],
            [
              -150.015557,
              59.754075
            ],
            [
              -150.042854,
              59.772274
            ],
            [
              -150.028296,
              59.788652
            ],
            [
              -149.904216,
              59.762268
            ],
            [
              -149.849315,
              59.738066
            ],
            [
              -149.842672,
              59.7013
            ],
            [
              -149.791905,
              59.661379
            ],
            [
              -149.74622,
              59.637585
            ],
            [
              -149.728136,
              59.692788
            ],
            [
              -149.738941,
              59.732237
            ],
            [
              -149.746364,
              59.860881
            ],
            [
              -149.717105,
              59.902951
            ],
            [
              -149.73291,
              59.927614
            ],
            [
              -149.741348,
              59.947557
            ],
            [
              -149.714865,
              59.953604
            ],
            [
              -149.678482,
              59.942736
            ],
            [
              -149.642235,
              59.894607
            ],
            [
              -149.640334,
              59.875586
            ],
            [
              -149.668719,
              59.843483
            ],
            [
              -149.654357,
              59.803997
            ],
            [
              -149.645088,
              59.769311
            ],
            [
              -149.626311,
              59.73441
            ],
            [
              -149.585549,
              59.719612
            ],
            [
              -149.527793,
              59.706846
            ],
            [
              -149.515671,
              59.738183
            ],
            [
              -149.506758,
              59.770927
            ],
            [
              -149.545381,
              59.782592
            ],
            [
              -149.595531,
              59.79772
            ],
            [
              -149.610149,
              59.837751
            ],
            [
              -149.572714,
              59.852317
            ],
            [
              -149.553081,
              59.890256
            ],
            [
              -149.554769,
              59.910578
            ],
            [
              -149.523103,
              59.927631
            ],
            [
              -149.480257,
              59.928746
            ],
            [
              -149.464213,
              59.918561
            ],
            [
              -149.435454,
              59.964455
            ],
            [
              -149.427135,
              59.980631
            ],
            [
              -149.390533,
              59.983841
            ],
            [
              -149.408834,
              60.013433
            ],
            [
              -149.437237,
              60.029229
            ],
            [
              -149.44237,
              60.059567
            ],
            [
              -149.44245165604502,
              60.064174057748694
            ],
            [
              -149.44306,
              60.098497
            ],
            [
              -149.424283,
              60.122837
            ],
            [
              -149.365695,
              60.118575
            ],
            [
              -149.357614,
              60.088543
            ],
            [
              -149.35508760220503,
              60.08293667597469
            ],
            [
              -149.338481,
              60.046085
            ],
            [
              -149.325765,
              60.009454
            ],
            [
              -149.314594,
              59.971057
            ],
            [
              -149.350484,
              59.935176
            ],
            [
              -149.358921,
              59.899018
            ],
            [
              -149.384115,
              59.882564
            ],
            [
              -149.411532,
              59.851043
            ],
            [
              -149.376593,
              59.835879
            ],
            [
              -149.270623,
              59.872067
            ],
            [
              -149.221067,
              59.938748
            ],
            [
              -149.202409,
              59.988716
            ],
            [
              -149.165806,
              60.022045
            ],
            [
              -149.103415,
              60.04383
            ],
            [
              -149.070738,
              60.055401
            ],
            [
              -149.037222,
              60.042821
            ],
            [
              -149.084414,
              60.009425
            ],
            [
              -149.122073,
              59.979501
            ],
            [
              -149.123262,
              59.968559
            ],
            [
              -149.089868,
              59.955531
            ],
            [
              -149.041619,
              59.968143
            ],
            [
              -149.01179,
              59.950771
            ],
            [
              -148.964018,
              59.972435
            ],
            [
              -148.914597,
              59.968937
            ],
            [
              -148.877857,
              59.936903
            ],
            [
              -148.859556,
              59.924398
            ],
            [
              -148.823072,
              59.930949
            ],
            [
              -148.801325,
              59.952794
            ],
            [
              -148.757591,
              59.958685
            ],
            [
              -148.689496,
              59.944701
            ],
            [
              -148.6757,
              59.922644
            ],
            [
              -148.66908961113703,
              59.9215299112971
            ],
            [
              -148.634777,
              59.915747
            ],
            [
              -148.585580269786,
              59.9372103414648
            ],
            [
              -148.571915,
              60.06194
            ],
            [
              -148.563026,
              60.126314
            ],
            [
              -148.563715,
              60.415172
            ],
            [
              -148.66447,
              60.415183
            ],
            [
              -148.664471,
              60.423047
            ],
            [
              -148.664474,
              60.472959
            ],
            [
              -148.657076,
              60.472958
            ],
            [
              -148.657134,
              60.725066
            ],
            [
              -148.657119,
              60.732976
            ],
            [
              -148.745618,
              60.733007
            ],
            [
              -149.037741,
              60.732987
            ],
            [
              -149.037839,
              60.819648
            ],
            [
              -149.034071,
              60.834081
            ],
            [
              -149.034078391325,
              60.8438693782096
            ],
            [
              -149.089987,
              60.867223
            ],
            [
              -149.111617,
              60.878949
            ],
            [
              -149.345756,
              60.896082
            ],
            [
              -149.571329,
              60.937008
            ],
            [
              -149.639423,
              60.928647
            ],
            [
              -149.770264,
              60.967607
            ],
            [
              -149.816817,
              60.966947
            ],
            [
              -149.83558,
              60.968855
            ],
            [
              -149.853693,
              60.967395
            ],
            [
              -149.875188,
              60.960244
            ],
            [
              -149.945158,
              60.903507
            ],
            [
              -149.972373,
              60.892901
            ],
            [
              -149.999468,
              60.863983
            ],
            [
              -150.023474,
              60.868843
            ],
            [
              -150.045816,
              60.88712
            ],
            [
              -150.079328,
              60.886426
            ],
            [
              -150.109276,
              60.890357
            ],
            [
              -150.139937,
              60.901918
            ],
            [
              -150.194128,
              60.90134
            ],
            [
              -150.262096,
              60.947839
            ],
            [
              -150.286369,
              60.966696
            ],
            [
              -150.310334,
              60.989547
            ],
            [
              -150.341709,
              61.024201
            ],
            [
              -150.353702,
              61.031822
            ],
            [
              -150.377171,
              61.039144
            ],
            [
              -150.401859,
              61.036227
            ],
            [
              -150.431873,
              61.023939
            ],
            [
              -150.454661,
              61.016566
            ],
            [
              -150.501923,
              61.007957
            ],
            [
              -150.511099,
              61.005145
            ],
            [
              -150.515058,
              60.999443
            ],
            [
              -150.582471,
              60.982095
            ],
            [
              -150.603069,
              60.974434
            ],
            [
              -150.678438,
              60.958267
            ],
            [
              -150.705812,
              60.937792
            ],
            [
              -150.770594,
              60.911362
            ],
            [
              -150.808418,
              60.891336
            ],
            [
              -150.845731,
              60.877893
            ],
            [
              -150.883774,
              60.861865
            ],
            [
              -150.886964,
              60.858187
            ],
            [
              -150.895508,
              60.853166
            ],
            [
              -151.012016,
              60.80934
            ],
            [
              -151.024799,
              60.801787
            ],
            [
              -151.025634,
              60.797497
            ],
            [
              -151.037007,
              60.793649
            ],
            [
              -151.062558,
              60.787429
            ],
            [
              -151.106079,
              60.783749
            ],
            [
              -151.212186,
              60.780342
            ],
            [
              -151.252902,
              60.773993
            ],
            [
              -151.261319,
              60.769801
            ],
            [
              -151.259343,
              60.762896
            ],
            [
              -151.261383,
              60.757768
            ],
            [
              -151.270505,
              60.751286
            ],
            [
              -151.279635,
              60.747676
            ],
            [
              -151.30923,
              60.740724
            ],
            [
              -151.370515,
              60.733572
            ],
            [
              -151.3848,
              60.729946
            ],
            [
              -151.40927,
              60.720558
            ],
            [
              -151.410273,
              60.711023
            ],
            [
              -151.404451,
              60.695004
            ],
            [
              -151.387839,
              60.674501
            ],
            [
              -151.362397,
              60.653526
            ],
            [
              -151.350154,
              60.63466
            ],
            [
              -151.345508,
              60.622954
            ],
            [
              -151.344477,
              60.613458
            ],
            [
              -151.339069,
              60.594244
            ],
            [
              -151.335530106749,
              60.588643476674
            ],
            [
              -151.330409,
              60.580539
            ],
            [
              -151.323951,
              60.574135
            ],
            [
              -151.303125,
              60.561326
            ],
            [
              -151.268373,
              60.548977
            ],
            [
              -151.264461,
              60.543263
            ],
            [
              -151.27881,
              60.520107
            ],
            [
              -151.280992,
              60.512627
            ],
            [
              -151.283123399508,
              60.46933200716529
            ],
            [
              -151.283967,
              60.452196
            ],
            [
              -151.286819,
              60.434648
            ],
            [
              -151.293074,
              60.416163
            ],
            [
              -151.299782,
              60.385481
            ],
            [
              -151.301868,
              60.384712
            ],
            [
              -151.30609,
              60.387257
            ],
            [
              -151.366874,
              60.372655
            ],
            [
              -151.377281,
              60.365522
            ],
            [
              -151.381604,
              60.358728
            ],
            [
              -151.383231,
              60.326348
            ],
            [
              -151.381959,
              60.296951
            ],
            [
              -151.387919,
              60.267066
            ],
            [
              -151.406607,
              60.228183
            ],
            [
              -151.421702,
              60.212931
            ],
            [
              -151.488721,
              60.167616
            ],
            [
              -151.517887,
              60.145008
            ],
            [
              -151.545579,
              60.128394
            ],
            [
              -151.606881,
              60.099558
            ],
            [
              -151.623799,
              60.088033
            ],
            [
              -151.661437,
              60.057139
            ],
            [
              -151.702898,
              60.032253
            ],
            [
              -151.71801,
              60.009473
            ],
            [
              -151.742742,
              59.944626
            ],
            [
              -151.757693,
              59.917637
            ],
            [
              -151.777855,
              59.897493
            ],
            [
              -151.792594,
              59.88881
            ],
            [
              -151.803059,
              59.878533
            ],
            [
              -151.813619,
              59.844297
            ],
            [
              -151.83334,
              59.814129
            ],
            [
              -151.857339,
              59.791145
            ],
            [
              -151.867713,
              59.778411
            ],
            [
              -151.869468,
              59.769159
            ],
            [
              -151.859327,
              59.749567
            ],
            [
              -151.850272,
              59.739035
            ],
            [
              -151.829137,
              59.720151
            ],
            [
              -151.7963,
              59.704156
            ],
            [
              -151.746815,
              59.686234
            ],
            [
              -151.686486,
              59.660864
            ],
            [
              -151.643061,
              59.646966
            ],
            [
              -151.503822,
              59.633662
            ],
            [
              -151.461253,
              59.643039
            ],
            [
              -151.448669,
              59.648171
            ],
            [
              -151.441127,
              59.653543
            ],
            [
              -151.439187,
              59.663247
            ],
            [
              -151.43661,
              59.66636
            ],
            [
              -151.42484,
              59.670521
            ],
            [
              -151.377054,
              59.681313
            ],
            [
              -151.329812,
              59.683644
            ],
            [
              -151.214539,
              59.729847
            ],
            [
              -151.172439,
              59.751346
            ],
            [
              -151.113845,
              59.777231
            ],
            [
              -151.063758,
              59.793146
            ],
            [
              -151.027756,
              59.796196
            ],
            [
              -151.006717,
              59.792986
            ],
            [
              -151.001663,
              59.788391
            ],
            [
              -150.982996,
              59.783543
            ],
            [
              -150.948132,
              59.792194
            ],
            [
              -150.927312,
              59.793431
            ],
            [
              -150.94387088882402,
              59.7867699059742
            ],
            [
              -151.018888,
              59.756593
            ],
            [
              -151.098253,
              59.709442
            ],
            [
              -151.11649,
              59.696132
            ],
            [
              -151.122791,
              59.677782
            ],
            [
              -151.121362,
              59.674735
            ],
            [
              -151.126122,
              59.668336
            ],
            [
              -151.173984,
              59.651793
            ],
            [
              -151.203186,
              59.645989
            ],
            [
              -151.207639,
              59.64067
            ],
            [
              -151.205459,
              59.630284
            ],
            [
              -151.188032,
              59.608687
            ],
            [
              -151.165427,
              59.601329
            ],
            [
              -151.158254,
              59.594141
            ],
            [
              -151.164259,
              59.587013
            ],
            [
              -151.232313,
              59.597688
            ],
            [
              -151.277477,
              59.594321
            ],
            [
              -151.30124,
              59.580001
            ],
            [
              -151.349489,
              59.567363
            ],
            [
              -151.399402,
              59.541348
            ],
            [
              -151.434578,
              59.543999
            ],
            [
              -151.436359,
              59.530329
            ],
            [
              -151.47332,
              59.538426
            ],
            [
              -151.516339,
              59.52352
            ],
            [
              -151.529888,
              59.503857
            ],
            [
              -151.518717,
              59.484432
            ],
            [
              -151.549415,
              59.471125
            ],
            [
              -151.571333,
              59.483541
            ],
            [
              -151.59525,
              59.475983
            ],
            [
              -151.634472,
              59.482443
            ],
            [
              -151.694726,
              59.46837
            ],
            [
              -151.706462,
              59.462811
            ],
            [
              -151.720421,
              59.443117
            ],
            [
              -151.728486,
              59.439679
            ],
            [
              -151.740538,
              59.438432
            ],
            [
              -151.75142,
              59.446554
            ],
            [
              -151.770875,
              59.447917
            ],
            [
              -151.826047,
              59.439049
            ],
            [
              -151.886513,
              59.421033
            ],
            [
              -151.905153,
              59.401035
            ],
            [
              -151.908015,
              59.395274
            ],
            [
              -151.887102,
              59.382532
            ],
            [
              -151.890738,
              59.373156
            ],
            [
              -151.903021,
              59.360454
            ],
            [
              -151.924018,
              59.354417
            ],
            [
              -151.952705,
              59.349413
            ],
            [
              -151.96313,
              59.344958
            ],
            [
              -151.991618,
              59.313617
            ]
          ]
        ],
        [
          [
            [
              -152.08285,
              60.34595
            ],
            [
              -152.079995,
              60.341191
            ],
            [
              -151.956263,
              60.367841
            ],
            [
              -151.974347,
              60.38878
            ],
            [
              -151.940083,
              60.430659
            ],
            [
              -151.891542,
              60.440177
            ],
            [
              -151.895349,
              60.460164
            ],
            [
              -151.841097,
              60.481104
            ],
            [
              -151.839194,
              60.485862
            ],
            [
              -151.930565,
              60.51632
            ],
            [
              -151.952456,
              60.510609
            ],
            [
              -151.965781,
              60.474441
            ],
            [
              -151.980058,
              60.414479
            ],
            [
              -152.012418,
              60.404009
            ],
            [
              -152.072381,
              60.361179
            ],
            [
              -152.08285,
              60.34595
            ]
          ]
        ],
        [
          [
            [
              -152.639644,
              60.165111
            ],
            [
              -152.632982,
              60.148931
            ],
            [
              -152.560646,
              60.094679
            ],
            [
              -152.553032,
              60.098486
            ],
            [
              -152.550177,
              60.113715
            ],
            [
              -152.567309,
              60.158449
            ],
            [
              -152.57873,
              60.16987
            ],
            [
              -152.619657,
              60.183195
            ],
            [
              -152.639644,
              60.165111
            ]
          ]
        ],
        [
          [
            [
              -153.56002,
              59.39131
            ],
            [
              -153.573345,
              59.371323
            ],
            [
              -153.546695,
              59.331348
            ],
            [
              -153.515286,
              59.320878
            ],
            [
              -153.413445,
              59.322782
            ],
            [
              -153.365856,
              59.337059
            ],
            [
              -153.343013,
              59.356094
            ],
            [
              -153.347772,
              59.377985
            ],
            [
              -153.390602,
              59.41225
            ],
            [
              -153.412493,
              59.415105
            ],
            [
              -153.510527,
              59.39131
            ],
            [
              -153.56002,
              59.39131
            ]
          ]
        ],
        [
          [
            [
              -154.748861,
              59.259518
            ],
            [
              -154.748834,
              59.086159
            ],
            [
              -154.695105,
              59.086159
            ],
            [
              -154.695036,
              58.739431
            ],
            [
              -154.642558,
              58.739431
            ],
            [
              -154.642545,
              58.652747
            ],
            [
              -154.305691,
              58.652751
            ],
            [
              -153.817076,
              58.65276
            ],
            [
              -153.817076,
              58.739446
            ],
            [
              -153.639027,
              58.73945
            ],
            [
              -153.639403,
              58.855029
            ],
            [
              -153.258597535022,
              58.855036
            ],
            [
              -153.25225,
              58.85585
            ],
            [
              -153.254798,
              58.861756
            ],
            [
              -153.267407,
              58.867218
            ],
            [
              -153.286163,
              58.863077
            ],
            [
              -153.294726,
              58.865432
            ],
            [
              -153.302433,
              58.871212
            ],
            [
              -153.305216,
              58.874637
            ],
            [
              -153.304788,
              58.878919
            ],
            [
              -153.322843,
              58.907849
            ],
            [
              -153.33478,
              58.920521
            ],
            [
              -153.352283,
              58.921705
            ],
            [
              -153.365371,
              58.927753
            ],
            [
              -153.388765,
              58.945337
            ],
            [
              -153.393101,
              58.951097
            ],
            [
              -153.398479,
              58.966056
            ],
            [
              -153.422015,
              58.970648
            ],
            [
              -153.438144,
              58.969911
            ],
            [
              -153.450672,
              58.976119
            ],
            [
              -153.463266,
              58.986903
            ],
            [
              -153.479939,
              58.995286
            ],
            [
              -153.505618,
              58.981734
            ],
            [
              -153.523522,
              58.979221
            ],
            [
              -153.547283,
              58.983716
            ],
            [
              -153.616066,
              59.006737
            ],
            [
              -153.648029,
              59.028924
            ],
            [
              -153.695664,
              59.073994
            ],
            [
              -153.704162,
              59.07578
            ],
            [
              -153.746201,
              59.065199
            ],
            [
              -153.74868,
              59.058729
            ],
            [
              -153.750936,
              59.05284
            ],
            [
              -153.793972,
              59.071416
            ],
            [
              -153.802782,
              59.072224
            ],
            [
              -153.809866,
              59.070797
            ],
            [
              -153.815724,
              59.064851
            ],
            [
              -153.83818,
              59.055296
            ],
            [
              -153.850238,
              59.052917
            ],
            [
              -153.932824,
              59.062677
            ],
            [
              -153.949958,
              59.066782
            ],
            [
              -154.008547,
              59.072904
            ],
            [
              -154.031822,
              59.073681
            ],
            [
              -154.063489,
              59.07214
            ],
            [
              -154.108278,
              59.036827
            ],
            [
              -154.132449,
              59.024745
            ],
            [
              -154.158207,
              59.017853
            ],
            [
              -154.159835,
              59.010595
            ],
            [
              -154.171462,
              59.021963
            ],
            [
              -154.18958,
              59.044207
            ],
            [
              -154.197422,
              59.061155
            ],
            [
              -154.195271,
              59.069491
            ],
            [
              -154.171502,
              59.083423
            ],
            [
              -154.166406,
              59.090582
            ],
            [
              -154.166745,
              59.100548
            ],
            [
              -154.173669,
              59.1202
            ],
            [
              -154.180691,
              59.123235
            ],
            [
              -154.227238,
              59.125407
            ],
            [
              -154.239842,
              59.119324
            ],
            [
              -154.243785,
              59.114868
            ],
            [
              -154.251233,
              59.111239
            ],
            [
              -154.256528,
              59.118462
            ],
            [
              -154.263291,
              59.138462
            ],
            [
              -154.260121,
              59.14302
            ],
            [
              -154.24422,
              59.144161
            ],
            [
              -154.214818,
              59.151562
            ],
            [
              -154.172944,
              59.172496
            ],
            [
              -154.130585,
              59.210503
            ],
            [
              -154.141192,
              59.216598
            ],
            [
              -154.13684,
              59.262666
            ],
            [
              -154.122681,
              59.287622
            ],
            [
              -154.113577,
              59.299627
            ],
            [
              -154.077942,
              59.313364
            ],
            [
              -154.030807,
              59.32704
            ],
            [
              -154.028739,
              59.337381
            ],
            [
              -154.033703,
              59.343999
            ],
            [
              -154.103014,
              59.342719
            ],
            [
              -154.121394,
              59.353099
            ],
            [
              -154.121808,
              59.360544
            ],
            [
              -154.117672,
              59.365508
            ],
            [
              -154.100989,
              59.366016
            ],
            [
              -154.087803,
              59.367967
            ],
            [
              -154.062453,
              59.382753
            ],
            [
              -154.05215,
              59.387138
            ],
            [
              -154.044563,
              59.388295
            ],
            [
              -154.035965,
              59.386362
            ],
            [
              -154.025696,
              59.381521
            ],
            [
              -154.007207,
              59.382528
            ],
            [
              -153.998506,
              59.384723
            ],
            [
              -153.996261,
              59.39082
            ],
            [
              -153.993994,
              59.394049
            ],
            [
              -153.990003,
              59.396777
            ],
            [
              -153.959893,
              59.39685
            ],
            [
              -153.954717,
              59.392532
            ],
            [
              -153.951389,
              59.387017
            ],
            [
              -153.945539,
              59.386061
            ],
            [
              -153.925307,
              59.405254
            ],
            [
              -153.911268,
              59.413732
            ],
            [
              -153.896576,
              59.418486
            ],
            [
              -153.862199,
              59.424124
            ],
            [
              -153.823384,
              59.418035
            ],
            [
              -153.807119,
              59.419466
            ],
            [
              -153.747201,
              59.429657
            ],
            [
              -153.727546,
              59.435346
            ],
            [
              -153.699025,
              59.463603
            ],
            [
              -153.706419,
              59.477994
            ],
            [
              -153.733853,
              59.505754
            ],
            [
              -153.766242,
              59.522342
            ],
            [
              -153.76148,
              59.543411
            ],
            [
              -153.719309,
              59.550264
            ],
            [
              -153.650943,
              59.555427
            ],
            [
              -153.635262,
              59.555694
            ],
            [
              -153.618151,
              59.552571
            ],
            [
              -153.585406,
              59.551475
            ],
            [
              -153.577828,
              59.555991
            ],
            [
              -153.555148,
              59.587858
            ],
            [
              -153.553163,
              59.597046
            ],
            [
              -153.558292,
              59.60579
            ],
            [
              -153.592193,
              59.610842
            ],
            [
              -153.609253,
              59.62164
            ],
            [
              -153.610739,
              59.628341
            ],
            [
              -153.604813,
              59.638734
            ],
            [
              -153.586518,
              59.651541
            ],
            [
              -153.563866,
              59.638903
            ],
            [
              -153.542466,
              59.630236
            ],
            [
              -153.476098,
              59.64273
            ],
            [
              -153.464556,
              59.651712
            ],
            [
              -153.444003,
              59.689957
            ],
            [
              -153.441214,
              59.701316
            ],
            [
              -153.442219,
              59.71742
            ],
            [
              -153.445336,
              59.728865
            ],
            [
              -153.44962,
              59.74381
            ],
            [
              -153.458549,
              59.764467
            ],
            [
              -153.454972,
              59.792099
            ],
            [
              -153.439977,
              59.784652
            ],
            [
              -153.374778,
              59.731587
            ],
            [
              -153.378235,
              59.688936
            ],
            [
              -153.384886,
              59.667188
            ],
            [
              -153.393849,
              59.658847
            ],
            [
              -153.415507,
              59.650918
            ],
            [
              -153.418099,
              59.642147
            ],
            [
              -153.414898,
              59.6386
            ],
            [
              -153.409422,
              59.636328
            ],
            [
              -153.392022,
              59.638856
            ],
            [
              -153.381595,
              59.638032
            ],
            [
              -153.366613,
              59.633729
            ],
            [
              -153.342938,
              59.621312
            ],
            [
              -153.308837,
              59.625706
            ],
            [
              -153.302756,
              59.627679
            ],
            [
              -153.298047,
              59.632502
            ],
            [
              -153.298205,
              59.636345
            ],
            [
              -153.307199,
              59.653954
            ],
            [
              -153.315083,
              59.66249
            ],
            [
              -153.315123,
              59.664896
            ],
            [
              -153.314002,
              59.666336
            ],
            [
              -153.301687,
              59.668717
            ],
            [
              -153.286525,
              59.670251
            ],
            [
              -153.275175,
              59.667303
            ],
            [
              -153.26274,
              59.643426
            ],
            [
              -153.253408,
              59.638415
            ],
            [
              -153.240018,
              59.632426
            ],
            [
              -153.214156,
              59.634271
            ],
            [
              -153.155019,
              59.654344
            ],
            [
              -153.12174,
              59.678009
            ],
            [
              -153.10894,
              59.678316
            ],
            [
              -153.051559,
              59.691562
            ],
            [
              -153.031319,
              59.723625
            ],
            [
              -152.994466,
              59.791261
            ],
            [
              -152.992126,
              59.810027
            ],
            [
              -153.003964,
              59.826747
            ],
            [
              -153.009084,
              59.830643
            ],
            [
              -153.021945,
              59.834133
            ],
            [
              -153.088515,
              59.833376
            ],
            [
              -153.113586,
              59.815631
            ],
            [
              -153.144372,
              59.807616
            ],
            [
              -153.182307,
              59.822389
            ],
            [
              -153.197352,
              59.824827
            ],
            [
              -153.217481,
              59.824721
            ],
            [
              -153.236556,
              59.821867
            ],
            [
              -153.257736,
              59.810807
            ],
            [
              -153.278535,
              59.810924
            ],
            [
              -153.285412,
              59.816755
            ],
            [
              -153.285802,
              59.820535
            ],
            [
              -153.278808,
              59.828066
            ],
            [
              -153.256944,
              59.83649
            ],
            [
              -153.228615,
              59.853355
            ],
            [
              -153.225937,
              59.858343
            ],
            [
              -153.212865,
              59.862784
            ],
            [
              -153.144747,
              59.859829
            ],
            [
              -153.079187,
              59.871103
            ],
            [
              -153.046986,
              59.882425
            ],
            [
              -153.019977,
              59.88623
            ],
            [
              -153.002521,
              59.886726
            ],
            [
              -152.967267,
              59.881494
            ],
            [
              -152.950662,
              59.876759
            ],
            [
              -152.920417,
              59.877741
            ],
            [
              -152.900414,
              59.881812
            ],
            [
              -152.882672,
              59.881986
            ],
            [
              -152.875167,
              59.877471
            ],
            [
              -152.860867,
              59.875033
            ],
            [
              -152.810058,
              59.878322
            ],
            [
              -152.806934,
              59.888029
            ],
            [
              -152.793584,
              59.89672
            ],
            [
              -152.745083,
              59.904232
            ],
            [
              -152.706431,
              59.915284
            ],
            [
              -152.700822,
              59.920309
            ],
            [
              -152.693674,
              59.932773
            ],
            [
              -152.679402,
              59.968054
            ],
            [
              -152.649479,
              59.988253
            ],
            [
              -152.611651,
              60.008521
            ],
            [
              -152.612721,
              60.015115
            ],
            [
              -152.608599,
              60.025429
            ],
            [
              -152.590169,
              60.035978
            ],
            [
              -152.575153,
              60.04826
            ],
            [
              -152.569121,
              60.071748
            ],
            [
              -152.575271,
              60.082363
            ],
            [
              -152.596784,
              60.101071
            ],
            [
              -152.634972,
              60.115799
            ],
            [
              -152.658418,
              60.121591
            ],
            [
              -152.686373,
              60.137717
            ],
            [
              -152.687485,
              60.140305
            ],
            [
              -152.674176,
              60.151731
            ],
            [
              -152.678085,
              60.163504
            ],
            [
              -152.688392,
              60.16582
            ],
            [
              -152.699879,
              60.165272
            ],
            [
              -152.734251,
              60.174801
            ],
            [
              -152.749545,
              60.189552
            ],
            [
              -152.754884,
              60.202901
            ],
            [
              -152.754884,
              60.21091
            ],
            [
              -152.743388,
              60.224323
            ],
            [
              -152.747026,
              60.233311
            ],
            [
              -152.715881,
              60.241274
            ],
            [
              -152.698634,
              60.240661
            ],
            [
              -152.670403,
              60.24432
            ],
            [
              -152.660055,
              60.242004
            ],
            [
              -152.642361,
              60.228766
            ],
            [
              -152.627683,
              60.2257
            ],
            [
              -152.626901,
              60.222728
            ],
            [
              -152.626275,
              60.220852
            ],
            [
              -152.624648,
              60.218687
            ],
            [
              -152.556752,
              60.224217
            ],
            [
              -152.549236,
              60.227631
            ],
            [
              -152.539843,
              60.241644
            ],
            [
              -152.528206,
              60.251346
            ],
            [
              -152.481794,
              60.274681
            ],
            [
              -152.456291,
              60.284042
            ],
            [
              -152.444165,
              60.285717
            ],
            [
              -152.42113,
              60.285331
            ],
            [
              -152.411281,
              60.287864
            ],
            [
              -152.392009,
              60.302108
            ],
            [
              -152.385979,
              60.315845
            ],
            [
              -152.386334,
              60.327889
            ],
            [
              -152.376743,
              60.345613
            ],
            [
              -152.371475,
              60.350176
            ],
            [
              -152.366213,
              60.353304
            ],
            [
              -152.352294,
              60.356101
            ],
            [
              -152.315855,
              60.359071
            ],
            [
              -152.307615,
              60.366489
            ],
            [
              -152.300622,
              60.369604
            ],
            [
              -152.234199,
              60.393888
            ],
            [
              -152.30195,
              60.414328
            ],
            [
              -152.312226,
              60.420397
            ],
            [
              -152.325821,
              60.434806
            ],
            [
              -152.330263,
              60.443134
            ],
            [
              -152.333375,
              60.460641
            ],
            [
              -152.331365,
              60.473525
            ],
            [
              -152.315149,
              60.499824
            ],
            [
              -152.309221,
              60.506384
            ],
            [
              -152.261497,
              60.538237
            ],
            [
              -152.195084,
              60.569675
            ],
            [
              -152.163517,
              60.576934
            ],
            [
              -152.148434,
              60.575977
            ],
            [
              -152.13616,
              60.578475
            ],
            [
              -152.099983,
              60.594366
            ],
            [
              -152.058104,
              60.646714
            ],
            [
              -152.021936,
              60.673364
            ],
            [
              -151.916914,
              60.717916
            ],
            [
              -151.870471,
              60.727284
            ],
            [
              -151.848614,
              60.733976
            ],
            [
              -151.847965,
              60.735694
            ],
            [
              -151.849634,
              60.738286
            ],
            [
              -151.864958,
              60.750458
            ],
            [
              -151.860179,
              60.753282
            ],
            [
              -151.851967,
              60.754074
            ],
            [
              -151.831185,
              60.747303
            ],
            [
              -151.822596,
              60.742352
            ],
            [
              -151.811286,
              60.732222
            ],
            [
              -151.803814,
              60.729004
            ],
            [
              -151.784039,
              60.726814
            ],
            [
              -151.760301,
              60.721441
            ],
            [
              -151.749493,
              60.714175
            ],
            [
              -151.744321,
              60.712403
            ],
            [
              -151.716379,
              60.710415
            ],
            [
              -151.710444,
              60.712657
            ],
            [
              -151.705553,
              60.718052
            ],
            [
              -151.702833,
              60.727778
            ],
            [
              -151.703802,
              60.732376
            ],
            [
              -151.751817,
              60.788729
            ],
            [
              -151.77731,
              60.810461
            ],
            [
              -151.787394,
              60.822307
            ],
            [
              -151.796723,
              60.838734
            ],
            [
              -151.800264,
              60.853672
            ],
            [
              -151.791698,
              60.86306
            ],
            [
              -151.783271,
              60.868713
            ],
            [
              -151.736015,
              60.891507
            ],
            [
              -151.720815,
              60.904257
            ],
            [
              -151.713913,
              60.916546
            ],
            [
              -151.692644,
              60.917743
            ],
            [
              -151.679518,
              60.922491
            ],
            [
              -151.641066,
              60.942177
            ],
            [
              -151.637346,
              60.946727
            ],
            [
              -151.621005,
              60.957453
            ],
            [
              -151.573698,
              60.975876
            ],
            [
              -151.538227,
              60.991835
            ],
            [
              -151.4803,
              61.010902
            ],
            [
              -151.467851,
              61.012423
            ],
            [
              -151.42512,
              61.013107
            ],
            [
              -151.362243,
              61.009412
            ],
            [
              -151.349004,
              61.010004
            ],
            [
              -151.33092,
              61.015124
            ],
            [
              -151.312653,
              61.026364
            ],
            [
              -151.307796,
              61.031008
            ],
            [
              -151.293622,
              61.035715
            ],
            [
              -151.252384,
              61.039968
            ],
            [
              -151.190318,
              61.042737
            ],
            [
              -151.166606,
              61.046404
            ],
            [
              -151.142587,
              61.062778
            ],
            [
              -151.127357,
              61.076896
            ],
            [
              -151.121692,
              61.083574
            ],
            [
              -151.119722,
              61.091117
            ],
            [
              -151.0785,
              61.133381
            ],
            [
              -151.072775,
              61.141669
            ],
            [
              -151.024905,
              61.178391
            ],
            [
              -151.01262,
              61.183258
            ],
            [
              -150.990086,
              61.188907
            ],
            [
              -150.974011240993,
              61.191658351718395
            ],
            [
              -150.974014,
              61.25294
            ],
            [
              -151.332802,
              61.252936
            ],
            [
              -151.332784,
              61.426239
            ],
            [
              -152.132646,
              61.426207
            ],
            [
              -152.999882,
              61.426172
            ],
            [
              -153.485575,
              61.426153
            ],
            [
              -153.485584,
              61.166193
            ],
            [
              -153.440877,
              61.166196
            ],
            [
              -153.440884,
              60.906236
            ],
            [
              -153.440886,
              60.819578
            ],
            [
              -153.397284,
              60.819581
            ],
            [
              -153.397276,
              60.472948
            ],
            [
              -153.528441,
              60.472939
            ],
            [
              -153.528392,
              60.126281
            ],
            [
              -153.65698,
              60.126277
            ],
            [
              -153.656977,
              59.779607
            ],
            [
              -153.78307,
              59.779597
            ],
            [
              -153.783068,
              59.692926
            ],
            [
              -153.953198,
              59.692918
            ],
            [
              -153.953181,
              59.519569
            ],
            [
              -154.123327,
              59.519552
            ],
            [
              -154.123321,
              59.432887
            ],
            [
              -154.412028,
              59.432882
            ],
            [
              -154.412013,
              59.259524
            ],
            [
              -154.748861,
              59.259518
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "02",
      "COUNTYFP": "180",
      "COUNTYNS": "01419977",
      "AFFGEOID": "0500000US02180",
      "GEOID": "02180",
      "NAME": "Nome",
      "LSAD": "05",
      "ALAND": 59496552709,
      "AWATER": 13774839669,
      "County_state": "Nome,02"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -162.682629,
              63.584066
            ],
            [
              -162.707559,
              63.577607
            ],
            [
              -162.680973,
              63.556859
            ],
            [
              -162.676581,
              63.555648
            ],
            [
              -162.614949,
              63.540601
            ],
            [
              -162.562007,
              63.537105
            ],
            [
              -162.552701,
              63.540951
            ],
            [
              -162.470029,
              63.5475
            ],
            [
              -162.416802,
              63.547389
            ],
            [
              -162.377988,
              63.543813
            ],
            [
              -162.345179,
              63.551785
            ],
            [
              -162.341892,
              63.594062
            ],
            [
              -162.374243,
              63.626425
            ],
            [
              -162.401203,
              63.634367
            ],
            [
              -162.424205,
              63.636215
            ],
            [
              -162.427696,
              63.633134
            ],
            [
              -162.425265,
              63.631654
            ],
            [
              -162.425419,
              63.62995
            ],
            [
              -162.430304,
              63.625745
            ],
            [
              -162.440229,
              63.622491
            ],
            [
              -162.451929,
              63.62127
            ],
            [
              -162.498175,
              63.622069
            ],
            [
              -162.512298,
              63.629784
            ],
            [
              -162.541389,
              63.635727
            ],
            [
              -162.558234,
              63.634308
            ],
            [
              -162.587527,
              63.625115
            ],
            [
              -162.614621,
              63.621832
            ],
            [
              -162.644513,
              63.602599
            ],
            [
              -162.682629,
              63.584066
            ]
          ]
        ],
        [
          [
            [
              -168.097709,
              64.969025
            ],
            [
              -168.097709,
              64.964266
            ],
            [
              -168.080577,
              64.958556
            ],
            [
              -168.050227,
              64.961234
            ],
            [
              -168.049168,
              64.98235
            ],
            [
              -168.074866,
              64.980447
            ],
            [
              -168.08248,
              64.974736
            ],
            [
              -168.097709,
              64.969025
            ]
          ]
        ],
        [
          [
            [
              -168.12893,
              65.655744
            ],
            [
              -168.134663,
              65.64084
            ],
            [
              -168.127044,
              65.626584
            ],
            [
              -168.100003,
              65.610972
            ],
            [
              -168.09614,
              65.600882
            ],
            [
              -168.099356,
              65.599045
            ],
            [
              -168.099046,
              65.592239
            ],
            [
              -168.0752,
              65.576355
            ],
            [
              -168.04762,
              65.569149
            ],
            [
              -167.997178,
              65.559346
            ],
            [
              -167.967065,
              65.558599
            ],
            [
              -167.909599,
              65.550876
            ],
            [
              -167.851234,
              65.538181
            ],
            [
              -167.841836,
              65.530249
            ],
            [
              -167.710888,
              65.498524
            ],
            [
              -167.621371,
              65.466589
            ],
            [
              -167.620388,
              65.463463
            ],
            [
              -167.574639,
              65.444979
            ],
            [
              -167.474024,
              65.412744
            ],
            [
              -167.398458,
              65.400259
            ],
            [
              -167.170465,
              65.389269
            ],
            [
              -167.10186,
              65.387737
            ],
            [
              -167.026782,
              65.381967
            ],
            [
              -166.899681,
              65.360642
            ],
            [
              -166.851646,
              65.348485
            ],
            [
              -166.796001,
              65.337184
            ],
            [
              -166.679717,
              65.326856
            ],
            [
              -166.655179,
              65.324938
            ],
            [
              -166.625987,
              65.325121
            ],
            [
              -166.596964,
              65.336246
            ],
            [
              -166.572735,
              65.338155
            ],
            [
              -166.551097,
              65.338406
            ],
            [
              -166.51864,
              65.335824
            ],
            [
              -166.485968,
              65.3309
            ],
            [
              -166.439404,
              65.319058
            ],
            [
              -166.377721,
              65.297983
            ],
            [
              -166.360618,
              65.288631
            ],
            [
              -166.347189,
              65.276341
            ],
            [
              -166.386271,
              65.254143
            ],
            [
              -166.451711,
              65.236178
            ],
            [
              -166.475297,
              65.224335
            ],
            [
              -166.474839,
              65.217663
            ],
            [
              -166.465342,
              65.194818
            ],
            [
              -166.46005,
              65.189897
            ],
            [
              -166.459984,
              65.183284
            ],
            [
              -166.464192,
              65.177086
            ],
            [
              -166.479913,
              65.167249
            ],
            [
              -166.509566,
              65.152719
            ],
            [
              -166.521506,
              65.149242
            ],
            [
              -166.60607,
              65.135992
            ],
            [
              -166.634449,
              65.125873
            ],
            [
              -166.638411,
              65.113586
            ],
            [
              -166.67032,
              65.10972
            ],
            [
              -166.755554,
              65.110585
            ],
            [
              -166.81679,
              65.117089
            ],
            [
              -166.826753,
              65.119778
            ],
            [
              -166.837496,
              65.128146
            ],
            [
              -166.872666,
              65.136928
            ],
            [
              -166.886677,
              65.138763
            ],
            [
              -166.89772,
              65.139028
            ],
            [
              -166.906687,
              65.13632
            ],
            [
              -166.910131,
              65.134024
            ],
            [
              -166.911922,
              65.125965
            ],
            [
              -166.885451,
              65.105856
            ],
            [
              -166.860402,
              65.090866
            ],
            [
              -166.82091,
              65.077053
            ],
            [
              -166.732794,
              65.053498
            ],
            [
              -166.696453,
              65.035857
            ],
            [
              -166.692426,
              65.029629
            ],
            [
              -166.688762,
              65.018029
            ],
            [
              -166.695206,
              65.005184
            ],
            [
              -166.705283,
              64.999846
            ],
            [
              -166.70483,
              64.997051
            ],
            [
              -166.690814,
              64.985372
            ],
            [
              -166.636843,
              64.968113
            ],
            [
              -166.61511,
              64.96433
            ],
            [
              -166.586066,
              64.955712
            ],
            [
              -166.530518,
              64.937114
            ],
            [
              -166.432246,
              64.88316
            ],
            [
              -166.415624,
              64.871928
            ],
            [
              -166.409331,
              64.859755
            ],
            [
              -166.407315,
              64.852281
            ],
            [
              -166.407303,
              64.834278
            ],
            [
              -166.410198,
              64.827968
            ],
            [
              -166.417028,
              64.81874
            ],
            [
              -166.430516,
              64.807715
            ],
            [
              -166.478978,
              64.797036
            ],
            [
              -166.481076,
              64.786156
            ],
            [
              -166.483801,
              64.733419
            ],
            [
              -166.474714,
              64.719267
            ],
            [
              -166.451788,
              64.691761
            ],
            [
              -166.413926,
              64.651229
            ],
            [
              -166.392403,
              64.638161
            ],
            [
              -166.236939,
              64.583558
            ],
            [
              -166.189546,
              64.575798
            ],
            [
              -165.919704,
              64.54866
            ],
            [
              -165.819595,
              64.540171
            ],
            [
              -165.751093,
              64.536437
            ],
            [
              -165.550573,
              64.512235
            ],
            [
              -165.413443,
              64.497939
            ],
            [
              -165.214182,
              64.469726
            ],
            [
              -165.016519,
              64.434392
            ],
            [
              -165.001961,
              64.433917
            ],
            [
              -164.874421,
              64.441195
            ],
            [
              -164.835679,
              64.444917
            ],
            [
              -164.807747,
              64.449432
            ],
            [
              -164.548298,
              64.516738
            ],
            [
              -164.491327,
              64.529824
            ],
            [
              -164.421871,
              64.545256
            ],
            [
              -164.307273,
              64.561488
            ],
            [
              -164.260064,
              64.56422
            ],
            [
              -164.147059,
              64.564552
            ],
            [
              -164.071997,
              64.56128
            ],
            [
              -164.044839,
              64.559167
            ],
            [
              -163.994532,
              64.551742
            ],
            [
              -163.974352,
              64.55137
            ],
            [
              -163.89618,
              64.564483
            ],
            [
              -163.875774,
              64.572935
            ],
            [
              -163.829739,
              64.574965
            ],
            [
              -163.651943,
              64.567299
            ],
            [
              -163.597834,
              64.563356
            ],
            [
              -163.451482,
              64.53482
            ],
            [
              -163.4129,
              64.524986
            ],
            [
              -163.350926,
              64.505859
            ],
            [
              -163.253027,
              64.469501
            ],
            [
              -163.249092,
              64.456223
            ],
            [
              -163.229206,
              64.430019
            ],
            [
              -163.175336,
              64.399334
            ],
            [
              -163.150789,
              64.397249
            ],
            [
              -163.11945,
              64.403808
            ],
            [
              -163.107459,
              64.409192
            ],
            [
              -163.091486,
              64.437736
            ],
            [
              -163.027158,
              64.477945
            ],
            [
              -163.033231,
              64.519314
            ],
            [
              -163.119723,
              64.510233
            ],
            [
              -163.178734,
              64.543546
            ],
            [
              -163.254876,
              64.549257
            ],
            [
              -163.309128,
              64.571148
            ],
            [
              -163.311983,
              64.58828
            ],
            [
              -163.217757,
              64.632062
            ],
            [
              -163.173975,
              64.639676
            ],
            [
              -163.142566,
              64.609219
            ],
            [
              -163.060712,
              64.58828
            ],
            [
              -163.028352,
              64.569244
            ],
            [
              -163.0274,
              64.559726
            ],
            [
              -163.049291,
              64.549257
            ],
            [
              -163.042618,
              64.540046
            ],
            [
              -163.030657,
              64.542353
            ],
            [
              -162.96925,
              64.54687
            ],
            [
              -162.940776,
              64.542417
            ],
            [
              -162.857562,
              64.49978
            ],
            [
              -162.858556,
              64.474864
            ],
            [
              -162.83654,
              64.436702
            ],
            [
              -162.806612,
              64.405608
            ],
            [
              -162.802266,
              64.395327
            ],
            [
              -162.80035,
              64.374695
            ],
            [
              -162.807205,
              64.364643
            ],
            [
              -162.810004,
              64.352647
            ],
            [
              -162.805385,
              64.336023
            ],
            [
              -162.795636,
              64.327716
            ],
            [
              -162.790167,
              64.325182
            ],
            [
              -162.768424,
              64.333516
            ],
            [
              -162.719218,
              64.359971
            ],
            [
              -162.66768,
              64.375356
            ],
            [
              -162.645156,
              64.379783
            ],
            [
              -162.632242,
              64.385734
            ],
            [
              -162.60302,
              64.448666
            ],
            [
              -162.602178,
              64.456869
            ],
            [
              -162.615452,
              64.467077
            ],
            [
              -162.61422,
              64.470702
            ],
            [
              -162.554875,
              64.520341
            ],
            [
              -162.539996,
              64.530931
            ],
            [
              -162.342308,
              64.59224
            ],
            [
              -162.290571,
              64.605496
            ],
            [
              -162.270025,
              64.60871
            ],
            [
              -162.234477,
              64.619336
            ],
            [
              -162.219718,
              64.644176
            ],
            [
              -162.21662,
              64.656213
            ],
            [
              -162.188146,
              64.672395
            ],
            [
              -162.168516,
              64.68029
            ],
            [
              -162.138832,
              64.685934
            ],
            [
              -162.096528,
              64.690983
            ],
            [
              -161.939279,
              64.699119
            ],
            [
              -161.902429,
              64.703851
            ],
            [
              -161.878363,
              64.709476
            ],
            [
              -161.667261,
              64.788981
            ],
            [
              -161.64552,
              64.776452
            ],
            [
              -161.630287,
              64.77129
            ],
            [
              -161.595506,
              64.764478
            ],
            [
              -161.518211,
              64.75325
            ],
            [
              -161.42986,
              64.759027
            ],
            [
              -161.413493,
              64.762723
            ],
            [
              -161.376985,
              64.773036
            ],
            [
              -161.367483,
              64.778907
            ],
            [
              -161.364438,
              64.782099
            ],
            [
              -161.366808,
              64.793777
            ],
            [
              -161.357867,
              64.805922
            ],
            [
              -161.327848,
              64.829836
            ],
            [
              -161.293049,
              64.853243
            ],
            [
              -161.264283,
              64.86197
            ],
            [
              -161.213756,
              64.883324
            ],
            [
              -161.198586,
              64.894403
            ],
            [
              -161.200964,
              64.898659
            ],
            [
              -161.200893,
              64.905796
            ],
            [
              -161.195202,
              64.918178
            ],
            [
              -161.19212,
              64.921366
            ],
            [
              -161.176009,
              64.927161
            ],
            [
              -161.145725,
              64.920534
            ],
            [
              -161.149366,
              64.916558
            ],
            [
              -161.149655,
              64.911985
            ],
            [
              -161.133062,
              64.898219
            ],
            [
              -161.102755,
              64.880661
            ],
            [
              -161.079718,
              64.869549
            ],
            [
              -160.986417,
              64.833984
            ],
            [
              -160.935974,
              64.82237
            ],
            [
              -160.869571,
              64.783797
            ],
            [
              -160.783398,
              64.71716
            ],
            [
              -160.78357,
              64.680581
            ],
            [
              -160.791614,
              64.623055
            ],
            [
              -160.793356,
              64.619317
            ],
            [
              -160.802048,
              64.610352
            ],
            [
              -160.940493,
              64.55
            ],
            [
              -160.970555,
              64.543884
            ],
            [
              -160.992894,
              64.541295
            ],
            [
              -161.049148,
              64.540952
            ],
            [
              -161.052348,
              64.537395
            ],
            [
              -161.055549,
              64.532416
            ],
            [
              -161.05306,
              64.528504
            ],
            [
              -161.045947,
              64.524948
            ],
            [
              -161.037534,
              64.522246
            ],
            [
              -161.01714,
              64.517124
            ],
            [
              -161.013228,
              64.507521
            ],
            [
              -161.015095,
              64.502124
            ],
            [
              -161.024185,
              64.499719
            ],
            [
              -161.078031,
              64.494094
            ],
            [
              -161.155518,
              64.494687
            ],
            [
              -161.198029,
              64.496626
            ],
            [
              -161.234092,
              64.500365
            ],
            [
              -161.321343,
              64.513865
            ],
            [
              -161.351145,
              64.521382
            ],
            [
              -161.362901,
              64.526913
            ],
            [
              -161.373572,
              64.535028
            ],
            [
              -161.388621,
              64.532783
            ],
            [
              -161.469046,
              64.506575
            ],
            [
              -161.479093,
              64.486401
            ],
            [
              -161.504903,
              64.423074
            ],
            [
              -161.492926,
              64.407851
            ],
            [
              -161.470182,
              64.418814
            ],
            [
              -161.463026,
              64.420074
            ],
            [
              -161.410382,
              64.422107
            ],
            [
              -161.313668,
              64.400874
            ],
            [
              -161.263519,
              64.398166
            ],
            [
              -161.228941,
              64.370747
            ],
            [
              -160.976038,
              64.235761
            ],
            [
              -160.946857,
              64.204158
            ],
            [
              -160.953596,
              64.197775
            ],
            [
              -160.956425,
              64.191732
            ],
            [
              -160.955132,
              64.13803
            ],
            [
              -160.951641,
              64.090067
            ],
            [
              -160.93374,
              64.049729
            ],
            [
              -160.892455,
              63.985943
            ],
            [
              -160.877686,
              63.977265
            ],
            [
              -160.851979,
              63.954409
            ],
            [
              -160.810798,
              63.904646
            ],
            [
              -160.787624,
              63.869196
            ],
            [
              -160.766291,
              63.835189
            ],
            [
              -160.761974,
              63.793453
            ],
            [
              -160.76556,
              63.773552
            ],
            [
              -160.783304,
              63.752893
            ],
            [
              -160.809089,
              63.731332
            ],
            [
              -160.904353,
              63.658024
            ],
            [
              -160.924877,
              63.644814
            ],
            [
              -161.036049,
              63.579566
            ],
            [
              -161.102721,
              63.5478
            ],
            [
              -161.119964,
              63.532544
            ],
            [
              -161.13423,
              63.506735
            ],
            [
              -161.136758,
              63.504525
            ],
            [
              -161.191163,
              63.490072
            ],
            [
              -161.310181,
              63.471312
            ],
            [
              -161.450463,
              63.457178
            ],
            [
              -161.553077,
              63.449217
            ],
            [
              -161.583772,
              63.447857
            ],
            [
              -161.591632,
              63.454244
            ],
            [
              -161.676526,
              63.465003
            ],
            [
              -161.70563,
              63.464061
            ],
            [
              -161.765832,
              63.453803
            ],
            [
              -161.839897,
              63.447313
            ],
            [
              -161.930714,
              63.444843
            ],
            [
              -162.025552,
              63.447539
            ],
            [
              -162.039444,
              63.45893
            ],
            [
              -162.050543,
              63.470589
            ],
            [
              -162.050132,
              63.47285
            ],
            [
              -162.045709,
              63.475434
            ],
            [
              -162.041687,
              63.48965
            ],
            [
              -162.073156,
              63.513768
            ],
            [
              -162.108597,
              63.511927
            ],
            [
              -162.123249,
              63.512807
            ],
            [
              -162.151574,
              63.517952
            ],
            [
              -162.190145,
              63.529886
            ],
            [
              -162.252411,
              63.541753
            ],
            [
              -162.296731,
              63.540108
            ],
            [
              -162.301471,
              63.53735
            ],
            [
              -162.288532,
              63.526412
            ],
            [
              -162.267833,
              63.495084
            ],
            [
              -162.268242,
              63.490799
            ],
            [
              -162.271089,
              63.487711
            ],
            [
              -162.301869,
              63.473422
            ],
            [
              -162.352274,
              63.454069
            ],
            [
              -162.384625,
              63.435797
            ],
            [
              -162.42153,
              63.409014
            ],
            [
              -162.428744,
              63.401055
            ],
            [
              -162.426095,
              63.393651
            ],
            [
              -162.432169,
              63.382606
            ],
            [
              -162.437059,
              63.377836
            ],
            [
              -162.571695,
              63.285556
            ],
            [
              -162.593381202272,
              63.272282238119196
            ],
            [
              -162.383082,
              63.271816
            ],
            [
              -162.383076,
              63.185195
            ],
            [
              -162.192503,
              63.185184
            ],
            [
              -162.192443,
              63.098556
            ],
            [
              -162.001798,
              63.098536
            ],
            [
              -162.001769,
              63.011919
            ],
            [
              -161.452121,
              63.011935
            ],
            [
              -160.85799,
              63.011953
            ],
            [
              -160.858004,
              63.271838
            ],
            [
              -160.667361,
              63.271845
            ],
            [
              -160.667367,
              63.358459
            ],
            [
              -160.690263,
              63.358485
            ],
            [
              -160.690294,
              63.531689
            ],
            [
              -160.497375,
              63.531713
            ],
            [
              -160.497381,
              63.618343
            ],
            [
              -160.111522,
              63.618351
            ],
            [
              -160.111498,
              63.704972
            ],
            [
              -159.932739,
              63.704973
            ],
            [
              -159.932731,
              63.791589
            ],
            [
              -159.737451,
              63.791591
            ],
            [
              -159.737475,
              64.051459
            ],
            [
              -159.947288,
              64.051459
            ],
            [
              -159.962255,
              64.397927
            ],
            [
              -159.96229,
              64.744377
            ],
            [
              -159.774897,
              64.744382
            ],
            [
              -159.774921,
              64.917565
            ],
            [
              -159.572173,
              64.917597
            ],
            [
              -159.582715,
              65.090912
            ],
            [
              -159.582736,
              65.264026
            ],
            [
              -159.377365,
              65.264033
            ],
            [
              -159.385483,
              65.437236
            ],
            [
              -159.385578,
              65.523835
            ],
            [
              -159.593506,
              65.523835
            ],
            [
              -159.8017,
              65.523826
            ],
            [
              -159.801692,
              65.437231
            ],
            [
              -160.634052,
              65.437216
            ],
            [
              -161.457968,
              65.437205
            ],
            [
              -162.331036,
              65.437158
            ],
            [
              -162.824941,
              65.437181
            ],
            [
              -163.755391,
              65.437165
            ],
            [
              -163.755407,
              65.610367
            ],
            [
              -163.963498,
              65.610363
            ],
            [
              -163.963516,
              65.783561
            ],
            [
              -164.244152,
              65.783558
            ],
            [
              -164.244191,
              66.129946
            ],
            [
              -164.318913,
              66.129944
            ],
            [
              -164.318936,
              66.476319
            ],
            [
              -164.395887,
              66.476324
            ],
            [
              -164.39594352714502,
              66.581086316026
            ],
            [
              -164.400724,
              66.58111
            ],
            [
              -164.711009,
              66.542541
            ],
            [
              -165.124026,
              66.473179
            ],
            [
              -165.187082,
              66.465154
            ],
            [
              -165.407204,
              66.420441
            ],
            [
              -165.6686,
              66.361971
            ],
            [
              -165.80503,
              66.33331
            ],
            [
              -165.869233,
              66.316112
            ],
            [
              -165.882496,
              66.311603
            ],
            [
              -166.330971,
              66.189514
            ],
            [
              -166.526162,
              66.141849
            ],
            [
              -166.597243,
              66.118919
            ],
            [
              -166.827684,
              66.051277
            ],
            [
              -166.956089,
              66.007711
            ],
            [
              -166.977872,
              65.996247
            ],
            [
              -167.139524,
              65.948095
            ],
            [
              -167.246146,
              65.911408
            ],
            [
              -167.314935,
              65.885039
            ],
            [
              -167.539643,
              65.820836
            ],
            [
              -167.73569,
              65.776124
            ],
            [
              -167.857216,
              65.754341
            ],
            [
              -167.979889,
              65.727972
            ],
            [
              -168.103708,
              65.685552
            ],
            [
              -168.12893,
              65.655744
            ]
          ]
        ],
        [
          [
            [
              -168.952766,
              65.758911
            ],
            [
              -168.954515,
              65.757144
            ],
            [
              -168.951388,
              65.749319
            ],
            [
              -168.94076,
              65.742714
            ],
            [
              -168.93122,
              65.73894
            ],
            [
              -168.903439,
              65.738454
            ],
            [
              -168.898754,
              65.739709
            ],
            [
              -168.893219,
              65.744705
            ],
            [
              -168.902235,
              65.769665
            ],
            [
              -168.915518,
              65.770484
            ],
            [
              -168.93724,
              65.767116
            ],
            [
              -168.947278,
              65.763817
            ],
            [
              -168.952766,
              65.758911
            ]
          ]
        ],
        [
          [
            [
              -171.840382,
              63.547724
            ],
            [
              -171.849984,
              63.485039
            ],
            [
              -171.824872,
              63.437141
            ],
            [
              -171.818259,
              63.429452
            ],
            [
              -171.795297,
              63.407853
            ],
            [
              -171.739321,
              63.366114
            ],
            [
              -171.667115,
              63.356166
            ],
            [
              -171.562263,
              63.334591
            ],
            [
              -171.464455,
              63.306915
            ],
            [
              -171.433319,
              63.307578
            ],
            [
              -171.333089,
              63.335393
            ],
            [
              -171.290324,
              63.355383
            ],
            [
              -171.285411,
              63.366464
            ],
            [
              -171.288265,
              63.374833
            ],
            [
              -171.287157,
              63.376642
            ],
            [
              -171.280185,
              63.381543
            ],
            [
              -171.269249,
              63.385386
            ],
            [
              -171.100855,
              63.42342
            ],
            [
              -171.067663,
              63.424579
            ],
            [
              -170.967475,
              63.42373
            ],
            [
              -170.92345,
              63.420859
            ],
            [
              -170.865412,
              63.414229
            ],
            [
              -170.712572,
              63.385975
            ],
            [
              -170.55895,
              63.354989
            ],
            [
              -170.512102,
              63.341881
            ],
            [
              -170.430656,
              63.314284
            ],
            [
              -170.364806,
              63.285596
            ],
            [
              -170.337275,
              63.266308
            ],
            [
              -170.30363,
              63.238692
            ],
            [
              -170.277721,
              63.208819
            ],
            [
              -170.277915,
              63.200239
            ],
            [
              -170.279881,
              63.197108
            ],
            [
              -170.285648,
              63.19457
            ],
            [
              -170.281388,
              63.186821
            ],
            [
              -170.263032,
              63.179147
            ],
            [
              -170.193695,
              63.177434
            ],
            [
              -170.181985,
              63.178804
            ],
            [
              -170.186485,
              63.181618
            ],
            [
              -170.174421,
              63.185464
            ],
            [
              -170.154072,
              63.186402
            ],
            [
              -170.124354,
              63.183665
            ],
            [
              -170.101301,
              63.1793
            ],
            [
              -170.053402,
              63.168858
            ],
            [
              -170.051062,
              63.167489
            ],
            [
              -170.049622,
              63.163377
            ],
            [
              -170.021208,
              63.1495
            ],
            [
              -170.006196,
              63.14454
            ],
            [
              -169.987936,
              63.142975
            ],
            [
              -169.944056,
              63.13236
            ],
            [
              -169.88123,
              63.105848
            ],
            [
              -169.838511,
              63.084339
            ],
            [
              -169.829912,
              63.07855
            ],
            [
              -169.788466,
              63.043015
            ],
            [
              -169.734938,
              62.976617
            ],
            [
              -169.734938,
              62.974468
            ],
            [
              -169.757514,
              62.963722
            ],
            [
              -169.757249,
              62.960087
            ],
            [
              -169.746736,
              62.955991
            ],
            [
              -169.638309,
              62.937527
            ],
            [
              -169.568016,
              62.976879
            ],
            [
              -169.572777,
              63.022118
            ],
            [
              -169.576965,
              63.027025
            ],
            [
              -169.575873,
              63.03645
            ],
            [
              -169.561131,
              63.055178
            ],
            [
              -169.534984,
              63.074355
            ],
            [
              -169.51365,
              63.084717
            ],
            [
              -169.471949,
              63.098565
            ],
            [
              -169.436748,
              63.113579
            ],
            [
              -169.396308,
              63.136617
            ],
            [
              -169.375667,
              63.151269
            ],
            [
              -169.303477,
              63.164439
            ],
            [
              -169.262039,
              63.169936
            ],
            [
              -169.198398,
              63.176011
            ],
            [
              -169.105808,
              63.178803
            ],
            [
              -169.042674,
              63.176511
            ],
            [
              -168.983024,
              63.169671
            ],
            [
              -168.963577,
              63.167104
            ],
            [
              -168.950091,
              63.160895
            ],
            [
              -168.889683,
              63.147708
            ],
            [
              -168.871465,
              63.146009
            ],
            [
              -168.85875,
              63.146958
            ],
            [
              -168.818344,
              63.163224
            ],
            [
              -168.789266,
              63.179646
            ],
            [
              -168.783239,
              63.184131
            ],
            [
              -168.751537,
              63.217962
            ],
            [
              -168.716872,
              63.256316
            ],
            [
              -168.686675,
              63.293022
            ],
            [
              -168.685145,
              63.296427
            ],
            [
              -168.692939,
              63.302282
            ],
            [
              -168.796086,
              63.308781
            ],
            [
              -168.932623,
              63.32914
            ],
            [
              -168.936333,
              63.330622
            ],
            [
              -168.937385,
              63.333789
            ],
            [
              -168.999241,
              63.341249
            ],
            [
              -169.05195,
              63.343127
            ],
            [
              -169.087914,
              63.340937
            ],
            [
              -169.101961,
              63.338022
            ],
            [
              -169.267598,
              63.343995
            ],
            [
              -169.281422,
              63.348381
            ],
            [
              -169.31297,
              63.353335
            ],
            [
              -169.38408,
              63.356733
            ],
            [
              -169.415329,
              63.355943
            ],
            [
              -169.520524,
              63.365941
            ],
            [
              -169.546934,
              63.372792
            ],
            [
              -169.554375,
              63.377158
            ],
            [
              -169.565439,
              63.385563
            ],
            [
              -169.566562,
              63.388725
            ],
            [
              -169.579892,
              63.40287
            ],
            [
              -169.643167,
              63.427802
            ],
            [
              -169.656474,
              63.429929
            ],
            [
              -169.747634,
              63.432756
            ],
            [
              -169.857078,
              63.441975
            ],
            [
              -169.906304,
              63.457519
            ],
            [
              -169.974858,
              63.470618
            ],
            [
              -170.007943,
              63.475428
            ],
            [
              -170.026953,
              63.480702
            ],
            [
              -170.047114,
              63.490135
            ],
            [
              -170.040919,
              63.523411
            ],
            [
              -170.076689,
              63.587988
            ],
            [
              -170.095833,
              63.612701
            ],
            [
              -170.113066,
              63.616245
            ],
            [
              -170.14004,
              63.616696
            ],
            [
              -170.154754,
              63.619072
            ],
            [
              -170.176413,
              63.625489
            ],
            [
              -170.257808,
              63.666611
            ],
            [
              -170.26748,
              63.675816
            ],
            [
              -170.281988,
              63.68502
            ],
            [
              -170.315839,
              63.691923
            ],
            [
              -170.344855,
              63.694225
            ],
            [
              -170.354527,
              63.691924
            ],
            [
              -170.359363,
              63.687321
            ],
            [
              -170.373871,
              63.687322
            ],
            [
              -170.441066,
              63.691981
            ],
            [
              -170.462947,
              63.698022
            ],
            [
              -170.472181,
              63.698677
            ],
            [
              -170.488192,
              63.696723
            ],
            [
              -170.606282,
              63.672732
            ],
            [
              -170.698156,
              63.646778
            ],
            [
              -170.816581,
              63.606329
            ],
            [
              -170.859032,
              63.587503
            ],
            [
              -170.897581,
              63.574676
            ],
            [
              -170.907197,
              63.572107
            ],
            [
              -170.950817,
              63.570127
            ],
            [
              -171.044486,
              63.580431
            ],
            [
              -171.103558,
              63.589268
            ],
            [
              -171.202557,
              63.606897
            ],
            [
              -171.381677,
              63.630646
            ],
            [
              -171.521859,
              63.658797
            ],
            [
              -171.609439,
              63.679832
            ],
            [
              -171.632194,
              63.688601
            ],
            [
              -171.640027,
              63.69343
            ],
            [
              -171.649923,
              63.70254
            ],
            [
              -171.65263,
              63.708523
            ],
            [
              -171.646692,
              63.729425
            ],
            [
              -171.648935,
              63.73487
            ],
            [
              -171.652664,
              63.73661
            ],
            [
              -171.652912,
              63.73922
            ],
            [
              -171.641477,
              63.745559
            ],
            [
              -171.638246,
              63.749536
            ],
            [
              -171.638991,
              63.759231
            ],
            [
              -171.643963,
              63.770791
            ],
            [
              -171.659873,
              63.775762
            ],
            [
              -171.66733,
              63.785581
            ],
            [
              -171.673296,
              63.788067
            ],
            [
              -171.682494,
              63.78757
            ],
            [
              -171.692686,
              63.782598
            ],
            [
              -171.699647,
              63.781728
            ],
            [
              -171.738178,
              63.784711
            ],
            [
              -171.743398,
              63.782971
            ],
            [
              -171.737432,
              63.76035
            ],
            [
              -171.727986,
              63.744938
            ],
            [
              -171.725748,
              63.734745
            ],
            [
              -171.727986,
              63.726791
            ],
            [
              -171.733206,
              63.720327
            ],
            [
              -171.739918,
              63.717096
            ],
            [
              -171.754336,
              63.71896
            ],
            [
              -171.755552,
              63.701173
            ],
            [
              -171.742338,
              63.665494
            ],
            [
              -171.743979,
              63.654905
            ],
            [
              -171.757081,
              63.640252
            ],
            [
              -171.791881,
              63.620625
            ],
            [
              -171.833681,
              63.580074
            ],
            [
              -171.840382,
              63.547724
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "02",
      "COUNTYFP": "230",
      "COUNTYNS": "02339479",
      "AFFGEOID": "0500000US02230",
      "GEOID": "02230",
      "NAME": "Skagway",
      "LSAD": "12",
      "ALAND": 1124107036,
      "AWATER": 28607173,
      "County_state": "Skagway,02"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -135.477436,
              59.799626
            ],
            [
              -135.717560201194,
              59.7309280295579
            ],
            [
              -135.37502,
              59.350033
            ],
            [
              -135.029245,
              59.345364
            ],
            [
              -135.016206,
              59.361005
            ],
            [
              -135.010033,
              59.381288
            ],
            [
              -135.016206,
              59.395398
            ],
            [
              -135.067356,
              59.421855
            ],
            [
              -135.071239,
              59.453309
            ],
            [
              -135.026328,
              59.474658
            ],
            [
              -135.026373,
              59.535741
            ],
            [
              -135.027456,
              59.563692
            ],
            [
              -135.114588,
              59.623415
            ],
            [
              -135.153113,
              59.625159
            ],
            [
              -135.162745,
              59.630635
            ],
            [
              -135.166736,
              59.63224
            ],
            [
              -135.214344,
              59.664343
            ],
            [
              -135.231148,
              59.697176
            ],
            [
              -135.234447,
              59.697931
            ],
            [
              -135.243777,
              59.69795
            ],
            [
              -135.254125,
              59.701339
            ],
            [
              -135.477436,
              59.799626
            ]
          ]
        ]
      ]
    }
  }
]
};