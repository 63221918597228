export const counties_la = {
  "type":"FeatureCollection","name":"la_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "105",
      "COUNTYNS": "00559500",
      "AFFGEOID": "0500000US22105",
      "GEOID": "22105",
      "NAME": "Tangipahoa",
      "LSAD": "15",
      "ALAND": 2049488093,
      "AWATER": 136678798,
      "County_state": "Tangipahoa,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.567171,
              30.824806
            ],
            [
              -90.567485,
              30.754113
            ],
            [
              -90.56673,
              30.747784
            ],
            [
              -90.565857,
              30.663902
            ],
            [
              -90.567165,
              30.650023
            ],
            [
              -90.567192,
              30.643089
            ],
            [
              -90.566901,
              30.577052
            ],
            [
              -90.567138,
              30.544199
            ],
            [
              -90.5669,
              30.52613
            ],
            [
              -90.566906,
              30.48706
            ],
            [
              -90.558229,
              30.478919
            ],
            [
              -90.550044,
              30.467662
            ],
            [
              -90.552854,
              30.462921
            ],
            [
              -90.54596,
              30.430119
            ],
            [
              -90.519949,
              30.414219
            ],
            [
              -90.488347,
              30.355418
            ],
            [
              -90.47376,
              30.345246
            ],
            [
              -90.48537,
              30.321693
            ],
            [
              -90.485826,
              30.276992
            ],
            [
              -90.420533,
              30.279837
            ],
            [
              -90.39928,
              30.285981
            ],
            [
              -90.372264,
              30.283638
            ],
            [
              -90.350308,
              30.296831
            ],
            [
              -90.320464,
              30.298985
            ],
            [
              -90.300923,
              30.29468
            ],
            [
              -90.243402,
              30.269971
            ],
            [
              -90.160636,
              30.234337
            ],
            [
              -90.185508,
              30.288178
            ],
            [
              -90.192061,
              30.310783
            ],
            [
              -90.242062,
              30.359927
            ],
            [
              -90.243649,
              30.361581
            ],
            [
              -90.243555,
              30.363827
            ],
            [
              -90.244305,
              30.503592
            ],
            [
              -90.244811,
              30.580169
            ],
            [
              -90.243172,
              30.596015
            ],
            [
              -90.248694,
              30.616078
            ],
            [
              -90.243669,
              30.646933
            ],
            [
              -90.253241,
              30.664346
            ],
            [
              -90.250365,
              30.695552
            ],
            [
              -90.257535,
              30.71024
            ],
            [
              -90.255648,
              30.711905
            ],
            [
              -90.253526,
              30.735549
            ],
            [
              -90.264536,
              30.756972
            ],
            [
              -90.262659,
              30.765291
            ],
            [
              -90.277626,
              30.779879
            ],
            [
              -90.279506,
              30.795005
            ],
            [
              -90.293345,
              30.80649
            ],
            [
              -90.290243,
              30.819787
            ],
            [
              -90.301169,
              30.850788
            ],
            [
              -90.317689,
              30.873639
            ],
            [
              -90.316871,
              30.890932
            ],
            [
              -90.329897,
              30.890907
            ],
            [
              -90.333921,
              30.90552
            ],
            [
              -90.34671,
              30.905427
            ],
            [
              -90.3472409612406,
              31.0003589881184
            ],
            [
              -90.369371,
              31.000335
            ],
            [
              -90.380536,
              30.999872
            ],
            [
              -90.422117,
              30.99981
            ],
            [
              -90.426849,
              30.999776
            ],
            [
              -90.437351,
              30.99973
            ],
            [
              -90.441725,
              30.999729
            ],
            [
              -90.442479,
              30.999722
            ],
            [
              -90.4566267908343,
              30.9997560101883
            ],
            [
              -90.474094,
              30.999798
            ],
            [
              -90.475928,
              30.99974
            ],
            [
              -90.477284,
              30.999717
            ],
            [
              -90.485876,
              30.99974
            ],
            [
              -90.486749,
              30.999693
            ],
            [
              -90.54761548218009,
              30.9997232645164
            ],
            [
              -90.567195,
              30.999733
            ],
            [
              -90.567171,
              30.824806
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "001",
      "COUNTYNS": "00558389",
      "AFFGEOID": "0500000US22001",
      "GEOID": "22001",
      "NAME": "Acadia",
      "LSAD": "15",
      "ALAND": 1696777754,
      "AWATER": 6021519,
      "County_state": "Acadia,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.632161,
              30.314289
            ],
            [
              -92.625139,
              30.301335
            ],
            [
              -92.627726,
              30.264845
            ],
            [
              -92.622484,
              30.241758
            ],
            [
              -92.626691,
              30.230525
            ],
            [
              -92.6009,
              30.205636
            ],
            [
              -92.583089,
              30.200968
            ],
            [
              -92.591055,
              30.201623
            ],
            [
              -92.591129,
              30.182365
            ],
            [
              -92.618728,
              30.175524
            ],
            [
              -92.607983,
              30.15858
            ],
            [
              -92.621773,
              30.147967
            ],
            [
              -92.62864,
              30.133743
            ],
            [
              -92.618338,
              30.10415
            ],
            [
              -92.625484,
              30.09233
            ],
            [
              -92.604815,
              30.098338
            ],
            [
              -92.587311,
              30.096118
            ],
            [
              -92.557126,
              30.115993
            ],
            [
              -92.534638,
              30.107852
            ],
            [
              -92.505971,
              30.079282
            ],
            [
              -92.469755,
              30.06203
            ],
            [
              -92.423166,
              30.04932
            ],
            [
              -92.37615,
              30.066566
            ],
            [
              -92.332364,
              30.101525
            ],
            [
              -92.322939,
              30.117274
            ],
            [
              -92.297912,
              30.136638
            ],
            [
              -92.288856,
              30.149422
            ],
            [
              -92.284675,
              30.147443
            ],
            [
              -92.275312,
              30.146134
            ],
            [
              -92.246328,
              30.185459
            ],
            [
              -92.25049,
              30.203198
            ],
            [
              -92.233996,
              30.219685
            ],
            [
              -92.209894,
              30.226357
            ],
            [
              -92.210202,
              30.241344
            ],
            [
              -92.176097,
              30.241076
            ],
            [
              -92.176279,
              30.269923
            ],
            [
              -92.159137,
              30.269839
            ],
            [
              -92.159509,
              30.284542
            ],
            [
              -92.142014,
              30.284523
            ],
            [
              -92.142184,
              30.298817
            ],
            [
              -92.141509,
              30.320764
            ],
            [
              -92.15846,
              30.320806
            ],
            [
              -92.158554,
              30.342445
            ],
            [
              -92.175775,
              30.349572
            ],
            [
              -92.176059,
              30.400471
            ],
            [
              -92.1765,
              30.422691
            ],
            [
              -92.176444,
              30.436852
            ],
            [
              -92.24425,
              30.436678
            ],
            [
              -92.244795,
              30.480193
            ],
            [
              -92.264419,
              30.480194
            ],
            [
              -92.413567,
              30.480061
            ],
            [
              -92.493259,
              30.480499
            ],
            [
              -92.627041,
              30.480635
            ],
            [
              -92.631987,
              30.481147
            ],
            [
              -92.600597,
              30.437483
            ],
            [
              -92.594586,
              30.420339
            ],
            [
              -92.60406,
              30.400042
            ],
            [
              -92.608402,
              30.385884
            ],
            [
              -92.629695,
              30.378926
            ],
            [
              -92.619505,
              30.340052
            ],
            [
              -92.632161,
              30.314289
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "013",
      "COUNTYNS": "00558445",
      "AFFGEOID": "0500000US22013",
      "GEOID": "22013",
      "NAME": "Bienville",
      "LSAD": "15",
      "ALAND": 2101207370,
      "AWATER": 27945994,
      "County_state": "Bienville,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.408126,
              32.250517
            ],
            [
              -93.42819,
              32.235897
            ],
            [
              -93.213143,
              32.235757
            ],
            [
              -93.211144,
              32.192231
            ],
            [
              -93.191901,
              32.178362
            ],
            [
              -93.187162,
              32.148412
            ],
            [
              -92.93908,
              32.147942
            ],
            [
              -92.814737,
              32.146907
            ],
            [
              -92.822191,
              32.162594
            ],
            [
              -92.817149,
              32.161698
            ],
            [
              -92.798816,
              32.181325
            ],
            [
              -92.800963,
              32.210725
            ],
            [
              -92.787498,
              32.21497
            ],
            [
              -92.773173,
              32.235984
            ],
            [
              -92.774762,
              32.317878
            ],
            [
              -92.777197,
              32.453455
            ],
            [
              -92.879268,
              32.454118
            ],
            [
              -92.880243,
              32.557651
            ],
            [
              -92.880805,
              32.585277
            ],
            [
              -92.938598,
              32.58461
            ],
            [
              -93.002742,
              32.584095
            ],
            [
              -93.178767,
              32.584916
            ],
            [
              -93.168158,
              32.549719
            ],
            [
              -93.171274,
              32.526369
            ],
            [
              -93.146446,
              32.469962
            ],
            [
              -93.147193,
              32.455173
            ],
            [
              -93.236673,
              32.454212
            ],
            [
              -93.236656,
              32.410071
            ],
            [
              -93.372781,
              32.410153
            ],
            [
              -93.388612,
              32.396441
            ],
            [
              -93.412466,
              32.400865
            ],
            [
              -93.42338,
              32.39774
            ],
            [
              -93.424582,
              32.377261
            ],
            [
              -93.402923,
              32.368727
            ],
            [
              -93.426697,
              32.309213
            ],
            [
              -93.41746,
              32.283579
            ],
            [
              -93.407172,
              32.284401
            ],
            [
              -93.408126,
              32.250517
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "035",
      "COUNTYNS": "00558534",
      "AFFGEOID": "0500000US22035",
      "GEOID": "22035",
      "NAME": "East Carroll",
      "LSAD": "15",
      "ALAND": 1089619193,
      "AWATER": 56273972,
      "County_state": "East Carroll,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.430908,
              32.560477
            ],
            [
              -91.457643,
              32.537807
            ],
            [
              -91.306696,
              32.537861
            ],
            [
              -91.200126,
              32.537683
            ],
            [
              -91.133713,
              32.537912
            ],
            [
              -91.108421,
              32.562623
            ],
            [
              -91.0697862054881,
              32.562793318519
            ],
            [
              -91.069354,
              32.563052
            ],
            [
              -91.049312,
              32.573624
            ],
            [
              -91.0434509306581,
              32.576269553442096
            ],
            [
              -91.03617,
              32.579556
            ],
            [
              -91.030991,
              32.583347
            ],
            [
              -91.022943,
              32.591848
            ],
            [
              -91.016231,
              32.596953
            ],
            [
              -91.013723,
              32.598419
            ],
            [
              -91.010228,
              32.601927
            ],
            [
              -91.004599,
              32.60978
            ],
            [
              -91.002997,
              32.614678
            ],
            [
              -91.002962,
              32.622555
            ],
            [
              -91.0035,
              32.624845
            ],
            [
              -91.00682,
              32.631261
            ],
            [
              -91.014286,
              32.640482
            ],
            [
              -91.019115,
              32.643845
            ],
            [
              -91.025769,
              32.646573
            ],
            [
              -91.030181,
              32.644052
            ],
            [
              -91.038415,
              32.636443
            ],
            [
              -91.040401,
              32.632521
            ],
            [
              -91.041448,
              32.625135
            ],
            [
              -91.043263,
              32.620779
            ],
            [
              -91.0489,
              32.613556
            ],
            [
              -91.049796,
              32.607188
            ],
            [
              -91.061354,
              32.605372
            ],
            [
              -91.079506,
              32.60068
            ],
            [
              -91.087784,
              32.59707
            ],
            [
              -91.105704,
              32.590879
            ],
            [
              -91.112764,
              32.590186
            ],
            [
              -91.118641,
              32.585139
            ],
            [
              -91.119854,
              32.584795
            ],
            [
              -91.125108,
              32.585187
            ],
            [
              -91.127912,
              32.586493
            ],
            [
              -91.13528,
              32.591651
            ],
            [
              -91.141148,
              32.597209
            ],
            [
              -91.144074,
              32.600613
            ],
            [
              -91.146204,
              32.604144
            ],
            [
              -91.151318,
              32.615919
            ],
            [
              -91.153556,
              32.626181
            ],
            [
              -91.153821,
              32.631282
            ],
            [
              -91.153744,
              32.635228
            ],
            [
              -91.152699,
              32.640757
            ],
            [
              -91.152081,
              32.641508
            ],
            [
              -91.149753,
              32.644041
            ],
            [
              -91.144347,
              32.648029
            ],
            [
              -91.142038,
              32.649265
            ],
            [
              -91.138712,
              32.649774
            ],
            [
              -91.137638,
              32.650621
            ],
            [
              -91.130928,
              32.65887
            ],
            [
              -91.127723,
              32.665343
            ],
            [
              -91.118258,
              32.674075
            ],
            [
              -91.104443,
              32.682434
            ],
            [
              -91.098762,
              32.685291
            ],
            [
              -91.081145,
              32.691127
            ],
            [
              -91.076061,
              32.693751
            ],
            [
              -91.063946,
              32.702926
            ],
            [
              -91.057043,
              32.712576
            ],
            [
              -91.054749,
              32.719229
            ],
            [
              -91.054481,
              32.722259
            ],
            [
              -91.056999,
              32.72558
            ],
            [
              -91.060766,
              32.727494
            ],
            [
              -91.077176,
              32.732534
            ],
            [
              -91.090573,
              32.7361
            ],
            [
              -91.113652,
              32.73997
            ],
            [
              -91.123152,
              32.742798
            ],
            [
              -91.131691,
              32.743107
            ],
            [
              -91.150244,
              32.741786
            ],
            [
              -91.154461,
              32.742339
            ],
            [
              -91.15861,
              32.743449
            ],
            [
              -91.163389,
              32.747009
            ],
            [
              -91.165328,
              32.751301
            ],
            [
              -91.165814,
              32.757842
            ],
            [
              -91.164968,
              32.761984
            ],
            [
              -91.157614,
              32.776033
            ],
            [
              -91.156918,
              32.780343
            ],
            [
              -91.158583,
              32.781096
            ],
            [
              -91.164397,
              32.785821
            ],
            [
              -91.161412,
              32.800004
            ],
            [
              -91.161669,
              32.812465
            ],
            [
              -91.158336,
              32.822304
            ],
            [
              -91.157155,
              32.823823
            ],
            [
              -91.152174,
              32.826673
            ],
            [
              -91.149704,
              32.83122
            ],
            [
              -91.145002,
              32.84287
            ],
            [
              -91.143559,
              32.844739
            ],
            [
              -91.13789,
              32.848975
            ],
            [
              -91.127886,
              32.855059
            ],
            [
              -91.124725,
              32.854861
            ],
            [
              -91.116091,
              32.855641
            ],
            [
              -91.105631,
              32.858396
            ],
            [
              -91.086683,
              32.873392
            ],
            [
              -91.070602,
              32.888659
            ],
            [
              -91.068186,
              32.891929
            ],
            [
              -91.064449,
              32.901064
            ],
            [
              -91.063809,
              32.903709
            ],
            [
              -91.0637985738819,
              32.903930450747694
            ],
            [
              -91.063684,
              32.906364
            ],
            [
              -91.064854,
              32.91652
            ],
            [
              -91.063875,
              32.922692
            ],
            [
              -91.064804,
              32.926464
            ],
            [
              -91.070547,
              32.936036
            ],
            [
              -91.072075,
              32.937832
            ],
            [
              -91.080431,
              32.943206
            ],
            [
              -91.081913,
              32.944768
            ],
            [
              -91.083084,
              32.947909
            ],
            [
              -91.081892,
              32.949435
            ],
            [
              -91.080507,
              32.950123
            ],
            [
              -91.078904,
              32.951818
            ],
            [
              -91.080355,
              32.962794
            ],
            [
              -91.086802,
              32.976266
            ],
            [
              -91.090887,
              32.981174
            ],
            [
              -91.094265,
              32.984371
            ],
            [
              -91.09693,
              32.986412
            ],
            [
              -91.106581,
              32.988938
            ],
            [
              -91.111757,
              32.988361
            ],
            [
              -91.125107,
              32.984669
            ],
            [
              -91.134414,
              32.980533
            ],
            [
              -91.135517,
              32.979657
            ],
            [
              -91.138585,
              32.971352
            ],
            [
              -91.137524,
              32.96955
            ],
            [
              -91.13305,
              32.966541
            ],
            [
              -91.130947,
              32.963815
            ],
            [
              -91.130721,
              32.962257
            ],
            [
              -91.131243,
              32.960928
            ],
            [
              -91.133335,
              32.959056
            ],
            [
              -91.136628,
              32.957349
            ],
            [
              -91.137167,
              32.95652
            ],
            [
              -91.137863,
              32.952756
            ],
            [
              -91.135507,
              32.946164
            ],
            [
              -91.131289,
              32.930049
            ],
            [
              -91.131304,
              32.926919
            ],
            [
              -91.132115,
              32.923122
            ],
            [
              -91.134041,
              32.917676
            ],
            [
              -91.145076,
              32.905494
            ],
            [
              -91.15169,
              32.901935
            ],
            [
              -91.159975,
              32.899879
            ],
            [
              -91.170235,
              32.899391
            ],
            [
              -91.175405,
              32.899998
            ],
            [
              -91.181631,
              32.901446
            ],
            [
              -91.196785,
              32.906784
            ],
            [
              -91.199775,
              32.908512
            ],
            [
              -91.208263,
              32.915354
            ],
            [
              -91.211597,
              32.919624
            ],
            [
              -91.212837,
              32.922104
            ],
            [
              -91.213972,
              32.927198
            ],
            [
              -91.214027,
              32.93032
            ],
            [
              -91.21282,
              32.936076
            ],
            [
              -91.210705,
              32.939845
            ],
            [
              -91.20744,
              32.944393
            ],
            [
              -91.199415,
              32.952314
            ],
            [
              -91.20119,
              32.954982
            ],
            [
              -91.20203,
              32.957332
            ],
            [
              -91.201842,
              32.961212
            ],
            [
              -91.199646,
              32.963561
            ],
            [
              -91.197433,
              32.96482
            ],
            [
              -91.186983,
              32.976194
            ],
            [
              -91.182158,
              32.978639
            ],
            [
              -91.173308,
              32.986088
            ],
            [
              -91.168973,
              32.992132
            ],
            [
              -91.166195,
              33.002238
            ],
            [
              -91.166073,
              33.004106
            ],
            [
              -91.265018,
              33.005084
            ],
            [
              -91.267665,
              32.975081
            ],
            [
              -91.280203,
              32.950851
            ],
            [
              -91.30919,
              32.935901
            ],
            [
              -91.31036,
              32.890251
            ],
            [
              -91.321377,
              32.863976
            ],
            [
              -91.326942,
              32.872337
            ],
            [
              -91.339615,
              32.862119
            ],
            [
              -91.347716,
              32.823017
            ],
            [
              -91.339767,
              32.821713
            ],
            [
              -91.344845,
              32.756659
            ],
            [
              -91.377197,
              32.706279
            ],
            [
              -91.374887,
              32.689863
            ],
            [
              -91.398255,
              32.69298
            ],
            [
              -91.401733,
              32.669089
            ],
            [
              -91.395761,
              32.641025
            ],
            [
              -91.404971,
              32.627057
            ],
            [
              -91.396964,
              32.609468
            ],
            [
              -91.426574,
              32.599854
            ],
            [
              -91.445299,
              32.581956
            ],
            [
              -91.430908,
              32.560477
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "113",
      "COUNTYNS": "00559538",
      "AFFGEOID": "0500000US22113",
      "GEOID": "22113",
      "NAME": "Vermilion",
      "LSAD": "15",
      "ALAND": 3038598763,
      "AWATER": 955343208,
      "County_state": "Vermilion,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.723869,
              30.048652
            ],
            [
              -92.738604,
              30.037323
            ],
            [
              -92.61561,
              30.038168
            ],
            [
              -92.614143,
              29.774096
            ],
            [
              -92.61734231526239,
              29.5861534551647
            ],
            [
              -92.615098,
              29.585561
            ],
            [
              -92.568038,
              29.577397
            ],
            [
              -92.536902,
              29.573779
            ],
            [
              -92.473585,
              29.561081
            ],
            [
              -92.40986,
              29.547477
            ],
            [
              -92.364703,
              29.539459
            ],
            [
              -92.323465,
              29.531497
            ],
            [
              -92.280392,
              29.533434
            ],
            [
              -92.25186,
              29.539354
            ],
            [
              -92.21259,
              29.562479
            ],
            [
              -92.158624,
              29.581616
            ],
            [
              -92.105923,
              29.586335
            ],
            [
              -92.064513,
              29.585665
            ],
            [
              -92.041866,
              29.586769
            ],
            [
              -92.048351,
              29.592485
            ],
            [
              -92.065345,
              29.603852
            ],
            [
              -92.048826,
              29.614494
            ],
            [
              -92.016858,
              29.615837
            ],
            [
              -92.034566,
              29.63154
            ],
            [
              -92.058872,
              29.62235
            ],
            [
              -92.091455,
              29.619804
            ],
            [
              -92.108246,
              29.613667
            ],
            [
              -92.126429,
              29.643108
            ],
            [
              -92.136649,
              29.667481
            ],
            [
              -92.103179,
              29.69313
            ],
            [
              -92.117875,
              29.6985
            ],
            [
              -92.148652,
              29.695151
            ],
            [
              -92.169092,
              29.70021
            ],
            [
              -92.200076,
              29.72571
            ],
            [
              -92.208168,
              29.744184
            ],
            [
              -92.199871,
              29.753219
            ],
            [
              -92.171106,
              29.739782
            ],
            [
              -92.17494,
              29.759475
            ],
            [
              -92.149771,
              29.763048
            ],
            [
              -92.136527,
              29.767336
            ],
            [
              -92.123577,
              29.753461
            ],
            [
              -92.115852,
              29.739532
            ],
            [
              -92.062911,
              29.769163
            ],
            [
              -92.00545,
              29.790906
            ],
            [
              -91.978228,
              29.799942
            ],
            [
              -91.981987,
              29.811983
            ],
            [
              -91.982755,
              29.825391
            ],
            [
              -91.9721116903134,
              29.8334623347738
            ],
            [
              -91.993663,
              29.984341
            ],
            [
              -91.982012,
              29.990537
            ],
            [
              -91.964458,
              30.036918
            ],
            [
              -92.023819,
              30.058455
            ],
            [
              -92.066153,
              30.073926
            ],
            [
              -92.072743,
              30.080871
            ],
            [
              -92.088421,
              30.128299
            ],
            [
              -92.119913,
              30.121843
            ],
            [
              -92.128423,
              30.115989
            ],
            [
              -92.170159,
              30.130853
            ],
            [
              -92.202722,
              30.14286
            ],
            [
              -92.22705,
              30.136896
            ],
            [
              -92.279691,
              30.141533
            ],
            [
              -92.284675,
              30.147443
            ],
            [
              -92.288856,
              30.149422
            ],
            [
              -92.297912,
              30.136638
            ],
            [
              -92.322939,
              30.117274
            ],
            [
              -92.332364,
              30.101525
            ],
            [
              -92.37615,
              30.066566
            ],
            [
              -92.423166,
              30.04932
            ],
            [
              -92.469755,
              30.06203
            ],
            [
              -92.505971,
              30.079282
            ],
            [
              -92.534638,
              30.107852
            ],
            [
              -92.557126,
              30.115993
            ],
            [
              -92.587311,
              30.096118
            ],
            [
              -92.604815,
              30.098338
            ],
            [
              -92.625484,
              30.09233
            ],
            [
              -92.637841,
              30.072174
            ],
            [
              -92.65416,
              30.079038
            ],
            [
              -92.684644,
              30.059503
            ],
            [
              -92.723869,
              30.048652
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "039",
      "COUNTYNS": "00558551",
      "AFFGEOID": "0500000US22039",
      "GEOID": "22039",
      "NAME": "Evangeline",
      "LSAD": "15",
      "ALAND": 1715552082,
      "AWATER": 44665790,
      "County_state": "Evangeline,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.630347,
              30.487873
            ],
            [
              -92.631987,
              30.481147
            ],
            [
              -92.627041,
              30.480635
            ],
            [
              -92.493259,
              30.480499
            ],
            [
              -92.464347,
              30.495318
            ],
            [
              -92.458914,
              30.52793
            ],
            [
              -92.449111,
              30.534338
            ],
            [
              -92.415311,
              30.538623
            ],
            [
              -92.359761,
              30.538745
            ],
            [
              -92.262719,
              30.538738
            ],
            [
              -92.262305,
              30.568152
            ],
            [
              -92.211504,
              30.56813
            ],
            [
              -92.211683,
              30.613182
            ],
            [
              -92.193956,
              30.659333
            ],
            [
              -92.172408,
              30.673767
            ],
            [
              -92.172415,
              30.767935
            ],
            [
              -92.206915,
              30.78475
            ],
            [
              -92.211343,
              30.793112
            ],
            [
              -92.212711,
              30.848607
            ],
            [
              -92.237698,
              30.84821
            ],
            [
              -92.238219,
              30.892208
            ],
            [
              -92.280715,
              30.935954
            ],
            [
              -92.280738,
              30.965071
            ],
            [
              -92.330778,
              30.963323
            ],
            [
              -92.341763,
              30.981503
            ],
            [
              -92.38021,
              31.000374
            ],
            [
              -92.409377,
              30.992288
            ],
            [
              -92.418651,
              30.974968
            ],
            [
              -92.43887,
              30.969792
            ],
            [
              -92.459669,
              30.955754
            ],
            [
              -92.48033,
              30.952271
            ],
            [
              -92.480124,
              30.918449
            ],
            [
              -92.498602,
              30.900109
            ],
            [
              -92.509137,
              30.895814
            ],
            [
              -92.547965,
              30.903084
            ],
            [
              -92.561046,
              30.898007
            ],
            [
              -92.597741,
              30.896101
            ],
            [
              -92.597402,
              30.583928
            ],
            [
              -92.582874,
              30.567343
            ],
            [
              -92.605186,
              30.52945
            ],
            [
              -92.62329,
              30.517982
            ],
            [
              -92.614494,
              30.507443
            ],
            [
              -92.630347,
              30.487873
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "023",
      "COUNTYNS": "00558488",
      "AFFGEOID": "0500000US22023",
      "GEOID": "22023",
      "NAME": "Cameron",
      "LSAD": "15",
      "ALAND": 3327836871,
      "AWATER": 1688122644,
      "County_state": "Cameron,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.929208,
              29.802952
            ],
            [
              -93.928808,
              29.79708
            ],
            [
              -93.926504,
              29.78956
            ],
            [
              -93.922407,
              29.785048
            ],
            [
              -93.89847,
              29.771577
            ],
            [
              -93.893862,
              29.767289
            ],
            [
              -93.890821,
              29.761673
            ],
            [
              -93.893829,
              29.753033
            ],
            [
              -93.891637,
              29.744618
            ],
            [
              -93.888821,
              29.742234
            ],
            [
              -93.873941,
              29.73777
            ],
            [
              -93.87002,
              29.735482
            ],
            [
              -93.863204,
              29.724059
            ],
            [
              -93.837971,
              29.690619
            ],
            [
              -93.818995,
              29.704076
            ],
            [
              -93.79925,
              29.71526
            ],
            [
              -93.766048,
              29.7295
            ],
            [
              -93.741948,
              29.736343
            ],
            [
              -93.68364,
              29.747153
            ],
            [
              -93.635304,
              29.752806
            ],
            [
              -93.590786,
              29.755649
            ],
            [
              -93.538462,
              29.763299
            ],
            [
              -93.475252,
              29.769242
            ],
            [
              -93.438973,
              29.768949
            ],
            [
              -93.380563,
              29.765606
            ],
            [
              -93.346439,
              29.762643
            ],
            [
              -93.295573,
              29.775071
            ],
            [
              -93.267456,
              29.778113
            ],
            [
              -93.226934,
              29.777519
            ],
            [
              -93.17693,
              29.770487
            ],
            [
              -93.088182,
              29.749125
            ],
            [
              -93.031157,
              29.736333
            ],
            [
              -92.993128,
              29.723846
            ],
            [
              -92.947493,
              29.708777
            ],
            [
              -92.879992,
              29.680285
            ],
            [
              -92.786465,
              29.641146
            ],
            [
              -92.741306,
              29.622139
            ],
            [
              -92.655029,
              29.596102
            ],
            [
              -92.61734231526239,
              29.5861534551647
            ],
            [
              -92.614143,
              29.774096
            ],
            [
              -92.61561,
              30.038168
            ],
            [
              -92.738604,
              30.037323
            ],
            [
              -92.756546,
              30.039326
            ],
            [
              -92.996371,
              30.038479
            ],
            [
              -93.128409,
              30.03837
            ],
            [
              -93.128475,
              30.052587
            ],
            [
              -93.168262,
              30.051854
            ],
            [
              -93.179107,
              30.052193
            ],
            [
              -93.312527,
              30.052102
            ],
            [
              -93.34175,
              30.052232
            ],
            [
              -93.530545,
              30.051755
            ],
            [
              -93.704009,
              30.052176
            ],
            [
              -93.7227914118907,
              30.051161751793895
            ],
            [
              -93.737446,
              30.037283
            ],
            [
              -93.739158,
              30.032627
            ],
            [
              -93.739734,
              30.023987
            ],
            [
              -93.741078,
              30.021571
            ],
            [
              -93.786935,
              29.99058
            ],
            [
              -93.789431,
              29.987812
            ],
            [
              -93.807815,
              29.954549
            ],
            [
              -93.813735,
              29.935126
            ],
            [
              -93.81655,
              29.920726
            ],
            [
              -93.818998,
              29.914822
            ],
            [
              -93.830374,
              29.894359
            ],
            [
              -93.838374,
              29.882855
            ],
            [
              -93.85434264083091,
              29.8649909997958
            ],
            [
              -93.85514,
              29.864099
            ],
            [
              -93.86357,
              29.857177
            ],
            [
              -93.872446,
              29.85165
            ],
            [
              -93.890679,
              29.843159
            ],
            [
              -93.900728,
              29.836967
            ],
            [
              -93.91636,
              29.824968
            ],
            [
              -93.922744,
              29.818808
            ],
            [
              -93.927992,
              29.80964
            ],
            [
              -93.929208,
              29.802952
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "077",
      "COUNTYNS": "00559449",
      "AFFGEOID": "0500000US22077",
      "GEOID": "22077",
      "NAME": "Pointe Coupee",
      "LSAD": "15",
      "ALAND": 1443519879,
      "AWATER": 86418272,
      "County_state": "Pointe Coupee,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.823072,
              30.856354
            ],
            [
              -91.815258,
              30.848107
            ],
            [
              -91.798459,
              30.828991
            ],
            [
              -91.807295,
              30.811069
            ],
            [
              -91.768759,
              30.785389
            ],
            [
              -91.756279,
              30.760135
            ],
            [
              -91.757942,
              30.74099
            ],
            [
              -91.733955,
              30.703406
            ],
            [
              -91.734813,
              30.666697
            ],
            [
              -91.74643,
              30.658782
            ],
            [
              -91.740996,
              30.632538
            ],
            [
              -91.751357,
              30.603698
            ],
            [
              -91.75698,
              30.562551
            ],
            [
              -91.732517,
              30.510096
            ],
            [
              -91.756193,
              30.497738
            ],
            [
              -91.700933,
              30.497433
            ],
            [
              -91.58337,
              30.497005
            ],
            [
              -91.583656,
              30.48259
            ],
            [
              -91.556373,
              30.482822
            ],
            [
              -91.52622,
              30.497066
            ],
            [
              -91.485089,
              30.497138
            ],
            [
              -91.481794,
              30.51083
            ],
            [
              -91.41476,
              30.511516
            ],
            [
              -91.414926,
              30.540233
            ],
            [
              -91.397555,
              30.568889
            ],
            [
              -91.364958,
              30.568992
            ],
            [
              -91.365088,
              30.58805
            ],
            [
              -91.319924,
              30.632869
            ],
            [
              -91.309475,
              30.650045
            ],
            [
              -91.33087,
              30.658677
            ],
            [
              -91.340652,
              30.670238
            ],
            [
              -91.353181,
              30.722048
            ],
            [
              -91.375112,
              30.756717
            ],
            [
              -91.395037,
              30.757892
            ],
            [
              -91.450382,
              30.736168
            ],
            [
              -91.486906,
              30.741337
            ],
            [
              -91.509918,
              30.740279
            ],
            [
              -91.553019,
              30.730798
            ],
            [
              -91.571266,
              30.735344
            ],
            [
              -91.594595,
              30.750184
            ],
            [
              -91.592941,
              30.766016
            ],
            [
              -91.584475,
              30.772765
            ],
            [
              -91.557251,
              30.778159
            ],
            [
              -91.539473,
              30.791224
            ],
            [
              -91.517894,
              30.851381
            ],
            [
              -91.515476,
              30.872403
            ],
            [
              -91.521413,
              30.890078
            ],
            [
              -91.549308,
              30.916228
            ],
            [
              -91.568307,
              30.918802
            ],
            [
              -91.614608,
              30.9176
            ],
            [
              -91.609559,
              30.898444
            ],
            [
              -91.576078,
              30.893402
            ],
            [
              -91.563605,
              30.883332
            ],
            [
              -91.556407,
              30.848643
            ],
            [
              -91.567459,
              30.83678
            ],
            [
              -91.578546,
              30.846278
            ],
            [
              -91.603031,
              30.845964
            ],
            [
              -91.623381,
              30.84048
            ],
            [
              -91.646961,
              30.844936
            ],
            [
              -91.661372,
              30.857099
            ],
            [
              -91.660864,
              30.895093
            ],
            [
              -91.644917,
              30.928971
            ],
            [
              -91.662506,
              30.970362
            ],
            [
              -91.663142,
              30.984393
            ],
            [
              -91.658321,
              30.994365
            ],
            [
              -91.687266,
              31.018142
            ],
            [
              -91.714001,
              31.006768
            ],
            [
              -91.750119,
              31.018814
            ],
            [
              -91.774174,
              31.002637
            ],
            [
              -91.780473,
              30.986694
            ],
            [
              -91.796521,
              30.982842
            ],
            [
              -91.805963,
              30.972558
            ],
            [
              -91.80018,
              30.924621
            ],
            [
              -91.804324,
              30.863713
            ],
            [
              -91.823072,
              30.856354
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "101",
      "COUNTYNS": "01629492",
      "AFFGEOID": "0500000US22101",
      "GEOID": "22101",
      "NAME": "St. Mary",
      "LSAD": "15",
      "ALAND": 1438845622,
      "AWATER": 1460445312,
      "County_state": "St. Mary,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.859158,
              29.727768
            ],
            [
              -91.880999,
              29.713338
            ],
            [
              -91.853987,
              29.707618
            ],
            [
              -91.830499,
              29.718918
            ],
            [
              -91.783674,
              29.740689
            ],
            [
              -91.752259,
              29.748264
            ],
            [
              -91.737253,
              29.74937
            ],
            [
              -91.710935,
              29.738993
            ],
            [
              -91.667128,
              29.745822
            ],
            [
              -91.632829,
              29.742576
            ],
            [
              -91.621512,
              29.735429
            ],
            [
              -91.61809,
              29.720694
            ],
            [
              -91.618479,
              29.710816
            ],
            [
              -91.623829,
              29.69924
            ],
            [
              -91.626826,
              29.684753
            ],
            [
              -91.625114,
              29.671679
            ],
            [
              -91.627286,
              29.662132
            ],
            [
              -91.637344,
              29.647217
            ],
            [
              -91.643198,
              29.640274
            ],
            [
              -91.646478,
              29.639427
            ],
            [
              -91.648657,
              29.636713
            ],
            [
              -91.648941,
              29.633635
            ],
            [
              -91.643832,
              29.630625
            ],
            [
              -91.625114,
              29.626195
            ],
            [
              -91.600179,
              29.631156
            ],
            [
              -91.581843,
              29.637165
            ],
            [
              -91.570589,
              29.638312
            ],
            [
              -91.560908,
              29.63735
            ],
            [
              -91.553537,
              29.632766
            ],
            [
              -91.541974,
              29.594353
            ],
            [
              -91.537445,
              29.565888
            ],
            [
              -91.529217,
              29.558598
            ],
            [
              -91.525523,
              29.551904
            ],
            [
              -91.52584,
              29.545946
            ],
            [
              -91.531471,
              29.535374
            ],
            [
              -91.531021,
              29.531543
            ],
            [
              -91.517274,
              29.52974
            ],
            [
              -91.496136,
              29.538931
            ],
            [
              -91.486411,
              29.52169
            ],
            [
              -91.48559,
              29.499116
            ],
            [
              -91.460963,
              29.469961
            ],
            [
              -91.434859,
              29.482396
            ],
            [
              -91.417292,
              29.485111
            ],
            [
              -91.394307,
              29.497115
            ],
            [
              -91.379361,
              29.512371
            ],
            [
              -91.357613,
              29.512991
            ],
            [
              -91.340454,
              29.488463
            ],
            [
              -91.320424,
              29.478316
            ],
            [
              -91.333066,
              29.463022
            ],
            [
              -91.353096,
              29.454731
            ],
            [
              -91.347514,
              29.444438
            ],
            [
              -91.36426,
              29.43543
            ],
            [
              -91.363967,
              29.420664
            ],
            [
              -91.329624,
              29.426615
            ],
            [
              -91.334051,
              29.391525
            ],
            [
              -91.309917,
              29.400536
            ],
            [
              -91.301872,
              29.413122
            ],
            [
              -91.287425,
              29.403111
            ],
            [
              -91.276094,
              29.409336
            ],
            [
              -91.257216,
              29.438004
            ],
            [
              -91.2382713753225,
              29.4378709279237
            ],
            [
              -91.258332,
              29.444319
            ],
            [
              -91.265596,
              29.472669
            ],
            [
              -91.273662,
              29.479433
            ],
            [
              -91.265644,
              29.514412
            ],
            [
              -91.270618,
              29.536735
            ],
            [
              -91.264268,
              29.543397
            ],
            [
              -91.239708,
              29.548434
            ],
            [
              -91.222958,
              29.563162
            ],
            [
              -91.225201,
              29.576299
            ],
            [
              -91.211043,
              29.590738
            ],
            [
              -91.178048,
              29.609703
            ],
            [
              -91.163698,
              29.59272
            ],
            [
              -91.13945,
              29.608911
            ],
            [
              -91.122904,
              29.607722
            ],
            [
              -91.117166,
              29.617523
            ],
            [
              -91.082602,
              29.626368
            ],
            [
              -91.086163,
              29.634838
            ],
            [
              -91.105249,
              29.643012
            ],
            [
              -91.095213,
              29.696239
            ],
            [
              -91.10001,
              29.699402
            ],
            [
              -91.134117,
              29.724073
            ],
            [
              -91.180909,
              29.728548
            ],
            [
              -91.215195,
              29.751211
            ],
            [
              -91.229398,
              29.748922
            ],
            [
              -91.298584,
              29.764966
            ],
            [
              -91.35177,
              29.766284
            ],
            [
              -91.386491,
              29.783681
            ],
            [
              -91.373642,
              29.834401
            ],
            [
              -91.40113,
              29.873526
            ],
            [
              -91.413559,
              29.904993
            ],
            [
              -91.47196,
              29.947964
            ],
            [
              -91.471951,
              29.955989
            ],
            [
              -91.624709,
              29.945275
            ],
            [
              -91.671809,
              29.890904
            ],
            [
              -91.711149,
              29.846065
            ],
            [
              -91.840811,
              29.750208
            ],
            [
              -91.8585532125057,
              29.7360680465543
            ],
            [
              -91.859158,
              29.727768
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "081",
      "COUNTYNS": "00559472",
      "AFFGEOID": "0500000US22081",
      "GEOID": "22081",
      "NAME": "Red River",
      "LSAD": "15",
      "ALAND": 1007739206,
      "AWATER": 34327512,
      "County_state": "Red River,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.61469,
              32.237526
            ],
            [
              -93.596924,
              32.218187
            ],
            [
              -93.592995,
              32.205523
            ],
            [
              -93.556901,
              32.192104
            ],
            [
              -93.535963,
              32.193126
            ],
            [
              -93.5265,
              32.182205
            ],
            [
              -93.520735,
              32.144621
            ],
            [
              -93.509505,
              32.132179
            ],
            [
              -93.522627,
              32.125681
            ],
            [
              -93.541353,
              32.10588
            ],
            [
              -93.510758,
              32.078343
            ],
            [
              -93.501553,
              32.062975
            ],
            [
              -93.480312,
              32.057043
            ],
            [
              -93.481179,
              32.032031
            ],
            [
              -93.460366,
              32.020259
            ],
            [
              -93.441766,
              31.989358
            ],
            [
              -93.406582,
              31.987978
            ],
            [
              -93.372622,
              31.961663
            ],
            [
              -93.366076,
              31.935752
            ],
            [
              -93.355452,
              31.932222
            ],
            [
              -93.350481,
              31.898736
            ],
            [
              -93.315091,
              31.889968
            ],
            [
              -93.303806,
              31.89762
            ],
            [
              -93.282375,
              31.887442
            ],
            [
              -93.245472,
              31.910891
            ],
            [
              -93.239499,
              31.903605
            ],
            [
              -93.23847,
              31.972781
            ],
            [
              -93.121188,
              31.97261
            ],
            [
              -93.1361,
              31.983242
            ],
            [
              -93.158098,
              32.009407
            ],
            [
              -93.156492,
              32.024751
            ],
            [
              -93.137321,
              32.056336
            ],
            [
              -93.164306,
              32.093317
            ],
            [
              -93.152395,
              32.104512
            ],
            [
              -93.187162,
              32.148412
            ],
            [
              -93.191901,
              32.178362
            ],
            [
              -93.211144,
              32.192231
            ],
            [
              -93.213143,
              32.235757
            ],
            [
              -93.42819,
              32.235897
            ],
            [
              -93.471249,
              32.237186
            ],
            [
              -93.61469,
              32.237526
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "089",
      "COUNTYNS": "01629449",
      "AFFGEOID": "0500000US22089",
      "GEOID": "22089",
      "NAME": "St. Charles",
      "LSAD": "15",
      "ALAND": 719296011,
      "AWATER": 308712052,
      "County_state": "St. Charles,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.549766,
              30.002431
            ],
            [
              -90.54934,
              29.968052
            ],
            [
              -90.532203,
              29.967938
            ],
            [
              -90.529601,
              29.884997
            ],
            [
              -90.515724,
              29.864727
            ],
            [
              -90.49887,
              29.86506
            ],
            [
              -90.479904,
              29.847377
            ],
            [
              -90.476306,
              29.824875
            ],
            [
              -90.475784,
              29.812915
            ],
            [
              -90.468043,
              29.803474
            ],
            [
              -90.429887,
              29.776387
            ],
            [
              -90.405227,
              29.774335
            ],
            [
              -90.371978,
              29.759239
            ],
            [
              -90.370933,
              29.746192
            ],
            [
              -90.360794,
              29.737054
            ],
            [
              -90.361541,
              29.70745
            ],
            [
              -90.352104,
              29.695512
            ],
            [
              -90.346191,
              29.693302
            ],
            [
              -90.228157,
              29.692028
            ],
            [
              -90.202346,
              29.750669
            ],
            [
              -90.167274,
              29.81846
            ],
            [
              -90.173202,
              29.827524
            ],
            [
              -90.240173,
              29.862117
            ],
            [
              -90.243171,
              29.880704
            ],
            [
              -90.267982,
              29.904888
            ],
            [
              -90.279533,
              29.967971
            ],
            [
              -90.280296,
              29.972618
            ],
            [
              -90.279495,
              30.006797
            ],
            [
              -90.279571,
              30.023229
            ],
            [
              -90.279242,
              30.048479
            ],
            [
              -90.27922,
              30.049118
            ],
            [
              -90.272604,
              30.1078
            ],
            [
              -90.259889,
              30.16341
            ],
            [
              -90.326637,
              30.150188
            ],
            [
              -90.349714,
              30.120251
            ],
            [
              -90.407006,
              30.083109
            ],
            [
              -90.450218,
              30.033106
            ],
            [
              -90.467788,
              30.031709
            ],
            [
              -90.475767,
              30.035905
            ],
            [
              -90.538391,
              30.013401
            ],
            [
              -90.549766,
              30.002431
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "045",
      "COUNTYNS": "00558576",
      "AFFGEOID": "0500000US22045",
      "GEOID": "22045",
      "NAME": "Iberia",
      "LSAD": "15",
      "ALAND": 1486941064,
      "AWATER": 1182115493,
      "County_state": "Iberia,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.982012,
              29.990537
            ],
            [
              -91.993663,
              29.984341
            ],
            [
              -91.9721116903134,
              29.8334623347738
            ],
            [
              -91.970986,
              29.834316
            ],
            [
              -91.937507,
              29.816765
            ],
            [
              -91.91525,
              29.819367
            ],
            [
              -91.901043,
              29.833447
            ],
            [
              -91.889118,
              29.836023
            ],
            [
              -91.859514,
              29.826068
            ],
            [
              -91.830808,
              29.82927
            ],
            [
              -91.82492,
              29.808539
            ],
            [
              -91.824784,
              29.789297
            ],
            [
              -91.828803,
              29.777929
            ],
            [
              -91.843115,
              29.779251
            ],
            [
              -91.851671,
              29.791834
            ],
            [
              -91.861222,
              29.773199
            ],
            [
              -91.879242,
              29.756556
            ],
            [
              -91.872873,
              29.747878
            ],
            [
              -91.85797,
              29.744072
            ],
            [
              -91.8585532125057,
              29.7360680465543
            ],
            [
              -91.840811,
              29.750208
            ],
            [
              -91.711149,
              29.846065
            ],
            [
              -91.671809,
              29.890904
            ],
            [
              -91.624709,
              29.945275
            ],
            [
              -91.471951,
              29.955989
            ],
            [
              -91.255229,
              29.971457
            ],
            [
              -91.259388,
              30.0002
            ],
            [
              -91.235889,
              30.001128
            ],
            [
              -91.224356,
              30.025336
            ],
            [
              -91.223552,
              30.040789
            ],
            [
              -91.238689,
              30.03052
            ],
            [
              -91.255942,
              30.039776
            ],
            [
              -91.269085,
              30.059308
            ],
            [
              -91.368835,
              30.058824
            ],
            [
              -91.487217,
              30.058055
            ],
            [
              -91.495427,
              30.041369
            ],
            [
              -91.507763,
              30.033282
            ],
            [
              -91.600354,
              30.033169
            ],
            [
              -91.605996,
              30.057528
            ],
            [
              -91.61231,
              30.067945
            ],
            [
              -91.620576,
              30.069584
            ],
            [
              -91.642383,
              30.083405
            ],
            [
              -91.661523,
              30.109952
            ],
            [
              -91.679051,
              30.105102
            ],
            [
              -91.703278,
              30.121144
            ],
            [
              -91.726628,
              30.122413
            ],
            [
              -91.765168,
              30.108495
            ],
            [
              -91.760596,
              30.090778
            ],
            [
              -91.801499,
              30.069916
            ],
            [
              -91.813941,
              30.049927
            ],
            [
              -91.819216,
              30.044013
            ],
            [
              -91.851235,
              30.057326
            ],
            [
              -91.876207,
              30.067579
            ],
            [
              -91.883612,
              30.063666
            ],
            [
              -91.896234,
              30.057084
            ],
            [
              -91.907242,
              30.072496
            ],
            [
              -91.950998,
              30.073213
            ],
            [
              -91.961974,
              30.043582
            ],
            [
              -91.964458,
              30.036918
            ],
            [
              -91.982012,
              29.990537
            ]
          ]
        ],
        [
          [
            [
              -92.017827,
              29.590962
            ],
            [
              -92.030186,
              29.572669
            ],
            [
              -92.015139,
              29.561328
            ],
            [
              -91.971954,
              29.543414
            ],
            [
              -91.963597,
              29.535937
            ],
            [
              -91.949256,
              29.53628
            ],
            [
              -91.915321,
              29.518513
            ],
            [
              -91.900857,
              29.513948
            ],
            [
              -91.883987,
              29.501702
            ],
            [
              -91.870465,
              29.503808
            ],
            [
              -91.857274,
              29.500085
            ],
            [
              -91.846463,
              29.479308
            ],
            [
              -91.821579,
              29.473925
            ],
            [
              -91.814606,
              29.482061
            ],
            [
              -91.803558,
              29.487879
            ],
            [
              -91.786202,
              29.483108
            ],
            [
              -91.768263,
              29.490362
            ],
            [
              -91.774205,
              29.504946
            ],
            [
              -91.765411,
              29.524802
            ],
            [
              -91.735344,
              29.540106
            ],
            [
              -91.71324,
              29.55551
            ],
            [
              -91.711081,
              29.569328
            ],
            [
              -91.731541,
              29.562539
            ],
            [
              -91.733324,
              29.580834
            ],
            [
              -91.746323,
              29.574337
            ],
            [
              -91.765173,
              29.575046
            ],
            [
              -91.776582,
              29.583831
            ],
            [
              -91.784663,
              29.594062
            ],
            [
              -91.802727,
              29.588688
            ],
            [
              -91.806631,
              29.600197
            ],
            [
              -91.830945,
              29.611618
            ],
            [
              -91.848242,
              29.627124
            ],
            [
              -91.866425,
              29.629707
            ],
            [
              -91.886271,
              29.628467
            ],
            [
              -91.900532,
              29.634458
            ],
            [
              -91.927865,
              29.632496
            ],
            [
              -91.939903,
              29.610291
            ],
            [
              -91.960887,
              29.608937
            ],
            [
              -91.98265,
              29.61421
            ],
            [
              -92.005348,
              29.603052
            ],
            [
              -92.0092636191027,
              29.5992584400231
            ],
            [
              -92.017827,
              29.590962
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "033",
      "COUNTYNS": "00558530",
      "AFFGEOID": "0500000US22033",
      "GEOID": "22033",
      "NAME": "East Baton Rouge",
      "LSAD": "15",
      "ALAND": 1179346038,
      "AWATER": 38556769,
      "County_state": "East Baton Rouge,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.315282,
              30.594288
            ],
            [
              -91.314406,
              30.578817
            ],
            [
              -91.305854,
              30.573027
            ],
            [
              -91.267121,
              30.572522
            ],
            [
              -91.24309,
              30.55314
            ],
            [
              -91.246058,
              30.535851
            ],
            [
              -91.284359,
              30.515339
            ],
            [
              -91.281478,
              30.506115
            ],
            [
              -91.262734,
              30.505238
            ],
            [
              -91.236101,
              30.513973
            ],
            [
              -91.209308,
              30.522989
            ],
            [
              -91.200808,
              30.517689
            ],
            [
              -91.197205,
              30.506864
            ],
            [
              -91.196207,
              30.45779
            ],
            [
              -91.196114,
              30.452699
            ],
            [
              -91.195907,
              30.43959
            ],
            [
              -91.199056,
              30.422279
            ],
            [
              -91.208107,
              30.403691
            ],
            [
              -91.233908,
              30.375292
            ],
            [
              -91.241508,
              30.357592
            ],
            [
              -91.235808,
              30.344493
            ],
            [
              -91.174419,
              30.344187
            ],
            [
              -91.150801,
              30.337424
            ],
            [
              -91.142105,
              30.323293
            ],
            [
              -91.137906,
              30.317194
            ],
            [
              -91.098798,
              30.319537
            ],
            [
              -91.028645,
              30.315816
            ],
            [
              -91.021014,
              30.321489
            ],
            [
              -91.017168,
              30.326419
            ],
            [
              -91.014157,
              30.334779
            ],
            [
              -90.992883,
              30.342749
            ],
            [
              -90.987569,
              30.34543
            ],
            [
              -90.923852,
              30.34413
            ],
            [
              -90.911121,
              30.339578
            ],
            [
              -90.891728,
              30.345244
            ],
            [
              -90.895577,
              30.347851
            ],
            [
              -90.897515,
              30.347981
            ],
            [
              -90.906185,
              30.361223
            ],
            [
              -90.93723,
              30.366565
            ],
            [
              -90.949197,
              30.394445
            ],
            [
              -90.972346,
              30.397519
            ],
            [
              -90.965794,
              30.414713
            ],
            [
              -90.982351,
              30.423465
            ],
            [
              -90.973444,
              30.437701
            ],
            [
              -90.991529,
              30.461818
            ],
            [
              -90.976516,
              30.467267
            ],
            [
              -90.96789,
              30.504381
            ],
            [
              -90.982197,
              30.522114
            ],
            [
              -90.980931,
              30.535555
            ],
            [
              -90.972952,
              30.540928
            ],
            [
              -90.971302,
              30.562687
            ],
            [
              -90.985601,
              30.584686
            ],
            [
              -90.972247,
              30.596887
            ],
            [
              -90.934106,
              30.613147
            ],
            [
              -90.940582,
              30.61989
            ],
            [
              -90.910701,
              30.649385
            ],
            [
              -90.870916,
              30.693329
            ],
            [
              -90.844181,
              30.708047
            ],
            [
              -90.849041,
              30.719311
            ],
            [
              -91.053393,
              30.712184
            ],
            [
              -91.254419,
              30.705294
            ],
            [
              -91.264839,
              30.698677
            ],
            [
              -91.270216,
              30.680929
            ],
            [
              -91.288731,
              30.677904
            ],
            [
              -91.297658,
              30.649548
            ],
            [
              -91.294211,
              30.641591
            ],
            [
              -91.292821,
              30.631539
            ],
            [
              -91.301863,
              30.609497
            ],
            [
              -91.315282,
              30.594288
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "043",
      "COUNTYNS": "00558567",
      "AFFGEOID": "0500000US22043",
      "GEOID": "22043",
      "NAME": "Grant",
      "LSAD": "15",
      "ALAND": 1665429795,
      "AWATER": 56013947,
      "County_state": "Grant,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.973529,
              31.708922
            ],
            [
              -92.959858,
              31.701294
            ],
            [
              -92.963782,
              31.679894
            ],
            [
              -92.929432,
              31.677866
            ],
            [
              -92.924674,
              31.665941
            ],
            [
              -92.902801,
              31.65951
            ],
            [
              -92.906321,
              31.623628
            ],
            [
              -92.871434,
              31.617664
            ],
            [
              -92.840947,
              31.601233
            ],
            [
              -92.801416,
              31.597012
            ],
            [
              -92.787369,
              31.578661
            ],
            [
              -92.757052,
              31.558838
            ],
            [
              -92.76131,
              31.547388
            ],
            [
              -92.716708,
              31.522001
            ],
            [
              -92.718983,
              31.517594
            ],
            [
              -92.70195,
              31.49438
            ],
            [
              -92.687996,
              31.500947
            ],
            [
              -92.684169,
              31.492777
            ],
            [
              -92.698725,
              31.491881
            ],
            [
              -92.693147,
              31.471274
            ],
            [
              -92.706948,
              31.470473
            ],
            [
              -92.706233,
              31.450683
            ],
            [
              -92.680155,
              31.444085
            ],
            [
              -92.694422,
              31.428162
            ],
            [
              -92.677606,
              31.414738
            ],
            [
              -92.694512,
              31.400966
            ],
            [
              -92.679147,
              31.396143
            ],
            [
              -92.66966,
              31.4041
            ],
            [
              -92.658646,
              31.388078
            ],
            [
              -92.631944,
              31.390478
            ],
            [
              -92.450301,
              31.428567
            ],
            [
              -92.447482,
              31.429222
            ],
            [
              -92.303433,
              31.458677
            ],
            [
              -92.242262,
              31.468783
            ],
            [
              -92.196131,
              31.477877
            ],
            [
              -92.212733,
              31.481645
            ],
            [
              -92.213969,
              31.491579
            ],
            [
              -92.24827,
              31.48448
            ],
            [
              -92.239461,
              31.501227
            ],
            [
              -92.276623,
              31.495385
            ],
            [
              -92.275088,
              31.536087
            ],
            [
              -92.286896,
              31.536584
            ],
            [
              -92.304568,
              31.553157
            ],
            [
              -92.298709,
              31.563248
            ],
            [
              -92.310231,
              31.573235
            ],
            [
              -92.32008,
              31.57173
            ],
            [
              -92.306855,
              31.58952
            ],
            [
              -92.34297,
              31.595824
            ],
            [
              -92.384613,
              31.620131
            ],
            [
              -92.36754,
              31.618483
            ],
            [
              -92.351466,
              31.624242
            ],
            [
              -92.367061,
              31.648809
            ],
            [
              -92.347739,
              31.696584
            ],
            [
              -92.372476,
              31.701519
            ],
            [
              -92.36837,
              31.723024
            ],
            [
              -92.360483,
              31.721462
            ],
            [
              -92.350709,
              31.738527
            ],
            [
              -92.358272,
              31.742726
            ],
            [
              -92.344578,
              31.758987
            ],
            [
              -92.352974,
              31.780395
            ],
            [
              -92.361287,
              31.78136
            ],
            [
              -92.362608,
              31.796633
            ],
            [
              -92.579307,
              31.79701
            ],
            [
              -92.619224,
              31.797149
            ],
            [
              -92.61999,
              31.71249
            ],
            [
              -92.619976,
              31.709572
            ],
            [
              -92.667733,
              31.709504
            ],
            [
              -92.892195,
              31.708908
            ],
            [
              -92.973529,
              31.708922
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "031",
      "COUNTYNS": "00558521",
      "AFFGEOID": "0500000US22031",
      "GEOID": "22031",
      "NAME": "De Soto",
      "LSAD": "15",
      "ALAND": 2269878444,
      "AWATER": 47049426,
      "County_state": "De Soto,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.042681,
              32.137956
            ],
            [
              -94.042752,
              32.125163
            ],
            [
              -94.042337,
              32.119914
            ],
            [
              -94.0427,
              32.056012
            ],
            [
              -94.04272,
              31.999265
            ],
            [
              -94.041833,
              31.992402
            ],
            [
              -94.038412,
              31.992437
            ],
            [
              -94.029283,
              31.995865
            ],
            [
              -94.018664,
              31.990843
            ],
            [
              -94.0127962602173,
              31.9816683460243
            ],
            [
              -93.977461,
              31.926419
            ],
            [
              -93.971712,
              31.920384
            ],
            [
              -93.953546,
              31.910563
            ],
            [
              -93.943541,
              31.908564
            ],
            [
              -93.938002,
              31.906917
            ],
            [
              -93.935008,
              31.903773
            ],
            [
              -93.932463,
              31.895539
            ],
            [
              -93.927672,
              31.891497
            ],
            [
              -93.923929,
              31.88985
            ],
            [
              -93.919588,
              31.890748
            ],
            [
              -93.915949,
              31.892861
            ],
            [
              -93.909557,
              31.893144
            ],
            [
              -93.904766,
              31.890599
            ],
            [
              -93.901173,
              31.885958
            ],
            [
              -93.901888,
              31.880063
            ],
            [
              -93.896981,
              31.873382
            ],
            [
              -93.889197,
              31.867693
            ],
            [
              -93.888149,
              31.856914
            ],
            [
              -93.884117,
              31.847606
            ],
            [
              -93.8792944619865,
              31.8439767742438
            ],
            [
              -93.51059,
              31.84541
            ],
            [
              -93.441171,
              31.845233
            ],
            [
              -93.44057,
              31.91373
            ],
            [
              -93.420165,
              31.933964
            ],
            [
              -93.398813,
              31.91791
            ],
            [
              -93.377907,
              31.932712
            ],
            [
              -93.355452,
              31.932222
            ],
            [
              -93.366076,
              31.935752
            ],
            [
              -93.372622,
              31.961663
            ],
            [
              -93.406582,
              31.987978
            ],
            [
              -93.441766,
              31.989358
            ],
            [
              -93.460366,
              32.020259
            ],
            [
              -93.481179,
              32.032031
            ],
            [
              -93.480312,
              32.057043
            ],
            [
              -93.501553,
              32.062975
            ],
            [
              -93.510758,
              32.078343
            ],
            [
              -93.541353,
              32.10588
            ],
            [
              -93.522627,
              32.125681
            ],
            [
              -93.509505,
              32.132179
            ],
            [
              -93.520735,
              32.144621
            ],
            [
              -93.5265,
              32.182205
            ],
            [
              -93.535963,
              32.193126
            ],
            [
              -93.556901,
              32.192104
            ],
            [
              -93.592995,
              32.205523
            ],
            [
              -93.596924,
              32.218187
            ],
            [
              -93.61469,
              32.237526
            ],
            [
              -93.614265,
              32.269607
            ],
            [
              -93.650965,
              32.306497
            ],
            [
              -93.666472,
              32.317444
            ],
            [
              -93.686834,
              32.316747
            ],
            [
              -93.714306,
              32.316189
            ],
            [
              -93.75151,
              32.339821
            ],
            [
              -93.755554,
              32.343783
            ],
            [
              -93.791323,
              32.340197
            ],
            [
              -93.807098,
              32.327751
            ],
            [
              -93.807733,
              32.313525
            ],
            [
              -93.847708,
              32.292405
            ],
            [
              -93.856598,
              32.281063
            ],
            [
              -93.860327,
              32.253673
            ],
            [
              -93.897246,
              32.226759
            ],
            [
              -93.920039,
              32.216222
            ],
            [
              -93.951085,
              32.195545
            ],
            [
              -93.989928,
              32.195571
            ],
            [
              -94.0426205276662,
              32.1960049578035
            ],
            [
              -94.042566,
              32.166894
            ],
            [
              -94.042539,
              32.166826
            ],
            [
              -94.042591,
              32.158097
            ],
            [
              -94.042681,
              32.137956
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "119",
      "COUNTYNS": "00559567",
      "AFFGEOID": "0500000US22119",
      "GEOID": "22119",
      "NAME": "Webster",
      "LSAD": "15",
      "ALAND": 1536282650,
      "AWATER": 56145063,
      "County_state": "Webster,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.530405,
              32.975397
            ],
            [
              -93.519562,
              32.96508
            ],
            [
              -93.508922,
              32.935789
            ],
            [
              -93.49326,
              32.921529
            ],
            [
              -93.48081,
              32.927231
            ],
            [
              -93.469572,
              32.917717
            ],
            [
              -93.469776,
              32.904404
            ],
            [
              -93.482231,
              32.901981
            ],
            [
              -93.476734,
              32.885694
            ],
            [
              -93.463841,
              32.86844
            ],
            [
              -93.448039,
              32.866321
            ],
            [
              -93.454793,
              32.850973
            ],
            [
              -93.467489,
              32.848078
            ],
            [
              -93.456555,
              32.835026
            ],
            [
              -93.454012,
              32.821609
            ],
            [
              -93.46695,
              32.809169
            ],
            [
              -93.459702,
              32.806231
            ],
            [
              -93.473797,
              32.783172
            ],
            [
              -93.464996,
              32.771711
            ],
            [
              -93.469701,
              32.7614
            ],
            [
              -93.444743,
              32.761166
            ],
            [
              -93.445317,
              32.644006
            ],
            [
              -93.446498,
              32.497972
            ],
            [
              -93.441225,
              32.497835
            ],
            [
              -93.441212,
              32.427527
            ],
            [
              -93.441212,
              32.410431
            ],
            [
              -93.372781,
              32.410153
            ],
            [
              -93.236656,
              32.410071
            ],
            [
              -93.236673,
              32.454212
            ],
            [
              -93.147193,
              32.455173
            ],
            [
              -93.146446,
              32.469962
            ],
            [
              -93.171274,
              32.526369
            ],
            [
              -93.168158,
              32.549719
            ],
            [
              -93.178767,
              32.584916
            ],
            [
              -93.178666,
              32.62515
            ],
            [
              -93.173803,
              32.63364
            ],
            [
              -93.186286,
              32.672657
            ],
            [
              -93.186571,
              32.716755
            ],
            [
              -93.237381,
              32.71625
            ],
            [
              -93.238607,
              33.017992
            ],
            [
              -93.308181,
              33.018156
            ],
            [
              -93.308398,
              33.018179
            ],
            [
              -93.340353,
              33.018337
            ],
            [
              -93.377134,
              33.018234
            ],
            [
              -93.467042,
              33.018611
            ],
            [
              -93.46709735374169,
              33.018610586029695
            ],
            [
              -93.489506,
              33.018443
            ],
            [
              -93.4905199755506,
              33.0184422689434
            ],
            [
              -93.490893,
              33.018442
            ],
            [
              -93.520971,
              33.018616
            ],
            [
              -93.52099399934829,
              33.01861612243
            ],
            [
              -93.516315,
              33.002541
            ],
            [
              -93.530405,
              32.975397
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "019",
      "COUNTYNS": "00558468",
      "AFFGEOID": "0500000US22019",
      "GEOID": "22019",
      "NAME": "Calcasieu",
      "LSAD": "15",
      "ALAND": 2756538198,
      "AWATER": 77608623,
      "County_state": "Calcasieu,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.765822,
              30.333318
            ],
            [
              -93.764265,
              30.330223
            ],
            [
              -93.760328,
              30.329924
            ],
            [
              -93.738699,
              30.303794
            ],
            [
              -93.72422,
              30.295134
            ],
            [
              -93.718684,
              30.29501
            ],
            [
              -93.714319,
              30.294282
            ],
            [
              -93.711118,
              30.291372
            ],
            [
              -93.708645,
              30.288317
            ],
            [
              -93.706608,
              30.281187
            ],
            [
              -93.70719,
              30.275513
            ],
            [
              -93.709132,
              30.271827
            ],
            [
              -93.707271,
              30.249937
            ],
            [
              -93.705519312533,
              30.244184772189
            ],
            [
              -93.705083,
              30.242752
            ],
            [
              -93.713359,
              30.225261
            ],
            [
              -93.71922,
              30.218542
            ],
            [
              -93.720946,
              30.209852
            ],
            [
              -93.717397,
              30.193439
            ],
            [
              -93.710468,
              30.180671
            ],
            [
              -93.703764,
              30.173936
            ],
            [
              -93.7014984578938,
              30.166030704140198
            ],
            [
              -93.697748,
              30.152944
            ],
            [
              -93.688212,
              30.141376
            ],
            [
              -93.692868,
              30.135217
            ],
            [
              -93.69498,
              30.135185
            ],
            [
              -93.698276,
              30.138608
            ],
            [
              -93.701252,
              30.137376
            ],
            [
              -93.702436,
              30.112721
            ],
            [
              -93.723765,
              30.09413
            ],
            [
              -93.732485,
              30.088914
            ],
            [
              -93.734085,
              30.08613
            ],
            [
              -93.731605,
              30.081282
            ],
            [
              -93.716405,
              30.069122
            ],
            [
              -93.70218,
              30.065474
            ],
            [
              -93.70058,
              30.063666
            ],
            [
              -93.699396,
              30.05925
            ],
            [
              -93.70082,
              30.056274
            ],
            [
              -93.70394,
              30.054291
            ],
            [
              -93.720805,
              30.053043
            ],
            [
              -93.7227914118907,
              30.051161751793895
            ],
            [
              -93.704009,
              30.052176
            ],
            [
              -93.530545,
              30.051755
            ],
            [
              -93.34175,
              30.052232
            ],
            [
              -93.312527,
              30.052102
            ],
            [
              -93.179107,
              30.052193
            ],
            [
              -93.168262,
              30.051854
            ],
            [
              -93.128475,
              30.052587
            ],
            [
              -93.128409,
              30.03837
            ],
            [
              -92.996371,
              30.038479
            ],
            [
              -92.996193,
              30.082343
            ],
            [
              -92.887222,
              30.083455
            ],
            [
              -92.891522,
              30.09661
            ],
            [
              -92.907896,
              30.112734
            ],
            [
              -92.906865,
              30.130812
            ],
            [
              -92.889115,
              30.143406
            ],
            [
              -92.893324,
              30.156314
            ],
            [
              -92.9963,
              30.156955
            ],
            [
              -92.996768,
              30.260733
            ],
            [
              -93.030685,
              30.260595
            ],
            [
              -93.030955,
              30.37939
            ],
            [
              -93.087443,
              30.37965
            ],
            [
              -93.131087,
              30.379985
            ],
            [
              -93.131094,
              30.384841
            ],
            [
              -93.131109,
              30.403057
            ],
            [
              -93.38515,
              30.40263
            ],
            [
              -93.384422,
              30.460728
            ],
            [
              -93.384673,
              30.490109
            ],
            [
              -93.486384,
              30.490523
            ],
            [
              -93.486334,
              30.475455
            ],
            [
              -93.486179,
              30.421357
            ],
            [
              -93.486116,
              30.4025
            ],
            [
              -93.7402445414121,
              30.4022625442349
            ],
            [
              -93.745333,
              30.397022
            ],
            [
              -93.751437,
              30.396288
            ],
            [
              -93.757654,
              30.390423
            ],
            [
              -93.758554,
              30.387077
            ],
            [
              -93.755894,
              30.370709
            ],
            [
              -93.756352,
              30.356166
            ],
            [
              -93.765822,
              30.333318
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "061",
      "COUNTYNS": "00558077",
      "AFFGEOID": "0500000US22061",
      "GEOID": "22061",
      "NAME": "Lincoln",
      "LSAD": "15",
      "ALAND": 1221806134,
      "AWATER": 1897709,
      "County_state": "Lincoln,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.881359,
              32.600918
            ],
            [
              -92.880805,
              32.585277
            ],
            [
              -92.880243,
              32.557651
            ],
            [
              -92.879268,
              32.454118
            ],
            [
              -92.777197,
              32.453455
            ],
            [
              -92.747251,
              32.453553
            ],
            [
              -92.621678,
              32.452999
            ],
            [
              -92.622335,
              32.496406
            ],
            [
              -92.585352,
              32.496212
            ],
            [
              -92.41535,
              32.495486
            ],
            [
              -92.415073,
              32.575524
            ],
            [
              -92.415071,
              32.582845
            ],
            [
              -92.41505,
              32.670056
            ],
            [
              -92.518356,
              32.67072
            ],
            [
              -92.519016,
              32.720777
            ],
            [
              -92.545537,
              32.718139
            ],
            [
              -92.571295,
              32.748579
            ],
            [
              -92.591177,
              32.754591
            ],
            [
              -92.613422,
              32.752527
            ],
            [
              -92.636516,
              32.759382
            ],
            [
              -92.725439,
              32.75954
            ],
            [
              -92.828535,
              32.758814
            ],
            [
              -92.828049,
              32.714388
            ],
            [
              -92.839655,
              32.709669
            ],
            [
              -92.851995,
              32.696303
            ],
            [
              -92.863924,
              32.687715
            ],
            [
              -92.868775,
              32.678001
            ],
            [
              -92.862248,
              32.621164
            ],
            [
              -92.881359,
              32.600918
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "111",
      "COUNTYNS": "00559528",
      "AFFGEOID": "0500000US22111",
      "GEOID": "22111",
      "NAME": "Union",
      "LSAD": "15",
      "ALAND": 2271359845,
      "AWATER": 73624039,
      "County_state": "Union,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.734045,
              32.817998
            ],
            [
              -92.734057,
              32.803436
            ],
            [
              -92.725403,
              32.803457
            ],
            [
              -92.725439,
              32.75954
            ],
            [
              -92.636516,
              32.759382
            ],
            [
              -92.613422,
              32.752527
            ],
            [
              -92.591177,
              32.754591
            ],
            [
              -92.571295,
              32.748579
            ],
            [
              -92.545537,
              32.718139
            ],
            [
              -92.519016,
              32.720777
            ],
            [
              -92.518356,
              32.67072
            ],
            [
              -92.41505,
              32.670056
            ],
            [
              -92.415071,
              32.582845
            ],
            [
              -92.312874,
              32.582803
            ],
            [
              -92.272313,
              32.580975
            ],
            [
              -92.224265,
              32.590094
            ],
            [
              -92.221672,
              32.610341
            ],
            [
              -92.183694,
              32.633042
            ],
            [
              -92.138119,
              32.66077
            ],
            [
              -92.13904,
              32.675363
            ],
            [
              -92.120867,
              32.673589
            ],
            [
              -92.090171,
              32.687691
            ],
            [
              -92.065179,
              32.723003
            ],
            [
              -92.069639,
              32.733513
            ],
            [
              -92.052668,
              32.736954
            ],
            [
              -92.063138,
              32.762179
            ],
            [
              -92.052728,
              32.794734
            ],
            [
              -92.06599,
              32.821434
            ],
            [
              -92.067014,
              32.833947
            ],
            [
              -92.056038,
              32.83753
            ],
            [
              -92.078362,
              32.864929
            ],
            [
              -92.084296,
              32.882176
            ],
            [
              -92.077266,
              32.893159
            ],
            [
              -92.076693,
              32.933871
            ],
            [
              -92.085617,
              32.965193
            ],
            [
              -92.076088,
              32.969609
            ],
            [
              -92.081664,
              32.9821
            ],
            [
              -92.071004,
              32.988643
            ],
            [
              -92.069105,
              33.008163
            ],
            [
              -92.222825,
              33.00908
            ],
            [
              -92.292664,
              33.010103
            ],
            [
              -92.335893,
              33.010349
            ],
            [
              -92.362865,
              33.010628
            ],
            [
              -92.37029,
              33.010717
            ],
            [
              -92.469762,
              33.01201
            ],
            [
              -92.501383,
              33.01216
            ],
            [
              -92.503776,
              33.012161
            ],
            [
              -92.5297818414117,
              33.0124299399492
            ],
            [
              -92.711289,
              33.014307
            ],
            [
              -92.715884,
              33.014398
            ],
            [
              -92.723553,
              33.014328
            ],
            [
              -92.72474282953719,
              33.0143469910336
            ],
            [
              -92.725369,
              32.817979
            ],
            [
              -92.734045,
              32.817998
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "085",
      "COUNTYNS": "00559489",
      "AFFGEOID": "0500000US22085",
      "GEOID": "22085",
      "NAME": "Sabine",
      "LSAD": "15",
      "ALAND": 2244636627,
      "AWATER": 375317817,
      "County_state": "Sabine,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.8792944619865,
              31.8439767742438
            ],
            [
              -93.874822,
              31.840611
            ],
            [
              -93.874761,
              31.821661
            ],
            [
              -93.870917,
              31.816837
            ],
            [
              -93.85339,
              31.805467
            ],
            [
              -93.846188,
              31.802021
            ],
            [
              -93.839951,
              31.798597
            ],
            [
              -93.836868,
              31.794159
            ],
            [
              -93.836868,
              31.788734
            ],
            [
              -93.834649,
              31.783309
            ],
            [
              -93.831197,
              31.780227
            ],
            [
              -93.827451,
              31.777741
            ],
            [
              -93.823443,
              31.775098
            ],
            [
              -93.822598,
              31.773559
            ],
            [
              -93.827343,
              31.75937
            ],
            [
              -93.830112,
              31.754555
            ],
            [
              -93.830647,
              31.745811
            ],
            [
              -93.824579,
              31.734397
            ],
            [
              -93.819048,
              31.728858
            ],
            [
              -93.815657,
              31.719043
            ],
            [
              -93.815836,
              31.711905
            ],
            [
              -93.814587,
              31.707444
            ],
            [
              -93.810304,
              31.705481
            ],
            [
              -93.80727,
              31.704232
            ],
            [
              -93.803419,
              31.700686
            ],
            [
              -93.802694,
              31.697783
            ],
            [
              -93.802452,
              31.693186
            ],
            [
              -93.804479,
              31.685664
            ],
            [
              -93.817425,
              31.672146
            ],
            [
              -93.822051,
              31.673967
            ],
            [
              -93.826462,
              31.666919
            ],
            [
              -93.825661,
              31.661022
            ],
            [
              -93.818037,
              31.647892
            ],
            [
              -93.816838,
              31.622509
            ],
            [
              -93.81686189111218,
              31.622407879183
            ],
            [
              -93.818717,
              31.614556
            ],
            [
              -93.823977,
              31.614228
            ],
            [
              -93.827852,
              31.616551
            ],
            [
              -93.838057,
              31.606795
            ],
            [
              -93.839383,
              31.599075
            ],
            [
              -93.834924,
              31.586211
            ],
            [
              -93.8349233245136,
              31.5862099793189
            ],
            [
              -93.822958,
              31.56813
            ],
            [
              -93.820764,
              31.558221
            ],
            [
              -93.818582,
              31.554826
            ],
            [
              -93.798087,
              31.534044
            ],
            [
              -93.787687,
              31.527344
            ],
            [
              -93.780835,
              31.525384
            ],
            [
              -93.760062,
              31.523933
            ],
            [
              -93.75386,
              31.525331
            ],
            [
              -93.751899,
              31.525602
            ],
            [
              -93.74987,
              31.526211
            ],
            [
              -93.746826,
              31.526008
            ],
            [
              -93.743376,
              31.525196
            ],
            [
              -93.74155,
              31.522558
            ],
            [
              -93.741111,
              31.520101
            ],
            [
              -93.740332,
              31.517079
            ],
            [
              -93.739318,
              31.51505
            ],
            [
              -93.733433,
              31.513223
            ],
            [
              -93.726736,
              31.5116
            ],
            [
              -93.725925,
              31.504092
            ],
            [
              -93.728766,
              31.496786
            ],
            [
              -93.730998,
              31.492119
            ],
            [
              -93.737168,
              31.484622
            ],
            [
              -93.741885,
              31.483535
            ],
            [
              -93.745608,
              31.481973
            ],
            [
              -93.747841,
              31.480958
            ],
            [
              -93.74987,
              31.478929
            ],
            [
              -93.74987,
              31.475276
            ],
            [
              -93.749476,
              31.46869
            ],
            [
              -93.709416,
              31.442995
            ],
            [
              -93.70093,
              31.437784
            ],
            [
              -93.697603,
              31.428409
            ],
            [
              -93.704678,
              31.4189
            ],
            [
              -93.704879,
              31.410881
            ],
            [
              -93.701611,
              31.409334
            ],
            [
              -93.695866,
              31.409392
            ],
            [
              -93.674117,
              31.397681
            ],
            [
              -93.671644,
              31.393352
            ],
            [
              -93.670182,
              31.387184
            ],
            [
              -93.668533,
              31.379357
            ],
            [
              -93.668146,
              31.375103
            ],
            [
              -93.669693,
              31.371815
            ],
            [
              -93.66892,
              31.3664
            ],
            [
              -93.665052,
              31.363886
            ],
            [
              -93.663892,
              31.361953
            ],
            [
              -93.663698,
              31.360019
            ],
            [
              -93.664665,
              31.357698
            ],
            [
              -93.665825,
              31.355184
            ],
            [
              -93.668439,
              31.353012
            ],
            [
              -93.677277,
              31.330483
            ],
            [
              -93.687851,
              31.309835
            ],
            [
              -93.68688,
              31.305166
            ],
            [
              -93.684039,
              31.301771
            ],
            [
              -93.67544,
              31.30104
            ],
            [
              -93.668928,
              31.297975
            ],
            [
              -93.657004,
              31.281736
            ],
            [
              -93.642516,
              31.269508
            ],
            [
              -93.620343,
              31.271025
            ],
            [
              -93.613942,
              31.259375
            ],
            [
              -93.614288,
              31.251631
            ],
            [
              -93.616308,
              31.244595
            ],
            [
              -93.616007,
              31.23396
            ],
            [
              -93.609828,
              31.229661
            ],
            [
              -93.607409,
              31.227243
            ],
            [
              -93.60526,
              31.224153
            ],
            [
              -93.604319,
              31.220794
            ],
            [
              -93.604319,
              31.215286
            ],
            [
              -93.607288,
              31.205403
            ],
            [
              -93.602443,
              31.182541
            ],
            [
              -93.600308,
              31.176158
            ],
            [
              -93.598828,
              31.174679
            ],
            [
              -93.588503,
              31.165581
            ],
            [
              -93.579215,
              31.167422
            ],
            [
              -93.569563,
              31.177574
            ],
            [
              -93.560943,
              31.182482
            ],
            [
              -93.552649,
              31.185575
            ],
            [
              -93.5525399494441,
              31.1856050930259
            ],
            [
              -93.544349,
              31.202781
            ],
            [
              -93.551281,
              31.215428
            ],
            [
              -93.5415,
              31.226926
            ],
            [
              -93.552139,
              31.23813
            ],
            [
              -93.535883,
              31.252715
            ],
            [
              -93.535414,
              31.276402
            ],
            [
              -93.442554,
              31.276716
            ],
            [
              -93.390495,
              31.364147
            ],
            [
              -93.236104,
              31.364507
            ],
            [
              -93.236662,
              31.538339
            ],
            [
              -93.337456,
              31.53811
            ],
            [
              -93.338972,
              31.714259
            ],
            [
              -93.441743,
              31.714485
            ],
            [
              -93.441283,
              31.761647
            ],
            [
              -93.440761,
              31.78713
            ],
            [
              -93.441171,
              31.845233
            ],
            [
              -93.51059,
              31.84541
            ],
            [
              -93.8792944619865,
              31.8439767742438
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "115",
      "COUNTYNS": "00559548",
      "AFFGEOID": "0500000US22115",
      "GEOID": "22115",
      "NAME": "Vernon",
      "LSAD": "15",
      "ALAND": 3439275960,
      "AWATER": 35153783,
      "County_state": "Vernon,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.569764,
              30.996715
            ],
            [
              -93.571906,
              30.987614
            ],
            [
              -93.567972,
              30.977981
            ],
            [
              -93.560533,
              30.971286
            ],
            [
              -93.549841,
              30.967118
            ],
            [
              -93.532549,
              30.950696
            ],
            [
              -93.526245,
              30.939411
            ],
            [
              -93.526147,
              30.930035
            ],
            [
              -93.530936,
              30.924534
            ],
            [
              -93.542489,
              30.920064
            ],
            [
              -93.54503,
              30.920837
            ],
            [
              -93.546884,
              30.921511
            ],
            [
              -93.549244,
              30.921006
            ],
            [
              -93.551942,
              30.918646
            ],
            [
              -93.55565,
              30.911228
            ],
            [
              -93.556493,
              30.901451
            ],
            [
              -93.564248,
              30.895045
            ],
            [
              -93.567788,
              30.888302
            ],
            [
              -93.567451,
              30.878524
            ],
            [
              -93.565428,
              30.87431
            ],
            [
              -93.558617,
              30.869424
            ],
            [
              -93.55860790084809,
              30.868821820173896
            ],
            [
              -93.554597,
              30.877461
            ],
            [
              -93.522812,
              30.879563
            ],
            [
              -93.500344,
              30.865026
            ],
            [
              -93.478425,
              30.859362
            ],
            [
              -93.458531,
              30.866441
            ],
            [
              -93.435652,
              30.881816
            ],
            [
              -93.386798,
              30.88307
            ],
            [
              -93.280164,
              30.882841
            ],
            [
              -93.276031,
              30.883068
            ],
            [
              -93.204954,
              30.883164
            ],
            [
              -93.155348,
              30.882727
            ],
            [
              -93.133715,
              30.878121
            ],
            [
              -92.978453,
              30.878175
            ],
            [
              -92.823552,
              30.875594
            ],
            [
              -92.823932,
              30.89
            ],
            [
              -92.827664,
              31.011171
            ],
            [
              -92.829651,
              31.252281
            ],
            [
              -92.846252,
              31.253281
            ],
            [
              -92.846352,
              31.27418
            ],
            [
              -92.862552,
              31.27428
            ],
            [
              -92.862553,
              31.288679
            ],
            [
              -92.879542,
              31.288617
            ],
            [
              -92.879756,
              31.317373
            ],
            [
              -92.896651,
              31.324581
            ],
            [
              -92.930253,
              31.324506
            ],
            [
              -92.930287,
              31.332242
            ],
            [
              -92.964481,
              31.332251
            ],
            [
              -92.964586,
              31.339521
            ],
            [
              -92.981393,
              31.339416
            ],
            [
              -92.981464,
              31.346644
            ],
            [
              -92.992013,
              31.36043
            ],
            [
              -93.032309,
              31.360479
            ],
            [
              -93.236104,
              31.364507
            ],
            [
              -93.390495,
              31.364147
            ],
            [
              -93.442554,
              31.276716
            ],
            [
              -93.535414,
              31.276402
            ],
            [
              -93.535883,
              31.252715
            ],
            [
              -93.552139,
              31.23813
            ],
            [
              -93.5415,
              31.226926
            ],
            [
              -93.551281,
              31.215428
            ],
            [
              -93.544349,
              31.202781
            ],
            [
              -93.5525399494441,
              31.1856050930259
            ],
            [
              -93.548931,
              31.186601
            ],
            [
              -93.535097,
              31.185614
            ],
            [
              -93.533307,
              31.184463
            ],
            [
              -93.531744,
              31.180817
            ],
            [
              -93.53683,
              31.15862
            ],
            [
              -93.540253,
              31.156579
            ],
            [
              -93.54401,
              31.153015
            ],
            [
              -93.544888,
              31.148844
            ],
            [
              -93.544888,
              31.143137
            ],
            [
              -93.544702,
              31.135889
            ],
            [
              -93.540278,
              31.128868
            ],
            [
              -93.539619,
              31.121844
            ],
            [
              -93.541375,
              31.113502
            ],
            [
              -93.549717,
              31.10516
            ],
            [
              -93.551693,
              31.097258
            ],
            [
              -93.551034,
              31.091111
            ],
            [
              -93.546644,
              31.082989
            ],
            [
              -93.540129,
              31.078003
            ],
            [
              -93.53104,
              31.074699
            ],
            [
              -93.526044,
              31.070773
            ],
            [
              -93.52301,
              31.065241
            ],
            [
              -93.52533,
              31.060601
            ],
            [
              -93.529256,
              31.057567
            ],
            [
              -93.532069,
              31.055264
            ],
            [
              -93.531219,
              31.051678
            ],
            [
              -93.523248,
              31.037842
            ],
            [
              -93.516943,
              31.032584
            ],
            [
              -93.516407,
              31.02955
            ],
            [
              -93.516943,
              31.023662
            ],
            [
              -93.539526,
              31.008498
            ],
            [
              -93.555581,
              31.003919
            ],
            [
              -93.562626,
              31.005995
            ],
            [
              -93.566017,
              31.004567
            ],
            [
              -93.56798,
              31.001534
            ],
            [
              -93.569764,
              30.996715
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "021",
      "COUNTYNS": "00558477",
      "AFFGEOID": "0500000US22021",
      "GEOID": "22021",
      "NAME": "Caldwell",
      "LSAD": "15",
      "ALAND": 1371203831,
      "AWATER": 29277303,
      "County_state": "Caldwell,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.312218,
              32.146123
            ],
            [
              -92.312623,
              31.927382
            ],
            [
              -92.005434,
              31.927296
            ],
            [
              -91.904381,
              31.927561
            ],
            [
              -91.90471,
              31.971533
            ],
            [
              -91.889687,
              31.971474
            ],
            [
              -91.902051,
              31.995805
            ],
            [
              -91.894379,
              32.010551
            ],
            [
              -91.902815,
              32.025444
            ],
            [
              -91.898496,
              32.040193
            ],
            [
              -91.909014,
              32.04621
            ],
            [
              -91.922108,
              32.066297
            ],
            [
              -91.933229,
              32.066882
            ],
            [
              -91.911081,
              32.08504
            ],
            [
              -91.903527,
              32.075809
            ],
            [
              -91.898017,
              32.085565
            ],
            [
              -91.868202,
              32.091555
            ],
            [
              -91.874466,
              32.104064
            ],
            [
              -91.869477,
              32.115354
            ],
            [
              -91.883078,
              32.132347
            ],
            [
              -91.894385,
              32.137987
            ],
            [
              -91.894729,
              32.15155
            ],
            [
              -91.900438,
              32.152698
            ],
            [
              -91.894885,
              32.16358
            ],
            [
              -91.910586,
              32.16906
            ],
            [
              -91.932756,
              32.165236
            ],
            [
              -91.954852,
              32.173558
            ],
            [
              -91.979868,
              32.166148
            ],
            [
              -91.975908,
              32.17718
            ],
            [
              -91.982313,
              32.193292
            ],
            [
              -91.975255,
              32.20429
            ],
            [
              -92.000132,
              32.190393
            ],
            [
              -92.006391,
              32.201628
            ],
            [
              -91.977979,
              32.216175
            ],
            [
              -91.984835,
              32.223089
            ],
            [
              -91.998233,
              32.211532
            ],
            [
              -91.992589,
              32.227093
            ],
            [
              -92.005402,
              32.228909
            ],
            [
              -91.996989,
              32.246383
            ],
            [
              -91.993367,
              32.276795
            ],
            [
              -92.034397,
              32.277068
            ],
            [
              -92.106385,
              32.276632
            ],
            [
              -92.106115,
              32.258552
            ],
            [
              -92.152628,
              32.258645
            ],
            [
              -92.164744,
              32.276697
            ],
            [
              -92.311851,
              32.277441
            ],
            [
              -92.312218,
              32.146123
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "055",
      "COUNTYNS": "00558059",
      "AFFGEOID": "0500000US22055",
      "GEOID": "22055",
      "NAME": "Lafayette",
      "LSAD": "15",
      "ALAND": 696041521,
      "AWATER": 1208724,
      "County_state": "Lafayette,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.284675,
              30.147443
            ],
            [
              -92.279691,
              30.141533
            ],
            [
              -92.22705,
              30.136896
            ],
            [
              -92.202722,
              30.14286
            ],
            [
              -92.170159,
              30.130853
            ],
            [
              -92.128423,
              30.115989
            ],
            [
              -92.119913,
              30.121843
            ],
            [
              -92.088421,
              30.128299
            ],
            [
              -92.072743,
              30.080871
            ],
            [
              -92.066153,
              30.073926
            ],
            [
              -92.023819,
              30.058455
            ],
            [
              -91.964458,
              30.036918
            ],
            [
              -91.961974,
              30.043582
            ],
            [
              -91.950998,
              30.073213
            ],
            [
              -91.941428,
              30.099765
            ],
            [
              -91.934067,
              30.119882
            ],
            [
              -91.926433,
              30.12937
            ],
            [
              -91.905544,
              30.154616
            ],
            [
              -91.920718,
              30.1879
            ],
            [
              -91.954718,
              30.1871
            ],
            [
              -91.965619,
              30.176701
            ],
            [
              -91.979565,
              30.181884
            ],
            [
              -91.976879,
              30.210933
            ],
            [
              -91.957918,
              30.2119
            ],
            [
              -91.955466,
              30.218965
            ],
            [
              -91.943618,
              30.226
            ],
            [
              -91.942818,
              30.236599
            ],
            [
              -91.958909,
              30.237184
            ],
            [
              -91.976933,
              30.260649
            ],
            [
              -91.949047,
              30.276771
            ],
            [
              -91.952329,
              30.29278
            ],
            [
              -91.954997,
              30.308368
            ],
            [
              -91.965938,
              30.325863
            ],
            [
              -91.955918,
              30.333898
            ],
            [
              -91.953927,
              30.3598
            ],
            [
              -91.988718,
              30.368598
            ],
            [
              -92.015775,
              30.371395
            ],
            [
              -92.03175,
              30.36121
            ],
            [
              -92.042619,
              30.376496
            ],
            [
              -92.04943,
              30.381307
            ],
            [
              -92.06132,
              30.373198
            ],
            [
              -92.083321,
              30.360599
            ],
            [
              -92.089446,
              30.330166
            ],
            [
              -92.142184,
              30.298817
            ],
            [
              -92.142014,
              30.284523
            ],
            [
              -92.159509,
              30.284542
            ],
            [
              -92.159137,
              30.269839
            ],
            [
              -92.176279,
              30.269923
            ],
            [
              -92.176097,
              30.241076
            ],
            [
              -92.210202,
              30.241344
            ],
            [
              -92.209894,
              30.226357
            ],
            [
              -92.233996,
              30.219685
            ],
            [
              -92.25049,
              30.203198
            ],
            [
              -92.246328,
              30.185459
            ],
            [
              -92.275312,
              30.146134
            ],
            [
              -92.284675,
              30.147443
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "087",
      "COUNTYNS": "01629542",
      "AFFGEOID": "0500000US22087",
      "GEOID": "22087",
      "NAME": "St. Bernard",
      "LSAD": "15",
      "ALAND": 977746424,
      "AWATER": 4604207636,
      "County_state": "St. Bernard,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.881454,
              30.053202
            ],
            [
              -88.877933,
              30.041126
            ],
            [
              -88.867856,
              30.025858
            ],
            [
              -88.854419,
              30.012042
            ],
            [
              -88.84938,
              29.994588
            ],
            [
              -88.838715,
              29.962326
            ],
            [
              -88.837379,
              29.944878
            ],
            [
              -88.835104,
              29.922558
            ],
            [
              -88.836784,
              29.886159
            ],
            [
              -88.843277,
              29.86381
            ],
            [
              -88.846861,
              29.836636
            ],
            [
              -88.853579,
              29.821337
            ],
            [
              -88.861267,
              29.805826
            ],
            [
              -88.867973,
              29.79133
            ],
            [
              -88.873734,
              29.775426
            ],
            [
              -88.875091,
              29.759818
            ],
            [
              -88.86295,
              29.757052
            ],
            [
              -88.844078,
              29.795713
            ],
            [
              -88.83271,
              29.824062
            ],
            [
              -88.826538,
              29.847092
            ],
            [
              -88.818146,
              29.889109
            ],
            [
              -88.817017,
              29.93425
            ],
            [
              -88.824158,
              29.970461
            ],
            [
              -88.833725,
              29.998821
            ],
            [
              -88.841225,
              30.012789
            ],
            [
              -88.855583,
              30.034414
            ],
            [
              -88.870476,
              30.049212
            ],
            [
              -88.881454,
              30.053202
            ]
          ]
        ],
        [
          [
            [
              -89.342163,
              30.059172
            ],
            [
              -89.336991,
              30.039992
            ],
            [
              -89.328706,
              30.038323
            ],
            [
              -89.308504,
              30.039763
            ],
            [
              -89.281262,
              30.032894
            ],
            [
              -89.276179,
              30.031739
            ],
            [
              -89.259779,
              30.049433
            ],
            [
              -89.239289,
              30.040805
            ],
            [
              -89.227217,
              30.021244
            ],
            [
              -89.2295,
              30.000433
            ],
            [
              -89.215675,
              29.993523
            ],
            [
              -89.201584,
              30.001429
            ],
            [
              -89.212336,
              30.019577
            ],
            [
              -89.202974,
              30.03462
            ],
            [
              -89.183959,
              30.032768
            ],
            [
              -89.173401,
              30.04336
            ],
            [
              -89.185799,
              30.063934
            ],
            [
              -89.2208,
              30.071445
            ],
            [
              -89.227085,
              30.095126
            ],
            [
              -89.212966,
              30.115427
            ],
            [
              -89.183256,
              30.149344
            ],
            [
              -89.190475,
              30.16176
            ],
            [
              -89.233168,
              30.134957
            ],
            [
              -89.303026,
              30.09157
            ],
            [
              -89.316431,
              30.069213
            ],
            [
              -89.342163,
              30.059172
            ]
          ]
        ],
        [
          [
            [
              -89.442093,
              30.144369
            ],
            [
              -89.453022,
              30.139068
            ],
            [
              -89.439003,
              30.127527
            ],
            [
              -89.429031,
              30.128559
            ],
            [
              -89.417694,
              30.143682
            ],
            [
              -89.424501,
              30.153455
            ],
            [
              -89.442093,
              30.144369
            ]
          ]
        ],
        [
          [
            [
              -89.989882,
              29.98949
            ],
            [
              -90.012102,
              29.94598
            ],
            [
              -89.969768,
              29.924702
            ],
            [
              -89.926895,
              29.920617
            ],
            [
              -89.907784,
              29.897151
            ],
            [
              -89.902694,
              29.878068
            ],
            [
              -89.910708,
              29.867865
            ],
            [
              -89.895932,
              29.833237
            ],
            [
              -89.878485,
              29.815193
            ],
            [
              -89.86972,
              29.795451
            ],
            [
              -89.859815,
              29.793042
            ],
            [
              -89.83066,
              29.801246
            ],
            [
              -89.816395,
              29.801327
            ],
            [
              -89.803004,
              29.792724
            ],
            [
              -89.786198,
              29.773281
            ],
            [
              -89.790754,
              29.760821
            ],
            [
              -89.767727,
              29.755323
            ],
            [
              -89.758023,
              29.758498
            ],
            [
              -89.721045,
              29.757652
            ],
            [
              -89.705033,
              29.752792
            ],
            [
              -89.692208,
              29.738457
            ],
            [
              -89.683853,
              29.716033
            ],
            [
              -89.660282,
              29.708747
            ],
            [
              -89.628181,
              29.680682
            ],
            [
              -89.570928,
              29.665046
            ],
            [
              -89.53376,
              29.670204
            ],
            [
              -89.519563,
              29.692215
            ],
            [
              -89.524887,
              29.726566
            ],
            [
              -89.486961,
              29.72592
            ],
            [
              -89.467599,
              29.699019
            ],
            [
              -89.471182,
              29.673158
            ],
            [
              -89.465562,
              29.651738
            ],
            [
              -89.433808,
              29.656365
            ],
            [
              -89.426667,
              29.678625
            ],
            [
              -89.42421,
              29.697638
            ],
            [
              -89.403956,
              29.681808
            ],
            [
              -89.387794,
              29.679991
            ],
            [
              -89.39609,
              29.69572
            ],
            [
              -89.43025,
              29.712606
            ],
            [
              -89.428207,
              29.74155
            ],
            [
              -89.414536,
              29.752371
            ],
            [
              -89.399162,
              29.770592
            ],
            [
              -89.394608,
              29.784828
            ],
            [
              -89.386063,
              29.788815
            ],
            [
              -89.367271,
              29.775148
            ],
            [
              -89.354179,
              29.781412
            ],
            [
              -89.337662,
              29.779135
            ],
            [
              -89.325134,
              29.772301
            ],
            [
              -89.316025,
              29.760912
            ],
            [
              -89.305199,
              29.756926
            ],
            [
              -89.271034,
              29.756355
            ],
            [
              -89.269325,
              29.760912
            ],
            [
              -89.284706,
              29.770021
            ],
            [
              -89.284134,
              29.795649
            ],
            [
              -89.277298,
              29.799635
            ],
            [
              -89.277298,
              29.807608
            ],
            [
              -89.293251,
              29.803053
            ],
            [
              -89.318306,
              29.788815
            ],
            [
              -89.33197,
              29.790524
            ],
            [
              -89.342781,
              29.798496
            ],
            [
              -89.345634,
              29.820135
            ],
            [
              -89.372971,
              29.82526
            ],
            [
              -89.383217,
              29.830385
            ],
            [
              -89.383789,
              29.838928
            ],
            [
              -89.363289,
              29.84576
            ],
            [
              -89.317726,
              29.850885
            ],
            [
              -89.294952,
              29.857149
            ],
            [
              -89.269897,
              29.859997
            ],
            [
              -89.254517,
              29.864552
            ],
            [
              -89.236298,
              29.877081
            ],
            [
              -89.236298,
              29.886763
            ],
            [
              -89.241425,
              29.88961
            ],
            [
              -89.272179,
              29.886763
            ],
            [
              -89.289253,
              29.880499
            ],
            [
              -89.311462,
              29.881636
            ],
            [
              -89.322289,
              29.887333
            ],
            [
              -89.318306,
              29.898149
            ],
            [
              -89.280144,
              29.924915
            ],
            [
              -89.263062,
              29.929472
            ],
            [
              -89.244843,
              29.93004
            ],
            [
              -89.231178,
              29.925484
            ],
            [
              -89.22377,
              29.961929
            ],
            [
              -89.218071,
              29.97275
            ],
            [
              -89.249969,
              29.975597
            ],
            [
              -89.243706,
              29.997236
            ],
            [
              -89.250534,
              30.002361
            ],
            [
              -89.273315,
              29.99382
            ],
            [
              -89.283562,
              29.97332
            ],
            [
              -89.315453,
              29.923208
            ],
            [
              -89.331894,
              29.91585
            ],
            [
              -89.368019,
              29.911491
            ],
            [
              -89.378601,
              29.919588
            ],
            [
              -89.379227,
              29.963804
            ],
            [
              -89.393555,
              29.966295
            ],
            [
              -89.40538,
              29.965672
            ],
            [
              -89.432785,
              29.978752
            ],
            [
              -89.433411,
              29.991205
            ],
            [
              -89.432785,
              30.008022
            ],
            [
              -89.422813,
              30.015495
            ],
            [
              -89.41597,
              30.020477
            ],
            [
              -89.393555,
              30.029818
            ],
            [
              -89.381096,
              30.030441
            ],
            [
              -89.372375,
              30.036671
            ],
            [
              -89.368637,
              30.047256
            ],
            [
              -89.372375,
              30.054729
            ],
            [
              -89.418465,
              30.049747
            ],
            [
              -89.429047,
              30.05224
            ],
            [
              -89.444618,
              30.060959
            ],
            [
              -89.458946,
              30.06345
            ],
            [
              -89.481926,
              30.079128
            ],
            [
              -89.493484,
              30.072191
            ],
            [
              -89.499275,
              30.058893
            ],
            [
              -89.494637,
              30.0508
            ],
            [
              -89.494064,
              30.040972
            ],
            [
              -89.501587,
              30.034037
            ],
            [
              -89.551292,
              30.005709
            ],
            [
              -89.571533,
              29.999926
            ],
            [
              -89.58136,
              29.994722
            ],
            [
              -89.574425,
              29.983738
            ],
            [
              -89.574997,
              29.959455
            ],
            [
              -89.583099,
              29.945581
            ],
            [
              -89.583099,
              29.931705
            ],
            [
              -89.592346,
              29.917253
            ],
            [
              -89.591194,
              29.897018
            ],
            [
              -89.598129,
              29.881409
            ],
            [
              -89.613159,
              29.87216
            ],
            [
              -89.638016,
              29.864065
            ],
            [
              -89.660568,
              29.862909
            ],
            [
              -89.671555,
              29.867535
            ],
            [
              -89.692004,
              29.868722
            ],
            [
              -89.711158,
              29.879287
            ],
            [
              -89.742479,
              29.90817
            ],
            [
              -89.746273,
              29.928221
            ],
            [
              -89.742727,
              29.935894
            ],
            [
              -89.736311,
              29.936263
            ],
            [
              -89.71291,
              29.946349
            ],
            [
              -89.719067,
              29.953699
            ],
            [
              -89.727933,
              29.95878
            ],
            [
              -89.748492,
              29.945831
            ],
            [
              -89.775459,
              29.937416
            ],
            [
              -89.804463,
              29.932588
            ],
            [
              -89.81803,
              29.934145
            ],
            [
              -89.829023,
              29.939228
            ],
            [
              -89.8326957046356,
              29.941781105216798
            ],
            [
              -89.8385,
              29.945816
            ],
            [
              -89.844202,
              29.955645
            ],
            [
              -89.852312,
              29.97765
            ],
            [
              -89.8546384892603,
              29.9895303508949
            ],
            [
              -89.85611454998869,
              29.9970679396967
            ],
            [
              -89.859307,
              30.002859
            ],
            [
              -89.879699,
              29.985443
            ],
            [
              -89.894539,
              30.002325
            ],
            [
              -89.914168,
              29.999098
            ],
            [
              -89.945365,
              29.982401
            ],
            [
              -89.953038,
              29.981165
            ],
            [
              -89.974291,
              29.994692
            ],
            [
              -89.989882,
              29.98949
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "037",
      "COUNTYNS": "00558542",
      "AFFGEOID": "0500000US22037",
      "GEOID": "22037",
      "NAME": "East Feliciana",
      "LSAD": "15",
      "ALAND": 1174307241,
      "AWATER": 6151756,
      "County_state": "East Feliciana,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.320706,
              30.699417
            ],
            [
              -91.305212,
              30.673382
            ],
            [
              -91.310061,
              30.653961
            ],
            [
              -91.297658,
              30.649548
            ],
            [
              -91.288731,
              30.677904
            ],
            [
              -91.270216,
              30.680929
            ],
            [
              -91.264839,
              30.698677
            ],
            [
              -91.254419,
              30.705294
            ],
            [
              -91.053393,
              30.712184
            ],
            [
              -90.849041,
              30.719311
            ],
            [
              -90.839207,
              30.724545
            ],
            [
              -90.850396,
              30.754326
            ],
            [
              -90.841945,
              30.765474
            ],
            [
              -90.844672,
              30.792886
            ],
            [
              -90.834735,
              30.813177
            ],
            [
              -90.848207,
              30.839675
            ],
            [
              -90.840953,
              30.84724
            ],
            [
              -90.858293,
              30.858894
            ],
            [
              -90.857983,
              30.873144
            ],
            [
              -90.856788,
              30.872343
            ],
            [
              -90.845366,
              30.888574
            ],
            [
              -90.863041,
              30.941981
            ],
            [
              -90.845242,
              30.962524
            ],
            [
              -90.844211,
              30.982705
            ],
            [
              -90.8258293654941,
              30.9993604066554
            ],
            [
              -90.826027,
              30.99936
            ],
            [
              -90.89473,
              30.99963
            ],
            [
              -91.0421723672845,
              30.999026772612797
            ],
            [
              -91.0602129522528,
              30.9989529636044
            ],
            [
              -91.06827,
              30.99892
            ],
            [
              -91.080814,
              30.998909
            ],
            [
              -91.108114,
              30.998857
            ],
            [
              -91.108291,
              30.99888
            ],
            [
              -91.17613975227569,
              30.9991437308313
            ],
            [
              -91.182138,
              30.974454
            ],
            [
              -91.195143,
              30.962905
            ],
            [
              -91.194731,
              30.945544
            ],
            [
              -91.186858,
              30.936544
            ],
            [
              -91.208848,
              30.914816
            ],
            [
              -91.207246,
              30.891956
            ],
            [
              -91.217104,
              30.892945
            ],
            [
              -91.222387,
              30.880762
            ],
            [
              -91.219648,
              30.864538
            ],
            [
              -91.229253,
              30.853388
            ],
            [
              -91.224791,
              30.846957
            ],
            [
              -91.232648,
              30.827883
            ],
            [
              -91.234828,
              30.8247
            ],
            [
              -91.230589,
              30.810482
            ],
            [
              -91.242616,
              30.804611
            ],
            [
              -91.236164,
              30.795093
            ],
            [
              -91.24886,
              30.786429
            ],
            [
              -91.267553,
              30.757337
            ],
            [
              -91.276942,
              30.758374
            ],
            [
              -91.28618,
              30.74666
            ],
            [
              -91.286119,
              30.734524
            ],
            [
              -91.296684,
              30.725444
            ],
            [
              -91.293103,
              30.715571
            ],
            [
              -91.320706,
              30.699417
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "125",
      "COUNTYNS": "00559584",
      "AFFGEOID": "0500000US22125",
      "GEOID": "22125",
      "NAME": "West Feliciana",
      "LSAD": "15",
      "ALAND": 1044342243,
      "AWATER": 58999603,
      "County_state": "West Feliciana,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.648766,
              30.983167
            ],
            [
              -91.662506,
              30.970362
            ],
            [
              -91.644917,
              30.928971
            ],
            [
              -91.660864,
              30.895093
            ],
            [
              -91.661372,
              30.857099
            ],
            [
              -91.646961,
              30.844936
            ],
            [
              -91.623381,
              30.84048
            ],
            [
              -91.603031,
              30.845964
            ],
            [
              -91.578546,
              30.846278
            ],
            [
              -91.567459,
              30.83678
            ],
            [
              -91.556407,
              30.848643
            ],
            [
              -91.563605,
              30.883332
            ],
            [
              -91.576078,
              30.893402
            ],
            [
              -91.609559,
              30.898444
            ],
            [
              -91.614608,
              30.9176
            ],
            [
              -91.568307,
              30.918802
            ],
            [
              -91.549308,
              30.916228
            ],
            [
              -91.521413,
              30.890078
            ],
            [
              -91.515476,
              30.872403
            ],
            [
              -91.517894,
              30.851381
            ],
            [
              -91.539473,
              30.791224
            ],
            [
              -91.557251,
              30.778159
            ],
            [
              -91.584475,
              30.772765
            ],
            [
              -91.592941,
              30.766016
            ],
            [
              -91.594595,
              30.750184
            ],
            [
              -91.571266,
              30.735344
            ],
            [
              -91.553019,
              30.730798
            ],
            [
              -91.509918,
              30.740279
            ],
            [
              -91.486906,
              30.741337
            ],
            [
              -91.450382,
              30.736168
            ],
            [
              -91.395037,
              30.757892
            ],
            [
              -91.375112,
              30.756717
            ],
            [
              -91.353181,
              30.722048
            ],
            [
              -91.340652,
              30.670238
            ],
            [
              -91.33087,
              30.658677
            ],
            [
              -91.322256,
              30.656025
            ],
            [
              -91.310061,
              30.653961
            ],
            [
              -91.305212,
              30.673382
            ],
            [
              -91.320706,
              30.699417
            ],
            [
              -91.293103,
              30.715571
            ],
            [
              -91.296684,
              30.725444
            ],
            [
              -91.286119,
              30.734524
            ],
            [
              -91.28618,
              30.74666
            ],
            [
              -91.276942,
              30.758374
            ],
            [
              -91.267553,
              30.757337
            ],
            [
              -91.24886,
              30.786429
            ],
            [
              -91.236164,
              30.795093
            ],
            [
              -91.242616,
              30.804611
            ],
            [
              -91.230589,
              30.810482
            ],
            [
              -91.234828,
              30.8247
            ],
            [
              -91.232648,
              30.827883
            ],
            [
              -91.224791,
              30.846957
            ],
            [
              -91.229253,
              30.853388
            ],
            [
              -91.219648,
              30.864538
            ],
            [
              -91.222387,
              30.880762
            ],
            [
              -91.217104,
              30.892945
            ],
            [
              -91.207246,
              30.891956
            ],
            [
              -91.208848,
              30.914816
            ],
            [
              -91.186858,
              30.936544
            ],
            [
              -91.194731,
              30.945544
            ],
            [
              -91.195143,
              30.962905
            ],
            [
              -91.182138,
              30.974454
            ],
            [
              -91.17613975227569,
              30.9991437308313
            ],
            [
              -91.176209,
              30.999144
            ],
            [
              -91.224068,
              30.999183
            ],
            [
              -91.224839,
              30.999183
            ],
            [
              -91.24649,
              30.999474
            ],
            [
              -91.39651504144929,
              30.999058983598
            ],
            [
              -91.423621,
              30.998984
            ],
            [
              -91.425749,
              30.999007
            ],
            [
              -91.49739,
              30.999006
            ],
            [
              -91.500116,
              30.998691
            ],
            [
              -91.538727,
              30.999388
            ],
            [
              -91.625118,
              30.999167
            ],
            [
              -91.636942,
              30.999416
            ],
            [
              -91.648766,
              30.983167
            ]
          ]
        ],
        [
          [
            [
              -91.747552,
              31.023223
            ],
            [
              -91.750119,
              31.018814
            ],
            [
              -91.714001,
              31.006768
            ],
            [
              -91.687266,
              31.018142
            ],
            [
              -91.658321,
              30.994365
            ],
            [
              -91.644117,
              31.022516
            ],
            [
              -91.649246,
              31.037183
            ],
            [
              -91.659808,
              31.043991
            ],
            [
              -91.696606,
              31.039186
            ],
            [
              -91.723804,
              31.044254
            ],
            [
              -91.747552,
              31.023223
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "091",
      "COUNTYNS": "01629457",
      "AFFGEOID": "0500000US22091",
      "GEOID": "22091",
      "NAME": "St. Helena",
      "LSAD": "15",
      "ALAND": 1057772583,
      "AWATER": 2796210,
      "County_state": "St. Helena,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.870916,
              30.693329
            ],
            [
              -90.910701,
              30.649385
            ],
            [
              -90.852662,
              30.649564
            ],
            [
              -90.634603,
              30.649827
            ],
            [
              -90.567165,
              30.650023
            ],
            [
              -90.565857,
              30.663902
            ],
            [
              -90.56673,
              30.747784
            ],
            [
              -90.567485,
              30.754113
            ],
            [
              -90.567171,
              30.824806
            ],
            [
              -90.567195,
              30.999733
            ],
            [
              -90.583518,
              30.999698
            ],
            [
              -90.584448,
              30.999698
            ],
            [
              -90.587373,
              30.999604
            ],
            [
              -90.588676,
              30.99965
            ],
            [
              -90.648721,
              30.999486
            ],
            [
              -90.651193,
              30.99951
            ],
            [
              -90.734473,
              30.999214
            ],
            [
              -90.734552,
              30.999222
            ],
            [
              -90.758775,
              30.999583
            ],
            [
              -90.769333,
              30.999374
            ],
            [
              -90.775981,
              30.999491
            ],
            [
              -90.779858,
              30.999457
            ],
            [
              -90.783745,
              30.999447
            ],
            [
              -90.8258293654941,
              30.9993604066554
            ],
            [
              -90.844211,
              30.982705
            ],
            [
              -90.845242,
              30.962524
            ],
            [
              -90.863041,
              30.941981
            ],
            [
              -90.845366,
              30.888574
            ],
            [
              -90.856788,
              30.872343
            ],
            [
              -90.857983,
              30.873144
            ],
            [
              -90.858293,
              30.858894
            ],
            [
              -90.840953,
              30.84724
            ],
            [
              -90.848207,
              30.839675
            ],
            [
              -90.834735,
              30.813177
            ],
            [
              -90.844672,
              30.792886
            ],
            [
              -90.841945,
              30.765474
            ],
            [
              -90.850396,
              30.754326
            ],
            [
              -90.839207,
              30.724545
            ],
            [
              -90.849041,
              30.719311
            ],
            [
              -90.844181,
              30.708047
            ],
            [
              -90.870916,
              30.693329
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "083",
      "COUNTYNS": "00559481",
      "AFFGEOID": "0500000US22083",
      "GEOID": "22083",
      "NAME": "Richland",
      "LSAD": "15",
      "ALAND": 1448537069,
      "AWATER": 13551678,
      "County_state": "Richland,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.020179,
              32.29679
            ],
            [
              -92.034397,
              32.277068
            ],
            [
              -91.993367,
              32.276795
            ],
            [
              -91.996989,
              32.246383
            ],
            [
              -92.005402,
              32.228909
            ],
            [
              -91.992589,
              32.227093
            ],
            [
              -91.998233,
              32.211532
            ],
            [
              -91.984835,
              32.223089
            ],
            [
              -91.977979,
              32.216175
            ],
            [
              -92.006391,
              32.201628
            ],
            [
              -92.000132,
              32.190393
            ],
            [
              -91.975255,
              32.20429
            ],
            [
              -91.982313,
              32.193292
            ],
            [
              -91.975908,
              32.17718
            ],
            [
              -91.979868,
              32.166148
            ],
            [
              -91.954852,
              32.173558
            ],
            [
              -91.932756,
              32.165236
            ],
            [
              -91.910586,
              32.16906
            ],
            [
              -91.894885,
              32.16358
            ],
            [
              -91.900438,
              32.152698
            ],
            [
              -91.894729,
              32.15155
            ],
            [
              -91.872734,
              32.171824
            ],
            [
              -91.837285,
              32.176158
            ],
            [
              -91.830503,
              32.181013
            ],
            [
              -91.819768,
              32.213672
            ],
            [
              -91.797048,
              32.225125
            ],
            [
              -91.775019,
              32.243333
            ],
            [
              -91.769165,
              32.257188
            ],
            [
              -91.774117,
              32.277351
            ],
            [
              -91.76185,
              32.298504
            ],
            [
              -91.7613,
              32.334989
            ],
            [
              -91.723188,
              32.333621
            ],
            [
              -91.701538,
              32.344879
            ],
            [
              -91.698824,
              32.369594
            ],
            [
              -91.680386,
              32.373144
            ],
            [
              -91.680253,
              32.383544
            ],
            [
              -91.658298,
              32.38617
            ],
            [
              -91.634883,
              32.405253
            ],
            [
              -91.476739,
              32.405808
            ],
            [
              -91.486497,
              32.413229
            ],
            [
              -91.486536,
              32.434809
            ],
            [
              -91.471653,
              32.433785
            ],
            [
              -91.484501,
              32.47176
            ],
            [
              -91.484971,
              32.494965
            ],
            [
              -91.457643,
              32.537807
            ],
            [
              -91.430908,
              32.560477
            ],
            [
              -91.445299,
              32.581956
            ],
            [
              -91.596027,
              32.582192
            ],
            [
              -91.596876,
              32.667212
            ],
            [
              -91.637587,
              32.667341
            ],
            [
              -91.647093,
              32.639924
            ],
            [
              -91.671986,
              32.611187
            ],
            [
              -91.705991,
              32.613177
            ],
            [
              -91.73477,
              32.587854
            ],
            [
              -91.735118,
              32.571477
            ],
            [
              -91.74773,
              32.557484
            ],
            [
              -91.759742,
              32.559571
            ],
            [
              -91.769798,
              32.554375
            ],
            [
              -91.80432,
              32.571316
            ],
            [
              -91.816579,
              32.567203
            ],
            [
              -91.818415,
              32.553835
            ],
            [
              -91.837652,
              32.543531
            ],
            [
              -91.839532,
              32.518482
            ],
            [
              -91.866439,
              32.522483
            ],
            [
              -91.888816,
              32.543484
            ],
            [
              -91.897824,
              32.53566
            ],
            [
              -91.903312,
              32.509838
            ],
            [
              -91.913057,
              32.505902
            ],
            [
              -91.919716,
              32.497422
            ],
            [
              -91.933301,
              32.490932
            ],
            [
              -91.944278,
              32.448777
            ],
            [
              -91.983631,
              32.413859
            ],
            [
              -91.993631,
              32.397902
            ],
            [
              -92.011423,
              32.363106
            ],
            [
              -92.006192,
              32.354244
            ],
            [
              -92.01767,
              32.308781
            ],
            [
              -92.020179,
              32.29679
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "059",
      "COUNTYNS": "00558049",
      "AFFGEOID": "0500000US22059",
      "GEOID": "22059",
      "NAME": "LaSalle",
      "LSAD": "15",
      "ALAND": 1617924843,
      "AWATER": 97664776,
      "County_state": "LaSalle,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.36754,
              31.618483
            ],
            [
              -92.384613,
              31.620131
            ],
            [
              -92.34297,
              31.595824
            ],
            [
              -92.306855,
              31.58952
            ],
            [
              -92.32008,
              31.57173
            ],
            [
              -92.310231,
              31.573235
            ],
            [
              -92.298709,
              31.563248
            ],
            [
              -92.304568,
              31.553157
            ],
            [
              -92.286896,
              31.536584
            ],
            [
              -92.275088,
              31.536087
            ],
            [
              -92.276623,
              31.495385
            ],
            [
              -92.239461,
              31.501227
            ],
            [
              -92.24827,
              31.48448
            ],
            [
              -92.213969,
              31.491579
            ],
            [
              -92.212733,
              31.481645
            ],
            [
              -92.196131,
              31.477877
            ],
            [
              -92.160698,
              31.4672
            ],
            [
              -92.167492,
              31.455591
            ],
            [
              -92.154481,
              31.447432
            ],
            [
              -92.161874,
              31.434554
            ],
            [
              -92.148761,
              31.404911
            ],
            [
              -92.109296,
              31.372761
            ],
            [
              -92.102652,
              31.377277
            ],
            [
              -92.083288,
              31.364943
            ],
            [
              -92.07239,
              31.343174
            ],
            [
              -92.086718,
              31.335374
            ],
            [
              -92.081357,
              31.324143
            ],
            [
              -92.025948,
              31.343009
            ],
            [
              -92.008121,
              31.325797
            ],
            [
              -92.008386,
              31.615433
            ],
            [
              -92.0085,
              31.642788
            ],
            [
              -92.008154,
              31.796315
            ],
            [
              -92.005434,
              31.927296
            ],
            [
              -92.312623,
              31.927382
            ],
            [
              -92.312586,
              31.89618
            ],
            [
              -92.324671,
              31.886304
            ],
            [
              -92.321311,
              31.865134
            ],
            [
              -92.337095,
              31.851613
            ],
            [
              -92.329229,
              31.849415
            ],
            [
              -92.340768,
              31.833075
            ],
            [
              -92.340494,
              31.813367
            ],
            [
              -92.362227,
              31.808113
            ],
            [
              -92.362608,
              31.796633
            ],
            [
              -92.361287,
              31.78136
            ],
            [
              -92.352974,
              31.780395
            ],
            [
              -92.344578,
              31.758987
            ],
            [
              -92.358272,
              31.742726
            ],
            [
              -92.350709,
              31.738527
            ],
            [
              -92.360483,
              31.721462
            ],
            [
              -92.36837,
              31.723024
            ],
            [
              -92.372476,
              31.701519
            ],
            [
              -92.347739,
              31.696584
            ],
            [
              -92.367061,
              31.648809
            ],
            [
              -92.351466,
              31.624242
            ],
            [
              -92.36754,
              31.618483
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "007",
      "COUNTYNS": "00558414",
      "AFFGEOID": "0500000US22007",
      "GEOID": "22007",
      "NAME": "Assumption",
      "LSAD": "15",
      "ALAND": 877133400,
      "AWATER": 66996288,
      "County_state": "Assumption,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.259388,
              30.0002
            ],
            [
              -91.255229,
              29.971457
            ],
            [
              -91.251395,
              29.957591
            ],
            [
              -91.231576,
              29.935649
            ],
            [
              -91.232016,
              29.918171
            ],
            [
              -91.214076,
              29.907569
            ],
            [
              -91.200263,
              29.861793
            ],
            [
              -91.187338,
              29.84869
            ],
            [
              -91.150227,
              29.836831
            ],
            [
              -91.133079,
              29.837306
            ],
            [
              -91.109462,
              29.823945
            ],
            [
              -91.092748,
              29.801574
            ],
            [
              -91.100052,
              29.785059
            ],
            [
              -91.094378,
              29.764575
            ],
            [
              -91.095364,
              29.737032
            ],
            [
              -91.106551,
              29.733487
            ],
            [
              -91.10001,
              29.699402
            ],
            [
              -91.095213,
              29.696239
            ],
            [
              -91.105249,
              29.643012
            ],
            [
              -91.086163,
              29.634838
            ],
            [
              -91.082602,
              29.626368
            ],
            [
              -91.006743,
              29.714771
            ],
            [
              -90.947496,
              29.792306
            ],
            [
              -90.92447,
              29.830307
            ],
            [
              -90.925046,
              29.830377
            ],
            [
              -90.909695,
              29.877104
            ],
            [
              -90.885442,
              29.877502
            ],
            [
              -90.885589,
              29.905353
            ],
            [
              -90.885925,
              29.92373
            ],
            [
              -90.886199,
              29.935644
            ],
            [
              -90.925849,
              29.934955
            ],
            [
              -90.926078,
              29.978225
            ],
            [
              -90.959869,
              29.977924
            ],
            [
              -90.959571,
              30.035594
            ],
            [
              -90.963693,
              30.06645
            ],
            [
              -90.984318,
              30.064987
            ],
            [
              -91.005748,
              30.079106
            ],
            [
              -91.056106,
              30.070328
            ],
            [
              -91.074581,
              30.081631
            ],
            [
              -91.089329,
              30.076593
            ],
            [
              -91.089103,
              30.062514
            ],
            [
              -91.105951,
              30.062478
            ],
            [
              -91.224356,
              30.025336
            ],
            [
              -91.235889,
              30.001128
            ],
            [
              -91.259388,
              30.0002
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "027",
      "COUNTYNS": "00558505",
      "AFFGEOID": "0500000US22027",
      "GEOID": "22027",
      "NAME": "Claiborne",
      "LSAD": "15",
      "ALAND": 1955125391,
      "AWATER": 32473222,
      "County_state": "Claiborne,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.238607,
              33.017992
            ],
            [
              -93.237381,
              32.71625
            ],
            [
              -93.186571,
              32.716755
            ],
            [
              -93.186286,
              32.672657
            ],
            [
              -93.173803,
              32.63364
            ],
            [
              -93.178666,
              32.62515
            ],
            [
              -93.178767,
              32.584916
            ],
            [
              -93.002742,
              32.584095
            ],
            [
              -92.938598,
              32.58461
            ],
            [
              -92.880805,
              32.585277
            ],
            [
              -92.881359,
              32.600918
            ],
            [
              -92.862248,
              32.621164
            ],
            [
              -92.868775,
              32.678001
            ],
            [
              -92.863924,
              32.687715
            ],
            [
              -92.851995,
              32.696303
            ],
            [
              -92.839655,
              32.709669
            ],
            [
              -92.828049,
              32.714388
            ],
            [
              -92.828535,
              32.758814
            ],
            [
              -92.725439,
              32.75954
            ],
            [
              -92.725403,
              32.803457
            ],
            [
              -92.734057,
              32.803436
            ],
            [
              -92.734045,
              32.817998
            ],
            [
              -92.725369,
              32.817979
            ],
            [
              -92.72474282953719,
              33.0143469910336
            ],
            [
              -92.724994,
              33.014351
            ],
            [
              -92.733197,
              33.014347
            ],
            [
              -92.796533,
              33.014836
            ],
            [
              -92.830798,
              33.015661
            ],
            [
              -92.844073,
              33.016034
            ],
            [
              -92.844286,
              33.01607
            ],
            [
              -92.854167,
              33.016132
            ],
            [
              -92.86751,
              33.016062
            ],
            [
              -92.946553,
              33.016807
            ],
            [
              -92.971137,
              33.017192
            ],
            [
              -92.9887078142573,
              33.0172979025058
            ],
            [
              -93.070686,
              33.017792
            ],
            [
              -93.073167,
              33.017898
            ],
            [
              -93.081428,
              33.017928
            ],
            [
              -93.100981,
              33.017786
            ],
            [
              -93.101443,
              33.01774
            ],
            [
              -93.1197298546433,
              33.0177800936558
            ],
            [
              -93.154351,
              33.017856
            ],
            [
              -93.197402,
              33.017951
            ],
            [
              -93.215653,
              33.018393
            ],
            [
              -93.238607,
              33.017992
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "029",
      "COUNTYNS": "00558515",
      "AFFGEOID": "0500000US22029",
      "GEOID": "22029",
      "NAME": "Concordia",
      "LSAD": "15",
      "ALAND": 1805172274,
      "AWATER": 130329995,
      "County_state": "Concordia,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.8633,
              31.354448
            ],
            [
              -91.867422,
              31.316187
            ],
            [
              -91.862168,
              31.302314
            ],
            [
              -91.841027,
              31.29244
            ],
            [
              -91.823646,
              31.29321
            ],
            [
              -91.823671,
              31.277577
            ],
            [
              -91.83428,
              31.266344
            ],
            [
              -91.814197,
              31.250968
            ],
            [
              -91.811001,
              31.237087
            ],
            [
              -91.777566,
              31.222047
            ],
            [
              -91.75846,
              31.222245
            ],
            [
              -91.73837,
              31.204001
            ],
            [
              -91.721856,
              31.19955
            ],
            [
              -91.70815,
              31.208923
            ],
            [
              -91.690959,
              31.201906
            ],
            [
              -91.677302,
              31.188047
            ],
            [
              -91.687263,
              31.161928
            ],
            [
              -91.702705,
              31.161812
            ],
            [
              -91.709336,
              31.153846
            ],
            [
              -91.719004,
              31.134616
            ],
            [
              -91.703447,
              31.105746
            ],
            [
              -91.716257,
              31.093342
            ],
            [
              -91.710309,
              31.078327
            ],
            [
              -91.723804,
              31.044254
            ],
            [
              -91.696606,
              31.039186
            ],
            [
              -91.659808,
              31.043991
            ],
            [
              -91.649246,
              31.037183
            ],
            [
              -91.644117,
              31.022516
            ],
            [
              -91.658321,
              30.994365
            ],
            [
              -91.663142,
              30.984393
            ],
            [
              -91.662506,
              30.970362
            ],
            [
              -91.648766,
              30.983167
            ],
            [
              -91.636942,
              30.999416
            ],
            [
              -91.625118,
              31.005374
            ],
            [
              -91.60649,
              31.011216
            ],
            [
              -91.590463,
              31.01727
            ],
            [
              -91.578413,
              31.02403
            ],
            [
              -91.571695,
              31.029782
            ],
            [
              -91.564397,
              31.038965
            ],
            [
              -91.561232,
              31.046225
            ],
            [
              -91.560365,
              31.049508
            ],
            [
              -91.559907,
              31.054119
            ],
            [
              -91.561283,
              31.060906
            ],
            [
              -91.56415,
              31.06683
            ],
            [
              -91.567648,
              31.070186
            ],
            [
              -91.594693,
              31.091444
            ],
            [
              -91.609523,
              31.101557
            ],
            [
              -91.614763,
              31.104357
            ],
            [
              -91.61857,
              31.107328
            ],
            [
              -91.621535,
              31.110257
            ],
            [
              -91.625994,
              31.116896
            ],
            [
              -91.626476,
              31.119125
            ],
            [
              -91.625707,
              31.128763
            ],
            [
              -91.625118,
              31.131879
            ],
            [
              -91.624217,
              31.133729
            ],
            [
              -91.621671,
              31.13687
            ],
            [
              -91.604197,
              31.154545
            ],
            [
              -91.599732,
              31.159592
            ],
            [
              -91.597062,
              31.163492
            ],
            [
              -91.591502,
              31.173118
            ],
            [
              -91.589046,
              31.178586
            ],
            [
              -91.588695,
              31.181025
            ],
            [
              -91.588939,
              31.188959
            ],
            [
              -91.5894512930811,
              31.1911399311563
            ],
            [
              -91.590051,
              31.193693
            ],
            [
              -91.595029,
              31.201969
            ],
            [
              -91.601616,
              31.208573
            ],
            [
              -91.625119,
              31.226071
            ],
            [
              -91.644356,
              31.234414
            ],
            [
              -91.652019,
              31.242691
            ],
            [
              -91.654907,
              31.250175
            ],
            [
              -91.655009,
              31.251815
            ],
            [
              -91.654027,
              31.255753
            ],
            [
              -91.651369,
              31.259528
            ],
            [
              -91.648669,
              31.262238
            ],
            [
              -91.641253,
              31.266917
            ],
            [
              -91.637672,
              31.26768
            ],
            [
              -91.621358,
              31.267811
            ],
            [
              -91.606672,
              31.2659
            ],
            [
              -91.587749,
              31.262563
            ],
            [
              -91.574493,
              31.261289
            ],
            [
              -91.564192,
              31.261633
            ],
            [
              -91.553905,
              31.26305
            ],
            [
              -91.537734,
              31.267369
            ],
            [
              -91.518578,
              31.275283
            ],
            [
              -91.515614,
              31.27821
            ],
            [
              -91.512085,
              31.284177
            ],
            [
              -91.508858,
              31.291644
            ],
            [
              -91.508296,
              31.295829
            ],
            [
              -91.507977,
              31.312943
            ],
            [
              -91.50866,
              31.315131
            ],
            [
              -91.510049,
              31.316822
            ],
            [
              -91.518509,
              31.323332
            ],
            [
              -91.531657,
              31.331801
            ],
            [
              -91.533206,
              31.333225
            ],
            [
              -91.536061,
              31.338355
            ],
            [
              -91.545617,
              31.343762
            ],
            [
              -91.548967,
              31.347255
            ],
            [
              -91.548894,
              31.353998
            ],
            [
              -91.550869,
              31.360574
            ],
            [
              -91.551002,
              31.363645
            ],
            [
              -91.549915,
              31.376315
            ],
            [
              -91.546607,
              31.381198
            ],
            [
              -91.546207,
              31.38248
            ],
            [
              -91.552432,
              31.385658
            ],
            [
              -91.55568,
              31.386413
            ],
            [
              -91.560524,
              31.384559
            ],
            [
              -91.562555,
              31.383219
            ],
            [
              -91.568953,
              31.377629
            ],
            [
              -91.571234,
              31.384664
            ],
            [
              -91.573931,
              31.390886
            ],
            [
              -91.578334,
              31.399067
            ],
            [
              -91.578246,
              31.403859
            ],
            [
              -91.576265,
              31.410498
            ],
            [
              -91.570092,
              31.419487
            ],
            [
              -91.565179,
              31.423447
            ],
            [
              -91.554805,
              31.42857
            ],
            [
              -91.55275,
              31.430692
            ],
            [
              -91.548465,
              31.432668
            ],
            [
              -91.545013,
              31.433026
            ],
            [
              -91.541626,
              31.431706
            ],
            [
              -91.540647,
              31.430758
            ],
            [
              -91.537137,
              31.424161
            ],
            [
              -91.537002,
              31.423184
            ],
            [
              -91.539504,
              31.41791
            ],
            [
              -91.539458,
              31.414021
            ],
            [
              -91.532336,
              31.390275
            ],
            [
              -91.52695,
              31.380855
            ],
            [
              -91.521836,
              31.37517
            ],
            [
              -91.515978,
              31.370286
            ],
            [
              -91.508075,
              31.366276
            ],
            [
              -91.504163,
              31.36495
            ],
            [
              -91.47887,
              31.364955
            ],
            [
              -91.472465,
              31.371326
            ],
            [
              -91.471098,
              31.376917
            ],
            [
              -91.471992,
              31.382143
            ],
            [
              -91.472149,
              31.391434
            ],
            [
              -91.472065,
              31.395925
            ],
            [
              -91.476926,
              31.397796
            ],
            [
              -91.48023,
              31.404391
            ],
            [
              -91.49004,
              31.412716
            ],
            [
              -91.500046,
              31.42052
            ],
            [
              -91.506423,
              31.43146
            ],
            [
              -91.510356,
              31.438928
            ],
            [
              -91.513366,
              31.444396
            ],
            [
              -91.51513,
              31.449206
            ],
            [
              -91.516999,
              31.460574
            ],
            [
              -91.518148,
              31.483483
            ],
            [
              -91.51714,
              31.498394
            ],
            [
              -91.515157,
              31.50338
            ],
            [
              -91.514917,
              31.510113
            ],
            [
              -91.516759,
              31.511772
            ],
            [
              -91.520579,
              31.513207
            ],
            [
              -91.522862,
              31.517493
            ],
            [
              -91.52292,
              31.519841
            ],
            [
              -91.522536,
              31.522078
            ],
            [
              -91.521445,
              31.523912
            ],
            [
              -91.51581,
              31.530894
            ],
            [
              -91.513963,
              31.532084
            ],
            [
              -91.511217,
              31.532612
            ],
            [
              -91.506719,
              31.532966
            ],
            [
              -91.500118,
              31.532616
            ],
            [
              -91.489618,
              31.534266
            ],
            [
              -91.483918,
              31.532566
            ],
            [
              -91.481318,
              31.530666
            ],
            [
              -91.479718,
              31.530366
            ],
            [
              -91.450017,
              31.539666
            ],
            [
              -91.443916,
              31.542466
            ],
            [
              -91.437616,
              31.546166
            ],
            [
              -91.426416,
              31.554566
            ],
            [
              -91.414915,
              31.562166
            ],
            [
              -91.407915,
              31.569366
            ],
            [
              -91.4077734,
              31.5696492
            ],
            [
              -91.406615,
              31.571966
            ],
            [
              -91.405415,
              31.576466
            ],
            [
              -91.403915,
              31.589766
            ],
            [
              -91.413015,
              31.595365
            ],
            [
              -91.417115,
              31.596265
            ],
            [
              -91.422716,
              31.597065
            ],
            [
              -91.430716,
              31.596465
            ],
            [
              -91.436316,
              31.594965
            ],
            [
              -91.457517,
              31.587566
            ],
            [
              -91.466317,
              31.586066
            ],
            [
              -91.479418,
              31.585466
            ],
            [
              -91.485218,
              31.586166
            ],
            [
              -91.488618,
              31.587466
            ],
            [
              -91.494118,
              31.590165
            ],
            [
              -91.502783,
              31.595727
            ],
            [
              -91.51301,
              31.606885
            ],
            [
              -91.5148155315998,
              31.6093889885807
            ],
            [
              -91.516567,
              31.611818
            ],
            [
              -91.517233,
              31.61346
            ],
            [
              -91.517921,
              31.618642
            ],
            [
              -91.516659,
              31.627332
            ],
            [
              -91.515462,
              31.630372
            ],
            [
              -91.512336,
              31.634722
            ],
            [
              -91.50731,
              31.639068
            ],
            [
              -91.499278,
              31.644658
            ],
            [
              -91.497665,
              31.645371
            ],
            [
              -91.494478,
              31.645013
            ],
            [
              -91.492748,
              31.644279
            ],
            [
              -91.490027,
              31.641
            ],
            [
              -91.487518,
              31.637065
            ],
            [
              -91.482618,
              31.631565
            ],
            [
              -91.479818,
              31.628965
            ],
            [
              -91.474318,
              31.625365
            ],
            [
              -91.463817,
              31.620365
            ],
            [
              -91.436716,
              31.612665
            ],
            [
              -91.429616,
              31.611365
            ],
            [
              -91.421116,
              31.611565
            ],
            [
              -91.416498,
              31.613133
            ],
            [
              -91.401015,
              31.620365
            ],
            [
              -91.398315,
              31.626265
            ],
            [
              -91.396115,
              31.639265
            ],
            [
              -91.395715,
              31.644165
            ],
            [
              -91.398015,
              31.662665
            ],
            [
              -91.400115,
              31.688164
            ],
            [
              -91.400015,
              31.697864
            ],
            [
              -91.397915,
              31.709364
            ],
            [
              -91.397115,
              31.711364
            ],
            [
              -91.380915,
              31.732464
            ],
            [
              -91.3800788050052,
              31.7327598853811
            ],
            [
              -91.377992,
              31.747585
            ],
            [
              -91.39829,
              31.753212
            ],
            [
              -91.485056,
              31.751045
            ],
            [
              -91.544791,
              31.751731
            ],
            [
              -91.536193,
              31.742718
            ],
            [
              -91.537718,
              31.731805
            ],
            [
              -91.548234,
              31.722659
            ],
            [
              -91.571204,
              31.735048
            ],
            [
              -91.584021,
              31.755764
            ],
            [
              -91.601622,
              31.761864
            ],
            [
              -91.614125,
              31.745557
            ],
            [
              -91.635822,
              31.729119
            ],
            [
              -91.662466,
              31.729368
            ],
            [
              -91.678744,
              31.743101
            ],
            [
              -91.686082,
              31.742115
            ],
            [
              -91.696595,
              31.731796
            ],
            [
              -91.701676,
              31.70298
            ],
            [
              -91.714273,
              31.675992
            ],
            [
              -91.705948,
              31.642863
            ],
            [
              -91.727768,
              31.621432
            ],
            [
              -91.737189,
              31.614088
            ],
            [
              -91.756123,
              31.62267
            ],
            [
              -91.774761,
              31.648827
            ],
            [
              -91.806435,
              31.630765
            ],
            [
              -91.815553,
              31.618325
            ],
            [
              -91.820926,
              31.606371
            ],
            [
              -91.824426,
              31.599671
            ],
            [
              -91.824969,
              31.598003
            ],
            [
              -91.826426,
              31.572472
            ],
            [
              -91.813026,
              31.554872
            ],
            [
              -91.779725,
              31.530973
            ],
            [
              -91.77704,
              31.515311
            ],
            [
              -91.784124,
              31.502273
            ],
            [
              -91.802062,
              31.491104
            ],
            [
              -91.798754,
              31.473354
            ],
            [
              -91.778953,
              31.468115
            ],
            [
              -91.738032,
              31.46741
            ],
            [
              -91.727781,
              31.463586
            ],
            [
              -91.720888,
              31.449543
            ],
            [
              -91.724119,
              31.438833
            ],
            [
              -91.738331,
              31.431572
            ],
            [
              -91.766493,
              31.433135
            ],
            [
              -91.784556,
              31.441381
            ],
            [
              -91.807098,
              31.438794
            ],
            [
              -91.824156,
              31.490419
            ],
            [
              -91.833825,
              31.500874
            ],
            [
              -91.847932,
              31.501652
            ],
            [
              -91.855908,
              31.49601
            ],
            [
              -91.855678,
              31.480205
            ],
            [
              -91.86413,
              31.466167
            ],
            [
              -91.855889,
              31.423831
            ],
            [
              -91.845524,
              31.409495
            ],
            [
              -91.81703,
              31.395619
            ],
            [
              -91.785669,
              31.395021
            ],
            [
              -91.776779,
              31.385215
            ],
            [
              -91.78929,
              31.374647
            ],
            [
              -91.808047,
              31.370718
            ],
            [
              -91.831618,
              31.378987
            ],
            [
              -91.845019,
              31.375916
            ],
            [
              -91.8633,
              31.354448
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "107",
      "COUNTYNS": "00559509",
      "AFFGEOID": "0500000US22107",
      "GEOID": "22107",
      "NAME": "Tensas",
      "LSAD": "15",
      "ALAND": 1561204507,
      "AWATER": 99471076,
      "County_state": "Tensas,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.56873,
              31.826961
            ],
            [
              -91.577653,
              31.795857
            ],
            [
              -91.544791,
              31.751731
            ],
            [
              -91.485056,
              31.751045
            ],
            [
              -91.39829,
              31.753212
            ],
            [
              -91.377992,
              31.747585
            ],
            [
              -91.3800788050052,
              31.7327598853811
            ],
            [
              -91.355144,
              31.741583
            ],
            [
              -91.334666,
              31.744071
            ],
            [
              -91.318576,
              31.745315
            ],
            [
              -91.3165801943028,
              31.7453109122142
            ],
            [
              -91.291723,
              31.74526
            ],
            [
              -91.275545,
              31.745515
            ],
            [
              -91.263406,
              31.754468
            ],
            [
              -91.259611,
              31.76129
            ],
            [
              -91.263043,
              31.766995
            ],
            [
              -91.273874,
              31.771178
            ],
            [
              -91.286045,
              31.772062
            ],
            [
              -91.301315,
              31.766748
            ],
            [
              -91.31597187202169,
              31.767416482856497
            ],
            [
              -91.322232,
              31.767702
            ],
            [
              -91.348562,
              31.765837
            ],
            [
              -91.364685,
              31.770345
            ],
            [
              -91.363714,
              31.780363
            ],
            [
              -91.359514,
              31.799362
            ],
            [
              -91.345714,
              31.842861
            ],
            [
              -91.343014,
              31.846861
            ],
            [
              -91.338414,
              31.851261
            ],
            [
              -91.333814,
              31.853261
            ],
            [
              -91.326914,
              31.854961
            ],
            [
              -91.294713,
              31.86046
            ],
            [
              -91.293413,
              31.86016
            ],
            [
              -91.289312,
              31.846861
            ],
            [
              -91.290135,
              31.833658
            ],
            [
              -91.289412,
              31.828661
            ],
            [
              -91.287812,
              31.824161
            ],
            [
              -91.284912,
              31.818362
            ],
            [
              -91.282212,
              31.814762
            ],
            [
              -91.280212,
              31.813162
            ],
            [
              -91.276712,
              31.811362
            ],
            [
              -91.269212,
              31.809162
            ],
            [
              -91.262011,
              31.809362
            ],
            [
              -91.255611,
              31.812662
            ],
            [
              -91.250111,
              31.817762
            ],
            [
              -91.247367,
              31.822323
            ],
            [
              -91.245047,
              31.831447
            ],
            [
              -91.245624,
              31.833165
            ],
            [
              -91.261144,
              31.846119
            ],
            [
              -91.266612,
              31.851161
            ],
            [
              -91.268112,
              31.853061
            ],
            [
              -91.268812,
              31.855161
            ],
            [
              -91.269012,
              31.858661
            ],
            [
              -91.267712,
              31.86266
            ],
            [
              -91.266512,
              31.86426
            ],
            [
              -91.264412,
              31.86546
            ],
            [
              -91.248144,
              31.869848
            ],
            [
              -91.234899,
              31.876863
            ],
            [
              -91.226951,
              31.885105
            ],
            [
              -91.20881,
              31.900459
            ],
            [
              -91.20511,
              31.904159
            ],
            [
              -91.20281,
              31.907959
            ],
            [
              -91.20101,
              31.909159
            ],
            [
              -91.19751,
              31.908659
            ],
            [
              -91.19381,
              31.909559
            ],
            [
              -91.19261,
              31.910159
            ],
            [
              -91.19081,
              31.912959
            ],
            [
              -91.18921,
              31.914259
            ],
            [
              -91.18321,
              31.916159
            ],
            [
              -91.18061,
              31.917959
            ],
            [
              -91.18111,
              31.920059
            ],
            [
              -91.18491,
              31.923759
            ],
            [
              -91.18371,
              31.933158
            ],
            [
              -91.18471,
              31.935058
            ],
            [
              -91.19111,
              31.934158
            ],
            [
              -91.19291,
              31.934958
            ],
            [
              -91.19321,
              31.935658
            ],
            [
              -91.18951,
              31.946358
            ],
            [
              -91.18881,
              31.953158
            ],
            [
              -91.18911,
              31.957458
            ],
            [
              -91.18831,
              31.960958
            ],
            [
              -91.18481,
              31.965557
            ],
            [
              -91.17741,
              31.973257
            ],
            [
              -91.17021,
              31.979057
            ],
            [
              -91.16441,
              31.982557
            ],
            [
              -91.117409,
              31.987057
            ],
            [
              -91.104108,
              31.990357
            ],
            [
              -91.096108,
              31.994857
            ],
            [
              -91.090105,
              32.000157
            ],
            [
              -91.075908,
              32.016828
            ],
            [
              -91.079928,
              32.018316
            ],
            [
              -91.080808,
              32.023456
            ],
            [
              -91.082608,
              32.028656
            ],
            [
              -91.084408,
              32.031456
            ],
            [
              -91.088108,
              32.034455
            ],
            [
              -91.095508,
              32.037455
            ],
            [
              -91.125109,
              32.042855
            ],
            [
              -91.14511,
              32.046555
            ],
            [
              -91.15141,
              32.049255
            ],
            [
              -91.15631,
              32.052655
            ],
            [
              -91.15901,
              32.055955
            ],
            [
              -91.16131,
              32.059755
            ],
            [
              -91.16201,
              32.062355
            ],
            [
              -91.16201,
              32.065354
            ],
            [
              -91.16161,
              32.067754
            ],
            [
              -91.16031,
              32.070354
            ],
            [
              -91.15581,
              32.075554
            ],
            [
              -91.14881,
              32.080154
            ],
            [
              -91.14511,
              32.081354
            ],
            [
              -91.139309,
              32.081754
            ],
            [
              -91.134909,
              32.080354
            ],
            [
              -91.128609,
              32.076554
            ],
            [
              -91.124109,
              32.071854
            ],
            [
              -91.114309,
              32.058255
            ],
            [
              -91.103708,
              32.050255
            ],
            [
              -91.098708,
              32.048355
            ],
            [
              -91.082308,
              32.047555
            ],
            [
              -91.080208,
              32.048355
            ],
            [
              -91.079108,
              32.050255
            ],
            [
              -91.081708,
              32.075754
            ],
            [
              -91.081508,
              32.077754
            ],
            [
              -91.080008,
              32.079154
            ],
            [
              -91.066679,
              32.08533
            ],
            [
              -91.038607,
              32.098254
            ],
            [
              -91.034707,
              32.101053
            ],
            [
              -91.030507,
              32.108153
            ],
            [
              -91.0307064962793,
              32.114336885917304
            ],
            [
              -91.030907,
              32.120552
            ],
            [
              -91.0307991898912,
              32.1205658494524
            ],
            [
              -91.039007,
              32.120353
            ],
            [
              -91.045207,
              32.121553
            ],
            [
              -91.053175,
              32.124237
            ],
            [
              -91.061555,
              32.126907
            ],
            [
              -91.069081,
              32.131478
            ],
            [
              -91.077043,
              32.133493
            ],
            [
              -91.08163,
              32.133992
            ],
            [
              -91.091656,
              32.133604
            ],
            [
              -91.101181,
              32.131136
            ],
            [
              -91.103696,
              32.130007
            ],
            [
              -91.111294,
              32.125036
            ],
            [
              -91.113866,
              32.125731
            ],
            [
              -91.131403,
              32.126213
            ],
            [
              -91.136566,
              32.127371
            ],
            [
              -91.1444,
              32.13039
            ],
            [
              -91.155043,
              32.132243
            ],
            [
              -91.162822,
              32.132694
            ],
            [
              -91.165452,
              32.13429
            ],
            [
              -91.171702,
              32.14425
            ],
            [
              -91.173495,
              32.149009
            ],
            [
              -91.174552,
              32.154978
            ],
            [
              -91.173664,
              32.164231
            ],
            [
              -91.171046,
              32.176526
            ],
            [
              -91.168073,
              32.186635
            ],
            [
              -91.164171,
              32.196888
            ],
            [
              -91.162062,
              32.199035
            ],
            [
              -91.158026,
              32.201956
            ],
            [
              -91.133587,
              32.213432
            ],
            [
              -91.130197,
              32.213666
            ],
            [
              -91.128495,
              32.213169
            ],
            [
              -91.124043,
              32.211104
            ],
            [
              -91.121209,
              32.21435
            ],
            [
              -91.121583,
              32.239987
            ],
            [
              -91.13378,
              32.249339
            ],
            [
              -91.183592,
              32.245435
            ],
            [
              -91.215529,
              32.233065
            ],
            [
              -91.234815,
              32.210039
            ],
            [
              -91.272214,
              32.230908
            ],
            [
              -91.317059,
              32.207619
            ],
            [
              -91.320742,
              32.202455
            ],
            [
              -91.492718,
              32.203349
            ],
            [
              -91.492419,
              32.15645
            ],
            [
              -91.505724,
              32.145774
            ],
            [
              -91.501224,
              32.10577
            ],
            [
              -91.509439,
              32.097662
            ],
            [
              -91.489884,
              32.089441
            ],
            [
              -91.492248,
              32.069831
            ],
            [
              -91.50173,
              32.066378
            ],
            [
              -91.501705,
              32.045869
            ],
            [
              -91.510012,
              32.020539
            ],
            [
              -91.497342,
              32.010557
            ],
            [
              -91.498426,
              31.990728
            ],
            [
              -91.514782,
              31.985396
            ],
            [
              -91.512178,
              31.975355
            ],
            [
              -91.528204,
              31.959343
            ],
            [
              -91.512804,
              31.954092
            ],
            [
              -91.511374,
              31.930025
            ],
            [
              -91.525016,
              31.915519
            ],
            [
              -91.54322,
              31.913759
            ],
            [
              -91.569221,
              31.89356
            ],
            [
              -91.575721,
              31.88256
            ],
            [
              -91.56873,
              31.826961
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "049",
      "COUNTYNS": "00558592",
      "AFFGEOID": "0500000US22049",
      "GEOID": "22049",
      "NAME": "Jackson",
      "LSAD": "15",
      "ALAND": 1474195902,
      "AWATER": 28714912,
      "County_state": "Jackson,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.817149,
              32.161698
            ],
            [
              -92.822191,
              32.162594
            ],
            [
              -92.814737,
              32.146907
            ],
            [
              -92.791563,
              32.146597
            ],
            [
              -92.699591,
              32.14688
            ],
            [
              -92.645949,
              32.146896
            ],
            [
              -92.312218,
              32.146123
            ],
            [
              -92.311851,
              32.277441
            ],
            [
              -92.311829,
              32.320755
            ],
            [
              -92.322306,
              32.329583
            ],
            [
              -92.415418,
              32.407812
            ],
            [
              -92.41539,
              32.47137
            ],
            [
              -92.41535,
              32.495486
            ],
            [
              -92.585352,
              32.496212
            ],
            [
              -92.622335,
              32.496406
            ],
            [
              -92.621678,
              32.452999
            ],
            [
              -92.747251,
              32.453553
            ],
            [
              -92.777197,
              32.453455
            ],
            [
              -92.774762,
              32.317878
            ],
            [
              -92.773173,
              32.235984
            ],
            [
              -92.787498,
              32.21497
            ],
            [
              -92.800963,
              32.210725
            ],
            [
              -92.798816,
              32.181325
            ],
            [
              -92.817149,
              32.161698
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "053",
      "COUNTYNS": "00558039",
      "AFFGEOID": "0500000US22053",
      "GEOID": "22053",
      "NAME": "Jefferson Davis",
      "LSAD": "15",
      "ALAND": 1687025362,
      "AWATER": 18718206,
      "County_state": "Jefferson Davis,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.131155,
              30.423612
            ],
            [
              -93.131109,
              30.403057
            ],
            [
              -93.131094,
              30.384841
            ],
            [
              -93.131087,
              30.379985
            ],
            [
              -93.087443,
              30.37965
            ],
            [
              -93.030955,
              30.37939
            ],
            [
              -93.030685,
              30.260595
            ],
            [
              -92.996768,
              30.260733
            ],
            [
              -92.9963,
              30.156955
            ],
            [
              -92.893324,
              30.156314
            ],
            [
              -92.889115,
              30.143406
            ],
            [
              -92.906865,
              30.130812
            ],
            [
              -92.907896,
              30.112734
            ],
            [
              -92.891522,
              30.09661
            ],
            [
              -92.887222,
              30.083455
            ],
            [
              -92.996193,
              30.082343
            ],
            [
              -92.996371,
              30.038479
            ],
            [
              -92.756546,
              30.039326
            ],
            [
              -92.738604,
              30.037323
            ],
            [
              -92.723869,
              30.048652
            ],
            [
              -92.684644,
              30.059503
            ],
            [
              -92.65416,
              30.079038
            ],
            [
              -92.637841,
              30.072174
            ],
            [
              -92.625484,
              30.09233
            ],
            [
              -92.618338,
              30.10415
            ],
            [
              -92.62864,
              30.133743
            ],
            [
              -92.621773,
              30.147967
            ],
            [
              -92.607983,
              30.15858
            ],
            [
              -92.618728,
              30.175524
            ],
            [
              -92.591129,
              30.182365
            ],
            [
              -92.591055,
              30.201623
            ],
            [
              -92.583089,
              30.200968
            ],
            [
              -92.6009,
              30.205636
            ],
            [
              -92.626691,
              30.230525
            ],
            [
              -92.622484,
              30.241758
            ],
            [
              -92.627726,
              30.264845
            ],
            [
              -92.625139,
              30.301335
            ],
            [
              -92.632161,
              30.314289
            ],
            [
              -92.619505,
              30.340052
            ],
            [
              -92.629695,
              30.378926
            ],
            [
              -92.608402,
              30.385884
            ],
            [
              -92.60406,
              30.400042
            ],
            [
              -92.594586,
              30.420339
            ],
            [
              -92.600597,
              30.437483
            ],
            [
              -92.631987,
              30.481147
            ],
            [
              -92.630347,
              30.487873
            ],
            [
              -92.773976,
              30.488284
            ],
            [
              -92.775737,
              30.437617
            ],
            [
              -93.065543,
              30.438569
            ],
            [
              -93.06557,
              30.424325
            ],
            [
              -93.131155,
              30.423612
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "051",
      "COUNTYNS": "00558027",
      "AFFGEOID": "0500000US22051",
      "GEOID": "22051",
      "NAME": "Jefferson",
      "LSAD": "15",
      "ALAND": 765682137,
      "AWATER": 897184724,
      "County_state": "Jefferson,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.280296,
              29.972618
            ],
            [
              -90.279533,
              29.967971
            ],
            [
              -90.267982,
              29.904888
            ],
            [
              -90.243171,
              29.880704
            ],
            [
              -90.240173,
              29.862117
            ],
            [
              -90.173202,
              29.827524
            ],
            [
              -90.167274,
              29.81846
            ],
            [
              -90.202346,
              29.750669
            ],
            [
              -90.228157,
              29.692028
            ],
            [
              -90.195198,
              29.690505
            ],
            [
              -90.183486,
              29.680614
            ],
            [
              -90.170387,
              29.683523
            ],
            [
              -90.155807,
              29.67753
            ],
            [
              -90.15538,
              29.657428
            ],
            [
              -90.161117,
              29.64623
            ],
            [
              -90.151168,
              29.609112
            ],
            [
              -90.151725,
              29.587871
            ],
            [
              -90.164526,
              29.577672
            ],
            [
              -90.158459,
              29.570032
            ],
            [
              -90.186172,
              29.563341
            ],
            [
              -90.192176,
              29.55274
            ],
            [
              -90.174209,
              29.542892
            ],
            [
              -90.166458,
              29.526504
            ],
            [
              -90.146041,
              29.500232
            ],
            [
              -90.137504,
              29.477163
            ],
            [
              -90.114128,
              29.465684
            ],
            [
              -90.095184,
              29.461421
            ],
            [
              -90.080467,
              29.465915
            ],
            [
              -90.061424,
              29.459157
            ],
            [
              -90.049041,
              29.461523
            ],
            [
              -90.037376,
              29.447832
            ],
            [
              -90.037551,
              29.436344
            ],
            [
              -90.020622,
              29.428461
            ],
            [
              -90.033294,
              29.393278
            ],
            [
              -90.029396,
              29.377021
            ],
            [
              -90.03543,
              29.368234
            ],
            [
              -90.030189,
              29.332071
            ],
            [
              -90.003349,
              29.310392
            ],
            [
              -89.998954,
              29.298339
            ],
            [
              -90.015869,
              29.274007
            ],
            [
              -90.029251,
              29.269289
            ],
            [
              -90.056045,
              29.230382
            ],
            [
              -90.063713,
              29.207233
            ],
            [
              -90.090337,
              29.180574
            ],
            [
              -90.0896939118368,
              29.163815627628
            ],
            [
              -90.079276,
              29.16997
            ],
            [
              -90.058512,
              29.183687
            ],
            [
              -90.036530324742,
              29.203195337623097
            ],
            [
              -90.022029,
              29.216065
            ],
            [
              -89.95646,
              29.253744
            ],
            [
              -89.950756,
              29.260801
            ],
            [
              -89.94733734726009,
              29.2665114840563
            ],
            [
              -89.944221,
              29.271717
            ],
            [
              -89.90437063072201,
              29.2949262097711
            ],
            [
              -89.930686,
              29.335561
            ],
            [
              -89.946517,
              29.375223
            ],
            [
              -89.960914,
              29.430746
            ],
            [
              -89.97747,
              29.449371
            ],
            [
              -89.979611,
              29.485596
            ],
            [
              -89.97895,
              29.533643
            ],
            [
              -89.988677,
              29.548892
            ],
            [
              -89.980812,
              29.580155
            ],
            [
              -89.984533,
              29.617396
            ],
            [
              -90.002201,
              29.623421
            ],
            [
              -90.015884,
              29.674413
            ],
            [
              -90.029518,
              29.687091
            ],
            [
              -90.040634,
              29.707984
            ],
            [
              -90.067242,
              29.738664
            ],
            [
              -90.06562,
              29.753118
            ],
            [
              -90.075683,
              29.77185
            ],
            [
              -90.06742,
              29.7786
            ],
            [
              -90.060264,
              29.784946
            ],
            [
              -90.071861,
              29.807896
            ],
            [
              -90.06861,
              29.82883
            ],
            [
              -90.057939,
              29.836873
            ],
            [
              -90.041048,
              29.845209
            ],
            [
              -90.027456,
              29.85285
            ],
            [
              -90.017757,
              29.878534
            ],
            [
              -90.008056,
              29.895528
            ],
            [
              -90.014385,
              29.902957
            ],
            [
              -90.018297,
              29.906829
            ],
            [
              -90.022794,
              29.91108
            ],
            [
              -90.050472,
              29.937304
            ],
            [
              -90.05413,
              29.940912
            ],
            [
              -90.058547,
              29.945334
            ],
            [
              -90.058372,
              29.943004
            ],
            [
              -90.058073,
              29.939191
            ],
            [
              -90.059172,
              29.930404
            ],
            [
              -90.068752,
              29.921069
            ],
            [
              -90.083737,
              29.915051
            ],
            [
              -90.107155,
              29.90849
            ],
            [
              -90.122024,
              29.909635
            ],
            [
              -90.127428,
              29.910859
            ],
            [
              -90.138574,
              29.918805
            ],
            [
              -90.139274,
              29.924904
            ],
            [
              -90.138974,
              29.938404
            ],
            [
              -90.134653,
              29.942454
            ],
            [
              -90.130394,
              29.946507
            ],
            [
              -90.137602,
              29.951489
            ],
            [
              -90.136293,
              29.954155
            ],
            [
              -90.130741,
              29.963919
            ],
            [
              -90.1277,
              29.968282
            ],
            [
              -90.125755,
              29.972332
            ],
            [
              -90.124775,
              29.975791
            ],
            [
              -90.124662,
              29.979253
            ],
            [
              -90.122957,
              30.000071
            ],
            [
              -90.120985,
              30.023548
            ],
            [
              -90.118541,
              30.029845
            ],
            [
              -90.119373,
              30.036637
            ],
            [
              -90.108119,
              30.16494
            ],
            [
              -90.144658,
              30.180021
            ],
            [
              -90.156256,
              30.189613
            ],
            [
              -90.220332,
              30.1756
            ],
            [
              -90.259889,
              30.16341
            ],
            [
              -90.272604,
              30.1078
            ],
            [
              -90.27922,
              30.049118
            ],
            [
              -90.279242,
              30.048479
            ],
            [
              -90.279571,
              30.023229
            ],
            [
              -90.279495,
              30.006797
            ],
            [
              -90.280296,
              29.972618
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "065",
      "COUNTYNS": "00558088",
      "AFFGEOID": "0500000US22065",
      "GEOID": "22065",
      "NAME": "Madison",
      "LSAD": "15",
      "ALAND": 1617282478,
      "AWATER": 68539547,
      "County_state": "Madison,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.057647,
              32.177354
            ],
            [
              -91.058907,
              32.171251
            ],
            [
              -91.055707,
              32.163752
            ],
            [
              -91.049707,
              32.157352
            ],
            [
              -91.048507,
              32.150152
            ],
            [
              -91.050207,
              32.145252
            ],
            [
              -91.051207,
              32.144152
            ],
            [
              -91.053175,
              32.124237
            ],
            [
              -91.045207,
              32.121553
            ],
            [
              -91.039007,
              32.120353
            ],
            [
              -91.0307991898912,
              32.1205658494524
            ],
            [
              -91.027007,
              32.121053
            ],
            [
              -91.020006,
              32.123553
            ],
            [
              -91.017606,
              32.125153
            ],
            [
              -91.011806,
              32.131153
            ],
            [
              -91.006406,
              32.139952
            ],
            [
              -91.005006,
              32.142852
            ],
            [
              -91.004106,
              32.146152
            ],
            [
              -91.00619,
              32.156957
            ],
            [
              -91.016606,
              32.160852
            ],
            [
              -91.025007,
              32.162552
            ],
            [
              -91.031907,
              32.167851
            ],
            [
              -91.041807,
              32.174551
            ],
            [
              -91.050207,
              32.178451
            ],
            [
              -91.057647,
              32.177354
            ]
          ]
        ],
        [
          [
            [
              -91.543021,
              32.259006
            ],
            [
              -91.557199,
              32.249654
            ],
            [
              -91.525099,
              32.234326
            ],
            [
              -91.50946,
              32.244194
            ],
            [
              -91.525752,
              32.220832
            ],
            [
              -91.54656,
              32.210071
            ],
            [
              -91.535514,
              32.203744
            ],
            [
              -91.492718,
              32.203349
            ],
            [
              -91.320742,
              32.202455
            ],
            [
              -91.317059,
              32.207619
            ],
            [
              -91.272214,
              32.230908
            ],
            [
              -91.234815,
              32.210039
            ],
            [
              -91.215529,
              32.233065
            ],
            [
              -91.183592,
              32.245435
            ],
            [
              -91.13378,
              32.249339
            ],
            [
              -91.121583,
              32.239987
            ],
            [
              -91.121209,
              32.21435
            ],
            [
              -91.124043,
              32.211104
            ],
            [
              -91.11727,
              32.206668
            ],
            [
              -91.114084,
              32.206697
            ],
            [
              -91.113009,
              32.20655
            ],
            [
              -91.108509,
              32.20815
            ],
            [
              -91.100409,
              32.21785
            ],
            [
              -91.092108,
              32.22385
            ],
            [
              -91.083708,
              32.22645
            ],
            [
              -91.075108,
              32.22705
            ],
            [
              -91.071108,
              32.22605
            ],
            [
              -91.061408,
              32.21865
            ],
            [
              -91.055107,
              32.22475
            ],
            [
              -91.053107,
              32.22795
            ],
            [
              -91.051807,
              32.234449
            ],
            [
              -91.050307,
              32.237949
            ],
            [
              -91.046507,
              32.241149
            ],
            [
              -91.039007,
              32.242349
            ],
            [
              -91.034307,
              32.241549
            ],
            [
              -91.026607,
              32.238749
            ],
            [
              -91.021507,
              32.236149
            ],
            [
              -91.009606,
              32.22615
            ],
            [
              -91.006106,
              32.22405
            ],
            [
              -91.004769,
              32.22105
            ],
            [
              -91.002469,
              32.215812
            ],
            [
              -91.001192,
              32.215173
            ],
            [
              -90.999531,
              32.214662
            ],
            [
              -90.997359,
              32.213896
            ],
            [
              -90.994293,
              32.213768
            ],
            [
              -90.991227,
              32.214662
            ],
            [
              -90.988672,
              32.215812
            ],
            [
              -90.985989,
              32.217856
            ],
            [
              -90.983434,
              32.221305
            ],
            [
              -90.983263,
              32.226201
            ],
            [
              -90.983135,
              32.231371
            ],
            [
              -90.98029,
              32.243601
            ],
            [
              -90.976139,
              32.248092
            ],
            [
              -90.970016,
              32.25168
            ],
            [
              -90.969403,
              32.25252
            ],
            [
              -90.971344,
              32.257742
            ],
            [
              -90.979663,
              32.265252
            ],
            [
              -90.981028,
              32.266733
            ],
            [
              -90.982985,
              32.270294
            ],
            [
              -90.984077,
              32.279954
            ],
            [
              -90.980747,
              32.29141
            ],
            [
              -90.979475,
              32.293702
            ],
            [
              -90.976199,
              32.29645
            ],
            [
              -90.974602,
              32.297122
            ],
            [
              -90.971643,
              32.297497
            ],
            [
              -90.964149,
              32.296872
            ],
            [
              -90.963079,
              32.296285
            ],
            [
              -90.961171,
              32.293288
            ],
            [
              -90.955405,
              32.286241
            ],
            [
              -90.953008,
              32.284043
            ],
            [
              -90.951351,
              32.283199
            ],
            [
              -90.947834,
              32.283486
            ],
            [
              -90.933991,
              32.290343
            ],
            [
              -90.922231,
              32.298639
            ],
            [
              -90.916157,
              32.303582
            ],
            [
              -90.905173,
              32.315497
            ],
            [
              -90.902558,
              32.319587
            ],
            [
              -90.893653,
              32.328095
            ],
            [
              -90.8761,
              32.357754
            ],
            [
              -90.875631,
              32.372434
            ],
            [
              -90.878289,
              32.374548
            ],
            [
              -90.888947,
              32.373246
            ],
            [
              -90.89206,
              32.370579
            ],
            [
              -90.897762,
              32.35436
            ],
            [
              -90.907756,
              32.343611
            ],
            [
              -90.912363,
              32.339454
            ],
            [
              -90.92117,
              32.342073
            ],
            [
              -90.934897,
              32.344967
            ],
            [
              -90.942981,
              32.345956
            ],
            [
              -90.954583,
              32.345859
            ],
            [
              -90.986672,
              32.35176
            ],
            [
              -90.993625,
              32.354047
            ],
            [
              -91.000106,
              32.357695
            ],
            [
              -91.003506,
              32.362145
            ],
            [
              -91.004506,
              32.364744
            ],
            [
              -91.004506,
              32.368144
            ],
            [
              -91.000606,
              32.381644
            ],
            [
              -90.996237,
              32.388061
            ],
            [
              -90.994686,
              32.392277
            ],
            [
              -90.99378,
              32.396778
            ],
            [
              -90.99408,
              32.403862
            ],
            [
              -90.980723,
              32.408243
            ],
            [
              -90.974461,
              32.412001
            ],
            [
              -90.971141,
              32.414636
            ],
            [
              -90.967767,
              32.418279
            ],
            [
              -90.966255,
              32.421027
            ],
            [
              -90.965986,
              32.424806
            ],
            [
              -90.966457,
              32.433868
            ],
            [
              -90.966869,
              32.435499
            ],
            [
              -90.96856,
              32.438084
            ],
            [
              -90.96959,
              32.43949
            ],
            [
              -90.978547,
              32.447032
            ],
            [
              -90.983423,
              32.449077
            ],
            [
              -90.993863,
              32.45085
            ],
            [
              -90.998974,
              32.450165
            ],
            [
              -91.004806,
              32.445741
            ],
            [
              -91.016506,
              32.440342
            ],
            [
              -91.026306,
              32.434442
            ],
            [
              -91.029606,
              32.433542
            ],
            [
              -91.052907,
              32.438442
            ],
            [
              -91.070207,
              32.445141
            ],
            [
              -91.081807,
              32.450441
            ],
            [
              -91.095308,
              32.458741
            ],
            [
              -91.108808,
              32.47204
            ],
            [
              -91.112108,
              32.47614
            ],
            [
              -91.116008,
              32.48314
            ],
            [
              -91.117308,
              32.495039
            ],
            [
              -91.116708,
              32.500139
            ],
            [
              -91.106985,
              32.514934
            ],
            [
              -91.101304,
              32.525599
            ],
            [
              -91.083808,
              32.526873
            ],
            [
              -91.060516,
              32.512361
            ],
            [
              -91.050907,
              32.500139
            ],
            [
              -91.045807,
              32.495539
            ],
            [
              -91.038106,
              32.49044
            ],
            [
              -91.033906,
              32.48854
            ],
            [
              -91.024106,
              32.48524
            ],
            [
              -91.017106,
              32.48374
            ],
            [
              -91.004206,
              32.48214
            ],
            [
              -90.999223,
              32.482615
            ],
            [
              -90.996388,
              32.483925
            ],
            [
              -90.990703,
              32.487749
            ],
            [
              -90.988278,
              32.49119
            ],
            [
              -90.987831,
              32.49419
            ],
            [
              -90.988174,
              32.496796
            ],
            [
              -90.989826,
              32.500139
            ],
            [
              -90.994481,
              32.506331
            ],
            [
              -91.005468,
              32.513842
            ],
            [
              -91.011275,
              32.516596
            ],
            [
              -91.051168,
              32.53089
            ],
            [
              -91.061685,
              32.536448
            ],
            [
              -91.075373,
              32.546718
            ],
            [
              -91.080398,
              32.556442
            ],
            [
              -91.0697862054881,
              32.562793318519
            ],
            [
              -91.108421,
              32.562623
            ],
            [
              -91.133713,
              32.537912
            ],
            [
              -91.200126,
              32.537683
            ],
            [
              -91.306696,
              32.537861
            ],
            [
              -91.457643,
              32.537807
            ],
            [
              -91.484971,
              32.494965
            ],
            [
              -91.484501,
              32.47176
            ],
            [
              -91.471653,
              32.433785
            ],
            [
              -91.486536,
              32.434809
            ],
            [
              -91.486497,
              32.413229
            ],
            [
              -91.476739,
              32.405808
            ],
            [
              -91.475792,
              32.385148
            ],
            [
              -91.451705,
              32.368557
            ],
            [
              -91.450678,
              32.361731
            ],
            [
              -91.465952,
              32.351053
            ],
            [
              -91.480532,
              32.35524
            ],
            [
              -91.484986,
              32.366535
            ],
            [
              -91.500529,
              32.347718
            ],
            [
              -91.494336,
              32.338652
            ],
            [
              -91.478094,
              32.342917
            ],
            [
              -91.472391,
              32.316529
            ],
            [
              -91.504107,
              32.313449
            ],
            [
              -91.50331,
              32.295191
            ],
            [
              -91.526719,
              32.300675
            ],
            [
              -91.523638,
              32.253045
            ],
            [
              -91.543021,
              32.259006
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "015",
      "COUNTYNS": "00558453",
      "AFFGEOID": "0500000US22015",
      "GEOID": "22015",
      "NAME": "Bossier",
      "LSAD": "15",
      "ALAND": 2176185432,
      "AWATER": 70496339,
      "County_state": "Bossier,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.826597,
              32.966734
            ],
            [
              -93.845224,
              32.950428
            ],
            [
              -93.82357,
              32.946394
            ],
            [
              -93.801967,
              32.931806
            ],
            [
              -93.82085,
              32.901971
            ],
            [
              -93.820313,
              32.88434
            ],
            [
              -93.801147,
              32.871361
            ],
            [
              -93.802824,
              32.860263
            ],
            [
              -93.814747,
              32.851593
            ],
            [
              -93.799553,
              32.84688
            ],
            [
              -93.785181,
              32.857353
            ],
            [
              -93.789598,
              32.822026
            ],
            [
              -93.798491,
              32.80758
            ],
            [
              -93.816949,
              32.801169
            ],
            [
              -93.824253,
              32.792451
            ],
            [
              -93.816831,
              32.782333
            ],
            [
              -93.80323,
              32.797078
            ],
            [
              -93.78729,
              32.794313
            ],
            [
              -93.783233,
              32.78436
            ],
            [
              -93.791111,
              32.759273
            ],
            [
              -93.819169,
              32.736002
            ],
            [
              -93.826268,
              32.716593
            ],
            [
              -93.819091,
              32.710869
            ],
            [
              -93.802562,
              32.72616
            ],
            [
              -93.787129,
              32.724641
            ],
            [
              -93.782111,
              32.712212
            ],
            [
              -93.786773,
              32.668863
            ],
            [
              -93.774928,
              32.674142
            ],
            [
              -93.765852,
              32.652696
            ],
            [
              -93.781248,
              32.648853
            ],
            [
              -93.781818,
              32.639063
            ],
            [
              -93.761311,
              32.641891
            ],
            [
              -93.751515,
              32.620858
            ],
            [
              -93.759971,
              32.606107
            ],
            [
              -93.743545,
              32.602844
            ],
            [
              -93.769107,
              32.595669
            ],
            [
              -93.756946,
              32.587525
            ],
            [
              -93.739474,
              32.590773
            ],
            [
              -93.73661,
              32.579845
            ],
            [
              -93.754354,
              32.56507
            ],
            [
              -93.753263,
              32.556199
            ],
            [
              -93.741681,
              32.556635
            ],
            [
              -93.756206,
              32.537037
            ],
            [
              -93.767577,
              32.541471
            ],
            [
              -93.73833,
              32.512581
            ],
            [
              -93.705043,
              32.497351
            ],
            [
              -93.717578,
              32.487952
            ],
            [
              -93.697432,
              32.493927
            ],
            [
              -93.701458,
              32.481635
            ],
            [
              -93.683709,
              32.458808
            ],
            [
              -93.691636,
              32.448923
            ],
            [
              -93.674049,
              32.440676
            ],
            [
              -93.661396,
              32.427624
            ],
            [
              -93.673362,
              32.420892
            ],
            [
              -93.680024,
              32.433565
            ],
            [
              -93.691777,
              32.422794
            ],
            [
              -93.683409,
              32.417465
            ],
            [
              -93.660336,
              32.41641
            ],
            [
              -93.686675,
              32.407048
            ],
            [
              -93.685569,
              32.395498
            ],
            [
              -93.663343,
              32.387819
            ],
            [
              -93.667969,
              32.404074
            ],
            [
              -93.648101,
              32.402687
            ],
            [
              -93.635741,
              32.39593
            ],
            [
              -93.65069,
              32.378197
            ],
            [
              -93.62888,
              32.399738
            ],
            [
              -93.625616,
              32.361915
            ],
            [
              -93.608253,
              32.370246
            ],
            [
              -93.622248,
              32.35401
            ],
            [
              -93.61513,
              32.348332
            ],
            [
              -93.588454,
              32.348934
            ],
            [
              -93.582402,
              32.328516
            ],
            [
              -93.555323,
              32.331423
            ],
            [
              -93.545009,
              32.306454
            ],
            [
              -93.538862,
              32.31838
            ],
            [
              -93.528165,
              32.313212
            ],
            [
              -93.537114,
              32.304861
            ],
            [
              -93.539354,
              32.28992
            ],
            [
              -93.514407,
              32.297087
            ],
            [
              -93.524803,
              32.274003
            ],
            [
              -93.510609,
              32.276063
            ],
            [
              -93.492204,
              32.265582
            ],
            [
              -93.49022,
              32.243585
            ],
            [
              -93.480343,
              32.242073
            ],
            [
              -93.48605,
              32.257185
            ],
            [
              -93.461871,
              32.249482
            ],
            [
              -93.471249,
              32.237186
            ],
            [
              -93.42819,
              32.235897
            ],
            [
              -93.408126,
              32.250517
            ],
            [
              -93.407172,
              32.284401
            ],
            [
              -93.41746,
              32.283579
            ],
            [
              -93.426697,
              32.309213
            ],
            [
              -93.402923,
              32.368727
            ],
            [
              -93.424582,
              32.377261
            ],
            [
              -93.42338,
              32.39774
            ],
            [
              -93.412466,
              32.400865
            ],
            [
              -93.388612,
              32.396441
            ],
            [
              -93.372781,
              32.410153
            ],
            [
              -93.441212,
              32.410431
            ],
            [
              -93.441212,
              32.427527
            ],
            [
              -93.441225,
              32.497835
            ],
            [
              -93.446498,
              32.497972
            ],
            [
              -93.445317,
              32.644006
            ],
            [
              -93.444743,
              32.761166
            ],
            [
              -93.469701,
              32.7614
            ],
            [
              -93.464996,
              32.771711
            ],
            [
              -93.473797,
              32.783172
            ],
            [
              -93.459702,
              32.806231
            ],
            [
              -93.46695,
              32.809169
            ],
            [
              -93.454012,
              32.821609
            ],
            [
              -93.456555,
              32.835026
            ],
            [
              -93.467489,
              32.848078
            ],
            [
              -93.454793,
              32.850973
            ],
            [
              -93.448039,
              32.866321
            ],
            [
              -93.463841,
              32.86844
            ],
            [
              -93.476734,
              32.885694
            ],
            [
              -93.482231,
              32.901981
            ],
            [
              -93.469776,
              32.904404
            ],
            [
              -93.469572,
              32.917717
            ],
            [
              -93.48081,
              32.927231
            ],
            [
              -93.49326,
              32.921529
            ],
            [
              -93.508922,
              32.935789
            ],
            [
              -93.519562,
              32.96508
            ],
            [
              -93.530405,
              32.975397
            ],
            [
              -93.516315,
              33.002541
            ],
            [
              -93.52099399934829,
              33.01861612243
            ],
            [
              -93.524916,
              33.018637
            ],
            [
              -93.531499,
              33.018643
            ],
            [
              -93.723273,
              33.019457
            ],
            [
              -93.804916416422,
              33.0193809735934
            ],
            [
              -93.814553,
              33.019372
            ],
            [
              -93.825719,
              33.011985
            ],
            [
              -93.817689,
              32.975814
            ],
            [
              -93.822527,
              32.967191
            ],
            [
              -93.805313,
              32.973807
            ],
            [
              -93.800924,
              32.96562
            ],
            [
              -93.826597,
              32.966734
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "067",
      "COUNTYNS": "00558097",
      "AFFGEOID": "0500000US22067",
      "GEOID": "22067",
      "NAME": "Morehouse",
      "LSAD": "15",
      "ALAND": 2058870205,
      "AWATER": 28878805,
      "County_state": "Morehouse,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.085617,
              32.965193
            ],
            [
              -92.076693,
              32.933871
            ],
            [
              -92.077266,
              32.893159
            ],
            [
              -92.084296,
              32.882176
            ],
            [
              -92.078362,
              32.864929
            ],
            [
              -92.056038,
              32.83753
            ],
            [
              -92.067014,
              32.833947
            ],
            [
              -92.06599,
              32.821434
            ],
            [
              -92.052728,
              32.794734
            ],
            [
              -92.063138,
              32.762179
            ],
            [
              -92.052668,
              32.736954
            ],
            [
              -92.069639,
              32.733513
            ],
            [
              -92.065179,
              32.723003
            ],
            [
              -92.05614,
              32.718496
            ],
            [
              -92.05585,
              32.698074
            ],
            [
              -92.042829,
              32.699248
            ],
            [
              -92.049891,
              32.686078
            ],
            [
              -92.031879,
              32.700372
            ],
            [
              -92.044301,
              32.706566
            ],
            [
              -92.033241,
              32.712336
            ],
            [
              -92.015996,
              32.700659
            ],
            [
              -91.975026,
              32.711428
            ],
            [
              -91.967297,
              32.686222
            ],
            [
              -91.9363,
              32.658873
            ],
            [
              -91.911168,
              32.661139
            ],
            [
              -91.924214,
              32.645255
            ],
            [
              -91.924401,
              32.618587
            ],
            [
              -91.943419,
              32.587119
            ],
            [
              -91.925527,
              32.573725
            ],
            [
              -91.927233,
              32.569932
            ],
            [
              -91.904832,
              32.528621
            ],
            [
              -91.90416,
              32.515326
            ],
            [
              -91.913057,
              32.505902
            ],
            [
              -91.903312,
              32.509838
            ],
            [
              -91.897824,
              32.53566
            ],
            [
              -91.888816,
              32.543484
            ],
            [
              -91.866439,
              32.522483
            ],
            [
              -91.839532,
              32.518482
            ],
            [
              -91.837652,
              32.543531
            ],
            [
              -91.818415,
              32.553835
            ],
            [
              -91.816579,
              32.567203
            ],
            [
              -91.80432,
              32.571316
            ],
            [
              -91.769798,
              32.554375
            ],
            [
              -91.759742,
              32.559571
            ],
            [
              -91.74773,
              32.557484
            ],
            [
              -91.735118,
              32.571477
            ],
            [
              -91.73477,
              32.587854
            ],
            [
              -91.705991,
              32.613177
            ],
            [
              -91.671986,
              32.611187
            ],
            [
              -91.647093,
              32.639924
            ],
            [
              -91.637587,
              32.667341
            ],
            [
              -91.621993,
              32.679944
            ],
            [
              -91.624023,
              32.69567
            ],
            [
              -91.638883,
              32.702472
            ],
            [
              -91.628567,
              32.719112
            ],
            [
              -91.617719,
              32.720816
            ],
            [
              -91.597171,
              32.748897
            ],
            [
              -91.595407,
              32.766207
            ],
            [
              -91.602765,
              32.787444
            ],
            [
              -91.589398,
              32.797521
            ],
            [
              -91.590995,
              32.807949
            ],
            [
              -91.57775,
              32.83001
            ],
            [
              -91.566363,
              32.855561
            ],
            [
              -91.534728,
              32.87747
            ],
            [
              -91.511515,
              32.879007
            ],
            [
              -91.494655,
              32.890796
            ],
            [
              -91.498296,
              32.901705
            ],
            [
              -91.47576,
              32.929723
            ],
            [
              -91.479736,
              32.93542
            ],
            [
              -91.456279,
              32.965842
            ],
            [
              -91.437551,
              32.980932
            ],
            [
              -91.449623,
              32.983825
            ],
            [
              -91.435782,
              33.006099
            ],
            [
              -91.453369,
              33.005843
            ],
            [
              -91.4604000514466,
              33.0059095659352
            ],
            [
              -91.489176,
              33.006182
            ],
            [
              -91.500118,
              33.006784
            ],
            [
              -91.559494,
              33.00684
            ],
            [
              -91.572326,
              33.006908
            ],
            [
              -91.579639,
              33.006472
            ],
            [
              -91.579802,
              33.006518
            ],
            [
              -91.609001,
              33.006556
            ],
            [
              -91.617615,
              33.006717
            ],
            [
              -91.62667,
              33.006639
            ],
            [
              -91.62763897199659,
              33.006647277932
            ],
            [
              -91.735531,
              33.007569
            ],
            [
              -91.755068,
              33.00701
            ],
            [
              -91.875128,
              33.007728
            ],
            [
              -91.950001,
              33.00752
            ],
            [
              -91.951958,
              33.007428
            ],
            [
              -92.069105,
              33.008163
            ],
            [
              -92.071004,
              32.988643
            ],
            [
              -92.081664,
              32.9821
            ],
            [
              -92.076088,
              32.969609
            ],
            [
              -92.085617,
              32.965193
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "071",
      "COUNTYNS": "00558113",
      "AFFGEOID": "0500000US22071",
      "GEOID": "22071",
      "NAME": "Orleans",
      "LSAD": "15",
      "ALAND": 438813602,
      "AWATER": 467285512,
      "County_state": "Orleans,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.138974,
              29.938404
            ],
            [
              -90.139274,
              29.924904
            ],
            [
              -90.138574,
              29.918805
            ],
            [
              -90.127428,
              29.910859
            ],
            [
              -90.122024,
              29.909635
            ],
            [
              -90.107155,
              29.90849
            ],
            [
              -90.083737,
              29.915051
            ],
            [
              -90.068752,
              29.921069
            ],
            [
              -90.059172,
              29.930404
            ],
            [
              -90.058073,
              29.939191
            ],
            [
              -90.058372,
              29.943004
            ],
            [
              -90.058547,
              29.945334
            ],
            [
              -90.05413,
              29.940912
            ],
            [
              -90.050472,
              29.937304
            ],
            [
              -90.022794,
              29.91108
            ],
            [
              -90.018297,
              29.906829
            ],
            [
              -90.014385,
              29.902957
            ],
            [
              -90.008056,
              29.895528
            ],
            [
              -89.989183,
              29.889388
            ],
            [
              -89.987266,
              29.905004
            ],
            [
              -89.935066,
              29.892705
            ],
            [
              -89.917587,
              29.866661
            ],
            [
              -89.910708,
              29.867865
            ],
            [
              -89.902694,
              29.878068
            ],
            [
              -89.907784,
              29.897151
            ],
            [
              -89.926895,
              29.920617
            ],
            [
              -89.969768,
              29.924702
            ],
            [
              -90.012102,
              29.94598
            ],
            [
              -89.989882,
              29.98949
            ],
            [
              -89.974291,
              29.994692
            ],
            [
              -89.953038,
              29.981165
            ],
            [
              -89.945365,
              29.982401
            ],
            [
              -89.914168,
              29.999098
            ],
            [
              -89.894539,
              30.002325
            ],
            [
              -89.879699,
              29.985443
            ],
            [
              -89.859307,
              30.002859
            ],
            [
              -89.85611454998869,
              29.9970679396967
            ],
            [
              -89.857558,
              30.004439
            ],
            [
              -89.839933,
              30.024146
            ],
            [
              -89.818561,
              30.043328
            ],
            [
              -89.782534,
              30.045372
            ],
            [
              -89.763216,
              30.042108
            ],
            [
              -89.746505,
              30.032599
            ],
            [
              -89.733323,
              30.022054
            ],
            [
              -89.724649,
              30.022454
            ],
            [
              -89.716377,
              30.026222
            ],
            [
              -89.7163,
              30.02811
            ],
            [
              -89.731545,
              30.049691
            ],
            [
              -89.730999,
              30.057581
            ],
            [
              -89.727453,
              30.062661
            ],
            [
              -89.712942,
              30.069088
            ],
            [
              -89.698496,
              30.070491
            ],
            [
              -89.683712,
              30.076018
            ],
            [
              -89.682181,
              30.091536
            ],
            [
              -89.678163,
              30.108286
            ],
            [
              -89.674633,
              30.110406
            ],
            [
              -89.669182,
              30.110667
            ],
            [
              -89.656986,
              30.118381
            ],
            [
              -89.640989,
              30.138612
            ],
            [
              -89.62669234577159,
              30.149471530507398
            ],
            [
              -89.6228797076882,
              30.1523675549142
            ],
            [
              -89.655406,
              30.159304
            ],
            [
              -89.685287,
              30.175346
            ],
            [
              -89.714037,
              30.161214
            ],
            [
              -89.74544,
              30.17745
            ],
            [
              -89.755318,
              30.16975
            ],
            [
              -89.775915,
              30.159404
            ],
            [
              -89.797414,
              30.160757
            ],
            [
              -89.820793,
              30.180002
            ],
            [
              -89.848403,
              30.190079
            ],
            [
              -89.903234,
              30.19866
            ],
            [
              -89.974424,
              30.176177
            ],
            [
              -90.003403,
              30.162997
            ],
            [
              -90.040277,
              30.155114
            ],
            [
              -90.082527,
              30.1572
            ],
            [
              -90.108119,
              30.16494
            ],
            [
              -90.119373,
              30.036637
            ],
            [
              -90.118541,
              30.029845
            ],
            [
              -90.120985,
              30.023548
            ],
            [
              -90.122957,
              30.000071
            ],
            [
              -90.124662,
              29.979253
            ],
            [
              -90.124775,
              29.975791
            ],
            [
              -90.125755,
              29.972332
            ],
            [
              -90.1277,
              29.968282
            ],
            [
              -90.130741,
              29.963919
            ],
            [
              -90.136293,
              29.954155
            ],
            [
              -90.137602,
              29.951489
            ],
            [
              -90.130394,
              29.946507
            ],
            [
              -90.134653,
              29.942454
            ],
            [
              -90.138974,
              29.938404
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "073",
      "COUNTYNS": "00558114",
      "AFFGEOID": "0500000US22073",
      "GEOID": "22073",
      "NAME": "Ouachita",
      "LSAD": "15",
      "ALAND": 1580943094,
      "AWATER": 55283099,
      "County_state": "Ouachita,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.41539,
              32.47137
            ],
            [
              -92.415418,
              32.407812
            ],
            [
              -92.322306,
              32.329583
            ],
            [
              -92.311829,
              32.320755
            ],
            [
              -92.311851,
              32.277441
            ],
            [
              -92.164744,
              32.276697
            ],
            [
              -92.152628,
              32.258645
            ],
            [
              -92.106115,
              32.258552
            ],
            [
              -92.106385,
              32.276632
            ],
            [
              -92.034397,
              32.277068
            ],
            [
              -92.020179,
              32.29679
            ],
            [
              -92.01767,
              32.308781
            ],
            [
              -92.006192,
              32.354244
            ],
            [
              -92.011423,
              32.363106
            ],
            [
              -91.993631,
              32.397902
            ],
            [
              -91.983631,
              32.413859
            ],
            [
              -91.944278,
              32.448777
            ],
            [
              -91.933301,
              32.490932
            ],
            [
              -91.919716,
              32.497422
            ],
            [
              -91.913057,
              32.505902
            ],
            [
              -91.90416,
              32.515326
            ],
            [
              -91.904832,
              32.528621
            ],
            [
              -91.927233,
              32.569932
            ],
            [
              -91.925527,
              32.573725
            ],
            [
              -91.943419,
              32.587119
            ],
            [
              -91.924401,
              32.618587
            ],
            [
              -91.924214,
              32.645255
            ],
            [
              -91.911168,
              32.661139
            ],
            [
              -91.9363,
              32.658873
            ],
            [
              -91.967297,
              32.686222
            ],
            [
              -91.975026,
              32.711428
            ],
            [
              -92.015996,
              32.700659
            ],
            [
              -92.033241,
              32.712336
            ],
            [
              -92.044301,
              32.706566
            ],
            [
              -92.031879,
              32.700372
            ],
            [
              -92.049891,
              32.686078
            ],
            [
              -92.042829,
              32.699248
            ],
            [
              -92.05585,
              32.698074
            ],
            [
              -92.05614,
              32.718496
            ],
            [
              -92.065179,
              32.723003
            ],
            [
              -92.090171,
              32.687691
            ],
            [
              -92.120867,
              32.673589
            ],
            [
              -92.13904,
              32.675363
            ],
            [
              -92.138119,
              32.66077
            ],
            [
              -92.183694,
              32.633042
            ],
            [
              -92.221672,
              32.610341
            ],
            [
              -92.224265,
              32.590094
            ],
            [
              -92.272313,
              32.580975
            ],
            [
              -92.312874,
              32.582803
            ],
            [
              -92.415071,
              32.582845
            ],
            [
              -92.415073,
              32.575524
            ],
            [
              -92.41535,
              32.495486
            ],
            [
              -92.41539,
              32.47137
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "117",
      "COUNTYNS": "00559557",
      "AFFGEOID": "0500000US22117",
      "GEOID": "22117",
      "NAME": "Washington",
      "LSAD": "15",
      "ALAND": 1734071719,
      "AWATER": 16460917,
      "County_state": "Washington,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.34723,
              31.000359
            ],
            [
              -90.3472409612406,
              31.0003589881184
            ],
            [
              -90.34671,
              30.905427
            ],
            [
              -90.333921,
              30.90552
            ],
            [
              -90.329897,
              30.890907
            ],
            [
              -90.316871,
              30.890932
            ],
            [
              -90.317689,
              30.873639
            ],
            [
              -90.301169,
              30.850788
            ],
            [
              -90.290243,
              30.819787
            ],
            [
              -90.293345,
              30.80649
            ],
            [
              -90.279506,
              30.795005
            ],
            [
              -90.277626,
              30.779879
            ],
            [
              -90.262659,
              30.765291
            ],
            [
              -90.264536,
              30.756972
            ],
            [
              -90.253526,
              30.735549
            ],
            [
              -90.255648,
              30.711905
            ],
            [
              -90.001757,
              30.666795
            ],
            [
              -89.990776,
              30.66541
            ],
            [
              -89.84861106109449,
              30.6659742005644
            ],
            [
              -89.847201,
              30.670038
            ],
            [
              -89.844965,
              30.674691
            ],
            [
              -89.836797,
              30.690573
            ],
            [
              -89.835478,
              30.691166
            ],
            [
              -89.835848,
              30.699555
            ],
            [
              -89.838065,
              30.704036
            ],
            [
              -89.839312,
              30.704143
            ],
            [
              -89.84173,
              30.702713
            ],
            [
              -89.843605,
              30.702511
            ],
            [
              -89.845926,
              30.704157
            ],
            [
              -89.846576,
              30.706209
            ],
            [
              -89.845801,
              30.707314
            ],
            [
              -89.836257,
              30.716185
            ],
            [
              -89.831961,
              30.715384
            ],
            [
              -89.83006,
              30.71631
            ],
            [
              -89.828061,
              30.725018
            ],
            [
              -89.833065,
              30.726759
            ],
            [
              -89.836331,
              30.727197
            ],
            [
              -89.836945,
              30.728201
            ],
            [
              -89.83687,
              30.734661
            ],
            [
              -89.83580066478609,
              30.7358542079603
            ],
            [
              -89.835437,
              30.73626
            ],
            [
              -89.833818,
              30.736972
            ],
            [
              -89.826175,
              30.736594
            ],
            [
              -89.821535,
              30.736618
            ],
            [
              -89.81748,
              30.737305
            ],
            [
              -89.816499,
              30.737946
            ],
            [
              -89.816075,
              30.739366
            ],
            [
              -89.816764,
              30.740076
            ],
            [
              -89.819548,
              30.740671
            ],
            [
              -89.823492,
              30.740988
            ],
            [
              -89.826053,
              30.742322
            ],
            [
              -89.825774,
              30.747305
            ],
            [
              -89.827886,
              30.758419
            ],
            [
              -89.831537,
              30.76761
            ],
            [
              -89.824395,
              30.779629
            ],
            [
              -89.82113,
              30.788609
            ],
            [
              -89.821486,
              30.791183
            ],
            [
              -89.821078,
              30.792523
            ],
            [
              -89.819164,
              30.795229
            ],
            [
              -89.817559,
              30.796054
            ],
            [
              -89.816418,
              30.796054
            ],
            [
              -89.813946,
              30.793782
            ],
            [
              -89.813535,
              30.792035
            ],
            [
              -89.81261,
              30.789876
            ],
            [
              -89.812096,
              30.788437
            ],
            [
              -89.810657,
              30.788026
            ],
            [
              -89.806763,
              30.789069
            ],
            [
              -89.805107,
              30.790596
            ],
            [
              -89.804696,
              30.791624
            ],
            [
              -89.804901,
              30.792549
            ],
            [
              -89.806237,
              30.793371
            ],
            [
              -89.807071,
              30.793908
            ],
            [
              -89.808601,
              30.794913
            ],
            [
              -89.810863,
              30.797379
            ],
            [
              -89.811479,
              30.797996
            ],
            [
              -89.811171,
              30.798921
            ],
            [
              -89.810143,
              30.799846
            ],
            [
              -89.808176,
              30.800562
            ],
            [
              -89.804632,
              30.802511
            ],
            [
              -89.804065,
              30.803247
            ],
            [
              -89.800422,
              30.810425
            ],
            [
              -89.799673,
              30.815172
            ],
            [
              -89.800049,
              30.819078
            ],
            [
              -89.798654,
              30.820855
            ],
            [
              -89.797491,
              30.821478
            ],
            [
              -89.796634,
              30.821648
            ],
            [
              -89.791745,
              30.820387
            ],
            [
              -89.785894,
              30.815962
            ],
            [
              -89.782404,
              30.817975
            ],
            [
              -89.781168,
              30.820123
            ],
            [
              -89.783985,
              30.827385
            ],
            [
              -89.786837,
              30.830642
            ],
            [
              -89.789426,
              30.83047
            ],
            [
              -89.790432,
              30.830985
            ],
            [
              -89.790805,
              30.832131
            ],
            [
              -89.790121,
              30.837983
            ],
            [
              -89.7875,
              30.844112
            ],
            [
              -89.782649,
              30.845264
            ],
            [
              -89.7806,
              30.845508
            ],
            [
              -89.780228,
              30.846235
            ],
            [
              -89.780947,
              30.848542
            ],
            [
              -89.783791,
              30.852131
            ],
            [
              -89.784416,
              30.853744
            ],
            [
              -89.784073,
              30.85527
            ],
            [
              -89.783384,
              30.856022
            ],
            [
              -89.781643,
              30.856613
            ],
            [
              -89.778755,
              30.8558
            ],
            [
              -89.774739,
              30.853254
            ],
            [
              -89.772587,
              30.85366
            ],
            [
              -89.771722,
              30.854677
            ],
            [
              -89.767955,
              30.863858
            ],
            [
              -89.767789,
              30.865577
            ],
            [
              -89.768237,
              30.866392
            ],
            [
              -89.778005,
              30.873411
            ],
            [
              -89.779194,
              30.875185
            ],
            [
              -89.778583,
              30.878903
            ],
            [
              -89.77711,
              30.881088
            ],
            [
              -89.775458,
              30.881497
            ],
            [
              -89.770027,
              30.882254
            ],
            [
              -89.772011,
              30.89024
            ],
            [
              -89.773553,
              30.896862
            ],
            [
              -89.773099,
              30.898338
            ],
            [
              -89.771986,
              30.899127
            ],
            [
              -89.770269,
              30.89939
            ],
            [
              -89.765101,
              30.896919
            ],
            [
              -89.760701,
              30.896306
            ],
            [
              -89.758719,
              30.897319
            ],
            [
              -89.757024,
              30.898947
            ],
            [
              -89.756671,
              30.901069
            ],
            [
              -89.759803,
              30.906216
            ],
            [
              -89.761593,
              30.906591
            ],
            [
              -89.763622,
              30.907732
            ],
            [
              -89.764451,
              30.910276
            ],
            [
              -89.764202,
              30.911906
            ],
            [
              -89.7626,
              30.913736
            ],
            [
              -89.759403,
              30.915134
            ],
            [
              -89.757417,
              30.914993
            ],
            [
              -89.754086,
              30.9128
            ],
            [
              -89.750073,
              30.91293
            ],
            [
              -89.746718,
              30.915805
            ],
            [
              -89.744789,
              30.918933
            ],
            [
              -89.744448,
              30.920577
            ],
            [
              -89.745161,
              30.922416
            ],
            [
              -89.748208,
              30.923369
            ],
            [
              -89.750073,
              30.929815
            ],
            [
              -89.748851,
              30.932816
            ],
            [
              -89.748897,
              30.933888
            ],
            [
              -89.750073,
              30.935763
            ],
            [
              -89.755835,
              30.939543
            ],
            [
              -89.756554,
              30.941949
            ],
            [
              -89.756333,
              30.943498
            ],
            [
              -89.751196,
              30.951439
            ],
            [
              -89.743592,
              30.958482
            ],
            [
              -89.743134,
              30.959993
            ],
            [
              -89.735686,
              30.966573
            ],
            [
              -89.733933,
              30.966919
            ],
            [
              -89.731393,
              30.96613
            ],
            [
              -89.729327,
              30.966242
            ],
            [
              -89.728041,
              30.966518
            ],
            [
              -89.727072,
              30.967395
            ],
            [
              -89.727086,
              30.969707
            ],
            [
              -89.728382,
              30.971141
            ],
            [
              -89.736086,
              30.974446
            ],
            [
              -89.737149,
              30.976081
            ],
            [
              -89.736883,
              30.977122
            ],
            [
              -89.735912,
              30.977865
            ],
            [
              -89.732168,
              30.978088
            ],
            [
              -89.730501,
              30.979707
            ],
            [
              -89.72993,
              30.98209
            ],
            [
              -89.727698,
              30.993329
            ],
            [
              -89.728563,
              30.994396
            ],
            [
              -89.732035,
              30.994409
            ],
            [
              -89.734227,
              30.995602
            ],
            [
              -89.73554,
              30.999715
            ],
            [
              -89.73,
              30.999749
            ],
            [
              -89.728126,
              31.000956
            ],
            [
              -89.7281451296188,
              31.002299627985096
            ],
            [
              -89.728147,
              31.002431
            ],
            [
              -89.729616,
              31.003927
            ],
            [
              -89.732504,
              31.004831
            ],
            [
              -89.741821,
              31.003441
            ],
            [
              -89.745215,
              31.002252
            ],
            [
              -89.747229,
              31.000184
            ],
            [
              -89.749189,
              30.999555
            ],
            [
              -89.751481,
              30.99969
            ],
            [
              -89.752133,
              31.000183
            ],
            [
              -89.752642,
              31.001853
            ],
            [
              -89.816429,
              31.002084
            ],
            [
              -89.824617,
              31.00206
            ],
            [
              -89.835542,
              31.002059
            ],
            [
              -89.83590796037879,
              31.002059274641898
            ],
            [
              -89.856862,
              31.002075
            ],
            [
              -89.892708,
              31.001759
            ],
            [
              -89.897516,
              31.001913
            ],
            [
              -89.923119,
              31.001446
            ],
            [
              -89.927161,
              31.001437
            ],
            [
              -89.972802,
              31.001392
            ],
            [
              -89.97543,
              31.001692
            ],
            [
              -90.005332,
              31.001364
            ],
            [
              -90.022185,
              31.001302
            ],
            [
              -90.029574,
              31.00119
            ],
            [
              -90.050706,
              31.001215
            ],
            [
              -90.128406,
              31.001047
            ],
            [
              -90.131395,
              31.000924
            ],
            [
              -90.164278,
              31.001025
            ],
            [
              -90.164676,
              31.00098
            ],
            [
              -90.1943924283431,
              31.0008788863664
            ],
            [
              -90.2595549256981,
              31.0006571633248
            ],
            [
              -90.346007,
              31.000363
            ],
            [
              -90.34723,
              31.000359
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "057",
      "COUNTYNS": "00558065",
      "AFFGEOID": "0500000US22057",
      "GEOID": "22057",
      "NAME": "Lafourche",
      "LSAD": "15",
      "ALAND": 2766953348,
      "AWATER": 1037594367,
      "County_state": "Lafourche,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.376173,
              29.108668
            ],
            [
              -90.361688,
              29.103076
            ],
            [
              -90.334935,
              29.063803
            ],
            [
              -90.317585,
              29.067335
            ],
            [
              -90.303916,
              29.07538
            ],
            [
              -90.343013,
              29.128487
            ],
            [
              -90.351454,
              29.131092
            ],
            [
              -90.35977,
              29.125222
            ],
            [
              -90.376173,
              29.108668
            ]
          ]
        ],
        [
          [
            [
              -90.3997615912778,
              29.261401767989003
            ],
            [
              -90.403268,
              29.233811
            ],
            [
              -90.38110813872349,
              29.2562180456391
            ],
            [
              -90.383873,
              29.261864
            ],
            [
              -90.3997615912778,
              29.261401767989003
            ]
          ]
        ],
        [
          [
            [
              -90.947496,
              29.792306
            ],
            [
              -91.006743,
              29.714771
            ],
            [
              -90.880194,
              29.716809
            ],
            [
              -90.882094,
              29.746008
            ],
            [
              -90.840893,
              29.746508
            ],
            [
              -90.844404,
              29.755922
            ],
            [
              -90.827893,
              29.764508
            ],
            [
              -90.827799,
              29.775828
            ],
            [
              -90.810364,
              29.776199
            ],
            [
              -90.804692,
              29.749509
            ],
            [
              -90.788092,
              29.747509
            ],
            [
              -90.777603,
              29.72027
            ],
            [
              -90.766849,
              29.695477
            ],
            [
              -90.73921,
              29.675389
            ],
            [
              -90.727302,
              29.675582
            ],
            [
              -90.707832,
              29.66538
            ],
            [
              -90.701992,
              29.661068
            ],
            [
              -90.687572,
              29.645354
            ],
            [
              -90.657696,
              29.607393
            ],
            [
              -90.619309,
              29.598092
            ],
            [
              -90.602059,
              29.572856
            ],
            [
              -90.574544,
              29.558887
            ],
            [
              -90.568408,
              29.535195
            ],
            [
              -90.550791,
              29.531265
            ],
            [
              -90.506421,
              29.477508
            ],
            [
              -90.466869,
              29.45189
            ],
            [
              -90.457421,
              29.429809
            ],
            [
              -90.418556,
              29.390037
            ],
            [
              -90.403304,
              29.38423
            ],
            [
              -90.384285,
              29.365755
            ],
            [
              -90.379589,
              29.349054
            ],
            [
              -90.386473,
              29.324743
            ],
            [
              -90.376666,
              29.302883
            ],
            [
              -90.383498,
              29.281933
            ],
            [
              -90.3759237213589,
              29.283292062328098
            ],
            [
              -90.376886,
              29.288655
            ],
            [
              -90.3565797064189,
              29.3002836347893
            ],
            [
              -90.349196,
              29.304512
            ],
            [
              -90.344324,
              29.289277
            ],
            [
              -90.322101,
              29.273003
            ],
            [
              -90.304275,
              29.266161
            ],
            [
              -90.272832,
              29.199032
            ],
            [
              -90.259234,
              29.199583
            ],
            [
              -90.263513,
              29.180597
            ],
            [
              -90.28526,
              29.178003
            ],
            [
              -90.277773,
              29.142822
            ],
            [
              -90.248629,
              29.13837
            ],
            [
              -90.234405,
              29.128824
            ],
            [
              -90.233334,
              29.110655
            ],
            [
              -90.250044,
              29.108067
            ],
            [
              -90.245924,
              29.093403
            ],
            [
              -90.231984,
              29.08773
            ],
            [
              -90.223587,
              29.085075
            ],
            [
              -90.17058,
              29.115209
            ],
            [
              -90.122753,
              29.144286
            ],
            [
              -90.0896939118368,
              29.163815627628
            ],
            [
              -90.090337,
              29.180574
            ],
            [
              -90.063713,
              29.207233
            ],
            [
              -90.056045,
              29.230382
            ],
            [
              -90.029251,
              29.269289
            ],
            [
              -90.015869,
              29.274007
            ],
            [
              -89.998954,
              29.298339
            ],
            [
              -90.003349,
              29.310392
            ],
            [
              -90.030189,
              29.332071
            ],
            [
              -90.03543,
              29.368234
            ],
            [
              -90.029396,
              29.377021
            ],
            [
              -90.033294,
              29.393278
            ],
            [
              -90.020622,
              29.428461
            ],
            [
              -90.037551,
              29.436344
            ],
            [
              -90.037376,
              29.447832
            ],
            [
              -90.049041,
              29.461523
            ],
            [
              -90.061424,
              29.459157
            ],
            [
              -90.080467,
              29.465915
            ],
            [
              -90.095184,
              29.461421
            ],
            [
              -90.114128,
              29.465684
            ],
            [
              -90.137504,
              29.477163
            ],
            [
              -90.146041,
              29.500232
            ],
            [
              -90.166458,
              29.526504
            ],
            [
              -90.174209,
              29.542892
            ],
            [
              -90.192176,
              29.55274
            ],
            [
              -90.186172,
              29.563341
            ],
            [
              -90.158459,
              29.570032
            ],
            [
              -90.164526,
              29.577672
            ],
            [
              -90.151725,
              29.587871
            ],
            [
              -90.151168,
              29.609112
            ],
            [
              -90.161117,
              29.64623
            ],
            [
              -90.15538,
              29.657428
            ],
            [
              -90.155807,
              29.67753
            ],
            [
              -90.170387,
              29.683523
            ],
            [
              -90.183486,
              29.680614
            ],
            [
              -90.195198,
              29.690505
            ],
            [
              -90.228157,
              29.692028
            ],
            [
              -90.346191,
              29.693302
            ],
            [
              -90.352104,
              29.695512
            ],
            [
              -90.361541,
              29.70745
            ],
            [
              -90.360794,
              29.737054
            ],
            [
              -90.370933,
              29.746192
            ],
            [
              -90.371978,
              29.759239
            ],
            [
              -90.405227,
              29.774335
            ],
            [
              -90.429887,
              29.776387
            ],
            [
              -90.468043,
              29.803474
            ],
            [
              -90.475784,
              29.812915
            ],
            [
              -90.476306,
              29.824875
            ],
            [
              -90.479904,
              29.847377
            ],
            [
              -90.49887,
              29.86506
            ],
            [
              -90.515724,
              29.864727
            ],
            [
              -90.529601,
              29.884997
            ],
            [
              -90.536595,
              29.891492
            ],
            [
              -90.593505,
              29.892927
            ],
            [
              -90.609348,
              29.883094
            ],
            [
              -90.627098,
              29.895042
            ],
            [
              -90.651609,
              29.89154
            ],
            [
              -90.656312,
              29.889246
            ],
            [
              -90.680691,
              29.892588
            ],
            [
              -90.690195,
              29.904865
            ],
            [
              -90.717054,
              29.896558
            ],
            [
              -90.728748,
              29.910585
            ],
            [
              -90.747428,
              29.911937
            ],
            [
              -90.77729,
              29.922029
            ],
            [
              -90.785659,
              29.921592
            ],
            [
              -90.785163,
              29.907495
            ],
            [
              -90.885589,
              29.905353
            ],
            [
              -90.885442,
              29.877502
            ],
            [
              -90.909695,
              29.877104
            ],
            [
              -90.925046,
              29.830377
            ],
            [
              -90.92447,
              29.830307
            ],
            [
              -90.947496,
              29.792306
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "093",
      "COUNTYNS": "01629464",
      "AFFGEOID": "0500000US22093",
      "GEOID": "22093",
      "NAME": "St. James",
      "LSAD": "15",
      "ALAND": 625582188,
      "AWATER": 42168610,
      "County_state": "St. James,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.963693,
              30.06645
            ],
            [
              -90.959571,
              30.035594
            ],
            [
              -90.959869,
              29.977924
            ],
            [
              -90.926078,
              29.978225
            ],
            [
              -90.925849,
              29.934955
            ],
            [
              -90.886199,
              29.935644
            ],
            [
              -90.885925,
              29.92373
            ],
            [
              -90.885589,
              29.905353
            ],
            [
              -90.785163,
              29.907495
            ],
            [
              -90.785659,
              29.921592
            ],
            [
              -90.77729,
              29.922029
            ],
            [
              -90.747428,
              29.911937
            ],
            [
              -90.728748,
              29.910585
            ],
            [
              -90.717054,
              29.896558
            ],
            [
              -90.690195,
              29.904865
            ],
            [
              -90.680691,
              29.892588
            ],
            [
              -90.656312,
              29.889246
            ],
            [
              -90.665039,
              29.907415
            ],
            [
              -90.666041,
              29.951504
            ],
            [
              -90.674736,
              29.966018
            ],
            [
              -90.666819,
              29.980884
            ],
            [
              -90.675407,
              29.987245
            ],
            [
              -90.671289,
              29.992603
            ],
            [
              -90.68885,
              30.035953
            ],
            [
              -90.672818,
              30.046052
            ],
            [
              -90.661202,
              30.050779
            ],
            [
              -90.670761,
              30.093986
            ],
            [
              -90.653709,
              30.096256
            ],
            [
              -90.641998,
              30.16643
            ],
            [
              -90.707915,
              30.153488
            ],
            [
              -90.869262,
              30.121713
            ],
            [
              -90.915565,
              30.113285
            ],
            [
              -90.914657,
              30.108835
            ],
            [
              -90.9356,
              30.085699
            ],
            [
              -90.963693,
              30.06645
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "109",
      "COUNTYNS": "00559517",
      "AFFGEOID": "0500000US22109",
      "GEOID": "22109",
      "NAME": "Terrebonne",
      "LSAD": "15",
      "ALAND": 3190255110,
      "AWATER": 2202157453,
      "County_state": "Terrebonne,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.558233,
              29.094074
            ],
            [
              -90.536842,
              29.074341
            ],
            [
              -90.488117,
              29.05876
            ],
            [
              -90.442734,
              29.056053
            ],
            [
              -90.41919,
              29.064993
            ],
            [
              -90.438205,
              29.072264
            ],
            [
              -90.477422,
              29.07538
            ],
            [
              -90.523769,
              29.090958
            ],
            [
              -90.558233,
              29.094074
            ]
          ]
        ],
        [
          [
            [
              -90.750754,
              29.055644
            ],
            [
              -90.765014,
              29.045255
            ],
            [
              -90.748377,
              29.04006
            ],
            [
              -90.683015,
              29.050449
            ],
            [
              -90.652116,
              29.057721
            ],
            [
              -90.661624,
              29.072264
            ],
            [
              -90.698464,
              29.070186
            ],
            [
              -90.726986,
              29.062915
            ],
            [
              -90.750754,
              29.055644
            ]
          ]
        ],
        [
          [
            [
              -91.33275,
              29.305816
            ],
            [
              -91.334885,
              29.298775
            ],
            [
              -91.302677,
              29.265958
            ],
            [
              -91.278792,
              29.247776
            ],
            [
              -91.219032,
              29.226051
            ],
            [
              -91.199647,
              29.221287
            ],
            [
              -91.129141,
              29.215863
            ],
            [
              -91.11476,
              29.207918
            ],
            [
              -91.094015,
              29.187711
            ],
            [
              -91.05863,
              29.181734
            ],
            [
              -91.031786,
              29.182188
            ],
            [
              -91.023955,
              29.174784
            ],
            [
              -91.000096,
              29.169481
            ],
            [
              -90.981458,
              29.171211
            ],
            [
              -90.961278,
              29.180817
            ],
            [
              -90.948091,
              29.174104
            ],
            [
              -90.941877,
              29.162373
            ],
            [
              -90.925797,
              29.153116
            ],
            [
              -90.898215,
              29.131342
            ],
            [
              -90.885351,
              29.117016
            ],
            [
              -90.877583,
              29.104891
            ],
            [
              -90.85997,
              29.094961
            ],
            [
              -90.851528,
              29.074295
            ],
            [
              -90.85932165369,
              29.065591410387597
            ],
            [
              -90.867853,
              29.056064
            ],
            [
              -90.840587,
              29.046269
            ],
            [
              -90.81255,
              29.042138
            ],
            [
              -90.80830573213801,
              29.0564557493146
            ],
            [
              -90.805933,
              29.06446
            ],
            [
              -90.798411,
              29.095999
            ],
            [
              -90.787953,
              29.107629
            ],
            [
              -90.772504,
              29.103164
            ],
            [
              -90.766681,
              29.113547
            ],
            [
              -90.744814,
              29.117597
            ],
            [
              -90.735782,
              29.134622
            ],
            [
              -90.7330620487186,
              29.134913276834002
            ],
            [
              -90.723185,
              29.135971
            ],
            [
              -90.71142,
              29.116351
            ],
            [
              -90.694307,
              29.125071
            ],
            [
              -90.681354,
              29.156003
            ],
            [
              -90.685038,
              29.165551
            ],
            [
              -90.675887,
              29.17406
            ],
            [
              -90.646771,
              29.168664
            ],
            [
              -90.643206,
              29.192736
            ],
            [
              -90.63251,
              29.214416
            ],
            [
              -90.611832,
              29.214831
            ],
            [
              -90.606128,
              29.224373
            ],
            [
              -90.612441,
              29.232589
            ],
            [
              -90.605283,
              29.238272
            ],
            [
              -90.574873,
              29.227416
            ],
            [
              -90.561801,
              29.234951
            ],
            [
              -90.589728,
              29.253305
            ],
            [
              -90.587945,
              29.271344
            ],
            [
              -90.600067,
              29.288551
            ],
            [
              -90.597571,
              29.303268
            ],
            [
              -90.579627,
              29.311662
            ],
            [
              -90.566198,
              29.30389
            ],
            [
              -90.554314,
              29.285235
            ],
            [
              -90.527253,
              29.304051
            ],
            [
              -90.513314,
              29.302025
            ],
            [
              -90.496386,
              29.295532
            ],
            [
              -90.479088,
              29.292179
            ],
            [
              -90.469462,
              29.29819
            ],
            [
              -90.451636,
              29.321506
            ],
            [
              -90.458172,
              29.335804
            ],
            [
              -90.447358,
              29.347614
            ],
            [
              -90.432265,
              29.346889
            ],
            [
              -90.419668,
              29.3242
            ],
            [
              -90.402555,
              29.312181
            ],
            [
              -90.393999,
              29.299019
            ],
            [
              -90.397207,
              29.281503
            ],
            [
              -90.3997615912778,
              29.261401767989003
            ],
            [
              -90.383873,
              29.261864
            ],
            [
              -90.38110813872349,
              29.2562180456391
            ],
            [
              -90.372608,
              29.264813
            ],
            [
              -90.3759237213589,
              29.283292062328098
            ],
            [
              -90.383498,
              29.281933
            ],
            [
              -90.376666,
              29.302883
            ],
            [
              -90.386473,
              29.324743
            ],
            [
              -90.379589,
              29.349054
            ],
            [
              -90.384285,
              29.365755
            ],
            [
              -90.403304,
              29.38423
            ],
            [
              -90.418556,
              29.390037
            ],
            [
              -90.457421,
              29.429809
            ],
            [
              -90.466869,
              29.45189
            ],
            [
              -90.506421,
              29.477508
            ],
            [
              -90.550791,
              29.531265
            ],
            [
              -90.568408,
              29.535195
            ],
            [
              -90.574544,
              29.558887
            ],
            [
              -90.602059,
              29.572856
            ],
            [
              -90.619309,
              29.598092
            ],
            [
              -90.657696,
              29.607393
            ],
            [
              -90.687572,
              29.645354
            ],
            [
              -90.701992,
              29.661068
            ],
            [
              -90.707832,
              29.66538
            ],
            [
              -90.727302,
              29.675582
            ],
            [
              -90.73921,
              29.675389
            ],
            [
              -90.766849,
              29.695477
            ],
            [
              -90.777603,
              29.72027
            ],
            [
              -90.788092,
              29.747509
            ],
            [
              -90.804692,
              29.749509
            ],
            [
              -90.810364,
              29.776199
            ],
            [
              -90.827799,
              29.775828
            ],
            [
              -90.827893,
              29.764508
            ],
            [
              -90.844404,
              29.755922
            ],
            [
              -90.840893,
              29.746508
            ],
            [
              -90.882094,
              29.746008
            ],
            [
              -90.880194,
              29.716809
            ],
            [
              -91.006743,
              29.714771
            ],
            [
              -91.082602,
              29.626368
            ],
            [
              -91.117166,
              29.617523
            ],
            [
              -91.122904,
              29.607722
            ],
            [
              -91.13945,
              29.608911
            ],
            [
              -91.163698,
              29.59272
            ],
            [
              -91.178048,
              29.609703
            ],
            [
              -91.211043,
              29.590738
            ],
            [
              -91.225201,
              29.576299
            ],
            [
              -91.222958,
              29.563162
            ],
            [
              -91.239708,
              29.548434
            ],
            [
              -91.264268,
              29.543397
            ],
            [
              -91.270618,
              29.536735
            ],
            [
              -91.265644,
              29.514412
            ],
            [
              -91.273662,
              29.479433
            ],
            [
              -91.265596,
              29.472669
            ],
            [
              -91.258332,
              29.444319
            ],
            [
              -91.2382713753225,
              29.4378709279237
            ],
            [
              -91.236858,
              29.437861
            ],
            [
              -91.217448,
              29.432549
            ],
            [
              -91.211999,
              29.420931
            ],
            [
              -91.2145868873278,
              29.4157963179733
            ],
            [
              -91.220186,
              29.404687
            ],
            [
              -91.217827,
              29.396437
            ],
            [
              -91.200087,
              29.38955
            ],
            [
              -91.19284,
              29.371126
            ],
            [
              -91.149701,
              29.353518
            ],
            [
              -91.150533,
              29.344921
            ],
            [
              -91.133064,
              29.341088
            ],
            [
              -91.123676,
              29.319952
            ],
            [
              -91.120229,
              29.30047
            ],
            [
              -91.132113,
              29.285753
            ],
            [
              -91.128084,
              29.273518
            ],
            [
              -91.119397,
              29.266472
            ],
            [
              -91.121655,
              29.251439
            ],
            [
              -91.136391,
              29.250194
            ],
            [
              -91.154455,
              29.254964
            ],
            [
              -91.1724,
              29.266576
            ],
            [
              -91.168003,
              29.288241
            ],
            [
              -91.169429,
              29.30389
            ],
            [
              -91.162061,
              29.318191
            ],
            [
              -91.171687,
              29.325133
            ],
            [
              -91.195811,
              29.305548
            ],
            [
              -91.222194,
              29.325962
            ],
            [
              -91.238237,
              29.350618
            ],
            [
              -91.236987,
              29.371342
            ],
            [
              -91.265452,
              29.360976
            ],
            [
              -91.274308,
              29.344878
            ],
            [
              -91.276647,
              29.329825
            ],
            [
              -91.291821,
              29.311357
            ],
            [
              -91.309314,
              29.305698
            ],
            [
              -91.33275,
              29.305816
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "063",
      "COUNTYNS": "00558083",
      "AFFGEOID": "0500000US22063",
      "GEOID": "22063",
      "NAME": "Livingston",
      "LSAD": "15",
      "ALAND": 1678750672,
      "AWATER": 112119167,
      "County_state": "Livingston,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.976516,
              30.467267
            ],
            [
              -90.991529,
              30.461818
            ],
            [
              -90.973444,
              30.437701
            ],
            [
              -90.982351,
              30.423465
            ],
            [
              -90.965794,
              30.414713
            ],
            [
              -90.972346,
              30.397519
            ],
            [
              -90.949197,
              30.394445
            ],
            [
              -90.93723,
              30.366565
            ],
            [
              -90.906185,
              30.361223
            ],
            [
              -90.897515,
              30.347981
            ],
            [
              -90.895577,
              30.347851
            ],
            [
              -90.891728,
              30.345244
            ],
            [
              -90.863455,
              30.340581
            ],
            [
              -90.858509,
              30.343765
            ],
            [
              -90.85097,
              30.330641
            ],
            [
              -90.830752,
              30.318625
            ],
            [
              -90.824045,
              30.292692
            ],
            [
              -90.81091,
              30.275701
            ],
            [
              -90.784695,
              30.280266
            ],
            [
              -90.790909,
              30.266809
            ],
            [
              -90.774577,
              30.249808
            ],
            [
              -90.767804,
              30.239749
            ],
            [
              -90.749109,
              30.241281
            ],
            [
              -90.731407,
              30.221153
            ],
            [
              -90.734413,
              30.211878
            ],
            [
              -90.722632,
              30.206514
            ],
            [
              -90.719539,
              30.189994
            ],
            [
              -90.70327,
              30.185806
            ],
            [
              -90.694146,
              30.175067
            ],
            [
              -90.673287,
              30.198013
            ],
            [
              -90.678968,
              30.20669
            ],
            [
              -90.66169,
              30.213722
            ],
            [
              -90.660422,
              30.22553
            ],
            [
              -90.649294,
              30.232296
            ],
            [
              -90.632811,
              30.221409
            ],
            [
              -90.5995,
              30.216836
            ],
            [
              -90.592021,
              30.208324
            ],
            [
              -90.577815,
              30.197168
            ],
            [
              -90.554147,
              30.195628
            ],
            [
              -90.509076,
              30.230135
            ],
            [
              -90.485826,
              30.276992
            ],
            [
              -90.48537,
              30.321693
            ],
            [
              -90.47376,
              30.345246
            ],
            [
              -90.488347,
              30.355418
            ],
            [
              -90.519949,
              30.414219
            ],
            [
              -90.54596,
              30.430119
            ],
            [
              -90.552854,
              30.462921
            ],
            [
              -90.550044,
              30.467662
            ],
            [
              -90.558229,
              30.478919
            ],
            [
              -90.566906,
              30.48706
            ],
            [
              -90.5669,
              30.52613
            ],
            [
              -90.567138,
              30.544199
            ],
            [
              -90.566901,
              30.577052
            ],
            [
              -90.567192,
              30.643089
            ],
            [
              -90.567165,
              30.650023
            ],
            [
              -90.634603,
              30.649827
            ],
            [
              -90.852662,
              30.649564
            ],
            [
              -90.910701,
              30.649385
            ],
            [
              -90.940582,
              30.61989
            ],
            [
              -90.934106,
              30.613147
            ],
            [
              -90.972247,
              30.596887
            ],
            [
              -90.985601,
              30.584686
            ],
            [
              -90.971302,
              30.562687
            ],
            [
              -90.972952,
              30.540928
            ],
            [
              -90.980931,
              30.535555
            ],
            [
              -90.982197,
              30.522114
            ],
            [
              -90.96789,
              30.504381
            ],
            [
              -90.976516,
              30.467267
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "005",
      "COUNTYNS": "00558403",
      "AFFGEOID": "0500000US22005",
      "GEOID": "22005",
      "NAME": "Ascension",
      "LSAD": "15",
      "ALAND": 751030090,
      "AWATER": 33194065,
      "County_state": "Ascension,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.106477,
              30.108099
            ],
            [
              -91.105951,
              30.062478
            ],
            [
              -91.089103,
              30.062514
            ],
            [
              -91.089329,
              30.076593
            ],
            [
              -91.074581,
              30.081631
            ],
            [
              -91.056106,
              30.070328
            ],
            [
              -91.005748,
              30.079106
            ],
            [
              -90.984318,
              30.064987
            ],
            [
              -90.963693,
              30.06645
            ],
            [
              -90.9356,
              30.085699
            ],
            [
              -90.914657,
              30.108835
            ],
            [
              -90.915565,
              30.113285
            ],
            [
              -90.869262,
              30.121713
            ],
            [
              -90.707915,
              30.153488
            ],
            [
              -90.641998,
              30.16643
            ],
            [
              -90.632811,
              30.221409
            ],
            [
              -90.649294,
              30.232296
            ],
            [
              -90.660422,
              30.22553
            ],
            [
              -90.66169,
              30.213722
            ],
            [
              -90.678968,
              30.20669
            ],
            [
              -90.673287,
              30.198013
            ],
            [
              -90.694146,
              30.175067
            ],
            [
              -90.70327,
              30.185806
            ],
            [
              -90.719539,
              30.189994
            ],
            [
              -90.722632,
              30.206514
            ],
            [
              -90.734413,
              30.211878
            ],
            [
              -90.731407,
              30.221153
            ],
            [
              -90.749109,
              30.241281
            ],
            [
              -90.767804,
              30.239749
            ],
            [
              -90.774577,
              30.249808
            ],
            [
              -90.790909,
              30.266809
            ],
            [
              -90.784695,
              30.280266
            ],
            [
              -90.81091,
              30.275701
            ],
            [
              -90.824045,
              30.292692
            ],
            [
              -90.830752,
              30.318625
            ],
            [
              -90.85097,
              30.330641
            ],
            [
              -90.858509,
              30.343765
            ],
            [
              -90.863455,
              30.340581
            ],
            [
              -90.891728,
              30.345244
            ],
            [
              -90.911121,
              30.339578
            ],
            [
              -90.923852,
              30.34413
            ],
            [
              -90.987569,
              30.34543
            ],
            [
              -90.992883,
              30.342749
            ],
            [
              -91.014157,
              30.334779
            ],
            [
              -91.017168,
              30.326419
            ],
            [
              -91.021014,
              30.321489
            ],
            [
              -91.01923,
              30.318473
            ],
            [
              -91.044718,
              30.251857
            ],
            [
              -91.059273,
              30.213113
            ],
            [
              -91.073432,
              30.160735
            ],
            [
              -91.090097,
              30.14111
            ],
            [
              -91.08969,
              30.108355
            ],
            [
              -91.106477,
              30.108099
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "079",
      "COUNTYNS": "00559460",
      "AFFGEOID": "0500000US22079",
      "GEOID": "22079",
      "NAME": "Rapides",
      "LSAD": "15",
      "ALAND": 3413632347,
      "AWATER": 113043381,
      "County_state": "Rapides,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.981464,
              31.346644
            ],
            [
              -92.981393,
              31.339416
            ],
            [
              -92.964586,
              31.339521
            ],
            [
              -92.964481,
              31.332251
            ],
            [
              -92.930287,
              31.332242
            ],
            [
              -92.930253,
              31.324506
            ],
            [
              -92.896651,
              31.324581
            ],
            [
              -92.879756,
              31.317373
            ],
            [
              -92.879542,
              31.288617
            ],
            [
              -92.862553,
              31.288679
            ],
            [
              -92.862552,
              31.27428
            ],
            [
              -92.846352,
              31.27418
            ],
            [
              -92.846252,
              31.253281
            ],
            [
              -92.829651,
              31.252281
            ],
            [
              -92.827664,
              31.011171
            ],
            [
              -92.823932,
              30.89
            ],
            [
              -92.720971,
              30.891893
            ],
            [
              -92.599047,
              30.898992
            ],
            [
              -92.597741,
              30.896101
            ],
            [
              -92.561046,
              30.898007
            ],
            [
              -92.547965,
              30.903084
            ],
            [
              -92.509137,
              30.895814
            ],
            [
              -92.498602,
              30.900109
            ],
            [
              -92.480124,
              30.918449
            ],
            [
              -92.48033,
              30.952271
            ],
            [
              -92.459669,
              30.955754
            ],
            [
              -92.43887,
              30.969792
            ],
            [
              -92.418651,
              30.974968
            ],
            [
              -92.409377,
              30.992288
            ],
            [
              -92.38021,
              31.000374
            ],
            [
              -92.341763,
              30.981503
            ],
            [
              -92.330778,
              30.963323
            ],
            [
              -92.280738,
              30.965071
            ],
            [
              -92.208299,
              30.96243
            ],
            [
              -92.216372,
              30.99123
            ],
            [
              -92.227124,
              31.074759
            ],
            [
              -92.232265,
              31.116243
            ],
            [
              -92.234258,
              31.131466
            ],
            [
              -92.224987,
              31.141627
            ],
            [
              -92.233764,
              31.151495
            ],
            [
              -92.23323,
              31.321545
            ],
            [
              -92.221837,
              31.331667
            ],
            [
              -92.211065,
              31.327402
            ],
            [
              -92.193856,
              31.333904
            ],
            [
              -92.178268,
              31.323295
            ],
            [
              -92.176738,
              31.311206
            ],
            [
              -92.162698,
              31.303482
            ],
            [
              -92.126396,
              31.305986
            ],
            [
              -92.095835,
              31.324279
            ],
            [
              -92.086718,
              31.335374
            ],
            [
              -92.07239,
              31.343174
            ],
            [
              -92.083288,
              31.364943
            ],
            [
              -92.102652,
              31.377277
            ],
            [
              -92.109296,
              31.372761
            ],
            [
              -92.148761,
              31.404911
            ],
            [
              -92.161874,
              31.434554
            ],
            [
              -92.154481,
              31.447432
            ],
            [
              -92.167492,
              31.455591
            ],
            [
              -92.160698,
              31.4672
            ],
            [
              -92.196131,
              31.477877
            ],
            [
              -92.242262,
              31.468783
            ],
            [
              -92.303433,
              31.458677
            ],
            [
              -92.447482,
              31.429222
            ],
            [
              -92.450301,
              31.428567
            ],
            [
              -92.631944,
              31.390478
            ],
            [
              -92.658646,
              31.388078
            ],
            [
              -92.66966,
              31.4041
            ],
            [
              -92.679147,
              31.396143
            ],
            [
              -92.694512,
              31.400966
            ],
            [
              -92.677606,
              31.414738
            ],
            [
              -92.694422,
              31.428162
            ],
            [
              -92.680155,
              31.444085
            ],
            [
              -92.706233,
              31.450683
            ],
            [
              -92.706948,
              31.470473
            ],
            [
              -92.693147,
              31.471274
            ],
            [
              -92.698725,
              31.491881
            ],
            [
              -92.684169,
              31.492777
            ],
            [
              -92.687996,
              31.500947
            ],
            [
              -92.70195,
              31.49438
            ],
            [
              -92.718983,
              31.517594
            ],
            [
              -92.761551,
              31.486473
            ],
            [
              -92.761651,
              31.476473
            ],
            [
              -92.779032,
              31.476958
            ],
            [
              -92.795951,
              31.455772
            ],
            [
              -92.813551,
              31.455271
            ],
            [
              -92.813156,
              31.441278
            ],
            [
              -92.846832,
              31.433767
            ],
            [
              -92.846957,
              31.418875
            ],
            [
              -92.871547,
              31.418874
            ],
            [
              -92.871553,
              31.403976
            ],
            [
              -92.888571,
              31.403775
            ],
            [
              -92.888386,
              31.389491
            ],
            [
              -92.913491,
              31.389174
            ],
            [
              -92.913019,
              31.368236
            ],
            [
              -92.94734,
              31.367797
            ],
            [
              -92.947095,
              31.346751
            ],
            [
              -92.981464,
              31.346644
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "025",
      "COUNTYNS": "00558495",
      "AFFGEOID": "0500000US22025",
      "GEOID": "22025",
      "NAME": "Catahoula",
      "LSAD": "15",
      "ALAND": 1833782911,
      "AWATER": 81308667,
      "County_state": "Catahoula,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.016531,
              31.302445
            ],
            [
              -92.019825,
              31.28046
            ],
            [
              -92.009849,
              31.282507
            ],
            [
              -92.003192,
              31.258911
            ],
            [
              -91.988022,
              31.248897
            ],
            [
              -92.004242,
              31.243457
            ],
            [
              -91.992195,
              31.239005
            ],
            [
              -91.992041,
              31.226153
            ],
            [
              -91.973862,
              31.225333
            ],
            [
              -91.962675,
              31.242321
            ],
            [
              -91.946569,
              31.239667
            ],
            [
              -91.940962,
              31.221518
            ],
            [
              -91.920454,
              31.231672
            ],
            [
              -91.935391,
              31.238433
            ],
            [
              -91.946522,
              31.271981
            ],
            [
              -91.934305,
              31.289855
            ],
            [
              -91.908924,
              31.296789
            ],
            [
              -91.869676,
              31.280713
            ],
            [
              -91.854853,
              31.264282
            ],
            [
              -91.83428,
              31.266344
            ],
            [
              -91.823671,
              31.277577
            ],
            [
              -91.823646,
              31.29321
            ],
            [
              -91.841027,
              31.29244
            ],
            [
              -91.862168,
              31.302314
            ],
            [
              -91.867422,
              31.316187
            ],
            [
              -91.8633,
              31.354448
            ],
            [
              -91.845019,
              31.375916
            ],
            [
              -91.831618,
              31.378987
            ],
            [
              -91.808047,
              31.370718
            ],
            [
              -91.78929,
              31.374647
            ],
            [
              -91.776779,
              31.385215
            ],
            [
              -91.785669,
              31.395021
            ],
            [
              -91.81703,
              31.395619
            ],
            [
              -91.845524,
              31.409495
            ],
            [
              -91.855889,
              31.423831
            ],
            [
              -91.86413,
              31.466167
            ],
            [
              -91.855678,
              31.480205
            ],
            [
              -91.855908,
              31.49601
            ],
            [
              -91.847932,
              31.501652
            ],
            [
              -91.833825,
              31.500874
            ],
            [
              -91.824156,
              31.490419
            ],
            [
              -91.807098,
              31.438794
            ],
            [
              -91.784556,
              31.441381
            ],
            [
              -91.766493,
              31.433135
            ],
            [
              -91.738331,
              31.431572
            ],
            [
              -91.724119,
              31.438833
            ],
            [
              -91.720888,
              31.449543
            ],
            [
              -91.727781,
              31.463586
            ],
            [
              -91.738032,
              31.46741
            ],
            [
              -91.778953,
              31.468115
            ],
            [
              -91.798754,
              31.473354
            ],
            [
              -91.802062,
              31.491104
            ],
            [
              -91.784124,
              31.502273
            ],
            [
              -91.77704,
              31.515311
            ],
            [
              -91.779725,
              31.530973
            ],
            [
              -91.813026,
              31.554872
            ],
            [
              -91.826426,
              31.572472
            ],
            [
              -91.824969,
              31.598003
            ],
            [
              -91.824426,
              31.599671
            ],
            [
              -91.820926,
              31.606371
            ],
            [
              -91.815553,
              31.618325
            ],
            [
              -91.806435,
              31.630765
            ],
            [
              -91.774761,
              31.648827
            ],
            [
              -91.756123,
              31.62267
            ],
            [
              -91.737189,
              31.614088
            ],
            [
              -91.727768,
              31.621432
            ],
            [
              -91.705948,
              31.642863
            ],
            [
              -91.714273,
              31.675992
            ],
            [
              -91.701676,
              31.70298
            ],
            [
              -91.696595,
              31.731796
            ],
            [
              -91.686082,
              31.742115
            ],
            [
              -91.678744,
              31.743101
            ],
            [
              -91.662466,
              31.729368
            ],
            [
              -91.635822,
              31.729119
            ],
            [
              -91.614125,
              31.745557
            ],
            [
              -91.601622,
              31.761864
            ],
            [
              -91.584021,
              31.755764
            ],
            [
              -91.571204,
              31.735048
            ],
            [
              -91.548234,
              31.722659
            ],
            [
              -91.537718,
              31.731805
            ],
            [
              -91.536193,
              31.742718
            ],
            [
              -91.544791,
              31.751731
            ],
            [
              -91.577653,
              31.795857
            ],
            [
              -91.56873,
              31.826961
            ],
            [
              -91.575721,
              31.88256
            ],
            [
              -91.597122,
              31.88266
            ],
            [
              -91.596922,
              31.969758
            ],
            [
              -91.6475,
              31.970428
            ],
            [
              -91.655022,
              31.950049
            ],
            [
              -91.670325,
              31.925313
            ],
            [
              -91.682169,
              31.923516
            ],
            [
              -91.69659,
              31.9035
            ],
            [
              -91.722221,
              31.893081
            ],
            [
              -91.729158,
              31.88251
            ],
            [
              -91.756667,
              31.884686
            ],
            [
              -91.779829,
              31.871896
            ],
            [
              -91.778655,
              31.887686
            ],
            [
              -91.804869,
              31.893193
            ],
            [
              -91.794975,
              31.902869
            ],
            [
              -91.804797,
              31.918466
            ],
            [
              -91.823547,
              31.917816
            ],
            [
              -91.850289,
              31.944862
            ],
            [
              -91.844509,
              31.953257
            ],
            [
              -91.889687,
              31.971474
            ],
            [
              -91.90471,
              31.971533
            ],
            [
              -91.904381,
              31.927561
            ],
            [
              -92.005434,
              31.927296
            ],
            [
              -92.008154,
              31.796315
            ],
            [
              -92.0085,
              31.642788
            ],
            [
              -92.008386,
              31.615433
            ],
            [
              -92.008121,
              31.325797
            ],
            [
              -92.001702,
              31.317652
            ],
            [
              -92.007155,
              31.306639
            ],
            [
              -91.996456,
              31.29965
            ],
            [
              -92.010887,
              31.292424
            ],
            [
              -92.016531,
              31.302445
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "069",
      "COUNTYNS": "00558108",
      "AFFGEOID": "0500000US22069",
      "GEOID": "22069",
      "NAME": "Natchitoches",
      "LSAD": "15",
      "ALAND": 3243341073,
      "AWATER": 121033890,
      "County_state": "Natchitoches,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.441283,
              31.761647
            ],
            [
              -93.441743,
              31.714485
            ],
            [
              -93.338972,
              31.714259
            ],
            [
              -93.337456,
              31.53811
            ],
            [
              -93.236662,
              31.538339
            ],
            [
              -93.236104,
              31.364507
            ],
            [
              -93.032309,
              31.360479
            ],
            [
              -92.992013,
              31.36043
            ],
            [
              -92.981464,
              31.346644
            ],
            [
              -92.947095,
              31.346751
            ],
            [
              -92.94734,
              31.367797
            ],
            [
              -92.913019,
              31.368236
            ],
            [
              -92.913491,
              31.389174
            ],
            [
              -92.888386,
              31.389491
            ],
            [
              -92.888571,
              31.403775
            ],
            [
              -92.871553,
              31.403976
            ],
            [
              -92.871547,
              31.418874
            ],
            [
              -92.846957,
              31.418875
            ],
            [
              -92.846832,
              31.433767
            ],
            [
              -92.813156,
              31.441278
            ],
            [
              -92.813551,
              31.455271
            ],
            [
              -92.795951,
              31.455772
            ],
            [
              -92.779032,
              31.476958
            ],
            [
              -92.761651,
              31.476473
            ],
            [
              -92.761551,
              31.486473
            ],
            [
              -92.718983,
              31.517594
            ],
            [
              -92.716708,
              31.522001
            ],
            [
              -92.76131,
              31.547388
            ],
            [
              -92.757052,
              31.558838
            ],
            [
              -92.787369,
              31.578661
            ],
            [
              -92.801416,
              31.597012
            ],
            [
              -92.840947,
              31.601233
            ],
            [
              -92.871434,
              31.617664
            ],
            [
              -92.906321,
              31.623628
            ],
            [
              -92.902801,
              31.65951
            ],
            [
              -92.924674,
              31.665941
            ],
            [
              -92.929432,
              31.677866
            ],
            [
              -92.963782,
              31.679894
            ],
            [
              -92.959858,
              31.701294
            ],
            [
              -92.973529,
              31.708922
            ],
            [
              -92.973818,
              31.709138
            ],
            [
              -92.976,
              31.71298
            ],
            [
              -92.968603,
              31.731006
            ],
            [
              -92.9668,
              31.781299
            ],
            [
              -92.952552,
              31.783688
            ],
            [
              -92.94198,
              31.814973
            ],
            [
              -92.948597,
              31.818894
            ],
            [
              -92.938425,
              31.82941
            ],
            [
              -92.950876,
              31.849589
            ],
            [
              -92.945953,
              31.857138
            ],
            [
              -92.908368,
              31.850751
            ],
            [
              -92.8982,
              31.859415
            ],
            [
              -92.906222,
              31.869395
            ],
            [
              -92.901088,
              31.893909
            ],
            [
              -92.892582,
              31.901446
            ],
            [
              -92.89464,
              31.917159
            ],
            [
              -92.884867,
              31.939233
            ],
            [
              -92.872174,
              31.945856
            ],
            [
              -92.885034,
              31.956563
            ],
            [
              -92.883495,
              31.981142
            ],
            [
              -92.901043,
              31.987973
            ],
            [
              -92.887804,
              32.008629
            ],
            [
              -92.888185,
              32.031202
            ],
            [
              -92.898457,
              32.044894
            ],
            [
              -92.895838,
              32.055007
            ],
            [
              -92.911756,
              32.077104
            ],
            [
              -92.904734,
              32.081435
            ],
            [
              -92.912763,
              32.10342
            ],
            [
              -92.931221,
              32.122484
            ],
            [
              -92.93908,
              32.147942
            ],
            [
              -93.187162,
              32.148412
            ],
            [
              -93.152395,
              32.104512
            ],
            [
              -93.164306,
              32.093317
            ],
            [
              -93.137321,
              32.056336
            ],
            [
              -93.156492,
              32.024751
            ],
            [
              -93.158098,
              32.009407
            ],
            [
              -93.1361,
              31.983242
            ],
            [
              -93.121188,
              31.97261
            ],
            [
              -93.23847,
              31.972781
            ],
            [
              -93.239499,
              31.903605
            ],
            [
              -93.245472,
              31.910891
            ],
            [
              -93.282375,
              31.887442
            ],
            [
              -93.303806,
              31.89762
            ],
            [
              -93.315091,
              31.889968
            ],
            [
              -93.350481,
              31.898736
            ],
            [
              -93.355452,
              31.932222
            ],
            [
              -93.377907,
              31.932712
            ],
            [
              -93.398813,
              31.91791
            ],
            [
              -93.420165,
              31.933964
            ],
            [
              -93.44057,
              31.91373
            ],
            [
              -93.441171,
              31.845233
            ],
            [
              -93.440761,
              31.78713
            ],
            [
              -93.441283,
              31.761647
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "041",
      "COUNTYNS": "00558557",
      "AFFGEOID": "0500000US22041",
      "GEOID": "22041",
      "NAME": "Franklin",
      "LSAD": "15",
      "ALAND": 1617667694,
      "AWATER": 28107882,
      "County_state": "Franklin,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.933229,
              32.066882
            ],
            [
              -91.922108,
              32.066297
            ],
            [
              -91.909014,
              32.04621
            ],
            [
              -91.898496,
              32.040193
            ],
            [
              -91.902815,
              32.025444
            ],
            [
              -91.894379,
              32.010551
            ],
            [
              -91.902051,
              31.995805
            ],
            [
              -91.889687,
              31.971474
            ],
            [
              -91.844509,
              31.953257
            ],
            [
              -91.850289,
              31.944862
            ],
            [
              -91.823547,
              31.917816
            ],
            [
              -91.804797,
              31.918466
            ],
            [
              -91.794975,
              31.902869
            ],
            [
              -91.804869,
              31.893193
            ],
            [
              -91.778655,
              31.887686
            ],
            [
              -91.779829,
              31.871896
            ],
            [
              -91.756667,
              31.884686
            ],
            [
              -91.729158,
              31.88251
            ],
            [
              -91.722221,
              31.893081
            ],
            [
              -91.69659,
              31.9035
            ],
            [
              -91.682169,
              31.923516
            ],
            [
              -91.670325,
              31.925313
            ],
            [
              -91.655022,
              31.950049
            ],
            [
              -91.6475,
              31.970428
            ],
            [
              -91.596922,
              31.969758
            ],
            [
              -91.597122,
              31.88266
            ],
            [
              -91.575721,
              31.88256
            ],
            [
              -91.569221,
              31.89356
            ],
            [
              -91.54322,
              31.913759
            ],
            [
              -91.525016,
              31.915519
            ],
            [
              -91.511374,
              31.930025
            ],
            [
              -91.512804,
              31.954092
            ],
            [
              -91.528204,
              31.959343
            ],
            [
              -91.512178,
              31.975355
            ],
            [
              -91.514782,
              31.985396
            ],
            [
              -91.498426,
              31.990728
            ],
            [
              -91.497342,
              32.010557
            ],
            [
              -91.510012,
              32.020539
            ],
            [
              -91.501705,
              32.045869
            ],
            [
              -91.50173,
              32.066378
            ],
            [
              -91.492248,
              32.069831
            ],
            [
              -91.489884,
              32.089441
            ],
            [
              -91.509439,
              32.097662
            ],
            [
              -91.501224,
              32.10577
            ],
            [
              -91.505724,
              32.145774
            ],
            [
              -91.492419,
              32.15645
            ],
            [
              -91.492718,
              32.203349
            ],
            [
              -91.535514,
              32.203744
            ],
            [
              -91.54656,
              32.210071
            ],
            [
              -91.525752,
              32.220832
            ],
            [
              -91.50946,
              32.244194
            ],
            [
              -91.525099,
              32.234326
            ],
            [
              -91.557199,
              32.249654
            ],
            [
              -91.543021,
              32.259006
            ],
            [
              -91.523638,
              32.253045
            ],
            [
              -91.526719,
              32.300675
            ],
            [
              -91.50331,
              32.295191
            ],
            [
              -91.504107,
              32.313449
            ],
            [
              -91.472391,
              32.316529
            ],
            [
              -91.478094,
              32.342917
            ],
            [
              -91.494336,
              32.338652
            ],
            [
              -91.500529,
              32.347718
            ],
            [
              -91.484986,
              32.366535
            ],
            [
              -91.480532,
              32.35524
            ],
            [
              -91.465952,
              32.351053
            ],
            [
              -91.450678,
              32.361731
            ],
            [
              -91.451705,
              32.368557
            ],
            [
              -91.475792,
              32.385148
            ],
            [
              -91.476739,
              32.405808
            ],
            [
              -91.634883,
              32.405253
            ],
            [
              -91.658298,
              32.38617
            ],
            [
              -91.680253,
              32.383544
            ],
            [
              -91.680386,
              32.373144
            ],
            [
              -91.698824,
              32.369594
            ],
            [
              -91.701538,
              32.344879
            ],
            [
              -91.723188,
              32.333621
            ],
            [
              -91.7613,
              32.334989
            ],
            [
              -91.76185,
              32.298504
            ],
            [
              -91.774117,
              32.277351
            ],
            [
              -91.769165,
              32.257188
            ],
            [
              -91.775019,
              32.243333
            ],
            [
              -91.797048,
              32.225125
            ],
            [
              -91.819768,
              32.213672
            ],
            [
              -91.830503,
              32.181013
            ],
            [
              -91.837285,
              32.176158
            ],
            [
              -91.872734,
              32.171824
            ],
            [
              -91.894729,
              32.15155
            ],
            [
              -91.894385,
              32.137987
            ],
            [
              -91.883078,
              32.132347
            ],
            [
              -91.869477,
              32.115354
            ],
            [
              -91.874466,
              32.104064
            ],
            [
              -91.868202,
              32.091555
            ],
            [
              -91.898017,
              32.085565
            ],
            [
              -91.903527,
              32.075809
            ],
            [
              -91.911081,
              32.08504
            ],
            [
              -91.933229,
              32.066882
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "097",
      "COUNTYNS": "01629479",
      "AFFGEOID": "0500000US22097",
      "GEOID": "22097",
      "NAME": "St. Landry",
      "LSAD": "15",
      "ALAND": 2392904508,
      "AWATER": 39047412,
      "County_state": "St. Landry,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.464347,
              30.495318
            ],
            [
              -92.493259,
              30.480499
            ],
            [
              -92.413567,
              30.480061
            ],
            [
              -92.264419,
              30.480194
            ],
            [
              -92.244795,
              30.480193
            ],
            [
              -92.24425,
              30.436678
            ],
            [
              -92.176444,
              30.436852
            ],
            [
              -92.1765,
              30.422691
            ],
            [
              -92.176059,
              30.400471
            ],
            [
              -92.175775,
              30.349572
            ],
            [
              -92.158554,
              30.342445
            ],
            [
              -92.15846,
              30.320806
            ],
            [
              -92.141509,
              30.320764
            ],
            [
              -92.142184,
              30.298817
            ],
            [
              -92.089446,
              30.330166
            ],
            [
              -92.083321,
              30.360599
            ],
            [
              -92.06132,
              30.373198
            ],
            [
              -92.04943,
              30.381307
            ],
            [
              -92.042619,
              30.376496
            ],
            [
              -92.03175,
              30.36121
            ],
            [
              -92.015775,
              30.371395
            ],
            [
              -91.988718,
              30.368598
            ],
            [
              -91.981472,
              30.381692
            ],
            [
              -91.978184,
              30.407403
            ],
            [
              -91.965698,
              30.410367
            ],
            [
              -91.937905,
              30.395035
            ],
            [
              -91.936912,
              30.394742
            ],
            [
              -91.935323,
              30.395725
            ],
            [
              -91.932662,
              30.396847
            ],
            [
              -91.930692,
              30.396552
            ],
            [
              -91.928619,
              30.397349
            ],
            [
              -91.925481,
              30.398267
            ],
            [
              -91.869273,
              30.417235
            ],
            [
              -91.81271,
              30.39426
            ],
            [
              -91.764861,
              30.394424
            ],
            [
              -91.678896,
              30.394731
            ],
            [
              -91.6943,
              30.401748
            ],
            [
              -91.72264,
              30.452326
            ],
            [
              -91.736545,
              30.451489
            ],
            [
              -91.756193,
              30.497738
            ],
            [
              -91.732517,
              30.510096
            ],
            [
              -91.75698,
              30.562551
            ],
            [
              -91.751357,
              30.603698
            ],
            [
              -91.740996,
              30.632538
            ],
            [
              -91.74643,
              30.658782
            ],
            [
              -91.734813,
              30.666697
            ],
            [
              -91.733955,
              30.703406
            ],
            [
              -91.757942,
              30.74099
            ],
            [
              -91.756279,
              30.760135
            ],
            [
              -91.768759,
              30.785389
            ],
            [
              -91.807295,
              30.811069
            ],
            [
              -91.798459,
              30.828991
            ],
            [
              -91.815258,
              30.848107
            ],
            [
              -92.028871,
              30.849497
            ],
            [
              -92.124878,
              30.849942
            ],
            [
              -92.212711,
              30.848607
            ],
            [
              -92.211343,
              30.793112
            ],
            [
              -92.206915,
              30.78475
            ],
            [
              -92.172415,
              30.767935
            ],
            [
              -92.172408,
              30.673767
            ],
            [
              -92.193956,
              30.659333
            ],
            [
              -92.211683,
              30.613182
            ],
            [
              -92.211504,
              30.56813
            ],
            [
              -92.262305,
              30.568152
            ],
            [
              -92.262719,
              30.538738
            ],
            [
              -92.359761,
              30.538745
            ],
            [
              -92.415311,
              30.538623
            ],
            [
              -92.449111,
              30.534338
            ],
            [
              -92.458914,
              30.52793
            ],
            [
              -92.464347,
              30.495318
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "095",
      "COUNTYNS": "01629472",
      "AFFGEOID": "0500000US22095",
      "GEOID": "22095",
      "NAME": "St. John the Baptist",
      "LSAD": "15",
      "ALAND": 555050557,
      "AWATER": 506474565,
      "County_state": "St. John the Baptist,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.672818,
              30.046052
            ],
            [
              -90.68885,
              30.035953
            ],
            [
              -90.671289,
              29.992603
            ],
            [
              -90.675407,
              29.987245
            ],
            [
              -90.666819,
              29.980884
            ],
            [
              -90.674736,
              29.966018
            ],
            [
              -90.666041,
              29.951504
            ],
            [
              -90.665039,
              29.907415
            ],
            [
              -90.656312,
              29.889246
            ],
            [
              -90.651609,
              29.89154
            ],
            [
              -90.627098,
              29.895042
            ],
            [
              -90.609348,
              29.883094
            ],
            [
              -90.593505,
              29.892927
            ],
            [
              -90.536595,
              29.891492
            ],
            [
              -90.529601,
              29.884997
            ],
            [
              -90.532203,
              29.967938
            ],
            [
              -90.54934,
              29.968052
            ],
            [
              -90.549766,
              30.002431
            ],
            [
              -90.538391,
              30.013401
            ],
            [
              -90.475767,
              30.035905
            ],
            [
              -90.467788,
              30.031709
            ],
            [
              -90.450218,
              30.033106
            ],
            [
              -90.407006,
              30.083109
            ],
            [
              -90.349714,
              30.120251
            ],
            [
              -90.326637,
              30.150188
            ],
            [
              -90.259889,
              30.16341
            ],
            [
              -90.220332,
              30.1756
            ],
            [
              -90.156256,
              30.189613
            ],
            [
              -90.154034,
              30.20348
            ],
            [
              -90.160636,
              30.234337
            ],
            [
              -90.243402,
              30.269971
            ],
            [
              -90.300923,
              30.29468
            ],
            [
              -90.320464,
              30.298985
            ],
            [
              -90.350308,
              30.296831
            ],
            [
              -90.372264,
              30.283638
            ],
            [
              -90.39928,
              30.285981
            ],
            [
              -90.420533,
              30.279837
            ],
            [
              -90.485826,
              30.276992
            ],
            [
              -90.509076,
              30.230135
            ],
            [
              -90.554147,
              30.195628
            ],
            [
              -90.577815,
              30.197168
            ],
            [
              -90.592021,
              30.208324
            ],
            [
              -90.5995,
              30.216836
            ],
            [
              -90.632811,
              30.221409
            ],
            [
              -90.641998,
              30.16643
            ],
            [
              -90.653709,
              30.096256
            ],
            [
              -90.670761,
              30.093986
            ],
            [
              -90.661202,
              30.050779
            ],
            [
              -90.672818,
              30.046052
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "121",
      "COUNTYNS": "00559570",
      "AFFGEOID": "0500000US22121",
      "GEOID": "22121",
      "NAME": "West Baton Rouge",
      "LSAD": "15",
      "ALAND": 498293891,
      "AWATER": 29602238,
      "County_state": "West Baton Rouge,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.481794,
              30.51083
            ],
            [
              -91.485089,
              30.497138
            ],
            [
              -91.460166,
              30.470376
            ],
            [
              -91.433597,
              30.453309
            ],
            [
              -91.394818,
              30.408805
            ],
            [
              -91.394856,
              30.395462
            ],
            [
              -91.362651,
              30.382827
            ],
            [
              -91.336975,
              30.352495
            ],
            [
              -91.325479,
              30.346806
            ],
            [
              -91.319539,
              30.343886
            ],
            [
              -91.311285,
              30.322472
            ],
            [
              -91.252698,
              30.322939
            ],
            [
              -91.142105,
              30.323293
            ],
            [
              -91.150801,
              30.337424
            ],
            [
              -91.174419,
              30.344187
            ],
            [
              -91.235808,
              30.344493
            ],
            [
              -91.241508,
              30.357592
            ],
            [
              -91.233908,
              30.375292
            ],
            [
              -91.208107,
              30.403691
            ],
            [
              -91.199056,
              30.422279
            ],
            [
              -91.195907,
              30.43959
            ],
            [
              -91.196114,
              30.452699
            ],
            [
              -91.196207,
              30.45779
            ],
            [
              -91.197205,
              30.506864
            ],
            [
              -91.200808,
              30.517689
            ],
            [
              -91.209308,
              30.522989
            ],
            [
              -91.236101,
              30.513973
            ],
            [
              -91.262734,
              30.505238
            ],
            [
              -91.281478,
              30.506115
            ],
            [
              -91.284359,
              30.515339
            ],
            [
              -91.246058,
              30.535851
            ],
            [
              -91.24309,
              30.55314
            ],
            [
              -91.267121,
              30.572522
            ],
            [
              -91.305854,
              30.573027
            ],
            [
              -91.314406,
              30.578817
            ],
            [
              -91.315282,
              30.594288
            ],
            [
              -91.301863,
              30.609497
            ],
            [
              -91.292821,
              30.631539
            ],
            [
              -91.294211,
              30.641591
            ],
            [
              -91.297658,
              30.649548
            ],
            [
              -91.310061,
              30.653961
            ],
            [
              -91.322256,
              30.656025
            ],
            [
              -91.33087,
              30.658677
            ],
            [
              -91.309475,
              30.650045
            ],
            [
              -91.319924,
              30.632869
            ],
            [
              -91.365088,
              30.58805
            ],
            [
              -91.364958,
              30.568992
            ],
            [
              -91.397555,
              30.568889
            ],
            [
              -91.414926,
              30.540233
            ],
            [
              -91.41476,
              30.511516
            ],
            [
              -91.481794,
              30.51083
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "003",
      "COUNTYNS": "00558397",
      "AFFGEOID": "0500000US22003",
      "GEOID": "22003",
      "NAME": "Allen",
      "LSAD": "15",
      "ALAND": 1973083800,
      "AWATER": 10634064,
      "County_state": "Allen,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.130817,
              30.593313
            ],
            [
              -93.131155,
              30.423612
            ],
            [
              -93.06557,
              30.424325
            ],
            [
              -93.065543,
              30.438569
            ],
            [
              -92.775737,
              30.437617
            ],
            [
              -92.773976,
              30.488284
            ],
            [
              -92.630347,
              30.487873
            ],
            [
              -92.614494,
              30.507443
            ],
            [
              -92.62329,
              30.517982
            ],
            [
              -92.605186,
              30.52945
            ],
            [
              -92.582874,
              30.567343
            ],
            [
              -92.597402,
              30.583928
            ],
            [
              -92.597741,
              30.896101
            ],
            [
              -92.599047,
              30.898992
            ],
            [
              -92.720971,
              30.891893
            ],
            [
              -92.823932,
              30.89
            ],
            [
              -92.823552,
              30.875594
            ],
            [
              -92.978453,
              30.878175
            ],
            [
              -92.977925,
              30.837191
            ],
            [
              -92.97917,
              30.59834
            ],
            [
              -93.130294,
              30.597886
            ],
            [
              -93.130817,
              30.593313
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "047",
      "COUNTYNS": "00558582",
      "AFFGEOID": "0500000US22047",
      "GEOID": "22047",
      "NAME": "Iberville",
      "LSAD": "15",
      "ALAND": 1602243609,
      "AWATER": 88655983,
      "County_state": "Iberville,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.700933,
              30.497433
            ],
            [
              -91.685283,
              30.476059
            ],
            [
              -91.674547,
              30.474359
            ],
            [
              -91.640099,
              30.442728
            ],
            [
              -91.639568,
              30.429032
            ],
            [
              -91.628087,
              30.413915
            ],
            [
              -91.624655,
              30.394369
            ],
            [
              -91.629032,
              30.377933
            ],
            [
              -91.647934,
              30.365327
            ],
            [
              -91.625483,
              30.354209
            ],
            [
              -91.627544,
              30.330102
            ],
            [
              -91.621055,
              30.309938
            ],
            [
              -91.587732,
              30.273282
            ],
            [
              -91.588039,
              30.257113
            ],
            [
              -91.56276,
              30.241949
            ],
            [
              -91.508268,
              30.240297
            ],
            [
              -91.475277,
              30.229231
            ],
            [
              -91.489081,
              30.199277
            ],
            [
              -91.471797,
              30.186266
            ],
            [
              -91.468183,
              30.148957
            ],
            [
              -91.461313,
              30.131246
            ],
            [
              -91.46995,
              30.109072
            ],
            [
              -91.463833,
              30.102493
            ],
            [
              -91.38895,
              30.102294
            ],
            [
              -91.378025,
              30.094851
            ],
            [
              -91.368835,
              30.058824
            ],
            [
              -91.269085,
              30.059308
            ],
            [
              -91.255942,
              30.039776
            ],
            [
              -91.238689,
              30.03052
            ],
            [
              -91.223552,
              30.040789
            ],
            [
              -91.224356,
              30.025336
            ],
            [
              -91.105951,
              30.062478
            ],
            [
              -91.106477,
              30.108099
            ],
            [
              -91.08969,
              30.108355
            ],
            [
              -91.090097,
              30.14111
            ],
            [
              -91.073432,
              30.160735
            ],
            [
              -91.059273,
              30.213113
            ],
            [
              -91.044718,
              30.251857
            ],
            [
              -91.01923,
              30.318473
            ],
            [
              -91.021014,
              30.321489
            ],
            [
              -91.028645,
              30.315816
            ],
            [
              -91.098798,
              30.319537
            ],
            [
              -91.137906,
              30.317194
            ],
            [
              -91.142105,
              30.323293
            ],
            [
              -91.252698,
              30.322939
            ],
            [
              -91.311285,
              30.322472
            ],
            [
              -91.319539,
              30.343886
            ],
            [
              -91.325479,
              30.346806
            ],
            [
              -91.336975,
              30.352495
            ],
            [
              -91.362651,
              30.382827
            ],
            [
              -91.394856,
              30.395462
            ],
            [
              -91.394818,
              30.408805
            ],
            [
              -91.433597,
              30.453309
            ],
            [
              -91.460166,
              30.470376
            ],
            [
              -91.485089,
              30.497138
            ],
            [
              -91.52622,
              30.497066
            ],
            [
              -91.556373,
              30.482822
            ],
            [
              -91.583656,
              30.48259
            ],
            [
              -91.58337,
              30.497005
            ],
            [
              -91.700933,
              30.497433
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "011",
      "COUNTYNS": "00558436",
      "AFFGEOID": "0500000US22011",
      "GEOID": "22011",
      "NAME": "Beauregard",
      "LSAD": "15",
      "ALAND": 2997502172,
      "AWATER": 21999001,
      "County_state": "Beauregard,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.740253,
              30.539569
            ],
            [
              -93.732793,
              30.52996
            ],
            [
              -93.727721,
              30.525671
            ],
            [
              -93.714322,
              30.518562
            ],
            [
              -93.710117,
              30.5064
            ],
            [
              -93.716678,
              30.494006
            ],
            [
              -93.705845,
              30.457748
            ],
            [
              -93.697828,
              30.443838
            ],
            [
              -93.6978,
              30.440583
            ],
            [
              -93.702665,
              30.429947
            ],
            [
              -93.722314,
              30.420729
            ],
            [
              -93.7402445414121,
              30.4022625442349
            ],
            [
              -93.486116,
              30.4025
            ],
            [
              -93.486179,
              30.421357
            ],
            [
              -93.486334,
              30.475455
            ],
            [
              -93.486384,
              30.490523
            ],
            [
              -93.384673,
              30.490109
            ],
            [
              -93.384422,
              30.460728
            ],
            [
              -93.38515,
              30.40263
            ],
            [
              -93.131109,
              30.403057
            ],
            [
              -93.131155,
              30.423612
            ],
            [
              -93.130817,
              30.593313
            ],
            [
              -93.130294,
              30.597886
            ],
            [
              -92.97917,
              30.59834
            ],
            [
              -92.977925,
              30.837191
            ],
            [
              -92.978453,
              30.878175
            ],
            [
              -93.133715,
              30.878121
            ],
            [
              -93.155348,
              30.882727
            ],
            [
              -93.204954,
              30.883164
            ],
            [
              -93.276031,
              30.883068
            ],
            [
              -93.280164,
              30.882841
            ],
            [
              -93.386798,
              30.88307
            ],
            [
              -93.435652,
              30.881816
            ],
            [
              -93.458531,
              30.866441
            ],
            [
              -93.478425,
              30.859362
            ],
            [
              -93.500344,
              30.865026
            ],
            [
              -93.522812,
              30.879563
            ],
            [
              -93.554597,
              30.877461
            ],
            [
              -93.55860790084809,
              30.868821820173896
            ],
            [
              -93.558172,
              30.839974
            ],
            [
              -93.553626,
              30.83514
            ],
            [
              -93.554057,
              30.824941
            ],
            [
              -93.561666,
              30.807739
            ],
            [
              -93.563243,
              30.806218
            ],
            [
              -93.569303,
              30.802969
            ],
            [
              -93.578395,
              30.802047
            ],
            [
              -93.584265,
              30.796663
            ],
            [
              -93.589381,
              30.786681
            ],
            [
              -93.589896,
              30.77776
            ],
            [
              -93.592828,
              30.763986
            ],
            [
              -93.607757,
              30.757657
            ],
            [
              -93.619129,
              30.742002
            ],
            [
              -93.617688,
              30.738479
            ],
            [
              -93.609719,
              30.729182
            ],
            [
              -93.609544,
              30.723139
            ],
            [
              -93.611192,
              30.718053
            ],
            [
              -93.616184,
              30.71398
            ],
            [
              -93.620774,
              30.704122
            ],
            [
              -93.621093,
              30.695159
            ],
            [
              -93.629904,
              30.67994
            ],
            [
              -93.638213,
              30.673058
            ],
            [
              -93.654971,
              30.670184
            ],
            [
              -93.670354,
              30.658034
            ],
            [
              -93.6831,
              30.640763
            ],
            [
              -93.685121,
              30.625201
            ],
            [
              -93.683397,
              30.608041
            ],
            [
              -93.680813,
              30.602993
            ],
            [
              -93.679828,
              30.599758
            ],
            [
              -93.681235,
              30.596102
            ],
            [
              -93.684329,
              30.592586
            ],
            [
              -93.687282,
              30.591601
            ],
            [
              -93.689534,
              30.592759
            ],
            [
              -93.692869,
              30.594382
            ],
            [
              -93.712454,
              30.588479
            ],
            [
              -93.727844,
              30.57407
            ],
            [
              -93.727746,
              30.566487
            ],
            [
              -93.725847,
              30.556978
            ],
            [
              -93.729195,
              30.544842
            ],
            [
              -93.740253,
              30.539569
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "009",
      "COUNTYNS": "00558424",
      "AFFGEOID": "0500000US22009",
      "GEOID": "22009",
      "NAME": "Avoyelles",
      "LSAD": "15",
      "ALAND": 2155855604,
      "AWATER": 86365500,
      "County_state": "Avoyelles,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.280738,
              30.965071
            ],
            [
              -92.280715,
              30.935954
            ],
            [
              -92.238219,
              30.892208
            ],
            [
              -92.237698,
              30.84821
            ],
            [
              -92.212711,
              30.848607
            ],
            [
              -92.124878,
              30.849942
            ],
            [
              -92.028871,
              30.849497
            ],
            [
              -91.815258,
              30.848107
            ],
            [
              -91.823072,
              30.856354
            ],
            [
              -91.804324,
              30.863713
            ],
            [
              -91.80018,
              30.924621
            ],
            [
              -91.805963,
              30.972558
            ],
            [
              -91.796521,
              30.982842
            ],
            [
              -91.780473,
              30.986694
            ],
            [
              -91.774174,
              31.002637
            ],
            [
              -91.750119,
              31.018814
            ],
            [
              -91.747552,
              31.023223
            ],
            [
              -91.723804,
              31.044254
            ],
            [
              -91.710309,
              31.078327
            ],
            [
              -91.716257,
              31.093342
            ],
            [
              -91.703447,
              31.105746
            ],
            [
              -91.719004,
              31.134616
            ],
            [
              -91.709336,
              31.153846
            ],
            [
              -91.702705,
              31.161812
            ],
            [
              -91.687263,
              31.161928
            ],
            [
              -91.677302,
              31.188047
            ],
            [
              -91.690959,
              31.201906
            ],
            [
              -91.70815,
              31.208923
            ],
            [
              -91.721856,
              31.19955
            ],
            [
              -91.73837,
              31.204001
            ],
            [
              -91.75846,
              31.222245
            ],
            [
              -91.777566,
              31.222047
            ],
            [
              -91.811001,
              31.237087
            ],
            [
              -91.814197,
              31.250968
            ],
            [
              -91.83428,
              31.266344
            ],
            [
              -91.854853,
              31.264282
            ],
            [
              -91.869676,
              31.280713
            ],
            [
              -91.908924,
              31.296789
            ],
            [
              -91.934305,
              31.289855
            ],
            [
              -91.946522,
              31.271981
            ],
            [
              -91.935391,
              31.238433
            ],
            [
              -91.920454,
              31.231672
            ],
            [
              -91.940962,
              31.221518
            ],
            [
              -91.946569,
              31.239667
            ],
            [
              -91.962675,
              31.242321
            ],
            [
              -91.973862,
              31.225333
            ],
            [
              -91.992041,
              31.226153
            ],
            [
              -91.992195,
              31.239005
            ],
            [
              -92.004242,
              31.243457
            ],
            [
              -91.988022,
              31.248897
            ],
            [
              -92.003192,
              31.258911
            ],
            [
              -92.009849,
              31.282507
            ],
            [
              -92.019825,
              31.28046
            ],
            [
              -92.016531,
              31.302445
            ],
            [
              -92.010887,
              31.292424
            ],
            [
              -91.996456,
              31.29965
            ],
            [
              -92.007155,
              31.306639
            ],
            [
              -92.001702,
              31.317652
            ],
            [
              -92.008121,
              31.325797
            ],
            [
              -92.025948,
              31.343009
            ],
            [
              -92.081357,
              31.324143
            ],
            [
              -92.086718,
              31.335374
            ],
            [
              -92.095835,
              31.324279
            ],
            [
              -92.126396,
              31.305986
            ],
            [
              -92.162698,
              31.303482
            ],
            [
              -92.176738,
              31.311206
            ],
            [
              -92.178268,
              31.323295
            ],
            [
              -92.193856,
              31.333904
            ],
            [
              -92.211065,
              31.327402
            ],
            [
              -92.221837,
              31.331667
            ],
            [
              -92.23323,
              31.321545
            ],
            [
              -92.233764,
              31.151495
            ],
            [
              -92.224987,
              31.141627
            ],
            [
              -92.234258,
              31.131466
            ],
            [
              -92.232265,
              31.116243
            ],
            [
              -92.227124,
              31.074759
            ],
            [
              -92.216372,
              30.99123
            ],
            [
              -92.208299,
              30.96243
            ],
            [
              -92.280738,
              30.965071
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "099",
      "COUNTYNS": "01629486",
      "AFFGEOID": "0500000US22099",
      "GEOID": "22099",
      "NAME": "St. Martin",
      "LSAD": "15",
      "ALAND": 1910534476,
      "AWATER": 204161447,
      "County_state": "St. Martin,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.471951,
              29.955989
            ],
            [
              -91.47196,
              29.947964
            ],
            [
              -91.413559,
              29.904993
            ],
            [
              -91.40113,
              29.873526
            ],
            [
              -91.373642,
              29.834401
            ],
            [
              -91.386491,
              29.783681
            ],
            [
              -91.35177,
              29.766284
            ],
            [
              -91.298584,
              29.764966
            ],
            [
              -91.229398,
              29.748922
            ],
            [
              -91.215195,
              29.751211
            ],
            [
              -91.180909,
              29.728548
            ],
            [
              -91.134117,
              29.724073
            ],
            [
              -91.10001,
              29.699402
            ],
            [
              -91.106551,
              29.733487
            ],
            [
              -91.095364,
              29.737032
            ],
            [
              -91.094378,
              29.764575
            ],
            [
              -91.100052,
              29.785059
            ],
            [
              -91.092748,
              29.801574
            ],
            [
              -91.109462,
              29.823945
            ],
            [
              -91.133079,
              29.837306
            ],
            [
              -91.150227,
              29.836831
            ],
            [
              -91.187338,
              29.84869
            ],
            [
              -91.200263,
              29.861793
            ],
            [
              -91.214076,
              29.907569
            ],
            [
              -91.232016,
              29.918171
            ],
            [
              -91.231576,
              29.935649
            ],
            [
              -91.251395,
              29.957591
            ],
            [
              -91.255229,
              29.971457
            ],
            [
              -91.471951,
              29.955989
            ]
          ]
        ],
        [
          [
            [
              -91.981472,
              30.381692
            ],
            [
              -91.988718,
              30.368598
            ],
            [
              -91.953927,
              30.3598
            ],
            [
              -91.955918,
              30.333898
            ],
            [
              -91.965938,
              30.325863
            ],
            [
              -91.954997,
              30.308368
            ],
            [
              -91.952329,
              30.29278
            ],
            [
              -91.949047,
              30.276771
            ],
            [
              -91.976933,
              30.260649
            ],
            [
              -91.958909,
              30.237184
            ],
            [
              -91.942818,
              30.236599
            ],
            [
              -91.943618,
              30.226
            ],
            [
              -91.955466,
              30.218965
            ],
            [
              -91.957918,
              30.2119
            ],
            [
              -91.976879,
              30.210933
            ],
            [
              -91.979565,
              30.181884
            ],
            [
              -91.965619,
              30.176701
            ],
            [
              -91.954718,
              30.1871
            ],
            [
              -91.920718,
              30.1879
            ],
            [
              -91.905544,
              30.154616
            ],
            [
              -91.926433,
              30.12937
            ],
            [
              -91.934067,
              30.119882
            ],
            [
              -91.941428,
              30.099765
            ],
            [
              -91.950998,
              30.073213
            ],
            [
              -91.907242,
              30.072496
            ],
            [
              -91.896234,
              30.057084
            ],
            [
              -91.883612,
              30.063666
            ],
            [
              -91.876207,
              30.067579
            ],
            [
              -91.851235,
              30.057326
            ],
            [
              -91.819216,
              30.044013
            ],
            [
              -91.813941,
              30.049927
            ],
            [
              -91.801499,
              30.069916
            ],
            [
              -91.760596,
              30.090778
            ],
            [
              -91.765168,
              30.108495
            ],
            [
              -91.726628,
              30.122413
            ],
            [
              -91.703278,
              30.121144
            ],
            [
              -91.679051,
              30.105102
            ],
            [
              -91.661523,
              30.109952
            ],
            [
              -91.642383,
              30.083405
            ],
            [
              -91.620576,
              30.069584
            ],
            [
              -91.61231,
              30.067945
            ],
            [
              -91.605996,
              30.057528
            ],
            [
              -91.600354,
              30.033169
            ],
            [
              -91.507763,
              30.033282
            ],
            [
              -91.495427,
              30.041369
            ],
            [
              -91.487217,
              30.058055
            ],
            [
              -91.368835,
              30.058824
            ],
            [
              -91.378025,
              30.094851
            ],
            [
              -91.38895,
              30.102294
            ],
            [
              -91.463833,
              30.102493
            ],
            [
              -91.46995,
              30.109072
            ],
            [
              -91.461313,
              30.131246
            ],
            [
              -91.468183,
              30.148957
            ],
            [
              -91.471797,
              30.186266
            ],
            [
              -91.489081,
              30.199277
            ],
            [
              -91.475277,
              30.229231
            ],
            [
              -91.508268,
              30.240297
            ],
            [
              -91.56276,
              30.241949
            ],
            [
              -91.588039,
              30.257113
            ],
            [
              -91.587732,
              30.273282
            ],
            [
              -91.621055,
              30.309938
            ],
            [
              -91.627544,
              30.330102
            ],
            [
              -91.625483,
              30.354209
            ],
            [
              -91.647934,
              30.365327
            ],
            [
              -91.629032,
              30.377933
            ],
            [
              -91.624655,
              30.394369
            ],
            [
              -91.628087,
              30.413915
            ],
            [
              -91.639568,
              30.429032
            ],
            [
              -91.640099,
              30.442728
            ],
            [
              -91.674547,
              30.474359
            ],
            [
              -91.685283,
              30.476059
            ],
            [
              -91.700933,
              30.497433
            ],
            [
              -91.756193,
              30.497738
            ],
            [
              -91.736545,
              30.451489
            ],
            [
              -91.72264,
              30.452326
            ],
            [
              -91.6943,
              30.401748
            ],
            [
              -91.678896,
              30.394731
            ],
            [
              -91.764861,
              30.394424
            ],
            [
              -91.81271,
              30.39426
            ],
            [
              -91.869273,
              30.417235
            ],
            [
              -91.925481,
              30.398267
            ],
            [
              -91.928619,
              30.397349
            ],
            [
              -91.930692,
              30.396552
            ],
            [
              -91.932662,
              30.396847
            ],
            [
              -91.935323,
              30.395725
            ],
            [
              -91.936912,
              30.394742
            ],
            [
              -91.937905,
              30.395035
            ],
            [
              -91.965698,
              30.410367
            ],
            [
              -91.978184,
              30.407403
            ],
            [
              -91.981472,
              30.381692
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "127",
      "COUNTYNS": "00559594",
      "AFFGEOID": "0500000US22127",
      "GEOID": "22127",
      "NAME": "Winn",
      "LSAD": "15",
      "ALAND": 2460711982,
      "AWATER": 17448075,
      "County_state": "Winn,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.976,
              31.71298
            ],
            [
              -92.973818,
              31.709138
            ],
            [
              -92.973529,
              31.708922
            ],
            [
              -92.892195,
              31.708908
            ],
            [
              -92.667733,
              31.709504
            ],
            [
              -92.619976,
              31.709572
            ],
            [
              -92.61999,
              31.71249
            ],
            [
              -92.619224,
              31.797149
            ],
            [
              -92.579307,
              31.79701
            ],
            [
              -92.362608,
              31.796633
            ],
            [
              -92.362227,
              31.808113
            ],
            [
              -92.340494,
              31.813367
            ],
            [
              -92.340768,
              31.833075
            ],
            [
              -92.329229,
              31.849415
            ],
            [
              -92.337095,
              31.851613
            ],
            [
              -92.321311,
              31.865134
            ],
            [
              -92.324671,
              31.886304
            ],
            [
              -92.312586,
              31.89618
            ],
            [
              -92.312623,
              31.927382
            ],
            [
              -92.312218,
              32.146123
            ],
            [
              -92.645949,
              32.146896
            ],
            [
              -92.699591,
              32.14688
            ],
            [
              -92.791563,
              32.146597
            ],
            [
              -92.814737,
              32.146907
            ],
            [
              -92.93908,
              32.147942
            ],
            [
              -92.931221,
              32.122484
            ],
            [
              -92.912763,
              32.10342
            ],
            [
              -92.904734,
              32.081435
            ],
            [
              -92.911756,
              32.077104
            ],
            [
              -92.895838,
              32.055007
            ],
            [
              -92.898457,
              32.044894
            ],
            [
              -92.888185,
              32.031202
            ],
            [
              -92.887804,
              32.008629
            ],
            [
              -92.901043,
              31.987973
            ],
            [
              -92.883495,
              31.981142
            ],
            [
              -92.885034,
              31.956563
            ],
            [
              -92.872174,
              31.945856
            ],
            [
              -92.884867,
              31.939233
            ],
            [
              -92.89464,
              31.917159
            ],
            [
              -92.892582,
              31.901446
            ],
            [
              -92.901088,
              31.893909
            ],
            [
              -92.906222,
              31.869395
            ],
            [
              -92.8982,
              31.859415
            ],
            [
              -92.908368,
              31.850751
            ],
            [
              -92.945953,
              31.857138
            ],
            [
              -92.950876,
              31.849589
            ],
            [
              -92.938425,
              31.82941
            ],
            [
              -92.948597,
              31.818894
            ],
            [
              -92.94198,
              31.814973
            ],
            [
              -92.952552,
              31.783688
            ],
            [
              -92.9668,
              31.781299
            ],
            [
              -92.968603,
              31.731006
            ],
            [
              -92.976,
              31.71298
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "123",
      "COUNTYNS": "00559578",
      "AFFGEOID": "0500000US22123",
      "GEOID": "22123",
      "NAME": "West Carroll",
      "LSAD": "15",
      "ALAND": 931478638,
      "AWATER": 2386193,
      "County_state": "West Carroll,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.628567,
              32.719112
            ],
            [
              -91.638883,
              32.702472
            ],
            [
              -91.624023,
              32.69567
            ],
            [
              -91.621993,
              32.679944
            ],
            [
              -91.637587,
              32.667341
            ],
            [
              -91.596876,
              32.667212
            ],
            [
              -91.596027,
              32.582192
            ],
            [
              -91.445299,
              32.581956
            ],
            [
              -91.426574,
              32.599854
            ],
            [
              -91.396964,
              32.609468
            ],
            [
              -91.404971,
              32.627057
            ],
            [
              -91.395761,
              32.641025
            ],
            [
              -91.401733,
              32.669089
            ],
            [
              -91.398255,
              32.69298
            ],
            [
              -91.374887,
              32.689863
            ],
            [
              -91.377197,
              32.706279
            ],
            [
              -91.344845,
              32.756659
            ],
            [
              -91.339767,
              32.821713
            ],
            [
              -91.347716,
              32.823017
            ],
            [
              -91.339615,
              32.862119
            ],
            [
              -91.326942,
              32.872337
            ],
            [
              -91.321377,
              32.863976
            ],
            [
              -91.31036,
              32.890251
            ],
            [
              -91.30919,
              32.935901
            ],
            [
              -91.280203,
              32.950851
            ],
            [
              -91.267665,
              32.975081
            ],
            [
              -91.265018,
              33.005084
            ],
            [
              -91.284398,
              33.005007
            ],
            [
              -91.312016,
              33.005262
            ],
            [
              -91.322506,
              33.005341
            ],
            [
              -91.325037,
              33.005364
            ],
            [
              -91.326396,
              33.005376
            ],
            [
              -91.329767,
              33.005421
            ],
            [
              -91.333011,
              33.005529
            ],
            [
              -91.376016,
              33.005794
            ],
            [
              -91.425466,
              33.006016
            ],
            [
              -91.435782,
              33.006099
            ],
            [
              -91.449623,
              32.983825
            ],
            [
              -91.437551,
              32.980932
            ],
            [
              -91.456279,
              32.965842
            ],
            [
              -91.479736,
              32.93542
            ],
            [
              -91.47576,
              32.929723
            ],
            [
              -91.498296,
              32.901705
            ],
            [
              -91.494655,
              32.890796
            ],
            [
              -91.511515,
              32.879007
            ],
            [
              -91.534728,
              32.87747
            ],
            [
              -91.566363,
              32.855561
            ],
            [
              -91.57775,
              32.83001
            ],
            [
              -91.590995,
              32.807949
            ],
            [
              -91.589398,
              32.797521
            ],
            [
              -91.602765,
              32.787444
            ],
            [
              -91.595407,
              32.766207
            ],
            [
              -91.597171,
              32.748897
            ],
            [
              -91.617719,
              32.720816
            ],
            [
              -91.628567,
              32.719112
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "017",
      "COUNTYNS": "00558458",
      "AFFGEOID": "0500000US22017",
      "GEOID": "22017",
      "NAME": "Caddo",
      "LSAD": "15",
      "ALAND": 2275369596,
      "AWATER": 150398853,
      "County_state": "Caddo,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.043147,
              32.693031
            ],
            [
              -94.0431469938267,
              32.6930300000381
            ],
            [
              -94.042913,
              32.655127
            ],
            [
              -94.04278,
              32.643466
            ],
            [
              -94.042824,
              32.640305
            ],
            [
              -94.042926,
              32.622015
            ],
            [
              -94.042929,
              32.61826
            ],
            [
              -94.042919,
              32.610142
            ],
            [
              -94.043083,
              32.564261
            ],
            [
              -94.043142,
              32.559502
            ],
            [
              -94.0430847911547,
              32.516465004884196
            ],
            [
              -94.043081,
              32.513613
            ],
            [
              -94.042885,
              32.505145
            ],
            [
              -94.042911,
              32.492852
            ],
            [
              -94.043089,
              32.486561
            ],
            [
              -94.043072,
              32.4843
            ],
            [
              -94.042955,
              32.480261
            ],
            [
              -94.042995,
              32.478004
            ],
            [
              -94.042902,
              32.472906
            ],
            [
              -94.042875,
              32.471348
            ],
            [
              -94.042903,
              32.470386
            ],
            [
              -94.042908,
              32.439891
            ],
            [
              -94.042986,
              32.435507
            ],
            [
              -94.042899,
              32.400659
            ],
            [
              -94.042923,
              32.399918
            ],
            [
              -94.042901,
              32.392283
            ],
            [
              -94.042763,
              32.373332
            ],
            [
              -94.042739,
              32.363559
            ],
            [
              -94.042733,
              32.269696
            ],
            [
              -94.042732,
              32.26962
            ],
            [
              -94.042662,
              32.218146
            ],
            [
              -94.0426205276662,
              32.1960049578035
            ],
            [
              -93.989928,
              32.195571
            ],
            [
              -93.951085,
              32.195545
            ],
            [
              -93.920039,
              32.216222
            ],
            [
              -93.897246,
              32.226759
            ],
            [
              -93.860327,
              32.253673
            ],
            [
              -93.856598,
              32.281063
            ],
            [
              -93.847708,
              32.292405
            ],
            [
              -93.807733,
              32.313525
            ],
            [
              -93.807098,
              32.327751
            ],
            [
              -93.791323,
              32.340197
            ],
            [
              -93.755554,
              32.343783
            ],
            [
              -93.75151,
              32.339821
            ],
            [
              -93.714306,
              32.316189
            ],
            [
              -93.686834,
              32.316747
            ],
            [
              -93.666472,
              32.317444
            ],
            [
              -93.650965,
              32.306497
            ],
            [
              -93.614265,
              32.269607
            ],
            [
              -93.61469,
              32.237526
            ],
            [
              -93.471249,
              32.237186
            ],
            [
              -93.461871,
              32.249482
            ],
            [
              -93.48605,
              32.257185
            ],
            [
              -93.480343,
              32.242073
            ],
            [
              -93.49022,
              32.243585
            ],
            [
              -93.492204,
              32.265582
            ],
            [
              -93.510609,
              32.276063
            ],
            [
              -93.524803,
              32.274003
            ],
            [
              -93.514407,
              32.297087
            ],
            [
              -93.539354,
              32.28992
            ],
            [
              -93.537114,
              32.304861
            ],
            [
              -93.528165,
              32.313212
            ],
            [
              -93.538862,
              32.31838
            ],
            [
              -93.545009,
              32.306454
            ],
            [
              -93.555323,
              32.331423
            ],
            [
              -93.582402,
              32.328516
            ],
            [
              -93.588454,
              32.348934
            ],
            [
              -93.61513,
              32.348332
            ],
            [
              -93.622248,
              32.35401
            ],
            [
              -93.608253,
              32.370246
            ],
            [
              -93.625616,
              32.361915
            ],
            [
              -93.62888,
              32.399738
            ],
            [
              -93.65069,
              32.378197
            ],
            [
              -93.635741,
              32.39593
            ],
            [
              -93.648101,
              32.402687
            ],
            [
              -93.667969,
              32.404074
            ],
            [
              -93.663343,
              32.387819
            ],
            [
              -93.685569,
              32.395498
            ],
            [
              -93.686675,
              32.407048
            ],
            [
              -93.660336,
              32.41641
            ],
            [
              -93.683409,
              32.417465
            ],
            [
              -93.691777,
              32.422794
            ],
            [
              -93.680024,
              32.433565
            ],
            [
              -93.673362,
              32.420892
            ],
            [
              -93.661396,
              32.427624
            ],
            [
              -93.674049,
              32.440676
            ],
            [
              -93.691636,
              32.448923
            ],
            [
              -93.683709,
              32.458808
            ],
            [
              -93.701458,
              32.481635
            ],
            [
              -93.697432,
              32.493927
            ],
            [
              -93.717578,
              32.487952
            ],
            [
              -93.705043,
              32.497351
            ],
            [
              -93.73833,
              32.512581
            ],
            [
              -93.767577,
              32.541471
            ],
            [
              -93.756206,
              32.537037
            ],
            [
              -93.741681,
              32.556635
            ],
            [
              -93.753263,
              32.556199
            ],
            [
              -93.754354,
              32.56507
            ],
            [
              -93.73661,
              32.579845
            ],
            [
              -93.739474,
              32.590773
            ],
            [
              -93.756946,
              32.587525
            ],
            [
              -93.769107,
              32.595669
            ],
            [
              -93.743545,
              32.602844
            ],
            [
              -93.759971,
              32.606107
            ],
            [
              -93.751515,
              32.620858
            ],
            [
              -93.761311,
              32.641891
            ],
            [
              -93.781818,
              32.639063
            ],
            [
              -93.781248,
              32.648853
            ],
            [
              -93.765852,
              32.652696
            ],
            [
              -93.774928,
              32.674142
            ],
            [
              -93.786773,
              32.668863
            ],
            [
              -93.782111,
              32.712212
            ],
            [
              -93.787129,
              32.724641
            ],
            [
              -93.802562,
              32.72616
            ],
            [
              -93.819091,
              32.710869
            ],
            [
              -93.826268,
              32.716593
            ],
            [
              -93.819169,
              32.736002
            ],
            [
              -93.791111,
              32.759273
            ],
            [
              -93.783233,
              32.78436
            ],
            [
              -93.78729,
              32.794313
            ],
            [
              -93.80323,
              32.797078
            ],
            [
              -93.816831,
              32.782333
            ],
            [
              -93.824253,
              32.792451
            ],
            [
              -93.816949,
              32.801169
            ],
            [
              -93.798491,
              32.80758
            ],
            [
              -93.789598,
              32.822026
            ],
            [
              -93.785181,
              32.857353
            ],
            [
              -93.799553,
              32.84688
            ],
            [
              -93.814747,
              32.851593
            ],
            [
              -93.802824,
              32.860263
            ],
            [
              -93.801147,
              32.871361
            ],
            [
              -93.820313,
              32.88434
            ],
            [
              -93.82085,
              32.901971
            ],
            [
              -93.801967,
              32.931806
            ],
            [
              -93.82357,
              32.946394
            ],
            [
              -93.845224,
              32.950428
            ],
            [
              -93.826597,
              32.966734
            ],
            [
              -93.800924,
              32.96562
            ],
            [
              -93.805313,
              32.973807
            ],
            [
              -93.822527,
              32.967191
            ],
            [
              -93.817689,
              32.975814
            ],
            [
              -93.825719,
              33.011985
            ],
            [
              -93.814553,
              33.019372
            ],
            [
              -94.024475,
              33.019207
            ],
            [
              -94.027983,
              33.019139
            ],
            [
              -94.035839,
              33.019145
            ],
            [
              -94.041444,
              33.019188
            ],
            [
              -94.042964,
              33.019219
            ],
            [
              -94.043088,
              32.955592
            ],
            [
              -94.043067,
              32.937903
            ],
            [
              -94.043092,
              32.910021
            ],
            [
              -94.042885,
              32.898911
            ],
            [
              -94.042859,
              32.892771
            ],
            [
              -94.0428857174211,
              32.881088560255
            ],
            [
              -94.042886,
              32.880965
            ],
            [
              -94.043025,
              32.880446
            ],
            [
              -94.042785,
              32.871486
            ],
            [
              -94.043026,
              32.797476
            ],
            [
              -94.042747,
              32.786973
            ],
            [
              -94.042829,
              32.785277
            ],
            [
              -94.042938,
              32.780558
            ],
            [
              -94.043027,
              32.776863
            ],
            [
              -94.042947,
              32.767991
            ],
            [
              -94.043147,
              32.693031
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "075",
      "COUNTYNS": "00558125",
      "AFFGEOID": "0500000US22075",
      "GEOID": "22075",
      "NAME": "Plaquemines",
      "LSAD": "15",
      "ALAND": 2021056000,
      "AWATER": 4628015820,
      "County_state": "Plaquemines,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.626769,
              29.535205
            ],
            [
              -89.617737,
              29.520521
            ],
            [
              -89.599673,
              29.507594
            ],
            [
              -89.590404,
              29.518867
            ],
            [
              -89.578401,
              29.532309
            ],
            [
              -89.564615,
              29.543786
            ],
            [
              -89.575192,
              29.549886
            ],
            [
              -89.588978,
              29.547612
            ],
            [
              -89.610844,
              29.536549
            ],
            [
              -89.626769,
              29.535205
            ]
          ]
        ],
        [
          [
            [
              -90.06742,
              29.7786
            ],
            [
              -90.075683,
              29.77185
            ],
            [
              -90.06562,
              29.753118
            ],
            [
              -90.067242,
              29.738664
            ],
            [
              -90.040634,
              29.707984
            ],
            [
              -90.029518,
              29.687091
            ],
            [
              -90.015884,
              29.674413
            ],
            [
              -90.002201,
              29.623421
            ],
            [
              -89.984533,
              29.617396
            ],
            [
              -89.980812,
              29.580155
            ],
            [
              -89.988677,
              29.548892
            ],
            [
              -89.97895,
              29.533643
            ],
            [
              -89.979611,
              29.485596
            ],
            [
              -89.97747,
              29.449371
            ],
            [
              -89.960914,
              29.430746
            ],
            [
              -89.946517,
              29.375223
            ],
            [
              -89.930686,
              29.335561
            ],
            [
              -89.90437063072201,
              29.2949262097711
            ],
            [
              -89.883463,
              29.307103
            ],
            [
              -89.842641,
              29.318823
            ],
            [
              -89.808513,
              29.313845
            ],
            [
              -89.782149,
              29.311132
            ],
            [
              -89.726162,
              29.304026
            ],
            [
              -89.697258,
              29.296679
            ],
            [
              -89.671781,
              29.289028
            ],
            [
              -89.639663,
              29.290531
            ],
            [
              -89.623976,
              29.269178
            ],
            [
              -89.608289,
              29.264202
            ],
            [
              -89.606651,
              29.252023
            ],
            [
              -89.5366,
              29.236212
            ],
            [
              -89.482844,
              29.215053
            ],
            [
              -89.47231,
              29.20755
            ],
            [
              -89.455829,
              29.190991
            ],
            [
              -89.447472,
              29.178576
            ],
            [
              -89.432932,
              29.149023
            ],
            [
              -89.428965,
              29.14451
            ],
            [
              -89.417718,
              29.13869
            ],
            [
              -89.409371,
              29.127855
            ],
            [
              -89.390515,
              29.123576
            ],
            [
              -89.350585,
              29.129598
            ],
            [
              -89.331868,
              29.148645
            ],
            [
              -89.325688,
              29.177079
            ],
            [
              -89.32854,
              29.190567
            ],
            [
              -89.318557,
              29.201564
            ],
            [
              -89.295027,
              29.198867
            ],
            [
              -89.282261,
              29.181435
            ],
            [
              -89.281479,
              29.159853
            ],
            [
              -89.278627,
              29.137642
            ],
            [
              -89.29574,
              29.133698
            ],
            [
              -89.296216,
              29.126431
            ],
            [
              -89.312378,
              29.117503
            ],
            [
              -89.302633,
              29.097775
            ],
            [
              -89.312853,
              29.087391
            ],
            [
              -89.310001,
              29.070981
            ],
            [
              -89.329728,
              29.056023
            ],
            [
              -89.343751,
              29.052906
            ],
            [
              -89.362112,
              29.012123
            ],
            [
              -89.393011,
              28.987073
            ],
            [
              -89.419865,
              28.929709
            ],
            [
              -89.4075611825122,
              28.9323801764195
            ],
            [
              -89.400966,
              28.933812
            ],
            [
              -89.36437,
              28.964721
            ],
            [
              -89.293066,
              29.041322
            ],
            [
              -89.259354,
              29.058358
            ],
            [
              -89.251727,
              29.083172
            ],
            [
              -89.225957,
              29.069404
            ],
            [
              -89.217514,
              29.055675
            ],
            [
              -89.218673,
              29.022515
            ],
            [
              -89.142866,
              28.991623
            ],
            [
              -89.137743,
              29.018878
            ],
            [
              -89.148792,
              29.02967
            ],
            [
              -89.146953,
              29.051659
            ],
            [
              -89.13079,
              29.070773
            ],
            [
              -89.11653,
              29.074097
            ],
            [
              -89.110825,
              29.081159
            ],
            [
              -89.106309,
              29.111481
            ],
            [
              -89.098704,
              29.117711
            ],
            [
              -89.085869,
              29.111689
            ],
            [
              -89.066617,
              29.090714
            ],
            [
              -89.039521,
              29.135463
            ],
            [
              -89.09704,
              29.138784
            ],
            [
              -89.11035,
              29.152693
            ],
            [
              -89.089434,
              29.166391
            ],
            [
              -89.051173,
              29.170483
            ],
            [
              -89.032506,
              29.180735
            ],
            [
              -89.01428,
              29.166913
            ],
            [
              -89.003869,
              29.179881
            ],
            [
              -89.025974,
              29.215153
            ],
            [
              -89.057126,
              29.210534
            ],
            [
              -89.066379,
              29.197518
            ],
            [
              -89.091336,
              29.191086
            ],
            [
              -89.112727,
              29.20229
            ],
            [
              -89.116653,
              29.219532
            ],
            [
              -89.115104,
              29.252901
            ],
            [
              -89.129688,
              29.265632
            ],
            [
              -89.136979,
              29.275239
            ],
            [
              -89.134337,
              29.27934
            ],
            [
              -89.140275,
              29.291085
            ],
            [
              -89.157593,
              29.296691
            ],
            [
              -89.165015,
              29.303039
            ],
            [
              -89.178221,
              29.32697
            ],
            [
              -89.177351,
              29.33521
            ],
            [
              -89.179547,
              29.339608
            ],
            [
              -89.189354,
              29.345061
            ],
            [
              -89.200599,
              29.347672
            ],
            [
              -89.200389,
              29.344418
            ],
            [
              -89.204703,
              29.338674
            ],
            [
              -89.219734,
              29.324412
            ],
            [
              -89.223444,
              29.318066
            ],
            [
              -89.224192,
              29.313792
            ],
            [
              -89.24087,
              29.310081
            ],
            [
              -89.253545,
              29.322802
            ],
            [
              -89.257852,
              29.336872
            ],
            [
              -89.2653,
              29.345352
            ],
            [
              -89.272543,
              29.351195
            ],
            [
              -89.312085,
              29.388038
            ],
            [
              -89.319922,
              29.36268
            ],
            [
              -89.338582,
              29.355445
            ],
            [
              -89.347615,
              29.365
            ],
            [
              -89.336589,
              29.378228
            ],
            [
              -89.340304,
              29.381412
            ],
            [
              -89.355528,
              29.381569
            ],
            [
              -89.373109,
              29.387175
            ],
            [
              -89.380001,
              29.391785
            ],
            [
              -89.42238,
              29.390628
            ],
            [
              -89.457303,
              29.393148
            ],
            [
              -89.470142,
              29.401471
            ],
            [
              -89.47714,
              29.411241
            ],
            [
              -89.482318,
              29.406222
            ],
            [
              -89.484354,
              29.397471
            ],
            [
              -89.487308,
              29.393346
            ],
            [
              -89.505038,
              29.38604
            ],
            [
              -89.508551,
              29.386168
            ],
            [
              -89.518368,
              29.40023
            ],
            [
              -89.531943,
              29.425679
            ],
            [
              -89.53215,
              29.434567
            ],
            [
              -89.528429,
              29.454702
            ],
            [
              -89.551088,
              29.463446
            ],
            [
              -89.572102,
              29.475217
            ],
            [
              -89.569607,
              29.494044
            ],
            [
              -89.60846,
              29.490439
            ],
            [
              -89.640198,
              29.50356
            ],
            [
              -89.657192,
              29.532309
            ],
            [
              -89.681092,
              29.534487
            ],
            [
              -89.684486,
              29.551073
            ],
            [
              -89.684486,
              29.563263
            ],
            [
              -89.663668,
              29.56441
            ],
            [
              -89.641802,
              29.575573
            ],
            [
              -89.650358,
              29.586942
            ],
            [
              -89.671082,
              29.588243
            ],
            [
              -89.684486,
              29.602867
            ],
            [
              -89.688141,
              29.615055
            ],
            [
              -89.684486,
              29.624804
            ],
            [
              -89.674736,
              29.626633
            ],
            [
              -89.649108,
              29.613017
            ],
            [
              -89.637761,
              29.596655
            ],
            [
              -89.602585,
              29.582188
            ],
            [
              -89.602109,
              29.610295
            ],
            [
              -89.665689,
              29.643145
            ],
            [
              -89.641228,
              29.647961
            ],
            [
              -89.610827,
              29.63856
            ],
            [
              -89.583336,
              29.652834
            ],
            [
              -89.535202,
              29.648567
            ],
            [
              -89.523018,
              29.639427
            ],
            [
              -89.504738,
              29.631508
            ],
            [
              -89.486931,
              29.620447
            ],
            [
              -89.4867087315144,
              29.621002671213898
            ],
            [
              -89.485367,
              29.624357
            ],
            [
              -89.487915,
              29.630405
            ],
            [
              -89.510735,
              29.657473
            ],
            [
              -89.53376,
              29.670204
            ],
            [
              -89.570928,
              29.665046
            ],
            [
              -89.628181,
              29.680682
            ],
            [
              -89.660282,
              29.708747
            ],
            [
              -89.683853,
              29.716033
            ],
            [
              -89.692208,
              29.738457
            ],
            [
              -89.705033,
              29.752792
            ],
            [
              -89.721045,
              29.757652
            ],
            [
              -89.758023,
              29.758498
            ],
            [
              -89.767727,
              29.755323
            ],
            [
              -89.790754,
              29.760821
            ],
            [
              -89.786198,
              29.773281
            ],
            [
              -89.803004,
              29.792724
            ],
            [
              -89.816395,
              29.801327
            ],
            [
              -89.83066,
              29.801246
            ],
            [
              -89.859815,
              29.793042
            ],
            [
              -89.86972,
              29.795451
            ],
            [
              -89.878485,
              29.815193
            ],
            [
              -89.895932,
              29.833237
            ],
            [
              -89.910708,
              29.867865
            ],
            [
              -89.917587,
              29.866661
            ],
            [
              -89.935066,
              29.892705
            ],
            [
              -89.987266,
              29.905004
            ],
            [
              -89.989183,
              29.889388
            ],
            [
              -90.008056,
              29.895528
            ],
            [
              -90.017757,
              29.878534
            ],
            [
              -90.027456,
              29.85285
            ],
            [
              -90.041048,
              29.845209
            ],
            [
              -90.057939,
              29.836873
            ],
            [
              -90.06861,
              29.82883
            ],
            [
              -90.071861,
              29.807896
            ],
            [
              -90.060264,
              29.784946
            ],
            [
              -90.06742,
              29.7786
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "22",
      "COUNTYFP": "103",
      "COUNTYNS": "01629503",
      "AFFGEOID": "0500000US22103",
      "GEOID": "22103",
      "NAME": "St. Tammany",
      "LSAD": "15",
      "ALAND": 2190165998,
      "AWATER": 647368450,
      "County_state": "St. Tammany,22"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.255648,
              30.711905
            ],
            [
              -90.257535,
              30.71024
            ],
            [
              -90.250365,
              30.695552
            ],
            [
              -90.253241,
              30.664346
            ],
            [
              -90.243669,
              30.646933
            ],
            [
              -90.248694,
              30.616078
            ],
            [
              -90.243172,
              30.596015
            ],
            [
              -90.244811,
              30.580169
            ],
            [
              -90.244305,
              30.503592
            ],
            [
              -90.243555,
              30.363827
            ],
            [
              -90.243649,
              30.361581
            ],
            [
              -90.242062,
              30.359927
            ],
            [
              -90.192061,
              30.310783
            ],
            [
              -90.185508,
              30.288178
            ],
            [
              -90.160636,
              30.234337
            ],
            [
              -90.154034,
              30.20348
            ],
            [
              -90.156256,
              30.189613
            ],
            [
              -90.144658,
              30.180021
            ],
            [
              -90.108119,
              30.16494
            ],
            [
              -90.082527,
              30.1572
            ],
            [
              -90.040277,
              30.155114
            ],
            [
              -90.003403,
              30.162997
            ],
            [
              -89.974424,
              30.176177
            ],
            [
              -89.903234,
              30.19866
            ],
            [
              -89.848403,
              30.190079
            ],
            [
              -89.820793,
              30.180002
            ],
            [
              -89.797414,
              30.160757
            ],
            [
              -89.775915,
              30.159404
            ],
            [
              -89.755318,
              30.16975
            ],
            [
              -89.74544,
              30.17745
            ],
            [
              -89.714037,
              30.161214
            ],
            [
              -89.685287,
              30.175346
            ],
            [
              -89.655406,
              30.159304
            ],
            [
              -89.6228797076882,
              30.1523675549142
            ],
            [
              -89.617542,
              30.156422
            ],
            [
              -89.598027,
              30.152409
            ],
            [
              -89.595021,
              30.149891
            ],
            [
              -89.587062,
              30.150648
            ],
            [
              -89.572093,
              30.160362
            ],
            [
              -89.56827,
              30.163932
            ],
            [
              -89.562825,
              30.168667
            ],
            [
              -89.555013,
              30.170798
            ],
            [
              -89.537493,
              30.171745
            ],
            [
              -89.531213,
              30.177099
            ],
            [
              -89.524504,
              30.180753
            ],
            [
              -89.527952,
              30.188697
            ],
            [
              -89.530452,
              30.192197
            ],
            [
              -89.533352,
              30.194797
            ],
            [
              -89.538652,
              30.195797
            ],
            [
              -89.541453,
              30.195397
            ],
            [
              -89.546953,
              30.193097
            ],
            [
              -89.549053,
              30.191597
            ],
            [
              -89.550853,
              30.189197
            ],
            [
              -89.554653,
              30.185797
            ],
            [
              -89.562253,
              30.182397
            ],
            [
              -89.570154,
              30.180297
            ],
            [
              -89.574454,
              30.181697
            ],
            [
              -89.580754,
              30.186197
            ],
            [
              -89.585754,
              30.192096
            ],
            [
              -89.587354,
              30.195196
            ],
            [
              -89.588854,
              30.200296
            ],
            [
              -89.596655,
              30.211796
            ],
            [
              -89.601255,
              30.216096
            ],
            [
              -89.607655,
              30.217096
            ],
            [
              -89.610655,
              30.218096
            ],
            [
              -89.612556,
              30.219496
            ],
            [
              -89.615856,
              30.223195
            ],
            [
              -89.616956,
              30.225595
            ],
            [
              -89.617056,
              30.227495
            ],
            [
              -89.615856,
              30.235295
            ],
            [
              -89.614056,
              30.241495
            ],
            [
              -89.614156,
              30.244595
            ],
            [
              -89.616156,
              30.247395
            ],
            [
              -89.623856,
              30.249895
            ],
            [
              -89.626922,
              30.251745
            ],
            [
              -89.631789,
              30.256924
            ],
            [
              -89.632225,
              30.260137
            ],
            [
              -89.631215,
              30.261704
            ],
            [
              -89.630649,
              30.262084
            ],
            [
              -89.629757,
              30.267195
            ],
            [
              -89.63052,
              30.276562
            ],
            [
              -89.631411,
              30.279662
            ],
            [
              -89.637647,
              30.285032
            ],
            [
              -89.64344,
              30.287682
            ],
            [
              -89.641705,
              30.303799
            ],
            [
              -89.640401,
              30.306755
            ],
            [
              -89.639872,
              30.307281
            ],
            [
              -89.634208,
              30.308256
            ],
            [
              -89.631643,
              30.309332
            ],
            [
              -89.626221,
              30.314255
            ],
            [
              -89.626606,
              30.315457
            ],
            [
              -89.629877,
              30.321017
            ],
            [
              -89.630399,
              30.332933
            ],
            [
              -89.629727,
              30.339287
            ],
            [
              -89.636299,
              30.34397
            ],
            [
              -89.645199,
              30.348126
            ],
            [
              -89.645617,
              30.351314
            ],
            [
              -89.6467,
              30.3525
            ],
            [
              -89.652693,
              30.355536
            ],
            [
              -89.657191,
              30.356515
            ],
            [
              -89.660274,
              30.363487
            ],
            [
              -89.662204,
              30.371267
            ],
            [
              -89.670134,
              30.382429
            ],
            [
              -89.672762,
              30.389276
            ],
            [
              -89.679153,
              30.399991
            ],
            [
              -89.6815402880308,
              30.4030887339946
            ],
            [
              -89.683686,
              30.405873
            ],
            [
              -89.684118,
              30.412646
            ],
            [
              -89.68232,
              30.412991
            ],
            [
              -89.681165,
              30.411492
            ],
            [
              -89.680134,
              30.4114
            ],
            [
              -89.678514,
              30.414012
            ],
            [
              -89.680515,
              30.428924
            ],
            [
              -89.681946,
              30.434073
            ],
            [
              -89.683521,
              30.434959
            ],
            [
              -89.684816,
              30.439511
            ],
            [
              -89.682829,
              30.44581
            ],
            [
              -89.68341,
              30.451793
            ],
            [
              -89.6897794685397,
              30.4592779821573
            ],
            [
              -89.690102,
              30.459657
            ],
            [
              -89.695864,
              30.463269
            ],
            [
              -89.701799,
              30.465115
            ],
            [
              -89.705538,
              30.47235
            ],
            [
              -89.709551,
              30.477853
            ],
            [
              -89.710164,
              30.478308
            ],
            [
              -89.712493,
              30.47751
            ],
            [
              -89.715886,
              30.477797
            ],
            [
              -89.719652,
              30.483166
            ],
            [
              -89.721181,
              30.488608
            ],
            [
              -89.724614,
              30.491902
            ],
            [
              -89.726154,
              30.49256
            ],
            [
              -89.734615,
              30.494723
            ],
            [
              -89.742396,
              30.497316
            ],
            [
              -89.742816,
              30.498704
            ],
            [
              -89.746435,
              30.502619
            ],
            [
              -89.752931,
              30.502493
            ],
            [
              -89.758133,
              30.505404
            ],
            [
              -89.758575,
              30.505942
            ],
            [
              -89.758862,
              30.513062
            ],
            [
              -89.76057,
              30.515761
            ],
            [
              -89.768133,
              30.51502
            ],
            [
              -89.769996,
              30.521896
            ],
            [
              -89.770744,
              30.527819
            ],
            [
              -89.771643,
              30.530249
            ],
            [
              -89.775355,
              30.538848
            ],
            [
              -89.779565,
              30.544345
            ],
            [
              -89.780246,
              30.544607
            ],
            [
              -89.783994,
              30.544075
            ],
            [
              -89.788542,
              30.544464
            ],
            [
              -89.791046,
              30.545046
            ],
            [
              -89.793818,
              30.545935
            ],
            [
              -89.795335,
              30.546563
            ],
            [
              -89.795388,
              30.547452
            ],
            [
              -89.795231,
              30.548132
            ],
            [
              -89.793989,
              30.548283
            ],
            [
              -89.79196,
              30.548788
            ],
            [
              -89.791664,
              30.551524
            ],
            [
              -89.794532,
              30.556554
            ],
            [
              -89.802789,
              30.557903
            ],
            [
              -89.803831,
              30.558888
            ],
            [
              -89.803887,
              30.560581
            ],
            [
              -89.802833,
              30.562879
            ],
            [
              -89.801494,
              30.563703
            ],
            [
              -89.800277,
              30.563695
            ],
            [
              -89.796697,
              30.561718
            ],
            [
              -89.79243,
              30.563087
            ],
            [
              -89.790078,
              30.565333
            ],
            [
              -89.789695,
              30.56658
            ],
            [
              -89.790318,
              30.567524
            ],
            [
              -89.794495,
              30.569653
            ],
            [
              -89.799947,
              30.569351
            ],
            [
              -89.803753,
              30.568148
            ],
            [
              -89.806182,
              30.567543
            ],
            [
              -89.808027,
              30.567998
            ],
            [
              -89.808184,
              30.568795
            ],
            [
              -89.806843,
              30.572039
            ],
            [
              -89.809739,
              30.584714
            ],
            [
              -89.80866317829809,
              30.585318571527896
            ],
            [
              -89.807762,
              30.585825
            ],
            [
              -89.807118,
              30.587337
            ],
            [
              -89.812109,
              30.591473
            ],
            [
              -89.816396,
              30.591646
            ],
            [
              -89.818527,
              30.592688
            ],
            [
              -89.819838,
              30.59534
            ],
            [
              -89.819696,
              30.596785
            ],
            [
              -89.817202,
              30.600891
            ],
            [
              -89.814563,
              30.606152
            ],
            [
              -89.81392,
              30.607721
            ],
            [
              -89.81538,
              30.608566
            ],
            [
              -89.816905,
              30.60862
            ],
            [
              -89.821286,
              30.60713
            ],
            [
              -89.823278,
              30.60823
            ],
            [
              -89.822389,
              30.614462
            ],
            [
              -89.820868,
              30.618254
            ],
            [
              -89.821424,
              30.619815
            ],
            [
              -89.823261,
              30.622803
            ],
            [
              -89.818081,
              30.634019
            ],
            [
              -89.821868,
              30.644024
            ],
            [
              -89.824986,
              30.649423
            ],
            [
              -89.833261,
              30.657516
            ],
            [
              -89.836047,
              30.657298
            ],
            [
              -89.840988,
              30.658515
            ],
            [
              -89.851889,
              30.661199
            ],
            [
              -89.852263,
              30.662934
            ],
            [
              -89.85055,
              30.664781
            ],
            [
              -89.848879,
              30.665202
            ],
            [
              -89.84861106109449,
              30.6659742005644
            ],
            [
              -89.990776,
              30.66541
            ],
            [
              -90.001757,
              30.666795
            ],
            [
              -90.255648,
              30.711905
            ]
          ]
        ]
      ]
    }
  }
]
};