export const counties_pr = {
  "type":"FeatureCollection","name":"pr_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "133",
      "COUNTYNS": "01804547",
      "AFFGEOID": "0500000US72133",
      "GEOID": "72133",
      "NAME": "Santa Isabel",
      "LSAD": "13",
      "ALAND": 88144282,
      "AWATER": 111140897,
      "County_state": "Santa Isabel,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.4503675981588,
              17.9832256311647
            ],
            [
              -66.445481,
              17.979379
            ],
            [
              -66.412131,
              17.957286
            ],
            [
              -66.398945,
              17.950925
            ],
            [
              -66.391227,
              17.945819
            ],
            [
              -66.385059,
              17.939004
            ],
            [
              -66.37664488676769,
              17.946791490454498
            ],
            [
              -66.371591,
              17.951469
            ],
            [
              -66.37158643139439,
              17.9514791437981
            ],
            [
              -66.368777,
              17.957717
            ],
            [
              -66.365098,
              17.964832
            ],
            [
              -66.362511,
              17.968231
            ],
            [
              -66.33839,
              17.976458
            ],
            [
              -66.3381518765016,
              17.9764915904303
            ],
            [
              -66.342607,
              17.993561
            ],
            [
              -66.331244,
              18.015891
            ],
            [
              -66.356779,
              18.036942
            ],
            [
              -66.372226,
              18.032614
            ],
            [
              -66.426989,
              18.042627
            ],
            [
              -66.421054,
              18.019309
            ],
            [
              -66.4503675981588,
              17.9832256311647
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "025",
      "COUNTYNS": "01804492",
      "AFFGEOID": "0500000US72025",
      "GEOID": "72025",
      "NAME": "Caguas",
      "LSAD": "13",
      "ALAND": 151782488,
      "AWATER": 1201689,
      "County_state": "Caguas,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.117774,
              18.209949
            ],
            [
              -66.101673,
              18.183082
            ],
            [
              -66.103759,
              18.170453
            ],
            [
              -66.095099,
              18.169077
            ],
            [
              -66.065968,
              18.148411
            ],
            [
              -66.060761,
              18.116315
            ],
            [
              -66.052555,
              18.113069
            ],
            [
              -66.035368,
              18.119541
            ],
            [
              -66.020283,
              18.144081
            ],
            [
              -66.022256,
              18.166035
            ],
            [
              -66.011142,
              18.186181
            ],
            [
              -66.003855,
              18.185597
            ],
            [
              -65.999613,
              18.188708
            ],
            [
              -65.99765,
              18.206992
            ],
            [
              -66.013744,
              18.225645
            ],
            [
              -66.009461,
              18.241051
            ],
            [
              -66.013971,
              18.264706
            ],
            [
              -66.008233,
              18.278173
            ],
            [
              -66.029894,
              18.29087
            ],
            [
              -66.030572,
              18.30118
            ],
            [
              -66.021747,
              18.305034
            ],
            [
              -66.025614,
              18.309614
            ],
            [
              -66.041535,
              18.312344
            ],
            [
              -66.047309,
              18.29818
            ],
            [
              -66.066103,
              18.303321
            ],
            [
              -66.077313,
              18.25651
            ],
            [
              -66.090193,
              18.2276
            ],
            [
              -66.117774,
              18.209949
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "033",
      "COUNTYNS": "01804496",
      "AFFGEOID": "0500000US72033",
      "GEOID": "72033",
      "NAME": "CataÃ±o",
      "LSAD": "13",
      "ALAND": 12629288,
      "AWATER": 5619250,
      "County_state": "CataÃ±o,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.161216,
              18.453407
            ],
            [
              -66.169516,
              18.430998
            ],
            [
              -66.158545,
              18.421561
            ],
            [
              -66.13158,
              18.424656
            ],
            [
              -66.116133,
              18.439628
            ],
            [
              -66.112391,
              18.440105
            ],
            [
              -66.108281,
              18.438902
            ],
            [
              -66.108448,
              18.445354
            ],
            [
              -66.1292518737795,
              18.4725585650613
            ],
            [
              -66.137959,
              18.473892
            ],
            [
              -66.142225,
              18.464274
            ],
            [
              -66.153037,
              18.454457
            ],
            [
              -66.15307824699329,
              18.454451704812
            ],
            [
              -66.161216,
              18.453407
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "101",
      "COUNTYNS": "01804531",
      "AFFGEOID": "0500000US72101",
      "GEOID": "72101",
      "NAME": "Morovis",
      "LSAD": "13",
      "ALAND": 100676181,
      "AWATER": 139537,
      "County_state": "Morovis,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.463211,
              18.371473
            ],
            [
              -66.465142,
              18.352237
            ],
            [
              -66.454289,
              18.332654
            ],
            [
              -66.453353,
              18.30989
            ],
            [
              -66.464883,
              18.28324
            ],
            [
              -66.455703,
              18.257726
            ],
            [
              -66.409431,
              18.26331
            ],
            [
              -66.390957,
              18.273392
            ],
            [
              -66.377712,
              18.293976
            ],
            [
              -66.37302,
              18.302763
            ],
            [
              -66.365272,
              18.333229
            ],
            [
              -66.377058,
              18.344246
            ],
            [
              -66.395499,
              18.371756
            ],
            [
              -66.400998,
              18.363707
            ],
            [
              -66.421864,
              18.377808
            ],
            [
              -66.4438,
              18.371027
            ],
            [
              -66.451578,
              18.372214
            ],
            [
              -66.463211,
              18.371473
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "054",
      "COUNTYNS": "01804507",
      "AFFGEOID": "0500000US72054",
      "GEOID": "72054",
      "NAME": "Florida",
      "LSAD": "13",
      "ALAND": 39391498,
      "AWATER": 34012,
      "County_state": "Florida,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.582278,
              18.373716
            ],
            [
              -66.590785,
              18.33806
            ],
            [
              -66.539636,
              18.344405
            ],
            [
              -66.533623,
              18.351333
            ],
            [
              -66.530529,
              18.385013
            ],
            [
              -66.543079,
              18.405422
            ],
            [
              -66.564313,
              18.413309
            ],
            [
              -66.588181,
              18.389408
            ],
            [
              -66.582278,
              18.373716
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "073",
      "COUNTYNS": "01804517",
      "AFFGEOID": "0500000US72073",
      "GEOID": "72073",
      "NAME": "Jayuya",
      "LSAD": "13",
      "ALAND": 115340543,
      "AWATER": 3091,
      "County_state": "Jayuya,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.65455,
              18.169591
            ],
            [
              -66.649467,
              18.158857
            ],
            [
              -66.636604,
              18.151677
            ],
            [
              -66.591005,
              18.172479
            ],
            [
              -66.552766,
              18.152057
            ],
            [
              -66.54722,
              18.15313
            ],
            [
              -66.543133,
              18.163098
            ],
            [
              -66.531023,
              18.180137
            ],
            [
              -66.532725,
              18.200519
            ],
            [
              -66.548883,
              18.220567
            ],
            [
              -66.564456,
              18.242647
            ],
            [
              -66.554703,
              18.281583
            ],
            [
              -66.56662,
              18.298549
            ],
            [
              -66.578757,
              18.284049
            ],
            [
              -66.597475,
              18.284104
            ],
            [
              -66.606736,
              18.23848
            ],
            [
              -66.625141,
              18.246688
            ],
            [
              -66.628431,
              18.214298
            ],
            [
              -66.65455,
              18.169591
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "009",
      "COUNTYNS": "01804484",
      "AFFGEOID": "0500000US72009",
      "GEOID": "72009",
      "NAME": "Aibonito",
      "LSAD": "13",
      "ALAND": 81094471,
      "AWATER": 71895,
      "County_state": "Aibonito,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.314848,
              18.152088
            ],
            [
              -66.320913,
              18.126005
            ],
            [
              -66.310767,
              18.118168
            ],
            [
              -66.272941,
              18.086586
            ],
            [
              -66.259949,
              18.082612
            ],
            [
              -66.257527,
              18.075884
            ],
            [
              -66.242238,
              18.077148
            ],
            [
              -66.240053,
              18.093186
            ],
            [
              -66.22449,
              18.095339
            ],
            [
              -66.226828,
              18.116641
            ],
            [
              -66.216899,
              18.14322
            ],
            [
              -66.223716,
              18.165717
            ],
            [
              -66.242735,
              18.180248
            ],
            [
              -66.248172,
              18.165752
            ],
            [
              -66.277717,
              18.174194
            ],
            [
              -66.314848,
              18.152088
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "129",
      "COUNTYNS": "01804545",
      "AFFGEOID": "0500000US72129",
      "GEOID": "72129",
      "NAME": "San Lorenzo",
      "LSAD": "13",
      "ALAND": 137547974,
      "AWATER": 256425,
      "County_state": "San Lorenzo,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.052555,
              18.113069
            ],
            [
              -66.051788,
              18.109878
            ],
            [
              -66.012849,
              18.096415
            ],
            [
              -66.010245,
              18.075565
            ],
            [
              -65.975978,
              18.094308
            ],
            [
              -65.962051,
              18.086497
            ],
            [
              -65.933166,
              18.093754
            ],
            [
              -65.926227,
              18.119963
            ],
            [
              -65.920665,
              18.137823
            ],
            [
              -65.928341,
              18.142845
            ],
            [
              -65.932008,
              18.1619
            ],
            [
              -65.929844,
              18.164113
            ],
            [
              -65.929404,
              18.197433
            ],
            [
              -65.951741,
              18.215919
            ],
            [
              -65.951019,
              18.229292
            ],
            [
              -65.989048,
              18.220339
            ],
            [
              -65.99765,
              18.206992
            ],
            [
              -65.999613,
              18.188708
            ],
            [
              -66.003855,
              18.185597
            ],
            [
              -66.011142,
              18.186181
            ],
            [
              -66.022256,
              18.166035
            ],
            [
              -66.020283,
              18.144081
            ],
            [
              -66.035368,
              18.119541
            ],
            [
              -66.052555,
              18.113069
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "143",
      "COUNTYNS": "01804552",
      "AFFGEOID": "0500000US72143",
      "GEOID": "72143",
      "NAME": "Vega Alta",
      "LSAD": "13",
      "ALAND": 71816290,
      "AWATER": 25307600,
      "County_state": "Vega Alta,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.373243,
              18.371937
            ],
            [
              -66.377058,
              18.344246
            ],
            [
              -66.365272,
              18.333229
            ],
            [
              -66.348862,
              18.351605
            ],
            [
              -66.355278,
              18.360643
            ],
            [
              -66.318764,
              18.367411
            ],
            [
              -66.314158,
              18.380801
            ],
            [
              -66.303663,
              18.384077
            ],
            [
              -66.299926,
              18.423913
            ],
            [
              -66.317421,
              18.451074
            ],
            [
              -66.31503033652929,
              18.4746802213809
            ],
            [
              -66.315477,
              18.474724
            ],
            [
              -66.337728,
              18.48562
            ],
            [
              -66.33811576061011,
              18.4856432709405
            ],
            [
              -66.3496472039647,
              18.4863353152727
            ],
            [
              -66.347835,
              18.42886
            ],
            [
              -66.358898,
              18.421032
            ],
            [
              -66.362875,
              18.395421
            ],
            [
              -66.364284,
              18.389816
            ],
            [
              -66.373243,
              18.371937
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "093",
      "COUNTYNS": "01804527",
      "AFFGEOID": "0500000US72093",
      "GEOID": "72093",
      "NAME": "Maricao",
      "LSAD": "13",
      "ALAND": 94851792,
      "AWATER": 12487,
      "County_state": "Maricao,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -67.017388,
              18.189899
            ],
            [
              -67.051461,
              18.174053
            ],
            [
              -67.013844,
              18.165874
            ],
            [
              -66.977855,
              18.143799
            ],
            [
              -66.97554,
              18.143395
            ],
            [
              -66.971866,
              18.14154
            ],
            [
              -66.926517,
              18.151267
            ],
            [
              -66.903806,
              18.146363
            ],
            [
              -66.888098,
              18.153398
            ],
            [
              -66.85678,
              18.13859
            ],
            [
              -66.85012,
              18.162886
            ],
            [
              -66.83676,
              18.170553
            ],
            [
              -66.862832,
              18.174808
            ],
            [
              -66.863991,
              18.186748
            ],
            [
              -66.897964,
              18.187744
            ],
            [
              -66.97838,
              18.207916
            ],
            [
              -67.003803,
              18.193501
            ],
            [
              -67.019836,
              18.195471
            ],
            [
              -67.017388,
              18.189899
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "117",
      "COUNTYNS": "01804539",
      "AFFGEOID": "0500000US72117",
      "GEOID": "72117",
      "NAME": "RincÃ³n",
      "LSAD": "13",
      "ALAND": 37005461,
      "AWATER": 103924383,
      "County_state": "RincÃ³n,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -67.268259,
              18.366989
            ],
            [
              -67.27135,
              18.362329
            ],
            [
              -67.267484,
              18.353149
            ],
            [
              -67.2673694039721,
              18.352960478250598
            ],
            [
              -67.2481022134838,
              18.3212640527817
            ],
            [
              -67.235137,
              18.299935
            ],
            [
              -67.226081,
              18.296722
            ],
            [
              -67.2254030766224,
              18.296648479087697
            ],
            [
              -67.220709,
              18.3057
            ],
            [
              -67.183176,
              18.311052
            ],
            [
              -67.182852,
              18.313026
            ],
            [
              -67.195508,
              18.330309
            ],
            [
              -67.227336,
              18.348766
            ],
            [
              -67.23909007224889,
              18.375317596233
            ],
            [
              -67.260671,
              18.370197
            ],
            [
              -67.268259,
              18.366989
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "035",
      "COUNTYNS": "01804497",
      "AFFGEOID": "0500000US72035",
      "GEOID": "72035",
      "NAME": "Cayey",
      "LSAD": "13",
      "ALAND": 134509871,
      "AWATER": 68569,
      "County_state": "Cayey,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.226828,
              18.116641
            ],
            [
              -66.22449,
              18.095339
            ],
            [
              -66.201753,
              18.067639
            ],
            [
              -66.202038,
              18.046113
            ],
            [
              -66.166145,
              18.050063
            ],
            [
              -66.138987,
              18.036917
            ],
            [
              -66.119815,
              18.062899
            ],
            [
              -66.129031,
              18.088637
            ],
            [
              -66.112707,
              18.090159
            ],
            [
              -66.10257,
              18.105032
            ],
            [
              -66.068571,
              18.101828
            ],
            [
              -66.053146,
              18.107293
            ],
            [
              -66.051788,
              18.109878
            ],
            [
              -66.052555,
              18.113069
            ],
            [
              -66.060761,
              18.116315
            ],
            [
              -66.065968,
              18.148411
            ],
            [
              -66.095099,
              18.169077
            ],
            [
              -66.101726,
              18.151256
            ],
            [
              -66.118503,
              18.138151
            ],
            [
              -66.130827,
              18.138828
            ],
            [
              -66.131881,
              18.132577
            ],
            [
              -66.164748,
              18.144463
            ],
            [
              -66.175067,
              18.133745
            ],
            [
              -66.182272,
              18.144625
            ],
            [
              -66.187334,
              18.137053
            ],
            [
              -66.216899,
              18.14322
            ],
            [
              -66.226828,
              18.116641
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "061",
      "COUNTYNS": "01804511",
      "AFFGEOID": "0500000US72061",
      "GEOID": "72061",
      "NAME": "Guaynabo",
      "LSAD": "13",
      "ALAND": 71444631,
      "AWATER": 468304,
      "County_state": "Guaynabo,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.137958,
              18.2982
            ],
            [
              -66.143861,
              18.27959
            ],
            [
              -66.107787,
              18.277874
            ],
            [
              -66.086465,
              18.285094
            ],
            [
              -66.080475,
              18.297295
            ],
            [
              -66.085147,
              18.300507
            ],
            [
              -66.087582,
              18.334611
            ],
            [
              -66.099667,
              18.342625
            ],
            [
              -66.098203,
              18.349637
            ],
            [
              -66.088351,
              18.35714
            ],
            [
              -66.090042,
              18.375056
            ],
            [
              -66.106506,
              18.382683
            ],
            [
              -66.099813,
              18.412005
            ],
            [
              -66.097865,
              18.416424
            ],
            [
              -66.107805,
              18.427577
            ],
            [
              -66.108281,
              18.438902
            ],
            [
              -66.112391,
              18.440105
            ],
            [
              -66.116133,
              18.439628
            ],
            [
              -66.13158,
              18.424656
            ],
            [
              -66.121283,
              18.414388
            ],
            [
              -66.124067,
              18.3991
            ],
            [
              -66.118701,
              18.379253
            ],
            [
              -66.135185,
              18.382701
            ],
            [
              -66.134518,
              18.374579
            ],
            [
              -66.141645,
              18.361815
            ],
            [
              -66.133812,
              18.334678
            ],
            [
              -66.141783,
              18.322403
            ],
            [
              -66.140209,
              18.300577
            ],
            [
              -66.137958,
              18.2982
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "029",
      "COUNTYNS": "01804494",
      "AFFGEOID": "0500000US72029",
      "GEOID": "72029",
      "NAME": "CanÃ³vanas",
      "LSAD": "13",
      "ALAND": 85127784,
      "AWATER": 364681,
      "County_state": "CanÃ³vanas,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -65.93134,
              18.393679
            ],
            [
              -65.923708,
              18.385853
            ],
            [
              -65.913152,
              18.379291
            ],
            [
              -65.917097,
              18.367765
            ],
            [
              -65.915923,
              18.348092
            ],
            [
              -65.903141,
              18.320657
            ],
            [
              -65.915647,
              18.292416
            ],
            [
              -65.907258,
              18.272172
            ],
            [
              -65.918535,
              18.27013
            ],
            [
              -65.873997,
              18.252738
            ],
            [
              -65.852616,
              18.254972
            ],
            [
              -65.84643,
              18.258078
            ],
            [
              -65.836387,
              18.275246
            ],
            [
              -65.867875,
              18.293814
            ],
            [
              -65.862761,
              18.32149
            ],
            [
              -65.87671,
              18.361511
            ],
            [
              -65.867447,
              18.378198
            ],
            [
              -65.876502,
              18.378681
            ],
            [
              -65.869949,
              18.408915
            ],
            [
              -65.89993,
              18.413682
            ],
            [
              -65.919278,
              18.402993
            ],
            [
              -65.93134,
              18.393679
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "047",
      "COUNTYNS": "01804503",
      "AFFGEOID": "0500000US72047",
      "GEOID": "72047",
      "NAME": "Corozal",
      "LSAD": "13",
      "ALAND": 110248539,
      "AWATER": 16284,
      "County_state": "Corozal,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.377712,
              18.293976
            ],
            [
              -66.383033,
              18.275348
            ],
            [
              -66.350793,
              18.241235
            ],
            [
              -66.340121,
              18.238689
            ],
            [
              -66.313299,
              18.249324
            ],
            [
              -66.296086,
              18.257545
            ],
            [
              -66.278076,
              18.329975
            ],
            [
              -66.305719,
              18.369992
            ],
            [
              -66.318764,
              18.367411
            ],
            [
              -66.355278,
              18.360643
            ],
            [
              -66.348862,
              18.351605
            ],
            [
              -66.365272,
              18.333229
            ],
            [
              -66.37302,
              18.302763
            ],
            [
              -66.377712,
              18.293976
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "069",
      "COUNTYNS": "01804515",
      "AFFGEOID": "0500000US72069",
      "GEOID": "72069",
      "NAME": "Humacao",
      "LSAD": "13",
      "ALAND": 115929990,
      "AWATER": 68988203,
      "County_state": "Humacao,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -65.877615,
              18.118167
            ],
            [
              -65.870211,
              18.108426
            ],
            [
              -65.848117,
              18.117488
            ],
            [
              -65.848051,
              18.099164
            ],
            [
              -65.829242,
              18.084713
            ],
            [
              -65.7967555091572,
              18.0697433647407
            ],
            [
              -65.7950455506125,
              18.0735222147718
            ],
            [
              -65.795028,
              18.073561
            ],
            [
              -65.794686,
              18.078607
            ],
            [
              -65.7947706318306,
              18.0786718333674
            ],
            [
              -65.796289,
              18.079835
            ],
            [
              -65.796711,
              18.083746
            ],
            [
              -65.777584,
              18.129239
            ],
            [
              -65.766919,
              18.148424
            ],
            [
              -65.758728,
              18.156601
            ],
            [
              -65.743632,
              18.163957
            ],
            [
              -65.7391246723876,
              18.173453576664098
            ],
            [
              -65.766046,
              18.182096
            ],
            [
              -65.760504,
              18.195581
            ],
            [
              -65.792477,
              18.206759
            ],
            [
              -65.826063,
              18.200408
            ],
            [
              -65.836448,
              18.175243
            ],
            [
              -65.846571,
              18.17793
            ],
            [
              -65.854119,
              18.177276
            ],
            [
              -65.863074,
              18.140167
            ],
            [
              -65.877615,
              18.118167
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "015",
      "COUNTYNS": "01804487",
      "AFFGEOID": "0500000US72015",
      "GEOID": "72015",
      "NAME": "Arroyo",
      "LSAD": "13",
      "ALAND": 38869489,
      "AWATER": 53459916,
      "County_state": "Arroyo,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.082263,
              18.032958
            ],
            [
              -66.095209,
              18.022186
            ],
            [
              -66.07161,
              17.993732
            ],
            [
              -66.0699791737438,
              17.966357176775198
            ],
            [
              -66.068678,
              17.966335
            ],
            [
              -66.058217,
              17.959238
            ],
            [
              -66.0577482384095,
              17.9589992807101
            ],
            [
              -66.0491073758073,
              17.9545988762686
            ],
            [
              -66.049033,
              17.954561
            ],
            [
              -66.046585,
              17.954853
            ],
            [
              -66.024,
              17.975896
            ],
            [
              -66.0195388847422,
              17.978353879849898
            ],
            [
              -66.046927,
              18.009097
            ],
            [
              -66.043683,
              18.029445
            ],
            [
              -66.057263,
              18.040434
            ],
            [
              -66.082263,
              18.032958
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "019",
      "COUNTYNS": "01804489",
      "AFFGEOID": "0500000US72019",
      "GEOID": "72019",
      "NAME": "Barranquitas",
      "LSAD": "13",
      "ALAND": 88714041,
      "AWATER": 77812,
      "County_state": "Barranquitas,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.363734,
              18.200663
            ],
            [
              -66.373768,
              18.173694
            ],
            [
              -66.351258,
              18.165488
            ],
            [
              -66.322323,
              18.165503
            ],
            [
              -66.314848,
              18.152088
            ],
            [
              -66.277717,
              18.174194
            ],
            [
              -66.248172,
              18.165752
            ],
            [
              -66.242735,
              18.180248
            ],
            [
              -66.24046,
              18.184794
            ],
            [
              -66.263553,
              18.184959
            ],
            [
              -66.270262,
              18.219378
            ],
            [
              -66.265333,
              18.233545
            ],
            [
              -66.266466,
              18.24527
            ],
            [
              -66.293622,
              18.246491
            ],
            [
              -66.307073,
              18.241481
            ],
            [
              -66.313299,
              18.249324
            ],
            [
              -66.340121,
              18.238689
            ],
            [
              -66.350793,
              18.241235
            ],
            [
              -66.351654,
              18.194196
            ],
            [
              -66.363734,
              18.200663
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "077",
      "COUNTYNS": "01804519",
      "AFFGEOID": "0500000US72077",
      "GEOID": "72077",
      "NAME": "Juncos",
      "LSAD": "13",
      "ALAND": 68605410,
      "AWATER": 327694,
      "County_state": "Juncos,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -65.951019,
              18.229292
            ],
            [
              -65.951741,
              18.215919
            ],
            [
              -65.929404,
              18.197433
            ],
            [
              -65.929844,
              18.164113
            ],
            [
              -65.932008,
              18.1619
            ],
            [
              -65.928341,
              18.142845
            ],
            [
              -65.896106,
              18.20227
            ],
            [
              -65.874765,
              18.215914
            ],
            [
              -65.851934,
              18.248011
            ],
            [
              -65.852616,
              18.254972
            ],
            [
              -65.873997,
              18.252738
            ],
            [
              -65.918535,
              18.27013
            ],
            [
              -65.945842,
              18.252655
            ],
            [
              -65.939473,
              18.234118
            ],
            [
              -65.951019,
              18.229292
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "121",
      "COUNTYNS": "01804541",
      "AFFGEOID": "0500000US72121",
      "GEOID": "72121",
      "NAME": "Sabana Grande",
      "LSAD": "13",
      "ALAND": 94050249,
      "AWATER": 25323,
      "County_state": "Sabana Grande,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.984134,
              18.106966
            ],
            [
              -66.980602,
              18.078775
            ],
            [
              -66.983337,
              18.052518
            ],
            [
              -66.982675,
              18.041202
            ],
            [
              -66.958748,
              18.032477
            ],
            [
              -66.895216,
              18.023356
            ],
            [
              -66.884653,
              18.024816
            ],
            [
              -66.887484,
              18.034075
            ],
            [
              -66.901033,
              18.039854
            ],
            [
              -66.920696,
              18.103469
            ],
            [
              -66.911623,
              18.113522
            ],
            [
              -66.926517,
              18.151267
            ],
            [
              -66.971866,
              18.14154
            ],
            [
              -66.97554,
              18.143395
            ],
            [
              -66.977855,
              18.143799
            ],
            [
              -66.970369,
              18.131898
            ],
            [
              -66.984134,
              18.106966
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "045",
      "COUNTYNS": "01804502",
      "AFFGEOID": "0500000US72045",
      "GEOID": "72045",
      "NAME": "ComerÃ­o",
      "LSAD": "13",
      "ALAND": 73557184,
      "AWATER": 319738,
      "County_state": "ComerÃ­o,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.265333,
              18.233545
            ],
            [
              -66.270262,
              18.219378
            ],
            [
              -66.263553,
              18.184959
            ],
            [
              -66.24046,
              18.184794
            ],
            [
              -66.211851,
              18.173087
            ],
            [
              -66.2071,
              18.196933
            ],
            [
              -66.182483,
              18.202302
            ],
            [
              -66.169126,
              18.22429
            ],
            [
              -66.178251,
              18.246309
            ],
            [
              -66.190676,
              18.25884
            ],
            [
              -66.201669,
              18.276413
            ],
            [
              -66.206135,
              18.27666
            ],
            [
              -66.218205,
              18.27471
            ],
            [
              -66.227884,
              18.254206
            ],
            [
              -66.266466,
              18.24527
            ],
            [
              -66.265333,
              18.233545
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "095",
      "COUNTYNS": "01804528",
      "AFFGEOID": "0500000US72095",
      "GEOID": "72095",
      "NAME": "Maunabo",
      "LSAD": "13",
      "ALAND": 54816722,
      "AWATER": 44549509,
      "County_state": "Maunabo,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -65.988523,
              18.058941
            ],
            [
              -65.9819,
              18.03484
            ],
            [
              -65.952848,
              18.023111
            ],
            [
              -65.943123,
              17.992527
            ],
            [
              -65.91384489566289,
              17.980511219265
            ],
            [
              -65.905319,
              17.983974
            ],
            [
              -65.896102,
              17.99026
            ],
            [
              -65.8958744790137,
              17.990224562562002
            ],
            [
              -65.88627363906511,
              17.9887291876699
            ],
            [
              -65.884937,
              17.988521
            ],
            [
              -65.875122,
              18.002826
            ],
            [
              -65.870335,
              18.006597
            ],
            [
              -65.8509127553522,
              18.011954163499002
            ],
            [
              -65.865038,
              18.025769
            ],
            [
              -65.879255,
              18.020512
            ],
            [
              -65.907393,
              18.035898
            ],
            [
              -65.933874,
              18.03682
            ],
            [
              -65.97779,
              18.061093
            ],
            [
              -65.988523,
              18.058941
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "075",
      "COUNTYNS": "01804518",
      "AFFGEOID": "0500000US72075",
      "GEOID": "72075",
      "NAME": "Juana DÃ­az",
      "LSAD": "13",
      "ALAND": 156117100,
      "AWATER": 121328720,
      "County_state": "Juana DÃ­az,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.564561,
              18.139412
            ],
            [
              -66.561587,
              18.115293
            ],
            [
              -66.549059,
              18.095624
            ],
            [
              -66.55542,
              18.084516
            ],
            [
              -66.545258,
              18.068753
            ],
            [
              -66.546173,
              18.053798
            ],
            [
              -66.53734,
              18.041286
            ],
            [
              -66.542196,
              18.031038
            ],
            [
              -66.53042,
              18.003075
            ],
            [
              -66.541596,
              17.991605
            ],
            [
              -66.5405373162362,
              17.9754758878275
            ],
            [
              -66.510143,
              17.985618
            ],
            [
              -66.5099187251802,
              17.9856735572765
            ],
            [
              -66.4914604449615,
              17.9902460357177
            ],
            [
              -66.491396,
              17.990262
            ],
            [
              -66.4791995553355,
              17.990266463994498
            ],
            [
              -66.47902512479179,
              17.9902665278375
            ],
            [
              -66.47623926156929,
              17.9902675474853
            ],
            [
              -66.461342,
              17.990273
            ],
            [
              -66.454888,
              17.986784
            ],
            [
              -66.4503675981588,
              17.9832256311647
            ],
            [
              -66.421054,
              18.019309
            ],
            [
              -66.426989,
              18.042627
            ],
            [
              -66.424105,
              18.047972
            ],
            [
              -66.430064,
              18.076387
            ],
            [
              -66.430314,
              18.082053
            ],
            [
              -66.454634,
              18.08125
            ],
            [
              -66.492367,
              18.098185
            ],
            [
              -66.518244,
              18.08503
            ],
            [
              -66.530043,
              18.106944
            ],
            [
              -66.521899,
              18.151954
            ],
            [
              -66.544281,
              18.147619
            ],
            [
              -66.54722,
              18.15313
            ],
            [
              -66.552766,
              18.152057
            ],
            [
              -66.564561,
              18.139412
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "087",
      "COUNTYNS": "01804524",
      "AFFGEOID": "0500000US72087",
      "GEOID": "72087",
      "NAME": "LoÃ­za",
      "LSAD": "13",
      "ALAND": 50086873,
      "AWATER": 119822959,
      "County_state": "LoÃ­za,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -65.9926607734279,
              18.4586752873041
            ],
            [
              -65.9928101783015,
              18.458028970999898
            ],
            [
              -65.967213,
              18.432383
            ],
            [
              -65.961461,
              18.433368
            ],
            [
              -65.9304,
              18.428478
            ],
            [
              -65.919278,
              18.402993
            ],
            [
              -65.89993,
              18.413682
            ],
            [
              -65.869949,
              18.408915
            ],
            [
              -65.876502,
              18.378681
            ],
            [
              -65.867447,
              18.378198
            ],
            [
              -65.859688,
              18.382065
            ],
            [
              -65.852538,
              18.4017
            ],
            [
              -65.82845652722071,
              18.4235434074125
            ],
            [
              -65.831476,
              18.426849
            ],
            [
              -65.838825,
              18.431865
            ],
            [
              -65.83890779632279,
              18.431878413012598
            ],
            [
              -65.84727288661641,
              18.433233558479596
            ],
            [
              -65.878683,
              18.438322
            ],
            [
              -65.904988,
              18.450926
            ],
            [
              -65.907756,
              18.446893
            ],
            [
              -65.916843,
              18.444619
            ],
            [
              -65.92567,
              18.444881
            ],
            [
              -65.958492,
              18.451354
            ],
            [
              -65.99079,
              18.460419
            ],
            [
              -65.992349,
              18.460024
            ],
            [
              -65.9926607734279,
              18.4586752873041
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "023",
      "COUNTYNS": "01804491",
      "AFFGEOID": "0500000US72023",
      "GEOID": "72023",
      "NAME": "Cabo Rojo",
      "LSAD": "13",
      "ALAND": 182264459,
      "AWATER": 277228652,
      "County_state": "Cabo Rojo,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -67.215271,
              17.983464
            ],
            [
              -67.21433,
              17.962436
            ],
            [
              -67.212101,
              17.956027
            ],
            [
              -67.2115814743772,
              17.952978817768297
            ],
            [
              -67.211521,
              17.952624
            ],
            [
              -67.203714,
              17.945517
            ],
            [
              -67.196924,
              17.935651
            ],
            [
              -67.1954579244092,
              17.933714738408597
            ],
            [
              -67.194785,
              17.932826
            ],
            [
              -67.183457,
              17.931135
            ],
            [
              -67.182488,
              17.937754
            ],
            [
              -67.187474,
              17.946252
            ],
            [
              -67.188717,
              17.950989
            ],
            [
              -67.183508,
              17.962706
            ],
            [
              -67.178566,
              17.964792
            ],
            [
              -67.167031,
              17.963073
            ],
            [
              -67.133733,
              17.951919
            ],
            [
              -67.128251,
              17.948153
            ],
            [
              -67.1099859168433,
              17.945806117805297
            ],
            [
              -67.102278,
              17.974957
            ],
            [
              -67.110134,
              18.015868
            ],
            [
              -67.115852,
              18.033366
            ],
            [
              -67.109044,
              18.056085
            ],
            [
              -67.09912,
              18.067358
            ],
            [
              -67.105566,
              18.073861
            ],
            [
              -67.100492,
              18.106395
            ],
            [
              -67.126157,
              18.115143
            ],
            [
              -67.133754,
              18.131867
            ],
            [
              -67.159733,
              18.153227
            ],
            [
              -67.1622,
              18.152484
            ],
            [
              -67.1807014673381,
              18.1681816869411
            ],
            [
              -67.180822,
              18.168055
            ],
            [
              -67.178618,
              18.159318
            ],
            [
              -67.176554,
              18.151046
            ],
            [
              -67.182182,
              18.108507
            ],
            [
              -67.183921,
              18.103683
            ],
            [
              -67.19529,
              18.096149
            ],
            [
              -67.199314,
              18.091135
            ],
            [
              -67.198212,
              18.076828
            ],
            [
              -67.196694,
              18.066491
            ],
            [
              -67.209887,
              18.035439
            ],
            [
              -67.193269,
              18.03185
            ],
            [
              -67.173761,
              18.024548
            ],
            [
              -67.172138,
              18.021422
            ],
            [
              -67.172397,
              18.014906
            ],
            [
              -67.174299,
              18.011149
            ],
            [
              -67.177893,
              18.008882
            ],
            [
              -67.207694,
              17.998019
            ],
            [
              -67.211973,
              17.992993
            ],
            [
              -67.215271,
              17.983464
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "105",
      "COUNTYNS": "01804533",
      "AFFGEOID": "0500000US72105",
      "GEOID": "72105",
      "NAME": "Naranjito",
      "LSAD": "13",
      "ALAND": 70969180,
      "AWATER": 898339,
      "County_state": "Naranjito,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.313299,
              18.249324
            ],
            [
              -66.307073,
              18.241481
            ],
            [
              -66.293622,
              18.246491
            ],
            [
              -66.266466,
              18.24527
            ],
            [
              -66.227884,
              18.254206
            ],
            [
              -66.218205,
              18.27471
            ],
            [
              -66.206135,
              18.27666
            ],
            [
              -66.213537,
              18.306283
            ],
            [
              -66.204734,
              18.316035
            ],
            [
              -66.217179,
              18.334194
            ],
            [
              -66.233009,
              18.324547
            ],
            [
              -66.258509,
              18.331909
            ],
            [
              -66.278076,
              18.329975
            ],
            [
              -66.296086,
              18.257545
            ],
            [
              -66.313299,
              18.249324
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "067",
      "COUNTYNS": "01804514",
      "AFFGEOID": "0500000US72067",
      "GEOID": "72067",
      "NAME": "Hormigueros",
      "LSAD": "13",
      "ALAND": 29384893,
      "AWATER": 481,
      "County_state": "Hormigueros,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -67.159733,
              18.153227
            ],
            [
              -67.133754,
              18.131867
            ],
            [
              -67.126157,
              18.115143
            ],
            [
              -67.100492,
              18.106395
            ],
            [
              -67.08105,
              18.106715
            ],
            [
              -67.07953,
              18.116996
            ],
            [
              -67.092119,
              18.125369
            ],
            [
              -67.093914,
              18.142307
            ],
            [
              -67.086359,
              18.149829
            ],
            [
              -67.098382,
              18.15855
            ],
            [
              -67.159733,
              18.153227
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "057",
      "COUNTYNS": "01804509",
      "AFFGEOID": "0500000US72057",
      "GEOID": "72057",
      "NAME": "Guayama",
      "LSAD": "13",
      "ALAND": 168328028,
      "AWATER": 108327763,
      "County_state": "Guayama,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.222513,
              17.942076
            ],
            [
              -66.225359,
              17.925402
            ],
            [
              -66.2188794436979,
              17.9156773822481
            ],
            [
              -66.209438,
              17.921237
            ],
            [
              -66.2048620646808,
              17.924503041465897
            ],
            [
              -66.197068,
              17.930066
            ],
            [
              -66.1934,
              17.936787
            ],
            [
              -66.18679539783821,
              17.9362373780371
            ],
            [
              -66.183282,
              17.935945
            ],
            [
              -66.174969,
              17.935733
            ],
            [
              -66.16757,
              17.933485
            ],
            [
              -66.158801,
              17.92984
            ],
            [
              -66.155387,
              17.929406
            ],
            [
              -66.147912,
              17.933963
            ],
            [
              -66.135033,
              17.941372
            ],
            [
              -66.127009,
              17.946953
            ],
            [
              -66.116194,
              17.949141
            ],
            [
              -66.08141,
              17.966552
            ],
            [
              -66.0699791737438,
              17.966357176775198
            ],
            [
              -66.07161,
              17.993732
            ],
            [
              -66.095209,
              18.022186
            ],
            [
              -66.082263,
              18.032958
            ],
            [
              -66.089399,
              18.056256
            ],
            [
              -66.06832,
              18.078723
            ],
            [
              -66.053146,
              18.107293
            ],
            [
              -66.068571,
              18.101828
            ],
            [
              -66.10257,
              18.105032
            ],
            [
              -66.112707,
              18.090159
            ],
            [
              -66.129031,
              18.088637
            ],
            [
              -66.119815,
              18.062899
            ],
            [
              -66.138987,
              18.036917
            ],
            [
              -66.166145,
              18.050063
            ],
            [
              -66.18051,
              18.025069
            ],
            [
              -66.205592,
              18.01298
            ],
            [
              -66.20621,
              17.962893
            ],
            [
              -66.222513,
              17.942076
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "145",
      "COUNTYNS": "01804553",
      "AFFGEOID": "0500000US72145",
      "GEOID": "72145",
      "NAME": "Vega Baja",
      "LSAD": "13",
      "ALAND": 118777657,
      "AWATER": 57795017,
      "County_state": "Vega Baja,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.448989,
              18.387214
            ],
            [
              -66.4438,
              18.371027
            ],
            [
              -66.421864,
              18.377808
            ],
            [
              -66.400998,
              18.363707
            ],
            [
              -66.395499,
              18.371756
            ],
            [
              -66.377058,
              18.344246
            ],
            [
              -66.373243,
              18.371937
            ],
            [
              -66.364284,
              18.389816
            ],
            [
              -66.362875,
              18.395421
            ],
            [
              -66.358898,
              18.421032
            ],
            [
              -66.347835,
              18.42886
            ],
            [
              -66.3496472039647,
              18.4863353152727
            ],
            [
              -66.37282,
              18.487726
            ],
            [
              -66.377286,
              18.488044
            ],
            [
              -66.3784808271801,
              18.4881637568093
            ],
            [
              -66.394287,
              18.489748
            ],
            [
              -66.3945782401828,
              18.4897505030295
            ],
            [
              -66.410344,
              18.489886
            ],
            [
              -66.420921,
              18.488639
            ],
            [
              -66.4388131518907,
              18.4857127310406
            ],
            [
              -66.439494,
              18.462245
            ],
            [
              -66.439413,
              18.410366
            ],
            [
              -66.448989,
              18.387214
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "149",
      "COUNTYNS": "01804555",
      "AFFGEOID": "0500000US72149",
      "GEOID": "72149",
      "NAME": "Villalba",
      "LSAD": "13",
      "ALAND": 92298569,
      "AWATER": 3622637,
      "County_state": "Villalba,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.521899,
              18.151954
            ],
            [
              -66.530043,
              18.106944
            ],
            [
              -66.518244,
              18.08503
            ],
            [
              -66.492367,
              18.098185
            ],
            [
              -66.454634,
              18.08125
            ],
            [
              -66.430314,
              18.082053
            ],
            [
              -66.421379,
              18.098589
            ],
            [
              -66.430494,
              18.116403
            ],
            [
              -66.419368,
              18.145304
            ],
            [
              -66.434573,
              18.173537
            ],
            [
              -66.438561,
              18.172963
            ],
            [
              -66.44456,
              18.176674
            ],
            [
              -66.470545,
              18.177829
            ],
            [
              -66.521899,
              18.151954
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "137",
      "COUNTYNS": "01804549",
      "AFFGEOID": "0500000US72137",
      "GEOID": "72137",
      "NAME": "Toa Baja",
      "LSAD": "13",
      "ALAND": 60201690,
      "AWATER": 48075984,
      "County_state": "Toa Baja,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.2581,
              18.454976
            ],
            [
              -66.264011,
              18.427967
            ],
            [
              -66.252554,
              18.394187
            ],
            [
              -66.233917,
              18.401281
            ],
            [
              -66.19698,
              18.389289
            ],
            [
              -66.198013,
              18.414626
            ],
            [
              -66.187556,
              18.41974
            ],
            [
              -66.172653,
              18.42105
            ],
            [
              -66.169516,
              18.430998
            ],
            [
              -66.161216,
              18.453407
            ],
            [
              -66.172051,
              18.458524
            ],
            [
              -66.182076,
              18.463469
            ],
            [
              -66.18549930891639,
              18.4680837618906
            ],
            [
              -66.186722,
              18.469732
            ],
            [
              -66.193919,
              18.467251
            ],
            [
              -66.1989303211706,
              18.466657825366198
            ],
            [
              -66.230532,
              18.454392
            ],
            [
              -66.2581,
              18.454976
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "125",
      "COUNTYNS": "01804543",
      "AFFGEOID": "0500000US72125",
      "GEOID": "72125",
      "NAME": "San GermÃ¡n",
      "LSAD": "13",
      "ALAND": 141145018,
      "AWATER": 29360,
      "County_state": "San GermÃ¡n,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -67.09912,
              18.067358
            ],
            [
              -67.109044,
              18.056085
            ],
            [
              -67.067912,
              18.066324
            ],
            [
              -67.03619,
              18.057817
            ],
            [
              -67.00787,
              18.060227
            ],
            [
              -66.983337,
              18.052518
            ],
            [
              -66.980602,
              18.078775
            ],
            [
              -66.984134,
              18.106966
            ],
            [
              -66.970369,
              18.131898
            ],
            [
              -66.977855,
              18.143799
            ],
            [
              -67.013844,
              18.165874
            ],
            [
              -67.051461,
              18.174053
            ],
            [
              -67.082533,
              18.167734
            ],
            [
              -67.086359,
              18.149829
            ],
            [
              -67.093914,
              18.142307
            ],
            [
              -67.092119,
              18.125369
            ],
            [
              -67.07953,
              18.116996
            ],
            [
              -67.08105,
              18.106715
            ],
            [
              -67.100492,
              18.106395
            ],
            [
              -67.105566,
              18.073861
            ],
            [
              -67.09912,
              18.067358
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "119",
      "COUNTYNS": "01804540",
      "AFFGEOID": "0500000US72119",
      "GEOID": "72119",
      "NAME": "RÃ­o Grande",
      "LSAD": "13",
      "ALAND": 157008580,
      "AWATER": 74876344,
      "County_state": "RÃ­o Grande,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -65.867447,
              18.378198
            ],
            [
              -65.87671,
              18.361511
            ],
            [
              -65.862761,
              18.32149
            ],
            [
              -65.867875,
              18.293814
            ],
            [
              -65.836387,
              18.275246
            ],
            [
              -65.82422,
              18.273054
            ],
            [
              -65.811424,
              18.290141
            ],
            [
              -65.795542,
              18.289512
            ],
            [
              -65.793223,
              18.296529
            ],
            [
              -65.784859,
              18.294
            ],
            [
              -65.766831,
              18.280004
            ],
            [
              -65.758861,
              18.290604
            ],
            [
              -65.762494,
              18.306094
            ],
            [
              -65.753513,
              18.341538
            ],
            [
              -65.770335,
              18.366244
            ],
            [
              -65.7501794863426,
              18.3850503787063
            ],
            [
              -65.750455,
              18.385208
            ],
            [
              -65.76417035092189,
              18.3988129307238
            ],
            [
              -65.771695,
              18.406277
            ],
            [
              -65.769749,
              18.409473
            ],
            [
              -65.77053,
              18.41294
            ],
            [
              -65.774937,
              18.413951
            ],
            [
              -65.77510499637,
              18.4139325581612
            ],
            [
              -65.784502,
              18.412901
            ],
            [
              -65.788496,
              18.418518
            ],
            [
              -65.78906114417761,
              18.4191224695862
            ],
            [
              -65.79206,
              18.42233
            ],
            [
              -65.805793,
              18.410796
            ],
            [
              -65.816691,
              18.410663
            ],
            [
              -65.82845652722071,
              18.4235434074125
            ],
            [
              -65.852538,
              18.4017
            ],
            [
              -65.859688,
              18.382065
            ],
            [
              -65.867447,
              18.378198
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "113",
      "COUNTYNS": "01804537",
      "AFFGEOID": "0500000US72113",
      "GEOID": "72113",
      "NAME": "Ponce",
      "LSAD": "13",
      "ALAND": 297688774,
      "AWATER": 203748398,
      "County_state": "Ponce,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.533305,
              17.894142
            ],
            [
              -66.532117,
              17.878309
            ],
            [
              -66.517856,
              17.882833
            ],
            [
              -66.505972,
              17.893011
            ],
            [
              -66.505972,
              17.9049434880462
            ],
            [
              -66.505972,
              17.906582
            ],
            [
              -66.517856,
              17.907713
            ],
            [
              -66.533305,
              17.894142
            ]
          ]
        ],
        [
          [
            [
              -66.686768,
              17.991486
            ],
            [
              -66.69911511688659,
              17.9775681152688
            ],
            [
              -66.672819,
              17.966451
            ],
            [
              -66.664391,
              17.968259
            ],
            [
              -66.6629388383594,
              17.969656545916102
            ],
            [
              -66.657797,
              17.974605
            ],
            [
              -66.645651,
              17.98026
            ],
            [
              -66.631944,
              17.982746
            ],
            [
              -66.623788,
              17.98105
            ],
            [
              -66.605035,
              17.969015
            ],
            [
              -66.594392,
              17.970682
            ],
            [
              -66.589658,
              17.969386
            ],
            [
              -66.58943147259119,
              17.969098407148
            ],
            [
              -66.5842103807569,
              17.9624698552271
            ],
            [
              -66.583233,
              17.961229
            ],
            [
              -66.58070813424371,
              17.9620715085638
            ],
            [
              -66.5405373162362,
              17.9754758878275
            ],
            [
              -66.541596,
              17.991605
            ],
            [
              -66.53042,
              18.003075
            ],
            [
              -66.542196,
              18.031038
            ],
            [
              -66.53734,
              18.041286
            ],
            [
              -66.546173,
              18.053798
            ],
            [
              -66.545258,
              18.068753
            ],
            [
              -66.55542,
              18.084516
            ],
            [
              -66.549059,
              18.095624
            ],
            [
              -66.561587,
              18.115293
            ],
            [
              -66.564561,
              18.139412
            ],
            [
              -66.552766,
              18.152057
            ],
            [
              -66.591005,
              18.172479
            ],
            [
              -66.636604,
              18.151677
            ],
            [
              -66.649467,
              18.158857
            ],
            [
              -66.653183,
              18.159149
            ],
            [
              -66.670494,
              18.152831
            ],
            [
              -66.676951,
              18.129977
            ],
            [
              -66.69407,
              18.130534
            ],
            [
              -66.678087,
              18.1007
            ],
            [
              -66.680353,
              18.077052
            ],
            [
              -66.689446,
              18.040257
            ],
            [
              -66.686768,
              17.991486
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "079",
      "COUNTYNS": "01804520",
      "AFFGEOID": "0500000US72079",
      "GEOID": "72079",
      "NAME": "Lajas",
      "LSAD": "13",
      "ALAND": 155287823,
      "AWATER": 106643202,
      "County_state": "Lajas,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -67.115852,
              18.033366
            ],
            [
              -67.110134,
              18.015868
            ],
            [
              -67.102278,
              17.974957
            ],
            [
              -67.1099859168433,
              17.945806117805297
            ],
            [
              -67.109985,
              17.945806
            ],
            [
              -67.1017314216045,
              17.9465957929309
            ],
            [
              -67.101468,
              17.946621
            ],
            [
              -67.089827,
              17.951418
            ],
            [
              -67.0826494523927,
              17.9602413134319
            ],
            [
              -67.076534,
              17.967759
            ],
            [
              -67.062478,
              17.973819
            ],
            [
              -67.024522,
              17.970722
            ],
            [
              -67.014744,
              17.968468
            ],
            [
              -67.004703,
              17.960818
            ],
            [
              -67.001405922887,
              17.9639069336146
            ],
            [
              -66.996862,
              17.968164
            ],
            [
              -66.986085,
              17.967337
            ],
            [
              -66.9849260736644,
              17.9655010623016
            ],
            [
              -66.982206,
              17.961192
            ],
            [
              -66.982669,
              17.9551
            ],
            [
              -66.9810503126819,
              17.952504687124
            ],
            [
              -66.961935,
              17.991592
            ],
            [
              -66.958748,
              18.032477
            ],
            [
              -66.982675,
              18.041202
            ],
            [
              -66.983337,
              18.052518
            ],
            [
              -67.00787,
              18.060227
            ],
            [
              -67.03619,
              18.057817
            ],
            [
              -67.067912,
              18.066324
            ],
            [
              -67.109044,
              18.056085
            ],
            [
              -67.115852,
              18.033366
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "131",
      "COUNTYNS": "01804546",
      "AFFGEOID": "0500000US72131",
      "GEOID": "72131",
      "NAME": "San SebastiÃ¡n",
      "LSAD": "13",
      "ALAND": 182394904,
      "AWATER": 2060666,
      "County_state": "San SebastiÃ¡n,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -67.052583,
              18.306655
            ],
            [
              -67.040709,
              18.300302
            ],
            [
              -67.037935,
              18.289705
            ],
            [
              -67.03819,
              18.28047
            ],
            [
              -67.005921,
              18.279157
            ],
            [
              -66.946388,
              18.268764
            ],
            [
              -66.93839,
              18.258788
            ],
            [
              -66.907236,
              18.25309
            ],
            [
              -66.909969,
              18.302811
            ],
            [
              -66.893339,
              18.367089
            ],
            [
              -66.8989,
              18.366108
            ],
            [
              -66.903952,
              18.365949
            ],
            [
              -66.919497,
              18.378578
            ],
            [
              -66.92202,
              18.393204
            ],
            [
              -66.936594,
              18.376985
            ],
            [
              -67.009142,
              18.395125
            ],
            [
              -67.027173,
              18.393376
            ],
            [
              -67.042023,
              18.355805
            ],
            [
              -67.047405,
              18.350859
            ],
            [
              -67.035829,
              18.329371
            ],
            [
              -67.052583,
              18.306655
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "141",
      "COUNTYNS": "01804551",
      "AFFGEOID": "0500000US72141",
      "GEOID": "72141",
      "NAME": "Utuado",
      "LSAD": "13",
      "ALAND": 294045388,
      "AWATER": 3982230,
      "County_state": "Utuado,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.829558,
              18.287745
            ],
            [
              -66.827209,
              18.244096
            ],
            [
              -66.825706,
              18.244294
            ],
            [
              -66.817558,
              18.235424
            ],
            [
              -66.817271,
              18.230336
            ],
            [
              -66.798759,
              18.230026
            ],
            [
              -66.794506,
              18.249871
            ],
            [
              -66.781701,
              18.250248
            ],
            [
              -66.764697,
              18.237676
            ],
            [
              -66.760626,
              18.225895
            ],
            [
              -66.739358,
              18.220517
            ],
            [
              -66.732181,
              18.225759
            ],
            [
              -66.707725,
              18.216701
            ],
            [
              -66.69373,
              18.216891
            ],
            [
              -66.68132,
              18.204165
            ],
            [
              -66.681696,
              18.18761
            ],
            [
              -66.664697,
              18.175465
            ],
            [
              -66.67699,
              18.150955
            ],
            [
              -66.670494,
              18.152831
            ],
            [
              -66.653183,
              18.159149
            ],
            [
              -66.649467,
              18.158857
            ],
            [
              -66.65455,
              18.169591
            ],
            [
              -66.628431,
              18.214298
            ],
            [
              -66.625141,
              18.246688
            ],
            [
              -66.606736,
              18.23848
            ],
            [
              -66.597475,
              18.284104
            ],
            [
              -66.578757,
              18.284049
            ],
            [
              -66.56662,
              18.298549
            ],
            [
              -66.585269,
              18.322689
            ],
            [
              -66.610783,
              18.322649
            ],
            [
              -66.606989,
              18.328186
            ],
            [
              -66.671621,
              18.324522
            ],
            [
              -66.717302,
              18.315681
            ],
            [
              -66.719761,
              18.336244
            ],
            [
              -66.735947,
              18.349675
            ],
            [
              -66.770072,
              18.325013
            ],
            [
              -66.770363,
              18.320645
            ],
            [
              -66.826128,
              18.323382
            ],
            [
              -66.829558,
              18.287745
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "003",
      "COUNTYNS": "01804481",
      "AFFGEOID": "0500000US72003",
      "GEOID": "72003",
      "NAME": "Aguada",
      "LSAD": "13",
      "ALAND": 79923633,
      "AWATER": 38025989,
      "County_state": "Aguada,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -67.23909007224889,
              18.375317596233
            ],
            [
              -67.227336,
              18.348766
            ],
            [
              -67.195508,
              18.330309
            ],
            [
              -67.182852,
              18.313026
            ],
            [
              -67.145774,
              18.322665
            ],
            [
              -67.130167,
              18.317927
            ],
            [
              -67.134515,
              18.346514
            ],
            [
              -67.126651,
              18.371447
            ],
            [
              -67.13221,
              18.389391
            ],
            [
              -67.15656,
              18.393978
            ],
            [
              -67.1601443257786,
              18.41558726146
            ],
            [
              -67.1910079394156,
              18.3967270908766
            ],
            [
              -67.2015152380097,
              18.390306279425698
            ],
            [
              -67.202167,
              18.389908
            ],
            [
              -67.216998,
              18.382078
            ],
            [
              -67.226744,
              18.378247
            ],
            [
              -67.23909007224889,
              18.375317596233
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "001",
      "COUNTYNS": "01804480",
      "AFFGEOID": "0500000US72001",
      "GEOID": "72001",
      "NAME": "Adjuntas",
      "LSAD": "13",
      "ALAND": 172725728,
      "AWATER": 1051790,
      "County_state": "Adjuntas,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.832696,
              18.229099
            ],
            [
              -66.836301,
              18.185784
            ],
            [
              -66.827327,
              18.171242
            ],
            [
              -66.826278,
              18.160216
            ],
            [
              -66.804058,
              18.148056
            ],
            [
              -66.797557,
              18.133445
            ],
            [
              -66.7844,
              18.138652
            ],
            [
              -66.769049,
              18.134983
            ],
            [
              -66.749618,
              18.113664
            ],
            [
              -66.727344,
              18.11304
            ],
            [
              -66.713595,
              18.132105
            ],
            [
              -66.69407,
              18.130534
            ],
            [
              -66.676951,
              18.129977
            ],
            [
              -66.670494,
              18.152831
            ],
            [
              -66.67699,
              18.150955
            ],
            [
              -66.664697,
              18.175465
            ],
            [
              -66.681696,
              18.18761
            ],
            [
              -66.68132,
              18.204165
            ],
            [
              -66.69373,
              18.216891
            ],
            [
              -66.707725,
              18.216701
            ],
            [
              -66.732181,
              18.225759
            ],
            [
              -66.739358,
              18.220517
            ],
            [
              -66.760626,
              18.225895
            ],
            [
              -66.764697,
              18.237676
            ],
            [
              -66.781701,
              18.250248
            ],
            [
              -66.794506,
              18.249871
            ],
            [
              -66.798759,
              18.230026
            ],
            [
              -66.817271,
              18.230336
            ],
            [
              -66.832696,
              18.229099
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "007",
      "COUNTYNS": "01804483",
      "AFFGEOID": "0500000US72007",
      "GEOID": "72007",
      "NAME": "Aguas Buenas",
      "LSAD": "13",
      "ALAND": 77919490,
      "AWATER": 28383,
      "County_state": "Aguas Buenas,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.190676,
              18.25884
            ],
            [
              -66.178251,
              18.246309
            ],
            [
              -66.169126,
              18.22429
            ],
            [
              -66.143941,
              18.228111
            ],
            [
              -66.141065,
              18.197906
            ],
            [
              -66.117774,
              18.209949
            ],
            [
              -66.090193,
              18.2276
            ],
            [
              -66.077313,
              18.25651
            ],
            [
              -66.066103,
              18.303321
            ],
            [
              -66.080475,
              18.297295
            ],
            [
              -66.086465,
              18.285094
            ],
            [
              -66.107787,
              18.277874
            ],
            [
              -66.143861,
              18.27959
            ],
            [
              -66.1616,
              18.282538
            ],
            [
              -66.190676,
              18.25884
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "107",
      "COUNTYNS": "01804534",
      "AFFGEOID": "0500000US72107",
      "GEOID": "72107",
      "NAME": "Orocovis",
      "LSAD": "13",
      "ALAND": 164788358,
      "AWATER": 402899,
      "County_state": "Orocovis,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.54722,
              18.15313
            ],
            [
              -66.544281,
              18.147619
            ],
            [
              -66.521899,
              18.151954
            ],
            [
              -66.470545,
              18.177829
            ],
            [
              -66.44456,
              18.176674
            ],
            [
              -66.423649,
              18.172025
            ],
            [
              -66.413686,
              18.163337
            ],
            [
              -66.373768,
              18.173694
            ],
            [
              -66.363734,
              18.200663
            ],
            [
              -66.351654,
              18.194196
            ],
            [
              -66.350793,
              18.241235
            ],
            [
              -66.383033,
              18.275348
            ],
            [
              -66.377712,
              18.293976
            ],
            [
              -66.390957,
              18.273392
            ],
            [
              -66.409431,
              18.26331
            ],
            [
              -66.455703,
              18.257726
            ],
            [
              -66.466969,
              18.245667
            ],
            [
              -66.501065,
              18.256748
            ],
            [
              -66.517712,
              18.225934
            ],
            [
              -66.51105,
              18.178101
            ],
            [
              -66.531556,
              18.155679
            ],
            [
              -66.543133,
              18.163098
            ],
            [
              -66.54722,
              18.15313
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "037",
      "COUNTYNS": "01804498",
      "AFFGEOID": "0500000US72037",
      "GEOID": "72037",
      "NAME": "Ceiba",
      "LSAD": "13",
      "ALAND": 75201651,
      "AWATER": 336178483,
      "County_state": "Ceiba,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -65.758861,
              18.290604
            ],
            [
              -65.766831,
              18.280004
            ],
            [
              -65.749618,
              18.262803
            ],
            [
              -65.721918,
              18.252203
            ],
            [
              -65.702403,
              18.259473
            ],
            [
              -65.682893,
              18.252634
            ],
            [
              -65.693028,
              18.239018
            ],
            [
              -65.657799,
              18.222497
            ],
            [
              -65.66248822575669,
              18.2067291754019
            ],
            [
              -65.635281,
              18.199975
            ],
            [
              -65.628414,
              18.205149
            ],
            [
              -65.637565,
              18.224444
            ],
            [
              -65.637566570278,
              18.224455922386998
            ],
            [
              -65.6375674755836,
              18.2244627959493
            ],
            [
              -65.638181,
              18.229121
            ],
            [
              -65.617129,
              18.22741
            ],
            [
              -65.6105334703828,
              18.211640244651
            ],
            [
              -65.610236,
              18.210929
            ],
            [
              -65.599065,
              18.212961
            ],
            [
              -65.589083,
              18.228539
            ],
            [
              -65.574845,
              18.249676
            ],
            [
              -65.604294,
              18.25969
            ],
            [
              -65.60494797079339,
              18.2602701166958
            ],
            [
              -65.6067050597691,
              18.261828774454198
            ],
            [
              -65.622358,
              18.275714
            ],
            [
              -65.6232776456455,
              18.2885011936473
            ],
            [
              -65.634367,
              18.283584
            ],
            [
              -65.66613,
              18.288044
            ],
            [
              -65.682174,
              18.275069
            ],
            [
              -65.695013,
              18.28568
            ],
            [
              -65.699892,
              18.273079
            ],
            [
              -65.71751,
              18.267744
            ],
            [
              -65.737739,
              18.273881
            ],
            [
              -65.753591,
              18.296755
            ],
            [
              -65.758861,
              18.290604
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "039",
      "COUNTYNS": "01804499",
      "AFFGEOID": "0500000US72039",
      "GEOID": "72039",
      "NAME": "Ciales",
      "LSAD": "13",
      "ALAND": 172310206,
      "AWATER": 459605,
      "County_state": "Ciales,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.606989,
              18.328186
            ],
            [
              -66.610783,
              18.322649
            ],
            [
              -66.585269,
              18.322689
            ],
            [
              -66.56662,
              18.298549
            ],
            [
              -66.554703,
              18.281583
            ],
            [
              -66.564456,
              18.242647
            ],
            [
              -66.548883,
              18.220567
            ],
            [
              -66.532725,
              18.200519
            ],
            [
              -66.531023,
              18.180137
            ],
            [
              -66.543133,
              18.163098
            ],
            [
              -66.531556,
              18.155679
            ],
            [
              -66.51105,
              18.178101
            ],
            [
              -66.517712,
              18.225934
            ],
            [
              -66.501065,
              18.256748
            ],
            [
              -66.466969,
              18.245667
            ],
            [
              -66.455703,
              18.257726
            ],
            [
              -66.464883,
              18.28324
            ],
            [
              -66.453353,
              18.30989
            ],
            [
              -66.454289,
              18.332654
            ],
            [
              -66.465142,
              18.352237
            ],
            [
              -66.463211,
              18.371473
            ],
            [
              -66.473346,
              18.367604
            ],
            [
              -66.48343,
              18.36604
            ],
            [
              -66.511776,
              18.362003
            ],
            [
              -66.529283,
              18.350255
            ],
            [
              -66.533623,
              18.351333
            ],
            [
              -66.539636,
              18.344405
            ],
            [
              -66.590785,
              18.33806
            ],
            [
              -66.600229,
              18.339603
            ],
            [
              -66.606989,
              18.328186
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "055",
      "COUNTYNS": "01804508",
      "AFFGEOID": "0500000US72055",
      "GEOID": "72055",
      "NAME": "GuÃ¡nica",
      "LSAD": "13",
      "ALAND": 95959910,
      "AWATER": 109853315,
      "County_state": "GuÃ¡nica,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.9810503126819,
              17.952504687124
            ],
            [
              -66.980516,
              17.951648
            ],
            [
              -66.968101,
              17.947251
            ],
            [
              -66.959998,
              17.940216
            ],
            [
              -66.955577,
              17.931557
            ],
            [
              -66.941741,
              17.932961
            ],
            [
              -66.927261,
              17.926875
            ],
            [
              -66.923826,
              17.926923
            ],
            [
              -66.919298,
              17.932062
            ],
            [
              -66.91922644070719,
              17.932224465785197
            ],
            [
              -66.91252239662009,
              17.947445099527197
            ],
            [
              -66.912522,
              17.947446
            ],
            [
              -66.909359,
              17.94988
            ],
            [
              -66.9092387324054,
              17.9498962993577
            ],
            [
              -66.904585,
              17.950527
            ],
            [
              -66.9036659646334,
              17.9501128208993
            ],
            [
              -66.899639,
              17.948298
            ],
            [
              -66.88344,
              17.952526
            ],
            [
              -66.87929,
              17.951574
            ],
            [
              -66.877307,
              17.94793
            ],
            [
              -66.863533,
              17.944667
            ],
            [
              -66.862545,
              17.952022
            ],
            [
              -66.8594708563219,
              17.954316341130898
            ],
            [
              -66.863219,
              17.998029
            ],
            [
              -66.884653,
              18.024816
            ],
            [
              -66.895216,
              18.023356
            ],
            [
              -66.958748,
              18.032477
            ],
            [
              -66.961935,
              17.991592
            ],
            [
              -66.9810503126819,
              17.952504687124
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "049",
      "COUNTYNS": "01804504",
      "AFFGEOID": "0500000US72049",
      "GEOID": "72049",
      "NAME": "Culebra",
      "LSAD": "13",
      "ALAND": 30115058,
      "AWATER": 407158805,
      "County_state": "Culebra,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -65.3374982181209,
              18.3483367604958
            ],
            [
              -65.342068,
              18.34529
            ],
            [
              -65.327318,
              18.323666
            ],
            [
              -65.3273338560502,
              18.323641967904898
            ],
            [
              -65.337451,
              18.308308
            ],
            [
              -65.3277,
              18.295843
            ],
            [
              -65.2863817971239,
              18.2813089541142
            ],
            [
              -65.2832878950941,
              18.2802206465177
            ],
            [
              -65.283269,
              18.280214
            ],
            [
              -65.2628625935527,
              18.289632
            ],
            [
              -65.260282,
              18.290823
            ],
            [
              -65.2549996963343,
              18.2937962909266
            ],
            [
              -65.249857,
              18.296691
            ],
            [
              -65.222853,
              18.310464
            ],
            [
              -65.221568,
              18.320959
            ],
            [
              -65.255933,
              18.342117
            ],
            [
              -65.2698346446608,
              18.3383172061958
            ],
            [
              -65.2903781390053,
              18.3327019682558
            ],
            [
              -65.298328,
              18.330529
            ],
            [
              -65.304409,
              18.332054
            ],
            [
              -65.3098011279993,
              18.3379382193267
            ],
            [
              -65.309833,
              18.337973
            ],
            [
              -65.321754,
              18.338316
            ],
            [
              -65.329334,
              18.341955
            ],
            [
              -65.335701,
              18.349535
            ],
            [
              -65.3374982181209,
              18.3483367604958
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "013",
      "COUNTYNS": "01804486",
      "AFFGEOID": "0500000US72013",
      "GEOID": "72013",
      "NAME": "Arecibo",
      "LSAD": "13",
      "ALAND": 326179591,
      "AWATER": 117205831,
      "County_state": "Arecibo,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.76799,
              18.478246
            ],
            [
              -66.770142,
              18.433862
            ],
            [
              -66.767942,
              18.427246
            ],
            [
              -66.770072,
              18.325013
            ],
            [
              -66.735947,
              18.349675
            ],
            [
              -66.719761,
              18.336244
            ],
            [
              -66.717302,
              18.315681
            ],
            [
              -66.671621,
              18.324522
            ],
            [
              -66.606989,
              18.328186
            ],
            [
              -66.600229,
              18.339603
            ],
            [
              -66.590785,
              18.33806
            ],
            [
              -66.582278,
              18.373716
            ],
            [
              -66.588181,
              18.389408
            ],
            [
              -66.593456,
              18.399407
            ],
            [
              -66.583222,
              18.437781
            ],
            [
              -66.58777,
              18.437599
            ],
            [
              -66.579868,
              18.467957
            ],
            [
              -66.5867777514421,
              18.4849480000073
            ],
            [
              -66.624618,
              18.494199
            ],
            [
              -66.645839,
              18.488777
            ],
            [
              -66.664364,
              18.487809
            ],
            [
              -66.679876,
              18.484944
            ],
            [
              -66.683719,
              18.481367
            ],
            [
              -66.6948129247276,
              18.477772476431497
            ],
            [
              -66.7013591615783,
              18.4756514414306
            ],
            [
              -66.7107205711168,
              18.4726182671441
            ],
            [
              -66.710743,
              18.472611
            ],
            [
              -66.733986,
              18.473457
            ],
            [
              -66.742067,
              18.474681
            ],
            [
              -66.749301,
              18.476701
            ],
            [
              -66.7648930382495,
              18.4840972877346
            ],
            [
              -66.76799,
              18.478246
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "103",
      "COUNTYNS": "01804532",
      "AFFGEOID": "0500000US72103",
      "GEOID": "72103",
      "NAME": "Naguabo",
      "LSAD": "13",
      "ALAND": 133799723,
      "AWATER": 53351781,
      "County_state": "Naguabo,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -65.828789,
              18.230243
            ],
            [
              -65.826063,
              18.200408
            ],
            [
              -65.792477,
              18.206759
            ],
            [
              -65.760504,
              18.195581
            ],
            [
              -65.766046,
              18.182096
            ],
            [
              -65.7391246723876,
              18.173453576664098
            ],
            [
              -65.738834,
              18.174066
            ],
            [
              -65.734664,
              18.180368
            ],
            [
              -65.728471,
              18.185588
            ],
            [
              -65.717999,
              18.190176
            ],
            [
              -65.712533,
              18.189146
            ],
            [
              -65.710895,
              18.186963
            ],
            [
              -65.7007513221226,
              18.1818105593254
            ],
            [
              -65.6962014905405,
              18.1794994905405
            ],
            [
              -65.695856,
              18.179324
            ],
            [
              -65.691021,
              18.178998
            ],
            [
              -65.694515,
              18.187011
            ],
            [
              -65.690749,
              18.19499
            ],
            [
              -65.664127,
              18.207136
            ],
            [
              -65.66248822575669,
              18.2067291754019
            ],
            [
              -65.657799,
              18.222497
            ],
            [
              -65.693028,
              18.239018
            ],
            [
              -65.682893,
              18.252634
            ],
            [
              -65.702403,
              18.259473
            ],
            [
              -65.721918,
              18.252203
            ],
            [
              -65.749618,
              18.262803
            ],
            [
              -65.766831,
              18.280004
            ],
            [
              -65.784859,
              18.294
            ],
            [
              -65.793223,
              18.296529
            ],
            [
              -65.795542,
              18.289512
            ],
            [
              -65.811424,
              18.290141
            ],
            [
              -65.82422,
              18.273054
            ],
            [
              -65.815664,
              18.262527
            ],
            [
              -65.828789,
              18.230243
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "059",
      "COUNTYNS": "01804510",
      "AFFGEOID": "0500000US72059",
      "GEOID": "72059",
      "NAME": "Guayanilla",
      "LSAD": "13",
      "ALAND": 109480387,
      "AWATER": 56911386,
      "County_state": "Guayanilla,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.8522884755987,
              17.9550037242825
            ],
            [
              -66.838584,
              17.949931
            ],
            [
              -66.8224,
              17.954499
            ],
            [
              -66.80827,
              17.965635
            ],
            [
              -66.787245,
              17.972914
            ],
            [
              -66.784953,
              17.978326
            ],
            [
              -66.7850113629027,
              17.9859623938262
            ],
            [
              -66.7850850245454,
              17.9956005250358
            ],
            [
              -66.785087,
              17.995859
            ],
            [
              -66.770307,
              18.005955
            ],
            [
              -66.764491,
              18.006317
            ],
            [
              -66.76019200580761,
              17.9983698553574
            ],
            [
              -66.748428,
              18.011068
            ],
            [
              -66.732995,
              18.017494
            ],
            [
              -66.74172,
              18.030531
            ],
            [
              -66.73629,
              18.042404
            ],
            [
              -66.753517,
              18.049552
            ],
            [
              -66.765131,
              18.03898
            ],
            [
              -66.769498,
              18.058825
            ],
            [
              -66.765884,
              18.082197
            ],
            [
              -66.76969,
              18.087833
            ],
            [
              -66.760966,
              18.111611
            ],
            [
              -66.769049,
              18.134983
            ],
            [
              -66.7844,
              18.138652
            ],
            [
              -66.797557,
              18.133445
            ],
            [
              -66.796181,
              18.122297
            ],
            [
              -66.810013,
              18.108313
            ],
            [
              -66.80221,
              18.090992
            ],
            [
              -66.816614,
              18.070727
            ],
            [
              -66.824983,
              18.042682
            ],
            [
              -66.818032,
              18.029307
            ],
            [
              -66.829663,
              18.018709
            ],
            [
              -66.825979,
              17.999698
            ],
            [
              -66.836682,
              17.965971
            ],
            [
              -66.8522884755987,
              17.9550037242825
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "011",
      "COUNTYNS": "01804485",
      "AFFGEOID": "0500000US72011",
      "GEOID": "72011",
      "NAME": "AÃ±asco",
      "LSAD": "13",
      "ALAND": 101747426,
      "AWATER": 14607646,
      "County_state": "AÃ±asco,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -67.220709,
              18.3057
            ],
            [
              -67.2254030766224,
              18.296648479087697
            ],
            [
              -67.209963,
              18.294974
            ],
            [
              -67.2057118826065,
              18.2935889555684
            ],
            [
              -67.196056,
              18.290443
            ],
            [
              -67.1900729583821,
              18.2811729726585
            ],
            [
              -67.189971,
              18.281015
            ],
            [
              -67.18787311452101,
              18.266873989985502
            ],
            [
              -67.141899,
              18.270279
            ],
            [
              -67.135633,
              18.251468
            ],
            [
              -67.135819,
              18.245928
            ],
            [
              -67.082002,
              18.254946
            ],
            [
              -67.061828,
              18.270082
            ],
            [
              -67.040228,
              18.271985
            ],
            [
              -67.040853,
              18.281837
            ],
            [
              -67.037935,
              18.289705
            ],
            [
              -67.040709,
              18.300302
            ],
            [
              -67.052583,
              18.306655
            ],
            [
              -67.063469,
              18.311563
            ],
            [
              -67.108046,
              18.320013
            ],
            [
              -67.121241,
              18.324007
            ],
            [
              -67.130167,
              18.317927
            ],
            [
              -67.145774,
              18.322665
            ],
            [
              -67.182852,
              18.313026
            ],
            [
              -67.183176,
              18.311052
            ],
            [
              -67.220709,
              18.3057
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "097",
      "COUNTYNS": "01804529",
      "AFFGEOID": "0500000US72097",
      "GEOID": "72097",
      "NAME": "MayagÃ¼ez",
      "LSAD": "13",
      "ALAND": 201174437,
      "AWATER": 508705758,
      "County_state": "MayagÃ¼ez,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -67.18787311452101,
              18.266873989985502
            ],
            [
              -67.187843,
              18.266671
            ],
            [
              -67.175429,
              18.248008
            ],
            [
              -67.17536358418529,
              18.2475409596301
            ],
            [
              -67.173,
              18.230666
            ],
            [
              -67.158001,
              18.216719
            ],
            [
              -67.152665,
              18.203493
            ],
            [
              -67.155185,
              18.195001
            ],
            [
              -67.1807014673381,
              18.1681816869411
            ],
            [
              -67.1622,
              18.152484
            ],
            [
              -67.159733,
              18.153227
            ],
            [
              -67.098382,
              18.15855
            ],
            [
              -67.086359,
              18.149829
            ],
            [
              -67.082533,
              18.167734
            ],
            [
              -67.051461,
              18.174053
            ],
            [
              -67.017388,
              18.189899
            ],
            [
              -67.019836,
              18.195471
            ],
            [
              -67.032965,
              18.218086
            ],
            [
              -67.050354,
              18.227478
            ],
            [
              -67.056218,
              18.245438
            ],
            [
              -67.082002,
              18.254946
            ],
            [
              -67.135819,
              18.245928
            ],
            [
              -67.135633,
              18.251468
            ],
            [
              -67.141899,
              18.270279
            ],
            [
              -67.18787311452101,
              18.266873989985502
            ]
          ]
        ],
        [
          [
            [
              -67.493531,
              18.388784
            ],
            [
              -67.491155,
              18.376379
            ],
            [
              -67.475705,
              18.37074
            ],
            [
              -67.466198,
              18.379763
            ],
            [
              -67.473329,
              18.392168
            ],
            [
              -67.482836,
              18.396678
            ],
            [
              -67.493531,
              18.388784
            ]
          ]
        ],
        [
          [
            [
              -67.934479,
              18.111306
            ],
            [
              -67.940799,
              18.079716
            ],
            [
              -67.927841,
              18.068572
            ],
            [
              -67.918778,
              18.063116
            ],
            [
              -67.904431,
              18.05913
            ],
            [
              -67.895921,
              18.052342
            ],
            [
              -67.871462,
              18.0578
            ],
            [
              -67.865598,
              18.06544
            ],
            [
              -67.853098,
              18.078195
            ],
            [
              -67.845293,
              18.081938
            ],
            [
              -67.843615,
              18.085099
            ],
            [
              -67.843202,
              18.094858
            ],
            [
              -67.848245,
              18.10832
            ],
            [
              -67.861548,
              18.122144
            ],
            [
              -67.86496710074219,
              18.1207802525746
            ],
            [
              -67.869804,
              18.118851
            ],
            [
              -67.87643,
              18.114157
            ],
            [
              -67.887099,
              18.112574
            ],
            [
              -67.89174,
              18.11397
            ],
            [
              -67.91088,
              18.119668
            ],
            [
              -67.932185,
              18.113221
            ],
            [
              -67.934479,
              18.111306
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "127",
      "COUNTYNS": "01804544",
      "AFFGEOID": "0500000US72127",
      "GEOID": "72127",
      "NAME": "San Juan",
      "LSAD": "13",
      "ALAND": 124029911,
      "AWATER": 75277891,
      "County_state": "San Juan,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.1248495314011,
              18.4718843773912
            ],
            [
              -66.1292518737795,
              18.4725585650613
            ],
            [
              -66.108448,
              18.445354
            ],
            [
              -66.108281,
              18.438902
            ],
            [
              -66.107805,
              18.427577
            ],
            [
              -66.097865,
              18.416424
            ],
            [
              -66.099813,
              18.412005
            ],
            [
              -66.106506,
              18.382683
            ],
            [
              -66.090042,
              18.375056
            ],
            [
              -66.088351,
              18.35714
            ],
            [
              -66.098203,
              18.349637
            ],
            [
              -66.099667,
              18.342625
            ],
            [
              -66.087582,
              18.334611
            ],
            [
              -66.085147,
              18.300507
            ],
            [
              -66.080475,
              18.297295
            ],
            [
              -66.066103,
              18.303321
            ],
            [
              -66.047309,
              18.29818
            ],
            [
              -66.041535,
              18.312344
            ],
            [
              -66.035499,
              18.31984
            ],
            [
              -66.035533,
              18.344368
            ],
            [
              -66.032505,
              18.355701
            ],
            [
              -66.035349,
              18.369681
            ],
            [
              -66.025096,
              18.379774
            ],
            [
              -65.999556,
              18.380065
            ],
            [
              -65.99181,
              18.403869
            ],
            [
              -66.024989,
              18.423978
            ],
            [
              -66.029735,
              18.427795
            ],
            [
              -66.042969,
              18.444174
            ],
            [
              -66.0365586548931,
              18.450216199646498
            ],
            [
              -66.03944,
              18.454441
            ],
            [
              -66.043272,
              18.453655
            ],
            [
              -66.073987,
              18.4581
            ],
            [
              -66.08265356323959,
              18.4617678818416
            ],
            [
              -66.083254,
              18.462022
            ],
            [
              -66.08497849293789,
              18.462901990573197
            ],
            [
              -66.0854553642358,
              18.463145332971102
            ],
            [
              -66.092098,
              18.466535
            ],
            [
              -66.116542,
              18.469823
            ],
            [
              -66.124723,
              18.471865
            ],
            [
              -66.1248495314011,
              18.4718843773912
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "139",
      "COUNTYNS": "01804550",
      "AFFGEOID": "0500000US72139",
      "GEOID": "72139",
      "NAME": "Trujillo Alto",
      "LSAD": "13",
      "ALAND": 53786135,
      "AWATER": 1592601,
      "County_state": "Trujillo Alto,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.035499,
              18.31984
            ],
            [
              -66.041535,
              18.312344
            ],
            [
              -66.025614,
              18.309614
            ],
            [
              -66.021747,
              18.305034
            ],
            [
              -65.968149,
              18.310153
            ],
            [
              -65.942429,
              18.293469
            ],
            [
              -65.938908,
              18.30917
            ],
            [
              -65.956602,
              18.31831
            ],
            [
              -65.982048,
              18.358494
            ],
            [
              -65.98976,
              18.366277
            ],
            [
              -65.991498,
              18.377004
            ],
            [
              -65.999556,
              18.380065
            ],
            [
              -66.025096,
              18.379774
            ],
            [
              -66.035349,
              18.369681
            ],
            [
              -66.032505,
              18.355701
            ],
            [
              -66.035533,
              18.344368
            ],
            [
              -66.035499,
              18.31984
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "071",
      "COUNTYNS": "01804516",
      "AFFGEOID": "0500000US72071",
      "GEOID": "72071",
      "NAME": "Isabela",
      "LSAD": "13",
      "ALAND": 143255845,
      "AWATER": 94636933,
      "County_state": "Isabela,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -67.093752,
              18.515757
            ],
            [
              -67.1034675264819,
              18.514523335524
            ],
            [
              -67.077061,
              18.488966
            ],
            [
              -67.05846,
              18.479982
            ],
            [
              -67.056507,
              18.460954
            ],
            [
              -67.052813,
              18.448354
            ],
            [
              -67.027173,
              18.393376
            ],
            [
              -67.009142,
              18.395125
            ],
            [
              -66.936594,
              18.376985
            ],
            [
              -66.92202,
              18.393204
            ],
            [
              -66.947333,
              18.401596
            ],
            [
              -66.938588,
              18.419121
            ],
            [
              -66.94794,
              18.437866
            ],
            [
              -66.96678,
              18.449915
            ],
            [
              -66.9575168451,
              18.489171316649898
            ],
            [
              -66.957733,
              18.489129
            ],
            [
              -66.95954,
              18.489878
            ],
            [
              -66.9723807241609,
              18.4933027169001
            ],
            [
              -66.988805861334,
              18.4976834234831
            ],
            [
              -66.988958,
              18.497724
            ],
            [
              -67.020276,
              18.510603
            ],
            [
              -67.07929,
              18.513256
            ],
            [
              -67.093752,
              18.515757
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "083",
      "COUNTYNS": "01804522",
      "AFFGEOID": "0500000US72083",
      "GEOID": "72083",
      "NAME": "Las MarÃ­as",
      "LSAD": "13",
      "ALAND": 120071822,
      "AWATER": 376224,
      "County_state": "Las MarÃ­as,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -67.061828,
              18.270082
            ],
            [
              -67.082002,
              18.254946
            ],
            [
              -67.056218,
              18.245438
            ],
            [
              -67.050354,
              18.227478
            ],
            [
              -67.032965,
              18.218086
            ],
            [
              -67.019836,
              18.195471
            ],
            [
              -67.003803,
              18.193501
            ],
            [
              -66.97838,
              18.207916
            ],
            [
              -66.897964,
              18.187744
            ],
            [
              -66.912859,
              18.233009
            ],
            [
              -66.907236,
              18.25309
            ],
            [
              -66.93839,
              18.258788
            ],
            [
              -66.946388,
              18.268764
            ],
            [
              -67.005921,
              18.279157
            ],
            [
              -67.03819,
              18.28047
            ],
            [
              -67.037935,
              18.289705
            ],
            [
              -67.040853,
              18.281837
            ],
            [
              -67.040228,
              18.271985
            ],
            [
              -67.061828,
              18.270082
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "115",
      "COUNTYNS": "01804538",
      "AFFGEOID": "0500000US72115",
      "GEOID": "72115",
      "NAME": "Quebradillas",
      "LSAD": "13",
      "ALAND": 58747685,
      "AWATER": 30224116,
      "County_state": "Quebradillas,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.9575168451,
              18.489171316649898
            ],
            [
              -66.96678,
              18.449915
            ],
            [
              -66.94794,
              18.437866
            ],
            [
              -66.938588,
              18.419121
            ],
            [
              -66.947333,
              18.401596
            ],
            [
              -66.92202,
              18.393204
            ],
            [
              -66.919497,
              18.378578
            ],
            [
              -66.903952,
              18.365949
            ],
            [
              -66.8989,
              18.366108
            ],
            [
              -66.900982,
              18.385353
            ],
            [
              -66.90142974200549,
              18.4845523552409
            ],
            [
              -66.906872,
              18.483556
            ],
            [
              -66.9071764159919,
              18.4836215924406
            ],
            [
              -66.93639919840619,
              18.489918218341003
            ],
            [
              -66.944636,
              18.491693
            ],
            [
              -66.9575168451,
              18.489171316649898
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "081",
      "COUNTYNS": "01804521",
      "AFFGEOID": "0500000US72081",
      "GEOID": "72081",
      "NAME": "Lares",
      "LSAD": "13",
      "ALAND": 159155302,
      "AWATER": 485277,
      "County_state": "Lares,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.907236,
              18.25309
            ],
            [
              -66.912859,
              18.233009
            ],
            [
              -66.897964,
              18.187744
            ],
            [
              -66.863991,
              18.186748
            ],
            [
              -66.862832,
              18.174808
            ],
            [
              -66.83676,
              18.170553
            ],
            [
              -66.827327,
              18.171242
            ],
            [
              -66.836301,
              18.185784
            ],
            [
              -66.832696,
              18.229099
            ],
            [
              -66.817271,
              18.230336
            ],
            [
              -66.817558,
              18.235424
            ],
            [
              -66.825706,
              18.244294
            ],
            [
              -66.827209,
              18.244096
            ],
            [
              -66.829558,
              18.287745
            ],
            [
              -66.826128,
              18.323382
            ],
            [
              -66.824223,
              18.342998
            ],
            [
              -66.854737,
              18.355335
            ],
            [
              -66.876958,
              18.356829
            ],
            [
              -66.889954,
              18.367984
            ],
            [
              -66.893339,
              18.367089
            ],
            [
              -66.909969,
              18.302811
            ],
            [
              -66.907236,
              18.25309
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "089",
      "COUNTYNS": "01804525",
      "AFFGEOID": "0500000US72089",
      "GEOID": "72089",
      "NAME": "Luquillo",
      "LSAD": "13",
      "ALAND": 66851395,
      "AWATER": 53659423,
      "County_state": "Luquillo,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -65.770335,
              18.366244
            ],
            [
              -65.753513,
              18.341538
            ],
            [
              -65.762494,
              18.306094
            ],
            [
              -65.758861,
              18.290604
            ],
            [
              -65.753591,
              18.296755
            ],
            [
              -65.732065,
              18.288169
            ],
            [
              -65.703926,
              18.320826
            ],
            [
              -65.68592,
              18.329991
            ],
            [
              -65.6691572086783,
              18.3634735247608
            ],
            [
              -65.674172,
              18.363937
            ],
            [
              -65.683395,
              18.368486
            ],
            [
              -65.689859,
              18.36687
            ],
            [
              -65.699069,
              18.368156
            ],
            [
              -65.71434,
              18.376343
            ],
            [
              -65.71440377874659,
              18.376575021653398
            ],
            [
              -65.718618,
              18.391906
            ],
            [
              -65.731176,
              18.395105
            ],
            [
              -65.732641,
              18.392583
            ],
            [
              -65.72999,
              18.386004
            ],
            [
              -65.7335127345653,
              18.3822685422962
            ],
            [
              -65.733567,
              18.382211
            ],
            [
              -65.742154,
              18.380459
            ],
            [
              -65.7501794863426,
              18.3850503787063
            ],
            [
              -65.770335,
              18.366244
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "111",
      "COUNTYNS": "01804536",
      "AFFGEOID": "0500000US72111",
      "GEOID": "72111",
      "NAME": "PeÃ±uelas",
      "LSAD": "13",
      "ALAND": 115558555,
      "AWATER": 60210081,
      "County_state": "PeÃ±uelas,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.76969,
              18.087833
            ],
            [
              -66.765884,
              18.082197
            ],
            [
              -66.769498,
              18.058825
            ],
            [
              -66.765131,
              18.03898
            ],
            [
              -66.753517,
              18.049552
            ],
            [
              -66.73629,
              18.042404
            ],
            [
              -66.74172,
              18.030531
            ],
            [
              -66.732995,
              18.017494
            ],
            [
              -66.748428,
              18.011068
            ],
            [
              -66.76019200580761,
              17.9983698553574
            ],
            [
              -66.758467,
              17.995181
            ],
            [
              -66.75840567631501,
              17.9949544268734
            ],
            [
              -66.756256,
              17.987012
            ],
            [
              -66.75528358263469,
              17.9869734512327
            ],
            [
              -66.751337,
              17.986817
            ],
            [
              -66.746248,
              17.990349
            ],
            [
              -66.731118,
              17.991658
            ],
            [
              -66.716957,
              17.990344
            ],
            [
              -66.713394,
              17.987763
            ],
            [
              -66.709856,
              17.982109
            ],
            [
              -66.69911511688659,
              17.9775681152688
            ],
            [
              -66.686768,
              17.991486
            ],
            [
              -66.689446,
              18.040257
            ],
            [
              -66.680353,
              18.077052
            ],
            [
              -66.678087,
              18.1007
            ],
            [
              -66.69407,
              18.130534
            ],
            [
              -66.713595,
              18.132105
            ],
            [
              -66.727344,
              18.11304
            ],
            [
              -66.749618,
              18.113664
            ],
            [
              -66.769049,
              18.134983
            ],
            [
              -66.760966,
              18.111611
            ],
            [
              -66.76969,
              18.087833
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "085",
      "COUNTYNS": "01804523",
      "AFFGEOID": "0500000US72085",
      "GEOID": "72085",
      "NAME": "Las Piedras",
      "LSAD": "13",
      "ALAND": 87748363,
      "AWATER": 32509,
      "County_state": "Las Piedras,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -65.928341,
              18.142845
            ],
            [
              -65.920665,
              18.137823
            ],
            [
              -65.926227,
              18.119963
            ],
            [
              -65.907453,
              18.126145
            ],
            [
              -65.877615,
              18.118167
            ],
            [
              -65.863074,
              18.140167
            ],
            [
              -65.854119,
              18.177276
            ],
            [
              -65.846571,
              18.17793
            ],
            [
              -65.836448,
              18.175243
            ],
            [
              -65.826063,
              18.200408
            ],
            [
              -65.828789,
              18.230243
            ],
            [
              -65.815664,
              18.262527
            ],
            [
              -65.82422,
              18.273054
            ],
            [
              -65.836387,
              18.275246
            ],
            [
              -65.84643,
              18.258078
            ],
            [
              -65.852616,
              18.254972
            ],
            [
              -65.851934,
              18.248011
            ],
            [
              -65.874765,
              18.215914
            ],
            [
              -65.896106,
              18.20227
            ],
            [
              -65.928341,
              18.142845
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "109",
      "COUNTYNS": "01804535",
      "AFFGEOID": "0500000US72109",
      "GEOID": "72109",
      "NAME": "Patillas",
      "LSAD": "13",
      "ALAND": 120695652,
      "AWATER": 74074554,
      "County_state": "Patillas,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.089399,
              18.056256
            ],
            [
              -66.082263,
              18.032958
            ],
            [
              -66.057263,
              18.040434
            ],
            [
              -66.043683,
              18.029445
            ],
            [
              -66.046927,
              18.009097
            ],
            [
              -66.0195388847422,
              17.978353879849898
            ],
            [
              -66.017308,
              17.979583
            ],
            [
              -66.007731,
              17.980541
            ],
            [
              -65.995185,
              17.978989
            ],
            [
              -65.985358,
              17.971854
            ],
            [
              -65.98455,
              17.969411
            ],
            [
              -65.976611,
              17.967669
            ],
            [
              -65.9765544048249,
              17.967678184319098
            ],
            [
              -65.9578410925026,
              17.970714998521597
            ],
            [
              -65.924738,
              17.976087
            ],
            [
              -65.91384489566289,
              17.980511219265
            ],
            [
              -65.943123,
              17.992527
            ],
            [
              -65.952848,
              18.023111
            ],
            [
              -65.9819,
              18.03484
            ],
            [
              -65.988523,
              18.058941
            ],
            [
              -66.010521,
              18.067698
            ],
            [
              -66.010245,
              18.075565
            ],
            [
              -66.012849,
              18.096415
            ],
            [
              -66.051788,
              18.109878
            ],
            [
              -66.053146,
              18.107293
            ],
            [
              -66.06832,
              18.078723
            ],
            [
              -66.089399,
              18.056256
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "017",
      "COUNTYNS": "01804488",
      "AFFGEOID": "0500000US72017",
      "GEOID": "72017",
      "NAME": "Barceloneta",
      "LSAD": "13",
      "ALAND": 48421896,
      "AWATER": 31649450,
      "County_state": "Barceloneta,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.593456,
              18.399407
            ],
            [
              -66.588181,
              18.389408
            ],
            [
              -66.564313,
              18.413309
            ],
            [
              -66.543079,
              18.405422
            ],
            [
              -66.543785,
              18.418148
            ],
            [
              -66.52118,
              18.431529
            ],
            [
              -66.537521,
              18.435923
            ],
            [
              -66.527334,
              18.45601
            ],
            [
              -66.5332322982059,
              18.481739746404497
            ],
            [
              -66.53484,
              18.481253
            ],
            [
              -66.558503,
              18.489987
            ],
            [
              -66.56338787877459,
              18.4905017654269
            ],
            [
              -66.563485,
              18.490512
            ],
            [
              -66.562916,
              18.48845
            ],
            [
              -66.5651726973261,
              18.4856089879254
            ],
            [
              -66.565241,
              18.485523
            ],
            [
              -66.584074,
              18.484287
            ],
            [
              -66.5867777514421,
              18.4849480000073
            ],
            [
              -66.579868,
              18.467957
            ],
            [
              -66.58777,
              18.437599
            ],
            [
              -66.583222,
              18.437781
            ],
            [
              -66.593456,
              18.399407
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "147",
      "COUNTYNS": "01804554",
      "AFFGEOID": "0500000US72147",
      "GEOID": "72147",
      "NAME": "Vieques",
      "LSAD": "13",
      "ALAND": 131527813,
      "AWATER": 552206400,
      "County_state": "Vieques,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -65.575579,
              18.115669
            ],
            [
              -65.57686,
              18.103224
            ],
            [
              -65.570628,
              18.097325
            ],
            [
              -65.569305,
              18.091616
            ],
            [
              -65.558646,
              18.08566
            ],
            [
              -65.542087,
              18.081177
            ],
            [
              -65.524209,
              18.081977
            ],
            [
              -65.507265,
              18.091646
            ],
            [
              -65.47979,
              18.096352
            ],
            [
              -65.468768,
              18.092643
            ],
            [
              -65.4678203681304,
              18.0910431610643
            ],
            [
              -65.46599067703119,
              18.0879541861629
            ],
            [
              -65.465849,
              18.087715
            ],
            [
              -65.45681,
              18.087778
            ],
            [
              -65.45138,
              18.086096
            ],
            [
              -65.426311,
              18.093749
            ],
            [
              -65.423765,
              18.097764
            ],
            [
              -65.42255215364649,
              18.0986178850765
            ],
            [
              -65.4199328449784,
              18.1004619674502
            ],
            [
              -65.411767,
              18.106211
            ],
            [
              -65.399835759147,
              18.1088222042648
            ],
            [
              -65.399791,
              18.108832
            ],
            [
              -65.39335,
              18.108287
            ],
            [
              -65.386472,
              18.110431
            ],
            [
              -65.383104,
              18.106049
            ],
            [
              -65.374419,
              18.108041
            ],
            [
              -65.37523,
              18.115309
            ],
            [
              -65.37367,
              18.121419
            ],
            [
              -65.365884,
              18.124693
            ],
            [
              -65.355402,
              18.12512
            ],
            [
              -65.35079,
              18.123351
            ],
            [
              -65.356001,
              18.112311
            ],
            [
              -65.350493,
              18.111914
            ],
            [
              -65.342037,
              18.11138
            ],
            [
              -65.338506,
              18.112439
            ],
            [
              -65.327184,
              18.124106
            ],
            [
              -65.322794,
              18.126589
            ],
            [
              -65.296036,
              18.12799
            ],
            [
              -65.283248,
              18.132999
            ],
            [
              -65.276214,
              18.131936
            ],
            [
              -65.275165,
              18.13443
            ],
            [
              -65.287962,
              18.148097
            ],
            [
              -65.294896,
              18.14283
            ],
            [
              -65.302295,
              18.141089
            ],
            [
              -65.308717,
              18.145172
            ],
            [
              -65.313476,
              18.144296
            ],
            [
              -65.334289,
              18.147761
            ],
            [
              -65.371373,
              18.157517
            ],
            [
              -65.399517,
              18.161935
            ],
            [
              -65.437058,
              18.15766
            ],
            [
              -65.46791,
              18.143767
            ],
            [
              -65.489829,
              18.135912
            ],
            [
              -65.511712,
              18.13284
            ],
            [
              -65.546199,
              18.119329
            ],
            [
              -65.575579,
              18.115669
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "063",
      "COUNTYNS": "01804512",
      "AFFGEOID": "0500000US72063",
      "GEOID": "72063",
      "NAME": "Gurabo",
      "LSAD": "13",
      "ALAND": 72228545,
      "AWATER": 1101116,
      "County_state": "Gurabo,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.030572,
              18.30118
            ],
            [
              -66.029894,
              18.29087
            ],
            [
              -66.008233,
              18.278173
            ],
            [
              -66.013971,
              18.264706
            ],
            [
              -66.009461,
              18.241051
            ],
            [
              -66.013744,
              18.225645
            ],
            [
              -65.99765,
              18.206992
            ],
            [
              -65.989048,
              18.220339
            ],
            [
              -65.951019,
              18.229292
            ],
            [
              -65.939473,
              18.234118
            ],
            [
              -65.945842,
              18.252655
            ],
            [
              -65.918535,
              18.27013
            ],
            [
              -65.939296,
              18.286771
            ],
            [
              -65.942429,
              18.293469
            ],
            [
              -65.968149,
              18.310153
            ],
            [
              -66.021747,
              18.305034
            ],
            [
              -66.030572,
              18.30118
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "153",
      "COUNTYNS": "01804557",
      "AFFGEOID": "0500000US72153",
      "GEOID": "72153",
      "NAME": "Yauco",
      "LSAD": "13",
      "ALAND": 175371914,
      "AWATER": 1625260,
      "County_state": "Yauco,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.926517,
              18.151267
            ],
            [
              -66.911623,
              18.113522
            ],
            [
              -66.920696,
              18.103469
            ],
            [
              -66.901033,
              18.039854
            ],
            [
              -66.887484,
              18.034075
            ],
            [
              -66.884653,
              18.024816
            ],
            [
              -66.863219,
              17.998029
            ],
            [
              -66.8594708563219,
              17.954316341130898
            ],
            [
              -66.856474,
              17.956553
            ],
            [
              -66.85545288261319,
              17.956175032457498
            ],
            [
              -66.85280250934049,
              17.955193994346097
            ],
            [
              -66.8522884755987,
              17.9550037242825
            ],
            [
              -66.836682,
              17.965971
            ],
            [
              -66.825979,
              17.999698
            ],
            [
              -66.829663,
              18.018709
            ],
            [
              -66.818032,
              18.029307
            ],
            [
              -66.824983,
              18.042682
            ],
            [
              -66.816614,
              18.070727
            ],
            [
              -66.80221,
              18.090992
            ],
            [
              -66.810013,
              18.108313
            ],
            [
              -66.796181,
              18.122297
            ],
            [
              -66.797557,
              18.133445
            ],
            [
              -66.804058,
              18.148056
            ],
            [
              -66.826278,
              18.160216
            ],
            [
              -66.827327,
              18.171242
            ],
            [
              -66.83676,
              18.170553
            ],
            [
              -66.85012,
              18.162886
            ],
            [
              -66.85678,
              18.13859
            ],
            [
              -66.888098,
              18.153398
            ],
            [
              -66.903806,
              18.146363
            ],
            [
              -66.926517,
              18.151267
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "005",
      "COUNTYNS": "01804482",
      "AFFGEOID": "0500000US72005",
      "GEOID": "72005",
      "NAME": "Aguadilla",
      "LSAD": "13",
      "ALAND": 94613892,
      "AWATER": 101131781,
      "County_state": "Aguadilla,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -67.169016,
              18.478488
            ],
            [
              -67.169011,
              18.466352
            ],
            [
              -67.161746,
              18.453462
            ],
            [
              -67.156619,
              18.439562
            ],
            [
              -67.155245,
              18.424401
            ],
            [
              -67.156599,
              18.418983
            ],
            [
              -67.159608,
              18.415915
            ],
            [
              -67.1601443257786,
              18.41558726146
            ],
            [
              -67.15656,
              18.393978
            ],
            [
              -67.13221,
              18.389391
            ],
            [
              -67.125171,
              18.408926
            ],
            [
              -67.098937,
              18.437032
            ],
            [
              -67.056507,
              18.460954
            ],
            [
              -67.05846,
              18.479982
            ],
            [
              -67.077061,
              18.488966
            ],
            [
              -67.1034675264819,
              18.514523335524
            ],
            [
              -67.125655,
              18.511706
            ],
            [
              -67.13779724027829,
              18.5079169731385
            ],
            [
              -67.138249,
              18.507776
            ],
            [
              -67.14283,
              18.505485
            ],
            [
              -67.1630713783686,
              18.4883063243263
            ],
            [
              -67.164144,
              18.487396
            ],
            [
              -67.169016,
              18.478488
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "031",
      "COUNTYNS": "01804495",
      "AFFGEOID": "0500000US72031",
      "GEOID": "72031",
      "NAME": "Carolina",
      "LSAD": "13",
      "ALAND": 117502258,
      "AWATER": 38734577,
      "County_state": "Carolina,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.0365586548931,
              18.450216199646498
            ],
            [
              -66.042969,
              18.444174
            ],
            [
              -66.029735,
              18.427795
            ],
            [
              -66.024989,
              18.423978
            ],
            [
              -65.99181,
              18.403869
            ],
            [
              -65.999556,
              18.380065
            ],
            [
              -65.991498,
              18.377004
            ],
            [
              -65.98976,
              18.366277
            ],
            [
              -65.982048,
              18.358494
            ],
            [
              -65.956602,
              18.31831
            ],
            [
              -65.938908,
              18.30917
            ],
            [
              -65.942429,
              18.293469
            ],
            [
              -65.939296,
              18.286771
            ],
            [
              -65.918535,
              18.27013
            ],
            [
              -65.907258,
              18.272172
            ],
            [
              -65.915647,
              18.292416
            ],
            [
              -65.903141,
              18.320657
            ],
            [
              -65.915923,
              18.348092
            ],
            [
              -65.917097,
              18.367765
            ],
            [
              -65.913152,
              18.379291
            ],
            [
              -65.923708,
              18.385853
            ],
            [
              -65.93134,
              18.393679
            ],
            [
              -65.919278,
              18.402993
            ],
            [
              -65.9304,
              18.428478
            ],
            [
              -65.961461,
              18.433368
            ],
            [
              -65.967213,
              18.432383
            ],
            [
              -65.9928101783015,
              18.458028970999898
            ],
            [
              -65.9928187600341,
              18.4579918469517
            ],
            [
              -65.992935,
              18.457489
            ],
            [
              -65.99718,
              18.449895
            ],
            [
              -66.006523,
              18.444347
            ],
            [
              -66.023221,
              18.443875
            ],
            [
              -66.0233148148293,
              18.443919129025197
            ],
            [
              -66.036491,
              18.450117
            ],
            [
              -66.0365586548931,
              18.450216199646498
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "065",
      "COUNTYNS": "01804513",
      "AFFGEOID": "0500000US72065",
      "GEOID": "72065",
      "NAME": "Hatillo",
      "LSAD": "13",
      "ALAND": 108213684,
      "AWATER": 43975697,
      "County_state": "Hatillo,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.8366347465608,
              18.4877005118712
            ],
            [
              -66.828973,
              18.472711
            ],
            [
              -66.823643,
              18.412465
            ],
            [
              -66.813974,
              18.390218
            ],
            [
              -66.810675,
              18.386627
            ],
            [
              -66.824223,
              18.342998
            ],
            [
              -66.826128,
              18.323382
            ],
            [
              -66.770363,
              18.320645
            ],
            [
              -66.770072,
              18.325013
            ],
            [
              -66.767942,
              18.427246
            ],
            [
              -66.770142,
              18.433862
            ],
            [
              -66.76799,
              18.478246
            ],
            [
              -66.7648930382495,
              18.4840972877346
            ],
            [
              -66.780311,
              18.491411
            ],
            [
              -66.79932,
              18.492775
            ],
            [
              -66.8254305567145,
              18.4892241863862
            ],
            [
              -66.8366347465608,
              18.4877005118712
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "151",
      "COUNTYNS": "01804556",
      "AFFGEOID": "0500000US72151",
      "GEOID": "72151",
      "NAME": "Yabucoa",
      "LSAD": "13",
      "ALAND": 143005178,
      "AWATER": 72592521,
      "County_state": "Yabucoa,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.010245,
              18.075565
            ],
            [
              -66.010521,
              18.067698
            ],
            [
              -65.988523,
              18.058941
            ],
            [
              -65.97779,
              18.061093
            ],
            [
              -65.933874,
              18.03682
            ],
            [
              -65.907393,
              18.035898
            ],
            [
              -65.879255,
              18.020512
            ],
            [
              -65.865038,
              18.025769
            ],
            [
              -65.8509127553522,
              18.011954163499002
            ],
            [
              -65.839591,
              18.015077
            ],
            [
              -65.832429,
              18.014916
            ],
            [
              -65.83423580873101,
              18.038489712613597
            ],
            [
              -65.834274,
              18.038988
            ],
            [
              -65.83109,
              18.050664
            ],
            [
              -65.8293932353968,
              18.0528708945183
            ],
            [
              -65.8292818897593,
              18.0530157160666
            ],
            [
              -65.825848,
              18.057482
            ],
            [
              -65.81718686434729,
              18.063324129711496
            ],
            [
              -65.817107,
              18.063378
            ],
            [
              -65.809174,
              18.056818
            ],
            [
              -65.801831,
              18.058527
            ],
            [
              -65.7967555091572,
              18.0697433647407
            ],
            [
              -65.829242,
              18.084713
            ],
            [
              -65.848051,
              18.099164
            ],
            [
              -65.848117,
              18.117488
            ],
            [
              -65.870211,
              18.108426
            ],
            [
              -65.877615,
              18.118167
            ],
            [
              -65.907453,
              18.126145
            ],
            [
              -65.926227,
              18.119963
            ],
            [
              -65.933166,
              18.093754
            ],
            [
              -65.962051,
              18.086497
            ],
            [
              -65.975978,
              18.094308
            ],
            [
              -66.010245,
              18.075565
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "021",
      "COUNTYNS": "01804490",
      "AFFGEOID": "0500000US72021",
      "GEOID": "72021",
      "NAME": "BayamÃ³n",
      "LSAD": "13",
      "ALAND": 114816256,
      "AWATER": 477626,
      "County_state": "BayamÃ³n,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.213537,
              18.306283
            ],
            [
              -66.206135,
              18.27666
            ],
            [
              -66.201669,
              18.276413
            ],
            [
              -66.190676,
              18.25884
            ],
            [
              -66.1616,
              18.282538
            ],
            [
              -66.143861,
              18.27959
            ],
            [
              -66.137958,
              18.2982
            ],
            [
              -66.140209,
              18.300577
            ],
            [
              -66.141783,
              18.322403
            ],
            [
              -66.133812,
              18.334678
            ],
            [
              -66.141645,
              18.361815
            ],
            [
              -66.134518,
              18.374579
            ],
            [
              -66.135185,
              18.382701
            ],
            [
              -66.118701,
              18.379253
            ],
            [
              -66.124067,
              18.3991
            ],
            [
              -66.121283,
              18.414388
            ],
            [
              -66.13158,
              18.424656
            ],
            [
              -66.158545,
              18.421561
            ],
            [
              -66.169516,
              18.430998
            ],
            [
              -66.172653,
              18.42105
            ],
            [
              -66.187556,
              18.41974
            ],
            [
              -66.198013,
              18.414626
            ],
            [
              -66.19698,
              18.389289
            ],
            [
              -66.197293,
              18.360199
            ],
            [
              -66.199676,
              18.35455
            ],
            [
              -66.198629,
              18.351108
            ],
            [
              -66.200471,
              18.347138
            ],
            [
              -66.199554,
              18.339888
            ],
            [
              -66.203615,
              18.336673
            ],
            [
              -66.201531,
              18.328983
            ],
            [
              -66.204014,
              18.315908
            ],
            [
              -66.204734,
              18.316035
            ],
            [
              -66.213537,
              18.306283
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "041",
      "COUNTYNS": "01804500",
      "AFFGEOID": "0500000US72041",
      "GEOID": "72041",
      "NAME": "Cidra",
      "LSAD": "13",
      "ALAND": 93294534,
      "AWATER": 1123798,
      "County_state": "Cidra,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.24046,
              18.184794
            ],
            [
              -66.242735,
              18.180248
            ],
            [
              -66.223716,
              18.165717
            ],
            [
              -66.216899,
              18.14322
            ],
            [
              -66.187334,
              18.137053
            ],
            [
              -66.182272,
              18.144625
            ],
            [
              -66.175067,
              18.133745
            ],
            [
              -66.164748,
              18.144463
            ],
            [
              -66.131881,
              18.132577
            ],
            [
              -66.130827,
              18.138828
            ],
            [
              -66.118503,
              18.138151
            ],
            [
              -66.101726,
              18.151256
            ],
            [
              -66.095099,
              18.169077
            ],
            [
              -66.103759,
              18.170453
            ],
            [
              -66.101673,
              18.183082
            ],
            [
              -66.117774,
              18.209949
            ],
            [
              -66.141065,
              18.197906
            ],
            [
              -66.143941,
              18.228111
            ],
            [
              -66.169126,
              18.22429
            ],
            [
              -66.182483,
              18.202302
            ],
            [
              -66.2071,
              18.196933
            ],
            [
              -66.211851,
              18.173087
            ],
            [
              -66.24046,
              18.184794
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "099",
      "COUNTYNS": "01804530",
      "AFFGEOID": "0500000US72099",
      "GEOID": "72099",
      "NAME": "Moca",
      "LSAD": "13",
      "ALAND": 130383573,
      "AWATER": 56474,
      "County_state": "Moca,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -67.134515,
              18.346514
            ],
            [
              -67.130167,
              18.317927
            ],
            [
              -67.121241,
              18.324007
            ],
            [
              -67.108046,
              18.320013
            ],
            [
              -67.063469,
              18.311563
            ],
            [
              -67.052583,
              18.306655
            ],
            [
              -67.035829,
              18.329371
            ],
            [
              -67.047405,
              18.350859
            ],
            [
              -67.042023,
              18.355805
            ],
            [
              -67.027173,
              18.393376
            ],
            [
              -67.052813,
              18.448354
            ],
            [
              -67.056507,
              18.460954
            ],
            [
              -67.098937,
              18.437032
            ],
            [
              -67.125171,
              18.408926
            ],
            [
              -67.13221,
              18.389391
            ],
            [
              -67.126651,
              18.371447
            ],
            [
              -67.134515,
              18.346514
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "135",
      "COUNTYNS": "01804548",
      "AFFGEOID": "0500000US72135",
      "GEOID": "72135",
      "NAME": "Toa Alta",
      "LSAD": "13",
      "ALAND": 69972584,
      "AWATER": 1405464,
      "County_state": "Toa Alta,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.314158,
              18.380801
            ],
            [
              -66.318764,
              18.367411
            ],
            [
              -66.305719,
              18.369992
            ],
            [
              -66.278076,
              18.329975
            ],
            [
              -66.258509,
              18.331909
            ],
            [
              -66.233009,
              18.324547
            ],
            [
              -66.217179,
              18.334194
            ],
            [
              -66.204734,
              18.316035
            ],
            [
              -66.204014,
              18.315908
            ],
            [
              -66.201531,
              18.328983
            ],
            [
              -66.203615,
              18.336673
            ],
            [
              -66.199554,
              18.339888
            ],
            [
              -66.200471,
              18.347138
            ],
            [
              -66.198629,
              18.351108
            ],
            [
              -66.199676,
              18.35455
            ],
            [
              -66.197293,
              18.360199
            ],
            [
              -66.19698,
              18.389289
            ],
            [
              -66.233917,
              18.401281
            ],
            [
              -66.252554,
              18.394187
            ],
            [
              -66.270788,
              18.383768
            ],
            [
              -66.303663,
              18.384077
            ],
            [
              -66.314158,
              18.380801
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "091",
      "COUNTYNS": "01804526",
      "AFFGEOID": "0500000US72091",
      "GEOID": "72091",
      "NAME": "ManatÃ­",
      "LSAD": "13",
      "ALAND": 116913007,
      "AWATER": 66163823,
      "County_state": "ManatÃ­,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.543785,
              18.418148
            ],
            [
              -66.543079,
              18.405422
            ],
            [
              -66.530529,
              18.385013
            ],
            [
              -66.533623,
              18.351333
            ],
            [
              -66.529283,
              18.350255
            ],
            [
              -66.511776,
              18.362003
            ],
            [
              -66.48343,
              18.36604
            ],
            [
              -66.473346,
              18.367604
            ],
            [
              -66.463211,
              18.371473
            ],
            [
              -66.451578,
              18.372214
            ],
            [
              -66.4438,
              18.371027
            ],
            [
              -66.448989,
              18.387214
            ],
            [
              -66.439413,
              18.410366
            ],
            [
              -66.439494,
              18.462245
            ],
            [
              -66.4388131518907,
              18.4857127310406
            ],
            [
              -66.439961,
              18.485525
            ],
            [
              -66.441852,
              18.479751
            ],
            [
              -66.4420645015128,
              18.4794971111222
            ],
            [
              -66.4441317634748,
              18.477027223671598
            ],
            [
              -66.449184,
              18.470991
            ],
            [
              -66.456486,
              18.46892
            ],
            [
              -66.4702636552049,
              18.469069692038296
            ],
            [
              -66.470292,
              18.46907
            ],
            [
              -66.511609,
              18.476848
            ],
            [
              -66.529476,
              18.482877
            ],
            [
              -66.5332322982059,
              18.481739746404497
            ],
            [
              -66.527334,
              18.45601
            ],
            [
              -66.537521,
              18.435923
            ],
            [
              -66.52118,
              18.431529
            ],
            [
              -66.543785,
              18.418148
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "027",
      "COUNTYNS": "01804493",
      "AFFGEOID": "0500000US72027",
      "GEOID": "72027",
      "NAME": "Camuy",
      "LSAD": "13",
      "ALAND": 120058280,
      "AWATER": 40430074,
      "County_state": "Camuy,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.90142974200549,
              18.4845523552409
            ],
            [
              -66.900982,
              18.385353
            ],
            [
              -66.8989,
              18.366108
            ],
            [
              -66.893339,
              18.367089
            ],
            [
              -66.889954,
              18.367984
            ],
            [
              -66.876958,
              18.356829
            ],
            [
              -66.854737,
              18.355335
            ],
            [
              -66.824223,
              18.342998
            ],
            [
              -66.810675,
              18.386627
            ],
            [
              -66.813974,
              18.390218
            ],
            [
              -66.823643,
              18.412465
            ],
            [
              -66.828973,
              18.472711
            ],
            [
              -66.8366347465608,
              18.4877005118712
            ],
            [
              -66.83694,
              18.487659
            ],
            [
              -66.849673,
              18.490745
            ],
            [
              -66.867386,
              18.490785
            ],
            [
              -66.8899641492141,
              18.486651447838
            ],
            [
              -66.90142974200549,
              18.4845523552409
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "043",
      "COUNTYNS": "01804501",
      "AFFGEOID": "0500000US72043",
      "GEOID": "72043",
      "NAME": "Coamo",
      "LSAD": "13",
      "ALAND": 202052316,
      "AWATER": 38061,
      "County_state": "Coamo,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.44456,
              18.176674
            ],
            [
              -66.438561,
              18.172963
            ],
            [
              -66.434573,
              18.173537
            ],
            [
              -66.419368,
              18.145304
            ],
            [
              -66.430494,
              18.116403
            ],
            [
              -66.421379,
              18.098589
            ],
            [
              -66.430314,
              18.082053
            ],
            [
              -66.430064,
              18.076387
            ],
            [
              -66.424105,
              18.047972
            ],
            [
              -66.426989,
              18.042627
            ],
            [
              -66.372226,
              18.032614
            ],
            [
              -66.356779,
              18.036942
            ],
            [
              -66.331244,
              18.015891
            ],
            [
              -66.297944,
              18.056942
            ],
            [
              -66.284812,
              18.059722
            ],
            [
              -66.279382,
              18.068916
            ],
            [
              -66.257527,
              18.075884
            ],
            [
              -66.259949,
              18.082612
            ],
            [
              -66.272941,
              18.086586
            ],
            [
              -66.310767,
              18.118168
            ],
            [
              -66.320913,
              18.126005
            ],
            [
              -66.314848,
              18.152088
            ],
            [
              -66.322323,
              18.165503
            ],
            [
              -66.351258,
              18.165488
            ],
            [
              -66.373768,
              18.173694
            ],
            [
              -66.413686,
              18.163337
            ],
            [
              -66.423649,
              18.172025
            ],
            [
              -66.44456,
              18.176674
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "051",
      "COUNTYNS": "01804505",
      "AFFGEOID": "0500000US72051",
      "GEOID": "72051",
      "NAME": "Dorado",
      "LSAD": "13",
      "ALAND": 59870098,
      "AWATER": 79238790,
      "County_state": "Dorado,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.31503033652929,
              18.4746802213809
            ],
            [
              -66.317421,
              18.451074
            ],
            [
              -66.299926,
              18.423913
            ],
            [
              -66.303663,
              18.384077
            ],
            [
              -66.270788,
              18.383768
            ],
            [
              -66.252554,
              18.394187
            ],
            [
              -66.264011,
              18.427967
            ],
            [
              -66.2581,
              18.454976
            ],
            [
              -66.230532,
              18.454392
            ],
            [
              -66.1989303211706,
              18.466657825366198
            ],
            [
              -66.203398,
              18.466129
            ],
            [
              -66.220148,
              18.466
            ],
            [
              -66.241797,
              18.46874
            ],
            [
              -66.251547,
              18.472464
            ],
            [
              -66.255744130561,
              18.475346444952297
            ],
            [
              -66.2561249989763,
              18.475608012284
            ],
            [
              -66.258015,
              18.476906
            ],
            [
              -66.269799,
              18.480281
            ],
            [
              -66.276599,
              18.478129
            ],
            [
              -66.283675,
              18.472203
            ],
            [
              -66.291225,
              18.472347
            ],
            [
              -66.31503033652929,
              18.4746802213809
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "053",
      "COUNTYNS": "01804506",
      "AFFGEOID": "0500000US72053",
      "GEOID": "72053",
      "NAME": "Fajardo",
      "LSAD": "13",
      "ALAND": 77448647,
      "AWATER": 195352764,
      "County_state": "Fajardo,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -65.594158,
              18.392699
            ],
            [
              -65.595239,
              18.382784
            ],
            [
              -65.585872,
              18.375946
            ],
            [
              -65.577045,
              18.350985
            ],
            [
              -65.567678,
              18.342265
            ],
            [
              -65.564269,
              18.348008
            ],
            [
              -65.565949,
              18.358333
            ],
            [
              -65.566904,
              18.380321
            ],
            [
              -65.5700108850007,
              18.382420655002
            ],
            [
              -65.586232,
              18.393383
            ],
            [
              -65.594158,
              18.392699
            ]
          ]
        ],
        [
          [
            [
              -65.753591,
              18.296755
            ],
            [
              -65.737739,
              18.273881
            ],
            [
              -65.71751,
              18.267744
            ],
            [
              -65.699892,
              18.273079
            ],
            [
              -65.695013,
              18.28568
            ],
            [
              -65.682174,
              18.275069
            ],
            [
              -65.66613,
              18.288044
            ],
            [
              -65.634367,
              18.283584
            ],
            [
              -65.6232776456455,
              18.2885011936473
            ],
            [
              -65.626399,
              18.331902
            ],
            [
              -65.6310244163239,
              18.3423494544274
            ],
            [
              -65.633291,
              18.347469
            ],
            [
              -65.619268,
              18.367321
            ],
            [
              -65.613746,
              18.381957
            ],
            [
              -65.617842,
              18.387846
            ],
            [
              -65.624022,
              18.38717
            ],
            [
              -65.6243374830551,
              18.3870473539033
            ],
            [
              -65.6428099648468,
              18.3798660560445
            ],
            [
              -65.643749,
              18.379501
            ],
            [
              -65.649712,
              18.37406
            ],
            [
              -65.653902,
              18.366934
            ],
            [
              -65.664856,
              18.363076
            ],
            [
              -65.6691572086783,
              18.3634735247608
            ],
            [
              -65.68592,
              18.329991
            ],
            [
              -65.703926,
              18.320826
            ],
            [
              -65.732065,
              18.288169
            ],
            [
              -65.753591,
              18.296755
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "72",
      "COUNTYFP": "123",
      "COUNTYNS": "01804542",
      "AFFGEOID": "0500000US72123",
      "GEOID": "72123",
      "NAME": "Salinas",
      "LSAD": "13",
      "ALAND": 179660999,
      "AWATER": 115910809,
      "County_state": "Salinas,72"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -66.342607,
              17.993561
            ],
            [
              -66.3381518765016,
              17.9764915904303
            ],
            [
              -66.323659,
              17.978536
            ],
            [
              -66.31695,
              17.976683
            ],
            [
              -66.29772696353379,
              17.9591916428086
            ],
            [
              -66.297679,
              17.959148
            ],
            [
              -66.290782,
              17.946491
            ],
            [
              -66.275651,
              17.94826
            ],
            [
              -66.264331,
              17.944538
            ],
            [
              -66.2642921570134,
              17.9444931277414
            ],
            [
              -66.257676,
              17.93685
            ],
            [
              -66.251188,
              17.932785
            ],
            [
              -66.2511912970884,
              17.9327694670773
            ],
            [
              -66.253881,
              17.920098
            ],
            [
              -66.243235,
              17.913769
            ],
            [
              -66.224401,
              17.912426
            ],
            [
              -66.2188794436979,
              17.9156773822481
            ],
            [
              -66.225359,
              17.925402
            ],
            [
              -66.222513,
              17.942076
            ],
            [
              -66.20621,
              17.962893
            ],
            [
              -66.205592,
              18.01298
            ],
            [
              -66.18051,
              18.025069
            ],
            [
              -66.166145,
              18.050063
            ],
            [
              -66.202038,
              18.046113
            ],
            [
              -66.201753,
              18.067639
            ],
            [
              -66.22449,
              18.095339
            ],
            [
              -66.240053,
              18.093186
            ],
            [
              -66.242238,
              18.077148
            ],
            [
              -66.257527,
              18.075884
            ],
            [
              -66.279382,
              18.068916
            ],
            [
              -66.284812,
              18.059722
            ],
            [
              -66.297944,
              18.056942
            ],
            [
              -66.331244,
              18.015891
            ],
            [
              -66.342607,
              17.993561
            ]
          ]
        ]
      ]
    }
  }
]
};