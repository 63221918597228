export const counties_mo = {
  "type":"FeatureCollection","name":"mo_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "185",
      "COUNTYNS": "00758547",
      "AFFGEOID": "0500000US29185",
      "GEOID": "29185",
      "NAME": "St. Clair",
      "LSAD": "06",
      "ALAND": 1748192189,
      "AWATER": 70138955,
      "County_state": "St. Clair,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.058285,
              38.036627
            ],
            [
              -94.065675,
              37.900987
            ],
            [
              -93.808953,
              37.892705
            ],
            [
              -93.811402,
              37.834571
            ],
            [
              -93.628404,
              37.829435
            ],
            [
              -93.573202,
              37.828035
            ],
            [
              -93.568709,
              37.914918
            ],
            [
              -93.512103,
              37.913535
            ],
            [
              -93.503946,
              38.073123
            ],
            [
              -93.49728,
              38.199087
            ],
            [
              -93.523484,
              38.207216
            ],
            [
              -93.536682,
              38.205657
            ],
            [
              -93.557027,
              38.219673
            ],
            [
              -93.569525,
              38.218944
            ],
            [
              -93.557009,
              38.201074
            ],
            [
              -94.049895,
              38.213985
            ],
            [
              -94.058285,
              38.036627
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "119",
      "COUNTYNS": "00758518",
      "AFFGEOID": "0500000US29119",
      "GEOID": "29119",
      "NAME": "McDonald",
      "LSAD": "06",
      "ALAND": 1397247177,
      "AWATER": 520251,
      "County_state": "McDonald,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.618307,
              36.76656
            ],
            [
              -94.61813,
              36.701423
            ],
            [
              -94.618025,
              36.66943
            ],
            [
              -94.61801942428959,
              36.6679212127705
            ],
            [
              -94.617815,
              36.612604
            ],
            [
              -94.617865,
              36.606851
            ],
            [
              -94.617853,
              36.59912
            ],
            [
              -94.617814,
              36.577732
            ],
            [
              -94.617832380978,
              36.5687078256061
            ],
            [
              -94.617897,
              36.536983
            ],
            [
              -94.617868,
              36.53609
            ],
            [
              -94.617997,
              36.53428
            ],
            [
              -94.617883,
              36.517799
            ],
            [
              -94.617877,
              36.514999
            ],
            [
              -94.617919,
              36.499414
            ],
            [
              -94.55929,
              36.499496
            ],
            [
              -94.519478,
              36.499214
            ],
            [
              -94.361203,
              36.4996
            ],
            [
              -94.269279803981,
              36.4992308054074
            ],
            [
              -94.1814127473658,
              36.4988779016402
            ],
            [
              -94.111473,
              36.498597
            ],
            [
              -94.110673,
              36.498587
            ],
            [
              -94.100252,
              36.49867
            ],
            [
              -94.098588,
              36.498676
            ],
            [
              -94.077089,
              36.49873
            ],
            [
              -94.067651,
              36.738374
            ],
            [
              -94.068898,
              36.747818
            ],
            [
              -94.618307,
              36.76656
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "225",
      "COUNTYNS": "00758565",
      "AFFGEOID": "0500000US29225",
      "GEOID": "29225",
      "NAME": "Webster",
      "LSAD": "06",
      "ALAND": 1534730262,
      "AWATER": 3110547,
      "County_state": "Webster,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.07338,
              37.414989
            ],
            [
              -93.076779,
              37.27004
            ],
            [
              -93.061541,
              37.269832
            ],
            [
              -93.062142,
              37.235117
            ],
            [
              -93.065274,
              37.088694
            ],
            [
              -92.993629,
              37.087667
            ],
            [
              -92.902922,
              37.085221
            ],
            [
              -92.903273,
              37.070651
            ],
            [
              -92.82467,
              37.068674
            ],
            [
              -92.685867,
              37.067051
            ],
            [
              -92.67897,
              37.263149
            ],
            [
              -92.692303,
              37.263514
            ],
            [
              -92.686671,
              37.481545
            ],
            [
              -92.852107,
              37.484083
            ],
            [
              -92.853481,
              37.48397
            ],
            [
              -93.071584,
              37.487444
            ],
            [
              -93.07338,
              37.414989
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "219",
      "COUNTYNS": "00758562",
      "AFFGEOID": "0500000US29219",
      "GEOID": "29219",
      "NAME": "Warren",
      "LSAD": "06",
      "ALAND": 1110070358,
      "AWATER": 23748217,
      "County_state": "Warren,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.415832,
              38.8453
            ],
            [
              -91.418637,
              38.709778
            ],
            [
              -91.397327,
              38.708444
            ],
            [
              -91.369192,
              38.699324
            ],
            [
              -91.341682,
              38.697415
            ],
            [
              -91.319022,
              38.708368
            ],
            [
              -91.313093,
              38.706715
            ],
            [
              -91.259813,
              38.666601
            ],
            [
              -91.237423,
              38.654966
            ],
            [
              -91.2276,
              38.642978
            ],
            [
              -91.226547,
              38.621567
            ],
            [
              -91.182957,
              38.607896
            ],
            [
              -91.132535,
              38.598279
            ],
            [
              -91.11291,
              38.599606
            ],
            [
              -91.077324,
              38.609343
            ],
            [
              -91.055898,
              38.608267
            ],
            [
              -91.032325,
              38.59349
            ],
            [
              -91.02143,
              38.57352
            ],
            [
              -91.007668,
              38.562492
            ],
            [
              -90.99241,
              38.555126
            ],
            [
              -90.964461,
              38.547545
            ],
            [
              -90.96303,
              38.70148
            ],
            [
              -90.962577,
              38.742262
            ],
            [
              -90.958536,
              38.870865
            ],
            [
              -91.07373,
              38.872738
            ],
            [
              -91.111251,
              38.872709
            ],
            [
              -91.110187,
              38.931901
            ],
            [
              -91.189348,
              38.932151
            ],
            [
              -91.18839,
              38.992141
            ],
            [
              -91.264287,
              38.992762
            ],
            [
              -91.264927,
              38.843833
            ],
            [
              -91.301894,
              38.845851
            ],
            [
              -91.33885,
              38.84453
            ],
            [
              -91.415832,
              38.8453
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "043",
      "COUNTYNS": "00758476",
      "AFFGEOID": "0500000US29043",
      "GEOID": "29043",
      "NAME": "Christian",
      "LSAD": "06",
      "ALAND": 1457243595,
      "AWATER": 3012641,
      "County_state": "Christian,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.608899,
              37.098153
            ],
            [
              -93.610126,
              36.99581
            ],
            [
              -93.465446,
              36.993842
            ],
            [
              -93.337451,
              36.992494
            ],
            [
              -93.342041,
              36.817301
            ],
            [
              -93.304359,
              36.816866
            ],
            [
              -93.226041,
              36.815444
            ],
            [
              -93.146854,
              36.81382
            ],
            [
              -92.909336,
              36.809178
            ],
            [
              -92.903273,
              37.070651
            ],
            [
              -92.902922,
              37.085221
            ],
            [
              -92.993629,
              37.087667
            ],
            [
              -93.065274,
              37.088694
            ],
            [
              -93.173173,
              37.090885
            ],
            [
              -93.263985,
              37.093751
            ],
            [
              -93.297659,
              37.094451
            ],
            [
              -93.325301,
              37.094652
            ],
            [
              -93.3896,
              37.09549
            ],
            [
              -93.46292,
              37.09637
            ],
            [
              -93.608899,
              37.098153
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "053",
      "COUNTYNS": "00758481",
      "AFFGEOID": "0500000US29053",
      "GEOID": "29053",
      "NAME": "Cooper",
      "LSAD": "06",
      "ALAND": 1462738176,
      "AWATER": 11434005,
      "County_state": "Cooper,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.050453,
              38.928244
            ],
            [
              -93.059974,
              38.693077
            ],
            [
              -92.950512,
              38.68577
            ],
            [
              -92.839837,
              38.683974
            ],
            [
              -92.834172,
              38.684834
            ],
            [
              -92.726692,
              38.684077
            ],
            [
              -92.617147,
              38.680013
            ],
            [
              -92.616842,
              38.687949
            ],
            [
              -92.616652,
              38.694466
            ],
            [
              -92.513043,
              38.89559
            ],
            [
              -92.498377,
              38.922084
            ],
            [
              -92.508657,
              38.93271
            ],
            [
              -92.558086,
              38.970692
            ],
            [
              -92.594126,
              38.967537
            ],
            [
              -92.665822,
              38.976237
            ],
            [
              -92.693002,
              38.975957
            ],
            [
              -92.715643,
              38.984011
            ],
            [
              -92.791809,
              38.972044
            ],
            [
              -92.852628,
              38.982183
            ],
            [
              -92.876046,
              39.000836
            ],
            [
              -92.914477,
              39.021188
            ],
            [
              -92.934569,
              39.064547
            ],
            [
              -93.048471,
              38.972418
            ],
            [
              -93.050453,
              38.928244
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "105",
      "COUNTYNS": "00758507",
      "AFFGEOID": "0500000US29105",
      "GEOID": "29105",
      "NAME": "Laclede",
      "LSAD": "06",
      "ALAND": 1980565357,
      "AWATER": 8515352,
      "County_state": "Laclede,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.858116,
              37.82342
            ],
            [
              -92.859913,
              37.721239
            ],
            [
              -92.846281,
              37.721039
            ],
            [
              -92.853481,
              37.48397
            ],
            [
              -92.852107,
              37.484083
            ],
            [
              -92.686671,
              37.481545
            ],
            [
              -92.559,
              37.478734
            ],
            [
              -92.252261,
              37.472944
            ],
            [
              -92.249463,
              37.604543
            ],
            [
              -92.248865,
              37.648442
            ],
            [
              -92.304028,
              37.670648
            ],
            [
              -92.413669,
              37.713941
            ],
            [
              -92.409727,
              37.847013
            ],
            [
              -92.409231,
              37.85918
            ],
            [
              -92.419453,
              37.859762
            ],
            [
              -92.449459,
              37.835359
            ],
            [
              -92.452494,
              37.824284
            ],
            [
              -92.480354,
              37.829018
            ],
            [
              -92.501467,
              37.827735
            ],
            [
              -92.513696,
              37.811962
            ],
            [
              -92.53168,
              37.811139
            ],
            [
              -92.549074,
              37.800539
            ],
            [
              -92.564338,
              37.805838
            ],
            [
              -92.570718,
              37.822625
            ],
            [
              -92.600077,
              37.840338
            ],
            [
              -92.599577,
              37.852238
            ],
            [
              -92.614679,
              37.863538
            ],
            [
              -92.620181,
              37.892521
            ],
            [
              -92.855384,
              37.895736
            ],
            [
              -92.858116,
              37.82342
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "117",
      "COUNTYNS": "00758513",
      "AFFGEOID": "0500000US29117",
      "GEOID": "29117",
      "NAME": "Livingston",
      "LSAD": "06",
      "ALAND": 1378706292,
      "AWATER": 16156752,
      "County_state": "Livingston,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.761973,
              39.959552
            ],
            [
              -93.758992,
              39.784674
            ],
            [
              -93.758357,
              39.611407
            ],
            [
              -93.278098,
              39.615632
            ],
            [
              -93.261386,
              39.623253
            ],
            [
              -93.283418,
              39.651433
            ],
            [
              -93.287328,
              39.682023
            ],
            [
              -93.266968,
              39.703562
            ],
            [
              -93.364808,
              39.703803
            ],
            [
              -93.361422,
              39.880137
            ],
            [
              -93.361957,
              39.967606
            ],
            [
              -93.761973,
              39.959552
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "011",
      "COUNTYNS": "00758460",
      "AFFGEOID": "0500000US29011",
      "GEOID": "29011",
      "NAME": "Barton",
      "LSAD": "06",
      "ALAND": 1533062380,
      "AWATER": 12440434,
      "County_state": "Barton,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.617625,
              37.367576
            ],
            [
              -94.617626,
              37.367445
            ],
            [
              -94.617537,
              37.364355
            ],
            [
              -94.61753771716201,
              37.3641671035633
            ],
            [
              -94.082083,
              37.349291
            ],
            [
              -94.075385,
              37.58157
            ],
            [
              -94.073524,
              37.639722
            ],
            [
              -94.6175754334997,
              37.6535765775648
            ],
            [
              -94.617477,
              37.63717
            ],
            [
              -94.6173,
              37.610495
            ],
            [
              -94.617428,
              37.609522
            ],
            [
              -94.617283,
              37.571896
            ],
            [
              -94.617315,
              37.571499
            ],
            [
              -94.617081,
              37.567013
            ],
            [
              -94.61716,
              37.557308
            ],
            [
              -94.617186,
              37.553485
            ],
            [
              -94.616908,
              37.527804
            ],
            [
              -94.616789,
              37.52151
            ],
            [
              -94.617023,
              37.483765
            ],
            [
              -94.617183,
              37.469665
            ],
            [
              -94.61718,
              37.465203
            ],
            [
              -94.617222,
              37.460476
            ],
            [
              -94.617205,
              37.460373
            ],
            [
              -94.617201,
              37.454788
            ],
            [
              -94.6172009810493,
              37.4547838885303
            ],
            [
              -94.617132,
              37.439818
            ],
            [
              -94.617265,
              37.425536
            ],
            [
              -94.617511,
              37.410909
            ],
            [
              -94.617557,
              37.396375
            ],
            [
              -94.617625,
              37.367576
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "145",
      "COUNTYNS": "00758527",
      "AFFGEOID": "0500000US29145",
      "GEOID": "29145",
      "NAME": "Newton",
      "LSAD": "06",
      "ALAND": 1618104260,
      "AWATER": 4717267,
      "County_state": "Newton,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.618658,
              36.880064
            ],
            [
              -94.61838,
              36.84732
            ],
            [
              -94.618307,
              36.76656
            ],
            [
              -94.068898,
              36.747818
            ],
            [
              -94.06274,
              36.931775
            ],
            [
              -94.062363,
              36.946395
            ],
            [
              -94.06108,
              36.989851
            ],
            [
              -94.059211,
              37.048127
            ],
            [
              -94.295404,
              37.053234
            ],
            [
              -94.40548,
              37.05424
            ],
            [
              -94.415509,
              37.054333
            ],
            [
              -94.426301,
              37.054463
            ],
            [
              -94.442036,
              37.054648
            ],
            [
              -94.445457,
              37.054679
            ],
            [
              -94.564402,
              37.055846
            ],
            [
              -94.576364,
              37.05615
            ],
            [
              -94.6178750047381,
              37.0567971439288
            ],
            [
              -94.617965,
              37.040537
            ],
            [
              -94.617995,
              37.009016
            ],
            [
              -94.617964,
              36.998905
            ],
            [
              -94.618049,
              36.996208
            ],
            [
              -94.618031,
              36.994704
            ],
            [
              -94.618026,
              36.950158
            ],
            [
              -94.618109,
              36.946564
            ],
            [
              -94.618166,
              36.937584
            ],
            [
              -94.618295,
              36.929647
            ],
            [
              -94.618207,
              36.926236
            ],
            [
              -94.6182075266432,
              36.926132328535495
            ],
            [
              -94.618282,
              36.911472
            ],
            [
              -94.618243,
              36.897027
            ],
            [
              -94.618658,
              36.880064
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "209",
      "COUNTYNS": "00758557",
      "AFFGEOID": "0500000US29209",
      "GEOID": "29209",
      "NAME": "Stone",
      "LSAD": "06",
      "ALAND": 1201843614,
      "AWATER": 121059169,
      "County_state": "Stone,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.611853,
              36.88608
            ],
            [
              -93.612929,
              36.820562
            ],
            [
              -93.5738,
              36.820032
            ],
            [
              -93.5842819998356,
              36.498896002062004
            ],
            [
              -93.584281,
              36.498896
            ],
            [
              -93.514512,
              36.498881
            ],
            [
              -93.507408,
              36.498911
            ],
            [
              -93.426989,
              36.498585
            ],
            [
              -93.396079,
              36.498669
            ],
            [
              -93.394718,
              36.498519
            ],
            [
              -93.315337,
              36.498408
            ],
            [
              -93.31532440839989,
              36.4984079629635
            ],
            [
              -93.313648,
              36.557583
            ],
            [
              -93.30956,
              36.729997
            ],
            [
              -93.304359,
              36.816866
            ],
            [
              -93.342041,
              36.817301
            ],
            [
              -93.337451,
              36.992494
            ],
            [
              -93.465446,
              36.993842
            ],
            [
              -93.610126,
              36.99581
            ],
            [
              -93.611003,
              36.922831
            ],
            [
              -93.611853,
              36.88608
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "061",
      "COUNTYNS": "00758485",
      "AFFGEOID": "0500000US29061",
      "GEOID": "29061",
      "NAME": "Daviess",
      "LSAD": "06",
      "ALAND": 1458906364,
      "AWATER": 14913879,
      "County_state": "Daviess,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.216696,
              40.136626
            ],
            [
              -94.2188,
              40.034885
            ],
            [
              -94.203457,
              40.034539
            ],
            [
              -94.205844,
              39.788954
            ],
            [
              -93.872301,
              39.786834
            ],
            [
              -93.758992,
              39.784674
            ],
            [
              -93.761973,
              39.959552
            ],
            [
              -93.764021,
              40.132909
            ],
            [
              -94.216696,
              40.136626
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "199",
      "COUNTYNS": "00758552",
      "AFFGEOID": "0500000US29199",
      "GEOID": "29199",
      "NAME": "Scotland",
      "LSAD": "06",
      "ALAND": 1130718763,
      "AWATER": 7047471,
      "County_state": "Scotland,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.350776,
              40.597274
            ],
            [
              -92.35080671229349,
              40.597273187449304
            ],
            [
              -92.349975,
              40.346505
            ],
            [
              -92.349923,
              40.30637
            ],
            [
              -92.347801,
              40.302756
            ],
            [
              -91.949747,
              40.300832
            ],
            [
              -91.94311318663401,
              40.60584170797
            ],
            [
              -91.947708,
              40.605471
            ],
            [
              -91.970988,
              40.605112
            ],
            [
              -91.998683,
              40.604433
            ],
            [
              -92.029649,
              40.603713
            ],
            [
              -92.067904,
              40.602648
            ],
            [
              -92.069521,
              40.602772
            ],
            [
              -92.082339,
              40.602176
            ],
            [
              -92.0832,
              40.602244
            ],
            [
              -92.092875,
              40.602082
            ],
            [
              -92.096387,
              40.60183
            ],
            [
              -92.17978,
              40.600529
            ],
            [
              -92.196162,
              40.600069
            ],
            [
              -92.201669,
              40.59998
            ],
            [
              -92.217603,
              40.599832
            ],
            [
              -92.236484,
              40.599531
            ],
            [
              -92.298754,
              40.598469
            ],
            [
              -92.331205,
              40.597805
            ],
            [
              -92.331445,
              40.597714
            ],
            [
              -92.350776,
              40.597274
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "039",
      "COUNTYNS": "00758474",
      "AFFGEOID": "0500000US29039",
      "GEOID": "29039",
      "NAME": "Cedar",
      "LSAD": "06",
      "ALAND": 1228905197,
      "AWATER": 62603495,
      "County_state": "Cedar,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.073524,
              37.639722
            ],
            [
              -94.075385,
              37.58157
            ],
            [
              -93.616033,
              37.572689
            ],
            [
              -93.61381,
              37.630927
            ],
            [
              -93.609489,
              37.741755
            ],
            [
              -93.632439,
              37.742302
            ],
            [
              -93.629742,
              37.797101
            ],
            [
              -93.628404,
              37.829435
            ],
            [
              -93.811402,
              37.834571
            ],
            [
              -93.808953,
              37.892705
            ],
            [
              -94.065675,
              37.900987
            ],
            [
              -94.073342,
              37.755706
            ],
            [
              -94.068716,
              37.755568
            ],
            [
              -94.073117,
              37.654243
            ],
            [
              -94.073524,
              37.639722
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "159",
      "COUNTYNS": "00758534",
      "AFFGEOID": "0500000US29159",
      "GEOID": "29159",
      "NAME": "Pettis",
      "LSAD": "06",
      "ALAND": 1766949637,
      "AWATER": 10503983,
      "County_state": "Pettis,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.51103,
              38.55621
            ],
            [
              -93.512743,
              38.512476
            ],
            [
              -93.291851,
              38.506321
            ],
            [
              -93.290454,
              38.535388
            ],
            [
              -93.067291,
              38.529995
            ],
            [
              -93.063452,
              38.620832
            ],
            [
              -93.059974,
              38.693077
            ],
            [
              -93.050453,
              38.928244
            ],
            [
              -93.105535,
              38.931503
            ],
            [
              -93.27376,
              38.935492
            ],
            [
              -93.273782,
              38.93512
            ],
            [
              -93.496377,
              38.942837
            ],
            [
              -93.497278,
              38.92843
            ],
            [
              -93.498616,
              38.8993
            ],
            [
              -93.493733,
              38.888203
            ],
            [
              -93.499941,
              38.781198
            ],
            [
              -93.500848,
              38.766688
            ],
            [
              -93.505503,
              38.694133
            ],
            [
              -93.506424,
              38.679589
            ],
            [
              -93.51103,
              38.55621
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "015",
      "COUNTYNS": "00758462",
      "AFFGEOID": "0500000US29015",
      "GEOID": "29015",
      "NAME": "Benton",
      "LSAD": "06",
      "ALAND": 1823463551,
      "AWATER": 125579913,
      "County_state": "Benton,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.518259,
              38.321792
            ],
            [
              -93.523484,
              38.207216
            ],
            [
              -93.49728,
              38.199087
            ],
            [
              -93.503946,
              38.073123
            ],
            [
              -93.065199,
              38.062479
            ],
            [
              -93.064554,
              38.077134
            ],
            [
              -93.059073,
              38.185685
            ],
            [
              -93.082164,
              38.186503
            ],
            [
              -93.077692,
              38.263092
            ],
            [
              -93.075704,
              38.304768
            ],
            [
              -93.072054,
              38.442274
            ],
            [
              -93.067291,
              38.529995
            ],
            [
              -93.290454,
              38.535388
            ],
            [
              -93.291851,
              38.506321
            ],
            [
              -93.512743,
              38.512476
            ],
            [
              -93.511958,
              38.446814
            ],
            [
              -93.518259,
              38.321792
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "067",
      "COUNTYNS": "00758488",
      "AFFGEOID": "0500000US29067",
      "GEOID": "29067",
      "NAME": "Douglas",
      "LSAD": "06",
      "ALAND": 2107273796,
      "AWATER": 2520175,
      "County_state": "Douglas,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.903273,
              37.070651
            ],
            [
              -92.909336,
              36.809178
            ],
            [
              -92.764869,
              36.806097
            ],
            [
              -92.112346,
              36.794231
            ],
            [
              -92.095982,
              36.794011
            ],
            [
              -92.088708,
              37.056248
            ],
            [
              -92.251359,
              37.059653
            ],
            [
              -92.685867,
              37.067051
            ],
            [
              -92.82467,
              37.068674
            ],
            [
              -92.903273,
              37.070651
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "065",
      "COUNTYNS": "00758487",
      "AFFGEOID": "0500000US29065",
      "GEOID": "29065",
      "NAME": "Dent",
      "LSAD": "06",
      "ALAND": 1949640578,
      "AWATER": 4519381,
      "County_state": "Dent,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.806219,
              37.791286
            ],
            [
              -91.809105,
              37.598863
            ],
            [
              -91.754795,
              37.598768
            ],
            [
              -91.75504,
              37.42411
            ],
            [
              -91.646626,
              37.422731
            ],
            [
              -91.211863,
              37.415277
            ],
            [
              -91.210984,
              37.501911
            ],
            [
              -91.314236,
              37.505132
            ],
            [
              -91.312458,
              37.592824
            ],
            [
              -91.209437,
              37.588811
            ],
            [
              -91.155073,
              37.588092
            ],
            [
              -91.153345,
              37.69734
            ],
            [
              -91.310655,
              37.700048
            ],
            [
              -91.308311,
              37.787186
            ],
            [
              -91.528797,
              37.788989
            ],
            [
              -91.734,
              37.791057
            ],
            [
              -91.806219,
              37.791286
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "203",
      "COUNTYNS": "00758554",
      "AFFGEOID": "0500000US29203",
      "GEOID": "29203",
      "NAME": "Shannon",
      "LSAD": "06",
      "ALAND": 2599883711,
      "AWATER": 464011,
      "County_state": "Shannon,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.656339,
              36.960627
            ],
            [
              -91.658111,
              36.888724
            ],
            [
              -91.500138,
              36.886898
            ],
            [
              -91.478122,
              36.885819
            ],
            [
              -91.250129,
              36.88515
            ],
            [
              -91.22283,
              36.883857
            ],
            [
              -91.21715,
              37.086197
            ],
            [
              -91.10437,
              37.086663
            ],
            [
              -91.066736,
              37.093775
            ],
            [
              -91.017766,
              37.095677
            ],
            [
              -91.017163,
              37.143478
            ],
            [
              -91.035138,
              37.140181
            ],
            [
              -91.034346,
              37.169343
            ],
            [
              -91.071046,
              37.162712
            ],
            [
              -91.070634,
              37.177573
            ],
            [
              -91.088865,
              37.174179
            ],
            [
              -91.08826,
              37.203193
            ],
            [
              -91.126028,
              37.19948
            ],
            [
              -91.123152,
              37.254504
            ],
            [
              -91.159323,
              37.254936
            ],
            [
              -91.158429,
              37.312917
            ],
            [
              -91.176158,
              37.312592
            ],
            [
              -91.176068,
              37.341704
            ],
            [
              -91.175405,
              37.414805
            ],
            [
              -91.211863,
              37.415277
            ],
            [
              -91.646626,
              37.422731
            ],
            [
              -91.647702,
              37.248371
            ],
            [
              -91.655607,
              37.048925
            ],
            [
              -91.656339,
              36.960627
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "211",
      "COUNTYNS": "00758558",
      "AFFGEOID": "0500000US29211",
      "GEOID": "29211",
      "NAME": "Sullivan",
      "LSAD": "06",
      "ALAND": 1678264466,
      "AWATER": 9123184,
      "County_state": "Sullivan,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.366935,
              40.382999
            ],
            [
              -93.367214,
              40.266314
            ],
            [
              -93.366556,
              40.033099
            ],
            [
              -93.361951,
              40.033098
            ],
            [
              -92.899877,
              40.036626
            ],
            [
              -92.865015,
              40.036791
            ],
            [
              -92.856191,
              40.037253
            ],
            [
              -92.855629,
              40.342736
            ],
            [
              -92.855313,
              40.385894
            ],
            [
              -93.366935,
              40.382999
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "049",
      "COUNTYNS": "00758479",
      "AFFGEOID": "0500000US29049",
      "GEOID": "29049",
      "NAME": "Clinton",
      "LSAD": "06",
      "ALAND": 1085096687,
      "AWATER": 11719847,
      "County_state": "Clinton,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.602257,
              39.747195
            ],
            [
              -94.602124,
              39.616743
            ],
            [
              -94.601246,
              39.530372
            ],
            [
              -94.60104,
              39.470415
            ],
            [
              -94.600819,
              39.456155
            ],
            [
              -94.596507,
              39.455875
            ],
            [
              -94.463372,
              39.455429
            ],
            [
              -94.210642,
              39.454682
            ],
            [
              -94.208423,
              39.5272
            ],
            [
              -94.205961,
              39.745841
            ],
            [
              -94.602257,
              39.747195
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "205",
      "COUNTYNS": "00758555",
      "AFFGEOID": "0500000US29205",
      "GEOID": "29205",
      "NAME": "Shelby",
      "LSAD": "06",
      "ALAND": 1297230230,
      "AWATER": 3764605,
      "County_state": "Shelby,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.287882,
              39.95249
            ],
            [
              -92.299316,
              39.605344
            ],
            [
              -92.19293,
              39.604207
            ],
            [
              -92.186529,
              39.608104
            ],
            [
              -92.184986,
              39.662932
            ],
            [
              -91.846743,
              39.658513
            ],
            [
              -91.840353,
              39.948235
            ],
            [
              -91.952726,
              39.949426
            ],
            [
              -92.287882,
              39.95249
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "221",
      "COUNTYNS": "00758563",
      "AFFGEOID": "0500000US29221",
      "GEOID": "29221",
      "NAME": "Washington",
      "LSAD": "06",
      "ALAND": 1968056216,
      "AWATER": 6778977,
      "County_state": "Washington,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.096354,
              37.870838
            ],
            [
              -91.100017,
              37.740012
            ],
            [
              -90.947182,
              37.73806
            ],
            [
              -90.874134,
              37.734196
            ],
            [
              -90.783124,
              37.736635
            ],
            [
              -90.762091,
              37.738517
            ],
            [
              -90.751589,
              37.737142
            ],
            [
              -90.645135,
              37.734813
            ],
            [
              -90.641593,
              37.935731
            ],
            [
              -90.641491,
              37.950026
            ],
            [
              -90.63998,
              38.076548
            ],
            [
              -90.684776,
              38.08627
            ],
            [
              -90.681399,
              38.102681
            ],
            [
              -90.780185,
              38.204112
            ],
            [
              -90.895457,
              38.204937
            ],
            [
              -91.095765,
              38.204083
            ],
            [
              -91.094779,
              37.957889
            ],
            [
              -91.096354,
              37.870838
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "001",
      "COUNTYNS": "00765805",
      "AFFGEOID": "0500000US29001",
      "GEOID": "29001",
      "NAME": "Adair",
      "LSAD": "06",
      "ALAND": 1469362052,
      "AWATER": 5468507,
      "County_state": "Adair,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.855629,
              40.342736
            ],
            [
              -92.856191,
              40.037253
            ],
            [
              -92.847477,
              40.037301
            ],
            [
              -92.685819,
              40.038289
            ],
            [
              -92.673681,
              40.038058
            ],
            [
              -92.345064,
              40.03721
            ],
            [
              -92.346455,
              40.215888
            ],
            [
              -92.347801,
              40.302756
            ],
            [
              -92.349923,
              40.30637
            ],
            [
              -92.349975,
              40.346505
            ],
            [
              -92.684167,
              40.343466
            ],
            [
              -92.686852,
              40.343427
            ],
            [
              -92.855629,
              40.342736
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "169",
      "COUNTYNS": "00758539",
      "AFFGEOID": "0500000US29169",
      "GEOID": "29169",
      "NAME": "Pulaski",
      "LSAD": "06",
      "ALAND": 1416985288,
      "AWATER": 11341981,
      "County_state": "Pulaski,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.409727,
              37.847013
            ],
            [
              -92.413669,
              37.713941
            ],
            [
              -92.304028,
              37.670648
            ],
            [
              -92.248865,
              37.648442
            ],
            [
              -92.249463,
              37.604543
            ],
            [
              -92.183261,
              37.605243
            ],
            [
              -92.029258,
              37.602542
            ],
            [
              -92.0246,
              37.793721
            ],
            [
              -92.022863,
              37.88074
            ],
            [
              -92.021165,
              38.010639
            ],
            [
              -92.182005,
              38.015031
            ],
            [
              -92.18537,
              38.016338
            ],
            [
              -92.406275,
              38.021179
            ],
            [
              -92.406872,
              37.977802
            ],
            [
              -92.409231,
              37.85918
            ],
            [
              -92.409727,
              37.847013
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "093",
      "COUNTYNS": "00758501",
      "AFFGEOID": "0500000US29093",
      "GEOID": "29093",
      "NAME": "Iron",
      "LSAD": "06",
      "ALAND": 1425177705,
      "AWATER": 4662498,
      "County_state": "Iron,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.153345,
              37.69734
            ],
            [
              -91.155073,
              37.588092
            ],
            [
              -91.136788,
              37.588144
            ],
            [
              -91.100665,
              37.587762
            ],
            [
              -90.987055,
              37.603852
            ],
            [
              -90.790947,
              37.602118
            ],
            [
              -90.768695,
              37.603011
            ],
            [
              -90.772949,
              37.373659
            ],
            [
              -90.74972,
              37.372796
            ],
            [
              -90.753627,
              37.270113
            ],
            [
              -90.735453,
              37.27104
            ],
            [
              -90.661775,
              37.274767
            ],
            [
              -90.642761,
              37.272216
            ],
            [
              -90.550603,
              37.272608
            ],
            [
              -90.549729,
              37.317594
            ],
            [
              -90.547406,
              37.59841
            ],
            [
              -90.535144,
              37.5984
            ],
            [
              -90.535016,
              37.643641
            ],
            [
              -90.647874,
              37.642809
            ],
            [
              -90.645135,
              37.734813
            ],
            [
              -90.751589,
              37.737142
            ],
            [
              -90.762091,
              37.738517
            ],
            [
              -90.783124,
              37.736635
            ],
            [
              -90.874134,
              37.734196
            ],
            [
              -90.947182,
              37.73806
            ],
            [
              -91.100017,
              37.740012
            ],
            [
              -91.146521,
              37.740811
            ],
            [
              -91.153345,
              37.69734
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "025",
      "COUNTYNS": "00758467",
      "AFFGEOID": "0500000US29025",
      "GEOID": "29025",
      "NAME": "Caldwell",
      "LSAD": "06",
      "ALAND": 1104350543,
      "AWATER": 8402198,
      "County_state": "Caldwell,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.205961,
              39.745841
            ],
            [
              -94.208423,
              39.5272
            ],
            [
              -94.153327,
              39.526229
            ],
            [
              -93.978242,
              39.524392
            ],
            [
              -93.870196,
              39.525865
            ],
            [
              -93.759183,
              39.524558
            ],
            [
              -93.758357,
              39.611407
            ],
            [
              -93.758992,
              39.784674
            ],
            [
              -93.872301,
              39.786834
            ],
            [
              -94.205844,
              39.788954
            ],
            [
              -94.205961,
              39.745841
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "155",
      "COUNTYNS": "00758532",
      "AFFGEOID": "0500000US29155",
      "GEOID": "29155",
      "NAME": "Pemiscot",
      "LSAD": "06",
      "ALAND": 1275622233,
      "AWATER": 54164612,
      "County_state": "Pemiscot,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.96131,
              36.388803
            ],
            [
              -89.960121,
              36.190824
            ],
            [
              -89.9593753420007,
              35.9990200098294
            ],
            [
              -89.90141276984102,
              35.999363637821794
            ],
            [
              -89.901183,
              35.999365
            ],
            [
              -89.896508,
              35.999432
            ],
            [
              -89.875586,
              35.999562
            ],
            [
              -89.875085,
              35.999578
            ],
            [
              -89.87459,
              35.999575
            ],
            [
              -89.86901,
              35.99964
            ],
            [
              -89.770255,
              36.000524
            ],
            [
              -89.769973,
              36.000536
            ],
            [
              -89.737648,
              36.000567
            ],
            [
              -89.737564,
              36.000522
            ],
            [
              -89.733095,
              36.000608
            ],
            [
              -89.706932,
              36.000981
            ],
            [
              -89.705677,
              36.005018
            ],
            [
              -89.703571,
              36.00804
            ],
            [
              -89.692437,
              36.020507
            ],
            [
              -89.688577,
              36.029238
            ],
            [
              -89.687254,
              36.034048
            ],
            [
              -89.684439,
              36.051719
            ],
            [
              -89.681946,
              36.072336
            ],
            [
              -89.680029,
              36.082494
            ],
            [
              -89.678821,
              36.084636
            ],
            [
              -89.672463,
              36.091837
            ],
            [
              -89.666598,
              36.095802
            ],
            [
              -89.657709,
              36.099128
            ],
            [
              -89.64302,
              36.10362
            ],
            [
              -89.628305,
              36.106853
            ],
            [
              -89.625078,
              36.108131
            ],
            [
              -89.615128,
              36.113816
            ],
            [
              -89.601936,
              36.11947
            ],
            [
              -89.598946,
              36.121778
            ],
            [
              -89.594,
              36.12719
            ],
            [
              -89.59307,
              36.129699
            ],
            [
              -89.592102,
              36.135637
            ],
            [
              -89.591605,
              36.144096
            ],
            [
              -89.592206,
              36.15012
            ],
            [
              -89.594397,
              36.155457
            ],
            [
              -89.600871,
              36.164558
            ],
            [
              -89.607004,
              36.171179
            ],
            [
              -89.618228,
              36.179966
            ],
            [
              -89.623804,
              36.183128
            ],
            [
              -89.629452,
              36.185382
            ],
            [
              -89.636893,
              36.188951
            ],
            [
              -89.64479,
              36.194101
            ],
            [
              -89.654876,
              36.20153
            ],
            [
              -89.664144,
              36.20652
            ],
            [
              -89.679548,
              36.215911
            ],
            [
              -89.68509280107669,
              36.219745991602295
            ],
            [
              -89.69263,
              36.224959
            ],
            [
              -89.699677,
              36.230821
            ],
            [
              -89.704459,
              36.235468
            ],
            [
              -89.70525,
              36.236568
            ],
            [
              -89.705545,
              36.238136
            ],
            [
              -89.705328,
              36.239898
            ],
            [
              -89.703511,
              36.243412
            ],
            [
              -89.699817,
              36.248384
            ],
            [
              -89.698568,
              36.250591
            ],
            [
              -89.695235,
              36.252766
            ],
            [
              -89.691308,
              36.252079
            ],
            [
              -89.686229,
              36.249507
            ],
            [
              -89.678046,
              36.248284
            ],
            [
              -89.652518,
              36.250692
            ],
            [
              -89.642182,
              36.249486
            ],
            [
              -89.63679,
              36.248079
            ],
            [
              -89.624416,
              36.243305
            ],
            [
              -89.611145,
              36.239271
            ],
            [
              -89.60651,
              36.238328
            ],
            [
              -89.602374,
              36.238106
            ],
            [
              -89.587326,
              36.239484
            ],
            [
              -89.577544,
              36.242262
            ],
            [
              -89.573928,
              36.243843
            ],
            [
              -89.564997,
              36.250067
            ],
            [
              -89.562206,
              36.250909
            ],
            [
              -89.557991,
              36.251037
            ],
            [
              -89.553563,
              36.250341
            ],
            [
              -89.548952,
              36.2482
            ],
            [
              -89.544743,
              36.247484
            ],
            [
              -89.541621,
              36.247891
            ],
            [
              -89.539229,
              36.248821
            ],
            [
              -89.534745,
              36.252576
            ],
            [
              -89.534507,
              36.261802
            ],
            [
              -89.535529,
              36.270541
            ],
            [
              -89.537675,
              36.275279
            ],
            [
              -89.539487,
              36.277368
            ],
            [
              -89.544797,
              36.280458
            ],
            [
              -89.546577,
              36.280439
            ],
            [
              -89.549219,
              36.27875
            ],
            [
              -89.554289,
              36.277751
            ],
            [
              -89.578492,
              36.288317
            ],
            [
              -89.584337,
              36.29334
            ],
            [
              -89.58982,
              36.296814
            ],
            [
              -89.611819,
              36.309088
            ],
            [
              -89.619242,
              36.320726
            ],
            [
              -89.620255,
              36.323006
            ],
            [
              -89.6198,
              36.329546
            ],
            [
              -89.615841,
              36.336085
            ],
            [
              -89.610689,
              36.340442
            ],
            [
              -89.605668,
              36.342234
            ],
            [
              -89.600544,
              36.342985
            ],
            [
              -89.581636,
              36.342357
            ],
            [
              -89.560439,
              36.337746
            ],
            [
              -89.545006,
              36.336809
            ],
            [
              -89.545085,
              36.359996
            ],
            [
              -89.563218,
              36.367031
            ],
            [
              -89.583722,
              36.383028
            ],
            [
              -89.5923,
              36.403805
            ],
            [
              -89.611016,
              36.411212
            ],
            [
              -89.639738,
              36.400788
            ],
            [
              -89.676984,
              36.400998
            ],
            [
              -89.695795,
              36.41639
            ],
            [
              -89.731883,
              36.427636
            ],
            [
              -89.745168,
              36.41842
            ],
            [
              -89.752147,
              36.386396
            ],
            [
              -89.913426,
              36.38645
            ],
            [
              -89.96131,
              36.388803
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "127",
      "COUNTYNS": "00758517",
      "AFFGEOID": "0500000US29127",
      "GEOID": "29127",
      "NAME": "Marion",
      "LSAD": "06",
      "ALAND": 1131627484,
      "AWATER": 19114157,
      "County_state": "Marion,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.840353,
              39.948235
            ],
            [
              -91.846743,
              39.658513
            ],
            [
              -91.715247,
              39.657644
            ],
            [
              -91.715497,
              39.686492
            ],
            [
              -91.3066819759012,
              39.6848809168884
            ],
            [
              -91.317814,
              39.692591
            ],
            [
              -91.331603,
              39.700433
            ],
            [
              -91.3453,
              39.709402
            ],
            [
              -91.352749,
              39.715279
            ],
            [
              -91.367753,
              39.729029
            ],
            [
              -91.370009,
              39.732524
            ],
            [
              -91.369953,
              39.745042
            ],
            [
              -91.367406,
              39.75388
            ],
            [
              -91.366047,
              39.755955
            ],
            [
              -91.365125,
              39.758723
            ],
            [
              -91.365906,
              39.764956
            ],
            [
              -91.365694,
              39.77491
            ],
            [
              -91.364848,
              39.779388
            ],
            [
              -91.361744,
              39.785079
            ],
            [
              -91.361571,
              39.787548
            ],
            [
              -91.363444,
              39.792804
            ],
            [
              -91.367966,
              39.800403
            ],
            [
              -91.375148,
              39.80886
            ],
            [
              -91.377971,
              39.811273
            ],
            [
              -91.385773,
              39.815553
            ],
            [
              -91.397853,
              39.821122
            ],
            [
              -91.406223,
              39.826472
            ],
            [
              -91.414513,
              39.829984
            ],
            [
              -91.429519,
              39.837801
            ],
            [
              -91.432919,
              39.840554
            ],
            [
              -91.436051,
              39.84551
            ],
            [
              -91.446385,
              39.870394
            ],
            [
              -91.447844,
              39.877951
            ],
            [
              -91.446922,
              39.883034
            ],
            [
              -91.443513,
              39.893583
            ],
            [
              -91.428956,
              39.907729
            ],
            [
              -91.420878,
              39.914865
            ],
            [
              -91.41988,
              39.916533
            ],
            [
              -91.418807,
              39.922126
            ],
            [
              -91.41936,
              39.927717
            ],
            [
              -91.421832,
              39.932973
            ],
            [
              -91.425782,
              39.937765
            ],
            [
              -91.429055,
              39.940741
            ],
            [
              -91.43547750086269,
              39.945277915357394
            ],
            [
              -91.840353,
              39.948235
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "063",
      "COUNTYNS": "00758486",
      "AFFGEOID": "0500000US29063",
      "GEOID": "29063",
      "NAME": "DeKalb",
      "LSAD": "06",
      "ALAND": 1091311254,
      "AWATER": 11561078,
      "County_state": "DeKalb,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.605377,
              40.039217
            ],
            [
              -94.602819,
              39.819608
            ],
            [
              -94.602257,
              39.747195
            ],
            [
              -94.205961,
              39.745841
            ],
            [
              -94.205844,
              39.788954
            ],
            [
              -94.203457,
              40.034539
            ],
            [
              -94.2188,
              40.034885
            ],
            [
              -94.329967,
              40.039255
            ],
            [
              -94.605377,
              40.039217
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "071",
      "COUNTYNS": "00758490",
      "AFFGEOID": "0500000US29071",
      "GEOID": "29071",
      "NAME": "Franklin",
      "LSAD": "06",
      "ALAND": 2389734942,
      "AWATER": 20729139,
      "County_state": "Franklin,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.369192,
              38.699324
            ],
            [
              -91.369222,
              38.689538
            ],
            [
              -91.368417,
              38.688158
            ],
            [
              -91.36777,
              38.434604
            ],
            [
              -91.367037,
              38.331491
            ],
            [
              -91.365565,
              38.322086
            ],
            [
              -91.367482,
              38.209741
            ],
            [
              -91.367527,
              38.208022
            ],
            [
              -91.36763,
              38.204013
            ],
            [
              -91.095765,
              38.204083
            ],
            [
              -90.895457,
              38.204937
            ],
            [
              -90.780185,
              38.204112
            ],
            [
              -90.762804,
              38.29555
            ],
            [
              -90.755601,
              38.330052
            ],
            [
              -90.7494,
              38.35819
            ],
            [
              -90.73763,
              38.415453
            ],
            [
              -90.736311,
              38.466643
            ],
            [
              -90.73614,
              38.477114
            ],
            [
              -90.73388,
              38.548944
            ],
            [
              -90.732231,
              38.62137
            ],
            [
              -90.732328,
              38.639302
            ],
            [
              -90.742412,
              38.634563
            ],
            [
              -90.750703,
              38.625103
            ],
            [
              -90.75609,
              38.611318
            ],
            [
              -90.773622,
              38.594617
            ],
            [
              -90.812988,
              38.585554
            ],
            [
              -90.849841,
              38.562257
            ],
            [
              -90.894922,
              38.540963
            ],
            [
              -90.923303,
              38.534134
            ],
            [
              -90.964461,
              38.547545
            ],
            [
              -90.99241,
              38.555126
            ],
            [
              -91.007668,
              38.562492
            ],
            [
              -91.02143,
              38.57352
            ],
            [
              -91.032325,
              38.59349
            ],
            [
              -91.055898,
              38.608267
            ],
            [
              -91.077324,
              38.609343
            ],
            [
              -91.11291,
              38.599606
            ],
            [
              -91.132535,
              38.598279
            ],
            [
              -91.182957,
              38.607896
            ],
            [
              -91.226547,
              38.621567
            ],
            [
              -91.2276,
              38.642978
            ],
            [
              -91.237423,
              38.654966
            ],
            [
              -91.259813,
              38.666601
            ],
            [
              -91.313093,
              38.706715
            ],
            [
              -91.319022,
              38.708368
            ],
            [
              -91.341682,
              38.697415
            ],
            [
              -91.369192,
              38.699324
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "097",
      "COUNTYNS": "00758503",
      "AFFGEOID": "0500000US29097",
      "GEOID": "29097",
      "NAME": "Jasper",
      "LSAD": "06",
      "ALAND": 1653653979,
      "AWATER": 7233655,
      "County_state": "Jasper,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.618505,
              37.181184
            ],
            [
              -94.618473,
              37.174782
            ],
            [
              -94.618351,
              37.160211
            ],
            [
              -94.6183507189726,
              37.160182931508196
            ],
            [
              -94.618072,
              37.132345
            ],
            [
              -94.618075,
              37.129755
            ],
            [
              -94.618212,
              37.113169
            ],
            [
              -94.618151,
              37.103968
            ],
            [
              -94.618059,
              37.096676
            ],
            [
              -94.618088,
              37.093671
            ],
            [
              -94.61809,
              37.093494
            ],
            [
              -94.618082,
              37.086432
            ],
            [
              -94.61812,
              37.085934
            ],
            [
              -94.617982,
              37.075077
            ],
            [
              -94.617875,
              37.056798
            ],
            [
              -94.6178750047381,
              37.0567971439288
            ],
            [
              -94.576364,
              37.05615
            ],
            [
              -94.564402,
              37.055846
            ],
            [
              -94.445457,
              37.054679
            ],
            [
              -94.442036,
              37.054648
            ],
            [
              -94.426301,
              37.054463
            ],
            [
              -94.415509,
              37.054333
            ],
            [
              -94.40548,
              37.05424
            ],
            [
              -94.295404,
              37.053234
            ],
            [
              -94.059211,
              37.048127
            ],
            [
              -94.052313,
              37.290078
            ],
            [
              -94.083833,
              37.290851
            ],
            [
              -94.082083,
              37.349291
            ],
            [
              -94.61753771716201,
              37.3641671035633
            ],
            [
              -94.617636,
              37.338417
            ],
            [
              -94.61763608310468,
              37.3384147815258
            ],
            [
              -94.617695,
              37.336842
            ],
            [
              -94.617648,
              37.323589
            ],
            [
              -94.618075,
              37.240436
            ],
            [
              -94.618158,
              37.237597
            ],
            [
              -94.618123,
              37.229334
            ],
            [
              -94.61815,
              37.228121
            ],
            [
              -94.618177481484,
              37.2200163519201
            ],
            [
              -94.618219,
              37.207772
            ],
            [
              -94.618305,
              37.207337
            ],
            [
              -94.6183145404829,
              37.1946870011618
            ],
            [
              -94.618319,
              37.188774
            ],
            [
              -94.618505,
              37.181184
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "229",
      "COUNTYNS": "00758567",
      "AFFGEOID": "0500000US29229",
      "GEOID": "29229",
      "NAME": "Wright",
      "LSAD": "06",
      "ALAND": 1765780148,
      "AWATER": 3533325,
      "County_state": "Wright,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.686671,
              37.481545
            ],
            [
              -92.692303,
              37.263514
            ],
            [
              -92.67897,
              37.263149
            ],
            [
              -92.685867,
              37.067051
            ],
            [
              -92.251359,
              37.059653
            ],
            [
              -92.25298,
              37.254843
            ],
            [
              -92.25331,
              37.3774
            ],
            [
              -92.252261,
              37.472944
            ],
            [
              -92.559,
              37.478734
            ],
            [
              -92.686671,
              37.481545
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "215",
      "COUNTYNS": "00758560",
      "AFFGEOID": "0500000US29215",
      "GEOID": "29215",
      "NAME": "Texas",
      "LSAD": "06",
      "ALAND": 3049104547,
      "AWATER": 5071080,
      "County_state": "Texas,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.25331,
              37.3774
            ],
            [
              -92.25298,
              37.254843
            ],
            [
              -92.251359,
              37.059653
            ],
            [
              -92.088708,
              37.056248
            ],
            [
              -91.980733,
              37.055638
            ],
            [
              -91.79944,
              37.05002
            ],
            [
              -91.655607,
              37.048925
            ],
            [
              -91.647702,
              37.248371
            ],
            [
              -91.646626,
              37.422731
            ],
            [
              -91.75504,
              37.42411
            ],
            [
              -91.754795,
              37.598768
            ],
            [
              -91.809105,
              37.598863
            ],
            [
              -92.029258,
              37.602542
            ],
            [
              -92.183261,
              37.605243
            ],
            [
              -92.249463,
              37.604543
            ],
            [
              -92.252261,
              37.472944
            ],
            [
              -92.25331,
              37.3774
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "123",
      "COUNTYNS": "00758515",
      "AFFGEOID": "0500000US29123",
      "GEOID": "29123",
      "NAME": "Madison",
      "LSAD": "06",
      "ALAND": 1280463342,
      "AWATER": 8312953,
      "County_state": "Madison,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.547406,
              37.59841
            ],
            [
              -90.549729,
              37.317594
            ],
            [
              -90.346243,
              37.314577
            ],
            [
              -90.236355,
              37.316315
            ],
            [
              -90.217657,
              37.314967
            ],
            [
              -90.145699,
              37.312091
            ],
            [
              -90.138536,
              37.541219
            ],
            [
              -90.146763,
              37.597434
            ],
            [
              -90.146778,
              37.641618
            ],
            [
              -90.453576,
              37.642752
            ],
            [
              -90.516756,
              37.642665
            ],
            [
              -90.535016,
              37.643641
            ],
            [
              -90.535144,
              37.5984
            ],
            [
              -90.547406,
              37.59841
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "139",
      "COUNTYNS": "00758524",
      "AFFGEOID": "0500000US29139",
      "GEOID": "29139",
      "NAME": "Montgomery",
      "LSAD": "06",
      "ALAND": 1385765331,
      "AWATER": 14100636,
      "County_state": "Montgomery,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.647171,
              38.703396
            ],
            [
              -91.640372,
              38.703792
            ],
            [
              -91.587665,
              38.682185
            ],
            [
              -91.547142,
              38.676406
            ],
            [
              -91.502602,
              38.695
            ],
            [
              -91.502407,
              38.702875
            ],
            [
              -91.496533,
              38.709464
            ],
            [
              -91.485101,
              38.713307
            ],
            [
              -91.472339,
              38.707621
            ],
            [
              -91.452796,
              38.709383
            ],
            [
              -91.4576,
              38.70627
            ],
            [
              -91.442845,
              38.709182
            ],
            [
              -91.418637,
              38.709778
            ],
            [
              -91.415832,
              38.8453
            ],
            [
              -91.33885,
              38.84453
            ],
            [
              -91.301894,
              38.845851
            ],
            [
              -91.264927,
              38.843833
            ],
            [
              -91.264287,
              38.992762
            ],
            [
              -91.260232,
              39.139845
            ],
            [
              -91.409033,
              39.142542
            ],
            [
              -91.629598,
              39.147853
            ],
            [
              -91.633998,
              39.059057
            ],
            [
              -91.647171,
              38.703396
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "161",
      "COUNTYNS": "00758535",
      "AFFGEOID": "0500000US29161",
      "GEOID": "29161",
      "NAME": "Phelps",
      "LSAD": "06",
      "ALAND": 1739898257,
      "AWATER": 6587831,
      "County_state": "Phelps,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.0246,
              37.793721
            ],
            [
              -92.029258,
              37.602542
            ],
            [
              -91.809105,
              37.598863
            ],
            [
              -91.806219,
              37.791286
            ],
            [
              -91.734,
              37.791057
            ],
            [
              -91.528797,
              37.788989
            ],
            [
              -91.525025,
              38.152433
            ],
            [
              -91.534384,
              38.152593
            ],
            [
              -91.630554,
              38.153519
            ],
            [
              -91.632431,
              38.050951
            ],
            [
              -91.753347,
              38.053243
            ],
            [
              -91.895347,
              38.051199
            ],
            [
              -91.935247,
              38.03087
            ],
            [
              -91.941527,
              38.041294
            ],
            [
              -91.952776,
              38.040695
            ],
            [
              -91.966333,
              38.009683
            ],
            [
              -92.021165,
              38.010639
            ],
            [
              -92.022863,
              37.88074
            ],
            [
              -92.0246,
              37.793721
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "177",
      "COUNTYNS": "00758543",
      "AFFGEOID": "0500000US29177",
      "GEOID": "29177",
      "NAME": "Ray",
      "LSAD": "06",
      "ALAND": 1473200436,
      "AWATER": 13287170,
      "County_state": "Ray,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.211337,
              39.395638
            ],
            [
              -94.212515,
              39.207086
            ],
            [
              -94.170495,
              39.21231
            ],
            [
              -94.187184,
              39.183333
            ],
            [
              -94.179984,
              39.178793
            ],
            [
              -94.133664,
              39.171239
            ],
            [
              -94.104823,
              39.143507
            ],
            [
              -94.087135,
              39.135543
            ],
            [
              -94.062463,
              39.13428
            ],
            [
              -94.024206,
              39.154537
            ],
            [
              -94.035727,
              39.178121
            ],
            [
              -94.028822,
              39.191595
            ],
            [
              -93.980088,
              39.205874
            ],
            [
              -93.961779,
              39.19212
            ],
            [
              -93.962632,
              39.183084
            ],
            [
              -93.983361,
              39.168524
            ],
            [
              -93.988892,
              39.152786
            ],
            [
              -93.961155,
              39.143563
            ],
            [
              -93.948345,
              39.152661
            ],
            [
              -93.940043,
              39.17969
            ],
            [
              -93.92783,
              39.184386
            ],
            [
              -93.899417,
              39.184539
            ],
            [
              -93.87522,
              39.20408
            ],
            [
              -93.840147,
              39.215467
            ],
            [
              -93.818513,
              39.213655
            ],
            [
              -93.788494,
              39.20103
            ],
            [
              -93.758463,
              39.207021
            ],
            [
              -93.759183,
              39.524558
            ],
            [
              -93.870196,
              39.525865
            ],
            [
              -93.978242,
              39.524392
            ],
            [
              -94.153327,
              39.526229
            ],
            [
              -94.208423,
              39.5272
            ],
            [
              -94.210642,
              39.454682
            ],
            [
              -94.21114,
              39.407248
            ],
            [
              -94.211337,
              39.395638
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "041",
      "COUNTYNS": "00758475",
      "AFFGEOID": "0500000US29041",
      "GEOID": "29041",
      "NAME": "Chariton",
      "LSAD": "06",
      "ALAND": 1945540984,
      "AWATER": 40353306,
      "County_state": "Chariton,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.287328,
              39.682023
            ],
            [
              -93.283418,
              39.651433
            ],
            [
              -93.261386,
              39.623253
            ],
            [
              -93.278098,
              39.615632
            ],
            [
              -93.270135,
              39.59736
            ],
            [
              -93.274498,
              39.588587
            ],
            [
              -93.266399,
              39.572038
            ],
            [
              -93.265968,
              39.549637
            ],
            [
              -93.283007,
              39.519477
            ],
            [
              -93.265951,
              39.497905
            ],
            [
              -93.276539,
              39.496705
            ],
            [
              -93.266338,
              39.478358
            ],
            [
              -93.264322,
              39.461182
            ],
            [
              -93.275848,
              39.458302
            ],
            [
              -93.247415,
              39.458092
            ],
            [
              -93.228095,
              39.473215
            ],
            [
              -93.219546,
              39.450793
            ],
            [
              -93.228755,
              39.436297
            ],
            [
              -93.211233,
              39.441405
            ],
            [
              -93.213581,
              39.430507
            ],
            [
              -93.197222,
              39.436845
            ],
            [
              -93.183537,
              39.432473
            ],
            [
              -93.16555,
              39.436335
            ],
            [
              -93.15475,
              39.421723
            ],
            [
              -93.14084,
              39.423642
            ],
            [
              -93.117177,
              39.415861
            ],
            [
              -93.115685,
              39.389455
            ],
            [
              -93.104485,
              39.383656
            ],
            [
              -93.080289,
              39.371752
            ],
            [
              -93.072915,
              39.33552
            ],
            [
              -93.044755,
              39.317666
            ],
            [
              -93.023776,
              39.313115
            ],
            [
              -92.976803,
              39.31548
            ],
            [
              -92.959801,
              39.312526
            ],
            [
              -92.953273,
              39.298457
            ],
            [
              -92.958978,
              39.283041
            ],
            [
              -92.947403,
              39.270094
            ],
            [
              -92.923617,
              39.263376
            ],
            [
              -92.915469,
              39.253379
            ],
            [
              -92.919572,
              39.231615
            ],
            [
              -92.911669,
              39.223946
            ],
            [
              -92.896194,
              39.221897
            ],
            [
              -92.86523,
              39.235904
            ],
            [
              -92.849224,
              39.226218
            ],
            [
              -92.790369,
              39.343586
            ],
            [
              -92.707367,
              39.321614
            ],
            [
              -92.703937,
              39.408078
            ],
            [
              -92.703669,
              39.41469
            ],
            [
              -92.696673,
              39.60871
            ],
            [
              -92.692149,
              39.610265
            ],
            [
              -92.689331,
              39.698094
            ],
            [
              -92.85792,
              39.699985
            ],
            [
              -92.95992,
              39.70215
            ],
            [
              -93.082306,
              39.706714
            ],
            [
              -93.266968,
              39.703562
            ],
            [
              -93.287328,
              39.682023
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "131",
      "COUNTYNS": "00758520",
      "AFFGEOID": "0500000US29131",
      "GEOID": "29131",
      "NAME": "Miller",
      "LSAD": "06",
      "ALAND": 1534813236,
      "AWATER": 19159074,
      "County_state": "Miller,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.689834,
              38.348472
            ],
            [
              -92.695886,
              38.222031
            ],
            [
              -92.675586,
              38.212831
            ],
            [
              -92.640085,
              38.213032
            ],
            [
              -92.641385,
              38.171632
            ],
            [
              -92.604684,
              38.170733
            ],
            [
              -92.596374,
              38.139787
            ],
            [
              -92.596761,
              38.110754
            ],
            [
              -92.587688,
              38.110636
            ],
            [
              -92.587984,
              38.096134
            ],
            [
              -92.569682,
              38.095934
            ],
            [
              -92.570227,
              38.069272
            ],
            [
              -92.552081,
              38.066335
            ],
            [
              -92.552381,
              38.052145
            ],
            [
              -92.533554,
              38.051808
            ],
            [
              -92.533895,
              38.037467
            ],
            [
              -92.51558,
              38.036936
            ],
            [
              -92.515979,
              38.022336
            ],
            [
              -92.406275,
              38.021179
            ],
            [
              -92.18537,
              38.016338
            ],
            [
              -92.181372,
              38.161238
            ],
            [
              -92.197773,
              38.161538
            ],
            [
              -92.19735,
              38.189318
            ],
            [
              -92.195675,
              38.292237
            ],
            [
              -92.194476,
              38.335536
            ],
            [
              -92.224678,
              38.335733
            ],
            [
              -92.250178,
              38.322713
            ],
            [
              -92.284479,
              38.337534
            ],
            [
              -92.328962,
              38.337299
            ],
            [
              -92.408407,
              38.341344
            ],
            [
              -92.403484,
              38.425331
            ],
            [
              -92.495185,
              38.427829
            ],
            [
              -92.531857,
              38.428548
            ],
            [
              -92.625189,
              38.430778
            ],
            [
              -92.63019,
              38.430958
            ],
            [
              -92.633756,
              38.348082
            ],
            [
              -92.689834,
              38.348472
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "510",
      "COUNTYNS": "00767557",
      "AFFGEOID": "0500000US29510",
      "GEOID": "29510",
      "NAME": "St. Louis",
      "LSAD": "25",
      "ALAND": 160462911,
      "AWATER": 10663382,
      "County_state": "St. Louis,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.318212,
              38.600017
            ],
            [
              -90.31858,
              38.599092
            ],
            [
              -90.31624,
              38.584686
            ],
            [
              -90.310018,
              38.575231
            ],
            [
              -90.297022,
              38.562368
            ],
            [
              -90.280563,
              38.552187
            ],
            [
              -90.280257,
              38.552002
            ],
            [
              -90.272348,
              38.548387
            ],
            [
              -90.269146,
              38.537535
            ],
            [
              -90.2577725855853,
              38.53200774918
            ],
            [
              -90.2502824164961,
              38.542782135028794
            ],
            [
              -90.248913,
              38.544752
            ],
            [
              -90.224212,
              38.575051
            ],
            [
              -90.222112,
              38.576451
            ],
            [
              -90.216712,
              38.578751
            ],
            [
              -90.210111,
              38.583951
            ],
            [
              -90.2055396773137,
              38.58677800218759
            ],
            [
              -90.202511,
              38.588651
            ],
            [
              -90.196011,
              38.594451
            ],
            [
              -90.191811,
              38.598951
            ],
            [
              -90.18664790603499,
              38.6078614210325
            ],
            [
              -90.18451,
              38.611551
            ],
            [
              -90.17881,
              38.62915
            ],
            [
              -90.17801,
              38.63375
            ],
            [
              -90.1777132808621,
              38.6426515741363
            ],
            [
              -90.17771,
              38.64275
            ],
            [
              -90.1810494295439,
              38.6592507106875
            ],
            [
              -90.18111,
              38.65955
            ],
            [
              -90.1813250257207,
              38.6603814327868
            ],
            [
              -90.18261,
              38.66535
            ],
            [
              -90.18641,
              38.67475
            ],
            [
              -90.19521,
              38.68755
            ],
            [
              -90.19770973268129,
              38.6896569175457
            ],
            [
              -90.20221,
              38.69345
            ],
            [
              -90.2022575105174,
              38.6935131211159
            ],
            [
              -90.20921,
              38.70275
            ],
            [
              -90.21201,
              38.71175
            ],
            [
              -90.21191,
              38.71795
            ],
            [
              -90.21141,
              38.72135
            ],
            [
              -90.211178903355,
              38.7220741028209
            ],
            [
              -90.20991,
              38.72605
            ],
            [
              -90.20521,
              38.73215
            ],
            [
              -90.191309,
              38.742949
            ],
            [
              -90.183409,
              38.746849
            ],
            [
              -90.176309,
              38.754449
            ],
            [
              -90.175109,
              38.760249
            ],
            [
              -90.171309,
              38.766549
            ],
            [
              -90.16657184776219,
              38.77244627115319
            ],
            [
              -90.184196,
              38.774431
            ],
            [
              -90.186265,
              38.761354
            ],
            [
              -90.207946,
              38.739739
            ],
            [
              -90.240614,
              38.723257
            ],
            [
              -90.247332,
              38.720619
            ],
            [
              -90.252266,
              38.718219
            ],
            [
              -90.265026,
              38.702171
            ],
            [
              -90.28144,
              38.681434
            ],
            [
              -90.284394,
              38.677727
            ],
            [
              -90.301828,
              38.655642
            ],
            [
              -90.305324,
              38.639656
            ],
            [
              -90.309398,
              38.626972
            ],
            [
              -90.310184,
              38.62311
            ],
            [
              -90.313365,
              38.612349
            ],
            [
              -90.318212,
              38.600017
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "163",
      "COUNTYNS": "00758536",
      "AFFGEOID": "0500000US29163",
      "GEOID": "29163",
      "NAME": "Pike",
      "LSAD": "06",
      "ALAND": 1736428536,
      "AWATER": 36976453,
      "County_state": "Pike,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.460442,
              39.450722
            ],
            [
              -91.438235,
              39.318716
            ],
            [
              -91.409033,
              39.142542
            ],
            [
              -91.260232,
              39.139845
            ],
            [
              -91.18546,
              39.13905
            ],
            [
              -91.184197,
              39.179095
            ],
            [
              -91.182942,
              39.227233
            ],
            [
              -90.721835,
              39.224108
            ],
            [
              -90.721188,
              39.230176
            ],
            [
              -90.721593,
              39.23273
            ],
            [
              -90.726981,
              39.251173
            ],
            [
              -90.72996,
              39.255894
            ],
            [
              -90.733976,
              39.259098
            ],
            [
              -90.739087,
              39.261893
            ],
            [
              -90.748877,
              39.264126
            ],
            [
              -90.751599,
              39.265432
            ],
            [
              -90.767648,
              39.280025
            ],
            [
              -90.773887,
              39.290544
            ],
            [
              -90.775673,
              39.292811
            ],
            [
              -90.783789,
              39.297164
            ],
            [
              -90.790675,
              39.302908
            ],
            [
              -90.791689,
              39.306957
            ],
            [
              -90.793461,
              39.309498
            ],
            [
              -90.799346,
              39.313087
            ],
            [
              -90.816851,
              39.320496
            ],
            [
              -90.821306,
              39.323659
            ],
            [
              -90.840106,
              39.340438
            ],
            [
              -90.8475,
              39.345272
            ],
            [
              -90.859113,
              39.35137
            ],
            [
              -90.893777,
              39.367343
            ],
            [
              -90.900095,
              39.372354
            ],
            [
              -90.902656,
              39.375366
            ],
            [
              -90.902905,
              39.377534
            ],
            [
              -90.904862,
              39.379403
            ],
            [
              -90.907999,
              39.380812
            ],
            [
              -90.914658,
              39.381956
            ],
            [
              -90.920976,
              39.383687
            ],
            [
              -90.924601,
              39.385136
            ],
            [
              -90.928745,
              39.387544
            ],
            [
              -90.934007,
              39.392127
            ],
            [
              -90.935729,
              39.397331
            ],
            [
              -90.9367782192347,
              39.399486555729595
            ],
            [
              -90.937419,
              39.400803
            ],
            [
              -90.939025,
              39.402744
            ],
            [
              -90.940766,
              39.403984
            ],
            [
              -90.948299,
              39.407502
            ],
            [
              -90.957459,
              39.408996
            ],
            [
              -90.96748,
              39.411948
            ],
            [
              -90.972465,
              39.414144
            ],
            [
              -90.977618,
              39.41829
            ],
            [
              -90.98302,
              39.420462
            ],
            [
              -90.993789,
              39.422959
            ],
            [
              -91.003692,
              39.427603
            ],
            [
              -91.011954,
              39.432661
            ],
            [
              -91.02361,
              39.438694
            ],
            [
              -91.03827,
              39.448436
            ],
            [
              -91.053058,
              39.462122
            ],
            [
              -91.059439,
              39.46886
            ],
            [
              -91.062414,
              39.474122
            ],
            [
              -91.064305,
              39.494643
            ],
            [
              -91.075309,
              39.502845
            ],
            [
              -91.079769,
              39.507728
            ],
            [
              -91.086292,
              39.517141
            ],
            [
              -91.092869,
              39.529275
            ],
            [
              -91.100307,
              39.538695
            ],
            [
              -91.114305,
              39.541098
            ],
            [
              -91.126638,
              39.542227
            ],
            [
              -91.148275,
              39.545798
            ],
            [
              -91.153628,
              39.548248
            ],
            [
              -91.158606,
              39.553048
            ],
            [
              -91.163634,
              39.558566
            ],
            [
              -91.168419,
              39.564928
            ],
            [
              -91.16982,
              39.569555
            ],
            [
              -91.171641,
              39.581899
            ],
            [
              -91.174232,
              39.591975
            ],
            [
              -91.174651,
              39.593313
            ],
            [
              -91.178012,
              39.598196
            ],
            [
              -91.203315,
              39.598393
            ],
            [
              -91.367658,
              39.502466
            ],
            [
              -91.460442,
              39.450722
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "027",
      "COUNTYNS": "00758468",
      "AFFGEOID": "0500000US29027",
      "GEOID": "29027",
      "NAME": "Callaway",
      "LSAD": "06",
      "ALAND": 2161546696,
      "AWATER": 33099111,
      "County_state": "Callaway,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.226091,
              38.697852
            ],
            [
              -92.21846,
              38.689051
            ],
            [
              -92.220661,
              38.643167
            ],
            [
              -92.204751,
              38.630279
            ],
            [
              -92.202962,
              38.604938
            ],
            [
              -92.169328,
              38.58166
            ],
            [
              -92.13775,
              38.569315
            ],
            [
              -92.107211,
              38.561054
            ],
            [
              -92.065233,
              38.557079
            ],
            [
              -92.008983,
              38.5709
            ],
            [
              -91.982025,
              38.587638
            ],
            [
              -91.943936,
              38.597075
            ],
            [
              -91.893749,
              38.634087
            ],
            [
              -91.877356,
              38.657743
            ],
            [
              -91.86057,
              38.668174
            ],
            [
              -91.821484,
              38.680021
            ],
            [
              -91.760234,
              38.685
            ],
            [
              -91.726221,
              38.706721
            ],
            [
              -91.647171,
              38.703396
            ],
            [
              -91.633998,
              39.059057
            ],
            [
              -92.110387,
              39.064204
            ],
            [
              -92.131512,
              39.064484
            ],
            [
              -92.135974,
              39.032397
            ],
            [
              -92.13022,
              39.023642
            ],
            [
              -92.140289,
              39.001102
            ],
            [
              -92.136528,
              38.995118
            ],
            [
              -92.14706,
              38.977577
            ],
            [
              -92.143265,
              38.967854
            ],
            [
              -92.153366,
              38.943578
            ],
            [
              -92.158291,
              38.911084
            ],
            [
              -92.161916,
              38.865284
            ],
            [
              -92.149272,
              38.866238
            ],
            [
              -92.145523,
              38.832777
            ],
            [
              -92.1525,
              38.825447
            ],
            [
              -92.135158,
              38.814389
            ],
            [
              -92.159384,
              38.800426
            ],
            [
              -92.164867,
              38.782505
            ],
            [
              -92.163428,
              38.764814
            ],
            [
              -92.17095,
              38.7653
            ],
            [
              -92.194159,
              38.736647
            ],
            [
              -92.192431,
              38.726132
            ],
            [
              -92.216388,
              38.714495
            ],
            [
              -92.215691,
              38.702487
            ],
            [
              -92.226091,
              38.697852
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "173",
      "COUNTYNS": "00758541",
      "AFFGEOID": "0500000US29173",
      "GEOID": "29173",
      "NAME": "Ralls",
      "LSAD": "06",
      "ALAND": 1216727530,
      "AWATER": 36396585,
      "County_state": "Ralls,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.717968,
              39.339122
            ],
            [
              -91.718467,
              39.324505
            ],
            [
              -91.623822,
              39.324219
            ],
            [
              -91.438235,
              39.318716
            ],
            [
              -91.460442,
              39.450722
            ],
            [
              -91.367658,
              39.502466
            ],
            [
              -91.203315,
              39.598393
            ],
            [
              -91.178012,
              39.598196
            ],
            [
              -91.181936,
              39.602677
            ],
            [
              -91.185921,
              39.605119
            ],
            [
              -91.21664,
              39.615124
            ],
            [
              -91.223328,
              39.617603
            ],
            [
              -91.229317,
              39.620853
            ],
            [
              -91.241225,
              39.630067
            ],
            [
              -91.24356,
              39.633064
            ],
            [
              -91.245914,
              39.637311
            ],
            [
              -91.248779,
              39.64088
            ],
            [
              -91.260475,
              39.649024
            ],
            [
              -91.266765,
              39.656993
            ],
            [
              -91.27614,
              39.665759
            ],
            [
              -91.283329,
              39.670134
            ],
            [
              -91.293788,
              39.674766
            ],
            [
              -91.302485,
              39.679631
            ],
            [
              -91.305348,
              39.683957
            ],
            [
              -91.3066819759012,
              39.6848809168884
            ],
            [
              -91.715497,
              39.686492
            ],
            [
              -91.715247,
              39.657644
            ],
            [
              -91.716029,
              39.613696
            ],
            [
              -91.711934,
              39.599095
            ],
            [
              -91.717968,
              39.339122
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "107",
      "COUNTYNS": "00758508",
      "AFFGEOID": "0500000US29107",
      "GEOID": "29107",
      "NAME": "Lafayette",
      "LSAD": "06",
      "ALAND": 1627640379,
      "AWATER": 27215050,
      "County_state": "Lafayette,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.110992,
              38.985587
            ],
            [
              -94.114175,
              38.917304
            ],
            [
              -94.100787,
              38.9173
            ],
            [
              -93.943675,
              38.912386
            ],
            [
              -93.836319,
              38.908709
            ],
            [
              -93.834719,
              38.937909
            ],
            [
              -93.497278,
              38.92843
            ],
            [
              -93.496377,
              38.942837
            ],
            [
              -93.477233,
              39.292796
            ],
            [
              -93.492018,
              39.282938
            ],
            [
              -93.496221,
              39.264891
            ],
            [
              -93.487611,
              39.238338
            ],
            [
              -93.49196,
              39.223461
            ],
            [
              -93.516071,
              39.215289
            ],
            [
              -93.559544,
              39.218797
            ],
            [
              -93.58495,
              39.240431
            ],
            [
              -93.628359,
              39.243164
            ],
            [
              -93.650526,
              39.248214
            ],
            [
              -93.661901,
              39.235782
            ],
            [
              -93.681595,
              39.231235
            ],
            [
              -93.700126,
              39.209684
            ],
            [
              -93.71121,
              39.206262
            ],
            [
              -93.738956,
              39.209655
            ],
            [
              -93.758463,
              39.207021
            ],
            [
              -93.788494,
              39.20103
            ],
            [
              -93.818513,
              39.213655
            ],
            [
              -93.840147,
              39.215467
            ],
            [
              -93.87522,
              39.20408
            ],
            [
              -93.899417,
              39.184539
            ],
            [
              -93.92783,
              39.184386
            ],
            [
              -93.940043,
              39.17969
            ],
            [
              -93.948345,
              39.152661
            ],
            [
              -93.961155,
              39.143563
            ],
            [
              -93.988892,
              39.152786
            ],
            [
              -93.983361,
              39.168524
            ],
            [
              -93.962632,
              39.183084
            ],
            [
              -93.961779,
              39.19212
            ],
            [
              -93.980088,
              39.205874
            ],
            [
              -94.028822,
              39.191595
            ],
            [
              -94.035727,
              39.178121
            ],
            [
              -94.024206,
              39.154537
            ],
            [
              -94.062463,
              39.13428
            ],
            [
              -94.087135,
              39.135543
            ],
            [
              -94.104823,
              39.143507
            ],
            [
              -94.106764,
              39.091703
            ],
            [
              -94.108145,
              39.06233
            ],
            [
              -94.110005,
              39.014987
            ],
            [
              -94.11054,
              38.998447
            ],
            [
              -94.11058,
              38.997336
            ],
            [
              -94.110992,
              38.985587
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "125",
      "COUNTYNS": "00758516",
      "AFFGEOID": "0500000US29125",
      "GEOID": "29125",
      "NAME": "Maries",
      "LSAD": "06",
      "ALAND": 1364869337,
      "AWATER": 7636521,
      "County_state": "Maries,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.19735,
              38.189318
            ],
            [
              -92.197773,
              38.161538
            ],
            [
              -92.181372,
              38.161238
            ],
            [
              -92.18537,
              38.016338
            ],
            [
              -92.182005,
              38.015031
            ],
            [
              -92.021165,
              38.010639
            ],
            [
              -91.966333,
              38.009683
            ],
            [
              -91.952776,
              38.040695
            ],
            [
              -91.941527,
              38.041294
            ],
            [
              -91.935247,
              38.03087
            ],
            [
              -91.895347,
              38.051199
            ],
            [
              -91.753347,
              38.053243
            ],
            [
              -91.632431,
              38.050951
            ],
            [
              -91.630554,
              38.153519
            ],
            [
              -91.644893,
              38.153679
            ],
            [
              -91.644729,
              38.28851
            ],
            [
              -91.757318,
              38.28872
            ],
            [
              -91.775346,
              38.292044
            ],
            [
              -91.86312,
              38.29361
            ],
            [
              -91.883986,
              38.289898
            ],
            [
              -92.024705,
              38.288743
            ],
            [
              -92.195675,
              38.292237
            ],
            [
              -92.19735,
              38.189318
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "151",
      "COUNTYNS": "00758530",
      "AFFGEOID": "0500000US29151",
      "GEOID": "29151",
      "NAME": "Osage",
      "LSAD": "06",
      "ALAND": 1571017654,
      "AWATER": 17751408,
      "County_state": "Osage,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.194476,
              38.335536
            ],
            [
              -92.195675,
              38.292237
            ],
            [
              -92.024705,
              38.288743
            ],
            [
              -91.883986,
              38.289898
            ],
            [
              -91.86312,
              38.29361
            ],
            [
              -91.775346,
              38.292044
            ],
            [
              -91.757318,
              38.28872
            ],
            [
              -91.644729,
              38.28851
            ],
            [
              -91.643556,
              38.437723
            ],
            [
              -91.643133,
              38.46528
            ],
            [
              -91.640372,
              38.703792
            ],
            [
              -91.647171,
              38.703396
            ],
            [
              -91.726221,
              38.706721
            ],
            [
              -91.760234,
              38.685
            ],
            [
              -91.821484,
              38.680021
            ],
            [
              -91.86057,
              38.668174
            ],
            [
              -91.877356,
              38.657743
            ],
            [
              -91.893749,
              38.634087
            ],
            [
              -91.943936,
              38.597075
            ],
            [
              -91.982025,
              38.587638
            ],
            [
              -92.008983,
              38.5709
            ],
            [
              -92.0035,
              38.56026
            ],
            [
              -92.025857,
              38.552017
            ],
            [
              -92.025183,
              38.524129
            ],
            [
              -92.0087,
              38.500038
            ],
            [
              -92.020554,
              38.47629
            ],
            [
              -92.043489,
              38.466479
            ],
            [
              -92.081944,
              38.46525
            ],
            [
              -92.116275,
              38.457571
            ],
            [
              -92.151594,
              38.474768
            ],
            [
              -92.167357,
              38.469687
            ],
            [
              -92.167255,
              38.463443
            ],
            [
              -92.149291,
              38.431152
            ],
            [
              -92.124778,
              38.419936
            ],
            [
              -92.114977,
              38.401937
            ],
            [
              -92.133381,
              38.384609
            ],
            [
              -92.193178,
              38.370835
            ],
            [
              -92.194476,
              38.335536
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "133",
      "COUNTYNS": "00758521",
      "AFFGEOID": "0500000US29133",
      "GEOID": "29133",
      "NAME": "Mississippi",
      "LSAD": "06",
      "ALAND": 1065995413,
      "AWATER": 44494832,
      "County_state": "Mississippi,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.519932,
              36.876965
            ],
            [
              -89.519809,
              36.869617
            ],
            [
              -89.519701,
              36.847896
            ],
            [
              -89.502093,
              36.847905
            ],
            [
              -89.502045,
              36.832803
            ],
            [
              -89.483141,
              36.832561
            ],
            [
              -89.463226,
              36.788923
            ],
            [
              -89.445614,
              36.789311
            ],
            [
              -89.445888,
              36.767646
            ],
            [
              -89.436886,
              36.767944
            ],
            [
              -89.42795,
              36.746524
            ],
            [
              -89.400702,
              36.729992
            ],
            [
              -89.39146,
              36.702911
            ],
            [
              -89.373741,
              36.702948
            ],
            [
              -89.364331,
              36.674012
            ],
            [
              -89.346188,
              36.666785
            ],
            [
              -89.345968,
              36.651818
            ],
            [
              -89.327476,
              36.644969
            ],
            [
              -89.32758872176659,
              36.6321935043326
            ],
            [
              -89.326731,
              36.632186
            ],
            [
              -89.318154,
              36.625059
            ],
            [
              -89.313405,
              36.62012
            ],
            [
              -89.301502,
              36.604138
            ],
            [
              -89.294637,
              36.593729
            ],
            [
              -89.278935,
              36.577699
            ],
            [
              -89.27171,
              36.571387
            ],
            [
              -89.268057,
              36.568908
            ],
            [
              -89.259994,
              36.565149
            ],
            [
              -89.258318,
              36.564948
            ],
            [
              -89.236542,
              36.566824
            ],
            [
              -89.227319,
              36.569375
            ],
            [
              -89.217447,
              36.576159
            ],
            [
              -89.213563,
              36.580119
            ],
            [
              -89.202607,
              36.601576
            ],
            [
              -89.200902,
              36.618177
            ],
            [
              -89.199136,
              36.625649
            ],
            [
              -89.197654,
              36.628936
            ],
            [
              -89.192542,
              36.635997
            ],
            [
              -89.187749,
              36.641115
            ],
            [
              -89.183321,
              36.644694
            ],
            [
              -89.174741,
              36.650416
            ],
            [
              -89.17468187525279,
              36.6504726524698
            ],
            [
              -89.164601,
              36.660132
            ],
            [
              -89.15908,
              36.666352
            ],
            [
              -89.168723,
              36.671892
            ],
            [
              -89.171882,
              36.672526
            ],
            [
              -89.169467,
              36.674596
            ],
            [
              -89.167961,
              36.678189
            ],
            [
              -89.168016,
              36.685514
            ],
            [
              -89.169522,
              36.688878
            ],
            [
              -89.174836,
              36.69396
            ],
            [
              -89.184356,
              36.701238
            ],
            [
              -89.19604,
              36.712017
            ],
            [
              -89.19948,
              36.716045
            ],
            [
              -89.200732,
              36.720141
            ],
            [
              -89.201047,
              36.725772
            ],
            [
              -89.199798,
              36.734217
            ],
            [
              -89.197808,
              36.739412
            ],
            [
              -89.191553,
              36.747217
            ],
            [
              -89.184523,
              36.753638
            ],
            [
              -89.179545,
              36.756132
            ],
            [
              -89.169106,
              36.759473
            ],
            [
              -89.166888,
              36.759633
            ],
            [
              -89.15699,
              36.755968
            ],
            [
              -89.151756,
              36.756264
            ],
            [
              -89.142313,
              36.755369
            ],
            [
              -89.135518,
              36.751956
            ],
            [
              -89.130399,
              36.751702
            ],
            [
              -89.126134,
              36.751735
            ],
            [
              -89.12243,
              36.754844
            ],
            [
              -89.119198,
              36.759802
            ],
            [
              -89.116563,
              36.767557
            ],
            [
              -89.116067,
              36.772423
            ],
            [
              -89.116847,
              36.775607
            ],
            [
              -89.120437,
              36.782071
            ],
            [
              -89.1234809771075,
              36.7852576789117
            ],
            [
              -89.12353,
              36.785309
            ],
            [
              -89.128923,
              36.787677
            ],
            [
              -89.13321,
              36.788228
            ],
            [
              -89.144208,
              36.788353
            ],
            [
              -89.155891,
              36.789126
            ],
            [
              -89.160877,
              36.790914
            ],
            [
              -89.168458,
              36.795571
            ],
            [
              -89.171069,
              36.798119
            ],
            [
              -89.17505946393709,
              36.803870850192894
            ],
            [
              -89.177417,
              36.807269
            ],
            [
              -89.178749,
              36.809928
            ],
            [
              -89.179229,
              36.812915
            ],
            [
              -89.178574,
              36.816554
            ],
            [
              -89.178888,
              36.831368
            ],
            [
              -89.178153,
              36.834586
            ],
            [
              -89.177177,
              36.835779
            ],
            [
              -89.173419,
              36.839806
            ],
            [
              -89.1704,
              36.841522
            ],
            [
              -89.166841,
              36.842529
            ],
            [
              -89.159466,
              36.842505
            ],
            [
              -89.15733,
              36.843305
            ],
            [
              -89.153513,
              36.8466
            ],
            [
              -89.147674,
              36.847148
            ],
            [
              -89.137969,
              36.847349
            ],
            [
              -89.131944,
              36.857437
            ],
            [
              -89.126702,
              36.872073
            ],
            [
              -89.119813,
              36.882792
            ],
            [
              -89.117567,
              36.887356
            ],
            [
              -89.109377,
              36.920066
            ],
            [
              -89.102137,
              36.939154
            ],
            [
              -89.100766,
              36.943973
            ],
            [
              -89.10076200668469,
              36.9440018170751
            ],
            [
              -89.098843,
              36.95785
            ],
            [
              -89.099007,
              36.961389
            ],
            [
              -89.099594,
              36.964543
            ],
            [
              -89.102879,
              36.9697
            ],
            [
              -89.109498,
              36.976563
            ],
            [
              -89.11503,
              36.980335
            ],
            [
              -89.1183,
              36.981879
            ],
            [
              -89.125069,
              36.983499
            ],
            [
              -89.128868,
              36.983265
            ],
            [
              -89.132915,
              36.982057
            ],
            [
              -89.140814,
              36.979416
            ],
            [
              -89.14411,
              36.979133
            ],
            [
              -89.149882,
              36.977636
            ],
            [
              -89.161767,
              36.972768
            ],
            [
              -89.170008,
              36.970298
            ],
            [
              -89.177235,
              36.970885
            ],
            [
              -89.185491,
              36.973518
            ],
            [
              -89.192097,
              36.979995
            ],
            [
              -89.195039,
              36.989768
            ],
            [
              -89.195029,
              37.000051
            ],
            [
              -89.198488,
              37.011723
            ],
            [
              -89.200793,
              37.016164
            ],
            [
              -89.205038,
              37.020047
            ],
            [
              -89.225482,
              37.031077
            ],
            [
              -89.234053,
              37.037277
            ],
            [
              -89.238253,
              37.042853
            ],
            [
              -89.245648,
              37.057783
            ],
            [
              -89.25493,
              37.072014
            ],
            [
              -89.259936,
              37.064071
            ],
            [
              -89.264484,
              37.064814
            ],
            [
              -89.280375,
              37.065224
            ],
            [
              -89.283488,
              37.065811
            ],
            [
              -89.283685,
              37.066736
            ],
            [
              -89.294036,
              37.067345
            ],
            [
              -89.307726,
              37.069654
            ],
            [
              -89.30829,
              37.068371
            ],
            [
              -89.310819,
              37.057897
            ],
            [
              -89.309401,
              37.053769
            ],
            [
              -89.307397,
              37.050432
            ],
            [
              -89.304752,
              37.047565
            ],
            [
              -89.301368,
              37.044982
            ],
            [
              -89.291185,
              37.040408
            ],
            [
              -89.277715,
              37.03614
            ],
            [
              -89.266286,
              37.028683
            ],
            [
              -89.260003,
              37.023288
            ],
            [
              -89.257608,
              37.015496
            ],
            [
              -89.263527,
              37.00005
            ],
            [
              -89.266242,
              36.996302
            ],
            [
              -89.269564,
              36.993401
            ],
            [
              -89.274198,
              36.990495
            ],
            [
              -89.278628,
              36.98867
            ],
            [
              -89.29213,
              36.992189
            ],
            [
              -89.3126416196891,
              37.0090469003899
            ],
            [
              -89.347107,
              37.009193
            ],
            [
              -89.347215,
              37.002004
            ],
            [
              -89.374613,
              36.994288
            ],
            [
              -89.375064,
              36.964947
            ],
            [
              -89.411289,
              36.964787
            ],
            [
              -89.411299,
              36.950135
            ],
            [
              -89.4299,
              36.950183
            ],
            [
              -89.42995,
              36.935609
            ],
            [
              -89.465393,
              36.935729
            ],
            [
              -89.465161,
              36.906239
            ],
            [
              -89.501683,
              36.906262
            ],
            [
              -89.501516,
              36.877131
            ],
            [
              -89.519932,
              36.876965
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "095",
      "COUNTYNS": "00758502",
      "AFFGEOID": "0500000US29095",
      "GEOID": "29095",
      "NAME": "Jackson",
      "LSAD": "06",
      "ALAND": 1565601892,
      "AWATER": 30717815,
      "County_state": "Jackson,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.608334,
              38.981806
            ],
            [
              -94.6083185155548,
              38.9787245953992
            ],
            [
              -94.60820799442169,
              38.9567308899182
            ],
            [
              -94.608134,
              38.942006
            ],
            [
              -94.608134,
              38.940006
            ],
            [
              -94.607866,
              38.937398
            ],
            [
              -94.607978,
              38.93687
            ],
            [
              -94.6079780238428,
              38.93684699691519
            ],
            [
              -94.6079797993633,
              38.9351340070476
            ],
            [
              -94.60801759440719,
              38.898670035857194
            ],
            [
              -94.6080256044729,
              38.8909420700723
            ],
            [
              -94.60802799130869,
              38.888639294334695
            ],
            [
              -94.608033,
              38.883807
            ],
            [
              -94.608033,
              38.869207
            ],
            [
              -94.608033,
              38.868107
            ],
            [
              -94.607993,
              38.867271
            ],
            [
              -94.6079958957829,
              38.866831999312495
            ],
            [
              -94.608033,
              38.861207
            ],
            [
              -94.608033,
              38.855007
            ],
            [
              -94.608033,
              38.847207
            ],
            [
              -94.530087,
              38.843678
            ],
            [
              -94.490031,
              38.841713
            ],
            [
              -94.399447,
              38.838809
            ],
            [
              -94.376002,
              38.838005
            ],
            [
              -94.272497,
              38.835044
            ],
            [
              -94.267951,
              38.835037
            ],
            [
              -94.119096,
              38.833167
            ],
            [
              -94.113859,
              38.912437
            ],
            [
              -94.114175,
              38.917304
            ],
            [
              -94.110992,
              38.985587
            ],
            [
              -94.11058,
              38.997336
            ],
            [
              -94.11054,
              38.998447
            ],
            [
              -94.110005,
              39.014987
            ],
            [
              -94.108145,
              39.06233
            ],
            [
              -94.106764,
              39.091703
            ],
            [
              -94.104823,
              39.143507
            ],
            [
              -94.133664,
              39.171239
            ],
            [
              -94.179984,
              39.178793
            ],
            [
              -94.187184,
              39.183333
            ],
            [
              -94.170495,
              39.21231
            ],
            [
              -94.212515,
              39.207086
            ],
            [
              -94.255424,
              39.236219
            ],
            [
              -94.290785,
              39.236915
            ],
            [
              -94.315074,
              39.219331
            ],
            [
              -94.335213,
              39.183337
            ],
            [
              -94.367481,
              39.163311
            ],
            [
              -94.390694,
              39.194013
            ],
            [
              -94.408628,
              39.193603
            ],
            [
              -94.401575,
              39.166823
            ],
            [
              -94.444292,
              39.129007
            ],
            [
              -94.46159,
              39.120372
            ],
            [
              -94.484616,
              39.145504
            ],
            [
              -94.520887,
              39.149219
            ],
            [
              -94.53572,
              39.144947
            ],
            [
              -94.549573,
              39.130895
            ],
            [
              -94.564724,
              39.12346
            ],
            [
              -94.566029,
              39.123151
            ],
            [
              -94.578825,
              39.117476
            ],
            [
              -94.597756,
              39.109763
            ],
            [
              -94.607354,
              39.113444
            ],
            [
              -94.6072345427118,
              39.0897118187523
            ],
            [
              -94.607234,
              39.089604
            ],
            [
              -94.607334,
              39.081704
            ],
            [
              -94.607234,
              39.065704
            ],
            [
              -94.6075101495286,
              39.0446418244116
            ],
            [
              -94.6075174403161,
              39.044085749416595
            ],
            [
              -94.607992364788,
              39.007862828196195
            ],
            [
              -94.608334,
              38.981806
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "029",
      "COUNTYNS": "00758469",
      "AFFGEOID": "0500000US29029",
      "GEOID": "29029",
      "NAME": "Camden",
      "LSAD": "06",
      "ALAND": 1699090933,
      "AWATER": 136351829,
      "County_state": "Camden,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.077692,
              38.263092
            ],
            [
              -93.082164,
              38.186503
            ],
            [
              -93.059073,
              38.185685
            ],
            [
              -93.064554,
              38.077134
            ],
            [
              -93.065199,
              38.062479
            ],
            [
              -93.072447,
              37.902627
            ],
            [
              -92.855384,
              37.895736
            ],
            [
              -92.620181,
              37.892521
            ],
            [
              -92.614679,
              37.863538
            ],
            [
              -92.599577,
              37.852238
            ],
            [
              -92.600077,
              37.840338
            ],
            [
              -92.570718,
              37.822625
            ],
            [
              -92.564338,
              37.805838
            ],
            [
              -92.549074,
              37.800539
            ],
            [
              -92.53168,
              37.811139
            ],
            [
              -92.513696,
              37.811962
            ],
            [
              -92.501467,
              37.827735
            ],
            [
              -92.480354,
              37.829018
            ],
            [
              -92.452494,
              37.824284
            ],
            [
              -92.449459,
              37.835359
            ],
            [
              -92.419453,
              37.859762
            ],
            [
              -92.409231,
              37.85918
            ],
            [
              -92.406872,
              37.977802
            ],
            [
              -92.406275,
              38.021179
            ],
            [
              -92.515979,
              38.022336
            ],
            [
              -92.51558,
              38.036936
            ],
            [
              -92.533895,
              38.037467
            ],
            [
              -92.533554,
              38.051808
            ],
            [
              -92.552381,
              38.052145
            ],
            [
              -92.552081,
              38.066335
            ],
            [
              -92.570227,
              38.069272
            ],
            [
              -92.569682,
              38.095934
            ],
            [
              -92.587984,
              38.096134
            ],
            [
              -92.587688,
              38.110636
            ],
            [
              -92.596761,
              38.110754
            ],
            [
              -92.596374,
              38.139787
            ],
            [
              -92.604684,
              38.170733
            ],
            [
              -92.641385,
              38.171632
            ],
            [
              -92.640085,
              38.213032
            ],
            [
              -92.675586,
              38.212831
            ],
            [
              -92.695886,
              38.222031
            ],
            [
              -92.731405,
              38.221588
            ],
            [
              -92.755988,
              38.212465
            ],
            [
              -92.764539,
              38.189619
            ],
            [
              -92.80696,
              38.191157
            ],
            [
              -92.962994,
              38.197229
            ],
            [
              -92.934693,
              38.211129
            ],
            [
              -92.931993,
              38.220229
            ],
            [
              -92.960592,
              38.228358
            ],
            [
              -92.979558,
              38.197734
            ],
            [
              -92.989903,
              38.188375
            ],
            [
              -93.01499,
              38.189775
            ],
            [
              -93.022277,
              38.198642
            ],
            [
              -93.01204,
              38.210224
            ],
            [
              -92.988294,
              38.210428
            ],
            [
              -92.986195,
              38.220828
            ],
            [
              -93.00565,
              38.222705
            ],
            [
              -93.013743,
              38.241464
            ],
            [
              -93.042475,
              38.26518
            ],
            [
              -93.056666,
              38.271737
            ],
            [
              -93.077692,
              38.263092
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "201",
      "COUNTYNS": "00758553",
      "AFFGEOID": "0500000US29201",
      "GEOID": "29201",
      "NAME": "Scott",
      "LSAD": "06",
      "ALAND": 1087765645,
      "AWATER": 15199712,
      "County_state": "Scott,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.774512,
              37.097348
            ],
            [
              -89.786867,
              37.087102
            ],
            [
              -89.767608,
              37.051881
            ],
            [
              -89.75705,
              37.049825
            ],
            [
              -89.754727,
              37.03067
            ],
            [
              -89.721175,
              36.99002
            ],
            [
              -89.715424,
              36.952997
            ],
            [
              -89.693943,
              36.94072
            ],
            [
              -89.687657,
              36.922706
            ],
            [
              -89.68469,
              36.923278
            ],
            [
              -89.691892,
              36.901041
            ],
            [
              -89.686683,
              36.887022
            ],
            [
              -89.697389,
              36.874385
            ],
            [
              -89.691603,
              36.859517
            ],
            [
              -89.664556,
              36.860238
            ],
            [
              -89.623629,
              36.861094
            ],
            [
              -89.605965,
              36.861537
            ],
            [
              -89.604596,
              36.866218
            ],
            [
              -89.567101,
              36.870882
            ],
            [
              -89.53773,
              36.869495
            ],
            [
              -89.519809,
              36.869617
            ],
            [
              -89.519932,
              36.876965
            ],
            [
              -89.501516,
              36.877131
            ],
            [
              -89.501683,
              36.906262
            ],
            [
              -89.465161,
              36.906239
            ],
            [
              -89.465393,
              36.935729
            ],
            [
              -89.42995,
              36.935609
            ],
            [
              -89.4299,
              36.950183
            ],
            [
              -89.411299,
              36.950135
            ],
            [
              -89.411289,
              36.964787
            ],
            [
              -89.375064,
              36.964947
            ],
            [
              -89.374613,
              36.994288
            ],
            [
              -89.347215,
              37.002004
            ],
            [
              -89.347107,
              37.009193
            ],
            [
              -89.3126416196891,
              37.0090469003899
            ],
            [
              -89.317168,
              37.012767
            ],
            [
              -89.322982,
              37.01609
            ],
            [
              -89.331164,
              37.019936
            ],
            [
              -89.345996,
              37.025521
            ],
            [
              -89.362397,
              37.030156
            ],
            [
              -89.378277,
              37.039605
            ],
            [
              -89.381644,
              37.04301
            ],
            [
              -89.383937,
              37.046441
            ],
            [
              -89.384681,
              37.048251
            ],
            [
              -89.385434,
              37.05513
            ],
            [
              -89.385186,
              37.057748
            ],
            [
              -89.378889,
              37.070499
            ],
            [
              -89.375712,
              37.080505
            ],
            [
              -89.375615,
              37.085936
            ],
            [
              -89.37871,
              37.094586
            ],
            [
              -89.384175,
              37.103267
            ],
            [
              -89.38805,
              37.107481
            ],
            [
              -89.393427,
              37.111197
            ],
            [
              -89.407451,
              37.119307
            ],
            [
              -89.41173,
              37.122507
            ],
            [
              -89.414471,
              37.12505
            ],
            [
              -89.42558,
              37.138235
            ],
            [
              -89.435202,
              37.15209
            ],
            [
              -89.438275,
              37.161287
            ],
            [
              -89.456105,
              37.18812
            ],
            [
              -89.461862,
              37.199517
            ],
            [
              -89.462676,
              37.203351
            ],
            [
              -89.467631,
              37.2182
            ],
            [
              -89.4675,
              37.221844
            ],
            [
              -89.458302,
              37.240368
            ],
            [
              -89.457832,
              37.242594
            ],
            [
              -89.458246,
              37.247066
            ],
            [
              -89.458827,
              37.248661
            ],
            [
              -89.460692,
              37.250577
            ],
            [
              -89.46266,
              37.25152
            ],
            [
              -89.470525,
              37.253357
            ],
            [
              -89.479205,
              37.253052
            ],
            [
              -89.4887281875826,
              37.251507483022294
            ],
            [
              -89.499992,
              37.245268
            ],
            [
              -89.545986,
              37.247505
            ],
            [
              -89.54612,
              37.240219
            ],
            [
              -89.583327,
              37.233056
            ],
            [
              -89.620256,
              37.233008
            ],
            [
              -89.620457,
              37.21828
            ],
            [
              -89.632917,
              37.218279
            ],
            [
              -89.649659,
              37.218268
            ],
            [
              -89.649464,
              37.205982
            ],
            [
              -89.68612,
              37.19848
            ],
            [
              -89.703805,
              37.183965
            ],
            [
              -89.703797,
              37.155161
            ],
            [
              -89.721783,
              37.14056
            ],
            [
              -89.758512,
              37.140528
            ],
            [
              -89.765211,
              37.126066
            ],
            [
              -89.774512,
              37.097348
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "033",
      "COUNTYNS": "00758471",
      "AFFGEOID": "0500000US29033",
      "GEOID": "29033",
      "NAME": "Carroll",
      "LSAD": "06",
      "ALAND": 1799061775,
      "AWATER": 17533485,
      "County_state": "Carroll,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.759183,
              39.524558
            ],
            [
              -93.758463,
              39.207021
            ],
            [
              -93.738956,
              39.209655
            ],
            [
              -93.71121,
              39.206262
            ],
            [
              -93.700126,
              39.209684
            ],
            [
              -93.681595,
              39.231235
            ],
            [
              -93.661901,
              39.235782
            ],
            [
              -93.650526,
              39.248214
            ],
            [
              -93.628359,
              39.243164
            ],
            [
              -93.58495,
              39.240431
            ],
            [
              -93.559544,
              39.218797
            ],
            [
              -93.516071,
              39.215289
            ],
            [
              -93.49196,
              39.223461
            ],
            [
              -93.487611,
              39.238338
            ],
            [
              -93.496221,
              39.264891
            ],
            [
              -93.492018,
              39.282938
            ],
            [
              -93.477233,
              39.292796
            ],
            [
              -93.464985,
              39.29237
            ],
            [
              -93.444231,
              39.268525
            ],
            [
              -93.411449,
              39.267152
            ],
            [
              -93.402903,
              39.260054
            ],
            [
              -93.415226,
              39.234923
            ],
            [
              -93.396335,
              39.22634
            ],
            [
              -93.368136,
              39.23331
            ],
            [
              -93.333337,
              39.250012
            ],
            [
              -93.328962,
              39.265483
            ],
            [
              -93.343782,
              39.287104
            ],
            [
              -93.344109,
              39.297179
            ],
            [
              -93.332252,
              39.309606
            ],
            [
              -93.292635,
              39.309283
            ],
            [
              -93.277725,
              39.311549
            ],
            [
              -93.255922,
              39.337837
            ],
            [
              -93.230079,
              39.327877
            ],
            [
              -93.215349,
              39.338107
            ],
            [
              -93.192037,
              39.365705
            ],
            [
              -93.190199,
              39.381792
            ],
            [
              -93.200708,
              39.401787
            ],
            [
              -93.184755,
              39.411348
            ],
            [
              -93.164322,
              39.405007
            ],
            [
              -93.139631,
              39.378643
            ],
            [
              -93.104485,
              39.383656
            ],
            [
              -93.115685,
              39.389455
            ],
            [
              -93.117177,
              39.415861
            ],
            [
              -93.14084,
              39.423642
            ],
            [
              -93.15475,
              39.421723
            ],
            [
              -93.16555,
              39.436335
            ],
            [
              -93.183537,
              39.432473
            ],
            [
              -93.197222,
              39.436845
            ],
            [
              -93.213581,
              39.430507
            ],
            [
              -93.211233,
              39.441405
            ],
            [
              -93.228755,
              39.436297
            ],
            [
              -93.219546,
              39.450793
            ],
            [
              -93.228095,
              39.473215
            ],
            [
              -93.247415,
              39.458092
            ],
            [
              -93.275848,
              39.458302
            ],
            [
              -93.264322,
              39.461182
            ],
            [
              -93.266338,
              39.478358
            ],
            [
              -93.276539,
              39.496705
            ],
            [
              -93.265951,
              39.497905
            ],
            [
              -93.283007,
              39.519477
            ],
            [
              -93.265968,
              39.549637
            ],
            [
              -93.266399,
              39.572038
            ],
            [
              -93.274498,
              39.588587
            ],
            [
              -93.270135,
              39.59736
            ],
            [
              -93.278098,
              39.615632
            ],
            [
              -93.758357,
              39.611407
            ],
            [
              -93.759183,
              39.524558
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "005",
      "COUNTYNS": "00758457",
      "AFFGEOID": "0500000US29005",
      "GEOID": "29005",
      "NAME": "Atchison",
      "LSAD": "06",
      "ALAND": 1417462632,
      "AWATER": 7359184,
      "County_state": "Atchison,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.773549,
              40.578205
            ],
            [
              -95.774704,
              40.573574
            ],
            [
              -95.771776,
              40.566133
            ],
            [
              -95.76503,
              40.556921
            ],
            [
              -95.763833,
              40.553873
            ],
            [
              -95.763366,
              40.550797
            ],
            [
              -95.763624,
              40.548298
            ],
            [
              -95.764696,
              40.545721
            ],
            [
              -95.768412,
              40.540347
            ],
            [
              -95.769281,
              40.536656
            ],
            [
              -95.768693,
              40.534106
            ],
            [
              -95.76692,
              40.531563
            ],
            [
              -95.762857,
              40.528371
            ],
            [
              -95.75711,
              40.52599
            ],
            [
              -95.74868,
              40.524275
            ],
            [
              -95.73725,
              40.52393
            ],
            [
              -95.731179,
              40.525436
            ],
            [
              -95.725214,
              40.527773
            ],
            [
              -95.722444,
              40.528118
            ],
            [
              -95.714291,
              40.527208
            ],
            [
              -95.709974,
              40.523798
            ],
            [
              -95.708591,
              40.521551
            ],
            [
              -95.69721,
              40.528477
            ],
            [
              -95.69505,
              40.533124
            ],
            [
              -95.697281,
              40.536985
            ],
            [
              -95.696673,
              40.545137
            ],
            [
              -95.694881,
              40.55072
            ],
            [
              -95.694147,
              40.556942
            ],
            [
              -95.687109,
              40.560664
            ],
            [
              -95.678718,
              40.56256
            ],
            [
              -95.671754,
              40.562626
            ],
            [
              -95.665486,
              40.556686
            ],
            [
              -95.662097,
              40.549959
            ],
            [
              -95.655848,
              40.546609
            ],
            [
              -95.65341,
              40.541893
            ],
            [
              -95.652262,
              40.538114
            ],
            [
              -95.655674,
              40.523557
            ],
            [
              -95.661687,
              40.517309
            ],
            [
              -95.667981,
              40.51496
            ],
            [
              -95.692083,
              40.508133
            ],
            [
              -95.695604,
              40.506473
            ],
            [
              -95.699969,
              40.505275
            ],
            [
              -95.695945,
              40.499051
            ],
            [
              -95.694726,
              40.493602
            ],
            [
              -95.695247,
              40.486587
            ],
            [
              -95.696206,
              40.482113
            ],
            [
              -95.696756,
              40.478849
            ],
            [
              -95.696365,
              40.475897
            ],
            [
              -95.694651,
              40.471452
            ],
            [
              -95.693133,
              40.469396
            ],
            [
              -95.684363,
              40.463366
            ],
            [
              -95.677174,
              40.460158
            ],
            [
              -95.671742,
              40.456695
            ],
            [
              -95.665413,
              40.451182
            ],
            [
              -95.65819,
              40.44188
            ],
            [
              -95.65563,
              40.434736
            ],
            [
              -95.656288,
              40.428765
            ],
            [
              -95.65831,
              40.424538
            ],
            [
              -95.660721,
              40.418841
            ],
            [
              -95.661463,
              40.415947
            ],
            [
              -95.659134,
              40.40869
            ],
            [
              -95.649418,
              40.396149
            ],
            [
              -95.643934,
              40.386849
            ],
            [
              -95.642147,
              40.378243
            ],
            [
              -95.642679,
              40.375001
            ],
            [
              -95.642414,
              40.369829
            ],
            [
              -95.641027,
              40.366399
            ],
            [
              -95.636991,
              40.36136
            ],
            [
              -95.631481,
              40.35731
            ],
            [
              -95.627124,
              40.3528
            ],
            [
              -95.624815,
              40.349214
            ],
            [
              -95.623728,
              40.346567
            ],
            [
              -95.622704,
              40.340856
            ],
            [
              -95.623182,
              40.338367
            ],
            [
              -95.625204,
              40.334288
            ],
            [
              -95.629936,
              40.330994
            ],
            [
              -95.633807,
              40.329297
            ],
            [
              -95.647931,
              40.325556
            ],
            [
              -95.653729,
              40.322582
            ],
            [
              -95.656612,
              40.319465
            ],
            [
              -95.657764,
              40.315788
            ],
            [
              -95.658025,
              40.3127
            ],
            [
              -95.657328,
              40.310856
            ],
            [
              -95.654294,
              40.307906
            ],
            [
              -95.651507,
              40.306684
            ],
            [
              -95.645329,
              40.305693
            ],
            [
              -95.642262,
              40.306025
            ],
            [
              -95.63631,
              40.307675
            ],
            [
              -95.623213,
              40.312469
            ],
            [
              -95.617931,
              40.313728
            ],
            [
              -95.613479,
              40.314233
            ],
            [
              -95.610439,
              40.31397
            ],
            [
              -95.60511,
              40.312559
            ],
            [
              -95.598657,
              40.309809
            ],
            [
              -95.590165,
              40.303199
            ],
            [
              -95.587371,
              40.301649
            ],
            [
              -95.581787,
              40.29958
            ],
            [
              -95.56814,
              40.299129
            ],
            [
              -95.562157,
              40.297359
            ],
            [
              -95.558732,
              40.295774
            ],
            [
              -95.553292,
              40.291158
            ],
            [
              -95.55162,
              40.288666
            ],
            [
              -95.550966,
              40.285947
            ],
            [
              -95.551488,
              40.281061
            ],
            [
              -95.556275,
              40.270761
            ],
            [
              -95.556325,
              40.267714
            ],
            [
              -95.554817342733,
              40.26445984121119
            ],
            [
              -95.180614,
              40.261706
            ],
            [
              -95.177708,
              40.478903
            ],
            [
              -95.202017,
              40.479135
            ],
            [
              -95.2022644684083,
              40.578527764403404
            ],
            [
              -95.211408,
              40.57865
            ],
            [
              -95.21159,
              40.578654
            ],
            [
              -95.212715,
              40.578679
            ],
            [
              -95.213327,
              40.578689
            ],
            [
              -95.217455,
              40.578759
            ],
            [
              -95.218783,
              40.578781
            ],
            [
              -95.221525,
              40.578827
            ],
            [
              -95.335588,
              40.579871
            ],
            [
              -95.357802,
              40.5801
            ],
            [
              -95.373893,
              40.580501
            ],
            [
              -95.37392302013079,
              40.580501370976
            ],
            [
              -95.415406,
              40.581014
            ],
            [
              -95.469319,
              40.58154
            ],
            [
              -95.525392,
              40.58209
            ],
            [
              -95.526682,
              40.582136
            ],
            [
              -95.533182,
              40.582249
            ],
            [
              -95.554959,
              40.582629
            ],
            [
              -95.574046,
              40.582963
            ],
            [
              -95.611069,
              40.583495
            ],
            [
              -95.64184,
              40.584234
            ],
            [
              -95.687442,
              40.58438
            ],
            [
              -95.6875,
              40.584381
            ],
            [
              -95.746443,
              40.584935
            ],
            [
              -95.765645,
              40.585208
            ],
            [
              -95.768527,
              40.583296
            ],
            [
              -95.773549,
              40.578205
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "075",
      "COUNTYNS": "00758492",
      "AFFGEOID": "0500000US29075",
      "GEOID": "29075",
      "NAME": "Gentry",
      "LSAD": "06",
      "ALAND": 1272776756,
      "AWATER": 762384,
      "County_state": "Gentry,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.605942,
              40.126127
            ],
            [
              -94.606554,
              40.044898
            ],
            [
              -94.605377,
              40.039217
            ],
            [
              -94.329967,
              40.039255
            ],
            [
              -94.2188,
              40.034885
            ],
            [
              -94.216696,
              40.136626
            ],
            [
              -94.214278,
              40.384254
            ],
            [
              -94.598487,
              40.386714
            ],
            [
              -94.605942,
              40.126127
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "021",
      "COUNTYNS": "00758465",
      "AFFGEOID": "0500000US29021",
      "GEOID": "29021",
      "NAME": "Buchanan",
      "LSAD": "06",
      "ALAND": 1056742981,
      "AWATER": 17211398,
      "County_state": "Buchanan,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.113077,
              39.559133
            ],
            [
              -95.113557,
              39.553941
            ],
            [
              -95.109304,
              39.542285
            ],
            [
              -95.106596,
              39.537657
            ],
            [
              -95.102888,
              39.533347
            ],
            [
              -94.607146,
              39.532092
            ],
            [
              -94.601246,
              39.530372
            ],
            [
              -94.602124,
              39.616743
            ],
            [
              -94.602257,
              39.747195
            ],
            [
              -94.602819,
              39.819608
            ],
            [
              -94.822018,
              39.821224
            ],
            [
              -94.876544,
              39.820594
            ],
            [
              -94.875944,
              39.813294
            ],
            [
              -94.876344,
              39.806894
            ],
            [
              -94.880932,
              39.797338
            ],
            [
              -94.884084,
              39.794234
            ],
            [
              -94.890292,
              39.791626
            ],
            [
              -94.892965,
              39.791098
            ],
            [
              -94.925605,
              39.789754
            ],
            [
              -94.929654,
              39.788282
            ],
            [
              -94.932726,
              39.786282
            ],
            [
              -94.935206,
              39.78313
            ],
            [
              -94.935782,
              39.778906
            ],
            [
              -94.935302,
              39.77561
            ],
            [
              -94.934262,
              39.773642
            ],
            [
              -94.929653,
              39.769098
            ],
            [
              -94.926229,
              39.76649
            ],
            [
              -94.916789,
              39.760938
            ],
            [
              -94.912293,
              39.759338
            ],
            [
              -94.906244,
              39.759418
            ],
            [
              -94.899156,
              39.761258
            ],
            [
              -94.895268,
              39.76321
            ],
            [
              -94.895041,
              39.76335
            ],
            [
              -94.894071,
              39.763946
            ],
            [
              -94.893919,
              39.76404
            ],
            [
              -94.893724,
              39.76416
            ],
            [
              -94.893646,
              39.764208
            ],
            [
              -94.883924,
              39.770186
            ],
            [
              -94.88146,
              39.771258
            ],
            [
              -94.881422,
              39.771258
            ],
            [
              -94.871144,
              39.772994
            ],
            [
              -94.869644,
              39.772894
            ],
            [
              -94.867143,
              39.771694
            ],
            [
              -94.865243,
              39.770094
            ],
            [
              -94.863143,
              39.767294
            ],
            [
              -94.860743,
              39.763094
            ],
            [
              -94.859443,
              39.753694
            ],
            [
              -94.8594779750445,
              39.753537064563204
            ],
            [
              -94.860371,
              39.74953
            ],
            [
              -94.862943,
              39.742994
            ],
            [
              -94.870143,
              39.734594
            ],
            [
              -94.875643,
              39.730494
            ],
            [
              -94.884143,
              39.726794
            ],
            [
              -94.891744,
              39.724894
            ],
            [
              -94.899316,
              39.724042
            ],
            [
              -94.902612,
              39.724202
            ],
            [
              -94.910068,
              39.725786
            ],
            [
              -94.918324,
              39.728794
            ],
            [
              -94.930005,
              39.73537
            ],
            [
              -94.939221,
              39.741578
            ],
            [
              -94.944741,
              39.744377
            ],
            [
              -94.948726,
              39.745593
            ],
            [
              -94.95263,
              39.745961
            ],
            [
              -94.955286,
              39.745689
            ],
            [
              -94.960086,
              39.743065
            ],
            [
              -94.965318,
              39.739065
            ],
            [
              -94.970422,
              39.732121
            ],
            [
              -94.971206,
              39.729305
            ],
            [
              -94.971078,
              39.723146
            ],
            [
              -94.968453,
              39.707402
            ],
            [
              -94.968981,
              39.692954
            ],
            [
              -94.969909,
              39.68905
            ],
            [
              -94.971317,
              39.68641
            ],
            [
              -94.976325,
              39.68137
            ],
            [
              -94.981557,
              39.678634
            ],
            [
              -94.984149,
              39.67785
            ],
            [
              -94.993557,
              39.67657
            ],
            [
              -95.001379,
              39.676479
            ],
            [
              -95.009023,
              39.675765
            ],
            [
              -95.01531,
              39.674262
            ],
            [
              -95.018318,
              39.672869
            ],
            [
              -95.024595,
              39.668485
            ],
            [
              -95.027644,
              39.665454
            ],
            [
              -95.037464,
              39.652905
            ],
            [
              -95.039049,
              39.649639
            ],
            [
              -95.044554,
              39.64437
            ],
            [
              -95.049518,
              39.637876
            ],
            [
              -95.053367,
              39.630347
            ],
            [
              -95.054925,
              39.624995
            ],
            [
              -95.055152,
              39.621657
            ],
            [
              -95.05338996101001,
              39.615323540228594
            ],
            [
              -95.053012,
              39.613965
            ],
            [
              -95.047911,
              39.606288
            ],
            [
              -95.046445,
              39.601606
            ],
            [
              -95.046361,
              39.599557
            ],
            [
              -95.047165,
              39.595117
            ],
            [
              -95.049277,
              39.589583
            ],
            [
              -95.054804,
              39.582488
            ],
            [
              -95.056897,
              39.580567
            ],
            [
              -95.059519,
              39.579132
            ],
            [
              -95.064519,
              39.577115
            ],
            [
              -95.069315,
              39.576218
            ],
            [
              -95.07216,
              39.576122
            ],
            [
              -95.076688,
              39.576764
            ],
            [
              -95.089515,
              39.581028
            ],
            [
              -95.095736,
              39.580618
            ],
            [
              -95.099095,
              39.579691
            ],
            [
              -95.103228,
              39.577783
            ],
            [
              -95.106406,
              39.575252
            ],
            [
              -95.107454,
              39.573843
            ],
            [
              -95.113077,
              39.559133
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "007",
      "COUNTYNS": "00758458",
      "AFFGEOID": "0500000US29007",
      "GEOID": "29007",
      "NAME": "Audrain",
      "LSAD": "06",
      "ALAND": 1792880224,
      "AWATER": 11880946,
      "County_state": "Audrain,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.310538,
              39.333905
            ],
            [
              -92.314471,
              39.246454
            ],
            [
              -92.104374,
              39.239809
            ],
            [
              -92.110387,
              39.064204
            ],
            [
              -91.633998,
              39.059057
            ],
            [
              -91.629598,
              39.147853
            ],
            [
              -91.409033,
              39.142542
            ],
            [
              -91.438235,
              39.318716
            ],
            [
              -91.623822,
              39.324219
            ],
            [
              -91.718467,
              39.324505
            ],
            [
              -91.717968,
              39.339122
            ],
            [
              -92.088295,
              39.34068
            ],
            [
              -92.310028,
              39.347795
            ],
            [
              -92.310538,
              39.333905
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "101",
      "COUNTYNS": "00758505",
      "AFFGEOID": "0500000US29101",
      "GEOID": "29101",
      "NAME": "Johnson",
      "LSAD": "06",
      "ALAND": 2147833558,
      "AWATER": 10122996,
      "County_state": "Johnson,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.119096,
              38.833167
            ],
            [
              -94.129372,
              38.655862
            ],
            [
              -94.116408,
              38.655518
            ],
            [
              -94.117961,
              38.612267
            ],
            [
              -94.119664,
              38.568712
            ],
            [
              -94.064317,
              38.56738
            ],
            [
              -93.51103,
              38.55621
            ],
            [
              -93.506424,
              38.679589
            ],
            [
              -93.505503,
              38.694133
            ],
            [
              -93.500848,
              38.766688
            ],
            [
              -93.499941,
              38.781198
            ],
            [
              -93.493733,
              38.888203
            ],
            [
              -93.498616,
              38.8993
            ],
            [
              -93.497278,
              38.92843
            ],
            [
              -93.834719,
              38.937909
            ],
            [
              -93.836319,
              38.908709
            ],
            [
              -93.943675,
              38.912386
            ],
            [
              -94.100787,
              38.9173
            ],
            [
              -94.114175,
              38.917304
            ],
            [
              -94.113859,
              38.912437
            ],
            [
              -94.119096,
              38.833167
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "017",
      "COUNTYNS": "00758463",
      "AFFGEOID": "0500000US29017",
      "GEOID": "29017",
      "NAME": "Bollinger",
      "LSAD": "06",
      "ALAND": 1600363402,
      "AWATER": 8531304,
      "County_state": "Bollinger,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.217657,
              37.314967
            ],
            [
              -90.22323,
              37.086519
            ],
            [
              -90.110354,
              37.085871
            ],
            [
              -90.111247,
              37.041205
            ],
            [
              -89.99572,
              37.04011
            ],
            [
              -89.995852,
              37.054734
            ],
            [
              -89.959196,
              37.05438
            ],
            [
              -89.959576,
              37.127353
            ],
            [
              -89.866627,
              37.126227
            ],
            [
              -89.866794,
              37.216352
            ],
            [
              -89.86326,
              37.219035
            ],
            [
              -89.861123,
              37.599286
            ],
            [
              -90.146763,
              37.597434
            ],
            [
              -90.138536,
              37.541219
            ],
            [
              -90.145699,
              37.312091
            ],
            [
              -90.217657,
              37.314967
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "035",
      "COUNTYNS": "00758472",
      "AFFGEOID": "0500000US29035",
      "GEOID": "29035",
      "NAME": "Carter",
      "LSAD": "06",
      "ALAND": 1314052209,
      "AWATER": 4305247,
      "County_state": "Carter,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.22283,
              36.883857
            ],
            [
              -91.224825,
              36.825679
            ],
            [
              -91.115926,
              36.823672
            ],
            [
              -91.025335,
              36.822148
            ],
            [
              -90.946826,
              36.817954
            ],
            [
              -90.895724,
              36.812854
            ],
            [
              -90.662617,
              36.811453
            ],
            [
              -90.661244,
              36.882909
            ],
            [
              -90.679729,
              36.883107
            ],
            [
              -90.678562,
              36.92649
            ],
            [
              -90.696891,
              36.927186
            ],
            [
              -90.695419,
              36.970751
            ],
            [
              -90.71342,
              36.971051
            ],
            [
              -90.713239,
              36.986065
            ],
            [
              -90.712986,
              37.000367
            ],
            [
              -90.730938,
              37.000398
            ],
            [
              -90.729722,
              37.049534
            ],
            [
              -90.779553,
              37.050324
            ],
            [
              -90.964466,
              37.053584
            ],
            [
              -90.963562,
              37.09736
            ],
            [
              -90.99989,
              37.100404
            ],
            [
              -91.017766,
              37.095677
            ],
            [
              -91.066736,
              37.093775
            ],
            [
              -91.10437,
              37.086663
            ],
            [
              -91.21715,
              37.086197
            ],
            [
              -91.22283,
              36.883857
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "019",
      "COUNTYNS": "00758464",
      "AFFGEOID": "0500000US29019",
      "GEOID": "29019",
      "NAME": "Boone",
      "LSAD": "06",
      "ALAND": 1775563346,
      "AWATER": 14239688,
      "County_state": "Boone,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.570111,
              38.979008
            ],
            [
              -92.558086,
              38.970692
            ],
            [
              -92.508657,
              38.93271
            ],
            [
              -92.498377,
              38.922084
            ],
            [
              -92.479225,
              38.907879
            ],
            [
              -92.474259,
              38.901596
            ],
            [
              -92.477794,
              38.874107
            ],
            [
              -92.456717,
              38.860269
            ],
            [
              -92.444856,
              38.838344
            ],
            [
              -92.416872,
              38.818869
            ],
            [
              -92.397237,
              38.81983
            ],
            [
              -92.381304,
              38.808732
            ],
            [
              -92.38298,
              38.789725
            ],
            [
              -92.409409,
              38.764277
            ],
            [
              -92.394866,
              38.73913
            ],
            [
              -92.360216,
              38.730133
            ],
            [
              -92.343265,
              38.705073
            ],
            [
              -92.36091,
              38.681848
            ],
            [
              -92.29337,
              38.666564
            ],
            [
              -92.251523,
              38.64419
            ],
            [
              -92.229401,
              38.646027
            ],
            [
              -92.220661,
              38.643167
            ],
            [
              -92.21846,
              38.689051
            ],
            [
              -92.226091,
              38.697852
            ],
            [
              -92.215691,
              38.702487
            ],
            [
              -92.216388,
              38.714495
            ],
            [
              -92.192431,
              38.726132
            ],
            [
              -92.194159,
              38.736647
            ],
            [
              -92.17095,
              38.7653
            ],
            [
              -92.163428,
              38.764814
            ],
            [
              -92.164867,
              38.782505
            ],
            [
              -92.159384,
              38.800426
            ],
            [
              -92.135158,
              38.814389
            ],
            [
              -92.1525,
              38.825447
            ],
            [
              -92.145523,
              38.832777
            ],
            [
              -92.149272,
              38.866238
            ],
            [
              -92.161916,
              38.865284
            ],
            [
              -92.158291,
              38.911084
            ],
            [
              -92.153366,
              38.943578
            ],
            [
              -92.143265,
              38.967854
            ],
            [
              -92.14706,
              38.977577
            ],
            [
              -92.136528,
              38.995118
            ],
            [
              -92.140289,
              39.001102
            ],
            [
              -92.13022,
              39.023642
            ],
            [
              -92.135974,
              39.032397
            ],
            [
              -92.131512,
              39.064484
            ],
            [
              -92.110387,
              39.064204
            ],
            [
              -92.104374,
              39.239809
            ],
            [
              -92.314471,
              39.246454
            ],
            [
              -92.370556,
              39.247688
            ],
            [
              -92.372492,
              39.247724
            ],
            [
              -92.430229,
              39.248795
            ],
            [
              -92.513553,
              39.088806
            ],
            [
              -92.565124,
              38.998274
            ],
            [
              -92.556383,
              38.985644
            ],
            [
              -92.570111,
              38.979008
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "157",
      "COUNTYNS": "00758533",
      "AFFGEOID": "0500000US29157",
      "GEOID": "29157",
      "NAME": "Perry",
      "LSAD": "06",
      "ALAND": 1228575201,
      "AWATER": 25393801,
      "County_state": "Perry,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.146778,
              37.641618
            ],
            [
              -90.146763,
              37.597434
            ],
            [
              -89.861123,
              37.599286
            ],
            [
              -89.802261,
              37.602706
            ],
            [
              -89.802206,
              37.593679
            ],
            [
              -89.748588,
              37.590217
            ],
            [
              -89.727266,
              37.599833
            ],
            [
              -89.719313,
              37.587857
            ],
            [
              -89.711123,
              37.599428
            ],
            [
              -89.684126,
              37.593541
            ],
            [
              -89.672802,
              37.583761
            ],
            [
              -89.665362,
              37.598197
            ],
            [
              -89.647518,
              37.59765
            ],
            [
              -89.625785,
              37.588462
            ],
            [
              -89.602429,
              37.603024
            ],
            [
              -89.591972,
              37.574613
            ],
            [
              -89.581474,
              37.571011
            ],
            [
              -89.555365,
              37.576819
            ],
            [
              -89.54339,
              37.567009
            ],
            [
              -89.5217295671219,
              37.5662095222196
            ],
            [
              -89.52156646342159,
              37.5707784301758
            ],
            [
              -89.5215174256744,
              37.57215208971179
            ],
            [
              -89.521274,
              37.578971
            ],
            [
              -89.520804,
              37.581155
            ],
            [
              -89.519808,
              37.582748
            ],
            [
              -89.516538,
              37.584504
            ],
            [
              -89.513943,
              37.584815
            ],
            [
              -89.509542,
              37.584147
            ],
            [
              -89.494051,
              37.580116
            ],
            [
              -89.486062,
              37.580853
            ],
            [
              -89.481118,
              37.582973
            ],
            [
              -89.477548,
              37.585885
            ],
            [
              -89.47603,
              37.590226
            ],
            [
              -89.475932,
              37.592998
            ],
            [
              -89.476514,
              37.595554
            ],
            [
              -89.478399,
              37.598869
            ],
            [
              -89.485792,
              37.607157
            ],
            [
              -89.506563,
              37.62505
            ],
            [
              -89.510526,
              37.631755
            ],
            [
              -89.515649,
              37.636446
            ],
            [
              -89.517718,
              37.641217
            ],
            [
              -89.517136,
              37.643789
            ],
            [
              -89.51586,
              37.645555
            ],
            [
              -89.515903,
              37.650803
            ],
            [
              -89.516827,
              37.656089
            ],
            [
              -89.516146,
              37.667975
            ],
            [
              -89.513927,
              37.676575
            ],
            [
              -89.51204,
              37.680985
            ],
            [
              -89.512009,
              37.685525
            ],
            [
              -89.514255,
              37.689923
            ],
            [
              -89.516685,
              37.692762
            ],
            [
              -89.521948,
              37.696475
            ],
            [
              -89.52573,
              37.698441
            ],
            [
              -89.531427,
              37.700334
            ],
            [
              -89.538652,
              37.701054
            ],
            [
              -89.566704,
              37.707189
            ],
            [
              -89.573516,
              37.709065
            ],
            [
              -89.583316,
              37.713261
            ],
            [
              -89.587213,
              37.71751
            ],
            [
              -89.591289,
              37.723599
            ],
            [
              -89.596566,
              37.732886
            ],
            [
              -89.602406,
              37.736492
            ],
            [
              -89.608757,
              37.739684
            ],
            [
              -89.612478,
              37.740036
            ],
            [
              -89.615586,
              37.74235
            ],
            [
              -89.616194,
              37.744283
            ],
            [
              -89.615933,
              37.748184
            ],
            [
              -89.616389,
              37.749167
            ],
            [
              -89.617278,
              37.74972
            ],
            [
              -89.624023,
              37.74912
            ],
            [
              -89.62801,
              37.748135
            ],
            [
              -89.63337,
              37.745782
            ],
            [
              -89.645429,
              37.746108
            ],
            [
              -89.64953,
              37.745498
            ],
            [
              -89.658455,
              37.74771
            ],
            [
              -89.663352,
              37.750052
            ],
            [
              -89.665546,
              37.752095
            ],
            [
              -89.6660352292041,
              37.753572284262
            ],
            [
              -89.667993,
              37.759484
            ],
            [
              -89.666474,
              37.764195
            ],
            [
              -89.66413,
              37.76851
            ],
            [
              -89.66119,
              37.775732
            ],
            [
              -89.660227,
              37.781032
            ],
            [
              -89.66038,
              37.786296
            ],
            [
              -89.66132,
              37.788204
            ],
            [
              -89.663982,
              37.790801
            ],
            [
              -89.669644,
              37.799922
            ],
            [
              -89.67438986535349,
              37.8029885408088
            ],
            [
              -89.677605,
              37.805066
            ],
            [
              -89.68285,
              37.807789
            ],
            [
              -89.696559,
              37.814337
            ],
            [
              -89.702844,
              37.816812
            ],
            [
              -89.71748,
              37.825724
            ],
            [
              -89.729426,
              37.835081
            ],
            [
              -89.732737,
              37.838507
            ],
            [
              -89.736439,
              37.843494
            ],
            [
              -89.739873,
              37.84693
            ],
            [
              -89.749961,
              37.846984
            ],
            [
              -89.754104,
              37.846358
            ],
            [
              -89.757363,
              37.847613
            ],
            [
              -89.761992,
              37.850657
            ],
            [
              -89.765222,
              37.851782
            ],
            [
              -89.774306,
              37.852123
            ],
            [
              -89.779828,
              37.853896
            ],
            [
              -89.782035,
              37.855092
            ],
            [
              -89.786369,
              37.851734
            ],
            [
              -89.793718,
              37.857054
            ],
            [
              -89.796087,
              37.859505
            ],
            [
              -89.80036,
              37.868625
            ],
            [
              -89.799835,
              37.871367
            ],
            [
              -89.797678,
              37.874202
            ],
            [
              -89.798041,
              37.879655
            ],
            [
              -89.799333,
              37.881517
            ],
            [
              -89.803913,
              37.882985
            ],
            [
              -89.813647,
              37.88771
            ],
            [
              -89.836254,
              37.901802
            ],
            [
              -89.842649,
              37.905196
            ],
            [
              -89.844786,
              37.905572
            ],
            [
              -89.851048,
              37.90398
            ],
            [
              -89.862949,
              37.896906
            ],
            [
              -89.866988,
              37.893519
            ],
            [
              -89.876594,
              37.883294
            ],
            [
              -89.881475,
              37.879591
            ],
            [
              -89.897301,
              37.871605
            ],
            [
              -89.901832,
              37.869822
            ],
            [
              -89.913317,
              37.869641
            ],
            [
              -89.923185,
              37.870672
            ],
            [
              -89.937383,
              37.874693
            ],
            [
              -89.93819072186169,
              37.8751107703036
            ],
            [
              -89.999923,
              37.821523
            ],
            [
              -90.034488,
              37.772831
            ],
            [
              -90.110537,
              37.67134
            ],
            [
              -90.146778,
              37.641618
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "113",
      "COUNTYNS": "00758511",
      "AFFGEOID": "0500000US29113",
      "GEOID": "29113",
      "NAME": "Lincoln",
      "LSAD": "06",
      "ALAND": 1622786223,
      "AWATER": 36005143,
      "County_state": "Lincoln,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.260232,
              39.139845
            ],
            [
              -91.264287,
              38.992762
            ],
            [
              -91.18839,
              38.992141
            ],
            [
              -91.189348,
              38.932151
            ],
            [
              -91.110187,
              38.931901
            ],
            [
              -91.111251,
              38.872709
            ],
            [
              -91.07373,
              38.872738
            ],
            [
              -90.958536,
              38.870865
            ],
            [
              -90.95645,
              38.894164
            ],
            [
              -90.947309,
              38.893478
            ],
            [
              -90.947125,
              38.893347
            ],
            [
              -90.947061,
              38.893101
            ],
            [
              -90.94709,
              38.892925
            ],
            [
              -90.946961,
              38.892545
            ],
            [
              -90.946988,
              38.892416
            ],
            [
              -90.946644,
              38.892162
            ],
            [
              -90.946436,
              38.892088
            ],
            [
              -90.945801,
              38.892035
            ],
            [
              -90.945552,
              38.891944
            ],
            [
              -90.944714,
              38.891387
            ],
            [
              -90.944515,
              38.891109
            ],
            [
              -90.944534,
              38.890618
            ],
            [
              -90.94439,
              38.890317
            ],
            [
              -90.944041,
              38.889986
            ],
            [
              -90.943698,
              38.889565
            ],
            [
              -90.943401,
              38.889399
            ],
            [
              -90.942541,
              38.888633
            ],
            [
              -90.942362,
              38.888265
            ],
            [
              -90.94187,
              38.887797
            ],
            [
              -90.941079,
              38.887311
            ],
            [
              -90.940938,
              38.887169
            ],
            [
              -90.940639,
              38.886685
            ],
            [
              -90.940387,
              38.88653
            ],
            [
              -90.940172,
              38.886472
            ],
            [
              -90.939583,
              38.88651
            ],
            [
              -90.939455,
              38.886475
            ],
            [
              -90.939197,
              38.88654
            ],
            [
              -90.939088,
              38.886655
            ],
            [
              -90.938829,
              38.887224
            ],
            [
              -90.938414,
              38.887494
            ],
            [
              -90.938154,
              38.888061
            ],
            [
              -90.937979,
              38.888163
            ],
            [
              -90.937757,
              38.888199
            ],
            [
              -90.937278,
              38.888086
            ],
            [
              -90.936636,
              38.887575
            ],
            [
              -90.93608,
              38.887325
            ],
            [
              -90.935109,
              38.886999
            ],
            [
              -90.93477,
              38.886772
            ],
            [
              -90.934594,
              38.886525
            ],
            [
              -90.934392,
              38.886458
            ],
            [
              -90.934342,
              38.886325
            ],
            [
              -90.934382,
              38.886044
            ],
            [
              -90.934518,
              38.885947
            ],
            [
              -90.934499,
              38.885845
            ],
            [
              -90.934073,
              38.885335
            ],
            [
              -90.933877,
              38.885184
            ],
            [
              -90.933455,
              38.885049
            ],
            [
              -90.93322,
              38.885146
            ],
            [
              -90.932831,
              38.885903
            ],
            [
              -90.93232,
              38.885996
            ],
            [
              -90.931738,
              38.885945
            ],
            [
              -90.931278,
              38.886498
            ],
            [
              -90.930592,
              38.886626
            ],
            [
              -90.929266,
              38.886998
            ],
            [
              -90.928588,
              38.887561
            ],
            [
              -90.927819,
              38.887408
            ],
            [
              -90.927588,
              38.886979
            ],
            [
              -90.927061,
              38.886587
            ],
            [
              -90.926652,
              38.886546
            ],
            [
              -90.926154,
              38.886695
            ],
            [
              -90.925664,
              38.887118
            ],
            [
              -90.92548,
              38.887743
            ],
            [
              -90.925663,
              38.88814
            ],
            [
              -90.9256,
              38.888474
            ],
            [
              -90.925191,
              38.888631
            ],
            [
              -90.924742,
              38.888571
            ],
            [
              -90.924164,
              38.888287
            ],
            [
              -90.922967,
              38.887943
            ],
            [
              -90.922461,
              38.887529
            ],
            [
              -90.922121,
              38.887426
            ],
            [
              -90.920795,
              38.887404
            ],
            [
              -90.920037,
              38.887548
            ],
            [
              -90.918995,
              38.887948
            ],
            [
              -90.864216,
              38.887828
            ],
            [
              -90.817827,
              38.875966
            ],
            [
              -90.807577,
              38.87766
            ],
            [
              -90.805944,
              38.901733
            ],
            [
              -90.804418,
              38.911139
            ],
            [
              -90.77821,
              38.922488
            ],
            [
              -90.754113,
              38.920747
            ],
            [
              -90.746086,
              38.928686
            ],
            [
              -90.723701,
              38.925932
            ],
            [
              -90.73367,
              38.914272
            ],
            [
              -90.708149,
              38.902591
            ],
            [
              -90.707322,
              38.92149
            ],
            [
              -90.683773,
              38.915986
            ],
            [
              -90.694001,
              38.933863
            ],
            [
              -90.6655700805267,
              38.934198408333195
            ],
            [
              -90.669229,
              38.948176
            ],
            [
              -90.671844,
              38.952296
            ],
            [
              -90.675949,
              38.96214
            ],
            [
              -90.676417,
              38.965812
            ],
            [
              -90.676397,
              38.984096
            ],
            [
              -90.678193,
              38.991851
            ],
            [
              -90.682068,
              38.998778
            ],
            [
              -90.683683,
              39.000049
            ],
            [
              -90.684978937801,
              39.001758828739
            ],
            [
              -90.687719,
              39.005374
            ],
            [
              -90.688813,
              39.007342
            ],
            [
              -90.69,
              39.012169
            ],
            [
              -90.692403,
              39.016656
            ],
            [
              -90.700595,
              39.029074
            ],
            [
              -90.707885,
              39.042262
            ],
            [
              -90.71158,
              39.046798
            ],
            [
              -90.713629,
              39.053977
            ],
            [
              -90.713585,
              39.055567
            ],
            [
              -90.712541,
              39.057064
            ],
            [
              -90.702136,
              39.065568
            ],
            [
              -90.701187,
              39.070408
            ],
            [
              -90.700424,
              39.071787
            ],
            [
              -90.682744,
              39.088348
            ],
            [
              -90.681994,
              39.090066
            ],
            [
              -90.681086,
              39.10059
            ],
            [
              -90.684518,
              39.113567
            ],
            [
              -90.686051,
              39.117785
            ],
            [
              -90.6876585183957,
              39.1199349248164
            ],
            [
              -90.694945,
              39.12968
            ],
            [
              -90.700464,
              39.135439
            ],
            [
              -90.702923,
              39.138749
            ],
            [
              -90.705168,
              39.143152
            ],
            [
              -90.707902,
              39.15086
            ],
            [
              -90.709146,
              39.155111
            ],
            [
              -90.709953,
              39.172924
            ],
            [
              -90.71048,
              39.176366
            ],
            [
              -90.71437,
              39.185308
            ],
            [
              -90.717404,
              39.197414
            ],
            [
              -90.717427,
              39.202791
            ],
            [
              -90.716677,
              39.206723
            ],
            [
              -90.716597,
              39.210414
            ],
            [
              -90.717113,
              39.213912
            ],
            [
              -90.721835,
              39.224108
            ],
            [
              -91.182942,
              39.227233
            ],
            [
              -91.184197,
              39.179095
            ],
            [
              -91.18546,
              39.13905
            ],
            [
              -91.260232,
              39.139845
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "013",
      "COUNTYNS": "00758461",
      "AFFGEOID": "0500000US29013",
      "GEOID": "29013",
      "NAME": "Bates",
      "LSAD": "06",
      "ALAND": 2167013743,
      "AWATER": 38050433,
      "County_state": "Bates,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.614061,
              38.067343
            ],
            [
              -94.614089,
              38.065901
            ],
            [
              -94.614055,
              38.060088
            ],
            [
              -94.6140548972092,
              38.0600558584152
            ],
            [
              -94.240643,
              38.050821
            ],
            [
              -94.230543,
              38.045801
            ],
            [
              -94.240429,
              38.038018
            ],
            [
              -94.237787,
              38.026017
            ],
            [
              -94.224849,
              38.027337
            ],
            [
              -94.223889,
              38.040348
            ],
            [
              -94.214581,
              38.034998
            ],
            [
              -94.209544,
              38.052158
            ],
            [
              -94.195358,
              38.053688
            ],
            [
              -94.198597,
              38.036673
            ],
            [
              -94.189502,
              38.04548
            ],
            [
              -94.156122,
              38.057284
            ],
            [
              -94.135238,
              38.058118
            ],
            [
              -94.115478,
              38.048433
            ],
            [
              -94.109911,
              38.03732
            ],
            [
              -94.101912,
              38.048105
            ],
            [
              -94.082057,
              38.031581
            ],
            [
              -94.058285,
              38.036627
            ],
            [
              -94.049895,
              38.213985
            ],
            [
              -94.075214,
              38.214502
            ],
            [
              -94.067379,
              38.402937
            ],
            [
              -94.065713,
              38.447087
            ],
            [
              -94.08641,
              38.455934
            ],
            [
              -94.132773,
              38.453834
            ],
            [
              -94.174102,
              38.445452
            ],
            [
              -94.186674,
              38.449366
            ],
            [
              -94.212023,
              38.446754
            ],
            [
              -94.226302,
              38.459074
            ],
            [
              -94.257162,
              38.450072
            ],
            [
              -94.282738,
              38.461643
            ],
            [
              -94.29751,
              38.458879
            ],
            [
              -94.31217,
              38.471496
            ],
            [
              -94.426056,
              38.473851
            ],
            [
              -94.428027,
              38.473881
            ],
            [
              -94.6128650452802,
              38.477602354122006
            ],
            [
              -94.612866,
              38.477571
            ],
            [
              -94.6131579346,
              38.4341909225274
            ],
            [
              -94.613365,
              38.403422
            ],
            [
              -94.613265,
              38.392426
            ],
            [
              -94.613275403738,
              38.3887183678658
            ],
            [
              -94.613329,
              38.369618
            ],
            [
              -94.613312,
              38.364407
            ],
            [
              -94.613,
              38.335801
            ],
            [
              -94.612825,
              38.324387
            ],
            [
              -94.612788,
              38.320142
            ],
            [
              -94.612673,
              38.314832
            ],
            [
              -94.612673,
              38.302527
            ],
            [
              -94.612844,
              38.291423
            ],
            [
              -94.612849,
              38.289914
            ],
            [
              -94.612692,
              38.270394
            ],
            [
              -94.612614,
              38.237766
            ],
            [
              -94.612635,
              38.226987
            ],
            [
              -94.612659,
              38.219251
            ],
            [
              -94.612658,
              38.217649
            ],
            [
              -94.612822,
              38.203918
            ],
            [
              -94.612848,
              38.200714
            ],
            [
              -94.613073,
              38.190552
            ],
            [
              -94.613422,
              38.167908
            ],
            [
              -94.613748,
              38.160633
            ],
            [
              -94.613856,
              38.149769
            ],
            [
              -94.614061,
              38.067343
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "047",
      "COUNTYNS": "00758478",
      "AFFGEOID": "0500000US29047",
      "GEOID": "29047",
      "NAME": "Clay",
      "LSAD": "06",
      "ALAND": 1029939957,
      "AWATER": 28510134,
      "County_state": "Clay,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.607034,
              39.119404
            ],
            [
              -94.607354,
              39.113444
            ],
            [
              -94.597756,
              39.109763
            ],
            [
              -94.578825,
              39.117476
            ],
            [
              -94.566029,
              39.123151
            ],
            [
              -94.564724,
              39.12346
            ],
            [
              -94.549573,
              39.130895
            ],
            [
              -94.53572,
              39.144947
            ],
            [
              -94.520887,
              39.149219
            ],
            [
              -94.484616,
              39.145504
            ],
            [
              -94.46159,
              39.120372
            ],
            [
              -94.444292,
              39.129007
            ],
            [
              -94.401575,
              39.166823
            ],
            [
              -94.408628,
              39.193603
            ],
            [
              -94.390694,
              39.194013
            ],
            [
              -94.367481,
              39.163311
            ],
            [
              -94.335213,
              39.183337
            ],
            [
              -94.315074,
              39.219331
            ],
            [
              -94.290785,
              39.236915
            ],
            [
              -94.255424,
              39.236219
            ],
            [
              -94.212515,
              39.207086
            ],
            [
              -94.211337,
              39.395638
            ],
            [
              -94.21114,
              39.407248
            ],
            [
              -94.210642,
              39.454682
            ],
            [
              -94.463372,
              39.455429
            ],
            [
              -94.596507,
              39.455875
            ],
            [
              -94.600819,
              39.456155
            ],
            [
              -94.60081,
              39.370971
            ],
            [
              -94.600762,
              39.3333
            ],
            [
              -94.60038,
              39.289436
            ],
            [
              -94.600677,
              39.267546
            ],
            [
              -94.600966,
              39.246494
            ],
            [
              -94.601111,
              39.195857
            ],
            [
              -94.601466,
              39.179732
            ],
            [
              -94.601733,
              39.159603
            ],
            [
              -94.596033,
              39.157703
            ],
            [
              -94.591933,
              39.155003
            ],
            [
              -94.589933,
              39.140403
            ],
            [
              -94.592533,
              39.135903
            ],
            [
              -94.600434,
              39.128503
            ],
            [
              -94.605734,
              39.122204
            ],
            [
              -94.607034,
              39.119404
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "171",
      "COUNTYNS": "00758540",
      "AFFGEOID": "0500000US29171",
      "GEOID": "29171",
      "NAME": "Putnam",
      "LSAD": "06",
      "ALAND": 1339843851,
      "AWATER": 5943334,
      "County_state": "Putnam,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.3743860081406,
              40.5803343112705
            ],
            [
              -93.37484,
              40.472212
            ],
            [
              -93.366859,
              40.471518
            ],
            [
              -93.366935,
              40.382999
            ],
            [
              -92.855313,
              40.385894
            ],
            [
              -92.855629,
              40.342736
            ],
            [
              -92.686852,
              40.343427
            ],
            [
              -92.684167,
              40.343466
            ],
            [
              -92.686019,
              40.39427
            ],
            [
              -92.666175,
              40.397677
            ],
            [
              -92.667478,
              40.408258
            ],
            [
              -92.693564,
              40.422898
            ],
            [
              -92.70932,
              40.445893
            ],
            [
              -92.708665,
              40.460904
            ],
            [
              -92.696385,
              40.486581
            ],
            [
              -92.676032,
              40.49954
            ],
            [
              -92.684605,
              40.515197
            ],
            [
              -92.671382,
              40.519086
            ],
            [
              -92.699359,
              40.527896
            ],
            [
              -92.694953,
              40.548581
            ],
            [
              -92.683162,
              40.560663
            ],
            [
              -92.717545,
              40.558348
            ],
            [
              -92.692224,
              40.567528
            ],
            [
              -92.7145977730885,
              40.5895641799538
            ],
            [
              -92.742232,
              40.589207
            ],
            [
              -92.757407,
              40.588908
            ],
            [
              -92.828061,
              40.588593
            ],
            [
              -92.827992,
              40.588515
            ],
            [
              -92.835074,
              40.588484
            ],
            [
              -92.857391,
              40.58836
            ],
            [
              -92.863034,
              40.588175
            ],
            [
              -92.879178,
              40.588341
            ],
            [
              -92.889796,
              40.588039
            ],
            [
              -92.903544,
              40.58786
            ],
            [
              -92.941595,
              40.587743
            ],
            [
              -92.957747,
              40.58743
            ],
            [
              -93.085517,
              40.584403
            ],
            [
              -93.097296,
              40.584014
            ],
            [
              -93.098507,
              40.583973
            ],
            [
              -93.135802,
              40.582854
            ],
            [
              -93.260612,
              40.580797
            ],
            [
              -93.317605,
              40.580671
            ],
            [
              -93.345442,
              40.580514
            ],
            [
              -93.3743860081406,
              40.5803343112705
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "197",
      "COUNTYNS": "00758551",
      "AFFGEOID": "0500000US29197",
      "GEOID": "29197",
      "NAME": "Schuyler",
      "LSAD": "06",
      "ALAND": 795916406,
      "AWATER": 2216011,
      "County_state": "Schuyler,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.692224,
              40.567528
            ],
            [
              -92.717545,
              40.558348
            ],
            [
              -92.683162,
              40.560663
            ],
            [
              -92.694953,
              40.548581
            ],
            [
              -92.699359,
              40.527896
            ],
            [
              -92.671382,
              40.519086
            ],
            [
              -92.684605,
              40.515197
            ],
            [
              -92.676032,
              40.49954
            ],
            [
              -92.696385,
              40.486581
            ],
            [
              -92.708665,
              40.460904
            ],
            [
              -92.70932,
              40.445893
            ],
            [
              -92.693564,
              40.422898
            ],
            [
              -92.667478,
              40.408258
            ],
            [
              -92.666175,
              40.397677
            ],
            [
              -92.686019,
              40.39427
            ],
            [
              -92.684167,
              40.343466
            ],
            [
              -92.349975,
              40.346505
            ],
            [
              -92.35080671229349,
              40.597273187449304
            ],
            [
              -92.379691,
              40.596509
            ],
            [
              -92.453745,
              40.595288
            ],
            [
              -92.461609,
              40.595355
            ],
            [
              -92.481692,
              40.594941
            ],
            [
              -92.482394,
              40.594894
            ],
            [
              -92.484588,
              40.594924
            ],
            [
              -92.580278,
              40.592151
            ],
            [
              -92.637898,
              40.590853
            ],
            [
              -92.639223,
              40.590825
            ],
            [
              -92.686693,
              40.589809
            ],
            [
              -92.689854,
              40.589884
            ],
            [
              -92.7145977730885,
              40.5895641799538
            ],
            [
              -92.692224,
              40.567528
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "045",
      "COUNTYNS": "00758477",
      "AFFGEOID": "0500000US29045",
      "GEOID": "29045",
      "NAME": "Clark",
      "LSAD": "06",
      "ALAND": 1307092462,
      "AWATER": 18528245,
      "County_state": "Clark,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.949747,
              40.300832
            ],
            [
              -91.950812,
              40.25721
            ],
            [
              -91.4972633213575,
              40.248761318639396
            ],
            [
              -91.490524,
              40.259498
            ],
            [
              -91.489969,
              40.26234
            ],
            [
              -91.490525,
              40.264814
            ],
            [
              -91.492891,
              40.269923
            ],
            [
              -91.493061,
              40.275262
            ],
            [
              -91.492727,
              40.278217
            ],
            [
              -91.489868,
              40.286048
            ],
            [
              -91.486078,
              40.293426
            ],
            [
              -91.471826,
              40.31734
            ],
            [
              -91.469656,
              40.322409
            ],
            [
              -91.466603,
              40.334461
            ],
            [
              -91.46214,
              40.342414
            ],
            [
              -91.452237,
              40.35367
            ],
            [
              -91.447835,
              40.359129
            ],
            [
              -91.446308,
              40.361823
            ],
            [
              -91.444833,
              40.36317
            ],
            [
              -91.439342,
              40.366569
            ],
            [
              -91.429442,
              40.370386
            ],
            [
              -91.426632,
              40.371988
            ],
            [
              -91.419422,
              40.378264
            ],
            [
              -91.422324,
              40.380939
            ],
            [
              -91.425662,
              40.382491
            ],
            [
              -91.441243,
              40.386255
            ],
            [
              -91.465116,
              40.385257
            ],
            [
              -91.484507,
              40.3839
            ],
            [
              -91.488597,
              40.400009
            ],
            [
              -91.487955,
              40.402465
            ],
            [
              -91.487829,
              40.403866
            ],
            [
              -91.488481,
              40.404317
            ],
            [
              -91.489816,
              40.404317
            ],
            [
              -91.493644,
              40.402433
            ],
            [
              -91.498093,
              40.401926
            ],
            [
              -91.505272,
              40.403512
            ],
            [
              -91.506745,
              40.404335
            ],
            [
              -91.507427,
              40.405524
            ],
            [
              -91.509063,
              40.406775
            ],
            [
              -91.513993,
              40.408537
            ],
            [
              -91.518392,
              40.408682
            ],
            [
              -91.522333,
              40.409648
            ],
            [
              -91.524612,
              40.410765
            ],
            [
              -91.526425,
              40.413404
            ],
            [
              -91.527057,
              40.416689
            ],
            [
              -91.526555,
              40.419872
            ],
            [
              -91.521388,
              40.426488
            ],
            [
              -91.519012,
              40.431298
            ],
            [
              -91.519134,
              40.432822
            ],
            [
              -91.519935,
              40.433673
            ],
            [
              -91.525,
              40.433483
            ],
            [
              -91.529132,
              40.434272
            ],
            [
              -91.532807,
              40.436784
            ],
            [
              -91.533623,
              40.43832
            ],
            [
              -91.533548,
              40.440804
            ],
            [
              -91.531912,
              40.44273
            ],
            [
              -91.526108,
              40.446634
            ],
            [
              -91.524053,
              40.448437
            ],
            [
              -91.523271,
              40.450061
            ],
            [
              -91.523072,
              40.452254
            ],
            [
              -91.523864,
              40.456331
            ],
            [
              -91.52509,
              40.457845
            ],
            [
              -91.526155,
              40.458625
            ],
            [
              -91.5286,
              40.459002
            ],
            [
              -91.543785,
              40.458149
            ],
            [
              -91.552691,
              40.458769
            ],
            [
              -91.563844,
              40.460988
            ],
            [
              -91.567743,
              40.46229
            ],
            [
              -91.574746,
              40.465664
            ],
            [
              -91.580355,
              40.471015
            ],
            [
              -91.581528,
              40.472876
            ],
            [
              -91.582437,
              40.474703
            ],
            [
              -91.583315,
              40.479118
            ],
            [
              -91.586884,
              40.487233
            ],
            [
              -91.590817,
              40.492292
            ],
            [
              -91.594644,
              40.494997
            ],
            [
              -91.608347,
              40.50004
            ],
            [
              -91.612821,
              40.502377
            ],
            [
              -91.616948,
              40.504794
            ],
            [
              -91.619486,
              40.507134
            ],
            [
              -91.621353,
              40.510072
            ],
            [
              -91.622362,
              40.514362
            ],
            [
              -91.622196,
              40.51704
            ],
            [
              -91.618793,
              40.526286
            ],
            [
              -91.618028,
              40.53403
            ],
            [
              -91.618999,
              40.539084
            ],
            [
              -91.620071,
              40.540817
            ],
            [
              -91.6219,
              40.542292
            ],
            [
              -91.625161,
              40.5435
            ],
            [
              -91.638082,
              40.545541
            ],
            [
              -91.654345,
              40.549189
            ],
            [
              -91.670993,
              40.550937
            ],
            [
              -91.681714,
              40.553035
            ],
            [
              -91.6887,
              40.55739
            ],
            [
              -91.690804,
              40.559893
            ],
            [
              -91.691591,
              40.562222
            ],
            [
              -91.691557,
              40.564867
            ],
            [
              -91.685723,
              40.576785
            ],
            [
              -91.685381,
              40.578892
            ],
            [
              -91.686357,
              40.580875
            ],
            [
              -91.68882,
              40.583409
            ],
            [
              -91.696359,
              40.588148
            ],
            [
              -91.712025,
              40.595046
            ],
            [
              -91.716769,
              40.59853
            ],
            [
              -91.720058,
              40.601527
            ],
            [
              -91.729115,
              40.61364
            ],
            [
              -91.785916,
              40.611488
            ],
            [
              -91.795374,
              40.611101
            ],
            [
              -91.800133,
              40.610953
            ],
            [
              -91.813968,
              40.610526
            ],
            [
              -91.824826,
              40.610191
            ],
            [
              -91.832481,
              40.609797
            ],
            [
              -91.868401,
              40.608059
            ],
            [
              -91.939292,
              40.60615
            ],
            [
              -91.94311318663401,
              40.60584170797
            ],
            [
              -91.949747,
              40.300832
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "077",
      "COUNTYNS": "00758493",
      "AFFGEOID": "0500000US29077",
      "GEOID": "29077",
      "NAME": "Greene",
      "LSAD": "06",
      "ALAND": 1749069420,
      "AWATER": 6631777,
      "County_state": "Greene,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.621153,
              37.427423
            ],
            [
              -93.625844,
              37.282011
            ],
            [
              -93.605095,
              37.281482
            ],
            [
              -93.608899,
              37.098153
            ],
            [
              -93.46292,
              37.09637
            ],
            [
              -93.3896,
              37.09549
            ],
            [
              -93.325301,
              37.094652
            ],
            [
              -93.297659,
              37.094451
            ],
            [
              -93.263985,
              37.093751
            ],
            [
              -93.173173,
              37.090885
            ],
            [
              -93.065274,
              37.088694
            ],
            [
              -93.062142,
              37.235117
            ],
            [
              -93.061541,
              37.269832
            ],
            [
              -93.076779,
              37.27004
            ],
            [
              -93.07338,
              37.414989
            ],
            [
              -93.182489,
              37.417204
            ],
            [
              -93.251045,
              37.418861
            ],
            [
              -93.43723,
              37.423554
            ],
            [
              -93.621153,
              37.427423
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "135",
      "COUNTYNS": "00758522",
      "AFFGEOID": "0500000US29135",
      "GEOID": "29135",
      "NAME": "Moniteau",
      "LSAD": "06",
      "ALAND": 1074911084,
      "AWATER": 9960533,
      "County_state": "Moniteau,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.839837,
              38.683974
            ],
            [
              -92.841871,
              38.596792
            ],
            [
              -92.625189,
              38.430778
            ],
            [
              -92.531857,
              38.428548
            ],
            [
              -92.495185,
              38.427829
            ],
            [
              -92.430744,
              38.627109
            ],
            [
              -92.430657,
              38.627382
            ],
            [
              -92.394866,
              38.73913
            ],
            [
              -92.409409,
              38.764277
            ],
            [
              -92.38298,
              38.789725
            ],
            [
              -92.381304,
              38.808732
            ],
            [
              -92.397237,
              38.81983
            ],
            [
              -92.416872,
              38.818869
            ],
            [
              -92.444856,
              38.838344
            ],
            [
              -92.456717,
              38.860269
            ],
            [
              -92.477794,
              38.874107
            ],
            [
              -92.474259,
              38.901596
            ],
            [
              -92.479225,
              38.907879
            ],
            [
              -92.498377,
              38.922084
            ],
            [
              -92.513043,
              38.89559
            ],
            [
              -92.616652,
              38.694466
            ],
            [
              -92.616842,
              38.687949
            ],
            [
              -92.617147,
              38.680013
            ],
            [
              -92.726692,
              38.684077
            ],
            [
              -92.834172,
              38.684834
            ],
            [
              -92.839837,
              38.683974
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "081",
      "COUNTYNS": "00758495",
      "AFFGEOID": "0500000US29081",
      "GEOID": "29081",
      "NAME": "Harrison",
      "LSAD": "06",
      "ALAND": 1871276777,
      "AWATER": 10103762,
      "County_state": "Harrison,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.23224004467359,
              40.5719073719895
            ],
            [
              -94.231565,
              40.470357
            ],
            [
              -94.214619,
              40.470464
            ],
            [
              -94.214278,
              40.384254
            ],
            [
              -94.216696,
              40.136626
            ],
            [
              -93.764021,
              40.132909
            ],
            [
              -93.763324,
              40.263988
            ],
            [
              -93.764823,
              40.472515
            ],
            [
              -93.774548,
              40.47235
            ],
            [
              -93.7743442708352,
              40.5775843690664
            ],
            [
              -93.815485,
              40.577278
            ],
            [
              -93.818725,
              40.577086
            ],
            [
              -93.84093,
              40.576791
            ],
            [
              -93.853656,
              40.576606
            ],
            [
              -93.898327,
              40.576011
            ],
            [
              -93.899317,
              40.575942
            ],
            [
              -93.900877,
              40.575874
            ],
            [
              -93.913961,
              40.575672
            ],
            [
              -93.935687,
              40.57533
            ],
            [
              -93.936317,
              40.575284
            ],
            [
              -93.937097,
              40.575421
            ],
            [
              -93.938627,
              40.575284
            ],
            [
              -93.939857,
              40.575192
            ],
            [
              -93.963863,
              40.574754
            ],
            [
              -93.976766,
              40.574635
            ],
            [
              -94.015492,
              40.573914
            ],
            [
              -94.034134,
              40.573585
            ],
            [
              -94.080223,
              40.572899
            ],
            [
              -94.080463,
              40.572899
            ],
            [
              -94.089194,
              40.572806
            ],
            [
              -94.091085,
              40.572897
            ],
            [
              -94.23224004467359,
              40.5719073719895
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "055",
      "COUNTYNS": "00758482",
      "AFFGEOID": "0500000US29055",
      "GEOID": "29055",
      "NAME": "Crawford",
      "LSAD": "06",
      "ALAND": 1923108653,
      "AWATER": 3203556,
      "County_state": "Crawford,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.535115,
              38.210976
            ],
            [
              -91.534384,
              38.152593
            ],
            [
              -91.525025,
              38.152433
            ],
            [
              -91.528797,
              37.788989
            ],
            [
              -91.308311,
              37.787186
            ],
            [
              -91.310655,
              37.700048
            ],
            [
              -91.153345,
              37.69734
            ],
            [
              -91.146521,
              37.740811
            ],
            [
              -91.100017,
              37.740012
            ],
            [
              -91.096354,
              37.870838
            ],
            [
              -91.094779,
              37.957889
            ],
            [
              -91.095765,
              38.204083
            ],
            [
              -91.36763,
              38.204013
            ],
            [
              -91.367527,
              38.208022
            ],
            [
              -91.367482,
              38.209741
            ],
            [
              -91.535115,
              38.210976
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "091",
      "COUNTYNS": "00758500",
      "AFFGEOID": "0500000US29091",
      "GEOID": "29091",
      "NAME": "Howell",
      "LSAD": "06",
      "ALAND": 2401562307,
      "AWATER": 2913502,
      "County_state": "Howell,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.1204149758124,
              36.498863012436004
            ],
            [
              -92.120306,
              36.498864
            ],
            [
              -92.098356,
              36.498803
            ],
            [
              -92.074934,
              36.498761
            ],
            [
              -92.057178,
              36.49867
            ],
            [
              -92.055789,
              36.49867
            ],
            [
              -92.028847,
              36.498642
            ],
            [
              -92.019375,
              36.498524
            ],
            [
              -91.988751,
              36.498498
            ],
            [
              -91.985802,
              36.498431
            ],
            [
              -91.865995,
              36.498783
            ],
            [
              -91.864385,
              36.498789
            ],
            [
              -91.805981,
              36.498987
            ],
            [
              -91.80204,
              36.498963
            ],
            [
              -91.7995,
              36.498952
            ],
            [
              -91.784713,
              36.499074
            ],
            [
              -91.766111,
              36.499114
            ],
            [
              -91.726663,
              36.499209
            ],
            [
              -91.687615,
              36.499397
            ],
            [
              -91.686026,
              36.499374
            ],
            [
              -91.672343,
              36.499463
            ],
            [
              -91.672018,
              36.606214
            ],
            [
              -91.678333,
              36.62506
            ],
            [
              -91.674237,
              36.787832
            ],
            [
              -91.660017,
              36.787531
            ],
            [
              -91.658111,
              36.888724
            ],
            [
              -91.656339,
              36.960627
            ],
            [
              -91.655607,
              37.048925
            ],
            [
              -91.79944,
              37.05002
            ],
            [
              -91.980733,
              37.055638
            ],
            [
              -92.088708,
              37.056248
            ],
            [
              -92.095982,
              36.794011
            ],
            [
              -92.112346,
              36.794231
            ],
            [
              -92.1204149758124,
              36.498863012436004
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "037",
      "COUNTYNS": "00758473",
      "AFFGEOID": "0500000US29037",
      "GEOID": "29037",
      "NAME": "Cass",
      "LSAD": "06",
      "ALAND": 1804142327,
      "AWATER": 15044253,
      "County_state": "Cass,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.612696,
              38.483154
            ],
            [
              -94.6128650452802,
              38.477602354122006
            ],
            [
              -94.428027,
              38.473881
            ],
            [
              -94.426056,
              38.473851
            ],
            [
              -94.31217,
              38.471496
            ],
            [
              -94.29751,
              38.458879
            ],
            [
              -94.282738,
              38.461643
            ],
            [
              -94.257162,
              38.450072
            ],
            [
              -94.226302,
              38.459074
            ],
            [
              -94.212023,
              38.446754
            ],
            [
              -94.186674,
              38.449366
            ],
            [
              -94.174102,
              38.445452
            ],
            [
              -94.132773,
              38.453834
            ],
            [
              -94.08641,
              38.455934
            ],
            [
              -94.065713,
              38.447087
            ],
            [
              -94.06782,
              38.466016
            ],
            [
              -94.064317,
              38.56738
            ],
            [
              -94.119664,
              38.568712
            ],
            [
              -94.117961,
              38.612267
            ],
            [
              -94.116408,
              38.655518
            ],
            [
              -94.129372,
              38.655862
            ],
            [
              -94.119096,
              38.833167
            ],
            [
              -94.267951,
              38.835037
            ],
            [
              -94.272497,
              38.835044
            ],
            [
              -94.376002,
              38.838005
            ],
            [
              -94.399447,
              38.838809
            ],
            [
              -94.490031,
              38.841713
            ],
            [
              -94.530087,
              38.843678
            ],
            [
              -94.608033,
              38.847207
            ],
            [
              -94.607625,
              38.82756
            ],
            [
              -94.60767342128659,
              38.825639909751196
            ],
            [
              -94.608041,
              38.811064
            ],
            [
              -94.609399,
              38.74244
            ],
            [
              -94.609456,
              38.7407
            ],
            [
              -94.60950894217369,
              38.7381018369191
            ],
            [
              -94.611602,
              38.635384
            ],
            [
              -94.611465,
              38.625011
            ],
            [
              -94.611858,
              38.620485
            ],
            [
              -94.611908,
              38.609272
            ],
            [
              -94.611887,
              38.580139
            ],
            [
              -94.611902,
              38.58011
            ],
            [
              -94.612176,
              38.576546
            ],
            [
              -94.612157,
              38.549817
            ],
            [
              -94.612272,
              38.547917
            ],
            [
              -94.6122740486503,
              38.547607097579096
            ],
            [
              -94.6124647571677,
              38.5187583330626
            ],
            [
              -94.612644,
              38.491644
            ],
            [
              -94.612726,
              38.484367
            ],
            [
              -94.612696,
              38.483154
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "179",
      "COUNTYNS": "00758544",
      "AFFGEOID": "0500000US29179",
      "GEOID": "29179",
      "NAME": "Reynolds",
      "LSAD": "06",
      "ALAND": 2093947210,
      "AWATER": 15372862,
      "County_state": "Reynolds,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.312458,
              37.592824
            ],
            [
              -91.314236,
              37.505132
            ],
            [
              -91.210984,
              37.501911
            ],
            [
              -91.211863,
              37.415277
            ],
            [
              -91.175405,
              37.414805
            ],
            [
              -91.176068,
              37.341704
            ],
            [
              -91.176158,
              37.312592
            ],
            [
              -91.158429,
              37.312917
            ],
            [
              -91.159323,
              37.254936
            ],
            [
              -91.123152,
              37.254504
            ],
            [
              -91.126028,
              37.19948
            ],
            [
              -91.08826,
              37.203193
            ],
            [
              -91.088865,
              37.174179
            ],
            [
              -91.070634,
              37.177573
            ],
            [
              -91.071046,
              37.162712
            ],
            [
              -91.034346,
              37.169343
            ],
            [
              -91.035138,
              37.140181
            ],
            [
              -91.017163,
              37.143478
            ],
            [
              -91.017766,
              37.095677
            ],
            [
              -90.99989,
              37.100404
            ],
            [
              -90.963562,
              37.09736
            ],
            [
              -90.964466,
              37.053584
            ],
            [
              -90.779553,
              37.050324
            ],
            [
              -90.779025,
              37.13792
            ],
            [
              -90.756799,
              37.137739
            ],
            [
              -90.75587,
              37.166722
            ],
            [
              -90.737146,
              37.166413
            ],
            [
              -90.734438,
              37.227146
            ],
            [
              -90.735453,
              37.27104
            ],
            [
              -90.753627,
              37.270113
            ],
            [
              -90.74972,
              37.372796
            ],
            [
              -90.772949,
              37.373659
            ],
            [
              -90.768695,
              37.603011
            ],
            [
              -90.790947,
              37.602118
            ],
            [
              -90.987055,
              37.603852
            ],
            [
              -91.100665,
              37.587762
            ],
            [
              -91.136788,
              37.588144
            ],
            [
              -91.155073,
              37.588092
            ],
            [
              -91.209437,
              37.588811
            ],
            [
              -91.312458,
              37.592824
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "227",
      "COUNTYNS": "00758566",
      "AFFGEOID": "0500000US29227",
      "GEOID": "29227",
      "NAME": "Worth",
      "LSAD": "06",
      "ALAND": 690500346,
      "AWATER": 558541,
      "County_state": "Worth,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.632032,
              40.571186
            ],
            [
              -94.632035122503,
              40.571186037110195
            ],
            [
              -94.631889,
              40.472513
            ],
            [
              -94.608409,
              40.472443
            ],
            [
              -94.608415,
              40.386687
            ],
            [
              -94.598487,
              40.386714
            ],
            [
              -94.214278,
              40.384254
            ],
            [
              -94.214619,
              40.470464
            ],
            [
              -94.231565,
              40.470357
            ],
            [
              -94.23224004467359,
              40.5719073719895
            ],
            [
              -94.28735,
              40.571521
            ],
            [
              -94.294813,
              40.571341
            ],
            [
              -94.310724,
              40.571524
            ],
            [
              -94.324765,
              40.571477
            ],
            [
              -94.336556,
              40.571475
            ],
            [
              -94.336706,
              40.571452
            ],
            [
              -94.358307,
              40.571363
            ],
            [
              -94.429725,
              40.571041
            ],
            [
              -94.460088,
              40.570947
            ],
            [
              -94.470648,
              40.57083
            ],
            [
              -94.471213,
              40.570825
            ],
            [
              -94.48928,
              40.570707
            ],
            [
              -94.533878,
              40.570739
            ],
            [
              -94.537058,
              40.570763
            ],
            [
              -94.538318,
              40.570763
            ],
            [
              -94.541828,
              40.570809
            ],
            [
              -94.542154,
              40.570809
            ],
            [
              -94.594001,
              40.570966
            ],
            [
              -94.632032,
              40.571186
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "175",
      "COUNTYNS": "00758542",
      "AFFGEOID": "0500000US29175",
      "GEOID": "29175",
      "NAME": "Randolph",
      "LSAD": "06",
      "ALAND": 1250217889,
      "AWATER": 13254193,
      "County_state": "Randolph,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.703937,
              39.408078
            ],
            [
              -92.707367,
              39.321614
            ],
            [
              -92.568533,
              39.285326
            ],
            [
              -92.55953,
              39.28297
            ],
            [
              -92.430229,
              39.248795
            ],
            [
              -92.372492,
              39.247724
            ],
            [
              -92.370556,
              39.247688
            ],
            [
              -92.314471,
              39.246454
            ],
            [
              -92.310538,
              39.333905
            ],
            [
              -92.310028,
              39.347795
            ],
            [
              -92.308284,
              39.392089
            ],
            [
              -92.300871,
              39.605348
            ],
            [
              -92.692149,
              39.610265
            ],
            [
              -92.696673,
              39.60871
            ],
            [
              -92.703669,
              39.41469
            ],
            [
              -92.703937,
              39.408078
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "147",
      "COUNTYNS": "00758528",
      "AFFGEOID": "0500000US29147",
      "GEOID": "29147",
      "NAME": "Nodaway",
      "LSAD": "06",
      "ALAND": 2271326597,
      "AWATER": 2361588,
      "County_state": "Nodaway,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.2022644684083,
              40.578527764403404
            ],
            [
              -95.202017,
              40.479135
            ],
            [
              -95.177708,
              40.478903
            ],
            [
              -95.180614,
              40.261706
            ],
            [
              -95.071703,
              40.260859
            ],
            [
              -95.064972,
              40.246589
            ],
            [
              -95.063849,
              40.205863
            ],
            [
              -95.073527,
              40.195537
            ],
            [
              -95.060645,
              40.17173
            ],
            [
              -95.065662,
              40.15097
            ],
            [
              -95.04308,
              40.130096
            ],
            [
              -94.605942,
              40.126127
            ],
            [
              -94.598487,
              40.386714
            ],
            [
              -94.608415,
              40.386687
            ],
            [
              -94.608409,
              40.472443
            ],
            [
              -94.631889,
              40.472513
            ],
            [
              -94.632035122503,
              40.571186037110195
            ],
            [
              -94.682601,
              40.571787
            ],
            [
              -94.714925,
              40.572201
            ],
            [
              -94.716665,
              40.572201
            ],
            [
              -94.773988,
              40.572977
            ],
            [
              -94.811188,
              40.573532
            ],
            [
              -94.819978,
              40.573714
            ],
            [
              -94.823758,
              40.573942
            ],
            [
              -94.896801,
              40.574738
            ],
            [
              -94.901451,
              40.574877
            ],
            [
              -94.914896,
              40.575068
            ],
            [
              -94.955134,
              40.575669
            ],
            [
              -94.966491,
              40.575839
            ],
            [
              -94.991661,
              40.575692
            ],
            [
              -95.068921,
              40.57688
            ],
            [
              -95.079742,
              40.577007
            ],
            [
              -95.097607,
              40.577168
            ],
            [
              -95.107213,
              40.577116
            ],
            [
              -95.110303,
              40.57716
            ],
            [
              -95.110663,
              40.577206
            ],
            [
              -95.112222,
              40.577228
            ],
            [
              -95.120829,
              40.577413
            ],
            [
              -95.154499,
              40.57786
            ],
            [
              -95.164058,
              40.578017
            ],
            [
              -95.2022644684083,
              40.578527764403404
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "189",
      "COUNTYNS": "00758549",
      "AFFGEOID": "0500000US29189",
      "GEOID": "29189",
      "NAME": "St. Louis",
      "LSAD": "06",
      "ALAND": 1314771903,
      "AWATER": 39737787,
      "County_state": "St. Louis,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.73614,
              38.477114
            ],
            [
              -90.736311,
              38.466643
            ],
            [
              -90.73203,
              38.482153
            ],
            [
              -90.696027,
              38.471252
            ],
            [
              -90.685958,
              38.448824
            ],
            [
              -90.678775,
              38.445877
            ],
            [
              -90.654389,
              38.457156
            ],
            [
              -90.660703,
              38.479195
            ],
            [
              -90.653829,
              38.487547
            ],
            [
              -90.633623,
              38.485752
            ],
            [
              -90.613685,
              38.471328
            ],
            [
              -90.602652,
              38.479617
            ],
            [
              -90.591123,
              38.501652
            ],
            [
              -90.51494,
              38.501455
            ],
            [
              -90.512522,
              38.501454
            ],
            [
              -90.49139,
              38.501187
            ],
            [
              -90.466184,
              38.50181
            ],
            [
              -90.405262,
              38.501153
            ],
            [
              -90.414513,
              38.480821
            ],
            [
              -90.422231,
              38.483359
            ],
            [
              -90.414408,
              38.456971
            ],
            [
              -90.376783,
              38.452868
            ],
            [
              -90.336443,
              38.453978
            ],
            [
              -90.347117,
              38.435953
            ],
            [
              -90.348145,
              38.406898
            ],
            [
              -90.3407573658894,
              38.387506287665495
            ],
            [
              -90.328517,
              38.398153
            ],
            [
              -90.322317,
              38.401753
            ],
            [
              -90.295316,
              38.426753
            ],
            [
              -90.288815,
              38.438453
            ],
            [
              -90.285215,
              38.443453
            ],
            [
              -90.284015,
              38.451053
            ],
            [
              -90.280587257131,
              38.4663350202911
            ],
            [
              -90.279215,
              38.472453
            ],
            [
              -90.275915,
              38.479553
            ],
            [
              -90.274914,
              38.486253
            ],
            [
              -90.271314,
              38.496052
            ],
            [
              -90.269989207833,
              38.5014041603547
            ],
            [
              -90.268814,
              38.506152
            ],
            [
              -90.263814,
              38.520552
            ],
            [
              -90.2637229314809,
              38.5207549526996
            ],
            [
              -90.260314,
              38.528352
            ],
            [
              -90.2577725855853,
              38.53200774918
            ],
            [
              -90.269146,
              38.537535
            ],
            [
              -90.272348,
              38.548387
            ],
            [
              -90.280257,
              38.552002
            ],
            [
              -90.280563,
              38.552187
            ],
            [
              -90.297022,
              38.562368
            ],
            [
              -90.310018,
              38.575231
            ],
            [
              -90.31624,
              38.584686
            ],
            [
              -90.31858,
              38.599092
            ],
            [
              -90.318212,
              38.600017
            ],
            [
              -90.313365,
              38.612349
            ],
            [
              -90.310184,
              38.62311
            ],
            [
              -90.309398,
              38.626972
            ],
            [
              -90.305324,
              38.639656
            ],
            [
              -90.301828,
              38.655642
            ],
            [
              -90.284394,
              38.677727
            ],
            [
              -90.28144,
              38.681434
            ],
            [
              -90.265026,
              38.702171
            ],
            [
              -90.252266,
              38.718219
            ],
            [
              -90.247332,
              38.720619
            ],
            [
              -90.240614,
              38.723257
            ],
            [
              -90.207946,
              38.739739
            ],
            [
              -90.186265,
              38.761354
            ],
            [
              -90.184196,
              38.774431
            ],
            [
              -90.16657184776219,
              38.77244627115319
            ],
            [
              -90.166409,
              38.772649
            ],
            [
              -90.146708,
              38.783049
            ],
            [
              -90.123107,
              38.798048
            ],
            [
              -90.117707,
              38.805748
            ],
            [
              -90.140641,
              38.829055
            ],
            [
              -90.183058,
              38.818053
            ],
            [
              -90.236557,
              38.843272
            ],
            [
              -90.256136,
              38.852128
            ],
            [
              -90.298743,
              38.887759
            ],
            [
              -90.320213,
              38.891148
            ],
            [
              -90.332022,
              38.886516
            ],
            [
              -90.342414,
              38.861749
            ],
            [
              -90.362163,
              38.82786
            ],
            [
              -90.393111,
              38.823105
            ],
            [
              -90.430975,
              38.829806
            ],
            [
              -90.440924,
              38.827421
            ],
            [
              -90.473188,
              38.790978
            ],
            [
              -90.475574,
              38.783016
            ],
            [
              -90.482299,
              38.764348
            ],
            [
              -90.492149,
              38.751793
            ],
            [
              -90.53134,
              38.731178
            ],
            [
              -90.530363,
              38.69863
            ],
            [
              -90.539633,
              38.686566
            ],
            [
              -90.548659,
              38.682755
            ],
            [
              -90.571822,
              38.681052
            ],
            [
              -90.609991,
              38.68345
            ],
            [
              -90.636197,
              38.690855
            ],
            [
              -90.656757,
              38.689223
            ],
            [
              -90.662749,
              38.687439
            ],
            [
              -90.678678,
              38.666346
            ],
            [
              -90.703831,
              38.656525
            ],
            [
              -90.708809,
              38.645782
            ],
            [
              -90.732328,
              38.639302
            ],
            [
              -90.732231,
              38.62137
            ],
            [
              -90.73388,
              38.548944
            ],
            [
              -90.73614,
              38.477114
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "069",
      "COUNTYNS": "00758489",
      "AFFGEOID": "0500000US29069",
      "GEOID": "29069",
      "NAME": "Dunklin",
      "LSAD": "06",
      "ALAND": 1401432378,
      "AWATER": 15767988,
      "County_state": "Dunklin,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.37789,
              35.995683
            ],
            [
              -90.368718,
              35.995812
            ],
            [
              -90.342616,
              35.995895
            ],
            [
              -90.339434,
              35.996033
            ],
            [
              -90.292376,
              35.996397
            ],
            [
              -90.2889470498035,
              35.9964180953312
            ],
            [
              -90.2888,
              35.996419
            ],
            [
              -90.158812,
              35.997375
            ],
            [
              -90.127331,
              35.997635
            ],
            [
              -90.12635,
              35.997596
            ],
            [
              -90.103842,
              35.998143
            ],
            [
              -89.972563,
              35.998994
            ],
            [
              -89.965327,
              35.998813
            ],
            [
              -89.961075,
              35.999135
            ],
            [
              -89.959893,
              35.99902
            ],
            [
              -89.959377,
              35.99902
            ],
            [
              -89.9593753420007,
              35.9990200098294
            ],
            [
              -89.960121,
              36.190824
            ],
            [
              -89.96131,
              36.388803
            ],
            [
              -89.972478,
              36.389597
            ],
            [
              -89.957425,
              36.460158
            ],
            [
              -89.958839,
              36.505344
            ],
            [
              -89.95387,
              36.545644
            ],
            [
              -89.963284,
              36.625471
            ],
            [
              -89.958786,
              36.629331
            ],
            [
              -90.137928,
              36.631212
            ],
            [
              -90.148398,
              36.630656
            ],
            [
              -90.156044,
              36.617174
            ],
            [
              -90.168559,
              36.614975
            ],
            [
              -90.175915,
              36.592736
            ],
            [
              -90.181673,
              36.596704
            ],
            [
              -90.187018,
              36.587137
            ],
            [
              -90.182802,
              36.561768
            ],
            [
              -90.188746,
              36.544624
            ],
            [
              -90.209954,
              36.542034
            ],
            [
              -90.209458,
              36.530121
            ],
            [
              -90.219942,
              36.522033
            ],
            [
              -90.2207319861011,
              36.497857683437594
            ],
            [
              -90.220702,
              36.497858
            ],
            [
              -90.217323,
              36.497797
            ],
            [
              -90.193943,
              36.497823
            ],
            [
              -90.152481,
              36.497952
            ],
            [
              -90.154409,
              36.496832
            ],
            [
              -90.153871,
              36.495344
            ],
            [
              -90.160167,
              36.481344
            ],
            [
              -90.1593750959925,
              36.4809366769308
            ],
            [
              -90.142269,
              36.472138
            ],
            [
              -90.141399,
              36.459874
            ],
            [
              -90.134136,
              36.436602
            ],
            [
              -90.143743,
              36.424433
            ],
            [
              -90.131038,
              36.415069
            ],
            [
              -90.109495,
              36.404073
            ],
            [
              -90.080426,
              36.400763
            ],
            [
              -90.066136,
              36.386272
            ],
            [
              -90.063526,
              36.356911
            ],
            [
              -90.077401,
              36.341003
            ],
            [
              -90.079981,
              36.318619
            ],
            [
              -90.06398,
              36.303038
            ],
            [
              -90.078807,
              36.288547
            ],
            [
              -90.083731,
              36.272332
            ],
            [
              -90.114922,
              36.265595
            ],
            [
              -90.125958,
              36.243416
            ],
            [
              -90.126366,
              36.229367
            ],
            [
              -90.14224,
              36.227522
            ],
            [
              -90.155928,
              36.214074
            ],
            [
              -90.182853,
              36.205108
            ],
            [
              -90.1894381168169,
              36.2003064993461
            ],
            [
              -90.200582,
              36.192181
            ],
            [
              -90.209776,
              36.182004
            ],
            [
              -90.220425,
              36.184764
            ],
            [
              -90.23537,
              36.159153
            ],
            [
              -90.235585,
              36.139474
            ],
            [
              -90.255596,
              36.127086
            ],
            [
              -90.278724,
              36.117406
            ],
            [
              -90.294492,
              36.112949
            ],
            [
              -90.29991,
              36.098236
            ],
            [
              -90.319168,
              36.089976
            ],
            [
              -90.318491,
              36.075514
            ],
            [
              -90.333261,
              36.067504
            ],
            [
              -90.339343,
              36.047112
            ],
            [
              -90.35131,
              36.02688
            ],
            [
              -90.37789,
              35.995683
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "137",
      "COUNTYNS": "00758523",
      "AFFGEOID": "0500000US29137",
      "GEOID": "29137",
      "NAME": "Monroe",
      "LSAD": "06",
      "ALAND": 1677414413,
      "AWATER": 58300656,
      "County_state": "Monroe,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.308284,
              39.392089
            ],
            [
              -92.310028,
              39.347795
            ],
            [
              -92.088295,
              39.34068
            ],
            [
              -91.717968,
              39.339122
            ],
            [
              -91.711934,
              39.599095
            ],
            [
              -91.716029,
              39.613696
            ],
            [
              -91.715247,
              39.657644
            ],
            [
              -91.846743,
              39.658513
            ],
            [
              -92.184986,
              39.662932
            ],
            [
              -92.186529,
              39.608104
            ],
            [
              -92.19293,
              39.604207
            ],
            [
              -92.299316,
              39.605344
            ],
            [
              -92.300871,
              39.605348
            ],
            [
              -92.308284,
              39.392089
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "141",
      "COUNTYNS": "00758525",
      "AFFGEOID": "0500000US29141",
      "GEOID": "29141",
      "NAME": "Morgan",
      "LSAD": "06",
      "ALAND": 1547854690,
      "AWATER": 42426936,
      "County_state": "Morgan,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.075704,
              38.304768
            ],
            [
              -93.077692,
              38.263092
            ],
            [
              -93.056666,
              38.271737
            ],
            [
              -93.042475,
              38.26518
            ],
            [
              -93.013743,
              38.241464
            ],
            [
              -93.00565,
              38.222705
            ],
            [
              -92.986195,
              38.220828
            ],
            [
              -92.988294,
              38.210428
            ],
            [
              -93.01204,
              38.210224
            ],
            [
              -93.022277,
              38.198642
            ],
            [
              -93.01499,
              38.189775
            ],
            [
              -92.989903,
              38.188375
            ],
            [
              -92.979558,
              38.197734
            ],
            [
              -92.960592,
              38.228358
            ],
            [
              -92.931993,
              38.220229
            ],
            [
              -92.934693,
              38.211129
            ],
            [
              -92.962994,
              38.197229
            ],
            [
              -92.80696,
              38.191157
            ],
            [
              -92.764539,
              38.189619
            ],
            [
              -92.755988,
              38.212465
            ],
            [
              -92.731405,
              38.221588
            ],
            [
              -92.695886,
              38.222031
            ],
            [
              -92.689834,
              38.348472
            ],
            [
              -92.633756,
              38.348082
            ],
            [
              -92.63019,
              38.430958
            ],
            [
              -92.625189,
              38.430778
            ],
            [
              -92.841871,
              38.596792
            ],
            [
              -92.839837,
              38.683974
            ],
            [
              -92.950512,
              38.68577
            ],
            [
              -93.059974,
              38.693077
            ],
            [
              -93.063452,
              38.620832
            ],
            [
              -93.067291,
              38.529995
            ],
            [
              -93.072054,
              38.442274
            ],
            [
              -93.075704,
              38.304768
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "143",
      "COUNTYNS": "00758526",
      "AFFGEOID": "0500000US29143",
      "GEOID": "29143",
      "NAME": "New Madrid",
      "LSAD": "06",
      "ALAND": 1747828876,
      "AWATER": 56291575,
      "County_state": "New Madrid,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.972478,
              36.389597
            ],
            [
              -89.96131,
              36.388803
            ],
            [
              -89.913426,
              36.38645
            ],
            [
              -89.752147,
              36.386396
            ],
            [
              -89.745168,
              36.41842
            ],
            [
              -89.731883,
              36.427636
            ],
            [
              -89.695795,
              36.41639
            ],
            [
              -89.676984,
              36.400998
            ],
            [
              -89.639738,
              36.400788
            ],
            [
              -89.611016,
              36.411212
            ],
            [
              -89.5923,
              36.403805
            ],
            [
              -89.583722,
              36.383028
            ],
            [
              -89.563218,
              36.367031
            ],
            [
              -89.545085,
              36.359996
            ],
            [
              -89.545006,
              36.336809
            ],
            [
              -89.538079,
              36.337496
            ],
            [
              -89.531822,
              36.339246
            ],
            [
              -89.527029,
              36.341679
            ],
            [
              -89.522695,
              36.344789
            ],
            [
              -89.519,
              36.3486
            ],
            [
              -89.513178,
              36.359897
            ],
            [
              -89.509722,
              36.373626
            ],
            [
              -89.509558,
              36.375065
            ],
            [
              -89.51038,
              36.378356
            ],
            [
              -89.513956,
              36.384891
            ],
            [
              -89.525293,
              36.400446
            ],
            [
              -89.542337,
              36.420103
            ],
            [
              -89.544221,
              36.423684
            ],
            [
              -89.545255,
              36.427079
            ],
            [
              -89.545503,
              36.4307
            ],
            [
              -89.545061,
              36.434915
            ],
            [
              -89.543406,
              36.43877
            ],
            [
              -89.540868,
              36.441559
            ],
            [
              -89.527274,
              36.451545
            ],
            [
              -89.52519,
              36.453991
            ],
            [
              -89.523427,
              36.456572
            ],
            [
              -89.521021,
              36.461934
            ],
            [
              -89.51972,
              36.467002
            ],
            [
              -89.519501,
              36.475419
            ],
            [
              -89.520642,
              36.478668
            ],
            [
              -89.522674,
              36.481305
            ],
            [
              -89.534524,
              36.491432
            ],
            [
              -89.539232,
              36.497934
            ],
            [
              -89.542955,
              36.504957
            ],
            [
              -89.554321,
              36.517022
            ],
            [
              -89.558349,
              36.522099
            ],
            [
              -89.560344,
              36.525436
            ],
            [
              -89.565804,
              36.536988
            ],
            [
              -89.570071,
              36.544387
            ],
            [
              -89.571241,
              36.547343
            ],
            [
              -89.571509,
              36.552569
            ],
            [
              -89.569807,
              36.558119
            ],
            [
              -89.566817,
              36.564216
            ],
            [
              -89.563185,
              36.568749
            ],
            [
              -89.558089,
              36.573514
            ],
            [
              -89.55264,
              36.577178
            ],
            [
              -89.546113,
              36.579989
            ],
            [
              -89.542459,
              36.580566
            ],
            [
              -89.527583,
              36.581147
            ],
            [
              -89.522338,
              36.58018
            ],
            [
              -89.518702,
              36.578698
            ],
            [
              -89.514468,
              36.577803
            ],
            [
              -89.500076,
              36.576305
            ],
            [
              -89.484836,
              36.571821
            ],
            [
              -89.480893,
              36.569771
            ],
            [
              -89.479093,
              36.568206
            ],
            [
              -89.473341,
              36.559918
            ],
            [
              -89.467761,
              36.546847
            ],
            [
              -89.465445,
              36.536163
            ],
            [
              -89.465888,
              36.529946
            ],
            [
              -89.468668,
              36.521291
            ],
            [
              -89.47246,
              36.513741
            ],
            [
              -89.477186,
              36.50707
            ],
            [
              -89.482474,
              36.502131
            ],
            [
              -89.485427,
              36.497491
            ],
            [
              -89.48671,
              36.494954
            ],
            [
              -89.493495,
              36.4787
            ],
            [
              -89.494248,
              36.475972
            ],
            [
              -89.494074,
              36.473225
            ],
            [
              -89.493198,
              36.470124
            ],
            [
              -89.49067,
              36.465528
            ],
            [
              -89.486215,
              36.46162
            ],
            [
              -89.476532,
              36.457846
            ],
            [
              -89.471718,
              36.457001
            ],
            [
              -89.464153,
              36.457189
            ],
            [
              -89.460436,
              36.45814
            ],
            [
              -89.453081,
              36.461285
            ],
            [
              -89.448468,
              36.46442
            ],
            [
              -89.436763,
              36.474432
            ],
            [
              -89.429311,
              36.481875
            ],
            [
              -89.422942,
              36.489381
            ],
            [
              -89.41977,
              36.493896
            ],
            [
              -89.417293,
              36.499033
            ],
            [
              -89.405654,
              36.528165
            ],
            [
              -89.398685,
              36.542329
            ],
            [
              -89.396811,
              36.551975
            ],
            [
              -89.396627,
              36.556429
            ],
            [
              -89.395909,
              36.559649
            ],
            [
              -89.382762,
              36.583603
            ],
            [
              -89.380643,
              36.59113
            ],
            [
              -89.378012,
              36.608096
            ],
            [
              -89.376367,
              36.613868
            ],
            [
              -89.375453,
              36.615719
            ],
            [
              -89.371002,
              36.62084
            ],
            [
              -89.365548,
              36.625059
            ],
            [
              -89.35651,
              36.628439
            ],
            [
              -89.343753,
              36.630991
            ],
            [
              -89.334046,
              36.63225
            ],
            [
              -89.32758872176659,
              36.6321935043326
            ],
            [
              -89.327476,
              36.644969
            ],
            [
              -89.345968,
              36.651818
            ],
            [
              -89.346188,
              36.666785
            ],
            [
              -89.364331,
              36.674012
            ],
            [
              -89.373741,
              36.702948
            ],
            [
              -89.39146,
              36.702911
            ],
            [
              -89.400702,
              36.729992
            ],
            [
              -89.42795,
              36.746524
            ],
            [
              -89.436886,
              36.767944
            ],
            [
              -89.445888,
              36.767646
            ],
            [
              -89.445614,
              36.789311
            ],
            [
              -89.463226,
              36.788923
            ],
            [
              -89.483141,
              36.832561
            ],
            [
              -89.502045,
              36.832803
            ],
            [
              -89.502093,
              36.847905
            ],
            [
              -89.519701,
              36.847896
            ],
            [
              -89.519809,
              36.869617
            ],
            [
              -89.53773,
              36.869495
            ],
            [
              -89.567101,
              36.870882
            ],
            [
              -89.604596,
              36.866218
            ],
            [
              -89.605965,
              36.861537
            ],
            [
              -89.623629,
              36.861094
            ],
            [
              -89.664556,
              36.860238
            ],
            [
              -89.691603,
              36.859517
            ],
            [
              -89.704833,
              36.859285
            ],
            [
              -89.700104,
              36.740265
            ],
            [
              -89.700952,
              36.627453
            ],
            [
              -89.958786,
              36.629331
            ],
            [
              -89.963284,
              36.625471
            ],
            [
              -89.95387,
              36.545644
            ],
            [
              -89.958839,
              36.505344
            ],
            [
              -89.957425,
              36.460158
            ],
            [
              -89.972478,
              36.389597
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "149",
      "COUNTYNS": "00758529",
      "AFFGEOID": "0500000US29149",
      "GEOID": "29149",
      "NAME": "Oregon",
      "LSAD": "06",
      "ALAND": 2045563369,
      "AWATER": 4528610,
      "County_state": "Oregon,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.674237,
              36.787832
            ],
            [
              -91.678333,
              36.62506
            ],
            [
              -91.672018,
              36.606214
            ],
            [
              -91.672343,
              36.499463
            ],
            [
              -91.64259,
              36.499335
            ],
            [
              -91.631439,
              36.499198
            ],
            [
              -91.6176945446342,
              36.499264162473494
            ],
            [
              -91.601317,
              36.499343
            ],
            [
              -91.596213,
              36.499162
            ],
            [
              -91.549163,
              36.499161
            ],
            [
              -91.539359,
              36.499116
            ],
            [
              -91.53687,
              36.499156
            ],
            [
              -91.529774,
              36.499022
            ],
            [
              -91.50014,
              36.498812
            ],
            [
              -91.4500043450567,
              36.4975617737561
            ],
            [
              -91.446284,
              36.497469
            ],
            [
              -91.436502,
              36.497377
            ],
            [
              -91.433298,
              36.497262
            ],
            [
              -91.407261,
              36.497123
            ],
            [
              -91.407137,
              36.497112
            ],
            [
              -91.405141,
              36.497165
            ],
            [
              -91.404915,
              36.49712
            ],
            [
              -91.227398,
              36.497617
            ],
            [
              -91.218645,
              36.497564
            ],
            [
              -91.21736,
              36.497511
            ],
            [
              -91.126529,
              36.497712
            ],
            [
              -91.119618,
              36.780081
            ],
            [
              -91.117458,
              36.781814
            ],
            [
              -91.115926,
              36.823672
            ],
            [
              -91.224825,
              36.825679
            ],
            [
              -91.22283,
              36.883857
            ],
            [
              -91.250129,
              36.88515
            ],
            [
              -91.478122,
              36.885819
            ],
            [
              -91.500138,
              36.886898
            ],
            [
              -91.658111,
              36.888724
            ],
            [
              -91.660017,
              36.787531
            ],
            [
              -91.674237,
              36.787832
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "186",
      "COUNTYNS": "00765806",
      "AFFGEOID": "0500000US29186",
      "GEOID": "29186",
      "NAME": "Ste. Genevieve",
      "LSAD": "06",
      "ALAND": 1292800497,
      "AWATER": 19739775,
      "County_state": "Ste. Genevieve,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.459133,
              37.878924
            ],
            [
              -90.402446,
              37.83577
            ],
            [
              -90.200208,
              37.669628
            ],
            [
              -90.154187,
              37.699766
            ],
            [
              -90.110537,
              37.67134
            ],
            [
              -90.034488,
              37.772831
            ],
            [
              -89.999923,
              37.821523
            ],
            [
              -89.93819072186169,
              37.8751107703036
            ],
            [
              -89.950594,
              37.881526
            ],
            [
              -89.952499,
              37.883218
            ],
            [
              -89.971649,
              37.91526
            ],
            [
              -89.973642,
              37.917661
            ],
            [
              -89.974221,
              37.919217
            ],
            [
              -89.974918,
              37.926719
            ],
            [
              -89.968365,
              37.931456
            ],
            [
              -89.962273,
              37.934363
            ],
            [
              -89.959646,
              37.940196
            ],
            [
              -89.947429,
              37.940336
            ],
            [
              -89.937927,
              37.946193
            ],
            [
              -89.932467,
              37.947497
            ],
            [
              -89.925389,
              37.95413
            ],
            [
              -89.924811,
              37.955823
            ],
            [
              -89.925085,
              37.960021
            ],
            [
              -89.933797,
              37.959143
            ],
            [
              -89.935886,
              37.959581
            ],
            [
              -89.93693,
              37.961042
            ],
            [
              -89.93774,
              37.964994
            ],
            [
              -89.942099,
              37.970121
            ],
            [
              -89.95491,
              37.966647
            ],
            [
              -89.978919,
              37.962791
            ],
            [
              -89.986296,
              37.962198
            ],
            [
              -89.997103,
              37.963225
            ],
            [
              -90.00011,
              37.964563
            ],
            [
              -90.008353,
              37.970179
            ],
            [
              -90.03241,
              37.995258
            ],
            [
              -90.045908,
              38.000052
            ],
            [
              -90.049106,
              38.001715
            ],
            [
              -90.051357,
              38.003584
            ],
            [
              -90.052883,
              38.005907
            ],
            [
              -90.053541,
              38.00844
            ],
            [
              -90.055399,
              38.011953
            ],
            [
              -90.057269,
              38.014362
            ],
            [
              -90.059367,
              38.015543
            ],
            [
              -90.065045,
              38.016875
            ],
            [
              -90.072283,
              38.017001
            ],
            [
              -90.080959,
              38.015428
            ],
            [
              -90.08826,
              38.015772
            ],
            [
              -90.093774,
              38.017833
            ],
            [
              -90.11052,
              38.026547
            ],
            [
              -90.117423,
              38.031708
            ],
            [
              -90.126194,
              38.040702
            ],
            [
              -90.126612,
              38.043981
            ],
            [
              -90.126006,
              38.05057
            ],
            [
              -90.126396,
              38.054897
            ],
            [
              -90.128159,
              38.059644
            ],
            [
              -90.130788,
              38.062341
            ],
            [
              -90.144553,
              38.069023
            ],
            [
              -90.158533,
              38.074735
            ],
            [
              -90.161562,
              38.07489
            ],
            [
              -90.163411,
              38.074347
            ],
            [
              -90.17222,
              38.069636
            ],
            [
              -90.2066844548659,
              38.0879691505846
            ],
            [
              -90.2071279620056,
              38.0882050714256
            ],
            [
              -90.218708,
              38.094365
            ],
            [
              -90.243116,
              38.112669
            ],
            [
              -90.250118,
              38.125054
            ],
            [
              -90.252484,
              38.127571
            ],
            [
              -90.25266473951389,
              38.1278129416306
            ],
            [
              -90.249858,
              38.11455
            ],
            [
              -90.281651,
              38.103391
            ],
            [
              -90.295999,
              38.087979
            ],
            [
              -90.32939,
              38.09436
            ],
            [
              -90.416022,
              38.042315
            ],
            [
              -90.32287,
              37.981731
            ],
            [
              -90.328433,
              37.977513
            ],
            [
              -90.459133,
              37.878924
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "111",
      "COUNTYNS": "00758510",
      "AFFGEOID": "0500000US29111",
      "GEOID": "29111",
      "NAME": "Lewis",
      "LSAD": "06",
      "ALAND": 1308052634,
      "AWATER": 14901556,
      "County_state": "Lewis,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.947241,
              40.213799
            ],
            [
              -91.952726,
              39.949426
            ],
            [
              -91.840353,
              39.948235
            ],
            [
              -91.43547750086269,
              39.945277915357394
            ],
            [
              -91.43709,
              39.946417
            ],
            [
              -91.44156,
              39.951299
            ],
            [
              -91.447236,
              39.959502
            ],
            [
              -91.449806,
              39.965278
            ],
            [
              -91.454647,
              39.971306
            ],
            [
              -91.458852,
              39.979015
            ],
            [
              -91.459533,
              39.979892
            ],
            [
              -91.463683,
              39.981845
            ],
            [
              -91.465315,
              39.983995
            ],
            [
              -91.466682,
              39.987253
            ],
            [
              -91.467294,
              39.990631
            ],
            [
              -91.469247,
              39.995327
            ],
            [
              -91.477298,
              40.008993
            ],
            [
              -91.484064,
              40.019332
            ],
            [
              -91.487351,
              40.023201
            ],
            [
              -91.494878,
              40.036453
            ],
            [
              -91.489606,
              40.057435
            ],
            [
              -91.495365,
              40.070951
            ],
            [
              -91.497663,
              40.078257
            ],
            [
              -91.500823,
              40.090956
            ],
            [
              -91.506006,
              40.108126
            ],
            [
              -91.509245,
              40.121876
            ],
            [
              -91.510322,
              40.127994
            ],
            [
              -91.511749,
              40.147091
            ],
            [
              -91.51159,
              40.149269
            ],
            [
              -91.508324,
              40.156326
            ],
            [
              -91.508224,
              40.157665
            ],
            [
              -91.511956,
              40.170441
            ],
            [
              -91.513079,
              40.178537
            ],
            [
              -91.512974,
              40.181062
            ],
            [
              -91.511073,
              40.188794
            ],
            [
              -91.509551,
              40.191338
            ],
            [
              -91.505495,
              40.195606
            ],
            [
              -91.504477,
              40.198262
            ],
            [
              -91.50522531361149,
              40.200484700146596
            ],
            [
              -91.506664,
              40.204758
            ],
            [
              -91.507269,
              40.209338
            ],
            [
              -91.506947,
              40.21555
            ],
            [
              -91.504282,
              40.224299
            ],
            [
              -91.504289,
              40.231712
            ],
            [
              -91.505968,
              40.234305
            ],
            [
              -91.506501,
              40.236304
            ],
            [
              -91.505828,
              40.238839
            ],
            [
              -91.503231,
              40.243474
            ],
            [
              -91.500855,
              40.245722
            ],
            [
              -91.498104,
              40.247422
            ],
            [
              -91.4972633213575,
              40.248761318639396
            ],
            [
              -91.950812,
              40.25721
            ],
            [
              -91.951663,
              40.21395
            ],
            [
              -91.947241,
              40.213799
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "207",
      "COUNTYNS": "00758556",
      "AFFGEOID": "0500000US29207",
      "GEOID": "29207",
      "NAME": "Stoddard",
      "LSAD": "06",
      "ALAND": 2132125025,
      "AWATER": 15031190,
      "County_state": "Stoddard,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.258563,
              36.922043
            ],
            [
              -90.253266,
              36.899093
            ],
            [
              -90.245161,
              36.89255
            ],
            [
              -90.256856,
              36.879162
            ],
            [
              -90.22973,
              36.850621
            ],
            [
              -90.216321,
              36.842811
            ],
            [
              -90.224467,
              36.831273
            ],
            [
              -90.215884,
              36.814272
            ],
            [
              -90.197911,
              36.804143
            ],
            [
              -90.204205,
              36.772262
            ],
            [
              -90.19071,
              36.772329
            ],
            [
              -90.18744,
              36.755814
            ],
            [
              -90.175021,
              36.751333
            ],
            [
              -90.176481,
              36.741912
            ],
            [
              -90.156722,
              36.718085
            ],
            [
              -90.139804,
              36.696072
            ],
            [
              -90.149153,
              36.677162
            ],
            [
              -90.135556,
              36.659771
            ],
            [
              -90.148398,
              36.630656
            ],
            [
              -90.137928,
              36.631212
            ],
            [
              -89.958786,
              36.629331
            ],
            [
              -89.700952,
              36.627453
            ],
            [
              -89.700104,
              36.740265
            ],
            [
              -89.704833,
              36.859285
            ],
            [
              -89.691603,
              36.859517
            ],
            [
              -89.697389,
              36.874385
            ],
            [
              -89.686683,
              36.887022
            ],
            [
              -89.691892,
              36.901041
            ],
            [
              -89.68469,
              36.923278
            ],
            [
              -89.687657,
              36.922706
            ],
            [
              -89.693943,
              36.94072
            ],
            [
              -89.715424,
              36.952997
            ],
            [
              -89.721175,
              36.99002
            ],
            [
              -89.754727,
              37.03067
            ],
            [
              -89.75705,
              37.049825
            ],
            [
              -89.767608,
              37.051881
            ],
            [
              -89.786867,
              37.087102
            ],
            [
              -89.774512,
              37.097348
            ],
            [
              -89.765211,
              37.126066
            ],
            [
              -89.866627,
              37.126227
            ],
            [
              -89.959576,
              37.127353
            ],
            [
              -89.959196,
              37.05438
            ],
            [
              -89.995852,
              37.054734
            ],
            [
              -89.99572,
              37.04011
            ],
            [
              -90.111247,
              37.041205
            ],
            [
              -90.148518,
              37.041601
            ],
            [
              -90.149447,
              37.007444
            ],
            [
              -90.167874,
              37.007651
            ],
            [
              -90.168461,
              36.993195
            ],
            [
              -90.186327,
              36.993345
            ],
            [
              -90.186577,
              36.978683
            ],
            [
              -90.204884,
              36.978777
            ],
            [
              -90.205175,
              36.964552
            ],
            [
              -90.223203,
              36.964652
            ],
            [
              -90.223603,
              36.935756
            ],
            [
              -90.24164,
              36.935945
            ],
            [
              -90.24181,
              36.921807
            ],
            [
              -90.258563,
              36.922043
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "079",
      "COUNTYNS": "00758494",
      "AFFGEOID": "0500000US29079",
      "GEOID": "29079",
      "NAME": "Grundy",
      "LSAD": "06",
      "ALAND": 1127383741,
      "AWATER": 7118002,
      "County_state": "Grundy,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.763324,
              40.263988
            ],
            [
              -93.764021,
              40.132909
            ],
            [
              -93.761973,
              39.959552
            ],
            [
              -93.361957,
              39.967606
            ],
            [
              -93.361951,
              40.033098
            ],
            [
              -93.366556,
              40.033099
            ],
            [
              -93.367214,
              40.266314
            ],
            [
              -93.763324,
              40.263988
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "187",
      "COUNTYNS": "00758548",
      "AFFGEOID": "0500000US29187",
      "GEOID": "29187",
      "NAME": "St. Francois",
      "LSAD": "06",
      "ALAND": 1170437420,
      "AWATER": 7160607,
      "County_state": "St. Francois,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.645135,
              37.734813
            ],
            [
              -90.647874,
              37.642809
            ],
            [
              -90.535016,
              37.643641
            ],
            [
              -90.516756,
              37.642665
            ],
            [
              -90.453576,
              37.642752
            ],
            [
              -90.146778,
              37.641618
            ],
            [
              -90.110537,
              37.67134
            ],
            [
              -90.154187,
              37.699766
            ],
            [
              -90.200208,
              37.669628
            ],
            [
              -90.402446,
              37.83577
            ],
            [
              -90.459133,
              37.878924
            ],
            [
              -90.328433,
              37.977513
            ],
            [
              -90.32287,
              37.981731
            ],
            [
              -90.416022,
              38.042315
            ],
            [
              -90.583324,
              38.003499
            ],
            [
              -90.628192,
              38.007962
            ],
            [
              -90.612787,
              38.015895
            ],
            [
              -90.621508,
              38.040357
            ],
            [
              -90.612011,
              38.069585
            ],
            [
              -90.632063,
              38.074201
            ],
            [
              -90.63998,
              38.076548
            ],
            [
              -90.641491,
              37.950026
            ],
            [
              -90.641593,
              37.935731
            ],
            [
              -90.645135,
              37.734813
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "165",
      "COUNTYNS": "00758537",
      "AFFGEOID": "0500000US29165",
      "GEOID": "29165",
      "NAME": "Platte",
      "LSAD": "06",
      "ALAND": 1088302651,
      "AWATER": 17119867,
      "County_state": "Platte,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.102888,
              39.533347
            ],
            [
              -95.092704,
              39.524241
            ],
            [
              -95.082714,
              39.516712
            ],
            [
              -95.077441,
              39.513552
            ],
            [
              -95.059461,
              39.506143
            ],
            [
              -95.05638,
              39.503972
            ],
            [
              -95.052177,
              39.499996
            ],
            [
              -95.050552,
              39.497514
            ],
            [
              -95.049845,
              39.494415
            ],
            [
              -95.04837,
              39.48042
            ],
            [
              -95.047133,
              39.474971
            ],
            [
              -95.045716,
              39.472459
            ],
            [
              -95.04078,
              39.466387
            ],
            [
              -95.0375,
              39.463689
            ],
            [
              -95.033408,
              39.460876
            ],
            [
              -95.028498,
              39.458287
            ],
            [
              -95.015825,
              39.452809
            ],
            [
              -94.995768,
              39.448174
            ],
            [
              -94.990172,
              39.446192
            ],
            [
              -94.982144,
              39.440552
            ],
            [
              -94.978798,
              39.436241
            ],
            [
              -94.976606,
              39.426701
            ],
            [
              -94.972952,
              39.421705
            ],
            [
              -94.96884862177319,
              39.4190729027552
            ],
            [
              -94.966066,
              39.417288
            ],
            [
              -94.954817,
              39.413844
            ],
            [
              -94.951209,
              39.411707
            ],
            [
              -94.947864,
              39.408604
            ],
            [
              -94.946293,
              39.405646
            ],
            [
              -94.946662,
              39.399717
            ],
            [
              -94.946227,
              39.395648
            ],
            [
              -94.945577,
              39.393851
            ],
            [
              -94.942039,
              39.389499
            ],
            [
              -94.939697,
              39.38795
            ],
            [
              -94.9378575597478,
              39.3869219709658
            ],
            [
              -94.937158,
              39.386531
            ],
            [
              -94.933652,
              39.385546
            ],
            [
              -94.92311,
              39.384492
            ],
            [
              -94.919225,
              39.385174
            ],
            [
              -94.915859,
              39.386348
            ],
            [
              -94.909581,
              39.388865
            ],
            [
              -94.901823,
              39.392798
            ],
            [
              -94.894979,
              39.393565
            ],
            [
              -94.891845,
              39.393313
            ],
            [
              -94.888972,
              39.392432
            ],
            [
              -94.885026,
              39.389801
            ],
            [
              -94.880979,
              39.383899
            ],
            [
              -94.879281,
              39.37978
            ],
            [
              -94.879088,
              39.375703
            ],
            [
              -94.88136,
              39.370383
            ],
            [
              -94.885216,
              39.366911
            ],
            [
              -94.890928,
              39.364031
            ],
            [
              -94.896832,
              39.363135
            ],
            [
              -94.899024,
              39.362431
            ],
            [
              -94.902497,
              39.360383
            ],
            [
              -94.907297,
              39.356735
            ],
            [
              -94.909409,
              39.354255
            ],
            [
              -94.910017,
              39.352543
            ],
            [
              -94.91016417217429,
              39.351550531235
            ],
            [
              -94.910641,
              39.348335
            ],
            [
              -94.908065,
              39.323663
            ],
            [
              -94.9066023350017,
              39.3174023019755
            ],
            [
              -94.905329,
              39.311952
            ],
            [
              -94.903137,
              39.306272
            ],
            [
              -94.900049,
              39.300192
            ],
            [
              -94.895217,
              39.294208
            ],
            [
              -94.887056,
              39.28648
            ],
            [
              -94.882576,
              39.283328
            ],
            [
              -94.87832,
              39.281136
            ],
            [
              -94.867568,
              39.277841
            ],
            [
              -94.857072,
              39.273825
            ],
            [
              -94.84632,
              39.268481
            ],
            [
              -94.837855,
              39.262417
            ],
            [
              -94.831471,
              39.256273
            ],
            [
              -94.827487,
              39.249889
            ],
            [
              -94.825663,
              39.241729
            ],
            [
              -94.826111,
              39.238289
            ],
            [
              -94.827791,
              39.234001
            ],
            [
              -94.834896,
              39.223842
            ],
            [
              -94.835056,
              39.220658
            ],
            [
              -94.833552,
              39.217794
            ],
            [
              -94.831679,
              39.215938
            ],
            [
              -94.823791,
              39.209874
            ],
            [
              -94.820687,
              39.208626
            ],
            [
              -94.811663,
              39.206594
            ],
            [
              -94.799663,
              39.206018
            ],
            [
              -94.787343,
              39.207666
            ],
            [
              -94.783838,
              39.207154
            ],
            [
              -94.781518,
              39.206146
            ],
            [
              -94.777838,
              39.203522
            ],
            [
              -94.7755431917741,
              39.2006085912959
            ],
            [
              -94.775538,
              39.200602
            ],
            [
              -94.770338,
              39.190002
            ],
            [
              -94.763138,
              39.179903
            ],
            [
              -94.752338,
              39.173203
            ],
            [
              -94.741938,
              39.170203
            ],
            [
              -94.74192558188149,
              39.1702007007742
            ],
            [
              -94.736537,
              39.169203
            ],
            [
              -94.723637,
              39.169003
            ],
            [
              -94.714137,
              39.170403
            ],
            [
              -94.696332,
              39.178563
            ],
            [
              -94.687236,
              39.183503
            ],
            [
              -94.680336,
              39.184303
            ],
            [
              -94.669135,
              39.182003
            ],
            [
              -94.663835,
              39.179103
            ],
            [
              -94.6621616011898,
              39.1738489080539
            ],
            [
              -94.660315,
              39.168051
            ],
            [
              -94.662435,
              39.157603
            ],
            [
              -94.650735,
              39.154103
            ],
            [
              -94.640035,
              39.153103
            ],
            [
              -94.623934,
              39.156603
            ],
            [
              -94.615834,
              39.160003
            ],
            [
              -94.608834,
              39.160503
            ],
            [
              -94.601733,
              39.159603
            ],
            [
              -94.601466,
              39.179732
            ],
            [
              -94.601111,
              39.195857
            ],
            [
              -94.600966,
              39.246494
            ],
            [
              -94.600677,
              39.267546
            ],
            [
              -94.60038,
              39.289436
            ],
            [
              -94.600762,
              39.3333
            ],
            [
              -94.60081,
              39.370971
            ],
            [
              -94.600819,
              39.456155
            ],
            [
              -94.60104,
              39.470415
            ],
            [
              -94.601246,
              39.530372
            ],
            [
              -94.607146,
              39.532092
            ],
            [
              -95.102888,
              39.533347
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "153",
      "COUNTYNS": "00758531",
      "AFFGEOID": "0500000US29153",
      "GEOID": "29153",
      "NAME": "Ozark",
      "LSAD": "06",
      "ALAND": 1929467329,
      "AWATER": 26575007,
      "County_state": "Ozark,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.7723406785747,
              36.497772035561795
            ],
            [
              -92.772333,
              36.497772
            ],
            [
              -92.564238,
              36.49824
            ],
            [
              -92.5291281035939,
              36.498608860563095
            ],
            [
              -92.516836,
              36.498738
            ],
            [
              -92.444129,
              36.498526
            ],
            [
              -92.420383,
              36.497914
            ],
            [
              -92.384927,
              36.497845
            ],
            [
              -92.375159,
              36.497199
            ],
            [
              -92.350277,
              36.497787
            ],
            [
              -92.318415,
              36.497711
            ],
            [
              -92.309424,
              36.497894
            ],
            [
              -92.216412,
              36.498417
            ],
            [
              -92.214143,
              36.498372
            ],
            [
              -92.211449,
              36.498395
            ],
            [
              -92.199396,
              36.498351
            ],
            [
              -92.1502951365485,
              36.4986337152141
            ],
            [
              -92.137741,
              36.498706
            ],
            [
              -92.1204149758124,
              36.498863012436004
            ],
            [
              -92.112346,
              36.794231
            ],
            [
              -92.764869,
              36.806097
            ],
            [
              -92.7723406785747,
              36.497772035561795
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "213",
      "COUNTYNS": "00758559",
      "AFFGEOID": "0500000US29213",
      "GEOID": "29213",
      "NAME": "Taney",
      "LSAD": "06",
      "ALAND": 1637993911,
      "AWATER": 49745785,
      "County_state": "Taney,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.313648,
              36.557583
            ],
            [
              -93.31532440839989,
              36.4984079629635
            ],
            [
              -93.2934668009526,
              36.498343671809
            ],
            [
              -93.2908089270187,
              36.4983358540374
            ],
            [
              -93.1511512645162,
              36.497925070177295
            ],
            [
              -93.125969,
              36.497851
            ],
            [
              -93.088988,
              36.498184
            ],
            [
              -93.087635,
              36.498239
            ],
            [
              -93.069512,
              36.498242
            ],
            [
              -93.068455,
              36.49825
            ],
            [
              -92.894336,
              36.497867
            ],
            [
              -92.894001,
              36.49785
            ],
            [
              -92.85404946684739,
              36.4979826282189
            ],
            [
              -92.838876,
              36.498033
            ],
            [
              -92.838621,
              36.498079
            ],
            [
              -92.7723406785747,
              36.497772035561795
            ],
            [
              -92.764869,
              36.806097
            ],
            [
              -92.909336,
              36.809178
            ],
            [
              -93.146854,
              36.81382
            ],
            [
              -93.226041,
              36.815444
            ],
            [
              -93.304359,
              36.816866
            ],
            [
              -93.30956,
              36.729997
            ],
            [
              -93.313648,
              36.557583
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "223",
      "COUNTYNS": "00758564",
      "AFFGEOID": "0500000US29223",
      "GEOID": "29223",
      "NAME": "Wayne",
      "LSAD": "06",
      "ALAND": 1966257800,
      "AWATER": 38558983,
      "County_state": "Wayne,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.779025,
              37.13792
            ],
            [
              -90.779553,
              37.050324
            ],
            [
              -90.729722,
              37.049534
            ],
            [
              -90.730938,
              37.000398
            ],
            [
              -90.712986,
              37.000367
            ],
            [
              -90.713239,
              36.986065
            ],
            [
              -90.71342,
              36.971051
            ],
            [
              -90.695419,
              36.970751
            ],
            [
              -90.696891,
              36.927186
            ],
            [
              -90.678562,
              36.92649
            ],
            [
              -90.352708,
              36.926653
            ],
            [
              -90.338508,
              36.925653
            ],
            [
              -90.258563,
              36.922043
            ],
            [
              -90.24181,
              36.921807
            ],
            [
              -90.24164,
              36.935945
            ],
            [
              -90.223603,
              36.935756
            ],
            [
              -90.223203,
              36.964652
            ],
            [
              -90.205175,
              36.964552
            ],
            [
              -90.204884,
              36.978777
            ],
            [
              -90.186577,
              36.978683
            ],
            [
              -90.186327,
              36.993345
            ],
            [
              -90.168461,
              36.993195
            ],
            [
              -90.167874,
              37.007651
            ],
            [
              -90.149447,
              37.007444
            ],
            [
              -90.148518,
              37.041601
            ],
            [
              -90.111247,
              37.041205
            ],
            [
              -90.110354,
              37.085871
            ],
            [
              -90.22323,
              37.086519
            ],
            [
              -90.217657,
              37.314967
            ],
            [
              -90.236355,
              37.316315
            ],
            [
              -90.346243,
              37.314577
            ],
            [
              -90.549729,
              37.317594
            ],
            [
              -90.550603,
              37.272608
            ],
            [
              -90.642761,
              37.272216
            ],
            [
              -90.661775,
              37.274767
            ],
            [
              -90.735453,
              37.27104
            ],
            [
              -90.734438,
              37.227146
            ],
            [
              -90.737146,
              37.166413
            ],
            [
              -90.75587,
              37.166722
            ],
            [
              -90.756799,
              37.137739
            ],
            [
              -90.779025,
              37.13792
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "087",
      "COUNTYNS": "00758498",
      "AFFGEOID": "0500000US29087",
      "GEOID": "29087",
      "NAME": "Holt",
      "LSAD": "06",
      "ALAND": 1198369598,
      "AWATER": 19839056,
      "County_state": "Holt,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.554817342733,
              40.26445984121119
            ],
            [
              -95.554324,
              40.263395
            ],
            [
              -95.552473,
              40.261904
            ],
            [
              -95.54716,
              40.259066
            ],
            [
              -95.521925,
              40.24947
            ],
            [
              -95.515455,
              40.248505
            ],
            [
              -95.50026,
              40.249629
            ],
            [
              -95.490333,
              40.248966
            ],
            [
              -95.485994,
              40.247825
            ],
            [
              -95.482778,
              40.246273
            ],
            [
              -95.477501,
              40.24272
            ],
            [
              -95.472548,
              40.236078
            ],
            [
              -95.470349,
              40.230819
            ],
            [
              -95.469718,
              40.227908
            ],
            [
              -95.470061,
              40.221507
            ],
            [
              -95.471393,
              40.217333
            ],
            [
              -95.473469,
              40.213482
            ],
            [
              -95.477948,
              40.208643
            ],
            [
              -95.482319,
              40.200667
            ],
            [
              -95.482757,
              40.197346
            ],
            [
              -95.48254,
              40.192283
            ],
            [
              -95.48102,
              40.188524
            ],
            [
              -95.479193,
              40.185652
            ],
            [
              -95.476301,
              40.181988
            ],
            [
              -95.471054,
              40.17691
            ],
            [
              -95.460746,
              40.169173
            ],
            [
              -95.454919,
              40.166577
            ],
            [
              -95.442818,
              40.163261
            ],
            [
              -95.440694,
              40.162282
            ],
            [
              -95.436348,
              40.15872
            ],
            [
              -95.434817,
              40.156017
            ],
            [
              -95.433822,
              40.152935
            ],
            [
              -95.433374,
              40.146114
            ],
            [
              -95.432165,
              40.141025
            ],
            [
              -95.431022,
              40.138411
            ],
            [
              -95.428749,
              40.135577
            ],
            [
              -95.42478,
              40.132765
            ],
            [
              -95.419186,
              40.130586
            ],
            [
              -95.409481,
              40.130052
            ],
            [
              -95.404395,
              40.129018
            ],
            [
              -95.398667,
              40.126419
            ],
            [
              -95.395369,
              40.122811
            ],
            [
              -95.393347,
              40.119212
            ],
            [
              -95.39284,
              40.115887
            ],
            [
              -95.393062,
              40.111175
            ],
            [
              -95.394216,
              40.108263
            ],
            [
              -95.397146,
              40.105183
            ],
            [
              -95.400139,
              40.103238
            ],
            [
              -95.407591,
              40.09803
            ],
            [
              -95.410643,
              40.091531
            ],
            [
              -95.409243,
              40.084166
            ],
            [
              -95.408455,
              40.079158
            ],
            [
              -95.409856,
              40.07432
            ],
            [
              -95.414734,
              40.06982
            ],
            [
              -95.418345,
              40.066509
            ],
            [
              -95.420643,
              40.062599
            ],
            [
              -95.42164,
              40.058952
            ],
            [
              -95.41932,
              40.048442
            ],
            [
              -95.416824,
              40.043235
            ],
            [
              -95.413588,
              40.038424
            ],
            [
              -95.40726,
              40.033112
            ],
            [
              -95.402665,
              40.030567
            ],
            [
              -95.395858,
              40.028038
            ],
            [
              -95.391527,
              40.027058
            ],
            [
              -95.387195,
              40.02677
            ],
            [
              -95.382957,
              40.027112
            ],
            [
              -95.363983,
              40.031498
            ],
            [
              -95.356876,
              40.031522
            ],
            [
              -95.348777,
              40.029297
            ],
            [
              -95.346573,
              40.028272
            ],
            [
              -95.336242,
              40.019104
            ],
            [
              -95.328501,
              40.015657
            ],
            [
              -95.318015,
              40.013216
            ],
            [
              -95.315271,
              40.01207
            ],
            [
              -95.312211,
              40.009395
            ],
            [
              -95.311163,
              40.007806
            ],
            [
              -95.308469,
              40.002194
            ],
            [
              -95.30829,
              39.999998
            ],
            [
              -95.308404,
              39.993758
            ],
            [
              -95.30778,
              39.990618
            ],
            [
              -95.307111,
              39.989114
            ],
            [
              -95.302507,
              39.984357
            ],
            [
              -95.289715,
              39.977706
            ],
            [
              -95.274757,
              39.972115
            ],
            [
              -95.269886,
              39.969396
            ],
            [
              -95.261854,
              39.960618
            ],
            [
              -95.257652,
              39.954886
            ],
            [
              -95.250254,
              39.948644
            ],
            [
              -95.241383,
              39.944949
            ],
            [
              -95.236761,
              39.943931
            ],
            [
              -95.231114,
              39.943784
            ],
            [
              -95.220212,
              39.944433
            ],
            [
              -95.21644,
              39.943953
            ],
            [
              -95.213737,
              39.943206
            ],
            [
              -95.204428,
              39.938949
            ],
            [
              -95.201277,
              39.934194
            ],
            [
              -95.20069,
              39.928155
            ],
            [
              -95.20201,
              39.922438
            ],
            [
              -95.205745,
              39.915169
            ],
            [
              -95.206326,
              39.912121
            ],
            [
              -95.206196,
              39.909557
            ],
            [
              -95.205733,
              39.908275
            ],
            [
              -95.201935,
              39.904053
            ],
            [
              -95.199347,
              39.902709
            ],
            [
              -95.193816,
              39.90069
            ],
            [
              -95.189565,
              39.899959
            ],
            [
              -95.179453,
              39.900062
            ],
            [
              -95.172296,
              39.902026
            ],
            [
              -95.159834,
              39.906984
            ],
            [
              -95.156024,
              39.907243
            ],
            [
              -95.149657,
              39.905948
            ],
            [
              -95.146055,
              39.904183
            ],
            [
              -95.143802,
              39.901918
            ],
            [
              -95.142563,
              39.897992
            ],
            [
              -95.142445,
              39.89542
            ],
            [
              -95.143403,
              39.889356
            ],
            [
              -95.142718,
              39.885889
            ],
            [
              -95.140601,
              39.881688
            ],
            [
              -95.137092,
              39.878351
            ],
            [
              -95.134747,
              39.876852
            ],
            [
              -95.128166,
              39.874165
            ],
            [
              -95.105912,
              39.869164
            ],
            [
              -95.090158,
              39.86314
            ],
            [
              -95.085003,
              39.861883
            ],
            [
              -95.081534,
              39.861718
            ],
            [
              -95.052535,
              39.864374
            ],
            [
              -95.042142,
              39.864805
            ],
            [
              -95.037767,
              39.865542
            ],
            [
              -95.032053,
              39.868337
            ],
            [
              -95.027931,
              39.871522
            ],
            [
              -95.025422,
              39.876711
            ],
            [
              -95.025119,
              39.878833
            ],
            [
              -95.025947,
              39.886747
            ],
            [
              -95.02524,
              39.8897
            ],
            [
              -95.024389,
              39.891202
            ],
            [
              -95.018743,
              39.897372
            ],
            [
              -95.013152,
              39.899953
            ],
            [
              -95.00844,
              39.900596
            ],
            [
              -95.003819,
              39.900401
            ],
            [
              -94.99341025106669,
              39.8977932373378
            ],
            [
              -94.993392,
              39.915031
            ],
            [
              -95.006447,
              39.922239
            ],
            [
              -94.988809,
              39.942738
            ],
            [
              -94.99463,
              39.971979
            ],
            [
              -95.004856,
              39.979501
            ],
            [
              -95.011848,
              40.01539
            ],
            [
              -95.004258,
              40.027338
            ],
            [
              -95.010542,
              40.046761
            ],
            [
              -95.034004,
              40.048847
            ],
            [
              -95.042625,
              40.062441
            ],
            [
              -95.06071,
              40.069866
            ],
            [
              -95.043494,
              40.078447
            ],
            [
              -95.044283,
              40.102154
            ],
            [
              -95.033587,
              40.108718
            ],
            [
              -95.04308,
              40.130096
            ],
            [
              -95.065662,
              40.15097
            ],
            [
              -95.060645,
              40.17173
            ],
            [
              -95.073527,
              40.195537
            ],
            [
              -95.063849,
              40.205863
            ],
            [
              -95.064972,
              40.246589
            ],
            [
              -95.071703,
              40.260859
            ],
            [
              -95.180614,
              40.261706
            ],
            [
              -95.554817342733,
              40.26445984121119
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "083",
      "COUNTYNS": "00758496",
      "AFFGEOID": "0500000US29083",
      "GEOID": "29083",
      "NAME": "Henry",
      "LSAD": "06",
      "ALAND": 1805075858,
      "AWATER": 91636450,
      "County_state": "Henry,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.067379,
              38.402937
            ],
            [
              -94.075214,
              38.214502
            ],
            [
              -94.049895,
              38.213985
            ],
            [
              -93.557009,
              38.201074
            ],
            [
              -93.569525,
              38.218944
            ],
            [
              -93.557027,
              38.219673
            ],
            [
              -93.536682,
              38.205657
            ],
            [
              -93.523484,
              38.207216
            ],
            [
              -93.518259,
              38.321792
            ],
            [
              -93.511958,
              38.446814
            ],
            [
              -93.512743,
              38.512476
            ],
            [
              -93.51103,
              38.55621
            ],
            [
              -94.064317,
              38.56738
            ],
            [
              -94.06782,
              38.466016
            ],
            [
              -94.065713,
              38.447087
            ],
            [
              -94.067379,
              38.402937
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "085",
      "COUNTYNS": "00758497",
      "AFFGEOID": "0500000US29085",
      "GEOID": "29085",
      "NAME": "Hickory",
      "LSAD": "06",
      "ALAND": 1032907248,
      "AWATER": 33455656,
      "County_state": "Hickory,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.573202,
              37.828035
            ],
            [
              -93.574103,
              37.813336
            ],
            [
              -93.187915,
              37.802737
            ],
            [
              -93.182648,
              37.904232
            ],
            [
              -93.072447,
              37.902627
            ],
            [
              -93.065199,
              38.062479
            ],
            [
              -93.503946,
              38.073123
            ],
            [
              -93.512103,
              37.913535
            ],
            [
              -93.568709,
              37.914918
            ],
            [
              -93.573202,
              37.828035
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "129",
      "COUNTYNS": "00758519",
      "AFFGEOID": "0500000US29129",
      "GEOID": "29129",
      "NAME": "Mercer",
      "LSAD": "06",
      "ALAND": 1175427728,
      "AWATER": 3302140,
      "County_state": "Mercer,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.7743442708352,
              40.5775843690664
            ],
            [
              -93.774548,
              40.47235
            ],
            [
              -93.764823,
              40.472515
            ],
            [
              -93.763324,
              40.263988
            ],
            [
              -93.367214,
              40.266314
            ],
            [
              -93.366935,
              40.382999
            ],
            [
              -93.366859,
              40.471518
            ],
            [
              -93.37484,
              40.472212
            ],
            [
              -93.3743860081406,
              40.5803343112705
            ],
            [
              -93.441767,
              40.579916
            ],
            [
              -93.465297,
              40.580164
            ],
            [
              -93.466887,
              40.580072
            ],
            [
              -93.524124,
              40.580481
            ],
            [
              -93.527607,
              40.580436
            ],
            [
              -93.528177,
              40.580367
            ],
            [
              -93.548284,
              40.580417
            ],
            [
              -93.553986,
              40.580303
            ],
            [
              -93.556899,
              40.580235
            ],
            [
              -93.558938,
              40.580189
            ],
            [
              -93.560798,
              40.580304
            ],
            [
              -93.56524,
              40.580143
            ],
            [
              -93.56581,
              40.580075
            ],
            [
              -93.566189,
              40.580117
            ],
            [
              -93.597352,
              40.579496
            ],
            [
              -93.656211,
              40.578352
            ],
            [
              -93.659272,
              40.57833
            ],
            [
              -93.661913,
              40.578354
            ],
            [
              -93.668845,
              40.578241
            ],
            [
              -93.677099,
              40.578127
            ],
            [
              -93.690333,
              40.577875
            ],
            [
              -93.722443,
              40.577641
            ],
            [
              -93.728355,
              40.577547
            ],
            [
              -93.737259,
              40.577542
            ],
            [
              -93.742759,
              40.577518
            ],
            [
              -93.750223,
              40.57772
            ],
            [
              -93.770231,
              40.577615
            ],
            [
              -93.7743442708352,
              40.5775843690664
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "167",
      "COUNTYNS": "00758538",
      "AFFGEOID": "0500000US29167",
      "GEOID": "29167",
      "NAME": "Polk",
      "LSAD": "06",
      "ALAND": 1646005964,
      "AWATER": 17838300,
      "County_state": "Polk,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.629742,
              37.797101
            ],
            [
              -93.632439,
              37.742302
            ],
            [
              -93.609489,
              37.741755
            ],
            [
              -93.61381,
              37.630927
            ],
            [
              -93.616033,
              37.572689
            ],
            [
              -93.621153,
              37.427423
            ],
            [
              -93.43723,
              37.423554
            ],
            [
              -93.251045,
              37.418861
            ],
            [
              -93.182489,
              37.417204
            ],
            [
              -93.173183,
              37.72966
            ],
            [
              -93.19138,
              37.730012
            ],
            [
              -93.187915,
              37.802737
            ],
            [
              -93.574103,
              37.813336
            ],
            [
              -93.573202,
              37.828035
            ],
            [
              -93.628404,
              37.829435
            ],
            [
              -93.629742,
              37.797101
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "103",
      "COUNTYNS": "00758506",
      "AFFGEOID": "0500000US29103",
      "GEOID": "29103",
      "NAME": "Knox",
      "LSAD": "06",
      "ALAND": 1305376708,
      "AWATER": 7307012,
      "County_state": "Knox,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.347801,
              40.302756
            ],
            [
              -92.346455,
              40.215888
            ],
            [
              -92.345064,
              40.03721
            ],
            [
              -92.344708,
              39.953297
            ],
            [
              -92.287882,
              39.95249
            ],
            [
              -91.952726,
              39.949426
            ],
            [
              -91.947241,
              40.213799
            ],
            [
              -91.951663,
              40.21395
            ],
            [
              -91.950812,
              40.25721
            ],
            [
              -91.949747,
              40.300832
            ],
            [
              -92.347801,
              40.302756
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "121",
      "COUNTYNS": "00758514",
      "AFFGEOID": "0500000US29121",
      "GEOID": "29121",
      "NAME": "Macon",
      "LSAD": "06",
      "ALAND": 2075118128,
      "AWATER": 28912824,
      "County_state": "Macon,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.847477,
              40.037301
            ],
            [
              -92.85792,
              39.699985
            ],
            [
              -92.689331,
              39.698094
            ],
            [
              -92.692149,
              39.610265
            ],
            [
              -92.300871,
              39.605348
            ],
            [
              -92.299316,
              39.605344
            ],
            [
              -92.287882,
              39.95249
            ],
            [
              -92.344708,
              39.953297
            ],
            [
              -92.345064,
              40.03721
            ],
            [
              -92.673681,
              40.038058
            ],
            [
              -92.685819,
              40.038289
            ],
            [
              -92.847477,
              40.037301
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "057",
      "COUNTYNS": "00758483",
      "AFFGEOID": "0500000US29057",
      "GEOID": "29057",
      "NAME": "Dade",
      "LSAD": "06",
      "ALAND": 1269123621,
      "AWATER": 42069988,
      "County_state": "Dade,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.082083,
              37.349291
            ],
            [
              -94.083833,
              37.290851
            ],
            [
              -94.052313,
              37.290078
            ],
            [
              -93.625844,
              37.282011
            ],
            [
              -93.621153,
              37.427423
            ],
            [
              -93.616033,
              37.572689
            ],
            [
              -94.075385,
              37.58157
            ],
            [
              -94.082083,
              37.349291
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "115",
      "COUNTYNS": "00758512",
      "AFFGEOID": "0500000US29115",
      "GEOID": "29115",
      "NAME": "Linn",
      "LSAD": "06",
      "ALAND": 1594366100,
      "AWATER": 14991073,
      "County_state": "Linn,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.361422,
              39.880137
            ],
            [
              -93.364808,
              39.703803
            ],
            [
              -93.266968,
              39.703562
            ],
            [
              -93.082306,
              39.706714
            ],
            [
              -92.95992,
              39.70215
            ],
            [
              -92.85792,
              39.699985
            ],
            [
              -92.847477,
              40.037301
            ],
            [
              -92.856191,
              40.037253
            ],
            [
              -92.865015,
              40.036791
            ],
            [
              -92.899877,
              40.036626
            ],
            [
              -93.361951,
              40.033098
            ],
            [
              -93.361957,
              39.967606
            ],
            [
              -93.361422,
              39.880137
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "109",
      "COUNTYNS": "00758509",
      "AFFGEOID": "0500000US29109",
      "GEOID": "29109",
      "NAME": "Lawrence",
      "LSAD": "06",
      "ALAND": 1584391270,
      "AWATER": 4249327,
      "County_state": "Lawrence,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.062363,
              36.946395
            ],
            [
              -94.06274,
              36.931775
            ],
            [
              -94.008605,
              36.930711
            ],
            [
              -93.611003,
              36.922831
            ],
            [
              -93.610126,
              36.99581
            ],
            [
              -93.608899,
              37.098153
            ],
            [
              -93.605095,
              37.281482
            ],
            [
              -93.625844,
              37.282011
            ],
            [
              -94.052313,
              37.290078
            ],
            [
              -94.059211,
              37.048127
            ],
            [
              -94.06108,
              36.989851
            ],
            [
              -94.062363,
              36.946395
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "003",
      "COUNTYNS": "00758456",
      "AFFGEOID": "0500000US29003",
      "GEOID": "29003",
      "NAME": "Andrew",
      "LSAD": "06",
      "ALAND": 1120659795,
      "AWATER": 9686701,
      "County_state": "Andrew,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.043494,
              40.078447
            ],
            [
              -95.06071,
              40.069866
            ],
            [
              -95.042625,
              40.062441
            ],
            [
              -95.034004,
              40.048847
            ],
            [
              -95.010542,
              40.046761
            ],
            [
              -95.004258,
              40.027338
            ],
            [
              -95.011848,
              40.01539
            ],
            [
              -95.004856,
              39.979501
            ],
            [
              -94.99463,
              39.971979
            ],
            [
              -94.988809,
              39.942738
            ],
            [
              -95.006447,
              39.922239
            ],
            [
              -94.993392,
              39.915031
            ],
            [
              -94.99341025106669,
              39.8977932373378
            ],
            [
              -94.990284,
              39.89701
            ],
            [
              -94.986975,
              39.89667
            ],
            [
              -94.977749,
              39.897472
            ],
            [
              -94.963345,
              39.901136
            ],
            [
              -94.959276,
              39.901671
            ],
            [
              -94.95154,
              39.900533
            ],
            [
              -94.943867,
              39.89813
            ],
            [
              -94.934493,
              39.893366
            ],
            [
              -94.929574,
              39.888754
            ],
            [
              -94.927897,
              39.886112
            ],
            [
              -94.927359,
              39.883966
            ],
            [
              -94.927252,
              39.880258
            ],
            [
              -94.928466,
              39.876344
            ],
            [
              -94.931463,
              39.872602
            ],
            [
              -94.938791,
              39.866954
            ],
            [
              -94.940743,
              39.86441
            ],
            [
              -94.942407,
              39.861066
            ],
            [
              -94.942567,
              39.856602
            ],
            [
              -94.939767,
              39.85193
            ],
            [
              -94.937655,
              39.849786
            ],
            [
              -94.92615,
              39.841322
            ],
            [
              -94.916918,
              39.836138
            ],
            [
              -94.909942,
              39.834426
            ],
            [
              -94.903157,
              39.83385
            ],
            [
              -94.892677,
              39.834378
            ],
            [
              -94.889493,
              39.834026
            ],
            [
              -94.886933,
              39.833098
            ],
            [
              -94.881013,
              39.828922
            ],
            [
              -94.878677,
              39.826522
            ],
            [
              -94.877044,
              39.823754
            ],
            [
              -94.876544,
              39.820594
            ],
            [
              -94.822018,
              39.821224
            ],
            [
              -94.602819,
              39.819608
            ],
            [
              -94.605377,
              40.039217
            ],
            [
              -94.606554,
              40.044898
            ],
            [
              -94.605942,
              40.126127
            ],
            [
              -95.04308,
              40.130096
            ],
            [
              -95.033587,
              40.108718
            ],
            [
              -95.044283,
              40.102154
            ],
            [
              -95.043494,
              40.078447
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "089",
      "COUNTYNS": "00758499",
      "AFFGEOID": "0500000US29089",
      "GEOID": "29089",
      "NAME": "Howard",
      "LSAD": "06",
      "ALAND": 1201192546,
      "AWATER": 19893064,
      "County_state": "Howard,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.94385,
              39.121738
            ],
            [
              -92.945232,
              39.107089
            ],
            [
              -92.934053,
              39.099329
            ],
            [
              -92.90897,
              39.095547
            ],
            [
              -92.907362,
              39.084206
            ],
            [
              -92.934569,
              39.064547
            ],
            [
              -92.914477,
              39.021188
            ],
            [
              -92.876046,
              39.000836
            ],
            [
              -92.852628,
              38.982183
            ],
            [
              -92.791809,
              38.972044
            ],
            [
              -92.715643,
              38.984011
            ],
            [
              -92.693002,
              38.975957
            ],
            [
              -92.665822,
              38.976237
            ],
            [
              -92.594126,
              38.967537
            ],
            [
              -92.558086,
              38.970692
            ],
            [
              -92.570111,
              38.979008
            ],
            [
              -92.556383,
              38.985644
            ],
            [
              -92.565124,
              38.998274
            ],
            [
              -92.513553,
              39.088806
            ],
            [
              -92.430229,
              39.248795
            ],
            [
              -92.55953,
              39.28297
            ],
            [
              -92.568533,
              39.285326
            ],
            [
              -92.707367,
              39.321614
            ],
            [
              -92.790369,
              39.343586
            ],
            [
              -92.849224,
              39.226218
            ],
            [
              -92.860333,
              39.20107
            ],
            [
              -92.890544,
              39.180379
            ],
            [
              -92.904802,
              39.166155
            ],
            [
              -92.906073,
              39.147639
            ],
            [
              -92.922615,
              39.130789
            ],
            [
              -92.94385,
              39.121738
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "059",
      "COUNTYNS": "00758484",
      "AFFGEOID": "0500000US29059",
      "GEOID": "29059",
      "NAME": "Dallas",
      "LSAD": "06",
      "ALAND": 1400714729,
      "AWATER": 5252024,
      "County_state": "Dallas,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.187915,
              37.802737
            ],
            [
              -93.19138,
              37.730012
            ],
            [
              -93.173183,
              37.72966
            ],
            [
              -93.182489,
              37.417204
            ],
            [
              -93.07338,
              37.414989
            ],
            [
              -93.071584,
              37.487444
            ],
            [
              -92.853481,
              37.48397
            ],
            [
              -92.846281,
              37.721039
            ],
            [
              -92.859913,
              37.721239
            ],
            [
              -92.858116,
              37.82342
            ],
            [
              -92.855384,
              37.895736
            ],
            [
              -93.072447,
              37.902627
            ],
            [
              -93.182648,
              37.904232
            ],
            [
              -93.187915,
              37.802737
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "051",
      "COUNTYNS": "00758480",
      "AFFGEOID": "0500000US29051",
      "GEOID": "29051",
      "NAME": "Cole",
      "LSAD": "06",
      "ALAND": 1014013382,
      "AWATER": 19259438,
      "County_state": "Cole,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.430744,
              38.627109
            ],
            [
              -92.495185,
              38.427829
            ],
            [
              -92.403484,
              38.425331
            ],
            [
              -92.408407,
              38.341344
            ],
            [
              -92.328962,
              38.337299
            ],
            [
              -92.284479,
              38.337534
            ],
            [
              -92.250178,
              38.322713
            ],
            [
              -92.224678,
              38.335733
            ],
            [
              -92.194476,
              38.335536
            ],
            [
              -92.193178,
              38.370835
            ],
            [
              -92.133381,
              38.384609
            ],
            [
              -92.114977,
              38.401937
            ],
            [
              -92.124778,
              38.419936
            ],
            [
              -92.149291,
              38.431152
            ],
            [
              -92.167255,
              38.463443
            ],
            [
              -92.167357,
              38.469687
            ],
            [
              -92.151594,
              38.474768
            ],
            [
              -92.116275,
              38.457571
            ],
            [
              -92.081944,
              38.46525
            ],
            [
              -92.043489,
              38.466479
            ],
            [
              -92.020554,
              38.47629
            ],
            [
              -92.0087,
              38.500038
            ],
            [
              -92.025183,
              38.524129
            ],
            [
              -92.025857,
              38.552017
            ],
            [
              -92.0035,
              38.56026
            ],
            [
              -92.008983,
              38.5709
            ],
            [
              -92.065233,
              38.557079
            ],
            [
              -92.107211,
              38.561054
            ],
            [
              -92.13775,
              38.569315
            ],
            [
              -92.169328,
              38.58166
            ],
            [
              -92.202962,
              38.604938
            ],
            [
              -92.204751,
              38.630279
            ],
            [
              -92.220661,
              38.643167
            ],
            [
              -92.229401,
              38.646027
            ],
            [
              -92.251523,
              38.64419
            ],
            [
              -92.29337,
              38.666564
            ],
            [
              -92.36091,
              38.681848
            ],
            [
              -92.343265,
              38.705073
            ],
            [
              -92.360216,
              38.730133
            ],
            [
              -92.394866,
              38.73913
            ],
            [
              -92.430657,
              38.627382
            ],
            [
              -92.430744,
              38.627109
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "009",
      "COUNTYNS": "00758459",
      "AFFGEOID": "0500000US29009",
      "GEOID": "29009",
      "NAME": "Barry",
      "LSAD": "06",
      "ALAND": 2015661187,
      "AWATER": 32940075,
      "County_state": "Barry,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.077089,
              36.49873
            ],
            [
              -94.07342263223619,
              36.498729578835395
            ],
            [
              -93.96392,
              36.498717
            ],
            [
              -93.95919,
              36.498717
            ],
            [
              -93.92184,
              36.498718
            ],
            [
              -93.906128,
              36.498718
            ],
            [
              -93.86675809584749,
              36.498788514184895
            ],
            [
              -93.728022,
              36.499037
            ],
            [
              -93.727552,
              36.499055
            ],
            [
              -93.718893,
              36.499178
            ],
            [
              -93.709956,
              36.499179
            ],
            [
              -93.700171,
              36.499135
            ],
            [
              -93.67922490554069,
              36.4990918028598
            ],
            [
              -93.5842819998356,
              36.498896002062004
            ],
            [
              -93.5738,
              36.820032
            ],
            [
              -93.612929,
              36.820562
            ],
            [
              -93.611853,
              36.88608
            ],
            [
              -93.611003,
              36.922831
            ],
            [
              -94.008605,
              36.930711
            ],
            [
              -94.06274,
              36.931775
            ],
            [
              -94.068898,
              36.747818
            ],
            [
              -94.067651,
              36.738374
            ],
            [
              -94.077089,
              36.49873
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "195",
      "COUNTYNS": "00758550",
      "AFFGEOID": "0500000US29195",
      "GEOID": "29195",
      "NAME": "Saline",
      "LSAD": "06",
      "ALAND": 1956716997,
      "AWATER": 29587339,
      "County_state": "Saline,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.477233,
              39.292796
            ],
            [
              -93.496377,
              38.942837
            ],
            [
              -93.273782,
              38.93512
            ],
            [
              -93.27376,
              38.935492
            ],
            [
              -93.105535,
              38.931503
            ],
            [
              -93.050453,
              38.928244
            ],
            [
              -93.048471,
              38.972418
            ],
            [
              -92.934569,
              39.064547
            ],
            [
              -92.907362,
              39.084206
            ],
            [
              -92.90897,
              39.095547
            ],
            [
              -92.934053,
              39.099329
            ],
            [
              -92.945232,
              39.107089
            ],
            [
              -92.94385,
              39.121738
            ],
            [
              -92.922615,
              39.130789
            ],
            [
              -92.906073,
              39.147639
            ],
            [
              -92.904802,
              39.166155
            ],
            [
              -92.890544,
              39.180379
            ],
            [
              -92.860333,
              39.20107
            ],
            [
              -92.849224,
              39.226218
            ],
            [
              -92.86523,
              39.235904
            ],
            [
              -92.896194,
              39.221897
            ],
            [
              -92.911669,
              39.223946
            ],
            [
              -92.919572,
              39.231615
            ],
            [
              -92.915469,
              39.253379
            ],
            [
              -92.923617,
              39.263376
            ],
            [
              -92.947403,
              39.270094
            ],
            [
              -92.958978,
              39.283041
            ],
            [
              -92.953273,
              39.298457
            ],
            [
              -92.959801,
              39.312526
            ],
            [
              -92.976803,
              39.31548
            ],
            [
              -93.023776,
              39.313115
            ],
            [
              -93.044755,
              39.317666
            ],
            [
              -93.072915,
              39.33552
            ],
            [
              -93.080289,
              39.371752
            ],
            [
              -93.104485,
              39.383656
            ],
            [
              -93.139631,
              39.378643
            ],
            [
              -93.164322,
              39.405007
            ],
            [
              -93.184755,
              39.411348
            ],
            [
              -93.200708,
              39.401787
            ],
            [
              -93.190199,
              39.381792
            ],
            [
              -93.192037,
              39.365705
            ],
            [
              -93.215349,
              39.338107
            ],
            [
              -93.230079,
              39.327877
            ],
            [
              -93.255922,
              39.337837
            ],
            [
              -93.277725,
              39.311549
            ],
            [
              -93.292635,
              39.309283
            ],
            [
              -93.332252,
              39.309606
            ],
            [
              -93.344109,
              39.297179
            ],
            [
              -93.343782,
              39.287104
            ],
            [
              -93.328962,
              39.265483
            ],
            [
              -93.333337,
              39.250012
            ],
            [
              -93.368136,
              39.23331
            ],
            [
              -93.396335,
              39.22634
            ],
            [
              -93.415226,
              39.234923
            ],
            [
              -93.402903,
              39.260054
            ],
            [
              -93.411449,
              39.267152
            ],
            [
              -93.444231,
              39.268525
            ],
            [
              -93.464985,
              39.29237
            ],
            [
              -93.477233,
              39.292796
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "183",
      "COUNTYNS": "00758546",
      "AFFGEOID": "0500000US29183",
      "GEOID": "29183",
      "NAME": "St. Charles",
      "LSAD": "06",
      "ALAND": 1451493239,
      "AWATER": 83353121,
      "County_state": "St. Charles,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.96303,
              38.70148
            ],
            [
              -90.964461,
              38.547545
            ],
            [
              -90.923303,
              38.534134
            ],
            [
              -90.894922,
              38.540963
            ],
            [
              -90.849841,
              38.562257
            ],
            [
              -90.812988,
              38.585554
            ],
            [
              -90.773622,
              38.594617
            ],
            [
              -90.75609,
              38.611318
            ],
            [
              -90.750703,
              38.625103
            ],
            [
              -90.742412,
              38.634563
            ],
            [
              -90.732328,
              38.639302
            ],
            [
              -90.708809,
              38.645782
            ],
            [
              -90.703831,
              38.656525
            ],
            [
              -90.678678,
              38.666346
            ],
            [
              -90.662749,
              38.687439
            ],
            [
              -90.656757,
              38.689223
            ],
            [
              -90.636197,
              38.690855
            ],
            [
              -90.609991,
              38.68345
            ],
            [
              -90.571822,
              38.681052
            ],
            [
              -90.548659,
              38.682755
            ],
            [
              -90.539633,
              38.686566
            ],
            [
              -90.530363,
              38.69863
            ],
            [
              -90.53134,
              38.731178
            ],
            [
              -90.492149,
              38.751793
            ],
            [
              -90.482299,
              38.764348
            ],
            [
              -90.475574,
              38.783016
            ],
            [
              -90.473188,
              38.790978
            ],
            [
              -90.440924,
              38.827421
            ],
            [
              -90.430975,
              38.829806
            ],
            [
              -90.393111,
              38.823105
            ],
            [
              -90.362163,
              38.82786
            ],
            [
              -90.342414,
              38.861749
            ],
            [
              -90.332022,
              38.886516
            ],
            [
              -90.320213,
              38.891148
            ],
            [
              -90.298743,
              38.887759
            ],
            [
              -90.256136,
              38.852128
            ],
            [
              -90.236557,
              38.843272
            ],
            [
              -90.183058,
              38.818053
            ],
            [
              -90.140641,
              38.829055
            ],
            [
              -90.117707,
              38.805748
            ],
            [
              -90.114707,
              38.815048
            ],
            [
              -90.109107,
              38.837448
            ],
            [
              -90.109407,
              38.843548
            ],
            [
              -90.113327,
              38.849306
            ],
            [
              -90.151508,
              38.867148
            ],
            [
              -90.166409,
              38.876348
            ],
            [
              -90.186909,
              38.885048
            ],
            [
              -90.190309,
              38.886248
            ],
            [
              -90.19521,
              38.886748
            ],
            [
              -90.19761,
              38.887648
            ],
            [
              -90.2092211508743,
              38.8969944327171
            ],
            [
              -90.213519,
              38.900454
            ],
            [
              -90.223041,
              38.907389
            ],
            [
              -90.230336,
              38.91086
            ],
            [
              -90.241703,
              38.914828
            ],
            [
              -90.250248,
              38.919344
            ],
            [
              -90.254033,
              38.920489
            ],
            [
              -90.256993,
              38.920763
            ],
            [
              -90.262792,
              38.920344
            ],
            [
              -90.269872,
              38.922556
            ],
            [
              -90.2758247170787,
              38.92346469217149
            ],
            [
              -90.277471,
              38.923716
            ],
            [
              -90.283712,
              38.924048
            ],
            [
              -90.298711,
              38.923395
            ],
            [
              -90.306113,
              38.923525
            ],
            [
              -90.309454,
              38.92412
            ],
            [
              -90.317572,
              38.927912
            ],
            [
              -90.324179,
              38.929827
            ],
            [
              -90.333533,
              38.933489
            ],
            [
              -90.346442,
              38.94079
            ],
            [
              -90.383126,
              38.955453
            ],
            [
              -90.385768377236,
              38.9564075041174
            ],
            [
              -90.395816,
              38.960037
            ],
            [
              -90.406367,
              38.962554
            ],
            [
              -90.413108,
              38.96333
            ],
            [
              -90.424726,
              38.963785
            ],
            [
              -90.433745,
              38.965526
            ],
            [
              -90.440078,
              38.967364
            ],
            [
              -90.450792,
              38.967764
            ],
            [
              -90.450809944078,
              38.967758684265696
            ],
            [
              -90.462411,
              38.964322
            ],
            [
              -90.467784,
              38.961809
            ],
            [
              -90.472122,
              38.958838
            ],
            [
              -90.482419,
              38.94446
            ],
            [
              -90.483339,
              38.942133
            ],
            [
              -90.483452,
              38.940436
            ],
            [
              -90.482725,
              38.934712
            ],
            [
              -90.486974,
              38.925982
            ],
            [
              -90.500117,
              38.910408
            ],
            [
              -90.507451,
              38.902767
            ],
            [
              -90.516963,
              38.898818
            ],
            [
              -90.531118,
              38.887078
            ],
            [
              -90.54403,
              38.87505
            ],
            [
              -90.545872,
              38.874008
            ],
            [
              -90.555693,
              38.870785
            ],
            [
              -90.5646273539146,
              38.8691912244213
            ],
            [
              -90.566557,
              38.868847
            ],
            [
              -90.576719,
              38.868336
            ],
            [
              -90.583388,
              38.86903
            ],
            [
              -90.595354,
              38.87505
            ],
            [
              -90.625122,
              38.888654
            ],
            [
              -90.628485,
              38.891617
            ],
            [
              -90.635896,
              38.903941
            ],
            [
              -90.639917,
              38.908272
            ],
            [
              -90.647988,
              38.912106
            ],
            [
              -90.653164,
              38.916141
            ],
            [
              -90.657254,
              38.92027
            ],
            [
              -90.6614,
              38.924989
            ],
            [
              -90.663372,
              38.928042
            ],
            [
              -90.665565,
              38.934179
            ],
            [
              -90.6655700805267,
              38.934198408333195
            ],
            [
              -90.694001,
              38.933863
            ],
            [
              -90.683773,
              38.915986
            ],
            [
              -90.707322,
              38.92149
            ],
            [
              -90.708149,
              38.902591
            ],
            [
              -90.73367,
              38.914272
            ],
            [
              -90.723701,
              38.925932
            ],
            [
              -90.746086,
              38.928686
            ],
            [
              -90.754113,
              38.920747
            ],
            [
              -90.77821,
              38.922488
            ],
            [
              -90.804418,
              38.911139
            ],
            [
              -90.805944,
              38.901733
            ],
            [
              -90.807577,
              38.87766
            ],
            [
              -90.817827,
              38.875966
            ],
            [
              -90.864216,
              38.887828
            ],
            [
              -90.918995,
              38.887948
            ],
            [
              -90.920037,
              38.887548
            ],
            [
              -90.920795,
              38.887404
            ],
            [
              -90.922121,
              38.887426
            ],
            [
              -90.922461,
              38.887529
            ],
            [
              -90.922967,
              38.887943
            ],
            [
              -90.924164,
              38.888287
            ],
            [
              -90.924742,
              38.888571
            ],
            [
              -90.925191,
              38.888631
            ],
            [
              -90.9256,
              38.888474
            ],
            [
              -90.925663,
              38.88814
            ],
            [
              -90.92548,
              38.887743
            ],
            [
              -90.925664,
              38.887118
            ],
            [
              -90.926154,
              38.886695
            ],
            [
              -90.926652,
              38.886546
            ],
            [
              -90.927061,
              38.886587
            ],
            [
              -90.927588,
              38.886979
            ],
            [
              -90.927819,
              38.887408
            ],
            [
              -90.928588,
              38.887561
            ],
            [
              -90.929266,
              38.886998
            ],
            [
              -90.930592,
              38.886626
            ],
            [
              -90.931278,
              38.886498
            ],
            [
              -90.931738,
              38.885945
            ],
            [
              -90.93232,
              38.885996
            ],
            [
              -90.932831,
              38.885903
            ],
            [
              -90.93322,
              38.885146
            ],
            [
              -90.933455,
              38.885049
            ],
            [
              -90.933877,
              38.885184
            ],
            [
              -90.934073,
              38.885335
            ],
            [
              -90.934499,
              38.885845
            ],
            [
              -90.934518,
              38.885947
            ],
            [
              -90.934382,
              38.886044
            ],
            [
              -90.934342,
              38.886325
            ],
            [
              -90.934392,
              38.886458
            ],
            [
              -90.934594,
              38.886525
            ],
            [
              -90.93477,
              38.886772
            ],
            [
              -90.935109,
              38.886999
            ],
            [
              -90.93608,
              38.887325
            ],
            [
              -90.936636,
              38.887575
            ],
            [
              -90.937278,
              38.888086
            ],
            [
              -90.937757,
              38.888199
            ],
            [
              -90.937979,
              38.888163
            ],
            [
              -90.938154,
              38.888061
            ],
            [
              -90.938414,
              38.887494
            ],
            [
              -90.938829,
              38.887224
            ],
            [
              -90.939088,
              38.886655
            ],
            [
              -90.939197,
              38.88654
            ],
            [
              -90.939455,
              38.886475
            ],
            [
              -90.939583,
              38.88651
            ],
            [
              -90.940172,
              38.886472
            ],
            [
              -90.940387,
              38.88653
            ],
            [
              -90.940639,
              38.886685
            ],
            [
              -90.940938,
              38.887169
            ],
            [
              -90.941079,
              38.887311
            ],
            [
              -90.94187,
              38.887797
            ],
            [
              -90.942362,
              38.888265
            ],
            [
              -90.942541,
              38.888633
            ],
            [
              -90.943401,
              38.889399
            ],
            [
              -90.943698,
              38.889565
            ],
            [
              -90.944041,
              38.889986
            ],
            [
              -90.94439,
              38.890317
            ],
            [
              -90.944534,
              38.890618
            ],
            [
              -90.944515,
              38.891109
            ],
            [
              -90.944714,
              38.891387
            ],
            [
              -90.945552,
              38.891944
            ],
            [
              -90.945801,
              38.892035
            ],
            [
              -90.946436,
              38.892088
            ],
            [
              -90.946644,
              38.892162
            ],
            [
              -90.946988,
              38.892416
            ],
            [
              -90.946961,
              38.892545
            ],
            [
              -90.94709,
              38.892925
            ],
            [
              -90.947061,
              38.893101
            ],
            [
              -90.947125,
              38.893347
            ],
            [
              -90.947309,
              38.893478
            ],
            [
              -90.95645,
              38.894164
            ],
            [
              -90.958536,
              38.870865
            ],
            [
              -90.962577,
              38.742262
            ],
            [
              -90.96303,
              38.70148
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "181",
      "COUNTYNS": "00758545",
      "AFFGEOID": "0500000US29181",
      "GEOID": "29181",
      "NAME": "Ripley",
      "LSAD": "06",
      "ALAND": 1630498740,
      "AWATER": 5443027,
      "County_state": "Ripley,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.119618,
              36.780081
            ],
            [
              -91.126529,
              36.497712
            ],
            [
              -91.096277,
              36.497893
            ],
            [
              -91.09588,
              36.49787
            ],
            [
              -91.017974,
              36.498062
            ],
            [
              -91.008558,
              36.49827
            ],
            [
              -90.98125406602479,
              36.498358822556895
            ],
            [
              -90.963063,
              36.498418
            ],
            [
              -90.960648,
              36.498426
            ],
            [
              -90.87922,
              36.498378
            ],
            [
              -90.876867,
              36.498423
            ],
            [
              -90.876567,
              36.498313
            ],
            [
              -90.873775,
              36.498074
            ],
            [
              -90.850434,
              36.498548
            ],
            [
              -90.7843982380622,
              36.4985237150037
            ],
            [
              -90.782454,
              36.498523
            ],
            [
              -90.765672,
              36.498494
            ],
            [
              -90.711226,
              36.498318
            ],
            [
              -90.693005,
              36.49851
            ],
            [
              -90.653246,
              36.498488
            ],
            [
              -90.648494,
              36.498447
            ],
            [
              -90.612554,
              36.498559
            ],
            [
              -90.60545,
              36.498459
            ],
            [
              -90.5943,
              36.498459
            ],
            [
              -90.585342,
              36.498497
            ],
            [
              -90.57617955600901,
              36.4984463732781
            ],
            [
              -90.574759,
              36.59185
            ],
            [
              -90.593005,
              36.592445
            ],
            [
              -90.592166,
              36.679368
            ],
            [
              -90.628387,
              36.679544
            ],
            [
              -90.627663,
              36.766408
            ],
            [
              -90.627517,
              36.810954
            ],
            [
              -90.662617,
              36.811453
            ],
            [
              -90.895724,
              36.812854
            ],
            [
              -90.946826,
              36.817954
            ],
            [
              -91.025335,
              36.822148
            ],
            [
              -91.115926,
              36.823672
            ],
            [
              -91.117458,
              36.781814
            ],
            [
              -91.119618,
              36.780081
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "217",
      "COUNTYNS": "00758561",
      "AFFGEOID": "0500000US29217",
      "GEOID": "29217",
      "NAME": "Vernon",
      "LSAD": "06",
      "ALAND": 2140348947,
      "AWATER": 27015775,
      "County_state": "Vernon,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.617808,
              37.729707
            ],
            [
              -94.617975,
              37.722176
            ],
            [
              -94.617805,
              37.690178
            ],
            [
              -94.617651,
              37.687671
            ],
            [
              -94.617687,
              37.686653
            ],
            [
              -94.617885,
              37.682214
            ],
            [
              -94.617734,
              37.673127
            ],
            [
              -94.6177338243197,
              37.6731053668666
            ],
            [
              -94.617576,
              37.653671
            ],
            [
              -94.6175754334997,
              37.6535765775648
            ],
            [
              -94.073524,
              37.639722
            ],
            [
              -94.073117,
              37.654243
            ],
            [
              -94.068716,
              37.755568
            ],
            [
              -94.073342,
              37.755706
            ],
            [
              -94.065675,
              37.900987
            ],
            [
              -94.058285,
              38.036627
            ],
            [
              -94.082057,
              38.031581
            ],
            [
              -94.101912,
              38.048105
            ],
            [
              -94.109911,
              38.03732
            ],
            [
              -94.115478,
              38.048433
            ],
            [
              -94.135238,
              38.058118
            ],
            [
              -94.156122,
              38.057284
            ],
            [
              -94.189502,
              38.04548
            ],
            [
              -94.198597,
              38.036673
            ],
            [
              -94.195358,
              38.053688
            ],
            [
              -94.209544,
              38.052158
            ],
            [
              -94.214581,
              38.034998
            ],
            [
              -94.223889,
              38.040348
            ],
            [
              -94.224849,
              38.027337
            ],
            [
              -94.237787,
              38.026017
            ],
            [
              -94.240429,
              38.038018
            ],
            [
              -94.230543,
              38.045801
            ],
            [
              -94.240643,
              38.050821
            ],
            [
              -94.6140548972092,
              38.0600558584152
            ],
            [
              -94.6139813464919,
              38.0370573442828
            ],
            [
              -94.613981,
              38.036949
            ],
            [
              -94.614212,
              37.992462
            ],
            [
              -94.614465,
              37.987799
            ],
            [
              -94.614557,
              37.971037
            ],
            [
              -94.614562,
              37.951517
            ],
            [
              -94.614594,
              37.949978
            ],
            [
              -94.614612,
              37.944362
            ],
            [
              -94.614754,
              37.940769
            ],
            [
              -94.614835,
              37.9367
            ],
            [
              -94.614778,
              37.9342
            ],
            [
              -94.615181,
              37.915944
            ],
            [
              -94.615393,
              37.906392
            ],
            [
              -94.615469,
              37.901775
            ],
            [
              -94.615706,
              37.886843
            ],
            [
              -94.615921,
              37.878331
            ],
            [
              -94.615834,
              37.87251
            ],
            [
              -94.616,
              37.863126
            ],
            [
              -94.6162739579757,
              37.8516506335261
            ],
            [
              -94.616426,
              37.845282
            ],
            [
              -94.61645,
              37.83756
            ],
            [
              -94.616862,
              37.819456
            ],
            [
              -94.617721,
              37.77297
            ],
            [
              -94.617808,
              37.729707
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "023",
      "COUNTYNS": "00758466",
      "AFFGEOID": "0500000US29023",
      "GEOID": "29023",
      "NAME": "Butler",
      "LSAD": "06",
      "ALAND": 1799185018,
      "AWATER": 11195137,
      "County_state": "Butler,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.678562,
              36.92649
            ],
            [
              -90.679729,
              36.883107
            ],
            [
              -90.661244,
              36.882909
            ],
            [
              -90.662617,
              36.811453
            ],
            [
              -90.627517,
              36.810954
            ],
            [
              -90.627663,
              36.766408
            ],
            [
              -90.628387,
              36.679544
            ],
            [
              -90.592166,
              36.679368
            ],
            [
              -90.593005,
              36.592445
            ],
            [
              -90.574759,
              36.59185
            ],
            [
              -90.57617955600901,
              36.4984463732781
            ],
            [
              -90.576112,
              36.498446
            ],
            [
              -90.50016,
              36.498399
            ],
            [
              -90.495027,
              36.498371
            ],
            [
              -90.494575,
              36.498368
            ],
            [
              -90.339892,
              36.498213
            ],
            [
              -90.228943,
              36.497771
            ],
            [
              -90.2207319861011,
              36.497857683437594
            ],
            [
              -90.219942,
              36.522033
            ],
            [
              -90.209458,
              36.530121
            ],
            [
              -90.209954,
              36.542034
            ],
            [
              -90.188746,
              36.544624
            ],
            [
              -90.182802,
              36.561768
            ],
            [
              -90.187018,
              36.587137
            ],
            [
              -90.181673,
              36.596704
            ],
            [
              -90.175915,
              36.592736
            ],
            [
              -90.168559,
              36.614975
            ],
            [
              -90.156044,
              36.617174
            ],
            [
              -90.148398,
              36.630656
            ],
            [
              -90.135556,
              36.659771
            ],
            [
              -90.149153,
              36.677162
            ],
            [
              -90.139804,
              36.696072
            ],
            [
              -90.156722,
              36.718085
            ],
            [
              -90.176481,
              36.741912
            ],
            [
              -90.175021,
              36.751333
            ],
            [
              -90.18744,
              36.755814
            ],
            [
              -90.19071,
              36.772329
            ],
            [
              -90.204205,
              36.772262
            ],
            [
              -90.197911,
              36.804143
            ],
            [
              -90.215884,
              36.814272
            ],
            [
              -90.224467,
              36.831273
            ],
            [
              -90.216321,
              36.842811
            ],
            [
              -90.22973,
              36.850621
            ],
            [
              -90.256856,
              36.879162
            ],
            [
              -90.245161,
              36.89255
            ],
            [
              -90.253266,
              36.899093
            ],
            [
              -90.258563,
              36.922043
            ],
            [
              -90.338508,
              36.925653
            ],
            [
              -90.352708,
              36.926653
            ],
            [
              -90.678562,
              36.92649
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "031",
      "COUNTYNS": "00758470",
      "AFFGEOID": "0500000US29031",
      "GEOID": "29031",
      "NAME": "Cape Girardeau",
      "LSAD": "06",
      "ALAND": 1498398283,
      "AWATER": 20174864,
      "County_state": "Cape Girardeau,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -89.866794,
              37.216352
            ],
            [
              -89.866627,
              37.126227
            ],
            [
              -89.765211,
              37.126066
            ],
            [
              -89.758512,
              37.140528
            ],
            [
              -89.721783,
              37.14056
            ],
            [
              -89.703797,
              37.155161
            ],
            [
              -89.703805,
              37.183965
            ],
            [
              -89.68612,
              37.19848
            ],
            [
              -89.649464,
              37.205982
            ],
            [
              -89.649659,
              37.218268
            ],
            [
              -89.632917,
              37.218279
            ],
            [
              -89.620457,
              37.21828
            ],
            [
              -89.620256,
              37.233008
            ],
            [
              -89.583327,
              37.233056
            ],
            [
              -89.54612,
              37.240219
            ],
            [
              -89.545986,
              37.247505
            ],
            [
              -89.499992,
              37.245268
            ],
            [
              -89.4887281875826,
              37.251507483022294
            ],
            [
              -89.489915,
              37.251315
            ],
            [
              -89.496386,
              37.258474
            ],
            [
              -89.502303,
              37.263738
            ],
            [
              -89.506773,
              37.268537
            ],
            [
              -89.513905,
              37.277164
            ],
            [
              -89.517032,
              37.28192
            ],
            [
              -89.51834,
              37.285497
            ],
            [
              -89.518393,
              37.289354
            ],
            [
              -89.517692,
              37.29204
            ],
            [
              -89.515741,
              37.295362
            ],
            [
              -89.514605,
              37.299323
            ],
            [
              -89.514042,
              37.303776
            ],
            [
              -89.511842,
              37.310825
            ],
            [
              -89.509699,
              37.31426
            ],
            [
              -89.508174,
              37.315662
            ],
            [
              -89.49909,
              37.32149
            ],
            [
              -89.49516,
              37.324795
            ],
            [
              -89.491194,
              37.331361
            ],
            [
              -89.4893198828342,
              37.333079297465396
            ],
            [
              -89.489005,
              37.333368
            ],
            [
              -89.4859297365844,
              37.3348292953819
            ],
            [
              -89.484211,
              37.335646
            ],
            [
              -89.474569,
              37.338165
            ],
            [
              -89.454723,
              37.339283
            ],
            [
              -89.447556,
              37.340475
            ],
            [
              -89.43604,
              37.344441
            ],
            [
              -89.432836,
              37.347056
            ],
            [
              -89.429738,
              37.351956
            ],
            [
              -89.428185,
              37.356158
            ],
            [
              -89.422037,
              37.38053
            ],
            [
              -89.421054,
              37.387668
            ],
            [
              -89.42132,
              37.392077
            ],
            [
              -89.422465,
              37.397132
            ],
            [
              -89.42594,
              37.407471
            ],
            [
              -89.43413,
              37.426847
            ],
            [
              -89.439769,
              37.4372
            ],
            [
              -89.443493,
              37.442129
            ],
            [
              -89.450969,
              37.450069
            ],
            [
              -89.471201,
              37.466473
            ],
            [
              -89.475525,
              37.471388
            ],
            [
              -89.492051,
              37.494008
            ],
            [
              -89.497689,
              37.504948
            ],
            [
              -89.500231,
              37.512954
            ],
            [
              -89.502917,
              37.51787
            ],
            [
              -89.507459,
              37.524322
            ],
            [
              -89.5124,
              37.52981
            ],
            [
              -89.516447,
              37.535558
            ],
            [
              -89.517051,
              37.537278
            ],
            [
              -89.521093,
              37.553805
            ],
            [
              -89.521925,
              37.560735
            ],
            [
              -89.5217295671219,
              37.5662095222196
            ],
            [
              -89.54339,
              37.567009
            ],
            [
              -89.555365,
              37.576819
            ],
            [
              -89.581474,
              37.571011
            ],
            [
              -89.591972,
              37.574613
            ],
            [
              -89.602429,
              37.603024
            ],
            [
              -89.625785,
              37.588462
            ],
            [
              -89.647518,
              37.59765
            ],
            [
              -89.665362,
              37.598197
            ],
            [
              -89.672802,
              37.583761
            ],
            [
              -89.684126,
              37.593541
            ],
            [
              -89.711123,
              37.599428
            ],
            [
              -89.719313,
              37.587857
            ],
            [
              -89.727266,
              37.599833
            ],
            [
              -89.748588,
              37.590217
            ],
            [
              -89.802206,
              37.593679
            ],
            [
              -89.802261,
              37.602706
            ],
            [
              -89.861123,
              37.599286
            ],
            [
              -89.86326,
              37.219035
            ],
            [
              -89.866794,
              37.216352
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "073",
      "COUNTYNS": "00758491",
      "AFFGEOID": "0500000US29073",
      "GEOID": "29073",
      "NAME": "Gasconade",
      "LSAD": "06",
      "ALAND": 1344215195,
      "AWATER": 18009291,
      "County_state": "Gasconade,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.644729,
              38.28851
            ],
            [
              -91.644893,
              38.153679
            ],
            [
              -91.630554,
              38.153519
            ],
            [
              -91.534384,
              38.152593
            ],
            [
              -91.535115,
              38.210976
            ],
            [
              -91.367482,
              38.209741
            ],
            [
              -91.365565,
              38.322086
            ],
            [
              -91.367037,
              38.331491
            ],
            [
              -91.36777,
              38.434604
            ],
            [
              -91.368417,
              38.688158
            ],
            [
              -91.369222,
              38.689538
            ],
            [
              -91.369192,
              38.699324
            ],
            [
              -91.397327,
              38.708444
            ],
            [
              -91.418637,
              38.709778
            ],
            [
              -91.442845,
              38.709182
            ],
            [
              -91.4576,
              38.70627
            ],
            [
              -91.452796,
              38.709383
            ],
            [
              -91.472339,
              38.707621
            ],
            [
              -91.485101,
              38.713307
            ],
            [
              -91.496533,
              38.709464
            ],
            [
              -91.502407,
              38.702875
            ],
            [
              -91.502602,
              38.695
            ],
            [
              -91.547142,
              38.676406
            ],
            [
              -91.587665,
              38.682185
            ],
            [
              -91.640372,
              38.703792
            ],
            [
              -91.643133,
              38.46528
            ],
            [
              -91.643556,
              38.437723
            ],
            [
              -91.644729,
              38.28851
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "29",
      "COUNTYFP": "099",
      "COUNTYNS": "00758504",
      "AFFGEOID": "0500000US29099",
      "GEOID": "29099",
      "NAME": "Jefferson",
      "LSAD": "06",
      "ALAND": 1700691208,
      "AWATER": 19797687,
      "County_state": "Jefferson,29"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -90.762804,
              38.29555
            ],
            [
              -90.780185,
              38.204112
            ],
            [
              -90.681399,
              38.102681
            ],
            [
              -90.684776,
              38.08627
            ],
            [
              -90.63998,
              38.076548
            ],
            [
              -90.632063,
              38.074201
            ],
            [
              -90.612011,
              38.069585
            ],
            [
              -90.621508,
              38.040357
            ],
            [
              -90.612787,
              38.015895
            ],
            [
              -90.628192,
              38.007962
            ],
            [
              -90.583324,
              38.003499
            ],
            [
              -90.416022,
              38.042315
            ],
            [
              -90.32939,
              38.09436
            ],
            [
              -90.295999,
              38.087979
            ],
            [
              -90.281651,
              38.103391
            ],
            [
              -90.249858,
              38.11455
            ],
            [
              -90.25266473951389,
              38.1278129416306
            ],
            [
              -90.274928,
              38.157615
            ],
            [
              -90.283091,
              38.164447
            ],
            [
              -90.290765,
              38.170453
            ],
            [
              -90.30049,
              38.175246
            ],
            [
              -90.31063,
              38.178572
            ],
            [
              -90.316839,
              38.179456
            ],
            [
              -90.322353,
              38.181593
            ],
            [
              -90.331554,
              38.18758
            ],
            [
              -90.334258,
              38.189932
            ],
            [
              -90.353902,
              38.213855
            ],
            [
              -90.3554305754695,
              38.216305829446696
            ],
            [
              -90.356176,
              38.217501
            ],
            [
              -90.359559,
              38.224525
            ],
            [
              -90.363926,
              38.236355
            ],
            [
              -90.367013,
              38.250054
            ],
            [
              -90.367764,
              38.255807
            ],
            [
              -90.370892,
              38.267441
            ],
            [
              -90.371869,
              38.273926
            ],
            [
              -90.373929,
              38.281853
            ],
            [
              -90.373819,
              38.294454
            ],
            [
              -90.371719,
              38.304354
            ],
            [
              -90.372519,
              38.323354
            ],
            [
              -90.370819,
              38.333554
            ],
            [
              -90.368219,
              38.340254
            ],
            [
              -90.356318,
              38.360354
            ],
            [
              -90.349743,
              38.377609
            ],
            [
              -90.346118,
              38.381853
            ],
            [
              -90.34348267213109,
              38.38501539344259
            ],
            [
              -90.343118,
              38.385453
            ],
            [
              -90.3407573658894,
              38.387506287665495
            ],
            [
              -90.348145,
              38.406898
            ],
            [
              -90.347117,
              38.435953
            ],
            [
              -90.336443,
              38.453978
            ],
            [
              -90.376783,
              38.452868
            ],
            [
              -90.414408,
              38.456971
            ],
            [
              -90.422231,
              38.483359
            ],
            [
              -90.414513,
              38.480821
            ],
            [
              -90.405262,
              38.501153
            ],
            [
              -90.466184,
              38.50181
            ],
            [
              -90.49139,
              38.501187
            ],
            [
              -90.512522,
              38.501454
            ],
            [
              -90.51494,
              38.501455
            ],
            [
              -90.591123,
              38.501652
            ],
            [
              -90.602652,
              38.479617
            ],
            [
              -90.613685,
              38.471328
            ],
            [
              -90.633623,
              38.485752
            ],
            [
              -90.653829,
              38.487547
            ],
            [
              -90.660703,
              38.479195
            ],
            [
              -90.654389,
              38.457156
            ],
            [
              -90.678775,
              38.445877
            ],
            [
              -90.685958,
              38.448824
            ],
            [
              -90.696027,
              38.471252
            ],
            [
              -90.73203,
              38.482153
            ],
            [
              -90.736311,
              38.466643
            ],
            [
              -90.73763,
              38.415453
            ],
            [
              -90.7494,
              38.35819
            ],
            [
              -90.755601,
              38.330052
            ],
            [
              -90.762804,
              38.29555
            ]
          ]
        ]
      ]
    }
  }
]
};