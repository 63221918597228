export const counties_nm = {
  "type":"FeatureCollection","name":"nm_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "35",
      "COUNTYFP": "029",
      "COUNTYNS": "00933057",
      "AFFGEOID": "0500000US35029",
      "GEOID": "35029",
      "NAME": "Luna",
      "LSAD": "06",
      "ALAND": 7679944778,
      "AWATER": 433660,
      "County_state": "Luna,35"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -108.22981,
              32.207159
            ],
            [
              -108.229951,
              32.07987
            ],
            [
              -108.216964,
              32.079863
            ],
            [
              -108.217143,
              31.864139
            ],
            [
              -108.217134,
              31.820475
            ],
            [
              -108.210014,
              31.820499
            ],
            [
              -108.208394,
              31.783599
            ],
            [
              -108.05135,
              31.783586
            ],
            [
              -107.848354,
              31.783632
            ],
            [
              -107.64113,
              31.783716
            ],
            [
              -107.422495,
              31.783599
            ],
            [
              -107.422246,
              31.783599
            ],
            [
              -107.296824,
              31.783762
            ],
            [
              -107.296793744203,
              31.783761974571004
            ],
            [
              -107.296594,
              32.043154
            ],
            [
              -107.298743,
              32.082348
            ],
            [
              -107.298291,
              32.220128
            ],
            [
              -107.299489,
              32.595834
            ],
            [
              -107.299631,
              32.60537
            ],
            [
              -107.608485,
              32.605449
            ],
            [
              -107.923997,
              32.604379
            ],
            [
              -107.923766,
              32.517416
            ],
            [
              -108.052231,
              32.516927
            ],
            [
              -108.229343,
              32.516837
            ],
            [
              -108.22981,
              32.207159
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "35",
      "COUNTYFP": "057",
      "COUNTYNS": "00929112",
      "AFFGEOID": "0500000US35057",
      "GEOID": "35057",
      "NAME": "Torrance",
      "LSAD": "06",
      "ALAND": 8664074710,
      "AWATER": 2303485,
      "County_state": "Torrance,35"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -106.464873,
              34.608385
            ],
            [
              -106.46979,
              34.521217
            ],
            [
              -106.416881,
              34.521715
            ],
            [
              -106.416924,
              34.439687
            ],
            [
              -106.415972,
              34.259822
            ],
            [
              -106.101744,
              34.259983
            ],
            [
              -105.925968,
              34.25999
            ],
            [
              -105.313218,
              34.260031
            ],
            [
              -105.313072,
              34.347205
            ],
            [
              -105.312765,
              34.606951
            ],
            [
              -105.290396,
              34.606766
            ],
            [
              -105.290791,
              35.04203
            ],
            [
              -105.682848,
              35.041633
            ],
            [
              -105.714419,
              35.041605
            ],
            [
              -106.029524,
              35.040678
            ],
            [
              -106.194713,
              35.040256
            ],
            [
              -106.243874,
              35.040003
            ],
            [
              -106.243762,
              34.953355
            ],
            [
              -106.234775,
              34.953394
            ],
            [
              -106.156793,
              34.953426
            ],
            [
              -106.14982,
              34.949915
            ],
            [
              -106.149889,
              34.91902
            ],
            [
              -106.149737,
              34.870142
            ],
            [
              -106.360659,
              34.870331
            ],
            [
              -106.411781,
              34.870252
            ],
            [
              -106.463868,
              34.870194
            ],
            [
              -106.464546,
              34.810517
            ],
            [
              -106.466707,
              34.717011
            ],
            [
              -106.464873,
              34.608385
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "35",
      "COUNTYFP": "017",
      "COUNTYNS": "00915980",
      "AFFGEOID": "0500000US35017",
      "GEOID": "35017",
      "NAME": "Grant",
      "LSAD": "06",
      "ALAND": 10259427302,
      "AWATER": 15346268,
      "County_state": "Grant,35"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -109.047453,
              33.069427
            ],
            [
              -109.04748,
              33.06842
            ],
            [
              -109.047117279572,
              32.777794004252
            ],
            [
              -108.854581,
              32.777276
            ],
            [
              -108.852919,
              32.603374
            ],
            [
              -108.738055,
              32.603505
            ],
            [
              -108.646523,
              32.60373
            ],
            [
              -108.64677,
              32.516521
            ],
            [
              -108.537011,
              32.516618
            ],
            [
              -108.536929,
              32.270711
            ],
            [
              -108.537676,
              32.079832
            ],
            [
              -108.52463,
              32.079828
            ],
            [
              -108.524538,
              31.863614
            ],
            [
              -108.217143,
              31.864139
            ],
            [
              -108.216964,
              32.079863
            ],
            [
              -108.229951,
              32.07987
            ],
            [
              -108.22981,
              32.207159
            ],
            [
              -108.229343,
              32.516837
            ],
            [
              -108.052231,
              32.516927
            ],
            [
              -107.923766,
              32.517416
            ],
            [
              -107.923997,
              32.604379
            ],
            [
              -107.608485,
              32.605449
            ],
            [
              -107.716925,
              32.622555
            ],
            [
              -107.736949,
              32.629494
            ],
            [
              -107.725321,
              32.661081
            ],
            [
              -107.727446,
              32.692113
            ],
            [
              -107.719567,
              32.697474
            ],
            [
              -107.715276,
              32.72157
            ],
            [
              -107.722858,
              32.736119
            ],
            [
              -107.716186,
              32.762676
            ],
            [
              -107.721387,
              32.778077
            ],
            [
              -107.736887,
              32.784376
            ],
            [
              -107.749487,
              32.805076
            ],
            [
              -107.745288,
              32.825474
            ],
            [
              -107.758388,
              32.846075
            ],
            [
              -107.773088,
              32.853775
            ],
            [
              -107.778577,
              32.897309
            ],
            [
              -107.766172,
              32.910802
            ],
            [
              -107.765332,
              32.911816
            ],
            [
              -107.766038,
              32.913474
            ],
            [
              -107.765114,
              32.915211
            ],
            [
              -107.766936,
              32.917116
            ],
            [
              -107.766247,
              32.919689
            ],
            [
              -107.767312,
              32.921695
            ],
            [
              -107.769806,
              32.924704
            ],
            [
              -107.769957,
              32.92644
            ],
            [
              -107.772535,
              32.928815
            ],
            [
              -107.774761,
              32.929719
            ],
            [
              -107.775711,
              32.930822
            ],
            [
              -107.775022,
              32.933462
            ],
            [
              -107.775759,
              32.937539
            ],
            [
              -107.772767,
              32.938606
            ],
            [
              -107.770366,
              32.940942
            ],
            [
              -107.771276,
              32.942146
            ],
            [
              -107.774064,
              32.950567
            ],
            [
              -107.775968,
              32.95234
            ],
            [
              -107.780548,
              32.952776
            ],
            [
              -107.829772,
              32.963918
            ],
            [
              -107.856672,
              33.002539
            ],
            [
              -107.855199,
              33.041117
            ],
            [
              -107.851489,
              33.056193
            ],
            [
              -107.836192,
              33.067763
            ],
            [
              -107.845804,
              33.086036
            ],
            [
              -107.842127,
              33.095818
            ],
            [
              -107.852912,
              33.134321
            ],
            [
              -107.856292,
              33.165318
            ],
            [
              -107.83965,
              33.182769
            ],
            [
              -107.861704,
              33.189751
            ],
            [
              -107.865009,
              33.199974
            ],
            [
              -108.000598,
              33.201253
            ],
            [
              -108.449988,
              33.200997
            ],
            [
              -108.543178,
              33.207955
            ],
            [
              -108.646597,
              33.209342
            ],
            [
              -108.956436,
              33.207544
            ],
            [
              -109.04723688070601,
              33.2088950306819
            ],
            [
              -109.047116,
              33.137995
            ],
            [
              -109.047117,
              33.137559
            ],
            [
              -109.047013,
              33.092917
            ],
            [
              -109.046905,
              33.091931
            ],
            [
              -109.047453,
              33.069427
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "35",
      "COUNTYFP": "051",
      "COUNTYNS": "01702370",
      "AFFGEOID": "0500000US35051",
      "GEOID": "35051",
      "NAME": "Sierra",
      "LSAD": "06",
      "ALAND": 10828937018,
      "AWATER": 147987561,
      "County_state": "Sierra,35"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -108.000602,
              33.478014
            ],
            [
              -108.000598,
              33.201253
            ],
            [
              -107.865009,
              33.199974
            ],
            [
              -107.861704,
              33.189751
            ],
            [
              -107.83965,
              33.182769
            ],
            [
              -107.856292,
              33.165318
            ],
            [
              -107.852912,
              33.134321
            ],
            [
              -107.842127,
              33.095818
            ],
            [
              -107.845804,
              33.086036
            ],
            [
              -107.836192,
              33.067763
            ],
            [
              -107.851489,
              33.056193
            ],
            [
              -107.855199,
              33.041117
            ],
            [
              -107.856672,
              33.002539
            ],
            [
              -107.829772,
              32.963918
            ],
            [
              -107.780548,
              32.952776
            ],
            [
              -107.775968,
              32.95234
            ],
            [
              -107.774064,
              32.950567
            ],
            [
              -107.771276,
              32.942146
            ],
            [
              -107.770366,
              32.940942
            ],
            [
              -107.772767,
              32.938606
            ],
            [
              -107.775759,
              32.937539
            ],
            [
              -107.775022,
              32.933462
            ],
            [
              -107.775711,
              32.930822
            ],
            [
              -107.774761,
              32.929719
            ],
            [
              -107.772535,
              32.928815
            ],
            [
              -107.769957,
              32.92644
            ],
            [
              -107.769806,
              32.924704
            ],
            [
              -107.767312,
              32.921695
            ],
            [
              -107.766247,
              32.919689
            ],
            [
              -107.766936,
              32.917116
            ],
            [
              -107.765114,
              32.915211
            ],
            [
              -107.766038,
              32.913474
            ],
            [
              -107.765332,
              32.911816
            ],
            [
              -107.766172,
              32.910802
            ],
            [
              -107.778577,
              32.897309
            ],
            [
              -107.773088,
              32.853775
            ],
            [
              -107.758388,
              32.846075
            ],
            [
              -107.745288,
              32.825474
            ],
            [
              -107.749487,
              32.805076
            ],
            [
              -107.736887,
              32.784376
            ],
            [
              -107.721387,
              32.778077
            ],
            [
              -107.716186,
              32.762676
            ],
            [
              -107.722858,
              32.736119
            ],
            [
              -107.715276,
              32.72157
            ],
            [
              -107.719567,
              32.697474
            ],
            [
              -107.727446,
              32.692113
            ],
            [
              -107.725321,
              32.661081
            ],
            [
              -107.736949,
              32.629494
            ],
            [
              -107.716925,
              32.622555
            ],
            [
              -107.608485,
              32.605449
            ],
            [
              -107.299631,
              32.60537
            ],
            [
              -107.299474,
              32.779923
            ],
            [
              -107.259393,
              32.780066
            ],
            [
              -106.937874,
              32.779365
            ],
            [
              -106.887733,
              32.779273
            ],
            [
              -106.887891,
              32.822781
            ],
            [
              -106.340515,
              33.052777
            ],
            [
              -106.340537,
              33.116096
            ],
            [
              -106.34571,
              33.390595
            ],
            [
              -106.371885,
              33.390615
            ],
            [
              -106.372797,
              33.479875
            ],
            [
              -106.469021,
              33.4802
            ],
            [
              -106.474295,
              33.475786
            ],
            [
              -107.402917,
              33.477769
            ],
            [
              -107.712754,
              33.476832
            ],
            [
              -107.938959,
              33.474382
            ],
            [
              -107.967777,
              33.477953
            ],
            [
              -108.000602,
              33.478014
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "35",
      "COUNTYFP": "059",
      "COUNTYNS": "00929115",
      "AFFGEOID": "0500000US35059",
      "GEOID": "35059",
      "NAME": "Union",
      "LSAD": "06",
      "ALAND": 9903868964,
      "AWATER": 18464199,
      "County_state": "Union,35"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.009342,
              36.697657
            ],
            [
              -104.00845,
              36.567616
            ],
            [
              -104.008823,
              36.218115
            ],
            [
              -104.008864,
              36.174527
            ],
            [
              -103.793991,
              36.173972
            ],
            [
              -103.794525,
              36.085738
            ],
            [
              -103.364977,
              36.08605
            ],
            [
              -103.365062,
              35.998664
            ],
            [
              -103.374864,
              35.998657
            ],
            [
              -103.375117,
              35.739516
            ],
            [
              -103.04127182956401,
              35.7394328138008
            ],
            [
              -103.041146,
              35.791583
            ],
            [
              -103.041917,
              35.796441
            ],
            [
              -103.041716,
              35.814072
            ],
            [
              -103.042186,
              35.825217
            ],
            [
              -103.041305,
              35.837694
            ],
            [
              -103.040824,
              36.055231
            ],
            [
              -103.041674,
              36.317534
            ],
            [
              -103.041745,
              36.318267
            ],
            [
              -103.041924,
              36.500439
            ],
            [
              -103.002434,
              36.500397
            ],
            [
              -103.002565,
              36.526588
            ],
            [
              -103.002188,
              36.602716
            ],
            [
              -103.002252,
              36.61718
            ],
            [
              -103.002518,
              36.675186
            ],
            [
              -103.002198,
              36.719427
            ],
            [
              -103.001964,
              36.909573
            ],
            [
              -103.002247,
              36.911587
            ],
            [
              -103.002199,
              37.000104
            ],
            [
              -103.086102011771,
              37.000173863231296
            ],
            [
              -103.155922,
              37.000232
            ],
            [
              -103.733247,
              36.998016
            ],
            [
              -103.734364,
              36.998041
            ],
            [
              -104.007851691976,
              36.99623902179619
            ],
            [
              -104.009342,
              36.697657
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "35",
      "COUNTYFP": "011",
      "COUNTYNS": "00933054",
      "AFFGEOID": "0500000US35011",
      "GEOID": "35011",
      "NAME": "De Baca",
      "LSAD": "06",
      "ALAND": 6016761648,
      "AWATER": 29147345,
      "County_state": "De Baca,35"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.891745,
              34.347043
            ],
            [
              -104.893377,
              34.088407
            ],
            [
              -104.786429,
              34.087664
            ],
            [
              -104.786104,
              34.000137
            ],
            [
              -104.317404,
              33.995877
            ],
            [
              -104.156269,
              33.995413
            ],
            [
              -104.1556,
              34.083205
            ],
            [
              -103.946019,
              34.082463
            ],
            [
              -103.94567,
              34.250072
            ],
            [
              -103.94917,
              34.259648
            ],
            [
              -103.948778,
              34.605058
            ],
            [
              -104.12888,
              34.605201
            ],
            [
              -104.129143,
              34.779337
            ],
            [
              -104.339817,
              34.779062
            ],
            [
              -104.339725,
              34.692234
            ],
            [
              -104.444935,
              34.691655
            ],
            [
              -104.444762,
              34.604952
            ],
            [
              -104.892023,
              34.604426
            ],
            [
              -104.891745,
              34.347043
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "35",
      "COUNTYFP": "061",
      "COUNTYNS": "00933052",
      "AFFGEOID": "0500000US35061",
      "GEOID": "35061",
      "NAME": "Valencia",
      "LSAD": "06",
      "ALAND": 2762826562,
      "AWATER": 2787804,
      "County_state": "Valencia,35"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -107.203312,
              34.796963
            ],
            [
              -107.204381,
              34.608805
            ],
            [
              -107.201743,
              34.578757
            ],
            [
              -107.020048,
              34.579581
            ],
            [
              -106.882658,
              34.579531
            ],
            [
              -106.797826,
              34.543802
            ],
            [
              -106.77329,
              34.533388
            ],
            [
              -106.77306,
              34.53329
            ],
            [
              -106.768281,
              34.531262
            ],
            [
              -106.564648,
              34.473894
            ],
            [
              -106.504624,
              34.457003
            ],
            [
              -106.456314,
              34.437054
            ],
            [
              -106.416924,
              34.439687
            ],
            [
              -106.416881,
              34.521715
            ],
            [
              -106.46979,
              34.521217
            ],
            [
              -106.464873,
              34.608385
            ],
            [
              -106.466707,
              34.717011
            ],
            [
              -106.464546,
              34.810517
            ],
            [
              -106.463868,
              34.870194
            ],
            [
              -106.411781,
              34.870252
            ],
            [
              -106.411558,
              34.889733
            ],
            [
              -106.414475,
              34.892225
            ],
            [
              -106.429486,
              34.9034
            ],
            [
              -106.685955,
              34.906004
            ],
            [
              -106.702871,
              34.885331
            ],
            [
              -106.720091,
              34.872141
            ],
            [
              -106.721834,
              34.870057
            ],
            [
              -106.766228,
              34.869967
            ],
            [
              -107.02441,
              34.869953
            ],
            [
              -107.024996,
              34.871018
            ],
            [
              -107.066562,
              34.95719
            ],
            [
              -107.202882,
              34.957572
            ],
            [
              -107.203312,
              34.796963
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "35",
      "COUNTYFP": "047",
      "COUNTYNS": "00929114",
      "AFFGEOID": "0500000US35047",
      "GEOID": "35047",
      "NAME": "San Miguel",
      "LSAD": "06",
      "ALAND": 12230067556,
      "AWATER": 51380263,
      "County_state": "San Miguel,35"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -105.724461,
              35.871194
            ],
            [
              -105.723108,
              35.823147
            ],
            [
              -105.721207,
              35.812112
            ],
            [
              -105.721205,
              35.795353
            ],
            [
              -105.721207,
              35.733654
            ],
            [
              -105.716344,
              35.654204
            ],
            [
              -105.718501,
              35.647101
            ],
            [
              -105.718016,
              35.553264
            ],
            [
              -105.718103,
              35.464216
            ],
            [
              -105.717409,
              35.304167
            ],
            [
              -105.715387,
              35.21552
            ],
            [
              -105.714419,
              35.041605
            ],
            [
              -105.682848,
              35.041633
            ],
            [
              -105.290791,
              35.04203
            ],
            [
              -105.291169,
              35.216489
            ],
            [
              -105.169417,
              35.21646
            ],
            [
              -105.082522,
              35.216431
            ],
            [
              -104.891141,
              35.216371
            ],
            [
              -104.355204,
              35.21606
            ],
            [
              -104.125136,
              35.215696
            ],
            [
              -104.125121,
              35.142058
            ],
            [
              -103.857661,
              35.264139
            ],
            [
              -103.85753,
              35.242405
            ],
            [
              -103.842824,
              35.241517
            ],
            [
              -103.63713,
              35.240819
            ],
            [
              -103.637053,
              35.389662
            ],
            [
              -103.637026,
              35.422339
            ],
            [
              -103.723609,
              35.422785
            ],
            [
              -103.976901,
              35.801747
            ],
            [
              -104.021702,
              35.781494
            ],
            [
              -104.107426,
              35.781742
            ],
            [
              -104.37012,
              35.779302
            ],
            [
              -104.455933,
              35.778725
            ],
            [
              -104.546363,
              35.78069
            ],
            [
              -104.750645,
              35.783127
            ],
            [
              -105.006417,
              35.771256
            ],
            [
              -105.018602,
              35.769686
            ],
            [
              -105.025454,
              35.754197
            ],
            [
              -105.052848,
              35.77638
            ],
            [
              -105.079465,
              35.786575
            ],
            [
              -105.110049,
              35.807904
            ],
            [
              -105.150136,
              35.809166
            ],
            [
              -105.177657,
              35.825836
            ],
            [
              -105.219191,
              35.829416
            ],
            [
              -105.23777,
              35.838337
            ],
            [
              -105.34783,
              35.870695
            ],
            [
              -105.524329,
              35.870721
            ],
            [
              -105.724461,
              35.871194
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "35",
      "COUNTYFP": "035",
      "COUNTYNS": "00929104",
      "AFFGEOID": "0500000US35035",
      "GEOID": "35035",
      "NAME": "Otero",
      "LSAD": "06",
      "ALAND": 17126453574,
      "AWATER": 36946480,
      "County_state": "Otero,35"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -106.377264,
              32.02301
            ],
            [
              -106.37717982050499,
              32.0011777046509
            ],
            [
              -106.376861,
              32.001172
            ],
            [
              -106.32608506721301,
              32.0014436401351
            ],
            [
              -106.323469336526,
              32.001457633722204
            ],
            [
              -106.313307,
              32.001512
            ],
            [
              -106.205915,
              32.001762
            ],
            [
              -106.200699,
              32.001785
            ],
            [
              -106.18184,
              32.00205
            ],
            [
              -106.125534,
              32.002533
            ],
            [
              -106.05045102059302,
              32.0024123076916
            ],
            [
              -105.99800678071601,
              32.0023280060773
            ],
            [
              -105.998003,
              32.002328
            ],
            [
              -105.9006,
              32.0021
            ],
            [
              -105.886159,
              32.00197
            ],
            [
              -105.854061,
              32.00235
            ],
            [
              -105.750527,
              32.002206
            ],
            [
              -105.731362,
              32.001564
            ],
            [
              -105.429281,
              32.000577
            ],
            [
              -105.428582,
              32.0006
            ],
            [
              -105.427049,
              32.000638
            ],
            [
              -105.390396,
              32.000607
            ],
            [
              -105.153994,
              32.000497
            ],
            [
              -105.15031,
              32.000497
            ],
            [
              -105.14824,
              32.000485
            ],
            [
              -105.132916,
              32.000518
            ],
            [
              -105.131377,
              32.000524
            ],
            [
              -105.11804,
              32.000485
            ],
            [
              -105.078605,
              32.000533
            ],
            [
              -105.077046,
              32.000579
            ],
            [
              -104.918356959202,
              32.0004960444129
            ],
            [
              -104.918272,
              32.000496
            ],
            [
              -104.84774,
              32.000482393992996
            ],
            [
              -104.847637,
              32.253497
            ],
            [
              -104.851302,
              32.26018
            ],
            [
              -104.851566,
              32.484301
            ],
            [
              -104.851524,
              32.520541
            ],
            [
              -105.149278,
              32.520722
            ],
            [
              -105.35401,
              32.518776
            ],
            [
              -105.35344,
              32.604736
            ],
            [
              -105.352513,
              32.962007
            ],
            [
              -105.316047,
              32.9621
            ],
            [
              -105.316224,
              33.002719
            ],
            [
              -105.316968,
              33.132303
            ],
            [
              -105.317376,
              33.305977
            ],
            [
              -105.381814,
              33.305425
            ],
            [
              -105.725957,
              33.304834
            ],
            [
              -105.727171,
              33.390633
            ],
            [
              -105.733894,
              33.390666
            ],
            [
              -105.931639,
              33.3899
            ],
            [
              -106.34571,
              33.390595
            ],
            [
              -106.340537,
              33.116096
            ],
            [
              -106.340515,
              33.052777
            ],
            [
              -106.340709,
              32.958382
            ],
            [
              -106.376585,
              32.95805
            ],
            [
              -106.376942,
              32.516482
            ],
            [
              -106.377171,
              32.081588
            ],
            [
              -106.377264,
              32.02301
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "35",
      "COUNTYFP": "023",
      "COUNTYNS": "00929106",
      "AFFGEOID": "0500000US35023",
      "GEOID": "35023",
      "NAME": "Hidalgo",
      "LSAD": "06",
      "ALAND": 8905571558,
      "AWATER": 23650187,
      "County_state": "Hidalgo,35"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -109.050173,
              31.480004
            ],
            [
              -109.050044,
              31.332502
            ],
            [
              -108.861028,
              31.332315
            ],
            [
              -108.851105,
              31.332301
            ],
            [
              -108.788711,
              31.332365
            ],
            [
              -108.707657,
              31.333191
            ],
            [
              -108.475353,
              31.333437
            ],
            [
              -108.208573,
              31.333395
            ],
            [
              -108.208572,
              31.499742
            ],
            [
              -108.208521,
              31.499798
            ],
            [
              -108.208087,
              31.613489
            ],
            [
              -108.208394,
              31.783599
            ],
            [
              -108.210014,
              31.820499
            ],
            [
              -108.217134,
              31.820475
            ],
            [
              -108.217143,
              31.864139
            ],
            [
              -108.524538,
              31.863614
            ],
            [
              -108.52463,
              32.079828
            ],
            [
              -108.537676,
              32.079832
            ],
            [
              -108.536929,
              32.270711
            ],
            [
              -108.537011,
              32.516618
            ],
            [
              -108.64677,
              32.516521
            ],
            [
              -108.646523,
              32.60373
            ],
            [
              -108.738055,
              32.603505
            ],
            [
              -108.852919,
              32.603374
            ],
            [
              -108.854581,
              32.777276
            ],
            [
              -109.047117279572,
              32.777794004252
            ],
            [
              -109.047117,
              32.77757
            ],
            [
              -109.047638,
              32.693439
            ],
            [
              -109.047645,
              32.689988
            ],
            [
              -109.047653,
              32.686327
            ],
            [
              -109.047653,
              32.681379
            ],
            [
              -109.04764748009501,
              32.647047615744995
            ],
            [
              -109.047612,
              32.426377
            ],
            [
              -109.047612003221,
              32.4263753884837
            ],
            [
              -109.048286,
              32.089114
            ],
            [
              -109.048296,
              32.084093
            ],
            [
              -109.048731,
              32.028174
            ],
            [
              -109.048599,
              32.013651
            ],
            [
              -109.04859,
              31.870791
            ],
            [
              -109.048769,
              31.861383
            ],
            [
              -109.049106,
              31.843715
            ],
            [
              -109.048763,
              31.810776
            ],
            [
              -109.049195,
              31.796551
            ],
            [
              -109.049112,
              31.636598
            ],
            [
              -109.049813,
              31.499528
            ],
            [
              -109.049843,
              31.499515
            ],
            [
              -109.050173,
              31.480004
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "35",
      "COUNTYFP": "006",
      "COUNTYNS": "00933051",
      "AFFGEOID": "0500000US35006",
      "GEOID": "35006",
      "NAME": "Cibola",
      "LSAD": "06",
      "ALAND": 11758240020,
      "AWATER": 5819738,
      "County_state": "Cibola,35"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -109.046086,
              34.771016
            ],
            [
              -109.046156,
              34.579291
            ],
            [
              -108.125109,
              34.580252
            ],
            [
              -108.010592,
              34.579262
            ],
            [
              -107.724794,
              34.578132
            ],
            [
              -107.691565,
              34.579425
            ],
            [
              -107.201743,
              34.578757
            ],
            [
              -107.204381,
              34.608805
            ],
            [
              -107.203312,
              34.796963
            ],
            [
              -107.202882,
              34.957572
            ],
            [
              -107.066562,
              34.95719
            ],
            [
              -107.197244,
              35.219459
            ],
            [
              -107.23889,
              35.305507
            ],
            [
              -107.309386,
              35.305687
            ],
            [
              -107.628788,
              35.304333
            ],
            [
              -107.628313,
              35.348314
            ],
            [
              -107.734241,
              35.348145
            ],
            [
              -107.734224,
              35.304987
            ],
            [
              -107.835449,
              35.305045
            ],
            [
              -108.11249,
              35.306094
            ],
            [
              -108.468715,
              35.306658
            ],
            [
              -108.468683,
              35.299243
            ],
            [
              -108.468656,
              35.292948
            ],
            [
              -108.469402,
              34.959081
            ],
            [
              -108.502943,
              34.959134
            ],
            [
              -108.518964,
              34.959125
            ],
            [
              -108.850348,
              34.959631
            ],
            [
              -109.045851080673,
              34.95981851532
            ],
            [
              -109.045851,
              34.959718
            ],
            [
              -109.046072,
              34.828566
            ],
            [
              -109.045624,
              34.814226
            ],
            [
              -109.046104,
              34.799981
            ],
            [
              -109.045363,
              34.785406
            ],
            [
              -109.046086,
              34.771016
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "35",
      "COUNTYFP": "015",
      "COUNTYNS": "00936829",
      "AFFGEOID": "0500000US35015",
      "GEOID": "35015",
      "NAME": "Eddy",
      "LSAD": "06",
      "ALAND": 10816855244,
      "AWATER": 56325882,
      "County_state": "Eddy,35"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.851524,
              32.520541
            ],
            [
              -104.851566,
              32.484301
            ],
            [
              -104.851302,
              32.26018
            ],
            [
              -104.847637,
              32.253497
            ],
            [
              -104.84774,
              32.000482393992996
            ],
            [
              -104.643526,
              32.000443
            ],
            [
              -104.640918,
              32.000396
            ],
            [
              -104.531937,
              32.000311
            ],
            [
              -104.531756,
              32.000117
            ],
            [
              -104.329638023971,
              32.0000743637004
            ],
            [
              -104.024521,
              32.00001
            ],
            [
              -103.980179,
              32.000125
            ],
            [
              -103.875476,
              32.000554
            ],
            [
              -103.748317,
              32.000198
            ],
            [
              -103.72288201487001,
              32.0002083713881
            ],
            [
              -103.722938,
              32.352915
            ],
            [
              -103.722938,
              32.522344
            ],
            [
              -103.814216,
              32.522368
            ],
            [
              -103.814507,
              32.965114
            ],
            [
              -104.05099,
              32.965395
            ],
            [
              -104.358456,
              32.964439
            ],
            [
              -104.362212,
              32.964599
            ],
            [
              -104.550721,
              32.963997
            ],
            [
              -104.841576,
              32.963213
            ],
            [
              -104.842171,
              32.52054
            ],
            [
              -104.851524,
              32.520541
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "35",
      "COUNTYFP": "039",
      "COUNTYNS": "01702368",
      "AFFGEOID": "0500000US35039",
      "GEOID": "35039",
      "NAME": "Rio Arriba",
      "LSAD": "06",
      "ALAND": 15179716552,
      "AWATER": 91476917,
      "County_state": "Rio Arriba,35"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -107.625778,
              36.493207
            ],
            [
              -107.625682,
              36.423922
            ],
            [
              -107.625439,
              36.420393
            ],
            [
              -107.624969,
              36.308023
            ],
            [
              -107.624283,
              36.219809
            ],
            [
              -107.571392,
              36.219896
            ],
            [
              -107.304621,
              36.21463
            ],
            [
              -107.254029,
              36.216567
            ],
            [
              -107.053731,
              36.216584
            ],
            [
              -106.885053,
              36.21657
            ],
            [
              -106.885317,
              35.998969
            ],
            [
              -106.863509,
              35.998622
            ],
            [
              -106.80825,
              35.998799
            ],
            [
              -106.714251,
              35.999457
            ],
            [
              -106.390836,
              36.001352
            ],
            [
              -106.38202,
              36.000445
            ],
            [
              -106.247164,
              36.001434
            ],
            [
              -106.248161,
              35.966327
            ],
            [
              -106.245605,
              35.959348
            ],
            [
              -106.245649,
              35.930742
            ],
            [
              -106.054346,
              35.930783
            ],
            [
              -106.054153,
              35.985908
            ],
            [
              -106.055885,
              35.98943
            ],
            [
              -106.065938,
              35.989301
            ],
            [
              -106.067781,
              35.991399
            ],
            [
              -106.06766,
              35.994558
            ],
            [
              -106.064898,
              35.996687
            ],
            [
              -106.071336,
              35.999458
            ],
            [
              -106.071153,
              36.001573
            ],
            [
              -106.050876,
              36.002019
            ],
            [
              -105.987729,
              36.002084
            ],
            [
              -105.964414,
              36.002101
            ],
            [
              -105.716808,
              36.00232
            ],
            [
              -105.718611,
              35.979463
            ],
            [
              -105.707581,
              35.968747
            ],
            [
              -105.67963,
              35.968173
            ],
            [
              -105.660019,
              35.958524
            ],
            [
              -105.64516,
              35.962163
            ],
            [
              -105.632109,
              35.97959
            ],
            [
              -105.605058,
              35.973634
            ],
            [
              -105.568045,
              35.973796
            ],
            [
              -105.537116,
              35.990883
            ],
            [
              -105.53038,
              36.013014
            ],
            [
              -105.61309,
              36.048558
            ],
            [
              -105.735883,
              36.100133
            ],
            [
              -105.761537,
              36.129971
            ],
            [
              -105.838076,
              36.210648
            ],
            [
              -105.856947,
              36.230434
            ],
            [
              -106.058364,
              36.296978
            ],
            [
              -106.038312,
              36.325661
            ],
            [
              -105.998244,
              36.333733
            ],
            [
              -105.9598,
              36.354789
            ],
            [
              -105.951876,
              36.374517
            ],
            [
              -105.955468,
              36.419099
            ],
            [
              -105.938469,
              36.465516
            ],
            [
              -105.94285,
              36.500019
            ],
            [
              -105.95162,
              36.517357
            ],
            [
              -105.949961,
              36.542591
            ],
            [
              -105.955673,
              36.57559
            ],
            [
              -105.964114,
              36.598326
            ],
            [
              -105.972491,
              36.648088
            ],
            [
              -105.984394,
              36.645286
            ],
            [
              -105.984332,
              36.659632
            ],
            [
              -105.968312,
              36.659603
            ],
            [
              -105.970852,
              36.683111
            ],
            [
              -105.981088,
              36.713445
            ],
            [
              -105.97758,
              36.720952
            ],
            [
              -105.982116,
              36.746394
            ],
            [
              -105.979947,
              36.767343
            ],
            [
              -105.984436,
              36.812065
            ],
            [
              -105.974555,
              36.837491
            ],
            [
              -105.971889,
              36.886247
            ],
            [
              -105.985771,
              36.920448
            ],
            [
              -105.98799,
              36.940462
            ],
            [
              -106.004695,
              36.976462
            ],
            [
              -106.00631663454301,
              36.9953455633097
            ],
            [
              -106.006634,
              36.995343
            ],
            [
              -106.201469,
              36.994122
            ],
            [
              -106.247705,
              36.994266
            ],
            [
              -106.248675,
              36.994288
            ],
            [
              -106.293279,
              36.99389
            ],
            [
              -106.343139,
              36.99423
            ],
            [
              -106.47627502252101,
              36.9938393436494
            ],
            [
              -106.500589,
              36.993768
            ],
            [
              -106.617159,
              36.992967
            ],
            [
              -106.617125,
              36.993004
            ],
            [
              -106.628652,
              36.993175
            ],
            [
              -106.628733,
              36.993161
            ],
            [
              -106.661344,
              36.993243
            ],
            [
              -106.675626,
              36.993123
            ],
            [
              -106.750591,
              36.992461
            ],
            [
              -106.869796,
              36.992426
            ],
            [
              -106.877292,
              37.000139
            ],
            [
              -106.985514007228,
              37.0001123237881
            ],
            [
              -107.420913,
              37.000005
            ],
            [
              -107.420921905745,
              37.0000049999386
            ],
            [
              -107.421279,
              36.999419
            ],
            [
              -107.431496,
              36.985655
            ],
            [
              -107.429811,
              36.975622
            ],
            [
              -107.439332,
              36.967969
            ],
            [
              -107.447295,
              36.964708
            ],
            [
              -107.452016,
              36.958399
            ],
            [
              -107.452236,
              36.949504
            ],
            [
              -107.448802,
              36.944394
            ],
            [
              -107.454852,
              36.932631
            ],
            [
              -107.450665,
              36.920027
            ],
            [
              -107.450931,
              36.909975
            ],
            [
              -107.455743,
              36.901423
            ],
            [
              -107.461276,
              36.897608
            ],
            [
              -107.472094,
              36.894529
            ],
            [
              -107.481895,
              36.888389
            ],
            [
              -107.491501,
              36.878817
            ],
            [
              -107.504789,
              36.878619
            ],
            [
              -107.517607,
              36.878003
            ],
            [
              -107.528751,
              36.866066
            ],
            [
              -107.533255,
              36.854398
            ],
            [
              -107.542417,
              36.851805
            ],
            [
              -107.552411,
              36.851614
            ],
            [
              -107.554923,
              36.854611
            ],
            [
              -107.565749,
              36.850302
            ],
            [
              -107.567024,
              36.844359
            ],
            [
              -107.578392,
              36.837319
            ],
            [
              -107.589212,
              36.837091
            ],
            [
              -107.600067,
              36.829356
            ],
            [
              -107.601002,
              36.82205
            ],
            [
              -107.594715,
              36.815901
            ],
            [
              -107.592592,
              36.811882
            ],
            [
              -107.601658,
              36.810817
            ],
            [
              -107.606211,
              36.803577
            ],
            [
              -107.610369,
              36.798987
            ],
            [
              -107.613487,
              36.800156
            ],
            [
              -107.61821,
              36.797734
            ],
            [
              -107.618283,
              36.773817
            ],
            [
              -107.618744,
              36.739563
            ],
            [
              -107.619381,
              36.676333
            ],
            [
              -107.623397,
              36.637287
            ],
            [
              -107.624748,
              36.588275
            ],
            [
              -107.625551,
              36.539574
            ],
            [
              -107.625778,
              36.493207
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "35",
      "COUNTYFP": "031",
      "COUNTYNS": "00929107",
      "AFFGEOID": "0500000US35031",
      "GEOID": "35031",
      "NAME": "McKinley",
      "LSAD": "06",
      "ALAND": 14116799310,
      "AWATER": 14078537,
      "County_state": "McKinley,35"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -109.046796,
              35.363606
            ],
            [
              -109.04666527206801,
              35.3427517735473
            ],
            [
              -109.04650442603601,
              35.3170929910607
            ],
            [
              -109.046084,
              35.250025
            ],
            [
              -109.046024192748,
              35.1755079878063
            ],
            [
              -109.045851080673,
              34.95981851532
            ],
            [
              -108.850348,
              34.959631
            ],
            [
              -108.518964,
              34.959125
            ],
            [
              -108.502943,
              34.959134
            ],
            [
              -108.469402,
              34.959081
            ],
            [
              -108.468656,
              35.292948
            ],
            [
              -108.468683,
              35.299243
            ],
            [
              -108.468715,
              35.306658
            ],
            [
              -108.11249,
              35.306094
            ],
            [
              -107.835449,
              35.305045
            ],
            [
              -107.734224,
              35.304987
            ],
            [
              -107.734241,
              35.348145
            ],
            [
              -107.628313,
              35.348314
            ],
            [
              -107.628788,
              35.304333
            ],
            [
              -107.309386,
              35.305687
            ],
            [
              -107.306122,
              35.41906
            ],
            [
              -107.308394,
              35.654668
            ],
            [
              -107.308667,
              35.998556
            ],
            [
              -107.626511,
              36.000288
            ],
            [
              -107.815746,
              36.001309
            ],
            [
              -108.151217,
              36.002785
            ],
            [
              -108.533786,
              36.002693
            ],
            [
              -108.804541,
              36.002732
            ],
            [
              -108.827181,
              36.00273
            ],
            [
              -108.929479,
              36.002545
            ],
            [
              -109.045972227078,
              36.0027013051401
            ],
            [
              -109.045973,
              36.002338
            ],
            [
              -109.046011,
              35.925896
            ],
            [
              -109.046054,
              35.92586
            ],
            [
              -109.046055,
              35.888721
            ],
            [
              -109.046024,
              35.8798
            ],
            [
              -109.046295,
              35.616517
            ],
            [
              -109.046296,
              35.614251
            ],
            [
              -109.046509,
              35.54644
            ],
            [
              -109.046481,
              35.546326
            ],
            [
              -109.046796,
              35.363606
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "35",
      "COUNTYFP": "027",
      "COUNTYNS": "00929116",
      "AFFGEOID": "0500000US35027",
      "GEOID": "35027",
      "NAME": "Lincoln",
      "LSAD": "06",
      "ALAND": 12512530204,
      "AWATER": 554703,
      "County_state": "Lincoln,35"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -106.37366,
              33.660504
            ],
            [
              -106.372797,
              33.479875
            ],
            [
              -106.371885,
              33.390615
            ],
            [
              -106.34571,
              33.390595
            ],
            [
              -105.931639,
              33.3899
            ],
            [
              -105.733894,
              33.390666
            ],
            [
              -105.727171,
              33.390633
            ],
            [
              -105.725957,
              33.304834
            ],
            [
              -105.381814,
              33.305425
            ],
            [
              -105.317376,
              33.305977
            ],
            [
              -105.316968,
              33.132303
            ],
            [
              -105.108621,
              33.131742
            ],
            [
              -105.074892,
              33.134208
            ],
            [
              -105.006481,
              33.134401
            ],
            [
              -104.905757,
              33.138904
            ],
            [
              -104.905431,
              33.22094
            ],
            [
              -104.903838,
              33.305904
            ],
            [
              -104.88513,
              33.305827
            ],
            [
              -104.885405,
              33.34731
            ],
            [
              -104.885088,
              33.393271
            ],
            [
              -104.892433,
              33.398199
            ],
            [
              -104.892168,
              33.566376
            ],
            [
              -104.892056,
              33.594681
            ],
            [
              -104.89109,
              33.822258
            ],
            [
              -104.894573,
              33.82443
            ],
            [
              -104.893377,
              34.088407
            ],
            [
              -104.891745,
              34.347043
            ],
            [
              -105.313072,
              34.347205
            ],
            [
              -105.313218,
              34.260031
            ],
            [
              -105.925968,
              34.25999
            ],
            [
              -105.924685,
              33.825624
            ],
            [
              -106.052662,
              33.825606
            ],
            [
              -106.05268,
              33.748463
            ],
            [
              -106.052718,
              33.650297
            ],
            [
              -106.158299,
              33.650279
            ],
            [
              -106.268261,
              33.660535
            ],
            [
              -106.37366,
              33.660504
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "35",
      "COUNTYFP": "053",
      "COUNTYNS": "01702371",
      "AFFGEOID": "0500000US35053",
      "GEOID": "35053",
      "NAME": "Socorro",
      "LSAD": "06",
      "ALAND": 17214061674,
      "AWATER": 4843011,
      "County_state": "Socorro,35"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -107.725661,
              34.360619
            ],
            [
              -107.72542,
              34.259823
            ],
            [
              -107.716301,
              34.259856
            ],
            [
              -107.715771,
              33.825637
            ],
            [
              -107.712748,
              33.811376
            ],
            [
              -107.712754,
              33.476832
            ],
            [
              -107.402917,
              33.477769
            ],
            [
              -106.474295,
              33.475786
            ],
            [
              -106.469021,
              33.4802
            ],
            [
              -106.372797,
              33.479875
            ],
            [
              -106.37366,
              33.660504
            ],
            [
              -106.268261,
              33.660535
            ],
            [
              -106.158299,
              33.650279
            ],
            [
              -106.052718,
              33.650297
            ],
            [
              -106.05268,
              33.748463
            ],
            [
              -106.052662,
              33.825606
            ],
            [
              -105.924685,
              33.825624
            ],
            [
              -105.925968,
              34.25999
            ],
            [
              -106.101744,
              34.259983
            ],
            [
              -106.415972,
              34.259822
            ],
            [
              -106.416924,
              34.439687
            ],
            [
              -106.456314,
              34.437054
            ],
            [
              -106.504624,
              34.457003
            ],
            [
              -106.564648,
              34.473894
            ],
            [
              -106.768281,
              34.531262
            ],
            [
              -106.77306,
              34.53329
            ],
            [
              -106.77329,
              34.533388
            ],
            [
              -106.797826,
              34.543802
            ],
            [
              -106.882658,
              34.579531
            ],
            [
              -107.020048,
              34.579581
            ],
            [
              -107.201743,
              34.578757
            ],
            [
              -107.691565,
              34.579425
            ],
            [
              -107.724794,
              34.578132
            ],
            [
              -107.725661,
              34.360619
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "35",
      "COUNTYFP": "055",
      "COUNTYNS": "00933056",
      "AFFGEOID": "0500000US35055",
      "GEOID": "35055",
      "NAME": "Taos",
      "LSAD": "06",
      "ALAND": 5704119046,
      "AWATER": 3383126,
      "County_state": "Taos,35"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -106.038312,
              36.325661
            ],
            [
              -106.058364,
              36.296978
            ],
            [
              -105.856947,
              36.230434
            ],
            [
              -105.838076,
              36.210648
            ],
            [
              -105.761537,
              36.129971
            ],
            [
              -105.735883,
              36.100133
            ],
            [
              -105.61309,
              36.048558
            ],
            [
              -105.53038,
              36.013014
            ],
            [
              -105.506872,
              36.020772
            ],
            [
              -105.489345,
              36.037398
            ],
            [
              -105.462918,
              36.078369
            ],
            [
              -105.419992,
              36.131704
            ],
            [
              -105.414742,
              36.157121
            ],
            [
              -105.421576,
              36.170108
            ],
            [
              -105.397015,
              36.191574
            ],
            [
              -105.392798,
              36.21612
            ],
            [
              -105.409677,
              36.236814
            ],
            [
              -105.387352,
              36.252842
            ],
            [
              -105.341264,
              36.261863
            ],
            [
              -105.327521,
              36.277036
            ],
            [
              -105.335651,
              36.301477
            ],
            [
              -105.328953,
              36.337257
            ],
            [
              -105.332091,
              36.366954
            ],
            [
              -105.32734,
              36.38032
            ],
            [
              -105.327301,
              36.40543
            ],
            [
              -105.340454,
              36.416078
            ],
            [
              -105.332704,
              36.428787
            ],
            [
              -105.338104,
              36.444844
            ],
            [
              -105.353031,
              36.443061
            ],
            [
              -105.356282,
              36.452297
            ],
            [
              -105.339578,
              36.470464
            ],
            [
              -105.336373,
              36.4835
            ],
            [
              -105.318718,
              36.491693
            ],
            [
              -105.343874,
              36.511151
            ],
            [
              -105.348999,
              36.53304
            ],
            [
              -105.377745,
              36.552096
            ],
            [
              -105.349762,
              36.561419
            ],
            [
              -105.357922,
              36.589749
            ],
            [
              -105.374793,
              36.608622
            ],
            [
              -105.363362,
              36.629098
            ],
            [
              -105.351385,
              36.636905
            ],
            [
              -105.364105,
              36.654077
            ],
            [
              -105.360344,
              36.662979
            ],
            [
              -105.370638,
              36.681032
            ],
            [
              -105.352327,
              36.697226
            ],
            [
              -105.335298,
              36.702394
            ],
            [
              -105.326616,
              36.69786
            ],
            [
              -105.313223,
              36.723236
            ],
            [
              -105.290843,
              36.728464
            ],
            [
              -105.280771,
              36.718335
            ],
            [
              -105.250287,
              36.717368
            ],
            [
              -105.230632,
              36.723784
            ],
            [
              -105.224327,
              36.73408
            ],
            [
              -105.226639,
              36.771432
            ],
            [
              -105.235298,
              36.790656
            ],
            [
              -105.226718,
              36.804683
            ],
            [
              -105.233438,
              36.811996
            ],
            [
              -105.223083,
              36.833541
            ],
            [
              -105.229372,
              36.84771
            ],
            [
              -105.200117,
              36.915628
            ],
            [
              -105.211793,
              36.919728
            ],
            [
              -105.236154,
              36.962341
            ],
            [
              -105.236167,
              36.981949
            ],
            [
              -105.22050650671301,
              36.995169276334394
            ],
            [
              -105.220613,
              36.995169
            ],
            [
              -105.251296,
              36.995605
            ],
            [
              -105.41931,
              36.995856
            ],
            [
              -105.419665893396,
              36.995858121616
            ],
            [
              -105.442459,
              36.995994
            ],
            [
              -105.447255,
              36.996017
            ],
            [
              -105.465182,
              36.995991
            ],
            [
              -105.508836,
              36.995895
            ],
            [
              -105.512485,
              36.995777
            ],
            [
              -105.533922,
              36.995875
            ],
            [
              -105.62747,
              36.995679
            ],
            [
              -105.66472,
              36.995874
            ],
            [
              -105.716471,
              36.995849
            ],
            [
              -105.718463007598,
              36.99584593031069
            ],
            [
              -105.718469674138,
              36.995845920037496
            ],
            [
              -105.996159,
              36.995418
            ],
            [
              -105.997472,
              36.995417
            ],
            [
              -106.00631663454301,
              36.9953455633097
            ],
            [
              -106.004695,
              36.976462
            ],
            [
              -105.98799,
              36.940462
            ],
            [
              -105.985771,
              36.920448
            ],
            [
              -105.971889,
              36.886247
            ],
            [
              -105.974555,
              36.837491
            ],
            [
              -105.984436,
              36.812065
            ],
            [
              -105.979947,
              36.767343
            ],
            [
              -105.982116,
              36.746394
            ],
            [
              -105.97758,
              36.720952
            ],
            [
              -105.981088,
              36.713445
            ],
            [
              -105.970852,
              36.683111
            ],
            [
              -105.968312,
              36.659603
            ],
            [
              -105.984332,
              36.659632
            ],
            [
              -105.984394,
              36.645286
            ],
            [
              -105.972491,
              36.648088
            ],
            [
              -105.964114,
              36.598326
            ],
            [
              -105.955673,
              36.57559
            ],
            [
              -105.949961,
              36.542591
            ],
            [
              -105.95162,
              36.517357
            ],
            [
              -105.94285,
              36.500019
            ],
            [
              -105.938469,
              36.465516
            ],
            [
              -105.955468,
              36.419099
            ],
            [
              -105.951876,
              36.374517
            ],
            [
              -105.9598,
              36.354789
            ],
            [
              -105.998244,
              36.333733
            ],
            [
              -106.038312,
              36.325661
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "35",
      "COUNTYFP": "045",
      "COUNTYNS": "00936844",
      "AFFGEOID": "0500000US35045",
      "GEOID": "35045",
      "NAME": "San Juan",
      "LSAD": "06",
      "ALAND": 14289427705,
      "AWATER": 55030762,
      "County_state": "San Juan,35"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -109.045868336264,
              36.31491819928
            ],
            [
              -109.046183,
              36.181751
            ],
            [
              -109.045729,
              36.117028
            ],
            [
              -109.045972227078,
              36.0027013051401
            ],
            [
              -108.929479,
              36.002545
            ],
            [
              -108.827181,
              36.00273
            ],
            [
              -108.804541,
              36.002732
            ],
            [
              -108.533786,
              36.002693
            ],
            [
              -108.151217,
              36.002785
            ],
            [
              -107.815746,
              36.001309
            ],
            [
              -107.626511,
              36.000288
            ],
            [
              -107.624283,
              36.219809
            ],
            [
              -107.624969,
              36.308023
            ],
            [
              -107.625439,
              36.420393
            ],
            [
              -107.625682,
              36.423922
            ],
            [
              -107.625778,
              36.493207
            ],
            [
              -107.625551,
              36.539574
            ],
            [
              -107.624748,
              36.588275
            ],
            [
              -107.623397,
              36.637287
            ],
            [
              -107.619381,
              36.676333
            ],
            [
              -107.618744,
              36.739563
            ],
            [
              -107.618283,
              36.773817
            ],
            [
              -107.61821,
              36.797734
            ],
            [
              -107.613487,
              36.800156
            ],
            [
              -107.610369,
              36.798987
            ],
            [
              -107.606211,
              36.803577
            ],
            [
              -107.601658,
              36.810817
            ],
            [
              -107.592592,
              36.811882
            ],
            [
              -107.594715,
              36.815901
            ],
            [
              -107.601002,
              36.82205
            ],
            [
              -107.600067,
              36.829356
            ],
            [
              -107.589212,
              36.837091
            ],
            [
              -107.578392,
              36.837319
            ],
            [
              -107.567024,
              36.844359
            ],
            [
              -107.565749,
              36.850302
            ],
            [
              -107.554923,
              36.854611
            ],
            [
              -107.552411,
              36.851614
            ],
            [
              -107.542417,
              36.851805
            ],
            [
              -107.533255,
              36.854398
            ],
            [
              -107.528751,
              36.866066
            ],
            [
              -107.517607,
              36.878003
            ],
            [
              -107.504789,
              36.878619
            ],
            [
              -107.491501,
              36.878817
            ],
            [
              -107.481895,
              36.888389
            ],
            [
              -107.472094,
              36.894529
            ],
            [
              -107.461276,
              36.897608
            ],
            [
              -107.455743,
              36.901423
            ],
            [
              -107.450931,
              36.909975
            ],
            [
              -107.450665,
              36.920027
            ],
            [
              -107.454852,
              36.932631
            ],
            [
              -107.448802,
              36.944394
            ],
            [
              -107.452236,
              36.949504
            ],
            [
              -107.452016,
              36.958399
            ],
            [
              -107.447295,
              36.964708
            ],
            [
              -107.439332,
              36.967969
            ],
            [
              -107.429811,
              36.975622
            ],
            [
              -107.431496,
              36.985655
            ],
            [
              -107.421279,
              36.999419
            ],
            [
              -107.420921905745,
              37.0000049999386
            ],
            [
              -107.481736999155,
              37.0000045803143
            ],
            [
              -108.000623,
              37.000001
            ],
            [
              -108.033460486906,
              36.9998708302929
            ],
            [
              -108.249358,
              36.999015
            ],
            [
              -108.250635,
              36.999561
            ],
            [
              -108.288086,
              36.999555
            ],
            [
              -108.2884,
              36.99952
            ],
            [
              -108.320464,
              36.999499
            ],
            [
              -108.320721,
              36.99951
            ],
            [
              -108.379302869798,
              36.999458857844296
            ],
            [
              -108.536412308881,
              36.9993217008121
            ],
            [
              -108.619689,
              36.999249
            ],
            [
              -108.620309,
              36.999287
            ],
            [
              -108.954404,
              36.998906
            ],
            [
              -108.958868,
              36.998913
            ],
            [
              -109.045223,
              36.999084
            ],
            [
              -109.045244,
              36.969489
            ],
            [
              -109.045272,
              36.968871
            ],
            [
              -109.045407,
              36.874998
            ],
            [
              -109.045433,
              36.874589
            ],
            [
              -109.0454311461,
              36.5273646779981
            ],
            [
              -109.045431,
              36.500001
            ],
            [
              -109.045868336264,
              36.31491819928
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "35",
      "COUNTYFP": "003",
      "COUNTYNS": "00929108",
      "AFFGEOID": "0500000US35003",
      "GEOID": "35003",
      "NAME": "Catron",
      "LSAD": "06",
      "ALAND": 17933560026,
      "AWATER": 14194439,
      "County_state": "Catron,35"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -109.04747,
              33.250063
            ],
            [
              -109.047237,
              33.208965
            ],
            [
              -109.04723688070601,
              33.2088950306819
            ],
            [
              -108.956436,
              33.207544
            ],
            [
              -108.646597,
              33.209342
            ],
            [
              -108.543178,
              33.207955
            ],
            [
              -108.449988,
              33.200997
            ],
            [
              -108.000598,
              33.201253
            ],
            [
              -108.000602,
              33.478014
            ],
            [
              -107.967777,
              33.477953
            ],
            [
              -107.938959,
              33.474382
            ],
            [
              -107.712754,
              33.476832
            ],
            [
              -107.712748,
              33.811376
            ],
            [
              -107.715771,
              33.825637
            ],
            [
              -107.716301,
              34.259856
            ],
            [
              -107.72542,
              34.259823
            ],
            [
              -107.725661,
              34.360619
            ],
            [
              -107.724794,
              34.578132
            ],
            [
              -108.010592,
              34.579262
            ],
            [
              -108.125109,
              34.580252
            ],
            [
              -109.046156,
              34.579291
            ],
            [
              -109.046182,
              34.522553
            ],
            [
              -109.046182,
              34.522393
            ],
            [
              -109.047006,
              34.00005
            ],
            [
              -109.046426,
              33.875052
            ],
            [
              -109.04694152920001,
              33.7782257216099
            ],
            [
              -109.047145,
              33.74001
            ],
            [
              -109.046662,
              33.625055
            ],
            [
              -109.047298,
              33.409783
            ],
            [
              -109.046564,
              33.37506
            ],
            [
              -109.04687,
              33.372654
            ],
            [
              -109.047045,
              33.36928
            ],
            [
              -109.046909,
              33.36557
            ],
            [
              -109.046827,
              33.365272
            ],
            [
              -109.04747,
              33.250063
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "35",
      "COUNTYFP": "028",
      "COUNTYNS": "01702366",
      "AFFGEOID": "0500000US35028",
      "GEOID": "35028",
      "NAME": "Los Alamos",
      "LSAD": "06",
      "ALAND": 282821648,
      "AWATER": 229869,
      "County_state": "Los Alamos,35"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -106.418768,
              35.825296
            ],
            [
              -106.411148,
              35.810862
            ],
            [
              -106.382358,
              35.80798
            ],
            [
              -106.357028,
              35.816374
            ],
            [
              -106.282606,
              35.791905
            ],
            [
              -106.250499,
              35.755818
            ],
            [
              -106.216172,
              35.772284
            ],
            [
              -106.21396,
              35.776682
            ],
            [
              -106.208507,
              35.777785
            ],
            [
              -106.193817,
              35.803989
            ],
            [
              -106.173687,
              35.825526
            ],
            [
              -106.172682,
              35.827795
            ],
            [
              -106.20567,
              35.829341
            ],
            [
              -106.225391,
              35.828754
            ],
            [
              -106.248257,
              35.839178
            ],
            [
              -106.249293,
              35.842795
            ],
            [
              -106.261006,
              35.846727
            ],
            [
              -106.263196,
              35.8505
            ],
            [
              -106.276983,
              35.855547
            ],
            [
              -106.248345,
              35.855652
            ],
            [
              -106.245649,
              35.930742
            ],
            [
              -106.245605,
              35.959348
            ],
            [
              -106.248161,
              35.966327
            ],
            [
              -106.264241,
              35.97275
            ],
            [
              -106.300374,
              35.973389
            ],
            [
              -106.30016,
              35.958518
            ],
            [
              -106.315793,
              35.952521
            ],
            [
              -106.385209,
              35.952795
            ],
            [
              -106.399713,
              35.960095
            ],
            [
              -106.399634,
              35.828233
            ],
            [
              -106.418768,
              35.825296
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "35",
      "COUNTYFP": "049",
      "COUNTYNS": "00933322",
      "AFFGEOID": "0500000US35049",
      "GEOID": "35049",
      "NAME": "Santa Fe",
      "LSAD": "06",
      "ALAND": 4941709205,
      "AWATER": 3537550,
      "County_state": "Santa Fe,35"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -106.250499,
              35.755818
            ],
            [
              -106.250253,
              35.652903
            ],
            [
              -106.248668,
              35.530517
            ],
            [
              -106.248674,
              35.505968
            ],
            [
              -106.248848,
              35.443282
            ],
            [
              -106.248926,
              35.377522
            ],
            [
              -106.248949,
              35.304324
            ],
            [
              -106.245162,
              35.238661
            ],
            [
              -106.244216,
              35.215657
            ],
            [
              -106.243874,
              35.040003
            ],
            [
              -106.194713,
              35.040256
            ],
            [
              -106.029524,
              35.040678
            ],
            [
              -105.714419,
              35.041605
            ],
            [
              -105.715387,
              35.21552
            ],
            [
              -105.717409,
              35.304167
            ],
            [
              -105.718103,
              35.464216
            ],
            [
              -105.718016,
              35.553264
            ],
            [
              -105.718501,
              35.647101
            ],
            [
              -105.716344,
              35.654204
            ],
            [
              -105.721207,
              35.733654
            ],
            [
              -105.721205,
              35.795353
            ],
            [
              -105.721207,
              35.812112
            ],
            [
              -105.723108,
              35.823147
            ],
            [
              -105.724461,
              35.871194
            ],
            [
              -105.718611,
              35.979463
            ],
            [
              -105.716808,
              36.00232
            ],
            [
              -105.964414,
              36.002101
            ],
            [
              -105.987729,
              36.002084
            ],
            [
              -106.050876,
              36.002019
            ],
            [
              -106.071153,
              36.001573
            ],
            [
              -106.071336,
              35.999458
            ],
            [
              -106.064898,
              35.996687
            ],
            [
              -106.06766,
              35.994558
            ],
            [
              -106.067781,
              35.991399
            ],
            [
              -106.065938,
              35.989301
            ],
            [
              -106.055885,
              35.98943
            ],
            [
              -106.054153,
              35.985908
            ],
            [
              -106.054346,
              35.930783
            ],
            [
              -106.245649,
              35.930742
            ],
            [
              -106.248345,
              35.855652
            ],
            [
              -106.248257,
              35.839178
            ],
            [
              -106.225391,
              35.828754
            ],
            [
              -106.20567,
              35.829341
            ],
            [
              -106.172682,
              35.827795
            ],
            [
              -106.173687,
              35.825526
            ],
            [
              -106.193817,
              35.803989
            ],
            [
              -106.208507,
              35.777785
            ],
            [
              -106.21396,
              35.776682
            ],
            [
              -106.216172,
              35.772284
            ],
            [
              -106.250499,
              35.755818
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "35",
      "COUNTYFP": "033",
      "COUNTYNS": "01702367",
      "AFFGEOID": "0500000US35033",
      "GEOID": "35033",
      "NAME": "Mora",
      "LSAD": "06",
      "ALAND": 4992675873,
      "AWATER": 6093304,
      "County_state": "Mora,35"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -105.718611,
              35.979463
            ],
            [
              -105.724461,
              35.871194
            ],
            [
              -105.524329,
              35.870721
            ],
            [
              -105.34783,
              35.870695
            ],
            [
              -105.23777,
              35.838337
            ],
            [
              -105.219191,
              35.829416
            ],
            [
              -105.177657,
              35.825836
            ],
            [
              -105.150136,
              35.809166
            ],
            [
              -105.110049,
              35.807904
            ],
            [
              -105.079465,
              35.786575
            ],
            [
              -105.052848,
              35.77638
            ],
            [
              -105.025454,
              35.754197
            ],
            [
              -105.018602,
              35.769686
            ],
            [
              -105.006417,
              35.771256
            ],
            [
              -104.750645,
              35.783127
            ],
            [
              -104.546363,
              35.78069
            ],
            [
              -104.455933,
              35.778725
            ],
            [
              -104.37012,
              35.779302
            ],
            [
              -104.362168,
              35.783181
            ],
            [
              -104.371119,
              35.804571
            ],
            [
              -104.364118,
              35.812633
            ],
            [
              -104.378125,
              35.823601
            ],
            [
              -104.36691,
              35.829399
            ],
            [
              -104.379919,
              35.835776
            ],
            [
              -104.364959,
              35.842196
            ],
            [
              -104.390522,
              35.859843
            ],
            [
              -104.381918,
              35.861958
            ],
            [
              -104.372596,
              35.883068
            ],
            [
              -104.343739,
              35.912213
            ],
            [
              -104.354406,
              35.929233
            ],
            [
              -104.352497,
              35.940411
            ],
            [
              -104.327284,
              35.960197
            ],
            [
              -104.355724,
              35.982659
            ],
            [
              -104.341171,
              35.991875
            ],
            [
              -104.349853,
              36.009999
            ],
            [
              -104.365294,
              36.013427
            ],
            [
              -104.360549,
              36.023923
            ],
            [
              -104.365724,
              36.04429
            ],
            [
              -104.37975,
              36.049269
            ],
            [
              -104.371028,
              36.066813
            ],
            [
              -104.401075,
              36.091545
            ],
            [
              -104.402922,
              36.117006
            ],
            [
              -104.414546,
              36.123761
            ],
            [
              -104.404361,
              36.155401
            ],
            [
              -104.419908,
              36.16828
            ],
            [
              -104.414429,
              36.176836
            ],
            [
              -104.439119,
              36.193471
            ],
            [
              -104.430465,
              36.213598
            ],
            [
              -104.436056,
              36.218534
            ],
            [
              -104.865402,
              36.217869
            ],
            [
              -104.865244,
              36.261419
            ],
            [
              -105.341264,
              36.261863
            ],
            [
              -105.387352,
              36.252842
            ],
            [
              -105.409677,
              36.236814
            ],
            [
              -105.392798,
              36.21612
            ],
            [
              -105.397015,
              36.191574
            ],
            [
              -105.421576,
              36.170108
            ],
            [
              -105.414742,
              36.157121
            ],
            [
              -105.419992,
              36.131704
            ],
            [
              -105.462918,
              36.078369
            ],
            [
              -105.489345,
              36.037398
            ],
            [
              -105.506872,
              36.020772
            ],
            [
              -105.53038,
              36.013014
            ],
            [
              -105.537116,
              35.990883
            ],
            [
              -105.568045,
              35.973796
            ],
            [
              -105.605058,
              35.973634
            ],
            [
              -105.632109,
              35.97959
            ],
            [
              -105.64516,
              35.962163
            ],
            [
              -105.660019,
              35.958524
            ],
            [
              -105.67963,
              35.968173
            ],
            [
              -105.707581,
              35.968747
            ],
            [
              -105.718611,
              35.979463
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "35",
      "COUNTYFP": "043",
      "COUNTYNS": "00929113",
      "AFFGEOID": "0500000US35043",
      "GEOID": "35043",
      "NAME": "Sandoval",
      "LSAD": "06",
      "ALAND": 9609139504,
      "AWATER": 10854361,
      "County_state": "Sandoval,35"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -106.276983,
              35.855547
            ],
            [
              -106.263196,
              35.8505
            ],
            [
              -106.261006,
              35.846727
            ],
            [
              -106.249293,
              35.842795
            ],
            [
              -106.248257,
              35.839178
            ],
            [
              -106.248345,
              35.855652
            ],
            [
              -106.276983,
              35.855547
            ]
          ]
        ],
        [
          [
            [
              -107.624283,
              36.219809
            ],
            [
              -107.626511,
              36.000288
            ],
            [
              -107.308667,
              35.998556
            ],
            [
              -107.308394,
              35.654668
            ],
            [
              -107.306122,
              35.41906
            ],
            [
              -107.309386,
              35.305687
            ],
            [
              -107.23889,
              35.305507
            ],
            [
              -107.197244,
              35.219459
            ],
            [
              -106.962038,
              35.218392
            ],
            [
              -106.763062,
              35.217994
            ],
            [
              -106.72899,
              35.217978
            ],
            [
              -106.696565,
              35.217963
            ],
            [
              -106.663867,
              35.217926
            ],
            [
              -106.661058,
              35.217922
            ],
            [
              -106.658119,
              35.217802
            ],
            [
              -106.642494,
              35.207297
            ],
            [
              -106.643366,
              35.197762
            ],
            [
              -106.639727,
              35.202946
            ],
            [
              -106.605382,
              35.217442
            ],
            [
              -106.598725,
              35.217852
            ],
            [
              -106.593595,
              35.217844
            ],
            [
              -106.574047,
              35.217943
            ],
            [
              -106.504214,
              35.217924
            ],
            [
              -106.459379,
              35.217942
            ],
            [
              -106.4024,
              35.217396
            ],
            [
              -106.244216,
              35.215657
            ],
            [
              -106.245162,
              35.238661
            ],
            [
              -106.248949,
              35.304324
            ],
            [
              -106.248926,
              35.377522
            ],
            [
              -106.248848,
              35.443282
            ],
            [
              -106.248674,
              35.505968
            ],
            [
              -106.248668,
              35.530517
            ],
            [
              -106.250253,
              35.652903
            ],
            [
              -106.250499,
              35.755818
            ],
            [
              -106.282606,
              35.791905
            ],
            [
              -106.357028,
              35.816374
            ],
            [
              -106.382358,
              35.80798
            ],
            [
              -106.411148,
              35.810862
            ],
            [
              -106.418768,
              35.825296
            ],
            [
              -106.399634,
              35.828233
            ],
            [
              -106.399713,
              35.960095
            ],
            [
              -106.385209,
              35.952795
            ],
            [
              -106.315793,
              35.952521
            ],
            [
              -106.30016,
              35.958518
            ],
            [
              -106.300374,
              35.973389
            ],
            [
              -106.264241,
              35.97275
            ],
            [
              -106.248161,
              35.966327
            ],
            [
              -106.247164,
              36.001434
            ],
            [
              -106.38202,
              36.000445
            ],
            [
              -106.390836,
              36.001352
            ],
            [
              -106.714251,
              35.999457
            ],
            [
              -106.80825,
              35.998799
            ],
            [
              -106.863509,
              35.998622
            ],
            [
              -106.885317,
              35.998969
            ],
            [
              -106.885053,
              36.21657
            ],
            [
              -107.053731,
              36.216584
            ],
            [
              -107.254029,
              36.216567
            ],
            [
              -107.304621,
              36.21463
            ],
            [
              -107.571392,
              36.219896
            ],
            [
              -107.624283,
              36.219809
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "35",
      "COUNTYFP": "037",
      "COUNTYNS": "00929110",
      "AFFGEOID": "0500000US35037",
      "GEOID": "35037",
      "NAME": "Quay",
      "LSAD": "06",
      "ALAND": 7443334318,
      "AWATER": 18728804,
      "County_state": "Quay,35"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.129018,
              34.954592
            ],
            [
              -104.129143,
              34.779337
            ],
            [
              -104.12888,
              34.605201
            ],
            [
              -103.948778,
              34.605058
            ],
            [
              -103.738166,
              34.60467
            ],
            [
              -103.706088,
              34.60501
            ],
            [
              -103.705788,
              34.692446
            ],
            [
              -103.635246,
              34.691887
            ],
            [
              -103.495653,
              34.691393
            ],
            [
              -103.495646,
              34.778575
            ],
            [
              -103.443031,
              34.778255
            ],
            [
              -103.390274,
              34.778122
            ],
            [
              -103.389798,
              34.865429
            ],
            [
              -103.284317,
              34.86499
            ],
            [
              -103.284149,
              34.954172
            ],
            [
              -103.042552402864,
              34.9541420854709
            ],
            [
              -103.042642,
              35.109913
            ],
            [
              -103.043261,
              35.125058
            ],
            [
              -103.04252,
              35.135596
            ],
            [
              -103.0426,
              35.142766
            ],
            [
              -103.042711,
              35.144735
            ],
            [
              -103.042568,
              35.159318
            ],
            [
              -103.042395,
              35.178573
            ],
            [
              -103.042339,
              35.181922
            ],
            [
              -103.042366,
              35.182786
            ],
            [
              -103.042377,
              35.183149
            ],
            [
              -103.042377029193,
              35.1831559850696
            ],
            [
              -103.042497,
              35.211862
            ],
            [
              -103.042775,
              35.241237
            ],
            [
              -103.042366,
              35.250056
            ],
            [
              -103.041554,
              35.622487
            ],
            [
              -103.041553998475,
              35.6224876319576
            ],
            [
              -103.04127182956401,
              35.7394328138008
            ],
            [
              -103.375117,
              35.739516
            ],
            [
              -103.379393,
              35.65252
            ],
            [
              -103.379556,
              35.494718
            ],
            [
              -103.37973,
              35.396796
            ],
            [
              -103.388952,
              35.389622
            ],
            [
              -103.637053,
              35.389662
            ],
            [
              -103.63713,
              35.240819
            ],
            [
              -103.842824,
              35.241517
            ],
            [
              -103.85753,
              35.242405
            ],
            [
              -103.857661,
              35.264139
            ],
            [
              -104.125121,
              35.142058
            ],
            [
              -104.125137,
              35.083436
            ],
            [
              -104.125601,
              34.969004
            ],
            [
              -104.129018,
              34.954592
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "35",
      "COUNTYFP": "013",
      "COUNTYNS": "00929109",
      "AFFGEOID": "0500000US35013",
      "GEOID": "35013",
      "NAME": "DoÃ±a Ana",
      "LSAD": "06",
      "ALAND": 9863129996,
      "AWATER": 17627587,
      "County_state": "DoÃ±a Ana,35"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -107.299631,
              32.60537
            ],
            [
              -107.299489,
              32.595834
            ],
            [
              -107.298291,
              32.220128
            ],
            [
              -107.298743,
              32.082348
            ],
            [
              -107.296594,
              32.043154
            ],
            [
              -107.296793744203,
              31.783761974571004
            ],
            [
              -107.03296116537702,
              31.7835402320976
            ],
            [
              -107.00056,
              31.783513
            ],
            [
              -107.00056,
              31.783679
            ],
            [
              -106.998235,
              31.783671
            ],
            [
              -106.993544,
              31.783689
            ],
            [
              -106.750547,
              31.783898
            ],
            [
              -106.750547,
              31.783706
            ],
            [
              -106.531731,
              31.78391
            ],
            [
              -106.528242,
              31.783148
            ],
            [
              -106.528124,
              31.784985
            ],
            [
              -106.527997,
              31.786945
            ],
            [
              -106.527623,
              31.789119
            ],
            [
              -106.527738,
              31.789761
            ],
            [
              -106.527943,
              31.790507
            ],
            [
              -106.530515,
              31.792103
            ],
            [
              -106.53248,
              31.791914
            ],
            [
              -106.533,
              31.791829
            ],
            [
              -106.533043,
              31.791907
            ],
            [
              -106.534743,
              31.796107
            ],
            [
              -106.535154,
              31.797089
            ],
            [
              -106.535343,
              31.797507
            ],
            [
              -106.535843,
              31.798607
            ],
            [
              -106.542097,
              31.802146
            ],
            [
              -106.542144,
              31.802107
            ],
            [
              -106.544714,
              31.804287
            ],
            [
              -106.545344,
              31.805007
            ],
            [
              -106.54657652495901,
              31.8065805235309
            ],
            [
              -106.547144,
              31.807305
            ],
            [
              -106.558444,
              31.810406
            ],
            [
              -106.562945,
              31.811104
            ],
            [
              -106.563444,
              31.812606
            ],
            [
              -106.566844,
              31.813306
            ],
            [
              -106.570944,
              31.810206
            ],
            [
              -106.577244,
              31.810406
            ],
            [
              -106.581344,
              31.813906
            ],
            [
              -106.582144,
              31.815506
            ],
            [
              -106.588045,
              31.822106
            ],
            [
              -106.589045,
              31.822706
            ],
            [
              -106.593826,
              31.824901
            ],
            [
              -106.602727,
              31.825024
            ],
            [
              -106.605267,
              31.827912
            ],
            [
              -106.601945,
              31.839605
            ],
            [
              -106.602045,
              31.844405
            ],
            [
              -106.605245,
              31.845905
            ],
            [
              -106.605845,
              31.846305
            ],
            [
              -106.614637,
              31.84649
            ],
            [
              -106.621857,
              31.852854
            ],
            [
              -106.625763,
              31.856276
            ],
            [
              -106.627808,
              31.860593
            ],
            [
              -106.635926,
              31.866235
            ],
            [
              -106.63588,
              31.871514
            ],
            [
              -106.634873,
              31.874478
            ],
            [
              -106.630799,
              31.879697
            ],
            [
              -106.629197,
              31.883717
            ],
            [
              -106.630692,
              31.886411
            ],
            [
              -106.633927,
              31.889184
            ],
            [
              -106.638154,
              31.891663
            ],
            [
              -106.6429,
              31.892933
            ],
            [
              -106.645296,
              31.894859
            ],
            [
              -106.645646,
              31.895649
            ],
            [
              -106.645479,
              31.89867
            ],
            [
              -106.64084,
              31.904598
            ],
            [
              -106.633668,
              31.90979
            ],
            [
              -106.625947,
              31.912227
            ],
            [
              -106.623445,
              31.914034
            ],
            [
              -106.628663,
              31.923614
            ],
            [
              -106.629747,
              31.92657
            ],
            [
              -106.628499124429,
              31.927552226127002
            ],
            [
              -106.625322,
              31.930053
            ],
            [
              -106.622529,
              31.934863
            ],
            [
              -106.622117,
              31.936621
            ],
            [
              -106.622377,
              31.940863
            ],
            [
              -106.623659,
              31.94551
            ],
            [
              -106.622819,
              31.952891
            ],
            [
              -106.625123,
              31.954531
            ],
            [
              -106.625535,
              31.957476
            ],
            [
              -106.624299,
              31.961054
            ],
            [
              -106.626466,
              31.97069
            ],
            [
              -106.630114,
              31.971258
            ],
            [
              -106.638186,
              31.97682
            ],
            [
              -106.639529,
              31.980348
            ],
            [
              -106.636492,
              31.985719
            ],
            [
              -106.631182,
              31.989809
            ],
            [
              -106.623568,
              31.990999
            ],
            [
              -106.619448,
              31.994733
            ],
            [
              -106.618486,
              32.000495
            ],
            [
              -106.599096,
              32.000731
            ],
            [
              -106.598639,
              32.000754
            ],
            [
              -106.595333,
              32.000778
            ],
            [
              -106.587972,
              32.000749
            ],
            [
              -106.582678,
              32.0007514155868
            ],
            [
              -106.566056,
              32.000759
            ],
            [
              -106.565142,
              32.000736
            ],
            [
              -106.411075,
              32.001334
            ],
            [
              -106.394298,
              32.001484
            ],
            [
              -106.37717982050499,
              32.0011777046509
            ],
            [
              -106.377264,
              32.02301
            ],
            [
              -106.377171,
              32.081588
            ],
            [
              -106.376942,
              32.516482
            ],
            [
              -106.376585,
              32.95805
            ],
            [
              -106.340709,
              32.958382
            ],
            [
              -106.340515,
              33.052777
            ],
            [
              -106.887891,
              32.822781
            ],
            [
              -106.887733,
              32.779273
            ],
            [
              -106.937874,
              32.779365
            ],
            [
              -107.259393,
              32.780066
            ],
            [
              -107.299474,
              32.779923
            ],
            [
              -107.299631,
              32.60537
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "35",
      "COUNTYFP": "025",
      "COUNTYNS": "01702365",
      "AFFGEOID": "0500000US35025",
      "GEOID": "35025",
      "NAME": "Lea",
      "LSAD": "06",
      "ALAND": 11373741670,
      "AWATER": 8469171,
      "County_state": "Lea,35"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.814507,
              32.965114
            ],
            [
              -103.814216,
              32.522368
            ],
            [
              -103.722938,
              32.522344
            ],
            [
              -103.722938,
              32.352915
            ],
            [
              -103.72288201487001,
              32.0002083713881
            ],
            [
              -103.326501,
              32.00037
            ],
            [
              -103.278521,
              32.000419
            ],
            [
              -103.270383,
              32.000326
            ],
            [
              -103.267708,
              32.000324
            ],
            [
              -103.267633,
              32.000475
            ],
            [
              -103.215641,
              32.000513
            ],
            [
              -103.088698,
              32.000453
            ],
            [
              -103.085876,
              32.000465
            ],
            [
              -103.064423,
              32.000518
            ],
            [
              -103.064344,
              32.087051
            ],
            [
              -103.064348,
              32.123041
            ],
            [
              -103.064422,
              32.145006
            ],
            [
              -103.064696,
              32.522193
            ],
            [
              -103.064761,
              32.587983
            ],
            [
              -103.064788,
              32.600397
            ],
            [
              -103.064761,
              32.601863
            ],
            [
              -103.064815,
              32.624537
            ],
            [
              -103.064633,
              32.64642
            ],
            [
              -103.064864,
              32.682647
            ],
            [
              -103.064798,
              32.690761
            ],
            [
              -103.064799,
              32.708694
            ],
            [
              -103.064827,
              32.726628
            ],
            [
              -103.064807,
              32.777303
            ],
            [
              -103.064698,
              32.783602
            ],
            [
              -103.064711,
              32.784593
            ],
            [
              -103.064699,
              32.827531
            ],
            [
              -103.06467362514,
              32.8284134812341
            ],
            [
              -103.064672,
              32.82847
            ],
            [
              -103.064889,
              32.849359
            ],
            [
              -103.064916,
              32.85726
            ],
            [
              -103.064807,
              32.857696
            ],
            [
              -103.064862,
              32.868346
            ],
            [
              -103.064701,
              32.879355
            ],
            [
              -103.064569,
              32.900014
            ],
            [
              -103.064657,
              32.959097
            ],
            [
              -103.064657009318,
              32.9590992345529
            ],
            [
              -103.064679,
              32.964373
            ],
            [
              -103.064625,
              32.999899
            ],
            [
              -103.064452,
              33.01029
            ],
            [
              -103.06398,
              33.038693
            ],
            [
              -103.063905,
              33.042055
            ],
            [
              -103.061784167753,
              33.14088399770589
            ],
            [
              -103.060103,
              33.219225
            ],
            [
              -103.05972,
              33.256262
            ],
            [
              -103.059242,
              33.260371
            ],
            [
              -103.057856,
              33.315234
            ],
            [
              -103.057487,
              33.329477
            ],
            [
              -103.05665530475301,
              33.3884164031742
            ],
            [
              -103.056655,
              33.388438
            ],
            [
              -103.052610538094,
              33.5705747676868
            ],
            [
              -103.510129,
              33.570117
            ],
            [
              -103.720809,
              33.569537
            ],
            [
              -103.721399,
              33.395409
            ],
            [
              -103.766361,
              33.395297
            ],
            [
              -103.766313,
              33.311912
            ],
            [
              -103.76639,
              33.162353
            ],
            [
              -103.766417,
              32.965264
            ],
            [
              -103.778911,
              32.965236
            ],
            [
              -103.814507,
              32.965114
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "35",
      "COUNTYFP": "009",
      "COUNTYNS": "00933053",
      "AFFGEOID": "0500000US35009",
      "GEOID": "35009",
      "NAME": "Curry",
      "LSAD": "06",
      "ALAND": 3640098102,
      "AWATER": 8190594,
      "County_state": "Curry,35"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.738166,
              34.60467
            ],
            [
              -103.740066,
              34.303056
            ],
            [
              -103.472732,
              34.303301
            ],
            [
              -103.45982,
              34.303261
            ],
            [
              -103.319635,
              34.303258
            ],
            [
              -103.251988,
              34.303185
            ],
            [
              -103.14357,
              34.302959
            ],
            [
              -103.043936266136,
              34.302648
            ],
            [
              -103.043978936987,
              34.312749083575
            ],
            [
              -103.043979,
              34.312764
            ],
            [
              -103.043946,
              34.379555
            ],
            [
              -103.043944,
              34.37966
            ],
            [
              -103.043919,
              34.380916
            ],
            [
              -103.043693,
              34.383578
            ],
            [
              -103.04363,
              34.38469
            ],
            [
              -103.043614,
              34.384969
            ],
            [
              -103.043613,
              34.388679
            ],
            [
              -103.043613,
              34.390442
            ],
            [
              -103.043585,
              34.393716
            ],
            [
              -103.043611,
              34.397105
            ],
            [
              -103.043583,
              34.400678
            ],
            [
              -103.043538,
              34.405463
            ],
            [
              -103.043582,
              34.455657
            ],
            [
              -103.043588,
              34.459662
            ],
            [
              -103.043589,
              34.459774
            ],
            [
              -103.043594,
              34.46266
            ],
            [
              -103.043072,
              34.619782
            ],
            [
              -103.043286,
              34.653099
            ],
            [
              -103.042827,
              34.671188
            ],
            [
              -103.042769,
              34.747361
            ],
            [
              -103.04277,
              34.792224
            ],
            [
              -103.042781,
              34.850243
            ],
            [
              -103.042521,
              34.899546
            ],
            [
              -103.042552402864,
              34.9541420854709
            ],
            [
              -103.284149,
              34.954172
            ],
            [
              -103.284317,
              34.86499
            ],
            [
              -103.389798,
              34.865429
            ],
            [
              -103.390274,
              34.778122
            ],
            [
              -103.443031,
              34.778255
            ],
            [
              -103.495646,
              34.778575
            ],
            [
              -103.495653,
              34.691393
            ],
            [
              -103.635246,
              34.691887
            ],
            [
              -103.705788,
              34.692446
            ],
            [
              -103.706088,
              34.60501
            ],
            [
              -103.738166,
              34.60467
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "35",
      "COUNTYFP": "001",
      "COUNTYNS": "01702363",
      "AFFGEOID": "0500000US35001",
      "GEOID": "35001",
      "NAME": "Bernalillo",
      "LSAD": "06",
      "ALAND": 3007310205,
      "AWATER": 16190430,
      "County_state": "Bernalillo,35"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -107.197244,
              35.219459
            ],
            [
              -107.066562,
              34.95719
            ],
            [
              -107.024996,
              34.871018
            ],
            [
              -107.02441,
              34.869953
            ],
            [
              -106.766228,
              34.869967
            ],
            [
              -106.721834,
              34.870057
            ],
            [
              -106.720091,
              34.872141
            ],
            [
              -106.702871,
              34.885331
            ],
            [
              -106.685955,
              34.906004
            ],
            [
              -106.429486,
              34.9034
            ],
            [
              -106.414475,
              34.892225
            ],
            [
              -106.411558,
              34.889733
            ],
            [
              -106.411781,
              34.870252
            ],
            [
              -106.360659,
              34.870331
            ],
            [
              -106.149737,
              34.870142
            ],
            [
              -106.149889,
              34.91902
            ],
            [
              -106.14982,
              34.949915
            ],
            [
              -106.156793,
              34.953426
            ],
            [
              -106.234775,
              34.953394
            ],
            [
              -106.243762,
              34.953355
            ],
            [
              -106.243874,
              35.040003
            ],
            [
              -106.244216,
              35.215657
            ],
            [
              -106.4024,
              35.217396
            ],
            [
              -106.459379,
              35.217942
            ],
            [
              -106.504214,
              35.217924
            ],
            [
              -106.574047,
              35.217943
            ],
            [
              -106.593595,
              35.217844
            ],
            [
              -106.598725,
              35.217852
            ],
            [
              -106.605382,
              35.217442
            ],
            [
              -106.639727,
              35.202946
            ],
            [
              -106.643366,
              35.197762
            ],
            [
              -106.642494,
              35.207297
            ],
            [
              -106.658119,
              35.217802
            ],
            [
              -106.661058,
              35.217922
            ],
            [
              -106.663867,
              35.217926
            ],
            [
              -106.696565,
              35.217963
            ],
            [
              -106.72899,
              35.217978
            ],
            [
              -106.763062,
              35.217994
            ],
            [
              -106.962038,
              35.218392
            ],
            [
              -107.197244,
              35.219459
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "35",
      "COUNTYFP": "005",
      "COUNTYNS": "01702364",
      "AFFGEOID": "0500000US35005",
      "GEOID": "35005",
      "NAME": "Chaves",
      "LSAD": "06",
      "ALAND": 15713841748,
      "AWATER": 20545303,
      "County_state": "Chaves,35"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -105.35344,
              32.604736
            ],
            [
              -105.35401,
              32.518776
            ],
            [
              -105.149278,
              32.520722
            ],
            [
              -104.851524,
              32.520541
            ],
            [
              -104.842171,
              32.52054
            ],
            [
              -104.841576,
              32.963213
            ],
            [
              -104.550721,
              32.963997
            ],
            [
              -104.362212,
              32.964599
            ],
            [
              -104.358456,
              32.964439
            ],
            [
              -104.05099,
              32.965395
            ],
            [
              -103.814507,
              32.965114
            ],
            [
              -103.778911,
              32.965236
            ],
            [
              -103.766417,
              32.965264
            ],
            [
              -103.76639,
              33.162353
            ],
            [
              -103.766313,
              33.311912
            ],
            [
              -103.766361,
              33.395297
            ],
            [
              -103.721399,
              33.395409
            ],
            [
              -103.720809,
              33.569537
            ],
            [
              -103.510129,
              33.570117
            ],
            [
              -103.509759,
              33.657262
            ],
            [
              -103.718333,
              33.65621
            ],
            [
              -103.716885,
              33.819558
            ],
            [
              -103.841933,
              33.8192
            ],
            [
              -103.841691,
              34.081909
            ],
            [
              -103.875518,
              34.082106
            ],
            [
              -103.946019,
              34.082463
            ],
            [
              -104.1556,
              34.083205
            ],
            [
              -104.156269,
              33.995413
            ],
            [
              -104.317404,
              33.995877
            ],
            [
              -104.786104,
              34.000137
            ],
            [
              -104.786429,
              34.087664
            ],
            [
              -104.893377,
              34.088407
            ],
            [
              -104.894573,
              33.82443
            ],
            [
              -104.89109,
              33.822258
            ],
            [
              -104.892056,
              33.594681
            ],
            [
              -104.892168,
              33.566376
            ],
            [
              -104.892433,
              33.398199
            ],
            [
              -104.885088,
              33.393271
            ],
            [
              -104.885405,
              33.34731
            ],
            [
              -104.88513,
              33.305827
            ],
            [
              -104.903838,
              33.305904
            ],
            [
              -104.905431,
              33.22094
            ],
            [
              -104.905757,
              33.138904
            ],
            [
              -105.006481,
              33.134401
            ],
            [
              -105.074892,
              33.134208
            ],
            [
              -105.108621,
              33.131742
            ],
            [
              -105.316968,
              33.132303
            ],
            [
              -105.316224,
              33.002719
            ],
            [
              -105.316047,
              32.9621
            ],
            [
              -105.352513,
              32.962007
            ],
            [
              -105.35344,
              32.604736
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "35",
      "COUNTYFP": "021",
      "COUNTYNS": "00933055",
      "AFFGEOID": "0500000US35021",
      "GEOID": "35021",
      "NAME": "Harding",
      "LSAD": "06",
      "ALAND": 5504963090,
      "AWATER": 1162620,
      "County_state": "Harding,35"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.430465,
              36.213598
            ],
            [
              -104.439119,
              36.193471
            ],
            [
              -104.414429,
              36.176836
            ],
            [
              -104.419908,
              36.16828
            ],
            [
              -104.404361,
              36.155401
            ],
            [
              -104.414546,
              36.123761
            ],
            [
              -104.402922,
              36.117006
            ],
            [
              -104.401075,
              36.091545
            ],
            [
              -104.371028,
              36.066813
            ],
            [
              -104.37975,
              36.049269
            ],
            [
              -104.365724,
              36.04429
            ],
            [
              -104.360549,
              36.023923
            ],
            [
              -104.365294,
              36.013427
            ],
            [
              -104.349853,
              36.009999
            ],
            [
              -104.341171,
              35.991875
            ],
            [
              -104.355724,
              35.982659
            ],
            [
              -104.327284,
              35.960197
            ],
            [
              -104.352497,
              35.940411
            ],
            [
              -104.354406,
              35.929233
            ],
            [
              -104.343739,
              35.912213
            ],
            [
              -104.372596,
              35.883068
            ],
            [
              -104.381918,
              35.861958
            ],
            [
              -104.390522,
              35.859843
            ],
            [
              -104.364959,
              35.842196
            ],
            [
              -104.379919,
              35.835776
            ],
            [
              -104.36691,
              35.829399
            ],
            [
              -104.378125,
              35.823601
            ],
            [
              -104.364118,
              35.812633
            ],
            [
              -104.371119,
              35.804571
            ],
            [
              -104.362168,
              35.783181
            ],
            [
              -104.37012,
              35.779302
            ],
            [
              -104.107426,
              35.781742
            ],
            [
              -104.021702,
              35.781494
            ],
            [
              -103.976901,
              35.801747
            ],
            [
              -103.723609,
              35.422785
            ],
            [
              -103.637026,
              35.422339
            ],
            [
              -103.637053,
              35.389662
            ],
            [
              -103.388952,
              35.389622
            ],
            [
              -103.37973,
              35.396796
            ],
            [
              -103.379556,
              35.494718
            ],
            [
              -103.379393,
              35.65252
            ],
            [
              -103.375117,
              35.739516
            ],
            [
              -103.374864,
              35.998657
            ],
            [
              -103.365062,
              35.998664
            ],
            [
              -103.364977,
              36.08605
            ],
            [
              -103.794525,
              36.085738
            ],
            [
              -103.793991,
              36.173972
            ],
            [
              -104.008864,
              36.174527
            ],
            [
              -104.008823,
              36.218115
            ],
            [
              -104.436056,
              36.218534
            ],
            [
              -104.430465,
              36.213598
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "35",
      "COUNTYFP": "007",
      "COUNTYNS": "00929117",
      "AFFGEOID": "0500000US35007",
      "GEOID": "35007",
      "NAME": "Colfax",
      "LSAD": "06",
      "ALAND": 9733110215,
      "AWATER": 26299618,
      "County_state": "Colfax,35"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -105.349762,
              36.561419
            ],
            [
              -105.377745,
              36.552096
            ],
            [
              -105.348999,
              36.53304
            ],
            [
              -105.343874,
              36.511151
            ],
            [
              -105.318718,
              36.491693
            ],
            [
              -105.336373,
              36.4835
            ],
            [
              -105.339578,
              36.470464
            ],
            [
              -105.356282,
              36.452297
            ],
            [
              -105.353031,
              36.443061
            ],
            [
              -105.338104,
              36.444844
            ],
            [
              -105.332704,
              36.428787
            ],
            [
              -105.340454,
              36.416078
            ],
            [
              -105.327301,
              36.40543
            ],
            [
              -105.32734,
              36.38032
            ],
            [
              -105.332091,
              36.366954
            ],
            [
              -105.328953,
              36.337257
            ],
            [
              -105.335651,
              36.301477
            ],
            [
              -105.327521,
              36.277036
            ],
            [
              -105.341264,
              36.261863
            ],
            [
              -104.865244,
              36.261419
            ],
            [
              -104.865402,
              36.217869
            ],
            [
              -104.436056,
              36.218534
            ],
            [
              -104.008823,
              36.218115
            ],
            [
              -104.00845,
              36.567616
            ],
            [
              -104.009342,
              36.697657
            ],
            [
              -104.007851691976,
              36.99623902179619
            ],
            [
              -104.007855,
              36.996239
            ],
            [
              -104.250536,
              36.994644
            ],
            [
              -104.338833,
              36.993535
            ],
            [
              -104.519257,
              36.993766
            ],
            [
              -104.624556,
              36.994377
            ],
            [
              -104.625545,
              36.993599
            ],
            [
              -104.645029,
              36.993378
            ],
            [
              -104.732031,
              36.993447
            ],
            [
              -104.73212,
              36.993484
            ],
            [
              -105.000554,
              36.993264
            ],
            [
              -105.029228,
              36.992729
            ],
            [
              -105.1208,
              36.995428
            ],
            [
              -105.13644756670801,
              36.9953873968744
            ],
            [
              -105.155042271642,
              36.9953391463601
            ],
            [
              -105.22050650671301,
              36.995169276334394
            ],
            [
              -105.236167,
              36.981949
            ],
            [
              -105.236154,
              36.962341
            ],
            [
              -105.211793,
              36.919728
            ],
            [
              -105.200117,
              36.915628
            ],
            [
              -105.229372,
              36.84771
            ],
            [
              -105.223083,
              36.833541
            ],
            [
              -105.233438,
              36.811996
            ],
            [
              -105.226718,
              36.804683
            ],
            [
              -105.235298,
              36.790656
            ],
            [
              -105.226639,
              36.771432
            ],
            [
              -105.224327,
              36.73408
            ],
            [
              -105.230632,
              36.723784
            ],
            [
              -105.250287,
              36.717368
            ],
            [
              -105.280771,
              36.718335
            ],
            [
              -105.290843,
              36.728464
            ],
            [
              -105.313223,
              36.723236
            ],
            [
              -105.326616,
              36.69786
            ],
            [
              -105.335298,
              36.702394
            ],
            [
              -105.352327,
              36.697226
            ],
            [
              -105.370638,
              36.681032
            ],
            [
              -105.360344,
              36.662979
            ],
            [
              -105.364105,
              36.654077
            ],
            [
              -105.351385,
              36.636905
            ],
            [
              -105.363362,
              36.629098
            ],
            [
              -105.374793,
              36.608622
            ],
            [
              -105.357922,
              36.589749
            ],
            [
              -105.349762,
              36.561419
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "35",
      "COUNTYFP": "019",
      "COUNTYNS": "00929111",
      "AFFGEOID": "0500000US35019",
      "GEOID": "35019",
      "NAME": "Guadalupe",
      "LSAD": "06",
      "ALAND": 7847071878,
      "AWATER": 2888161,
      "County_state": "Guadalupe,35"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -105.312765,
              34.606951
            ],
            [
              -105.313072,
              34.347205
            ],
            [
              -104.891745,
              34.347043
            ],
            [
              -104.892023,
              34.604426
            ],
            [
              -104.444762,
              34.604952
            ],
            [
              -104.444935,
              34.691655
            ],
            [
              -104.339725,
              34.692234
            ],
            [
              -104.339817,
              34.779062
            ],
            [
              -104.129143,
              34.779337
            ],
            [
              -104.129018,
              34.954592
            ],
            [
              -104.125601,
              34.969004
            ],
            [
              -104.125137,
              35.083436
            ],
            [
              -104.125121,
              35.142058
            ],
            [
              -104.125136,
              35.215696
            ],
            [
              -104.355204,
              35.21606
            ],
            [
              -104.891141,
              35.216371
            ],
            [
              -105.082522,
              35.216431
            ],
            [
              -105.169417,
              35.21646
            ],
            [
              -105.291169,
              35.216489
            ],
            [
              -105.290791,
              35.04203
            ],
            [
              -105.290396,
              34.606766
            ],
            [
              -105.312765,
              34.606951
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "35",
      "COUNTYFP": "041",
      "COUNTYNS": "01702369",
      "AFFGEOID": "0500000US35041",
      "GEOID": "35041",
      "NAME": "Roosevelt",
      "LSAD": "06",
      "ALAND": 6335224660,
      "AWATER": 18697914,
      "County_state": "Roosevelt,35"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.948778,
              34.605058
            ],
            [
              -103.94917,
              34.259648
            ],
            [
              -103.94567,
              34.250072
            ],
            [
              -103.946019,
              34.082463
            ],
            [
              -103.875518,
              34.082106
            ],
            [
              -103.841691,
              34.081909
            ],
            [
              -103.841933,
              33.8192
            ],
            [
              -103.716885,
              33.819558
            ],
            [
              -103.718333,
              33.65621
            ],
            [
              -103.509759,
              33.657262
            ],
            [
              -103.510129,
              33.570117
            ],
            [
              -103.052610538094,
              33.5705747676868
            ],
            [
              -103.05261,
              33.570599
            ],
            [
              -103.051664,
              33.629489
            ],
            [
              -103.051363,
              33.64195
            ],
            [
              -103.051535,
              33.650487
            ],
            [
              -103.051087,
              33.658186
            ],
            [
              -103.050532,
              33.672408
            ],
            [
              -103.050148,
              33.701971
            ],
            [
              -103.049608,
              33.737766
            ],
            [
              -103.049096,
              33.74627
            ],
            [
              -103.04734601957101,
              33.8246741231489
            ],
            [
              -103.047346,
              33.824675
            ],
            [
              -103.046907,
              33.8503
            ],
            [
              -103.045644,
              33.901537
            ],
            [
              -103.045698,
              33.906299
            ],
            [
              -103.044893,
              33.945617
            ],
            [
              -103.04395,
              33.974629
            ],
            [
              -103.043617,
              34.003633
            ],
            [
              -103.043571877651,
              34.0111784012571
            ],
            [
              -103.043531,
              34.018014
            ],
            [
              -103.043555,
              34.032714
            ],
            [
              -103.043746,
              34.037294
            ],
            [
              -103.043771,
              34.041538
            ],
            [
              -103.043721,
              34.04232
            ],
            [
              -103.043767,
              34.043545
            ],
            [
              -103.043744,
              34.049986
            ],
            [
              -103.043686,
              34.063078
            ],
            [
              -103.043516,
              34.079382
            ],
            [
              -103.043569,
              34.087947
            ],
            [
              -103.043580047453,
              34.112834113618
            ],
            [
              -103.043644,
              34.256903
            ],
            [
              -103.043719,
              34.289441
            ],
            [
              -103.043936,
              34.302585
            ],
            [
              -103.043936266136,
              34.302648
            ],
            [
              -103.14357,
              34.302959
            ],
            [
              -103.251988,
              34.303185
            ],
            [
              -103.319635,
              34.303258
            ],
            [
              -103.45982,
              34.303261
            ],
            [
              -103.472732,
              34.303301
            ],
            [
              -103.740066,
              34.303056
            ],
            [
              -103.738166,
              34.60467
            ],
            [
              -103.948778,
              34.605058
            ]
          ]
        ]
      ]
    }
  }
]
};