export const counties_al = {
  "type":"FeatureCollection","name":"al_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "013",
      "COUNTYNS": "00161532",
      "AFFGEOID": "0500000US01013",
      "GEOID": "01013",
      "NAME": "Butler",
      "LSAD": "06",
      "ALAND": 2011985458,
      "AWATER": 2718248,
      "County_state": "Butler,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.908939,
              31.961673
            ],
            [
              -86.906899,
              31.830628
            ],
            [
              -86.905899,
              31.753035
            ],
            [
              -86.906461,
              31.632622
            ],
            [
              -86.893959,
              31.61634
            ],
            [
              -86.888106,
              31.592207
            ],
            [
              -86.874565,
              31.577993
            ],
            [
              -86.866254,
              31.54642
            ],
            [
              -86.84351,
              31.535654
            ],
            [
              -86.839386,
              31.525204
            ],
            [
              -86.701554,
              31.523946
            ],
            [
              -86.499213,
              31.525331
            ],
            [
              -86.499533,
              31.655247
            ],
            [
              -86.448635,
              31.655617
            ],
            [
              -86.445978,
              31.70112
            ],
            [
              -86.446411,
              31.831661
            ],
            [
              -86.438363,
              31.84284
            ],
            [
              -86.446619,
              31.846429
            ],
            [
              -86.448198,
              31.964629
            ],
            [
              -86.857583,
              31.962167
            ],
            [
              -86.908939,
              31.961673
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "031",
      "COUNTYNS": "00161541",
      "AFFGEOID": "0500000US01031",
      "GEOID": "01031",
      "NAME": "Coffee",
      "LSAD": "06",
      "ALAND": 1758565927,
      "AWATER": 3907189,
      "County_state": "Coffee,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.196365,
              31.425431
            ],
            [
              -86.195044,
              31.352681
            ],
            [
              -86.193476,
              31.192213
            ],
            [
              -86.125482,
              31.192754
            ],
            [
              -86.116736,
              31.182099
            ],
            [
              -86.116734,
              31.193015
            ],
            [
              -86.058535,
              31.194082
            ],
            [
              -85.791402,
              31.196349
            ],
            [
              -85.7901,
              31.336276
            ],
            [
              -85.789785,
              31.356562
            ],
            [
              -85.789142,
              31.617964
            ],
            [
              -86.145895,
              31.617741
            ],
            [
              -86.14395,
              31.537675
            ],
            [
              -86.150314,
              31.537601
            ],
            [
              -86.156561,
              31.530203
            ],
            [
              -86.194784,
              31.529949
            ],
            [
              -86.193951,
              31.440072
            ],
            [
              -86.196365,
              31.425431
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "103",
      "COUNTYNS": "00161578",
      "AFFGEOID": "0500000US01103",
      "GEOID": "01103",
      "NAME": "Morgan",
      "LSAD": "06",
      "ALAND": 1500602270,
      "AWATER": 50931876,
      "County_state": "Morgan,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.10937,
              34.415814
            ],
            [
              -87.110111,
              34.313799
            ],
            [
              -87.09198,
              34.312296
            ],
            [
              -86.838659,
              34.308173
            ],
            [
              -86.814585,
              34.307761
            ],
            [
              -86.581936,
              34.304694
            ],
            [
              -86.581634,
              34.33168
            ],
            [
              -86.58132,
              34.371094
            ],
            [
              -86.573656,
              34.418158
            ],
            [
              -86.573362,
              34.42014
            ],
            [
              -86.553093,
              34.54316
            ],
            [
              -86.550166,
              34.545963
            ],
            [
              -86.553542,
              34.561659
            ],
            [
              -86.567965,
              34.575516
            ],
            [
              -86.598488,
              34.582107
            ],
            [
              -86.614169,
              34.577227
            ],
            [
              -86.636195,
              34.555198
            ],
            [
              -86.66514,
              34.552092
            ],
            [
              -86.676558,
              34.577794
            ],
            [
              -86.702185,
              34.589004
            ],
            [
              -86.721721,
              34.582709
            ],
            [
              -86.753138,
              34.555672
            ],
            [
              -86.790056,
              34.55079
            ],
            [
              -86.836897,
              34.556186
            ],
            [
              -86.860116,
              34.567128
            ],
            [
              -86.907205,
              34.579792
            ],
            [
              -86.97279,
              34.613344
            ],
            [
              -86.980266,
              34.618564
            ],
            [
              -86.992053,
              34.627297
            ],
            [
              -87.00658,
              34.634113
            ],
            [
              -87.035323,
              34.652042
            ],
            [
              -87.052861,
              34.650399
            ],
            [
              -87.105073,
              34.686037
            ],
            [
              -87.10576,
              34.605623
            ],
            [
              -87.106213,
              34.574389
            ],
            [
              -87.10937,
              34.415814
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "003",
      "COUNTYNS": "00161527",
      "AFFGEOID": "0500000US01003",
      "GEOID": "01003",
      "NAME": "Baldwin",
      "LSAD": "06",
      "ALAND": 4117605847,
      "AWATER": 1133109409,
      "County_state": "Baldwin,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.029272,
              30.222714
            ],
            [
              -88.028401,
              30.221132
            ],
            [
              -88.014572,
              30.222366
            ],
            [
              -87.999996,
              30.225753
            ],
            [
              -87.962253,
              30.229522
            ],
            [
              -87.926119,
              30.230373
            ],
            [
              -87.838462,
              30.227185
            ],
            [
              -87.80056,
              30.229365
            ],
            [
              -87.73553,
              30.240679
            ],
            [
              -87.656888,
              30.249709
            ],
            [
              -87.581362,
              30.269257
            ],
            [
              -87.558097,
              30.274437
            ],
            [
              -87.544533,
              30.275659
            ],
            [
              -87.518324,
              30.280435
            ],
            [
              -87.51838,
              30.283901
            ],
            [
              -87.509407,
              30.292188
            ],
            [
              -87.466903,
              30.302264
            ],
            [
              -87.464143,
              30.311797
            ],
            [
              -87.485674,
              30.308937
            ],
            [
              -87.492851,
              30.311797
            ],
            [
              -87.496163,
              30.317992
            ],
            [
              -87.490642,
              30.323234
            ],
            [
              -87.481257,
              30.32657
            ],
            [
              -87.464881,
              30.333298
            ],
            [
              -87.462982,
              30.333998
            ],
            [
              -87.459982,
              30.336298
            ],
            [
              -87.452282,
              30.344097
            ],
            [
              -87.450966,
              30.34626
            ],
            [
              -87.451983,
              30.360296
            ],
            [
              -87.451883,
              30.364996
            ],
            [
              -87.451383,
              30.367196
            ],
            [
              -87.449083,
              30.370396
            ],
            [
              -87.441828,
              30.376301
            ],
            [
              -87.438684,
              30.380794
            ],
            [
              -87.438684,
              30.382094
            ],
            [
              -87.440884,
              30.386694
            ],
            [
              -87.441184,
              30.388594
            ],
            [
              -87.440684,
              30.391494
            ],
            [
              -87.437284,
              30.395894
            ],
            [
              -87.434284,
              30.397494
            ],
            [
              -87.431784,
              30.403193
            ],
            [
              -87.4306873846154,
              30.404837923076897
            ],
            [
              -87.429584,
              30.406493
            ],
            [
              -87.426184,
              30.409193
            ],
            [
              -87.422684,
              30.410093
            ],
            [
              -87.419184,
              30.410193
            ],
            [
              -87.413184,
              30.408993
            ],
            [
              -87.408884,
              30.408793
            ],
            [
              -87.403485,
              30.410193
            ],
            [
              -87.401785,
              30.411393
            ],
            [
              -87.398784,
              30.415093
            ],
            [
              -87.395684,
              30.417592
            ],
            [
              -87.386385,
              30.420492
            ],
            [
              -87.3843547793814,
              30.421625146391797
            ],
            [
              -87.382085,
              30.422892
            ],
            [
              -87.371179,
              30.430485
            ],
            [
              -87.368201,
              30.433402
            ],
            [
              -87.366601,
              30.436643
            ],
            [
              -87.366949,
              30.440475
            ],
            [
              -87.36869,
              30.444625
            ],
            [
              -87.370778,
              30.446859
            ],
            [
              -87.381185,
              30.450091
            ],
            [
              -87.391985,
              30.451591
            ],
            [
              -87.396886,
              30.450591
            ],
            [
              -87.399886,
              30.450991
            ],
            [
              -87.404685,
              30.45289
            ],
            [
              -87.407885,
              30.456389
            ],
            [
              -87.414685,
              30.457289
            ],
            [
              -87.425086,
              30.465589
            ],
            [
              -87.429586,
              30.470588
            ],
            [
              -87.430586,
              30.476588
            ],
            [
              -87.431586,
              30.477688
            ],
            [
              -87.434686,
              30.479188
            ],
            [
              -87.435585,
              30.480488
            ],
            [
              -87.432986,
              30.484888
            ],
            [
              -87.430586,
              30.491087
            ],
            [
              -87.431186,
              30.495786
            ],
            [
              -87.438277,
              30.505348
            ],
            [
              -87.439698,
              30.506639
            ],
            [
              -87.443228,
              30.506772
            ],
            [
              -87.444722,
              30.507484
            ],
            [
              -87.447709,
              30.510448
            ],
            [
              -87.447789,
              30.511903
            ],
            [
              -87.447313,
              30.512619
            ],
            [
              -87.446507,
              30.513559
            ],
            [
              -87.44519,
              30.51397
            ],
            [
              -87.444952,
              30.514933
            ],
            [
              -87.446435,
              30.520296
            ],
            [
              -87.446594,
              30.527057
            ],
            [
              -87.435449,
              30.549129
            ],
            [
              -87.434972,
              30.549588
            ],
            [
              -87.43145,
              30.550252
            ],
            [
              -87.4279,
              30.554147
            ],
            [
              -87.426046,
              30.560061
            ],
            [
              -87.423372,
              30.561413
            ],
            [
              -87.422815,
              30.561367
            ],
            [
              -87.422418,
              30.560427
            ],
            [
              -87.420935,
              30.560656
            ],
            [
              -87.418657,
              30.561825
            ],
            [
              -87.41667,
              30.566294
            ],
            [
              -87.416961,
              30.567991
            ],
            [
              -87.418523,
              30.569549
            ],
            [
              -87.418364,
              30.570031
            ],
            [
              -87.416271,
              30.572436
            ],
            [
              -87.414523,
              30.573444
            ],
            [
              -87.412722,
              30.573215
            ],
            [
              -87.408747,
              30.583688
            ],
            [
              -87.406569,
              30.599915
            ],
            [
              -87.404608,
              30.603375
            ],
            [
              -87.401189,
              30.604383
            ],
            [
              -87.399281,
              30.605597
            ],
            [
              -87.39732,
              30.608714
            ],
            [
              -87.395038,
              30.615267
            ],
            [
              -87.395065,
              30.615886
            ],
            [
              -87.396442,
              30.616895
            ],
            [
              -87.396442,
              30.61772
            ],
            [
              -87.395671,
              30.623358
            ],
            [
              -87.394491,
              30.625178
            ],
            [
              -87.393787,
              30.626991
            ],
            [
              -87.3936,
              30.630865
            ],
            [
              -87.395953,
              30.643953
            ],
            [
              -87.397197,
              30.648101
            ],
            [
              -87.396189,
              30.650438
            ],
            [
              -87.397274,
              30.654335
            ],
            [
              -87.400189,
              30.657201
            ],
            [
              -87.400719,
              30.657132
            ],
            [
              -87.405886,
              30.666599
            ],
            [
              -87.40713,
              30.671779
            ],
            [
              -87.406573,
              30.674002
            ],
            [
              -87.40697,
              30.675148
            ],
            [
              -87.412751,
              30.678038
            ],
            [
              -87.419539,
              30.679964
            ],
            [
              -87.424895,
              30.683356
            ],
            [
              -87.430384,
              30.688627
            ],
            [
              -87.436032,
              30.68865
            ],
            [
              -87.439825,
              30.690461
            ],
            [
              -87.442291,
              30.692661
            ],
            [
              -87.443591,
              30.694586
            ],
            [
              -87.449373,
              30.698894
            ],
            [
              -87.451415,
              30.699787
            ],
            [
              -87.456958,
              30.697541
            ],
            [
              -87.466348,
              30.700816
            ],
            [
              -87.467727,
              30.701664
            ],
            [
              -87.470407,
              30.705262
            ],
            [
              -87.474439,
              30.706567
            ],
            [
              -87.479589,
              30.712845
            ],
            [
              -87.479829,
              30.71493
            ],
            [
              -87.481235,
              30.716488
            ],
            [
              -87.487045,
              30.71848
            ],
            [
              -87.496781,
              30.720333
            ],
            [
              -87.497524,
              30.720103
            ],
            [
              -87.502326,
              30.72157
            ],
            [
              -87.505162,
              30.726292
            ],
            [
              -87.511738,
              30.733514
            ],
            [
              -87.523621,
              30.738285
            ],
            [
              -87.532615,
              30.743467
            ],
            [
              -87.535373,
              30.749753
            ],
            [
              -87.535424,
              30.754738
            ],
            [
              -87.536536,
              30.76136
            ],
            [
              -87.537093,
              30.762507
            ],
            [
              -87.542268,
              30.767481
            ],
            [
              -87.546168,
              30.771997
            ],
            [
              -87.545372,
              30.774082
            ],
            [
              -87.545052,
              30.778642
            ],
            [
              -87.552059,
              30.78623
            ],
            [
              -87.552962,
              30.786917
            ],
            [
              -87.554845,
              30.787101
            ],
            [
              -87.559491,
              30.790423
            ],
            [
              -87.560075,
              30.792234
            ],
            [
              -87.564216,
              30.796221
            ],
            [
              -87.568147,
              30.799063
            ],
            [
              -87.57205,
              30.800507
            ],
            [
              -87.576856,
              30.808138
            ],
            [
              -87.581876,
              30.812377
            ],
            [
              -87.587876,
              30.815011
            ],
            [
              -87.594303,
              30.816958
            ],
            [
              -87.600492,
              30.820601
            ],
            [
              -87.601636,
              30.825113
            ],
            [
              -87.603576,
              30.828597
            ],
            [
              -87.605782,
              30.831277
            ],
            [
              -87.610988,
              30.832605
            ],
            [
              -87.615928,
              30.834666
            ],
            [
              -87.615373,
              30.837004
            ],
            [
              -87.617287,
              30.840326
            ],
            [
              -87.624142,
              30.845685
            ],
            [
              -87.62608,
              30.846466
            ],
            [
              -87.627328,
              30.847933
            ],
            [
              -87.626502,
              30.851852
            ],
            [
              -87.625385,
              30.854327
            ],
            [
              -87.626233,
              30.857099
            ],
            [
              -87.62825,
              30.860102
            ],
            [
              -87.634943,
              30.865857
            ],
            [
              -87.629993,
              30.877657
            ],
            [
              -87.62946,
              30.880085
            ],
            [
              -87.624406,
              30.884666
            ],
            [
              -87.622068,
              30.885378
            ],
            [
              -87.620794,
              30.887464
            ],
            [
              -87.620928,
              30.889893
            ],
            [
              -87.622525,
              30.89365
            ],
            [
              -87.622209,
              30.897478
            ],
            [
              -87.620721,
              30.8989
            ],
            [
              -87.61602,
              30.901423
            ],
            [
              -87.614958,
              30.904196
            ],
            [
              -87.614216,
              30.908505
            ],
            [
              -87.611854,
              30.91451
            ],
            [
              -87.610207,
              30.916597
            ],
            [
              -87.608269,
              30.921869
            ],
            [
              -87.607818,
              30.924459
            ],
            [
              -87.602692,
              30.934245
            ],
            [
              -87.600699,
              30.937042
            ],
            [
              -87.598307,
              30.938761
            ],
            [
              -87.596898,
              30.941099
            ],
            [
              -87.592064,
              30.95146
            ],
            [
              -87.589196,
              30.964431
            ],
            [
              -87.590926,
              30.969381
            ],
            [
              -87.593055,
              30.972933
            ],
            [
              -87.59412,
              30.976301
            ],
            [
              -87.594173,
              30.977538
            ],
            [
              -87.592685,
              30.980106
            ],
            [
              -87.593404,
              30.982925
            ],
            [
              -87.596731,
              30.987576
            ],
            [
              -87.599181,
              30.995688
            ],
            [
              -87.598937,
              30.997422
            ],
            [
              -87.5988285301321,
              30.9974236243995
            ],
            [
              -87.615431,
              30.997361
            ],
            [
              -87.615417,
              31.041002
            ],
            [
              -87.61589,
              31.244458
            ],
            [
              -87.632226,
              31.242931
            ],
            [
              -87.676419,
              31.261992
            ],
            [
              -87.690614,
              31.282916
            ],
            [
              -87.71765,
              31.303124
            ],
            [
              -87.765152,
              31.297346
            ],
            [
              -87.795633,
              31.302737
            ],
            [
              -87.804896,
              31.318885
            ],
            [
              -87.808973,
              31.306638
            ],
            [
              -87.785775,
              31.282944
            ],
            [
              -87.801502,
              31.269812
            ],
            [
              -87.828313,
              31.275304
            ],
            [
              -87.841158,
              31.242769
            ],
            [
              -87.83592,
              31.231053
            ],
            [
              -87.860066,
              31.2354
            ],
            [
              -87.853499,
              31.203445
            ],
            [
              -87.869774,
              31.215765
            ],
            [
              -87.879167,
              31.197996
            ],
            [
              -87.889581,
              31.204349
            ],
            [
              -87.913637,
              31.187356
            ],
            [
              -87.946588,
              31.19293
            ],
            [
              -87.941318,
              31.16305
            ],
            [
              -87.972869,
              31.162694
            ],
            [
              -87.966284,
              31.148261
            ],
            [
              -87.940121,
              31.147237
            ],
            [
              -87.946577,
              31.121685
            ],
            [
              -87.976222,
              31.089535
            ],
            [
              -87.963823,
              31.068287
            ],
            [
              -87.944787,
              31.066278
            ],
            [
              -87.941152,
              31.048161
            ],
            [
              -87.957352,
              31.029368
            ],
            [
              -87.950506,
              31.022089
            ],
            [
              -87.9648,
              31.008187
            ],
            [
              -87.956619,
              30.989778
            ],
            [
              -87.965187,
              30.967784
            ],
            [
              -87.943552,
              30.961773
            ],
            [
              -87.92429,
              30.935405
            ],
            [
              -87.952311,
              30.924882
            ],
            [
              -87.954492,
              30.918767
            ],
            [
              -87.981261,
              30.886895
            ],
            [
              -87.980971,
              30.86187
            ],
            [
              -87.954476,
              30.849408
            ],
            [
              -87.944546,
              30.827046
            ],
            [
              -87.987065,
              30.811445
            ],
            [
              -87.990601,
              30.794155
            ],
            [
              -88.016698,
              30.781087
            ],
            [
              -88.026319,
              30.753358
            ],
            [
              -88.000997,
              30.698289
            ],
            [
              -88.008396,
              30.684956
            ],
            [
              -87.99077964503859,
              30.6785661853658
            ],
            [
              -87.981196,
              30.67509
            ],
            [
              -87.955989,
              30.658862
            ],
            [
              -87.936717,
              30.657432
            ],
            [
              -87.93107,
              30.652694
            ],
            [
              -87.919346,
              30.63606
            ],
            [
              -87.91253,
              30.615795
            ],
            [
              -87.914956,
              30.585893
            ],
            [
              -87.911431,
              30.576261
            ],
            [
              -87.907891,
              30.573114
            ],
            [
              -87.904168,
              30.565985
            ],
            [
              -87.901711,
              30.550879
            ],
            [
              -87.9021722477051,
              30.549188308728397
            ],
            [
              -87.905343,
              30.537566
            ],
            [
              -87.911141,
              30.525848
            ],
            [
              -87.933355,
              30.487357
            ],
            [
              -87.931902,
              30.4811
            ],
            [
              -87.924211,
              30.4761
            ],
            [
              -87.920031,
              30.470645
            ],
            [
              -87.914136,
              30.446144
            ],
            [
              -87.908908,
              30.41424
            ],
            [
              -87.906343,
              30.40938
            ],
            [
              -87.865017,
              30.38345
            ],
            [
              -87.853806,
              30.378481
            ],
            [
              -87.845132,
              30.377446
            ],
            [
              -87.837239,
              30.369324
            ],
            [
              -87.8313794943197,
              30.3569703880106
            ],
            [
              -87.82988,
              30.353809
            ],
            [
              -87.809266,
              30.332702
            ],
            [
              -87.796717,
              30.324198
            ],
            [
              -87.772758,
              30.311701
            ],
            [
              -87.755516,
              30.291217
            ],
            [
              -87.755263,
              30.277292
            ],
            [
              -87.766626,
              30.262353
            ],
            [
              -87.78775,
              30.254244
            ],
            [
              -87.802087,
              30.253054
            ],
            [
              -87.817743,
              30.254292
            ],
            [
              -87.860085,
              30.240289
            ],
            [
              -87.879343,
              30.23859
            ],
            [
              -87.893201,
              30.239237
            ],
            [
              -87.90046,
              30.241531
            ],
            [
              -87.913762,
              30.247837
            ],
            [
              -87.918247,
              30.253308
            ],
            [
              -87.936041,
              30.261469
            ],
            [
              -87.948979,
              30.256564
            ],
            [
              -87.966847,
              30.235618
            ],
            [
              -88.023991,
              30.23039
            ],
            [
              -88.029272,
              30.222714
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "085",
      "COUNTYNS": "00161568",
      "AFFGEOID": "0500000US01085",
      "GEOID": "01085",
      "NAME": "Lowndes",
      "LSAD": "06",
      "ALAND": 1854201380,
      "AWATER": 23721835,
      "County_state": "Lowndes,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.908302,
              32.225028
            ],
            [
              -86.906956,
              32.04797
            ],
            [
              -86.856576,
              32.048352
            ],
            [
              -86.857583,
              31.962167
            ],
            [
              -86.448198,
              31.964629
            ],
            [
              -86.405005,
              31.963775
            ],
            [
              -86.406276,
              32.050731
            ],
            [
              -86.408272,
              32.208976
            ],
            [
              -86.408771,
              32.244309
            ],
            [
              -86.484678,
              32.284473
            ],
            [
              -86.491055,
              32.301622
            ],
            [
              -86.474479,
              32.331547
            ],
            [
              -86.496774,
              32.344437
            ],
            [
              -86.532531,
              32.338775
            ],
            [
              -86.542537,
              32.363517
            ],
            [
              -86.570551,
              32.375006
            ],
            [
              -86.581873,
              32.375019
            ],
            [
              -86.595335,
              32.361345
            ],
            [
              -86.614841,
              32.374266
            ],
            [
              -86.613453,
              32.398584
            ],
            [
              -86.618,
              32.405717
            ],
            [
              -86.653419,
              32.397247
            ],
            [
              -86.655597,
              32.376147
            ],
            [
              -86.683537,
              32.353395
            ],
            [
              -86.711337,
              32.360767
            ],
            [
              -86.719028,
              32.372059
            ],
            [
              -86.717897,
              32.402814
            ],
            [
              -86.727181,
              32.404497
            ],
            [
              -86.749981,
              32.389105
            ],
            [
              -86.778365,
              32.394601
            ],
            [
              -86.780447,
              32.3686
            ],
            [
              -86.773163,
              32.340728
            ],
            [
              -86.798268,
              32.308632
            ],
            [
              -86.816107,
              32.30997
            ],
            [
              -86.820921,
              32.33324
            ],
            [
              -86.814912,
              32.340803
            ],
            [
              -86.850412,
              32.328947
            ],
            [
              -86.850507,
              32.309857
            ],
            [
              -86.82606,
              32.305957
            ],
            [
              -86.847327,
              32.287906
            ],
            [
              -86.858396,
              32.284112
            ],
            [
              -86.864432,
              32.271777
            ],
            [
              -86.854983,
              32.256701
            ],
            [
              -86.834478,
              32.236775
            ],
            [
              -86.810448,
              32.237589
            ],
            [
              -86.810313,
              32.224747
            ],
            [
              -86.908302,
              32.225028
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "057",
      "COUNTYNS": "00161554",
      "AFFGEOID": "0500000US01057",
      "GEOID": "01057",
      "NAME": "Fayette",
      "LSAD": "06",
      "ALAND": 1625693280,
      "AWATER": 4330881,
      "County_state": "Fayette,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.951785,
              33.91993
            ],
            [
              -87.946658,
              33.6413
            ],
            [
              -87.946519,
              33.524065
            ],
            [
              -87.840683,
              33.524839
            ],
            [
              -87.736652,
              33.519568
            ],
            [
              -87.687565,
              33.521087
            ],
            [
              -87.666661,
              33.521667
            ],
            [
              -87.666633,
              33.579807
            ],
            [
              -87.63161,
              33.580669
            ],
            [
              -87.631718,
              33.609833
            ],
            [
              -87.423701,
              33.602096
            ],
            [
              -87.423843,
              33.689112
            ],
            [
              -87.528338,
              33.692049
            ],
            [
              -87.531602,
              33.867618
            ],
            [
              -87.63604,
              33.871999
            ],
            [
              -87.635932,
              33.915251
            ],
            [
              -87.722438,
              33.914797
            ],
            [
              -87.863239,
              33.919511
            ],
            [
              -87.951785,
              33.91993
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "133",
      "COUNTYNS": "00161592",
      "AFFGEOID": "0500000US01133",
      "GEOID": "01133",
      "NAME": "Winston",
      "LSAD": "06",
      "ALAND": 1587608917,
      "AWATER": 48922729,
      "County_state": "Winston,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.636241,
              34.070144
            ],
            [
              -87.636118,
              34.002203
            ],
            [
              -87.500021,
              33.996627
            ],
            [
              -87.266302,
              33.994559
            ],
            [
              -87.216735,
              33.993907
            ],
            [
              -87.200031,
              33.984882
            ],
            [
              -87.189908,
              34.002804
            ],
            [
              -87.168907,
              34.005104
            ],
            [
              -87.151036,
              33.993225
            ],
            [
              -87.111992,
              33.992385
            ],
            [
              -87.110941,
              34.172554
            ],
            [
              -87.110199,
              34.228364
            ],
            [
              -87.109911,
              34.299299
            ],
            [
              -87.498155,
              34.304443
            ],
            [
              -87.529722,
              34.304598
            ],
            [
              -87.634725,
              34.306997
            ],
            [
              -87.634368,
              34.220165
            ],
            [
              -87.635285,
              34.170897
            ],
            [
              -87.636241,
              34.070144
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "021",
      "COUNTYNS": "00161536",
      "AFFGEOID": "0500000US01021",
      "GEOID": "01021",
      "NAME": "Chilton",
      "LSAD": "06",
      "ALAND": 1794478905,
      "AWATER": 20590301,
      "County_state": "Chilton,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.019157,
              32.837034
            ],
            [
              -87.018251,
              32.78803
            ],
            [
              -87.017762,
              32.729532
            ],
            [
              -87.01766,
              32.663269
            ],
            [
              -86.917595,
              32.664169
            ],
            [
              -86.816574,
              32.660117
            ],
            [
              -86.771532,
              32.660717
            ],
            [
              -86.71339,
              32.661732
            ],
            [
              -86.714219,
              32.705694
            ],
            [
              -86.524434,
              32.707058
            ],
            [
              -86.476875,
              32.707687
            ],
            [
              -86.413116,
              32.707386
            ],
            [
              -86.413335,
              32.750591
            ],
            [
              -86.374974,
              32.75358
            ],
            [
              -86.396737,
              32.762946
            ],
            [
              -86.413402,
              32.788182
            ],
            [
              -86.427238,
              32.791707
            ],
            [
              -86.457015,
              32.813899
            ],
            [
              -86.460691,
              32.833464
            ],
            [
              -86.447837,
              32.850976
            ],
            [
              -86.451524,
              32.863692
            ],
            [
              -86.465672,
              32.869141
            ],
            [
              -86.480222,
              32.87435
            ],
            [
              -86.490511,
              32.905619
            ],
            [
              -86.515959,
              32.929361
            ],
            [
              -86.518117,
              32.971181
            ],
            [
              -86.510622,
              32.98538
            ],
            [
              -86.522283,
              33.004395
            ],
            [
              -86.517344,
              33.020566
            ],
            [
              -86.536859,
              33.024433
            ],
            [
              -86.553845,
              33.018931
            ],
            [
              -86.604032,
              33.055086
            ],
            [
              -86.610003,
              33.070003
            ],
            [
              -86.730002,
              33.069274
            ],
            [
              -86.730252,
              33.069274
            ],
            [
              -86.802305,
              33.068844
            ],
            [
              -86.824835,
              33.06853
            ],
            [
              -86.842723,
              33.07094
            ],
            [
              -86.881638,
              33.071861
            ],
            [
              -86.881182,
              33.049901
            ],
            [
              -86.876866,
              33.049925
            ],
            [
              -86.876222,
              32.901927
            ],
            [
              -86.87622,
              32.847838
            ],
            [
              -86.876118,
              32.836264
            ],
            [
              -87.019157,
              32.837034
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "073",
      "COUNTYNS": "00161562",
      "AFFGEOID": "0500000US01073",
      "GEOID": "01073",
      "NAME": "Jefferson",
      "LSAD": "06",
      "ALAND": 2877921069,
      "AWATER": 32756125,
      "County_state": "Jefferson,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.341698,
              33.470501
            ],
            [
              -87.335577,
              33.456251
            ],
            [
              -87.314114,
              33.45573
            ],
            [
              -87.314395,
              33.441719
            ],
            [
              -87.284363,
              33.440578
            ],
            [
              -87.284275,
              33.425708
            ],
            [
              -87.253788,
              33.417813
            ],
            [
              -87.249311,
              33.395583
            ],
            [
              -87.232045,
              33.395137
            ],
            [
              -87.231999,
              33.365851
            ],
            [
              -87.197584,
              33.358134
            ],
            [
              -87.194841,
              33.343069
            ],
            [
              -87.180006,
              33.342979
            ],
            [
              -87.179754,
              33.32801
            ],
            [
              -87.162175,
              33.321012
            ],
            [
              -87.162007,
              33.306587
            ],
            [
              -87.127142,
              33.306013
            ],
            [
              -87.126756,
              33.290844
            ],
            [
              -87.087497,
              33.290675
            ],
            [
              -87.083932,
              33.279634
            ],
            [
              -87.065754,
              33.275937
            ],
            [
              -87.065752,
              33.26763
            ],
            [
              -87.065738,
              33.246907
            ],
            [
              -87.026846,
              33.246459
            ],
            [
              -86.982887,
              33.246007
            ],
            [
              -86.982744,
              33.252994
            ],
            [
              -86.978363,
              33.255144
            ],
            [
              -86.97836,
              33.256222
            ],
            [
              -86.969575,
              33.267474
            ],
            [
              -86.926167,
              33.27816
            ],
            [
              -86.926135,
              33.296529
            ],
            [
              -86.895556,
              33.316549
            ],
            [
              -86.882518,
              33.332687
            ],
            [
              -86.85539,
              33.332323
            ],
            [
              -86.839428,
              33.332031
            ],
            [
              -86.830688,
              33.332015
            ],
            [
              -86.830229,
              33.360766
            ],
            [
              -86.778644,
              33.366878
            ],
            [
              -86.778356,
              33.388941
            ],
            [
              -86.76114,
              33.388365
            ],
            [
              -86.752485,
              33.415855
            ],
            [
              -86.748807,
              33.417274
            ],
            [
              -86.72774,
              33.416533
            ],
            [
              -86.718131,
              33.416316
            ],
            [
              -86.717842,
              33.425187
            ],
            [
              -86.710613,
              33.430571
            ],
            [
              -86.700354,
              33.430346
            ],
            [
              -86.691938,
              33.451498
            ],
            [
              -86.67445,
              33.451738
            ],
            [
              -86.667634,
              33.466602
            ],
            [
              -86.638953,
              33.46689
            ],
            [
              -86.62669,
              33.466768
            ],
            [
              -86.60451,
              33.467053
            ],
            [
              -86.560624,
              33.517512
            ],
            [
              -86.517199,
              33.524136
            ],
            [
              -86.516783,
              33.545896
            ],
            [
              -86.516743,
              33.553379
            ],
            [
              -86.551992,
              33.561023
            ],
            [
              -86.551345,
              33.61997
            ],
            [
              -86.525424,
              33.656053
            ],
            [
              -86.52536,
              33.692748
            ],
            [
              -86.525073,
              33.721236
            ],
            [
              -86.542642,
              33.735636
            ],
            [
              -86.542758,
              33.765173
            ],
            [
              -86.577799,
              33.765316
            ],
            [
              -86.583082,
              33.765416
            ],
            [
              -86.605881,
              33.7652
            ],
            [
              -86.647765,
              33.765896
            ],
            [
              -86.759144,
              33.840617
            ],
            [
              -86.788372,
              33.841035
            ],
            [
              -86.883947,
              33.843237
            ],
            [
              -86.936537,
              33.81531
            ],
            [
              -86.953664,
              33.815297
            ],
            [
              -86.95577,
              33.800828
            ],
            [
              -86.970928,
              33.786462
            ],
            [
              -87.005965,
              33.787237
            ],
            [
              -87.005889,
              33.772572
            ],
            [
              -87.023286,
              33.772727
            ],
            [
              -87.031661,
              33.73985
            ],
            [
              -87.031582,
              33.728979
            ],
            [
              -87.040638,
              33.728969
            ],
            [
              -87.039818,
              33.687461
            ],
            [
              -87.057252,
              33.685473
            ],
            [
              -87.057757,
              33.656363
            ],
            [
              -87.0927,
              33.656873
            ],
            [
              -87.092733,
              33.62785
            ],
            [
              -87.144473,
              33.628198
            ],
            [
              -87.144413,
              33.613406
            ],
            [
              -87.17943,
              33.613794
            ],
            [
              -87.179555,
              33.599275
            ],
            [
              -87.196924,
              33.599339
            ],
            [
              -87.204577,
              33.578835
            ],
            [
              -87.196914,
              33.5624
            ],
            [
              -87.185794,
              33.555882
            ],
            [
              -87.210029,
              33.538732
            ],
            [
              -87.232599,
              33.539383
            ],
            [
              -87.250469,
              33.514198
            ],
            [
              -87.2687,
              33.530583
            ],
            [
              -87.275532,
              33.522056
            ],
            [
              -87.266923,
              33.512929
            ],
            [
              -87.261361,
              33.500683
            ],
            [
              -87.28544,
              33.50228
            ],
            [
              -87.285024,
              33.485785
            ],
            [
              -87.330278,
              33.491961
            ],
            [
              -87.341698,
              33.470501
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "027",
      "COUNTYNS": "00161539",
      "AFFGEOID": "0500000US01027",
      "GEOID": "01027",
      "NAME": "Clay",
      "LSAD": "06",
      "ALAND": 1564253513,
      "AWATER": 5283523,
      "County_state": "Clay,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.17283,
              33.195681
            ],
            [
              -86.17437,
              33.104394
            ],
            [
              -86.086238,
              33.104702
            ],
            [
              -86.079858,
              33.104695
            ],
            [
              -86.009313,
              33.104642
            ],
            [
              -86.00917,
              33.09026
            ],
            [
              -85.97456,
              33.090507
            ],
            [
              -85.974715,
              33.105286
            ],
            [
              -85.801694,
              33.104298
            ],
            [
              -85.753628,
              33.10528
            ],
            [
              -85.653654,
              33.106634
            ],
            [
              -85.650466,
              33.306154
            ],
            [
              -85.641435,
              33.305959
            ],
            [
              -85.641132,
              33.323932
            ],
            [
              -85.649915,
              33.324211
            ],
            [
              -85.643482,
              33.495885
            ],
            [
              -85.765427,
              33.498593
            ],
            [
              -85.765631,
              33.483477
            ],
            [
              -85.782689,
              33.483638
            ],
            [
              -85.782735,
              33.469349
            ],
            [
              -85.887675,
              33.476768
            ],
            [
              -85.870053,
              33.476757
            ],
            [
              -85.869308,
              33.491574
            ],
            [
              -85.852421,
              33.491644
            ],
            [
              -85.85189,
              33.498742
            ],
            [
              -85.904909,
              33.498655
            ],
            [
              -85.905338,
              33.45471
            ],
            [
              -85.922817,
              33.454576
            ],
            [
              -85.923762,
              33.396206
            ],
            [
              -85.940875,
              33.396054
            ],
            [
              -85.952741,
              33.381572
            ],
            [
              -85.976525,
              33.38187
            ],
            [
              -85.979089,
              33.32005
            ],
            [
              -85.980293,
              33.29419
            ],
            [
              -86.011555,
              33.294316
            ],
            [
              -86.100837,
              33.296078
            ],
            [
              -86.118198,
              33.29632
            ],
            [
              -86.120567,
              33.194511
            ],
            [
              -86.17283,
              33.195681
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "017",
      "COUNTYNS": "00161534",
      "AFFGEOID": "0500000US01017",
      "GEOID": "01017",
      "NAME": "Chambers",
      "LSAD": "06",
      "ALAND": 1545009122,
      "AWATER": 17048126,
      "County_state": "Chambers,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.593177,
              33.10734
            ],
            [
              -85.593435,
              32.897267
            ],
            [
              -85.593066,
              32.7453
            ],
            [
              -85.593151,
              32.72853
            ],
            [
              -85.430549,
              32.73033
            ],
            [
              -85.430548,
              32.74503
            ],
            [
              -85.413447,
              32.744729
            ],
            [
              -85.396688,
              32.730483
            ],
            [
              -85.389074,
              32.730435
            ],
            [
              -85.356413,
              32.730497
            ],
            [
              -85.293221,
              32.730731
            ],
            [
              -85.284843,
              32.745131
            ],
            [
              -85.135038,
              32.74653
            ],
            [
              -85.1300975128125,
              32.7517487666425
            ],
            [
              -85.138879,
              32.760062
            ],
            [
              -85.122189,
              32.773353
            ],
            [
              -85.133322,
              32.782078
            ],
            [
              -85.167939,
              32.811612
            ],
            [
              -85.153497,
              32.842781
            ],
            [
              -85.1844,
              32.861317
            ],
            [
              -85.1844151797869,
              32.861414761819795
            ],
            [
              -85.1857897629128,
              32.870267438636695
            ],
            [
              -85.188811,
              32.889725
            ],
            [
              -85.2026164555261,
              32.9588381423037
            ],
            [
              -85.20735641663379,
              32.98256742859439
            ],
            [
              -85.221932,
              33.055536
            ],
            [
              -85.223325,
              33.062578
            ],
            [
              -85.232441,
              33.108075
            ],
            [
              -85.23244140250969,
              33.1080770939296
            ],
            [
              -85.593177,
              33.10734
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "115",
      "COUNTYNS": "00164997",
      "AFFGEOID": "0500000US01115",
      "GEOID": "01115",
      "NAME": "St. Clair",
      "LSAD": "06",
      "ALAND": 1636634176,
      "AWATER": 56284614,
      "County_state": "St. Clair,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.577528,
              33.801977
            ],
            [
              -86.577799,
              33.765316
            ],
            [
              -86.542758,
              33.765173
            ],
            [
              -86.542642,
              33.735636
            ],
            [
              -86.525073,
              33.721236
            ],
            [
              -86.52536,
              33.692748
            ],
            [
              -86.525424,
              33.656053
            ],
            [
              -86.551345,
              33.61997
            ],
            [
              -86.551992,
              33.561023
            ],
            [
              -86.516743,
              33.553379
            ],
            [
              -86.516783,
              33.545896
            ],
            [
              -86.481549,
              33.546073
            ],
            [
              -86.481939,
              33.502544
            ],
            [
              -86.378222,
              33.502411
            ],
            [
              -86.378665,
              33.390983
            ],
            [
              -86.346277,
              33.410724
            ],
            [
              -86.336111,
              33.425088
            ],
            [
              -86.323096,
              33.444499
            ],
            [
              -86.310629,
              33.452551
            ],
            [
              -86.280611,
              33.511032
            ],
            [
              -86.263094,
              33.512118
            ],
            [
              -86.265604,
              33.496352
            ],
            [
              -86.253259,
              33.488844
            ],
            [
              -86.235328,
              33.494532
            ],
            [
              -86.226579,
              33.52055
            ],
            [
              -86.190495,
              33.543729
            ],
            [
              -86.192673,
              33.55314
            ],
            [
              -86.208596,
              33.55986
            ],
            [
              -86.221017,
              33.587919
            ],
            [
              -86.213229,
              33.594598
            ],
            [
              -86.188878,
              33.591145
            ],
            [
              -86.184385,
              33.593758
            ],
            [
              -86.200763,
              33.608721
            ],
            [
              -86.16948,
              33.619236
            ],
            [
              -86.164884,
              33.636629
            ],
            [
              -86.172437,
              33.659831
            ],
            [
              -86.200788,
              33.67682
            ],
            [
              -86.204984,
              33.689521
            ],
            [
              -86.186619,
              33.699461
            ],
            [
              -86.176619,
              33.696643
            ],
            [
              -86.170871,
              33.679332
            ],
            [
              -86.155117,
              33.67163
            ],
            [
              -86.145562,
              33.679098
            ],
            [
              -86.143711,
              33.709135
            ],
            [
              -86.123882,
              33.710789
            ],
            [
              -86.098664,
              33.736616
            ],
            [
              -86.08556,
              33.740659
            ],
            [
              -86.060121,
              33.761116
            ],
            [
              -86.044363,
              33.763058
            ],
            [
              -86.064364,
              33.797775
            ],
            [
              -86.051439,
              33.824134
            ],
            [
              -86.065272,
              33.842198
            ],
            [
              -86.054537,
              33.846397
            ],
            [
              -86.073123,
              33.865783
            ],
            [
              -86.096668,
              33.861365
            ],
            [
              -86.112474,
              33.903998
            ],
            [
              -86.142717,
              33.899391
            ],
            [
              -86.149202,
              33.920565
            ],
            [
              -86.16537,
              33.926578
            ],
            [
              -86.166162,
              33.944697
            ],
            [
              -86.168141,
              33.965181
            ],
            [
              -86.183634,
              33.972202
            ],
            [
              -86.199164,
              33.988918
            ],
            [
              -86.230207,
              33.982787
            ],
            [
              -86.251585,
              33.965604
            ],
            [
              -86.277828,
              33.989302
            ],
            [
              -86.290127,
              33.983719
            ],
            [
              -86.321312,
              33.950002
            ],
            [
              -86.325622,
              33.940147
            ],
            [
              -86.32215,
              33.932427
            ],
            [
              -86.349501,
              33.906982
            ],
            [
              -86.342694,
              33.88647
            ],
            [
              -86.377532,
              33.861706
            ],
            [
              -86.405981,
              33.835896
            ],
            [
              -86.437717,
              33.819981
            ],
            [
              -86.44528,
              33.825977
            ],
            [
              -86.480295,
              33.815151
            ],
            [
              -86.512492,
              33.810794
            ],
            [
              -86.521771,
              33.801901
            ],
            [
              -86.577528,
              33.801977
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "077",
      "COUNTYNS": "00161564",
      "AFFGEOID": "0500000US01077",
      "GEOID": "01077",
      "NAME": "Lauderdale",
      "LSAD": "06",
      "ALAND": 1730742958,
      "AWATER": 133012067,
      "County_state": "Lauderdale,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.202959,
              35.008028
            ],
            [
              -88.201987,
              35.005421
            ],
            [
              -88.20082,
              34.997774
            ],
            [
              -88.200064,
              34.995634
            ],
            [
              -88.198811,
              34.991192
            ],
            [
              -88.187429,
              34.974909
            ],
            [
              -88.179973,
              34.967466
            ],
            [
              -88.176106,
              34.962519
            ],
            [
              -88.172102,
              34.955213
            ],
            [
              -88.161594,
              34.933456
            ],
            [
              -88.154617,
              34.922392
            ],
            [
              -88.146335,
              34.914374
            ],
            [
              -88.139721,
              34.909631
            ],
            [
              -88.136692,
              34.907592
            ],
            [
              -88.125038,
              34.902227
            ],
            [
              -88.099999,
              34.894095
            ],
            [
              -88.097888,
              34.892202
            ],
            [
              -88.040871,
              34.906613
            ],
            [
              -87.986612,
              34.886745
            ],
            [
              -87.952269,
              34.867663
            ],
            [
              -87.933456,
              34.838902
            ],
            [
              -87.92029,
              34.804468
            ],
            [
              -87.861315,
              34.762983
            ],
            [
              -87.835833,
              34.741239
            ],
            [
              -87.806963,
              34.732061
            ],
            [
              -87.767331,
              34.734589
            ],
            [
              -87.732665,
              34.745687
            ],
            [
              -87.707572,
              34.772919
            ],
            [
              -87.694193,
              34.776628
            ],
            [
              -87.675387,
              34.782145
            ],
            [
              -87.671051,
              34.782542
            ],
            [
              -87.643396,
              34.792846
            ],
            [
              -87.613694,
              34.79903
            ],
            [
              -87.589485,
              34.81074
            ],
            [
              -87.584746,
              34.819188
            ],
            [
              -87.54629,
              34.830431
            ],
            [
              -87.524363,
              34.832384
            ],
            [
              -87.479966,
              34.812626
            ],
            [
              -87.44998,
              34.80828
            ],
            [
              -87.42651,
              34.800022
            ],
            [
              -87.370249,
              34.804992
            ],
            [
              -87.344224,
              34.796493
            ],
            [
              -87.297905,
              34.750812
            ],
            [
              -87.260676,
              34.758626
            ],
            [
              -87.278302,
              34.773563
            ],
            [
              -87.271079,
              34.783829
            ],
            [
              -87.255566,
              34.788354
            ],
            [
              -87.214914,
              34.816011
            ],
            [
              -87.210759,
              34.999024
            ],
            [
              -87.216683,
              34.999148
            ],
            [
              -87.22405322029701,
              34.999326659116896
            ],
            [
              -87.230544,
              34.999484
            ],
            [
              -87.270014,
              35.00039
            ],
            [
              -87.299185,
              35.000915
            ],
            [
              -87.349251,
              35.001662
            ],
            [
              -87.359281,
              35.001823
            ],
            [
              -87.381071,
              35.002118
            ],
            [
              -87.391314,
              35.002374
            ],
            [
              -87.4174,
              35.002669
            ],
            [
              -87.421543,
              35.002679
            ],
            [
              -87.428613,
              35.002795
            ],
            [
              -87.4370737168059,
              35.0028353625626
            ],
            [
              -87.60617837916699,
              35.0036420906069
            ],
            [
              -87.625025,
              35.003732
            ],
            [
              -87.664123,
              35.003523
            ],
            [
              -87.671405,
              35.003537
            ],
            [
              -87.696834,
              35.003852
            ],
            [
              -87.700543,
              35.003988
            ],
            [
              -87.702321,
              35.003945
            ],
            [
              -87.709491,
              35.004089
            ],
            [
              -87.75889,
              35.004711
            ],
            [
              -87.767602,
              35.004783
            ],
            [
              -87.773586,
              35.004946
            ],
            [
              -87.851886,
              35.005656
            ],
            [
              -87.853411,
              35.005576
            ],
            [
              -87.853528,
              35.005541
            ],
            [
              -87.872626,
              35.005571
            ],
            [
              -87.877742,
              35.005512
            ],
            [
              -87.877969,
              35.005468
            ],
            [
              -87.9269766619543,
              35.005657104059196
            ],
            [
              -87.9734958113087,
              35.0058366057866
            ],
            [
              -87.9849162019114,
              35.005880673227
            ],
            [
              -88.000032,
              35.005939
            ],
            [
              -88.202959,
              35.008028
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "049",
      "COUNTYNS": "00161550",
      "AFFGEOID": "0500000US01049",
      "GEOID": "01049",
      "NAME": "DeKalb",
      "LSAD": "06",
      "ALAND": 2012676476,
      "AWATER": 4121538,
      "County_state": "DeKalb,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.110362,
              34.427797
            ],
            [
              -86.118894,
              34.403845
            ],
            [
              -86.110025,
              34.392945
            ],
            [
              -86.110073,
              34.374802
            ],
            [
              -86.108916,
              34.333764
            ],
            [
              -86.107907,
              34.282721
            ],
            [
              -86.107244,
              34.251145
            ],
            [
              -86.107433,
              34.249798
            ],
            [
              -86.106086,
              34.200756
            ],
            [
              -85.843617,
              34.200006
            ],
            [
              -85.843836,
              34.24345
            ],
            [
              -85.843801,
              34.244595
            ],
            [
              -85.808417,
              34.239377
            ],
            [
              -85.809045,
              34.258921
            ],
            [
              -85.774165,
              34.259094
            ],
            [
              -85.675283,
              34.343396
            ],
            [
              -85.672166,
              34.351838
            ],
            [
              -85.664154,
              34.361937
            ],
            [
              -85.636645,
              34.366622
            ],
            [
              -85.620337,
              34.386978
            ],
            [
              -85.627512,
              34.39592
            ],
            [
              -85.616667,
              34.393415
            ],
            [
              -85.600683,
              34.420926
            ],
            [
              -85.579656,
              34.439709
            ],
            [
              -85.583917,
              34.457961
            ],
            [
              -85.580325,
              34.470306
            ],
            [
              -85.570342,
              34.467764
            ],
            [
              -85.576981,
              34.483543
            ],
            [
              -85.560386,
              34.489909
            ],
            [
              -85.559365,
              34.501247
            ],
            [
              -85.5444,
              34.500085
            ],
            [
              -85.533434,
              34.514083
            ],
            [
              -85.5137295115038,
              34.524190957986
            ],
            [
              -85.513945,
              34.525192
            ],
            [
              -85.517089,
              34.542598
            ],
            [
              -85.5272733946093,
              34.5886802607335
            ],
            [
              -85.527274,
              34.588683
            ],
            [
              -85.53410097210269,
              34.62385567623
            ],
            [
              -85.534339,
              34.625082
            ],
            [
              -85.541275,
              34.656701
            ],
            [
              -85.541278,
              34.656783
            ],
            [
              -85.552463,
              34.708138
            ],
            [
              -85.552491,
              34.708321
            ],
            [
              -85.561424,
              34.750079
            ],
            [
              -85.583148376479,
              34.8603678345509
            ],
            [
              -85.627068,
              34.831879
            ],
            [
              -85.634268,
              34.798978
            ],
            [
              -85.660198,
              34.754963
            ],
            [
              -85.673379,
              34.744915
            ],
            [
              -85.693037,
              34.74425
            ],
            [
              -85.785471,
              34.624584
            ],
            [
              -85.881114,
              34.562315
            ],
            [
              -85.938544,
              34.525354
            ],
            [
              -85.956935,
              34.507125
            ],
            [
              -85.95698,
              34.506142
            ],
            [
              -85.959608,
              34.503485
            ],
            [
              -85.960106,
              34.502179
            ],
            [
              -85.961177,
              34.500587
            ],
            [
              -85.962343,
              34.499755
            ],
            [
              -85.964114,
              34.499642
            ],
            [
              -85.966156,
              34.498606
            ],
            [
              -86.00384,
              34.480031
            ],
            [
              -86.015594,
              34.481639
            ],
            [
              -86.034848,
              34.473423
            ],
            [
              -86.057712,
              34.475994
            ],
            [
              -86.110362,
              34.427797
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "063",
      "COUNTYNS": "00161557",
      "AFFGEOID": "0500000US01063",
      "GEOID": "01063",
      "NAME": "Greene",
      "LSAD": "06",
      "ALAND": 1675782063,
      "AWATER": 33416143,
      "County_state": "Greene,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.196302,
              32.935651
            ],
            [
              -88.207316,
              32.924782
            ],
            [
              -88.189754,
              32.910731
            ],
            [
              -88.179966,
              32.871675
            ],
            [
              -88.154973,
              32.852874
            ],
            [
              -88.178343,
              32.828535
            ],
            [
              -88.147278,
              32.824866
            ],
            [
              -88.132685,
              32.832853
            ],
            [
              -88.105407,
              32.798412
            ],
            [
              -88.098691,
              32.795884
            ],
            [
              -88.07324,
              32.804563
            ],
            [
              -88.064004,
              32.790674
            ],
            [
              -88.075882,
              32.77451
            ],
            [
              -88.10298,
              32.779426
            ],
            [
              -88.109633,
              32.770989
            ],
            [
              -88.101427,
              32.739261
            ],
            [
              -88.081872,
              32.719428
            ],
            [
              -88.08538,
              32.709054
            ],
            [
              -88.115864,
              32.701039
            ],
            [
              -88.110089,
              32.690762
            ],
            [
              -88.07823,
              32.684002
            ],
            [
              -88.054828,
              32.696697
            ],
            [
              -88.040708,
              32.689584
            ],
            [
              -88.057987,
              32.674384
            ],
            [
              -88.057037,
              32.643129
            ],
            [
              -88.080283,
              32.633148
            ],
            [
              -88.079159,
              32.619684
            ],
            [
              -88.053729,
              32.593052
            ],
            [
              -88.009887,
              32.600721
            ],
            [
              -87.977005,
              32.610781
            ],
            [
              -87.947297,
              32.626827
            ],
            [
              -87.928689,
              32.632284
            ],
            [
              -87.911821,
              32.624764
            ],
            [
              -87.904841,
              32.614576
            ],
            [
              -87.877238,
              32.622084
            ],
            [
              -87.85812,
              32.620793
            ],
            [
              -87.840889,
              32.605763
            ],
            [
              -87.860194,
              32.590888
            ],
            [
              -87.862095,
              32.606484
            ],
            [
              -87.882628,
              32.609723
            ],
            [
              -87.898355,
              32.592321
            ],
            [
              -87.875014,
              32.571824
            ],
            [
              -87.874434,
              32.549926
            ],
            [
              -87.86242,
              32.532739
            ],
            [
              -87.85342,
              32.532086
            ],
            [
              -87.843838,
              32.547205
            ],
            [
              -87.822317,
              32.543748
            ],
            [
              -87.823383,
              32.520443
            ],
            [
              -87.812559,
              32.52456
            ],
            [
              -87.797775,
              32.543709
            ],
            [
              -87.800959,
              32.558891
            ],
            [
              -87.788904,
              32.560342
            ],
            [
              -87.787701,
              32.582289
            ],
            [
              -87.769961,
              32.577798
            ],
            [
              -87.769206,
              32.568181
            ],
            [
              -87.756981,
              32.580509
            ],
            [
              -87.73692,
              32.588738
            ],
            [
              -87.748723,
              32.612816
            ],
            [
              -87.783271,
              32.605231
            ],
            [
              -87.798436,
              32.610447
            ],
            [
              -87.813401,
              32.633875
            ],
            [
              -87.773803,
              32.641705
            ],
            [
              -87.753061,
              32.633583
            ],
            [
              -87.743158,
              32.651743
            ],
            [
              -87.752319,
              32.659396
            ],
            [
              -87.769664,
              32.649768
            ],
            [
              -87.787661,
              32.654162
            ],
            [
              -87.793923,
              32.666128
            ],
            [
              -87.786571,
              32.682481
            ],
            [
              -87.796532,
              32.698444
            ],
            [
              -87.796043,
              32.715632
            ],
            [
              -87.814668,
              32.706629
            ],
            [
              -87.83077,
              32.712856
            ],
            [
              -87.820842,
              32.733545
            ],
            [
              -87.807411,
              32.733812
            ],
            [
              -87.808284,
              32.752372
            ],
            [
              -87.828097,
              32.768721
            ],
            [
              -87.836018,
              32.759821
            ],
            [
              -87.860296,
              32.756722
            ],
            [
              -87.870464,
              32.762442
            ],
            [
              -87.864892,
              32.773696
            ],
            [
              -87.853921,
              32.772187
            ],
            [
              -87.821633,
              32.794455
            ],
            [
              -87.813178,
              32.810176
            ],
            [
              -87.816325,
              32.819437
            ],
            [
              -87.819865,
              32.836393
            ],
            [
              -87.831749,
              32.840505
            ],
            [
              -87.832742,
              32.863729
            ],
            [
              -87.820144,
              32.864938
            ],
            [
              -87.822125,
              32.880522
            ],
            [
              -87.812682,
              32.877606
            ],
            [
              -87.804218,
              32.897523
            ],
            [
              -87.782929,
              32.895375
            ],
            [
              -87.759961,
              32.907099
            ],
            [
              -87.775016,
              32.920015
            ],
            [
              -87.774735,
              32.927424
            ],
            [
              -87.740116,
              32.936847
            ],
            [
              -87.729941,
              32.949258
            ],
            [
              -87.747716,
              32.949049
            ],
            [
              -87.738707,
              32.958903
            ],
            [
              -87.750357,
              32.978068
            ],
            [
              -87.735327,
              32.974573
            ],
            [
              -87.727863,
              32.985813
            ],
            [
              -87.712491,
              32.986009
            ],
            [
              -87.707381,
              32.99756
            ],
            [
              -87.715709,
              33.006824
            ],
            [
              -87.710995,
              33.017721
            ],
            [
              -87.752641,
              33.017259
            ],
            [
              -87.832233,
              33.017258
            ],
            [
              -87.831459,
              33.093857
            ],
            [
              -87.838047,
              33.136864
            ],
            [
              -87.837521,
              33.153637
            ],
            [
              -87.876792,
              33.13919
            ],
            [
              -87.93368,
              33.1132
            ],
            [
              -87.938309,
              33.114666
            ],
            [
              -87.968838,
              33.077641
            ],
            [
              -87.981493,
              33.080615
            ],
            [
              -88.026971,
              33.063602
            ],
            [
              -88.043891,
              33.032719
            ],
            [
              -88.064997,
              33.051344
            ],
            [
              -88.086075,
              33.036247
            ],
            [
              -88.115209,
              33.041804
            ],
            [
              -88.135072,
              33.03649
            ],
            [
              -88.147654,
              33.03842
            ],
            [
              -88.142928,
              33.02536
            ],
            [
              -88.166101,
              33.013615
            ],
            [
              -88.171935,
              33.003422
            ],
            [
              -88.171852,
              32.99586
            ],
            [
              -88.171466,
              32.991937
            ],
            [
              -88.17242,
              32.987628
            ],
            [
              -88.17221,
              32.98657
            ],
            [
              -88.168649,
              32.981084
            ],
            [
              -88.16844,
              32.98006
            ],
            [
              -88.168737,
              32.973287
            ],
            [
              -88.167338,
              32.970551
            ],
            [
              -88.157349,
              32.964582
            ],
            [
              -88.165276,
              32.954321
            ],
            [
              -88.184064,
              32.962452
            ],
            [
              -88.182362,
              32.945689
            ],
            [
              -88.172299,
              32.942892
            ],
            [
              -88.175329,
              32.932093
            ],
            [
              -88.196302,
              32.935651
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "019",
      "COUNTYNS": "00161535",
      "AFFGEOID": "0500000US01019",
      "GEOID": "01019",
      "NAME": "Cherokee",
      "LSAD": "06",
      "ALAND": 1434228753,
      "AWATER": 119702920,
      "County_state": "Cherokee,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.843801,
              34.244595
            ],
            [
              -85.843836,
              34.24345
            ],
            [
              -85.843617,
              34.200006
            ],
            [
              -85.841365,
              34.110695
            ],
            [
              -85.833205,
              34.109944
            ],
            [
              -85.811967,
              34.125096
            ],
            [
              -85.791007,
              34.125096
            ],
            [
              -85.797071,
              34.100179
            ],
            [
              -85.737137,
              33.989203
            ],
            [
              -85.738975,
              33.96846
            ],
            [
              -85.712088,
              33.966906
            ],
            [
              -85.59115,
              33.959641
            ],
            [
              -85.529432,
              33.95598
            ],
            [
              -85.530094,
              33.941423
            ],
            [
              -85.49582,
              33.942394
            ],
            [
              -85.495289,
              33.95691
            ],
            [
              -85.425627,
              33.957069
            ],
            [
              -85.3988712532846,
              33.9641292868502
            ],
            [
              -85.405951,
              34.000099
            ],
            [
              -85.406781,
              34.002313
            ],
            [
              -85.420263,
              34.072277
            ],
            [
              -85.4218824893099,
              34.0808162176071
            ],
            [
              -85.428251,
              34.114396
            ],
            [
              -85.429499,
              34.125095
            ],
            [
              -85.4447454521673,
              34.199943441036595
            ],
            [
              -85.455082,
              34.250688
            ],
            [
              -85.455396,
              34.252853
            ],
            [
              -85.458095,
              34.265735
            ],
            [
              -85.458717,
              34.269436
            ],
            [
              -85.46210536142519,
              34.28638558983619
            ],
            [
              -85.470472,
              34.328238
            ],
            [
              -85.474036379434,
              34.3445395457524
            ],
            [
              -85.48460743979379,
              34.3928858644596
            ],
            [
              -85.502333,
              34.473953
            ],
            [
              -85.502471,
              34.474526
            ],
            [
              -85.5084,
              34.501212
            ],
            [
              -85.512124,
              34.518252
            ],
            [
              -85.513725,
              34.52417
            ],
            [
              -85.5137295115038,
              34.524190957986
            ],
            [
              -85.533434,
              34.514083
            ],
            [
              -85.5444,
              34.500085
            ],
            [
              -85.559365,
              34.501247
            ],
            [
              -85.560386,
              34.489909
            ],
            [
              -85.576981,
              34.483543
            ],
            [
              -85.570342,
              34.467764
            ],
            [
              -85.580325,
              34.470306
            ],
            [
              -85.583917,
              34.457961
            ],
            [
              -85.579656,
              34.439709
            ],
            [
              -85.600683,
              34.420926
            ],
            [
              -85.616667,
              34.393415
            ],
            [
              -85.627512,
              34.39592
            ],
            [
              -85.620337,
              34.386978
            ],
            [
              -85.636645,
              34.366622
            ],
            [
              -85.664154,
              34.361937
            ],
            [
              -85.672166,
              34.351838
            ],
            [
              -85.675283,
              34.343396
            ],
            [
              -85.774165,
              34.259094
            ],
            [
              -85.809045,
              34.258921
            ],
            [
              -85.808417,
              34.239377
            ],
            [
              -85.843801,
              34.244595
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "033",
      "COUNTYNS": "00161542",
      "AFFGEOID": "0500000US01033",
      "GEOID": "01033",
      "NAME": "Colbert",
      "LSAD": "06",
      "ALAND": 1534875463,
      "AWATER": 80027136,
      "County_state": "Colbert,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.139246,
              34.587795
            ],
            [
              -88.139988,
              34.581703
            ],
            [
              -87.637926,
              34.56915
            ],
            [
              -87.600753,
              34.568237
            ],
            [
              -87.529667,
              34.567081
            ],
            [
              -87.517615,
              34.566271
            ],
            [
              -87.518558,
              34.577482
            ],
            [
              -87.507342,
              34.586491
            ],
            [
              -87.513912,
              34.59476
            ],
            [
              -87.496908,
              34.625988
            ],
            [
              -87.476822,
              34.637798
            ],
            [
              -87.483226,
              34.64857
            ],
            [
              -87.459496,
              34.644349
            ],
            [
              -87.443353,
              34.661227
            ],
            [
              -87.451138,
              34.682385
            ],
            [
              -87.44995,
              34.696804
            ],
            [
              -87.426161,
              34.709743
            ],
            [
              -87.432429,
              34.737135
            ],
            [
              -87.416484,
              34.767544
            ],
            [
              -87.434391,
              34.773614
            ],
            [
              -87.42651,
              34.800022
            ],
            [
              -87.44998,
              34.80828
            ],
            [
              -87.479966,
              34.812626
            ],
            [
              -87.524363,
              34.832384
            ],
            [
              -87.54629,
              34.830431
            ],
            [
              -87.584746,
              34.819188
            ],
            [
              -87.589485,
              34.81074
            ],
            [
              -87.613694,
              34.79903
            ],
            [
              -87.643396,
              34.792846
            ],
            [
              -87.671051,
              34.782542
            ],
            [
              -87.675387,
              34.782145
            ],
            [
              -87.694193,
              34.776628
            ],
            [
              -87.707572,
              34.772919
            ],
            [
              -87.732665,
              34.745687
            ],
            [
              -87.767331,
              34.734589
            ],
            [
              -87.806963,
              34.732061
            ],
            [
              -87.835833,
              34.741239
            ],
            [
              -87.861315,
              34.762983
            ],
            [
              -87.92029,
              34.804468
            ],
            [
              -87.933456,
              34.838902
            ],
            [
              -87.952269,
              34.867663
            ],
            [
              -87.986612,
              34.886745
            ],
            [
              -88.040871,
              34.906613
            ],
            [
              -88.097888,
              34.892202
            ],
            [
              -88.10756,
              34.811628
            ],
            [
              -88.116418,
              34.746303
            ],
            [
              -88.118407,
              34.724292
            ],
            [
              -88.1229228444128,
              34.6953468499391
            ],
            [
              -88.134263,
              34.62266
            ],
            [
              -88.138719,
              34.589215
            ],
            [
              -88.139246,
              34.587795
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "101",
      "COUNTYNS": "00161577",
      "AFFGEOID": "0500000US01101",
      "GEOID": "01101",
      "NAME": "Montgomery",
      "LSAD": "06",
      "ALAND": 2033764493,
      "AWATER": 37997545,
      "County_state": "Montgomery,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.492451,
              32.36348
            ],
            [
              -86.496774,
              32.344437
            ],
            [
              -86.474479,
              32.331547
            ],
            [
              -86.491055,
              32.301622
            ],
            [
              -86.484678,
              32.284473
            ],
            [
              -86.408771,
              32.244309
            ],
            [
              -86.408272,
              32.208976
            ],
            [
              -86.406276,
              32.050731
            ],
            [
              -86.307039,
              32.051696
            ],
            [
              -86.303513,
              32.050309
            ],
            [
              -86.302217,
              31.965065
            ],
            [
              -86.191379,
              31.966453
            ],
            [
              -85.995563,
              31.967554
            ],
            [
              -85.996853,
              32.051049
            ],
            [
              -85.997859,
              32.141605
            ],
            [
              -85.999157,
              32.250543
            ],
            [
              -85.986546,
              32.250636
            ],
            [
              -85.986557,
              32.272342
            ],
            [
              -85.919293,
              32.274382
            ],
            [
              -85.954322,
              32.302208
            ],
            [
              -85.958464,
              32.323484
            ],
            [
              -85.969104,
              32.333551
            ],
            [
              -85.991283,
              32.336098
            ],
            [
              -85.993667,
              32.357908
            ],
            [
              -86.014579,
              32.38429
            ],
            [
              -86.010941,
              32.40431
            ],
            [
              -86.023012,
              32.419978
            ],
            [
              -86.042547,
              32.417926
            ],
            [
              -86.046402,
              32.406146
            ],
            [
              -86.078063,
              32.412714
            ],
            [
              -86.086279,
              32.42165
            ],
            [
              -86.116329,
              32.417026
            ],
            [
              -86.129555,
              32.422188
            ],
            [
              -86.12545,
              32.434049
            ],
            [
              -86.151034,
              32.424636
            ],
            [
              -86.154242,
              32.434347
            ],
            [
              -86.192284,
              32.43613
            ],
            [
              -86.180603,
              32.447496
            ],
            [
              -86.198075,
              32.45138
            ],
            [
              -86.213988,
              32.474553
            ],
            [
              -86.237068,
              32.49498
            ],
            [
              -86.262894,
              32.501246
            ],
            [
              -86.262023,
              32.486524
            ],
            [
              -86.276718,
              32.486257
            ],
            [
              -86.290252,
              32.472608
            ],
            [
              -86.30539,
              32.488774
            ],
            [
              -86.314087,
              32.486173
            ],
            [
              -86.307984,
              32.467008
            ],
            [
              -86.325165,
              32.443527
            ],
            [
              -86.306722,
              32.419347
            ],
            [
              -86.310714,
              32.41172
            ],
            [
              -86.34447,
              32.432636
            ],
            [
              -86.398398,
              32.426941
            ],
            [
              -86.401775,
              32.411505
            ],
            [
              -86.411172,
              32.409937
            ],
            [
              -86.444721,
              32.399841
            ],
            [
              -86.456273,
              32.405837
            ],
            [
              -86.462497,
              32.378135
            ],
            [
              -86.476703,
              32.364418
            ],
            [
              -86.492451,
              32.36348
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "055",
      "COUNTYNS": "00161553",
      "AFFGEOID": "0500000US01055",
      "GEOID": "01055",
      "NAME": "Etowah",
      "LSAD": "06",
      "ALAND": 1386383039,
      "AWATER": 34559412,
      "County_state": "Etowah,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.339337,
              33.971484
            ],
            [
              -86.370152,
              33.93977
            ],
            [
              -86.325622,
              33.940147
            ],
            [
              -86.321312,
              33.950002
            ],
            [
              -86.290127,
              33.983719
            ],
            [
              -86.277828,
              33.989302
            ],
            [
              -86.251585,
              33.965604
            ],
            [
              -86.230207,
              33.982787
            ],
            [
              -86.199164,
              33.988918
            ],
            [
              -86.183634,
              33.972202
            ],
            [
              -86.168141,
              33.965181
            ],
            [
              -86.166162,
              33.944697
            ],
            [
              -86.16537,
              33.926578
            ],
            [
              -86.149202,
              33.920565
            ],
            [
              -86.142717,
              33.899391
            ],
            [
              -86.112474,
              33.903998
            ],
            [
              -86.096668,
              33.861365
            ],
            [
              -86.073123,
              33.865783
            ],
            [
              -86.054537,
              33.846397
            ],
            [
              -86.065272,
              33.842198
            ],
            [
              -86.049072,
              33.841598
            ],
            [
              -86.046525,
              33.852601
            ],
            [
              -86.021271,
              33.851498
            ],
            [
              -86.021471,
              33.866098
            ],
            [
              -85.995169,
              33.864897
            ],
            [
              -85.987542,
              33.879205
            ],
            [
              -85.98647,
              33.893698
            ],
            [
              -85.96917,
              33.892898
            ],
            [
              -85.96917,
              33.903398
            ],
            [
              -85.96917,
              33.914598
            ],
            [
              -85.934469,
              33.920998
            ],
            [
              -85.934269,
              33.906398
            ],
            [
              -85.899568,
              33.906399
            ],
            [
              -85.899668,
              33.942999
            ],
            [
              -85.881768,
              33.950299
            ],
            [
              -85.846174,
              33.95726
            ],
            [
              -85.811032,
              33.947067
            ],
            [
              -85.740968,
              33.935301
            ],
            [
              -85.738975,
              33.96846
            ],
            [
              -85.737137,
              33.989203
            ],
            [
              -85.797071,
              34.100179
            ],
            [
              -85.791007,
              34.125096
            ],
            [
              -85.811967,
              34.125096
            ],
            [
              -85.833205,
              34.109944
            ],
            [
              -85.841365,
              34.110695
            ],
            [
              -85.843617,
              34.200006
            ],
            [
              -86.106086,
              34.200756
            ],
            [
              -86.105611,
              34.186232
            ],
            [
              -86.156997,
              34.187269
            ],
            [
              -86.173715,
              34.183483
            ],
            [
              -86.189971,
              34.182916
            ],
            [
              -86.303516,
              34.099073
            ],
            [
              -86.322662,
              34.072288
            ],
            [
              -86.332394,
              34.040499
            ],
            [
              -86.332723,
              33.986109
            ],
            [
              -86.339337,
              33.971484
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "059",
      "COUNTYNS": "00161555",
      "AFFGEOID": "0500000US01059",
      "GEOID": "01059",
      "NAME": "Franklin",
      "LSAD": "06",
      "ALAND": 1641839976,
      "AWATER": 32645401,
      "County_state": "Franklin,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.16591,
              34.380926
            ],
            [
              -88.173632,
              34.321054
            ],
            [
              -87.908896,
              34.312527
            ],
            [
              -87.73089,
              34.310187
            ],
            [
              -87.634725,
              34.306997
            ],
            [
              -87.529722,
              34.304598
            ],
            [
              -87.529667,
              34.567081
            ],
            [
              -87.600753,
              34.568237
            ],
            [
              -87.637926,
              34.56915
            ],
            [
              -88.139988,
              34.581703
            ],
            [
              -88.156292,
              34.463214
            ],
            [
              -88.165634,
              34.383102
            ],
            [
              -88.16591,
              34.380926
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "051",
      "COUNTYNS": "00161551",
      "AFFGEOID": "0500000US01051",
      "GEOID": "01051",
      "NAME": "Elmore",
      "LSAD": "06",
      "ALAND": 1601762124,
      "AWATER": 99965171,
      "County_state": "Elmore,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.413335,
              32.750591
            ],
            [
              -86.413116,
              32.707386
            ],
            [
              -86.412826,
              32.635879
            ],
            [
              -86.412377,
              32.556969
            ],
            [
              -86.411972,
              32.491417
            ],
            [
              -86.411172,
              32.409937
            ],
            [
              -86.401775,
              32.411505
            ],
            [
              -86.398398,
              32.426941
            ],
            [
              -86.34447,
              32.432636
            ],
            [
              -86.310714,
              32.41172
            ],
            [
              -86.306722,
              32.419347
            ],
            [
              -86.325165,
              32.443527
            ],
            [
              -86.307984,
              32.467008
            ],
            [
              -86.314087,
              32.486173
            ],
            [
              -86.30539,
              32.488774
            ],
            [
              -86.290252,
              32.472608
            ],
            [
              -86.276718,
              32.486257
            ],
            [
              -86.262023,
              32.486524
            ],
            [
              -86.262894,
              32.501246
            ],
            [
              -86.237068,
              32.49498
            ],
            [
              -86.213988,
              32.474553
            ],
            [
              -86.198075,
              32.45138
            ],
            [
              -86.180603,
              32.447496
            ],
            [
              -86.192284,
              32.43613
            ],
            [
              -86.154242,
              32.434347
            ],
            [
              -86.151034,
              32.424636
            ],
            [
              -86.12545,
              32.434049
            ],
            [
              -86.129555,
              32.422188
            ],
            [
              -86.116329,
              32.417026
            ],
            [
              -86.086279,
              32.42165
            ],
            [
              -86.078063,
              32.412714
            ],
            [
              -86.046402,
              32.406146
            ],
            [
              -86.042547,
              32.417926
            ],
            [
              -86.023012,
              32.419978
            ],
            [
              -85.990652,
              32.433053
            ],
            [
              -85.993493,
              32.451509
            ],
            [
              -85.985262,
              32.440472
            ],
            [
              -85.973455,
              32.453453
            ],
            [
              -85.958953,
              32.44113
            ],
            [
              -85.931776,
              32.453528
            ],
            [
              -85.89442,
              32.447578
            ],
            [
              -85.882219,
              32.454988
            ],
            [
              -85.872695,
              32.473869
            ],
            [
              -85.852625,
              32.475747
            ],
            [
              -85.870305,
              32.481474
            ],
            [
              -85.886148,
              32.493053
            ],
            [
              -85.890392,
              32.500135
            ],
            [
              -85.885458,
              32.547634
            ],
            [
              -85.894559,
              32.603132
            ],
            [
              -85.890283,
              32.633162
            ],
            [
              -85.887747,
              32.64761
            ],
            [
              -85.911714,
              32.67784
            ],
            [
              -85.90747,
              32.699302
            ],
            [
              -85.890611,
              32.717005
            ],
            [
              -85.893402,
              32.727663
            ],
            [
              -85.881859,
              32.737558
            ],
            [
              -85.87986,
              32.754528
            ],
            [
              -85.954859,
              32.754824
            ],
            [
              -85.955561,
              32.769126
            ],
            [
              -85.972763,
              32.769225
            ],
            [
              -85.972159,
              32.75493
            ],
            [
              -86.007187,
              32.754984
            ],
            [
              -86.31948,
              32.753698
            ],
            [
              -86.319163,
              32.767837
            ],
            [
              -86.336694,
              32.76813
            ],
            [
              -86.336768,
              32.753783
            ],
            [
              -86.374974,
              32.75358
            ],
            [
              -86.413335,
              32.750591
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "105",
      "COUNTYNS": "00161579",
      "AFFGEOID": "0500000US01105",
      "GEOID": "01105",
      "NAME": "Perry",
      "LSAD": "06",
      "ALAND": 1863885725,
      "AWATER": 10952676,
      "County_state": "Perry,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.525198,
              32.655713
            ],
            [
              -87.524485,
              32.482028
            ],
            [
              -87.52429,
              32.307293
            ],
            [
              -87.47308,
              32.307614
            ],
            [
              -87.421744,
              32.308101
            ],
            [
              -87.423153,
              32.482965
            ],
            [
              -87.265617,
              32.48402
            ],
            [
              -87.220981,
              32.486989
            ],
            [
              -87.112747,
              32.488509
            ],
            [
              -87.100724,
              32.504737
            ],
            [
              -87.089967,
              32.534416
            ],
            [
              -87.081844,
              32.537078
            ],
            [
              -87.077256,
              32.557364
            ],
            [
              -87.081405,
              32.570428
            ],
            [
              -87.057007,
              32.584443
            ],
            [
              -87.040711,
              32.59919
            ],
            [
              -87.047873,
              32.620776
            ],
            [
              -87.043535,
              32.64295
            ],
            [
              -87.032081,
              32.664
            ],
            [
              -87.033255,
              32.695844
            ],
            [
              -87.017762,
              32.729532
            ],
            [
              -87.018251,
              32.78803
            ],
            [
              -87.019157,
              32.837034
            ],
            [
              -87.098183,
              32.836008
            ],
            [
              -87.223663,
              32.835149
            ],
            [
              -87.233286,
              32.832239
            ],
            [
              -87.319184,
              32.831522
            ],
            [
              -87.319473,
              32.875124
            ],
            [
              -87.4212,
              32.874508
            ],
            [
              -87.421387,
              32.830829
            ],
            [
              -87.471665,
              32.830622
            ],
            [
              -87.472194,
              32.743451
            ],
            [
              -87.473807,
              32.728189
            ],
            [
              -87.473915,
              32.655867
            ],
            [
              -87.525198,
              32.655713
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "015",
      "COUNTYNS": "00161533",
      "AFFGEOID": "0500000US01015",
      "GEOID": "01015",
      "NAME": "Calhoun",
      "LSAD": "06",
      "ALAND": 1569244078,
      "AWATER": 16573120,
      "County_state": "Calhoun,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.143711,
              33.709135
            ],
            [
              -86.145562,
              33.679098
            ],
            [
              -86.105988,
              33.6772
            ],
            [
              -86.054816,
              33.674716
            ],
            [
              -86.049576,
              33.673468
            ],
            [
              -86.025797,
              33.646285
            ],
            [
              -86.023381,
              33.623123
            ],
            [
              -86.021707,
              33.601172
            ],
            [
              -86.002107,
              33.601021
            ],
            [
              -85.999554,
              33.600959
            ],
            [
              -85.994935,
              33.586475
            ],
            [
              -85.948416,
              33.5858
            ],
            [
              -85.875569,
              33.586004
            ],
            [
              -85.794559,
              33.585565
            ],
            [
              -85.796054,
              33.55622
            ],
            [
              -85.744118,
              33.556075
            ],
            [
              -85.742308,
              33.600006
            ],
            [
              -85.724517,
              33.59943
            ],
            [
              -85.724953,
              33.613539
            ],
            [
              -85.69014,
              33.615815
            ],
            [
              -85.690684,
              33.627789
            ],
            [
              -85.65581,
              33.627166
            ],
            [
              -85.64704,
              33.648772
            ],
            [
              -85.638579,
              33.648413
            ],
            [
              -85.638049,
              33.773339
            ],
            [
              -85.621245,
              33.773508
            ],
            [
              -85.62089,
              33.787944
            ],
            [
              -85.603469,
              33.787755
            ],
            [
              -85.603391,
              33.802344
            ],
            [
              -85.585985,
              33.802095
            ],
            [
              -85.585201,
              33.84592
            ],
            [
              -85.637126,
              33.846497
            ],
            [
              -85.637012,
              33.875273
            ],
            [
              -85.601899,
              33.874865
            ],
            [
              -85.601858,
              33.88975
            ],
            [
              -85.532482,
              33.889152
            ],
            [
              -85.530094,
              33.941423
            ],
            [
              -85.529432,
              33.95598
            ],
            [
              -85.59115,
              33.959641
            ],
            [
              -85.712088,
              33.966906
            ],
            [
              -85.738975,
              33.96846
            ],
            [
              -85.740968,
              33.935301
            ],
            [
              -85.811032,
              33.947067
            ],
            [
              -85.846174,
              33.95726
            ],
            [
              -85.881768,
              33.950299
            ],
            [
              -85.899668,
              33.942999
            ],
            [
              -85.899568,
              33.906399
            ],
            [
              -85.934269,
              33.906398
            ],
            [
              -85.934469,
              33.920998
            ],
            [
              -85.96917,
              33.914598
            ],
            [
              -85.96917,
              33.903398
            ],
            [
              -85.96917,
              33.892898
            ],
            [
              -85.98647,
              33.893698
            ],
            [
              -85.987542,
              33.879205
            ],
            [
              -85.995169,
              33.864897
            ],
            [
              -86.021471,
              33.866098
            ],
            [
              -86.021271,
              33.851498
            ],
            [
              -86.046525,
              33.852601
            ],
            [
              -86.049072,
              33.841598
            ],
            [
              -86.065272,
              33.842198
            ],
            [
              -86.051439,
              33.824134
            ],
            [
              -86.064364,
              33.797775
            ],
            [
              -86.044363,
              33.763058
            ],
            [
              -86.060121,
              33.761116
            ],
            [
              -86.08556,
              33.740659
            ],
            [
              -86.098664,
              33.736616
            ],
            [
              -86.123882,
              33.710789
            ],
            [
              -86.143711,
              33.709135
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "047",
      "COUNTYNS": "00161549",
      "AFFGEOID": "0500000US01047",
      "GEOID": "01047",
      "NAME": "Dallas",
      "LSAD": "06",
      "ALAND": 2534863481,
      "AWATER": 39131006,
      "County_state": "Dallas,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.47308,
              32.307614
            ],
            [
              -87.472206,
              32.264919
            ],
            [
              -87.444429,
              32.268889
            ],
            [
              -87.42275,
              32.244118
            ],
            [
              -87.409079,
              32.236736
            ],
            [
              -87.392284,
              32.239894
            ],
            [
              -87.388599,
              32.223166
            ],
            [
              -87.374521,
              32.221922
            ],
            [
              -87.365394,
              32.197225
            ],
            [
              -87.324783,
              32.199069
            ],
            [
              -87.317962,
              32.181264
            ],
            [
              -87.29253,
              32.167904
            ],
            [
              -87.280866,
              32.144345
            ],
            [
              -87.262853,
              32.147007
            ],
            [
              -87.252237,
              32.135795
            ],
            [
              -87.261156,
              32.123753
            ],
            [
              -87.245513,
              32.122165
            ],
            [
              -87.234028,
              32.101694
            ],
            [
              -87.189937,
              32.086058
            ],
            [
              -87.191578,
              32.070256
            ],
            [
              -87.175585,
              32.064701
            ],
            [
              -87.17786,
              32.047514
            ],
            [
              -86.906956,
              32.04797
            ],
            [
              -86.908302,
              32.225028
            ],
            [
              -86.810313,
              32.224747
            ],
            [
              -86.810448,
              32.237589
            ],
            [
              -86.834478,
              32.236775
            ],
            [
              -86.854983,
              32.256701
            ],
            [
              -86.864432,
              32.271777
            ],
            [
              -86.858396,
              32.284112
            ],
            [
              -86.847327,
              32.287906
            ],
            [
              -86.82606,
              32.305957
            ],
            [
              -86.850507,
              32.309857
            ],
            [
              -86.850412,
              32.328947
            ],
            [
              -86.814912,
              32.340803
            ],
            [
              -86.807378,
              32.354356
            ],
            [
              -86.815399,
              32.370821
            ],
            [
              -86.827244,
              32.378816
            ],
            [
              -86.843758,
              32.400416
            ],
            [
              -86.845448,
              32.415416
            ],
            [
              -86.824394,
              32.424725
            ],
            [
              -86.849898,
              32.438325
            ],
            [
              -86.86709,
              32.439188
            ],
            [
              -86.860056,
              32.450861
            ],
            [
              -86.890581,
              32.502974
            ],
            [
              -86.906403,
              32.536712
            ],
            [
              -86.898304,
              32.567687
            ],
            [
              -86.905677,
              32.601002
            ],
            [
              -86.911962,
              32.612192
            ],
            [
              -86.910859,
              32.629088
            ],
            [
              -86.921145,
              32.65623
            ],
            [
              -86.917595,
              32.664169
            ],
            [
              -87.01766,
              32.663269
            ],
            [
              -87.017762,
              32.729532
            ],
            [
              -87.033255,
              32.695844
            ],
            [
              -87.032081,
              32.664
            ],
            [
              -87.043535,
              32.64295
            ],
            [
              -87.047873,
              32.620776
            ],
            [
              -87.040711,
              32.59919
            ],
            [
              -87.057007,
              32.584443
            ],
            [
              -87.081405,
              32.570428
            ],
            [
              -87.077256,
              32.557364
            ],
            [
              -87.081844,
              32.537078
            ],
            [
              -87.089967,
              32.534416
            ],
            [
              -87.100724,
              32.504737
            ],
            [
              -87.112747,
              32.488509
            ],
            [
              -87.220981,
              32.486989
            ],
            [
              -87.265617,
              32.48402
            ],
            [
              -87.423153,
              32.482965
            ],
            [
              -87.421744,
              32.308101
            ],
            [
              -87.47308,
              32.307614
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "035",
      "COUNTYNS": "00161543",
      "AFFGEOID": "0500000US01035",
      "GEOID": "01035",
      "NAME": "Conecuh",
      "LSAD": "06",
      "ALAND": 2201948618,
      "AWATER": 6643480,
      "County_state": "Conecuh,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.420796,
              31.27652
            ],
            [
              -87.427455,
              31.260386
            ],
            [
              -87.036472,
              31.261315
            ],
            [
              -86.871022,
              31.261902
            ],
            [
              -86.767537,
              31.261333
            ],
            [
              -86.772263,
              31.250655
            ],
            [
              -86.763763,
              31.220463
            ],
            [
              -86.772519,
              31.202243
            ],
            [
              -86.766368,
              31.182297
            ],
            [
              -86.756671,
              31.193158
            ],
            [
              -86.737189,
              31.187358
            ],
            [
              -86.700282,
              31.192217
            ],
            [
              -86.701139,
              31.324358
            ],
            [
              -86.702446,
              31.345397
            ],
            [
              -86.692516,
              31.347479
            ],
            [
              -86.667245,
              31.369404
            ],
            [
              -86.675442,
              31.389001
            ],
            [
              -86.662082,
              31.402798
            ],
            [
              -86.67485,
              31.436557
            ],
            [
              -86.70122,
              31.436641
            ],
            [
              -86.701554,
              31.523946
            ],
            [
              -86.839386,
              31.525204
            ],
            [
              -86.84351,
              31.535654
            ],
            [
              -86.866254,
              31.54642
            ],
            [
              -86.874565,
              31.577993
            ],
            [
              -86.888106,
              31.592207
            ],
            [
              -86.893959,
              31.61634
            ],
            [
              -86.906461,
              31.632622
            ],
            [
              -86.905899,
              31.753035
            ],
            [
              -86.933897,
              31.746158
            ],
            [
              -86.948725,
              31.734883
            ],
            [
              -86.979895,
              31.726179
            ],
            [
              -86.989524,
              31.718226
            ],
            [
              -87.024141,
              31.712559
            ],
            [
              -87.0511,
              31.71834
            ],
            [
              -87.060954,
              31.704848
            ],
            [
              -87.055967,
              31.677381
            ],
            [
              -87.078331,
              31.666343
            ],
            [
              -87.094854,
              31.652136
            ],
            [
              -87.135899,
              31.641719
            ],
            [
              -87.145126,
              31.587168
            ],
            [
              -87.158925,
              31.56614
            ],
            [
              -87.166581,
              31.519561
            ],
            [
              -87.177864,
              31.5041
            ],
            [
              -87.188207,
              31.487678
            ],
            [
              -87.197237,
              31.479626
            ],
            [
              -87.205931,
              31.459025
            ],
            [
              -87.227797,
              31.454363
            ],
            [
              -87.296721,
              31.401278
            ],
            [
              -87.324391,
              31.366293
            ],
            [
              -87.337553,
              31.352121
            ],
            [
              -87.343748,
              31.351851
            ],
            [
              -87.360024,
              31.317496
            ],
            [
              -87.393206,
              31.300853
            ],
            [
              -87.420796,
              31.27652
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "023",
      "COUNTYNS": "00161537",
      "AFFGEOID": "0500000US01023",
      "GEOID": "01023",
      "NAME": "Choctaw",
      "LSAD": "06",
      "ALAND": 2365869837,
      "AWATER": 19144469,
      "County_state": "Choctaw,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.473227,
              31.893856
            ],
            [
              -88.472642,
              31.875153
            ],
            [
              -88.471214,
              31.851385
            ],
            [
              -88.471106,
              31.850949
            ],
            [
              -88.468669,
              31.790722
            ],
            [
              -88.465107,
              31.722312
            ],
            [
              -88.46442697928089,
              31.697951902948798
            ],
            [
              -88.088288,
              31.699303
            ],
            [
              -88.093383,
              31.737524
            ],
            [
              -88.104918,
              31.760798
            ],
            [
              -88.142624,
              31.752491
            ],
            [
              -88.158583,
              31.766138
            ],
            [
              -88.179302,
              31.821297
            ],
            [
              -88.161945,
              31.840803
            ],
            [
              -88.158366,
              31.853511
            ],
            [
              -88.141987,
              31.86436
            ],
            [
              -88.129434,
              31.904936
            ],
            [
              -88.088645,
              31.910775
            ],
            [
              -88.077033,
              31.921815
            ],
            [
              -88.055248,
              31.923478
            ],
            [
              -88.067717,
              31.936905
            ],
            [
              -88.088087,
              31.921931
            ],
            [
              -88.102291,
              31.939993
            ],
            [
              -88.117209,
              31.939283
            ],
            [
              -88.121414,
              31.950256
            ],
            [
              -88.096675,
              31.965019
            ],
            [
              -88.080913,
              31.953908
            ],
            [
              -88.070763,
              31.958645
            ],
            [
              -88.07349,
              31.990182
            ],
            [
              -88.090543,
              32.007052
            ],
            [
              -88.095071,
              31.993078
            ],
            [
              -88.107857,
              32.000149
            ],
            [
              -88.117433,
              32.01682
            ],
            [
              -88.113399,
              32.041176
            ],
            [
              -88.118211,
              32.053443
            ],
            [
              -88.09589,
              32.069847
            ],
            [
              -88.084771,
              32.070824
            ],
            [
              -88.070615,
              32.0617
            ],
            [
              -88.044715,
              32.082256
            ],
            [
              -88.052203,
              32.10177
            ],
            [
              -88.035812,
              32.14501
            ],
            [
              -88.021369,
              32.14495
            ],
            [
              -88.016846,
              32.153413
            ],
            [
              -88.007077,
              32.185313
            ],
            [
              -88.012536,
              32.200826
            ],
            [
              -88.007523,
              32.219319
            ],
            [
              -88.01505,
              32.23494
            ],
            [
              -88.009453,
              32.261704
            ],
            [
              -88.020121,
              32.271769
            ],
            [
              -88.019145,
              32.28524
            ],
            [
              -88.004225,
              32.283687
            ],
            [
              -88.007316,
              32.293197
            ],
            [
              -87.991395,
              32.294615
            ],
            [
              -87.975667,
              32.307848
            ],
            [
              -87.967893,
              32.298249
            ],
            [
              -87.948354,
              32.30409
            ],
            [
              -87.936757,
              32.301664
            ],
            [
              -87.944645,
              32.282953
            ],
            [
              -87.933553,
              32.282366
            ],
            [
              -87.9297,
              32.292516
            ],
            [
              -87.931799,
              32.297266
            ],
            [
              -87.930661,
              32.310574
            ],
            [
              -88.421453,
              32.30868
            ],
            [
              -88.428278,
              32.250143
            ],
            [
              -88.43129401638389,
              32.2276546024792
            ],
            [
              -88.4338373824385,
              32.2086904392936
            ],
            [
              -88.43865,
              32.172806
            ],
            [
              -88.43871,
              32.172078
            ],
            [
              -88.443049750597,
              32.1369567074276
            ],
            [
              -88.4534176891327,
              32.0530497191012
            ],
            [
              -88.454959,
              32.040576
            ],
            [
              -88.455039,
              32.039719
            ],
            [
              -88.46866,
              31.933173
            ],
            [
              -88.468879,
              31.930262
            ],
            [
              -88.473227,
              31.893856
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "119",
      "COUNTYNS": "00161585",
      "AFFGEOID": "0500000US01119",
      "GEOID": "01119",
      "NAME": "Sumter",
      "LSAD": "06",
      "ALAND": 2340898915,
      "AWATER": 24634883,
      "County_state": "Sumter,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.413819,
              32.373922
            ],
            [
              -88.421453,
              32.30868
            ],
            [
              -87.930661,
              32.310574
            ],
            [
              -87.941974,
              32.314649
            ],
            [
              -87.951892,
              32.328915
            ],
            [
              -87.970943,
              32.344347
            ],
            [
              -87.993862,
              32.34723
            ],
            [
              -88.019476,
              32.370461
            ],
            [
              -88.046335,
              32.377087
            ],
            [
              -88.042323,
              32.410271
            ],
            [
              -88.031623,
              32.43361
            ],
            [
              -87.994566,
              32.455242
            ],
            [
              -87.975056,
              32.457559
            ],
            [
              -87.954149,
              32.472972
            ],
            [
              -87.945065,
              32.491251
            ],
            [
              -87.913666,
              32.518189
            ],
            [
              -87.901264,
              32.523172
            ],
            [
              -87.85268,
              32.515203
            ],
            [
              -87.844575,
              32.518183
            ],
            [
              -87.841507,
              32.525573
            ],
            [
              -87.85342,
              32.532086
            ],
            [
              -87.86242,
              32.532739
            ],
            [
              -87.874434,
              32.549926
            ],
            [
              -87.875014,
              32.571824
            ],
            [
              -87.898355,
              32.592321
            ],
            [
              -87.882628,
              32.609723
            ],
            [
              -87.862095,
              32.606484
            ],
            [
              -87.860194,
              32.590888
            ],
            [
              -87.840889,
              32.605763
            ],
            [
              -87.85812,
              32.620793
            ],
            [
              -87.877238,
              32.622084
            ],
            [
              -87.904841,
              32.614576
            ],
            [
              -87.911821,
              32.624764
            ],
            [
              -87.928689,
              32.632284
            ],
            [
              -87.947297,
              32.626827
            ],
            [
              -87.977005,
              32.610781
            ],
            [
              -88.009887,
              32.600721
            ],
            [
              -88.053729,
              32.593052
            ],
            [
              -88.079159,
              32.619684
            ],
            [
              -88.080283,
              32.633148
            ],
            [
              -88.057037,
              32.643129
            ],
            [
              -88.057987,
              32.674384
            ],
            [
              -88.040708,
              32.689584
            ],
            [
              -88.054828,
              32.696697
            ],
            [
              -88.07823,
              32.684002
            ],
            [
              -88.110089,
              32.690762
            ],
            [
              -88.115864,
              32.701039
            ],
            [
              -88.08538,
              32.709054
            ],
            [
              -88.081872,
              32.719428
            ],
            [
              -88.101427,
              32.739261
            ],
            [
              -88.109633,
              32.770989
            ],
            [
              -88.10298,
              32.779426
            ],
            [
              -88.075882,
              32.77451
            ],
            [
              -88.064004,
              32.790674
            ],
            [
              -88.07324,
              32.804563
            ],
            [
              -88.098691,
              32.795884
            ],
            [
              -88.105407,
              32.798412
            ],
            [
              -88.132685,
              32.832853
            ],
            [
              -88.147278,
              32.824866
            ],
            [
              -88.178343,
              32.828535
            ],
            [
              -88.154973,
              32.852874
            ],
            [
              -88.179966,
              32.871675
            ],
            [
              -88.189754,
              32.910731
            ],
            [
              -88.207316,
              32.924782
            ],
            [
              -88.196302,
              32.935651
            ],
            [
              -88.175329,
              32.932093
            ],
            [
              -88.172299,
              32.942892
            ],
            [
              -88.182362,
              32.945689
            ],
            [
              -88.184064,
              32.962452
            ],
            [
              -88.165276,
              32.954321
            ],
            [
              -88.157349,
              32.964582
            ],
            [
              -88.167338,
              32.970551
            ],
            [
              -88.168737,
              32.973287
            ],
            [
              -88.16844,
              32.98006
            ],
            [
              -88.168649,
              32.981084
            ],
            [
              -88.17221,
              32.98657
            ],
            [
              -88.17242,
              32.987628
            ],
            [
              -88.171466,
              32.991937
            ],
            [
              -88.171852,
              32.99586
            ],
            [
              -88.340432,
              32.991199
            ],
            [
              -88.3478500256084,
              32.929077584824
            ],
            [
              -88.354292,
              32.87513
            ],
            [
              -88.368349,
              32.747656
            ],
            [
              -88.373338,
              32.711825
            ],
            [
              -88.382985,
              32.626954
            ],
            [
              -88.383039,
              32.626679
            ],
            [
              -88.3888572465182,
              32.5781229194103
            ],
            [
              -88.399966,
              32.485415
            ],
            [
              -88.3999703119346,
              32.4853749259647
            ],
            [
              -88.403789,
              32.449885
            ],
            [
              -88.403789,
              32.44977
            ],
            [
              -88.4100961455102,
              32.3992715654218
            ],
            [
              -88.4125,
              32.380025
            ],
            [
              -88.413819,
              32.373922
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "111",
      "COUNTYNS": "00161582",
      "AFFGEOID": "0500000US01111",
      "GEOID": "01111",
      "NAME": "Randolph",
      "LSAD": "06",
      "ALAND": 1503615934,
      "AWATER": 9285420,
      "County_state": "Randolph,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.650466,
              33.306154
            ],
            [
              -85.653654,
              33.106634
            ],
            [
              -85.593177,
              33.10734
            ],
            [
              -85.23244140250969,
              33.1080770939296
            ],
            [
              -85.23656892180279,
              33.129549211458
            ],
            [
              -85.2939525862265,
              33.4280696299523
            ],
            [
              -85.3044873227631,
              33.4828732747038
            ],
            [
              -85.331061,
              33.491014
            ],
            [
              -85.347744,
              33.501378
            ],
            [
              -85.352573,
              33.492438
            ],
            [
              -85.643482,
              33.495885
            ],
            [
              -85.649915,
              33.324211
            ],
            [
              -85.641132,
              33.323932
            ],
            [
              -85.641435,
              33.305959
            ],
            [
              -85.650466,
              33.306154
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "083",
      "COUNTYNS": "00161567",
      "AFFGEOID": "0500000US01083",
      "GEOID": "01083",
      "NAME": "Limestone",
      "LSAD": "06",
      "ALAND": 1450278151,
      "AWATER": 122329451,
      "County_state": "Limestone,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.271079,
              34.783829
            ],
            [
              -87.278302,
              34.773563
            ],
            [
              -87.260676,
              34.758626
            ],
            [
              -87.222773,
              34.763226
            ],
            [
              -87.171588,
              34.74286
            ],
            [
              -87.138858,
              34.724101
            ],
            [
              -87.119458,
              34.697258
            ],
            [
              -87.105073,
              34.686037
            ],
            [
              -87.052861,
              34.650399
            ],
            [
              -87.035323,
              34.652042
            ],
            [
              -87.00658,
              34.634113
            ],
            [
              -86.992053,
              34.627297
            ],
            [
              -86.980266,
              34.618564
            ],
            [
              -86.97279,
              34.613344
            ],
            [
              -86.907205,
              34.579792
            ],
            [
              -86.860116,
              34.567128
            ],
            [
              -86.836897,
              34.556186
            ],
            [
              -86.790056,
              34.55079
            ],
            [
              -86.786928,
              34.662612
            ],
            [
              -86.786448,
              34.718172
            ],
            [
              -86.786359,
              34.728976
            ],
            [
              -86.785388,
              34.802191
            ],
            [
              -86.785328,
              34.805803
            ],
            [
              -86.783696,
              34.933403
            ],
            [
              -86.78362801709059,
              34.9919250273269
            ],
            [
              -86.783648,
              34.991925
            ],
            [
              -86.820657,
              34.991764
            ],
            [
              -86.836306,
              34.991764
            ],
            [
              -86.83637,
              34.991764
            ],
            [
              -86.846466,
              34.99186
            ],
            [
              -86.849794,
              34.991924
            ],
            [
              -86.85504577547928,
              34.9919376045345
            ],
            [
              -86.862147,
              34.991956
            ],
            [
              -86.96712,
              34.9944
            ],
            [
              -86.970236,
              34.994546
            ],
            [
              -86.972613,
              34.99461
            ],
            [
              -86.974412,
              34.994513
            ],
            [
              -87.000007,
              34.995121
            ],
            [
              -87.011174,
              34.995162
            ],
            [
              -87.1226456781005,
              34.9973189938664
            ],
            [
              -87.210759,
              34.999024
            ],
            [
              -87.214914,
              34.816011
            ],
            [
              -87.255566,
              34.788354
            ],
            [
              -87.271079,
              34.783829
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "095",
      "COUNTYNS": "00161574",
      "AFFGEOID": "0500000US01095",
      "GEOID": "01095",
      "NAME": "Marshall",
      "LSAD": "06",
      "ALAND": 1465528724,
      "AWATER": 148498594,
      "County_state": "Marshall,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.581634,
              34.33168
            ],
            [
              -86.581936,
              34.304694
            ],
            [
              -86.54952,
              34.303743
            ],
            [
              -86.477525,
              34.302758
            ],
            [
              -86.459449,
              34.289148
            ],
            [
              -86.45302,
              34.259317
            ],
            [
              -86.444133,
              34.259115
            ],
            [
              -86.43069,
              34.231891
            ],
            [
              -86.41694,
              34.214887
            ],
            [
              -86.409017,
              34.205002
            ],
            [
              -86.386702,
              34.18227
            ],
            [
              -86.378307,
              34.173792
            ],
            [
              -86.313663,
              34.111794
            ],
            [
              -86.303516,
              34.099073
            ],
            [
              -86.189971,
              34.182916
            ],
            [
              -86.173715,
              34.183483
            ],
            [
              -86.156997,
              34.187269
            ],
            [
              -86.105611,
              34.186232
            ],
            [
              -86.106086,
              34.200756
            ],
            [
              -86.107433,
              34.249798
            ],
            [
              -86.107244,
              34.251145
            ],
            [
              -86.107907,
              34.282721
            ],
            [
              -86.108916,
              34.333764
            ],
            [
              -86.110073,
              34.374802
            ],
            [
              -86.110025,
              34.392945
            ],
            [
              -86.118894,
              34.403845
            ],
            [
              -86.110362,
              34.427797
            ],
            [
              -86.057712,
              34.475994
            ],
            [
              -86.087628,
              34.466558
            ],
            [
              -86.097741,
              34.512547
            ],
            [
              -86.097846,
              34.521696
            ],
            [
              -86.126378,
              34.5333
            ],
            [
              -86.147764,
              34.520904
            ],
            [
              -86.148681,
              34.588339
            ],
            [
              -86.148464,
              34.599069
            ],
            [
              -86.326853,
              34.599403
            ],
            [
              -86.303133,
              34.592633
            ],
            [
              -86.303081,
              34.579617
            ],
            [
              -86.331423,
              34.573261
            ],
            [
              -86.328847,
              34.565242
            ],
            [
              -86.306646,
              34.560738
            ],
            [
              -86.311187,
              34.539277
            ],
            [
              -86.324499,
              34.529144
            ],
            [
              -86.330198,
              34.512684
            ],
            [
              -86.338181,
              34.510103
            ],
            [
              -86.35518,
              34.523489
            ],
            [
              -86.369735,
              34.513754
            ],
            [
              -86.392422,
              34.518379
            ],
            [
              -86.388926,
              34.500199
            ],
            [
              -86.412446,
              34.494155
            ],
            [
              -86.423914,
              34.479581
            ],
            [
              -86.45396,
              34.483798
            ],
            [
              -86.46891,
              34.475633
            ],
            [
              -86.499988,
              34.482292
            ],
            [
              -86.530777,
              34.500398
            ],
            [
              -86.531749,
              34.501275
            ],
            [
              -86.551094,
              34.541499
            ],
            [
              -86.550166,
              34.545963
            ],
            [
              -86.553093,
              34.54316
            ],
            [
              -86.573362,
              34.42014
            ],
            [
              -86.573656,
              34.418158
            ],
            [
              -86.58132,
              34.371094
            ],
            [
              -86.581634,
              34.33168
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "011",
      "COUNTYNS": "00161531",
      "AFFGEOID": "0500000US01011",
      "GEOID": "01011",
      "NAME": "Bullock",
      "LSAD": "06",
      "ALAND": 1613059168,
      "AWATER": 6054988,
      "County_state": "Bullock,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.999157,
              32.250543
            ],
            [
              -85.997859,
              32.141605
            ],
            [
              -85.996853,
              32.051049
            ],
            [
              -85.984054,
              32.047453
            ],
            [
              -85.975912,
              32.054425
            ],
            [
              -85.950253,
              32.05455
            ],
            [
              -85.946298,
              32.06189
            ],
            [
              -85.946278,
              32.054571
            ],
            [
              -85.911755,
              32.054752
            ],
            [
              -85.911458,
              32.047355
            ],
            [
              -85.893652,
              32.047351
            ],
            [
              -85.892651,
              31.989053
            ],
            [
              -85.88425,
              31.981852
            ],
            [
              -85.88435,
              31.967253
            ],
            [
              -85.790048,
              31.967254
            ],
            [
              -85.791047,
              31.880357
            ],
            [
              -85.657668,
              31.880275
            ],
            [
              -85.648767,
              31.911615
            ],
            [
              -85.604769,
              31.960852
            ],
            [
              -85.592551,
              31.994259
            ],
            [
              -85.580544,
              31.996955
            ],
            [
              -85.51236,
              31.999694
            ],
            [
              -85.512643,
              32.014055
            ],
            [
              -85.428476,
              32.014951
            ],
            [
              -85.430313,
              32.051962
            ],
            [
              -85.427442,
              32.13935
            ],
            [
              -85.410241,
              32.146651
            ],
            [
              -85.427441,
              32.146551
            ],
            [
              -85.433543,
              32.234648
            ],
            [
              -85.856218,
              32.231975
            ],
            [
              -85.862601,
              32.234915
            ],
            [
              -85.876061,
              32.246344
            ],
            [
              -85.877868,
              32.291528
            ],
            [
              -85.898539,
              32.305289
            ],
            [
              -85.898689,
              32.274987
            ],
            [
              -85.919293,
              32.274382
            ],
            [
              -85.986557,
              32.272342
            ],
            [
              -85.986546,
              32.250636
            ],
            [
              -85.999157,
              32.250543
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "113",
      "COUNTYNS": "00161583",
      "AFFGEOID": "0500000US01113",
      "GEOID": "01113",
      "NAME": "Russell",
      "LSAD": "06",
      "ALAND": 1660557977,
      "AWATER": 15661281,
      "County_state": "Russell,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.434045,
              32.40984
            ],
            [
              -85.433543,
              32.234648
            ],
            [
              -85.427441,
              32.146551
            ],
            [
              -85.410241,
              32.146651
            ],
            [
              -85.257834,
              32.147931
            ],
            [
              -85.259778,
              32.13854
            ],
            [
              -85.236261,
              32.123665
            ],
            [
              -85.22912,
              32.102707
            ],
            [
              -85.195747,
              32.081843
            ],
            [
              -85.191415,
              32.064594
            ],
            [
              -85.185067,
              32.061708
            ],
            [
              -85.04934254607609,
              32.0624439732037
            ],
            [
              -85.047063,
              32.087389
            ],
            [
              -85.058749,
              32.136018
            ],
            [
              -85.011267,
              32.180493
            ],
            [
              -84.961728,
              32.19865
            ],
            [
              -84.967428,
              32.219351
            ],
            [
              -84.930127,
              32.219051
            ],
            [
              -84.9199421479259,
              32.2308482009333
            ],
            [
              -84.891841,
              32.263398
            ],
            [
              -84.9338,
              32.29826
            ],
            [
              -84.9563588359664,
              32.3061320972527
            ],
            [
              -85.001874,
              32.322015
            ],
            [
              -85.008096,
              32.336677
            ],
            [
              -84.983466,
              32.363186
            ],
            [
              -84.9834252816289,
              32.3777300931769
            ],
            [
              -84.983386,
              32.391761
            ],
            [
              -84.96343,
              32.422544
            ],
            [
              -84.971831,
              32.442843
            ],
            [
              -84.995331,
              32.453243
            ],
            [
              -84.995231,
              32.475242
            ],
            [
              -84.9957506231826,
              32.4773708395717
            ],
            [
              -85.0026861200596,
              32.505784814729004
            ],
            [
              -85.00283808653009,
              32.5064074048035
            ],
            [
              -85.00374006827789,
              32.510102725763296
            ],
            [
              -85.056516,
              32.509277
            ],
            [
              -85.060814,
              32.507713
            ],
            [
              -85.059294,
              32.472909
            ],
            [
              -85.206457,
              32.470503
            ],
            [
              -85.224747,
              32.470283
            ],
            [
              -85.269466,
              32.469625
            ],
            [
              -85.333843,
              32.468639
            ],
            [
              -85.333812,
              32.468161
            ],
            [
              -85.330143,
              32.410842
            ],
            [
              -85.356843,
              32.410487
            ],
            [
              -85.434045,
              32.40984
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "117",
      "COUNTYNS": "00161584",
      "AFFGEOID": "0500000US01117",
      "GEOID": "01117",
      "NAME": "Shelby",
      "LSAD": "06",
      "ALAND": 2034107820,
      "AWATER": 62542790,
      "County_state": "Shelby,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.026846,
              33.246459
            ],
            [
              -87.025596,
              33.165795
            ],
            [
              -86.988799,
              33.161839
            ],
            [
              -86.969563,
              33.158135
            ],
            [
              -86.969309,
              33.10405
            ],
            [
              -86.969291,
              33.098397
            ],
            [
              -86.950876,
              33.098585
            ],
            [
              -86.951659,
              33.078741
            ],
            [
              -86.933992,
              33.078797
            ],
            [
              -86.933972,
              33.064059
            ],
            [
              -86.881182,
              33.049901
            ],
            [
              -86.881638,
              33.071861
            ],
            [
              -86.842723,
              33.07094
            ],
            [
              -86.824835,
              33.06853
            ],
            [
              -86.802305,
              33.068844
            ],
            [
              -86.730252,
              33.069274
            ],
            [
              -86.730002,
              33.069274
            ],
            [
              -86.610003,
              33.070003
            ],
            [
              -86.604032,
              33.055086
            ],
            [
              -86.553845,
              33.018931
            ],
            [
              -86.536859,
              33.024433
            ],
            [
              -86.517344,
              33.020566
            ],
            [
              -86.520061,
              33.056987
            ],
            [
              -86.506026,
              33.067748
            ],
            [
              -86.511142,
              33.088431
            ],
            [
              -86.491029,
              33.102944
            ],
            [
              -86.459926,
              33.125117
            ],
            [
              -86.482985,
              33.14828
            ],
            [
              -86.483468,
              33.15952
            ],
            [
              -86.503127,
              33.179144
            ],
            [
              -86.486144,
              33.201867
            ],
            [
              -86.460978,
              33.20293
            ],
            [
              -86.466485,
              33.224489
            ],
            [
              -86.446998,
              33.242586
            ],
            [
              -86.435527,
              33.241994
            ],
            [
              -86.428871,
              33.249529
            ],
            [
              -86.433527,
              33.282623
            ],
            [
              -86.410284,
              33.27294
            ],
            [
              -86.393341,
              33.27543
            ],
            [
              -86.357595,
              33.295554
            ],
            [
              -86.367509,
              33.335692
            ],
            [
              -86.362216,
              33.346924
            ],
            [
              -86.340937,
              33.353074
            ],
            [
              -86.352857,
              33.372537
            ],
            [
              -86.378665,
              33.390983
            ],
            [
              -86.378222,
              33.502411
            ],
            [
              -86.481939,
              33.502544
            ],
            [
              -86.481549,
              33.546073
            ],
            [
              -86.516783,
              33.545896
            ],
            [
              -86.517199,
              33.524136
            ],
            [
              -86.560624,
              33.517512
            ],
            [
              -86.60451,
              33.467053
            ],
            [
              -86.62669,
              33.466768
            ],
            [
              -86.638953,
              33.46689
            ],
            [
              -86.667634,
              33.466602
            ],
            [
              -86.67445,
              33.451738
            ],
            [
              -86.691938,
              33.451498
            ],
            [
              -86.700354,
              33.430346
            ],
            [
              -86.710613,
              33.430571
            ],
            [
              -86.717842,
              33.425187
            ],
            [
              -86.718131,
              33.416316
            ],
            [
              -86.72774,
              33.416533
            ],
            [
              -86.748807,
              33.417274
            ],
            [
              -86.752485,
              33.415855
            ],
            [
              -86.76114,
              33.388365
            ],
            [
              -86.778356,
              33.388941
            ],
            [
              -86.778644,
              33.366878
            ],
            [
              -86.830229,
              33.360766
            ],
            [
              -86.830688,
              33.332015
            ],
            [
              -86.839428,
              33.332031
            ],
            [
              -86.85539,
              33.332323
            ],
            [
              -86.882518,
              33.332687
            ],
            [
              -86.895556,
              33.316549
            ],
            [
              -86.926135,
              33.296529
            ],
            [
              -86.926167,
              33.27816
            ],
            [
              -86.969575,
              33.267474
            ],
            [
              -86.97836,
              33.256222
            ],
            [
              -86.978363,
              33.255144
            ],
            [
              -86.982744,
              33.252994
            ],
            [
              -86.982887,
              33.246007
            ],
            [
              -87.026846,
              33.246459
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "025",
      "COUNTYNS": "00161538",
      "AFFGEOID": "0500000US01025",
      "GEOID": "01025",
      "NAME": "Clarke",
      "LSAD": "06",
      "ALAND": 3207494103,
      "AWATER": 36657891,
      "County_state": "Clarke,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.161945,
              31.840803
            ],
            [
              -88.179302,
              31.821297
            ],
            [
              -88.158583,
              31.766138
            ],
            [
              -88.142624,
              31.752491
            ],
            [
              -88.104918,
              31.760798
            ],
            [
              -88.093383,
              31.737524
            ],
            [
              -88.088288,
              31.699303
            ],
            [
              -88.086946,
              31.686181
            ],
            [
              -88.078711,
              31.669618
            ],
            [
              -88.089388,
              31.658747
            ],
            [
              -88.083974,
              31.63032
            ],
            [
              -88.074261,
              31.616382
            ],
            [
              -88.079963,
              31.605772
            ],
            [
              -88.072234,
              31.5945
            ],
            [
              -88.051449,
              31.583504
            ],
            [
              -88.027693,
              31.584251
            ],
            [
              -88.023349,
              31.570917
            ],
            [
              -88.040058,
              31.572261
            ],
            [
              -88.031277,
              31.560494
            ],
            [
              -88.018627,
              31.552366
            ],
            [
              -87.981756,
              31.538901
            ],
            [
              -87.970377,
              31.530546
            ],
            [
              -87.936534,
              31.521947
            ],
            [
              -87.936376,
              31.521786
            ],
            [
              -87.925519,
              31.50385
            ],
            [
              -87.906143,
              31.491752
            ],
            [
              -87.919088,
              31.467141
            ],
            [
              -87.91545,
              31.449766
            ],
            [
              -87.935106,
              31.442398
            ],
            [
              -87.911139,
              31.424402
            ],
            [
              -87.927818,
              31.407842
            ],
            [
              -87.907342,
              31.406068
            ],
            [
              -87.912066,
              31.394528
            ],
            [
              -87.927705,
              31.396568
            ],
            [
              -87.944281,
              31.375167
            ],
            [
              -87.917291,
              31.376734
            ],
            [
              -87.918638,
              31.389633
            ],
            [
              -87.898003,
              31.38108
            ],
            [
              -87.88729,
              31.392197
            ],
            [
              -87.892814,
              31.372344
            ],
            [
              -87.888857,
              31.364023
            ],
            [
              -87.908068,
              31.323041
            ],
            [
              -87.910117,
              31.334061
            ],
            [
              -87.923226,
              31.334568
            ],
            [
              -87.924525,
              31.312907
            ],
            [
              -87.959619,
              31.316206
            ],
            [
              -87.964725,
              31.311186
            ],
            [
              -87.947507,
              31.296272
            ],
            [
              -87.957383,
              31.279647
            ],
            [
              -87.955115,
              31.267745
            ],
            [
              -87.975734,
              31.269725
            ],
            [
              -87.986228,
              31.259758
            ],
            [
              -87.969227,
              31.25836
            ],
            [
              -87.963378,
              31.250171
            ],
            [
              -87.944034,
              31.244291
            ],
            [
              -87.931333,
              31.224554
            ],
            [
              -87.938092,
              31.22068
            ],
            [
              -87.950294,
              31.204195
            ],
            [
              -87.946588,
              31.19293
            ],
            [
              -87.913637,
              31.187356
            ],
            [
              -87.889581,
              31.204349
            ],
            [
              -87.879167,
              31.197996
            ],
            [
              -87.869774,
              31.215765
            ],
            [
              -87.853499,
              31.203445
            ],
            [
              -87.860066,
              31.2354
            ],
            [
              -87.83592,
              31.231053
            ],
            [
              -87.841158,
              31.242769
            ],
            [
              -87.828313,
              31.275304
            ],
            [
              -87.801502,
              31.269812
            ],
            [
              -87.785775,
              31.282944
            ],
            [
              -87.808973,
              31.306638
            ],
            [
              -87.804896,
              31.318885
            ],
            [
              -87.795633,
              31.302737
            ],
            [
              -87.765152,
              31.297346
            ],
            [
              -87.78276,
              31.312051
            ],
            [
              -87.784378,
              31.328914
            ],
            [
              -87.754542,
              31.362327
            ],
            [
              -87.746399,
              31.333261
            ],
            [
              -87.722758,
              31.343621
            ],
            [
              -87.725837,
              31.373741
            ],
            [
              -87.706045,
              31.400957
            ],
            [
              -87.691958,
              31.405062
            ],
            [
              -87.669306,
              31.420934
            ],
            [
              -87.656694,
              31.425447
            ],
            [
              -87.639649,
              31.424768
            ],
            [
              -87.622706,
              31.410737
            ],
            [
              -87.61271,
              31.407825
            ],
            [
              -87.594827,
              31.415076
            ],
            [
              -87.588529,
              31.423919
            ],
            [
              -87.577002,
              31.432096
            ],
            [
              -87.56336,
              31.464696
            ],
            [
              -87.562787,
              31.477686
            ],
            [
              -87.605064,
              31.500162
            ],
            [
              -87.621445,
              31.514746
            ],
            [
              -87.599256,
              31.527441
            ],
            [
              -87.565413,
              31.553573
            ],
            [
              -87.566841,
              31.697115
            ],
            [
              -87.516131,
              31.697824
            ],
            [
              -87.518363,
              31.814386
            ],
            [
              -87.500953,
              31.814018
            ],
            [
              -87.50093,
              31.829251
            ],
            [
              -87.620112,
              31.827123
            ],
            [
              -87.620951,
              31.870395
            ],
            [
              -87.666849,
              31.877733
            ],
            [
              -87.667769,
              31.991355
            ],
            [
              -87.758238,
              31.990243
            ],
            [
              -87.763436,
              31.990227
            ],
            [
              -87.805297,
              31.990456
            ],
            [
              -88.032785,
              31.989944
            ],
            [
              -88.07349,
              31.990182
            ],
            [
              -88.070763,
              31.958645
            ],
            [
              -88.080913,
              31.953908
            ],
            [
              -88.096675,
              31.965019
            ],
            [
              -88.121414,
              31.950256
            ],
            [
              -88.117209,
              31.939283
            ],
            [
              -88.102291,
              31.939993
            ],
            [
              -88.088087,
              31.921931
            ],
            [
              -88.067717,
              31.936905
            ],
            [
              -88.055248,
              31.923478
            ],
            [
              -88.077033,
              31.921815
            ],
            [
              -88.088645,
              31.910775
            ],
            [
              -88.129434,
              31.904936
            ],
            [
              -88.141987,
              31.86436
            ],
            [
              -88.158366,
              31.853511
            ],
            [
              -88.161945,
              31.840803
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "091",
      "COUNTYNS": "00161572",
      "AFFGEOID": "0500000US01091",
      "GEOID": "01091",
      "NAME": "Marengo",
      "LSAD": "06",
      "ALAND": 2530140238,
      "AWATER": 14966620,
      "County_state": "Marengo,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.118211,
              32.053443
            ],
            [
              -88.113399,
              32.041176
            ],
            [
              -88.117433,
              32.01682
            ],
            [
              -88.107857,
              32.000149
            ],
            [
              -88.095071,
              31.993078
            ],
            [
              -88.090543,
              32.007052
            ],
            [
              -88.07349,
              31.990182
            ],
            [
              -88.032785,
              31.989944
            ],
            [
              -87.805297,
              31.990456
            ],
            [
              -87.763436,
              31.990227
            ],
            [
              -87.758238,
              31.990243
            ],
            [
              -87.667769,
              31.991355
            ],
            [
              -87.659785,
              32.002797
            ],
            [
              -87.622756,
              32.005482
            ],
            [
              -87.624005,
              32.132345
            ],
            [
              -87.522125,
              32.132783
            ],
            [
              -87.523405,
              32.25483
            ],
            [
              -87.514066,
              32.265993
            ],
            [
              -87.472206,
              32.264919
            ],
            [
              -87.47308,
              32.307614
            ],
            [
              -87.52429,
              32.307293
            ],
            [
              -87.524485,
              32.482028
            ],
            [
              -87.710553,
              32.480783
            ],
            [
              -87.728744,
              32.480918
            ],
            [
              -87.72876,
              32.489369
            ],
            [
              -87.728521,
              32.524532
            ],
            [
              -87.812559,
              32.52456
            ],
            [
              -87.823383,
              32.520443
            ],
            [
              -87.822317,
              32.543748
            ],
            [
              -87.843838,
              32.547205
            ],
            [
              -87.85342,
              32.532086
            ],
            [
              -87.841507,
              32.525573
            ],
            [
              -87.844575,
              32.518183
            ],
            [
              -87.85268,
              32.515203
            ],
            [
              -87.901264,
              32.523172
            ],
            [
              -87.913666,
              32.518189
            ],
            [
              -87.945065,
              32.491251
            ],
            [
              -87.954149,
              32.472972
            ],
            [
              -87.975056,
              32.457559
            ],
            [
              -87.994566,
              32.455242
            ],
            [
              -88.031623,
              32.43361
            ],
            [
              -88.042323,
              32.410271
            ],
            [
              -88.046335,
              32.377087
            ],
            [
              -88.019476,
              32.370461
            ],
            [
              -87.993862,
              32.34723
            ],
            [
              -87.970943,
              32.344347
            ],
            [
              -87.951892,
              32.328915
            ],
            [
              -87.941974,
              32.314649
            ],
            [
              -87.930661,
              32.310574
            ],
            [
              -87.931799,
              32.297266
            ],
            [
              -87.9297,
              32.292516
            ],
            [
              -87.933553,
              32.282366
            ],
            [
              -87.944645,
              32.282953
            ],
            [
              -87.936757,
              32.301664
            ],
            [
              -87.948354,
              32.30409
            ],
            [
              -87.967893,
              32.298249
            ],
            [
              -87.975667,
              32.307848
            ],
            [
              -87.991395,
              32.294615
            ],
            [
              -88.007316,
              32.293197
            ],
            [
              -88.004225,
              32.283687
            ],
            [
              -88.019145,
              32.28524
            ],
            [
              -88.020121,
              32.271769
            ],
            [
              -88.009453,
              32.261704
            ],
            [
              -88.01505,
              32.23494
            ],
            [
              -88.007523,
              32.219319
            ],
            [
              -88.012536,
              32.200826
            ],
            [
              -88.007077,
              32.185313
            ],
            [
              -88.016846,
              32.153413
            ],
            [
              -88.021369,
              32.14495
            ],
            [
              -88.035812,
              32.14501
            ],
            [
              -88.052203,
              32.10177
            ],
            [
              -88.044715,
              32.082256
            ],
            [
              -88.070615,
              32.0617
            ],
            [
              -88.084771,
              32.070824
            ],
            [
              -88.09589,
              32.069847
            ],
            [
              -88.118211,
              32.053443
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "065",
      "COUNTYNS": "00161558",
      "AFFGEOID": "0500000US01065",
      "GEOID": "01065",
      "NAME": "Hale",
      "LSAD": "06",
      "ALAND": 1667907107,
      "AWATER": 32423356,
      "County_state": "Hale,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.864892,
              32.773696
            ],
            [
              -87.870464,
              32.762442
            ],
            [
              -87.860296,
              32.756722
            ],
            [
              -87.836018,
              32.759821
            ],
            [
              -87.828097,
              32.768721
            ],
            [
              -87.808284,
              32.752372
            ],
            [
              -87.807411,
              32.733812
            ],
            [
              -87.820842,
              32.733545
            ],
            [
              -87.83077,
              32.712856
            ],
            [
              -87.814668,
              32.706629
            ],
            [
              -87.796043,
              32.715632
            ],
            [
              -87.796532,
              32.698444
            ],
            [
              -87.786571,
              32.682481
            ],
            [
              -87.793923,
              32.666128
            ],
            [
              -87.787661,
              32.654162
            ],
            [
              -87.769664,
              32.649768
            ],
            [
              -87.752319,
              32.659396
            ],
            [
              -87.743158,
              32.651743
            ],
            [
              -87.753061,
              32.633583
            ],
            [
              -87.773803,
              32.641705
            ],
            [
              -87.813401,
              32.633875
            ],
            [
              -87.798436,
              32.610447
            ],
            [
              -87.783271,
              32.605231
            ],
            [
              -87.748723,
              32.612816
            ],
            [
              -87.73692,
              32.588738
            ],
            [
              -87.756981,
              32.580509
            ],
            [
              -87.769206,
              32.568181
            ],
            [
              -87.769961,
              32.577798
            ],
            [
              -87.787701,
              32.582289
            ],
            [
              -87.788904,
              32.560342
            ],
            [
              -87.800959,
              32.558891
            ],
            [
              -87.797775,
              32.543709
            ],
            [
              -87.812559,
              32.52456
            ],
            [
              -87.728521,
              32.524532
            ],
            [
              -87.72876,
              32.489369
            ],
            [
              -87.728744,
              32.480918
            ],
            [
              -87.710553,
              32.480783
            ],
            [
              -87.524485,
              32.482028
            ],
            [
              -87.525198,
              32.655713
            ],
            [
              -87.473915,
              32.655867
            ],
            [
              -87.473807,
              32.728189
            ],
            [
              -87.472194,
              32.743451
            ],
            [
              -87.471665,
              32.830622
            ],
            [
              -87.421387,
              32.830829
            ],
            [
              -87.4212,
              32.874508
            ],
            [
              -87.421936,
              33.003379
            ],
            [
              -87.615954,
              33.004638
            ],
            [
              -87.634507,
              33.005122
            ],
            [
              -87.715709,
              33.006824
            ],
            [
              -87.707381,
              32.99756
            ],
            [
              -87.712491,
              32.986009
            ],
            [
              -87.727863,
              32.985813
            ],
            [
              -87.735327,
              32.974573
            ],
            [
              -87.750357,
              32.978068
            ],
            [
              -87.738707,
              32.958903
            ],
            [
              -87.747716,
              32.949049
            ],
            [
              -87.729941,
              32.949258
            ],
            [
              -87.740116,
              32.936847
            ],
            [
              -87.774735,
              32.927424
            ],
            [
              -87.775016,
              32.920015
            ],
            [
              -87.759961,
              32.907099
            ],
            [
              -87.782929,
              32.895375
            ],
            [
              -87.804218,
              32.897523
            ],
            [
              -87.812682,
              32.877606
            ],
            [
              -87.822125,
              32.880522
            ],
            [
              -87.820144,
              32.864938
            ],
            [
              -87.832742,
              32.863729
            ],
            [
              -87.831749,
              32.840505
            ],
            [
              -87.819865,
              32.836393
            ],
            [
              -87.816325,
              32.819437
            ],
            [
              -87.813178,
              32.810176
            ],
            [
              -87.821633,
              32.794455
            ],
            [
              -87.853921,
              32.772187
            ],
            [
              -87.864892,
              32.773696
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "075",
      "COUNTYNS": "00161563",
      "AFFGEOID": "0500000US01075",
      "GEOID": "01075",
      "NAME": "Lamar",
      "LSAD": "06",
      "ALAND": 1566544636,
      "AWATER": 1600679,
      "County_state": "Lamar,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.27005,
              33.570819
            ],
            [
              -88.274619,
              33.534008
            ],
            [
              -87.946519,
              33.524065
            ],
            [
              -87.946658,
              33.6413
            ],
            [
              -87.951785,
              33.91993
            ],
            [
              -87.951929,
              34.022202
            ],
            [
              -87.98743,
              34.022903
            ],
            [
              -87.98693,
              34.052102
            ],
            [
              -88.207229,
              34.058333
            ],
            [
              -88.210741,
              34.029199
            ],
            [
              -88.2132700197265,
              34.0104455295678
            ],
            [
              -88.226428,
              33.912875
            ],
            [
              -88.226517,
              33.911665
            ],
            [
              -88.226517,
              33.911551
            ],
            [
              -88.22924906832169,
              33.8919629072572
            ],
            [
              -88.235492,
              33.847203
            ],
            [
              -88.240054,
              33.810879
            ],
            [
              -88.243025,
              33.79568
            ],
            [
              -88.244142,
              33.781673
            ],
            [
              -88.2489373465951,
              33.744973677721305
            ],
            [
              -88.252257,
              33.719568
            ],
            [
              -88.254445,
              33.698779
            ],
            [
              -88.254622,
              33.69578
            ],
            [
              -88.256131,
              33.68286
            ],
            [
              -88.256343,
              33.682053
            ],
            [
              -88.262002140265,
              33.6354380745981
            ],
            [
              -88.267005,
              33.594229
            ],
            [
              -88.267148,
              33.591989
            ],
            [
              -88.26816,
              33.58504
            ],
            [
              -88.269076,
              33.576929
            ],
            [
              -88.269532,
              33.572894
            ],
            [
              -88.27005,
              33.570819
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "107",
      "COUNTYNS": "00161580",
      "AFFGEOID": "0500000US01107",
      "GEOID": "01107",
      "NAME": "Pickens",
      "LSAD": "06",
      "ALAND": 2282893859,
      "AWATER": 22521211,
      "County_state": "Pickens,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.3310076496119,
              33.072489727510394
            ],
            [
              -88.340432,
              32.991199
            ],
            [
              -88.171852,
              32.99586
            ],
            [
              -88.171935,
              33.003422
            ],
            [
              -88.166101,
              33.013615
            ],
            [
              -88.142928,
              33.02536
            ],
            [
              -88.147654,
              33.03842
            ],
            [
              -88.135072,
              33.03649
            ],
            [
              -88.115209,
              33.041804
            ],
            [
              -88.086075,
              33.036247
            ],
            [
              -88.064997,
              33.051344
            ],
            [
              -88.043891,
              33.032719
            ],
            [
              -88.026971,
              33.063602
            ],
            [
              -87.981493,
              33.080615
            ],
            [
              -87.968838,
              33.077641
            ],
            [
              -87.938309,
              33.114666
            ],
            [
              -87.93368,
              33.1132
            ],
            [
              -87.876792,
              33.13919
            ],
            [
              -87.837521,
              33.153637
            ],
            [
              -87.838264,
              33.361238
            ],
            [
              -87.840683,
              33.524839
            ],
            [
              -87.946519,
              33.524065
            ],
            [
              -88.274619,
              33.534008
            ],
            [
              -88.276805,
              33.516463
            ],
            [
              -88.277421,
              33.512436
            ],
            [
              -88.2910782508144,
              33.3990548370265
            ],
            [
              -88.30274373083179,
              33.3022091607627
            ],
            [
              -88.30441698708249,
              33.2883179507206
            ],
            [
              -88.30517442861469,
              33.2820297450658
            ],
            [
              -88.30792779585659,
              33.259171559021006
            ],
            [
              -88.312535,
              33.220923
            ],
            [
              -88.315235,
              33.203323
            ],
            [
              -88.317135,
              33.184123
            ],
            [
              -88.330934,
              33.073125
            ],
            [
              -88.3310076496119,
              33.072489727510394
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "037",
      "COUNTYNS": "00161544",
      "AFFGEOID": "0500000US01037",
      "GEOID": "01037",
      "NAME": "Coosa",
      "LSAD": "06",
      "ALAND": 1685850816,
      "AWATER": 39987274,
      "County_state": "Coosa,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.517344,
              33.020566
            ],
            [
              -86.522283,
              33.004395
            ],
            [
              -86.510622,
              32.98538
            ],
            [
              -86.518117,
              32.971181
            ],
            [
              -86.515959,
              32.929361
            ],
            [
              -86.490511,
              32.905619
            ],
            [
              -86.480222,
              32.87435
            ],
            [
              -86.465672,
              32.869141
            ],
            [
              -86.451524,
              32.863692
            ],
            [
              -86.447837,
              32.850976
            ],
            [
              -86.460691,
              32.833464
            ],
            [
              -86.457015,
              32.813899
            ],
            [
              -86.427238,
              32.791707
            ],
            [
              -86.413402,
              32.788182
            ],
            [
              -86.396737,
              32.762946
            ],
            [
              -86.374974,
              32.75358
            ],
            [
              -86.336768,
              32.753783
            ],
            [
              -86.336694,
              32.76813
            ],
            [
              -86.319163,
              32.767837
            ],
            [
              -86.31948,
              32.753698
            ],
            [
              -86.007187,
              32.754984
            ],
            [
              -86.007066,
              32.796431
            ],
            [
              -86.00005,
              32.801645
            ],
            [
              -86.007565,
              32.827773
            ],
            [
              -86.007847,
              32.887614
            ],
            [
              -86.008342,
              32.997798
            ],
            [
              -86.008498,
              33.020626
            ],
            [
              -86.00917,
              33.09026
            ],
            [
              -86.009313,
              33.104642
            ],
            [
              -86.079858,
              33.104695
            ],
            [
              -86.086238,
              33.104702
            ],
            [
              -86.17437,
              33.104394
            ],
            [
              -86.491029,
              33.102944
            ],
            [
              -86.511142,
              33.088431
            ],
            [
              -86.506026,
              33.067748
            ],
            [
              -86.520061,
              33.056987
            ],
            [
              -86.517344,
              33.020566
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "127",
      "COUNTYNS": "00161589",
      "AFFGEOID": "0500000US01127",
      "GEOID": "01127",
      "NAME": "Walker",
      "LSAD": "06",
      "ALAND": 2048686077,
      "AWATER": 36754700,
      "County_state": "Walker,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.636118,
              34.002203
            ],
            [
              -87.635932,
              33.915251
            ],
            [
              -87.63604,
              33.871999
            ],
            [
              -87.531602,
              33.867618
            ],
            [
              -87.528338,
              33.692049
            ],
            [
              -87.423843,
              33.689112
            ],
            [
              -87.423701,
              33.602096
            ],
            [
              -87.371118,
              33.601697
            ],
            [
              -87.37104,
              33.587065
            ],
            [
              -87.318532,
              33.587393
            ],
            [
              -87.318237,
              33.514166
            ],
            [
              -87.266923,
              33.512929
            ],
            [
              -87.275532,
              33.522056
            ],
            [
              -87.2687,
              33.530583
            ],
            [
              -87.250469,
              33.514198
            ],
            [
              -87.232599,
              33.539383
            ],
            [
              -87.210029,
              33.538732
            ],
            [
              -87.185794,
              33.555882
            ],
            [
              -87.196914,
              33.5624
            ],
            [
              -87.204577,
              33.578835
            ],
            [
              -87.196924,
              33.599339
            ],
            [
              -87.179555,
              33.599275
            ],
            [
              -87.17943,
              33.613794
            ],
            [
              -87.144413,
              33.613406
            ],
            [
              -87.144473,
              33.628198
            ],
            [
              -87.092733,
              33.62785
            ],
            [
              -87.0927,
              33.656873
            ],
            [
              -87.057757,
              33.656363
            ],
            [
              -87.057252,
              33.685473
            ],
            [
              -87.039818,
              33.687461
            ],
            [
              -87.040638,
              33.728969
            ],
            [
              -87.031582,
              33.728979
            ],
            [
              -87.031661,
              33.73985
            ],
            [
              -87.023286,
              33.772727
            ],
            [
              -87.005889,
              33.772572
            ],
            [
              -87.005965,
              33.787237
            ],
            [
              -86.970928,
              33.786462
            ],
            [
              -86.95577,
              33.800828
            ],
            [
              -86.953664,
              33.815297
            ],
            [
              -86.954305,
              33.844862
            ],
            [
              -86.963358,
              33.858221
            ],
            [
              -86.993987,
              33.860932
            ],
            [
              -87.006862,
              33.866179
            ],
            [
              -87.007077,
              33.888927
            ],
            [
              -87.091836,
              33.890093
            ],
            [
              -87.10081,
              33.898358
            ],
            [
              -87.081996,
              33.906401
            ],
            [
              -87.085584,
              33.919164
            ],
            [
              -87.104574,
              33.935216
            ],
            [
              -87.112377,
              33.959487
            ],
            [
              -87.13408,
              33.965093
            ],
            [
              -87.151036,
              33.993225
            ],
            [
              -87.168907,
              34.005104
            ],
            [
              -87.189908,
              34.002804
            ],
            [
              -87.200031,
              33.984882
            ],
            [
              -87.216735,
              33.993907
            ],
            [
              -87.266302,
              33.994559
            ],
            [
              -87.500021,
              33.996627
            ],
            [
              -87.636118,
              34.002203
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "129",
      "COUNTYNS": "00161590",
      "AFFGEOID": "0500000US01129",
      "GEOID": "01129",
      "NAME": "Washington",
      "LSAD": "06",
      "ALAND": 2797720370,
      "AWATER": 21652733,
      "County_state": "Washington,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.46442697928089,
              31.697951902948798
            ],
            [
              -88.464425,
              31.697881
            ],
            [
              -88.459722,
              31.624002
            ],
            [
              -88.459478,
              31.621652
            ],
            [
              -88.453013,
              31.500164
            ],
            [
              -88.451575,
              31.481533
            ],
            [
              -88.451045,
              31.459448
            ],
            [
              -88.4495695963996,
              31.4358347375977
            ],
            [
              -88.44866,
              31.421277
            ],
            [
              -88.448686,
              31.420888
            ],
            [
              -88.445209,
              31.355969
            ],
            [
              -88.445182,
              31.355855
            ],
            [
              -88.43970863275949,
              31.267623670323697
            ],
            [
              -88.43878,
              31.252654
            ],
            [
              -88.43898,
              31.246896
            ],
            [
              -88.438211,
              31.231252
            ],
            [
              -88.438104,
              31.23006
            ],
            [
              -88.432007,
              31.114298
            ],
            [
              -88.329927,
              31.114357
            ],
            [
              -88.329782,
              31.143821
            ],
            [
              -88.279194,
              31.143679
            ],
            [
              -88.19693,
              31.144044
            ],
            [
              -88.026936,
              31.144244
            ],
            [
              -88.020853,
              31.145814
            ],
            [
              -88.010111,
              31.155078
            ],
            [
              -87.98882,
              31.173306
            ],
            [
              -87.972869,
              31.162694
            ],
            [
              -87.941318,
              31.16305
            ],
            [
              -87.946588,
              31.19293
            ],
            [
              -87.950294,
              31.204195
            ],
            [
              -87.938092,
              31.22068
            ],
            [
              -87.931333,
              31.224554
            ],
            [
              -87.944034,
              31.244291
            ],
            [
              -87.963378,
              31.250171
            ],
            [
              -87.969227,
              31.25836
            ],
            [
              -87.986228,
              31.259758
            ],
            [
              -87.975734,
              31.269725
            ],
            [
              -87.955115,
              31.267745
            ],
            [
              -87.957383,
              31.279647
            ],
            [
              -87.947507,
              31.296272
            ],
            [
              -87.964725,
              31.311186
            ],
            [
              -87.959619,
              31.316206
            ],
            [
              -87.924525,
              31.312907
            ],
            [
              -87.923226,
              31.334568
            ],
            [
              -87.910117,
              31.334061
            ],
            [
              -87.908068,
              31.323041
            ],
            [
              -87.888857,
              31.364023
            ],
            [
              -87.892814,
              31.372344
            ],
            [
              -87.88729,
              31.392197
            ],
            [
              -87.898003,
              31.38108
            ],
            [
              -87.918638,
              31.389633
            ],
            [
              -87.917291,
              31.376734
            ],
            [
              -87.944281,
              31.375167
            ],
            [
              -87.927705,
              31.396568
            ],
            [
              -87.912066,
              31.394528
            ],
            [
              -87.907342,
              31.406068
            ],
            [
              -87.927818,
              31.407842
            ],
            [
              -87.911139,
              31.424402
            ],
            [
              -87.935106,
              31.442398
            ],
            [
              -87.91545,
              31.449766
            ],
            [
              -87.919088,
              31.467141
            ],
            [
              -87.906143,
              31.491752
            ],
            [
              -87.925519,
              31.50385
            ],
            [
              -87.936376,
              31.521786
            ],
            [
              -87.936534,
              31.521947
            ],
            [
              -87.970377,
              31.530546
            ],
            [
              -87.981756,
              31.538901
            ],
            [
              -88.018627,
              31.552366
            ],
            [
              -88.031277,
              31.560494
            ],
            [
              -88.040058,
              31.572261
            ],
            [
              -88.023349,
              31.570917
            ],
            [
              -88.027693,
              31.584251
            ],
            [
              -88.051449,
              31.583504
            ],
            [
              -88.072234,
              31.5945
            ],
            [
              -88.079963,
              31.605772
            ],
            [
              -88.074261,
              31.616382
            ],
            [
              -88.083974,
              31.63032
            ],
            [
              -88.089388,
              31.658747
            ],
            [
              -88.078711,
              31.669618
            ],
            [
              -88.086946,
              31.686181
            ],
            [
              -88.088288,
              31.699303
            ],
            [
              -88.46442697928089,
              31.697951902948798
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "041",
      "COUNTYNS": "00161546",
      "AFFGEOID": "0500000US01041",
      "GEOID": "01041",
      "NAME": "Crenshaw",
      "LSAD": "06",
      "ALAND": 1576952826,
      "AWATER": 5388561,
      "County_state": "Crenshaw,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.499533,
              31.655247
            ],
            [
              -86.499213,
              31.525331
            ],
            [
              -86.415383,
              31.525572
            ],
            [
              -86.39994,
              31.527128
            ],
            [
              -86.395686,
              31.450684
            ],
            [
              -86.3703,
              31.473689
            ],
            [
              -86.364659,
              31.486732
            ],
            [
              -86.340037,
              31.486919
            ],
            [
              -86.3306,
              31.500951
            ],
            [
              -86.319641,
              31.505043
            ],
            [
              -86.298887,
              31.528497
            ],
            [
              -86.280155,
              31.52851
            ],
            [
              -86.277031,
              31.455602
            ],
            [
              -86.260189,
              31.455716
            ],
            [
              -86.261876,
              31.441168
            ],
            [
              -86.193951,
              31.440072
            ],
            [
              -86.194784,
              31.529949
            ],
            [
              -86.156561,
              31.530203
            ],
            [
              -86.150314,
              31.537601
            ],
            [
              -86.14395,
              31.537675
            ],
            [
              -86.145895,
              31.617741
            ],
            [
              -86.179672,
              31.616414
            ],
            [
              -86.180057,
              31.625109
            ],
            [
              -86.158882,
              31.64754
            ],
            [
              -86.160056,
              31.660234
            ],
            [
              -86.147126,
              31.662997
            ],
            [
              -86.148339,
              31.790951
            ],
            [
              -86.199378,
              31.79045
            ],
            [
              -86.199408,
              31.807861
            ],
            [
              -86.182949,
              31.83239
            ],
            [
              -86.182307,
              31.870287
            ],
            [
              -86.173091,
              31.893143
            ],
            [
              -86.174969,
              31.930597
            ],
            [
              -86.191379,
              31.966453
            ],
            [
              -86.302217,
              31.965065
            ],
            [
              -86.303513,
              32.050309
            ],
            [
              -86.307039,
              32.051696
            ],
            [
              -86.406276,
              32.050731
            ],
            [
              -86.405005,
              31.963775
            ],
            [
              -86.448198,
              31.964629
            ],
            [
              -86.446619,
              31.846429
            ],
            [
              -86.438363,
              31.84284
            ],
            [
              -86.446411,
              31.831661
            ],
            [
              -86.445978,
              31.70112
            ],
            [
              -86.448635,
              31.655617
            ],
            [
              -86.499533,
              31.655247
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "045",
      "COUNTYNS": "00161548",
      "AFFGEOID": "0500000US01045",
      "GEOID": "01045",
      "NAME": "Dale",
      "LSAD": "06",
      "ALAND": 1453311031,
      "AWATER": 4016457,
      "County_state": "Dale,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.7901,
              31.336276
            ],
            [
              -85.791402,
              31.196349
            ],
            [
              -85.710333,
              31.195173
            ],
            [
              -85.692365,
              31.21191
            ],
            [
              -85.689092,
              31.23609
            ],
            [
              -85.67119,
              31.249695
            ],
            [
              -85.662893,
              31.269034
            ],
            [
              -85.635307,
              31.275555
            ],
            [
              -85.602956,
              31.272378
            ],
            [
              -85.585534,
              31.264179
            ],
            [
              -85.546113,
              31.253868
            ],
            [
              -85.485854,
              31.246096
            ],
            [
              -85.485442,
              31.287095
            ],
            [
              -85.468042,
              31.286783
            ],
            [
              -85.447416,
              31.286594
            ],
            [
              -85.418304,
              31.286271
            ],
            [
              -85.417417,
              31.293344
            ],
            [
              -85.417434,
              31.314973
            ],
            [
              -85.41671,
              31.363351
            ],
            [
              -85.418035,
              31.44077
            ],
            [
              -85.415774,
              31.490413
            ],
            [
              -85.416437,
              31.619466
            ],
            [
              -85.499956,
              31.620267
            ],
            [
              -85.748251,
              31.618048
            ],
            [
              -85.789142,
              31.617964
            ],
            [
              -85.789785,
              31.356562
            ],
            [
              -85.7901,
              31.336276
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "081",
      "COUNTYNS": "00161566",
      "AFFGEOID": "0500000US01081",
      "GEOID": "01081",
      "NAME": "Lee",
      "LSAD": "06",
      "ALAND": 1573550101,
      "AWATER": 21491164,
      "County_state": "Lee,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.696755,
              32.697429
            ],
            [
              -85.695854,
              32.595933
            ],
            [
              -85.695654,
              32.582034
            ],
            [
              -85.661253,
              32.582334
            ],
            [
              -85.661064,
              32.568639
            ],
            [
              -85.661053,
              32.567935
            ],
            [
              -85.610102,
              32.547049
            ],
            [
              -85.498598,
              32.500684
            ],
            [
              -85.489348,
              32.496937
            ],
            [
              -85.467946,
              32.497062
            ],
            [
              -85.438575,
              32.49709
            ],
            [
              -85.437142,
              32.478681
            ],
            [
              -85.434749,
              32.42969
            ],
            [
              -85.434045,
              32.40984
            ],
            [
              -85.356843,
              32.410487
            ],
            [
              -85.330143,
              32.410842
            ],
            [
              -85.333812,
              32.468161
            ],
            [
              -85.333843,
              32.468639
            ],
            [
              -85.269466,
              32.469625
            ],
            [
              -85.224747,
              32.470283
            ],
            [
              -85.206457,
              32.470503
            ],
            [
              -85.059294,
              32.472909
            ],
            [
              -85.060814,
              32.507713
            ],
            [
              -85.056516,
              32.509277
            ],
            [
              -85.00374006827789,
              32.510102725763296
            ],
            [
              -85.0071,
              32.523868
            ],
            [
              -85.022509,
              32.542923
            ],
            [
              -85.03429201871789,
              32.5529347949659
            ],
            [
              -85.069848,
              32.583146
            ],
            [
              -85.08128389549479,
              32.607840500985695
            ],
            [
              -85.088319,
              32.623032
            ],
            [
              -85.105337,
              32.644835
            ],
            [
              -85.088533,
              32.657958
            ],
            [
              -85.093536,
              32.669734
            ],
            [
              -85.114737,
              32.685634
            ],
            [
              -85.122738,
              32.715727
            ],
            [
              -85.11425,
              32.730447
            ],
            [
              -85.11855,
              32.740817
            ],
            [
              -85.1300975128125,
              32.7517487666425
            ],
            [
              -85.135038,
              32.74653
            ],
            [
              -85.284843,
              32.745131
            ],
            [
              -85.293221,
              32.730731
            ],
            [
              -85.356413,
              32.730497
            ],
            [
              -85.389074,
              32.730435
            ],
            [
              -85.396688,
              32.730483
            ],
            [
              -85.413447,
              32.744729
            ],
            [
              -85.430548,
              32.74503
            ],
            [
              -85.430549,
              32.73033
            ],
            [
              -85.593151,
              32.72853
            ],
            [
              -85.609994,
              32.727949
            ],
            [
              -85.610253,
              32.713929
            ],
            [
              -85.678249,
              32.712567
            ],
            [
              -85.679654,
              32.69793
            ],
            [
              -85.696755,
              32.697429
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "001",
      "COUNTYNS": "00161526",
      "AFFGEOID": "0500000US01001",
      "GEOID": "01001",
      "NAME": "Autauga",
      "LSAD": "06",
      "ALAND": 1539614693,
      "AWATER": 25744269,
      "County_state": "Autauga,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.917595,
              32.664169
            ],
            [
              -86.921145,
              32.65623
            ],
            [
              -86.910859,
              32.629088
            ],
            [
              -86.911962,
              32.612192
            ],
            [
              -86.905677,
              32.601002
            ],
            [
              -86.898304,
              32.567687
            ],
            [
              -86.906403,
              32.536712
            ],
            [
              -86.890581,
              32.502974
            ],
            [
              -86.860056,
              32.450861
            ],
            [
              -86.86709,
              32.439188
            ],
            [
              -86.849898,
              32.438325
            ],
            [
              -86.824394,
              32.424725
            ],
            [
              -86.845448,
              32.415416
            ],
            [
              -86.843758,
              32.400416
            ],
            [
              -86.827244,
              32.378816
            ],
            [
              -86.815399,
              32.370821
            ],
            [
              -86.807378,
              32.354356
            ],
            [
              -86.814912,
              32.340803
            ],
            [
              -86.820921,
              32.33324
            ],
            [
              -86.816107,
              32.30997
            ],
            [
              -86.798268,
              32.308632
            ],
            [
              -86.773163,
              32.340728
            ],
            [
              -86.780447,
              32.3686
            ],
            [
              -86.778365,
              32.394601
            ],
            [
              -86.749981,
              32.389105
            ],
            [
              -86.727181,
              32.404497
            ],
            [
              -86.717897,
              32.402814
            ],
            [
              -86.719028,
              32.372059
            ],
            [
              -86.711337,
              32.360767
            ],
            [
              -86.683537,
              32.353395
            ],
            [
              -86.655597,
              32.376147
            ],
            [
              -86.653419,
              32.397247
            ],
            [
              -86.618,
              32.405717
            ],
            [
              -86.613453,
              32.398584
            ],
            [
              -86.614841,
              32.374266
            ],
            [
              -86.595335,
              32.361345
            ],
            [
              -86.581873,
              32.375019
            ],
            [
              -86.570551,
              32.375006
            ],
            [
              -86.542537,
              32.363517
            ],
            [
              -86.532531,
              32.338775
            ],
            [
              -86.496774,
              32.344437
            ],
            [
              -86.492451,
              32.36348
            ],
            [
              -86.476703,
              32.364418
            ],
            [
              -86.462497,
              32.378135
            ],
            [
              -86.456273,
              32.405837
            ],
            [
              -86.444721,
              32.399841
            ],
            [
              -86.411172,
              32.409937
            ],
            [
              -86.411972,
              32.491417
            ],
            [
              -86.412377,
              32.556969
            ],
            [
              -86.412826,
              32.635879
            ],
            [
              -86.413116,
              32.707386
            ],
            [
              -86.476875,
              32.707687
            ],
            [
              -86.524434,
              32.707058
            ],
            [
              -86.714219,
              32.705694
            ],
            [
              -86.71339,
              32.661732
            ],
            [
              -86.771532,
              32.660717
            ],
            [
              -86.816574,
              32.660117
            ],
            [
              -86.917595,
              32.664169
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "005",
      "COUNTYNS": "00161528",
      "AFFGEOID": "0500000US01005",
      "GEOID": "01005",
      "NAME": "Barbour",
      "LSAD": "06",
      "ALAND": 2292144656,
      "AWATER": 50538698,
      "County_state": "Barbour,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.74174,
              31.619403
            ],
            [
              -85.748251,
              31.618048
            ],
            [
              -85.499956,
              31.620267
            ],
            [
              -85.416437,
              31.619466
            ],
            [
              -85.416038,
              31.706664
            ],
            [
              -85.216076,
              31.702409
            ],
            [
              -85.209513,
              31.710474
            ],
            [
              -85.206034,
              31.741813
            ],
            [
              -85.188314,
              31.746441
            ],
            [
              -85.148434,
              31.773468
            ],
            [
              -85.12513385383839,
              31.763011743932
            ],
            [
              -85.12633,
              31.768863
            ],
            [
              -85.1274996270141,
              31.7802526552609
            ],
            [
              -85.131331,
              31.817562
            ],
            [
              -85.141831,
              31.839261
            ],
            [
              -85.128431,
              31.87756
            ],
            [
              -85.114031,
              31.89336
            ],
            [
              -85.07893,
              31.940159
            ],
            [
              -85.067829,
              31.967358
            ],
            [
              -85.060929,
              31.9900776803197
            ],
            [
              -85.053815,
              32.013502
            ],
            [
              -85.04934254607609,
              32.0624439732037
            ],
            [
              -85.185067,
              32.061708
            ],
            [
              -85.191415,
              32.064594
            ],
            [
              -85.195747,
              32.081843
            ],
            [
              -85.22912,
              32.102707
            ],
            [
              -85.236261,
              32.123665
            ],
            [
              -85.259778,
              32.13854
            ],
            [
              -85.257834,
              32.147931
            ],
            [
              -85.410241,
              32.146651
            ],
            [
              -85.427442,
              32.13935
            ],
            [
              -85.430313,
              32.051962
            ],
            [
              -85.428476,
              32.014951
            ],
            [
              -85.512643,
              32.014055
            ],
            [
              -85.51236,
              31.999694
            ],
            [
              -85.580544,
              31.996955
            ],
            [
              -85.592551,
              31.994259
            ],
            [
              -85.604769,
              31.960852
            ],
            [
              -85.648767,
              31.911615
            ],
            [
              -85.657668,
              31.880275
            ],
            [
              -85.677591,
              31.85678
            ],
            [
              -85.668309,
              31.834486
            ],
            [
              -85.673976,
              31.806603
            ],
            [
              -85.662692,
              31.783319
            ],
            [
              -85.674117,
              31.758626
            ],
            [
              -85.695602,
              31.747834
            ],
            [
              -85.705108,
              31.731715
            ],
            [
              -85.706122,
              31.707245
            ],
            [
              -85.717911,
              31.698032
            ],
            [
              -85.716146,
              31.660505
            ],
            [
              -85.729832,
              31.632373
            ],
            [
              -85.74174,
              31.619403
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "097",
      "COUNTYNS": "00161575",
      "AFFGEOID": "0500000US01097",
      "GEOID": "01097",
      "NAME": "Mobile",
      "LSAD": "06",
      "ALAND": 3184127436,
      "AWATER": 1073844850,
      "County_state": "Mobile,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.043743,
              30.517423
            ],
            [
              -88.05325,
              30.506161
            ],
            [
              -88.025917,
              30.490801
            ],
            [
              -88.024729,
              30.503089
            ],
            [
              -88.024729,
              30.516399
            ],
            [
              -88.036613,
              30.520494
            ],
            [
              -88.043743,
              30.517423
            ]
          ]
        ],
        [
          [
            [
              -88.267271,
              30.353806
            ],
            [
              -88.273213,
              30.333294
            ],
            [
              -88.257764,
              30.318933
            ],
            [
              -88.248256,
              30.326114
            ],
            [
              -88.248256,
              30.346627
            ],
            [
              -88.250633,
              30.367136
            ],
            [
              -88.261329,
              30.372263
            ],
            [
              -88.267271,
              30.353806
            ]
          ]
        ],
        [
          [
            [
              -88.330758,
              30.235026
            ],
            [
              -88.324659,
              30.22317
            ],
            [
              -88.305346,
              30.221413
            ],
            [
              -88.279426,
              30.222291
            ],
            [
              -88.207765,
              30.236783
            ],
            [
              -88.166597,
              30.241173
            ],
            [
              -88.144743,
              30.238978
            ],
            [
              -88.107331,
              30.225076
            ],
            [
              -88.092903,
              30.240734
            ],
            [
              -88.078786,
              30.245039
            ],
            [
              -88.075856,
              30.246139
            ],
            [
              -88.074854,
              30.249119
            ],
            [
              -88.086812,
              30.259864
            ],
            [
              -88.124658,
              30.28364
            ],
            [
              -88.124722,
              30.273541
            ],
            [
              -88.130631,
              30.262125
            ],
            [
              -88.143727,
              30.260491
            ],
            [
              -88.158974,
              30.257857
            ],
            [
              -88.177779,
              30.257857
            ],
            [
              -88.225553,
              30.250833
            ],
            [
              -88.261638,
              30.244686
            ],
            [
              -88.288574,
              30.238539
            ],
            [
              -88.30433,
              30.237661
            ],
            [
              -88.330758,
              30.235026
            ]
          ]
        ],
        [
          [
            [
              -88.432007,
              31.114298
            ],
            [
              -88.425807,
              31.001123
            ],
            [
              -88.425729,
              31.000183
            ],
            [
              -88.4256361537673,
              30.9983011286609
            ],
            [
              -88.4220230007208,
              30.925067256218597
            ],
            [
              -88.419562,
              30.875186
            ],
            [
              -88.41863,
              30.866528
            ],
            [
              -88.41245060127909,
              30.7355976173844
            ],
            [
              -88.41227,
              30.731771
            ],
            [
              -88.412209,
              30.730395
            ],
            [
              -88.41155,
              30.712956
            ],
            [
              -88.411339,
              30.706334
            ],
            [
              -88.409571,
              30.668731
            ],
            [
              -88.40807,
              30.63697
            ],
            [
              -88.407462,
              30.631653
            ],
            [
              -88.407484,
              30.622736
            ],
            [
              -88.4060104917735,
              30.589761
            ],
            [
              -88.404676680269,
              30.559912212208598
            ],
            [
              -88.404013,
              30.54506
            ],
            [
              -88.403931,
              30.543359
            ],
            [
              -88.403547,
              30.5331
            ],
            [
              -88.402283,
              30.510852
            ],
            [
              -88.395023,
              30.369425
            ],
            [
              -88.374671,
              30.385608
            ],
            [
              -88.364022,
              30.388006
            ],
            [
              -88.341345,
              30.38947
            ],
            [
              -88.332277,
              30.38844
            ],
            [
              -88.319599,
              30.380334
            ],
            [
              -88.316525,
              30.369985
            ],
            [
              -88.311608,
              30.368908
            ],
            [
              -88.290649,
              30.370741
            ],
            [
              -88.282635,
              30.382876
            ],
            [
              -88.260695,
              30.382381
            ],
            [
              -88.204495,
              30.362102
            ],
            [
              -88.200065,
              30.362378
            ],
            [
              -88.188527,
              30.348124
            ],
            [
              -88.188532,
              30.345053
            ],
            [
              -88.196353,
              30.343586
            ],
            [
              -88.198361,
              30.338819
            ],
            [
              -88.195664,
              30.321242
            ],
            [
              -88.191542,
              30.317002
            ],
            [
              -88.171967,
              30.324679
            ],
            [
              -88.155775,
              30.327184
            ],
            [
              -88.136173,
              30.320729
            ],
            [
              -88.128052,
              30.338509
            ],
            [
              -88.124611,
              30.341623
            ],
            [
              -88.115432,
              30.35657
            ],
            [
              -88.107274,
              30.377246
            ],
            [
              -88.10407,
              30.4273
            ],
            [
              -88.106437,
              30.452738
            ],
            [
              -88.100646,
              30.46122
            ],
            [
              -88.096867,
              30.471053
            ],
            [
              -88.102988,
              30.493029
            ],
            [
              -88.103768,
              30.500903
            ],
            [
              -88.100874,
              30.50975
            ],
            [
              -88.090734,
              30.52357
            ],
            [
              -88.082792,
              30.528713
            ],
            [
              -88.081617,
              30.546317
            ],
            [
              -88.085493,
              30.563258
            ],
            [
              -88.0840368994008,
              30.565406624517898
            ],
            [
              -88.074898,
              30.578892
            ],
            [
              -88.064898,
              30.588292
            ],
            [
              -88.053998,
              30.612491
            ],
            [
              -88.059598,
              30.619091
            ],
            [
              -88.061998,
              30.644891
            ],
            [
              -88.044339,
              30.652568
            ],
            [
              -88.034588,
              30.653715
            ],
            [
              -88.026706,
              30.66149
            ],
            [
              -88.022076,
              30.673873
            ],
            [
              -88.012444,
              30.68319
            ],
            [
              -88.00992354047371,
              30.6842895878269
            ],
            [
              -88.008396,
              30.684956
            ],
            [
              -88.000997,
              30.698289
            ],
            [
              -88.026319,
              30.753358
            ],
            [
              -88.016698,
              30.781087
            ],
            [
              -87.990601,
              30.794155
            ],
            [
              -87.987065,
              30.811445
            ],
            [
              -87.944546,
              30.827046
            ],
            [
              -87.954476,
              30.849408
            ],
            [
              -87.980971,
              30.86187
            ],
            [
              -87.981261,
              30.886895
            ],
            [
              -87.954492,
              30.918767
            ],
            [
              -87.952311,
              30.924882
            ],
            [
              -87.92429,
              30.935405
            ],
            [
              -87.943552,
              30.961773
            ],
            [
              -87.965187,
              30.967784
            ],
            [
              -87.956619,
              30.989778
            ],
            [
              -87.9648,
              31.008187
            ],
            [
              -87.950506,
              31.022089
            ],
            [
              -87.957352,
              31.029368
            ],
            [
              -87.941152,
              31.048161
            ],
            [
              -87.944787,
              31.066278
            ],
            [
              -87.963823,
              31.068287
            ],
            [
              -87.976222,
              31.089535
            ],
            [
              -87.946577,
              31.121685
            ],
            [
              -87.940121,
              31.147237
            ],
            [
              -87.966284,
              31.148261
            ],
            [
              -87.972869,
              31.162694
            ],
            [
              -87.98882,
              31.173306
            ],
            [
              -88.010111,
              31.155078
            ],
            [
              -88.020853,
              31.145814
            ],
            [
              -88.026936,
              31.144244
            ],
            [
              -88.19693,
              31.144044
            ],
            [
              -88.279194,
              31.143679
            ],
            [
              -88.329782,
              31.143821
            ],
            [
              -88.329927,
              31.114357
            ],
            [
              -88.432007,
              31.114298
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "007",
      "COUNTYNS": "00161529",
      "AFFGEOID": "0500000US01007",
      "GEOID": "01007",
      "NAME": "Bibb",
      "LSAD": "06",
      "ALAND": 1612165763,
      "AWATER": 9603798,
      "County_state": "Bibb,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.421936,
              33.003379
            ],
            [
              -87.4212,
              32.874508
            ],
            [
              -87.319473,
              32.875124
            ],
            [
              -87.319184,
              32.831522
            ],
            [
              -87.233286,
              32.832239
            ],
            [
              -87.223663,
              32.835149
            ],
            [
              -87.098183,
              32.836008
            ],
            [
              -87.019157,
              32.837034
            ],
            [
              -86.876118,
              32.836264
            ],
            [
              -86.87622,
              32.847838
            ],
            [
              -86.876222,
              32.901927
            ],
            [
              -86.876866,
              33.049925
            ],
            [
              -86.881182,
              33.049901
            ],
            [
              -86.933972,
              33.064059
            ],
            [
              -86.933992,
              33.078797
            ],
            [
              -86.951659,
              33.078741
            ],
            [
              -86.950876,
              33.098585
            ],
            [
              -86.969291,
              33.098397
            ],
            [
              -86.969309,
              33.10405
            ],
            [
              -86.969563,
              33.158135
            ],
            [
              -86.988799,
              33.161839
            ],
            [
              -87.025596,
              33.165795
            ],
            [
              -87.026846,
              33.246459
            ],
            [
              -87.065738,
              33.246907
            ],
            [
              -87.076642,
              33.246831
            ],
            [
              -87.104101,
              33.221457
            ],
            [
              -87.121511,
              33.221474
            ],
            [
              -87.121871,
              33.235843
            ],
            [
              -87.143502,
              33.236189
            ],
            [
              -87.152071,
              33.232484
            ],
            [
              -87.199153,
              33.196554
            ],
            [
              -87.199317,
              33.130657
            ],
            [
              -87.281945,
              33.13306
            ],
            [
              -87.311577,
              33.093933
            ],
            [
              -87.318327,
              33.013328
            ],
            [
              -87.318539,
              33.006179
            ],
            [
              -87.421936,
              33.003379
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "109",
      "COUNTYNS": "00161581",
      "AFFGEOID": "0500000US01109",
      "GEOID": "01109",
      "NAME": "Pike",
      "LSAD": "06",
      "ALAND": 1740647521,
      "AWATER": 2336975,
      "County_state": "Pike,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.199408,
              31.807861
            ],
            [
              -86.199378,
              31.79045
            ],
            [
              -86.148339,
              31.790951
            ],
            [
              -86.147126,
              31.662997
            ],
            [
              -86.160056,
              31.660234
            ],
            [
              -86.158882,
              31.64754
            ],
            [
              -86.180057,
              31.625109
            ],
            [
              -86.179672,
              31.616414
            ],
            [
              -86.145895,
              31.617741
            ],
            [
              -85.789142,
              31.617964
            ],
            [
              -85.748251,
              31.618048
            ],
            [
              -85.74174,
              31.619403
            ],
            [
              -85.729832,
              31.632373
            ],
            [
              -85.716146,
              31.660505
            ],
            [
              -85.717911,
              31.698032
            ],
            [
              -85.706122,
              31.707245
            ],
            [
              -85.705108,
              31.731715
            ],
            [
              -85.695602,
              31.747834
            ],
            [
              -85.674117,
              31.758626
            ],
            [
              -85.662692,
              31.783319
            ],
            [
              -85.673976,
              31.806603
            ],
            [
              -85.668309,
              31.834486
            ],
            [
              -85.677591,
              31.85678
            ],
            [
              -85.657668,
              31.880275
            ],
            [
              -85.791047,
              31.880357
            ],
            [
              -85.790048,
              31.967254
            ],
            [
              -85.88435,
              31.967253
            ],
            [
              -85.88425,
              31.981852
            ],
            [
              -85.892651,
              31.989053
            ],
            [
              -85.893652,
              32.047351
            ],
            [
              -85.911458,
              32.047355
            ],
            [
              -85.911755,
              32.054752
            ],
            [
              -85.946278,
              32.054571
            ],
            [
              -85.946298,
              32.06189
            ],
            [
              -85.950253,
              32.05455
            ],
            [
              -85.975912,
              32.054425
            ],
            [
              -85.984054,
              32.047453
            ],
            [
              -85.996853,
              32.051049
            ],
            [
              -85.995563,
              31.967554
            ],
            [
              -86.191379,
              31.966453
            ],
            [
              -86.174969,
              31.930597
            ],
            [
              -86.173091,
              31.893143
            ],
            [
              -86.182307,
              31.870287
            ],
            [
              -86.182949,
              31.83239
            ],
            [
              -86.199408,
              31.807861
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "061",
      "COUNTYNS": "00161556",
      "AFFGEOID": "0500000US01061",
      "GEOID": "01061",
      "NAME": "Geneva",
      "LSAD": "06",
      "ALAND": 1487870866,
      "AWATER": 11604964,
      "County_state": "Geneva,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.193476,
              31.192213
            ],
            [
              -86.190355,
              31.003061
            ],
            [
              -86.18724655883341,
              30.994033470489097
            ],
            [
              -86.180308,
              30.99399
            ],
            [
              -86.17528,
              30.993783
            ],
            [
              -86.169055,
              30.993691
            ],
            [
              -86.162962,
              30.993667
            ],
            [
              -86.116997,
              30.992902
            ],
            [
              -86.056294,
              30.993119
            ],
            [
              -86.052544,
              30.993233
            ],
            [
              -86.035121,
              30.993307
            ],
            [
              -86.0350402418067,
              30.993306001451096
            ],
            [
              -85.998727,
              30.992857
            ],
            [
              -85.893632,
              30.993455
            ],
            [
              -85.750028,
              30.994827
            ],
            [
              -85.749715,
              30.995282
            ],
            [
              -85.57949030335179,
              30.9963927426443
            ],
            [
              -85.49838,
              30.996922
            ],
            [
              -85.4979938168177,
              30.996928004442598
            ],
            [
              -85.4882980047342,
              30.9970787565989
            ],
            [
              -85.485426,
              31.141411
            ],
            [
              -85.48576,
              31.199886
            ],
            [
              -85.585733,
              31.194875
            ],
            [
              -85.710333,
              31.195173
            ],
            [
              -85.791402,
              31.196349
            ],
            [
              -86.058535,
              31.194082
            ],
            [
              -86.116734,
              31.193015
            ],
            [
              -86.116736,
              31.182099
            ],
            [
              -86.125482,
              31.192754
            ],
            [
              -86.193476,
              31.192213
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "069",
      "COUNTYNS": "00161560",
      "AFFGEOID": "0500000US01069",
      "GEOID": "01069",
      "NAME": "Houston",
      "LSAD": "06",
      "ALAND": 1501807104,
      "AWATER": 4730565,
      "County_state": "Houston,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.692365,
              31.21191
            ],
            [
              -85.710333,
              31.195173
            ],
            [
              -85.585733,
              31.194875
            ],
            [
              -85.48576,
              31.199886
            ],
            [
              -85.485426,
              31.141411
            ],
            [
              -85.4882980047342,
              30.9970787565989
            ],
            [
              -85.243752,
              31.000881
            ],
            [
              -85.154576,
              31.000833
            ],
            [
              -85.152342,
              31.000832
            ],
            [
              -85.152209,
              31.000886
            ],
            [
              -85.145959,
              31.000693
            ],
            [
              -85.057662,
              31.000584
            ],
            [
              -85.054931,
              31.000584
            ],
            [
              -85.052217,
              31.000584
            ],
            [
              -85.031285,
              31.000647
            ],
            [
              -85.030237,
              31.000653
            ],
            [
              -85.027642,
              31.00067
            ],
            [
              -85.024238,
              31.000681
            ],
            [
              -85.002499,
              31.000682
            ],
            [
              -85.001497,
              31.005044
            ],
            [
              -85.011392,
              31.053546
            ],
            [
              -85.0235761810791,
              31.0755033185024
            ],
            [
              -85.026603,
              31.080958
            ],
            [
              -85.035615,
              31.108192
            ],
            [
              -85.052867,
              31.119489
            ],
            [
              -85.076628,
              31.156927
            ],
            [
              -85.093405,
              31.162326
            ],
            [
              -85.107516,
              31.186451
            ],
            [
              -85.09977,
              31.209751
            ],
            [
              -85.098844,
              31.232524
            ],
            [
              -85.114548,
              31.276302
            ],
            [
              -85.089774,
              31.295026
            ],
            [
              -85.08882981560309,
              31.308647760712898
            ],
            [
              -85.179131,
              31.307675
            ],
            [
              -85.323021,
              31.312673
            ],
            [
              -85.373496,
              31.314519
            ],
            [
              -85.409015,
              31.314894
            ],
            [
              -85.417434,
              31.314973
            ],
            [
              -85.417417,
              31.293344
            ],
            [
              -85.418304,
              31.286271
            ],
            [
              -85.447416,
              31.286594
            ],
            [
              -85.468042,
              31.286783
            ],
            [
              -85.485442,
              31.287095
            ],
            [
              -85.485854,
              31.246096
            ],
            [
              -85.546113,
              31.253868
            ],
            [
              -85.585534,
              31.264179
            ],
            [
              -85.602956,
              31.272378
            ],
            [
              -85.635307,
              31.275555
            ],
            [
              -85.662893,
              31.269034
            ],
            [
              -85.67119,
              31.249695
            ],
            [
              -85.689092,
              31.23609
            ],
            [
              -85.692365,
              31.21191
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "093",
      "COUNTYNS": "00161573",
      "AFFGEOID": "0500000US01093",
      "GEOID": "01093",
      "NAME": "Marion",
      "LSAD": "06",
      "ALAND": 1922656984,
      "AWATER": 3183684,
      "County_state": "Marion,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -88.2037840638383,
              34.0865542422802
            ],
            [
              -88.207229,
              34.058333
            ],
            [
              -87.98693,
              34.052102
            ],
            [
              -87.98743,
              34.022903
            ],
            [
              -87.951929,
              34.022202
            ],
            [
              -87.951785,
              33.91993
            ],
            [
              -87.863239,
              33.919511
            ],
            [
              -87.722438,
              33.914797
            ],
            [
              -87.635932,
              33.915251
            ],
            [
              -87.636118,
              34.002203
            ],
            [
              -87.636241,
              34.070144
            ],
            [
              -87.635285,
              34.170897
            ],
            [
              -87.634368,
              34.220165
            ],
            [
              -87.634725,
              34.306997
            ],
            [
              -87.73089,
              34.310187
            ],
            [
              -87.908896,
              34.312527
            ],
            [
              -88.173632,
              34.321054
            ],
            [
              -88.175867,
              34.302171
            ],
            [
              -88.176889,
              34.293858
            ],
            [
              -88.17710617074539,
              34.292238747559196
            ],
            [
              -88.186667,
              34.220952
            ],
            [
              -88.18762,
              34.204778
            ],
            [
              -88.190678,
              34.190123
            ],
            [
              -88.192128,
              34.175351
            ],
            [
              -88.200196,
              34.115948
            ],
            [
              -88.2037840638383,
              34.0865542422802
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "029",
      "COUNTYNS": "00161540",
      "AFFGEOID": "0500000US01029",
      "GEOID": "01029",
      "NAME": "Cleburne",
      "LSAD": "06",
      "ALAND": 1450663938,
      "AWATER": 2354898,
      "County_state": "Cleburne,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.870053,
              33.476757
            ],
            [
              -85.887675,
              33.476768
            ],
            [
              -85.782735,
              33.469349
            ],
            [
              -85.782689,
              33.483638
            ],
            [
              -85.765631,
              33.483477
            ],
            [
              -85.765427,
              33.498593
            ],
            [
              -85.643482,
              33.495885
            ],
            [
              -85.352573,
              33.492438
            ],
            [
              -85.347744,
              33.501378
            ],
            [
              -85.331061,
              33.491014
            ],
            [
              -85.3044873227631,
              33.4828732747038
            ],
            [
              -85.304489,
              33.482882
            ],
            [
              -85.314048,
              33.529805
            ],
            [
              -85.31414,
              33.530216
            ],
            [
              -85.315043,
              33.535896
            ],
            [
              -85.3226150430291,
              33.5741503542721
            ],
            [
              -85.3238402036472,
              33.58033992928419
            ],
            [
              -85.33824506153,
              33.65311401873009
            ],
            [
              -85.357443,
              33.750103
            ],
            [
              -85.360532,
              33.767957
            ],
            [
              -85.361885,
              33.77395
            ],
            [
              -85.37643978043509,
              33.8506487133064
            ],
            [
              -85.377464,
              33.856046
            ],
            [
              -85.38651002496731,
              33.901718559000095
            ],
            [
              -85.398871,
              33.964128
            ],
            [
              -85.3988712532846,
              33.9641292868502
            ],
            [
              -85.425627,
              33.957069
            ],
            [
              -85.495289,
              33.95691
            ],
            [
              -85.49582,
              33.942394
            ],
            [
              -85.530094,
              33.941423
            ],
            [
              -85.532482,
              33.889152
            ],
            [
              -85.601858,
              33.88975
            ],
            [
              -85.601899,
              33.874865
            ],
            [
              -85.637012,
              33.875273
            ],
            [
              -85.637126,
              33.846497
            ],
            [
              -85.585201,
              33.84592
            ],
            [
              -85.585985,
              33.802095
            ],
            [
              -85.603391,
              33.802344
            ],
            [
              -85.603469,
              33.787755
            ],
            [
              -85.62089,
              33.787944
            ],
            [
              -85.621245,
              33.773508
            ],
            [
              -85.638049,
              33.773339
            ],
            [
              -85.638579,
              33.648413
            ],
            [
              -85.64704,
              33.648772
            ],
            [
              -85.65581,
              33.627166
            ],
            [
              -85.690684,
              33.627789
            ],
            [
              -85.69014,
              33.615815
            ],
            [
              -85.724953,
              33.613539
            ],
            [
              -85.724517,
              33.59943
            ],
            [
              -85.742308,
              33.600006
            ],
            [
              -85.744118,
              33.556075
            ],
            [
              -85.796054,
              33.55622
            ],
            [
              -85.796852,
              33.541849
            ],
            [
              -85.85189,
              33.498742
            ],
            [
              -85.852421,
              33.491644
            ],
            [
              -85.869308,
              33.491574
            ],
            [
              -85.870053,
              33.476757
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "089",
      "COUNTYNS": "00161570",
      "AFFGEOID": "0500000US01089",
      "GEOID": "01089",
      "NAME": "Madison",
      "LSAD": "06",
      "ALAND": 2076145098,
      "AWATER": 28777508,
      "County_state": "Madison,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.786928,
              34.662612
            ],
            [
              -86.790056,
              34.55079
            ],
            [
              -86.753138,
              34.555672
            ],
            [
              -86.721721,
              34.582709
            ],
            [
              -86.702185,
              34.589004
            ],
            [
              -86.676558,
              34.577794
            ],
            [
              -86.66514,
              34.552092
            ],
            [
              -86.636195,
              34.555198
            ],
            [
              -86.614169,
              34.577227
            ],
            [
              -86.598488,
              34.582107
            ],
            [
              -86.567965,
              34.575516
            ],
            [
              -86.553542,
              34.561659
            ],
            [
              -86.550166,
              34.545963
            ],
            [
              -86.551094,
              34.541499
            ],
            [
              -86.531749,
              34.501275
            ],
            [
              -86.530777,
              34.500398
            ],
            [
              -86.499988,
              34.482292
            ],
            [
              -86.46891,
              34.475633
            ],
            [
              -86.45396,
              34.483798
            ],
            [
              -86.423914,
              34.479581
            ],
            [
              -86.412446,
              34.494155
            ],
            [
              -86.388926,
              34.500199
            ],
            [
              -86.392422,
              34.518379
            ],
            [
              -86.369735,
              34.513754
            ],
            [
              -86.35518,
              34.523489
            ],
            [
              -86.338181,
              34.510103
            ],
            [
              -86.330198,
              34.512684
            ],
            [
              -86.324499,
              34.529144
            ],
            [
              -86.311187,
              34.539277
            ],
            [
              -86.306646,
              34.560738
            ],
            [
              -86.328847,
              34.565242
            ],
            [
              -86.331423,
              34.573261
            ],
            [
              -86.303081,
              34.579617
            ],
            [
              -86.303133,
              34.592633
            ],
            [
              -86.326853,
              34.599403
            ],
            [
              -86.331344,
              34.60732
            ],
            [
              -86.325865,
              34.609587
            ],
            [
              -86.353817,
              34.62908
            ],
            [
              -86.359034,
              34.63261
            ],
            [
              -86.358121,
              34.735034
            ],
            [
              -86.342251,
              34.74431
            ],
            [
              -86.337854,
              34.763039
            ],
            [
              -86.343541,
              34.81706
            ],
            [
              -86.326546,
              34.831325
            ],
            [
              -86.346966,
              34.857689
            ],
            [
              -86.3513,
              34.873662
            ],
            [
              -86.32943,
              34.901402
            ],
            [
              -86.31131,
              34.914794
            ],
            [
              -86.32624,
              34.937102
            ],
            [
              -86.311436,
              34.934834
            ],
            [
              -86.296018,
              34.944784
            ],
            [
              -86.28172,
              34.938457
            ],
            [
              -86.272236,
              34.924099
            ],
            [
              -86.256939,
              34.926484
            ],
            [
              -86.256968,
              34.937775
            ],
            [
              -86.259185,
              34.945375
            ],
            [
              -86.287048,
              34.957738
            ],
            [
              -86.289371,
              34.968625
            ],
            [
              -86.306317,
              34.974917
            ],
            [
              -86.311274,
              34.991098
            ],
            [
              -86.31876114409519,
              34.9911469680432
            ],
            [
              -86.397203,
              34.99166
            ],
            [
              -86.433927,
              34.991085
            ],
            [
              -86.467798,
              34.990692
            ],
            [
              -86.5179380043519,
              34.9906796068999
            ],
            [
              -86.528485,
              34.990677
            ],
            [
              -86.555864,
              34.990971
            ],
            [
              -86.571217,
              34.991011
            ],
            [
              -86.5713079656821,
              34.9910119534864
            ],
            [
              -86.588962,
              34.991197
            ],
            [
              -86.600039,
              34.99124
            ],
            [
              -86.641212,
              34.99174
            ],
            [
              -86.6413928487564,
              34.9917405111499
            ],
            [
              -86.65961,
              34.991792
            ],
            [
              -86.670853,
              34.992
            ],
            [
              -86.67436,
              34.992001
            ],
            [
              -86.676726,
              34.99207
            ],
            [
              -86.677616,
              34.99207
            ],
            [
              -86.78362801709059,
              34.9919250273269
            ],
            [
              -86.783696,
              34.933403
            ],
            [
              -86.785328,
              34.805803
            ],
            [
              -86.785388,
              34.802191
            ],
            [
              -86.786359,
              34.728976
            ],
            [
              -86.786448,
              34.718172
            ],
            [
              -86.786928,
              34.662612
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "131",
      "COUNTYNS": "00161591",
      "AFFGEOID": "0500000US01131",
      "GEOID": "01131",
      "NAME": "Wilcox",
      "LSAD": "06",
      "ALAND": 2301075110,
      "AWATER": 49057525,
      "County_state": "Wilcox,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.667769,
              31.991355
            ],
            [
              -87.666849,
              31.877733
            ],
            [
              -87.620951,
              31.870395
            ],
            [
              -87.620112,
              31.827123
            ],
            [
              -87.50093,
              31.829251
            ],
            [
              -87.244551,
              31.831795
            ],
            [
              -87.214084,
              31.828498
            ],
            [
              -86.906899,
              31.830628
            ],
            [
              -86.908939,
              31.961673
            ],
            [
              -86.857583,
              31.962167
            ],
            [
              -86.856576,
              32.048352
            ],
            [
              -86.906956,
              32.04797
            ],
            [
              -87.17786,
              32.047514
            ],
            [
              -87.175585,
              32.064701
            ],
            [
              -87.191578,
              32.070256
            ],
            [
              -87.189937,
              32.086058
            ],
            [
              -87.234028,
              32.101694
            ],
            [
              -87.245513,
              32.122165
            ],
            [
              -87.261156,
              32.123753
            ],
            [
              -87.252237,
              32.135795
            ],
            [
              -87.262853,
              32.147007
            ],
            [
              -87.280866,
              32.144345
            ],
            [
              -87.29253,
              32.167904
            ],
            [
              -87.317962,
              32.181264
            ],
            [
              -87.324783,
              32.199069
            ],
            [
              -87.365394,
              32.197225
            ],
            [
              -87.374521,
              32.221922
            ],
            [
              -87.388599,
              32.223166
            ],
            [
              -87.392284,
              32.239894
            ],
            [
              -87.409079,
              32.236736
            ],
            [
              -87.42275,
              32.244118
            ],
            [
              -87.444429,
              32.268889
            ],
            [
              -87.472206,
              32.264919
            ],
            [
              -87.514066,
              32.265993
            ],
            [
              -87.523405,
              32.25483
            ],
            [
              -87.522125,
              32.132783
            ],
            [
              -87.624005,
              32.132345
            ],
            [
              -87.622756,
              32.005482
            ],
            [
              -87.659785,
              32.002797
            ],
            [
              -87.667769,
              31.991355
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "009",
      "COUNTYNS": "00161530",
      "AFFGEOID": "0500000US01009",
      "GEOID": "01009",
      "NAME": "Blount",
      "LSAD": "06",
      "ALAND": 1670079465,
      "AWATER": 15039864,
      "County_state": "Blount,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.963358,
              33.858221
            ],
            [
              -86.954305,
              33.844862
            ],
            [
              -86.953664,
              33.815297
            ],
            [
              -86.936537,
              33.81531
            ],
            [
              -86.883947,
              33.843237
            ],
            [
              -86.788372,
              33.841035
            ],
            [
              -86.759144,
              33.840617
            ],
            [
              -86.647765,
              33.765896
            ],
            [
              -86.605881,
              33.7652
            ],
            [
              -86.583082,
              33.765416
            ],
            [
              -86.577799,
              33.765316
            ],
            [
              -86.577528,
              33.801977
            ],
            [
              -86.521771,
              33.801901
            ],
            [
              -86.512492,
              33.810794
            ],
            [
              -86.480295,
              33.815151
            ],
            [
              -86.44528,
              33.825977
            ],
            [
              -86.437717,
              33.819981
            ],
            [
              -86.405981,
              33.835896
            ],
            [
              -86.377532,
              33.861706
            ],
            [
              -86.342694,
              33.88647
            ],
            [
              -86.349501,
              33.906982
            ],
            [
              -86.32215,
              33.932427
            ],
            [
              -86.325622,
              33.940147
            ],
            [
              -86.370152,
              33.93977
            ],
            [
              -86.339337,
              33.971484
            ],
            [
              -86.332723,
              33.986109
            ],
            [
              -86.332394,
              34.040499
            ],
            [
              -86.322662,
              34.072288
            ],
            [
              -86.303516,
              34.099073
            ],
            [
              -86.313663,
              34.111794
            ],
            [
              -86.378307,
              34.173792
            ],
            [
              -86.386702,
              34.18227
            ],
            [
              -86.409017,
              34.205002
            ],
            [
              -86.41694,
              34.214887
            ],
            [
              -86.43069,
              34.231891
            ],
            [
              -86.444133,
              34.259115
            ],
            [
              -86.45302,
              34.259317
            ],
            [
              -86.485943,
              34.260185
            ],
            [
              -86.499658,
              34.248968
            ],
            [
              -86.518927,
              34.252321
            ],
            [
              -86.523387,
              34.227338
            ],
            [
              -86.539352,
              34.226006
            ],
            [
              -86.555796,
              34.189129
            ],
            [
              -86.545211,
              34.18727
            ],
            [
              -86.56385,
              34.170493
            ],
            [
              -86.567833,
              34.170221
            ],
            [
              -86.594729,
              34.13544
            ],
            [
              -86.601147,
              34.11904
            ],
            [
              -86.624127,
              34.122211
            ],
            [
              -86.653448,
              34.107223
            ],
            [
              -86.65682,
              34.091646
            ],
            [
              -86.658764,
              34.089997
            ],
            [
              -86.660258,
              34.089377
            ],
            [
              -86.664327,
              34.087035
            ],
            [
              -86.666664,
              34.085437
            ],
            [
              -86.667786,
              34.084415
            ],
            [
              -86.668956,
              34.083701
            ],
            [
              -86.669995,
              34.083845
            ],
            [
              -86.670426,
              34.084478
            ],
            [
              -86.670213,
              34.085812
            ],
            [
              -86.670721,
              34.087269
            ],
            [
              -86.67134,
              34.087927
            ],
            [
              -86.672603,
              34.088412
            ],
            [
              -86.673295,
              34.088215
            ],
            [
              -86.673993,
              34.087687
            ],
            [
              -86.675547,
              34.08535
            ],
            [
              -86.67727,
              34.084355
            ],
            [
              -86.681097,
              34.083288
            ],
            [
              -86.682884,
              34.082356
            ],
            [
              -86.685242,
              34.079858
            ],
            [
              -86.686576,
              34.079569
            ],
            [
              -86.687304,
              34.079989
            ],
            [
              -86.687697,
              34.080735
            ],
            [
              -86.687748,
              34.084064
            ],
            [
              -86.688703,
              34.08628
            ],
            [
              -86.688678,
              34.087047
            ],
            [
              -86.687651,
              34.089101
            ],
            [
              -86.687556,
              34.090663
            ],
            [
              -86.687751,
              34.091791
            ],
            [
              -86.688431,
              34.092596
            ],
            [
              -86.689278,
              34.092443
            ],
            [
              -86.691243,
              34.092718
            ],
            [
              -86.692474,
              34.092405
            ],
            [
              -86.693138,
              34.090787
            ],
            [
              -86.694429,
              34.088426
            ],
            [
              -86.695346,
              34.087137
            ],
            [
              -86.698115,
              34.085085
            ],
            [
              -86.702468,
              34.082779
            ],
            [
              -86.704244,
              34.081497
            ],
            [
              -86.704553,
              34.080916
            ],
            [
              -86.704538,
              34.080421
            ],
            [
              -86.704037,
              34.079718
            ],
            [
              -86.702052,
              34.078275
            ],
            [
              -86.700552,
              34.077516
            ],
            [
              -86.698743,
              34.075085
            ],
            [
              -86.696858,
              34.072268
            ],
            [
              -86.695045,
              34.070346
            ],
            [
              -86.693259,
              34.06818
            ],
            [
              -86.692769,
              34.067384
            ],
            [
              -86.691746,
              34.065116
            ],
            [
              -86.690581,
              34.063789
            ],
            [
              -86.685365,
              34.05914
            ],
            [
              -86.70095,
              34.060553
            ],
            [
              -86.701465,
              34.039633
            ],
            [
              -86.720577,
              34.032032
            ],
            [
              -86.736688,
              34.012006
            ],
            [
              -86.740378,
              34.000386
            ],
            [
              -86.760071,
              33.995934
            ],
            [
              -86.763999,
              33.974187
            ],
            [
              -86.791678,
              33.963558
            ],
            [
              -86.791756,
              33.952548
            ],
            [
              -86.820117,
              33.957526
            ],
            [
              -86.821315,
              33.941531
            ],
            [
              -86.836215,
              33.95062
            ],
            [
              -86.868171,
              33.932767
            ],
            [
              -86.877888,
              33.936301
            ],
            [
              -86.886022,
              33.917432
            ],
            [
              -86.924387,
              33.909222
            ],
            [
              -86.920198,
              33.873453
            ],
            [
              -86.963358,
              33.858221
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "079",
      "COUNTYNS": "00161565",
      "AFFGEOID": "0500000US01079",
      "GEOID": "01079",
      "NAME": "Lawrence",
      "LSAD": "06",
      "ALAND": 1788859207,
      "AWATER": 68687366,
      "County_state": "Lawrence,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.529667,
              34.567081
            ],
            [
              -87.529722,
              34.304598
            ],
            [
              -87.498155,
              34.304443
            ],
            [
              -87.109911,
              34.299299
            ],
            [
              -87.110111,
              34.313799
            ],
            [
              -87.10937,
              34.415814
            ],
            [
              -87.106213,
              34.574389
            ],
            [
              -87.10576,
              34.605623
            ],
            [
              -87.105073,
              34.686037
            ],
            [
              -87.119458,
              34.697258
            ],
            [
              -87.138858,
              34.724101
            ],
            [
              -87.171588,
              34.74286
            ],
            [
              -87.222773,
              34.763226
            ],
            [
              -87.260676,
              34.758626
            ],
            [
              -87.297905,
              34.750812
            ],
            [
              -87.344224,
              34.796493
            ],
            [
              -87.370249,
              34.804992
            ],
            [
              -87.42651,
              34.800022
            ],
            [
              -87.434391,
              34.773614
            ],
            [
              -87.416484,
              34.767544
            ],
            [
              -87.432429,
              34.737135
            ],
            [
              -87.426161,
              34.709743
            ],
            [
              -87.44995,
              34.696804
            ],
            [
              -87.451138,
              34.682385
            ],
            [
              -87.443353,
              34.661227
            ],
            [
              -87.459496,
              34.644349
            ],
            [
              -87.483226,
              34.64857
            ],
            [
              -87.476822,
              34.637798
            ],
            [
              -87.496908,
              34.625988
            ],
            [
              -87.513912,
              34.59476
            ],
            [
              -87.507342,
              34.586491
            ],
            [
              -87.518558,
              34.577482
            ],
            [
              -87.517615,
              34.566271
            ],
            [
              -87.529667,
              34.567081
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "125",
      "COUNTYNS": "00161588",
      "AFFGEOID": "0500000US01125",
      "GEOID": "01125",
      "NAME": "Tuscaloosa",
      "LSAD": "06",
      "ALAND": 3421041792,
      "AWATER": 78682797,
      "County_state": "Tuscaloosa,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.840683,
              33.524839
            ],
            [
              -87.838264,
              33.361238
            ],
            [
              -87.837521,
              33.153637
            ],
            [
              -87.838047,
              33.136864
            ],
            [
              -87.831459,
              33.093857
            ],
            [
              -87.832233,
              33.017258
            ],
            [
              -87.752641,
              33.017259
            ],
            [
              -87.710995,
              33.017721
            ],
            [
              -87.715709,
              33.006824
            ],
            [
              -87.634507,
              33.005122
            ],
            [
              -87.615954,
              33.004638
            ],
            [
              -87.421936,
              33.003379
            ],
            [
              -87.318539,
              33.006179
            ],
            [
              -87.318327,
              33.013328
            ],
            [
              -87.311577,
              33.093933
            ],
            [
              -87.281945,
              33.13306
            ],
            [
              -87.199317,
              33.130657
            ],
            [
              -87.199153,
              33.196554
            ],
            [
              -87.152071,
              33.232484
            ],
            [
              -87.143502,
              33.236189
            ],
            [
              -87.121871,
              33.235843
            ],
            [
              -87.121511,
              33.221474
            ],
            [
              -87.104101,
              33.221457
            ],
            [
              -87.076642,
              33.246831
            ],
            [
              -87.065738,
              33.246907
            ],
            [
              -87.065752,
              33.26763
            ],
            [
              -87.065754,
              33.275937
            ],
            [
              -87.083932,
              33.279634
            ],
            [
              -87.087497,
              33.290675
            ],
            [
              -87.126756,
              33.290844
            ],
            [
              -87.127142,
              33.306013
            ],
            [
              -87.162007,
              33.306587
            ],
            [
              -87.162175,
              33.321012
            ],
            [
              -87.179754,
              33.32801
            ],
            [
              -87.180006,
              33.342979
            ],
            [
              -87.194841,
              33.343069
            ],
            [
              -87.197584,
              33.358134
            ],
            [
              -87.231999,
              33.365851
            ],
            [
              -87.232045,
              33.395137
            ],
            [
              -87.249311,
              33.395583
            ],
            [
              -87.253788,
              33.417813
            ],
            [
              -87.284275,
              33.425708
            ],
            [
              -87.284363,
              33.440578
            ],
            [
              -87.314395,
              33.441719
            ],
            [
              -87.314114,
              33.45573
            ],
            [
              -87.335577,
              33.456251
            ],
            [
              -87.341698,
              33.470501
            ],
            [
              -87.330278,
              33.491961
            ],
            [
              -87.285024,
              33.485785
            ],
            [
              -87.28544,
              33.50228
            ],
            [
              -87.261361,
              33.500683
            ],
            [
              -87.266923,
              33.512929
            ],
            [
              -87.318237,
              33.514166
            ],
            [
              -87.318532,
              33.587393
            ],
            [
              -87.37104,
              33.587065
            ],
            [
              -87.371118,
              33.601697
            ],
            [
              -87.423701,
              33.602096
            ],
            [
              -87.631718,
              33.609833
            ],
            [
              -87.63161,
              33.580669
            ],
            [
              -87.666633,
              33.579807
            ],
            [
              -87.666661,
              33.521667
            ],
            [
              -87.687565,
              33.521087
            ],
            [
              -87.736652,
              33.519568
            ],
            [
              -87.840683,
              33.524839
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "087",
      "COUNTYNS": "00161569",
      "AFFGEOID": "0500000US01087",
      "GEOID": "01087",
      "NAME": "Macon",
      "LSAD": "06",
      "ALAND": 1576801788,
      "AWATER": 11399310,
      "County_state": "Macon,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.023012,
              32.419978
            ],
            [
              -86.010941,
              32.40431
            ],
            [
              -86.014579,
              32.38429
            ],
            [
              -85.993667,
              32.357908
            ],
            [
              -85.991283,
              32.336098
            ],
            [
              -85.969104,
              32.333551
            ],
            [
              -85.958464,
              32.323484
            ],
            [
              -85.954322,
              32.302208
            ],
            [
              -85.919293,
              32.274382
            ],
            [
              -85.898689,
              32.274987
            ],
            [
              -85.898539,
              32.305289
            ],
            [
              -85.877868,
              32.291528
            ],
            [
              -85.876061,
              32.246344
            ],
            [
              -85.862601,
              32.234915
            ],
            [
              -85.856218,
              32.231975
            ],
            [
              -85.433543,
              32.234648
            ],
            [
              -85.434045,
              32.40984
            ],
            [
              -85.434749,
              32.42969
            ],
            [
              -85.437142,
              32.478681
            ],
            [
              -85.438575,
              32.49709
            ],
            [
              -85.467946,
              32.497062
            ],
            [
              -85.489348,
              32.496937
            ],
            [
              -85.498598,
              32.500684
            ],
            [
              -85.610102,
              32.547049
            ],
            [
              -85.661053,
              32.567935
            ],
            [
              -85.661064,
              32.568639
            ],
            [
              -85.661253,
              32.582334
            ],
            [
              -85.695654,
              32.582034
            ],
            [
              -85.695854,
              32.595933
            ],
            [
              -85.712756,
              32.595731
            ],
            [
              -85.712654,
              32.581834
            ],
            [
              -85.798585,
              32.581089
            ],
            [
              -85.79822,
              32.558849
            ],
            [
              -85.797156,
              32.494236
            ],
            [
              -85.869291,
              32.493495
            ],
            [
              -85.886148,
              32.493053
            ],
            [
              -85.870305,
              32.481474
            ],
            [
              -85.852625,
              32.475747
            ],
            [
              -85.872695,
              32.473869
            ],
            [
              -85.882219,
              32.454988
            ],
            [
              -85.89442,
              32.447578
            ],
            [
              -85.931776,
              32.453528
            ],
            [
              -85.958953,
              32.44113
            ],
            [
              -85.973455,
              32.453453
            ],
            [
              -85.985262,
              32.440472
            ],
            [
              -85.993493,
              32.451509
            ],
            [
              -85.990652,
              32.433053
            ],
            [
              -86.023012,
              32.419978
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "123",
      "COUNTYNS": "00161587",
      "AFFGEOID": "0500000US01123",
      "GEOID": "01123",
      "NAME": "Tallapoosa",
      "LSAD": "06",
      "ALAND": 1855771079,
      "AWATER": 128817905,
      "County_state": "Tallapoosa,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.00917,
              33.09026
            ],
            [
              -86.008498,
              33.020626
            ],
            [
              -86.008342,
              32.997798
            ],
            [
              -86.007847,
              32.887614
            ],
            [
              -86.007565,
              32.827773
            ],
            [
              -86.00005,
              32.801645
            ],
            [
              -86.007066,
              32.796431
            ],
            [
              -86.007187,
              32.754984
            ],
            [
              -85.972159,
              32.75493
            ],
            [
              -85.972763,
              32.769225
            ],
            [
              -85.955561,
              32.769126
            ],
            [
              -85.954859,
              32.754824
            ],
            [
              -85.87986,
              32.754528
            ],
            [
              -85.881859,
              32.737558
            ],
            [
              -85.893402,
              32.727663
            ],
            [
              -85.890611,
              32.717005
            ],
            [
              -85.90747,
              32.699302
            ],
            [
              -85.911714,
              32.67784
            ],
            [
              -85.887747,
              32.64761
            ],
            [
              -85.890283,
              32.633162
            ],
            [
              -85.894559,
              32.603132
            ],
            [
              -85.885458,
              32.547634
            ],
            [
              -85.890392,
              32.500135
            ],
            [
              -85.886148,
              32.493053
            ],
            [
              -85.869291,
              32.493495
            ],
            [
              -85.797156,
              32.494236
            ],
            [
              -85.79822,
              32.558849
            ],
            [
              -85.798585,
              32.581089
            ],
            [
              -85.712654,
              32.581834
            ],
            [
              -85.712756,
              32.595731
            ],
            [
              -85.695854,
              32.595933
            ],
            [
              -85.696755,
              32.697429
            ],
            [
              -85.679654,
              32.69793
            ],
            [
              -85.678249,
              32.712567
            ],
            [
              -85.610253,
              32.713929
            ],
            [
              -85.609994,
              32.727949
            ],
            [
              -85.593151,
              32.72853
            ],
            [
              -85.593066,
              32.7453
            ],
            [
              -85.593435,
              32.897267
            ],
            [
              -85.593177,
              33.10734
            ],
            [
              -85.653654,
              33.106634
            ],
            [
              -85.753628,
              33.10528
            ],
            [
              -85.801694,
              33.104298
            ],
            [
              -85.974715,
              33.105286
            ],
            [
              -85.97456,
              33.090507
            ],
            [
              -86.00917,
              33.09026
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "071",
      "COUNTYNS": "00161561",
      "AFFGEOID": "0500000US01071",
      "GEOID": "01071",
      "NAME": "Jackson",
      "LSAD": "06",
      "ALAND": 2791974087,
      "AWATER": 126334708,
      "County_state": "Jackson,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.358121,
              34.735034
            ],
            [
              -86.359034,
              34.63261
            ],
            [
              -86.353817,
              34.62908
            ],
            [
              -86.325865,
              34.609587
            ],
            [
              -86.331344,
              34.60732
            ],
            [
              -86.326853,
              34.599403
            ],
            [
              -86.148464,
              34.599069
            ],
            [
              -86.148681,
              34.588339
            ],
            [
              -86.147764,
              34.520904
            ],
            [
              -86.126378,
              34.5333
            ],
            [
              -86.097846,
              34.521696
            ],
            [
              -86.097741,
              34.512547
            ],
            [
              -86.087628,
              34.466558
            ],
            [
              -86.057712,
              34.475994
            ],
            [
              -86.034848,
              34.473423
            ],
            [
              -86.015594,
              34.481639
            ],
            [
              -86.00384,
              34.480031
            ],
            [
              -85.966156,
              34.498606
            ],
            [
              -85.964114,
              34.499642
            ],
            [
              -85.962343,
              34.499755
            ],
            [
              -85.961177,
              34.500587
            ],
            [
              -85.960106,
              34.502179
            ],
            [
              -85.959608,
              34.503485
            ],
            [
              -85.95698,
              34.506142
            ],
            [
              -85.956935,
              34.507125
            ],
            [
              -85.938544,
              34.525354
            ],
            [
              -85.881114,
              34.562315
            ],
            [
              -85.785471,
              34.624584
            ],
            [
              -85.693037,
              34.74425
            ],
            [
              -85.673379,
              34.744915
            ],
            [
              -85.660198,
              34.754963
            ],
            [
              -85.634268,
              34.798978
            ],
            [
              -85.627068,
              34.831879
            ],
            [
              -85.583148376479,
              34.8603678345509
            ],
            [
              -85.583149,
              34.860371
            ],
            [
              -85.595165,
              34.924171
            ],
            [
              -85.595193,
              34.924331
            ],
            [
              -85.598782,
              34.944915
            ],
            [
              -85.599386,
              34.951766
            ],
            [
              -85.605165,
              34.984678
            ],
            [
              -85.824411,
              34.988142
            ],
            [
              -85.828724,
              34.988165
            ],
            [
              -85.8639354755464,
              34.9883790198068
            ],
            [
              -86.06161930972429,
              34.9895805671815
            ],
            [
              -86.311274,
              34.991098
            ],
            [
              -86.306317,
              34.974917
            ],
            [
              -86.289371,
              34.968625
            ],
            [
              -86.287048,
              34.957738
            ],
            [
              -86.259185,
              34.945375
            ],
            [
              -86.256968,
              34.937775
            ],
            [
              -86.256939,
              34.926484
            ],
            [
              -86.272236,
              34.924099
            ],
            [
              -86.28172,
              34.938457
            ],
            [
              -86.296018,
              34.944784
            ],
            [
              -86.311436,
              34.934834
            ],
            [
              -86.32624,
              34.937102
            ],
            [
              -86.31131,
              34.914794
            ],
            [
              -86.32943,
              34.901402
            ],
            [
              -86.3513,
              34.873662
            ],
            [
              -86.346966,
              34.857689
            ],
            [
              -86.326546,
              34.831325
            ],
            [
              -86.343541,
              34.81706
            ],
            [
              -86.337854,
              34.763039
            ],
            [
              -86.342251,
              34.74431
            ],
            [
              -86.358121,
              34.735034
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "039",
      "COUNTYNS": "00161545",
      "AFFGEOID": "0500000US01039",
      "GEOID": "01039",
      "NAME": "Covington",
      "LSAD": "06",
      "ALAND": 2668871125,
      "AWATER": 34489285,
      "County_state": "Covington,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.702446,
              31.345397
            ],
            [
              -86.701139,
              31.324358
            ],
            [
              -86.700282,
              31.192217
            ],
            [
              -86.70022,
              31.000418
            ],
            [
              -86.68831401792629,
              30.994573472502697
            ],
            [
              -86.678435,
              30.994515
            ],
            [
              -86.664734,
              30.994512
            ],
            [
              -86.567644,
              30.995088
            ],
            [
              -86.563494,
              30.995202
            ],
            [
              -86.519998,
              30.993225
            ],
            [
              -86.512894,
              30.99368
            ],
            [
              -86.500011,
              30.99332
            ],
            [
              -86.458382,
              30.993979
            ],
            [
              -86.454767,
              30.993772
            ],
            [
              -86.404977,
              30.994031
            ],
            [
              -86.392003,
              30.994154
            ],
            [
              -86.388713,
              30.994163
            ],
            [
              -86.38864587273319,
              30.994164394815197
            ],
            [
              -86.374612,
              30.994456
            ],
            [
              -86.369337,
              30.994459
            ],
            [
              -86.364974,
              30.994437
            ],
            [
              -86.304666,
              30.994012
            ],
            [
              -86.289318,
              30.993781
            ],
            [
              -86.25652,
              30.993837
            ],
            [
              -86.238408,
              30.994354
            ],
            [
              -86.18724655883341,
              30.994033470489097
            ],
            [
              -86.190355,
              31.003061
            ],
            [
              -86.193476,
              31.192213
            ],
            [
              -86.195044,
              31.352681
            ],
            [
              -86.196365,
              31.425431
            ],
            [
              -86.193951,
              31.440072
            ],
            [
              -86.261876,
              31.441168
            ],
            [
              -86.260189,
              31.455716
            ],
            [
              -86.277031,
              31.455602
            ],
            [
              -86.280155,
              31.52851
            ],
            [
              -86.298887,
              31.528497
            ],
            [
              -86.319641,
              31.505043
            ],
            [
              -86.3306,
              31.500951
            ],
            [
              -86.340037,
              31.486919
            ],
            [
              -86.364659,
              31.486732
            ],
            [
              -86.3703,
              31.473689
            ],
            [
              -86.395686,
              31.450684
            ],
            [
              -86.39994,
              31.527128
            ],
            [
              -86.415383,
              31.525572
            ],
            [
              -86.499213,
              31.525331
            ],
            [
              -86.701554,
              31.523946
            ],
            [
              -86.70122,
              31.436641
            ],
            [
              -86.67485,
              31.436557
            ],
            [
              -86.662082,
              31.402798
            ],
            [
              -86.675442,
              31.389001
            ],
            [
              -86.667245,
              31.369404
            ],
            [
              -86.692516,
              31.347479
            ],
            [
              -86.702446,
              31.345397
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "099",
      "COUNTYNS": "00161576",
      "AFFGEOID": "0500000US01099",
      "GEOID": "01099",
      "NAME": "Monroe",
      "LSAD": "06",
      "ALAND": 2656517454,
      "AWATER": 22609680,
      "County_state": "Monroe,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.784378,
              31.328914
            ],
            [
              -87.78276,
              31.312051
            ],
            [
              -87.765152,
              31.297346
            ],
            [
              -87.71765,
              31.303124
            ],
            [
              -87.690614,
              31.282916
            ],
            [
              -87.676419,
              31.261992
            ],
            [
              -87.632226,
              31.242931
            ],
            [
              -87.61589,
              31.244458
            ],
            [
              -87.559287,
              31.227291
            ],
            [
              -87.52301,
              31.247911
            ],
            [
              -87.489206,
              31.260076
            ],
            [
              -87.427455,
              31.260386
            ],
            [
              -87.420796,
              31.27652
            ],
            [
              -87.393206,
              31.300853
            ],
            [
              -87.360024,
              31.317496
            ],
            [
              -87.343748,
              31.351851
            ],
            [
              -87.337553,
              31.352121
            ],
            [
              -87.324391,
              31.366293
            ],
            [
              -87.296721,
              31.401278
            ],
            [
              -87.227797,
              31.454363
            ],
            [
              -87.205931,
              31.459025
            ],
            [
              -87.197237,
              31.479626
            ],
            [
              -87.188207,
              31.487678
            ],
            [
              -87.177864,
              31.5041
            ],
            [
              -87.166581,
              31.519561
            ],
            [
              -87.158925,
              31.56614
            ],
            [
              -87.145126,
              31.587168
            ],
            [
              -87.135899,
              31.641719
            ],
            [
              -87.094854,
              31.652136
            ],
            [
              -87.078331,
              31.666343
            ],
            [
              -87.055967,
              31.677381
            ],
            [
              -87.060954,
              31.704848
            ],
            [
              -87.0511,
              31.71834
            ],
            [
              -87.024141,
              31.712559
            ],
            [
              -86.989524,
              31.718226
            ],
            [
              -86.979895,
              31.726179
            ],
            [
              -86.948725,
              31.734883
            ],
            [
              -86.933897,
              31.746158
            ],
            [
              -86.905899,
              31.753035
            ],
            [
              -86.906899,
              31.830628
            ],
            [
              -87.214084,
              31.828498
            ],
            [
              -87.244551,
              31.831795
            ],
            [
              -87.50093,
              31.829251
            ],
            [
              -87.500953,
              31.814018
            ],
            [
              -87.518363,
              31.814386
            ],
            [
              -87.516131,
              31.697824
            ],
            [
              -87.566841,
              31.697115
            ],
            [
              -87.565413,
              31.553573
            ],
            [
              -87.599256,
              31.527441
            ],
            [
              -87.621445,
              31.514746
            ],
            [
              -87.605064,
              31.500162
            ],
            [
              -87.562787,
              31.477686
            ],
            [
              -87.56336,
              31.464696
            ],
            [
              -87.577002,
              31.432096
            ],
            [
              -87.588529,
              31.423919
            ],
            [
              -87.594827,
              31.415076
            ],
            [
              -87.61271,
              31.407825
            ],
            [
              -87.622706,
              31.410737
            ],
            [
              -87.639649,
              31.424768
            ],
            [
              -87.656694,
              31.425447
            ],
            [
              -87.669306,
              31.420934
            ],
            [
              -87.691958,
              31.405062
            ],
            [
              -87.706045,
              31.400957
            ],
            [
              -87.725837,
              31.373741
            ],
            [
              -87.722758,
              31.343621
            ],
            [
              -87.746399,
              31.333261
            ],
            [
              -87.754542,
              31.362327
            ],
            [
              -87.784378,
              31.328914
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "121",
      "COUNTYNS": "00161586",
      "AFFGEOID": "0500000US01121",
      "GEOID": "01121",
      "NAME": "Talladega",
      "LSAD": "06",
      "ALAND": 1908251622,
      "AWATER": 60931665,
      "County_state": "Talladega,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -86.486144,
              33.201867
            ],
            [
              -86.503127,
              33.179144
            ],
            [
              -86.483468,
              33.15952
            ],
            [
              -86.482985,
              33.14828
            ],
            [
              -86.459926,
              33.125117
            ],
            [
              -86.491029,
              33.102944
            ],
            [
              -86.17437,
              33.104394
            ],
            [
              -86.17283,
              33.195681
            ],
            [
              -86.120567,
              33.194511
            ],
            [
              -86.118198,
              33.29632
            ],
            [
              -86.100837,
              33.296078
            ],
            [
              -86.011555,
              33.294316
            ],
            [
              -85.980293,
              33.29419
            ],
            [
              -85.979089,
              33.32005
            ],
            [
              -85.976525,
              33.38187
            ],
            [
              -85.952741,
              33.381572
            ],
            [
              -85.940875,
              33.396054
            ],
            [
              -85.923762,
              33.396206
            ],
            [
              -85.922817,
              33.454576
            ],
            [
              -85.905338,
              33.45471
            ],
            [
              -85.904909,
              33.498655
            ],
            [
              -85.85189,
              33.498742
            ],
            [
              -85.796852,
              33.541849
            ],
            [
              -85.796054,
              33.55622
            ],
            [
              -85.794559,
              33.585565
            ],
            [
              -85.875569,
              33.586004
            ],
            [
              -85.948416,
              33.5858
            ],
            [
              -85.994935,
              33.586475
            ],
            [
              -85.999554,
              33.600959
            ],
            [
              -86.002107,
              33.601021
            ],
            [
              -86.021707,
              33.601172
            ],
            [
              -86.023381,
              33.623123
            ],
            [
              -86.025797,
              33.646285
            ],
            [
              -86.049576,
              33.673468
            ],
            [
              -86.054816,
              33.674716
            ],
            [
              -86.105988,
              33.6772
            ],
            [
              -86.145562,
              33.679098
            ],
            [
              -86.155117,
              33.67163
            ],
            [
              -86.170871,
              33.679332
            ],
            [
              -86.176619,
              33.696643
            ],
            [
              -86.186619,
              33.699461
            ],
            [
              -86.204984,
              33.689521
            ],
            [
              -86.200788,
              33.67682
            ],
            [
              -86.172437,
              33.659831
            ],
            [
              -86.164884,
              33.636629
            ],
            [
              -86.16948,
              33.619236
            ],
            [
              -86.200763,
              33.608721
            ],
            [
              -86.184385,
              33.593758
            ],
            [
              -86.188878,
              33.591145
            ],
            [
              -86.213229,
              33.594598
            ],
            [
              -86.221017,
              33.587919
            ],
            [
              -86.208596,
              33.55986
            ],
            [
              -86.192673,
              33.55314
            ],
            [
              -86.190495,
              33.543729
            ],
            [
              -86.226579,
              33.52055
            ],
            [
              -86.235328,
              33.494532
            ],
            [
              -86.253259,
              33.488844
            ],
            [
              -86.265604,
              33.496352
            ],
            [
              -86.263094,
              33.512118
            ],
            [
              -86.280611,
              33.511032
            ],
            [
              -86.310629,
              33.452551
            ],
            [
              -86.323096,
              33.444499
            ],
            [
              -86.336111,
              33.425088
            ],
            [
              -86.346277,
              33.410724
            ],
            [
              -86.378665,
              33.390983
            ],
            [
              -86.352857,
              33.372537
            ],
            [
              -86.340937,
              33.353074
            ],
            [
              -86.362216,
              33.346924
            ],
            [
              -86.367509,
              33.335692
            ],
            [
              -86.357595,
              33.295554
            ],
            [
              -86.393341,
              33.27543
            ],
            [
              -86.410284,
              33.27294
            ],
            [
              -86.433527,
              33.282623
            ],
            [
              -86.428871,
              33.249529
            ],
            [
              -86.435527,
              33.241994
            ],
            [
              -86.446998,
              33.242586
            ],
            [
              -86.466485,
              33.224489
            ],
            [
              -86.460978,
              33.20293
            ],
            [
              -86.486144,
              33.201867
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "067",
      "COUNTYNS": "00161559",
      "AFFGEOID": "0500000US01067",
      "GEOID": "01067",
      "NAME": "Henry",
      "LSAD": "06",
      "ALAND": 1454926615,
      "AWATER": 16986823,
      "County_state": "Henry,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -85.418035,
              31.44077
            ],
            [
              -85.41671,
              31.363351
            ],
            [
              -85.417434,
              31.314973
            ],
            [
              -85.409015,
              31.314894
            ],
            [
              -85.373496,
              31.314519
            ],
            [
              -85.323021,
              31.312673
            ],
            [
              -85.179131,
              31.307675
            ],
            [
              -85.08882981560309,
              31.308647760712898
            ],
            [
              -85.086657,
              31.339995
            ],
            [
              -85.092487,
              31.362881
            ],
            [
              -85.077581,
              31.398115
            ],
            [
              -85.079978,
              31.410472
            ],
            [
              -85.066005,
              31.431363
            ],
            [
              -85.071621,
              31.468384
            ],
            [
              -85.04555669828159,
              31.517161968216698
            ],
            [
              -85.044986,
              31.51823
            ],
            [
              -85.041881,
              31.544684
            ],
            [
              -85.05796,
              31.57084
            ],
            [
              -85.058169,
              31.620227
            ],
            [
              -85.08096,
              31.653102
            ],
            [
              -85.12553,
              31.694965
            ],
            [
              -85.11893,
              31.732664
            ],
            [
              -85.12513385383839,
              31.763011743932
            ],
            [
              -85.148434,
              31.773468
            ],
            [
              -85.188314,
              31.746441
            ],
            [
              -85.206034,
              31.741813
            ],
            [
              -85.209513,
              31.710474
            ],
            [
              -85.216076,
              31.702409
            ],
            [
              -85.416038,
              31.706664
            ],
            [
              -85.416437,
              31.619466
            ],
            [
              -85.415774,
              31.490413
            ],
            [
              -85.418035,
              31.44077
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "053",
      "COUNTYNS": "00161552",
      "AFFGEOID": "0500000US01053",
      "GEOID": "01053",
      "NAME": "Escambia",
      "LSAD": "06",
      "ALAND": 2447822304,
      "AWATER": 20797758,
      "County_state": "Escambia,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.61589,
              31.244458
            ],
            [
              -87.615417,
              31.041002
            ],
            [
              -87.615431,
              30.997361
            ],
            [
              -87.5988285301321,
              30.9974236243995
            ],
            [
              -87.571292,
              30.997836
            ],
            [
              -87.548555,
              30.997893
            ],
            [
              -87.519533,
              30.997552
            ],
            [
              -87.480258,
              30.998169
            ],
            [
              -87.479718,
              30.998164
            ],
            [
              -87.478721,
              30.99818
            ],
            [
              -87.466894,
              30.998145
            ],
            [
              -87.466842,
              30.998145
            ],
            [
              -87.461799,
              30.998168
            ],
            [
              -87.461654,
              30.998169
            ],
            [
              -87.458674,
              30.998353
            ],
            [
              -87.455721,
              30.998285
            ],
            [
              -87.449827,
              30.998239
            ],
            [
              -87.432309,
              30.998173
            ],
            [
              -87.425791,
              30.998058
            ],
            [
              -87.367862,
              30.99826
            ],
            [
              -87.364031,
              30.998187
            ],
            [
              -87.355677,
              30.998213
            ],
            [
              -87.333995,
              30.998241
            ],
            [
              -87.312206,
              30.998404
            ],
            [
              -87.304053,
              30.99816
            ],
            [
              -87.30159,
              30.998403
            ],
            [
              -87.291019,
              30.998322
            ],
            [
              -87.288929,
              30.998315
            ],
            [
              -87.265589,
              30.998237
            ],
            [
              -87.260565,
              30.998165
            ],
            [
              -87.259714,
              30.998142
            ],
            [
              -87.257985,
              30.998233
            ],
            [
              -87.257027,
              30.998164
            ],
            [
              -87.255617,
              30.998186
            ],
            [
              -87.255005,
              30.998255
            ],
            [
              -87.237711,
              30.996363
            ],
            [
              -87.2295403631042,
              30.996853061387498
            ],
            [
              -87.224773,
              30.997139
            ],
            [
              -87.1630788827798,
              30.999012791614096
            ],
            [
              -87.162644,
              30.999026
            ],
            [
              -87.140786,
              30.999503
            ],
            [
              -87.125,
              30.998774
            ],
            [
              -87.118905,
              30.999399
            ],
            [
              -87.068667,
              30.999115
            ],
            [
              -87.064097,
              30.999164
            ],
            [
              -87.053772,
              30.999104
            ],
            [
              -87.040024,
              30.999567
            ],
            [
              -87.036402,
              30.999321
            ],
            [
              -87.027143,
              30.999228
            ],
            [
              -87.004396,
              30.999289
            ],
            [
              -86.998514,
              30.998634
            ],
            [
              -86.927851,
              30.997678
            ],
            [
              -86.888178,
              30.997552
            ],
            [
              -86.873032,
              30.997606
            ],
            [
              -86.831979,
              30.997354
            ],
            [
              -86.830542,
              30.997377
            ],
            [
              -86.785965,
              30.996954
            ],
            [
              -86.78569442138259,
              30.9969494300445
            ],
            [
              -86.745289,
              30.996267
            ],
            [
              -86.728442,
              30.996716
            ],
            [
              -86.727343,
              30.996859
            ],
            [
              -86.725429,
              30.996849
            ],
            [
              -86.706312,
              30.99468
            ],
            [
              -86.68831401792629,
              30.994573472502697
            ],
            [
              -86.70022,
              31.000418
            ],
            [
              -86.700282,
              31.192217
            ],
            [
              -86.737189,
              31.187358
            ],
            [
              -86.756671,
              31.193158
            ],
            [
              -86.766368,
              31.182297
            ],
            [
              -86.772519,
              31.202243
            ],
            [
              -86.763763,
              31.220463
            ],
            [
              -86.772263,
              31.250655
            ],
            [
              -86.767537,
              31.261333
            ],
            [
              -86.871022,
              31.261902
            ],
            [
              -87.036472,
              31.261315
            ],
            [
              -87.427455,
              31.260386
            ],
            [
              -87.489206,
              31.260076
            ],
            [
              -87.52301,
              31.247911
            ],
            [
              -87.559287,
              31.227291
            ],
            [
              -87.61589,
              31.244458
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "01",
      "COUNTYFP": "043",
      "COUNTYNS": "00161547",
      "AFFGEOID": "0500000US01043",
      "GEOID": "01043",
      "NAME": "Cullman",
      "LSAD": "06",
      "ALAND": 1903070708,
      "AWATER": 52424813,
      "County_state": "Cullman,01"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -87.151036,
              33.993225
            ],
            [
              -87.13408,
              33.965093
            ],
            [
              -87.112377,
              33.959487
            ],
            [
              -87.104574,
              33.935216
            ],
            [
              -87.085584,
              33.919164
            ],
            [
              -87.081996,
              33.906401
            ],
            [
              -87.10081,
              33.898358
            ],
            [
              -87.091836,
              33.890093
            ],
            [
              -87.007077,
              33.888927
            ],
            [
              -87.006862,
              33.866179
            ],
            [
              -86.993987,
              33.860932
            ],
            [
              -86.963358,
              33.858221
            ],
            [
              -86.920198,
              33.873453
            ],
            [
              -86.924387,
              33.909222
            ],
            [
              -86.886022,
              33.917432
            ],
            [
              -86.877888,
              33.936301
            ],
            [
              -86.868171,
              33.932767
            ],
            [
              -86.836215,
              33.95062
            ],
            [
              -86.821315,
              33.941531
            ],
            [
              -86.820117,
              33.957526
            ],
            [
              -86.791756,
              33.952548
            ],
            [
              -86.791678,
              33.963558
            ],
            [
              -86.763999,
              33.974187
            ],
            [
              -86.760071,
              33.995934
            ],
            [
              -86.740378,
              34.000386
            ],
            [
              -86.736688,
              34.012006
            ],
            [
              -86.720577,
              34.032032
            ],
            [
              -86.701465,
              34.039633
            ],
            [
              -86.70095,
              34.060553
            ],
            [
              -86.685365,
              34.05914
            ],
            [
              -86.690581,
              34.063789
            ],
            [
              -86.691746,
              34.065116
            ],
            [
              -86.692769,
              34.067384
            ],
            [
              -86.693259,
              34.06818
            ],
            [
              -86.695045,
              34.070346
            ],
            [
              -86.696858,
              34.072268
            ],
            [
              -86.698743,
              34.075085
            ],
            [
              -86.700552,
              34.077516
            ],
            [
              -86.702052,
              34.078275
            ],
            [
              -86.704037,
              34.079718
            ],
            [
              -86.704538,
              34.080421
            ],
            [
              -86.704553,
              34.080916
            ],
            [
              -86.704244,
              34.081497
            ],
            [
              -86.702468,
              34.082779
            ],
            [
              -86.698115,
              34.085085
            ],
            [
              -86.695346,
              34.087137
            ],
            [
              -86.694429,
              34.088426
            ],
            [
              -86.693138,
              34.090787
            ],
            [
              -86.692474,
              34.092405
            ],
            [
              -86.691243,
              34.092718
            ],
            [
              -86.689278,
              34.092443
            ],
            [
              -86.688431,
              34.092596
            ],
            [
              -86.687751,
              34.091791
            ],
            [
              -86.687556,
              34.090663
            ],
            [
              -86.687651,
              34.089101
            ],
            [
              -86.688678,
              34.087047
            ],
            [
              -86.688703,
              34.08628
            ],
            [
              -86.687748,
              34.084064
            ],
            [
              -86.687697,
              34.080735
            ],
            [
              -86.687304,
              34.079989
            ],
            [
              -86.686576,
              34.079569
            ],
            [
              -86.685242,
              34.079858
            ],
            [
              -86.682884,
              34.082356
            ],
            [
              -86.681097,
              34.083288
            ],
            [
              -86.67727,
              34.084355
            ],
            [
              -86.675547,
              34.08535
            ],
            [
              -86.673993,
              34.087687
            ],
            [
              -86.673295,
              34.088215
            ],
            [
              -86.672603,
              34.088412
            ],
            [
              -86.67134,
              34.087927
            ],
            [
              -86.670721,
              34.087269
            ],
            [
              -86.670213,
              34.085812
            ],
            [
              -86.670426,
              34.084478
            ],
            [
              -86.669995,
              34.083845
            ],
            [
              -86.668956,
              34.083701
            ],
            [
              -86.667786,
              34.084415
            ],
            [
              -86.666664,
              34.085437
            ],
            [
              -86.664327,
              34.087035
            ],
            [
              -86.660258,
              34.089377
            ],
            [
              -86.658764,
              34.089997
            ],
            [
              -86.65682,
              34.091646
            ],
            [
              -86.653448,
              34.107223
            ],
            [
              -86.624127,
              34.122211
            ],
            [
              -86.601147,
              34.11904
            ],
            [
              -86.594729,
              34.13544
            ],
            [
              -86.567833,
              34.170221
            ],
            [
              -86.56385,
              34.170493
            ],
            [
              -86.545211,
              34.18727
            ],
            [
              -86.555796,
              34.189129
            ],
            [
              -86.539352,
              34.226006
            ],
            [
              -86.523387,
              34.227338
            ],
            [
              -86.518927,
              34.252321
            ],
            [
              -86.499658,
              34.248968
            ],
            [
              -86.485943,
              34.260185
            ],
            [
              -86.45302,
              34.259317
            ],
            [
              -86.459449,
              34.289148
            ],
            [
              -86.477525,
              34.302758
            ],
            [
              -86.54952,
              34.303743
            ],
            [
              -86.581936,
              34.304694
            ],
            [
              -86.814585,
              34.307761
            ],
            [
              -86.838659,
              34.308173
            ],
            [
              -87.09198,
              34.312296
            ],
            [
              -87.110111,
              34.313799
            ],
            [
              -87.109911,
              34.299299
            ],
            [
              -87.110199,
              34.228364
            ],
            [
              -87.110941,
              34.172554
            ],
            [
              -87.111992,
              33.992385
            ],
            [
              -87.151036,
              33.993225
            ]
          ]
        ]
      ]
    }
  }
]
};