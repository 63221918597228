export const counties_mn = {
  "type":"FeatureCollection","name":"mn_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "119",
      "COUNTYNS": "00659505",
      "AFFGEOID": "0500000US27119",
      "GEOID": "27119",
      "NAME": "Polk",
      "LSAD": "06",
      "ALAND": 5105067510,
      "AWATER": 69169913,
      "County_state": "Polk,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.146672,
              48.171484
            ],
            [
              -97.146745,
              48.168556
            ],
            [
              -97.144242,
              48.16249
            ],
            [
              -97.14195,
              48.160202
            ],
            [
              -97.139643,
              48.159111
            ],
            [
              -97.138911,
              48.157793
            ],
            [
              -97.138911,
              48.155304
            ],
            [
              -97.139497,
              48.153108
            ],
            [
              -97.140295,
              48.150894
            ],
            [
              -97.142279,
              48.148056
            ],
            [
              -97.142133,
              48.144981
            ],
            [
              -97.141401,
              48.14359
            ],
            [
              -97.134299,
              48.141833
            ],
            [
              -97.131956,
              48.139563
            ],
            [
              -97.13252,
              48.137641
            ],
            [
              -97.132176,
              48.135829
            ],
            [
              -97.129453,
              48.133133
            ],
            [
              -97.128279,
              48.127185
            ],
            [
              -97.126862,
              48.124277
            ],
            [
              -97.121586,
              48.116925
            ],
            [
              -97.120702,
              48.114987
            ],
            [
              -97.120592,
              48.113365
            ],
            [
              -97.12104,
              48.112281
            ],
            [
              -97.123135,
              48.109497
            ],
            [
              -97.123666,
              48.108004
            ],
            [
              -97.123205,
              48.106648
            ],
            [
              -97.119773,
              48.105381
            ],
            [
              -97.113194,
              48.106188
            ],
            [
              -97.11147,
              48.105913
            ],
            [
              -97.109535,
              48.104723
            ],
            [
              -97.108428,
              48.099824
            ],
            [
              -97.104872,
              48.097851
            ],
            [
              -97.10395,
              48.096184
            ],
            [
              -97.103879,
              48.094517
            ],
            [
              -97.105475,
              48.09278
            ],
            [
              -97.105616,
              48.091362
            ],
            [
              -97.105226,
              48.09044
            ],
            [
              -97.102165,
              48.089122
            ],
            [
              -97.099798,
              48.085884
            ],
            [
              -97.099431,
              48.082106
            ],
            [
              -97.100771,
              48.077452
            ],
            [
              -97.104154,
              48.074578
            ],
            [
              -97.104697,
              48.073094
            ],
            [
              -97.104483,
              48.072428
            ],
            [
              -97.103052,
              48.071669
            ],
            [
              -97.097772,
              48.07108
            ],
            [
              -97.086986,
              48.058222
            ],
            [
              -97.082895,
              48.055794
            ],
            [
              -97.075641,
              48.052725
            ],
            [
              -97.074015,
              48.051212
            ],
            [
              -97.072257,
              48.048068
            ],
            [
              -97.070411,
              48.041765
            ],
            [
              -97.068711,
              48.027694
            ],
            [
              -97.068987,
              48.026267
            ],
            [
              -97.071911,
              48.021395
            ],
            [
              -97.0721836195239,
              48.0194933125891
            ],
            [
              -97.072239,
              48.019107
            ],
            [
              -97.070654,
              48.016918
            ],
            [
              -97.069284,
              48.016176
            ],
            [
              -97.064927,
              48.015658
            ],
            [
              -97.063289,
              48.014989
            ],
            [
              -97.063012,
              48.013179
            ],
            [
              -97.065411,
              48.011337
            ],
            [
              -97.066762,
              48.009558
            ],
            [
              -97.064289,
              47.998508
            ],
            [
              -97.062257,
              47.995948
            ],
            [
              -97.054945,
              47.992924
            ],
            [
              -97.053553,
              47.991612
            ],
            [
              -97.053089,
              47.990252
            ],
            [
              -97.053537,
              47.987948
            ],
            [
              -97.056481,
              47.980556
            ],
            [
              -97.059153,
              47.97538
            ],
            [
              -97.059353,
              47.97398
            ],
            [
              -97.057153,
              47.97048
            ],
            [
              -97.057854,
              47.96898
            ],
            [
              -97.061554,
              47.96588
            ],
            [
              -97.061854,
              47.96448
            ],
            [
              -97.061454,
              47.96358
            ],
            [
              -97.059054,
              47.96208
            ],
            [
              -97.054054,
              47.959679
            ],
            [
              -97.052454,
              47.957179
            ],
            [
              -97.052554,
              47.954779
            ],
            [
              -97.055154,
              47.950779
            ],
            [
              -97.055554,
              47.949079
            ],
            [
              -97.054554,
              47.946279
            ],
            [
              -97.051054,
              47.943379
            ],
            [
              -97.044954,
              47.941079
            ],
            [
              -97.039154,
              47.940479
            ],
            [
              -97.036054,
              47.939379
            ],
            [
              -97.035554,
              47.936579
            ],
            [
              -97.037354,
              47.933279
            ],
            [
              -97.035754,
              47.930179
            ],
            [
              -97.029654,
              47.927578
            ],
            [
              -97.017754,
              47.919778
            ],
            [
              -97.018054,
              47.918078
            ],
            [
              -97.023754,
              47.915878
            ],
            [
              -97.02133187083248,
              47.9148346212817
            ],
            [
              -97.017254,
              47.913078
            ],
            [
              -97.015354,
              47.910278
            ],
            [
              -97.015054,
              47.907178
            ],
            [
              -97.017254,
              47.905678
            ],
            [
              -97.020355,
              47.906378
            ],
            [
              -97.023555,
              47.908478
            ],
            [
              -97.024955,
              47.908178
            ],
            [
              -97.024155,
              47.905278
            ],
            [
              -97.020255,
              47.902178
            ],
            [
              -97.020155,
              47.900478
            ],
            [
              -97.023955,
              47.898078
            ],
            [
              -97.024955,
              47.894978
            ],
            [
              -97.018955,
              47.891078
            ],
            [
              -97.019155,
              47.889778
            ],
            [
              -97.024955,
              47.886878
            ],
            [
              -97.025355,
              47.884278
            ],
            [
              -97.023355,
              47.882078
            ],
            [
              -97.019355,
              47.880278
            ],
            [
              -97.017955,
              47.878478
            ],
            [
              -97.018955,
              47.876878
            ],
            [
              -97.023156,
              47.874978
            ],
            [
              -97.023156,
              47.873978
            ],
            [
              -97.021256,
              47.872578
            ],
            [
              -97.017356,
              47.871578
            ],
            [
              -97.005356,
              47.870177
            ],
            [
              -97.002456,
              47.868677
            ],
            [
              -97.001556,
              47.867377
            ],
            [
              -97.003356,
              47.865877
            ],
            [
              -97.005857,
              47.865277
            ],
            [
              -97.005557,
              47.863977
            ],
            [
              -97.001759,
              47.861266
            ],
            [
              -97.000356,
              47.860915
            ],
            [
              -96.998144,
              47.858882
            ],
            [
              -96.996816,
              47.854405
            ],
            [
              -96.99643528050339,
              47.8459761061896
            ],
            [
              -96.996364,
              47.844398
            ],
            [
              -96.99789,
              47.843163
            ],
            [
              -96.998295,
              47.841724
            ],
            [
              -96.992963,
              47.837911
            ],
            [
              -96.986685,
              47.837639
            ],
            [
              -96.981725,
              47.830421
            ],
            [
              -96.982272,
              47.826668
            ],
            [
              -96.981683,
              47.825785
            ],
            [
              -96.979327,
              47.824533
            ],
            [
              -96.979327,
              47.821809
            ],
            [
              -96.980137,
              47.821441
            ],
            [
              -96.980726,
              47.820411
            ],
            [
              -96.980391,
              47.815662
            ],
            [
              -96.977946,
              47.811619
            ],
            [
              -96.978894,
              47.809882
            ],
            [
              -96.980947,
              47.808337
            ],
            [
              -96.981168,
              47.806792
            ],
            [
              -96.980579,
              47.805614
            ],
            [
              -96.976176,
              47.801544
            ],
            [
              -96.976088,
              47.799577
            ],
            [
              -96.975131,
              47.798326
            ],
            [
              -96.973585,
              47.797884
            ],
            [
              -96.971698,
              47.798255
            ],
            [
              -96.966068,
              47.797297
            ],
            [
              -96.963523,
              47.794601
            ],
            [
              -96.95786,
              47.792021
            ],
            [
              -96.957216,
              47.79097
            ],
            [
              -96.957283,
              47.790147
            ],
            [
              -96.961554,
              47.788707
            ],
            [
              -96.963521,
              47.78729
            ],
            [
              -96.96535,
              47.784937
            ],
            [
              -96.965316,
              47.783474
            ],
            [
              -96.9644,
              47.782995
            ],
            [
              -96.961926,
              47.783292
            ],
            [
              -96.956501,
              47.779798
            ],
            [
              -96.956635,
              47.776188
            ],
            [
              -96.949585,
              47.775228
            ],
            [
              -96.939179,
              47.768397
            ],
            [
              -96.936909,
              47.764536
            ],
            [
              -96.938435,
              47.762411
            ],
            [
              -96.937859,
              47.760195
            ],
            [
              -96.935555,
              47.758276
            ],
            [
              -96.932684,
              47.756804
            ],
            [
              -96.932648,
              47.755315
            ],
            [
              -96.934209,
              47.754517
            ],
            [
              -96.934463,
              47.752956
            ],
            [
              -96.934173,
              47.752412
            ],
            [
              -96.929051,
              47.750331
            ],
            [
              -96.928505,
              47.748037
            ],
            [
              -96.928506,
              47.744884
            ],
            [
              -96.929319,
              47.742988
            ],
            [
              -96.933011,
              47.739949
            ],
            [
              -96.933316,
              47.738716
            ],
            [
              -96.932809,
              47.737139
            ],
            [
              -96.930574,
              47.734352
            ],
            [
              -96.925089,
              47.729051
            ],
            [
              -96.919131,
              47.724731
            ],
            [
              -96.918556,
              47.723863
            ],
            [
              -96.919471,
              47.722515
            ],
            [
              -96.92348,
              47.719809
            ],
            [
              -96.923544,
              47.718201
            ],
            [
              -96.920391,
              47.716527
            ],
            [
              -96.919811,
              47.714339
            ],
            [
              -96.920321,
              47.712394
            ],
            [
              -96.920119,
              47.710383
            ],
            [
              -96.9155,
              47.707968
            ],
            [
              -96.914856,
              47.707003
            ],
            [
              -96.914405,
              47.704814
            ],
            [
              -96.915242,
              47.703527
            ],
            [
              -96.915242,
              47.702369
            ],
            [
              -96.913762,
              47.701468
            ],
            [
              -96.912846,
              47.701746
            ],
            [
              -96.911527,
              47.700512
            ],
            [
              -96.909769,
              47.697313
            ],
            [
              -96.907604,
              47.695119
            ],
            [
              -96.907266,
              47.693976
            ],
            [
              -96.910144,
              47.691235
            ],
            [
              -96.909909,
              47.689522
            ],
            [
              -96.908928,
              47.688722
            ],
            [
              -96.907236,
              47.688493
            ],
            [
              -96.905273,
              47.689246
            ],
            [
              -96.902971,
              47.691576
            ],
            [
              -96.901719,
              47.691621
            ],
            [
              -96.900264,
              47.690775
            ],
            [
              -96.899352,
              47.689473
            ],
            [
              -96.896724,
              47.674758
            ],
            [
              -96.895271,
              47.67357
            ],
            [
              -96.891922,
              47.673157
            ],
            [
              -96.8910416719539,
              47.672149192756
            ],
            [
              -96.889726,
              47.670643
            ],
            [
              -96.889627,
              47.668587
            ],
            [
              -96.887126,
              47.666369
            ],
            [
              -96.885573,
              47.663443
            ],
            [
              -96.88571,
              47.661547
            ],
            [
              -96.887607,
              47.658853
            ],
            [
              -96.88697,
              47.653049
            ],
            [
              -96.882882,
              47.650168
            ],
            [
              -96.882376,
              47.649025
            ],
            [
              -96.882857,
              47.641714
            ],
            [
              -96.884515,
              47.640755
            ],
            [
              -96.888166,
              47.63973
            ],
            [
              -96.888573,
              47.63845
            ],
            [
              -96.882393,
              47.633489
            ],
            [
              -96.879496,
              47.620576
            ],
            [
              -96.876355,
              47.619181
            ],
            [
              -96.870871,
              47.618042
            ],
            [
              -96.8706,
              47.617563
            ],
            [
              -96.871005,
              47.616832
            ],
            [
              -96.874078,
              47.614774
            ],
            [
              -96.873671,
              47.613654
            ],
            [
              -96.860255,
              47.612175
            ],
            [
              -96.857112,
              47.61076
            ],
            [
              -96.855421,
              47.60875
            ],
            [
              -96.854812,
              47.606328
            ],
            [
              -96.856903,
              47.602329
            ],
            [
              -96.855618,
              47.60089
            ],
            [
              -96.853785,
              47.599808
            ],
            [
              -96.852826,
              47.597891
            ],
            [
              -96.853114,
              47.596836
            ],
            [
              -96.854456,
              47.596261
            ],
            [
              -96.854743,
              47.594728
            ],
            [
              -96.851964,
              47.591469
            ],
            [
              -96.851293,
              47.589264
            ],
            [
              -96.853273,
              47.579483
            ],
            [
              -96.856373,
              47.575749
            ],
            [
              -96.855894,
              47.573352
            ],
            [
              -96.854073,
              47.57201
            ],
            [
              -96.853689,
              47.570381
            ],
            [
              -96.856661,
              47.567889
            ],
            [
              -96.858769,
              47.56741
            ],
            [
              -96.859153,
              47.566355
            ],
            [
              -96.858673,
              47.564534
            ],
            [
              -96.857236,
              47.564055
            ],
            [
              -96.856852,
              47.563288
            ],
            [
              -96.857427,
              47.561658
            ],
            [
              -96.859153,
              47.559741
            ],
            [
              -96.859057,
              47.558591
            ],
            [
              -96.858002,
              47.556578
            ],
            [
              -96.855092,
              47.554598
            ],
            [
              -96.853755,
              47.552497
            ],
            [
              -96.854328,
              47.550491
            ],
            [
              -96.856238,
              47.548963
            ],
            [
              -96.85662,
              47.548103
            ],
            [
              -96.856429,
              47.546957
            ],
            [
              -96.854423,
              47.545333
            ],
            [
              -96.854232,
              47.544665
            ],
            [
              -96.854614,
              47.54285
            ],
            [
              -96.856716,
              47.540271
            ],
            [
              -96.856429,
              47.538456
            ],
            [
              -96.855092,
              47.53731
            ],
            [
              -96.85471,
              47.535973
            ],
            [
              -96.860524,
              47.529536
            ],
            [
              -96.862379,
              47.529055
            ],
            [
              -96.864739,
              47.527663
            ],
            [
              -96.866363,
              47.525944
            ],
            [
              -96.866363,
              47.524893
            ],
            [
              -96.863551,
              47.520304
            ],
            [
              -96.863245,
              47.517266
            ],
            [
              -96.861422,
              47.515873
            ],
            [
              -96.858454,
              47.514892
            ],
            [
              -96.854204,
              47.514368
            ],
            [
              -96.853468,
              47.513813
            ],
            [
              -96.853181,
              47.511425
            ],
            [
              -96.851749,
              47.510088
            ],
            [
              -96.851367,
              47.509037
            ],
            [
              -96.851749,
              47.507891
            ],
            [
              -96.853052,
              47.506828
            ],
            [
              -96.853286,
              47.503881
            ],
            [
              -96.853317,
              47.501322
            ],
            [
              -96.851844,
              47.49939
            ],
            [
              -96.8517908344828,
              47.498752013793094
            ],
            [
              -96.067621,
              47.499136
            ],
            [
              -95.562897,
              47.500271
            ],
            [
              -95.554248,
              47.499893
            ],
            [
              -95.553392,
              47.67361
            ],
            [
              -95.578839,
              47.673677
            ],
            [
              -95.582155,
              47.933966
            ],
            [
              -95.709619,
              47.935805
            ],
            [
              -95.709148,
              47.848594
            ],
            [
              -95.836827,
              47.848219
            ],
            [
              -95.837177,
              47.760894
            ],
            [
              -96.351727,
              47.7603
            ],
            [
              -96.352708,
              47.847328
            ],
            [
              -96.482092,
              47.847428
            ],
            [
              -96.48255,
              47.963595
            ],
            [
              -96.482586,
              48.021673
            ],
            [
              -96.500901,
              48.021656
            ],
            [
              -96.501028,
              48.108693
            ],
            [
              -96.500843,
              48.174108
            ],
            [
              -97.1457019134364,
              48.1732232314736
            ],
            [
              -97.146672,
              48.171484
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "125",
      "COUNTYNS": "00659508",
      "AFFGEOID": "0500000US27125",
      "GEOID": "27125",
      "NAME": "Red Lake",
      "LSAD": "06",
      "ALAND": 1119929328,
      "AWATER": 227815,
      "County_state": "Red Lake,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.48255,
              47.963595
            ],
            [
              -96.482092,
              47.847428
            ],
            [
              -96.352708,
              47.847328
            ],
            [
              -96.351727,
              47.7603
            ],
            [
              -95.837177,
              47.760894
            ],
            [
              -95.836827,
              47.848219
            ],
            [
              -95.709148,
              47.848594
            ],
            [
              -95.709619,
              47.935805
            ],
            [
              -95.70984,
              47.96433
            ],
            [
              -96.48255,
              47.963595
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "093",
      "COUNTYNS": "00659492",
      "AFFGEOID": "0500000US27093",
      "GEOID": "27093",
      "NAME": "Meeker",
      "LSAD": "06",
      "ALAND": 1575108557,
      "AWATER": 94852453,
      "County_state": "Meeker,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.76308,
              45.3261
            ],
            [
              -94.763306,
              45.239089
            ],
            [
              -94.758189,
              44.892097
            ],
            [
              -94.502395,
              44.892295
            ],
            [
              -94.501885,
              44.979695
            ],
            [
              -94.256074,
              44.979465
            ],
            [
              -94.256,
              45.066323
            ],
            [
              -94.255775,
              45.152642
            ],
            [
              -94.256681,
              45.239272
            ],
            [
              -94.260821,
              45.283941
            ],
            [
              -94.383449,
              45.282797
            ],
            [
              -94.383744,
              45.326727
            ],
            [
              -94.53537,
              45.326104
            ],
            [
              -94.556913,
              45.325892
            ],
            [
              -94.640096,
              45.325601
            ],
            [
              -94.76308,
              45.3261
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "059",
      "COUNTYNS": "00659475",
      "AFFGEOID": "0500000US27059",
      "GEOID": "27059",
      "NAME": "Isanti",
      "LSAD": "06",
      "ALAND": 1128567937,
      "AWATER": 41166465,
      "County_state": "Isanti,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.513683,
              45.734318
            ],
            [
              -93.510704,
              45.558772
            ],
            [
              -93.510589,
              45.471879
            ],
            [
              -93.51007,
              45.414798
            ],
            [
              -93.265467,
              45.413698
            ],
            [
              -93.162917,
              45.414212
            ],
            [
              -93.142242,
              45.413645
            ],
            [
              -93.093271,
              45.413778
            ],
            [
              -93.019563,
              45.41177
            ],
            [
              -93.021689,
              45.470396
            ],
            [
              -93.022274,
              45.557154
            ],
            [
              -93.141974,
              45.558109
            ],
            [
              -93.141793,
              45.730657
            ],
            [
              -93.388982,
              45.730717
            ],
            [
              -93.513683,
              45.734318
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "133",
      "COUNTYNS": "00659512",
      "AFFGEOID": "0500000US27133",
      "GEOID": "27133",
      "NAME": "Rock",
      "LSAD": "06",
      "ALAND": 1249560774,
      "AWATER": 698231,
      "County_state": "Rock,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.453408,
              43.675008
            ],
            [
              -96.453387,
              43.609944
            ],
            [
              -96.453356,
              43.607544
            ],
            [
              -96.453383,
              43.588183
            ],
            [
              -96.453352,
              43.58704
            ],
            [
              -96.45326,
              43.50039
            ],
            [
              -96.351059,
              43.500333
            ],
            [
              -96.332062,
              43.500415
            ],
            [
              -96.208814,
              43.500391
            ],
            [
              -96.198766,
              43.500312
            ],
            [
              -96.198484,
              43.500335
            ],
            [
              -96.0531625367105,
              43.500176036071394
            ],
            [
              -96.05264,
              43.76106
            ],
            [
              -96.05232,
              43.84907
            ],
            [
              -96.06462,
              43.849039
            ],
            [
              -96.45326361188069,
              43.8495059094739
            ],
            [
              -96.45326359312119,
              43.849501168336694
            ],
            [
              -96.453088,
              43.805123
            ],
            [
              -96.453281,
              43.791435
            ],
            [
              -96.45338,
              43.689637
            ],
            [
              -96.453408,
              43.675008
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "019",
      "COUNTYNS": "00659455",
      "AFFGEOID": "0500000US27019",
      "GEOID": "27019",
      "NAME": "Carver",
      "LSAD": "06",
      "ALAND": 917348283,
      "AWATER": 56768380,
      "County_state": "Carver,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.012236,
              44.978712
            ],
            [
              -94.009864,
              44.891898
            ],
            [
              -94.010494,
              44.717464
            ],
            [
              -93.888636,
              44.717044
            ],
            [
              -93.888831,
              44.673409
            ],
            [
              -93.768176,
              44.67352
            ],
            [
              -93.768039,
              44.640303
            ],
            [
              -93.755735,
              44.634907
            ],
            [
              -93.747339,
              44.645263
            ],
            [
              -93.698273,
              44.669446
            ],
            [
              -93.667961,
              44.695259
            ],
            [
              -93.643947,
              44.692662
            ],
            [
              -93.618067,
              44.707773
            ],
            [
              -93.626735,
              44.718462
            ],
            [
              -93.631542,
              44.738055
            ],
            [
              -93.621967,
              44.760281
            ],
            [
              -93.594234,
              44.776215
            ],
            [
              -93.599813,
              44.782347
            ],
            [
              -93.54123,
              44.804561
            ],
            [
              -93.520431,
              44.804263
            ],
            [
              -93.520632,
              44.858562
            ],
            [
              -93.520813,
              44.866077
            ],
            [
              -93.520717,
              44.888557
            ],
            [
              -93.523355,
              44.891457
            ],
            [
              -93.580704,
              44.891162
            ],
            [
              -93.645317,
              44.891058
            ],
            [
              -93.663986,
              44.891438
            ],
            [
              -93.683722,
              44.890934
            ],
            [
              -93.767041,
              44.890862
            ],
            [
              -93.766442,
              44.974462
            ],
            [
              -93.767367,
              44.977964
            ],
            [
              -94.012236,
              44.978712
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "067",
      "COUNTYNS": "00659479",
      "AFFGEOID": "0500000US27067",
      "GEOID": "27067",
      "NAME": "Kandiyohi",
      "LSAD": "06",
      "ALAND": 2064978211,
      "AWATER": 168506888,
      "County_state": "Kandiyohi,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.255206,
              45.412525
            ],
            [
              -95.255196,
              45.238712
            ],
            [
              -95.246654,
              45.238683
            ],
            [
              -95.24649,
              45.152384
            ],
            [
              -95.246906,
              45.065384
            ],
            [
              -95.24852,
              44.89131
            ],
            [
              -94.884682,
              44.892322
            ],
            [
              -94.758189,
              44.892097
            ],
            [
              -94.763306,
              45.239089
            ],
            [
              -94.76308,
              45.3261
            ],
            [
              -94.762934,
              45.412861
            ],
            [
              -95.13173,
              45.412402
            ],
            [
              -95.255206,
              45.412525
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "033",
      "COUNTYNS": "00659462",
      "AFFGEOID": "0500000US27033",
      "GEOID": "27033",
      "NAME": "Cottonwood",
      "LSAD": "06",
      "ALAND": 1657445964,
      "AWATER": 22318968,
      "County_state": "Cottonwood,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.462547,
              44.195942
            ],
            [
              -95.462427,
              43.847911
            ],
            [
              -95.452502,
              43.847953
            ],
            [
              -95.100586,
              43.847937
            ],
            [
              -95.095391,
              43.847927
            ],
            [
              -94.859388,
              43.848093
            ],
            [
              -94.859803,
              44.108016
            ],
            [
              -95.100221,
              44.108034
            ],
            [
              -95.100998,
              44.194938
            ],
            [
              -95.108777,
              44.195044
            ],
            [
              -95.121234,
              44.195274
            ],
            [
              -95.229985,
              44.195264
            ],
            [
              -95.462547,
              44.195942
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "047",
      "COUNTYNS": "00659469",
      "AFFGEOID": "0500000US27047",
      "GEOID": "27047",
      "NAME": "Freeborn",
      "LSAD": "06",
      "ALAND": 1831351973,
      "AWATER": 39709692,
      "County_state": "Freeborn,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.64829,
              43.848139
            ],
            [
              -93.6485330413135,
              43.4995586947376
            ],
            [
              -93.617131,
              43.499548
            ],
            [
              -93.576728,
              43.49952
            ],
            [
              -93.558631,
              43.499521
            ],
            [
              -93.532178,
              43.499472
            ],
            [
              -93.528482,
              43.499471
            ],
            [
              -93.497405,
              43.499456
            ],
            [
              -93.4973499498203,
              43.499455765205894
            ],
            [
              -93.488261,
              43.499417
            ],
            [
              -93.482009,
              43.499482
            ],
            [
              -93.472804,
              43.4994
            ],
            [
              -93.468563,
              43.499473
            ],
            [
              -93.428509,
              43.499478
            ],
            [
              -93.399035,
              43.499485
            ],
            [
              -93.2718,
              43.499356
            ],
            [
              -93.228861,
              43.499567
            ],
            [
              -93.16917299993789,
              43.4995684598497
            ],
            [
              -93.04919199673229,
              43.4995713943464
            ],
            [
              -93.049331,
              43.586809
            ],
            [
              -93.049452,
              43.760959
            ],
            [
              -93.049524,
              43.848443
            ],
            [
              -93.406642,
              43.84812
            ],
            [
              -93.64829,
              43.848139
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "009",
      "COUNTYNS": "00659450",
      "AFFGEOID": "0500000US27009",
      "GEOID": "27009",
      "NAME": "Benton",
      "LSAD": "06",
      "ALAND": 1057483363,
      "AWATER": 12286992,
      "County_state": "Benton,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.353165,
              45.822131
            ],
            [
              -94.327853,
              45.79861
            ],
            [
              -94.31323,
              45.800071
            ],
            [
              -94.312007,
              45.790589
            ],
            [
              -94.271776,
              45.775208
            ],
            [
              -94.259727,
              45.742357
            ],
            [
              -94.215712,
              45.72656
            ],
            [
              -94.224689,
              45.715319
            ],
            [
              -94.203049,
              45.678153
            ],
            [
              -94.1823,
              45.678854
            ],
            [
              -94.198068,
              45.668642
            ],
            [
              -94.203859,
              45.647386
            ],
            [
              -94.203666,
              45.619556
            ],
            [
              -94.193638,
              45.602929
            ],
            [
              -94.168828,
              45.586257
            ],
            [
              -94.165623,
              45.574243
            ],
            [
              -94.160782,
              45.566362
            ],
            [
              -94.149467,
              45.559066
            ],
            [
              -94.006878,
              45.559232
            ],
            [
              -93.759435,
              45.559646
            ],
            [
              -93.763948,
              45.822143
            ],
            [
              -94.010432,
              45.823981
            ],
            [
              -94.353165,
              45.822131
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "041",
      "COUNTYNS": "00659466",
      "AFFGEOID": "0500000US27041",
      "GEOID": "27041",
      "NAME": "Douglas",
      "LSAD": "06",
      "ALAND": 1649200283,
      "AWATER": 215604217,
      "County_state": "Douglas,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.76975,
              46.10745
            ],
            [
              -95.76956,
              45.93368
            ],
            [
              -95.758701,
              45.933692
            ],
            [
              -95.758508,
              45.759932
            ],
            [
              -95.509894,
              45.758786
            ],
            [
              -95.409538,
              45.759845
            ],
            [
              -95.13967,
              45.758891
            ],
            [
              -95.139731,
              45.773416
            ],
            [
              -95.140166,
              45.846064
            ],
            [
              -95.146255,
              45.9328
            ],
            [
              -95.14588,
              46.106761
            ],
            [
              -95.520733,
              46.107835
            ],
            [
              -95.76975,
              46.10745
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "101",
      "COUNTYNS": "00659496",
      "AFFGEOID": "0500000US27101",
      "GEOID": "27101",
      "NAME": "Murray",
      "LSAD": "06",
      "ALAND": 1825163785,
      "AWATER": 38338284,
      "County_state": "Murray,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.063778,
              44.196425
            ],
            [
              -96.06462,
              43.849039
            ],
            [
              -96.05232,
              43.84907
            ],
            [
              -95.811764,
              43.848237
            ],
            [
              -95.462427,
              43.847911
            ],
            [
              -95.462547,
              44.195942
            ],
            [
              -95.59357,
              44.19553
            ],
            [
              -96.063778,
              44.196425
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "117",
      "COUNTYNS": "00659504",
      "AFFGEOID": "0500000US27117",
      "GEOID": "27117",
      "NAME": "Pipestone",
      "LSAD": "06",
      "ALAND": 1204488112,
      "AWATER": 2348855,
      "County_state": "Pipestone,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.453405,
              44.025413
            ],
            [
              -96.453373,
              44.023744
            ],
            [
              -96.453053,
              44.008887
            ],
            [
              -96.453116,
              44.006876
            ],
            [
              -96.453297,
              43.994723
            ],
            [
              -96.453328,
              43.992871
            ],
            [
              -96.453263,
              43.980277
            ],
            [
              -96.453389,
              43.97806
            ],
            [
              -96.453292,
              43.96718
            ],
            [
              -96.453165,
              43.96654
            ],
            [
              -96.453289,
              43.950814
            ],
            [
              -96.453352,
              43.949122
            ],
            [
              -96.453183,
              43.87865
            ],
            [
              -96.453304,
              43.878583
            ],
            [
              -96.453335,
              43.877029
            ],
            [
              -96.453264,
              43.849604
            ],
            [
              -96.45326361188069,
              43.8495059094739
            ],
            [
              -96.06462,
              43.849039
            ],
            [
              -96.063778,
              44.196425
            ],
            [
              -96.07858,
              44.19662
            ],
            [
              -96.45277429961769,
              44.196779980888
            ],
            [
              -96.453187,
              44.03835
            ],
            [
              -96.453313,
              44.03643
            ],
            [
              -96.453405,
              44.025413
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "017",
      "COUNTYNS": "00659454",
      "AFFGEOID": "0500000US27017",
      "GEOID": "27017",
      "NAME": "Carlton",
      "LSAD": "06",
      "ALAND": 2230821123,
      "AWATER": 36133708,
      "County_state": "Carlton,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.064867,
              46.677801
            ],
            [
              -93.062678,
              46.59331
            ],
            [
              -93.055844,
              46.579055
            ],
            [
              -93.054847,
              46.419269
            ],
            [
              -92.803169,
              46.418408
            ],
            [
              -92.553163,
              46.418097
            ],
            [
              -92.29286,
              46.41722
            ],
            [
              -92.292847,
              46.420876
            ],
            [
              -92.292727,
              46.431993
            ],
            [
              -92.29251,
              46.478761
            ],
            [
              -92.292371,
              46.495585
            ],
            [
              -92.291976,
              46.503997
            ],
            [
              -92.291647,
              46.604649
            ],
            [
              -92.291597,
              46.624941
            ],
            [
              -92.292192,
              46.663242
            ],
            [
              -92.292192,
              46.663318
            ],
            [
              -92.301092,
              46.666941
            ],
            [
              -92.301105,
              46.689413
            ],
            [
              -92.301676,
              46.764134
            ],
            [
              -92.428463,
              46.764827
            ],
            [
              -92.638393,
              46.766844
            ],
            [
              -92.658694,
              46.769244
            ],
            [
              -92.808646,
              46.766321
            ],
            [
              -93.061529,
              46.76655
            ],
            [
              -93.064867,
              46.677801
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "001",
      "COUNTYNS": "00663198",
      "AFFGEOID": "0500000US27001",
      "GEOID": "27001",
      "NAME": "Aitkin",
      "LSAD": "06",
      "ALAND": 4718271444,
      "AWATER": 449535492,
      "County_state": "Aitkin,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.811464,
              46.584238
            ],
            [
              -93.810357,
              46.250925
            ],
            [
              -93.796281,
              46.243199
            ],
            [
              -93.430648,
              46.246412
            ],
            [
              -93.42963,
              46.18171
            ],
            [
              -93.431831,
              46.153991
            ],
            [
              -93.284475,
              46.159443
            ],
            [
              -93.178985,
              46.15855
            ],
            [
              -93.053871,
              46.15812
            ],
            [
              -93.054847,
              46.419269
            ],
            [
              -93.055844,
              46.579055
            ],
            [
              -93.062678,
              46.59331
            ],
            [
              -93.064867,
              46.677801
            ],
            [
              -93.061529,
              46.76655
            ],
            [
              -93.055943,
              47.026415
            ],
            [
              -93.191277,
              47.025194
            ],
            [
              -93.316728,
              47.028284
            ],
            [
              -93.775257,
              47.030418
            ],
            [
              -93.776024,
              46.802891
            ],
            [
              -93.777905,
              46.589671
            ],
            [
              -93.811464,
              46.584238
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "131",
      "COUNTYNS": "00659511",
      "AFFGEOID": "0500000US27131",
      "GEOID": "27131",
      "NAME": "Rice",
      "LSAD": "06",
      "ALAND": 1284224027,
      "AWATER": 52057387,
      "County_state": "Rice,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.5252,
              44.282837
            ],
            [
              -93.525231,
              44.196134
            ],
            [
              -93.406565,
              44.196377
            ],
            [
              -93.286479,
              44.196479
            ],
            [
              -93.162285,
              44.196568
            ],
            [
              -93.04591,
              44.196716
            ],
            [
              -93.041159,
              44.196726
            ],
            [
              -93.040237,
              44.370979
            ],
            [
              -93.039485,
              44.471871
            ],
            [
              -93.281686,
              44.471998
            ],
            [
              -93.281521,
              44.543957
            ],
            [
              -93.357704,
              44.544006
            ],
            [
              -93.524327,
              44.543618
            ],
            [
              -93.5252,
              44.282837
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "165",
      "COUNTYNS": "00659527",
      "AFFGEOID": "0500000US27165",
      "GEOID": "27165",
      "NAME": "Watonwan",
      "LSAD": "06",
      "ALAND": 1126526382,
      "AWATER": 12370135,
      "County_state": "Watonwan,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.859803,
              44.108016
            ],
            [
              -94.859388,
              43.848093
            ],
            [
              -94.854444,
              43.848099
            ],
            [
              -94.368974,
              43.848047
            ],
            [
              -94.369018,
              43.934939
            ],
            [
              -94.36885,
              44.108698
            ],
            [
              -94.738344,
              44.108898
            ],
            [
              -94.859803,
              44.108016
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "111",
      "COUNTYNS": "00659501",
      "AFFGEOID": "0500000US27111",
      "GEOID": "27111",
      "NAME": "Otter Tail",
      "LSAD": "06",
      "ALAND": 5107436184,
      "AWATER": 654101087,
      "County_state": "Otter Tail,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.280977,
              46.630765
            ],
            [
              -96.28,
              46.28222
            ],
            [
              -96.264647,
              46.282196
            ],
            [
              -96.265366,
              46.10861
            ],
            [
              -95.76975,
              46.10745
            ],
            [
              -95.520733,
              46.107835
            ],
            [
              -95.14588,
              46.106761
            ],
            [
              -95.145791,
              46.282368
            ],
            [
              -95.154256,
              46.282428
            ],
            [
              -95.15557,
              46.36888
            ],
            [
              -95.15554,
              46.62994
            ],
            [
              -95.163392,
              46.629938
            ],
            [
              -95.16371,
              46.71764
            ],
            [
              -95.435464,
              46.715917
            ],
            [
              -95.48856,
              46.717539
            ],
            [
              -95.542719,
              46.71595
            ],
            [
              -95.794775,
              46.71641
            ],
            [
              -96.174587,
              46.717854
            ],
            [
              -96.17227,
              46.630709
            ],
            [
              -96.280977,
              46.630765
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "113",
      "COUNTYNS": "00659502",
      "AFFGEOID": "0500000US27113",
      "GEOID": "27113",
      "NAME": "Pennington",
      "LSAD": "06",
      "ALAND": 1596928760,
      "AWATER": 4332057,
      "County_state": "Pennington,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.501028,
              48.108693
            ],
            [
              -96.500901,
              48.021656
            ],
            [
              -96.482586,
              48.021673
            ],
            [
              -96.48255,
              47.963595
            ],
            [
              -95.70984,
              47.96433
            ],
            [
              -95.709619,
              47.935805
            ],
            [
              -95.582155,
              47.933966
            ],
            [
              -95.582887,
              48.020557
            ],
            [
              -95.592487,
              48.020558
            ],
            [
              -95.593788,
              48.172916
            ],
            [
              -95.981727,
              48.171686
            ],
            [
              -96.108454,
              48.171796
            ],
            [
              -96.173639,
              48.173695
            ],
            [
              -96.239181,
              48.172641
            ],
            [
              -96.261895,
              48.174908
            ],
            [
              -96.500843,
              48.174108
            ],
            [
              -96.501028,
              48.108693
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "087",
      "COUNTYNS": "00659488",
      "AFFGEOID": "0500000US27087",
      "GEOID": "27087",
      "NAME": "Mahnomen",
      "LSAD": "06",
      "ALAND": 1444886580,
      "AWATER": 64851262,
      "County_state": "Mahnomen,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.067621,
              47.499136
            ],
            [
              -96.067069,
              47.151813
            ],
            [
              -95.551186,
              47.151453
            ],
            [
              -95.551458,
              47.50002
            ],
            [
              -95.554248,
              47.499893
            ],
            [
              -95.562897,
              47.500271
            ],
            [
              -96.067621,
              47.499136
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "135",
      "COUNTYNS": "00659513",
      "AFFGEOID": "0500000US27135",
      "GEOID": "27135",
      "NAME": "Roseau",
      "LSAD": "06",
      "ALAND": 4329471930,
      "AWATER": 16913419,
      "County_state": "Roseau,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.405408,
              48.999984
            ],
            [
              -96.404924,
              48.71707
            ],
            [
              -96.387472,
              48.7171
            ],
            [
              -96.387835,
              48.544308
            ],
            [
              -95.602315,
              48.538887
            ],
            [
              -95.34254,
              48.540211
            ],
            [
              -95.34273,
              48.713028
            ],
            [
              -95.089863,
              48.713062
            ],
            [
              -95.091464,
              48.920618
            ],
            [
              -95.138145,
              48.894833
            ],
            [
              -95.235606,
              48.88145
            ],
            [
              -95.254859,
              48.884474
            ],
            [
              -95.30104,
              48.904216
            ],
            [
              -95.32264,
              48.935683
            ],
            [
              -95.317731,
              48.952444
            ],
            [
              -95.326758,
              48.982076
            ],
            [
              -95.31989470776959,
              48.998769452068096
            ],
            [
              -95.322946,
              48.998767
            ],
            [
              -95.340962,
              48.99874
            ],
            [
              -95.355819,
              48.998735
            ],
            [
              -95.368698,
              48.998729
            ],
            [
              -95.97539,
              48.999984
            ],
            [
              -96.405408,
              48.999984
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "091",
      "COUNTYNS": "00659490",
      "AFFGEOID": "0500000US27091",
      "GEOID": "27091",
      "NAME": "Martin",
      "LSAD": "06",
      "ALAND": 1844947511,
      "AWATER": 44649915,
      "County_state": "Martin,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.854444,
              43.848099
            ],
            [
              -94.854555091254,
              43.5006140281275
            ],
            [
              -94.615916,
              43.500544
            ],
            [
              -94.565665,
              43.50033
            ],
            [
              -94.560838,
              43.500377
            ],
            [
              -94.47042,
              43.50034
            ],
            [
              -94.447048,
              43.500639
            ],
            [
              -94.4428478576855,
              43.5005831709068
            ],
            [
              -94.442835,
              43.500583
            ],
            [
              -94.390597,
              43.500469
            ],
            [
              -94.377466,
              43.500379
            ],
            [
              -94.2479653766318,
              43.5003325399466
            ],
            [
              -94.247123,
              43.847946
            ],
            [
              -94.368974,
              43.848047
            ],
            [
              -94.854444,
              43.848099
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "007",
      "COUNTYNS": "00659449",
      "AFFGEOID": "0500000US27007",
      "GEOID": "27007",
      "NAME": "Beltrami",
      "LSAD": "06",
      "ALAND": 6487120495,
      "AWATER": 1426321738,
      "County_state": "Beltrami,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.602315,
              48.538887
            ],
            [
              -95.600743,
              48.495592
            ],
            [
              -95.601978,
              48.36543
            ],
            [
              -95.593199,
              48.365445
            ],
            [
              -95.593788,
              48.172916
            ],
            [
              -95.592487,
              48.020558
            ],
            [
              -95.582887,
              48.020557
            ],
            [
              -95.229133,
              48.019958
            ],
            [
              -95.246849,
              48.005321
            ],
            [
              -95.272566,
              47.960205
            ],
            [
              -95.2771,
              47.943691
            ],
            [
              -95.269705,
              47.903875
            ],
            [
              -95.24632,
              47.883266
            ],
            [
              -95.193705,
              47.872544
            ],
            [
              -95.194304,
              47.67337
            ],
            [
              -95.183313,
              47.499438
            ],
            [
              -95.183216,
              47.412773
            ],
            [
              -94.927526,
              47.41173
            ],
            [
              -94.799934,
              47.409848
            ],
            [
              -94.68316,
              47.409988
            ],
            [
              -94.670214,
              47.410151
            ],
            [
              -94.416387,
              47.41183
            ],
            [
              -94.416279,
              47.444828
            ],
            [
              -94.41428,
              47.586491
            ],
            [
              -94.416317,
              47.593873
            ],
            [
              -94.418543,
              47.845816
            ],
            [
              -94.417747,
              47.93311
            ],
            [
              -94.41776,
              48.019811
            ],
            [
              -94.42361,
              48.019808
            ],
            [
              -94.428702,
              48.367333
            ],
            [
              -95.210953,
              48.365971
            ],
            [
              -95.211966,
              48.539961
            ],
            [
              -95.34254,
              48.540211
            ],
            [
              -95.602315,
              48.538887
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "137",
      "COUNTYNS": "00662850",
      "AFFGEOID": "0500000US27137",
      "GEOID": "27137",
      "NAME": "St. Louis",
      "LSAD": "06",
      "ALAND": 16181092598,
      "AWATER": 1584976832,
      "County_state": "St. Louis,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.095175,
              48.249922
            ],
            [
              -93.096488,
              48.067826
            ],
            [
              -93.088984,
              48.067923
            ],
            [
              -93.088261,
              47.978609
            ],
            [
              -93.081158,
              47.89187
            ],
            [
              -93.083724,
              47.806353
            ],
            [
              -93.082187,
              47.720382
            ],
            [
              -93.061253,
              47.720187
            ],
            [
              -93.065087,
              47.499932
            ],
            [
              -93.06882,
              47.45789
            ],
            [
              -93.0695,
              47.393915
            ],
            [
              -93.06471,
              47.371575
            ],
            [
              -93.066245,
              47.109319
            ],
            [
              -93.056187,
              47.109279
            ],
            [
              -93.055943,
              47.026415
            ],
            [
              -93.061529,
              46.76655
            ],
            [
              -92.808646,
              46.766321
            ],
            [
              -92.658694,
              46.769244
            ],
            [
              -92.638393,
              46.766844
            ],
            [
              -92.428463,
              46.764827
            ],
            [
              -92.301676,
              46.764134
            ],
            [
              -92.301105,
              46.689413
            ],
            [
              -92.301092,
              46.666941
            ],
            [
              -92.292192,
              46.663318
            ],
            [
              -92.292192,
              46.666042
            ],
            [
              -92.291292,
              46.668142
            ],
            [
              -92.287392,
              46.667342
            ],
            [
              -92.287092,
              46.662842
            ],
            [
              -92.286192,
              46.660342
            ],
            [
              -92.283692,
              46.658841
            ],
            [
              -92.278492,
              46.658641
            ],
            [
              -92.274392,
              46.657441
            ],
            [
              -92.272792,
              46.652841
            ],
            [
              -92.270592,
              46.650741
            ],
            [
              -92.265993,
              46.651041
            ],
            [
              -92.259692,
              46.657141
            ],
            [
              -92.256592,
              46.658741
            ],
            [
              -92.242493,
              46.649241
            ],
            [
              -92.235592,
              46.650041
            ],
            [
              -92.228492,
              46.652941
            ],
            [
              -92.223492,
              46.652641
            ],
            [
              -92.216392,
              46.649841
            ],
            [
              -92.212392,
              46.649941
            ],
            [
              -92.207092,
              46.651941
            ],
            [
              -92.202292,
              46.655041
            ],
            [
              -92.201592,
              46.656641
            ],
            [
              -92.202192,
              46.658941
            ],
            [
              -92.205492,
              46.664741
            ],
            [
              -92.204092,
              46.666941
            ],
            [
              -92.199492,
              46.670241
            ],
            [
              -92.192492,
              46.676741
            ],
            [
              -92.187592,
              46.678941
            ],
            [
              -92.181391,
              46.680241
            ],
            [
              -92.177591,
              46.683441
            ],
            [
              -92.176091,
              46.686341
            ],
            [
              -92.176491,
              46.690241
            ],
            [
              -92.177891,
              46.691841
            ],
            [
              -92.183091,
              46.695241
            ],
            [
              -92.198491,
              46.696141
            ],
            [
              -92.205192,
              46.698341
            ],
            [
              -92.205692,
              46.702541
            ],
            [
              -92.204691,
              46.704041
            ],
            [
              -92.201591,
              46.705941
            ],
            [
              -92.197391,
              46.707641
            ],
            [
              -92.193291,
              46.711241
            ],
            [
              -92.191491,
              46.716241
            ],
            [
              -92.189091,
              46.717541
            ],
            [
              -92.178891,
              46.716741
            ],
            [
              -92.174291,
              46.717241
            ],
            [
              -92.167291,
              46.719941
            ],
            [
              -92.155191,
              46.71594
            ],
            [
              -92.148691,
              46.71514
            ],
            [
              -92.146291,
              46.71594
            ],
            [
              -92.141291,
              46.72524
            ],
            [
              -92.143391,
              46.72814
            ],
            [
              -92.14329,
              46.73464
            ],
            [
              -92.13789,
              46.73954
            ],
            [
              -92.11659,
              46.74864
            ],
            [
              -92.10819,
              46.74914
            ],
            [
              -92.08949,
              46.74924
            ],
            [
              -92.03399,
              46.708939
            ],
            [
              -92.020289,
              46.704039
            ],
            [
              -92.01529,
              46.706469
            ],
            [
              -92.025789,
              46.710839
            ],
            [
              -92.06449,
              46.745439
            ],
            [
              -92.088289,
              46.773639
            ],
            [
              -92.094089,
              46.787839
            ],
            [
              -92.09294244284719,
              46.7887705776867
            ],
            [
              -92.086089,
              46.794339
            ],
            [
              -92.062088,
              46.804038
            ],
            [
              -92.058888,
              46.809938
            ],
            [
              -92.013405,
              46.833727
            ],
            [
              -91.997987,
              46.838737
            ],
            [
              -91.985086,
              46.849637
            ],
            [
              -91.952985,
              46.867037
            ],
            [
              -91.9228713104525,
              46.8803492778534
            ],
            [
              -91.914984,
              46.883836
            ],
            [
              -91.906483,
              46.891236
            ],
            [
              -91.883238,
              46.905728
            ],
            [
              -91.871286,
              46.908352
            ],
            [
              -91.841349,
              46.925215
            ],
            [
              -91.834852,
              46.927135
            ],
            [
              -91.826068,
              46.927199
            ],
            [
              -91.806851,
              46.933727
            ],
            [
              -91.7940392854246,
              46.9396757155772
            ],
            [
              -91.794391,
              47.025315
            ],
            [
              -91.793885,
              47.078895
            ],
            [
              -91.794136,
              47.109372
            ],
            [
              -91.79173,
              47.111456
            ],
            [
              -91.792655,
              47.12493
            ],
            [
              -91.790552,
              47.374922
            ],
            [
              -91.787928,
              47.546817
            ],
            [
              -91.798252,
              48.202857757290396
            ],
            [
              -91.809038,
              48.206013
            ],
            [
              -91.814473,
              48.208664
            ],
            [
              -91.815772,
              48.211748
            ],
            [
              -91.831975,
              48.209302
            ],
            [
              -91.834404,
              48.209804
            ],
            [
              -91.839463,
              48.209643
            ],
            [
              -91.845821,
              48.208636
            ],
            [
              -91.864382,
              48.207031
            ],
            [
              -91.867882,
              48.219095
            ],
            [
              -91.884691,
              48.227321
            ],
            [
              -91.89347,
              48.237699
            ],
            [
              -91.903767,
              48.23704
            ],
            [
              -91.905991,
              48.237132
            ],
            [
              -91.906967,
              48.23777
            ],
            [
              -91.907597,
              48.238183
            ],
            [
              -91.915772,
              48.238871
            ],
            [
              -91.920802,
              48.236747
            ],
            [
              -91.929045,
              48.235834
            ],
            [
              -91.937356,
              48.234213
            ],
            [
              -91.940709,
              48.232019
            ],
            [
              -91.941838,
              48.230602
            ],
            [
              -91.945155,
              48.230442
            ],
            [
              -91.951297,
              48.232647
            ],
            [
              -91.953398,
              48.232978
            ],
            [
              -91.957798,
              48.232989
            ],
            [
              -91.959166,
              48.236296
            ],
            [
              -91.957798,
              48.23949
            ],
            [
              -91.957683,
              48.242683
            ],
            [
              -91.952209,
              48.244394
            ],
            [
              -91.952095,
              48.247131
            ],
            [
              -91.953806,
              48.249412
            ],
            [
              -91.954397,
              48.251199
            ],
            [
              -91.954432,
              48.251678
            ],
            [
              -91.959565,
              48.253551
            ],
            [
              -91.97024,
              48.253594
            ],
            [
              -91.971779,
              48.252977
            ],
            [
              -91.972565,
              48.250396
            ],
            [
              -91.970371,
              48.249358
            ],
            [
              -91.971056,
              48.247667
            ],
            [
              -91.975809,
              48.244535
            ],
            [
              -91.976903,
              48.244626
            ],
            [
              -91.977725,
              48.245723
            ],
            [
              -91.977486,
              48.24634
            ],
            [
              -91.977555,
              48.24714
            ],
            [
              -91.980772,
              48.247801
            ],
            [
              -91.989545,
              48.260214
            ],
            [
              -92.006577,
              48.265421
            ],
            [
              -92.007246,
              48.280388
            ],
            [
              -92.012066,
              48.287268
            ],
            [
              -92.01298,
              48.297391
            ],
            [
              -92.000133,
              48.321355
            ],
            [
              -92.030872,
              48.325824
            ],
            [
              -92.037721,
              48.333183
            ],
            [
              -92.046562,
              48.33474
            ],
            [
              -92.047655,
              48.343766
            ],
            [
              -92.045152,
              48.345776
            ],
            [
              -92.045734,
              48.347901
            ],
            [
              -92.048648,
              48.348861
            ],
            [
              -92.055228,
              48.359213
            ],
            [
              -92.066269,
              48.359602
            ],
            [
              -92.077961,
              48.358253
            ],
            [
              -92.083513,
              48.353865
            ],
            [
              -92.092256,
              48.354617
            ],
            [
              -92.143583,
              48.356121
            ],
            [
              -92.145049,
              48.365651
            ],
            [
              -92.162161,
              48.363279
            ],
            [
              -92.177354,
              48.357228
            ],
            [
              -92.178897,
              48.355285
            ],
            [
              -92.178418,
              48.351881
            ],
            [
              -92.193571,
              48.348613
            ],
            [
              -92.194188,
              48.348728
            ],
            [
              -92.194874,
              48.350396
            ],
            [
              -92.203684,
              48.352063
            ],
            [
              -92.207729,
              48.347812
            ],
            [
              -92.207009,
              48.346891
            ],
            [
              -92.206803,
              48.345596
            ],
            [
              -92.216983,
              48.345114
            ],
            [
              -92.219658,
              48.34813
            ],
            [
              -92.222813,
              48.349203
            ],
            [
              -92.26228,
              48.354933
            ],
            [
              -92.288994,
              48.342991
            ],
            [
              -92.295412,
              48.323957
            ],
            [
              -92.304561,
              48.322977
            ],
            [
              -92.306309,
              48.316442
            ],
            [
              -92.294527,
              48.306454
            ],
            [
              -92.301451,
              48.288608
            ],
            [
              -92.295668,
              48.278118
            ],
            [
              -92.295053,
              48.276587
            ],
            [
              -92.292999,
              48.276404
            ],
            [
              -92.294541,
              48.27156
            ],
            [
              -92.290368,
              48.265527
            ],
            [
              -92.273706,
              48.256747
            ],
            [
              -92.269742,
              48.248241
            ],
            [
              -92.280727,
              48.244269
            ],
            [
              -92.314665,
              48.240527
            ],
            [
              -92.321746,
              48.237304
            ],
            [
              -92.325304,
              48.23703
            ],
            [
              -92.332247,
              48.233876
            ],
            [
              -92.333649,
              48.233898
            ],
            [
              -92.335394,
              48.2352
            ],
            [
              -92.336831,
              48.235383
            ],
            [
              -92.33943,
              48.234537
            ],
            [
              -92.341207,
              48.23248
            ],
            [
              -92.349177,
              48.231404
            ],
            [
              -92.353177,
              48.230328
            ],
            [
              -92.362097,
              48.222876
            ],
            [
              -92.369174,
              48.220268
            ],
            [
              -92.372802,
              48.223717
            ],
            [
              -92.377903,
              48.229635
            ],
            [
              -92.378449,
              48.230801
            ],
            [
              -92.378343,
              48.233383
            ],
            [
              -92.378922,
              48.235782
            ],
            [
              -92.383161,
              48.238367
            ],
            [
              -92.384355,
              48.24072
            ],
            [
              -92.384387,
              48.242914
            ],
            [
              -92.383906,
              48.244696
            ],
            [
              -92.386438,
              48.244194
            ],
            [
              -92.387191,
              48.244606
            ],
            [
              -92.388112,
              48.246732
            ],
            [
              -92.387049,
              48.249268
            ],
            [
              -92.389305,
              48.253316
            ],
            [
              -92.393781,
              48.260562
            ],
            [
              -92.397645,
              48.265546
            ],
            [
              -92.406706,
              48.279351
            ],
            [
              -92.416285,
              48.295463
            ],
            [
              -92.426077,
              48.304491
            ],
            [
              -92.428919,
              48.305771
            ],
            [
              -92.432003,
              48.305063
            ],
            [
              -92.437825,
              48.309839
            ],
            [
              -92.441286,
              48.315597
            ],
            [
              -92.453691,
              48.329514
            ],
            [
              -92.469948,
              48.351836
            ],
            [
              -92.47675,
              48.37176
            ],
            [
              -92.456389,
              48.401134
            ],
            [
              -92.456325,
              48.414204
            ],
            [
              -92.475585,
              48.418793
            ],
            [
              -92.481152,
              48.425349
            ],
            [
              -92.480844,
              48.426583
            ],
            [
              -92.482082,
              48.428662
            ],
            [
              -92.484074,
              48.42953
            ],
            [
              -92.48919,
              48.430328
            ],
            [
              -92.492078,
              48.433709
            ],
            [
              -92.507285,
              48.447875
            ],
            [
              -92.51491,
              48.448313
            ],
            [
              -92.537202,
              48.447703
            ],
            [
              -92.575636,
              48.440827
            ],
            [
              -92.656027,
              48.436709
            ],
            [
              -92.663271,
              48.440184
            ],
            [
              -92.687998,
              48.443889
            ],
            [
              -92.712562,
              48.463013
            ],
            [
              -92.708647,
              48.470349
            ],
            [
              -92.709267,
              48.473091
            ],
            [
              -92.701298,
              48.484586
            ],
            [
              -92.698824,
              48.494892
            ],
            [
              -92.684866,
              48.497611
            ],
            [
              -92.661418,
              48.496557
            ],
            [
              -92.654039,
              48.501635
            ],
            [
              -92.647114,
              48.499905
            ],
            [
              -92.636696,
              48.499428
            ],
            [
              -92.630644,
              48.500917
            ],
            [
              -92.627237,
              48.503383
            ],
            [
              -92.629126,
              48.505303
            ],
            [
              -92.631463,
              48.50679
            ],
            [
              -92.631137,
              48.508077
            ],
            [
              -92.631117,
              48.508252
            ],
            [
              -92.625374,
              48.512916
            ],
            [
              -92.625151,
              48.513048
            ],
            [
              -92.626365,
              48.51362
            ],
            [
              -92.626639,
              48.514374
            ],
            [
              -92.625541,
              48.517549
            ],
            [
              -92.625739,
              48.518189
            ],
            [
              -92.627833,
              48.522167
            ],
            [
              -92.634931,
              48.542873
            ],
            [
              -92.657881,
              48.546263
            ],
            [
              -92.728046,
              48.53929
            ],
            [
              -92.894687,
              48.594915
            ],
            [
              -92.909947,
              48.596313
            ],
            [
              -92.929614,
              48.606874
            ],
            [
              -92.949839,
              48.608269
            ],
            [
              -92.95012,
              48.630419
            ],
            [
              -92.954876,
              48.631493
            ],
            [
              -92.984963,
              48.623731
            ],
            [
              -93.088438,
              48.627597
            ],
            [
              -93.095175,
              48.249922
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "039",
      "COUNTYNS": "00659465",
      "AFFGEOID": "0500000US27039",
      "GEOID": "27039",
      "NAME": "Dodge",
      "LSAD": "06",
      "ALAND": 1137724830,
      "AWATER": 800245,
      "County_state": "Dodge,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.045612,
              43.857969
            ],
            [
              -93.045966,
              43.848467
            ],
            [
              -92.929924,
              43.848581
            ],
            [
              -92.799223,
              43.848683
            ],
            [
              -92.689391,
              43.84857
            ],
            [
              -92.678412,
              43.848487
            ],
            [
              -92.67822,
              43.93526
            ],
            [
              -92.67806,
              44.02181
            ],
            [
              -92.678717,
              44.195516
            ],
            [
              -92.79894,
              44.196547
            ],
            [
              -92.925209,
              44.196918
            ],
            [
              -93.041159,
              44.196726
            ],
            [
              -93.04591,
              44.196716
            ],
            [
              -93.045336,
              43.935669
            ],
            [
              -93.045394,
              43.877943
            ],
            [
              -93.045612,
              43.857969
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "079",
      "COUNTYNS": "00659485",
      "AFFGEOID": "0500000US27079",
      "GEOID": "27079",
      "NAME": "Le Sueur",
      "LSAD": "06",
      "ALAND": 1162055133,
      "AWATER": 65130232,
      "County_state": "Le Sueur,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.025237,
              44.257737
            ],
            [
              -94.011868,
              44.23952
            ],
            [
              -93.959115,
              44.239434
            ],
            [
              -93.888521,
              44.239424
            ],
            [
              -93.768031,
              44.239384
            ],
            [
              -93.767971,
              44.195836
            ],
            [
              -93.527,
              44.196111
            ],
            [
              -93.525231,
              44.196134
            ],
            [
              -93.5252,
              44.282837
            ],
            [
              -93.524327,
              44.543618
            ],
            [
              -93.552114,
              44.543542
            ],
            [
              -93.597924,
              44.543744
            ],
            [
              -93.647037,
              44.543897
            ],
            [
              -93.910405,
              44.543163
            ],
            [
              -93.885373,
              44.518209
            ],
            [
              -93.916802,
              44.463163
            ],
            [
              -93.92955,
              44.456716
            ],
            [
              -93.927892,
              44.44541
            ],
            [
              -93.94092,
              44.412347
            ],
            [
              -93.950696,
              44.406775
            ],
            [
              -93.955259,
              44.374
            ],
            [
              -93.962082,
              44.364981
            ],
            [
              -93.932806,
              44.344007
            ],
            [
              -93.959783,
              44.307299
            ],
            [
              -93.991132,
              44.295466
            ],
            [
              -93.998135,
              44.278806
            ],
            [
              -94.025237,
              44.257737
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "147",
      "COUNTYNS": "00659518",
      "AFFGEOID": "0500000US27147",
      "GEOID": "27147",
      "NAME": "Steele",
      "LSAD": "06",
      "ALAND": 1112775698,
      "AWATER": 6976791,
      "County_state": "Steele,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.406553,
              43.93515
            ],
            [
              -93.406642,
              43.84812
            ],
            [
              -93.049524,
              43.848443
            ],
            [
              -93.045966,
              43.848467
            ],
            [
              -93.045612,
              43.857969
            ],
            [
              -93.045394,
              43.877943
            ],
            [
              -93.045336,
              43.935669
            ],
            [
              -93.04591,
              44.196716
            ],
            [
              -93.162285,
              44.196568
            ],
            [
              -93.286479,
              44.196479
            ],
            [
              -93.406565,
              44.196377
            ],
            [
              -93.406273,
              44.02186
            ],
            [
              -93.406553,
              43.93515
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "105",
      "COUNTYNS": "00659498",
      "AFFGEOID": "0500000US27105",
      "GEOID": "27105",
      "NAME": "Nobles",
      "LSAD": "06",
      "ALAND": 1852077339,
      "AWATER": 19323570,
      "County_state": "Nobles,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.05264,
              43.76106
            ],
            [
              -96.0531625367105,
              43.500176036071394
            ],
            [
              -95.861152,
              43.499966
            ],
            [
              -95.8609457037037,
              43.499966
            ],
            [
              -95.834421,
              43.499966
            ],
            [
              -95.821277,
              43.499965
            ],
            [
              -95.741569,
              43.499891
            ],
            [
              -95.740813,
              43.499894
            ],
            [
              -95.514774,
              43.499865
            ],
            [
              -95.486803,
              43.500246
            ],
            [
              -95.486737,
              43.500274
            ],
            [
              -95.475065,
              43.500335
            ],
            [
              -95.454706,
              43.500563
            ],
            [
              -95.454706,
              43.500648
            ],
            [
              -95.45443302611929,
              43.5006441487053
            ],
            [
              -95.452502,
              43.847953
            ],
            [
              -95.462427,
              43.847911
            ],
            [
              -95.811764,
              43.848237
            ],
            [
              -96.05232,
              43.84907
            ],
            [
              -96.05264,
              43.76106
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "143",
      "COUNTYNS": "00659516",
      "AFFGEOID": "0500000US27143",
      "GEOID": "27143",
      "NAME": "Sibley",
      "LSAD": "06",
      "ALAND": 1524941287,
      "AWATER": 30470667,
      "County_state": "Sibley,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.629489,
              44.716663
            ],
            [
              -94.624317,
              44.539748
            ],
            [
              -94.6242,
              44.45603
            ],
            [
              -93.92955,
              44.456716
            ],
            [
              -93.916802,
              44.463163
            ],
            [
              -93.885373,
              44.518209
            ],
            [
              -93.910405,
              44.543163
            ],
            [
              -93.891356,
              44.565652
            ],
            [
              -93.90042,
              44.569421
            ],
            [
              -93.892714,
              44.584985
            ],
            [
              -93.902479,
              44.587647
            ],
            [
              -93.87387,
              44.606843
            ],
            [
              -93.860515,
              44.606198
            ],
            [
              -93.857678,
              44.621095
            ],
            [
              -93.836536,
              44.618479
            ],
            [
              -93.815516,
              44.632985
            ],
            [
              -93.792116,
              44.628983
            ],
            [
              -93.768039,
              44.640303
            ],
            [
              -93.768176,
              44.67352
            ],
            [
              -93.888831,
              44.673409
            ],
            [
              -93.888636,
              44.717044
            ],
            [
              -94.010494,
              44.717464
            ],
            [
              -94.254076,
              44.717855
            ],
            [
              -94.254719,
              44.630311
            ],
            [
              -94.497835,
              44.629921
            ],
            [
              -94.498074,
              44.717171
            ],
            [
              -94.629489,
              44.716663
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "109",
      "COUNTYNS": "00659500",
      "AFFGEOID": "0500000US27109",
      "GEOID": "27109",
      "NAME": "Olmsted",
      "LSAD": "06",
      "ALAND": 1692581425,
      "AWATER": 3505507,
      "County_state": "Olmsted,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.689391,
              43.84857
            ],
            [
              -92.689283,
              43.833847
            ],
            [
              -92.448965,
              43.834105
            ],
            [
              -92.44883,
              43.848274
            ],
            [
              -92.079292,
              43.84708
            ],
            [
              -92.078544,
              43.933931
            ],
            [
              -92.079491,
              44.106988
            ],
            [
              -92.318971,
              44.107734
            ],
            [
              -92.318986,
              44.194057
            ],
            [
              -92.438411,
              44.194517
            ],
            [
              -92.550871,
              44.194916
            ],
            [
              -92.678717,
              44.195516
            ],
            [
              -92.67806,
              44.02181
            ],
            [
              -92.67822,
              43.93526
            ],
            [
              -92.678412,
              43.848487
            ],
            [
              -92.689391,
              43.84857
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "083",
      "COUNTYNS": "00659487",
      "AFFGEOID": "0500000US27083",
      "GEOID": "27083",
      "NAME": "Lyon",
      "LSAD": "06",
      "ALAND": 1850315135,
      "AWATER": 18794796,
      "County_state": "Lyon,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.09295,
              44.630486
            ],
            [
              -96.0932,
              44.54374
            ],
            [
              -96.08041,
              44.54313
            ],
            [
              -96.07936,
              44.37151
            ],
            [
              -96.07858,
              44.19662
            ],
            [
              -96.063778,
              44.196425
            ],
            [
              -95.59357,
              44.19553
            ],
            [
              -95.593282,
              44.282966
            ],
            [
              -95.594062,
              44.370294
            ],
            [
              -95.594817,
              44.542305
            ],
            [
              -95.604001,
              44.629945
            ],
            [
              -96.09295,
              44.630486
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "129",
      "COUNTYNS": "00659510",
      "AFFGEOID": "0500000US27129",
      "GEOID": "27129",
      "NAME": "Renville",
      "LSAD": "06",
      "ALAND": 2545744678,
      "AWATER": 10851893,
      "County_state": "Renville,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.482889,
              44.890712
            ],
            [
              -95.484376,
              44.796516
            ],
            [
              -95.482843,
              44.752505
            ],
            [
              -95.477221,
              44.756133
            ],
            [
              -95.455683,
              44.740881
            ],
            [
              -95.441107,
              44.743516
            ],
            [
              -95.401355,
              44.71904
            ],
            [
              -95.360177,
              44.69854
            ],
            [
              -95.317656,
              44.693107
            ],
            [
              -95.312707,
              44.677543
            ],
            [
              -95.29021,
              44.661406
            ],
            [
              -95.269074,
              44.65921
            ],
            [
              -95.244218,
              44.66706
            ],
            [
              -95.186413,
              44.624478
            ],
            [
              -95.150588,
              44.615168
            ],
            [
              -95.146558,
              44.607533
            ],
            [
              -95.117035,
              44.593254
            ],
            [
              -95.104343,
              44.587441
            ],
            [
              -95.098206,
              44.572032
            ],
            [
              -95.077014,
              44.573485
            ],
            [
              -95.058639,
              44.562358
            ],
            [
              -95.03352,
              44.557245
            ],
            [
              -95.015237,
              44.542616
            ],
            [
              -94.988683,
              44.546135
            ],
            [
              -94.969044,
              44.53376
            ],
            [
              -94.929099,
              44.524289
            ],
            [
              -94.914369,
              44.515808
            ],
            [
              -94.878405,
              44.514653
            ],
            [
              -94.865824,
              44.498218
            ],
            [
              -94.81488,
              44.48445
            ],
            [
              -94.810028,
              44.471307
            ],
            [
              -94.78063,
              44.45665
            ],
            [
              -94.6242,
              44.45603
            ],
            [
              -94.624317,
              44.539748
            ],
            [
              -94.629489,
              44.716663
            ],
            [
              -94.498074,
              44.717171
            ],
            [
              -94.497762,
              44.804995
            ],
            [
              -94.497802,
              44.889669
            ],
            [
              -94.502395,
              44.892295
            ],
            [
              -94.758189,
              44.892097
            ],
            [
              -94.884682,
              44.892322
            ],
            [
              -95.24852,
              44.89131
            ],
            [
              -95.482889,
              44.890712
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "153",
      "COUNTYNS": "00659521",
      "AFFGEOID": "0500000US27153",
      "GEOID": "27153",
      "NAME": "Todd",
      "LSAD": "06",
      "ALAND": 2447451028,
      "AWATER": 89444026,
      "County_state": "Todd,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.15557,
              46.36888
            ],
            [
              -95.154256,
              46.282428
            ],
            [
              -95.145791,
              46.282368
            ],
            [
              -95.14588,
              46.106761
            ],
            [
              -95.146255,
              45.9328
            ],
            [
              -95.140166,
              45.846064
            ],
            [
              -95.139731,
              45.773416
            ],
            [
              -94.766899,
              45.773911
            ],
            [
              -94.64399,
              45.77395
            ],
            [
              -94.643596,
              45.932852
            ],
            [
              -94.648217,
              46.020416
            ],
            [
              -94.647909,
              46.282439
            ],
            [
              -94.653462,
              46.348678
            ],
            [
              -94.721382,
              46.35154
            ],
            [
              -94.731442,
              46.368605
            ],
            [
              -95.15557,
              46.36888
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "031",
      "COUNTYNS": "00659461",
      "AFFGEOID": "0500000US27031",
      "GEOID": "27031",
      "NAME": "Cook",
      "LSAD": "06",
      "ALAND": 3761621396,
      "AWATER": 4887941604,
      "County_state": "Cook,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.03176070629569,
              48.188478951097196
            ],
            [
              -91.031627,
              48.064129
            ],
            [
              -91.026548,
              47.902406
            ],
            [
              -91.020737,
              47.887995
            ],
            [
              -91.02312471447969,
              47.4649635105367
            ],
            [
              -91.023124,
              47.464964
            ],
            [
              -90.939072,
              47.514532
            ],
            [
              -90.927975,
              47.519008
            ],
            [
              -90.919375,
              47.519784
            ],
            [
              -90.914247,
              47.522639
            ],
            [
              -90.909801,
              47.526215
            ],
            [
              -90.910127,
              47.530178
            ],
            [
              -90.907494,
              47.532873
            ],
            [
              -90.86827,
              47.5569
            ],
            [
              -90.735927,
              47.624343
            ],
            [
              -90.686382,
              47.643594
            ],
            [
              -90.647837,
              47.656176
            ],
            [
              -90.584954,
              47.68074
            ],
            [
              -90.551291,
              47.690266
            ],
            [
              -90.537105,
              47.703055
            ],
            [
              -90.458365,
              47.7214
            ],
            [
              -90.441912,
              47.726404
            ],
            [
              -90.437712,
              47.731612
            ],
            [
              -90.42139,
              47.73515
            ],
            [
              -90.393823,
              47.738271
            ],
            [
              -90.386234,
              47.7411
            ],
            [
              -90.332686,
              47.746387
            ],
            [
              -90.330254,
              47.750892
            ],
            [
              -90.323446,
              47.753771
            ],
            [
              -90.313958,
              47.756681
            ],
            [
              -90.30634,
              47.756627
            ],
            [
              -90.295952,
              47.759054
            ],
            [
              -90.248794,
              47.772763
            ],
            [
              -90.229145,
              47.776198
            ],
            [
              -90.187636,
              47.77813
            ],
            [
              -90.178755,
              47.786414
            ],
            [
              -90.16079,
              47.792807
            ],
            [
              -90.132078,
              47.79572
            ],
            [
              -90.1168,
              47.79538
            ],
            [
              -90.08816,
              47.803041
            ],
            [
              -90.082354,
              47.803619
            ],
            [
              -90.075559,
              47.803303
            ],
            [
              -90.072241,
              47.807727
            ],
            [
              -90.072025,
              47.811105
            ],
            [
              -90.042761,
              47.817568
            ],
            [
              -90.01373,
              47.821373
            ],
            [
              -89.974296,
              47.830514
            ],
            [
              -89.933899,
              47.84676
            ],
            [
              -89.92752,
              47.850825
            ],
            [
              -89.930844,
              47.857723
            ],
            [
              -89.923649,
              47.862062
            ],
            [
              -89.87158,
              47.874194
            ],
            [
              -89.85396,
              47.873997
            ],
            [
              -89.793539,
              47.891358
            ],
            [
              -89.758714,
              47.906993
            ],
            [
              -89.737539,
              47.918183
            ],
            [
              -89.72973,
              47.925245
            ],
            [
              -89.697619,
              47.941288
            ],
            [
              -89.660616,
              47.951216
            ],
            [
              -89.639545,
              47.95359
            ],
            [
              -89.638285,
              47.954275
            ],
            [
              -89.639844,
              47.959826
            ],
            [
              -89.640129,
              47.96793
            ],
            [
              -89.637015,
              47.973465
            ],
            [
              -89.631825,
              47.980039
            ],
            [
              -89.624559,
              47.983153
            ],
            [
              -89.611412,
              47.980731
            ],
            [
              -89.59589,
              47.971046
            ],
            [
              -89.58823,
              47.9662
            ],
            [
              -89.572315,
              47.967238
            ],
            [
              -89.555015,
              47.974849
            ],
            [
              -89.552939,
              47.980731
            ],
            [
              -89.551555,
              47.987305
            ],
            [
              -89.541521,
              47.992841
            ],
            [
              -89.495344,
              48.002356
            ],
            [
              -89.491739,
              48.005212
            ],
            [
              -89.489226,
              48.014528
            ],
            [
              -89.564288,
              48.00293
            ],
            [
              -89.570671,
              47.99802
            ],
            [
              -89.581007,
              47.995899
            ],
            [
              -89.582117,
              47.996314
            ],
            [
              -89.586,
              47.999885
            ],
            [
              -89.588996,
              48.001821
            ],
            [
              -89.594749,
              48.004332
            ],
            [
              -89.601659,
              48.004764
            ],
            [
              -89.607821,
              48.006566
            ],
            [
              -89.60973,
              48.009398
            ],
            [
              -89.608507,
              48.012482
            ],
            [
              -89.609396,
              48.016684
            ],
            [
              -89.610351,
              48.01778
            ],
            [
              -89.611678,
              48.017529
            ],
            [
              -89.614161,
              48.015495
            ],
            [
              -89.616133,
              48.012364
            ],
            [
              -89.617867,
              48.010947
            ],
            [
              -89.620454,
              48.01074
            ],
            [
              -89.625087,
              48.011517
            ],
            [
              -89.637173,
              48.009308
            ],
            [
              -89.638774,
              48.008166
            ],
            [
              -89.637652,
              48.006658
            ],
            [
              -89.637177,
              48.004945
            ],
            [
              -89.63728,
              48.0041
            ],
            [
              -89.637995,
              48.00378
            ],
            [
              -89.639833,
              48.003964
            ],
            [
              -89.641465,
              48.005906
            ],
            [
              -89.645447,
              48.006204
            ],
            [
              -89.64783,
              48.005132
            ],
            [
              -89.649057,
              48.003853
            ],
            [
              -89.651065,
              48.003625
            ],
            [
              -89.653208,
              48.004608
            ],
            [
              -89.655793,
              48.007532
            ],
            [
              -89.657051,
              48.009954
            ],
            [
              -89.663212,
              48.010618
            ],
            [
              -89.664813,
              48.0079
            ],
            [
              -89.667128,
              48.007421
            ],
            [
              -89.669374,
              48.008312
            ],
            [
              -89.67162,
              48.010162
            ],
            [
              -89.671892,
              48.010939
            ],
            [
              -89.673798,
              48.01151
            ],
            [
              -89.676896,
              48.011237
            ],
            [
              -89.67979,
              48.010278
            ],
            [
              -89.684931,
              48.009821
            ],
            [
              -89.685986,
              48.009798
            ],
            [
              -89.686495,
              48.010643
            ],
            [
              -89.688879,
              48.01078
            ],
            [
              -89.701438,
              48.006211
            ],
            [
              -89.702528,
              48.006325
            ],
            [
              -89.706068,
              48.007992
            ],
            [
              -89.70709,
              48.009522
            ],
            [
              -89.708145,
              48.010162
            ],
            [
              -89.713183,
              48.010024
            ],
            [
              -89.715906,
              48.009246
            ],
            [
              -89.716417,
              48.010251
            ],
            [
              -89.716114,
              48.016441
            ],
            [
              -89.717102,
              48.017172
            ],
            [
              -89.719245,
              48.016349
            ],
            [
              -89.721287,
              48.01443
            ],
            [
              -89.724044,
              48.013675
            ],
            [
              -89.724318,
              48.016485
            ],
            [
              -89.723019,
              48.017553
            ],
            [
              -89.721569,
              48.017499
            ],
            [
              -89.721038,
              48.017965
            ],
            [
              -89.72221,
              48.020162
            ],
            [
              -89.723164,
              48.020481
            ],
            [
              -89.724117,
              48.020207
            ],
            [
              -89.723571,
              48.019156
            ],
            [
              -89.724048,
              48.018996
            ],
            [
              -89.731163,
              48.018788
            ],
            [
              -89.7313,
              48.019747
            ],
            [
              -89.736851,
              48.021321
            ],
            [
              -89.739131,
              48.020384
            ],
            [
              -89.742569,
              48.019834
            ],
            [
              -89.743046,
              48.019971
            ],
            [
              -89.744206,
              48.022186
            ],
            [
              -89.749314,
              48.023325
            ],
            [
              -89.763967,
              48.022969
            ],
            [
              -89.773944,
              48.021694
            ],
            [
              -89.779427,
              48.018361
            ],
            [
              -89.782696,
              48.017837
            ],
            [
              -89.791853,
              48.018204
            ],
            [
              -89.794237,
              48.017656
            ],
            [
              -89.795224,
              48.017154
            ],
            [
              -89.796212,
              48.01487
            ],
            [
              -89.797744,
              48.014505
            ],
            [
              -89.804926,
              48.013775
            ],
            [
              -89.806016,
              48.014026
            ],
            [
              -89.807445,
              48.017224
            ],
            [
              -89.819802,
              48.015099
            ],
            [
              -89.820483,
              48.014665
            ],
            [
              -89.822594,
              48.010737
            ],
            [
              -89.830385,
              48.000284
            ],
            [
              -89.831825,
              47.9994
            ],
            [
              -89.834049,
              47.999516
            ],
            [
              -89.838689,
              48.002214
            ],
            [
              -89.841673,
              48.0029
            ],
            [
              -89.842183,
              48.002773
            ],
            [
              -89.842629,
              48.002391
            ],
            [
              -89.842629,
              48.001945
            ],
            [
              -89.842568,
              48.001368
            ],
            [
              -89.842709,
              47.997422
            ],
            [
              -89.846244,
              47.992717
            ],
            [
              -89.847571,
              47.992442
            ],
            [
              -89.868153,
              47.989898
            ],
            [
              -89.871245,
              47.985945
            ],
            [
              -89.873286,
              47.985419
            ],
            [
              -89.88071,
              47.986405
            ],
            [
              -89.886528,
              47.986305
            ],
            [
              -89.897414,
              47.987599
            ],
            [
              -89.900237,
              47.988765
            ],
            [
              -89.903501,
              47.991667
            ],
            [
              -89.904828,
              47.992261
            ],
            [
              -89.911258,
              47.993267
            ],
            [
              -89.915341,
              47.994866
            ],
            [
              -89.921633,
              47.999886
            ],
            [
              -89.930745,
              48.00816
            ],
            [
              -89.932617,
              48.010398
            ],
            [
              -89.932991,
              48.013161
            ],
            [
              -89.934489,
              48.015628
            ],
            [
              -89.95059,
              48.015901
            ],
            [
              -89.954605,
              48.011516
            ],
            [
              -89.96349,
              48.014643
            ],
            [
              -89.968255,
              48.014482
            ],
            [
              -89.973433,
              48.02035
            ],
            [
              -89.97718,
              48.023501
            ],
            [
              -89.984332,
              48.026079
            ],
            [
              -89.985217,
              48.026215
            ],
            [
              -89.987293,
              48.025484
            ],
            [
              -89.988894,
              48.025666
            ],
            [
              -89.99305,
              48.028404
            ],
            [
              -89.994687,
              48.030733
            ],
            [
              -89.996702,
              48.035391
            ],
            [
              -89.995994,
              48.041649
            ],
            [
              -89.993822,
              48.049027
            ],
            [
              -89.997852,
              48.057567
            ],
            [
              -90.008446,
              48.068396
            ],
            [
              -90.010013,
              48.068853
            ],
            [
              -90.010866,
              48.067917
            ],
            [
              -90.015057,
              48.067188
            ],
            [
              -90.018835,
              48.072032
            ],
            [
              -90.023595,
              48.084708
            ],
            [
              -90.029626,
              48.087588
            ],
            [
              -90.045577,
              48.09136
            ],
            [
              -90.04902,
              48.091681
            ],
            [
              -90.057644,
              48.096364
            ],
            [
              -90.073873,
              48.101138
            ],
            [
              -90.091639,
              48.10463
            ],
            [
              -90.116259,
              48.104303
            ],
            [
              -90.122603,
              48.105602
            ],
            [
              -90.1239,
              48.107131
            ],
            [
              -90.12509,
              48.107702
            ],
            [
              -90.128647,
              48.108436
            ],
            [
              -90.132645,
              48.111768
            ],
            [
              -90.136191,
              48.112136
            ],
            [
              -90.143762,
              48.112641
            ],
            [
              -90.14523,
              48.111637
            ],
            [
              -90.150721,
              48.110269
            ],
            [
              -90.164227,
              48.109725
            ],
            [
              -90.176605,
              48.112445
            ],
            [
              -90.188679,
              48.107947
            ],
            [
              -90.19509,
              48.108381
            ],
            [
              -90.211426,
              48.106278
            ],
            [
              -90.216404,
              48.106505
            ],
            [
              -90.224692,
              48.108148
            ],
            [
              -90.233797,
              48.107071
            ],
            [
              -90.25387,
              48.102245
            ],
            [
              -90.264986,
              48.103301
            ],
            [
              -90.274636,
              48.10326
            ],
            [
              -90.289337,
              48.098993
            ],
            [
              -90.293326,
              48.099131
            ],
            [
              -90.298099,
              48.102512
            ],
            [
              -90.305634,
              48.105117
            ],
            [
              -90.312386,
              48.1053
            ],
            [
              -90.31723,
              48.103793
            ],
            [
              -90.330052,
              48.102399
            ],
            [
              -90.337177,
              48.099771
            ],
            [
              -90.338438,
              48.096207
            ],
            [
              -90.342939,
              48.09559
            ],
            [
              -90.343484,
              48.095064
            ],
            [
              -90.344234,
              48.094447
            ],
            [
              -90.346689,
              48.094104
            ],
            [
              -90.353713,
              48.095016
            ],
            [
              -90.367658,
              48.094577
            ],
            [
              -90.372261,
              48.093639
            ],
            [
              -90.373042,
              48.091217
            ],
            [
              -90.374542,
              48.090942
            ],
            [
              -90.382258,
              48.093182
            ],
            [
              -90.384575,
              48.094599
            ],
            [
              -90.385597,
              48.095833
            ],
            [
              -90.386413,
              48.098209
            ],
            [
              -90.390162,
              48.100061
            ],
            [
              -90.393469,
              48.100359
            ],
            [
              -90.403219,
              48.105114
            ],
            [
              -90.410347,
              48.105048
            ],
            [
              -90.438449,
              48.098747
            ],
            [
              -90.443462,
              48.100575
            ],
            [
              -90.447384,
              48.10343
            ],
            [
              -90.452022,
              48.105006
            ],
            [
              -90.46321,
              48.107357
            ],
            [
              -90.465495,
              48.108659
            ],
            [
              -90.467712,
              48.108818
            ],
            [
              -90.471019,
              48.106076
            ],
            [
              -90.477635,
              48.105458
            ],
            [
              -90.480294,
              48.102099
            ],
            [
              -90.483361,
              48.100363
            ],
            [
              -90.487077,
              48.099082
            ],
            [
              -90.489873,
              48.099012
            ],
            [
              -90.493797,
              48.101318
            ],
            [
              -90.495398,
              48.099787
            ],
            [
              -90.495637,
              48.099444
            ],
            [
              -90.496148,
              48.098781
            ],
            [
              -90.505485,
              48.099644
            ],
            [
              -90.508141,
              48.099238
            ],
            [
              -90.510871,
              48.097389
            ],
            [
              -90.517075,
              48.099402
            ],
            [
              -90.556838,
              48.096008
            ],
            [
              -90.564341,
              48.098773
            ],
            [
              -90.567482,
              48.101178
            ],
            [
              -90.569763,
              48.106951
            ],
            [
              -90.555845,
              48.117069
            ],
            [
              -90.55929,
              48.121683
            ],
            [
              -90.566113,
              48.12262
            ],
            [
              -90.570481,
              48.121501
            ],
            [
              -90.575905,
              48.120907
            ],
            [
              -90.577065,
              48.121272
            ],
            [
              -90.579897,
              48.123922
            ],
            [
              -90.582217,
              48.123784
            ],
            [
              -90.590574,
              48.119762
            ],
            [
              -90.59146,
              48.117546
            ],
            [
              -90.606402,
              48.115966
            ],
            [
              -90.616154,
              48.112491
            ],
            [
              -90.62035,
              48.111895
            ],
            [
              -90.626886,
              48.111846
            ],
            [
              -90.641596,
              48.103515
            ],
            [
              -90.686617,
              48.10051
            ],
            [
              -90.703702,
              48.096009
            ],
            [
              -90.74152,
              48.094583
            ],
            [
              -90.751608,
              48.090968
            ],
            [
              -90.761625,
              48.098283
            ],
            [
              -90.761555,
              48.100133
            ],
            [
              -90.767615,
              48.110302
            ],
            [
              -90.76911,
              48.116585
            ],
            [
              -90.772998,
              48.117523
            ],
            [
              -90.774191,
              48.118575
            ],
            [
              -90.774225,
              48.118894
            ],
            [
              -90.775962,
              48.122229
            ],
            [
              -90.776133,
              48.122481
            ],
            [
              -90.776814,
              48.124103
            ],
            [
              -90.783471,
              48.126885
            ],
            [
              -90.787122,
              48.127709
            ],
            [
              -90.789919,
              48.129902
            ],
            [
              -90.787563,
              48.132872
            ],
            [
              -90.787305,
              48.133665
            ],
            [
              -90.787305,
              48.134196
            ],
            [
              -90.788101,
              48.135081
            ],
            [
              -90.790312,
              48.135788
            ],
            [
              -90.793841,
              48.135569
            ],
            [
              -90.795308,
              48.135523
            ],
            [
              -90.79797,
              48.136894
            ],
            [
              -90.796809,
              48.139521
            ],
            [
              -90.785781,
              48.145504
            ],
            [
              -90.778031,
              48.148723
            ],
            [
              -90.777512,
              48.156696
            ],
            [
              -90.776279,
              48.161927
            ],
            [
              -90.777917,
              48.163801
            ],
            [
              -90.781263,
              48.164693
            ],
            [
              -90.78338,
              48.163939
            ],
            [
              -90.785874,
              48.160902
            ],
            [
              -90.796596,
              48.159373
            ],
            [
              -90.800693,
              48.163235
            ],
            [
              -90.804207,
              48.177833
            ],
            [
              -90.810628,
              48.179661
            ],
            [
              -90.817698,
              48.179569
            ],
            [
              -90.819304,
              48.182699
            ],
            [
              -90.821115,
              48.184709
            ],
            [
              -90.825726,
              48.183567
            ],
            [
              -90.825418,
              48.181237
            ],
            [
              -90.826135,
              48.177147
            ],
            [
              -90.832589,
              48.173765
            ],
            [
              -90.836313,
              48.176963
            ],
            [
              -90.834166,
              48.18866
            ],
            [
              -90.834854,
              48.202161
            ],
            [
              -90.837323,
              48.225621
            ],
            [
              -90.8377,
              48.226512
            ],
            [
              -90.839615,
              48.2277
            ],
            [
              -90.83982,
              48.228294
            ],
            [
              -90.837772,
              48.234714
            ],
            [
              -90.839176,
              48.239511
            ],
            [
              -90.843624,
              48.243576
            ],
            [
              -90.847352,
              48.244443
            ],
            [
              -90.867079,
              48.238177
            ],
            [
              -90.875107,
              48.237784
            ],
            [
              -90.881451,
              48.240459
            ],
            [
              -90.88548,
              48.245784
            ],
            [
              -90.906829,
              48.237339
            ],
            [
              -90.914971,
              48.230603
            ],
            [
              -90.925092,
              48.229897
            ],
            [
              -90.976955,
              48.219452
            ],
            [
              -91.004239,
              48.202628
            ],
            [
              -91.003353,
              48.200183
            ],
            [
              -91.012411,
              48.198062
            ],
            [
              -91.022667,
              48.19247
            ],
            [
              -91.024208,
              48.190072
            ],
            [
              -91.031589,
              48.188452
            ],
            [
              -91.03176070629569,
              48.188478951097196
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "081",
      "COUNTYNS": "00659486",
      "AFFGEOID": "0500000US27081",
      "GEOID": "27081",
      "NAME": "Lincoln",
      "LSAD": "06",
      "ALAND": 1390177661,
      "AWATER": 30255242,
      "County_state": "Lincoln,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.452774,
              44.196895
            ],
            [
              -96.45277429961769,
              44.196779980888
            ],
            [
              -96.07858,
              44.19662
            ],
            [
              -96.07936,
              44.37151
            ],
            [
              -96.08041,
              44.54313
            ],
            [
              -96.0932,
              44.54374
            ],
            [
              -96.09295,
              44.630486
            ],
            [
              -96.4517605285233,
              44.6313495894722
            ],
            [
              -96.451761,
              44.631194
            ],
            [
              -96.45172,
              44.630708
            ],
            [
              -96.451888,
              44.544058
            ],
            [
              -96.4519902127759,
              44.5436387679114
            ],
            [
              -96.452016,
              44.543533
            ],
            [
              -96.452236,
              44.526871
            ],
            [
              -96.45201,
              44.516929
            ],
            [
              -96.451974,
              44.506849
            ],
            [
              -96.452122,
              44.473043
            ],
            [
              -96.452218,
              44.470873
            ],
            [
              -96.451816,
              44.460402
            ],
            [
              -96.451924,
              44.441549
            ],
            [
              -96.452073,
              44.38969
            ],
            [
              -96.452134,
              44.383679
            ],
            [
              -96.452213,
              44.360149
            ],
            [
              -96.452282,
              44.354857
            ],
            [
              -96.452305,
              44.345332
            ],
            [
              -96.452152,
              44.342219
            ],
            [
              -96.452248,
              44.340642
            ],
            [
              -96.452309,
              44.328094
            ],
            [
              -96.452372,
              44.325991
            ],
            [
              -96.452248,
              44.313362
            ],
            [
              -96.452369,
              44.312071
            ],
            [
              -96.452239,
              44.298655
            ],
            [
              -96.452334,
              44.297009
            ],
            [
              -96.4525,
              44.285687
            ],
            [
              -96.452617,
              44.282702
            ],
            [
              -96.452365,
              44.271972
            ],
            [
              -96.452369,
              44.268967
            ],
            [
              -96.452419,
              44.255274
            ],
            [
              -96.452673,
              44.254588
            ],
            [
              -96.452774,
              44.196895
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "127",
      "COUNTYNS": "00659509",
      "AFFGEOID": "0500000US27127",
      "GEOID": "27127",
      "NAME": "Redwood",
      "LSAD": "06",
      "ALAND": 2275500535,
      "AWATER": 8220933,
      "County_state": "Redwood,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.594817,
              44.542305
            ],
            [
              -95.594062,
              44.370294
            ],
            [
              -95.593282,
              44.282966
            ],
            [
              -95.59357,
              44.19553
            ],
            [
              -95.462547,
              44.195942
            ],
            [
              -95.229985,
              44.195264
            ],
            [
              -95.121234,
              44.195274
            ],
            [
              -95.108777,
              44.195044
            ],
            [
              -95.10748,
              44.281768
            ],
            [
              -94.866259,
              44.282372
            ],
            [
              -94.865824,
              44.498218
            ],
            [
              -94.878405,
              44.514653
            ],
            [
              -94.914369,
              44.515808
            ],
            [
              -94.929099,
              44.524289
            ],
            [
              -94.969044,
              44.53376
            ],
            [
              -94.988683,
              44.546135
            ],
            [
              -95.015237,
              44.542616
            ],
            [
              -95.03352,
              44.557245
            ],
            [
              -95.058639,
              44.562358
            ],
            [
              -95.077014,
              44.573485
            ],
            [
              -95.098206,
              44.572032
            ],
            [
              -95.104343,
              44.587441
            ],
            [
              -95.117035,
              44.593254
            ],
            [
              -95.146558,
              44.607533
            ],
            [
              -95.150588,
              44.615168
            ],
            [
              -95.186413,
              44.624478
            ],
            [
              -95.244218,
              44.66706
            ],
            [
              -95.269074,
              44.65921
            ],
            [
              -95.29021,
              44.661406
            ],
            [
              -95.312707,
              44.677543
            ],
            [
              -95.317656,
              44.693107
            ],
            [
              -95.360177,
              44.69854
            ],
            [
              -95.3604,
              44.541762
            ],
            [
              -95.594817,
              44.542305
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "075",
      "COUNTYNS": "00659484",
      "AFFGEOID": "0500000US27075",
      "GEOID": "27075",
      "NAME": "Lake",
      "LSAD": "06",
      "ALAND": 5463008925,
      "AWATER": 2282651295,
      "County_state": "Lake,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.798099,
              48.202813
            ],
            [
              -91.798252,
              48.202857757290396
            ],
            [
              -91.787928,
              47.546817
            ],
            [
              -91.790552,
              47.374922
            ],
            [
              -91.792655,
              47.12493
            ],
            [
              -91.79173,
              47.111456
            ],
            [
              -91.794136,
              47.109372
            ],
            [
              -91.793885,
              47.078895
            ],
            [
              -91.794391,
              47.025315
            ],
            [
              -91.7940392854246,
              46.9396757155772
            ],
            [
              -91.780675,
              46.945881
            ],
            [
              -91.7773,
              46.951799
            ],
            [
              -91.737098,
              46.982853
            ],
            [
              -91.704649,
              47.005246
            ],
            [
              -91.666477,
              47.014297
            ],
            [
              -91.660248,
              47.019288
            ],
            [
              -91.66014271286359,
              47.019336353943096
            ],
            [
              -91.644564,
              47.026491
            ],
            [
              -91.6390702666069,
              47.0368385210856
            ],
            [
              -91.637164,
              47.040429
            ],
            [
              -91.626824,
              47.049953
            ],
            [
              -91.613173,
              47.059192
            ],
            [
              -91.604949,
              47.063309
            ],
            [
              -91.600969,
              47.063425
            ],
            [
              -91.591508,
              47.068684
            ],
            [
              -91.573817,
              47.089917
            ],
            [
              -91.518793,
              47.108121
            ],
            [
              -91.506998,
              47.118489
            ],
            [
              -91.497902,
              47.122579
            ],
            [
              -91.477351,
              47.125667
            ],
            [
              -91.456965,
              47.139156
            ],
            [
              -91.452031,
              47.145158
            ],
            [
              -91.418805,
              47.172152
            ],
            [
              -91.398455,
              47.183916
            ],
            [
              -91.387021,
              47.187293
            ],
            [
              -91.374191,
              47.1978
            ],
            [
              -91.357803,
              47.206743
            ],
            [
              -91.35385,
              47.212686
            ],
            [
              -91.326019,
              47.238993
            ],
            [
              -91.288478,
              47.26596
            ],
            [
              -91.270697,
              47.277134
            ],
            [
              -91.26595,
              47.279479
            ],
            [
              -91.262512,
              47.27929
            ],
            [
              -91.250163,
              47.29049
            ],
            [
              -91.238658,
              47.304976
            ],
            [
              -91.206248,
              47.329182
            ],
            [
              -91.188772,
              47.340082
            ],
            [
              -91.170037,
              47.366266
            ],
            [
              -91.156513,
              47.378816
            ],
            [
              -91.146958,
              47.381464
            ],
            [
              -91.131268,
              47.393567
            ],
            [
              -91.128131,
              47.399619
            ],
            [
              -91.106218,
              47.411806
            ],
            [
              -91.097569,
              47.413888
            ],
            [
              -91.077712,
              47.428767
            ],
            [
              -91.045646,
              47.456525
            ],
            [
              -91.032945,
              47.458236
            ],
            [
              -91.02312471447969,
              47.4649635105367
            ],
            [
              -91.020737,
              47.887995
            ],
            [
              -91.026548,
              47.902406
            ],
            [
              -91.031627,
              48.064129
            ],
            [
              -91.03176070629569,
              48.188478951097196
            ],
            [
              -91.0348,
              48.188956
            ],
            [
              -91.03555,
              48.189459
            ],
            [
              -91.035858,
              48.189436
            ],
            [
              -91.043613,
              48.189163
            ],
            [
              -91.056562,
              48.187566
            ],
            [
              -91.062918,
              48.185213
            ],
            [
              -91.065549,
              48.181215
            ],
            [
              -91.07566,
              48.179204
            ],
            [
              -91.080408,
              48.179272
            ],
            [
              -91.08116,
              48.180414
            ],
            [
              -91.082731,
              48.180756
            ],
            [
              -91.088708,
              48.177351
            ],
            [
              -91.092258,
              48.173101
            ],
            [
              -91.097892,
              48.171157
            ],
            [
              -91.108887,
              48.168436
            ],
            [
              -91.114862,
              48.166057
            ],
            [
              -91.117965,
              48.162081
            ],
            [
              -91.120047,
              48.160412
            ],
            [
              -91.13858,
              48.155844
            ],
            [
              -91.139402,
              48.154738
            ],
            [
              -91.139402,
              48.153186
            ],
            [
              -91.138482,
              48.151458
            ],
            [
              -91.138311,
              48.151024
            ],
            [
              -91.137733,
              48.14915
            ],
            [
              -91.156107,
              48.140475
            ],
            [
              -91.176181,
              48.125811
            ],
            [
              -91.183207,
              48.122235
            ],
            [
              -91.190461,
              48.124891
            ],
            [
              -91.214428,
              48.10294
            ],
            [
              -91.226203,
              48.099671
            ],
            [
              -91.234932,
              48.095923
            ],
            [
              -91.250112,
              48.084087
            ],
            [
              -91.26638,
              48.078713
            ],
            [
              -91.275961,
              48.078488
            ],
            [
              -91.290215,
              48.073945
            ],
            [
              -91.302625,
              48.073033
            ],
            [
              -91.311829,
              48.072942
            ],
            [
              -91.314693,
              48.073422
            ],
            [
              -91.324784,
              48.072599
            ],
            [
              -91.327886,
              48.071388
            ],
            [
              -91.328738,
              48.070588
            ],
            [
              -91.330033,
              48.069811
            ],
            [
              -91.332589,
              48.069331
            ],
            [
              -91.336578,
              48.069627
            ],
            [
              -91.336715,
              48.070884
            ],
            [
              -91.340159,
              48.073236
            ],
            [
              -91.350521,
              48.07168
            ],
            [
              -91.365143,
              48.066968
            ],
            [
              -91.370872,
              48.06941
            ],
            [
              -91.379463,
              48.065295
            ],
            [
              -91.391128,
              48.057075
            ],
            [
              -91.413862,
              48.053518
            ],
            [
              -91.429642,
              48.048608
            ],
            [
              -91.437582,
              48.049248
            ],
            [
              -91.438877,
              48.049979
            ],
            [
              -91.438093,
              48.052104
            ],
            [
              -91.447125,
              48.063186
            ],
            [
              -91.44658,
              48.06739
            ],
            [
              -91.45033,
              48.068806
            ],
            [
              -91.465499,
              48.06677
            ],
            [
              -91.488646,
              48.068065
            ],
            [
              -91.542512,
              48.053268
            ],
            [
              -91.567254,
              48.043719
            ],
            [
              -91.575672,
              48.048791
            ],
            [
              -91.573015,
              48.057292
            ],
            [
              -91.575471,
              48.066294
            ],
            [
              -91.569746,
              48.093348
            ],
            [
              -91.552962,
              48.103012
            ],
            [
              -91.559272,
              48.108268
            ],
            [
              -91.575853,
              48.106509
            ],
            [
              -91.588953,
              48.102166
            ],
            [
              -91.615255,
              48.101906
            ],
            [
              -91.640175,
              48.096926
            ],
            [
              -91.653571,
              48.109567
            ],
            [
              -91.651624,
              48.112742
            ],
            [
              -91.652204,
              48.113725
            ],
            [
              -91.653261,
              48.114137
            ],
            [
              -91.662647,
              48.111489
            ],
            [
              -91.663092,
              48.108861
            ],
            [
              -91.665208,
              48.107011
            ],
            [
              -91.667527,
              48.108359
            ],
            [
              -91.671519,
              48.10836
            ],
            [
              -91.676876,
              48.107264
            ],
            [
              -91.680902,
              48.108111
            ],
            [
              -91.687623,
              48.111698
            ],
            [
              -91.683801,
              48.117731
            ],
            [
              -91.682845,
              48.122118
            ],
            [
              -91.692366,
              48.11933
            ],
            [
              -91.691512,
              48.117617
            ],
            [
              -91.692843,
              48.11636
            ],
            [
              -91.703524,
              48.113548
            ],
            [
              -91.711986,
              48.114713
            ],
            [
              -91.712498,
              48.115718
            ],
            [
              -91.712226,
              48.116883
            ],
            [
              -91.708099,
              48.122985
            ],
            [
              -91.704143,
              48.124894
            ],
            [
              -91.699981,
              48.13184
            ],
            [
              -91.698174,
              48.141643
            ],
            [
              -91.698448,
              48.143791
            ],
            [
              -91.699336,
              48.144728
            ],
            [
              -91.701691,
              48.144773
            ],
            [
              -91.703569,
              48.14539
            ],
            [
              -91.708523,
              48.152701
            ],
            [
              -91.70726,
              48.153661
            ],
            [
              -91.705109,
              48.159716
            ],
            [
              -91.705318,
              48.170775
            ],
            [
              -91.709383,
              48.172717
            ],
            [
              -91.717548,
              48.171801
            ],
            [
              -91.723285,
              48.169263
            ],
            [
              -91.724584,
              48.170657
            ],
            [
              -91.722574,
              48.178335
            ],
            [
              -91.721413,
              48.180255
            ],
            [
              -91.71243,
              48.1875
            ],
            [
              -91.711611,
              48.1891
            ],
            [
              -91.710519,
              48.193898
            ],
            [
              -91.714931,
              48.19913
            ],
            [
              -91.738861,
              48.204173
            ],
            [
              -91.742313,
              48.204491
            ],
            [
              -91.741932,
              48.199122
            ],
            [
              -91.744973,
              48.198458
            ],
            [
              -91.749075,
              48.198844
            ],
            [
              -91.753939,
              48.201198
            ],
            [
              -91.756637,
              48.205022
            ],
            [
              -91.760874,
              48.204789
            ],
            [
              -91.763236,
              48.201499
            ],
            [
              -91.764672,
              48.200586
            ],
            [
              -91.781182,
              48.200432
            ],
            [
              -91.78614,
              48.196412
            ],
            [
              -91.789011,
              48.196549
            ],
            [
              -91.79181,
              48.202492
            ],
            [
              -91.798099,
              48.202813
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "015",
      "COUNTYNS": "00659453",
      "AFFGEOID": "0500000US27015",
      "GEOID": "27015",
      "NAME": "Brown",
      "LSAD": "06",
      "ALAND": 1582659431,
      "AWATER": 18584892,
      "County_state": "Brown,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.10748,
              44.281768
            ],
            [
              -95.108777,
              44.195044
            ],
            [
              -95.100998,
              44.194938
            ],
            [
              -95.100221,
              44.108034
            ],
            [
              -94.859803,
              44.108016
            ],
            [
              -94.738344,
              44.108898
            ],
            [
              -94.36885,
              44.108698
            ],
            [
              -94.371731,
              44.264448
            ],
            [
              -94.411245,
              44.273168
            ],
            [
              -94.414184,
              44.286965
            ],
            [
              -94.431566,
              44.296035
            ],
            [
              -94.451833,
              44.31656
            ],
            [
              -94.451849,
              44.333485
            ],
            [
              -94.461354,
              44.332424
            ],
            [
              -94.491686,
              44.352254
            ],
            [
              -94.491333,
              44.359676
            ],
            [
              -94.50922,
              44.355152
            ],
            [
              -94.522958,
              44.365391
            ],
            [
              -94.547027,
              44.364717
            ],
            [
              -94.55486,
              44.375641
            ],
            [
              -94.578754,
              44.37206
            ],
            [
              -94.596118,
              44.385118
            ],
            [
              -94.621429,
              44.382098
            ],
            [
              -94.654035,
              44.399416
            ],
            [
              -94.666062,
              44.394048
            ],
            [
              -94.680683,
              44.409167
            ],
            [
              -94.6929,
              44.402191
            ],
            [
              -94.688426,
              44.417231
            ],
            [
              -94.702775,
              44.416612
            ],
            [
              -94.695043,
              44.428258
            ],
            [
              -94.726233,
              44.433771
            ],
            [
              -94.749782,
              44.449667
            ],
            [
              -94.767602,
              44.443025
            ],
            [
              -94.78649,
              44.451309
            ],
            [
              -94.78063,
              44.45665
            ],
            [
              -94.810028,
              44.471307
            ],
            [
              -94.81488,
              44.48445
            ],
            [
              -94.865824,
              44.498218
            ],
            [
              -94.866259,
              44.282372
            ],
            [
              -95.10748,
              44.281768
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "069",
      "COUNTYNS": "00659480",
      "AFFGEOID": "0500000US27069",
      "GEOID": "27069",
      "NAME": "Kittson",
      "LSAD": "06",
      "ALAND": 2845894834,
      "AWATER": 12411766,
      "County_state": "Kittson,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.239209,
              48.968684
            ],
            [
              -97.238882,
              48.966573
            ],
            [
              -97.237541,
              48.965341
            ],
            [
              -97.232491,
              48.963897
            ],
            [
              -97.23146,
              48.962437
            ],
            [
              -97.230859,
              48.960891
            ],
            [
              -97.230859,
              48.958229
            ],
            [
              -97.232319,
              48.950501
            ],
            [
              -97.232147,
              48.948955
            ],
            [
              -97.227854,
              48.945864
            ],
            [
              -97.226823,
              48.943545
            ],
            [
              -97.226394,
              48.938651
            ],
            [
              -97.224505,
              48.9341
            ],
            [
              -97.218666,
              48.931781
            ],
            [
              -97.217549,
              48.929892
            ],
            [
              -97.217463,
              48.927659
            ],
            [
              -97.219185,
              48.92486
            ],
            [
              -97.219095,
              48.922078
            ],
            [
              -97.217992,
              48.919735
            ],
            [
              -97.212926,
              48.918033
            ],
            [
              -97.211161,
              48.916649
            ],
            [
              -97.210809,
              48.91395
            ],
            [
              -97.212553,
              48.90986
            ],
            [
              -97.212706,
              48.908143
            ],
            [
              -97.210541,
              48.90439
            ],
            [
              -97.207688,
              48.902629
            ],
            [
              -97.198857,
              48.899831
            ],
            [
              -97.197982,
              48.898332
            ],
            [
              -97.198107,
              48.893959
            ],
            [
              -97.199981,
              48.891086
            ],
            [
              -97.197857,
              48.886838
            ],
            [
              -97.197982,
              48.884839
            ],
            [
              -97.198857,
              48.882215
            ],
            [
              -97.197982,
              48.880341
            ],
            [
              -97.190486,
              48.875594
            ],
            [
              -97.187737,
              48.874594
            ],
            [
              -97.186238,
              48.87347
            ],
            [
              -97.185738,
              48.87222
            ],
            [
              -97.187362,
              48.867598
            ],
            [
              -97.187113,
              48.866098
            ],
            [
              -97.185488,
              48.864849
            ],
            [
              -97.182365,
              48.863725
            ],
            [
              -97.180116,
              48.861601
            ],
            [
              -97.179071,
              48.856866
            ],
            [
              -97.175618,
              48.853105
            ],
            [
              -97.175618,
              48.849857
            ],
            [
              -97.176993,
              48.847733
            ],
            [
              -97.177243,
              48.846483
            ],
            [
              -97.174355,
              48.842619
            ],
            [
              -97.173811,
              48.838309
            ],
            [
              -97.174275,
              48.837261
            ],
            [
              -97.175727,
              48.836158
            ],
            [
              -97.180366,
              48.834365
            ],
            [
              -97.181116,
              48.832741
            ],
            [
              -97.180991,
              48.828992
            ],
            [
              -97.177747,
              48.824815
            ],
            [
              -97.180028,
              48.81845
            ],
            [
              -97.178611,
              48.815839
            ],
            [
              -97.177045,
              48.814124
            ],
            [
              -97.174045,
              48.812108
            ],
            [
              -97.168497,
              48.811002
            ],
            [
              -97.165624,
              48.809627
            ],
            [
              -97.164874,
              48.808253
            ],
            [
              -97.164874,
              48.807129
            ],
            [
              -97.165921,
              48.805273
            ],
            [
              -97.165921,
              48.803792
            ],
            [
              -97.163699,
              48.799513
            ],
            [
              -97.163535,
              48.79507
            ],
            [
              -97.162959,
              48.79293
            ],
            [
              -97.161231,
              48.791778
            ],
            [
              -97.158102,
              48.791145
            ],
            [
              -97.157093,
              48.790024
            ],
            [
              -97.157797,
              48.78768
            ],
            [
              -97.157804,
              48.784104
            ],
            [
              -97.157067,
              48.78312
            ],
            [
              -97.154116,
              48.781891
            ],
            [
              -97.153256,
              48.781031
            ],
            [
              -97.153871,
              48.777712
            ],
            [
              -97.154854,
              48.776728
            ],
            [
              -97.155223,
              48.775499
            ],
            [
              -97.154854,
              48.774515
            ],
            [
              -97.153871,
              48.773286
            ],
            [
              -97.152588,
              48.772602
            ],
            [
              -97.147478,
              48.766033
            ],
            [
              -97.147478,
              48.763698
            ],
            [
              -97.151289,
              48.757428
            ],
            [
              -97.151043,
              48.755707
            ],
            [
              -97.15006,
              48.754724
            ],
            [
              -97.143176,
              48.750913
            ],
            [
              -97.139488,
              48.746611
            ],
            [
              -97.139611,
              48.738129
            ],
            [
              -97.138996,
              48.736654
            ],
            [
              -97.135341,
              48.73456
            ],
            [
              -97.134847,
              48.733324
            ],
            [
              -97.135094,
              48.72974
            ],
            [
              -97.136083,
              48.727763
            ],
            [
              -97.135588,
              48.726403
            ],
            [
              -97.134229,
              48.725167
            ],
            [
              -97.126398,
              48.721101
            ],
            [
              -97.124328,
              48.719166
            ],
            [
              -97.121253,
              48.713593
            ],
            [
              -97.116185,
              48.709348
            ],
            [
              -97.116926,
              48.705022
            ],
            [
              -97.119027,
              48.703292
            ],
            [
              -97.118286,
              48.700573
            ],
            [
              -97.108655,
              48.691484
            ],
            [
              -97.098697,
              48.687534
            ],
            [
              -97.097584,
              48.686298
            ],
            [
              -97.097337,
              48.685186
            ],
            [
              -97.097708,
              48.68395
            ],
            [
              -97.100056,
              48.681355
            ],
            [
              -97.100674,
              48.679624
            ],
            [
              -97.099811,
              48.671377
            ],
            [
              -97.100009,
              48.667926
            ],
            [
              -97.101539,
              48.666771
            ],
            [
              -97.102652,
              48.664793
            ],
            [
              -97.100674,
              48.661951
            ],
            [
              -97.100551,
              48.658614
            ],
            [
              -97.10179,
              48.656294
            ],
            [
              -97.104566,
              48.654416
            ],
            [
              -97.10591,
              48.652632
            ],
            [
              -97.107814,
              48.647728
            ],
            [
              -97.111179,
              48.644525
            ],
            [
              -97.111921,
              48.642918
            ],
            [
              -97.109651,
              48.638888
            ],
            [
              -97.108276,
              48.634396
            ],
            [
              -97.108466,
              48.632658
            ],
            [
              -97.109515,
              48.631453
            ],
            [
              -97.111559,
              48.630266
            ],
            [
              -97.115043,
              48.629821
            ],
            [
              -97.120819,
              48.631053
            ],
            [
              -97.125269,
              48.629694
            ],
            [
              -97.125887,
              48.629076
            ],
            [
              -97.125887,
              48.626975
            ],
            [
              -97.124175,
              48.625387
            ],
            [
              -97.124033,
              48.623267
            ],
            [
              -97.124774,
              48.621537
            ],
            [
              -97.125639,
              48.620919
            ],
            [
              -97.130089,
              48.621166
            ],
            [
              -97.131325,
              48.619065
            ],
            [
              -97.130707,
              48.616593
            ],
            [
              -97.131448,
              48.613998
            ],
            [
              -97.132931,
              48.61338
            ],
            [
              -97.136145,
              48.613256
            ],
            [
              -97.137504,
              48.612268
            ],
            [
              -97.138246,
              48.609301
            ],
            [
              -97.13738,
              48.607324
            ],
            [
              -97.138246,
              48.604234
            ],
            [
              -97.140841,
              48.600032
            ],
            [
              -97.142818,
              48.598425
            ],
            [
              -97.143684,
              48.597066
            ],
            [
              -97.143931,
              48.594594
            ],
            [
              -97.142237,
              48.592595
            ],
            [
              -97.141585,
              48.59082
            ],
            [
              -97.142915,
              48.583733
            ],
            [
              -97.143654,
              48.582358
            ],
            [
              -97.144922,
              48.581452
            ],
            [
              -97.148429,
              48.581028
            ],
            [
              -97.14974,
              48.579516
            ],
            [
              -97.149616,
              48.576921
            ],
            [
              -97.148874,
              48.575067
            ],
            [
              -97.148998,
              48.571977
            ],
            [
              -97.149616,
              48.569876
            ],
            [
              -97.151638,
              48.56763
            ],
            [
              -97.157402,
              48.565921
            ],
            [
              -97.158638,
              48.564067
            ],
            [
              -97.158762,
              48.560112
            ],
            [
              -97.158267,
              48.558753
            ],
            [
              -97.156413,
              48.557146
            ],
            [
              -97.153942,
              48.556034
            ],
            [
              -97.152211,
              48.553933
            ],
            [
              -97.152459,
              48.552326
            ],
            [
              -97.153447,
              48.551214
            ],
            [
              -97.155791,
              48.551173
            ],
            [
              -97.160863,
              48.549236
            ],
            [
              -97.162099,
              48.548124
            ],
            [
              -97.162717,
              48.546765
            ],
            [
              -97.1630904767802,
              48.5439639241486
            ],
            [
              -96.387835,
              48.544308
            ],
            [
              -96.387472,
              48.7171
            ],
            [
              -96.404924,
              48.71707
            ],
            [
              -96.405408,
              48.999984
            ],
            [
              -96.93096,
              48.999984
            ],
            [
              -97.229039,
              49.000687
            ],
            [
              -97.231397,
              48.997212
            ],
            [
              -97.23149,
              48.995995
            ],
            [
              -97.230403,
              48.993366
            ],
            [
              -97.230833,
              48.991303
            ],
            [
              -97.234214,
              48.988966
            ],
            [
              -97.237297,
              48.985696
            ],
            [
              -97.238387,
              48.982631
            ],
            [
              -97.238025,
              48.975143
            ],
            [
              -97.239209,
              48.968684
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "073",
      "COUNTYNS": "00659482",
      "AFFGEOID": "0500000US27073",
      "GEOID": "27073",
      "NAME": "Lac qui Parle",
      "LSAD": "06",
      "ALAND": 1981394520,
      "AWATER": 33643131,
      "County_state": "Lac qui Parle,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.452947585722,
              45.268924993818395
            ],
            [
              -96.452315,
              45.208986
            ],
            [
              -96.452152,
              45.204849
            ],
            [
              -96.452162,
              45.203109
            ],
            [
              -96.452304,
              45.178563
            ],
            [
              -96.452353,
              45.124071
            ],
            [
              -96.452418,
              45.122677
            ],
            [
              -96.452026,
              45.095138
            ],
            [
              -96.452219,
              45.093836
            ],
            [
              -96.45221,
              45.051602
            ],
            [
              -96.452177,
              45.050185
            ],
            [
              -96.45224,
              45.042347
            ],
            [
              -96.4520920423924,
              44.9774935816171
            ],
            [
              -96.452092,
              44.977475
            ],
            [
              -96.452347,
              44.962734
            ],
            [
              -96.452047,
              44.910695
            ],
            [
              -96.451853,
              44.906672
            ],
            [
              -96.452009,
              44.89008
            ],
            [
              -96.4515595397173,
              44.8055694812453
            ],
            [
              -95.849009,
              44.805347
            ],
            [
              -95.847452,
              44.891799
            ],
            [
              -95.737309,
              44.891479
            ],
            [
              -95.736696,
              44.93604
            ],
            [
              -95.74352,
              44.944235
            ],
            [
              -95.770171,
              44.943545
            ],
            [
              -95.804281,
              44.949197
            ],
            [
              -95.82315,
              44.965022
            ],
            [
              -95.824614,
              44.986884
            ],
            [
              -95.860504,
              45.007264
            ],
            [
              -95.869409,
              45.023918
            ],
            [
              -95.883696,
              45.028013
            ],
            [
              -95.980308,
              45.097511
            ],
            [
              -96.003403,
              45.122313
            ],
            [
              -96.012472,
              45.123726
            ],
            [
              -96.036698,
              45.152206
            ],
            [
              -96.056283,
              45.156466
            ],
            [
              -96.103614,
              45.176675
            ],
            [
              -96.107596,
              45.18319
            ],
            [
              -96.138165,
              45.193286
            ],
            [
              -96.173033,
              45.192862
            ],
            [
              -96.223872,
              45.220756
            ],
            [
              -96.250676,
              45.219029
            ],
            [
              -96.27455,
              45.22266
            ],
            [
              -96.283393,
              45.246911
            ],
            [
              -96.37202,
              45.26035
            ],
            [
              -96.406964,
              45.25652
            ],
            [
              -96.41753,
              45.266516
            ],
            [
              -96.452947585722,
              45.268924993818395
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "161",
      "COUNTYNS": "00659525",
      "AFFGEOID": "0500000US27161",
      "GEOID": "27161",
      "NAME": "Waseca",
      "LSAD": "06",
      "ALAND": 1096505111,
      "AWATER": 24893716,
      "County_state": "Waseca,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.767971,
              44.195836
            ],
            [
              -93.767761,
              43.847956
            ],
            [
              -93.64829,
              43.848139
            ],
            [
              -93.406642,
              43.84812
            ],
            [
              -93.406553,
              43.93515
            ],
            [
              -93.406273,
              44.02186
            ],
            [
              -93.406565,
              44.196377
            ],
            [
              -93.525231,
              44.196134
            ],
            [
              -93.527,
              44.196111
            ],
            [
              -93.767971,
              44.195836
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "055",
      "COUNTYNS": "00659473",
      "AFFGEOID": "0500000US27055",
      "GEOID": "27055",
      "NAME": "Houston",
      "LSAD": "06",
      "ALAND": 1429784922,
      "AWATER": 43495762,
      "County_state": "Houston,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -91.730424,
              43.846935
            ],
            [
              -91.730507,
              43.504326
            ],
            [
              -91.7302174228592,
              43.5006795266418
            ],
            [
              -91.700749,
              43.500581
            ],
            [
              -91.670872,
              43.500513
            ],
            [
              -91.658401,
              43.500533
            ],
            [
              -91.651396,
              43.500454
            ],
            [
              -91.644924,
              43.500529
            ],
            [
              -91.639772,
              43.500573
            ],
            [
              -91.635626,
              43.500463
            ],
            [
              -91.634495,
              43.500439
            ],
            [
              -91.634244,
              43.500479
            ],
            [
              -91.625611,
              43.500727
            ],
            [
              -91.620785,
              43.500677
            ],
            [
              -91.617407,
              43.500687
            ],
            [
              -91.616895,
              43.500663
            ],
            [
              -91.615293,
              43.50055
            ],
            [
              -91.610895,
              43.50053
            ],
            [
              -91.6108315885754,
              43.5005300191943
            ],
            [
              -91.591073,
              43.500536
            ],
            [
              -91.551021,
              43.500539
            ],
            [
              -91.54122,
              43.500515
            ],
            [
              -91.533806,
              43.50056
            ],
            [
              -91.491042,
              43.50069
            ],
            [
              -91.465063,
              43.500608
            ],
            [
              -91.461403,
              43.500642
            ],
            [
              -91.445932,
              43.500588
            ],
            [
              -91.441786,
              43.500438
            ],
            [
              -91.397319,
              43.500887
            ],
            [
              -91.37695,
              43.500482
            ],
            [
              -91.371608,
              43.500945
            ],
            [
              -91.369325,
              43.500827
            ],
            [
              -91.261781,
              43.500993
            ],
            [
              -91.246715,
              43.500488
            ],
            [
              -91.217706,
              43.50055
            ],
            [
              -91.217876,
              43.508104
            ],
            [
              -91.217353,
              43.512474
            ],
            [
              -91.218292,
              43.514434
            ],
            [
              -91.222613,
              43.517892
            ],
            [
              -91.230027,
              43.521595
            ],
            [
              -91.232941,
              43.523967
            ],
            [
              -91.236725,
              43.53293
            ],
            [
              -91.243183,
              43.540309
            ],
            [
              -91.244093,
              43.54562
            ],
            [
              -91.24382,
              43.54913
            ],
            [
              -91.243214,
              43.550722
            ],
            [
              -91.240649,
              43.554995
            ],
            [
              -91.234432,
              43.561781
            ],
            [
              -91.232812,
              43.564842
            ],
            [
              -91.23149,
              43.575595
            ],
            [
              -91.231865,
              43.581822
            ],
            [
              -91.232707,
              43.583533
            ],
            [
              -91.234499,
              43.585529
            ],
            [
              -91.239109,
              43.58976
            ],
            [
              -91.252926,
              43.600363
            ],
            [
              -91.258267,
              43.603484
            ],
            [
              -91.261631,
              43.606175
            ],
            [
              -91.265091,
              43.609977
            ],
            [
              -91.268748,
              43.615348
            ],
            [
              -91.268457,
              43.627352
            ],
            [
              -91.263178,
              43.638203
            ],
            [
              -91.262397,
              43.64176
            ],
            [
              -91.263856,
              43.647662
            ],
            [
              -91.265051,
              43.649141
            ],
            [
              -91.270767,
              43.65308
            ],
            [
              -91.271749,
              43.654929
            ],
            [
              -91.273252,
              43.666623
            ],
            [
              -91.272741,
              43.676609
            ],
            [
              -91.267792,
              43.695652
            ],
            [
              -91.268455,
              43.709824
            ],
            [
              -91.266538,
              43.713947
            ],
            [
              -91.261316,
              43.71949
            ],
            [
              -91.258756,
              43.723426
            ],
            [
              -91.25777341547641,
              43.725660823086095
            ],
            [
              -91.255932,
              43.729849
            ],
            [
              -91.254903,
              43.733533
            ],
            [
              -91.255431,
              43.744876
            ],
            [
              -91.243955,
              43.773046
            ],
            [
              -91.244135,
              43.774667
            ],
            [
              -91.2532447735738,
              43.783377558317504
            ],
            [
              -91.262436,
              43.792166
            ],
            [
              -91.264436,
              43.800366
            ],
            [
              -91.267436,
              43.804166
            ],
            [
              -91.272037,
              43.813766
            ],
            [
              -91.273037,
              43.818566
            ],
            [
              -91.275737,
              43.824866
            ],
            [
              -91.277695,
              43.837741
            ],
            [
              -91.281968,
              43.842738
            ],
            [
              -91.284138,
              43.847065
            ],
            [
              -91.730424,
              43.846935
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "063",
      "COUNTYNS": "00659477",
      "AFFGEOID": "0500000US27063",
      "GEOID": "27063",
      "NAME": "Jackson",
      "LSAD": "06",
      "ALAND": 1820689421,
      "AWATER": 42120425,
      "County_state": "Jackson,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.452502,
              43.847953
            ],
            [
              -95.45443302611929,
              43.5006441487053
            ],
            [
              -95.434293,
              43.50036
            ],
            [
              -95.434199,
              43.500314
            ],
            [
              -95.387851,
              43.50024
            ],
            [
              -95.387812,
              43.50024
            ],
            [
              -95.38778709258969,
              43.5002401628325
            ],
            [
              -95.375269,
              43.500322
            ],
            [
              -95.374737,
              43.500314
            ],
            [
              -95.250969,
              43.500464
            ],
            [
              -95.250762,
              43.500406
            ],
            [
              -95.244844,
              43.501196
            ],
            [
              -95.214938,
              43.500885
            ],
            [
              -95.2149304213735,
              43.5008849756272
            ],
            [
              -95.180423,
              43.500774
            ],
            [
              -95.167891,
              43.500885
            ],
            [
              -95.167294,
              43.500771
            ],
            [
              -95.154936,
              43.500449
            ],
            [
              -95.122633,
              43.500755
            ],
            [
              -95.114874,
              43.500667
            ],
            [
              -95.054289,
              43.50086
            ],
            [
              -95.053504,
              43.500769
            ],
            [
              -95.034,
              43.500811
            ],
            [
              -95.014245,
              43.500872
            ],
            [
              -94.99446,
              43.500523
            ],
            [
              -94.974359,
              43.500508
            ],
            [
              -94.954477,
              43.500467
            ],
            [
              -94.934625,
              43.50049
            ],
            [
              -94.914955,
              43.50045
            ],
            [
              -94.914905,
              43.50045
            ],
            [
              -94.91463375313809,
              43.50045
            ],
            [
              -94.914523,
              43.50045
            ],
            [
              -94.887291,
              43.500502
            ],
            [
              -94.874235,
              43.500557
            ],
            [
              -94.872725,
              43.500564
            ],
            [
              -94.860192,
              43.500546
            ],
            [
              -94.857867,
              43.500615
            ],
            [
              -94.854555091254,
              43.5006140281275
            ],
            [
              -94.854444,
              43.848099
            ],
            [
              -94.859388,
              43.848093
            ],
            [
              -95.095391,
              43.847927
            ],
            [
              -95.100586,
              43.847937
            ],
            [
              -95.452502,
              43.847953
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "163",
      "COUNTYNS": "00659526",
      "AFFGEOID": "0500000US27163",
      "GEOID": "27163",
      "NAME": "Washington",
      "LSAD": "06",
      "ALAND": 995558956,
      "AWATER": 99517524,
      "County_state": "Washington,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.021521,
              44.786753
            ],
            [
              -93.015961,
              44.775564
            ],
            [
              -93.005978,
              44.771685
            ],
            [
              -92.974799,
              44.772315
            ],
            [
              -92.928629,
              44.781417
            ],
            [
              -92.882559,
              44.774107
            ],
            [
              -92.8553,
              44.747715
            ],
            [
              -92.812634,
              44.747252
            ],
            [
              -92.80257940581069,
              44.7457971094334
            ],
            [
              -92.802875,
              44.746847
            ],
            [
              -92.804035,
              44.748433
            ],
            [
              -92.807317,
              44.750364
            ],
            [
              -92.807988,
              44.75147
            ],
            [
              -92.807362,
              44.758909
            ],
            [
              -92.805287,
              44.768361
            ],
            [
              -92.800313,
              44.777379
            ],
            [
              -92.796039,
              44.782056
            ],
            [
              -92.788776,
              44.787794
            ],
            [
              -92.785206,
              44.792303
            ],
            [
              -92.782963,
              44.798131
            ],
            [
              -92.781498,
              44.809408
            ],
            [
              -92.78043,
              44.812589
            ],
            [
              -92.772663,
              44.821424
            ],
            [
              -92.771902,
              44.823067
            ],
            [
              -92.772663,
              44.826337
            ],
            [
              -92.772266,
              44.828046
            ],
            [
              -92.769367,
              44.8318
            ],
            [
              -92.766102,
              44.834966
            ],
            [
              -92.765278,
              44.837186
            ],
            [
              -92.765278,
              44.84107
            ],
            [
              -92.768574,
              44.854368
            ],
            [
              -92.76909047947728,
              44.8619968322789
            ],
            [
              -92.7690926333535,
              44.862028646825095
            ],
            [
              -92.769102,
              44.862167
            ],
            [
              -92.767102,
              44.866767
            ],
            [
              -92.763706,
              44.872129
            ],
            [
              -92.763402,
              44.874167
            ],
            [
              -92.764133,
              44.875905
            ],
            [
              -92.769603,
              44.882967
            ],
            [
              -92.773946,
              44.889997
            ],
            [
              -92.774907,
              44.892797
            ],
            [
              -92.774571,
              44.898084
            ],
            [
              -92.774022,
              44.900083
            ],
            [
              -92.773103,
              44.901367
            ],
            [
              -92.761341,
              44.906904
            ],
            [
              -92.759556,
              44.907857
            ],
            [
              -92.758701,
              44.908979
            ],
            [
              -92.757557,
              44.911214
            ],
            [
              -92.75559183382249,
              44.9186302846844
            ],
            [
              -92.750645,
              44.937299
            ],
            [
              -92.750802,
              44.941567
            ],
            [
              -92.754603,
              44.955767
            ],
            [
              -92.760701,
              44.964979
            ],
            [
              -92.767218,
              44.968084
            ],
            [
              -92.768545,
              44.969839
            ],
            [
              -92.769445,
              44.97215
            ],
            [
              -92.770304,
              44.978967
            ],
            [
              -92.770346,
              44.983327
            ],
            [
              -92.769049,
              44.988195
            ],
            [
              -92.770834,
              44.994131
            ],
            [
              -92.771231,
              45.001378
            ],
            [
              -92.768118,
              45.009115
            ],
            [
              -92.762533,
              45.020551
            ],
            [
              -92.761904,
              45.022467
            ],
            [
              -92.76206,
              45.02432
            ],
            [
              -92.764604,
              45.028767
            ],
            [
              -92.770362,
              45.033803
            ],
            [
              -92.778815,
              45.039327
            ],
            [
              -92.78791,
              45.043516
            ],
            [
              -92.793282,
              45.047178
            ],
            [
              -92.797081,
              45.050648
            ],
            [
              -92.802056,
              45.057423
            ],
            [
              -92.803079,
              45.060978
            ],
            [
              -92.802911,
              45.065403
            ],
            [
              -92.802163,
              45.067555
            ],
            [
              -92.800851,
              45.069477
            ],
            [
              -92.79223936985119,
              45.078871001782
            ],
            [
              -92.79223863282759,
              45.0788718057646
            ],
            [
              -92.791528,
              45.079647
            ],
            [
              -92.77401,
              45.089138
            ],
            [
              -92.765602,
              45.09573
            ],
            [
              -92.754387,
              45.103146
            ],
            [
              -92.746749,
              45.107051
            ],
            [
              -92.744938,
              45.108309
            ],
            [
              -92.740509,
              45.113396
            ],
            [
              -92.739584,
              45.115598
            ],
            [
              -92.739528,
              45.116515
            ],
            [
              -92.740611,
              45.118454
            ],
            [
              -92.742925,
              45.119918
            ],
            [
              -92.7456477664165,
              45.1230586702544
            ],
            [
              -92.745694,
              45.123112
            ],
            [
              -92.749427,
              45.138117
            ],
            [
              -92.756807,
              45.151866
            ],
            [
              -92.757707,
              45.155466
            ],
            [
              -92.757775,
              45.160519
            ],
            [
              -92.756907,
              45.165166
            ],
            [
              -92.752542,
              45.171772
            ],
            [
              -92.752404,
              45.173916
            ],
            [
              -92.764872,
              45.182812
            ],
            [
              -92.766808,
              45.185466
            ],
            [
              -92.767408,
              45.190166
            ],
            [
              -92.766932,
              45.195111
            ],
            [
              -92.763908,
              45.204866
            ],
            [
              -92.762108,
              45.207166
            ],
            [
              -92.758008,
              45.209566
            ],
            [
              -92.754008,
              45.212766
            ],
            [
              -92.751708,
              45.218666
            ],
            [
              -92.752192,
              45.221051
            ],
            [
              -92.753931,
              45.222905
            ],
            [
              -92.755732,
              45.225949
            ],
            [
              -92.757456,
              45.230526
            ],
            [
              -92.757503,
              45.238308
            ],
            [
              -92.760249,
              45.2496
            ],
            [
              -92.758907,
              45.253407
            ],
            [
              -92.755199,
              45.256733
            ],
            [
              -92.751709,
              45.261666
            ],
            [
              -92.751659,
              45.26591
            ],
            [
              -92.752666,
              45.269565
            ],
            [
              -92.758022,
              45.274822
            ],
            [
              -92.760615,
              45.278827
            ],
            [
              -92.761868,
              45.284938
            ],
            [
              -92.761868,
              45.287013
            ],
            [
              -92.761013,
              45.289028
            ],
            [
              -92.75871,
              45.290965
            ],
            [
              -92.750819,
              45.29298
            ],
            [
              -92.74557465605889,
              45.295843579494395
            ],
            [
              -92.815217,
              45.296332
            ],
            [
              -93.01944,
              45.296929
            ],
            [
              -93.019745,
              45.210895
            ],
            [
              -93.019747,
              45.210741
            ],
            [
              -93.020069,
              45.183876
            ],
            [
              -93.020518,
              45.123866
            ],
            [
              -92.984619,
              45.123622
            ],
            [
              -92.984549,
              45.115937
            ],
            [
              -92.984517,
              45.096655
            ],
            [
              -92.984767,
              45.057475
            ],
            [
              -92.984789,
              45.035411
            ],
            [
              -92.984771,
              45.029051
            ],
            [
              -92.984705,
              44.987605
            ],
            [
              -92.984735,
              44.963252
            ],
            [
              -92.98421,
              44.894594
            ],
            [
              -93.004424,
              44.890767
            ],
            [
              -93.020044,
              44.89075
            ],
            [
              -93.016466,
              44.884611
            ],
            [
              -93.010076,
              44.863787
            ],
            [
              -93.010032,
              44.845989
            ],
            [
              -93.004711,
              44.814873
            ],
            [
              -93.021521,
              44.786753
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "025",
      "COUNTYNS": "00659458",
      "AFFGEOID": "0500000US27025",
      "GEOID": "27025",
      "NAME": "Chisago",
      "LSAD": "06",
      "ALAND": 1074617226,
      "AWATER": 71151170,
      "County_state": "Chisago,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.141793,
              45.730657
            ],
            [
              -93.141974,
              45.558109
            ],
            [
              -93.022274,
              45.557154
            ],
            [
              -93.021689,
              45.470396
            ],
            [
              -93.019563,
              45.41177
            ],
            [
              -93.01944,
              45.296929
            ],
            [
              -92.815217,
              45.296332
            ],
            [
              -92.74557465605889,
              45.295843579494395
            ],
            [
              -92.737122,
              45.300459
            ],
            [
              -92.732594,
              45.304224
            ],
            [
              -92.727737,
              45.309288
            ],
            [
              -92.709968,
              45.321302
            ],
            [
              -92.704794,
              45.326526
            ],
            [
              -92.699956,
              45.333716
            ],
            [
              -92.698967,
              45.336374
            ],
            [
              -92.69892,
              45.339364
            ],
            [
              -92.699524,
              45.342421
            ],
            [
              -92.704054,
              45.35366
            ],
            [
              -92.703705,
              45.35633
            ],
            [
              -92.70272,
              45.358472
            ],
            [
              -92.696499,
              45.363529
            ],
            [
              -92.679193,
              45.37271
            ],
            [
              -92.678223,
              45.373604
            ],
            [
              -92.678756,
              45.376201
            ],
            [
              -92.676961,
              45.380137
            ],
            [
              -92.6746941140289,
              45.382865411306895
            ],
            [
              -92.669505,
              45.389111
            ],
            [
              -92.664102,
              45.393309
            ],
            [
              -92.658486,
              45.396058
            ],
            [
              -92.650422,
              45.398507
            ],
            [
              -92.65057,
              45.403308
            ],
            [
              -92.648157,
              45.407423
            ],
            [
              -92.646676,
              45.413227
            ],
            [
              -92.646943,
              45.414265
            ],
            [
              -92.649467,
              45.416408
            ],
            [
              -92.650269,
              45.419168
            ],
            [
              -92.649152,
              45.429618
            ],
            [
              -92.646768,
              45.437929
            ],
            [
              -92.646602,
              45.441635
            ],
            [
              -92.652698,
              45.454527
            ],
            [
              -92.653549,
              45.455346
            ],
            [
              -92.661131,
              45.458278
            ],
            [
              -92.677219,
              45.462864
            ],
            [
              -92.680234,
              45.464344
            ],
            [
              -92.686793,
              45.472271
            ],
            [
              -92.691619,
              45.476273
            ],
            [
              -92.695212,
              45.482882
            ],
            [
              -92.702224,
              45.493046
            ],
            [
              -92.71189,
              45.503281
            ],
            [
              -92.715814,
              45.506676
            ],
            [
              -92.724337,
              45.512223
            ],
            [
              -92.726677,
              45.514462
            ],
            [
              -92.727744,
              45.518811
            ],
            [
              -92.728023,
              45.525652
            ],
            [
              -92.72465,
              45.536744
            ],
            [
              -92.724762,
              45.538617
            ],
            [
              -92.726082,
              45.541112
            ],
            [
              -92.745591,
              45.553016
            ],
            [
              -92.756906,
              45.557499
            ],
            [
              -92.764574,
              45.563592
            ],
            [
              -92.770223,
              45.566939
            ],
            [
              -92.773412,
              45.568235
            ],
            [
              -92.775988,
              45.568478
            ],
            [
              -92.785741,
              45.567888
            ],
            [
              -92.790143,
              45.566915
            ],
            [
              -92.801503,
              45.562854
            ],
            [
              -92.812083,
              45.561122
            ],
            [
              -92.823309,
              45.560934
            ],
            [
              -92.834156,
              45.563096
            ],
            [
              -92.843783,
              45.566135
            ],
            [
              -92.846447,
              45.566515
            ],
            [
              -92.871082,
              45.567581
            ],
            [
              -92.881136,
              45.573409
            ],
            [
              -92.883749,
              45.575483
            ],
            [
              -92.884954,
              45.578818
            ],
            [
              -92.883277,
              45.589831
            ],
            [
              -92.886421,
              45.594881
            ],
            [
              -92.886442,
              45.598679
            ],
            [
              -92.8849,
              45.605001
            ],
            [
              -92.882529,
              45.610216
            ],
            [
              -92.88297,
              45.613738
            ],
            [
              -92.886669,
              45.61976
            ],
            [
              -92.888035,
              45.624959
            ],
            [
              -92.888114,
              45.628377
            ],
            [
              -92.886827,
              45.633403
            ],
            [
              -92.886963,
              45.636777
            ],
            [
              -92.887929,
              45.639006
            ],
            [
              -92.887067,
              45.644148
            ],
            [
              -92.885711,
              45.646017
            ],
            [
              -92.883987,
              45.65487
            ],
            [
              -92.882504,
              45.659471
            ],
            [
              -92.878932,
              45.665606
            ],
            [
              -92.876891,
              45.675289
            ],
            [
              -92.875488,
              45.689014
            ],
            [
              -92.870145,
              45.696757
            ],
            [
              -92.870025,
              45.697272
            ],
            [
              -92.871775,
              45.699774
            ],
            [
              -92.868862,
              45.711993
            ],
            [
              -92.869689,
              45.715142
            ],
            [
              -92.869193,
              45.717568
            ],
            [
              -92.865688,
              45.720623
            ],
            [
              -92.862598,
              45.722241
            ],
            [
              -92.853405,
              45.723152
            ],
            [
              -92.850933,
              45.723831
            ],
            [
              -92.850537,
              45.724376
            ],
            [
              -92.850388,
              45.727576
            ],
            [
              -92.848851,
              45.728751
            ],
            [
              -92.843079,
              45.729163
            ],
            [
              -92.841051,
              45.730024
            ],
            [
              -93.141793,
              45.730657
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "043",
      "COUNTYNS": "00659467",
      "AFFGEOID": "0500000US27043",
      "GEOID": "27043",
      "NAME": "Faribault",
      "LSAD": "06",
      "ALAND": 1845301801,
      "AWATER": 24422161,
      "County_state": "Faribault,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.247123,
              43.847946
            ],
            [
              -94.2479653766318,
              43.5003325399466
            ],
            [
              -94.10988,
              43.500283
            ],
            [
              -94.108068,
              43.5003
            ],
            [
              -94.094339,
              43.500302
            ],
            [
              -94.092894,
              43.500302
            ],
            [
              -93.97076155853709,
              43.49960500889429
            ],
            [
              -93.97076,
              43.499605
            ],
            [
              -93.795793,
              43.49952
            ],
            [
              -93.794285,
              43.499542
            ],
            [
              -93.76849795694349,
              43.4995489366181
            ],
            [
              -93.716217,
              43.499563
            ],
            [
              -93.708771,
              43.499564
            ],
            [
              -93.704916,
              43.499568
            ],
            [
              -93.699345,
              43.499576
            ],
            [
              -93.6485330413135,
              43.4995586947376
            ],
            [
              -93.64829,
              43.848139
            ],
            [
              -93.767761,
              43.847956
            ],
            [
              -93.826581,
              43.847927
            ],
            [
              -93.830764,
              43.847913
            ],
            [
              -93.832909,
              43.848021
            ],
            [
              -93.835031,
              43.848012
            ],
            [
              -94.247123,
              43.847946
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "013",
      "COUNTYNS": "00659452",
      "AFFGEOID": "0500000US27013",
      "GEOID": "27013",
      "NAME": "Blue Earth",
      "LSAD": "06",
      "ALAND": 1936830865,
      "AWATER": 46082217,
      "County_state": "Blue Earth,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.371731,
              44.264448
            ],
            [
              -94.36885,
              44.108698
            ],
            [
              -94.369018,
              43.934939
            ],
            [
              -94.368974,
              43.848047
            ],
            [
              -94.247123,
              43.847946
            ],
            [
              -93.835031,
              43.848012
            ],
            [
              -93.832909,
              43.848021
            ],
            [
              -93.830764,
              43.847913
            ],
            [
              -93.826581,
              43.847927
            ],
            [
              -93.767761,
              43.847956
            ],
            [
              -93.767971,
              44.195836
            ],
            [
              -93.768031,
              44.239384
            ],
            [
              -93.888521,
              44.239424
            ],
            [
              -93.959115,
              44.239434
            ],
            [
              -94.011868,
              44.23952
            ],
            [
              -94.024602,
              44.222016
            ],
            [
              -94.003441,
              44.169286
            ],
            [
              -94.045978,
              44.154715
            ],
            [
              -94.062509,
              44.162825
            ],
            [
              -94.073812,
              44.158515
            ],
            [
              -94.109911,
              44.172389
            ],
            [
              -94.114133,
              44.181113
            ],
            [
              -94.141898,
              44.193566
            ],
            [
              -94.151979,
              44.191785
            ],
            [
              -94.214723,
              44.201384
            ],
            [
              -94.218971,
              44.21359
            ],
            [
              -94.252358,
              44.215351
            ],
            [
              -94.256014,
              44.223657
            ],
            [
              -94.272356,
              44.224161
            ],
            [
              -94.290883,
              44.239537
            ],
            [
              -94.318125,
              44.243101
            ],
            [
              -94.337158,
              44.256797
            ],
            [
              -94.371731,
              44.264448
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "103",
      "COUNTYNS": "00659497",
      "AFFGEOID": "0500000US27103",
      "GEOID": "27103",
      "NAME": "Nicollet",
      "LSAD": "06",
      "ALAND": 1161921138,
      "AWATER": 47467472,
      "County_state": "Nicollet,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.78063,
              44.45665
            ],
            [
              -94.78649,
              44.451309
            ],
            [
              -94.767602,
              44.443025
            ],
            [
              -94.749782,
              44.449667
            ],
            [
              -94.726233,
              44.433771
            ],
            [
              -94.695043,
              44.428258
            ],
            [
              -94.702775,
              44.416612
            ],
            [
              -94.688426,
              44.417231
            ],
            [
              -94.6929,
              44.402191
            ],
            [
              -94.680683,
              44.409167
            ],
            [
              -94.666062,
              44.394048
            ],
            [
              -94.654035,
              44.399416
            ],
            [
              -94.621429,
              44.382098
            ],
            [
              -94.596118,
              44.385118
            ],
            [
              -94.578754,
              44.37206
            ],
            [
              -94.55486,
              44.375641
            ],
            [
              -94.547027,
              44.364717
            ],
            [
              -94.522958,
              44.365391
            ],
            [
              -94.50922,
              44.355152
            ],
            [
              -94.491333,
              44.359676
            ],
            [
              -94.491686,
              44.352254
            ],
            [
              -94.461354,
              44.332424
            ],
            [
              -94.451849,
              44.333485
            ],
            [
              -94.451833,
              44.31656
            ],
            [
              -94.431566,
              44.296035
            ],
            [
              -94.414184,
              44.286965
            ],
            [
              -94.411245,
              44.273168
            ],
            [
              -94.371731,
              44.264448
            ],
            [
              -94.337158,
              44.256797
            ],
            [
              -94.318125,
              44.243101
            ],
            [
              -94.290883,
              44.239537
            ],
            [
              -94.272356,
              44.224161
            ],
            [
              -94.256014,
              44.223657
            ],
            [
              -94.252358,
              44.215351
            ],
            [
              -94.218971,
              44.21359
            ],
            [
              -94.214723,
              44.201384
            ],
            [
              -94.151979,
              44.191785
            ],
            [
              -94.141898,
              44.193566
            ],
            [
              -94.114133,
              44.181113
            ],
            [
              -94.109911,
              44.172389
            ],
            [
              -94.073812,
              44.158515
            ],
            [
              -94.062509,
              44.162825
            ],
            [
              -94.045978,
              44.154715
            ],
            [
              -94.003441,
              44.169286
            ],
            [
              -94.024602,
              44.222016
            ],
            [
              -94.011868,
              44.23952
            ],
            [
              -94.025237,
              44.257737
            ],
            [
              -93.998135,
              44.278806
            ],
            [
              -93.991132,
              44.295466
            ],
            [
              -93.959783,
              44.307299
            ],
            [
              -93.932806,
              44.344007
            ],
            [
              -93.962082,
              44.364981
            ],
            [
              -93.955259,
              44.374
            ],
            [
              -93.950696,
              44.406775
            ],
            [
              -93.94092,
              44.412347
            ],
            [
              -93.927892,
              44.44541
            ],
            [
              -93.92955,
              44.456716
            ],
            [
              -94.6242,
              44.45603
            ],
            [
              -94.78063,
              44.45665
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "149",
      "COUNTYNS": "00659519",
      "AFFGEOID": "0500000US27149",
      "GEOID": "27149",
      "NAME": "Stevens",
      "LSAD": "06",
      "ALAND": 1459747661,
      "AWATER": 30319494,
      "County_state": "Stevens,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.254022,
              45.75982
            ],
            [
              -96.253026,
              45.585526
            ],
            [
              -96.241431,
              45.58561
            ],
            [
              -96.241328,
              45.412667
            ],
            [
              -96.117036,
              45.411993
            ],
            [
              -95.747402,
              45.412313
            ],
            [
              -95.747754,
              45.585521
            ],
            [
              -95.758327,
              45.585508
            ],
            [
              -95.758508,
              45.759932
            ],
            [
              -96.254022,
              45.75982
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "095",
      "COUNTYNS": "00659493",
      "AFFGEOID": "0500000US27095",
      "GEOID": "27095",
      "NAME": "Mille Lacs",
      "LSAD": "06",
      "ALAND": 1482348668,
      "AWATER": 282998978,
      "County_state": "Mille Lacs,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.810912,
              46.243128
            ],
            [
              -93.810362,
              46.156585
            ],
            [
              -93.810321,
              45.98308
            ],
            [
              -93.768891,
              45.982525
            ],
            [
              -93.768374,
              45.909702
            ],
            [
              -93.763948,
              45.822143
            ],
            [
              -93.759435,
              45.559646
            ],
            [
              -93.510704,
              45.558772
            ],
            [
              -93.513683,
              45.734318
            ],
            [
              -93.51808,
              45.981789
            ],
            [
              -93.432492,
              45.981398
            ],
            [
              -93.431831,
              46.153991
            ],
            [
              -93.42963,
              46.18171
            ],
            [
              -93.430648,
              46.246412
            ],
            [
              -93.796281,
              46.243199
            ],
            [
              -93.810912,
              46.243128
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "099",
      "COUNTYNS": "00659495",
      "AFFGEOID": "0500000US27099",
      "GEOID": "27099",
      "NAME": "Mower",
      "LSAD": "06",
      "ALAND": 1842332253,
      "AWATER": 617233,
      "County_state": "Mower,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.049524,
              43.848443
            ],
            [
              -93.049452,
              43.760959
            ],
            [
              -93.049331,
              43.586809
            ],
            [
              -93.04919199673229,
              43.4995713943464
            ],
            [
              -93.024429,
              43.499572
            ],
            [
              -93.0243476382082,
              43.499572157239804
            ],
            [
              -93.007871,
              43.499604
            ],
            [
              -92.870277,
              43.499548
            ],
            [
              -92.790317,
              43.499567
            ],
            [
              -92.752088,
              43.500084
            ],
            [
              -92.707312,
              43.500069
            ],
            [
              -92.692786,
              43.500063
            ],
            [
              -92.689033,
              43.500062
            ],
            [
              -92.67258,
              43.500055
            ],
            [
              -92.653318,
              43.50005
            ],
            [
              -92.649194,
              43.500049
            ],
            [
              -92.553161,
              43.5003
            ],
            [
              -92.55312804163,
              43.500300016729
            ],
            [
              -92.464505,
              43.500345
            ],
            [
              -92.44894781237689,
              43.5004201689952
            ],
            [
              -92.448965,
              43.834105
            ],
            [
              -92.689283,
              43.833847
            ],
            [
              -92.689391,
              43.84857
            ],
            [
              -92.799223,
              43.848683
            ],
            [
              -92.929924,
              43.848581
            ],
            [
              -93.045966,
              43.848467
            ],
            [
              -93.049524,
              43.848443
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "051",
      "COUNTYNS": "00659471",
      "AFFGEOID": "0500000US27051",
      "GEOID": "27051",
      "NAME": "Grant",
      "LSAD": "06",
      "ALAND": 1418786606,
      "AWATER": 71704359,
      "County_state": "Grant,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.26614,
              46.02161
            ],
            [
              -96.26616,
              45.93471
            ],
            [
              -96.253675,
              45.934648
            ],
            [
              -96.254022,
              45.75982
            ],
            [
              -95.758508,
              45.759932
            ],
            [
              -95.758701,
              45.933692
            ],
            [
              -95.76956,
              45.93368
            ],
            [
              -95.76975,
              46.10745
            ],
            [
              -96.265366,
              46.10861
            ],
            [
              -96.26614,
              46.02161
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "057",
      "COUNTYNS": "00659474",
      "AFFGEOID": "0500000US27057",
      "GEOID": "27057",
      "NAME": "Hubbard",
      "LSAD": "06",
      "ALAND": 2398071535,
      "AWATER": 190599026,
      "County_state": "Hubbard,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.183216,
              47.412773
            ],
            [
              -95.182711,
              47.325524
            ],
            [
              -95.169633,
              47.325399
            ],
            [
              -95.169172,
              47.152515
            ],
            [
              -95.1703,
              46.978175
            ],
            [
              -95.162266,
              46.97824
            ],
            [
              -95.16301,
              46.80472
            ],
            [
              -94.787126,
              46.803943
            ],
            [
              -94.659942,
              46.803716
            ],
            [
              -94.659387,
              46.890702
            ],
            [
              -94.65837,
              46.97694
            ],
            [
              -94.663638,
              46.977702
            ],
            [
              -94.663891,
              47.143055
            ],
            [
              -94.671456,
              47.322781
            ],
            [
              -94.670214,
              47.410151
            ],
            [
              -94.68316,
              47.409988
            ],
            [
              -94.799934,
              47.409848
            ],
            [
              -94.927526,
              47.41173
            ],
            [
              -95.183216,
              47.412773
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "011",
      "COUNTYNS": "00659451",
      "AFFGEOID": "0500000US27011",
      "GEOID": "27011",
      "NAME": "Big Stone",
      "LSAD": "06",
      "ALAND": 1292477791,
      "AWATER": 75340289,
      "County_state": "Big Stone,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.835451,
              45.586129
            ],
            [
              -96.801987,
              45.555414
            ],
            [
              -96.799102,
              45.554225
            ],
            [
              -96.79384,
              45.550724
            ],
            [
              -96.784863,
              45.5413
            ],
            [
              -96.781036,
              45.535972
            ],
            [
              -96.76528,
              45.521414
            ],
            [
              -96.760591,
              45.514895
            ],
            [
              -96.752865,
              45.502163
            ],
            [
              -96.745487,
              45.488712
            ],
            [
              -96.743683,
              45.484439
            ],
            [
              -96.743486,
              45.480649
            ],
            [
              -96.742509,
              45.478723
            ],
            [
              -96.738446,
              45.473499
            ],
            [
              -96.736837,
              45.466775
            ],
            [
              -96.732739,
              45.458737
            ],
            [
              -96.731396,
              45.45702
            ],
            [
              -96.72425,
              45.451482
            ],
            [
              -96.710786,
              45.43693
            ],
            [
              -96.702006,
              45.426247
            ],
            [
              -96.692541,
              45.417338
            ],
            [
              -96.683753,
              45.411556
            ],
            [
              -96.680454,
              45.410499
            ],
            [
              -96.675447,
              45.410216
            ],
            [
              -96.670301,
              45.410545
            ],
            [
              -96.662258,
              45.409011
            ],
            [
              -96.647888,
              45.410126
            ],
            [
              -96.640624,
              45.409227
            ],
            [
              -96.631204,
              45.409238
            ],
            [
              -96.617726,
              45.408092
            ],
            [
              -96.60118,
              45.403181
            ],
            [
              -96.584764,
              45.395705
            ],
            [
              -96.578879,
              45.392295
            ],
            [
              -96.571364,
              45.389871
            ],
            [
              -96.562142,
              45.38609
            ],
            [
              -96.545973,
              45.38105
            ],
            [
              -96.539722,
              45.380338
            ],
            [
              -96.530944,
              45.378495
            ],
            [
              -96.521787,
              45.375645
            ],
            [
              -96.508132,
              45.367832
            ],
            [
              -96.502006,
              45.365473
            ],
            [
              -96.489065,
              45.357071
            ],
            [
              -96.482556,
              45.346273
            ],
            [
              -96.479323,
              45.339644
            ],
            [
              -96.47038677579108,
              45.3266054662554
            ],
            [
              -96.469246,
              45.324941
            ],
            [
              -96.468756,
              45.320564
            ],
            [
              -96.468027,
              45.318619
            ],
            [
              -96.466644,
              45.317162
            ],
            [
              -96.46191,
              45.313884
            ],
            [
              -96.457781,
              45.30761
            ],
            [
              -96.456941,
              45.303652
            ],
            [
              -96.454094,
              45.301546
            ],
            [
              -96.453067,
              45.298115
            ],
            [
              -96.452791,
              45.28428
            ],
            [
              -96.452949,
              45.269059
            ],
            [
              -96.452947585722,
              45.268924993818395
            ],
            [
              -96.41753,
              45.266516
            ],
            [
              -96.406964,
              45.25652
            ],
            [
              -96.37202,
              45.26035
            ],
            [
              -96.283393,
              45.246911
            ],
            [
              -96.27455,
              45.22266
            ],
            [
              -96.250676,
              45.219029
            ],
            [
              -96.223872,
              45.220756
            ],
            [
              -96.173033,
              45.192862
            ],
            [
              -96.138165,
              45.193286
            ],
            [
              -96.107596,
              45.18319
            ],
            [
              -96.103614,
              45.176675
            ],
            [
              -96.10371,
              45.239766
            ],
            [
              -96.118054,
              45.239784
            ],
            [
              -96.118326,
              45.391529
            ],
            [
              -96.117036,
              45.411993
            ],
            [
              -96.241328,
              45.412667
            ],
            [
              -96.241431,
              45.58561
            ],
            [
              -96.253026,
              45.585526
            ],
            [
              -96.835451,
              45.586129
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "159",
      "COUNTYNS": "00659524",
      "AFFGEOID": "0500000US27159",
      "GEOID": "27159",
      "NAME": "Wadena",
      "LSAD": "06",
      "ALAND": 1388921383,
      "AWATER": 18038938,
      "County_state": "Wadena,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.16371,
              46.71764
            ],
            [
              -95.163392,
              46.629938
            ],
            [
              -95.15554,
              46.62994
            ],
            [
              -95.15557,
              46.36888
            ],
            [
              -94.731442,
              46.368605
            ],
            [
              -94.727958,
              46.38071
            ],
            [
              -94.751798,
              46.379053
            ],
            [
              -94.779121,
              46.394215
            ],
            [
              -94.785982,
              46.630612
            ],
            [
              -94.786633,
              46.716261
            ],
            [
              -94.787126,
              46.803943
            ],
            [
              -95.16301,
              46.80472
            ],
            [
              -95.16371,
              46.71764
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "023",
      "COUNTYNS": "00659457",
      "AFFGEOID": "0500000US27023",
      "GEOID": "27023",
      "NAME": "Chippewa",
      "LSAD": "06",
      "ALAND": 1505064475,
      "AWATER": 17233195,
      "County_state": "Chippewa,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.036698,
              45.152206
            ],
            [
              -96.012472,
              45.123726
            ],
            [
              -96.003403,
              45.122313
            ],
            [
              -95.980308,
              45.097511
            ],
            [
              -95.883696,
              45.028013
            ],
            [
              -95.869409,
              45.023918
            ],
            [
              -95.860504,
              45.007264
            ],
            [
              -95.824614,
              44.986884
            ],
            [
              -95.82315,
              44.965022
            ],
            [
              -95.804281,
              44.949197
            ],
            [
              -95.770171,
              44.943545
            ],
            [
              -95.74352,
              44.944235
            ],
            [
              -95.736696,
              44.93604
            ],
            [
              -95.71936,
              44.921524
            ],
            [
              -95.709568,
              44.903575
            ],
            [
              -95.672179,
              44.904195
            ],
            [
              -95.672819,
              44.896596
            ],
            [
              -95.655587,
              44.889071
            ],
            [
              -95.641859,
              44.874547
            ],
            [
              -95.633246,
              44.878062
            ],
            [
              -95.614643,
              44.859634
            ],
            [
              -95.615296,
              44.850728
            ],
            [
              -95.576881,
              44.838896
            ],
            [
              -95.560203,
              44.823183
            ],
            [
              -95.535689,
              44.811478
            ],
            [
              -95.545098,
              44.803528
            ],
            [
              -95.519483,
              44.801835
            ],
            [
              -95.498456,
              44.786867
            ],
            [
              -95.525482,
              44.783059
            ],
            [
              -95.529166,
              44.770802
            ],
            [
              -95.511631,
              44.765315
            ],
            [
              -95.501501,
              44.771413
            ],
            [
              -95.500968,
              44.771133
            ],
            [
              -95.499639,
              44.770643
            ],
            [
              -95.497861,
              44.770219
            ],
            [
              -95.49579,
              44.769944
            ],
            [
              -95.495318,
              44.769794
            ],
            [
              -95.490001,
              44.751408
            ],
            [
              -95.482843,
              44.752505
            ],
            [
              -95.484376,
              44.796516
            ],
            [
              -95.482889,
              44.890712
            ],
            [
              -95.24852,
              44.89131
            ],
            [
              -95.246906,
              45.065384
            ],
            [
              -95.24649,
              45.152384
            ],
            [
              -96.036698,
              45.152206
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "157",
      "COUNTYNS": "00659523",
      "AFFGEOID": "0500000US27157",
      "GEOID": "27157",
      "NAME": "Wabasha",
      "LSAD": "06",
      "ALAND": 1354359268,
      "AWATER": 68954671,
      "County_state": "Wabasha,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.550733,
              44.368521
            ],
            [
              -92.550871,
              44.194916
            ],
            [
              -92.438411,
              44.194517
            ],
            [
              -92.318986,
              44.194057
            ],
            [
              -92.318971,
              44.107734
            ],
            [
              -92.079491,
              44.106988
            ],
            [
              -92.079236,
              44.192966
            ],
            [
              -91.8583929427025,
              44.1930031501986
            ],
            [
              -91.864387,
              44.196574
            ],
            [
              -91.872369,
              44.199167
            ],
            [
              -91.875158,
              44.200575
            ],
            [
              -91.876056,
              44.202728
            ],
            [
              -91.876356,
              44.209575
            ],
            [
              -91.877429,
              44.212921
            ],
            [
              -91.880265,
              44.216555
            ],
            [
              -91.88979,
              44.226286
            ],
            [
              -91.892698,
              44.231105
            ],
            [
              -91.892963,
              44.235149
            ],
            [
              -91.887905,
              44.246398
            ],
            [
              -91.88704,
              44.251772
            ],
            [
              -91.887824,
              44.254171
            ],
            [
              -91.889132,
              44.25606
            ],
            [
              -91.896008,
              44.262871
            ],
            [
              -91.89676,
              44.265447
            ],
            [
              -91.895652,
              44.273008
            ],
            [
              -91.896388,
              44.27469
            ],
            [
              -91.898697,
              44.277172
            ],
            [
              -91.905789,
              44.281614
            ],
            [
              -91.920282,
              44.286496
            ],
            [
              -91.922205,
              44.287811
            ],
            [
              -91.924613,
              44.291815
            ],
            [
              -91.924975,
              44.294819
            ],
            [
              -91.924102,
              44.297095
            ],
            [
              -91.921028,
              44.301069
            ],
            [
              -91.91436,
              44.30823
            ],
            [
              -91.913574,
              44.310392
            ],
            [
              -91.913534,
              44.311392
            ],
            [
              -91.916191,
              44.318094
            ],
            [
              -91.918625,
              44.322671
            ],
            [
              -91.92559,
              44.333548
            ],
            [
              -91.928224,
              44.335473
            ],
            [
              -91.941311,
              44.340978
            ],
            [
              -91.949599,
              44.348796
            ],
            [
              -91.95282,
              44.352982
            ],
            [
              -91.959523,
              44.359404
            ],
            [
              -91.9636,
              44.362112
            ],
            [
              -91.97022850758609,
              44.3658210208965
            ],
            [
              -91.970266,
              44.365842
            ],
            [
              -91.974922,
              44.367516
            ],
            [
              -91.978574,
              44.368372
            ],
            [
              -91.983974,
              44.368448
            ],
            [
              -91.987289,
              44.369119
            ],
            [
              -91.993984,
              44.3718
            ],
            [
              -92.000165,
              44.374966
            ],
            [
              -92.001198663636,
              44.3757981901027
            ],
            [
              -92.002838,
              44.377118
            ],
            [
              -92.006179,
              44.378825
            ],
            [
              -92.008589,
              44.379626
            ],
            [
              -92.019313,
              44.381217
            ],
            [
              -92.038147,
              44.388731
            ],
            [
              -92.046285,
              44.394398
            ],
            [
              -92.053549,
              44.401375
            ],
            [
              -92.056486,
              44.402729
            ],
            [
              -92.061637,
              44.404124
            ],
            [
              -92.072267,
              44.404017
            ],
            [
              -92.078605,
              44.404869
            ],
            [
              -92.08363983219859,
              44.4071887773643
            ],
            [
              -92.087241,
              44.408848
            ],
            [
              -92.097415,
              44.411464
            ],
            [
              -92.111085,
              44.413948
            ],
            [
              -92.115296,
              44.416056
            ],
            [
              -92.121106,
              44.420572
            ],
            [
              -92.124513,
              44.422115
            ],
            [
              -92.139569,
              44.424673
            ],
            [
              -92.162454,
              44.427208
            ],
            [
              -92.17028,
              44.428598
            ],
            [
              -92.195378,
              44.433792
            ],
            [
              -92.215163,
              44.438503
            ],
            [
              -92.221083,
              44.440386
            ],
            [
              -92.232472,
              44.445434
            ],
            [
              -92.237325,
              44.449417
            ],
            [
              -92.24201,
              44.454254
            ],
            [
              -92.430277,
              44.455321
            ],
            [
              -92.430629,
              44.368291
            ],
            [
              -92.550733,
              44.368521
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "071",
      "COUNTYNS": "00659481",
      "AFFGEOID": "0500000US27071",
      "GEOID": "27071",
      "NAME": "Koochiching",
      "LSAD": "06",
      "ALAND": 8039819178,
      "AWATER": 129002560,
      "County_state": "Koochiching,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.430302595501,
              48.7009500088477
            ],
            [
              -94.428702,
              48.367333
            ],
            [
              -94.42361,
              48.019808
            ],
            [
              -94.41776,
              48.019811
            ],
            [
              -94.417747,
              47.93311
            ],
            [
              -94.418543,
              47.845816
            ],
            [
              -93.776173,
              47.846404
            ],
            [
              -93.775772,
              47.89895
            ],
            [
              -93.081158,
              47.89187
            ],
            [
              -93.088261,
              47.978609
            ],
            [
              -93.088984,
              48.067923
            ],
            [
              -93.096488,
              48.067826
            ],
            [
              -93.095175,
              48.249922
            ],
            [
              -93.088438,
              48.627597
            ],
            [
              -93.14242,
              48.624924
            ],
            [
              -93.178095,
              48.623339
            ],
            [
              -93.17999,
              48.624926
            ],
            [
              -93.184091,
              48.628375
            ],
            [
              -93.207398,
              48.642474
            ],
            [
              -93.254854,
              48.642784
            ],
            [
              -93.347528,
              48.62662
            ],
            [
              -93.348183,
              48.626414
            ],
            [
              -93.349095,
              48.624935
            ],
            [
              -93.353138,
              48.615709
            ],
            [
              -93.35324,
              48.613378
            ],
            [
              -93.354135,
              48.61235
            ],
            [
              -93.35541,
              48.611595
            ],
            [
              -93.35641,
              48.611778
            ],
            [
              -93.362132,
              48.613832
            ],
            [
              -93.367025,
              48.608283
            ],
            [
              -93.367666,
              48.60702
            ],
            [
              -93.371156,
              48.605085
            ],
            [
              -93.383807,
              48.605149
            ],
            [
              -93.395022,
              48.603303
            ],
            [
              -93.398974,
              48.603905
            ],
            [
              -93.40366,
              48.607593
            ],
            [
              -93.404205,
              48.609351
            ],
            [
              -93.405269,
              48.609344
            ],
            [
              -93.40856,
              48.608415
            ],
            [
              -93.414026,
              48.605605
            ],
            [
              -93.425483,
              48.6013
            ],
            [
              -93.428328,
              48.599777
            ],
            [
              -93.434141,
              48.595138
            ],
            [
              -93.438494,
              48.59338
            ],
            [
              -93.464308,
              48.591792
            ],
            [
              -93.465199,
              48.590659
            ],
            [
              -93.466007,
              48.587291
            ],
            [
              -93.461731,
              48.57403
            ],
            [
              -93.457046,
              48.567199
            ],
            [
              -93.456675,
              48.561834
            ],
            [
              -93.458246,
              48.555291
            ],
            [
              -93.460798,
              48.550552
            ],
            [
              -93.465392,
              48.546668
            ],
            [
              -93.467504,
              48.545664
            ],
            [
              -93.481471,
              48.543146
            ],
            [
              -93.500153,
              48.541202
            ],
            [
              -93.515457,
              48.534792
            ],
            [
              -93.518691,
              48.533997
            ],
            [
              -93.532087,
              48.532453
            ],
            [
              -93.540369,
              48.529877
            ],
            [
              -93.547191,
              48.528684
            ],
            [
              -93.562062,
              48.528897
            ],
            [
              -93.578333,
              48.52652
            ],
            [
              -93.580711,
              48.526667
            ],
            [
              -93.587957,
              48.528881
            ],
            [
              -93.594379,
              48.528793
            ],
            [
              -93.598212,
              48.527154
            ],
            [
              -93.603752,
              48.523326
            ],
            [
              -93.60587,
              48.522472
            ],
            [
              -93.610618,
              48.521661
            ],
            [
              -93.612844,
              48.521876
            ],
            [
              -93.618321,
              48.52397
            ],
            [
              -93.622333,
              48.52651
            ],
            [
              -93.626447,
              48.530985
            ],
            [
              -93.628865,
              48.53121
            ],
            [
              -93.632327,
              48.530092
            ],
            [
              -93.635476,
              48.527702
            ],
            [
              -93.638199,
              48.522533
            ],
            [
              -93.64144,
              48.519238
            ],
            [
              -93.643091,
              48.518294
            ],
            [
              -93.645397,
              48.517281
            ],
            [
              -93.656652,
              48.515731
            ],
            [
              -93.674568,
              48.516297
            ],
            [
              -93.690901,
              48.514588
            ],
            [
              -93.694676,
              48.514774
            ],
            [
              -93.703303,
              48.51715
            ],
            [
              -93.709147,
              48.518029
            ],
            [
              -93.72368,
              48.517329
            ],
            [
              -93.732139,
              48.517995
            ],
            [
              -93.741843,
              48.517347
            ],
            [
              -93.752942,
              48.51512
            ],
            [
              -93.756483,
              48.515366
            ],
            [
              -93.763176,
              48.516118
            ],
            [
              -93.771741,
              48.515825
            ],
            [
              -93.784657,
              48.51549
            ],
            [
              -93.794454,
              48.516021
            ],
            [
              -93.797436,
              48.518356
            ],
            [
              -93.80152,
              48.520551
            ],
            [
              -93.812149,
              48.524778
            ],
            [
              -93.815178,
              48.526508
            ],
            [
              -93.818253,
              48.530046
            ],
            [
              -93.818853,
              48.532669
            ],
            [
              -93.818375,
              48.534442
            ],
            [
              -93.817572,
              48.535833
            ],
            [
              -93.812223,
              48.540509
            ],
            [
              -93.811201,
              48.542385
            ],
            [
              -93.811303,
              48.545543
            ],
            [
              -93.812278,
              48.549111
            ],
            [
              -93.812098,
              48.550664
            ],
            [
              -93.808973,
              48.555897
            ],
            [
              -93.806748,
              48.561779
            ],
            [
              -93.805369,
              48.568393
            ],
            [
              -93.80527,
              48.570299
            ],
            [
              -93.806763,
              48.577616
            ],
            [
              -93.807984,
              48.580297
            ],
            [
              -93.812037,
              48.584944
            ],
            [
              -93.818518,
              48.595314
            ],
            [
              -93.820067,
              48.603755
            ],
            [
              -93.822644,
              48.609067
            ],
            [
              -93.824144,
              48.610724
            ],
            [
              -93.827959,
              48.613001
            ],
            [
              -93.834323,
              48.624954
            ],
            [
              -93.837392,
              48.627098
            ],
            [
              -93.840754,
              48.628548
            ],
            [
              -93.844008,
              48.629395
            ],
            [
              -93.851618,
              48.630108
            ],
            [
              -93.886934,
              48.630779
            ],
            [
              -93.91153,
              48.634673
            ],
            [
              -93.914357,
              48.63432
            ],
            [
              -93.915494,
              48.632667
            ],
            [
              -93.916649,
              48.632156
            ],
            [
              -93.927004,
              48.63122
            ],
            [
              -93.944221,
              48.632294
            ],
            [
              -93.954413,
              48.633744
            ],
            [
              -93.960632,
              48.636496
            ],
            [
              -93.963375,
              48.637151
            ],
            [
              -93.976535,
              48.637573
            ],
            [
              -93.990082,
              48.639738
            ],
            [
              -94.000675,
              48.642777
            ],
            [
              -94.006933,
              48.643193
            ],
            [
              -94.029491,
              48.640861
            ],
            [
              -94.035616,
              48.641018
            ],
            [
              -94.043187,
              48.643416
            ],
            [
              -94.052452,
              48.64402
            ],
            [
              -94.060267,
              48.643115
            ],
            [
              -94.064243,
              48.643717
            ],
            [
              -94.065775,
              48.646104
            ],
            [
              -94.071357,
              48.645895
            ],
            [
              -94.076675,
              48.644203
            ],
            [
              -94.091244,
              48.643669
            ],
            [
              -94.099898,
              48.645863
            ],
            [
              -94.110031,
              48.644192
            ],
            [
              -94.126336,
              48.644447
            ],
            [
              -94.138682,
              48.645714
            ],
            [
              -94.157387,
              48.645766
            ],
            [
              -94.167725,
              48.648104
            ],
            [
              -94.188581,
              48.650402
            ],
            [
              -94.199517,
              48.650996
            ],
            [
              -94.214448,
              48.649382
            ],
            [
              -94.224276,
              48.649527
            ],
            [
              -94.233575,
              48.652336
            ],
            [
              -94.244394,
              48.653442
            ],
            [
              -94.246841,
              48.654224
            ],
            [
              -94.250191,
              48.656323
            ],
            [
              -94.250497,
              48.656654
            ],
            [
              -94.254577,
              48.661375
            ],
            [
              -94.254643,
              48.663888
            ],
            [
              -94.250623,
              48.678236
            ],
            [
              -94.251169,
              48.683514
            ],
            [
              -94.252753,
              48.686325
            ],
            [
              -94.25813,
              48.691834
            ],
            [
              -94.260541,
              48.696381
            ],
            [
              -94.264473,
              48.698919
            ],
            [
              -94.274345,
              48.699882
            ],
            [
              -94.281797,
              48.705255
            ],
            [
              -94.290737,
              48.707747
            ],
            [
              -94.308446,
              48.710239
            ],
            [
              -94.328434,
              48.704481
            ],
            [
              -94.342758,
              48.703382
            ],
            [
              -94.353046,
              48.704132
            ],
            [
              -94.368583,
              48.706434
            ],
            [
              -94.378216,
              48.710272
            ],
            [
              -94.384221,
              48.711806
            ],
            [
              -94.388848,
              48.711945
            ],
            [
              -94.406318,
              48.710535
            ],
            [
              -94.416191,
              48.710948
            ],
            [
              -94.418919,
              48.710172
            ],
            [
              -94.421405,
              48.708756
            ],
            [
              -94.424203,
              48.705352
            ],
            [
              -94.430302595501,
              48.7009500088477
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "107",
      "COUNTYNS": "00659499",
      "AFFGEOID": "0500000US27107",
      "GEOID": "27107",
      "NAME": "Norman",
      "LSAD": "06",
      "ALAND": 2260512389,
      "AWATER": 10208057,
      "County_state": "Norman,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.864261,
              47.420972
            ],
            [
              -96.864261,
              47.419539
            ],
            [
              -96.863593,
              47.418775
            ],
            [
              -96.861231,
              47.41781
            ],
            [
              -96.861095,
              47.417056
            ],
            [
              -96.86207,
              47.415159
            ],
            [
              -96.861833,
              47.414337
            ],
            [
              -96.858094,
              47.410317
            ],
            [
              -96.853325,
              47.408889
            ],
            [
              -96.852656,
              47.407647
            ],
            [
              -96.852739,
              47.405909
            ],
            [
              -96.848071,
              47.403158
            ],
            [
              -96.84511,
              47.400483
            ],
            [
              -96.844919,
              47.399815
            ],
            [
              -96.845874,
              47.396185
            ],
            [
              -96.845492,
              47.394179
            ],
            [
              -96.841767,
              47.39246
            ],
            [
              -96.840717,
              47.391314
            ],
            [
              -96.840621,
              47.389881
            ],
            [
              -96.841099,
              47.38415
            ],
            [
              -96.845588,
              47.381571
            ],
            [
              -96.846925,
              47.376891
            ],
            [
              -96.848931,
              47.375363
            ],
            [
              -96.852676,
              47.374973
            ],
            [
              -96.853754,
              47.373405
            ],
            [
              -96.852035,
              47.371876
            ],
            [
              -96.848907,
              47.370565
            ],
            [
              -96.848597,
              47.369584
            ],
            [
              -96.849552,
              47.368247
            ],
            [
              -96.852226,
              47.367291
            ],
            [
              -96.852417,
              47.366241
            ],
            [
              -96.849456,
              47.363662
            ],
            [
              -96.849265,
              47.359841
            ],
            [
              -96.848119,
              47.358026
            ],
            [
              -96.846877,
              47.356785
            ],
            [
              -96.844298,
              47.356021
            ],
            [
              -96.843439,
              47.354397
            ],
            [
              -96.845158,
              47.34943
            ],
            [
              -96.844012,
              47.346182
            ],
            [
              -96.840586,
              47.340956
            ],
            [
              -96.836609,
              47.338684
            ],
            [
              -96.835845,
              47.335914
            ],
            [
              -96.83852,
              47.33238
            ],
            [
              -96.838329,
              47.331043
            ],
            [
              -96.836036,
              47.329706
            ],
            [
              -96.835177,
              47.32856
            ],
            [
              -96.835177,
              47.326267
            ],
            [
              -96.836609,
              47.323975
            ],
            [
              -96.835845,
              47.321014
            ],
            [
              -96.836036,
              47.320059
            ],
            [
              -96.836991,
              47.318817
            ],
            [
              -96.841194,
              47.317575
            ],
            [
              -96.841958,
              47.316907
            ],
            [
              -96.842531,
              47.312418
            ],
            [
              -96.841003,
              47.311558
            ],
            [
              -96.837045,
              47.311391
            ],
            [
              -96.835735,
              47.310843
            ],
            [
              -96.832884,
              47.307069
            ],
            [
              -96.832884,
              47.30449
            ],
            [
              -96.843922,
              47.29302
            ],
            [
              -96.844088,
              47.289981
            ],
            [
              -96.841465,
              47.284041
            ],
            [
              -96.84022,
              47.276981
            ],
            [
              -96.840353,
              47.275496
            ],
            [
              -96.842245,
              47.273351
            ],
            [
              -96.8432,
              47.270486
            ],
            [
              -96.842531,
              47.269531
            ],
            [
              -96.839761,
              47.268767
            ],
            [
              -96.838997,
              47.267716
            ],
            [
              -96.839857,
              47.265997
            ],
            [
              -96.842054,
              47.265328
            ],
            [
              -96.842627,
              47.263991
            ],
            [
              -96.842531,
              47.262845
            ],
            [
              -96.84129,
              47.262463
            ],
            [
              -96.840717,
              47.261221
            ],
            [
              -96.841003,
              47.259215
            ],
            [
              -96.841672,
              47.258164
            ],
            [
              -96.840048,
              47.256159
            ],
            [
              -96.839857,
              47.25549
            ],
            [
              -96.840525,
              47.253866
            ],
            [
              -96.840143,
              47.253102
            ],
            [
              -96.835368,
              47.250428
            ],
            [
              -96.834699,
              47.248135
            ],
            [
              -96.83489,
              47.246416
            ],
            [
              -96.837278,
              47.244219
            ],
            [
              -96.838233,
              47.242882
            ],
            [
              -96.838233,
              47.241831
            ],
            [
              -96.83766,
              47.240876
            ],
            [
              -96.83358911211658,
              47.2380365686549
            ],
            [
              -96.832946,
              47.237588
            ],
            [
              -96.832693,
              47.236196
            ],
            [
              -96.833362,
              47.23505
            ],
            [
              -96.836036,
              47.233999
            ],
            [
              -96.837564,
              47.231802
            ],
            [
              -96.837374,
              47.229254
            ],
            [
              -96.835654,
              47.227217
            ],
            [
              -96.835654,
              47.226549
            ],
            [
              -96.838806,
              47.22502
            ],
            [
              -96.839284,
              47.223874
            ],
            [
              -96.838329,
              47.222059
            ],
            [
              -96.835941,
              47.221009
            ],
            [
              -96.835654,
              47.219289
            ],
            [
              -96.836514,
              47.216137
            ],
            [
              -96.833553,
              47.212794
            ],
            [
              -96.833362,
              47.211457
            ],
            [
              -96.833648,
              47.210406
            ],
            [
              -96.835463,
              47.208401
            ],
            [
              -96.835177,
              47.207445
            ],
            [
              -96.833457,
              47.20649
            ],
            [
              -96.83212,
              47.204866
            ],
            [
              -96.832789,
              47.203911
            ],
            [
              -96.83766,
              47.201141
            ],
            [
              -96.838615,
              47.199613
            ],
            [
              -96.838806,
              47.197894
            ],
            [
              -96.838233,
              47.196366
            ],
            [
              -96.8368,
              47.195028
            ],
            [
              -96.833075,
              47.193596
            ],
            [
              -96.83126,
              47.191781
            ],
            [
              -96.831165,
              47.190826
            ],
            [
              -96.832502,
              47.188342
            ],
            [
              -96.832407,
              47.187483
            ],
            [
              -96.831451,
              47.185572
            ],
            [
              -96.830401,
              47.184617
            ],
            [
              -96.828299,
              47.183948
            ],
            [
              -96.826962,
              47.182802
            ],
            [
              -96.826676,
              47.181561
            ],
            [
              -96.826962,
              47.180128
            ],
            [
              -96.829446,
              47.177262
            ],
            [
              -96.829828,
              47.176307
            ],
            [
              -96.829637,
              47.17497
            ],
            [
              -96.825147,
              47.172295
            ],
            [
              -96.824288,
              47.170863
            ],
            [
              -96.824479,
              47.167042
            ],
            [
              -96.822091,
              47.165036
            ],
            [
              -96.822377,
              47.162744
            ],
            [
              -96.824288,
              47.16112
            ],
            [
              -96.824861,
              47.159783
            ],
            [
              -96.82467,
              47.159019
            ],
            [
              -96.822707,
              47.157668
            ],
            [
              -96.822405,
              47.156914
            ],
            [
              -96.822706,
              47.156229
            ],
            [
              -96.828013,
              47.153956
            ],
            [
              -96.83126,
              47.1509
            ],
            [
              -96.8312096159494,
              47.1505219877248
            ],
            [
              -96.194671,
              47.151149
            ],
            [
              -96.067069,
              47.151813
            ],
            [
              -96.067621,
              47.499136
            ],
            [
              -96.8517908344828,
              47.498752013793094
            ],
            [
              -96.851653,
              47.497098
            ],
            [
              -96.857002,
              47.493468
            ],
            [
              -96.857957,
              47.492513
            ],
            [
              -96.85853,
              47.490889
            ],
            [
              -96.85853,
              47.489934
            ],
            [
              -96.855856,
              47.48831
            ],
            [
              -96.855665,
              47.48726
            ],
            [
              -96.856142,
              47.48554
            ],
            [
              -96.857957,
              47.484681
            ],
            [
              -96.85853,
              47.483917
            ],
            [
              -96.85853,
              47.482484
            ],
            [
              -96.858148,
              47.481624
            ],
            [
              -96.854996,
              47.479618
            ],
            [
              -96.85471,
              47.478281
            ],
            [
              -96.855856,
              47.475702
            ],
            [
              -96.859103,
              47.472837
            ],
            [
              -96.859868,
              47.470926
            ],
            [
              -96.859555,
              47.466865
            ],
            [
              -96.856811,
              47.46319
            ],
            [
              -96.85748,
              47.460229
            ],
            [
              -96.859581,
              47.4587
            ],
            [
              -96.859963,
              47.457363
            ],
            [
              -96.859677,
              47.456026
            ],
            [
              -96.858148,
              47.454498
            ],
            [
              -96.858244,
              47.453351
            ],
            [
              -96.859239,
              47.451557
            ],
            [
              -96.859537,
              47.445662
            ],
            [
              -96.85748,
              47.441603
            ],
            [
              -96.85748,
              47.440457
            ],
            [
              -96.859772,
              47.437209
            ],
            [
              -96.860059,
              47.435681
            ],
            [
              -96.85853,
              47.433389
            ],
            [
              -96.860823,
              47.430237
            ],
            [
              -96.861014,
              47.428995
            ],
            [
              -96.860632,
              47.427658
            ],
            [
              -96.858721,
              47.426129
            ],
            [
              -96.859581,
              47.424028
            ],
            [
              -96.862924,
              47.422309
            ],
            [
              -96.864261,
              47.420972
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "005",
      "COUNTYNS": "00659448",
      "AFFGEOID": "0500000US27005",
      "GEOID": "27005",
      "NAME": "Becker",
      "LSAD": "06",
      "ALAND": 3406166398,
      "AWATER": 336840617,
      "County_state": "Becker,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.194671,
              47.151149
            ],
            [
              -96.193571,
              46.977371
            ],
            [
              -96.174108,
              46.977375
            ],
            [
              -96.177027,
              46.804851
            ],
            [
              -96.174587,
              46.717854
            ],
            [
              -95.794775,
              46.71641
            ],
            [
              -95.542719,
              46.71595
            ],
            [
              -95.48856,
              46.717539
            ],
            [
              -95.435464,
              46.715917
            ],
            [
              -95.16371,
              46.71764
            ],
            [
              -95.16301,
              46.80472
            ],
            [
              -95.162266,
              46.97824
            ],
            [
              -95.1703,
              46.978175
            ],
            [
              -95.169172,
              47.152515
            ],
            [
              -95.551186,
              47.151453
            ],
            [
              -96.067069,
              47.151813
            ],
            [
              -96.194671,
              47.151149
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "049",
      "COUNTYNS": "00659470",
      "AFFGEOID": "0500000US27049",
      "GEOID": "27049",
      "NAME": "Goodhue",
      "LSAD": "06",
      "ALAND": 1960231247,
      "AWATER": 61007223,
      "County_state": "Goodhue,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.040237,
              44.370979
            ],
            [
              -93.041159,
              44.196726
            ],
            [
              -92.925209,
              44.196918
            ],
            [
              -92.79894,
              44.196547
            ],
            [
              -92.678717,
              44.195516
            ],
            [
              -92.550871,
              44.194916
            ],
            [
              -92.550733,
              44.368521
            ],
            [
              -92.430629,
              44.368291
            ],
            [
              -92.430277,
              44.455321
            ],
            [
              -92.24201,
              44.454254
            ],
            [
              -92.244884,
              44.456842
            ],
            [
              -92.249071,
              44.459524
            ],
            [
              -92.262476,
              44.465149
            ],
            [
              -92.276784,
              44.473649
            ],
            [
              -92.282364,
              44.477707
            ],
            [
              -92.291005,
              44.485464
            ],
            [
              -92.297122,
              44.492732
            ],
            [
              -92.302215,
              44.500298
            ],
            [
              -92.302961,
              44.503601
            ],
            [
              -92.302466,
              44.516487
            ],
            [
              -92.303046,
              44.518646
            ],
            [
              -92.303527,
              44.519822
            ],
            [
              -92.307957,
              44.524475
            ],
            [
              -92.310827,
              44.528756
            ],
            [
              -92.314071,
              44.538014
            ],
            [
              -92.3162276738383,
              44.5409661360087
            ],
            [
              -92.317357,
              44.542512
            ],
            [
              -92.319938,
              44.54494
            ],
            [
              -92.329013,
              44.550895
            ],
            [
              -92.336114,
              44.554004
            ],
            [
              -92.347567,
              44.557149
            ],
            [
              -92.361518,
              44.558935
            ],
            [
              -92.368298,
              44.559182
            ],
            [
              -92.38904,
              44.557697
            ],
            [
              -92.399281,
              44.558292
            ],
            [
              -92.415089,
              44.560359
            ],
            [
              -92.420702,
              44.562041
            ],
            [
              -92.425774,
              44.564602
            ],
            [
              -92.431101,
              44.565786
            ],
            [
              -92.433256,
              44.5655
            ],
            [
              -92.440745,
              44.562833
            ],
            [
              -92.455105,
              44.561886
            ],
            [
              -92.470209,
              44.565036
            ],
            [
              -92.481001,
              44.568276
            ],
            [
              -92.48474,
              44.568067
            ],
            [
              -92.490472,
              44.566205
            ],
            [
              -92.493808,
              44.566063
            ],
            [
              -92.508759,
              44.570325
            ],
            [
              -92.512564,
              44.571801
            ],
            [
              -92.518358,
              44.575183
            ],
            [
              -92.520878,
              44.5752
            ],
            [
              -92.527337,
              44.573554
            ],
            [
              -92.540551,
              44.567258
            ],
            [
              -92.544346,
              44.566986
            ],
            [
              -92.54806,
              44.567792
            ],
            [
              -92.549957,
              44.568988
            ],
            [
              -92.55151,
              44.571607
            ],
            [
              -92.551182,
              44.573449
            ],
            [
              -92.549685,
              44.576
            ],
            [
              -92.54928,
              44.577704
            ],
            [
              -92.549777,
              44.58113
            ],
            [
              -92.560796,
              44.594956
            ],
            [
              -92.567226,
              44.60177
            ],
            [
              -92.569434,
              44.603539
            ],
            [
              -92.572943,
              44.604649
            ],
            [
              -92.577148,
              44.605054
            ],
            [
              -92.57885,
              44.603939
            ],
            [
              -92.581591,
              44.600863
            ],
            [
              -92.584711,
              44.599861
            ],
            [
              -92.586216,
              44.600088
            ],
            [
              -92.588797,
              44.601698
            ],
            [
              -92.590467,
              44.605936
            ],
            [
              -92.601516,
              44.612052
            ],
            [
              -92.607141,
              44.612433
            ],
            [
              -92.614569,
              44.61173
            ],
            [
              -92.618025,
              44.61287
            ],
            [
              -92.621456,
              44.615017
            ],
            [
              -92.623163,
              44.618224
            ],
            [
              -92.623348,
              44.620713
            ],
            [
              -92.622571,
              44.623518
            ],
            [
              -92.619774,
              44.629214
            ],
            [
              -92.619779,
              44.634195
            ],
            [
              -92.621733,
              44.638983
            ],
            [
              -92.632105,
              44.649027
            ],
            [
              -92.655807,
              44.65804
            ],
            [
              -92.660988,
              44.660884
            ],
            [
              -92.664699,
              44.66338
            ],
            [
              -92.686511,
              44.682096
            ],
            [
              -92.696491,
              44.689436
            ],
            [
              -92.700948,
              44.693751
            ],
            [
              -92.713198,
              44.701085
            ],
            [
              -92.732042,
              44.7136706398321
            ],
            [
              -92.732043,
              44.629493
            ],
            [
              -92.792584,
              44.629722
            ],
            [
              -92.792715,
              44.54324
            ],
            [
              -92.918977,
              44.54325
            ],
            [
              -92.919148,
              44.517569
            ],
            [
              -92.954826,
              44.507025
            ],
            [
              -92.984914,
              44.518822
            ],
            [
              -93.039339,
              44.515545
            ],
            [
              -93.039485,
              44.471871
            ],
            [
              -93.040237,
              44.370979
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "123",
      "COUNTYNS": "00659507",
      "AFFGEOID": "0500000US27123",
      "GEOID": "27123",
      "NAME": "Ramsey",
      "LSAD": "06",
      "ALAND": 394086546,
      "AWATER": 46423262,
      "County_state": "Ramsey,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.227828,
              45.107089
            ],
            [
              -93.227734,
              45.093217
            ],
            [
              -93.227237,
              45.065027
            ],
            [
              -93.227208,
              45.061619
            ],
            [
              -93.227059,
              45.049937
            ],
            [
              -93.226929,
              45.035671
            ],
            [
              -93.208092,
              45.035744
            ],
            [
              -93.207569,
              45.006239
            ],
            [
              -93.207587,
              44.988071
            ],
            [
              -93.207757,
              44.953209
            ],
            [
              -93.20266,
              44.948375
            ],
            [
              -93.200767,
              44.93413
            ],
            [
              -93.200334,
              44.908934
            ],
            [
              -93.194023,
              44.905414
            ],
            [
              -93.177201,
              44.893793
            ],
            [
              -93.178539,
              44.888338
            ],
            [
              -93.168176,
              44.888551
            ],
            [
              -93.1539,
              44.892898
            ],
            [
              -93.138497,
              44.903963
            ],
            [
              -93.128629,
              44.919648
            ],
            [
              -93.050888,
              44.919501
            ],
            [
              -93.048739,
              44.917354
            ],
            [
              -93.036802,
              44.899865
            ],
            [
              -93.020044,
              44.89075
            ],
            [
              -93.004424,
              44.890767
            ],
            [
              -92.98421,
              44.894594
            ],
            [
              -92.984735,
              44.963252
            ],
            [
              -92.984705,
              44.987605
            ],
            [
              -92.984771,
              45.029051
            ],
            [
              -92.984789,
              45.035411
            ],
            [
              -92.984767,
              45.057475
            ],
            [
              -92.984517,
              45.096655
            ],
            [
              -92.984549,
              45.115937
            ],
            [
              -92.984619,
              45.123622
            ],
            [
              -93.020518,
              45.123866
            ],
            [
              -93.106725,
              45.124103
            ],
            [
              -93.188706,
              45.124374
            ],
            [
              -93.212672,
              45.124418
            ],
            [
              -93.227696,
              45.124533
            ],
            [
              -93.227828,
              45.107089
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "173",
      "COUNTYNS": "00659531",
      "AFFGEOID": "0500000US27173",
      "GEOID": "27173",
      "NAME": "Yellow Medicine",
      "LSAD": "06",
      "ALAND": 1966062971,
      "AWATER": 10687958,
      "County_state": "Yellow Medicine,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.451829,
              44.797691
            ],
            [
              -96.451888,
              44.792299
            ],
            [
              -96.451823,
              44.790471
            ],
            [
              -96.45162,
              44.776191
            ],
            [
              -96.45138,
              44.761788
            ],
            [
              -96.451573,
              44.76051
            ],
            [
              -96.451232,
              44.718375
            ],
            [
              -96.451543,
              44.703135
            ],
            [
              -96.4517605285233,
              44.6313495894722
            ],
            [
              -96.09295,
              44.630486
            ],
            [
              -95.604001,
              44.629945
            ],
            [
              -95.594817,
              44.542305
            ],
            [
              -95.3604,
              44.541762
            ],
            [
              -95.360177,
              44.69854
            ],
            [
              -95.401355,
              44.71904
            ],
            [
              -95.441107,
              44.743516
            ],
            [
              -95.455683,
              44.740881
            ],
            [
              -95.477221,
              44.756133
            ],
            [
              -95.482843,
              44.752505
            ],
            [
              -95.490001,
              44.751408
            ],
            [
              -95.495318,
              44.769794
            ],
            [
              -95.49579,
              44.769944
            ],
            [
              -95.497861,
              44.770219
            ],
            [
              -95.499639,
              44.770643
            ],
            [
              -95.500968,
              44.771133
            ],
            [
              -95.501501,
              44.771413
            ],
            [
              -95.511631,
              44.765315
            ],
            [
              -95.529166,
              44.770802
            ],
            [
              -95.525482,
              44.783059
            ],
            [
              -95.498456,
              44.786867
            ],
            [
              -95.519483,
              44.801835
            ],
            [
              -95.545098,
              44.803528
            ],
            [
              -95.535689,
              44.811478
            ],
            [
              -95.560203,
              44.823183
            ],
            [
              -95.576881,
              44.838896
            ],
            [
              -95.615296,
              44.850728
            ],
            [
              -95.614643,
              44.859634
            ],
            [
              -95.633246,
              44.878062
            ],
            [
              -95.641859,
              44.874547
            ],
            [
              -95.655587,
              44.889071
            ],
            [
              -95.672819,
              44.896596
            ],
            [
              -95.672179,
              44.904195
            ],
            [
              -95.709568,
              44.903575
            ],
            [
              -95.71936,
              44.921524
            ],
            [
              -95.736696,
              44.93604
            ],
            [
              -95.737309,
              44.891479
            ],
            [
              -95.847452,
              44.891799
            ],
            [
              -95.849009,
              44.805347
            ],
            [
              -96.4515595397173,
              44.8055694812453
            ],
            [
              -96.451559,
              44.805468
            ],
            [
              -96.451829,
              44.797691
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "045",
      "COUNTYNS": "00659468",
      "AFFGEOID": "0500000US27045",
      "GEOID": "27045",
      "NAME": "Fillmore",
      "LSAD": "06",
      "ALAND": 2230746823,
      "AWATER": 2055507,
      "County_state": "Fillmore,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.448965,
              43.834105
            ],
            [
              -92.44894781237689,
              43.5004201689952
            ],
            [
              -92.408832,
              43.500614
            ],
            [
              -92.40613,
              43.500476
            ],
            [
              -92.388298,
              43.500483
            ],
            [
              -92.368908,
              43.500454
            ],
            [
              -92.279084,
              43.500436
            ],
            [
              -92.277425,
              43.500466
            ],
            [
              -92.198788,
              43.500527
            ],
            [
              -92.178863,
              43.500713
            ],
            [
              -92.103886,
              43.500735
            ],
            [
              -92.08997,
              43.500684
            ],
            [
              -92.079954,
              43.500647
            ],
            [
              -92.0798019678545,
              43.5006468106538
            ],
            [
              -91.949879,
              43.500485
            ],
            [
              -91.941837,
              43.500554
            ],
            [
              -91.850940149364,
              43.500655005997
            ],
            [
              -91.824848,
              43.500684
            ],
            [
              -91.807156,
              43.500648
            ],
            [
              -91.804925,
              43.500716
            ],
            [
              -91.77929,
              43.500803
            ],
            [
              -91.777688,
              43.500711
            ],
            [
              -91.761414,
              43.500637
            ],
            [
              -91.738446,
              43.500525
            ],
            [
              -91.736558,
              43.500561
            ],
            [
              -91.73333,
              43.500623
            ],
            [
              -91.730359,
              43.50068
            ],
            [
              -91.7302174228592,
              43.5006795266418
            ],
            [
              -91.730507,
              43.504326
            ],
            [
              -91.730424,
              43.846935
            ],
            [
              -92.079292,
              43.84708
            ],
            [
              -92.44883,
              43.848274
            ],
            [
              -92.448965,
              43.834105
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "171",
      "COUNTYNS": "00659530",
      "AFFGEOID": "0500000US27171",
      "GEOID": "27171",
      "NAME": "Wright",
      "LSAD": "06",
      "ALAND": 1712598664,
      "AWATER": 137298052,
      "County_state": "Wright,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.260821,
              45.283941
            ],
            [
              -94.256681,
              45.239272
            ],
            [
              -94.255775,
              45.152642
            ],
            [
              -94.256,
              45.066323
            ],
            [
              -94.256074,
              44.979465
            ],
            [
              -94.012236,
              44.978712
            ],
            [
              -93.767367,
              44.977964
            ],
            [
              -93.766808,
              45.057312
            ],
            [
              -93.761281,
              45.081837
            ],
            [
              -93.737832,
              45.082702
            ],
            [
              -93.733857,
              45.087236
            ],
            [
              -93.73323,
              45.087442
            ],
            [
              -93.728918,
              45.090016
            ],
            [
              -93.728882,
              45.093376
            ],
            [
              -93.729593,
              45.097441
            ],
            [
              -93.700742,
              45.136363
            ],
            [
              -93.68131,
              45.15125
            ],
            [
              -93.676267,
              45.151044
            ],
            [
              -93.638622,
              45.16562
            ],
            [
              -93.638653,
              45.174161
            ],
            [
              -93.65881,
              45.194116
            ],
            [
              -93.639951,
              45.2093
            ],
            [
              -93.569971,
              45.218523
            ],
            [
              -93.534107,
              45.230606
            ],
            [
              -93.52243,
              45.244371
            ],
            [
              -93.521653,
              45.246618
            ],
            [
              -93.551938,
              45.264989
            ],
            [
              -93.565473,
              45.301577
            ],
            [
              -93.57939,
              45.292122
            ],
            [
              -93.673369,
              45.296206
            ],
            [
              -93.685845,
              45.305056
            ],
            [
              -93.70439,
              45.297723
            ],
            [
              -93.75661,
              45.294721
            ],
            [
              -93.799622,
              45.310244
            ],
            [
              -93.831139,
              45.334779
            ],
            [
              -93.86759,
              45.339281
            ],
            [
              -93.888878,
              45.363053
            ],
            [
              -93.918525,
              45.384373
            ],
            [
              -93.957401,
              45.377949
            ],
            [
              -93.973642,
              45.38881
            ],
            [
              -93.996209,
              45.401656
            ],
            [
              -94.00914,
              45.390868
            ],
            [
              -94.007947,
              45.404653
            ],
            [
              -94.025662,
              45.40447
            ],
            [
              -94.047327,
              45.422861
            ],
            [
              -94.052958,
              45.423665
            ],
            [
              -94.08371,
              45.379856
            ],
            [
              -94.081374,
              45.366844
            ],
            [
              -94.120819,
              45.325627
            ],
            [
              -94.161892,
              45.301233
            ],
            [
              -94.187262,
              45.318278
            ],
            [
              -94.256658,
              45.306473
            ],
            [
              -94.260821,
              45.283941
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "167",
      "COUNTYNS": "00659528",
      "AFFGEOID": "0500000US27167",
      "GEOID": "27167",
      "NAME": "Wilkin",
      "LSAD": "06",
      "ALAND": 1945079886,
      "AWATER": 482554,
      "County_state": "Wilkin,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.78481470379259,
              46.629439247899995
            ],
            [
              -96.784792,
              46.62943
            ],
            [
              -96.784123,
              46.628666
            ],
            [
              -96.783837,
              46.627329
            ],
            [
              -96.784505,
              46.625418
            ],
            [
              -96.783932,
              46.621598
            ],
            [
              -96.779061,
              46.620834
            ],
            [
              -96.778201,
              46.619305
            ],
            [
              -96.778965,
              46.617873
            ],
            [
              -96.778488,
              46.616153
            ],
            [
              -96.774954,
              46.614625
            ],
            [
              -96.774094,
              46.613288
            ],
            [
              -96.775622,
              46.609276
            ],
            [
              -96.774763,
              46.607461
            ],
            [
              -96.772088,
              46.606315
            ],
            [
              -96.771802,
              46.605742
            ],
            [
              -96.772476,
              46.603716
            ],
            [
              -96.772457,
              46.601491
            ],
            [
              -96.772446,
              46.600129
            ],
            [
              -96.770226,
              46.598148
            ],
            [
              -96.766596,
              46.597957
            ],
            [
              -96.763865,
              46.594595
            ],
            [
              -96.762584,
              46.593946
            ],
            [
              -96.76182,
              46.592991
            ],
            [
              -96.762393,
              46.589743
            ],
            [
              -96.76182,
              46.588501
            ],
            [
              -96.757999,
              46.586878
            ],
            [
              -96.756662,
              46.585827
            ],
            [
              -96.756949,
              46.583534
            ],
            [
              -96.755421,
              46.582866
            ],
            [
              -96.752746,
              46.58277
            ],
            [
              -96.752078,
              46.582197
            ],
            [
              -96.753033,
              46.57895
            ],
            [
              -96.752746,
              46.577517
            ],
            [
              -96.7516,
              46.576371
            ],
            [
              -96.748161,
              46.575798
            ],
            [
              -96.746442,
              46.574078
            ],
            [
              -96.744436,
              46.56596
            ],
            [
              -96.746633,
              46.560706
            ],
            [
              -96.748161,
              46.559847
            ],
            [
              -96.74883,
              46.558127
            ],
            [
              -96.748161,
              46.556408
            ],
            [
              -96.746824,
              46.555071
            ],
            [
              -96.744532,
              46.551346
            ],
            [
              -96.744341,
              46.550104
            ],
            [
              -96.746347,
              46.546283
            ],
            [
              -96.743577,
              46.54485
            ],
            [
              -96.742812,
              46.543609
            ],
            [
              -96.743003,
              46.54294
            ],
            [
              -96.745009,
              46.541698
            ],
            [
              -96.745105,
              46.541125
            ],
            [
              -96.745009,
              46.540457
            ],
            [
              -96.742335,
              46.538546
            ],
            [
              -96.742239,
              46.536827
            ],
            [
              -96.744341,
              46.53463
            ],
            [
              -96.744341,
              46.533006
            ],
            [
              -96.74202,
              46.529036
            ],
            [
              -96.738475,
              46.525793
            ],
            [
              -96.737408,
              46.517636
            ],
            [
              -96.736147,
              46.513478
            ],
            [
              -96.738562,
              46.509366
            ],
            [
              -96.735888,
              46.50631
            ],
            [
              -96.735888,
              46.504973
            ],
            [
              -96.737702,
              46.50077
            ],
            [
              -96.735499,
              46.497932
            ],
            [
              -96.733612,
              46.497224
            ],
            [
              -96.73457,
              46.494254
            ],
            [
              -96.737798,
              46.489785
            ],
            [
              -96.737989,
              46.487875
            ],
            [
              -96.737129,
              46.485965
            ],
            [
              -96.735505,
              46.484914
            ],
            [
              -96.735028,
              46.483863
            ],
            [
              -96.73627,
              46.48138
            ],
            [
              -96.736365,
              46.480138
            ],
            [
              -96.735123,
              46.478897
            ],
            [
              -96.726914,
              46.476432
            ],
            [
              -96.726718,
              46.474121
            ],
            [
              -96.724712,
              46.473166
            ],
            [
              -96.72242,
              46.472784
            ],
            [
              -96.72156,
              46.472115
            ],
            [
              -96.720891,
              46.471446
            ],
            [
              -96.721274,
              46.470014
            ],
            [
              -96.720414,
              46.468008
            ],
            [
              -96.717453,
              46.464474
            ],
            [
              -96.715557,
              46.463232
            ],
            [
              -96.714861,
              46.459132
            ],
            [
              -96.715593,
              46.453867
            ],
            [
              -96.718551,
              46.451913
            ],
            [
              -96.718933,
              46.451054
            ],
            [
              -96.718169,
              46.448666
            ],
            [
              -96.717119,
              46.448093
            ],
            [
              -96.716641,
              46.447233
            ],
            [
              -96.716438,
              46.444567
            ],
            [
              -96.717967,
              46.442021
            ],
            [
              -96.718647,
              46.439974
            ],
            [
              -96.718074,
              46.438255
            ],
            [
              -96.715495,
              46.436153
            ],
            [
              -96.71177,
              46.436153
            ],
            [
              -96.709095,
              46.435294
            ],
            [
              -96.707471,
              46.432715
            ],
            [
              -96.706994,
              46.430231
            ],
            [
              -96.706134,
              46.429754
            ],
            [
              -96.703078,
              46.429467
            ],
            [
              -96.701645,
              46.428607
            ],
            [
              -96.701167,
              46.426506
            ],
            [
              -96.702314,
              46.423832
            ],
            [
              -96.702314,
              46.422685
            ],
            [
              -96.701358,
              46.420584
            ],
            [
              -96.69792,
              46.42068
            ],
            [
              -96.696869,
              46.420011
            ],
            [
              -96.696392,
              46.418483
            ],
            [
              -96.696869,
              46.416859
            ],
            [
              -96.696583,
              46.415617
            ],
            [
              -96.69429,
              46.41428
            ],
            [
              -96.688941,
              46.413134
            ],
            [
              -96.688318,
              46.410948
            ],
            [
              -96.688846,
              46.409409
            ],
            [
              -96.688082,
              46.40788
            ],
            [
              -96.684834,
              46.407021
            ],
            [
              -96.682008,
              46.40784
            ],
            [
              -96.680687,
              46.407383
            ],
            [
              -96.678507,
              46.404823
            ],
            [
              -96.669132,
              46.390037
            ],
            [
              -96.669794,
              46.384644
            ],
            [
              -96.667189,
              46.375458
            ],
            [
              -96.666028,
              46.374566
            ],
            [
              -96.658436,
              46.373391
            ],
            [
              -96.658009,
              46.370512
            ],
            [
              -96.655206,
              46.365964
            ],
            [
              -96.650718,
              46.363655
            ],
            [
              -96.646532,
              46.36251
            ],
            [
              -96.646341,
              46.360982
            ],
            [
              -96.647296,
              46.358499
            ],
            [
              -96.645959,
              46.353532
            ],
            [
              -96.644335,
              46.351908
            ],
            [
              -96.640267,
              46.351585
            ],
            [
              -96.631586,
              46.353752
            ],
            [
              -96.629211,
              46.352654
            ],
            [
              -96.629378,
              46.350529
            ],
            [
              -96.628522,
              46.349569
            ],
            [
              -96.62079,
              46.347607
            ],
            [
              -96.618147,
              46.344295
            ],
            [
              -96.620454,
              46.341346
            ],
            [
              -96.619991,
              46.340135
            ],
            [
              -96.614676,
              46.337418
            ],
            [
              -96.608075,
              46.332576
            ],
            [
              -96.601048,
              46.331139
            ],
            [
              -96.599761,
              46.330386
            ],
            [
              -96.60104,
              46.319554
            ],
            [
              -96.598399,
              46.314482
            ],
            [
              -96.598233,
              46.312563
            ],
            [
              -96.60136,
              46.30413
            ],
            [
              -96.60027,
              46.300406
            ],
            [
              -96.599156,
              46.299183
            ],
            [
              -96.598679,
              46.29775
            ],
            [
              -96.600302,
              46.294407
            ],
            [
              -96.599347,
              46.292879
            ],
            [
              -96.596968,
              46.291838
            ],
            [
              -96.596077,
              46.290536
            ],
            [
              -96.5961,
              46.286097
            ],
            [
              -96.598201,
              46.283136
            ],
            [
              -96.598774,
              46.281417
            ],
            [
              -96.598392,
              46.28008
            ],
            [
              -96.595509,
              46.276689
            ],
            [
              -96.595014,
              46.275135
            ],
            [
              -96.596822,
              46.267913
            ],
            [
              -96.599087,
              46.263701
            ],
            [
              -96.599729,
              46.262123
            ],
            [
              -96.59887,
              46.26069
            ],
            [
              -96.595909,
              46.259926
            ],
            [
              -96.594571,
              46.258302
            ],
            [
              -96.593616,
              46.256679
            ],
            [
              -96.593712,
              46.254959
            ],
            [
              -96.594571,
              46.253335
            ],
            [
              -96.594189,
              46.251712
            ],
            [
              -96.59247,
              46.250757
            ],
            [
              -96.590942,
              46.250183
            ],
            [
              -96.590369,
              46.249515
            ],
            [
              -96.590082,
              46.248655
            ],
            [
              -96.590369,
              46.247891
            ],
            [
              -96.591037,
              46.247222
            ],
            [
              -96.592375,
              46.246076
            ],
            [
              -96.594234,
              46.245329
            ],
            [
              -96.598119,
              46.243112
            ],
            [
              -96.598645,
              46.241626
            ],
            [
              -96.59755,
              46.227733
            ],
            [
              -96.59567,
              46.21985
            ],
            [
              -96.591652,
              46.218183
            ],
            [
              -96.586744,
              46.209912
            ],
            [
              -96.584899,
              46.204383
            ],
            [
              -96.584372,
              46.204155
            ],
            [
              -96.583582,
              46.201047
            ],
            [
              -96.584272,
              46.198053
            ],
            [
              -96.584929,
              46.197231
            ],
            [
              -96.587694,
              46.195262
            ],
            [
              -96.587724,
              46.191838
            ],
            [
              -96.588579,
              46.189689
            ],
            [
              -96.588554,
              46.185233
            ],
            [
              -96.587503,
              46.183609
            ],
            [
              -96.587217,
              46.182749
            ],
            [
              -96.587408,
              46.181221
            ],
            [
              -96.587599,
              46.180075
            ],
            [
              -96.587599,
              46.178928
            ],
            [
              -96.587408,
              46.178164
            ],
            [
              -96.586739,
              46.177305
            ],
            [
              -96.585647,
              46.177309
            ],
            [
              -96.584495,
              46.177123
            ],
            [
              -96.583324,
              46.174154
            ],
            [
              -96.583779,
              46.173563
            ],
            [
              -96.582823,
              46.170905
            ],
            [
              -96.580531,
              46.169186
            ],
            [
              -96.57862,
              46.168135
            ],
            [
              -96.577952,
              46.165843
            ],
            [
              -96.577715,
              46.162797
            ],
            [
              -96.580408,
              46.151234
            ],
            [
              -96.579453,
              46.147601
            ],
            [
              -96.577381,
              46.144951
            ],
            [
              -96.574784,
              46.143146
            ],
            [
              -96.56926,
              46.133686
            ],
            [
              -96.570081,
              46.127037
            ],
            [
              -96.571439,
              46.12572
            ],
            [
              -96.570023,
              46.123756
            ],
            [
              -96.563043,
              46.119512
            ],
            [
              -96.562811,
              46.11625
            ],
            [
              -96.56692,
              46.11475
            ],
            [
              -96.565723,
              46.111963
            ],
            [
              -96.563175,
              46.107995
            ],
            [
              -96.559167,
              46.105024
            ],
            [
              -96.557952,
              46.102442
            ],
            [
              -96.556672,
              46.097232
            ],
            [
              -96.556345,
              46.08688
            ],
            [
              -96.554507,
              46.083978
            ],
            [
              -96.558088,
              46.072096
            ],
            [
              -96.558055,
              46.071159
            ],
            [
              -96.556611,
              46.06892
            ],
            [
              -96.556907,
              46.06483
            ],
            [
              -96.559271,
              46.058272
            ],
            [
              -96.560945,
              46.055415
            ],
            [
              -96.566295,
              46.051416
            ],
            [
              -96.573644,
              46.037911
            ],
            [
              -96.57794,
              46.026874
            ],
            [
              -96.577315,
              46.02356
            ],
            [
              -96.5765693887837,
              46.0218456562824
            ],
            [
              -96.26614,
              46.02161
            ],
            [
              -96.265366,
              46.10861
            ],
            [
              -96.264647,
              46.282196
            ],
            [
              -96.28,
              46.28222
            ],
            [
              -96.280977,
              46.630765
            ],
            [
              -96.779394,
              46.630272
            ],
            [
              -96.78481470379259,
              46.629439247899995
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "151",
      "COUNTYNS": "00659520",
      "AFFGEOID": "0500000US27151",
      "GEOID": "27151",
      "NAME": "Swift",
      "LSAD": "06",
      "ALAND": 1921870987,
      "AWATER": 26208987,
      "County_state": "Swift,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.118326,
              45.391529
            ],
            [
              -96.118054,
              45.239784
            ],
            [
              -96.10371,
              45.239766
            ],
            [
              -96.103614,
              45.176675
            ],
            [
              -96.056283,
              45.156466
            ],
            [
              -96.036698,
              45.152206
            ],
            [
              -95.24649,
              45.152384
            ],
            [
              -95.246654,
              45.238683
            ],
            [
              -95.255196,
              45.238712
            ],
            [
              -95.255206,
              45.412525
            ],
            [
              -95.500436,
              45.411896
            ],
            [
              -95.747402,
              45.412313
            ],
            [
              -96.117036,
              45.411993
            ],
            [
              -96.118326,
              45.391529
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "089",
      "COUNTYNS": "00659489",
      "AFFGEOID": "0500000US27089",
      "GEOID": "27089",
      "NAME": "Marshall",
      "LSAD": "06",
      "ALAND": 4597384434,
      "AWATER": 98019666,
      "County_state": "Marshall,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.1630904767802,
              48.5439639241486
            ],
            [
              -97.163105,
              48.543855
            ],
            [
              -97.1612768424102,
              48.5425053390564
            ],
            [
              -97.159697,
              48.541339
            ],
            [
              -97.153942,
              48.539102
            ],
            [
              -97.150481,
              48.536877
            ],
            [
              -97.148874,
              48.534282
            ],
            [
              -97.149122,
              48.532305
            ],
            [
              -97.151964,
              48.529215
            ],
            [
              -97.153076,
              48.524148
            ],
            [
              -97.148133,
              48.503384
            ],
            [
              -97.147638,
              48.501531
            ],
            [
              -97.146279,
              48.499677
            ],
            [
              -97.140347,
              48.496834
            ],
            [
              -97.138864,
              48.494362
            ],
            [
              -97.139276,
              48.48631
            ],
            [
              -97.140291,
              48.484722
            ],
            [
              -97.143869,
              48.48293
            ],
            [
              -97.144981,
              48.481571
            ],
            [
              -97.144611,
              48.478975
            ],
            [
              -97.142757,
              48.477987
            ],
            [
              -97.141397,
              48.476256
            ],
            [
              -97.142015,
              48.47465
            ],
            [
              -97.143745,
              48.473661
            ],
            [
              -97.144116,
              48.469212
            ],
            [
              -97.143127,
              48.466246
            ],
            [
              -97.141768,
              48.464021
            ],
            [
              -97.134229,
              48.461178
            ],
            [
              -97.132746,
              48.459942
            ],
            [
              -97.132622,
              48.456482
            ],
            [
              -97.133611,
              48.45228
            ],
            [
              -97.137072,
              48.449067
            ],
            [
              -97.137689,
              48.447583
            ],
            [
              -97.137689,
              48.444247
            ],
            [
              -97.137319,
              48.443505
            ],
            [
              -97.135094,
              48.442269
            ],
            [
              -97.134229,
              48.439797
            ],
            [
              -97.13497,
              48.436337
            ],
            [
              -97.136206,
              48.434606
            ],
            [
              -97.139296,
              48.432011
            ],
            [
              -97.139173,
              48.430528
            ],
            [
              -97.137813,
              48.428056
            ],
            [
              -97.1356,
              48.426524
            ],
            [
              -97.1356,
              48.424369
            ],
            [
              -97.136971,
              48.422018
            ],
            [
              -97.142066,
              48.42045
            ],
            [
              -97.142849,
              48.419471
            ],
            [
              -97.142457,
              48.416727
            ],
            [
              -97.138343,
              48.415944
            ],
            [
              -97.1356,
              48.411829
            ],
            [
              -97.135012,
              48.406735
            ],
            [
              -97.135795,
              48.404187
            ],
            [
              -97.140106,
              48.399289
            ],
            [
              -97.143829,
              48.397134
            ],
            [
              -97.145201,
              48.395566
            ],
            [
              -97.145592,
              48.394195
            ],
            [
              -97.145201,
              48.388904
            ],
            [
              -97.143633,
              48.386161
            ],
            [
              -97.14089,
              48.384006
            ],
            [
              -97.140106,
              48.382242
            ],
            [
              -97.140106,
              48.380479
            ],
            [
              -97.142066,
              48.374209
            ],
            [
              -97.144221,
              48.37127
            ],
            [
              -97.146376,
              48.37029
            ],
            [
              -97.147356,
              48.368723
            ],
            [
              -97.147748,
              48.366959
            ],
            [
              -97.147748,
              48.359905
            ],
            [
              -97.143861,
              48.354503
            ],
            [
              -97.139851,
              48.353425
            ],
            [
              -97.137822,
              48.352003
            ],
            [
              -97.137492,
              48.350602
            ],
            [
              -97.138481,
              48.34747
            ],
            [
              -97.137904,
              48.344585
            ],
            [
              -97.131722,
              48.341123
            ],
            [
              -97.131145,
              48.339722
            ],
            [
              -97.131969,
              48.335518
            ],
            [
              -97.134854,
              48.331314
            ],
            [
              -97.134772,
              48.328677
            ],
            [
              -97.133751,
              48.327847
            ],
            [
              -97.131227,
              48.327935
            ],
            [
              -97.127766,
              48.326781
            ],
            [
              -97.127436,
              48.325709
            ],
            [
              -97.127601,
              48.323319
            ],
            [
              -97.129826,
              48.320516
            ],
            [
              -97.13125,
              48.319543
            ],
            [
              -97.131697,
              48.318324
            ],
            [
              -97.132443,
              48.315489
            ],
            [
              -97.131921,
              48.312728
            ],
            [
              -97.130951,
              48.311609
            ],
            [
              -97.127146,
              48.310192
            ],
            [
              -97.126176,
              48.309147
            ],
            [
              -97.126176,
              48.303701
            ],
            [
              -97.122296,
              48.301388
            ],
            [
              -97.122072,
              48.300865
            ],
            [
              -97.12252,
              48.299299
            ],
            [
              -97.123341,
              48.298627
            ],
            [
              -97.127295,
              48.298478
            ],
            [
              -97.128638,
              48.297657
            ],
            [
              -97.129086,
              48.295792
            ],
            [
              -97.128862,
              48.292882
            ],
            [
              -97.127236,
              48.291827
            ],
            [
              -97.125348,
              48.291855
            ],
            [
              -97.12216,
              48.290056
            ],
            [
              -97.117726,
              48.283488
            ],
            [
              -97.116717,
              48.281246
            ],
            [
              -97.11657,
              48.279661
            ],
            [
              -97.12408,
              48.27125
            ],
            [
              -97.125348,
              48.270452
            ],
            [
              -97.13028,
              48.269305
            ],
            [
              -97.131846,
              48.267589
            ],
            [
              -97.131921,
              48.266023
            ],
            [
              -97.130951,
              48.265276
            ],
            [
              -97.128551,
              48.264816
            ],
            [
              -97.127146,
              48.262889
            ],
            [
              -97.127146,
              48.260874
            ],
            [
              -97.129384,
              48.258785
            ],
            [
              -97.129533,
              48.257815
            ],
            [
              -97.129235,
              48.256398
            ],
            [
              -97.127594,
              48.254383
            ],
            [
              -97.127276,
              48.253323
            ],
            [
              -97.127967,
              48.251474
            ],
            [
              -97.129384,
              48.250429
            ],
            [
              -97.133434,
              48.249873
            ],
            [
              -97.138033,
              48.246236
            ],
            [
              -97.138765,
              48.244991
            ],
            [
              -97.138618,
              48.242429
            ],
            [
              -97.135617,
              48.238988
            ],
            [
              -97.135763,
              48.237596
            ],
            [
              -97.13979,
              48.235913
            ],
            [
              -97.141254,
              48.234668
            ],
            [
              -97.140815,
              48.232032
            ],
            [
              -97.139311,
              48.230187
            ],
            [
              -97.136304,
              48.228984
            ],
            [
              -97.136003,
              48.228082
            ],
            [
              -97.136304,
              48.226176
            ],
            [
              -97.13769,
              48.225126
            ],
            [
              -97.138154,
              48.223104
            ],
            [
              -97.137522,
              48.221713
            ],
            [
              -97.135617,
              48.220904
            ],
            [
              -97.135201,
              48.219156
            ],
            [
              -97.135177,
              48.217243
            ],
            [
              -97.137407,
              48.215245
            ],
            [
              -97.137006,
              48.212537
            ],
            [
              -97.134372,
              48.210434
            ],
            [
              -97.134738,
              48.207506
            ],
            [
              -97.13774,
              48.206188
            ],
            [
              -97.138765,
              48.20465
            ],
            [
              -97.139131,
              48.20282
            ],
            [
              -97.138007,
              48.197587
            ],
            [
              -97.141233,
              48.193602
            ],
            [
              -97.141518,
              48.192518
            ],
            [
              -97.146233,
              48.186054
            ],
            [
              -97.146013,
              48.18459
            ],
            [
              -97.144622,
              48.183199
            ],
            [
              -97.142938,
              48.182686
            ],
            [
              -97.14184,
              48.181734
            ],
            [
              -97.141474,
              48.179099
            ],
            [
              -97.14162,
              48.177781
            ],
            [
              -97.142352,
              48.176609
            ],
            [
              -97.145243,
              48.174046
            ],
            [
              -97.1457019134364,
              48.1732232314736
            ],
            [
              -96.500843,
              48.174108
            ],
            [
              -96.261895,
              48.174908
            ],
            [
              -96.239181,
              48.172641
            ],
            [
              -96.173639,
              48.173695
            ],
            [
              -96.108454,
              48.171796
            ],
            [
              -95.981727,
              48.171686
            ],
            [
              -95.593788,
              48.172916
            ],
            [
              -95.593199,
              48.365445
            ],
            [
              -95.601978,
              48.36543
            ],
            [
              -95.600743,
              48.495592
            ],
            [
              -95.602315,
              48.538887
            ],
            [
              -96.387835,
              48.544308
            ],
            [
              -97.1630904767802,
              48.5439639241486
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "115",
      "COUNTYNS": "00659503",
      "AFFGEOID": "0500000US27115",
      "GEOID": "27115",
      "NAME": "Pine",
      "LSAD": "06",
      "ALAND": 3655221004,
      "AWATER": 60262765,
      "County_state": "Pine,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.142869,
              45.981005
            ],
            [
              -93.141793,
              45.730657
            ],
            [
              -92.841051,
              45.730024
            ],
            [
              -92.835917,
              45.732802
            ],
            [
              -92.830685,
              45.73312
            ],
            [
              -92.828981,
              45.733714
            ],
            [
              -92.826013,
              45.73665
            ],
            [
              -92.816559,
              45.742037
            ],
            [
              -92.812939,
              45.742709
            ],
            [
              -92.809837,
              45.744172
            ],
            [
              -92.805348,
              45.747493
            ],
            [
              -92.803971,
              45.749805
            ],
            [
              -92.80263,
              45.751888
            ],
            [
              -92.798645,
              45.753654
            ],
            [
              -92.784621,
              45.764196
            ],
            [
              -92.781373,
              45.773062
            ],
            [
              -92.779617,
              45.782563
            ],
            [
              -92.776496,
              45.790014
            ],
            [
              -92.772065,
              45.79523
            ],
            [
              -92.76843,
              45.79801
            ],
            [
              -92.761833,
              45.801258
            ],
            [
              -92.75901,
              45.803965
            ],
            [
              -92.757815,
              45.806574
            ],
            [
              -92.757947,
              45.811216
            ],
            [
              -92.760023,
              45.815475
            ],
            [
              -92.761889,
              45.817928
            ],
            [
              -92.764906,
              45.824859
            ],
            [
              -92.765681,
              45.827252
            ],
            [
              -92.765146,
              45.830183
            ],
            [
              -92.761712,
              45.833861
            ],
            [
              -92.759458,
              45.835341
            ],
            [
              -92.74918,
              45.840717
            ],
            [
              -92.745557,
              45.841455
            ],
            [
              -92.739991,
              45.846283
            ],
            [
              -92.739278,
              45.84758
            ],
            [
              -92.736117,
              45.859129
            ],
            [
              -92.736484,
              45.863356
            ],
            [
              -92.734039,
              45.868108
            ],
            [
              -92.721128,
              45.883805
            ],
            [
              -92.712503,
              45.891705
            ],
            [
              -92.707702,
              45.894901
            ],
            [
              -92.703265,
              45.896155
            ],
            [
              -92.698983,
              45.896451
            ],
            [
              -92.683924,
              45.903939
            ],
            [
              -92.676607,
              45.90637
            ],
            [
              -92.675737,
              45.907478
            ],
            [
              -92.676807,
              45.91093
            ],
            [
              -92.676167,
              45.912072
            ],
            [
              -92.670352,
              45.916247
            ],
            [
              -92.659549,
              45.922937
            ],
            [
              -92.656125,
              45.924442
            ],
            [
              -92.639116,
              45.924555
            ],
            [
              -92.638474,
              45.925971
            ],
            [
              -92.640115,
              45.932478
            ],
            [
              -92.639936,
              45.933541
            ],
            [
              -92.638824,
              45.934166
            ],
            [
              -92.636316,
              45.934634
            ],
            [
              -92.62926,
              45.932404
            ],
            [
              -92.627723,
              45.932682
            ],
            [
              -92.62272,
              45.935186
            ],
            [
              -92.614314,
              45.934529
            ],
            [
              -92.61083482831539,
              45.9366118524889
            ],
            [
              -92.608329,
              45.938112
            ],
            [
              -92.60246,
              45.940815
            ],
            [
              -92.590138,
              45.941773
            ],
            [
              -92.580565,
              45.94625
            ],
            [
              -92.574892,
              45.948103
            ],
            [
              -92.569764,
              45.948146
            ],
            [
              -92.561256,
              45.951006
            ],
            [
              -92.551933,
              45.951651
            ],
            [
              -92.551186,
              45.95224
            ],
            [
              -92.549858,
              45.957039
            ],
            [
              -92.550672,
              45.960759
            ],
            [
              -92.549806,
              45.967986
            ],
            [
              -92.548459,
              45.969056
            ],
            [
              -92.545682,
              45.970118
            ],
            [
              -92.537709,
              45.977818
            ],
            [
              -92.530516,
              45.981918
            ],
            [
              -92.527052,
              45.983245
            ],
            [
              -92.522032,
              45.984203
            ],
            [
              -92.519488,
              45.983917
            ],
            [
              -92.502535,
              45.979995
            ],
            [
              -92.490996,
              45.97556
            ],
            [
              -92.484633,
              45.975872
            ],
            [
              -92.479478,
              45.973992
            ],
            [
              -92.472761,
              45.972952
            ],
            [
              -92.469354,
              45.973811
            ],
            [
              -92.464481,
              45.976267
            ],
            [
              -92.46126,
              45.979427
            ],
            [
              -92.461138,
              45.980216
            ],
            [
              -92.463429,
              45.981507
            ],
            [
              -92.464173,
              45.982423
            ],
            [
              -92.464512,
              45.985038
            ],
            [
              -92.462477,
              45.98785
            ],
            [
              -92.456494,
              45.990243
            ],
            [
              -92.453373,
              45.992913
            ],
            [
              -92.453635,
              45.996171
            ],
            [
              -92.452952,
              45.997782
            ],
            [
              -92.451627,
              46.000441
            ],
            [
              -92.44963,
              46.002252
            ],
            [
              -92.444294,
              46.009161
            ],
            [
              -92.444356,
              46.011777
            ],
            [
              -92.442259,
              46.016177
            ],
            [
              -92.435627,
              46.021232
            ],
            [
              -92.428555,
              46.024241
            ],
            [
              -92.420696,
              46.026769
            ],
            [
              -92.410649,
              46.027259
            ],
            [
              -92.408259,
              46.02663
            ],
            [
              -92.392681,
              46.01954
            ],
            [
              -92.381707,
              46.017034
            ],
            [
              -92.372717,
              46.014198
            ],
            [
              -92.362141,
              46.013103
            ],
            [
              -92.357965,
              46.013413
            ],
            [
              -92.35176,
              46.015685
            ],
            [
              -92.349977,
              46.016982
            ],
            [
              -92.350319,
              46.01898
            ],
            [
              -92.350004,
              46.021888
            ],
            [
              -92.349281,
              46.023624
            ],
            [
              -92.346345,
              46.025429
            ],
            [
              -92.344244,
              46.02743
            ],
            [
              -92.343745,
              46.028525
            ],
            [
              -92.342429,
              46.034541
            ],
            [
              -92.343604,
              46.040917
            ],
            [
              -92.343459,
              46.04299
            ],
            [
              -92.341278,
              46.045424
            ],
            [
              -92.33859,
              46.050111
            ],
            [
              -92.338239,
              46.052149
            ],
            [
              -92.335335,
              46.059422
            ],
            [
              -92.332912,
              46.062697
            ],
            [
              -92.329806,
              46.065216
            ],
            [
              -92.327868,
              46.06618
            ],
            [
              -92.319329,
              46.069289
            ],
            [
              -92.306756,
              46.07241
            ],
            [
              -92.298638,
              46.072989
            ],
            [
              -92.294033,
              46.074377
            ],
            [
              -92.294069,
              46.078346
            ],
            [
              -92.29353,
              46.113824
            ],
            [
              -92.2937055830063,
              46.1573209976466
            ],
            [
              -92.293744,
              46.166838
            ],
            [
              -92.293857,
              46.180073
            ],
            [
              -92.293558,
              46.224578
            ],
            [
              -92.293619,
              46.244043
            ],
            [
              -92.293074,
              46.295129
            ],
            [
              -92.293007,
              46.297987
            ],
            [
              -92.29284,
              46.304319
            ],
            [
              -92.292839,
              46.307107
            ],
            [
              -92.29288,
              46.313752
            ],
            [
              -92.292803,
              46.314628
            ],
            [
              -92.292782,
              46.319312
            ],
            [
              -92.292999,
              46.321894
            ],
            [
              -92.29286,
              46.41722
            ],
            [
              -92.553163,
              46.418097
            ],
            [
              -92.803169,
              46.418408
            ],
            [
              -93.054847,
              46.419269
            ],
            [
              -93.053871,
              46.15812
            ],
            [
              -93.055081,
              45.981233
            ],
            [
              -93.142869,
              45.981005
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "139",
      "COUNTYNS": "00659514",
      "AFFGEOID": "0500000US27139",
      "GEOID": "27139",
      "NAME": "Scott",
      "LSAD": "06",
      "ALAND": 922693644,
      "AWATER": 31374929,
      "County_state": "Scott,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.891356,
              44.565652
            ],
            [
              -93.910405,
              44.543163
            ],
            [
              -93.647037,
              44.543897
            ],
            [
              -93.597924,
              44.543744
            ],
            [
              -93.552114,
              44.543542
            ],
            [
              -93.524327,
              44.543618
            ],
            [
              -93.357704,
              44.544006
            ],
            [
              -93.281521,
              44.543957
            ],
            [
              -93.278473,
              44.543957
            ],
            [
              -93.278169,
              44.630751
            ],
            [
              -93.318707,
              44.630578
            ],
            [
              -93.318353,
              44.681362
            ],
            [
              -93.318341,
              44.717551
            ],
            [
              -93.328401,
              44.717529
            ],
            [
              -93.328652,
              44.746429
            ],
            [
              -93.328712,
              44.775271
            ],
            [
              -93.329668,
              44.778442
            ],
            [
              -93.329615,
              44.791086
            ],
            [
              -93.336745,
              44.786386
            ],
            [
              -93.351882,
              44.789893
            ],
            [
              -93.3554,
              44.785595
            ],
            [
              -93.380848,
              44.785691
            ],
            [
              -93.398827,
              44.798763
            ],
            [
              -93.39885,
              44.798782
            ],
            [
              -93.438089,
              44.813007
            ],
            [
              -93.457929,
              44.803363
            ],
            [
              -93.520431,
              44.804263
            ],
            [
              -93.54123,
              44.804561
            ],
            [
              -93.599813,
              44.782347
            ],
            [
              -93.594234,
              44.776215
            ],
            [
              -93.621967,
              44.760281
            ],
            [
              -93.631542,
              44.738055
            ],
            [
              -93.626735,
              44.718462
            ],
            [
              -93.618067,
              44.707773
            ],
            [
              -93.643947,
              44.692662
            ],
            [
              -93.667961,
              44.695259
            ],
            [
              -93.698273,
              44.669446
            ],
            [
              -93.747339,
              44.645263
            ],
            [
              -93.755735,
              44.634907
            ],
            [
              -93.768039,
              44.640303
            ],
            [
              -93.792116,
              44.628983
            ],
            [
              -93.815516,
              44.632985
            ],
            [
              -93.836536,
              44.618479
            ],
            [
              -93.857678,
              44.621095
            ],
            [
              -93.860515,
              44.606198
            ],
            [
              -93.87387,
              44.606843
            ],
            [
              -93.902479,
              44.587647
            ],
            [
              -93.892714,
              44.584985
            ],
            [
              -93.90042,
              44.569421
            ],
            [
              -93.891356,
              44.565652
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "029",
      "COUNTYNS": "00659460",
      "AFFGEOID": "0500000US27029",
      "GEOID": "27029",
      "NAME": "Clearwater",
      "LSAD": "06",
      "ALAND": 2587409023,
      "AWATER": 79744538,
      "County_state": "Clearwater,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.582887,
              48.020557
            ],
            [
              -95.582155,
              47.933966
            ],
            [
              -95.578839,
              47.673677
            ],
            [
              -95.553392,
              47.67361
            ],
            [
              -95.554248,
              47.499893
            ],
            [
              -95.551458,
              47.50002
            ],
            [
              -95.551186,
              47.151453
            ],
            [
              -95.169172,
              47.152515
            ],
            [
              -95.169633,
              47.325399
            ],
            [
              -95.182711,
              47.325524
            ],
            [
              -95.183216,
              47.412773
            ],
            [
              -95.183313,
              47.499438
            ],
            [
              -95.194304,
              47.67337
            ],
            [
              -95.193705,
              47.872544
            ],
            [
              -95.24632,
              47.883266
            ],
            [
              -95.269705,
              47.903875
            ],
            [
              -95.2771,
              47.943691
            ],
            [
              -95.272566,
              47.960205
            ],
            [
              -95.246849,
              48.005321
            ],
            [
              -95.229133,
              48.019958
            ],
            [
              -95.582887,
              48.020557
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "035",
      "COUNTYNS": "00659463",
      "AFFGEOID": "0500000US27035",
      "GEOID": "27035",
      "NAME": "Crow Wing",
      "LSAD": "06",
      "ALAND": 2585785629,
      "AWATER": 409661410,
      "County_state": "Crow Wing,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.394384,
              46.228711
            ],
            [
              -94.395718,
              46.211859
            ],
            [
              -94.366186,
              46.180083
            ],
            [
              -94.374592,
              46.157093
            ],
            [
              -93.810362,
              46.156585
            ],
            [
              -93.810912,
              46.243128
            ],
            [
              -93.796281,
              46.243199
            ],
            [
              -93.810357,
              46.250925
            ],
            [
              -93.811464,
              46.584238
            ],
            [
              -93.777905,
              46.589671
            ],
            [
              -93.776024,
              46.802891
            ],
            [
              -94.27998,
              46.805107
            ],
            [
              -94.34291,
              46.805529
            ],
            [
              -94.345633,
              46.718489
            ],
            [
              -94.345441,
              46.631338
            ],
            [
              -94.330936,
              46.631208
            ],
            [
              -94.327285,
              46.289658
            ],
            [
              -94.332523,
              46.279566
            ],
            [
              -94.341679,
              46.277705
            ],
            [
              -94.33977,
              46.270087
            ],
            [
              -94.355302,
              46.255095
            ],
            [
              -94.388922,
              46.238166
            ],
            [
              -94.394384,
              46.228711
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "037",
      "COUNTYNS": "00659464",
      "AFFGEOID": "0500000US27037",
      "GEOID": "27037",
      "NAME": "Dakota",
      "LSAD": "06",
      "ALAND": 1456715603,
      "AWATER": 63149548,
      "County_state": "Dakota,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.329615,
              44.791086
            ],
            [
              -93.329668,
              44.778442
            ],
            [
              -93.328712,
              44.775271
            ],
            [
              -93.328652,
              44.746429
            ],
            [
              -93.328401,
              44.717529
            ],
            [
              -93.318341,
              44.717551
            ],
            [
              -93.318353,
              44.681362
            ],
            [
              -93.318707,
              44.630578
            ],
            [
              -93.278169,
              44.630751
            ],
            [
              -93.278473,
              44.543957
            ],
            [
              -93.281521,
              44.543957
            ],
            [
              -93.281686,
              44.471998
            ],
            [
              -93.039485,
              44.471871
            ],
            [
              -93.039339,
              44.515545
            ],
            [
              -92.984914,
              44.518822
            ],
            [
              -92.954826,
              44.507025
            ],
            [
              -92.919148,
              44.517569
            ],
            [
              -92.918977,
              44.54325
            ],
            [
              -92.792715,
              44.54324
            ],
            [
              -92.792584,
              44.629722
            ],
            [
              -92.732043,
              44.629493
            ],
            [
              -92.732042,
              44.7136706398321
            ],
            [
              -92.737259,
              44.717155
            ],
            [
              -92.7502,
              44.72212
            ],
            [
              -92.7542,
              44.722767
            ],
            [
              -92.75699,
              44.723829
            ],
            [
              -92.766054,
              44.729604
            ],
            [
              -92.787906,
              44.737432
            ],
            [
              -92.802402,
              44.745167
            ],
            [
              -92.80257940581069,
              44.7457971094334
            ],
            [
              -92.812634,
              44.747252
            ],
            [
              -92.8553,
              44.747715
            ],
            [
              -92.882559,
              44.774107
            ],
            [
              -92.928629,
              44.781417
            ],
            [
              -92.974799,
              44.772315
            ],
            [
              -93.005978,
              44.771685
            ],
            [
              -93.015961,
              44.775564
            ],
            [
              -93.021521,
              44.786753
            ],
            [
              -93.004711,
              44.814873
            ],
            [
              -93.010032,
              44.845989
            ],
            [
              -93.010076,
              44.863787
            ],
            [
              -93.016466,
              44.884611
            ],
            [
              -93.020044,
              44.89075
            ],
            [
              -93.036802,
              44.899865
            ],
            [
              -93.048739,
              44.917354
            ],
            [
              -93.050888,
              44.919501
            ],
            [
              -93.128629,
              44.919648
            ],
            [
              -93.138497,
              44.903963
            ],
            [
              -93.1539,
              44.892898
            ],
            [
              -93.168176,
              44.888551
            ],
            [
              -93.178539,
              44.888338
            ],
            [
              -93.178771,
              44.880162
            ],
            [
              -93.19794,
              44.862
            ],
            [
              -93.211437,
              44.839742
            ],
            [
              -93.251873,
              44.811476
            ],
            [
              -93.329615,
              44.791086
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "061",
      "COUNTYNS": "00659476",
      "AFFGEOID": "0500000US27061",
      "GEOID": "27061",
      "NAME": "Itasca",
      "LSAD": "06",
      "ALAND": 6907775594,
      "AWATER": 675388758,
      "County_state": "Itasca,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.418543,
              47.845816
            ],
            [
              -94.416317,
              47.593873
            ],
            [
              -94.41428,
              47.586491
            ],
            [
              -94.416279,
              47.444828
            ],
            [
              -94.396382,
              47.428073
            ],
            [
              -94.377944,
              47.431952
            ],
            [
              -94.327531,
              47.415844
            ],
            [
              -94.117999,
              47.480744
            ],
            [
              -94.095115,
              47.477972
            ],
            [
              -94.079909,
              47.468477
            ],
            [
              -94.061498,
              47.438162
            ],
            [
              -94.027858,
              47.408633
            ],
            [
              -94.041186,
              47.402487
            ],
            [
              -94.042287,
              47.373112
            ],
            [
              -94.027108,
              47.352277
            ],
            [
              -94.006686,
              47.349007
            ],
            [
              -93.942215,
              47.309525
            ],
            [
              -93.911505,
              47.297868
            ],
            [
              -93.89341,
              47.306859
            ],
            [
              -93.889775,
              47.326239
            ],
            [
              -93.888213,
              47.318856
            ],
            [
              -93.845079,
              47.316838
            ],
            [
              -93.847651,
              47.310086
            ],
            [
              -93.819988,
              47.300838
            ],
            [
              -93.815248,
              47.30774
            ],
            [
              -93.77401,
              47.30506
            ],
            [
              -93.786521,
              47.29944
            ],
            [
              -93.776074,
              47.277986
            ],
            [
              -93.782449,
              47.261605
            ],
            [
              -93.796769,
              47.259447
            ],
            [
              -93.806673,
              47.237243
            ],
            [
              -93.810639,
              47.234781
            ],
            [
              -93.799886,
              47.222029
            ],
            [
              -93.774658,
              47.21265
            ],
            [
              -93.775257,
              47.030418
            ],
            [
              -93.316728,
              47.028284
            ],
            [
              -93.191277,
              47.025194
            ],
            [
              -93.055943,
              47.026415
            ],
            [
              -93.056187,
              47.109279
            ],
            [
              -93.066245,
              47.109319
            ],
            [
              -93.06471,
              47.371575
            ],
            [
              -93.0695,
              47.393915
            ],
            [
              -93.06882,
              47.45789
            ],
            [
              -93.065087,
              47.499932
            ],
            [
              -93.061253,
              47.720187
            ],
            [
              -93.082187,
              47.720382
            ],
            [
              -93.083724,
              47.806353
            ],
            [
              -93.081158,
              47.89187
            ],
            [
              -93.775772,
              47.89895
            ],
            [
              -93.776173,
              47.846404
            ],
            [
              -94.418543,
              47.845816
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "021",
      "COUNTYNS": "00659456",
      "AFFGEOID": "0500000US27021",
      "GEOID": "27021",
      "NAME": "Cass",
      "LSAD": "06",
      "ALAND": 5235576788,
      "AWATER": 1016652167,
      "County_state": "Cass,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.787126,
              46.803943
            ],
            [
              -94.786633,
              46.716261
            ],
            [
              -94.785982,
              46.630612
            ],
            [
              -94.779121,
              46.394215
            ],
            [
              -94.751798,
              46.379053
            ],
            [
              -94.727958,
              46.38071
            ],
            [
              -94.731442,
              46.368605
            ],
            [
              -94.721382,
              46.35154
            ],
            [
              -94.653462,
              46.348678
            ],
            [
              -94.622852,
              46.326665
            ],
            [
              -94.607545,
              46.329468
            ],
            [
              -94.585557,
              46.324378
            ],
            [
              -94.556183,
              46.307136
            ],
            [
              -94.534724,
              46.302797
            ],
            [
              -94.507885,
              46.312863
            ],
            [
              -94.483826,
              46.315805
            ],
            [
              -94.460459,
              46.331163
            ],
            [
              -94.426529,
              46.332336
            ],
            [
              -94.381181,
              46.309239
            ],
            [
              -94.370736,
              46.297166
            ],
            [
              -94.360673,
              46.300008
            ],
            [
              -94.341679,
              46.277705
            ],
            [
              -94.332523,
              46.279566
            ],
            [
              -94.327285,
              46.289658
            ],
            [
              -94.330936,
              46.631208
            ],
            [
              -94.345441,
              46.631338
            ],
            [
              -94.345633,
              46.718489
            ],
            [
              -94.34291,
              46.805529
            ],
            [
              -94.27998,
              46.805107
            ],
            [
              -93.776024,
              46.802891
            ],
            [
              -93.775257,
              47.030418
            ],
            [
              -93.774658,
              47.21265
            ],
            [
              -93.799886,
              47.222029
            ],
            [
              -93.810639,
              47.234781
            ],
            [
              -93.806673,
              47.237243
            ],
            [
              -93.796769,
              47.259447
            ],
            [
              -93.782449,
              47.261605
            ],
            [
              -93.776074,
              47.277986
            ],
            [
              -93.786521,
              47.29944
            ],
            [
              -93.77401,
              47.30506
            ],
            [
              -93.815248,
              47.30774
            ],
            [
              -93.819988,
              47.300838
            ],
            [
              -93.847651,
              47.310086
            ],
            [
              -93.845079,
              47.316838
            ],
            [
              -93.888213,
              47.318856
            ],
            [
              -93.889775,
              47.326239
            ],
            [
              -93.89341,
              47.306859
            ],
            [
              -93.911505,
              47.297868
            ],
            [
              -93.942215,
              47.309525
            ],
            [
              -94.006686,
              47.349007
            ],
            [
              -94.027108,
              47.352277
            ],
            [
              -94.042287,
              47.373112
            ],
            [
              -94.041186,
              47.402487
            ],
            [
              -94.027858,
              47.408633
            ],
            [
              -94.061498,
              47.438162
            ],
            [
              -94.079909,
              47.468477
            ],
            [
              -94.095115,
              47.477972
            ],
            [
              -94.117999,
              47.480744
            ],
            [
              -94.327531,
              47.415844
            ],
            [
              -94.377944,
              47.431952
            ],
            [
              -94.396382,
              47.428073
            ],
            [
              -94.416279,
              47.444828
            ],
            [
              -94.416387,
              47.41183
            ],
            [
              -94.670214,
              47.410151
            ],
            [
              -94.671456,
              47.322781
            ],
            [
              -94.663891,
              47.143055
            ],
            [
              -94.663638,
              46.977702
            ],
            [
              -94.65837,
              46.97694
            ],
            [
              -94.659387,
              46.890702
            ],
            [
              -94.659942,
              46.803716
            ],
            [
              -94.787126,
              46.803943
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "065",
      "COUNTYNS": "00659478",
      "AFFGEOID": "0500000US27065",
      "GEOID": "27065",
      "NAME": "Kanabec",
      "LSAD": "06",
      "ALAND": 1350910899,
      "AWATER": 31322650,
      "County_state": "Kanabec,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.51808,
              45.981789
            ],
            [
              -93.513683,
              45.734318
            ],
            [
              -93.388982,
              45.730717
            ],
            [
              -93.141793,
              45.730657
            ],
            [
              -93.142869,
              45.981005
            ],
            [
              -93.055081,
              45.981233
            ],
            [
              -93.053871,
              46.15812
            ],
            [
              -93.178985,
              46.15855
            ],
            [
              -93.284475,
              46.159443
            ],
            [
              -93.431831,
              46.153991
            ],
            [
              -93.432492,
              45.981398
            ],
            [
              -93.51808,
              45.981789
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "141",
      "COUNTYNS": "00659515",
      "AFFGEOID": "0500000US27141",
      "GEOID": "27141",
      "NAME": "Sherburne",
      "LSAD": "06",
      "ALAND": 1121229505,
      "AWATER": 46967228,
      "County_state": "Sherburne,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.149467,
              45.559066
            ],
            [
              -94.14709,
              45.548935
            ],
            [
              -94.149058,
              45.525568
            ],
            [
              -94.139461,
              45.516366
            ],
            [
              -94.141989,
              45.493243
            ],
            [
              -94.107743,
              45.472368
            ],
            [
              -94.100303,
              45.466646
            ],
            [
              -94.101948,
              45.456052
            ],
            [
              -94.079197,
              45.43795
            ],
            [
              -94.05107,
              45.434433
            ],
            [
              -94.047327,
              45.422861
            ],
            [
              -94.025662,
              45.40447
            ],
            [
              -94.007947,
              45.404653
            ],
            [
              -94.00914,
              45.390868
            ],
            [
              -93.996209,
              45.401656
            ],
            [
              -93.973642,
              45.38881
            ],
            [
              -93.957401,
              45.377949
            ],
            [
              -93.918525,
              45.384373
            ],
            [
              -93.888878,
              45.363053
            ],
            [
              -93.86759,
              45.339281
            ],
            [
              -93.831139,
              45.334779
            ],
            [
              -93.799622,
              45.310244
            ],
            [
              -93.75661,
              45.294721
            ],
            [
              -93.70439,
              45.297723
            ],
            [
              -93.685845,
              45.305056
            ],
            [
              -93.673369,
              45.296206
            ],
            [
              -93.57939,
              45.292122
            ],
            [
              -93.565473,
              45.301577
            ],
            [
              -93.551938,
              45.264989
            ],
            [
              -93.521653,
              45.246618
            ],
            [
              -93.51219,
              45.245683
            ],
            [
              -93.510992,
              45.299346
            ],
            [
              -93.509794,
              45.385613
            ],
            [
              -93.51007,
              45.414798
            ],
            [
              -93.510589,
              45.471879
            ],
            [
              -93.510704,
              45.558772
            ],
            [
              -93.759435,
              45.559646
            ],
            [
              -94.006878,
              45.559232
            ],
            [
              -94.149467,
              45.559066
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "169",
      "COUNTYNS": "00659529",
      "AFFGEOID": "0500000US27169",
      "GEOID": "27169",
      "NAME": "Winona",
      "LSAD": "06",
      "ALAND": 1621558336,
      "AWATER": 40131783,
      "County_state": "Winona,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -92.079236,
              44.192966
            ],
            [
              -92.079491,
              44.106988
            ],
            [
              -92.078544,
              43.933931
            ],
            [
              -92.079292,
              43.84708
            ],
            [
              -91.730424,
              43.846935
            ],
            [
              -91.284138,
              43.847065
            ],
            [
              -91.291002,
              43.852733
            ],
            [
              -91.296739,
              43.855165
            ],
            [
              -91.298815,
              43.856555
            ],
            [
              -91.301302,
              43.859515
            ],
            [
              -91.310991,
              43.867381
            ],
            [
              -91.313037,
              43.875757
            ],
            [
              -91.31531,
              43.881808
            ],
            [
              -91.320605,
              43.888491
            ],
            [
              -91.328143,
              43.893435
            ],
            [
              -91.338141,
              43.897664
            ],
            [
              -91.342335,
              43.902697
            ],
            [
              -91.346271,
              43.910074
            ],
            [
              -91.347741,
              43.911964
            ],
            [
              -91.351688,
              43.914545
            ],
            [
              -91.356741,
              43.916564
            ],
            [
              -91.357426,
              43.917231
            ],
            [
              -91.363242,
              43.926563
            ],
            [
              -91.364736,
              43.934884
            ],
            [
              -91.366642,
              43.937463
            ],
            [
              -91.375142,
              43.944289
            ],
            [
              -91.385785,
              43.954239
            ],
            [
              -91.395086,
              43.959409
            ],
            [
              -91.406011,
              43.963929
            ],
            [
              -91.407395,
              43.965148
            ],
            [
              -91.410555,
              43.970892
            ],
            [
              -91.412491,
              43.973411
            ],
            [
              -91.424134,
              43.982631
            ],
            [
              -91.42518797925139,
              43.9843219996781
            ],
            [
              -91.425681,
              43.985113
            ],
            [
              -91.42672,
              43.9885
            ],
            [
              -91.429878,
              43.993888
            ],
            [
              -91.432522,
              43.996827
            ],
            [
              -91.43738,
              43.999962
            ],
            [
              -91.440536,
              44.001501
            ],
            [
              -91.457378,
              44.006301
            ],
            [
              -91.463515,
              44.009041
            ],
            [
              -91.468472,
              44.00948
            ],
            [
              -91.478498,
              44.00803
            ],
            [
              -91.48087,
              44.008145
            ],
            [
              -91.494988,
              44.012536
            ],
            [
              -91.502163,
              44.016856
            ],
            [
              -91.507121,
              44.01898
            ],
            [
              -91.524315,
              44.021433
            ],
            [
              -91.533778,
              44.021433
            ],
            [
              -91.547028,
              44.022226
            ],
            [
              -91.559004,
              44.025315
            ],
            [
              -91.573283,
              44.026901
            ],
            [
              -91.580019,
              44.026925
            ],
            [
              -91.582604,
              44.027381
            ],
            [
              -91.59207,
              44.031372
            ],
            [
              -91.597617,
              44.034965
            ],
            [
              -91.60355,
              44.043681
            ],
            [
              -91.607339,
              44.047357
            ],
            [
              -91.610487,
              44.04931
            ],
            [
              -91.615375,
              44.051598
            ],
            [
              -91.623784,
              44.054106
            ],
            [
              -91.6279,
              44.055807
            ],
            [
              -91.633365,
              44.060364
            ],
            [
              -91.638115,
              44.063285
            ],
            [
              -91.640535,
              44.063679
            ],
            [
              -91.6434,
              44.062711
            ],
            [
              -91.644717,
              44.062782
            ],
            [
              -91.647873,
              44.064109
            ],
            [
              -91.652247,
              44.068634
            ],
            [
              -91.657,
              44.071409
            ],
            [
              -91.659511,
              44.074203
            ],
            [
              -91.663442,
              44.08091
            ],
            [
              -91.665263,
              44.085041
            ],
            [
              -91.667006,
              44.086964
            ],
            [
              -91.68153,
              44.0974
            ],
            [
              -91.685748,
              44.098419
            ],
            [
              -91.691281,
              44.097858
            ],
            [
              -91.69531,
              44.09857
            ],
            [
              -91.707491,
              44.103906
            ],
            [
              -91.708207,
              44.105186
            ],
            [
              -91.708082,
              44.110929
            ],
            [
              -91.709476,
              44.117565
            ],
            [
              -91.710597,
              44.12048
            ],
            [
              -91.719097,
              44.128853
            ],
            [
              -91.71972299802539,
              44.1292326786394
            ],
            [
              -91.721552,
              44.130342
            ],
            [
              -91.730648,
              44.1329
            ],
            [
              -91.751747,
              44.134786
            ],
            [
              -91.756719,
              44.136804
            ],
            [
              -91.768574,
              44.143508
            ],
            [
              -91.774486,
              44.147539
            ],
            [
              -91.796669,
              44.154335
            ],
            [
              -91.808064,
              44.159262
            ],
            [
              -91.817302,
              44.164235
            ],
            [
              -91.829167,
              44.17835
            ],
            [
              -91.832479,
              44.180308
            ],
            [
              -91.844754,
              44.184878
            ],
            [
              -91.8583929427025,
              44.1930031501986
            ],
            [
              -92.079236,
              44.192966
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "121",
      "COUNTYNS": "00659506",
      "AFFGEOID": "0500000US27121",
      "GEOID": "27121",
      "NAME": "Pope",
      "LSAD": "06",
      "ALAND": 1734134611,
      "AWATER": 123231111,
      "County_state": "Pope,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.758508,
              45.759932
            ],
            [
              -95.758327,
              45.585508
            ],
            [
              -95.747754,
              45.585521
            ],
            [
              -95.747402,
              45.412313
            ],
            [
              -95.500436,
              45.411896
            ],
            [
              -95.255206,
              45.412525
            ],
            [
              -95.13173,
              45.412402
            ],
            [
              -95.13148,
              45.58503
            ],
            [
              -95.139856,
              45.585016
            ],
            [
              -95.13967,
              45.758891
            ],
            [
              -95.409538,
              45.759845
            ],
            [
              -95.509894,
              45.758786
            ],
            [
              -95.758508,
              45.759932
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "085",
      "COUNTYNS": "00659491",
      "AFFGEOID": "0500000US27085",
      "GEOID": "27085",
      "NAME": "McLeod",
      "LSAD": "06",
      "ALAND": 1272940264,
      "AWATER": 36370228,
      "County_state": "McLeod,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.501885,
              44.979695
            ],
            [
              -94.502395,
              44.892295
            ],
            [
              -94.497802,
              44.889669
            ],
            [
              -94.497762,
              44.804995
            ],
            [
              -94.498074,
              44.717171
            ],
            [
              -94.497835,
              44.629921
            ],
            [
              -94.254719,
              44.630311
            ],
            [
              -94.254076,
              44.717855
            ],
            [
              -94.010494,
              44.717464
            ],
            [
              -94.009864,
              44.891898
            ],
            [
              -94.012236,
              44.978712
            ],
            [
              -94.256074,
              44.979465
            ],
            [
              -94.501885,
              44.979695
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "077",
      "COUNTYNS": "00659483",
      "AFFGEOID": "0500000US27077",
      "GEOID": "27077",
      "NAME": "Lake of the Woods",
      "LSAD": "06",
      "ALAND": 3361462398,
      "AWATER": 1247519338,
      "County_state": "Lake of the Woods,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.34273,
              48.713028
            ],
            [
              -95.34254,
              48.540211
            ],
            [
              -95.211966,
              48.539961
            ],
            [
              -95.210953,
              48.365971
            ],
            [
              -94.428702,
              48.367333
            ],
            [
              -94.430302595501,
              48.7009500088477
            ],
            [
              -94.438701,
              48.694889
            ],
            [
              -94.446604,
              48.6929
            ],
            [
              -94.452332,
              48.692444
            ],
            [
              -94.464481,
              48.695503
            ],
            [
              -94.472938,
              48.696849
            ],
            [
              -94.486503,
              48.698054
            ],
            [
              -94.500203,
              48.698175
            ],
            [
              -94.508862,
              48.700362
            ],
            [
              -94.5246,
              48.701556
            ],
            [
              -94.533057,
              48.701262
            ],
            [
              -94.538372,
              48.70284
            ],
            [
              -94.545514,
              48.712185
            ],
            [
              -94.549069,
              48.714653
            ],
            [
              -94.555835,
              48.716207
            ],
            [
              -94.568368,
              48.715522
            ],
            [
              -94.58715,
              48.717599
            ],
            [
              -94.591018,
              48.719494
            ],
            [
              -94.595855,
              48.724222
            ],
            [
              -94.601384,
              48.728356
            ],
            [
              -94.610539,
              48.731893
            ],
            [
              -94.61901,
              48.737374
            ],
            [
              -94.640803,
              48.741171
            ],
            [
              -94.645083,
              48.744143
            ],
            [
              -94.64515,
              48.748991
            ],
            [
              -94.646256,
              48.749975
            ],
            [
              -94.645164,
              48.749975
            ],
            [
              -94.651765,
              48.755913
            ],
            [
              -94.660063,
              48.760288
            ],
            [
              -94.66711,
              48.766115
            ],
            [
              -94.672812,
              48.769315
            ],
            [
              -94.687951,
              48.775896
            ],
            [
              -94.690863,
              48.778047
            ],
            [
              -94.690889,
              48.778066
            ],
            [
              -94.694312,
              48.789352
            ],
            [
              -94.695975,
              48.799771
            ],
            [
              -94.694974,
              48.809206
            ],
            [
              -94.704284,
              48.824284
            ],
            [
              -94.701968,
              48.831778
            ],
            [
              -94.697055,
              48.835731
            ],
            [
              -94.685681,
              48.840119
            ],
            [
              -94.693044,
              48.853392
            ],
            [
              -94.690246,
              48.863363
            ],
            [
              -94.690302,
              48.863711
            ],
            [
              -94.692527,
              48.86895
            ],
            [
              -94.684217,
              48.872399
            ],
            [
              -94.683127,
              48.883376
            ],
            [
              -94.683069,
              48.883929
            ],
            [
              -94.718932,
              48.999991
            ],
            [
              -94.750221,
              49.099763
            ],
            [
              -94.773223,
              49.120733
            ],
            [
              -94.774228,
              49.124994
            ],
            [
              -94.797527,
              49.197791
            ],
            [
              -94.797244,
              49.214284
            ],
            [
              -94.82516,
              49.294283
            ],
            [
              -94.824291,
              49.308834
            ],
            [
              -94.816222,
              49.320987
            ],
            [
              -94.836876,
              49.324068
            ],
            [
              -94.854245,
              49.324154
            ],
            [
              -94.878454,
              49.333193
            ],
            [
              -94.907036,
              49.348508
            ],
            [
              -94.909273,
              49.350176
            ],
            [
              -94.952111,
              49.368679
            ],
            [
              -94.957465,
              49.370186
            ],
            [
              -94.974286,
              49.367738
            ],
            [
              -94.988908,
              49.368897
            ],
            [
              -95.014415,
              49.356405
            ],
            [
              -95.049382,
              49.353056
            ],
            [
              -95.058404,
              49.35317
            ],
            [
              -95.089806,
              49.361114
            ],
            [
              -95.102818,
              49.363554
            ],
            [
              -95.105057,
              49.364962
            ],
            [
              -95.109535,
              49.366315
            ],
            [
              -95.115866,
              49.366518
            ],
            [
              -95.126467,
              49.369439
            ],
            [
              -95.141808,
              49.378301
            ],
            [
              -95.145306,
              49.37828
            ],
            [
              -95.149747,
              49.380565
            ],
            [
              -95.150235,
              49.382964
            ],
            [
              -95.153314,
              49.384358
            ],
            [
              -95.15335,
              49.383079
            ],
            [
              -95.153293,
              49.369107
            ],
            [
              -95.153259,
              49.367691
            ],
            [
              -95.15333,
              49.365886
            ],
            [
              -95.153407,
              49.354397
            ],
            [
              -95.153344,
              49.343662
            ],
            [
              -95.153284,
              49.343409
            ],
            [
              -95.15333,
              49.309287
            ],
            [
              -95.153331,
              49.308442
            ],
            [
              -95.153319,
              49.30772
            ],
            [
              -95.153333,
              49.305655
            ],
            [
              -95.153424,
              49.249995
            ],
            [
              -95.153309,
              49.18488
            ],
            [
              -95.153711,
              48.998903
            ],
            [
              -95.31989470776959,
              48.998769452068096
            ],
            [
              -95.326758,
              48.982076
            ],
            [
              -95.317731,
              48.952444
            ],
            [
              -95.32264,
              48.935683
            ],
            [
              -95.30104,
              48.904216
            ],
            [
              -95.254859,
              48.884474
            ],
            [
              -95.235606,
              48.88145
            ],
            [
              -95.138145,
              48.894833
            ],
            [
              -95.091464,
              48.920618
            ],
            [
              -95.089863,
              48.713062
            ],
            [
              -95.34273,
              48.713028
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "097",
      "COUNTYNS": "00659494",
      "AFFGEOID": "0500000US27097",
      "GEOID": "27097",
      "NAME": "Morrison",
      "LSAD": "06",
      "ALAND": 2913973124,
      "AWATER": 73283119,
      "County_state": "Morrison,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.653462,
              46.348678
            ],
            [
              -94.647909,
              46.282439
            ],
            [
              -94.648217,
              46.020416
            ],
            [
              -94.643596,
              45.932852
            ],
            [
              -94.64399,
              45.77395
            ],
            [
              -94.5133,
              45.77438
            ],
            [
              -94.271776,
              45.775208
            ],
            [
              -94.312007,
              45.790589
            ],
            [
              -94.31323,
              45.800071
            ],
            [
              -94.327853,
              45.79861
            ],
            [
              -94.353165,
              45.822131
            ],
            [
              -94.010432,
              45.823981
            ],
            [
              -93.763948,
              45.822143
            ],
            [
              -93.768374,
              45.909702
            ],
            [
              -93.768891,
              45.982525
            ],
            [
              -93.810321,
              45.98308
            ],
            [
              -93.810362,
              46.156585
            ],
            [
              -94.374592,
              46.157093
            ],
            [
              -94.366186,
              46.180083
            ],
            [
              -94.395718,
              46.211859
            ],
            [
              -94.394384,
              46.228711
            ],
            [
              -94.388922,
              46.238166
            ],
            [
              -94.355302,
              46.255095
            ],
            [
              -94.33977,
              46.270087
            ],
            [
              -94.341679,
              46.277705
            ],
            [
              -94.360673,
              46.300008
            ],
            [
              -94.370736,
              46.297166
            ],
            [
              -94.381181,
              46.309239
            ],
            [
              -94.426529,
              46.332336
            ],
            [
              -94.460459,
              46.331163
            ],
            [
              -94.483826,
              46.315805
            ],
            [
              -94.507885,
              46.312863
            ],
            [
              -94.534724,
              46.302797
            ],
            [
              -94.556183,
              46.307136
            ],
            [
              -94.585557,
              46.324378
            ],
            [
              -94.607545,
              46.329468
            ],
            [
              -94.622852,
              46.326665
            ],
            [
              -94.653462,
              46.348678
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "027",
      "COUNTYNS": "00659459",
      "AFFGEOID": "0500000US27027",
      "GEOID": "27027",
      "NAME": "Clay",
      "LSAD": "06",
      "ALAND": 2707039036,
      "AWATER": 19306174,
      "County_state": "Clay,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.834508,
              47.008867
            ],
            [
              -96.834603,
              47.007721
            ],
            [
              -96.834221,
              47.006671
            ],
            [
              -96.831798,
              47.004353
            ],
            [
              -96.827489,
              47.001611
            ],
            [
              -96.826198,
              47.001895
            ],
            [
              -96.82318,
              46.999965
            ],
            [
              -96.823189,
              46.998026
            ],
            [
              -96.82447,
              46.996173
            ],
            [
              -96.824598,
              46.993309
            ],
            [
              -96.822566,
              46.990141
            ],
            [
              -96.819894,
              46.977357
            ],
            [
              -96.8200744513851,
              46.9768308444026
            ],
            [
              -96.822043,
              46.971091
            ],
            [
              -96.821852,
              46.969372
            ],
            [
              -96.819558,
              46.967453
            ],
            [
              -96.809814,
              46.9639
            ],
            [
              -96.802749,
              46.965933
            ],
            [
              -96.801316,
              46.965933
            ],
            [
              -96.79931,
              46.964118
            ],
            [
              -96.798737,
              46.962399
            ],
            [
              -96.79991,
              46.959228
            ],
            [
              -96.799606,
              46.954316
            ],
            [
              -96.798758,
              46.952988
            ],
            [
              -96.799358,
              46.947355
            ],
            [
              -96.797734,
              46.9464
            ],
            [
              -96.792863,
              46.946018
            ],
            [
              -96.791558,
              46.944464
            ],
            [
              -96.790058,
              46.937664
            ],
            [
              -96.791558,
              46.934264
            ],
            [
              -96.791621,
              46.931213
            ],
            [
              -96.791048,
              46.929876
            ],
            [
              -96.79038,
              46.929398
            ],
            [
              -96.786845,
              46.928921
            ],
            [
              -96.785126,
              46.925769
            ],
            [
              -96.78312,
              46.925482
            ],
            [
              -96.780258,
              46.928263
            ],
            [
              -96.775157,
              46.930863
            ],
            [
              -96.763257,
              46.935063
            ],
            [
              -96.761757,
              46.934663
            ],
            [
              -96.760292,
              46.93341
            ],
            [
              -96.760292,
              46.932073
            ],
            [
              -96.762011,
              46.929303
            ],
            [
              -96.762011,
              46.928347
            ],
            [
              -96.761725,
              46.927297
            ],
            [
              -96.759528,
              46.925769
            ],
            [
              -96.760961,
              46.923858
            ],
            [
              -96.761343,
              46.922234
            ],
            [
              -96.760865,
              46.920897
            ],
            [
              -96.7604247876106,
              46.9205118141593
            ],
            [
              -96.759337,
              46.91956
            ],
            [
              -96.759241,
              46.918223
            ],
            [
              -96.762871,
              46.916886
            ],
            [
              -96.763973,
              46.912507
            ],
            [
              -96.763557,
              46.909463
            ],
            [
              -96.765657,
              46.905063
            ],
            [
              -96.767458,
              46.905163
            ],
            [
              -96.770458,
              46.906763
            ],
            [
              -96.773558,
              46.903563
            ],
            [
              -96.776558,
              46.895663
            ],
            [
              -96.773558,
              46.884763
            ],
            [
              -96.771858,
              46.884063
            ],
            [
              -96.769758,
              46.884763
            ],
            [
              -96.768058,
              46.884763
            ],
            [
              -96.767358,
              46.883663
            ],
            [
              -96.768458,
              46.879563
            ],
            [
              -96.769758,
              46.877563
            ],
            [
              -96.771258,
              46.877463
            ],
            [
              -96.775558,
              46.879163
            ],
            [
              -96.775590535491,
              46.8791697782273
            ],
            [
              -96.780358,
              46.880163
            ],
            [
              -96.781358,
              46.879363
            ],
            [
              -96.780358,
              46.877063
            ],
            [
              -96.779258,
              46.875963
            ],
            [
              -96.779302,
              46.872699
            ],
            [
              -96.780758,
              46.867163
            ],
            [
              -96.782881,
              46.86459
            ],
            [
              -96.782881,
              46.862585
            ],
            [
              -96.7828151889964,
              46.8624944668
            ],
            [
              -96.781353,
              46.860483
            ],
            [
              -96.781067,
              46.859146
            ],
            [
              -96.781162,
              46.857809
            ],
            [
              -96.781926,
              46.856472
            ],
            [
              -96.782022,
              46.853415
            ],
            [
              -96.780876,
              46.852269
            ],
            [
              -96.779061,
              46.851696
            ],
            [
              -96.777915,
              46.850741
            ],
            [
              -96.777915,
              46.849594
            ],
            [
              -96.779729,
              46.847302
            ],
            [
              -96.780207,
              46.845392
            ],
            [
              -96.779347,
              46.843672
            ],
            [
              -96.779347,
              46.842144
            ],
            [
              -96.780398,
              46.841189
            ],
            [
              -96.783359,
              46.840807
            ],
            [
              -96.783837,
              46.840234
            ],
            [
              -96.784028,
              46.838897
            ],
            [
              -96.783264,
              46.837464
            ],
            [
              -96.78355,
              46.835936
            ],
            [
              -96.785365,
              46.834025
            ],
            [
              -96.789377,
              46.833166
            ],
            [
              -96.789663,
              46.832306
            ],
            [
              -96.787275,
              46.829059
            ],
            [
              -96.787657,
              46.827817
            ],
            [
              -96.789377,
              46.827435
            ],
            [
              -96.791559,
              46.827864
            ],
            [
              -96.79796,
              46.822364
            ],
            [
              -96.80016,
              46.819664
            ],
            [
              -96.7998821963033,
              46.8182385703524
            ],
            [
              -96.799336,
              46.815436
            ],
            [
              -96.80036,
              46.8135
            ],
            [
              -96.802013,
              46.812464
            ],
            [
              -96.802544,
              46.811521
            ],
            [
              -96.801446,
              46.810401
            ],
            [
              -96.796488,
              46.808709
            ],
            [
              -96.795756,
              46.807795
            ],
            [
              -96.796992,
              46.791572
            ],
            [
              -96.796195,
              46.789881
            ],
            [
              -96.7949794676555,
              46.7890238787444
            ],
            [
              -96.793102,
              46.7877
            ],
            [
              -96.791478,
              46.785694
            ],
            [
              -96.791096,
              46.783688
            ],
            [
              -96.792624,
              46.780632
            ],
            [
              -96.792433,
              46.778913
            ],
            [
              -96.792051,
              46.778339
            ],
            [
              -96.788803,
              46.777575
            ],
            [
              -96.788135,
              46.776238
            ],
            [
              -96.78909,
              46.773373
            ],
            [
              -96.788612,
              46.771271
            ],
            [
              -96.784983,
              46.768788
            ],
            [
              -96.784314,
              46.767546
            ],
            [
              -96.784314,
              46.766973
            ],
            [
              -96.785651,
              46.766113
            ],
            [
              -96.785556,
              46.764394
            ],
            [
              -96.783646,
              46.762579
            ],
            [
              -96.784601,
              46.761338
            ],
            [
              -96.786129,
              46.760956
            ],
            [
              -96.787466,
              46.758472
            ],
            [
              -96.787466,
              46.756753
            ],
            [
              -96.783646,
              46.753123
            ],
            [
              -96.783455,
              46.750353
            ],
            [
              -96.784568,
              46.748669
            ],
            [
              -96.785269,
              46.746246
            ],
            [
              -96.784601,
              46.743094
            ],
            [
              -96.781216,
              46.740944
            ],
            [
              -96.781617,
              46.737197
            ],
            [
              -96.784279,
              46.732993
            ],
            [
              -96.781544,
              46.730104
            ],
            [
              -96.77992,
              46.729149
            ],
            [
              -96.779252,
              46.727429
            ],
            [
              -96.779899,
              46.722915
            ],
            [
              -96.784751,
              46.720495
            ],
            [
              -96.78552018417359,
              46.7163860643062
            ],
            [
              -96.786184,
              46.71284
            ],
            [
              -96.791204,
              46.703747
            ],
            [
              -96.790906,
              46.70297
            ],
            [
              -96.787801,
              46.700446
            ],
            [
              -96.786654,
              46.695861
            ],
            [
              -96.786845,
              46.692805
            ],
            [
              -96.787801,
              46.691181
            ],
            [
              -96.786941,
              46.68822
            ],
            [
              -96.785068,
              46.687636
            ],
            [
              -96.784205,
              46.686768
            ],
            [
              -96.784339,
              46.685054
            ],
            [
              -96.788159,
              46.681879
            ],
            [
              -96.787801,
              46.679815
            ],
            [
              -96.788947,
              46.678382
            ],
            [
              -96.792958,
              46.677427
            ],
            [
              -96.79334,
              46.676854
            ],
            [
              -96.793723,
              46.674943
            ],
            [
              -96.792576,
              46.672173
            ],
            [
              -96.793914,
              46.669212
            ],
            [
              -96.798357,
              46.665314
            ],
            [
              -96.798823,
              46.658071
            ],
            [
              -96.796767,
              46.653363
            ],
            [
              -96.790663,
              46.649112
            ],
            [
              -96.789405,
              46.641639
            ],
            [
              -96.789572,
              46.639079
            ],
            [
              -96.790523,
              46.63688
            ],
            [
              -96.791096,
              46.633155
            ],
            [
              -96.78995,
              46.631531
            ],
            [
              -96.78481470379259,
              46.629439247899995
            ],
            [
              -96.779394,
              46.630272
            ],
            [
              -96.280977,
              46.630765
            ],
            [
              -96.17227,
              46.630709
            ],
            [
              -96.174587,
              46.717854
            ],
            [
              -96.177027,
              46.804851
            ],
            [
              -96.174108,
              46.977375
            ],
            [
              -96.193571,
              46.977371
            ],
            [
              -96.194671,
              47.151149
            ],
            [
              -96.8312096159494,
              47.1505219877248
            ],
            [
              -96.831069,
              47.149467
            ],
            [
              -96.830114,
              47.148512
            ],
            [
              -96.830114,
              47.146793
            ],
            [
              -96.832407,
              47.143736
            ],
            [
              -96.831547,
              47.142017
            ],
            [
              -96.828597,
              47.1398
            ],
            [
              -96.827631,
              47.136572
            ],
            [
              -96.827631,
              47.134758
            ],
            [
              -96.828777,
              47.13151
            ],
            [
              -96.827631,
              47.129504
            ],
            [
              -96.824476,
              47.127188
            ],
            [
              -96.824807,
              47.124968
            ],
            [
              -96.82544,
              47.123354
            ],
            [
              -96.826712,
              47.122852
            ],
            [
              -96.827726,
              47.121481
            ],
            [
              -96.827344,
              47.120144
            ],
            [
              -96.821189,
              47.115723
            ],
            [
              -96.820619,
              47.113712
            ],
            [
              -96.822192,
              47.111679
            ],
            [
              -96.822694,
              47.109622
            ],
            [
              -96.82159,
              47.108457
            ],
            [
              -96.818843,
              47.107154
            ],
            [
              -96.817984,
              47.106007
            ],
            [
              -96.818175,
              47.104193
            ],
            [
              -96.81999,
              47.100849
            ],
            [
              -96.819894,
              47.099321
            ],
            [
              -96.818557,
              47.097888
            ],
            [
              -96.818366,
              47.093304
            ],
            [
              -96.820085,
              47.091393
            ],
            [
              -96.820563,
              47.08977
            ],
            [
              -96.819034,
              47.087573
            ],
            [
              -96.82065,
              47.083619
            ],
            [
              -96.820216,
              47.082111
            ],
            [
              -96.819078,
              47.081152
            ],
            [
              -96.819479,
              47.078181
            ],
            [
              -96.821613,
              47.076302
            ],
            [
              -96.820849,
              47.073818
            ],
            [
              -96.821231,
              47.07315
            ],
            [
              -96.823715,
              47.071717
            ],
            [
              -96.824097,
              47.070666
            ],
            [
              -96.823491,
              47.065911
            ],
            [
              -96.821804,
              47.064362
            ],
            [
              -96.821327,
              47.06293
            ],
            [
              -96.822186,
              47.06207
            ],
            [
              -96.824097,
              47.061497
            ],
            [
              -96.824479,
              47.059682
            ],
            [
              -96.822568,
              47.055861
            ],
            [
              -96.819321,
              47.0529
            ],
            [
              -96.818843,
              47.047074
            ],
            [
              -96.820849,
              47.041438
            ],
            [
              -96.820563,
              47.039528
            ],
            [
              -96.818748,
              47.037618
            ],
            [
              -96.818557,
              47.035516
            ],
            [
              -96.818843,
              47.034179
            ],
            [
              -96.821422,
              47.032842
            ],
            [
              -96.821613,
              47.031505
            ],
            [
              -96.821231,
              47.029977
            ],
            [
              -96.818557,
              47.02778
            ],
            [
              -96.817984,
              47.026538
            ],
            [
              -96.819416,
              47.024914
            ],
            [
              -96.826358,
              47.023205
            ],
            [
              -96.829499,
              47.021537
            ],
            [
              -96.833038,
              47.016029
            ],
            [
              -96.832303,
              47.015184
            ],
            [
              -96.833504,
              47.01011
            ],
            [
              -96.834508,
              47.008867
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "053",
      "COUNTYNS": "00659472",
      "AFFGEOID": "0500000US27053",
      "GEOID": "27053",
      "NAME": "Hennepin",
      "LSAD": "06",
      "ALAND": 1433839320,
      "AWATER": 137173629,
      "County_state": "Hennepin,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.766808,
              45.057312
            ],
            [
              -93.767367,
              44.977964
            ],
            [
              -93.766442,
              44.974462
            ],
            [
              -93.767041,
              44.890862
            ],
            [
              -93.683722,
              44.890934
            ],
            [
              -93.663986,
              44.891438
            ],
            [
              -93.645317,
              44.891058
            ],
            [
              -93.580704,
              44.891162
            ],
            [
              -93.523355,
              44.891457
            ],
            [
              -93.520717,
              44.888557
            ],
            [
              -93.520813,
              44.866077
            ],
            [
              -93.520632,
              44.858562
            ],
            [
              -93.520431,
              44.804263
            ],
            [
              -93.457929,
              44.803363
            ],
            [
              -93.438089,
              44.813007
            ],
            [
              -93.39885,
              44.798782
            ],
            [
              -93.398827,
              44.798763
            ],
            [
              -93.380848,
              44.785691
            ],
            [
              -93.3554,
              44.785595
            ],
            [
              -93.351882,
              44.789893
            ],
            [
              -93.336745,
              44.786386
            ],
            [
              -93.329615,
              44.791086
            ],
            [
              -93.251873,
              44.811476
            ],
            [
              -93.211437,
              44.839742
            ],
            [
              -93.19794,
              44.862
            ],
            [
              -93.178771,
              44.880162
            ],
            [
              -93.178539,
              44.888338
            ],
            [
              -93.177201,
              44.893793
            ],
            [
              -93.194023,
              44.905414
            ],
            [
              -93.200334,
              44.908934
            ],
            [
              -93.200767,
              44.93413
            ],
            [
              -93.20266,
              44.948375
            ],
            [
              -93.207757,
              44.953209
            ],
            [
              -93.207587,
              44.988071
            ],
            [
              -93.207569,
              45.006239
            ],
            [
              -93.208092,
              45.035744
            ],
            [
              -93.226929,
              45.035671
            ],
            [
              -93.272029,
              45.035503
            ],
            [
              -93.283426,
              45.035365
            ],
            [
              -93.282226,
              45.051165
            ],
            [
              -93.285226,
              45.058465
            ],
            [
              -93.280815,
              45.071716
            ],
            [
              -93.278327,
              45.103365
            ],
            [
              -93.284727,
              45.108565
            ],
            [
              -93.294796,
              45.114918
            ],
            [
              -93.296092,
              45.121736
            ],
            [
              -93.298986,
              45.128892
            ],
            [
              -93.311544,
              45.143778
            ],
            [
              -93.328389,
              45.151507
            ],
            [
              -93.337849,
              45.156156
            ],
            [
              -93.35883,
              45.174965
            ],
            [
              -93.373301,
              45.178169
            ],
            [
              -93.415232,
              45.200665
            ],
            [
              -93.422453,
              45.211423
            ],
            [
              -93.485469,
              45.225678
            ],
            [
              -93.51219,
              45.245683
            ],
            [
              -93.521653,
              45.246618
            ],
            [
              -93.52243,
              45.244371
            ],
            [
              -93.534107,
              45.230606
            ],
            [
              -93.569971,
              45.218523
            ],
            [
              -93.639951,
              45.2093
            ],
            [
              -93.65881,
              45.194116
            ],
            [
              -93.638653,
              45.174161
            ],
            [
              -93.638622,
              45.16562
            ],
            [
              -93.676267,
              45.151044
            ],
            [
              -93.68131,
              45.15125
            ],
            [
              -93.700742,
              45.136363
            ],
            [
              -93.729593,
              45.097441
            ],
            [
              -93.728882,
              45.093376
            ],
            [
              -93.728918,
              45.090016
            ],
            [
              -93.73323,
              45.087442
            ],
            [
              -93.733857,
              45.087236
            ],
            [
              -93.737832,
              45.082702
            ],
            [
              -93.761281,
              45.081837
            ],
            [
              -93.766808,
              45.057312
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "003",
      "COUNTYNS": "00659447",
      "AFFGEOID": "0500000US27003",
      "GEOID": "27003",
      "NAME": "Anoka",
      "LSAD": "06",
      "ALAND": 1095486322,
      "AWATER": 59843479,
      "County_state": "Anoka,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -93.510992,
              45.299346
            ],
            [
              -93.51219,
              45.245683
            ],
            [
              -93.485469,
              45.225678
            ],
            [
              -93.422453,
              45.211423
            ],
            [
              -93.415232,
              45.200665
            ],
            [
              -93.373301,
              45.178169
            ],
            [
              -93.35883,
              45.174965
            ],
            [
              -93.337849,
              45.156156
            ],
            [
              -93.328389,
              45.151507
            ],
            [
              -93.311544,
              45.143778
            ],
            [
              -93.298986,
              45.128892
            ],
            [
              -93.296092,
              45.121736
            ],
            [
              -93.294796,
              45.114918
            ],
            [
              -93.284727,
              45.108565
            ],
            [
              -93.278327,
              45.103365
            ],
            [
              -93.280815,
              45.071716
            ],
            [
              -93.285226,
              45.058465
            ],
            [
              -93.282226,
              45.051165
            ],
            [
              -93.283426,
              45.035365
            ],
            [
              -93.272029,
              45.035503
            ],
            [
              -93.226929,
              45.035671
            ],
            [
              -93.227059,
              45.049937
            ],
            [
              -93.227208,
              45.061619
            ],
            [
              -93.227237,
              45.065027
            ],
            [
              -93.227734,
              45.093217
            ],
            [
              -93.227828,
              45.107089
            ],
            [
              -93.227696,
              45.124533
            ],
            [
              -93.212672,
              45.124418
            ],
            [
              -93.188706,
              45.124374
            ],
            [
              -93.106725,
              45.124103
            ],
            [
              -93.020518,
              45.123866
            ],
            [
              -93.020069,
              45.183876
            ],
            [
              -93.019747,
              45.210741
            ],
            [
              -93.019745,
              45.210895
            ],
            [
              -93.01944,
              45.296929
            ],
            [
              -93.019563,
              45.41177
            ],
            [
              -93.093271,
              45.413778
            ],
            [
              -93.142242,
              45.413645
            ],
            [
              -93.162917,
              45.414212
            ],
            [
              -93.265467,
              45.413698
            ],
            [
              -93.51007,
              45.414798
            ],
            [
              -93.509794,
              45.385613
            ],
            [
              -93.510992,
              45.299346
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "155",
      "COUNTYNS": "00659522",
      "AFFGEOID": "0500000US27155",
      "GEOID": "27155",
      "NAME": "Traverse",
      "LSAD": "06",
      "ALAND": 1486320975,
      "AWATER": 31025234,
      "County_state": "Traverse,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.856657,
              45.609041
            ],
            [
              -96.857751,
              45.605962
            ],
            [
              -96.853646,
              45.602307
            ],
            [
              -96.849444,
              45.598944
            ],
            [
              -96.844334,
              45.594375
            ],
            [
              -96.843957,
              45.594003
            ],
            [
              -96.835451,
              45.586129
            ],
            [
              -96.253026,
              45.585526
            ],
            [
              -96.254022,
              45.75982
            ],
            [
              -96.253675,
              45.934648
            ],
            [
              -96.26616,
              45.93471
            ],
            [
              -96.26614,
              46.02161
            ],
            [
              -96.5765693887837,
              46.0218456562824
            ],
            [
              -96.574264,
              46.016545
            ],
            [
              -96.575869,
              46.007999
            ],
            [
              -96.574064,
              46.004434
            ],
            [
              -96.573605,
              46.002309
            ],
            [
              -96.572483,
              45.989577
            ],
            [
              -96.572384,
              45.980231
            ],
            [
              -96.57035,
              45.963595
            ],
            [
              -96.564803,
              45.950349
            ],
            [
              -96.562135,
              45.947718
            ],
            [
              -96.561334,
              45.945655
            ],
            [
              -96.562525,
              45.937087
            ],
            [
              -96.563672,
              45.935245
            ],
            [
              -96.564518,
              45.926256
            ],
            [
              -96.564317,
              45.921074
            ],
            [
              -96.564002,
              45.91956
            ],
            [
              -96.566562,
              45.916931
            ],
            [
              -96.56703,
              45.915682
            ],
            [
              -96.566534,
              45.911876
            ],
            [
              -96.565541,
              45.910883
            ],
            [
              -96.56442,
              45.909415
            ],
            [
              -96.567268,
              45.905393
            ],
            [
              -96.568315,
              45.902902
            ],
            [
              -96.568053,
              45.898697
            ],
            [
              -96.568281,
              45.891203
            ],
            [
              -96.568772,
              45.888072
            ],
            [
              -96.571354,
              45.886673
            ],
            [
              -96.572651,
              45.876474
            ],
            [
              -96.571871,
              45.871846
            ],
            [
              -96.574667,
              45.866816
            ],
            [
              -96.574417,
              45.86501
            ],
            [
              -96.572984,
              45.861602
            ],
            [
              -96.574517,
              45.843098
            ],
            [
              -96.576544,
              45.839945
            ],
            [
              -96.577534,
              45.83793
            ],
            [
              -96.577484,
              45.833108
            ],
            [
              -96.57974,
              45.82582
            ],
            [
              -96.583085,
              45.820024
            ],
            [
              -96.587093,
              45.816445
            ],
            [
              -96.593216,
              45.813873
            ],
            [
              -96.596704,
              45.811801
            ],
            [
              -96.601863,
              45.806343
            ],
            [
              -96.607621,
              45.79907
            ],
            [
              -96.612512,
              45.794442
            ],
            [
              -96.618195,
              45.791063
            ],
            [
              -96.625347,
              45.787924
            ],
            [
              -96.627778,
              45.786239
            ],
            [
              -96.629426,
              45.784211
            ],
            [
              -96.630512,
              45.781157
            ],
            [
              -96.636646,
              45.773702
            ],
            [
              -96.638726,
              45.770171
            ],
            [
              -96.639685,
              45.7654
            ],
            [
              -96.641941,
              45.759871
            ],
            [
              -96.652226,
              45.746809
            ],
            [
              -96.662595,
              45.738682
            ],
            [
              -96.672665,
              45.732336
            ],
            [
              -96.687224,
              45.725931
            ],
            [
              -96.711157,
              45.717561
            ],
            [
              -96.745086,
              45.701576
            ],
            [
              -96.75035,
              45.698782
            ],
            [
              -96.757174,
              45.690957
            ],
            [
              -96.760866,
              45.687518
            ],
            [
              -96.800156,
              45.668355
            ],
            [
              -96.82616,
              45.654164
            ],
            [
              -96.827428,
              45.653067
            ],
            [
              -96.832659,
              45.651716
            ],
            [
              -96.835769,
              45.649648
            ],
            [
              -96.840746,
              45.645294
            ],
            [
              -96.844211,
              45.639583
            ],
            [
              -96.851621,
              45.619412
            ],
            [
              -96.852392,
              45.61484
            ],
            [
              -96.856657,
              45.609041
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "27",
      "COUNTYFP": "145",
      "COUNTYNS": "00659517",
      "AFFGEOID": "0500000US27145",
      "GEOID": "27145",
      "NAME": "Stearns",
      "LSAD": "06",
      "ALAND": 3477880457,
      "AWATER": 122121594,
      "County_state": "Stearns,27"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.13967,
              45.758891
            ],
            [
              -95.139856,
              45.585016
            ],
            [
              -95.13148,
              45.58503
            ],
            [
              -95.13173,
              45.412402
            ],
            [
              -94.762934,
              45.412861
            ],
            [
              -94.76308,
              45.3261
            ],
            [
              -94.640096,
              45.325601
            ],
            [
              -94.556913,
              45.325892
            ],
            [
              -94.53537,
              45.326104
            ],
            [
              -94.383744,
              45.326727
            ],
            [
              -94.383449,
              45.282797
            ],
            [
              -94.260821,
              45.283941
            ],
            [
              -94.256658,
              45.306473
            ],
            [
              -94.187262,
              45.318278
            ],
            [
              -94.161892,
              45.301233
            ],
            [
              -94.120819,
              45.325627
            ],
            [
              -94.081374,
              45.366844
            ],
            [
              -94.08371,
              45.379856
            ],
            [
              -94.052958,
              45.423665
            ],
            [
              -94.047327,
              45.422861
            ],
            [
              -94.05107,
              45.434433
            ],
            [
              -94.079197,
              45.43795
            ],
            [
              -94.101948,
              45.456052
            ],
            [
              -94.100303,
              45.466646
            ],
            [
              -94.107743,
              45.472368
            ],
            [
              -94.141989,
              45.493243
            ],
            [
              -94.139461,
              45.516366
            ],
            [
              -94.149058,
              45.525568
            ],
            [
              -94.14709,
              45.548935
            ],
            [
              -94.149467,
              45.559066
            ],
            [
              -94.160782,
              45.566362
            ],
            [
              -94.165623,
              45.574243
            ],
            [
              -94.168828,
              45.586257
            ],
            [
              -94.193638,
              45.602929
            ],
            [
              -94.203666,
              45.619556
            ],
            [
              -94.203859,
              45.647386
            ],
            [
              -94.198068,
              45.668642
            ],
            [
              -94.1823,
              45.678854
            ],
            [
              -94.203049,
              45.678153
            ],
            [
              -94.224689,
              45.715319
            ],
            [
              -94.215712,
              45.72656
            ],
            [
              -94.259727,
              45.742357
            ],
            [
              -94.271776,
              45.775208
            ],
            [
              -94.5133,
              45.77438
            ],
            [
              -94.64399,
              45.77395
            ],
            [
              -94.766899,
              45.773911
            ],
            [
              -95.139731,
              45.773416
            ],
            [
              -95.13967,
              45.758891
            ]
          ]
        ]
      ]
    }
  }
]
};