export const counties_wv = {
  "type":"FeatureCollection","name":"wv_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "093",
      "COUNTYNS": "01689423",
      "AFFGEOID": "0500000US54093",
      "GEOID": "54093",
      "NAME": "Tucker",
      "LSAD": "06",
      "ALAND": 1085094885,
      "AWATER": 5528932,
      "County_state": "Tucker,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.827491,
              39.160402
            ],
            [
              -79.838093,
              39.131002
            ],
            [
              -79.825092,
              39.115803
            ],
            [
              -79.799389,
              39.108003
            ],
            [
              -79.775289,
              39.076604
            ],
            [
              -79.776989,
              39.046505
            ],
            [
              -79.785685,
              39.037404
            ],
            [
              -79.642222,
              39.005211
            ],
            [
              -79.508765,
              38.975173
            ],
            [
              -79.503541,
              38.969967
            ],
            [
              -79.494634,
              38.97749
            ],
            [
              -79.42094,
              38.971681
            ],
            [
              -79.408663,
              38.973835
            ],
            [
              -79.357666,
              38.964509
            ],
            [
              -79.340667,
              38.969609
            ],
            [
              -79.310244,
              39.030293
            ],
            [
              -79.298164,
              39.07321
            ],
            [
              -79.314705,
              39.07025
            ],
            [
              -79.334094,
              39.082656
            ],
            [
              -79.355468,
              39.086508
            ],
            [
              -79.346632,
              39.107754
            ],
            [
              -79.331305,
              39.118839
            ],
            [
              -79.331766,
              39.147807
            ],
            [
              -79.308566,
              39.182005
            ],
            [
              -79.289264,
              39.189706
            ],
            [
              -79.307361,
              39.209803
            ],
            [
              -79.345066,
              39.213806
            ],
            [
              -79.365068,
              39.199406
            ],
            [
              -79.382369,
              39.203706
            ],
            [
              -79.39287,
              39.192907
            ],
            [
              -79.442572,
              39.186307
            ],
            [
              -79.467474,
              39.178807
            ],
            [
              -79.487175,
              39.194906
            ],
            [
              -79.507576,
              39.216206
            ],
            [
              -79.68387,
              39.270358
            ],
            [
              -79.692399,
              39.269667
            ],
            [
              -79.809729,
              39.230526
            ],
            [
              -79.826693,
              39.194101
            ],
            [
              -79.827491,
              39.160402
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "075",
      "COUNTYNS": "01550044",
      "AFFGEOID": "0500000US54075",
      "GEOID": "54075",
      "NAME": "Pocahontas",
      "LSAD": "06",
      "ALAND": 2435322222,
      "AWATER": 3978619,
      "County_state": "Pocahontas,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.360048,
              38.225845
            ],
            [
              -80.363295,
              38.114331
            ],
            [
              -80.264653,
              38.046616
            ],
            [
              -80.21594,
              38.041992
            ],
            [
              -80.197761,
              38.042947
            ],
            [
              -80.169169,
              38.036111
            ],
            [
              -80.106891,
              38.055845
            ],
            [
              -79.959844,
              38.063697
            ],
            [
              -79.960093,
              38.068677
            ],
            [
              -79.954369,
              38.080397
            ],
            [
              -79.953509,
              38.081484
            ],
            [
              -79.949113,
              38.084238
            ],
            [
              -79.942364,
              38.091588
            ],
            [
              -79.938274,
              38.094741
            ],
            [
              -79.935101,
              38.096541
            ],
            [
              -79.93425,
              38.097669
            ],
            [
              -79.933911,
              38.099168
            ],
            [
              -79.931034,
              38.101402
            ],
            [
              -79.927645,
              38.104826
            ],
            [
              -79.92633,
              38.107151
            ],
            [
              -79.929687,
              38.109197
            ],
            [
              -79.934364,
              38.109718
            ],
            [
              -79.938051,
              38.110759
            ],
            [
              -79.938952,
              38.111619
            ],
            [
              -79.944843,
              38.131585
            ],
            [
              -79.942747,
              38.134333
            ],
            [
              -79.933751,
              38.135508
            ],
            [
              -79.929031,
              38.139771
            ],
            [
              -79.928747,
              38.144436
            ],
            [
              -79.928683,
              38.144928
            ],
            [
              -79.925512,
              38.150237
            ],
            [
              -79.925251,
              38.150465
            ],
            [
              -79.923125,
              38.150874
            ],
            [
              -79.918662,
              38.15479
            ],
            [
              -79.914884,
              38.167524
            ],
            [
              -79.915065,
              38.168088
            ],
            [
              -79.916072,
              38.168428
            ],
            [
              -79.917924,
              38.168399
            ],
            [
              -79.918913,
              38.170439
            ],
            [
              -79.918629,
              38.172671
            ],
            [
              -79.916765,
              38.175504
            ],
            [
              -79.916622,
              38.177994
            ],
            [
              -79.921026,
              38.179954
            ],
            [
              -79.921196,
              38.180378
            ],
            [
              -79.917061,
              38.183741
            ],
            [
              -79.916174,
              38.184386
            ],
            [
              -79.916344,
              38.186278
            ],
            [
              -79.91441,
              38.188418
            ],
            [
              -79.910961,
              38.18792
            ],
            [
              -79.90609,
              38.188999
            ],
            [
              -79.898426,
              38.193045
            ],
            [
              -79.892916,
              38.199868
            ],
            [
              -79.892345,
              38.202397
            ],
            [
              -79.891999,
              38.203378
            ],
            [
              -79.891591,
              38.204652
            ],
            [
              -79.888045,
              38.20736
            ],
            [
              -79.886413,
              38.207953
            ],
            [
              -79.884234,
              38.207868
            ],
            [
              -79.879087,
              38.211016
            ],
            [
              -79.863625,
              38.223945
            ],
            [
              -79.856962,
              38.231075
            ],
            [
              -79.850324,
              38.233329
            ],
            [
              -79.846445,
              38.240003
            ],
            [
              -79.845207,
              38.241082
            ],
            [
              -79.842981,
              38.241594
            ],
            [
              -79.837494,
              38.241276
            ],
            [
              -79.835124,
              38.241892
            ],
            [
              -79.834171,
              38.242899
            ],
            [
              -79.834031,
              38.244957
            ],
            [
              -79.832971,
              38.247553
            ],
            [
              -79.830882,
              38.249687
            ],
            [
              -79.825283,
              38.250488
            ],
            [
              -79.82101,
              38.248277
            ],
            [
              -79.819623,
              38.248234
            ],
            [
              -79.817149,
              38.249511
            ],
            [
              -79.814865,
              38.251568
            ],
            [
              -79.815719,
              38.253645
            ],
            [
              -79.815708,
              38.255065
            ],
            [
              -79.814202,
              38.258174
            ],
            [
              -79.811987,
              38.260401
            ],
            [
              -79.806333,
              38.259193
            ],
            [
              -79.801274,
              38.261474
            ],
            [
              -79.798295,
              38.265957
            ],
            [
              -79.7961001204602,
              38.266413403698
            ],
            [
              -79.790134,
              38.267654
            ],
            [
              -79.788945,
              38.268703
            ],
            [
              -79.787542,
              38.273298
            ],
            [
              -79.789791,
              38.281167
            ],
            [
              -79.795448,
              38.290228
            ],
            [
              -79.797848,
              38.292053
            ],
            [
              -79.802778,
              38.292073
            ],
            [
              -79.803346,
              38.296682
            ],
            [
              -79.804026,
              38.298622
            ],
            [
              -79.807542,
              38.301694
            ],
            [
              -79.810115,
              38.305037
            ],
            [
              -79.810154,
              38.306707
            ],
            [
              -79.808711,
              38.309429
            ],
            [
              -79.804093,
              38.313922
            ],
            [
              -79.799617,
              38.317149
            ],
            [
              -79.798159,
              38.319161
            ],
            [
              -79.79655,
              38.32348
            ],
            [
              -79.785972,
              38.330878
            ],
            [
              -79.779272,
              38.331609
            ],
            [
              -79.77309,
              38.335529
            ],
            [
              -79.769906,
              38.341843
            ],
            [
              -79.766403,
              38.350873
            ],
            [
              -79.767263,
              38.353395
            ],
            [
              -79.764432,
              38.356514
            ],
            [
              -79.757626,
              38.357566
            ],
            [
              -79.75556,
              38.357372
            ],
            [
              -79.744105,
              38.353968
            ],
            [
              -79.740615,
              38.354101
            ],
            [
              -79.7346,
              38.356728
            ],
            [
              -79.732059,
              38.360168
            ],
            [
              -79.729344,
              38.36183
            ],
            [
              -79.727053,
              38.362233
            ],
            [
              -79.725973,
              38.363229
            ],
            [
              -79.725597,
              38.363828
            ],
            [
              -79.725804,
              38.366128
            ],
            [
              -79.72679,
              38.370832
            ],
            [
              -79.727676,
              38.371701
            ],
            [
              -79.730494,
              38.372217
            ],
            [
              -79.731698,
              38.373376
            ],
            [
              -79.729895,
              38.380351
            ],
            [
              -79.72635,
              38.38707
            ],
            [
              -79.722653,
              38.389517
            ],
            [
              -79.717365,
              38.401562
            ],
            [
              -79.712904,
              38.405034
            ],
            [
              -79.708965,
              38.409553
            ],
            [
              -79.70914,
              38.412064
            ],
            [
              -79.706634,
              38.41573
            ],
            [
              -79.689675,
              38.431439
            ],
            [
              -79.689909,
              38.432864
            ],
            [
              -79.69093,
              38.433995
            ],
            [
              -79.691656,
              38.436436
            ],
            [
              -79.691377,
              38.439558
            ],
            [
              -79.689544,
              38.442511
            ],
            [
              -79.691478,
              38.446282
            ],
            [
              -79.688962,
              38.449538
            ],
            [
              -79.688205,
              38.450476
            ],
            [
              -79.688365,
              38.45687
            ],
            [
              -79.688882,
              38.458714
            ],
            [
              -79.691088,
              38.463744
            ],
            [
              -79.695588,
              38.469058
            ],
            [
              -79.698929,
              38.469869
            ],
            [
              -79.699622,
              38.473967
            ],
            [
              -79.699006,
              38.475148
            ],
            [
              -79.695565,
              38.477842
            ],
            [
              -79.69418,
              38.478311
            ],
            [
              -79.693424,
              38.481011
            ],
            [
              -79.695462,
              38.481454
            ],
            [
              -79.696959,
              38.484574
            ],
            [
              -79.697572,
              38.487223
            ],
            [
              -79.694506,
              38.494232
            ],
            [
              -79.692273,
              38.496474
            ],
            [
              -79.691301,
              38.496768
            ],
            [
              -79.688345,
              38.496183
            ],
            [
              -79.682974,
              38.501317
            ],
            [
              -79.681606,
              38.504504
            ],
            [
              -79.681574,
              38.508217
            ],
            [
              -79.680374,
              38.510617
            ],
            [
              -79.674074,
              38.510417
            ],
            [
              -79.670474,
              38.507717
            ],
            [
              -79.669128,
              38.510883
            ],
            [
              -79.669128,
              38.510975
            ],
            [
              -79.668774,
              38.512017
            ],
            [
              -79.667574,
              38.512917
            ],
            [
              -79.665674,
              38.513817
            ],
            [
              -79.663474,
              38.514117
            ],
            [
              -79.662074,
              38.515517
            ],
            [
              -79.662974,
              38.518717
            ],
            [
              -79.666774,
              38.524317
            ],
            [
              -79.669774,
              38.526917
            ],
            [
              -79.671574,
              38.527517
            ],
            [
              -79.672974,
              38.528717
            ],
            [
              -79.668774,
              38.534217
            ],
            [
              -79.666874,
              38.538317
            ],
            [
              -79.669675,
              38.543416
            ],
            [
              -79.669275,
              38.549516
            ],
            [
              -79.665075,
              38.560916
            ],
            [
              -79.662575,
              38.560516
            ],
            [
              -79.659275,
              38.562416
            ],
            [
              -79.660675,
              38.566216
            ],
            [
              -79.661575,
              38.567316
            ],
            [
              -79.659375,
              38.572616
            ],
            [
              -79.658175,
              38.573016
            ],
            [
              -79.656109,
              38.5762
            ],
            [
              -79.649075,
              38.591515
            ],
            [
              -79.619174,
              38.620815
            ],
            [
              -79.633933,
              38.635968
            ],
            [
              -79.617906,
              38.658365
            ],
            [
              -79.626774,
              38.664214
            ],
            [
              -79.629175,
              38.660714
            ],
            [
              -79.633375,
              38.660614
            ],
            [
              -79.635176,
              38.663814
            ],
            [
              -79.634576,
              38.666914
            ],
            [
              -79.629676,
              38.671513
            ],
            [
              -79.627676,
              38.675013
            ],
            [
              -79.628476,
              38.679613
            ],
            [
              -79.632876,
              38.681513
            ],
            [
              -79.641076,
              38.680813
            ],
            [
              -79.648276,
              38.683013
            ],
            [
              -79.65504,
              38.68702
            ],
            [
              -79.656976,
              38.688512
            ],
            [
              -79.661976,
              38.689812
            ],
            [
              -79.664576,
              38.687512
            ],
            [
              -79.671876,
              38.684612
            ],
            [
              -79.678977,
              38.680611
            ],
            [
              -79.678576,
              38.674311
            ],
            [
              -79.679545,
              38.668058
            ],
            [
              -79.681077,
              38.664413
            ],
            [
              -79.685978,
              38.664513
            ],
            [
              -79.692178,
              38.665113
            ],
            [
              -79.697978,
              38.668313
            ],
            [
              -79.699378,
              38.674063
            ],
            [
              -79.702078,
              38.677213
            ],
            [
              -79.703679,
              38.681013
            ],
            [
              -79.710779,
              38.685813
            ],
            [
              -79.720279,
              38.684013
            ],
            [
              -79.724379,
              38.679413
            ],
            [
              -79.728779,
              38.678613
            ],
            [
              -79.73628,
              38.678813
            ],
            [
              -79.74048,
              38.682113
            ],
            [
              -79.74308,
              38.685113
            ],
            [
              -79.74138,
              38.688712
            ],
            [
              -79.73928,
              38.689412
            ],
            [
              -79.738754,
              38.692442
            ],
            [
              -79.739178,
              38.69381
            ],
            [
              -79.73728,
              38.696912
            ],
            [
              -79.73728,
              38.702412
            ],
            [
              -79.73738,
              38.706211
            ],
            [
              -79.73458,
              38.709612
            ],
            [
              -79.73178,
              38.710612
            ],
            [
              -79.732202,
              38.713087
            ],
            [
              -79.733727,
              38.712555
            ],
            [
              -79.73628,
              38.714112
            ],
            [
              -79.740481,
              38.718512
            ],
            [
              -79.746598,
              38.719725
            ],
            [
              -79.75024,
              38.721508
            ],
            [
              -79.752061,
              38.726704
            ],
            [
              -79.754082,
              38.731011
            ],
            [
              -79.758274,
              38.733711
            ],
            [
              -79.764882,
              38.734711
            ],
            [
              -79.769382,
              38.736911
            ],
            [
              -79.773583,
              38.738811
            ],
            [
              -79.778983,
              38.738711
            ],
            [
              -79.781883,
              38.736411
            ],
            [
              -79.782383,
              38.732711
            ],
            [
              -79.785683,
              38.727611
            ],
            [
              -79.789183,
              38.724411
            ],
            [
              -79.793183,
              38.720411
            ],
            [
              -79.793483,
              38.716011
            ],
            [
              -79.799883,
              38.712311
            ],
            [
              -79.802183,
              38.709111
            ],
            [
              -79.801235,
              38.706094
            ],
            [
              -79.799468,
              38.705251
            ],
            [
              -79.799683,
              38.700811
            ],
            [
              -79.802283,
              38.697511
            ],
            [
              -79.807783,
              38.692212
            ],
            [
              -79.807983,
              38.686412
            ],
            [
              -79.809183,
              38.681612
            ],
            [
              -79.813583,
              38.677812
            ],
            [
              -79.814383,
              38.675812
            ],
            [
              -79.814242,
              38.673363
            ],
            [
              -79.816183,
              38.670812
            ],
            [
              -79.817883,
              38.667612
            ],
            [
              -79.819271,
              38.665912
            ],
            [
              -79.820683,
              38.662212
            ],
            [
              -79.821583,
              38.656412
            ],
            [
              -79.829383,
              38.652212
            ],
            [
              -79.833628,
              38.647092
            ],
            [
              -79.832383,
              38.641213
            ],
            [
              -79.829883,
              38.637569
            ],
            [
              -79.834469,
              38.630887
            ],
            [
              -79.838083,
              38.628813
            ],
            [
              -79.841357,
              38.629282
            ],
            [
              -79.843083,
              38.630113
            ],
            [
              -79.84583,
              38.630516
            ],
            [
              -79.846683,
              38.629613
            ],
            [
              -79.846834,
              38.625113
            ],
            [
              -79.846308,
              38.620015
            ],
            [
              -79.843783,
              38.616013
            ],
            [
              -79.842783,
              38.610713
            ],
            [
              -79.844283,
              38.605913
            ],
            [
              -79.848883,
              38.599012
            ],
            [
              -79.850683,
              38.593314
            ],
            [
              -79.855683,
              38.585114
            ],
            [
              -79.855483,
              38.577214
            ],
            [
              -79.857283,
              38.569814
            ],
            [
              -79.859383,
              38.563416
            ],
            [
              -79.861383,
              38.557314
            ],
            [
              -79.863211,
              38.551637
            ],
            [
              -79.895709,
              38.532783
            ],
            [
              -79.928335,
              38.514729
            ],
            [
              -79.979326,
              38.486472
            ],
            [
              -80.029208,
              38.459184
            ],
            [
              -80.07232,
              38.466448
            ],
            [
              -80.115146,
              38.473654
            ],
            [
              -80.145111,
              38.454953
            ],
            [
              -80.177364,
              38.433567
            ],
            [
              -80.202213,
              38.417119
            ],
            [
              -80.236199,
              38.394622
            ],
            [
              -80.245518,
              38.388457
            ],
            [
              -80.330267,
              38.335758
            ],
            [
              -80.352171,
              38.345337
            ],
            [
              -80.360048,
              38.225845
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "047",
      "COUNTYNS": "01689162",
      "AFFGEOID": "0500000US54047",
      "GEOID": "54047",
      "NAME": "McDowell",
      "LSAD": "06",
      "ALAND": 1381646897,
      "AWATER": 3733285,
      "County_state": "McDowell,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.996578,
              37.476705
            ],
            [
              -81.995649,
              37.469833
            ],
            [
              -81.99227,
              37.460916
            ],
            [
              -81.987006,
              37.454878
            ],
            [
              -81.984891,
              37.454315
            ],
            [
              -81.980013,
              37.45721
            ],
            [
              -81.976176,
              37.457186
            ],
            [
              -81.968795,
              37.451496
            ],
            [
              -81.969342,
              37.450324
            ],
            [
              -81.965582,
              37.446918
            ],
            [
              -81.958672,
              37.448045
            ],
            [
              -81.949367,
              37.445687
            ],
            [
              -81.945765,
              37.440214
            ],
            [
              -81.942856,
              37.439929
            ],
            [
              -81.941175,
              37.440485
            ],
            [
              -81.938843,
              37.440463
            ],
            [
              -81.935621,
              37.438397
            ],
            [
              -81.935316,
              37.43639
            ],
            [
              -81.937838,
              37.432111
            ],
            [
              -81.940553,
              37.429058
            ],
            [
              -81.93695,
              37.41992
            ],
            [
              -81.932468,
              37.415217
            ],
            [
              -81.923481,
              37.411379
            ],
            [
              -81.924506,
              37.407613
            ],
            [
              -81.925764,
              37.406874
            ],
            [
              -81.927338,
              37.406844
            ],
            [
              -81.930042,
              37.405291
            ],
            [
              -81.930786,
              37.401656
            ],
            [
              -81.92828,
              37.398059
            ],
            [
              -81.928778,
              37.393845
            ],
            [
              -81.933601,
              37.389217
            ],
            [
              -81.936744,
              37.38073
            ],
            [
              -81.935872,
              37.378554
            ],
            [
              -81.93388,
              37.377796
            ],
            [
              -81.932763,
              37.374229
            ],
            [
              -81.933895,
              37.372747
            ],
            [
              -81.930194,
              37.366728
            ],
            [
              -81.929915,
              37.366589
            ],
            [
              -81.926697,
              37.364618
            ],
            [
              -81.928497,
              37.360645
            ],
            [
              -81.926589,
              37.358942
            ],
            [
              -81.925643,
              37.357316
            ],
            [
              -81.921571,
              37.356423
            ],
            [
              -81.920711,
              37.355416
            ],
            [
              -81.920279,
              37.353402
            ],
            [
              -81.916678,
              37.349346
            ],
            [
              -81.911951,
              37.349339
            ],
            [
              -81.911487,
              37.348839
            ],
            [
              -81.910875,
              37.348729
            ],
            [
              -81.907895,
              37.343783
            ],
            [
              -81.907322,
              37.343119
            ],
            [
              -81.906368,
              37.34276
            ],
            [
              -81.905945,
              37.342775
            ],
            [
              -81.903795,
              37.34305
            ],
            [
              -81.902992,
              37.34234
            ],
            [
              -81.899495,
              37.341102
            ],
            [
              -81.899459,
              37.340277
            ],
            [
              -81.896001,
              37.331967
            ],
            [
              -81.895489,
              37.332022
            ],
            [
              -81.894797,
              37.332012
            ],
            [
              -81.894768,
              37.331381
            ],
            [
              -81.893773,
              37.330105
            ],
            [
              -81.892876,
              37.330134
            ],
            [
              -81.887722,
              37.331156
            ],
            [
              -81.886952,
              37.330725
            ],
            [
              -81.885075,
              37.330665
            ],
            [
              -81.880886,
              37.331146
            ],
            [
              -81.879601,
              37.332074
            ],
            [
              -81.878713,
              37.331753
            ],
            [
              -81.878343,
              37.328837
            ],
            [
              -81.873213,
              37.325065
            ],
            [
              -81.872662,
              37.323314
            ],
            [
              -81.87018,
              37.320667
            ],
            [
              -81.867425,
              37.320838
            ],
            [
              -81.860267,
              37.315715
            ],
            [
              -81.859928,
              37.313965
            ],
            [
              -81.863712,
              37.31223
            ],
            [
              -81.865429,
              37.31012
            ],
            [
              -81.865683,
              37.309484
            ],
            [
              -81.865219,
              37.308839
            ],
            [
              -81.86476,
              37.308404
            ],
            [
              -81.862031,
              37.305648
            ],
            [
              -81.859624,
              37.304765
            ],
            [
              -81.856032,
              37.306742
            ],
            [
              -81.85446,
              37.30657
            ],
            [
              -81.853645,
              37.300779
            ],
            [
              -81.853978,
              37.300418
            ],
            [
              -81.854465,
              37.299937
            ],
            [
              -81.853488,
              37.294763
            ],
            [
              -81.854059,
              37.291352
            ],
            [
              -81.853551,
              37.287701
            ],
            [
              -81.849949,
              37.285227
            ],
            [
              -81.846807,
              37.284649
            ],
            [
              -81.843167,
              37.285586
            ],
            [
              -81.84231,
              37.285556
            ],
            [
              -81.838762,
              37.286343
            ],
            [
              -81.834432,
              37.285416
            ],
            [
              -81.833406,
              37.284535
            ],
            [
              -81.834387,
              37.283086
            ],
            [
              -81.83447,
              37.281763
            ],
            [
              -81.825065,
              37.279536
            ],
            [
              -81.819625,
              37.279411
            ],
            [
              -81.816702,
              37.279865
            ],
            [
              -81.813222,
              37.281091
            ],
            [
              -81.810559,
              37.28298
            ],
            [
              -81.809184,
              37.283003
            ],
            [
              -81.807232,
              37.283175
            ],
            [
              -81.805382,
              37.285622
            ],
            [
              -81.803275,
              37.285916
            ],
            [
              -81.793595,
              37.284838
            ],
            [
              -81.793115,
              37.283538
            ],
            [
              -81.793639,
              37.282188
            ],
            [
              -81.793425,
              37.281674
            ],
            [
              -81.7900754311478,
              37.283897315854
            ],
            [
              -81.789294,
              37.284416
            ],
            [
              -81.783122,
              37.28258
            ],
            [
              -81.779362,
              37.279982
            ],
            [
              -81.77935,
              37.277394
            ],
            [
              -81.777319,
              37.275873
            ],
            [
              -81.774747,
              37.274847
            ],
            [
              -81.774684,
              37.274807
            ],
            [
              -81.767837,
              37.274216
            ],
            [
              -81.765195,
              37.275099
            ],
            [
              -81.763836,
              37.275218
            ],
            [
              -81.762776,
              37.275391
            ],
            [
              -81.761752,
              37.275713
            ],
            [
              -81.76022,
              37.275254
            ],
            [
              -81.757631,
              37.274003
            ],
            [
              -81.75773,
              37.271934
            ],
            [
              -81.757714,
              37.271124
            ],
            [
              -81.757531,
              37.27001
            ],
            [
              -81.755012,
              37.26772
            ],
            [
              -81.752912,
              37.266614
            ],
            [
              -81.752123,
              37.265568
            ],
            [
              -81.75129,
              37.265131
            ],
            [
              -81.747656,
              37.264329
            ],
            [
              -81.746109,
              37.263597
            ],
            [
              -81.745505,
              37.26133
            ],
            [
              -81.745445,
              37.258125
            ],
            [
              -81.743008,
              37.255127
            ],
            [
              -81.741662,
              37.254784
            ],
            [
              -81.740974,
              37.254052
            ],
            [
              -81.743505,
              37.247601
            ],
            [
              -81.74342,
              37.245858
            ],
            [
              -81.744291,
              37.244178
            ],
            [
              -81.744003,
              37.242528
            ],
            [
              -81.7392766866866,
              37.2388369743647
            ],
            [
              -81.738543,
              37.238264
            ],
            [
              -81.73332,
              37.238127
            ],
            [
              -81.728194,
              37.239823
            ],
            [
              -81.726171,
              37.240522
            ],
            [
              -81.723061,
              37.240493
            ],
            [
              -81.719554,
              37.237785
            ],
            [
              -81.716248,
              37.234321
            ],
            [
              -81.71573,
              37.228771
            ],
            [
              -81.698954,
              37.218201
            ],
            [
              -81.695113,
              37.21357
            ],
            [
              -81.686717,
              37.213105
            ],
            [
              -81.683544,
              37.211452
            ],
            [
              -81.684012,
              37.211098
            ],
            [
              -81.683268,
              37.205649
            ],
            [
              -81.681379,
              37.203634
            ],
            [
              -81.678603,
              37.202467
            ],
            [
              -81.67821,
              37.201483
            ],
            [
              -81.560625,
              37.206663
            ],
            [
              -81.558353,
              37.208145
            ],
            [
              -81.557315,
              37.207697
            ],
            [
              -81.556892,
              37.207275
            ],
            [
              -81.556119,
              37.207413
            ],
            [
              -81.5536,
              37.208443
            ],
            [
              -81.549248,
              37.213732
            ],
            [
              -81.545211,
              37.220165
            ],
            [
              -81.544437,
              37.220761
            ],
            [
              -81.53307,
              37.223414
            ],
            [
              -81.527458,
              37.225817
            ],
            [
              -81.520729,
              37.226914
            ],
            [
              -81.508786,
              37.232564
            ],
            [
              -81.507325,
              37.2338
            ],
            [
              -81.50626,
              37.239272
            ],
            [
              -81.506428,
              37.244469
            ],
            [
              -81.50488,
              37.247697
            ],
            [
              -81.504168,
              37.250115
            ],
            [
              -81.50319,
              37.252579
            ],
            [
              -81.498874,
              37.258025
            ],
            [
              -81.497775,
              37.257899
            ],
            [
              -81.497773,
              37.25719
            ],
            [
              -81.498445,
              37.256568
            ],
            [
              -81.498045,
              37.254659
            ],
            [
              -81.495738,
              37.252393
            ],
            [
              -81.492287,
              37.25096
            ],
            [
              -81.483559,
              37.250604
            ],
            [
              -81.480144,
              37.251121
            ],
            [
              -81.476431,
              37.255127
            ],
            [
              -81.462107,
              37.259899
            ],
            [
              -81.46,
              37.262547
            ],
            [
              -81.459874,
              37.263901
            ],
            [
              -81.460585,
              37.265527
            ],
            [
              -81.458895,
              37.266466
            ],
            [
              -81.454199,
              37.266999
            ],
            [
              -81.449068,
              37.269583
            ],
            [
              -81.448285,
              37.270575
            ],
            [
              -81.43285,
              37.272697
            ],
            [
              -81.427946,
              37.271015
            ],
            [
              -81.416663,
              37.273214
            ],
            [
              -81.411593,
              37.28033
            ],
            [
              -81.409577,
              37.284025
            ],
            [
              -81.409729,
              37.284837
            ],
            [
              -81.409196,
              37.286071
            ],
            [
              -81.403764,
              37.296597
            ],
            [
              -81.40506,
              37.298794
            ],
            [
              -81.402195,
              37.30166
            ],
            [
              -81.398185,
              37.302965
            ],
            [
              -81.396817,
              37.304498
            ],
            [
              -81.397357,
              37.306358
            ],
            [
              -81.398702,
              37.307806
            ],
            [
              -81.398548,
              37.310635
            ],
            [
              -81.394287,
              37.316411
            ],
            [
              -81.388132,
              37.319903
            ],
            [
              -81.386727,
              37.320474
            ],
            [
              -81.38581,
              37.320085
            ],
            [
              -81.384914,
              37.318832
            ],
            [
              -81.384127,
              37.318596
            ],
            [
              -81.380159,
              37.317838
            ],
            [
              -81.377349,
              37.318447
            ],
            [
              -81.374455,
              37.318614
            ],
            [
              -81.37261,
              37.320195
            ],
            [
              -81.371315,
              37.324115
            ],
            [
              -81.367599,
              37.327569
            ],
            [
              -81.36803,
              37.329447
            ],
            [
              -81.369264,
              37.330568
            ],
            [
              -81.369379,
              37.331827
            ],
            [
              -81.36809,
              37.332423
            ],
            [
              -81.367052,
              37.334504
            ],
            [
              -81.366315,
              37.335927
            ],
            [
              -81.362156,
              37.337687
            ],
            [
              -81.311201,
              37.424509
            ],
            [
              -81.374499,
              37.443371
            ],
            [
              -81.398268,
              37.464479
            ],
            [
              -81.431805,
              37.448621
            ],
            [
              -81.438099,
              37.45427
            ],
            [
              -81.456056,
              37.469641
            ],
            [
              -81.450215,
              37.476211
            ],
            [
              -81.460623,
              37.483406
            ],
            [
              -81.490278,
              37.48113
            ],
            [
              -81.496757,
              37.473996
            ],
            [
              -81.518076,
              37.478091
            ],
            [
              -81.527615,
              37.475793
            ],
            [
              -81.55345,
              37.491805
            ],
            [
              -81.560432,
              37.489624
            ],
            [
              -81.602034,
              37.493113
            ],
            [
              -81.624575,
              37.518267
            ],
            [
              -81.63393,
              37.510012
            ],
            [
              -81.660431,
              37.521512
            ],
            [
              -81.688332,
              37.514111
            ],
            [
              -81.690365,
              37.503768
            ],
            [
              -81.704032,
              37.500511
            ],
            [
              -81.707833,
              37.495306
            ],
            [
              -81.773268,
              37.505111
            ],
            [
              -81.817937,
              37.50661
            ],
            [
              -81.826437,
              37.535409
            ],
            [
              -81.855939,
              37.54891
            ],
            [
              -81.909374,
              37.527041
            ],
            [
              -81.922848,
              37.511874
            ],
            [
              -81.9277586640962,
              37.5122085217842
            ],
            [
              -81.92787,
              37.512118
            ],
            [
              -81.932279,
              37.511961
            ],
            [
              -81.941151,
              37.509483
            ],
            [
              -81.943045,
              37.508609
            ],
            [
              -81.944188,
              37.506976
            ],
            [
              -81.943912,
              37.502929
            ],
            [
              -81.945957,
              37.501901
            ],
            [
              -81.949188,
              37.502376
            ],
            [
              -81.951831,
              37.50205
            ],
            [
              -81.953147,
              37.501314
            ],
            [
              -81.954077,
              37.499822
            ],
            [
              -81.954364,
              37.496084
            ],
            [
              -81.954167,
              37.495302
            ],
            [
              -81.952735,
              37.494162
            ],
            [
              -81.952681,
              37.492274
            ],
            [
              -81.953264,
              37.491763
            ],
            [
              -81.957213,
              37.491504
            ],
            [
              -81.964986,
              37.493488
            ],
            [
              -81.97073,
              37.489904
            ],
            [
              -81.977593,
              37.484603
            ],
            [
              -81.979169,
              37.484604
            ],
            [
              -81.980327,
              37.485447
            ],
            [
              -81.985703,
              37.485681
            ],
            [
              -81.989849,
              37.484879
            ],
            [
              -81.992916,
              37.482969
            ],
            [
              -81.996578,
              37.476705
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "021",
      "COUNTYNS": "01689673",
      "AFFGEOID": "0500000US54021",
      "GEOID": "54021",
      "NAME": "Gilmer",
      "LSAD": "06",
      "ALAND": 876717634,
      "AWATER": 4082111,
      "County_state": "Gilmer,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.056714,
              38.793322
            ],
            [
              -81.051909,
              38.779996
            ],
            [
              -81.022438,
              38.764809
            ],
            [
              -80.997417,
              38.74736
            ],
            [
              -80.98495,
              38.717898
            ],
            [
              -80.829993,
              38.804324
            ],
            [
              -80.757075,
              38.846822
            ],
            [
              -80.669962,
              38.892688
            ],
            [
              -80.641017,
              38.884815
            ],
            [
              -80.605799,
              38.904167
            ],
            [
              -80.693346,
              38.986289
            ],
            [
              -80.716815,
              39.00843
            ],
            [
              -80.728326,
              39.095679
            ],
            [
              -80.81297,
              39.109401
            ],
            [
              -80.810777,
              39.09947
            ],
            [
              -80.835024,
              39.082376
            ],
            [
              -80.854581,
              39.08392
            ],
            [
              -80.85871,
              39.073957
            ],
            [
              -80.878457,
              39.072895
            ],
            [
              -80.911618,
              39.04624
            ],
            [
              -80.965684,
              39.035303
            ],
            [
              -81.002631,
              39.009585
            ],
            [
              -81.033632,
              39.009584
            ],
            [
              -81.036244,
              38.992976
            ],
            [
              -81.003484,
              38.96099
            ],
            [
              -81.011078,
              38.957451
            ],
            [
              -81.002611,
              38.932385
            ],
            [
              -81.013624,
              38.922898
            ],
            [
              -81.007672,
              38.900335
            ],
            [
              -81.002777,
              38.846025
            ],
            [
              -81.012089,
              38.840694
            ],
            [
              -81.012064,
              38.822142
            ],
            [
              -81.032811,
              38.804153
            ],
            [
              -81.056714,
              38.793322
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "103",
      "COUNTYNS": "01717836",
      "AFFGEOID": "0500000US54103",
      "GEOID": "54103",
      "NAME": "Wetzel",
      "LSAD": "06",
      "ALAND": 927380674,
      "AWATER": 8458366,
      "County_state": "Wetzel,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.936906,
              39.612616
            ],
            [
              -80.943782,
              39.606926
            ],
            [
              -80.886043,
              39.58031
            ],
            [
              -80.852182,
              39.602048
            ],
            [
              -80.846393,
              39.601091
            ],
            [
              -80.843731,
              39.583885
            ],
            [
              -80.827226,
              39.576318
            ],
            [
              -80.823203,
              39.554462
            ],
            [
              -80.809772,
              39.561961
            ],
            [
              -80.782831,
              39.547705
            ],
            [
              -80.784688,
              39.537346
            ],
            [
              -80.740849,
              39.512823
            ],
            [
              -80.73407,
              39.491553
            ],
            [
              -80.722703,
              39.491122
            ],
            [
              -80.710971,
              39.475878
            ],
            [
              -80.685995,
              39.470495
            ],
            [
              -80.670296,
              39.47515
            ],
            [
              -80.671106,
              39.464214
            ],
            [
              -80.652655,
              39.470068
            ],
            [
              -80.618629,
              39.450375
            ],
            [
              -80.597836,
              39.4418
            ],
            [
              -80.583091,
              39.451415
            ],
            [
              -80.544521,
              39.42912
            ],
            [
              -80.518626,
              39.434997
            ],
            [
              -80.512663,
              39.458612
            ],
            [
              -80.494085,
              39.469599
            ],
            [
              -80.489887,
              39.482514
            ],
            [
              -80.471782,
              39.486344
            ],
            [
              -80.467421,
              39.503243
            ],
            [
              -80.480867,
              39.514609
            ],
            [
              -80.498163,
              39.563327
            ],
            [
              -80.480278,
              39.583408
            ],
            [
              -80.478771,
              39.597333
            ],
            [
              -80.451015,
              39.595661
            ],
            [
              -80.449417,
              39.612707
            ],
            [
              -80.433841,
              39.620268
            ],
            [
              -80.443387,
              39.62954
            ],
            [
              -80.432837,
              39.639292
            ],
            [
              -80.39581,
              39.637347
            ],
            [
              -80.395248,
              39.680506
            ],
            [
              -80.407408,
              39.717752
            ],
            [
              -80.421388,
              39.721189
            ],
            [
              -80.519342,
              39.721403
            ],
            [
              -80.83346277006619,
              39.72081235044139
            ],
            [
              -80.831551,
              39.719475
            ],
            [
              -80.829723,
              39.714041
            ],
            [
              -80.829764,
              39.711839
            ],
            [
              -80.831871,
              39.705655
            ],
            [
              -80.833882,
              39.703497
            ],
            [
              -80.839112,
              39.701033
            ],
            [
              -80.844721,
              39.69944
            ],
            [
              -80.852,
              39.69856
            ],
            [
              -80.854599,
              39.697473
            ],
            [
              -80.861718,
              39.693625
            ],
            [
              -80.863698,
              39.691724
            ],
            [
              -80.865805,
              39.686484
            ],
            [
              -80.86633,
              39.683167
            ],
            [
              -80.86667,
              39.678487
            ],
            [
              -80.865575,
              39.662751
            ],
            [
              -80.866647,
              39.652616
            ],
            [
              -80.869802,
              39.646896
            ],
            [
              -80.870771,
              39.642885
            ],
            [
              -80.870473,
              39.641764
            ],
            [
              -80.87102,
              39.638963
            ],
            [
              -80.876002,
              39.627084
            ],
            [
              -80.88036,
              39.620706
            ],
            [
              -80.892208,
              39.616756
            ],
            [
              -80.896514,
              39.616757
            ],
            [
              -80.906247,
              39.618431
            ],
            [
              -80.91762,
              39.618703
            ],
            [
              -80.925841,
              39.617396
            ],
            [
              -80.933292,
              39.614812
            ],
            [
              -80.936906,
              39.612616
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "101",
      "COUNTYNS": "01550057",
      "AFFGEOID": "0500000US54101",
      "GEOID": "54101",
      "NAME": "Webster",
      "LSAD": "06",
      "ALAND": 1433477403,
      "AWATER": 7148637,
      "County_state": "Webster,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.650786,
              38.52659
            ],
            [
              -80.678291,
              38.503492
            ],
            [
              -80.650471,
              38.44532
            ],
            [
              -80.640725,
              38.424173
            ],
            [
              -80.661668,
              38.41251
            ],
            [
              -80.635422,
              38.375827
            ],
            [
              -80.62552,
              38.354392
            ],
            [
              -80.612755,
              38.366983
            ],
            [
              -80.609378,
              38.358606
            ],
            [
              -80.436514,
              38.267334
            ],
            [
              -80.360048,
              38.225845
            ],
            [
              -80.352171,
              38.345337
            ],
            [
              -80.330267,
              38.335758
            ],
            [
              -80.245518,
              38.388457
            ],
            [
              -80.183931,
              38.525386
            ],
            [
              -80.209289,
              38.570171
            ],
            [
              -80.280059,
              38.694867
            ],
            [
              -80.318246,
              38.684296
            ],
            [
              -80.330562,
              38.716962
            ],
            [
              -80.353105,
              38.730565
            ],
            [
              -80.393063,
              38.727571
            ],
            [
              -80.445248,
              38.726848
            ],
            [
              -80.457332,
              38.739169
            ],
            [
              -80.508655,
              38.645553
            ],
            [
              -80.650786,
              38.52659
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "017",
      "COUNTYNS": "01689476",
      "AFFGEOID": "0500000US54017",
      "GEOID": "54017",
      "NAME": "Doddridge",
      "LSAD": "06",
      "ALAND": 828070519,
      "AWATER": 1958441,
      "County_state": "Doddridge,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.888744,
              39.294298
            ],
            [
              -80.91384,
              39.272947
            ],
            [
              -80.81297,
              39.109401
            ],
            [
              -80.728326,
              39.095679
            ],
            [
              -80.701037,
              39.090278
            ],
            [
              -80.68144,
              39.121504
            ],
            [
              -80.656934,
              39.141119
            ],
            [
              -80.62962,
              39.139239
            ],
            [
              -80.596126,
              39.167069
            ],
            [
              -80.591766,
              39.182697
            ],
            [
              -80.575179,
              39.175273
            ],
            [
              -80.528756,
              39.195706
            ],
            [
              -80.525709,
              39.209429
            ],
            [
              -80.565973,
              39.220722
            ],
            [
              -80.547068,
              39.234786
            ],
            [
              -80.58344,
              39.256975
            ],
            [
              -80.577764,
              39.282151
            ],
            [
              -80.604301,
              39.286047
            ],
            [
              -80.581939,
              39.316544
            ],
            [
              -80.564397,
              39.321002
            ],
            [
              -80.557302,
              39.337107
            ],
            [
              -80.531808,
              39.360121
            ],
            [
              -80.544096,
              39.36648
            ],
            [
              -80.541259,
              39.387527
            ],
            [
              -80.547748,
              39.40369
            ],
            [
              -80.539076,
              39.41029
            ],
            [
              -80.544521,
              39.42912
            ],
            [
              -80.583091,
              39.451415
            ],
            [
              -80.597836,
              39.4418
            ],
            [
              -80.618629,
              39.450375
            ],
            [
              -80.648488,
              39.443023
            ],
            [
              -80.658711,
              39.433012
            ],
            [
              -80.713324,
              39.43025
            ],
            [
              -80.73389,
              39.395148
            ],
            [
              -80.829445,
              39.343228
            ],
            [
              -80.888744,
              39.294298
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "083",
      "COUNTYNS": "01550048",
      "AFFGEOID": "0500000US54083",
      "GEOID": "54083",
      "NAME": "Randolph",
      "LSAD": "06",
      "ALAND": 2692810852,
      "AWATER": 667359,
      "County_state": "Randolph,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.236831,
              38.743765
            ],
            [
              -80.280059,
              38.694867
            ],
            [
              -80.209289,
              38.570171
            ],
            [
              -80.183931,
              38.525386
            ],
            [
              -80.245518,
              38.388457
            ],
            [
              -80.236199,
              38.394622
            ],
            [
              -80.202213,
              38.417119
            ],
            [
              -80.177364,
              38.433567
            ],
            [
              -80.145111,
              38.454953
            ],
            [
              -80.115146,
              38.473654
            ],
            [
              -80.07232,
              38.466448
            ],
            [
              -80.029208,
              38.459184
            ],
            [
              -79.979326,
              38.486472
            ],
            [
              -79.928335,
              38.514729
            ],
            [
              -79.895709,
              38.532783
            ],
            [
              -79.863211,
              38.551637
            ],
            [
              -79.861383,
              38.557314
            ],
            [
              -79.859383,
              38.563416
            ],
            [
              -79.857283,
              38.569814
            ],
            [
              -79.855483,
              38.577214
            ],
            [
              -79.855683,
              38.585114
            ],
            [
              -79.850683,
              38.593314
            ],
            [
              -79.848883,
              38.599012
            ],
            [
              -79.844283,
              38.605913
            ],
            [
              -79.842783,
              38.610713
            ],
            [
              -79.843783,
              38.616013
            ],
            [
              -79.846308,
              38.620015
            ],
            [
              -79.846834,
              38.625113
            ],
            [
              -79.846683,
              38.629613
            ],
            [
              -79.84583,
              38.630516
            ],
            [
              -79.843083,
              38.630113
            ],
            [
              -79.841357,
              38.629282
            ],
            [
              -79.838083,
              38.628813
            ],
            [
              -79.834469,
              38.630887
            ],
            [
              -79.829883,
              38.637569
            ],
            [
              -79.832383,
              38.641213
            ],
            [
              -79.833628,
              38.647092
            ],
            [
              -79.829383,
              38.652212
            ],
            [
              -79.821583,
              38.656412
            ],
            [
              -79.820683,
              38.662212
            ],
            [
              -79.819271,
              38.665912
            ],
            [
              -79.817883,
              38.667612
            ],
            [
              -79.816183,
              38.670812
            ],
            [
              -79.814242,
              38.673363
            ],
            [
              -79.814383,
              38.675812
            ],
            [
              -79.813583,
              38.677812
            ],
            [
              -79.809183,
              38.681612
            ],
            [
              -79.807983,
              38.686412
            ],
            [
              -79.807783,
              38.692212
            ],
            [
              -79.802283,
              38.697511
            ],
            [
              -79.799683,
              38.700811
            ],
            [
              -79.799468,
              38.705251
            ],
            [
              -79.801235,
              38.706094
            ],
            [
              -79.802183,
              38.709111
            ],
            [
              -79.799883,
              38.712311
            ],
            [
              -79.793483,
              38.716011
            ],
            [
              -79.793183,
              38.720411
            ],
            [
              -79.789183,
              38.724411
            ],
            [
              -79.785683,
              38.727611
            ],
            [
              -79.782383,
              38.732711
            ],
            [
              -79.781883,
              38.736411
            ],
            [
              -79.778983,
              38.738711
            ],
            [
              -79.773583,
              38.738811
            ],
            [
              -79.769382,
              38.736911
            ],
            [
              -79.764882,
              38.734711
            ],
            [
              -79.758274,
              38.733711
            ],
            [
              -79.754082,
              38.731011
            ],
            [
              -79.752061,
              38.726704
            ],
            [
              -79.75024,
              38.721508
            ],
            [
              -79.746598,
              38.719725
            ],
            [
              -79.740481,
              38.718512
            ],
            [
              -79.73628,
              38.714112
            ],
            [
              -79.733727,
              38.712555
            ],
            [
              -79.732202,
              38.713087
            ],
            [
              -79.73178,
              38.710612
            ],
            [
              -79.73458,
              38.709612
            ],
            [
              -79.73738,
              38.706211
            ],
            [
              -79.73728,
              38.702412
            ],
            [
              -79.73728,
              38.696912
            ],
            [
              -79.739178,
              38.69381
            ],
            [
              -79.738754,
              38.692442
            ],
            [
              -79.73928,
              38.689412
            ],
            [
              -79.74138,
              38.688712
            ],
            [
              -79.74308,
              38.685113
            ],
            [
              -79.74048,
              38.682113
            ],
            [
              -79.73628,
              38.678813
            ],
            [
              -79.728779,
              38.678613
            ],
            [
              -79.724379,
              38.679413
            ],
            [
              -79.720279,
              38.684013
            ],
            [
              -79.710779,
              38.685813
            ],
            [
              -79.703679,
              38.681013
            ],
            [
              -79.702078,
              38.677213
            ],
            [
              -79.699378,
              38.674063
            ],
            [
              -79.697978,
              38.668313
            ],
            [
              -79.692178,
              38.665113
            ],
            [
              -79.685978,
              38.664513
            ],
            [
              -79.681077,
              38.664413
            ],
            [
              -79.679545,
              38.668058
            ],
            [
              -79.678576,
              38.674311
            ],
            [
              -79.678977,
              38.680611
            ],
            [
              -79.671876,
              38.684612
            ],
            [
              -79.664576,
              38.687512
            ],
            [
              -79.661976,
              38.689812
            ],
            [
              -79.656976,
              38.688512
            ],
            [
              -79.65504,
              38.68702
            ],
            [
              -79.648276,
              38.683013
            ],
            [
              -79.641076,
              38.680813
            ],
            [
              -79.632876,
              38.681513
            ],
            [
              -79.628476,
              38.679613
            ],
            [
              -79.627676,
              38.675013
            ],
            [
              -79.629676,
              38.671513
            ],
            [
              -79.634576,
              38.666914
            ],
            [
              -79.635176,
              38.663814
            ],
            [
              -79.633375,
              38.660614
            ],
            [
              -79.629175,
              38.660714
            ],
            [
              -79.626774,
              38.664214
            ],
            [
              -79.623075,
              38.668814
            ],
            [
              -79.595357,
              38.663252
            ],
            [
              -79.597675,
              38.687614
            ],
            [
              -79.560944,
              38.71725
            ],
            [
              -79.555574,
              38.732512
            ],
            [
              -79.535773,
              38.746612
            ],
            [
              -79.526973,
              38.763112
            ],
            [
              -79.510073,
              38.780712
            ],
            [
              -79.507673,
              38.800511
            ],
            [
              -79.527193,
              38.814156
            ],
            [
              -79.523173,
              38.830214
            ],
            [
              -79.539075,
              38.841411
            ],
            [
              -79.515774,
              38.863211
            ],
            [
              -79.510074,
              38.87581
            ],
            [
              -79.519474,
              38.89201
            ],
            [
              -79.482873,
              38.88631
            ],
            [
              -79.444471,
              38.89861
            ],
            [
              -79.455472,
              38.92991
            ],
            [
              -79.443371,
              38.93261
            ],
            [
              -79.41567,
              38.92301
            ],
            [
              -79.374868,
              38.92081
            ],
            [
              -79.367968,
              38.92481
            ],
            [
              -79.349867,
              38.957509
            ],
            [
              -79.357666,
              38.964509
            ],
            [
              -79.408663,
              38.973835
            ],
            [
              -79.42094,
              38.971681
            ],
            [
              -79.494634,
              38.97749
            ],
            [
              -79.503541,
              38.969967
            ],
            [
              -79.508765,
              38.975173
            ],
            [
              -79.642222,
              39.005211
            ],
            [
              -79.785685,
              39.037404
            ],
            [
              -79.776989,
              39.046505
            ],
            [
              -79.775289,
              39.076604
            ],
            [
              -79.799389,
              39.108003
            ],
            [
              -79.825092,
              39.115803
            ],
            [
              -79.836192,
              39.096203
            ],
            [
              -79.849293,
              39.095103
            ],
            [
              -79.863193,
              39.072703
            ],
            [
              -79.872793,
              39.034403
            ],
            [
              -79.887294,
              39.003204
            ],
            [
              -79.896094,
              38.973404
            ],
            [
              -80.085062,
              38.947236
            ],
            [
              -80.097343,
              38.927706
            ],
            [
              -80.089219,
              38.918269
            ],
            [
              -80.109045,
              38.896849
            ],
            [
              -80.098387,
              38.840549
            ],
            [
              -80.126105,
              38.745836
            ],
            [
              -80.142759,
              38.74561
            ],
            [
              -80.236831,
              38.743765
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "025",
      "COUNTYNS": "01557518",
      "AFFGEOID": "0500000US54025",
      "GEOID": "54025",
      "NAME": "Greenbrier",
      "LSAD": "06",
      "ALAND": 2640889025,
      "AWATER": 12578757,
      "County_state": "Greenbrier,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.884852,
              38.082467
            ],
            [
              -80.893881,
              38.079614
            ],
            [
              -80.866168,
              38.058317
            ],
            [
              -80.834607,
              38.05384
            ],
            [
              -80.819458,
              38.064943
            ],
            [
              -80.799972,
              38.063032
            ],
            [
              -80.812476,
              38.047063
            ],
            [
              -80.829615,
              38.043389
            ],
            [
              -80.828253,
              38.031684
            ],
            [
              -80.797396,
              38.025141
            ],
            [
              -80.786245,
              38.003799
            ],
            [
              -80.78611,
              37.989805
            ],
            [
              -80.769866,
              37.98527
            ],
            [
              -80.810866,
              37.949141
            ],
            [
              -80.748757,
              37.865097
            ],
            [
              -80.806316,
              37.868915
            ],
            [
              -80.751409,
              37.835849
            ],
            [
              -80.680592,
              37.773371
            ],
            [
              -80.668228,
              37.762966
            ],
            [
              -80.663348,
              37.731696
            ],
            [
              -80.654813,
              37.727319
            ],
            [
              -80.506695,
              37.691158
            ],
            [
              -80.463308,
              37.693255
            ],
            [
              -80.450651,
              37.683858
            ],
            [
              -80.394562,
              37.716918
            ],
            [
              -80.393354,
              37.70219
            ],
            [
              -80.380764,
              37.707325
            ],
            [
              -80.385295,
              37.692771
            ],
            [
              -80.361694,
              37.689637
            ],
            [
              -80.352741,
              37.707893
            ],
            [
              -80.34021,
              37.704671
            ],
            [
              -80.347712,
              37.693608
            ],
            [
              -80.336316,
              37.687568
            ],
            [
              -80.318308,
              37.685839
            ],
            [
              -80.296138,
              37.691783
            ],
            [
              -80.294108,
              37.693852
            ],
            [
              -80.287107,
              37.696403
            ],
            [
              -80.275007,
              37.707844
            ],
            [
              -80.27199,
              37.711532
            ],
            [
              -80.264406,
              37.718786
            ],
            [
              -80.258143,
              37.720612
            ],
            [
              -80.253077,
              37.725899
            ],
            [
              -80.252227,
              37.727261
            ],
            [
              -80.252024,
              37.729825
            ],
            [
              -80.260313,
              37.733517
            ],
            [
              -80.262765,
              37.738336
            ],
            [
              -80.257411,
              37.756084
            ],
            [
              -80.25641,
              37.756372
            ],
            [
              -80.251622,
              37.755866
            ],
            [
              -80.24979,
              37.757111
            ],
            [
              -80.250427,
              37.761301
            ],
            [
              -80.251319,
              37.762958
            ],
            [
              -80.246902,
              37.768309
            ],
            [
              -80.24139,
              37.769443
            ],
            [
              -80.232011,
              37.775621
            ],
            [
              -80.230458,
              37.778305
            ],
            [
              -80.227498,
              37.778889
            ],
            [
              -80.221827,
              37.778293
            ],
            [
              -80.217634,
              37.776775
            ],
            [
              -80.216899,
              37.776056
            ],
            [
              -80.216498,
              37.776445
            ],
            [
              -80.215658,
              37.777481
            ],
            [
              -80.215892,
              37.781989
            ],
            [
              -80.218616,
              37.783291
            ],
            [
              -80.220092,
              37.78316
            ],
            [
              -80.227965,
              37.791714
            ],
            [
              -80.229489,
              37.792331
            ],
            [
              -80.229228,
              37.79466
            ],
            [
              -80.227092,
              37.798886
            ],
            [
              -80.218611,
              37.809783
            ],
            [
              -80.216939,
              37.809505
            ],
            [
              -80.216229,
              37.80982
            ],
            [
              -80.210965,
              37.812598
            ],
            [
              -80.206482,
              37.81597
            ],
            [
              -80.205841,
              37.818921
            ],
            [
              -80.202853,
              37.82424
            ],
            [
              -80.199633,
              37.827507
            ],
            [
              -80.19465,
              37.831759
            ],
            [
              -80.18638,
              37.837741
            ],
            [
              -80.181768,
              37.838343
            ],
            [
              -80.179391,
              37.839751
            ],
            [
              -80.183555,
              37.84681
            ],
            [
              -80.183062,
              37.850646
            ],
            [
              -80.181815,
              37.852724
            ],
            [
              -80.176712,
              37.854029
            ],
            [
              -80.172076,
              37.860066
            ],
            [
              -80.172033,
              37.862144
            ],
            [
              -80.168957,
              37.867116
            ],
            [
              -80.162202,
              37.875122
            ],
            [
              -80.153832,
              37.881824
            ],
            [
              -80.148951,
              37.886892
            ],
            [
              -80.147316,
              37.885936
            ],
            [
              -80.14613,
              37.884453
            ],
            [
              -80.141947,
              37.882616
            ],
            [
              -80.131931,
              37.8895
            ],
            [
              -80.13104,
              37.890697
            ],
            [
              -80.130464,
              37.893194
            ],
            [
              -80.129555,
              37.894134
            ],
            [
              -80.12362,
              37.897943
            ],
            [
              -80.123021,
              37.898046
            ],
            [
              -80.120613,
              37.896735
            ],
            [
              -80.117747,
              37.89772
            ],
            [
              -80.11748,
              37.900581
            ],
            [
              -80.119106,
              37.902018
            ],
            [
              -80.118967,
              37.903614
            ],
            [
              -80.116884,
              37.906292
            ],
            [
              -80.106819,
              37.914698
            ],
            [
              -80.102931,
              37.918911
            ],
            [
              -80.096563,
              37.918112
            ],
            [
              -80.086954,
              37.929547
            ],
            [
              -80.080823,
              37.935526
            ],
            [
              -80.075441,
              37.939629
            ],
            [
              -80.074514,
              37.942221
            ],
            [
              -80.066569,
              37.947171
            ],
            [
              -80.063682,
              37.947968
            ],
            [
              -80.056839,
              37.951833
            ],
            [
              -80.0563291815345,
              37.95227447323649
            ],
            [
              -80.051043,
              37.956852
            ],
            [
              -80.04841,
              37.957481
            ],
            [
              -80.036236,
              37.96792
            ],
            [
              -80.024168,
              37.976907
            ],
            [
              -80.013145,
              37.984253
            ],
            [
              -80.012555,
              37.985999
            ],
            [
              -80.012891,
              37.987443
            ],
            [
              -80.012193,
              37.988633
            ],
            [
              -80.008888,
              37.99083
            ],
            [
              -80.002507,
              37.992767
            ],
            [
              -79.999384,
              37.995842
            ],
            [
              -79.996134,
              38.000996
            ],
            [
              -79.995398,
              38.003309
            ],
            [
              -79.995901,
              38.005791
            ],
            [
              -79.994985,
              38.007853
            ],
            [
              -79.990114,
              38.013246
            ],
            [
              -79.986142,
              38.014182
            ],
            [
              -79.984842,
              38.01661
            ],
            [
              -79.985792,
              38.018089
            ],
            [
              -79.985619,
              38.01916
            ],
            [
              -79.98029,
              38.027596
            ],
            [
              -79.978427,
              38.029082
            ],
            [
              -79.976732,
              38.029278
            ],
            [
              -79.975269,
              38.030075
            ],
            [
              -79.973701,
              38.032556
            ],
            [
              -79.972165,
              38.036102
            ],
            [
              -79.973777,
              38.038744
            ],
            [
              -79.973895,
              38.040004
            ],
            [
              -79.971231,
              38.044326
            ],
            [
              -79.968189,
              38.047709
            ],
            [
              -79.959844,
              38.063697
            ],
            [
              -80.106891,
              38.055845
            ],
            [
              -80.169169,
              38.036111
            ],
            [
              -80.197761,
              38.042947
            ],
            [
              -80.21594,
              38.041992
            ],
            [
              -80.264653,
              38.046616
            ],
            [
              -80.363295,
              38.114331
            ],
            [
              -80.360048,
              38.225845
            ],
            [
              -80.436514,
              38.267334
            ],
            [
              -80.532658,
              38.198595
            ],
            [
              -80.586191,
              38.162593
            ],
            [
              -80.630361,
              38.132804
            ],
            [
              -80.711961,
              38.079129
            ],
            [
              -80.749813,
              38.086306
            ],
            [
              -80.882187,
              38.103457
            ],
            [
              -80.884852,
              38.082467
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "055",
      "COUNTYNS": "01557645",
      "AFFGEOID": "0500000US54055",
      "GEOID": "54055",
      "NAME": "Mercer",
      "LSAD": "06",
      "ALAND": 1085113561,
      "AWATER": 4434929,
      "County_state": "Mercer,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.362156,
              37.337687
            ],
            [
              -81.320105,
              37.299323
            ],
            [
              -81.2744856162342,
              37.2683746556213
            ],
            [
              -81.225104,
              37.234874
            ],
            [
              -81.204774,
              37.243013
            ],
            [
              -81.178151,
              37.257979
            ],
            [
              -81.167029,
              37.262881
            ],
            [
              -81.158964,
              37.265382
            ],
            [
              -81.142404,
              37.269165
            ],
            [
              -81.112596,
              37.278497
            ],
            [
              -81.09482,
              37.28264
            ],
            [
              -81.084012,
              37.284401
            ],
            [
              -81.037191,
              37.290251
            ],
            [
              -81.021937,
              37.294143
            ],
            [
              -81.008457,
              37.296073
            ],
            [
              -81.000576,
              37.297868
            ],
            [
              -80.996013,
              37.299545
            ],
            [
              -80.979589,
              37.302279
            ],
            [
              -80.979106,
              37.300581
            ],
            [
              -80.982173,
              37.296023
            ],
            [
              -80.981322,
              37.293465
            ],
            [
              -80.980146,
              37.292743
            ],
            [
              -80.973889,
              37.291444
            ],
            [
              -80.966556,
              37.292158
            ],
            [
              -80.947896,
              37.295872
            ],
            [
              -80.938135,
              37.300278
            ],
            [
              -80.931118,
              37.302872
            ],
            [
              -80.92704,
              37.303683
            ],
            [
              -80.919259,
              37.306163
            ],
            [
              -80.900535,
              37.315
            ],
            [
              -80.880103,
              37.328903
            ],
            [
              -80.868986,
              37.338573
            ],
            [
              -80.865321,
              37.340523
            ],
            [
              -80.849451,
              37.346909
            ],
            [
              -80.883248,
              37.383933
            ],
            [
              -80.862761,
              37.411829
            ],
            [
              -80.864455,
              37.41418
            ],
            [
              -80.865174,
              37.416996
            ],
            [
              -80.865148,
              37.419927
            ],
            [
              -80.863142,
              37.424644
            ],
            [
              -80.85956311282,
              37.429558233261005
            ],
            [
              -81.09463,
              37.588658
            ],
            [
              -81.108117,
              37.593917
            ],
            [
              -81.126591,
              37.592116
            ],
            [
              -81.135418,
              37.597916
            ],
            [
              -81.198869,
              37.575533
            ],
            [
              -81.199966,
              37.542374
            ],
            [
              -81.21375,
              37.509401
            ],
            [
              -81.222121,
              37.510416
            ],
            [
              -81.24042,
              37.506416
            ],
            [
              -81.246407,
              37.501941
            ],
            [
              -81.263719,
              37.494939
            ],
            [
              -81.278521,
              37.472177
            ],
            [
              -81.307387,
              37.459486
            ],
            [
              -81.282599,
              37.419797
            ],
            [
              -81.292919,
              37.413178
            ],
            [
              -81.311201,
              37.424509
            ],
            [
              -81.362156,
              37.337687
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "051",
      "COUNTYNS": "01717539",
      "AFFGEOID": "0500000US54051",
      "GEOID": "54051",
      "NAME": "Marshall",
      "LSAD": "06",
      "ALAND": 791063503,
      "AWATER": 17411500,
      "County_state": "Marshall,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.869092,
              39.766364
            ],
            [
              -80.869933,
              39.763555
            ],
            [
              -80.867596,
              39.757116
            ],
            [
              -80.865339,
              39.753251
            ],
            [
              -80.854717,
              39.742592
            ],
            [
              -80.852738,
              39.74104
            ],
            [
              -80.846091,
              39.737812
            ],
            [
              -80.836597,
              39.723925
            ],
            [
              -80.834563,
              39.721582
            ],
            [
              -80.83346277006619,
              39.72081235044139
            ],
            [
              -80.519342,
              39.721403
            ],
            [
              -80.5193982276866,
              39.780253256914
            ],
            [
              -80.519423,
              39.806181
            ],
            [
              -80.518891,
              39.890964
            ],
            [
              -80.519248,
              39.936967
            ],
            [
              -80.519115,
              39.939188
            ],
            [
              -80.519175,
              39.956648
            ],
            [
              -80.519203,
              39.959394
            ],
            [
              -80.5192168880039,
              39.9621993767921
            ],
            [
              -80.519218,
              39.962424
            ],
            [
              -80.519207,
              39.963381
            ],
            [
              -80.51912,
              40.01641
            ],
            [
              -80.651957,
              40.027342
            ],
            [
              -80.669253,
              40.028626
            ],
            [
              -80.694346,
              40.030569
            ],
            [
              -80.7332673845788,
              40.03335743598289
            ],
            [
              -80.733304,
              40.033272
            ],
            [
              -80.7363,
              40.029929
            ],
            [
              -80.737389,
              40.027593
            ],
            [
              -80.737341,
              40.022969
            ],
            [
              -80.737805,
              40.020761
            ],
            [
              -80.740509,
              40.015225
            ],
            [
              -80.741901,
              40.007929
            ],
            [
              -80.742045,
              40.005641
            ],
            [
              -80.741085,
              39.996857
            ],
            [
              -80.740045,
              39.993929
            ],
            [
              -80.738717,
              39.985113
            ],
            [
              -80.740126,
              39.970793
            ],
            [
              -80.743166,
              39.969113
            ],
            [
              -80.74627,
              39.966233
            ],
            [
              -80.755135,
              39.961561
            ],
            [
              -80.758527,
              39.959241
            ],
            [
              -80.763375,
              39.953514
            ],
            [
              -80.764479,
              39.95025
            ],
            [
              -80.764511,
              39.946602
            ],
            [
              -80.761312,
              39.929098
            ],
            [
              -80.756784,
              39.920586
            ],
            [
              -80.755936,
              39.916554
            ],
            [
              -80.756432,
              39.91393
            ],
            [
              -80.758304,
              39.910426
            ],
            [
              -80.759296,
              39.909466
            ],
            [
              -80.760656,
              39.908906
            ],
            [
              -80.762592,
              39.908906
            ],
            [
              -80.768272,
              39.909642
            ],
            [
              -80.772641,
              39.911306
            ],
            [
              -80.782849,
              39.917162
            ],
            [
              -80.795714,
              39.91969
            ],
            [
              -80.80053,
              39.919434
            ],
            [
              -80.803394,
              39.918762
            ],
            [
              -80.806018,
              39.91713
            ],
            [
              -80.807618,
              39.914938
            ],
            [
              -80.809283,
              39.910314
            ],
            [
              -80.809683,
              39.906106
            ],
            [
              -80.809011,
              39.903226
            ],
            [
              -80.806179,
              39.897306
            ],
            [
              -80.802339,
              39.89161
            ],
            [
              -80.796162,
              39.88553
            ],
            [
              -80.793989,
              39.882787
            ],
            [
              -80.790156,
              39.872252
            ],
            [
              -80.790761,
              39.86728
            ],
            [
              -80.793131,
              39.863751
            ],
            [
              -80.799898,
              39.858912
            ],
            [
              -80.81643,
              39.853032
            ],
            [
              -80.821279,
              39.849982
            ],
            [
              -80.824276,
              39.847159
            ],
            [
              -80.826124,
              39.844238
            ],
            [
              -80.826964,
              39.841656
            ],
            [
              -80.826228,
              39.835802
            ],
            [
              -80.82303,
              39.827484
            ],
            [
              -80.82248,
              39.824971
            ],
            [
              -80.822181,
              39.811708
            ],
            [
              -80.824969,
              39.801092
            ],
            [
              -80.826079,
              39.798584
            ],
            [
              -80.835311,
              39.79069
            ],
            [
              -80.863048,
              39.775197
            ],
            [
              -80.866329,
              39.771738
            ],
            [
              -80.869092,
              39.766364
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "001",
      "COUNTYNS": "01696996",
      "AFFGEOID": "0500000US54001",
      "GEOID": "54001",
      "NAME": "Barbour",
      "LSAD": "06",
      "ALAND": 883338745,
      "AWATER": 4639232,
      "County_state": "Barbour,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.224687,
              39.170977
            ],
            [
              -80.227173,
              39.112792
            ],
            [
              -80.209469,
              39.097357
            ],
            [
              -80.199636,
              39.098558
            ],
            [
              -80.167831,
              39.087583
            ],
            [
              -80.15177,
              39.071621
            ],
            [
              -80.128198,
              39.037795
            ],
            [
              -80.05582,
              39.03716
            ],
            [
              -80.059128,
              39.022028
            ],
            [
              -80.044431,
              39.011388
            ],
            [
              -80.054939,
              39.005345
            ],
            [
              -80.052651,
              38.975163
            ],
            [
              -80.073346,
              38.964957
            ],
            [
              -80.063824,
              38.961711
            ],
            [
              -80.085062,
              38.947236
            ],
            [
              -79.896094,
              38.973404
            ],
            [
              -79.887294,
              39.003204
            ],
            [
              -79.872793,
              39.034403
            ],
            [
              -79.863193,
              39.072703
            ],
            [
              -79.849293,
              39.095103
            ],
            [
              -79.836192,
              39.096203
            ],
            [
              -79.825092,
              39.115803
            ],
            [
              -79.838093,
              39.131002
            ],
            [
              -79.827491,
              39.160402
            ],
            [
              -79.826693,
              39.194101
            ],
            [
              -79.809729,
              39.230526
            ],
            [
              -79.818882,
              39.240479
            ],
            [
              -79.850281,
              39.246532
            ],
            [
              -79.861731,
              39.285099
            ],
            [
              -79.888662,
              39.302852
            ],
            [
              -79.895536,
              39.299584
            ],
            [
              -79.926061,
              39.288738
            ],
            [
              -79.937032,
              39.297327
            ],
            [
              -79.961099,
              39.268796
            ],
            [
              -80.023411,
              39.247892
            ],
            [
              -80.150041,
              39.23825
            ],
            [
              -80.166387,
              39.242283
            ],
            [
              -80.224687,
              39.170977
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "077",
      "COUNTYNS": "01558642",
      "AFFGEOID": "0500000US54077",
      "GEOID": "54077",
      "NAME": "Preston",
      "LSAD": "06",
      "ALAND": 1680406633,
      "AWATER": 6681831,
      "County_state": "Preston,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.89974,
              39.481945
            ],
            [
              -79.894688,
              39.435588
            ],
            [
              -79.895536,
              39.299584
            ],
            [
              -79.888662,
              39.302852
            ],
            [
              -79.861731,
              39.285099
            ],
            [
              -79.850281,
              39.246532
            ],
            [
              -79.818882,
              39.240479
            ],
            [
              -79.809729,
              39.230526
            ],
            [
              -79.692399,
              39.269667
            ],
            [
              -79.68387,
              39.270358
            ],
            [
              -79.507576,
              39.216206
            ],
            [
              -79.487175,
              39.194906
            ],
            [
              -79.486862069471,
              39.20595874752019
            ],
            [
              -79.486873,
              39.205961
            ],
            [
              -79.485874,
              39.264905
            ],
            [
              -79.486179,
              39.26497
            ],
            [
              -79.487274,
              39.265205
            ],
            [
              -79.486737,
              39.278149
            ],
            [
              -79.487651,
              39.279933
            ],
            [
              -79.486812,
              39.296367
            ],
            [
              -79.486072,
              39.3443
            ],
            [
              -79.484372,
              39.3443
            ],
            [
              -79.482648,
              39.521364
            ],
            [
              -79.482354,
              39.524682
            ],
            [
              -79.482366,
              39.531689
            ],
            [
              -79.478866,
              39.531689
            ],
            [
              -79.477888644888,
              39.6297730598018
            ],
            [
              -79.477764,
              39.642282
            ],
            [
              -79.476968,
              39.642986
            ],
            [
              -79.476574,
              39.644206
            ],
            [
              -79.476662,
              39.721078
            ],
            [
              -79.548465,
              39.720778
            ],
            [
              -79.608223,
              39.721154
            ],
            [
              -79.610623,
              39.721245
            ],
            [
              -79.763774,
              39.720776
            ],
            [
              -79.763773,
              39.696867
            ],
            [
              -79.78537,
              39.657287
            ],
            [
              -79.789277,
              39.623781
            ],
            [
              -79.812106,
              39.59435
            ],
            [
              -79.849689,
              39.545998
            ],
            [
              -79.881707,
              39.504811
            ],
            [
              -79.89974,
              39.481945
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "089",
      "COUNTYNS": "01557805",
      "AFFGEOID": "0500000US54089",
      "GEOID": "54089",
      "NAME": "Summers",
      "LSAD": "06",
      "ALAND": 933901529,
      "AWATER": 18506801,
      "County_state": "Summers,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.064268,
              37.611057
            ],
            [
              -81.09463,
              37.588658
            ],
            [
              -80.85956311282,
              37.429558233261005
            ],
            [
              -80.859556,
              37.429568
            ],
            [
              -80.85847336829929,
              37.4283007053671
            ],
            [
              -80.798451,
              37.522103
            ],
            [
              -80.748147,
              37.587601
            ],
            [
              -80.701137,
              37.655039
            ],
            [
              -80.69722,
              37.660484
            ],
            [
              -80.660446,
              37.714563
            ],
            [
              -80.663348,
              37.731696
            ],
            [
              -80.668228,
              37.762966
            ],
            [
              -80.680592,
              37.773371
            ],
            [
              -80.751409,
              37.835849
            ],
            [
              -80.806316,
              37.868915
            ],
            [
              -80.94509,
              37.819121
            ],
            [
              -80.926321,
              37.80973
            ],
            [
              -80.921435,
              37.793408
            ],
            [
              -80.902947,
              37.782806
            ],
            [
              -80.894312,
              37.766775
            ],
            [
              -80.901921,
              37.756738
            ],
            [
              -80.91798,
              37.751939
            ],
            [
              -80.923334,
              37.742196
            ],
            [
              -80.898717,
              37.729312
            ],
            [
              -80.88997,
              37.701512
            ],
            [
              -80.877935,
              37.692109
            ],
            [
              -80.879542,
              37.682665
            ],
            [
              -80.900267,
              37.676282
            ],
            [
              -80.897588,
              37.689509
            ],
            [
              -80.914784,
              37.703205
            ],
            [
              -80.947017,
              37.715449
            ],
            [
              -80.977099,
              37.71452
            ],
            [
              -80.972629,
              37.69902
            ],
            [
              -80.988944,
              37.685794
            ],
            [
              -81.021701,
              37.669609
            ],
            [
              -81.027558,
              37.65856
            ],
            [
              -81.065522,
              37.641811
            ],
            [
              -81.064268,
              37.611057
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "009",
      "COUNTYNS": "01558262",
      "AFFGEOID": "0500000US54009",
      "GEOID": "54009",
      "NAME": "Brooke",
      "LSAD": "06",
      "ALAND": 231034166,
      "AWATER": 8714144,
      "County_state": "Brooke,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.6796,
              40.186371
            ],
            [
              -80.68181217781239,
              40.185566237639705
            ],
            [
              -80.663734,
              40.185897
            ],
            [
              -80.663905,
              40.182663
            ],
            [
              -80.662238,
              40.182323
            ],
            [
              -80.663447,
              40.179448
            ],
            [
              -80.664006,
              40.178947
            ],
            [
              -80.625066,
              40.177228
            ],
            [
              -80.595403,
              40.177017
            ],
            [
              -80.55336,
              40.167444
            ],
            [
              -80.519104,
              40.159672
            ],
            [
              -80.519056,
              40.172744
            ],
            [
              -80.519056,
              40.172771
            ],
            [
              -80.51904013767269,
              40.3307691106087
            ],
            [
              -80.519039,
              40.342101
            ],
            [
              -80.517991,
              40.367968
            ],
            [
              -80.517991,
              40.398868
            ],
            [
              -80.5179873274459,
              40.3996439826186
            ],
            [
              -80.573784,
              40.399755
            ],
            [
              -80.6296993745958,
              40.395006652655894
            ],
            [
              -80.632196,
              40.393667
            ],
            [
              -80.633596,
              40.390467
            ],
            [
              -80.631596,
              40.385468
            ],
            [
              -80.63074,
              40.3849
            ],
            [
              -80.619196,
              40.381768
            ],
            [
              -80.614396,
              40.378768
            ],
            [
              -80.609695,
              40.374968
            ],
            [
              -80.607595,
              40.369568
            ],
            [
              -80.608695,
              40.361968
            ],
            [
              -80.611796,
              40.355168
            ],
            [
              -80.612796,
              40.347668
            ],
            [
              -80.6127655700637,
              40.347564538216595
            ],
            [
              -80.610796,
              40.340868
            ],
            [
              -80.602895,
              40.327869
            ],
            [
              -80.600495,
              40.321169
            ],
            [
              -80.599895,
              40.317669
            ],
            [
              -80.602895,
              40.307069
            ],
            [
              -80.606596,
              40.303869
            ],
            [
              -80.614896,
              40.291969
            ],
            [
              -80.616796,
              40.285269
            ],
            [
              -80.616196,
              40.27227
            ],
            [
              -80.619297,
              40.26517
            ],
            [
              -80.622497,
              40.26177
            ],
            [
              -80.637098,
              40.25427
            ],
            [
              -80.637198,
              40.25507
            ],
            [
              -80.644598,
              40.25127
            ],
            [
              -80.652098,
              40.24497
            ],
            [
              -80.661543,
              40.229798
            ],
            [
              -80.664299,
              40.21917
            ],
            [
              -80.666299,
              40.206271
            ],
            [
              -80.6681,
              40.199671
            ],
            [
              -80.6726,
              40.192371
            ],
            [
              -80.6796,
              40.186371
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "057",
      "COUNTYNS": "01717890",
      "AFFGEOID": "0500000US54057",
      "GEOID": "54057",
      "NAME": "Mineral",
      "LSAD": "06",
      "ALAND": 849173054,
      "AWATER": 3502975,
      "County_state": "Mineral,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.269365,
              39.330732
            ],
            [
              -79.2722802951283,
              39.3289642342356
            ],
            [
              -79.237927,
              39.322104
            ],
            [
              -79.134456,
              39.312501
            ],
            [
              -78.979898,
              39.237624
            ],
            [
              -78.959223,
              39.260648
            ],
            [
              -78.926616,
              39.278869
            ],
            [
              -78.922345,
              39.292926
            ],
            [
              -78.883182,
              39.328114
            ],
            [
              -78.852098,
              39.330181
            ],
            [
              -78.810684,
              39.376623
            ],
            [
              -78.791405,
              39.412585
            ],
            [
              -78.789242,
              39.424778
            ],
            [
              -78.767447,
              39.447238
            ],
            [
              -78.729843,
              39.463205
            ],
            [
              -78.703567,
              39.497536
            ],
            [
              -78.6574165631331,
              39.5350677240827
            ],
            [
              -78.66399,
              39.536778
            ],
            [
              -78.668745,
              39.540164
            ],
            [
              -78.675629,
              39.540371
            ],
            [
              -78.682423,
              39.543848
            ],
            [
              -78.689455,
              39.54577
            ],
            [
              -78.691494,
              39.547646
            ],
            [
              -78.691996,
              39.55078
            ],
            [
              -78.692824,
              39.55197
            ],
            [
              -78.694626,
              39.553251
            ],
            [
              -78.707098,
              39.555857
            ],
            [
              -78.708664,
              39.556795
            ],
            [
              -78.713335,
              39.562032
            ],
            [
              -78.714784,
              39.562558
            ],
            [
              -78.72459061271309,
              39.5639149682172
            ],
            [
              -78.72501,
              39.563973
            ],
            [
              -78.726342,
              39.567587
            ],
            [
              -78.731992,
              39.575364
            ],
            [
              -78.733149,
              39.58369
            ],
            [
              -78.733979,
              39.586618
            ],
            [
              -78.738502,
              39.586319
            ],
            [
              -78.740246,
              39.585655
            ],
            [
              -78.743318,
              39.580712
            ],
            [
              -78.746421,
              39.579544
            ],
            [
              -78.756747,
              39.58069
            ],
            [
              -78.760196,
              39.582154
            ],
            [
              -78.76749,
              39.587487
            ],
            [
              -78.768314,
              39.589394
            ],
            [
              -78.768481,
              39.591583
            ],
            [
              -78.770511,
              39.594994
            ],
            [
              -78.772128,
              39.596497
            ],
            [
              -78.774281,
              39.597328
            ],
            [
              -78.778141,
              39.601364
            ],
            [
              -78.778096,
              39.602097
            ],
            [
              -78.77686,
              39.604027
            ],
            [
              -78.768115,
              39.608704
            ],
            [
              -78.760497,
              39.609984
            ],
            [
              -78.751514,
              39.609947
            ],
            [
              -78.74935,
              39.608572
            ],
            [
              -78.749222,
              39.606536
            ],
            [
              -78.747063,
              39.60569
            ],
            [
              -78.73905,
              39.609697
            ],
            [
              -78.733759,
              39.613931
            ],
            [
              -78.733553,
              39.615533
            ],
            [
              -78.736189,
              39.621708
            ],
            [
              -78.74288,
              39.625088
            ],
            [
              -78.748499,
              39.626262
            ],
            [
              -78.756686,
              39.622971
            ],
            [
              -78.763171,
              39.618897
            ],
            [
              -78.769565,
              39.619431
            ],
            [
              -78.777516,
              39.621712
            ],
            [
              -78.778477,
              39.62265
            ],
            [
              -78.778477,
              39.624405
            ],
            [
              -78.77264,
              39.636887
            ],
            [
              -78.77114,
              39.638387
            ],
            [
              -78.76814,
              39.639287
            ],
            [
              -78.76534,
              39.643987
            ],
            [
              -78.76504,
              39.646087
            ],
            [
              -78.76584,
              39.648487
            ],
            [
              -78.775241,
              39.645687
            ],
            [
              -78.790941,
              39.638287
            ],
            [
              -78.794597107557,
              39.6375557784886
            ],
            [
              -78.795941,
              39.637287
            ],
            [
              -78.801741,
              39.627488
            ],
            [
              -78.795964,
              39.614205
            ],
            [
              -78.795368,
              39.61071
            ],
            [
              -78.795857,
              39.606934
            ],
            [
              -78.79784,
              39.604897
            ],
            [
              -78.800434,
              39.605232
            ],
            [
              -78.801792,
              39.606812
            ],
            [
              -78.809347,
              39.608063
            ],
            [
              -78.812154,
              39.60054
            ],
            [
              -78.812215,
              39.597717
            ],
            [
              -78.818899,
              39.59037
            ],
            [
              -78.824788,
              39.590233
            ],
            [
              -78.826009,
              39.588829
            ],
            [
              -78.82636,
              39.577333
            ],
            [
              -78.820104,
              39.576287
            ],
            [
              -78.815114,
              39.571351
            ],
            [
              -78.813512,
              39.56772
            ],
            [
              -78.816764,
              39.561691
            ],
            [
              -78.821404,
              39.560616
            ],
            [
              -78.826407,
              39.562589
            ],
            [
              -78.830298,
              39.565355
            ],
            [
              -78.838553,
              39.5673
            ],
            [
              -78.840055958723,
              39.5664231651079
            ],
            [
              -78.851196,
              39.559924
            ],
            [
              -78.851016,
              39.554044
            ],
            [
              -78.851931,
              39.551848
            ],
            [
              -78.868908,
              39.532487
            ],
            [
              -78.868966,
              39.531366
            ],
            [
              -78.874744,
              39.522611
            ],
            [
              -78.87681,
              39.52125
            ],
            [
              -78.879084,
              39.521205
            ],
            [
              -78.885996,
              39.522581
            ],
            [
              -78.891197,
              39.5189
            ],
            [
              -78.895307,
              39.512085
            ],
            [
              -78.908719,
              39.496699
            ],
            [
              -78.916488,
              39.486544
            ],
            [
              -78.918142,
              39.485858
            ],
            [
              -78.926999,
              39.487003
            ],
            [
              -78.933613,
              39.48618
            ],
            [
              -78.938751,
              39.483732
            ],
            [
              -78.942293,
              39.480987
            ],
            [
              -78.942618,
              39.479614
            ],
            [
              -78.941526,
              39.476869
            ],
            [
              -78.939164,
              39.475267
            ],
            [
              -78.938869,
              39.4741
            ],
            [
              -78.941969,
              39.469959
            ],
            [
              -78.946603,
              39.46614
            ],
            [
              -78.953333,
              39.463645
            ],
            [
              -78.955483,
              39.442277
            ],
            [
              -78.956751,
              39.440264
            ],
            [
              -78.9579587921713,
              39.4400138115152
            ],
            [
              -78.965484,
              39.438455
            ],
            [
              -78.967461,
              39.439804
            ],
            [
              -78.970118,
              39.443327
            ],
            [
              -78.978826,
              39.448678
            ],
            [
              -78.99695,
              39.454961
            ],
            [
              -79.010097,
              39.461048
            ],
            [
              -79.017147,
              39.466977
            ],
            [
              -79.020542,
              39.467002
            ],
            [
              -79.028159,
              39.46506
            ],
            [
              -79.030343,
              39.465403
            ],
            [
              -79.033884,
              39.467761
            ],
            [
              -79.035712,
              39.471331
            ],
            [
              -79.035623,
              39.473344
            ],
            [
              -79.036915,
              39.476795
            ],
            [
              -79.046276,
              39.483801
            ],
            [
              -79.050528,
              39.483299
            ],
            [
              -79.052447,
              39.482315
            ],
            [
              -79.05388,
              39.480094
            ],
            [
              -79.0544297162916,
              39.4766251861057
            ],
            [
              -79.054989,
              39.473096
            ],
            [
              -79.056583,
              39.471014
            ],
            [
              -79.068627,
              39.474515
            ],
            [
              -79.08327,
              39.471379
            ],
            [
              -79.091329,
              39.472407
            ],
            [
              -79.094702,
              39.473253
            ],
            [
              -79.096517,
              39.472799
            ],
            [
              -79.098059,
              39.472073
            ],
            [
              -79.098875,
              39.471438
            ],
            [
              -79.099057,
              39.470259
            ],
            [
              -79.09824,
              39.468445
            ],
            [
              -79.096154,
              39.465542
            ],
            [
              -79.095428,
              39.462548
            ],
            [
              -79.104217,
              39.448358
            ],
            [
              -79.107933,
              39.445748
            ],
            [
              -79.11407,
              39.443321
            ],
            [
              -79.116369,
              39.440482
            ],
            [
              -79.116574,
              39.438058
            ],
            [
              -79.116932,
              39.435788
            ],
            [
              -79.117932,
              39.434412
            ],
            [
              -79.119433,
              39.433161
            ],
            [
              -79.12156,
              39.432786
            ],
            [
              -79.124036,
              39.433204
            ],
            [
              -79.129047,
              39.429542
            ],
            [
              -79.129404,
              39.426637
            ],
            [
              -79.128941,
              39.423279
            ],
            [
              -79.129816,
              39.419901
            ],
            [
              -79.132193,
              39.418275
            ],
            [
              -79.136696,
              39.417649
            ],
            [
              -79.140699,
              39.416649
            ],
            [
              -79.14195,
              39.414272
            ],
            [
              -79.142701,
              39.410519
            ],
            [
              -79.143827,
              39.408517
            ],
            [
              -79.145453,
              39.407767
            ],
            [
              -79.147455,
              39.407767
            ],
            [
              -79.149581,
              39.407767
            ],
            [
              -79.151583,
              39.408768
            ],
            [
              -79.153584,
              39.41202
            ],
            [
              -79.157212,
              39.413021
            ],
            [
              -79.159213,
              39.413021
            ],
            [
              -79.16134,
              39.411895
            ],
            [
              -79.166497,
              39.400888
            ],
            [
              -79.165593,
              39.397134
            ],
            [
              -79.16722,
              39.393256
            ],
            [
              -79.170494,
              39.392026
            ],
            [
              -79.1746,
              39.392756
            ],
            [
              -79.176977,
              39.39213
            ],
            [
              -79.179335,
              39.388342
            ],
            [
              -79.189465,
              39.3865
            ],
            [
              -79.193332,
              39.387974
            ],
            [
              -79.195543,
              39.38779
            ],
            [
              -79.197937,
              39.386132
            ],
            [
              -79.202943,
              39.377872
            ],
            [
              -79.213961,
              39.36532
            ],
            [
              -79.220357,
              39.363157
            ],
            [
              -79.229247,
              39.363662
            ],
            [
              -79.235878,
              39.358689
            ],
            [
              -79.25227,
              39.356663
            ],
            [
              -79.253928,
              39.354085
            ],
            [
              -79.253891,
              39.337222
            ],
            [
              -79.255306,
              39.335874
            ],
            [
              -79.269365,
              39.330732
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "011",
      "COUNTYNS": "01550012",
      "AFFGEOID": "0500000US54011",
      "GEOID": "54011",
      "NAME": "Cabell",
      "LSAD": "06",
      "ALAND": 727837364,
      "AWATER": 18122707,
      "County_state": "Cabell,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.50767557967869,
              38.4107828373227
            ],
            [
              -82.490386,
              38.399415
            ],
            [
              -82.454518,
              38.376216
            ],
            [
              -82.3465,
              38.306426
            ],
            [
              -82.342714,
              38.306971
            ],
            [
              -82.32845,
              38.314874
            ],
            [
              -82.330482,
              38.323769
            ],
            [
              -82.314411,
              38.331364
            ],
            [
              -82.298392,
              38.328262
            ],
            [
              -82.276212,
              38.303868
            ],
            [
              -82.290372,
              38.304088
            ],
            [
              -82.305608,
              38.295229
            ],
            [
              -82.302576,
              38.287714
            ],
            [
              -82.31328,
              38.268383
            ],
            [
              -82.29952,
              38.240304
            ],
            [
              -82.275784,
              38.228262
            ],
            [
              -82.264849,
              38.229199
            ],
            [
              -82.241316,
              38.27129
            ],
            [
              -82.207585,
              38.288697
            ],
            [
              -82.216797,
              38.309361
            ],
            [
              -82.220603,
              38.310791
            ],
            [
              -82.197506,
              38.310692
            ],
            [
              -82.17679,
              38.3396
            ],
            [
              -82.164651,
              38.336506
            ],
            [
              -82.117636,
              38.344194
            ],
            [
              -82.097205,
              38.367479
            ],
            [
              -82.064735,
              38.376765
            ],
            [
              -82.047128,
              38.374432
            ],
            [
              -82.05201,
              38.426513
            ],
            [
              -82.055127,
              38.474547
            ],
            [
              -82.218967,
              38.591683
            ],
            [
              -82.222168,
              38.591384
            ],
            [
              -82.245969,
              38.598483
            ],
            [
              -82.252469,
              38.598783
            ],
            [
              -82.26207,
              38.598183
            ],
            [
              -82.27147,
              38.595383
            ],
            [
              -82.27427,
              38.593683
            ],
            [
              -82.2871015269719,
              38.5825881557857
            ],
            [
              -82.291271,
              38.578983
            ],
            [
              -82.293471,
              38.575383
            ],
            [
              -82.293871,
              38.572683
            ],
            [
              -82.293271,
              38.560283
            ],
            [
              -82.295671,
              38.538483
            ],
            [
              -82.297771,
              38.533283
            ],
            [
              -82.300271,
              38.529383
            ],
            [
              -82.302871,
              38.523183
            ],
            [
              -82.303971,
              38.517683
            ],
            [
              -82.303071,
              38.504384
            ],
            [
              -82.304223,
              38.496308
            ],
            [
              -82.306351,
              38.490692
            ],
            [
              -82.310639,
              38.483172
            ],
            [
              -82.312511,
              38.476116
            ],
            [
              -82.313935,
              38.468084
            ],
            [
              -82.318111,
              38.457876
            ],
            [
              -82.323999,
              38.449268
            ],
            [
              -82.330335,
              38.4445
            ],
            [
              -82.34064,
              38.440948
            ],
            [
              -82.360145,
              38.438596
            ],
            [
              -82.381773,
              38.434783
            ],
            [
              -82.389746,
              38.434355
            ],
            [
              -82.404882,
              38.439347
            ],
            [
              -82.434375,
              38.430082
            ],
            [
              -82.43601878360201,
              38.4296807930741
            ],
            [
              -82.447076,
              38.426982
            ],
            [
              -82.459676,
              38.424682
            ],
            [
              -82.486577,
              38.418082
            ],
            [
              -82.50767557967869,
              38.4107828373227
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "015",
      "COUNTYNS": "01550014",
      "AFFGEOID": "0500000US54015",
      "GEOID": "54015",
      "NAME": "Clay",
      "LSAD": "06",
      "ALAND": 885617251,
      "AWATER": 4862445,
      "County_state": "Clay,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.282117,
              38.473173
            ],
            [
              -81.282929,
              38.468616
            ],
            [
              -81.25418,
              38.372567
            ],
            [
              -81.241745,
              38.314057
            ],
            [
              -81.231434,
              38.263716
            ],
            [
              -81.098788,
              38.326032
            ],
            [
              -81.04201,
              38.352712
            ],
            [
              -80.911361,
              38.414785
            ],
            [
              -80.817424,
              38.478553
            ],
            [
              -80.825292,
              38.494349
            ],
            [
              -80.864608,
              38.500102
            ],
            [
              -80.881232,
              38.507045
            ],
            [
              -80.891499,
              38.536913
            ],
            [
              -80.917744,
              38.544816
            ],
            [
              -80.913138,
              38.563568
            ],
            [
              -80.926342,
              38.579339
            ],
            [
              -80.955146,
              38.592206
            ],
            [
              -80.970274,
              38.593445
            ],
            [
              -80.975961,
              38.625096
            ],
            [
              -81.031677,
              38.667839
            ],
            [
              -81.08371,
              38.611982
            ],
            [
              -81.130775,
              38.565948
            ],
            [
              -81.194113,
              38.527634
            ],
            [
              -81.282117,
              38.473173
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "105",
      "COUNTYNS": "01678877",
      "AFFGEOID": "0500000US54105",
      "GEOID": "54105",
      "NAME": "Wirt",
      "LSAD": "06",
      "ALAND": 602201080,
      "AWATER": 5839706,
      "County_state": "Wirt,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.553546,
              39.049279
            ],
            [
              -81.581447,
              39.026179
            ],
            [
              -81.532186,
              39.00545
            ],
            [
              -81.529535,
              38.97757
            ],
            [
              -81.549504,
              38.967245
            ],
            [
              -81.537989,
              38.944209
            ],
            [
              -81.502628,
              38.917922
            ],
            [
              -81.467363,
              38.927503
            ],
            [
              -81.448148,
              38.941645
            ],
            [
              -81.415086,
              38.920725
            ],
            [
              -81.342147,
              38.896261
            ],
            [
              -81.308894,
              38.908104
            ],
            [
              -81.278412,
              38.914867
            ],
            [
              -81.27269,
              38.936338
            ],
            [
              -81.162455,
              39.030608
            ],
            [
              -81.179656,
              39.041419
            ],
            [
              -81.224738,
              39.04088
            ],
            [
              -81.228238,
              39.03228
            ],
            [
              -81.250252,
              39.035074
            ],
            [
              -81.253593,
              39.055924
            ],
            [
              -81.273239,
              39.058479
            ],
            [
              -81.25719,
              39.072103
            ],
            [
              -81.263731,
              39.107234
            ],
            [
              -81.275701,
              39.107726
            ],
            [
              -81.28781,
              39.132747
            ],
            [
              -81.299778,
              39.129827
            ],
            [
              -81.329228,
              39.15103
            ],
            [
              -81.310771,
              39.157799
            ],
            [
              -81.316807,
              39.16758
            ],
            [
              -81.298017,
              39.185572
            ],
            [
              -81.401522,
              39.137994
            ],
            [
              -81.424906,
              39.135679
            ],
            [
              -81.553546,
              39.049279
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "013",
      "COUNTYNS": "01696945",
      "AFFGEOID": "0500000US54013",
      "GEOID": "54013",
      "NAME": "Calhoun",
      "LSAD": "06",
      "ALAND": 723252434,
      "AWATER": 3704539,
      "County_state": "Calhoun,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.27269,
              38.936338
            ],
            [
              -81.278412,
              38.914867
            ],
            [
              -81.262885,
              38.91103
            ],
            [
              -81.249565,
              38.895885
            ],
            [
              -81.259474,
              38.893638
            ],
            [
              -81.238265,
              38.880855
            ],
            [
              -81.246393,
              38.853135
            ],
            [
              -81.220791,
              38.843604
            ],
            [
              -81.215205,
              38.824461
            ],
            [
              -81.224594,
              38.825531
            ],
            [
              -81.217865,
              38.798363
            ],
            [
              -81.227448,
              38.7932
            ],
            [
              -81.195867,
              38.767443
            ],
            [
              -81.171819,
              38.739805
            ],
            [
              -81.172165,
              38.707444
            ],
            [
              -81.166676,
              38.69507
            ],
            [
              -81.171764,
              38.675936
            ],
            [
              -81.158582,
              38.644037
            ],
            [
              -81.131599,
              38.63826
            ],
            [
              -81.123837,
              38.630962
            ],
            [
              -81.102097,
              38.63898
            ],
            [
              -81.08371,
              38.611982
            ],
            [
              -81.031677,
              38.667839
            ],
            [
              -80.98495,
              38.717898
            ],
            [
              -80.997417,
              38.74736
            ],
            [
              -81.022438,
              38.764809
            ],
            [
              -81.051909,
              38.779996
            ],
            [
              -81.056714,
              38.793322
            ],
            [
              -81.032811,
              38.804153
            ],
            [
              -81.012064,
              38.822142
            ],
            [
              -81.012089,
              38.840694
            ],
            [
              -81.002777,
              38.846025
            ],
            [
              -81.007672,
              38.900335
            ],
            [
              -81.013624,
              38.922898
            ],
            [
              -81.002611,
              38.932385
            ],
            [
              -81.011078,
              38.957451
            ],
            [
              -81.003484,
              38.96099
            ],
            [
              -81.036244,
              38.992976
            ],
            [
              -81.033632,
              39.009584
            ],
            [
              -81.059733,
              39.007884
            ],
            [
              -81.102532,
              39.02148
            ],
            [
              -81.118634,
              39.042582
            ],
            [
              -81.136087,
              39.045169
            ],
            [
              -81.162455,
              39.030608
            ],
            [
              -81.27269,
              38.936338
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "045",
      "COUNTYNS": "01550029",
      "AFFGEOID": "0500000US54045",
      "GEOID": "54045",
      "NAME": "Logan",
      "LSAD": "06",
      "ALAND": 1175209778,
      "AWATER": 4830034,
      "County_state": "Logan,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.190658,
              37.974778
            ],
            [
              -82.197169,
              37.962696
            ],
            [
              -82.181231,
              37.950284
            ],
            [
              -82.16368,
              37.912372
            ],
            [
              -82.125405,
              37.8994
            ],
            [
              -82.128809,
              37.87785
            ],
            [
              -82.11222,
              37.859418
            ],
            [
              -82.145915,
              37.83936
            ],
            [
              -82.133639,
              37.826251
            ],
            [
              -82.132219,
              37.809705
            ],
            [
              -82.105819,
              37.801142
            ],
            [
              -82.100361,
              37.766947
            ],
            [
              -82.11704,
              37.747606
            ],
            [
              -82.106618,
              37.750448
            ],
            [
              -82.088395,
              37.732381
            ],
            [
              -82.087938,
              37.73231
            ],
            [
              -82.082648,
              37.701565
            ],
            [
              -82.041254,
              37.701589
            ],
            [
              -82.022647,
              37.668151
            ],
            [
              -81.999077,
              37.649915
            ],
            [
              -81.993444,
              37.640607
            ],
            [
              -81.991937,
              37.647117
            ],
            [
              -81.979444,
              37.668107
            ],
            [
              -81.944826,
              37.671219
            ],
            [
              -81.930542,
              37.681907
            ],
            [
              -81.914042,
              37.680407
            ],
            [
              -81.902341,
              37.661208
            ],
            [
              -81.87484,
              37.670108
            ],
            [
              -81.865024,
              37.678909
            ],
            [
              -81.850284,
              37.66437
            ],
            [
              -81.838039,
              37.672108
            ],
            [
              -81.814438,
              37.655309
            ],
            [
              -81.802937,
              37.660508
            ],
            [
              -81.793037,
              37.670909
            ],
            [
              -81.712249,
              37.699085
            ],
            [
              -81.701734,
              37.701409
            ],
            [
              -81.719223,
              37.715497
            ],
            [
              -81.764537,
              37.751708
            ],
            [
              -81.742736,
              37.764208
            ],
            [
              -81.707436,
              37.763508
            ],
            [
              -81.693835,
              37.774108
            ],
            [
              -81.675135,
              37.772709
            ],
            [
              -81.657134,
              37.779809
            ],
            [
              -81.638033,
              37.777609
            ],
            [
              -81.607532,
              37.788709
            ],
            [
              -81.631833,
              37.803109
            ],
            [
              -81.631519,
              37.806526
            ],
            [
              -81.649034,
              37.830908
            ],
            [
              -81.663593,
              37.832917
            ],
            [
              -81.700836,
              37.822507
            ],
            [
              -81.723136,
              37.809607
            ],
            [
              -81.731437,
              37.825007
            ],
            [
              -81.744738,
              37.829107
            ],
            [
              -81.745738,
              37.843806
            ],
            [
              -81.774639,
              37.854006
            ],
            [
              -81.782239,
              37.867205
            ],
            [
              -81.759145,
              37.885911
            ],
            [
              -81.764439,
              37.905305
            ],
            [
              -81.781441,
              37.934504
            ],
            [
              -81.801141,
              37.936304
            ],
            [
              -81.815347,
              37.947222
            ],
            [
              -81.861144,
              37.948303
            ],
            [
              -81.901845,
              37.961102
            ],
            [
              -81.911246,
              37.974601
            ],
            [
              -81.950647,
              37.971901
            ],
            [
              -81.980248,
              37.9865
            ],
            [
              -81.932507,
              38.025356
            ],
            [
              -81.943523,
              38.021105
            ],
            [
              -81.988776,
              38.024884
            ],
            [
              -82.023317,
              38.01815
            ],
            [
              -82.027488,
              38.014371
            ],
            [
              -82.044575,
              38.014757
            ],
            [
              -82.127511,
              37.992187
            ],
            [
              -82.190658,
              37.974778
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "071",
      "COUNTYNS": "01550042",
      "AFFGEOID": "0500000US54071",
      "GEOID": "54071",
      "NAME": "Pendleton",
      "LSAD": "06",
      "ALAND": 1802751454,
      "AWATER": 5437237,
      "County_state": "Pendleton,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.619174,
              38.620815
            ],
            [
              -79.649075,
              38.591515
            ],
            [
              -79.571771,
              38.563117
            ],
            [
              -79.566271,
              38.562517
            ],
            [
              -79.555471,
              38.560217
            ],
            [
              -79.54257,
              38.553217
            ],
            [
              -79.53827,
              38.551817
            ],
            [
              -79.537523299639,
              38.5513369783393
            ],
            [
              -79.53687,
              38.550917
            ],
            [
              -79.53337,
              38.546217
            ],
            [
              -79.53317,
              38.544717
            ],
            [
              -79.53187,
              38.542817
            ],
            [
              -79.521469,
              38.533918
            ],
            [
              -79.499768,
              38.49772
            ],
            [
              -79.476638,
              38.457228
            ],
            [
              -79.370302,
              38.427244
            ],
            [
              -79.312276,
              38.411876
            ],
            [
              -79.300081,
              38.414888
            ],
            [
              -79.297758,
              38.416438
            ],
            [
              -79.295712,
              38.418129
            ],
            [
              -79.291813,
              38.419627
            ],
            [
              -79.290529,
              38.420757
            ],
            [
              -79.288432,
              38.42096
            ],
            [
              -79.286874,
              38.420555
            ],
            [
              -79.285613,
              38.419249
            ],
            [
              -79.282971,
              38.418095
            ],
            [
              -79.280149,
              38.42076
            ],
            [
              -79.279678,
              38.424173
            ],
            [
              -79.280263,
              38.425475
            ],
            [
              -79.280581,
              38.426833
            ],
            [
              -79.28247,
              38.429168
            ],
            [
              -79.282663,
              38.431021
            ],
            [
              -79.282762,
              38.431647
            ],
            [
              -79.282225,
              38.432078
            ],
            [
              -79.274529,
              38.436337
            ],
            [
              -79.272064,
              38.437376
            ],
            [
              -79.267414,
              38.438322
            ],
            [
              -79.265327,
              38.441772
            ],
            [
              -79.263376,
              38.443762
            ],
            [
              -79.26291,
              38.444586
            ],
            [
              -79.261107,
              38.448082
            ],
            [
              -79.254435,
              38.455949
            ],
            [
              -79.253067,
              38.455818
            ],
            [
              -79.247342,
              38.453294
            ],
            [
              -79.242641,
              38.454168
            ],
            [
              -79.241854,
              38.457055
            ],
            [
              -79.242024,
              38.464332
            ],
            [
              -79.240059,
              38.469841
            ],
            [
              -79.234408,
              38.473011
            ],
            [
              -79.23162,
              38.474041
            ],
            [
              -79.225669,
              38.476471
            ],
            [
              -79.2243090862597,
              38.4776607800969
            ],
            [
              -79.220961,
              38.48059
            ],
            [
              -79.221406,
              38.484837
            ],
            [
              -79.219067,
              38.487441
            ],
            [
              -79.215212,
              38.489261
            ],
            [
              -79.210591,
              38.492913
            ],
            [
              -79.210026,
              38.494231
            ],
            [
              -79.210008,
              38.494283
            ],
            [
              -79.209703,
              38.495574
            ],
            [
              -79.207873,
              38.500122
            ],
            [
              -79.207884,
              38.500428
            ],
            [
              -79.206959,
              38.503522
            ],
            [
              -79.210959,
              38.507422
            ],
            [
              -79.205859,
              38.524521
            ],
            [
              -79.201459,
              38.527821
            ],
            [
              -79.196959,
              38.536721
            ],
            [
              -79.193458,
              38.542421
            ],
            [
              -79.188958,
              38.54742
            ],
            [
              -79.184058,
              38.55152
            ],
            [
              -79.180858,
              38.55992
            ],
            [
              -79.176658,
              38.56552
            ],
            [
              -79.174881,
              38.566314
            ],
            [
              -79.174512,
              38.566531
            ],
            [
              -79.170958,
              38.56812
            ],
            [
              -79.170658,
              38.56922
            ],
            [
              -79.171658,
              38.57162
            ],
            [
              -79.170858,
              38.574119
            ],
            [
              -79.168058,
              38.578619
            ],
            [
              -79.1673213887043,
              38.5793395980066
            ],
            [
              -79.163458,
              38.583119
            ],
            [
              -79.158657,
              38.592319
            ],
            [
              -79.158257,
              38.593919
            ],
            [
              -79.158957,
              38.594519
            ],
            [
              -79.159158,
              38.601219
            ],
            [
              -79.154357,
              38.606518
            ],
            [
              -79.155557,
              38.609218
            ],
            [
              -79.155355,
              38.611225
            ],
            [
              -79.151257,
              38.620618
            ],
            [
              -79.146974,
              38.625641
            ],
            [
              -79.146741,
              38.625819
            ],
            [
              -79.142657,
              38.634417
            ],
            [
              -79.139657,
              38.637217
            ],
            [
              -79.137557,
              38.638017
            ],
            [
              -79.137012,
              38.640655
            ],
            [
              -79.136374,
              38.6424
            ],
            [
              -79.135546,
              38.643715
            ],
            [
              -79.135472,
              38.644057
            ],
            [
              -79.133557,
              38.646017
            ],
            [
              -79.131057,
              38.653217
            ],
            [
              -79.129757,
              38.655017
            ],
            [
              -79.122256,
              38.659817
            ],
            [
              -79.120256,
              38.660216
            ],
            [
              -79.111556,
              38.659717
            ],
            [
              -79.106356,
              38.656217
            ],
            [
              -79.092955,
              38.659517
            ],
            [
              -79.092755,
              38.662816
            ],
            [
              -79.091055,
              38.669316
            ],
            [
              -79.087855,
              38.673816
            ],
            [
              -79.084355,
              38.686516
            ],
            [
              -79.085555,
              38.688816
            ],
            [
              -79.088055,
              38.690115
            ],
            [
              -79.090755,
              38.692515
            ],
            [
              -79.092271,
              38.699208
            ],
            [
              -79.092555,
              38.700149
            ],
            [
              -79.092755,
              38.702315
            ],
            [
              -79.086555,
              38.716015
            ],
            [
              -79.087255,
              38.720114
            ],
            [
              -79.085455,
              38.724614
            ],
            [
              -79.081955,
              38.729714
            ],
            [
              -79.079655,
              38.734714
            ],
            [
              -79.076555,
              38.739214
            ],
            [
              -79.073855,
              38.742114
            ],
            [
              -79.072755,
              38.744614
            ],
            [
              -79.072555,
              38.747513
            ],
            [
              -79.064854,
              38.754413
            ],
            [
              -79.060954,
              38.756713
            ],
            [
              -79.057554,
              38.760213
            ],
            [
              -79.057253,
              38.761413
            ],
            [
              -79.134296,
              38.81334
            ],
            [
              -79.177797,
              38.842764
            ],
            [
              -79.349867,
              38.957509
            ],
            [
              -79.367968,
              38.92481
            ],
            [
              -79.374868,
              38.92081
            ],
            [
              -79.41567,
              38.92301
            ],
            [
              -79.443371,
              38.93261
            ],
            [
              -79.455472,
              38.92991
            ],
            [
              -79.444471,
              38.89861
            ],
            [
              -79.482873,
              38.88631
            ],
            [
              -79.519474,
              38.89201
            ],
            [
              -79.510074,
              38.87581
            ],
            [
              -79.515774,
              38.863211
            ],
            [
              -79.539075,
              38.841411
            ],
            [
              -79.523173,
              38.830214
            ],
            [
              -79.527193,
              38.814156
            ],
            [
              -79.507673,
              38.800511
            ],
            [
              -79.510073,
              38.780712
            ],
            [
              -79.526973,
              38.763112
            ],
            [
              -79.535773,
              38.746612
            ],
            [
              -79.555574,
              38.732512
            ],
            [
              -79.560944,
              38.71725
            ],
            [
              -79.597675,
              38.687614
            ],
            [
              -79.595357,
              38.663252
            ],
            [
              -79.623075,
              38.668814
            ],
            [
              -79.626774,
              38.664214
            ],
            [
              -79.617906,
              38.658365
            ],
            [
              -79.633933,
              38.635968
            ],
            [
              -79.619174,
              38.620815
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "085",
      "COUNTYNS": "01689049",
      "AFFGEOID": "0500000US54085",
      "GEOID": "54085",
      "NAME": "Ritchie",
      "LSAD": "06",
      "ALAND": 1170648844,
      "AWATER": 4424610,
      "County_state": "Ritchie,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.310771,
              39.157799
            ],
            [
              -81.329228,
              39.15103
            ],
            [
              -81.299778,
              39.129827
            ],
            [
              -81.28781,
              39.132747
            ],
            [
              -81.275701,
              39.107726
            ],
            [
              -81.263731,
              39.107234
            ],
            [
              -81.25719,
              39.072103
            ],
            [
              -81.273239,
              39.058479
            ],
            [
              -81.253593,
              39.055924
            ],
            [
              -81.250252,
              39.035074
            ],
            [
              -81.228238,
              39.03228
            ],
            [
              -81.224738,
              39.04088
            ],
            [
              -81.179656,
              39.041419
            ],
            [
              -81.162455,
              39.030608
            ],
            [
              -81.136087,
              39.045169
            ],
            [
              -81.118634,
              39.042582
            ],
            [
              -81.102532,
              39.02148
            ],
            [
              -81.059733,
              39.007884
            ],
            [
              -81.033632,
              39.009584
            ],
            [
              -81.002631,
              39.009585
            ],
            [
              -80.965684,
              39.035303
            ],
            [
              -80.911618,
              39.04624
            ],
            [
              -80.878457,
              39.072895
            ],
            [
              -80.85871,
              39.073957
            ],
            [
              -80.854581,
              39.08392
            ],
            [
              -80.835024,
              39.082376
            ],
            [
              -80.810777,
              39.09947
            ],
            [
              -80.81297,
              39.109401
            ],
            [
              -80.91384,
              39.272947
            ],
            [
              -80.888744,
              39.294298
            ],
            [
              -80.883952,
              39.307767
            ],
            [
              -80.892453,
              39.325179
            ],
            [
              -80.884737,
              39.330694
            ],
            [
              -80.883729,
              39.360723
            ],
            [
              -80.904438,
              39.378121
            ],
            [
              -80.910719,
              39.375079
            ],
            [
              -80.93214,
              39.389119
            ],
            [
              -80.943571,
              39.373731
            ],
            [
              -80.948824,
              39.349848
            ],
            [
              -80.962862,
              39.339561
            ],
            [
              -80.987506,
              39.347257
            ],
            [
              -80.99452,
              39.341056
            ],
            [
              -81.007836,
              39.350923
            ],
            [
              -81.034726,
              39.351173
            ],
            [
              -81.044432,
              39.328777
            ],
            [
              -81.056732,
              39.324077
            ],
            [
              -81.080704,
              39.331183
            ],
            [
              -81.121037,
              39.314059
            ],
            [
              -81.166933,
              39.311578
            ],
            [
              -81.189835,
              39.305276
            ],
            [
              -81.221036,
              39.308175
            ],
            [
              -81.239477,
              39.268328
            ],
            [
              -81.259324,
              39.267131
            ],
            [
              -81.301501,
              39.20268
            ],
            [
              -81.298017,
              39.185572
            ],
            [
              -81.316807,
              39.16758
            ],
            [
              -81.310771,
              39.157799
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "095",
      "COUNTYNS": "01678706",
      "AFFGEOID": "0500000US54095",
      "GEOID": "54095",
      "NAME": "Tyler",
      "LSAD": "06",
      "ALAND": 663797704,
      "AWATER": 11403003,
      "County_state": "Tyler,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.115133,
              39.466275
            ],
            [
              -81.121472141059,
              39.457756779201894
            ],
            [
              -81.106913,
              39.463571
            ],
            [
              -81.094324,
              39.457689
            ],
            [
              -81.078895,
              39.467556
            ],
            [
              -81.05711,
              39.463333
            ],
            [
              -81.054048,
              39.467447
            ],
            [
              -81.061269,
              39.476432
            ],
            [
              -81.042631,
              39.46861
            ],
            [
              -81.029932,
              39.467745
            ],
            [
              -81.02569,
              39.462063
            ],
            [
              -81.023162,
              39.441821
            ],
            [
              -81.014433,
              39.403506
            ],
            [
              -81.007836,
              39.350923
            ],
            [
              -80.99452,
              39.341056
            ],
            [
              -80.987506,
              39.347257
            ],
            [
              -80.962862,
              39.339561
            ],
            [
              -80.948824,
              39.349848
            ],
            [
              -80.943571,
              39.373731
            ],
            [
              -80.93214,
              39.389119
            ],
            [
              -80.910719,
              39.375079
            ],
            [
              -80.904438,
              39.378121
            ],
            [
              -80.883729,
              39.360723
            ],
            [
              -80.884737,
              39.330694
            ],
            [
              -80.892453,
              39.325179
            ],
            [
              -80.883952,
              39.307767
            ],
            [
              -80.888744,
              39.294298
            ],
            [
              -80.829445,
              39.343228
            ],
            [
              -80.73389,
              39.395148
            ],
            [
              -80.713324,
              39.43025
            ],
            [
              -80.658711,
              39.433012
            ],
            [
              -80.648488,
              39.443023
            ],
            [
              -80.618629,
              39.450375
            ],
            [
              -80.652655,
              39.470068
            ],
            [
              -80.671106,
              39.464214
            ],
            [
              -80.670296,
              39.47515
            ],
            [
              -80.685995,
              39.470495
            ],
            [
              -80.710971,
              39.475878
            ],
            [
              -80.722703,
              39.491122
            ],
            [
              -80.73407,
              39.491553
            ],
            [
              -80.740849,
              39.512823
            ],
            [
              -80.784688,
              39.537346
            ],
            [
              -80.782831,
              39.547705
            ],
            [
              -80.809772,
              39.561961
            ],
            [
              -80.823203,
              39.554462
            ],
            [
              -80.827226,
              39.576318
            ],
            [
              -80.843731,
              39.583885
            ],
            [
              -80.846393,
              39.601091
            ],
            [
              -80.852182,
              39.602048
            ],
            [
              -80.886043,
              39.58031
            ],
            [
              -80.943782,
              39.606926
            ],
            [
              -80.970436,
              39.590127
            ],
            [
              -80.978664,
              39.583517
            ],
            [
              -80.987732,
              39.577262
            ],
            [
              -80.993695,
              39.571253
            ],
            [
              -80.996804,
              39.56912
            ],
            [
              -81.00866,
              39.562798
            ],
            [
              -81.020055,
              39.55541
            ],
            [
              -81.0239,
              39.552313
            ],
            [
              -81.026662,
              39.548547
            ],
            [
              -81.030169,
              39.545211
            ],
            [
              -81.03870601730101,
              39.5400475328739
            ],
            [
              -81.044902,
              39.5363
            ],
            [
              -81.049955,
              39.531893
            ],
            [
              -81.051982,
              39.52931
            ],
            [
              -81.060379,
              39.522744
            ],
            [
              -81.070594,
              39.515991
            ],
            [
              -81.07595,
              39.50966
            ],
            [
              -81.091433,
              39.496975
            ],
            [
              -81.100833,
              39.487175
            ],
            [
              -81.114433,
              39.466275
            ],
            [
              -81.115133,
              39.466275
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "049",
      "COUNTYNS": "01718173",
      "AFFGEOID": "0500000US54049",
      "GEOID": "54049",
      "NAME": "Marion",
      "LSAD": "06",
      "ALAND": 799642461,
      "AWATER": 7186185,
      "County_state": "Marion,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.498163,
              39.563327
            ],
            [
              -80.480867,
              39.514609
            ],
            [
              -80.467421,
              39.503243
            ],
            [
              -80.471782,
              39.486344
            ],
            [
              -80.489887,
              39.482514
            ],
            [
              -80.494085,
              39.469599
            ],
            [
              -80.313269,
              39.437627
            ],
            [
              -80.282063,
              39.435449
            ],
            [
              -80.197286,
              39.393186
            ],
            [
              -80.192251,
              39.393141
            ],
            [
              -80.122035,
              39.401291
            ],
            [
              -80.113391,
              39.408954
            ],
            [
              -80.087334,
              39.386215
            ],
            [
              -80.025001,
              39.401446
            ],
            [
              -80.011235,
              39.417362
            ],
            [
              -79.938878,
              39.452465
            ],
            [
              -79.967959,
              39.462978
            ],
            [
              -79.993682,
              39.484572
            ],
            [
              -80.01106,
              39.487172
            ],
            [
              -80.015511,
              39.501538
            ],
            [
              -80.016538,
              39.50256
            ],
            [
              -80.018593,
              39.5035
            ],
            [
              -80.020676,
              39.503366
            ],
            [
              -80.021415,
              39.503962
            ],
            [
              -80.023089,
              39.503644
            ],
            [
              -80.025109,
              39.50471
            ],
            [
              -80.027463,
              39.504815
            ],
            [
              -80.027746,
              39.505672
            ],
            [
              -80.028582,
              39.506002
            ],
            [
              -80.032031,
              39.505502
            ],
            [
              -80.038618,
              39.517
            ],
            [
              -80.110721,
              39.576844
            ],
            [
              -80.128283,
              39.582475
            ],
            [
              -80.153532,
              39.610426
            ],
            [
              -80.171455,
              39.610529
            ],
            [
              -80.312894,
              39.611775
            ],
            [
              -80.347654,
              39.61185
            ],
            [
              -80.3677,
              39.633563
            ],
            [
              -80.39581,
              39.637347
            ],
            [
              -80.432837,
              39.639292
            ],
            [
              -80.443387,
              39.62954
            ],
            [
              -80.433841,
              39.620268
            ],
            [
              -80.449417,
              39.612707
            ],
            [
              -80.451015,
              39.595661
            ],
            [
              -80.478771,
              39.597333
            ],
            [
              -80.480278,
              39.583408
            ],
            [
              -80.498163,
              39.563327
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "107",
      "COUNTYNS": "01560558",
      "AFFGEOID": "0500000US54107",
      "GEOID": "54107",
      "NAME": "Wood",
      "LSAD": "06",
      "ALAND": 948592039,
      "AWATER": 27228519,
      "County_state": "Wood,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.7558150545194,
              39.180524196556696
            ],
            [
              -81.756254,
              39.177276
            ],
            [
              -81.754254,
              39.171476
            ],
            [
              -81.744621,
              39.148413
            ],
            [
              -81.743565,
              39.141933
            ],
            [
              -81.744525,
              39.138829
            ],
            [
              -81.744838,
              39.130898
            ],
            [
              -81.744568,
              39.126816
            ],
            [
              -81.742153,
              39.116777
            ],
            [
              -81.742953,
              39.106578
            ],
            [
              -81.743853,
              39.102378
            ],
            [
              -81.745453,
              39.098078
            ],
            [
              -81.74725270065791,
              39.0953784490132
            ],
            [
              -81.581447,
              39.026179
            ],
            [
              -81.553546,
              39.049279
            ],
            [
              -81.424906,
              39.135679
            ],
            [
              -81.401522,
              39.137994
            ],
            [
              -81.298017,
              39.185572
            ],
            [
              -81.301501,
              39.20268
            ],
            [
              -81.259324,
              39.267131
            ],
            [
              -81.239477,
              39.268328
            ],
            [
              -81.261143,
              39.292389
            ],
            [
              -81.324215,
              39.298557
            ],
            [
              -81.324063,
              39.316371
            ],
            [
              -81.34812,
              39.328764
            ],
            [
              -81.363942,
              39.320804
            ],
            [
              -81.3712707002794,
              39.3420616369494
            ],
            [
              -81.375961,
              39.341697
            ],
            [
              -81.379674,
              39.342081
            ],
            [
              -81.384556,
              39.343449
            ],
            [
              -81.391249,
              39.348814
            ],
            [
              -81.393794,
              39.351706
            ],
            [
              -81.395883,
              39.355553
            ],
            [
              -81.400744,
              39.369221
            ],
            [
              -81.40277,
              39.376914
            ],
            [
              -81.406689,
              39.38809
            ],
            [
              -81.412706,
              39.394618
            ],
            [
              -81.4175426815188,
              39.3981570352576
            ],
            [
              -81.420578,
              39.400378
            ],
            [
              -81.428642,
              39.405374
            ],
            [
              -81.435642,
              39.408474
            ],
            [
              -81.446543,
              39.410374
            ],
            [
              -81.456143,
              39.409274
            ],
            [
              -81.467744,
              39.403774
            ],
            [
              -81.473188,
              39.40017
            ],
            [
              -81.4829,
              39.389674
            ],
            [
              -81.489044,
              39.384074
            ],
            [
              -81.503189,
              39.373242
            ],
            [
              -81.513493,
              39.36705
            ],
            [
              -81.524309,
              39.36161
            ],
            [
              -81.53447,
              39.358234
            ],
            [
              -81.542346,
              39.352874
            ],
            [
              -81.557547,
              39.338774
            ],
            [
              -81.559647,
              39.330774
            ],
            [
              -81.560147,
              39.317874
            ],
            [
              -81.565047,
              39.293874
            ],
            [
              -81.565247,
              39.276175
            ],
            [
              -81.567347,
              39.270675
            ],
            [
              -81.570247,
              39.267675
            ],
            [
              -81.585559,
              39.268747
            ],
            [
              -81.588583,
              39.269787
            ],
            [
              -81.59516,
              39.273387
            ],
            [
              -81.603352,
              39.275531
            ],
            [
              -81.608408,
              39.276043
            ],
            [
              -81.613896,
              39.275339
            ],
            [
              -81.621305,
              39.273643
            ],
            [
              -81.643178,
              39.277195
            ],
            [
              -81.656138,
              39.277355
            ],
            [
              -81.670187,
              39.275963
            ],
            [
              -81.678331,
              39.273755
            ],
            [
              -81.683627,
              39.270939
            ],
            [
              -81.689483,
              39.266043
            ],
            [
              -81.69206,
              39.263227
            ],
            [
              -81.69638,
              39.257035
            ],
            [
              -81.696988,
              39.248747
            ],
            [
              -81.696636,
              39.246123
            ],
            [
              -81.695724,
              39.242859
            ],
            [
              -81.692203,
              39.236091
            ],
            [
              -81.691067,
              39.230139
            ],
            [
              -81.691339,
              39.227947
            ],
            [
              -81.692395,
              39.226443
            ],
            [
              -81.694603,
              39.224107
            ],
            [
              -81.700908,
              39.220844
            ],
            [
              -81.711628,
              39.219228
            ],
            [
              -81.7190774611809,
              39.21763715958139
            ],
            [
              -81.724365,
              39.216508
            ],
            [
              -81.7255834730739,
              39.2158352234561
            ],
            [
              -81.726973,
              39.215068
            ],
            [
              -81.729949,
              39.211884
            ],
            [
              -81.733357,
              39.205868
            ],
            [
              -81.735805,
              39.196268
            ],
            [
              -81.737085,
              39.193836
            ],
            [
              -81.741533,
              39.189596
            ],
            [
              -81.749853,
              39.186489
            ],
            [
              -81.752754,
              39.184676
            ],
            [
              -81.755754,
              39.180976
            ],
            [
              -81.7558150545194,
              39.180524196556696
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "033",
      "COUNTYNS": "01718426",
      "AFFGEOID": "0500000US54033",
      "GEOID": "54033",
      "NAME": "Harrison",
      "LSAD": "06",
      "ALAND": 1077454921,
      "AWATER": 1320319,
      "County_state": "Harrison,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.581939,
              39.316544
            ],
            [
              -80.604301,
              39.286047
            ],
            [
              -80.577764,
              39.282151
            ],
            [
              -80.58344,
              39.256975
            ],
            [
              -80.547068,
              39.234786
            ],
            [
              -80.565973,
              39.220722
            ],
            [
              -80.525709,
              39.209429
            ],
            [
              -80.528756,
              39.195706
            ],
            [
              -80.575179,
              39.175273
            ],
            [
              -80.591766,
              39.182697
            ],
            [
              -80.596126,
              39.167069
            ],
            [
              -80.58514,
              39.168499
            ],
            [
              -80.548913,
              39.158755
            ],
            [
              -80.546044,
              39.151257
            ],
            [
              -80.517982,
              39.145989
            ],
            [
              -80.46737,
              39.145678
            ],
            [
              -80.451246,
              39.138475
            ],
            [
              -80.300209,
              39.103857
            ],
            [
              -80.248771,
              39.100809
            ],
            [
              -80.227173,
              39.112792
            ],
            [
              -80.224687,
              39.170977
            ],
            [
              -80.166387,
              39.242283
            ],
            [
              -80.187648,
              39.267143
            ],
            [
              -80.199504,
              39.27069
            ],
            [
              -80.202276,
              39.32852
            ],
            [
              -80.205036,
              39.381643
            ],
            [
              -80.197286,
              39.393186
            ],
            [
              -80.282063,
              39.435449
            ],
            [
              -80.313269,
              39.437627
            ],
            [
              -80.494085,
              39.469599
            ],
            [
              -80.512663,
              39.458612
            ],
            [
              -80.518626,
              39.434997
            ],
            [
              -80.544521,
              39.42912
            ],
            [
              -80.539076,
              39.41029
            ],
            [
              -80.547748,
              39.40369
            ],
            [
              -80.541259,
              39.387527
            ],
            [
              -80.544096,
              39.36648
            ],
            [
              -80.531808,
              39.360121
            ],
            [
              -80.557302,
              39.337107
            ],
            [
              -80.564397,
              39.321002
            ],
            [
              -80.581939,
              39.316544
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "091",
      "COUNTYNS": "01689856",
      "AFFGEOID": "0500000US54091",
      "GEOID": "54091",
      "NAME": "Taylor",
      "LSAD": "06",
      "ALAND": 447475054,
      "AWATER": 7532835,
      "County_state": "Taylor,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.205036,
              39.381643
            ],
            [
              -80.202276,
              39.32852
            ],
            [
              -80.199504,
              39.27069
            ],
            [
              -80.187648,
              39.267143
            ],
            [
              -80.166387,
              39.242283
            ],
            [
              -80.150041,
              39.23825
            ],
            [
              -80.023411,
              39.247892
            ],
            [
              -79.961099,
              39.268796
            ],
            [
              -79.937032,
              39.297327
            ],
            [
              -79.926061,
              39.288738
            ],
            [
              -79.895536,
              39.299584
            ],
            [
              -79.894688,
              39.435588
            ],
            [
              -79.938878,
              39.452465
            ],
            [
              -80.011235,
              39.417362
            ],
            [
              -80.025001,
              39.401446
            ],
            [
              -80.087334,
              39.386215
            ],
            [
              -80.113391,
              39.408954
            ],
            [
              -80.122035,
              39.401291
            ],
            [
              -80.192251,
              39.393141
            ],
            [
              -80.197286,
              39.393186
            ],
            [
              -80.205036,
              39.381643
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "005",
      "COUNTYNS": "01550009",
      "AFFGEOID": "0500000US54005",
      "GEOID": "54005",
      "NAME": "Boone",
      "LSAD": "06",
      "ALAND": 1298967373,
      "AWATER": 4305976,
      "County_state": "Boone,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.980248,
              37.9865
            ],
            [
              -81.950647,
              37.971901
            ],
            [
              -81.911246,
              37.974601
            ],
            [
              -81.901845,
              37.961102
            ],
            [
              -81.861144,
              37.948303
            ],
            [
              -81.815347,
              37.947222
            ],
            [
              -81.801141,
              37.936304
            ],
            [
              -81.781441,
              37.934504
            ],
            [
              -81.764439,
              37.905305
            ],
            [
              -81.759145,
              37.885911
            ],
            [
              -81.782239,
              37.867205
            ],
            [
              -81.774639,
              37.854006
            ],
            [
              -81.745738,
              37.843806
            ],
            [
              -81.744738,
              37.829107
            ],
            [
              -81.731437,
              37.825007
            ],
            [
              -81.723136,
              37.809607
            ],
            [
              -81.700836,
              37.822507
            ],
            [
              -81.663593,
              37.832917
            ],
            [
              -81.649034,
              37.830908
            ],
            [
              -81.631519,
              37.806526
            ],
            [
              -81.631833,
              37.803109
            ],
            [
              -81.607532,
              37.788709
            ],
            [
              -81.57653,
              37.76331
            ],
            [
              -81.565794,
              37.767642
            ],
            [
              -81.530429,
              37.78971
            ],
            [
              -81.514228,
              37.791211
            ],
            [
              -81.516729,
              37.81841
            ],
            [
              -81.528736,
              37.82197
            ],
            [
              -81.552331,
              37.824709
            ],
            [
              -81.560831,
              37.841409
            ],
            [
              -81.563416,
              37.883396
            ],
            [
              -81.553832,
              37.894309
            ],
            [
              -81.564233,
              37.902708
            ],
            [
              -81.561933,
              37.919207
            ],
            [
              -81.571534,
              37.927707
            ],
            [
              -81.554934,
              37.946107
            ],
            [
              -81.555834,
              37.954307
            ],
            [
              -81.538634,
              37.971007
            ],
            [
              -81.526233,
              37.971307
            ],
            [
              -81.507233,
              37.988407
            ],
            [
              -81.489132,
              37.981007
            ],
            [
              -81.456632,
              37.987307
            ],
            [
              -81.466329,
              38.008619
            ],
            [
              -81.459897,
              38.027051
            ],
            [
              -81.490091,
              38.067369
            ],
            [
              -81.505275,
              38.060697
            ],
            [
              -81.537468,
              38.076073
            ],
            [
              -81.534077,
              38.09684
            ],
            [
              -81.515836,
              38.11236
            ],
            [
              -81.534013,
              38.121352
            ],
            [
              -81.555742,
              38.141543
            ],
            [
              -81.569471,
              38.137287
            ],
            [
              -81.5904,
              38.154166
            ],
            [
              -81.602288,
              38.174309
            ],
            [
              -81.634572,
              38.177534
            ],
            [
              -81.648537,
              38.185036
            ],
            [
              -81.652547,
              38.203096
            ],
            [
              -81.64387,
              38.220759
            ],
            [
              -81.659771,
              38.223487
            ],
            [
              -81.687031,
              38.218138
            ],
            [
              -81.698063,
              38.228254
            ],
            [
              -81.72551,
              38.221453
            ],
            [
              -81.737005,
              38.198173
            ],
            [
              -81.754607,
              38.204938
            ],
            [
              -81.764623,
              38.227471
            ],
            [
              -81.783886,
              38.230604
            ],
            [
              -81.785456,
              38.214246
            ],
            [
              -81.810465,
              38.208321
            ],
            [
              -81.814224,
              38.198657
            ],
            [
              -81.801501,
              38.179115
            ],
            [
              -81.820868,
              38.186714
            ],
            [
              -81.83347,
              38.20957
            ],
            [
              -81.851766,
              38.184813
            ],
            [
              -81.851176,
              38.173074
            ],
            [
              -81.871974,
              38.176846
            ],
            [
              -81.872118,
              38.163699
            ],
            [
              -81.886469,
              38.168002
            ],
            [
              -81.888065,
              38.151275
            ],
            [
              -81.878779,
              38.137202
            ],
            [
              -81.903797,
              38.14164
            ],
            [
              -81.927565,
              38.152492
            ],
            [
              -81.943864,
              38.137504
            ],
            [
              -81.953263,
              38.118878
            ],
            [
              -81.945795,
              38.108836
            ],
            [
              -81.964081,
              38.089653
            ],
            [
              -81.972102,
              38.050279
            ],
            [
              -81.954564,
              38.039438
            ],
            [
              -81.90516,
              38.029757
            ],
            [
              -81.932507,
              38.025356
            ],
            [
              -81.980248,
              37.9865
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "019",
      "COUNTYNS": "01560095",
      "AFFGEOID": "0500000US54019",
      "GEOID": "54019",
      "NAME": "Fayette",
      "LSAD": "06",
      "ALAND": 1713507808,
      "AWATER": 17505425,
      "County_state": "Fayette,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.380829,
              37.969109
            ],
            [
              -81.34733,
              37.976709
            ],
            [
              -81.322229,
              37.95221
            ],
            [
              -81.296628,
              37.94401
            ],
            [
              -81.301528,
              37.93341
            ],
            [
              -81.295182,
              37.889368
            ],
            [
              -81.073249,
              37.875865
            ],
            [
              -81.072668,
              37.860767
            ],
            [
              -81.100669,
              37.859386
            ],
            [
              -81.092643,
              37.85078
            ],
            [
              -81.051138,
              37.858302
            ],
            [
              -81.057286,
              37.835537
            ],
            [
              -81.027897,
              37.829518
            ],
            [
              -80.978109,
              37.83315
            ],
            [
              -80.94509,
              37.819121
            ],
            [
              -80.806316,
              37.868915
            ],
            [
              -80.748757,
              37.865097
            ],
            [
              -80.810866,
              37.949141
            ],
            [
              -80.769866,
              37.98527
            ],
            [
              -80.78611,
              37.989805
            ],
            [
              -80.786245,
              38.003799
            ],
            [
              -80.797396,
              38.025141
            ],
            [
              -80.828253,
              38.031684
            ],
            [
              -80.829615,
              38.043389
            ],
            [
              -80.812476,
              38.047063
            ],
            [
              -80.799972,
              38.063032
            ],
            [
              -80.819458,
              38.064943
            ],
            [
              -80.834607,
              38.05384
            ],
            [
              -80.866168,
              38.058317
            ],
            [
              -80.893881,
              38.079614
            ],
            [
              -80.884852,
              38.082467
            ],
            [
              -80.882187,
              38.103457
            ],
            [
              -80.882478,
              38.133552
            ],
            [
              -80.901184,
              38.122513
            ],
            [
              -80.949766,
              38.168014
            ],
            [
              -80.944073,
              38.193702
            ],
            [
              -80.968825,
              38.214971
            ],
            [
              -80.983472,
              38.22254
            ],
            [
              -81.007667,
              38.221364
            ],
            [
              -81.007497,
              38.205634
            ],
            [
              -81.034686,
              38.220558
            ],
            [
              -81.027696,
              38.236088
            ],
            [
              -81.057581,
              38.222827
            ],
            [
              -81.050716,
              38.21327
            ],
            [
              -81.070465,
              38.210103
            ],
            [
              -81.075039,
              38.196136
            ],
            [
              -81.084381,
              38.204104
            ],
            [
              -81.110385,
              38.212923
            ],
            [
              -81.133427,
              38.235223
            ],
            [
              -81.152532,
              38.226401
            ],
            [
              -81.178553,
              38.233438
            ],
            [
              -81.192285,
              38.249368
            ],
            [
              -81.204275,
              38.248883
            ],
            [
              -81.231636,
              38.263514
            ],
            [
              -81.314961,
              38.180529
            ],
            [
              -81.329262,
              38.182306
            ],
            [
              -81.329042,
              38.176942
            ],
            [
              -81.326752,
              38.174452
            ],
            [
              -81.330054,
              38.169509
            ],
            [
              -81.333236,
              38.161281
            ],
            [
              -81.380829,
              37.969109
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "039",
      "COUNTYNS": "01550026",
      "AFFGEOID": "0500000US54039",
      "GEOID": "54039",
      "NAME": "Kanawha",
      "LSAD": "06",
      "ALAND": 2335215662,
      "AWATER": 24056487,
      "County_state": "Kanawha,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.903521,
              38.382968
            ],
            [
              -81.915197,
              38.325597
            ],
            [
              -81.885772,
              38.31475
            ],
            [
              -81.84709,
              38.313351
            ],
            [
              -81.830634,
              38.322526
            ],
            [
              -81.816874,
              38.315759
            ],
            [
              -81.806505,
              38.293711
            ],
            [
              -81.802558,
              38.264305
            ],
            [
              -81.810543,
              38.257013
            ],
            [
              -81.809171,
              38.220089
            ],
            [
              -81.833149,
              38.222872
            ],
            [
              -81.83347,
              38.20957
            ],
            [
              -81.820868,
              38.186714
            ],
            [
              -81.801501,
              38.179115
            ],
            [
              -81.814224,
              38.198657
            ],
            [
              -81.810465,
              38.208321
            ],
            [
              -81.785456,
              38.214246
            ],
            [
              -81.783886,
              38.230604
            ],
            [
              -81.764623,
              38.227471
            ],
            [
              -81.754607,
              38.204938
            ],
            [
              -81.737005,
              38.198173
            ],
            [
              -81.72551,
              38.221453
            ],
            [
              -81.698063,
              38.228254
            ],
            [
              -81.687031,
              38.218138
            ],
            [
              -81.659771,
              38.223487
            ],
            [
              -81.64387,
              38.220759
            ],
            [
              -81.652547,
              38.203096
            ],
            [
              -81.648537,
              38.185036
            ],
            [
              -81.634572,
              38.177534
            ],
            [
              -81.602288,
              38.174309
            ],
            [
              -81.5904,
              38.154166
            ],
            [
              -81.569471,
              38.137287
            ],
            [
              -81.555742,
              38.141543
            ],
            [
              -81.534013,
              38.121352
            ],
            [
              -81.515836,
              38.11236
            ],
            [
              -81.534077,
              38.09684
            ],
            [
              -81.537468,
              38.076073
            ],
            [
              -81.505275,
              38.060697
            ],
            [
              -81.490091,
              38.067369
            ],
            [
              -81.459897,
              38.027051
            ],
            [
              -81.466329,
              38.008619
            ],
            [
              -81.456632,
              37.987307
            ],
            [
              -81.459132,
              37.979108
            ],
            [
              -81.436131,
              37.968108
            ],
            [
              -81.414331,
              37.973508
            ],
            [
              -81.380829,
              37.969109
            ],
            [
              -81.333236,
              38.161281
            ],
            [
              -81.330054,
              38.169509
            ],
            [
              -81.326752,
              38.174452
            ],
            [
              -81.329042,
              38.176942
            ],
            [
              -81.329262,
              38.182306
            ],
            [
              -81.314961,
              38.180529
            ],
            [
              -81.231636,
              38.263514
            ],
            [
              -81.231434,
              38.263716
            ],
            [
              -81.241745,
              38.314057
            ],
            [
              -81.25418,
              38.372567
            ],
            [
              -81.282929,
              38.468616
            ],
            [
              -81.282117,
              38.473173
            ],
            [
              -81.194113,
              38.527634
            ],
            [
              -81.220561,
              38.531839
            ],
            [
              -81.274252,
              38.52183
            ],
            [
              -81.30349,
              38.534799
            ],
            [
              -81.349335,
              38.541301
            ],
            [
              -81.465372,
              38.549898
            ],
            [
              -81.471654,
              38.546336
            ],
            [
              -81.485624,
              38.560402
            ],
            [
              -81.507547,
              38.582713
            ],
            [
              -81.522166,
              38.612746
            ],
            [
              -81.578202,
              38.577068
            ],
            [
              -81.632817,
              38.554699
            ],
            [
              -81.696348,
              38.626427
            ],
            [
              -81.728775,
              38.596219
            ],
            [
              -81.738968,
              38.599326
            ],
            [
              -81.7559,
              38.580944
            ],
            [
              -81.747164,
              38.553563
            ],
            [
              -81.754069,
              38.523956
            ],
            [
              -81.742237,
              38.517496
            ],
            [
              -81.726378,
              38.475548
            ],
            [
              -81.727228,
              38.466116
            ],
            [
              -81.822348,
              38.430332
            ],
            [
              -81.825637,
              38.430552
            ],
            [
              -81.840488,
              38.426099
            ],
            [
              -81.850797,
              38.421932
            ],
            [
              -81.88026,
              38.408235
            ],
            [
              -81.899359,
              38.399088
            ],
            [
              -81.903521,
              38.382968
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "087",
      "COUNTYNS": "01550050",
      "AFFGEOID": "0500000US54087",
      "GEOID": "54087",
      "NAME": "Roane",
      "LSAD": "06",
      "ALAND": 1252422375,
      "AWATER": 404682,
      "County_state": "Roane,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.533965,
              38.727371
            ],
            [
              -81.546631,
              38.672387
            ],
            [
              -81.522166,
              38.612746
            ],
            [
              -81.507547,
              38.582713
            ],
            [
              -81.485624,
              38.560402
            ],
            [
              -81.471654,
              38.546336
            ],
            [
              -81.465372,
              38.549898
            ],
            [
              -81.349335,
              38.541301
            ],
            [
              -81.30349,
              38.534799
            ],
            [
              -81.274252,
              38.52183
            ],
            [
              -81.220561,
              38.531839
            ],
            [
              -81.194113,
              38.527634
            ],
            [
              -81.130775,
              38.565948
            ],
            [
              -81.08371,
              38.611982
            ],
            [
              -81.102097,
              38.63898
            ],
            [
              -81.123837,
              38.630962
            ],
            [
              -81.131599,
              38.63826
            ],
            [
              -81.158582,
              38.644037
            ],
            [
              -81.171764,
              38.675936
            ],
            [
              -81.166676,
              38.69507
            ],
            [
              -81.172165,
              38.707444
            ],
            [
              -81.171819,
              38.739805
            ],
            [
              -81.195867,
              38.767443
            ],
            [
              -81.227448,
              38.7932
            ],
            [
              -81.217865,
              38.798363
            ],
            [
              -81.224594,
              38.825531
            ],
            [
              -81.215205,
              38.824461
            ],
            [
              -81.220791,
              38.843604
            ],
            [
              -81.246393,
              38.853135
            ],
            [
              -81.238265,
              38.880855
            ],
            [
              -81.259474,
              38.893638
            ],
            [
              -81.249565,
              38.895885
            ],
            [
              -81.262885,
              38.91103
            ],
            [
              -81.278412,
              38.914867
            ],
            [
              -81.308894,
              38.908104
            ],
            [
              -81.342147,
              38.896261
            ],
            [
              -81.415086,
              38.920725
            ],
            [
              -81.448148,
              38.941645
            ],
            [
              -81.467363,
              38.927503
            ],
            [
              -81.502628,
              38.917922
            ],
            [
              -81.52731,
              38.901666
            ],
            [
              -81.521561,
              38.824904
            ],
            [
              -81.533965,
              38.727371
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "027",
      "COUNTYNS": "01718644",
      "AFFGEOID": "0500000US54027",
      "GEOID": "54027",
      "NAME": "Hampshire",
      "LSAD": "06",
      "ALAND": 1658614732,
      "AWATER": 10922070,
      "County_state": "Hampshire,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.959223,
              39.260648
            ],
            [
              -78.979898,
              39.237624
            ],
            [
              -78.899695,
              39.199137
            ],
            [
              -78.842198,
              39.184278
            ],
            [
              -78.732971,
              39.149922
            ],
            [
              -78.69857,
              39.148073
            ],
            [
              -78.656875,
              39.125673
            ],
            [
              -78.623555,
              39.118509
            ],
            [
              -78.602332,
              39.140367
            ],
            [
              -78.561282,
              39.131444
            ],
            [
              -78.508132,
              39.08863
            ],
            [
              -78.504384,
              39.091398
            ],
            [
              -78.495984,
              39.09898
            ],
            [
              -78.49516,
              39.100992
            ],
            [
              -78.484283,
              39.107372
            ],
            [
              -78.478426,
              39.109843
            ],
            [
              -78.47732,
              39.109398
            ],
            [
              -78.475376,
              39.107469
            ],
            [
              -78.473209,
              39.108143
            ],
            [
              -78.470261,
              39.110063
            ],
            [
              -78.46953,
              39.111204
            ],
            [
              -78.466662,
              39.112858
            ],
            [
              -78.459869,
              39.113351
            ],
            [
              -78.439429,
              39.132146
            ],
            [
              -78.437771,
              39.135426
            ],
            [
              -78.436658,
              39.141691
            ],
            [
              -78.427294,
              39.152726
            ],
            [
              -78.413943,
              39.158415
            ],
            [
              -78.412599,
              39.160038
            ],
            [
              -78.403697,
              39.167451
            ],
            [
              -78.406966,
              39.170903
            ],
            [
              -78.411972,
              39.172734
            ],
            [
              -78.426315,
              39.182762
            ],
            [
              -78.428697,
              39.187217
            ],
            [
              -78.424292,
              39.192156
            ],
            [
              -78.424905,
              39.193301
            ],
            [
              -78.430846,
              39.196227
            ],
            [
              -78.436662,
              39.196658
            ],
            [
              -78.438651,
              39.198049
            ],
            [
              -78.437053,
              39.199766
            ],
            [
              -78.43213,
              39.204717
            ],
            [
              -78.431167,
              39.205744
            ],
            [
              -78.429803,
              39.207014
            ],
            [
              -78.427911,
              39.208611
            ],
            [
              -78.423968,
              39.212049
            ],
            [
              -78.41789,
              39.217504
            ],
            [
              -78.405585,
              39.231176
            ],
            [
              -78.40498,
              39.238006
            ],
            [
              -78.404214,
              39.241214
            ],
            [
              -78.399669,
              39.243874
            ],
            [
              -78.399785,
              39.244129
            ],
            [
              -78.409116,
              39.252564
            ],
            [
              -78.414631,
              39.255733
            ],
            [
              -78.41612,
              39.25541
            ],
            [
              -78.418584,
              39.256065
            ],
            [
              -78.419422,
              39.257476
            ],
            [
              -78.414204,
              39.26391
            ],
            [
              -78.402783,
              39.275471
            ],
            [
              -78.401813,
              39.276754
            ],
            [
              -78.402275,
              39.277238
            ],
            [
              -78.398682,
              39.281332
            ],
            [
              -78.393371,
              39.282988
            ],
            [
              -78.388785,
              39.288572
            ],
            [
              -78.387194,
              39.291444
            ],
            [
              -78.387242,
              39.29343
            ],
            [
              -78.385888,
              39.294888
            ],
            [
              -78.374728,
              39.305136
            ],
            [
              -78.371604,
              39.307692
            ],
            [
              -78.367242,
              39.310148
            ],
            [
              -78.364686,
              39.317312
            ],
            [
              -78.361567,
              39.318037
            ],
            [
              -78.360035,
              39.317771
            ],
            [
              -78.35894,
              39.319484
            ],
            [
              -78.346301,
              39.339108
            ],
            [
              -78.34546,
              39.341024
            ],
            [
              -78.347634,
              39.34272
            ],
            [
              -78.347409,
              39.343402
            ],
            [
              -78.343685,
              39.346713
            ],
            [
              -78.342514,
              39.346769
            ],
            [
              -78.341308,
              39.345555
            ],
            [
              -78.339284,
              39.348605
            ],
            [
              -78.338958,
              39.349889
            ],
            [
              -78.34048,
              39.353492
            ],
            [
              -78.348698,
              39.354744
            ],
            [
              -78.362267,
              39.357784
            ],
            [
              -78.366867,
              39.35929
            ],
            [
              -78.362632,
              39.362888
            ],
            [
              -78.354837,
              39.371616
            ],
            [
              -78.343214,
              39.388807
            ],
            [
              -78.350014,
              39.392861
            ],
            [
              -78.349436,
              39.397252
            ],
            [
              -78.357949,
              39.404192
            ],
            [
              -78.359918,
              39.409028
            ],
            [
              -78.359352,
              39.412534
            ],
            [
              -78.356627,
              39.415902
            ],
            [
              -78.351236,
              39.420595
            ],
            [
              -78.348354,
              39.424431
            ],
            [
              -78.346718,
              39.427618
            ],
            [
              -78.347942,
              39.430879
            ],
            [
              -78.353227,
              39.436792
            ],
            [
              -78.347773,
              39.440583
            ],
            [
              -78.346546,
              39.442616
            ],
            [
              -78.346061,
              39.445613
            ],
            [
              -78.347333,
              39.447659
            ],
            [
              -78.346962,
              39.450679
            ],
            [
              -78.345823,
              39.453499
            ],
            [
              -78.345143,
              39.459484
            ],
            [
              -78.349476,
              39.462205
            ],
            [
              -78.347087,
              39.466012
            ],
            [
              -78.437675,
              39.500081
            ],
            [
              -78.468639,
              39.516789
            ],
            [
              -78.471166,
              39.516103
            ],
            [
              -78.474178,
              39.51624
            ],
            [
              -78.480677,
              39.51896
            ],
            [
              -78.484044,
              39.519507
            ],
            [
              -78.485697,
              39.519392
            ],
            [
              -78.489742,
              39.517789
            ],
            [
              -78.499017,
              39.518906
            ],
            [
              -78.5032,
              39.518652
            ],
            [
              -78.513622,
              39.522545
            ],
            [
              -78.521388,
              39.52479
            ],
            [
              -78.527886,
              39.524654
            ],
            [
              -78.546584,
              39.520998
            ],
            [
              -78.550128,
              39.520702
            ],
            [
              -78.552756,
              39.521388
            ],
            [
              -78.557127,
              39.521526
            ],
            [
              -78.565929,
              39.519444
            ],
            [
              -78.56691088080801,
              39.5196679548855
            ],
            [
              -78.567937,
              39.519902
            ],
            [
              -78.572692,
              39.522372
            ],
            [
              -78.578956,
              39.526695
            ],
            [
              -78.587079,
              39.52802
            ],
            [
              -78.5873312363446,
              39.52817324680849
            ],
            [
              -78.590654,
              39.530192
            ],
            [
              -78.592131,
              39.531816
            ],
            [
              -78.593114,
              39.534401
            ],
            [
              -78.593871,
              39.535158
            ],
            [
              -78.595603,
              39.535483
            ],
            [
              -78.597659,
              39.53505
            ],
            [
              -78.600511,
              39.533434
            ],
            [
              -78.605868,
              39.534304
            ],
            [
              -78.606873,
              39.535082
            ],
            [
              -78.614526,
              39.537595
            ],
            [
              -78.623037,
              39.539512
            ],
            [
              -78.628566,
              39.53919
            ],
            [
              -78.628744,
              39.537863
            ],
            [
              -78.630842,
              39.537109
            ],
            [
              -78.655984,
              39.534695
            ],
            [
              -78.6574165631331,
              39.5350677240827
            ],
            [
              -78.703567,
              39.497536
            ],
            [
              -78.729843,
              39.463205
            ],
            [
              -78.767447,
              39.447238
            ],
            [
              -78.789242,
              39.424778
            ],
            [
              -78.791405,
              39.412585
            ],
            [
              -78.810684,
              39.376623
            ],
            [
              -78.852098,
              39.330181
            ],
            [
              -78.883182,
              39.328114
            ],
            [
              -78.922345,
              39.292926
            ],
            [
              -78.926616,
              39.278869
            ],
            [
              -78.959223,
              39.260648
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "041",
      "COUNTYNS": "01550027",
      "AFFGEOID": "0500000US54041",
      "GEOID": "54041",
      "NAME": "Lewis",
      "LSAD": "06",
      "ALAND": 1002071716,
      "AWATER": 7108412,
      "County_state": "Lewis,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.728326,
              39.095679
            ],
            [
              -80.716815,
              39.00843
            ],
            [
              -80.693346,
              38.986289
            ],
            [
              -80.605799,
              38.904167
            ],
            [
              -80.590991,
              38.883679
            ],
            [
              -80.593289,
              38.870418
            ],
            [
              -80.58349,
              38.858871
            ],
            [
              -80.557957,
              38.867609
            ],
            [
              -80.543991,
              38.853817
            ],
            [
              -80.522545,
              38.862347
            ],
            [
              -80.47381,
              38.833229
            ],
            [
              -80.485528,
              38.800304
            ],
            [
              -80.463556,
              38.79604
            ],
            [
              -80.457827,
              38.775493
            ],
            [
              -80.446148,
              38.777514
            ],
            [
              -80.451149,
              38.749146
            ],
            [
              -80.457332,
              38.739169
            ],
            [
              -80.445248,
              38.726848
            ],
            [
              -80.393063,
              38.727571
            ],
            [
              -80.409541,
              38.768327
            ],
            [
              -80.399935,
              38.813817
            ],
            [
              -80.386989,
              38.874808
            ],
            [
              -80.367778,
              38.886613
            ],
            [
              -80.362301,
              38.906775
            ],
            [
              -80.348353,
              38.939901
            ],
            [
              -80.321285,
              38.955721
            ],
            [
              -80.319915,
              38.969515
            ],
            [
              -80.325708,
              38.974261
            ],
            [
              -80.334484,
              38.996586
            ],
            [
              -80.317982,
              39.013441
            ],
            [
              -80.310937,
              39.040187
            ],
            [
              -80.305057,
              39.061467
            ],
            [
              -80.300209,
              39.103857
            ],
            [
              -80.451246,
              39.138475
            ],
            [
              -80.46737,
              39.145678
            ],
            [
              -80.517982,
              39.145989
            ],
            [
              -80.546044,
              39.151257
            ],
            [
              -80.548913,
              39.158755
            ],
            [
              -80.58514,
              39.168499
            ],
            [
              -80.596126,
              39.167069
            ],
            [
              -80.62962,
              39.139239
            ],
            [
              -80.656934,
              39.141119
            ],
            [
              -80.68144,
              39.121504
            ],
            [
              -80.701037,
              39.090278
            ],
            [
              -80.728326,
              39.095679
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "029",
      "COUNTYNS": "01560574",
      "AFFGEOID": "0500000US54029",
      "GEOID": "54029",
      "NAME": "Hancock",
      "LSAD": "06",
      "ALAND": 213957146,
      "AWATER": 13879326,
      "County_state": "Hancock,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.667957,
              40.582496
            ],
            [
              -80.667781,
              40.578096
            ],
            [
              -80.666917,
              40.573664
            ],
            [
              -80.662708,
              40.570176
            ],
            [
              -80.655316,
              40.565184
            ],
            [
              -80.652436,
              40.562544
            ],
            [
              -80.64138,
              40.548417
            ],
            [
              -80.633107,
              40.538705
            ],
            [
              -80.630483,
              40.537921
            ],
            [
              -80.627507,
              40.535793
            ],
            [
              -80.622195,
              40.520497
            ],
            [
              -80.620883,
              40.512257
            ],
            [
              -80.618003,
              40.502049
            ],
            [
              -80.609058,
              40.489506
            ],
            [
              -80.60245,
              40.484226
            ],
            [
              -80.599194,
              40.482566
            ],
            [
              -80.595494,
              40.475266
            ],
            [
              -80.594794,
              40.471366
            ],
            [
              -80.596094,
              40.463366
            ],
            [
              -80.598294,
              40.458366
            ],
            [
              -80.604395,
              40.449767
            ],
            [
              -80.604895,
              40.446667
            ],
            [
              -80.611195,
              40.437767
            ],
            [
              -80.612295,
              40.434867
            ],
            [
              -80.612995,
              40.429367
            ],
            [
              -80.612295,
              40.418567
            ],
            [
              -80.612695,
              40.407667
            ],
            [
              -80.611795,
              40.403867
            ],
            [
              -80.612195,
              40.402667
            ],
            [
              -80.615195,
              40.399867
            ],
            [
              -80.628096,
              40.395867
            ],
            [
              -80.6296993745958,
              40.395006652655894
            ],
            [
              -80.573784,
              40.399755
            ],
            [
              -80.5179873274459,
              40.3996439826186
            ],
            [
              -80.51769,
              40.462467
            ],
            [
              -80.51899,
              40.473667
            ],
            [
              -80.5189953454551,
              40.4773632987013
            ],
            [
              -80.519054,
              40.517922
            ],
            [
              -80.519057,
              40.517922
            ],
            [
              -80.51905584593969,
              40.5596130052828
            ],
            [
              -80.519055,
              40.590173
            ],
            [
              -80.519086,
              40.590161
            ],
            [
              -80.519086,
              40.616385
            ],
            [
              -80.519039,
              40.616391
            ],
            [
              -80.518991,
              40.638801
            ],
            [
              -80.521917,
              40.636992
            ],
            [
              -80.52566,
              40.636068
            ],
            [
              -80.530093,
              40.636255
            ],
            [
              -80.532737,
              40.63559
            ],
            [
              -80.539541,
              40.632122
            ],
            [
              -80.545892,
              40.629702
            ],
            [
              -80.551126,
              40.628847
            ],
            [
              -80.56072,
              40.62368
            ],
            [
              -80.56784,
              40.617552
            ],
            [
              -80.571936,
              40.615456
            ],
            [
              -80.576736,
              40.614224
            ],
            [
              -80.579856,
              40.614304
            ],
            [
              -80.583633,
              40.61552
            ],
            [
              -80.592049,
              40.622496
            ],
            [
              -80.594065,
              40.623664
            ],
            [
              -80.598764,
              40.625263
            ],
            [
              -80.603876,
              40.625064
            ],
            [
              -80.616002,
              40.621696
            ],
            [
              -80.627171,
              40.619936
            ],
            [
              -80.634355,
              40.616095
            ],
            [
              -80.639379,
              40.61128
            ],
            [
              -80.644963,
              40.603648
            ],
            [
              -80.651716,
              40.597744
            ],
            [
              -80.653972,
              40.59648
            ],
            [
              -80.655188,
              40.596544
            ],
            [
              -80.662564,
              40.5916
            ],
            [
              -80.665892,
              40.587728
            ],
            [
              -80.667957,
              40.582496
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "069",
      "COUNTYNS": "01717163",
      "AFFGEOID": "0500000US54069",
      "GEOID": "54069",
      "NAME": "Ohio",
      "LSAD": "06",
      "ALAND": 274098959,
      "AWATER": 8274296,
      "County_state": "Ohio,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.738604,
              40.075672
            ],
            [
              -80.738504,
              40.071472
            ],
            [
              -80.737104,
              40.064972
            ],
            [
              -80.734304,
              40.059672
            ],
            [
              -80.733104,
              40.058772
            ],
            [
              -80.730904,
              40.049172
            ],
            [
              -80.730904,
              40.046672
            ],
            [
              -80.7313963316683,
              40.039122914420005
            ],
            [
              -80.731504,
              40.037472
            ],
            [
              -80.7332673845788,
              40.03335743598289
            ],
            [
              -80.694346,
              40.030569
            ],
            [
              -80.669253,
              40.028626
            ],
            [
              -80.651957,
              40.027342
            ],
            [
              -80.51912,
              40.01641
            ],
            [
              -80.519008,
              40.077001
            ],
            [
              -80.51896,
              40.078089
            ],
            [
              -80.5189913219311,
              40.095834396573295
            ],
            [
              -80.519104,
              40.159672
            ],
            [
              -80.55336,
              40.167444
            ],
            [
              -80.595403,
              40.177017
            ],
            [
              -80.625066,
              40.177228
            ],
            [
              -80.664006,
              40.178947
            ],
            [
              -80.663447,
              40.179448
            ],
            [
              -80.662238,
              40.182323
            ],
            [
              -80.663905,
              40.182663
            ],
            [
              -80.663734,
              40.185897
            ],
            [
              -80.68181217781239,
              40.185566237639705
            ],
            [
              -80.682008,
              40.185495
            ],
            [
              -80.683705,
              40.184215
            ],
            [
              -80.686137,
              40.181607
            ],
            [
              -80.7029242071106,
              40.1572566856078
            ],
            [
              -80.704602,
              40.154823
            ],
            [
              -80.705994,
              40.151591
            ],
            [
              -80.707322,
              40.144999
            ],
            [
              -80.710042,
              40.138311
            ],
            [
              -80.710554,
              40.125271
            ],
            [
              -80.70881,
              40.118088
            ],
            [
              -80.708106,
              40.117256
            ],
            [
              -80.707002,
              40.113272
            ],
            [
              -80.706702,
              40.110872
            ],
            [
              -80.707702,
              40.105372
            ],
            [
              -80.709102,
              40.101472
            ],
            [
              -80.710203,
              40.099572
            ],
            [
              -80.713003,
              40.096872
            ],
            [
              -80.730704,
              40.086472
            ],
            [
              -80.736804,
              40.080072
            ],
            [
              -80.738604,
              40.075672
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "059",
      "COUNTYNS": "01550036",
      "AFFGEOID": "0500000US54059",
      "GEOID": "54059",
      "NAME": "Mingo",
      "LSAD": "06",
      "ALAND": 1095926624,
      "AWATER": 2177833,
      "County_state": "Mingo,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.4213880826174,
              37.8546737766706
            ],
            [
              -82.420484,
              37.847496
            ],
            [
              -82.420484,
              37.846809
            ],
            [
              -82.417367,
              37.845664
            ],
            [
              -82.413153,
              37.845343
            ],
            [
              -82.412172,
              37.844793
            ],
            [
              -82.408941,
              37.836644
            ],
            [
              -82.407874,
              37.835499
            ],
            [
              -82.39968,
              37.829935
            ],
            [
              -82.387769,
              37.818212
            ],
            [
              -82.386586,
              37.818212
            ],
            [
              -82.385259,
              37.81741
            ],
            [
              -82.37958,
              37.810907
            ],
            [
              -82.378514,
              37.80832
            ],
            [
              -82.377393,
              37.803009
            ],
            [
              -82.374867,
              37.80216
            ],
            [
              -82.369973,
              37.801749
            ],
            [
              -82.36778,
              37.800628
            ],
            [
              -82.365557,
              37.798318
            ],
            [
              -82.364979,
              37.796784
            ],
            [
              -82.363795,
              37.795435
            ],
            [
              -82.361199,
              37.794429
            ],
            [
              -82.356122,
              37.793859
            ],
            [
              -82.354275,
              37.793104
            ],
            [
              -82.348849,
              37.787178
            ],
            [
              -82.340455,
              37.786058
            ],
            [
              -82.339705,
              37.785509
            ],
            [
              -82.338377,
              37.780428
            ],
            [
              -82.339097,
              37.778276
            ],
            [
              -82.338895,
              37.776857
            ],
            [
              -82.337596,
              37.775369
            ],
            [
              -82.335981,
              37.7745
            ],
            [
              -82.333903,
              37.766306
            ],
            [
              -82.333816,
              37.765391
            ],
            [
              -82.331162,
              37.763125
            ],
            [
              -82.32946,
              37.762393
            ],
            [
              -82.327356,
              37.762233
            ],
            [
              -82.321012,
              37.755435
            ],
            [
              -82.321415,
              37.751269
            ],
            [
              -82.328221,
              37.74848
            ],
            [
              -82.333581,
              37.743283
            ],
            [
              -82.333349,
              37.7412
            ],
            [
              -82.33304432026151,
              37.7409688362322
            ],
            [
              -82.326834,
              37.736257
            ],
            [
              -82.319686,
              37.734404
            ],
            [
              -82.318879,
              37.733763
            ],
            [
              -82.318302,
              37.733053
            ],
            [
              -82.317869,
              37.72772
            ],
            [
              -82.316197,
              37.721541
            ],
            [
              -82.311702,
              37.718771
            ],
            [
              -82.311097,
              37.717329
            ],
            [
              -82.310665,
              37.7133
            ],
            [
              -82.307235,
              37.707669
            ],
            [
              -82.305679,
              37.706708
            ],
            [
              -82.300954,
              37.706135
            ],
            [
              -82.298074,
              37.704143
            ],
            [
              -82.296634,
              37.702403
            ],
            [
              -82.297325,
              37.699817
            ],
            [
              -82.301964,
              37.696223
            ],
            [
              -82.302886,
              37.693683
            ],
            [
              -82.301504,
              37.690775
            ],
            [
              -82.297788,
              37.687753
            ],
            [
              -82.296262,
              37.687067
            ],
            [
              -82.296118,
              37.686174
            ],
            [
              -82.294737,
              37.678277
            ],
            [
              -82.294392,
              37.677957
            ],
            [
              -82.294134,
              37.673378
            ],
            [
              -82.29471,
              37.672257
            ],
            [
              -82.294393,
              37.670448
            ],
            [
              -82.291773,
              37.669143
            ],
            [
              -82.288174,
              37.668227
            ],
            [
              -82.286446,
              37.670127
            ],
            [
              -82.277876,
              37.669998
            ],
            [
              -82.272021,
              37.663782
            ],
            [
              -82.267962,
              37.662407
            ],
            [
              -82.257111,
              37.656749
            ],
            [
              -82.252273,
              37.656907
            ],
            [
              -82.243911,
              37.660959
            ],
            [
              -82.240773,
              37.661785
            ],
            [
              -82.23939,
              37.661465
            ],
            [
              -82.226111,
              37.653092
            ],
            [
              -82.225535,
              37.651947
            ],
            [
              -82.224956,
              37.647003
            ],
            [
              -82.223602,
              37.644554
            ],
            [
              -82.21934,
              37.642931
            ],
            [
              -82.216691,
              37.641329
            ],
            [
              -82.21669,
              37.639956
            ],
            [
              -82.219078,
              37.635903
            ],
            [
              -82.220257,
              37.634781
            ],
            [
              -82.2202,
              37.633912
            ],
            [
              -82.214815,
              37.627572
            ],
            [
              -82.215649,
              37.626244
            ],
            [
              -82.21349,
              37.625408
            ],
            [
              -82.209691,
              37.625103
            ],
            [
              -82.204337,
              37.625811
            ],
            [
              -82.203388,
              37.626132
            ],
            [
              -82.202467,
              37.627483
            ],
            [
              -82.201201,
              37.627895
            ],
            [
              -82.192394,
              37.625606
            ],
            [
              -82.187298,
              37.626935
            ],
            [
              -82.186694,
              37.627576
            ],
            [
              -82.1858895205048,
              37.6274578192887
            ],
            [
              -82.181398,
              37.626798
            ],
            [
              -82.182438,
              37.623971
            ],
            [
              -82.18143,
              37.621842
            ],
            [
              -82.176682,
              37.618202
            ],
            [
              -82.1692,
              37.613028
            ],
            [
              -82.169057,
              37.609869
            ],
            [
              -82.168137,
              37.608495
            ],
            [
              -82.164454,
              37.608014
            ],
            [
              -82.157609,
              37.596773
            ],
            [
              -82.156718,
              37.59279
            ],
            [
              -82.148434,
              37.59091
            ],
            [
              -82.146419,
              37.59265
            ],
            [
              -82.141555,
              37.595166
            ],
            [
              -82.131977,
              37.593537
            ],
            [
              -82.130165,
              37.591544
            ],
            [
              -82.127321,
              37.586667
            ],
            [
              -82.125601,
              37.579021
            ],
            [
              -82.124307,
              37.577806
            ],
            [
              -82.124372,
              37.57641
            ],
            [
              -82.116584,
              37.559588
            ],
            [
              -82.103127,
              37.560097
            ],
            [
              -82.102263,
              37.559456
            ],
            [
              -82.101946,
              37.558106
            ],
            [
              -82.089178,
              37.556004
            ],
            [
              -82.084605,
              37.55541
            ],
            [
              -82.07503,
              37.555824
            ],
            [
              -82.073246,
              37.555023
            ],
            [
              -82.064418,
              37.544516
            ],
            [
              -82.063671,
              37.541929
            ],
            [
              -82.064792,
              37.539021
            ],
            [
              -82.064418,
              37.53687
            ],
            [
              -82.063326,
              37.536206
            ],
            [
              -82.061256,
              37.536001
            ],
            [
              -82.05746,
              37.536893
            ],
            [
              -82.054787,
              37.536756
            ],
            [
              -82.04481,
              37.541814
            ],
            [
              -82.045155,
              37.544516
            ],
            [
              -82.04478,
              37.546713
            ],
            [
              -82.042825,
              37.548361
            ],
            [
              -82.038972,
              37.547926
            ],
            [
              -82.038024,
              37.546918
            ],
            [
              -82.036932,
              37.542729
            ],
            [
              -82.028826,
              37.537667
            ],
            [
              -82.025261,
              37.538261
            ],
            [
              -82.021006,
              37.540526
            ],
            [
              -82.018878,
              37.540572
            ],
            [
              -82.016925,
              37.538556
            ],
            [
              -82.01592,
              37.534321
            ],
            [
              -82.013966,
              37.533564
            ],
            [
              -82.009194,
              37.533243
            ],
            [
              -82.007412,
              37.533677
            ],
            [
              -81.994033,
              37.537612
            ],
            [
              -81.992597,
              37.538323
            ],
            [
              -81.989092,
              37.542514
            ],
            [
              -81.987511,
              37.542835
            ],
            [
              -81.982479,
              37.541807
            ],
            [
              -81.980841,
              37.542357
            ],
            [
              -81.976386,
              37.545426
            ],
            [
              -81.970147,
              37.546504
            ],
            [
              -81.964971,
              37.543026
            ],
            [
              -81.965401,
              37.541171
            ],
            [
              -81.968297,
              37.537798
            ],
            [
              -81.969279,
              37.534325
            ],
            [
              -81.967583,
              37.532815
            ],
            [
              -81.959362,
              37.53522
            ],
            [
              -81.957379,
              37.535198
            ],
            [
              -81.956947,
              37.534259
            ],
            [
              -81.957436,
              37.533206
            ],
            [
              -81.957693,
              37.529841
            ],
            [
              -81.95663,
              37.52849
            ],
            [
              -81.953524,
              37.528056
            ],
            [
              -81.946022,
              37.531742
            ],
            [
              -81.94401,
              37.530964
            ],
            [
              -81.943981,
              37.5303
            ],
            [
              -81.947545,
              37.52753
            ],
            [
              -81.94766,
              37.52508
            ],
            [
              -81.947085,
              37.523913
            ],
            [
              -81.943693,
              37.521212
            ],
            [
              -81.933088,
              37.518968
            ],
            [
              -81.926391,
              37.514207
            ],
            [
              -81.926736,
              37.51304
            ],
            [
              -81.9277586640962,
              37.5122085217842
            ],
            [
              -81.922848,
              37.511874
            ],
            [
              -81.909374,
              37.527041
            ],
            [
              -81.855939,
              37.54891
            ],
            [
              -81.855839,
              37.561809
            ],
            [
              -81.834238,
              37.569509
            ],
            [
              -81.828222,
              37.597989
            ],
            [
              -81.801537,
              37.615309
            ],
            [
              -81.795537,
              37.631509
            ],
            [
              -81.802937,
              37.660508
            ],
            [
              -81.814438,
              37.655309
            ],
            [
              -81.838039,
              37.672108
            ],
            [
              -81.850284,
              37.66437
            ],
            [
              -81.865024,
              37.678909
            ],
            [
              -81.87484,
              37.670108
            ],
            [
              -81.902341,
              37.661208
            ],
            [
              -81.914042,
              37.680407
            ],
            [
              -81.930542,
              37.681907
            ],
            [
              -81.944826,
              37.671219
            ],
            [
              -81.979444,
              37.668107
            ],
            [
              -81.991937,
              37.647117
            ],
            [
              -81.993444,
              37.640607
            ],
            [
              -81.999077,
              37.649915
            ],
            [
              -82.022647,
              37.668151
            ],
            [
              -82.041254,
              37.701589
            ],
            [
              -82.082648,
              37.701565
            ],
            [
              -82.087938,
              37.73231
            ],
            [
              -82.088395,
              37.732381
            ],
            [
              -82.106618,
              37.750448
            ],
            [
              -82.11704,
              37.747606
            ],
            [
              -82.100361,
              37.766947
            ],
            [
              -82.105819,
              37.801142
            ],
            [
              -82.132219,
              37.809705
            ],
            [
              -82.133639,
              37.826251
            ],
            [
              -82.145915,
              37.83936
            ],
            [
              -82.11222,
              37.859418
            ],
            [
              -82.128809,
              37.87785
            ],
            [
              -82.125405,
              37.8994
            ],
            [
              -82.16368,
              37.912372
            ],
            [
              -82.181231,
              37.950284
            ],
            [
              -82.197169,
              37.962696
            ],
            [
              -82.190658,
              37.974778
            ],
            [
              -82.305779,
              37.943803
            ],
            [
              -82.306391,
              37.94364
            ],
            [
              -82.403743,
              37.862894
            ],
            [
              -82.394445,
              37.84242
            ],
            [
              -82.4213880826174,
              37.8546737766706
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "065",
      "COUNTYNS": "01557950",
      "AFFGEOID": "0500000US54065",
      "GEOID": "54065",
      "NAME": "Morgan",
      "LSAD": "06",
      "ALAND": 593295476,
      "AWATER": 1401570,
      "County_state": "Morgan,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.462899,
              39.52084
            ],
            [
              -78.468639,
              39.516789
            ],
            [
              -78.437675,
              39.500081
            ],
            [
              -78.347087,
              39.466012
            ],
            [
              -78.34703569532559,
              39.465979661051094
            ],
            [
              -78.28798,
              39.428755
            ],
            [
              -78.262785,
              39.414323
            ],
            [
              -78.228766,
              39.391233
            ],
            [
              -78.227295,
              39.395003
            ],
            [
              -78.198727,
              39.445761
            ],
            [
              -78.173926,
              39.521355
            ],
            [
              -78.154274,
              39.563723
            ],
            [
              -78.138937,
              39.593796
            ],
            [
              -78.124064,
              39.593803
            ],
            [
              -78.094794,
              39.582204
            ],
            [
              -78.079959,
              39.59972
            ],
            [
              -78.059342,
              39.598294
            ],
            [
              -78.0224018601498,
              39.619600466954395
            ],
            [
              -78.023896,
              39.621697
            ],
            [
              -78.03014,
              39.627462
            ],
            [
              -78.035992,
              39.63572
            ],
            [
              -78.03886,
              39.638121
            ],
            [
              -78.0456843725835,
              39.641982361972396
            ],
            [
              -78.047672,
              39.643107
            ],
            [
              -78.04995,
              39.645349
            ],
            [
              -78.051932,
              39.648207
            ],
            [
              -78.068291,
              39.66106
            ],
            [
              -78.06898805131749,
              39.6616820829176
            ],
            [
              -78.074595,
              39.666686
            ],
            [
              -78.077525,
              39.66888
            ],
            [
              -78.08226,
              39.671166
            ],
            [
              -78.088592,
              39.671211
            ],
            [
              -78.089835,
              39.671668
            ],
            [
              -78.097118,
              39.678161
            ],
            [
              -78.101737,
              39.680286
            ],
            [
              -78.107834,
              39.682137
            ],
            [
              -78.11183,
              39.682593
            ],
            [
              -78.123939,
              39.685652
            ],
            [
              -78.132706,
              39.686977
            ],
            [
              -78.135221,
              39.688305
            ],
            [
              -78.143478,
              39.690412
            ],
            [
              -78.154164,
              39.690531
            ],
            [
              -78.162126,
              39.693643
            ],
            [
              -78.171361,
              39.695612
            ],
            [
              -78.176625,
              39.695967
            ],
            [
              -78.182759,
              39.69511
            ],
            [
              -78.191107,
              39.690262
            ],
            [
              -78.192439,
              39.689118
            ],
            [
              -78.196701,
              39.682074
            ],
            [
              -78.201081,
              39.677866
            ],
            [
              -78.202945,
              39.676653
            ],
            [
              -78.206763,
              39.67599
            ],
            [
              -78.227333,
              39.676121
            ],
            [
              -78.231564,
              39.674382
            ],
            [
              -78.233138,
              39.672875
            ],
            [
              -78.233012,
              39.670471
            ],
            [
              -78.223864,
              39.662607
            ],
            [
              -78.223597,
              39.661097
            ],
            [
              -78.225075,
              39.658878
            ],
            [
              -78.227677,
              39.656796
            ],
            [
              -78.238059,
              39.652081
            ],
            [
              -78.246722,
              39.644758
            ],
            [
              -78.254077,
              39.640089
            ],
            [
              -78.262189,
              39.630464
            ],
            [
              -78.263344,
              39.626417
            ],
            [
              -78.263371,
              39.621675
            ],
            [
              -78.265088,
              39.619274
            ],
            [
              -78.266833,
              39.618818
            ],
            [
              -78.271122,
              39.619642
            ],
            [
              -78.283039,
              39.62047
            ],
            [
              -78.308152,
              39.629606
            ],
            [
              -78.313033,
              39.631001
            ],
            [
              -78.331934,
              39.636054
            ],
            [
              -78.33388026831301,
              39.6364859193412
            ],
            [
              -78.351905,
              39.640486
            ],
            [
              -78.355218,
              39.640576
            ],
            [
              -78.358264,
              39.63966
            ],
            [
              -78.359506,
              39.638081
            ],
            [
              -78.358735,
              39.635589
            ],
            [
              -78.355567,
              39.633463
            ],
            [
              -78.353673,
              39.630787
            ],
            [
              -78.353465,
              39.628912
            ],
            [
              -78.353878,
              39.627722
            ],
            [
              -78.35577,
              39.626258
            ],
            [
              -78.358343,
              39.625581
            ],
            [
              -78.362485,
              39.626049
            ],
            [
              -78.366212,
              39.627534
            ],
            [
              -78.367959,
              39.628929
            ],
            [
              -78.373166,
              39.630459
            ],
            [
              -78.380504,
              39.629359
            ],
            [
              -78.382487,
              39.628216
            ],
            [
              -78.383447,
              39.625091
            ],
            [
              -78.383461,
              39.623321
            ],
            [
              -78.382959,
              39.622246
            ],
            [
              -78.379118,
              39.618127
            ],
            [
              -78.372404,
              39.612297
            ],
            [
              -78.372255,
              39.6112
            ],
            [
              -78.3732,
              39.60953
            ],
            [
              -78.374732,
              39.608635
            ],
            [
              -78.378181,
              39.608178
            ],
            [
              -78.383591,
              39.608912
            ],
            [
              -78.390774,
              39.612117
            ],
            [
              -78.395828,
              39.616076
            ],
            [
              -78.41286,
              39.621091
            ],
            [
              -78.420549,
              39.624021
            ],
            [
              -78.425902,
              39.624548
            ],
            [
              -78.43025,
              39.62329
            ],
            [
              -78.433297,
              39.620569
            ],
            [
              -78.433623,
              39.618259
            ],
            [
              -78.433002,
              39.61652
            ],
            [
              -78.431524,
              39.614484
            ],
            [
              -78.425581,
              39.607599
            ],
            [
              -78.420644,
              39.603183
            ],
            [
              -78.41287,
              39.598311
            ],
            [
              -78.402702,
              39.593596
            ],
            [
              -78.397471,
              39.590232
            ],
            [
              -78.395463,
              39.587372
            ],
            [
              -78.395317,
              39.584215
            ],
            [
              -78.397446,
              39.581952
            ],
            [
              -78.400936,
              39.580214
            ],
            [
              -78.408031,
              39.578593
            ],
            [
              -78.41867,
              39.581111
            ],
            [
              -78.420059,
              39.581706
            ],
            [
              -78.422985,
              39.584109
            ],
            [
              -78.428246,
              39.586717
            ],
            [
              -78.443175,
              39.591155
            ],
            [
              -78.445983,
              39.591223
            ],
            [
              -78.451186,
              39.590193
            ],
            [
              -78.454527,
              39.588958
            ],
            [
              -78.457187,
              39.587379
            ],
            [
              -78.458052,
              39.585241
            ],
            [
              -78.458338,
              39.580426
            ],
            [
              -78.454376,
              39.574319
            ],
            [
              -78.450207,
              39.570889
            ],
            [
              -78.438179,
              39.563524
            ],
            [
              -78.426537,
              39.559155
            ],
            [
              -78.423287,
              39.556319
            ],
            [
              -78.420019,
              39.551745
            ],
            [
              -78.418777,
              39.548953
            ],
            [
              -78.419398,
              39.547401
            ],
            [
              -78.421105,
              39.54678
            ],
            [
              -78.424053,
              39.546315
            ],
            [
              -78.426953,
              39.546598
            ],
            [
              -78.430414,
              39.549418
            ],
            [
              -78.433828,
              39.548953
            ],
            [
              -78.434759,
              39.54678
            ],
            [
              -78.434759,
              39.543988
            ],
            [
              -78.435107,
              39.541658
            ],
            [
              -78.436378,
              39.539302
            ],
            [
              -78.438357,
              39.538753
            ],
            [
              -78.441961,
              39.541223
            ],
            [
              -78.445309,
              39.543367
            ],
            [
              -78.447171,
              39.543367
            ],
            [
              -78.449499,
              39.542281
            ],
            [
              -78.449964,
              39.54104
            ],
            [
              -78.449654,
              39.539643
            ],
            [
              -78.449499,
              39.538092
            ],
            [
              -78.45105,
              39.536695
            ],
            [
              -78.459274,
              39.535919
            ],
            [
              -78.461291,
              39.534678
            ],
            [
              -78.461911,
              39.532971
            ],
            [
              -78.461071,
              39.529304
            ],
            [
              -78.460951,
              39.525987
            ],
            [
              -78.462899,
              39.52084
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "023",
      "COUNTYNS": "01697238",
      "AFFGEOID": "0500000US54023",
      "GEOID": "54023",
      "NAME": "Grant",
      "LSAD": "06",
      "ALAND": 1236390684,
      "AWATER": 7545524,
      "County_state": "Grant,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.486862069471,
              39.20595874752019
            ],
            [
              -79.487175,
              39.194906
            ],
            [
              -79.467474,
              39.178807
            ],
            [
              -79.442572,
              39.186307
            ],
            [
              -79.39287,
              39.192907
            ],
            [
              -79.382369,
              39.203706
            ],
            [
              -79.365068,
              39.199406
            ],
            [
              -79.345066,
              39.213806
            ],
            [
              -79.307361,
              39.209803
            ],
            [
              -79.289264,
              39.189706
            ],
            [
              -79.308566,
              39.182005
            ],
            [
              -79.331766,
              39.147807
            ],
            [
              -79.331305,
              39.118839
            ],
            [
              -79.346632,
              39.107754
            ],
            [
              -79.355468,
              39.086508
            ],
            [
              -79.334094,
              39.082656
            ],
            [
              -79.314705,
              39.07025
            ],
            [
              -79.298164,
              39.07321
            ],
            [
              -79.310244,
              39.030293
            ],
            [
              -79.340667,
              38.969609
            ],
            [
              -79.357666,
              38.964509
            ],
            [
              -79.349867,
              38.957509
            ],
            [
              -79.177797,
              38.842764
            ],
            [
              -79.134296,
              38.81334
            ],
            [
              -79.112556,
              38.835012
            ],
            [
              -79.080755,
              38.877011
            ],
            [
              -79.086054,
              38.893911
            ],
            [
              -79.074055,
              38.899011
            ],
            [
              -79.046053,
              38.92841
            ],
            [
              -79.081755,
              39.000109
            ],
            [
              -79.077755,
              39.021809
            ],
            [
              -79.089655,
              39.038208
            ],
            [
              -79.056848,
              39.091675
            ],
            [
              -79.02877,
              39.14176
            ],
            [
              -79.036077,
              39.150002
            ],
            [
              -79.00155,
              39.195204
            ],
            [
              -78.979898,
              39.237624
            ],
            [
              -79.134456,
              39.312501
            ],
            [
              -79.237927,
              39.322104
            ],
            [
              -79.2722802951283,
              39.3289642342356
            ],
            [
              -79.282037,
              39.323048
            ],
            [
              -79.283723,
              39.30964
            ],
            [
              -79.290236,
              39.299323
            ],
            [
              -79.29271,
              39.298729
            ],
            [
              -79.302311,
              39.299554
            ],
            [
              -79.314768,
              39.304381
            ],
            [
              -79.33238,
              39.299919
            ],
            [
              -79.344344,
              39.293534
            ],
            [
              -79.345599,
              39.289733
            ],
            [
              -79.343625,
              39.287148
            ],
            [
              -79.343801,
              39.286096
            ],
            [
              -79.35375,
              39.278039
            ],
            [
              -79.361343,
              39.274924
            ],
            [
              -79.376154,
              39.273154
            ],
            [
              -79.387023,
              39.26554
            ],
            [
              -79.412051,
              39.240546
            ],
            [
              -79.42035,
              39.23888
            ],
            [
              -79.425059,
              39.233686
            ],
            [
              -79.424413,
              39.228171
            ],
            [
              -79.43983,
              39.217074
            ],
            [
              -79.452685,
              39.211719
            ],
            [
              -79.469156,
              39.2073
            ],
            [
              -79.476037,
              39.203728
            ],
            [
              -79.486862069471,
              39.20595874752019
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "037",
      "COUNTYNS": "01550025",
      "AFFGEOID": "0500000US54037",
      "GEOID": "54037",
      "NAME": "Jefferson",
      "LSAD": "06",
      "ALAND": 543006589,
      "AWATER": 5216623,
      "County_state": "Jefferson,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.035454,
              39.278224
            ],
            [
              -78.0331865984578,
              39.2646218054158
            ],
            [
              -78.0331853088999,
              39.2646209889705
            ],
            [
              -78.032841,
              39.264403
            ],
            [
              -77.82830183326729,
              39.1324224548325
            ],
            [
              -77.828157,
              39.132329
            ],
            [
              -77.822182,
              39.139985
            ],
            [
              -77.82223,
              39.142734
            ],
            [
              -77.82299,
              39.145451
            ],
            [
              -77.822874,
              39.147755
            ],
            [
              -77.821413,
              39.15241
            ],
            [
              -77.818446,
              39.155279
            ],
            [
              -77.813206,
              39.165023
            ],
            [
              -77.811295,
              39.167563
            ],
            [
              -77.809125,
              39.168567
            ],
            [
              -77.805991,
              39.172421
            ],
            [
              -77.805099,
              39.174222
            ],
            [
              -77.804415,
              39.178045
            ],
            [
              -77.804712,
              39.179419
            ],
            [
              -77.797943,
              39.192826
            ],
            [
              -77.797714,
              39.19424
            ],
            [
              -77.798478,
              39.199574
            ],
            [
              -77.79819,
              39.200658
            ],
            [
              -77.794596,
              39.206299
            ],
            [
              -77.793631,
              39.210125
            ],
            [
              -77.788763,
              39.215243
            ],
            [
              -77.78364,
              39.224081
            ],
            [
              -77.781268,
              39.226909
            ],
            [
              -77.778068,
              39.229305
            ],
            [
              -77.771415,
              39.236776
            ],
            [
              -77.7709401864601,
              39.2382222421114
            ],
            [
              -77.767277,
              39.24938
            ],
            [
              -77.770589,
              39.249393
            ],
            [
              -77.770876,
              39.24976
            ],
            [
              -77.770669,
              39.255262
            ],
            [
              -77.770281,
              39.255977
            ],
            [
              -77.768992,
              39.256417
            ],
            [
              -77.768,
              39.257657
            ],
            [
              -77.766525,
              39.25734
            ],
            [
              -77.762844,
              39.258445
            ],
            [
              -77.761768,
              39.263031
            ],
            [
              -77.761217,
              39.263721
            ],
            [
              -77.758733,
              39.268114
            ],
            [
              -77.758412,
              39.269197
            ],
            [
              -77.755698,
              39.274575
            ],
            [
              -77.755193,
              39.275191
            ],
            [
              -77.753105,
              39.27734
            ],
            [
              -77.75306,
              39.277971
            ],
            [
              -77.753357,
              39.280331
            ],
            [
              -77.752726,
              39.283373
            ],
            [
              -77.750267,
              39.289284
            ],
            [
              -77.747287,
              39.295001
            ],
            [
              -77.734899,
              39.312409
            ],
            [
              -77.730047,
              39.315666
            ],
            [
              -77.721638,
              39.318494
            ],
            [
              -77.719946,
              39.319693
            ],
            [
              -77.719519,
              39.321314
            ],
            [
              -77.727379,
              39.321666
            ],
            [
              -77.730914,
              39.324684
            ],
            [
              -77.735009,
              39.327015
            ],
            [
              -77.755789,
              39.333899
            ],
            [
              -77.759615,
              39.337331
            ],
            [
              -77.761115,
              39.339757
            ],
            [
              -77.761084,
              39.342524
            ],
            [
              -77.760435,
              39.344171
            ],
            [
              -77.759315,
              39.345314
            ],
            [
              -77.750387,
              39.34945
            ],
            [
              -77.74593,
              39.353221
            ],
            [
              -77.743874,
              39.359947
            ],
            [
              -77.744144,
              39.365139
            ],
            [
              -77.753274,
              39.37832
            ],
            [
              -77.753804,
              39.379624
            ],
            [
              -77.753389,
              39.382094
            ],
            [
              -77.752209,
              39.383328
            ],
            [
              -77.749715,
              39.384171
            ],
            [
              -77.740765,
              39.385409
            ],
            [
              -77.738084,
              39.386211
            ],
            [
              -77.736317,
              39.387744
            ],
            [
              -77.735905,
              39.389665
            ],
            [
              -77.736409,
              39.392684
            ],
            [
              -77.740012,
              39.401694
            ],
            [
              -77.747478,
              39.41093
            ],
            [
              -77.75268,
              39.420174
            ],
            [
              -77.75309,
              39.423262
            ],
            [
              -77.754681,
              39.424658
            ],
            [
              -77.75872,
              39.42681
            ],
            [
              -77.763319,
              39.428436
            ],
            [
              -77.77485,
              39.427845
            ],
            [
              -77.783847,
              39.430456
            ],
            [
              -77.786768,
              39.435442
            ],
            [
              -77.786052,
              39.444224
            ],
            [
              -77.78558,
              39.445367
            ],
            [
              -77.78611,
              39.447197
            ],
            [
              -77.780471,
              39.459867
            ],
            [
              -77.779202,
              39.460392
            ],
            [
              -77.777815,
              39.461924
            ],
            [
              -77.777815,
              39.462816
            ],
            [
              -77.778522,
              39.463663
            ],
            [
              -77.789645,
              39.467827
            ],
            [
              -77.795634,
              39.471259
            ],
            [
              -77.796755,
              39.472448
            ],
            [
              -77.798201,
              39.475719
            ],
            [
              -77.797787,
              39.47876
            ],
            [
              -77.796695,
              39.480498
            ],
            [
              -77.795485,
              39.481824
            ],
            [
              -77.788519,
              39.485048
            ],
            [
              -77.78176,
              39.487128
            ],
            [
              -77.771723,
              39.489207
            ],
            [
              -77.769125,
              39.490281
            ],
            [
              -77.767087,
              39.491333
            ],
            [
              -77.765551,
              39.493025
            ],
            [
              -77.765403,
              39.494397
            ],
            [
              -77.765993,
              39.495724
            ],
            [
              -77.768442,
              39.497783
            ],
            [
              -77.77095,
              39.499087
            ],
            [
              -77.774374,
              39.4995
            ],
            [
              -77.781608,
              39.499067
            ],
            [
              -77.784442,
              39.498061
            ],
            [
              -77.786539,
              39.496598
            ],
            [
              -77.807821,
              39.490241
            ],
            [
              -77.820781,
              39.4939
            ],
            [
              -77.8254985217078,
              39.4942577990943
            ],
            [
              -77.84714,
              39.446557
            ],
            [
              -77.885254,
              39.41694
            ],
            [
              -77.930868,
              39.38117
            ],
            [
              -77.947056,
              39.380485
            ],
            [
              -77.966953,
              39.367164
            ],
            [
              -77.996752,
              39.311631
            ],
            [
              -78.035454,
              39.278224
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "067",
      "COUNTYNS": "01550040",
      "AFFGEOID": "0500000US54067",
      "GEOID": "54067",
      "NAME": "Nicholas",
      "LSAD": "06",
      "ALAND": 1675296015,
      "AWATER": 19608977,
      "County_state": "Nicholas,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.231434,
              38.263716
            ],
            [
              -81.231636,
              38.263514
            ],
            [
              -81.204275,
              38.248883
            ],
            [
              -81.192285,
              38.249368
            ],
            [
              -81.178553,
              38.233438
            ],
            [
              -81.152532,
              38.226401
            ],
            [
              -81.133427,
              38.235223
            ],
            [
              -81.110385,
              38.212923
            ],
            [
              -81.084381,
              38.204104
            ],
            [
              -81.075039,
              38.196136
            ],
            [
              -81.070465,
              38.210103
            ],
            [
              -81.050716,
              38.21327
            ],
            [
              -81.057581,
              38.222827
            ],
            [
              -81.027696,
              38.236088
            ],
            [
              -81.034686,
              38.220558
            ],
            [
              -81.007497,
              38.205634
            ],
            [
              -81.007667,
              38.221364
            ],
            [
              -80.983472,
              38.22254
            ],
            [
              -80.968825,
              38.214971
            ],
            [
              -80.944073,
              38.193702
            ],
            [
              -80.949766,
              38.168014
            ],
            [
              -80.901184,
              38.122513
            ],
            [
              -80.882478,
              38.133552
            ],
            [
              -80.882187,
              38.103457
            ],
            [
              -80.749813,
              38.086306
            ],
            [
              -80.711961,
              38.079129
            ],
            [
              -80.630361,
              38.132804
            ],
            [
              -80.586191,
              38.162593
            ],
            [
              -80.532658,
              38.198595
            ],
            [
              -80.436514,
              38.267334
            ],
            [
              -80.609378,
              38.358606
            ],
            [
              -80.612755,
              38.366983
            ],
            [
              -80.62552,
              38.354392
            ],
            [
              -80.635422,
              38.375827
            ],
            [
              -80.661668,
              38.41251
            ],
            [
              -80.640725,
              38.424173
            ],
            [
              -80.650471,
              38.44532
            ],
            [
              -80.678291,
              38.503492
            ],
            [
              -80.650786,
              38.52659
            ],
            [
              -80.71635,
              38.536858
            ],
            [
              -80.751065,
              38.525771
            ],
            [
              -80.760294,
              38.541512
            ],
            [
              -80.785177,
              38.557649
            ],
            [
              -80.881232,
              38.507045
            ],
            [
              -80.864608,
              38.500102
            ],
            [
              -80.825292,
              38.494349
            ],
            [
              -80.817424,
              38.478553
            ],
            [
              -80.911361,
              38.414785
            ],
            [
              -81.04201,
              38.352712
            ],
            [
              -81.098788,
              38.326032
            ],
            [
              -81.231434,
              38.263716
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "079",
      "COUNTYNS": "01550046",
      "AFFGEOID": "0500000US54079",
      "GEOID": "54079",
      "NAME": "Putnam",
      "LSAD": "06",
      "ALAND": 895302287,
      "AWATER": 12226982,
      "County_state": "Putnam,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.064562,
              38.607985
            ],
            [
              -82.055127,
              38.474547
            ],
            [
              -82.05201,
              38.426513
            ],
            [
              -82.047128,
              38.374432
            ],
            [
              -82.043094,
              38.337278
            ],
            [
              -82.048852,
              38.290342
            ],
            [
              -81.997406,
              38.266605
            ],
            [
              -81.991151,
              38.275772
            ],
            [
              -81.982094,
              38.276701
            ],
            [
              -81.915197,
              38.325597
            ],
            [
              -81.903521,
              38.382968
            ],
            [
              -81.899359,
              38.399088
            ],
            [
              -81.88026,
              38.408235
            ],
            [
              -81.850797,
              38.421932
            ],
            [
              -81.840488,
              38.426099
            ],
            [
              -81.825637,
              38.430552
            ],
            [
              -81.822348,
              38.430332
            ],
            [
              -81.727228,
              38.466116
            ],
            [
              -81.726378,
              38.475548
            ],
            [
              -81.742237,
              38.517496
            ],
            [
              -81.754069,
              38.523956
            ],
            [
              -81.747164,
              38.553563
            ],
            [
              -81.7559,
              38.580944
            ],
            [
              -81.738968,
              38.599326
            ],
            [
              -81.728775,
              38.596219
            ],
            [
              -81.696348,
              38.626427
            ],
            [
              -81.747024,
              38.683391
            ],
            [
              -81.749852,
              38.683821
            ],
            [
              -81.772843,
              38.680843
            ],
            [
              -81.951411,
              38.657902
            ],
            [
              -81.957338,
              38.656644
            ],
            [
              -82.064562,
              38.607985
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "109",
      "COUNTYNS": "01550061",
      "AFFGEOID": "0500000US54109",
      "GEOID": "54109",
      "NAME": "Wyoming",
      "LSAD": "06",
      "ALAND": 1293580739,
      "AWATER": 6123514,
      "County_state": "Wyoming,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.855839,
              37.561809
            ],
            [
              -81.855939,
              37.54891
            ],
            [
              -81.826437,
              37.535409
            ],
            [
              -81.817937,
              37.50661
            ],
            [
              -81.773268,
              37.505111
            ],
            [
              -81.707833,
              37.495306
            ],
            [
              -81.704032,
              37.500511
            ],
            [
              -81.690365,
              37.503768
            ],
            [
              -81.688332,
              37.514111
            ],
            [
              -81.660431,
              37.521512
            ],
            [
              -81.63393,
              37.510012
            ],
            [
              -81.624575,
              37.518267
            ],
            [
              -81.602034,
              37.493113
            ],
            [
              -81.560432,
              37.489624
            ],
            [
              -81.55345,
              37.491805
            ],
            [
              -81.527615,
              37.475793
            ],
            [
              -81.518076,
              37.478091
            ],
            [
              -81.496757,
              37.473996
            ],
            [
              -81.490278,
              37.48113
            ],
            [
              -81.460623,
              37.483406
            ],
            [
              -81.450215,
              37.476211
            ],
            [
              -81.456056,
              37.469641
            ],
            [
              -81.438099,
              37.45427
            ],
            [
              -81.431805,
              37.448621
            ],
            [
              -81.398268,
              37.464479
            ],
            [
              -81.374499,
              37.443371
            ],
            [
              -81.311201,
              37.424509
            ],
            [
              -81.292919,
              37.413178
            ],
            [
              -81.282599,
              37.419797
            ],
            [
              -81.307387,
              37.459486
            ],
            [
              -81.278521,
              37.472177
            ],
            [
              -81.263719,
              37.494939
            ],
            [
              -81.246407,
              37.501941
            ],
            [
              -81.24042,
              37.506416
            ],
            [
              -81.222121,
              37.510416
            ],
            [
              -81.253101,
              37.532976
            ],
            [
              -81.261473,
              37.527499
            ],
            [
              -81.311575,
              37.552671
            ],
            [
              -81.310323,
              37.576614
            ],
            [
              -81.321717,
              37.597796
            ],
            [
              -81.396525,
              37.705695
            ],
            [
              -81.423941,
              37.743995
            ],
            [
              -81.458027,
              37.784211
            ],
            [
              -81.476628,
              37.780811
            ],
            [
              -81.514228,
              37.791211
            ],
            [
              -81.530429,
              37.78971
            ],
            [
              -81.565794,
              37.767642
            ],
            [
              -81.57653,
              37.76331
            ],
            [
              -81.607532,
              37.788709
            ],
            [
              -81.638033,
              37.777609
            ],
            [
              -81.657134,
              37.779809
            ],
            [
              -81.675135,
              37.772709
            ],
            [
              -81.693835,
              37.774108
            ],
            [
              -81.707436,
              37.763508
            ],
            [
              -81.742736,
              37.764208
            ],
            [
              -81.764537,
              37.751708
            ],
            [
              -81.719223,
              37.715497
            ],
            [
              -81.701734,
              37.701409
            ],
            [
              -81.712249,
              37.699085
            ],
            [
              -81.793037,
              37.670909
            ],
            [
              -81.802937,
              37.660508
            ],
            [
              -81.795537,
              37.631509
            ],
            [
              -81.801537,
              37.615309
            ],
            [
              -81.828222,
              37.597989
            ],
            [
              -81.834238,
              37.569509
            ],
            [
              -81.855839,
              37.561809
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "003",
      "COUNTYNS": "01550008",
      "AFFGEOID": "0500000US54003",
      "GEOID": "54003",
      "NAME": "Berkeley",
      "LSAD": "06",
      "ALAND": 831766640,
      "AWATER": 1061824,
      "County_state": "Berkeley,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -78.227295,
              39.395003
            ],
            [
              -78.228766,
              39.391233
            ],
            [
              -78.205401,
              39.375099
            ],
            [
              -78.18737,
              39.363989
            ],
            [
              -78.158194,
              39.343392
            ],
            [
              -78.14092,
              39.333745
            ],
            [
              -78.108746,
              39.31246
            ],
            [
              -78.0816712960184,
              39.295318453998505
            ],
            [
              -78.0800128545788,
              39.2942684609775
            ],
            [
              -78.0331865984578,
              39.2646218054158
            ],
            [
              -78.035454,
              39.278224
            ],
            [
              -77.996752,
              39.311631
            ],
            [
              -77.966953,
              39.367164
            ],
            [
              -77.947056,
              39.380485
            ],
            [
              -77.930868,
              39.38117
            ],
            [
              -77.885254,
              39.41694
            ],
            [
              -77.84714,
              39.446557
            ],
            [
              -77.8254985217078,
              39.4942577990943
            ],
            [
              -77.831909,
              39.494744
            ],
            [
              -77.845666,
              39.498628
            ],
            [
              -77.847639,
              39.500698
            ],
            [
              -77.8478531897793,
              39.5013297013575
            ],
            [
              -77.848112,
              39.502093
            ],
            [
              -77.847611,
              39.503351
            ],
            [
              -77.845103,
              39.505845
            ],
            [
              -77.829045,
              39.514425
            ],
            [
              -77.82565,
              39.516895
            ],
            [
              -77.823555,
              39.524077
            ],
            [
              -77.823762,
              39.525907
            ],
            [
              -77.825357,
              39.529177
            ],
            [
              -77.827188,
              39.530458
            ],
            [
              -77.833509,
              39.532628
            ],
            [
              -77.836935,
              39.53217
            ],
            [
              -77.839061,
              39.531117
            ],
            [
              -77.840536,
              39.529196
            ],
            [
              -77.840651,
              39.520941
            ],
            [
              -77.84192,
              39.51847
            ],
            [
              -77.850747,
              39.515403
            ],
            [
              -77.860195,
              39.514325
            ],
            [
              -77.86368,
              39.515032
            ],
            [
              -77.8649397818319,
              39.5163827204063
            ],
            [
              -77.866132,
              39.517661
            ],
            [
              -77.866518,
              39.520039
            ],
            [
              -77.866138,
              39.524727
            ],
            [
              -77.865078,
              39.528226
            ],
            [
              -77.864315,
              39.534813
            ],
            [
              -77.864434,
              39.536483
            ],
            [
              -77.865351,
              39.538381
            ],
            [
              -77.87153,
              39.544278
            ],
            [
              -77.886436,
              39.551947
            ],
            [
              -77.888945,
              39.55595
            ],
            [
              -77.888648,
              39.558054
            ],
            [
              -77.887968,
              39.559198
            ],
            [
              -77.886135,
              39.560432
            ],
            [
              -77.878451,
              39.563493
            ],
            [
              -77.872723,
              39.563895
            ],
            [
              -77.865734,
              39.563547
            ],
            [
              -77.855847,
              39.564607
            ],
            [
              -77.842174,
              39.564333
            ],
            [
              -77.83633,
              39.56637
            ],
            [
              -77.8347328891263,
              39.5687536097396
            ],
            [
              -77.833217,
              39.571016
            ],
            [
              -77.830775,
              39.581178
            ],
            [
              -77.829814,
              39.587288
            ],
            [
              -77.829753,
              39.59105
            ],
            [
              -77.831813,
              39.601105
            ],
            [
              -77.833568,
              39.602936
            ],
            [
              -77.838008,
              39.606125
            ],
            [
              -77.842785,
              39.607255
            ],
            [
              -77.853436,
              39.607117
            ],
            [
              -77.8578,
              39.60788
            ],
            [
              -77.868679,
              39.611138
            ],
            [
              -77.874718,
              39.614293
            ],
            [
              -77.885124,
              39.615775
            ],
            [
              -77.887017,
              39.614518
            ],
            [
              -77.886959,
              39.613329
            ],
            [
              -77.881936,
              39.608112
            ],
            [
              -77.88111,
              39.606214
            ],
            [
              -77.880993,
              39.602852
            ],
            [
              -77.881823,
              39.600039
            ],
            [
              -77.882977,
              39.598828
            ],
            [
              -77.885077,
              39.597937
            ],
            [
              -77.888477,
              39.597343
            ],
            [
              -77.8919216798459,
              39.5980179268892
            ],
            [
              -77.91641,
              39.602816
            ],
            [
              -77.916836,
              39.602942
            ],
            [
              -77.923298,
              39.604852
            ],
            [
              -77.925988,
              39.607642
            ],
            [
              -77.928738,
              39.613908
            ],
            [
              -77.932862,
              39.617676
            ],
            [
              -77.937492,
              39.61915
            ],
            [
              -77.94194,
              39.61879
            ],
            [
              -77.944622,
              39.616772
            ],
            [
              -77.940431,
              39.605776
            ],
            [
              -77.936371,
              39.594508
            ],
            [
              -77.93905,
              39.587139
            ],
            [
              -77.946182,
              39.584814
            ],
            [
              -77.956122,
              39.591268
            ],
            [
              -77.955337,
              39.600336
            ],
            [
              -77.957642,
              39.608614
            ],
            [
              -77.962092,
              39.608702
            ],
            [
              -77.966223,
              39.607435
            ],
            [
              -77.97015,
              39.605091
            ],
            [
              -77.973967,
              39.601071
            ],
            [
              -77.976686,
              39.599744
            ],
            [
              -77.984815,
              39.59942
            ],
            [
              -77.991437,
              39.600194
            ],
            [
              -78.00233,
              39.600488
            ],
            [
              -78.006734,
              39.601337
            ],
            [
              -78.009985,
              39.602893
            ],
            [
              -78.011343,
              39.604083
            ],
            [
              -78.0224018601498,
              39.619600466954395
            ],
            [
              -78.059342,
              39.598294
            ],
            [
              -78.079959,
              39.59972
            ],
            [
              -78.094794,
              39.582204
            ],
            [
              -78.124064,
              39.593803
            ],
            [
              -78.138937,
              39.593796
            ],
            [
              -78.154274,
              39.563723
            ],
            [
              -78.173926,
              39.521355
            ],
            [
              -78.198727,
              39.445761
            ],
            [
              -78.227295,
              39.395003
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "081",
      "COUNTYNS": "01550047",
      "AFFGEOID": "0500000US54081",
      "GEOID": "54081",
      "NAME": "Raleigh",
      "LSAD": "06",
      "ALAND": 1567866394,
      "AWATER": 10334430,
      "County_state": "Raleigh,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.571534,
              37.927707
            ],
            [
              -81.561933,
              37.919207
            ],
            [
              -81.564233,
              37.902708
            ],
            [
              -81.553832,
              37.894309
            ],
            [
              -81.563416,
              37.883396
            ],
            [
              -81.560831,
              37.841409
            ],
            [
              -81.552331,
              37.824709
            ],
            [
              -81.528736,
              37.82197
            ],
            [
              -81.516729,
              37.81841
            ],
            [
              -81.514228,
              37.791211
            ],
            [
              -81.476628,
              37.780811
            ],
            [
              -81.458027,
              37.784211
            ],
            [
              -81.423941,
              37.743995
            ],
            [
              -81.396525,
              37.705695
            ],
            [
              -81.321717,
              37.597796
            ],
            [
              -81.310323,
              37.576614
            ],
            [
              -81.311575,
              37.552671
            ],
            [
              -81.261473,
              37.527499
            ],
            [
              -81.253101,
              37.532976
            ],
            [
              -81.222121,
              37.510416
            ],
            [
              -81.21375,
              37.509401
            ],
            [
              -81.199966,
              37.542374
            ],
            [
              -81.198869,
              37.575533
            ],
            [
              -81.135418,
              37.597916
            ],
            [
              -81.126591,
              37.592116
            ],
            [
              -81.108117,
              37.593917
            ],
            [
              -81.09463,
              37.588658
            ],
            [
              -81.064268,
              37.611057
            ],
            [
              -81.065522,
              37.641811
            ],
            [
              -81.027558,
              37.65856
            ],
            [
              -81.021701,
              37.669609
            ],
            [
              -80.988944,
              37.685794
            ],
            [
              -80.972629,
              37.69902
            ],
            [
              -80.977099,
              37.71452
            ],
            [
              -80.947017,
              37.715449
            ],
            [
              -80.914784,
              37.703205
            ],
            [
              -80.897588,
              37.689509
            ],
            [
              -80.900267,
              37.676282
            ],
            [
              -80.879542,
              37.682665
            ],
            [
              -80.877935,
              37.692109
            ],
            [
              -80.88997,
              37.701512
            ],
            [
              -80.898717,
              37.729312
            ],
            [
              -80.923334,
              37.742196
            ],
            [
              -80.91798,
              37.751939
            ],
            [
              -80.901921,
              37.756738
            ],
            [
              -80.894312,
              37.766775
            ],
            [
              -80.902947,
              37.782806
            ],
            [
              -80.921435,
              37.793408
            ],
            [
              -80.926321,
              37.80973
            ],
            [
              -80.94509,
              37.819121
            ],
            [
              -80.978109,
              37.83315
            ],
            [
              -81.027897,
              37.829518
            ],
            [
              -81.057286,
              37.835537
            ],
            [
              -81.051138,
              37.858302
            ],
            [
              -81.092643,
              37.85078
            ],
            [
              -81.100669,
              37.859386
            ],
            [
              -81.072668,
              37.860767
            ],
            [
              -81.073249,
              37.875865
            ],
            [
              -81.295182,
              37.889368
            ],
            [
              -81.301528,
              37.93341
            ],
            [
              -81.296628,
              37.94401
            ],
            [
              -81.322229,
              37.95221
            ],
            [
              -81.34733,
              37.976709
            ],
            [
              -81.380829,
              37.969109
            ],
            [
              -81.414331,
              37.973508
            ],
            [
              -81.436131,
              37.968108
            ],
            [
              -81.459132,
              37.979108
            ],
            [
              -81.456632,
              37.987307
            ],
            [
              -81.489132,
              37.981007
            ],
            [
              -81.507233,
              37.988407
            ],
            [
              -81.526233,
              37.971307
            ],
            [
              -81.538634,
              37.971007
            ],
            [
              -81.555834,
              37.954307
            ],
            [
              -81.554934,
              37.946107
            ],
            [
              -81.571534,
              37.927707
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "097",
      "COUNTYNS": "01696800",
      "AFFGEOID": "0500000US54097",
      "GEOID": "54097",
      "NAME": "Upshur",
      "LSAD": "06",
      "ALAND": 918506896,
      "AWATER": 302703,
      "County_state": "Upshur,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.399935,
              38.813817
            ],
            [
              -80.409541,
              38.768327
            ],
            [
              -80.393063,
              38.727571
            ],
            [
              -80.353105,
              38.730565
            ],
            [
              -80.330562,
              38.716962
            ],
            [
              -80.318246,
              38.684296
            ],
            [
              -80.280059,
              38.694867
            ],
            [
              -80.236831,
              38.743765
            ],
            [
              -80.142759,
              38.74561
            ],
            [
              -80.126105,
              38.745836
            ],
            [
              -80.098387,
              38.840549
            ],
            [
              -80.109045,
              38.896849
            ],
            [
              -80.089219,
              38.918269
            ],
            [
              -80.097343,
              38.927706
            ],
            [
              -80.085062,
              38.947236
            ],
            [
              -80.063824,
              38.961711
            ],
            [
              -80.073346,
              38.964957
            ],
            [
              -80.052651,
              38.975163
            ],
            [
              -80.054939,
              39.005345
            ],
            [
              -80.044431,
              39.011388
            ],
            [
              -80.059128,
              39.022028
            ],
            [
              -80.05582,
              39.03716
            ],
            [
              -80.128198,
              39.037795
            ],
            [
              -80.15177,
              39.071621
            ],
            [
              -80.167831,
              39.087583
            ],
            [
              -80.199636,
              39.098558
            ],
            [
              -80.209469,
              39.097357
            ],
            [
              -80.227173,
              39.112792
            ],
            [
              -80.248771,
              39.100809
            ],
            [
              -80.300209,
              39.103857
            ],
            [
              -80.305057,
              39.061467
            ],
            [
              -80.310937,
              39.040187
            ],
            [
              -80.317982,
              39.013441
            ],
            [
              -80.334484,
              38.996586
            ],
            [
              -80.325708,
              38.974261
            ],
            [
              -80.319915,
              38.969515
            ],
            [
              -80.321285,
              38.955721
            ],
            [
              -80.348353,
              38.939901
            ],
            [
              -80.362301,
              38.906775
            ],
            [
              -80.367778,
              38.886613
            ],
            [
              -80.386989,
              38.874808
            ],
            [
              -80.399935,
              38.813817
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "099",
      "COUNTYNS": "01550056",
      "AFFGEOID": "0500000US54099",
      "GEOID": "54099",
      "NAME": "Wayne",
      "LSAD": "06",
      "ALAND": 1310504124,
      "AWATER": 15860720,
      "County_state": "Wayne,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.642997,
              38.16956
            ],
            [
              -82.644739,
              38.165487
            ],
            [
              -82.638947,
              38.156742
            ],
            [
              -82.638398,
              38.152157
            ],
            [
              -82.638288,
              38.143491
            ],
            [
              -82.637306,
              38.13905
            ],
            [
              -82.636466,
              38.13786
            ],
            [
              -82.634265,
              38.136669
            ],
            [
              -82.626182,
              38.134835
            ],
            [
              -82.622125,
              38.133414
            ],
            [
              -82.621167,
              38.131996
            ],
            [
              -82.621164,
              38.123239
            ],
            [
              -82.620351,
              38.121477
            ],
            [
              -82.619452,
              38.120745
            ],
            [
              -82.616149,
              38.120221
            ],
            [
              -82.606589,
              38.120843
            ],
            [
              -82.602618,
              38.11835
            ],
            [
              -82.600127,
              38.117389
            ],
            [
              -82.598011,
              38.115925
            ],
            [
              -82.593605,
              38.110869
            ],
            [
              -82.59178,
              38.109908
            ],
            [
              -82.587782,
              38.108879
            ],
            [
              -82.585696,
              38.107003
            ],
            [
              -82.585142,
              38.098055
            ],
            [
              -82.585488,
              38.094256
            ],
            [
              -82.584039,
              38.090663
            ],
            [
              -82.574075,
              38.082104
            ],
            [
              -82.565736,
              38.08064
            ],
            [
              -82.559598,
              38.072837
            ],
            [
              -82.551259,
              38.070799
            ],
            [
              -82.54958,
              38.068579
            ],
            [
              -82.549407,
              38.063063
            ],
            [
              -82.547284,
              38.061094
            ],
            [
              -82.54485,
              38.058521
            ],
            [
              -82.544779,
              38.054262
            ],
            [
              -82.543916,
              38.052133
            ],
            [
              -82.541245,
              38.048444
            ],
            [
              -82.537293,
              38.045204
            ],
            [
              -82.53747,
              38.042701
            ],
            [
              -82.539071,
              38.039788
            ],
            [
              -82.538639,
              38.037381
            ],
            [
              -82.534976,
              38.03225
            ],
            [
              -82.530371,
              38.028792
            ],
            [
              -82.527068,
              38.027586
            ],
            [
              -82.525817,
              38.026406
            ],
            [
              -82.525831,
              38.019564
            ],
            [
              -82.522591,
              38.012968
            ],
            [
              -82.519665,
              38.008538
            ],
            [
              -82.517606,
              38.007222
            ],
            [
              -82.517351,
              38.005131
            ],
            [
              -82.51781,
              38.002479
            ],
            [
              -82.517351,
              38.001204
            ],
            [
              -82.515974,
              37.999929
            ],
            [
              -82.513271,
              37.999674
            ],
            [
              -82.509812,
              38.001249
            ],
            [
              -82.507197,
              38.001512
            ],
            [
              -82.499874,
              37.999157
            ],
            [
              -82.48978,
              37.998869
            ],
            [
              -82.487732,
              37.99833
            ],
            [
              -82.485967,
              37.995705
            ],
            [
              -82.471629,
              37.986826
            ],
            [
              -82.467015,
              37.985578
            ],
            [
              -82.465473,
              37.98478
            ],
            [
              -82.464257,
              37.983412
            ],
            [
              -82.464067,
              37.980295
            ],
            [
              -82.464987,
              37.976859
            ],
            [
              -82.46938,
              37.973059
            ],
            [
              -82.472669,
              37.960721
            ],
            [
              -82.471657,
              37.959988
            ],
            [
              -82.471801,
              37.959119
            ],
            [
              -82.475096,
              37.954906
            ],
            [
              -82.48096,
              37.949136
            ],
            [
              -82.48512,
              37.946044
            ],
            [
              -82.487836,
              37.945288
            ],
            [
              -82.495294,
              37.946612
            ],
            [
              -82.496681,
              37.946405
            ],
            [
              -82.4973,
              37.945507
            ],
            [
              -82.4972999070143,
              37.9454908576785
            ],
            [
              -82.497285,
              37.942903
            ],
            [
              -82.500386,
              37.936518
            ],
            [
              -82.501948,
              37.934756
            ],
            [
              -82.501862,
              37.9332
            ],
            [
              -82.49814,
              37.9283
            ],
            [
              -82.49574,
              37.927043
            ],
            [
              -82.488279,
              37.91812
            ],
            [
              -82.487556,
              37.916975
            ],
            [
              -82.474574,
              37.900295
            ],
            [
              -82.472523,
              37.899243
            ],
            [
              -82.471223,
              37.899358
            ],
            [
              -82.447596,
              37.904352
            ],
            [
              -82.438582,
              37.900256
            ],
            [
              -82.435751,
              37.897028
            ],
            [
              -82.432113,
              37.88991
            ],
            [
              -82.429023,
              37.888285
            ],
            [
              -82.421484,
              37.885652
            ],
            [
              -82.419781,
              37.883821
            ],
            [
              -82.419204,
              37.882081
            ],
            [
              -82.419337,
              37.875095
            ],
            [
              -82.41869,
              37.872375
            ],
            [
              -82.417679,
              37.870658
            ],
            [
              -82.422127,
              37.863952
            ],
            [
              -82.42409,
              37.863037
            ],
            [
              -82.424264,
              37.861709
            ],
            [
              -82.423513,
              37.860313
            ],
            [
              -82.421983,
              37.859397
            ],
            [
              -82.4213880826174,
              37.8546737766706
            ],
            [
              -82.394445,
              37.84242
            ],
            [
              -82.403743,
              37.862894
            ],
            [
              -82.306391,
              37.94364
            ],
            [
              -82.290915,
              37.95519
            ],
            [
              -82.274507,
              37.972778
            ],
            [
              -82.217515,
              38.020054
            ],
            [
              -82.203865,
              38.023606
            ],
            [
              -82.192314,
              38.046314
            ],
            [
              -82.205195,
              38.049432
            ],
            [
              -82.20893,
              38.068275
            ],
            [
              -82.235354,
              38.076167
            ],
            [
              -82.240868,
              38.097212
            ],
            [
              -82.261968,
              38.107511
            ],
            [
              -82.26656,
              38.119453
            ],
            [
              -82.257755,
              38.133606
            ],
            [
              -82.285709,
              38.144883
            ],
            [
              -82.271671,
              38.169422
            ],
            [
              -82.277824,
              38.205667
            ],
            [
              -82.266411,
              38.210616
            ],
            [
              -82.264849,
              38.229199
            ],
            [
              -82.275784,
              38.228262
            ],
            [
              -82.29952,
              38.240304
            ],
            [
              -82.31328,
              38.268383
            ],
            [
              -82.302576,
              38.287714
            ],
            [
              -82.305608,
              38.295229
            ],
            [
              -82.290372,
              38.304088
            ],
            [
              -82.276212,
              38.303868
            ],
            [
              -82.298392,
              38.328262
            ],
            [
              -82.314411,
              38.331364
            ],
            [
              -82.330482,
              38.323769
            ],
            [
              -82.32845,
              38.314874
            ],
            [
              -82.342714,
              38.306971
            ],
            [
              -82.3465,
              38.306426
            ],
            [
              -82.454518,
              38.376216
            ],
            [
              -82.490386,
              38.399415
            ],
            [
              -82.50767557967869,
              38.4107828373227
            ],
            [
              -82.507678,
              38.410782
            ],
            [
              -82.5293290908971,
              38.405245900779704
            ],
            [
              -82.529579,
              38.405182
            ],
            [
              -82.540199,
              38.403666
            ],
            [
              -82.549799,
              38.403202
            ],
            [
              -82.5535345000504,
              38.4035925686201
            ],
            [
              -82.560664,
              38.404338
            ],
            [
              -82.569368,
              38.406258
            ],
            [
              -82.577176,
              38.40877
            ],
            [
              -82.579976,
              38.41013
            ],
            [
              -82.588249,
              38.415489
            ],
            [
              -82.593673,
              38.421809
            ],
            [
              -82.596281,
              38.417681
            ],
            [
              -82.597113,
              38.412881
            ],
            [
              -82.597033,
              38.409937
            ],
            [
              -82.595001,
              38.40133
            ],
            [
              -82.597801,
              38.395154
            ],
            [
              -82.599241,
              38.39317
            ],
            [
              -82.599737,
              38.39037
            ],
            [
              -82.599273,
              38.388738
            ],
            [
              -82.595369,
              38.382722
            ],
            [
              -82.595382,
              38.382712
            ],
            [
              -82.5949049758321,
              38.3811788515581
            ],
            [
              -82.593008,
              38.375082
            ],
            [
              -82.593952,
              38.371847
            ],
            [
              -82.596654,
              38.367338
            ],
            [
              -82.597524,
              38.364843
            ],
            [
              -82.598189,
              38.357885
            ],
            [
              -82.597979,
              38.344909
            ],
            [
              -82.596525,
              38.342849
            ],
            [
              -82.592543,
              38.34166
            ],
            [
              -82.589724,
              38.340265
            ],
            [
              -82.587951,
              38.338595
            ],
            [
              -82.585363,
              38.334064
            ],
            [
              -82.576936,
              38.328275
            ],
            [
              -82.5759667693653,
              38.326111869014596
            ],
            [
              -82.572691,
              38.318801
            ],
            [
              -82.571877,
              38.315781
            ],
            [
              -82.571964,
              38.313606
            ],
            [
              -82.572893,
              38.311981
            ],
            [
              -82.578352,
              38.305458
            ],
            [
              -82.58146,
              38.300445
            ],
            [
              -82.583056,
              38.296829
            ],
            [
              -82.582823,
              38.295478
            ],
            [
              -82.579743,
              38.291726
            ],
            [
              -82.57948,
              38.284928
            ],
            [
              -82.578782,
              38.281747
            ],
            [
              -82.57672,
              38.277513
            ],
            [
              -82.5746,
              38.274721
            ],
            [
              -82.574656,
              38.263873
            ],
            [
              -82.578254,
              38.254809
            ],
            [
              -82.581796,
              38.248592
            ],
            [
              -82.584001,
              38.246371
            ],
            [
              -82.586061,
              38.245616
            ],
            [
              -82.59497,
              38.245453
            ],
            [
              -82.60423,
              38.247303
            ],
            [
              -82.604625,
              38.247303
            ],
            [
              -82.605333,
              38.247303
            ],
            [
              -82.607131,
              38.245975
            ],
            [
              -82.61226,
              38.236087
            ],
            [
              -82.61252,
              38.234553
            ],
            [
              -82.610367,
              38.226772
            ],
            [
              -82.608944,
              38.22366
            ],
            [
              -82.60575,
              38.221144
            ],
            [
              -82.600353,
              38.218949
            ],
            [
              -82.598437,
              38.217393
            ],
            [
              -82.598864,
              38.201007
            ],
            [
              -82.599326,
              38.197231
            ],
            [
              -82.60425,
              38.187639
            ],
            [
              -82.611343,
              38.171548
            ],
            [
              -82.613487,
              38.170242
            ],
            [
              -82.619429,
              38.169027
            ],
            [
              -82.625457,
              38.170491
            ],
            [
              -82.639054,
              38.171114
            ],
            [
              -82.642997,
              38.16956
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "043",
      "COUNTYNS": "01550028",
      "AFFGEOID": "0500000US54043",
      "GEOID": "54043",
      "NAME": "Lincoln",
      "LSAD": "06",
      "ALAND": 1131925075,
      "AWATER": 4023478,
      "County_state": "Lincoln,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.306391,
              37.94364
            ],
            [
              -82.305779,
              37.943803
            ],
            [
              -82.190658,
              37.974778
            ],
            [
              -82.127511,
              37.992187
            ],
            [
              -82.044575,
              38.014757
            ],
            [
              -82.027488,
              38.014371
            ],
            [
              -82.023317,
              38.01815
            ],
            [
              -81.988776,
              38.024884
            ],
            [
              -81.943523,
              38.021105
            ],
            [
              -81.932507,
              38.025356
            ],
            [
              -81.90516,
              38.029757
            ],
            [
              -81.954564,
              38.039438
            ],
            [
              -81.972102,
              38.050279
            ],
            [
              -81.964081,
              38.089653
            ],
            [
              -81.945795,
              38.108836
            ],
            [
              -81.953263,
              38.118878
            ],
            [
              -81.943864,
              38.137504
            ],
            [
              -81.927565,
              38.152492
            ],
            [
              -81.903797,
              38.14164
            ],
            [
              -81.878779,
              38.137202
            ],
            [
              -81.888065,
              38.151275
            ],
            [
              -81.886469,
              38.168002
            ],
            [
              -81.872118,
              38.163699
            ],
            [
              -81.871974,
              38.176846
            ],
            [
              -81.851176,
              38.173074
            ],
            [
              -81.851766,
              38.184813
            ],
            [
              -81.83347,
              38.20957
            ],
            [
              -81.833149,
              38.222872
            ],
            [
              -81.809171,
              38.220089
            ],
            [
              -81.810543,
              38.257013
            ],
            [
              -81.802558,
              38.264305
            ],
            [
              -81.806505,
              38.293711
            ],
            [
              -81.816874,
              38.315759
            ],
            [
              -81.830634,
              38.322526
            ],
            [
              -81.84709,
              38.313351
            ],
            [
              -81.885772,
              38.31475
            ],
            [
              -81.915197,
              38.325597
            ],
            [
              -81.982094,
              38.276701
            ],
            [
              -81.991151,
              38.275772
            ],
            [
              -81.997406,
              38.266605
            ],
            [
              -82.048852,
              38.290342
            ],
            [
              -82.043094,
              38.337278
            ],
            [
              -82.047128,
              38.374432
            ],
            [
              -82.064735,
              38.376765
            ],
            [
              -82.097205,
              38.367479
            ],
            [
              -82.117636,
              38.344194
            ],
            [
              -82.164651,
              38.336506
            ],
            [
              -82.17679,
              38.3396
            ],
            [
              -82.197506,
              38.310692
            ],
            [
              -82.220603,
              38.310791
            ],
            [
              -82.216797,
              38.309361
            ],
            [
              -82.207585,
              38.288697
            ],
            [
              -82.241316,
              38.27129
            ],
            [
              -82.264849,
              38.229199
            ],
            [
              -82.266411,
              38.210616
            ],
            [
              -82.277824,
              38.205667
            ],
            [
              -82.271671,
              38.169422
            ],
            [
              -82.285709,
              38.144883
            ],
            [
              -82.257755,
              38.133606
            ],
            [
              -82.26656,
              38.119453
            ],
            [
              -82.261968,
              38.107511
            ],
            [
              -82.240868,
              38.097212
            ],
            [
              -82.235354,
              38.076167
            ],
            [
              -82.20893,
              38.068275
            ],
            [
              -82.205195,
              38.049432
            ],
            [
              -82.192314,
              38.046314
            ],
            [
              -82.203865,
              38.023606
            ],
            [
              -82.217515,
              38.020054
            ],
            [
              -82.274507,
              37.972778
            ],
            [
              -82.290915,
              37.95519
            ],
            [
              -82.306391,
              37.94364
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "061",
      "COUNTYNS": "01550037",
      "AFFGEOID": "0500000US54061",
      "GEOID": "54061",
      "NAME": "Monongalia",
      "LSAD": "06",
      "ALAND": 932572493,
      "AWATER": 15051916,
      "County_state": "Monongalia,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.421388,
              39.721189
            ],
            [
              -80.407408,
              39.717752
            ],
            [
              -80.395248,
              39.680506
            ],
            [
              -80.39581,
              39.637347
            ],
            [
              -80.3677,
              39.633563
            ],
            [
              -80.347654,
              39.61185
            ],
            [
              -80.312894,
              39.611775
            ],
            [
              -80.171455,
              39.610529
            ],
            [
              -80.153532,
              39.610426
            ],
            [
              -80.128283,
              39.582475
            ],
            [
              -80.110721,
              39.576844
            ],
            [
              -80.038618,
              39.517
            ],
            [
              -80.032031,
              39.505502
            ],
            [
              -80.028582,
              39.506002
            ],
            [
              -80.027746,
              39.505672
            ],
            [
              -80.027463,
              39.504815
            ],
            [
              -80.025109,
              39.50471
            ],
            [
              -80.023089,
              39.503644
            ],
            [
              -80.021415,
              39.503962
            ],
            [
              -80.020676,
              39.503366
            ],
            [
              -80.018593,
              39.5035
            ],
            [
              -80.016538,
              39.50256
            ],
            [
              -80.015511,
              39.501538
            ],
            [
              -80.01106,
              39.487172
            ],
            [
              -79.993682,
              39.484572
            ],
            [
              -79.967959,
              39.462978
            ],
            [
              -79.938878,
              39.452465
            ],
            [
              -79.894688,
              39.435588
            ],
            [
              -79.89974,
              39.481945
            ],
            [
              -79.881707,
              39.504811
            ],
            [
              -79.849689,
              39.545998
            ],
            [
              -79.812106,
              39.59435
            ],
            [
              -79.789277,
              39.623781
            ],
            [
              -79.78537,
              39.657287
            ],
            [
              -79.763773,
              39.696867
            ],
            [
              -79.763774,
              39.720776
            ],
            [
              -79.80810850275802,
              39.720744662923
            ],
            [
              -79.852904,
              39.720713
            ],
            [
              -79.853131,
              39.720713
            ],
            [
              -79.9161705658965,
              39.72089322136409
            ],
            [
              -80.0417021197902,
              39.7212520986433
            ],
            [
              -80.075947,
              39.72135
            ],
            [
              -80.24130705308929,
              39.721302389629905
            ],
            [
              -80.308651,
              39.721283
            ],
            [
              -80.309457,
              39.721264
            ],
            [
              -80.421388,
              39.721189
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "007",
      "COUNTYNS": "01550010",
      "AFFGEOID": "0500000US54007",
      "GEOID": "54007",
      "NAME": "Braxton",
      "LSAD": "06",
      "ALAND": 1322996693,
      "AWATER": 14144661,
      "County_state": "Braxton,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.98495,
              38.717898
            ],
            [
              -81.031677,
              38.667839
            ],
            [
              -80.975961,
              38.625096
            ],
            [
              -80.970274,
              38.593445
            ],
            [
              -80.955146,
              38.592206
            ],
            [
              -80.926342,
              38.579339
            ],
            [
              -80.913138,
              38.563568
            ],
            [
              -80.917744,
              38.544816
            ],
            [
              -80.891499,
              38.536913
            ],
            [
              -80.881232,
              38.507045
            ],
            [
              -80.785177,
              38.557649
            ],
            [
              -80.760294,
              38.541512
            ],
            [
              -80.751065,
              38.525771
            ],
            [
              -80.71635,
              38.536858
            ],
            [
              -80.650786,
              38.52659
            ],
            [
              -80.508655,
              38.645553
            ],
            [
              -80.457332,
              38.739169
            ],
            [
              -80.451149,
              38.749146
            ],
            [
              -80.446148,
              38.777514
            ],
            [
              -80.457827,
              38.775493
            ],
            [
              -80.463556,
              38.79604
            ],
            [
              -80.485528,
              38.800304
            ],
            [
              -80.47381,
              38.833229
            ],
            [
              -80.522545,
              38.862347
            ],
            [
              -80.543991,
              38.853817
            ],
            [
              -80.557957,
              38.867609
            ],
            [
              -80.58349,
              38.858871
            ],
            [
              -80.593289,
              38.870418
            ],
            [
              -80.590991,
              38.883679
            ],
            [
              -80.605799,
              38.904167
            ],
            [
              -80.641017,
              38.884815
            ],
            [
              -80.669962,
              38.892688
            ],
            [
              -80.757075,
              38.846822
            ],
            [
              -80.829993,
              38.804324
            ],
            [
              -80.98495,
              38.717898
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "073",
      "COUNTYNS": "01678557",
      "AFFGEOID": "0500000US54073",
      "GEOID": "54073",
      "NAME": "Pleasants",
      "LSAD": "06",
      "ALAND": 337015703,
      "AWATER": 11459910,
      "County_state": "Pleasants,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.3712707002794,
              39.3420616369494
            ],
            [
              -81.363942,
              39.320804
            ],
            [
              -81.34812,
              39.328764
            ],
            [
              -81.324063,
              39.316371
            ],
            [
              -81.324215,
              39.298557
            ],
            [
              -81.261143,
              39.292389
            ],
            [
              -81.239477,
              39.268328
            ],
            [
              -81.221036,
              39.308175
            ],
            [
              -81.189835,
              39.305276
            ],
            [
              -81.166933,
              39.311578
            ],
            [
              -81.121037,
              39.314059
            ],
            [
              -81.080704,
              39.331183
            ],
            [
              -81.056732,
              39.324077
            ],
            [
              -81.044432,
              39.328777
            ],
            [
              -81.034726,
              39.351173
            ],
            [
              -81.007836,
              39.350923
            ],
            [
              -81.014433,
              39.403506
            ],
            [
              -81.023162,
              39.441821
            ],
            [
              -81.02569,
              39.462063
            ],
            [
              -81.029932,
              39.467745
            ],
            [
              -81.042631,
              39.46861
            ],
            [
              -81.061269,
              39.476432
            ],
            [
              -81.054048,
              39.467447
            ],
            [
              -81.05711,
              39.463333
            ],
            [
              -81.078895,
              39.467556
            ],
            [
              -81.094324,
              39.457689
            ],
            [
              -81.106913,
              39.463571
            ],
            [
              -81.121472141059,
              39.457756779201894
            ],
            [
              -81.124733,
              39.453375
            ],
            [
              -81.128533,
              39.449375
            ],
            [
              -81.132534,
              39.446275
            ],
            [
              -81.134434,
              39.445075
            ],
            [
              -81.138134,
              39.443775
            ],
            [
              -81.152534,
              39.443175
            ],
            [
              -81.16352,
              39.441186
            ],
            [
              -81.170634,
              39.439175
            ],
            [
              -81.179934,
              39.435121
            ],
            [
              -81.182307,
              39.433533
            ],
            [
              -81.185946,
              39.430731
            ],
            [
              -81.190714,
              39.423562
            ],
            [
              -81.200412,
              39.415303
            ],
            [
              -81.205223,
              39.410786
            ],
            [
              -81.208231,
              39.407147
            ],
            [
              -81.210833,
              39.403563
            ],
            [
              -81.211433,
              39.402031
            ],
            [
              -81.21087,
              39.397112
            ],
            [
              -81.211654,
              39.392977
            ],
            [
              -81.213064,
              39.390656
            ],
            [
              -81.217315,
              39.38759
            ],
            [
              -81.221372,
              39.386172
            ],
            [
              -81.223581,
              39.386062
            ],
            [
              -81.24184,
              39.390276
            ],
            [
              -81.249088,
              39.389992
            ],
            [
              -81.259788,
              39.386698
            ],
            [
              -81.270716,
              39.385914
            ],
            [
              -81.275677,
              39.383786
            ],
            [
              -81.281405,
              39.379258
            ],
            [
              -81.297517,
              39.374378
            ],
            [
              -81.304798,
              39.370538
            ],
            [
              -81.319598,
              39.36129
            ],
            [
              -81.326174,
              39.358186
            ],
            [
              -81.335599,
              39.352794
            ],
            [
              -81.342623,
              39.348042
            ],
            [
              -81.347567,
              39.34577
            ],
            [
              -81.356911,
              39.343178
            ],
            [
              -81.3712707002794,
              39.3420616369494
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "031",
      "COUNTYNS": "01718557",
      "AFFGEOID": "0500000US54031",
      "GEOID": "54031",
      "NAME": "Hardy",
      "LSAD": "06",
      "ALAND": 1508194693,
      "AWATER": 5617193,
      "County_state": "Hardy,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.112556,
              38.835012
            ],
            [
              -79.134296,
              38.81334
            ],
            [
              -79.057253,
              38.761413
            ],
            [
              -79.056754,
              38.766513
            ],
            [
              -79.055654,
              38.770913
            ],
            [
              -79.053754,
              38.772313
            ],
            [
              -79.051554,
              38.772613
            ],
            [
              -79.051254,
              38.773913
            ],
            [
              -79.051654,
              38.778013
            ],
            [
              -79.052454,
              38.779213
            ],
            [
              -79.054354,
              38.780613
            ],
            [
              -79.055654,
              38.783013
            ],
            [
              -79.054954,
              38.785713
            ],
            [
              -79.048954,
              38.790713
            ],
            [
              -79.046554,
              38.792113
            ],
            [
              -79.033153,
              38.791013
            ],
            [
              -79.029253,
              38.791013
            ],
            [
              -79.027253,
              38.792113
            ],
            [
              -79.023053,
              38.798613
            ],
            [
              -79.023453,
              38.802612
            ],
            [
              -79.024453,
              38.803712
            ],
            [
              -79.024053,
              38.809212
            ],
            [
              -79.019553,
              38.817912
            ],
            [
              -79.016752,
              38.820012
            ],
            [
              -79.011952,
              38.820412
            ],
            [
              -79.007952,
              38.822312
            ],
            [
              -79.006552,
              38.823712
            ],
            [
              -79.006152,
              38.824512
            ],
            [
              -79.006352,
              38.826112
            ],
            [
              -79.005152,
              38.829912
            ],
            [
              -79.002352,
              38.836512
            ],
            [
              -78.999014,
              38.840074
            ],
            [
              -78.998863,
              38.840962
            ],
            [
              -79.000252,
              38.845412
            ],
            [
              -78.998171,
              38.847353
            ],
            [
              -78.993997,
              38.850102
            ],
            [
              -78.869276,
              38.762991
            ],
            [
              -78.8692752588773,
              38.7629918888636
            ],
            [
              -78.865905,
              38.767034
            ],
            [
              -78.863684,
              38.7718
            ],
            [
              -78.848187,
              38.794978
            ],
            [
              -78.835191,
              38.811499
            ],
            [
              -78.832267,
              38.814388
            ],
            [
              -78.827262,
              38.82161
            ],
            [
              -78.821167,
              38.830982
            ],
            [
              -78.815116,
              38.841594
            ],
            [
              -78.810943,
              38.849616
            ],
            [
              -78.808181,
              38.856175
            ],
            [
              -78.796213,
              38.874606
            ],
            [
              -78.79161,
              38.877593
            ],
            [
              -78.790078,
              38.880076
            ],
            [
              -78.788031,
              38.885123
            ],
            [
              -78.786025,
              38.887187
            ],
            [
              -78.779198,
              38.892298
            ],
            [
              -78.772793,
              38.893742
            ],
            [
              -78.759085,
              38.900529
            ],
            [
              -78.757278,
              38.903203
            ],
            [
              -78.754516,
              38.905728
            ],
            [
              -78.754658,
              38.907582
            ],
            [
              -78.750517,
              38.916029
            ],
            [
              -78.738921,
              38.927283
            ],
            [
              -78.726222,
              38.930932
            ],
            [
              -78.724062,
              38.930846
            ],
            [
              -78.722451,
              38.931405
            ],
            [
              -78.718482,
              38.934267
            ],
            [
              -78.717076,
              38.936028
            ],
            [
              -78.71962,
              38.92651
            ],
            [
              -78.720095,
              38.923863
            ],
            [
              -78.719806,
              38.922638
            ],
            [
              -78.719755,
              38.922135
            ],
            [
              -78.719451,
              38.92026
            ],
            [
              -78.7209,
              38.909844
            ],
            [
              -78.71981,
              38.905907
            ],
            [
              -78.718647,
              38.904561
            ],
            [
              -78.717178,
              38.904296
            ],
            [
              -78.716168,
              38.90483
            ],
            [
              -78.712622,
              38.908665
            ],
            [
              -78.704323,
              38.915231
            ],
            [
              -78.69738,
              38.915602
            ],
            [
              -78.69145,
              38.922195
            ],
            [
              -78.688266,
              38.92478
            ],
            [
              -78.681617,
              38.92584
            ],
            [
              -78.680456,
              38.925313
            ],
            [
              -78.670679,
              38.9338
            ],
            [
              -78.666594,
              38.9392
            ],
            [
              -78.665886,
              38.941579
            ],
            [
              -78.662083,
              38.945702
            ],
            [
              -78.65905,
              38.947375
            ],
            [
              -78.655043,
              38.953766
            ],
            [
              -78.652352,
              38.960677
            ],
            [
              -78.646589,
              38.968138
            ],
            [
              -78.638423,
              38.966819
            ],
            [
              -78.632471,
              38.974739
            ],
            [
              -78.632452,
              38.976983
            ],
            [
              -78.630846,
              38.979586
            ],
            [
              -78.629553,
              38.980866
            ],
            [
              -78.625672,
              38.982575
            ],
            [
              -78.620453,
              38.982601
            ],
            [
              -78.619914,
              38.981288
            ],
            [
              -78.619982,
              38.977338
            ],
            [
              -78.618676,
              38.974082
            ],
            [
              -78.614312,
              38.97585
            ],
            [
              -78.611184,
              38.976134
            ],
            [
              -78.608369,
              38.969743
            ],
            [
              -78.601655,
              38.964603
            ],
            [
              -78.601399,
              38.96653
            ],
            [
              -78.598894,
              38.969546
            ],
            [
              -78.596015,
              38.970192
            ],
            [
              -78.593261,
              38.971918
            ],
            [
              -78.588704,
              38.978579
            ],
            [
              -78.582928,
              38.985416
            ],
            [
              -78.581981,
              38.988398
            ],
            [
              -78.580465,
              38.990567
            ],
            [
              -78.570462,
              39.001552
            ],
            [
              -78.561711,
              39.009007
            ],
            [
              -78.5594,
              39.011877
            ],
            [
              -78.554919,
              39.015124
            ],
            [
              -78.552321,
              39.016374
            ],
            [
              -78.550467,
              39.018065
            ],
            [
              -78.55738,
              39.021393
            ],
            [
              -78.55964,
              39.024456
            ],
            [
              -78.563294,
              39.026328
            ],
            [
              -78.565073,
              39.025935
            ],
            [
              -78.565837,
              39.026303
            ],
            [
              -78.571901,
              39.031995
            ],
            [
              -78.559997,
              39.041573
            ],
            [
              -78.556748,
              39.044527
            ],
            [
              -78.554263,
              39.048058
            ],
            [
              -78.547734,
              39.054069
            ],
            [
              -78.545679,
              39.055052
            ],
            [
              -78.5441928910609,
              39.056569664611196
            ],
            [
              -78.540216,
              39.060631
            ],
            [
              -78.531695,
              39.066519
            ],
            [
              -78.526543,
              39.068221
            ],
            [
              -78.522714,
              39.071062
            ],
            [
              -78.516789,
              39.077569
            ],
            [
              -78.515955,
              39.080046
            ],
            [
              -78.516479,
              39.081802
            ],
            [
              -78.512103,
              39.084878
            ],
            [
              -78.508132,
              39.08863
            ],
            [
              -78.561282,
              39.131444
            ],
            [
              -78.602332,
              39.140367
            ],
            [
              -78.623555,
              39.118509
            ],
            [
              -78.656875,
              39.125673
            ],
            [
              -78.69857,
              39.148073
            ],
            [
              -78.732971,
              39.149922
            ],
            [
              -78.842198,
              39.184278
            ],
            [
              -78.899695,
              39.199137
            ],
            [
              -78.979898,
              39.237624
            ],
            [
              -79.00155,
              39.195204
            ],
            [
              -79.036077,
              39.150002
            ],
            [
              -79.02877,
              39.14176
            ],
            [
              -79.056848,
              39.091675
            ],
            [
              -79.089655,
              39.038208
            ],
            [
              -79.077755,
              39.021809
            ],
            [
              -79.081755,
              39.000109
            ],
            [
              -79.046053,
              38.92841
            ],
            [
              -79.074055,
              38.899011
            ],
            [
              -79.086054,
              38.893911
            ],
            [
              -79.080755,
              38.877011
            ],
            [
              -79.112556,
              38.835012
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "063",
      "COUNTYNS": "01557702",
      "AFFGEOID": "0500000US54063",
      "GEOID": "54063",
      "NAME": "Monroe",
      "LSAD": "06",
      "ALAND": 1224426774,
      "AWATER": 2297605,
      "County_state": "Monroe,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.85847336829929,
              37.4283007053671
            ],
            [
              -80.85836,
              37.428168
            ],
            [
              -80.856997,
              37.427052
            ],
            [
              -80.853163,
              37.426902
            ],
            [
              -80.850656,
              37.426062
            ],
            [
              -80.846324,
              37.423394
            ],
            [
              -80.844213,
              37.423555
            ],
            [
              -80.841672,
              37.425971
            ],
            [
              -80.837678,
              37.425658
            ],
            [
              -80.836446,
              37.424355
            ],
            [
              -80.811639,
              37.407507
            ],
            [
              -80.808769,
              37.406271
            ],
            [
              -80.806358,
              37.404119
            ],
            [
              -80.807134,
              37.401348
            ],
            [
              -80.806129,
              37.398074
            ],
            [
              -80.800447,
              37.395738
            ],
            [
              -80.798869,
              37.395807
            ],
            [
              -80.790317,
              37.395668
            ],
            [
              -80.784188,
              37.394587
            ],
            [
              -80.783324,
              37.392793
            ],
            [
              -80.783382,
              37.390649
            ],
            [
              -80.782295,
              37.389016
            ],
            [
              -80.776766,
              37.384131
            ],
            [
              -80.776649,
              37.383679
            ],
            [
              -80.770082,
              37.372363
            ],
            [
              -80.759886,
              37.374882
            ],
            [
              -80.748722,
              37.38005
            ],
            [
              -80.745527,
              37.380111
            ],
            [
              -80.73804,
              37.382547
            ],
            [
              -80.731589,
              37.38471
            ],
            [
              -80.723596,
              37.388261
            ],
            [
              -80.715479,
              37.390707
            ],
            [
              -80.705203,
              37.394618
            ],
            [
              -80.691709,
              37.401749
            ],
            [
              -80.684576,
              37.40463
            ],
            [
              -80.664971,
              37.414215
            ],
            [
              -80.664112,
              37.41422
            ],
            [
              -80.656687,
              37.417585
            ],
            [
              -80.653589,
              37.419514
            ],
            [
              -80.645893,
              37.422147
            ],
            [
              -80.636947,
              37.427471
            ],
            [
              -80.637554,
              37.428556
            ],
            [
              -80.637379,
              37.429372
            ],
            [
              -80.63439,
              37.431227
            ],
            [
              -80.632365,
              37.432125
            ],
            [
              -80.626365,
              37.433328
            ],
            [
              -80.622664,
              37.433307
            ],
            [
              -80.622117,
              37.435969
            ],
            [
              -80.616802,
              37.439443
            ],
            [
              -80.600204,
              37.446173
            ],
            [
              -80.591377,
              37.45144
            ],
            [
              -80.59024,
              37.453296
            ],
            [
              -80.585856,
              37.456654
            ],
            [
              -80.566297,
              37.466575
            ],
            [
              -80.561442,
              37.469775
            ],
            [
              -80.552036,
              37.473563
            ],
            [
              -80.544836,
              37.474695
            ],
            [
              -80.539786,
              37.474527
            ],
            [
              -80.533449,
              37.476406
            ],
            [
              -80.532372,
              37.477124
            ],
            [
              -80.528349,
              37.477368
            ],
            [
              -80.523481,
              37.476905
            ],
            [
              -80.515139,
              37.478566
            ],
            [
              -80.513409,
              37.479446
            ],
            [
              -80.511391,
              37.481672
            ],
            [
              -80.492981,
              37.457749
            ],
            [
              -80.49728,
              37.444779
            ],
            [
              -80.494867,
              37.43507
            ],
            [
              -80.475601,
              37.422949
            ],
            [
              -80.4727968266129,
              37.4237800299575
            ],
            [
              -80.46482,
              37.426144
            ],
            [
              -80.457313,
              37.432267
            ],
            [
              -80.451367,
              37.434039
            ],
            [
              -80.443025,
              37.438126
            ],
            [
              -80.425656,
              37.449876
            ],
            [
              -80.402816,
              37.460322
            ],
            [
              -80.39988,
              37.462314
            ],
            [
              -80.382535,
              37.470367
            ],
            [
              -80.378308,
              37.471381
            ],
            [
              -80.371952,
              37.474069
            ],
            [
              -80.369449,
              37.476599
            ],
            [
              -80.36317,
              37.480001
            ],
            [
              -80.366838,
              37.484879
            ],
            [
              -80.343789,
              37.492148
            ],
            [
              -80.332038,
              37.493744
            ],
            [
              -80.327103,
              37.495376
            ],
            [
              -80.320627,
              37.49888
            ],
            [
              -80.314806,
              37.500943
            ],
            [
              -80.309331,
              37.50288
            ],
            [
              -80.309833,
              37.503827
            ],
            [
              -80.299789,
              37.508271
            ],
            [
              -80.282385,
              37.533517
            ],
            [
              -80.291644,
              37.536505
            ],
            [
              -80.309346,
              37.527381
            ],
            [
              -80.330306,
              37.536244
            ],
            [
              -80.327489,
              37.540022
            ],
            [
              -80.324384,
              37.541052
            ],
            [
              -80.321249,
              37.541419
            ],
            [
              -80.314464,
              37.54412
            ],
            [
              -80.312393,
              37.546239
            ],
            [
              -80.328504,
              37.564315
            ],
            [
              -80.294882,
              37.57877
            ],
            [
              -80.28244,
              37.585481
            ],
            [
              -80.270342,
              37.591149
            ],
            [
              -80.26356,
              37.593374
            ],
            [
              -80.258919,
              37.595499
            ],
            [
              -80.24978,
              37.602117
            ],
            [
              -80.240272,
              37.606961
            ],
            [
              -80.226017,
              37.620059
            ],
            [
              -80.223386,
              37.623185
            ],
            [
              -80.220984,
              37.627767
            ],
            [
              -80.239288,
              37.637672
            ],
            [
              -80.254431,
              37.642352
            ],
            [
              -80.254469,
              37.642333
            ],
            [
              -80.263281,
              37.645082
            ],
            [
              -80.263291,
              37.645101
            ],
            [
              -80.26483,
              37.645526
            ],
            [
              -80.264874,
              37.645511
            ],
            [
              -80.267228,
              37.646011
            ],
            [
              -80.267455,
              37.646108
            ],
            [
              -80.270352,
              37.648929
            ],
            [
              -80.270323,
              37.648982
            ],
            [
              -80.279372,
              37.657077
            ],
            [
              -80.292258,
              37.683732
            ],
            [
              -80.292337,
              37.683976
            ],
            [
              -80.296138,
              37.691783
            ],
            [
              -80.318308,
              37.685839
            ],
            [
              -80.336316,
              37.687568
            ],
            [
              -80.347712,
              37.693608
            ],
            [
              -80.34021,
              37.704671
            ],
            [
              -80.352741,
              37.707893
            ],
            [
              -80.361694,
              37.689637
            ],
            [
              -80.385295,
              37.692771
            ],
            [
              -80.380764,
              37.707325
            ],
            [
              -80.393354,
              37.70219
            ],
            [
              -80.394562,
              37.716918
            ],
            [
              -80.450651,
              37.683858
            ],
            [
              -80.463308,
              37.693255
            ],
            [
              -80.506695,
              37.691158
            ],
            [
              -80.654813,
              37.727319
            ],
            [
              -80.663348,
              37.731696
            ],
            [
              -80.660446,
              37.714563
            ],
            [
              -80.69722,
              37.660484
            ],
            [
              -80.701137,
              37.655039
            ],
            [
              -80.748147,
              37.587601
            ],
            [
              -80.798451,
              37.522103
            ],
            [
              -80.85847336829929,
              37.4283007053671
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "035",
      "COUNTYNS": "01559963",
      "AFFGEOID": "0500000US54035",
      "GEOID": "54035",
      "NAME": "Jackson",
      "LSAD": "06",
      "ALAND": 1202677932,
      "AWATER": 18836099,
      "County_state": "Jackson,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.908645,
              38.87846
            ],
            [
              -81.910156,
              38.875081
            ],
            [
              -81.902851,
              38.863324
            ],
            [
              -81.831115,
              38.779658
            ],
            [
              -81.791265,
              38.733104
            ],
            [
              -81.8135,
              38.714761
            ],
            [
              -81.772843,
              38.680843
            ],
            [
              -81.749852,
              38.683821
            ],
            [
              -81.747024,
              38.683391
            ],
            [
              -81.696348,
              38.626427
            ],
            [
              -81.632817,
              38.554699
            ],
            [
              -81.578202,
              38.577068
            ],
            [
              -81.522166,
              38.612746
            ],
            [
              -81.546631,
              38.672387
            ],
            [
              -81.533965,
              38.727371
            ],
            [
              -81.521561,
              38.824904
            ],
            [
              -81.52731,
              38.901666
            ],
            [
              -81.502628,
              38.917922
            ],
            [
              -81.537989,
              38.944209
            ],
            [
              -81.549504,
              38.967245
            ],
            [
              -81.529535,
              38.97757
            ],
            [
              -81.532186,
              39.00545
            ],
            [
              -81.581447,
              39.026179
            ],
            [
              -81.74725270065791,
              39.0953784490132
            ],
            [
              -81.747253,
              39.095378
            ],
            [
              -81.752353,
              39.089878
            ],
            [
              -81.760753,
              39.084078
            ],
            [
              -81.764854,
              39.081978
            ],
            [
              -81.775554,
              39.078378
            ],
            [
              -81.781454,
              39.078078
            ],
            [
              -81.785554,
              39.078578
            ],
            [
              -81.790754,
              39.079778
            ],
            [
              -81.798155,
              39.082878
            ],
            [
              -81.803055,
              39.083878
            ],
            [
              -81.807855,
              39.083978
            ],
            [
              -81.810655,
              39.083278
            ],
            [
              -81.812355,
              39.082078
            ],
            [
              -81.813855,
              39.079278
            ],
            [
              -81.814155,
              39.073478
            ],
            [
              -81.813355,
              39.065878
            ],
            [
              -81.811655,
              39.059578
            ],
            [
              -81.808955,
              39.055178
            ],
            [
              -81.803355,
              39.047678
            ],
            [
              -81.800355,
              39.044978
            ],
            [
              -81.793304,
              39.040353
            ],
            [
              -81.786554,
              39.036579
            ],
            [
              -81.772854,
              39.026179
            ],
            [
              -81.767253,
              39.019979
            ],
            [
              -81.764253,
              39.015279
            ],
            [
              -81.764253,
              39.006579
            ],
            [
              -81.765153,
              39.002579
            ],
            [
              -81.767188,
              39.000115
            ],
            [
              -81.771975,
              38.996845
            ],
            [
              -81.774062,
              38.993682
            ],
            [
              -81.775551,
              38.990107
            ],
            [
              -81.776723,
              38.985142
            ],
            [
              -81.776466,
              38.982048
            ],
            [
              -81.775734,
              38.980737
            ],
            [
              -81.779883,
              38.972773
            ],
            [
              -81.78182,
              38.964935
            ],
            [
              -81.7815788388276,
              38.9636090584987
            ],
            [
              -81.780736,
              38.958975
            ],
            [
              -81.778845,
              38.955892
            ],
            [
              -81.77396,
              38.951645
            ],
            [
              -81.769703,
              38.948707
            ],
            [
              -81.756975,
              38.937152
            ],
            [
              -81.756131,
              38.933545
            ],
            [
              -81.758506,
              38.927942
            ],
            [
              -81.759995,
              38.925828
            ],
            [
              -81.762659,
              38.924121
            ],
            [
              -81.766227,
              38.922946
            ],
            [
              -81.76976,
              38.92273
            ],
            [
              -81.774106,
              38.922965
            ],
            [
              -81.781248,
              38.924804
            ],
            [
              -81.785647,
              38.926193
            ],
            [
              -81.793372,
              38.930204
            ],
            [
              -81.796376,
              38.932498
            ],
            [
              -81.806137,
              38.942112
            ],
            [
              -81.814235,
              38.946168
            ],
            [
              -81.819692,
              38.947016
            ],
            [
              -81.825026,
              38.946603
            ],
            [
              -81.827354,
              38.945898
            ],
            [
              -81.831516,
              38.943697
            ],
            [
              -81.838067,
              38.937135
            ],
            [
              -81.844486,
              38.928746
            ],
            [
              -81.84607,
              38.913192
            ],
            [
              -81.845312,
              38.910088
            ],
            [
              -81.848653,
              38.901407
            ],
            [
              -81.855971,
              38.892734
            ],
            [
              -81.858921,
              38.89019
            ],
            [
              -81.874857,
              38.881174
            ],
            [
              -81.889233,
              38.874279
            ],
            [
              -81.892837,
              38.873869
            ],
            [
              -81.898541,
              38.874582
            ],
            [
              -81.908645,
              38.87846
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "54",
      "COUNTYFP": "053",
      "COUNTYNS": "01560254",
      "AFFGEOID": "0500000US54053",
      "GEOID": "54053",
      "NAME": "Mason",
      "LSAD": "06",
      "ALAND": 1115633278,
      "AWATER": 36174540,
      "County_state": "Mason,54"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.221566,
              38.787187
            ],
            [
              -82.221518,
              38.77981
            ],
            [
              -82.220449,
              38.773739
            ],
            [
              -82.216614,
              38.76835
            ],
            [
              -82.207141,
              38.763943
            ],
            [
              -82.201537,
              38.760372
            ],
            [
              -82.198882,
              38.757725
            ],
            [
              -82.195606,
              38.752441
            ],
            [
              -82.193268,
              38.741182
            ],
            [
              -82.189668,
              38.737382
            ],
            [
              -82.188268,
              38.734082
            ],
            [
              -82.186568,
              38.722482
            ],
            [
              -82.184567,
              38.715882
            ],
            [
              -82.182467,
              38.708782
            ],
            [
              -82.182867,
              38.705482
            ],
            [
              -82.185067,
              38.699182
            ],
            [
              -82.190167,
              38.687382
            ],
            [
              -82.190867,
              38.680383
            ],
            [
              -82.187667,
              38.672683
            ],
            [
              -82.186067,
              38.666783
            ],
            [
              -82.185567,
              38.659583
            ],
            [
              -82.179067,
              38.648883
            ],
            [
              -82.176767,
              38.642783
            ],
            [
              -82.174267,
              38.633183
            ],
            [
              -82.172667,
              38.629684
            ],
            [
              -82.172066,
              38.625984
            ],
            [
              -82.172066,
              38.619284
            ],
            [
              -82.175167,
              38.608484
            ],
            [
              -82.177267,
              38.603784
            ],
            [
              -82.181967,
              38.599384
            ],
            [
              -82.188767,
              38.594984
            ],
            [
              -82.193824,
              38.593096
            ],
            [
              -82.205171,
              38.591719
            ],
            [
              -82.218967,
              38.591683
            ],
            [
              -82.055127,
              38.474547
            ],
            [
              -82.064562,
              38.607985
            ],
            [
              -81.957338,
              38.656644
            ],
            [
              -81.951411,
              38.657902
            ],
            [
              -81.772843,
              38.680843
            ],
            [
              -81.8135,
              38.714761
            ],
            [
              -81.791265,
              38.733104
            ],
            [
              -81.831115,
              38.779658
            ],
            [
              -81.902851,
              38.863324
            ],
            [
              -81.910156,
              38.875081
            ],
            [
              -81.908645,
              38.87846
            ],
            [
              -81.910312,
              38.879294
            ],
            [
              -81.915898,
              38.88427
            ],
            [
              -81.926967,
              38.891602
            ],
            [
              -81.928,
              38.893492
            ],
            [
              -81.928352,
              38.895371
            ],
            [
              -81.926671,
              38.901311
            ],
            [
              -81.919098,
              38.908615
            ],
            [
              -81.917757,
              38.910604
            ],
            [
              -81.911936,
              38.915564
            ],
            [
              -81.908341,
              38.917403
            ],
            [
              -81.90091,
              38.924338
            ],
            [
              -81.899953,
              38.925405
            ],
            [
              -81.89847,
              38.929603
            ],
            [
              -81.900595,
              38.937671
            ],
            [
              -81.9066,
              38.945262
            ],
            [
              -81.912443,
              38.954343
            ],
            [
              -81.918214,
              38.966595
            ],
            [
              -81.926561,
              38.977508
            ],
            [
              -81.933186,
              38.987659
            ],
            [
              -81.936828,
              38.990414
            ],
            [
              -81.941829,
              38.993295
            ],
            [
              -81.951447,
              38.996032
            ],
            [
              -81.95926,
              38.995227
            ],
            [
              -81.960832,
              38.994275
            ],
            [
              -81.967769,
              38.992955
            ],
            [
              -81.973871,
              38.992413
            ],
            [
              -81.977455,
              38.992679
            ],
            [
              -81.979371,
              38.993193
            ],
            [
              -81.981158,
              38.994351
            ],
            [
              -81.982032,
              38.995697
            ],
            [
              -81.982761,
              39.001978
            ],
            [
              -81.983761,
              39.005478
            ],
            [
              -81.987061,
              39.011978
            ],
            [
              -81.991361,
              39.018378
            ],
            [
              -81.994961,
              39.022478
            ],
            [
              -82.002261,
              39.027878
            ],
            [
              -82.007062,
              39.029578
            ],
            [
              -82.017562,
              39.030078
            ],
            [
              -82.027262,
              39.028378
            ],
            [
              -82.035963,
              39.025478
            ],
            [
              -82.038763,
              39.022678
            ],
            [
              -82.041563,
              39.017878
            ],
            [
              -82.045663,
              39.003778
            ],
            [
              -82.049163,
              38.997278
            ],
            [
              -82.051563,
              38.994378
            ],
            [
              -82.058764,
              38.990078
            ],
            [
              -82.068864,
              38.984878
            ],
            [
              -82.079364,
              38.981078
            ],
            [
              -82.089065,
              38.975978
            ],
            [
              -82.091565,
              38.973778
            ],
            [
              -82.093165,
              38.97098
            ],
            [
              -82.094865,
              38.964578
            ],
            [
              -82.0988733706356,
              38.9583193160251
            ],
            [
              -82.100565,
              38.955678
            ],
            [
              -82.109065,
              38.945579
            ],
            [
              -82.110866,
              38.940379
            ],
            [
              -82.110565,
              38.935279
            ],
            [
              -82.111666,
              38.932579
            ],
            [
              -82.120966,
              38.921079
            ],
            [
              -82.128866,
              38.909979
            ],
            [
              -82.134766,
              38.905579
            ],
            [
              -82.143167,
              38.898079
            ],
            [
              -82.144567,
              38.891679
            ],
            [
              -82.145267,
              38.883479
            ],
            [
              -82.142167,
              38.87708
            ],
            [
              -82.1410712160353,
              38.873693077098594
            ],
            [
              -82.139988,
              38.870345
            ],
            [
              -82.139224,
              38.86502
            ],
            [
              -82.141616,
              38.851394
            ],
            [
              -82.144867,
              38.84048
            ],
            [
              -82.147667,
              38.83698
            ],
            [
              -82.16157,
              38.824632
            ],
            [
              -82.165898,
              38.822437
            ],
            [
              -82.179478,
              38.817376
            ],
            [
              -82.191172,
              38.815137
            ],
            [
              -82.19928,
              38.808688
            ],
            [
              -82.20929,
              38.802672
            ],
            [
              -82.214494,
              38.798691
            ],
            [
              -82.217269,
              38.79568
            ],
            [
              -82.221566,
              38.787187
            ]
          ]
        ]
      ]
    }
  }
]
};