export const counties_ks = {
  "type":"FeatureCollection","name":"ks_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "137",
      "COUNTYNS": "00485032",
      "AFFGEOID": "0500000US20137",
      "GEOID": "20137",
      "NAME": "Norton",
      "LSAD": "06",
      "ALAND": 2274122723,
      "AWATER": 8421464,
      "County_state": "Norton,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.177794883236,
              40.001592986460395
            ],
            [
              -100.180351,
              39.56689
            ],
            [
              -100.161667,
              39.567277
            ],
            [
              -99.627953,
              39.567437
            ],
            [
              -99.6253240088811,
              40.001865850441
            ],
            [
              -99.62598,
              40.001865
            ],
            [
              -99.6282545420969,
              40.0018659613449
            ],
            [
              -99.628346,
              40.001866
            ],
            [
              -99.719639,
              40.001808
            ],
            [
              -99.731959,
              40.001827
            ],
            [
              -99.746628,
              40.00182
            ],
            [
              -99.756835,
              40.001342
            ],
            [
              -99.764214,
              40.001551
            ],
            [
              -99.772121,
              40.001804
            ],
            [
              -99.77564,
              40.001647
            ],
            [
              -99.813401,
              40.0014
            ],
            [
              -99.906658,
              40.001512
            ],
            [
              -99.930433,
              40.001516
            ],
            [
              -99.944417,
              40.001584
            ],
            [
              -99.948167,
              40.001813
            ],
            [
              -99.986611,
              40.00155
            ],
            [
              -99.990926,
              40.001503
            ],
            [
              -100.177794883236,
              40.001592986460395
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "073",
      "COUNTYNS": "00485003",
      "AFFGEOID": "0500000US20073",
      "GEOID": "20073",
      "NAME": "Greenwood",
      "LSAD": "06",
      "ALAND": 2961121922,
      "AWATER": 24166737,
      "County_state": "Greenwood,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.525712,
              37.825089
            ],
            [
              -96.5253,
              37.607015
            ],
            [
              -95.961002,
              37.603761
            ],
            [
              -95.960876,
              37.734304
            ],
            [
              -95.961408,
              37.821985
            ],
            [
              -95.959046,
              38.040195
            ],
            [
              -95.958862,
              38.170939
            ],
            [
              -96.357277,
              38.17266
            ],
            [
              -96.358099,
              38.085817
            ],
            [
              -96.522782,
              38.08637
            ],
            [
              -96.525712,
              37.825089
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "101",
      "COUNTYNS": "00485015",
      "AFFGEOID": "0500000US20101",
      "GEOID": "20101",
      "NAME": "Lane",
      "LSAD": "06",
      "ALAND": 1858093492,
      "AWATER": 551220,
      "County_state": "Lane,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.688006,
              38.700021
            ],
            [
              -100.684699,
              38.264137
            ],
            [
              -100.464287,
              38.263111
            ],
            [
              -100.244393,
              38.26229
            ],
            [
              -100.2472,
              38.698165
            ],
            [
              -100.688006,
              38.700021
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "115",
      "COUNTYNS": "00485022",
      "AFFGEOID": "0500000US20115",
      "GEOID": "20115",
      "NAME": "Marion",
      "LSAD": "06",
      "ALAND": 2445701362,
      "AWATER": 24448710,
      "County_state": "Marion,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.371911,
              38.609353
            ],
            [
              -97.37167,
              38.347989
            ],
            [
              -97.371627,
              38.260833
            ],
            [
              -97.37175,
              38.173673
            ],
            [
              -97.153093,
              38.174634
            ],
            [
              -97.152913,
              38.087704
            ],
            [
              -96.840772,
              38.085622
            ],
            [
              -96.840929,
              38.261284
            ],
            [
              -96.837801,
              38.27583
            ],
            [
              -96.837654,
              38.348636
            ],
            [
              -96.819475,
              38.348502
            ],
            [
              -96.819723,
              38.522464
            ],
            [
              -96.929969,
              38.522412
            ],
            [
              -96.930286,
              38.609362
            ],
            [
              -97.371911,
              38.609353
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "007",
      "COUNTYNS": "00484973",
      "AFFGEOID": "0500000US20007",
      "GEOID": "20007",
      "NAME": "Barber",
      "LSAD": "06",
      "ALAND": 2937248475,
      "AWATER": 5403867,
      "County_state": "Barber,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.012598,
              37.470421
            ],
            [
              -99.011499,
              37.384263
            ],
            [
              -99.001294,
              37.384296
            ],
            [
              -99.00030291303248,
              36.999510304099104
            ],
            [
              -98.994371,
              36.999493
            ],
            [
              -98.88058,
              36.999309
            ],
            [
              -98.880009,
              36.999263
            ],
            [
              -98.869449,
              36.999286
            ],
            [
              -98.797452,
              36.999229
            ],
            [
              -98.793711,
              36.999227
            ],
            [
              -98.791936,
              36.999255
            ],
            [
              -98.761597,
              36.999425
            ],
            [
              -98.718465,
              36.99918
            ],
            [
              -98.714512,
              36.99906
            ],
            [
              -98.544872,
              36.998997
            ],
            [
              -98.5446600848551,
              36.9989961823461
            ],
            [
              -98.420209,
              36.998516
            ],
            [
              -98.418268,
              36.998538
            ],
            [
              -98.408991,
              36.998513
            ],
            [
              -98.354073,
              36.997961
            ],
            [
              -98.3471489755077,
              36.9979618781261
            ],
            [
              -98.349804,
              37.384056
            ],
            [
              -98.464951,
              37.384086
            ],
            [
              -98.464663,
              37.471013
            ],
            [
              -99.012598,
              37.470421
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "047",
      "COUNTYNS": "00484993",
      "AFFGEOID": "0500000US20047",
      "GEOID": "20047",
      "NAME": "Edwards",
      "LSAD": "06",
      "ALAND": 1610699247,
      "AWATER": 206413,
      "County_state": "Edwards,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.569879,
              37.913016
            ],
            [
              -99.570576,
              37.825634
            ],
            [
              -99.560272,
              37.825587
            ],
            [
              -99.55958,
              37.734888
            ],
            [
              -99.01355,
              37.73297
            ],
            [
              -99.013318,
              37.825336
            ],
            [
              -99.022627,
              37.825358
            ],
            [
              -99.022136,
              38.000236
            ],
            [
              -99.240685,
              37.999968
            ],
            [
              -99.350289,
              38.000745
            ],
            [
              -99.350359,
              38.087594
            ],
            [
              -99.569533,
              38.087372
            ],
            [
              -99.569879,
              37.913016
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "193",
      "COUNTYNS": "00485057",
      "AFFGEOID": "0500000US20193",
      "GEOID": "20193",
      "NAME": "Thomas",
      "LSAD": "06",
      "ALAND": 2783425154,
      "AWATER": 288241,
      "County_state": "Thomas,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.391256,
              39.308825
            ],
            [
              -101.391717,
              39.135117
            ],
            [
              -100.811858,
              39.13336
            ],
            [
              -100.721296,
              39.133381
            ],
            [
              -100.720213,
              39.568034
            ],
            [
              -100.740873,
              39.568043
            ],
            [
              -100.942872,
              39.567984
            ],
            [
              -101.389068,
              39.568369
            ],
            [
              -101.391256,
              39.308825
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "057",
      "COUNTYNS": "00484997",
      "AFFGEOID": "0500000US20057",
      "GEOID": "20057",
      "NAME": "Ford",
      "LSAD": "06",
      "ALAND": 2844508248,
      "AWATER": 2097356,
      "County_state": "Ford,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.22709,
              37.914018
            ],
            [
              -100.227416,
              37.826842
            ],
            [
              -100.214637,
              37.826808
            ],
            [
              -100.216229,
              37.47519
            ],
            [
              -100.107248,
              37.474836
            ],
            [
              -99.99779,
              37.47278
            ],
            [
              -99.774512,
              37.467404
            ],
            [
              -99.556119,
              37.467722
            ],
            [
              -99.55958,
              37.734888
            ],
            [
              -99.560272,
              37.825587
            ],
            [
              -99.570576,
              37.825634
            ],
            [
              -99.569879,
              37.913016
            ],
            [
              -99.873763,
              37.913214
            ],
            [
              -100.22709,
              37.914018
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "017",
      "COUNTYNS": "00484978",
      "AFFGEOID": "0500000US20017",
      "GEOID": "20017",
      "NAME": "Chase",
      "LSAD": "06",
      "ALAND": 2002208724,
      "AWATER": 12087996,
      "County_state": "Chase,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.840929,
              38.261284
            ],
            [
              -96.840772,
              38.085622
            ],
            [
              -96.522782,
              38.08637
            ],
            [
              -96.358099,
              38.085817
            ],
            [
              -96.357277,
              38.17266
            ],
            [
              -96.355229,
              38.318383
            ],
            [
              -96.35378,
              38.521657
            ],
            [
              -96.819723,
              38.522464
            ],
            [
              -96.819475,
              38.348502
            ],
            [
              -96.837654,
              38.348636
            ],
            [
              -96.837801,
              38.27583
            ],
            [
              -96.840929,
              38.261284
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "207",
      "COUNTYNS": "00485064",
      "AFFGEOID": "0500000US20207",
      "GEOID": "20207",
      "NAME": "Woodson",
      "LSAD": "06",
      "ALAND": 1289347405,
      "AWATER": 19251486,
      "County_state": "Woodson,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.961408,
              37.821985
            ],
            [
              -95.960876,
              37.734304
            ],
            [
              -95.525499,
              37.732759
            ],
            [
              -95.524862,
              37.820285
            ],
            [
              -95.519308,
              37.820259
            ],
            [
              -95.51897,
              38.03823
            ],
            [
              -95.959046,
              38.040195
            ],
            [
              -95.961408,
              37.821985
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "199",
      "COUNTYNS": "00485060",
      "AFFGEOID": "0500000US20199",
      "GEOID": "20199",
      "NAME": "Wallace",
      "LSAD": "06",
      "ALAND": 2366348295,
      "AWATER": 140932,
      "County_state": "Wallace,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.047188609269,
              39.13314656241629
            ],
            [
              -102.047134,
              39.129701
            ],
            [
              -102.046571,
              39.047038
            ],
            [
              -102.045388,
              38.813392
            ],
            [
              -102.045334,
              38.799463
            ],
            [
              -102.045448,
              38.783453
            ],
            [
              -102.045371,
              38.770064
            ],
            [
              -102.045287,
              38.755528
            ],
            [
              -102.045375,
              38.754339
            ],
            [
              -102.045212,
              38.697567
            ],
            [
              -101.567094,
              38.699669
            ],
            [
              -101.484383,
              38.700166
            ],
            [
              -101.478195,
              39.134761
            ],
            [
              -102.047188609269,
              39.13314656241629
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "059",
      "COUNTYNS": "00484998",
      "AFFGEOID": "0500000US20059",
      "GEOID": "20059",
      "NAME": "Franklin",
      "LSAD": "06",
      "ALAND": 1480855486,
      "AWATER": 13920174,
      "County_state": "Franklin,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.508667,
              38.623101
            ],
            [
              -95.508267,
              38.433933
            ],
            [
              -95.508328,
              38.390278
            ],
            [
              -95.360246,
              38.390137
            ],
            [
              -95.065831,
              38.389945
            ],
            [
              -95.065526,
              38.51655
            ],
            [
              -95.065245,
              38.608064
            ],
            [
              -95.065772,
              38.694903
            ],
            [
              -95.056412,
              38.738587
            ],
            [
              -95.167545,
              38.738471
            ],
            [
              -95.24159,
              38.738678
            ],
            [
              -95.265941,
              38.738625
            ],
            [
              -95.500738,
              38.738808
            ],
            [
              -95.501223,
              38.695263
            ],
            [
              -95.508042,
              38.695268
            ],
            [
              -95.508667,
              38.623101
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "133",
      "COUNTYNS": "00485030",
      "AFFGEOID": "0500000US20133",
      "GEOID": "20133",
      "NAME": "Neosho",
      "LSAD": "06",
      "ALAND": 1480106489,
      "AWATER": 16374155,
      "County_state": "Neosho,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.525042,
              37.514663
            ],
            [
              -95.52556,
              37.383979
            ],
            [
              -95.52113,
              37.38399
            ],
            [
              -95.379277,
              37.38402
            ],
            [
              -95.088189,
              37.383837
            ],
            [
              -95.088012,
              37.674519
            ],
            [
              -95.088082,
              37.732476
            ],
            [
              -95.124987,
              37.733924
            ],
            [
              -95.233963,
              37.732531
            ],
            [
              -95.378793,
              37.732906
            ],
            [
              -95.525499,
              37.732759
            ],
            [
              -95.525042,
              37.514663
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "029",
      "COUNTYNS": "00484984",
      "AFFGEOID": "0500000US20029",
      "GEOID": "20029",
      "NAME": "Cloud",
      "LSAD": "06",
      "ALAND": 1852726628,
      "AWATER": 6859887,
      "County_state": "Cloud,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.931482,
              39.653767
            ],
            [
              -97.931844,
              39.566921
            ],
            [
              -97.928462,
              39.566915
            ],
            [
              -97.929097,
              39.306397
            ],
            [
              -97.369839,
              39.306011
            ],
            [
              -97.368574,
              39.567018
            ],
            [
              -97.36867,
              39.654043
            ],
            [
              -97.931482,
              39.653767
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "135",
      "COUNTYNS": "00485031",
      "AFFGEOID": "0500000US20135",
      "GEOID": "20135",
      "NAME": "Ness",
      "LSAD": "06",
      "ALAND": 2783557951,
      "AWATER": 667491,
      "County_state": "Ness,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.2472,
              38.698165
            ],
            [
              -100.244393,
              38.26229
            ],
            [
              -100.227137,
              38.262237
            ],
            [
              -99.584207,
              38.262179
            ],
            [
              -99.584794,
              38.349386
            ],
            [
              -99.585087,
              38.696537
            ],
            [
              -99.598323,
              38.696514
            ],
            [
              -100.153823,
              38.697341
            ],
            [
              -100.2472,
              38.698165
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "003",
      "COUNTYNS": "00484971",
      "AFFGEOID": "0500000US20003",
      "GEOID": "20003",
      "NAME": "Anderson",
      "LSAD": "06",
      "ALAND": 1501277564,
      "AWATER": 10586104,
      "County_state": "Anderson,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.516757,
              38.259296
            ],
            [
              -95.51897,
              38.03823
            ],
            [
              -95.299176,
              38.038067
            ],
            [
              -95.224902,
              38.037805
            ],
            [
              -95.077876,
              38.037706
            ],
            [
              -95.076712,
              38.15412
            ],
            [
              -95.076667,
              38.168252
            ],
            [
              -95.075459,
              38.258714
            ],
            [
              -95.065103,
              38.258648
            ],
            [
              -95.065831,
              38.389945
            ],
            [
              -95.360246,
              38.390137
            ],
            [
              -95.508328,
              38.390278
            ],
            [
              -95.507474,
              38.259248
            ],
            [
              -95.516757,
              38.259296
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "051",
      "COUNTYNS": "00484995",
      "AFFGEOID": "0500000US20051",
      "GEOID": "20051",
      "NAME": "Ellis",
      "LSAD": "06",
      "ALAND": 2330762291,
      "AWATER": 1197085,
      "County_state": "Ellis,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.591776,
              39.132357
            ],
            [
              -99.598323,
              38.696514
            ],
            [
              -99.585087,
              38.696537
            ],
            [
              -99.320364,
              38.696823
            ],
            [
              -99.25371,
              38.696934
            ],
            [
              -99.042626,
              38.696807
            ],
            [
              -99.039447,
              38.856109
            ],
            [
              -99.0375,
              39.133121
            ],
            [
              -99.047687,
              39.133014
            ],
            [
              -99.591776,
              39.132357
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "085",
      "COUNTYNS": "00485007",
      "AFFGEOID": "0500000US20085",
      "GEOID": "20085",
      "NAME": "Jackson",
      "LSAD": "06",
      "ALAND": 1699600373,
      "AWATER": 4666514,
      "County_state": "Jackson,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.035952,
              39.5661
            ],
            [
              -96.034976,
              39.274576
            ],
            [
              -96.035573,
              39.21652
            ],
            [
              -95.905235,
              39.216595
            ],
            [
              -95.589472,
              39.216081
            ],
            [
              -95.589295,
              39.419086
            ],
            [
              -95.570351,
              39.41905
            ],
            [
              -95.570348,
              39.56587
            ],
            [
              -95.564308,
              39.567294
            ],
            [
              -95.564126,
              39.652872
            ],
            [
              -95.788941,
              39.653001
            ],
            [
              -95.789098,
              39.565943
            ],
            [
              -96.035952,
              39.5661
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "171",
      "COUNTYNS": "00485048",
      "AFFGEOID": "0500000US20171",
      "GEOID": "20171",
      "NAME": "Scott",
      "LSAD": "06",
      "ALAND": 1858536844,
      "AWATER": 306079,
      "County_state": "Scott,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.128379,
              38.700603
            ],
            [
              -101.125438,
              38.264509
            ],
            [
              -101.103268,
              38.264557
            ],
            [
              -100.684699,
              38.264137
            ],
            [
              -100.688006,
              38.700021
            ],
            [
              -100.811244,
              38.699695
            ],
            [
              -100.818698,
              38.699861
            ],
            [
              -101.128379,
              38.700603
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "151",
      "COUNTYNS": "00485039",
      "AFFGEOID": "0500000US20151",
      "GEOID": "20151",
      "NAME": "Pratt",
      "LSAD": "06",
      "ALAND": 1903740958,
      "AWATER": 1771939,
      "County_state": "Pratt,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.013318,
              37.825336
            ],
            [
              -99.01355,
              37.73297
            ],
            [
              -99.012598,
              37.470421
            ],
            [
              -98.464663,
              37.471013
            ],
            [
              -98.464801,
              37.557939
            ],
            [
              -98.464804,
              37.732675
            ],
            [
              -98.464906,
              37.823149
            ],
            [
              -98.472257,
              37.824497
            ],
            [
              -99.013318,
              37.825336
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "037",
      "COUNTYNS": "00484988",
      "AFFGEOID": "0500000US20037",
      "GEOID": "20037",
      "NAME": "Crawford",
      "LSAD": "06",
      "ALAND": 1527463453,
      "AWATER": 13609414,
      "County_state": "Crawford,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.088012,
              37.674519
            ],
            [
              -95.088189,
              37.383837
            ],
            [
              -95.074972,
              37.383823
            ],
            [
              -95.075166,
              37.339937
            ],
            [
              -94.820861,
              37.339387
            ],
            [
              -94.61763608310468,
              37.3384147815258
            ],
            [
              -94.617636,
              37.338417
            ],
            [
              -94.61753771716201,
              37.3641671035633
            ],
            [
              -94.617537,
              37.364355
            ],
            [
              -94.617626,
              37.367445
            ],
            [
              -94.617625,
              37.367576
            ],
            [
              -94.617557,
              37.396375
            ],
            [
              -94.617511,
              37.410909
            ],
            [
              -94.617265,
              37.425536
            ],
            [
              -94.617132,
              37.439818
            ],
            [
              -94.6172009810493,
              37.4547838885303
            ],
            [
              -94.617201,
              37.454788
            ],
            [
              -94.617205,
              37.460373
            ],
            [
              -94.617222,
              37.460476
            ],
            [
              -94.61718,
              37.465203
            ],
            [
              -94.617183,
              37.469665
            ],
            [
              -94.617023,
              37.483765
            ],
            [
              -94.616789,
              37.52151
            ],
            [
              -94.616908,
              37.527804
            ],
            [
              -94.617186,
              37.553485
            ],
            [
              -94.61716,
              37.557308
            ],
            [
              -94.617081,
              37.567013
            ],
            [
              -94.617315,
              37.571499
            ],
            [
              -94.617283,
              37.571896
            ],
            [
              -94.617428,
              37.609522
            ],
            [
              -94.6173,
              37.610495
            ],
            [
              -94.617477,
              37.63717
            ],
            [
              -94.6175754334997,
              37.6535765775648
            ],
            [
              -94.617576,
              37.653671
            ],
            [
              -94.6177338243197,
              37.6731053668666
            ],
            [
              -94.887228,
              37.674876
            ],
            [
              -94.906328,
              37.674593
            ],
            [
              -95.088012,
              37.674519
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "111",
      "COUNTYNS": "00485020",
      "AFFGEOID": "0500000US20111",
      "GEOID": "20111",
      "NAME": "Lyon",
      "LSAD": "06",
      "ALAND": 2194957493,
      "AWATER": 20562422,
      "County_state": "Lyon,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.355229,
              38.318383
            ],
            [
              -96.357277,
              38.17266
            ],
            [
              -95.958862,
              38.170939
            ],
            [
              -95.958502,
              38.259718
            ],
            [
              -95.949994,
              38.259706
            ],
            [
              -95.950282,
              38.434105
            ],
            [
              -95.949969,
              38.477704
            ],
            [
              -95.949654,
              38.695385
            ],
            [
              -95.945924,
              38.739112
            ],
            [
              -96.186638,
              38.73898
            ],
            [
              -96.352613,
              38.739021
            ],
            [
              -96.353088,
              38.608893
            ],
            [
              -96.35378,
              38.521657
            ],
            [
              -96.355229,
              38.318383
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "147",
      "COUNTYNS": "00485037",
      "AFFGEOID": "0500000US20147",
      "GEOID": "20147",
      "NAME": "Phillips",
      "LSAD": "06",
      "ALAND": 2294395642,
      "AWATER": 22493382,
      "County_state": "Phillips,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.6253240088811,
              40.001865850441
            ],
            [
              -99.627953,
              39.567437
            ],
            [
              -99.602176,
              39.567328
            ],
            [
              -99.402734,
              39.567294
            ],
            [
              -99.06622,
              39.568125
            ],
            [
              -99.067021970224,
              40.0021702872539
            ],
            [
              -99.085597,
              40.002133
            ],
            [
              -99.11351,
              40.002193
            ],
            [
              -99.123033,
              40.002165
            ],
            [
              -99.169816,
              40.001925
            ],
            [
              -99.178965,
              40.001977
            ],
            [
              -99.179134,
              40.001977
            ],
            [
              -99.186962,
              40.001977
            ],
            [
              -99.188905,
              40.002023
            ],
            [
              -99.197592,
              40.002033
            ],
            [
              -99.216376,
              40.002016
            ],
            [
              -99.25037,
              40.001957
            ],
            [
              -99.254012,
              40.002074
            ],
            [
              -99.282967,
              40.001879
            ],
            [
              -99.286656,
              40.002017
            ],
            [
              -99.290703,
              40.001949
            ],
            [
              -99.403389,
              40.001969
            ],
            [
              -99.412645,
              40.001868
            ],
            [
              -99.423565,
              40.00227
            ],
            [
              -99.480728,
              40.001942
            ],
            [
              -99.493465,
              40.001937
            ],
            [
              -99.49766,
              40.001912
            ],
            [
              -99.498999,
              40.001957
            ],
            [
              -99.501792,
              40.002026
            ],
            [
              -99.6253240088811,
              40.001865850441
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "067",
      "COUNTYNS": "00485099",
      "AFFGEOID": "0500000US20067",
      "GEOID": "20067",
      "NAME": "Grant",
      "LSAD": "06",
      "ALAND": 1488724884,
      "AWATER": 892323,
      "County_state": "Grant,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.527063,
              37.736314
            ],
            [
              -101.52581,
              37.388392
            ],
            [
              -101.089653,
              37.387721
            ],
            [
              -101.08965,
              37.489526
            ],
            [
              -101.089667,
              37.736337
            ],
            [
              -101.527063,
              37.736314
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "065",
      "COUNTYNS": "00481811",
      "AFFGEOID": "0500000US20065",
      "GEOID": "20065",
      "NAME": "Graham",
      "LSAD": "06",
      "ALAND": 2327173855,
      "AWATER": 599356,
      "County_state": "Graham,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.161667,
              39.567277
            ],
            [
              -100.1642,
              39.132071
            ],
            [
              -100.14794,
              39.132179
            ],
            [
              -99.605187,
              39.132481
            ],
            [
              -99.602498,
              39.394324
            ],
            [
              -99.602304,
              39.437636
            ],
            [
              -99.602176,
              39.567328
            ],
            [
              -99.627953,
              39.567437
            ],
            [
              -100.161667,
              39.567277
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "001",
      "COUNTYNS": "00484970",
      "AFFGEOID": "0500000US20001",
      "GEOID": "20001",
      "NAME": "Allen",
      "LSAD": "06",
      "ALAND": 1295762491,
      "AWATER": 13023677,
      "County_state": "Allen,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.524862,
              37.820285
            ],
            [
              -95.525499,
              37.732759
            ],
            [
              -95.378793,
              37.732906
            ],
            [
              -95.233963,
              37.732531
            ],
            [
              -95.124987,
              37.733924
            ],
            [
              -95.088082,
              37.732476
            ],
            [
              -95.087839,
              37.761405
            ],
            [
              -95.087808,
              37.819815
            ],
            [
              -95.079692,
              37.8198
            ],
            [
              -95.079559,
              37.906826
            ],
            [
              -95.077876,
              38.037706
            ],
            [
              -95.224902,
              38.037805
            ],
            [
              -95.299176,
              38.038067
            ],
            [
              -95.51897,
              38.03823
            ],
            [
              -95.519308,
              37.820259
            ],
            [
              -95.524862,
              37.820285
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "195",
      "COUNTYNS": "00485058",
      "AFFGEOID": "0500000US20195",
      "GEOID": "20195",
      "NAME": "Trego",
      "LSAD": "06",
      "ALAND": 2303731642,
      "AWATER": 26281951,
      "County_state": "Trego,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.14794,
              39.132179
            ],
            [
              -100.153823,
              38.697341
            ],
            [
              -99.598323,
              38.696514
            ],
            [
              -99.591776,
              39.132357
            ],
            [
              -99.605187,
              39.132481
            ],
            [
              -100.14794,
              39.132179
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "049",
      "COUNTYNS": "00484994",
      "AFFGEOID": "0500000US20049",
      "GEOID": "20049",
      "NAME": "Elk",
      "LSAD": "06",
      "ALAND": 1668636733,
      "AWATER": 16061122,
      "County_state": "Elk,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.5253,
              37.607015
            ],
            [
              -96.52569,
              37.476405
            ],
            [
              -96.524873,
              37.30273
            ],
            [
              -96.272384,
              37.30072
            ],
            [
              -95.964399,
              37.299232
            ],
            [
              -95.964337,
              37.375118
            ],
            [
              -95.961605,
              37.386636
            ],
            [
              -95.962311,
              37.430273
            ],
            [
              -95.961002,
              37.603761
            ],
            [
              -96.5253,
              37.607015
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "075",
      "COUNTYNS": "00485327",
      "AFFGEOID": "0500000US20075",
      "GEOID": "20075",
      "NAME": "Hamilton",
      "LSAD": "06",
      "ALAND": 2580958344,
      "AWATER": 2893322,
      "County_state": "Hamilton,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.044255,
              38.113011
            ],
            [
              -102.044644,
              38.045532
            ],
            [
              -102.043844,
              37.928102
            ],
            [
              -102.043845,
              37.926135
            ],
            [
              -102.043219,
              37.867929
            ],
            [
              -102.043033,
              37.824146
            ],
            [
              -102.042953,
              37.803535
            ],
            [
              -102.042668,
              37.788758
            ],
            [
              -102.042158,
              37.760164
            ],
            [
              -102.041989965863,
              37.7385406283736
            ],
            [
              -101.527063,
              37.736314
            ],
            [
              -101.526964,
              37.827626
            ],
            [
              -101.542757,
              37.827594
            ],
            [
              -101.542312,
              38.263207
            ],
            [
              -101.567482,
              38.263145
            ],
            [
              -102.04451007292,
              38.2624115834718
            ],
            [
              -102.044398,
              38.250015
            ],
            [
              -102.044251,
              38.141778
            ],
            [
              -102.044589,
              38.125013
            ],
            [
              -102.044255,
              38.113011
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "039",
      "COUNTYNS": "00484989",
      "AFFGEOID": "0500000US20039",
      "GEOID": "20039",
      "NAME": "Decatur",
      "LSAD": "06",
      "ALAND": 2314199314,
      "AWATER": 1631670,
      "County_state": "Decatur,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.73882584525201,
              40.002228424417495
            ],
            [
              -100.740873,
              39.568043
            ],
            [
              -100.720213,
              39.568034
            ],
            [
              -100.180351,
              39.56689
            ],
            [
              -100.177794883236,
              40.001592986460395
            ],
            [
              -100.177823,
              40.001593
            ],
            [
              -100.188181,
              40.001541
            ],
            [
              -100.190323,
              40.001586
            ],
            [
              -100.19359,
              40.001573
            ],
            [
              -100.193596885795,
              40.001572838534294
            ],
            [
              -100.196959,
              40.001494
            ],
            [
              -100.215406,
              40.001629
            ],
            [
              -100.229479,
              40.001693
            ],
            [
              -100.231652,
              40.001623
            ],
            [
              -100.39008,
              40.001809
            ],
            [
              -100.439081,
              40.001774
            ],
            [
              -100.447072,
              40.001795
            ],
            [
              -100.468773,
              40.001724
            ],
            [
              -100.475854,
              40.001768
            ],
            [
              -100.477018,
              40.001752
            ],
            [
              -100.487159,
              40.001767
            ],
            [
              -100.511065,
              40.00184
            ],
            [
              -100.551886,
              40.001889
            ],
            [
              -100.567238,
              40.001889
            ],
            [
              -100.594757,
              40.001977
            ],
            [
              -100.600945,
              40.001906
            ],
            [
              -100.645445,
              40.001883
            ],
            [
              -100.66023,
              40.002162
            ],
            [
              -100.683435,
              40.002234
            ],
            [
              -100.721128,
              40.002069
            ],
            [
              -100.729904,
              40.002111
            ],
            [
              -100.733296,
              40.00227
            ],
            [
              -100.73882584525201,
              40.002228424417495
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "201",
      "COUNTYNS": "00485061",
      "AFFGEOID": "0500000US20201",
      "GEOID": "20201",
      "NAME": "Washington",
      "LSAD": "06",
      "ALAND": 2317419238,
      "AWATER": 10139803,
      "County_state": "Washington,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.3691985101944,
              40.0020598794115
            ],
            [
              -97.3691991185285,
              40.002059878643394
            ],
            [
              -97.36867,
              39.654043
            ],
            [
              -97.368574,
              39.567018
            ],
            [
              -96.958719,
              39.566401
            ],
            [
              -96.806544,
              39.566423
            ],
            [
              -96.806201,
              39.827538
            ],
            [
              -96.805768,
              40.0013709706497
            ],
            [
              -96.873812,
              40.00145
            ],
            [
              -96.875057,
              40.001448
            ],
            [
              -96.878253,
              40.001466
            ],
            [
              -96.880459,
              40.001448
            ],
            [
              -96.916093,
              40.001506
            ],
            [
              -96.9164069976229,
              40.0015058549306
            ],
            [
              -97.009165,
              40.001463
            ],
            [
              -97.030803,
              40.001342
            ],
            [
              -97.049663,
              40.001323
            ],
            [
              -97.137866,
              40.001814
            ],
            [
              -97.142448,
              40.001495
            ],
            [
              -97.181775,
              40.00155
            ],
            [
              -97.20019,
              40.001549
            ],
            [
              -97.20231,
              40.001442
            ],
            [
              -97.24508,
              40.001467
            ],
            [
              -97.245169,
              40.001513
            ],
            [
              -97.350272,
              40.001976
            ],
            [
              -97.350896,
              40.00193
            ],
            [
              -97.369103,
              40.00206
            ],
            [
              -97.3691985101944,
              40.0020598794115
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "069",
      "COUNTYNS": "00485001",
      "AFFGEOID": "0500000US20069",
      "GEOID": "20069",
      "NAME": "Gray",
      "LSAD": "06",
      "ALAND": 2250356164,
      "AWATER": 1113338,
      "County_state": "Gray,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.664166,
              38.00253
            ],
            [
              -100.665172,
              37.828831
            ],
            [
              -100.651748,
              37.828712
            ],
            [
              -100.652251,
              37.736275
            ],
            [
              -100.652642,
              37.474848
            ],
            [
              -100.216229,
              37.47519
            ],
            [
              -100.214637,
              37.826808
            ],
            [
              -100.227416,
              37.826842
            ],
            [
              -100.22709,
              37.914018
            ],
            [
              -100.22661,
              38.001015
            ],
            [
              -100.44549,
              38.000698
            ],
            [
              -100.664166,
              38.00253
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "061",
      "COUNTYNS": "00484999",
      "AFFGEOID": "0500000US20061",
      "GEOID": "20061",
      "NAME": "Geary",
      "LSAD": "06",
      "ALAND": 996280339,
      "AWATER": 51594747,
      "County_state": "Geary,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.962956,
              39.059802
            ],
            [
              -96.96347,
              38.964901
            ],
            [
              -96.933478,
              38.978553
            ],
            [
              -96.926209,
              38.978941
            ],
            [
              -96.92671,
              38.87035
            ],
            [
              -96.890245,
              38.870067
            ],
            [
              -96.815905,
              38.869893
            ],
            [
              -96.501556,
              38.869704
            ],
            [
              -96.501166,
              39.043666
            ],
            [
              -96.500962,
              39.072681
            ],
            [
              -96.519668,
              39.072872
            ],
            [
              -96.67948,
              39.073114
            ],
            [
              -96.706122,
              39.073077
            ],
            [
              -96.7063,
              39.091136
            ],
            [
              -96.728177,
              39.088358
            ],
            [
              -96.826454,
              39.088122
            ],
            [
              -96.851409,
              39.088176
            ],
            [
              -96.851269,
              39.10074
            ],
            [
              -96.832271,
              39.119721
            ],
            [
              -96.830985,
              39.139228
            ],
            [
              -96.84967,
              39.13928
            ],
            [
              -96.849879,
              39.219012
            ],
            [
              -96.961693,
              39.220076
            ],
            [
              -96.96138,
              39.132325
            ],
            [
              -96.963177,
              39.1323
            ],
            [
              -96.962956,
              39.059802
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "203",
      "COUNTYNS": "00485062",
      "AFFGEOID": "0500000US20203",
      "GEOID": "20203",
      "NAME": "Wichita",
      "LSAD": "06",
      "ALAND": 1861078210,
      "AWATER": 61987,
      "County_state": "Wichita,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.567094,
              38.699669
            ],
            [
              -101.567482,
              38.263145
            ],
            [
              -101.542312,
              38.263207
            ],
            [
              -101.125438,
              38.264509
            ],
            [
              -101.128379,
              38.700603
            ],
            [
              -101.484383,
              38.700166
            ],
            [
              -101.567094,
              38.699669
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "027",
      "COUNTYNS": "00484983",
      "AFFGEOID": "0500000US20027",
      "GEOID": "20027",
      "NAME": "Clay",
      "LSAD": "06",
      "ALAND": 1671314205,
      "AWATER": 26701337,
      "County_state": "Clay,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.370697,
              39.21899
            ],
            [
              -97.371154,
              39.131961
            ],
            [
              -97.259519,
              39.132962
            ],
            [
              -96.963177,
              39.1323
            ],
            [
              -96.96138,
              39.132325
            ],
            [
              -96.961693,
              39.220076
            ],
            [
              -96.958719,
              39.566401
            ],
            [
              -97.368574,
              39.567018
            ],
            [
              -97.369839,
              39.306011
            ],
            [
              -97.370697,
              39.21899
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "179",
      "COUNTYNS": "00485052",
      "AFFGEOID": "0500000US20179",
      "GEOID": "20179",
      "NAME": "Sheridan",
      "LSAD": "06",
      "ALAND": 2320518307,
      "AWATER": 647342,
      "County_state": "Sheridan,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.720213,
              39.568034
            ],
            [
              -100.721296,
              39.133381
            ],
            [
              -100.1642,
              39.132071
            ],
            [
              -100.161667,
              39.567277
            ],
            [
              -100.180351,
              39.56689
            ],
            [
              -100.720213,
              39.568034
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "097",
      "COUNTYNS": "00485013",
      "AFFGEOID": "0500000US20097",
      "GEOID": "20097",
      "NAME": "Kiowa",
      "LSAD": "06",
      "ALAND": 1871627778,
      "AWATER": 596764,
      "County_state": "Kiowa,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.55958,
              37.734888
            ],
            [
              -99.556119,
              37.467722
            ],
            [
              -99.555774,
              37.381112
            ],
            [
              -99.543189,
              37.381124
            ],
            [
              -99.490225,
              37.381022
            ],
            [
              -99.011499,
              37.384263
            ],
            [
              -99.012598,
              37.470421
            ],
            [
              -99.01355,
              37.73297
            ],
            [
              -99.55958,
              37.734888
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "139",
      "COUNTYNS": "00485033",
      "AFFGEOID": "0500000US20139",
      "GEOID": "20139",
      "NAME": "Osage",
      "LSAD": "06",
      "ALAND": 1827283226,
      "AWATER": 37107132,
      "County_state": "Osage,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.949969,
              38.477704
            ],
            [
              -95.950282,
              38.434105
            ],
            [
              -95.508267,
              38.433933
            ],
            [
              -95.508667,
              38.623101
            ],
            [
              -95.508042,
              38.695268
            ],
            [
              -95.501223,
              38.695263
            ],
            [
              -95.500738,
              38.738808
            ],
            [
              -95.500708,
              38.825929
            ],
            [
              -95.500724,
              38.869815
            ],
            [
              -95.68284,
              38.869981
            ],
            [
              -95.946587,
              38.869973
            ],
            [
              -95.945924,
              38.739112
            ],
            [
              -95.949654,
              38.695385
            ],
            [
              -95.949969,
              38.477704
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "181",
      "COUNTYNS": "00485053",
      "AFFGEOID": "0500000US20181",
      "GEOID": "20181",
      "NAME": "Sherman",
      "LSAD": "06",
      "ALAND": 2735193304,
      "AWATER": 548248,
      "County_state": "Sherman,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.049763830217,
              39.568180000858
            ],
            [
              -102.049554,
              39.538932
            ],
            [
              -102.049673,
              39.536691
            ],
            [
              -102.049679,
              39.506183
            ],
            [
              -102.049369,
              39.423333
            ],
            [
              -102.04937,
              39.41821
            ],
            [
              -102.049167,
              39.403597
            ],
            [
              -102.04896,
              39.373712
            ],
            [
              -102.048449,
              39.303138
            ],
            [
              -102.04725,
              39.13702
            ],
            [
              -102.047188609269,
              39.13314656241629
            ],
            [
              -101.478195,
              39.134761
            ],
            [
              -101.391717,
              39.135117
            ],
            [
              -101.391256,
              39.308825
            ],
            [
              -101.389068,
              39.568369
            ],
            [
              -101.413913,
              39.568361
            ],
            [
              -102.049763830217,
              39.568180000858
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "163",
      "COUNTYNS": "00485045",
      "AFFGEOID": "0500000US20163",
      "GEOID": "20163",
      "NAME": "Rooks",
      "LSAD": "06",
      "ALAND": 2306483733,
      "AWATER": 11962259,
      "County_state": "Rooks,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.602498,
              39.394324
            ],
            [
              -99.605187,
              39.132481
            ],
            [
              -99.591776,
              39.132357
            ],
            [
              -99.047687,
              39.133014
            ],
            [
              -99.044398,
              39.568035
            ],
            [
              -99.06622,
              39.568125
            ],
            [
              -99.402734,
              39.567294
            ],
            [
              -99.602176,
              39.567328
            ],
            [
              -99.602304,
              39.437636
            ],
            [
              -99.602498,
              39.394324
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "169",
      "COUNTYNS": "00485047",
      "AFFGEOID": "0500000US20169",
      "GEOID": "20169",
      "NAME": "Saline",
      "LSAD": "06",
      "ALAND": 1865381414,
      "AWATER": 2809967,
      "County_state": "Saline,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.928595,
              38.871121
            ],
            [
              -97.928592,
              38.871099
            ],
            [
              -97.92846,
              38.696974
            ],
            [
              -97.924795,
              38.60988
            ],
            [
              -97.70353,
              38.609101
            ],
            [
              -97.592945,
              38.608978
            ],
            [
              -97.371911,
              38.609353
            ],
            [
              -97.371828,
              38.783421
            ],
            [
              -97.372093,
              38.870776
            ],
            [
              -97.372109,
              38.914258
            ],
            [
              -97.381205,
              38.9289
            ],
            [
              -97.371614,
              38.957852
            ],
            [
              -97.593353,
              38.957916
            ],
            [
              -97.92856,
              38.958395
            ],
            [
              -97.928595,
              38.871121
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "053",
      "COUNTYNS": "00484996",
      "AFFGEOID": "0500000US20053",
      "GEOID": "20053",
      "NAME": "Ellsworth",
      "LSAD": "06",
      "ALAND": 1854037822,
      "AWATER": 19262994,
      "County_state": "Ellsworth,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.484861,
              38.870791
            ],
            [
              -98.486108,
              38.696878
            ],
            [
              -98.479958,
              38.696916
            ],
            [
              -98.480377,
              38.521841
            ],
            [
              -98.257057,
              38.522176
            ],
            [
              -97.924269,
              38.522755
            ],
            [
              -97.924795,
              38.60988
            ],
            [
              -97.92846,
              38.696974
            ],
            [
              -97.928592,
              38.871099
            ],
            [
              -98.15071,
              38.870788
            ],
            [
              -98.484861,
              38.870791
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "121",
      "COUNTYNS": "00485025",
      "AFFGEOID": "0500000US20121",
      "GEOID": "20121",
      "NAME": "Miami",
      "LSAD": "06",
      "ALAND": 1490955581,
      "AWATER": 37798751,
      "County_state": "Miami,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.065526,
              38.51655
            ],
            [
              -95.065831,
              38.389945
            ],
            [
              -94.918137,
              38.389142
            ],
            [
              -94.613275403738,
              38.3887183678658
            ],
            [
              -94.613265,
              38.392426
            ],
            [
              -94.613365,
              38.403422
            ],
            [
              -94.6131579346,
              38.4341909225274
            ],
            [
              -94.612866,
              38.477571
            ],
            [
              -94.6128650452802,
              38.477602354122006
            ],
            [
              -94.612696,
              38.483154
            ],
            [
              -94.612726,
              38.484367
            ],
            [
              -94.612644,
              38.491644
            ],
            [
              -94.6124647571677,
              38.5187583330626
            ],
            [
              -94.6122740486503,
              38.547607097579096
            ],
            [
              -94.612272,
              38.547917
            ],
            [
              -94.612157,
              38.549817
            ],
            [
              -94.612176,
              38.576546
            ],
            [
              -94.611902,
              38.58011
            ],
            [
              -94.611887,
              38.580139
            ],
            [
              -94.611908,
              38.609272
            ],
            [
              -94.611858,
              38.620485
            ],
            [
              -94.611465,
              38.625011
            ],
            [
              -94.611602,
              38.635384
            ],
            [
              -94.60950894217369,
              38.7381018369191
            ],
            [
              -94.760315,
              38.738235
            ],
            [
              -94.797192,
              38.738313
            ],
            [
              -94.871603,
              38.738324
            ],
            [
              -94.908606,
              38.738281
            ],
            [
              -94.917842,
              38.73833
            ],
            [
              -95.056412,
              38.738587
            ],
            [
              -95.065772,
              38.694903
            ],
            [
              -95.065245,
              38.608064
            ],
            [
              -95.065526,
              38.51655
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "189",
      "COUNTYNS": "00485056",
      "AFFGEOID": "0500000US20189",
      "GEOID": "20189",
      "NAME": "Stevens",
      "LSAD": "06",
      "ALAND": 1883604193,
      "AWATER": 464936,
      "County_state": "Stevens,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.556606,
              37.388485
            ],
            [
              -101.555260303913,
              36.9954138768669
            ],
            [
              -101.555239,
              36.995414
            ],
            [
              -101.519066,
              36.995546
            ],
            [
              -101.485326,
              36.995611
            ],
            [
              -101.415005,
              36.995966
            ],
            [
              -101.413868,
              36.996008
            ],
            [
              -101.37818,
              36.996164
            ],
            [
              -101.359674,
              36.996232
            ],
            [
              -101.357797,
              36.996271
            ],
            [
              -101.212909,
              36.997044
            ],
            [
              -101.211486,
              36.997124
            ],
            [
              -101.066742,
              36.997921
            ],
            [
              -101.066451000186,
              36.9979220177139
            ],
            [
              -101.067763,
              37.256999
            ],
            [
              -101.067904,
              37.387779
            ],
            [
              -101.089653,
              37.387721
            ],
            [
              -101.52581,
              37.388392
            ],
            [
              -101.556606,
              37.388485
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "043",
      "COUNTYNS": "00484991",
      "AFFGEOID": "0500000US20043",
      "GEOID": "20043",
      "NAME": "Doniphan",
      "LSAD": "06",
      "ALAND": 1019094906,
      "AWATER": 12434954,
      "County_state": "Doniphan,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.339896,
              39.999999
            ],
            [
              -95.33974,
              39.65298
            ],
            [
              -95.115189,
              39.652549
            ],
            [
              -95.098006,
              39.620871
            ],
            [
              -95.08544,
              39.615074
            ],
            [
              -95.05338996101001,
              39.615323540228594
            ],
            [
              -95.055152,
              39.621657
            ],
            [
              -95.054925,
              39.624995
            ],
            [
              -95.053367,
              39.630347
            ],
            [
              -95.049518,
              39.637876
            ],
            [
              -95.044554,
              39.64437
            ],
            [
              -95.039049,
              39.649639
            ],
            [
              -95.037464,
              39.652905
            ],
            [
              -95.027644,
              39.665454
            ],
            [
              -95.024595,
              39.668485
            ],
            [
              -95.018318,
              39.672869
            ],
            [
              -95.01531,
              39.674262
            ],
            [
              -95.009023,
              39.675765
            ],
            [
              -95.001379,
              39.676479
            ],
            [
              -94.993557,
              39.67657
            ],
            [
              -94.984149,
              39.67785
            ],
            [
              -94.981557,
              39.678634
            ],
            [
              -94.976325,
              39.68137
            ],
            [
              -94.971317,
              39.68641
            ],
            [
              -94.969909,
              39.68905
            ],
            [
              -94.968981,
              39.692954
            ],
            [
              -94.968453,
              39.707402
            ],
            [
              -94.971078,
              39.723146
            ],
            [
              -94.971206,
              39.729305
            ],
            [
              -94.970422,
              39.732121
            ],
            [
              -94.965318,
              39.739065
            ],
            [
              -94.960086,
              39.743065
            ],
            [
              -94.955286,
              39.745689
            ],
            [
              -94.95263,
              39.745961
            ],
            [
              -94.948726,
              39.745593
            ],
            [
              -94.944741,
              39.744377
            ],
            [
              -94.939221,
              39.741578
            ],
            [
              -94.930005,
              39.73537
            ],
            [
              -94.918324,
              39.728794
            ],
            [
              -94.910068,
              39.725786
            ],
            [
              -94.902612,
              39.724202
            ],
            [
              -94.899316,
              39.724042
            ],
            [
              -94.891744,
              39.724894
            ],
            [
              -94.884143,
              39.726794
            ],
            [
              -94.875643,
              39.730494
            ],
            [
              -94.870143,
              39.734594
            ],
            [
              -94.862943,
              39.742994
            ],
            [
              -94.860371,
              39.74953
            ],
            [
              -94.8594779750445,
              39.753537064563204
            ],
            [
              -94.859443,
              39.753694
            ],
            [
              -94.860743,
              39.763094
            ],
            [
              -94.863143,
              39.767294
            ],
            [
              -94.865243,
              39.770094
            ],
            [
              -94.867143,
              39.771694
            ],
            [
              -94.869644,
              39.772894
            ],
            [
              -94.871144,
              39.772994
            ],
            [
              -94.881422,
              39.771258
            ],
            [
              -94.88146,
              39.771258
            ],
            [
              -94.883924,
              39.770186
            ],
            [
              -94.893646,
              39.764208
            ],
            [
              -94.893724,
              39.76416
            ],
            [
              -94.893919,
              39.76404
            ],
            [
              -94.894071,
              39.763946
            ],
            [
              -94.895041,
              39.76335
            ],
            [
              -94.895268,
              39.76321
            ],
            [
              -94.899156,
              39.761258
            ],
            [
              -94.906244,
              39.759418
            ],
            [
              -94.912293,
              39.759338
            ],
            [
              -94.916789,
              39.760938
            ],
            [
              -94.926229,
              39.76649
            ],
            [
              -94.929653,
              39.769098
            ],
            [
              -94.934262,
              39.773642
            ],
            [
              -94.935302,
              39.77561
            ],
            [
              -94.935782,
              39.778906
            ],
            [
              -94.935206,
              39.78313
            ],
            [
              -94.932726,
              39.786282
            ],
            [
              -94.929654,
              39.788282
            ],
            [
              -94.925605,
              39.789754
            ],
            [
              -94.892965,
              39.791098
            ],
            [
              -94.890292,
              39.791626
            ],
            [
              -94.884084,
              39.794234
            ],
            [
              -94.880932,
              39.797338
            ],
            [
              -94.876344,
              39.806894
            ],
            [
              -94.875944,
              39.813294
            ],
            [
              -94.876544,
              39.820594
            ],
            [
              -94.877044,
              39.823754
            ],
            [
              -94.878677,
              39.826522
            ],
            [
              -94.881013,
              39.828922
            ],
            [
              -94.886933,
              39.833098
            ],
            [
              -94.889493,
              39.834026
            ],
            [
              -94.892677,
              39.834378
            ],
            [
              -94.903157,
              39.83385
            ],
            [
              -94.909942,
              39.834426
            ],
            [
              -94.916918,
              39.836138
            ],
            [
              -94.92615,
              39.841322
            ],
            [
              -94.937655,
              39.849786
            ],
            [
              -94.939767,
              39.85193
            ],
            [
              -94.942567,
              39.856602
            ],
            [
              -94.942407,
              39.861066
            ],
            [
              -94.940743,
              39.86441
            ],
            [
              -94.938791,
              39.866954
            ],
            [
              -94.931463,
              39.872602
            ],
            [
              -94.928466,
              39.876344
            ],
            [
              -94.927252,
              39.880258
            ],
            [
              -94.927359,
              39.883966
            ],
            [
              -94.927897,
              39.886112
            ],
            [
              -94.929574,
              39.888754
            ],
            [
              -94.934493,
              39.893366
            ],
            [
              -94.943867,
              39.89813
            ],
            [
              -94.95154,
              39.900533
            ],
            [
              -94.959276,
              39.901671
            ],
            [
              -94.963345,
              39.901136
            ],
            [
              -94.977749,
              39.897472
            ],
            [
              -94.986975,
              39.89667
            ],
            [
              -94.990284,
              39.89701
            ],
            [
              -94.99341025106669,
              39.8977932373378
            ],
            [
              -95.003819,
              39.900401
            ],
            [
              -95.00844,
              39.900596
            ],
            [
              -95.013152,
              39.899953
            ],
            [
              -95.018743,
              39.897372
            ],
            [
              -95.024389,
              39.891202
            ],
            [
              -95.02524,
              39.8897
            ],
            [
              -95.025947,
              39.886747
            ],
            [
              -95.025119,
              39.878833
            ],
            [
              -95.025422,
              39.876711
            ],
            [
              -95.027931,
              39.871522
            ],
            [
              -95.032053,
              39.868337
            ],
            [
              -95.037767,
              39.865542
            ],
            [
              -95.042142,
              39.864805
            ],
            [
              -95.052535,
              39.864374
            ],
            [
              -95.081534,
              39.861718
            ],
            [
              -95.085003,
              39.861883
            ],
            [
              -95.090158,
              39.86314
            ],
            [
              -95.105912,
              39.869164
            ],
            [
              -95.128166,
              39.874165
            ],
            [
              -95.134747,
              39.876852
            ],
            [
              -95.137092,
              39.878351
            ],
            [
              -95.140601,
              39.881688
            ],
            [
              -95.142718,
              39.885889
            ],
            [
              -95.143403,
              39.889356
            ],
            [
              -95.142445,
              39.89542
            ],
            [
              -95.142563,
              39.897992
            ],
            [
              -95.143802,
              39.901918
            ],
            [
              -95.146055,
              39.904183
            ],
            [
              -95.149657,
              39.905948
            ],
            [
              -95.156024,
              39.907243
            ],
            [
              -95.159834,
              39.906984
            ],
            [
              -95.172296,
              39.902026
            ],
            [
              -95.179453,
              39.900062
            ],
            [
              -95.189565,
              39.899959
            ],
            [
              -95.193816,
              39.90069
            ],
            [
              -95.199347,
              39.902709
            ],
            [
              -95.201935,
              39.904053
            ],
            [
              -95.205733,
              39.908275
            ],
            [
              -95.206196,
              39.909557
            ],
            [
              -95.206326,
              39.912121
            ],
            [
              -95.205745,
              39.915169
            ],
            [
              -95.20201,
              39.922438
            ],
            [
              -95.20069,
              39.928155
            ],
            [
              -95.201277,
              39.934194
            ],
            [
              -95.204428,
              39.938949
            ],
            [
              -95.213737,
              39.943206
            ],
            [
              -95.21644,
              39.943953
            ],
            [
              -95.220212,
              39.944433
            ],
            [
              -95.231114,
              39.943784
            ],
            [
              -95.236761,
              39.943931
            ],
            [
              -95.241383,
              39.944949
            ],
            [
              -95.250254,
              39.948644
            ],
            [
              -95.257652,
              39.954886
            ],
            [
              -95.261854,
              39.960618
            ],
            [
              -95.269886,
              39.969396
            ],
            [
              -95.274757,
              39.972115
            ],
            [
              -95.289715,
              39.977706
            ],
            [
              -95.302507,
              39.984357
            ],
            [
              -95.307111,
              39.989114
            ],
            [
              -95.30778,
              39.990618
            ],
            [
              -95.308404,
              39.993758
            ],
            [
              -95.30829,
              39.999998
            ],
            [
              -95.339896,
              39.999999
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "173",
      "COUNTYNS": "00485049",
      "AFFGEOID": "0500000US20173",
      "GEOID": "20173",
      "NAME": "Sedgwick",
      "LSAD": "06",
      "ALAND": 2583496854,
      "AWATER": 30113787,
      "County_state": "Sedgwick,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.808348,
              37.646788
            ],
            [
              -97.807722,
              37.561821
            ],
            [
              -97.8076,
              37.474184
            ],
            [
              -97.514691,
              37.475556
            ],
            [
              -97.350789,
              37.477031
            ],
            [
              -97.278025,
              37.475819
            ],
            [
              -97.26084,
              37.47554
            ],
            [
              -97.208147,
              37.475693
            ],
            [
              -97.153331,
              37.475544
            ],
            [
              -97.153022,
              37.519258
            ],
            [
              -97.152751,
              37.563043
            ],
            [
              -97.152964,
              37.650313
            ],
            [
              -97.15305,
              37.679531
            ],
            [
              -97.153015,
              37.708678
            ],
            [
              -97.152941,
              37.723292
            ],
            [
              -97.152879,
              37.7379
            ],
            [
              -97.15345,
              37.825166
            ],
            [
              -97.152145,
              37.825175
            ],
            [
              -97.152476,
              37.912733
            ],
            [
              -97.263186,
              37.912313
            ],
            [
              -97.686667,
              37.912469
            ],
            [
              -97.701969,
              37.911325
            ],
            [
              -97.702089,
              37.829173
            ],
            [
              -97.699679,
              37.825156
            ],
            [
              -97.698692,
              37.735056
            ],
            [
              -97.807823,
              37.733855
            ],
            [
              -97.806717,
              37.654039
            ],
            [
              -97.808348,
              37.646788
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "183",
      "COUNTYNS": "00484969",
      "AFFGEOID": "0500000US20183",
      "GEOID": "20183",
      "NAME": "Smith",
      "LSAD": "06",
      "ALAND": 2319241783,
      "AWATER": 3884267,
      "County_state": "Smith,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.067021970224,
              40.0021702872539
            ],
            [
              -99.06622,
              39.568125
            ],
            [
              -99.044398,
              39.568035
            ],
            [
              -98.505266,
              39.567603
            ],
            [
              -98.5044549849831,
              40.0023285653691
            ],
            [
              -98.523053,
              40.002336
            ],
            [
              -98.543186,
              40.002285
            ],
            [
              -98.560578,
              40.002274
            ],
            [
              -98.575219,
              40.00248
            ],
            [
              -98.593342,
              40.002476
            ],
            [
              -98.613755,
              40.0024
            ],
            [
              -98.64071,
              40.002493
            ],
            [
              -98.652494,
              40.002245
            ],
            [
              -98.653833,
              40.002269
            ],
            [
              -98.669724,
              40.00241
            ],
            [
              -98.672819,
              40.002364
            ],
            [
              -98.690287,
              40.002548
            ],
            [
              -98.691443,
              40.002505
            ],
            [
              -98.693096,
              40.002373
            ],
            [
              -98.710404,
              40.00218
            ],
            [
              -98.726295,
              40.002222
            ],
            [
              -98.7263734167054,
              40.0022221837665
            ],
            [
              -98.774941,
              40.002336
            ],
            [
              -98.777203,
              40.002359
            ],
            [
              -98.82059,
              40.002319
            ],
            [
              -98.834456,
              40.002363
            ],
            [
              -98.934792,
              40.002205
            ],
            [
              -98.960919,
              40.002271
            ],
            [
              -98.961009,
              40.002317
            ],
            [
              -98.971721,
              40.002268
            ],
            [
              -98.972287,
              40.002245
            ],
            [
              -98.992135,
              40.002192
            ],
            [
              -99.018701,
              40.002333
            ],
            [
              -99.020338,
              40.002264
            ],
            [
              -99.067021970224,
              40.0021702872539
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "079",
      "COUNTYNS": "00485005",
      "AFFGEOID": "0500000US20079",
      "GEOID": "20079",
      "NAME": "Harvey",
      "LSAD": "06",
      "ALAND": 1397961182,
      "AWATER": 2503283,
      "County_state": "Harvey,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.702006,
              38.072221
            ],
            [
              -97.701969,
              37.911325
            ],
            [
              -97.686667,
              37.912469
            ],
            [
              -97.263186,
              37.912313
            ],
            [
              -97.152476,
              37.912733
            ],
            [
              -97.152548,
              37.955962
            ],
            [
              -97.152526,
              37.964896
            ],
            [
              -97.152618,
              37.966394
            ],
            [
              -97.152807,
              38.000371
            ],
            [
              -97.152913,
              38.087704
            ],
            [
              -97.153093,
              38.174634
            ],
            [
              -97.37175,
              38.173673
            ],
            [
              -97.592145,
              38.174163
            ],
            [
              -97.701841,
              38.173814
            ],
            [
              -97.702006,
              38.072221
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "013",
      "COUNTYNS": "00484976",
      "AFFGEOID": "0500000US20013",
      "GEOID": "20013",
      "NAME": "Brown",
      "LSAD": "06",
      "ALAND": 1478550074,
      "AWATER": 3196147,
      "County_state": "Brown,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.7881114096839,
              40.0004520166495
            ],
            [
              -95.788941,
              39.653001
            ],
            [
              -95.564126,
              39.652872
            ],
            [
              -95.396177,
              39.652949
            ],
            [
              -95.33974,
              39.65298
            ],
            [
              -95.339896,
              39.999999
            ],
            [
              -95.375257,
              40
            ],
            [
              -95.784575,
              40.000463
            ],
            [
              -95.788024,
              40.000452
            ],
            [
              -95.7881114096839,
              40.0004520166495
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "023",
      "COUNTYNS": "00484981",
      "AFFGEOID": "0500000US20023",
      "GEOID": "20023",
      "NAME": "Cheyenne",
      "LSAD": "06",
      "ALAND": 2641501922,
      "AWATER": 2738021,
      "County_state": "Cheyenne,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.051744,
              40.003078
            ],
            [
              -102.051569,
              39.849805
            ],
            [
              -102.051363,
              39.843471
            ],
            [
              -102.051318,
              39.833311
            ],
            [
              -102.051254,
              39.818992
            ],
            [
              -102.050594,
              39.675594
            ],
            [
              -102.050099,
              39.653812
            ],
            [
              -102.050422,
              39.646048
            ],
            [
              -102.049954,
              39.592331
            ],
            [
              -102.049806,
              39.574058
            ],
            [
              -102.049763830217,
              39.568180000858
            ],
            [
              -101.413913,
              39.568361
            ],
            [
              -101.411042549953,
              40.0023645110938
            ],
            [
              -101.417209,
              40.002424
            ],
            [
              -101.542273,
              40.002609
            ],
            [
              -101.625809,
              40.002711
            ],
            [
              -101.627071,
              40.00262
            ],
            [
              -101.804862,
              40.002752
            ],
            [
              -101.807687,
              40.002798
            ],
            [
              -101.832161,
              40.002933
            ],
            [
              -101.841025,
              40.002784
            ],
            [
              -101.904176,
              40.003162
            ],
            [
              -101.916696,
              40.003142
            ],
            [
              -102.051744,
              40.003078
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "071",
      "COUNTYNS": "00485002",
      "AFFGEOID": "0500000US20071",
      "GEOID": "20071",
      "NAME": "Greeley",
      "LSAD": "06",
      "ALAND": 2016057985,
      "AWATER": 0,
      "County_state": "Greeley,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.045263,
              38.505395
            ],
            [
              -102.045324,
              38.453647
            ],
            [
              -102.044936,
              38.41968
            ],
            [
              -102.044442,
              38.415802
            ],
            [
              -102.044944,
              38.384419
            ],
            [
              -102.044613,
              38.312324
            ],
            [
              -102.044568,
              38.268819
            ],
            [
              -102.044567368215,
              38.2687491172007
            ],
            [
              -102.04451007292,
              38.2624115834718
            ],
            [
              -101.567482,
              38.263145
            ],
            [
              -101.567094,
              38.699669
            ],
            [
              -102.045212,
              38.697567
            ],
            [
              -102.045156,
              38.688555
            ],
            [
              -102.045127,
              38.686725
            ],
            [
              -102.04516,
              38.675221
            ],
            [
              -102.045102,
              38.674946
            ],
            [
              -102.045074,
              38.669617
            ],
            [
              -102.045288,
              38.615249
            ],
            [
              -102.045287815606,
              38.6151684415595
            ],
            [
              -102.045211,
              38.581609
            ],
            [
              -102.045189,
              38.558732
            ],
            [
              -102.045223,
              38.543797
            ],
            [
              -102.045112,
              38.523784
            ],
            [
              -102.045262,
              38.505532
            ],
            [
              -102.045263,
              38.505395
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "191",
      "COUNTYNS": "00481812",
      "AFFGEOID": "0500000US20191",
      "GEOID": "20191",
      "NAME": "Sumner",
      "LSAD": "06",
      "ALAND": 3060416043,
      "AWATER": 8602566,
      "County_state": "Sumner,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.8076,
              37.474184
            ],
            [
              -97.807057,
              37.386293
            ],
            [
              -97.80453,
              37.384055
            ],
            [
              -97.802134,
              37.212636
            ],
            [
              -97.8023129924768,
              36.998712977542695
            ],
            [
              -97.802298,
              36.998713
            ],
            [
              -97.783489,
              36.998847
            ],
            [
              -97.783432,
              36.998961
            ],
            [
              -97.768704,
              36.99875
            ],
            [
              -97.697104,
              36.998826
            ],
            [
              -97.650466,
              36.999004
            ],
            [
              -97.637137,
              36.99909
            ],
            [
              -97.606549,
              36.998682
            ],
            [
              -97.564536,
              36.998711
            ],
            [
              -97.546498,
              36.998747
            ],
            [
              -97.5459,
              36.998709
            ],
            [
              -97.527292,
              36.99875
            ],
            [
              -97.472861,
              36.998721
            ],
            [
              -97.4623463219255,
              36.9986852256487
            ],
            [
              -97.46228,
              36.998685
            ],
            [
              -97.384925,
              36.998843
            ],
            [
              -97.372421,
              36.998861
            ],
            [
              -97.30079008310649,
              36.9989406961692
            ],
            [
              -97.147721,
              36.999111
            ],
            [
              -97.149233,
              37.128605
            ],
            [
              -97.14975,
              37.216518
            ],
            [
              -97.150845,
              37.303713
            ],
            [
              -97.153331,
              37.475544
            ],
            [
              -97.208147,
              37.475693
            ],
            [
              -97.26084,
              37.47554
            ],
            [
              -97.278025,
              37.475819
            ],
            [
              -97.350789,
              37.477031
            ],
            [
              -97.514691,
              37.475556
            ],
            [
              -97.8076,
              37.474184
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "149",
      "COUNTYNS": "00485038",
      "AFFGEOID": "0500000US20149",
      "GEOID": "20149",
      "NAME": "Pottawatomie",
      "LSAD": "06",
      "ALAND": 2177617398,
      "AWATER": 54039076,
      "County_state": "Pottawatomie,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.728327,
              39.427132
            ],
            [
              -96.715148,
              39.420293
            ],
            [
              -96.726667,
              39.403601
            ],
            [
              -96.693334,
              39.371681
            ],
            [
              -96.688676,
              39.358211
            ],
            [
              -96.668409,
              39.34313
            ],
            [
              -96.671743,
              39.334496
            ],
            [
              -96.64656,
              39.326182
            ],
            [
              -96.656665,
              39.317202
            ],
            [
              -96.641696,
              39.305373
            ],
            [
              -96.638761,
              39.292183
            ],
            [
              -96.594841,
              39.25466
            ],
            [
              -96.562342,
              39.241341
            ],
            [
              -96.54611,
              39.208404
            ],
            [
              -96.538493,
              39.214834
            ],
            [
              -96.519209,
              39.198912
            ],
            [
              -96.556557,
              39.198351
            ],
            [
              -96.559356,
              39.198391
            ],
            [
              -96.56059,
              39.197723
            ],
            [
              -96.560972,
              39.196235
            ],
            [
              -96.560059,
              39.194524
            ],
            [
              -96.558937,
              39.190127
            ],
            [
              -96.557892,
              39.181974
            ],
            [
              -96.556274,
              39.179912
            ],
            [
              -96.553866,
              39.17895
            ],
            [
              -96.517084,
              39.187627
            ],
            [
              -96.5167,
              39.17113
            ],
            [
              -96.501055,
              39.167503
            ],
            [
              -96.490172,
              39.17709
            ],
            [
              -96.476131,
              39.164457
            ],
            [
              -96.460371,
              39.168445
            ],
            [
              -96.463808,
              39.177884
            ],
            [
              -96.4596,
              39.189871
            ],
            [
              -96.416707,
              39.185877
            ],
            [
              -96.389046,
              39.172878
            ],
            [
              -96.358292,
              39.156525
            ],
            [
              -96.330006,
              39.159181
            ],
            [
              -96.324729,
              39.186051
            ],
            [
              -96.308083,
              39.19678
            ],
            [
              -96.305151,
              39.197265
            ],
            [
              -96.269382,
              39.178963
            ],
            [
              -96.231214,
              39.211585
            ],
            [
              -96.21975,
              39.200764
            ],
            [
              -96.178864,
              39.203206
            ],
            [
              -96.176741,
              39.188616
            ],
            [
              -96.138094,
              39.19058
            ],
            [
              -96.11551,
              39.190767
            ],
            [
              -96.110458,
              39.20082
            ],
            [
              -96.083557,
              39.193504
            ],
            [
              -96.057658,
              39.158168
            ],
            [
              -96.083867,
              39.163263
            ],
            [
              -96.088225,
              39.152339
            ],
            [
              -96.04398,
              39.141174
            ],
            [
              -96.03906,
              39.126527
            ],
            [
              -96.035899,
              39.129784
            ],
            [
              -96.035573,
              39.21652
            ],
            [
              -96.034976,
              39.274576
            ],
            [
              -96.035952,
              39.5661
            ],
            [
              -96.238969,
              39.56622
            ],
            [
              -96.580362,
              39.566332
            ],
            [
              -96.58923,
              39.54566
            ],
            [
              -96.615616,
              39.531773
            ],
            [
              -96.620675,
              39.538355
            ],
            [
              -96.644383,
              39.518926
            ],
            [
              -96.639738,
              39.510206
            ],
            [
              -96.656024,
              39.501267
            ],
            [
              -96.667793,
              39.475324
            ],
            [
              -96.683205,
              39.480354
            ],
            [
              -96.6876,
              39.462122
            ],
            [
              -96.706722,
              39.461533
            ],
            [
              -96.721691,
              39.443848
            ],
            [
              -96.713094,
              39.43568
            ],
            [
              -96.728327,
              39.427132
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "205",
      "COUNTYNS": "00485063",
      "AFFGEOID": "0500000US20205",
      "GEOID": "20205",
      "NAME": "Wilson",
      "LSAD": "06",
      "ALAND": 1477379082,
      "AWATER": 12055440,
      "County_state": "Wilson,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.962311,
              37.430273
            ],
            [
              -95.961605,
              37.386636
            ],
            [
              -95.819458,
              37.385757
            ],
            [
              -95.52556,
              37.383979
            ],
            [
              -95.525042,
              37.514663
            ],
            [
              -95.525499,
              37.732759
            ],
            [
              -95.960876,
              37.734304
            ],
            [
              -95.961002,
              37.603761
            ],
            [
              -95.962311,
              37.430273
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "019",
      "COUNTYNS": "00484979",
      "AFFGEOID": "0500000US20019",
      "GEOID": "20019",
      "NAME": "Chautauqua",
      "LSAD": "06",
      "ALAND": 1654694139,
      "AWATER": 15243783,
      "County_state": "Chautauqua,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.524873,
              37.30273
            ],
            [
              -96.5255776738603,
              36.9987120358304
            ],
            [
              -96.500288,
              36.998643
            ],
            [
              -96.415412,
              36.999113
            ],
            [
              -96.394272,
              36.999221
            ],
            [
              -96.279079,
              36.999272
            ],
            [
              -96.276368,
              36.999271
            ],
            [
              -96.217571,
              36.99907
            ],
            [
              -96.200028,
              36.999028
            ],
            [
              -96.184768,
              36.999211
            ],
            [
              -96.1847130166869,
              36.999210910599096
            ],
            [
              -96.154017,
              36.999161
            ],
            [
              -96.152384,
              36.999051
            ],
            [
              -96.149709,
              36.99904
            ],
            [
              -96.147143,
              36.999022
            ],
            [
              -96.143207,
              36.999134
            ],
            [
              -96.14121,
              36.998973
            ],
            [
              -96.00081,
              36.99886
            ],
            [
              -95.9642699335572,
              36.9990936072442
            ],
            [
              -95.964396,
              37.255693
            ],
            [
              -95.964399,
              37.299232
            ],
            [
              -96.272384,
              37.30072
            ],
            [
              -96.524873,
              37.30273
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "107",
      "COUNTYNS": "00485018",
      "AFFGEOID": "0500000US20107",
      "GEOID": "20107",
      "NAME": "Linn",
      "LSAD": "06",
      "ALAND": 1538581904,
      "AWATER": 31974029,
      "County_state": "Linn,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.076712,
              38.15412
            ],
            [
              -95.077876,
              38.037706
            ],
            [
              -94.657335,
              38.037227
            ],
            [
              -94.641534,
              38.036786
            ],
            [
              -94.6139813464919,
              38.0370573442828
            ],
            [
              -94.6140548972092,
              38.0600558584152
            ],
            [
              -94.614055,
              38.060088
            ],
            [
              -94.614089,
              38.065901
            ],
            [
              -94.614061,
              38.067343
            ],
            [
              -94.613856,
              38.149769
            ],
            [
              -94.613748,
              38.160633
            ],
            [
              -94.613422,
              38.167908
            ],
            [
              -94.613073,
              38.190552
            ],
            [
              -94.612848,
              38.200714
            ],
            [
              -94.612822,
              38.203918
            ],
            [
              -94.612658,
              38.217649
            ],
            [
              -94.612659,
              38.219251
            ],
            [
              -94.612635,
              38.226987
            ],
            [
              -94.612614,
              38.237766
            ],
            [
              -94.612692,
              38.270394
            ],
            [
              -94.612849,
              38.289914
            ],
            [
              -94.612844,
              38.291423
            ],
            [
              -94.612673,
              38.302527
            ],
            [
              -94.612673,
              38.314832
            ],
            [
              -94.612788,
              38.320142
            ],
            [
              -94.612825,
              38.324387
            ],
            [
              -94.613,
              38.335801
            ],
            [
              -94.613312,
              38.364407
            ],
            [
              -94.613329,
              38.369618
            ],
            [
              -94.613275403738,
              38.3887183678658
            ],
            [
              -94.918137,
              38.389142
            ],
            [
              -95.065831,
              38.389945
            ],
            [
              -95.065103,
              38.258648
            ],
            [
              -95.075459,
              38.258714
            ],
            [
              -95.076667,
              38.168252
            ],
            [
              -95.076712,
              38.15412
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "095",
      "COUNTYNS": "00485012",
      "AFFGEOID": "0500000US20095",
      "GEOID": "20095",
      "NAME": "Kingman",
      "LSAD": "06",
      "ALAND": 2236100139,
      "AWATER": 8536907,
      "County_state": "Kingman,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.464663,
              37.471013
            ],
            [
              -98.464951,
              37.384086
            ],
            [
              -98.349804,
              37.384056
            ],
            [
              -97.807057,
              37.386293
            ],
            [
              -97.8076,
              37.474184
            ],
            [
              -97.807722,
              37.561821
            ],
            [
              -97.808348,
              37.646788
            ],
            [
              -97.806717,
              37.654039
            ],
            [
              -97.807823,
              37.733855
            ],
            [
              -98.136225,
              37.732913
            ],
            [
              -98.464804,
              37.732675
            ],
            [
              -98.464801,
              37.557939
            ],
            [
              -98.464663,
              37.471013
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "123",
      "COUNTYNS": "00485026",
      "AFFGEOID": "0500000US20123",
      "GEOID": "20123",
      "NAME": "Mitchell",
      "LSAD": "06",
      "ALAND": 1817632928,
      "AWATER": 44979980,
      "County_state": "Mitchell,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.487384,
              39.567492
            ],
            [
              -98.490149,
              39.21978
            ],
            [
              -98.154008,
              39.219158
            ],
            [
              -97.929746,
              39.219273
            ],
            [
              -97.931614,
              39.277094
            ],
            [
              -97.929097,
              39.306397
            ],
            [
              -97.928462,
              39.566915
            ],
            [
              -97.931844,
              39.566921
            ],
            [
              -98.157133,
              39.567138
            ],
            [
              -98.487384,
              39.567492
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "083",
      "COUNTYNS": "00485006",
      "AFFGEOID": "0500000US20083",
      "GEOID": "20083",
      "NAME": "Hodgeman",
      "LSAD": "06",
      "ALAND": 2227369151,
      "AWATER": 826344,
      "County_state": "Hodgeman,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.227137,
              38.262237
            ],
            [
              -100.225511,
              38.088184
            ],
            [
              -100.22661,
              38.001015
            ],
            [
              -100.22709,
              37.914018
            ],
            [
              -99.873763,
              37.913214
            ],
            [
              -99.569879,
              37.913016
            ],
            [
              -99.569533,
              38.087372
            ],
            [
              -99.569998,
              38.189332
            ],
            [
              -99.570675,
              38.262239
            ],
            [
              -99.584207,
              38.262179
            ],
            [
              -100.227137,
              38.262237
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "127",
      "COUNTYNS": "00485028",
      "AFFGEOID": "0500000US20127",
      "GEOID": "20127",
      "NAME": "Morris",
      "LSAD": "06",
      "ALAND": 1800737060,
      "AWATER": 19736109,
      "County_state": "Morris,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.933022,
              38.657858
            ],
            [
              -96.933924,
              38.65535
            ],
            [
              -96.930303,
              38.650491
            ],
            [
              -96.930376,
              38.638426
            ],
            [
              -96.930286,
              38.609362
            ],
            [
              -96.929969,
              38.522412
            ],
            [
              -96.819723,
              38.522464
            ],
            [
              -96.35378,
              38.521657
            ],
            [
              -96.353088,
              38.608893
            ],
            [
              -96.352613,
              38.739021
            ],
            [
              -96.389749,
              38.738984
            ],
            [
              -96.390398,
              38.825858
            ],
            [
              -96.501397,
              38.826188
            ],
            [
              -96.501556,
              38.869704
            ],
            [
              -96.815905,
              38.869893
            ],
            [
              -96.890245,
              38.870067
            ],
            [
              -96.890171,
              38.812169
            ],
            [
              -96.927234,
              38.81216
            ],
            [
              -96.927374,
              38.696005
            ],
            [
              -96.930409,
              38.696009
            ],
            [
              -96.930413,
              38.679179
            ],
            [
              -96.933022,
              38.657858
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "165",
      "COUNTYNS": "00485358",
      "AFFGEOID": "0500000US20165",
      "GEOID": "20165",
      "NAME": "Rush",
      "LSAD": "06",
      "ALAND": 1858997545,
      "AWATER": 544720,
      "County_state": "Rush,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.585087,
              38.696537
            ],
            [
              -99.584794,
              38.349386
            ],
            [
              -99.361881,
              38.349405
            ],
            [
              -99.248926,
              38.350719
            ],
            [
              -99.032408,
              38.348334
            ],
            [
              -99.032971,
              38.696759
            ],
            [
              -99.042626,
              38.696807
            ],
            [
              -99.25371,
              38.696934
            ],
            [
              -99.320364,
              38.696823
            ],
            [
              -99.585087,
              38.696537
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "185",
      "COUNTYNS": "00485054",
      "AFFGEOID": "0500000US20185",
      "GEOID": "20185",
      "NAME": "Stafford",
      "LSAD": "06",
      "ALAND": 2051389756,
      "AWATER": 7435417,
      "County_state": "Stafford,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.022136,
              38.000236
            ],
            [
              -99.022627,
              37.825358
            ],
            [
              -99.013318,
              37.825336
            ],
            [
              -98.472257,
              37.824497
            ],
            [
              -98.472794,
              38.172757
            ],
            [
              -98.472437,
              38.260741
            ],
            [
              -98.479841,
              38.26079
            ],
            [
              -98.70075,
              38.260844
            ],
            [
              -98.912583,
              38.261088
            ],
            [
              -98.913136,
              38.000451
            ],
            [
              -99.022136,
              38.000236
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "055",
      "COUNTYNS": "00485326",
      "AFFGEOID": "0500000US20055",
      "GEOID": "20055",
      "NAME": "Finney",
      "LSAD": "06",
      "ALAND": 3372066557,
      "AWATER": 1794398,
      "County_state": "Finney,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.103268,
              38.264557
            ],
            [
              -101.103778,
              37.829035
            ],
            [
              -101.089886,
              37.829115
            ],
            [
              -101.089667,
              37.736337
            ],
            [
              -100.797903,
              37.736565
            ],
            [
              -100.652251,
              37.736275
            ],
            [
              -100.651748,
              37.828712
            ],
            [
              -100.665172,
              37.828831
            ],
            [
              -100.664166,
              38.00253
            ],
            [
              -100.44549,
              38.000698
            ],
            [
              -100.22661,
              38.001015
            ],
            [
              -100.225511,
              38.088184
            ],
            [
              -100.227137,
              38.262237
            ],
            [
              -100.244393,
              38.26229
            ],
            [
              -100.464287,
              38.263111
            ],
            [
              -100.684699,
              38.264137
            ],
            [
              -101.103268,
              38.264557
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "087",
      "COUNTYNS": "00485008",
      "AFFGEOID": "0500000US20087",
      "GEOID": "20087",
      "NAME": "Jefferson",
      "LSAD": "06",
      "ALAND": 1379359782,
      "AWATER": 62427887,
      "County_state": "Jefferson,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.594756,
              39.086399
            ],
            [
              -95.596759,
              39.064672
            ],
            [
              -95.582345,
              39.053998
            ],
            [
              -95.548751,
              39.068319
            ],
            [
              -95.513902,
              39.067151
            ],
            [
              -95.500254,
              39.056656
            ],
            [
              -95.481473,
              39.057322
            ],
            [
              -95.43614,
              39.069781
            ],
            [
              -95.398486,
              39.058061
            ],
            [
              -95.340731,
              39.031712
            ],
            [
              -95.29795,
              39.0423
            ],
            [
              -95.284055,
              39.032306
            ],
            [
              -95.284434,
              39.046675
            ],
            [
              -95.268556,
              39.044091
            ],
            [
              -95.187103,
              39.044109
            ],
            [
              -95.187963,
              39.128909
            ],
            [
              -95.180125,
              39.128889
            ],
            [
              -95.179914,
              39.404744
            ],
            [
              -95.180891,
              39.419218
            ],
            [
              -95.385268,
              39.420397
            ],
            [
              -95.570351,
              39.41905
            ],
            [
              -95.589295,
              39.419086
            ],
            [
              -95.589472,
              39.216081
            ],
            [
              -95.589571,
              39.158278
            ],
            [
              -95.589443,
              39.14378
            ],
            [
              -95.594711,
              39.129275
            ],
            [
              -95.594756,
              39.086399
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "145",
      "COUNTYNS": "00485036",
      "AFFGEOID": "0500000US20145",
      "GEOID": "20145",
      "NAME": "Pawnee",
      "LSAD": "06",
      "ALAND": 1953531655,
      "AWATER": 992041,
      "County_state": "Pawnee,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.584794,
              38.349386
            ],
            [
              -99.584207,
              38.262179
            ],
            [
              -99.570675,
              38.262239
            ],
            [
              -99.569998,
              38.189332
            ],
            [
              -99.569533,
              38.087372
            ],
            [
              -99.350359,
              38.087594
            ],
            [
              -99.350289,
              38.000745
            ],
            [
              -99.240685,
              37.999968
            ],
            [
              -99.022136,
              38.000236
            ],
            [
              -98.913136,
              38.000451
            ],
            [
              -98.912583,
              38.261088
            ],
            [
              -98.921845,
              38.261227
            ],
            [
              -99.022472,
              38.261208
            ],
            [
              -99.032309,
              38.261227
            ],
            [
              -99.032408,
              38.348334
            ],
            [
              -99.248926,
              38.350719
            ],
            [
              -99.361881,
              38.349405
            ],
            [
              -99.584794,
              38.349386
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "031",
      "COUNTYNS": "00484985",
      "AFFGEOID": "0500000US20031",
      "GEOID": "20031",
      "NAME": "Coffey",
      "LSAD": "06",
      "ALAND": 1623624044,
      "AWATER": 70470775,
      "County_state": "Coffey,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.958862,
              38.170939
            ],
            [
              -95.959046,
              38.040195
            ],
            [
              -95.51897,
              38.03823
            ],
            [
              -95.516757,
              38.259296
            ],
            [
              -95.507474,
              38.259248
            ],
            [
              -95.508328,
              38.390278
            ],
            [
              -95.508267,
              38.433933
            ],
            [
              -95.950282,
              38.434105
            ],
            [
              -95.949994,
              38.259706
            ],
            [
              -95.958502,
              38.259718
            ],
            [
              -95.958862,
              38.170939
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "025",
      "COUNTYNS": "00484982",
      "AFFGEOID": "0500000US20025",
      "GEOID": "20025",
      "NAME": "Clark",
      "LSAD": "06",
      "ALAND": 2524300311,
      "AWATER": 6603384,
      "County_state": "Clark,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.107248,
              37.474836
            ],
            [
              -100.107422,
              37.387092
            ],
            [
              -100.091529,
              37.386892
            ],
            [
              -100.089483739431,
              37.0020915224233
            ],
            [
              -100.005706,
              37.001726
            ],
            [
              -100.002563,
              37.001706
            ],
            [
              -100.001286,
              37.001699
            ],
            [
              -99.995201,
              37.001631
            ],
            [
              -99.875409,
              37.001659
            ],
            [
              -99.786016,
              37.000931
            ],
            [
              -99.774816,
              37.000841
            ],
            [
              -99.774255,
              37.000837
            ],
            [
              -99.657658,
              37.000197
            ],
            [
              -99.648652,
              36.999604
            ],
            [
              -99.625399,
              36.999671
            ],
            [
              -99.558068,
              36.999528
            ],
            [
              -99.54111590157169,
              36.9995725260596
            ],
            [
              -99.54402,
              37.192156
            ],
            [
              -99.543189,
              37.381124
            ],
            [
              -99.555774,
              37.381112
            ],
            [
              -99.556119,
              37.467722
            ],
            [
              -99.774512,
              37.467404
            ],
            [
              -99.99779,
              37.47278
            ],
            [
              -100.107248,
              37.474836
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "187",
      "COUNTYNS": "00485055",
      "AFFGEOID": "0500000US20187",
      "GEOID": "20187",
      "NAME": "Stanton",
      "LSAD": "06",
      "ALAND": 1762104518,
      "AWATER": 178555,
      "County_state": "Stanton,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.041899,
              37.541186
            ],
            [
              -102.042016,
              37.535261
            ],
            [
              -102.041786,
              37.506066
            ],
            [
              -102.041801,
              37.469488
            ],
            [
              -102.041755,
              37.434855
            ],
            [
              -102.041669,
              37.43474
            ],
            [
              -102.041676,
              37.409898
            ],
            [
              -102.041585760593,
              37.3891904308032
            ],
            [
              -101.556606,
              37.388485
            ],
            [
              -101.52581,
              37.388392
            ],
            [
              -101.527063,
              37.736314
            ],
            [
              -102.041989965863,
              37.7385406283736
            ],
            [
              -102.041876,
              37.723875
            ],
            [
              -102.041574,
              37.680436
            ],
            [
              -102.041694,
              37.665681
            ],
            [
              -102.041582,
              37.654495
            ],
            [
              -102.041585,
              37.644282
            ],
            [
              -102.041618,
              37.607868
            ],
            [
              -102.041894,
              37.557977
            ],
            [
              -102.041899,
              37.541186
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "033",
      "COUNTYNS": "00484986",
      "AFFGEOID": "0500000US20033",
      "GEOID": "20033",
      "NAME": "Comanche",
      "LSAD": "06",
      "ALAND": 2041681113,
      "AWATER": 3604155,
      "County_state": "Comanche,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.543189,
              37.381124
            ],
            [
              -99.54402,
              37.192156
            ],
            [
              -99.54111590157169,
              36.9995725260596
            ],
            [
              -99.508574,
              36.999658
            ],
            [
              -99.504093,
              36.999648
            ],
            [
              -99.502665,
              36.999645
            ],
            [
              -99.500395,
              36.999637
            ],
            [
              -99.500395,
              36.999576
            ],
            [
              -99.484333,
              36.999626
            ],
            [
              -99.456203,
              36.999471
            ],
            [
              -99.407015,
              36.999579
            ],
            [
              -99.375391,
              37.000177
            ],
            [
              -99.277506,
              36.999579
            ],
            [
              -99.24812,
              36.999565
            ],
            [
              -99.129449,
              36.999422
            ],
            [
              -99.124883,
              36.99942
            ],
            [
              -99.049695,
              36.999221
            ],
            [
              -99.029337,
              36.999595
            ],
            [
              -99.00030291303248,
              36.999510304099104
            ],
            [
              -99.001294,
              37.384296
            ],
            [
              -99.011499,
              37.384263
            ],
            [
              -99.490225,
              37.381022
            ],
            [
              -99.543189,
              37.381124
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "153",
      "COUNTYNS": "00485040",
      "AFFGEOID": "0500000US20153",
      "GEOID": "20153",
      "NAME": "Rawlins",
      "LSAD": "06",
      "ALAND": 2769775729,
      "AWATER": 415527,
      "County_state": "Rawlins,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.411042549953,
              40.0023645110938
            ],
            [
              -101.413913,
              39.568361
            ],
            [
              -101.389068,
              39.568369
            ],
            [
              -100.942872,
              39.567984
            ],
            [
              -100.740873,
              39.568043
            ],
            [
              -100.73882584525201,
              40.002228424417495
            ],
            [
              -100.752183,
              40.002128
            ],
            [
              -100.75883,
              40.002302
            ],
            [
              -100.937427,
              40.002145
            ],
            [
              -101.027686,
              40.002256
            ],
            [
              -101.060317,
              40.002307
            ],
            [
              -101.130907,
              40.002427
            ],
            [
              -101.168704,
              40.002547
            ],
            [
              -101.178805,
              40.002468
            ],
            [
              -101.192219,
              40.002491
            ],
            [
              -101.215033,
              40.002555
            ],
            [
              -101.248673,
              40.002543
            ],
            [
              -101.286555,
              40.002559
            ],
            [
              -101.293991,
              40.002559
            ],
            [
              -101.324036,
              40.002696
            ],
            [
              -101.32551390689501,
              40.0026868921426
            ],
            [
              -101.342859,
              40.00258
            ],
            [
              -101.374326,
              40.002521
            ],
            [
              -101.409953,
              40.002354
            ],
            [
              -101.411042549953,
              40.0023645110938
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "021",
      "COUNTYNS": "00484980",
      "AFFGEOID": "0500000US20021",
      "GEOID": "20021",
      "NAME": "Cherokee",
      "LSAD": "06",
      "ALAND": 1521791027,
      "AWATER": 8951473,
      "County_state": "Cherokee,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.075166,
              37.339937
            ],
            [
              -95.074159,
              37.12051
            ],
            [
              -95.073677,
              37.047975
            ],
            [
              -95.0735038728895,
              36.999509015161394
            ],
            [
              -95.049499,
              36.99958
            ],
            [
              -95.037857,
              36.999497
            ],
            [
              -95.030324,
              36.999517
            ],
            [
              -95.011433,
              36.999535
            ],
            [
              -95.00762,
              36.999514
            ],
            [
              -94.995293,
              36.999529
            ],
            [
              -94.853197,
              36.998874
            ],
            [
              -94.849801,
              36.998876
            ],
            [
              -94.840926,
              36.998833
            ],
            [
              -94.83128,
              36.998812
            ],
            [
              -94.777257,
              36.998764
            ],
            [
              -94.739324,
              36.998687
            ],
            [
              -94.737183,
              36.998665
            ],
            [
              -94.71277,
              36.998794
            ],
            [
              -94.701797,
              36.998814
            ],
            [
              -94.699735,
              36.998805
            ],
            [
              -94.625224,
              36.998672
            ],
            [
              -94.617964,
              36.998905
            ],
            [
              -94.617995,
              37.009016
            ],
            [
              -94.617965,
              37.040537
            ],
            [
              -94.6178750047381,
              37.0567971439288
            ],
            [
              -94.617875,
              37.056798
            ],
            [
              -94.617982,
              37.075077
            ],
            [
              -94.61812,
              37.085934
            ],
            [
              -94.618082,
              37.086432
            ],
            [
              -94.61809,
              37.093494
            ],
            [
              -94.618088,
              37.093671
            ],
            [
              -94.618059,
              37.096676
            ],
            [
              -94.618151,
              37.103968
            ],
            [
              -94.618212,
              37.113169
            ],
            [
              -94.618075,
              37.129755
            ],
            [
              -94.618072,
              37.132345
            ],
            [
              -94.6183507189726,
              37.160182931508196
            ],
            [
              -94.618351,
              37.160211
            ],
            [
              -94.618473,
              37.174782
            ],
            [
              -94.618505,
              37.181184
            ],
            [
              -94.618319,
              37.188774
            ],
            [
              -94.6183145404829,
              37.1946870011618
            ],
            [
              -94.618305,
              37.207337
            ],
            [
              -94.618219,
              37.207772
            ],
            [
              -94.618177481484,
              37.2200163519201
            ],
            [
              -94.61815,
              37.228121
            ],
            [
              -94.618123,
              37.229334
            ],
            [
              -94.618158,
              37.237597
            ],
            [
              -94.618075,
              37.240436
            ],
            [
              -94.617648,
              37.323589
            ],
            [
              -94.617695,
              37.336842
            ],
            [
              -94.61763608310468,
              37.3384147815258
            ],
            [
              -94.820861,
              37.339387
            ],
            [
              -95.075166,
              37.339937
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "177",
      "COUNTYNS": "00485051",
      "AFFGEOID": "0500000US20177",
      "GEOID": "20177",
      "NAME": "Shawnee",
      "LSAD": "06",
      "ALAND": 1408930616,
      "AWATER": 30396059,
      "County_state": "Shawnee,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.035899,
              39.129784
            ],
            [
              -96.03906,
              39.126527
            ],
            [
              -96.016997,
              39.12033
            ],
            [
              -95.993738,
              39.133817
            ],
            [
              -96.008993,
              39.115818
            ],
            [
              -95.972607,
              39.111569
            ],
            [
              -95.946754,
              39.098685
            ],
            [
              -95.946712,
              39.044047
            ],
            [
              -95.946728,
              38.986417
            ],
            [
              -95.946868,
              38.928445
            ],
            [
              -95.946587,
              38.869973
            ],
            [
              -95.68284,
              38.869981
            ],
            [
              -95.500724,
              38.869815
            ],
            [
              -95.501258,
              39.014929
            ],
            [
              -95.501861,
              39.027413
            ],
            [
              -95.500254,
              39.056656
            ],
            [
              -95.513902,
              39.067151
            ],
            [
              -95.548751,
              39.068319
            ],
            [
              -95.582345,
              39.053998
            ],
            [
              -95.596759,
              39.064672
            ],
            [
              -95.594756,
              39.086399
            ],
            [
              -95.594711,
              39.129275
            ],
            [
              -95.589443,
              39.14378
            ],
            [
              -95.589571,
              39.158278
            ],
            [
              -95.589472,
              39.216081
            ],
            [
              -95.905235,
              39.216595
            ],
            [
              -96.035573,
              39.21652
            ],
            [
              -96.035899,
              39.129784
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "005",
      "COUNTYNS": "00484972",
      "AFFGEOID": "0500000US20005",
      "GEOID": "20005",
      "NAME": "Atchison",
      "LSAD": "06",
      "ALAND": 1116736559,
      "AWATER": 6851102,
      "County_state": "Atchison,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.570348,
              39.56587
            ],
            [
              -95.570351,
              39.41905
            ],
            [
              -95.385268,
              39.420397
            ],
            [
              -95.180891,
              39.419218
            ],
            [
              -94.96884862177319,
              39.4190729027552
            ],
            [
              -94.972952,
              39.421705
            ],
            [
              -94.976606,
              39.426701
            ],
            [
              -94.978798,
              39.436241
            ],
            [
              -94.982144,
              39.440552
            ],
            [
              -94.990172,
              39.446192
            ],
            [
              -94.995768,
              39.448174
            ],
            [
              -95.015825,
              39.452809
            ],
            [
              -95.028498,
              39.458287
            ],
            [
              -95.033408,
              39.460876
            ],
            [
              -95.0375,
              39.463689
            ],
            [
              -95.04078,
              39.466387
            ],
            [
              -95.045716,
              39.472459
            ],
            [
              -95.047133,
              39.474971
            ],
            [
              -95.04837,
              39.48042
            ],
            [
              -95.049845,
              39.494415
            ],
            [
              -95.050552,
              39.497514
            ],
            [
              -95.052177,
              39.499996
            ],
            [
              -95.05638,
              39.503972
            ],
            [
              -95.059461,
              39.506143
            ],
            [
              -95.077441,
              39.513552
            ],
            [
              -95.082714,
              39.516712
            ],
            [
              -95.092704,
              39.524241
            ],
            [
              -95.102888,
              39.533347
            ],
            [
              -95.106596,
              39.537657
            ],
            [
              -95.109304,
              39.542285
            ],
            [
              -95.113557,
              39.553941
            ],
            [
              -95.113077,
              39.559133
            ],
            [
              -95.107454,
              39.573843
            ],
            [
              -95.106406,
              39.575252
            ],
            [
              -95.103228,
              39.577783
            ],
            [
              -95.099095,
              39.579691
            ],
            [
              -95.095736,
              39.580618
            ],
            [
              -95.089515,
              39.581028
            ],
            [
              -95.076688,
              39.576764
            ],
            [
              -95.07216,
              39.576122
            ],
            [
              -95.069315,
              39.576218
            ],
            [
              -95.064519,
              39.577115
            ],
            [
              -95.059519,
              39.579132
            ],
            [
              -95.056897,
              39.580567
            ],
            [
              -95.054804,
              39.582488
            ],
            [
              -95.049277,
              39.589583
            ],
            [
              -95.047165,
              39.595117
            ],
            [
              -95.046361,
              39.599557
            ],
            [
              -95.046445,
              39.601606
            ],
            [
              -95.047911,
              39.606288
            ],
            [
              -95.053012,
              39.613965
            ],
            [
              -95.05338996101001,
              39.615323540228594
            ],
            [
              -95.08544,
              39.615074
            ],
            [
              -95.098006,
              39.620871
            ],
            [
              -95.115189,
              39.652549
            ],
            [
              -95.33974,
              39.65298
            ],
            [
              -95.396177,
              39.652949
            ],
            [
              -95.564126,
              39.652872
            ],
            [
              -95.564308,
              39.567294
            ],
            [
              -95.570348,
              39.56587
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "089",
      "COUNTYNS": "00485009",
      "AFFGEOID": "0500000US20089",
      "GEOID": "20089",
      "NAME": "Jewell",
      "LSAD": "06",
      "ALAND": 2356317389,
      "AWATER": 11863330,
      "County_state": "Jewell,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.5044549849831,
              40.0023285653691
            ],
            [
              -98.505266,
              39.567603
            ],
            [
              -98.487384,
              39.567492
            ],
            [
              -98.157133,
              39.567138
            ],
            [
              -97.931844,
              39.566921
            ],
            [
              -97.931482,
              39.653767
            ],
            [
              -97.93182554601101,
              40.0020500230574
            ],
            [
              -97.972186,
              40.002114
            ],
            [
              -98.010157,
              40.002153
            ],
            [
              -98.014412,
              40.002223
            ],
            [
              -98.047469,
              40.002186
            ],
            [
              -98.050057,
              40.002278
            ],
            [
              -98.068701,
              40.002355
            ],
            [
              -98.076034,
              40.002301
            ],
            [
              -98.099659,
              40.002227
            ],
            [
              -98.142031,
              40.002452
            ],
            [
              -98.15684409451629,
              40.0024451416323
            ],
            [
              -98.172269,
              40.002438
            ],
            [
              -98.179315,
              40.002483
            ],
            [
              -98.193483,
              40.002614
            ],
            [
              -98.250008,
              40.002307
            ],
            [
              -98.268218,
              40.00249
            ],
            [
              -98.274015,
              40.002516
            ],
            [
              -98.2740168698569,
              40.002515998333195
            ],
            [
              -98.490533,
              40.002323
            ],
            [
              -98.5044549849831,
              40.0023285653691
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "117",
      "COUNTYNS": "00485023",
      "AFFGEOID": "0500000US20117",
      "GEOID": "20117",
      "NAME": "Marshall",
      "LSAD": "06",
      "ALAND": 2331432992,
      "AWATER": 12743798,
      "County_state": "Marshall,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.806201,
              39.827538
            ],
            [
              -96.806544,
              39.566423
            ],
            [
              -96.581453,
              39.566333
            ],
            [
              -96.580362,
              39.566332
            ],
            [
              -96.238969,
              39.56622
            ],
            [
              -96.2395,
              39.914345
            ],
            [
              -96.2392078816029,
              40.000690965796096
            ],
            [
              -96.301066,
              40.000632
            ],
            [
              -96.304555,
              40.000629
            ],
            [
              -96.46364,
              40.000967
            ],
            [
              -96.463712955188,
              40.0009682733452
            ],
            [
              -96.467536,
              40.001035
            ],
            [
              -96.469945,
              40.000966
            ],
            [
              -96.527111,
              40.001031
            ],
            [
              -96.538977,
              40.000851
            ],
            [
              -96.557863,
              40.000968
            ],
            [
              -96.570854,
              40.001091
            ],
            [
              -96.580852,
              40.000966
            ],
            [
              -96.58178800024619,
              40.000963078894
            ],
            [
              -96.604884,
              40.000891
            ],
            [
              -96.610349,
              40.000881
            ],
            [
              -96.622401,
              40.001158
            ],
            [
              -96.805768,
              40.0013709706497
            ],
            [
              -96.806201,
              39.827538
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "131",
      "COUNTYNS": "00485029",
      "AFFGEOID": "0500000US20131",
      "GEOID": "20131",
      "NAME": "Nemaha",
      "LSAD": "06",
      "ALAND": 1858124407,
      "AWATER": 5208983,
      "County_state": "Nemaha,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.2392078816029,
              40.000690965796096
            ],
            [
              -96.2395,
              39.914345
            ],
            [
              -96.238969,
              39.56622
            ],
            [
              -96.035952,
              39.5661
            ],
            [
              -95.789098,
              39.565943
            ],
            [
              -95.788941,
              39.653001
            ],
            [
              -95.7881114096839,
              40.0004520166495
            ],
            [
              -95.882524,
              40.00047
            ],
            [
              -95.958139,
              40.000521
            ],
            [
              -96.010678,
              40.000638
            ],
            [
              -96.01068007843601,
              40.00063801255239
            ],
            [
              -96.02409,
              40.000719
            ],
            [
              -96.051691,
              40.000727
            ],
            [
              -96.081395,
              40.000603
            ],
            [
              -96.089781,
              40.000519
            ],
            [
              -96.125788,
              40.000467
            ],
            [
              -96.125937,
              40.000432
            ],
            [
              -96.147167,
              40.000479
            ],
            [
              -96.154246,
              40.00045
            ],
            [
              -96.154365,
              40.000495
            ],
            [
              -96.220171,
              40.00072
            ],
            [
              -96.223839,
              40.000729
            ],
            [
              -96.239172,
              40.000691
            ],
            [
              -96.2392078816029,
              40.000690965796096
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "093",
      "COUNTYNS": "00485011",
      "AFFGEOID": "0500000US20093",
      "GEOID": "20093",
      "NAME": "Kearny",
      "LSAD": "06",
      "ALAND": 2254696682,
      "AWATER": 1133601,
      "County_state": "Kearny,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.542312,
              38.263207
            ],
            [
              -101.542757,
              37.827594
            ],
            [
              -101.526964,
              37.827626
            ],
            [
              -101.527063,
              37.736314
            ],
            [
              -101.089667,
              37.736337
            ],
            [
              -101.089886,
              37.829115
            ],
            [
              -101.103778,
              37.829035
            ],
            [
              -101.103268,
              38.264557
            ],
            [
              -101.125438,
              38.264509
            ],
            [
              -101.542312,
              38.263207
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "129",
      "COUNTYNS": "00485135",
      "AFFGEOID": "0500000US20129",
      "GEOID": "20129",
      "NAME": "Morton",
      "LSAD": "06",
      "ALAND": 1889993244,
      "AWATER": 507796,
      "County_state": "Morton,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.041952,
              37.024742
            ],
            [
              -102.04224,
              36.993083
            ],
            [
              -102.028207,
              36.993125
            ],
            [
              -102.02820412187499,
              36.9931250152408
            ],
            [
              -102.000447,
              36.993272
            ],
            [
              -102.000447,
              36.993249
            ],
            [
              -101.90244,
              36.993702
            ],
            [
              -101.601593,
              36.995095
            ],
            [
              -101.600396,
              36.995153
            ],
            [
              -101.555260303913,
              36.9954138768669
            ],
            [
              -101.556606,
              37.388485
            ],
            [
              -102.041585760593,
              37.3891904308032
            ],
            [
              -102.041524,
              37.375018
            ],
            [
              -102.042089,
              37.352819
            ],
            [
              -102.041974,
              37.352613
            ],
            [
              -102.041817,
              37.30949
            ],
            [
              -102.041664,
              37.29765
            ],
            [
              -102.041963,
              37.258164
            ],
            [
              -102.042002,
              37.141744
            ],
            [
              -102.042135,
              37.125021
            ],
            [
              -102.042092,
              37.125021
            ],
            [
              -102.041809,
              37.111973
            ],
            [
              -102.041983,
              37.106551
            ],
            [
              -102.04192,
              37.035083
            ],
            [
              -102.041749,
              37.034397
            ],
            [
              -102.041921,
              37.032178
            ],
            [
              -102.04195,
              37.030805
            ],
            [
              -102.041952,
              37.024742
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "143",
      "COUNTYNS": "00485035",
      "AFFGEOID": "0500000US20143",
      "GEOID": "20143",
      "NAME": "Ottawa",
      "LSAD": "06",
      "ALAND": 1866683174,
      "AWATER": 1567629,
      "County_state": "Ottawa,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.931614,
              39.277094
            ],
            [
              -97.929746,
              39.219273
            ],
            [
              -97.92856,
              38.958395
            ],
            [
              -97.593353,
              38.957916
            ],
            [
              -97.371614,
              38.957852
            ],
            [
              -97.371154,
              39.131961
            ],
            [
              -97.370697,
              39.21899
            ],
            [
              -97.369839,
              39.306011
            ],
            [
              -97.929097,
              39.306397
            ],
            [
              -97.931614,
              39.277094
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "167",
      "COUNTYNS": "00485046",
      "AFFGEOID": "0500000US20167",
      "GEOID": "20167",
      "NAME": "Russell",
      "LSAD": "06",
      "ALAND": 2295402887,
      "AWATER": 34126777,
      "County_state": "Russell,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.039447,
              38.856109
            ],
            [
              -99.042626,
              38.696807
            ],
            [
              -99.032971,
              38.696759
            ],
            [
              -98.486108,
              38.696878
            ],
            [
              -98.484861,
              38.870791
            ],
            [
              -98.483725,
              39.131614
            ],
            [
              -98.489997,
              39.132697
            ],
            [
              -99.0375,
              39.133121
            ],
            [
              -99.039447,
              38.856109
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "045",
      "COUNTYNS": "00484992",
      "AFFGEOID": "0500000US20045",
      "GEOID": "20045",
      "NAME": "Douglas",
      "LSAD": "06",
      "ALAND": 1180628927,
      "AWATER": 48674249,
      "County_state": "Douglas,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.501861,
              39.027413
            ],
            [
              -95.501258,
              39.014929
            ],
            [
              -95.500724,
              38.869815
            ],
            [
              -95.500708,
              38.825929
            ],
            [
              -95.500738,
              38.738808
            ],
            [
              -95.265941,
              38.738625
            ],
            [
              -95.24159,
              38.738678
            ],
            [
              -95.167545,
              38.738471
            ],
            [
              -95.056412,
              38.738587
            ],
            [
              -95.056198,
              38.796814
            ],
            [
              -95.056117,
              38.869573
            ],
            [
              -95.055898,
              38.91322
            ],
            [
              -95.056258,
              38.98212
            ],
            [
              -95.084764,
              38.982334
            ],
            [
              -95.074453,
              38.964369
            ],
            [
              -95.096492,
              38.956319
            ],
            [
              -95.11091,
              38.969338
            ],
            [
              -95.13969,
              38.969388
            ],
            [
              -95.164123,
              38.98163
            ],
            [
              -95.167563,
              38.968875
            ],
            [
              -95.186189,
              38.964542
            ],
            [
              -95.186623,
              38.996674
            ],
            [
              -95.187103,
              39.044109
            ],
            [
              -95.268556,
              39.044091
            ],
            [
              -95.284434,
              39.046675
            ],
            [
              -95.284055,
              39.032306
            ],
            [
              -95.29795,
              39.0423
            ],
            [
              -95.340731,
              39.031712
            ],
            [
              -95.398486,
              39.058061
            ],
            [
              -95.43614,
              39.069781
            ],
            [
              -95.481473,
              39.057322
            ],
            [
              -95.500254,
              39.056656
            ],
            [
              -95.501861,
              39.027413
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "157",
      "COUNTYNS": "00485042",
      "AFFGEOID": "0500000US20157",
      "GEOID": "20157",
      "NAME": "Republic",
      "LSAD": "06",
      "ALAND": 1857980543,
      "AWATER": 7969708,
      "County_state": "Republic,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.931811,
              40.00205
            ],
            [
              -97.93182554601101,
              40.0020500230574
            ],
            [
              -97.931482,
              39.653767
            ],
            [
              -97.36867,
              39.654043
            ],
            [
              -97.3691991185285,
              40.002059878643394
            ],
            [
              -97.415833,
              40.002001
            ],
            [
              -97.417826,
              40.002024
            ],
            [
              -97.425443,
              40.002048
            ],
            [
              -97.444662,
              40.001958
            ],
            [
              -97.463285,
              40.002047
            ],
            [
              -97.510264,
              40.001835
            ],
            [
              -97.511381,
              40.001899
            ],
            [
              -97.515308,
              40.001901
            ],
            [
              -97.767746,
              40.001994
            ],
            [
              -97.769204,
              40.001995
            ],
            [
              -97.770776,
              40.001977
            ],
            [
              -97.777155,
              40.002167
            ],
            [
              -97.819426,
              40.001958
            ],
            [
              -97.8214959564515,
              40.0020018388567
            ],
            [
              -97.821598,
              40.002004
            ],
            [
              -97.838379,
              40.00191
            ],
            [
              -97.85745,
              40.002065
            ],
            [
              -97.876261,
              40.002102
            ],
            [
              -97.931811,
              40.00205
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "103",
      "COUNTYNS": "00485016",
      "AFFGEOID": "0500000US20103",
      "GEOID": "20103",
      "NAME": "Leavenworth",
      "LSAD": "06",
      "ALAND": 1200335284,
      "AWATER": 14221659,
      "County_state": "Leavenworth,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.187963,
              39.128909
            ],
            [
              -95.187103,
              39.044109
            ],
            [
              -95.186623,
              38.996674
            ],
            [
              -95.186189,
              38.964542
            ],
            [
              -95.167563,
              38.968875
            ],
            [
              -95.164123,
              38.98163
            ],
            [
              -95.13969,
              38.969388
            ],
            [
              -95.11091,
              38.969338
            ],
            [
              -95.096492,
              38.956319
            ],
            [
              -95.074453,
              38.964369
            ],
            [
              -95.084764,
              38.982334
            ],
            [
              -95.056258,
              38.98212
            ],
            [
              -95.027847,
              38.978865
            ],
            [
              -95.021132,
              39.000003
            ],
            [
              -94.948275,
              38.984214
            ],
            [
              -94.923349,
              39.002633
            ],
            [
              -94.908765,
              38.991401
            ],
            [
              -94.908275,
              39.128654
            ],
            [
              -94.901394,
              39.128686
            ],
            [
              -94.900454,
              39.201988
            ],
            [
              -94.900191,
              39.202911
            ],
            [
              -94.78472,
              39.201102
            ],
            [
              -94.7755431917741,
              39.2006085912959
            ],
            [
              -94.777838,
              39.203522
            ],
            [
              -94.781518,
              39.206146
            ],
            [
              -94.783838,
              39.207154
            ],
            [
              -94.787343,
              39.207666
            ],
            [
              -94.799663,
              39.206018
            ],
            [
              -94.811663,
              39.206594
            ],
            [
              -94.820687,
              39.208626
            ],
            [
              -94.823791,
              39.209874
            ],
            [
              -94.831679,
              39.215938
            ],
            [
              -94.833552,
              39.217794
            ],
            [
              -94.835056,
              39.220658
            ],
            [
              -94.834896,
              39.223842
            ],
            [
              -94.827791,
              39.234001
            ],
            [
              -94.826111,
              39.238289
            ],
            [
              -94.825663,
              39.241729
            ],
            [
              -94.827487,
              39.249889
            ],
            [
              -94.831471,
              39.256273
            ],
            [
              -94.837855,
              39.262417
            ],
            [
              -94.84632,
              39.268481
            ],
            [
              -94.857072,
              39.273825
            ],
            [
              -94.867568,
              39.277841
            ],
            [
              -94.87832,
              39.281136
            ],
            [
              -94.882576,
              39.283328
            ],
            [
              -94.887056,
              39.28648
            ],
            [
              -94.895217,
              39.294208
            ],
            [
              -94.900049,
              39.300192
            ],
            [
              -94.903137,
              39.306272
            ],
            [
              -94.905329,
              39.311952
            ],
            [
              -94.9066023350017,
              39.3174023019755
            ],
            [
              -94.908065,
              39.323663
            ],
            [
              -94.910641,
              39.348335
            ],
            [
              -94.91016417217429,
              39.351550531235
            ],
            [
              -94.910017,
              39.352543
            ],
            [
              -94.909409,
              39.354255
            ],
            [
              -94.907297,
              39.356735
            ],
            [
              -94.902497,
              39.360383
            ],
            [
              -94.899024,
              39.362431
            ],
            [
              -94.896832,
              39.363135
            ],
            [
              -94.890928,
              39.364031
            ],
            [
              -94.885216,
              39.366911
            ],
            [
              -94.88136,
              39.370383
            ],
            [
              -94.879088,
              39.375703
            ],
            [
              -94.879281,
              39.37978
            ],
            [
              -94.880979,
              39.383899
            ],
            [
              -94.885026,
              39.389801
            ],
            [
              -94.888972,
              39.392432
            ],
            [
              -94.891845,
              39.393313
            ],
            [
              -94.894979,
              39.393565
            ],
            [
              -94.901823,
              39.392798
            ],
            [
              -94.909581,
              39.388865
            ],
            [
              -94.915859,
              39.386348
            ],
            [
              -94.919225,
              39.385174
            ],
            [
              -94.92311,
              39.384492
            ],
            [
              -94.933652,
              39.385546
            ],
            [
              -94.937158,
              39.386531
            ],
            [
              -94.9378575597478,
              39.3869219709658
            ],
            [
              -94.939697,
              39.38795
            ],
            [
              -94.942039,
              39.389499
            ],
            [
              -94.945577,
              39.393851
            ],
            [
              -94.946227,
              39.395648
            ],
            [
              -94.946662,
              39.399717
            ],
            [
              -94.946293,
              39.405646
            ],
            [
              -94.947864,
              39.408604
            ],
            [
              -94.951209,
              39.411707
            ],
            [
              -94.954817,
              39.413844
            ],
            [
              -94.966066,
              39.417288
            ],
            [
              -94.96884862177319,
              39.4190729027552
            ],
            [
              -95.180891,
              39.419218
            ],
            [
              -95.179914,
              39.404744
            ],
            [
              -95.180125,
              39.128889
            ],
            [
              -95.187963,
              39.128909
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "141",
      "COUNTYNS": "00485034",
      "AFFGEOID": "0500000US20141",
      "GEOID": "20141",
      "NAME": "Osborne",
      "LSAD": "06",
      "ALAND": 2311575393,
      "AWATER": 4806274,
      "County_state": "Osborne,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.044398,
              39.568035
            ],
            [
              -99.047687,
              39.133014
            ],
            [
              -99.0375,
              39.133121
            ],
            [
              -98.489997,
              39.132697
            ],
            [
              -98.490149,
              39.21978
            ],
            [
              -98.487384,
              39.567492
            ],
            [
              -98.505266,
              39.567603
            ],
            [
              -99.044398,
              39.568035
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "159",
      "COUNTYNS": "00485043",
      "AFFGEOID": "0500000US20159",
      "GEOID": "20159",
      "NAME": "Rice",
      "LSAD": "06",
      "ALAND": 1880935846,
      "AWATER": 4482698,
      "County_state": "Rice,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.480377,
              38.521841
            ],
            [
              -98.48064,
              38.434674
            ],
            [
              -98.479841,
              38.26079
            ],
            [
              -98.472437,
              38.260741
            ],
            [
              -98.472794,
              38.172757
            ],
            [
              -98.270693,
              38.173537
            ],
            [
              -98.216032,
              38.173498
            ],
            [
              -98.215924,
              38.15875
            ],
            [
              -98.142181,
              38.159538
            ],
            [
              -98.142268,
              38.173918
            ],
            [
              -97.922136,
              38.173713
            ],
            [
              -97.922229,
              38.256672
            ],
            [
              -97.924427,
              38.34789
            ],
            [
              -97.924269,
              38.522755
            ],
            [
              -98.257057,
              38.522176
            ],
            [
              -98.480377,
              38.521841
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "209",
      "COUNTYNS": "00485065",
      "AFFGEOID": "0500000US20209",
      "GEOID": "20209",
      "NAME": "Wyandotte",
      "LSAD": "06",
      "ALAND": 392650861,
      "AWATER": 11890119,
      "County_state": "Wyandotte,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -94.908275,
              39.128654
            ],
            [
              -94.908765,
              38.991401
            ],
            [
              -94.888431,
              39.016142
            ],
            [
              -94.891141,
              39.030679
            ],
            [
              -94.872442,
              39.059088
            ],
            [
              -94.845257,
              39.061342
            ],
            [
              -94.821708,
              39.051037
            ],
            [
              -94.803326,
              39.04352
            ],
            [
              -94.789177,
              39.043612
            ],
            [
              -94.77971,
              39.043721
            ],
            [
              -94.76099,
              39.043662
            ],
            [
              -94.758749,
              39.043693
            ],
            [
              -94.728491,
              39.043988
            ],
            [
              -94.705282,
              39.043993
            ],
            [
              -94.686504,
              39.044054
            ],
            [
              -94.667772,
              39.044073
            ],
            [
              -94.656102,
              39.044029
            ],
            [
              -94.6075174403161,
              39.044085749416595
            ],
            [
              -94.6075101495286,
              39.0446418244116
            ],
            [
              -94.607234,
              39.065704
            ],
            [
              -94.607334,
              39.081704
            ],
            [
              -94.607234,
              39.089604
            ],
            [
              -94.6072345427118,
              39.0897118187523
            ],
            [
              -94.607354,
              39.113444
            ],
            [
              -94.607034,
              39.119404
            ],
            [
              -94.605734,
              39.122204
            ],
            [
              -94.600434,
              39.128503
            ],
            [
              -94.592533,
              39.135903
            ],
            [
              -94.589933,
              39.140403
            ],
            [
              -94.591933,
              39.155003
            ],
            [
              -94.596033,
              39.157703
            ],
            [
              -94.601733,
              39.159603
            ],
            [
              -94.608834,
              39.160503
            ],
            [
              -94.615834,
              39.160003
            ],
            [
              -94.623934,
              39.156603
            ],
            [
              -94.640035,
              39.153103
            ],
            [
              -94.650735,
              39.154103
            ],
            [
              -94.662435,
              39.157603
            ],
            [
              -94.660315,
              39.168051
            ],
            [
              -94.6621616011898,
              39.1738489080539
            ],
            [
              -94.663835,
              39.179103
            ],
            [
              -94.669135,
              39.182003
            ],
            [
              -94.680336,
              39.184303
            ],
            [
              -94.687236,
              39.183503
            ],
            [
              -94.696332,
              39.178563
            ],
            [
              -94.714137,
              39.170403
            ],
            [
              -94.723637,
              39.169003
            ],
            [
              -94.736537,
              39.169203
            ],
            [
              -94.74192558188149,
              39.1702007007742
            ],
            [
              -94.741938,
              39.170203
            ],
            [
              -94.752338,
              39.173203
            ],
            [
              -94.763138,
              39.179903
            ],
            [
              -94.770338,
              39.190002
            ],
            [
              -94.775538,
              39.200602
            ],
            [
              -94.7755431917741,
              39.2006085912959
            ],
            [
              -94.78472,
              39.201102
            ],
            [
              -94.900191,
              39.202911
            ],
            [
              -94.900454,
              39.201988
            ],
            [
              -94.901394,
              39.128686
            ],
            [
              -94.908275,
              39.128654
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "099",
      "COUNTYNS": "00485014",
      "AFFGEOID": "0500000US20099",
      "GEOID": "20099",
      "NAME": "Labette",
      "LSAD": "06",
      "ALAND": 1671303693,
      "AWATER": 20263208,
      "County_state": "Labette,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.521414,
              37.193749
            ],
            [
              -95.5224149880945,
              36.9992810582678
            ],
            [
              -95.511578,
              36.999235
            ],
            [
              -95.407683,
              36.999241
            ],
            [
              -95.40757195069268,
              36.9992412018471
            ],
            [
              -95.33121,
              36.99938
            ],
            [
              -95.328327,
              36.999366
            ],
            [
              -95.328058,
              36.999365
            ],
            [
              -95.322565,
              36.999358
            ],
            [
              -95.195307,
              36.999565
            ],
            [
              -95.177301,
              36.99952
            ],
            [
              -95.1589689477582,
              36.9995367194603
            ],
            [
              -95.155372,
              36.99954
            ],
            [
              -95.155187,
              36.999539
            ],
            [
              -95.1221400136406,
              36.9995268619752
            ],
            [
              -95.073509,
              36.999509
            ],
            [
              -95.0735038728895,
              36.999509015161394
            ],
            [
              -95.073677,
              37.047975
            ],
            [
              -95.074159,
              37.12051
            ],
            [
              -95.075166,
              37.339937
            ],
            [
              -95.074972,
              37.383823
            ],
            [
              -95.088189,
              37.383837
            ],
            [
              -95.379277,
              37.38402
            ],
            [
              -95.52113,
              37.38399
            ],
            [
              -95.518004,
              37.252001
            ],
            [
              -95.521414,
              37.193749
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "091",
      "COUNTYNS": "00485010",
      "AFFGEOID": "0500000US20091",
      "GEOID": "20091",
      "NAME": "Johnson",
      "LSAD": "06",
      "ALAND": 1226262480,
      "AWATER": 16736159,
      "County_state": "Johnson,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.056198,
              38.796814
            ],
            [
              -95.056412,
              38.738587
            ],
            [
              -94.917842,
              38.73833
            ],
            [
              -94.908606,
              38.738281
            ],
            [
              -94.871603,
              38.738324
            ],
            [
              -94.797192,
              38.738313
            ],
            [
              -94.760315,
              38.738235
            ],
            [
              -94.60950894217369,
              38.7381018369191
            ],
            [
              -94.609456,
              38.7407
            ],
            [
              -94.609399,
              38.74244
            ],
            [
              -94.608041,
              38.811064
            ],
            [
              -94.60767342128659,
              38.825639909751196
            ],
            [
              -94.607625,
              38.82756
            ],
            [
              -94.608033,
              38.847207
            ],
            [
              -94.608033,
              38.855007
            ],
            [
              -94.608033,
              38.861207
            ],
            [
              -94.6079958957829,
              38.866831999312495
            ],
            [
              -94.607993,
              38.867271
            ],
            [
              -94.608033,
              38.868107
            ],
            [
              -94.608033,
              38.869207
            ],
            [
              -94.608033,
              38.883807
            ],
            [
              -94.60802799130869,
              38.888639294334695
            ],
            [
              -94.6080256044729,
              38.8909420700723
            ],
            [
              -94.60801759440719,
              38.898670035857194
            ],
            [
              -94.6079797993633,
              38.9351340070476
            ],
            [
              -94.6079780238428,
              38.93684699691519
            ],
            [
              -94.607978,
              38.93687
            ],
            [
              -94.607866,
              38.937398
            ],
            [
              -94.608134,
              38.940006
            ],
            [
              -94.608134,
              38.942006
            ],
            [
              -94.60820799442169,
              38.9567308899182
            ],
            [
              -94.6083185155548,
              38.9787245953992
            ],
            [
              -94.608334,
              38.981806
            ],
            [
              -94.607992364788,
              39.007862828196195
            ],
            [
              -94.6075174403161,
              39.044085749416595
            ],
            [
              -94.656102,
              39.044029
            ],
            [
              -94.667772,
              39.044073
            ],
            [
              -94.686504,
              39.044054
            ],
            [
              -94.705282,
              39.043993
            ],
            [
              -94.728491,
              39.043988
            ],
            [
              -94.758749,
              39.043693
            ],
            [
              -94.76099,
              39.043662
            ],
            [
              -94.77971,
              39.043721
            ],
            [
              -94.789177,
              39.043612
            ],
            [
              -94.803326,
              39.04352
            ],
            [
              -94.821708,
              39.051037
            ],
            [
              -94.845257,
              39.061342
            ],
            [
              -94.872442,
              39.059088
            ],
            [
              -94.891141,
              39.030679
            ],
            [
              -94.888431,
              39.016142
            ],
            [
              -94.908765,
              38.991401
            ],
            [
              -94.923349,
              39.002633
            ],
            [
              -94.948275,
              38.984214
            ],
            [
              -95.021132,
              39.000003
            ],
            [
              -95.027847,
              38.978865
            ],
            [
              -95.056258,
              38.98212
            ],
            [
              -95.055898,
              38.91322
            ],
            [
              -95.056117,
              38.869573
            ],
            [
              -95.056198,
              38.796814
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "041",
      "COUNTYNS": "00484990",
      "AFFGEOID": "0500000US20041",
      "GEOID": "20041",
      "NAME": "Dickinson",
      "LSAD": "06",
      "ALAND": 2193894222,
      "AWATER": 12632461,
      "County_state": "Dickinson,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.381205,
              38.9289
            ],
            [
              -97.372109,
              38.914258
            ],
            [
              -97.372093,
              38.870776
            ],
            [
              -97.371828,
              38.783421
            ],
            [
              -97.371911,
              38.609353
            ],
            [
              -96.930286,
              38.609362
            ],
            [
              -96.930376,
              38.638426
            ],
            [
              -96.930303,
              38.650491
            ],
            [
              -96.933924,
              38.65535
            ],
            [
              -96.933022,
              38.657858
            ],
            [
              -96.930413,
              38.679179
            ],
            [
              -96.930409,
              38.696009
            ],
            [
              -96.927374,
              38.696005
            ],
            [
              -96.927234,
              38.81216
            ],
            [
              -96.890171,
              38.812169
            ],
            [
              -96.890245,
              38.870067
            ],
            [
              -96.92671,
              38.87035
            ],
            [
              -96.926209,
              38.978941
            ],
            [
              -96.933478,
              38.978553
            ],
            [
              -96.96347,
              38.964901
            ],
            [
              -96.962956,
              39.059802
            ],
            [
              -96.963177,
              39.1323
            ],
            [
              -97.259519,
              39.132962
            ],
            [
              -97.371154,
              39.131961
            ],
            [
              -97.371614,
              38.957852
            ],
            [
              -97.381205,
              38.9289
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "155",
      "COUNTYNS": "00485041",
      "AFFGEOID": "0500000US20155",
      "GEOID": "20155",
      "NAME": "Reno",
      "LSAD": "06",
      "ALAND": 3251326003,
      "AWATER": 43423736,
      "County_state": "Reno,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.472794,
              38.172757
            ],
            [
              -98.472257,
              37.824497
            ],
            [
              -98.464906,
              37.823149
            ],
            [
              -98.464804,
              37.732675
            ],
            [
              -98.136225,
              37.732913
            ],
            [
              -97.807823,
              37.733855
            ],
            [
              -97.698692,
              37.735056
            ],
            [
              -97.699679,
              37.825156
            ],
            [
              -97.702089,
              37.829173
            ],
            [
              -97.701969,
              37.911325
            ],
            [
              -97.702006,
              38.072221
            ],
            [
              -97.701841,
              38.173814
            ],
            [
              -97.922136,
              38.173713
            ],
            [
              -98.142268,
              38.173918
            ],
            [
              -98.142181,
              38.159538
            ],
            [
              -98.215924,
              38.15875
            ],
            [
              -98.216032,
              38.173498
            ],
            [
              -98.270693,
              38.173537
            ],
            [
              -98.472794,
              38.172757
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "109",
      "COUNTYNS": "00485019",
      "AFFGEOID": "0500000US20109",
      "GEOID": "20109",
      "NAME": "Logan",
      "LSAD": "06",
      "ALAND": 2779039722,
      "AWATER": 274632,
      "County_state": "Logan,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.478195,
              39.134761
            ],
            [
              -101.484383,
              38.700166
            ],
            [
              -101.128379,
              38.700603
            ],
            [
              -100.818698,
              38.699861
            ],
            [
              -100.811858,
              39.13336
            ],
            [
              -101.391717,
              39.135117
            ],
            [
              -101.478195,
              39.134761
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "081",
      "COUNTYNS": "00485328",
      "AFFGEOID": "0500000US20081",
      "GEOID": "20081",
      "NAME": "Haskell",
      "LSAD": "06",
      "ALAND": 1495773052,
      "AWATER": 910409,
      "County_state": "Haskell,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.089667,
              37.736337
            ],
            [
              -101.08965,
              37.489526
            ],
            [
              -101.089653,
              37.387721
            ],
            [
              -101.067904,
              37.387779
            ],
            [
              -100.798234,
              37.387697
            ],
            [
              -100.652728,
              37.387627
            ],
            [
              -100.652642,
              37.474848
            ],
            [
              -100.652251,
              37.736275
            ],
            [
              -100.797903,
              37.736565
            ],
            [
              -101.089667,
              37.736337
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "063",
      "COUNTYNS": "00485000",
      "AFFGEOID": "0500000US20063",
      "GEOID": "20063",
      "NAME": "Gove",
      "LSAD": "06",
      "ALAND": 2775601943,
      "AWATER": 245369,
      "County_state": "Gove,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.811858,
              39.13336
            ],
            [
              -100.818698,
              38.699861
            ],
            [
              -100.811244,
              38.699695
            ],
            [
              -100.688006,
              38.700021
            ],
            [
              -100.2472,
              38.698165
            ],
            [
              -100.153823,
              38.697341
            ],
            [
              -100.14794,
              39.132179
            ],
            [
              -100.1642,
              39.132071
            ],
            [
              -100.721296,
              39.133381
            ],
            [
              -100.811858,
              39.13336
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "197",
      "COUNTYNS": "00485059",
      "AFFGEOID": "0500000US20197",
      "GEOID": "20197",
      "NAME": "Wabaunsee",
      "LSAD": "06",
      "ALAND": 2057386289,
      "AWATER": 13786678,
      "County_state": "Wabaunsee,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.501556,
              38.869704
            ],
            [
              -96.501397,
              38.826188
            ],
            [
              -96.390398,
              38.825858
            ],
            [
              -96.389749,
              38.738984
            ],
            [
              -96.352613,
              38.739021
            ],
            [
              -96.186638,
              38.73898
            ],
            [
              -95.945924,
              38.739112
            ],
            [
              -95.946587,
              38.869973
            ],
            [
              -95.946868,
              38.928445
            ],
            [
              -95.946728,
              38.986417
            ],
            [
              -95.946712,
              39.044047
            ],
            [
              -95.946754,
              39.098685
            ],
            [
              -95.972607,
              39.111569
            ],
            [
              -96.008993,
              39.115818
            ],
            [
              -95.993738,
              39.133817
            ],
            [
              -96.016997,
              39.12033
            ],
            [
              -96.03906,
              39.126527
            ],
            [
              -96.04398,
              39.141174
            ],
            [
              -96.088225,
              39.152339
            ],
            [
              -96.083867,
              39.163263
            ],
            [
              -96.057658,
              39.158168
            ],
            [
              -96.083557,
              39.193504
            ],
            [
              -96.110458,
              39.20082
            ],
            [
              -96.11551,
              39.190767
            ],
            [
              -96.138094,
              39.19058
            ],
            [
              -96.176741,
              39.188616
            ],
            [
              -96.178864,
              39.203206
            ],
            [
              -96.21975,
              39.200764
            ],
            [
              -96.231214,
              39.211585
            ],
            [
              -96.269382,
              39.178963
            ],
            [
              -96.305151,
              39.197265
            ],
            [
              -96.308083,
              39.19678
            ],
            [
              -96.324729,
              39.186051
            ],
            [
              -96.330006,
              39.159181
            ],
            [
              -96.358292,
              39.156525
            ],
            [
              -96.389046,
              39.172878
            ],
            [
              -96.390796,
              39.043257
            ],
            [
              -96.501166,
              39.043666
            ],
            [
              -96.501556,
              38.869704
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "119",
      "COUNTYNS": "00485024",
      "AFFGEOID": "0500000US20119",
      "GEOID": "20119",
      "NAME": "Meade",
      "LSAD": "06",
      "ALAND": 2533222390,
      "AWATER": 3258258,
      "County_state": "Meade,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.652642,
              37.474848
            ],
            [
              -100.652728,
              37.387627
            ],
            [
              -100.633831,
              37.387577
            ],
            [
              -100.635237,
              37.257016
            ],
            [
              -100.633322824093,
              36.9999361044857
            ],
            [
              -100.62977,
              37.000025
            ],
            [
              -100.591413,
              37.000399
            ],
            [
              -100.591328,
              37.000376
            ],
            [
              -100.552683,
              37.000735
            ],
            [
              -100.551598,
              37.00062
            ],
            [
              -100.201676,
              37.002081
            ],
            [
              -100.193754,
              37.002133
            ],
            [
              -100.192371,
              37.002036
            ],
            [
              -100.187547,
              37.002082
            ],
            [
              -100.115722,
              37.002206
            ],
            [
              -100.089483739431,
              37.0020915224233
            ],
            [
              -100.091529,
              37.386892
            ],
            [
              -100.107422,
              37.387092
            ],
            [
              -100.107248,
              37.474836
            ],
            [
              -100.216229,
              37.47519
            ],
            [
              -100.652642,
              37.474848
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "077",
      "COUNTYNS": "00485004",
      "AFFGEOID": "0500000US20077",
      "GEOID": "20077",
      "NAME": "Harper",
      "LSAD": "06",
      "ALAND": 2075276152,
      "AWATER": 3913474,
      "County_state": "Harper,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.349804,
              37.384056
            ],
            [
              -98.3471489755077,
              36.9979618781261
            ],
            [
              -98.346188,
              36.997962
            ],
            [
              -98.237712,
              36.997972
            ],
            [
              -98.219499,
              36.997824
            ],
            [
              -98.208218,
              36.997997
            ],
            [
              -98.177596,
              36.998009
            ],
            [
              -98.147452,
              36.998162
            ],
            [
              -98.111985,
              36.998133
            ],
            [
              -98.045342,
              36.998327
            ],
            [
              -98.03989,
              36.998349
            ],
            [
              -98.033955,
              36.998366
            ],
            [
              -97.8023129924768,
              36.998712977542695
            ],
            [
              -97.802134,
              37.212636
            ],
            [
              -97.80453,
              37.384055
            ],
            [
              -97.807057,
              37.386293
            ],
            [
              -98.349804,
              37.384056
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "161",
      "COUNTYNS": "00485044",
      "AFFGEOID": "0500000US20161",
      "GEOID": "20161",
      "NAME": "Riley",
      "LSAD": "06",
      "ALAND": 1579167161,
      "AWATER": 31952420,
      "County_state": "Riley,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -96.958719,
              39.566401
            ],
            [
              -96.961693,
              39.220076
            ],
            [
              -96.849879,
              39.219012
            ],
            [
              -96.84967,
              39.13928
            ],
            [
              -96.830985,
              39.139228
            ],
            [
              -96.832271,
              39.119721
            ],
            [
              -96.851269,
              39.10074
            ],
            [
              -96.851409,
              39.088176
            ],
            [
              -96.826454,
              39.088122
            ],
            [
              -96.728177,
              39.088358
            ],
            [
              -96.7063,
              39.091136
            ],
            [
              -96.706122,
              39.073077
            ],
            [
              -96.67948,
              39.073114
            ],
            [
              -96.519668,
              39.072872
            ],
            [
              -96.500962,
              39.072681
            ],
            [
              -96.501166,
              39.043666
            ],
            [
              -96.390796,
              39.043257
            ],
            [
              -96.389046,
              39.172878
            ],
            [
              -96.416707,
              39.185877
            ],
            [
              -96.4596,
              39.189871
            ],
            [
              -96.463808,
              39.177884
            ],
            [
              -96.460371,
              39.168445
            ],
            [
              -96.476131,
              39.164457
            ],
            [
              -96.490172,
              39.17709
            ],
            [
              -96.501055,
              39.167503
            ],
            [
              -96.5167,
              39.17113
            ],
            [
              -96.517084,
              39.187627
            ],
            [
              -96.553866,
              39.17895
            ],
            [
              -96.556274,
              39.179912
            ],
            [
              -96.557892,
              39.181974
            ],
            [
              -96.558937,
              39.190127
            ],
            [
              -96.560059,
              39.194524
            ],
            [
              -96.560972,
              39.196235
            ],
            [
              -96.56059,
              39.197723
            ],
            [
              -96.559356,
              39.198391
            ],
            [
              -96.556557,
              39.198351
            ],
            [
              -96.519209,
              39.198912
            ],
            [
              -96.538493,
              39.214834
            ],
            [
              -96.54611,
              39.208404
            ],
            [
              -96.562342,
              39.241341
            ],
            [
              -96.594841,
              39.25466
            ],
            [
              -96.638761,
              39.292183
            ],
            [
              -96.641696,
              39.305373
            ],
            [
              -96.656665,
              39.317202
            ],
            [
              -96.64656,
              39.326182
            ],
            [
              -96.671743,
              39.334496
            ],
            [
              -96.668409,
              39.34313
            ],
            [
              -96.688676,
              39.358211
            ],
            [
              -96.693334,
              39.371681
            ],
            [
              -96.726667,
              39.403601
            ],
            [
              -96.715148,
              39.420293
            ],
            [
              -96.728327,
              39.427132
            ],
            [
              -96.713094,
              39.43568
            ],
            [
              -96.721691,
              39.443848
            ],
            [
              -96.706722,
              39.461533
            ],
            [
              -96.6876,
              39.462122
            ],
            [
              -96.683205,
              39.480354
            ],
            [
              -96.667793,
              39.475324
            ],
            [
              -96.656024,
              39.501267
            ],
            [
              -96.639738,
              39.510206
            ],
            [
              -96.644383,
              39.518926
            ],
            [
              -96.620675,
              39.538355
            ],
            [
              -96.615616,
              39.531773
            ],
            [
              -96.58923,
              39.54566
            ],
            [
              -96.580362,
              39.566332
            ],
            [
              -96.581453,
              39.566333
            ],
            [
              -96.806544,
              39.566423
            ],
            [
              -96.958719,
              39.566401
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "105",
      "COUNTYNS": "00485017",
      "AFFGEOID": "0500000US20105",
      "GEOID": "20105",
      "NAME": "Lincoln",
      "LSAD": "06",
      "ALAND": 1863226687,
      "AWATER": 2748359,
      "County_state": "Lincoln,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.490149,
              39.21978
            ],
            [
              -98.489997,
              39.132697
            ],
            [
              -98.483725,
              39.131614
            ],
            [
              -98.484861,
              38.870791
            ],
            [
              -98.15071,
              38.870788
            ],
            [
              -97.928592,
              38.871099
            ],
            [
              -97.928595,
              38.871121
            ],
            [
              -97.92856,
              38.958395
            ],
            [
              -97.929746,
              39.219273
            ],
            [
              -98.154008,
              39.219158
            ],
            [
              -98.490149,
              39.21978
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "175",
      "COUNTYNS": "00485050",
      "AFFGEOID": "0500000US20175",
      "GEOID": "20175",
      "NAME": "Seward",
      "LSAD": "06",
      "ALAND": 1655865965,
      "AWATER": 2777350,
      "County_state": "Seward,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.067904,
              37.387779
            ],
            [
              -101.067763,
              37.256999
            ],
            [
              -101.066451000186,
              36.9979220177139
            ],
            [
              -101.053589,
              36.997967
            ],
            [
              -101.012641,
              36.998176
            ],
            [
              -100.996502,
              36.998044
            ],
            [
              -100.945566,
              36.998152
            ],
            [
              -100.945468897552,
              36.998152969176196
            ],
            [
              -100.904588,
              36.998561
            ],
            [
              -100.904274,
              36.998745
            ],
            [
              -100.89166,
              36.998604
            ],
            [
              -100.855634,
              36.998626
            ],
            [
              -100.814277,
              36.999085
            ],
            [
              -100.806116,
              36.999091
            ],
            [
              -100.765484,
              36.999177
            ],
            [
              -100.756894,
              36.999357
            ],
            [
              -100.734517,
              36.999059
            ],
            [
              -100.675552,
              36.999688
            ],
            [
              -100.633327,
              36.999936
            ],
            [
              -100.633322824093,
              36.9999361044857
            ],
            [
              -100.635237,
              37.257016
            ],
            [
              -100.633831,
              37.387577
            ],
            [
              -100.652728,
              37.387627
            ],
            [
              -100.798234,
              37.387697
            ],
            [
              -101.067904,
              37.387779
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "009",
      "COUNTYNS": "00484974",
      "AFFGEOID": "0500000US20009",
      "GEOID": "20009",
      "NAME": "Barton",
      "LSAD": "06",
      "ALAND": 2318718654,
      "AWATER": 13913626,
      "County_state": "Barton,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.032971,
              38.696759
            ],
            [
              -99.032408,
              38.348334
            ],
            [
              -99.032309,
              38.261227
            ],
            [
              -99.022472,
              38.261208
            ],
            [
              -98.921845,
              38.261227
            ],
            [
              -98.912583,
              38.261088
            ],
            [
              -98.70075,
              38.260844
            ],
            [
              -98.479841,
              38.26079
            ],
            [
              -98.48064,
              38.434674
            ],
            [
              -98.480377,
              38.521841
            ],
            [
              -98.479958,
              38.696916
            ],
            [
              -98.486108,
              38.696878
            ],
            [
              -99.032971,
              38.696759
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "125",
      "COUNTYNS": "00485027",
      "AFFGEOID": "0500000US20125",
      "GEOID": "20125",
      "NAME": "Montgomery",
      "LSAD": "06",
      "ALAND": 1666728645,
      "AWATER": 20601461,
      "County_state": "Montgomery,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.964399,
              37.299232
            ],
            [
              -95.964396,
              37.255693
            ],
            [
              -95.9642699335572,
              36.9990936072442
            ],
            [
              -95.936992,
              36.999268
            ],
            [
              -95.928122,
              36.999245
            ],
            [
              -95.91018,
              36.999336
            ],
            [
              -95.877151,
              36.999304
            ],
            [
              -95.875257,
              36.999302
            ],
            [
              -95.873944,
              36.9993
            ],
            [
              -95.866899,
              36.999261
            ],
            [
              -95.80798,
              36.999124
            ],
            [
              -95.786762,
              36.99931
            ],
            [
              -95.768719,
              36.999205
            ],
            [
              -95.759905,
              36.999271
            ],
            [
              -95.7464701516561,
              36.9992508443682
            ],
            [
              -95.741908,
              36.999244
            ],
            [
              -95.718054,
              36.999255
            ],
            [
              -95.714887,
              36.999279
            ],
            [
              -95.71038,
              36.999371
            ],
            [
              -95.696659,
              36.999215
            ],
            [
              -95.686452,
              36.999349
            ],
            [
              -95.6740419235188,
              36.9993338732759
            ],
            [
              -95.664301,
              36.999322
            ],
            [
              -95.630079,
              36.99932
            ],
            [
              -95.62435,
              36.99936
            ],
            [
              -95.615934,
              36.999365
            ],
            [
              -95.61214,
              36.999321
            ],
            [
              -95.573598,
              36.99931
            ],
            [
              -95.534401,
              36.999332
            ],
            [
              -95.5224149880945,
              36.9992810582678
            ],
            [
              -95.521414,
              37.193749
            ],
            [
              -95.518004,
              37.252001
            ],
            [
              -95.52113,
              37.38399
            ],
            [
              -95.52556,
              37.383979
            ],
            [
              -95.819458,
              37.385757
            ],
            [
              -95.961605,
              37.386636
            ],
            [
              -95.964337,
              37.375118
            ],
            [
              -95.964399,
              37.299232
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "113",
      "COUNTYNS": "00485021",
      "AFFGEOID": "0500000US20113",
      "GEOID": "20113",
      "NAME": "McPherson",
      "LSAD": "06",
      "ALAND": 2326519566,
      "AWATER": 6031186,
      "County_state": "McPherson,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.924795,
              38.60988
            ],
            [
              -97.924269,
              38.522755
            ],
            [
              -97.924427,
              38.34789
            ],
            [
              -97.922229,
              38.256672
            ],
            [
              -97.922136,
              38.173713
            ],
            [
              -97.701841,
              38.173814
            ],
            [
              -97.592145,
              38.174163
            ],
            [
              -97.37175,
              38.173673
            ],
            [
              -97.371627,
              38.260833
            ],
            [
              -97.37167,
              38.347989
            ],
            [
              -97.371911,
              38.609353
            ],
            [
              -97.592945,
              38.608978
            ],
            [
              -97.70353,
              38.609101
            ],
            [
              -97.924795,
              38.60988
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "035",
      "COUNTYNS": "00484987",
      "AFFGEOID": "0500000US20035",
      "GEOID": "20035",
      "NAME": "Cowley",
      "LSAD": "06",
      "ALAND": 2915648172,
      "AWATER": 17322934,
      "County_state": "Cowley,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.153331,
              37.475544
            ],
            [
              -97.150845,
              37.303713
            ],
            [
              -97.14975,
              37.216518
            ],
            [
              -97.149233,
              37.128605
            ],
            [
              -97.147721,
              36.999111
            ],
            [
              -97.122597,
              36.999036
            ],
            [
              -97.120285,
              36.999014
            ],
            [
              -97.104276,
              36.99902
            ],
            [
              -97.100652,
              36.998998
            ],
            [
              -97.039784,
              36.999
            ],
            [
              -97.030082,
              36.998929
            ],
            [
              -96.975562,
              36.999019
            ],
            [
              -96.967371,
              36.999067
            ],
            [
              -96.934642,
              36.99907
            ],
            [
              -96.9252940031478,
              36.999129494597696
            ],
            [
              -96.921915,
              36.999151
            ],
            [
              -96.917093,
              36.999182
            ],
            [
              -96.90351,
              36.999132
            ],
            [
              -96.902083,
              36.999155
            ],
            [
              -96.87629,
              36.999233
            ],
            [
              -96.867517,
              36.999217
            ],
            [
              -96.822791,
              36.999182
            ],
            [
              -96.795199,
              36.99886
            ],
            [
              -96.79206,
              36.99918
            ],
            [
              -96.749838,
              36.998988
            ],
            [
              -96.74127,
              36.999239
            ],
            [
              -96.73659,
              36.999286
            ],
            [
              -96.710482,
              36.999271
            ],
            [
              -96.705431,
              36.999203
            ],
            [
              -96.5255776738603,
              36.9987120358304
            ],
            [
              -96.524873,
              37.30273
            ],
            [
              -96.52569,
              37.476405
            ],
            [
              -96.935147,
              37.475652
            ],
            [
              -96.936629,
              37.475703
            ],
            [
              -97.043882,
              37.475805
            ],
            [
              -97.153331,
              37.475544
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "011",
      "COUNTYNS": "00484975",
      "AFFGEOID": "0500000US20011",
      "GEOID": "20011",
      "NAME": "Bourbon",
      "LSAD": "06",
      "ALAND": 1645861891,
      "AWATER": 9377598,
      "County_state": "Bourbon,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -95.088082,
              37.732476
            ],
            [
              -95.088012,
              37.674519
            ],
            [
              -94.906328,
              37.674593
            ],
            [
              -94.887228,
              37.674876
            ],
            [
              -94.6177338243197,
              37.6731053668666
            ],
            [
              -94.617734,
              37.673127
            ],
            [
              -94.617885,
              37.682214
            ],
            [
              -94.617687,
              37.686653
            ],
            [
              -94.617651,
              37.687671
            ],
            [
              -94.617805,
              37.690178
            ],
            [
              -94.617975,
              37.722176
            ],
            [
              -94.617808,
              37.729707
            ],
            [
              -94.617721,
              37.77297
            ],
            [
              -94.616862,
              37.819456
            ],
            [
              -94.61645,
              37.83756
            ],
            [
              -94.616426,
              37.845282
            ],
            [
              -94.6162739579757,
              37.8516506335261
            ],
            [
              -94.616,
              37.863126
            ],
            [
              -94.615834,
              37.87251
            ],
            [
              -94.615921,
              37.878331
            ],
            [
              -94.615706,
              37.886843
            ],
            [
              -94.615469,
              37.901775
            ],
            [
              -94.615393,
              37.906392
            ],
            [
              -94.615181,
              37.915944
            ],
            [
              -94.614778,
              37.9342
            ],
            [
              -94.614835,
              37.9367
            ],
            [
              -94.614754,
              37.940769
            ],
            [
              -94.614612,
              37.944362
            ],
            [
              -94.614594,
              37.949978
            ],
            [
              -94.614562,
              37.951517
            ],
            [
              -94.614557,
              37.971037
            ],
            [
              -94.614465,
              37.987799
            ],
            [
              -94.614212,
              37.992462
            ],
            [
              -94.613981,
              38.036949
            ],
            [
              -94.6139813464919,
              38.0370573442828
            ],
            [
              -94.641534,
              38.036786
            ],
            [
              -94.657335,
              38.037227
            ],
            [
              -95.077876,
              38.037706
            ],
            [
              -95.079559,
              37.906826
            ],
            [
              -95.079692,
              37.8198
            ],
            [
              -95.087808,
              37.819815
            ],
            [
              -95.087839,
              37.761405
            ],
            [
              -95.088082,
              37.732476
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "20",
      "COUNTYFP": "015",
      "COUNTYNS": "00484977",
      "AFFGEOID": "0500000US20015",
      "GEOID": "20015",
      "NAME": "Butler",
      "LSAD": "06",
      "ALAND": 3703330875,
      "AWATER": 43157337,
      "County_state": "Butler,20"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.15345,
              37.825166
            ],
            [
              -97.152879,
              37.7379
            ],
            [
              -97.152941,
              37.723292
            ],
            [
              -97.153015,
              37.708678
            ],
            [
              -97.15305,
              37.679531
            ],
            [
              -97.152964,
              37.650313
            ],
            [
              -97.152751,
              37.563043
            ],
            [
              -97.153022,
              37.519258
            ],
            [
              -97.153331,
              37.475544
            ],
            [
              -97.043882,
              37.475805
            ],
            [
              -96.936629,
              37.475703
            ],
            [
              -96.935147,
              37.475652
            ],
            [
              -96.52569,
              37.476405
            ],
            [
              -96.5253,
              37.607015
            ],
            [
              -96.525712,
              37.825089
            ],
            [
              -96.522782,
              38.08637
            ],
            [
              -96.840772,
              38.085622
            ],
            [
              -97.152913,
              38.087704
            ],
            [
              -97.152807,
              38.000371
            ],
            [
              -97.152618,
              37.966394
            ],
            [
              -97.152526,
              37.964896
            ],
            [
              -97.152548,
              37.955962
            ],
            [
              -97.152476,
              37.912733
            ],
            [
              -97.152145,
              37.825175
            ],
            [
              -97.15345,
              37.825166
            ]
          ]
        ]
      ]
    }
  }
]
};