export const counties_nd = {
  "type":"FeatureCollection","name":"nd_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "017",
      "COUNTYNS": "01034226",
      "AFFGEOID": "0500000US38017",
      "GEOID": "38017",
      "NAME": "Cass",
      "LSAD": "06",
      "ALAND": 4571107601,
      "AWATER": 7732062,
      "County_state": "Cass,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.706034,
              47.239978
            ],
            [
              -97.706027,
              46.978558
            ],
            [
              -97.681508,
              46.978481
            ],
            [
              -97.682006,
              46.629928
            ],
            [
              -97.30432,
              46.629356
            ],
            [
              -97.279948,
              46.629348
            ],
            [
              -97.052125,
              46.629892
            ],
            [
              -97.032356,
              46.629915
            ],
            [
              -96.798128,
              46.630326
            ],
            [
              -96.791096,
              46.633155
            ],
            [
              -96.790523,
              46.63688
            ],
            [
              -96.789572,
              46.639079
            ],
            [
              -96.789405,
              46.641639
            ],
            [
              -96.790663,
              46.649112
            ],
            [
              -96.796767,
              46.653363
            ],
            [
              -96.798823,
              46.658071
            ],
            [
              -96.798357,
              46.665314
            ],
            [
              -96.793914,
              46.669212
            ],
            [
              -96.792576,
              46.672173
            ],
            [
              -96.793723,
              46.674943
            ],
            [
              -96.79334,
              46.676854
            ],
            [
              -96.792958,
              46.677427
            ],
            [
              -96.788947,
              46.678382
            ],
            [
              -96.787801,
              46.679815
            ],
            [
              -96.788159,
              46.681879
            ],
            [
              -96.784339,
              46.685054
            ],
            [
              -96.784205,
              46.686768
            ],
            [
              -96.785068,
              46.687636
            ],
            [
              -96.786941,
              46.68822
            ],
            [
              -96.787801,
              46.691181
            ],
            [
              -96.786845,
              46.692805
            ],
            [
              -96.786654,
              46.695861
            ],
            [
              -96.787801,
              46.700446
            ],
            [
              -96.790906,
              46.70297
            ],
            [
              -96.791204,
              46.703747
            ],
            [
              -96.786184,
              46.71284
            ],
            [
              -96.78552018417359,
              46.7163860643062
            ],
            [
              -96.784751,
              46.720495
            ],
            [
              -96.779899,
              46.722915
            ],
            [
              -96.779252,
              46.727429
            ],
            [
              -96.77992,
              46.729149
            ],
            [
              -96.781544,
              46.730104
            ],
            [
              -96.784279,
              46.732993
            ],
            [
              -96.781617,
              46.737197
            ],
            [
              -96.781216,
              46.740944
            ],
            [
              -96.784601,
              46.743094
            ],
            [
              -96.785269,
              46.746246
            ],
            [
              -96.784568,
              46.748669
            ],
            [
              -96.783455,
              46.750353
            ],
            [
              -96.783646,
              46.753123
            ],
            [
              -96.787466,
              46.756753
            ],
            [
              -96.787466,
              46.758472
            ],
            [
              -96.786129,
              46.760956
            ],
            [
              -96.784601,
              46.761338
            ],
            [
              -96.783646,
              46.762579
            ],
            [
              -96.785556,
              46.764394
            ],
            [
              -96.785651,
              46.766113
            ],
            [
              -96.784314,
              46.766973
            ],
            [
              -96.784314,
              46.767546
            ],
            [
              -96.784983,
              46.768788
            ],
            [
              -96.788612,
              46.771271
            ],
            [
              -96.78909,
              46.773373
            ],
            [
              -96.788135,
              46.776238
            ],
            [
              -96.788803,
              46.777575
            ],
            [
              -96.792051,
              46.778339
            ],
            [
              -96.792433,
              46.778913
            ],
            [
              -96.792624,
              46.780632
            ],
            [
              -96.791096,
              46.783688
            ],
            [
              -96.791478,
              46.785694
            ],
            [
              -96.793102,
              46.7877
            ],
            [
              -96.7949794676555,
              46.7890238787444
            ],
            [
              -96.796195,
              46.789881
            ],
            [
              -96.796992,
              46.791572
            ],
            [
              -96.795756,
              46.807795
            ],
            [
              -96.796488,
              46.808709
            ],
            [
              -96.801446,
              46.810401
            ],
            [
              -96.802544,
              46.811521
            ],
            [
              -96.802013,
              46.812464
            ],
            [
              -96.80036,
              46.8135
            ],
            [
              -96.799336,
              46.815436
            ],
            [
              -96.7998821963033,
              46.8182385703524
            ],
            [
              -96.80016,
              46.819664
            ],
            [
              -96.79796,
              46.822364
            ],
            [
              -96.791559,
              46.827864
            ],
            [
              -96.789377,
              46.827435
            ],
            [
              -96.787657,
              46.827817
            ],
            [
              -96.787275,
              46.829059
            ],
            [
              -96.789663,
              46.832306
            ],
            [
              -96.789377,
              46.833166
            ],
            [
              -96.785365,
              46.834025
            ],
            [
              -96.78355,
              46.835936
            ],
            [
              -96.783264,
              46.837464
            ],
            [
              -96.784028,
              46.838897
            ],
            [
              -96.783837,
              46.840234
            ],
            [
              -96.783359,
              46.840807
            ],
            [
              -96.780398,
              46.841189
            ],
            [
              -96.779347,
              46.842144
            ],
            [
              -96.779347,
              46.843672
            ],
            [
              -96.780207,
              46.845392
            ],
            [
              -96.779729,
              46.847302
            ],
            [
              -96.777915,
              46.849594
            ],
            [
              -96.777915,
              46.850741
            ],
            [
              -96.779061,
              46.851696
            ],
            [
              -96.780876,
              46.852269
            ],
            [
              -96.782022,
              46.853415
            ],
            [
              -96.781926,
              46.856472
            ],
            [
              -96.781162,
              46.857809
            ],
            [
              -96.781067,
              46.859146
            ],
            [
              -96.781353,
              46.860483
            ],
            [
              -96.7828151889964,
              46.8624944668
            ],
            [
              -96.782881,
              46.862585
            ],
            [
              -96.782881,
              46.86459
            ],
            [
              -96.780758,
              46.867163
            ],
            [
              -96.779302,
              46.872699
            ],
            [
              -96.779258,
              46.875963
            ],
            [
              -96.780358,
              46.877063
            ],
            [
              -96.781358,
              46.879363
            ],
            [
              -96.780358,
              46.880163
            ],
            [
              -96.775590535491,
              46.8791697782273
            ],
            [
              -96.775558,
              46.879163
            ],
            [
              -96.771258,
              46.877463
            ],
            [
              -96.769758,
              46.877563
            ],
            [
              -96.768458,
              46.879563
            ],
            [
              -96.767358,
              46.883663
            ],
            [
              -96.768058,
              46.884763
            ],
            [
              -96.769758,
              46.884763
            ],
            [
              -96.771858,
              46.884063
            ],
            [
              -96.773558,
              46.884763
            ],
            [
              -96.776558,
              46.895663
            ],
            [
              -96.773558,
              46.903563
            ],
            [
              -96.770458,
              46.906763
            ],
            [
              -96.767458,
              46.905163
            ],
            [
              -96.765657,
              46.905063
            ],
            [
              -96.763557,
              46.909463
            ],
            [
              -96.763973,
              46.912507
            ],
            [
              -96.762871,
              46.916886
            ],
            [
              -96.759241,
              46.918223
            ],
            [
              -96.759337,
              46.91956
            ],
            [
              -96.7604247876106,
              46.9205118141593
            ],
            [
              -96.760865,
              46.920897
            ],
            [
              -96.761343,
              46.922234
            ],
            [
              -96.760961,
              46.923858
            ],
            [
              -96.759528,
              46.925769
            ],
            [
              -96.761725,
              46.927297
            ],
            [
              -96.762011,
              46.928347
            ],
            [
              -96.762011,
              46.929303
            ],
            [
              -96.760292,
              46.932073
            ],
            [
              -96.760292,
              46.93341
            ],
            [
              -96.761757,
              46.934663
            ],
            [
              -96.763257,
              46.935063
            ],
            [
              -96.775157,
              46.930863
            ],
            [
              -96.780258,
              46.928263
            ],
            [
              -96.78312,
              46.925482
            ],
            [
              -96.785126,
              46.925769
            ],
            [
              -96.786845,
              46.928921
            ],
            [
              -96.79038,
              46.929398
            ],
            [
              -96.791048,
              46.929876
            ],
            [
              -96.791621,
              46.931213
            ],
            [
              -96.791558,
              46.934264
            ],
            [
              -96.790058,
              46.937664
            ],
            [
              -96.791558,
              46.944464
            ],
            [
              -96.792863,
              46.946018
            ],
            [
              -96.797734,
              46.9464
            ],
            [
              -96.799358,
              46.947355
            ],
            [
              -96.798758,
              46.952988
            ],
            [
              -96.799606,
              46.954316
            ],
            [
              -96.79991,
              46.959228
            ],
            [
              -96.798737,
              46.962399
            ],
            [
              -96.79931,
              46.964118
            ],
            [
              -96.801316,
              46.965933
            ],
            [
              -96.802749,
              46.965933
            ],
            [
              -96.809814,
              46.9639
            ],
            [
              -96.819558,
              46.967453
            ],
            [
              -96.821852,
              46.969372
            ],
            [
              -96.822043,
              46.971091
            ],
            [
              -96.8200744513851,
              46.9768308444026
            ],
            [
              -96.819894,
              46.977357
            ],
            [
              -96.822566,
              46.990141
            ],
            [
              -96.824598,
              46.993309
            ],
            [
              -96.82447,
              46.996173
            ],
            [
              -96.823189,
              46.998026
            ],
            [
              -96.82318,
              46.999965
            ],
            [
              -96.826198,
              47.001895
            ],
            [
              -96.827489,
              47.001611
            ],
            [
              -96.831798,
              47.004353
            ],
            [
              -96.834221,
              47.006671
            ],
            [
              -96.834603,
              47.007721
            ],
            [
              -96.834508,
              47.008867
            ],
            [
              -96.833504,
              47.01011
            ],
            [
              -96.832303,
              47.015184
            ],
            [
              -96.833038,
              47.016029
            ],
            [
              -96.829499,
              47.021537
            ],
            [
              -96.826358,
              47.023205
            ],
            [
              -96.819416,
              47.024914
            ],
            [
              -96.817984,
              47.026538
            ],
            [
              -96.818557,
              47.02778
            ],
            [
              -96.821231,
              47.029977
            ],
            [
              -96.821613,
              47.031505
            ],
            [
              -96.821422,
              47.032842
            ],
            [
              -96.818843,
              47.034179
            ],
            [
              -96.818557,
              47.035516
            ],
            [
              -96.818748,
              47.037618
            ],
            [
              -96.820563,
              47.039528
            ],
            [
              -96.820849,
              47.041438
            ],
            [
              -96.818843,
              47.047074
            ],
            [
              -96.819321,
              47.0529
            ],
            [
              -96.822568,
              47.055861
            ],
            [
              -96.824479,
              47.059682
            ],
            [
              -96.824097,
              47.061497
            ],
            [
              -96.822186,
              47.06207
            ],
            [
              -96.821327,
              47.06293
            ],
            [
              -96.821804,
              47.064362
            ],
            [
              -96.823491,
              47.065911
            ],
            [
              -96.824097,
              47.070666
            ],
            [
              -96.823715,
              47.071717
            ],
            [
              -96.821231,
              47.07315
            ],
            [
              -96.820849,
              47.073818
            ],
            [
              -96.821613,
              47.076302
            ],
            [
              -96.819479,
              47.078181
            ],
            [
              -96.819078,
              47.081152
            ],
            [
              -96.820216,
              47.082111
            ],
            [
              -96.82065,
              47.083619
            ],
            [
              -96.819034,
              47.087573
            ],
            [
              -96.820563,
              47.08977
            ],
            [
              -96.820085,
              47.091393
            ],
            [
              -96.818366,
              47.093304
            ],
            [
              -96.818557,
              47.097888
            ],
            [
              -96.819894,
              47.099321
            ],
            [
              -96.81999,
              47.100849
            ],
            [
              -96.818175,
              47.104193
            ],
            [
              -96.817984,
              47.106007
            ],
            [
              -96.818843,
              47.107154
            ],
            [
              -96.82159,
              47.108457
            ],
            [
              -96.822694,
              47.109622
            ],
            [
              -96.822192,
              47.111679
            ],
            [
              -96.820619,
              47.113712
            ],
            [
              -96.821189,
              47.115723
            ],
            [
              -96.827344,
              47.120144
            ],
            [
              -96.827726,
              47.121481
            ],
            [
              -96.826712,
              47.122852
            ],
            [
              -96.82544,
              47.123354
            ],
            [
              -96.824807,
              47.124968
            ],
            [
              -96.824476,
              47.127188
            ],
            [
              -96.827631,
              47.129504
            ],
            [
              -96.828777,
              47.13151
            ],
            [
              -96.827631,
              47.134758
            ],
            [
              -96.827631,
              47.136572
            ],
            [
              -96.828597,
              47.1398
            ],
            [
              -96.831547,
              47.142017
            ],
            [
              -96.832407,
              47.143736
            ],
            [
              -96.830114,
              47.146793
            ],
            [
              -96.830114,
              47.148512
            ],
            [
              -96.831069,
              47.149467
            ],
            [
              -96.8312096159494,
              47.1505219877248
            ],
            [
              -96.83126,
              47.1509
            ],
            [
              -96.828013,
              47.153956
            ],
            [
              -96.822706,
              47.156229
            ],
            [
              -96.822405,
              47.156914
            ],
            [
              -96.822707,
              47.157668
            ],
            [
              -96.82467,
              47.159019
            ],
            [
              -96.824861,
              47.159783
            ],
            [
              -96.824288,
              47.16112
            ],
            [
              -96.822377,
              47.162744
            ],
            [
              -96.822091,
              47.165036
            ],
            [
              -96.824479,
              47.167042
            ],
            [
              -96.824288,
              47.170863
            ],
            [
              -96.825147,
              47.172295
            ],
            [
              -96.829637,
              47.17497
            ],
            [
              -96.829828,
              47.176307
            ],
            [
              -96.829446,
              47.177262
            ],
            [
              -96.826962,
              47.180128
            ],
            [
              -96.826676,
              47.181561
            ],
            [
              -96.826962,
              47.182802
            ],
            [
              -96.828299,
              47.183948
            ],
            [
              -96.830401,
              47.184617
            ],
            [
              -96.831451,
              47.185572
            ],
            [
              -96.832407,
              47.187483
            ],
            [
              -96.832502,
              47.188342
            ],
            [
              -96.831165,
              47.190826
            ],
            [
              -96.83126,
              47.191781
            ],
            [
              -96.833075,
              47.193596
            ],
            [
              -96.8368,
              47.195028
            ],
            [
              -96.838233,
              47.196366
            ],
            [
              -96.838806,
              47.197894
            ],
            [
              -96.838615,
              47.199613
            ],
            [
              -96.83766,
              47.201141
            ],
            [
              -96.832789,
              47.203911
            ],
            [
              -96.83212,
              47.204866
            ],
            [
              -96.833457,
              47.20649
            ],
            [
              -96.835177,
              47.207445
            ],
            [
              -96.835463,
              47.208401
            ],
            [
              -96.833648,
              47.210406
            ],
            [
              -96.833362,
              47.211457
            ],
            [
              -96.833553,
              47.212794
            ],
            [
              -96.836514,
              47.216137
            ],
            [
              -96.835654,
              47.219289
            ],
            [
              -96.835941,
              47.221009
            ],
            [
              -96.838329,
              47.222059
            ],
            [
              -96.839284,
              47.223874
            ],
            [
              -96.838806,
              47.22502
            ],
            [
              -96.835654,
              47.226549
            ],
            [
              -96.835654,
              47.227217
            ],
            [
              -96.837374,
              47.229254
            ],
            [
              -96.837564,
              47.231802
            ],
            [
              -96.836036,
              47.233999
            ],
            [
              -96.833362,
              47.23505
            ],
            [
              -96.832693,
              47.236196
            ],
            [
              -96.832946,
              47.237588
            ],
            [
              -96.83358911211658,
              47.2380365686549
            ],
            [
              -97.451512,
              47.239061
            ],
            [
              -97.706034,
              47.239978
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "021",
      "COUNTYNS": "01035622",
      "AFFGEOID": "0500000US38021",
      "GEOID": "38021",
      "NAME": "Dickey",
      "LSAD": "06",
      "ALAND": 2930494902,
      "AWATER": 27610562,
      "County_state": "Dickey,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.0057543501525,
              45.9399443091284
            ],
            [
              -99.005642,
              45.939944
            ],
            [
              -98.905477,
              45.93952
            ],
            [
              -98.904429,
              45.93952
            ],
            [
              -98.7243745348833,
              45.9386863487944
            ],
            [
              -98.625379,
              45.938228
            ],
            [
              -98.50700555702849,
              45.93726017846979
            ],
            [
              -98.414518,
              45.936504
            ],
            [
              -98.18563,
              45.936185
            ],
            [
              -98.184637,
              45.936183
            ],
            [
              -98.070515,
              45.93618
            ],
            [
              -98.008202,
              45.936096
            ],
            [
              -98.00810165517849,
              45.93609536428029
            ],
            [
              -98.006715,
              46.282626
            ],
            [
              -98.034573,
              46.282796
            ],
            [
              -98.041888,
              46.282655
            ],
            [
              -98.502762,
              46.282927
            ],
            [
              -98.661459,
              46.282763
            ],
            [
              -99.003118,
              46.282898
            ],
            [
              -99.0057543501525,
              45.9399443091284
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "063",
      "COUNTYNS": "01034217",
      "AFFGEOID": "0500000US38063",
      "GEOID": "38063",
      "NAME": "Nelson",
      "LSAD": "06",
      "ALAND": 2542787677,
      "AWATER": 70033883,
      "County_state": "Nelson,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.525609,
              48.02115
            ],
            [
              -98.525555,
              47.915056
            ],
            [
              -98.525516,
              47.846276
            ],
            [
              -98.525178,
              47.672497
            ],
            [
              -98.500181,
              47.672413
            ],
            [
              -97.98481,
              47.6723
            ],
            [
              -97.882405,
              47.672179
            ],
            [
              -97.882179,
              48.020803
            ],
            [
              -97.903599,
              48.020897
            ],
            [
              -97.903434,
              48.194897
            ],
            [
              -98.292184,
              48.195177
            ],
            [
              -98.4221,
              48.194824
            ],
            [
              -98.422659,
              48.021115
            ],
            [
              -98.525609,
              48.02115
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "075",
      "COUNTYNS": "01034229",
      "AFFGEOID": "0500000US38075",
      "GEOID": "38075",
      "NAME": "Renville",
      "LSAD": "06",
      "ALAND": 2271556737,
      "AWATER": 40648334,
      "County_state": "Renville,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.021655,
              48.807108
            ],
            [
              -102.021428,
              48.720283
            ],
            [
              -101.842141,
              48.720143
            ],
            [
              -101.841969,
              48.459545
            ],
            [
              -101.581912,
              48.459225
            ],
            [
              -101.320812,
              48.458797
            ],
            [
              -101.059802,
              48.458448
            ],
            [
              -101.05966,
              48.545337
            ],
            [
              -101.451275,
              48.546079
            ],
            [
              -101.451425,
              48.720025
            ],
            [
              -101.496684,
              48.720078
            ],
            [
              -101.49673523516701,
              48.9996403519979
            ],
            [
              -101.500437,
              48.99967
            ],
            [
              -101.625438,
              48.999168
            ],
            [
              -102.021144,
              48.999015
            ],
            [
              -102.021655,
              48.807108
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "039",
      "COUNTYNS": "01034219",
      "AFFGEOID": "0500000US38039",
      "GEOID": "38039",
      "NAME": "Griggs",
      "LSAD": "06",
      "ALAND": 1835826857,
      "AWATER": 19759408,
      "County_state": "Griggs,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.500181,
              47.672413
            ],
            [
              -98.500085,
              47.587228
            ],
            [
              -98.499246,
              47.326538
            ],
            [
              -98.467287,
              47.32641
            ],
            [
              -98.467476,
              47.240453
            ],
            [
              -97.961208,
              47.240512
            ],
            [
              -97.960468,
              47.326048
            ],
            [
              -97.986585,
              47.3261
            ],
            [
              -97.984323,
              47.665995
            ],
            [
              -97.98481,
              47.6723
            ],
            [
              -98.500181,
              47.672413
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "087",
      "COUNTYNS": "01035896",
      "AFFGEOID": "0500000US38087",
      "GEOID": "38087",
      "NAME": "Slope",
      "LSAD": "06",
      "ALAND": 3146544262,
      "AWATER": 10978000,
      "County_state": "Slope,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.046103,
              46.383916
            ],
            [
              -104.045481,
              46.366871
            ],
            [
              -104.045462,
              46.341895
            ],
            [
              -104.045469,
              46.324545
            ],
            [
              -104.045417428123,
              46.280188073090294
            ],
            [
              -103.360609,
              46.27994
            ],
            [
              -102.995454,
              46.280713
            ],
            [
              -102.924547,
              46.281519
            ],
            [
              -102.928003,
              46.630065
            ],
            [
              -103.231569,
              46.629764
            ],
            [
              -103.604838,
              46.629675
            ],
            [
              -103.60921,
              46.629797
            ],
            [
              -103.800825,
              46.629563
            ],
            [
              -103.800881,
              46.540747
            ],
            [
              -104.045098456003,
              46.540929906304
            ],
            [
              -104.045045,
              46.509788
            ],
            [
              -104.046103,
              46.383916
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "077",
      "COUNTYNS": "01035302",
      "AFFGEOID": "0500000US38077",
      "GEOID": "38077",
      "NAME": "Richland",
      "LSAD": "06",
      "ALAND": 3718608511,
      "AWATER": 25035912,
      "County_state": "Richland,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.280019,
              46.543059
            ],
            [
              -97.280568,
              46.282321
            ],
            [
              -97.259339,
              46.282197
            ],
            [
              -97.259632,
              45.997185
            ],
            [
              -97.228569,
              45.99246
            ],
            [
              -97.2282906022523,
              45.935141053260196
            ],
            [
              -97.144987,
              45.935278
            ],
            [
              -97.118053,
              45.935485
            ],
            [
              -97.103218,
              45.935991
            ],
            [
              -97.019596,
              45.935382
            ],
            [
              -97.000361,
              45.935233
            ],
            [
              -96.998652,
              45.9357
            ],
            [
              -96.8940764092495,
              45.9355546187594
            ],
            [
              -96.805155,
              45.935431
            ],
            [
              -96.791505,
              45.935857
            ],
            [
              -96.701313,
              45.935807
            ],
            [
              -96.680646,
              45.935716
            ],
            [
              -96.659895,
              45.93556
            ],
            [
              -96.639066,
              45.935318
            ],
            [
              -96.618295,
              45.935407
            ],
            [
              -96.607142,
              45.935301
            ],
            [
              -96.597432,
              45.935209
            ],
            [
              -96.576897,
              45.935259
            ],
            [
              -96.563672,
              45.935245
            ],
            [
              -96.562525,
              45.937087
            ],
            [
              -96.561334,
              45.945655
            ],
            [
              -96.562135,
              45.947718
            ],
            [
              -96.564803,
              45.950349
            ],
            [
              -96.57035,
              45.963595
            ],
            [
              -96.572384,
              45.980231
            ],
            [
              -96.572483,
              45.989577
            ],
            [
              -96.573605,
              46.002309
            ],
            [
              -96.574064,
              46.004434
            ],
            [
              -96.575869,
              46.007999
            ],
            [
              -96.574264,
              46.016545
            ],
            [
              -96.5765693887837,
              46.0218456562824
            ],
            [
              -96.577315,
              46.02356
            ],
            [
              -96.57794,
              46.026874
            ],
            [
              -96.573644,
              46.037911
            ],
            [
              -96.566295,
              46.051416
            ],
            [
              -96.560945,
              46.055415
            ],
            [
              -96.559271,
              46.058272
            ],
            [
              -96.556907,
              46.06483
            ],
            [
              -96.556611,
              46.06892
            ],
            [
              -96.558055,
              46.071159
            ],
            [
              -96.558088,
              46.072096
            ],
            [
              -96.554507,
              46.083978
            ],
            [
              -96.556345,
              46.08688
            ],
            [
              -96.556672,
              46.097232
            ],
            [
              -96.557952,
              46.102442
            ],
            [
              -96.559167,
              46.105024
            ],
            [
              -96.563175,
              46.107995
            ],
            [
              -96.565723,
              46.111963
            ],
            [
              -96.56692,
              46.11475
            ],
            [
              -96.562811,
              46.11625
            ],
            [
              -96.563043,
              46.119512
            ],
            [
              -96.570023,
              46.123756
            ],
            [
              -96.571439,
              46.12572
            ],
            [
              -96.570081,
              46.127037
            ],
            [
              -96.56926,
              46.133686
            ],
            [
              -96.574784,
              46.143146
            ],
            [
              -96.577381,
              46.144951
            ],
            [
              -96.579453,
              46.147601
            ],
            [
              -96.580408,
              46.151234
            ],
            [
              -96.577715,
              46.162797
            ],
            [
              -96.577952,
              46.165843
            ],
            [
              -96.57862,
              46.168135
            ],
            [
              -96.580531,
              46.169186
            ],
            [
              -96.582823,
              46.170905
            ],
            [
              -96.583779,
              46.173563
            ],
            [
              -96.583324,
              46.174154
            ],
            [
              -96.584495,
              46.177123
            ],
            [
              -96.585647,
              46.177309
            ],
            [
              -96.586739,
              46.177305
            ],
            [
              -96.587408,
              46.178164
            ],
            [
              -96.587599,
              46.178928
            ],
            [
              -96.587599,
              46.180075
            ],
            [
              -96.587408,
              46.181221
            ],
            [
              -96.587217,
              46.182749
            ],
            [
              -96.587503,
              46.183609
            ],
            [
              -96.588554,
              46.185233
            ],
            [
              -96.588579,
              46.189689
            ],
            [
              -96.587724,
              46.191838
            ],
            [
              -96.587694,
              46.195262
            ],
            [
              -96.584929,
              46.197231
            ],
            [
              -96.584272,
              46.198053
            ],
            [
              -96.583582,
              46.201047
            ],
            [
              -96.584372,
              46.204155
            ],
            [
              -96.584899,
              46.204383
            ],
            [
              -96.586744,
              46.209912
            ],
            [
              -96.591652,
              46.218183
            ],
            [
              -96.59567,
              46.21985
            ],
            [
              -96.59755,
              46.227733
            ],
            [
              -96.598645,
              46.241626
            ],
            [
              -96.598119,
              46.243112
            ],
            [
              -96.594234,
              46.245329
            ],
            [
              -96.592375,
              46.246076
            ],
            [
              -96.591037,
              46.247222
            ],
            [
              -96.590369,
              46.247891
            ],
            [
              -96.590082,
              46.248655
            ],
            [
              -96.590369,
              46.249515
            ],
            [
              -96.590942,
              46.250183
            ],
            [
              -96.59247,
              46.250757
            ],
            [
              -96.594189,
              46.251712
            ],
            [
              -96.594571,
              46.253335
            ],
            [
              -96.593712,
              46.254959
            ],
            [
              -96.593616,
              46.256679
            ],
            [
              -96.594571,
              46.258302
            ],
            [
              -96.595909,
              46.259926
            ],
            [
              -96.59887,
              46.26069
            ],
            [
              -96.599729,
              46.262123
            ],
            [
              -96.599087,
              46.263701
            ],
            [
              -96.596822,
              46.267913
            ],
            [
              -96.595014,
              46.275135
            ],
            [
              -96.595509,
              46.276689
            ],
            [
              -96.598392,
              46.28008
            ],
            [
              -96.598774,
              46.281417
            ],
            [
              -96.598201,
              46.283136
            ],
            [
              -96.5961,
              46.286097
            ],
            [
              -96.596077,
              46.290536
            ],
            [
              -96.596968,
              46.291838
            ],
            [
              -96.599347,
              46.292879
            ],
            [
              -96.600302,
              46.294407
            ],
            [
              -96.598679,
              46.29775
            ],
            [
              -96.599156,
              46.299183
            ],
            [
              -96.60027,
              46.300406
            ],
            [
              -96.60136,
              46.30413
            ],
            [
              -96.598233,
              46.312563
            ],
            [
              -96.598399,
              46.314482
            ],
            [
              -96.60104,
              46.319554
            ],
            [
              -96.599761,
              46.330386
            ],
            [
              -96.601048,
              46.331139
            ],
            [
              -96.608075,
              46.332576
            ],
            [
              -96.614676,
              46.337418
            ],
            [
              -96.619991,
              46.340135
            ],
            [
              -96.620454,
              46.341346
            ],
            [
              -96.618147,
              46.344295
            ],
            [
              -96.62079,
              46.347607
            ],
            [
              -96.628522,
              46.349569
            ],
            [
              -96.629378,
              46.350529
            ],
            [
              -96.629211,
              46.352654
            ],
            [
              -96.631586,
              46.353752
            ],
            [
              -96.640267,
              46.351585
            ],
            [
              -96.644335,
              46.351908
            ],
            [
              -96.645959,
              46.353532
            ],
            [
              -96.647296,
              46.358499
            ],
            [
              -96.646341,
              46.360982
            ],
            [
              -96.646532,
              46.36251
            ],
            [
              -96.650718,
              46.363655
            ],
            [
              -96.655206,
              46.365964
            ],
            [
              -96.658009,
              46.370512
            ],
            [
              -96.658436,
              46.373391
            ],
            [
              -96.666028,
              46.374566
            ],
            [
              -96.667189,
              46.375458
            ],
            [
              -96.669794,
              46.384644
            ],
            [
              -96.669132,
              46.390037
            ],
            [
              -96.678507,
              46.404823
            ],
            [
              -96.680687,
              46.407383
            ],
            [
              -96.682008,
              46.40784
            ],
            [
              -96.684834,
              46.407021
            ],
            [
              -96.688082,
              46.40788
            ],
            [
              -96.688846,
              46.409409
            ],
            [
              -96.688318,
              46.410948
            ],
            [
              -96.688941,
              46.413134
            ],
            [
              -96.69429,
              46.41428
            ],
            [
              -96.696583,
              46.415617
            ],
            [
              -96.696869,
              46.416859
            ],
            [
              -96.696392,
              46.418483
            ],
            [
              -96.696869,
              46.420011
            ],
            [
              -96.69792,
              46.42068
            ],
            [
              -96.701358,
              46.420584
            ],
            [
              -96.702314,
              46.422685
            ],
            [
              -96.702314,
              46.423832
            ],
            [
              -96.701167,
              46.426506
            ],
            [
              -96.701645,
              46.428607
            ],
            [
              -96.703078,
              46.429467
            ],
            [
              -96.706134,
              46.429754
            ],
            [
              -96.706994,
              46.430231
            ],
            [
              -96.707471,
              46.432715
            ],
            [
              -96.709095,
              46.435294
            ],
            [
              -96.71177,
              46.436153
            ],
            [
              -96.715495,
              46.436153
            ],
            [
              -96.718074,
              46.438255
            ],
            [
              -96.718647,
              46.439974
            ],
            [
              -96.717967,
              46.442021
            ],
            [
              -96.716438,
              46.444567
            ],
            [
              -96.716641,
              46.447233
            ],
            [
              -96.717119,
              46.448093
            ],
            [
              -96.718169,
              46.448666
            ],
            [
              -96.718933,
              46.451054
            ],
            [
              -96.718551,
              46.451913
            ],
            [
              -96.715593,
              46.453867
            ],
            [
              -96.714861,
              46.459132
            ],
            [
              -96.715557,
              46.463232
            ],
            [
              -96.717453,
              46.464474
            ],
            [
              -96.720414,
              46.468008
            ],
            [
              -96.721274,
              46.470014
            ],
            [
              -96.720891,
              46.471446
            ],
            [
              -96.72156,
              46.472115
            ],
            [
              -96.72242,
              46.472784
            ],
            [
              -96.724712,
              46.473166
            ],
            [
              -96.726718,
              46.474121
            ],
            [
              -96.726914,
              46.476432
            ],
            [
              -96.735123,
              46.478897
            ],
            [
              -96.736365,
              46.480138
            ],
            [
              -96.73627,
              46.48138
            ],
            [
              -96.735028,
              46.483863
            ],
            [
              -96.735505,
              46.484914
            ],
            [
              -96.737129,
              46.485965
            ],
            [
              -96.737989,
              46.487875
            ],
            [
              -96.737798,
              46.489785
            ],
            [
              -96.73457,
              46.494254
            ],
            [
              -96.733612,
              46.497224
            ],
            [
              -96.735499,
              46.497932
            ],
            [
              -96.737702,
              46.50077
            ],
            [
              -96.735888,
              46.504973
            ],
            [
              -96.735888,
              46.50631
            ],
            [
              -96.738562,
              46.509366
            ],
            [
              -96.736147,
              46.513478
            ],
            [
              -96.737408,
              46.517636
            ],
            [
              -96.738475,
              46.525793
            ],
            [
              -96.74202,
              46.529036
            ],
            [
              -96.744341,
              46.533006
            ],
            [
              -96.744341,
              46.53463
            ],
            [
              -96.742239,
              46.536827
            ],
            [
              -96.742335,
              46.538546
            ],
            [
              -96.745009,
              46.540457
            ],
            [
              -96.745105,
              46.541125
            ],
            [
              -96.745009,
              46.541698
            ],
            [
              -96.743003,
              46.54294
            ],
            [
              -96.742812,
              46.543609
            ],
            [
              -96.743577,
              46.54485
            ],
            [
              -96.746347,
              46.546283
            ],
            [
              -96.744341,
              46.550104
            ],
            [
              -96.744532,
              46.551346
            ],
            [
              -96.746824,
              46.555071
            ],
            [
              -96.748161,
              46.556408
            ],
            [
              -96.74883,
              46.558127
            ],
            [
              -96.748161,
              46.559847
            ],
            [
              -96.746633,
              46.560706
            ],
            [
              -96.744436,
              46.56596
            ],
            [
              -96.746442,
              46.574078
            ],
            [
              -96.748161,
              46.575798
            ],
            [
              -96.7516,
              46.576371
            ],
            [
              -96.752746,
              46.577517
            ],
            [
              -96.753033,
              46.57895
            ],
            [
              -96.752078,
              46.582197
            ],
            [
              -96.752746,
              46.58277
            ],
            [
              -96.755421,
              46.582866
            ],
            [
              -96.756949,
              46.583534
            ],
            [
              -96.756662,
              46.585827
            ],
            [
              -96.757999,
              46.586878
            ],
            [
              -96.76182,
              46.588501
            ],
            [
              -96.762393,
              46.589743
            ],
            [
              -96.76182,
              46.592991
            ],
            [
              -96.762584,
              46.593946
            ],
            [
              -96.763865,
              46.594595
            ],
            [
              -96.766596,
              46.597957
            ],
            [
              -96.770226,
              46.598148
            ],
            [
              -96.772446,
              46.600129
            ],
            [
              -96.772457,
              46.601491
            ],
            [
              -96.772476,
              46.603716
            ],
            [
              -96.771802,
              46.605742
            ],
            [
              -96.772088,
              46.606315
            ],
            [
              -96.774763,
              46.607461
            ],
            [
              -96.775622,
              46.609276
            ],
            [
              -96.774094,
              46.613288
            ],
            [
              -96.774954,
              46.614625
            ],
            [
              -96.778488,
              46.616153
            ],
            [
              -96.778965,
              46.617873
            ],
            [
              -96.778201,
              46.619305
            ],
            [
              -96.779061,
              46.620834
            ],
            [
              -96.783932,
              46.621598
            ],
            [
              -96.784505,
              46.625418
            ],
            [
              -96.783837,
              46.627329
            ],
            [
              -96.784123,
              46.628666
            ],
            [
              -96.784792,
              46.62943
            ],
            [
              -96.78481470379259,
              46.629439247899995
            ],
            [
              -96.78995,
              46.631531
            ],
            [
              -96.791096,
              46.633155
            ],
            [
              -96.798128,
              46.630326
            ],
            [
              -97.032356,
              46.629915
            ],
            [
              -97.052125,
              46.629892
            ],
            [
              -97.279948,
              46.629348
            ],
            [
              -97.280019,
              46.543059
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "095",
      "COUNTYNS": "01034213",
      "AFFGEOID": "0500000US38095",
      "GEOID": "38095",
      "NAME": "Towner",
      "LSAD": "06",
      "ALAND": 2653593200,
      "AWATER": 43823568,
      "County_state": "Towner,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.5257000007081,
              48.999309217492694
            ],
            [
              -99.525895,
              48.718384
            ],
            [
              -99.493002,
              48.718359
            ],
            [
              -99.493189,
              48.544834
            ],
            [
              -99.492919,
              48.370946
            ],
            [
              -99.200306,
              48.370658
            ],
            [
              -98.970975,
              48.370472
            ],
            [
              -98.970392,
              48.544232
            ],
            [
              -98.970368,
              48.718089
            ],
            [
              -98.99989,
              48.718129
            ],
            [
              -98.99980337271101,
              48.9999862956524
            ],
            [
              -99.376068,
              48.999357
            ],
            [
              -99.5257000007081,
              48.999309217492694
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "073",
      "COUNTYNS": "01035301",
      "AFFGEOID": "0500000US38073",
      "GEOID": "38073",
      "NAME": "Ransom",
      "LSAD": "06",
      "ALAND": 2233494166,
      "AWATER": 3581125,
      "County_state": "Ransom,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.033862,
              46.630727
            ],
            [
              -98.034573,
              46.282796
            ],
            [
              -98.006715,
              46.282626
            ],
            [
              -97.909259,
              46.282408
            ],
            [
              -97.280568,
              46.282321
            ],
            [
              -97.280019,
              46.543059
            ],
            [
              -97.279948,
              46.629348
            ],
            [
              -97.30432,
              46.629356
            ],
            [
              -97.682006,
              46.629928
            ],
            [
              -98.033862,
              46.630727
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "007",
      "COUNTYNS": "01035616",
      "AFFGEOID": "0500000US38007",
      "GEOID": "38007",
      "NAME": "Billings",
      "LSAD": "06",
      "ALAND": 2975481852,
      "AWATER": 11990484,
      "County_state": "Billings,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.666723,
              47.329354
            ],
            [
              -103.666986,
              46.979789
            ],
            [
              -103.609545,
              46.979902
            ],
            [
              -103.60921,
              46.629797
            ],
            [
              -103.604838,
              46.629675
            ],
            [
              -103.231569,
              46.629764
            ],
            [
              -103.230991,
              46.980296
            ],
            [
              -103.032681,
              46.98058
            ],
            [
              -103.032569,
              47.328692
            ],
            [
              -103.099197,
              47.328714
            ],
            [
              -103.666723,
              47.329354
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "049",
      "COUNTYNS": "01035305",
      "AFFGEOID": "0500000US38049",
      "GEOID": "38049",
      "NAME": "McHenry",
      "LSAD": "06",
      "ALAND": 4853553119,
      "AWATER": 97384707,
      "County_state": "McHenry,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.059802,
              48.458448
            ],
            [
              -101.059915,
              48.371453
            ],
            [
              -101.014138,
              48.371432
            ],
            [
              -101.014319,
              48.283491
            ],
            [
              -101.014783,
              48.109544
            ],
            [
              -101.015024,
              48.022739
            ],
            [
              -100.970502,
              48.022626
            ],
            [
              -100.971325,
              47.848703
            ],
            [
              -100.585161,
              47.84748
            ],
            [
              -100.197996,
              47.8476
            ],
            [
              -100.197558,
              48.021857
            ],
            [
              -100.237092,
              48.021866
            ],
            [
              -100.237332,
              48.371246
            ],
            [
              -100.276401,
              48.371334
            ],
            [
              -100.27666,
              48.544813
            ],
            [
              -100.406388,
              48.544657
            ],
            [
              -100.40678,
              48.631878
            ],
            [
              -101.059624,
              48.632424
            ],
            [
              -101.05966,
              48.545337
            ],
            [
              -101.059802,
              48.458448
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "009",
      "COUNTYNS": "01034227",
      "AFFGEOID": "0500000US38009",
      "GEOID": "38009",
      "NAME": "Bottineau",
      "LSAD": "06",
      "ALAND": 4321196493,
      "AWATER": 74864998,
      "County_state": "Bottineau,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.49673523516701,
              48.9996403519979
            ],
            [
              -101.496684,
              48.720078
            ],
            [
              -101.451425,
              48.720025
            ],
            [
              -101.451275,
              48.546079
            ],
            [
              -101.05966,
              48.545337
            ],
            [
              -101.059624,
              48.632424
            ],
            [
              -100.40678,
              48.631878
            ],
            [
              -100.406388,
              48.544657
            ],
            [
              -100.27666,
              48.544813
            ],
            [
              -100.145858,
              48.545211
            ],
            [
              -100.145494,
              48.718875
            ],
            [
              -100.183034,
              48.718909
            ],
            [
              -100.18270698232801,
              48.999230224641295
            ],
            [
              -100.431676,
              48.999398
            ],
            [
              -100.431642,
              48.999604
            ],
            [
              -100.434351,
              48.99957
            ],
            [
              -100.433981,
              48.99941
            ],
            [
              -100.907107,
              48.999593
            ],
            [
              -100.913634,
              48.999662
            ],
            [
              -100.917939,
              48.999571
            ],
            [
              -100.920577,
              48.99956
            ],
            [
              -101.125434,
              48.999078
            ],
            [
              -101.216182,
              48.999469
            ],
            [
              -101.220754,
              48.999455
            ],
            [
              -101.225187,
              48.999566
            ],
            [
              -101.225915,
              48.999531
            ],
            [
              -101.456737,
              48.99932
            ],
            [
              -101.49673523516701,
              48.9996403519979
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "047",
      "COUNTYNS": "01035620",
      "AFFGEOID": "0500000US38047",
      "GEOID": "38047",
      "NAME": "Logan",
      "LSAD": "06",
      "ALAND": 2571326104,
      "AWATER": 47715596,
      "County_state": "Logan,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.915508,
              46.632925
            ],
            [
              -99.916758,
              46.283171
            ],
            [
              -99.878431,
              46.282811
            ],
            [
              -99.037367,
              46.283151
            ],
            [
              -99.037134,
              46.457368
            ],
            [
              -99.036625,
              46.630211
            ],
            [
              -99.040377,
              46.630586
            ],
            [
              -99.44972,
              46.63163
            ],
            [
              -99.915508,
              46.632925
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "003",
      "COUNTYNS": "01034225",
      "AFFGEOID": "0500000US38003",
      "GEOID": "38003",
      "NAME": "Barnes",
      "LSAD": "06",
      "ALAND": 3863107435,
      "AWATER": 56591279,
      "County_state": "Barnes,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.467476,
              47.240453
            ],
            [
              -98.46753,
              46.979594
            ],
            [
              -98.439466,
              46.979658
            ],
            [
              -98.439056,
              46.63112
            ],
            [
              -98.033862,
              46.630727
            ],
            [
              -97.682006,
              46.629928
            ],
            [
              -97.681508,
              46.978481
            ],
            [
              -97.706027,
              46.978558
            ],
            [
              -97.706034,
              47.239978
            ],
            [
              -97.961208,
              47.240512
            ],
            [
              -98.467476,
              47.240453
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "093",
      "COUNTYNS": "01034224",
      "AFFGEOID": "0500000US38093",
      "GEOID": "38093",
      "NAME": "Stutsman",
      "LSAD": "06",
      "ALAND": 5754301968,
      "AWATER": 197312587,
      "County_state": "Stutsman,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.480726,
              47.327076
            ],
            [
              -99.481085,
              46.980694
            ],
            [
              -99.446423,
              46.980889
            ],
            [
              -99.44972,
              46.63163
            ],
            [
              -99.040377,
              46.630586
            ],
            [
              -99.036625,
              46.630211
            ],
            [
              -98.439056,
              46.63112
            ],
            [
              -98.439466,
              46.979658
            ],
            [
              -98.46753,
              46.979594
            ],
            [
              -98.467476,
              47.240453
            ],
            [
              -98.467287,
              47.32641
            ],
            [
              -98.499246,
              47.326538
            ],
            [
              -99.26628,
              47.327194
            ],
            [
              -99.480726,
              47.327076
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "019",
      "COUNTYNS": "01035304",
      "AFFGEOID": "0500000US38019",
      "GEOID": "38019",
      "NAME": "Cavalier",
      "LSAD": "06",
      "ALAND": 3855846888,
      "AWATER": 54931562,
      "County_state": "Cavalier,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.99980337271101,
              48.9999862956524
            ],
            [
              -98.99989,
              48.718129
            ],
            [
              -98.970368,
              48.718089
            ],
            [
              -98.970392,
              48.544232
            ],
            [
              -98.318098,
              48.543773
            ],
            [
              -97.927154,
              48.543124
            ],
            [
              -97.927411,
              48.717653
            ],
            [
              -97.949577,
              48.717693
            ],
            [
              -97.95020501151559,
              49.0005151189384
            ],
            [
              -98.869037,
              49.000205
            ],
            [
              -98.99980337271101,
              48.9999862956524
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "079",
      "COUNTYNS": "01034228",
      "AFFGEOID": "0500000US38079",
      "GEOID": "38079",
      "NAME": "Rolette",
      "LSAD": "06",
      "ALAND": 2338953786,
      "AWATER": 93938154,
      "County_state": "Rolette,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.18270698232801,
              48.999230224641295
            ],
            [
              -100.183034,
              48.718909
            ],
            [
              -100.145494,
              48.718875
            ],
            [
              -100.145858,
              48.545211
            ],
            [
              -99.493189,
              48.544834
            ],
            [
              -99.493002,
              48.718359
            ],
            [
              -99.525895,
              48.718384
            ],
            [
              -99.5257000007081,
              48.999309217492694
            ],
            [
              -99.861454,
              48.999202
            ],
            [
              -99.861488,
              48.999156
            ],
            [
              -99.913705,
              48.999049
            ],
            [
              -99.91378,
              48.999049
            ],
            [
              -100.18270698232801,
              48.999230224641295
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "041",
      "COUNTYNS": "01034209",
      "AFFGEOID": "0500000US38041",
      "GEOID": "38041",
      "NAME": "Hettinger",
      "LSAD": "06",
      "ALAND": 2932411624,
      "AWATER": 4036912,
      "County_state": "Hettinger,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.928003,
              46.630065
            ],
            [
              -102.924547,
              46.281519
            ],
            [
              -102.548204,
              46.282835
            ],
            [
              -102.497449,
              46.283196
            ],
            [
              -102.497475,
              46.206077
            ],
            [
              -101.997888,
              46.20548
            ],
            [
              -101.998069,
              46.283671
            ],
            [
              -102.046929,
              46.283606
            ],
            [
              -102.050986,
              46.630871
            ],
            [
              -102.096585,
              46.631024
            ],
            [
              -102.297372,
              46.630611
            ],
            [
              -102.928003,
              46.630065
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "091",
      "COUNTYNS": "01034220",
      "AFFGEOID": "0500000US38091",
      "GEOID": "38091",
      "NAME": "Steele",
      "LSAD": "06",
      "ALAND": 1844613572,
      "AWATER": 8424876,
      "County_state": "Steele,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.984323,
              47.665995
            ],
            [
              -97.986585,
              47.3261
            ],
            [
              -97.960468,
              47.326048
            ],
            [
              -97.961208,
              47.240512
            ],
            [
              -97.706034,
              47.239978
            ],
            [
              -97.451512,
              47.239061
            ],
            [
              -97.451868,
              47.324468
            ],
            [
              -97.474337,
              47.324776
            ],
            [
              -97.472745,
              47.672051
            ],
            [
              -97.496356,
              47.672059
            ],
            [
              -97.882405,
              47.672179
            ],
            [
              -97.98481,
              47.6723
            ],
            [
              -97.984323,
              47.665995
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "045",
      "COUNTYNS": "01035621",
      "AFFGEOID": "0500000US38045",
      "GEOID": "38045",
      "NAME": "LaMoure",
      "LSAD": "06",
      "ALAND": 2967976309,
      "AWATER": 12813378,
      "County_state": "LaMoure,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.037134,
              46.457368
            ],
            [
              -99.037367,
              46.283151
            ],
            [
              -99.003118,
              46.282898
            ],
            [
              -98.661459,
              46.282763
            ],
            [
              -98.502762,
              46.282927
            ],
            [
              -98.041888,
              46.282655
            ],
            [
              -98.034573,
              46.282796
            ],
            [
              -98.033862,
              46.630727
            ],
            [
              -98.439056,
              46.63112
            ],
            [
              -99.036625,
              46.630211
            ],
            [
              -99.037134,
              46.457368
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "065",
      "COUNTYNS": "01034205",
      "AFFGEOID": "0500000US38065",
      "GEOID": "38065",
      "NAME": "Oliver",
      "LSAD": "06",
      "ALAND": 1871296135,
      "AWATER": 22476236,
      "County_state": "Oliver,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.764419,
              47.241423
            ],
            [
              -101.764392,
              46.981306
            ],
            [
              -101.575499,
              46.98124
            ],
            [
              -100.935983,
              46.98284
            ],
            [
              -100.895729,
              47.002713
            ],
            [
              -100.879741,
              47.022167
            ],
            [
              -100.884241,
              47.047284
            ],
            [
              -100.925455,
              47.076898
            ],
            [
              -100.937483,
              47.093629
            ],
            [
              -100.944716,
              47.139492
            ],
            [
              -100.965156,
              47.156631
            ],
            [
              -100.983219,
              47.173692
            ],
            [
              -100.99282,
              47.203992
            ],
            [
              -100.97622,
              47.234292
            ],
            [
              -100.977629,
              47.249992
            ],
            [
              -101.002822,
              47.271492
            ],
            [
              -101.034623,
              47.286393
            ],
            [
              -101.060424,
              47.293293
            ],
            [
              -101.106426,
              47.295294
            ],
            [
              -101.18387,
              47.279068
            ],
            [
              -101.195263,
              47.248805
            ],
            [
              -101.215442,
              47.24848
            ],
            [
              -101.257031,
              47.264895
            ],
            [
              -101.257944,
              47.241347
            ],
            [
              -101.764419,
              47.241423
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "059",
      "COUNTYNS": "01034207",
      "AFFGEOID": "0500000US38059",
      "GEOID": "38059",
      "NAME": "Morton",
      "LSAD": "06",
      "ALAND": 4988626570,
      "AWATER": 50054878,
      "County_state": "Morton,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.096675,
              46.981159
            ],
            [
              -102.096239,
              46.717706
            ],
            [
              -101.718155,
              46.718019
            ],
            [
              -101.718105,
              46.630999
            ],
            [
              -101.299271,
              46.630472
            ],
            [
              -101.29899,
              46.371451
            ],
            [
              -101.048532,
              46.371393
            ],
            [
              -101.048579,
              46.28468
            ],
            [
              -101.025662,
              46.284219
            ],
            [
              -101.015926,
              46.299801
            ],
            [
              -101.016701,
              46.320392
            ],
            [
              -100.995296,
              46.31554
            ],
            [
              -100.97951,
              46.329693
            ],
            [
              -100.957543,
              46.340051
            ],
            [
              -100.960813,
              46.347684
            ],
            [
              -100.941508,
              46.350018
            ],
            [
              -100.939727,
              46.374997
            ],
            [
              -100.929059,
              46.377821
            ],
            [
              -100.91895,
              46.394983
            ],
            [
              -100.895431,
              46.39596
            ],
            [
              -100.884552,
              46.409096
            ],
            [
              -100.883182,
              46.391041
            ],
            [
              -100.869612,
              46.389163
            ],
            [
              -100.857966,
              46.402995
            ],
            [
              -100.853699,
              46.395432
            ],
            [
              -100.814873,
              46.384701
            ],
            [
              -100.805562,
              46.389816
            ],
            [
              -100.77403,
              46.391707
            ],
            [
              -100.769534,
              46.397898
            ],
            [
              -100.724731,
              46.392551
            ],
            [
              -100.720504,
              46.379745
            ],
            [
              -100.709867,
              46.392829
            ],
            [
              -100.691922,
              46.397927
            ],
            [
              -100.686469,
              46.409737
            ],
            [
              -100.665897,
              46.406321
            ],
            [
              -100.626984,
              46.41901
            ],
            [
              -100.6065,
              46.419394
            ],
            [
              -100.590199,
              46.427894
            ],
            [
              -100.598099,
              46.451294
            ],
            [
              -100.586599,
              46.492694
            ],
            [
              -100.578739,
              46.505556
            ],
            [
              -100.545821,
              46.525191
            ],
            [
              -100.544555,
              46.533766
            ],
            [
              -100.587027,
              46.573435
            ],
            [
              -100.565369,
              46.578449
            ],
            [
              -100.561035,
              46.587847
            ],
            [
              -100.568141,
              46.594025
            ],
            [
              -100.6254,
              46.612184
            ],
            [
              -100.66189,
              46.63445
            ],
            [
              -100.641665,
              46.678391
            ],
            [
              -100.675739,
              46.673736
            ],
            [
              -100.727996,
              46.655699
            ],
            [
              -100.745458,
              46.665785
            ],
            [
              -100.748293,
              46.692546
            ],
            [
              -100.778156,
              46.686486
            ],
            [
              -100.797939,
              46.715594
            ],
            [
              -100.778478,
              46.720029
            ],
            [
              -100.779477,
              46.736571
            ],
            [
              -100.805577,
              46.735471
            ],
            [
              -100.834782,
              46.749131
            ],
            [
              -100.839414,
              46.763776
            ],
            [
              -100.819608,
              46.796444
            ],
            [
              -100.818944,
              46.807904
            ],
            [
              -100.819015,
              46.808008
            ],
            [
              -100.833979,
              46.821952
            ],
            [
              -100.858566,
              46.84023
            ],
            [
              -100.861404,
              46.843472
            ],
            [
              -100.875458,
              46.865139
            ],
            [
              -100.903516,
              46.896505
            ],
            [
              -100.907675,
              46.948667
            ],
            [
              -100.933367,
              46.964408
            ],
            [
              -100.935983,
              46.98284
            ],
            [
              -101.575499,
              46.98124
            ],
            [
              -101.764392,
              46.981306
            ],
            [
              -102.096675,
              46.981159
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "099",
      "COUNTYNS": "01034214",
      "AFFGEOID": "0500000US38099",
      "GEOID": "38099",
      "NAME": "Walsh",
      "LSAD": "06",
      "ALAND": 3319505703,
      "AWATER": 32184979,
      "County_state": "Walsh,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.318098,
              48.543773
            ],
            [
              -98.31867,
              48.369722
            ],
            [
              -98.292112,
              48.369695
            ],
            [
              -98.292184,
              48.195177
            ],
            [
              -97.903434,
              48.194897
            ],
            [
              -97.878454,
              48.195102
            ],
            [
              -97.87247,
              48.19493
            ],
            [
              -97.141233,
              48.193602
            ],
            [
              -97.138007,
              48.197587
            ],
            [
              -97.139131,
              48.20282
            ],
            [
              -97.138765,
              48.20465
            ],
            [
              -97.13774,
              48.206188
            ],
            [
              -97.134738,
              48.207506
            ],
            [
              -97.134372,
              48.210434
            ],
            [
              -97.137006,
              48.212537
            ],
            [
              -97.137407,
              48.215245
            ],
            [
              -97.135177,
              48.217243
            ],
            [
              -97.135201,
              48.219156
            ],
            [
              -97.135617,
              48.220904
            ],
            [
              -97.137522,
              48.221713
            ],
            [
              -97.138154,
              48.223104
            ],
            [
              -97.13769,
              48.225126
            ],
            [
              -97.136304,
              48.226176
            ],
            [
              -97.136003,
              48.228082
            ],
            [
              -97.136304,
              48.228984
            ],
            [
              -97.139311,
              48.230187
            ],
            [
              -97.140815,
              48.232032
            ],
            [
              -97.141254,
              48.234668
            ],
            [
              -97.13979,
              48.235913
            ],
            [
              -97.135763,
              48.237596
            ],
            [
              -97.135617,
              48.238988
            ],
            [
              -97.138618,
              48.242429
            ],
            [
              -97.138765,
              48.244991
            ],
            [
              -97.138033,
              48.246236
            ],
            [
              -97.133434,
              48.249873
            ],
            [
              -97.129384,
              48.250429
            ],
            [
              -97.127967,
              48.251474
            ],
            [
              -97.127276,
              48.253323
            ],
            [
              -97.127594,
              48.254383
            ],
            [
              -97.129235,
              48.256398
            ],
            [
              -97.129533,
              48.257815
            ],
            [
              -97.129384,
              48.258785
            ],
            [
              -97.127146,
              48.260874
            ],
            [
              -97.127146,
              48.262889
            ],
            [
              -97.128551,
              48.264816
            ],
            [
              -97.130951,
              48.265276
            ],
            [
              -97.131921,
              48.266023
            ],
            [
              -97.131846,
              48.267589
            ],
            [
              -97.13028,
              48.269305
            ],
            [
              -97.125348,
              48.270452
            ],
            [
              -97.12408,
              48.27125
            ],
            [
              -97.11657,
              48.279661
            ],
            [
              -97.116717,
              48.281246
            ],
            [
              -97.117726,
              48.283488
            ],
            [
              -97.12216,
              48.290056
            ],
            [
              -97.125348,
              48.291855
            ],
            [
              -97.127236,
              48.291827
            ],
            [
              -97.128862,
              48.292882
            ],
            [
              -97.129086,
              48.295792
            ],
            [
              -97.128638,
              48.297657
            ],
            [
              -97.127295,
              48.298478
            ],
            [
              -97.123341,
              48.298627
            ],
            [
              -97.12252,
              48.299299
            ],
            [
              -97.122072,
              48.300865
            ],
            [
              -97.122296,
              48.301388
            ],
            [
              -97.126176,
              48.303701
            ],
            [
              -97.126176,
              48.309147
            ],
            [
              -97.127146,
              48.310192
            ],
            [
              -97.130951,
              48.311609
            ],
            [
              -97.131921,
              48.312728
            ],
            [
              -97.132443,
              48.315489
            ],
            [
              -97.131697,
              48.318324
            ],
            [
              -97.13125,
              48.319543
            ],
            [
              -97.129826,
              48.320516
            ],
            [
              -97.127601,
              48.323319
            ],
            [
              -97.127436,
              48.325709
            ],
            [
              -97.127766,
              48.326781
            ],
            [
              -97.131227,
              48.327935
            ],
            [
              -97.133751,
              48.327847
            ],
            [
              -97.134772,
              48.328677
            ],
            [
              -97.134854,
              48.331314
            ],
            [
              -97.131969,
              48.335518
            ],
            [
              -97.131145,
              48.339722
            ],
            [
              -97.131722,
              48.341123
            ],
            [
              -97.137904,
              48.344585
            ],
            [
              -97.138481,
              48.34747
            ],
            [
              -97.137492,
              48.350602
            ],
            [
              -97.137822,
              48.352003
            ],
            [
              -97.139851,
              48.353425
            ],
            [
              -97.143861,
              48.354503
            ],
            [
              -97.147748,
              48.359905
            ],
            [
              -97.147748,
              48.366959
            ],
            [
              -97.147356,
              48.368723
            ],
            [
              -97.146376,
              48.37029
            ],
            [
              -97.144221,
              48.37127
            ],
            [
              -97.142066,
              48.374209
            ],
            [
              -97.140106,
              48.380479
            ],
            [
              -97.140106,
              48.382242
            ],
            [
              -97.14089,
              48.384006
            ],
            [
              -97.143633,
              48.386161
            ],
            [
              -97.145201,
              48.388904
            ],
            [
              -97.145592,
              48.394195
            ],
            [
              -97.145201,
              48.395566
            ],
            [
              -97.143829,
              48.397134
            ],
            [
              -97.140106,
              48.399289
            ],
            [
              -97.135795,
              48.404187
            ],
            [
              -97.135012,
              48.406735
            ],
            [
              -97.1356,
              48.411829
            ],
            [
              -97.138343,
              48.415944
            ],
            [
              -97.142457,
              48.416727
            ],
            [
              -97.142849,
              48.419471
            ],
            [
              -97.142066,
              48.42045
            ],
            [
              -97.136971,
              48.422018
            ],
            [
              -97.1356,
              48.424369
            ],
            [
              -97.1356,
              48.426524
            ],
            [
              -97.137813,
              48.428056
            ],
            [
              -97.139173,
              48.430528
            ],
            [
              -97.139296,
              48.432011
            ],
            [
              -97.136206,
              48.434606
            ],
            [
              -97.13497,
              48.436337
            ],
            [
              -97.134229,
              48.439797
            ],
            [
              -97.135094,
              48.442269
            ],
            [
              -97.137319,
              48.443505
            ],
            [
              -97.137689,
              48.444247
            ],
            [
              -97.137689,
              48.447583
            ],
            [
              -97.137072,
              48.449067
            ],
            [
              -97.133611,
              48.45228
            ],
            [
              -97.132622,
              48.456482
            ],
            [
              -97.132746,
              48.459942
            ],
            [
              -97.134229,
              48.461178
            ],
            [
              -97.141768,
              48.464021
            ],
            [
              -97.143127,
              48.466246
            ],
            [
              -97.144116,
              48.469212
            ],
            [
              -97.143745,
              48.473661
            ],
            [
              -97.142015,
              48.47465
            ],
            [
              -97.141397,
              48.476256
            ],
            [
              -97.142757,
              48.477987
            ],
            [
              -97.144611,
              48.478975
            ],
            [
              -97.144981,
              48.481571
            ],
            [
              -97.143869,
              48.48293
            ],
            [
              -97.140291,
              48.484722
            ],
            [
              -97.139276,
              48.48631
            ],
            [
              -97.138864,
              48.494362
            ],
            [
              -97.140347,
              48.496834
            ],
            [
              -97.146279,
              48.499677
            ],
            [
              -97.147638,
              48.501531
            ],
            [
              -97.148133,
              48.503384
            ],
            [
              -97.153076,
              48.524148
            ],
            [
              -97.151964,
              48.529215
            ],
            [
              -97.149122,
              48.532305
            ],
            [
              -97.148874,
              48.534282
            ],
            [
              -97.150481,
              48.536877
            ],
            [
              -97.153942,
              48.539102
            ],
            [
              -97.159697,
              48.541339
            ],
            [
              -97.1612768424102,
              48.5425053390564
            ],
            [
              -97.666258,
              48.543188
            ],
            [
              -97.927154,
              48.543124
            ],
            [
              -98.318098,
              48.543773
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "055",
      "COUNTYNS": "01034232",
      "AFFGEOID": "0500000US38055",
      "GEOID": "38055",
      "NAME": "McLean",
      "LSAD": "06",
      "ALAND": 5466428300,
      "AWATER": 563112245,
      "County_state": "McLean,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.385565,
              47.84775
            ],
            [
              -102.385758,
              47.758008
            ],
            [
              -102.375457,
              47.763509
            ],
            [
              -102.358256,
              47.79061
            ],
            [
              -102.335554,
              47.80111
            ],
            [
              -102.262051,
              47.782009
            ],
            [
              -102.261251,
              47.762108
            ],
            [
              -102.291253,
              47.752808
            ],
            [
              -102.285052,
              47.738808
            ],
            [
              -102.263151,
              47.727108
            ],
            [
              -102.265452,
              47.624307
            ],
            [
              -102.252052,
              47.609906
            ],
            [
              -102.231452,
              47.598306
            ],
            [
              -102.20535,
              47.574506
            ],
            [
              -102.161251,
              47.565405
            ],
            [
              -102.076249,
              47.573805
            ],
            [
              -102.062249,
              47.569405
            ],
            [
              -102.036648,
              47.572705
            ],
            [
              -102.021648,
              47.559604
            ],
            [
              -102.030249,
              47.540204
            ],
            [
              -102.018349,
              47.535904
            ],
            [
              -101.962847,
              47.53789
            ],
            [
              -101.923651,
              47.523329
            ],
            [
              -101.892017,
              47.505133
            ],
            [
              -101.867294,
              47.504115
            ],
            [
              -101.835827,
              47.495474
            ],
            [
              -101.813848,
              47.496502
            ],
            [
              -101.741827,
              47.529229
            ],
            [
              -101.707537,
              47.538278
            ],
            [
              -101.641696,
              47.528379
            ],
            [
              -101.567785,
              47.536518
            ],
            [
              -101.498705,
              47.556353
            ],
            [
              -101.479654,
              47.555414
            ],
            [
              -101.45417,
              47.563159
            ],
            [
              -101.428037,
              47.561986
            ],
            [
              -101.396552,
              47.53779
            ],
            [
              -101.372373,
              47.527374
            ],
            [
              -101.370866,
              47.518696
            ],
            [
              -101.387166,
              47.507461
            ],
            [
              -101.420139,
              47.494399
            ],
            [
              -101.440139,
              47.477299
            ],
            [
              -101.437638,
              47.458881
            ],
            [
              -101.412338,
              47.435499
            ],
            [
              -101.402537,
              47.402299
            ],
            [
              -101.375436,
              47.372998
            ],
            [
              -101.362536,
              47.367998
            ],
            [
              -101.356635,
              47.324798
            ],
            [
              -101.345735,
              47.291897
            ],
            [
              -101.283833,
              47.278296
            ],
            [
              -101.257031,
              47.264895
            ],
            [
              -101.215442,
              47.24848
            ],
            [
              -101.195263,
              47.248805
            ],
            [
              -101.18387,
              47.279068
            ],
            [
              -101.106426,
              47.295294
            ],
            [
              -101.060424,
              47.293293
            ],
            [
              -101.034623,
              47.286393
            ],
            [
              -101.002822,
              47.271492
            ],
            [
              -100.977629,
              47.249992
            ],
            [
              -100.97622,
              47.234292
            ],
            [
              -100.99282,
              47.203992
            ],
            [
              -100.983219,
              47.173692
            ],
            [
              -100.965156,
              47.156631
            ],
            [
              -100.74937,
              47.157245
            ],
            [
              -100.749334,
              47.327685
            ],
            [
              -100.673285,
              47.327426
            ],
            [
              -100.672408,
              47.674038
            ],
            [
              -100.585106,
              47.673912
            ],
            [
              -100.585161,
              47.84748
            ],
            [
              -100.971325,
              47.848703
            ],
            [
              -101.099882,
              47.848439
            ],
            [
              -101.871862,
              47.847589
            ],
            [
              -102.385565,
              47.84775
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "105",
      "COUNTYNS": "01035306",
      "AFFGEOID": "0500000US38105",
      "GEOID": "38105",
      "NAME": "Williams",
      "LSAD": "06",
      "ALAND": 5381129960,
      "AWATER": 182522236,
      "County_state": "Williams,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.048212,
              48.599055
            ],
            [
              -104.047974,
              48.591606
            ],
            [
              -104.047811,
              48.56277
            ],
            [
              -104.047783,
              48.539737
            ],
            [
              -104.047648,
              48.531489
            ],
            [
              -104.047876,
              48.530798
            ],
            [
              -104.047513,
              48.525913
            ],
            [
              -104.047675,
              48.517852
            ],
            [
              -104.048054,
              48.500025
            ],
            [
              -104.047555,
              48.49414
            ],
            [
              -104.047392,
              48.467086
            ],
            [
              -104.047259,
              48.452941
            ],
            [
              -104.047294,
              48.452529
            ],
            [
              -104.047192,
              48.447251
            ],
            [
              -104.04709,
              48.445903
            ],
            [
              -104.04696,
              48.421065
            ],
            [
              -104.047134,
              48.411057
            ],
            [
              -104.046969,
              48.390675
            ],
            [
              -104.046913,
              48.389433
            ],
            [
              -104.046910665796,
              48.3893008786193
            ],
            [
              -104.046654,
              48.374773
            ],
            [
              -104.046371,
              48.374154
            ],
            [
              -104.046332,
              48.34229
            ],
            [
              -104.046039,
              48.256761
            ],
            [
              -104.045861,
              48.255097
            ],
            [
              -104.045645,
              48.246179
            ],
            [
              -104.045729,
              48.244586
            ],
            [
              -104.045692,
              48.241415
            ],
            [
              -104.04556,
              48.193913
            ],
            [
              -104.045424,
              48.192473
            ],
            [
              -104.045498,
              48.176249
            ],
            [
              -104.045399,
              48.16439
            ],
            [
              -104.04412,
              47.996107
            ],
            [
              -104.020397,
              47.98823
            ],
            [
              -104.007036,
              47.967339
            ],
            [
              -103.987399,
              47.974306
            ],
            [
              -103.986667,
              47.982483
            ],
            [
              -103.967386,
              47.98552
            ],
            [
              -103.942685,
              47.97572
            ],
            [
              -103.919789,
              47.95595
            ],
            [
              -103.888053,
              47.962026
            ],
            [
              -103.869353,
              47.97279
            ],
            [
              -103.855943,
              47.995139
            ],
            [
              -103.864373,
              48.00866
            ],
            [
              -103.856306,
              48.015072
            ],
            [
              -103.826052,
              47.996288
            ],
            [
              -103.82883,
              47.980121
            ],
            [
              -103.800817,
              47.994269
            ],
            [
              -103.791595,
              48.013317
            ],
            [
              -103.79777,
              48.035545
            ],
            [
              -103.784394,
              48.059491
            ],
            [
              -103.770512,
              48.056477
            ],
            [
              -103.747509,
              48.040767
            ],
            [
              -103.711036,
              48.058031
            ],
            [
              -103.709454,
              48.065462
            ],
            [
              -103.740322,
              48.083404
            ],
            [
              -103.742216,
              48.093403
            ],
            [
              -103.717074,
              48.109085
            ],
            [
              -103.675176,
              48.122423
            ],
            [
              -103.632775,
              48.111324
            ],
            [
              -103.597044,
              48.13441
            ],
            [
              -103.585712,
              48.124553
            ],
            [
              -103.608174,
              48.114124
            ],
            [
              -103.583873,
              48.092224
            ],
            [
              -103.548572,
              48.087724
            ],
            [
              -103.551473,
              48.077824
            ],
            [
              -103.582374,
              48.057824
            ],
            [
              -103.583974,
              48.043224
            ],
            [
              -103.569374,
              48.035724
            ],
            [
              -103.525472,
              48.033325
            ],
            [
              -103.501172,
              48.005925
            ],
            [
              -103.485072,
              48.006725
            ],
            [
              -103.459471,
              48.024725
            ],
            [
              -103.42837,
              48.035924
            ],
            [
              -103.379769,
              48.025124
            ],
            [
              -103.322767,
              48.039924
            ],
            [
              -103.288366,
              48.040123
            ],
            [
              -103.277266,
              48.058023
            ],
            [
              -103.232965,
              48.063323
            ],
            [
              -103.225965,
              48.075123
            ],
            [
              -103.252497,
              48.092974
            ],
            [
              -103.213794,
              48.128588
            ],
            [
              -103.20189,
              48.135208
            ],
            [
              -103.15711,
              48.136193
            ],
            [
              -103.107083,
              48.146494
            ],
            [
              -103.092056,
              48.143137
            ],
            [
              -103.088818,
              48.124648
            ],
            [
              -103.069081,
              48.132418
            ],
            [
              -103.027459,
              48.135489
            ],
            [
              -103.008915,
              48.13196
            ],
            [
              -102.972885,
              48.138011
            ],
            [
              -102.93846,
              48.132506
            ],
            [
              -102.928665,
              48.123629
            ],
            [
              -102.906885,
              48.12179
            ],
            [
              -102.864862,
              48.13692
            ],
            [
              -102.828477,
              48.123664
            ],
            [
              -102.828194,
              48.284138
            ],
            [
              -102.828061,
              48.37292
            ],
            [
              -102.885737,
              48.372578
            ],
            [
              -102.885763,
              48.54639
            ],
            [
              -102.885775,
              48.633298
            ],
            [
              -102.894855,
              48.633432
            ],
            [
              -102.907631,
              48.633083
            ],
            [
              -103.79882,
              48.633503
            ],
            [
              -104.047582044105,
              48.6339759112863
            ],
            [
              -104.04762,
              48.627015
            ],
            [
              -104.047586,
              48.625644
            ],
            [
              -104.04793,
              48.62019
            ],
            [
              -104.048212,
              48.599055
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "057",
      "COUNTYNS": "01034233",
      "AFFGEOID": "0500000US38057",
      "GEOID": "38057",
      "NAME": "Mercer",
      "LSAD": "06",
      "ALAND": 2701236315,
      "AWATER": 180102277,
      "County_state": "Mercer,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.205734,
              47.479827
            ],
            [
              -102.205601,
              47.328071
            ],
            [
              -102.144453,
              47.328079
            ],
            [
              -102.144521,
              47.155875
            ],
            [
              -102.144771,
              47.010307
            ],
            [
              -102.144878,
              46.981425
            ],
            [
              -102.096675,
              46.981159
            ],
            [
              -101.764392,
              46.981306
            ],
            [
              -101.764419,
              47.241423
            ],
            [
              -101.257944,
              47.241347
            ],
            [
              -101.257031,
              47.264895
            ],
            [
              -101.283833,
              47.278296
            ],
            [
              -101.345735,
              47.291897
            ],
            [
              -101.356635,
              47.324798
            ],
            [
              -101.362536,
              47.367998
            ],
            [
              -101.375436,
              47.372998
            ],
            [
              -101.402537,
              47.402299
            ],
            [
              -101.412338,
              47.435499
            ],
            [
              -101.437638,
              47.458881
            ],
            [
              -101.440139,
              47.477299
            ],
            [
              -101.420139,
              47.494399
            ],
            [
              -101.387166,
              47.507461
            ],
            [
              -101.370866,
              47.518696
            ],
            [
              -101.372373,
              47.527374
            ],
            [
              -101.396552,
              47.53779
            ],
            [
              -101.428037,
              47.561986
            ],
            [
              -101.45417,
              47.563159
            ],
            [
              -101.479654,
              47.555414
            ],
            [
              -101.498705,
              47.556353
            ],
            [
              -101.567785,
              47.536518
            ],
            [
              -101.641696,
              47.528379
            ],
            [
              -101.707537,
              47.538278
            ],
            [
              -101.741827,
              47.529229
            ],
            [
              -101.813848,
              47.496502
            ],
            [
              -101.835827,
              47.495474
            ],
            [
              -101.867294,
              47.504115
            ],
            [
              -101.892017,
              47.505133
            ],
            [
              -101.923651,
              47.523329
            ],
            [
              -101.962847,
              47.53789
            ],
            [
              -102.018349,
              47.535904
            ],
            [
              -102.030249,
              47.540204
            ],
            [
              -102.021648,
              47.559604
            ],
            [
              -102.036648,
              47.572705
            ],
            [
              -102.062249,
              47.569405
            ],
            [
              -102.076249,
              47.573805
            ],
            [
              -102.161251,
              47.565405
            ],
            [
              -102.20535,
              47.574506
            ],
            [
              -102.205734,
              47.479827
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "085",
      "COUNTYNS": "01034208",
      "AFFGEOID": "0500000US38085",
      "GEOID": "38085",
      "NAME": "Sioux",
      "LSAD": "06",
      "ALAND": 2833690974,
      "AWATER": 88449900,
      "County_state": "Sioux,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -101.998575,
              46.053461
            ],
            [
              -101.9986182554,
              45.9445558815169
            ],
            [
              -101.992187,
              45.944471
            ],
            [
              -101.989501,
              45.944472
            ],
            [
              -101.973749,
              45.944456
            ],
            [
              -101.957439,
              45.944484
            ],
            [
              -101.886838,
              45.944559
            ],
            [
              -101.852642,
              45.944457
            ],
            [
              -101.832991,
              45.944464
            ],
            [
              -101.794606,
              45.944397
            ],
            [
              -101.790054,
              45.944442
            ],
            [
              -101.766177,
              45.944322
            ],
            [
              -101.765293,
              45.944367
            ],
            [
              -101.764277,
              45.944412
            ],
            [
              -101.758611,
              45.944478
            ],
            [
              -101.730069,
              45.944356
            ],
            [
              -101.72338,
              45.944187
            ],
            [
              -101.708785,
              45.944348
            ],
            [
              -101.681819,
              45.944444
            ],
            [
              -101.680574,
              45.944329
            ],
            [
              -101.657631,
              45.944387
            ],
            [
              -101.628597,
              45.944293
            ],
            [
              -101.562156,
              45.944237
            ],
            [
              -101.557276,
              45.9441
            ],
            [
              -101.41989,
              45.943763
            ],
            [
              -101.373769,
              45.944265
            ],
            [
              -101.37069,
              45.944198
            ],
            [
              -101.365283,
              45.944092
            ],
            [
              -101.333871,
              45.944166
            ],
            [
              -101.313272,
              45.944164
            ],
            [
              -101.287223,
              45.944107
            ],
            [
              -101.271524,
              45.944209
            ],
            [
              -101.224006,
              45.944025
            ],
            [
              -101.203787,
              45.943895
            ],
            [
              -101.179103,
              45.943896
            ],
            [
              -101.175693,
              45.943983
            ],
            [
              -101.171074,
              45.943959
            ],
            [
              -101.163241,
              45.943915
            ],
            [
              -101.146076,
              45.943842
            ],
            [
              -101.142571,
              45.943841
            ],
            [
              -101.106826,
              45.943984
            ],
            [
              -101.101334,
              45.943841
            ],
            [
              -100.980693,
              45.944068
            ],
            [
              -100.976565,
              45.943864
            ],
            [
              -100.964411,
              45.943822
            ],
            [
              -100.938989,
              45.943848
            ],
            [
              -100.935582,
              45.943757
            ],
            [
              -100.890176,
              45.943861
            ],
            [
              -100.769751,
              45.943766
            ],
            [
              -100.76211,
              45.943767
            ],
            [
              -100.762072,
              45.943803
            ],
            [
              -100.750407,
              45.943649
            ],
            [
              -100.720865,
              45.944024
            ],
            [
              -100.65082,
              45.94368
            ],
            [
              -100.627681,
              45.943642
            ],
            [
              -100.51194899399302,
              45.9436539838471
            ],
            [
              -100.507026,
              45.966548
            ],
            [
              -100.533105,
              46.000496
            ],
            [
              -100.560205,
              46.008396
            ],
            [
              -100.600005,
              46.049896
            ],
            [
              -100.614504,
              46.071796
            ],
            [
              -100.616104,
              46.115695
            ],
            [
              -100.626329,
              46.126983
            ],
            [
              -100.629145,
              46.145608
            ],
            [
              -100.615303,
              46.169695
            ],
            [
              -100.592202,
              46.196394
            ],
            [
              -100.575301,
              46.209394
            ],
            [
              -100.5592,
              46.235694
            ],
            [
              -100.558549,
              46.249993
            ],
            [
              -100.5798,
              46.272294
            ],
            [
              -100.5804,
              46.281394
            ],
            [
              -100.559099,
              46.307994
            ],
            [
              -100.549199,
              46.329293
            ],
            [
              -100.551199,
              46.363494
            ],
            [
              -100.559399,
              46.381694
            ],
            [
              -100.590199,
              46.427894
            ],
            [
              -100.6065,
              46.419394
            ],
            [
              -100.626984,
              46.41901
            ],
            [
              -100.665897,
              46.406321
            ],
            [
              -100.686469,
              46.409737
            ],
            [
              -100.691922,
              46.397927
            ],
            [
              -100.709867,
              46.392829
            ],
            [
              -100.720504,
              46.379745
            ],
            [
              -100.724731,
              46.392551
            ],
            [
              -100.769534,
              46.397898
            ],
            [
              -100.77403,
              46.391707
            ],
            [
              -100.805562,
              46.389816
            ],
            [
              -100.814873,
              46.384701
            ],
            [
              -100.853699,
              46.395432
            ],
            [
              -100.857966,
              46.402995
            ],
            [
              -100.869612,
              46.389163
            ],
            [
              -100.883182,
              46.391041
            ],
            [
              -100.884552,
              46.409096
            ],
            [
              -100.895431,
              46.39596
            ],
            [
              -100.91895,
              46.394983
            ],
            [
              -100.929059,
              46.377821
            ],
            [
              -100.939727,
              46.374997
            ],
            [
              -100.941508,
              46.350018
            ],
            [
              -100.960813,
              46.347684
            ],
            [
              -100.957543,
              46.340051
            ],
            [
              -100.97951,
              46.329693
            ],
            [
              -100.995296,
              46.31554
            ],
            [
              -101.016701,
              46.320392
            ],
            [
              -101.015926,
              46.299801
            ],
            [
              -101.025662,
              46.284219
            ],
            [
              -101.044715,
              46.282144
            ],
            [
              -101.058492,
              46.270171
            ],
            [
              -101.087394,
              46.275333
            ],
            [
              -101.091339,
              46.258055
            ],
            [
              -101.116105,
              46.24459
            ],
            [
              -101.10012,
              46.2302
            ],
            [
              -101.143479,
              46.203347
            ],
            [
              -101.167899,
              46.202028
            ],
            [
              -101.170963,
              46.191814
            ],
            [
              -101.198073,
              46.18377
            ],
            [
              -101.189747,
              46.148693
            ],
            [
              -101.21197,
              46.156207
            ],
            [
              -101.228832,
              46.137831
            ],
            [
              -101.246981,
              46.14221
            ],
            [
              -101.267484,
              46.130656
            ],
            [
              -101.301698,
              46.133269
            ],
            [
              -101.311447,
              46.125003
            ],
            [
              -101.296777,
              46.118208
            ],
            [
              -101.299917,
              46.104844
            ],
            [
              -101.343514,
              46.089257
            ],
            [
              -101.337208,
              46.081484
            ],
            [
              -101.36235,
              46.070392
            ],
            [
              -101.386292,
              46.07942
            ],
            [
              -101.407114,
              46.070812
            ],
            [
              -101.414603,
              46.058382
            ],
            [
              -101.42802,
              46.060559
            ],
            [
              -101.458287,
              46.049996
            ],
            [
              -101.49012,
              46.050352
            ],
            [
              -101.497461,
              46.041802
            ],
            [
              -101.485669,
              46.028189
            ],
            [
              -101.496882,
              46.023568
            ],
            [
              -101.534334,
              46.030802
            ],
            [
              -101.527224,
              46.017182
            ],
            [
              -101.553299,
              46.00695
            ],
            [
              -101.573765,
              46.025138
            ],
            [
              -101.591927,
              46.009551
            ],
            [
              -101.610627,
              46.018662
            ],
            [
              -101.622717,
              46.037028
            ],
            [
              -101.637288,
              46.037538
            ],
            [
              -101.644193,
              46.016108
            ],
            [
              -101.660101,
              46.012046
            ],
            [
              -101.719658,
              46.023439
            ],
            [
              -101.737643,
              46.037606
            ],
            [
              -101.746328,
              46.028348
            ],
            [
              -101.755136,
              46.042426
            ],
            [
              -101.766431,
              46.030824
            ],
            [
              -101.779319,
              46.038985
            ],
            [
              -101.802253,
              46.027931
            ],
            [
              -101.825212,
              46.037084
            ],
            [
              -101.841552,
              46.028841
            ],
            [
              -101.849355,
              46.073275
            ],
            [
              -101.874587,
              46.063371
            ],
            [
              -101.894857,
              46.069254
            ],
            [
              -101.894144,
              46.056825
            ],
            [
              -101.937134,
              46.066358
            ],
            [
              -101.965154,
              46.049837
            ],
            [
              -101.991401,
              46.047016
            ],
            [
              -101.998575,
              46.053461
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "067",
      "COUNTYNS": "01034212",
      "AFFGEOID": "0500000US38067",
      "GEOID": "38067",
      "NAME": "Pembina",
      "LSAD": "06",
      "ALAND": 2897408189,
      "AWATER": 7010908,
      "County_state": "Pembina,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.95020501151559,
              49.0005151189384
            ],
            [
              -97.949577,
              48.717693
            ],
            [
              -97.927411,
              48.717653
            ],
            [
              -97.927154,
              48.543124
            ],
            [
              -97.666258,
              48.543188
            ],
            [
              -97.1612768424102,
              48.5425053390564
            ],
            [
              -97.163105,
              48.543855
            ],
            [
              -97.1630904767802,
              48.5439639241486
            ],
            [
              -97.162717,
              48.546765
            ],
            [
              -97.162099,
              48.548124
            ],
            [
              -97.160863,
              48.549236
            ],
            [
              -97.155791,
              48.551173
            ],
            [
              -97.153447,
              48.551214
            ],
            [
              -97.152459,
              48.552326
            ],
            [
              -97.152211,
              48.553933
            ],
            [
              -97.153942,
              48.556034
            ],
            [
              -97.156413,
              48.557146
            ],
            [
              -97.158267,
              48.558753
            ],
            [
              -97.158762,
              48.560112
            ],
            [
              -97.158638,
              48.564067
            ],
            [
              -97.157402,
              48.565921
            ],
            [
              -97.151638,
              48.56763
            ],
            [
              -97.149616,
              48.569876
            ],
            [
              -97.148998,
              48.571977
            ],
            [
              -97.148874,
              48.575067
            ],
            [
              -97.149616,
              48.576921
            ],
            [
              -97.14974,
              48.579516
            ],
            [
              -97.148429,
              48.581028
            ],
            [
              -97.144922,
              48.581452
            ],
            [
              -97.143654,
              48.582358
            ],
            [
              -97.142915,
              48.583733
            ],
            [
              -97.141585,
              48.59082
            ],
            [
              -97.142237,
              48.592595
            ],
            [
              -97.143931,
              48.594594
            ],
            [
              -97.143684,
              48.597066
            ],
            [
              -97.142818,
              48.598425
            ],
            [
              -97.140841,
              48.600032
            ],
            [
              -97.138246,
              48.604234
            ],
            [
              -97.13738,
              48.607324
            ],
            [
              -97.138246,
              48.609301
            ],
            [
              -97.137504,
              48.612268
            ],
            [
              -97.136145,
              48.613256
            ],
            [
              -97.132931,
              48.61338
            ],
            [
              -97.131448,
              48.613998
            ],
            [
              -97.130707,
              48.616593
            ],
            [
              -97.131325,
              48.619065
            ],
            [
              -97.130089,
              48.621166
            ],
            [
              -97.125639,
              48.620919
            ],
            [
              -97.124774,
              48.621537
            ],
            [
              -97.124033,
              48.623267
            ],
            [
              -97.124175,
              48.625387
            ],
            [
              -97.125887,
              48.626975
            ],
            [
              -97.125887,
              48.629076
            ],
            [
              -97.125269,
              48.629694
            ],
            [
              -97.120819,
              48.631053
            ],
            [
              -97.115043,
              48.629821
            ],
            [
              -97.111559,
              48.630266
            ],
            [
              -97.109515,
              48.631453
            ],
            [
              -97.108466,
              48.632658
            ],
            [
              -97.108276,
              48.634396
            ],
            [
              -97.109651,
              48.638888
            ],
            [
              -97.111921,
              48.642918
            ],
            [
              -97.111179,
              48.644525
            ],
            [
              -97.107814,
              48.647728
            ],
            [
              -97.10591,
              48.652632
            ],
            [
              -97.104566,
              48.654416
            ],
            [
              -97.10179,
              48.656294
            ],
            [
              -97.100551,
              48.658614
            ],
            [
              -97.100674,
              48.661951
            ],
            [
              -97.102652,
              48.664793
            ],
            [
              -97.101539,
              48.666771
            ],
            [
              -97.100009,
              48.667926
            ],
            [
              -97.099811,
              48.671377
            ],
            [
              -97.100674,
              48.679624
            ],
            [
              -97.100056,
              48.681355
            ],
            [
              -97.097708,
              48.68395
            ],
            [
              -97.097337,
              48.685186
            ],
            [
              -97.097584,
              48.686298
            ],
            [
              -97.098697,
              48.687534
            ],
            [
              -97.108655,
              48.691484
            ],
            [
              -97.118286,
              48.700573
            ],
            [
              -97.119027,
              48.703292
            ],
            [
              -97.116926,
              48.705022
            ],
            [
              -97.116185,
              48.709348
            ],
            [
              -97.121253,
              48.713593
            ],
            [
              -97.124328,
              48.719166
            ],
            [
              -97.126398,
              48.721101
            ],
            [
              -97.134229,
              48.725167
            ],
            [
              -97.135588,
              48.726403
            ],
            [
              -97.136083,
              48.727763
            ],
            [
              -97.135094,
              48.72974
            ],
            [
              -97.134847,
              48.733324
            ],
            [
              -97.135341,
              48.73456
            ],
            [
              -97.138996,
              48.736654
            ],
            [
              -97.139611,
              48.738129
            ],
            [
              -97.139488,
              48.746611
            ],
            [
              -97.143176,
              48.750913
            ],
            [
              -97.15006,
              48.754724
            ],
            [
              -97.151043,
              48.755707
            ],
            [
              -97.151289,
              48.757428
            ],
            [
              -97.147478,
              48.763698
            ],
            [
              -97.147478,
              48.766033
            ],
            [
              -97.152588,
              48.772602
            ],
            [
              -97.153871,
              48.773286
            ],
            [
              -97.154854,
              48.774515
            ],
            [
              -97.155223,
              48.775499
            ],
            [
              -97.154854,
              48.776728
            ],
            [
              -97.153871,
              48.777712
            ],
            [
              -97.153256,
              48.781031
            ],
            [
              -97.154116,
              48.781891
            ],
            [
              -97.157067,
              48.78312
            ],
            [
              -97.157804,
              48.784104
            ],
            [
              -97.157797,
              48.78768
            ],
            [
              -97.157093,
              48.790024
            ],
            [
              -97.158102,
              48.791145
            ],
            [
              -97.161231,
              48.791778
            ],
            [
              -97.162959,
              48.79293
            ],
            [
              -97.163535,
              48.79507
            ],
            [
              -97.163699,
              48.799513
            ],
            [
              -97.165921,
              48.803792
            ],
            [
              -97.165921,
              48.805273
            ],
            [
              -97.164874,
              48.807129
            ],
            [
              -97.164874,
              48.808253
            ],
            [
              -97.165624,
              48.809627
            ],
            [
              -97.168497,
              48.811002
            ],
            [
              -97.174045,
              48.812108
            ],
            [
              -97.177045,
              48.814124
            ],
            [
              -97.178611,
              48.815839
            ],
            [
              -97.180028,
              48.81845
            ],
            [
              -97.177747,
              48.824815
            ],
            [
              -97.180991,
              48.828992
            ],
            [
              -97.181116,
              48.832741
            ],
            [
              -97.180366,
              48.834365
            ],
            [
              -97.175727,
              48.836158
            ],
            [
              -97.174275,
              48.837261
            ],
            [
              -97.173811,
              48.838309
            ],
            [
              -97.174355,
              48.842619
            ],
            [
              -97.177243,
              48.846483
            ],
            [
              -97.176993,
              48.847733
            ],
            [
              -97.175618,
              48.849857
            ],
            [
              -97.175618,
              48.853105
            ],
            [
              -97.179071,
              48.856866
            ],
            [
              -97.180116,
              48.861601
            ],
            [
              -97.182365,
              48.863725
            ],
            [
              -97.185488,
              48.864849
            ],
            [
              -97.187113,
              48.866098
            ],
            [
              -97.187362,
              48.867598
            ],
            [
              -97.185738,
              48.87222
            ],
            [
              -97.186238,
              48.87347
            ],
            [
              -97.187737,
              48.874594
            ],
            [
              -97.190486,
              48.875594
            ],
            [
              -97.197982,
              48.880341
            ],
            [
              -97.198857,
              48.882215
            ],
            [
              -97.197982,
              48.884839
            ],
            [
              -97.197857,
              48.886838
            ],
            [
              -97.199981,
              48.891086
            ],
            [
              -97.198107,
              48.893959
            ],
            [
              -97.197982,
              48.898332
            ],
            [
              -97.198857,
              48.899831
            ],
            [
              -97.207688,
              48.902629
            ],
            [
              -97.210541,
              48.90439
            ],
            [
              -97.212706,
              48.908143
            ],
            [
              -97.212553,
              48.90986
            ],
            [
              -97.210809,
              48.91395
            ],
            [
              -97.211161,
              48.916649
            ],
            [
              -97.212926,
              48.918033
            ],
            [
              -97.217992,
              48.919735
            ],
            [
              -97.219095,
              48.922078
            ],
            [
              -97.219185,
              48.92486
            ],
            [
              -97.217463,
              48.927659
            ],
            [
              -97.217549,
              48.929892
            ],
            [
              -97.218666,
              48.931781
            ],
            [
              -97.224505,
              48.9341
            ],
            [
              -97.226394,
              48.938651
            ],
            [
              -97.226823,
              48.943545
            ],
            [
              -97.227854,
              48.945864
            ],
            [
              -97.232147,
              48.948955
            ],
            [
              -97.232319,
              48.950501
            ],
            [
              -97.230859,
              48.958229
            ],
            [
              -97.230859,
              48.960891
            ],
            [
              -97.23146,
              48.962437
            ],
            [
              -97.232491,
              48.963897
            ],
            [
              -97.237541,
              48.965341
            ],
            [
              -97.238882,
              48.966573
            ],
            [
              -97.239209,
              48.968684
            ],
            [
              -97.238025,
              48.975143
            ],
            [
              -97.238387,
              48.982631
            ],
            [
              -97.237297,
              48.985696
            ],
            [
              -97.234214,
              48.988966
            ],
            [
              -97.230833,
              48.991303
            ],
            [
              -97.230403,
              48.993366
            ],
            [
              -97.23149,
              48.995995
            ],
            [
              -97.231397,
              48.997212
            ],
            [
              -97.229039,
              49.000687
            ],
            [
              -97.411216,
              49.00051
            ],
            [
              -97.77575,
              49.000574
            ],
            [
              -97.95020501151559,
              49.0005151189384
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "097",
      "COUNTYNS": "01034221",
      "AFFGEOID": "0500000US38097",
      "GEOID": "38097",
      "NAME": "Traill",
      "LSAD": "06",
      "ALAND": 2232438250,
      "AWATER": 1462184,
      "County_state": "Traill,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.472745,
              47.672051
            ],
            [
              -97.474337,
              47.324776
            ],
            [
              -97.451868,
              47.324468
            ],
            [
              -97.451512,
              47.239061
            ],
            [
              -96.83358911211658,
              47.2380365686549
            ],
            [
              -96.83766,
              47.240876
            ],
            [
              -96.838233,
              47.241831
            ],
            [
              -96.838233,
              47.242882
            ],
            [
              -96.837278,
              47.244219
            ],
            [
              -96.83489,
              47.246416
            ],
            [
              -96.834699,
              47.248135
            ],
            [
              -96.835368,
              47.250428
            ],
            [
              -96.840143,
              47.253102
            ],
            [
              -96.840525,
              47.253866
            ],
            [
              -96.839857,
              47.25549
            ],
            [
              -96.840048,
              47.256159
            ],
            [
              -96.841672,
              47.258164
            ],
            [
              -96.841003,
              47.259215
            ],
            [
              -96.840717,
              47.261221
            ],
            [
              -96.84129,
              47.262463
            ],
            [
              -96.842531,
              47.262845
            ],
            [
              -96.842627,
              47.263991
            ],
            [
              -96.842054,
              47.265328
            ],
            [
              -96.839857,
              47.265997
            ],
            [
              -96.838997,
              47.267716
            ],
            [
              -96.839761,
              47.268767
            ],
            [
              -96.842531,
              47.269531
            ],
            [
              -96.8432,
              47.270486
            ],
            [
              -96.842245,
              47.273351
            ],
            [
              -96.840353,
              47.275496
            ],
            [
              -96.84022,
              47.276981
            ],
            [
              -96.841465,
              47.284041
            ],
            [
              -96.844088,
              47.289981
            ],
            [
              -96.843922,
              47.29302
            ],
            [
              -96.832884,
              47.30449
            ],
            [
              -96.832884,
              47.307069
            ],
            [
              -96.835735,
              47.310843
            ],
            [
              -96.837045,
              47.311391
            ],
            [
              -96.841003,
              47.311558
            ],
            [
              -96.842531,
              47.312418
            ],
            [
              -96.841958,
              47.316907
            ],
            [
              -96.841194,
              47.317575
            ],
            [
              -96.836991,
              47.318817
            ],
            [
              -96.836036,
              47.320059
            ],
            [
              -96.835845,
              47.321014
            ],
            [
              -96.836609,
              47.323975
            ],
            [
              -96.835177,
              47.326267
            ],
            [
              -96.835177,
              47.32856
            ],
            [
              -96.836036,
              47.329706
            ],
            [
              -96.838329,
              47.331043
            ],
            [
              -96.83852,
              47.33238
            ],
            [
              -96.835845,
              47.335914
            ],
            [
              -96.836609,
              47.338684
            ],
            [
              -96.840586,
              47.340956
            ],
            [
              -96.844012,
              47.346182
            ],
            [
              -96.845158,
              47.34943
            ],
            [
              -96.843439,
              47.354397
            ],
            [
              -96.844298,
              47.356021
            ],
            [
              -96.846877,
              47.356785
            ],
            [
              -96.848119,
              47.358026
            ],
            [
              -96.849265,
              47.359841
            ],
            [
              -96.849456,
              47.363662
            ],
            [
              -96.852417,
              47.366241
            ],
            [
              -96.852226,
              47.367291
            ],
            [
              -96.849552,
              47.368247
            ],
            [
              -96.848597,
              47.369584
            ],
            [
              -96.848907,
              47.370565
            ],
            [
              -96.852035,
              47.371876
            ],
            [
              -96.853754,
              47.373405
            ],
            [
              -96.852676,
              47.374973
            ],
            [
              -96.848931,
              47.375363
            ],
            [
              -96.846925,
              47.376891
            ],
            [
              -96.845588,
              47.381571
            ],
            [
              -96.841099,
              47.38415
            ],
            [
              -96.840621,
              47.389881
            ],
            [
              -96.840717,
              47.391314
            ],
            [
              -96.841767,
              47.39246
            ],
            [
              -96.845492,
              47.394179
            ],
            [
              -96.845874,
              47.396185
            ],
            [
              -96.844919,
              47.399815
            ],
            [
              -96.84511,
              47.400483
            ],
            [
              -96.848071,
              47.403158
            ],
            [
              -96.852739,
              47.405909
            ],
            [
              -96.852656,
              47.407647
            ],
            [
              -96.853325,
              47.408889
            ],
            [
              -96.858094,
              47.410317
            ],
            [
              -96.861833,
              47.414337
            ],
            [
              -96.86207,
              47.415159
            ],
            [
              -96.861095,
              47.417056
            ],
            [
              -96.861231,
              47.41781
            ],
            [
              -96.863593,
              47.418775
            ],
            [
              -96.864261,
              47.419539
            ],
            [
              -96.864261,
              47.420972
            ],
            [
              -96.862924,
              47.422309
            ],
            [
              -96.859581,
              47.424028
            ],
            [
              -96.858721,
              47.426129
            ],
            [
              -96.860632,
              47.427658
            ],
            [
              -96.861014,
              47.428995
            ],
            [
              -96.860823,
              47.430237
            ],
            [
              -96.85853,
              47.433389
            ],
            [
              -96.860059,
              47.435681
            ],
            [
              -96.859772,
              47.437209
            ],
            [
              -96.85748,
              47.440457
            ],
            [
              -96.85748,
              47.441603
            ],
            [
              -96.859537,
              47.445662
            ],
            [
              -96.859239,
              47.451557
            ],
            [
              -96.858244,
              47.453351
            ],
            [
              -96.858148,
              47.454498
            ],
            [
              -96.859677,
              47.456026
            ],
            [
              -96.859963,
              47.457363
            ],
            [
              -96.859581,
              47.4587
            ],
            [
              -96.85748,
              47.460229
            ],
            [
              -96.856811,
              47.46319
            ],
            [
              -96.859555,
              47.466865
            ],
            [
              -96.859868,
              47.470926
            ],
            [
              -96.859103,
              47.472837
            ],
            [
              -96.855856,
              47.475702
            ],
            [
              -96.85471,
              47.478281
            ],
            [
              -96.854996,
              47.479618
            ],
            [
              -96.858148,
              47.481624
            ],
            [
              -96.85853,
              47.482484
            ],
            [
              -96.85853,
              47.483917
            ],
            [
              -96.857957,
              47.484681
            ],
            [
              -96.856142,
              47.48554
            ],
            [
              -96.855665,
              47.48726
            ],
            [
              -96.855856,
              47.48831
            ],
            [
              -96.85853,
              47.489934
            ],
            [
              -96.85853,
              47.490889
            ],
            [
              -96.857957,
              47.492513
            ],
            [
              -96.857002,
              47.493468
            ],
            [
              -96.851653,
              47.497098
            ],
            [
              -96.8517908344828,
              47.498752013793094
            ],
            [
              -96.851844,
              47.49939
            ],
            [
              -96.853317,
              47.501322
            ],
            [
              -96.853286,
              47.503881
            ],
            [
              -96.853052,
              47.506828
            ],
            [
              -96.851749,
              47.507891
            ],
            [
              -96.851367,
              47.509037
            ],
            [
              -96.851749,
              47.510088
            ],
            [
              -96.853181,
              47.511425
            ],
            [
              -96.853468,
              47.513813
            ],
            [
              -96.854204,
              47.514368
            ],
            [
              -96.858454,
              47.514892
            ],
            [
              -96.861422,
              47.515873
            ],
            [
              -96.863245,
              47.517266
            ],
            [
              -96.863551,
              47.520304
            ],
            [
              -96.866363,
              47.524893
            ],
            [
              -96.866363,
              47.525944
            ],
            [
              -96.864739,
              47.527663
            ],
            [
              -96.862379,
              47.529055
            ],
            [
              -96.860524,
              47.529536
            ],
            [
              -96.85471,
              47.535973
            ],
            [
              -96.855092,
              47.53731
            ],
            [
              -96.856429,
              47.538456
            ],
            [
              -96.856716,
              47.540271
            ],
            [
              -96.854614,
              47.54285
            ],
            [
              -96.854232,
              47.544665
            ],
            [
              -96.854423,
              47.545333
            ],
            [
              -96.856429,
              47.546957
            ],
            [
              -96.85662,
              47.548103
            ],
            [
              -96.856238,
              47.548963
            ],
            [
              -96.854328,
              47.550491
            ],
            [
              -96.853755,
              47.552497
            ],
            [
              -96.855092,
              47.554598
            ],
            [
              -96.858002,
              47.556578
            ],
            [
              -96.859057,
              47.558591
            ],
            [
              -96.859153,
              47.559741
            ],
            [
              -96.857427,
              47.561658
            ],
            [
              -96.856852,
              47.563288
            ],
            [
              -96.857236,
              47.564055
            ],
            [
              -96.858673,
              47.564534
            ],
            [
              -96.859153,
              47.566355
            ],
            [
              -96.858769,
              47.56741
            ],
            [
              -96.856661,
              47.567889
            ],
            [
              -96.853689,
              47.570381
            ],
            [
              -96.854073,
              47.57201
            ],
            [
              -96.855894,
              47.573352
            ],
            [
              -96.856373,
              47.575749
            ],
            [
              -96.853273,
              47.579483
            ],
            [
              -96.851293,
              47.589264
            ],
            [
              -96.851964,
              47.591469
            ],
            [
              -96.854743,
              47.594728
            ],
            [
              -96.854456,
              47.596261
            ],
            [
              -96.853114,
              47.596836
            ],
            [
              -96.852826,
              47.597891
            ],
            [
              -96.853785,
              47.599808
            ],
            [
              -96.855618,
              47.60089
            ],
            [
              -96.856903,
              47.602329
            ],
            [
              -96.854812,
              47.606328
            ],
            [
              -96.855421,
              47.60875
            ],
            [
              -96.857112,
              47.61076
            ],
            [
              -96.860255,
              47.612175
            ],
            [
              -96.873671,
              47.613654
            ],
            [
              -96.874078,
              47.614774
            ],
            [
              -96.871005,
              47.616832
            ],
            [
              -96.8706,
              47.617563
            ],
            [
              -96.870871,
              47.618042
            ],
            [
              -96.876355,
              47.619181
            ],
            [
              -96.879496,
              47.620576
            ],
            [
              -96.882393,
              47.633489
            ],
            [
              -96.888573,
              47.63845
            ],
            [
              -96.888166,
              47.63973
            ],
            [
              -96.884515,
              47.640755
            ],
            [
              -96.882857,
              47.641714
            ],
            [
              -96.882376,
              47.649025
            ],
            [
              -96.882882,
              47.650168
            ],
            [
              -96.88697,
              47.653049
            ],
            [
              -96.887607,
              47.658853
            ],
            [
              -96.88571,
              47.661547
            ],
            [
              -96.885573,
              47.663443
            ],
            [
              -96.887126,
              47.666369
            ],
            [
              -96.889627,
              47.668587
            ],
            [
              -96.889726,
              47.670643
            ],
            [
              -96.8910416719539,
              47.672149192756
            ],
            [
              -97.472745,
              47.672051
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "071",
      "COUNTYNS": "01034215",
      "AFFGEOID": "0500000US38071",
      "GEOID": "38071",
      "NAME": "Ramsey",
      "LSAD": "06",
      "ALAND": 3074174337,
      "AWATER": 295182394,
      "County_state": "Ramsey,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.200306,
              48.370658
            ],
            [
              -99.199911,
              48.155309
            ],
            [
              -99.139356,
              48.143675
            ],
            [
              -99.109312,
              48.107663
            ],
            [
              -99.070443,
              48.107857
            ],
            [
              -99.070351,
              48.021955
            ],
            [
              -99.060211,
              48.021977
            ],
            [
              -99.036844,
              48.047403
            ],
            [
              -99.001038,
              48.053294
            ],
            [
              -99.004288,
              48.041931
            ],
            [
              -98.988601,
              48.012998
            ],
            [
              -98.991253,
              47.99026
            ],
            [
              -98.944798,
              48.002799
            ],
            [
              -98.928858,
              47.996983
            ],
            [
              -98.917497,
              48.004099
            ],
            [
              -98.919997,
              48.017999
            ],
            [
              -98.897697,
              48.027099
            ],
            [
              -98.888896,
              48.009499
            ],
            [
              -98.883396,
              48.024499
            ],
            [
              -98.835394,
              48.049
            ],
            [
              -98.825094,
              48.0624
            ],
            [
              -98.803893,
              48.059901
            ],
            [
              -98.785391,
              48.0299
            ],
            [
              -98.78319,
              48.0016
            ],
            [
              -98.769754,
              47.998689
            ],
            [
              -98.76669,
              48.0294
            ],
            [
              -98.763589,
              48.001
            ],
            [
              -98.75061,
              47.982456
            ],
            [
              -98.778622,
              47.973478
            ],
            [
              -98.794312,
              47.951314
            ],
            [
              -98.763029,
              47.971689
            ],
            [
              -98.734987,
              47.981
            ],
            [
              -98.723587,
              47.9976
            ],
            [
              -98.694816,
              48.003777
            ],
            [
              -98.672086,
              48.0004
            ],
            [
              -98.663485,
              47.9643
            ],
            [
              -98.625684,
              47.936799
            ],
            [
              -98.635284,
              47.930999
            ],
            [
              -98.625384,
              47.916399
            ],
            [
              -98.525555,
              47.915056
            ],
            [
              -98.525609,
              48.02115
            ],
            [
              -98.422659,
              48.021115
            ],
            [
              -98.4221,
              48.194824
            ],
            [
              -98.292184,
              48.195177
            ],
            [
              -98.292112,
              48.369695
            ],
            [
              -98.31867,
              48.369722
            ],
            [
              -98.318098,
              48.543773
            ],
            [
              -98.970392,
              48.544232
            ],
            [
              -98.970975,
              48.370472
            ],
            [
              -99.200306,
              48.370658
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "035",
      "COUNTYNS": "01034218",
      "AFFGEOID": "0500000US38035",
      "GEOID": "38035",
      "NAME": "Grand Forks",
      "LSAD": "06",
      "ALAND": 3720138079,
      "AWATER": 8156569,
      "County_state": "Grand Forks,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -97.903434,
              48.194897
            ],
            [
              -97.903599,
              48.020897
            ],
            [
              -97.882179,
              48.020803
            ],
            [
              -97.882405,
              47.672179
            ],
            [
              -97.496356,
              47.672059
            ],
            [
              -97.472745,
              47.672051
            ],
            [
              -96.8910416719539,
              47.672149192756
            ],
            [
              -96.891922,
              47.673157
            ],
            [
              -96.895271,
              47.67357
            ],
            [
              -96.896724,
              47.674758
            ],
            [
              -96.899352,
              47.689473
            ],
            [
              -96.900264,
              47.690775
            ],
            [
              -96.901719,
              47.691621
            ],
            [
              -96.902971,
              47.691576
            ],
            [
              -96.905273,
              47.689246
            ],
            [
              -96.907236,
              47.688493
            ],
            [
              -96.908928,
              47.688722
            ],
            [
              -96.909909,
              47.689522
            ],
            [
              -96.910144,
              47.691235
            ],
            [
              -96.907266,
              47.693976
            ],
            [
              -96.907604,
              47.695119
            ],
            [
              -96.909769,
              47.697313
            ],
            [
              -96.911527,
              47.700512
            ],
            [
              -96.912846,
              47.701746
            ],
            [
              -96.913762,
              47.701468
            ],
            [
              -96.915242,
              47.702369
            ],
            [
              -96.915242,
              47.703527
            ],
            [
              -96.914405,
              47.704814
            ],
            [
              -96.914856,
              47.707003
            ],
            [
              -96.9155,
              47.707968
            ],
            [
              -96.920119,
              47.710383
            ],
            [
              -96.920321,
              47.712394
            ],
            [
              -96.919811,
              47.714339
            ],
            [
              -96.920391,
              47.716527
            ],
            [
              -96.923544,
              47.718201
            ],
            [
              -96.92348,
              47.719809
            ],
            [
              -96.919471,
              47.722515
            ],
            [
              -96.918556,
              47.723863
            ],
            [
              -96.919131,
              47.724731
            ],
            [
              -96.925089,
              47.729051
            ],
            [
              -96.930574,
              47.734352
            ],
            [
              -96.932809,
              47.737139
            ],
            [
              -96.933316,
              47.738716
            ],
            [
              -96.933011,
              47.739949
            ],
            [
              -96.929319,
              47.742988
            ],
            [
              -96.928506,
              47.744884
            ],
            [
              -96.928505,
              47.748037
            ],
            [
              -96.929051,
              47.750331
            ],
            [
              -96.934173,
              47.752412
            ],
            [
              -96.934463,
              47.752956
            ],
            [
              -96.934209,
              47.754517
            ],
            [
              -96.932648,
              47.755315
            ],
            [
              -96.932684,
              47.756804
            ],
            [
              -96.935555,
              47.758276
            ],
            [
              -96.937859,
              47.760195
            ],
            [
              -96.938435,
              47.762411
            ],
            [
              -96.936909,
              47.764536
            ],
            [
              -96.939179,
              47.768397
            ],
            [
              -96.949585,
              47.775228
            ],
            [
              -96.956635,
              47.776188
            ],
            [
              -96.956501,
              47.779798
            ],
            [
              -96.961926,
              47.783292
            ],
            [
              -96.9644,
              47.782995
            ],
            [
              -96.965316,
              47.783474
            ],
            [
              -96.96535,
              47.784937
            ],
            [
              -96.963521,
              47.78729
            ],
            [
              -96.961554,
              47.788707
            ],
            [
              -96.957283,
              47.790147
            ],
            [
              -96.957216,
              47.79097
            ],
            [
              -96.95786,
              47.792021
            ],
            [
              -96.963523,
              47.794601
            ],
            [
              -96.966068,
              47.797297
            ],
            [
              -96.971698,
              47.798255
            ],
            [
              -96.973585,
              47.797884
            ],
            [
              -96.975131,
              47.798326
            ],
            [
              -96.976088,
              47.799577
            ],
            [
              -96.976176,
              47.801544
            ],
            [
              -96.980579,
              47.805614
            ],
            [
              -96.981168,
              47.806792
            ],
            [
              -96.980947,
              47.808337
            ],
            [
              -96.978894,
              47.809882
            ],
            [
              -96.977946,
              47.811619
            ],
            [
              -96.980391,
              47.815662
            ],
            [
              -96.980726,
              47.820411
            ],
            [
              -96.980137,
              47.821441
            ],
            [
              -96.979327,
              47.821809
            ],
            [
              -96.979327,
              47.824533
            ],
            [
              -96.981683,
              47.825785
            ],
            [
              -96.982272,
              47.826668
            ],
            [
              -96.981725,
              47.830421
            ],
            [
              -96.986685,
              47.837639
            ],
            [
              -96.992963,
              47.837911
            ],
            [
              -96.998295,
              47.841724
            ],
            [
              -96.99789,
              47.843163
            ],
            [
              -96.996364,
              47.844398
            ],
            [
              -96.99643528050339,
              47.8459761061896
            ],
            [
              -96.996816,
              47.854405
            ],
            [
              -96.998144,
              47.858882
            ],
            [
              -97.000356,
              47.860915
            ],
            [
              -97.001759,
              47.861266
            ],
            [
              -97.005557,
              47.863977
            ],
            [
              -97.005857,
              47.865277
            ],
            [
              -97.003356,
              47.865877
            ],
            [
              -97.001556,
              47.867377
            ],
            [
              -97.002456,
              47.868677
            ],
            [
              -97.005356,
              47.870177
            ],
            [
              -97.017356,
              47.871578
            ],
            [
              -97.021256,
              47.872578
            ],
            [
              -97.023156,
              47.873978
            ],
            [
              -97.023156,
              47.874978
            ],
            [
              -97.018955,
              47.876878
            ],
            [
              -97.017955,
              47.878478
            ],
            [
              -97.019355,
              47.880278
            ],
            [
              -97.023355,
              47.882078
            ],
            [
              -97.025355,
              47.884278
            ],
            [
              -97.024955,
              47.886878
            ],
            [
              -97.019155,
              47.889778
            ],
            [
              -97.018955,
              47.891078
            ],
            [
              -97.024955,
              47.894978
            ],
            [
              -97.023955,
              47.898078
            ],
            [
              -97.020155,
              47.900478
            ],
            [
              -97.020255,
              47.902178
            ],
            [
              -97.024155,
              47.905278
            ],
            [
              -97.024955,
              47.908178
            ],
            [
              -97.023555,
              47.908478
            ],
            [
              -97.020355,
              47.906378
            ],
            [
              -97.017254,
              47.905678
            ],
            [
              -97.015054,
              47.907178
            ],
            [
              -97.015354,
              47.910278
            ],
            [
              -97.017254,
              47.913078
            ],
            [
              -97.02133187083248,
              47.9148346212817
            ],
            [
              -97.023754,
              47.915878
            ],
            [
              -97.018054,
              47.918078
            ],
            [
              -97.017754,
              47.919778
            ],
            [
              -97.029654,
              47.927578
            ],
            [
              -97.035754,
              47.930179
            ],
            [
              -97.037354,
              47.933279
            ],
            [
              -97.035554,
              47.936579
            ],
            [
              -97.036054,
              47.939379
            ],
            [
              -97.039154,
              47.940479
            ],
            [
              -97.044954,
              47.941079
            ],
            [
              -97.051054,
              47.943379
            ],
            [
              -97.054554,
              47.946279
            ],
            [
              -97.055554,
              47.949079
            ],
            [
              -97.055154,
              47.950779
            ],
            [
              -97.052554,
              47.954779
            ],
            [
              -97.052454,
              47.957179
            ],
            [
              -97.054054,
              47.959679
            ],
            [
              -97.059054,
              47.96208
            ],
            [
              -97.061454,
              47.96358
            ],
            [
              -97.061854,
              47.96448
            ],
            [
              -97.061554,
              47.96588
            ],
            [
              -97.057854,
              47.96898
            ],
            [
              -97.057153,
              47.97048
            ],
            [
              -97.059353,
              47.97398
            ],
            [
              -97.059153,
              47.97538
            ],
            [
              -97.056481,
              47.980556
            ],
            [
              -97.053537,
              47.987948
            ],
            [
              -97.053089,
              47.990252
            ],
            [
              -97.053553,
              47.991612
            ],
            [
              -97.054945,
              47.992924
            ],
            [
              -97.062257,
              47.995948
            ],
            [
              -97.064289,
              47.998508
            ],
            [
              -97.066762,
              48.009558
            ],
            [
              -97.065411,
              48.011337
            ],
            [
              -97.063012,
              48.013179
            ],
            [
              -97.063289,
              48.014989
            ],
            [
              -97.064927,
              48.015658
            ],
            [
              -97.069284,
              48.016176
            ],
            [
              -97.070654,
              48.016918
            ],
            [
              -97.072239,
              48.019107
            ],
            [
              -97.0721836195239,
              48.0194933125891
            ],
            [
              -97.071911,
              48.021395
            ],
            [
              -97.068987,
              48.026267
            ],
            [
              -97.068711,
              48.027694
            ],
            [
              -97.070411,
              48.041765
            ],
            [
              -97.072257,
              48.048068
            ],
            [
              -97.074015,
              48.051212
            ],
            [
              -97.075641,
              48.052725
            ],
            [
              -97.082895,
              48.055794
            ],
            [
              -97.086986,
              48.058222
            ],
            [
              -97.097772,
              48.07108
            ],
            [
              -97.103052,
              48.071669
            ],
            [
              -97.104483,
              48.072428
            ],
            [
              -97.104697,
              48.073094
            ],
            [
              -97.104154,
              48.074578
            ],
            [
              -97.100771,
              48.077452
            ],
            [
              -97.099431,
              48.082106
            ],
            [
              -97.099798,
              48.085884
            ],
            [
              -97.102165,
              48.089122
            ],
            [
              -97.105226,
              48.09044
            ],
            [
              -97.105616,
              48.091362
            ],
            [
              -97.105475,
              48.09278
            ],
            [
              -97.103879,
              48.094517
            ],
            [
              -97.10395,
              48.096184
            ],
            [
              -97.104872,
              48.097851
            ],
            [
              -97.108428,
              48.099824
            ],
            [
              -97.109535,
              48.104723
            ],
            [
              -97.11147,
              48.105913
            ],
            [
              -97.113194,
              48.106188
            ],
            [
              -97.119773,
              48.105381
            ],
            [
              -97.123205,
              48.106648
            ],
            [
              -97.123666,
              48.108004
            ],
            [
              -97.123135,
              48.109497
            ],
            [
              -97.12104,
              48.112281
            ],
            [
              -97.120592,
              48.113365
            ],
            [
              -97.120702,
              48.114987
            ],
            [
              -97.121586,
              48.116925
            ],
            [
              -97.126862,
              48.124277
            ],
            [
              -97.128279,
              48.127185
            ],
            [
              -97.129453,
              48.133133
            ],
            [
              -97.132176,
              48.135829
            ],
            [
              -97.13252,
              48.137641
            ],
            [
              -97.131956,
              48.139563
            ],
            [
              -97.134299,
              48.141833
            ],
            [
              -97.141401,
              48.14359
            ],
            [
              -97.142133,
              48.144981
            ],
            [
              -97.142279,
              48.148056
            ],
            [
              -97.140295,
              48.150894
            ],
            [
              -97.139497,
              48.153108
            ],
            [
              -97.138911,
              48.155304
            ],
            [
              -97.138911,
              48.157793
            ],
            [
              -97.139643,
              48.159111
            ],
            [
              -97.14195,
              48.160202
            ],
            [
              -97.144242,
              48.16249
            ],
            [
              -97.146745,
              48.168556
            ],
            [
              -97.146672,
              48.171484
            ],
            [
              -97.1457019134364,
              48.1732232314736
            ],
            [
              -97.145243,
              48.174046
            ],
            [
              -97.142352,
              48.176609
            ],
            [
              -97.14162,
              48.177781
            ],
            [
              -97.141474,
              48.179099
            ],
            [
              -97.14184,
              48.181734
            ],
            [
              -97.142938,
              48.182686
            ],
            [
              -97.144622,
              48.183199
            ],
            [
              -97.146013,
              48.18459
            ],
            [
              -97.146233,
              48.186054
            ],
            [
              -97.141518,
              48.192518
            ],
            [
              -97.141233,
              48.193602
            ],
            [
              -97.87247,
              48.19493
            ],
            [
              -97.878454,
              48.195102
            ],
            [
              -97.903434,
              48.194897
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "061",
      "COUNTYNS": "01035307",
      "AFFGEOID": "0500000US38061",
      "GEOID": "38061",
      "NAME": "Mountrail",
      "LSAD": "06",
      "ALAND": 4727469721,
      "AWATER": 301107657,
      "County_state": "Mountrail,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.885763,
              48.54639
            ],
            [
              -102.885737,
              48.372578
            ],
            [
              -102.828061,
              48.37292
            ],
            [
              -102.828194,
              48.284138
            ],
            [
              -102.828477,
              48.123664
            ],
            [
              -102.79479,
              48.11913
            ],
            [
              -102.762314,
              48.129962
            ],
            [
              -102.73756,
              48.116608
            ],
            [
              -102.741635,
              48.10076
            ],
            [
              -102.758333,
              48.095092
            ],
            [
              -102.733162,
              48.088318
            ],
            [
              -102.703661,
              48.087918
            ],
            [
              -102.663487,
              48.076651
            ],
            [
              -102.668041,
              48.050859
            ],
            [
              -102.654684,
              48.039252
            ],
            [
              -102.639766,
              48.03712
            ],
            [
              -102.60836,
              48.046351
            ],
            [
              -102.599405,
              48.037246
            ],
            [
              -102.593862,
              48.011619
            ],
            [
              -102.552981,
              47.998646
            ],
            [
              -102.565902,
              47.969028
            ],
            [
              -102.610462,
              47.939116
            ],
            [
              -102.647864,
              47.901615
            ],
            [
              -102.656165,
              47.860814
            ],
            [
              -102.642266,
              47.823713
            ],
            [
              -102.624466,
              47.806413
            ],
            [
              -102.561965,
              47.780111
            ],
            [
              -102.499063,
              47.78001
            ],
            [
              -102.472962,
              47.76491
            ],
            [
              -102.415359,
              47.752009
            ],
            [
              -102.385758,
              47.758008
            ],
            [
              -102.385565,
              47.84775
            ],
            [
              -101.871862,
              47.847589
            ],
            [
              -101.871537,
              48.023176
            ],
            [
              -101.922123,
              48.023173
            ],
            [
              -101.922237,
              48.37233
            ],
            [
              -101.973399,
              48.372095
            ],
            [
              -101.973322,
              48.547133
            ],
            [
              -102.233892,
              48.546339
            ],
            [
              -102.36341,
              48.54642
            ],
            [
              -102.885763,
              48.54639
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "023",
      "COUNTYNS": "01034234",
      "AFFGEOID": "0500000US38023",
      "GEOID": "38023",
      "NAME": "Divide",
      "LSAD": "06",
      "ALAND": 3265315042,
      "AWATER": 86629398,
      "County_state": "Divide,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.048652,
              48.865734
            ],
            [
              -104.0489,
              48.847387
            ],
            [
              -104.048569,
              48.797052
            ],
            [
              -104.048537,
              48.788552
            ],
            [
              -104.048233,
              48.765636
            ],
            [
              -104.048548,
              48.751356
            ],
            [
              -104.04834,
              48.747133
            ],
            [
              -104.047883,
              48.664191
            ],
            [
              -104.047849,
              48.663163
            ],
            [
              -104.047861,
              48.658856
            ],
            [
              -104.047865,
              48.65745
            ],
            [
              -104.047887,
              48.649911
            ],
            [
              -104.047819,
              48.648631
            ],
            [
              -104.047582,
              48.633984
            ],
            [
              -104.047582044105,
              48.6339759112863
            ],
            [
              -103.79882,
              48.633503
            ],
            [
              -102.907631,
              48.633083
            ],
            [
              -102.894855,
              48.633432
            ],
            [
              -102.885775,
              48.633298
            ],
            [
              -102.885686,
              48.720488
            ],
            [
              -102.939932,
              48.720469
            ],
            [
              -102.93877696099301,
              48.9994577570233
            ],
            [
              -103.355491,
              48.999584
            ],
            [
              -103.375467,
              48.998951
            ],
            [
              -103.852287,
              48.999561
            ],
            [
              -103.856072,
              48.999572
            ],
            [
              -103.858363,
              48.999606
            ],
            [
              -103.862738,
              48.999639
            ],
            [
              -103.865336,
              48.999591
            ],
            [
              -103.876905,
              48.999544
            ],
            [
              -103.917428,
              48.999585
            ],
            [
              -103.918921,
              48.999551
            ],
            [
              -103.921976,
              48.999551
            ],
            [
              -103.923261,
              48.999562
            ],
            [
              -103.968611,
              48.999525
            ],
            [
              -103.969479,
              48.999525
            ],
            [
              -103.976459,
              48.999605
            ],
            [
              -103.980868,
              48.999581
            ],
            [
              -103.982361,
              48.999615
            ],
            [
              -103.983786,
              48.999604
            ],
            [
              -103.988925,
              48.99958
            ],
            [
              -103.992467,
              48.999567
            ],
            [
              -104.048736,
              48.999877
            ],
            [
              -104.048478,
              48.987007
            ],
            [
              -104.048616,
              48.966736
            ],
            [
              -104.048555,
              48.963772
            ],
            [
              -104.0488,
              48.958997
            ],
            [
              -104.048627,
              48.957124
            ],
            [
              -104.048698,
              48.951823
            ],
            [
              -104.048872,
              48.94963
            ],
            [
              -104.04877,
              48.943301
            ],
            [
              -104.048701,
              48.940331
            ],
            [
              -104.048807,
              48.933636
            ],
            [
              -104.048744,
              48.912113
            ],
            [
              -104.048746,
              48.906858
            ],
            [
              -104.048643,
              48.902609
            ],
            [
              -104.048719,
              48.879921
            ],
            [
              -104.048893,
              48.875739
            ],
            [
              -104.048883,
              48.874008
            ],
            [
              -104.048824,
              48.867539
            ],
            [
              -104.048652,
              48.865734
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "037",
      "COUNTYNS": "01035300",
      "AFFGEOID": "0500000US38037",
      "GEOID": "38037",
      "NAME": "Grant",
      "LSAD": "06",
      "ALAND": 4297180181,
      "AWATER": 17563262,
      "County_state": "Grant,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.096239,
              46.717706
            ],
            [
              -102.096585,
              46.631024
            ],
            [
              -102.050986,
              46.630871
            ],
            [
              -102.046929,
              46.283606
            ],
            [
              -101.998069,
              46.283671
            ],
            [
              -101.997888,
              46.20548
            ],
            [
              -101.998575,
              46.053461
            ],
            [
              -101.991401,
              46.047016
            ],
            [
              -101.965154,
              46.049837
            ],
            [
              -101.937134,
              46.066358
            ],
            [
              -101.894144,
              46.056825
            ],
            [
              -101.894857,
              46.069254
            ],
            [
              -101.874587,
              46.063371
            ],
            [
              -101.849355,
              46.073275
            ],
            [
              -101.841552,
              46.028841
            ],
            [
              -101.825212,
              46.037084
            ],
            [
              -101.802253,
              46.027931
            ],
            [
              -101.779319,
              46.038985
            ],
            [
              -101.766431,
              46.030824
            ],
            [
              -101.755136,
              46.042426
            ],
            [
              -101.746328,
              46.028348
            ],
            [
              -101.737643,
              46.037606
            ],
            [
              -101.719658,
              46.023439
            ],
            [
              -101.660101,
              46.012046
            ],
            [
              -101.644193,
              46.016108
            ],
            [
              -101.637288,
              46.037538
            ],
            [
              -101.622717,
              46.037028
            ],
            [
              -101.610627,
              46.018662
            ],
            [
              -101.591927,
              46.009551
            ],
            [
              -101.573765,
              46.025138
            ],
            [
              -101.553299,
              46.00695
            ],
            [
              -101.527224,
              46.017182
            ],
            [
              -101.534334,
              46.030802
            ],
            [
              -101.496882,
              46.023568
            ],
            [
              -101.485669,
              46.028189
            ],
            [
              -101.497461,
              46.041802
            ],
            [
              -101.49012,
              46.050352
            ],
            [
              -101.458287,
              46.049996
            ],
            [
              -101.42802,
              46.060559
            ],
            [
              -101.414603,
              46.058382
            ],
            [
              -101.407114,
              46.070812
            ],
            [
              -101.386292,
              46.07942
            ],
            [
              -101.36235,
              46.070392
            ],
            [
              -101.337208,
              46.081484
            ],
            [
              -101.343514,
              46.089257
            ],
            [
              -101.299917,
              46.104844
            ],
            [
              -101.296777,
              46.118208
            ],
            [
              -101.311447,
              46.125003
            ],
            [
              -101.301698,
              46.133269
            ],
            [
              -101.267484,
              46.130656
            ],
            [
              -101.246981,
              46.14221
            ],
            [
              -101.228832,
              46.137831
            ],
            [
              -101.21197,
              46.156207
            ],
            [
              -101.189747,
              46.148693
            ],
            [
              -101.198073,
              46.18377
            ],
            [
              -101.170963,
              46.191814
            ],
            [
              -101.167899,
              46.202028
            ],
            [
              -101.143479,
              46.203347
            ],
            [
              -101.10012,
              46.2302
            ],
            [
              -101.116105,
              46.24459
            ],
            [
              -101.091339,
              46.258055
            ],
            [
              -101.087394,
              46.275333
            ],
            [
              -101.058492,
              46.270171
            ],
            [
              -101.044715,
              46.282144
            ],
            [
              -101.025662,
              46.284219
            ],
            [
              -101.048579,
              46.28468
            ],
            [
              -101.048532,
              46.371393
            ],
            [
              -101.29899,
              46.371451
            ],
            [
              -101.299271,
              46.630472
            ],
            [
              -101.718105,
              46.630999
            ],
            [
              -101.718155,
              46.718019
            ],
            [
              -102.096239,
              46.717706
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "081",
      "COUNTYNS": "01035303",
      "AFFGEOID": "0500000US38081",
      "GEOID": "38081",
      "NAME": "Sargent",
      "LSAD": "06",
      "ALAND": 2223531669,
      "AWATER": 21728402,
      "County_state": "Sargent,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -98.006715,
              46.282626
            ],
            [
              -98.00810165517849,
              45.93609536428029
            ],
            [
              -97.986893,
              45.935961
            ],
            [
              -97.97877778172959,
              45.935937093589494
            ],
            [
              -97.958718,
              45.935878
            ],
            [
              -97.784575,
              45.935327
            ],
            [
              -97.77704,
              45.935393
            ],
            [
              -97.696691,
              45.935352
            ],
            [
              -97.542598,
              45.935258
            ],
            [
              -97.519035,
              45.935304
            ],
            [
              -97.518944,
              45.935304
            ],
            [
              -97.491892,
              45.935111
            ],
            [
              -97.481967,
              45.935138
            ],
            [
              -97.318899,
              45.935054
            ],
            [
              -97.312184,
              45.935077
            ],
            [
              -97.228323,
              45.935141
            ],
            [
              -97.2283038853974,
              45.93514103142339
            ],
            [
              -97.2282906022523,
              45.935141053260196
            ],
            [
              -97.228569,
              45.99246
            ],
            [
              -97.259632,
              45.997185
            ],
            [
              -97.259339,
              46.282197
            ],
            [
              -97.280568,
              46.282321
            ],
            [
              -97.909259,
              46.282408
            ],
            [
              -98.006715,
              46.282626
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "011",
      "COUNTYNS": "01035619",
      "AFFGEOID": "0500000US38011",
      "GEOID": "38011",
      "NAME": "Bowman",
      "LSAD": "06",
      "ALAND": 3009306320,
      "AWATER": 13587978,
      "County_state": "Bowman,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.045759,
              46.123946
            ],
            [
              -104.046822,
              46.000199
            ],
            [
              -104.045443,
              45.94531
            ],
            [
              -103.668479,
              45.945242
            ],
            [
              -103.660779,
              45.945241
            ],
            [
              -103.660779,
              45.945231
            ],
            [
              -103.577083,
              45.945283
            ],
            [
              -103.55871,
              45.945131
            ],
            [
              -103.434851,
              45.945291
            ],
            [
              -103.432393,
              45.945313
            ],
            [
              -103.41804,
              45.945186
            ],
            [
              -103.411325,
              45.945264
            ],
            [
              -103.37546,
              45.944797
            ],
            [
              -103.369148,
              45.945152
            ],
            [
              -103.284109,
              45.945152
            ],
            [
              -103.284092,
              45.945149
            ],
            [
              -103.218396,
              45.945208
            ],
            [
              -103.210634,
              45.945222
            ],
            [
              -103.161251,
              45.945309
            ],
            [
              -103.140939,
              45.945257
            ],
            [
              -103.097872,
              45.945262
            ],
            [
              -103.078477,
              45.945289
            ],
            [
              -103.047779,
              45.945335
            ],
            [
              -103.026058,
              45.945307
            ],
            [
              -102.99566790323101,
              45.9451674824132
            ],
            [
              -102.995454,
              46.280713
            ],
            [
              -103.360609,
              46.27994
            ],
            [
              -104.045417428123,
              46.280188073090294
            ],
            [
              -104.045237,
              46.125002
            ],
            [
              -104.045759,
              46.123946
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "033",
      "COUNTYNS": "01035617",
      "AFFGEOID": "0500000US38033",
      "GEOID": "38033",
      "NAME": "Golden Valley",
      "LSAD": "06",
      "ALAND": 2593894350,
      "AWATER": 4147470,
      "County_state": "Golden Valley,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.045542,
              46.933887
            ],
            [
              -104.045901,
              46.83079
            ],
            [
              -104.045402,
              46.725423
            ],
            [
              -104.045403,
              46.722177
            ],
            [
              -104.04537,
              46.721332
            ],
            [
              -104.045572,
              46.713881
            ],
            [
              -104.045474,
              46.708738
            ],
            [
              -104.04527115812101,
              46.6415014128297
            ],
            [
              -104.045271,
              46.641449
            ],
            [
              -104.045098456003,
              46.540929906304
            ],
            [
              -103.800881,
              46.540747
            ],
            [
              -103.800825,
              46.629563
            ],
            [
              -103.60921,
              46.629797
            ],
            [
              -103.609545,
              46.979902
            ],
            [
              -103.666986,
              46.979789
            ],
            [
              -103.666723,
              47.329354
            ],
            [
              -104.04530661997902,
              47.330127855
            ],
            [
              -104.045121,
              47.276969
            ],
            [
              -104.045155,
              47.27393
            ],
            [
              -104.045088,
              47.271406
            ],
            [
              -104.045057,
              47.266868
            ],
            [
              -104.045091,
              47.265953
            ],
            [
              -104.045159,
              47.263874
            ],
            [
              -104.045517,
              47.215666
            ],
            [
              -104.044788,
              47.12743
            ],
            [
              -104.045081,
              47.092813
            ],
            [
              -104.045018,
              47.081202
            ],
            [
              -104.045354,
              47.078574
            ],
            [
              -104.045259,
              47.063901
            ],
            [
              -104.045227,
              47.057502
            ],
            [
              -104.045195,
              47.053639
            ],
            [
              -104.045052,
              47.040863
            ],
            [
              -104.045076,
              47.037589
            ],
            [
              -104.045566,
              46.941231
            ],
            [
              -104.045535,
              46.934009
            ],
            [
              -104.045542,
              46.933887
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "051",
      "COUNTYNS": "01034211",
      "AFFGEOID": "0500000US38051",
      "GEOID": "38051",
      "NAME": "McIntosh",
      "LSAD": "06",
      "ALAND": 2524546488,
      "AWATER": 51817666,
      "County_state": "McIntosh,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.878431,
              46.282811
            ],
            [
              -99.8800620862737,
              45.9416699059574
            ],
            [
              -99.83868,
              45.941293
            ],
            [
              -99.750396,
              45.940935
            ],
            [
              -99.749494,
              45.940956
            ],
            [
              -99.749325,
              45.940935
            ],
            [
              -99.74787,
              45.940933
            ],
            [
              -99.718073,
              45.940907
            ],
            [
              -99.718071040168,
              45.9409070032797
            ],
            [
              -99.692975,
              45.940949
            ],
            [
              -99.671938,
              45.941062
            ],
            [
              -99.61116,
              45.941098
            ],
            [
              -99.58878,
              45.941104
            ],
            [
              -99.49314,
              45.940383
            ],
            [
              -99.490254,
              45.940362
            ],
            [
              -99.40126,
              45.940367
            ],
            [
              -99.385565,
              45.940407
            ],
            [
              -99.378486,
              45.940403
            ],
            [
              -99.34496,
              45.940299
            ],
            [
              -99.344774,
              45.940299
            ],
            [
              -99.317875,
              45.940263
            ],
            [
              -99.297272,
              45.940165
            ],
            [
              -99.283968,
              45.940195
            ],
            [
              -99.276266,
              45.940188
            ],
            [
              -99.257745,
              45.94006
            ],
            [
              -99.222269,
              45.940071
            ],
            [
              -99.221672,
              45.940069
            ],
            [
              -99.213644,
              45.940116
            ],
            [
              -99.212571,
              45.940108
            ],
            [
              -99.102372,
              45.940158
            ],
            [
              -99.092868,
              45.940184
            ],
            [
              -99.0057543501525,
              45.9399443091284
            ],
            [
              -99.003118,
              46.282898
            ],
            [
              -99.037367,
              46.283151
            ],
            [
              -99.878431,
              46.282811
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "089",
      "COUNTYNS": "01035618",
      "AFFGEOID": "0500000US38089",
      "GEOID": "38089",
      "NAME": "Stark",
      "LSAD": "06",
      "ALAND": 3457000613,
      "AWATER": 14109003,
      "County_state": "Stark,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.230991,
              46.980296
            ],
            [
              -103.231569,
              46.629764
            ],
            [
              -102.928003,
              46.630065
            ],
            [
              -102.297372,
              46.630611
            ],
            [
              -102.096585,
              46.631024
            ],
            [
              -102.096239,
              46.717706
            ],
            [
              -102.096675,
              46.981159
            ],
            [
              -102.144878,
              46.981425
            ],
            [
              -102.144771,
              47.010307
            ],
            [
              -102.525463,
              47.01024
            ],
            [
              -102.525462,
              46.981307
            ],
            [
              -103.032681,
              46.98058
            ],
            [
              -103.230991,
              46.980296
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "101",
      "COUNTYNS": "01034231",
      "AFFGEOID": "0500000US38101",
      "GEOID": "38101",
      "NAME": "Ward",
      "LSAD": "06",
      "ALAND": 5214059942,
      "AWATER": 110820843,
      "County_state": "Ward,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.2336,
              48.72029
            ],
            [
              -102.233892,
              48.546339
            ],
            [
              -101.973322,
              48.547133
            ],
            [
              -101.973399,
              48.372095
            ],
            [
              -101.922237,
              48.37233
            ],
            [
              -101.922123,
              48.023173
            ],
            [
              -101.871537,
              48.023176
            ],
            [
              -101.871862,
              47.847589
            ],
            [
              -101.099882,
              47.848439
            ],
            [
              -100.971325,
              47.848703
            ],
            [
              -100.970502,
              48.022626
            ],
            [
              -101.015024,
              48.022739
            ],
            [
              -101.014783,
              48.109544
            ],
            [
              -101.014319,
              48.283491
            ],
            [
              -101.014138,
              48.371432
            ],
            [
              -101.059915,
              48.371453
            ],
            [
              -101.059802,
              48.458448
            ],
            [
              -101.320812,
              48.458797
            ],
            [
              -101.581912,
              48.459225
            ],
            [
              -101.841969,
              48.459545
            ],
            [
              -101.842141,
              48.720143
            ],
            [
              -102.021428,
              48.720283
            ],
            [
              -102.021655,
              48.807108
            ],
            [
              -102.152164,
              48.8072
            ],
            [
              -102.152545,
              48.720294
            ],
            [
              -102.2336,
              48.72029
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "069",
      "COUNTYNS": "01034230",
      "AFFGEOID": "0500000US38069",
      "GEOID": "38069",
      "NAME": "Pierce",
      "LSAD": "06",
      "ALAND": 2637787590,
      "AWATER": 165445738,
      "County_state": "Pierce,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.27666,
              48.544813
            ],
            [
              -100.276401,
              48.371334
            ],
            [
              -100.237332,
              48.371246
            ],
            [
              -100.237092,
              48.021866
            ],
            [
              -100.197558,
              48.021857
            ],
            [
              -100.197996,
              47.8476
            ],
            [
              -100.069206,
              47.847301
            ],
            [
              -99.812167,
              47.847257
            ],
            [
              -99.812133,
              48.021451
            ],
            [
              -99.848165,
              48.021536
            ],
            [
              -99.846613,
              48.371298
            ],
            [
              -99.492919,
              48.370946
            ],
            [
              -99.493189,
              48.544834
            ],
            [
              -100.145858,
              48.545211
            ],
            [
              -100.27666,
              48.544813
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "083",
      "COUNTYNS": "01034203",
      "AFFGEOID": "0500000US38083",
      "GEOID": "38083",
      "NAME": "Sheridan",
      "LSAD": "06",
      "ALAND": 2518459184,
      "AWATER": 86143152,
      "County_state": "Sheridan,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.672408,
              47.674038
            ],
            [
              -100.673285,
              47.327426
            ],
            [
              -100.113261,
              47.327262
            ],
            [
              -100.033292,
              47.327192
            ],
            [
              -100.032849,
              47.673621
            ],
            [
              -100.069514,
              47.67365
            ],
            [
              -100.069206,
              47.847301
            ],
            [
              -100.197996,
              47.8476
            ],
            [
              -100.585161,
              47.84748
            ],
            [
              -100.585106,
              47.673912
            ],
            [
              -100.672408,
              47.674038
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "053",
      "COUNTYNS": "01035299",
      "AFFGEOID": "0500000US38053",
      "GEOID": "38053",
      "NAME": "McKenzie",
      "LSAD": "06",
      "ALAND": 7149551309,
      "AWATER": 260198853,
      "County_state": "McKenzie,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.045333,
              47.343452
            ],
            [
              -104.045313,
              47.331955
            ],
            [
              -104.04530661997902,
              47.330127855
            ],
            [
              -103.666723,
              47.329354
            ],
            [
              -103.099197,
              47.328714
            ],
            [
              -103.09913,
              47.350393
            ],
            [
              -103.099867,
              47.674416
            ],
            [
              -102.784254,
              47.674305
            ],
            [
              -102.642766,
              47.673911
            ],
            [
              -102.642266,
              47.823713
            ],
            [
              -102.656165,
              47.860814
            ],
            [
              -102.647864,
              47.901615
            ],
            [
              -102.610462,
              47.939116
            ],
            [
              -102.565902,
              47.969028
            ],
            [
              -102.552981,
              47.998646
            ],
            [
              -102.593862,
              48.011619
            ],
            [
              -102.599405,
              48.037246
            ],
            [
              -102.60836,
              48.046351
            ],
            [
              -102.639766,
              48.03712
            ],
            [
              -102.654684,
              48.039252
            ],
            [
              -102.668041,
              48.050859
            ],
            [
              -102.663487,
              48.076651
            ],
            [
              -102.703661,
              48.087918
            ],
            [
              -102.733162,
              48.088318
            ],
            [
              -102.758333,
              48.095092
            ],
            [
              -102.741635,
              48.10076
            ],
            [
              -102.73756,
              48.116608
            ],
            [
              -102.762314,
              48.129962
            ],
            [
              -102.79479,
              48.11913
            ],
            [
              -102.828477,
              48.123664
            ],
            [
              -102.864862,
              48.13692
            ],
            [
              -102.906885,
              48.12179
            ],
            [
              -102.928665,
              48.123629
            ],
            [
              -102.93846,
              48.132506
            ],
            [
              -102.972885,
              48.138011
            ],
            [
              -103.008915,
              48.13196
            ],
            [
              -103.027459,
              48.135489
            ],
            [
              -103.069081,
              48.132418
            ],
            [
              -103.088818,
              48.124648
            ],
            [
              -103.092056,
              48.143137
            ],
            [
              -103.107083,
              48.146494
            ],
            [
              -103.15711,
              48.136193
            ],
            [
              -103.20189,
              48.135208
            ],
            [
              -103.213794,
              48.128588
            ],
            [
              -103.252497,
              48.092974
            ],
            [
              -103.225965,
              48.075123
            ],
            [
              -103.232965,
              48.063323
            ],
            [
              -103.277266,
              48.058023
            ],
            [
              -103.288366,
              48.040123
            ],
            [
              -103.322767,
              48.039924
            ],
            [
              -103.379769,
              48.025124
            ],
            [
              -103.42837,
              48.035924
            ],
            [
              -103.459471,
              48.024725
            ],
            [
              -103.485072,
              48.006725
            ],
            [
              -103.501172,
              48.005925
            ],
            [
              -103.525472,
              48.033325
            ],
            [
              -103.569374,
              48.035724
            ],
            [
              -103.583974,
              48.043224
            ],
            [
              -103.582374,
              48.057824
            ],
            [
              -103.551473,
              48.077824
            ],
            [
              -103.548572,
              48.087724
            ],
            [
              -103.583873,
              48.092224
            ],
            [
              -103.608174,
              48.114124
            ],
            [
              -103.585712,
              48.124553
            ],
            [
              -103.597044,
              48.13441
            ],
            [
              -103.632775,
              48.111324
            ],
            [
              -103.675176,
              48.122423
            ],
            [
              -103.717074,
              48.109085
            ],
            [
              -103.742216,
              48.093403
            ],
            [
              -103.740322,
              48.083404
            ],
            [
              -103.709454,
              48.065462
            ],
            [
              -103.711036,
              48.058031
            ],
            [
              -103.747509,
              48.040767
            ],
            [
              -103.770512,
              48.056477
            ],
            [
              -103.784394,
              48.059491
            ],
            [
              -103.79777,
              48.035545
            ],
            [
              -103.791595,
              48.013317
            ],
            [
              -103.800817,
              47.994269
            ],
            [
              -103.82883,
              47.980121
            ],
            [
              -103.826052,
              47.996288
            ],
            [
              -103.856306,
              48.015072
            ],
            [
              -103.864373,
              48.00866
            ],
            [
              -103.855943,
              47.995139
            ],
            [
              -103.869353,
              47.97279
            ],
            [
              -103.888053,
              47.962026
            ],
            [
              -103.919789,
              47.95595
            ],
            [
              -103.942685,
              47.97572
            ],
            [
              -103.967386,
              47.98552
            ],
            [
              -103.986667,
              47.982483
            ],
            [
              -103.987399,
              47.974306
            ],
            [
              -104.007036,
              47.967339
            ],
            [
              -104.020397,
              47.98823
            ],
            [
              -104.04412,
              47.996107
            ],
            [
              -104.044162,
              47.992836
            ],
            [
              -104.043933,
              47.971515
            ],
            [
              -104.043497,
              47.95449
            ],
            [
              -104.043329,
              47.949554
            ],
            [
              -104.04223,
              47.891031
            ],
            [
              -104.041662,
              47.862282
            ],
            [
              -104.041869,
              47.841699
            ],
            [
              -104.042567,
              47.808237
            ],
            [
              -104.042432,
              47.805358
            ],
            [
              -104.042384,
              47.803256
            ],
            [
              -104.043199,
              47.747292
            ],
            [
              -104.043242,
              47.747106
            ],
            [
              -104.043742,
              47.625016
            ],
            [
              -104.044241,
              47.612288
            ],
            [
              -104.043912,
              47.603229
            ],
            [
              -104.044109,
              47.523595
            ],
            [
              -104.044621,
              47.45938
            ],
            [
              -104.044797,
              47.438445
            ],
            [
              -104.045069,
              47.397461
            ],
            [
              -104.04506544260501,
              47.3970733821092
            ],
            [
              -104.044863,
              47.375015
            ],
            [
              -104.045333,
              47.343452
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "001",
      "COUNTYNS": "01034210",
      "AFFGEOID": "0500000US38001",
      "GEOID": "38001",
      "NAME": "Adams",
      "LSAD": "06",
      "ALAND": 2557960600,
      "AWATER": 2833854,
      "County_state": "Adams,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.995454,
              46.280713
            ],
            [
              -102.99566790323101,
              45.9451674824132
            ],
            [
              -102.995345,
              45.945166
            ],
            [
              -102.989902,
              45.945211
            ],
            [
              -102.942069771231,
              45.945091798392696
            ],
            [
              -102.920482,
              45.945038
            ],
            [
              -102.880252,
              45.945069
            ],
            [
              -102.704871,
              45.945072
            ],
            [
              -102.674077,
              45.945274
            ],
            [
              -102.672474,
              45.945244
            ],
            [
              -102.666684,
              45.945307
            ],
            [
              -102.65162,
              45.94545
            ],
            [
              -102.642555,
              45.945404
            ],
            [
              -102.558579,
              45.945129
            ],
            [
              -102.550947,
              45.945015
            ],
            [
              -102.476024,
              45.945183
            ],
            [
              -102.467563,
              45.945159
            ],
            [
              -102.459586,
              45.945081
            ],
            [
              -102.446419,
              45.945083
            ],
            [
              -102.425397,
              45.945041
            ],
            [
              -102.425358,
              45.94499
            ],
            [
              -102.420173,
              45.94507
            ],
            [
              -102.410346,
              45.945079
            ],
            [
              -102.406176,
              45.944997
            ],
            [
              -102.398575,
              45.944868
            ],
            [
              -102.396359,
              45.944916
            ],
            [
              -102.392767,
              45.944979
            ],
            [
              -102.392696,
              45.944951
            ],
            [
              -102.354283,
              45.944901
            ],
            [
              -102.353384,
              45.944984
            ],
            [
              -102.32823,
              45.944806
            ],
            [
              -102.217867,
              45.944711
            ],
            [
              -102.176993,
              45.944622
            ],
            [
              -102.176698,
              45.944622
            ],
            [
              -102.159439,
              45.944641
            ],
            [
              -102.157965,
              45.944641
            ],
            [
              -102.156393,
              45.944663
            ],
            [
              -102.145356,
              45.944659
            ],
            [
              -102.135269,
              45.944586
            ],
            [
              -102.125429,
              45.944652
            ],
            [
              -102.124628,
              45.944813
            ],
            [
              -102.087555,
              45.944598
            ],
            [
              -102.085122,
              45.944642
            ],
            [
              -102.06093,
              45.944622
            ],
            [
              -102.000678897073,
              45.944515040647495
            ],
            [
              -102.000656,
              45.944515
            ],
            [
              -102.000425,
              45.944581
            ],
            [
              -101.998703,
              45.944557
            ],
            [
              -101.9986182554,
              45.9445558815169
            ],
            [
              -101.998575,
              46.053461
            ],
            [
              -101.997888,
              46.20548
            ],
            [
              -102.497475,
              46.206077
            ],
            [
              -102.497449,
              46.283196
            ],
            [
              -102.548204,
              46.282835
            ],
            [
              -102.924547,
              46.281519
            ],
            [
              -102.995454,
              46.280713
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "013",
      "COUNTYNS": "01034235",
      "AFFGEOID": "0500000US38013",
      "GEOID": "38013",
      "NAME": "Burke",
      "LSAD": "06",
      "ALAND": 2858266385,
      "AWATER": 65700367,
      "County_state": "Burke,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -102.93877696099301,
              48.9994577570233
            ],
            [
              -102.939932,
              48.720469
            ],
            [
              -102.885686,
              48.720488
            ],
            [
              -102.885775,
              48.633298
            ],
            [
              -102.885763,
              48.54639
            ],
            [
              -102.36341,
              48.54642
            ],
            [
              -102.233892,
              48.546339
            ],
            [
              -102.2336,
              48.72029
            ],
            [
              -102.152545,
              48.720294
            ],
            [
              -102.152164,
              48.8072
            ],
            [
              -102.021655,
              48.807108
            ],
            [
              -102.021144,
              48.999015
            ],
            [
              -102.131614,
              48.998842
            ],
            [
              -102.151847,
              48.998798
            ],
            [
              -102.211301,
              48.998554
            ],
            [
              -102.216993,
              48.998553
            ],
            [
              -102.850455,
              48.999431
            ],
            [
              -102.93877696099301,
              48.9994577570233
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "043",
      "COUNTYNS": "01034206",
      "AFFGEOID": "0500000US38043",
      "GEOID": "38043",
      "NAME": "Kidder",
      "LSAD": "06",
      "ALAND": 3499507647,
      "AWATER": 211725374,
      "County_state": "Kidder,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.113261,
              47.327262
            ],
            [
              -100.114637,
              46.981666
            ],
            [
              -100.075229,
              46.981282
            ],
            [
              -100.076554,
              46.894135
            ],
            [
              -100.081198,
              46.633396
            ],
            [
              -99.915508,
              46.632925
            ],
            [
              -99.44972,
              46.63163
            ],
            [
              -99.446423,
              46.980889
            ],
            [
              -99.481085,
              46.980694
            ],
            [
              -99.480726,
              47.327076
            ],
            [
              -100.033292,
              47.327192
            ],
            [
              -100.113261,
              47.327262
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "027",
      "COUNTYNS": "01034222",
      "AFFGEOID": "0500000US38027",
      "GEOID": "38027",
      "NAME": "Eddy",
      "LSAD": "06",
      "ALAND": 1632134148,
      "AWATER": 36114199,
      "County_state": "Eddy,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.297204,
              47.846811
            ],
            [
              -99.297318,
              47.673004
            ],
            [
              -99.26571,
              47.672969
            ],
            [
              -99.265846,
              47.587618
            ],
            [
              -98.500085,
              47.587228
            ],
            [
              -98.500181,
              47.672413
            ],
            [
              -98.525178,
              47.672497
            ],
            [
              -98.525516,
              47.846276
            ],
            [
              -98.539084,
              47.846258
            ],
            [
              -98.544776,
              47.847405
            ],
            [
              -99.297204,
              47.846811
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "031",
      "COUNTYNS": "01034223",
      "AFFGEOID": "0500000US38031",
      "GEOID": "38031",
      "NAME": "Foster",
      "LSAD": "06",
      "ALAND": 1645810432,
      "AWATER": 29450853,
      "County_state": "Foster,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.265846,
              47.587618
            ],
            [
              -99.26628,
              47.327194
            ],
            [
              -98.499246,
              47.326538
            ],
            [
              -98.500085,
              47.587228
            ],
            [
              -99.265846,
              47.587618
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "005",
      "COUNTYNS": "01034216",
      "AFFGEOID": "0500000US38005",
      "GEOID": "38005",
      "NAME": "Benson",
      "LSAD": "06",
      "ALAND": 3596568808,
      "AWATER": 131708144,
      "County_state": "Benson,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -99.846613,
              48.371298
            ],
            [
              -99.848165,
              48.021536
            ],
            [
              -99.812133,
              48.021451
            ],
            [
              -99.812167,
              47.847257
            ],
            [
              -99.297204,
              47.846811
            ],
            [
              -98.544776,
              47.847405
            ],
            [
              -98.539084,
              47.846258
            ],
            [
              -98.525516,
              47.846276
            ],
            [
              -98.525555,
              47.915056
            ],
            [
              -98.625384,
              47.916399
            ],
            [
              -98.635284,
              47.930999
            ],
            [
              -98.625684,
              47.936799
            ],
            [
              -98.663485,
              47.9643
            ],
            [
              -98.672086,
              48.0004
            ],
            [
              -98.694816,
              48.003777
            ],
            [
              -98.723587,
              47.9976
            ],
            [
              -98.734987,
              47.981
            ],
            [
              -98.763029,
              47.971689
            ],
            [
              -98.794312,
              47.951314
            ],
            [
              -98.778622,
              47.973478
            ],
            [
              -98.75061,
              47.982456
            ],
            [
              -98.763589,
              48.001
            ],
            [
              -98.76669,
              48.0294
            ],
            [
              -98.769754,
              47.998689
            ],
            [
              -98.78319,
              48.0016
            ],
            [
              -98.785391,
              48.0299
            ],
            [
              -98.803893,
              48.059901
            ],
            [
              -98.825094,
              48.0624
            ],
            [
              -98.835394,
              48.049
            ],
            [
              -98.883396,
              48.024499
            ],
            [
              -98.888896,
              48.009499
            ],
            [
              -98.897697,
              48.027099
            ],
            [
              -98.919997,
              48.017999
            ],
            [
              -98.917497,
              48.004099
            ],
            [
              -98.928858,
              47.996983
            ],
            [
              -98.944798,
              48.002799
            ],
            [
              -98.991253,
              47.99026
            ],
            [
              -98.988601,
              48.012998
            ],
            [
              -99.004288,
              48.041931
            ],
            [
              -99.001038,
              48.053294
            ],
            [
              -99.036844,
              48.047403
            ],
            [
              -99.060211,
              48.021977
            ],
            [
              -99.070351,
              48.021955
            ],
            [
              -99.070443,
              48.107857
            ],
            [
              -99.109312,
              48.107663
            ],
            [
              -99.139356,
              48.143675
            ],
            [
              -99.199911,
              48.155309
            ],
            [
              -99.200306,
              48.370658
            ],
            [
              -99.492919,
              48.370946
            ],
            [
              -99.846613,
              48.371298
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "029",
      "COUNTYNS": "01035615",
      "AFFGEOID": "0500000US38029",
      "GEOID": "38029",
      "NAME": "Emmons",
      "LSAD": "06",
      "ALAND": 3912007898,
      "AWATER": 115480697,
      "County_state": "Emmons,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.66189,
              46.63445
            ],
            [
              -100.6254,
              46.612184
            ],
            [
              -100.568141,
              46.594025
            ],
            [
              -100.561035,
              46.587847
            ],
            [
              -100.565369,
              46.578449
            ],
            [
              -100.587027,
              46.573435
            ],
            [
              -100.544555,
              46.533766
            ],
            [
              -100.545821,
              46.525191
            ],
            [
              -100.578739,
              46.505556
            ],
            [
              -100.586599,
              46.492694
            ],
            [
              -100.598099,
              46.451294
            ],
            [
              -100.590199,
              46.427894
            ],
            [
              -100.559399,
              46.381694
            ],
            [
              -100.551199,
              46.363494
            ],
            [
              -100.549199,
              46.329293
            ],
            [
              -100.559099,
              46.307994
            ],
            [
              -100.5804,
              46.281394
            ],
            [
              -100.5798,
              46.272294
            ],
            [
              -100.558549,
              46.249993
            ],
            [
              -100.5592,
              46.235694
            ],
            [
              -100.575301,
              46.209394
            ],
            [
              -100.592202,
              46.196394
            ],
            [
              -100.615303,
              46.169695
            ],
            [
              -100.629145,
              46.145608
            ],
            [
              -100.626329,
              46.126983
            ],
            [
              -100.616104,
              46.115695
            ],
            [
              -100.614504,
              46.071796
            ],
            [
              -100.600005,
              46.049896
            ],
            [
              -100.560205,
              46.008396
            ],
            [
              -100.533105,
              46.000496
            ],
            [
              -100.507026,
              45.966548
            ],
            [
              -100.51194899399302,
              45.9436539838471
            ],
            [
              -100.511793,
              45.943654
            ],
            [
              -100.499353928538,
              45.9436316399083
            ],
            [
              -100.462838,
              45.943566
            ],
            [
              -100.430597,
              45.943638
            ],
            [
              -100.424438,
              45.943569
            ],
            [
              -100.420162,
              45.943533
            ],
            [
              -100.410386,
              45.943453
            ],
            [
              -100.294126,
              45.943269
            ],
            [
              -100.285345,
              45.94313
            ],
            [
              -100.284134,
              45.942951
            ],
            [
              -100.275614,
              45.942922
            ],
            [
              -100.274762,
              45.942945
            ],
            [
              -100.170826,
              45.942514
            ],
            [
              -100.152084,
              45.942486
            ],
            [
              -100.14173,
              45.942506
            ],
            [
              -100.110339,
              45.942367
            ],
            [
              -100.108471,
              45.942391
            ],
            [
              -100.084163,
              45.942301
            ],
            [
              -100.06902,
              45.94217
            ],
            [
              -100.005486,
              45.94195
            ],
            [
              -99.965775,
              45.941822
            ],
            [
              -99.880292,
              45.941672
            ],
            [
              -99.8800620862737,
              45.9416699059574
            ],
            [
              -99.878431,
              46.282811
            ],
            [
              -99.916758,
              46.283171
            ],
            [
              -99.915508,
              46.632925
            ],
            [
              -100.081198,
              46.633396
            ],
            [
              -100.66189,
              46.63445
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "025",
      "COUNTYNS": "01035623",
      "AFFGEOID": "0500000US38025",
      "GEOID": "38025",
      "NAME": "Dunn",
      "LSAD": "06",
      "ALAND": 5201888569,
      "AWATER": 190575791,
      "County_state": "Dunn,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -103.099867,
              47.674416
            ],
            [
              -103.09913,
              47.350393
            ],
            [
              -103.099197,
              47.328714
            ],
            [
              -103.032569,
              47.328692
            ],
            [
              -103.032681,
              46.98058
            ],
            [
              -102.525462,
              46.981307
            ],
            [
              -102.525463,
              47.01024
            ],
            [
              -102.144771,
              47.010307
            ],
            [
              -102.144521,
              47.155875
            ],
            [
              -102.144453,
              47.328079
            ],
            [
              -102.205601,
              47.328071
            ],
            [
              -102.205734,
              47.479827
            ],
            [
              -102.20535,
              47.574506
            ],
            [
              -102.231452,
              47.598306
            ],
            [
              -102.252052,
              47.609906
            ],
            [
              -102.265452,
              47.624307
            ],
            [
              -102.263151,
              47.727108
            ],
            [
              -102.285052,
              47.738808
            ],
            [
              -102.291253,
              47.752808
            ],
            [
              -102.261251,
              47.762108
            ],
            [
              -102.262051,
              47.782009
            ],
            [
              -102.335554,
              47.80111
            ],
            [
              -102.358256,
              47.79061
            ],
            [
              -102.375457,
              47.763509
            ],
            [
              -102.385758,
              47.758008
            ],
            [
              -102.415359,
              47.752009
            ],
            [
              -102.472962,
              47.76491
            ],
            [
              -102.499063,
              47.78001
            ],
            [
              -102.561965,
              47.780111
            ],
            [
              -102.624466,
              47.806413
            ],
            [
              -102.642266,
              47.823713
            ],
            [
              -102.642766,
              47.673911
            ],
            [
              -102.784254,
              47.674305
            ],
            [
              -103.099867,
              47.674416
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "103",
      "COUNTYNS": "01034204",
      "AFFGEOID": "0500000US38103",
      "GEOID": "38103",
      "NAME": "Wells",
      "LSAD": "06",
      "ALAND": 3291081473,
      "AWATER": 50967324,
      "County_state": "Wells,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.069206,
              47.847301
            ],
            [
              -100.069514,
              47.67365
            ],
            [
              -100.032849,
              47.673621
            ],
            [
              -100.033292,
              47.327192
            ],
            [
              -99.480726,
              47.327076
            ],
            [
              -99.26628,
              47.327194
            ],
            [
              -99.265846,
              47.587618
            ],
            [
              -99.26571,
              47.672969
            ],
            [
              -99.297318,
              47.673004
            ],
            [
              -99.297204,
              47.846811
            ],
            [
              -99.812167,
              47.847257
            ],
            [
              -100.069206,
              47.847301
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "38",
      "COUNTYFP": "015",
      "COUNTYNS": "01035614",
      "AFFGEOID": "0500000US38015",
      "GEOID": "38015",
      "NAME": "Burleigh",
      "LSAD": "06",
      "ALAND": 4228239131,
      "AWATER": 92618423,
      "County_state": "Burleigh,38"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -100.965156,
              47.156631
            ],
            [
              -100.944716,
              47.139492
            ],
            [
              -100.937483,
              47.093629
            ],
            [
              -100.925455,
              47.076898
            ],
            [
              -100.884241,
              47.047284
            ],
            [
              -100.879741,
              47.022167
            ],
            [
              -100.895729,
              47.002713
            ],
            [
              -100.935983,
              46.98284
            ],
            [
              -100.933367,
              46.964408
            ],
            [
              -100.907675,
              46.948667
            ],
            [
              -100.903516,
              46.896505
            ],
            [
              -100.875458,
              46.865139
            ],
            [
              -100.861404,
              46.843472
            ],
            [
              -100.858566,
              46.84023
            ],
            [
              -100.833979,
              46.821952
            ],
            [
              -100.819015,
              46.808008
            ],
            [
              -100.818944,
              46.807904
            ],
            [
              -100.819608,
              46.796444
            ],
            [
              -100.839414,
              46.763776
            ],
            [
              -100.834782,
              46.749131
            ],
            [
              -100.805577,
              46.735471
            ],
            [
              -100.779477,
              46.736571
            ],
            [
              -100.778478,
              46.720029
            ],
            [
              -100.797939,
              46.715594
            ],
            [
              -100.778156,
              46.686486
            ],
            [
              -100.748293,
              46.692546
            ],
            [
              -100.745458,
              46.665785
            ],
            [
              -100.727996,
              46.655699
            ],
            [
              -100.675739,
              46.673736
            ],
            [
              -100.641665,
              46.678391
            ],
            [
              -100.66189,
              46.63445
            ],
            [
              -100.081198,
              46.633396
            ],
            [
              -100.076554,
              46.894135
            ],
            [
              -100.075229,
              46.981282
            ],
            [
              -100.114637,
              46.981666
            ],
            [
              -100.113261,
              47.327262
            ],
            [
              -100.673285,
              47.327426
            ],
            [
              -100.749334,
              47.327685
            ],
            [
              -100.74937,
              47.157245
            ],
            [
              -100.965156,
              47.156631
            ]
          ]
        ]
      ]
    }
  }
]
};