export const counties_mt = {
  "type":"FeatureCollection","name":"mt_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "033",
      "COUNTYNS": "01716058",
      "AFFGEOID": "0500000US30033",
      "GEOID": "30033",
      "NAME": "Garfield",
      "LSAD": "06",
      "ALAND": 12112233301,
      "AWATER": 446864348,
      "County_state": "Garfield,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -107.966521,
              47.252695
            ],
            [
              -107.967477,
              47.248352
            ],
            [
              -107.962692,
              47.247819
            ],
            [
              -107.960959,
              47.245603
            ],
            [
              -107.957935,
              47.245067
            ],
            [
              -107.956583,
              47.245877
            ],
            [
              -107.953524,
              47.245406
            ],
            [
              -107.94949,
              47.243735
            ],
            [
              -107.949091,
              47.239689
            ],
            [
              -107.952237,
              47.237748
            ],
            [
              -107.954597,
              47.239569
            ],
            [
              -107.960702,
              47.238631
            ],
            [
              -107.959886,
              47.236187
            ],
            [
              -107.956689,
              47.233773
            ],
            [
              -107.955627,
              47.229549
            ],
            [
              -107.959821,
              47.227996
            ],
            [
              -107.960177,
              47.230346
            ],
            [
              -107.964594,
              47.230315
            ],
            [
              -107.96329,
              47.228287
            ],
            [
              -107.959776,
              47.225224
            ],
            [
              -107.955952,
              47.224424
            ],
            [
              -107.951143,
              47.224942
            ],
            [
              -107.951504,
              47.226652
            ],
            [
              -107.948166,
              47.226862
            ],
            [
              -107.944667,
              47.224002
            ],
            [
              -107.945249,
              47.221616
            ],
            [
              -107.943763,
              47.218843
            ],
            [
              -107.938909,
              47.217309
            ],
            [
              -107.939901,
              47.208441
            ],
            [
              -107.9437,
              47.203771
            ],
            [
              -107.945837,
              47.199571
            ],
            [
              -107.947104,
              47.199012
            ],
            [
              -107.950337,
              47.196955
            ],
            [
              -107.948286,
              47.194942
            ],
            [
              -107.944071,
              47.195248
            ],
            [
              -107.939947,
              47.193154
            ],
            [
              -107.933075,
              47.193497
            ],
            [
              -107.933804,
              47.190546
            ],
            [
              -107.9351,
              47.19107
            ],
            [
              -107.93704,
              47.18798
            ],
            [
              -107.933362,
              47.187566
            ],
            [
              -107.932876,
              47.183899
            ],
            [
              -107.936933,
              47.179489
            ],
            [
              -107.936302,
              47.178128
            ],
            [
              -107.931951,
              47.180877
            ],
            [
              -107.926304,
              47.180966
            ],
            [
              -107.924938,
              47.177661
            ],
            [
              -107.929865,
              47.175333
            ],
            [
              -107.9295,
              47.170839
            ],
            [
              -107.929484,
              47.16724
            ],
            [
              -107.93513,
              47.16609
            ],
            [
              -107.940109,
              47.169985
            ],
            [
              -107.944584,
              47.171356
            ],
            [
              -107.945891,
              47.167318
            ],
            [
              -107.941014,
              47.16305
            ],
            [
              -107.939468,
              47.157223
            ],
            [
              -107.941366,
              47.153549
            ],
            [
              -107.941912,
              47.155262
            ],
            [
              -107.942967,
              47.156712
            ],
            [
              -107.944587,
              47.159729
            ],
            [
              -107.944967,
              47.159235
            ],
            [
              -107.947939,
              47.158755
            ],
            [
              -107.949044,
              47.159581
            ],
            [
              -107.953167,
              47.156055
            ],
            [
              -107.949382,
              47.153532
            ],
            [
              -107.946854,
              47.14782
            ],
            [
              -107.94146,
              47.146707
            ],
            [
              -107.945085,
              47.140868
            ],
            [
              -107.95038,
              47.14107
            ],
            [
              -107.954322,
              47.140516
            ],
            [
              -107.952287,
              47.138138
            ],
            [
              -107.948129,
              47.138582
            ],
            [
              -107.942647,
              47.139464
            ],
            [
              -107.94277,
              47.136283
            ],
            [
              -107.945074,
              47.132799
            ],
            [
              -107.950299,
              47.130998
            ],
            [
              -107.952681,
              47.131925
            ],
            [
              -107.951743,
              47.132744
            ],
            [
              -107.954756,
              47.13501
            ],
            [
              -107.956646,
              47.131812
            ],
            [
              -107.960026,
              47.126657
            ],
            [
              -107.954973,
              47.121261
            ],
            [
              -107.947713,
              47.121275
            ],
            [
              -107.93965,
              47.117365
            ],
            [
              -107.935033,
              47.116293
            ],
            [
              -107.930961,
              47.114885
            ],
            [
              -107.931365,
              47.11254
            ],
            [
              -107.933496,
              47.111571
            ],
            [
              -107.934527,
              47.112792
            ],
            [
              -107.93827,
              47.112926
            ],
            [
              -107.937972,
              47.111653
            ],
            [
              -107.943099,
              47.109289
            ],
            [
              -107.949024,
              47.110984
            ],
            [
              -107.951997,
              47.109921
            ],
            [
              -107.946651,
              47.107632
            ],
            [
              -107.939704,
              47.105101
            ],
            [
              -107.937109,
              47.09926
            ],
            [
              -107.933272,
              47.091163
            ],
            [
              -107.93864,
              47.086681
            ],
            [
              -107.940792,
              47.087064
            ],
            [
              -107.939558,
              47.083428
            ],
            [
              -107.936569,
              47.083616
            ],
            [
              -107.935128,
              47.083968
            ],
            [
              -107.933705,
              47.08054
            ],
            [
              -107.932146,
              47.077606
            ],
            [
              -107.927771,
              47.077276
            ],
            [
              -107.92855,
              47.079548
            ],
            [
              -107.929081,
              47.082453
            ],
            [
              -107.922202,
              47.082317
            ],
            [
              -107.920413,
              47.077771
            ],
            [
              -107.92229,
              47.076882
            ],
            [
              -107.926165,
              47.072865
            ],
            [
              -107.923677,
              47.069482
            ],
            [
              -107.919298,
              47.069339
            ],
            [
              -107.914642,
              47.070273
            ],
            [
              -107.913912,
              47.069243
            ],
            [
              -107.917806,
              47.067259
            ],
            [
              -107.92221,
              47.065552
            ],
            [
              -107.924311,
              47.063459
            ],
            [
              -107.924035,
              47.061458
            ],
            [
              -107.921359,
              47.06034
            ],
            [
              -107.920423,
              47.061117
            ],
            [
              -107.917763,
              47.061335
            ],
            [
              -107.918475,
              47.058714
            ],
            [
              -107.921958,
              47.057226
            ],
            [
              -107.925401,
              47.054342
            ],
            [
              -107.925661,
              47.05228
            ],
            [
              -107.921059,
              47.048666
            ],
            [
              -107.922511,
              47.048125
            ],
            [
              -107.924783,
              47.047449
            ],
            [
              -107.92537,
              47.045044
            ],
            [
              -107.920419,
              47.043728
            ],
            [
              -107.91406,
              47.039584
            ],
            [
              -107.907278,
              47.035392
            ],
            [
              -107.906272,
              47.031364
            ],
            [
              -107.905081,
              47.027416
            ],
            [
              -107.906678,
              47.027958
            ],
            [
              -107.907788,
              47.029471
            ],
            [
              -107.915079,
              47.027783
            ],
            [
              -107.918456,
              47.025898
            ],
            [
              -107.92181,
              47.022312
            ],
            [
              -107.920487,
              47.020786
            ],
            [
              -107.916226,
              47.015734
            ],
            [
              -107.909435,
              47.012957
            ],
            [
              -107.905003,
              47.015766
            ],
            [
              -107.90383,
              47.018371
            ],
            [
              -107.901551,
              47.019316
            ],
            [
              -107.897975,
              47.016874
            ],
            [
              -107.896924,
              47.010307
            ],
            [
              -107.891815,
              47.009391
            ],
            [
              -107.888583,
              47.005552
            ],
            [
              -107.884845,
              47.000148
            ],
            [
              -107.886317,
              46.997583
            ],
            [
              -107.889369,
              46.995061
            ],
            [
              -107.890865,
              46.991569
            ],
            [
              -107.887209,
              46.988239
            ],
            [
              -107.885506,
              46.986103
            ],
            [
              -107.887997,
              46.983201
            ],
            [
              -107.892457,
              46.98257
            ],
            [
              -107.895867,
              46.981445
            ],
            [
              -107.89515,
              46.980766
            ],
            [
              -107.891388,
              46.979272
            ],
            [
              -107.889396,
              46.978879
            ],
            [
              -107.894097,
              46.977799
            ],
            [
              -107.901231,
              46.972985
            ],
            [
              -107.90588,
              46.973206
            ],
            [
              -107.910418,
              46.971193
            ],
            [
              -107.913477,
              46.96857
            ],
            [
              -107.911586,
              46.969063
            ],
            [
              -107.90851,
              46.965827
            ],
            [
              -107.905867,
              46.964262
            ],
            [
              -107.907161,
              46.96171
            ],
            [
              -107.908953,
              46.961995
            ],
            [
              -107.91093,
              46.95947
            ],
            [
              -107.909381,
              46.956982
            ],
            [
              -107.90527,
              46.954476
            ],
            [
              -107.90876,
              46.953136
            ],
            [
              -107.912454,
              46.950825
            ],
            [
              -107.916595,
              46.944265
            ],
            [
              -107.913208,
              46.940798
            ],
            [
              -107.908289,
              46.939062
            ],
            [
              -107.905841,
              46.936823
            ],
            [
              -107.90997,
              46.936255
            ],
            [
              -107.917045,
              46.937082
            ],
            [
              -107.92367,
              46.935107
            ],
            [
              -107.925189,
              46.931742
            ],
            [
              -107.923358,
              46.931485
            ],
            [
              -107.918896,
              46.930179
            ],
            [
              -107.915518,
              46.930392
            ],
            [
              -107.916158,
              46.928491
            ],
            [
              -107.920108,
              46.92865
            ],
            [
              -107.924204,
              46.928133
            ],
            [
              -107.926249,
              46.925834
            ],
            [
              -107.926458,
              46.923892
            ],
            [
              -107.923778,
              46.922796
            ],
            [
              -107.921169,
              46.921857
            ],
            [
              -107.917781,
              46.919865
            ],
            [
              -107.920757,
              46.917484
            ],
            [
              -107.925986,
              46.917529
            ],
            [
              -107.929136,
              46.918146
            ],
            [
              -107.933181,
              46.913287
            ],
            [
              -107.930001,
              46.910239
            ],
            [
              -107.933762,
              46.903778
            ],
            [
              -107.931373,
              46.901435
            ],
            [
              -107.931188,
              46.897352
            ],
            [
              -107.935539,
              46.895866
            ],
            [
              -107.941377,
              46.893723
            ],
            [
              -107.940461,
              46.891227
            ],
            [
              -107.938405,
              46.890133
            ],
            [
              -107.938282,
              46.885366
            ],
            [
              -107.941213,
              46.881946
            ],
            [
              -107.939716,
              46.877671
            ],
            [
              -107.933282,
              46.875082
            ],
            [
              -107.930808,
              46.877427
            ],
            [
              -107.921213,
              46.87561
            ],
            [
              -107.918093,
              46.875741
            ],
            [
              -107.917945,
              46.872161
            ],
            [
              -107.9199,
              46.86954
            ],
            [
              -107.916942,
              46.86687
            ],
            [
              -107.911045,
              46.865727
            ],
            [
              -107.903332,
              46.865376
            ],
            [
              -107.901044,
              46.862442
            ],
            [
              -107.90518,
              46.859929
            ],
            [
              -107.906537,
              46.855888
            ],
            [
              -107.902161,
              46.852838
            ],
            [
              -107.896772,
              46.853561
            ],
            [
              -107.89173,
              46.852031
            ],
            [
              -107.892233,
              46.851215
            ],
            [
              -107.46777,
              46.85116
            ],
            [
              -107.467748,
              46.859254
            ],
            [
              -106.720157,
              46.859603
            ],
            [
              -106.720237,
              46.845176
            ],
            [
              -106.086463,
              46.845881
            ],
            [
              -106.086585,
              46.86029
            ],
            [
              -106.086777,
              47.180938
            ],
            [
              -106.17042,
              47.180826
            ],
            [
              -106.170675,
              47.354495
            ],
            [
              -106.298158,
              47.354298
            ],
            [
              -106.298073,
              47.529041
            ],
            [
              -106.261121,
              47.529052
            ],
            [
              -106.260932,
              47.868431
            ],
            [
              -106.358085,
              47.868412
            ],
            [
              -106.358288,
              47.957216
            ],
            [
              -106.41862,
              47.957457
            ],
            [
              -106.433499,
              47.964883
            ],
            [
              -106.428958,
              47.976176
            ],
            [
              -106.447421,
              47.98286
            ],
            [
              -106.452414,
              47.970419
            ],
            [
              -106.501725,
              47.958287
            ],
            [
              -106.508854,
              47.946536
            ],
            [
              -106.4887,
              47.932577
            ],
            [
              -106.498523,
              47.914524
            ],
            [
              -106.53081,
              47.903562
            ],
            [
              -106.510062,
              47.891155
            ],
            [
              -106.510321,
              47.878996
            ],
            [
              -106.530671,
              47.880821
            ],
            [
              -106.543318,
              47.874915
            ],
            [
              -106.527141,
              47.860976
            ],
            [
              -106.529265,
              47.850023
            ],
            [
              -106.56322,
              47.840718
            ],
            [
              -106.557488,
              47.828581
            ],
            [
              -106.571003,
              47.812933
            ],
            [
              -106.610188,
              47.804905
            ],
            [
              -106.603126,
              47.784743
            ],
            [
              -106.609813,
              47.777234
            ],
            [
              -106.621789,
              47.790855
            ],
            [
              -106.653028,
              47.790264
            ],
            [
              -106.661761,
              47.771737
            ],
            [
              -106.677435,
              47.775913
            ],
            [
              -106.711969,
              47.772113
            ],
            [
              -106.711442,
              47.755243
            ],
            [
              -106.734453,
              47.734156
            ],
            [
              -106.760188,
              47.731453
            ],
            [
              -106.7857,
              47.742507
            ],
            [
              -106.834171,
              47.743398
            ],
            [
              -106.861668,
              47.737372
            ],
            [
              -106.879223,
              47.718095
            ],
            [
              -106.86991,
              47.702268
            ],
            [
              -106.881162,
              47.678128
            ],
            [
              -106.892753,
              47.674818
            ],
            [
              -106.925557,
              47.66947
            ],
            [
              -106.94455,
              47.676289
            ],
            [
              -106.960755,
              47.672158
            ],
            [
              -106.999227,
              47.67366
            ],
            [
              -107.011358,
              47.663111
            ],
            [
              -107.070514,
              47.662396
            ],
            [
              -107.114109,
              47.669026
            ],
            [
              -107.158817,
              47.667788
            ],
            [
              -107.175088,
              47.66191
            ],
            [
              -107.249,
              47.661566
            ],
            [
              -107.284283,
              47.680446
            ],
            [
              -107.331757,
              47.680685
            ],
            [
              -107.360231,
              47.691218
            ],
            [
              -107.414886,
              47.691734
            ],
            [
              -107.428086,
              47.677316
            ],
            [
              -107.419745,
              47.662295
            ],
            [
              -107.439116,
              47.646778
            ],
            [
              -107.440064,
              47.625462
            ],
            [
              -107.451508,
              47.622141
            ],
            [
              -107.499344,
              47.624005
            ],
            [
              -107.520788,
              47.642919
            ],
            [
              -107.553672,
              47.646348
            ],
            [
              -107.567572,
              47.642964
            ],
            [
              -107.59425,
              47.649731
            ],
            [
              -107.610852,
              47.647369
            ],
            [
              -107.617108,
              47.63156
            ],
            [
              -107.637578,
              47.648806
            ],
            [
              -107.654416,
              47.652563
            ],
            [
              -107.646679,
              47.629444
            ],
            [
              -107.661986,
              47.620891
            ],
            [
              -107.671173,
              47.629892
            ],
            [
              -107.694687,
              47.629762
            ],
            [
              -107.709522,
              47.60026
            ],
            [
              -107.731521,
              47.58541
            ],
            [
              -107.732268,
              47.571874
            ],
            [
              -107.76311,
              47.563432
            ],
            [
              -107.761921,
              47.54805
            ],
            [
              -107.777865,
              47.543647
            ],
            [
              -107.792844,
              47.553222
            ],
            [
              -107.796198,
              47.545886
            ],
            [
              -107.817189,
              47.546282
            ],
            [
              -107.839431,
              47.534405
            ],
            [
              -107.842235,
              47.524906
            ],
            [
              -107.864922,
              47.51374
            ],
            [
              -107.850475,
              47.491947
            ],
            [
              -107.866219,
              47.482543
            ],
            [
              -107.863544,
              47.456052
            ],
            [
              -107.90912,
              47.450692
            ],
            [
              -107.916455,
              47.446843
            ],
            [
              -107.920493,
              47.443979
            ],
            [
              -107.918077,
              47.443687
            ],
            [
              -107.915091,
              47.440166
            ],
            [
              -107.918459,
              47.439699
            ],
            [
              -107.91901,
              47.437765
            ],
            [
              -107.918085,
              47.436793
            ],
            [
              -107.916414,
              47.43346
            ],
            [
              -107.915144,
              47.433785
            ],
            [
              -107.910397,
              47.430144
            ],
            [
              -107.912928,
              47.424496
            ],
            [
              -107.91004,
              47.422116
            ],
            [
              -107.911464,
              47.417358
            ],
            [
              -107.915748,
              47.417015
            ],
            [
              -107.918274,
              47.418277
            ],
            [
              -107.91263,
              47.413871
            ],
            [
              -107.910678,
              47.410806
            ],
            [
              -107.910573,
              47.404759
            ],
            [
              -107.914865,
              47.40143
            ],
            [
              -107.919948,
              47.397259
            ],
            [
              -107.924714,
              47.389561
            ],
            [
              -107.928618,
              47.386672
            ],
            [
              -107.936084,
              47.387217
            ],
            [
              -107.93639,
              47.385793
            ],
            [
              -107.932876,
              47.383492
            ],
            [
              -107.930241,
              47.380814
            ],
            [
              -107.932582,
              47.376578
            ],
            [
              -107.936739,
              47.377275
            ],
            [
              -107.942414,
              47.377486
            ],
            [
              -107.940227,
              47.373846
            ],
            [
              -107.940821,
              47.371443
            ],
            [
              -107.93992,
              47.369576
            ],
            [
              -107.942546,
              47.366287
            ],
            [
              -107.948414,
              47.364358
            ],
            [
              -107.953328,
              47.362045
            ],
            [
              -107.955192,
              47.359037
            ],
            [
              -107.952589,
              47.358002
            ],
            [
              -107.954703,
              47.354737
            ],
            [
              -107.958578,
              47.355073
            ],
            [
              -107.962329,
              47.352184
            ],
            [
              -107.960637,
              47.348894
            ],
            [
              -107.956315,
              47.347842
            ],
            [
              -107.951997,
              47.343924
            ],
            [
              -107.954745,
              47.341997
            ],
            [
              -107.95595,
              47.33558
            ],
            [
              -107.961633,
              47.332335
            ],
            [
              -107.96299,
              47.330394
            ],
            [
              -107.958229,
              47.326666
            ],
            [
              -107.95503,
              47.324241
            ],
            [
              -107.956833,
              47.323194
            ],
            [
              -107.957625,
              47.318497
            ],
            [
              -107.953752,
              47.316972
            ],
            [
              -107.954266,
              47.319276
            ],
            [
              -107.955178,
              47.320294
            ],
            [
              -107.951861,
              47.320383
            ],
            [
              -107.944086,
              47.321468
            ],
            [
              -107.941465,
              47.321467
            ],
            [
              -107.937032,
              47.320317
            ],
            [
              -107.937042,
              47.318321
            ],
            [
              -107.940068,
              47.31735
            ],
            [
              -107.948086,
              47.315937
            ],
            [
              -107.956414,
              47.309333
            ],
            [
              -107.954329,
              47.303246
            ],
            [
              -107.950232,
              47.302396
            ],
            [
              -107.948894,
              47.302851
            ],
            [
              -107.949192,
              47.299374
            ],
            [
              -107.949867,
              47.29646
            ],
            [
              -107.952627,
              47.295159
            ],
            [
              -107.955815,
              47.296072
            ],
            [
              -107.960501,
              47.293903
            ],
            [
              -107.961207,
              47.292114
            ],
            [
              -107.963167,
              47.290191
            ],
            [
              -107.95956,
              47.289027
            ],
            [
              -107.952832,
              47.288089
            ],
            [
              -107.949578,
              47.28385
            ],
            [
              -107.953411,
              47.284413
            ],
            [
              -107.959175,
              47.281949
            ],
            [
              -107.963456,
              47.277507
            ],
            [
              -107.964331,
              47.276036
            ],
            [
              -107.961732,
              47.274354
            ],
            [
              -107.958719,
              47.274837
            ],
            [
              -107.95267,
              47.272862
            ],
            [
              -107.953369,
              47.270335
            ],
            [
              -107.953959,
              47.26687
            ],
            [
              -107.956204,
              47.26846
            ],
            [
              -107.956036,
              47.268978
            ],
            [
              -107.955678,
              47.269404
            ],
            [
              -107.958679,
              47.268472
            ],
            [
              -107.961339,
              47.267596
            ],
            [
              -107.963121,
              47.263516
            ],
            [
              -107.956686,
              47.260271
            ],
            [
              -107.951719,
              47.259638
            ],
            [
              -107.947927,
              47.257824
            ],
            [
              -107.949363,
              47.256322
            ],
            [
              -107.950731,
              47.25474
            ],
            [
              -107.955704,
              47.255142
            ],
            [
              -107.958827,
              47.257018
            ],
            [
              -107.964158,
              47.254881
            ],
            [
              -107.966521,
              47.252695
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "051",
      "COUNTYNS": "01720036",
      "AFFGEOID": "0500000US30051",
      "GEOID": "30051",
      "NAME": "Liberty",
      "LSAD": "06",
      "ALAND": 3703639185,
      "AWATER": 44411469,
      "County_state": "Liberty,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -111.409018,
              48.219542
            ],
            [
              -111.409097,
              48.132218
            ],
            [
              -110.827168,
              48.133205
            ],
            [
              -110.827413,
              48.219566
            ],
            [
              -110.755873,
              48.219601
            ],
            [
              -110.750113,
              48.566516
            ],
            [
              -110.742698,
              48.914089
            ],
            [
              -110.743064,
              48.998557502115595
            ],
            [
              -110.886706,
              48.998124
            ],
            [
              -110.887459,
              48.998087
            ],
            [
              -111.003916,
              48.997537
            ],
            [
              -111.26986200082901,
              48.997229786811594
            ],
            [
              -111.269957,
              48.916991
            ],
            [
              -111.273311,
              48.914078
            ],
            [
              -111.275529,
              48.566445
            ],
            [
              -111.275306,
              48.219531
            ],
            [
              -111.409018,
              48.219542
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "037",
      "COUNTYNS": "01723078",
      "AFFGEOID": "0500000US30037",
      "GEOID": "30037",
      "NAME": "Golden Valley",
      "LSAD": "06",
      "ALAND": 3041740596,
      "AWATER": 2621792,
      "County_state": "Golden Valley,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -109.653871,
              46.219404
            ],
            [
              -109.65384,
              46.132465
            ],
            [
              -109.60419,
              46.132484
            ],
            [
              -109.605153,
              46.044994
            ],
            [
              -109.417158,
              46.044759
            ],
            [
              -109.416227,
              46.132371
            ],
            [
              -108.924589,
              46.13237
            ],
            [
              -108.779733,
              46.132457
            ],
            [
              -108.779272,
              46.277398
            ],
            [
              -108.800141,
              46.277405
            ],
            [
              -108.800203,
              46.306441
            ],
            [
              -108.821129,
              46.306456
            ],
            [
              -108.821158,
              46.321013
            ],
            [
              -108.83161,
              46.320999
            ],
            [
              -108.831262,
              46.350091
            ],
            [
              -108.841724,
              46.350064
            ],
            [
              -108.841331,
              46.364563
            ],
            [
              -108.862277,
              46.364586
            ],
            [
              -108.860801,
              46.451665
            ],
            [
              -108.881795,
              46.45173
            ],
            [
              -108.880114,
              46.489737
            ],
            [
              -108.905259,
              46.489805
            ],
            [
              -108.904904,
              46.576908
            ],
            [
              -108.988995,
              46.577851
            ],
            [
              -108.988727,
              46.62124
            ],
            [
              -109.009982,
              46.621172
            ],
            [
              -109.00957,
              46.750702
            ],
            [
              -109.389208,
              46.751351
            ],
            [
              -109.389092,
              46.693625
            ],
            [
              -109.389163,
              46.693625
            ],
            [
              -109.388548,
              46.490638
            ],
            [
              -109.403544,
              46.490657
            ],
            [
              -109.402906,
              46.219706
            ],
            [
              -109.653871,
              46.219404
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "001",
      "COUNTYNS": "01719627",
      "AFFGEOID": "0500000US30001",
      "GEOID": "30001",
      "NAME": "Beaverhead",
      "LSAD": "06",
      "ALAND": 14355492113,
      "AWATER": 78345297,
      "County_state": "Beaverhead,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -113.942693894537,
              45.6864080223607
            ],
            [
              -113.93422,
              45.682232
            ],
            [
              -113.930403,
              45.671878
            ],
            [
              -113.919752,
              45.658536
            ],
            [
              -113.903582,
              45.651165
            ],
            [
              -113.900588,
              45.648259
            ],
            [
              -113.898883,
              45.644167
            ],
            [
              -113.902539,
              45.636945
            ],
            [
              -113.904691,
              45.622007
            ],
            [
              -113.886006,
              45.61702
            ],
            [
              -113.861404,
              45.62366
            ],
            [
              -113.823068,
              45.612486
            ],
            [
              -113.806729,
              45.602146
            ],
            [
              -113.802955,
              45.592631
            ],
            [
              -113.803261,
              45.584193
            ],
            [
              -113.804796,
              45.580358
            ],
            [
              -113.819868,
              45.566326
            ],
            [
              -113.834555,
              45.520729
            ],
            [
              -113.809144,
              45.519908
            ],
            [
              -113.802849,
              45.523159
            ],
            [
              -113.796579,
              45.523462
            ],
            [
              -113.778361,
              45.523415
            ],
            [
              -113.766022,
              45.520621
            ],
            [
              -113.759986,
              45.480735
            ],
            [
              -113.78416,
              45.454946
            ],
            [
              -113.783272,
              45.451839
            ],
            [
              -113.764591,
              45.431403
            ],
            [
              -113.763368,
              45.427732
            ],
            [
              -113.768058,
              45.418147
            ],
            [
              -113.765203,
              45.410601
            ],
            [
              -113.760924,
              45.406501
            ],
            [
              -113.750546,
              45.40272
            ],
            [
              -113.734402,
              45.392353
            ],
            [
              -113.733092,
              45.390173
            ],
            [
              -113.73239,
              45.385058
            ],
            [
              -113.73553,
              45.364738
            ],
            [
              -113.7402,
              45.34559
            ],
            [
              -113.738729,
              45.329741
            ],
            [
              -113.735601,
              45.325265
            ],
            [
              -113.689359,
              45.28355
            ],
            [
              -113.688077,
              45.276407
            ],
            [
              -113.691557,
              45.270912
            ],
            [
              -113.692039,
              45.265191
            ],
            [
              -113.684946,
              45.253706
            ],
            [
              -113.678749,
              45.24927
            ],
            [
              -113.674409,
              45.249411
            ],
            [
              -113.665633,
              45.246265
            ],
            [
              -113.657027,
              45.241436
            ],
            [
              -113.650064,
              45.23471
            ],
            [
              -113.647399,
              45.228282
            ],
            [
              -113.636889,
              45.212983
            ],
            [
              -113.599506,
              45.191114
            ],
            [
              -113.589891,
              45.176986
            ],
            [
              -113.594632,
              45.166034
            ],
            [
              -113.57467,
              45.128411
            ],
            [
              -113.554744,
              45.112901
            ],
            [
              -113.546488,
              45.112285
            ],
            [
              -113.538037,
              45.11503
            ],
            [
              -113.513342,
              45.115225
            ],
            [
              -113.506638,
              45.107288
            ],
            [
              -113.510819,
              45.099902
            ],
            [
              -113.520134,
              45.093033
            ],
            [
              -113.485278,
              45.063519
            ],
            [
              -113.47377,
              45.0617
            ],
            [
              -113.465073,
              45.062755
            ],
            [
              -113.460578,
              45.064879
            ],
            [
              -113.45197,
              45.059247
            ],
            [
              -113.44912,
              45.046098
            ],
            [
              -113.449909,
              45.035167
            ],
            [
              -113.437726,
              45.006967
            ],
            [
              -113.446884,
              44.998545
            ],
            [
              -113.447013,
              44.984637
            ],
            [
              -113.444862,
              44.976141
            ],
            [
              -113.443782,
              44.95989
            ],
            [
              -113.448958,
              44.953544
            ],
            [
              -113.467467,
              44.948061
            ],
            [
              -113.474781,
              44.948795
            ],
            [
              -113.480836,
              44.95031
            ],
            [
              -113.494446,
              44.948597
            ],
            [
              -113.498745,
              44.942314
            ],
            [
              -113.491121,
              44.927548
            ],
            [
              -113.474573,
              44.910846
            ],
            [
              -113.455071,
              44.865424
            ],
            [
              -113.422376,
              44.842595
            ],
            [
              -113.383984,
              44.8374
            ],
            [
              -113.377153,
              44.834858
            ],
            [
              -113.356062,
              44.819798
            ],
            [
              -113.3461,
              44.800611
            ],
            [
              -113.346692,
              44.798898
            ],
            [
              -113.354763,
              44.795468
            ],
            [
              -113.354034,
              44.791745
            ],
            [
              -113.341704,
              44.784853
            ],
            [
              -113.301508,
              44.798985
            ],
            [
              -113.29683,
              44.803358
            ],
            [
              -113.278382,
              44.812706
            ],
            [
              -113.247166,
              44.82295
            ],
            [
              -113.238729,
              44.814144
            ],
            [
              -113.209624,
              44.80907
            ],
            [
              -113.19436,
              44.802151
            ],
            [
              -113.183395,
              44.793565
            ],
            [
              -113.179366,
              44.787142
            ],
            [
              -113.163806,
              44.778921
            ],
            [
              -113.158206,
              44.780847
            ],
            [
              -113.140618,
              44.776698
            ],
            [
              -113.131453,
              44.772837
            ],
            [
              -113.131387,
              44.764738
            ],
            [
              -113.137704,
              44.760109
            ],
            [
              -113.134824,
              44.752763
            ],
            [
              -113.116874,
              44.738104
            ],
            [
              -113.102138,
              44.729027
            ],
            [
              -113.101154,
              44.708578
            ],
            [
              -113.098064,
              44.697477
            ],
            [
              -113.081906,
              44.691392
            ],
            [
              -113.06776,
              44.679474
            ],
            [
              -113.067756,
              44.672807
            ],
            [
              -113.07042,
              44.667844
            ],
            [
              -113.068306,
              44.656374
            ],
            [
              -113.065589,
              44.649371
            ],
            [
              -113.051504,
              44.63695
            ],
            [
              -113.049349,
              44.62938
            ],
            [
              -113.053529,
              44.621187
            ],
            [
              -113.05677,
              44.618657
            ],
            [
              -113.065593,
              44.617391
            ],
            [
              -113.07376,
              44.613928
            ],
            [
              -113.083819,
              44.60222
            ],
            [
              -113.082483,
              44.59569
            ],
            [
              -113.070356,
              44.590132
            ],
            [
              -113.061071,
              44.577329
            ],
            [
              -113.037512,
              44.564362
            ],
            [
              -113.038522,
              44.558551
            ],
            [
              -113.046102,
              44.550972
            ],
            [
              -113.04235,
              44.54215
            ],
            [
              -113.007194,
              44.52596
            ],
            [
              -113.006828,
              44.518439
            ],
            [
              -113.017047,
              44.50398
            ],
            [
              -113.026148,
              44.497016
            ],
            [
              -113.01864,
              44.481673
            ],
            [
              -113.006846,
              44.471715
            ],
            [
              -113.003544,
              44.450814
            ],
            [
              -112.981682,
              44.434279
            ],
            [
              -112.951146,
              44.416699
            ],
            [
              -112.915602,
              44.402699
            ],
            [
              -112.886041,
              44.395874
            ],
            [
              -112.881769,
              44.380315
            ],
            [
              -112.855395,
              44.359975
            ],
            [
              -112.844859,
              44.358221
            ],
            [
              -112.820489,
              44.370946
            ],
            [
              -112.814350040863,
              44.3770070471164
            ],
            [
              -112.81324,
              44.378103
            ],
            [
              -112.812608,
              44.392275
            ],
            [
              -112.821896,
              44.407436
            ],
            [
              -112.836034,
              44.422653
            ],
            [
              -112.828191,
              44.442472
            ],
            [
              -112.797863,
              44.466112
            ],
            [
              -112.781294,
              44.484888
            ],
            [
              -112.749011,
              44.491233
            ],
            [
              -112.735084,
              44.499159
            ],
            [
              -112.71911,
              44.504344
            ],
            [
              -112.707815,
              44.503023
            ],
            [
              -112.671169,
              44.491265
            ],
            [
              -112.664485,
              44.48645
            ],
            [
              -112.660696,
              44.485756
            ],
            [
              -112.601863,
              44.491015
            ],
            [
              -112.584197,
              44.481368
            ],
            [
              -112.573513,
              44.480983
            ],
            [
              -112.550557,
              44.484928
            ],
            [
              -112.541989,
              44.483971
            ],
            [
              -112.518871,
              44.475784
            ],
            [
              -112.512036,
              44.47042
            ],
            [
              -112.511713,
              44.466445
            ],
            [
              -112.50031,
              44.463051
            ],
            [
              -112.473207,
              44.480027
            ],
            [
              -112.460347,
              44.47571
            ],
            [
              -112.435342,
              44.462216
            ],
            [
              -112.387389,
              44.448058
            ],
            [
              -112.368764,
              44.467153
            ],
            [
              -112.358926,
              44.48628
            ],
            [
              -112.3566,
              44.493127
            ],
            [
              -112.358917,
              44.528847
            ],
            [
              -112.35421,
              44.535638
            ],
            [
              -112.348794,
              44.538691
            ],
            [
              -112.319198,
              44.53911
            ],
            [
              -112.315008,
              44.5419
            ],
            [
              -112.315047,
              44.550049
            ],
            [
              -112.312899,
              44.553536
            ],
            [
              -112.307642,
              44.557651
            ],
            [
              -112.286187,
              44.568472
            ],
            [
              -112.258665,
              44.569516
            ],
            [
              -112.242785,
              44.568091
            ],
            [
              -112.230117,
              44.562759
            ],
            [
              -112.226841,
              44.555239
            ],
            [
              -112.229477,
              44.549494
            ],
            [
              -112.221698,
              44.543519
            ],
            [
              -112.183937,
              44.533067
            ],
            [
              -112.179703,
              44.533021
            ],
            [
              -112.164597,
              44.541666
            ],
            [
              -112.136454,
              44.539911
            ],
            [
              -112.129078,
              44.5363
            ],
            [
              -112.125101,
              44.528527
            ],
            [
              -112.106755,
              44.520829
            ],
            [
              -112.101564,
              44.520847
            ],
            [
              -112.096299,
              44.523212
            ],
            [
              -112.093304,
              44.530002
            ],
            [
              -112.069011,
              44.537104
            ],
            [
              -112.053434,
              44.535089
            ],
            [
              -112.036943,
              44.530323
            ],
            [
              -112.034133,
              44.537716
            ],
            [
              -112.035025,
              44.542691
            ],
            [
              -112.032707,
              44.546642
            ],
            [
              -112.01385,
              44.542348
            ],
            [
              -111.995231,
              44.535444
            ],
            [
              -111.980833,
              44.536682
            ],
            [
              -111.951522,
              44.550062
            ],
            [
              -111.947941,
              44.556776
            ],
            [
              -111.903566,
              44.55723
            ],
            [
              -111.887852,
              44.563413
            ],
            [
              -111.870504,
              44.564033
            ],
            [
              -111.849293,
              44.539837
            ],
            [
              -111.842542,
              44.526069
            ],
            [
              -111.821488,
              44.509286
            ],
            [
              -111.807914,
              44.511716
            ],
            [
              -111.806512,
              44.516264
            ],
            [
              -111.761904,
              44.529841
            ],
            [
              -111.758966,
              44.533766
            ],
            [
              -111.746401,
              44.540766
            ],
            [
              -111.737191,
              44.54306
            ],
            [
              -111.715474,
              44.543543
            ],
            [
              -111.709553,
              44.550206
            ],
            [
              -111.704218,
              44.560205
            ],
            [
              -111.681571,
              44.559864
            ],
            [
              -111.61729585677601,
              44.5494589791222
            ],
            [
              -111.614405,
              44.548991
            ],
            [
              -111.601249,
              44.55421
            ],
            [
              -111.591768,
              44.561502
            ],
            [
              -111.585763,
              44.562843
            ],
            [
              -111.562814,
              44.555209
            ],
            [
              -111.556577,
              44.554495
            ],
            [
              -111.546637,
              44.557099
            ],
            [
              -111.524006,
              44.548385
            ],
            [
              -111.518095,
              44.544177
            ],
            [
              -111.500792,
              44.540062
            ],
            [
              -111.471682,
              44.540824
            ],
            [
              -111.467736,
              44.544521
            ],
            [
              -111.469185,
              44.552044
            ],
            [
              -111.492024,
              44.56081
            ],
            [
              -111.519126,
              44.582916
            ],
            [
              -111.524213,
              44.595585
            ],
            [
              -111.525764,
              44.604883
            ],
            [
              -111.521688,
              44.613371
            ],
            [
              -111.50494,
              44.635746
            ],
            [
              -111.473178,
              44.665479
            ],
            [
              -111.468833,
              44.679335
            ],
            [
              -111.47798,
              44.682393
            ],
            [
              -111.484898,
              44.687578
            ],
            [
              -111.490228,
              44.700221
            ],
            [
              -111.489339,
              44.704946
            ],
            [
              -111.486019,
              44.707654
            ],
            [
              -111.482206819713,
              44.7086946688743
            ],
            [
              -111.781099,
              44.708845
            ],
            [
              -111.781157,
              44.781027
            ],
            [
              -112.025141,
              44.782112
            ],
            [
              -112.065797,
              44.783475
            ],
            [
              -112.065678,
              44.825985
            ],
            [
              -112.167108,
              44.826738
            ],
            [
              -112.166986,
              44.912587
            ],
            [
              -112.190634,
              44.912735
            ],
            [
              -112.191187,
              45.000512
            ],
            [
              -112.334248,
              45.000519
            ],
            [
              -112.333899,
              45.088423
            ],
            [
              -112.456854,
              45.088509
            ],
            [
              -112.464636,
              45.34991
            ],
            [
              -112.46426,
              45.39382
            ],
            [
              -112.505568,
              45.443009
            ],
            [
              -112.53944,
              45.446534
            ],
            [
              -112.577233,
              45.429838
            ],
            [
              -112.59855,
              45.42943
            ],
            [
              -112.628609,
              45.440586
            ],
            [
              -112.638841,
              45.4551
            ],
            [
              -112.691417,
              45.483518
            ],
            [
              -112.699133,
              45.513612
            ],
            [
              -112.704848,
              45.533232
            ],
            [
              -112.694053,
              45.542756
            ],
            [
              -112.686825,
              45.569294
            ],
            [
              -112.687155,
              45.601005
            ],
            [
              -112.679445,
              45.612427
            ],
            [
              -112.687054,
              45.624854
            ],
            [
              -112.699704,
              45.645018
            ],
            [
              -112.692293,
              45.655676
            ],
            [
              -112.701192,
              45.67654
            ],
            [
              -112.736315,
              45.686198
            ],
            [
              -112.753717,
              45.711934
            ],
            [
              -112.747754,
              45.729421
            ],
            [
              -112.758271,
              45.734084
            ],
            [
              -112.759339,
              45.748209
            ],
            [
              -112.780579,
              45.755554
            ],
            [
              -112.785239,
              45.763659
            ],
            [
              -112.799207,
              45.75876
            ],
            [
              -112.823566,
              45.774252
            ],
            [
              -112.855172,
              45.779797
            ],
            [
              -112.868394,
              45.777761
            ],
            [
              -112.884734,
              45.789258
            ],
            [
              -112.902475,
              45.780303
            ],
            [
              -112.927058,
              45.801387
            ],
            [
              -112.945329,
              45.799029
            ],
            [
              -112.988358,
              45.808476
            ],
            [
              -112.999274,
              45.807347
            ],
            [
              -113.002041,
              45.815887
            ],
            [
              -113.024696,
              45.829739
            ],
            [
              -113.085517,
              45.860648
            ],
            [
              -113.100273,
              45.881717
            ],
            [
              -113.178277,
              45.885226
            ],
            [
              -113.19694,
              45.874555
            ],
            [
              -113.228838,
              45.866457
            ],
            [
              -113.255046,
              45.845582
            ],
            [
              -113.249978,
              45.839614
            ],
            [
              -113.275418,
              45.817973
            ],
            [
              -113.298169,
              45.81861
            ],
            [
              -113.308299,
              45.806389
            ],
            [
              -113.335912,
              45.81324
            ],
            [
              -113.346235,
              45.805143
            ],
            [
              -113.364429,
              45.771036
            ],
            [
              -113.362945,
              45.758021
            ],
            [
              -113.377852,
              45.759976
            ],
            [
              -113.395063,
              45.76649
            ],
            [
              -113.434945,
              45.806614
            ],
            [
              -113.429665,
              45.83108
            ],
            [
              -113.441418,
              45.855822
            ],
            [
              -113.468014,
              45.885971
            ],
            [
              -113.495527,
              45.936184
            ],
            [
              -113.516144,
              45.938814
            ],
            [
              -113.535109,
              45.913198
            ],
            [
              -113.53655,
              45.893877
            ],
            [
              -113.528581,
              45.88116
            ],
            [
              -113.568151,
              45.871916
            ],
            [
              -113.5914,
              45.862898
            ],
            [
              -113.592215,
              45.850224
            ],
            [
              -113.610954,
              45.850455
            ],
            [
              -113.622542,
              45.841305
            ],
            [
              -113.654693,
              45.840373
            ],
            [
              -113.667875,
              45.835373
            ],
            [
              -113.697639,
              45.843545
            ],
            [
              -113.712522,
              45.838384
            ],
            [
              -113.729448,
              45.819538
            ],
            [
              -113.744046,
              45.815074
            ],
            [
              -113.777981,
              45.792129
            ],
            [
              -113.791792,
              45.792216
            ],
            [
              -113.787101,
              45.778497
            ],
            [
              -113.804353,
              45.763062
            ],
            [
              -113.829875,
              45.750746
            ],
            [
              -113.849397,
              45.75278
            ],
            [
              -113.854917,
              45.773141
            ],
            [
              -113.870452,
              45.781574
            ],
            [
              -113.896496,
              45.769723
            ],
            [
              -113.916267,
              45.746133
            ],
            [
              -113.904489,
              45.730735
            ],
            [
              -113.911328,
              45.698877
            ],
            [
              -113.922306,
              45.703976
            ],
            [
              -113.942693894537,
              45.6864080223607
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "107",
      "COUNTYNS": "01719560",
      "AFFGEOID": "0500000US30107",
      "GEOID": "30107",
      "NAME": "Wheatland",
      "LSAD": "06",
      "ALAND": 3684208406,
      "AWATER": 13010925,
      "County_state": "Wheatland,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -110.281471,
              46.489826
            ],
            [
              -110.281971,
              46.220605
            ],
            [
              -109.653871,
              46.219404
            ],
            [
              -109.402906,
              46.219706
            ],
            [
              -109.403544,
              46.490657
            ],
            [
              -109.388548,
              46.490638
            ],
            [
              -109.389163,
              46.693625
            ],
            [
              -109.745745,
              46.693593
            ],
            [
              -109.745745,
              46.694097
            ],
            [
              -109.819866,
              46.693446
            ],
            [
              -109.845162,
              46.710814
            ],
            [
              -109.857791,
              46.708259
            ],
            [
              -109.895758,
              46.725927
            ],
            [
              -109.936151,
              46.737808
            ],
            [
              -109.969221,
              46.752909
            ],
            [
              -110.028992,
              46.736207
            ],
            [
              -110.051525,
              46.749393
            ],
            [
              -110.086131,
              46.746895
            ],
            [
              -110.115707,
              46.73887
            ],
            [
              -110.127177,
              46.718932
            ],
            [
              -110.157346,
              46.716396
            ],
            [
              -110.191562,
              46.725631
            ],
            [
              -110.210758,
              46.708503
            ],
            [
              -110.273337,
              46.710509
            ],
            [
              -110.272998,
              46.48983
            ],
            [
              -110.281471,
              46.489826
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "049",
      "COUNTYNS": "00793926",
      "AFFGEOID": "0500000US30049",
      "GEOID": "30049",
      "NAME": "Lewis and Clark",
      "LSAD": "06",
      "ALAND": 8957160027,
      "AWATER": 101500820,
      "County_state": "Lewis and Clark,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -113.164119,
              47.730023
            ],
            [
              -113.165811,
              47.719764
            ],
            [
              -113.146412,
              47.676472
            ],
            [
              -113.12847,
              47.661047
            ],
            [
              -113.132619,
              47.639194
            ],
            [
              -113.144481,
              47.637216
            ],
            [
              -113.135758,
              47.620321
            ],
            [
              -113.146287,
              47.607507
            ],
            [
              -113.144368,
              47.595507
            ],
            [
              -113.117746,
              47.570473
            ],
            [
              -113.124661,
              47.55797
            ],
            [
              -113.123098,
              47.51573
            ],
            [
              -113.11455,
              47.490409
            ],
            [
              -113.091448,
              47.480427
            ],
            [
              -113.059324,
              47.488229
            ],
            [
              -113.059053,
              47.17957
            ],
            [
              -112.795808,
              47.177004
            ],
            [
              -112.795782,
              46.93943
            ],
            [
              -112.795642,
              46.831665
            ],
            [
              -112.668246,
              46.831613
            ],
            [
              -112.535748,
              46.834234
            ],
            [
              -112.535597,
              46.699482
            ],
            [
              -112.410846,
              46.699505
            ],
            [
              -112.410638,
              46.656011
            ],
            [
              -112.320871,
              46.655927
            ],
            [
              -112.29822,
              46.618092
            ],
            [
              -112.293205,
              46.605089
            ],
            [
              -112.316754,
              46.574418
            ],
            [
              -112.307773,
              46.549338
            ],
            [
              -112.293709,
              46.531128
            ],
            [
              -112.293341,
              46.517915
            ],
            [
              -112.30629,
              46.510393
            ],
            [
              -112.301847,
              46.489877
            ],
            [
              -112.304662,
              46.449908
            ],
            [
              -112.321199,
              46.443353
            ],
            [
              -112.323443,
              46.43331
            ],
            [
              -112.310573,
              46.421171
            ],
            [
              -112.287028,
              46.421879
            ],
            [
              -112.268397,
              46.414961
            ],
            [
              -112.242578,
              46.422525
            ],
            [
              -112.220222,
              46.436169
            ],
            [
              -112.215251,
              46.450423
            ],
            [
              -112.18849,
              46.450476
            ],
            [
              -112.176235,
              46.456342
            ],
            [
              -112.179743,
              46.470966
            ],
            [
              -112.203016,
              46.479642
            ],
            [
              -112.205757,
              46.499748
            ],
            [
              -112.183801,
              46.501106
            ],
            [
              -112.151836,
              46.511772
            ],
            [
              -112.135258,
              46.521887
            ],
            [
              -112.120845,
              46.51473
            ],
            [
              -112.083441,
              46.516663
            ],
            [
              -112.044049,
              46.533838
            ],
            [
              -112.033615,
              46.543127
            ],
            [
              -112.033348,
              46.566198
            ],
            [
              -112.019452,
              46.568792
            ],
            [
              -111.817548,
              46.569528
            ],
            [
              -111.787949,
              46.569465
            ],
            [
              -111.631906,
              46.569972
            ],
            [
              -111.640095,
              46.582463
            ],
            [
              -111.639261,
              46.73818
            ],
            [
              -111.624978,
              46.746337
            ],
            [
              -111.602526,
              46.778259
            ],
            [
              -111.587,
              46.781852
            ],
            [
              -111.56733,
              46.776866
            ],
            [
              -111.561771,
              46.766413
            ],
            [
              -111.544694,
              46.769188
            ],
            [
              -111.508887,
              46.759565
            ],
            [
              -111.498095,
              46.762502
            ],
            [
              -111.496029,
              46.790273
            ],
            [
              -111.523814,
              46.817074
            ],
            [
              -111.532301,
              46.804383
            ],
            [
              -111.573536,
              46.810051
            ],
            [
              -111.599121,
              46.807846
            ],
            [
              -111.627579,
              46.83251
            ],
            [
              -111.639502,
              46.832134
            ],
            [
              -111.645739,
              46.843361
            ],
            [
              -111.635437,
              46.850984
            ],
            [
              -111.639154,
              46.865314
            ],
            [
              -111.608529,
              46.878442
            ],
            [
              -111.608678,
              46.892266
            ],
            [
              -111.623864,
              46.902935
            ],
            [
              -111.658132,
              46.913423
            ],
            [
              -111.790236,
              46.913764
            ],
            [
              -111.789278,
              47.128922
            ],
            [
              -111.87488,
              47.128655
            ],
            [
              -111.890969,
              47.121302
            ],
            [
              -111.896343,
              47.123236
            ],
            [
              -111.896286,
              47.135741
            ],
            [
              -111.920137,
              47.135693
            ],
            [
              -111.942139,
              47.143582
            ],
            [
              -111.961708,
              47.169033
            ],
            [
              -111.979252,
              47.173743
            ],
            [
              -111.977382,
              47.183932
            ],
            [
              -111.996733,
              47.185456
            ],
            [
              -112.01016,
              47.17921
            ],
            [
              -112.008461,
              47.190763
            ],
            [
              -112.026356,
              47.19926
            ],
            [
              -112.044311,
              47.192826
            ],
            [
              -112.047781,
              47.516034
            ],
            [
              -112.051983,
              47.515045
            ],
            [
              -112.063359,
              47.51694
            ],
            [
              -112.068007,
              47.513857
            ],
            [
              -112.075347,
              47.512484
            ],
            [
              -112.079187,
              47.515536
            ],
            [
              -112.087636,
              47.515713
            ],
            [
              -112.093494,
              47.51144
            ],
            [
              -112.102974,
              47.511052
            ],
            [
              -112.105604,
              47.508126
            ],
            [
              -112.110886,
              47.50748
            ],
            [
              -112.116641,
              47.50786
            ],
            [
              -112.121429,
              47.511495
            ],
            [
              -112.131162,
              47.512421
            ],
            [
              -112.140311,
              47.514841
            ],
            [
              -112.145119,
              47.516005
            ],
            [
              -112.149973,
              47.514795
            ],
            [
              -112.154387,
              47.512488
            ],
            [
              -112.163172,
              47.510732
            ],
            [
              -112.17263,
              47.51037
            ],
            [
              -112.176438,
              47.513011
            ],
            [
              -112.185151,
              47.510812
            ],
            [
              -112.19163,
              47.507173
            ],
            [
              -112.201767,
              47.505844
            ],
            [
              -112.207539,
              47.502421
            ],
            [
              -112.213005,
              47.503567
            ],
            [
              -112.215898,
              47.507736
            ],
            [
              -112.225182,
              47.507677
            ],
            [
              -112.233527,
              47.507178
            ],
            [
              -112.24377,
              47.50703
            ],
            [
              -112.251861,
              47.510263
            ],
            [
              -112.264307,
              47.508612
            ],
            [
              -112.272216,
              47.507419
            ],
            [
              -112.280139,
              47.506511
            ],
            [
              -112.282973,
              47.510346
            ],
            [
              -112.287413,
              47.515683
            ],
            [
              -112.292777,
              47.522381
            ],
            [
              -112.30224,
              47.52603
            ],
            [
              -112.309252,
              47.52851
            ],
            [
              -112.311927,
              47.535691
            ],
            [
              -112.317216,
              47.541254
            ],
            [
              -112.326861,
              47.543147
            ],
            [
              -112.332962,
              47.542214
            ],
            [
              -112.337427,
              47.539898
            ],
            [
              -112.344719,
              47.541769
            ],
            [
              -112.343162,
              47.545815
            ],
            [
              -112.342669,
              47.549574
            ],
            [
              -112.351299,
              47.550471
            ],
            [
              -112.358452,
              47.549381
            ],
            [
              -112.370777,
              47.546921
            ],
            [
              -112.379571,
              47.54903
            ],
            [
              -112.387699,
              47.552075
            ],
            [
              -112.398388,
              47.552525
            ],
            [
              -112.403816,
              47.552324
            ],
            [
              -112.407949,
              47.556999
            ],
            [
              -112.404638,
              47.560294
            ],
            [
              -112.402853,
              47.565026
            ],
            [
              -112.407224,
              47.570504
            ],
            [
              -112.405076,
              47.574266
            ],
            [
              -112.408199,
              47.578091
            ],
            [
              -112.411945,
              47.577435
            ],
            [
              -112.418633,
              47.582554
            ],
            [
              -112.416691,
              47.58793
            ],
            [
              -112.419136,
              47.592725
            ],
            [
              -112.420876,
              47.599395
            ],
            [
              -112.42913,
              47.603358
            ],
            [
              -112.432711,
              47.606104
            ],
            [
              -112.440203,
              47.606123
            ],
            [
              -112.447445,
              47.60791
            ],
            [
              -112.45472,
              47.610271
            ],
            [
              -112.464873,
              47.611252
            ],
            [
              -112.475005,
              47.614269
            ],
            [
              -112.484593,
              47.615145
            ],
            [
              -112.490045,
              47.613215
            ],
            [
              -112.496797,
              47.615509
            ],
            [
              -112.499154,
              47.617605
            ],
            [
              -112.502314,
              47.620849
            ],
            [
              -112.507007,
              47.6195
            ],
            [
              -112.511327,
              47.621224
            ],
            [
              -112.516068,
              47.624844
            ],
            [
              -112.514738,
              47.629104
            ],
            [
              -112.519945,
              47.633029
            ],
            [
              -112.519679,
              47.638193
            ],
            [
              -112.519253,
              47.641547
            ],
            [
              -112.526957,
              47.641816
            ],
            [
              -112.532063,
              47.64296
            ],
            [
              -112.539375,
              47.646792
            ],
            [
              -112.541976,
              47.646658
            ],
            [
              -112.544047,
              47.647727
            ],
            [
              -112.545231,
              47.650257
            ],
            [
              -112.551193,
              47.65203
            ],
            [
              -112.554478,
              47.65069
            ],
            [
              -112.557075,
              47.646594
            ],
            [
              -112.563129,
              47.642928
            ],
            [
              -112.564052,
              47.639522
            ],
            [
              -112.567537,
              47.637302
            ],
            [
              -112.575,
              47.634767
            ],
            [
              -112.584639,
              47.634468
            ],
            [
              -112.592762,
              47.634933
            ],
            [
              -112.600166,
              47.633022
            ],
            [
              -112.605991,
              47.633548
            ],
            [
              -112.613358,
              47.635648
            ],
            [
              -112.622313,
              47.633527
            ],
            [
              -112.626886,
              47.630178
            ],
            [
              -112.631545,
              47.62731
            ],
            [
              -112.638513,
              47.627226
            ],
            [
              -112.642616,
              47.622493
            ],
            [
              -112.651817,
              47.621642
            ],
            [
              -112.657029,
              47.622584
            ],
            [
              -112.662576,
              47.621838
            ],
            [
              -112.662766,
              47.619359
            ],
            [
              -112.66647,
              47.61826
            ],
            [
              -112.670187,
              47.620575
            ],
            [
              -112.672964,
              47.621639
            ],
            [
              -112.675981,
              47.620733
            ],
            [
              -112.680646,
              47.61866
            ],
            [
              -112.681536,
              47.616768
            ],
            [
              -112.685768,
              47.614876
            ],
            [
              -112.689941,
              47.616837
            ],
            [
              -112.692853,
              47.619656
            ],
            [
              -112.697161,
              47.62224
            ],
            [
              -112.702702,
              47.620912
            ],
            [
              -112.710891,
              47.619327
            ],
            [
              -112.719465,
              47.616151
            ],
            [
              -112.728178,
              47.617462
            ],
            [
              -112.735355,
              47.613553
            ],
            [
              -112.740234,
              47.609482
            ],
            [
              -112.749064,
              47.607733
            ],
            [
              -112.753655,
              47.607151
            ],
            [
              -112.758021,
              47.605631
            ],
            [
              -112.759642,
              47.601848
            ],
            [
              -112.768642,
              47.599077
            ],
            [
              -112.779548,
              47.598429
            ],
            [
              -112.791495,
              47.602597
            ],
            [
              -112.801723,
              47.610373
            ],
            [
              -112.809653,
              47.609964
            ],
            [
              -112.81896,
              47.608118
            ],
            [
              -112.827435,
              47.613294
            ],
            [
              -112.834708,
              47.619647
            ],
            [
              -112.840963,
              47.623247
            ],
            [
              -112.846122,
              47.628257
            ],
            [
              -112.85617,
              47.630134
            ],
            [
              -112.859612,
              47.638027
            ],
            [
              -112.868253,
              47.646501
            ],
            [
              -112.875404,
              47.651618
            ],
            [
              -112.878282,
              47.65939
            ],
            [
              -112.885177,
              47.66524
            ],
            [
              -112.891918,
              47.67438
            ],
            [
              -112.897659,
              47.677811
            ],
            [
              -112.902888,
              47.683199
            ],
            [
              -112.906474,
              47.688667
            ],
            [
              -112.909889,
              47.692392
            ],
            [
              -112.909354,
              47.701557
            ],
            [
              -112.903846,
              47.707215
            ],
            [
              -112.903833,
              47.718094
            ],
            [
              -112.903182,
              47.725907
            ],
            [
              -112.902862,
              47.731109
            ],
            [
              -112.907363,
              47.737162
            ],
            [
              -112.913371,
              47.742477
            ],
            [
              -112.918242,
              47.753242
            ],
            [
              -112.925757,
              47.761044
            ],
            [
              -112.920841,
              47.76967
            ],
            [
              -112.920307,
              47.77678
            ],
            [
              -112.920823,
              47.78374
            ],
            [
              -112.91916,
              47.79103
            ],
            [
              -112.918313,
              47.795465
            ],
            [
              -112.916334,
              47.804039
            ],
            [
              -112.916562,
              47.809735
            ],
            [
              -112.915972,
              47.817618
            ],
            [
              -112.914137,
              47.824917
            ],
            [
              -112.91627,
              47.832338
            ],
            [
              -112.917737,
              47.838339
            ],
            [
              -112.925172,
              47.843877
            ],
            [
              -112.925327,
              47.848301
            ],
            [
              -112.927513,
              47.852745
            ],
            [
              -112.928108,
              47.86041
            ],
            [
              -112.937613,
              47.865797
            ],
            [
              -112.943553,
              47.870793
            ],
            [
              -112.946435,
              47.875569
            ],
            [
              -112.950256,
              47.88519
            ],
            [
              -112.948661,
              47.891712
            ],
            [
              -112.951582,
              47.897885
            ],
            [
              -112.956751,
              47.90779
            ],
            [
              -112.967616,
              47.910902
            ],
            [
              -112.974992,
              47.916027
            ],
            [
              -112.976326,
              47.926696
            ],
            [
              -112.980385,
              47.942392
            ],
            [
              -112.98381,
              47.952581
            ],
            [
              -112.984734,
              47.953918
            ],
            [
              -113.013791,
              47.941483
            ],
            [
              -113.022115,
              47.927097
            ],
            [
              -113.063483,
              47.925118
            ],
            [
              -113.086634,
              47.918182
            ],
            [
              -113.092519,
              47.896129
            ],
            [
              -113.062876,
              47.885563
            ],
            [
              -113.05813,
              47.870819
            ],
            [
              -113.058188,
              47.842496
            ],
            [
              -113.0693,
              47.829101
            ],
            [
              -113.059974,
              47.80806
            ],
            [
              -113.066451,
              47.776214
            ],
            [
              -113.082785,
              47.773041
            ],
            [
              -113.080997,
              47.749917
            ],
            [
              -113.094429,
              47.743507
            ],
            [
              -113.121863,
              47.741589
            ],
            [
              -113.138261,
              47.745892
            ],
            [
              -113.164119,
              47.730023
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "081",
      "COUNTYNS": "01719582",
      "AFFGEOID": "0500000US30081",
      "GEOID": "30081",
      "NAME": "Ravalli",
      "LSAD": "06",
      "ALAND": 6192612337,
      "AWATER": 24251907,
      "County_state": "Ravalli,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -114.562509,
              45.779927
            ],
            [
              -114.566172,
              45.773864
            ],
            [
              -114.535634,
              45.739095
            ],
            [
              -114.504869,
              45.722176
            ],
            [
              -114.497553,
              45.710677
            ],
            [
              -114.495421,
              45.703321
            ],
            [
              -114.499637,
              45.669035
            ],
            [
              -114.507645,
              45.658949
            ],
            [
              -114.522142,
              45.64934
            ],
            [
              -114.529678,
              45.65232
            ],
            [
              -114.53577,
              45.650613
            ],
            [
              -114.561046,
              45.639906
            ],
            [
              -114.563652,
              45.637412
            ],
            [
              -114.563305,
              45.631612
            ],
            [
              -114.553937,
              45.619299
            ],
            [
              -114.544905,
              45.616673
            ],
            [
              -114.538132,
              45.606834
            ],
            [
              -114.553999,
              45.591279
            ],
            [
              -114.558253,
              45.585104
            ],
            [
              -114.559038,
              45.565706
            ],
            [
              -114.549508,
              45.56059
            ],
            [
              -114.526075,
              45.570771
            ],
            [
              -114.517761,
              45.568129
            ],
            [
              -114.506341,
              45.559216
            ],
            [
              -114.498176,
              45.555473
            ],
            [
              -114.473759,
              45.563278
            ],
            [
              -114.460542,
              45.561283
            ],
            [
              -114.456764,
              45.543983
            ],
            [
              -114.450863,
              45.54253
            ],
            [
              -114.438991,
              45.536076
            ],
            [
              -114.415804,
              45.509753
            ],
            [
              -114.388618,
              45.502903
            ],
            [
              -114.36852,
              45.492716
            ],
            [
              -114.36562,
              45.490416
            ],
            [
              -114.360719,
              45.474116
            ],
            [
              -114.345019,
              45.459916
            ],
            [
              -114.333218,
              45.459316
            ],
            [
              -114.279217,
              45.480616
            ],
            [
              -114.270717,
              45.486116
            ],
            [
              -114.261616,
              45.495816
            ],
            [
              -114.247824,
              45.524283
            ],
            [
              -114.248183,
              45.533226
            ],
            [
              -114.251836,
              45.537812
            ],
            [
              -114.248121,
              45.545877
            ],
            [
              -114.227942,
              45.546423
            ],
            [
              -114.203665,
              45.53557
            ],
            [
              -114.192802,
              45.536596
            ],
            [
              -114.18647,
              45.545539
            ],
            [
              -114.180043,
              45.551432
            ],
            [
              -114.154837,
              45.552916
            ],
            [
              -114.135249,
              45.557465
            ],
            [
              -114.129099,
              45.565491
            ],
            [
              -114.128601,
              45.568996
            ],
            [
              -114.132359,
              45.572531
            ],
            [
              -114.131469,
              45.574444
            ],
            [
              -114.122322,
              45.58426
            ],
            [
              -114.100308,
              45.586354
            ],
            [
              -114.086584,
              45.59118
            ],
            [
              -114.0821,
              45.596958
            ],
            [
              -114.083149,
              45.603996
            ],
            [
              -114.08179,
              45.611329
            ],
            [
              -114.067619,
              45.627706
            ],
            [
              -114.033456,
              45.648629
            ],
            [
              -114.018731,
              45.648616
            ],
            [
              -114.014973,
              45.654008
            ],
            [
              -114.013786,
              45.658238
            ],
            [
              -114.02007,
              45.670332
            ],
            [
              -114.020533,
              45.681223
            ],
            [
              -114.019315,
              45.692937
            ],
            [
              -114.015633,
              45.696127
            ],
            [
              -113.986656,
              45.704564
            ],
            [
              -113.971565,
              45.700636
            ],
            [
              -113.942693894537,
              45.6864080223607
            ],
            [
              -113.922306,
              45.703976
            ],
            [
              -113.911328,
              45.698877
            ],
            [
              -113.904489,
              45.730735
            ],
            [
              -113.916267,
              45.746133
            ],
            [
              -113.896496,
              45.769723
            ],
            [
              -113.870452,
              45.781574
            ],
            [
              -113.854917,
              45.773141
            ],
            [
              -113.849397,
              45.75278
            ],
            [
              -113.829875,
              45.750746
            ],
            [
              -113.804353,
              45.763062
            ],
            [
              -113.787101,
              45.778497
            ],
            [
              -113.791792,
              45.792216
            ],
            [
              -113.777981,
              45.792129
            ],
            [
              -113.744046,
              45.815074
            ],
            [
              -113.729448,
              45.819538
            ],
            [
              -113.712522,
              45.838384
            ],
            [
              -113.697639,
              45.843545
            ],
            [
              -113.667875,
              45.835373
            ],
            [
              -113.654693,
              45.840373
            ],
            [
              -113.622542,
              45.841305
            ],
            [
              -113.610954,
              45.850455
            ],
            [
              -113.592215,
              45.850224
            ],
            [
              -113.5914,
              45.862898
            ],
            [
              -113.568151,
              45.871916
            ],
            [
              -113.528581,
              45.88116
            ],
            [
              -113.53655,
              45.893877
            ],
            [
              -113.535109,
              45.913198
            ],
            [
              -113.516144,
              45.938814
            ],
            [
              -113.517409,
              45.940089
            ],
            [
              -113.520851,
              45.940885
            ],
            [
              -113.524545,
              45.942684
            ],
            [
              -113.532103,
              45.945191
            ],
            [
              -113.539685,
              45.944815
            ],
            [
              -113.545313,
              45.946265
            ],
            [
              -113.550345,
              45.946421
            ],
            [
              -113.556441,
              45.945805
            ],
            [
              -113.562765,
              45.942788
            ],
            [
              -113.571707,
              45.940579
            ],
            [
              -113.575561,
              45.938842
            ],
            [
              -113.579582,
              45.942076
            ],
            [
              -113.580926,
              45.945196
            ],
            [
              -113.585519,
              45.947318
            ],
            [
              -113.590048,
              45.946861
            ],
            [
              -113.596307,
              45.949915
            ],
            [
              -113.603846,
              45.948618
            ],
            [
              -113.609357,
              45.951111
            ],
            [
              -113.613103,
              45.957236
            ],
            [
              -113.612838,
              45.96204
            ],
            [
              -113.613297,
              45.966142
            ],
            [
              -113.616988,
              45.970397
            ],
            [
              -113.620061,
              45.969644
            ],
            [
              -113.628886,
              45.967637
            ],
            [
              -113.636249,
              45.966033
            ],
            [
              -113.643074,
              45.963839
            ],
            [
              -113.650585,
              45.967084
            ],
            [
              -113.656757,
              45.975729
            ],
            [
              -113.66306,
              45.979863
            ],
            [
              -113.664146,
              45.985646
            ],
            [
              -113.668488,
              45.992346
            ],
            [
              -113.667711,
              45.998283
            ],
            [
              -113.670855,
              46.004757
            ],
            [
              -113.672302,
              46.009322
            ],
            [
              -113.673825,
              46.013933
            ],
            [
              -113.675417,
              46.017333
            ],
            [
              -113.672617,
              46.020625
            ],
            [
              -113.674309,
              46.023613
            ],
            [
              -113.678185,
              46.022533
            ],
            [
              -113.686526,
              46.023498
            ],
            [
              -113.690328,
              46.028341
            ],
            [
              -113.687931,
              46.033023
            ],
            [
              -113.689004,
              46.035625
            ],
            [
              -113.696822,
              46.037269
            ],
            [
              -113.708471,
              46.039208
            ],
            [
              -113.719707,
              46.039031
            ],
            [
              -113.731733,
              46.038375
            ],
            [
              -113.735615,
              46.041612
            ],
            [
              -113.741568,
              46.047257
            ],
            [
              -113.747411,
              46.048295
            ],
            [
              -113.753466,
              46.046593
            ],
            [
              -113.756054,
              46.041445
            ],
            [
              -113.7583,
              46.037772
            ],
            [
              -113.760641,
              46.03209
            ],
            [
              -113.764892,
              46.029494
            ],
            [
              -113.770741,
              46.028601
            ],
            [
              -113.77646,
              46.03143
            ],
            [
              -113.783181,
              46.032164
            ],
            [
              -113.793878,
              46.035108
            ],
            [
              -113.798611,
              46.035505
            ],
            [
              -113.803601,
              46.039361
            ],
            [
              -113.801878,
              46.04261
            ],
            [
              -113.80329,
              46.049296
            ],
            [
              -113.79521,
              46.054147
            ],
            [
              -113.792622,
              46.062284
            ],
            [
              -113.789236,
              46.066931
            ],
            [
              -113.786382,
              46.070852
            ],
            [
              -113.786057,
              46.07546
            ],
            [
              -113.778184,
              46.081178
            ],
            [
              -113.772885,
              46.084706
            ],
            [
              -113.771573,
              46.089403
            ],
            [
              -113.768228,
              46.098791
            ],
            [
              -113.761837,
              46.103149
            ],
            [
              -113.75392,
              46.113344
            ],
            [
              -113.758229,
              46.118223
            ],
            [
              -113.765061,
              46.123673
            ],
            [
              -113.767616,
              46.129386
            ],
            [
              -113.773271,
              46.133851
            ],
            [
              -113.77738,
              46.1344
            ],
            [
              -113.77834,
              46.137023
            ],
            [
              -113.777656,
              46.13908
            ],
            [
              -113.780344,
              46.142618
            ],
            [
              -113.784331,
              46.144609
            ],
            [
              -113.790441,
              46.148343
            ],
            [
              -113.79433,
              46.151077
            ],
            [
              -113.793434,
              46.156173
            ],
            [
              -113.791038,
              46.160581
            ],
            [
              -113.790891,
              46.165528
            ],
            [
              -113.791054,
              46.169511
            ],
            [
              -113.791586,
              46.175016
            ],
            [
              -113.788944,
              46.177492
            ],
            [
              -113.786744,
              46.179238
            ],
            [
              -113.786952,
              46.18134
            ],
            [
              -113.78958,
              46.182121
            ],
            [
              -113.795432,
              46.184822
            ],
            [
              -113.800513,
              46.188424
            ],
            [
              -113.802438,
              46.191356
            ],
            [
              -113.806164,
              46.191742
            ],
            [
              -113.812713,
              46.189546
            ],
            [
              -113.817995,
              46.191468
            ],
            [
              -113.81975,
              46.196261
            ],
            [
              -113.823357,
              46.201768
            ],
            [
              -113.822965,
              46.208567
            ],
            [
              -113.817675,
              46.215486
            ],
            [
              -113.80825,
              46.218161
            ],
            [
              -113.799302,
              46.219668
            ],
            [
              -113.791855,
              46.223008
            ],
            [
              -113.782292,
              46.226164
            ],
            [
              -113.777519,
              46.227512
            ],
            [
              -113.776631,
              46.233686
            ],
            [
              -113.778078,
              46.237611
            ],
            [
              -113.775022,
              46.242923
            ],
            [
              -113.771124,
              46.245464
            ],
            [
              -113.763436,
              46.247514
            ],
            [
              -113.758354,
              46.259744
            ],
            [
              -113.751949,
              46.264002
            ],
            [
              -113.74909,
              46.269885
            ],
            [
              -113.753561,
              46.274143
            ],
            [
              -113.766074,
              46.277252
            ],
            [
              -113.778689,
              46.282346
            ],
            [
              -113.784581,
              46.285911
            ],
            [
              -113.784258,
              46.291945
            ],
            [
              -113.78495,
              46.29701
            ],
            [
              -113.782204,
              46.30253
            ],
            [
              -113.779474,
              46.307348
            ],
            [
              -113.774921,
              46.313093
            ],
            [
              -113.773269,
              46.31947
            ],
            [
              -113.778079,
              46.326454
            ],
            [
              -113.783219,
              46.332755
            ],
            [
              -113.784727,
              46.341719
            ],
            [
              -113.786282,
              46.352886
            ],
            [
              -113.783073,
              46.362243
            ],
            [
              -113.786891,
              46.366894
            ],
            [
              -113.788368,
              46.371462
            ],
            [
              -113.797499,
              46.375079
            ],
            [
              -113.800998,
              46.385171
            ],
            [
              -113.809595,
              46.391773
            ],
            [
              -113.815956,
              46.39708
            ],
            [
              -113.816646,
              46.399082
            ],
            [
              -113.82024,
              46.406992
            ],
            [
              -113.857659,
              46.419419
            ],
            [
              -113.849218,
              46.440978
            ],
            [
              -113.865926,
              46.492123
            ],
            [
              -113.878358,
              46.503199
            ],
            [
              -113.848976,
              46.516744
            ],
            [
              -113.845183,
              46.54658
            ],
            [
              -113.828715,
              46.570718
            ],
            [
              -113.835256,
              46.584031
            ],
            [
              -113.811703,
              46.58907
            ],
            [
              -113.816393,
              46.614812
            ],
            [
              -113.803224,
              46.629914
            ],
            [
              -113.81765,
              46.637306
            ],
            [
              -113.827743,
              46.660896
            ],
            [
              -114.157847,
              46.660923
            ],
            [
              -114.170755,
              46.660389
            ],
            [
              -114.33268369823399,
              46.660580775216395
            ],
            [
              -114.32456,
              46.653579
            ],
            [
              -114.320665,
              46.646963
            ],
            [
              -114.322912,
              46.642938
            ],
            [
              -114.322519,
              46.611066
            ],
            [
              -114.333931,
              46.592162
            ],
            [
              -114.334992,
              46.588154
            ],
            [
              -114.333931,
              46.582732
            ],
            [
              -114.331338,
              46.577781
            ],
            [
              -114.33161059250301,
              46.573899203359694
            ],
            [
              -114.33175,
              46.571914
            ],
            [
              -114.339533,
              46.564039
            ],
            [
              -114.34534,
              46.548444
            ],
            [
              -114.348733,
              46.533792
            ],
            [
              -114.349208,
              46.529514
            ],
            [
              -114.342072,
              46.519679
            ],
            [
              -114.351655,
              46.508119
            ],
            [
              -114.35874,
              46.505306
            ],
            [
              -114.375348,
              46.501855
            ],
            [
              -114.385871,
              46.50437
            ],
            [
              -114.395204,
              46.503148
            ],
            [
              -114.400257,
              46.502143
            ],
            [
              -114.403019,
              46.498675
            ],
            [
              -114.400068,
              46.47718
            ],
            [
              -114.394447,
              46.469549
            ],
            [
              -114.383051,
              46.466402
            ],
            [
              -114.379338,
              46.460166
            ],
            [
              -114.376413,
              46.442983
            ],
            [
              -114.384756,
              46.411784
            ],
            [
              -114.408974,
              46.400438
            ],
            [
              -114.422458,
              46.387097
            ],
            [
              -114.411592,
              46.366688
            ],
            [
              -114.410682,
              46.360673
            ],
            [
              -114.413066870269,
              46.341500999999894
            ],
            [
              -114.413758,
              46.335945
            ],
            [
              -114.431708,
              46.310744
            ],
            [
              -114.433478,
              46.305502
            ],
            [
              -114.425587,
              46.287899
            ],
            [
              -114.427309,
              46.283624
            ],
            [
              -114.43544,
              46.27661
            ],
            [
              -114.441326,
              46.2738
            ],
            [
              -114.453257,
              46.270939
            ],
            [
              -114.465024,
              46.273127
            ],
            [
              -114.470479,
              46.26732
            ],
            [
              -114.468254,
              46.248796
            ],
            [
              -114.451912,
              46.241253
            ],
            [
              -114.449819,
              46.237119
            ],
            [
              -114.445497,
              46.220227
            ],
            [
              -114.443215,
              46.202943
            ],
            [
              -114.445928,
              46.173933
            ],
            [
              -114.457549,
              46.170231
            ],
            [
              -114.472643,
              46.162202
            ],
            [
              -114.478333,
              46.160876
            ],
            [
              -114.489254,
              46.167684
            ],
            [
              -114.514706,
              46.167726
            ],
            [
              -114.527096,
              46.146218
            ],
            [
              -114.5213,
              46.125287
            ],
            [
              -114.488303,
              46.113106
            ],
            [
              -114.474415,
              46.112515
            ],
            [
              -114.460049,
              46.097104
            ],
            [
              -114.461864,
              46.078571
            ],
            [
              -114.468529,
              46.062484
            ],
            [
              -114.492153,
              46.04729
            ],
            [
              -114.494683,
              46.042546
            ],
            [
              -114.493418,
              46.03717
            ],
            [
              -114.490572,
              46.032427
            ],
            [
              -114.485793,
              46.030022
            ],
            [
              -114.480241,
              46.030325
            ],
            [
              -114.473811,
              46.016614
            ],
            [
              -114.477922,
              46.009025
            ],
            [
              -114.47729,
              46.000802
            ],
            [
              -114.470965,
              45.995742
            ],
            [
              -114.441185,
              45.988453
            ],
            [
              -114.425843,
              45.984984
            ],
            [
              -114.419899,
              45.981106
            ],
            [
              -114.411892,
              45.977883
            ],
            [
              -114.409353,
              45.97141
            ],
            [
              -114.403712,
              45.967049
            ],
            [
              -114.402261,
              45.961489
            ],
            [
              -114.404708,
              45.9559
            ],
            [
              -114.411933,
              45.952358
            ],
            [
              -114.415977,
              45.947891
            ],
            [
              -114.423681,
              45.9441
            ],
            [
              -114.427717,
              45.939625
            ],
            [
              -114.431328,
              45.938023
            ],
            [
              -114.431159,
              45.935737
            ],
            [
              -114.413168,
              45.911479
            ],
            [
              -114.404314,
              45.903497
            ],
            [
              -114.395059,
              45.901458
            ],
            [
              -114.387166,
              45.889164
            ],
            [
              -114.388243,
              45.88234
            ],
            [
              -114.409477,
              45.85164
            ],
            [
              -114.422963,
              45.855381
            ],
            [
              -114.44868,
              45.858891
            ],
            [
              -114.455532,
              45.855012
            ],
            [
              -114.470296,
              45.851343
            ],
            [
              -114.498809,
              45.850676
            ],
            [
              -114.509303,
              45.845531
            ],
            [
              -114.514596,
              45.840785
            ],
            [
              -114.517143,
              45.835993
            ],
            [
              -114.51704,
              45.833148
            ],
            [
              -114.512973,
              45.828825
            ],
            [
              -114.544692,
              45.791447
            ],
            [
              -114.555487,
              45.786249
            ],
            [
              -114.562509,
              45.779927
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "029",
      "COUNTYNS": "01719611",
      "AFFGEOID": "0500000US30029",
      "GEOID": "30029",
      "NAME": "Flathead",
      "LSAD": "06",
      "ALAND": 13175679131,
      "AWATER": 437280390,
      "County_state": "Flathead,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -115.020079,
              48.225954
            ],
            [
              -115.010094,
              48.139115
            ],
            [
              -115.010672,
              48.017621
            ],
            [
              -115.010678,
              48.003139
            ],
            [
              -114.989433,
              48.003141
            ],
            [
              -114.989344,
              47.872807
            ],
            [
              -114.861117,
              47.872802
            ],
            [
              -114.818683,
              47.875773
            ],
            [
              -114.604584,
              47.875605
            ],
            [
              -114.604462,
              47.789871
            ],
            [
              -114.47729,
              47.789941
            ],
            [
              -114.477451,
              47.875401
            ],
            [
              -114.492147,
              47.875414
            ],
            [
              -114.49219,
              47.961989
            ],
            [
              -114.368104,
              47.962026
            ],
            [
              -114.110078,
              47.962542
            ],
            [
              -114.038545,
              47.96255
            ],
            [
              -114.044143,
              47.996603
            ],
            [
              -114.057877,
              48.000049
            ],
            [
              -114.061661,
              47.990088
            ],
            [
              -114.078282,
              48.023148
            ],
            [
              -114.050243,
              48.022994
            ],
            [
              -114.049903,
              48.037342
            ],
            [
              -114.028326,
              48.037343
            ],
            [
              -114.029042,
              48.051653
            ],
            [
              -113.884541,
              48.0519
            ],
            [
              -113.872342,
              48.042841
            ],
            [
              -113.865544,
              48.013908
            ],
            [
              -113.842199,
              47.978242
            ],
            [
              -113.803682,
              47.980212
            ],
            [
              -113.772336,
              47.957966
            ],
            [
              -113.774103,
              47.950707
            ],
            [
              -113.770362,
              47.947664
            ],
            [
              -113.762387,
              47.951789
            ],
            [
              -113.752767,
              47.951183
            ],
            [
              -113.747947,
              47.953025
            ],
            [
              -113.744272,
              47.950447
            ],
            [
              -113.718499,
              47.916726
            ],
            [
              -113.69873,
              47.916746
            ],
            [
              -113.672014,
              47.896349
            ],
            [
              -113.665292,
              47.882898
            ],
            [
              -113.675251,
              47.865571
            ],
            [
              -113.666201,
              47.84065
            ],
            [
              -113.66959,
              47.812324
            ],
            [
              -113.6817,
              47.805153
            ],
            [
              -113.670642,
              47.78315
            ],
            [
              -113.652681,
              47.773809
            ],
            [
              -113.649744,
              47.755847
            ],
            [
              -113.664021,
              47.746003
            ],
            [
              -113.659492,
              47.735306
            ],
            [
              -113.640588,
              47.719998
            ],
            [
              -113.603924,
              47.72144
            ],
            [
              -113.598647,
              47.697463
            ],
            [
              -113.605954,
              47.663181
            ],
            [
              -113.622663,
              47.658657
            ],
            [
              -113.625087,
              47.622019
            ],
            [
              -113.634079,
              47.600027
            ],
            [
              -113.466485,
              47.600057
            ],
            [
              -113.325845,
              47.600161
            ],
            [
              -113.325845,
              47.595507
            ],
            [
              -113.144368,
              47.595507
            ],
            [
              -113.146287,
              47.607507
            ],
            [
              -113.135758,
              47.620321
            ],
            [
              -113.144481,
              47.637216
            ],
            [
              -113.132619,
              47.639194
            ],
            [
              -113.12847,
              47.661047
            ],
            [
              -113.146412,
              47.676472
            ],
            [
              -113.165811,
              47.719764
            ],
            [
              -113.164119,
              47.730023
            ],
            [
              -113.138261,
              47.745892
            ],
            [
              -113.121863,
              47.741589
            ],
            [
              -113.094429,
              47.743507
            ],
            [
              -113.080997,
              47.749917
            ],
            [
              -113.082785,
              47.773041
            ],
            [
              -113.066451,
              47.776214
            ],
            [
              -113.059974,
              47.80806
            ],
            [
              -113.0693,
              47.829101
            ],
            [
              -113.058188,
              47.842496
            ],
            [
              -113.05813,
              47.870819
            ],
            [
              -113.062876,
              47.885563
            ],
            [
              -113.092519,
              47.896129
            ],
            [
              -113.086634,
              47.918182
            ],
            [
              -113.063483,
              47.925118
            ],
            [
              -113.022115,
              47.927097
            ],
            [
              -113.013791,
              47.941483
            ],
            [
              -112.984734,
              47.953918
            ],
            [
              -112.951407,
              47.944591
            ],
            [
              -112.912178,
              47.946577
            ],
            [
              -112.892309,
              47.976359
            ],
            [
              -112.878931,
              47.975713
            ],
            [
              -112.907849,
              48.029873
            ],
            [
              -112.936203,
              48.035341
            ],
            [
              -112.947066,
              48.030981
            ],
            [
              -112.950716,
              48.049976
            ],
            [
              -112.93713,
              48.055523
            ],
            [
              -112.963711,
              48.07491
            ],
            [
              -112.982017,
              48.073957
            ],
            [
              -112.978902,
              48.088645
            ],
            [
              -112.994237,
              48.098514
            ],
            [
              -112.983366,
              48.117761
            ],
            [
              -113.000624,
              48.130734
            ],
            [
              -113.014811,
              48.13103
            ],
            [
              -113.030149,
              48.133269
            ],
            [
              -113.051183,
              48.12131
            ],
            [
              -113.052922,
              48.140902
            ],
            [
              -113.063915,
              48.149976
            ],
            [
              -113.090804,
              48.136443
            ],
            [
              -113.13468,
              48.138183
            ],
            [
              -113.130786,
              48.145457
            ],
            [
              -113.152279,
              48.15124
            ],
            [
              -113.166205,
              48.165455
            ],
            [
              -113.190154,
              48.175254
            ],
            [
              -113.207854,
              48.164377
            ],
            [
              -113.230906,
              48.170426
            ],
            [
              -113.236852,
              48.218002
            ],
            [
              -113.226613,
              48.234355
            ],
            [
              -113.236777,
              48.247211
            ],
            [
              -113.267613,
              48.259966
            ],
            [
              -113.289585,
              48.265339
            ],
            [
              -113.2978,
              48.284251
            ],
            [
              -113.322583,
              48.287724
            ],
            [
              -113.348,
              48.303113
            ],
            [
              -113.349046,
              48.310263
            ],
            [
              -113.354804,
              48.316498
            ],
            [
              -113.357088,
              48.316915
            ],
            [
              -113.365568,
              48.321204
            ],
            [
              -113.374761,
              48.325
            ],
            [
              -113.375094,
              48.332875
            ],
            [
              -113.3827,
              48.339101
            ],
            [
              -113.385396,
              48.340388
            ],
            [
              -113.381552,
              48.344188
            ],
            [
              -113.377057,
              48.347294
            ],
            [
              -113.369832,
              48.353957
            ],
            [
              -113.356908,
              48.359775
            ],
            [
              -113.348453,
              48.364923
            ],
            [
              -113.339676,
              48.370768
            ],
            [
              -113.336099,
              48.378756
            ],
            [
              -113.333708,
              48.384238
            ],
            [
              -113.337788,
              48.390396
            ],
            [
              -113.347054,
              48.395218
            ],
            [
              -113.349866,
              48.402804
            ],
            [
              -113.349705,
              48.410417
            ],
            [
              -113.351085,
              48.415943
            ],
            [
              -113.354342,
              48.419999
            ],
            [
              -113.359739,
              48.423693
            ],
            [
              -113.367303,
              48.423128
            ],
            [
              -113.378893,
              48.419169
            ],
            [
              -113.390976,
              48.416858
            ],
            [
              -113.401756,
              48.418413
            ],
            [
              -113.412965,
              48.420448
            ],
            [
              -113.418236,
              48.425954
            ],
            [
              -113.425176,
              48.430813
            ],
            [
              -113.434466,
              48.432841
            ],
            [
              -113.440414,
              48.436315
            ],
            [
              -113.444664,
              48.441386
            ],
            [
              -113.451214,
              48.443505
            ],
            [
              -113.459402,
              48.445142
            ],
            [
              -113.465956,
              48.447301
            ],
            [
              -113.471644,
              48.450838
            ],
            [
              -113.47649,
              48.45282
            ],
            [
              -113.480516,
              48.457086
            ],
            [
              -113.478309,
              48.461742
            ],
            [
              -113.47444,
              48.468765
            ],
            [
              -113.467018,
              48.474257
            ],
            [
              -113.469938,
              48.482436
            ],
            [
              -113.471067,
              48.489363
            ],
            [
              -113.467356,
              48.496064
            ],
            [
              -113.468831,
              48.500197
            ],
            [
              -113.473263,
              48.509443
            ],
            [
              -113.472308,
              48.518073
            ],
            [
              -113.471373,
              48.526166
            ],
            [
              -113.469139,
              48.534895
            ],
            [
              -113.468792,
              48.541468
            ],
            [
              -113.467412,
              48.547265
            ],
            [
              -113.476535,
              48.552776
            ],
            [
              -113.4881,
              48.553335
            ],
            [
              -113.499979,
              48.556647
            ],
            [
              -113.511963,
              48.561825
            ],
            [
              -113.51482,
              48.569249
            ],
            [
              -113.521966,
              48.573768
            ],
            [
              -113.5325,
              48.577013
            ],
            [
              -113.539682,
              48.581128
            ],
            [
              -113.54233,
              48.584504
            ],
            [
              -113.547098,
              48.584767
            ],
            [
              -113.552853,
              48.583579
            ],
            [
              -113.565471,
              48.581604
            ],
            [
              -113.573963,
              48.580855
            ],
            [
              -113.582374,
              48.583612
            ],
            [
              -113.590233,
              48.580885
            ],
            [
              -113.594386,
              48.576765
            ],
            [
              -113.605025,
              48.575749
            ],
            [
              -113.613369,
              48.579806
            ],
            [
              -113.617736,
              48.588596
            ],
            [
              -113.62595,
              48.594623
            ],
            [
              -113.632257,
              48.598819
            ],
            [
              -113.639719,
              48.600057
            ],
            [
              -113.645885,
              48.596483
            ],
            [
              -113.651124,
              48.592488
            ],
            [
              -113.658268,
              48.587683
            ],
            [
              -113.668284,
              48.588457
            ],
            [
              -113.677422,
              48.592234
            ],
            [
              -113.690777,
              48.592985
            ],
            [
              -113.706855,
              48.594572
            ],
            [
              -113.715302,
              48.598467
            ],
            [
              -113.723333,
              48.600299
            ],
            [
              -113.728971,
              48.603302
            ],
            [
              -113.733115,
              48.60757
            ],
            [
              -113.73854,
              48.609467
            ],
            [
              -113.746688,
              48.612696
            ],
            [
              -113.75107,
              48.615276
            ],
            [
              -113.752066,
              48.620592
            ],
            [
              -113.74713,
              48.624256
            ],
            [
              -113.745734,
              48.632226
            ],
            [
              -113.745761,
              48.638723
            ],
            [
              -113.746275,
              48.646185
            ],
            [
              -113.744242,
              48.653302
            ],
            [
              -113.740286,
              48.659263
            ],
            [
              -113.735073,
              48.665875
            ],
            [
              -113.729583,
              48.669099
            ],
            [
              -113.729671,
              48.673485
            ],
            [
              -113.73227,
              48.678097
            ],
            [
              -113.737582,
              48.684111
            ],
            [
              -113.739922,
              48.68909
            ],
            [
              -113.740356,
              48.691358
            ],
            [
              -113.734663,
              48.693901
            ],
            [
              -113.730419,
              48.693683
            ],
            [
              -113.718123,
              48.696591
            ],
            [
              -113.712935,
              48.700909
            ],
            [
              -113.70872,
              48.70667
            ],
            [
              -113.707397,
              48.711534
            ],
            [
              -113.702732,
              48.717066
            ],
            [
              -113.707584,
              48.72281
            ],
            [
              -113.708324,
              48.727295
            ],
            [
              -113.708662,
              48.733239
            ],
            [
              -113.711898,
              48.738255
            ],
            [
              -113.718167,
              48.743205
            ],
            [
              -113.728386,
              48.745564
            ],
            [
              -113.735678,
              48.749526
            ],
            [
              -113.740773,
              48.75636
            ],
            [
              -113.742824,
              48.764871
            ],
            [
              -113.754763,
              48.769601
            ],
            [
              -113.762614,
              48.775482
            ],
            [
              -113.765637,
              48.782596
            ],
            [
              -113.768909,
              48.786178
            ],
            [
              -113.771085,
              48.790089
            ],
            [
              -113.773606,
              48.796918
            ],
            [
              -113.767334,
              48.802413
            ],
            [
              -113.760342,
              48.810052
            ],
            [
              -113.760142,
              48.814096
            ],
            [
              -113.759304,
              48.816404
            ],
            [
              -113.760754,
              48.819846
            ],
            [
              -113.76664,
              48.821752
            ],
            [
              -113.776441,
              48.826393
            ],
            [
              -113.782842,
              48.830976
            ],
            [
              -113.785703,
              48.837077
            ],
            [
              -113.78756,
              48.841385
            ],
            [
              -113.79206,
              48.845254
            ],
            [
              -113.79607,
              48.844213
            ],
            [
              -113.802604,
              48.840083
            ],
            [
              -113.81075,
              48.838584
            ],
            [
              -113.817992,
              48.842412
            ],
            [
              -113.818212,
              48.846545
            ],
            [
              -113.830521,
              48.849659
            ],
            [
              -113.840583,
              48.854299
            ],
            [
              -113.846626,
              48.853527
            ],
            [
              -113.855532,
              48.851109
            ],
            [
              -113.863615,
              48.848075
            ],
            [
              -113.871838,
              48.842778
            ],
            [
              -113.874574,
              48.839897
            ],
            [
              -113.880352,
              48.838721
            ],
            [
              -113.886427,
              48.840313
            ],
            [
              -113.892725,
              48.844523
            ],
            [
              -113.904313,
              48.846459
            ],
            [
              -113.908965,
              48.847747
            ],
            [
              -113.91261,
              48.845783
            ],
            [
              -113.914783,
              48.840677
            ],
            [
              -113.921959,
              48.834177
            ],
            [
              -113.93085,
              48.828087
            ],
            [
              -113.940318,
              48.825676
            ],
            [
              -113.946296,
              48.821531
            ],
            [
              -113.952139,
              48.818491
            ],
            [
              -113.96042,
              48.819321
            ],
            [
              -113.964613,
              48.82325
            ],
            [
              -113.970725,
              48.822606
            ],
            [
              -113.972396,
              48.820896
            ],
            [
              -113.976239,
              48.821077
            ],
            [
              -113.97974,
              48.824206
            ],
            [
              -113.987183,
              48.826649
            ],
            [
              -113.996416,
              48.829546
            ],
            [
              -114.000336,
              48.831241
            ],
            [
              -114.002862,
              48.834639
            ],
            [
              -114,
              48.836702
            ],
            [
              -113.993211,
              48.838979
            ],
            [
              -113.989502,
              48.844461
            ],
            [
              -113.992204,
              48.851802
            ],
            [
              -113.996926,
              48.856713
            ],
            [
              -114.003367,
              48.861229
            ],
            [
              -114.008071,
              48.86471
            ],
            [
              -114.017865,
              48.865374
            ],
            [
              -114.025798,
              48.872098
            ],
            [
              -114.029947,
              48.878489
            ],
            [
              -114.04278,
              48.883749
            ],
            [
              -114.055451,
              48.888092
            ],
            [
              -114.055901,
              48.891776
            ],
            [
              -114.050664,
              48.894411
            ],
            [
              -114.04486,
              48.89458
            ],
            [
              -114.030882,
              48.898403
            ],
            [
              -114.017852,
              48.904078
            ],
            [
              -114.013602,
              48.910082
            ],
            [
              -114.015587,
              48.918439
            ],
            [
              -114.014984,
              48.924434
            ],
            [
              -114.023381,
              48.930897
            ],
            [
              -114.037044,
              48.931449
            ],
            [
              -114.042985,
              48.932281
            ],
            [
              -114.044964,
              48.937914
            ],
            [
              -114.041902,
              48.941086
            ],
            [
              -114.037745,
              48.949376
            ],
            [
              -114.028048,
              48.955744
            ],
            [
              -114.024269,
              48.962905
            ],
            [
              -114.029571,
              48.964753
            ],
            [
              -114.042372,
              48.962765
            ],
            [
              -114.051375,
              48.966052
            ],
            [
              -114.055974,
              48.973786
            ],
            [
              -114.057956,
              48.979035
            ],
            [
              -114.055889,
              48.984012
            ],
            [
              -114.063096,
              48.990339
            ],
            [
              -114.066337,
              48.996708
            ],
            [
              -114.068179566579,
              48.9989189290043
            ],
            [
              -114.180211,
              48.999703
            ],
            [
              -114.201107,
              48.999249
            ],
            [
              -114.224912,
              48.999687
            ],
            [
              -114.250971,
              49.000905
            ],
            [
              -114.375977,
              49.00139
            ],
            [
              -114.674398,
              49.000679
            ],
            [
              -114.678217,
              49.000725
            ],
            [
              -114.727045223277,
              49.0005870038886
            ],
            [
              -114.739398,
              48.990826
            ],
            [
              -114.73477,
              48.982392
            ],
            [
              -114.696861,
              48.979942
            ],
            [
              -114.6848,
              48.973276
            ],
            [
              -114.696994,
              48.9539
            ],
            [
              -114.718806,
              48.938678
            ],
            [
              -114.70391,
              48.910269
            ],
            [
              -114.711031,
              48.890687
            ],
            [
              -114.693653,
              48.874198
            ],
            [
              -114.691671,
              48.842115
            ],
            [
              -114.723248,
              48.831477
            ],
            [
              -114.732545,
              48.809752
            ],
            [
              -114.714542,
              48.801601
            ],
            [
              -114.671723,
              48.799353
            ],
            [
              -114.673381,
              48.789405
            ],
            [
              -114.648134,
              48.779332
            ],
            [
              -114.635274,
              48.759028
            ],
            [
              -114.612063,
              48.751858
            ],
            [
              -114.619201,
              48.742601
            ],
            [
              -114.6433,
              48.73276
            ],
            [
              -114.678752,
              48.724549
            ],
            [
              -114.691699,
              48.708625
            ],
            [
              -114.680912,
              48.694632
            ],
            [
              -114.69185,
              48.678941
            ],
            [
              -114.655922,
              48.670574
            ],
            [
              -114.639007,
              48.658878
            ],
            [
              -114.88859,
              48.658765
            ],
            [
              -114.888679,
              48.573546
            ],
            [
              -114.849803,
              48.573502
            ],
            [
              -114.84863,
              48.399464
            ],
            [
              -114.848098,
              48.226125
            ],
            [
              -115.020079,
              48.225954
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "089",
      "COUNTYNS": "01719578",
      "AFFGEOID": "0500000US30089",
      "GEOID": "30089",
      "NAME": "Sanders",
      "LSAD": "06",
      "ALAND": 7149403319,
      "AWATER": 76395973,
      "County_state": "Sanders,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -116.049773718343,
              48.216117548345196
            ],
            [
              -116.048911,
              48.12493
            ],
            [
              -116.049296939457,
              48.088395929540695
            ],
            [
              -116.049415,
              48.07722
            ],
            [
              -116.04932,
              48.066644
            ],
            [
              -116.048739,
              48.060093
            ],
            [
              -116.049153,
              47.999923
            ],
            [
              -116.048852017752,
              47.977337411295295
            ],
            [
              -116.04885,
              47.977186
            ],
            [
              -116.048421,
              47.97682
            ],
            [
              -116.03834,
              47.971318
            ],
            [
              -116.030751,
              47.973349
            ],
            [
              -116.007246,
              47.950087
            ],
            [
              -115.998236,
              47.938779
            ],
            [
              -115.995121,
              47.933827
            ],
            [
              -115.993678,
              47.926183
            ],
            [
              -115.982791,
              47.915994
            ],
            [
              -115.969076,
              47.914256
            ],
            [
              -115.965153,
              47.910131
            ],
            [
              -115.959946,
              47.898142
            ],
            [
              -115.939993,
              47.883153
            ],
            [
              -115.919291,
              47.857406
            ],
            [
              -115.906409,
              47.846261
            ],
            [
              -115.900934,
              47.843064
            ],
            [
              -115.881522,
              47.849672
            ],
            [
              -115.875262,
              47.843272
            ],
            [
              -115.870861,
              47.834939
            ],
            [
              -115.852291,
              47.827991
            ],
            [
              -115.845474,
              47.814967
            ],
            [
              -115.848509,
              47.809331
            ],
            [
              -115.847487,
              47.785227
            ],
            [
              -115.84044,
              47.780172
            ],
            [
              -115.837438,
              47.774846
            ],
            [
              -115.835069,
              47.77006
            ],
            [
              -115.835365,
              47.760957
            ],
            [
              -115.831755,
              47.755785
            ],
            [
              -115.824597,
              47.752154
            ],
            [
              -115.803917,
              47.75848
            ],
            [
              -115.797299,
              47.75752
            ],
            [
              -115.780441,
              47.743447
            ],
            [
              -115.783504,
              47.729305
            ],
            [
              -115.776219,
              47.719818
            ],
            [
              -115.77177,
              47.717412
            ],
            [
              -115.763424,
              47.717313
            ],
            [
              -115.758623,
              47.719041
            ],
            [
              -115.752349,
              47.716743
            ],
            [
              -115.730764,
              47.704426
            ],
            [
              -115.72377,
              47.696671
            ],
            [
              -115.726613,
              47.672093
            ],
            [
              -115.73627,
              47.654762
            ],
            [
              -115.72993,
              47.642442
            ],
            [
              -115.715193,
              47.63634
            ],
            [
              -115.708537,
              47.635356
            ],
            [
              -115.694284,
              47.62346
            ],
            [
              -115.689404,
              47.595402
            ],
            [
              -115.706473,
              47.577299
            ],
            [
              -115.721207,
              47.576323
            ],
            [
              -115.734674,
              47.567401
            ],
            [
              -115.746945,
              47.555293
            ],
            [
              -115.748536,
              47.549245
            ],
            [
              -115.747263,
              47.543197
            ],
            [
              -115.741371,
              47.538645
            ],
            [
              -115.717024,
              47.532693
            ],
            [
              -115.703365,
              47.534148
            ],
            [
              -115.698228,
              47.527786
            ],
            [
              -115.709989,
              47.523778
            ],
            [
              -115.708748,
              47.51264
            ],
            [
              -115.694106,
              47.498634
            ],
            [
              -115.686704,
              47.485596
            ],
            [
              -115.667058,
              47.487592
            ],
            [
              -115.655272,
              47.477944
            ],
            [
              -115.634684,
              47.48176
            ],
            [
              -115.62887367061101,
              47.4797042164009
            ],
            [
              -115.612747,
              47.474894
            ],
            [
              -115.566332,
              47.471053
            ],
            [
              -115.533142,
              47.475678
            ],
            [
              -115.522726,
              47.484203
            ],
            [
              -115.52591,
              47.49541
            ],
            [
              -115.504465,
              47.494791
            ],
            [
              -115.489936,
              47.47449
            ],
            [
              -115.443734,
              47.465608
            ],
            [
              -115.429305,
              47.476241
            ],
            [
              -115.401676,
              47.484904
            ],
            [
              -115.3634,
              47.48367
            ],
            [
              -115.342687,
              47.470913
            ],
            [
              -115.305978,
              47.471432
            ],
            [
              -115.282181,
              47.486923
            ],
            [
              -115.249057,
              47.480416
            ],
            [
              -115.212804,
              47.46245
            ],
            [
              -115.193855,
              47.461075
            ],
            [
              -115.157386,
              47.447809
            ],
            [
              -115.149491,
              47.432934
            ],
            [
              -115.128069,
              47.423028
            ],
            [
              -115.087872,
              47.427136
            ],
            [
              -115.045046,
              47.408409
            ],
            [
              -115.027637,
              47.414431
            ],
            [
              -115.014059,
              47.400682
            ],
            [
              -114.979189,
              47.395391
            ],
            [
              -114.969056,
              47.37405
            ],
            [
              -114.968978,
              47.312625
            ],
            [
              -114.906014,
              47.312482
            ],
            [
              -114.906046,
              47.29797
            ],
            [
              -114.842453,
              47.297943
            ],
            [
              -114.842324,
              47.268847
            ],
            [
              -114.797204,
              47.268916
            ],
            [
              -114.761193,
              47.259931
            ],
            [
              -114.751653,
              47.26381
            ],
            [
              -114.708847,
              47.254689
            ],
            [
              -114.687295,
              47.27431
            ],
            [
              -114.636642,
              47.26593
            ],
            [
              -114.593458,
              47.257089
            ],
            [
              -114.563737,
              47.254611
            ],
            [
              -114.542704,
              47.239883
            ],
            [
              -114.49132,
              47.220205
            ],
            [
              -114.469089,
              47.21934
            ],
            [
              -114.454395,
              47.207963
            ],
            [
              -114.423858,
              47.210091
            ],
            [
              -114.403361,
              47.196835
            ],
            [
              -114.362241,
              47.187055
            ],
            [
              -114.35384,
              47.190995
            ],
            [
              -114.346111,
              47.176235
            ],
            [
              -114.355922,
              47.157903
            ],
            [
              -114.333079,
              47.145241
            ],
            [
              -114.3026,
              47.136271
            ],
            [
              -114.289478,
              47.125798
            ],
            [
              -114.254478,
              47.120717
            ],
            [
              -114.236088,
              47.134301
            ],
            [
              -114.212098,
              47.127709
            ],
            [
              -114.18717,
              47.138191
            ],
            [
              -114.187062,
              47.181889
            ],
            [
              -114.203119,
              47.181908
            ],
            [
              -114.203026,
              47.355746
            ],
            [
              -114.288557,
              47.355875
            ],
            [
              -114.305213,
              47.362521
            ],
            [
              -114.325392,
              47.35644
            ],
            [
              -114.324081,
              47.365058
            ],
            [
              -114.35308,
              47.38985
            ],
            [
              -114.343046,
              47.407803
            ],
            [
              -114.340725,
              47.440549
            ],
            [
              -114.326635,
              47.451859
            ],
            [
              -114.326169,
              47.467144
            ],
            [
              -114.304732,
              47.462108
            ],
            [
              -114.28418,
              47.468496
            ],
            [
              -114.298654,
              47.496084
            ],
            [
              -114.350356,
              47.496695
            ],
            [
              -114.3703,
              47.481102
            ],
            [
              -114.383453,
              47.483254
            ],
            [
              -114.364242,
              47.520819
            ],
            [
              -114.362605,
              47.541157
            ],
            [
              -114.329114,
              47.557493
            ],
            [
              -114.339166,
              47.569356
            ],
            [
              -114.339571,
              47.585027
            ],
            [
              -114.322124,
              47.595951
            ],
            [
              -114.340833,
              47.58934
            ],
            [
              -114.350364,
              47.602142
            ],
            [
              -114.58376,
              47.601718
            ],
            [
              -114.584454,
              47.746355
            ],
            [
              -114.604857,
              47.746421
            ],
            [
              -114.604462,
              47.789871
            ],
            [
              -114.604584,
              47.875605
            ],
            [
              -114.818683,
              47.875773
            ],
            [
              -114.861117,
              47.872802
            ],
            [
              -114.989344,
              47.872807
            ],
            [
              -114.989433,
              48.003141
            ],
            [
              -115.010678,
              48.003139
            ],
            [
              -115.010672,
              48.017621
            ],
            [
              -115.021077,
              48.017615
            ],
            [
              -115.040756,
              48.017563
            ],
            [
              -115.085376,
              48.017594
            ],
            [
              -115.116292,
              48.017664
            ],
            [
              -115.147949,
              48.017701
            ],
            [
              -115.153938,
              48.014703
            ],
            [
              -115.152183,
              48.011847
            ],
            [
              -115.150264,
              48.008472
            ],
            [
              -115.149607,
              48.002791
            ],
            [
              -115.149669,
              47.995081
            ],
            [
              -115.144956,
              47.990657
            ],
            [
              -115.140851,
              47.987098
            ],
            [
              -115.137658,
              47.982214
            ],
            [
              -115.139928,
              47.978311
            ],
            [
              -115.145784,
              47.975178
            ],
            [
              -115.146204,
              47.9697
            ],
            [
              -115.14813,
              47.963501
            ],
            [
              -115.152522,
              47.962376
            ],
            [
              -115.158879,
              47.962222
            ],
            [
              -115.161637,
              47.967069
            ],
            [
              -115.16728,
              47.973189
            ],
            [
              -115.172077,
              47.974825
            ],
            [
              -115.174691,
              47.972611
            ],
            [
              -115.173612,
              47.970593
            ],
            [
              -115.17398,
              47.96432
            ],
            [
              -115.178748,
              47.959013
            ],
            [
              -115.183127,
              47.958671
            ],
            [
              -115.183204,
              47.954372
            ],
            [
              -115.181365,
              47.952192
            ],
            [
              -115.180209,
              47.947033
            ],
            [
              -115.179599,
              47.942935
            ],
            [
              -115.181252,
              47.939294
            ],
            [
              -115.17948,
              47.935637
            ],
            [
              -115.181812,
              47.930567
            ],
            [
              -115.185052,
              47.924511
            ],
            [
              -115.1865,
              47.920978
            ],
            [
              -115.188533,
              47.918224
            ],
            [
              -115.192556,
              47.917691
            ],
            [
              -115.197025,
              47.918541
            ],
            [
              -115.201723,
              47.916869
            ],
            [
              -115.204787,
              47.918989
            ],
            [
              -115.209335,
              47.916612
            ],
            [
              -115.21409,
              47.914324
            ],
            [
              -115.21945,
              47.911531
            ],
            [
              -115.226758,
              47.908681
            ],
            [
              -115.232902,
              47.909602
            ],
            [
              -115.238997,
              47.912906
            ],
            [
              -115.249241,
              47.913095
            ],
            [
              -115.251223,
              47.909312
            ],
            [
              -115.250819,
              47.907195
            ],
            [
              -115.256198,
              47.90456
            ],
            [
              -115.262763,
              47.903539
            ],
            [
              -115.26919,
              47.89699
            ],
            [
              -115.274479,
              47.893546
            ],
            [
              -115.277651,
              47.891699
            ],
            [
              -115.282824,
              47.890796
            ],
            [
              -115.287214,
              47.893408
            ],
            [
              -115.292921,
              47.898146
            ],
            [
              -115.296149,
              47.903967
            ],
            [
              -115.299488,
              47.910296
            ],
            [
              -115.309563,
              47.913852
            ],
            [
              -115.316634,
              47.919397
            ],
            [
              -115.323989,
              47.919631
            ],
            [
              -115.335879,
              47.917467
            ],
            [
              -115.346398,
              47.914489
            ],
            [
              -115.35563,
              47.911726
            ],
            [
              -115.366614,
              47.907763
            ],
            [
              -115.380769,
              47.904155
            ],
            [
              -115.391231,
              47.909198
            ],
            [
              -115.39995,
              47.914661
            ],
            [
              -115.401911,
              47.921002
            ],
            [
              -115.400365,
              47.928209
            ],
            [
              -115.399185,
              47.932467
            ],
            [
              -115.405135,
              47.935166
            ],
            [
              -115.413071,
              47.934294
            ],
            [
              -115.421659,
              47.933216
            ],
            [
              -115.423926,
              47.929214
            ],
            [
              -115.424857,
              47.925309
            ],
            [
              -115.424507,
              47.91911
            ],
            [
              -115.424649,
              47.914203
            ],
            [
              -115.429855,
              47.911555
            ],
            [
              -115.433866,
              47.912481
            ],
            [
              -115.445481,
              47.915401
            ],
            [
              -115.458941,
              47.919857
            ],
            [
              -115.467789,
              47.916759
            ],
            [
              -115.46863,
              47.914968
            ],
            [
              -115.475999,
              47.915677
            ],
            [
              -115.479603,
              47.917463
            ],
            [
              -115.48485,
              47.916683
            ],
            [
              -115.487502,
              47.914993
            ],
            [
              -115.493534,
              47.911086
            ],
            [
              -115.504455,
              47.908832
            ],
            [
              -115.517554,
              47.908455
            ],
            [
              -115.526685,
              47.911492
            ],
            [
              -115.534958,
              47.915963
            ],
            [
              -115.541,
              47.91954
            ],
            [
              -115.546196,
              47.925496
            ],
            [
              -115.550284,
              47.932235
            ],
            [
              -115.556374,
              47.937093
            ],
            [
              -115.557468,
              47.943555
            ],
            [
              -115.554705,
              47.949435
            ],
            [
              -115.548448,
              47.955785
            ],
            [
              -115.546647,
              47.963662
            ],
            [
              -115.54253,
              47.9706
            ],
            [
              -115.540708,
              47.977205
            ],
            [
              -115.538912,
              47.982088
            ],
            [
              -115.541085,
              47.987068
            ],
            [
              -115.542875,
              47.993552
            ],
            [
              -115.544602,
              47.998412
            ],
            [
              -115.546996,
              48.001158
            ],
            [
              -115.54994,
              48.003809
            ],
            [
              -115.551901,
              48.007513
            ],
            [
              -115.555625,
              48.01059
            ],
            [
              -115.561713,
              48.014144
            ],
            [
              -115.566859,
              48.018127
            ],
            [
              -115.571523,
              48.018843
            ],
            [
              -115.580051,
              48.020304
            ],
            [
              -115.586362,
              48.023836
            ],
            [
              -115.589009,
              48.026976
            ],
            [
              -115.594057,
              48.030714
            ],
            [
              -115.599799,
              48.034237
            ],
            [
              -115.600937,
              48.038656
            ],
            [
              -115.60201,
              48.041497
            ],
            [
              -115.607208,
              48.044486
            ],
            [
              -115.611342,
              48.048327
            ],
            [
              -115.610212,
              48.052496
            ],
            [
              -115.608418,
              48.057236
            ],
            [
              -115.610206,
              48.060143
            ],
            [
              -115.617805,
              48.063197
            ],
            [
              -115.620843,
              48.065303
            ],
            [
              -115.626252,
              48.069646
            ],
            [
              -115.632016,
              48.072372
            ],
            [
              -115.637604,
              48.075144
            ],
            [
              -115.639118,
              48.077697
            ],
            [
              -115.640507,
              48.080412
            ],
            [
              -115.638045,
              48.080681
            ],
            [
              -115.634954,
              48.084913
            ],
            [
              -115.633682,
              48.089886
            ],
            [
              -115.63784,
              48.094979
            ],
            [
              -115.637746,
              48.099657
            ],
            [
              -115.637723,
              48.106508
            ],
            [
              -115.640688,
              48.112377
            ],
            [
              -115.641935,
              48.117058
            ],
            [
              -115.648621,
              48.123042
            ],
            [
              -115.646428,
              48.132178
            ],
            [
              -115.653906,
              48.139183
            ],
            [
              -115.657164,
              48.143334
            ],
            [
              -115.65625,
              48.146923
            ],
            [
              -115.661603,
              48.150583
            ],
            [
              -115.667016,
              48.153863
            ],
            [
              -115.67201,
              48.159658
            ],
            [
              -115.675588,
              48.163397
            ],
            [
              -115.686649,
              48.164706
            ],
            [
              -115.693771,
              48.167833
            ],
            [
              -115.69491,
              48.175451
            ],
            [
              -115.693805,
              48.178947
            ],
            [
              -115.690547,
              48.183313
            ],
            [
              -115.684904,
              48.189467
            ],
            [
              -115.681679,
              48.193864
            ],
            [
              -115.677036,
              48.194426
            ],
            [
              -115.674766,
              48.195506
            ],
            [
              -115.67452,
              48.197702
            ],
            [
              -115.67453,
              48.203648
            ],
            [
              -115.673904,
              48.207552
            ],
            [
              -115.679807,
              48.212781
            ],
            [
              -115.683928,
              48.218166
            ],
            [
              -115.688517,
              48.222657
            ],
            [
              -115.693611,
              48.225038
            ],
            [
              -115.708817,
              48.24739
            ],
            [
              -115.737279,
              48.263292
            ],
            [
              -115.771401,
              48.259064
            ],
            [
              -115.78252,
              48.239714
            ],
            [
              -115.800172,
              48.222818
            ],
            [
              -115.829364,
              48.217383
            ],
            [
              -115.831545,
              48.211318
            ],
            [
              -115.855995,
              48.210707
            ],
            [
              -115.900949,
              48.193956
            ],
            [
              -115.908191,
              48.182753
            ],
            [
              -115.936745,
              48.184166
            ],
            [
              -115.95671,
              48.149072
            ],
            [
              -115.969641,
              48.154879
            ],
            [
              -116.004505,
              48.157828
            ],
            [
              -116.018005,
              48.169538
            ],
            [
              -116.016723,
              48.206217
            ],
            [
              -116.049773718343,
              48.216117548345196
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "069",
      "COUNTYNS": "01719590",
      "AFFGEOID": "0500000US30069",
      "GEOID": "30069",
      "NAME": "Petroleum",
      "LSAD": "06",
      "ALAND": 4288047856,
      "AWATER": 48927318,
      "County_state": "Petroleum,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -108.736077,
              47.183414
            ],
            [
              -108.736838,
              47.098218
            ],
            [
              -108.608954,
              47.098126
            ],
            [
              -108.611116,
              46.836776
            ],
            [
              -108.630689,
              46.836807
            ],
            [
              -108.631462,
              46.749392
            ],
            [
              -108.379299,
              46.749555
            ],
            [
              -108.261779,
              46.754232
            ],
            [
              -107.827687,
              46.755875
            ],
            [
              -107.845747,
              46.780484
            ],
            [
              -107.8416,
              46.801646
            ],
            [
              -107.873446,
              46.835011
            ],
            [
              -107.8767,
              46.845031
            ],
            [
              -107.897356,
              46.849722
            ],
            [
              -107.892233,
              46.851215
            ],
            [
              -107.89173,
              46.852031
            ],
            [
              -107.896772,
              46.853561
            ],
            [
              -107.902161,
              46.852838
            ],
            [
              -107.906537,
              46.855888
            ],
            [
              -107.90518,
              46.859929
            ],
            [
              -107.901044,
              46.862442
            ],
            [
              -107.903332,
              46.865376
            ],
            [
              -107.911045,
              46.865727
            ],
            [
              -107.916942,
              46.86687
            ],
            [
              -107.9199,
              46.86954
            ],
            [
              -107.917945,
              46.872161
            ],
            [
              -107.918093,
              46.875741
            ],
            [
              -107.921213,
              46.87561
            ],
            [
              -107.930808,
              46.877427
            ],
            [
              -107.933282,
              46.875082
            ],
            [
              -107.939716,
              46.877671
            ],
            [
              -107.941213,
              46.881946
            ],
            [
              -107.938282,
              46.885366
            ],
            [
              -107.938405,
              46.890133
            ],
            [
              -107.940461,
              46.891227
            ],
            [
              -107.941377,
              46.893723
            ],
            [
              -107.935539,
              46.895866
            ],
            [
              -107.931188,
              46.897352
            ],
            [
              -107.931373,
              46.901435
            ],
            [
              -107.933762,
              46.903778
            ],
            [
              -107.930001,
              46.910239
            ],
            [
              -107.933181,
              46.913287
            ],
            [
              -107.929136,
              46.918146
            ],
            [
              -107.925986,
              46.917529
            ],
            [
              -107.920757,
              46.917484
            ],
            [
              -107.917781,
              46.919865
            ],
            [
              -107.921169,
              46.921857
            ],
            [
              -107.923778,
              46.922796
            ],
            [
              -107.926458,
              46.923892
            ],
            [
              -107.926249,
              46.925834
            ],
            [
              -107.924204,
              46.928133
            ],
            [
              -107.920108,
              46.92865
            ],
            [
              -107.916158,
              46.928491
            ],
            [
              -107.915518,
              46.930392
            ],
            [
              -107.918896,
              46.930179
            ],
            [
              -107.923358,
              46.931485
            ],
            [
              -107.925189,
              46.931742
            ],
            [
              -107.92367,
              46.935107
            ],
            [
              -107.917045,
              46.937082
            ],
            [
              -107.90997,
              46.936255
            ],
            [
              -107.905841,
              46.936823
            ],
            [
              -107.908289,
              46.939062
            ],
            [
              -107.913208,
              46.940798
            ],
            [
              -107.916595,
              46.944265
            ],
            [
              -107.912454,
              46.950825
            ],
            [
              -107.90876,
              46.953136
            ],
            [
              -107.90527,
              46.954476
            ],
            [
              -107.909381,
              46.956982
            ],
            [
              -107.91093,
              46.95947
            ],
            [
              -107.908953,
              46.961995
            ],
            [
              -107.907161,
              46.96171
            ],
            [
              -107.905867,
              46.964262
            ],
            [
              -107.90851,
              46.965827
            ],
            [
              -107.911586,
              46.969063
            ],
            [
              -107.913477,
              46.96857
            ],
            [
              -107.910418,
              46.971193
            ],
            [
              -107.90588,
              46.973206
            ],
            [
              -107.901231,
              46.972985
            ],
            [
              -107.894097,
              46.977799
            ],
            [
              -107.889396,
              46.978879
            ],
            [
              -107.891388,
              46.979272
            ],
            [
              -107.89515,
              46.980766
            ],
            [
              -107.895867,
              46.981445
            ],
            [
              -107.892457,
              46.98257
            ],
            [
              -107.887997,
              46.983201
            ],
            [
              -107.885506,
              46.986103
            ],
            [
              -107.887209,
              46.988239
            ],
            [
              -107.890865,
              46.991569
            ],
            [
              -107.889369,
              46.995061
            ],
            [
              -107.886317,
              46.997583
            ],
            [
              -107.884845,
              47.000148
            ],
            [
              -107.888583,
              47.005552
            ],
            [
              -107.891815,
              47.009391
            ],
            [
              -107.896924,
              47.010307
            ],
            [
              -107.897975,
              47.016874
            ],
            [
              -107.901551,
              47.019316
            ],
            [
              -107.90383,
              47.018371
            ],
            [
              -107.905003,
              47.015766
            ],
            [
              -107.909435,
              47.012957
            ],
            [
              -107.916226,
              47.015734
            ],
            [
              -107.920487,
              47.020786
            ],
            [
              -107.92181,
              47.022312
            ],
            [
              -107.918456,
              47.025898
            ],
            [
              -107.915079,
              47.027783
            ],
            [
              -107.907788,
              47.029471
            ],
            [
              -107.906678,
              47.027958
            ],
            [
              -107.905081,
              47.027416
            ],
            [
              -107.906272,
              47.031364
            ],
            [
              -107.907278,
              47.035392
            ],
            [
              -107.91406,
              47.039584
            ],
            [
              -107.920419,
              47.043728
            ],
            [
              -107.92537,
              47.045044
            ],
            [
              -107.924783,
              47.047449
            ],
            [
              -107.922511,
              47.048125
            ],
            [
              -107.921059,
              47.048666
            ],
            [
              -107.925661,
              47.05228
            ],
            [
              -107.925401,
              47.054342
            ],
            [
              -107.921958,
              47.057226
            ],
            [
              -107.918475,
              47.058714
            ],
            [
              -107.917763,
              47.061335
            ],
            [
              -107.920423,
              47.061117
            ],
            [
              -107.921359,
              47.06034
            ],
            [
              -107.924035,
              47.061458
            ],
            [
              -107.924311,
              47.063459
            ],
            [
              -107.92221,
              47.065552
            ],
            [
              -107.917806,
              47.067259
            ],
            [
              -107.913912,
              47.069243
            ],
            [
              -107.914642,
              47.070273
            ],
            [
              -107.919298,
              47.069339
            ],
            [
              -107.923677,
              47.069482
            ],
            [
              -107.926165,
              47.072865
            ],
            [
              -107.92229,
              47.076882
            ],
            [
              -107.920413,
              47.077771
            ],
            [
              -107.922202,
              47.082317
            ],
            [
              -107.929081,
              47.082453
            ],
            [
              -107.92855,
              47.079548
            ],
            [
              -107.927771,
              47.077276
            ],
            [
              -107.932146,
              47.077606
            ],
            [
              -107.933705,
              47.08054
            ],
            [
              -107.935128,
              47.083968
            ],
            [
              -107.936569,
              47.083616
            ],
            [
              -107.939558,
              47.083428
            ],
            [
              -107.940792,
              47.087064
            ],
            [
              -107.93864,
              47.086681
            ],
            [
              -107.933272,
              47.091163
            ],
            [
              -107.937109,
              47.09926
            ],
            [
              -107.939704,
              47.105101
            ],
            [
              -107.946651,
              47.107632
            ],
            [
              -107.951997,
              47.109921
            ],
            [
              -107.949024,
              47.110984
            ],
            [
              -107.943099,
              47.109289
            ],
            [
              -107.937972,
              47.111653
            ],
            [
              -107.93827,
              47.112926
            ],
            [
              -107.934527,
              47.112792
            ],
            [
              -107.933496,
              47.111571
            ],
            [
              -107.931365,
              47.11254
            ],
            [
              -107.930961,
              47.114885
            ],
            [
              -107.935033,
              47.116293
            ],
            [
              -107.93965,
              47.117365
            ],
            [
              -107.947713,
              47.121275
            ],
            [
              -107.954973,
              47.121261
            ],
            [
              -107.960026,
              47.126657
            ],
            [
              -107.956646,
              47.131812
            ],
            [
              -107.954756,
              47.13501
            ],
            [
              -107.951743,
              47.132744
            ],
            [
              -107.952681,
              47.131925
            ],
            [
              -107.950299,
              47.130998
            ],
            [
              -107.945074,
              47.132799
            ],
            [
              -107.94277,
              47.136283
            ],
            [
              -107.942647,
              47.139464
            ],
            [
              -107.948129,
              47.138582
            ],
            [
              -107.952287,
              47.138138
            ],
            [
              -107.954322,
              47.140516
            ],
            [
              -107.95038,
              47.14107
            ],
            [
              -107.945085,
              47.140868
            ],
            [
              -107.94146,
              47.146707
            ],
            [
              -107.946854,
              47.14782
            ],
            [
              -107.949382,
              47.153532
            ],
            [
              -107.953167,
              47.156055
            ],
            [
              -107.949044,
              47.159581
            ],
            [
              -107.947939,
              47.158755
            ],
            [
              -107.944967,
              47.159235
            ],
            [
              -107.944587,
              47.159729
            ],
            [
              -107.942967,
              47.156712
            ],
            [
              -107.941912,
              47.155262
            ],
            [
              -107.941366,
              47.153549
            ],
            [
              -107.939468,
              47.157223
            ],
            [
              -107.941014,
              47.16305
            ],
            [
              -107.945891,
              47.167318
            ],
            [
              -107.944584,
              47.171356
            ],
            [
              -107.940109,
              47.169985
            ],
            [
              -107.93513,
              47.16609
            ],
            [
              -107.929484,
              47.16724
            ],
            [
              -107.9295,
              47.170839
            ],
            [
              -107.929865,
              47.175333
            ],
            [
              -107.924938,
              47.177661
            ],
            [
              -107.926304,
              47.180966
            ],
            [
              -107.931951,
              47.180877
            ],
            [
              -107.936302,
              47.178128
            ],
            [
              -107.936933,
              47.179489
            ],
            [
              -107.932876,
              47.183899
            ],
            [
              -107.933362,
              47.187566
            ],
            [
              -107.93704,
              47.18798
            ],
            [
              -107.9351,
              47.19107
            ],
            [
              -107.933804,
              47.190546
            ],
            [
              -107.933075,
              47.193497
            ],
            [
              -107.939947,
              47.193154
            ],
            [
              -107.944071,
              47.195248
            ],
            [
              -107.948286,
              47.194942
            ],
            [
              -107.950337,
              47.196955
            ],
            [
              -107.947104,
              47.199012
            ],
            [
              -107.945837,
              47.199571
            ],
            [
              -107.9437,
              47.203771
            ],
            [
              -107.939901,
              47.208441
            ],
            [
              -107.938909,
              47.217309
            ],
            [
              -107.943763,
              47.218843
            ],
            [
              -107.945249,
              47.221616
            ],
            [
              -107.944667,
              47.224002
            ],
            [
              -107.948166,
              47.226862
            ],
            [
              -107.951504,
              47.226652
            ],
            [
              -107.951143,
              47.224942
            ],
            [
              -107.955952,
              47.224424
            ],
            [
              -107.959776,
              47.225224
            ],
            [
              -107.96329,
              47.228287
            ],
            [
              -107.964594,
              47.230315
            ],
            [
              -107.960177,
              47.230346
            ],
            [
              -107.959821,
              47.227996
            ],
            [
              -107.955627,
              47.229549
            ],
            [
              -107.956689,
              47.233773
            ],
            [
              -107.959886,
              47.236187
            ],
            [
              -107.960702,
              47.238631
            ],
            [
              -107.954597,
              47.239569
            ],
            [
              -107.952237,
              47.237748
            ],
            [
              -107.949091,
              47.239689
            ],
            [
              -107.94949,
              47.243735
            ],
            [
              -107.953524,
              47.245406
            ],
            [
              -107.956583,
              47.245877
            ],
            [
              -107.957935,
              47.245067
            ],
            [
              -107.960959,
              47.245603
            ],
            [
              -107.962692,
              47.247819
            ],
            [
              -107.967477,
              47.248352
            ],
            [
              -107.966521,
              47.252695
            ],
            [
              -107.964158,
              47.254881
            ],
            [
              -107.958827,
              47.257018
            ],
            [
              -107.955704,
              47.255142
            ],
            [
              -107.950731,
              47.25474
            ],
            [
              -107.949363,
              47.256322
            ],
            [
              -107.947927,
              47.257824
            ],
            [
              -107.951719,
              47.259638
            ],
            [
              -107.956686,
              47.260271
            ],
            [
              -107.963121,
              47.263516
            ],
            [
              -107.961339,
              47.267596
            ],
            [
              -107.958679,
              47.268472
            ],
            [
              -107.955678,
              47.269404
            ],
            [
              -107.956036,
              47.268978
            ],
            [
              -107.956204,
              47.26846
            ],
            [
              -107.953959,
              47.26687
            ],
            [
              -107.953369,
              47.270335
            ],
            [
              -107.95267,
              47.272862
            ],
            [
              -107.958719,
              47.274837
            ],
            [
              -107.961732,
              47.274354
            ],
            [
              -107.964331,
              47.276036
            ],
            [
              -107.963456,
              47.277507
            ],
            [
              -107.959175,
              47.281949
            ],
            [
              -107.953411,
              47.284413
            ],
            [
              -107.949578,
              47.28385
            ],
            [
              -107.952832,
              47.288089
            ],
            [
              -107.95956,
              47.289027
            ],
            [
              -107.963167,
              47.290191
            ],
            [
              -107.961207,
              47.292114
            ],
            [
              -107.960501,
              47.293903
            ],
            [
              -107.955815,
              47.296072
            ],
            [
              -107.952627,
              47.295159
            ],
            [
              -107.949867,
              47.29646
            ],
            [
              -107.949192,
              47.299374
            ],
            [
              -107.948894,
              47.302851
            ],
            [
              -107.950232,
              47.302396
            ],
            [
              -107.954329,
              47.303246
            ],
            [
              -107.956414,
              47.309333
            ],
            [
              -107.948086,
              47.315937
            ],
            [
              -107.940068,
              47.31735
            ],
            [
              -107.937042,
              47.318321
            ],
            [
              -107.937032,
              47.320317
            ],
            [
              -107.941465,
              47.321467
            ],
            [
              -107.944086,
              47.321468
            ],
            [
              -107.951861,
              47.320383
            ],
            [
              -107.955178,
              47.320294
            ],
            [
              -107.954266,
              47.319276
            ],
            [
              -107.953752,
              47.316972
            ],
            [
              -107.957625,
              47.318497
            ],
            [
              -107.956833,
              47.323194
            ],
            [
              -107.95503,
              47.324241
            ],
            [
              -107.958229,
              47.326666
            ],
            [
              -107.96299,
              47.330394
            ],
            [
              -107.961633,
              47.332335
            ],
            [
              -107.95595,
              47.33558
            ],
            [
              -107.954745,
              47.341997
            ],
            [
              -107.951997,
              47.343924
            ],
            [
              -107.956315,
              47.347842
            ],
            [
              -107.960637,
              47.348894
            ],
            [
              -107.962329,
              47.352184
            ],
            [
              -107.958578,
              47.355073
            ],
            [
              -107.954703,
              47.354737
            ],
            [
              -107.952589,
              47.358002
            ],
            [
              -107.955192,
              47.359037
            ],
            [
              -107.953328,
              47.362045
            ],
            [
              -107.948414,
              47.364358
            ],
            [
              -107.942546,
              47.366287
            ],
            [
              -107.93992,
              47.369576
            ],
            [
              -107.940821,
              47.371443
            ],
            [
              -107.940227,
              47.373846
            ],
            [
              -107.942414,
              47.377486
            ],
            [
              -107.936739,
              47.377275
            ],
            [
              -107.932582,
              47.376578
            ],
            [
              -107.930241,
              47.380814
            ],
            [
              -107.932876,
              47.383492
            ],
            [
              -107.93639,
              47.385793
            ],
            [
              -107.936084,
              47.387217
            ],
            [
              -107.928618,
              47.386672
            ],
            [
              -107.924714,
              47.389561
            ],
            [
              -107.919948,
              47.397259
            ],
            [
              -107.914865,
              47.40143
            ],
            [
              -107.910573,
              47.404759
            ],
            [
              -107.910678,
              47.410806
            ],
            [
              -107.91263,
              47.413871
            ],
            [
              -107.918274,
              47.418277
            ],
            [
              -107.915748,
              47.417015
            ],
            [
              -107.911464,
              47.417358
            ],
            [
              -107.91004,
              47.422116
            ],
            [
              -107.912928,
              47.424496
            ],
            [
              -107.910397,
              47.430144
            ],
            [
              -107.915144,
              47.433785
            ],
            [
              -107.916414,
              47.43346
            ],
            [
              -107.918085,
              47.436793
            ],
            [
              -107.91901,
              47.437765
            ],
            [
              -107.918459,
              47.439699
            ],
            [
              -107.915091,
              47.440166
            ],
            [
              -107.918077,
              47.443687
            ],
            [
              -107.920493,
              47.443979
            ],
            [
              -107.916455,
              47.446843
            ],
            [
              -107.90912,
              47.450692
            ],
            [
              -107.909119,
              47.488011
            ],
            [
              -107.898107,
              47.510071
            ],
            [
              -107.919184,
              47.520588
            ],
            [
              -107.919502,
              47.535189
            ],
            [
              -107.930208,
              47.544324
            ],
            [
              -107.928591,
              47.567612
            ],
            [
              -107.96158,
              47.569875
            ],
            [
              -107.976184,
              47.585931
            ],
            [
              -108.022231,
              47.582066
            ],
            [
              -108.065418,
              47.598294
            ],
            [
              -108.079585,
              47.592012
            ],
            [
              -108.137272,
              47.592428
            ],
            [
              -108.147544,
              47.583642
            ],
            [
              -108.178548,
              47.580064
            ],
            [
              -108.19957,
              47.589725
            ],
            [
              -108.237974,
              47.582369
            ],
            [
              -108.259619,
              47.587987
            ],
            [
              -108.282375,
              47.579615
            ],
            [
              -108.313048,
              47.58365
            ],
            [
              -108.31549,
              47.531071
            ],
            [
              -108.315481,
              47.315152
            ],
            [
              -108.3368,
              47.307932
            ],
            [
              -108.464255,
              47.306838
            ],
            [
              -108.590835,
              47.306516
            ],
            [
              -108.591111,
              47.270135
            ],
            [
              -108.719189,
              47.270342
            ],
            [
              -108.719283,
              47.183358
            ],
            [
              -108.736077,
              47.183414
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "007",
      "COUNTYNS": "01719625",
      "AFFGEOID": "0500000US30007",
      "GEOID": "30007",
      "NAME": "Broadwater",
      "LSAD": "06",
      "ALAND": 3088195835,
      "AWATER": 118487318,
      "County_state": "Broadwater,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -111.787949,
              46.569465
            ],
            [
              -111.786418,
              46.136277
            ],
            [
              -111.784565,
              46.049813
            ],
            [
              -111.660422,
              46.049192
            ],
            [
              -111.66032,
              45.919835
            ],
            [
              -111.660057,
              45.8335
            ],
            [
              -111.659056,
              45.833809
            ],
            [
              -111.655229,
              45.836394
            ],
            [
              -111.655399,
              45.841346
            ],
            [
              -111.654505,
              45.844408
            ],
            [
              -111.651424,
              45.848773
            ],
            [
              -111.649406,
              45.851291
            ],
            [
              -111.645027,
              45.850269
            ],
            [
              -111.643719,
              45.84908
            ],
            [
              -111.641398,
              45.847926
            ],
            [
              -111.637325,
              45.849563
            ],
            [
              -111.638615,
              45.85173
            ],
            [
              -111.640188,
              45.853762
            ],
            [
              -111.637021,
              45.860631
            ],
            [
              -111.634035,
              45.86265
            ],
            [
              -111.628382,
              45.862203
            ],
            [
              -111.625736,
              45.861772
            ],
            [
              -111.625474,
              45.863592
            ],
            [
              -111.623395,
              45.866141
            ],
            [
              -111.62284,
              45.868836
            ],
            [
              -111.617718,
              45.872131
            ],
            [
              -111.612244,
              45.875681
            ],
            [
              -111.610408,
              45.882013
            ],
            [
              -111.608979,
              45.887666
            ],
            [
              -111.603341,
              45.891766
            ],
            [
              -111.598428,
              45.895885
            ],
            [
              -111.592512,
              45.900495
            ],
            [
              -111.588241,
              45.905988
            ],
            [
              -111.578648,
              45.907233
            ],
            [
              -111.572918,
              45.910007
            ],
            [
              -111.566272,
              45.910107
            ],
            [
              -111.563968,
              45.912911
            ],
            [
              -111.559742,
              45.914811
            ],
            [
              -111.55763,
              45.913249
            ],
            [
              -111.552973,
              45.9126
            ],
            [
              -111.550464,
              45.910492
            ],
            [
              -111.546753,
              45.910157
            ],
            [
              -111.54389,
              45.912372
            ],
            [
              -111.543356,
              45.916389
            ],
            [
              -111.543558,
              45.918401
            ],
            [
              -111.541298,
              45.919713
            ],
            [
              -111.53891,
              45.91914
            ],
            [
              -111.538328,
              45.917255
            ],
            [
              -111.532899,
              45.918541
            ],
            [
              -111.532908,
              45.921291
            ],
            [
              -111.530554,
              45.92469
            ],
            [
              -111.5271,
              45.924694
            ],
            [
              -111.519819,
              45.926528
            ],
            [
              -111.515515,
              45.926541
            ],
            [
              -111.513773,
              45.927741
            ],
            [
              -111.510304,
              45.926933
            ],
            [
              -111.508648,
              45.925949
            ],
            [
              -111.505836,
              45.926817
            ],
            [
              -111.506687,
              45.928489
            ],
            [
              -111.506439,
              45.931721
            ],
            [
              -111.500149,
              45.932714
            ],
            [
              -111.495519,
              45.935023
            ],
            [
              -111.492009,
              45.939655
            ],
            [
              -111.484127,
              45.944255
            ],
            [
              -111.476494,
              45.949469
            ],
            [
              -111.468158,
              45.955019
            ],
            [
              -111.463843,
              45.965395
            ],
            [
              -111.464232,
              45.975522
            ],
            [
              -111.464449,
              45.982623
            ],
            [
              -111.463274,
              45.990742
            ],
            [
              -111.461694,
              45.994736
            ],
            [
              -111.455707,
              45.998666
            ],
            [
              -111.450831,
              45.99712
            ],
            [
              -111.444792,
              45.991851
            ],
            [
              -111.439586,
              45.991398
            ],
            [
              -111.430609,
              45.993013
            ],
            [
              -111.421545,
              45.995067
            ],
            [
              -111.415993,
              46.001509
            ],
            [
              -111.421248,
              46.015208
            ],
            [
              -111.427321,
              46.017438
            ],
            [
              -111.426539,
              46.023392
            ],
            [
              -111.422435,
              46.028236
            ],
            [
              -111.423465,
              46.033624
            ],
            [
              -111.424158,
              46.043621
            ],
            [
              -111.423456,
              46.051062
            ],
            [
              -111.424126,
              46.060721
            ],
            [
              -111.416124,
              46.071025
            ],
            [
              -111.408065,
              46.082159
            ],
            [
              -111.395868,
              46.088699
            ],
            [
              -111.392334,
              46.094874
            ],
            [
              -111.397068,
              46.103797
            ],
            [
              -111.395538,
              46.107411
            ],
            [
              -111.394859,
              46.108366
            ],
            [
              -111.392686,
              46.107983
            ],
            [
              -111.390876,
              46.106916
            ],
            [
              -111.388934,
              46.106229
            ],
            [
              -111.386572,
              46.104752
            ],
            [
              -111.384395,
              46.104142
            ],
            [
              -111.383621,
              46.10536
            ],
            [
              -111.381893,
              46.106517
            ],
            [
              -111.381448,
              46.108903
            ],
            [
              -111.377665,
              46.113735
            ],
            [
              -111.375017,
              46.114458
            ],
            [
              -111.372571,
              46.114246
            ],
            [
              -111.371077,
              46.114314
            ],
            [
              -111.370087,
              46.115372
            ],
            [
              -111.367193,
              46.116212
            ],
            [
              -111.363896,
              46.117048
            ],
            [
              -111.360914,
              46.117658
            ],
            [
              -111.357317,
              46.118587
            ],
            [
              -111.355543,
              46.119959
            ],
            [
              -111.353051,
              46.121321
            ],
            [
              -111.349413,
              46.127883
            ],
            [
              -111.346635,
              46.152741
            ],
            [
              -111.305562,
              46.187784
            ],
            [
              -111.26466,
              46.187759
            ],
            [
              -111.193183,
              46.187833
            ],
            [
              -111.149312,
              46.192804
            ],
            [
              -111.061956,
              46.192834
            ],
            [
              -111.060622,
              46.200162
            ],
            [
              -111.062272,
              46.215175
            ],
            [
              -111.063044,
              46.222435
            ],
            [
              -111.065926,
              46.226226
            ],
            [
              -111.072191,
              46.23327
            ],
            [
              -111.078791,
              46.233324
            ],
            [
              -111.083294,
              46.238436
            ],
            [
              -111.081739,
              46.242527
            ],
            [
              -111.078762,
              46.247393
            ],
            [
              -111.078881,
              46.250451
            ],
            [
              -111.09291,
              46.256589
            ],
            [
              -111.098892,
              46.257004
            ],
            [
              -111.105031,
              46.258428
            ],
            [
              -111.108016,
              46.260404
            ],
            [
              -111.107557,
              46.26254
            ],
            [
              -111.109534,
              46.26483
            ],
            [
              -111.113618,
              46.265433
            ],
            [
              -111.117832,
              46.266994
            ],
            [
              -111.123264,
              46.271542
            ],
            [
              -111.124951,
              46.276573
            ],
            [
              -111.12322,
              46.28345
            ],
            [
              -111.119859,
              46.293432
            ],
            [
              -111.120595,
              46.300846
            ],
            [
              -111.118578,
              46.312657
            ],
            [
              -111.10895,
              46.32479
            ],
            [
              -111.099698,
              46.334977
            ],
            [
              -111.094826,
              46.342701
            ],
            [
              -111.082092,
              46.34733
            ],
            [
              -111.073919,
              46.348202
            ],
            [
              -111.068648,
              46.352454
            ],
            [
              -111.071135,
              46.35689
            ],
            [
              -111.070144,
              46.363911
            ],
            [
              -111.072884,
              46.369226
            ],
            [
              -111.071331,
              46.374926
            ],
            [
              -111.064289,
              46.381445
            ],
            [
              -111.064632,
              46.387373
            ],
            [
              -111.06419,
              46.393057
            ],
            [
              -111.058607,
              46.39637
            ],
            [
              -111.058128,
              46.404001
            ],
            [
              -111.06183,
              46.408571
            ],
            [
              -111.067291,
              46.410065
            ],
            [
              -111.076651,
              46.413298
            ],
            [
              -111.08174,
              46.418664
            ],
            [
              -111.087564,
              46.421709
            ],
            [
              -111.093445,
              46.42318
            ],
            [
              -111.095972,
              46.427971
            ],
            [
              -111.097512,
              46.431782
            ],
            [
              -111.10277,
              46.434482
            ],
            [
              -111.111147,
              46.435237
            ],
            [
              -111.114747,
              46.437683
            ],
            [
              -111.122159,
              46.438936
            ],
            [
              -111.125128,
              46.44319
            ],
            [
              -111.121542,
              46.446563
            ],
            [
              -111.120613,
              46.450486
            ],
            [
              -111.12662,
              46.452633
            ],
            [
              -111.131588,
              46.450529
            ],
            [
              -111.144715,
              46.450322
            ],
            [
              -111.155838,
              46.449123
            ],
            [
              -111.165865,
              46.448035
            ],
            [
              -111.17635,
              46.441332
            ],
            [
              -111.184895,
              46.432647
            ],
            [
              -111.197046,
              46.428677
            ],
            [
              -111.211423,
              46.428334
            ],
            [
              -111.221924,
              46.429688
            ],
            [
              -111.233719,
              46.43449
            ],
            [
              -111.240866,
              46.436822
            ],
            [
              -111.247579,
              46.440147
            ],
            [
              -111.251258,
              46.447262
            ],
            [
              -111.248329,
              46.455765
            ],
            [
              -111.250104,
              46.462687
            ],
            [
              -111.252409,
              46.472557
            ],
            [
              -111.256494,
              46.479868
            ],
            [
              -111.253654,
              46.486959
            ],
            [
              -111.255472,
              46.493327
            ],
            [
              -111.256934,
              46.49727
            ],
            [
              -111.262615,
              46.498495
            ],
            [
              -111.265182,
              46.497559
            ],
            [
              -111.270104,
              46.500708
            ],
            [
              -111.272411,
              46.503739
            ],
            [
              -111.27637,
              46.508763
            ],
            [
              -111.277281,
              46.517281
            ],
            [
              -111.28926,
              46.525588
            ],
            [
              -111.300957,
              46.52858
            ],
            [
              -111.311775,
              46.530596
            ],
            [
              -111.317851,
              46.531416
            ],
            [
              -111.330004,
              46.536019
            ],
            [
              -111.339017,
              46.539106
            ],
            [
              -111.341097,
              46.542473
            ],
            [
              -111.341743,
              46.548374
            ],
            [
              -111.33461,
              46.551722
            ],
            [
              -111.326193,
              46.556266
            ],
            [
              -111.32071,
              46.560696
            ],
            [
              -111.319826,
              46.566925
            ],
            [
              -111.32727,
              46.573106
            ],
            [
              -111.334086,
              46.579161
            ],
            [
              -111.330636,
              46.585056
            ],
            [
              -111.329914,
              46.591181
            ],
            [
              -111.333587,
              46.599304
            ],
            [
              -111.333737,
              46.607648
            ],
            [
              -111.329219,
              46.611463
            ],
            [
              -111.327,
              46.613372
            ],
            [
              -111.32548,
              46.615307
            ],
            [
              -111.32822,
              46.617021
            ],
            [
              -111.328365,
              46.62072
            ],
            [
              -111.327431,
              46.62647
            ],
            [
              -111.33073,
              46.62965
            ],
            [
              -111.330223,
              46.632322
            ],
            [
              -111.3355,
              46.634555
            ],
            [
              -111.340187,
              46.635697
            ],
            [
              -111.344722,
              46.634607
            ],
            [
              -111.34912,
              46.635839
            ],
            [
              -111.358727,
              46.634797
            ],
            [
              -111.36669,
              46.635528
            ],
            [
              -111.370654,
              46.641636
            ],
            [
              -111.376175,
              46.644557
            ],
            [
              -111.381146,
              46.645349
            ],
            [
              -111.391297,
              46.644421
            ],
            [
              -111.399012,
              46.64403
            ],
            [
              -111.407284,
              46.63951
            ],
            [
              -111.410822,
              46.639601
            ],
            [
              -111.410905,
              46.643125
            ],
            [
              -111.412792,
              46.64701
            ],
            [
              -111.408519,
              46.655361
            ],
            [
              -111.412489,
              46.663317
            ],
            [
              -111.41418,
              46.670934
            ],
            [
              -111.420203,
              46.675368
            ],
            [
              -111.4233,
              46.679213
            ],
            [
              -111.428866,
              46.681513
            ],
            [
              -111.437947,
              46.680641
            ],
            [
              -111.446231,
              46.681659
            ],
            [
              -111.449939,
              46.682277
            ],
            [
              -111.450235,
              46.685039
            ],
            [
              -111.448023,
              46.685807
            ],
            [
              -111.445064,
              46.689709
            ],
            [
              -111.443563,
              46.691767
            ],
            [
              -111.446038,
              46.696212
            ],
            [
              -111.446692,
              46.699692
            ],
            [
              -111.450927,
              46.702136
            ],
            [
              -111.455063,
              46.702488
            ],
            [
              -111.458004,
              46.703719
            ],
            [
              -111.461567,
              46.704325
            ],
            [
              -111.461218,
              46.706279
            ],
            [
              -111.455848,
              46.710288
            ],
            [
              -111.450787,
              46.714736
            ],
            [
              -111.449004,
              46.7173
            ],
            [
              -111.451606,
              46.718919
            ],
            [
              -111.454844,
              46.71928
            ],
            [
              -111.460822,
              46.722129
            ],
            [
              -111.463151,
              46.723656
            ],
            [
              -111.461679,
              46.725866
            ],
            [
              -111.459384,
              46.727652
            ],
            [
              -111.457474,
              46.727767
            ],
            [
              -111.457015,
              46.729209
            ],
            [
              -111.458198,
              46.730178
            ],
            [
              -111.456734,
              46.73405
            ],
            [
              -111.45592,
              46.74089
            ],
            [
              -111.457094,
              46.745339
            ],
            [
              -111.461722,
              46.747441
            ],
            [
              -111.467744,
              46.752384
            ],
            [
              -111.475139,
              46.752996
            ],
            [
              -111.479232,
              46.755833
            ],
            [
              -111.483513,
              46.755738
            ],
            [
              -111.48874,
              46.757331
            ],
            [
              -111.496208,
              46.761959
            ],
            [
              -111.498095,
              46.762502
            ],
            [
              -111.508887,
              46.759565
            ],
            [
              -111.544694,
              46.769188
            ],
            [
              -111.561771,
              46.766413
            ],
            [
              -111.56733,
              46.776866
            ],
            [
              -111.587,
              46.781852
            ],
            [
              -111.602526,
              46.778259
            ],
            [
              -111.624978,
              46.746337
            ],
            [
              -111.639261,
              46.73818
            ],
            [
              -111.640095,
              46.582463
            ],
            [
              -111.631906,
              46.569972
            ],
            [
              -111.787949,
              46.569465
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "065",
      "COUNTYNS": "01719593",
      "AFFGEOID": "0500000US30065",
      "GEOID": "30065",
      "NAME": "Musselshell",
      "LSAD": "06",
      "ALAND": 4840577752,
      "AWATER": 7118682,
      "County_state": "Musselshell,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -109.00957,
              46.750702
            ],
            [
              -109.009982,
              46.621172
            ],
            [
              -108.988727,
              46.62124
            ],
            [
              -108.988995,
              46.577851
            ],
            [
              -108.904904,
              46.576908
            ],
            [
              -108.905259,
              46.489805
            ],
            [
              -108.880114,
              46.489737
            ],
            [
              -108.881795,
              46.45173
            ],
            [
              -108.860801,
              46.451665
            ],
            [
              -108.862277,
              46.364586
            ],
            [
              -108.841331,
              46.364563
            ],
            [
              -108.841724,
              46.350064
            ],
            [
              -108.831262,
              46.350091
            ],
            [
              -108.83161,
              46.320999
            ],
            [
              -108.821158,
              46.321013
            ],
            [
              -108.821129,
              46.306456
            ],
            [
              -108.800203,
              46.306441
            ],
            [
              -108.800141,
              46.277405
            ],
            [
              -108.779272,
              46.277398
            ],
            [
              -108.779733,
              46.132457
            ],
            [
              -108.404795,
              46.132952
            ],
            [
              -108.404856,
              46.23596
            ],
            [
              -108.36294,
              46.235584
            ],
            [
              -108.362927,
              46.25003
            ],
            [
              -108.320952,
              46.249664
            ],
            [
              -108.320812,
              46.263997
            ],
            [
              -108.029363,
              46.264055
            ],
            [
              -108.02933,
              46.307841
            ],
            [
              -108.011597,
              46.307822
            ],
            [
              -108.011948,
              46.336813
            ],
            [
              -107.990834,
              46.337014
            ],
            [
              -107.991101,
              46.35147
            ],
            [
              -107.970009,
              46.351474
            ],
            [
              -107.970165,
              46.365967
            ],
            [
              -107.949322,
              46.36606
            ],
            [
              -107.949395,
              46.380596
            ],
            [
              -107.9285,
              46.380796
            ],
            [
              -107.928547,
              46.395284
            ],
            [
              -107.782087,
              46.395182
            ],
            [
              -107.781214,
              46.496014
            ],
            [
              -107.796755,
              46.495874
            ],
            [
              -107.795473,
              46.596652
            ],
            [
              -107.837732,
              46.596647
            ],
            [
              -107.816987,
              46.615751
            ],
            [
              -107.812174,
              46.632306
            ],
            [
              -107.820919,
              46.641483
            ],
            [
              -107.808655,
              46.662646
            ],
            [
              -107.792957,
              46.680187
            ],
            [
              -107.800323,
              46.692267
            ],
            [
              -107.822949,
              46.703141
            ],
            [
              -107.816882,
              46.708428
            ],
            [
              -107.82719,
              46.726655
            ],
            [
              -107.809508,
              46.728798
            ],
            [
              -107.813427,
              46.743319
            ],
            [
              -107.827687,
              46.755875
            ],
            [
              -108.261779,
              46.754232
            ],
            [
              -108.379299,
              46.749555
            ],
            [
              -108.631462,
              46.749392
            ],
            [
              -109.00957,
              46.750702
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "109",
      "COUNTYNS": "01696629",
      "AFFGEOID": "0500000US30109",
      "GEOID": "30109",
      "NAME": "Wibaux",
      "LSAD": "06",
      "ALAND": 2301488703,
      "AWATER": 1756846,
      "County_state": "Wibaux,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.607307,
              46.831877
            ],
            [
              -104.606903,
              46.684924
            ],
            [
              -104.417998,
              46.684892
            ],
            [
              -104.418001,
              46.670435
            ],
            [
              -104.354701,
              46.670381
            ],
            [
              -104.354835,
              46.641409
            ],
            [
              -104.04527115812101,
              46.6415014128297
            ],
            [
              -104.045474,
              46.708738
            ],
            [
              -104.045572,
              46.713881
            ],
            [
              -104.04537,
              46.721332
            ],
            [
              -104.045403,
              46.722177
            ],
            [
              -104.045402,
              46.725423
            ],
            [
              -104.045901,
              46.83079
            ],
            [
              -104.045542,
              46.933887
            ],
            [
              -104.045535,
              46.934009
            ],
            [
              -104.045566,
              46.941231
            ],
            [
              -104.045076,
              47.037589
            ],
            [
              -104.045052,
              47.040863
            ],
            [
              -104.045195,
              47.053639
            ],
            [
              -104.045227,
              47.057502
            ],
            [
              -104.045259,
              47.063901
            ],
            [
              -104.045354,
              47.078574
            ],
            [
              -104.045018,
              47.081202
            ],
            [
              -104.045081,
              47.092813
            ],
            [
              -104.044788,
              47.12743
            ],
            [
              -104.045517,
              47.215666
            ],
            [
              -104.045159,
              47.263874
            ],
            [
              -104.045091,
              47.265953
            ],
            [
              -104.045057,
              47.266868
            ],
            [
              -104.045088,
              47.271406
            ],
            [
              -104.045155,
              47.27393
            ],
            [
              -104.045121,
              47.276969
            ],
            [
              -104.04530661997902,
              47.330127855
            ],
            [
              -104.045313,
              47.331955
            ],
            [
              -104.045333,
              47.343452
            ],
            [
              -104.044863,
              47.375015
            ],
            [
              -104.04506544260501,
              47.3970733821092
            ],
            [
              -104.131508,
              47.397403
            ],
            [
              -104.131863,
              47.353901
            ],
            [
              -104.385293,
              47.35346
            ],
            [
              -104.420347,
              47.354435
            ],
            [
              -104.422616,
              47.345007
            ],
            [
              -104.450985,
              47.319277
            ],
            [
              -104.430045,
              47.317949
            ],
            [
              -104.419293,
              47.303343
            ],
            [
              -104.387266,
              47.288716
            ],
            [
              -104.387164,
              47.274256
            ],
            [
              -104.373515,
              47.274291
            ],
            [
              -104.352315,
              47.245391
            ],
            [
              -104.34168,
              47.245392
            ],
            [
              -104.341804,
              47.2165
            ],
            [
              -104.33118,
              47.216506
            ],
            [
              -104.331316,
              47.194862
            ],
            [
              -104.316166,
              47.18054
            ],
            [
              -104.31637,
              47.136863
            ],
            [
              -104.306062,
              47.042046
            ],
            [
              -104.327277,
              47.041967
            ],
            [
              -104.327241,
              47.005803
            ],
            [
              -104.359173,
              46.976582
            ],
            [
              -104.369824,
              46.947576
            ],
            [
              -104.401536,
              46.93307
            ],
            [
              -104.412124,
              46.904003
            ],
            [
              -104.433241,
              46.904095
            ],
            [
              -104.433233,
              46.88961
            ],
            [
              -104.454413,
              46.88966
            ],
            [
              -104.464947,
              46.875204
            ],
            [
              -104.486034,
              46.875266
            ],
            [
              -104.496582,
              46.860813
            ],
            [
              -104.603766,
              46.860853
            ],
            [
              -104.607307,
              46.831877
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "083",
      "COUNTYNS": "01715338",
      "AFFGEOID": "0500000US30083",
      "GEOID": "30083",
      "NAME": "Richland",
      "LSAD": "06",
      "ALAND": 5398268971,
      "AWATER": 49652120,
      "County_state": "Richland,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -105.234771,
              47.790502
            ],
            [
              -105.235016,
              47.703581
            ],
            [
              -104.97809,
              47.703293
            ],
            [
              -104.978553,
              47.616272
            ],
            [
              -104.593664,
              47.616294
            ],
            [
              -104.594046,
              47.529335
            ],
            [
              -104.513049,
              47.529442
            ],
            [
              -104.513292,
              47.354719
            ],
            [
              -104.420347,
              47.354435
            ],
            [
              -104.385293,
              47.35346
            ],
            [
              -104.131863,
              47.353901
            ],
            [
              -104.131508,
              47.397403
            ],
            [
              -104.04506544260501,
              47.3970733821092
            ],
            [
              -104.045069,
              47.397461
            ],
            [
              -104.044797,
              47.438445
            ],
            [
              -104.044621,
              47.45938
            ],
            [
              -104.044109,
              47.523595
            ],
            [
              -104.043912,
              47.603229
            ],
            [
              -104.044241,
              47.612288
            ],
            [
              -104.043742,
              47.625016
            ],
            [
              -104.043242,
              47.747106
            ],
            [
              -104.043199,
              47.747292
            ],
            [
              -104.042384,
              47.803256
            ],
            [
              -104.042432,
              47.805358
            ],
            [
              -104.042567,
              47.808237
            ],
            [
              -104.041869,
              47.841699
            ],
            [
              -104.041662,
              47.862282
            ],
            [
              -104.04223,
              47.891031
            ],
            [
              -104.043329,
              47.949554
            ],
            [
              -104.043497,
              47.95449
            ],
            [
              -104.043933,
              47.971515
            ],
            [
              -104.044162,
              47.992836
            ],
            [
              -104.04412,
              47.996107
            ],
            [
              -104.066179,
              48.003402
            ],
            [
              -104.098921,
              48.000019
            ],
            [
              -104.106695,
              48.013774
            ],
            [
              -104.09497,
              48.025879
            ],
            [
              -104.123554,
              48.037617
            ],
            [
              -104.134413,
              48.055566
            ],
            [
              -104.167221,
              48.035501
            ],
            [
              -104.1883,
              48.048998
            ],
            [
              -104.183153,
              48.066079
            ],
            [
              -104.203194,
              48.066029
            ],
            [
              -104.200698,
              48.0373
            ],
            [
              -104.224029,
              48.03044
            ],
            [
              -104.245936,
              48.031005
            ],
            [
              -104.29044,
              48.050927
            ],
            [
              -104.320674,
              48.045136
            ],
            [
              -104.349492,
              48.046435
            ],
            [
              -104.360398,
              48.068699
            ],
            [
              -104.376549,
              48.076711
            ],
            [
              -104.395056,
              48.076923
            ],
            [
              -104.443255,
              48.095029
            ],
            [
              -104.459967,
              48.119605
            ],
            [
              -104.487092,
              48.121875
            ],
            [
              -104.511696,
              48.109894
            ],
            [
              -104.528335,
              48.124262
            ],
            [
              -104.544517,
              48.126553
            ],
            [
              -104.584407,
              48.107656
            ],
            [
              -104.592125,
              48.108116
            ],
            [
              -104.587269,
              48.125016
            ],
            [
              -104.610948,
              48.141372
            ],
            [
              -104.630275,
              48.126813
            ],
            [
              -104.625514,
              48.112705
            ],
            [
              -104.681633,
              48.103323
            ],
            [
              -104.684148,
              48.113302
            ],
            [
              -104.714307,
              48.118934
            ],
            [
              -104.747636,
              48.111448
            ],
            [
              -104.760735,
              48.126382
            ],
            [
              -104.789218,
              48.128259
            ],
            [
              -104.822581,
              48.118945
            ],
            [
              -104.853055,
              48.125019
            ],
            [
              -104.882039,
              48.149369
            ],
            [
              -104.898447,
              48.149315
            ],
            [
              -104.923002,
              48.136192
            ],
            [
              -104.926139,
              48.11005
            ],
            [
              -104.947319,
              48.094202
            ],
            [
              -104.974058,
              48.103307
            ],
            [
              -104.984108,
              48.100992
            ],
            [
              -104.974812,
              48.084466
            ],
            [
              -104.990346,
              48.066449
            ],
            [
              -104.992493,
              48.080691
            ],
            [
              -105.006225,
              48.085365
            ],
            [
              -105.013845,
              48.069332
            ],
            [
              -105.034969,
              48.064399
            ],
            [
              -105.043563,
              48.053565
            ],
            [
              -105.057163,
              48.077671
            ],
            [
              -105.08487,
              48.064299
            ],
            [
              -105.115222,
              48.05902
            ],
            [
              -105.109414,
              48.076426
            ],
            [
              -105.142334,
              48.072988
            ],
            [
              -105.173367,
              48.081855
            ],
            [
              -105.167189,
              48.096488
            ],
            [
              -105.181111,
              48.10133
            ],
            [
              -105.181574,
              48.079735
            ],
            [
              -105.194783,
              48.066617
            ],
            [
              -105.195122,
              47.868081
            ],
            [
              -105.234651,
              47.868057
            ],
            [
              -105.234771,
              47.790502
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "085",
      "COUNTYNS": "01669428",
      "AFFGEOID": "0500000US30085",
      "GEOID": "30085",
      "NAME": "Roosevelt",
      "LSAD": "06",
      "ALAND": 6097970108,
      "AWATER": 37765673,
      "County_state": "Roosevelt,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -105.843824,
              48.182762
            ],
            [
              -105.843891,
              48.010289
            ],
            [
              -105.80888,
              48.024732
            ],
            [
              -105.786283,
              48.022558
            ],
            [
              -105.7778,
              48.047159
            ],
            [
              -105.765447,
              48.03063
            ],
            [
              -105.750561,
              48.025397
            ],
            [
              -105.720805,
              48.03195
            ],
            [
              -105.694541,
              48.053536
            ],
            [
              -105.666202,
              48.057048
            ],
            [
              -105.661286,
              48.045752
            ],
            [
              -105.643437,
              48.051097
            ],
            [
              -105.639652,
              48.066001
            ],
            [
              -105.647941,
              48.077304
            ],
            [
              -105.617496,
              48.073268
            ],
            [
              -105.595027,
              48.08178
            ],
            [
              -105.568481,
              48.08355
            ],
            [
              -105.531131,
              48.068027
            ],
            [
              -105.51858,
              48.088258
            ],
            [
              -105.503195,
              48.097792
            ],
            [
              -105.484563,
              48.090897
            ],
            [
              -105.453298,
              48.089386
            ],
            [
              -105.383523,
              48.092925
            ],
            [
              -105.364528,
              48.088752
            ],
            [
              -105.331305,
              48.092638
            ],
            [
              -105.317563,
              48.079016
            ],
            [
              -105.262551,
              48.089062
            ],
            [
              -105.22899,
              48.089284
            ],
            [
              -105.218934,
              48.081464
            ],
            [
              -105.217593,
              48.063749
            ],
            [
              -105.194783,
              48.066617
            ],
            [
              -105.181574,
              48.079735
            ],
            [
              -105.181111,
              48.10133
            ],
            [
              -105.167189,
              48.096488
            ],
            [
              -105.173367,
              48.081855
            ],
            [
              -105.142334,
              48.072988
            ],
            [
              -105.109414,
              48.076426
            ],
            [
              -105.115222,
              48.05902
            ],
            [
              -105.08487,
              48.064299
            ],
            [
              -105.057163,
              48.077671
            ],
            [
              -105.043563,
              48.053565
            ],
            [
              -105.034969,
              48.064399
            ],
            [
              -105.013845,
              48.069332
            ],
            [
              -105.006225,
              48.085365
            ],
            [
              -104.992493,
              48.080691
            ],
            [
              -104.990346,
              48.066449
            ],
            [
              -104.974812,
              48.084466
            ],
            [
              -104.984108,
              48.100992
            ],
            [
              -104.974058,
              48.103307
            ],
            [
              -104.947319,
              48.094202
            ],
            [
              -104.926139,
              48.11005
            ],
            [
              -104.923002,
              48.136192
            ],
            [
              -104.898447,
              48.149315
            ],
            [
              -104.882039,
              48.149369
            ],
            [
              -104.853055,
              48.125019
            ],
            [
              -104.822581,
              48.118945
            ],
            [
              -104.789218,
              48.128259
            ],
            [
              -104.760735,
              48.126382
            ],
            [
              -104.747636,
              48.111448
            ],
            [
              -104.714307,
              48.118934
            ],
            [
              -104.684148,
              48.113302
            ],
            [
              -104.681633,
              48.103323
            ],
            [
              -104.625514,
              48.112705
            ],
            [
              -104.630275,
              48.126813
            ],
            [
              -104.610948,
              48.141372
            ],
            [
              -104.587269,
              48.125016
            ],
            [
              -104.592125,
              48.108116
            ],
            [
              -104.584407,
              48.107656
            ],
            [
              -104.544517,
              48.126553
            ],
            [
              -104.528335,
              48.124262
            ],
            [
              -104.511696,
              48.109894
            ],
            [
              -104.487092,
              48.121875
            ],
            [
              -104.459967,
              48.119605
            ],
            [
              -104.443255,
              48.095029
            ],
            [
              -104.395056,
              48.076923
            ],
            [
              -104.376549,
              48.076711
            ],
            [
              -104.360398,
              48.068699
            ],
            [
              -104.349492,
              48.046435
            ],
            [
              -104.320674,
              48.045136
            ],
            [
              -104.29044,
              48.050927
            ],
            [
              -104.245936,
              48.031005
            ],
            [
              -104.224029,
              48.03044
            ],
            [
              -104.200698,
              48.0373
            ],
            [
              -104.203194,
              48.066029
            ],
            [
              -104.183153,
              48.066079
            ],
            [
              -104.1883,
              48.048998
            ],
            [
              -104.167221,
              48.035501
            ],
            [
              -104.134413,
              48.055566
            ],
            [
              -104.123554,
              48.037617
            ],
            [
              -104.09497,
              48.025879
            ],
            [
              -104.106695,
              48.013774
            ],
            [
              -104.098921,
              48.000019
            ],
            [
              -104.066179,
              48.003402
            ],
            [
              -104.04412,
              47.996107
            ],
            [
              -104.045399,
              48.16439
            ],
            [
              -104.045498,
              48.176249
            ],
            [
              -104.045424,
              48.192473
            ],
            [
              -104.04556,
              48.193913
            ],
            [
              -104.045692,
              48.241415
            ],
            [
              -104.045729,
              48.244586
            ],
            [
              -104.045645,
              48.246179
            ],
            [
              -104.045861,
              48.255097
            ],
            [
              -104.046039,
              48.256761
            ],
            [
              -104.046332,
              48.34229
            ],
            [
              -104.046371,
              48.374154
            ],
            [
              -104.046654,
              48.374773
            ],
            [
              -104.046910665796,
              48.3893008786193
            ],
            [
              -104.62776,
              48.389362
            ],
            [
              -104.627726,
              48.476349
            ],
            [
              -104.757643,
              48.476556
            ],
            [
              -104.757619,
              48.563127
            ],
            [
              -104.973354,
              48.56319
            ],
            [
              -105.804436,
              48.563358
            ],
            [
              -105.804599,
              48.219037
            ],
            [
              -105.843823,
              48.219034
            ],
            [
              -105.843824,
              48.182762
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "071",
      "COUNTYNS": "01720023",
      "AFFGEOID": "0500000US30071",
      "GEOID": "30071",
      "NAME": "Phillips",
      "LSAD": "06",
      "ALAND": 13313706952,
      "AWATER": 185563449,
      "County_state": "Phillips,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -108.891069,
              47.870608
            ],
            [
              -108.891115,
              47.734456
            ],
            [
              -108.823987,
              47.710251
            ],
            [
              -108.78406,
              47.682104
            ],
            [
              -108.78982,
              47.662943
            ],
            [
              -108.771619,
              47.645523
            ],
            [
              -108.755437,
              47.643842
            ],
            [
              -108.728836,
              47.649826
            ],
            [
              -108.709856,
              47.632769
            ],
            [
              -108.6883,
              47.631485
            ],
            [
              -108.6714,
              47.619556
            ],
            [
              -108.631376,
              47.619525
            ],
            [
              -108.610972,
              47.624329
            ],
            [
              -108.572422,
              47.609483
            ],
            [
              -108.538516,
              47.61394
            ],
            [
              -108.526067,
              47.620824
            ],
            [
              -108.507494,
              47.609147
            ],
            [
              -108.509227,
              47.601199
            ],
            [
              -108.474541,
              47.598274
            ],
            [
              -108.456908,
              47.615726
            ],
            [
              -108.442461,
              47.61477
            ],
            [
              -108.435911,
              47.601227
            ],
            [
              -108.425143,
              47.604153
            ],
            [
              -108.428956,
              47.588458
            ],
            [
              -108.412458,
              47.5849
            ],
            [
              -108.404464,
              47.595058
            ],
            [
              -108.386342,
              47.592007
            ],
            [
              -108.377853,
              47.577607
            ],
            [
              -108.356267,
              47.580207
            ],
            [
              -108.350385,
              47.567444
            ],
            [
              -108.335819,
              47.5674
            ],
            [
              -108.332682,
              47.580277
            ],
            [
              -108.313048,
              47.58365
            ],
            [
              -108.282375,
              47.579615
            ],
            [
              -108.259619,
              47.587987
            ],
            [
              -108.237974,
              47.582369
            ],
            [
              -108.19957,
              47.589725
            ],
            [
              -108.178548,
              47.580064
            ],
            [
              -108.147544,
              47.583642
            ],
            [
              -108.137272,
              47.592428
            ],
            [
              -108.079585,
              47.592012
            ],
            [
              -108.065418,
              47.598294
            ],
            [
              -108.022231,
              47.582066
            ],
            [
              -107.976184,
              47.585931
            ],
            [
              -107.96158,
              47.569875
            ],
            [
              -107.928591,
              47.567612
            ],
            [
              -107.930208,
              47.544324
            ],
            [
              -107.919502,
              47.535189
            ],
            [
              -107.919184,
              47.520588
            ],
            [
              -107.898107,
              47.510071
            ],
            [
              -107.909119,
              47.488011
            ],
            [
              -107.90912,
              47.450692
            ],
            [
              -107.863544,
              47.456052
            ],
            [
              -107.866219,
              47.482543
            ],
            [
              -107.850475,
              47.491947
            ],
            [
              -107.864922,
              47.51374
            ],
            [
              -107.842235,
              47.524906
            ],
            [
              -107.839431,
              47.534405
            ],
            [
              -107.817189,
              47.546282
            ],
            [
              -107.796198,
              47.545886
            ],
            [
              -107.792844,
              47.553222
            ],
            [
              -107.777865,
              47.543647
            ],
            [
              -107.761921,
              47.54805
            ],
            [
              -107.76311,
              47.563432
            ],
            [
              -107.732268,
              47.571874
            ],
            [
              -107.731521,
              47.58541
            ],
            [
              -107.709522,
              47.60026
            ],
            [
              -107.694687,
              47.629762
            ],
            [
              -107.671173,
              47.629892
            ],
            [
              -107.661986,
              47.620891
            ],
            [
              -107.646679,
              47.629444
            ],
            [
              -107.654416,
              47.652563
            ],
            [
              -107.637578,
              47.648806
            ],
            [
              -107.617108,
              47.63156
            ],
            [
              -107.610852,
              47.647369
            ],
            [
              -107.59425,
              47.649731
            ],
            [
              -107.567572,
              47.642964
            ],
            [
              -107.553672,
              47.646348
            ],
            [
              -107.520788,
              47.642919
            ],
            [
              -107.499344,
              47.624005
            ],
            [
              -107.451508,
              47.622141
            ],
            [
              -107.440064,
              47.625462
            ],
            [
              -107.439116,
              47.646778
            ],
            [
              -107.419745,
              47.662295
            ],
            [
              -107.428086,
              47.677316
            ],
            [
              -107.414886,
              47.691734
            ],
            [
              -107.4144,
              47.870513
            ],
            [
              -107.404676,
              47.870509
            ],
            [
              -107.404513,
              48.21844
            ],
            [
              -107.36973,
              48.218373
            ],
            [
              -107.369252,
              48.305163
            ],
            [
              -107.260458,
              48.304768
            ],
            [
              -107.260279,
              48.39408
            ],
            [
              -107.301073,
              48.394
            ],
            [
              -107.301455,
              48.44003
            ],
            [
              -107.301721,
              48.480943
            ],
            [
              -107.257944,
              48.480989
            ],
            [
              -107.251912,
              48.516932
            ],
            [
              -107.226312,
              48.509712
            ],
            [
              -107.192691,
              48.509402
            ],
            [
              -107.192709,
              48.568113
            ],
            [
              -107.206316,
              48.56817
            ],
            [
              -107.206032,
              48.91271
            ],
            [
              -107.179823,
              48.912668
            ],
            [
              -107.179812011685,
              48.9999246229794
            ],
            [
              -107.363582,
              49.000019
            ],
            [
              -107.441017,
              48.999363
            ],
            [
              -107.704696,
              48.999872
            ],
            [
              -108.236393,
              48.99952991858989
            ],
            [
              -108.236357,
              48.91352
            ],
            [
              -108.259372,
              48.913592
            ],
            [
              -108.259384,
              48.742225
            ],
            [
              -108.319119,
              48.742209
            ],
            [
              -108.319011,
              48.568431
            ],
            [
              -108.346193,
              48.56845
            ],
            [
              -108.346292,
              48.422795
            ],
            [
              -108.359095,
              48.438107
            ],
            [
              -108.404675,
              48.440634
            ],
            [
              -108.410374,
              48.448484
            ],
            [
              -108.411186,
              48.444981
            ],
            [
              -108.411239,
              48.21955
            ],
            [
              -108.433848,
              48.219551
            ],
            [
              -108.43398,
              47.976553
            ],
            [
              -108.569564,
              47.981272
            ],
            [
              -108.598581,
              47.991896
            ],
            [
              -108.607579,
              47.990997
            ],
            [
              -108.623259,
              47.957242
            ],
            [
              -108.625587,
              47.920411
            ],
            [
              -108.671694,
              47.923256
            ],
            [
              -108.888316,
              47.922722
            ],
            [
              -108.891069,
              47.870608
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "111",
      "COUNTYNS": "01719558",
      "AFFGEOID": "0500000US30111",
      "GEOID": "30111",
      "NAME": "Yellowstone",
      "LSAD": "06",
      "ALAND": 6820738870,
      "AWATER": 39856668,
      "County_state": "Yellowstone,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -108.924589,
              46.13237
            ],
            [
              -108.925372,
              46.031936
            ],
            [
              -108.922324,
              45.959444
            ],
            [
              -108.901655,
              45.959416
            ],
            [
              -108.901927,
              45.784264
            ],
            [
              -108.904726,
              45.682821
            ],
            [
              -108.884297,
              45.682936
            ],
            [
              -108.884437,
              45.661687
            ],
            [
              -108.863707,
              45.639422
            ],
            [
              -108.843145,
              45.639458
            ],
            [
              -108.843016,
              45.611042
            ],
            [
              -108.798523,
              45.634892
            ],
            [
              -108.782,
              45.638639
            ],
            [
              -108.781945,
              45.615657
            ],
            [
              -108.802373,
              45.587518
            ],
            [
              -108.801761,
              45.551965
            ],
            [
              -108.760831,
              45.55183
            ],
            [
              -108.760308,
              45.522831
            ],
            [
              -108.699283,
              45.522629
            ],
            [
              -108.698102,
              45.464072
            ],
            [
              -108.645325,
              45.464073
            ],
            [
              -108.644992,
              45.464073
            ],
            [
              -108.44523,
              45.460449
            ],
            [
              -108.316374,
              45.460509
            ],
            [
              -108.316245,
              45.489503
            ],
            [
              -108.193191,
              45.489526
            ],
            [
              -108.193205,
              45.518538
            ],
            [
              -108.069752,
              45.518535
            ],
            [
              -108.0697,
              45.681266
            ],
            [
              -108.069572,
              45.783507
            ],
            [
              -108.047559,
              45.783476
            ],
            [
              -108.047352,
              45.899316
            ],
            [
              -107.881846,
              45.899233
            ],
            [
              -107.881873,
              45.928149
            ],
            [
              -107.840375,
              45.928018
            ],
            [
              -107.84038,
              45.956812
            ],
            [
              -107.798821,
              45.956723
            ],
            [
              -107.798753,
              45.98568
            ],
            [
              -107.674468,
              45.985759
            ],
            [
              -107.674454,
              46.04361
            ],
            [
              -107.511534,
              46.043403
            ],
            [
              -107.517447,
              46.059519
            ],
            [
              -107.500627,
              46.07132
            ],
            [
              -107.49814,
              46.087872
            ],
            [
              -107.473383,
              46.107717
            ],
            [
              -107.483975,
              46.122842
            ],
            [
              -107.462965,
              46.139913
            ],
            [
              -107.474342,
              46.150838
            ],
            [
              -107.466131,
              46.177774
            ],
            [
              -107.487115,
              46.177794
            ],
            [
              -107.487022,
              46.192296
            ],
            [
              -107.507925,
              46.192283
            ],
            [
              -107.507959,
              46.221294
            ],
            [
              -107.528695,
              46.221227
            ],
            [
              -107.528646,
              46.250245
            ],
            [
              -107.549555,
              46.250222
            ],
            [
              -107.549552,
              46.264717
            ],
            [
              -107.570468,
              46.264705
            ],
            [
              -107.570495,
              46.293652
            ],
            [
              -107.591422,
              46.29365
            ],
            [
              -107.591438,
              46.308156
            ],
            [
              -107.612395,
              46.308113
            ],
            [
              -107.612411,
              46.351557
            ],
            [
              -107.633363,
              46.351525
            ],
            [
              -107.633378,
              46.365993
            ],
            [
              -107.654153,
              46.365951
            ],
            [
              -107.654159,
              46.394888
            ],
            [
              -107.675069,
              46.394936
            ],
            [
              -107.675188,
              46.409433
            ],
            [
              -107.6961,
              46.409482
            ],
            [
              -107.696323,
              46.438477
            ],
            [
              -107.717289,
              46.438507
            ],
            [
              -107.717512,
              46.467508
            ],
            [
              -107.738432,
              46.467556
            ],
            [
              -107.738548,
              46.481806
            ],
            [
              -107.754824,
              46.496043
            ],
            [
              -107.781214,
              46.496014
            ],
            [
              -107.782087,
              46.395182
            ],
            [
              -107.928547,
              46.395284
            ],
            [
              -107.9285,
              46.380796
            ],
            [
              -107.949395,
              46.380596
            ],
            [
              -107.949322,
              46.36606
            ],
            [
              -107.970165,
              46.365967
            ],
            [
              -107.970009,
              46.351474
            ],
            [
              -107.991101,
              46.35147
            ],
            [
              -107.990834,
              46.337014
            ],
            [
              -108.011948,
              46.336813
            ],
            [
              -108.011597,
              46.307822
            ],
            [
              -108.02933,
              46.307841
            ],
            [
              -108.029363,
              46.264055
            ],
            [
              -108.320812,
              46.263997
            ],
            [
              -108.320952,
              46.249664
            ],
            [
              -108.362927,
              46.25003
            ],
            [
              -108.36294,
              46.235584
            ],
            [
              -108.404856,
              46.23596
            ],
            [
              -108.404795,
              46.132952
            ],
            [
              -108.779733,
              46.132457
            ],
            [
              -108.924589,
              46.13237
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "059",
      "COUNTYNS": "01719600",
      "AFFGEOID": "0500000US30059",
      "GEOID": "30059",
      "NAME": "Meagher",
      "LSAD": "06",
      "ALAND": 6194911314,
      "AWATER": 7474581,
      "County_state": "Meagher,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -111.658132,
              46.913423
            ],
            [
              -111.623864,
              46.902935
            ],
            [
              -111.608678,
              46.892266
            ],
            [
              -111.608529,
              46.878442
            ],
            [
              -111.639154,
              46.865314
            ],
            [
              -111.635437,
              46.850984
            ],
            [
              -111.645739,
              46.843361
            ],
            [
              -111.639502,
              46.832134
            ],
            [
              -111.627579,
              46.83251
            ],
            [
              -111.599121,
              46.807846
            ],
            [
              -111.573536,
              46.810051
            ],
            [
              -111.532301,
              46.804383
            ],
            [
              -111.523814,
              46.817074
            ],
            [
              -111.496029,
              46.790273
            ],
            [
              -111.498095,
              46.762502
            ],
            [
              -111.496208,
              46.761959
            ],
            [
              -111.48874,
              46.757331
            ],
            [
              -111.483513,
              46.755738
            ],
            [
              -111.479232,
              46.755833
            ],
            [
              -111.475139,
              46.752996
            ],
            [
              -111.467744,
              46.752384
            ],
            [
              -111.461722,
              46.747441
            ],
            [
              -111.457094,
              46.745339
            ],
            [
              -111.45592,
              46.74089
            ],
            [
              -111.456734,
              46.73405
            ],
            [
              -111.458198,
              46.730178
            ],
            [
              -111.457015,
              46.729209
            ],
            [
              -111.457474,
              46.727767
            ],
            [
              -111.459384,
              46.727652
            ],
            [
              -111.461679,
              46.725866
            ],
            [
              -111.463151,
              46.723656
            ],
            [
              -111.460822,
              46.722129
            ],
            [
              -111.454844,
              46.71928
            ],
            [
              -111.451606,
              46.718919
            ],
            [
              -111.449004,
              46.7173
            ],
            [
              -111.450787,
              46.714736
            ],
            [
              -111.455848,
              46.710288
            ],
            [
              -111.461218,
              46.706279
            ],
            [
              -111.461567,
              46.704325
            ],
            [
              -111.458004,
              46.703719
            ],
            [
              -111.455063,
              46.702488
            ],
            [
              -111.450927,
              46.702136
            ],
            [
              -111.446692,
              46.699692
            ],
            [
              -111.446038,
              46.696212
            ],
            [
              -111.443563,
              46.691767
            ],
            [
              -111.445064,
              46.689709
            ],
            [
              -111.448023,
              46.685807
            ],
            [
              -111.450235,
              46.685039
            ],
            [
              -111.449939,
              46.682277
            ],
            [
              -111.446231,
              46.681659
            ],
            [
              -111.437947,
              46.680641
            ],
            [
              -111.428866,
              46.681513
            ],
            [
              -111.4233,
              46.679213
            ],
            [
              -111.420203,
              46.675368
            ],
            [
              -111.41418,
              46.670934
            ],
            [
              -111.412489,
              46.663317
            ],
            [
              -111.408519,
              46.655361
            ],
            [
              -111.412792,
              46.64701
            ],
            [
              -111.410905,
              46.643125
            ],
            [
              -111.410822,
              46.639601
            ],
            [
              -111.407284,
              46.63951
            ],
            [
              -111.399012,
              46.64403
            ],
            [
              -111.391297,
              46.644421
            ],
            [
              -111.381146,
              46.645349
            ],
            [
              -111.376175,
              46.644557
            ],
            [
              -111.370654,
              46.641636
            ],
            [
              -111.36669,
              46.635528
            ],
            [
              -111.358727,
              46.634797
            ],
            [
              -111.34912,
              46.635839
            ],
            [
              -111.344722,
              46.634607
            ],
            [
              -111.340187,
              46.635697
            ],
            [
              -111.3355,
              46.634555
            ],
            [
              -111.330223,
              46.632322
            ],
            [
              -111.33073,
              46.62965
            ],
            [
              -111.327431,
              46.62647
            ],
            [
              -111.328365,
              46.62072
            ],
            [
              -111.32822,
              46.617021
            ],
            [
              -111.32548,
              46.615307
            ],
            [
              -111.327,
              46.613372
            ],
            [
              -111.329219,
              46.611463
            ],
            [
              -111.333737,
              46.607648
            ],
            [
              -111.333587,
              46.599304
            ],
            [
              -111.329914,
              46.591181
            ],
            [
              -111.330636,
              46.585056
            ],
            [
              -111.334086,
              46.579161
            ],
            [
              -111.32727,
              46.573106
            ],
            [
              -111.319826,
              46.566925
            ],
            [
              -111.32071,
              46.560696
            ],
            [
              -111.326193,
              46.556266
            ],
            [
              -111.33461,
              46.551722
            ],
            [
              -111.341743,
              46.548374
            ],
            [
              -111.341097,
              46.542473
            ],
            [
              -111.339017,
              46.539106
            ],
            [
              -111.330004,
              46.536019
            ],
            [
              -111.317851,
              46.531416
            ],
            [
              -111.311775,
              46.530596
            ],
            [
              -111.300957,
              46.52858
            ],
            [
              -111.28926,
              46.525588
            ],
            [
              -111.277281,
              46.517281
            ],
            [
              -111.27637,
              46.508763
            ],
            [
              -111.272411,
              46.503739
            ],
            [
              -111.270104,
              46.500708
            ],
            [
              -111.265182,
              46.497559
            ],
            [
              -111.262615,
              46.498495
            ],
            [
              -111.256934,
              46.49727
            ],
            [
              -111.255472,
              46.493327
            ],
            [
              -111.253654,
              46.486959
            ],
            [
              -111.256494,
              46.479868
            ],
            [
              -111.252409,
              46.472557
            ],
            [
              -111.250104,
              46.462687
            ],
            [
              -111.248329,
              46.455765
            ],
            [
              -111.251258,
              46.447262
            ],
            [
              -111.247579,
              46.440147
            ],
            [
              -111.240866,
              46.436822
            ],
            [
              -111.233719,
              46.43449
            ],
            [
              -111.221924,
              46.429688
            ],
            [
              -111.211423,
              46.428334
            ],
            [
              -111.197046,
              46.428677
            ],
            [
              -111.184895,
              46.432647
            ],
            [
              -111.17635,
              46.441332
            ],
            [
              -111.165865,
              46.448035
            ],
            [
              -111.155838,
              46.449123
            ],
            [
              -111.144715,
              46.450322
            ],
            [
              -111.131588,
              46.450529
            ],
            [
              -111.12662,
              46.452633
            ],
            [
              -111.120613,
              46.450486
            ],
            [
              -111.121542,
              46.446563
            ],
            [
              -111.125128,
              46.44319
            ],
            [
              -111.122159,
              46.438936
            ],
            [
              -111.114747,
              46.437683
            ],
            [
              -111.111147,
              46.435237
            ],
            [
              -111.10277,
              46.434482
            ],
            [
              -111.097512,
              46.431782
            ],
            [
              -111.095972,
              46.427971
            ],
            [
              -111.093445,
              46.42318
            ],
            [
              -111.087564,
              46.421709
            ],
            [
              -111.08174,
              46.418664
            ],
            [
              -111.076651,
              46.413298
            ],
            [
              -111.067291,
              46.410065
            ],
            [
              -111.06183,
              46.408571
            ],
            [
              -111.058128,
              46.404001
            ],
            [
              -111.058607,
              46.39637
            ],
            [
              -111.06419,
              46.393057
            ],
            [
              -111.064632,
              46.387373
            ],
            [
              -111.064289,
              46.381445
            ],
            [
              -111.071331,
              46.374926
            ],
            [
              -111.072884,
              46.369226
            ],
            [
              -111.070144,
              46.363911
            ],
            [
              -111.071135,
              46.35689
            ],
            [
              -111.068648,
              46.352454
            ],
            [
              -111.073919,
              46.348202
            ],
            [
              -111.082092,
              46.34733
            ],
            [
              -111.094826,
              46.342701
            ],
            [
              -111.099698,
              46.334977
            ],
            [
              -111.10895,
              46.32479
            ],
            [
              -111.118578,
              46.312657
            ],
            [
              -111.120595,
              46.300846
            ],
            [
              -111.119859,
              46.293432
            ],
            [
              -111.12322,
              46.28345
            ],
            [
              -111.124951,
              46.276573
            ],
            [
              -111.123264,
              46.271542
            ],
            [
              -111.117832,
              46.266994
            ],
            [
              -111.113618,
              46.265433
            ],
            [
              -111.109534,
              46.26483
            ],
            [
              -111.107557,
              46.26254
            ],
            [
              -111.108016,
              46.260404
            ],
            [
              -111.105031,
              46.258428
            ],
            [
              -111.098892,
              46.257004
            ],
            [
              -111.09291,
              46.256589
            ],
            [
              -111.078881,
              46.250451
            ],
            [
              -111.078762,
              46.247393
            ],
            [
              -111.081739,
              46.242527
            ],
            [
              -111.083294,
              46.238436
            ],
            [
              -111.078791,
              46.233324
            ],
            [
              -111.072191,
              46.23327
            ],
            [
              -111.065926,
              46.226226
            ],
            [
              -111.063044,
              46.222435
            ],
            [
              -111.062272,
              46.215175
            ],
            [
              -111.060622,
              46.200162
            ],
            [
              -111.061956,
              46.192834
            ],
            [
              -110.993241,
              46.192795
            ],
            [
              -110.783833,
              46.192699
            ],
            [
              -110.409271,
              46.192065
            ],
            [
              -110.409283,
              46.184368
            ],
            [
              -110.281916,
              46.184397
            ],
            [
              -110.281971,
              46.220605
            ],
            [
              -110.281471,
              46.489826
            ],
            [
              -110.272998,
              46.48983
            ],
            [
              -110.273337,
              46.710509
            ],
            [
              -110.302726,
              46.688647
            ],
            [
              -110.325376,
              46.685724
            ],
            [
              -110.328511,
              46.673178
            ],
            [
              -110.34424,
              46.672562
            ],
            [
              -110.349481,
              46.682461
            ],
            [
              -110.375292,
              46.692912
            ],
            [
              -110.399556,
              46.68816
            ],
            [
              -110.421998,
              46.678022
            ],
            [
              -110.437216,
              46.686231
            ],
            [
              -110.466626,
              46.678526
            ],
            [
              -110.483057,
              46.70147
            ],
            [
              -110.497827,
              46.702633
            ],
            [
              -110.506319,
              46.712774
            ],
            [
              -110.524345,
              46.712788
            ],
            [
              -110.549607,
              46.733089
            ],
            [
              -110.564334,
              46.738864
            ],
            [
              -110.557791,
              46.759299
            ],
            [
              -110.563704,
              46.771363
            ],
            [
              -110.620687,
              46.772216
            ],
            [
              -110.649524,
              46.801473
            ],
            [
              -110.644458,
              46.816158
            ],
            [
              -110.652877,
              46.823626
            ],
            [
              -110.662034,
              46.818042
            ],
            [
              -110.678922,
              46.824774
            ],
            [
              -110.697417,
              46.84496
            ],
            [
              -110.716737,
              46.838176
            ],
            [
              -110.735911,
              46.848474
            ],
            [
              -110.751586,
              46.844531
            ],
            [
              -110.752108,
              46.859553
            ],
            [
              -110.76234,
              46.88258
            ],
            [
              -110.752715,
              46.890184
            ],
            [
              -110.783399,
              46.901712
            ],
            [
              -110.790205,
              46.910109
            ],
            [
              -110.804985,
              46.901864
            ],
            [
              -110.825002,
              46.914867
            ],
            [
              -110.856038,
              46.921347
            ],
            [
              -110.85901,
              46.94606
            ],
            [
              -110.870649,
              46.944535
            ],
            [
              -110.891441,
              46.965719
            ],
            [
              -110.913035,
              46.980559
            ],
            [
              -110.996401,
              47.005093
            ],
            [
              -111.055003,
              47.00907
            ],
            [
              -111.072278,
              47.005663
            ],
            [
              -111.104792,
              47.029036
            ],
            [
              -111.076802,
              47.045283
            ],
            [
              -111.083856,
              47.072528
            ],
            [
              -111.080723,
              47.087868
            ],
            [
              -111.158559,
              47.088301
            ],
            [
              -111.158386,
              47.011771
            ],
            [
              -111.285208,
              47.011746
            ],
            [
              -111.285268,
              47.001026
            ],
            [
              -111.539373,
              47.000559
            ],
            [
              -111.539214,
              46.913371
            ],
            [
              -111.658132,
              46.913423
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "017",
      "COUNTYNS": "01720109",
      "AFFGEOID": "0500000US30017",
      "GEOID": "30017",
      "NAME": "Custer",
      "LSAD": "06",
      "ALAND": 9798723679,
      "AWATER": 25612456,
      "County_state": "Custer,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -106.191505,
              45.876391
            ],
            [
              -106.191734,
              45.7886
            ],
            [
              -105.658795,
              45.788381
            ],
            [
              -105.155935,
              45.787484
            ],
            [
              -104.98194,
              45.786982
            ],
            [
              -104.939289,
              45.786964
            ],
            [
              -104.939205,
              46.136575
            ],
            [
              -104.885495,
              46.136595
            ],
            [
              -104.885577,
              46.313346
            ],
            [
              -104.905313,
              46.313333
            ],
            [
              -104.905988,
              46.482251
            ],
            [
              -104.732672,
              46.482202
            ],
            [
              -104.733059,
              46.612695
            ],
            [
              -104.858738,
              46.612557
            ],
            [
              -104.858749,
              46.569115
            ],
            [
              -104.98474,
              46.569386
            ],
            [
              -104.984714,
              46.540389
            ],
            [
              -105.239238,
              46.541188
            ],
            [
              -105.239058,
              46.570265
            ],
            [
              -105.449196,
              46.570967
            ],
            [
              -105.449406,
              46.600011
            ],
            [
              -105.491919,
              46.600085
            ],
            [
              -105.492002,
              46.658117
            ],
            [
              -105.576063,
              46.658003
            ],
            [
              -105.576394,
              46.745021
            ],
            [
              -105.618097,
              46.744932
            ],
            [
              -105.618158,
              46.832148
            ],
            [
              -105.832631,
              46.83196
            ],
            [
              -105.832674,
              46.86082
            ],
            [
              -106.086585,
              46.86029
            ],
            [
              -106.086463,
              46.845881
            ],
            [
              -106.086341,
              46.831462
            ],
            [
              -106.12063,
              46.831438
            ],
            [
              -106.120384,
              46.624821
            ],
            [
              -106.120366,
              46.483648
            ],
            [
              -106.155143,
              46.483634
            ],
            [
              -106.151635,
              46.224286
            ],
            [
              -106.153433,
              46.13726
            ],
            [
              -106.191629,
              46.137183
            ],
            [
              -106.191505,
              45.876391
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "075",
      "COUNTYNS": "01719584",
      "AFFGEOID": "0500000US30075",
      "GEOID": "30075",
      "NAME": "Powder River",
      "LSAD": "06",
      "ALAND": 8542288902,
      "AWATER": 1565958,
      "County_state": "Powder River,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -106.279549,
              45.351797
            ],
            [
              -106.279709,
              45.179728
            ],
            [
              -106.263684,
              45.179723
            ],
            [
              -106.263715092465,
              44.9937884330015
            ],
            [
              -106.263586,
              44.993788
            ],
            [
              -106.024814919023,
              44.9936876227738
            ],
            [
              -105.928184,
              44.993647
            ],
            [
              -105.914258,
              44.999986
            ],
            [
              -105.913382,
              45.000941
            ],
            [
              -105.848065,
              45.000396
            ],
            [
              -105.371313283991,
              45.000365969694
            ],
            [
              -105.076600973456,
              45.000347405941596
            ],
            [
              -105.038405,
              45.000345
            ],
            [
              -105.038252994286,
              45.000344363702396
            ],
            [
              -105.038205,
              45.352035
            ],
            [
              -104.987318,
              45.35204
            ],
            [
              -104.987306,
              45.442759
            ],
            [
              -104.98194,
              45.786982
            ],
            [
              -105.155935,
              45.787484
            ],
            [
              -105.658795,
              45.788381
            ],
            [
              -106.191734,
              45.7886
            ],
            [
              -106.234502,
              45.788578
            ],
            [
              -106.231728,
              45.756782
            ],
            [
              -106.235716,
              45.351796
            ],
            [
              -106.279549,
              45.351797
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "053",
      "COUNTYNS": "01720038",
      "AFFGEOID": "0500000US30053",
      "GEOID": "30053",
      "NAME": "Lincoln",
      "LSAD": "06",
      "ALAND": 9356348235,
      "AWATER": 161596376,
      "County_state": "Lincoln,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -116.049155,
              48.481247
            ],
            [
              -116.050003,
              48.413492
            ],
            [
              -116.048948,
              48.309847
            ],
            [
              -116.049735,
              48.274668
            ],
            [
              -116.049353,
              48.249936
            ],
            [
              -116.049977,
              48.237604
            ],
            [
              -116.049773718343,
              48.216117548345196
            ],
            [
              -116.016723,
              48.206217
            ],
            [
              -116.018005,
              48.169538
            ],
            [
              -116.004505,
              48.157828
            ],
            [
              -115.969641,
              48.154879
            ],
            [
              -115.95671,
              48.149072
            ],
            [
              -115.936745,
              48.184166
            ],
            [
              -115.908191,
              48.182753
            ],
            [
              -115.900949,
              48.193956
            ],
            [
              -115.855995,
              48.210707
            ],
            [
              -115.831545,
              48.211318
            ],
            [
              -115.829364,
              48.217383
            ],
            [
              -115.800172,
              48.222818
            ],
            [
              -115.78252,
              48.239714
            ],
            [
              -115.771401,
              48.259064
            ],
            [
              -115.737279,
              48.263292
            ],
            [
              -115.708817,
              48.24739
            ],
            [
              -115.693611,
              48.225038
            ],
            [
              -115.688517,
              48.222657
            ],
            [
              -115.683928,
              48.218166
            ],
            [
              -115.679807,
              48.212781
            ],
            [
              -115.673904,
              48.207552
            ],
            [
              -115.67453,
              48.203648
            ],
            [
              -115.67452,
              48.197702
            ],
            [
              -115.674766,
              48.195506
            ],
            [
              -115.677036,
              48.194426
            ],
            [
              -115.681679,
              48.193864
            ],
            [
              -115.684904,
              48.189467
            ],
            [
              -115.690547,
              48.183313
            ],
            [
              -115.693805,
              48.178947
            ],
            [
              -115.69491,
              48.175451
            ],
            [
              -115.693771,
              48.167833
            ],
            [
              -115.686649,
              48.164706
            ],
            [
              -115.675588,
              48.163397
            ],
            [
              -115.67201,
              48.159658
            ],
            [
              -115.667016,
              48.153863
            ],
            [
              -115.661603,
              48.150583
            ],
            [
              -115.65625,
              48.146923
            ],
            [
              -115.657164,
              48.143334
            ],
            [
              -115.653906,
              48.139183
            ],
            [
              -115.646428,
              48.132178
            ],
            [
              -115.648621,
              48.123042
            ],
            [
              -115.641935,
              48.117058
            ],
            [
              -115.640688,
              48.112377
            ],
            [
              -115.637723,
              48.106508
            ],
            [
              -115.637746,
              48.099657
            ],
            [
              -115.63784,
              48.094979
            ],
            [
              -115.633682,
              48.089886
            ],
            [
              -115.634954,
              48.084913
            ],
            [
              -115.638045,
              48.080681
            ],
            [
              -115.640507,
              48.080412
            ],
            [
              -115.639118,
              48.077697
            ],
            [
              -115.637604,
              48.075144
            ],
            [
              -115.632016,
              48.072372
            ],
            [
              -115.626252,
              48.069646
            ],
            [
              -115.620843,
              48.065303
            ],
            [
              -115.617805,
              48.063197
            ],
            [
              -115.610206,
              48.060143
            ],
            [
              -115.608418,
              48.057236
            ],
            [
              -115.610212,
              48.052496
            ],
            [
              -115.611342,
              48.048327
            ],
            [
              -115.607208,
              48.044486
            ],
            [
              -115.60201,
              48.041497
            ],
            [
              -115.600937,
              48.038656
            ],
            [
              -115.599799,
              48.034237
            ],
            [
              -115.594057,
              48.030714
            ],
            [
              -115.589009,
              48.026976
            ],
            [
              -115.586362,
              48.023836
            ],
            [
              -115.580051,
              48.020304
            ],
            [
              -115.571523,
              48.018843
            ],
            [
              -115.566859,
              48.018127
            ],
            [
              -115.561713,
              48.014144
            ],
            [
              -115.555625,
              48.01059
            ],
            [
              -115.551901,
              48.007513
            ],
            [
              -115.54994,
              48.003809
            ],
            [
              -115.546996,
              48.001158
            ],
            [
              -115.544602,
              47.998412
            ],
            [
              -115.542875,
              47.993552
            ],
            [
              -115.541085,
              47.987068
            ],
            [
              -115.538912,
              47.982088
            ],
            [
              -115.540708,
              47.977205
            ],
            [
              -115.54253,
              47.9706
            ],
            [
              -115.546647,
              47.963662
            ],
            [
              -115.548448,
              47.955785
            ],
            [
              -115.554705,
              47.949435
            ],
            [
              -115.557468,
              47.943555
            ],
            [
              -115.556374,
              47.937093
            ],
            [
              -115.550284,
              47.932235
            ],
            [
              -115.546196,
              47.925496
            ],
            [
              -115.541,
              47.91954
            ],
            [
              -115.534958,
              47.915963
            ],
            [
              -115.526685,
              47.911492
            ],
            [
              -115.517554,
              47.908455
            ],
            [
              -115.504455,
              47.908832
            ],
            [
              -115.493534,
              47.911086
            ],
            [
              -115.487502,
              47.914993
            ],
            [
              -115.48485,
              47.916683
            ],
            [
              -115.479603,
              47.917463
            ],
            [
              -115.475999,
              47.915677
            ],
            [
              -115.46863,
              47.914968
            ],
            [
              -115.467789,
              47.916759
            ],
            [
              -115.458941,
              47.919857
            ],
            [
              -115.445481,
              47.915401
            ],
            [
              -115.433866,
              47.912481
            ],
            [
              -115.429855,
              47.911555
            ],
            [
              -115.424649,
              47.914203
            ],
            [
              -115.424507,
              47.91911
            ],
            [
              -115.424857,
              47.925309
            ],
            [
              -115.423926,
              47.929214
            ],
            [
              -115.421659,
              47.933216
            ],
            [
              -115.413071,
              47.934294
            ],
            [
              -115.405135,
              47.935166
            ],
            [
              -115.399185,
              47.932467
            ],
            [
              -115.400365,
              47.928209
            ],
            [
              -115.401911,
              47.921002
            ],
            [
              -115.39995,
              47.914661
            ],
            [
              -115.391231,
              47.909198
            ],
            [
              -115.380769,
              47.904155
            ],
            [
              -115.366614,
              47.907763
            ],
            [
              -115.35563,
              47.911726
            ],
            [
              -115.346398,
              47.914489
            ],
            [
              -115.335879,
              47.917467
            ],
            [
              -115.323989,
              47.919631
            ],
            [
              -115.316634,
              47.919397
            ],
            [
              -115.309563,
              47.913852
            ],
            [
              -115.299488,
              47.910296
            ],
            [
              -115.296149,
              47.903967
            ],
            [
              -115.292921,
              47.898146
            ],
            [
              -115.287214,
              47.893408
            ],
            [
              -115.282824,
              47.890796
            ],
            [
              -115.277651,
              47.891699
            ],
            [
              -115.274479,
              47.893546
            ],
            [
              -115.26919,
              47.89699
            ],
            [
              -115.262763,
              47.903539
            ],
            [
              -115.256198,
              47.90456
            ],
            [
              -115.250819,
              47.907195
            ],
            [
              -115.251223,
              47.909312
            ],
            [
              -115.249241,
              47.913095
            ],
            [
              -115.238997,
              47.912906
            ],
            [
              -115.232902,
              47.909602
            ],
            [
              -115.226758,
              47.908681
            ],
            [
              -115.21945,
              47.911531
            ],
            [
              -115.21409,
              47.914324
            ],
            [
              -115.209335,
              47.916612
            ],
            [
              -115.204787,
              47.918989
            ],
            [
              -115.201723,
              47.916869
            ],
            [
              -115.197025,
              47.918541
            ],
            [
              -115.192556,
              47.917691
            ],
            [
              -115.188533,
              47.918224
            ],
            [
              -115.1865,
              47.920978
            ],
            [
              -115.185052,
              47.924511
            ],
            [
              -115.181812,
              47.930567
            ],
            [
              -115.17948,
              47.935637
            ],
            [
              -115.181252,
              47.939294
            ],
            [
              -115.179599,
              47.942935
            ],
            [
              -115.180209,
              47.947033
            ],
            [
              -115.181365,
              47.952192
            ],
            [
              -115.183204,
              47.954372
            ],
            [
              -115.183127,
              47.958671
            ],
            [
              -115.178748,
              47.959013
            ],
            [
              -115.17398,
              47.96432
            ],
            [
              -115.173612,
              47.970593
            ],
            [
              -115.174691,
              47.972611
            ],
            [
              -115.172077,
              47.974825
            ],
            [
              -115.16728,
              47.973189
            ],
            [
              -115.161637,
              47.967069
            ],
            [
              -115.158879,
              47.962222
            ],
            [
              -115.152522,
              47.962376
            ],
            [
              -115.14813,
              47.963501
            ],
            [
              -115.146204,
              47.9697
            ],
            [
              -115.145784,
              47.975178
            ],
            [
              -115.139928,
              47.978311
            ],
            [
              -115.137658,
              47.982214
            ],
            [
              -115.140851,
              47.987098
            ],
            [
              -115.144956,
              47.990657
            ],
            [
              -115.149669,
              47.995081
            ],
            [
              -115.149607,
              48.002791
            ],
            [
              -115.150264,
              48.008472
            ],
            [
              -115.152183,
              48.011847
            ],
            [
              -115.153938,
              48.014703
            ],
            [
              -115.147949,
              48.017701
            ],
            [
              -115.116292,
              48.017664
            ],
            [
              -115.085376,
              48.017594
            ],
            [
              -115.040756,
              48.017563
            ],
            [
              -115.021077,
              48.017615
            ],
            [
              -115.010672,
              48.017621
            ],
            [
              -115.010094,
              48.139115
            ],
            [
              -115.020079,
              48.225954
            ],
            [
              -114.848098,
              48.226125
            ],
            [
              -114.84863,
              48.399464
            ],
            [
              -114.849803,
              48.573502
            ],
            [
              -114.888679,
              48.573546
            ],
            [
              -114.88859,
              48.658765
            ],
            [
              -114.639007,
              48.658878
            ],
            [
              -114.655922,
              48.670574
            ],
            [
              -114.69185,
              48.678941
            ],
            [
              -114.680912,
              48.694632
            ],
            [
              -114.691699,
              48.708625
            ],
            [
              -114.678752,
              48.724549
            ],
            [
              -114.6433,
              48.73276
            ],
            [
              -114.619201,
              48.742601
            ],
            [
              -114.612063,
              48.751858
            ],
            [
              -114.635274,
              48.759028
            ],
            [
              -114.648134,
              48.779332
            ],
            [
              -114.673381,
              48.789405
            ],
            [
              -114.671723,
              48.799353
            ],
            [
              -114.714542,
              48.801601
            ],
            [
              -114.732545,
              48.809752
            ],
            [
              -114.723248,
              48.831477
            ],
            [
              -114.691671,
              48.842115
            ],
            [
              -114.693653,
              48.874198
            ],
            [
              -114.711031,
              48.890687
            ],
            [
              -114.70391,
              48.910269
            ],
            [
              -114.718806,
              48.938678
            ],
            [
              -114.696994,
              48.9539
            ],
            [
              -114.6848,
              48.973276
            ],
            [
              -114.696861,
              48.979942
            ],
            [
              -114.73477,
              48.982392
            ],
            [
              -114.739398,
              48.990826
            ],
            [
              -114.727045223277,
              49.0005870038886
            ],
            [
              -115.207912,
              48.999228
            ],
            [
              -115.501016,
              49.000694
            ],
            [
              -115.990685,
              49.000909
            ],
            [
              -116.00103,
              49.00127
            ],
            [
              -116.049193,
              49.000912
            ],
            [
              -116.049157492578,
              48.515333990311596
            ],
            [
              -116.049156520649,
              48.502042481758096
            ],
            [
              -116.049155,
              48.481247
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "031",
      "COUNTYNS": "01720053",
      "AFFGEOID": "0500000US30031",
      "GEOID": "30031",
      "NAME": "Gallatin",
      "LSAD": "06",
      "ALAND": 6746899240,
      "AWATER": 74547800,
      "County_state": "Gallatin,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -111.805259,
              45.798306
            ],
            [
              -111.80538,
              45.786748
            ],
            [
              -111.763324,
              45.786828
            ],
            [
              -111.763273,
              45.772554
            ],
            [
              -111.721847,
              45.772369
            ],
            [
              -111.721683,
              45.743278
            ],
            [
              -111.659738,
              45.743344
            ],
            [
              -111.659742,
              45.728834
            ],
            [
              -111.639745,
              45.728825
            ],
            [
              -111.639717,
              45.714305
            ],
            [
              -111.619036,
              45.714363
            ],
            [
              -111.619037,
              45.699863
            ],
            [
              -111.598318,
              45.699962
            ],
            [
              -111.598239,
              45.685121
            ],
            [
              -111.577687,
              45.685251
            ],
            [
              -111.577698,
              45.670771
            ],
            [
              -111.557006,
              45.670995
            ],
            [
              -111.556969,
              45.656427
            ],
            [
              -111.536316,
              45.656833
            ],
            [
              -111.536241,
              45.642257
            ],
            [
              -111.35138,
              45.641938
            ],
            [
              -111.35003,
              45.350548
            ],
            [
              -111.37303,
              45.350521
            ],
            [
              -111.377483,
              44.911794
            ],
            [
              -111.37704749842301,
              44.7513741097978
            ],
            [
              -111.37476,
              44.750295
            ],
            [
              -111.36627,
              44.742234
            ],
            [
              -111.366723,
              44.738361
            ],
            [
              -111.355768,
              44.727602
            ],
            [
              -111.348184,
              44.725459
            ],
            [
              -111.341351,
              44.7293
            ],
            [
              -111.323669,
              44.724474
            ],
            [
              -111.29626,
              44.702271
            ],
            [
              -111.26875,
              44.668279
            ],
            [
              -111.276956,
              44.655626
            ],
            [
              -111.262839,
              44.649658
            ],
            [
              -111.25268,
              44.651092
            ],
            [
              -111.224161,
              44.623402
            ],
            [
              -111.231227,
              44.606915
            ],
            [
              -111.23018,
              44.587025
            ],
            [
              -111.225208,
              44.581006
            ],
            [
              -111.201459,
              44.575696
            ],
            [
              -111.189617,
              44.571062
            ],
            [
              -111.182551,
              44.566874
            ],
            [
              -111.175747,
              44.552219
            ],
            [
              -111.166892,
              44.54722
            ],
            [
              -111.15959,
              44.546376
            ],
            [
              -111.143557,
              44.535732
            ],
            [
              -111.139455,
              44.517112
            ],
            [
              -111.131379,
              44.499925
            ],
            [
              -111.122654,
              44.493659
            ],
            [
              -111.062729,
              44.476073
            ],
            [
              -111.048974,
              44.474072
            ],
            [
              -111.055208,
              44.624927
            ],
            [
              -111.055332727124,
              44.6662623099175
            ],
            [
              -111.055511,
              44.725343
            ],
            [
              -111.056416,
              44.749928
            ],
            [
              -111.056888,
              44.866658
            ],
            [
              -111.055629,
              44.933578
            ],
            [
              -111.056207,
              44.935901
            ],
            [
              -111.055199,
              45.001321
            ],
            [
              -111.045065708123,
              45.0013432628163
            ],
            [
              -111.038613,
              45.176379
            ],
            [
              -111.038169,
              45.351391
            ],
            [
              -110.917899,
              45.351273
            ],
            [
              -110.919222,
              45.524861
            ],
            [
              -110.858252,
              45.524879
            ],
            [
              -110.857382,
              45.59065
            ],
            [
              -110.794774,
              45.590682
            ],
            [
              -110.794681,
              45.613406
            ],
            [
              -110.795277,
              45.70187
            ],
            [
              -110.795441,
              45.742872
            ],
            [
              -110.795264,
              45.786319
            ],
            [
              -110.789399,
              45.786308
            ],
            [
              -110.78852,
              45.931278
            ],
            [
              -110.792404,
              45.988779
            ],
            [
              -110.784031,
              46.141826
            ],
            [
              -110.783833,
              46.192699
            ],
            [
              -110.993241,
              46.192795
            ],
            [
              -111.061956,
              46.192834
            ],
            [
              -111.149312,
              46.192804
            ],
            [
              -111.193183,
              46.187833
            ],
            [
              -111.26466,
              46.187759
            ],
            [
              -111.305562,
              46.187784
            ],
            [
              -111.346635,
              46.152741
            ],
            [
              -111.349413,
              46.127883
            ],
            [
              -111.353051,
              46.121321
            ],
            [
              -111.355543,
              46.119959
            ],
            [
              -111.357317,
              46.118587
            ],
            [
              -111.360914,
              46.117658
            ],
            [
              -111.363896,
              46.117048
            ],
            [
              -111.367193,
              46.116212
            ],
            [
              -111.370087,
              46.115372
            ],
            [
              -111.371077,
              46.114314
            ],
            [
              -111.372571,
              46.114246
            ],
            [
              -111.375017,
              46.114458
            ],
            [
              -111.377665,
              46.113735
            ],
            [
              -111.381448,
              46.108903
            ],
            [
              -111.381893,
              46.106517
            ],
            [
              -111.383621,
              46.10536
            ],
            [
              -111.384395,
              46.104142
            ],
            [
              -111.386572,
              46.104752
            ],
            [
              -111.388934,
              46.106229
            ],
            [
              -111.390876,
              46.106916
            ],
            [
              -111.392686,
              46.107983
            ],
            [
              -111.394859,
              46.108366
            ],
            [
              -111.395538,
              46.107411
            ],
            [
              -111.397068,
              46.103797
            ],
            [
              -111.392334,
              46.094874
            ],
            [
              -111.395868,
              46.088699
            ],
            [
              -111.408065,
              46.082159
            ],
            [
              -111.416124,
              46.071025
            ],
            [
              -111.424126,
              46.060721
            ],
            [
              -111.423456,
              46.051062
            ],
            [
              -111.424158,
              46.043621
            ],
            [
              -111.423465,
              46.033624
            ],
            [
              -111.422435,
              46.028236
            ],
            [
              -111.426539,
              46.023392
            ],
            [
              -111.427321,
              46.017438
            ],
            [
              -111.421248,
              46.015208
            ],
            [
              -111.415993,
              46.001509
            ],
            [
              -111.421545,
              45.995067
            ],
            [
              -111.430609,
              45.993013
            ],
            [
              -111.439586,
              45.991398
            ],
            [
              -111.444792,
              45.991851
            ],
            [
              -111.450831,
              45.99712
            ],
            [
              -111.455707,
              45.998666
            ],
            [
              -111.461694,
              45.994736
            ],
            [
              -111.463274,
              45.990742
            ],
            [
              -111.464449,
              45.982623
            ],
            [
              -111.464232,
              45.975522
            ],
            [
              -111.463843,
              45.965395
            ],
            [
              -111.468158,
              45.955019
            ],
            [
              -111.476494,
              45.949469
            ],
            [
              -111.484127,
              45.944255
            ],
            [
              -111.492009,
              45.939655
            ],
            [
              -111.495519,
              45.935023
            ],
            [
              -111.500149,
              45.932714
            ],
            [
              -111.506439,
              45.931721
            ],
            [
              -111.506687,
              45.928489
            ],
            [
              -111.505836,
              45.926817
            ],
            [
              -111.508648,
              45.925949
            ],
            [
              -111.510304,
              45.926933
            ],
            [
              -111.513773,
              45.927741
            ],
            [
              -111.515515,
              45.926541
            ],
            [
              -111.519819,
              45.926528
            ],
            [
              -111.5271,
              45.924694
            ],
            [
              -111.530554,
              45.92469
            ],
            [
              -111.532908,
              45.921291
            ],
            [
              -111.532899,
              45.918541
            ],
            [
              -111.538328,
              45.917255
            ],
            [
              -111.53891,
              45.91914
            ],
            [
              -111.541298,
              45.919713
            ],
            [
              -111.543558,
              45.918401
            ],
            [
              -111.543356,
              45.916389
            ],
            [
              -111.54389,
              45.912372
            ],
            [
              -111.546753,
              45.910157
            ],
            [
              -111.550464,
              45.910492
            ],
            [
              -111.552973,
              45.9126
            ],
            [
              -111.55763,
              45.913249
            ],
            [
              -111.559742,
              45.914811
            ],
            [
              -111.563968,
              45.912911
            ],
            [
              -111.566272,
              45.910107
            ],
            [
              -111.572918,
              45.910007
            ],
            [
              -111.578648,
              45.907233
            ],
            [
              -111.588241,
              45.905988
            ],
            [
              -111.592512,
              45.900495
            ],
            [
              -111.598428,
              45.895885
            ],
            [
              -111.603341,
              45.891766
            ],
            [
              -111.608979,
              45.887666
            ],
            [
              -111.610408,
              45.882013
            ],
            [
              -111.612244,
              45.875681
            ],
            [
              -111.617718,
              45.872131
            ],
            [
              -111.62284,
              45.868836
            ],
            [
              -111.623395,
              45.866141
            ],
            [
              -111.625474,
              45.863592
            ],
            [
              -111.625736,
              45.861772
            ],
            [
              -111.628382,
              45.862203
            ],
            [
              -111.634035,
              45.86265
            ],
            [
              -111.637021,
              45.860631
            ],
            [
              -111.640188,
              45.853762
            ],
            [
              -111.638615,
              45.85173
            ],
            [
              -111.637325,
              45.849563
            ],
            [
              -111.641398,
              45.847926
            ],
            [
              -111.643719,
              45.84908
            ],
            [
              -111.645027,
              45.850269
            ],
            [
              -111.649406,
              45.851291
            ],
            [
              -111.651424,
              45.848773
            ],
            [
              -111.654505,
              45.844408
            ],
            [
              -111.655399,
              45.841346
            ],
            [
              -111.655229,
              45.836394
            ],
            [
              -111.659056,
              45.833809
            ],
            [
              -111.660057,
              45.8335
            ],
            [
              -111.704659,
              45.795979
            ],
            [
              -111.726992,
              45.793468
            ],
            [
              -111.755855,
              45.804233
            ],
            [
              -111.78526,
              45.793137
            ],
            [
              -111.805259,
              45.798306
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "043",
      "COUNTYNS": "01720045",
      "AFFGEOID": "0500000US30043",
      "GEOID": "30043",
      "NAME": "Jefferson",
      "LSAD": "06",
      "ALAND": 4291254207,
      "AWATER": 6605135,
      "County_state": "Jefferson,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -112.579659,
              46.241824
            ],
            [
              -112.597936,
              46.236894
            ],
            [
              -112.573573,
              46.211809
            ],
            [
              -112.577106,
              46.178981
            ],
            [
              -112.545226,
              46.175001
            ],
            [
              -112.525797,
              46.185048
            ],
            [
              -112.505377,
              46.184317
            ],
            [
              -112.517036,
              46.165867
            ],
            [
              -112.512523,
              46.157775
            ],
            [
              -112.519997,
              46.136394
            ],
            [
              -112.495847,
              46.108856
            ],
            [
              -112.466329,
              46.092979
            ],
            [
              -112.46174,
              46.075894
            ],
            [
              -112.476952,
              46.051715
            ],
            [
              -112.472575,
              46.039189
            ],
            [
              -112.459429,
              46.036704
            ],
            [
              -112.444971,
              46.03378
            ],
            [
              -112.435614,
              46.013727
            ],
            [
              -112.444127,
              45.996905
            ],
            [
              -112.429902,
              45.958362
            ],
            [
              -112.436282,
              45.940318
            ],
            [
              -112.416567,
              45.921476
            ],
            [
              -112.419708,
              45.91786
            ],
            [
              -112.420745,
              45.910198
            ],
            [
              -112.404349,
              45.888049
            ],
            [
              -112.430538,
              45.854146
            ],
            [
              -112.426464,
              45.843725
            ],
            [
              -112.414212,
              45.836684
            ],
            [
              -112.26684,
              45.786311
            ],
            [
              -112.253419,
              45.770266
            ],
            [
              -112.214791,
              45.765666
            ],
            [
              -112.190269,
              45.748503
            ],
            [
              -112.168767,
              45.749981
            ],
            [
              -112.136621,
              45.771722
            ],
            [
              -112.137384,
              45.781295
            ],
            [
              -112.112238,
              45.805518
            ],
            [
              -112.118869,
              45.812461
            ],
            [
              -112.106089,
              45.841259
            ],
            [
              -112.069601,
              45.852665
            ],
            [
              -112.049832,
              45.849644
            ],
            [
              -112.022865,
              45.858781
            ],
            [
              -111.984039,
              45.846493
            ],
            [
              -111.964865,
              45.852934
            ],
            [
              -111.957188,
              45.849868
            ],
            [
              -111.928857,
              45.856977
            ],
            [
              -111.912629,
              45.830002
            ],
            [
              -111.884542,
              45.830597
            ],
            [
              -111.872147,
              45.818027
            ],
            [
              -111.843036,
              45.821302
            ],
            [
              -111.807209,
              45.809624
            ],
            [
              -111.805259,
              45.798306
            ],
            [
              -111.78526,
              45.793137
            ],
            [
              -111.755855,
              45.804233
            ],
            [
              -111.726992,
              45.793468
            ],
            [
              -111.704659,
              45.795979
            ],
            [
              -111.660057,
              45.8335
            ],
            [
              -111.66032,
              45.919835
            ],
            [
              -111.660422,
              46.049192
            ],
            [
              -111.784565,
              46.049813
            ],
            [
              -111.786418,
              46.136277
            ],
            [
              -111.787949,
              46.569465
            ],
            [
              -111.817548,
              46.569528
            ],
            [
              -112.019452,
              46.568792
            ],
            [
              -112.033348,
              46.566198
            ],
            [
              -112.033615,
              46.543127
            ],
            [
              -112.044049,
              46.533838
            ],
            [
              -112.083441,
              46.516663
            ],
            [
              -112.120845,
              46.51473
            ],
            [
              -112.135258,
              46.521887
            ],
            [
              -112.151836,
              46.511772
            ],
            [
              -112.183801,
              46.501106
            ],
            [
              -112.205757,
              46.499748
            ],
            [
              -112.203016,
              46.479642
            ],
            [
              -112.179743,
              46.470966
            ],
            [
              -112.176235,
              46.456342
            ],
            [
              -112.18849,
              46.450476
            ],
            [
              -112.215251,
              46.450423
            ],
            [
              -112.220222,
              46.436169
            ],
            [
              -112.242578,
              46.422525
            ],
            [
              -112.268397,
              46.414961
            ],
            [
              -112.287028,
              46.421879
            ],
            [
              -112.310573,
              46.421171
            ],
            [
              -112.33708,
              46.403689
            ],
            [
              -112.349605,
              46.385345
            ],
            [
              -112.403784,
              46.386566
            ],
            [
              -112.426093,
              46.36898
            ],
            [
              -112.426005,
              46.357464
            ],
            [
              -112.451363,
              46.349998
            ],
            [
              -112.454085,
              46.328932
            ],
            [
              -112.471736,
              46.340552
            ],
            [
              -112.498557,
              46.342062
            ],
            [
              -112.503954,
              46.330196
            ],
            [
              -112.533277,
              46.331401
            ],
            [
              -112.570046,
              46.304972
            ],
            [
              -112.558599,
              46.265821
            ],
            [
              -112.579659,
              46.241824
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "045",
      "COUNTYNS": "01719602",
      "AFFGEOID": "0500000US30045",
      "GEOID": "30045",
      "NAME": "Judith Basin",
      "LSAD": "06",
      "ALAND": 4842466690,
      "AWATER": 2066739,
      "County_state": "Judith Basin,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -110.788302,
              47.307848
            ],
            [
              -110.788068,
              47.249133
            ],
            [
              -110.777326,
              47.249098
            ],
            [
              -110.777426,
              47.234576
            ],
            [
              -110.766593,
              47.234539
            ],
            [
              -110.76694,
              47.183872
            ],
            [
              -110.770698,
              47.096546
            ],
            [
              -110.645212,
              47.09587
            ],
            [
              -110.645109,
              47.010685
            ],
            [
              -110.640951,
              46.923934
            ],
            [
              -110.636381,
              46.837161
            ],
            [
              -110.652909,
              46.837162
            ],
            [
              -110.652877,
              46.823626
            ],
            [
              -110.644458,
              46.816158
            ],
            [
              -110.649524,
              46.801473
            ],
            [
              -110.620687,
              46.772216
            ],
            [
              -110.563704,
              46.771363
            ],
            [
              -110.557791,
              46.759299
            ],
            [
              -110.564334,
              46.738864
            ],
            [
              -110.549607,
              46.733089
            ],
            [
              -110.524345,
              46.712788
            ],
            [
              -110.506319,
              46.712774
            ],
            [
              -110.497827,
              46.702633
            ],
            [
              -110.483057,
              46.70147
            ],
            [
              -110.466626,
              46.678526
            ],
            [
              -110.437216,
              46.686231
            ],
            [
              -110.421998,
              46.678022
            ],
            [
              -110.399556,
              46.68816
            ],
            [
              -110.375292,
              46.692912
            ],
            [
              -110.349481,
              46.682461
            ],
            [
              -110.34424,
              46.672562
            ],
            [
              -110.328511,
              46.673178
            ],
            [
              -110.325376,
              46.685724
            ],
            [
              -110.302726,
              46.688647
            ],
            [
              -110.273337,
              46.710509
            ],
            [
              -110.210758,
              46.708503
            ],
            [
              -110.191562,
              46.725631
            ],
            [
              -110.157346,
              46.716396
            ],
            [
              -110.127177,
              46.718932
            ],
            [
              -110.115707,
              46.73887
            ],
            [
              -110.086131,
              46.746895
            ],
            [
              -110.051525,
              46.749393
            ],
            [
              -110.028992,
              46.736207
            ],
            [
              -109.969221,
              46.752909
            ],
            [
              -109.936151,
              46.737808
            ],
            [
              -109.895758,
              46.725927
            ],
            [
              -109.857791,
              46.708259
            ],
            [
              -109.845162,
              46.710814
            ],
            [
              -109.819866,
              46.693446
            ],
            [
              -109.745745,
              46.694097
            ],
            [
              -109.745815,
              46.744327
            ],
            [
              -109.756379,
              46.744238
            ],
            [
              -109.756666,
              46.780389
            ],
            [
              -109.787614,
              46.780261
            ],
            [
              -109.787763,
              46.794745
            ],
            [
              -109.798462,
              46.801972
            ],
            [
              -109.830132,
              46.801932
            ],
            [
              -109.835424,
              46.809161
            ],
            [
              -109.835569,
              46.830865
            ],
            [
              -109.798833,
              46.830933
            ],
            [
              -109.797469,
              46.867038
            ],
            [
              -109.792249,
              46.881524
            ],
            [
              -109.776408,
              46.881539
            ],
            [
              -109.776507,
              46.896005
            ],
            [
              -109.755423,
              46.896023
            ],
            [
              -109.756568,
              47.185694
            ],
            [
              -109.871985,
              47.185323
            ],
            [
              -109.872475,
              47.228883
            ],
            [
              -110.000024,
              47.228716
            ],
            [
              -110.000289,
              47.272133
            ],
            [
              -110.127699,
              47.27191
            ],
            [
              -110.127952,
              47.35902
            ],
            [
              -110.213285,
              47.358771
            ],
            [
              -110.213596,
              47.416657
            ],
            [
              -110.639559,
              47.416486
            ],
            [
              -110.638911,
              47.359032
            ],
            [
              -110.767125,
              47.359128
            ],
            [
              -110.767032,
              47.297065
            ],
            [
              -110.772313,
              47.307914
            ],
            [
              -110.788302,
              47.307848
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "099",
      "COUNTYNS": "01720018",
      "AFFGEOID": "0500000US30099",
      "GEOID": "30099",
      "NAME": "Teton",
      "LSAD": "06",
      "ALAND": 5883425292,
      "AWATER": 52657849,
      "County_state": "Teton,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -113.014811,
              48.13103
            ],
            [
              -113.000624,
              48.130734
            ],
            [
              -112.983366,
              48.117761
            ],
            [
              -112.994237,
              48.098514
            ],
            [
              -112.978902,
              48.088645
            ],
            [
              -112.982017,
              48.073957
            ],
            [
              -112.963711,
              48.07491
            ],
            [
              -112.93713,
              48.055523
            ],
            [
              -112.950716,
              48.049976
            ],
            [
              -112.947066,
              48.030981
            ],
            [
              -112.936203,
              48.035341
            ],
            [
              -112.907849,
              48.029873
            ],
            [
              -112.878931,
              47.975713
            ],
            [
              -112.892309,
              47.976359
            ],
            [
              -112.912178,
              47.946577
            ],
            [
              -112.951407,
              47.944591
            ],
            [
              -112.984734,
              47.953918
            ],
            [
              -112.98381,
              47.952581
            ],
            [
              -112.980385,
              47.942392
            ],
            [
              -112.976326,
              47.926696
            ],
            [
              -112.974992,
              47.916027
            ],
            [
              -112.967616,
              47.910902
            ],
            [
              -112.956751,
              47.90779
            ],
            [
              -112.951582,
              47.897885
            ],
            [
              -112.948661,
              47.891712
            ],
            [
              -112.950256,
              47.88519
            ],
            [
              -112.946435,
              47.875569
            ],
            [
              -112.943553,
              47.870793
            ],
            [
              -112.937613,
              47.865797
            ],
            [
              -112.928108,
              47.86041
            ],
            [
              -112.927513,
              47.852745
            ],
            [
              -112.925327,
              47.848301
            ],
            [
              -112.925172,
              47.843877
            ],
            [
              -112.917737,
              47.838339
            ],
            [
              -112.91627,
              47.832338
            ],
            [
              -112.914137,
              47.824917
            ],
            [
              -112.915972,
              47.817618
            ],
            [
              -112.916562,
              47.809735
            ],
            [
              -112.916334,
              47.804039
            ],
            [
              -112.918313,
              47.795465
            ],
            [
              -112.91916,
              47.79103
            ],
            [
              -112.920823,
              47.78374
            ],
            [
              -112.920307,
              47.77678
            ],
            [
              -112.920841,
              47.76967
            ],
            [
              -112.925757,
              47.761044
            ],
            [
              -112.918242,
              47.753242
            ],
            [
              -112.913371,
              47.742477
            ],
            [
              -112.907363,
              47.737162
            ],
            [
              -112.902862,
              47.731109
            ],
            [
              -112.903182,
              47.725907
            ],
            [
              -112.903833,
              47.718094
            ],
            [
              -112.903846,
              47.707215
            ],
            [
              -112.909354,
              47.701557
            ],
            [
              -112.909889,
              47.692392
            ],
            [
              -112.906474,
              47.688667
            ],
            [
              -112.902888,
              47.683199
            ],
            [
              -112.897659,
              47.677811
            ],
            [
              -112.891918,
              47.67438
            ],
            [
              -112.885177,
              47.66524
            ],
            [
              -112.878282,
              47.65939
            ],
            [
              -112.875404,
              47.651618
            ],
            [
              -112.868253,
              47.646501
            ],
            [
              -112.859612,
              47.638027
            ],
            [
              -112.85617,
              47.630134
            ],
            [
              -112.846122,
              47.628257
            ],
            [
              -112.840963,
              47.623247
            ],
            [
              -112.834708,
              47.619647
            ],
            [
              -112.827435,
              47.613294
            ],
            [
              -112.81896,
              47.608118
            ],
            [
              -112.809653,
              47.609964
            ],
            [
              -112.801723,
              47.610373
            ],
            [
              -112.791495,
              47.602597
            ],
            [
              -112.779548,
              47.598429
            ],
            [
              -112.768642,
              47.599077
            ],
            [
              -112.759642,
              47.601848
            ],
            [
              -112.758021,
              47.605631
            ],
            [
              -112.753655,
              47.607151
            ],
            [
              -112.749064,
              47.607733
            ],
            [
              -112.740234,
              47.609482
            ],
            [
              -112.735355,
              47.613553
            ],
            [
              -112.728178,
              47.617462
            ],
            [
              -112.719465,
              47.616151
            ],
            [
              -112.710891,
              47.619327
            ],
            [
              -112.702702,
              47.620912
            ],
            [
              -112.697161,
              47.62224
            ],
            [
              -112.692853,
              47.619656
            ],
            [
              -112.689941,
              47.616837
            ],
            [
              -112.685768,
              47.614876
            ],
            [
              -112.681536,
              47.616768
            ],
            [
              -112.680646,
              47.61866
            ],
            [
              -112.675981,
              47.620733
            ],
            [
              -112.672964,
              47.621639
            ],
            [
              -112.670187,
              47.620575
            ],
            [
              -112.66647,
              47.61826
            ],
            [
              -112.662766,
              47.619359
            ],
            [
              -112.662576,
              47.621838
            ],
            [
              -112.657029,
              47.622584
            ],
            [
              -112.651817,
              47.621642
            ],
            [
              -112.642616,
              47.622493
            ],
            [
              -112.638513,
              47.627226
            ],
            [
              -112.631545,
              47.62731
            ],
            [
              -112.626886,
              47.630178
            ],
            [
              -112.622313,
              47.633527
            ],
            [
              -112.613358,
              47.635648
            ],
            [
              -112.605991,
              47.633548
            ],
            [
              -112.600166,
              47.633022
            ],
            [
              -112.592762,
              47.634933
            ],
            [
              -112.584639,
              47.634468
            ],
            [
              -112.575,
              47.634767
            ],
            [
              -112.567537,
              47.637302
            ],
            [
              -112.564052,
              47.639522
            ],
            [
              -112.563129,
              47.642928
            ],
            [
              -112.557075,
              47.646594
            ],
            [
              -112.554478,
              47.65069
            ],
            [
              -112.551193,
              47.65203
            ],
            [
              -112.545231,
              47.650257
            ],
            [
              -112.544047,
              47.647727
            ],
            [
              -112.541976,
              47.646658
            ],
            [
              -112.539375,
              47.646792
            ],
            [
              -112.532063,
              47.64296
            ],
            [
              -112.526957,
              47.641816
            ],
            [
              -112.519253,
              47.641547
            ],
            [
              -112.519679,
              47.638193
            ],
            [
              -112.519945,
              47.633029
            ],
            [
              -112.514738,
              47.629104
            ],
            [
              -112.516068,
              47.624844
            ],
            [
              -112.511327,
              47.621224
            ],
            [
              -112.507007,
              47.6195
            ],
            [
              -112.502314,
              47.620849
            ],
            [
              -112.499154,
              47.617605
            ],
            [
              -112.496797,
              47.615509
            ],
            [
              -112.490045,
              47.613215
            ],
            [
              -112.484593,
              47.615145
            ],
            [
              -112.475005,
              47.614269
            ],
            [
              -112.464873,
              47.611252
            ],
            [
              -112.45472,
              47.610271
            ],
            [
              -112.447445,
              47.60791
            ],
            [
              -112.440203,
              47.606123
            ],
            [
              -112.432711,
              47.606104
            ],
            [
              -112.42913,
              47.603358
            ],
            [
              -112.420876,
              47.599395
            ],
            [
              -112.419136,
              47.592725
            ],
            [
              -112.416691,
              47.58793
            ],
            [
              -112.418633,
              47.582554
            ],
            [
              -112.411945,
              47.577435
            ],
            [
              -112.408199,
              47.578091
            ],
            [
              -112.405076,
              47.574266
            ],
            [
              -112.407224,
              47.570504
            ],
            [
              -112.402853,
              47.565026
            ],
            [
              -112.404638,
              47.560294
            ],
            [
              -112.407949,
              47.556999
            ],
            [
              -112.403816,
              47.552324
            ],
            [
              -112.398388,
              47.552525
            ],
            [
              -112.387699,
              47.552075
            ],
            [
              -112.379571,
              47.54903
            ],
            [
              -112.370777,
              47.546921
            ],
            [
              -112.358452,
              47.549381
            ],
            [
              -112.351299,
              47.550471
            ],
            [
              -112.342669,
              47.549574
            ],
            [
              -112.343162,
              47.545815
            ],
            [
              -112.344719,
              47.541769
            ],
            [
              -112.337427,
              47.539898
            ],
            [
              -112.332962,
              47.542214
            ],
            [
              -112.326861,
              47.543147
            ],
            [
              -112.317216,
              47.541254
            ],
            [
              -112.311927,
              47.535691
            ],
            [
              -112.309252,
              47.52851
            ],
            [
              -112.30224,
              47.52603
            ],
            [
              -112.292777,
              47.522381
            ],
            [
              -112.287413,
              47.515683
            ],
            [
              -112.282973,
              47.510346
            ],
            [
              -112.280139,
              47.506511
            ],
            [
              -112.272216,
              47.507419
            ],
            [
              -112.264307,
              47.508612
            ],
            [
              -112.251861,
              47.510263
            ],
            [
              -112.24377,
              47.50703
            ],
            [
              -112.233527,
              47.507178
            ],
            [
              -112.225182,
              47.507677
            ],
            [
              -112.215898,
              47.507736
            ],
            [
              -112.213005,
              47.503567
            ],
            [
              -112.207539,
              47.502421
            ],
            [
              -112.201767,
              47.505844
            ],
            [
              -112.19163,
              47.507173
            ],
            [
              -112.185151,
              47.510812
            ],
            [
              -112.176438,
              47.513011
            ],
            [
              -112.17263,
              47.51037
            ],
            [
              -112.163172,
              47.510732
            ],
            [
              -112.154387,
              47.512488
            ],
            [
              -112.149973,
              47.514795
            ],
            [
              -112.145119,
              47.516005
            ],
            [
              -112.140311,
              47.514841
            ],
            [
              -112.131162,
              47.512421
            ],
            [
              -112.121429,
              47.511495
            ],
            [
              -112.116641,
              47.50786
            ],
            [
              -112.110886,
              47.50748
            ],
            [
              -112.105604,
              47.508126
            ],
            [
              -112.102974,
              47.511052
            ],
            [
              -112.093494,
              47.51144
            ],
            [
              -112.087636,
              47.515713
            ],
            [
              -112.079187,
              47.515536
            ],
            [
              -112.075347,
              47.512484
            ],
            [
              -112.068007,
              47.513857
            ],
            [
              -112.063359,
              47.51694
            ],
            [
              -112.051983,
              47.515045
            ],
            [
              -112.047781,
              47.516034
            ],
            [
              -112.026416,
              47.517836
            ],
            [
              -111.99944,
              47.507289
            ],
            [
              -111.962236,
              47.504089
            ],
            [
              -111.922795,
              47.505064
            ],
            [
              -111.922567,
              47.611259
            ],
            [
              -111.66626,
              47.611253
            ],
            [
              -111.666482,
              47.697761
            ],
            [
              -111.645592,
              47.697842
            ],
            [
              -111.409103,
              47.698123
            ],
            [
              -111.40885,
              47.698123
            ],
            [
              -111.408514,
              47.987178
            ],
            [
              -111.666316,
              47.987273
            ],
            [
              -111.789595,
              47.984162
            ],
            [
              -111.984263,
              47.984592
            ],
            [
              -111.984125,
              48.042767
            ],
            [
              -112.048342,
              48.042909
            ],
            [
              -112.047999,
              48.086612
            ],
            [
              -112.177751,
              48.087162
            ],
            [
              -112.177755,
              48.130717
            ],
            [
              -112.900707,
              48.13103
            ],
            [
              -113.014811,
              48.13103
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "061",
      "COUNTYNS": "01720030",
      "AFFGEOID": "0500000US30061",
      "GEOID": "30061",
      "NAME": "Mineral",
      "LSAD": "06",
      "ALAND": 3158799736,
      "AWATER": 9897206,
      "County_state": "Mineral,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -115.748284,
              47.441703
            ],
            [
              -115.759347,
              47.422233
            ],
            [
              -115.739287,
              47.425943
            ],
            [
              -115.72148,
              47.424469
            ],
            [
              -115.718934,
              47.420967
            ],
            [
              -115.71034,
              47.417784
            ],
            [
              -115.69057,
              47.415059
            ],
            [
              -115.657681,
              47.400651
            ],
            [
              -115.648479,
              47.390293
            ],
            [
              -115.644341,
              47.381826
            ],
            [
              -115.639186,
              47.378605
            ],
            [
              -115.617247,
              47.382521
            ],
            [
              -115.609492,
              47.380799
            ],
            [
              -115.578619,
              47.367007
            ],
            [
              -115.570887,
              47.356375
            ],
            [
              -115.564766,
              47.353476
            ],
            [
              -115.556318,
              47.353076
            ],
            [
              -115.551079,
              47.349856
            ],
            [
              -115.548658,
              47.332213
            ],
            [
              -115.531971,
              47.314121
            ],
            [
              -115.526751,
              47.303219
            ],
            [
              -115.51186,
              47.295219
            ],
            [
              -115.487314,
              47.286518
            ],
            [
              -115.470959,
              47.284873
            ],
            [
              -115.457077,
              47.277794
            ],
            [
              -115.443566,
              47.277309
            ],
            [
              -115.428359,
              47.278722
            ],
            [
              -115.423173,
              47.276222
            ],
            [
              -115.421645,
              47.271736
            ],
            [
              -115.410685,
              47.264228
            ],
            [
              -115.371825,
              47.265213
            ],
            [
              -115.36628,
              47.261485
            ],
            [
              -115.3593,
              47.259461
            ],
            [
              -115.339201,
              47.261623
            ],
            [
              -115.326903,
              47.255912
            ],
            [
              -115.324832,
              47.244841
            ],
            [
              -115.317124,
              47.233305
            ],
            [
              -115.311875,
              47.229673
            ],
            [
              -115.307239,
              47.229892
            ],
            [
              -115.298794,
              47.225245
            ],
            [
              -115.294785,
              47.220914
            ],
            [
              -115.29211,
              47.209861
            ],
            [
              -115.295986,
              47.205658
            ],
            [
              -115.300805,
              47.19393
            ],
            [
              -115.300504,
              47.188139
            ],
            [
              -115.286353,
              47.18327
            ],
            [
              -115.261885,
              47.181742
            ],
            [
              -115.255786,
              47.174725
            ],
            [
              -115.255146,
              47.162876
            ],
            [
              -115.243707,
              47.150347
            ],
            [
              -115.223246,
              47.148974
            ],
            [
              -115.200547,
              47.139154
            ],
            [
              -115.189451,
              47.131032
            ],
            [
              -115.172938,
              47.112881
            ],
            [
              -115.170436,
              47.106265
            ],
            [
              -115.140375,
              47.093013
            ],
            [
              -115.139515,
              47.08456
            ],
            [
              -115.136671,
              47.078276
            ],
            [
              -115.120917,
              47.061237
            ],
            [
              -115.107132,
              47.049041
            ],
            [
              -115.102681,
              47.047239
            ],
            [
              -115.098136,
              47.048897
            ],
            [
              -115.087806,
              47.045519
            ],
            [
              -115.071254,
              47.022083
            ],
            [
              -115.066223,
              46.996375
            ],
            [
              -115.057098,
              46.986758
            ],
            [
              -115.049538,
              46.970774
            ],
            [
              -115.047857,
              46.969533
            ],
            [
              -115.031651,
              46.971548
            ],
            [
              -115.028994,
              46.973159
            ],
            [
              -115.028386,
              46.975659
            ],
            [
              -115.001274,
              46.971901
            ],
            [
              -115.00091,
              46.967703
            ],
            [
              -114.986539,
              46.952099
            ],
            [
              -114.96397803595102,
              46.9328888722194
            ],
            [
              -114.960597,
              46.93001
            ],
            [
              -114.929997,
              46.919625
            ],
            [
              -114.927432,
              46.914185
            ],
            [
              -114.927948,
              46.909948
            ],
            [
              -114.935035,
              46.901749
            ],
            [
              -114.936805,
              46.897378
            ],
            [
              -114.931058,
              46.882108
            ],
            [
              -114.931608,
              46.876799
            ],
            [
              -114.938713,
              46.869021
            ],
            [
              -114.943281,
              46.867971
            ],
            [
              -114.947413,
              46.859324
            ],
            [
              -114.940398,
              46.85605
            ],
            [
              -114.928615,
              46.854815
            ],
            [
              -114.92349,
              46.847594
            ],
            [
              -114.92845,
              46.843242
            ],
            [
              -114.927837,
              46.83599
            ],
            [
              -114.920459,
              46.827697
            ],
            [
              -114.904505,
              46.822851
            ],
            [
              -114.897857,
              46.813184
            ],
            [
              -114.888146,
              46.808573
            ],
            [
              -114.880588,
              46.811791
            ],
            [
              -114.864342,
              46.813858
            ],
            [
              -114.861376,
              46.81196
            ],
            [
              -114.860067,
              46.804988
            ],
            [
              -114.856874,
              46.801633
            ],
            [
              -114.844794,
              46.794305
            ],
            [
              -114.835917,
              46.791111
            ],
            [
              -114.829117,
              46.782503
            ],
            [
              -114.818161,
              46.781139
            ],
            [
              -114.808587,
              46.78235
            ],
            [
              -114.79004,
              46.778729
            ],
            [
              -114.765106,
              46.758153
            ],
            [
              -114.765127,
              46.745383
            ],
            [
              -114.76718,
              46.738828
            ],
            [
              -114.773765,
              46.731805
            ],
            [
              -114.779668,
              46.730411
            ],
            [
              -114.788656,
              46.714033
            ],
            [
              -114.787065,
              46.711255
            ],
            [
              -114.76689,
              46.696901
            ],
            [
              -114.751921,
              46.697207
            ],
            [
              -114.749257,
              46.699688
            ],
            [
              -114.747758,
              46.702649
            ],
            [
              -114.740115,
              46.711771
            ],
            [
              -114.727445,
              46.714604
            ],
            [
              -114.713516,
              46.715138
            ],
            [
              -114.710425,
              46.717704
            ],
            [
              -114.699008,
              46.740223
            ],
            [
              -114.696656,
              46.740572
            ],
            [
              -114.674941612313,
              46.737042975581495
            ],
            [
              -114.674925,
              46.747414
            ],
            [
              -114.549127,
              46.747366
            ],
            [
              -114.549004,
              46.833626
            ],
            [
              -114.566259,
              46.833557
            ],
            [
              -114.566129,
              46.963405
            ],
            [
              -114.546445,
              46.963371
            ],
            [
              -114.547172,
              46.992439
            ],
            [
              -114.52533,
              46.992328
            ],
            [
              -114.525358,
              47.00925
            ],
            [
              -114.502648,
              47.009137
            ],
            [
              -114.486134,
              46.999197
            ],
            [
              -114.451265,
              46.993201
            ],
            [
              -114.451356,
              47.0089
            ],
            [
              -114.419374,
              47.008896
            ],
            [
              -114.419532,
              47.023246
            ],
            [
              -114.483227,
              47.023358
            ],
            [
              -114.483694,
              47.067508
            ],
            [
              -114.566094,
              47.06738
            ],
            [
              -114.565653,
              47.09485
            ],
            [
              -114.586671,
              47.094853
            ],
            [
              -114.586735,
              47.123757
            ],
            [
              -114.629214,
              47.123721
            ],
            [
              -114.629151,
              47.138255
            ],
            [
              -114.65047,
              47.138244
            ],
            [
              -114.650226,
              47.167163
            ],
            [
              -114.691397,
              47.167201
            ],
            [
              -114.691364,
              47.18227
            ],
            [
              -114.712283,
              47.182187
            ],
            [
              -114.712274,
              47.22559
            ],
            [
              -114.775833,
              47.225573
            ],
            [
              -114.775846,
              47.240053
            ],
            [
              -114.797135,
              47.240033
            ],
            [
              -114.797204,
              47.268916
            ],
            [
              -114.842324,
              47.268847
            ],
            [
              -114.842453,
              47.297943
            ],
            [
              -114.906046,
              47.29797
            ],
            [
              -114.906014,
              47.312482
            ],
            [
              -114.968978,
              47.312625
            ],
            [
              -114.969056,
              47.37405
            ],
            [
              -114.979189,
              47.395391
            ],
            [
              -115.014059,
              47.400682
            ],
            [
              -115.027637,
              47.414431
            ],
            [
              -115.045046,
              47.408409
            ],
            [
              -115.087872,
              47.427136
            ],
            [
              -115.128069,
              47.423028
            ],
            [
              -115.149491,
              47.432934
            ],
            [
              -115.157386,
              47.447809
            ],
            [
              -115.193855,
              47.461075
            ],
            [
              -115.212804,
              47.46245
            ],
            [
              -115.249057,
              47.480416
            ],
            [
              -115.282181,
              47.486923
            ],
            [
              -115.305978,
              47.471432
            ],
            [
              -115.342687,
              47.470913
            ],
            [
              -115.3634,
              47.48367
            ],
            [
              -115.401676,
              47.484904
            ],
            [
              -115.429305,
              47.476241
            ],
            [
              -115.443734,
              47.465608
            ],
            [
              -115.489936,
              47.47449
            ],
            [
              -115.504465,
              47.494791
            ],
            [
              -115.52591,
              47.49541
            ],
            [
              -115.522726,
              47.484203
            ],
            [
              -115.533142,
              47.475678
            ],
            [
              -115.566332,
              47.471053
            ],
            [
              -115.612747,
              47.474894
            ],
            [
              -115.62887367061101,
              47.4797042164009
            ],
            [
              -115.628695,
              47.479641
            ],
            [
              -115.631955,
              47.473409
            ],
            [
              -115.639512,
              47.460812
            ],
            [
              -115.648807,
              47.456228
            ],
            [
              -115.671188,
              47.45439
            ],
            [
              -115.69293,
              47.457237
            ],
            [
              -115.718247,
              47.45316
            ],
            [
              -115.728801,
              47.445159
            ],
            [
              -115.748284,
              47.441703
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "023",
      "COUNTYNS": "01719617",
      "AFFGEOID": "0500000US30023",
      "GEOID": "30023",
      "NAME": "Deer Lodge",
      "LSAD": "06",
      "ALAND": 1907999319,
      "AWATER": 12199471,
      "County_state": "Deer Lodge,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -113.517409,
              45.940089
            ],
            [
              -113.516144,
              45.938814
            ],
            [
              -113.495527,
              45.936184
            ],
            [
              -113.468014,
              45.885971
            ],
            [
              -113.441418,
              45.855822
            ],
            [
              -113.429665,
              45.83108
            ],
            [
              -113.434945,
              45.806614
            ],
            [
              -113.395063,
              45.76649
            ],
            [
              -113.377852,
              45.759976
            ],
            [
              -113.362945,
              45.758021
            ],
            [
              -113.364429,
              45.771036
            ],
            [
              -113.346235,
              45.805143
            ],
            [
              -113.335912,
              45.81324
            ],
            [
              -113.308299,
              45.806389
            ],
            [
              -113.298169,
              45.81861
            ],
            [
              -113.275418,
              45.817973
            ],
            [
              -113.249978,
              45.839614
            ],
            [
              -113.255046,
              45.845582
            ],
            [
              -113.228838,
              45.866457
            ],
            [
              -113.19694,
              45.874555
            ],
            [
              -113.178277,
              45.885226
            ],
            [
              -113.100273,
              45.881717
            ],
            [
              -113.085517,
              45.860648
            ],
            [
              -113.060777,
              45.869716
            ],
            [
              -113.024083,
              45.871393
            ],
            [
              -113.014113,
              45.883285
            ],
            [
              -112.965254,
              45.90554
            ],
            [
              -112.96021,
              45.922587
            ],
            [
              -112.929238,
              45.926848
            ],
            [
              -112.923224,
              45.934595
            ],
            [
              -112.938401,
              45.968412
            ],
            [
              -112.938955,
              45.993258
            ],
            [
              -112.922793,
              46.004811
            ],
            [
              -112.88375,
              45.998092
            ],
            [
              -112.867843,
              46.010551
            ],
            [
              -112.870103,
              46.028215
            ],
            [
              -112.852763,
              46.048434
            ],
            [
              -112.777717,
              46.048313
            ],
            [
              -112.777499,
              46.067428
            ],
            [
              -112.777487,
              46.067811
            ],
            [
              -112.77712,
              46.135826
            ],
            [
              -112.681004,
              46.135672
            ],
            [
              -112.656229,
              46.152864
            ],
            [
              -112.640342,
              46.174398
            ],
            [
              -112.615227,
              46.165391
            ],
            [
              -112.577106,
              46.178981
            ],
            [
              -112.573573,
              46.211809
            ],
            [
              -112.597936,
              46.236894
            ],
            [
              -112.579659,
              46.241824
            ],
            [
              -112.558599,
              46.265821
            ],
            [
              -113.036825,
              46.266826
            ],
            [
              -113.036818,
              46.22333
            ],
            [
              -113.284532,
              46.222783
            ],
            [
              -113.285039,
              46.135788
            ],
            [
              -113.274364,
              46.135798
            ],
            [
              -113.274359,
              46.059326
            ],
            [
              -113.278012,
              46.045268
            ],
            [
              -113.335804,
              46.005384
            ],
            [
              -113.362468,
              46.007155
            ],
            [
              -113.378448,
              46.01863
            ],
            [
              -113.396907,
              46.014241
            ],
            [
              -113.399267,
              46.000469
            ],
            [
              -113.415089,
              45.989807
            ],
            [
              -113.440669,
              45.981405
            ],
            [
              -113.458019,
              45.984303
            ],
            [
              -113.468163,
              45.968122
            ],
            [
              -113.459333,
              45.962294
            ],
            [
              -113.480035,
              45.939763
            ],
            [
              -113.499843,
              45.948439
            ],
            [
              -113.517409,
              45.940089
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "093",
      "COUNTYNS": "01719575",
      "AFFGEOID": "0500000US30093",
      "GEOID": "30093",
      "NAME": "Silver Bow",
      "LSAD": "06",
      "ALAND": 1859568288,
      "AWATER": 1518177,
      "County_state": "Silver Bow,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -113.060777,
              45.869716
            ],
            [
              -113.085517,
              45.860648
            ],
            [
              -113.024696,
              45.829739
            ],
            [
              -113.002041,
              45.815887
            ],
            [
              -112.999274,
              45.807347
            ],
            [
              -112.988358,
              45.808476
            ],
            [
              -112.945329,
              45.799029
            ],
            [
              -112.927058,
              45.801387
            ],
            [
              -112.902475,
              45.780303
            ],
            [
              -112.884734,
              45.789258
            ],
            [
              -112.868394,
              45.777761
            ],
            [
              -112.855172,
              45.779797
            ],
            [
              -112.823566,
              45.774252
            ],
            [
              -112.799207,
              45.75876
            ],
            [
              -112.785239,
              45.763659
            ],
            [
              -112.780579,
              45.755554
            ],
            [
              -112.759339,
              45.748209
            ],
            [
              -112.758271,
              45.734084
            ],
            [
              -112.747754,
              45.729421
            ],
            [
              -112.753717,
              45.711934
            ],
            [
              -112.736315,
              45.686198
            ],
            [
              -112.701192,
              45.67654
            ],
            [
              -112.692293,
              45.655676
            ],
            [
              -112.699704,
              45.645018
            ],
            [
              -112.687054,
              45.624854
            ],
            [
              -112.60066,
              45.661264
            ],
            [
              -112.577565,
              45.678144
            ],
            [
              -112.549953,
              45.684158
            ],
            [
              -112.523792,
              45.70795
            ],
            [
              -112.525706,
              45.727652
            ],
            [
              -112.515453,
              45.746702
            ],
            [
              -112.498432,
              45.759765
            ],
            [
              -112.485311,
              45.759007
            ],
            [
              -112.461816,
              45.742584
            ],
            [
              -112.190269,
              45.748503
            ],
            [
              -112.214791,
              45.765666
            ],
            [
              -112.253419,
              45.770266
            ],
            [
              -112.26684,
              45.786311
            ],
            [
              -112.414212,
              45.836684
            ],
            [
              -112.426464,
              45.843725
            ],
            [
              -112.430538,
              45.854146
            ],
            [
              -112.404349,
              45.888049
            ],
            [
              -112.420745,
              45.910198
            ],
            [
              -112.419708,
              45.91786
            ],
            [
              -112.416567,
              45.921476
            ],
            [
              -112.436282,
              45.940318
            ],
            [
              -112.429902,
              45.958362
            ],
            [
              -112.444127,
              45.996905
            ],
            [
              -112.435614,
              46.013727
            ],
            [
              -112.444971,
              46.03378
            ],
            [
              -112.459429,
              46.036704
            ],
            [
              -112.472575,
              46.039189
            ],
            [
              -112.476952,
              46.051715
            ],
            [
              -112.46174,
              46.075894
            ],
            [
              -112.466329,
              46.092979
            ],
            [
              -112.495847,
              46.108856
            ],
            [
              -112.519997,
              46.136394
            ],
            [
              -112.512523,
              46.157775
            ],
            [
              -112.517036,
              46.165867
            ],
            [
              -112.505377,
              46.184317
            ],
            [
              -112.525797,
              46.185048
            ],
            [
              -112.545226,
              46.175001
            ],
            [
              -112.577106,
              46.178981
            ],
            [
              -112.615227,
              46.165391
            ],
            [
              -112.640342,
              46.174398
            ],
            [
              -112.656229,
              46.152864
            ],
            [
              -112.681004,
              46.135672
            ],
            [
              -112.77712,
              46.135826
            ],
            [
              -112.777487,
              46.067811
            ],
            [
              -112.777499,
              46.067428
            ],
            [
              -112.777717,
              46.048313
            ],
            [
              -112.852763,
              46.048434
            ],
            [
              -112.870103,
              46.028215
            ],
            [
              -112.867843,
              46.010551
            ],
            [
              -112.88375,
              45.998092
            ],
            [
              -112.922793,
              46.004811
            ],
            [
              -112.938955,
              45.993258
            ],
            [
              -112.938401,
              45.968412
            ],
            [
              -112.923224,
              45.934595
            ],
            [
              -112.929238,
              45.926848
            ],
            [
              -112.96021,
              45.922587
            ],
            [
              -112.965254,
              45.90554
            ],
            [
              -113.014113,
              45.883285
            ],
            [
              -113.024083,
              45.871393
            ],
            [
              -113.060777,
              45.869716
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "057",
      "COUNTYNS": "01720033",
      "AFFGEOID": "0500000US30057",
      "GEOID": "30057",
      "NAME": "Madison",
      "LSAD": "06",
      "ALAND": 9293288997,
      "AWATER": 39841801,
      "County_state": "Madison,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -112.704848,
              45.533232
            ],
            [
              -112.699133,
              45.513612
            ],
            [
              -112.691417,
              45.483518
            ],
            [
              -112.638841,
              45.4551
            ],
            [
              -112.628609,
              45.440586
            ],
            [
              -112.59855,
              45.42943
            ],
            [
              -112.577233,
              45.429838
            ],
            [
              -112.53944,
              45.446534
            ],
            [
              -112.505568,
              45.443009
            ],
            [
              -112.46426,
              45.39382
            ],
            [
              -112.464636,
              45.34991
            ],
            [
              -112.456854,
              45.088509
            ],
            [
              -112.333899,
              45.088423
            ],
            [
              -112.334248,
              45.000519
            ],
            [
              -112.191187,
              45.000512
            ],
            [
              -112.190634,
              44.912735
            ],
            [
              -112.166986,
              44.912587
            ],
            [
              -112.167108,
              44.826738
            ],
            [
              -112.065678,
              44.825985
            ],
            [
              -112.065797,
              44.783475
            ],
            [
              -112.025141,
              44.782112
            ],
            [
              -111.781157,
              44.781027
            ],
            [
              -111.781099,
              44.708845
            ],
            [
              -111.482206819713,
              44.7086946688743
            ],
            [
              -111.438793,
              44.720546
            ],
            [
              -111.429604,
              44.720149
            ],
            [
              -111.424214,
              44.714024
            ],
            [
              -111.414271,
              44.710741
            ],
            [
              -111.398575,
              44.723343
            ],
            [
              -111.394459,
              44.744578
            ],
            [
              -111.397805,
              44.746738
            ],
            [
              -111.393854,
              44.752549
            ],
            [
              -111.385005,
              44.755128
            ],
            [
              -111.37704749842301,
              44.7513741097978
            ],
            [
              -111.377483,
              44.911794
            ],
            [
              -111.37303,
              45.350521
            ],
            [
              -111.35003,
              45.350548
            ],
            [
              -111.35138,
              45.641938
            ],
            [
              -111.536241,
              45.642257
            ],
            [
              -111.536316,
              45.656833
            ],
            [
              -111.556969,
              45.656427
            ],
            [
              -111.557006,
              45.670995
            ],
            [
              -111.577698,
              45.670771
            ],
            [
              -111.577687,
              45.685251
            ],
            [
              -111.598239,
              45.685121
            ],
            [
              -111.598318,
              45.699962
            ],
            [
              -111.619037,
              45.699863
            ],
            [
              -111.619036,
              45.714363
            ],
            [
              -111.639717,
              45.714305
            ],
            [
              -111.639745,
              45.728825
            ],
            [
              -111.659742,
              45.728834
            ],
            [
              -111.659738,
              45.743344
            ],
            [
              -111.721683,
              45.743278
            ],
            [
              -111.721847,
              45.772369
            ],
            [
              -111.763273,
              45.772554
            ],
            [
              -111.763324,
              45.786828
            ],
            [
              -111.80538,
              45.786748
            ],
            [
              -111.805259,
              45.798306
            ],
            [
              -111.807209,
              45.809624
            ],
            [
              -111.843036,
              45.821302
            ],
            [
              -111.872147,
              45.818027
            ],
            [
              -111.884542,
              45.830597
            ],
            [
              -111.912629,
              45.830002
            ],
            [
              -111.928857,
              45.856977
            ],
            [
              -111.957188,
              45.849868
            ],
            [
              -111.964865,
              45.852934
            ],
            [
              -111.984039,
              45.846493
            ],
            [
              -112.022865,
              45.858781
            ],
            [
              -112.049832,
              45.849644
            ],
            [
              -112.069601,
              45.852665
            ],
            [
              -112.106089,
              45.841259
            ],
            [
              -112.118869,
              45.812461
            ],
            [
              -112.112238,
              45.805518
            ],
            [
              -112.137384,
              45.781295
            ],
            [
              -112.136621,
              45.771722
            ],
            [
              -112.168767,
              45.749981
            ],
            [
              -112.190269,
              45.748503
            ],
            [
              -112.461816,
              45.742584
            ],
            [
              -112.485311,
              45.759007
            ],
            [
              -112.498432,
              45.759765
            ],
            [
              -112.515453,
              45.746702
            ],
            [
              -112.525706,
              45.727652
            ],
            [
              -112.523792,
              45.70795
            ],
            [
              -112.549953,
              45.684158
            ],
            [
              -112.577565,
              45.678144
            ],
            [
              -112.60066,
              45.661264
            ],
            [
              -112.687054,
              45.624854
            ],
            [
              -112.679445,
              45.612427
            ],
            [
              -112.687155,
              45.601005
            ],
            [
              -112.686825,
              45.569294
            ],
            [
              -112.694053,
              45.542756
            ],
            [
              -112.704848,
              45.533232
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "025",
      "COUNTYNS": "01688745",
      "AFFGEOID": "0500000US30025",
      "GEOID": "30025",
      "NAME": "Fallon",
      "LSAD": "06",
      "ALAND": 4197205007,
      "AWATER": 5915331,
      "County_state": "Fallon,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -104.905988,
              46.482251
            ],
            [
              -104.905313,
              46.313333
            ],
            [
              -104.885577,
              46.313346
            ],
            [
              -104.885495,
              46.136595
            ],
            [
              -104.516126,
              46.136196
            ],
            [
              -104.516078,
              46.054844
            ],
            [
              -104.390744,
              46.054826
            ],
            [
              -104.390876,
              45.970363
            ],
            [
              -104.328064,
              45.970697
            ],
            [
              -104.134842,
              45.968833
            ],
            [
              -104.134826,
              45.881928
            ],
            [
              -104.044030081588,
              45.881978657009796
            ],
            [
              -104.045443,
              45.94531
            ],
            [
              -104.046822,
              46.000199
            ],
            [
              -104.045759,
              46.123946
            ],
            [
              -104.045237,
              46.125002
            ],
            [
              -104.045417428123,
              46.280188073090294
            ],
            [
              -104.045469,
              46.324545
            ],
            [
              -104.045462,
              46.341895
            ],
            [
              -104.045481,
              46.366871
            ],
            [
              -104.046103,
              46.383916
            ],
            [
              -104.045045,
              46.509788
            ],
            [
              -104.045098456003,
              46.540929906304
            ],
            [
              -104.045271,
              46.641449
            ],
            [
              -104.04527115812101,
              46.6415014128297
            ],
            [
              -104.354835,
              46.641409
            ],
            [
              -104.354701,
              46.670381
            ],
            [
              -104.418001,
              46.670435
            ],
            [
              -104.417998,
              46.684892
            ],
            [
              -104.606903,
              46.684924
            ],
            [
              -104.606835,
              46.655968
            ],
            [
              -104.732843,
              46.656264
            ],
            [
              -104.733059,
              46.612695
            ],
            [
              -104.732672,
              46.482202
            ],
            [
              -104.905988,
              46.482251
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "011",
      "COUNTYNS": "01688746",
      "AFFGEOID": "0500000US30011",
      "GEOID": "30011",
      "NAME": "Carter",
      "LSAD": "06",
      "ALAND": 8651792773,
      "AWATER": 19503321,
      "County_state": "Carter,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -105.038205,
              45.352035
            ],
            [
              -105.038252994286,
              45.000344363702396
            ],
            [
              -105.025266,
              45.00029
            ],
            [
              -105.019284,
              45.000329
            ],
            [
              -105.01824,
              45.000437
            ],
            [
              -104.765063,
              44.999183
            ],
            [
              -104.759855,
              44.999066
            ],
            [
              -104.72637,
              44.999518
            ],
            [
              -104.665171,
              44.998618
            ],
            [
              -104.663882,
              44.998869
            ],
            [
              -104.470422,
              44.998453
            ],
            [
              -104.470117,
              44.998453
            ],
            [
              -104.250145,
              44.99822
            ],
            [
              -104.057698,
              44.997431
            ],
            [
              -104.05021,
              44.997571
            ],
            [
              -104.039138,
              44.99852
            ],
            [
              -104.039693,
              45.000552
            ],
            [
              -104.039563,
              45.124039
            ],
            [
              -104.039977,
              45.124988
            ],
            [
              -104.04013575649302,
              45.212890762041596
            ],
            [
              -104.040358,
              45.335946
            ],
            [
              -104.040265,
              45.345356
            ],
            [
              -104.040114,
              45.374214
            ],
            [
              -104.04041,
              45.393474
            ],
            [
              -104.040816,
              45.462708
            ],
            [
              -104.041764,
              45.490789
            ],
            [
              -104.041274,
              45.499994
            ],
            [
              -104.041145,
              45.503367
            ],
            [
              -104.041717,
              45.539122
            ],
            [
              -104.041647,
              45.550691
            ],
            [
              -104.041937,
              45.557915
            ],
            [
              -104.042597,
              45.749998
            ],
            [
              -104.043814,
              45.868385
            ],
            [
              -104.044009,
              45.871974
            ],
            [
              -104.04403,
              45.881975
            ],
            [
              -104.044030081588,
              45.881978657009796
            ],
            [
              -104.134826,
              45.881928
            ],
            [
              -104.134842,
              45.968833
            ],
            [
              -104.328064,
              45.970697
            ],
            [
              -104.390876,
              45.970363
            ],
            [
              -104.390744,
              46.054826
            ],
            [
              -104.516078,
              46.054844
            ],
            [
              -104.516126,
              46.136196
            ],
            [
              -104.885495,
              46.136595
            ],
            [
              -104.939205,
              46.136575
            ],
            [
              -104.939289,
              45.786964
            ],
            [
              -104.98194,
              45.786982
            ],
            [
              -104.987306,
              45.442759
            ],
            [
              -104.987318,
              45.35204
            ],
            [
              -105.038205,
              45.352035
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "041",
      "COUNTYNS": "01720048",
      "AFFGEOID": "0500000US30041",
      "GEOID": "30041",
      "NAME": "Hill",
      "LSAD": "06",
      "ALAND": 7508815856,
      "AWATER": 44701996,
      "County_state": "Hill,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -110.750113,
              48.566516
            ],
            [
              -110.755873,
              48.219601
            ],
            [
              -110.625543,
              48.219731
            ],
            [
              -110.625409,
              48.306479
            ],
            [
              -110.063116,
              48.306055
            ],
            [
              -110.014062,
              48.30605
            ],
            [
              -109.845932,
              48.305971
            ],
            [
              -109.845812,
              48.219004
            ],
            [
              -109.726943,
              48.221525
            ],
            [
              -109.726873,
              48.147279
            ],
            [
              -109.726914,
              48.132859
            ],
            [
              -109.59811,
              48.132495
            ],
            [
              -109.534295,
              48.134564
            ],
            [
              -109.533938,
              48.221317
            ],
            [
              -109.551758,
              48.221373
            ],
            [
              -109.551644,
              48.293479
            ],
            [
              -109.51908,
              48.293457
            ],
            [
              -109.51878,
              48.394726
            ],
            [
              -109.497028,
              48.394739
            ],
            [
              -109.49673,
              48.45273
            ],
            [
              -109.464318,
              48.452638
            ],
            [
              -109.464318,
              48.52526
            ],
            [
              -109.496881,
              48.525207
            ],
            [
              -109.49688,
              48.562796
            ],
            [
              -109.496882,
              48.567914
            ],
            [
              -109.505333,
              48.56791
            ],
            [
              -109.505514,
              48.913911
            ],
            [
              -109.489627,
              48.913968
            ],
            [
              -109.489554646273,
              49.0006056503142
            ],
            [
              -109.500737,
              49.00044
            ],
            [
              -109.995618,
              48.999642
            ],
            [
              -110.171595,
              48.999262
            ],
            [
              -110.215516,
              48.999197
            ],
            [
              -110.216135,
              48.999239
            ],
            [
              -110.438151,
              48.999188
            ],
            [
              -110.479148371759,
              48.99883796252389
            ],
            [
              -110.530998414874,
              48.998395264432595
            ],
            [
              -110.531615,
              48.99839
            ],
            [
              -110.592465,
              48.999012
            ],
            [
              -110.743064,
              48.998557502115595
            ],
            [
              -110.742698,
              48.914089
            ],
            [
              -110.750113,
              48.566516
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "027",
      "COUNTYNS": "01719614",
      "AFFGEOID": "0500000US30027",
      "GEOID": "30027",
      "NAME": "Fergus",
      "LSAD": "06",
      "ALAND": 11238811043,
      "AWATER": 27700320,
      "County_state": "Fergus,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -110.213596,
              47.416657
            ],
            [
              -110.213285,
              47.358771
            ],
            [
              -110.127952,
              47.35902
            ],
            [
              -110.127699,
              47.27191
            ],
            [
              -110.000289,
              47.272133
            ],
            [
              -110.000024,
              47.228716
            ],
            [
              -109.872475,
              47.228883
            ],
            [
              -109.871985,
              47.185323
            ],
            [
              -109.756568,
              47.185694
            ],
            [
              -109.755423,
              46.896023
            ],
            [
              -109.776507,
              46.896005
            ],
            [
              -109.776408,
              46.881539
            ],
            [
              -109.792249,
              46.881524
            ],
            [
              -109.797469,
              46.867038
            ],
            [
              -109.798833,
              46.830933
            ],
            [
              -109.835569,
              46.830865
            ],
            [
              -109.835424,
              46.809161
            ],
            [
              -109.830132,
              46.801932
            ],
            [
              -109.798462,
              46.801972
            ],
            [
              -109.787763,
              46.794745
            ],
            [
              -109.787614,
              46.780261
            ],
            [
              -109.756666,
              46.780389
            ],
            [
              -109.756379,
              46.744238
            ],
            [
              -109.745815,
              46.744327
            ],
            [
              -109.745745,
              46.694097
            ],
            [
              -109.745745,
              46.693593
            ],
            [
              -109.389163,
              46.693625
            ],
            [
              -109.389092,
              46.693625
            ],
            [
              -109.389208,
              46.751351
            ],
            [
              -109.00957,
              46.750702
            ],
            [
              -108.631462,
              46.749392
            ],
            [
              -108.630689,
              46.836807
            ],
            [
              -108.611116,
              46.836776
            ],
            [
              -108.608954,
              47.098126
            ],
            [
              -108.736838,
              47.098218
            ],
            [
              -108.736077,
              47.183414
            ],
            [
              -108.719283,
              47.183358
            ],
            [
              -108.719189,
              47.270342
            ],
            [
              -108.591111,
              47.270135
            ],
            [
              -108.590835,
              47.306516
            ],
            [
              -108.464255,
              47.306838
            ],
            [
              -108.3368,
              47.307932
            ],
            [
              -108.315481,
              47.315152
            ],
            [
              -108.31549,
              47.531071
            ],
            [
              -108.313048,
              47.58365
            ],
            [
              -108.332682,
              47.580277
            ],
            [
              -108.335819,
              47.5674
            ],
            [
              -108.350385,
              47.567444
            ],
            [
              -108.356267,
              47.580207
            ],
            [
              -108.377853,
              47.577607
            ],
            [
              -108.386342,
              47.592007
            ],
            [
              -108.404464,
              47.595058
            ],
            [
              -108.412458,
              47.5849
            ],
            [
              -108.428956,
              47.588458
            ],
            [
              -108.425143,
              47.604153
            ],
            [
              -108.435911,
              47.601227
            ],
            [
              -108.442461,
              47.61477
            ],
            [
              -108.456908,
              47.615726
            ],
            [
              -108.474541,
              47.598274
            ],
            [
              -108.509227,
              47.601199
            ],
            [
              -108.507494,
              47.609147
            ],
            [
              -108.526067,
              47.620824
            ],
            [
              -108.538516,
              47.61394
            ],
            [
              -108.572422,
              47.609483
            ],
            [
              -108.610972,
              47.624329
            ],
            [
              -108.631376,
              47.619525
            ],
            [
              -108.6714,
              47.619556
            ],
            [
              -108.6883,
              47.631485
            ],
            [
              -108.709856,
              47.632769
            ],
            [
              -108.728836,
              47.649826
            ],
            [
              -108.755437,
              47.643842
            ],
            [
              -108.771619,
              47.645523
            ],
            [
              -108.78982,
              47.662943
            ],
            [
              -108.78406,
              47.682104
            ],
            [
              -108.823987,
              47.710251
            ],
            [
              -108.891115,
              47.734456
            ],
            [
              -108.919407,
              47.735396
            ],
            [
              -108.943659,
              47.729125
            ],
            [
              -108.950006,
              47.736901
            ],
            [
              -108.920422,
              47.756494
            ],
            [
              -108.945661,
              47.772897
            ],
            [
              -108.942745,
              47.791824
            ],
            [
              -108.959958,
              47.792624
            ],
            [
              -108.978383,
              47.802658
            ],
            [
              -109.000719,
              47.803119
            ],
            [
              -109.030258,
              47.792451
            ],
            [
              -109.0659,
              47.801067
            ],
            [
              -109.132768,
              47.781122
            ],
            [
              -109.160341,
              47.77981
            ],
            [
              -109.190276,
              47.790903
            ],
            [
              -109.229905,
              47.794923
            ],
            [
              -109.274171,
              47.793239
            ],
            [
              -109.281933,
              47.776942
            ],
            [
              -109.301081,
              47.770263
            ],
            [
              -109.312838,
              47.758383
            ],
            [
              -109.349699,
              47.750787
            ],
            [
              -109.425315,
              47.720153
            ],
            [
              -109.436685,
              47.725727
            ],
            [
              -109.464006,
              47.725093
            ],
            [
              -109.487237,
              47.730941
            ],
            [
              -109.510907,
              47.74451
            ],
            [
              -109.538677,
              47.738864
            ],
            [
              -109.579712,
              47.7478
            ],
            [
              -109.606785,
              47.732575
            ],
            [
              -109.633538,
              47.739449
            ],
            [
              -109.677536,
              47.734749
            ],
            [
              -109.722711,
              47.702621
            ],
            [
              -109.755126,
              47.703281
            ],
            [
              -109.836861,
              47.712041
            ],
            [
              -109.840826,
              47.691305
            ],
            [
              -109.831519,
              47.648816
            ],
            [
              -109.836491,
              47.625251
            ],
            [
              -109.848407,
              47.605439
            ],
            [
              -109.843075,
              47.576945
            ],
            [
              -109.850381,
              47.564907
            ],
            [
              -109.849102,
              47.540357
            ],
            [
              -109.871281,
              47.524599
            ],
            [
              -109.915022,
              47.52144
            ],
            [
              -109.974818,
              47.491097
            ],
            [
              -110.002765,
              47.475558
            ],
            [
              -110.030854,
              47.479551
            ],
            [
              -110.078918,
              47.45524
            ],
            [
              -110.079185,
              47.448489
            ],
            [
              -110.147246,
              47.42854
            ],
            [
              -110.159579,
              47.415312
            ],
            [
              -110.213596,
              47.416657
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "015",
      "COUNTYNS": "01719619",
      "AFFGEOID": "0500000US30015",
      "GEOID": "30015",
      "NAME": "Chouteau",
      "LSAD": "06",
      "ALAND": 10288824961,
      "AWATER": 62565296,
      "County_state": "Chouteau,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -111.409097,
              48.132218
            ],
            [
              -111.408514,
              47.987178
            ],
            [
              -111.40885,
              47.698123
            ],
            [
              -110.976171,
              47.698738
            ],
            [
              -110.983667,
              47.681897
            ],
            [
              -110.994822,
              47.679218
            ],
            [
              -111.005448,
              47.65864
            ],
            [
              -111.016482,
              47.655018
            ],
            [
              -111.037007,
              47.626398
            ],
            [
              -111.049847,
              47.598333
            ],
            [
              -111.030432,
              47.58233
            ],
            [
              -111.002503,
              47.5724
            ],
            [
              -110.969772,
              47.571639
            ],
            [
              -110.965297,
              47.563821
            ],
            [
              -110.937684,
              47.547337
            ],
            [
              -110.907845,
              47.542271
            ],
            [
              -110.890253,
              47.552288
            ],
            [
              -110.879804,
              47.547275
            ],
            [
              -110.863942,
              47.52506
            ],
            [
              -110.768465,
              47.525638
            ],
            [
              -110.769129,
              47.446096
            ],
            [
              -110.640599,
              47.446306
            ],
            [
              -110.639559,
              47.416486
            ],
            [
              -110.213596,
              47.416657
            ],
            [
              -110.159579,
              47.415312
            ],
            [
              -110.147246,
              47.42854
            ],
            [
              -110.079185,
              47.448489
            ],
            [
              -110.078918,
              47.45524
            ],
            [
              -110.030854,
              47.479551
            ],
            [
              -110.002765,
              47.475558
            ],
            [
              -109.974818,
              47.491097
            ],
            [
              -109.915022,
              47.52144
            ],
            [
              -109.871281,
              47.524599
            ],
            [
              -109.849102,
              47.540357
            ],
            [
              -109.850381,
              47.564907
            ],
            [
              -109.843075,
              47.576945
            ],
            [
              -109.848407,
              47.605439
            ],
            [
              -109.836491,
              47.625251
            ],
            [
              -109.831519,
              47.648816
            ],
            [
              -109.840826,
              47.691305
            ],
            [
              -109.836861,
              47.712041
            ],
            [
              -109.755126,
              47.703281
            ],
            [
              -109.722711,
              47.702621
            ],
            [
              -109.677536,
              47.734749
            ],
            [
              -109.633538,
              47.739449
            ],
            [
              -109.606785,
              47.732575
            ],
            [
              -109.579712,
              47.7478
            ],
            [
              -109.538677,
              47.738864
            ],
            [
              -109.538663,
              47.871021
            ],
            [
              -109.534295,
              48.134564
            ],
            [
              -109.59811,
              48.132495
            ],
            [
              -109.726914,
              48.132859
            ],
            [
              -109.726873,
              48.147279
            ],
            [
              -109.726943,
              48.221525
            ],
            [
              -109.845812,
              48.219004
            ],
            [
              -109.845932,
              48.305971
            ],
            [
              -110.014062,
              48.30605
            ],
            [
              -110.063116,
              48.306055
            ],
            [
              -110.625409,
              48.306479
            ],
            [
              -110.625543,
              48.219731
            ],
            [
              -110.755873,
              48.219601
            ],
            [
              -110.827413,
              48.219566
            ],
            [
              -110.827168,
              48.133205
            ],
            [
              -111.409097,
              48.132218
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "055",
      "COUNTYNS": "01716270",
      "AFFGEOID": "0500000US30055",
      "GEOID": "30055",
      "NAME": "McCone",
      "LSAD": "06",
      "ALAND": 6843265262,
      "AWATER": 103091384,
      "County_state": "McCone,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -106.417795,
              48.04292
            ],
            [
              -106.42987,
              48.018282
            ],
            [
              -106.414291,
              47.972816
            ],
            [
              -106.41862,
              47.957457
            ],
            [
              -106.358288,
              47.957216
            ],
            [
              -106.358085,
              47.868412
            ],
            [
              -106.260932,
              47.868431
            ],
            [
              -106.261121,
              47.529052
            ],
            [
              -106.298073,
              47.529041
            ],
            [
              -106.298158,
              47.354298
            ],
            [
              -106.170675,
              47.354495
            ],
            [
              -106.17042,
              47.180826
            ],
            [
              -106.086777,
              47.180938
            ],
            [
              -105.959819,
              47.181
            ],
            [
              -105.959897,
              47.092968
            ],
            [
              -105.832637,
              47.093109
            ],
            [
              -105.833044,
              47.181092
            ],
            [
              -105.406309,
              47.181583
            ],
            [
              -105.406581,
              47.529534
            ],
            [
              -105.363507,
              47.529558
            ],
            [
              -105.363262,
              47.790419
            ],
            [
              -105.234771,
              47.790502
            ],
            [
              -105.234651,
              47.868057
            ],
            [
              -105.195122,
              47.868081
            ],
            [
              -105.194783,
              48.066617
            ],
            [
              -105.217593,
              48.063749
            ],
            [
              -105.218934,
              48.081464
            ],
            [
              -105.22899,
              48.089284
            ],
            [
              -105.262551,
              48.089062
            ],
            [
              -105.317563,
              48.079016
            ],
            [
              -105.331305,
              48.092638
            ],
            [
              -105.364528,
              48.088752
            ],
            [
              -105.383523,
              48.092925
            ],
            [
              -105.453298,
              48.089386
            ],
            [
              -105.484563,
              48.090897
            ],
            [
              -105.503195,
              48.097792
            ],
            [
              -105.51858,
              48.088258
            ],
            [
              -105.531131,
              48.068027
            ],
            [
              -105.568481,
              48.08355
            ],
            [
              -105.595027,
              48.08178
            ],
            [
              -105.617496,
              48.073268
            ],
            [
              -105.647941,
              48.077304
            ],
            [
              -105.639652,
              48.066001
            ],
            [
              -105.643437,
              48.051097
            ],
            [
              -105.661286,
              48.045752
            ],
            [
              -105.666202,
              48.057048
            ],
            [
              -105.694541,
              48.053536
            ],
            [
              -105.720805,
              48.03195
            ],
            [
              -105.750561,
              48.025397
            ],
            [
              -105.765447,
              48.03063
            ],
            [
              -105.7778,
              48.047159
            ],
            [
              -105.786283,
              48.022558
            ],
            [
              -105.80888,
              48.024732
            ],
            [
              -105.843891,
              48.010289
            ],
            [
              -105.877243,
              48.021441
            ],
            [
              -105.884527,
              48.034202
            ],
            [
              -105.899279,
              48.034219
            ],
            [
              -105.896486,
              48.014355
            ],
            [
              -105.919548,
              48.003898
            ],
            [
              -105.935226,
              48.013338
            ],
            [
              -105.974602,
              48.015859
            ],
            [
              -106.006176,
              48.033689
            ],
            [
              -106.025102,
              48.026087
            ],
            [
              -106.012049,
              48.016174
            ],
            [
              -106.0144,
              48.006484
            ],
            [
              -106.044121,
              48.008469
            ],
            [
              -106.066238,
              48.032845
            ],
            [
              -106.085739,
              48.034706
            ],
            [
              -106.095538,
              48.010575
            ],
            [
              -106.122377,
              48.004274
            ],
            [
              -106.144765,
              48.007104
            ],
            [
              -106.128036,
              48.01879
            ],
            [
              -106.125577,
              48.030587
            ],
            [
              -106.229302,
              48.026416
            ],
            [
              -106.2448,
              48.029943
            ],
            [
              -106.275605,
              48.046578
            ],
            [
              -106.300974,
              48.041376
            ],
            [
              -106.321439,
              48.05544
            ],
            [
              -106.338655,
              48.046077
            ],
            [
              -106.364455,
              48.046828
            ],
            [
              -106.373618,
              48.062353
            ],
            [
              -106.400605,
              48.061921
            ],
            [
              -106.417795,
              48.04292
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "105",
      "COUNTYNS": "01678923",
      "AFFGEOID": "0500000US30105",
      "GEOID": "30105",
      "NAME": "Valley",
      "LSAD": "06",
      "ALAND": 12758538566,
      "AWATER": 351470610,
      "County_state": "Valley,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -107.4144,
              47.870513
            ],
            [
              -107.414886,
              47.691734
            ],
            [
              -107.360231,
              47.691218
            ],
            [
              -107.331757,
              47.680685
            ],
            [
              -107.284283,
              47.680446
            ],
            [
              -107.249,
              47.661566
            ],
            [
              -107.175088,
              47.66191
            ],
            [
              -107.158817,
              47.667788
            ],
            [
              -107.114109,
              47.669026
            ],
            [
              -107.070514,
              47.662396
            ],
            [
              -107.011358,
              47.663111
            ],
            [
              -106.999227,
              47.67366
            ],
            [
              -106.960755,
              47.672158
            ],
            [
              -106.94455,
              47.676289
            ],
            [
              -106.925557,
              47.66947
            ],
            [
              -106.892753,
              47.674818
            ],
            [
              -106.881162,
              47.678128
            ],
            [
              -106.86991,
              47.702268
            ],
            [
              -106.879223,
              47.718095
            ],
            [
              -106.861668,
              47.737372
            ],
            [
              -106.834171,
              47.743398
            ],
            [
              -106.7857,
              47.742507
            ],
            [
              -106.760188,
              47.731453
            ],
            [
              -106.734453,
              47.734156
            ],
            [
              -106.711442,
              47.755243
            ],
            [
              -106.711969,
              47.772113
            ],
            [
              -106.677435,
              47.775913
            ],
            [
              -106.661761,
              47.771737
            ],
            [
              -106.653028,
              47.790264
            ],
            [
              -106.621789,
              47.790855
            ],
            [
              -106.609813,
              47.777234
            ],
            [
              -106.603126,
              47.784743
            ],
            [
              -106.610188,
              47.804905
            ],
            [
              -106.571003,
              47.812933
            ],
            [
              -106.557488,
              47.828581
            ],
            [
              -106.56322,
              47.840718
            ],
            [
              -106.529265,
              47.850023
            ],
            [
              -106.527141,
              47.860976
            ],
            [
              -106.543318,
              47.874915
            ],
            [
              -106.530671,
              47.880821
            ],
            [
              -106.510321,
              47.878996
            ],
            [
              -106.510062,
              47.891155
            ],
            [
              -106.53081,
              47.903562
            ],
            [
              -106.498523,
              47.914524
            ],
            [
              -106.4887,
              47.932577
            ],
            [
              -106.508854,
              47.946536
            ],
            [
              -106.501725,
              47.958287
            ],
            [
              -106.452414,
              47.970419
            ],
            [
              -106.447421,
              47.98286
            ],
            [
              -106.428958,
              47.976176
            ],
            [
              -106.433499,
              47.964883
            ],
            [
              -106.41862,
              47.957457
            ],
            [
              -106.414291,
              47.972816
            ],
            [
              -106.42987,
              48.018282
            ],
            [
              -106.417795,
              48.04292
            ],
            [
              -106.400605,
              48.061921
            ],
            [
              -106.373618,
              48.062353
            ],
            [
              -106.364455,
              48.046828
            ],
            [
              -106.338655,
              48.046077
            ],
            [
              -106.321439,
              48.05544
            ],
            [
              -106.300974,
              48.041376
            ],
            [
              -106.275605,
              48.046578
            ],
            [
              -106.2448,
              48.029943
            ],
            [
              -106.229302,
              48.026416
            ],
            [
              -106.125577,
              48.030587
            ],
            [
              -106.128036,
              48.01879
            ],
            [
              -106.144765,
              48.007104
            ],
            [
              -106.122377,
              48.004274
            ],
            [
              -106.095538,
              48.010575
            ],
            [
              -106.085739,
              48.034706
            ],
            [
              -106.066238,
              48.032845
            ],
            [
              -106.044121,
              48.008469
            ],
            [
              -106.0144,
              48.006484
            ],
            [
              -106.012049,
              48.016174
            ],
            [
              -106.025102,
              48.026087
            ],
            [
              -106.006176,
              48.033689
            ],
            [
              -105.974602,
              48.015859
            ],
            [
              -105.935226,
              48.013338
            ],
            [
              -105.919548,
              48.003898
            ],
            [
              -105.896486,
              48.014355
            ],
            [
              -105.899279,
              48.034219
            ],
            [
              -105.884527,
              48.034202
            ],
            [
              -105.877243,
              48.021441
            ],
            [
              -105.843891,
              48.010289
            ],
            [
              -105.843824,
              48.182762
            ],
            [
              -105.843823,
              48.219034
            ],
            [
              -105.804599,
              48.219037
            ],
            [
              -105.804436,
              48.563358
            ],
            [
              -106.02131,
              48.563424
            ],
            [
              -106.021251,
              48.823975
            ],
            [
              -106.151908,
              48.823927
            ],
            [
              -106.15193,
              48.910637
            ],
            [
              -106.112003,
              48.910647
            ],
            [
              -106.11206332368401,
              48.99927943840029
            ],
            [
              -106.233987,
              48.999423
            ],
            [
              -106.243154,
              48.999373
            ],
            [
              -106.24621,
              48.999258
            ],
            [
              -106.274267,
              48.999312
            ],
            [
              -106.368151,
              48.999503
            ],
            [
              -106.374616,
              48.999617
            ],
            [
              -106.377887005339,
              48.999609367145396
            ],
            [
              -106.479609,
              48.999372
            ],
            [
              -106.500592,
              48.999443
            ],
            [
              -106.500592,
              48.999756
            ],
            [
              -106.518201,
              48.999564
            ],
            [
              -106.617539,
              48.999583
            ],
            [
              -106.625597,
              48.99964
            ],
            [
              -107.179812011685,
              48.9999246229794
            ],
            [
              -107.179823,
              48.912668
            ],
            [
              -107.206032,
              48.91271
            ],
            [
              -107.206316,
              48.56817
            ],
            [
              -107.192709,
              48.568113
            ],
            [
              -107.192691,
              48.509402
            ],
            [
              -107.226312,
              48.509712
            ],
            [
              -107.251912,
              48.516932
            ],
            [
              -107.257944,
              48.480989
            ],
            [
              -107.301721,
              48.480943
            ],
            [
              -107.301455,
              48.44003
            ],
            [
              -107.301073,
              48.394
            ],
            [
              -107.260279,
              48.39408
            ],
            [
              -107.260458,
              48.304768
            ],
            [
              -107.369252,
              48.305163
            ],
            [
              -107.36973,
              48.218373
            ],
            [
              -107.404513,
              48.21844
            ],
            [
              -107.404676,
              47.870509
            ],
            [
              -107.4144,
              47.870513
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "103",
      "COUNTYNS": "01719563",
      "AFFGEOID": "0500000US30103",
      "GEOID": "30103",
      "NAME": "Treasure",
      "LSAD": "06",
      "ALAND": 2532492080,
      "AWATER": 17149961,
      "County_state": "Treasure,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -107.738548,
              46.481806
            ],
            [
              -107.738432,
              46.467556
            ],
            [
              -107.717512,
              46.467508
            ],
            [
              -107.717289,
              46.438507
            ],
            [
              -107.696323,
              46.438477
            ],
            [
              -107.6961,
              46.409482
            ],
            [
              -107.675188,
              46.409433
            ],
            [
              -107.675069,
              46.394936
            ],
            [
              -107.654159,
              46.394888
            ],
            [
              -107.654153,
              46.365951
            ],
            [
              -107.633378,
              46.365993
            ],
            [
              -107.633363,
              46.351525
            ],
            [
              -107.612411,
              46.351557
            ],
            [
              -107.612395,
              46.308113
            ],
            [
              -107.591438,
              46.308156
            ],
            [
              -107.591422,
              46.29365
            ],
            [
              -107.570495,
              46.293652
            ],
            [
              -107.570468,
              46.264705
            ],
            [
              -107.549552,
              46.264717
            ],
            [
              -107.549555,
              46.250222
            ],
            [
              -107.528646,
              46.250245
            ],
            [
              -107.528695,
              46.221227
            ],
            [
              -107.507959,
              46.221294
            ],
            [
              -107.507925,
              46.192283
            ],
            [
              -107.487022,
              46.192296
            ],
            [
              -107.487115,
              46.177794
            ],
            [
              -107.466131,
              46.177774
            ],
            [
              -107.474342,
              46.150838
            ],
            [
              -107.462965,
              46.139913
            ],
            [
              -107.483975,
              46.122842
            ],
            [
              -107.473383,
              46.107717
            ],
            [
              -107.49814,
              46.087872
            ],
            [
              -107.500627,
              46.07132
            ],
            [
              -107.517447,
              46.059519
            ],
            [
              -107.511534,
              46.043403
            ],
            [
              -107.424929,
              46.043374
            ],
            [
              -107.425128,
              45.956674
            ],
            [
              -107.176358,
              45.956761
            ],
            [
              -107.17636,
              45.913323
            ],
            [
              -107.051946,
              45.913489
            ],
            [
              -107.052159,
              45.870052
            ],
            [
              -106.939095,
              45.870041
            ],
            [
              -106.939097,
              45.879194
            ],
            [
              -106.939067,
              46.136667
            ],
            [
              -107.007394,
              46.134198
            ],
            [
              -107.007394,
              46.305902
            ],
            [
              -107.028538,
              46.306639
            ],
            [
              -107.028422,
              46.39546
            ],
            [
              -107.153444,
              46.395613
            ],
            [
              -107.153515,
              46.468101
            ],
            [
              -107.174435,
              46.468176
            ],
            [
              -107.174531,
              46.482713
            ],
            [
              -107.738548,
              46.481806
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "095",
      "COUNTYNS": "01719572",
      "AFFGEOID": "0500000US30095",
      "GEOID": "30095",
      "NAME": "Stillwater",
      "LSAD": "06",
      "ALAND": 4653551003,
      "AWATER": 23732519,
      "County_state": "Stillwater,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -110.064581,
              45.348755
            ],
            [
              -110.064518,
              45.172129
            ],
            [
              -109.798666,
              45.172128
            ],
            [
              -109.798673,
              45.167339
            ],
            [
              -109.687792,
              45.167339
            ],
            [
              -109.687857,
              45.26144
            ],
            [
              -109.608504,
              45.261445
            ],
            [
              -109.608831,
              45.304832
            ],
            [
              -109.588185,
              45.304874
            ],
            [
              -109.588222,
              45.333905
            ],
            [
              -109.567657,
              45.333898
            ],
            [
              -109.561924,
              45.362843
            ],
            [
              -109.541381,
              45.362873
            ],
            [
              -109.54139,
              45.377353
            ],
            [
              -109.520873,
              45.377362
            ],
            [
              -109.520884,
              45.391852
            ],
            [
              -109.500319,
              45.391845
            ],
            [
              -109.500318,
              45.406327
            ],
            [
              -109.479707,
              45.40636
            ],
            [
              -109.479697,
              45.420834
            ],
            [
              -109.438524,
              45.420771
            ],
            [
              -109.438586,
              45.435272
            ],
            [
              -109.41831,
              45.435298
            ],
            [
              -109.418352,
              45.449762
            ],
            [
              -109.356454,
              45.449843
            ],
            [
              -109.356417,
              45.464326
            ],
            [
              -109.191761,
              45.464504
            ],
            [
              -109.191551,
              45.523095
            ],
            [
              -109.128225,
              45.523025
            ],
            [
              -109.127757,
              45.587567
            ],
            [
              -109.068498,
              45.583241
            ],
            [
              -109.03975,
              45.590937
            ],
            [
              -109.028807,
              45.601347
            ],
            [
              -108.97281,
              45.616554
            ],
            [
              -108.933844,
              45.602855
            ],
            [
              -108.89137,
              45.602532
            ],
            [
              -108.860998,
              45.613514
            ],
            [
              -108.843016,
              45.611042
            ],
            [
              -108.843145,
              45.639458
            ],
            [
              -108.863707,
              45.639422
            ],
            [
              -108.884437,
              45.661687
            ],
            [
              -108.884297,
              45.682936
            ],
            [
              -108.904726,
              45.682821
            ],
            [
              -108.901927,
              45.784264
            ],
            [
              -108.901655,
              45.959416
            ],
            [
              -108.922324,
              45.959444
            ],
            [
              -108.925372,
              46.031936
            ],
            [
              -108.924589,
              46.13237
            ],
            [
              -109.416227,
              46.132371
            ],
            [
              -109.417158,
              46.044759
            ],
            [
              -109.423011,
              46.033019
            ],
            [
              -109.423026,
              45.960011
            ],
            [
              -109.506604,
              45.959112
            ],
            [
              -109.505963,
              45.870218
            ],
            [
              -109.548822,
              45.870749
            ],
            [
              -109.546551,
              45.783475
            ],
            [
              -109.563907,
              45.783471
            ],
            [
              -109.56207,
              45.609119
            ],
            [
              -109.685385,
              45.609022
            ],
            [
              -109.685385,
              45.56561
            ],
            [
              -109.808793,
              45.565925
            ],
            [
              -109.808841,
              45.52247
            ],
            [
              -109.931139,
              45.522457
            ],
            [
              -109.931984,
              45.348755
            ],
            [
              -110.064581,
              45.348755
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "047",
      "COUNTYNS": "01796943",
      "AFFGEOID": "0500000US30047",
      "GEOID": "30047",
      "NAME": "Lake",
      "LSAD": "06",
      "ALAND": 3860298044,
      "AWATER": 423538790,
      "County_state": "Lake,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -114.604462,
              47.789871
            ],
            [
              -114.604857,
              47.746421
            ],
            [
              -114.584454,
              47.746355
            ],
            [
              -114.58376,
              47.601718
            ],
            [
              -114.350364,
              47.602142
            ],
            [
              -114.340833,
              47.58934
            ],
            [
              -114.322124,
              47.595951
            ],
            [
              -114.339571,
              47.585027
            ],
            [
              -114.339166,
              47.569356
            ],
            [
              -114.329114,
              47.557493
            ],
            [
              -114.362605,
              47.541157
            ],
            [
              -114.364242,
              47.520819
            ],
            [
              -114.383453,
              47.483254
            ],
            [
              -114.3703,
              47.481102
            ],
            [
              -114.350356,
              47.496695
            ],
            [
              -114.298654,
              47.496084
            ],
            [
              -114.28418,
              47.468496
            ],
            [
              -114.304732,
              47.462108
            ],
            [
              -114.326169,
              47.467144
            ],
            [
              -114.326635,
              47.451859
            ],
            [
              -114.340725,
              47.440549
            ],
            [
              -114.343046,
              47.407803
            ],
            [
              -114.35308,
              47.38985
            ],
            [
              -114.324081,
              47.365058
            ],
            [
              -114.325392,
              47.35644
            ],
            [
              -114.305213,
              47.362521
            ],
            [
              -114.288557,
              47.355875
            ],
            [
              -114.203026,
              47.355746
            ],
            [
              -114.203119,
              47.181908
            ],
            [
              -114.187062,
              47.181889
            ],
            [
              -114.18717,
              47.138191
            ],
            [
              -113.933503,
              47.138289
            ],
            [
              -113.933568,
              47.18172
            ],
            [
              -113.821447,
              47.181709
            ],
            [
              -113.824339,
              47.268684
            ],
            [
              -113.824348,
              47.274413
            ],
            [
              -113.843538,
              47.272055
            ],
            [
              -113.855385,
              47.285002
            ],
            [
              -113.870769,
              47.288158
            ],
            [
              -113.882738,
              47.308073
            ],
            [
              -113.900685,
              47.311317
            ],
            [
              -113.887021,
              47.317872
            ],
            [
              -113.881838,
              47.338524
            ],
            [
              -113.889045,
              47.355012
            ],
            [
              -113.877248,
              47.370108
            ],
            [
              -113.87111,
              47.39197
            ],
            [
              -113.891339,
              47.416784
            ],
            [
              -113.890834,
              47.431689
            ],
            [
              -113.903678,
              47.447615
            ],
            [
              -113.932412,
              47.4576
            ],
            [
              -113.928775,
              47.465901
            ],
            [
              -113.95118,
              47.475994
            ],
            [
              -113.935457,
              47.498007
            ],
            [
              -113.91422,
              47.500954
            ],
            [
              -113.908581,
              47.514436
            ],
            [
              -113.927674,
              47.520571
            ],
            [
              -113.929462,
              47.539529
            ],
            [
              -113.9195,
              47.549411
            ],
            [
              -113.932258,
              47.577161
            ],
            [
              -113.930289,
              47.598499
            ],
            [
              -113.948844,
              47.599993
            ],
            [
              -113.634079,
              47.600027
            ],
            [
              -113.625087,
              47.622019
            ],
            [
              -113.622663,
              47.658657
            ],
            [
              -113.605954,
              47.663181
            ],
            [
              -113.598647,
              47.697463
            ],
            [
              -113.603924,
              47.72144
            ],
            [
              -113.640588,
              47.719998
            ],
            [
              -113.659492,
              47.735306
            ],
            [
              -113.664021,
              47.746003
            ],
            [
              -113.649744,
              47.755847
            ],
            [
              -113.652681,
              47.773809
            ],
            [
              -113.670642,
              47.78315
            ],
            [
              -113.6817,
              47.805153
            ],
            [
              -113.66959,
              47.812324
            ],
            [
              -113.666201,
              47.84065
            ],
            [
              -113.675251,
              47.865571
            ],
            [
              -113.665292,
              47.882898
            ],
            [
              -113.672014,
              47.896349
            ],
            [
              -113.69873,
              47.916746
            ],
            [
              -113.718499,
              47.916726
            ],
            [
              -113.744272,
              47.950447
            ],
            [
              -113.747947,
              47.953025
            ],
            [
              -113.752767,
              47.951183
            ],
            [
              -113.762387,
              47.951789
            ],
            [
              -113.770362,
              47.947664
            ],
            [
              -113.774103,
              47.950707
            ],
            [
              -113.772336,
              47.957966
            ],
            [
              -113.803682,
              47.980212
            ],
            [
              -113.842199,
              47.978242
            ],
            [
              -113.865544,
              48.013908
            ],
            [
              -113.872342,
              48.042841
            ],
            [
              -113.884541,
              48.0519
            ],
            [
              -114.029042,
              48.051653
            ],
            [
              -114.028326,
              48.037343
            ],
            [
              -114.049903,
              48.037342
            ],
            [
              -114.050243,
              48.022994
            ],
            [
              -114.078282,
              48.023148
            ],
            [
              -114.061661,
              47.990088
            ],
            [
              -114.057877,
              48.000049
            ],
            [
              -114.044143,
              47.996603
            ],
            [
              -114.038545,
              47.96255
            ],
            [
              -114.110078,
              47.962542
            ],
            [
              -114.368104,
              47.962026
            ],
            [
              -114.49219,
              47.961989
            ],
            [
              -114.492147,
              47.875414
            ],
            [
              -114.477451,
              47.875401
            ],
            [
              -114.47729,
              47.789941
            ],
            [
              -114.604462,
              47.789871
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "063",
      "COUNTYNS": "01719596",
      "AFFGEOID": "0500000US30063",
      "GEOID": "30063",
      "NAME": "Missoula",
      "LSAD": "06",
      "ALAND": 6715966294,
      "AWATER": 64482586,
      "County_state": "Missoula,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -114.797204,
              47.268916
            ],
            [
              -114.797135,
              47.240033
            ],
            [
              -114.775846,
              47.240053
            ],
            [
              -114.775833,
              47.225573
            ],
            [
              -114.712274,
              47.22559
            ],
            [
              -114.712283,
              47.182187
            ],
            [
              -114.691364,
              47.18227
            ],
            [
              -114.691397,
              47.167201
            ],
            [
              -114.650226,
              47.167163
            ],
            [
              -114.65047,
              47.138244
            ],
            [
              -114.629151,
              47.138255
            ],
            [
              -114.629214,
              47.123721
            ],
            [
              -114.586735,
              47.123757
            ],
            [
              -114.586671,
              47.094853
            ],
            [
              -114.565653,
              47.09485
            ],
            [
              -114.566094,
              47.06738
            ],
            [
              -114.483694,
              47.067508
            ],
            [
              -114.483227,
              47.023358
            ],
            [
              -114.419532,
              47.023246
            ],
            [
              -114.419374,
              47.008896
            ],
            [
              -114.451356,
              47.0089
            ],
            [
              -114.451265,
              46.993201
            ],
            [
              -114.486134,
              46.999197
            ],
            [
              -114.502648,
              47.009137
            ],
            [
              -114.525358,
              47.00925
            ],
            [
              -114.52533,
              46.992328
            ],
            [
              -114.547172,
              46.992439
            ],
            [
              -114.546445,
              46.963371
            ],
            [
              -114.566129,
              46.963405
            ],
            [
              -114.566259,
              46.833557
            ],
            [
              -114.549004,
              46.833626
            ],
            [
              -114.549127,
              46.747366
            ],
            [
              -114.674925,
              46.747414
            ],
            [
              -114.674941612313,
              46.737042975581495
            ],
            [
              -114.649388,
              46.73289
            ],
            [
              -114.632954,
              46.715495
            ],
            [
              -114.626695,
              46.712889
            ],
            [
              -114.620859,
              46.707415
            ],
            [
              -114.623198,
              46.691511
            ],
            [
              -114.631898,
              46.68397
            ],
            [
              -114.641745,
              46.679286
            ],
            [
              -114.642713,
              46.673145
            ],
            [
              -114.635713,
              46.659375
            ],
            [
              -114.621483,
              46.658143
            ],
            [
              -114.614716,
              46.655256
            ],
            [
              -114.611676,
              46.647704
            ],
            [
              -114.616354,
              46.643646
            ],
            [
              -114.615036,
              46.639733
            ],
            [
              -114.595008042198,
              46.63339136601049
            ],
            [
              -114.593292,
              46.632848
            ],
            [
              -114.583385,
              46.633227
            ],
            [
              -114.561582,
              46.642043
            ],
            [
              -114.547321,
              46.644485
            ],
            [
              -114.498007,
              46.637655
            ],
            [
              -114.486218,
              46.632829
            ],
            [
              -114.466902,
              46.631695
            ],
            [
              -114.45425,
              46.640974
            ],
            [
              -114.453239,
              46.649266
            ],
            [
              -114.424424,
              46.660648
            ],
            [
              -114.410907,
              46.657466
            ],
            [
              -114.403383,
              46.659633
            ],
            [
              -114.394514,
              46.664846
            ],
            [
              -114.360709,
              46.669059
            ],
            [
              -114.332887,
              46.660756
            ],
            [
              -114.33268369823399,
              46.660580775216395
            ],
            [
              -114.170755,
              46.660389
            ],
            [
              -114.157847,
              46.660923
            ],
            [
              -113.827743,
              46.660896
            ],
            [
              -113.793557,
              46.658166
            ],
            [
              -113.667996,
              46.658372
            ],
            [
              -113.668166,
              46.716229
            ],
            [
              -113.55153,
              46.716508
            ],
            [
              -113.551523,
              46.72796
            ],
            [
              -113.545081,
              46.744623
            ],
            [
              -113.479856,
              46.744714
            ],
            [
              -113.479832,
              46.802772
            ],
            [
              -113.417085,
              46.802495
            ],
            [
              -113.416599,
              46.832113
            ],
            [
              -113.302903,
              46.832159
            ],
            [
              -113.302943,
              47.179643
            ],
            [
              -113.46645,
              47.179186
            ],
            [
              -113.466485,
              47.600057
            ],
            [
              -113.634079,
              47.600027
            ],
            [
              -113.948844,
              47.599993
            ],
            [
              -113.930289,
              47.598499
            ],
            [
              -113.932258,
              47.577161
            ],
            [
              -113.9195,
              47.549411
            ],
            [
              -113.929462,
              47.539529
            ],
            [
              -113.927674,
              47.520571
            ],
            [
              -113.908581,
              47.514436
            ],
            [
              -113.91422,
              47.500954
            ],
            [
              -113.935457,
              47.498007
            ],
            [
              -113.95118,
              47.475994
            ],
            [
              -113.928775,
              47.465901
            ],
            [
              -113.932412,
              47.4576
            ],
            [
              -113.903678,
              47.447615
            ],
            [
              -113.890834,
              47.431689
            ],
            [
              -113.891339,
              47.416784
            ],
            [
              -113.87111,
              47.39197
            ],
            [
              -113.877248,
              47.370108
            ],
            [
              -113.889045,
              47.355012
            ],
            [
              -113.881838,
              47.338524
            ],
            [
              -113.887021,
              47.317872
            ],
            [
              -113.900685,
              47.311317
            ],
            [
              -113.882738,
              47.308073
            ],
            [
              -113.870769,
              47.288158
            ],
            [
              -113.855385,
              47.285002
            ],
            [
              -113.843538,
              47.272055
            ],
            [
              -113.824348,
              47.274413
            ],
            [
              -113.824339,
              47.268684
            ],
            [
              -113.821447,
              47.181709
            ],
            [
              -113.933568,
              47.18172
            ],
            [
              -113.933503,
              47.138289
            ],
            [
              -114.18717,
              47.138191
            ],
            [
              -114.212098,
              47.127709
            ],
            [
              -114.236088,
              47.134301
            ],
            [
              -114.254478,
              47.120717
            ],
            [
              -114.289478,
              47.125798
            ],
            [
              -114.3026,
              47.136271
            ],
            [
              -114.333079,
              47.145241
            ],
            [
              -114.355922,
              47.157903
            ],
            [
              -114.346111,
              47.176235
            ],
            [
              -114.35384,
              47.190995
            ],
            [
              -114.362241,
              47.187055
            ],
            [
              -114.403361,
              47.196835
            ],
            [
              -114.423858,
              47.210091
            ],
            [
              -114.454395,
              47.207963
            ],
            [
              -114.469089,
              47.21934
            ],
            [
              -114.49132,
              47.220205
            ],
            [
              -114.542704,
              47.239883
            ],
            [
              -114.563737,
              47.254611
            ],
            [
              -114.593458,
              47.257089
            ],
            [
              -114.636642,
              47.26593
            ],
            [
              -114.687295,
              47.27431
            ],
            [
              -114.708847,
              47.254689
            ],
            [
              -114.751653,
              47.26381
            ],
            [
              -114.761193,
              47.259931
            ],
            [
              -114.797204,
              47.268916
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "077",
      "COUNTYNS": "01720021",
      "AFFGEOID": "0500000US30077",
      "GEOID": "30077",
      "NAME": "Powell",
      "LSAD": "06",
      "ALAND": 6024448193,
      "AWATER": 16220811,
      "County_state": "Powell,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -113.466485,
              47.600057
            ],
            [
              -113.46645,
              47.179186
            ],
            [
              -113.302943,
              47.179643
            ],
            [
              -113.302903,
              46.832159
            ],
            [
              -113.291498,
              46.832198
            ],
            [
              -113.291406,
              46.806932
            ],
            [
              -113.275771,
              46.812773
            ],
            [
              -113.26578,
              46.825982
            ],
            [
              -113.241937,
              46.813826
            ],
            [
              -113.242508,
              46.801437
            ],
            [
              -113.228169,
              46.783247
            ],
            [
              -113.211294,
              46.779266
            ],
            [
              -113.173073,
              46.754023
            ],
            [
              -113.182666,
              46.745221
            ],
            [
              -113.177539,
              46.730736
            ],
            [
              -113.166282,
              46.733491
            ],
            [
              -113.132541,
              46.725991
            ],
            [
              -113.115614,
              46.713387
            ],
            [
              -113.078552,
              46.720575
            ],
            [
              -113.061522,
              46.731348
            ],
            [
              -113.039134,
              46.73555
            ],
            [
              -113.03833,
              46.483774
            ],
            [
              -113.036083,
              46.401989
            ],
            [
              -113.036755,
              46.396683
            ],
            [
              -113.036825,
              46.266826
            ],
            [
              -112.558599,
              46.265821
            ],
            [
              -112.570046,
              46.304972
            ],
            [
              -112.533277,
              46.331401
            ],
            [
              -112.503954,
              46.330196
            ],
            [
              -112.498557,
              46.342062
            ],
            [
              -112.471736,
              46.340552
            ],
            [
              -112.454085,
              46.328932
            ],
            [
              -112.451363,
              46.349998
            ],
            [
              -112.426005,
              46.357464
            ],
            [
              -112.426093,
              46.36898
            ],
            [
              -112.403784,
              46.386566
            ],
            [
              -112.349605,
              46.385345
            ],
            [
              -112.33708,
              46.403689
            ],
            [
              -112.310573,
              46.421171
            ],
            [
              -112.323443,
              46.43331
            ],
            [
              -112.321199,
              46.443353
            ],
            [
              -112.304662,
              46.449908
            ],
            [
              -112.301847,
              46.489877
            ],
            [
              -112.30629,
              46.510393
            ],
            [
              -112.293341,
              46.517915
            ],
            [
              -112.293709,
              46.531128
            ],
            [
              -112.307773,
              46.549338
            ],
            [
              -112.316754,
              46.574418
            ],
            [
              -112.293205,
              46.605089
            ],
            [
              -112.29822,
              46.618092
            ],
            [
              -112.320871,
              46.655927
            ],
            [
              -112.410638,
              46.656011
            ],
            [
              -112.410846,
              46.699505
            ],
            [
              -112.535597,
              46.699482
            ],
            [
              -112.535748,
              46.834234
            ],
            [
              -112.668246,
              46.831613
            ],
            [
              -112.795642,
              46.831665
            ],
            [
              -112.795782,
              46.93943
            ],
            [
              -112.795808,
              47.177004
            ],
            [
              -113.059053,
              47.17957
            ],
            [
              -113.059324,
              47.488229
            ],
            [
              -113.091448,
              47.480427
            ],
            [
              -113.11455,
              47.490409
            ],
            [
              -113.123098,
              47.51573
            ],
            [
              -113.124661,
              47.55797
            ],
            [
              -113.117746,
              47.570473
            ],
            [
              -113.144368,
              47.595507
            ],
            [
              -113.325845,
              47.595507
            ],
            [
              -113.325845,
              47.600161
            ],
            [
              -113.466485,
              47.600057
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "035",
      "COUNTYNS": "01719607",
      "AFFGEOID": "0500000US30035",
      "GEOID": "30035",
      "NAME": "Glacier",
      "LSAD": "06",
      "ALAND": 7756795340,
      "AWATER": 106093201,
      "County_state": "Glacier,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -114.068179566579,
              48.9989189290043
            ],
            [
              -114.066337,
              48.996708
            ],
            [
              -114.063096,
              48.990339
            ],
            [
              -114.055889,
              48.984012
            ],
            [
              -114.057956,
              48.979035
            ],
            [
              -114.055974,
              48.973786
            ],
            [
              -114.051375,
              48.966052
            ],
            [
              -114.042372,
              48.962765
            ],
            [
              -114.029571,
              48.964753
            ],
            [
              -114.024269,
              48.962905
            ],
            [
              -114.028048,
              48.955744
            ],
            [
              -114.037745,
              48.949376
            ],
            [
              -114.041902,
              48.941086
            ],
            [
              -114.044964,
              48.937914
            ],
            [
              -114.042985,
              48.932281
            ],
            [
              -114.037044,
              48.931449
            ],
            [
              -114.023381,
              48.930897
            ],
            [
              -114.014984,
              48.924434
            ],
            [
              -114.015587,
              48.918439
            ],
            [
              -114.013602,
              48.910082
            ],
            [
              -114.017852,
              48.904078
            ],
            [
              -114.030882,
              48.898403
            ],
            [
              -114.04486,
              48.89458
            ],
            [
              -114.050664,
              48.894411
            ],
            [
              -114.055901,
              48.891776
            ],
            [
              -114.055451,
              48.888092
            ],
            [
              -114.04278,
              48.883749
            ],
            [
              -114.029947,
              48.878489
            ],
            [
              -114.025798,
              48.872098
            ],
            [
              -114.017865,
              48.865374
            ],
            [
              -114.008071,
              48.86471
            ],
            [
              -114.003367,
              48.861229
            ],
            [
              -113.996926,
              48.856713
            ],
            [
              -113.992204,
              48.851802
            ],
            [
              -113.989502,
              48.844461
            ],
            [
              -113.993211,
              48.838979
            ],
            [
              -114,
              48.836702
            ],
            [
              -114.002862,
              48.834639
            ],
            [
              -114.000336,
              48.831241
            ],
            [
              -113.996416,
              48.829546
            ],
            [
              -113.987183,
              48.826649
            ],
            [
              -113.97974,
              48.824206
            ],
            [
              -113.976239,
              48.821077
            ],
            [
              -113.972396,
              48.820896
            ],
            [
              -113.970725,
              48.822606
            ],
            [
              -113.964613,
              48.82325
            ],
            [
              -113.96042,
              48.819321
            ],
            [
              -113.952139,
              48.818491
            ],
            [
              -113.946296,
              48.821531
            ],
            [
              -113.940318,
              48.825676
            ],
            [
              -113.93085,
              48.828087
            ],
            [
              -113.921959,
              48.834177
            ],
            [
              -113.914783,
              48.840677
            ],
            [
              -113.91261,
              48.845783
            ],
            [
              -113.908965,
              48.847747
            ],
            [
              -113.904313,
              48.846459
            ],
            [
              -113.892725,
              48.844523
            ],
            [
              -113.886427,
              48.840313
            ],
            [
              -113.880352,
              48.838721
            ],
            [
              -113.874574,
              48.839897
            ],
            [
              -113.871838,
              48.842778
            ],
            [
              -113.863615,
              48.848075
            ],
            [
              -113.855532,
              48.851109
            ],
            [
              -113.846626,
              48.853527
            ],
            [
              -113.840583,
              48.854299
            ],
            [
              -113.830521,
              48.849659
            ],
            [
              -113.818212,
              48.846545
            ],
            [
              -113.817992,
              48.842412
            ],
            [
              -113.81075,
              48.838584
            ],
            [
              -113.802604,
              48.840083
            ],
            [
              -113.79607,
              48.844213
            ],
            [
              -113.79206,
              48.845254
            ],
            [
              -113.78756,
              48.841385
            ],
            [
              -113.785703,
              48.837077
            ],
            [
              -113.782842,
              48.830976
            ],
            [
              -113.776441,
              48.826393
            ],
            [
              -113.76664,
              48.821752
            ],
            [
              -113.760754,
              48.819846
            ],
            [
              -113.759304,
              48.816404
            ],
            [
              -113.760142,
              48.814096
            ],
            [
              -113.760342,
              48.810052
            ],
            [
              -113.767334,
              48.802413
            ],
            [
              -113.773606,
              48.796918
            ],
            [
              -113.771085,
              48.790089
            ],
            [
              -113.768909,
              48.786178
            ],
            [
              -113.765637,
              48.782596
            ],
            [
              -113.762614,
              48.775482
            ],
            [
              -113.754763,
              48.769601
            ],
            [
              -113.742824,
              48.764871
            ],
            [
              -113.740773,
              48.75636
            ],
            [
              -113.735678,
              48.749526
            ],
            [
              -113.728386,
              48.745564
            ],
            [
              -113.718167,
              48.743205
            ],
            [
              -113.711898,
              48.738255
            ],
            [
              -113.708662,
              48.733239
            ],
            [
              -113.708324,
              48.727295
            ],
            [
              -113.707584,
              48.72281
            ],
            [
              -113.702732,
              48.717066
            ],
            [
              -113.707397,
              48.711534
            ],
            [
              -113.70872,
              48.70667
            ],
            [
              -113.712935,
              48.700909
            ],
            [
              -113.718123,
              48.696591
            ],
            [
              -113.730419,
              48.693683
            ],
            [
              -113.734663,
              48.693901
            ],
            [
              -113.740356,
              48.691358
            ],
            [
              -113.739922,
              48.68909
            ],
            [
              -113.737582,
              48.684111
            ],
            [
              -113.73227,
              48.678097
            ],
            [
              -113.729671,
              48.673485
            ],
            [
              -113.729583,
              48.669099
            ],
            [
              -113.735073,
              48.665875
            ],
            [
              -113.740286,
              48.659263
            ],
            [
              -113.744242,
              48.653302
            ],
            [
              -113.746275,
              48.646185
            ],
            [
              -113.745761,
              48.638723
            ],
            [
              -113.745734,
              48.632226
            ],
            [
              -113.74713,
              48.624256
            ],
            [
              -113.752066,
              48.620592
            ],
            [
              -113.75107,
              48.615276
            ],
            [
              -113.746688,
              48.612696
            ],
            [
              -113.73854,
              48.609467
            ],
            [
              -113.733115,
              48.60757
            ],
            [
              -113.728971,
              48.603302
            ],
            [
              -113.723333,
              48.600299
            ],
            [
              -113.715302,
              48.598467
            ],
            [
              -113.706855,
              48.594572
            ],
            [
              -113.690777,
              48.592985
            ],
            [
              -113.677422,
              48.592234
            ],
            [
              -113.668284,
              48.588457
            ],
            [
              -113.658268,
              48.587683
            ],
            [
              -113.651124,
              48.592488
            ],
            [
              -113.645885,
              48.596483
            ],
            [
              -113.639719,
              48.600057
            ],
            [
              -113.632257,
              48.598819
            ],
            [
              -113.62595,
              48.594623
            ],
            [
              -113.617736,
              48.588596
            ],
            [
              -113.613369,
              48.579806
            ],
            [
              -113.605025,
              48.575749
            ],
            [
              -113.594386,
              48.576765
            ],
            [
              -113.590233,
              48.580885
            ],
            [
              -113.582374,
              48.583612
            ],
            [
              -113.573963,
              48.580855
            ],
            [
              -113.565471,
              48.581604
            ],
            [
              -113.552853,
              48.583579
            ],
            [
              -113.547098,
              48.584767
            ],
            [
              -113.54233,
              48.584504
            ],
            [
              -113.539682,
              48.581128
            ],
            [
              -113.5325,
              48.577013
            ],
            [
              -113.521966,
              48.573768
            ],
            [
              -113.51482,
              48.569249
            ],
            [
              -113.511963,
              48.561825
            ],
            [
              -113.499979,
              48.556647
            ],
            [
              -113.4881,
              48.553335
            ],
            [
              -113.476535,
              48.552776
            ],
            [
              -113.467412,
              48.547265
            ],
            [
              -113.468792,
              48.541468
            ],
            [
              -113.469139,
              48.534895
            ],
            [
              -113.471373,
              48.526166
            ],
            [
              -113.472308,
              48.518073
            ],
            [
              -113.473263,
              48.509443
            ],
            [
              -113.468831,
              48.500197
            ],
            [
              -113.467356,
              48.496064
            ],
            [
              -113.471067,
              48.489363
            ],
            [
              -113.469938,
              48.482436
            ],
            [
              -113.467018,
              48.474257
            ],
            [
              -113.47444,
              48.468765
            ],
            [
              -113.478309,
              48.461742
            ],
            [
              -113.480516,
              48.457086
            ],
            [
              -113.47649,
              48.45282
            ],
            [
              -113.471644,
              48.450838
            ],
            [
              -113.465956,
              48.447301
            ],
            [
              -113.459402,
              48.445142
            ],
            [
              -113.451214,
              48.443505
            ],
            [
              -113.444664,
              48.441386
            ],
            [
              -113.440414,
              48.436315
            ],
            [
              -113.434466,
              48.432841
            ],
            [
              -113.425176,
              48.430813
            ],
            [
              -113.418236,
              48.425954
            ],
            [
              -113.412965,
              48.420448
            ],
            [
              -113.401756,
              48.418413
            ],
            [
              -113.390976,
              48.416858
            ],
            [
              -113.378893,
              48.419169
            ],
            [
              -113.367303,
              48.423128
            ],
            [
              -113.359739,
              48.423693
            ],
            [
              -113.354342,
              48.419999
            ],
            [
              -113.351085,
              48.415943
            ],
            [
              -113.349705,
              48.410417
            ],
            [
              -113.349866,
              48.402804
            ],
            [
              -113.347054,
              48.395218
            ],
            [
              -113.337788,
              48.390396
            ],
            [
              -113.333708,
              48.384238
            ],
            [
              -113.336099,
              48.378756
            ],
            [
              -113.339676,
              48.370768
            ],
            [
              -113.348453,
              48.364923
            ],
            [
              -113.356908,
              48.359775
            ],
            [
              -113.369832,
              48.353957
            ],
            [
              -113.377057,
              48.347294
            ],
            [
              -113.381552,
              48.344188
            ],
            [
              -113.385396,
              48.340388
            ],
            [
              -113.3827,
              48.339101
            ],
            [
              -113.375094,
              48.332875
            ],
            [
              -113.374761,
              48.325
            ],
            [
              -113.365568,
              48.321204
            ],
            [
              -113.357088,
              48.316915
            ],
            [
              -113.354804,
              48.316498
            ],
            [
              -113.349046,
              48.310263
            ],
            [
              -112.58125,
              48.309928
            ],
            [
              -112.575873,
              48.310332
            ],
            [
              -112.577098,
              48.375536
            ],
            [
              -112.576982,
              48.483348
            ],
            [
              -112.229059,
              48.483614
            ],
            [
              -112.20721,
              48.477023
            ],
            [
              -112.184784,
              48.477578
            ],
            [
              -112.189102,
              48.566473
            ],
            [
              -112.189123,
              48.63891
            ],
            [
              -112.189016,
              48.913989
            ],
            [
              -112.193410146198,
              48.998899920427796
            ],
            [
              -113.009895,
              48.998619
            ],
            [
              -113.011041,
              48.998643
            ],
            [
              -113.085576,
              48.998581
            ],
            [
              -113.087863,
              48.998557
            ],
            [
              -113.092055,
              48.998543
            ],
            [
              -113.095436,
              48.998533
            ],
            [
              -113.098147,
              48.998494
            ],
            [
              -113.103212,
              48.99853
            ],
            [
              -113.106891,
              48.998501
            ],
            [
              -113.110155,
              48.99855
            ],
            [
              -113.116356,
              48.998462
            ],
            [
              -113.19474,
              48.998909
            ],
            [
              -113.356874,
              48.998224
            ],
            [
              -113.375925,
              48.998562
            ],
            [
              -113.576118,
              48.998478
            ],
            [
              -113.692982,
              48.997632
            ],
            [
              -113.864127,
              48.998276
            ],
            [
              -113.907487,
              48.998858
            ],
            [
              -114.059188,
              48.998856
            ],
            [
              -114.068179566579,
              48.9989189290043
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "087",
      "COUNTYNS": "01716795",
      "AFFGEOID": "0500000US30087",
      "GEOID": "30087",
      "NAME": "Rosebud",
      "LSAD": "06",
      "ALAND": 12971010170,
      "AWATER": 44234236,
      "County_state": "Rosebud,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -107.892233,
              46.851215
            ],
            [
              -107.897356,
              46.849722
            ],
            [
              -107.8767,
              46.845031
            ],
            [
              -107.873446,
              46.835011
            ],
            [
              -107.8416,
              46.801646
            ],
            [
              -107.845747,
              46.780484
            ],
            [
              -107.827687,
              46.755875
            ],
            [
              -107.813427,
              46.743319
            ],
            [
              -107.809508,
              46.728798
            ],
            [
              -107.82719,
              46.726655
            ],
            [
              -107.816882,
              46.708428
            ],
            [
              -107.822949,
              46.703141
            ],
            [
              -107.800323,
              46.692267
            ],
            [
              -107.792957,
              46.680187
            ],
            [
              -107.808655,
              46.662646
            ],
            [
              -107.820919,
              46.641483
            ],
            [
              -107.812174,
              46.632306
            ],
            [
              -107.816987,
              46.615751
            ],
            [
              -107.837732,
              46.596647
            ],
            [
              -107.795473,
              46.596652
            ],
            [
              -107.796755,
              46.495874
            ],
            [
              -107.781214,
              46.496014
            ],
            [
              -107.754824,
              46.496043
            ],
            [
              -107.738548,
              46.481806
            ],
            [
              -107.174531,
              46.482713
            ],
            [
              -107.174435,
              46.468176
            ],
            [
              -107.153515,
              46.468101
            ],
            [
              -107.153444,
              46.395613
            ],
            [
              -107.028422,
              46.39546
            ],
            [
              -107.028538,
              46.306639
            ],
            [
              -107.007394,
              46.305902
            ],
            [
              -107.007394,
              46.134198
            ],
            [
              -106.939067,
              46.136667
            ],
            [
              -106.939097,
              45.879194
            ],
            [
              -106.939095,
              45.870041
            ],
            [
              -106.939077,
              45.831608
            ],
            [
              -106.877217,
              45.831714
            ],
            [
              -106.877116,
              45.788217
            ],
            [
              -106.9128,
              45.788221
            ],
            [
              -106.912959,
              45.683915
            ],
            [
              -106.846476,
              45.676587
            ],
            [
              -106.727573,
              45.676469
            ],
            [
              -106.729571,
              45.67285
            ],
            [
              -106.729543,
              45.35148
            ],
            [
              -106.768676,
              45.352826
            ],
            [
              -106.768014,
              45.179728
            ],
            [
              -106.39888,
              45.179746
            ],
            [
              -106.279709,
              45.179728
            ],
            [
              -106.279549,
              45.351797
            ],
            [
              -106.235716,
              45.351796
            ],
            [
              -106.231728,
              45.756782
            ],
            [
              -106.234502,
              45.788578
            ],
            [
              -106.191734,
              45.7886
            ],
            [
              -106.191505,
              45.876391
            ],
            [
              -106.191629,
              46.137183
            ],
            [
              -106.153433,
              46.13726
            ],
            [
              -106.151635,
              46.224286
            ],
            [
              -106.155143,
              46.483634
            ],
            [
              -106.120366,
              46.483648
            ],
            [
              -106.120384,
              46.624821
            ],
            [
              -106.12063,
              46.831438
            ],
            [
              -106.086341,
              46.831462
            ],
            [
              -106.086463,
              46.845881
            ],
            [
              -106.720237,
              46.845176
            ],
            [
              -106.720157,
              46.859603
            ],
            [
              -107.467748,
              46.859254
            ],
            [
              -107.46777,
              46.85116
            ],
            [
              -107.892233,
              46.851215
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "097",
      "COUNTYNS": "01719569",
      "AFFGEOID": "0500000US30097",
      "GEOID": "30097",
      "NAME": "Sweet Grass",
      "LSAD": "06",
      "ALAND": 4805607292,
      "AWATER": 17665081,
      "County_state": "Sweet Grass,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -110.292752,
              46.133849
            ],
            [
              -110.292662,
              45.785057
            ],
            [
              -110.220908,
              45.784973
            ],
            [
              -110.218283,
              45.669593
            ],
            [
              -110.219511,
              45.519881
            ],
            [
              -110.219412,
              45.348799
            ],
            [
              -110.228243,
              45.348755
            ],
            [
              -110.228052,
              45.172129
            ],
            [
              -110.064518,
              45.172129
            ],
            [
              -110.064581,
              45.348755
            ],
            [
              -109.931984,
              45.348755
            ],
            [
              -109.931139,
              45.522457
            ],
            [
              -109.808841,
              45.52247
            ],
            [
              -109.808793,
              45.565925
            ],
            [
              -109.685385,
              45.56561
            ],
            [
              -109.685385,
              45.609022
            ],
            [
              -109.56207,
              45.609119
            ],
            [
              -109.563907,
              45.783471
            ],
            [
              -109.546551,
              45.783475
            ],
            [
              -109.548822,
              45.870749
            ],
            [
              -109.505963,
              45.870218
            ],
            [
              -109.506604,
              45.959112
            ],
            [
              -109.423026,
              45.960011
            ],
            [
              -109.423011,
              46.033019
            ],
            [
              -109.417158,
              46.044759
            ],
            [
              -109.605153,
              46.044994
            ],
            [
              -109.60419,
              46.132484
            ],
            [
              -109.65384,
              46.132465
            ],
            [
              -109.653871,
              46.219404
            ],
            [
              -110.281971,
              46.220605
            ],
            [
              -110.281916,
              46.184397
            ],
            [
              -110.281898,
              46.13384
            ],
            [
              -110.292752,
              46.133849
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "073",
      "COUNTYNS": "01719587",
      "AFFGEOID": "0500000US30073",
      "GEOID": "30073",
      "NAME": "Pondera",
      "LSAD": "06",
      "ALAND": 4207608379,
      "AWATER": 43980663,
      "County_state": "Pondera,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -113.349046,
              48.310263
            ],
            [
              -113.348,
              48.303113
            ],
            [
              -113.322583,
              48.287724
            ],
            [
              -113.2978,
              48.284251
            ],
            [
              -113.289585,
              48.265339
            ],
            [
              -113.267613,
              48.259966
            ],
            [
              -113.236777,
              48.247211
            ],
            [
              -113.226613,
              48.234355
            ],
            [
              -113.236852,
              48.218002
            ],
            [
              -113.230906,
              48.170426
            ],
            [
              -113.207854,
              48.164377
            ],
            [
              -113.190154,
              48.175254
            ],
            [
              -113.166205,
              48.165455
            ],
            [
              -113.152279,
              48.15124
            ],
            [
              -113.130786,
              48.145457
            ],
            [
              -113.13468,
              48.138183
            ],
            [
              -113.090804,
              48.136443
            ],
            [
              -113.063915,
              48.149976
            ],
            [
              -113.052922,
              48.140902
            ],
            [
              -113.051183,
              48.12131
            ],
            [
              -113.030149,
              48.133269
            ],
            [
              -113.014811,
              48.13103
            ],
            [
              -112.900707,
              48.13103
            ],
            [
              -112.177755,
              48.130717
            ],
            [
              -112.177751,
              48.087162
            ],
            [
              -112.047999,
              48.086612
            ],
            [
              -112.048342,
              48.042909
            ],
            [
              -111.984125,
              48.042767
            ],
            [
              -111.984263,
              47.984592
            ],
            [
              -111.789595,
              47.984162
            ],
            [
              -111.666316,
              47.987273
            ],
            [
              -111.408514,
              47.987178
            ],
            [
              -111.409097,
              48.132218
            ],
            [
              -111.409018,
              48.219542
            ],
            [
              -111.665599,
              48.219549
            ],
            [
              -111.665652,
              48.248281
            ],
            [
              -111.665756,
              48.349456
            ],
            [
              -111.795855,
              48.349812
            ],
            [
              -111.79591,
              48.393081
            ],
            [
              -111.920554,
              48.392967
            ],
            [
              -111.990889,
              48.394265
            ],
            [
              -111.990944,
              48.438546
            ],
            [
              -112.004436,
              48.448886
            ],
            [
              -112.025845,
              48.452353
            ],
            [
              -112.073026,
              48.444071
            ],
            [
              -112.077584,
              48.435369
            ],
            [
              -112.099419,
              48.449961
            ],
            [
              -112.151928,
              48.471408
            ],
            [
              -112.153619,
              48.464571
            ],
            [
              -112.174532,
              48.467348
            ],
            [
              -112.184784,
              48.477578
            ],
            [
              -112.20721,
              48.477023
            ],
            [
              -112.229059,
              48.483614
            ],
            [
              -112.576982,
              48.483348
            ],
            [
              -112.577098,
              48.375536
            ],
            [
              -112.575873,
              48.310332
            ],
            [
              -112.58125,
              48.309928
            ],
            [
              -113.349046,
              48.310263
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "019",
      "COUNTYNS": "01637566",
      "AFFGEOID": "0500000US30019",
      "GEOID": "30019",
      "NAME": "Daniels",
      "LSAD": "06",
      "ALAND": 3693365374,
      "AWATER": 1021697,
      "County_state": "Daniels,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -106.15193,
              48.910637
            ],
            [
              -106.151908,
              48.823927
            ],
            [
              -106.021251,
              48.823975
            ],
            [
              -106.02131,
              48.563424
            ],
            [
              -105.804436,
              48.563358
            ],
            [
              -104.973354,
              48.56319
            ],
            [
              -104.973283,
              48.65006
            ],
            [
              -105.038548,
              48.650099
            ],
            [
              -105.038171,
              48.910512
            ],
            [
              -105.057742,
              48.910522
            ],
            [
              -105.05764171669101,
              48.9992288873925
            ],
            [
              -105.265192,
              48.9995
            ],
            [
              -105.277521,
              48.999457
            ],
            [
              -105.355888,
              48.999357
            ],
            [
              -105.38749,
              48.999382
            ],
            [
              -105.391379,
              48.999475
            ],
            [
              -105.407909,
              48.99948
            ],
            [
              -105.411972,
              48.999582
            ],
            [
              -105.522636,
              48.999591
            ],
            [
              -105.578616,
              48.999673
            ],
            [
              -105.607542,
              48.999624
            ],
            [
              -105.612577,
              48.999703
            ],
            [
              -105.65027,
              48.999444
            ],
            [
              -105.775808,
              48.999637
            ],
            [
              -105.834181,
              48.999707
            ],
            [
              -105.966197,
              48.999445
            ],
            [
              -106.050543,
              48.999207
            ],
            [
              -106.11206332368401,
              48.99927943840029
            ],
            [
              -106.112003,
              48.910647
            ],
            [
              -106.15193,
              48.910637
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "067",
      "COUNTYNS": "01720027",
      "AFFGEOID": "0500000US30067",
      "GEOID": "30067",
      "NAME": "Park",
      "LSAD": "06",
      "ALAND": 7258393601,
      "AWATER": 27856467,
      "County_state": "Park,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -111.045065708123,
              45.0013432628163
            ],
            [
              -111.044275,
              45.001345
            ],
            [
              -110.785008,
              45.002952
            ],
            [
              -110.761554,
              44.999934
            ],
            [
              -110.750767,
              44.997948
            ],
            [
              -110.705272,
              44.992324
            ],
            [
              -110.552433,
              44.992237
            ],
            [
              -110.547165,
              44.992459
            ],
            [
              -110.48807,
              44.992361
            ],
            [
              -110.402927,
              44.99381
            ],
            [
              -110.362698,
              45.000593
            ],
            [
              -110.342131,
              44.999053
            ],
            [
              -110.324441,
              44.999156
            ],
            [
              -110.28677,
              44.99685
            ],
            [
              -110.199503,
              44.996188
            ],
            [
              -110.110103,
              45.003905
            ],
            [
              -110.026347,
              45.003665
            ],
            [
              -110.025544,
              45.003602
            ],
            [
              -110.001504976459,
              45.0032645991318
            ],
            [
              -109.99505,
              45.003174
            ],
            [
              -109.875735,
              45.003275
            ],
            [
              -109.798687,
              45.002188
            ],
            [
              -109.79848479260801,
              45.002185541820594
            ],
            [
              -109.798673,
              45.167339
            ],
            [
              -109.798666,
              45.172128
            ],
            [
              -110.064518,
              45.172129
            ],
            [
              -110.228052,
              45.172129
            ],
            [
              -110.228243,
              45.348755
            ],
            [
              -110.219412,
              45.348799
            ],
            [
              -110.219511,
              45.519881
            ],
            [
              -110.218283,
              45.669593
            ],
            [
              -110.220908,
              45.784973
            ],
            [
              -110.292662,
              45.785057
            ],
            [
              -110.292752,
              46.133849
            ],
            [
              -110.281898,
              46.13384
            ],
            [
              -110.281916,
              46.184397
            ],
            [
              -110.409283,
              46.184368
            ],
            [
              -110.409271,
              46.192065
            ],
            [
              -110.783833,
              46.192699
            ],
            [
              -110.784031,
              46.141826
            ],
            [
              -110.792404,
              45.988779
            ],
            [
              -110.78852,
              45.931278
            ],
            [
              -110.789399,
              45.786308
            ],
            [
              -110.795264,
              45.786319
            ],
            [
              -110.795441,
              45.742872
            ],
            [
              -110.795277,
              45.70187
            ],
            [
              -110.794681,
              45.613406
            ],
            [
              -110.794774,
              45.590682
            ],
            [
              -110.857382,
              45.59065
            ],
            [
              -110.858252,
              45.524879
            ],
            [
              -110.919222,
              45.524861
            ],
            [
              -110.917899,
              45.351273
            ],
            [
              -111.038169,
              45.351391
            ],
            [
              -111.038613,
              45.176379
            ],
            [
              -111.045065708123,
              45.0013432628163
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "079",
      "COUNTYNS": "01715847",
      "AFFGEOID": "0500000US30079",
      "GEOID": "30079",
      "NAME": "Prairie",
      "LSAD": "06",
      "ALAND": 4497780202,
      "AWATER": 15092932,
      "County_state": "Prairie,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -106.086777,
              47.180938
            ],
            [
              -106.086585,
              46.86029
            ],
            [
              -105.832674,
              46.86082
            ],
            [
              -105.832631,
              46.83196
            ],
            [
              -105.618158,
              46.832148
            ],
            [
              -105.618097,
              46.744932
            ],
            [
              -105.576394,
              46.745021
            ],
            [
              -105.576063,
              46.658003
            ],
            [
              -105.492002,
              46.658117
            ],
            [
              -105.491919,
              46.600085
            ],
            [
              -105.449406,
              46.600011
            ],
            [
              -105.449196,
              46.570967
            ],
            [
              -105.239058,
              46.570265
            ],
            [
              -105.239238,
              46.541188
            ],
            [
              -104.984714,
              46.540389
            ],
            [
              -104.98474,
              46.569386
            ],
            [
              -104.858749,
              46.569115
            ],
            [
              -104.858738,
              46.612557
            ],
            [
              -104.733059,
              46.612695
            ],
            [
              -104.732843,
              46.656264
            ],
            [
              -104.606835,
              46.655968
            ],
            [
              -104.606903,
              46.684924
            ],
            [
              -104.607307,
              46.831877
            ],
            [
              -104.603766,
              46.860853
            ],
            [
              -105.027526,
              46.861457
            ],
            [
              -105.027542,
              46.890581
            ],
            [
              -105.048789,
              46.890683
            ],
            [
              -105.048831,
              46.905229
            ],
            [
              -105.069583,
              46.905275
            ],
            [
              -105.069524,
              46.919172
            ],
            [
              -105.197011,
              46.919192
            ],
            [
              -105.196735,
              46.977172
            ],
            [
              -105.324757,
              46.977196
            ],
            [
              -105.324853,
              47.181496
            ],
            [
              -105.406309,
              47.181583
            ],
            [
              -105.833044,
              47.181092
            ],
            [
              -105.832637,
              47.093109
            ],
            [
              -105.959897,
              47.092968
            ],
            [
              -105.959819,
              47.181
            ],
            [
              -106.086777,
              47.180938
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "003",
      "COUNTYNS": "01716521",
      "AFFGEOID": "0500000US30003",
      "GEOID": "30003",
      "NAME": "Big Horn",
      "LSAD": "06",
      "ALAND": 12942465259,
      "AWATER": 49210403,
      "County_state": "Big Horn,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -108.69953,
              45.233662
            ],
            [
              -108.699576,
              45.226162
            ],
            [
              -108.69054,
              45.217683
            ],
            [
              -108.683988,
              45.217653
            ],
            [
              -108.659575,
              45.217561
            ],
            [
              -108.600603,
              45.217405
            ],
            [
              -108.550173,
              45.217779
            ],
            [
              -108.53279,
              45.217913
            ],
            [
              -108.503195,
              45.218113
            ],
            [
              -108.464173,
              45.218536
            ],
            [
              -108.417518,
              45.219156
            ],
            [
              -108.365922,
              45.219883
            ],
            [
              -108.323143,
              45.220033
            ],
            [
              -108.290414,
              45.220182
            ],
            [
              -108.229324,
              45.220325
            ],
            [
              -108.172366,
              45.220501
            ],
            [
              -108.128334,
              45.220486
            ],
            [
              -108.104615,
              45.220513
            ],
            [
              -108.104319,
              45.218699
            ],
            [
              -108.11322,
              45.216095
            ],
            [
              -108.122184,
              45.216317
            ],
            [
              -108.129786,
              45.216141
            ],
            [
              -108.129029,
              45.214484
            ],
            [
              -108.127807,
              45.209371
            ],
            [
              -108.132551,
              45.207084
            ],
            [
              -108.135358,
              45.199443
            ],
            [
              -108.133176,
              45.197337
            ],
            [
              -108.139952,
              45.194083
            ],
            [
              -108.142999,
              45.19409
            ],
            [
              -108.141257,
              45.191201
            ],
            [
              -108.136961,
              45.186928
            ],
            [
              -108.132418,
              45.185724
            ],
            [
              -108.133303,
              45.181755
            ],
            [
              -108.139037,
              45.182209
            ],
            [
              -108.143778,
              45.185332
            ],
            [
              -108.149107,
              45.183179
            ],
            [
              -108.148849,
              45.17943
            ],
            [
              -108.151318,
              45.171764
            ],
            [
              -108.152158,
              45.168641
            ],
            [
              -108.157724,
              45.169934
            ],
            [
              -108.15991,
              45.172184
            ],
            [
              -108.164534,
              45.172018
            ],
            [
              -108.167845,
              45.171086
            ],
            [
              -108.167389,
              45.16773
            ],
            [
              -108.163025,
              45.165811
            ],
            [
              -108.162484,
              45.161851
            ],
            [
              -108.167106,
              45.161788
            ],
            [
              -108.170861,
              45.160531
            ],
            [
              -108.172049,
              45.154603
            ],
            [
              -108.169206,
              45.148518
            ],
            [
              -108.171395,
              45.14582
            ],
            [
              -108.167505,
              45.143205
            ],
            [
              -108.164377,
              45.141817
            ],
            [
              -108.163715,
              45.138541
            ],
            [
              -108.166194,
              45.136024
            ],
            [
              -108.173887,
              45.131763
            ],
            [
              -108.181654,
              45.132209
            ],
            [
              -108.186978,
              45.128133
            ],
            [
              -108.184311,
              45.127209
            ],
            [
              -108.181986,
              45.123147
            ],
            [
              -108.184241,
              45.118435
            ],
            [
              -108.183771,
              45.11529
            ],
            [
              -108.189048,
              45.112464
            ],
            [
              -108.193562,
              45.114454
            ],
            [
              -108.194666,
              45.110933
            ],
            [
              -108.190578,
              45.10941
            ],
            [
              -108.187091,
              45.104233
            ],
            [
              -108.188627,
              45.100302
            ],
            [
              -108.19482,
              45.098399
            ],
            [
              -108.199594,
              45.101224
            ],
            [
              -108.199259,
              45.103186
            ],
            [
              -108.204955,
              45.10153
            ],
            [
              -108.206399,
              45.096415
            ],
            [
              -108.211427,
              45.093851
            ],
            [
              -108.214075,
              45.094817
            ],
            [
              -108.219278,
              45.090275
            ],
            [
              -108.220364,
              45.082917
            ],
            [
              -108.224335,
              45.076003
            ],
            [
              -108.229495,
              45.06819
            ],
            [
              -108.227466,
              45.064718
            ],
            [
              -108.233231,
              45.063774
            ],
            [
              -108.237787,
              45.063907
            ],
            [
              -108.239786,
              45.059964
            ],
            [
              -108.239635,
              45.05687
            ],
            [
              -108.233675,
              45.053758
            ],
            [
              -108.226662,
              45.053696
            ],
            [
              -108.221218,
              45.050899
            ],
            [
              -108.224501,
              45.046439
            ],
            [
              -108.228195,
              45.04739
            ],
            [
              -108.234511,
              45.048041
            ],
            [
              -108.240885,
              45.045682
            ],
            [
              -108.245006,
              45.038994
            ],
            [
              -108.24954,
              45.032193
            ],
            [
              -108.249714,
              45.025798
            ],
            [
              -108.247857,
              45.022811
            ],
            [
              -108.25067,
              45.019859
            ],
            [
              -108.253732,
              45.019152
            ],
            [
              -108.253321,
              45.016581
            ],
            [
              -108.253289,
              45.013375
            ],
            [
              -108.256679,
              45.01051
            ],
            [
              -108.257616,
              45.006703
            ],
            [
              -108.2537,
              45.006496
            ],
            [
              -108.249111,
              45.006815
            ],
            [
              -108.245852,
              45.004317
            ],
            [
              -108.24676,
              45.001714
            ],
            [
              -108.24942807283101,
              44.999461014126794
            ],
            [
              -108.249345,
              44.999458
            ],
            [
              -108.238139,
              45.000206
            ],
            [
              -108.218479,
              45.000541
            ],
            [
              -108.14939,
              45.001062
            ],
            [
              -108.000663,
              45.001223
            ],
            [
              -107.997353,
              45.001565
            ],
            [
              -107.911521043863,
              45.0012911855562
            ],
            [
              -107.750654,
              45.000778
            ],
            [
              -107.608854,
              45.00086
            ],
            [
              -107.607824,
              45.000929
            ],
            [
              -107.568293011909,
              45.0011171387396
            ],
            [
              -107.49205,
              45.00148
            ],
            [
              -107.351441,
              45.001407
            ],
            [
              -107.13418,
              45.000109
            ],
            [
              -107.125633,
              44.999388
            ],
            [
              -107.105685,
              44.998734
            ],
            [
              -107.084939,
              44.996599
            ],
            [
              -107.074996,
              44.997004
            ],
            [
              -107.050801,
              44.996424
            ],
            [
              -106.98756544712701,
              44.9962330032121
            ],
            [
              -106.892875,
              44.995947
            ],
            [
              -106.888773,
              44.995885
            ],
            [
              -106.56673983220502,
              44.9948048375
            ],
            [
              -106.263715092465,
              44.9937884330015
            ],
            [
              -106.263684,
              45.179723
            ],
            [
              -106.279709,
              45.179728
            ],
            [
              -106.39888,
              45.179746
            ],
            [
              -106.768014,
              45.179728
            ],
            [
              -106.768676,
              45.352826
            ],
            [
              -106.729543,
              45.35148
            ],
            [
              -106.729571,
              45.67285
            ],
            [
              -106.727573,
              45.676469
            ],
            [
              -106.846476,
              45.676587
            ],
            [
              -106.912959,
              45.683915
            ],
            [
              -106.9128,
              45.788221
            ],
            [
              -106.877116,
              45.788217
            ],
            [
              -106.877217,
              45.831714
            ],
            [
              -106.939077,
              45.831608
            ],
            [
              -106.939095,
              45.870041
            ],
            [
              -107.052159,
              45.870052
            ],
            [
              -107.051946,
              45.913489
            ],
            [
              -107.17636,
              45.913323
            ],
            [
              -107.176358,
              45.956761
            ],
            [
              -107.425128,
              45.956674
            ],
            [
              -107.424929,
              46.043374
            ],
            [
              -107.511534,
              46.043403
            ],
            [
              -107.674454,
              46.04361
            ],
            [
              -107.674468,
              45.985759
            ],
            [
              -107.798753,
              45.98568
            ],
            [
              -107.798821,
              45.956723
            ],
            [
              -107.84038,
              45.956812
            ],
            [
              -107.840375,
              45.928018
            ],
            [
              -107.881873,
              45.928149
            ],
            [
              -107.881846,
              45.899233
            ],
            [
              -108.047352,
              45.899316
            ],
            [
              -108.047559,
              45.783476
            ],
            [
              -108.069572,
              45.783507
            ],
            [
              -108.0697,
              45.681266
            ],
            [
              -108.069752,
              45.518535
            ],
            [
              -108.193205,
              45.518538
            ],
            [
              -108.193191,
              45.489526
            ],
            [
              -108.316245,
              45.489503
            ],
            [
              -108.316374,
              45.460509
            ],
            [
              -108.44523,
              45.460449
            ],
            [
              -108.644992,
              45.464073
            ],
            [
              -108.645325,
              45.464073
            ],
            [
              -108.651432,
              45.458942
            ],
            [
              -108.676089,
              45.438264
            ],
            [
              -108.686658,
              45.389018
            ],
            [
              -108.691612,
              45.37392
            ],
            [
              -108.697229,
              45.355993
            ],
            [
              -108.699444,
              45.329791
            ],
            [
              -108.699343,
              45.28862
            ],
            [
              -108.69953,
              45.233662
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "021",
      "COUNTYNS": "01715632",
      "AFFGEOID": "0500000US30021",
      "GEOID": "30021",
      "NAME": "Dawson",
      "LSAD": "06",
      "ALAND": 6143238452,
      "AWATER": 29905887,
      "County_state": "Dawson,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -105.406581,
              47.529534
            ],
            [
              -105.406309,
              47.181583
            ],
            [
              -105.324853,
              47.181496
            ],
            [
              -105.324757,
              46.977196
            ],
            [
              -105.196735,
              46.977172
            ],
            [
              -105.197011,
              46.919192
            ],
            [
              -105.069524,
              46.919172
            ],
            [
              -105.069583,
              46.905275
            ],
            [
              -105.048831,
              46.905229
            ],
            [
              -105.048789,
              46.890683
            ],
            [
              -105.027542,
              46.890581
            ],
            [
              -105.027526,
              46.861457
            ],
            [
              -104.603766,
              46.860853
            ],
            [
              -104.496582,
              46.860813
            ],
            [
              -104.486034,
              46.875266
            ],
            [
              -104.464947,
              46.875204
            ],
            [
              -104.454413,
              46.88966
            ],
            [
              -104.433233,
              46.88961
            ],
            [
              -104.433241,
              46.904095
            ],
            [
              -104.412124,
              46.904003
            ],
            [
              -104.401536,
              46.93307
            ],
            [
              -104.369824,
              46.947576
            ],
            [
              -104.359173,
              46.976582
            ],
            [
              -104.327241,
              47.005803
            ],
            [
              -104.327277,
              47.041967
            ],
            [
              -104.306062,
              47.042046
            ],
            [
              -104.31637,
              47.136863
            ],
            [
              -104.316166,
              47.18054
            ],
            [
              -104.331316,
              47.194862
            ],
            [
              -104.33118,
              47.216506
            ],
            [
              -104.341804,
              47.2165
            ],
            [
              -104.34168,
              47.245392
            ],
            [
              -104.352315,
              47.245391
            ],
            [
              -104.373515,
              47.274291
            ],
            [
              -104.387164,
              47.274256
            ],
            [
              -104.387266,
              47.288716
            ],
            [
              -104.419293,
              47.303343
            ],
            [
              -104.430045,
              47.317949
            ],
            [
              -104.450985,
              47.319277
            ],
            [
              -104.422616,
              47.345007
            ],
            [
              -104.420347,
              47.354435
            ],
            [
              -104.513292,
              47.354719
            ],
            [
              -104.513049,
              47.529442
            ],
            [
              -104.594046,
              47.529335
            ],
            [
              -104.593664,
              47.616294
            ],
            [
              -104.978553,
              47.616272
            ],
            [
              -104.97809,
              47.703293
            ],
            [
              -105.235016,
              47.703581
            ],
            [
              -105.234771,
              47.790502
            ],
            [
              -105.363262,
              47.790419
            ],
            [
              -105.363507,
              47.529558
            ],
            [
              -105.406581,
              47.529534
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "005",
      "COUNTYNS": "01722470",
      "AFFGEOID": "0500000US30005",
      "GEOID": "30005",
      "NAME": "Blaine",
      "LSAD": "06",
      "ALAND": 10948196395,
      "AWATER": 29079275,
      "County_state": "Blaine,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -109.551644,
              48.293479
            ],
            [
              -109.551758,
              48.221373
            ],
            [
              -109.533938,
              48.221317
            ],
            [
              -109.534295,
              48.134564
            ],
            [
              -109.538663,
              47.871021
            ],
            [
              -109.538677,
              47.738864
            ],
            [
              -109.510907,
              47.74451
            ],
            [
              -109.487237,
              47.730941
            ],
            [
              -109.464006,
              47.725093
            ],
            [
              -109.436685,
              47.725727
            ],
            [
              -109.425315,
              47.720153
            ],
            [
              -109.349699,
              47.750787
            ],
            [
              -109.312838,
              47.758383
            ],
            [
              -109.301081,
              47.770263
            ],
            [
              -109.281933,
              47.776942
            ],
            [
              -109.274171,
              47.793239
            ],
            [
              -109.229905,
              47.794923
            ],
            [
              -109.190276,
              47.790903
            ],
            [
              -109.160341,
              47.77981
            ],
            [
              -109.132768,
              47.781122
            ],
            [
              -109.0659,
              47.801067
            ],
            [
              -109.030258,
              47.792451
            ],
            [
              -109.000719,
              47.803119
            ],
            [
              -108.978383,
              47.802658
            ],
            [
              -108.959958,
              47.792624
            ],
            [
              -108.942745,
              47.791824
            ],
            [
              -108.945661,
              47.772897
            ],
            [
              -108.920422,
              47.756494
            ],
            [
              -108.950006,
              47.736901
            ],
            [
              -108.943659,
              47.729125
            ],
            [
              -108.919407,
              47.735396
            ],
            [
              -108.891115,
              47.734456
            ],
            [
              -108.891069,
              47.870608
            ],
            [
              -108.888316,
              47.922722
            ],
            [
              -108.671694,
              47.923256
            ],
            [
              -108.625587,
              47.920411
            ],
            [
              -108.623259,
              47.957242
            ],
            [
              -108.607579,
              47.990997
            ],
            [
              -108.598581,
              47.991896
            ],
            [
              -108.569564,
              47.981272
            ],
            [
              -108.43398,
              47.976553
            ],
            [
              -108.433848,
              48.219551
            ],
            [
              -108.411239,
              48.21955
            ],
            [
              -108.411186,
              48.444981
            ],
            [
              -108.410374,
              48.448484
            ],
            [
              -108.404675,
              48.440634
            ],
            [
              -108.359095,
              48.438107
            ],
            [
              -108.346292,
              48.422795
            ],
            [
              -108.346193,
              48.56845
            ],
            [
              -108.319011,
              48.568431
            ],
            [
              -108.319119,
              48.742209
            ],
            [
              -108.259384,
              48.742225
            ],
            [
              -108.259372,
              48.913592
            ],
            [
              -108.236357,
              48.91352
            ],
            [
              -108.236393,
              48.99952991858989
            ],
            [
              -108.488063,
              48.999368
            ],
            [
              -108.543194,
              48.999377
            ],
            [
              -108.994722,
              48.999237
            ],
            [
              -109.000708,
              48.999234
            ],
            [
              -109.060292,
              48.999621
            ],
            [
              -109.06057,
              48.999666
            ],
            [
              -109.250722,
              49.000011
            ],
            [
              -109.285975,
              49.000479
            ],
            [
              -109.384068,
              49.000374
            ],
            [
              -109.384762,
              49.000397
            ],
            [
              -109.437397,
              49.000631
            ],
            [
              -109.454023,
              49.001132
            ],
            [
              -109.489554646273,
              49.0006056503142
            ],
            [
              -109.489627,
              48.913968
            ],
            [
              -109.505514,
              48.913911
            ],
            [
              -109.505333,
              48.56791
            ],
            [
              -109.496882,
              48.567914
            ],
            [
              -109.49688,
              48.562796
            ],
            [
              -109.496881,
              48.525207
            ],
            [
              -109.464318,
              48.52526
            ],
            [
              -109.464318,
              48.452638
            ],
            [
              -109.49673,
              48.45273
            ],
            [
              -109.497028,
              48.394739
            ],
            [
              -109.51878,
              48.394726
            ],
            [
              -109.51908,
              48.293457
            ],
            [
              -109.551644,
              48.293479
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "101",
      "COUNTYNS": "01719566",
      "AFFGEOID": "0500000US30101",
      "GEOID": "30101",
      "NAME": "Toole",
      "LSAD": "06",
      "ALAND": 4959868816,
      "AWATER": 78026225,
      "County_state": "Toole,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -112.193410146198,
              48.998899920427796
            ],
            [
              -112.189016,
              48.913989
            ],
            [
              -112.189123,
              48.63891
            ],
            [
              -112.189102,
              48.566473
            ],
            [
              -112.184784,
              48.477578
            ],
            [
              -112.174532,
              48.467348
            ],
            [
              -112.153619,
              48.464571
            ],
            [
              -112.151928,
              48.471408
            ],
            [
              -112.099419,
              48.449961
            ],
            [
              -112.077584,
              48.435369
            ],
            [
              -112.073026,
              48.444071
            ],
            [
              -112.025845,
              48.452353
            ],
            [
              -112.004436,
              48.448886
            ],
            [
              -111.990944,
              48.438546
            ],
            [
              -111.990889,
              48.394265
            ],
            [
              -111.920554,
              48.392967
            ],
            [
              -111.79591,
              48.393081
            ],
            [
              -111.795855,
              48.349812
            ],
            [
              -111.665756,
              48.349456
            ],
            [
              -111.665652,
              48.248281
            ],
            [
              -111.665599,
              48.219549
            ],
            [
              -111.409018,
              48.219542
            ],
            [
              -111.275306,
              48.219531
            ],
            [
              -111.275529,
              48.566445
            ],
            [
              -111.273311,
              48.914078
            ],
            [
              -111.269957,
              48.916991
            ],
            [
              -111.26986200082901,
              48.997229786811594
            ],
            [
              -111.500812,
              48.996963
            ],
            [
              -111.761613,
              48.99765
            ],
            [
              -111.761679,
              48.997614
            ],
            [
              -111.85409,
              48.998039
            ],
            [
              -111.854088,
              48.998067
            ],
            [
              -112.000878,
              48.998921
            ],
            [
              -112.003866,
              48.99857
            ],
            [
              -112.143769,
              48.998917
            ],
            [
              -112.193410146198,
              48.998899920427796
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "091",
      "COUNTYNS": "01677577",
      "AFFGEOID": "0500000US30091",
      "GEOID": "30091",
      "NAME": "Sheridan",
      "LSAD": "06",
      "ALAND": 4340307007,
      "AWATER": 75071798,
      "County_state": "Sheridan,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -105.05764171669101,
              48.9992288873925
            ],
            [
              -105.057742,
              48.910522
            ],
            [
              -105.038171,
              48.910512
            ],
            [
              -105.038548,
              48.650099
            ],
            [
              -104.973283,
              48.65006
            ],
            [
              -104.973354,
              48.56319
            ],
            [
              -104.757619,
              48.563127
            ],
            [
              -104.757643,
              48.476556
            ],
            [
              -104.627726,
              48.476349
            ],
            [
              -104.62776,
              48.389362
            ],
            [
              -104.046910665796,
              48.3893008786193
            ],
            [
              -104.046913,
              48.389433
            ],
            [
              -104.046969,
              48.390675
            ],
            [
              -104.047134,
              48.411057
            ],
            [
              -104.04696,
              48.421065
            ],
            [
              -104.04709,
              48.445903
            ],
            [
              -104.047192,
              48.447251
            ],
            [
              -104.047294,
              48.452529
            ],
            [
              -104.047259,
              48.452941
            ],
            [
              -104.047392,
              48.467086
            ],
            [
              -104.047555,
              48.49414
            ],
            [
              -104.048054,
              48.500025
            ],
            [
              -104.047675,
              48.517852
            ],
            [
              -104.047513,
              48.525913
            ],
            [
              -104.047876,
              48.530798
            ],
            [
              -104.047648,
              48.531489
            ],
            [
              -104.047783,
              48.539737
            ],
            [
              -104.047811,
              48.56277
            ],
            [
              -104.047974,
              48.591606
            ],
            [
              -104.048212,
              48.599055
            ],
            [
              -104.04793,
              48.62019
            ],
            [
              -104.047586,
              48.625644
            ],
            [
              -104.04762,
              48.627015
            ],
            [
              -104.047582044105,
              48.6339759112863
            ],
            [
              -104.047582,
              48.633984
            ],
            [
              -104.047819,
              48.648631
            ],
            [
              -104.047887,
              48.649911
            ],
            [
              -104.047865,
              48.65745
            ],
            [
              -104.047861,
              48.658856
            ],
            [
              -104.047849,
              48.663163
            ],
            [
              -104.047883,
              48.664191
            ],
            [
              -104.04834,
              48.747133
            ],
            [
              -104.048548,
              48.751356
            ],
            [
              -104.048233,
              48.765636
            ],
            [
              -104.048537,
              48.788552
            ],
            [
              -104.048569,
              48.797052
            ],
            [
              -104.0489,
              48.847387
            ],
            [
              -104.048652,
              48.865734
            ],
            [
              -104.048824,
              48.867539
            ],
            [
              -104.048883,
              48.874008
            ],
            [
              -104.048893,
              48.875739
            ],
            [
              -104.048719,
              48.879921
            ],
            [
              -104.048643,
              48.902609
            ],
            [
              -104.048746,
              48.906858
            ],
            [
              -104.048744,
              48.912113
            ],
            [
              -104.048807,
              48.933636
            ],
            [
              -104.048701,
              48.940331
            ],
            [
              -104.04877,
              48.943301
            ],
            [
              -104.048872,
              48.94963
            ],
            [
              -104.048698,
              48.951823
            ],
            [
              -104.048627,
              48.957124
            ],
            [
              -104.0488,
              48.958997
            ],
            [
              -104.048555,
              48.963772
            ],
            [
              -104.048616,
              48.966736
            ],
            [
              -104.048478,
              48.987007
            ],
            [
              -104.048736,
              48.999877
            ],
            [
              -104.543636,
              48.999541
            ],
            [
              -104.647389,
              48.999344
            ],
            [
              -104.875527,
              48.998991
            ],
            [
              -105.05764171669101,
              48.9992288873925
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "039",
      "COUNTYNS": "01719605",
      "AFFGEOID": "0500000US30039",
      "GEOID": "30039",
      "NAME": "Granite",
      "LSAD": "06",
      "ALAND": 4473328965,
      "AWATER": 14546368,
      "County_state": "Granite,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -113.878358,
              46.503199
            ],
            [
              -113.865926,
              46.492123
            ],
            [
              -113.849218,
              46.440978
            ],
            [
              -113.857659,
              46.419419
            ],
            [
              -113.82024,
              46.406992
            ],
            [
              -113.816646,
              46.399082
            ],
            [
              -113.815956,
              46.39708
            ],
            [
              -113.809595,
              46.391773
            ],
            [
              -113.800998,
              46.385171
            ],
            [
              -113.797499,
              46.375079
            ],
            [
              -113.788368,
              46.371462
            ],
            [
              -113.786891,
              46.366894
            ],
            [
              -113.783073,
              46.362243
            ],
            [
              -113.786282,
              46.352886
            ],
            [
              -113.784727,
              46.341719
            ],
            [
              -113.783219,
              46.332755
            ],
            [
              -113.778079,
              46.326454
            ],
            [
              -113.773269,
              46.31947
            ],
            [
              -113.774921,
              46.313093
            ],
            [
              -113.779474,
              46.307348
            ],
            [
              -113.782204,
              46.30253
            ],
            [
              -113.78495,
              46.29701
            ],
            [
              -113.784258,
              46.291945
            ],
            [
              -113.784581,
              46.285911
            ],
            [
              -113.778689,
              46.282346
            ],
            [
              -113.766074,
              46.277252
            ],
            [
              -113.753561,
              46.274143
            ],
            [
              -113.74909,
              46.269885
            ],
            [
              -113.751949,
              46.264002
            ],
            [
              -113.758354,
              46.259744
            ],
            [
              -113.763436,
              46.247514
            ],
            [
              -113.771124,
              46.245464
            ],
            [
              -113.775022,
              46.242923
            ],
            [
              -113.778078,
              46.237611
            ],
            [
              -113.776631,
              46.233686
            ],
            [
              -113.777519,
              46.227512
            ],
            [
              -113.782292,
              46.226164
            ],
            [
              -113.791855,
              46.223008
            ],
            [
              -113.799302,
              46.219668
            ],
            [
              -113.80825,
              46.218161
            ],
            [
              -113.817675,
              46.215486
            ],
            [
              -113.822965,
              46.208567
            ],
            [
              -113.823357,
              46.201768
            ],
            [
              -113.81975,
              46.196261
            ],
            [
              -113.817995,
              46.191468
            ],
            [
              -113.812713,
              46.189546
            ],
            [
              -113.806164,
              46.191742
            ],
            [
              -113.802438,
              46.191356
            ],
            [
              -113.800513,
              46.188424
            ],
            [
              -113.795432,
              46.184822
            ],
            [
              -113.78958,
              46.182121
            ],
            [
              -113.786952,
              46.18134
            ],
            [
              -113.786744,
              46.179238
            ],
            [
              -113.788944,
              46.177492
            ],
            [
              -113.791586,
              46.175016
            ],
            [
              -113.791054,
              46.169511
            ],
            [
              -113.790891,
              46.165528
            ],
            [
              -113.791038,
              46.160581
            ],
            [
              -113.793434,
              46.156173
            ],
            [
              -113.79433,
              46.151077
            ],
            [
              -113.790441,
              46.148343
            ],
            [
              -113.784331,
              46.144609
            ],
            [
              -113.780344,
              46.142618
            ],
            [
              -113.777656,
              46.13908
            ],
            [
              -113.77834,
              46.137023
            ],
            [
              -113.77738,
              46.1344
            ],
            [
              -113.773271,
              46.133851
            ],
            [
              -113.767616,
              46.129386
            ],
            [
              -113.765061,
              46.123673
            ],
            [
              -113.758229,
              46.118223
            ],
            [
              -113.75392,
              46.113344
            ],
            [
              -113.761837,
              46.103149
            ],
            [
              -113.768228,
              46.098791
            ],
            [
              -113.771573,
              46.089403
            ],
            [
              -113.772885,
              46.084706
            ],
            [
              -113.778184,
              46.081178
            ],
            [
              -113.786057,
              46.07546
            ],
            [
              -113.786382,
              46.070852
            ],
            [
              -113.789236,
              46.066931
            ],
            [
              -113.792622,
              46.062284
            ],
            [
              -113.79521,
              46.054147
            ],
            [
              -113.80329,
              46.049296
            ],
            [
              -113.801878,
              46.04261
            ],
            [
              -113.803601,
              46.039361
            ],
            [
              -113.798611,
              46.035505
            ],
            [
              -113.793878,
              46.035108
            ],
            [
              -113.783181,
              46.032164
            ],
            [
              -113.77646,
              46.03143
            ],
            [
              -113.770741,
              46.028601
            ],
            [
              -113.764892,
              46.029494
            ],
            [
              -113.760641,
              46.03209
            ],
            [
              -113.7583,
              46.037772
            ],
            [
              -113.756054,
              46.041445
            ],
            [
              -113.753466,
              46.046593
            ],
            [
              -113.747411,
              46.048295
            ],
            [
              -113.741568,
              46.047257
            ],
            [
              -113.735615,
              46.041612
            ],
            [
              -113.731733,
              46.038375
            ],
            [
              -113.719707,
              46.039031
            ],
            [
              -113.708471,
              46.039208
            ],
            [
              -113.696822,
              46.037269
            ],
            [
              -113.689004,
              46.035625
            ],
            [
              -113.687931,
              46.033023
            ],
            [
              -113.690328,
              46.028341
            ],
            [
              -113.686526,
              46.023498
            ],
            [
              -113.678185,
              46.022533
            ],
            [
              -113.674309,
              46.023613
            ],
            [
              -113.672617,
              46.020625
            ],
            [
              -113.675417,
              46.017333
            ],
            [
              -113.673825,
              46.013933
            ],
            [
              -113.672302,
              46.009322
            ],
            [
              -113.670855,
              46.004757
            ],
            [
              -113.667711,
              45.998283
            ],
            [
              -113.668488,
              45.992346
            ],
            [
              -113.664146,
              45.985646
            ],
            [
              -113.66306,
              45.979863
            ],
            [
              -113.656757,
              45.975729
            ],
            [
              -113.650585,
              45.967084
            ],
            [
              -113.643074,
              45.963839
            ],
            [
              -113.636249,
              45.966033
            ],
            [
              -113.628886,
              45.967637
            ],
            [
              -113.620061,
              45.969644
            ],
            [
              -113.616988,
              45.970397
            ],
            [
              -113.613297,
              45.966142
            ],
            [
              -113.612838,
              45.96204
            ],
            [
              -113.613103,
              45.957236
            ],
            [
              -113.609357,
              45.951111
            ],
            [
              -113.603846,
              45.948618
            ],
            [
              -113.596307,
              45.949915
            ],
            [
              -113.590048,
              45.946861
            ],
            [
              -113.585519,
              45.947318
            ],
            [
              -113.580926,
              45.945196
            ],
            [
              -113.579582,
              45.942076
            ],
            [
              -113.575561,
              45.938842
            ],
            [
              -113.571707,
              45.940579
            ],
            [
              -113.562765,
              45.942788
            ],
            [
              -113.556441,
              45.945805
            ],
            [
              -113.550345,
              45.946421
            ],
            [
              -113.545313,
              45.946265
            ],
            [
              -113.539685,
              45.944815
            ],
            [
              -113.532103,
              45.945191
            ],
            [
              -113.524545,
              45.942684
            ],
            [
              -113.520851,
              45.940885
            ],
            [
              -113.517409,
              45.940089
            ],
            [
              -113.499843,
              45.948439
            ],
            [
              -113.480035,
              45.939763
            ],
            [
              -113.459333,
              45.962294
            ],
            [
              -113.468163,
              45.968122
            ],
            [
              -113.458019,
              45.984303
            ],
            [
              -113.440669,
              45.981405
            ],
            [
              -113.415089,
              45.989807
            ],
            [
              -113.399267,
              46.000469
            ],
            [
              -113.396907,
              46.014241
            ],
            [
              -113.378448,
              46.01863
            ],
            [
              -113.362468,
              46.007155
            ],
            [
              -113.335804,
              46.005384
            ],
            [
              -113.278012,
              46.045268
            ],
            [
              -113.274359,
              46.059326
            ],
            [
              -113.274364,
              46.135798
            ],
            [
              -113.285039,
              46.135788
            ],
            [
              -113.284532,
              46.222783
            ],
            [
              -113.036818,
              46.22333
            ],
            [
              -113.036825,
              46.266826
            ],
            [
              -113.036755,
              46.396683
            ],
            [
              -113.036083,
              46.401989
            ],
            [
              -113.03833,
              46.483774
            ],
            [
              -113.039134,
              46.73555
            ],
            [
              -113.061522,
              46.731348
            ],
            [
              -113.078552,
              46.720575
            ],
            [
              -113.115614,
              46.713387
            ],
            [
              -113.132541,
              46.725991
            ],
            [
              -113.166282,
              46.733491
            ],
            [
              -113.177539,
              46.730736
            ],
            [
              -113.182666,
              46.745221
            ],
            [
              -113.173073,
              46.754023
            ],
            [
              -113.211294,
              46.779266
            ],
            [
              -113.228169,
              46.783247
            ],
            [
              -113.242508,
              46.801437
            ],
            [
              -113.241937,
              46.813826
            ],
            [
              -113.26578,
              46.825982
            ],
            [
              -113.275771,
              46.812773
            ],
            [
              -113.291406,
              46.806932
            ],
            [
              -113.291498,
              46.832198
            ],
            [
              -113.302903,
              46.832159
            ],
            [
              -113.416599,
              46.832113
            ],
            [
              -113.417085,
              46.802495
            ],
            [
              -113.479832,
              46.802772
            ],
            [
              -113.479856,
              46.744714
            ],
            [
              -113.545081,
              46.744623
            ],
            [
              -113.551523,
              46.72796
            ],
            [
              -113.55153,
              46.716508
            ],
            [
              -113.668166,
              46.716229
            ],
            [
              -113.667996,
              46.658372
            ],
            [
              -113.793557,
              46.658166
            ],
            [
              -113.827743,
              46.660896
            ],
            [
              -113.81765,
              46.637306
            ],
            [
              -113.803224,
              46.629914
            ],
            [
              -113.816393,
              46.614812
            ],
            [
              -113.811703,
              46.58907
            ],
            [
              -113.835256,
              46.584031
            ],
            [
              -113.828715,
              46.570718
            ],
            [
              -113.845183,
              46.54658
            ],
            [
              -113.848976,
              46.516744
            ],
            [
              -113.878358,
              46.503199
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "009",
      "COUNTYNS": "01720111",
      "AFFGEOID": "0500000US30009",
      "GEOID": "30009",
      "NAME": "Carbon",
      "LSAD": "06",
      "ALAND": 5303530750,
      "AWATER": 35410727,
      "County_state": "Carbon,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -109.798673,
              45.167339
            ],
            [
              -109.79848479260801,
              45.002185541820594
            ],
            [
              -109.75073,
              45.001605
            ],
            [
              -109.663673,
              45.002536
            ],
            [
              -109.574321,
              45.002631
            ],
            [
              -109.386432,
              45.004887
            ],
            [
              -109.375713,
              45.00461
            ],
            [
              -109.269294,
              45.005283
            ],
            [
              -109.263431,
              45.005345
            ],
            [
              -109.103445,
              45.005904
            ],
            [
              -109.08301,
              44.99961
            ],
            [
              -109.062262,
              44.999623
            ],
            [
              -108.95023735661601,
              44.999822374957
            ],
            [
              -108.683057040733,
              45.000297886972795
            ],
            [
              -108.62131508324201,
              45.0004077717245
            ],
            [
              -108.578484,
              45.000484
            ],
            [
              -108.565921,
              45.000578
            ],
            [
              -108.500679,
              44.999691
            ],
            [
              -108.271201,
              45.000251
            ],
            [
              -108.24942807283101,
              44.999461014126794
            ],
            [
              -108.24676,
              45.001714
            ],
            [
              -108.245852,
              45.004317
            ],
            [
              -108.249111,
              45.006815
            ],
            [
              -108.2537,
              45.006496
            ],
            [
              -108.257616,
              45.006703
            ],
            [
              -108.256679,
              45.01051
            ],
            [
              -108.253289,
              45.013375
            ],
            [
              -108.253321,
              45.016581
            ],
            [
              -108.253732,
              45.019152
            ],
            [
              -108.25067,
              45.019859
            ],
            [
              -108.247857,
              45.022811
            ],
            [
              -108.249714,
              45.025798
            ],
            [
              -108.24954,
              45.032193
            ],
            [
              -108.245006,
              45.038994
            ],
            [
              -108.240885,
              45.045682
            ],
            [
              -108.234511,
              45.048041
            ],
            [
              -108.228195,
              45.04739
            ],
            [
              -108.224501,
              45.046439
            ],
            [
              -108.221218,
              45.050899
            ],
            [
              -108.226662,
              45.053696
            ],
            [
              -108.233675,
              45.053758
            ],
            [
              -108.239635,
              45.05687
            ],
            [
              -108.239786,
              45.059964
            ],
            [
              -108.237787,
              45.063907
            ],
            [
              -108.233231,
              45.063774
            ],
            [
              -108.227466,
              45.064718
            ],
            [
              -108.229495,
              45.06819
            ],
            [
              -108.224335,
              45.076003
            ],
            [
              -108.220364,
              45.082917
            ],
            [
              -108.219278,
              45.090275
            ],
            [
              -108.214075,
              45.094817
            ],
            [
              -108.211427,
              45.093851
            ],
            [
              -108.206399,
              45.096415
            ],
            [
              -108.204955,
              45.10153
            ],
            [
              -108.199259,
              45.103186
            ],
            [
              -108.199594,
              45.101224
            ],
            [
              -108.19482,
              45.098399
            ],
            [
              -108.188627,
              45.100302
            ],
            [
              -108.187091,
              45.104233
            ],
            [
              -108.190578,
              45.10941
            ],
            [
              -108.194666,
              45.110933
            ],
            [
              -108.193562,
              45.114454
            ],
            [
              -108.189048,
              45.112464
            ],
            [
              -108.183771,
              45.11529
            ],
            [
              -108.184241,
              45.118435
            ],
            [
              -108.181986,
              45.123147
            ],
            [
              -108.184311,
              45.127209
            ],
            [
              -108.186978,
              45.128133
            ],
            [
              -108.181654,
              45.132209
            ],
            [
              -108.173887,
              45.131763
            ],
            [
              -108.166194,
              45.136024
            ],
            [
              -108.163715,
              45.138541
            ],
            [
              -108.164377,
              45.141817
            ],
            [
              -108.167505,
              45.143205
            ],
            [
              -108.171395,
              45.14582
            ],
            [
              -108.169206,
              45.148518
            ],
            [
              -108.172049,
              45.154603
            ],
            [
              -108.170861,
              45.160531
            ],
            [
              -108.167106,
              45.161788
            ],
            [
              -108.162484,
              45.161851
            ],
            [
              -108.163025,
              45.165811
            ],
            [
              -108.167389,
              45.16773
            ],
            [
              -108.167845,
              45.171086
            ],
            [
              -108.164534,
              45.172018
            ],
            [
              -108.15991,
              45.172184
            ],
            [
              -108.157724,
              45.169934
            ],
            [
              -108.152158,
              45.168641
            ],
            [
              -108.151318,
              45.171764
            ],
            [
              -108.148849,
              45.17943
            ],
            [
              -108.149107,
              45.183179
            ],
            [
              -108.143778,
              45.185332
            ],
            [
              -108.139037,
              45.182209
            ],
            [
              -108.133303,
              45.181755
            ],
            [
              -108.132418,
              45.185724
            ],
            [
              -108.136961,
              45.186928
            ],
            [
              -108.141257,
              45.191201
            ],
            [
              -108.142999,
              45.19409
            ],
            [
              -108.139952,
              45.194083
            ],
            [
              -108.133176,
              45.197337
            ],
            [
              -108.135358,
              45.199443
            ],
            [
              -108.132551,
              45.207084
            ],
            [
              -108.127807,
              45.209371
            ],
            [
              -108.129029,
              45.214484
            ],
            [
              -108.129786,
              45.216141
            ],
            [
              -108.122184,
              45.216317
            ],
            [
              -108.11322,
              45.216095
            ],
            [
              -108.104319,
              45.218699
            ],
            [
              -108.104615,
              45.220513
            ],
            [
              -108.128334,
              45.220486
            ],
            [
              -108.172366,
              45.220501
            ],
            [
              -108.229324,
              45.220325
            ],
            [
              -108.290414,
              45.220182
            ],
            [
              -108.323143,
              45.220033
            ],
            [
              -108.365922,
              45.219883
            ],
            [
              -108.417518,
              45.219156
            ],
            [
              -108.464173,
              45.218536
            ],
            [
              -108.503195,
              45.218113
            ],
            [
              -108.53279,
              45.217913
            ],
            [
              -108.550173,
              45.217779
            ],
            [
              -108.600603,
              45.217405
            ],
            [
              -108.659575,
              45.217561
            ],
            [
              -108.683988,
              45.217653
            ],
            [
              -108.69054,
              45.217683
            ],
            [
              -108.699576,
              45.226162
            ],
            [
              -108.69953,
              45.233662
            ],
            [
              -108.699343,
              45.28862
            ],
            [
              -108.699444,
              45.329791
            ],
            [
              -108.697229,
              45.355993
            ],
            [
              -108.691612,
              45.37392
            ],
            [
              -108.686658,
              45.389018
            ],
            [
              -108.676089,
              45.438264
            ],
            [
              -108.651432,
              45.458942
            ],
            [
              -108.645325,
              45.464073
            ],
            [
              -108.698102,
              45.464072
            ],
            [
              -108.699283,
              45.522629
            ],
            [
              -108.760308,
              45.522831
            ],
            [
              -108.760831,
              45.55183
            ],
            [
              -108.801761,
              45.551965
            ],
            [
              -108.802373,
              45.587518
            ],
            [
              -108.781945,
              45.615657
            ],
            [
              -108.782,
              45.638639
            ],
            [
              -108.798523,
              45.634892
            ],
            [
              -108.843016,
              45.611042
            ],
            [
              -108.860998,
              45.613514
            ],
            [
              -108.89137,
              45.602532
            ],
            [
              -108.933844,
              45.602855
            ],
            [
              -108.97281,
              45.616554
            ],
            [
              -109.028807,
              45.601347
            ],
            [
              -109.03975,
              45.590937
            ],
            [
              -109.068498,
              45.583241
            ],
            [
              -109.127757,
              45.587567
            ],
            [
              -109.128225,
              45.523025
            ],
            [
              -109.191551,
              45.523095
            ],
            [
              -109.191761,
              45.464504
            ],
            [
              -109.356417,
              45.464326
            ],
            [
              -109.356454,
              45.449843
            ],
            [
              -109.418352,
              45.449762
            ],
            [
              -109.41831,
              45.435298
            ],
            [
              -109.438586,
              45.435272
            ],
            [
              -109.438524,
              45.420771
            ],
            [
              -109.479697,
              45.420834
            ],
            [
              -109.479707,
              45.40636
            ],
            [
              -109.500318,
              45.406327
            ],
            [
              -109.500319,
              45.391845
            ],
            [
              -109.520884,
              45.391852
            ],
            [
              -109.520873,
              45.377362
            ],
            [
              -109.54139,
              45.377353
            ],
            [
              -109.541381,
              45.362873
            ],
            [
              -109.561924,
              45.362843
            ],
            [
              -109.567657,
              45.333898
            ],
            [
              -109.588222,
              45.333905
            ],
            [
              -109.588185,
              45.304874
            ],
            [
              -109.608831,
              45.304832
            ],
            [
              -109.608504,
              45.261445
            ],
            [
              -109.687857,
              45.26144
            ],
            [
              -109.687792,
              45.167339
            ],
            [
              -109.798673,
              45.167339
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "30",
      "COUNTYFP": "013",
      "COUNTYNS": "01719622",
      "AFFGEOID": "0500000US30013",
      "GEOID": "30013",
      "NAME": "Cascade",
      "LSAD": "06",
      "ALAND": 6988237777,
      "AWATER": 34396942,
      "County_state": "Cascade,30"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -112.047781,
              47.516034
            ],
            [
              -112.044311,
              47.192826
            ],
            [
              -112.026356,
              47.19926
            ],
            [
              -112.008461,
              47.190763
            ],
            [
              -112.01016,
              47.17921
            ],
            [
              -111.996733,
              47.185456
            ],
            [
              -111.977382,
              47.183932
            ],
            [
              -111.979252,
              47.173743
            ],
            [
              -111.961708,
              47.169033
            ],
            [
              -111.942139,
              47.143582
            ],
            [
              -111.920137,
              47.135693
            ],
            [
              -111.896286,
              47.135741
            ],
            [
              -111.896343,
              47.123236
            ],
            [
              -111.890969,
              47.121302
            ],
            [
              -111.87488,
              47.128655
            ],
            [
              -111.789278,
              47.128922
            ],
            [
              -111.790236,
              46.913764
            ],
            [
              -111.658132,
              46.913423
            ],
            [
              -111.539214,
              46.913371
            ],
            [
              -111.539373,
              47.000559
            ],
            [
              -111.285268,
              47.001026
            ],
            [
              -111.285208,
              47.011746
            ],
            [
              -111.158386,
              47.011771
            ],
            [
              -111.158559,
              47.088301
            ],
            [
              -111.080723,
              47.087868
            ],
            [
              -111.083856,
              47.072528
            ],
            [
              -111.076802,
              47.045283
            ],
            [
              -111.104792,
              47.029036
            ],
            [
              -111.072278,
              47.005663
            ],
            [
              -111.055003,
              47.00907
            ],
            [
              -110.996401,
              47.005093
            ],
            [
              -110.913035,
              46.980559
            ],
            [
              -110.891441,
              46.965719
            ],
            [
              -110.870649,
              46.944535
            ],
            [
              -110.85901,
              46.94606
            ],
            [
              -110.856038,
              46.921347
            ],
            [
              -110.825002,
              46.914867
            ],
            [
              -110.804985,
              46.901864
            ],
            [
              -110.790205,
              46.910109
            ],
            [
              -110.783399,
              46.901712
            ],
            [
              -110.752715,
              46.890184
            ],
            [
              -110.76234,
              46.88258
            ],
            [
              -110.752108,
              46.859553
            ],
            [
              -110.751586,
              46.844531
            ],
            [
              -110.735911,
              46.848474
            ],
            [
              -110.716737,
              46.838176
            ],
            [
              -110.697417,
              46.84496
            ],
            [
              -110.678922,
              46.824774
            ],
            [
              -110.662034,
              46.818042
            ],
            [
              -110.652877,
              46.823626
            ],
            [
              -110.652909,
              46.837162
            ],
            [
              -110.636381,
              46.837161
            ],
            [
              -110.640951,
              46.923934
            ],
            [
              -110.645109,
              47.010685
            ],
            [
              -110.645212,
              47.09587
            ],
            [
              -110.770698,
              47.096546
            ],
            [
              -110.76694,
              47.183872
            ],
            [
              -110.766593,
              47.234539
            ],
            [
              -110.777426,
              47.234576
            ],
            [
              -110.777326,
              47.249098
            ],
            [
              -110.788068,
              47.249133
            ],
            [
              -110.788302,
              47.307848
            ],
            [
              -110.772313,
              47.307914
            ],
            [
              -110.767032,
              47.297065
            ],
            [
              -110.767125,
              47.359128
            ],
            [
              -110.638911,
              47.359032
            ],
            [
              -110.639559,
              47.416486
            ],
            [
              -110.640599,
              47.446306
            ],
            [
              -110.769129,
              47.446096
            ],
            [
              -110.768465,
              47.525638
            ],
            [
              -110.863942,
              47.52506
            ],
            [
              -110.879804,
              47.547275
            ],
            [
              -110.890253,
              47.552288
            ],
            [
              -110.907845,
              47.542271
            ],
            [
              -110.937684,
              47.547337
            ],
            [
              -110.965297,
              47.563821
            ],
            [
              -110.969772,
              47.571639
            ],
            [
              -111.002503,
              47.5724
            ],
            [
              -111.030432,
              47.58233
            ],
            [
              -111.049847,
              47.598333
            ],
            [
              -111.037007,
              47.626398
            ],
            [
              -111.016482,
              47.655018
            ],
            [
              -111.005448,
              47.65864
            ],
            [
              -110.994822,
              47.679218
            ],
            [
              -110.983667,
              47.681897
            ],
            [
              -110.976171,
              47.698738
            ],
            [
              -111.40885,
              47.698123
            ],
            [
              -111.409103,
              47.698123
            ],
            [
              -111.645592,
              47.697842
            ],
            [
              -111.666482,
              47.697761
            ],
            [
              -111.66626,
              47.611253
            ],
            [
              -111.922567,
              47.611259
            ],
            [
              -111.922795,
              47.505064
            ],
            [
              -111.962236,
              47.504089
            ],
            [
              -111.99944,
              47.507289
            ],
            [
              -112.026416,
              47.517836
            ],
            [
              -112.047781,
              47.516034
            ]
          ]
        ]
      ]
    }
  }
]
};