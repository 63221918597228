export const counties_mp = {
  "type":"FeatureCollection","name":"mp_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "69",
      "COUNTYFP": "110",
      "COUNTYNS": "01805247",
      "AFFGEOID": "0500000US69110",
      "GEOID": "69110",
      "NAME": "Saipan",
      "LSAD": "12",
      "ALAND": 118890998,
      "AWATER": 532767365,
      "County_state": "Saipan,69"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              145.686765,
              15.13239
            ],
            [
              145.689822,
              15.120805
            ],
            [
              145.707024,
              15.106965
            ],
            [
              145.732469,
              15.106236
            ],
            [
              145.748811,
              15.091922
            ],
            [
              145.756972,
              15.120632
            ],
            [
              145.744288,
              15.13066
            ],
            [
              145.748835,
              15.154967
            ],
            [
              145.770721,
              15.154203
            ],
            [
              145.779905,
              15.150867
            ],
            [
              145.793825,
              15.148329
            ],
            [
              145.792118,
              15.157246
            ],
            [
              145.79748,
              15.166941
            ],
            [
              145.78342,
              15.183164
            ],
            [
              145.782241,
              15.202927
            ],
            [
              145.799264,
              15.229936
            ],
            [
              145.809096,
              15.23942
            ],
            [
              145.808139,
              15.246902
            ],
            [
              145.826387,
              15.25956
            ],
            [
              145.830229,
              15.271378
            ],
            [
              145.820562,
              15.287977
            ],
            [
              145.811245,
              15.29012
            ],
            [
              145.771667,
              15.250873
            ],
            [
              145.739903,
              15.232597
            ],
            [
              145.710505,
              15.242859
            ],
            [
              145.710603,
              15.218232
            ],
            [
              145.700142,
              15.156617
            ],
            [
              145.686765,
              15.13239
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "69",
      "COUNTYFP": "120",
      "COUNTYNS": "01805248",
      "AFFGEOID": "0500000US69120",
      "GEOID": "69120",
      "NAME": "Tinian",
      "LSAD": "12",
      "ALAND": 108237606,
      "AWATER": 632893009,
      "County_state": "Tinian,69"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              145.582033,
              15.015924
            ],
            [
              145.582807,
              14.993032
            ],
            [
              145.597438,
              14.990146
            ],
            [
              145.612242,
              14.969413
            ],
            [
              145.628465,
              14.940506
            ],
            [
              145.635249,
              14.92271
            ],
            [
              145.645278,
              14.927232
            ],
            [
              145.664316,
              14.947544
            ],
            [
              145.665572,
              14.972651
            ],
            [
              145.672317,
              15.000384
            ],
            [
              145.668285,
              15.016214
            ],
            [
              145.648621,
              15.033912
            ],
            [
              145.660973,
              15.055345
            ],
            [
              145.656978,
              15.084056
            ],
            [
              145.64518,
              15.101164
            ],
            [
              145.626597,
              15.083859
            ],
            [
              145.598162,
              15.054934
            ],
            [
              145.582033,
              15.015924
            ]
          ]
        ],
        [
          [
            [
              145.544343,
              14.858757
            ],
            [
              145.534417,
              14.845565
            ],
            [
              145.545481,
              14.840414
            ],
            [
              145.562786,
              14.843855
            ],
            [
              145.575362,
              14.849163
            ],
            [
              145.578631,
              14.860024
            ],
            [
              145.572913,
              14.866469
            ],
            [
              145.544343,
              14.858757
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "69",
      "COUNTYFP": "085",
      "COUNTYNS": "01805245",
      "AFFGEOID": "0500000US69085",
      "GEOID": "69085",
      "NAME": "Northern Islands",
      "LSAD": "12",
      "ALAND": 160057011,
      "AWATER": 2939509407,
      "County_state": "Northern Islands,69"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              146.033197,
              16.004517
            ],
            [
              146.035679,
              15.989012
            ],
            [
              146.053049,
              15.989012
            ],
            [
              146.081586,
              16.027176
            ],
            [
              146.067938,
              16.041486
            ],
            [
              146.05429,
              16.033139
            ],
            [
              146.033197,
              16.004517
            ]
          ]
        ],
        [
          [
            [
              145.832722,
              17.316338
            ],
            [
              145.831696,
              17.306524
            ],
            [
              145.844675,
              17.296594
            ],
            [
              145.855608,
              17.309129
            ],
            [
              145.847723,
              17.319011
            ],
            [
              145.839491,
              17.321295
            ],
            [
              145.832722,
              17.316338
            ]
          ]
        ],
        [
          [
            [
              145.814982,
              17.604046
            ],
            [
              145.815768,
              17.591756
            ],
            [
              145.826787,
              17.579716
            ],
            [
              145.837898,
              17.579086
            ],
            [
              145.851498,
              17.593139
            ],
            [
              145.848116,
              17.608668
            ],
            [
              145.834941,
              17.620761
            ],
            [
              145.822356,
              17.618008
            ],
            [
              145.814982,
              17.604046
            ]
          ]
        ],
        [
          [
            [
              145.767394,
              16.707646
            ],
            [
              145.768855,
              16.696584
            ],
            [
              145.784306,
              16.690353
            ],
            [
              145.791435,
              16.701137
            ],
            [
              145.790247,
              16.711381
            ],
            [
              145.778363,
              16.720487
            ],
            [
              145.767394,
              16.707646
            ]
          ]
        ],
        [
          [
            [
              145.710624,
              18.068836
            ],
            [
              145.709974,
              18.044529
            ],
            [
              145.729245,
              18.049838
            ],
            [
              145.746276,
              18.063187
            ],
            [
              145.752218,
              18.075615
            ],
            [
              145.767667,
              18.097079
            ],
            [
              145.784895,
              18.109224
            ],
            [
              145.7983,
              18.108421
            ],
            [
              145.810571,
              18.135674
            ],
            [
              145.806526,
              18.157107
            ],
            [
              145.797874,
              18.165071
            ],
            [
              145.77408,
              18.168807
            ],
            [
              145.760537,
              18.164842
            ],
            [
              145.755197,
              18.149467
            ],
            [
              145.762085,
              18.137639
            ],
            [
              145.755497,
              18.126173
            ],
            [
              145.758053,
              18.113649
            ],
            [
              145.743899,
              18.100468
            ],
            [
              145.730827,
              18.089171
            ],
            [
              145.718929,
              18.078092
            ],
            [
              145.710624,
              18.068836
            ]
          ]
        ],
        [
          [
            [
              145.636331,
              18.778797
            ],
            [
              145.640508,
              18.746515
            ],
            [
              145.667302,
              18.725113
            ],
            [
              145.68559,
              18.737501
            ],
            [
              145.692762,
              18.744911
            ],
            [
              145.69955,
              18.760787
            ],
            [
              145.696479,
              18.788546
            ],
            [
              145.679949,
              18.805672
            ],
            [
              145.656978,
              18.812816
            ],
            [
              145.641542,
              18.794823
            ],
            [
              145.636331,
              18.778797
            ]
          ]
        ],
        [
          [
            [
              145.63348,
              16.367997
            ],
            [
              145.632988,
              16.351006
            ],
            [
              145.646469,
              16.331909
            ],
            [
              145.678314,
              16.333924
            ],
            [
              145.702698,
              16.333629
            ],
            [
              145.71489,
              16.338152
            ],
            [
              145.724252,
              16.347704
            ],
            [
              145.722651,
              16.360143
            ],
            [
              145.703519,
              16.369656
            ],
            [
              145.687655,
              16.368337
            ],
            [
              145.644814,
              16.369488
            ],
            [
              145.63348,
              16.367997
            ]
          ]
        ],
        [
          [
            [
              145.38964,
              19.69691
            ],
            [
              145.390133,
              19.682375
            ],
            [
              145.403505,
              19.675591
            ],
            [
              145.413632,
              19.681588
            ],
            [
              145.418056,
              19.691224
            ],
            [
              145.412845,
              19.703416
            ],
            [
              145.398785,
              19.706365
            ],
            [
              145.38964,
              19.69691
            ]
          ]
        ],
        [
          [
            [
              145.210161,
              20.030317
            ],
            [
              145.207239,
              20.022601
            ],
            [
              145.214212,
              20.010747
            ],
            [
              145.228146,
              20.011368
            ],
            [
              145.238815,
              20.020457
            ],
            [
              145.229967,
              20.032092
            ],
            [
              145.219378,
              20.035071
            ],
            [
              145.210161,
              20.030317
            ]
          ]
        ],
        [
          [
            [
              144.884687,
              20.551687
            ],
            [
              144.886331,
              20.539939
            ],
            [
              144.895382,
              20.533882
            ],
            [
              144.906078,
              20.542785
            ],
            [
              144.903701,
              20.5528
            ],
            [
              144.893006,
              20.557251
            ],
            [
              144.884687,
              20.551687
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "69",
      "COUNTYFP": "100",
      "COUNTYNS": "01805246",
      "AFFGEOID": "0500000US69100",
      "GEOID": "69100",
      "NAME": "Rota",
      "LSAD": "12",
      "ALAND": 85098741,
      "AWATER": 539090851,
      "County_state": "Rota,69"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              145.118768,
              14.124787
            ],
            [
              145.122758,
              14.117438
            ],
            [
              145.136456,
              14.118789
            ],
            [
              145.152452,
              14.130579
            ],
            [
              145.16269,
              14.127476
            ],
            [
              145.169892,
              14.110571
            ],
            [
              145.210223,
              14.116141
            ],
            [
              145.23032,
              14.132667
            ],
            [
              145.239319,
              14.149728
            ],
            [
              145.269885,
              14.152161
            ],
            [
              145.28335,
              14.160675
            ],
            [
              145.284338,
              14.175365
            ],
            [
              145.289473,
              14.190939
            ],
            [
              145.254055,
              14.19965
            ],
            [
              145.235669,
              14.198274
            ],
            [
              145.203032,
              14.177108
            ],
            [
              145.161141,
              14.168482
            ],
            [
              145.118768,
              14.124787
            ]
          ]
        ]
      ]
    }
  }
]
};