export const counties_sc = {
  "type":"FeatureCollection","name":"sc_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "045",
      "COUNTYNS": "01245489",
      "AFFGEOID": "0500000US45045",
      "GEOID": "45045",
      "NAME": "Greenville",
      "LSAD": "06",
      "ALAND": 2033687076,
      "AWATER": 25132346,
      "County_state": "Greenville,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.757704,
              35.068019
            ],
            [
              -82.764464,
              35.068177
            ],
            [
              -82.749351,
              35.047829
            ],
            [
              -82.716028,
              35.054915
            ],
            [
              -82.676662,
              35.064745
            ],
            [
              -82.641153,
              35.060291
            ],
            [
              -82.588598,
              35.073973
            ],
            [
              -82.571505,
              35.074636
            ],
            [
              -82.574886,
              35.064128
            ],
            [
              -82.592316,
              35.060129
            ],
            [
              -82.598838,
              35.040887
            ],
            [
              -82.585752,
              35.032339
            ],
            [
              -82.594141,
              35.008205
            ],
            [
              -82.537866,
              35.015696
            ],
            [
              -82.543132,
              35.004391
            ],
            [
              -82.533627,
              34.986083
            ],
            [
              -82.547818,
              34.978343
            ],
            [
              -82.527411,
              34.965909
            ],
            [
              -82.525784,
              34.952584
            ],
            [
              -82.520755,
              34.926954
            ],
            [
              -82.515959,
              34.925915
            ],
            [
              -82.493784,
              34.895217
            ],
            [
              -82.496251,
              34.889023
            ],
            [
              -82.490178,
              34.885111
            ],
            [
              -82.492534,
              34.879
            ],
            [
              -82.487511,
              34.874901
            ],
            [
              -82.488936,
              34.867665
            ],
            [
              -82.487143,
              34.86514
            ],
            [
              -82.489112,
              34.860869
            ],
            [
              -82.483559,
              34.858693
            ],
            [
              -82.484345,
              34.856194
            ],
            [
              -82.484614,
              34.851675
            ],
            [
              -82.489136,
              34.841145
            ],
            [
              -82.480333,
              34.840544
            ],
            [
              -82.487188,
              34.832122
            ],
            [
              -82.48053,
              34.824014
            ],
            [
              -82.486746,
              34.819475
            ],
            [
              -82.4607,
              34.796029
            ],
            [
              -82.45837,
              34.7792
            ],
            [
              -82.452015,
              34.776428
            ],
            [
              -82.454632,
              34.764676
            ],
            [
              -82.455144,
              34.750683
            ],
            [
              -82.463658,
              34.74456
            ],
            [
              -82.465008,
              34.705399
            ],
            [
              -82.456701,
              34.680259
            ],
            [
              -82.467807,
              34.666897
            ],
            [
              -82.4619,
              34.655373
            ],
            [
              -82.456289,
              34.657497
            ],
            [
              -82.453789,
              34.625114
            ],
            [
              -82.428386,
              34.597192
            ],
            [
              -82.417293,
              34.55345
            ],
            [
              -82.386073,
              34.538942
            ],
            [
              -82.364486,
              34.515965
            ],
            [
              -82.313996,
              34.484002
            ],
            [
              -82.236125,
              34.53583
            ],
            [
              -82.238054,
              34.586956
            ],
            [
              -82.211365,
              34.646742
            ],
            [
              -82.197489,
              34.676502
            ],
            [
              -82.180201,
              34.712958
            ],
            [
              -82.177954,
              34.718787
            ],
            [
              -82.163787,
              34.748324
            ],
            [
              -82.146792,
              34.785554
            ],
            [
              -82.151569,
              34.781895
            ],
            [
              -82.165093,
              34.796587
            ],
            [
              -82.162487,
              34.802387
            ],
            [
              -82.162545,
              34.802797
            ],
            [
              -82.163748,
              34.804058
            ],
            [
              -82.164675,
              34.805562
            ],
            [
              -82.164583,
              34.806261
            ],
            [
              -82.164619,
              34.806513
            ],
            [
              -82.165101,
              34.806948
            ],
            [
              -82.165598,
              34.806998
            ],
            [
              -82.166086,
              34.80693
            ],
            [
              -82.167009,
              34.806682
            ],
            [
              -82.1694,
              34.80584
            ],
            [
              -82.170061,
              34.805735
            ],
            [
              -82.170827,
              34.805495
            ],
            [
              -82.171374,
              34.805423
            ],
            [
              -82.173141,
              34.805551
            ],
            [
              -82.173309,
              34.805727
            ],
            [
              -82.173374,
              34.806808
            ],
            [
              -82.173099,
              34.807654
            ],
            [
              -82.173159,
              34.809242
            ],
            [
              -82.173644,
              34.810309
            ],
            [
              -82.174272,
              34.811327
            ],
            [
              -82.174689,
              34.812074
            ],
            [
              -82.175309,
              34.812753
            ],
            [
              -82.175863,
              34.813131
            ],
            [
              -82.176602,
              34.814195
            ],
            [
              -82.177286,
              34.815354
            ],
            [
              -82.177772,
              34.816091
            ],
            [
              -82.178353,
              34.816614
            ],
            [
              -82.178816,
              34.816928
            ],
            [
              -82.18025,
              34.818632
            ],
            [
              -82.180826,
              34.819258
            ],
            [
              -82.18193,
              34.820234
            ],
            [
              -82.183069,
              34.820807
            ],
            [
              -82.18342,
              34.82104
            ],
            [
              -82.185019,
              34.822651
            ],
            [
              -82.188765,
              34.82671
            ],
            [
              -82.19035,
              34.827996
            ],
            [
              -82.190575,
              34.8285
            ],
            [
              -82.19057,
              34.829239
            ],
            [
              -82.190908,
              34.829744
            ],
            [
              -82.192013,
              34.830484
            ],
            [
              -82.192398,
              34.830845
            ],
            [
              -82.193042,
              34.831624
            ],
            [
              -82.193716,
              34.832138
            ],
            [
              -82.194172,
              34.833197
            ],
            [
              -82.194539,
              34.833704
            ],
            [
              -82.195483,
              34.834619
            ],
            [
              -82.19598,
              34.835267
            ],
            [
              -82.197747,
              34.836316
            ],
            [
              -82.198088,
              34.836474
            ],
            [
              -82.198952,
              34.837197
            ],
            [
              -82.199882,
              34.837808
            ],
            [
              -82.200486,
              34.838027
            ],
            [
              -82.201366,
              34.838221
            ],
            [
              -82.202229,
              34.838103
            ],
            [
              -82.20281,
              34.837865
            ],
            [
              -82.203046,
              34.837851
            ],
            [
              -82.203454,
              34.838069
            ],
            [
              -82.203782,
              34.838554
            ],
            [
              -82.204932,
              34.839427
            ],
            [
              -82.205795,
              34.839983
            ],
            [
              -82.20664,
              34.840405
            ],
            [
              -82.207307,
              34.840876
            ],
            [
              -82.207756,
              34.841293
            ],
            [
              -82.208343,
              34.84154
            ],
            [
              -82.209354,
              34.841634
            ],
            [
              -82.209969,
              34.841967
            ],
            [
              -82.210447,
              34.842527
            ],
            [
              -82.210796,
              34.843373
            ],
            [
              -82.210815,
              34.84377
            ],
            [
              -82.221885,
              34.853879
            ],
            [
              -82.226538,
              34.850531
            ],
            [
              -82.22527,
              34.871724
            ],
            [
              -82.22402,
              34.90314
            ],
            [
              -82.223555,
              34.912969
            ],
            [
              -82.221865,
              34.947992
            ],
            [
              -82.221853,
              34.948166
            ],
            [
              -82.219765,
              35.037805
            ],
            [
              -82.216217,
              35.196044
            ],
            [
              -82.230517,
              35.196764
            ],
            [
              -82.230915,
              35.196784
            ],
            [
              -82.257515,
              35.198636
            ],
            [
              -82.27492,
              35.200071
            ],
            [
              -82.280858603372,
              35.199904475181896
            ],
            [
              -82.282516,
              35.199858
            ],
            [
              -82.288453,
              35.198605
            ],
            [
              -82.295354,
              35.194965
            ],
            [
              -82.307166,
              35.193012
            ],
            [
              -82.314863,
              35.191089
            ],
            [
              -82.315871,
              35.190678
            ],
            [
              -82.317871,
              35.187858
            ],
            [
              -82.32335,
              35.184789
            ],
            [
              -82.326917,
              35.185056
            ],
            [
              -82.330549,
              35.186767
            ],
            [
              -82.330779,
              35.189032
            ],
            [
              -82.332975,
              35.190645
            ],
            [
              -82.333934,
              35.190661
            ],
            [
              -82.338013,
              35.18901
            ],
            [
              -82.339508,
              35.18893
            ],
            [
              -82.340133,
              35.189188
            ],
            [
              -82.341194,
              35.19151
            ],
            [
              -82.344554,
              35.193115
            ],
            [
              -82.350086,
              35.192858
            ],
            [
              -82.35365431473379,
              35.1922225810449
            ],
            [
              -82.361469,
              35.190831
            ],
            [
              -82.363256,
              35.189639
            ],
            [
              -82.363554,
              35.188001
            ],
            [
              -82.363479,
              35.186214
            ],
            [
              -82.364299,
              35.184725
            ],
            [
              -82.36899,
              35.181747
            ],
            [
              -82.371298,
              35.181449
            ],
            [
              -82.373218,
              35.182201
            ],
            [
              -82.376808,
              35.184427
            ],
            [
              -82.379712,
              35.186884
            ],
            [
              -82.380903,
              35.189565
            ],
            [
              -82.381201,
              35.191203
            ],
            [
              -82.380605,
              35.193586
            ],
            [
              -82.379191,
              35.195894
            ],
            [
              -82.378744,
              35.198053
            ],
            [
              -82.380524,
              35.202276
            ],
            [
              -82.383776,
              35.207646
            ],
            [
              -82.384029,
              35.210542
            ],
            [
              -82.390439,
              35.215395
            ],
            [
              -82.39293,
              35.215402
            ],
            [
              -82.395697,
              35.213214
            ],
            [
              -82.403348,
              35.204473
            ],
            [
              -82.411301,
              35.202483
            ],
            [
              -82.417597,
              35.200131
            ],
            [
              -82.419744,
              35.198613
            ],
            [
              -82.424461,
              35.193092
            ],
            [
              -82.428,
              35.183224
            ],
            [
              -82.434126,
              35.170784
            ],
            [
              -82.435689,
              35.167715
            ],
            [
              -82.439595,
              35.165863
            ],
            [
              -82.448969,
              35.165037
            ],
            [
              -82.451201,
              35.16526
            ],
            [
              -82.452931,
              35.168999
            ],
            [
              -82.452764,
              35.172626
            ],
            [
              -82.452987,
              35.17469
            ],
            [
              -82.455609,
              35.177425
            ],
            [
              -82.460092,
              35.178143
            ],
            [
              -82.467991,
              35.174633
            ],
            [
              -82.472313,
              35.174619
            ],
            [
              -82.476136,
              35.175486
            ],
            [
              -82.483937,
              35.173798
            ],
            [
              -82.487357,
              35.172494
            ],
            [
              -82.490766,
              35.169715
            ],
            [
              -82.495506,
              35.164312
            ],
            [
              -82.499843,
              35.163675
            ],
            [
              -82.506137,
              35.163894
            ],
            [
              -82.516044,
              35.163442
            ],
            [
              -82.51691,
              35.163029
            ],
            [
              -82.517284,
              35.162643
            ],
            [
              -82.51921,
              35.161044
            ],
            [
              -82.521403,
              35.158851
            ],
            [
              -82.52593,
              35.156749
            ],
            [
              -82.529973,
              35.155617
            ],
            [
              -82.53256,
              35.155617
            ],
            [
              -82.534662,
              35.156911
            ],
            [
              -82.535967,
              35.158664
            ],
            [
              -82.536218,
              35.159259
            ],
            [
              -82.540483,
              35.160306
            ],
            [
              -82.544525,
              35.160306
            ],
            [
              -82.547436,
              35.160306
            ],
            [
              -82.550508,
              35.159498
            ],
            [
              -82.552934,
              35.158042
            ],
            [
              -82.554227,
              35.156911
            ],
            [
              -82.554871,
              35.154639
            ],
            [
              -82.554874,
              35.152868
            ],
            [
              -82.556168,
              35.151736
            ],
            [
              -82.558593,
              35.150928
            ],
            [
              -82.560807,
              35.151644
            ],
            [
              -82.563767,
              35.151575
            ],
            [
              -82.566193,
              35.150119
            ],
            [
              -82.567486,
              35.147694
            ],
            [
              -82.569912,
              35.145268
            ],
            [
              -82.5742297924061,
              35.1436424056196
            ],
            [
              -82.578316,
              35.142104
            ],
            [
              -82.580687,
              35.141742
            ],
            [
              -82.581836,
              35.142352
            ],
            [
              -82.586035,
              35.143142
            ],
            [
              -82.588158,
              35.142928
            ],
            [
              -82.59243,
              35.139002
            ],
            [
              -82.59814,
              35.137729
            ],
            [
              -82.602358,
              35.139036
            ],
            [
              -82.609706,
              35.139039
            ],
            [
              -82.612444,
              35.138234
            ],
            [
              -82.613866,
              35.137529
            ],
            [
              -82.614402,
              35.136701
            ],
            [
              -82.617993,
              35.13527
            ],
            [
              -82.621185,
              35.134635
            ],
            [
              -82.624847,
              35.130432
            ],
            [
              -82.626436,
              35.127903
            ],
            [
              -82.629031,
              35.126155
            ],
            [
              -82.632574,
              35.125833
            ],
            [
              -82.634668,
              35.126317
            ],
            [
              -82.63821,
              35.128088
            ],
            [
              -82.642237,
              35.129215
            ],
            [
              -82.645296,
              35.12841
            ],
            [
              -82.648694,
              35.12677
            ],
            [
              -82.651416,
              35.124867
            ],
            [
              -82.65351,
              35.121968
            ],
            [
              -82.657858,
              35.119392
            ],
            [
              -82.662381,
              35.118123
            ],
            [
              -82.669614,
              35.118103
            ],
            [
              -82.672513,
              35.119392
            ],
            [
              -82.673318,
              35.121002
            ],
            [
              -82.675089,
              35.123257
            ],
            [
              -82.676861,
              35.12535
            ],
            [
              -82.680887,
              35.126155
            ],
            [
              -82.683625,
              35.125833
            ],
            [
              -82.68604,
              35.124545
            ],
            [
              -82.686496,
              35.121822
            ],
            [
              -82.686738,
              35.11979
            ],
            [
              -82.687641,
              35.119287
            ],
            [
              -82.688939,
              35.118103
            ],
            [
              -82.690549,
              35.116171
            ],
            [
              -82.691194,
              35.114721
            ],
            [
              -82.691355,
              35.113272
            ],
            [
              -82.690711,
              35.111501
            ],
            [
              -82.688778,
              35.108602
            ],
            [
              -82.688456,
              35.106347
            ],
            [
              -82.689634,
              35.104117
            ],
            [
              -82.694898,
              35.098456
            ],
            [
              -82.698602,
              35.097168
            ],
            [
              -82.701017,
              35.09749
            ],
            [
              -82.703916,
              35.097651
            ],
            [
              -82.707152,
              35.096542
            ],
            [
              -82.715297,
              35.092943
            ],
            [
              -82.720442,
              35.093265
            ],
            [
              -82.723462,
              35.094341
            ],
            [
              -82.72701,
              35.094142
            ],
            [
              -82.728961,
              35.091978
            ],
            [
              -82.729517,
              35.09059
            ],
            [
              -82.729683,
              35.087827
            ],
            [
              -82.730971,
              35.086378
            ],
            [
              -82.735904,
              35.082701
            ],
            [
              -82.738379,
              35.079453
            ],
            [
              -82.741761,
              35.078326
            ],
            [
              -82.746431,
              35.079131
            ],
            [
              -82.749491,
              35.078487
            ],
            [
              -82.751102,
              35.075749
            ],
            [
              -82.751265,
              35.073463
            ],
            [
              -82.754162,
              35.069629
            ],
            [
              -82.757704,
              35.068019
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "009",
      "COUNTYNS": "01247982",
      "AFFGEOID": "0500000US45009",
      "GEOID": "45009",
      "NAME": "Bamberg",
      "LSAD": "06",
      "ALAND": 1018815541,
      "AWATER": 5685855,
      "County_state": "Bamberg,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.220603,
              33.206018
            ],
            [
              -81.227396,
              33.160766
            ],
            [
              -81.215924,
              33.150115
            ],
            [
              -81.206442,
              33.129635
            ],
            [
              -81.193099,
              33.118668
            ],
            [
              -81.148709,
              33.080905
            ],
            [
              -81.124826,
              33.053448
            ],
            [
              -81.101174,
              33.049104
            ],
            [
              -81.086562,
              33.035201
            ],
            [
              -81.082291,
              33.02663
            ],
            [
              -80.937157,
              33.102584
            ],
            [
              -80.906025,
              33.060228
            ],
            [
              -80.896074,
              33.06141
            ],
            [
              -80.884175,
              33.086478
            ],
            [
              -80.895426,
              33.125348
            ],
            [
              -80.797912,
              33.176944
            ],
            [
              -80.8375,
              33.203712
            ],
            [
              -80.846151,
              33.238328
            ],
            [
              -80.887109,
              33.264183
            ],
            [
              -80.890196,
              33.261392
            ],
            [
              -80.892257,
              33.268391
            ],
            [
              -80.918425,
              33.283372
            ],
            [
              -80.940103,
              33.30356
            ],
            [
              -80.959314,
              33.309987
            ],
            [
              -80.985338,
              33.327018
            ],
            [
              -81.031356,
              33.348824
            ],
            [
              -81.05535,
              33.353912
            ],
            [
              -81.08241,
              33.375464
            ],
            [
              -81.117716,
              33.383455
            ],
            [
              -81.157004,
              33.406177
            ],
            [
              -81.189531,
              33.411683
            ],
            [
              -81.206606,
              33.437812
            ],
            [
              -81.222673,
              33.44
            ],
            [
              -81.213182,
              33.397866
            ],
            [
              -81.215167,
              33.366255
            ],
            [
              -81.220824,
              33.259149
            ],
            [
              -81.220603,
              33.206018
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "023",
      "COUNTYNS": "01247988",
      "AFFGEOID": "0500000US45023",
      "GEOID": "45023",
      "NAME": "Chester",
      "LSAD": "06",
      "ALAND": 1503893316,
      "AWATER": 14261786,
      "County_state": "Chester,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.482351,
              34.744398
            ],
            [
              -81.478364,
              34.723688
            ],
            [
              -81.456419,
              34.723817
            ],
            [
              -81.454129,
              34.693681
            ],
            [
              -81.454196,
              34.675187
            ],
            [
              -81.441917,
              34.653727
            ],
            [
              -81.415894,
              34.633589
            ],
            [
              -81.422706,
              34.572029
            ],
            [
              -81.245054,
              34.563822
            ],
            [
              -81.05999,
              34.553244
            ],
            [
              -81.00268,
              34.547877
            ],
            [
              -80.877491,
              34.543146
            ],
            [
              -80.872863,
              34.552965
            ],
            [
              -80.882346,
              34.584744
            ],
            [
              -80.891397,
              34.596787
            ],
            [
              -80.896406,
              34.631781
            ],
            [
              -80.888469,
              34.668603
            ],
            [
              -80.860274,
              34.695847
            ],
            [
              -80.865999,
              34.706169
            ],
            [
              -80.878278,
              34.759078
            ],
            [
              -80.876121,
              34.788047
            ],
            [
              -80.898215,
              34.820746
            ],
            [
              -81.03294,
              34.821022
            ],
            [
              -81.178326,
              34.820906
            ],
            [
              -81.331449,
              34.822269
            ],
            [
              -81.478454,
              34.821509
            ],
            [
              -81.453463,
              34.781782
            ],
            [
              -81.453097,
              34.760903
            ],
            [
              -81.482351,
              34.744398
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "087",
      "COUNTYNS": "01248017",
      "AFFGEOID": "0500000US45087",
      "GEOID": "45087",
      "NAME": "Union",
      "LSAD": "06",
      "ALAND": 1331696509,
      "AWATER": 4805060,
      "County_state": "Union,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.854002,
              34.594549
            ],
            [
              -81.849337,
              34.594639
            ],
            [
              -81.842846,
              34.600635
            ],
            [
              -81.800262,
              34.574811
            ],
            [
              -81.749606,
              34.568344
            ],
            [
              -81.716182,
              34.551449
            ],
            [
              -81.714358,
              34.554045
            ],
            [
              -81.677321,
              34.557972
            ],
            [
              -81.643552,
              34.533652
            ],
            [
              -81.62719,
              34.521685
            ],
            [
              -81.617791,
              34.525249
            ],
            [
              -81.588783,
              34.497012
            ],
            [
              -81.552944,
              34.476874
            ],
            [
              -81.557021,
              34.463921
            ],
            [
              -81.541005,
              34.445034
            ],
            [
              -81.484647,
              34.490003
            ],
            [
              -81.468976,
              34.494052
            ],
            [
              -81.458723,
              34.486973
            ],
            [
              -81.443275,
              34.495599
            ],
            [
              -81.423241,
              34.494379
            ],
            [
              -81.427545,
              34.529482
            ],
            [
              -81.419829,
              34.566588
            ],
            [
              -81.422706,
              34.572029
            ],
            [
              -81.415894,
              34.633589
            ],
            [
              -81.441917,
              34.653727
            ],
            [
              -81.454196,
              34.675187
            ],
            [
              -81.454129,
              34.693681
            ],
            [
              -81.456419,
              34.723817
            ],
            [
              -81.478364,
              34.723688
            ],
            [
              -81.482351,
              34.744398
            ],
            [
              -81.453097,
              34.760903
            ],
            [
              -81.453463,
              34.781782
            ],
            [
              -81.478454,
              34.821509
            ],
            [
              -81.461755,
              34.831018
            ],
            [
              -81.457257,
              34.83928
            ],
            [
              -81.481945,
              34.866495
            ],
            [
              -81.514872,
              34.87713
            ],
            [
              -81.543533,
              34.869886
            ],
            [
              -81.586733,
              34.887313
            ],
            [
              -81.611602,
              34.876146
            ],
            [
              -81.640692,
              34.881344
            ],
            [
              -81.64672,
              34.900151
            ],
            [
              -81.663631,
              34.898679
            ],
            [
              -81.684598,
              34.913027
            ],
            [
              -81.711694,
              34.912577
            ],
            [
              -81.7427,
              34.882151
            ],
            [
              -81.78339,
              34.837125
            ],
            [
              -81.824356,
              34.679917
            ],
            [
              -81.839461,
              34.646626
            ],
            [
              -81.854002,
              34.594549
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "041",
      "COUNTYNS": "01248001",
      "AFFGEOID": "0500000US45041",
      "GEOID": "45041",
      "NAME": "Florence",
      "LSAD": "06",
      "ALAND": 2071893246,
      "AWATER": 9745365,
      "County_state": "Florence,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.072808,
              34.086013
            ],
            [
              -80.026068,
              34.073364
            ],
            [
              -80.000594,
              34.047877
            ],
            [
              -80.000198,
              34.046508
            ],
            [
              -79.98537,
              34.037911
            ],
            [
              -79.974238,
              34.028974
            ],
            [
              -79.903823,
              34.002359
            ],
            [
              -79.893517,
              33.988017
            ],
            [
              -79.974601,
              33.946527
            ],
            [
              -79.945948,
              33.885997
            ],
            [
              -79.902425,
              33.889063
            ],
            [
              -79.87562,
              33.885245
            ],
            [
              -79.866427,
              33.884322
            ],
            [
              -79.854891,
              33.891738
            ],
            [
              -79.847156,
              33.884487
            ],
            [
              -79.834881,
              33.882601
            ],
            [
              -79.79837,
              33.835562
            ],
            [
              -79.743388,
              33.835056
            ],
            [
              -79.724936,
              33.814552
            ],
            [
              -79.687973,
              33.804914
            ],
            [
              -79.677327,
              33.814694
            ],
            [
              -79.648885,
              33.815261
            ],
            [
              -79.635808,
              33.818038
            ],
            [
              -79.63488,
              33.808359
            ],
            [
              -79.496486,
              33.775357
            ],
            [
              -79.473316,
              33.783747
            ],
            [
              -79.441533,
              33.784387
            ],
            [
              -79.433273,
              33.792892
            ],
            [
              -79.386548,
              33.807678
            ],
            [
              -79.386021,
              33.816196
            ],
            [
              -79.352229,
              33.815135
            ],
            [
              -79.340393,
              33.802796
            ],
            [
              -79.324317,
              33.799123
            ],
            [
              -79.314591,
              33.806982
            ],
            [
              -79.314838,
              33.826072
            ],
            [
              -79.336864,
              33.832552
            ],
            [
              -79.344857,
              33.842596
            ],
            [
              -79.368341,
              33.842931
            ],
            [
              -79.389787,
              33.867075
            ],
            [
              -79.389519,
              33.873197
            ],
            [
              -79.409838,
              33.885294
            ],
            [
              -79.401346,
              33.892806
            ],
            [
              -79.428997,
              33.897811
            ],
            [
              -79.482632,
              33.937589
            ],
            [
              -79.492908,
              33.949735
            ],
            [
              -79.481463,
              33.963921
            ],
            [
              -79.496095,
              33.969257
            ],
            [
              -79.51694,
              33.99398
            ],
            [
              -79.508646,
              34.004989
            ],
            [
              -79.512825,
              34.013761
            ],
            [
              -79.512859,
              34.046055
            ],
            [
              -79.528251,
              34.070969
            ],
            [
              -79.529432,
              34.099845
            ],
            [
              -79.515308,
              34.097207
            ],
            [
              -79.524548,
              34.116737
            ],
            [
              -79.517205,
              34.126267
            ],
            [
              -79.532479,
              34.126311
            ],
            [
              -79.543957,
              34.151698
            ],
            [
              -79.568984,
              34.175087
            ],
            [
              -79.57188,
              34.185366
            ],
            [
              -79.548316,
              34.204432
            ],
            [
              -79.544036,
              34.219676
            ],
            [
              -79.54793,
              34.230913
            ],
            [
              -79.561567,
              34.257944
            ],
            [
              -79.601003,
              34.290532
            ],
            [
              -79.617609,
              34.297171
            ],
            [
              -79.633413,
              34.297994
            ],
            [
              -79.635979,
              34.30122
            ],
            [
              -79.657764,
              34.30519
            ],
            [
              -79.68455,
              34.284591
            ],
            [
              -79.729566,
              34.29519
            ],
            [
              -79.752562,
              34.250616
            ],
            [
              -79.749383,
              34.245053
            ],
            [
              -79.771283,
              34.248953
            ],
            [
              -79.785325,
              34.249387
            ],
            [
              -79.801083,
              34.254952
            ],
            [
              -79.819058,
              34.245347
            ],
            [
              -79.837497,
              34.233031
            ],
            [
              -79.889652,
              34.200017
            ],
            [
              -79.91392,
              34.184793
            ],
            [
              -79.961433,
              34.154888
            ],
            [
              -79.974065,
              34.146895
            ],
            [
              -80.072808,
              34.086013
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "081",
      "COUNTYNS": "01248016",
      "AFFGEOID": "0500000US45081",
      "GEOID": "45081",
      "NAME": "Saluda",
      "LSAD": "06",
      "ALAND": 1172692435,
      "AWATER": 23417452,
      "County_state": "Saluda,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.009817,
              33.964623
            ],
            [
              -82.008298,
              33.961646
            ],
            [
              -81.932044,
              33.976914
            ],
            [
              -81.894595,
              33.975415
            ],
            [
              -81.87036,
              33.891985
            ],
            [
              -81.857904,
              33.880662
            ],
            [
              -81.83543,
              33.865658
            ],
            [
              -81.824624,
              33.867204
            ],
            [
              -81.776406,
              33.845898
            ],
            [
              -81.721001,
              33.832277
            ],
            [
              -81.651759,
              33.81451
            ],
            [
              -81.584337,
              33.867424
            ],
            [
              -81.571485,
              33.87723
            ],
            [
              -81.570302,
              33.884982
            ],
            [
              -81.534863,
              33.953376
            ],
            [
              -81.492397,
              34.034257
            ],
            [
              -81.471972,
              34.076552
            ],
            [
              -81.501674,
              34.074007
            ],
            [
              -81.51385,
              34.082253
            ],
            [
              -81.538899,
              34.081744
            ],
            [
              -81.544355,
              34.087492
            ],
            [
              -81.548539,
              34.093905
            ],
            [
              -81.568511,
              34.099687
            ],
            [
              -81.595286,
              34.118459
            ],
            [
              -81.619946,
              34.124724
            ],
            [
              -81.636963,
              34.146181
            ],
            [
              -81.672671,
              34.156009
            ],
            [
              -81.691503,
              34.172943
            ],
            [
              -81.709036,
              34.173179
            ],
            [
              -81.731614,
              34.187613
            ],
            [
              -81.743947,
              34.179158
            ],
            [
              -81.771025,
              34.173854
            ],
            [
              -81.785857,
              34.166201
            ],
            [
              -81.810816,
              34.175381
            ],
            [
              -81.838789,
              34.172204
            ],
            [
              -81.860571,
              34.179373
            ],
            [
              -81.876356,
              34.167834
            ],
            [
              -81.878848,
              34.156704
            ],
            [
              -81.870084,
              34.135357
            ],
            [
              -81.927444,
              34.07767
            ],
            [
              -81.946118,
              34.038942
            ],
            [
              -81.989142,
              33.998266
            ],
            [
              -82.009817,
              33.964623
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "007",
      "COUNTYNS": "01247981",
      "AFFGEOID": "0500000US45007",
      "GEOID": "45007",
      "NAME": "Anderson",
      "LSAD": "06",
      "ALAND": 1853025715,
      "AWATER": 108725240,
      "County_state": "Anderson,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.9922154353933,
              34.4791982077022
            ],
            [
              -82.979568,
              34.482702
            ],
            [
              -82.960668,
              34.482002
            ],
            [
              -82.954667,
              34.477302
            ],
            [
              -82.947367,
              34.479602
            ],
            [
              -82.940867,
              34.486102
            ],
            [
              -82.928466,
              34.484202
            ],
            [
              -82.925766,
              34.481802
            ],
            [
              -82.922866,
              34.481402
            ],
            [
              -82.908365,
              34.485402
            ],
            [
              -82.902665,
              34.485902
            ],
            [
              -82.882864,
              34.479003
            ],
            [
              -82.876864,
              34.475303
            ],
            [
              -82.873831,
              34.471508
            ],
            [
              -82.874864,
              34.468891
            ],
            [
              -82.875864,
              34.468003
            ],
            [
              -82.876464,
              34.465803
            ],
            [
              -82.875463,
              34.463503
            ],
            [
              -82.865345,
              34.460319
            ],
            [
              -82.862156,
              34.458748
            ],
            [
              -82.860707,
              34.457428
            ],
            [
              -82.860874,
              34.451469
            ],
            [
              -82.860127,
              34.449707
            ],
            [
              -82.855762,
              34.443977
            ],
            [
              -82.854434,
              34.432275
            ],
            [
              -82.851367,
              34.426812
            ],
            [
              -82.848651,
              34.423844
            ],
            [
              -82.847781,
              34.420571
            ],
            [
              -82.847446,
              34.412049
            ],
            [
              -82.8465977180057,
              34.4101368238693
            ],
            [
              -82.841997,
              34.399766
            ],
            [
              -82.841326,
              34.397332
            ],
            [
              -82.841997,
              34.392503
            ],
            [
              -82.841524,
              34.39013
            ],
            [
              -82.836611,
              34.382676
            ],
            [
              -82.835203,
              34.373899
            ],
            [
              -82.835413,
              34.369177
            ],
            [
              -82.835004,
              34.366069
            ],
            [
              -82.833702,
              34.364242
            ],
            [
              -82.82342,
              34.358872
            ],
            [
              -82.8157146886963,
              34.35379613833349
            ],
            [
              -82.809949,
              34.349998
            ],
            [
              -82.798198,
              34.341317
            ],
            [
              -82.795223,
              34.34096
            ],
            [
              -82.794054,
              34.339772
            ],
            [
              -82.791235,
              34.331048
            ],
            [
              -82.791608,
              34.327428
            ],
            [
              -82.790966,
              34.32355
            ],
            [
              -82.78684,
              34.310381
            ],
            [
              -82.781752,
              34.302901
            ],
            [
              -82.780308,
              34.296701
            ],
            [
              -82.7737024263561,
              34.288744030212904
            ],
            [
              -82.770928,
              34.285402
            ],
            [
              -82.765266,
              34.281539
            ],
            [
              -82.762945,
              34.28199
            ],
            [
              -82.761995,
              34.281492
            ],
            [
              -82.755028,
              34.276067
            ],
            [
              -82.749856,
              34.27087
            ],
            [
              -82.746656,
              34.266407
            ],
            [
              -82.748656,
              34.264107
            ],
            [
              -82.748756,
              34.263407
            ],
            [
              -82.744056,
              34.252407
            ],
            [
              -82.744982,
              34.244861
            ],
            [
              -82.744834,
              34.242957
            ],
            [
              -82.74198,
              34.230196
            ],
            [
              -82.743461,
              34.227343
            ],
            [
              -82.744415,
              34.224913
            ],
            [
              -82.740447,
              34.219679
            ],
            [
              -82.740544,
              34.218387
            ],
            [
              -82.74238,
              34.213766
            ],
            [
              -82.74192,
              34.210063
            ],
            [
              -82.741126673611,
              34.208787562607604
            ],
            [
              -82.684298,
              34.24541
            ],
            [
              -82.392351,
              34.433137
            ],
            [
              -82.387479,
              34.436276
            ],
            [
              -82.376815,
              34.443143
            ],
            [
              -82.313996,
              34.484002
            ],
            [
              -82.364486,
              34.515965
            ],
            [
              -82.386073,
              34.538942
            ],
            [
              -82.417293,
              34.55345
            ],
            [
              -82.428386,
              34.597192
            ],
            [
              -82.453789,
              34.625114
            ],
            [
              -82.456289,
              34.657497
            ],
            [
              -82.4619,
              34.655373
            ],
            [
              -82.467807,
              34.666897
            ],
            [
              -82.456701,
              34.680259
            ],
            [
              -82.465008,
              34.705399
            ],
            [
              -82.463658,
              34.74456
            ],
            [
              -82.455144,
              34.750683
            ],
            [
              -82.454632,
              34.764676
            ],
            [
              -82.452015,
              34.776428
            ],
            [
              -82.45837,
              34.7792
            ],
            [
              -82.4607,
              34.796029
            ],
            [
              -82.486746,
              34.819475
            ],
            [
              -82.528491,
              34.799206
            ],
            [
              -82.635241,
              34.747553
            ],
            [
              -82.675921,
              34.727525
            ],
            [
              -82.787568,
              34.672734
            ],
            [
              -82.83377,
              34.629954
            ],
            [
              -82.840253,
              34.623212
            ],
            [
              -82.844963,
              34.618874
            ],
            [
              -82.855303,
              34.609245
            ],
            [
              -82.9922154353933,
              34.4791982077022
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "047",
      "COUNTYNS": "01248003",
      "AFFGEOID": "0500000US45047",
      "GEOID": "45047",
      "NAME": "Greenwood",
      "LSAD": "06",
      "ALAND": 1177746759,
      "AWATER": 21226294,
      "County_state": "Greenwood,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.334234,
              34.34263
            ],
            [
              -82.311081,
              34.320441
            ],
            [
              -82.307622,
              34.277537
            ],
            [
              -82.264646,
              34.245118
            ],
            [
              -82.247747,
              34.219619
            ],
            [
              -82.240162,
              34.179147
            ],
            [
              -82.240446,
              34.16302
            ],
            [
              -82.246667,
              34.144053
            ],
            [
              -82.267184,
              34.10836
            ],
            [
              -82.326947,
              34.06412
            ],
            [
              -82.306243,
              33.971584
            ],
            [
              -82.237693,
              34.013146
            ],
            [
              -82.220076,
              33.990411
            ],
            [
              -82.200286,
              33.978597
            ],
            [
              -82.167716,
              33.973052
            ],
            [
              -82.150827,
              33.986717
            ],
            [
              -82.075687,
              33.979445
            ],
            [
              -82.04513,
              33.983396
            ],
            [
              -82.046974,
              33.95321
            ],
            [
              -82.008298,
              33.961646
            ],
            [
              -82.009817,
              33.964623
            ],
            [
              -81.989142,
              33.998266
            ],
            [
              -81.946118,
              34.038942
            ],
            [
              -81.927444,
              34.07767
            ],
            [
              -81.870084,
              34.135357
            ],
            [
              -81.897554,
              34.146613
            ],
            [
              -81.907646,
              34.16733
            ],
            [
              -81.904473,
              34.176408
            ],
            [
              -81.916989,
              34.176981
            ],
            [
              -81.926748,
              34.195856
            ],
            [
              -81.945019,
              34.203148
            ],
            [
              -81.971079,
              34.222157
            ],
            [
              -81.978375,
              34.238109
            ],
            [
              -81.99207,
              34.24226
            ],
            [
              -82.007176,
              34.24152
            ],
            [
              -82.025562,
              34.258446
            ],
            [
              -82.050752,
              34.268697
            ],
            [
              -82.053505,
              34.276423
            ],
            [
              -82.08367,
              34.29924
            ],
            [
              -82.110081,
              34.307963
            ],
            [
              -82.120008,
              34.303818
            ],
            [
              -82.134027,
              34.323794
            ],
            [
              -82.155444,
              34.33172
            ],
            [
              -82.168857,
              34.330151
            ],
            [
              -82.177999,
              34.358134
            ],
            [
              -82.20085,
              34.376919
            ],
            [
              -82.209161,
              34.375772
            ],
            [
              -82.223625,
              34.391707
            ],
            [
              -82.230516,
              34.391847
            ],
            [
              -82.246337,
              34.409676
            ],
            [
              -82.288677,
              34.377362
            ],
            [
              -82.334234,
              34.34263
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "019",
      "COUNTYNS": "01252740",
      "AFFGEOID": "0500000US45019",
      "GEOID": "45019",
      "NAME": "Charleston",
      "LSAD": "06",
      "ALAND": 2376245593,
      "AWATER": 1141008039,
      "County_state": "Charleston,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.44601,
              32.764804
            ],
            [
              -80.45363,
              32.7399
            ],
            [
              -80.422952,
              32.714757
            ],
            [
              -80.416133,
              32.695292
            ],
            [
              -80.420271,
              32.669604
            ],
            [
              -80.405785,
              32.652999
            ],
            [
              -80.389401,
              32.65282
            ],
            [
              -80.408504,
              32.626693
            ],
            [
              -80.42722,
              32.625183
            ],
            [
              -80.42445,
              32.607801
            ],
            [
              -80.418172,
              32.604474
            ],
            [
              -80.395095,
              32.619226
            ],
            [
              -80.389428,
              32.6185
            ],
            [
              -80.387605,
              32.616524
            ],
            [
              -80.387834,
              32.609139
            ],
            [
              -80.39623,
              32.596629
            ],
            [
              -80.388737,
              32.57447
            ],
            [
              -80.396064,
              32.561637
            ],
            [
              -80.416586,
              32.546318
            ],
            [
              -80.379032,
              32.530932
            ],
            [
              -80.352823,
              32.500187
            ],
            [
              -80.336327,
              32.493565
            ],
            [
              -80.32476,
              32.503578
            ],
            [
              -80.349285,
              32.518274
            ],
            [
              -80.346916,
              32.526271
            ],
            [
              -80.321274,
              32.523177
            ],
            [
              -80.319089,
              32.511026
            ],
            [
              -80.284708,
              32.530392
            ],
            [
              -80.27604256518659,
              32.5169432323041
            ],
            [
              -80.2495663650096,
              32.529583673595496
            ],
            [
              -80.246361,
              32.531114
            ],
            [
              -80.20523,
              32.555547
            ],
            [
              -80.1930029192576,
              32.548507655671
            ],
            [
              -80.190108,
              32.546841
            ],
            [
              -80.183374,
              32.539989
            ],
            [
              -80.171764,
              32.546118
            ],
            [
              -80.167286,
              32.559885
            ],
            [
              -80.148406,
              32.578479
            ],
            [
              -80.121368,
              32.590523
            ],
            [
              -80.077039,
              32.603319
            ],
            [
              -80.037276,
              32.610236
            ],
            [
              -80.010505,
              32.608852
            ],
            [
              -80.000801,
              32.605892
            ],
            [
              -79.99175,
              32.616389
            ],
            [
              -79.986917,
              32.626388
            ],
            [
              -79.975248,
              32.639537
            ],
            [
              -79.968468,
              32.639732
            ],
            [
              -79.915682,
              32.664915
            ],
            [
              -79.884961,
              32.684402
            ],
            [
              -79.887094,
              32.701947
            ],
            [
              -79.870336,
              32.727777
            ],
            [
              -79.868352,
              32.734849
            ],
            [
              -79.879109,
              32.750834
            ],
            [
              -79.89855417685389,
              32.7521709370335
            ],
            [
              -79.928546,
              32.754233
            ],
            [
              -79.9279741598658,
              32.763028468776
            ],
            [
              -79.927357,
              32.772521
            ],
            [
              -79.923198,
              32.781813
            ],
            [
              -79.886001,
              32.787907
            ],
            [
              -79.87905156210039,
              32.7835473838481
            ],
            [
              -79.870628,
              32.778263
            ],
            [
              -79.863422,
              32.771022
            ],
            [
              -79.86331377713628,
              32.769580951097396
            ],
            [
              -79.862709,
              32.761528
            ],
            [
              -79.848527,
              32.755248
            ],
            [
              -79.84035,
              32.756816
            ],
            [
              -79.818237,
              32.766352
            ],
            [
              -79.811021,
              32.77696
            ],
            [
              -79.726389,
              32.805996
            ],
            [
              -79.716761,
              32.813627
            ],
            [
              -79.718498541747,
              32.8204083167155
            ],
            [
              -79.719879,
              32.825796
            ],
            [
              -79.702956,
              32.835781
            ],
            [
              -79.699482,
              32.839997
            ],
            [
              -79.695141,
              32.850398
            ],
            [
              -79.655426,
              32.872705
            ],
            [
              -79.631149,
              32.888606
            ],
            [
              -79.576006,
              32.906235
            ],
            [
              -79.569762,
              32.926692
            ],
            [
              -79.572614,
              32.933885
            ],
            [
              -79.574951,
              32.934526
            ],
            [
              -79.581687,
              32.931341
            ],
            [
              -79.585897,
              32.926461
            ],
            [
              -79.6060026424905,
              32.9259577893588
            ],
            [
              -79.606194,
              32.925953
            ],
            [
              -79.612928,
              32.934815
            ],
            [
              -79.617715,
              32.94487
            ],
            [
              -79.617611,
              32.952726
            ],
            [
              -79.606615,
              32.972248
            ],
            [
              -79.60102,
              32.979282
            ],
            [
              -79.58659,
              32.991334
            ],
            [
              -79.580725,
              33.006447
            ],
            [
              -79.55756,
              33.021269
            ],
            [
              -79.522449,
              33.03535
            ],
            [
              -79.506923,
              33.032813
            ],
            [
              -79.488727,
              33.015832
            ],
            [
              -79.483499,
              33.001265
            ],
            [
              -79.461047,
              33.007639
            ],
            [
              -79.423447,
              33.015085
            ],
            [
              -79.416515,
              33.006815
            ],
            [
              -79.403712,
              33.003903
            ],
            [
              -79.359961,
              33.006672
            ],
            [
              -79.339313,
              33.050336
            ],
            [
              -79.335346,
              33.065362
            ],
            [
              -79.337169,
              33.072302
            ],
            [
              -79.329909,
              33.089986
            ],
            [
              -79.291591,
              33.109773
            ],
            [
              -79.29075361739139,
              33.110050747265504
            ],
            [
              -79.268169,
              33.134477
            ],
            [
              -79.29125,
              33.146563
            ],
            [
              -79.350816,
              33.153528
            ],
            [
              -79.404858,
              33.184929
            ],
            [
              -79.438387,
              33.215136
            ],
            [
              -79.446699,
              33.213458
            ],
            [
              -79.467377,
              33.206483
            ],
            [
              -79.473735,
              33.210366
            ],
            [
              -79.492818,
              33.191821
            ],
            [
              -79.496945,
              33.177427
            ],
            [
              -79.512829,
              33.166176
            ],
            [
              -79.521613,
              33.146708
            ],
            [
              -79.533952,
              33.144035
            ],
            [
              -79.560461,
              33.156403
            ],
            [
              -79.612274,
              33.138446
            ],
            [
              -79.645208,
              33.120478
            ],
            [
              -79.660501,
              33.090832
            ],
            [
              -79.674857,
              33.080584
            ],
            [
              -79.703032,
              33.045934
            ],
            [
              -79.714654,
              33.040642
            ],
            [
              -79.729024,
              33.014089
            ],
            [
              -79.76231,
              32.988708
            ],
            [
              -79.769324,
              32.968545
            ],
            [
              -79.76358,
              32.958174
            ],
            [
              -79.770984,
              32.94186
            ],
            [
              -79.783096,
              32.943164
            ],
            [
              -79.802195,
              32.929916
            ],
            [
              -79.824357,
              32.919874
            ],
            [
              -79.827518,
              32.922327
            ],
            [
              -79.83643,
              32.919574
            ],
            [
              -79.843314,
              32.893656
            ],
            [
              -79.86352,
              32.869911
            ],
            [
              -79.897279,
              32.858766
            ],
            [
              -79.89671,
              32.830472
            ],
            [
              -79.906108,
              32.823244
            ],
            [
              -79.914307,
              32.818892
            ],
            [
              -79.919202,
              32.818991
            ],
            [
              -79.928305,
              32.823983
            ],
            [
              -79.933536,
              32.851823
            ],
            [
              -79.95185,
              32.857117
            ],
            [
              -79.959649,
              32.863839
            ],
            [
              -79.964513,
              32.87795
            ],
            [
              -79.950448,
              32.910174
            ],
            [
              -79.985703,
              32.904209
            ],
            [
              -79.994217,
              32.902769
            ],
            [
              -80.011774,
              32.899881
            ],
            [
              -80.017205,
              32.910037
            ],
            [
              -80.02318,
              32.921101
            ],
            [
              -80.023318,
              32.921434
            ],
            [
              -80.033787,
              32.940953
            ],
            [
              -80.036557,
              32.946057
            ],
            [
              -80.041269,
              32.954986
            ],
            [
              -80.042406,
              32.959578
            ],
            [
              -80.039904,
              32.982582
            ],
            [
              -80.050766,
              32.990988
            ],
            [
              -80.055462,
              32.999512
            ],
            [
              -80.087288,
              32.992058
            ],
            [
              -80.098222,
              32.988106
            ],
            [
              -80.103096,
              33.001229
            ],
            [
              -80.132135,
              33.010484
            ],
            [
              -80.149246,
              33.021601
            ],
            [
              -80.1632,
              33.019245
            ],
            [
              -80.163441,
              33.014342
            ],
            [
              -80.162593,
              33.010502
            ],
            [
              -80.16766,
              33.002498
            ],
            [
              -80.159863,
              32.996128
            ],
            [
              -80.145729,
              32.984339
            ],
            [
              -80.136224,
              32.999253
            ],
            [
              -80.110543,
              32.985492
            ],
            [
              -80.129846,
              32.97959
            ],
            [
              -80.138246,
              32.981537
            ],
            [
              -80.138558,
              32.980539
            ],
            [
              -80.13946,
              32.981196
            ],
            [
              -80.140943,
              32.979613
            ],
            [
              -80.135371,
              32.975683
            ],
            [
              -80.100675,
              32.946033
            ],
            [
              -80.089297,
              32.93614
            ],
            [
              -80.085289,
              32.932801
            ],
            [
              -80.079028,
              32.927811
            ],
            [
              -80.089196,
              32.905444
            ],
            [
              -80.097125,
              32.886825
            ],
            [
              -80.103705,
              32.893285
            ],
            [
              -80.118877,
              32.893181
            ],
            [
              -80.122794,
              32.901766
            ],
            [
              -80.126308,
              32.889819
            ],
            [
              -80.162185,
              32.874614
            ],
            [
              -80.163494,
              32.854544
            ],
            [
              -80.185697,
              32.860807
            ],
            [
              -80.18376,
              32.847087
            ],
            [
              -80.16065,
              32.840773
            ],
            [
              -80.158591,
              32.830394
            ],
            [
              -80.155045,
              32.825699
            ],
            [
              -80.148484,
              32.819034
            ],
            [
              -80.157715,
              32.818215
            ],
            [
              -80.275441,
              32.843378
            ],
            [
              -80.292076,
              32.84886
            ],
            [
              -80.301688,
              32.851903
            ],
            [
              -80.40134,
              32.858466
            ],
            [
              -80.394258,
              32.844576
            ],
            [
              -80.407479,
              32.811106
            ],
            [
              -80.435692,
              32.787407
            ],
            [
              -80.44601,
              32.764804
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "091",
      "COUNTYNS": "01248019",
      "AFFGEOID": "0500000US45091",
      "GEOID": "45091",
      "NAME": "York",
      "LSAD": "06",
      "ALAND": 1763165632,
      "AWATER": 39248969,
      "County_state": "York,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.475875,
              34.98232
            ],
            [
              -81.492385,
              34.946774
            ],
            [
              -81.473842,
              34.935882
            ],
            [
              -81.470974,
              34.912316
            ],
            [
              -81.47873,
              34.899958
            ],
            [
              -81.463063,
              34.854969
            ],
            [
              -81.453309,
              34.846358
            ],
            [
              -81.457257,
              34.83928
            ],
            [
              -81.461755,
              34.831018
            ],
            [
              -81.478454,
              34.821509
            ],
            [
              -81.331449,
              34.822269
            ],
            [
              -81.178326,
              34.820906
            ],
            [
              -81.03294,
              34.821022
            ],
            [
              -80.898215,
              34.820746
            ],
            [
              -80.888757,
              34.831191
            ],
            [
              -80.86707,
              34.847334
            ],
            [
              -80.87845,
              34.86197
            ],
            [
              -80.878262,
              34.902951
            ],
            [
              -80.867181,
              34.929104
            ],
            [
              -80.873993,
              34.965091
            ],
            [
              -80.874218,
              34.965325
            ],
            [
              -80.875039,
              34.965252
            ],
            [
              -80.875678,
              34.965018
            ],
            [
              -80.876636,
              34.964283
            ],
            [
              -80.877291,
              34.963874
            ],
            [
              -80.877577,
              34.963518
            ],
            [
              -80.878275,
              34.963519
            ],
            [
              -80.878745,
              34.963142
            ],
            [
              -80.879842,
              34.962515
            ],
            [
              -80.880769,
              34.964873
            ],
            [
              -80.880612,
              34.965588
            ],
            [
              -80.880857,
              34.965992
            ],
            [
              -80.881465,
              34.966548
            ],
            [
              -80.881976,
              34.966734
            ],
            [
              -80.882273,
              34.966526
            ],
            [
              -80.882499,
              34.965879
            ],
            [
              -80.882453,
              34.965107
            ],
            [
              -80.88264,
              34.965054
            ],
            [
              -80.883148,
              34.965049
            ],
            [
              -80.884201,
              34.965694
            ],
            [
              -80.884587,
              34.966141
            ],
            [
              -80.884189,
              34.967458
            ],
            [
              -80.884237,
              34.968285
            ],
            [
              -80.88326,
              34.96919
            ],
            [
              -80.882984,
              34.969897
            ],
            [
              -80.882515,
              34.970312
            ],
            [
              -80.882389,
              34.970458
            ],
            [
              -80.882413,
              34.97084
            ],
            [
              -80.88306,
              34.971685
            ],
            [
              -80.883274,
              34.972132
            ],
            [
              -80.883781,
              34.972879
            ],
            [
              -80.883878,
              34.973361
            ],
            [
              -80.883711,
              34.973799
            ],
            [
              -80.883758,
              34.974151
            ],
            [
              -80.884296,
              34.97445
            ],
            [
              -80.884391,
              34.974674
            ],
            [
              -80.884053,
              34.975168
            ],
            [
              -80.884421,
              34.975716
            ],
            [
              -80.885457,
              34.976182
            ],
            [
              -80.885896,
              34.976702
            ],
            [
              -80.885902,
              34.977303
            ],
            [
              -80.886053,
              34.977902
            ],
            [
              -80.885937,
              34.978391
            ],
            [
              -80.885942,
              34.978852
            ],
            [
              -80.886353,
              34.979783
            ],
            [
              -80.88654,
              34.980952
            ],
            [
              -80.887017,
              34.981984
            ],
            [
              -80.887351,
              34.982402
            ],
            [
              -80.887928,
              34.983282
            ],
            [
              -80.88794,
              34.983799
            ],
            [
              -80.887553,
              34.984311
            ],
            [
              -80.887549,
              34.984557
            ],
            [
              -80.887753,
              34.984771
            ],
            [
              -80.888599,
              34.984631
            ],
            [
              -80.888957,
              34.984793
            ],
            [
              -80.889258,
              34.985206
            ],
            [
              -80.885396,
              34.990211
            ],
            [
              -80.902296,
              35.005742
            ],
            [
              -80.909458,
              35.016663
            ],
            [
              -80.897322,
              35.033629
            ],
            [
              -80.905885,
              35.046783
            ],
            [
              -80.9062325339664,
              35.0764190603399
            ],
            [
              -80.906553,
              35.076763
            ],
            [
              -80.93495,
              35.107409
            ],
            [
              -80.95787,
              35.092639
            ],
            [
              -80.98416,
              35.077568
            ],
            [
              -80.9891720831187,
              35.074694722746
            ],
            [
              -80.9967330696895,
              35.0703602354246
            ],
            [
              -81.0233351374323,
              35.0551100661147
            ],
            [
              -81.041489,
              35.044703
            ],
            [
              -81.0414930433063,
              35.04470799807459
            ],
            [
              -81.050018,
              35.055246
            ],
            [
              -81.055695,
              35.060121
            ],
            [
              -81.057648,
              35.062433
            ],
            [
              -81.058029,
              35.07319
            ],
            [
              -81.057236,
              35.086129
            ],
            [
              -81.052078,
              35.096276
            ],
            [
              -81.050079,
              35.098817
            ],
            [
              -81.046524,
              35.100617
            ],
            [
              -81.0401844043794,
              35.101949319167
            ],
            [
              -81.037369,
              35.102541
            ],
            [
              -81.034958,
              35.104105
            ],
            [
              -81.032806,
              35.108049
            ],
            [
              -81.032471,
              35.110033
            ],
            [
              -81.033005,
              35.113747
            ],
            [
              -81.036759,
              35.122552
            ],
            [
              -81.038968,
              35.126299
            ],
            [
              -81.05042,
              35.131048
            ],
            [
              -81.051037,
              35.131654
            ],
            [
              -81.051204,
              35.133237
            ],
            [
              -81.047826,
              35.143743
            ],
            [
              -81.047091,
              35.145157
            ],
            [
              -81.044391,
              35.147918
            ],
            [
              -81.043407,
              35.14839
            ],
            [
              -81.04287,
              35.149248
            ],
            [
              -81.043625,
              35.149877
            ],
            [
              -81.077253,
              35.152047
            ],
            [
              -81.109295,
              35.154115
            ],
            [
              -81.11084,
              35.154185
            ],
            [
              -81.138207,
              35.155417
            ],
            [
              -81.239358,
              35.159974
            ],
            [
              -81.241686,
              35.160081
            ],
            [
              -81.26523856687389,
              35.160987792575895
            ],
            [
              -81.2694460893412,
              35.1611497855407
            ],
            [
              -81.290672,
              35.161967
            ],
            [
              -81.3280393754573,
              35.163405284384005
            ],
            [
              -81.3665997502835,
              35.164889487764
            ],
            [
              -81.401337,
              35.136676
            ],
            [
              -81.40371,
              35.10733
            ],
            [
              -81.409356,
              35.048761
            ],
            [
              -81.453264,
              35.048887
            ],
            [
              -81.488286,
              35.026868
            ],
            [
              -81.475875,
              34.98232
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "053",
      "COUNTYNS": "01248006",
      "AFFGEOID": "0500000US45053",
      "GEOID": "45053",
      "NAME": "Jasper",
      "LSAD": "06",
      "ALAND": 1696775859,
      "AWATER": 114287450,
      "County_state": "Jasper,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.2812975973478,
              32.556440280647
            ],
            [
              -81.279238,
              32.55459
            ],
            [
              -81.275213,
              32.545202
            ],
            [
              -81.274927,
              32.544158
            ],
            [
              -81.274802,
              32.541143
            ],
            [
              -81.276242,
              32.538558
            ],
            [
              -81.277131,
              32.535417
            ],
            [
              -81.252882,
              32.51833
            ],
            [
              -81.249827,
              32.517541
            ],
            [
              -81.247874,
              32.518231
            ],
            [
              -81.24501,
              32.518317
            ],
            [
              -81.237095,
              32.517314
            ],
            [
              -81.23466,
              32.51627
            ],
            [
              -81.234023,
              32.513778
            ],
            [
              -81.234834,
              32.512271
            ],
            [
              -81.236707,
              32.511402
            ],
            [
              -81.238411,
              32.510192
            ],
            [
              -81.238728,
              32.508896
            ],
            [
              -81.238281,
              32.505988
            ],
            [
              -81.233585,
              32.498488
            ],
            [
              -81.227528,
              32.493884
            ],
            [
              -81.212428,
              32.478685
            ],
            [
              -81.200029,
              32.467985
            ],
            [
              -81.194829,
              32.465086
            ],
            [
              -81.192429,
              32.464786
            ],
            [
              -81.189229,
              32.465586
            ],
            [
              -81.188129,
              32.465386
            ],
            [
              -81.186829,
              32.464086
            ],
            [
              -81.187329,
              32.462886
            ],
            [
              -81.192629,
              32.456286
            ],
            [
              -81.197529,
              32.452086
            ],
            [
              -81.200908,
              32.451593
            ],
            [
              -81.202359,
              32.450448
            ],
            [
              -81.203046,
              32.448844
            ],
            [
              -81.203046,
              32.447164
            ],
            [
              -81.202206,
              32.445484
            ],
            [
              -81.201137,
              32.444033
            ],
            [
              -81.201137,
              32.442964
            ],
            [
              -81.201595,
              32.44136
            ],
            [
              -81.202588,
              32.439833
            ],
            [
              -81.20453,
              32.438687
            ],
            [
              -81.207246,
              32.437542
            ],
            [
              -81.20843,
              32.435987
            ],
            [
              -81.20513,
              32.423788
            ],
            [
              -81.194931,
              32.411489
            ],
            [
              -81.189731,
              32.407289
            ],
            [
              -81.180931,
              32.39649
            ],
            [
              -81.177231,
              32.39169
            ],
            [
              -81.178131,
              32.38459
            ],
            [
              -81.181072,
              32.380398
            ],
            [
              -81.18025865806439,
              32.379566879516794
            ],
            [
              -81.173432,
              32.372591
            ],
            [
              -81.169332,
              32.369436
            ],
            [
              -81.168722,
              32.367544
            ],
            [
              -81.169332,
              32.365591
            ],
            [
              -81.170553,
              32.363821
            ],
            [
              -81.170858,
              32.362722
            ],
            [
              -81.170126,
              32.361318
            ],
            [
              -81.161732,
              32.356092
            ],
            [
              -81.155032,
              32.350093
            ],
            [
              -81.154974,
              32.348794
            ],
            [
              -81.155136,
              32.34717
            ],
            [
              -81.154812,
              32.346412
            ],
            [
              -81.154433,
              32.346087
            ],
            [
              -81.154,
              32.345924
            ],
            [
              -81.153296,
              32.345816
            ],
            [
              -81.152105,
              32.345816
            ],
            [
              -81.150589,
              32.34587
            ],
            [
              -81.149073,
              32.346682
            ],
            [
              -81.148477,
              32.347549
            ],
            [
              -81.147632,
              32.349393
            ],
            [
              -81.144032,
              32.351093
            ],
            [
              -81.142532,
              32.350893
            ],
            [
              -81.140932,
              32.349393
            ],
            [
              -81.133632,
              32.341293
            ],
            [
              -81.133032,
              32.334794
            ],
            [
              -81.137032,
              32.329994
            ],
            [
              -81.137633,
              32.328194
            ],
            [
              -81.135733,
              32.324594
            ],
            [
              -81.125433,
              32.309295
            ],
            [
              -81.123933,
              32.308695
            ],
            [
              -81.122933,
              32.307295
            ],
            [
              -81.122333,
              32.305395
            ],
            [
              -81.119833,
              32.289596
            ],
            [
              -81.1196397722772,
              32.2876637227723
            ],
            [
              -81.119633,
              32.287596
            ],
            [
              -81.121433,
              32.284496
            ],
            [
              -81.128034,
              32.276297
            ],
            [
              -81.130834,
              32.274597
            ],
            [
              -81.136534,
              32.272697
            ],
            [
              -81.145834,
              32.263397
            ],
            [
              -81.148334,
              32.255098
            ],
            [
              -81.155595,
              32.246022
            ],
            [
              -81.156587,
              32.24391
            ],
            [
              -81.155995,
              32.241478
            ],
            [
              -81.153531,
              32.237687
            ],
            [
              -81.146530388013,
              32.226938177361
            ],
            [
              -81.143139,
              32.221731
            ],
            [
              -81.136727,
              32.213669
            ],
            [
              -81.136012,
              32.212858
            ],
            [
              -81.128283,
              32.208634
            ],
            [
              -81.12315,
              32.201329
            ],
            [
              -81.118234,
              32.189201
            ],
            [
              -81.117934,
              32.185301
            ],
            [
              -81.119834,
              32.181202
            ],
            [
              -81.120434,
              32.178702
            ],
            [
              -81.119361,
              32.177142
            ],
            [
              -81.119434,
              32.175402
            ],
            [
              -81.121134,
              32.174902
            ],
            [
              -81.124492,
              32.17212
            ],
            [
              -81.128134,
              32.169102
            ],
            [
              -81.129402,
              32.166922
            ],
            [
              -81.129634,
              32.165602
            ],
            [
              -81.128434,
              32.164402
            ],
            [
              -81.123134,
              32.162902
            ],
            [
              -81.122034,
              32.161803
            ],
            [
              -81.1203585245902,
              32.154682229508204
            ],
            [
              -81.120034,
              32.153303
            ],
            [
              -81.118334,
              32.144403
            ],
            [
              -81.119134,
              32.142104
            ],
            [
              -81.119994,
              32.134268
            ],
            [
              -81.117234,
              32.117605
            ],
            [
              -81.113334,
              32.113205
            ],
            [
              -81.111134,
              32.112005
            ],
            [
              -81.100458,
              32.111181
            ],
            [
              -81.093386,
              32.11123
            ],
            [
              -81.091498,
              32.110782
            ],
            [
              -81.090874,
              32.10699
            ],
            [
              -81.088234,
              32.10395
            ],
            [
              -81.0873316730266,
              32.10334023637979
            ],
            [
              -81.083546,
              32.100782
            ],
            [
              -81.066906,
              32.090351
            ],
            [
              -81.060442,
              32.087503
            ],
            [
              -81.050234,
              32.085308
            ],
            [
              -81.038265,
              32.084469
            ],
            [
              -81.032674,
              32.08545
            ],
            [
              -81.021622,
              32.090897
            ],
            [
              -81.02010401268758,
              32.0926621707087
            ],
            [
              -81.016009,
              32.097424
            ],
            [
              -81.011961,
              32.100176
            ],
            [
              -81.006745,
              32.101152
            ],
            [
              -81.002297,
              32.100048
            ],
            [
              -80.999833,
              32.099014
            ],
            [
              -80.994333,
              32.094608
            ],
            [
              -80.991733,
              32.091208
            ],
            [
              -80.987733,
              32.084209
            ],
            [
              -80.983133,
              32.079609
            ],
            [
              -80.978833,
              32.077309
            ],
            [
              -80.959402,
              32.071259
            ],
            [
              -80.954482,
              32.068622
            ],
            [
              -80.943226,
              32.057824
            ],
            [
              -80.933557,
              32.047554
            ],
            [
              -80.926753,
              32.041672
            ],
            [
              -80.922794,
              32.039151
            ],
            [
              -80.917845,
              32.037575
            ],
            [
              -80.910669,
              32.036735
            ],
            [
              -80.892977,
              32.034949
            ],
            [
              -80.885517,
              32.0346
            ],
            [
              -80.892344,
              32.043764
            ],
            [
              -80.905378,
              32.051943
            ],
            [
              -80.87811070607039,
              32.0797919665806
            ],
            [
              -80.908111,
              32.078172
            ],
            [
              -80.900573,
              32.111617
            ],
            [
              -80.928281,
              32.122225
            ],
            [
              -80.908441,
              32.13065
            ],
            [
              -80.941262,
              32.137172
            ],
            [
              -80.961471,
              32.126358
            ],
            [
              -80.967227,
              32.140717
            ],
            [
              -80.953825,
              32.141712
            ],
            [
              -80.948423,
              32.167386
            ],
            [
              -80.994289,
              32.196387
            ],
            [
              -81.012331,
              32.218449
            ],
            [
              -81.012452,
              32.236226
            ],
            [
              -80.99157,
              32.268036
            ],
            [
              -81.006323,
              32.306196
            ],
            [
              -80.983631,
              32.306296
            ],
            [
              -80.934935,
              32.300369
            ],
            [
              -80.934386,
              32.320005
            ],
            [
              -80.921097,
              32.352478
            ],
            [
              -80.892497,
              32.353713
            ],
            [
              -80.877594,
              32.345901
            ],
            [
              -80.873715,
              32.369059
            ],
            [
              -80.841936,
              32.3849
            ],
            [
              -80.842896,
              32.393391
            ],
            [
              -80.82924,
              32.41147
            ],
            [
              -80.847973,
              32.411642
            ],
            [
              -80.847729,
              32.428991
            ],
            [
              -80.830165,
              32.462093
            ],
            [
              -80.81869,
              32.463935
            ],
            [
              -80.817429,
              32.472589
            ],
            [
              -80.820228,
              32.490788
            ],
            [
              -80.838739,
              32.52093
            ],
            [
              -80.839207,
              32.557509
            ],
            [
              -80.832249,
              32.596363
            ],
            [
              -80.848442,
              32.614857
            ],
            [
              -80.869705,
              32.660935
            ],
            [
              -80.902448,
              32.621561
            ],
            [
              -80.918971,
              32.626561
            ],
            [
              -80.929287,
              32.636574
            ],
            [
              -80.95867,
              32.642507
            ],
            [
              -80.962627,
              32.653343
            ],
            [
              -80.951469,
              32.660128
            ],
            [
              -80.945865,
              32.687348
            ],
            [
              -80.964156,
              32.694701
            ],
            [
              -80.976116,
              32.720221
            ],
            [
              -80.99534,
              32.730547
            ],
            [
              -81.014625,
              32.753058
            ],
            [
              -81.207206,
              32.610526
            ],
            [
              -81.22258,
              32.603825
            ],
            [
              -81.2812975973478,
              32.556440280647
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "013",
      "COUNTYNS": "01247984",
      "AFFGEOID": "0500000US45013",
      "GEOID": "45013",
      "NAME": "Beaufort",
      "LSAD": "06",
      "ALAND": 1492881338,
      "AWATER": 899019054,
      "County_state": "Beaufort,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.012452,
              32.236226
            ],
            [
              -81.012331,
              32.218449
            ],
            [
              -80.994289,
              32.196387
            ],
            [
              -80.948423,
              32.167386
            ],
            [
              -80.953825,
              32.141712
            ],
            [
              -80.967227,
              32.140717
            ],
            [
              -80.961471,
              32.126358
            ],
            [
              -80.941262,
              32.137172
            ],
            [
              -80.908441,
              32.13065
            ],
            [
              -80.928281,
              32.122225
            ],
            [
              -80.900573,
              32.111617
            ],
            [
              -80.908111,
              32.078172
            ],
            [
              -80.87811070607039,
              32.0797919665806
            ],
            [
              -80.858735,
              32.099581
            ],
            [
              -80.844431,
              32.109709
            ],
            [
              -80.831531,
              32.112709
            ],
            [
              -80.828394,
              32.113222
            ],
            [
              -80.82153,
              32.108589
            ],
            [
              -80.812503,
              32.109746
            ],
            [
              -80.789996,
              32.122494
            ],
            [
              -80.749091,
              32.140137
            ],
            [
              -80.721463,
              32.160427
            ],
            [
              -80.688857,
              32.200971
            ],
            [
              -80.669166,
              32.216783
            ],
            [
              -80.677845,
              32.236406
            ],
            [
              -80.717796,
              32.266974
            ],
            [
              -80.738083,
              32.264168
            ],
            [
              -80.7397681997449,
              32.2653224934474
            ],
            [
              -80.761257,
              32.280044
            ],
            [
              -80.766038,
              32.292608
            ],
            [
              -80.75345,
              32.306822
            ],
            [
              -80.737118,
              32.306029
            ],
            [
              -80.733637,
              32.319469
            ],
            [
              -80.7276655533616,
              32.321409814265095
            ],
            [
              -80.714601,
              32.325656
            ],
            [
              -80.688527,
              32.302849
            ],
            [
              -80.65434498793701,
              32.2939761991188
            ],
            [
              -80.644794,
              32.291497
            ],
            [
              -80.646458,
              32.265072
            ],
            [
              -80.638857,
              32.255618
            ],
            [
              -80.618286,
              32.260183
            ],
            [
              -80.596394,
              32.273549
            ],
            [
              -80.571096,
              32.273278
            ],
            [
              -80.545688,
              32.282076
            ],
            [
              -80.539429,
              32.287024
            ],
            [
              -80.517871,
              32.298796
            ],
            [
              -80.466342,
              32.31917
            ],
            [
              -80.455192,
              32.326458
            ],
            [
              -80.456814,
              32.336884
            ],
            [
              -80.445451,
              32.350335
            ],
            [
              -80.434303,
              32.375193
            ],
            [
              -80.429291,
              32.389667
            ],
            [
              -80.429941,
              32.401782
            ],
            [
              -80.43296,
              32.410659
            ],
            [
              -80.457502,
              32.410264
            ],
            [
              -80.467588,
              32.425259
            ],
            [
              -80.480156,
              32.447048
            ],
            [
              -80.4804300569877,
              32.447903652482694
            ],
            [
              -80.484617,
              32.460976
            ],
            [
              -80.48025,
              32.477407
            ],
            [
              -80.476910367369,
              32.485389546487795
            ],
            [
              -80.504616,
              32.496947
            ],
            [
              -80.507975,
              32.505207
            ],
            [
              -80.51408,
              32.513897
            ],
            [
              -80.536483,
              32.529224
            ],
            [
              -80.551504,
              32.565769
            ],
            [
              -80.562437,
              32.550957
            ],
            [
              -80.567312,
              32.566438
            ],
            [
              -80.582823,
              32.566199
            ],
            [
              -80.576166,
              32.577761
            ],
            [
              -80.586358,
              32.586172
            ],
            [
              -80.599788,
              32.581047
            ],
            [
              -80.611662,
              32.589871
            ],
            [
              -80.620881,
              32.5812
            ],
            [
              -80.629624,
              32.590703
            ],
            [
              -80.647828,
              32.596371
            ],
            [
              -80.659751,
              32.612683
            ],
            [
              -80.666448,
              32.645704
            ],
            [
              -80.680061,
              32.643913
            ],
            [
              -80.683135,
              32.652383
            ],
            [
              -80.668144,
              32.667492
            ],
            [
              -80.685005,
              32.672951
            ],
            [
              -80.703327,
              32.664586
            ],
            [
              -80.706497,
              32.673244
            ],
            [
              -80.739243,
              32.682304
            ],
            [
              -80.744888,
              32.674229
            ],
            [
              -80.763252,
              32.675369
            ],
            [
              -80.807877,
              32.689438
            ],
            [
              -80.826309,
              32.704321
            ],
            [
              -80.837213,
              32.699821
            ],
            [
              -80.857214,
              32.675955
            ],
            [
              -80.869705,
              32.660935
            ],
            [
              -80.848442,
              32.614857
            ],
            [
              -80.832249,
              32.596363
            ],
            [
              -80.839207,
              32.557509
            ],
            [
              -80.838739,
              32.52093
            ],
            [
              -80.820228,
              32.490788
            ],
            [
              -80.817429,
              32.472589
            ],
            [
              -80.81869,
              32.463935
            ],
            [
              -80.830165,
              32.462093
            ],
            [
              -80.847729,
              32.428991
            ],
            [
              -80.847973,
              32.411642
            ],
            [
              -80.82924,
              32.41147
            ],
            [
              -80.842896,
              32.393391
            ],
            [
              -80.841936,
              32.3849
            ],
            [
              -80.873715,
              32.369059
            ],
            [
              -80.877594,
              32.345901
            ],
            [
              -80.892497,
              32.353713
            ],
            [
              -80.921097,
              32.352478
            ],
            [
              -80.934386,
              32.320005
            ],
            [
              -80.934935,
              32.300369
            ],
            [
              -80.983631,
              32.306296
            ],
            [
              -81.006323,
              32.306196
            ],
            [
              -80.99157,
              32.268036
            ],
            [
              -81.012452,
              32.236226
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "025",
      "COUNTYNS": "01247989",
      "AFFGEOID": "0500000US45025",
      "GEOID": "45025",
      "NAME": "Chesterfield",
      "LSAD": "06",
      "ALAND": 2069379207,
      "AWATER": 17250030,
      "County_state": "Chesterfield,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.561657,
              34.817481
            ],
            [
              -80.56170938792269,
              34.8174816094994
            ],
            [
              -80.544479,
              34.783956
            ],
            [
              -80.517871,
              34.762716
            ],
            [
              -80.487733,
              34.722265
            ],
            [
              -80.48976,
              34.720216
            ],
            [
              -80.488532,
              34.704584
            ],
            [
              -80.473065,
              34.692357
            ],
            [
              -80.460782,
              34.676984
            ],
            [
              -80.431648,
              34.659761
            ],
            [
              -80.435036,
              34.642075
            ],
            [
              -80.408373,
              34.614765
            ],
            [
              -80.389888,
              34.599448
            ],
            [
              -80.379382,
              34.584362
            ],
            [
              -80.381383,
              34.573759
            ],
            [
              -80.372336,
              34.546769
            ],
            [
              -80.354772,
              34.531125
            ],
            [
              -80.353634,
              34.520901
            ],
            [
              -80.334732,
              34.510028
            ],
            [
              -80.32759,
              34.49776
            ],
            [
              -80.327405,
              34.473167
            ],
            [
              -80.321215,
              34.426322
            ],
            [
              -80.311441,
              34.42429
            ],
            [
              -80.302215,
              34.399821
            ],
            [
              -80.291404,
              34.392732
            ],
            [
              -80.285538,
              34.393678
            ],
            [
              -80.288596,
              34.366207
            ],
            [
              -80.154244,
              34.436051
            ],
            [
              -80.076511,
              34.476339
            ],
            [
              -80.04608,
              34.492267
            ],
            [
              -80.027829,
              34.486322
            ],
            [
              -79.981038,
              34.501511
            ],
            [
              -79.965322,
              34.48761
            ],
            [
              -79.94064,
              34.488042
            ],
            [
              -79.919846,
              34.495764
            ],
            [
              -79.902076,
              34.489234
            ],
            [
              -79.884967,
              34.491113
            ],
            [
              -79.863641,
              34.508733
            ],
            [
              -79.862028,
              34.521041
            ],
            [
              -79.843676,
              34.533085
            ],
            [
              -79.829981,
              34.531925
            ],
            [
              -79.803877,
              34.609053
            ],
            [
              -79.777461,
              34.613492
            ],
            [
              -79.79609,
              34.648044
            ],
            [
              -79.830454,
              34.657212
            ],
            [
              -79.855007,
              34.673351
            ],
            [
              -79.872941,
              34.69563
            ],
            [
              -79.874031,
              34.70403
            ],
            [
              -79.8753,
              34.707205
            ],
            [
              -79.893039,
              34.741964
            ],
            [
              -79.909735,
              34.7546
            ],
            [
              -79.92524,
              34.765296
            ],
            [
              -79.934322,
              34.80127
            ],
            [
              -79.92800950600581,
              34.8065635148516
            ],
            [
              -80.000541,
              34.808141
            ],
            [
              -80.0132736813764,
              34.8084176637672
            ],
            [
              -80.027464,
              34.808726
            ],
            [
              -80.042764,
              34.809097
            ],
            [
              -80.072912,
              34.809736
            ],
            [
              -80.077223,
              34.809716
            ],
            [
              -80.098022,
              34.810147
            ],
            [
              -80.098994,
              34.810147
            ],
            [
              -80.131169,
              34.810811
            ],
            [
              -80.159252,
              34.81139
            ],
            [
              -80.229705,
              34.812843
            ],
            [
              -80.23396,
              34.812931
            ],
            [
              -80.283627,
              34.813589
            ],
            [
              -80.30469,
              34.813868
            ],
            [
              -80.3208073604558,
              34.814081463212
            ],
            [
              -80.350068,
              34.814469
            ],
            [
              -80.399871,
              34.815128
            ],
            [
              -80.417014,
              34.815508
            ],
            [
              -80.418433,
              34.815622
            ],
            [
              -80.419586,
              34.815581
            ],
            [
              -80.425902,
              34.81581
            ],
            [
              -80.434843,
              34.815968
            ],
            [
              -80.448766,
              34.816332
            ],
            [
              -80.45166,
              34.816396
            ],
            [
              -80.485234,
              34.816732
            ],
            [
              -80.491814,
              34.816798
            ],
            [
              -80.499788,
              34.817261
            ],
            [
              -80.561657,
              34.817481
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "035",
      "COUNTYNS": "01247998",
      "AFFGEOID": "0500000US45035",
      "GEOID": "45035",
      "NAME": "Dorchester",
      "LSAD": "06",
      "ALAND": 1484540310,
      "AWATER": 6724254,
      "County_state": "Dorchester,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.790296,
              33.18084
            ],
            [
              -80.772,
              33.171563
            ],
            [
              -80.737417,
              33.17037
            ],
            [
              -80.710063,
              33.156608
            ],
            [
              -80.681166,
              33.124449
            ],
            [
              -80.670443,
              33.103552
            ],
            [
              -80.626692,
              33.07506
            ],
            [
              -80.591488,
              33.05782
            ],
            [
              -80.551086,
              33.058236
            ],
            [
              -80.532013,
              33.069653
            ],
            [
              -80.526061,
              33.06118
            ],
            [
              -80.504195,
              33.057622
            ],
            [
              -80.496827,
              33.063172
            ],
            [
              -80.474788,
              33.064394
            ],
            [
              -80.412022,
              33.046596
            ],
            [
              -80.391671,
              33.044983
            ],
            [
              -80.387819,
              33.032843
            ],
            [
              -80.392604,
              33.02756
            ],
            [
              -80.406483,
              33.019011
            ],
            [
              -80.408696,
              32.984605
            ],
            [
              -80.418403,
              32.976039
            ],
            [
              -80.402279,
              32.94674
            ],
            [
              -80.405645,
              32.914535
            ],
            [
              -80.385344,
              32.886119
            ],
            [
              -80.400209,
              32.872475
            ],
            [
              -80.40134,
              32.858466
            ],
            [
              -80.301688,
              32.851903
            ],
            [
              -80.292076,
              32.84886
            ],
            [
              -80.275441,
              32.843378
            ],
            [
              -80.157715,
              32.818215
            ],
            [
              -80.148484,
              32.819034
            ],
            [
              -80.155045,
              32.825699
            ],
            [
              -80.158591,
              32.830394
            ],
            [
              -80.16065,
              32.840773
            ],
            [
              -80.18376,
              32.847087
            ],
            [
              -80.185697,
              32.860807
            ],
            [
              -80.163494,
              32.854544
            ],
            [
              -80.162185,
              32.874614
            ],
            [
              -80.126308,
              32.889819
            ],
            [
              -80.122794,
              32.901766
            ],
            [
              -80.118877,
              32.893181
            ],
            [
              -80.103705,
              32.893285
            ],
            [
              -80.097125,
              32.886825
            ],
            [
              -80.089196,
              32.905444
            ],
            [
              -80.079028,
              32.927811
            ],
            [
              -80.085289,
              32.932801
            ],
            [
              -80.089297,
              32.93614
            ],
            [
              -80.100675,
              32.946033
            ],
            [
              -80.135371,
              32.975683
            ],
            [
              -80.140943,
              32.979613
            ],
            [
              -80.13946,
              32.981196
            ],
            [
              -80.138558,
              32.980539
            ],
            [
              -80.138246,
              32.981537
            ],
            [
              -80.129846,
              32.97959
            ],
            [
              -80.110543,
              32.985492
            ],
            [
              -80.136224,
              32.999253
            ],
            [
              -80.145729,
              32.984339
            ],
            [
              -80.159863,
              32.996128
            ],
            [
              -80.16766,
              33.002498
            ],
            [
              -80.162593,
              33.010502
            ],
            [
              -80.163441,
              33.014342
            ],
            [
              -80.1632,
              33.019245
            ],
            [
              -80.149246,
              33.021601
            ],
            [
              -80.164252,
              33.032294
            ],
            [
              -80.184401,
              33.047736
            ],
            [
              -80.244542,
              33.093869
            ],
            [
              -80.279567,
              33.119213
            ],
            [
              -80.330057,
              33.148504
            ],
            [
              -80.310956,
              33.158355
            ],
            [
              -80.297577,
              33.181838
            ],
            [
              -80.330818,
              33.198424
            ],
            [
              -80.348407,
              33.248922
            ],
            [
              -80.361851,
              33.257443
            ],
            [
              -80.39421,
              33.264663
            ],
            [
              -80.427092,
              33.257762
            ],
            [
              -80.484578,
              33.280034
            ],
            [
              -80.495176,
              33.302546
            ],
            [
              -80.494129,
              33.321167
            ],
            [
              -80.50279,
              33.334496
            ],
            [
              -80.790296,
              33.18084
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "027",
      "COUNTYNS": "01247990",
      "AFFGEOID": "0500000US45027",
      "GEOID": "45027",
      "NAME": "Clarendon",
      "LSAD": "06",
      "ALAND": 1571964062,
      "AWATER": 229790157,
      "County_state": "Clarendon,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.534085,
              33.643911
            ],
            [
              -80.540514,
              33.629032
            ],
            [
              -80.521569,
              33.61653
            ],
            [
              -80.519799,
              33.597368
            ],
            [
              -80.503335,
              33.591624
            ],
            [
              -80.515234,
              33.574705
            ],
            [
              -80.496273,
              33.558481
            ],
            [
              -80.485019,
              33.560047
            ],
            [
              -80.468421,
              33.541058
            ],
            [
              -80.451861,
              33.545916
            ],
            [
              -80.447324,
              33.524527
            ],
            [
              -80.46836,
              33.523237
            ],
            [
              -80.456102,
              33.510821
            ],
            [
              -80.460048,
              33.502895
            ],
            [
              -80.439085,
              33.502025
            ],
            [
              -80.436325,
              33.477066
            ],
            [
              -80.393145,
              33.445339
            ],
            [
              -80.354539,
              33.430408
            ],
            [
              -80.322568,
              33.431371
            ],
            [
              -80.276675,
              33.446927
            ],
            [
              -80.272655,
              33.438181
            ],
            [
              -80.242419,
              33.449595
            ],
            [
              -80.238075,
              33.439582
            ],
            [
              -80.222267,
              33.443716
            ],
            [
              -80.208955,
              33.442662
            ],
            [
              -80.208162,
              33.452291
            ],
            [
              -80.191574,
              33.447507
            ],
            [
              -80.15072,
              33.448177
            ],
            [
              -80.144243,
              33.464106
            ],
            [
              -80.152045,
              33.478572
            ],
            [
              -80.123303,
              33.502528
            ],
            [
              -80.101697,
              33.496891
            ],
            [
              -80.045073,
              33.600835
            ],
            [
              -80.020879,
              33.645804
            ],
            [
              -79.993233,
              33.704458
            ],
            [
              -79.974382,
              33.72159
            ],
            [
              -79.995638,
              33.767906
            ],
            [
              -79.944505,
              33.810214
            ],
            [
              -79.915305,
              33.818648
            ],
            [
              -79.87562,
              33.885245
            ],
            [
              -79.902425,
              33.889063
            ],
            [
              -79.945948,
              33.885997
            ],
            [
              -79.974601,
              33.946527
            ],
            [
              -80.042855,
              33.914265
            ],
            [
              -80.121612,
              33.869555
            ],
            [
              -80.13548,
              33.868765
            ],
            [
              -80.195371,
              33.838982
            ],
            [
              -80.255904,
              33.803708
            ],
            [
              -80.262672,
              33.795999
            ],
            [
              -80.274059,
              33.785868
            ],
            [
              -80.397131,
              33.767821
            ],
            [
              -80.396993,
              33.746864
            ],
            [
              -80.392003,
              33.71555
            ],
            [
              -80.434426,
              33.71189
            ],
            [
              -80.425935,
              33.72409
            ],
            [
              -80.430925,
              33.738306
            ],
            [
              -80.449293,
              33.737528
            ],
            [
              -80.454441,
              33.713523
            ],
            [
              -80.478709,
              33.714265
            ],
            [
              -80.497595,
              33.67432
            ],
            [
              -80.534085,
              33.643911
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "031",
      "COUNTYNS": "01247992",
      "AFFGEOID": "0500000US45031",
      "GEOID": "45031",
      "NAME": "Darlington",
      "LSAD": "06",
      "ALAND": 1453370732,
      "AWATER": 14647308,
      "County_state": "Darlington,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.288596,
              34.366207
            ],
            [
              -80.288692,
              34.364139
            ],
            [
              -80.279235,
              34.351743
            ],
            [
              -80.252869,
              34.340714
            ],
            [
              -80.243558,
              34.323884
            ],
            [
              -80.206302,
              34.339055
            ],
            [
              -80.190851,
              34.366637
            ],
            [
              -80.177225,
              34.366203
            ],
            [
              -80.175036,
              34.326271
            ],
            [
              -80.164741,
              34.306277
            ],
            [
              -80.135413,
              34.284552
            ],
            [
              -80.135803,
              34.272437
            ],
            [
              -80.134541,
              34.270307
            ],
            [
              -80.123159,
              34.25346
            ],
            [
              -80.117721,
              34.238319
            ],
            [
              -80.111733,
              34.228613
            ],
            [
              -80.112727,
              34.197018
            ],
            [
              -80.158417,
              34.161233
            ],
            [
              -80.13452,
              34.128492
            ],
            [
              -80.097675,
              34.107473
            ],
            [
              -80.072808,
              34.086013
            ],
            [
              -79.974065,
              34.146895
            ],
            [
              -79.961433,
              34.154888
            ],
            [
              -79.91392,
              34.184793
            ],
            [
              -79.889652,
              34.200017
            ],
            [
              -79.837497,
              34.233031
            ],
            [
              -79.819058,
              34.245347
            ],
            [
              -79.801083,
              34.254952
            ],
            [
              -79.785325,
              34.249387
            ],
            [
              -79.771283,
              34.248953
            ],
            [
              -79.749383,
              34.245053
            ],
            [
              -79.752562,
              34.250616
            ],
            [
              -79.729566,
              34.29519
            ],
            [
              -79.68455,
              34.284591
            ],
            [
              -79.657764,
              34.30519
            ],
            [
              -79.660364,
              34.32228
            ],
            [
              -79.692873,
              34.356841
            ],
            [
              -79.6737,
              34.374394
            ],
            [
              -79.689442,
              34.381765
            ],
            [
              -79.683401,
              34.399148
            ],
            [
              -79.701493,
              34.405343
            ],
            [
              -79.715279,
              34.394374
            ],
            [
              -79.725012,
              34.417243
            ],
            [
              -79.750566,
              34.436797
            ],
            [
              -79.749784,
              34.450595
            ],
            [
              -79.749274,
              34.467963
            ],
            [
              -79.734312,
              34.465992
            ],
            [
              -79.721901,
              34.48802
            ],
            [
              -79.727827,
              34.496824
            ],
            [
              -79.754578,
              34.505569
            ],
            [
              -79.764004,
              34.518314
            ],
            [
              -79.770102,
              34.496266
            ],
            [
              -79.782927,
              34.497769
            ],
            [
              -79.790503,
              34.485296
            ],
            [
              -79.809472,
              34.486079
            ],
            [
              -79.814885,
              34.497192
            ],
            [
              -79.833489,
              34.513434
            ],
            [
              -79.829981,
              34.531925
            ],
            [
              -79.843676,
              34.533085
            ],
            [
              -79.862028,
              34.521041
            ],
            [
              -79.863641,
              34.508733
            ],
            [
              -79.884967,
              34.491113
            ],
            [
              -79.902076,
              34.489234
            ],
            [
              -79.919846,
              34.495764
            ],
            [
              -79.94064,
              34.488042
            ],
            [
              -79.965322,
              34.48761
            ],
            [
              -79.981038,
              34.501511
            ],
            [
              -80.027829,
              34.486322
            ],
            [
              -80.04608,
              34.492267
            ],
            [
              -80.076511,
              34.476339
            ],
            [
              -80.154244,
              34.436051
            ],
            [
              -80.288596,
              34.366207
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "033",
      "COUNTYNS": "01247993",
      "AFFGEOID": "0500000US45033",
      "GEOID": "45033",
      "NAME": "Dillon",
      "LSAD": "06",
      "ALAND": 1049113581,
      "AWATER": 4545513,
      "County_state": "Dillon,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.633413,
              34.297994
            ],
            [
              -79.617609,
              34.297171
            ],
            [
              -79.601003,
              34.290532
            ],
            [
              -79.561567,
              34.257944
            ],
            [
              -79.54793,
              34.230913
            ],
            [
              -79.554711,
              34.247016
            ],
            [
              -79.524424,
              34.251585
            ],
            [
              -79.480712,
              34.288081
            ],
            [
              -79.44015,
              34.292831
            ],
            [
              -79.432795,
              34.293889
            ],
            [
              -79.41104,
              34.300172
            ],
            [
              -79.374529,
              34.298855
            ],
            [
              -79.305384,
              34.269307
            ],
            [
              -79.294397,
              34.276994
            ],
            [
              -79.271857,
              34.269754
            ],
            [
              -79.259536,
              34.259031
            ],
            [
              -79.239802,
              34.254339
            ],
            [
              -79.21432,
              34.255539
            ],
            [
              -79.173475,
              34.287534
            ],
            [
              -79.128165,
              34.253652
            ],
            [
              -79.093731,
              34.277962
            ],
            [
              -79.086067,
              34.294956
            ],
            [
              -79.07125513793659,
              34.2993123985096
            ],
            [
              -79.10880981613849,
              34.330876919778895
            ],
            [
              -79.143242,
              34.359817
            ],
            [
              -79.151485,
              34.366753
            ],
            [
              -79.190739,
              34.399751
            ],
            [
              -79.192041,
              34.40104
            ],
            [
              -79.198982,
              34.406699
            ],
            [
              -79.21529988810791,
              34.4205410507552
            ],
            [
              -79.215993,
              34.421129
            ],
            [
              -79.2356025955262,
              34.4377625087099
            ],
            [
              -79.244886,
              34.445637
            ],
            [
              -79.249763,
              34.449774
            ],
            [
              -79.286703,
              34.482664
            ],
            [
              -79.306653,
              34.500426
            ],
            [
              -79.323249,
              34.514634
            ],
            [
              -79.324854,
              34.516282
            ],
            [
              -79.331328,
              34.521869
            ],
            [
              -79.358317,
              34.545358
            ],
            [
              -79.450034,
              34.621036
            ],
            [
              -79.528833,
              34.482744
            ],
            [
              -79.633413,
              34.297994
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "043",
      "COUNTYNS": "01248002",
      "AFFGEOID": "0500000US45043",
      "GEOID": "45043",
      "NAME": "Georgetown",
      "LSAD": "06",
      "ALAND": 2107183535,
      "AWATER": 572535186,
      "County_state": "Georgetown,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.677246,
              33.336762
            ],
            [
              -79.680883,
              33.319604
            ],
            [
              -79.677014,
              33.304944
            ],
            [
              -79.664251,
              33.298322
            ],
            [
              -79.630992,
              33.292928
            ],
            [
              -79.606574,
              33.27932
            ],
            [
              -79.56225,
              33.272523
            ],
            [
              -79.561018,
              33.262315
            ],
            [
              -79.549763,
              33.264979
            ],
            [
              -79.546461,
              33.255456
            ],
            [
              -79.493993,
              33.23603
            ],
            [
              -79.482644,
              33.245917
            ],
            [
              -79.473933,
              33.229771
            ],
            [
              -79.458734,
              33.235219
            ],
            [
              -79.446699,
              33.213458
            ],
            [
              -79.438387,
              33.215136
            ],
            [
              -79.404858,
              33.184929
            ],
            [
              -79.350816,
              33.153528
            ],
            [
              -79.29125,
              33.146563
            ],
            [
              -79.268169,
              33.134477
            ],
            [
              -79.29075361739139,
              33.110050747265504
            ],
            [
              -79.24609,
              33.124865
            ],
            [
              -79.238262,
              33.137055
            ],
            [
              -79.215453,
              33.155569
            ],
            [
              -79.195631,
              33.166016
            ],
            [
              -79.18787,
              33.173712
            ],
            [
              -79.172394,
              33.206577
            ],
            [
              -79.180563,
              33.237955
            ],
            [
              -79.180318,
              33.254141
            ],
            [
              -79.162332,
              33.327246
            ],
            [
              -79.158429,
              33.332811
            ],
            [
              -79.152035,
              33.350925
            ],
            [
              -79.147496,
              33.378243
            ],
            [
              -79.135441,
              33.403867
            ],
            [
              -79.10136,
              33.461016
            ],
            [
              -79.084588,
              33.483669
            ],
            [
              -79.041125,
              33.523773
            ],
            [
              -79.028516,
              33.533365
            ],
            [
              -79.007356,
              33.566565
            ],
            [
              -79.0021085980918,
              33.5721139305889
            ],
            [
              -79.01568,
              33.569108
            ],
            [
              -79.016519,
              33.572176
            ],
            [
              -79.100653,
              33.571807
            ],
            [
              -79.102181,
              33.581675
            ],
            [
              -79.096787,
              33.600349
            ],
            [
              -79.121278,
              33.604775
            ],
            [
              -79.134212,
              33.63802
            ],
            [
              -79.137683,
              33.66413
            ],
            [
              -79.14603,
              33.65798
            ],
            [
              -79.151935,
              33.658191
            ],
            [
              -79.152184,
              33.677371
            ],
            [
              -79.190264,
              33.705787
            ],
            [
              -79.246384,
              33.695347
            ],
            [
              -79.240655,
              33.704707
            ],
            [
              -79.266806,
              33.703173
            ],
            [
              -79.275057,
              33.708852
            ],
            [
              -79.271664,
              33.721855
            ],
            [
              -79.282995,
              33.715972
            ],
            [
              -79.291574,
              33.728981
            ],
            [
              -79.286248,
              33.737386
            ],
            [
              -79.292578,
              33.750167
            ],
            [
              -79.317041,
              33.779878
            ],
            [
              -79.337764,
              33.774605
            ],
            [
              -79.346554,
              33.755334
            ],
            [
              -79.392658,
              33.711812
            ],
            [
              -79.409332,
              33.68889
            ],
            [
              -79.419842,
              33.648468
            ],
            [
              -79.433288,
              33.621552
            ],
            [
              -79.44247,
              33.596033
            ],
            [
              -79.433639,
              33.576709
            ],
            [
              -79.483059,
              33.53703
            ],
            [
              -79.499341,
              33.515804
            ],
            [
              -79.53808,
              33.496896
            ],
            [
              -79.544578,
              33.487535
            ],
            [
              -79.59011,
              33.44354
            ],
            [
              -79.595994,
              33.421548
            ],
            [
              -79.625077,
              33.390405
            ],
            [
              -79.634419,
              33.372146
            ],
            [
              -79.6524,
              33.361327
            ],
            [
              -79.677246,
              33.336762
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "049",
      "COUNTYNS": "01248004",
      "AFFGEOID": "0500000US45049",
      "GEOID": "45049",
      "NAME": "Hampton",
      "LSAD": "06",
      "ALAND": 1450123401,
      "AWATER": 7276755,
      "County_state": "Hampton,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.427517,
              32.701896
            ],
            [
              -81.426735,
              32.700867
            ],
            [
              -81.4131,
              32.692648
            ],
            [
              -81.411609,
              32.693145
            ],
            [
              -81.411157,
              32.693959
            ],
            [
              -81.41075,
              32.694772
            ],
            [
              -81.409982,
              32.695179
            ],
            [
              -81.409349,
              32.695269
            ],
            [
              -81.40831,
              32.694908
            ],
            [
              -81.401256,
              32.680156
            ],
            [
              -81.401029,
              32.677494
            ],
            [
              -81.404287,
              32.667798
            ],
            [
              -81.406646,
              32.662515
            ],
            [
              -81.4073,
              32.66156
            ],
            [
              -81.407193,
              32.660519
            ],
            [
              -81.405273,
              32.656517
            ],
            [
              -81.398314,
              32.656307
            ],
            [
              -81.393818,
              32.653491
            ],
            [
              -81.393033,
              32.651543
            ],
            [
              -81.394589,
              32.64957
            ],
            [
              -81.403582,
              32.643398
            ],
            [
              -81.405109,
              32.64269
            ],
            [
              -81.404238,
              32.638258
            ],
            [
              -81.402735,
              32.637032
            ],
            [
              -81.402846,
              32.63621
            ],
            [
              -81.407271,
              32.631737
            ],
            [
              -81.40933,
              32.631096
            ],
            [
              -81.41026,
              32.631392
            ],
            [
              -81.411523,
              32.632907
            ],
            [
              -81.413411,
              32.637368
            ],
            [
              -81.414761,
              32.63744
            ],
            [
              -81.417014,
              32.636147
            ],
            [
              -81.418431,
              32.634704
            ],
            [
              -81.41866,
              32.629392
            ],
            [
              -81.411906,
              32.61841
            ],
            [
              -81.404714,
              32.611207
            ],
            [
              -81.397106,
              32.605587
            ],
            [
              -81.393865,
              32.60234
            ],
            [
              -81.389338,
              32.595436
            ],
            [
              -81.3892612409816,
              32.5953827593042
            ],
            [
              -81.380999,
              32.589652
            ],
            [
              -81.379216,
              32.589022
            ],
            [
              -81.376237,
              32.589217
            ],
            [
              -81.373178,
              32.592115
            ],
            [
              -81.37157,
              32.592018
            ],
            [
              -81.369757,
              32.591231
            ],
            [
              -81.368982,
              32.590025
            ],
            [
              -81.368386,
              32.584221
            ],
            [
              -81.366964,
              32.577059
            ],
            [
              -81.328753,
              32.561228
            ],
            [
              -81.320588,
              32.559534
            ],
            [
              -81.309009,
              32.56097
            ],
            [
              -81.300593,
              32.562843
            ],
            [
              -81.297955,
              32.563026
            ],
            [
              -81.29676,
              32.562648
            ],
            [
              -81.281324,
              32.556464
            ],
            [
              -81.2812975973478,
              32.556440280647
            ],
            [
              -81.22258,
              32.603825
            ],
            [
              -81.207206,
              32.610526
            ],
            [
              -81.014625,
              32.753058
            ],
            [
              -80.99534,
              32.730547
            ],
            [
              -80.976116,
              32.720221
            ],
            [
              -80.964156,
              32.694701
            ],
            [
              -80.945865,
              32.687348
            ],
            [
              -80.951469,
              32.660128
            ],
            [
              -80.962627,
              32.653343
            ],
            [
              -80.95867,
              32.642507
            ],
            [
              -80.929287,
              32.636574
            ],
            [
              -80.918971,
              32.626561
            ],
            [
              -80.902448,
              32.621561
            ],
            [
              -80.869705,
              32.660935
            ],
            [
              -80.857214,
              32.675955
            ],
            [
              -80.837213,
              32.699821
            ],
            [
              -80.826309,
              32.704321
            ],
            [
              -80.845164,
              32.72011
            ],
            [
              -80.848527,
              32.730264
            ],
            [
              -80.866191,
              32.775581
            ],
            [
              -80.895888,
              32.810805
            ],
            [
              -80.900196,
              32.832813
            ],
            [
              -80.944762,
              32.875698
            ],
            [
              -80.958832,
              32.87668
            ],
            [
              -80.976631,
              32.897285
            ],
            [
              -80.986703,
              32.900674
            ],
            [
              -80.999343,
              32.931247
            ],
            [
              -81.02963,
              32.973912
            ],
            [
              -81.053065,
              32.989478
            ],
            [
              -81.064532,
              33.013279
            ],
            [
              -81.082291,
              33.02663
            ],
            [
              -81.219563,
              32.95335
            ],
            [
              -81.236956,
              32.940244
            ],
            [
              -81.237681,
              32.906731
            ],
            [
              -81.260116,
              32.902979
            ],
            [
              -81.276729,
              32.892086
            ],
            [
              -81.261662,
              32.839139
            ],
            [
              -81.329101,
              32.7888
            ],
            [
              -81.338543,
              32.768456
            ],
            [
              -81.367973,
              32.760601
            ],
            [
              -81.39399,
              32.744867
            ],
            [
              -81.410281,
              32.744653
            ],
            [
              -81.410563,
              32.743244
            ],
            [
              -81.410845,
              32.741694
            ],
            [
              -81.411549,
              32.740145
            ],
            [
              -81.41267,
              32.739083
            ],
            [
              -81.418542,
              32.732586
            ],
            [
              -81.420516,
              32.720238
            ],
            [
              -81.421194,
              32.711978
            ],
            [
              -81.427517,
              32.701896
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "057",
      "COUNTYNS": "01248008",
      "AFFGEOID": "0500000US45057",
      "GEOID": "45057",
      "NAME": "Lancaster",
      "LSAD": "06",
      "ALAND": 1422134720,
      "AWATER": 15444070,
      "County_state": "Lancaster,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.909458,
              35.016663
            ],
            [
              -80.902296,
              35.005742
            ],
            [
              -80.885396,
              34.990211
            ],
            [
              -80.889258,
              34.985206
            ],
            [
              -80.888957,
              34.984793
            ],
            [
              -80.888599,
              34.984631
            ],
            [
              -80.887753,
              34.984771
            ],
            [
              -80.887549,
              34.984557
            ],
            [
              -80.887553,
              34.984311
            ],
            [
              -80.88794,
              34.983799
            ],
            [
              -80.887928,
              34.983282
            ],
            [
              -80.887351,
              34.982402
            ],
            [
              -80.887017,
              34.981984
            ],
            [
              -80.88654,
              34.980952
            ],
            [
              -80.886353,
              34.979783
            ],
            [
              -80.885942,
              34.978852
            ],
            [
              -80.885937,
              34.978391
            ],
            [
              -80.886053,
              34.977902
            ],
            [
              -80.885902,
              34.977303
            ],
            [
              -80.885896,
              34.976702
            ],
            [
              -80.885457,
              34.976182
            ],
            [
              -80.884421,
              34.975716
            ],
            [
              -80.884053,
              34.975168
            ],
            [
              -80.884391,
              34.974674
            ],
            [
              -80.884296,
              34.97445
            ],
            [
              -80.883758,
              34.974151
            ],
            [
              -80.883711,
              34.973799
            ],
            [
              -80.883878,
              34.973361
            ],
            [
              -80.883781,
              34.972879
            ],
            [
              -80.883274,
              34.972132
            ],
            [
              -80.88306,
              34.971685
            ],
            [
              -80.882413,
              34.97084
            ],
            [
              -80.882389,
              34.970458
            ],
            [
              -80.882515,
              34.970312
            ],
            [
              -80.882984,
              34.969897
            ],
            [
              -80.88326,
              34.96919
            ],
            [
              -80.884237,
              34.968285
            ],
            [
              -80.884189,
              34.967458
            ],
            [
              -80.884587,
              34.966141
            ],
            [
              -80.884201,
              34.965694
            ],
            [
              -80.883148,
              34.965049
            ],
            [
              -80.88264,
              34.965054
            ],
            [
              -80.882453,
              34.965107
            ],
            [
              -80.882499,
              34.965879
            ],
            [
              -80.882273,
              34.966526
            ],
            [
              -80.881976,
              34.966734
            ],
            [
              -80.881465,
              34.966548
            ],
            [
              -80.880857,
              34.965992
            ],
            [
              -80.880612,
              34.965588
            ],
            [
              -80.880769,
              34.964873
            ],
            [
              -80.879842,
              34.962515
            ],
            [
              -80.878745,
              34.963142
            ],
            [
              -80.878275,
              34.963519
            ],
            [
              -80.877577,
              34.963518
            ],
            [
              -80.877291,
              34.963874
            ],
            [
              -80.876636,
              34.964283
            ],
            [
              -80.875678,
              34.965018
            ],
            [
              -80.875039,
              34.965252
            ],
            [
              -80.874218,
              34.965325
            ],
            [
              -80.873993,
              34.965091
            ],
            [
              -80.867181,
              34.929104
            ],
            [
              -80.878262,
              34.902951
            ],
            [
              -80.87845,
              34.86197
            ],
            [
              -80.86707,
              34.847334
            ],
            [
              -80.888757,
              34.831191
            ],
            [
              -80.898215,
              34.820746
            ],
            [
              -80.876121,
              34.788047
            ],
            [
              -80.878278,
              34.759078
            ],
            [
              -80.865999,
              34.706169
            ],
            [
              -80.860274,
              34.695847
            ],
            [
              -80.888469,
              34.668603
            ],
            [
              -80.896406,
              34.631781
            ],
            [
              -80.891397,
              34.596787
            ],
            [
              -80.882346,
              34.584744
            ],
            [
              -80.872863,
              34.552965
            ],
            [
              -80.877491,
              34.543146
            ],
            [
              -80.88172,
              34.500117
            ],
            [
              -80.898722,
              34.486694
            ],
            [
              -80.879227,
              34.457776
            ],
            [
              -80.743345,
              34.539916
            ],
            [
              -80.745617,
              34.512591
            ],
            [
              -80.739966,
              34.479957
            ],
            [
              -80.713329,
              34.487334
            ],
            [
              -80.700049,
              34.515342
            ],
            [
              -80.689079,
              34.515324
            ],
            [
              -80.676062,
              34.493116
            ],
            [
              -80.658417,
              34.478612
            ],
            [
              -80.632213,
              34.491433
            ],
            [
              -80.632105,
              34.480609
            ],
            [
              -80.61363,
              34.499939
            ],
            [
              -80.614278,
              34.488808
            ],
            [
              -80.599085,
              34.495187
            ],
            [
              -80.548906,
              34.493264
            ],
            [
              -80.527821,
              34.504003
            ],
            [
              -80.550552,
              34.560116
            ],
            [
              -80.408373,
              34.614765
            ],
            [
              -80.435036,
              34.642075
            ],
            [
              -80.431648,
              34.659761
            ],
            [
              -80.460782,
              34.676984
            ],
            [
              -80.473065,
              34.692357
            ],
            [
              -80.488532,
              34.704584
            ],
            [
              -80.48976,
              34.720216
            ],
            [
              -80.487733,
              34.722265
            ],
            [
              -80.517871,
              34.762716
            ],
            [
              -80.544479,
              34.783956
            ],
            [
              -80.56170938792269,
              34.8174816094994
            ],
            [
              -80.621222,
              34.818174
            ],
            [
              -80.625993,
              34.818239
            ],
            [
              -80.626077,
              34.818217
            ],
            [
              -80.644656,
              34.818568
            ],
            [
              -80.646601,
              34.818592
            ],
            [
              -80.6710408026229,
              34.8187974422605
            ],
            [
              -80.684074,
              34.818907
            ],
            [
              -80.72694647607639,
              34.8192681888075
            ],
            [
              -80.771792,
              34.819646
            ],
            [
              -80.777712,
              34.819697
            ],
            [
              -80.797543,
              34.819786
            ],
            [
              -80.796997,
              34.823874
            ],
            [
              -80.795109,
              34.837999
            ],
            [
              -80.786517350476,
              34.902292048549896
            ],
            [
              -80.782042,
              34.935782
            ],
            [
              -80.806461,
              34.962894
            ],
            [
              -80.806784,
              34.963249
            ],
            [
              -80.82156,
              34.979695
            ],
            [
              -80.8403843929928,
              35.00163702423549
            ],
            [
              -80.88486293137589,
              35.0534819452132
            ],
            [
              -80.884887,
              35.05351
            ],
            [
              -80.9061772887421,
              35.076359768490796
            ],
            [
              -80.9062325339664,
              35.0764190603399
            ],
            [
              -80.905885,
              35.046783
            ],
            [
              -80.897322,
              35.033629
            ],
            [
              -80.909458,
              35.016663
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "061",
      "COUNTYNS": "01245652",
      "AFFGEOID": "0500000US45061",
      "GEOID": "45061",
      "NAME": "Lee",
      "LSAD": "06",
      "ALAND": 1062372377,
      "AWATER": 2715077,
      "County_state": "Lee,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.479857,
              34.168696
            ],
            [
              -80.481318,
              34.112622
            ],
            [
              -80.405526,
              34.081369
            ],
            [
              -80.378741,
              34.080701
            ],
            [
              -80.321721,
              34.080356
            ],
            [
              -80.303245,
              34.063222
            ],
            [
              -80.294931,
              34.039437
            ],
            [
              -80.26906,
              34.0351
            ],
            [
              -80.250224,
              33.99236
            ],
            [
              -80.228344,
              33.995178
            ],
            [
              -80.188324,
              34.004836
            ],
            [
              -80.180426,
              33.952083
            ],
            [
              -80.164684,
              33.957305
            ],
            [
              -80.070364,
              34.00792
            ],
            [
              -80.02676,
              34.008543
            ],
            [
              -80.000594,
              34.047877
            ],
            [
              -80.026068,
              34.073364
            ],
            [
              -80.072808,
              34.086013
            ],
            [
              -80.097675,
              34.107473
            ],
            [
              -80.13452,
              34.128492
            ],
            [
              -80.158417,
              34.161233
            ],
            [
              -80.112727,
              34.197018
            ],
            [
              -80.111733,
              34.228613
            ],
            [
              -80.117721,
              34.238319
            ],
            [
              -80.123159,
              34.25346
            ],
            [
              -80.134541,
              34.270307
            ],
            [
              -80.135803,
              34.272437
            ],
            [
              -80.135413,
              34.284552
            ],
            [
              -80.164741,
              34.306277
            ],
            [
              -80.175036,
              34.326271
            ],
            [
              -80.177225,
              34.366203
            ],
            [
              -80.190851,
              34.366637
            ],
            [
              -80.206302,
              34.339055
            ],
            [
              -80.243558,
              34.323884
            ],
            [
              -80.252869,
              34.340714
            ],
            [
              -80.279235,
              34.351743
            ],
            [
              -80.288692,
              34.364139
            ],
            [
              -80.301659,
              34.357336
            ],
            [
              -80.325107,
              34.339291
            ],
            [
              -80.363759,
              34.339547
            ],
            [
              -80.365772,
              34.328153
            ],
            [
              -80.353031,
              34.30333
            ],
            [
              -80.382004,
              34.305188
            ],
            [
              -80.400922,
              34.295196
            ],
            [
              -80.421654,
              34.294301
            ],
            [
              -80.437359,
              34.284502
            ],
            [
              -80.431036,
              34.276632
            ],
            [
              -80.4308,
              34.226997
            ],
            [
              -80.44324,
              34.208726
            ],
            [
              -80.454545,
              34.207963
            ],
            [
              -80.464667,
              34.178549
            ],
            [
              -80.479857,
              34.168696
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "065",
      "COUNTYNS": "01248012",
      "AFFGEOID": "0500000US45065",
      "GEOID": "45065",
      "NAME": "McCormick",
      "LSAD": "06",
      "ALAND": 930141786,
      "AWATER": 89983085,
      "County_state": "McCormick,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.5944878368938,
              34.0139372478806
            ],
            [
              -82.591855,
              34.009018
            ],
            [
              -82.589245,
              34.000118
            ],
            [
              -82.586234,
              33.997151
            ],
            [
              -82.583394,
              33.995286
            ],
            [
              -82.577735,
              33.993743
            ],
            [
              -82.576222,
              33.993106
            ],
            [
              -82.57554,
              33.992049
            ],
            [
              -82.575351,
              33.990904
            ],
            [
              -82.57633,
              33.989694
            ],
            [
              -82.578244,
              33.988671
            ],
            [
              -82.579996,
              33.987011
            ],
            [
              -82.580571,
              33.98514
            ],
            [
              -82.580551,
              33.982463
            ],
            [
              -82.579576,
              33.979761
            ],
            [
              -82.57754,
              33.977034
            ],
            [
              -82.574724,
              33.974113
            ],
            [
              -82.569864,
              33.970684
            ],
            [
              -82.568288,
              33.968772
            ],
            [
              -82.566145,
              33.9639
            ],
            [
              -82.5657,
              33.958682
            ],
            [
              -82.564582,
              33.95581
            ],
            [
              -82.56453073781259,
              33.955740805641796
            ],
            [
              -82.556835,
              33.945353
            ],
            [
              -82.554497,
              33.943819
            ],
            [
              -82.543128,
              33.940949
            ],
            [
              -82.53977,
              33.941551
            ],
            [
              -82.534111,
              33.943651
            ],
            [
              -82.526741,
              33.943765
            ],
            [
              -82.524515,
              33.94336
            ],
            [
              -82.51295,
              33.936969
            ],
            [
              -82.50764,
              33.931456
            ],
            [
              -82.503584,
              33.926048
            ],
            [
              -82.496109,
              33.913459
            ],
            [
              -82.492929,
              33.909754
            ],
            [
              -82.480111,
              33.901897
            ],
            [
              -82.469913,
              33.892838
            ],
            [
              -82.459391,
              33.886386
            ],
            [
              -82.455105,
              33.88165
            ],
            [
              -82.448109,
              33.877543
            ],
            [
              -82.440503,
              33.875123
            ],
            [
              -82.438644,
              33.873919
            ],
            [
              -82.43115,
              33.867051
            ],
            [
              -82.429164,
              33.865844
            ],
            [
              -82.422803,
              33.863754
            ],
            [
              -82.417871,
              33.864233
            ],
            [
              -82.414259,
              33.865348
            ],
            [
              -82.408354,
              33.86632
            ],
            [
              -82.403881,
              33.865477
            ],
            [
              -82.400517,
              33.863343
            ],
            [
              -82.395736,
              33.859089
            ],
            [
              -82.390527,
              33.857162
            ],
            [
              -82.384973,
              33.854428
            ],
            [
              -82.37975,
              33.851086
            ],
            [
              -82.374286,
              33.84559
            ],
            [
              -82.371775,
              33.843813
            ],
            [
              -82.369107,
              33.842375
            ],
            [
              -82.351881,
              33.836432
            ],
            [
              -82.346933,
              33.834298
            ],
            [
              -82.337829,
              33.827156
            ],
            [
              -82.32448,
              33.820033
            ],
            [
              -82.314746,
              33.811499
            ],
            [
              -82.313339,
              33.809205
            ],
            [
              -82.308997,
              33.805892
            ],
            [
              -82.302885,
              33.802907
            ],
            [
              -82.300213,
              33.800627
            ],
            [
              -82.29928,
              33.798939
            ],
            [
              -82.298923,
              33.795839
            ],
            [
              -82.299601,
              33.786483
            ],
            [
              -82.299393,
              33.785037
            ],
            [
              -82.298286,
              33.783518
            ],
            [
              -82.294984,
              33.781868
            ],
            [
              -82.292468,
              33.782406
            ],
            [
              -82.289762,
              33.782032
            ],
            [
              -82.285804,
              33.780058
            ],
            [
              -82.28106,
              33.776056
            ],
            [
              -82.277681,
              33.772032
            ],
            [
              -82.270445,
              33.767913
            ],
            [
              -82.267719,
              33.767651
            ],
            [
              -82.266127,
              33.766745
            ],
            [
              -82.265019,
              33.765742
            ],
            [
              -82.26438,
              33.763481
            ],
            [
              -82.263206,
              33.761962
            ],
            [
              -82.259471,
              33.760245
            ],
            [
              -82.258049,
              33.760429
            ],
            [
              -82.255267,
              33.75969
            ],
            [
              -82.247472,
              33.752591
            ],
            [
              -82.246161,
              33.746347
            ],
            [
              -82.240405,
              33.734901
            ],
            [
              -82.239098,
              33.730872
            ],
            [
              -82.235753,
              33.71439
            ],
            [
              -82.237192,
              33.70788
            ],
            [
              -82.234576,
              33.700216
            ],
            [
              -82.222709,
              33.689124
            ],
            [
              -82.218649,
              33.686299
            ],
            [
              -82.216868,
              33.6844
            ],
            [
              -82.212047,
              33.677317
            ],
            [
              -82.209677,
              33.67176
            ],
            [
              -82.208411,
              33.669872
            ],
            [
              -82.200718,
              33.66464
            ],
            [
              -82.199847,
              33.661758
            ],
            [
              -82.19983644896229,
              33.661320448468096
            ],
            [
              -82.199747,
              33.657611
            ],
            [
              -82.201186,
              33.646898
            ],
            [
              -82.196583,
              33.630582
            ],
            [
              -82.186154,
              33.62088
            ],
            [
              -82.179854,
              33.615945
            ],
            [
              -82.174351,
              33.613117
            ],
            [
              -82.1641411027551,
              33.6110870003388
            ],
            [
              -82.161908,
              33.610643
            ],
            [
              -82.158331,
              33.60971
            ],
            [
              -82.156288,
              33.60863
            ],
            [
              -82.153357,
              33.606319
            ],
            [
              -82.15106,
              33.600956
            ],
            [
              -82.148816,
              33.598092
            ],
            [
              -82.1450507828929,
              33.595676027919495
            ],
            [
              -82.142872,
              33.594278
            ],
            [
              -82.133523,
              33.590535
            ],
            [
              -82.12908,
              33.589925
            ],
            [
              -82.125864,
              33.590741
            ],
            [
              -82.120385,
              33.594885
            ],
            [
              -82.116545,
              33.596485
            ],
            [
              -82.11532750427301,
              33.5965013034719
            ],
            [
              -82.113661,
              33.600262
            ],
            [
              -82.095527,
              33.633869
            ],
            [
              -82.110474,
              33.655299
            ],
            [
              -82.131741,
              33.65952
            ],
            [
              -82.134481,
              33.673905
            ],
            [
              -82.148447,
              33.676772
            ],
            [
              -82.143924,
              33.684744
            ],
            [
              -82.166027,
              33.690316
            ],
            [
              -82.182888,
              33.723732
            ],
            [
              -82.164191,
              33.743367
            ],
            [
              -82.172379,
              33.765711
            ],
            [
              -82.149544,
              33.789131
            ],
            [
              -82.12351,
              33.827781
            ],
            [
              -82.113728,
              33.82988
            ],
            [
              -82.152044,
              33.878009
            ],
            [
              -82.155958,
              33.887346
            ],
            [
              -82.148275,
              33.902811
            ],
            [
              -82.1557,
              33.92929
            ],
            [
              -82.046974,
              33.95321
            ],
            [
              -82.04513,
              33.983396
            ],
            [
              -82.075687,
              33.979445
            ],
            [
              -82.150827,
              33.986717
            ],
            [
              -82.167716,
              33.973052
            ],
            [
              -82.200286,
              33.978597
            ],
            [
              -82.220076,
              33.990411
            ],
            [
              -82.237693,
              34.013146
            ],
            [
              -82.306243,
              33.971584
            ],
            [
              -82.326947,
              34.06412
            ],
            [
              -82.376589,
              34.057374
            ],
            [
              -82.452707,
              34.060344
            ],
            [
              -82.483551,
              34.081215
            ],
            [
              -82.49956,
              34.072098
            ],
            [
              -82.530567,
              34.071925
            ],
            [
              -82.558859,
              34.046458
            ],
            [
              -82.5944878368938,
              34.0139372478806
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "077",
      "COUNTYNS": "01248015",
      "AFFGEOID": "0500000US45077",
      "GEOID": "45077",
      "NAME": "Pickens",
      "LSAD": "06",
      "ALAND": 1285538264,
      "AWATER": 40609723,
      "County_state": "Pickens,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.86687,
              34.617425
            ],
            [
              -82.864806,
              34.613526
            ],
            [
              -82.855303,
              34.609245
            ],
            [
              -82.844963,
              34.618874
            ],
            [
              -82.852913,
              34.618641
            ],
            [
              -82.852484,
              34.614942
            ],
            [
              -82.856154,
              34.619032
            ],
            [
              -82.859479,
              34.618559
            ],
            [
              -82.858676,
              34.621664
            ],
            [
              -82.86687,
              34.617425
            ]
          ]
        ],
        [
          [
            [
              -82.943604,
              34.988154
            ],
            [
              -82.938639,
              34.980912
            ],
            [
              -82.915785,
              34.971836
            ],
            [
              -82.919189,
              34.960051
            ],
            [
              -82.903179,
              34.935229
            ],
            [
              -82.908473,
              34.919072
            ],
            [
              -82.900252,
              34.9024
            ],
            [
              -82.909288,
              34.894127
            ],
            [
              -82.904851,
              34.871327
            ],
            [
              -82.896306,
              34.837393
            ],
            [
              -82.896725,
              34.802896
            ],
            [
              -82.88352,
              34.791788
            ],
            [
              -82.88898,
              34.76082
            ],
            [
              -82.900144,
              34.74244
            ],
            [
              -82.873315,
              34.736804
            ],
            [
              -82.855425,
              34.724806
            ],
            [
              -82.865587,
              34.702389
            ],
            [
              -82.850148,
              34.696491
            ],
            [
              -82.852502,
              34.669497
            ],
            [
              -82.839867,
              34.652613
            ],
            [
              -82.822814,
              34.654404
            ],
            [
              -82.839307,
              34.644998
            ],
            [
              -82.854892,
              34.625103
            ],
            [
              -82.840253,
              34.623212
            ],
            [
              -82.83377,
              34.629954
            ],
            [
              -82.787568,
              34.672734
            ],
            [
              -82.675921,
              34.727525
            ],
            [
              -82.635241,
              34.747553
            ],
            [
              -82.528491,
              34.799206
            ],
            [
              -82.486746,
              34.819475
            ],
            [
              -82.48053,
              34.824014
            ],
            [
              -82.487188,
              34.832122
            ],
            [
              -82.480333,
              34.840544
            ],
            [
              -82.489136,
              34.841145
            ],
            [
              -82.484614,
              34.851675
            ],
            [
              -82.484345,
              34.856194
            ],
            [
              -82.483559,
              34.858693
            ],
            [
              -82.489112,
              34.860869
            ],
            [
              -82.487143,
              34.86514
            ],
            [
              -82.488936,
              34.867665
            ],
            [
              -82.487511,
              34.874901
            ],
            [
              -82.492534,
              34.879
            ],
            [
              -82.490178,
              34.885111
            ],
            [
              -82.496251,
              34.889023
            ],
            [
              -82.493784,
              34.895217
            ],
            [
              -82.515959,
              34.925915
            ],
            [
              -82.520755,
              34.926954
            ],
            [
              -82.525784,
              34.952584
            ],
            [
              -82.527411,
              34.965909
            ],
            [
              -82.547818,
              34.978343
            ],
            [
              -82.533627,
              34.986083
            ],
            [
              -82.543132,
              35.004391
            ],
            [
              -82.537866,
              35.015696
            ],
            [
              -82.594141,
              35.008205
            ],
            [
              -82.585752,
              35.032339
            ],
            [
              -82.598838,
              35.040887
            ],
            [
              -82.592316,
              35.060129
            ],
            [
              -82.574886,
              35.064128
            ],
            [
              -82.571505,
              35.074636
            ],
            [
              -82.588598,
              35.073973
            ],
            [
              -82.641153,
              35.060291
            ],
            [
              -82.676662,
              35.064745
            ],
            [
              -82.716028,
              35.054915
            ],
            [
              -82.749351,
              35.047829
            ],
            [
              -82.764464,
              35.068177
            ],
            [
              -82.770046,
              35.065476
            ],
            [
              -82.777376,
              35.064143
            ],
            [
              -82.781973,
              35.066817
            ],
            [
              -82.780546,
              35.069043
            ],
            [
              -82.779928,
              35.070435
            ],
            [
              -82.779928,
              35.072206
            ],
            [
              -82.779116,
              35.073674
            ],
            [
              -82.777407,
              35.076885
            ],
            [
              -82.776357,
              35.081349
            ],
            [
              -82.778651,
              35.083575
            ],
            [
              -82.781062,
              35.084492
            ],
            [
              -82.78113,
              35.084585
            ],
            [
              -82.783283,
              35.0856
            ],
            [
              -82.787867,
              35.085024
            ],
            [
              -82.809766,
              35.078748
            ],
            [
              -82.897499,
              35.056021
            ],
            [
              -82.918175,
              35.036214
            ],
            [
              -82.920589,
              35.015673
            ],
            [
              -82.943604,
              34.988154
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "085",
      "COUNTYNS": "01244717",
      "AFFGEOID": "0500000US45085",
      "GEOID": "45085",
      "NAME": "Sumter",
      "LSAD": "06",
      "ALAND": 1722537126,
      "AWATER": 44054520,
      "County_state": "Sumter,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.646379,
              33.999662
            ],
            [
              -80.633382,
              33.966967
            ],
            [
              -80.61576,
              33.965472
            ],
            [
              -80.628261,
              33.948781
            ],
            [
              -80.621527,
              33.933758
            ],
            [
              -80.634093,
              33.917333
            ],
            [
              -80.61703,
              33.895519
            ],
            [
              -80.625332,
              33.866811
            ],
            [
              -80.633545,
              33.85268
            ],
            [
              -80.619074,
              33.839308
            ],
            [
              -80.615676,
              33.814514
            ],
            [
              -80.596205,
              33.786431
            ],
            [
              -80.605638,
              33.757476
            ],
            [
              -80.620107,
              33.743237
            ],
            [
              -80.629638,
              33.73892
            ],
            [
              -80.615264,
              33.711835
            ],
            [
              -80.58978,
              33.684238
            ],
            [
              -80.565209,
              33.679712
            ],
            [
              -80.565379,
              33.666075
            ],
            [
              -80.534085,
              33.643911
            ],
            [
              -80.497595,
              33.67432
            ],
            [
              -80.478709,
              33.714265
            ],
            [
              -80.454441,
              33.713523
            ],
            [
              -80.449293,
              33.737528
            ],
            [
              -80.430925,
              33.738306
            ],
            [
              -80.425935,
              33.72409
            ],
            [
              -80.434426,
              33.71189
            ],
            [
              -80.392003,
              33.71555
            ],
            [
              -80.396993,
              33.746864
            ],
            [
              -80.397131,
              33.767821
            ],
            [
              -80.274059,
              33.785868
            ],
            [
              -80.262672,
              33.795999
            ],
            [
              -80.255904,
              33.803708
            ],
            [
              -80.195371,
              33.838982
            ],
            [
              -80.13548,
              33.868765
            ],
            [
              -80.121612,
              33.869555
            ],
            [
              -80.042855,
              33.914265
            ],
            [
              -79.974601,
              33.946527
            ],
            [
              -79.893517,
              33.988017
            ],
            [
              -79.903823,
              34.002359
            ],
            [
              -79.974238,
              34.028974
            ],
            [
              -79.98537,
              34.037911
            ],
            [
              -80.000198,
              34.046508
            ],
            [
              -80.000594,
              34.047877
            ],
            [
              -80.02676,
              34.008543
            ],
            [
              -80.070364,
              34.00792
            ],
            [
              -80.164684,
              33.957305
            ],
            [
              -80.180426,
              33.952083
            ],
            [
              -80.188324,
              34.004836
            ],
            [
              -80.228344,
              33.995178
            ],
            [
              -80.250224,
              33.99236
            ],
            [
              -80.26906,
              34.0351
            ],
            [
              -80.294931,
              34.039437
            ],
            [
              -80.303245,
              34.063222
            ],
            [
              -80.321721,
              34.080356
            ],
            [
              -80.378741,
              34.080701
            ],
            [
              -80.405526,
              34.081369
            ],
            [
              -80.481318,
              34.112622
            ],
            [
              -80.479857,
              34.168696
            ],
            [
              -80.58011,
              34.099949
            ],
            [
              -80.618533,
              34.10917
            ],
            [
              -80.616186,
              34.099605
            ],
            [
              -80.636526,
              34.082982
            ],
            [
              -80.613869,
              34.057324
            ],
            [
              -80.606416,
              34.057982
            ],
            [
              -80.600952,
              34.039386
            ],
            [
              -80.625285,
              34.003704
            ],
            [
              -80.646379,
              33.999662
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "005",
      "COUNTYNS": "01247980",
      "AFFGEOID": "0500000US45005",
      "GEOID": "45005",
      "NAME": "Allendale",
      "LSAD": "06",
      "ALAND": 1056949849,
      "AWATER": 11205143,
      "County_state": "Allendale,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.61429847805029,
              33.0946609670663
            ],
            [
              -81.612725,
              33.093953
            ],
            [
              -81.6108,
              33.09263
            ],
            [
              -81.609476,
              33.089862
            ],
            [
              -81.609533,
              33.086877
            ],
            [
              -81.609837,
              33.084929
            ],
            [
              -81.610078,
              33.082883
            ],
            [
              -81.609837,
              33.082161
            ],
            [
              -81.608995,
              33.0818
            ],
            [
              -81.606836,
              33.081717
            ],
            [
              -81.603587,
              33.084578
            ],
            [
              -81.601655,
              33.084688
            ],
            [
              -81.600211,
              33.083966
            ],
            [
              -81.598165,
              33.081078
            ],
            [
              -81.598286,
              33.079153
            ],
            [
              -81.599369,
              33.076867
            ],
            [
              -81.600211,
              33.075182
            ],
            [
              -81.600091,
              33.073497
            ],
            [
              -81.599248,
              33.071813
            ],
            [
              -81.594555,
              33.069887
            ],
            [
              -81.592645,
              33.06991
            ],
            [
              -81.590705,
              33.071211
            ],
            [
              -81.588539,
              33.07085
            ],
            [
              -81.583804,
              33.067021
            ],
            [
              -81.580994,
              33.062697
            ],
            [
              -81.578332,
              33.058936
            ],
            [
              -81.57288,
              33.05418
            ],
            [
              -81.568925,
              33.053523
            ],
            [
              -81.566759,
              33.053763
            ],
            [
              -81.564714,
              33.054726
            ],
            [
              -81.56327,
              33.055568
            ],
            [
              -81.562548,
              33.055568
            ],
            [
              -81.562066,
              33.055568
            ],
            [
              -81.561344,
              33.055568
            ],
            [
              -81.560502,
              33.055207
            ],
            [
              -81.559179,
              33.054124
            ],
            [
              -81.558938,
              33.052921
            ],
            [
              -81.559173,
              33.051765
            ],
            [
              -81.55966,
              33.04907
            ],
            [
              -81.559179,
              33.047386
            ],
            [
              -81.558336,
              33.046183
            ],
            [
              -81.557013,
              33.0451
            ],
            [
              -81.553643,
              33.044137
            ],
            [
              -81.551838,
              33.044739
            ],
            [
              -81.546785,
              33.047145
            ],
            [
              -81.544258,
              33.046905
            ],
            [
              -81.542509768548,
              33.04525362347609
            ],
            [
              -81.542092,
              33.044859
            ],
            [
              -81.540081,
              33.040613
            ],
            [
              -81.538789,
              33.039185
            ],
            [
              -81.519632,
              33.029181
            ],
            [
              -81.513231,
              33.028546
            ],
            [
              -81.511245,
              33.027786
            ],
            [
              -81.50203,
              33.015113
            ],
            [
              -81.492253,
              33.009342
            ],
            [
              -81.491419,
              33.008078
            ],
            [
              -81.491197,
              32.997824
            ],
            [
              -81.491457,
              32.995437
            ],
            [
              -81.494736,
              32.978998
            ],
            [
              -81.499471,
              32.96478
            ],
            [
              -81.49983,
              32.963816
            ],
            [
              -81.501369,
              32.962914
            ],
            [
              -81.503346,
              32.96295
            ],
            [
              -81.505256,
              32.963019
            ],
            [
              -81.506449,
              32.962423
            ],
            [
              -81.507144,
              32.96133
            ],
            [
              -81.507442,
              32.960237
            ],
            [
              -81.507741,
              32.959243
            ],
            [
              -81.508436,
              32.958349
            ],
            [
              -81.508536,
              32.957156
            ],
            [
              -81.508436,
              32.955765
            ],
            [
              -81.508138,
              32.953976
            ],
            [
              -81.507045,
              32.951194
            ],
            [
              -81.504016,
              32.948091
            ],
            [
              -81.499446,
              32.944988
            ],
            [
              -81.499566,
              32.943722
            ],
            [
              -81.502716,
              32.938688
            ],
            [
              -81.502427,
              32.935353
            ],
            [
              -81.499829,
              32.932614
            ],
            [
              -81.495092,
              32.931596
            ],
            [
              -81.483198,
              32.921802
            ],
            [
              -81.480008,
              32.913444
            ],
            [
              -81.479184,
              32.905638
            ],
            [
              -81.468978,
              32.901083
            ],
            [
              -81.465924,
              32.899889
            ],
            [
              -81.464069,
              32.897814
            ],
            [
              -81.464655,
              32.895999
            ],
            [
              -81.470836,
              32.890521
            ],
            [
              -81.471703,
              32.890439
            ],
            [
              -81.4771,
              32.887469
            ],
            [
              -81.479445,
              32.881082
            ],
            [
              -81.475918,
              32.877641
            ],
            [
              -81.470376,
              32.876267
            ],
            [
              -81.468042,
              32.876675
            ],
            [
              -81.45392,
              32.874074
            ],
            [
              -81.452883,
              32.872964
            ],
            [
              -81.451351,
              32.868583
            ],
            [
              -81.453017,
              32.859636
            ],
            [
              -81.455978,
              32.854107
            ],
            [
              -81.453949,
              32.849761
            ],
            [
              -81.452573,
              32.84795
            ],
            [
              -81.451199,
              32.847925
            ],
            [
              -81.449396,
              32.849126
            ],
            [
              -81.444866,
              32.850967
            ],
            [
              -81.443595,
              32.850628
            ],
            [
              -81.442671,
              32.850107
            ],
            [
              -81.426475,
              32.840773
            ],
            [
              -81.421614,
              32.835178
            ],
            [
              -81.42062,
              32.831223
            ],
            [
              -81.417984,
              32.818196
            ],
            [
              -81.418497,
              32.815664
            ],
            [
              -81.419752,
              32.813731
            ],
            [
              -81.423772,
              32.810514
            ],
            [
              -81.424874,
              32.801882
            ],
            [
              -81.425234,
              32.79419
            ],
            [
              -81.424999,
              32.790334
            ],
            [
              -81.428031,
              32.787618
            ],
            [
              -81.429017,
              32.785505
            ],
            [
              -81.428313,
              32.78311
            ],
            [
              -81.426481,
              32.78142
            ],
            [
              -81.424227,
              32.780152
            ],
            [
              -81.422114,
              32.779306
            ],
            [
              -81.421128,
              32.778039
            ],
            [
              -81.420987,
              32.776912
            ],
            [
              -81.421128,
              32.775926
            ],
            [
              -81.421269,
              32.774658
            ],
            [
              -81.422678,
              32.773249
            ],
            [
              -81.424714,
              32.772648
            ],
            [
              -81.425636,
              32.77184
            ],
            [
              -81.426059,
              32.771136
            ],
            [
              -81.426481,
              32.770291
            ],
            [
              -81.426481,
              32.769023
            ],
            [
              -81.426199,
              32.768319
            ],
            [
              -81.425017,
              32.768058
            ],
            [
              -81.422396,
              32.767051
            ],
            [
              -81.420142,
              32.765501
            ],
            [
              -81.417606,
              32.762684
            ],
            [
              -81.416479,
              32.760289
            ],
            [
              -81.415212,
              32.757753
            ],
            [
              -81.416479,
              32.754654
            ],
            [
              -81.416761,
              32.752259
            ],
            [
              -81.416198,
              32.750428
            ],
            [
              -81.415353,
              32.748879
            ],
            [
              -81.412817,
              32.748174
            ],
            [
              -81.411408,
              32.747329
            ],
            [
              -81.410563,
              32.74592
            ],
            [
              -81.410281,
              32.744653
            ],
            [
              -81.39399,
              32.744867
            ],
            [
              -81.367973,
              32.760601
            ],
            [
              -81.338543,
              32.768456
            ],
            [
              -81.329101,
              32.7888
            ],
            [
              -81.261662,
              32.839139
            ],
            [
              -81.276729,
              32.892086
            ],
            [
              -81.260116,
              32.902979
            ],
            [
              -81.237681,
              32.906731
            ],
            [
              -81.236956,
              32.940244
            ],
            [
              -81.219563,
              32.95335
            ],
            [
              -81.082291,
              33.02663
            ],
            [
              -81.086562,
              33.035201
            ],
            [
              -81.101174,
              33.049104
            ],
            [
              -81.124826,
              33.053448
            ],
            [
              -81.148709,
              33.080905
            ],
            [
              -81.193099,
              33.118668
            ],
            [
              -81.210497,
              33.120637
            ],
            [
              -81.264333,
              33.110823
            ],
            [
              -81.323793,
              33.10831
            ],
            [
              -81.337806,
              33.108221
            ],
            [
              -81.364857,
              33.108061
            ],
            [
              -81.453202,
              33.135021
            ],
            [
              -81.541881,
              33.158279
            ],
            [
              -81.61429847805029,
              33.0946609670663
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "001",
      "COUNTYNS": "01245666",
      "AFFGEOID": "0500000US45001",
      "GEOID": "45001",
      "NAME": "Abbeville",
      "LSAD": "06",
      "ALAND": 1270337025,
      "AWATER": 53126680,
      "County_state": "Abbeville,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.741126673611,
              34.208787562607604
            ],
            [
              -82.732761,
              34.195338
            ],
            [
              -82.731975,
              34.193154
            ],
            [
              -82.732359,
              34.180564
            ],
            [
              -82.731881,
              34.178363
            ],
            [
              -82.730824,
              34.175906
            ],
            [
              -82.725409,
              34.169774
            ],
            [
              -82.723312,
              34.165895
            ],
            [
              -82.717507,
              34.150504
            ],
            [
              -82.715373,
              34.148165
            ],
            [
              -82.70414,
              34.141007
            ],
            [
              -82.699758,
              34.139318
            ],
            [
              -82.69553,
              34.138815
            ],
            [
              -82.692152,
              34.138986
            ],
            [
              -82.690386,
              34.138293
            ],
            [
              -82.68629,
              34.134454
            ],
            [
              -82.67732,
              34.131657
            ],
            [
              -82.67522,
              34.129779
            ],
            [
              -82.668113,
              34.12016
            ],
            [
              -82.666879,
              34.113591
            ],
            [
              -82.661851,
              34.107754
            ],
            [
              -82.660322,
              34.106897
            ],
            [
              -82.659077,
              34.103544
            ],
            [
              -82.658561,
              34.103118
            ],
            [
              -82.654019,
              34.100346
            ],
            [
              -82.652175,
              34.099704
            ],
            [
              -82.648184,
              34.098649
            ],
            [
              -82.647028,
              34.097825
            ],
            [
              -82.641553,
              34.092212
            ],
            [
              -82.64103,
              34.090861
            ],
            [
              -82.641252,
              34.088914
            ],
            [
              -82.640701,
              34.088341
            ],
            [
              -82.640151,
              34.087609
            ],
            [
              -82.640345,
              34.086304
            ],
            [
              -82.642797,
              34.081312
            ],
            [
              -82.64522,
              34.079046
            ],
            [
              -82.645661,
              34.076046
            ],
            [
              -82.64398,
              34.072237
            ],
            [
              -82.640543,
              34.067595
            ],
            [
              -82.635991,
              34.064941
            ],
            [
              -82.633565,
              34.064822
            ],
            [
              -82.630972,
              34.065528
            ],
            [
              -82.626963,
              34.063457
            ],
            [
              -82.622155,
              34.058516
            ],
            [
              -82.621255,
              34.056916
            ],
            [
              -82.620955,
              34.054416
            ],
            [
              -82.619155,
              34.051316
            ],
            [
              -82.613355,
              34.046816
            ],
            [
              -82.609655,
              34.039917
            ],
            [
              -82.603055,
              34.034817
            ],
            [
              -82.596155,
              34.030517
            ],
            [
              -82.594555,
              34.028717
            ],
            [
              -82.594055,
              34.025917
            ],
            [
              -82.595855,
              34.018518
            ],
            [
              -82.595655,
              34.016118
            ],
            [
              -82.5944878368938,
              34.0139372478806
            ],
            [
              -82.558859,
              34.046458
            ],
            [
              -82.530567,
              34.071925
            ],
            [
              -82.49956,
              34.072098
            ],
            [
              -82.483551,
              34.081215
            ],
            [
              -82.452707,
              34.060344
            ],
            [
              -82.376589,
              34.057374
            ],
            [
              -82.326947,
              34.06412
            ],
            [
              -82.267184,
              34.10836
            ],
            [
              -82.246667,
              34.144053
            ],
            [
              -82.240446,
              34.16302
            ],
            [
              -82.240162,
              34.179147
            ],
            [
              -82.247747,
              34.219619
            ],
            [
              -82.264646,
              34.245118
            ],
            [
              -82.307622,
              34.277537
            ],
            [
              -82.311081,
              34.320441
            ],
            [
              -82.334234,
              34.34263
            ],
            [
              -82.288677,
              34.377362
            ],
            [
              -82.246337,
              34.409676
            ],
            [
              -82.266905,
              34.433668
            ],
            [
              -82.31484,
              34.470294
            ],
            [
              -82.313996,
              34.484002
            ],
            [
              -82.376815,
              34.443143
            ],
            [
              -82.387479,
              34.436276
            ],
            [
              -82.392351,
              34.433137
            ],
            [
              -82.684298,
              34.24541
            ],
            [
              -82.741126673611,
              34.208787562607604
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "089",
      "COUNTYNS": "01248018",
      "AFFGEOID": "0500000US45089",
      "GEOID": "45089",
      "NAME": "Williamsburg",
      "LSAD": "06",
      "ALAND": 2419464748,
      "AWATER": 7469568,
      "County_state": "Williamsburg,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.101697,
              33.496891
            ],
            [
              -80.082171,
              33.505728
            ],
            [
              -80.012136,
              33.505172
            ],
            [
              -80.004447,
              33.495734
            ],
            [
              -79.971062,
              33.500743
            ],
            [
              -79.914733,
              33.463483
            ],
            [
              -79.910226,
              33.455713
            ],
            [
              -79.894146,
              33.45365
            ],
            [
              -79.867282,
              33.430557
            ],
            [
              -79.877632,
              33.428471
            ],
            [
              -79.860164,
              33.41511
            ],
            [
              -79.854771,
              33.402768
            ],
            [
              -79.813862,
              33.382321
            ],
            [
              -79.807703,
              33.372799
            ],
            [
              -79.796391,
              33.377639
            ],
            [
              -79.781638,
              33.36822
            ],
            [
              -79.756172,
              33.329699
            ],
            [
              -79.739957,
              33.321606
            ],
            [
              -79.709576,
              33.321246
            ],
            [
              -79.677014,
              33.304944
            ],
            [
              -79.680883,
              33.319604
            ],
            [
              -79.677246,
              33.336762
            ],
            [
              -79.6524,
              33.361327
            ],
            [
              -79.634419,
              33.372146
            ],
            [
              -79.625077,
              33.390405
            ],
            [
              -79.595994,
              33.421548
            ],
            [
              -79.59011,
              33.44354
            ],
            [
              -79.544578,
              33.487535
            ],
            [
              -79.53808,
              33.496896
            ],
            [
              -79.499341,
              33.515804
            ],
            [
              -79.483059,
              33.53703
            ],
            [
              -79.433639,
              33.576709
            ],
            [
              -79.44247,
              33.596033
            ],
            [
              -79.433288,
              33.621552
            ],
            [
              -79.419842,
              33.648468
            ],
            [
              -79.409332,
              33.68889
            ],
            [
              -79.392658,
              33.711812
            ],
            [
              -79.346554,
              33.755334
            ],
            [
              -79.337764,
              33.774605
            ],
            [
              -79.317041,
              33.779878
            ],
            [
              -79.323277,
              33.788741
            ],
            [
              -79.324317,
              33.799123
            ],
            [
              -79.340393,
              33.802796
            ],
            [
              -79.352229,
              33.815135
            ],
            [
              -79.386021,
              33.816196
            ],
            [
              -79.386548,
              33.807678
            ],
            [
              -79.433273,
              33.792892
            ],
            [
              -79.441533,
              33.784387
            ],
            [
              -79.473316,
              33.783747
            ],
            [
              -79.496486,
              33.775357
            ],
            [
              -79.63488,
              33.808359
            ],
            [
              -79.635808,
              33.818038
            ],
            [
              -79.648885,
              33.815261
            ],
            [
              -79.677327,
              33.814694
            ],
            [
              -79.687973,
              33.804914
            ],
            [
              -79.724936,
              33.814552
            ],
            [
              -79.743388,
              33.835056
            ],
            [
              -79.79837,
              33.835562
            ],
            [
              -79.834881,
              33.882601
            ],
            [
              -79.847156,
              33.884487
            ],
            [
              -79.854891,
              33.891738
            ],
            [
              -79.866427,
              33.884322
            ],
            [
              -79.87562,
              33.885245
            ],
            [
              -79.915305,
              33.818648
            ],
            [
              -79.944505,
              33.810214
            ],
            [
              -79.995638,
              33.767906
            ],
            [
              -79.974382,
              33.72159
            ],
            [
              -79.993233,
              33.704458
            ],
            [
              -80.020879,
              33.645804
            ],
            [
              -80.045073,
              33.600835
            ],
            [
              -80.101697,
              33.496891
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "003",
      "COUNTYNS": "01247979",
      "AFFGEOID": "0500000US45003",
      "GEOID": "45003",
      "NAME": "Aiken",
      "LSAD": "06",
      "ALAND": 2774109851,
      "AWATER": 24619610,
      "County_state": "Aiken,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.0124261353723,
              33.532088114063704
            ],
            [
              -82.011538,
              33.531735
            ],
            [
              -82.01153651351349,
              33.531733711711695
            ],
            [
              -82.010038,
              33.530435
            ],
            [
              -82.007638,
              33.523335
            ],
            [
              -82.007138,
              33.522835
            ],
            [
              -82.004338,
              33.521935
            ],
            [
              -82.001338,
              33.520135
            ],
            [
              -81.991938,
              33.504435
            ],
            [
              -81.990382,
              33.500759
            ],
            [
              -81.990938,
              33.494235
            ],
            [
              -81.989338,
              33.490036
            ],
            [
              -81.985938,
              33.486536
            ],
            [
              -81.980637,
              33.484036
            ],
            [
              -81.973537,
              33.482636
            ],
            [
              -81.967037,
              33.480636
            ],
            [
              -81.95534093127469,
              33.4755834118647
            ],
            [
              -81.946437,
              33.471737
            ],
            [
              -81.941737,
              33.470037
            ],
            [
              -81.934136,
              33.468337
            ],
            [
              -81.929436,
              33.465837
            ],
            [
              -81.926336,
              33.462937
            ],
            [
              -81.9259452812842,
              33.4621627375848
            ],
            [
              -81.920836,
              33.452038
            ],
            [
              -81.913532,
              33.441274
            ],
            [
              -81.913457,
              33.439641
            ],
            [
              -81.913356,
              33.437418
            ],
            [
              -81.916236,
              33.433114
            ],
            [
              -81.920716,
              33.430986
            ],
            [
              -81.924981,
              33.429288
            ],
            [
              -81.926789,
              33.426576
            ],
            [
              -81.927241,
              33.422846
            ],
            [
              -81.924893,
              33.419307
            ],
            [
              -81.921068,
              33.417419
            ],
            [
              -81.91933,
              33.415613
            ],
            [
              -81.919217,
              33.413126
            ],
            [
              -81.920121,
              33.410753
            ],
            [
              -81.92306,
              33.408266
            ],
            [
              -81.930519,
              33.406797
            ],
            [
              -81.934927,
              33.406006
            ],
            [
              -81.936961,
              33.404197
            ],
            [
              -81.9373,
              33.401259
            ],
            [
              -81.935453,
              33.397851
            ],
            [
              -81.930861,
              33.380076
            ],
            [
              -81.924837,
              33.37414
            ],
            [
              -81.925737,
              33.37114
            ],
            [
              -81.930634,
              33.368165
            ],
            [
              -81.934637,
              33.36894
            ],
            [
              -81.939637,
              33.37254
            ],
            [
              -81.943737,
              33.37234
            ],
            [
              -81.946337,
              33.37064
            ],
            [
              -81.946737,
              33.36734
            ],
            [
              -81.944737,
              33.364041
            ],
            [
              -81.934837,
              33.356041
            ],
            [
              -81.935637,
              33.352041
            ],
            [
              -81.9379453725436,
              33.3496776662054
            ],
            [
              -81.939837,
              33.347741
            ],
            [
              -81.939737,
              33.344941
            ],
            [
              -81.937237,
              33.343641
            ],
            [
              -81.932737,
              33.343541
            ],
            [
              -81.924737,
              33.345341
            ],
            [
              -81.917973,
              33.34159
            ],
            [
              -81.919137,
              33.334442
            ],
            [
              -81.918337,
              33.332842
            ],
            [
              -81.913314,
              33.329532
            ],
            [
              -81.911266,
              33.327616
            ],
            [
              -81.910342,
              33.32537
            ],
            [
              -81.909285,
              33.324181
            ],
            [
              -81.906444,
              33.324181
            ],
            [
              -81.904132,
              33.327286
            ],
            [
              -81.902613,
              33.330258
            ],
            [
              -81.900301,
              33.331117
            ],
            [
              -81.898187,
              33.329664
            ],
            [
              -81.896937,
              33.327642
            ],
            [
              -81.897064,
              33.324445
            ],
            [
              -81.897329,
              33.322331
            ],
            [
              -81.886637,
              33.316943
            ],
            [
              -81.884137,
              33.310443
            ],
            [
              -81.875836,
              33.307443
            ],
            [
              -81.870436,
              33.312943
            ],
            [
              -81.867936,
              33.314043
            ],
            [
              -81.853652,
              33.310326
            ],
            [
              -81.847296,
              33.306783
            ],
            [
              -81.846136,
              33.303843
            ],
            [
              -81.849636,
              33.299544
            ],
            [
              -81.851636,
              33.298544
            ],
            [
              -81.852936,
              33.299644
            ],
            [
              -81.857336,
              33.299544
            ],
            [
              -81.861536,
              33.297944
            ],
            [
              -81.863236,
              33.288844
            ],
            [
              -81.861336,
              33.286244
            ],
            [
              -81.851836,
              33.283544
            ],
            [
              -81.844036,
              33.278644
            ],
            [
              -81.838257,
              33.272975
            ],
            [
              -81.838337,
              33.269098
            ],
            [
              -81.840078,
              33.26704
            ],
            [
              -81.842522,
              33.266584
            ],
            [
              -81.847336,
              33.266345
            ],
            [
              -81.853137,
              33.250745
            ],
            [
              -81.852136,
              33.247544
            ],
            [
              -81.851979119203,
              33.2473815723462
            ],
            [
              -81.846536,
              33.241746
            ],
            [
              -81.837016,
              33.237652
            ],
            [
              -81.831736,
              33.233546
            ],
            [
              -81.8315651013874,
              33.233330128068296
            ],
            [
              -81.827936,
              33.228746
            ],
            [
              -81.819636,
              33.226646
            ],
            [
              -81.811736,
              33.223847
            ],
            [
              -81.809636,
              33.222647
            ],
            [
              -81.808136,
              33.219447
            ],
            [
              -81.807936,
              33.213747
            ],
            [
              -81.807336,
              33.212647
            ],
            [
              -81.805236,
              33.211447
            ],
            [
              -81.790236,
              33.208447
            ],
            [
              -81.784535,
              33.208147
            ],
            [
              -81.778935,
              33.209847
            ],
            [
              -81.777535,
              33.211347
            ],
            [
              -81.777335,
              33.214947
            ],
            [
              -81.781035,
              33.219847
            ],
            [
              -81.780135,
              33.221147
            ],
            [
              -81.778435,
              33.221847
            ],
            [
              -81.774035,
              33.221147
            ],
            [
              -81.768935,
              33.217447
            ],
            [
              -81.767635,
              33.215747
            ],
            [
              -81.763535,
              33.203648
            ],
            [
              -81.761635,
              33.201748
            ],
            [
              -81.758235,
              33.200248
            ],
            [
              -81.7570833221477,
              33.1981218255034
            ],
            [
              -81.516319,
              33.382311
            ],
            [
              -81.37808,
              33.487132
            ],
            [
              -81.372931,
              33.490417
            ],
            [
              -81.215659,
              33.627794
            ],
            [
              -81.187271,
              33.652937
            ],
            [
              -81.207395,
              33.658876
            ],
            [
              -81.277009,
              33.696206
            ],
            [
              -81.282836,
              33.694333
            ],
            [
              -81.287133,
              33.693113
            ],
            [
              -81.302858,
              33.704672
            ],
            [
              -81.357386,
              33.722866
            ],
            [
              -81.415308,
              33.732161
            ],
            [
              -81.426743,
              33.743197
            ],
            [
              -81.436146,
              33.775816
            ],
            [
              -81.460478,
              33.797156
            ],
            [
              -81.474697,
              33.798428
            ],
            [
              -81.512289,
              33.813081
            ],
            [
              -81.53359,
              33.832154
            ],
            [
              -81.546946,
              33.858683
            ],
            [
              -81.560733,
              33.862645
            ],
            [
              -81.571485,
              33.87723
            ],
            [
              -81.584337,
              33.867424
            ],
            [
              -81.651759,
              33.81451
            ],
            [
              -81.716017,
              33.764537
            ],
            [
              -81.779093,
              33.715309
            ],
            [
              -81.860569,
              33.651624
            ],
            [
              -81.9332,
              33.593584
            ],
            [
              -81.940509,
              33.587744
            ],
            [
              -82.0124261353723,
              33.532088114063704
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "051",
      "COUNTYNS": "01248005",
      "AFFGEOID": "0500000US45051",
      "GEOID": "45051",
      "NAME": "Horry",
      "LSAD": "06",
      "ALAND": 2937082399,
      "AWATER": 313208767,
      "County_state": "Horry,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.333289,
              33.994123
            ],
            [
              -79.338842,
              33.970494
            ],
            [
              -79.295994,
              33.900696
            ],
            [
              -79.263758,
              33.892689
            ],
            [
              -79.256723,
              33.864302
            ],
            [
              -79.261223,
              33.838733
            ],
            [
              -79.249115,
              33.829754
            ],
            [
              -79.260283,
              33.814854
            ],
            [
              -79.252414,
              33.807834
            ],
            [
              -79.248094,
              33.784415
            ],
            [
              -79.230025,
              33.761821
            ],
            [
              -79.220452,
              33.756982
            ],
            [
              -79.219768,
              33.747877
            ],
            [
              -79.1959,
              33.727886
            ],
            [
              -79.190264,
              33.705787
            ],
            [
              -79.152184,
              33.677371
            ],
            [
              -79.151935,
              33.658191
            ],
            [
              -79.14603,
              33.65798
            ],
            [
              -79.137683,
              33.66413
            ],
            [
              -79.134212,
              33.63802
            ],
            [
              -79.121278,
              33.604775
            ],
            [
              -79.096787,
              33.600349
            ],
            [
              -79.102181,
              33.581675
            ],
            [
              -79.100653,
              33.571807
            ],
            [
              -79.016519,
              33.572176
            ],
            [
              -79.01568,
              33.569108
            ],
            [
              -79.0021085980918,
              33.5721139305889
            ],
            [
              -78.9599266607669,
              33.616719746269595
            ],
            [
              -78.938076,
              33.639826
            ],
            [
              -78.9141747672473,
              33.66076379159819
            ],
            [
              -78.862931,
              33.705654
            ],
            [
              -78.812931,
              33.743472
            ],
            [
              -78.772737,
              33.768511
            ],
            [
              -78.7464566303277,
              33.7826896945229
            ],
            [
              -78.7413726438936,
              33.785432589372
            ],
            [
              -78.714116,
              33.800138
            ],
            [
              -78.67226,
              33.817587
            ],
            [
              -78.584841,
              33.844282
            ],
            [
              -78.553944,
              33.847831
            ],
            [
              -78.541087,
              33.851112
            ],
            [
              -78.580378,
              33.884925
            ],
            [
              -78.615932,
              33.915523
            ],
            [
              -78.621369,
              33.920073
            ],
            [
              -78.6503363928608,
              33.9443152424589
            ],
            [
              -78.651629,
              33.945397
            ],
            [
              -78.66253,
              33.95452
            ],
            [
              -78.702771,
              33.989268
            ],
            [
              -78.710141,
              33.994688
            ],
            [
              -78.712206,
              33.996732
            ],
            [
              -78.769483,
              34.045242
            ],
            [
              -78.7942804437823,
              34.0662441024328
            ],
            [
              -78.81171,
              34.081006
            ],
            [
              -78.855385,
              34.117996
            ],
            [
              -78.874747,
              34.134395
            ],
            [
              -78.8896910841351,
              34.146936733500496
            ],
            [
              -78.909881,
              34.163881
            ],
            [
              -78.963692,
              34.209041
            ],
            [
              -78.99576,
              34.235954
            ],
            [
              -79.071169,
              34.29924
            ],
            [
              -79.0712438523822,
              34.299302913057005
            ],
            [
              -79.07125513793659,
              34.2993123985096
            ],
            [
              -79.086067,
              34.294956
            ],
            [
              -79.093731,
              34.277962
            ],
            [
              -79.128165,
              34.253652
            ],
            [
              -79.128264,
              34.230743
            ],
            [
              -79.134709,
              34.22592
            ],
            [
              -79.131079,
              34.22126
            ],
            [
              -79.153939,
              34.207207
            ],
            [
              -79.171441,
              34.181737
            ],
            [
              -79.191673,
              34.176412
            ],
            [
              -79.207807,
              34.145285
            ],
            [
              -79.205771,
              34.132973
            ],
            [
              -79.250441,
              34.089758
            ],
            [
              -79.242961,
              34.067958
            ],
            [
              -79.265089,
              34.043982
            ],
            [
              -79.302107,
              34.029185
            ],
            [
              -79.315079,
              33.996506
            ],
            [
              -79.333289,
              33.994123
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "029",
      "COUNTYNS": "01247991",
      "AFFGEOID": "0500000US45029",
      "GEOID": "45029",
      "NAME": "Colleton",
      "LSAD": "06",
      "ALAND": 2736299925,
      "AWATER": 198896760,
      "County_state": "Colleton,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.082291,
              33.02663
            ],
            [
              -81.064532,
              33.013279
            ],
            [
              -81.053065,
              32.989478
            ],
            [
              -81.02963,
              32.973912
            ],
            [
              -80.999343,
              32.931247
            ],
            [
              -80.986703,
              32.900674
            ],
            [
              -80.976631,
              32.897285
            ],
            [
              -80.958832,
              32.87668
            ],
            [
              -80.944762,
              32.875698
            ],
            [
              -80.900196,
              32.832813
            ],
            [
              -80.895888,
              32.810805
            ],
            [
              -80.866191,
              32.775581
            ],
            [
              -80.848527,
              32.730264
            ],
            [
              -80.845164,
              32.72011
            ],
            [
              -80.826309,
              32.704321
            ],
            [
              -80.807877,
              32.689438
            ],
            [
              -80.763252,
              32.675369
            ],
            [
              -80.744888,
              32.674229
            ],
            [
              -80.739243,
              32.682304
            ],
            [
              -80.706497,
              32.673244
            ],
            [
              -80.703327,
              32.664586
            ],
            [
              -80.685005,
              32.672951
            ],
            [
              -80.668144,
              32.667492
            ],
            [
              -80.683135,
              32.652383
            ],
            [
              -80.680061,
              32.643913
            ],
            [
              -80.666448,
              32.645704
            ],
            [
              -80.659751,
              32.612683
            ],
            [
              -80.647828,
              32.596371
            ],
            [
              -80.629624,
              32.590703
            ],
            [
              -80.620881,
              32.5812
            ],
            [
              -80.611662,
              32.589871
            ],
            [
              -80.599788,
              32.581047
            ],
            [
              -80.586358,
              32.586172
            ],
            [
              -80.576166,
              32.577761
            ],
            [
              -80.582823,
              32.566199
            ],
            [
              -80.567312,
              32.566438
            ],
            [
              -80.562437,
              32.550957
            ],
            [
              -80.551504,
              32.565769
            ],
            [
              -80.536483,
              32.529224
            ],
            [
              -80.51408,
              32.513897
            ],
            [
              -80.507975,
              32.505207
            ],
            [
              -80.504616,
              32.496947
            ],
            [
              -80.476910367369,
              32.485389546487795
            ],
            [
              -80.472068,
              32.496964
            ],
            [
              -80.46571,
              32.4953
            ],
            [
              -80.452078,
              32.497286
            ],
            [
              -80.439407,
              32.503472
            ],
            [
              -80.423454,
              32.497989
            ],
            [
              -80.4201340626362,
              32.4932323449927
            ],
            [
              -80.418502,
              32.490894
            ],
            [
              -80.417896,
              32.476076
            ],
            [
              -80.415943,
              32.472074
            ],
            [
              -80.413487,
              32.470672
            ],
            [
              -80.392561,
              32.475332
            ],
            [
              -80.386827,
              32.47881
            ],
            [
              -80.380716,
              32.486359
            ],
            [
              -80.363956,
              32.496098
            ],
            [
              -80.343883,
              32.490795
            ],
            [
              -80.338354,
              32.47873
            ],
            [
              -80.332438,
              32.478104
            ],
            [
              -80.277681,
              32.516161
            ],
            [
              -80.27604256518659,
              32.5169432323041
            ],
            [
              -80.284708,
              32.530392
            ],
            [
              -80.319089,
              32.511026
            ],
            [
              -80.321274,
              32.523177
            ],
            [
              -80.346916,
              32.526271
            ],
            [
              -80.349285,
              32.518274
            ],
            [
              -80.32476,
              32.503578
            ],
            [
              -80.336327,
              32.493565
            ],
            [
              -80.352823,
              32.500187
            ],
            [
              -80.379032,
              32.530932
            ],
            [
              -80.416586,
              32.546318
            ],
            [
              -80.396064,
              32.561637
            ],
            [
              -80.388737,
              32.57447
            ],
            [
              -80.39623,
              32.596629
            ],
            [
              -80.387834,
              32.609139
            ],
            [
              -80.387605,
              32.616524
            ],
            [
              -80.389428,
              32.6185
            ],
            [
              -80.395095,
              32.619226
            ],
            [
              -80.418172,
              32.604474
            ],
            [
              -80.42445,
              32.607801
            ],
            [
              -80.42722,
              32.625183
            ],
            [
              -80.408504,
              32.626693
            ],
            [
              -80.389401,
              32.65282
            ],
            [
              -80.405785,
              32.652999
            ],
            [
              -80.420271,
              32.669604
            ],
            [
              -80.416133,
              32.695292
            ],
            [
              -80.422952,
              32.714757
            ],
            [
              -80.45363,
              32.7399
            ],
            [
              -80.44601,
              32.764804
            ],
            [
              -80.435692,
              32.787407
            ],
            [
              -80.407479,
              32.811106
            ],
            [
              -80.394258,
              32.844576
            ],
            [
              -80.40134,
              32.858466
            ],
            [
              -80.400209,
              32.872475
            ],
            [
              -80.385344,
              32.886119
            ],
            [
              -80.405645,
              32.914535
            ],
            [
              -80.402279,
              32.94674
            ],
            [
              -80.418403,
              32.976039
            ],
            [
              -80.408696,
              32.984605
            ],
            [
              -80.406483,
              33.019011
            ],
            [
              -80.392604,
              33.02756
            ],
            [
              -80.387819,
              33.032843
            ],
            [
              -80.391671,
              33.044983
            ],
            [
              -80.412022,
              33.046596
            ],
            [
              -80.474788,
              33.064394
            ],
            [
              -80.496827,
              33.063172
            ],
            [
              -80.504195,
              33.057622
            ],
            [
              -80.526061,
              33.06118
            ],
            [
              -80.532013,
              33.069653
            ],
            [
              -80.551086,
              33.058236
            ],
            [
              -80.591488,
              33.05782
            ],
            [
              -80.626692,
              33.07506
            ],
            [
              -80.670443,
              33.103552
            ],
            [
              -80.681166,
              33.124449
            ],
            [
              -80.710063,
              33.156608
            ],
            [
              -80.737417,
              33.17037
            ],
            [
              -80.772,
              33.171563
            ],
            [
              -80.790296,
              33.18084
            ],
            [
              -80.797912,
              33.176944
            ],
            [
              -80.895426,
              33.125348
            ],
            [
              -80.884175,
              33.086478
            ],
            [
              -80.896074,
              33.06141
            ],
            [
              -80.906025,
              33.060228
            ],
            [
              -80.937157,
              33.102584
            ],
            [
              -81.082291,
              33.02663
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "059",
      "COUNTYNS": "01248009",
      "AFFGEOID": "0500000US45059",
      "GEOID": "45059",
      "NAME": "Laurens",
      "LSAD": "06",
      "ALAND": 1848783527,
      "AWATER": 25949287,
      "County_state": "Laurens,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.313996,
              34.484002
            ],
            [
              -82.31484,
              34.470294
            ],
            [
              -82.266905,
              34.433668
            ],
            [
              -82.246337,
              34.409676
            ],
            [
              -82.230516,
              34.391847
            ],
            [
              -82.223625,
              34.391707
            ],
            [
              -82.209161,
              34.375772
            ],
            [
              -82.20085,
              34.376919
            ],
            [
              -82.177999,
              34.358134
            ],
            [
              -82.168857,
              34.330151
            ],
            [
              -82.155444,
              34.33172
            ],
            [
              -82.134027,
              34.323794
            ],
            [
              -82.120008,
              34.303818
            ],
            [
              -82.110081,
              34.307963
            ],
            [
              -82.08367,
              34.29924
            ],
            [
              -82.053505,
              34.276423
            ],
            [
              -82.050752,
              34.268697
            ],
            [
              -82.025562,
              34.258446
            ],
            [
              -82.007176,
              34.24152
            ],
            [
              -81.99207,
              34.24226
            ],
            [
              -81.978375,
              34.238109
            ],
            [
              -81.971079,
              34.222157
            ],
            [
              -81.945019,
              34.203148
            ],
            [
              -81.926727,
              34.219721
            ],
            [
              -81.903453,
              34.262864
            ],
            [
              -81.893003,
              34.302924
            ],
            [
              -81.867573,
              34.31639
            ],
            [
              -81.848467,
              34.323528
            ],
            [
              -81.837443,
              34.342377
            ],
            [
              -81.814861,
              34.346998
            ],
            [
              -81.782627,
              34.365089
            ],
            [
              -81.737103,
              34.430544
            ],
            [
              -81.722491,
              34.449661
            ],
            [
              -81.703198,
              34.452631
            ],
            [
              -81.643552,
              34.533652
            ],
            [
              -81.677321,
              34.557972
            ],
            [
              -81.714358,
              34.554045
            ],
            [
              -81.716182,
              34.551449
            ],
            [
              -81.749606,
              34.568344
            ],
            [
              -81.800262,
              34.574811
            ],
            [
              -81.842846,
              34.600635
            ],
            [
              -81.849337,
              34.594639
            ],
            [
              -81.854002,
              34.594549
            ],
            [
              -81.863485,
              34.580183
            ],
            [
              -81.879304,
              34.596594
            ],
            [
              -81.890875,
              34.591166
            ],
            [
              -81.895293,
              34.601849
            ],
            [
              -81.910843,
              34.603473
            ],
            [
              -81.926476,
              34.618211
            ],
            [
              -81.937206,
              34.635961
            ],
            [
              -81.969872,
              34.657003
            ],
            [
              -82.00144,
              34.661591
            ],
            [
              -82.012297,
              34.668587
            ],
            [
              -82.040917,
              34.684348
            ],
            [
              -82.057834,
              34.701159
            ],
            [
              -82.071621,
              34.704242
            ],
            [
              -82.066655,
              34.715496
            ],
            [
              -82.078964,
              34.719754
            ],
            [
              -82.083913,
              34.74481
            ],
            [
              -82.101259,
              34.744172
            ],
            [
              -82.109776,
              34.756322
            ],
            [
              -82.116032,
              34.749346
            ],
            [
              -82.131812,
              34.778466
            ],
            [
              -82.146792,
              34.785554
            ],
            [
              -82.163787,
              34.748324
            ],
            [
              -82.177954,
              34.718787
            ],
            [
              -82.180201,
              34.712958
            ],
            [
              -82.197489,
              34.676502
            ],
            [
              -82.211365,
              34.646742
            ],
            [
              -82.238054,
              34.586956
            ],
            [
              -82.236125,
              34.53583
            ],
            [
              -82.313996,
              34.484002
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "039",
      "COUNTYNS": "01248000",
      "AFFGEOID": "0500000US45039",
      "GEOID": "45039",
      "NAME": "Fairfield",
      "LSAD": "06",
      "ALAND": 1777452228,
      "AWATER": 61132194,
      "County_state": "Fairfield,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.427545,
              34.529482
            ],
            [
              -81.423241,
              34.494379
            ],
            [
              -81.412853,
              34.467946
            ],
            [
              -81.42124,
              34.453824
            ],
            [
              -81.397948,
              34.400933
            ],
            [
              -81.394845,
              34.387098
            ],
            [
              -81.372097,
              34.35166
            ],
            [
              -81.366471,
              34.325822
            ],
            [
              -81.353991,
              34.302884
            ],
            [
              -81.339674,
              34.289981
            ],
            [
              -81.342297,
              34.266928
            ],
            [
              -81.330144,
              34.259084
            ],
            [
              -81.316911,
              34.239458
            ],
            [
              -81.296605,
              34.237391
            ],
            [
              -81.27824,
              34.229632
            ],
            [
              -81.251273,
              34.202946
            ],
            [
              -81.226412,
              34.205687
            ],
            [
              -81.178293,
              34.174552
            ],
            [
              -81.168877,
              34.194375
            ],
            [
              -81.181196,
              34.220285
            ],
            [
              -81.095863,
              34.213872
            ],
            [
              -81.069867,
              34.232246
            ],
            [
              -81.042528,
              34.236596
            ],
            [
              -81.040052,
              34.232779
            ],
            [
              -80.961986,
              34.25201
            ],
            [
              -80.90364,
              34.259249
            ],
            [
              -80.825921,
              34.26876
            ],
            [
              -80.769926,
              34.377111
            ],
            [
              -80.781444,
              34.385855
            ],
            [
              -80.783207,
              34.406485
            ],
            [
              -80.793478,
              34.413298
            ],
            [
              -80.825651,
              34.411865
            ],
            [
              -80.838576,
              34.418987
            ],
            [
              -80.879227,
              34.457776
            ],
            [
              -80.898722,
              34.486694
            ],
            [
              -80.88172,
              34.500117
            ],
            [
              -80.877491,
              34.543146
            ],
            [
              -81.00268,
              34.547877
            ],
            [
              -81.05999,
              34.553244
            ],
            [
              -81.245054,
              34.563822
            ],
            [
              -81.422706,
              34.572029
            ],
            [
              -81.419829,
              34.566588
            ],
            [
              -81.427545,
              34.529482
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "055",
      "COUNTYNS": "01248007",
      "AFFGEOID": "0500000US45055",
      "GEOID": "45055",
      "NAME": "Kershaw",
      "LSAD": "06",
      "ALAND": 1881777515,
      "AWATER": 35709172,
      "County_state": "Kershaw,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.879227,
              34.457776
            ],
            [
              -80.838576,
              34.418987
            ],
            [
              -80.825651,
              34.411865
            ],
            [
              -80.793478,
              34.413298
            ],
            [
              -80.783207,
              34.406485
            ],
            [
              -80.781444,
              34.385855
            ],
            [
              -80.769926,
              34.377111
            ],
            [
              -80.825921,
              34.26876
            ],
            [
              -80.840951,
              34.238921
            ],
            [
              -80.867054,
              34.186669
            ],
            [
              -80.868509,
              34.181705
            ],
            [
              -80.851379,
              34.174071
            ],
            [
              -80.820179,
              34.149167
            ],
            [
              -80.775505,
              34.113473
            ],
            [
              -80.719318,
              34.068972
            ],
            [
              -80.692965,
              34.08128
            ],
            [
              -80.658978,
              34.085743
            ],
            [
              -80.616186,
              34.099605
            ],
            [
              -80.618533,
              34.10917
            ],
            [
              -80.58011,
              34.099949
            ],
            [
              -80.479857,
              34.168696
            ],
            [
              -80.464667,
              34.178549
            ],
            [
              -80.454545,
              34.207963
            ],
            [
              -80.44324,
              34.208726
            ],
            [
              -80.4308,
              34.226997
            ],
            [
              -80.431036,
              34.276632
            ],
            [
              -80.437359,
              34.284502
            ],
            [
              -80.421654,
              34.294301
            ],
            [
              -80.400922,
              34.295196
            ],
            [
              -80.382004,
              34.305188
            ],
            [
              -80.353031,
              34.30333
            ],
            [
              -80.365772,
              34.328153
            ],
            [
              -80.363759,
              34.339547
            ],
            [
              -80.325107,
              34.339291
            ],
            [
              -80.301659,
              34.357336
            ],
            [
              -80.288692,
              34.364139
            ],
            [
              -80.288596,
              34.366207
            ],
            [
              -80.285538,
              34.393678
            ],
            [
              -80.291404,
              34.392732
            ],
            [
              -80.302215,
              34.399821
            ],
            [
              -80.311441,
              34.42429
            ],
            [
              -80.321215,
              34.426322
            ],
            [
              -80.327405,
              34.473167
            ],
            [
              -80.32759,
              34.49776
            ],
            [
              -80.334732,
              34.510028
            ],
            [
              -80.353634,
              34.520901
            ],
            [
              -80.354772,
              34.531125
            ],
            [
              -80.372336,
              34.546769
            ],
            [
              -80.381383,
              34.573759
            ],
            [
              -80.379382,
              34.584362
            ],
            [
              -80.389888,
              34.599448
            ],
            [
              -80.408373,
              34.614765
            ],
            [
              -80.550552,
              34.560116
            ],
            [
              -80.527821,
              34.504003
            ],
            [
              -80.548906,
              34.493264
            ],
            [
              -80.599085,
              34.495187
            ],
            [
              -80.614278,
              34.488808
            ],
            [
              -80.61363,
              34.499939
            ],
            [
              -80.632105,
              34.480609
            ],
            [
              -80.632213,
              34.491433
            ],
            [
              -80.658417,
              34.478612
            ],
            [
              -80.676062,
              34.493116
            ],
            [
              -80.689079,
              34.515324
            ],
            [
              -80.700049,
              34.515342
            ],
            [
              -80.713329,
              34.487334
            ],
            [
              -80.739966,
              34.479957
            ],
            [
              -80.745617,
              34.512591
            ],
            [
              -80.743345,
              34.539916
            ],
            [
              -80.879227,
              34.457776
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "073",
      "COUNTYNS": "01244832",
      "AFFGEOID": "0500000US45073",
      "GEOID": "45073",
      "NAME": "Oconee",
      "LSAD": "06",
      "ALAND": 1622181422,
      "AWATER": 122207290,
      "County_state": "Oconee,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -83.353238,
              34.728648
            ],
            [
              -83.3524926405687,
              34.7161214082301
            ],
            [
              -83.352485,
              34.715993
            ],
            [
              -83.351392,
              34.714456
            ],
            [
              -83.350976,
              34.713243
            ],
            [
              -83.349788,
              34.708274
            ],
            [
              -83.347718,
              34.705474
            ],
            [
              -83.347831,
              34.703669
            ],
            [
              -83.349636,
              34.70096
            ],
            [
              -83.349975,
              34.699155
            ],
            [
              -83.349411,
              34.697575
            ],
            [
              -83.347831,
              34.696108
            ],
            [
              -83.344671,
              34.693512
            ],
            [
              -83.342414,
              34.691255
            ],
            [
              -83.340383,
              34.688998
            ],
            [
              -83.339367,
              34.686967
            ],
            [
              -83.33933481518201,
              34.686666100517996
            ],
            [
              -83.339029,
              34.683807
            ],
            [
              -83.33869,
              34.682002
            ],
            [
              -83.336207,
              34.680534
            ],
            [
              -83.330284,
              34.681342
            ],
            [
              -83.325336,
              34.679517
            ],
            [
              -83.321463,
              34.677543
            ],
            [
              -83.31944,
              34.675974
            ],
            [
              -83.318524,
              34.674773
            ],
            [
              -83.316401,
              34.669316
            ],
            [
              -83.314394,
              34.668944
            ],
            [
              -83.308917,
              34.670273
            ],
            [
              -83.304641,
              34.669561
            ],
            [
              -83.301477,
              34.666582
            ],
            [
              -83.300848,
              34.66247
            ],
            [
              -83.292883,
              34.654196
            ],
            [
              -83.286583,
              34.650896
            ],
            [
              -83.27796,
              34.644853
            ],
            [
              -83.271982,
              34.641896
            ],
            [
              -83.262282,
              34.640296
            ],
            [
              -83.255281,
              34.637696
            ],
            [
              -83.248281,
              34.631696
            ],
            [
              -83.244581,
              34.626297
            ],
            [
              -83.240669,
              34.624507
            ],
            [
              -83.240676,
              34.624307
            ],
            [
              -83.243381,
              34.617997
            ],
            [
              -83.23178,
              34.611297
            ],
            [
              -83.221402,
              34.609947
            ],
            [
              -83.211598,
              34.610905
            ],
            [
              -83.199779,
              34.608398
            ],
            [
              -83.196979,
              34.605998
            ],
            [
              -83.179439,
              34.60802
            ],
            [
              -83.173428,
              34.607162
            ],
            [
              -83.169994,
              34.605444
            ],
            [
              -83.169572,
              34.603866
            ],
            [
              -83.169994,
              34.60201
            ],
            [
              -83.170978,
              34.598798
            ],
            [
              -83.170278,
              34.592398
            ],
            [
              -83.168278,
              34.590998
            ],
            [
              -83.154577,
              34.588198
            ],
            [
              -83.152577,
              34.578299
            ],
            [
              -83.139876,
              34.567999
            ],
            [
              -83.129676,
              34.561699
            ],
            [
              -83.127176,
              34.561999
            ],
            [
              -83.122901,
              34.560129
            ],
            [
              -83.103987,
              34.540166
            ],
            [
              -83.10356281094009,
              34.536630219427096
            ],
            [
              -83.103176,
              34.533406
            ],
            [
              -83.102179,
              34.532179
            ],
            [
              -83.096858,
              34.531524
            ],
            [
              -83.09285833837379,
              34.5328466640683
            ],
            [
              -83.092564,
              34.532944
            ],
            [
              -83.087789,
              34.532078
            ],
            [
              -83.084855,
              34.530967
            ],
            [
              -83.078113,
              34.524837
            ],
            [
              -83.077995,
              34.523746
            ],
            [
              -83.086861,
              34.517798
            ],
            [
              -83.087189,
              34.515939
            ],
            [
              -83.069451,
              34.502131
            ],
            [
              -83.065515,
              34.501126
            ],
            [
              -83.057843,
              34.503711
            ],
            [
              -83.054463,
              34.50289
            ],
            [
              -83.0494672080646,
              34.495092874783005
            ],
            [
              -83.048289,
              34.493254
            ],
            [
              -83.043771,
              34.488816
            ],
            [
              -83.034712,
              34.483495
            ],
            [
              -83.032513,
              34.483032
            ],
            [
              -83.029315,
              34.484147
            ],
            [
              -83.002924,
              34.472132
            ],
            [
              -82.99509,
              34.472483
            ],
            [
              -82.995279,
              34.475648
            ],
            [
              -82.992671,
              34.479072
            ],
            [
              -82.9922154353933,
              34.4791982077022
            ],
            [
              -82.855303,
              34.609245
            ],
            [
              -82.864806,
              34.613526
            ],
            [
              -82.86687,
              34.617425
            ],
            [
              -82.858676,
              34.621664
            ],
            [
              -82.859479,
              34.618559
            ],
            [
              -82.856154,
              34.619032
            ],
            [
              -82.852484,
              34.614942
            ],
            [
              -82.852913,
              34.618641
            ],
            [
              -82.844963,
              34.618874
            ],
            [
              -82.840253,
              34.623212
            ],
            [
              -82.854892,
              34.625103
            ],
            [
              -82.839307,
              34.644998
            ],
            [
              -82.822814,
              34.654404
            ],
            [
              -82.839867,
              34.652613
            ],
            [
              -82.852502,
              34.669497
            ],
            [
              -82.850148,
              34.696491
            ],
            [
              -82.865587,
              34.702389
            ],
            [
              -82.855425,
              34.724806
            ],
            [
              -82.873315,
              34.736804
            ],
            [
              -82.900144,
              34.74244
            ],
            [
              -82.88898,
              34.76082
            ],
            [
              -82.88352,
              34.791788
            ],
            [
              -82.896725,
              34.802896
            ],
            [
              -82.896306,
              34.837393
            ],
            [
              -82.904851,
              34.871327
            ],
            [
              -82.909288,
              34.894127
            ],
            [
              -82.900252,
              34.9024
            ],
            [
              -82.908473,
              34.919072
            ],
            [
              -82.903179,
              34.935229
            ],
            [
              -82.919189,
              34.960051
            ],
            [
              -82.915785,
              34.971836
            ],
            [
              -82.938639,
              34.980912
            ],
            [
              -82.943604,
              34.988154
            ],
            [
              -82.920589,
              35.015673
            ],
            [
              -82.918175,
              35.036214
            ],
            [
              -82.897499,
              35.056021
            ],
            [
              -82.999867,
              35.02995
            ],
            [
              -83.00866626840349,
              35.027579743047006
            ],
            [
              -83.108606,
              35.000659
            ],
            [
              -83.105531,
              34.996344
            ],
            [
              -83.1046,
              34.992783
            ],
            [
              -83.10449,
              34.989332
            ],
            [
              -83.106991,
              34.98272
            ],
            [
              -83.110025,
              34.980635
            ],
            [
              -83.112021,
              34.975896
            ],
            [
              -83.120387,
              34.968406
            ],
            [
              -83.121803,
              34.96362
            ],
            [
              -83.12114,
              34.958966
            ],
            [
              -83.124378,
              34.95524
            ],
            [
              -83.127035,
              34.953778
            ],
            [
              -83.126761,
              34.948742
            ],
            [
              -83.12294,
              34.944513
            ],
            [
              -83.121214,
              34.942684
            ],
            [
              -83.120502,
              34.941262
            ],
            [
              -83.121112,
              34.939129
            ],
            [
              -83.122585,
              34.938062
            ],
            [
              -83.125175,
              34.937047
            ],
            [
              -83.12807,
              34.938113
            ],
            [
              -83.129493,
              34.937402
            ],
            [
              -83.130356,
              34.935167
            ],
            [
              -83.129885,
              34.932351
            ],
            [
              -83.130554,
              34.930932
            ],
            [
              -83.140621,
              34.924915
            ],
            [
              -83.143261,
              34.924756
            ],
            [
              -83.149946,
              34.927218
            ],
            [
              -83.153253,
              34.926342
            ],
            [
              -83.155879,
              34.9243
            ],
            [
              -83.158019,
              34.920117
            ],
            [
              -83.160937,
              34.918269
            ],
            [
              -83.165022,
              34.918853
            ],
            [
              -83.168524,
              34.91788
            ],
            [
              -83.170754,
              34.914231
            ],
            [
              -83.174034,
              34.910911
            ],
            [
              -83.178932,
              34.90825
            ],
            [
              -83.180871,
              34.904708
            ],
            [
              -83.186541,
              34.899534
            ],
            [
              -83.190409,
              34.89794
            ],
            [
              -83.194786,
              34.897843
            ],
            [
              -83.197627,
              34.895046
            ],
            [
              -83.203351,
              34.893717
            ],
            [
              -83.204572,
              34.890284
            ],
            [
              -83.201183,
              34.884653
            ],
            [
              -83.205627,
              34.880142
            ],
            [
              -83.209683,
              34.880279
            ],
            [
              -83.213323,
              34.882796
            ],
            [
              -83.220099,
              34.878124
            ],
            [
              -83.22924,
              34.879907
            ],
            [
              -83.232379,
              34.878051
            ],
            [
              -83.23751,
              34.877057
            ],
            [
              -83.239081,
              34.875661
            ],
            [
              -83.238557,
              34.872868
            ],
            [
              -83.238419,
              34.869771
            ],
            [
              -83.240847,
              34.866736
            ],
            [
              -83.245602,
              34.865522
            ],
            [
              -83.247018,
              34.863094
            ],
            [
              -83.24722,
              34.85844
            ],
            [
              -83.250053,
              34.856012
            ],
            [
              -83.252582,
              34.853483
            ],
            [
              -83.253762,
              34.848057
            ],
            [
              -83.254605,
              34.846402
            ],
            [
              -83.255718,
              34.845592
            ],
            [
              -83.258146,
              34.844985
            ],
            [
              -83.25986,
              34.845629
            ],
            [
              -83.262193,
              34.846402
            ],
            [
              -83.26452,
              34.846402
            ],
            [
              -83.267656,
              34.845289
            ],
            [
              -83.269982,
              34.837196
            ],
            [
              -83.267293,
              34.832748
            ],
            [
              -83.268159,
              34.821393
            ],
            [
              -83.271214,
              34.81844
            ],
            [
              -83.275656,
              34.816862
            ],
            [
              -83.283151,
              34.821328
            ],
            [
              -83.284812,
              34.823043
            ],
            [
              -83.289914,
              34.824477
            ],
            [
              -83.29112,
              34.822508
            ],
            [
              -83.291325,
              34.818833
            ],
            [
              -83.294292,
              34.814725
            ],
            [
              -83.297259,
              34.814268
            ],
            [
              -83.299428,
              34.814268
            ],
            [
              -83.301368,
              34.814154
            ],
            [
              -83.302395,
              34.813241
            ],
            [
              -83.302965,
              34.812214
            ],
            [
              -83.302965,
              34.811073
            ],
            [
              -83.301482,
              34.808677
            ],
            [
              -83.301182,
              34.804008
            ],
            [
              -83.303643,
              34.802403
            ],
            [
              -83.313782,
              34.799911
            ],
            [
              -83.323866,
              34.789712
            ],
            [
              -83.319945,
              34.773725
            ],
            [
              -83.321008,
              34.765371
            ],
            [
              -83.320062,
              34.759616
            ],
            [
              -83.338666,
              34.742295
            ],
            [
              -83.348829,
              34.737194
            ],
            [
              -83.353238,
              34.728648
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "083",
      "COUNTYNS": "01248592",
      "AFFGEOID": "0500000US45083",
      "GEOID": "45083",
      "NAME": "Spartanburg",
      "LSAD": "06",
      "ALAND": 2092673515,
      "AWATER": 29211230,
      "County_state": "Spartanburg,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.22527,
              34.871724
            ],
            [
              -82.226538,
              34.850531
            ],
            [
              -82.221885,
              34.853879
            ],
            [
              -82.210815,
              34.84377
            ],
            [
              -82.210796,
              34.843373
            ],
            [
              -82.210447,
              34.842527
            ],
            [
              -82.209969,
              34.841967
            ],
            [
              -82.209354,
              34.841634
            ],
            [
              -82.208343,
              34.84154
            ],
            [
              -82.207756,
              34.841293
            ],
            [
              -82.207307,
              34.840876
            ],
            [
              -82.20664,
              34.840405
            ],
            [
              -82.205795,
              34.839983
            ],
            [
              -82.204932,
              34.839427
            ],
            [
              -82.203782,
              34.838554
            ],
            [
              -82.203454,
              34.838069
            ],
            [
              -82.203046,
              34.837851
            ],
            [
              -82.20281,
              34.837865
            ],
            [
              -82.202229,
              34.838103
            ],
            [
              -82.201366,
              34.838221
            ],
            [
              -82.200486,
              34.838027
            ],
            [
              -82.199882,
              34.837808
            ],
            [
              -82.198952,
              34.837197
            ],
            [
              -82.198088,
              34.836474
            ],
            [
              -82.197747,
              34.836316
            ],
            [
              -82.19598,
              34.835267
            ],
            [
              -82.195483,
              34.834619
            ],
            [
              -82.194539,
              34.833704
            ],
            [
              -82.194172,
              34.833197
            ],
            [
              -82.193716,
              34.832138
            ],
            [
              -82.193042,
              34.831624
            ],
            [
              -82.192398,
              34.830845
            ],
            [
              -82.192013,
              34.830484
            ],
            [
              -82.190908,
              34.829744
            ],
            [
              -82.19057,
              34.829239
            ],
            [
              -82.190575,
              34.8285
            ],
            [
              -82.19035,
              34.827996
            ],
            [
              -82.188765,
              34.82671
            ],
            [
              -82.185019,
              34.822651
            ],
            [
              -82.18342,
              34.82104
            ],
            [
              -82.183069,
              34.820807
            ],
            [
              -82.18193,
              34.820234
            ],
            [
              -82.180826,
              34.819258
            ],
            [
              -82.18025,
              34.818632
            ],
            [
              -82.178816,
              34.816928
            ],
            [
              -82.178353,
              34.816614
            ],
            [
              -82.177772,
              34.816091
            ],
            [
              -82.177286,
              34.815354
            ],
            [
              -82.176602,
              34.814195
            ],
            [
              -82.175863,
              34.813131
            ],
            [
              -82.175309,
              34.812753
            ],
            [
              -82.174689,
              34.812074
            ],
            [
              -82.174272,
              34.811327
            ],
            [
              -82.173644,
              34.810309
            ],
            [
              -82.173159,
              34.809242
            ],
            [
              -82.173099,
              34.807654
            ],
            [
              -82.173374,
              34.806808
            ],
            [
              -82.173309,
              34.805727
            ],
            [
              -82.173141,
              34.805551
            ],
            [
              -82.171374,
              34.805423
            ],
            [
              -82.170827,
              34.805495
            ],
            [
              -82.170061,
              34.805735
            ],
            [
              -82.1694,
              34.80584
            ],
            [
              -82.167009,
              34.806682
            ],
            [
              -82.166086,
              34.80693
            ],
            [
              -82.165598,
              34.806998
            ],
            [
              -82.165101,
              34.806948
            ],
            [
              -82.164619,
              34.806513
            ],
            [
              -82.164583,
              34.806261
            ],
            [
              -82.164675,
              34.805562
            ],
            [
              -82.163748,
              34.804058
            ],
            [
              -82.162545,
              34.802797
            ],
            [
              -82.162487,
              34.802387
            ],
            [
              -82.165093,
              34.796587
            ],
            [
              -82.151569,
              34.781895
            ],
            [
              -82.146792,
              34.785554
            ],
            [
              -82.131812,
              34.778466
            ],
            [
              -82.116032,
              34.749346
            ],
            [
              -82.109776,
              34.756322
            ],
            [
              -82.101259,
              34.744172
            ],
            [
              -82.083913,
              34.74481
            ],
            [
              -82.078964,
              34.719754
            ],
            [
              -82.066655,
              34.715496
            ],
            [
              -82.071621,
              34.704242
            ],
            [
              -82.057834,
              34.701159
            ],
            [
              -82.040917,
              34.684348
            ],
            [
              -82.012297,
              34.668587
            ],
            [
              -82.00144,
              34.661591
            ],
            [
              -81.969872,
              34.657003
            ],
            [
              -81.937206,
              34.635961
            ],
            [
              -81.926476,
              34.618211
            ],
            [
              -81.910843,
              34.603473
            ],
            [
              -81.895293,
              34.601849
            ],
            [
              -81.890875,
              34.591166
            ],
            [
              -81.879304,
              34.596594
            ],
            [
              -81.863485,
              34.580183
            ],
            [
              -81.854002,
              34.594549
            ],
            [
              -81.839461,
              34.646626
            ],
            [
              -81.824356,
              34.679917
            ],
            [
              -81.78339,
              34.837125
            ],
            [
              -81.7427,
              34.882151
            ],
            [
              -81.711694,
              34.912577
            ],
            [
              -81.734831,
              34.932928
            ],
            [
              -81.754911,
              34.932672
            ],
            [
              -81.774247,
              34.988485
            ],
            [
              -81.794288,
              35.035693
            ],
            [
              -81.807212,
              35.035813
            ],
            [
              -81.806384,
              35.048156
            ],
            [
              -81.805985,
              35.055552
            ],
            [
              -81.833745,
              35.107927
            ],
            [
              -81.842446,
              35.123684
            ],
            [
              -81.8743859859076,
              35.1841112338525
            ],
            [
              -81.874433,
              35.184113
            ],
            [
              -81.925612,
              35.185505
            ],
            [
              -81.9693458232069,
              35.1872287391339
            ],
            [
              -82.001422,
              35.188493
            ],
            [
              -82.039651,
              35.189449
            ],
            [
              -82.0484052363051,
              35.189667965478
            ],
            [
              -82.089586,
              35.190698
            ],
            [
              -82.1060847260646,
              35.1915082127587
            ],
            [
              -82.138947,
              35.193122
            ],
            [
              -82.167676,
              35.193699
            ],
            [
              -82.176874,
              35.19379
            ],
            [
              -82.185513,
              35.194355
            ],
            [
              -82.195483,
              35.194951
            ],
            [
              -82.216217,
              35.196044
            ],
            [
              -82.219765,
              35.037805
            ],
            [
              -82.221853,
              34.948166
            ],
            [
              -82.221865,
              34.947992
            ],
            [
              -82.223555,
              34.912969
            ],
            [
              -82.22402,
              34.90314
            ],
            [
              -82.22527,
              34.871724
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "015",
      "COUNTYNS": "01247985",
      "AFFGEOID": "0500000US45015",
      "GEOID": "45015",
      "NAME": "Berkeley",
      "LSAD": "06",
      "ALAND": 2845745102,
      "AWATER": 338007534,
      "County_state": "Berkeley,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -80.357354,
              33.274775
            ],
            [
              -80.361851,
              33.257443
            ],
            [
              -80.348407,
              33.248922
            ],
            [
              -80.330818,
              33.198424
            ],
            [
              -80.297577,
              33.181838
            ],
            [
              -80.310956,
              33.158355
            ],
            [
              -80.330057,
              33.148504
            ],
            [
              -80.279567,
              33.119213
            ],
            [
              -80.244542,
              33.093869
            ],
            [
              -80.184401,
              33.047736
            ],
            [
              -80.164252,
              33.032294
            ],
            [
              -80.149246,
              33.021601
            ],
            [
              -80.132135,
              33.010484
            ],
            [
              -80.103096,
              33.001229
            ],
            [
              -80.098222,
              32.988106
            ],
            [
              -80.087288,
              32.992058
            ],
            [
              -80.055462,
              32.999512
            ],
            [
              -80.050766,
              32.990988
            ],
            [
              -80.039904,
              32.982582
            ],
            [
              -80.042406,
              32.959578
            ],
            [
              -80.041269,
              32.954986
            ],
            [
              -80.036557,
              32.946057
            ],
            [
              -80.033787,
              32.940953
            ],
            [
              -80.023318,
              32.921434
            ],
            [
              -80.02318,
              32.921101
            ],
            [
              -80.017205,
              32.910037
            ],
            [
              -80.011774,
              32.899881
            ],
            [
              -79.994217,
              32.902769
            ],
            [
              -79.985703,
              32.904209
            ],
            [
              -79.950448,
              32.910174
            ],
            [
              -79.964513,
              32.87795
            ],
            [
              -79.959649,
              32.863839
            ],
            [
              -79.95185,
              32.857117
            ],
            [
              -79.933536,
              32.851823
            ],
            [
              -79.928305,
              32.823983
            ],
            [
              -79.919202,
              32.818991
            ],
            [
              -79.914307,
              32.818892
            ],
            [
              -79.906108,
              32.823244
            ],
            [
              -79.89671,
              32.830472
            ],
            [
              -79.897279,
              32.858766
            ],
            [
              -79.86352,
              32.869911
            ],
            [
              -79.843314,
              32.893656
            ],
            [
              -79.83643,
              32.919574
            ],
            [
              -79.827518,
              32.922327
            ],
            [
              -79.824357,
              32.919874
            ],
            [
              -79.802195,
              32.929916
            ],
            [
              -79.783096,
              32.943164
            ],
            [
              -79.770984,
              32.94186
            ],
            [
              -79.76358,
              32.958174
            ],
            [
              -79.769324,
              32.968545
            ],
            [
              -79.76231,
              32.988708
            ],
            [
              -79.729024,
              33.014089
            ],
            [
              -79.714654,
              33.040642
            ],
            [
              -79.703032,
              33.045934
            ],
            [
              -79.674857,
              33.080584
            ],
            [
              -79.660501,
              33.090832
            ],
            [
              -79.645208,
              33.120478
            ],
            [
              -79.612274,
              33.138446
            ],
            [
              -79.560461,
              33.156403
            ],
            [
              -79.533952,
              33.144035
            ],
            [
              -79.521613,
              33.146708
            ],
            [
              -79.512829,
              33.166176
            ],
            [
              -79.496945,
              33.177427
            ],
            [
              -79.492818,
              33.191821
            ],
            [
              -79.473735,
              33.210366
            ],
            [
              -79.467377,
              33.206483
            ],
            [
              -79.446699,
              33.213458
            ],
            [
              -79.458734,
              33.235219
            ],
            [
              -79.473933,
              33.229771
            ],
            [
              -79.482644,
              33.245917
            ],
            [
              -79.493993,
              33.23603
            ],
            [
              -79.546461,
              33.255456
            ],
            [
              -79.549763,
              33.264979
            ],
            [
              -79.561018,
              33.262315
            ],
            [
              -79.56225,
              33.272523
            ],
            [
              -79.606574,
              33.27932
            ],
            [
              -79.630992,
              33.292928
            ],
            [
              -79.664251,
              33.298322
            ],
            [
              -79.677014,
              33.304944
            ],
            [
              -79.709576,
              33.321246
            ],
            [
              -79.739957,
              33.321606
            ],
            [
              -79.756172,
              33.329699
            ],
            [
              -79.781638,
              33.36822
            ],
            [
              -79.796391,
              33.377639
            ],
            [
              -79.807703,
              33.372799
            ],
            [
              -79.813862,
              33.382321
            ],
            [
              -79.854771,
              33.402768
            ],
            [
              -79.860164,
              33.41511
            ],
            [
              -79.877632,
              33.428471
            ],
            [
              -79.867282,
              33.430557
            ],
            [
              -79.894146,
              33.45365
            ],
            [
              -79.910226,
              33.455713
            ],
            [
              -79.914733,
              33.463483
            ],
            [
              -79.971062,
              33.500743
            ],
            [
              -80.004447,
              33.495734
            ],
            [
              -80.012136,
              33.505172
            ],
            [
              -80.082171,
              33.505728
            ],
            [
              -80.101697,
              33.496891
            ],
            [
              -80.123303,
              33.502528
            ],
            [
              -80.152045,
              33.478572
            ],
            [
              -80.144243,
              33.464106
            ],
            [
              -80.15072,
              33.448177
            ],
            [
              -80.191574,
              33.447507
            ],
            [
              -80.208162,
              33.452291
            ],
            [
              -80.208955,
              33.442662
            ],
            [
              -80.222267,
              33.443716
            ],
            [
              -80.225201,
              33.409337
            ],
            [
              -80.222568,
              33.391288
            ],
            [
              -80.238463,
              33.391153
            ],
            [
              -80.239878,
              33.351377
            ],
            [
              -80.2479,
              33.338163
            ],
            [
              -80.253836,
              33.29926
            ],
            [
              -80.294481,
              33.271256
            ],
            [
              -80.311594,
              33.264571
            ],
            [
              -80.344645,
              33.277848
            ],
            [
              -80.357354,
              33.274775
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "037",
      "COUNTYNS": "01247999",
      "AFFGEOID": "0500000US45037",
      "GEOID": "45037",
      "NAME": "Edgefield",
      "LSAD": "06",
      "ALAND": 1296025466,
      "AWATER": 16321055,
      "County_state": "Edgefield,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -82.182888,
              33.723732
            ],
            [
              -82.166027,
              33.690316
            ],
            [
              -82.143924,
              33.684744
            ],
            [
              -82.148447,
              33.676772
            ],
            [
              -82.134481,
              33.673905
            ],
            [
              -82.131741,
              33.65952
            ],
            [
              -82.110474,
              33.655299
            ],
            [
              -82.095527,
              33.633869
            ],
            [
              -82.113661,
              33.600262
            ],
            [
              -82.11532750427301,
              33.5965013034719
            ],
            [
              -82.109376,
              33.596581
            ],
            [
              -82.10624,
              33.595637
            ],
            [
              -82.098816,
              33.586358
            ],
            [
              -82.096352,
              33.58407
            ],
            [
              -82.094128,
              33.582742
            ],
            [
              -82.087488,
              33.580614
            ],
            [
              -82.073104,
              33.57751
            ],
            [
              -82.069039,
              33.575382
            ],
            [
              -82.057727,
              33.566774
            ],
            [
              -82.05557829987289,
              33.5656996499365
            ],
            [
              -82.054943,
              33.565382
            ],
            [
              -82.048959,
              33.56487
            ],
            [
              -82.046335,
              33.56383
            ],
            [
              -82.037375,
              33.554662
            ],
            [
              -82.034895,
              33.549158
            ],
            [
              -82.033023,
              33.546454
            ],
            [
              -82.028238,
              33.544934
            ],
            [
              -82.023438,
              33.540734
            ],
            [
              -82.023438,
              33.537935
            ],
            [
              -82.019838,
              33.535035
            ],
            [
              -82.0124261353723,
              33.532088114063704
            ],
            [
              -81.940509,
              33.587744
            ],
            [
              -81.9332,
              33.593584
            ],
            [
              -81.860569,
              33.651624
            ],
            [
              -81.779093,
              33.715309
            ],
            [
              -81.716017,
              33.764537
            ],
            [
              -81.651759,
              33.81451
            ],
            [
              -81.721001,
              33.832277
            ],
            [
              -81.776406,
              33.845898
            ],
            [
              -81.824624,
              33.867204
            ],
            [
              -81.83543,
              33.865658
            ],
            [
              -81.857904,
              33.880662
            ],
            [
              -81.87036,
              33.891985
            ],
            [
              -81.894595,
              33.975415
            ],
            [
              -81.932044,
              33.976914
            ],
            [
              -82.008298,
              33.961646
            ],
            [
              -82.046974,
              33.95321
            ],
            [
              -82.1557,
              33.92929
            ],
            [
              -82.148275,
              33.902811
            ],
            [
              -82.155958,
              33.887346
            ],
            [
              -82.152044,
              33.878009
            ],
            [
              -82.113728,
              33.82988
            ],
            [
              -82.12351,
              33.827781
            ],
            [
              -82.149544,
              33.789131
            ],
            [
              -82.172379,
              33.765711
            ],
            [
              -82.164191,
              33.743367
            ],
            [
              -82.182888,
              33.723732
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "075",
      "COUNTYNS": "01248014",
      "AFFGEOID": "0500000US45075",
      "GEOID": "45075",
      "NAME": "Orangeburg",
      "LSAD": "06",
      "ALAND": 2864691029,
      "AWATER": 56516003,
      "County_state": "Orangeburg,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.372931,
              33.490417
            ],
            [
              -81.344231,
              33.480891
            ],
            [
              -81.301445,
              33.481218
            ],
            [
              -81.26327,
              33.451563
            ],
            [
              -81.242908,
              33.441136
            ],
            [
              -81.222673,
              33.44
            ],
            [
              -81.206606,
              33.437812
            ],
            [
              -81.189531,
              33.411683
            ],
            [
              -81.157004,
              33.406177
            ],
            [
              -81.117716,
              33.383455
            ],
            [
              -81.08241,
              33.375464
            ],
            [
              -81.05535,
              33.353912
            ],
            [
              -81.031356,
              33.348824
            ],
            [
              -80.985338,
              33.327018
            ],
            [
              -80.959314,
              33.309987
            ],
            [
              -80.940103,
              33.30356
            ],
            [
              -80.918425,
              33.283372
            ],
            [
              -80.892257,
              33.268391
            ],
            [
              -80.890196,
              33.261392
            ],
            [
              -80.887109,
              33.264183
            ],
            [
              -80.846151,
              33.238328
            ],
            [
              -80.8375,
              33.203712
            ],
            [
              -80.797912,
              33.176944
            ],
            [
              -80.790296,
              33.18084
            ],
            [
              -80.50279,
              33.334496
            ],
            [
              -80.494129,
              33.321167
            ],
            [
              -80.495176,
              33.302546
            ],
            [
              -80.484578,
              33.280034
            ],
            [
              -80.427092,
              33.257762
            ],
            [
              -80.39421,
              33.264663
            ],
            [
              -80.361851,
              33.257443
            ],
            [
              -80.357354,
              33.274775
            ],
            [
              -80.344645,
              33.277848
            ],
            [
              -80.311594,
              33.264571
            ],
            [
              -80.294481,
              33.271256
            ],
            [
              -80.253836,
              33.29926
            ],
            [
              -80.2479,
              33.338163
            ],
            [
              -80.239878,
              33.351377
            ],
            [
              -80.238463,
              33.391153
            ],
            [
              -80.222568,
              33.391288
            ],
            [
              -80.225201,
              33.409337
            ],
            [
              -80.222267,
              33.443716
            ],
            [
              -80.238075,
              33.439582
            ],
            [
              -80.242419,
              33.449595
            ],
            [
              -80.272655,
              33.438181
            ],
            [
              -80.276675,
              33.446927
            ],
            [
              -80.322568,
              33.431371
            ],
            [
              -80.354539,
              33.430408
            ],
            [
              -80.393145,
              33.445339
            ],
            [
              -80.436325,
              33.477066
            ],
            [
              -80.439085,
              33.502025
            ],
            [
              -80.460048,
              33.502895
            ],
            [
              -80.456102,
              33.510821
            ],
            [
              -80.46836,
              33.523237
            ],
            [
              -80.447324,
              33.524527
            ],
            [
              -80.451861,
              33.545916
            ],
            [
              -80.468421,
              33.541058
            ],
            [
              -80.485019,
              33.560047
            ],
            [
              -80.496273,
              33.558481
            ],
            [
              -80.511206,
              33.538429
            ],
            [
              -80.540143,
              33.5618
            ],
            [
              -80.552272,
              33.565414
            ],
            [
              -80.603615,
              33.5554
            ],
            [
              -80.610346,
              33.550732
            ],
            [
              -80.587927,
              33.538882
            ],
            [
              -80.624702,
              33.498899
            ],
            [
              -80.624951,
              33.488763
            ],
            [
              -80.611327,
              33.470416
            ],
            [
              -80.627291,
              33.473821
            ],
            [
              -80.65902,
              33.470982
            ],
            [
              -80.69609,
              33.510458
            ],
            [
              -80.693855,
              33.521753
            ],
            [
              -80.731565,
              33.549502
            ],
            [
              -80.807631,
              33.604081
            ],
            [
              -80.885195,
              33.618289
            ],
            [
              -80.919677,
              33.605505
            ],
            [
              -80.939785,
              33.608813
            ],
            [
              -80.943954,
              33.643604
            ],
            [
              -80.965489,
              33.625282
            ],
            [
              -80.98421,
              33.644797
            ],
            [
              -81.042854,
              33.707131
            ],
            [
              -81.05669,
              33.703432
            ],
            [
              -81.086633,
              33.695409
            ],
            [
              -81.187271,
              33.652937
            ],
            [
              -81.215659,
              33.627794
            ],
            [
              -81.372931,
              33.490417
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "071",
      "COUNTYNS": "01248013",
      "AFFGEOID": "0500000US45071",
      "GEOID": "45071",
      "NAME": "Newberry",
      "LSAD": "06",
      "ALAND": 1631805477,
      "AWATER": 44657974,
      "County_state": "Newberry,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.945019,
              34.203148
            ],
            [
              -81.926748,
              34.195856
            ],
            [
              -81.916989,
              34.176981
            ],
            [
              -81.904473,
              34.176408
            ],
            [
              -81.907646,
              34.16733
            ],
            [
              -81.897554,
              34.146613
            ],
            [
              -81.870084,
              34.135357
            ],
            [
              -81.878848,
              34.156704
            ],
            [
              -81.876356,
              34.167834
            ],
            [
              -81.860571,
              34.179373
            ],
            [
              -81.838789,
              34.172204
            ],
            [
              -81.810816,
              34.175381
            ],
            [
              -81.785857,
              34.166201
            ],
            [
              -81.771025,
              34.173854
            ],
            [
              -81.743947,
              34.179158
            ],
            [
              -81.731614,
              34.187613
            ],
            [
              -81.709036,
              34.173179
            ],
            [
              -81.691503,
              34.172943
            ],
            [
              -81.672671,
              34.156009
            ],
            [
              -81.636963,
              34.146181
            ],
            [
              -81.619946,
              34.124724
            ],
            [
              -81.595286,
              34.118459
            ],
            [
              -81.568511,
              34.099687
            ],
            [
              -81.548539,
              34.093905
            ],
            [
              -81.544355,
              34.087492
            ],
            [
              -81.538899,
              34.081744
            ],
            [
              -81.51385,
              34.082253
            ],
            [
              -81.501674,
              34.074007
            ],
            [
              -81.471972,
              34.076552
            ],
            [
              -81.466669,
              34.087247
            ],
            [
              -81.444674,
              34.077884
            ],
            [
              -81.424727,
              34.08317
            ],
            [
              -81.394083,
              34.071545
            ],
            [
              -81.388564,
              34.080227
            ],
            [
              -81.437172,
              34.129963
            ],
            [
              -81.403672,
              34.177876
            ],
            [
              -81.374766,
              34.179009
            ],
            [
              -81.352349,
              34.196689
            ],
            [
              -81.338961,
              34.197587
            ],
            [
              -81.345328,
              34.20503
            ],
            [
              -81.316911,
              34.239458
            ],
            [
              -81.330144,
              34.259084
            ],
            [
              -81.342297,
              34.266928
            ],
            [
              -81.339674,
              34.289981
            ],
            [
              -81.353991,
              34.302884
            ],
            [
              -81.366471,
              34.325822
            ],
            [
              -81.372097,
              34.35166
            ],
            [
              -81.394845,
              34.387098
            ],
            [
              -81.397948,
              34.400933
            ],
            [
              -81.42124,
              34.453824
            ],
            [
              -81.412853,
              34.467946
            ],
            [
              -81.423241,
              34.494379
            ],
            [
              -81.443275,
              34.495599
            ],
            [
              -81.458723,
              34.486973
            ],
            [
              -81.468976,
              34.494052
            ],
            [
              -81.484647,
              34.490003
            ],
            [
              -81.541005,
              34.445034
            ],
            [
              -81.557021,
              34.463921
            ],
            [
              -81.552944,
              34.476874
            ],
            [
              -81.588783,
              34.497012
            ],
            [
              -81.617791,
              34.525249
            ],
            [
              -81.62719,
              34.521685
            ],
            [
              -81.643552,
              34.533652
            ],
            [
              -81.703198,
              34.452631
            ],
            [
              -81.722491,
              34.449661
            ],
            [
              -81.737103,
              34.430544
            ],
            [
              -81.782627,
              34.365089
            ],
            [
              -81.814861,
              34.346998
            ],
            [
              -81.837443,
              34.342377
            ],
            [
              -81.848467,
              34.323528
            ],
            [
              -81.867573,
              34.31639
            ],
            [
              -81.893003,
              34.302924
            ],
            [
              -81.903453,
              34.262864
            ],
            [
              -81.926727,
              34.219721
            ],
            [
              -81.945019,
              34.203148
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "063",
      "COUNTYNS": "01244251",
      "AFFGEOID": "0500000US45063",
      "GEOID": "45063",
      "NAME": "Lexington",
      "LSAD": "06",
      "ALAND": 1810553251,
      "AWATER": 151957530,
      "County_state": "Lexington,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.570302,
              33.884982
            ],
            [
              -81.571485,
              33.87723
            ],
            [
              -81.560733,
              33.862645
            ],
            [
              -81.546946,
              33.858683
            ],
            [
              -81.53359,
              33.832154
            ],
            [
              -81.512289,
              33.813081
            ],
            [
              -81.474697,
              33.798428
            ],
            [
              -81.460478,
              33.797156
            ],
            [
              -81.436146,
              33.775816
            ],
            [
              -81.426743,
              33.743197
            ],
            [
              -81.415308,
              33.732161
            ],
            [
              -81.357386,
              33.722866
            ],
            [
              -81.302858,
              33.704672
            ],
            [
              -81.287133,
              33.693113
            ],
            [
              -81.282836,
              33.694333
            ],
            [
              -81.277009,
              33.696206
            ],
            [
              -81.207395,
              33.658876
            ],
            [
              -81.187271,
              33.652937
            ],
            [
              -81.086633,
              33.695409
            ],
            [
              -81.05669,
              33.703432
            ],
            [
              -81.042854,
              33.707131
            ],
            [
              -80.991997,
              33.732172
            ],
            [
              -80.955743,
              33.737818
            ],
            [
              -80.945601,
              33.749297
            ],
            [
              -80.924968,
              33.756803
            ],
            [
              -80.939953,
              33.76913
            ],
            [
              -80.966995,
              33.777181
            ],
            [
              -81.014003,
              33.781241
            ],
            [
              -81.032867,
              33.764055
            ],
            [
              -81.036124,
              33.745211
            ],
            [
              -81.058521,
              33.747053
            ],
            [
              -81.054124,
              33.778538
            ],
            [
              -81.047516,
              33.789425
            ],
            [
              -81.038779,
              33.848355
            ],
            [
              -81.037903,
              33.870868
            ],
            [
              -81.01233,
              33.88008
            ],
            [
              -81.008754,
              33.904102
            ],
            [
              -81.029714,
              33.915454
            ],
            [
              -81.017549,
              33.932121
            ],
            [
              -81.017654,
              33.93237
            ],
            [
              -81.019314,
              33.936755
            ],
            [
              -81.045932,
              33.977339
            ],
            [
              -81.055615,
              34.001735
            ],
            [
              -81.103823,
              34.023316
            ],
            [
              -81.110651,
              34.036711
            ],
            [
              -81.121655,
              34.05155
            ],
            [
              -81.125517,
              34.055809
            ],
            [
              -81.139509,
              34.068292
            ],
            [
              -81.152598,
              34.077349
            ],
            [
              -81.171262,
              34.086879
            ],
            [
              -81.174015,
              34.089703
            ],
            [
              -81.186237,
              34.092728
            ],
            [
              -81.233433,
              34.101768
            ],
            [
              -81.275845,
              34.097381
            ],
            [
              -81.288312,
              34.117529
            ],
            [
              -81.29037,
              34.11732
            ],
            [
              -81.291016,
              34.117156
            ],
            [
              -81.311904,
              34.14118
            ],
            [
              -81.311734,
              34.143396
            ],
            [
              -81.299744,
              34.165373
            ],
            [
              -81.317265,
              34.186242
            ],
            [
              -81.338961,
              34.197587
            ],
            [
              -81.352349,
              34.196689
            ],
            [
              -81.374766,
              34.179009
            ],
            [
              -81.403672,
              34.177876
            ],
            [
              -81.437172,
              34.129963
            ],
            [
              -81.388564,
              34.080227
            ],
            [
              -81.394083,
              34.071545
            ],
            [
              -81.424727,
              34.08317
            ],
            [
              -81.444674,
              34.077884
            ],
            [
              -81.466669,
              34.087247
            ],
            [
              -81.471972,
              34.076552
            ],
            [
              -81.492397,
              34.034257
            ],
            [
              -81.534863,
              33.953376
            ],
            [
              -81.570302,
              33.884982
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "069",
      "COUNTYNS": "01248011",
      "AFFGEOID": "0500000US45069",
      "GEOID": "45069",
      "NAME": "Marlboro",
      "LSAD": "06",
      "ALAND": 1242867984,
      "AWATER": 14488283,
      "County_state": "Marlboro,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.92800950600581,
              34.8065635148516
            ],
            [
              -79.934322,
              34.80127
            ],
            [
              -79.92524,
              34.765296
            ],
            [
              -79.909735,
              34.7546
            ],
            [
              -79.893039,
              34.741964
            ],
            [
              -79.8753,
              34.707205
            ],
            [
              -79.874031,
              34.70403
            ],
            [
              -79.872941,
              34.69563
            ],
            [
              -79.855007,
              34.673351
            ],
            [
              -79.830454,
              34.657212
            ],
            [
              -79.79609,
              34.648044
            ],
            [
              -79.777461,
              34.613492
            ],
            [
              -79.803877,
              34.609053
            ],
            [
              -79.829981,
              34.531925
            ],
            [
              -79.833489,
              34.513434
            ],
            [
              -79.814885,
              34.497192
            ],
            [
              -79.809472,
              34.486079
            ],
            [
              -79.790503,
              34.485296
            ],
            [
              -79.782927,
              34.497769
            ],
            [
              -79.770102,
              34.496266
            ],
            [
              -79.764004,
              34.518314
            ],
            [
              -79.754578,
              34.505569
            ],
            [
              -79.727827,
              34.496824
            ],
            [
              -79.721901,
              34.48802
            ],
            [
              -79.734312,
              34.465992
            ],
            [
              -79.749274,
              34.467963
            ],
            [
              -79.749784,
              34.450595
            ],
            [
              -79.750566,
              34.436797
            ],
            [
              -79.725012,
              34.417243
            ],
            [
              -79.715279,
              34.394374
            ],
            [
              -79.701493,
              34.405343
            ],
            [
              -79.683401,
              34.399148
            ],
            [
              -79.689442,
              34.381765
            ],
            [
              -79.6737,
              34.374394
            ],
            [
              -79.692873,
              34.356841
            ],
            [
              -79.660364,
              34.32228
            ],
            [
              -79.657764,
              34.30519
            ],
            [
              -79.635979,
              34.30122
            ],
            [
              -79.633413,
              34.297994
            ],
            [
              -79.528833,
              34.482744
            ],
            [
              -79.450034,
              34.621036
            ],
            [
              -79.459766,
              34.629027
            ],
            [
              -79.461318,
              34.630126
            ],
            [
              -79.4615628883712,
              34.6302980076855
            ],
            [
              -79.468717,
              34.635323
            ],
            [
              -79.471599,
              34.6372
            ],
            [
              -79.4792780633414,
              34.6446139931559
            ],
            [
              -79.479305,
              34.64464
            ],
            [
              -79.490201,
              34.653819
            ],
            [
              -79.519043,
              34.677321
            ],
            [
              -79.520269,
              34.678327
            ],
            [
              -79.53249202544909,
              34.6883514183558
            ],
            [
              -79.554454,
              34.706363
            ],
            [
              -79.561691,
              34.711996
            ],
            [
              -79.631577,
              34.768835
            ],
            [
              -79.634216,
              34.771012
            ],
            [
              -79.675299,
              34.804744
            ],
            [
              -79.688088,
              34.804897
            ],
            [
              -79.690201,
              34.804937
            ],
            [
              -79.6929464055027,
              34.804973357591194
            ],
            [
              -79.744116,
              34.805651
            ],
            [
              -79.744925,
              34.805686
            ],
            [
              -79.772829,
              34.805954
            ],
            [
              -79.773607,
              34.805931
            ],
            [
              -79.8122754663295,
              34.805710745155004
            ],
            [
              -79.870693,
              34.805378
            ],
            [
              -79.891443,
              34.805807
            ],
            [
              -79.9274157876858,
              34.8065508188027
            ],
            [
              -79.927618,
              34.806555
            ],
            [
              -79.92800950600581,
              34.8065635148516
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "011",
      "COUNTYNS": "01247983",
      "AFFGEOID": "0500000US45011",
      "GEOID": "45011",
      "NAME": "Barnwell",
      "LSAD": "06",
      "ALAND": 1420294943,
      "AWATER": 23008120,
      "County_state": "Barnwell,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.772435,
              33.181249
            ],
            [
              -81.772435,
              33.180449
            ],
            [
              -81.766735,
              33.170749
            ],
            [
              -81.764435,
              33.165549
            ],
            [
              -81.763135,
              33.159449
            ],
            [
              -81.755135,
              33.15155
            ],
            [
              -81.743835,
              33.14145
            ],
            [
              -81.724334,
              33.129451
            ],
            [
              -81.717134,
              33.124051
            ],
            [
              -81.704634,
              33.116451
            ],
            [
              -81.703134,
              33.116151
            ],
            [
              -81.699834,
              33.116751
            ],
            [
              -81.696934,
              33.116551
            ],
            [
              -81.683533,
              33.112651
            ],
            [
              -81.658433,
              33.103152
            ],
            [
              -81.646433,
              33.094552
            ],
            [
              -81.642333,
              33.093152
            ],
            [
              -81.637232,
              33.092952
            ],
            [
              -81.632232,
              33.093952
            ],
            [
              -81.617779,
              33.095277
            ],
            [
              -81.615132,
              33.095036
            ],
            [
              -81.61429847805029,
              33.0946609670663
            ],
            [
              -81.541881,
              33.158279
            ],
            [
              -81.453202,
              33.135021
            ],
            [
              -81.364857,
              33.108061
            ],
            [
              -81.337806,
              33.108221
            ],
            [
              -81.323793,
              33.10831
            ],
            [
              -81.264333,
              33.110823
            ],
            [
              -81.210497,
              33.120637
            ],
            [
              -81.193099,
              33.118668
            ],
            [
              -81.206442,
              33.129635
            ],
            [
              -81.215924,
              33.150115
            ],
            [
              -81.227396,
              33.160766
            ],
            [
              -81.220603,
              33.206018
            ],
            [
              -81.220824,
              33.259149
            ],
            [
              -81.215167,
              33.366255
            ],
            [
              -81.213182,
              33.397866
            ],
            [
              -81.222673,
              33.44
            ],
            [
              -81.242908,
              33.441136
            ],
            [
              -81.26327,
              33.451563
            ],
            [
              -81.301445,
              33.481218
            ],
            [
              -81.344231,
              33.480891
            ],
            [
              -81.372931,
              33.490417
            ],
            [
              -81.37808,
              33.487132
            ],
            [
              -81.516319,
              33.382311
            ],
            [
              -81.7570833221477,
              33.1981218255034
            ],
            [
              -81.756935,
              33.197848
            ],
            [
              -81.760635,
              33.189248
            ],
            [
              -81.762835,
              33.188248
            ],
            [
              -81.765735,
              33.187948
            ],
            [
              -81.772435,
              33.181249
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "021",
      "COUNTYNS": "01247987",
      "AFFGEOID": "0500000US45021",
      "GEOID": "45021",
      "NAME": "Cherokee",
      "LSAD": "06",
      "ALAND": 1017382887,
      "AWATER": 11701702,
      "County_state": "Cherokee,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.8743859859076,
              35.1841112338525
            ],
            [
              -81.842446,
              35.123684
            ],
            [
              -81.833745,
              35.107927
            ],
            [
              -81.805985,
              35.055552
            ],
            [
              -81.806384,
              35.048156
            ],
            [
              -81.807212,
              35.035813
            ],
            [
              -81.794288,
              35.035693
            ],
            [
              -81.774247,
              34.988485
            ],
            [
              -81.754911,
              34.932672
            ],
            [
              -81.734831,
              34.932928
            ],
            [
              -81.711694,
              34.912577
            ],
            [
              -81.684598,
              34.913027
            ],
            [
              -81.663631,
              34.898679
            ],
            [
              -81.64672,
              34.900151
            ],
            [
              -81.640692,
              34.881344
            ],
            [
              -81.611602,
              34.876146
            ],
            [
              -81.586733,
              34.887313
            ],
            [
              -81.543533,
              34.869886
            ],
            [
              -81.514872,
              34.87713
            ],
            [
              -81.481945,
              34.866495
            ],
            [
              -81.457257,
              34.83928
            ],
            [
              -81.453309,
              34.846358
            ],
            [
              -81.463063,
              34.854969
            ],
            [
              -81.47873,
              34.899958
            ],
            [
              -81.470974,
              34.912316
            ],
            [
              -81.473842,
              34.935882
            ],
            [
              -81.492385,
              34.946774
            ],
            [
              -81.475875,
              34.98232
            ],
            [
              -81.488286,
              35.026868
            ],
            [
              -81.453264,
              35.048887
            ],
            [
              -81.409356,
              35.048761
            ],
            [
              -81.40371,
              35.10733
            ],
            [
              -81.401337,
              35.136676
            ],
            [
              -81.3665997502835,
              35.164889487764
            ],
            [
              -81.366691,
              35.164893
            ],
            [
              -81.445627,
              35.168024
            ],
            [
              -81.452398,
              35.168293
            ],
            [
              -81.461408,
              35.168657
            ],
            [
              -81.493401,
              35.169951
            ],
            [
              -81.494265,
              35.169882
            ],
            [
              -81.54515,
              35.171744
            ],
            [
              -81.581681,
              35.17308
            ],
            [
              -81.614877,
              35.174504
            ],
            [
              -81.646707,
              35.175869
            ],
            [
              -81.680801,
              35.177331
            ],
            [
              -81.716259,
              35.178852
            ],
            [
              -81.738492,
              35.179511
            ],
            [
              -81.7681085961812,
              35.180388327917804
            ],
            [
              -81.772351,
              35.180514
            ],
            [
              -81.802081,
              35.181395
            ],
            [
              -81.8743859859076,
              35.1841112338525
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "067",
      "COUNTYNS": "01248010",
      "AFFGEOID": "0500000US45067",
      "GEOID": "45067",
      "NAME": "Marion",
      "LSAD": "06",
      "ALAND": 1267162021,
      "AWATER": 12643194,
      "County_state": "Marion,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -79.57188,
              34.185366
            ],
            [
              -79.568984,
              34.175087
            ],
            [
              -79.543957,
              34.151698
            ],
            [
              -79.532479,
              34.126311
            ],
            [
              -79.517205,
              34.126267
            ],
            [
              -79.524548,
              34.116737
            ],
            [
              -79.515308,
              34.097207
            ],
            [
              -79.529432,
              34.099845
            ],
            [
              -79.528251,
              34.070969
            ],
            [
              -79.512859,
              34.046055
            ],
            [
              -79.512825,
              34.013761
            ],
            [
              -79.508646,
              34.004989
            ],
            [
              -79.51694,
              33.99398
            ],
            [
              -79.496095,
              33.969257
            ],
            [
              -79.481463,
              33.963921
            ],
            [
              -79.492908,
              33.949735
            ],
            [
              -79.482632,
              33.937589
            ],
            [
              -79.428997,
              33.897811
            ],
            [
              -79.401346,
              33.892806
            ],
            [
              -79.409838,
              33.885294
            ],
            [
              -79.389519,
              33.873197
            ],
            [
              -79.389787,
              33.867075
            ],
            [
              -79.368341,
              33.842931
            ],
            [
              -79.344857,
              33.842596
            ],
            [
              -79.336864,
              33.832552
            ],
            [
              -79.314838,
              33.826072
            ],
            [
              -79.314591,
              33.806982
            ],
            [
              -79.324317,
              33.799123
            ],
            [
              -79.323277,
              33.788741
            ],
            [
              -79.317041,
              33.779878
            ],
            [
              -79.292578,
              33.750167
            ],
            [
              -79.286248,
              33.737386
            ],
            [
              -79.291574,
              33.728981
            ],
            [
              -79.282995,
              33.715972
            ],
            [
              -79.271664,
              33.721855
            ],
            [
              -79.275057,
              33.708852
            ],
            [
              -79.266806,
              33.703173
            ],
            [
              -79.240655,
              33.704707
            ],
            [
              -79.246384,
              33.695347
            ],
            [
              -79.190264,
              33.705787
            ],
            [
              -79.1959,
              33.727886
            ],
            [
              -79.219768,
              33.747877
            ],
            [
              -79.220452,
              33.756982
            ],
            [
              -79.230025,
              33.761821
            ],
            [
              -79.248094,
              33.784415
            ],
            [
              -79.252414,
              33.807834
            ],
            [
              -79.260283,
              33.814854
            ],
            [
              -79.249115,
              33.829754
            ],
            [
              -79.261223,
              33.838733
            ],
            [
              -79.256723,
              33.864302
            ],
            [
              -79.263758,
              33.892689
            ],
            [
              -79.295994,
              33.900696
            ],
            [
              -79.338842,
              33.970494
            ],
            [
              -79.333289,
              33.994123
            ],
            [
              -79.315079,
              33.996506
            ],
            [
              -79.302107,
              34.029185
            ],
            [
              -79.265089,
              34.043982
            ],
            [
              -79.242961,
              34.067958
            ],
            [
              -79.250441,
              34.089758
            ],
            [
              -79.205771,
              34.132973
            ],
            [
              -79.207807,
              34.145285
            ],
            [
              -79.191673,
              34.176412
            ],
            [
              -79.171441,
              34.181737
            ],
            [
              -79.153939,
              34.207207
            ],
            [
              -79.131079,
              34.22126
            ],
            [
              -79.134709,
              34.22592
            ],
            [
              -79.128264,
              34.230743
            ],
            [
              -79.128165,
              34.253652
            ],
            [
              -79.173475,
              34.287534
            ],
            [
              -79.21432,
              34.255539
            ],
            [
              -79.239802,
              34.254339
            ],
            [
              -79.259536,
              34.259031
            ],
            [
              -79.271857,
              34.269754
            ],
            [
              -79.294397,
              34.276994
            ],
            [
              -79.305384,
              34.269307
            ],
            [
              -79.374529,
              34.298855
            ],
            [
              -79.41104,
              34.300172
            ],
            [
              -79.432795,
              34.293889
            ],
            [
              -79.44015,
              34.292831
            ],
            [
              -79.480712,
              34.288081
            ],
            [
              -79.524424,
              34.251585
            ],
            [
              -79.554711,
              34.247016
            ],
            [
              -79.54793,
              34.230913
            ],
            [
              -79.544036,
              34.219676
            ],
            [
              -79.548316,
              34.204432
            ],
            [
              -79.57188,
              34.185366
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "079",
      "COUNTYNS": "01249299",
      "AFFGEOID": "0500000US45079",
      "GEOID": "45079",
      "NAME": "Richland",
      "LSAD": "06",
      "ALAND": 1960994034,
      "AWATER": 37854754,
      "County_state": "Richland,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.345328,
              34.20503
            ],
            [
              -81.338961,
              34.197587
            ],
            [
              -81.317265,
              34.186242
            ],
            [
              -81.299744,
              34.165373
            ],
            [
              -81.311734,
              34.143396
            ],
            [
              -81.311904,
              34.14118
            ],
            [
              -81.291016,
              34.117156
            ],
            [
              -81.29037,
              34.11732
            ],
            [
              -81.288312,
              34.117529
            ],
            [
              -81.275845,
              34.097381
            ],
            [
              -81.233433,
              34.101768
            ],
            [
              -81.186237,
              34.092728
            ],
            [
              -81.174015,
              34.089703
            ],
            [
              -81.171262,
              34.086879
            ],
            [
              -81.152598,
              34.077349
            ],
            [
              -81.139509,
              34.068292
            ],
            [
              -81.125517,
              34.055809
            ],
            [
              -81.121655,
              34.05155
            ],
            [
              -81.110651,
              34.036711
            ],
            [
              -81.103823,
              34.023316
            ],
            [
              -81.055615,
              34.001735
            ],
            [
              -81.045932,
              33.977339
            ],
            [
              -81.019314,
              33.936755
            ],
            [
              -81.017654,
              33.93237
            ],
            [
              -81.017549,
              33.932121
            ],
            [
              -81.029714,
              33.915454
            ],
            [
              -81.008754,
              33.904102
            ],
            [
              -81.01233,
              33.88008
            ],
            [
              -80.996574,
              33.873808
            ],
            [
              -80.999784,
              33.860602
            ],
            [
              -80.986217,
              33.857464
            ],
            [
              -80.985706,
              33.84873
            ],
            [
              -80.965945,
              33.847848
            ],
            [
              -80.950825,
              33.840895
            ],
            [
              -80.940584,
              33.828482
            ],
            [
              -80.913103,
              33.832934
            ],
            [
              -80.91731,
              33.813735
            ],
            [
              -80.898535,
              33.808767
            ],
            [
              -80.879813,
              33.81562
            ],
            [
              -80.865759,
              33.802719
            ],
            [
              -80.837472,
              33.793906
            ],
            [
              -80.829259,
              33.778193
            ],
            [
              -80.81569,
              33.781069
            ],
            [
              -80.807591,
              33.774211
            ],
            [
              -80.782968,
              33.768899
            ],
            [
              -80.78486,
              33.761008
            ],
            [
              -80.759731,
              33.773997
            ],
            [
              -80.747337,
              33.761742
            ],
            [
              -80.746995,
              33.776447
            ],
            [
              -80.737377,
              33.756617
            ],
            [
              -80.722537,
              33.767346
            ],
            [
              -80.686592,
              33.755993
            ],
            [
              -80.659051,
              33.772986
            ],
            [
              -80.656399,
              33.761398
            ],
            [
              -80.620107,
              33.743237
            ],
            [
              -80.605638,
              33.757476
            ],
            [
              -80.596205,
              33.786431
            ],
            [
              -80.615676,
              33.814514
            ],
            [
              -80.619074,
              33.839308
            ],
            [
              -80.633545,
              33.85268
            ],
            [
              -80.625332,
              33.866811
            ],
            [
              -80.61703,
              33.895519
            ],
            [
              -80.634093,
              33.917333
            ],
            [
              -80.621527,
              33.933758
            ],
            [
              -80.628261,
              33.948781
            ],
            [
              -80.61576,
              33.965472
            ],
            [
              -80.633382,
              33.966967
            ],
            [
              -80.646379,
              33.999662
            ],
            [
              -80.625285,
              34.003704
            ],
            [
              -80.600952,
              34.039386
            ],
            [
              -80.606416,
              34.057982
            ],
            [
              -80.613869,
              34.057324
            ],
            [
              -80.636526,
              34.082982
            ],
            [
              -80.616186,
              34.099605
            ],
            [
              -80.658978,
              34.085743
            ],
            [
              -80.692965,
              34.08128
            ],
            [
              -80.719318,
              34.068972
            ],
            [
              -80.775505,
              34.113473
            ],
            [
              -80.820179,
              34.149167
            ],
            [
              -80.851379,
              34.174071
            ],
            [
              -80.868509,
              34.181705
            ],
            [
              -80.867054,
              34.186669
            ],
            [
              -80.840951,
              34.238921
            ],
            [
              -80.825921,
              34.26876
            ],
            [
              -80.90364,
              34.259249
            ],
            [
              -80.961986,
              34.25201
            ],
            [
              -81.040052,
              34.232779
            ],
            [
              -81.042528,
              34.236596
            ],
            [
              -81.069867,
              34.232246
            ],
            [
              -81.095863,
              34.213872
            ],
            [
              -81.181196,
              34.220285
            ],
            [
              -81.168877,
              34.194375
            ],
            [
              -81.178293,
              34.174552
            ],
            [
              -81.226412,
              34.205687
            ],
            [
              -81.251273,
              34.202946
            ],
            [
              -81.27824,
              34.229632
            ],
            [
              -81.296605,
              34.237391
            ],
            [
              -81.316911,
              34.239458
            ],
            [
              -81.345328,
              34.20503
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "45",
      "COUNTYFP": "017",
      "COUNTYNS": "01247986",
      "AFFGEOID": "0500000US45017",
      "GEOID": "45017",
      "NAME": "Calhoun",
      "LSAD": "06",
      "ALAND": 987179165,
      "AWATER": 29350862,
      "County_state": "Calhoun,45"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -81.054124,
              33.778538
            ],
            [
              -81.058521,
              33.747053
            ],
            [
              -81.036124,
              33.745211
            ],
            [
              -81.032867,
              33.764055
            ],
            [
              -81.014003,
              33.781241
            ],
            [
              -80.966995,
              33.777181
            ],
            [
              -80.939953,
              33.76913
            ],
            [
              -80.924968,
              33.756803
            ],
            [
              -80.945601,
              33.749297
            ],
            [
              -80.955743,
              33.737818
            ],
            [
              -80.991997,
              33.732172
            ],
            [
              -81.042854,
              33.707131
            ],
            [
              -80.98421,
              33.644797
            ],
            [
              -80.965489,
              33.625282
            ],
            [
              -80.943954,
              33.643604
            ],
            [
              -80.939785,
              33.608813
            ],
            [
              -80.919677,
              33.605505
            ],
            [
              -80.885195,
              33.618289
            ],
            [
              -80.807631,
              33.604081
            ],
            [
              -80.731565,
              33.549502
            ],
            [
              -80.693855,
              33.521753
            ],
            [
              -80.69609,
              33.510458
            ],
            [
              -80.65902,
              33.470982
            ],
            [
              -80.627291,
              33.473821
            ],
            [
              -80.611327,
              33.470416
            ],
            [
              -80.624951,
              33.488763
            ],
            [
              -80.624702,
              33.498899
            ],
            [
              -80.587927,
              33.538882
            ],
            [
              -80.610346,
              33.550732
            ],
            [
              -80.603615,
              33.5554
            ],
            [
              -80.552272,
              33.565414
            ],
            [
              -80.540143,
              33.5618
            ],
            [
              -80.511206,
              33.538429
            ],
            [
              -80.496273,
              33.558481
            ],
            [
              -80.515234,
              33.574705
            ],
            [
              -80.503335,
              33.591624
            ],
            [
              -80.519799,
              33.597368
            ],
            [
              -80.521569,
              33.61653
            ],
            [
              -80.540514,
              33.629032
            ],
            [
              -80.534085,
              33.643911
            ],
            [
              -80.565379,
              33.666075
            ],
            [
              -80.565209,
              33.679712
            ],
            [
              -80.58978,
              33.684238
            ],
            [
              -80.615264,
              33.711835
            ],
            [
              -80.629638,
              33.73892
            ],
            [
              -80.620107,
              33.743237
            ],
            [
              -80.656399,
              33.761398
            ],
            [
              -80.659051,
              33.772986
            ],
            [
              -80.686592,
              33.755993
            ],
            [
              -80.722537,
              33.767346
            ],
            [
              -80.737377,
              33.756617
            ],
            [
              -80.746995,
              33.776447
            ],
            [
              -80.747337,
              33.761742
            ],
            [
              -80.759731,
              33.773997
            ],
            [
              -80.78486,
              33.761008
            ],
            [
              -80.782968,
              33.768899
            ],
            [
              -80.807591,
              33.774211
            ],
            [
              -80.81569,
              33.781069
            ],
            [
              -80.829259,
              33.778193
            ],
            [
              -80.837472,
              33.793906
            ],
            [
              -80.865759,
              33.802719
            ],
            [
              -80.879813,
              33.81562
            ],
            [
              -80.898535,
              33.808767
            ],
            [
              -80.91731,
              33.813735
            ],
            [
              -80.913103,
              33.832934
            ],
            [
              -80.940584,
              33.828482
            ],
            [
              -80.950825,
              33.840895
            ],
            [
              -80.965945,
              33.847848
            ],
            [
              -80.985706,
              33.84873
            ],
            [
              -80.986217,
              33.857464
            ],
            [
              -80.999784,
              33.860602
            ],
            [
              -80.996574,
              33.873808
            ],
            [
              -81.01233,
              33.88008
            ],
            [
              -81.037903,
              33.870868
            ],
            [
              -81.038779,
              33.848355
            ],
            [
              -81.047516,
              33.789425
            ],
            [
              -81.054124,
              33.778538
            ]
          ]
        ]
      ]
    }
  }
]
};