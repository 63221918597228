export const counties_vi = {
  "type":"FeatureCollection","name":"vi_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "78",
      "COUNTYFP": "030",
      "COUNTYNS": "02378250",
      "AFFGEOID": "0500000US78030",
      "GEOID": "78030",
      "NAME": "St. Thomas",
      "LSAD": "10",
      "ALAND": 81102199,
      "AWATER": 717927568,
      "County_state": "St. Thomas,78"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -64.904981,
              18.276422
            ],
            [
              -64.905872,
              18.267959
            ],
            [
              -64.891908,
              18.269369
            ],
            [
              -64.879133,
              18.268241
            ],
            [
              -64.87943,
              18.278115
            ],
            [
              -64.893373,
              18.279741
            ],
            [
              -64.904981,
              18.276422
            ]
          ]
        ],
        [
          [
            [
              -64.917628,
              18.404513
            ],
            [
              -64.91473,
              18.395264
            ],
            [
              -64.907652,
              18.387414
            ],
            [
              -64.89904,
              18.393121
            ],
            [
              -64.903002,
              18.399825
            ],
            [
              -64.902073,
              18.407274
            ],
            [
              -64.90436,
              18.411648
            ],
            [
              -64.910802,
              18.413486
            ],
            [
              -64.917628,
              18.404513
            ]
          ]
        ],
        [
          [
            [
              -65.009263,
              18.30576
            ],
            [
              -65.007183,
              18.296451
            ],
            [
              -64.995596,
              18.295887
            ],
            [
              -64.992922,
              18.306042
            ],
            [
              -65.000647,
              18.313657
            ],
            [
              -65.009263,
              18.30576
            ]
          ]
        ],
        [
          [
            [
              -65.08452,
              18.339318
            ],
            [
              -65.085361,
              18.335675
            ],
            [
              -65.077441,
              18.336787
            ],
            [
              -65.027642,
              18.341623
            ],
            [
              -65.022388,
              18.341366
            ],
            [
              -65.012549,
              18.342303
            ],
            [
              -64.999375,
              18.347464
            ],
            [
              -64.983722,
              18.33543
            ],
            [
              -64.972907,
              18.32891
            ],
            [
              -64.964913,
              18.325028
            ],
            [
              -64.961322,
              18.313917
            ],
            [
              -64.957341,
              18.305909
            ],
            [
              -64.952413,
              18.310695
            ],
            [
              -64.940576,
              18.317165
            ],
            [
              -64.933154,
              18.322505
            ],
            [
              -64.922714,
              18.319448
            ],
            [
              -64.89904,
              18.310035
            ],
            [
              -64.878847,
              18.302774
            ],
            [
              -64.873654,
              18.304426
            ],
            [
              -64.846288,
              18.311989
            ],
            [
              -64.829185,
              18.296667
            ],
            [
              -64.821102,
              18.297982
            ],
            [
              -64.822569,
              18.312711
            ],
            [
              -64.835575,
              18.327657
            ],
            [
              -64.852172,
              18.340408
            ],
            [
              -64.855809,
              18.343901
            ],
            [
              -64.853626,
              18.348458
            ],
            [
              -64.8437107199253,
              18.350169062183298
            ],
            [
              -64.84373014852419,
              18.3665673384852
            ],
            [
              -64.870648,
              18.367513
            ],
            [
              -64.893804,
              18.362817
            ],
            [
              -64.912724,
              18.366192
            ],
            [
              -64.940621,
              18.383284
            ],
            [
              -64.952467,
              18.375908
            ],
            [
              -64.963338,
              18.383632
            ],
            [
              -64.96859,
              18.394983
            ],
            [
              -64.974961,
              18.401262
            ],
            [
              -64.981064,
              18.394987
            ],
            [
              -64.984166,
              18.384894
            ],
            [
              -65.007357,
              18.367516
            ],
            [
              -65.024691,
              18.361914
            ],
            [
              -65.059905,
              18.381786
            ],
            [
              -65.06434,
              18.379913
            ],
            [
              -65.075364,
              18.34695
            ],
            [
              -65.08452,
              18.339318
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "78",
      "COUNTYFP": "010",
      "COUNTYNS": "02378248",
      "AFFGEOID": "0500000US78010",
      "GEOID": "78010",
      "NAME": "St. Croix",
      "LSAD": "10",
      "ALAND": 215914994,
      "AWATER": 645644841,
      "County_state": "St. Croix,78"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -64.634323,
              17.795891
            ],
            [
              -64.63254,
              17.787121
            ],
            [
              -64.622061,
              17.783843
            ],
            [
              -64.607287,
              17.784858
            ],
            [
              -64.606693,
              17.790516
            ],
            [
              -64.622142,
              17.796456
            ],
            [
              -64.634323,
              17.795891
            ]
          ]
        ],
        [
          [
            [
              -64.905872,
              17.68072
            ],
            [
              -64.901062,
              17.674091
            ],
            [
              -64.878017,
              17.68424
            ],
            [
              -64.850314,
              17.686381
            ],
            [
              -64.839916,
              17.679305
            ],
            [
              -64.797421,
              17.693371
            ],
            [
              -64.776931,
              17.694023
            ],
            [
              -64.763561,
              17.682135
            ],
            [
              -64.75931,
              17.683924
            ],
            [
              -64.741905,
              17.695778
            ],
            [
              -64.717374,
              17.699481
            ],
            [
              -64.693131,
              17.705354
            ],
            [
              -64.665585,
              17.714003
            ],
            [
              -64.639341,
              17.721721
            ],
            [
              -64.609816,
              17.732914
            ],
            [
              -64.604785,
              17.742646
            ],
            [
              -64.58652,
              17.745002
            ],
            [
              -64.577869,
              17.746111
            ],
            [
              -64.567178,
              17.751189
            ],
            [
              -64.566547,
              17.756987
            ],
            [
              -64.583834,
              17.761869
            ],
            [
              -64.614527,
              17.761545
            ],
            [
              -64.655832,
              17.763684
            ],
            [
              -64.679482,
              17.761658
            ],
            [
              -64.694089,
              17.75555
            ],
            [
              -64.723596,
              17.75753
            ],
            [
              -64.747518,
              17.78275
            ],
            [
              -64.778713,
              17.785141
            ],
            [
              -64.805749,
              17.779766
            ],
            [
              -64.836351,
              17.76081
            ],
            [
              -64.870814,
              17.772127
            ],
            [
              -64.887452,
              17.763356
            ],
            [
              -64.895176,
              17.746379
            ],
            [
              -64.886859,
              17.725886
            ],
            [
              -64.883698,
              17.712194
            ],
            [
              -64.905872,
              17.68072
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "78",
      "COUNTYFP": "020",
      "COUNTYNS": "02378249",
      "AFFGEOID": "0500000US78020",
      "GEOID": "78020",
      "NAME": "St. John",
      "LSAD": "10",
      "ALAND": 50996518,
      "AWATER": 186671948,
      "County_state": "St. John,78"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -64.797864,
              18.337156
            ],
            [
              -64.797883,
              18.327747
            ],
            [
              -64.79191,
              18.316735
            ],
            [
              -64.780893,
              18.310644
            ],
            [
              -64.765888,
              18.310581
            ],
            [
              -64.757197,
              18.316656
            ],
            [
              -64.733624,
              18.314886
            ],
            [
              -64.722114,
              18.308012
            ],
            [
              -64.703118,
              18.298203
            ],
            [
              -64.699826,
              18.303091
            ],
            [
              -64.701651,
              18.309864
            ],
            [
              -64.697269,
              18.320876
            ],
            [
              -64.700963,
              18.330558
            ],
            [
              -64.704766,
              18.340338
            ],
            [
              -64.692189,
              18.339148
            ],
            [
              -64.677062,
              18.332247
            ],
            [
              -64.670146,
              18.330304
            ],
            [
              -64.6675,
              18.335996
            ],
            [
              -64.662893,
              18.336243
            ],
            [
              -64.660866,
              18.343824
            ],
            [
              -64.674244,
              18.3503
            ],
            [
              -64.67823,
              18.348442
            ],
            [
              -64.686334,
              18.353484
            ],
            [
              -64.687965,
              18.358703
            ],
            [
              -64.694805,
              18.361902
            ],
            [
              -64.704202,
              18.361044
            ],
            [
              -64.713305,
              18.367283
            ],
            [
              -64.719478,
              18.366689
            ],
            [
              -64.721235,
              18.3626
            ],
            [
              -64.733349,
              18.363475
            ],
            [
              -64.734224,
              18.37041
            ],
            [
              -64.747585,
              18.372861
            ],
            [
              -64.752324,
              18.370797
            ],
            [
              -64.755062,
              18.354046
            ],
            [
              -64.76743,
              18.354141
            ],
            [
              -64.786926,
              18.35266
            ],
            [
              -64.797864,
              18.337156
            ]
          ]
        ],
        [
          [
            [
              -64.84373014852419,
              18.3665673384852
            ],
            [
              -64.8437107199253,
              18.350169062183298
            ],
            [
              -64.834422,
              18.351772
            ],
            [
              -64.804743,
              18.351772
            ],
            [
              -64.797719,
              18.363976
            ],
            [
              -64.797324,
              18.371656
            ],
            [
              -64.809544,
              18.373312
            ],
            [
              -64.835295,
              18.366271
            ],
            [
              -64.84373014852419,
              18.3665673384852
            ]
          ]
        ]
      ]
    }
  }
]
};