export const counties_ma = {
  "type":"FeatureCollection","name":"ma_counties","features":[
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "25",
      "COUNTYFP": "025",
      "COUNTYNS": "00606939",
      "AFFGEOID": "0500000US25025",
      "GEOID": "25025",
      "NAME": "Suffolk",
      "LSAD": "06",
      "ALAND": 150855462,
      "AWATER": 160479920,
      "County_state": "Suffolk,25"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -71.191155,
              42.283059
            ],
            [
              -71.188167,
              42.280412
            ],
            [
              -71.174764,
              42.275842
            ],
            [
              -71.155958,
              42.256485
            ],
            [
              -71.150386,
              42.25746
            ],
            [
              -71.130771,
              42.227926
            ],
            [
              -71.109347,
              42.24799
            ],
            [
              -71.113009,
              42.258689
            ],
            [
              -71.1085,
              42.261061
            ],
            [
              -71.093909,
              42.267112
            ],
            [
              -71.093737,
              42.267107
            ],
            [
              -71.08982,
              42.269539
            ],
            [
              -71.073264,
              42.270317
            ],
            [
              -71.060979,
              42.267454
            ],
            [
              -71.053284,
              42.277502
            ],
            [
              -71.042782,
              42.277102
            ],
            [
              -71.037894,
              42.284898
            ],
            [
              -71.041594,
              42.303692
            ],
            [
              -71.037094,
              42.306598
            ],
            [
              -71.028395,
              42.308799
            ],
            [
              -71.009875,
              42.305221
            ],
            [
              -70.9917773371543,
              42.313099521268
            ],
            [
              -70.997838,
              42.321205
            ],
            [
              -71.014052,
              42.319159
            ],
            [
              -71.01568,
              42.326019
            ],
            [
              -71.01455487897108,
              42.328705589120396
            ],
            [
              -71.0086734035467,
              42.3427495074916
            ],
            [
              -71.006877,
              42.347039
            ],
            [
              -70.998253,
              42.352788
            ],
            [
              -70.98228222112469,
              42.3559201062577
            ],
            [
              -70.979927,
              42.356382
            ],
            [
              -70.974897,
              42.355843
            ],
            [
              -70.963578,
              42.34686
            ],
            [
              -70.953022,
              42.343973
            ],
            [
              -70.953292,
              42.349698
            ],
            [
              -70.972223,
              42.377316
            ],
            [
              -70.972706,
              42.381759
            ],
            [
              -70.972513,
              42.385042
            ],
            [
              -70.97174,
              42.387071
            ],
            [
              -70.970195,
              42.388036
            ],
            [
              -70.972706,
              42.389968
            ],
            [
              -70.980336,
              42.391513
            ],
            [
              -70.983426,
              42.396246
            ],
            [
              -70.985068,
              42.402041
            ],
            [
              -70.989195,
              42.402598
            ],
            [
              -70.9892164824447,
              42.4026670507152
            ],
            [
              -70.990595,
              42.407098
            ],
            [
              -70.987694,
              42.416696
            ],
            [
              -70.9860229810294,
              42.4192914124436
            ],
            [
              -70.982994,
              42.423996
            ],
            [
              -70.960835,
              42.441272
            ],
            [
              -70.9606474235863,
              42.4437870656677
            ],
            [
              -70.968594,
              42.444396
            ],
            [
              -70.982294,
              42.431296
            ],
            [
              -71.010835,
              42.434682
            ],
            [
              -71.009671,
              42.441047
            ],
            [
              -71.025836,
              42.444709
            ],
            [
              -71.020377,
              42.43825
            ],
            [
              -71.028227,
              42.424202
            ],
            [
              -71.033181,
              42.414206
            ],
            [
              -71.040887,
              42.401749
            ],
            [
              -71.055295,
              42.387097
            ],
            [
              -71.073496,
              42.391796
            ],
            [
              -71.080947,
              42.382378
            ],
            [
              -71.072689,
              42.373222
            ],
            [
              -71.064059,
              42.369001
            ],
            [
              -71.070644,
              42.367949
            ],
            [
              -71.075777,
              42.361621
            ],
            [
              -71.077095,
              42.358697
            ],
            [
              -71.094042,
              42.35371
            ],
            [
              -71.103017,
              42.352638
            ],
            [
              -71.116995,
              42.361173
            ],
            [
              -71.117193,
              42.364229
            ],
            [
              -71.118497,
              42.368397
            ],
            [
              -71.130997,
              42.373796
            ],
            [
              -71.14363,
              42.364981
            ],
            [
              -71.147507,
              42.361788
            ],
            [
              -71.167625,
              42.360073
            ],
            [
              -71.17485,
              42.350337
            ],
            [
              -71.170003,
              42.344252
            ],
            [
              -71.167565,
              42.333441
            ],
            [
              -71.157036,
              42.33039
            ],
            [
              -71.146215,
              42.338632
            ],
            [
              -71.12878,
              42.349559
            ],
            [
              -71.121311,
              42.351675
            ],
            [
              -71.113784,
              42.35078
            ],
            [
              -71.106894,
              42.348588
            ],
            [
              -71.110597,
              42.340405
            ],
            [
              -71.110808,
              42.335586
            ],
            [
              -71.114547,
              42.328372
            ],
            [
              -71.119539,
              42.322745
            ],
            [
              -71.127177,
              42.318607
            ],
            [
              -71.140135,
              42.302147
            ],
            [
              -71.152148,
              42.294613
            ],
            [
              -71.164702,
              42.30383
            ],
            [
              -71.178636,
              42.294595
            ],
            [
              -71.191155,
              42.283059
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "25",
      "COUNTYFP": "011",
      "COUNTYNS": "00606932",
      "AFFGEOID": "0500000US25011",
      "GEOID": "25011",
      "NAME": "Franklin",
      "LSAD": "06",
      "ALAND": 1810952650,
      "AWATER": 65556538,
      "County_state": "Franklin,25"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -73.0230149955487,
              42.7411352241426
            ],
            [
              -73.023709,
              42.70269
            ],
            [
              -72.948838,
              42.704263
            ],
            [
              -72.954608,
              42.690106
            ],
            [
              -72.974705,
              42.68866
            ],
            [
              -72.995635,
              42.673342
            ],
            [
              -72.975017,
              42.660938
            ],
            [
              -72.95464,
              42.665044
            ],
            [
              -72.951039,
              42.641006
            ],
            [
              -72.975409,
              42.556037
            ],
            [
              -72.876849,
              42.541197
            ],
            [
              -72.871136,
              42.484041
            ],
            [
              -72.773329,
              42.466782
            ],
            [
              -72.757758,
              42.445883
            ],
            [
              -72.700877,
              42.45292
            ],
            [
              -72.704392,
              42.40555
            ],
            [
              -72.669138,
              42.409711
            ],
            [
              -72.589251,
              42.422341
            ],
            [
              -72.581143,
              42.422581
            ],
            [
              -72.544199,
              42.42715
            ],
            [
              -72.489891,
              42.433816
            ],
            [
              -72.483696,
              42.407481
            ],
            [
              -72.375022,
              42.420819
            ],
            [
              -72.366163,
              42.378208
            ],
            [
              -72.356443,
              42.366156
            ],
            [
              -72.355396,
              42.340678
            ],
            [
              -72.351157,
              42.334549
            ],
            [
              -72.355942,
              42.303401
            ],
            [
              -72.350373,
              42.304168
            ],
            [
              -72.327344,
              42.3198
            ],
            [
              -72.314599,
              42.343526
            ],
            [
              -72.310236,
              42.38753
            ],
            [
              -72.315994,
              42.394544
            ],
            [
              -72.306284,
              42.420114
            ],
            [
              -72.283408,
              42.441196
            ],
            [
              -72.291288,
              42.479525
            ],
            [
              -72.244868,
              42.513439
            ],
            [
              -72.269111,
              42.533425
            ],
            [
              -72.276434,
              42.577374
            ],
            [
              -72.261333,
              42.575922
            ],
            [
              -72.267064,
              42.600813
            ],
            [
              -72.254745,
              42.611614
            ],
            [
              -72.227752,
              42.615867
            ],
            [
              -72.224932,
              42.638937
            ],
            [
              -72.22948,
              42.662666
            ],
            [
              -72.271884,
              42.674418
            ],
            [
              -72.28303287565089,
              42.72155870837909
            ],
            [
              -72.285954,
              42.721631
            ],
            [
              -72.326282472606,
              42.7228513823716
            ],
            [
              -72.41203323742229,
              42.725446291500695
            ],
            [
              -72.4511957433251,
              42.726631390482694
            ],
            [
              -72.458519,
              42.726853
            ],
            [
              -72.5167111827822,
              42.72846766982919
            ],
            [
              -72.68617347799702,
              42.733169773139196
            ],
            [
              -72.7910764312897,
              42.7360805358152
            ],
            [
              -72.809113,
              42.736581
            ],
            [
              -72.8827924320332,
              42.7381497767183
            ],
            [
              -72.930260821819,
              42.739160469970095
            ],
            [
              -73.022903,
              42.741133
            ],
            [
              -73.0230149955487,
              42.7411352241426
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "25",
      "COUNTYFP": "023",
      "COUNTYNS": "00606938",
      "AFFGEOID": "0500000US25023",
      "GEOID": "25023",
      "NAME": "Plymouth",
      "LSAD": "06",
      "ALAND": 1706344355,
      "AWATER": 1125276862,
      "County_state": "Plymouth,25"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -71.080483,
              42.095539
            ],
            [
              -71.071197,
              42.056459
            ],
            [
              -71.069595,
              42.049704
            ],
            [
              -71.054718,
              41.985057
            ],
            [
              -71.054184,
              41.982716
            ],
            [
              -71.049485,
              41.963094
            ],
            [
              -70.999773,
              41.929671
            ],
            [
              -70.996438,
              41.923778
            ],
            [
              -70.992232,
              41.915996
            ],
            [
              -70.991978,
              41.915602
            ],
            [
              -70.991529,
              41.913181
            ],
            [
              -70.991427,
              41.912321
            ],
            [
              -70.991025,
              41.910533
            ],
            [
              -70.990984,
              41.910059
            ],
            [
              -70.99115,
              41.909133
            ],
            [
              -70.991455,
              41.90834
            ],
            [
              -70.991926,
              41.907507
            ],
            [
              -70.992307,
              41.907059
            ],
            [
              -70.993152,
              41.906445
            ],
            [
              -70.987256,
              41.905808
            ],
            [
              -70.973717,
              41.860879
            ],
            [
              -71.03657,
              41.816525
            ],
            [
              -71.023157,
              41.806176
            ],
            [
              -71.014591,
              41.799568
            ],
            [
              -71.026288,
              41.778888
            ],
            [
              -70.921782,
              41.791244
            ],
            [
              -70.907184,
              41.763543
            ],
            [
              -70.886439,
              41.760232
            ],
            [
              -70.865003,
              41.700164
            ],
            [
              -70.858212,
              41.681167
            ],
            [
              -70.845242,
              41.644665
            ],
            [
              -70.8431763095007,
              41.628486815378
            ],
            [
              -70.835296,
              41.624532
            ],
            [
              -70.810279,
              41.624873
            ],
            [
              -70.801063,
              41.629513
            ],
            [
              -70.800215,
              41.631753
            ],
            [
              -70.804664,
              41.641157
            ],
            [
              -70.816351,
              41.645995
            ],
            [
              -70.815729,
              41.652796
            ],
            [
              -70.813286,
              41.65567
            ],
            [
              -70.809118,
              41.656437
            ],
            [
              -70.776709,
              41.650756
            ],
            [
              -70.775798,
              41.649145
            ],
            [
              -70.773654,
              41.645033
            ],
            [
              -70.769318,
              41.641145
            ],
            [
              -70.765463,
              41.641575
            ],
            [
              -70.757622,
              41.654265
            ],
            [
              -70.758198,
              41.661225
            ],
            [
              -70.76236,
              41.667735
            ],
            [
              -70.76149717743209,
              41.6766410172446
            ],
            [
              -70.761481,
              41.676808
            ],
            [
              -70.755347,
              41.694326
            ],
            [
              -70.744396,
              41.696967
            ],
            [
              -70.729395,
              41.68814
            ],
            [
              -70.719575,
              41.685002
            ],
            [
              -70.717451,
              41.69398
            ],
            [
              -70.721302,
              41.712968
            ],
            [
              -70.728933,
              41.723433
            ],
            [
              -70.7282203375619,
              41.725979631571796
            ],
            [
              -70.726331,
              41.732731
            ],
            [
              -70.718739,
              41.73574
            ],
            [
              -70.708193,
              41.730959
            ],
            [
              -70.670453,
              41.721912
            ],
            [
              -70.656596,
              41.715401
            ],
            [
              -70.651093,
              41.715715
            ],
            [
              -70.644641,
              41.71898
            ],
            [
              -70.64291432961869,
              41.7184094322972
            ],
            [
              -70.620785,
              41.74753
            ],
            [
              -70.62587,
              41.755199
            ],
            [
              -70.632555,
              41.762904
            ],
            [
              -70.581198,
              41.781625
            ],
            [
              -70.565363,
              41.786669
            ],
            [
              -70.5372893339932,
              41.8108590611294
            ],
            [
              -70.54103,
              41.815754
            ],
            [
              -70.543168,
              41.824446
            ],
            [
              -70.542065,
              41.831263
            ],
            [
              -70.535487,
              41.839381
            ],
            [
              -70.525567,
              41.85873
            ],
            [
              -70.532084,
              41.889568
            ],
            [
              -70.545949,
              41.907158
            ],
            [
              -70.54741,
              41.911934
            ],
            [
              -70.546386,
              41.916751
            ],
            [
              -70.552941,
              41.929641
            ],
            [
              -70.583572,
              41.950007
            ],
            [
              -70.598078,
              41.947772
            ],
            [
              -70.608166,
              41.940701
            ],
            [
              -70.616491,
              41.940204
            ],
            [
              -70.623513,
              41.943273
            ],
            [
              -70.631251,
              41.950475
            ],
            [
              -70.648365,
              41.961672
            ],
            [
              -70.651673,
              41.958701
            ],
            [
              -70.662476,
              41.960592
            ],
            [
              -70.6736891437598,
              41.96873531336019
            ],
            [
              -70.698981,
              41.987103
            ],
            [
              -70.710034,
              41.999544
            ],
            [
              -70.71102082349068,
              42.0032011587613
            ],
            [
              -70.712204,
              42.007586
            ],
            [
              -70.695809,
              42.013346
            ],
            [
              -70.686798,
              42.012764
            ],
            [
              -70.678798,
              42.00551
            ],
            [
              -70.670934,
              42.007786
            ],
            [
              -70.667512,
              42.01232
            ],
            [
              -70.671666,
              42.02139
            ],
            [
              -70.6712483641376,
              42.024238109961495
            ],
            [
              -70.66936,
              42.037116
            ],
            [
              -70.650874,
              42.046247
            ],
            [
              -70.644337,
              42.045895
            ],
            [
              -70.643208,
              42.050821
            ],
            [
              -70.64819,
              42.068441
            ],
            [
              -70.64770377999459,
              42.073002564616296
            ],
            [
              -70.647349,
              42.076331
            ],
            [
              -70.63848,
              42.081579
            ],
            [
              -70.640169,
              42.088633
            ],
            [
              -70.663931,
              42.108336
            ],
            [
              -70.6794649147252,
              42.1262707559227
            ],
            [
              -70.685315,
              42.133025
            ],
            [
              -70.706264,
              42.163137
            ],
            [
              -70.714301,
              42.168783
            ],
            [
              -70.718707,
              42.184853
            ],
            [
              -70.722269,
              42.207959
            ],
            [
              -70.73056,
              42.21094
            ],
            [
              -70.7452686943585,
              42.2205363863133
            ],
            [
              -70.74723,
              42.221816
            ],
            [
              -70.754488,
              42.228673
            ],
            [
              -70.764757,
              42.244062
            ],
            [
              -70.770964,
              42.249197
            ],
            [
              -70.7807224462788,
              42.2517921093342
            ],
            [
              -70.785081,
              42.225276
            ],
            [
              -70.789184,
              42.22287
            ],
            [
              -70.827015,
              42.200786
            ],
            [
              -70.85338,
              42.239607
            ],
            [
              -70.844614,
              42.260512
            ],
            [
              -70.82466072392849,
              42.265934645772305
            ],
            [
              -70.831075,
              42.267424
            ],
            [
              -70.851093,
              42.26827
            ],
            [
              -70.861807,
              42.275965
            ],
            [
              -70.870873,
              42.285668
            ],
            [
              -70.881242,
              42.300663
            ],
            [
              -70.882764,
              42.30886
            ],
            [
              -70.907556,
              42.307889
            ],
            [
              -70.91749,
              42.305686
            ],
            [
              -70.935272,
              42.302101
            ],
            [
              -70.94864,
              42.291637
            ],
            [
              -70.9538625145905,
              42.2847655203842
            ],
            [
              -70.937691,
              42.277499
            ],
            [
              -70.914091,
              42.2888
            ],
            [
              -70.90209,
              42.267499
            ],
            [
              -70.931029,
              42.247121
            ],
            [
              -70.93129,
              42.244299
            ],
            [
              -70.92579,
              42.2368
            ],
            [
              -70.92239,
              42.2293
            ],
            [
              -70.92209,
              42.2253
            ],
            [
              -70.91879,
              42.2263
            ],
            [
              -70.914745,
              42.225115
            ],
            [
              -70.924877,
              42.15758
            ],
            [
              -70.948963,
              42.148156
            ],
            [
              -70.98127,
              42.135511
            ],
            [
              -71.002354,
              42.127224
            ],
            [
              -71.002029,
              42.126506
            ],
            [
              -71.021859,
              42.118745
            ],
            [
              -71.025534,
              42.117293
            ],
            [
              -71.061256,
              42.103194
            ],
            [
              -71.080483,
              42.095539
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "25",
      "COUNTYFP": "003",
      "COUNTYNS": "00606928",
      "AFFGEOID": "0500000US25003",
      "GEOID": "25003",
      "NAME": "Berkshire",
      "LSAD": "06",
      "ALAND": 2400678868,
      "AWATER": 50642922,
      "County_state": "Berkshire,25"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -73.508142,
              42.086257
            ],
            [
              -73.496879,
              42.049675
            ],
            [
              -73.487314,
              42.049638
            ],
            [
              -73.432812,
              42.050587
            ],
            [
              -73.29442,
              42.046984
            ],
            [
              -73.293097,
              42.04694
            ],
            [
              -73.2329591865244,
              42.045006199160504
            ],
            [
              -73.231056,
              42.044945
            ],
            [
              -73.229798,
              42.044877
            ],
            [
              -73.12727749969179,
              42.0419641690369
            ],
            [
              -73.0532880861326,
              42.039861968461295
            ],
            [
              -73.055058,
              42.057998
            ],
            [
              -73.073087,
              42.094213
            ],
            [
              -73.061178,
              42.119245
            ],
            [
              -73.071113,
              42.148206
            ],
            [
              -73.034916,
              42.143682
            ],
            [
              -73.00461,
              42.250089
            ],
            [
              -73.00185,
              42.251017
            ],
            [
              -73.000173,
              42.312678
            ],
            [
              -73.029398,
              42.308568
            ],
            [
              -73.062886,
              42.328951
            ],
            [
              -73.068508,
              42.38072
            ],
            [
              -73.065772,
              42.389111
            ],
            [
              -73.011683,
              42.379683
            ],
            [
              -72.968583,
              42.540012
            ],
            [
              -72.979036,
              42.541617
            ],
            [
              -72.975409,
              42.556037
            ],
            [
              -72.951039,
              42.641006
            ],
            [
              -72.95464,
              42.665044
            ],
            [
              -72.975017,
              42.660938
            ],
            [
              -72.995635,
              42.673342
            ],
            [
              -72.974705,
              42.68866
            ],
            [
              -72.954608,
              42.690106
            ],
            [
              -72.948838,
              42.704263
            ],
            [
              -73.023709,
              42.70269
            ],
            [
              -73.0230149955487,
              42.7411352241426
            ],
            [
              -73.1424934176269,
              42.7435079706162
            ],
            [
              -73.264957,
              42.74594
            ],
            [
              -73.3525249549442,
              42.5100075099506
            ],
            [
              -73.352527,
              42.510002
            ],
            [
              -73.3835056572836,
              42.4256460501838
            ],
            [
              -73.47591804393001,
              42.1740039412646
            ],
            [
              -73.508142,
              42.086257
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "25",
      "COUNTYFP": "005",
      "COUNTYNS": "00606929",
      "AFFGEOID": "0500000US25005",
      "GEOID": "25005",
      "NAME": "Bristol",
      "LSAD": "06",
      "ALAND": 1432461196,
      "AWATER": 357549440,
      "County_state": "Bristol,25"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -70.832044,
              41.606504
            ],
            [
              -70.838452,
              41.59646
            ],
            [
              -70.830087,
              41.585385
            ],
            [
              -70.82191,
              41.582841
            ],
            [
              -70.8210010615625,
              41.587268409808296
            ],
            [
              -70.820918,
              41.587673
            ],
            [
              -70.823735,
              41.598569
            ],
            [
              -70.832044,
              41.606504
            ]
          ]
        ],
        [
          [
            [
              -71.3817,
              41.89375174725279
            ],
            [
              -71.3817,
              41.893199
            ],
            [
              -71.3815292380827,
              41.8932257861831
            ],
            [
              -71.3766,
              41.893999
            ],
            [
              -71.373799,
              41.894399
            ],
            [
              -71.370999,
              41.894599
            ],
            [
              -71.365399,
              41.895299
            ],
            [
              -71.364699,
              41.895399
            ],
            [
              -71.362499,
              41.895599
            ],
            [
              -71.354699,
              41.896499
            ],
            [
              -71.3545188256255,
              41.8965170174374
            ],
            [
              -71.352699,
              41.896699
            ],
            [
              -71.3396650579154,
              41.898281579926
            ],
            [
              -71.3387624937221,
              41.898391169178794
            ],
            [
              -71.338698,
              41.898399
            ],
            [
              -71.339298,
              41.893599
            ],
            [
              -71.339298,
              41.893399
            ],
            [
              -71.340798,
              41.8816
            ],
            [
              -71.3378288661725,
              41.873174138675
            ],
            [
              -71.3374129679819,
              41.871993895317004
            ],
            [
              -71.333997,
              41.8623
            ],
            [
              -71.3344939886823,
              41.8612394827533
            ],
            [
              -71.342198,
              41.8448
            ],
            [
              -71.341797,
              41.8437
            ],
            [
              -71.335197,
              41.8355
            ],
            [
              -71.337597,
              41.8337
            ],
            [
              -71.339597,
              41.832
            ],
            [
              -71.344897,
              41.828
            ],
            [
              -71.347197,
              41.8231
            ],
            [
              -71.339197,
              41.809
            ],
            [
              -71.338897,
              41.8083
            ],
            [
              -71.339297,
              41.8065
            ],
            [
              -71.339297,
              41.8044
            ],
            [
              -71.34017342533939,
              41.8019460090498
            ],
            [
              -71.340797,
              41.8002
            ],
            [
              -71.340697,
              41.7983
            ],
            [
              -71.339297,
              41.7963
            ],
            [
              -71.335797,
              41.7948
            ],
            [
              -71.333896,
              41.7945
            ],
            [
              -71.332196,
              41.7923
            ],
            [
              -71.329296,
              41.7868
            ],
            [
              -71.329396,
              41.7826
            ],
            [
              -71.327896,
              41.780501
            ],
            [
              -71.31792439180039,
              41.7761573631246
            ],
            [
              -71.317795,
              41.776101
            ],
            [
              -71.3163878442731,
              41.775507231826296
            ],
            [
              -71.2844017716869,
              41.762010280821
            ],
            [
              -71.261392,
              41.752301
            ],
            [
              -71.2262389298232,
              41.7122074078117
            ],
            [
              -71.225709,
              41.711603
            ],
            [
              -71.224798,
              41.710498
            ],
            [
              -71.2012176289109,
              41.6818631903586
            ],
            [
              -71.19564,
              41.67509
            ],
            [
              -71.194384,
              41.674803
            ],
            [
              -71.191178,
              41.674216
            ],
            [
              -71.191175,
              41.674292
            ],
            [
              -71.18129,
              41.672502
            ],
            [
              -71.17599,
              41.671402
            ],
            [
              -71.17609,
              41.668502
            ],
            [
              -71.17609,
              41.668102
            ],
            [
              -71.1621482835032,
              41.665578726574005
            ],
            [
              -71.153989,
              41.664102
            ],
            [
              -71.14587,
              41.662795
            ],
            [
              -71.135188,
              41.660502
            ],
            [
              -71.134688,
              41.660502
            ],
            [
              -71.132888,
              41.660102
            ],
            [
              -71.13267,
              41.658744
            ],
            [
              -71.134478,
              41.641262
            ],
            [
              -71.134484,
              41.641198
            ],
            [
              -71.13470117132269,
              41.6388899233839
            ],
            [
              -71.135675749492,
              41.6285321972593
            ],
            [
              -71.135688,
              41.628402
            ],
            [
              -71.140468,
              41.623893
            ],
            [
              -71.141509,
              41.616076
            ],
            [
              -71.14091,
              41.607405
            ],
            [
              -71.140588,
              41.605102
            ],
            [
              -71.137492,
              41.602561
            ],
            [
              -71.131618,
              41.593918
            ],
            [
              -71.131312,
              41.592308
            ],
            [
              -71.1224,
              41.522156
            ],
            [
              -71.12057,
              41.497448
            ],
            [
              -71.085663,
              41.509292
            ],
            [
              -71.058418,
              41.505967
            ],
            [
              -71.041095,
              41.494665
            ],
            [
              -71.035514,
              41.499047
            ],
            [
              -71.0235227155905,
              41.5063263626273
            ],
            [
              -71.019354,
              41.508857
            ],
            [
              -71.003275,
              41.511912
            ],
            [
              -70.981708,
              41.51007
            ],
            [
              -70.983354,
              41.520616
            ],
            [
              -70.979225,
              41.530427
            ],
            [
              -70.961354,
              41.529698
            ],
            [
              -70.953299,
              41.51501
            ],
            [
              -70.947929,
              41.529802
            ],
            [
              -70.941785,
              41.540121
            ],
            [
              -70.931545,
              41.540169
            ],
            [
              -70.93783,
              41.565239
            ],
            [
              -70.9473,
              41.573659
            ],
            [
              -70.948797,
              41.579038
            ],
            [
              -70.946911,
              41.581089
            ],
            [
              -70.941588,
              41.581034
            ],
            [
              -70.937978,
              41.577416
            ],
            [
              -70.931338,
              41.5842
            ],
            [
              -70.927393,
              41.594064
            ],
            [
              -70.93,
              41.600441
            ],
            [
              -70.9297509367256,
              41.6085382441511
            ],
            [
              -70.929722,
              41.609479
            ],
            [
              -70.927172,
              41.611253
            ],
            [
              -70.920074,
              41.61081
            ],
            [
              -70.916581,
              41.607483
            ],
            [
              -70.910814,
              41.595506
            ],
            [
              -70.901381,
              41.592504
            ],
            [
              -70.899981,
              41.593504
            ],
            [
              -70.904522,
              41.610361
            ],
            [
              -70.913202,
              41.619266
            ],
            [
              -70.90576480079979,
              41.6234936146481
            ],
            [
              -70.905071,
              41.623888
            ],
            [
              -70.894566,
              41.62411
            ],
            [
              -70.889209,
              41.632904
            ],
            [
              -70.872665,
              41.627816
            ],
            [
              -70.869624,
              41.625608
            ],
            [
              -70.86836,
              41.622664
            ],
            [
              -70.868904,
              41.614664
            ],
            [
              -70.86885710133309,
              41.614315416876
            ],
            [
              -70.866995,
              41.600475
            ],
            [
              -70.8589018730395,
              41.5895551327991
            ],
            [
              -70.853447,
              41.582195
            ],
            [
              -70.853121,
              41.587321
            ],
            [
              -70.850181,
              41.593529
            ],
            [
              -70.843227,
              41.596565
            ],
            [
              -70.855162,
              41.624145
            ],
            [
              -70.852518,
              41.626919
            ],
            [
              -70.844165,
              41.628983
            ],
            [
              -70.8431763095007,
              41.628486815378
            ],
            [
              -70.845242,
              41.644665
            ],
            [
              -70.858212,
              41.681167
            ],
            [
              -70.865003,
              41.700164
            ],
            [
              -70.886439,
              41.760232
            ],
            [
              -70.907184,
              41.763543
            ],
            [
              -70.921782,
              41.791244
            ],
            [
              -71.026288,
              41.778888
            ],
            [
              -71.014591,
              41.799568
            ],
            [
              -71.023157,
              41.806176
            ],
            [
              -71.03657,
              41.816525
            ],
            [
              -70.973717,
              41.860879
            ],
            [
              -70.987256,
              41.905808
            ],
            [
              -70.993152,
              41.906445
            ],
            [
              -70.992307,
              41.907059
            ],
            [
              -70.991926,
              41.907507
            ],
            [
              -70.991455,
              41.90834
            ],
            [
              -70.99115,
              41.909133
            ],
            [
              -70.990984,
              41.910059
            ],
            [
              -70.991025,
              41.910533
            ],
            [
              -70.991427,
              41.912321
            ],
            [
              -70.991529,
              41.913181
            ],
            [
              -70.991978,
              41.915602
            ],
            [
              -70.992232,
              41.915996
            ],
            [
              -70.996438,
              41.923778
            ],
            [
              -70.999773,
              41.929671
            ],
            [
              -71.049485,
              41.963094
            ],
            [
              -71.054184,
              41.982716
            ],
            [
              -71.054718,
              41.985057
            ],
            [
              -71.069595,
              42.049704
            ],
            [
              -71.071197,
              42.056459
            ],
            [
              -71.080483,
              42.095539
            ],
            [
              -71.112863,
              42.082863
            ],
            [
              -71.138619,
              42.072813
            ],
            [
              -71.168681,
              42.061161
            ],
            [
              -71.178457,
              42.057362
            ],
            [
              -71.192629,
              42.051825
            ],
            [
              -71.21158,
              42.044428
            ],
            [
              -71.288265,
              42.014462
            ],
            [
              -71.364344,
              41.985293
            ],
            [
              -71.3814657108006,
              41.9850843199885
            ],
            [
              -71.381501,
              41.966699
            ],
            [
              -71.381401,
              41.964799
            ],
            [
              -71.3816,
              41.922899
            ],
            [
              -71.3817,
              41.922699
            ],
            [
              -71.3817,
              41.914816
            ],
            [
              -71.3817,
              41.9146596532258
            ],
            [
              -71.3817,
              41.89375174725279
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "25",
      "COUNTYFP": "021",
      "COUNTYNS": "00606937",
      "AFFGEOID": "0500000US25021",
      "GEOID": "25021",
      "NAME": "Norfolk",
      "LSAD": "06",
      "ALAND": 1025865057,
      "AWATER": 124987270,
      "County_state": "Norfolk,25"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -70.844614,
              42.260512
            ],
            [
              -70.85338,
              42.239607
            ],
            [
              -70.827015,
              42.200786
            ],
            [
              -70.789184,
              42.22287
            ],
            [
              -70.785081,
              42.225276
            ],
            [
              -70.7807224462788,
              42.2517921093342
            ],
            [
              -70.788724,
              42.25392
            ],
            [
              -70.811742,
              42.262935
            ],
            [
              -70.82466072392849,
              42.265934645772305
            ],
            [
              -70.844614,
              42.260512
            ]
          ]
        ],
        [
          [
            [
              -71.169223,
              42.321994
            ],
            [
              -71.178935,
              42.314316
            ],
            [
              -71.164702,
              42.30383
            ],
            [
              -71.152148,
              42.294613
            ],
            [
              -71.140135,
              42.302147
            ],
            [
              -71.127177,
              42.318607
            ],
            [
              -71.119539,
              42.322745
            ],
            [
              -71.114547,
              42.328372
            ],
            [
              -71.110808,
              42.335586
            ],
            [
              -71.110597,
              42.340405
            ],
            [
              -71.106894,
              42.348588
            ],
            [
              -71.113784,
              42.35078
            ],
            [
              -71.121311,
              42.351675
            ],
            [
              -71.12878,
              42.349559
            ],
            [
              -71.146215,
              42.338632
            ],
            [
              -71.157036,
              42.33039
            ],
            [
              -71.169223,
              42.321994
            ]
          ]
        ],
        [
          [
            [
              -71.501402,
              42.116276
            ],
            [
              -71.499689,
              42.10349
            ],
            [
              -71.495351,
              42.099812
            ],
            [
              -71.498287,
              42.064878
            ],
            [
              -71.49826,
              42.03159
            ],
            [
              -71.498258301062,
              42.01722023315919
            ],
            [
              -71.4581035191943,
              42.0177623891285
            ],
            [
              -71.42435692338171,
              42.018218023987295
            ],
            [
              -71.381401,
              42.018798
            ],
            [
              -71.3814657108006,
              41.9850843199885
            ],
            [
              -71.364344,
              41.985293
            ],
            [
              -71.288265,
              42.014462
            ],
            [
              -71.21158,
              42.044428
            ],
            [
              -71.192629,
              42.051825
            ],
            [
              -71.178457,
              42.057362
            ],
            [
              -71.168681,
              42.061161
            ],
            [
              -71.138619,
              42.072813
            ],
            [
              -71.112863,
              42.082863
            ],
            [
              -71.080483,
              42.095539
            ],
            [
              -71.061256,
              42.103194
            ],
            [
              -71.025534,
              42.117293
            ],
            [
              -71.021859,
              42.118745
            ],
            [
              -71.002029,
              42.126506
            ],
            [
              -71.002354,
              42.127224
            ],
            [
              -70.98127,
              42.135511
            ],
            [
              -70.948963,
              42.148156
            ],
            [
              -70.924877,
              42.15758
            ],
            [
              -70.914745,
              42.225115
            ],
            [
              -70.91879,
              42.2263
            ],
            [
              -70.92209,
              42.2253
            ],
            [
              -70.92239,
              42.2293
            ],
            [
              -70.92579,
              42.2368
            ],
            [
              -70.93129,
              42.244299
            ],
            [
              -70.931029,
              42.247121
            ],
            [
              -70.90209,
              42.267499
            ],
            [
              -70.914091,
              42.2888
            ],
            [
              -70.937691,
              42.277499
            ],
            [
              -70.9538625145905,
              42.2847655203842
            ],
            [
              -70.957432,
              42.280069
            ],
            [
              -70.956219,
              42.270794
            ],
            [
              -70.967351,
              42.268168
            ],
            [
              -70.98909,
              42.267449
            ],
            [
              -70.996097,
              42.271222
            ],
            [
              -71.0049,
              42.28272
            ],
            [
              -71.0054662371948,
              42.285146087822
            ],
            [
              -71.006158,
              42.28811
            ],
            [
              -71.003561,
              42.295276
            ],
            [
              -70.986667,
              42.306265
            ],
            [
              -70.9917773371543,
              42.313099521268
            ],
            [
              -71.009875,
              42.305221
            ],
            [
              -71.028395,
              42.308799
            ],
            [
              -71.037094,
              42.306598
            ],
            [
              -71.041594,
              42.303692
            ],
            [
              -71.037894,
              42.284898
            ],
            [
              -71.042782,
              42.277102
            ],
            [
              -71.053284,
              42.277502
            ],
            [
              -71.060979,
              42.267454
            ],
            [
              -71.073264,
              42.270317
            ],
            [
              -71.08982,
              42.269539
            ],
            [
              -71.093737,
              42.267107
            ],
            [
              -71.093909,
              42.267112
            ],
            [
              -71.1085,
              42.261061
            ],
            [
              -71.113009,
              42.258689
            ],
            [
              -71.109347,
              42.24799
            ],
            [
              -71.130771,
              42.227926
            ],
            [
              -71.150386,
              42.25746
            ],
            [
              -71.155958,
              42.256485
            ],
            [
              -71.174764,
              42.275842
            ],
            [
              -71.188167,
              42.280412
            ],
            [
              -71.191155,
              42.283059
            ],
            [
              -71.20524,
              42.289568
            ],
            [
              -71.207881,
              42.297157
            ],
            [
              -71.226894,
              42.308452
            ],
            [
              -71.227543,
              42.3154
            ],
            [
              -71.228405,
              42.318781
            ],
            [
              -71.249171,
              42.323568
            ],
            [
              -71.252808,
              42.32671
            ],
            [
              -71.269958,
              42.328086
            ],
            [
              -71.327327,
              42.313746
            ],
            [
              -71.326396,
              42.304402
            ],
            [
              -71.323662,
              42.297415
            ],
            [
              -71.30846,
              42.270709
            ],
            [
              -71.302922,
              42.248274
            ],
            [
              -71.332496,
              42.249075
            ],
            [
              -71.329561,
              42.23367
            ],
            [
              -71.342887,
              42.219956
            ],
            [
              -71.338435,
              42.215232
            ],
            [
              -71.350899,
              42.21264
            ],
            [
              -71.34417,
              42.200703
            ],
            [
              -71.361986,
              42.195761
            ],
            [
              -71.384765,
              42.192014
            ],
            [
              -71.404427,
              42.188433
            ],
            [
              -71.402295,
              42.178794
            ],
            [
              -71.430174,
              42.179386
            ],
            [
              -71.444067,
              42.174889
            ],
            [
              -71.463957,
              42.158239
            ],
            [
              -71.478119,
              42.156782
            ],
            [
              -71.478522,
              42.131378
            ],
            [
              -71.501402,
              42.116276
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "25",
      "COUNTYFP": "019",
      "COUNTYNS": "00606936",
      "AFFGEOID": "0500000US25019",
      "GEOID": "25019",
      "NAME": "Nantucket",
      "LSAD": "06",
      "ALAND": 119431680,
      "AWATER": 667032050,
      "County_state": "Nantucket,25"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -70.275526,
              41.310464
            ],
            [
              -70.2747281610406,
              41.3069828042281
            ],
            [
              -70.273478,
              41.301528
            ],
            [
              -70.266776,
              41.294453
            ],
            [
              -70.256164,
              41.288123
            ],
            [
              -70.237175,
              41.282724
            ],
            [
              -70.170681,
              41.255881
            ],
            [
              -70.118669,
              41.242351
            ],
            [
              -70.1160673730407,
              41.2421710607287
            ],
            [
              -70.096967,
              41.24085
            ],
            [
              -70.083239,
              41.2444
            ],
            [
              -70.052807,
              41.242685
            ],
            [
              -70.015225,
              41.237964
            ],
            [
              -70.001586,
              41.239353
            ],
            [
              -69.975,
              41.247392
            ],
            [
              -69.965725,
              41.252466
            ],
            [
              -69.964422,
              41.25457
            ],
            [
              -69.960181,
              41.264546
            ],
            [
              -69.960277,
              41.278731
            ],
            [
              -69.97576637505219,
              41.3026695124003
            ],
            [
              -70.018446,
              41.36863
            ],
            [
              -70.033514,
              41.385816
            ],
            [
              -70.049053,
              41.391702
            ],
            [
              -70.04906163493739,
              41.391637753362005
            ],
            [
              -70.049564,
              41.3879
            ],
            [
              -70.04759482184811,
              41.3857704362972
            ],
            [
              -70.045586,
              41.383598
            ],
            [
              -70.038458,
              41.376399
            ],
            [
              -70.035162,
              41.372161
            ],
            [
              -70.030924,
              41.367453
            ],
            [
              -70.028805,
              41.359919
            ],
            [
              -70.03086017914649,
              41.3431758369254
            ],
            [
              -70.031332,
              41.339332
            ],
            [
              -70.046088,
              41.321651
            ],
            [
              -70.062565,
              41.308726
            ],
            [
              -70.082072,
              41.299093
            ],
            [
              -70.092142,
              41.297741
            ],
            [
              -70.09251429565859,
              41.297696188127006
            ],
            [
              -70.1034695947471,
              41.2963775386606
            ],
            [
              -70.12446,
              41.293851
            ],
            [
              -70.196304,
              41.294612
            ],
            [
              -70.20515346737629,
              41.2914390236866
            ],
            [
              -70.20869,
              41.290171
            ],
            [
              -70.229541,
              41.290171
            ],
            [
              -70.24001809233859,
              41.2953177284547
            ],
            [
              -70.240153,
              41.295384
            ],
            [
              -70.244435,
              41.303203
            ],
            [
              -70.249276,
              41.305623
            ],
            [
              -70.260632,
              41.310092
            ],
            [
              -70.275526,
              41.310464
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "25",
      "COUNTYFP": "013",
      "COUNTYNS": "00606933",
      "AFFGEOID": "0500000US25013",
      "GEOID": "25013",
      "NAME": "Hampden",
      "LSAD": "06",
      "ALAND": 1598126407,
      "AWATER": 44423491,
      "County_state": "Hampden,25"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -73.061178,
              42.119245
            ],
            [
              -73.073087,
              42.094213
            ],
            [
              -73.055058,
              42.057998
            ],
            [
              -73.0532880861326,
              42.039861968461295
            ],
            [
              -73.053254,
              42.039861
            ],
            [
              -73.0087643066587,
              42.038860282198
            ],
            [
              -72.999549,
              42.038653
            ],
            [
              -72.9789786343673,
              42.0385101754816
            ],
            [
              -72.863733,
              42.03771
            ],
            [
              -72.86363841078709,
              42.0377091702701
            ],
            [
              -72.863619,
              42.037709
            ],
            [
              -72.847142,
              42.036894
            ],
            [
              -72.813541,
              42.036494
            ],
            [
              -72.816741,
              41.997595
            ],
            [
              -72.77475938856001,
              42.002128832682196
            ],
            [
              -72.766739,
              42.002995
            ],
            [
              -72.766139,
              42.007695
            ],
            [
              -72.763265,
              42.009742
            ],
            [
              -72.763238,
              42.012795
            ],
            [
              -72.761238,
              42.014595
            ],
            [
              -72.759738,
              42.016995
            ],
            [
              -72.761354,
              42.018183
            ],
            [
              -72.76231,
              42.019775
            ],
            [
              -72.762151,
              42.021527
            ],
            [
              -72.760558,
              42.021846
            ],
            [
              -72.758151,
              42.020865
            ],
            [
              -72.757467,
              42.020947
            ],
            [
              -72.754038,
              42.025395
            ],
            [
              -72.751738,
              42.030195
            ],
            [
              -72.753538,
              42.032095
            ],
            [
              -72.757538,
              42.033295
            ],
            [
              -72.755838,
              42.036195
            ],
            [
              -72.695927,
              42.036788
            ],
            [
              -72.643134,
              42.032395
            ],
            [
              -72.6079909707697,
              42.0307976349601
            ],
            [
              -72.607933,
              42.030795
            ],
            [
              -72.6069560573249,
              42.0251287324845
            ],
            [
              -72.606933,
              42.024995
            ],
            [
              -72.590233,
              42.024695
            ],
            [
              -72.582332,
              42.024695
            ],
            [
              -72.573231,
              42.030141
            ],
            [
              -72.55285820422401,
              42.032017465491194
            ],
            [
              -72.528131,
              42.034295
            ],
            [
              -72.5092040512358,
              42.0342165913446
            ],
            [
              -72.45668,
              42.033999
            ],
            [
              -72.39743337454189,
              42.0331107167355
            ],
            [
              -72.3669917600491,
              42.0326543063241
            ],
            [
              -72.317148,
              42.031907
            ],
            [
              -72.2706561367986,
              42.031713813847496
            ],
            [
              -72.249523,
              42.031626
            ],
            [
              -72.2050807834552,
              42.031086849809796
            ],
            [
              -72.135715,
              42.0302453396816
            ],
            [
              -72.135011,
              42.161784
            ],
            [
              -72.141886,
              42.161
            ],
            [
              -72.200626,
              42.161287
            ],
            [
              -72.263924,
              42.183831
            ],
            [
              -72.262792,
              42.202534
            ],
            [
              -72.221218,
              42.245252
            ],
            [
              -72.247013,
              42.242094
            ],
            [
              -72.257614,
              42.229874
            ],
            [
              -72.291567,
              42.232221
            ],
            [
              -72.333875,
              42.220719
            ],
            [
              -72.335968,
              42.220159
            ],
            [
              -72.364667,
              42.20726
            ],
            [
              -72.358109,
              42.189816
            ],
            [
              -72.395478,
              42.185737
            ],
            [
              -72.403947,
              42.231847
            ],
            [
              -72.44712,
              42.227254
            ],
            [
              -72.52429,
              42.218734
            ],
            [
              -72.546356,
              42.216489
            ],
            [
              -72.572202,
              42.213804
            ],
            [
              -72.593433,
              42.211691
            ],
            [
              -72.623934,
              42.233691
            ],
            [
              -72.598933,
              42.26809
            ],
            [
              -72.609202,
              42.285477
            ],
            [
              -72.613138,
              42.286265
            ],
            [
              -72.634585,
              42.273943
            ],
            [
              -72.656536,
              42.227739
            ],
            [
              -72.66777,
              42.225456
            ],
            [
              -72.690457,
              42.213052
            ],
            [
              -72.686861,
              42.18339
            ],
            [
              -72.781039,
              42.199749
            ],
            [
              -72.793414,
              42.236854
            ],
            [
              -72.813422,
              42.245004
            ],
            [
              -72.857634,
              42.24048
            ],
            [
              -72.868418,
              42.223748
            ],
            [
              -72.87218,
              42.216439
            ],
            [
              -72.912302,
              42.239133
            ],
            [
              -72.880602,
              42.265382
            ],
            [
              -72.885184,
              42.332615
            ],
            [
              -72.895638,
              42.340702
            ],
            [
              -72.953825,
              42.34386
            ],
            [
              -73.000173,
              42.312678
            ],
            [
              -73.00185,
              42.251017
            ],
            [
              -73.00461,
              42.250089
            ],
            [
              -73.034916,
              42.143682
            ],
            [
              -73.071113,
              42.148206
            ],
            [
              -73.061178,
              42.119245
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "25",
      "COUNTYFP": "009",
      "COUNTYNS": "00606931",
      "AFFGEOID": "0500000US25009",
      "GEOID": "25009",
      "NAME": "Essex",
      "LSAD": "06",
      "ALAND": 1275355437,
      "AWATER": 870391710,
      "County_state": "Essex,25"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -71.242227,
              42.662287
            ],
            [
              -71.255749,
              42.656929
            ],
            [
              -71.171758,
              42.616545
            ],
            [
              -71.182023,
              42.608431
            ],
            [
              -71.164974,
              42.598082
            ],
            [
              -71.156564,
              42.614977
            ],
            [
              -71.145942,
              42.608335
            ],
            [
              -71.135456,
              42.599116
            ],
            [
              -71.076167,
              42.604136
            ],
            [
              -71.058766,
              42.609077
            ],
            [
              -71.028312,
              42.574082
            ],
            [
              -71.03546,
              42.570259
            ],
            [
              -71.05704,
              42.574019
            ],
            [
              -71.070978,
              42.555889
            ],
            [
              -71.07515,
              42.531035
            ],
            [
              -71.038905,
              42.524582
            ],
            [
              -71.035839,
              42.505192
            ],
            [
              -71.043774,
              42.501199
            ],
            [
              -71.052629,
              42.486057
            ],
            [
              -71.053365,
              42.475925
            ],
            [
              -71.025836,
              42.444709
            ],
            [
              -71.009671,
              42.441047
            ],
            [
              -71.010835,
              42.434682
            ],
            [
              -70.982294,
              42.431296
            ],
            [
              -70.968594,
              42.444396
            ],
            [
              -70.9606474235863,
              42.4437870656677
            ],
            [
              -70.96047,
              42.446166
            ],
            [
              -70.951847,
              42.452622
            ],
            [
              -70.943612,
              42.452092
            ],
            [
              -70.943295,
              42.436248
            ],
            [
              -70.936393,
              42.418097
            ],
            [
              -70.905692,
              42.416197
            ],
            [
              -70.901992,
              42.420297
            ],
            [
              -70.908392,
              42.425197
            ],
            [
              -70.913192,
              42.427697
            ],
            [
              -70.927189,
              42.434706
            ],
            [
              -70.9275348715142,
              42.45199033486669
            ],
            [
              -70.927713,
              42.460892
            ],
            [
              -70.9273901235604,
              42.4612196179817
            ],
            [
              -70.921993,
              42.466696
            ],
            [
              -70.917693,
              42.467996
            ],
            [
              -70.908092,
              42.466896
            ],
            [
              -70.894292,
              42.460896
            ],
            [
              -70.887292,
              42.464896
            ],
            [
              -70.887992,
              42.467096
            ],
            [
              -70.886493,
              42.470197
            ],
            [
              -70.879692,
              42.478796
            ],
            [
              -70.857791,
              42.490296
            ],
            [
              -70.847391,
              42.491496
            ],
            [
              -70.841591,
              42.487596
            ],
            [
              -70.835991,
              42.490496
            ],
            [
              -70.831091,
              42.503596
            ],
            [
              -70.842091,
              42.519495
            ],
            [
              -70.857125,
              42.521492
            ],
            [
              -70.859751,
              42.520441
            ],
            [
              -70.866279,
              42.522617
            ],
            [
              -70.8664061180024,
              42.523042295258094
            ],
            [
              -70.8714771570143,
              42.54000833315
            ],
            [
              -70.872357,
              42.542952
            ],
            [
              -70.871382,
              42.546404
            ],
            [
              -70.848492,
              42.550195
            ],
            [
              -70.823291,
              42.551495
            ],
            [
              -70.815391,
              42.554195
            ],
            [
              -70.804091,
              42.561595
            ],
            [
              -70.7936634681151,
              42.563568763726295
            ],
            [
              -70.757283,
              42.570455
            ],
            [
              -70.737044,
              42.576863
            ],
            [
              -70.729688,
              42.57151
            ],
            [
              -70.71606354381339,
              42.5740860967971
            ],
            [
              -70.698574,
              42.577393
            ],
            [
              -70.684502,
              42.588858
            ],
            [
              -70.681428,
              42.593173
            ],
            [
              -70.678819,
              42.594389
            ],
            [
              -70.675747,
              42.594669
            ],
            [
              -70.672583,
              42.594296
            ],
            [
              -70.670442,
              42.592249
            ],
            [
              -70.668488,
              42.589643
            ],
            [
              -70.668115,
              42.585361
            ],
            [
              -70.668022,
              42.581732
            ],
            [
              -70.664887,
              42.580436
            ],
            [
              -70.654727,
              42.582234
            ],
            [
              -70.635635,
              42.600243
            ],
            [
              -70.61842,
              42.62864
            ],
            [
              -70.605611,
              42.634898
            ],
            [
              -70.594014,
              42.63503
            ],
            [
              -70.591469,
              42.639821
            ],
            [
              -70.591742,
              42.648508
            ],
            [
              -70.5919715339961,
              42.6492354726974
            ],
            [
              -70.5954619592649,
              42.660297838742004
            ],
            [
              -70.595474,
              42.660336
            ],
            [
              -70.61482,
              42.65765
            ],
            [
              -70.622791,
              42.660873
            ],
            [
              -70.623815,
              42.665481
            ],
            [
              -70.622864,
              42.67599
            ],
            [
              -70.620031,
              42.688006
            ],
            [
              -70.630077,
              42.692699
            ],
            [
              -70.645101,
              42.689423
            ],
            [
              -70.663548,
              42.677603
            ],
            [
              -70.681594,
              42.662342
            ],
            [
              -70.682594,
              42.654525
            ],
            [
              -70.689402,
              42.653319
            ],
            [
              -70.726250207424,
              42.6631824326492
            ],
            [
              -70.728845,
              42.663877
            ],
            [
              -70.72982,
              42.669602
            ],
            [
              -70.744427,
              42.682092
            ],
            [
              -70.748752,
              42.683878
            ],
            [
              -70.764421,
              42.68565
            ],
            [
              -70.778671,
              42.693622
            ],
            [
              -70.778552,
              42.69852
            ],
            [
              -70.770453,
              42.704824
            ],
            [
              -70.772267,
              42.711064
            ],
            [
              -70.792867,
              42.747118
            ],
            [
              -70.7950242003618,
              42.7531741570913
            ],
            [
              -70.80522,
              42.781798
            ],
            [
              -70.81045485766029,
              42.8105916000238
            ],
            [
              -70.817731,
              42.850613
            ],
            [
              -70.817296,
              42.87229
            ],
            [
              -70.821769,
              42.87188
            ],
            [
              -70.830795,
              42.868918
            ],
            [
              -70.837376,
              42.864996
            ],
            [
              -70.848625,
              42.860939
            ],
            [
              -70.886136,
              42.88261
            ],
            [
              -70.902768,
              42.88653
            ],
            [
              -70.9145252357167,
              42.8865629877879
            ],
            [
              -70.914886,
              42.886564
            ],
            [
              -70.914899,
              42.886589
            ],
            [
              -70.927629,
              42.885326
            ],
            [
              -70.930799,
              42.884589
            ],
            [
              -70.931699,
              42.884189
            ],
            [
              -70.949199,
              42.876089
            ],
            [
              -70.9665,
              42.868989
            ],
            [
              -70.99947469686201,
              42.8639434906735
            ],
            [
              -71.0046387450972,
              42.8631533316724
            ],
            [
              -71.031201,
              42.859089
            ],
            [
              -71.044401,
              42.848789
            ],
            [
              -71.047501,
              42.844089
            ],
            [
              -71.0535652698312,
              42.8331534314519
            ],
            [
              -71.053601,
              42.833089
            ],
            [
              -71.064201,
              42.806289
            ],
            [
              -71.1157535303384,
              42.817686077803096
            ],
            [
              -71.132503,
              42.821389
            ],
            [
              -71.149703,
              42.815489
            ],
            [
              -71.165603,
              42.808689
            ],
            [
              -71.16627183278689,
              42.808274960655694
            ],
            [
              -71.167703,
              42.807389
            ],
            [
              -71.174403,
              42.801589
            ],
            [
              -71.18227871033069,
              42.7942524268349
            ],
            [
              -71.186104,
              42.790689
            ],
            [
              -71.181803,
              42.73759
            ],
            [
              -71.208137,
              42.743273
            ],
            [
              -71.208227,
              42.743294
            ],
            [
              -71.208302,
              42.743314
            ],
            [
              -71.223904,
              42.746689
            ],
            [
              -71.233404,
              42.745489
            ],
            [
              -71.245504,
              42.742589
            ],
            [
              -71.24553925939401,
              42.742567357762304
            ],
            [
              -71.2551987703894,
              42.736638343984396
            ],
            [
              -71.238103,
              42.669491
            ],
            [
              -71.242227,
              42.662287
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "25",
      "COUNTYFP": "027",
      "COUNTYNS": "00606940",
      "AFFGEOID": "0500000US25027",
      "GEOID": "25027",
      "NAME": "Worcester",
      "LSAD": "06",
      "ALAND": 3912560126,
      "AWATER": 177461132,
      "County_state": "Worcester,25"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -72.315994,
              42.394544
            ],
            [
              -72.310236,
              42.38753
            ],
            [
              -72.314599,
              42.343526
            ],
            [
              -72.308401,
              42.34408
            ],
            [
              -72.288734,
              42.352024
            ],
            [
              -72.274639,
              42.301963
            ],
            [
              -72.244543,
              42.306033
            ],
            [
              -72.210795,
              42.31138
            ],
            [
              -72.216745,
              42.294056
            ],
            [
              -72.203281,
              42.291182
            ],
            [
              -72.212043,
              42.282652
            ],
            [
              -72.212779,
              42.269805
            ],
            [
              -72.217818,
              42.27018
            ],
            [
              -72.216514,
              42.259648
            ],
            [
              -72.211079,
              42.251262
            ],
            [
              -72.221218,
              42.245252
            ],
            [
              -72.262792,
              42.202534
            ],
            [
              -72.263924,
              42.183831
            ],
            [
              -72.200626,
              42.161287
            ],
            [
              -72.141886,
              42.161
            ],
            [
              -72.135011,
              42.161784
            ],
            [
              -72.135715,
              42.0302453396816
            ],
            [
              -72.135687,
              42.030245
            ],
            [
              -72.1021687771907,
              42.028899460948004
            ],
            [
              -72.0997252597077,
              42.0288013695839
            ],
            [
              -72.063496,
              42.027347
            ],
            [
              -71.987326,
              42.02688
            ],
            [
              -71.96301930863551,
              42.0262475718444
            ],
            [
              -71.89078,
              42.024368
            ],
            [
              -71.883947767419,
              42.0243074324439
            ],
            [
              -71.80065,
              42.023569
            ],
            [
              -71.799242,
              42.008065
            ],
            [
              -71.76601,
              42.009745
            ],
            [
              -71.6808758163105,
              42.011704731264594
            ],
            [
              -71.6453334734301,
              42.0125228919322
            ],
            [
              -71.60624200979849,
              42.0134227509563
            ],
            [
              -71.58686389633739,
              42.013868822007396
            ],
            [
              -71.576908,
              42.014098
            ],
            [
              -71.559439,
              42.014342
            ],
            [
              -71.5291172465257,
              42.0149668569183
            ],
            [
              -71.527606,
              42.014998
            ],
            [
              -71.527306,
              42.015098
            ],
            [
              -71.5020555032736,
              42.0170108439625
            ],
            [
              -71.500905,
              42.017098
            ],
            [
              -71.499905,
              42.017198
            ],
            [
              -71.498258301062,
              42.01722023315919
            ],
            [
              -71.49826,
              42.03159
            ],
            [
              -71.498287,
              42.064878
            ],
            [
              -71.495351,
              42.099812
            ],
            [
              -71.499689,
              42.10349
            ],
            [
              -71.501402,
              42.116276
            ],
            [
              -71.478522,
              42.131378
            ],
            [
              -71.478119,
              42.156782
            ],
            [
              -71.497378,
              42.166643
            ],
            [
              -71.502626,
              42.191416
            ],
            [
              -71.539498,
              42.189734
            ],
            [
              -71.555738,
              42.182503
            ],
            [
              -71.58291,
              42.195559
            ],
            [
              -71.602221,
              42.218101
            ],
            [
              -71.599318,
              42.225959
            ],
            [
              -71.589855,
              42.251265
            ],
            [
              -71.577968,
              42.262444
            ],
            [
              -71.558533,
              42.267819
            ],
            [
              -71.549453,
              42.266095
            ],
            [
              -71.540003,
              42.262826
            ],
            [
              -71.522178,
              42.266454
            ],
            [
              -71.506478,
              42.264276
            ],
            [
              -71.497046,
              42.289829
            ],
            [
              -71.485948,
              42.311228
            ],
            [
              -71.486797,
              42.330189
            ],
            [
              -71.505391,
              42.328227
            ],
            [
              -71.511238,
              42.328805
            ],
            [
              -71.551126,
              42.326395
            ],
            [
              -71.585168,
              42.310977
            ],
            [
              -71.604646,
              42.336835
            ],
            [
              -71.61826,
              42.342589
            ],
            [
              -71.624702,
              42.350465
            ],
            [
              -71.610007,
              42.357403
            ],
            [
              -71.603065,
              42.367267
            ],
            [
              -71.58015,
              42.386998
            ],
            [
              -71.604251,
              42.397703
            ],
            [
              -71.5591,
              42.411943
            ],
            [
              -71.548903,
              42.447308
            ],
            [
              -71.543394,
              42.466452
            ],
            [
              -71.560367,
              42.47435
            ],
            [
              -71.529841,
              42.519841
            ],
            [
              -71.532417,
              42.523538
            ],
            [
              -71.53878,
              42.54303
            ],
            [
              -71.580287,
              42.550467
            ],
            [
              -71.593189,
              42.543834
            ],
            [
              -71.607852,
              42.54538
            ],
            [
              -71.619927,
              42.552502
            ],
            [
              -71.639206,
              42.530957
            ],
            [
              -71.635869,
              42.524024
            ],
            [
              -71.678969,
              42.53043
            ],
            [
              -71.664601,
              42.611598
            ],
            [
              -71.775755,
              42.64423
            ],
            [
              -71.776982,
              42.636695
            ],
            [
              -71.844843,
              42.637985
            ],
            [
              -71.858483,
              42.633815
            ],
            [
              -71.857757,
              42.674982
            ],
            [
              -71.897103,
              42.703728
            ],
            [
              -71.89877002948,
              42.7114218822193
            ],
            [
              -71.928811,
              42.712234
            ],
            [
              -71.9290194043648,
              42.7122382005025
            ],
            [
              -71.981402,
              42.713294
            ],
            [
              -72.0786269963685,
              42.716243546786195
            ],
            [
              -72.0813685534858,
              42.71632671830879
            ],
            [
              -72.1110583499481,
              42.7172274274578
            ],
            [
              -72.124526,
              42.717636
            ],
            [
              -72.2035695536582,
              42.7195921600024
            ],
            [
              -72.28303287565089,
              42.72155870837909
            ],
            [
              -72.271884,
              42.674418
            ],
            [
              -72.22948,
              42.662666
            ],
            [
              -72.224932,
              42.638937
            ],
            [
              -72.227752,
              42.615867
            ],
            [
              -72.254745,
              42.611614
            ],
            [
              -72.267064,
              42.600813
            ],
            [
              -72.261333,
              42.575922
            ],
            [
              -72.276434,
              42.577374
            ],
            [
              -72.269111,
              42.533425
            ],
            [
              -72.244868,
              42.513439
            ],
            [
              -72.291288,
              42.479525
            ],
            [
              -72.283408,
              42.441196
            ],
            [
              -72.306284,
              42.420114
            ],
            [
              -72.315994,
              42.394544
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "25",
      "COUNTYFP": "015",
      "COUNTYNS": "00606934",
      "AFFGEOID": "0500000US25015",
      "GEOID": "25015",
      "NAME": "Hampshire",
      "LSAD": "06",
      "ALAND": 1365526500,
      "AWATER": 46525649,
      "County_state": "Hampshire,25"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -73.065772,
              42.389111
            ],
            [
              -73.068508,
              42.38072
            ],
            [
              -73.062886,
              42.328951
            ],
            [
              -73.029398,
              42.308568
            ],
            [
              -73.000173,
              42.312678
            ],
            [
              -72.953825,
              42.34386
            ],
            [
              -72.895638,
              42.340702
            ],
            [
              -72.885184,
              42.332615
            ],
            [
              -72.880602,
              42.265382
            ],
            [
              -72.912302,
              42.239133
            ],
            [
              -72.87218,
              42.216439
            ],
            [
              -72.868418,
              42.223748
            ],
            [
              -72.857634,
              42.24048
            ],
            [
              -72.813422,
              42.245004
            ],
            [
              -72.793414,
              42.236854
            ],
            [
              -72.781039,
              42.199749
            ],
            [
              -72.686861,
              42.18339
            ],
            [
              -72.690457,
              42.213052
            ],
            [
              -72.66777,
              42.225456
            ],
            [
              -72.656536,
              42.227739
            ],
            [
              -72.634585,
              42.273943
            ],
            [
              -72.613138,
              42.286265
            ],
            [
              -72.609202,
              42.285477
            ],
            [
              -72.598933,
              42.26809
            ],
            [
              -72.623934,
              42.233691
            ],
            [
              -72.593433,
              42.211691
            ],
            [
              -72.572202,
              42.213804
            ],
            [
              -72.546356,
              42.216489
            ],
            [
              -72.52429,
              42.218734
            ],
            [
              -72.44712,
              42.227254
            ],
            [
              -72.403947,
              42.231847
            ],
            [
              -72.395478,
              42.185737
            ],
            [
              -72.358109,
              42.189816
            ],
            [
              -72.364667,
              42.20726
            ],
            [
              -72.335968,
              42.220159
            ],
            [
              -72.333875,
              42.220719
            ],
            [
              -72.291567,
              42.232221
            ],
            [
              -72.257614,
              42.229874
            ],
            [
              -72.247013,
              42.242094
            ],
            [
              -72.221218,
              42.245252
            ],
            [
              -72.211079,
              42.251262
            ],
            [
              -72.216514,
              42.259648
            ],
            [
              -72.217818,
              42.27018
            ],
            [
              -72.212779,
              42.269805
            ],
            [
              -72.212043,
              42.282652
            ],
            [
              -72.203281,
              42.291182
            ],
            [
              -72.216745,
              42.294056
            ],
            [
              -72.210795,
              42.31138
            ],
            [
              -72.244543,
              42.306033
            ],
            [
              -72.274639,
              42.301963
            ],
            [
              -72.288734,
              42.352024
            ],
            [
              -72.308401,
              42.34408
            ],
            [
              -72.314599,
              42.343526
            ],
            [
              -72.327344,
              42.3198
            ],
            [
              -72.350373,
              42.304168
            ],
            [
              -72.355942,
              42.303401
            ],
            [
              -72.351157,
              42.334549
            ],
            [
              -72.355396,
              42.340678
            ],
            [
              -72.356443,
              42.366156
            ],
            [
              -72.366163,
              42.378208
            ],
            [
              -72.375022,
              42.420819
            ],
            [
              -72.483696,
              42.407481
            ],
            [
              -72.489891,
              42.433816
            ],
            [
              -72.544199,
              42.42715
            ],
            [
              -72.581143,
              42.422581
            ],
            [
              -72.589251,
              42.422341
            ],
            [
              -72.669138,
              42.409711
            ],
            [
              -72.704392,
              42.40555
            ],
            [
              -72.700877,
              42.45292
            ],
            [
              -72.757758,
              42.445883
            ],
            [
              -72.773329,
              42.466782
            ],
            [
              -72.871136,
              42.484041
            ],
            [
              -72.876849,
              42.541197
            ],
            [
              -72.975409,
              42.556037
            ],
            [
              -72.979036,
              42.541617
            ],
            [
              -72.968583,
              42.540012
            ],
            [
              -73.011683,
              42.379683
            ],
            [
              -73.065772,
              42.389111
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "25",
      "COUNTYFP": "007",
      "COUNTYNS": "00606930",
      "AFFGEOID": "0500000US25007",
      "GEOID": "25007",
      "NAME": "Dukes",
      "LSAD": "06",
      "ALAND": 267309256,
      "AWATER": 1004275270,
      "County_state": "Dukes,25"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -70.826503,
              41.259594
            ],
            [
              -70.832333,
              41.258362
            ],
            [
              -70.821284,
              41.251014
            ],
            [
              -70.812222,
              41.249408
            ],
            [
              -70.804236,
              41.249458
            ],
            [
              -70.801561,
              41.25841
            ],
            [
              -70.80648,
              41.262075
            ],
            [
              -70.811965,
              41.264839
            ],
            [
              -70.817263,
              41.261639
            ],
            [
              -70.826503,
              41.259594
            ]
          ]
        ],
        [
          [
            [
              -70.833802,
              41.353386
            ],
            [
              -70.838777,
              41.347209
            ],
            [
              -70.819415,
              41.327212
            ],
            [
              -70.802083,
              41.314207
            ],
            [
              -70.775665,
              41.300982
            ],
            [
              -70.768687,
              41.303702
            ],
            [
              -70.766166,
              41.308962
            ],
            [
              -70.768015,
              41.311959
            ],
            [
              -70.764188,
              41.318706
            ],
            [
              -70.747541,
              41.329952
            ],
            [
              -70.733253,
              41.336226
            ],
            [
              -70.709826,
              41.341723
            ],
            [
              -70.599157,
              41.349272
            ],
            [
              -70.538294,
              41.348958
            ],
            [
              -70.496162,
              41.346452
            ],
            [
              -70.451084,
              41.348161
            ],
            [
              -70.448262,
              41.353651
            ],
            [
              -70.449268,
              41.380422
            ],
            [
              -70.446233,
              41.39648
            ],
            [
              -70.450431,
              41.420703
            ],
            [
              -70.463833,
              41.419145
            ],
            [
              -70.470788,
              41.412875
            ],
            [
              -70.473035,
              41.408757
            ],
            [
              -70.472604,
              41.399128
            ],
            [
              -70.484503,
              41.38629
            ],
            [
              -70.490758,
              41.383634
            ],
            [
              -70.498959,
              41.384339
            ],
            [
              -70.501306,
              41.385391
            ],
            [
              -70.502372,
              41.392005
            ],
            [
              -70.506984,
              41.400242
            ],
            [
              -70.517584,
              41.403769
            ],
            [
              -70.528581,
              41.4051
            ],
            [
              -70.538301,
              41.409241
            ],
            [
              -70.547567,
              41.415831
            ],
            [
              -70.553096,
              41.423952
            ],
            [
              -70.555588,
              41.430882
            ],
            [
              -70.552943,
              41.443394
            ],
            [
              -70.553277,
              41.452955
            ],
            [
              -70.56328,
              41.469127
            ],
            [
              -70.567356,
              41.471208
            ],
            [
              -70.57485,
              41.468259
            ],
            [
              -70.59628,
              41.471905
            ],
            [
              -70.598444,
              41.481151
            ],
            [
              -70.603555,
              41.482384
            ],
            [
              -70.64933,
              41.461068
            ],
            [
              -70.686881,
              41.441334
            ],
            [
              -70.701378,
              41.430925
            ],
            [
              -70.711493,
              41.41546
            ],
            [
              -70.712432,
              41.40885
            ],
            [
              -70.724366,
              41.398942
            ],
            [
              -70.729225,
              41.397728
            ],
            [
              -70.768901,
              41.353246
            ],
            [
              -70.774974,
              41.349176
            ],
            [
              -70.783291,
              41.347829
            ],
            [
              -70.800289,
              41.3538
            ],
            [
              -70.812309,
              41.355745
            ],
            [
              -70.833802,
              41.353386
            ]
          ]
        ],
        [
          [
            [
              -70.949861,
              41.415323
            ],
            [
              -70.951045,
              41.411777
            ],
            [
              -70.948431,
              41.409193
            ],
            [
              -70.937282,
              41.411618
            ],
            [
              -70.928197,
              41.415781
            ],
            [
              -70.902763,
              41.421061
            ],
            [
              -70.866946,
              41.422378
            ],
            [
              -70.857528,
              41.425767
            ],
            [
              -70.835867,
              41.441877
            ],
            [
              -70.817478,
              41.445562
            ],
            [
              -70.79027,
              41.446339
            ],
            [
              -70.760863,
              41.460947
            ],
            [
              -70.756481,
              41.465977
            ],
            [
              -70.757171,
              41.469917
            ],
            [
              -70.734306,
              41.486335
            ],
            [
              -70.705181,
              41.496677
            ],
            [
              -70.675379,
              41.512623
            ],
            [
              -70.6695181559949,
              41.51333913888389
            ],
            [
              -70.672253,
              41.515774
            ],
            [
              -70.674646,
              41.519385
            ],
            [
              -70.681397,
              41.51755
            ],
            [
              -70.688353,
              41.516789
            ],
            [
              -70.691364,
              41.517842
            ],
            [
              -70.6947997905558,
              41.5256400617567
            ],
            [
              -70.745053,
              41.500966
            ],
            [
              -70.753905,
              41.492256
            ],
            [
              -70.775268,
              41.477465
            ],
            [
              -70.787769,
              41.474609
            ],
            [
              -70.802186,
              41.460864
            ],
            [
              -70.828546,
              41.456448
            ],
            [
              -70.855265,
              41.448892
            ],
            [
              -70.8803,
              41.437969
            ],
            [
              -70.901011,
              41.434453
            ],
            [
              -70.923698,
              41.430716
            ],
            [
              -70.928165,
              41.431265
            ],
            [
              -70.949861,
              41.415323
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "25",
      "COUNTYFP": "001",
      "COUNTYNS": "00606927",
      "AFFGEOID": "0500000US25001",
      "GEOID": "25001",
      "NAME": "Barnstable",
      "LSAD": "06",
      "ALAND": 1020989901,
      "AWATER": 2360346063,
      "County_state": "Barnstable,25"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -70.6947997905558,
              41.5256400617567
            ],
            [
              -70.691364,
              41.517842
            ],
            [
              -70.688353,
              41.516789
            ],
            [
              -70.681397,
              41.51755
            ],
            [
              -70.674646,
              41.519385
            ],
            [
              -70.672253,
              41.515774
            ],
            [
              -70.6695181559949,
              41.51333913888389
            ],
            [
              -70.663856,
              41.514031
            ],
            [
              -70.654104,
              41.519025
            ],
            [
              -70.643627,
              41.532357
            ],
            [
              -70.633607,
              41.538254
            ],
            [
              -70.611081,
              41.542989
            ],
            [
              -70.5888103294529,
              41.545303516877894
            ],
            [
              -70.559689,
              41.54833
            ],
            [
              -70.5594821312779,
              41.5483335159156
            ],
            [
              -70.5585618343495,
              41.548349157170094
            ],
            [
              -70.53248647352379,
              41.5487923308258
            ],
            [
              -70.5316401788343,
              41.5488067143472
            ],
            [
              -70.5277851527204,
              41.54887223390129
            ],
            [
              -70.522327,
              41.548965
            ],
            [
              -70.493244,
              41.552044
            ],
            [
              -70.485571,
              41.554244
            ],
            [
              -70.476256,
              41.558502
            ],
            [
              -70.461278,
              41.57182
            ],
            [
              -70.4503824729967,
              41.5854453713447
            ],
            [
              -70.445289,
              41.591815
            ],
            [
              -70.437246,
              41.605329
            ],
            [
              -70.408535,
              41.607345
            ],
            [
              -70.400581,
              41.606382
            ],
            [
              -70.379151,
              41.611361
            ],
            [
              -70.369854,
              41.615888
            ],
            [
              -70.364744,
              41.623671
            ],
            [
              -70.364892,
              41.626721
            ],
            [
              -70.360352,
              41.631069
            ],
            [
              -70.351634,
              41.634687
            ],
            [
              -70.338067,
              41.636338
            ],
            [
              -70.329924,
              41.634578
            ],
            [
              -70.321588,
              41.630508
            ],
            [
              -70.311687,
              41.627408
            ],
            [
              -70.300653,
              41.62951
            ],
            [
              -70.29062,
              41.635196
            ],
            [
              -70.28132,
              41.635125
            ],
            [
              -70.274522,
              41.632927
            ],
            [
              -70.26913,
              41.625742
            ],
            [
              -70.269687,
              41.617775
            ],
            [
              -70.267587,
              41.610912
            ],
            [
              -70.265424,
              41.609333
            ],
            [
              -70.259601,
              41.610863
            ],
            [
              -70.25542,
              41.617541
            ],
            [
              -70.25621,
              41.620698
            ],
            [
              -70.245867,
              41.628479
            ],
            [
              -70.2354437800992,
              41.6316702861719
            ],
            [
              -70.191061,
              41.645259
            ],
            [
              -70.158621,
              41.650438
            ],
            [
              -70.140877,
              41.650423
            ],
            [
              -70.1177579189868,
              41.65597007515159
            ],
            [
              -70.089238,
              41.662813
            ],
            [
              -70.055523,
              41.664843
            ],
            [
              -70.029346,
              41.667744
            ],
            [
              -70.014211,
              41.671971
            ],
            [
              -70.007011,
              41.671579
            ],
            [
              -69.996359,
              41.667184
            ],
            [
              -69.975719,
              41.653738
            ],
            [
              -69.973153,
              41.646963
            ],
            [
              -69.977478,
              41.630786
            ],
            [
              -69.98556,
              41.619668
            ],
            [
              -69.992215,
              41.603319
            ],
            [
              -69.996805,
              41.570181
            ],
            [
              -70.008615,
              41.557982
            ],
            [
              -70.013606,
              41.551046
            ],
            [
              -70.011229,
              41.543931
            ],
            [
              -70.003062,
              41.537759
            ],
            [
              -69.990956,
              41.543423
            ],
            [
              -69.988215,
              41.554704
            ],
            [
              -69.984146,
              41.580788
            ],
            [
              -69.967192,
              41.611956
            ],
            [
              -69.957775,
              41.620364
            ],
            [
              -69.951169,
              41.640799
            ],
            [
              -69.947599,
              41.645394
            ],
            [
              -69.939464,
              41.670699
            ],
            [
              -69.928261,
              41.6917
            ],
            [
              -69.928652,
              41.74125
            ],
            [
              -69.935952,
              41.809422
            ],
            [
              -69.945314,
              41.845222
            ],
            [
              -69.968598,
              41.9117
            ],
            [
              -69.986085,
              41.949597
            ],
            [
              -70.011898,
              41.98972
            ],
            [
              -70.033501,
              42.017736
            ],
            [
              -70.058531,
              42.040363
            ],
            [
              -70.082624,
              42.054657
            ],
            [
              -70.115968,
              42.067638
            ],
            [
              -70.160166,
              42.078628
            ],
            [
              -70.189305,
              42.082337
            ],
            [
              -70.206899,
              42.0819
            ],
            [
              -70.225626,
              42.078601
            ],
            [
              -70.238087,
              42.072878
            ],
            [
              -70.245385,
              42.063733
            ],
            [
              -70.24354,
              42.060569
            ],
            [
              -70.238875,
              42.060479
            ],
            [
              -70.233256,
              42.057714
            ],
            [
              -70.218701,
              42.045848
            ],
            [
              -70.208016,
              42.03073
            ],
            [
              -70.196693,
              42.022429
            ],
            [
              -70.190834,
              42.020028
            ],
            [
              -70.186708,
              42.019904
            ],
            [
              -70.186295,
              42.021308
            ],
            [
              -70.193074,
              42.027576
            ],
            [
              -70.195345,
              42.034163
            ],
            [
              -70.194456,
              42.03947
            ],
            [
              -70.186816,
              42.05045
            ],
            [
              -70.178468,
              42.05642
            ],
            [
              -70.169781,
              42.059736
            ],
            [
              -70.155415,
              42.062409
            ],
            [
              -70.148294,
              42.06195
            ],
            [
              -70.123043,
              42.051668
            ],
            [
              -70.10806,
              42.043601
            ],
            [
              -70.095595,
              42.032832
            ],
            [
              -70.089578,
              42.024896
            ],
            [
              -70.083775,
              42.012041
            ],
            [
              -70.077421,
              41.985497
            ],
            [
              -70.074006,
              41.93865
            ],
            [
              -70.077212,
              41.902026
            ],
            [
              -70.06901,
              41.884924
            ],
            [
              -70.063592,
              41.892452
            ],
            [
              -70.061322,
              41.90991
            ],
            [
              -70.054203,
              41.920949
            ],
            [
              -70.054464,
              41.927366
            ],
            [
              -70.044995,
              41.930049
            ],
            [
              -70.030537,
              41.929154
            ],
            [
              -70.025553,
              41.911699
            ],
            [
              -70.024335,
              41.89882
            ],
            [
              -70.012154,
              41.891656
            ],
            [
              -70.002922,
              41.890315
            ],
            [
              -70.000188,
              41.886938
            ],
            [
              -70.009013,
              41.876625
            ],
            [
              -70.004486,
              41.838826
            ],
            [
              -70.003842,
              41.80852
            ],
            [
              -70.008462,
              41.800786
            ],
            [
              -70.024734,
              41.787364
            ],
            [
              -70.03324188827351,
              41.784243079589594
            ],
            [
              -70.064314,
              41.772845
            ],
            [
              -70.096061,
              41.766549
            ],
            [
              -70.121978,
              41.758841
            ],
            [
              -70.141533,
              41.760072
            ],
            [
              -70.182076,
              41.750885
            ],
            [
              -70.189254,
              41.751982
            ],
            [
              -70.216073,
              41.742981
            ],
            [
              -70.23485,
              41.733733
            ],
            [
              -70.259205,
              41.713954
            ],
            [
              -70.263654,
              41.714115
            ],
            [
              -70.272289,
              41.721346
            ],
            [
              -70.2736740563574,
              41.723626067037195
            ],
            [
              -70.275203,
              41.726143
            ],
            [
              -70.290957,
              41.734312
            ],
            [
              -70.375341,
              41.738779
            ],
            [
              -70.3833717670257,
              41.739993941407
            ],
            [
              -70.412476,
              41.744397
            ],
            [
              -70.471552,
              41.761563
            ],
            [
              -70.494048,
              41.773883
            ],
            [
              -70.517411,
              41.790953
            ],
            [
              -70.532656,
              41.804796
            ],
            [
              -70.5372893339932,
              41.8108590611294
            ],
            [
              -70.565363,
              41.786669
            ],
            [
              -70.581198,
              41.781625
            ],
            [
              -70.632555,
              41.762904
            ],
            [
              -70.62587,
              41.755199
            ],
            [
              -70.620785,
              41.74753
            ],
            [
              -70.64291432961869,
              41.7184094322972
            ],
            [
              -70.626529,
              41.712995
            ],
            [
              -70.623652,
              41.707398
            ],
            [
              -70.62544,
              41.698691
            ],
            [
              -70.645962,
              41.693794
            ],
            [
              -70.661475,
              41.681756
            ],
            [
              -70.649285,
              41.680943
            ],
            [
              -70.646308,
              41.678433
            ],
            [
              -70.63900361468,
              41.658345098463094
            ],
            [
              -70.637632,
              41.654573
            ],
            [
              -70.638695,
              41.649427
            ],
            [
              -70.650419,
              41.644202
            ],
            [
              -70.652614,
              41.637829
            ],
            [
              -70.645251,
              41.633547
            ],
            [
              -70.640003,
              41.624616
            ],
            [
              -70.651986,
              41.610184
            ],
            [
              -70.652449,
              41.60521
            ],
            [
              -70.64204,
              41.583066
            ],
            [
              -70.640948,
              41.577325
            ],
            [
              -70.642748,
              41.572385
            ],
            [
              -70.64878,
              41.56987
            ],
            [
              -70.653899,
              41.56516
            ],
            [
              -70.655365,
              41.557498
            ],
            [
              -70.654302,
              41.549926
            ],
            [
              -70.658659,
              41.543385
            ],
            [
              -70.6947997905558,
              41.5256400617567
            ]
          ]
        ]
      ]
    }
  },
  {
    "type": "Feature",
    "properties": {
      "STATEFP": "25",
      "COUNTYFP": "017",
      "COUNTYNS": "00606935",
      "AFFGEOID": "0500000US25017",
      "GEOID": "25017",
      "NAME": "Middlesex",
      "LSAD": "06",
      "ALAND": 2117830644,
      "AWATER": 75814940,
      "County_state": "Middlesex,25"
    },
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            [
              -71.89877002948,
              42.7114218822193
            ],
            [
              -71.897103,
              42.703728
            ],
            [
              -71.857757,
              42.674982
            ],
            [
              -71.858483,
              42.633815
            ],
            [
              -71.844843,
              42.637985
            ],
            [
              -71.776982,
              42.636695
            ],
            [
              -71.775755,
              42.64423
            ],
            [
              -71.664601,
              42.611598
            ],
            [
              -71.678969,
              42.53043
            ],
            [
              -71.635869,
              42.524024
            ],
            [
              -71.639206,
              42.530957
            ],
            [
              -71.619927,
              42.552502
            ],
            [
              -71.607852,
              42.54538
            ],
            [
              -71.593189,
              42.543834
            ],
            [
              -71.580287,
              42.550467
            ],
            [
              -71.53878,
              42.54303
            ],
            [
              -71.532417,
              42.523538
            ],
            [
              -71.529841,
              42.519841
            ],
            [
              -71.560367,
              42.47435
            ],
            [
              -71.543394,
              42.466452
            ],
            [
              -71.548903,
              42.447308
            ],
            [
              -71.5591,
              42.411943
            ],
            [
              -71.604251,
              42.397703
            ],
            [
              -71.58015,
              42.386998
            ],
            [
              -71.603065,
              42.367267
            ],
            [
              -71.610007,
              42.357403
            ],
            [
              -71.624702,
              42.350465
            ],
            [
              -71.61826,
              42.342589
            ],
            [
              -71.604646,
              42.336835
            ],
            [
              -71.585168,
              42.310977
            ],
            [
              -71.551126,
              42.326395
            ],
            [
              -71.511238,
              42.328805
            ],
            [
              -71.505391,
              42.328227
            ],
            [
              -71.486797,
              42.330189
            ],
            [
              -71.485948,
              42.311228
            ],
            [
              -71.497046,
              42.289829
            ],
            [
              -71.506478,
              42.264276
            ],
            [
              -71.522178,
              42.266454
            ],
            [
              -71.540003,
              42.262826
            ],
            [
              -71.549453,
              42.266095
            ],
            [
              -71.558533,
              42.267819
            ],
            [
              -71.577968,
              42.262444
            ],
            [
              -71.589855,
              42.251265
            ],
            [
              -71.599318,
              42.225959
            ],
            [
              -71.602221,
              42.218101
            ],
            [
              -71.58291,
              42.195559
            ],
            [
              -71.555738,
              42.182503
            ],
            [
              -71.539498,
              42.189734
            ],
            [
              -71.502626,
              42.191416
            ],
            [
              -71.497378,
              42.166643
            ],
            [
              -71.478119,
              42.156782
            ],
            [
              -71.463957,
              42.158239
            ],
            [
              -71.444067,
              42.174889
            ],
            [
              -71.430174,
              42.179386
            ],
            [
              -71.402295,
              42.178794
            ],
            [
              -71.404427,
              42.188433
            ],
            [
              -71.384765,
              42.192014
            ],
            [
              -71.361986,
              42.195761
            ],
            [
              -71.34417,
              42.200703
            ],
            [
              -71.350899,
              42.21264
            ],
            [
              -71.338435,
              42.215232
            ],
            [
              -71.342887,
              42.219956
            ],
            [
              -71.329561,
              42.23367
            ],
            [
              -71.332496,
              42.249075
            ],
            [
              -71.302922,
              42.248274
            ],
            [
              -71.30846,
              42.270709
            ],
            [
              -71.323662,
              42.297415
            ],
            [
              -71.326396,
              42.304402
            ],
            [
              -71.327327,
              42.313746
            ],
            [
              -71.269958,
              42.328086
            ],
            [
              -71.252808,
              42.32671
            ],
            [
              -71.249171,
              42.323568
            ],
            [
              -71.228405,
              42.318781
            ],
            [
              -71.227543,
              42.3154
            ],
            [
              -71.226894,
              42.308452
            ],
            [
              -71.207881,
              42.297157
            ],
            [
              -71.20524,
              42.289568
            ],
            [
              -71.191155,
              42.283059
            ],
            [
              -71.178636,
              42.294595
            ],
            [
              -71.164702,
              42.30383
            ],
            [
              -71.178935,
              42.314316
            ],
            [
              -71.169223,
              42.321994
            ],
            [
              -71.157036,
              42.33039
            ],
            [
              -71.167565,
              42.333441
            ],
            [
              -71.170003,
              42.344252
            ],
            [
              -71.17485,
              42.350337
            ],
            [
              -71.167625,
              42.360073
            ],
            [
              -71.147507,
              42.361788
            ],
            [
              -71.14363,
              42.364981
            ],
            [
              -71.130997,
              42.373796
            ],
            [
              -71.118497,
              42.368397
            ],
            [
              -71.117193,
              42.364229
            ],
            [
              -71.116995,
              42.361173
            ],
            [
              -71.103017,
              42.352638
            ],
            [
              -71.094042,
              42.35371
            ],
            [
              -71.077095,
              42.358697
            ],
            [
              -71.075777,
              42.361621
            ],
            [
              -71.070644,
              42.367949
            ],
            [
              -71.064059,
              42.369001
            ],
            [
              -71.072689,
              42.373222
            ],
            [
              -71.080947,
              42.382378
            ],
            [
              -71.073496,
              42.391796
            ],
            [
              -71.055295,
              42.387097
            ],
            [
              -71.040887,
              42.401749
            ],
            [
              -71.033181,
              42.414206
            ],
            [
              -71.028227,
              42.424202
            ],
            [
              -71.020377,
              42.43825
            ],
            [
              -71.025836,
              42.444709
            ],
            [
              -71.053365,
              42.475925
            ],
            [
              -71.052629,
              42.486057
            ],
            [
              -71.043774,
              42.501199
            ],
            [
              -71.035839,
              42.505192
            ],
            [
              -71.038905,
              42.524582
            ],
            [
              -71.07515,
              42.531035
            ],
            [
              -71.070978,
              42.555889
            ],
            [
              -71.05704,
              42.574019
            ],
            [
              -71.03546,
              42.570259
            ],
            [
              -71.028312,
              42.574082
            ],
            [
              -71.058766,
              42.609077
            ],
            [
              -71.076167,
              42.604136
            ],
            [
              -71.135456,
              42.599116
            ],
            [
              -71.145942,
              42.608335
            ],
            [
              -71.156564,
              42.614977
            ],
            [
              -71.164974,
              42.598082
            ],
            [
              -71.182023,
              42.608431
            ],
            [
              -71.171758,
              42.616545
            ],
            [
              -71.255749,
              42.656929
            ],
            [
              -71.242227,
              42.662287
            ],
            [
              -71.238103,
              42.669491
            ],
            [
              -71.2551987703894,
              42.736638343984396
            ],
            [
              -71.255605,
              42.736389
            ],
            [
              -71.267905,
              42.72589
            ],
            [
              -71.278929,
              42.711258
            ],
            [
              -71.294205,
              42.69699
            ],
            [
              -71.330206,
              42.69719
            ],
            [
              -71.351874,
              42.698154
            ],
            [
              -71.3697250416668,
              42.69857703283
            ],
            [
              -71.3869430269232,
              42.6989850635301
            ],
            [
              -71.4323540138531,
              42.700061210159
            ],
            [
              -71.4491742102848,
              42.70045981408529
            ],
            [
              -71.45768776362699,
              42.7006615677213
            ],
            [
              -71.467553246573,
              42.7008953592976
            ],
            [
              -71.49568066781009,
              42.7015619211054
            ],
            [
              -71.5426896188347,
              42.702675936184
            ],
            [
              -71.6306223191203,
              42.7047597596236
            ],
            [
              -71.631814,
              42.704788
            ],
            [
              -71.636214,
              42.704888
            ],
            [
              -71.65187391472301,
              42.7052307655759
            ],
            [
              -71.745817,
              42.707287
            ],
            [
              -71.7725568791756,
              42.70800987715601
            ],
            [
              -71.80539006840151,
              42.7088974788648
            ],
            [
              -71.89877002948,
              42.7114218822193
            ]
          ]
        ]
      ]
    }
  }
]
};